export enum StorageKeys {
  AppProductAccess = 'postal:productAccessId',
  AppRedirectPath = 'postal:app:redirect',
  AppRefreshToken = 'postal:refreshToken',
  ApprovedEventsFilter = 'postal:events:approved:filter',
  ApprovedItemsFilter = 'postal:items:approved:filter',
  AppToken = 'postal:token',
  BillingAccountTab = 'postal:billingAccount:tab',
  BudgetHistory = 'postal:budget:history',
  CollectionApprovedItemsFilter = 'postal:collections:approvedItems:filter',
  CollectionMarketplaceItemsFilter = 'postal:collections:marketplaceItems:filter',
  CollectionsFilter = 'postal:collections:filter',
  ContactsFilter = 'postal:contacts:filter',
  ContactsPage = 'postal:contacts:page',
  ContactsTable = 'postal:contacts:table',
  DashboardStats = 'postal:dashboard:stats',
  // V2 combined Events filter
  EventsFilter = 'postal:events:filter',
  EventsTab = 'postal:events:tab',
  ExtContactId = 'postal-extension:contactId',
  ExtMarketplaceFilter = 'postal-extension:marketplace:filter',
  ItemsTab = 'postal:items:tab',
  // V2 combined Marketplace filter
  MarketplaceFilter = 'postal:marketplace:filter',
  MarketplaceSearchV2Filter = 'postal:marketplace:filter-v2',
  MarketplaceEventsFilter = 'postal:events:marketplace:filter',
  MarketplaceItemsFilter = 'postal:items:marketplace:filter',
  MarketplaceDisableConfirm = 'postal:marketplace:disable-confirm',
  ReportingTab = 'postal:reporting:tab',
  TeamTab = 'postal:team:tab',
  UsersTab = 'postal:users:tab',
  // V2 Save draft options
  ToOrdersPage = 'postal:send:draft:to-orders',
}
