import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { Box, Grid, RadioGroup, SimpleGrid, Stack } from '@chakra-ui/react'
import {
  FontWeight,
  humanize,
  UiIconButton,
  ZButton,
  ZCard,
  ZCardHeader,
  ZHeading,
  ZInput,
  ZRadio,
  ZText,
} from '@postal-io/postal-ui'
import type { IntegrationSync } from 'api'
import { Status } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import React, { useMemo } from 'react'
import type { ExternalProvider } from './data'
import { ExternalSystem } from './data'
import type { IntegrationFormProps } from './helpers'
import { isActiveIntegration, isContactIntegration } from './helpers'

interface IntegrationMappingsProps {
  state: IntegrationFormProps
  provider: ExternalProvider
  userIntegration?: IntegrationSync
  isLoading?: boolean
  isFetching?: boolean
  handleCreateEnabled?: (objectType: string | string[]) => void
  addCustomField: () => void
  handleCustomField: (e: React.ChangeEvent<HTMLInputElement>, idx: number) => void
  removeCustomField: (idx: number) => void
}

export const IntegrationMappings: React.FC<IntegrationMappingsProps> = ({
  state,
  provider,
  userIntegration,
  isLoading,
  isFetching,
  handleCreateEnabled,
  addCustomField,
  handleCustomField,
  removeCustomField,
}) => {
  const contactCreateOptions = useMemo(() => {
    if (!provider?.integration?.hasContactCreate) return []
    return state?.current?.filter((i) => isActiveIntegration(i) && isContactIntegration(i)).map((i) => i.objectType)
  }, [provider?.integration?.hasContactCreate, state])

  const createEnabledSync = useMemo(() => {
    return state?.current?.find((i) => i.createEnabled)
  }, [state])

  const createEnabledType = useMemo(() => {
    return createEnabledSync?.objectType || ''
  }, [createEnabledSync?.objectType])

  const hasContactCreate = useMemo(() => {
    return !!contactCreateOptions.length
  }, [contactCreateOptions.length])

  const customFields = useMemo(() => {
    return createEnabledSync?.createCustomFields || []
  }, [createEnabledSync?.createCustomFields])

  const IsDisabled = !hasContactCreate || userIntegration?.status !== Status.Active || isFetching

  return (
    <>
      <ZCard
        isLoading={isLoading}
        variant="form"
      >
        <ZCardHeader
          fontWeight="normal"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          p={8}
          pb={0}
          gap={2}
        >
          Mappings
          <ZInfoTooltip
            label={`Send custom fields back to ${provider?.name}. This can help with identifying contacts that were created from within Postal.`}
          />
        </ZCardHeader>
        <Stack
          spacing={8}
          p={8}
        >
          <Box>
            <ZHeading
              fontSize="lg"
              display="flex"
              alignItems="center"
              gap={2}
              fontWeight="normal"
              mb={4}
            >
              Create new contacts
              <ZInfoTooltip
                label={`Enabling this will enable the creation of new Postal contacts into ${provider?.name}. Customers find this useful for Magic Links and other workflows where you may be seeing new contacts from within Postal.`}
              />
            </ZHeading>

            <RadioGroup
              size="lg"
              value={createEnabledType}
              onChange={handleCreateEnabled}
            >
              <SimpleGrid
                spacing={8}
                minChildWidth="150px"
              >
                <ZRadio
                  value=""
                  isDisabled={IsDisabled}
                >
                  <ZText
                    fontSize="md"
                    fontWeight="normal"
                  >
                    Disabled
                  </ZText>
                </ZRadio>
                {contactCreateOptions?.map((option, idx) => (
                  <ZRadio
                    key={`${option}-${idx}`}
                    value={option}
                    isDisabled={IsDisabled}
                  >
                    <ZText
                      fontSize="md"
                      fontWeight="normal"
                    >
                      Sync {humanize(option)}
                    </ZText>
                  </ZRadio>
                ))}
              </SimpleGrid>
            </RadioGroup>
          </Box>
          <Stack spacing={4}>
            {customFields.length && (
              <Grid
                templateColumns={{ base: '2fr 1fr 2fr 40px' }}
                gap={4}
              >
                <ZText>Postal</ZText>
                <Box />
                <ZText textTransform={provider.system === ExternalSystem.Salesforce ? 'uppercase' : 'capitalize'}>
                  {provider.system}
                </ZText>
              </Grid>
            )}
            {customFields.map((field, idx) => {
              return (
                <Grid
                  templateColumns={{ base: '2fr 1fr 2fr 40px' }}
                  gap={4}
                  justifyItems="center"
                  alignItems="center"
                  key={idx}
                >
                  <ZInput
                    aria-label="API Field"
                    name="field"
                    value={field.field}
                    onChange={(e: any) => handleCustomField(e, idx)}
                    placeholder="API Field"
                    isDisabled={IsDisabled}
                    isRequired
                  />
                  <ZText
                    fontSize="2xl"
                    fontWeight={FontWeight.Bold}
                    color="tertiary.500"
                  >
                    =
                  </ZText>
                  <ZInput
                    aria-label="API Value"
                    name="value"
                    value={field.value}
                    onChange={(e: any) => handleCustomField(e, idx)}
                    placeholder="API Value"
                    isDisabled={IsDisabled}
                    isRequired
                    mr={2}
                  />
                  <UiIconButton
                    variant="ghost"
                    aria-label="delete"
                    icon={<DeleteIcon />}
                    onClick={() => removeCustomField(idx)}
                    isDisabled={IsDisabled}
                  />
                </Grid>
              )
            })}
            {createEnabledType && (
              <ZButton
                alignSelf="flex-start"
                leftIcon={<AddIcon />}
                fontSize="16px"
                onClick={addCustomField}
                variant="link"
                isDisabled={IsDisabled}
              >
                Add mapping item
              </ZButton>
            )}
          </Stack>
        </Stack>
      </ZCard>
    </>
  )
}
