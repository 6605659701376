import type { SkeletonProps } from '@chakra-ui/react'
import { Flex, Text } from '@chakra-ui/react'
import type { UiCardProps } from '@postal-io/postal-ui'
import {
  UiHeading3,
  UiResponsiveCard,
  UiResponsiveCardBody,
  UiResponsiveCardEffect,
  UiResponsiveCardElements,
  UiResponsiveCardImage,
} from '@postal-io/postal-ui'
import React, { useMemo } from 'react'
import type { ApprovedPostal, MarketplaceProduct } from '../../api'
import { useAssets } from '../../hooks'
import { PostalStatusTag } from '../Postal/PostalCard'

export interface EventCardProps extends Omit<SkeletonProps, 'onClick'> {
  postal: ApprovedPostal | MarketplaceProduct
  onClick?: (postal: ApprovedPostal | MarketplaceProduct) => void
}

export const EVENT_CARD_HEIGHT = 400
export const EVENT_CARD_WIDTH = 325

export const EventCard: React.FC<EventCardProps> = ({ postal, children, onClick, ...rest }) => {
  const { assetUrlSrc } = useAssets()

  const status = useMemo(() => {
    return (postal as ApprovedPostal).event?.status
  }, [postal])

  const { src, fallbackSrc } = useMemo(() => {
    return assetUrlSrc(postal.imageUrls?.[0]?.url, {
      w: EVENT_CARD_WIDTH * 2,
      h: EVENT_CARD_HEIGHT * 2,
      fit: 'clip',
    })
  }, [assetUrlSrc, postal.imageUrls])

  const handleClick = () => {
    onClick && onClick(postal)
  }

  return (
    <UiResponsiveCard
      isLoading={!postal}
      {...rest}
    >
      <UiResponsiveCardEffect>
        <UiResponsiveCardBody
          aspectRatio={3.25 / 4}
          borderRadius="2xl"
          boxShadow="none"
          borderWidth="2px"
          borderColor="gray.100"
          onClick={handleClick}
        >
          <UiResponsiveCardImage
            src={src}
            fallbackSrc={fallbackSrc}
          />
          <UiResponsiveCardElements>
            {!!status && (
              <PostalStatusTag
                status={status}
                position="absolute"
                top="15px"
                right="15px"
              />
            )}
          </UiResponsiveCardElements>
        </UiResponsiveCardBody>
      </UiResponsiveCardEffect>
      {children}
    </UiResponsiveCard>
  )
}

export const EventNotFound: React.FC<SkeletonProps> = () => {
  return (
    <UiResponsiveCard>
      <UiResponsiveCardBody
        aspectRatio={3.25 / 4}
        borderRadius="2xl"
        boxShadow="none"
        borderWidth="2px"
        borderColor="gray.100"
      >
        <UiResponsiveCardElements>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            pos="absolute"
            top={0}
            bottom={0}
            p={4}
          >
            <UiHeading3
              size="md"
              mb={2}
              fontWeight="normal"
            >
              Not Found
            </UiHeading3>
            <Text
              color="gray.600"
              textAlign="center"
            >
              No Events were found. Please try changing your search criteria.
            </Text>
          </Flex>
        </UiResponsiveCardElements>
      </UiResponsiveCardBody>
    </UiResponsiveCard>
  )
}

export const EventLoading: React.FC<UiCardProps> = () => {
  return (
    <UiResponsiveCard isLoading>
      <UiResponsiveCardBody
        aspectRatio={3.25 / 4}
        borderRadius="2xl"
        boxShadow="none"
        borderWidth="2px"
        borderColor="gray.100"
      />
    </UiResponsiveCard>
  )
}
