import { Box, Flex, Grid, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import type { UiChangeEvent, UiGenericChangeEvent } from '@postal-io/postal-ui'
import {
  UiFormControl,
  useAlerts,
  ZButton,
  ZFormLabel,
  ZInputMoney,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
  ZModalOverlay,
  ZMoney,
  ZSelect,
  ZTextarea,
} from '@postal-io/postal-ui'
import type { BillingAccount } from 'api'
import { CreateTransferIntentDocument } from 'api'
import { isNumber } from 'lodash'
import type { ChangeEvent, FormEvent } from 'react'
import { useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'
import { ConfirmTransferIntentDocument } from '../../api/index'
import { ConfirmViewV2 } from './BalanceTransferCreateModal'

interface FormProps {
  fromBillingAccountId: string
  toBillingAccountId: string
  amount: number
  comment: string
}

interface OldBalanceTransferCreateFormProps {
  billingAccounts?: BillingAccount[]
  defaultValues?: { fromBillingAccountId?: string }
}

export const OldBalanceTransferCreateForm: React.FC<OldBalanceTransferCreateFormProps> = ({
  billingAccounts,
  defaultValues,
}) => {
  const Alert = useAlerts()
  const confirmDisclosure = useDisclosure()
  const [form, setForm] = useImmer<Record<string, any>>({ ...defaultValues })

  const fromBillingAccounts = billingAccounts

  const createTransferIntentMutation = useGraphqlMutation(CreateTransferIntentDocument)
  const confirmTransferIntentMutation = useGraphqlMutation(ConfirmTransferIntentDocument)

  const fromBillingAccount = useMemo(
    () => fromBillingAccounts?.find((a) => a.id === form.fromBillingAccountId),
    [form.fromBillingAccountId, fromBillingAccounts]
  )

  // until we support transfer between different currencies
  const toBillingAccounts = useMemo(() => {
    return fromBillingAccounts?.filter(
      (account) => account.currency === fromBillingAccount?.currency && account.id !== form.fromBillingAccountId
    )
  }, [form.fromBillingAccountId, fromBillingAccounts, fromBillingAccount?.currency])

  const handleInput = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | UiGenericChangeEvent
  ) => {
    setForm((draft: any) => void (draft[e.target.name] = e.target.value))
  }

  const handleInputMoney = ({ key, value }: UiChangeEvent<number>) => {
    setForm((draft: any) => void (draft[key] = value))
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    confirmDisclosure.onOpen()
  }

  const handleConfirm = async () => {
    const fromBillingAccount = billingAccounts?.find((account) => account.id === form.fromBillingAccountId)
    // ensure have enough money
    if ((fromBillingAccount?.balance ?? 0) < form.amount) {
      return Alert.warning('Amount exceeds current balance. Please adjust and try again.')
    }

    try {
      const res = await createTransferIntentMutation.mutateAsync({ input: form as FormProps })
      await confirmTransferIntentMutation.mutateAsync({ id: res.createTransferIntent?.id })
      Alert.success('Transfer confirmed')
      setForm({})
      confirmDisclosure.onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  // if only one entry for "Transfer To", go ahead and select it
  useEffect(() => {
    if (toBillingAccounts?.length === 1) {
      setForm((draft: any) => void (draft.toBillingAccountId = toBillingAccounts[0].id))
    }
  }, [setForm, toBillingAccounts])

  const isLoading = createTransferIntentMutation.isLoading

  const labelProps = {
    color: 'atomicGray.500',
    fontWeight: 350,
    fontSize: '14px',
    sx: {
      '& > span': {
        ml: 0,
      },
    },
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        templateColumns="repeat(3, 1fr)"
        columnGap={4}
        rowGap={6}
        p={8}
      >
        <UiFormControl
          id="fromBillingAccountId"
          isRequired
        >
          <Flex justifyContent="space-between">
            <ZFormLabel {...labelProps}>Transfer From</ZFormLabel>
            {isNumber(fromBillingAccount?.balance) && (
              <ZMoney
                cents={fromBillingAccount?.balance}
                fontWeight="semibold"
                fontSize="sm"
              />
            )}
          </Flex>
          <ZSelect
            name="fromBillingAccountId"
            value={form.fromBillingAccountId ?? ''}
            onChange={handleInput}
            placeholder="Select an account"
            isDisabled={isLoading}
          >
            {fromBillingAccounts?.map((account) => (
              <option
                key={account.id}
                value={account.id}
              >
                {account.name}
              </option>
            ))}
          </ZSelect>
        </UiFormControl>
        <UiFormControl
          id="toBillingAccountId"
          isRequired
        >
          <ZFormLabel {...labelProps}>Transfer To</ZFormLabel>
          <ZSelect
            name="toBillingAccountId"
            value={form.toBillingAccountId ?? ''}
            onChange={handleInput}
            placeholder="Select an account"
            isDisabled={isLoading}
          >
            {toBillingAccounts?.map((account) => (
              <option
                key={account.id}
                value={account.id}
              >
                {account.name}
              </option>
            ))}
          </ZSelect>
        </UiFormControl>
        <UiFormControl
          id="amount"
          isRequired
        >
          <ZFormLabel {...labelProps}>Amount</ZFormLabel>
          <ZInputMoney
            name="amount"
            value={form.amount ?? ''}
            onChange={handleInputMoney}
            max={fromBillingAccount?.balance as number}
            isDisabled={isLoading}
          />
        </UiFormControl>
        <UiFormControl
          id="comment"
          gridColumn="1 / 4"
          isRequired
        >
          <ZFormLabel {...labelProps}>Notes</ZFormLabel>
          <ZTextarea
            name="comment"
            value={form.comment ?? ''}
            onChange={handleInput}
            isDisabled={isLoading}
          />
        </UiFormControl>
        <Box>
          <ZButton
            type="submit"
            colorScheme="atomicBlue"
            minW="150px"
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Set up transfer
          </ZButton>
        </Box>
      </Grid>

      <ZModal
        size="6xl"
        {...confirmDisclosure}
        scrollBehavior="inside"
      >
        <ZModalOverlay />
        <ZModalContent>
          <ZModalHeader>Review and Confirm</ZModalHeader>
          <ZModalCloseButton />
          <ZModalBody>
            <ConfirmViewV2
              form={form}
              billingAccounts={billingAccounts}
            />
          </ZModalBody>
          <ZModalFooter
            justifyContent="flex-start"
            pt={8}
          >
            <ZButton
              size="md"
              colorScheme="atomicBlue"
              onClick={handleConfirm}
              isLoading={isLoading}
              isDisabled={isLoading}
              minW="140px"
            >
              Confirm
            </ZButton>
          </ZModalFooter>
        </ZModalContent>
      </ZModal>
    </form>
  )
}
