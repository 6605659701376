import { Box } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import {
  LexendFontWeight,
  UiAccordion,
  UiAccordionButton,
  UiAccordionIcon,
  UiAccordionItem,
  UiAccordionPanel,
  UiDate,
  UiHorizontalList,
  useAlertError,
  ZText,
} from '@postal-io/postal-ui'
import type { MagicLink } from 'api'
import { GetMagicLinkDocument } from 'api'
import React, { useMemo } from 'react'
import { getLinkStatus } from './ExtLinkHelper'
interface ExtLinkInfoProps extends UiCardProps {
  link?: MagicLink
}

export const ExtLinkInfo: React.FC<ExtLinkInfoProps> = ({ link, ...rest }) => {
  const linkStatus = getLinkStatus(link)

  const { data, error } = useGraphqlQuery(GetMagicLinkDocument, { id: link?.id as string }, { enabled: !!link?.id })

  const magicLink = useMemo(() => (data?.getMagicLink as MagicLink) || {}, [data])

  useAlertError(error)

  return (
    <Box {...rest}>
      <UiAccordion
        defaultIndex={[0]}
        allowMultiple
      >
        <UiAccordionItem border={0}>
          <h2>
            <UiAccordionButton
              px={0}
              borderBottom={1}
              borderBottomStyle="solid"
              borderBottomColor="atomicGray.200"
              _hover={{ bg: 'WhiteAlpha.50' }}
            >
              <Box
                flex="1"
                textAlign="left"
                fontWeight={LexendFontWeight.Bold}
              >
                Details
              </Box>
              <UiAccordionIcon />
            </UiAccordionButton>
          </h2>
          <UiAccordionPanel
            pb={4}
            px={0}
          >
            <UiHorizontalList
              templateColumns="1fr 2fr"
              gap={4}
              mt={2}
            >
              <ZText
                size="md"
                color="atomicGray.500"
              >
                Created
              </ZText>
              <ZText size="md">
                <UiDate date={magicLink.created?.dateTime} />
              </ZText>
              <ZText
                size="md"
                color="atomicGray.500"
              >
                Expires
              </ZText>
              <ZText
                size="md"
                color={linkStatus.isExpired ? 'atomicRed.500' : 'atomicGray.900'}
              >
                <UiDate
                  date={magicLink.expirationDate}
                  fallback="Never"
                />
              </ZText>
              <ZText
                size="md"
                color="atomicGray.500"
              >
                Order Limit
              </ZText>
              <ZText
                size="md"
                color={linkStatus.isFull ? 'atomicRed.500' : 'atomicGray.800'}
              >
                {magicLink.maxExecutions || 'Unlimited'}
              </ZText>
              <ZText
                size="md"
                color="atomicGray.500"
              >
                Last Order
              </ZText>
              <ZText size="md">
                <UiDate
                  date={magicLink.lastExecution}
                  fallback="n/a"
                />
              </ZText>
              <ZText
                size="md"
                color="atomicGray.500"
              >
                Last View
              </ZText>
              <ZText size="md">
                <UiDate
                  date={magicLink.lastView}
                  fallback="n/a"
                />
              </ZText>
            </UiHorizontalList>
          </UiAccordionPanel>
        </UiAccordionItem>
      </UiAccordion>
    </Box>
  )
}
