import { Collapse, Flex, FormControl, Icon, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import { useGraphqlFetch } from '@postal-io/postal-graphql'
import {
  UiTooltip,
  useAlerts,
  ZActionLink,
  ZButton,
  ZCardBody,
  ZCardDivider,
  ZHeading,
  ZInput,
  ZLink,
} from '@postal-io/postal-ui'
import type { Address, Contact, SearchableContact } from 'api'
import { AddressSource, AddressStatus, GetContactDocument } from 'api'
import { ZAlert, ZFormLabel } from 'components/Common/ZComponents'
import { AddressFormInput, defaultAddressForm } from 'components/Contact/AddressForm'
import { useCanViewContactInfo } from 'components/Contact/useCanViewContactInfo'
import { VerifyAddress } from 'components/Contact/VerifyAddress'
import { MultiSelectSelectedTable } from 'components/MultiSelectContacts/MultiSelectSelectedTable'
import { dequal } from 'dequal'
import { CompletedAction, useCompletedActions } from 'hooks'
import React, { useEffect, useState } from 'react'
import { MdOutlineCheckCircleOutline, MdOutlinePersonOutline } from 'react-icons/md'
import { useImmer } from 'use-immer'
import type { PostalCustomizeV2Props } from './PostalCustomize'
import { usePostalSendFieldErrors } from './usePostalSendFieldErrors'

export type QuickCreateContactForm = { firstName: string; lastName: string; phoneNumber: string; emailAddress: string }
const blankContactForm = { firstName: '', lastName: '', phoneNumber: '', emailAddress: '' }

export const PostalCustomizeBulkSend: React.FC<PostalCustomizeV2Props> = ({ context, send }) => {
  const Alert = useAlerts()
  const verifyAddress = useDisclosure()
  const selectContact = useDisclosure()
  const { canViewAddresses } = useCanViewContactInfo()
  const { isComplete, markComplete } = useCompletedActions()
  const [showAddressRedactMessage, setShowAddressRedactMessage] = useState<boolean>(false)
  const [addressForm, setAddressForm] = useImmer<Address>(context.shipToAddress ?? defaultAddressForm)
  const [createContactForm, setCreateContactForm] = useImmer<QuickCreateContactForm>(
    context.newContact ?? blankContactForm
  )
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null)

  const fetchContact = useGraphqlFetch(GetContactDocument)

  const handleContactSelect = async (contact: SearchableContact) => {
    send({
      type: 'SET_CONTACTS',
      data: { items: [contact], orfilters: [{ id: { in: [contact.id] } }], totalRecords: 1 },
    })

    try {
      const { getContact } = await fetchContact({ id: contact.id })
      selectContact.onClose()
      setSelectedContact(getContact)

      // prefill preferred address & verification status if address form empty
      const address = getContact.addresses?.find((a) => a?.preferred) ?? getContact.addresses?.[0]
      if (canViewAddresses && address && dequal(addressForm, defaultAddressForm)) {
        setAddressForm(address)
        if (address.status === AddressStatus.Matched) {
          setTimeout(() => send({ type: 'SET_SHIP_TO_ADDRESS_VERIFIED', data: address }))
        }
      }
      // show address redacted banner if not dismissed
      if (!canViewAddresses) setShowAddressRedactMessage(!isComplete(CompletedAction.DismissBulkSendRedactedAddressTip))
    } catch (e) {
      Alert.error(e.message)
    }
  }

  useEffect(() => {
    // only use the currently selected contact if there's only one selected
    const contact = context.contacts?.items?.length === 1 && context.contacts?.items?.[0]
    if (contact) handleContactSelect(contact)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleContactFormChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target
    setCreateContactForm((draft: Record<string, any>) => void (draft[name] = value))
    send({ type: 'SET_NEW_CONTACT', data: { ...createContactForm, [name]: value } })
  }

  const resetForm = () => {
    setSelectedContact(null)
    send({
      type: 'SET_CONTACTS',
      data: { items: [], orfilters: [], totalRecords: 0 },
    })
    setCreateContactForm(blankContactForm)
    setAddressForm(defaultAddressForm)
  }

  useEffect(() => {
    if (!dequal(context.shipToAddress, addressForm)) send({ type: 'SET_SHIP_TO_ADDRESS', data: addressForm })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressForm])

  const verifiedAddressErrorProps = usePostalSendFieldErrors({ context, field: 'verifyAddress' })
  const contactSelectErrorProps = usePostalSendFieldErrors({ context, field: 'contacts' })

  const stateRequired = ['United States', 'US', 'USA']
    .map((s) => s.toUpperCase())
    .includes(addressForm.country?.toUpperCase() ?? '')

  const disableVerifyButton =
    !addressForm.address1 || !addressForm.city || !addressForm.country || (stateRequired && !addressForm.state)

  return (
    <>
      <ZCardBody>
        <ZHeading
          as="h3"
          size="h6"
          pb={8}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="atomicGray.800"
        >
          Recipient info
          <ZLink
            animation={contactSelectErrorProps.animation}
            color="atomicBlue.400"
            _hover={{ color: 'atomicBlue.600' }}
            onClick={selectContact.onOpen}
            size="lg"
            display="flex"
            alignItems="center"
          >
            <Icon
              as={MdOutlinePersonOutline}
              fontSize="20px"
              mr={1.5}
            />
            Select from Contacts
          </ZLink>
        </ZHeading>
        <SimpleGrid
          columns={2}
          spacing={4}
          pb={5}
        >
          <FormControl
            id="firstName"
            isRequired
          >
            <ZFormLabel fontWeight="normal">First Name</ZFormLabel>
            <ZInput
              onChange={handleContactFormChange}
              disabled={!!selectedContact}
              _disabled={{ bg: 'atomicGray.10', cursor: 'not-allowed' }}
              name="firstName"
              value={selectedContact?.firstName ?? createContactForm.firstName}
              placeholder="First Name"
            />
          </FormControl>
          <FormControl
            id="lastName"
            isRequired
          >
            <ZFormLabel fontWeight="normal">Last Name</ZFormLabel>
            <ZInput
              onChange={handleContactFormChange}
              disabled={!!selectedContact}
              _disabled={{ bg: 'atomicGray.10', cursor: 'not-allowed' }}
              name="lastName"
              value={selectedContact?.lastName ?? createContactForm.lastName}
              placeholder="Last Name"
            />
          </FormControl>
          <FormControl id="phoneNumber">
            <ZFormLabel fontWeight="normal">Phone Number</ZFormLabel>
            <ZInput
              onChange={handleContactFormChange}
              disabled={!!selectedContact}
              _disabled={{ bg: 'atomicGray.10', cursor: 'not-allowed' }}
              name="phoneNumber"
              value={selectedContact?.phones?.[0]?.phoneNumber ?? createContactForm.phoneNumber}
              placeholder="Phone Number"
            />
          </FormControl>
          <FormControl id="email">
            <ZFormLabel fontWeight="normal">Email</ZFormLabel>
            <ZInput
              onChange={handleContactFormChange}
              disabled={!!selectedContact}
              _disabled={{ bg: 'atomicGray.10', cursor: 'not-allowed' }}
              name="emailAddress"
              value={selectedContact?.emailAddress ?? createContactForm.emailAddress}
              placeholder="Email"
            />
          </FormControl>
        </SimpleGrid>
        {selectedContact ? (
          <Flex justifyContent="flex-end">
            <ZActionLink
              color="atomicBlue.400"
              cursor="pointer"
              _hover={{ color: 'atomicBlue.500' }}
              onClick={resetForm}
            >
              Clear Contact Info
            </ZActionLink>
          </Flex>
        ) : (
          <ZAlert
            status="info"
            hideClose
          >
            Note: These fields will create a new contact. Please{' '}
            <ZLink
              color="atomicBlue.400"
              onClick={selectContact.onOpen}
            >
              Select from Contacts
            </ZLink>{' '}
            if you wish to send to an existing contact.
          </ZAlert>
        )}
        <ZCardDivider
          mx={-10}
          my={8}
        />
        <ZHeading
          as="h3"
          size="h6"
          pt={2}
          pb={8}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="atomicGray.800"
        >
          Address
        </ZHeading>
        <AddressFormInput
          context={context}
          form={addressForm}
          setForm={setAddressForm}
        />

        <Collapse in={showAddressRedactMessage}>
          <ZAlert
            status="info"
            my={5}
            onClose={() => {
              setShowAddressRedactMessage(false)
              markComplete(CompletedAction.DismissBulkSendRedactedAddressTip)
            }}
          >
            Your organization has an account setting enabled that prevents users from seeing addresses. Please contact
            your Admin to learn more.
          </ZAlert>
        </Collapse>
        <Flex
          mt={5}
          justifyContent="flex-end"
        >
          {context.verifiedShipToAddress ? (
            <ZButton
              variant="outline"
              pointerEvents="none"
              borderColor="atomicGray.100"
            >
              {context.shipToAddress?.status === AddressStatus.MatchedOverride ? (
                'Non-verified Address Confirmed'
              ) : (
                <>
                  Address Verified{' '}
                  <Icon
                    as={MdOutlineCheckCircleOutline}
                    fontSize="xl"
                    color="atomicBlue.400"
                    ml={2}
                  />
                </>
              )}
            </ZButton>
          ) : (
            <UiTooltip
              label={
                disableVerifyButton &&
                `Add a ${
                  !addressForm.address1
                    ? 'valid Address'
                    : !addressForm.city
                    ? 'City'
                    : stateRequired && !addressForm.state
                    ? 'State'
                    : !addressForm.country
                    ? 'Country'
                    : ''
                } to verify`
              }
              shouldWrapChildren
            >
              <ZButton
                {...verifiedAddressErrorProps}
                variant="outline"
                colorScheme="atomicBlue"
                onClick={verifyAddress.onOpen}
                _disabled={{
                  // override disabled styling to use opacity: 1 to better show error state animation
                  borderColor: 'atomicGray.400',
                  color: 'atomicGray.400',
                  cursor: 'not-allowed',
                }}
                isDisabled={disableVerifyButton}
              >
                Verify Address
              </ZButton>
            </UiTooltip>
          )}
        </Flex>
      </ZCardBody>

      {selectContact.isOpen && (
        <MultiSelectSelectedTable
          {...selectContact}
          orfilters={[]}
          onSelect={handleContactSelect}
        />
      )}

      {verifyAddress.isOpen && (
        <VerifyAddress
          {...verifyAddress}
          address={addressForm}
          onVerified={(address) => {
            setAddressForm(address as Address)
            setTimeout(() =>
              send({
                type: 'SET_SHIP_TO_ADDRESS_VERIFIED',
                data: { ...address, preferred: false, source: AddressSource.Manual },
              })
            )
          }}
        />
      )}
    </>
  )
}
