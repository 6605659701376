import { Box, HStack, Icon, Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlFetch } from '@postal-io/postal-graphql'
import { ZButton, ZCard, ZCardBody, ZHeading, ZLink, ZText } from '@postal-io/postal-ui'
import type { Address } from 'api'
import { AddressStatus, VerifyAddressDocument } from 'api'
import { ZAlert, ZBasicDialogButtons, ZDialog } from 'components/Common/ZComponents'
import compact from 'lodash/compact'
import React, { useEffect, useMemo, useState } from 'react'
import { MdOutlineCheckCircle } from 'react-icons/md'

const cleanAddress = (dirtyAddressObj = {} as any) => {
  const dirtyAddress = { ...dirtyAddressObj }

  return {
    address1: dirtyAddress.address1,
    address2: dirtyAddress.address2,
    address3: '',
    city: dirtyAddress.city,
    state: dirtyAddress.state,
    postalCode: dirtyAddress.postalCode,
    country: dirtyAddress.country,
  }
}

const cleanUspsAddress = (dirtyAddress = {} as any) => {
  const uspsAddress = dirtyAddress.uspsAddress || dirtyAddress
  return {
    address1: uspsAddress.address1,
    address2: uspsAddress.address2,
    address3: uspsAddress.address3,
    city: uspsAddress.city,
    state: uspsAddress.state,
    postalCode: uspsAddress.postalCode,
    country: uspsAddress.country || dirtyAddress.country,
  }
}

interface AddressCardProps {
  address?: Partial<Address>
}
const AddressCard: React.FC<AddressCardProps> = ({ address }) => {
  return (
    <ZCard variant="form">
      <ZCardBody p={5}>
        <ZText data-private>{address?.address1}</ZText>
        {address?.address2 && <ZText data-private>{address.address2}</ZText>}
        {address?.address3 && <ZText data-private>{address.address3}</ZText>}
        <ZText data-private>{compact([address?.city, address?.state]).join(', ')} </ZText>
        <ZText data-private>{address?.postalCode}</ZText>
        <ZText data-private>{address?.country}</ZText>
      </ZCardBody>
    </ZCard>
  )
}

interface VerifyAddressProps {
  address: Partial<Address>
  isOpen: boolean
  onClose: () => void
  onVerified?: (address: Partial<Address>) => void
}
export const VerifyAddress: React.FC<VerifyAddressProps> = ({ address, isOpen, onClose, onVerified }) => {
  const verifyAddress = useGraphqlFetch(VerifyAddressDocument)
  const confirmUnverifiedAddress = useDisclosure()
  const [verifiedAddress, setVerifiedAddress] = useState<Partial<Address>>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!address) return
    setIsLoading(true)
    verifyAddress({ data: cleanAddress(address) })
      .then(({ verifyAddress }) => setVerifiedAddress(verifyAddress))
      .catch((err) => setVerifiedAddress({ status: AddressStatus.Error, statusReason: err.message }))
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  const handleOverride = async () => {
    await onVerified?.({
      ...cleanAddress(address),
      status: AddressStatus.MatchedOverride,
    })
    onClose()
  }

  const handleVerified = async () => {
    onVerified?.({
      ...cleanUspsAddress(verifiedAddress),
      status: AddressStatus.Matched,
    })
    onClose()
  }

  interface Results {
    status: 'success' | 'info' | 'warning' | 'error'
    message: string
  }
  const results = useMemo(() => {
    switch (verifiedAddress?.status) {
      case AddressStatus.Matched:
      case AddressStatus.MatchedOverride:
        return {
          status: 'info',
          message: 'We found a matching address!',
        } as Results
      case AddressStatus.Invalid:
        return {
          status: 'warning',
          message: `You entered an unknown address.
                    ${verifiedAddress.statusReason}`,
        } as Results
      case AddressStatus.Blocked:
        return {
          status: 'error',
          message: verifiedAddress?.statusReason || 'We are unable to ship to that address.',
        } as Results
      case AddressStatus.Error:
      default:
        return {
          status: 'error',
          message: verifiedAddress?.statusReason || 'An unknown error occurred.',
        } as Results
    }
  }, [verifiedAddress?.status, verifiedAddress?.statusReason])

  // if (isLoading) return <UiCard />

  return (
    <>
      <ZDialog
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        title="Verify Preferred Address"
      >
        {isLoading ? (
          <Box data-testid="verify-address-modal">
            <ZAlert
              status="info"
              hideClose
              data-testid="verify-address-alert"
            >
              Processing...
            </ZAlert>
          </Box>
        ) : (
          <>
            <Box data-testid="verify-address-modal">
              <ZAlert
                status={results?.status}
                hideClose
                data-testid="verify-address-alert"
              >
                {results?.message}
              </ZAlert>
              <Stack
                spacing={4}
                mt={8}
                data-testid="verify-address-stack"
              >
                <Box data-testid="verified-address-original">
                  <ZHeading
                    pb={2}
                    as="h3"
                    fontSize="h6"
                    fontWeight="bold"
                    color="atomicGray.600"
                  >
                    Original Address
                  </ZHeading>
                  <AddressCard address={cleanAddress(address)} />
                </Box>
                {(verifiedAddress?.status === AddressStatus.Matched ||
                  verifiedAddress?.status === AddressStatus.MatchedOverride) && (
                  <Box data-testid="verified-address">
                    <ZHeading
                      pb={2}
                      as="h3"
                      fontSize="h6"
                      fontWeight="bold"
                      color="atomicGray.600"
                      verticalAlign="middle"
                    >
                      Verified Address{' '}
                      <Icon
                        as={MdOutlineCheckCircle}
                        fontSize="18px"
                        color="atomicBlue.400"
                        verticalAlign="bottom"
                      />
                    </ZHeading>
                    <AddressCard address={cleanUspsAddress(verifiedAddress)} />
                  </Box>
                )}
              </Stack>
            </Box>
          </>
        )}
        <HStack
          data-testid="verify-address-modal-footer"
          justify="space-between"
          mt={5}
        >
          {verifiedAddress?.status === AddressStatus.Invalid ? (
            <>
              <ZButton
                colorScheme="atomicBlue"
                mr={3}
                onClick={onClose}
              >
                Update and Resubmit
              </ZButton>
              <ZButton
                variant="outline"
                onClick={confirmUnverifiedAddress.onOpen}
              >
                Use Unverified Address
              </ZButton>
            </>
          ) : verifiedAddress?.status === AddressStatus.Matched ||
            verifiedAddress?.status === AddressStatus.MatchedOverride ? (
            <>
              <ZButton
                variant="outline"
                mr={3}
                onClick={confirmUnverifiedAddress.onOpen}
              >
                Use Original Address
              </ZButton>
              <ZButton
                colorScheme="atomicBlue"
                onClick={handleVerified}
              >
                Use Verified Address
              </ZButton>
            </>
          ) : (
            <ZButton
              variant="outline"
              mr={3}
              onClick={onClose}
            >
              Try Again
            </ZButton>
          )}
        </HStack>
      </ZDialog>
      {confirmUnverifiedAddress.isOpen && (
        <ZDialog
          {...confirmUnverifiedAddress}
          size="lg"
          title="Confirm Unverified Address"
          bodyProps={{ pt: 0 }}
        >
          You have selected to move forward with an unverified address. Please note this could affect the delivery of
          your item(s). Learn more{' '}
          <ZLink
            href="https://help.postal.com/helpcenter/s/article/Address-Verification"
            target="_blank"
            fontSize="inherit"
          >
            here
          </ZLink>
          .
          <ZBasicDialogButtons
            onClose={confirmUnverifiedAddress.onClose}
            onConfirm={handleOverride}
          />
        </ZDialog>
      )}
    </>
  )
}
