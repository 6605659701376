import type { SlideDirection } from '@chakra-ui/react'
import { Box, Flex, Slide, StatNumber } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import {
  UiIconButton,
  UiSlideContainer,
  UiStat,
  UiStatGroup,
  UiStatHelpText,
  useColor,
  usePagination,
  ZCard,
  ZCardHeader,
  ZText,
} from '@postal-io/postal-ui'
import { DataObjectType, Granularity, UserAnalyticsV2Document } from 'api'
import { ZStatArrow } from 'components/Common/ZComponents'
import { differenceInMilliseconds, subMilliseconds } from 'date-fns'
import { getChangeMetric } from 'lib'
import { isEqual, sortBy } from 'lodash'
import React, { useMemo, useRef } from 'react'
import type { AxisOptions } from 'react-charts'
import { Chart } from 'react-charts'
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md'
import { GroupBy } from '../../../api/index'
import { useSession } from '../../../hooks/useSession'
import { NoDataOverlay } from '../NoDataOverlay'
import type { GroupByOptionV2 } from '../ReportingFilter/GroupByFilter'
import { PLACEHOLDER_USER_ANALYTICS_V2 } from './data'

type BarData = { name: string; data: number; color: string }

const CHART_SIZE = 5

interface GiftEmailSendsCardV2Props extends ZCardProps {
  startDate: Date
  endDate: Date
  teamIds?: string[]
  userIds?: string[]
  groupBy?: GroupByOptionV2
}

export const GiftEmailSendsCard: React.FC<GiftEmailSendsCardV2Props> = ({
  startDate,
  endDate,
  teamIds,
  userIds,
  groupBy,
  ...rest
}) => {
  const curQuery = useGraphqlQuery(UserAnalyticsV2Document, {
    config: {
      startDate,
      endDate,
      teamIds,
      userIds,
      groupBy,
      granularity: Granularity.All,
      type: DataObjectType.User,
    },
  })
  const { session } = useSession()
  const { colorCode } = useColor()

  const ALL_DATA_SORTED = useMemo(() => {
    const ALL_DATA = curQuery.data?.userAnalyticsV2 || []
    if (ALL_DATA?.length) {
      return sortBy(ALL_DATA, 'firstName')
    } else {
      return sortBy(PLACEHOLDER_USER_ANALYTICS_V2, 'firstName')
    }
  }, [curQuery.data?.userAnalyticsV2])

  const { deliveryEmailSent } = useMemo(() => {
    const data = curQuery.data?.userAnalyticsV2 || []
    return data.reduce((sum, user) => {
      sum.deliveryEmailSent = (sum.deliveryEmailSent || 0) + (user.deliveryEmailSent || 0)
      sum.deliveryEmailAccepted = (sum.deliveryEmailAccepted || 0) + (user.deliveryEmailAccepted || 0)
      return sum
    }, {})
  }, [curQuery.data?.userAnalyticsV2])

  const newStartDate = useMemo(() => {
    const diff = differenceInMilliseconds(endDate, startDate)
    return subMilliseconds(startDate, diff)
  }, [endDate, startDate])

  const prevQuery = useGraphqlQuery(UserAnalyticsV2Document, {
    config: {
      startDate: newStartDate,
      endDate: startDate,
      teamIds,
      userIds,
      groupBy,
      granularity: Granularity.All,
      type: DataObjectType.User,
    },
  })

  const { deliveryEmailSent: deliveryEmailSentPrev } = useMemo(() => {
    const data = prevQuery.data?.userAnalyticsV2 || []
    return data.reduce((sum, user) => {
      sum.deliveryEmailSent = (sum.deliveryEmailSent || 0) + (user.deliveryEmailSent || 0)
      sum.deliveryEmailAccepted = (sum.deliveryEmailAccepted || 0) + (user.deliveryEmailAccepted || 0)
      return sum
    }, {})
  }, [prevQuery.data?.userAnalyticsV2])

  const { deliveryEmailSentChange } = useMemo(() => {
    return {
      deliveryEmailSentChange: getChangeMetric(deliveryEmailSent, deliveryEmailSentPrev),
    }
  }, [deliveryEmailSent, deliveryEmailSentPrev])

  const [items, pager] = usePagination(ALL_DATA_SORTED ?? [], CHART_SIZE)

  const normalizedDataEmailSent = useMemo(() => {
    return (
      items.map((item) => {
        return {
          name:
            groupBy === GroupBy.Team
              ? item?.teamName
                ? item.teamName
                : session.accountName
              : `${item?.firstName} ${item?.lastName}` ?? '',
          data: item?.deliveryEmailSent ?? 0,
          color: 'atomicBlue.400',
        }
      }) ?? []
    )
  }, [groupBy, items, session.accountName]) as BarData[]

  const normalizedDataEmailAccpted = useMemo(() => {
    return (
      items.map((item) => {
        return {
          name:
            groupBy === GroupBy.Team
              ? item?.teamName
                ? item.teamName
                : session.accountName
              : `${item?.firstName} ${item?.lastName}` ?? '',
          data: item?.deliveryEmailAccepted ?? 0,
          color: 'atomicPurple.400',
        }
      }) ?? []
    )
  }, [groupBy, items, session.accountName]) as BarData[]

  const normalizedChartData = useMemo(() => {
    return [
      { label: 'Sent', data: normalizedDataEmailSent },
      { label: 'Accepted', data: normalizedDataEmailAccpted },
    ]
  }, [normalizedDataEmailAccpted, normalizedDataEmailSent])

  const primaryAxis = React.useMemo(
    (): AxisOptions<BarData> => ({
      getValue: (datum) => datum.name,
      scaleType: 'band',
    }),
    []
  )

  const secondaryAxes = React.useMemo(
    (): AxisOptions<BarData>[] => [
      {
        getValue: (datum) => datum.data ?? 0,
        elementType: 'bar',
        min: 0,
      },
    ],
    []
  )

  const direction = useRef('right')
  const currentItemList = useRef(items)

  const nextPage = () => {
    direction.current = 'right'
    currentItemList.current = items
    pager.onNext()
  }

  const prevPage = () => {
    direction.current = 'left'
    currentItemList.current = items
    pager.onPrevious()
  }

  const showNoDataOverlayV2 = useMemo(
    () => !curQuery.isFetching && !deliveryEmailSent,
    [curQuery.isFetching, deliveryEmailSent]
  )

  return (
    <ZCard
      isLoading={prevQuery.isLoading}
      isFetching={prevQuery.isFetching}
      variant="form"
      {...rest}
    >
      {showNoDataOverlayV2 && <NoDataOverlay />}

      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box
          display="flex"
          flexDir="column"
        >
          <ZCardHeader
            display="flex"
            p={4}
            pb={2}
            fontSize="lg"
          >
            Gift Email Sends
          </ZCardHeader>
          <Flex
            gap={4}
            px={4}
          >
            <Flex alignItems="center">
              <Box
                borderRadius="50%"
                backgroundColor="atomicBlue.400"
                boxSize="10px"
                mr={2}
              />{' '}
              <ZText
                fontSize="xs"
                color="atomicGray.500"
              >
                Sent
              </ZText>
            </Flex>
            <Flex alignItems="center">
              <Box
                borderRadius="50%"
                backgroundColor="atomicPurple.400"
                boxSize="10px"
                mr={2}
              />{' '}
              <ZText
                fontSize="xs"
                color="atomicGray.500"
              >
                Accepted
              </ZText>
            </Flex>
          </Flex>
        </Box>
        <UiStatGroup fontFamily="Lexend">
          <UiStat
            display="flex"
            justifyContent="flex-end"
          >
            <StatNumber
              fontWeight="normal"
              fontSize="lg"
              p={4}
              pb={2}
            >
              {deliveryEmailSent ?? 0}
            </StatNumber>
            <UiStatHelpText
              p={0}
              m={0}
              display="flex"
              alignItems="center"
              gap={1}
            >
              <ZStatArrow
                color={deliveryEmailSentChange >= 0 ? 'vendorGreen.500' : 'atomicRed.500'}
                type={deliveryEmailSentChange >= 0 ? 'increase' : 'decrease'}
              />
              <ZText
                fontSize="xs"
                color="atomicGray.500"
              >
                {Math.round(deliveryEmailSentChange) || 0} %
              </ZText>
            </UiStatHelpText>
          </UiStat>
        </UiStatGroup>
      </Flex>
      <Flex
        alignItems="center"
        mt={8}
        pb={8}
      >
        <UiIconButton
          aria-label="previous chart"
          size="sm"
          variant="ghost"
          icon={<MdOutlineChevronLeft size="50px" />}
          onClick={prevPage}
          disabled={pager.page === 1}
        />
        <UiSlideContainer h="400px">
          <Slide
            key={pager.page}
            direction={direction.current as SlideDirection}
            in={pager.page === 1 || !isEqual(items, currentItemList.current)}
            style={{
              position: 'relative',
            }}
          >
            <Chart
              options={{
                getDatumStyle: (datum) => ({ color: colorCode(datum.originalDatum.color) }),
                data: normalizedChartData,
                primaryAxis,
                secondaryAxes,
              }}
            />
          </Slide>
        </UiSlideContainer>
        <UiIconButton
          aria-label="previous chart"
          size="sm"
          variant="ghost"
          icon={<MdOutlineChevronRight size="50px" />}
          onClick={nextPage}
          disabled={pager.pages === pager.page}
        />
      </Flex>
    </ZCard>
  )
}
