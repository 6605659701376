import { Fade, FormControl, HStack, InputGroup } from '@chakra-ui/react'
import { UiToggle, ZFormLabel, ZInput, ZInputLeftIcon, ZSubNavbar, ZText } from '@postal-io/postal-ui'
import { AnalyticsEventV2, useAcl, useAnalyticsSend } from 'hooks'
import type { ChangeEvent } from 'react'
import React, { useContext } from 'react'
import { MdSearch } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { MarketplaceContext, MarketplaceView } from './useMarketplace'

export const MarketplaceSearchBar: React.FC<any> = (props) => {
  const navigate = useNavigate()
  const { aclCheck } = useAcl()
  const sendAnalytics = useAnalyticsSend()
  const hasMarketplace = aclCheck({ module: 'postals.marketplace' })

  const { state, marketplaceFilters } = useContext(MarketplaceContext)
  const { filters, updateFilter } = marketplaceFilters

  const handleToggle = () => {
    if (state.view === MarketplaceView.Postals) {
      navigate('/items/marketplace')
    } else {
      sendAnalytics({ event: AnalyticsEventV2.Marketplace_ApprovedItems_Clicked })
      navigate('/items/postals')
    }
  }

  const handleSwitchToProductsPage = useDebouncedCallback(() => {
    if (state.view === MarketplaceView.Home) {
      navigate('/items/marketplace')
    }
  }, 600)

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    handleSwitchToProductsPage()
    updateFilter?.('q', e.target.value)
  }

  return (
    <ZSubNavbar
      left={
        <FormControl id="isApproved">
          <HStack spacing={3}>
            <UiToggle
              name="isApproved"
              colorScheme="atomicBlue"
              isChecked={state.view === MarketplaceView.Postals}
              onChange={handleToggle}
              isDisabled={!hasMarketplace}
            />

            <ZFormLabel color="atomicGray.500">Approved items</ZFormLabel>
          </HStack>
        </FormControl>
      }
      center={
        <FormControl id="search">
          <InputGroup>
            <ZInputLeftIcon icon={<MdSearch size="16px" />} />
            <ZInput
              name="search"
              placeholder="Search"
              w="440px"
              value={filters.q ?? ''}
              onChange={handleSearch}
            />
          </InputGroup>
        </FormControl>
      }
      right={
        <Fade in>
          <ZText color="atomicGray.500">{state.numResultsText}</ZText>
        </Fade>
      }
      px={0}
      gridProps={{ py: 4 }}
      {...props}
    />
  )
}
