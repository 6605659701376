import { useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiMenu, UiMenuButton, UiMenuItem, UiMenuList } from '@postal-io/postal-ui'
import { Role, SearchContactListsDocument } from 'api'
import { useAcl } from 'hooks'
import React, { useMemo } from 'react'
import { MdArrowDropDown } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'

const FAVORITE_LIST_NAME = 'favorites'

export const ContactsListSelect: React.FC = () => {
  const { data } = useGraphqlQuery(SearchContactListsDocument)
  const navigate = useNavigate()
  const disclosure = useDisclosure()

  const lists = data?.searchContactLists

  const { hasRole } = useAcl()
  const { ownerId, listId } = useParams() as any

  const isOwnerMe = ownerId === 'me'
  const isManager = hasRole(Role.Manager)
  const isUser = hasRole(Role.User)

  const favoriteList = useMemo(() => {
    return lists?.find((list) => list.name.toLowerCase() === FAVORITE_LIST_NAME)
  }, [lists])

  const savedLists = useMemo(() => {
    return lists ? lists.filter((list) => list.name.toLowerCase() !== FAVORITE_LIST_NAME) : []
  }, [lists])

  const selectedList = useMemo(() => lists?.find(({ id }) => id === listId), [listId, lists])

  interface Option {
    id: string
    name: string
    value: string
  }

  const options = useMemo(() => {
    const opts: Option[] = []
    if (isManager) {
      opts.push({
        id: 'all-contacts',
        name: 'All Contacts',
        value: '/contacts',
      })
    }
    if (isUser)
      opts.push({
        id: 'my-contacts',
        name: 'My Contacts',
        value: '/contacts/owner/me',
      })
    if (favoriteList)
      opts.push({
        id: favoriteList.id,
        name: 'Favorites',
        value: `/contacts/list/${favoriteList.id}`,
      })
    savedLists.forEach(({ id, name }) => {
      opts.push({
        id,
        name,
        value: `/contacts/list/${id}`,
      })
    })
    return opts
  }, [favoriteList, isManager, isUser, savedLists])

  const selected = useMemo(() => {
    let id = ''
    const isAdmin = hasRole(Role.Manager)
    if (isOwnerMe) {
      id = 'my-contacts'
    } else if (selectedList) {
      id = selectedList.id
    } else if (!ownerId) {
      id = isAdmin ? 'all-contacts' : 'my-contacts'
    }
    return options.find((option) => option.id === id)
  }, [hasRole, isOwnerMe, options, ownerId, selectedList])

  const filteredOptions = useMemo(() => {
    return options.filter((o) => o.id !== selected?.id)
  }, [options, selected?.id])

  return (
    <UiMenu
      {...disclosure}
      matchWidth
      placement="bottom"
    >
      <UiMenuButton
        borderRadius={3}
        color="gray.500"
        h={10}
        textAlign="left"
        rightIcon={<MdArrowDropDown size="18px" />}
        w={200}
      >
        {selected?.name || 'Select a Filter'}
      </UiMenuButton>
      <UiMenuList>
        {filteredOptions.map(({ id, name, value }) => (
          <UiMenuItem
            key={id}
            onClick={() => navigate(value)}
          >
            {name}
          </UiMenuItem>
        ))}
      </UiMenuList>
    </UiMenu>
  )
}
