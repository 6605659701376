import { Box } from '@chakra-ui/react'
import { UiSkeleton, ZSidebar, ZSidebarBanner } from '@postal-io/postal-ui'
import type { CalendarProvider, UserAccount } from 'api'
import { ChronoUnit, MeetingRequestSetting } from 'api'
import { createEvent, fromIcal } from 'components/Profile/schedule'
import { UserCalendarProviders } from 'components/Profile/UserCalendarProviders'
import type { MeetingSettingFormState, MeetingSettingState } from 'components/Profile/userMeetingSettingsData'
import { UserMeetingSettingsEdit } from 'components/Profile/UserMeetingSettingsEdit'
import { PageTitle, useMe } from 'hooks'
import { set } from 'lodash'
import React from 'react'
import { useImmer } from 'use-immer'
import { CenteredBox, Header } from '../Common'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'
const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const MeetingSettings: React.FC = () => {
  const { me, isLoading } = useMe()
  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner title="Meeting Settings" />
        <PageTitle title="Meeting Settings" />
        <Box w="100%">
          {isLoading || !me ? (
            <UiSkeleton
              w="100%"
              minH="400px"
            />
          ) : (
            <MeetingSettingsConfig me={me} />
          )}
        </Box>
      </ZSidebar>
    </CenteredBox>
  )
}

interface MeetingSettingsConfigProps {
  me: UserAccount
}
const MeetingSettingsConfig: React.FC<MeetingSettingsConfigProps> = ({ me }) => {
  const meetingSettings = me.meetingSettings

  const buildState = React.useCallback(() => {
    const newState = {
      meetingRequestDefault: meetingSettings?.meetingRequestDefault || MeetingRequestSetting.No,
      meetingName: meetingSettings?.meetingName || '',
      videoMeetingLink: meetingSettings?.videoMeetingLink || '',
      enabled: meetingSettings?.enabled === false ? false : true,
      dateRange: meetingSettings?.dateRange || 14,
      meetingDurationMins: meetingSettings?.meetingDurationMins || 30,
      schedulingLink: meetingSettings?.schedulingLink || '',
      events: fromIcal(meetingSettings?.availabilityBlockIcal),
      calendar: {
        userId: me.id,
        calendarId: me?.emailAddress || '',
        provider: meetingSettings?.calendar?.provider,
        timeZone: meetingSettings?.calendar?.timeZone || localTimeZone,
        scheduleDayLeadTime: meetingSettings?.calendar?.scheduleDayLeadTime || 0,
        scheduleHourLeadTime: meetingSettings?.calendar?.scheduleHourLeadTime || 0,
        scheduleBuffer: meetingSettings?.calendar?.scheduleBuffer || 0,
        scheduleBufferUnits: meetingSettings?.calendar?.scheduleBufferUnits || ChronoUnit.Minutes,
        busyByDefault: false,
      },
    } as MeetingSettingState
    if (!newState.events.length) newState.events.push(createEvent())
    return { original: newState, current: newState }
  }, [
    me?.emailAddress,
    me.id,
    meetingSettings?.videoMeetingLink,
    meetingSettings?.availabilityBlockIcal,
    meetingSettings?.calendar?.provider,
    meetingSettings?.calendar?.scheduleBuffer,
    meetingSettings?.calendar?.scheduleBufferUnits,
    meetingSettings?.calendar?.scheduleDayLeadTime,
    meetingSettings?.calendar?.scheduleHourLeadTime,
    meetingSettings?.calendar?.timeZone,
    meetingSettings?.dateRange,
    meetingSettings?.enabled,
    meetingSettings?.meetingDurationMins,
    meetingSettings?.meetingName,
    meetingSettings?.meetingRequestDefault,
    meetingSettings?.schedulingLink,
  ])

  // set form state based on inbound me
  const [state, setState] = useImmer<MeetingSettingFormState>(buildState)

  React.useEffect(() => {
    setState(buildState)
  }, [buildState, setState])

  // change provider
  const handleProvider = (provider: CalendarProvider) => {
    setState((draft: any) => {
      set(draft, 'current.calendar.provider', provider)
    })
  }

  return !state.current.calendar?.provider ? (
    <Box>
      <Header
        as="h1"
        fontSize="2xl"
        textAlign="center"
        mb={16}
      >
        Choose Your Scheduling Method
      </Header>
      <UserCalendarProviders
        onSelect={handleProvider}
        boxSize="170px"
      />
    </Box>
  ) : (
    <UserMeetingSettingsEdit
      state={state}
      setState={setState}
    />
  )
}
