import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import { UiDataTable, UiLink, useAlertError, useAlerts } from '@postal-io/postal-ui'
import type { PlaybookInstance } from 'api'
import { PlaybookStatus, SearchPlaybookInstancesDocument, UpdatePlaybookDocument } from 'api'
import { zMultiSelectCellStyles, zMultiSelectStyles } from 'components/Common/ZComponents'
import { useAcl } from 'hooks'
import React, { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { playbookColumns } from './playbooksData'

const PAGE_SIZE = 10

interface ContactPlaybookInstancesV2Props extends UiCardProps {
  contactId: string | undefined
  pageSize?: number
}

export const ContactPlaybookInstances: React.FC<ContactPlaybookInstancesV2Props> = ({
  contactId,
  pageSize = PAGE_SIZE,
}) => {
  const Alert = useAlerts()
  const { hasPermission } = useAcl()

  const navigate = useNavigate()
  const openPlaybook = (playbook: PlaybookInstance) => {
    navigate(`/subscriptions/${playbook.playbookDefinitionId}`)
  }

  //Dont think the filter is working...
  const { data, error } = useGraphqlQuery(SearchPlaybookInstancesDocument, {
    filter: { contactId: { eq: contactId } },
  })

  const updatePlaybook = useGraphqlMutation(UpdatePlaybookDocument)

  const playbookInstances = useMemo(
    () => (data?.searchPlaybookInstances as PlaybookInstance[]) || [],
    [data?.searchPlaybookInstances]
  )

  const ContactPlaybookInstance = useMemo(() => {
    return playbookInstances?.filter((item) => item.contactId === contactId)
  }, [contactId, playbookInstances])

  const handleStatus = async (id: string, status: PlaybookStatus) => {
    try {
      await updatePlaybook.mutateAsync({ id: id, data: { playbookStatus: status } })
      Alert.success('Subscription Status Updated')
    } catch (err) {
      Alert.error(err)
    }
  }

  const normalizeData =
    (useMemo(() => {
      return ContactPlaybookInstance?.map((item) => {
        return {
          id: item.id,
          name: item.name,
          playbookDefinitionId: item.playbookDefinitionId,
          completedSteps: `${item.nextStepToExecute} of ${item.steps.length}`,
          postalDate: item.nextExecutionDate,
          duration: item.steps && Math.max(...item.steps.map((item: any) => item.delay)),
          status: item.playbookStatus,
          actions: [
            {
              title: `Cancel Subscription`,
              onClick: () => handleStatus(item?.id, PlaybookStatus.Cancelled),
              isHidden:
                !hasPermission('playbooks.update') ||
                item.playbookStatus === PlaybookStatus.Cancelled ||
                item.playbookStatus === PlaybookStatus.Failed ||
                item.playbookStatus === PlaybookStatus.Completed,
            },
            {
              title: `Activate Subscription`,
              onClick: () => handleStatus(item?.id, PlaybookStatus.Active),
              isHidden:
                !hasPermission('playbooks.update') ||
                item.playbookStatus === PlaybookStatus.Active ||
                item.playbookStatus === PlaybookStatus.Queued ||
                item.playbookStatus === PlaybookStatus.Failed ||
                item.playbookStatus === PlaybookStatus.Completed,
            },
          ],
        }
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playbookInstances]) as any[]) || []

  useAlertError(error)

  return (
    <>
      <UiDataTable
        mx={-6}
        width="calc(100% + 48px)"
        minHeight="150px"
        variant="list"
        columns={playbookColumns}
        rows={normalizeData}
        rowKey="id"
        pageSize={pageSize}
        onClick={openPlaybook}
        sx={{ ...zMultiSelectStyles, ...zMultiSelectCellStyles, '& thead': { display: 'none' } }}
      />
      {playbookInstances.length > 0 && (
        <Box mt={4}>
          <UiLink
            as={Link}
            to="/subscriptions"
          >
            View All
            <ArrowForwardIcon ml={2} />
          </UiLink>
        </Box>
      )}
    </>
  )
}
