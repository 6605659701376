import { FormControl } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { SelectTypeaheadStylesV2, useAlertError, ZFormLabel } from '@postal-io/postal-ui'
import { RequiredSelectTypeahead } from 'components/AutoComplete/RequiredSelectTypeahead'
import React, { useMemo } from 'react'
import { DataListType, GetDataListDocument } from '../../api'

interface TriggerEditProgramV2Props {
  state: Record<string, any>
  setState: (f: (draft: any) => any) => void
}

export const TriggerEditProgram: React.FC<TriggerEditProgramV2Props> = ({ state, setState }) => {
  const dataListsQuery = useGraphqlQuery(GetDataListDocument, {
    system: state.systemName,
    type: DataListType.Programs,
  })
  useAlertError(dataListsQuery.error)
  const items = useMemo(() => dataListsQuery?.data?.getDataList?.items, [dataListsQuery?.data?.getDataList?.items])

  const handleChange = (e: any) => {
    const { value } = e
    setState((draft) => {
      draft.attribution = draft.attribution || {}
      draft.attribution.marketoProgramId = value
    })
  }

  const options = [
    { label: dataListsQuery.isLoading ? 'Loading....' : `Select a Program`, value: '' },
    ...(items
      ?.map((item) => ({
        label: item.name,
        value: item.id,
      }))
      .filter(Boolean) ?? []),
  ]

  return (
    <>
      <FormControl>
        <ZFormLabel
          htmlFor="marketoProgramId"
          fontSize="md"
        >
          Program
        </ZFormLabel>
        <RequiredSelectTypeahead
          id="marketoProgramId"
          name="marketoProgramId"
          value={options.find((option) => option.value === state.attribution?.marketoProgramId)}
          onChange={handleChange}
          options={options}
          isClearable={false}
          isDisabled={!items?.length}
          {...SelectTypeaheadStylesV2}
        />
      </FormControl>
    </>
  )
}
