import {
  Badge,
  Flex,
  FormControl,
  Grid,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UseGraphqlFilterResponse } from '@postal-io/postal-ui'
import { UiInputDate, ZButton, ZInput, ZSelect, ZText } from '@postal-io/postal-ui'
import type { Tag, User } from 'api'
import { SearchContactListsDocument } from 'api'
import { zAutocompleteStyles, ZAutoCompleteUser, ZFormLabel } from 'components/Common/ZComponents'
import { useAcl } from 'hooks'
import { isEmpty } from 'lodash'
import type { ChangeEvent } from 'react'
import React from 'react'
import { MdArrowDropDown, MdClose, MdFilterList } from 'react-icons/md'
import { AutoCompleteTags } from '../AutoComplete'
import { DEFAULT_ROLES } from '../Users/UsersData'
import { defaultSort } from './ContactsData'

/**
 * Provides a form used to set query param filters.  The results are
 * pushed back to the caller via the onChange callback.  This function
 * is debounced according to the debounce prop.
 *
 * It would be nice to extract some of this logic into re-usable componens
 */

export interface ContactsFilterV2Props
  extends Pick<
    UseGraphqlFilterResponse,
    'filter' | 'setFilter' | 'resetFilter' | 'filterCount' | 'meta' | 'setMeta' | 'resetMeta' | 'setOrderBy'
  > {
  showLists?: boolean
  showOwner?: boolean
  closeOnReset?: boolean
  disclosure?: {
    isOpen?: boolean
    onClose?: () => void
    onToggle?: () => void
  }
}

export const ContactsFilter: React.FC<ContactsFilterV2Props> = ({
  filter,
  filterCount,
  meta,
  resetFilter,
  resetMeta,
  setFilter,
  setMeta,
  setOrderBy,
  showLists,
  showOwner = true,
  disclosure,
}) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const { hasPermission } = useAcl()

  // search for all lists
  const { data: listData } = useGraphqlQuery(SearchContactListsDocument)
  const lists = listData?.searchContactLists

  const handleInput = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = target
    setFilter({ key: name, value: isEmpty(value) ? '' : value })
  }

  const handleReset = () => {
    resetFilter()
    resetMeta()
    setOrderBy(defaultSort)
  }

  return (
    <Popover
      arrowSize={30}
      closeOnBlur={false}
      closeOnEsc
      gutter={16}
      isOpen={disclosure ? disclosure.isOpen : isOpen}
      onClose={disclosure ? disclosure.onClose : onClose}
      placement="left"
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <IconButton
          aria-label="Filter"
          color="gray.500"
          onClick={disclosure ? disclosure.onToggle : onToggle}
          size="sm"
          variant="ghost"
        >
          <>
            <MdFilterList size={20} />
            {filterCount > 0 && (
              <Badge
                backgroundColor="green.500"
                color="white"
                mb={6}
                ml={8}
                position="absolute"
                px={2}
                py={0.5}
                rounded="full"
                variant="solid"
              >
                {filterCount}
              </Badge>
            )}
          </>
        </IconButton>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          borderRadius={20}
          pt={4}
          px={10}
          w={600}
        >
          <PopoverArrow />
          <PopoverBody>
            <PopoverHeader
              borderBottom="none"
              color="grey.900"
              display="flex"
              fontSize={21}
              fontWeight="normal"
              justifyContent="center"
            >
              Filter
            </PopoverHeader>
            <PopoverCloseButton
              colorScheme="grey.300"
              onClick={disclosure ? disclosure.onClose : onClose}
              right={5}
              top={8}
            />
            <Grid
              templateColumns="repeat(2, 1fr)"
              columnGap={8}
              rowGap={7}
              mt={4}
            >
              <FormControl id="firstName">
                <ZFormLabel
                  mb={1}
                  color="grey.700"
                  fontWeight="normal"
                >
                  First Name
                </ZFormLabel>
                <ZInput
                  type="search"
                  name="firstName"
                  placeholder="First Name"
                  onChange={handleInput}
                  value={filter.firstName || ''}
                />
              </FormControl>
              <FormControl id="lastName">
                <ZFormLabel
                  mb={1}
                  color="grey.700"
                  fontWeight="normal"
                >
                  Last Name
                </ZFormLabel>
                <ZInput
                  type="search"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={handleInput}
                  value={filter.lastName || ''}
                />
              </FormControl>
              <FormControl id="emailAddress">
                <ZFormLabel
                  mb={1}
                  color="grey.700"
                  fontWeight="normal"
                >
                  Email Address
                </ZFormLabel>
                <ZInput
                  type="search"
                  name="emailAddress"
                  placeholder="Email Address"
                  onChange={handleInput}
                  value={filter.emailAddress || ''}
                />
              </FormControl>
              <FormControl id="addresses_city">
                <ZFormLabel
                  mb={1}
                  color="grey.700"
                  fontWeight="normal"
                >
                  City
                </ZFormLabel>
                <ZInput
                  type="search"
                  name="city"
                  placeholder="City"
                  onChange={handleInput}
                  value={filter.city || ''}
                />
              </FormControl>
              <FormControl id="addresses_state">
                <ZFormLabel
                  mb={1}
                  color="grey.700"
                  fontWeight="normal"
                >
                  State
                </ZFormLabel>
                <ZInput
                  type="search"
                  name="state"
                  placeholder="State"
                  onChange={handleInput}
                  value={filter.state || ''}
                />
              </FormControl>
              <FormControl id="addresses_postalCode">
                <ZFormLabel
                  mb={1}
                  color="grey.700"
                  fontWeight="normal"
                >
                  Postal Code
                </ZFormLabel>
                <ZInput
                  type="search"
                  name="postalCode"
                  placeholder="Postal Code"
                  onChange={handleInput}
                  value={filter.postalCode || ''}
                />
              </FormControl>
              <FormControl id="companyName">
                <ZFormLabel
                  mb={1}
                  color="grey.700"
                  fontWeight="normal"
                >
                  Company
                </ZFormLabel>
                <ZInput
                  type="search"
                  name="companyName"
                  placeholder="Company"
                  onChange={handleInput}
                  value={filter.companyName || ''}
                />
              </FormControl>
              <FormControl id="title">
                <ZFormLabel
                  mb={1}
                  color="grey.700"
                  fontWeight="normal"
                >
                  Title
                </ZFormLabel>
                <ZInput
                  type="search"
                  name="title"
                  placeholder="Title"
                  onChange={handleInput}
                  value={filter.title || ''}
                />
              </FormControl>
              <FormControl
                id="created"
                sx={{
                  '& input.chakra-input': { height: '40px', fontSize: 'sm' },
                  '& .chakra-input__left-element': { 'height': '100%', '& svg': { w: '16px', h: '16px' } },
                  '& .chakra-input__right-element': { height: '100%' },
                }}
              >
                <ZFormLabel
                  mb={1}
                  color="grey.700"
                  fontWeight="normal"
                >
                  Date Created
                </ZFormLabel>
                <UiInputDate
                  name="created"
                  onChange={handleInput}
                  value={filter.created || ''}
                  isRange
                />
              </FormControl>
              <FormControl
                id="lastSend"
                sx={{
                  '& input.chakra-input': { height: '40px', fontSize: 'sm' },
                  '& .chakra-input__left-element': { 'height': '100%', '& svg': { w: '16px', h: '16px' } },
                  '& .chakra-input__right-element': { height: '100%' },
                }}
              >
                <ZFormLabel
                  mb={1}
                  color="grey.700"
                  fontWeight="normal"
                >
                  Last Send
                </ZFormLabel>
                <UiInputDate
                  name="lastSend"
                  onChange={handleInput}
                  value={filter.lastSend || ''}
                  isRange
                />
              </FormControl>

              {showOwner && hasPermission('users.query') && (
                <FormControl id="ownerId">
                  <ZFormLabel
                    mb={1}
                    color="grey.700"
                    fontWeight="normal"
                  >
                    Contact Owner
                  </ZFormLabel>
                  <ZAutoCompleteUser
                    inputId="contact-owner"
                    isClearable
                    value={meta?.ownerId || ''}
                    onChange={(user: User | null) => {
                      setFilter({ key: 'ownerId', value: user?.id })
                      setMeta({ key: 'ownerId', value: user })
                    }}
                    roles={DEFAULT_ROLES}
                  />
                </FormControl>
              )}

              {showLists && (
                <FormControl id="listId">
                  <ZFormLabel
                    mb={1}
                    color="grey.700"
                    fontWeight="normal"
                  >
                    Saved List
                  </ZFormLabel>
                  <ZSelect
                    icon={<MdArrowDropDown />}
                    onChange={handleInput}
                    name="lists"
                    value={filter.lists}
                  >
                    <option value="">Select List</option>
                    {lists?.map((list) => {
                      return (
                        <option
                          key={list.id}
                          value={list.id}
                        >
                          {list.name}
                        </option>
                      )
                    })}
                  </ZSelect>
                </FormControl>
              )}

              <FormControl id="tags">
                <ZFormLabel
                  mb={1}
                  color="grey.700"
                  fontWeight="normal"
                >
                  Tags
                </ZFormLabel>
                <AutoCompleteTags
                  styles={zAutocompleteStyles}
                  inputId="tags"
                  value={meta.tags || []}
                  formatOptionLabel={(t: Tag) => <ZText>{t.name}</ZText>}
                  onChange={(items: any) => {
                    setFilter({ key: 'tags', value: items.map((t: Tag) => t.name) })
                    setMeta({ key: 'tags', value: items })
                  }}
                />
              </FormControl>
            </Grid>
            <Flex
              alignItems="center"
              h={20}
              justifyContent="center"
            >
              {filterCount > 0 && (
                <ZButton
                  color="gray.400"
                  leftIcon={<Icon as={MdClose} />}
                  onClick={handleReset}
                  size="md"
                  variant="link"
                  _hover={{ textDecoration: 'none' }}
                >
                  Clear Filters
                </ZButton>
              )}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
