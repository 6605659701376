import { Flex, Grid, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiLoading,
  useAlertError,
  useAlerts,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import { DataObjectType, DeleteIntegrationDocument, MeDocument, Role } from 'api'
import type { ExternalProvider } from 'components/Integrations/data'
import { IntegrationHistory } from 'components/Integrations/IntegrationHistory'
import { IntegrationMappings } from 'components/Integrations/IntegrationMappings'
import { IntegrationSettings } from 'components/Integrations/IntegrationSettings'
import { useProvider } from 'components/Integrations/useProvider'
import { dequal } from 'dequal'
import { AnalyticsEvent, useAcl, useAnalyticsEvent } from 'hooks'
import { cloneDeep, sortBy } from 'lodash'
import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import type { IntegrationSync } from '../../api'
import {
  SearchIntegrationSyncDocument,
  SetupDataSourceDocument,
  Status,
  UpdateIntegrationSyncDocument,
  UpdateIntegrationSyncStatusDocument,
} from '../../api'
import { IntegrationLayout } from '../Profile'
import { ExternalSystem } from './data'
import type { IntegrationFormProps } from './helpers'
import { isActiveIntegration, isUserIntegration } from './helpers'
import { IntegrationAbm } from './IntegrationAbm'

interface IntegrationSyncViewProps {
  integrationSyncs: IntegrationSync[]
}

export const IntegrationSyncView: React.FC<IntegrationSyncViewProps> = ({ integrationSyncs }) => {
  const Alert = useAlerts()
  const { hasPermission, hasRole, hasSubscription } = useAcl()
  const { systemName } = useParams() as any
  const { provider, loading } = useProvider(systemName)
  const navigate = useNavigate()
  const saveButtonRef = createRef<HTMLButtonElement>()

  const showABMSettings =
    hasRole(Role.Admin) && hasSubscription('POSTAL_ABM_ACCESS') && provider?.system === ExternalSystem.Salesforce

  const updateIntegrationStatus = useGraphqlMutation(UpdateIntegrationSyncStatusDocument)
  const updateIntegration = useGraphqlMutation(UpdateIntegrationSyncDocument)

  const [state, setState] = useImmer<IntegrationFormProps>({ current: [], original: [] })

  useEffect(() => {
    setState({ current: cloneDeep(integrationSyncs), original: cloneDeep(integrationSyncs) })
  }, [integrationSyncs, setState])

  const isDirty = useMemo(() => !dequal(state.current, state.original), [state])

  const handleReset = () => {
    setState({ current: cloneDeep(state.original), original: cloneDeep(state.original) })
  }

  const accountIntegration = useMemo(
    () => state?.current?.find((v) => v.objectType === DataObjectType.Account),
    [state]
  )
  const userIntegration = useMemo(() => state?.current?.find(isUserIntegration), [state])
  const nonUserIntegrations = useMemo(() => state?.current?.filter((i) => !isUserIntegration(i)), [state])
  // used to limit what we need to update in the promises on submit
  const dirtyStatuses = useMemo(() => {
    return state?.current?.filter((copy) => {
      const orig = integrationSyncs.find((i) => i.id === copy.id)
      return orig?.status !== copy.status
    })
  }, [integrationSyncs, state])

  // used to limit what we need to update in the promises on submit
  const dirtyConfigs = useMemo(() => {
    return state?.current?.filter((copy) => {
      const orig = state?.original?.find((i) => i.id === copy.id)
      return !dequal(
        { createEnabled: orig?.createEnabled, createCustomFields: orig?.createCustomFields },
        { createEnabled: copy.createEnabled, createCustomFields: copy.createCustomFields }
      )
    })
  }, [state])

  const handleCreateEnabled = (objectType: string | string[]) => {
    setState(
      (draft) =>
        void (draft.current = !objectType
          ? draft.current.map((i: IntegrationSync) => {
              return { ...i, createEnabled: false }
            })
          : draft.current.map((i: IntegrationSync) => {
              return { ...i, createEnabled: i.objectType === objectType }
            }))
    )
  }

  const handleToggle = (integration?: IntegrationSync, checked?: boolean) => {
    if (!integration) return
    const status = checked ? Status.Active : Status.Disabled
    setState(
      (draft) =>
        void (draft.current = isUserIntegration(integration)
          ? draft.current.map((i: IntegrationSync) => {
              return { ...i, status, createEnabled: checked ? i.createEnabled : i.createEnabled }
            })
          : draft.current.map((i: IntegrationSync) => {
              return i.id === integration.id
                ? { ...i, status, createEnabled: checked ? i.createEnabled : i.createEnabled }
                : i
            }))
    )
  }

  const addCustomField = () => {
    setState((draft) => {
      const item = draft.current.find((i) => i.createEnabled)
      if (item) {
        item.createCustomFields = item.createCustomFields || []
        item.createCustomFields.push({ field: '', value: '' })
      }
    })
  }

  const removeCustomField = (idx: number) => {
    setState((draft) => {
      const item = draft.current.find((i) => i.createEnabled)
      if (item) item.createCustomFields?.splice(idx, 1)
    })
    saveButtonRef?.current?.focus()
  }

  const handleCustomField = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const { name, value } = e.target
    setState((draft) => {
      const item = draft.current.find((i) => i.createEnabled)
      const customField = item?.createCustomFields?.[idx] as any
      if (customField) customField[name] = value
    })
  }

  const handleSubmit = async () => {
    if (!isDirty) return
    try {
      const statuses = dirtyStatuses.map((item) => {
        return updateIntegrationStatus.mutateAsync({
          id: item.id,
          status: item.status,
        })
      })
      const configs = dirtyConfigs.map((item) => {
        return updateIntegration.mutateAsync({
          id: item.id,
          data: { createEnabled: item.createEnabled, createCustomFields: item.createCustomFields },
        })
      })
      await Promise.allSettled([...statuses, ...configs])
      if (isActiveIntegration(userIntegration)) {
        Alert.success('Integration Updated. Syncing will begin in 5-10 minutes.')
      } else {
        Alert.warning('This integration has been disabled')
      }
    } catch (err) {
      Alert.error(err)
    }
  }

  useAnalyticsEvent({ event: AnalyticsEvent.IntegrationSelected, data: { system: provider?.system } })

  const canDelete = hasPermission('integrations.delete')
  const deleteIntegrationDisclosure = useDisclosure()

  const deleteIntegration = useGraphqlMutation(DeleteIntegrationDocument)

  useEffect(() => {
    if (!loading && !provider) navigate('/integrations')
  }, [loading, navigate, provider])

  const handleDeleteIntegration = async () => {
    try {
      if (!provider?.system) {
        throw new Error('Integration not available for deletion')
      }
      await deleteIntegration.mutateAsync({ systemName: provider.system })
      navigate('/integrations')
      Alert.success('Integration removed')
    } catch (err) {
      Alert.error(err)
    } finally {
      deleteIntegrationDisclosure.onClose()
    }
  }

  const isFetching = updateIntegration.isLoading

  const handleBack = () => navigate('/integrations')

  if (!provider) return null

  return (
    <>
      <IntegrationLayout
        isDirty={isDirty}
        isLoading={updateIntegration.isLoading}
        onBack={handleBack}
        onReset={handleReset}
        onSave={handleSubmit}
        canDelete={canDelete}
        title={provider.name}
        onRemove={deleteIntegrationDisclosure.onOpen}
      >
        <form>
          <Flex justifyContent="center">
            <Grid
              templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
              gap={8}
              flex="1800px 0 1"
            >
              <IntegrationSettings
                provider={provider}
                userIntegration={userIntegration}
                nonUserIntegrations={nonUserIntegrations}
                isFetching={isFetching}
                handleToggle={handleToggle}
                gridRow="span 2"
              />
              <IntegrationMappings
                provider={provider}
                state={state}
                userIntegration={userIntegration}
                isFetching={isFetching}
                handleCreateEnabled={handleCreateEnabled}
                addCustomField={addCustomField}
                handleCustomField={handleCustomField}
                removeCustomField={removeCustomField}
              />
              {showABMSettings && (
                <IntegrationAbm
                  provider={provider}
                  accountIntegration={accountIntegration}
                />
              )}
              <IntegrationHistory
                system={provider?.system}
                gridColumn="span 2"
              />
            </Grid>
          </Flex>
        </form>
      </IntegrationLayout>

      {deleteIntegrationDisclosure.isOpen && (
        <ZModal
          size="lg"
          isOpen={deleteIntegrationDisclosure.isOpen}
          onClose={deleteIntegrationDisclosure.onClose}
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Confirm Remove Integration</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <ZText textAlign="center">
                Are you sure you want to <strong>remove</strong> this integration?
              </ZText>
            </ZModalBody>
            <ZModalButtons
              confirmText="Remove"
              confirmProps={{
                colorScheme: 'atomicRed',
              }}
              onConfirm={handleDeleteIntegration}
              onClose={deleteIntegrationDisclosure.onClose}
            />
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}

interface IntegrationSyncLoaderProps {
  provider: ExternalProvider
}
export const IntegrationSyncLoader: React.FC<IntegrationSyncLoaderProps> = ({ provider }) => {
  const Alert = useAlerts()
  const [refetchInt, setRefetchInt] = useState<number>()
  const navigate = useNavigate()

  const meQuery = useGraphqlQuery(MeDocument)
  const authTypes = useMemo(() => meQuery.data?.me?.authTypes || [], [meQuery.data?.me?.authTypes])

  const isAuthenticated = useMemo(() => {
    const authType = provider?.integration?.authType
    return !!authType && authTypes.includes(authType)
  }, [authTypes, provider])

  const searchIntegrationSync = useGraphqlQuery(
    SearchIntegrationSyncDocument,
    { filter: { system: { eq: provider.system } } },
    { refetchInterval: refetchInt, enabled: !!provider.system }
  )
  useAlertError(searchIntegrationSync.error)

  const integrationSyncs = useMemo(
    () => sortBy(searchIntegrationSync.data?.searchIntegrationSync, 'objectType') || [],
    [searchIntegrationSync.data?.searchIntegrationSync]
  )

  const hasIntegration = useMemo(() => {
    return integrationSyncs.filter((p) => p.system === provider.system).length > 0
  }, [integrationSyncs, provider.system])

  const setupDataSource = useGraphqlMutation(SetupDataSourceDocument)

  // run this only once
  const hasRun = useRef(false)
  const runSetup = useCallback(async () => {
    if (hasRun.current) return
    hasRun.current = true
    setRefetchInt(1000)
    try {
      await setupDataSource.mutateAsync({ systemName: provider.system })
    } catch (err) {
      navigate('/integrations')
      Alert.error(err)
    }
  }, [Alert, navigate, provider.system, setupDataSource])

  const isLoading = useMemo(
    () => searchIntegrationSync.isLoading || meQuery.isLoading,
    [meQuery.isLoading, searchIntegrationSync.isLoading]
  )

  // run the setup mutation if we don't have any integrations yet
  useEffect(() => {
    // if we don't have access or we are loading bail
    if (!provider || isLoading) return

    // once we have integration syncs, we can make sure refetch is disabled
    if (hasIntegration) setRefetchInt(undefined)

    // if we are authenticated and we don't have syncs, then we need to run
    // the setup
    if (isAuthenticated && !hasIntegration) runSetup()
  }, [isLoading, hasIntegration, isAuthenticated, provider, runSetup])

  // if we are loading data lets show the loader
  if (isLoading) return <UiLoading />

  // if we don't have access at all redirect the user back
  if (!provider) return <Navigate to="/integrations" />

  // if we have syncs setup, lets bail early and just show the page
  // someone already authorized this and ran setupDataSource
  if (hasIntegration) return <IntegrationSyncView integrationSyncs={integrationSyncs} />

  // if we don't have syncs and don't have auth, redirect out of here
  // this user would need to authenticate with the provider first
  if (!hasIntegration && !isAuthenticated) return <Navigate to="/integrations" />

  // if we are here we've run setupDataSource and we are refetching waiting
  // for syncs to show up
  return <UiLoading />
}
