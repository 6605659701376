import { Stack } from '@chakra-ui/react'
import { ZCard, ZSidebar, ZSidebarBanner } from '@postal-io/postal-ui'
import { Colors } from 'components/Branding/Colors'
import { CustomLogo } from 'components/Branding/CustomLogo'
import { SignatureCopyright } from 'components/Branding/SignatureCopyright'
import { CenteredBox } from 'components/Common'
import { PageTitle } from 'hooks'
import React from 'react'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

export const Branding: React.FC = () => {
  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner title="Branding" />
        <PageTitle title="Branding" />
        <Stack spacing={8}>
          <ZCard variant="form">
            <CustomLogo p={8} />
          </ZCard>
          <ZCard variant="form">
            <Colors p={8} />
          </ZCard>
          <ZCard variant="form">
            <SignatureCopyright p={8} />
          </ZCard>
        </Stack>
      </ZSidebar>
    </CenteredBox>
  )
}
