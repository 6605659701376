import { Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import { LexendFontWeight, UiMoney, ZCard, ZCardBody, ZCardHeader, ZCardTitle } from '@postal-io/postal-ui'
import type { BasicMetrics } from 'api'
import { Granularity, PlaybookAnalyticsDocument } from 'api'
import { sub } from 'date-fns'
import { useSession } from 'hooks'
import { calculateCpt } from 'lib'
import React, { useMemo } from 'react'

interface PlaybookDefinitionStatProps extends ZCardProps {
  playbookId?: string | undefined
}

const basicMetrics = {
  queued: 0,
  failed: 0,
  sent: 0,
  started: 0,
  delivered: 0,
  bounces: 0,
  touches: 0,
  costInCents: 0,
} as BasicMetrics

export const PlaybookDefinitionStats: React.FC<PlaybookDefinitionStatProps> = ({ playbookId, ...rest }) => {
  const startDate = useMemo(() => sub(new Date(), { months: 12 }), [])

  const {
    session: { teamId },
  } = useSession()

  const { data: totalData, isLoading: totalLoading } = useGraphqlQuery(
    PlaybookAnalyticsDocument,
    {
      playbookDefinitionId: playbookId ?? '',
      granularity: Granularity.All,
      startDate,
      teamId,
    },
    { enabled: !!playbookId }
  )

  const [total = basicMetrics] = totalData?.playbookAnalytics || []

  const isLoading = totalLoading

  return (
    <>
      <ZCard
        variant="dash"
        isLoading={isLoading}
        borderWidth="1px"
        borderColor="atomicGray.200"
        {...rest}
      >
        <ZCardHeader>
          <ZCardTitle>Stats</ZCardTitle>
        </ZCardHeader>
        <ZCardBody>
          <StatGroup
            textAlign="center"
            fontFamily="lexend"
          >
            <Stat>
              <StatLabel
                color="atomicGray.500"
                fontSize="body-sm"
                fontWeight={LexendFontWeight.Bold}
              >
                Started
              </StatLabel>
              <StatNumber
                fontWeight={LexendFontWeight.Regular}
                fontSize={{ base: 'xl', lg: '2xl' }}
              >
                {total.started}
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel
                color="atomicGray.500"
                fontSize="body-sm"
                fontWeight={LexendFontWeight.Bold}
              >
                Delivered
              </StatLabel>
              <StatNumber
                fontWeight={LexendFontWeight.Regular}
                fontSize={{ base: 'xl', lg: '2xl' }}
              >
                {total.delivered}
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel
                color="atomicGray.500"
                fontSize="body-sm"
                fontWeight={LexendFontWeight.Bold}
                whiteSpace="nowrap"
              >
                Cost Per Touch
              </StatLabel>
              <StatNumber
                fontWeight={LexendFontWeight.Regular}
                fontSize={{ base: 'xl', lg: '2xl' }}
              >
                <UiMoney cents={calculateCpt(total)} />
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel
                color="atomicGray.500"
                fontSize="body-sm"
                fontWeight={LexendFontWeight.Bold}
                whiteSpace="nowrap"
              >
                Total Cost
              </StatLabel>
              <StatNumber
                fontWeight={LexendFontWeight.Regular}
                fontSize={{ base: 'xl', lg: '2xl' }}
              >
                <UiMoney cents={total?.costInCents} />
              </StatNumber>
            </Stat>
          </StatGroup>
        </ZCardBody>
      </ZCard>
    </>
  )
}
