import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { uniqBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import type { User } from '../api'
import { SearchUsersDocument } from '../api'

// The BE does not support a proper omni search/'OR' query on users yet.
// This is a TEMPORARY substitute that will make an educated guess as to what the user wants
// and perform one or more queries, merge the results and return them in a single-query-like object.
// This does not support infinite queries, but it's totes cool bro, because it's temporary.

export function useUserOmniSearch(searchString: string, baseQuery: Record<string, any>) {
  /**
   * Search Strings
   */

  const firstNameSearch = useMemo(() => searchString.split(' ')[0].toLowerCase(), [searchString])
  const lastNameSearch = useMemo(() => (searchString.split(' ')[1] || searchString).toLowerCase(), [searchString])
  const emailSearch = useMemo(() => searchString.toLowerCase(), [searchString])

  /**
   * Params
   */

  const getParams = (filterParams: Record<string, any>) => ({
    ...baseQuery,
    filter: { ...baseQuery.filter, ...filterParams },
  })

  const firstNameParams = getParams({ firstName: { contains: firstNameSearch } })
  const lastNameParams = getParams({ lastName: { contains: lastNameSearch } })
  const fullNameParams = getParams({
    firstName: { contains: firstNameSearch },
    lastName: { contains: lastNameSearch },
  })
  const userNameParams = getParams({ userName: { contains: emailSearch } })

  /**
   * Query Selection
   */

  const firstNameQueryEnabled = useMemo(
    () => searchString.split(' ').length < 2 && !searchString.includes('@'),
    [searchString]
  )
  const lastNameQueryEnabled = useMemo(
    () => !!searchString && searchString.split(' ').length < 2 && !searchString.includes('@'),
    [searchString]
  )
  const fullNameQueryEnabled = useMemo(
    () => !!searchString && searchString.split(' ').length >= 2 && !searchString.includes('@'),
    [searchString]
  )
  const userNameQueryEnabled = useMemo(() => !!searchString && searchString.split(' ').length < 2, [searchString])

  /**
   * Queries
   */

  const firstNameQuery = useGraphqlQuery(SearchUsersDocument, firstNameParams, { enabled: firstNameQueryEnabled })
  const lastNameQuery = useGraphqlQuery(SearchUsersDocument, lastNameParams, { enabled: lastNameQueryEnabled })
  const fullNameQuery = useGraphqlQuery(SearchUsersDocument, fullNameParams, { enabled: fullNameQueryEnabled })
  const userNameQuery = useGraphqlQuery(SearchUsersDocument, userNameParams, { enabled: userNameQueryEnabled })

  /**
   * Merged values
   */

  const isLoading =
    (firstNameQueryEnabled && firstNameQuery.isLoading) ||
    (lastNameQueryEnabled && lastNameQuery.isLoading) ||
    (fullNameQueryEnabled && fullNameQuery.isLoading) ||
    (userNameQueryEnabled && userNameQuery.isLoading)

  const error = useMemo(
    () => firstNameQuery.error || lastNameQuery.error || fullNameQuery.error || userNameQuery.error,
    [firstNameQuery.error, lastNameQuery.error, fullNameQuery.error, userNameQuery.error]
  )

  const sortByRelevance = useCallback(
    (a: User, b: User) => {
      // order by: first name starting match, last name starting match, email starting match
      if (a.firstName?.toLowerCase().startsWith(firstNameSearch)) return -1
      if (b.firstName?.toLowerCase().startsWith(firstNameSearch)) return 1
      if (a.lastName?.toLowerCase().startsWith(lastNameSearch)) return -1
      if (b.lastName?.toLowerCase().startsWith(lastNameSearch)) return 1
      if (a.emailAddress?.toLowerCase().startsWith(emailSearch)) return -1
      if (b.emailAddress?.toLowerCase().startsWith(emailSearch)) return 1
      return 1
    },
    [firstNameSearch, lastNameSearch, emailSearch]
  )

  const results = useMemo(
    () =>
      uniqBy<User>(
        [
          ...(firstNameQuery?.data?.searchUsers?.users || []),
          ...(lastNameQuery?.data?.searchUsers?.users || []),
          ...(fullNameQuery?.data?.searchUsers?.users || []),
          ...(userNameQuery?.data?.searchUsers?.users || []),
        ],
        'id'
      ).sort(sortByRelevance),
    [
      firstNameQuery?.data?.searchUsers?.users,
      lastNameQuery?.data?.searchUsers?.users,
      fullNameQuery?.data?.searchUsers?.users,
      userNameQuery?.data?.searchUsers?.users,
      sortByRelevance,
    ]
  )

  return {
    results,
    isLoading,
    error,
  }
}
