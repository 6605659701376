import { Box, Flex, FormControl, Grid, InputGroup } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import {
  UiButtonScrollTop,
  UiCard,
  UiSidePanelContainer,
  useAlertError,
  useInfiniteScroll,
  ZInput,
} from '@postal-io/postal-ui'
import { SidePanelHeader } from 'components/Common/SidePanelHeader'
import { OldMarketplaceCardProduct } from 'components/Marketplace/MarketplaceCardProduct'
import { MARKETPLACE_CARD_MIN_WIDTH } from 'components/Postals/data'
import { StorageKeys } from 'lib'
import React, { useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import type { Currency, MarketplaceProduct, MarketplaceProductFilterInput } from '../../api'
import { SearchMarketplaceProductsDocument } from '../../api'
import { usePostalFilters } from '../../hooks'
import { SidePanelFilter } from '../Common'
import { NoPostal } from '../Postal'
import { CATEGORIES_ITEMS_ONLY, ITEM_CATEGORY_EXCLUSIONS } from '../Postals/data'
import { LIMIT } from './data'

interface CollectionItemSelectMarketplaceProps {
  onSelect: (item: MarketplaceProduct) => void
  onBulkSelect?: (item: MarketplaceProduct) => void
  isBulkSelected?: (item: MarketplaceProduct) => boolean
  approvedCurrencies: Currency[]
}
export const CollectionItemSelectMarketplace: React.FC<CollectionItemSelectMarketplaceProps> = ({
  onSelect,
  onBulkSelect,
  isBulkSelected,
  approvedCurrencies,
}) => {
  // if we have a restricted currency, filter currency from the sidepanel
  const excludeFilters = useMemo(() => {
    return approvedCurrencies.length > 2 ? ['currency'] : undefined
  }, [approvedCurrencies.length])

  // filters will override these
  const defaultVariables = useMemo(
    () => ({
      currency: { in: approvedCurrencies },
      category: { in: CATEGORIES_ITEMS_ONLY },
    }),
    [approvedCurrencies]
  )

  const filter = usePostalFilters<MarketplaceProductFilterInput>({
    defaultVariables,
    persistKey: StorageKeys.CollectionMarketplaceItemsFilter,
  })

  // debounce filter to accomodate typing input
  const [debouncedFilter] = useDebounce(filter.graphqlFilter, 400)

  const searchProducts = useGraphqlInfiniteQuery(SearchMarketplaceProductsDocument, {
    filter: debouncedFilter,
    limit: LIMIT,
  })

  const products = useMemo(
    () => searchProducts.mergedData?.searchMarketplaceProducts || [],
    [searchProducts.mergedData?.searchMarketplaceProducts]
  )

  useAlertError(searchProducts.error)

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchProducts.hasNextPage,
    loadMore: searchProducts.fetchNextPage,
    loading: searchProducts.isFetching,
  })

  return (
    <>
      <Box px={8}>
        <UiSidePanelContainer>
          <Box>
            <FormControl
              id="search"
              mb={5}
            >
              <SidePanelHeader
                canClear={!!filter.filters?.q}
                onClear={() => filter.updateFilter('q', '')}
                title="Search"
              />
              <InputGroup>
                <ZInput
                  name="search"
                  placeholder="Search"
                  value={filter.filters?.q ?? ''}
                  onChange={(e: any) => filter.updateFilter('q', e.target.value)}
                />
              </InputGroup>
            </FormControl>
            <SidePanelFilter
              filters={filter.filters}
              onUpdate={filter.updateFilter}
              filterType="Marketplace"
              approvedCurrencies={approvedCurrencies}
              excludeCategories={ITEM_CATEGORY_EXCLUSIONS}
              excludeFilters={excludeFilters}
            />
          </Box>
          <Flex
            direction="column"
            w="100%"
            ref={topRef}
            style={{ scrollMargin: '20px' }}
          >
            <Grid
              templateColumns={`repeat(auto-fill, minmax(${MARKETPLACE_CARD_MIN_WIDTH}px, 1fr))`}
              columnGap={8}
              rowGap={4}
            >
              {products.map((item: any) => (
                <OldMarketplaceCardProduct
                  key={item.id}
                  product={item}
                  buttonText="Select this Item"
                  onSelect={onSelect}
                  onBulkSelect={onBulkSelect}
                  isBulkSelected={isBulkSelected?.(item)}
                />
              ))}

              {searchProducts.isFetching && products.length < 1 && (
                <UiCard
                  isLoading
                  h={MARKETPLACE_CARD_MIN_WIDTH}
                  w={MARKETPLACE_CARD_MIN_WIDTH}
                />
              )}
              {!searchProducts.isFetching && products.length < 1 && <NoPostal />}
            </Grid>

            {products.length >= LIMIT && (
              <Box
                ref={bottomRef}
                mb={8}
                position="relative"
              >
                <UiButtonScrollTop
                  onClick={scrollTop}
                  position="absolute"
                  top="0px"
                  right="0px"
                  isLoading={searchProducts.isFetching}
                  aria-label="scroll-up"
                />
              </Box>
            )}
          </Flex>
        </UiSidePanelContainer>
      </Box>
    </>
  )
}
