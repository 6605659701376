import { NotAllowedIcon } from '@chakra-ui/icons'
import { Icon, Skeleton, useDisclosure } from '@chakra-ui/react'
import { graphqlFetch, useGraphqlFetch, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps, ZCardProps } from '@postal-io/postal-ui'
import {
  FontWeight,
  UiCard,
  useAlerts,
  ZButton,
  ZCard,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import type { Connection } from 'api'
import { GetConnectionsDocument, GetRecipesDocument, GetWorkatoJwtDocument } from 'api'
import { isLocalEnvironment } from 'lib'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import type { ExternalProvider } from './data'
import { ExternalSystem } from './data'
import { isAuthorizedConnection } from './helpers'

interface IntegrationCardWorkatoProps extends ZCardProps {
  provider: ExternalProvider
  isLoading: boolean
  connections: Connection[]
  onComplete: () => void
}

export const IntegrationCardWorkato: React.FC<IntegrationCardWorkatoProps> = ({
  provider,
  isLoading,
  onComplete,
  connections,
  ...rest
}) => {
  const postalConnection = useMemo(() => {
    return connections.find((c) => c.name?.toLowerCase().startsWith('postal'))
  }, [connections])

  /**
   * Find the workato connection by matching the connection name starts with
   * the connectionPrefix hardcoded in the external provider
   */
  const integrationConnection = useMemo(() => {
    return connections.find((c) => {
      if (!provider.workato || !c.name) return false
      return c.name.startsWith(provider.workato.connectionPrefix)
    })
  }, [connections, provider.workato])

  /**
   * The main recipe tells us if this integration is running or not
   *
   * NOTE: This will likely not be the case moving forward as trigger recipes
   *       can still run without the main recipe. We'll just keep it for Dynamics
   *       since I'm not sure if that's the case for that integration.
   *
   *       -xoxo, Nate
   *
   */
  const getRecipes = useGraphqlQuery(GetRecipesDocument, undefined, { staleTime: 0 })
  const mainRecipe = useMemo(() => {
    return getRecipes.data?.getRecipes?.find((recipe) => {
      if (!provider.workato) return false
      // Dynamics we key off of the user recipe
      if (provider.system === ExternalSystem.MicrosoftDynamics)
        return recipe.name.startsWith(provider.workato.recipePrefix + 'user_')
      return false
    })
  }, [getRecipes.data?.getRecipes, provider.system, provider.workato])

  if (!postalConnection || !integrationConnection) return null

  const loading = isLoading || getRecipes.isLoading
  const isConnected = isAuthorizedConnection(postalConnection) && isAuthorizedConnection(integrationConnection)

  // Dynamics is the only Workato system that needs a recipe running to be active
  const isActive = provider.system === ExternalSystem.MicrosoftDynamics ? !!mainRecipe?.running : true
  const isDisabled = !provider?.workato?.enabled
  const opacity = !isDisabled ? 1 : 0.8

  return isDisabled || isConnected || loading ? (
    <ZCard
      isLoading={isLoading}
      opacity={opacity}
      variant="form"
      alignItems="center"
      {...rest}
    >
      <Icon
        as={provider.icon}
        fontSize="125px"
        display="block"
        mx="auto"
      />
      <ZButton
        m={8}
        variant="outline"
        alignItems="center"
        colorScheme={isActive ? 'atomicBlue' : 'atomicGray'}
        color={isActive ? 'atomicBlue.400' : 'atomicGray.400'}
        borderColor={isActive ? 'atomicBlue.400' : 'atomicGray.400'}
        fontWeight={500}
        leftIcon={isActive ? <MdCheckCircle size="16px" /> : <NotAllowedIcon color="inherit" />}
        size="md"
        isDisabled={isDisabled || loading}
        isLoading={isLoading || loading}
        onClick={onComplete}
      >
        <ZText
          pt={0.5}
          fontWeight={FontWeight.Bold}
          color="inherit"
        >
          {isDisabled ? 'Coming Soon' : isActive ? 'Enabled' : 'Disabled'}
        </ZText>
      </ZButton>
    </ZCard>
  ) : (
    <WorkatoConnectionsCard
      postalConnection={postalConnection}
      integrationConnection={integrationConnection}
      isLoading={isLoading}
      provider={provider}
      onComplete={onComplete}
      {...rest}
    />
  )
}

interface WorkatoConnectionsCardProps extends UiCardProps {
  provider: ExternalProvider
  isLoading: boolean
  postalConnection: Connection
  integrationConnection: Connection
  onComplete: () => void
}

const WorkatoConnectionsCard: React.FC<WorkatoConnectionsCardProps> = ({
  provider,
  isLoading,
  postalConnection,
  integrationConnection,
  onComplete,
  ...rest
}) => {
  const disclosure = useDisclosure()
  const getConnections = useGraphqlFetch(GetConnectionsDocument)

  /**
   * Periodic refetch to update the cache that passes the connections in here.  The Postal connection
   * isn't giving us callbacks on connection, so we need to use this to figure out which connection
   * to present.  Only fire when the modal is open.
   */
  useGraphqlQuery(GetConnectionsDocument, undefined, { refetchInterval: 1000, enabled: disclosure.isOpen })

  /**
   * If the postal connection isn't setup yet, then set that up first.  Otherwise
   * deal with the integration connection.
   */
  const currentConnection = useMemo(() => {
    return isAuthorizedConnection(postalConnection) ? integrationConnection : postalConnection
  }, [integrationConnection, postalConnection])

  /**
   * Re-run the connections query when the status is changed to see if we are done
   * with all of them.  If so, call the onComplete callback to take them to the
   * appropriate page
   */
  const handleOnConnect = useCallback(async () => {
    const data = await getConnections(undefined, { staleTime: 0 })
    const isComplete = (data.getConnections ?? [])
      .filter((c) => [postalConnection.id, integrationConnection.id].includes(c.id))
      .every(isAuthorizedConnection)
    if (isComplete) onComplete()
  }, [getConnections, integrationConnection.id, onComplete, postalConnection.id])

  return (
    <>
      <ZCard
        isLoading={isLoading}
        variant="form"
        alignItems="center"
        {...rest}
      >
        <Icon
          as={provider.icon}
          fontSize="125px"
          display="block"
          mx="auto"
        />
        <ZButton
          m={8}
          variant="outline"
          onClick={disclosure.onOpen}
          isDisabled={false}
          size="md"
          colorScheme="atomicGray"
          color="atomicGray.400"
          borderColor="atomicGray.400"
          isLoading={false}
          display="flex"
        >
          Connect to {provider.name}
        </ZButton>
      </ZCard>
      {disclosure.isOpen && (
        <ZModal
          size="5xl"
          scrollBehavior="inside"
          {...disclosure}
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>{`Connect to ${
              currentConnection.id === postalConnection.id ? 'Postal’s Integration Partner' : provider.name
            }`}</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody
              w="100%"
              maxW="1200px"
              mx="auto"
              pt={0}
              pb={8}
              px={8}
            >
              <WorkatoWidget
                key={currentConnection.id}
                connection={currentConnection}
                onConnect={handleOnConnect}
              />
            </ZModalBody>
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}

interface WorkatoWidgetProps {
  connection: Connection
  onConnect?: () => void
  onDisconnect?: () => void
}

export const WorkatoWidget: React.FC<WorkatoWidgetProps> = ({ connection, onConnect, onDisconnect }) => {
  const Alert = useAlerts()
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [height, setHeight] = useState(400)

  const src = token
    ? `https://www.workato.com/direct_link/embedded/connections/${connection.id}?workato_dl_token=${token}`
    : ''

  /**
   * We are running this query manually because these tokens only last for a few seconds
   * and need to be sure we get a new one each time this page is mounted.  Can't use
   * react-query cache here.
   */
  useEffect(() => {
    graphqlFetch(GetWorkatoJwtDocument, { test: isLocalEnvironment })
      .then(({ getWorkatoJWT }) => setToken(getWorkatoJWT))
      .catch((err) => Alert.error(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Workato iframe sends us updates via postMessage API
   */
  useEffect(() => {
    const receiveMessage = (event: any) => {
      if (typeof event.data === 'object') return
      if (event.origin === 'https://app.workato.com') {
        const data = JSON.parse(event.data)
        switch (data.type) {
          case 'heightChange':
            setHeight(data.payload.height)
            break
          case 'connectionStatusChange':
            if (data.payload.error) return Alert.error(data.payload.error)
            if (data.payload.connected === true) onConnect?.()
            if (data.payload.connected === false) onDisconnect?.()
            break
          case 'error':
            console.error(data)
            Alert.error(data.payload.message)
        }
      }
    }
    window.addEventListener('message', receiveMessage)
    return () => {
      window.removeEventListener('message', receiveMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading && <Skeleton minH="400px" />}
      <UiCard>
        <iframe
          id="workato-connection-widget"
          src={src}
          style={{
            height,
            width: '100%',
          }}
          title="Workato Connection Widget"
          onLoad={() => setLoading(false)}
        />
      </UiCard>
    </>
  )
}
