import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import type { ReactElement } from 'react'
import { IconContext } from 'react-icons'

interface ExtIconButtonProps extends BoxProps {
  icon: ReactElement
}
export const ExtIconButton: React.FC<ExtIconButtonProps> = ({ icon, ...rest }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="30px"
      height="30px"
      bg="atomicGray.50"
      borderRadius="50%"
      _hover={{ bg: 'atomicGray.100', cursor: 'pointer' }}
      {...rest}
    >
      <IconContext.Provider value={{ size: '16px', color: '#8492A6' }}>{icon}</IconContext.Provider>
    </Box>
  )
}
