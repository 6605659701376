import { Box, SimpleGrid, Stack, useClipboard, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import {
  UiFormControl,
  UiIconOkta,
  useAlerts,
  ZButton,
  ZCard,
  ZCardHeader,
  ZFormLabel,
  ZInput,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import { ZAlert, ZInfoTooltip } from 'components/Common/ZComponents'
import { useAcl } from 'hooks'
import React, { useCallback, useEffect, useMemo } from 'react'
import { MdOutlineContentCopy } from 'react-icons/md'
import { useImmer } from 'use-immer'
import type { Account } from '../../api'
import { ResetOktaDocument, SetupOktaDocument } from '../../api'

const OKTA_TOOLTIP = 'Enable your users to login to Postal via Okta'

interface FormState {
  clientId: string
  clientSecret: string
  baseUrl: string
}
export interface SecurityOktaProps extends UiCardProps {
  account?: Account
}
export const SecurityOkta: React.FC<SecurityOktaProps> = ({ account, ...rest }) => {
  const Alert = useAlerts()
  const { hasPermission } = useAcl()
  const canDelete = hasPermission('security.delete')
  const canUpdate = hasPermission('security.update')

  const hasOkta = useMemo(
    () => !!account?.oktaIntegration?.baseUrl && !!account?.oktaIntegration?.clientId,
    [account?.oktaIntegration?.baseUrl, account?.oktaIntegration?.clientId]
  )

  const [form, setForm] = useImmer<FormState>({ clientId: '', clientSecret: '', baseUrl: '' })

  const setupOkta = useGraphqlMutation(SetupOktaDocument)
  const resetOkta = useGraphqlMutation(ResetOktaDocument)

  const editOkta = useDisclosure()
  const confirmReset = useDisclosure()

  const reset = useCallback(() => {
    setForm((draft) => {
      draft.clientId = account?.oktaIntegration?.clientId || ''
      draft.baseUrl = account?.oktaIntegration?.baseUrl || ''
      draft.clientSecret = ''
    })
  }, [account?.oktaIntegration?.baseUrl, account?.oktaIntegration?.clientId, setForm])

  useEffect(() => {
    reset()
  }, [reset])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as { name: keyof FormState; value: string }
    setForm((draft) => {
      draft[name] = value
    })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await setupOkta.mutateAsync({ data: form })
      Alert.success('Okta is setup')
      editOkta.onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleReset = async () => {
    try {
      await resetOkta.mutateAsync({})
      Alert.warning('Okta is disabled')
      confirmReset.onClose()
      editOkta.onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const { onCopy, setValue: setValueToCopy } = useClipboard('')

  const handleCopy = (e: React.MouseEvent) => {
    e.preventDefault()
    setValueToCopy(account?.id as string)
    setTimeout(onCopy)
    Alert.success('External ID copied to clipboard')
  }

  const isLoading = setupOkta.isLoading || resetOkta.isLoading

  return (
    <>
      <ZCard
        isLoading={!account}
        variant="form"
        {...rest}
      >
        <ZCardHeader
          justifyContent="flex-start"
          alignItems="center"
          fontWeight="normal"
          p={8}
          pb={0}
          gap={2}
        >
          Auth Integrations
          <ZInfoTooltip label={OKTA_TOOLTIP} />
        </ZCardHeader>

        <SimpleGrid
          minChildWidth="280px"
          gap={8}
          p={8}
        >
          <ZCard
            isLoading={!account}
            maxW="280px"
            variant="form"
            {...rest}
          >
            <Box w="100%">
              <UiIconOkta
                mt={0}
                fontSize="125px"
                display="block"
                mx="auto"
              />
            </Box>
            <Box
              p={8}
              pt={0}
            >
              <ZButton
                w="100%"
                onClick={() => canUpdate && editOkta.onOpen()}
                size="md"
                colorScheme={hasOkta ? 'atomicBlue' : 'atomicGray'}
                display="flex"
                isDisabled={!canUpdate}
              >
                {hasOkta ? 'Edit Settings' : `Connect to Okta`}
              </ZButton>
            </Box>
          </ZCard>
        </SimpleGrid>
      </ZCard>
      {editOkta.isOpen && (
        <ZModal
          size="lg"
          isOpen={editOkta.isOpen}
          onClose={editOkta.onClose}
        >
          <ZModalOverlay />
          <ZModalContent>
            <form
              id="editOkta"
              onSubmit={handleSubmit}
            >
              <ZModalHeader>Edit Okta Settings</ZModalHeader>
              <ZModalCloseButton />
              <ZModalBody>
                <Stack spacing={8}>
                  <ZAlert
                    status="info"
                    hideClose
                    {...rest}
                  >
                    <ZLink
                      href=""
                      onClick={handleCopy}
                      color="atomicBlue.400"
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      Copy External ID
                      <MdOutlineContentCopy size="16px" />
                    </ZLink>
                  </ZAlert>
                  <UiFormControl
                    isRequired={!hasOkta}
                    id="clientId"
                  >
                    <ZFormLabel fontSize="md">Okta Client ID</ZFormLabel>
                    <ZInput
                      name="clientId"
                      value={form.clientId}
                      onChange={handleChange}
                      placeholder="Okta Client ID"
                    />
                  </UiFormControl>
                  <UiFormControl
                    isRequired={!hasOkta}
                    id="clientSecret"
                  >
                    <ZFormLabel fontSize="md">Okta Client Secret</ZFormLabel>
                    <ZInput
                      name="clientSecret"
                      value={form.clientSecret}
                      onChange={handleChange}
                      placeholder={hasOkta ? `****************` : `Okta Client Secret`}
                    />
                  </UiFormControl>
                  <UiFormControl
                    id="baseUrl"
                    isRequired={!hasOkta}
                  >
                    <ZFormLabel fontSize="md">Okta Domain</ZFormLabel>
                    <ZInput
                      name="baseUrl"
                      value={form.baseUrl}
                      onChange={handleChange}
                      placeholder="xxxxx.okta.com"
                    />
                  </UiFormControl>
                </Stack>
              </ZModalBody>

              <ZModalButtons gap={4}>
                <ZButton
                  type="submit"
                  isDisabled={isLoading}
                  form="editOkta"
                  colorScheme="atomicBlue"
                  minW="100px"
                >
                  Save
                </ZButton>
                {canDelete && hasOkta && (
                  <ZButton
                    isDisabled={isLoading}
                    colorScheme="atomicGray"
                    onClick={confirmReset.onOpen}
                    minW="100px"
                  >
                    Reset
                  </ZButton>
                )}
                <ZButton
                  colorScheme="atomicGray"
                  variant="ghost"
                  onClick={editOkta.onClose}
                  isDisabled={setupOkta.isLoading}
                  minW="100px"
                >
                  Cancel
                </ZButton>
              </ZModalButtons>
            </form>
          </ZModalContent>
        </ZModal>
      )}
      {confirmReset.isOpen && (
        <ZModal
          size="sm"
          isOpen={confirmReset.isOpen}
          onClose={confirmReset.onClose}
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Reset Okta Integration</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <ZText>Are you sure you want to disable your Okta integration?</ZText>
            </ZModalBody>
            <ZModalButtons
              onConfirm={handleReset}
              isConfirmLoading={resetOkta.isLoading}
              isConfirmDisabled={resetOkta.isLoading}
              confirmText="Reset Okta"
              confirmProps={{
                colorScheme: 'atomicRed',
              }}
              onClose={confirmReset.onClose}
            />
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}
