import { createId } from '@paralleldrive/cuid2'
import { addSeconds, differenceInSeconds, format, isValid, parse } from 'date-fns'
/* @ts-ignore */
import ical from 'ical.js'

const TIME_FORMAT_1 = 'HH:mm:ss'
const TIME_FORMAT_2 = 'HH:mm'

export enum DayOfWeek {
  'MO' = 'MO',
  'TU' = 'TU',
  'WE' = 'WE',
  'TH' = 'TH',
  'FR' = 'FR',
  'SA' = 'SA',
  'SU' = 'SU',
}

export enum Frequency {
  'SECONDLY' = 'SECONDLY',
  'MINUTELY' = 'MINUTELY',
  'HOURLY' = 'HOURLY',
  'DAILY' = 'DAILY',
  'WEEKLY' = 'WEEKLY',
  'MONTHLY' = 'MONTHLY',
  'YEARLY' = 'YEARLY',
}

export interface Event {
  id: string
  byDayOfWeek: DayOfWeek[]
  startTime: string
  endTime: string
}

const dateToTime = (date = new Date()) => format(date, TIME_FORMAT_1)

const timeToDate = (time: string) => {
  let date = parse(time, TIME_FORMAT_1, new Date())
  if (isValid(date)) return date
  date = parse(time, TIME_FORMAT_2, new Date())
  return isValid(date) ? date : new Date()
}

export const createEvent = () => ({ id: createId(), startTime: '', endTime: '', byDayOfWeek: [] })

export const fromIcal = (cal?: string) => {
  if (!cal) return []

  const data = ical.parse(cal)
  const component = new ical.Component(data)
  const vevents = component.getAllSubcomponents('vevent')
  const events: Event[] = []

  vevents.forEach((vevent: any) => {
    const event = new ical.Event(vevent)
    const startDate = event.startDate.toJSDate()
    const rule = event.component.getFirstPropertyValue('rrule')
    events.push({
      id: createId(),
      startTime: dateToTime(startDate),
      endTime: dateToTime(addSeconds(startDate, event.duration.toSeconds())),
      byDayOfWeek: rule?.parts?.BYDAY || [],
    })
  })

  return events
}

export const toIcal = (events: Event[], tzid: string) => {
  if (!events.length) return ''

  const cal = new ical.Component('vcalendar')
  events.forEach((e) => {
    const vevent = new ical.Component('vevent')
    const event = new ical.Event(vevent)
    const startTime = timeToDate(e.startTime)
    const endTime = timeToDate(e.endTime)
    event.duration = ical.Duration.fromSeconds(differenceInSeconds(endTime, startTime))
    const startDate = ical.Time.fromJSDate(startTime)
    startDate.zone = ical.Timezone.fromData({ tzid })
    event.startDate = startDate
    if (e.byDayOfWeek.length) {
      const rrule = ical.Recur.fromData({ byday: e.byDayOfWeek, freq: Frequency.WEEKLY })
      vevent.addPropertyWithValue('rrule', rrule)
    }
    cal.addSubcomponent(vevent)
  })

  return cal.toString()
}
