import { DeleteIcon } from '@chakra-ui/icons'
import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, FormControl, IconButton, Text, useDisclosure } from '@chakra-ui/react'
import { UiConfirm, ZButton, ZInput, ZSelect } from '@postal-io/postal-ui'
import type { ContactInput, ContactUpdateInput, PhoneMapInput } from 'api'
import { PhoneType } from 'api'
import { ZFormLabel } from 'components/Common/ZComponents'
import type { Updater } from 'use-immer'

interface ContactEditPhoneBlockV2Props extends BoxProps {
  phone: PhoneMapInput
  idx: number
  setForm: Updater<any>
  form: ContactUpdateInput | ContactInput
}

export const ContactEditPhoneBlock: React.FC<ContactEditPhoneBlockV2Props> = ({
  phone,
  idx,
  form,
  setForm,
  ...rest
}) => {
  const removePhone = useDisclosure()

  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, idx: number) => {
    const { name, value } = target
    setForm((draft: any) => void (draft.phones[idx][name] = value))
  }

  const handleRemovePhone = (idx: number) => {
    const phoneList = form.phones?.filter((_item, i) => i !== idx)
    setForm((draft: Record<string, any>) => void (draft.phones = phoneList))
    removePhone.onClose()
  }

  return (
    <>
      <Box
        data-testid={`contact-edit-phone-${idx}`}
        {...rest}
      >
        <Flex
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <FormControl
            mb={0}
            mr={4}
            id={`phoneNumber-${idx}`}
            isRequired={idx === 0}
          >
            <ZFormLabel fontWeight="normal">Phone Number</ZFormLabel>
            <ZInput
              value={phone.phoneNumber || ''}
              name="phoneNumber"
              onChange={(e: any) => handleInput(e, idx)}
              data-private
            />
          </FormControl>

          <FormControl
            mb={0}
            mr={4}
            id="type"
          >
            <ZFormLabel fontWeight="normal">Phone Type</ZFormLabel>
            <ZSelect
              value={phone.type}
              name="type"
              onChange={(e) => handleInput(e, idx)}
            >
              <option value={PhoneType.Work}>Work</option>
              <option value={PhoneType.Cell}>Cell</option>
              <option value={PhoneType.Home}>Home</option>
              <option value={PhoneType.Other}>Other</option>
            </ZSelect>
            <ZButton
              type="submit"
              hidden
            />
          </FormControl>

          <IconButton
            variant="outline"
            icon={<DeleteIcon />}
            aria-label="Delete"
            onClick={removePhone.onOpen}
            data-testid={`contact-edit-phone-remove-${idx}`}
          />
        </Flex>
      </Box>
      <UiConfirm
        title="Remove Phone"
        isOpen={removePhone.isOpen}
        onConfirm={() => handleRemovePhone(idx)}
        onClose={removePhone.onClose}
        buttonColor="red"
        buttonText="Remove"
      >
        <Text>
          Are you sure you want to <strong>Remove</strong> this phone number?
        </Text>
      </UiConfirm>
    </>
  )
}
