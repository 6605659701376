import { Box, Flex, Grid, useDisclosure } from '@chakra-ui/react'
import { useGraphqlFetch, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButtonScrollTop,
  UiPagination,
  UiSidePanelContainer,
  UiTooltip,
  useAlertError,
  useAlerts,
  ZButton,
  ZProductCard,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, MarketplaceProduct, SearchableProduct } from 'api'
import {
  MarketplaceSearchDocument,
  MarketplaceSort,
  Role,
  SearchApprovedPostalsDocument,
  SearchMarketplaceProductsDocument,
} from 'api'
import { useMaxCollectionItems } from 'components/Collections'
import { BulkSelectNavbar } from 'components/Common'
import { SidePanelFilterMarketplaceV2 } from 'components/Common/SidePanelFilterMarketplaceV2'
import { SubnavFilters } from 'components/Common/SubnavFilters'
import { AnalyticsEvent, useAcl, useAnalyticsEvent, useAnalyticsSend, useMe } from 'hooks'
import { useBulkSelect } from 'hooks/useBulkSelect'
import { useNavbarOverride } from 'hooks/useNavbarOverride'
import { useSearchableProductFavoriteStatus } from 'hooks/usePostalFavoriteStatus'
import { cloneDeep, omit, some } from 'lodash'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { MdAddCircleOutline, MdOutlineEdit } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useKey } from 'react-use'
import { NoPostal } from '../Postal'
import { ConciergeRequestCard, ProductRequestCard } from '../Postal/PostalCard'
import { MARKETPLACE_CARD_MIN_WIDTH, MARKETPLACE_CATEGORY_EXCLUSIONS } from '../Postals/data'
import { DesignTemplates } from '../Postals/DesignTemplates'
import { MarketplaceProductRequest } from '../Postals/MarketplaceProductRequest'
import { MARKETPLACE_ITEM_TYPES } from './data'
import { MarketplaceBulkEdit } from './MarketplaceBulkEdit'
import { MarketplaceCardSearchableProduct } from './MarketplaceCardSearchableProduct'
import type { MarketplaceState } from './useMarketplace'
import { MarketplaceContext, MarketplaceView } from './useMarketplace'

const PAGE_SIZE = 50

const PRODUCT_REQUEST_CARD_LIMIT = 5

const MARKETING_RESULTS_TEXT = 'over 10,000 Marketplace items'

export type MarketplaceSelectedItem = MarketplaceProduct & { isSelected?: boolean }

export const MarketplaceViewProducts: React.FC<{ isApproved?: boolean }> = ({ isApproved }) => {
  const navigate = useNavigate()
  const Alert = useAlerts()
  const sendAnalytics = useAnalyticsSend()

  const { hasPermission, aclCheck, hasFeature, hasRole } = useAcl()

  const canCreate = hasPermission('postals.create')
  const canCreateCollection = canCreate || (hasFeature('userCreatedCollections') && hasRole(Role.User))
  const hasMarketplace = aclCheck({ module: 'postals.marketplace' })

  // for semantics
  const canBulkSelect = canCreate || canCreateCollection

  const designTemplates = useDisclosure()
  const bulkApproveDisclosure = useDisclosure()
  const productRequestDisclosure = useDisclosure()

  const { approvedCurrencies } = useMe()

  /**
   * Bulk select
   */
  const { handleBulkSelect, isBulkSelected, handleCancelBulkSelect, bulkSelected, setBulkSelected } =
    useBulkSelect<SearchableProduct>()

  const [bulkSelectedFullItems, setBulkSelectedFullItems] = useState<MarketplaceProduct[] | ApprovedPostal[]>([])

  const getMarketplaceProducts = useGraphqlFetch(SearchMarketplaceProductsDocument)
  const getApprovedPostals = useGraphqlFetch(SearchApprovedPostalsDocument)

  // query and store the full bulk selected product/postal
  const getBulkSelectedItems = async () => {
    const variables = { filter: { id: { in: bulkSelected.map((i) => i.itemId) } } }
    let fullBulkSelected

    try {
      if (isApproved) fullBulkSelected = (await getApprovedPostals(variables))?.searchApprovedPostals
      else fullBulkSelected = (await getMarketplaceProducts(variables))?.searchMarketplaceProducts
    } catch (err) {
      Alert.error(err)
    }

    return fullBulkSelected
  }

  // reset bulk select on approved item toggle
  useEffect(() => {
    setBulkSelected([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApproved])

  const multiCurrencySelection = useMemo(
    () => some(bulkSelected, (item) => item.currency !== bulkSelected[0].currency),
    [bulkSelected]
  )

  const handleBulkApprove = async () => {
    const fullBulkSelected = await getBulkSelectedItems()
    setBulkSelectedFullItems(fullBulkSelected ?? [])
    bulkApproveDisclosure.onOpen()
  }

  const handleCreateCollection = async () => {
    const fullBulkSelected = await getBulkSelectedItems()
    navigate('/collections/create', {
      state: { items: fullBulkSelected, returnTo: 'Marketplace' },
    })
  }

  useKey('Escape', handleCancelBulkSelect)

  // CONCIERGE

  const goToConcierge = () => navigate('/paperplane')

  /**
   * Filters & Context
   */
  const { state, setNumResultsText, marketplaceFilters, scrollTopRef } = useContext(MarketplaceContext)
  const { filters, graphqlFilter, updateFilter, clearFilters } = marketplaceFilters

  // don't show status (taken from Postals but here too)
  const subnavFilters = useMemo(() => omit(cloneDeep(filters), ['status']), [filters])

  // const filter = useMemo(() => pick(graphqlFilter, MARKETPLACE_PRODUCT_FILTER_INPUT_KEYS), [graphqlFilter])

  /**
   * Pagination
   */
  const [page, setPageRaw] = useState(1)
  const setPage = useCallback(
    (page: number) => {
      setPageRaw(page)
      scrollTopRef?.current.scrollIntoView()
    },
    [scrollTopRef, setPageRaw]
  )

  /**
   * Query & Data
   */
  const searchProducts = useGraphqlQuery(MarketplaceSearchDocument, {
    query: {
      limit: PAGE_SIZE,
      skip: (page - 1) * PAGE_SIZE,
      // sort by best match to query string, or by newest first if no query string
      sort: MarketplaceSort.RecommendedDesc,
      ...graphqlFilter,
    },
  })
  useAlertError(searchProducts.error)

  const summary = useMemo(
    () => searchProducts.data?.marketplaceSearch.summary,
    [searchProducts.data?.marketplaceSearch.summary]
  )

  const products = useMemo(
    () => searchProducts.data?.marketplaceSearch.results || [],
    [searchProducts.data?.marketplaceSearch.results]
  )
  const { productsWithFavoriteStatus } = useSearchableProductFavoriteStatus(products)

  const availableFilters = useMemo(
    () => searchProducts.data?.marketplaceSearch.filters,
    [searchProducts.data?.marketplaceSearch.filters]
  )

  useEffect(() => {
    if (searchProducts.isLoading) return
    setNumResultsText?.(getNumResultsText({ summary, products, state, page }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProducts.data])

  const handleSelectProduct = (product: SearchableProduct) => {
    if (MARKETPLACE_ITEM_TYPES.includes(product.itemType)) {
      sendAnalytics({
        event: AnalyticsEvent.MarketplaceViewItemClicked,
        data: { marketplaceProductId: product.itemId, itemName: product.title },
      })
      navigate(`/items/marketplace/${product.itemId}`, { state: { returnTo: 'All Items' } })
    } else {
      sendAnalytics({
        event: AnalyticsEvent.MarketplaceViewMyItemClicked,
        data: { itemName: product.title },
      })
      navigate(`/items/postals/${product.itemId}`, { state: { returnTo: 'Approved Items' } })
    }
  }

  // ANALYTICS

  useAnalyticsEvent({ event: AnalyticsEvent.MarketplaceAllItemsPageViewed })
  useAnalyticsEvent({ event: AnalyticsEvent.MarketplaceFilterSelected, data: { filter: graphqlFilter } })

  const showConciergeCard = !searchProducts.isLoading && canCreate && hasMarketplace
  const showProductRequestCard = showConciergeCard && products.length < PRODUCT_REQUEST_CARD_LIMIT

  const handleClickProductRequest = () => {
    window.open('https://www.postal.com/recommend-a-product', '_blank')
  }

  /**
   * Collection create validation
   */
  const MAX_COLLECTION_ITEMS = useMaxCollectionItems()
  const collectionCreateError = useMemo(() => {
    if (multiCurrencySelection) return 'Multiple currencies selected. Only one currency is allowed per collection.'
    if (bulkSelected.length > MAX_COLLECTION_ITEMS)
      return `Cannot create a Collection with over ${MAX_COLLECTION_ITEMS} items.`
  }, [MAX_COLLECTION_ITEMS, bulkSelected.length, multiCurrencySelection])

  /**
   * Bulk Select Navbar
   */
  useNavbarOverride(
    !!bulkSelected.length && (
      <BulkSelectNavbar
        isEverythingSelected={bulkSelected.length === products.length}
        selectedItems={bulkSelected}
        onSelect={handleBulkSelect}
        onSelectAll={() => setBulkSelected(products)}
        onDeselectAll={handleCancelBulkSelect}
      >
        <>
          {canCreate && (
            <ZButton
              colorScheme="atomicBlue"
              onClick={handleBulkApprove}
              isDisabled={bulkSelected.length < 1}
              autoFocus
              leftIcon={<MdOutlineEdit fontSize="18px" />}
            >
              Bulk {isApproved ? 'Edit' : 'Approve'}
            </ZButton>
          )}
          {canCreateCollection && (
            <UiTooltip
              label={collectionCreateError}
              shouldWrapChildren
            >
              <ZButton
                variant="naked"
                color="white"
                _hover={{ color: '#FFFC' }}
                px={0}
                onClick={handleCreateCollection}
                isDisabled={!!collectionCreateError}
                leftIcon={<MdAddCircleOutline fontSize="18px" />}
              >
                Create Collection
              </ZButton>
            </UiTooltip>
          )}
        </>
      </BulkSelectNavbar>
    ),
    [bulkSelected, setBulkSelected, products, multiCurrencySelection, collectionCreateError]
  )

  return (
    <>
      <SubnavFilters
        data-testid="Marketplace_SubnavFilters"
        filters={subnavFilters}
        onUpdate={updateFilter}
        onClear={clearFilters}
        display={!Object.keys(subnavFilters).length ? 'none' : undefined}
      />
      <UiSidePanelContainer
        gridGap={16}
        mt={4}
      >
        <SidePanelFilterMarketplaceV2
          filters={filters}
          onUpdate={updateFilter}
          marketplaceV2AvailableFilters={availableFilters}
          isLoading={marketplaceFilters.isLoading}
          filterType={isApproved ? 'ApprovedPostal' : 'Marketplace'}
          approvedCurrencies={approvedCurrencies}
          excludeCategories={MARKETPLACE_CATEGORY_EXCLUSIONS}
          showDraft={isApproved}
        />
        <Flex
          direction="column"
          w="100%"
          style={{ scrollMargin: '20px' }}
        >
          <Grid
            templateColumns={`repeat(auto-fill, minmax(${MARKETPLACE_CARD_MIN_WIDTH}px, 1fr))`}
            gap={4}
          >
            {!searchProducts.isFetching && products.length < 1 && !showConciergeCard && <NoPostal />}
            {showProductRequestCard && <ProductRequestCard handleClick={handleClickProductRequest} />}
            {showConciergeCard && <ConciergeRequestCard handleClick={goToConcierge} />}
            {productsWithFavoriteStatus?.map((item) => (
              <MarketplaceCardSearchableProduct
                key={item.id}
                product={item}
                favoriteItemId={item.favoriteItemId}
                isBulkSelected={isBulkSelected(item)}
                onSelect={handleSelectProduct}
                onBulkSelect={canBulkSelect ? handleBulkSelect : undefined}
                truncateLength={33}
              />
            ))}

            {searchProducts.isFetching && products.length < 1 && (
              <ZProductCard
                isLoading
                h={MARKETPLACE_CARD_MIN_WIDTH}
                w={MARKETPLACE_CARD_MIN_WIDTH}
                startColor="atomicGray.50"
                endColor="atomicGray.200"
              />
            )}
          </Grid>
          <UiPagination
            pages={summary?.totalPages ?? 1}
            page={summary?.currentPage ?? 1}
            onFirst={() => setPage(1)}
            onPrevious={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            onLast={() => setPage(summary?.totalPages ?? 1)}
          />
          {scrollTopRef.current && (
            <Box
              mb={8}
              position="relative"
            >
              <UiButtonScrollTop
                onClick={() => scrollTopRef.current.scrollIntoView({ behavior: 'smooth' })}
                position="absolute"
                top="0px"
                right="0px"
                isLoading={searchProducts.isFetching}
                aria-label="scroll-up"
              />
            </Box>
          )}
        </Flex>
      </UiSidePanelContainer>
      {bulkApproveDisclosure.isOpen && (
        <MarketplaceBulkEdit
          type={isApproved ? 'Update' : 'Approve'}
          items={bulkSelectedFullItems}
          isOpen={bulkApproveDisclosure.isOpen}
          onClose={bulkApproveDisclosure.onClose}
          onSuccess={handleCancelBulkSelect}
        />
      )}
      <DesignTemplates
        isOpen={designTemplates.isOpen}
        onClose={designTemplates.onClose}
      />
      <MarketplaceProductRequest {...productRequestDisclosure} />
    </>
  )
}

// Generate 'xx to xx of xxx items' text
const getNumResultsText = ({
  summary,
  products,
  page,
  state,
}: {
  summary: any
  page: number
  products: SearchableProduct[]
  state: MarketplaceState
}) => {
  // xxx items
  const results = summary?.totalRecords ?? 0
  const numResults = Intl.NumberFormat().format(results)
  const resultsDescription = numResults === '1' ? '1 result' : `${numResults} results`
  const resultsText = state.numFilters > 0 ? `${resultsDescription} based on your filters` : `${numResults} total items`
  const showMarketplaceMarketingText = state.view !== MarketplaceView.Postals && !state.numFilters
  const numResultsText = showMarketplaceMarketingText ? MARKETING_RESULTS_TEXT : resultsText

  // xx to xx of
  const firstItemNumber = (page - 1) * PAGE_SIZE + 1
  const resultRangeText = results > 0 ? `${firstItemNumber} - ${firstItemNumber + products.length - 1} of ` : ''

  // xx to xx of xxx items
  return `${resultRangeText}${numResultsText}`
}
