import { sessionStorage } from '@postal-io/postal-ui'
import { identity, pickBy } from 'lodash'
import { createContext, useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useImmerReducer } from 'use-immer'

export enum MarketplaceView {
  Home = 'HOME',
  Products = 'PRODUCTS',
  Postals = 'POSTALS',
}

interface MarketplaceContextProps {
  state: MarketplaceState
  scrollTopRef?: any
  setNumFilters?: (numFilters: number) => void
  setNumResults?: (numResults: number | null) => void
  setNumResultsText?: (numResultsText: string | null) => void
  marketplaceFilters: any
}

export const MARKETPLACE_INITIAL_STATE: any = {
  view: null,
  numFilters: 0,
  numResults: null,
  numResultsText: null,
}

export const MarketplaceContext = createContext<MarketplaceContextProps>({
  state: MARKETPLACE_INITIAL_STATE,
  marketplaceFilters: {},
})

export type MarketplaceState = {
  view: MarketplaceView | null
  numFilters: number
  numResults: number | null
  numResultsText: string | null
}

export enum UseMarketplaceAction {
  SetView = 'SET_VIEW',
  SetNumFilters = 'SET_NUM_FILTERS',
  SetNumResults = 'SET_NUM_RESULTS',
  SetNumResultsText = 'SET_NUM_RESULTS_TEXT',
  ResetState = 'RESET_STATE',
}

interface PayloadProps {
  type: UseMarketplaceAction
  value?: any
}

const reducer = (draft: MarketplaceState, { type, ...payload }: PayloadProps) => {
  const { value } = payload

  switch (type) {
    case UseMarketplaceAction.SetView:
      draft.view = value
      break

    case UseMarketplaceAction.SetNumFilters:
      draft.numFilters = value
      break

    case UseMarketplaceAction.SetNumResults:
      draft.numResults = value
      draft.numResultsText =
        value === null
          ? 'Over 10,000 Marketplace Items'
          : value === 0
          ? '0 results'
          : `${Intl.NumberFormat().format(value)}+ results based on your filters`
      break

    // used by marketplace v2 to set the actual text elsewhere
    case UseMarketplaceAction.SetNumResultsText:
      draft.numResultsText = value
      break

    case UseMarketplaceAction.ResetState:
      draft = value
      break

    default:
      return draft
  }
}

interface UseMarketplaceProps {
  /**
   * set the initial state of the Marketplace pages
   **/
  initialState?: any
  /**
   * persist state to local storage
   */
  persistKey?: string
}
export const useMarketplace = ({ initialState, persistKey }: UseMarketplaceProps) => {
  const setInitialState = useCallback(
    (initialState = {}): MarketplaceState => {
      let storedState = {}
      try {
        if (persistKey) storedState = JSON.parse(sessionStorage.getItem(persistKey) || '{}')
      } catch (e) {}
      return pickBy(Object.assign({}, initialState, storedState), identity) as MarketplaceState
    },
    [persistKey]
  )

  const [state, dispatch] = useImmerReducer<MarketplaceState, PayloadProps, typeof setInitialState>(
    reducer,
    initialState,
    setInitialState
  )

  // every time we change the filters, write to localStorage
  useEffect(() => {
    if (persistKey) sessionStorage.setItem(persistKey, JSON.stringify(state))
  }, [state, persistKey])

  const pathname = useLocation().pathname

  const view = useMemo(() => {
    return pathname.includes('/items/marketplace')
      ? MarketplaceView.Products
      : pathname.includes('/items/postals')
      ? MarketplaceView.Postals
      : pathname.includes('/items')
      ? MarketplaceView.Home
      : null
  }, [pathname])

  const setNumFilters = useCallback(
    (numFilters: number) => {
      dispatch({ type: UseMarketplaceAction.SetNumFilters, value: numFilters })
    },
    [dispatch]
  )

  const setNumResults = useCallback(
    (numResults: number | null) => {
      dispatch({ type: UseMarketplaceAction.SetNumResults, value: numResults })
    },
    [dispatch]
  )

  const setNumResultsText = useCallback(
    (numResultsText: string | null) => {
      dispatch({ type: UseMarketplaceAction.SetNumResultsText, value: numResultsText })
    },
    [dispatch]
  )

  const resetState = useCallback(() => {
    dispatch({ type: UseMarketplaceAction.ResetState, value: initialState })
  }, [dispatch, initialState])

  const memoState = useMemo(() => {
    return { ...state, view }
  }, [state, view])

  return { state: memoState, setNumFilters, setNumResults, setNumResultsText, resetState }
}
