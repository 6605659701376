import { Grid } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { ZCard, ZCardHeader, ZHeading } from '@postal-io/postal-ui'
import { MeDocument } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { useCallback } from 'react'
import { HUMAN_FONTS } from './Fonts'
import { HandwritingBlock } from './HandwritingBlock'

export const Handwriting = () => {
  // get user's data
  const { data, isLoading } = useGraphqlQuery(MeDocument)
  const user = data?.me

  const isSelected = useCallback((font: any) => !!(user?.handwritingName === font.code), [user?.handwritingName])

  return (
    <ZCard
      isLoading={isLoading}
      variant="form"
    >
      <ZCardHeader
        p={8}
        justifyContent="flex-start"
        alignItems="center"
        gap={2}
      >
        <ZHeading size="h6">Handwriting Style</ZHeading>
        <ZInfoTooltip label="Choose a default handwriting style for your direct mail campaigns" />
      </ZCardHeader>
      <Grid
        templateColumns="repeat(auto-fill, minmax(270px, auto))"
        autoRows="minmax(300px, auto)"
        gridGap={8}
        px={8}
        pb={8}
      >
        {HUMAN_FONTS.map((font) => (
          <HandwritingBlock
            key={font.code}
            font={font}
            isSelected={isSelected(font)}
          />
        ))}
      </Grid>
    </ZCard>
  )
}
