import type { CheckboxProps } from '@chakra-ui/react'
import { Center, Flex } from '@chakra-ui/react'
import { ZCheckbox } from '@postal-io/postal-ui'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import React from 'react'

const TOOLTIP = 'Send an email notification to the User notifying them that they have been added to the team.'

export interface SendUserEmailCheckboxProps extends CheckboxProps {
  isChecked?: CheckboxProps['isChecked']
  onChange?: CheckboxProps['onChange']
}
export const SendUserEmailCheckbox: React.FC<SendUserEmailCheckboxProps> = ({ isChecked, onChange, ...rest }) => {
  return (
    <Center
      display="flex"
      gridGap={2}
      {...rest}
    >
      <ZCheckbox
        name="sendNotification"
        id="sendNotification"
        colorScheme="atomicBlue"
        isChecked={isChecked}
        onChange={onChange}
      >
        <Flex
          alignItems="center"
          gap={2}
        >
          Send User Email
        </Flex>
      </ZCheckbox>
      <ZInfoTooltip
        aria-label={TOOLTIP}
        label={TOOLTIP}
      />
    </Center>
  )
}
