import type { BoxProps, HeadingProps, MenuButtonProps } from '@chakra-ui/react'
import { Box, Icon, MenuButton, useDisclosure } from '@chakra-ui/react'
import type { UiNavbarLinkProps, UiNavbarMenuLinkProps, UiNavbarMenuProps, ZButtonProps } from '@postal-io/postal-ui'
import { UiEnhancedTooltip, UiMenu, UiMenuList, UiNavbarLink, UiNavbarMenuLink, ZButton } from '@postal-io/postal-ui'
import React, { useMemo } from 'react'
import { MdMoreHoriz } from 'react-icons/md'
import { NavLink, useLocation } from 'react-router-dom'

export const zNavbarLinkStyles = {
  fontFamily: 'Lexend',
  fontWeight: 'bold',
  fontSize: 'body-md',
  borderBottom: 'none',
  m: 0,
  color: 'white',
  _hover: { color: 'white', opacity: 0.8 },
}

export const zNavbarMenuLinkStyles = {
  ...zNavbarLinkStyles,
  my: -2,
  py: 2,
  mx: -3,
  px: 6,
  flexGrow: 1,
  color: 'atomicBlue.400',
  _hover: { color: 'atomicBlue.400', bg: 'atomicGray.50' },
  _active: { color: 'atomicBlue.400', bg: 'atomicGray.100' },
  sx: { '&.active': { color: 'atomicBlue.400' } },
}

export interface NavbarLinkV2Props extends UiNavbarLinkProps {
  to: string
  exact?: boolean
  extraPaths?: string[]
}
export const NavbarLink: React.FC<NavbarLinkV2Props> = ({ to, extraPaths, exact, children, ...rest }) => {
  const { pathname } = useLocation()
  const allPaths = useMemo(() => [to, ...(extraPaths || [])], [extraPaths, to])
  const isActive = useMemo(
    () => allPaths.some((p) => (exact ? pathname === p : pathname.startsWith(p))),
    [allPaths, exact, pathname]
  )

  return (
    <UiNavbarLink
      as={NavLink}
      to={to}
      isActive={isActive}
      {...zNavbarLinkStyles}
      {...rest}
    >
      {children}
    </UiNavbarLink>
  )
}

export const NavbarButtonV2: React.FC<ZButtonProps> = (props) => {
  return (
    <ZButton
      p={0}
      variant="link"
      {...zNavbarLinkStyles}
      {...props}
    />
  )
}

export interface NavbarMenuLinkV2Props extends UiNavbarMenuLinkProps {
  to: string
  exact?: boolean
  extraPaths?: string[]
  onClick?: () => void
}
export const NavbarMenuLinkV2: React.FC<NavbarMenuLinkV2Props> = ({
  to,
  extraPaths,
  exact,
  onClick,
  children,
  ...rest
}) => {
  const { pathname } = useLocation()
  const allPaths = useMemo(() => [to, ...(extraPaths || [])], [extraPaths, to])
  const isActive = useMemo(
    () => allPaths.some((p) => (exact ? pathname === p : pathname.startsWith(p))),
    [allPaths, exact, pathname]
  )

  return (
    <UiNavbarMenuLink
      as={NavLink}
      to={to}
      isActive={isActive}
      onClick={onClick}
      textTransform="none"
      {...zNavbarMenuLinkStyles}
      {...rest}
    >
      {children}
    </UiNavbarMenuLink>
  )
}

export interface NavbarMenuV2Props extends UiNavbarMenuProps {
  exact?: boolean
  paths: string | string[]
}
export const NavbarMenuV2: React.FC<NavbarMenuV2Props> = ({ paths, exact, ...rest }) => {
  const { pathname } = useLocation()
  const allPaths = useMemo(() => [paths].flat(), [paths])
  const isActive = useMemo(
    () => allPaths.some((p) => (exact ? pathname === p : pathname.startsWith(p))),
    [allPaths, exact, pathname]
  )

  return (
    <ZNavbarMenu
      isActive={isActive}
      titleProps={zNavbarLinkStyles}
      {...rest}
    />
  )
}

export interface ZNavbarMenuProps extends BoxProps {
  title?: string
  // color?: string
  isActive?: boolean
  buttonProps?: MenuButtonProps
  titleProps?: HeadingProps
}

export const ZNavbarMenu: React.FC<React.PropsWithChildren<ZNavbarMenuProps>> = ({
  title,
  // color = 'navbarLink',
  isActive,
  buttonProps,
  titleProps,
  children,
  ...rest
}) => {
  const disclosure = useDisclosure()
  // const { Color } = useColor()
  // const deactiveColor = Color(color).lighten(0.3).hex()

  return (
    <UiMenu
      placement="bottom"
      {...disclosure}
      {...rest}
    >
      <Box lineHeight={0}>
        <UiEnhancedTooltip
          label="More"
          isDisabled={disclosure.isOpen}
        >
          <MenuButton
            data-testid="More_Menu"
            display="inline-flex"
            position="relative"
            textDecoration="none"
            height={5}
            _hover={{ textDecoration: 'none' }}
            {...buttonProps}
          >
            <Icon
              as={MdMoreHoriz}
              height="auto"
              width="20px"
              color={isActive ? 'atomicBlue.400' : 'white'}
            />
          </MenuButton>
        </UiEnhancedTooltip>
      </Box>
      <UiMenuList
        borderRadius={10}
        mt={2}
        zIndex={5}
      >
        {children}
      </UiMenuList>
    </UiMenu>
  )
}
