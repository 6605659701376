import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiSelectTypeaheadProps } from '@postal-io/postal-ui'
import { useAlertError, ZSelectTypeahead } from '@postal-io/postal-ui'
import { useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import type { MagicLink } from '../../api'
import { MagicLinkOrderByInput, SearchMagicLinksDocument } from '../../api'

export function AutoCompleteMagicLink(props: UiSelectTypeaheadProps<MagicLink>) {
  const [search, setSearch] = useState(props.inputValue || props.defaultInputValue || '')
  const [debounced] = useDebounce(search, 400)

  const variables = useMemo(() => {
    return {
      filter: { name: debounced ? { contains: debounced } : undefined },
      orderBy: MagicLinkOrderByInput.CreatedAsc,
      limit: 20,
    }
  }, [debounced])

  const query = useGraphqlQuery(SearchMagicLinksDocument, variables)
  const items = useMemo(() => query.data?.searchMagicLinks ?? [], [query.data?.searchMagicLinks])
  useAlertError(query.error)

  const handleInput = useCallback(
    (val: any, meta: any) => {
      setSearch(val)
      props.onInputChange && props.onInputChange(val, meta)
    },
    [props]
  )

  const { onInputChange, ...rest } = props

  return (
    <ZSelectTypeahead
      data-testid="AutoCompleteMagicLink"
      options={items}
      getOptionLabel={(t) => t.name}
      getOptionValue={(t) => t.id}
      onInputChange={handleInput}
      isLoading={query.isLoading}
      placeholder="Search MagicLinks"
      noOptionsMessage={() => 'No MagicLink Found'}
      {...rest}
    />
  )
}
