import { Box, Divider, Flex } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { SelectTypeaheadStylesV2, UiTruncate, useAlerts, ZSelect, ZText } from '@postal-io/postal-ui'
import type { ApprovedPostal, Attribution, MarketplaceProduct } from 'api'
import { BulkCreateApprovedPostalDocument, BulkEditApprovedPostalDocument, Status } from 'api'
import { AutoCompleteTeams } from 'components/AutoComplete'
import { ZBasicDialogButtons, ZDialog } from 'components/Common/ZComponents'
import { AttributionSelect } from 'components/Integrations/AttributionSelect'
import { POSTAL_INVALIDATIONS, useAcl, useBackgroundQueue, useSession } from 'hooks'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface MarketplaceBulkEditV2Props {
  type: 'Approve' | 'Update'
  items: MarketplaceProduct[] | ApprovedPostal[]
  isOpen: boolean
  onClose: () => void
  onSuccess?: () => void
}

export const MarketplaceBulkEdit: React.FC<MarketplaceBulkEditV2Props> = ({
  type,
  items,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const Alert = useAlerts()
  const navigate = useNavigate()
  const { session } = useSession()
  const { hasFeature } = useAcl()

  // if there is a teamId in session, this team admin cannot assign teams
  const hasTeamSelect = hasFeature('teamPostals') && !session.teamId

  const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>()
  const [selectedAttribution, setSelectedAttribution] = useState<Attribution>()
  const [status, setStatus] = useState<Status>()

  const selectedItemsText = `${items.length} Item${items.length > 1 ? 's' : ''}`

  const { queue, invalidate } = useBackgroundQueue()
  const bulkCreateApprovedPostal = useGraphqlMutation(BulkCreateApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.bulkCreateApprovedPostal, () => {
        Alert.success(`${selectedItemsText} have been approved`)
        onSuccess?.()
      })
      invalidate(POSTAL_INVALIDATIONS)
    },
  })

  const bulkEditApprovedPostal = useGraphqlMutation(BulkEditApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.bulkEditApprovedPostal, () => {
        Alert.success(`${selectedItemsText} have been updated`)
        onSuccess?.()
      })
      invalidate(POSTAL_INVALIDATIONS)
    },
  })

  const handleSelectTeam = (teamIds: any) => setSelectedTeamIds(teamIds)

  const handleConfirm = async () => {
    const selectedItemIds = items.map((i) => i.id)
    try {
      if (type === 'Approve') {
        // if we are a team admin, we want to lock to this teamId
        await bulkCreateApprovedPostal.mutateAsync({
          marketplaceProductIds: selectedItemIds,
          data: {
            status: status || Status.Active,
            teamIds: !!session.teamId ? [session.teamId] : selectedTeamIds,
            attribution: selectedAttribution,
          },
        })
        navigate('/items/postals')
        Alert.success(`${selectedItemsText} will be approved in the background`)
      } else if (type === 'Update') {
        // if we are a team admin, we don't want to change the teamIds
        await bulkEditApprovedPostal.mutateAsync({
          approvedPostalIds: selectedItemIds,
          data: {
            status,
            teamIds: !!session.teamId ? undefined : selectedTeamIds,
            attribution: selectedAttribution,
          },
        })
      }
    } catch (err) {
      Alert.error(err)
    } finally {
      onClose()
    }
  }

  const handleStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as Status
    setStatus(isEmpty(value) ? undefined : value)
  }

  const isLoading = !items

  return (
    <>
      <ZDialog
        size="lg"
        title={`Confirm Bulk ${type} Items`}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ZText fontWeight="bold">You have selected the following {selectedItemsText}:</ZText>
        <Box
          mt={6}
          pl={8}
        >
          <ul>
            {items.slice(0, 10).map((item, idx) => (
              <li key={idx}>
                <UiTruncate
                  fontFamily="Lexend"
                  fontWeight="normal"
                  text={item.name}
                  length={50}
                />
              </li>
            ))}
            {items.length > 10 ? (
              <li style={{ fontFamily: 'Lexend', fontWeight: 'bold' }}>{`+${items.length - 10} more...`}</li>
            ) : null}
          </ul>
        </Box>

        <Divider my={8} />

        <ZText
          fontWeight="bold"
          mb={1}
        >
          Enable or Draft these Items
        </ZText>

        <Flex alignItems="center">
          <ZSelect
            data-testid="bulkApprovedItemStatus"
            name="status"
            isDisabled={isLoading}
            onChange={handleStatus}
            defaultValue={type === 'Approve' ? 'ACTIVE' : undefined}
          >
            {type === 'Update' && <option value="">Retain current statuses</option>}
            <option value={Status.Active}>Enable</option>
            <option value={Status.Disabled}>Draft</option>
          </ZSelect>
        </Flex>

        {hasTeamSelect && (
          <>
            <ZText
              fontWeight="bold"
              mt={8}
              mb={1}
            >
              Please specify any Team(s) that can use these Items
            </ZText>
            <AutoCompleteTeams
              value={selectedTeamIds}
              onChange={handleSelectTeam}
              includeNoChangesOption={type === 'Update'}
              {...SelectTypeaheadStylesV2}
            />
          </>
        )}

        <AttributionSelect
          menuPlacement="top"
          textAlign="center"
          mt={8}
          onChange={setSelectedAttribution}
        />
        <ZBasicDialogButtons
          onClose={onClose}
          onConfirm={handleConfirm}
          confirmText={`${type} ${selectedItemsText}`}
        />
      </ZDialog>
    </>
  )
}
