import { Collapse, Flex, FormControl, useDisclosure, VStack } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiChangeEvent } from '@postal-io/postal-ui'
import {
  UiAlert,
  useAlertError,
  useAlerts,
  ZButton,
  ZFormLabel,
  ZInput,
  ZInputDate,
  ZInputMoney,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZSelect,
  ZText,
  ZTextarea,
} from '@postal-io/postal-ui'
import { FormType, GetAccountDocument, SubmitFormDocument } from 'api'
import { ZAlert, ZDialog } from 'components/Common/ZComponents'
import { useAnalyticsSend } from 'hooks'
import React from 'react'
import { useImmer } from 'use-immer'
import { AnalyticsEventV2 } from '../../hooks/useAnalytics'

interface PostalConciergeFormProps {
  isOpen: boolean
  onClose: () => void
}

export const PostalConciergeForm: React.FC<PostalConciergeFormProps> = ({ isOpen, onClose }) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const locale = Intl.DateTimeFormat().resolvedOptions().locale
  const conciergeForm = useGraphqlMutation(SubmitFormDocument)
  const showSuccess = useDisclosure()
  const [form, setForm] = useImmer<Record<string, any>>({
    frequency: 'one-time send',
    totalSends: 50,
    localTimeZone: localTimeZone,
    locale: locale,
  })

  const getAccount = useGraphqlQuery(GetAccountDocument)
  const isTrial = getAccount?.data?.getAccount?.trialSubscription

  const Alert = useAlerts()
  const sendAnalytics = useAnalyticsSend()
  useAlertError(conciergeForm.error)

  const handleFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setForm((draft: Record<string, any>) => {
      draft[name] = value
    })
  }

  const handleSends = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((draft: Record<string, any>) => {
      draft['totalSends'] = e.target.value
    })
  }

  const handleBudget = ({ value }: UiChangeEvent<number>) => {
    setForm((draft: Record<string, any>) => {
      draft['totalBudget'] = value
    })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!form.totalSends || !form.totalBudget || !form.userMessage || !form.frequency || !form.sendDate)
      return Alert.error('Please complete all form fields')

    try {
      await conciergeForm.mutateAsync({
        type: FormType.Concierge,
        variables: form,
      })
      showSuccess.onOpen()
    } catch (err) {
      console.error(err)
    } finally {
      sendAnalytics({ event: AnalyticsEventV2.Concierge_RequestForm_Submitted })
    }
  }

  const isLoading = conciergeForm.isLoading

  return (
    <>
      <ZModal
        size="4xl"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="outside"
        closeOnEsc
      >
        <ZModalOverlay />
        <ZModalContent>
          <ZModalCloseButton />
          <ZModalHeader>Paper Plane Marketing Agency Request</ZModalHeader>
          <ZModalBody p={{ base: 4, md: 16 }}>
            {isTrial ? (
              <UiAlert
                status="info"
                hideClose
              >
                Paper Plane is not available during a trial. Please contact your Account Executive for details and to
                learn more.
              </UiAlert>
            ) : (
              <form onSubmit={handleSubmit}>
                <VStack spacing={8}>
                  <FormControl
                    maxW="700px"
                    id="frequency"
                    isRequired
                  >
                    <ZFormLabel>Send Frequency</ZFormLabel>
                    <ZSelect
                      name="frequency"
                      value={form.frequency || ''}
                      onChange={handleFormInput}
                    >
                      <option value="one-time send">One-time send</option>
                      <option value="send over time">Send over time</option>
                    </ZSelect>
                  </FormControl>
                  <FormControl
                    maxW="700px"
                    id="sendDate"
                    isRequired
                  >
                    <ZFormLabel htmlFor="time">In-Hands Date</ZFormLabel>
                    <ZInputDate
                      mb={2}
                      value={form.sendDate || ''}
                      onChange={handleFormInput}
                      name="sendDate"
                    />
                  </FormControl>
                  <FormControl
                    maxW="700px"
                    id="totalSends"
                    isRequired
                  >
                    <ZFormLabel>Units</ZFormLabel>
                    <ZInput
                      min={1}
                      type="number"
                      value={form.totalSends}
                      onChange={handleSends}
                      isInvalid={form.totalSends < 50}
                      name="totalSends"
                    />
                  </FormControl>
                  <Collapse in={form.totalSends < 50}>
                    <ZAlert
                      status="info"
                      hideClose
                      mx={8}
                      w="unset"
                      fontSize="body-lg"
                      color="atomicGray.500"
                      textAlign="center"
                    >
                      Minimum order quantity for Paper Plane requests is 50 units. If your need is for less than 50
                      units, please review our Marketplace options.
                    </ZAlert>
                  </Collapse>
                  <FormControl
                    maxW="700px"
                    id="totalBudget"
                    isRequired
                  >
                    <ZFormLabel>Budget</ZFormLabel>
                    <ZInputMoney
                      min={0}
                      name="totalBudget"
                      value={form.totalBudget}
                      onChange={handleBudget}
                    />
                  </FormControl>
                  <FormControl
                    maxW="700px"
                    id="userMessage"
                    isRequired
                  >
                    <ZFormLabel>Project Notes</ZFormLabel>
                    <ZTextarea
                      width="100%"
                      value={form.userMessage}
                      name="userMessage"
                      onChange={handleFormInput}
                    />
                  </FormControl>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDir="column"
                  >
                    <ZButton
                      type="submit"
                      minW="300px"
                      colorScheme="atomicBlue"
                      mt={8}
                      isDisabled={isLoading || form.totalSends < 50}
                      isLoading={isLoading}
                    >
                      Send Request
                    </ZButton>
                  </Flex>
                </VStack>
              </form>
            )}
          </ZModalBody>
        </ZModalContent>
      </ZModal>
      {showSuccess.isOpen && (
        <ZDialog
          status="success"
          title="Request Sent"
          onClose={onClose}
          isOpen={showSuccess.isOpen}
        >
          <ZText>
            Thank you for submitting your Paper Plane request! Our team will be in touch within 2 business days.
          </ZText>
          <Flex justify="center">
            <ZButton
              mt={8}
              minW="175px"
              colorScheme="atomicBlue"
              onClick={onClose}
            >
              Close
            </ZButton>
          </Flex>
        </ZDialog>
      )}
    </>
  )
}
