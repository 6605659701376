import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Instant: { input: any; output: any }
  Object: { input: any; output: any }
  ObjectId: { input: string; output: string }
  Url: { input: string; output: string }
}

export type Account = {
  __typename?: 'Account'
  accountContactSettings?: Maybe<AccountContactSettings>
  address?: Maybe<Address>
  addressRefreshDays?: Maybe<Scalars['Int']['output']>
  addressRefreshEnabled?: Maybe<Scalars['Boolean']['output']>
  addressRefreshExpirationDays?: Maybe<Scalars['Int']['output']>
  addressRefreshExpireAction?: Maybe<PostalDeliveryExpireAction>
  billingTermEndDate?: Maybe<Scalars['Instant']['output']>
  billingTermStartDate?: Maybe<Scalars['Instant']['output']>
  brandingInstance?: Maybe<BrandingInstance>
  brandingLogoEnabled?: Maybe<Scalars['Boolean']['output']>
  created?: Maybe<ActionRecord>
  displayName?: Maybe<Scalars['String']['output']>
  domains?: Maybe<Array<Scalars['String']['output']>>
  emailTemplateId?: Maybe<Scalars['ObjectId']['output']>
  eventRequestNotificationEmails?: Maybe<Array<Scalars['String']['output']>>
  fiscalQuarterSetup?: Maybe<FiscalQuarterSetup>
  forceAuthenticationMethod?: Maybe<Scalars['String']['output']>
  giftEmailFont?: Maybe<Scalars['String']['output']>
  giftEmailPrimaryColor?: Maybe<Scalars['String']['output']>
  giftEmailSecondaryColor?: Maybe<Scalars['String']['output']>
  giftEmailTemplateId?: Maybe<Scalars['ObjectId']['output']>
  giftEmailTertiaryColor?: Maybe<Scalars['String']['output']>
  id: Scalars['ObjectId']['output']
  linkApprovalSettings?: Maybe<LinkApprovalSettings>
  marketplaceRequestNotificationEmails?: Maybe<Array<Scalars['String']['output']>>
  name: Scalars['String']['output']
  notificationSettings?: Maybe<AccountNotificationSettings>
  oktaIntegration?: Maybe<OktaIntegration>
  oneLoginIntegration?: Maybe<OneLoginIntegration>
  partnerIds?: Maybe<Array<Scalars['String']['output']>>
  plan: Scalars['String']['output']
  postalDeliveryEmailSetting?: Maybe<PostalDeliveryEmailSetting>
  postalDeliveryExpirationDays?: Maybe<Scalars['Int']['output']>
  postalDeliveryExpireAction?: Maybe<PostalDeliveryExpireAction>
  primaryLogoUrl?: Maybe<Scalars['Url']['output']>
  seats?: Maybe<Scalars['Int']['output']>
  seatsAssigned?: Maybe<Scalars['Int']['output']>
  sendSettings?: Maybe<AccountSendSettings>
  sessionTimeoutMinutes?: Maybe<Scalars['Int']['output']>
  settings?: Maybe<Settings>
  sfdcPackageVersion?: Maybe<Scalars['String']['output']>
  status: Status
  subscriptionBillingAccountId?: Maybe<Scalars['ObjectId']['output']>
  subscriptionEndDate?: Maybe<Scalars['Instant']['output']>
  subscriptionStartDate?: Maybe<Scalars['Instant']['output']>
  subscriptions?: Maybe<Array<Scalars['String']['output']>>
  supportEnabled?: Maybe<Scalars['Instant']['output']>
  trialSubscription?: Maybe<Scalars['Boolean']['output']>
  userCanViewEvents?: Maybe<Scalars['Boolean']['output']>
  userCanViewMarketplace?: Maybe<Scalars['Boolean']['output']>
}

export type AccountContactSettings = {
  __typename?: 'AccountContactSettings'
  redactAddress?: Maybe<Scalars['Boolean']['output']>
}

export type AccountContactSettingsInput = {
  redactAddress?: InputMaybe<Scalars['Boolean']['input']>
}

export type AccountNotificationSettings = {
  __typename?: 'AccountNotificationSettings'
  balanceLowNotificationThreshold?: Maybe<Scalars['Int']['output']>
  budgetLowNotificationThreshold?: Maybe<Scalars['Int']['output']>
}

export type AccountNotificationSettingsInput = {
  balanceLowNotificationThreshold: Scalars['Int']['input']
  budgetLowNotificationThreshold: Scalars['Int']['input']
}

export type AccountSendSettings = {
  __typename?: 'AccountSendSettings'
  bulkSendEnabled?: Maybe<Scalars['Boolean']['output']>
  disclaimerInfo?: Maybe<DisclaimerInfo>
}

export type AccountSendSettingsInput = {
  bulkSendEnabled?: InputMaybe<Scalars['Boolean']['input']>
  disclaimerInfo?: InputMaybe<DisclaimerInfoInput>
}

export type ActionRecord = {
  __typename?: 'ActionRecord'
  actor?: Maybe<Scalars['String']['output']>
  dateTime?: Maybe<Scalars['Instant']['output']>
}

export type ActivityStream = {
  __typename?: 'ActivityStream'
  acknowledgedBy?: Maybe<Scalars['ObjectId']['output']>
  callouts?: Maybe<Array<StreamCallout>>
  category?: Maybe<NotificationCategory>
  count?: Maybe<Scalars['Int']['output']>
  created: ActionRecord
  message: Scalars['String']['output']
  name: Scalars['String']['output']
  type: ActivityType
  webhookGroup?: Maybe<Scalars['String']['output']>
}

export type ActivityStreamFilterInput = {
  category?: InputMaybe<StringFilterInput>
  contactCrmMap_accountId?: InputMaybe<StringFilterInput>
  parentActivityId?: InputMaybe<ObjectIdFilterInput>
  teamId?: InputMaybe<ObjectIdFilterInput>
  type?: InputMaybe<StringFilterInput>
  userId?: InputMaybe<ObjectIdFilterInput>
}

export enum ActivityStreamOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export enum ActivityType {
  Action = 'ACTION',
  ActionComplete = 'ACTION_COMPLETE',
  Delivered = 'DELIVERED',
  Team = 'TEAM',
  Update = 'UPDATE',
}

export type AddFundsFeeInfo = {
  __typename?: 'AddFundsFeeInfo'
  flatFee: Scalars['Int']['output']
  percentFee: Scalars['Float']['output']
  totalFee: Scalars['Int']['output']
}

export type AddFundsIntent = {
  __typename?: 'AddFundsIntent'
  accountId: Scalars['ObjectId']['output']
  billingAccountId: Scalars['ObjectId']['output']
  created: ActionRecord
  currency?: Maybe<Currency>
  feeInfo: AddFundsFeeInfo
  grossPaymentAmount: Scalars['Int']['output']
  id: Scalars['ObjectId']['output']
  lastPayByInvoiceCheck?: Maybe<Scalars['Instant']['output']>
  metadata?: Maybe<Scalars['Object']['output']>
  netPaymentAmount: Scalars['Int']['output']
  partnerPaymentMethodId?: Maybe<Scalars['String']['output']>
  paymentPartnerType?: Maybe<PaymentPartnerType>
  postalFulfillmentId?: Maybe<Scalars['ObjectId']['output']>
  status: AddFundsStatus
  type: AddFundsType
  updated?: Maybe<ActionRecord>
  userId: Scalars['ObjectId']['output']
}

export type AddFundsIntentFilterInput = {
  accountId?: InputMaybe<ObjectIdFilterInput>
  billingAccountId?: InputMaybe<ObjectIdFilterInput>
  created?: InputMaybe<InstantFilterInput>
  currency?: InputMaybe<StringFilterInput>
  grossPaymentAmount?: InputMaybe<IntFilterInput>
  id?: InputMaybe<ObjectIdFilterInput>
  netPaymentAmount?: InputMaybe<IntFilterInput>
  status?: InputMaybe<StringFilterInput>
  type?: InputMaybe<StringFilterInput>
  updated?: InputMaybe<InstantFilterInput>
  userId?: InputMaybe<ObjectIdFilterInput>
}

export enum AddFundsIntentOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  GrossPaymentAmountAsc = 'grossPaymentAmount_ASC',
  GrossPaymentAmountDesc = 'grossPaymentAmount_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetPaymentAmountAsc = 'netPaymentAmount_ASC',
  NetPaymentAmountDesc = 'netPaymentAmount_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAsc = 'updated_ASC',
  UpdatedDesc = 'updated_DESC',
}

export enum AddFundsStatus {
  AwaitingPayment = 'AWAITING_PAYMENT',
  BalanceError = 'BALANCE_ERROR',
  BalanceSuccess = 'BALANCE_SUCCESS',
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Created = 'CREATED',
  PaymentError = 'PAYMENT_ERROR',
  PaymentSuccess = 'PAYMENT_SUCCESS',
  Processing = 'PROCESSING',
}

export enum AddFundsType {
  AutoReloadPostDrawdown = 'AUTO_RELOAD_POST_DRAWDOWN',
  AutoReloadPreDrawdown = 'AUTO_RELOAD_PRE_DRAWDOWN',
  BrandCreditCard = 'BRAND_CREDIT_CARD',
  PayByInvoice = 'PAY_BY_INVOICE',
  Prepayment = 'PREPAYMENT',
}

export type AddFundsV2Input = {
  amount: Scalars['Int']['input']
  billingAccountId: Scalars['ObjectId']['input']
  partnerPaymentMethodId?: InputMaybe<Scalars['String']['input']>
  paymentPartnerType?: InputMaybe<PaymentPartnerType>
}

export type Address = {
  __typename?: 'Address'
  address1?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  address3?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  entryName?: Maybe<Scalars['String']['output']>
  postalCode?: Maybe<Scalars['String']['output']>
  preferred: Scalars['Boolean']['output']
  source?: Maybe<AddressSource>
  state?: Maybe<Scalars['String']['output']>
  status?: Maybe<AddressStatus>
  statusReason?: Maybe<Scalars['String']['output']>
  systems?: Maybe<Array<Scalars['String']['output']>>
  uspsAddress?: Maybe<Address>
}

export type AddressInput = {
  address1: Scalars['String']['input']
  address2?: InputMaybe<Scalars['String']['input']>
  address3?: InputMaybe<Scalars['String']['input']>
  city: Scalars['String']['input']
  country?: InputMaybe<Scalars['String']['input']>
  entryName?: InputMaybe<Scalars['String']['input']>
  postalCode: Scalars['String']['input']
  preferred: Scalars['Boolean']['input']
  source?: InputMaybe<AddressSource>
  state?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<AddressStatus>
}

export enum AddressSource {
  Eloqua = 'ELOQUA',
  External = 'EXTERNAL',
  Hubspot = 'HUBSPOT',
  Manual = 'MANUAL',
  Marketo = 'MARKETO',
  Outreach = 'OUTREACH',
  Refresh = 'REFRESH',
  Salesloft = 'SALESLOFT',
  Sfdc = 'SFDC',
}

export enum AddressStatus {
  Blocked = 'BLOCKED',
  Error = 'ERROR',
  Invalid = 'INVALID',
  Matched = 'MATCHED',
  MatchedOverride = 'MATCHED_OVERRIDE',
}

export type AdjustUserBudgetInput = {
  amountCents: Scalars['Int']['input']
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  userId: Scalars['ObjectId']['input']
}

export type AnalyticsFilterInput = {
  id?: InputMaybe<ObjectIdFilterInput>
}

export enum AnalyticsOrderByInput {
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UserNameAsc = 'userName_ASC',
  UserNameDesc = 'userName_DESC',
}

export type AnalyticsRequestConfig = {
  approvedPostalId?: InputMaybe<Scalars['ObjectId']['input']>
  endDate?: InputMaybe<Scalars['Instant']['input']>
  granularity?: InputMaybe<Granularity>
  groupBy?: InputMaybe<GroupBy>
  limit?: InputMaybe<Scalars['Int']['input']>
  magicLinkId?: InputMaybe<Scalars['ObjectId']['input']>
  playbookDefinitionId?: InputMaybe<Scalars['ObjectId']['input']>
  searchQuery?: InputMaybe<Scalars['Object']['input']>
  sortBy?: InputMaybe<SortBy>
  startDate?: InputMaybe<Scalars['Instant']['input']>
  teamIds?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  triggerId?: InputMaybe<Scalars['ObjectId']['input']>
  type: DataObjectType
  userIds?: InputMaybe<Array<Scalars['ObjectId']['input']>>
}

export type ApiStatus = {
  __typename?: 'ApiStatus'
  status: Scalars['String']['output']
}

export type ApplicableBudget = {
  __typename?: 'ApplicableBudget'
  accountId?: Maybe<Scalars['String']['output']>
  billingAccountId?: Maybe<Scalars['String']['output']>
  budget?: Maybe<Budget>
  teamId?: Maybe<Scalars['String']['output']>
}

export type ApprovalRequiredInfo = {
  __typename?: 'ApprovalRequiredInfo'
  approvalRequiredReason: ApprovalRequiredReason
  autoAction?: Maybe<AutoAction>
  autoActionDate?: Maybe<Scalars['Instant']['output']>
}

export enum ApprovalRequiredReason {
  CustomerConfiguration = 'CUSTOMER_CONFIGURATION',
  Suspicious = 'SUSPICIOUS',
}

export type ApprovedPostal = {
  __typename?: 'ApprovedPostal'
  attribution?: Maybe<Attribution>
  brandName?: Maybe<Scalars['String']['output']>
  category: Scalars['String']['output']
  collection?: Maybe<Scalars['Boolean']['output']>
  currency?: Maybe<Currency>
  customizable?: Maybe<Scalars['Boolean']['output']>
  description: Scalars['String']['output']
  designTemplate?: Maybe<DesignTemplate>
  displayName?: Maybe<Scalars['String']['output']>
  event?: Maybe<MagicEventInstance>
  eventDetails?: Maybe<MagicEvent>
  eventFeeSettings?: Maybe<EventFeeSettings>
  formFieldList?: Maybe<Array<FormField>>
  geographicMarkets?: Maybe<Array<Scalars['String']['output']>>
  id: Scalars['ObjectId']['output']
  imageUrls?: Maybe<Array<ImageReference>>
  itemCustomizationList?: Maybe<Array<ItemCustomization>>
  marketplaceProductId: Scalars['ObjectId']['output']
  metrics?: Maybe<BasicMetrics>
  name: Scalars['String']['output']
  ownerId?: Maybe<Scalars['ObjectId']['output']>
  status: Status
  subCategory?: Maybe<Array<Scalars['String']['output']>>
  teamIds?: Maybe<Array<Scalars['ObjectId']['output']>>
  type?: Maybe<Scalars['String']['output']>
  useCases?: Maybe<Array<Scalars['String']['output']>>
  userId?: Maybe<Scalars['ObjectId']['output']>
  variantOrderFlexibility?: Maybe<Scalars['Boolean']['output']>
  variants?: Maybe<Array<ApprovedProductVariant>>
}

export type ApprovedPostalAnalytics = {
  __typename?: 'ApprovedPostalAnalytics'
  accepted?: Maybe<Scalars['Int']['output']>
  activeMagicLinks?: Maybe<Scalars['Int']['output']>
  approvedPostalId?: Maybe<Scalars['ObjectId']['output']>
  bounces?: Maybe<Scalars['Int']['output']>
  campaignsSent?: Maybe<Scalars['Int']['output']>
  cancelled?: Maybe<Scalars['Int']['output']>
  completed?: Maybe<Scalars['Int']['output']>
  costInCents?: Maybe<Scalars['Int']['output']>
  date?: Maybe<Scalars['Instant']['output']>
  delivered?: Maybe<Scalars['Int']['output']>
  deliveryEmailAccepted?: Maybe<Scalars['Int']['output']>
  deliveryEmailClicked?: Maybe<Scalars['Int']['output']>
  deliveryEmailDeclined?: Maybe<Scalars['Int']['output']>
  deliveryEmailDonated?: Maybe<Scalars['Int']['output']>
  deliveryEmailExpired?: Maybe<Scalars['Int']['output']>
  deliveryEmailFailed?: Maybe<Scalars['Int']['output']>
  deliveryEmailOpened?: Maybe<Scalars['Int']['output']>
  deliveryEmailSent?: Maybe<Scalars['Int']['output']>
  deliveryEmailUnsubscribed?: Maybe<Scalars['Int']['output']>
  eventsBooked?: Maybe<Scalars['Int']['output']>
  failed?: Maybe<Scalars['Int']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  fundsAdded?: Maybe<Scalars['Int']['output']>
  gmvCAD?: Maybe<Scalars['Int']['output']>
  gmvEUR?: Maybe<Scalars['Int']['output']>
  gmvGBP?: Maybe<Scalars['Int']['output']>
  gmvUSD?: Maybe<Scalars['Int']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  linkExecutions?: Maybe<Scalars['Int']['output']>
  linkExpiredViews?: Maybe<Scalars['Int']['output']>
  linkMaxExeViews?: Maybe<Scalars['Int']['output']>
  linkNewContact?: Maybe<Scalars['Int']['output']>
  linkTotalViews?: Maybe<Scalars['Int']['output']>
  linkUniqViews?: Maybe<Scalars['Int']['output']>
  meetingsBooked?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
  newContact?: Maybe<Scalars['Int']['output']>
  playbooksStarted?: Maybe<Scalars['Int']['output']>
  previewImageUrl?: Maybe<Scalars['String']['output']>
  queued?: Maybe<Scalars['Int']['output']>
  sent?: Maybe<Scalars['Int']['output']>
  socialShare?: Maybe<Scalars['Int']['output']>
  started?: Maybe<Scalars['Int']['output']>
  successful?: Maybe<Scalars['Int']['output']>
  teamId?: Maybe<Scalars['ObjectId']['output']>
  teamName?: Maybe<Scalars['String']['output']>
  thankYou?: Maybe<Scalars['Int']['output']>
  touches?: Maybe<Scalars['Int']['output']>
  triggersExecuted?: Maybe<Scalars['Int']['output']>
  userId?: Maybe<Scalars['ObjectId']['output']>
  userName?: Maybe<Scalars['String']['output']>
}

export type ApprovedPostalFilterInput = {
  attributeFilters?: InputMaybe<Array<GenericFilterInput>>
  brandName?: InputMaybe<StringFilterInput>
  category?: InputMaybe<StringFilterInput>
  collection?: InputMaybe<BooleanFilterInput>
  currency?: InputMaybe<StringFilterInput>
  event_status?: InputMaybe<StringFilterInput>
  geographicMarkets?: InputMaybe<StringFilterInput>
  id?: InputMaybe<ObjectIdFilterInput>
  marketplaceProductId?: InputMaybe<ObjectIdFilterInput>
  name?: InputMaybe<StringFilterInput>
  ownerId?: InputMaybe<ObjectIdFilterInput>
  q?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<StringFilterInput>
  subCategory?: InputMaybe<StringFilterInput>
  teamIds?: InputMaybe<ObjectIdFilterInput>
  type?: InputMaybe<StringFilterInput>
  userId?: InputMaybe<ObjectIdFilterInput>
  variants_displayPrice?: InputMaybe<IntegerInputFilter>
  variants_displaySubscriberPrice?: InputMaybe<IntegerInputFilter>
}

export type ApprovedPostalInputItem = {
  approvedPostalId?: InputMaybe<Scalars['ObjectId']['input']>
  marketplaceProductId: Scalars['ObjectId']['input']
  variant: Scalars['ObjectId']['input']
}

export type ApprovedPostalResponse = {
  __typename?: 'ApprovedPostalResponse'
  postal: ApprovedPostal
  previewGenerationTask?: Maybe<BackgroundTask>
}

export type ApprovedPostalUpdateInput = {
  assignedToUserId?: InputMaybe<Scalars['ObjectId']['input']>
  attribution?: InputMaybe<AttributionInput>
  collection?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  designTemplate?: InputMaybe<DesignTemplateInput>
  displayName?: InputMaybe<Scalars['String']['input']>
  event?: InputMaybe<MagicEventInstanceInput>
  eventFeeSettings?: InputMaybe<EventFeeSettingsInput>
  formFieldList?: InputMaybe<Array<FormFieldInput>>
  imageUrls?: InputMaybe<Array<ImageReferenceInput>>
  itemCustomizationInputs?: InputMaybe<Array<ItemCustomizationInputInput>>
  items?: InputMaybe<Array<ApprovedPostalInputItem>>
  name?: InputMaybe<Scalars['String']['input']>
  ownerId?: InputMaybe<Scalars['ObjectId']['input']>
  status?: InputMaybe<Status>
  teamIds?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  variants?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  version?: InputMaybe<Scalars['String']['input']>
}

export type ApprovedProductVariant = {
  __typename?: 'ApprovedProductVariant'
  approvedPostalId?: Maybe<Scalars['ObjectId']['output']>
  containsPerishables?: Maybe<Scalars['Boolean']['output']>
  description?: Maybe<Scalars['String']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  displayPrice: Scalars['Int']['output']
  displaySubscriberPrice: Scalars['Int']['output']
  flatFees?: Maybe<Scalars['Int']['output']>
  giftMessageSupported?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ObjectId']['output']
  imageUrls?: Maybe<Array<ImageReference>>
  marketplaceProductId?: Maybe<Scalars['ObjectId']['output']>
  physicalMessageSupported?: Maybe<Scalars['Boolean']['output']>
  price: Scalars['Int']['output']
  productName?: Maybe<Scalars['String']['output']>
  shippingPriceEstimateMax?: Maybe<Scalars['Int']['output']>
  shippingPriceEstimateMin?: Maybe<Scalars['Int']['output']>
  status: Status
  variantName: Scalars['String']['output']
}

export type Asset = {
  __typename?: 'Asset'
  created: ActionRecord
  id: Scalars['ObjectId']['output']
  name: Scalars['String']['output']
  status: Status
  type: AssetType
  updated?: Maybe<ActionRecord>
}

export type AssetFilterInput = {
  id?: InputMaybe<StringFilterInput>
  name?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  type?: InputMaybe<StringFilterInput>
}

export type AssetInput = {
  name?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Status>
  type?: InputMaybe<AssetType>
}

export enum AssetOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export enum AssetType {
  ApprovedPostalImage = 'APPROVED_POSTAL_IMAGE',
  File = 'FILE',
  Image = 'IMAGE',
  Logo = 'LOGO',
  Other = 'OTHER',
  Pdf = 'PDF',
  PostalPreview = 'POSTAL_PREVIEW',
}

export type Attribution = {
  __typename?: 'Attribution'
  eloquaCampaignId?: Maybe<Scalars['String']['output']>
  eloquaCampaignName?: Maybe<Scalars['String']['output']>
  marketoCampaignId?: Maybe<Scalars['String']['output']>
  marketoCampaignName?: Maybe<Scalars['String']['output']>
  marketoProgramId?: Maybe<Scalars['String']['output']>
  marketoProgramName?: Maybe<Scalars['String']['output']>
  sdfcSandboxCampaignId?: Maybe<Scalars['String']['output']>
  sfdcCampaignId?: Maybe<Scalars['String']['output']>
  sfdcCampaignName?: Maybe<Scalars['String']['output']>
  sfdcSandboxCampaignName?: Maybe<Scalars['String']['output']>
}

export type AttributionInput = {
  eloquaCampaignId?: InputMaybe<Scalars['String']['input']>
  eloquaCampaignName?: InputMaybe<Scalars['String']['input']>
  marketoCampaignId?: InputMaybe<Scalars['String']['input']>
  marketoCampaignName?: InputMaybe<Scalars['String']['input']>
  marketoProgramId?: InputMaybe<Scalars['String']['input']>
  marketoProgramName?: InputMaybe<Scalars['String']['input']>
  sdfcSandboxCampaignId?: InputMaybe<Scalars['String']['input']>
  sfdcCampaignId?: InputMaybe<Scalars['String']['input']>
  sfdcCampaignName?: InputMaybe<Scalars['String']['input']>
  sfdcSandboxCampaignName?: InputMaybe<Scalars['String']['input']>
}

export type AuthenticationMethod = {
  __typename?: 'AuthenticationMethod'
  created?: Maybe<Scalars['Instant']['output']>
  expires?: Maybe<Scalars['Instant']['output']>
  method: Scalars['String']['output']
  updated?: Maybe<Scalars['Instant']['output']>
  version?: Maybe<Scalars['String']['output']>
}

export enum AutoAction {
  Approve = 'APPROVE',
  DoNothing = 'DO_NOTHING',
  Reject = 'REJECT',
}

export type AutomationItem = {
  __typename?: 'AutomationItem'
  id: Scalars['ObjectId']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type Automations = {
  __typename?: 'Automations'
  hasAutomations: Scalars['Boolean']['output']
  hasMore?: Maybe<Scalars['Boolean']['output']>
  items?: Maybe<Array<Maybe<AutomationItem>>>
  total?: Maybe<Scalars['Int']['output']>
  type: DataObjectType
}

export type BackgroundOperationError = {
  __typename?: 'BackgroundOperationError'
  code: Scalars['String']['output']
  count: Scalars['Int']['output']
  message?: Maybe<Scalars['String']['output']>
}

export type BackgroundTask = {
  __typename?: 'BackgroundTask'
  errorCount: Scalars['Int']['output']
  errors?: Maybe<Array<BackgroundOperationError>>
  id: Scalars['ObjectId']['output']
  name: Scalars['String']['output']
  outputs?: Maybe<Scalars['Object']['output']>
  processedCount: Scalars['Int']['output']
  relatedObjectId?: Maybe<Scalars['ObjectId']['output']>
  status: BackgroundTaskStatus
  successCount: Scalars['Int']['output']
  taskAction: Scalars['String']['output']
  taskCompleted?: Maybe<Scalars['Instant']['output']>
  taskErrored?: Maybe<Scalars['Instant']['output']>
  taskStarted?: Maybe<Scalars['Instant']['output']>
  type: BackgroundTaskType
}

export type BackgroundTaskFilterInput = {
  id?: InputMaybe<StringFilterInput>
  name?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  type?: InputMaybe<StringFilterInput>
}

export enum BackgroundTaskOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export enum BackgroundTaskStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Queued = 'QUEUED',
}

export enum BackgroundTaskType {
  Bulk = 'BULK',
  Import = 'IMPORT',
  Other = 'OTHER',
  Report = 'REPORT',
  Sync = 'SYNC',
}

export type BasicMetrics = {
  __typename?: 'BasicMetrics'
  accepted?: Maybe<Scalars['Int']['output']>
  activeMagicLinks?: Maybe<Scalars['Int']['output']>
  bounces?: Maybe<Scalars['Int']['output']>
  campaignsSent?: Maybe<Scalars['Int']['output']>
  cancelled?: Maybe<Scalars['Int']['output']>
  completed?: Maybe<Scalars['Int']['output']>
  costInCents?: Maybe<Scalars['Int']['output']>
  date?: Maybe<Scalars['Instant']['output']>
  delivered?: Maybe<Scalars['Int']['output']>
  deliveryEmailAccepted?: Maybe<Scalars['Int']['output']>
  deliveryEmailClicked?: Maybe<Scalars['Int']['output']>
  deliveryEmailDeclined?: Maybe<Scalars['Int']['output']>
  deliveryEmailDonated?: Maybe<Scalars['Int']['output']>
  deliveryEmailExpired?: Maybe<Scalars['Int']['output']>
  deliveryEmailFailed?: Maybe<Scalars['Int']['output']>
  deliveryEmailOpened?: Maybe<Scalars['Int']['output']>
  deliveryEmailSent?: Maybe<Scalars['Int']['output']>
  deliveryEmailUnsubscribed?: Maybe<Scalars['Int']['output']>
  eventsBooked?: Maybe<Scalars['Int']['output']>
  failed?: Maybe<Scalars['Int']['output']>
  fundsAdded?: Maybe<Scalars['Int']['output']>
  gmvCAD?: Maybe<Scalars['Int']['output']>
  gmvEUR?: Maybe<Scalars['Int']['output']>
  gmvGBP?: Maybe<Scalars['Int']['output']>
  gmvUSD?: Maybe<Scalars['Int']['output']>
  linkExecutions?: Maybe<Scalars['Int']['output']>
  linkExpiredViews?: Maybe<Scalars['Int']['output']>
  linkMaxExeViews?: Maybe<Scalars['Int']['output']>
  linkNewContact?: Maybe<Scalars['Int']['output']>
  linkTotalViews?: Maybe<Scalars['Int']['output']>
  linkUniqViews?: Maybe<Scalars['Int']['output']>
  meetingsBooked?: Maybe<Scalars['Int']['output']>
  newContact?: Maybe<Scalars['Int']['output']>
  playbooksStarted?: Maybe<Scalars['Int']['output']>
  queued?: Maybe<Scalars['Int']['output']>
  sent?: Maybe<Scalars['Int']['output']>
  socialShare?: Maybe<Scalars['Int']['output']>
  started?: Maybe<Scalars['Int']['output']>
  successful?: Maybe<Scalars['Int']['output']>
  thankYou?: Maybe<Scalars['Int']['output']>
  touches?: Maybe<Scalars['Int']['output']>
  triggersExecuted?: Maybe<Scalars['Int']['output']>
}

export type BillingAccount = {
  __typename?: 'BillingAccount'
  autoReload?: Maybe<Scalars['Boolean']['output']>
  autoReloadAmount?: Maybe<Scalars['Int']['output']>
  autoReloadLowLimit?: Maybe<Scalars['Int']['output']>
  balance?: Maybe<Scalars['Int']['output']>
  billingContactInfo?: Maybe<BillingContactInfo>
  created: ActionRecord
  currency?: Maybe<Currency>
  currentPrepaymentAmount?: Maybe<Scalars['Int']['output']>
  defaultPaymentMethod?: Maybe<DefaultPaymentMethod>
  id: Scalars['ObjectId']['output']
  incomingInvoiceActionRecord?: Maybe<ActionRecord>
  incomingInvoiceAmount?: Maybe<Scalars['Int']['output']>
  initialNetsuiteBalance?: Maybe<InitialBalanceNetSuiteSyncInfo>
  linkedAccountIdentifier?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  paymentPartners?: Maybe<Array<PaymentPartnerInfo>>
  prepaymentBackgroundTaskId?: Maybe<Scalars['ObjectId']['output']>
  prepaymentProcessing: Scalars['Boolean']['output']
  status: Status
  type: BillingAccountType
}

export type BillingAccountBalance = {
  __typename?: 'BillingAccountBalance'
  amount: Scalars['Int']['output']
  billingAccountId: Scalars['ObjectId']['output']
  currency: Currency
}

export type BillingAccountFilterInput = {
  name?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  type?: InputMaybe<StringFilterInput>
}

export type BillingAccountMap = {
  __typename?: 'BillingAccountMap'
  billingAccountId: Scalars['ObjectId']['output']
  currency: Currency
}

export type BillingAccountMapFilterInput = {
  billingAccountId?: InputMaybe<ObjectIdFilterInput>
  currency?: InputMaybe<StringFilterInput>
}

export type BillingAccountMapInput = {
  billingAccountId: Scalars['ObjectId']['input']
  currency: Currency
}

export enum BillingAccountOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export enum BillingAccountType {
  Funds = 'FUNDS',
  Subscription = 'SUBSCRIPTION',
}

export type BillingContactAddress = {
  __typename?: 'BillingContactAddress'
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  line1?: Maybe<Scalars['String']['output']>
  line2?: Maybe<Scalars['String']['output']>
  postalCode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
}

export type BillingContactAddressInput = {
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  line1: Scalars['String']['input']
  line2?: InputMaybe<Scalars['String']['input']>
  postalCode: Scalars['String']['input']
  state: Scalars['String']['input']
}

export type BillingContactInfo = {
  __typename?: 'BillingContactInfo'
  address?: Maybe<BillingContactAddress>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
}

export type BillingContactInfoInput = {
  address: BillingContactAddressInput
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phone: Scalars['String']['input']
}

export type BillingPaymentMethodInfo = {
  __typename?: 'BillingPaymentMethodInfo'
  address?: Maybe<BillingContactAddress>
  email?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
}

export enum BillingTransactionType {
  AddFundsIntent = 'ADD_FUNDS_INTENT',
}

export type BlockFreeBusy = {
  __typename?: 'BlockFreeBusy'
  end: Scalars['Instant']['output']
  start: Scalars['Instant']['output']
  type: FreeBusy
}

export type BlocklistEntry = {
  __typename?: 'BlocklistEntry'
  accountId: Scalars['ObjectId']['output']
  dataObjectType: DataObjectType
  entry: Scalars['String']['output']
  fieldName: Scalars['String']['output']
  filterType: BlocklistFilterType
  id: Scalars['ObjectId']['output']
}

export type BlocklistEntryFilterInput = {
  dataObjectType?: InputMaybe<StringFilterInput>
  entry?: InputMaybe<StringFilterInput>
  fieldName?: InputMaybe<StringFilterInput>
  filterType?: InputMaybe<StringFilterInput>
}

export type BlocklistEntryInput = {
  dataObjectType: DataObjectType
  entry: Scalars['String']['input']
  fieldName: Scalars['String']['input']
  filterType: BlocklistFilterType
}

export enum BlocklistEntryOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export enum BlocklistFilterType {
  Contains = 'CONTAINS',
  Domain = 'DOMAIN',
  EndsWith = 'ENDS_WITH',
  Equals = 'EQUALS',
  Subnet = 'SUBNET',
}

export type BooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>
}

export type BrandingInstance = {
  __typename?: 'BrandingInstance'
  whiteLogoUrl?: Maybe<Scalars['String']['output']>
}

export type BrandingInstanceInput = {
  whiteLogoUrl?: InputMaybe<Scalars['String']['input']>
}

export type BrandsCategories = {
  __typename?: 'BrandsCategories'
  brands?: Maybe<Array<MarketplaceFilterOption>>
  categories?: Maybe<Array<MarketplaceFilter>>
  currencies?: Maybe<Array<MarketplaceFilterOption>>
  shipTo?: Maybe<Array<MarketplaceFilterOption>>
  useCases?: Maybe<Array<MarketplaceFilterOption>>
}

export type Budget = {
  __typename?: 'Budget'
  amount: Scalars['Int']['output']
  duration: BudgetDuration
  mode: BudgetMode
}

export enum BudgetDuration {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY',
}

export type BudgetHistory = {
  __typename?: 'BudgetHistory'
  accountId: Scalars['ObjectId']['output']
  amount?: Maybe<Scalars['Int']['output']>
  billingAccountId?: Maybe<Scalars['ObjectId']['output']>
  created: ActionRecord
  currentBudget?: Maybe<Budget>
  drawdownRefundId?: Maybe<Scalars['ObjectId']['output']>
  id: Scalars['ObjectId']['output']
  newBudget?: Maybe<Budget>
  oldBudget?: Maybe<Budget>
  oldRemaining?: Maybe<Scalars['Int']['output']>
  relatedId?: Maybe<Scalars['ObjectId']['output']>
  relatedType?: Maybe<TransactionRelatedType>
  teamId?: Maybe<Scalars['ObjectId']['output']>
  type: BudgetHistoryType
  usageTransactionId?: Maybe<Scalars['ObjectId']['output']>
  userId?: Maybe<Scalars['ObjectId']['output']>
}

export type BudgetHistoryFilterInput = {
  accountId?: InputMaybe<ObjectIdFilterInput>
  teamId?: InputMaybe<ObjectIdFilterInput>
  type?: InputMaybe<StringFilterInput>
  userId?: InputMaybe<ObjectIdFilterInput>
}

export enum BudgetHistoryOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type BudgetHistorySearchResponse = {
  __typename?: 'BudgetHistorySearchResponse'
  data?: Maybe<Array<BudgetHistory>>
  resultsSummary: ResultsSummary
}

export enum BudgetHistoryType {
  Adjustment = 'ADJUSTMENT',
  Changed = 'CHANGED',
  RemainingIncreased = 'REMAINING_INCREASED',
  RemainingReduced = 'REMAINING_REDUCED',
  Reset = 'RESET',
}

export type BudgetInput = {
  amount: Scalars['Int']['input']
  duration: BudgetDuration
  mode: BudgetMode
}

export enum BudgetMode {
  PerUser = 'PER_USER',
  Pooled = 'POOLED',
  Unlimited = 'UNLIMITED',
}

export type BudgetRemaining = {
  __typename?: 'BudgetRemaining'
  applicableBudget?: Maybe<ApplicableBudget>
  budgetRemaining?: Maybe<Scalars['Int']['output']>
  totalAdjustments?: Maybe<Scalars['Int']['output']>
  totalSpend?: Maybe<Scalars['Int']['output']>
}

export type BudgetRemainingInput = {
  accountId: Scalars['String']['input']
  teamId?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['String']['input']>
}

export type BulkApprovedPostalUpdateInput = {
  attribution?: InputMaybe<AttributionInput>
  status?: InputMaybe<Status>
  teamIds?: InputMaybe<Array<Scalars['ObjectId']['input']>>
}

export type CachedCurrencyConfig = {
  __typename?: 'CachedCurrencyConfig'
  billingAccountEnabled: Scalars['Boolean']['output']
  currency: Currency
  productEnabled: Scalars['Boolean']['output']
}

export type CachedCurrencyConfigFilterInput = {
  billingAccountEnabled?: InputMaybe<Scalars['Boolean']['input']>
  productEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export enum CalendarProvider {
  Calendly = 'CALENDLY',
  ChiliPiper = 'CHILI_PIPER',
  Google = 'GOOGLE',
  Microsoft = 'MICROSOFT',
  Test = 'TEST',
}

export type Campaign = {
  __typename?: 'Campaign'
  approvedPostalId?: Maybe<Scalars['ObjectId']['output']>
  attribution?: Maybe<Attribution>
  backgroundTaskId?: Maybe<Scalars['ObjectId']['output']>
  campaignType?: Maybe<CampaignType>
  cancelDate?: Maybe<Scalars['Instant']['output']>
  completeDate?: Maybe<Scalars['Instant']['output']>
  created: ActionRecord
  deliveryEmail?: Maybe<Scalars['Boolean']['output']>
  deliveryEmailTemplateId?: Maybe<Scalars['ObjectId']['output']>
  designTemplate?: Maybe<DesignTemplate>
  emailSubjectLine?: Maybe<Scalars['String']['output']>
  errorDate?: Maybe<Scalars['Instant']['output']>
  formFieldList?: Maybe<Array<FormField>>
  giftEmailReminderSettings?: Maybe<GiftEmailReminderSettings>
  giftMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['ObjectId']['output']
  itemCustomizationInputs?: Maybe<Array<ItemCustomizationInput>>
  landingPageCustomization?: Maybe<LandingPageCustomization>
  meetingRequestSetting?: Maybe<MeetingRequestSetting>
  metrics?: Maybe<BasicMetrics>
  name: Scalars['String']['output']
  physicalMessage?: Maybe<Scalars['String']['output']>
  playbookId?: Maybe<Scalars['ObjectId']['output']>
  recipientEmailSettings?: Maybe<RecipientEmailSettings>
  scheduleDate?: Maybe<Scalars['Instant']['output']>
  sendAsContactOwner?: Maybe<Scalars['Boolean']['output']>
  sendAsUser?: Maybe<Scalars['ObjectId']['output']>
  spendAs?: Maybe<SpendAs>
  status: CampaignStatus
  teamId?: Maybe<Scalars['ObjectId']['output']>
  totalContacts?: Maybe<Scalars['Int']['output']>
  totalContactsAttempted?: Maybe<Scalars['Int']['output']>
  userId: Scalars['ObjectId']['output']
  userLink?: Maybe<UserLink>
  variantId?: Maybe<Scalars['ObjectId']['output']>
}

export type CampaignContact = {
  __typename?: 'CampaignContact'
  campaignId: Scalars['ObjectId']['output']
  campaignName?: Maybe<Scalars['String']['output']>
  postalFulfillmentId?: Maybe<Scalars['ObjectId']['output']>
  processed?: Maybe<Scalars['Instant']['output']>
  status?: Maybe<FulfillmentStatus>
}

export type CampaignContactFilterInput = {
  campaignId?: InputMaybe<ObjectIdFilterInput>
  contactId?: InputMaybe<ObjectIdFilterInput>
}

export type CampaignContactInput = {
  campaignId: Scalars['ObjectId']['input']
  campaignName: Scalars['String']['input']
}

export enum CampaignContactOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CampaignFilterInput = {
  campaignType?: InputMaybe<StringFilterInput>
  deliveryEmail?: InputMaybe<BooleanFilterInput>
  id?: InputMaybe<ObjectIdFilterInput>
  name?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  userId?: InputMaybe<ObjectIdFilterInput>
}

export type CampaignInput = {
  approvedPostalId?: InputMaybe<Scalars['ObjectId']['input']>
  attribution?: InputMaybe<AttributionInput>
  cancelDate?: InputMaybe<Scalars['Instant']['input']>
  deliveryEmail?: InputMaybe<Scalars['Boolean']['input']>
  deliveryEmailTemplateId?: InputMaybe<Scalars['ObjectId']['input']>
  designTemplate?: InputMaybe<DesignTemplateInput>
  emailSubjectLine?: InputMaybe<Scalars['String']['input']>
  errorDate?: InputMaybe<Scalars['Instant']['input']>
  formFieldList?: InputMaybe<Array<FormFieldInput>>
  giftEmailReminderSettings?: InputMaybe<GiftEmailReminderSettingsInput>
  giftMessage?: InputMaybe<Scalars['String']['input']>
  itemCustomizationInputs?: InputMaybe<Array<ItemCustomizationInputInput>>
  landingPageCustomization?: InputMaybe<LandingPageCustomizationInput>
  meetingRequestSetting?: InputMaybe<MeetingRequestSetting>
  name: Scalars['String']['input']
  physicalMessage?: InputMaybe<Scalars['String']['input']>
  playbookId?: InputMaybe<Scalars['ObjectId']['input']>
  recipientEmailSettings?: InputMaybe<RecipientEmailSettingsInput>
  scheduleDate?: InputMaybe<Scalars['Instant']['input']>
  sendAsContactOwner?: InputMaybe<Scalars['Boolean']['input']>
  sendAsUser?: InputMaybe<Scalars['ObjectId']['input']>
  spendAs?: InputMaybe<SpendAsInput>
  status: CampaignStatus
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  variantId?: InputMaybe<Scalars['ObjectId']['input']>
}

export enum CampaignOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export enum CampaignStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Error = 'ERROR',
  PausedBySystem = 'PAUSED_BY_SYSTEM',
  PausedByUser = 'PAUSED_BY_USER',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Scheduled = 'SCHEDULED',
}

export type CampaignStatusFilterInput = {
  campaignId?: InputMaybe<ObjectIdFilterInput>
  status?: InputMaybe<StringFilterInput>
}

export enum CampaignType {
  Abm = 'ABM',
  AbmClassic = 'ABM_CLASSIC',
  Engage = 'ENGAGE',
}

export type CanvasSize = {
  __typename?: 'CanvasSize'
  height: Scalars['Int']['output']
  width: Scalars['Int']['output']
}

export type CanvasSizeInput = {
  height: Scalars['Int']['input']
  width: Scalars['Int']['input']
}

export type CardPaymentMethod = {
  __typename?: 'CardPaymentMethod'
  brand?: Maybe<Scalars['String']['output']>
  expirationMonth: Scalars['Int']['output']
  expirationYear: Scalars['Int']['output']
  last4: Scalars['String']['output']
}

export type ChangePasswordInput = {
  currentPassword?: InputMaybe<Scalars['String']['input']>
  newPassword1: Scalars['String']['input']
  newPassword2: Scalars['String']['input']
}

export enum ChronoUnit {
  Days = 'DAYS',
  Hours = 'HOURS',
  Micros = 'MICROS',
  Millis = 'MILLIS',
  Minutes = 'MINUTES',
  Nano = 'NANO',
  Seconds = 'SECONDS',
}

export type ColumnInfoInput = {
  header: Scalars['String']['input']
  importRequestField?: InputMaybe<Scalars['String']['input']>
  index: Scalars['Int']['input']
  targetField: Scalars['String']['input']
  validationType?: InputMaybe<ValidationType>
}

export type CommonSendProperties = {
  __typename?: 'CommonSendProperties'
  approvedPostalId: Scalars['ObjectId']['output']
  formFieldList?: Maybe<Array<FormField>>
  giftEmailReminderSettings?: Maybe<GiftEmailReminderSettings>
  itemCustomizationInputs?: Maybe<Array<ItemCustomizationInput>>
  landingPageCustomization?: Maybe<LandingPageCustomization>
  meetingRequestSetting?: Maybe<MeetingRequestSetting>
  name?: Maybe<Scalars['String']['output']>
  physicalMessage?: Maybe<Scalars['String']['output']>
  recipientEmailSettings?: Maybe<RecipientEmailSettings>
  sendAsContactOwner?: Maybe<Scalars['Boolean']['output']>
  sendAsUser?: Maybe<Scalars['ObjectId']['output']>
  spendAs?: Maybe<SpendAs>
  variantId?: Maybe<Scalars['ObjectId']['output']>
}

export type CommonSendPropertiesInput = {
  approvedPostalId: Scalars['ObjectId']['input']
  formFieldList?: InputMaybe<Array<FormFieldInput>>
  giftEmailReminderSettings?: InputMaybe<GiftEmailReminderSettingsInput>
  itemCustomizationInputs?: InputMaybe<Array<ItemCustomizationInputInput>>
  landingPageCustomization?: InputMaybe<LandingPageCustomizationInput>
  meetingRequestSetting?: InputMaybe<MeetingRequestSetting>
  name?: InputMaybe<Scalars['String']['input']>
  physicalMessage?: InputMaybe<Scalars['String']['input']>
  recipientEmailSettings?: InputMaybe<RecipientEmailSettingsInput>
  sendAsContactOwner?: InputMaybe<Scalars['Boolean']['input']>
  sendAsUser?: InputMaybe<Scalars['ObjectId']['input']>
  spendAs?: InputMaybe<SpendAsInput>
  variantId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type ConnectedCalendar = {
  __typename?: 'ConnectedCalendar'
  busyByDefault: Scalars['Boolean']['output']
  calendarId: Scalars['String']['output']
  provider: CalendarProvider
  scheduleBuffer: Scalars['Int']['output']
  scheduleBufferUnits: ChronoUnit
  scheduleDayLeadTime: Scalars['Int']['output']
  scheduleHourLeadTime: Scalars['Int']['output']
  timeZone: Scalars['String']['output']
  userId: Scalars['ObjectId']['output']
}

export type ConnectedCalendarInput = {
  busyByDefault: Scalars['Boolean']['input']
  calendarId: Scalars['String']['input']
  provider: CalendarProvider
  scheduleBuffer: Scalars['Int']['input']
  scheduleBufferUnits: ChronoUnit
  scheduleDayLeadTime: Scalars['Int']['input']
  scheduleHourLeadTime: Scalars['Int']['input']
  timeZone: Scalars['String']['input']
  userId: Scalars['ObjectId']['input']
}

export type Connection = {
  __typename?: 'Connection'
  authorizationError?: Maybe<Scalars['String']['output']>
  authorizationStatus?: Maybe<Scalars['String']['output']>
  authorizedAt?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  provider: Scalars['String']['output']
}

export type Contact = {
  __typename?: 'Contact'
  addresses?: Maybe<Array<Maybe<Address>>>
  campaigns?: Maybe<Array<CampaignContact>>
  companyName?: Maybe<Scalars['String']['output']>
  created: ActionRecord
  deleted?: Maybe<ActionRecord>
  emailAddress?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  hasActivePlaybooks: Scalars['Boolean']['output']
  id: Scalars['ObjectId']['output']
  lastActivity?: Maybe<Scalars['Instant']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  lastSend?: Maybe<Scalars['Instant']['output']>
  lists?: Maybe<Array<ContactListMap>>
  mappings?: Maybe<Array<ExternalMapping>>
  metrics: BasicMetrics
  ownerId?: Maybe<Scalars['ObjectId']['output']>
  ownerLink?: Maybe<UserLink>
  phones?: Maybe<Array<Maybe<PhoneMap>>>
  playbooks?: Maybe<Array<PlaybookMap>>
  tags?: Maybe<Array<Scalars['String']['output']>>
  title?: Maybe<Scalars['String']['output']>
  type: ContactType
  updated?: Maybe<ActionRecord>
}

export enum ContactEventsRequestType {
  Cancellation = 'CANCELLATION',
  Contact = 'CONTACT',
  DateChange = 'DATE_CHANGE',
}

export type ContactEventsResponse = {
  __typename?: 'ContactEventsResponse'
  eventsEmailFailed: Scalars['Boolean']['output']
  vendorEmailFailed: Scalars['Boolean']['output']
}

export type ContactFilterInput = {
  addresses_city?: InputMaybe<StringFilterInput>
  addresses_postalCode?: InputMaybe<StringFilterInput>
  addresses_state?: InputMaybe<StringFilterInput>
  campaigns?: InputMaybe<CampaignStatusFilterInput>
  campaigns_campaignId?: InputMaybe<ObjectIdFilterInput>
  companyName?: InputMaybe<StringFilterInput>
  created?: InputMaybe<InstantFilterInput>
  emailAddress?: InputMaybe<StringFilterInput>
  firstName?: InputMaybe<StringFilterInput>
  hasActivePlaybooks?: InputMaybe<BooleanFilterInput>
  id?: InputMaybe<StringFilterInput>
  industry?: InputMaybe<StringFilterInput>
  lastActivity?: InputMaybe<InstantFilterInput>
  lastName?: InputMaybe<StringFilterInput>
  lastSend?: InputMaybe<InstantFilterInput>
  lists_listId?: InputMaybe<ObjectIdFilterInput>
  ownerId?: InputMaybe<ObjectIdFilterInput>
  playbooks_playbookDefinitionId?: InputMaybe<ObjectIdFilterInput>
  q?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<StringFilterInput>
  title?: InputMaybe<StringFilterInput>
}

export type ContactInput = {
  addresses?: InputMaybe<Array<InputMaybe<AddressInput>>>
  companyName?: InputMaybe<Scalars['String']['input']>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  id?: InputMaybe<Scalars['ObjectId']['input']>
  lastName: Scalars['String']['input']
  lists?: InputMaybe<Array<ContactListMapInput>>
  ownerId?: InputMaybe<Scalars['ObjectId']['input']>
  phones?: InputMaybe<Array<InputMaybe<PhoneMapInput>>>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  title?: InputMaybe<Scalars['String']['input']>
  type: ContactType
}

export type ContactList = {
  __typename?: 'ContactList'
  created: ActionRecord
  id: Scalars['ObjectId']['output']
  name: Scalars['String']['output']
  updated?: Maybe<ActionRecord>
}

export type ContactListInput = {
  name: Scalars['String']['input']
}

export type ContactListMap = {
  __typename?: 'ContactListMap'
  listId: Scalars['ObjectId']['output']
  name: Scalars['String']['output']
  userId: Scalars['ObjectId']['output']
}

export type ContactListMapInput = {
  listId: Scalars['ObjectId']['input']
  name: Scalars['String']['input']
  userId: Scalars['ObjectId']['input']
}

export type ContactSearchResponse = {
  __typename?: 'ContactSearchResponse'
  contacts?: Maybe<Array<Contact>>
  resultsSummary: ResultsSummary
}

export type ContactSearchResponseV2 = {
  __typename?: 'ContactSearchResponseV2'
  resultsSummary: ResultsSummary
  searchableContacts?: Maybe<Array<SearchableContact>>
}

export enum ContactType {
  Contact = 'CONTACT',
  Lead = 'LEAD',
  Other = 'OTHER',
}

export type ContactUnsubscribeFilterInput = {
  contactId?: InputMaybe<ObjectIdFilterInput>
  created?: InputMaybe<InstantFilterInput>
  emailAddress?: InputMaybe<Scalars['String']['input']>
}

export type ContactUpdateInput = {
  addresses?: InputMaybe<Array<InputMaybe<AddressInput>>>
  campaigns?: InputMaybe<Array<CampaignContactInput>>
  companyName?: InputMaybe<Scalars['String']['input']>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  lists?: InputMaybe<Array<ContactListMapInput>>
  ownerId?: InputMaybe<Scalars['ObjectId']['input']>
  phones?: InputMaybe<Array<InputMaybe<PhoneMapInput>>>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  title?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<ContactType>
}

export type ContactValidationIssue = {
  __typename?: 'ContactValidationIssue'
  contactId: Scalars['String']['output']
  contactName: Scalars['String']['output']
  validationIssues?: Maybe<Array<ValidationIssue>>
}

export type CorpCrmUser = {
  __typename?: 'CorpCrmUser'
  calendlyLink: Scalars['String']['output']
  emailAddress: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
}

export type CountAggregationResult = {
  __typename?: 'CountAggregationResult'
  count: Scalars['Int']['output']
  date: Scalars['Instant']['output']
}

export type CreateBillingAccountInput = {
  billingContactInfo?: InputMaybe<BillingContactInfoInput>
  country?: InputMaybe<Scalars['String']['input']>
  currency?: InputMaybe<Currency>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
}

export type CreateTeamInput = {
  billingAccountIds?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  budget?: InputMaybe<BudgetInput>
  department: Scalars['String']['input']
  name: Scalars['String']['input']
  parentTeamId?: InputMaybe<Scalars['ObjectId']['input']>
  settings?: InputMaybe<SettingsInput>
}

export type CreateTransferIntentInput = {
  amount: Scalars['Int']['input']
  comment: Scalars['String']['input']
  fromBillingAccountId: Scalars['ObjectId']['input']
  toBillingAccountId: Scalars['ObjectId']['input']
}

export type CreditCardPaymentInput = {
  cvc: Scalars['String']['input']
  expMonth: Scalars['Int']['input']
  expYear: Scalars['Int']['input']
  number: Scalars['String']['input']
}

export type CrmAccount = {
  __typename?: 'CrmAccount'
  accountId: Scalars['ObjectId']['output']
  addresses?: Maybe<Array<Maybe<Address>>>
  closedWonDollars?: Maybe<Scalars['Int']['output']>
  crmCreated?: Maybe<Scalars['Instant']['output']>
  crmIdentifier: Scalars['String']['output']
  crmOwner?: Maybe<Scalars['String']['output']>
  domain?: Maybe<Scalars['String']['output']>
  id: Scalars['ObjectId']['output']
  metrics?: Maybe<BasicMetrics>
  name?: Maybe<Scalars['String']['output']>
  ownerId?: Maybe<Scalars['String']['output']>
  parentId?: Maybe<Scalars['String']['output']>
  system?: Maybe<Scalars['String']['output']>
}

export enum CrmAccountPushFields {
  AcceptedItemsLast_30D = 'ACCEPTED_ITEMS_LAST_30D',
  EmailItemAcceptedLast_30D = 'EMAIL_ITEM_ACCEPTED_LAST_30D',
  EmailLandPageViewLast_30D = 'EMAIL_LAND_PAGE_VIEW_LAST_30D',
  EmailOpenedLast_30D = 'EMAIL_OPENED_LAST_30D',
  EmailSentLast_30D = 'EMAIL_SENT_LAST_30D',
  EventAcceptedLast_30D = 'EVENT_ACCEPTED_LAST_30D',
  EventSentLast_30D = 'EVENT_SENT_LAST_30D',
  MeetingsBookedLast_30D = 'MEETINGS_BOOKED_LAST_30D',
  MgclinkItemAcceptLast_30D = 'MGCLINK_ITEM_ACCEPT_LAST_30D',
  QrScannedLast_30D = 'QR_SCANNED_LAST_30D',
  RejectedItemsLast_30D = 'REJECTED_ITEMS_LAST_30D',
  SendsLast_30D = 'SENDS_LAST_30D',
  SpendLast_30D = 'SPEND_LAST_30D',
  StoreViewsLast_30D = 'STORE_VIEWS_LAST_30D',
  SwagstoreTransactionLast_30D = 'SWAGSTORE_TRANSACTION_LAST_30D',
  TotalSpend = 'TOTAL_SPEND',
}

export type CrmDataList = {
  __typename?: 'CrmDataList'
  items?: Maybe<Array<CrmDataListItem>>
}

export type CrmDataListItem = {
  __typename?: 'CrmDataListItem'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  values?: Maybe<Array<CrmDataListItem>>
}

export type CrmPostalCustomFieldResponse = {
  __typename?: 'CrmPostalCustomFieldResponse'
  items?: Maybe<Array<CrmPostalCustomFieldResponseItem>>
}

export type CrmPostalCustomFieldResponseItem = {
  __typename?: 'CrmPostalCustomFieldResponseItem'
  crmField: Scalars['String']['output']
  postalField: CrmAccountPushFields
  present: Scalars['Boolean']['output']
}

export type CrmReport = {
  __typename?: 'CrmReport'
  created?: Maybe<Scalars['Instant']['output']>
  properties?: Maybe<Scalars['Object']['output']>
  queryResults?: Maybe<Array<Maybe<QueryResults>>>
  reportType?: Maybe<CrmReportType>
  source?: Maybe<Scalars['String']['output']>
}

export enum CrmReportType {
  ClosedWonDeals = 'CLOSED_WON_DEALS',
  OpenPipeline = 'OPEN_PIPELINE',
  OpportunitiesByRepresentative = 'OPPORTUNITIES_BY_REPRESENTATIVE',
  PipelineInfluencedByDirectMailCampaigns = 'PIPELINE_INFLUENCED_BY_DIRECT_MAIL_CAMPAIGNS',
  WonRevenue = 'WON_REVENUE',
}

export type CrmUser = {
  __typename?: 'CrmUser'
  crmIdentifier: Scalars['String']['output']
  system: Scalars['String']['output']
}

export enum Currency {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sle = 'SLE',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ved = 'VED',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpf = 'XPF',
  Xsu = 'XSU',
  Xua = 'XUA',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL',
}

export enum CustomFieldDataListType {
  MetadataAccount = 'metadata_Account',
}

export enum CustomIntegrationNames {
  Marketo = 'marketo',
}

export enum DataListType {
  CampaignStatuses = 'campaignStatuses',
  Campaigns = 'campaigns',
  MetadataCustom = 'metadata_Custom',
  MetadataEvent = 'metadata_Event',
  MetadataOpportunity = 'metadata_Opportunity',
  MetadataTask = 'metadata_Task',
  Programs = 'programs',
}

export enum DataObjectType {
  Account = 'ACCOUNT',
  Activity = 'ACTIVITY',
  BillingAccount = 'BILLING_ACCOUNT',
  Campaign = 'CAMPAIGN',
  CampaignMember = 'CAMPAIGN_MEMBER',
  Collection = 'COLLECTION',
  Contact = 'CONTACT',
  ContactUnsubscribe = 'CONTACT_UNSUBSCRIBE',
  CrmAccount = 'CRM_ACCOUNT',
  Event = 'EVENT',
  Fulfillment = 'FULFILLMENT',
  Lead = 'LEAD',
  MagicLink = 'MAGIC_LINK',
  Opportunity = 'OPPORTUNITY',
  Other = 'OTHER',
  Playbook = 'PLAYBOOK',
  Postal = 'POSTAL',
  Product = 'PRODUCT',
  Trigger = 'TRIGGER',
  User = 'USER',
}

export type DayFreeBusy = {
  __typename?: 'DayFreeBusy'
  availableBlocks: Scalars['Int']['output']
  date: Scalars['Instant']['output']
  dateString: Scalars['String']['output']
}

export enum DefaultOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type DefaultPaymentMethod = {
  __typename?: 'DefaultPaymentMethod'
  autoReloadFailures: Scalars['Int']['output']
  autoReloadOk: Scalars['Boolean']['output']
  id?: Maybe<Scalars['String']['output']>
  lastFailedAutoReloadAttempt?: Maybe<Scalars['Instant']['output']>
  lastUnlocked?: Maybe<ActionRecord>
  partnerId?: Maybe<Scalars['String']['output']>
  paymentPartnerType?: Maybe<PaymentPartnerType>
  type?: Maybe<PaymentMethodType>
  updated?: Maybe<ActionRecord>
}

export type DesignTemplate = {
  __typename?: 'DesignTemplate'
  back?: Maybe<Array<ElementConfig>>
  dpi: Scalars['Int']['output']
  front?: Maybe<Array<ElementConfig>>
  outputSize: CanvasSize
}

export type DesignTemplateInput = {
  back?: InputMaybe<Array<ElementConfigInput>>
  dpi: Scalars['Int']['input']
  front?: InputMaybe<Array<ElementConfigInput>>
  outputSize: CanvasSizeInput
}

export type DisclaimerInfo = {
  __typename?: 'DisclaimerInfo'
  disclaimer?: Maybe<Scalars['String']['output']>
  enabled?: Maybe<Scalars['Boolean']['output']>
}

export type DisclaimerInfoInput = {
  disclaimer?: InputMaybe<Scalars['String']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type DropdownOptions = {
  __typename?: 'DropdownOptions'
  metaData?: Maybe<Scalars['Object']['output']>
  text?: Maybe<Scalars['String']['output']>
  value: Scalars['Object']['output']
}

export type ElementBoundary = {
  __typename?: 'ElementBoundary'
  maxX: Scalars['Int']['output']
  maxY: Scalars['Int']['output']
  minX: Scalars['Int']['output']
  minY: Scalars['Int']['output']
}

export type ElementBoundaryInput = {
  maxX: Scalars['Int']['input']
  maxY: Scalars['Int']['input']
  minX: Scalars['Int']['input']
  minY: Scalars['Int']['input']
}

export type ElementConfig = {
  __typename?: 'ElementConfig'
  boundary?: Maybe<ElementBoundary>
  customizable?: Maybe<Scalars['Boolean']['output']>
  description?: Maybe<Scalars['String']['output']>
  hidden?: Maybe<Scalars['Boolean']['output']>
  location: ElementLocation
  locked?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  noPdfOutput?: Maybe<Scalars['Boolean']['output']>
  resize?: Maybe<ElementResize>
  settings?: Maybe<Scalars['Object']['output']>
}

export type ElementConfigInput = {
  boundary?: InputMaybe<ElementBoundaryInput>
  customizable?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  hidden?: InputMaybe<Scalars['Boolean']['input']>
  location: ElementLocationInput
  locked?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  noPdfOutput?: InputMaybe<Scalars['Boolean']['input']>
  resize?: InputMaybe<ElementResizeInput>
  settings?: InputMaybe<Scalars['Object']['input']>
}

export type ElementLocation = {
  __typename?: 'ElementLocation'
  height: Scalars['Int']['output']
  width: Scalars['Int']['output']
  x: Scalars['Int']['output']
  y: Scalars['Int']['output']
}

export type ElementLocationInput = {
  height: Scalars['Int']['input']
  width: Scalars['Int']['input']
  x: Scalars['Int']['input']
  y: Scalars['Int']['input']
}

export type ElementResize = {
  __typename?: 'ElementResize'
  maxHeight: Scalars['Int']['output']
  maxWidth: Scalars['Int']['output']
  minHeight: Scalars['Int']['output']
  minWidth: Scalars['Int']['output']
}

export type ElementResizeInput = {
  maxHeight: Scalars['Int']['input']
  maxWidth: Scalars['Int']['input']
  minHeight: Scalars['Int']['input']
  minWidth: Scalars['Int']['input']
}

export enum EmailDigestFrequency {
  Daily = 'DAILY',
  Never = 'NEVER',
  Weekly = 'WEEKLY',
}

export type EmailDigestSettings = {
  __typename?: 'EmailDigestSettings'
  frequency?: Maybe<EmailDigestFrequency>
  itemLowStock?: Maybe<Scalars['Boolean']['output']>
  itemOutOfStock?: Maybe<Scalars['Boolean']['output']>
  itemRemoved?: Maybe<Scalars['Boolean']['output']>
  marketingUpdates?: Maybe<Scalars['Boolean']['output']>
  newCollectionApproved?: Maybe<Scalars['Boolean']['output']>
  newEventApproved?: Maybe<Scalars['Boolean']['output']>
  newItemApproved?: Maybe<Scalars['Boolean']['output']>
  ordersRequiringUserApproval?: Maybe<Scalars['Boolean']['output']>
  processingErrors?: Maybe<Scalars['Boolean']['output']>
}

export type EmailDigestSettingsInput = {
  frequency?: InputMaybe<EmailDigestFrequency>
  itemLowStock?: InputMaybe<Scalars['Boolean']['input']>
  itemOutOfStock?: InputMaybe<Scalars['Boolean']['input']>
  itemRemoved?: InputMaybe<Scalars['Boolean']['input']>
  marketingUpdates?: InputMaybe<Scalars['Boolean']['input']>
  newCollectionApproved?: InputMaybe<Scalars['Boolean']['input']>
  newEventApproved?: InputMaybe<Scalars['Boolean']['input']>
  newItemApproved?: InputMaybe<Scalars['Boolean']['input']>
  ordersRequiringUserApproval?: InputMaybe<Scalars['Boolean']['input']>
  processingErrors?: InputMaybe<Scalars['Boolean']['input']>
}

export type EmailPreviewRequestInput = {
  approvedPostalId?: InputMaybe<Scalars['ObjectId']['input']>
  approvedPostalVariantId?: InputMaybe<Scalars['ObjectId']['input']>
  contactId?: InputMaybe<Scalars['ObjectId']['input']>
  emailSubjectLine?: InputMaybe<Scalars['String']['input']>
  emailTemplateId?: InputMaybe<Scalars['ObjectId']['input']>
  giftMessage?: InputMaybe<Scalars['String']['input']>
  sendAsUser?: InputMaybe<Scalars['ObjectId']['input']>
}

export type EmailPreviewUrl = {
  __typename?: 'EmailPreviewUrl'
  emailName: Scalars['String']['output']
  emailType: Scalars['String']['output']
  htmlUrl: Scalars['String']['output']
  previewType: Scalars['String']['output']
  url?: Maybe<Scalars['String']['output']>
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate'
  contentList?: Maybe<Array<EmailTemplateContent>>
  defaultContent: EmailTemplateContent
  id: Scalars['ObjectId']['output']
  name: Scalars['String']['output']
}

export type EmailTemplateContent = {
  __typename?: 'EmailTemplateContent'
  html?: Maybe<Scalars['String']['output']>
  text?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type EmailTemplateFilterInput = {
  name?: InputMaybe<StringFilterInput>
}

export type Entitlement = {
  __typename?: 'Entitlement'
  created: ActionRecord
  id: Scalars['String']['output']
  role: Scalars['String']['output']
  type: EntitlementAccessType
}

export enum EntitlementAccessType {
  Admin = 'ADMIN',
  Billing = 'BILLING',
  Partner = 'PARTNER',
  Team = 'TEAM',
}

export enum EstimatedShippingTime {
  None = 'NONE',
  SevenDays = 'SEVEN_DAYS',
  TenDays = 'TEN_DAYS',
  TwoDays = 'TWO_DAYS',
}

export type EventEmailCustomization = {
  __typename?: 'EventEmailCustomization'
  body?: Maybe<Scalars['String']['output']>
  subjectLine?: Maybe<Scalars['String']['output']>
}

export type EventEmailCustomizationInput = {
  body?: InputMaybe<Scalars['String']['input']>
  subjectLine?: InputMaybe<Scalars['String']['input']>
}

export type EventFeeSettings = {
  __typename?: 'EventFeeSettings'
  flatFee?: Maybe<SpendAs>
}

export type EventFeeSettingsInput = {
  flatFee?: InputMaybe<SpendAsInput>
}

export type EventLimitResult = {
  __typename?: 'EventLimitResult'
  eventAllowed: Scalars['Boolean']['output']
  eventLimit: Scalars['Int']['output']
  eventLimitTimeFrame: Granularity
  eventLimitUsed: Scalars['Int']['output']
}

export type EventMapping = {
  __typename?: 'EventMapping'
  active: Scalars['Boolean']['output']
  status: Scalars['String']['output']
}

export type EventMappingInput = {
  active: Scalars['Boolean']['input']
  status: Scalars['String']['input']
}

export type EventPreview = {
  __typename?: 'EventPreview'
  budgetPassed?: Maybe<Scalars['Boolean']['output']>
  budgetRemaining?: Maybe<Scalars['Int']['output']>
  flatFees: OrderCost
  fundsPassed?: Maybe<Scalars['Boolean']['output']>
  fundsRemaining?: Maybe<Scalars['Int']['output']>
  minimumSpend?: Maybe<Scalars['Int']['output']>
  variableCost: OrderCost
}

export enum EventTier {
  Lite = 'LITE',
  None = 'NONE',
  Premium = 'PREMIUM',
}

export type ExtCampaignMapping = {
  __typename?: 'ExtCampaignMapping'
  accepted?: Maybe<EventMapping>
  bounced?: Maybe<EventMapping>
  campaignId: Scalars['String']['output']
  declined?: Maybe<EventMapping>
  delivered?: Maybe<EventMapping>
  donated?: Maybe<EventMapping>
  expired?: Maybe<EventMapping>
  failed?: Maybe<EventMapping>
  id: Scalars['ObjectId']['output']
  meetingBooked?: Maybe<EventMapping>
  opened?: Maybe<EventMapping>
  queued?: Maybe<EventMapping>
  setCampaignCost?: Maybe<Scalars['Boolean']['output']>
  shipped?: Maybe<EventMapping>
  system: Scalars['String']['output']
}

export type ExtCampaignMappingInput = {
  accepted?: InputMaybe<EventMappingInput>
  bounced?: InputMaybe<EventMappingInput>
  declined?: InputMaybe<EventMappingInput>
  delivered?: InputMaybe<EventMappingInput>
  donated?: InputMaybe<EventMappingInput>
  expired?: InputMaybe<EventMappingInput>
  failed?: InputMaybe<EventMappingInput>
  meetingBooked?: InputMaybe<EventMappingInput>
  opened?: InputMaybe<EventMappingInput>
  queued?: InputMaybe<EventMappingInput>
  setCampaignCost?: InputMaybe<Scalars['Boolean']['input']>
  shipped?: InputMaybe<EventMappingInput>
}

export type ExternalMapping = {
  __typename?: 'ExternalMapping'
  accountId?: Maybe<Scalars['String']['output']>
  companyId?: Maybe<Scalars['String']['output']>
  created?: Maybe<ActionRecord>
  id: Scalars['String']['output']
  owner?: Maybe<Scalars['String']['output']>
  profileUrl?: Maybe<Scalars['String']['output']>
  system: Scalars['String']['output']
}

export type ExternalSequence = {
  __typename?: 'ExternalSequence'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['Instant']['output']>
}

export type ExternalSequenceResponse = {
  __typename?: 'ExternalSequenceResponse'
  sequences?: Maybe<Array<ExternalSequence>>
}

export type ExternalSequenceStep = {
  __typename?: 'ExternalSequenceStep'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type ExternalSequenceStepResponse = {
  __typename?: 'ExternalSequenceStepResponse'
  steps?: Maybe<Array<ExternalSequenceStep>>
}

export enum FailureReason {
  Budget = 'BUDGET',
  ContactBadAddress = 'CONTACT_BAD_ADDRESS',
  Funds = 'FUNDS',
}

export type FavoriteItem = {
  __typename?: 'FavoriteItem'
  approvedPostalId: Scalars['ObjectId']['output']
  created: ActionRecord
  id: Scalars['ObjectId']['output']
  teamId?: Maybe<Scalars['ObjectId']['output']>
  userId: Scalars['ObjectId']['output']
  userLink?: Maybe<UserLink>
}

export type FavoriteItemFilterInput = {
  approvedPostalId?: InputMaybe<ObjectIdFilterInput>
}

export type FavoriteItemInput = {
  approvedPostalId: Scalars['ObjectId']['input']
}

export enum FieldType {
  Boolean = 'BOOLEAN',
  Datetime = 'DATETIME',
  Multiselect = 'MULTISELECT',
  Number = 'NUMBER',
  Select = 'SELECT',
  Text = 'TEXT',
}

export type FileDataMappingInput = {
  columnList?: InputMaybe<Array<ColumnInfoInput>>
  fileName: Scalars['String']['input']
  requestId: Scalars['String']['input']
  sampleData?: InputMaybe<Array<InputMaybe<Array<Scalars['String']['input']>>>>
  source: Scalars['String']['input']
  type?: InputMaybe<DataObjectType>
}

export enum FilterType {
  Bool = 'BOOL',
  Equals = 'EQUALS',
  Range = 'RANGE',
}

export type FiscalQuarterSetup = {
  __typename?: 'FiscalQuarterSetup'
  configuration?: Maybe<Scalars['String']['output']>
  method: QuarterCalculationMethod
}

export type FiscalQuarterSetupInput = {
  configuration?: InputMaybe<Scalars['String']['input']>
  method: QuarterCalculationMethod
}

export type ForeignExchangeInfo = {
  __typename?: 'ForeignExchangeInfo'
  apiProvider: Scalars['String']['output']
  rate: Scalars['String']['output']
  timestamp: Scalars['Instant']['output']
}

export type FormField = {
  __typename?: 'FormField'
  isRequired?: Maybe<Scalars['Boolean']['output']>
  max?: Maybe<Scalars['Object']['output']>
  min?: Maybe<Scalars['Object']['output']>
  name: Scalars['String']['output']
  options?: Maybe<Array<Scalars['Object']['output']>>
  type: FieldType
}

export type FormFieldInput = {
  isRequired?: InputMaybe<Scalars['Boolean']['input']>
  max?: InputMaybe<Scalars['Object']['input']>
  min?: InputMaybe<Scalars['Object']['input']>
  name: Scalars['String']['input']
  options?: InputMaybe<Array<Scalars['Object']['input']>>
  type: FieldType
}

export type FormFieldPair = {
  __typename?: 'FormFieldPair'
  field: Scalars['String']['output']
  value: Scalars['Object']['output']
}

export enum FormType {
  Concierge = 'CONCIERGE',
  IntegrationPartnerRequest = 'INTEGRATION_PARTNER_REQUEST',
  ProductRequest = 'PRODUCT_REQUEST',
  Warehouse = 'WAREHOUSE',
}

export enum FreeBusy {
  Busy = 'BUSY',
  Free = 'FREE',
}

export type Fulfillment = {
  __typename?: 'Fulfillment'
  actualCost?: Maybe<OrderCost>
  approvedPostalId?: Maybe<Scalars['ObjectId']['output']>
  cost?: Maybe<OrderCost>
  deliveryDate?: Maybe<Scalars['Instant']['output']>
  id?: Maybe<Scalars['String']['output']>
  marketplaceProductId?: Maybe<Scalars['ObjectId']['output']>
  quantity?: Maybe<Scalars['Int']['output']>
  status?: Maybe<ItemStatus>
  targetDeliveryDate?: Maybe<Scalars['Instant']['output']>
  trackingCompany?: Maybe<Scalars['String']['output']>
  trackingNumber?: Maybe<Scalars['String']['output']>
  trackingUrl?: Maybe<Scalars['String']['output']>
  variantId?: Maybe<Scalars['ObjectId']['output']>
}

export type FulfillmentHistory = {
  __typename?: 'FulfillmentHistory'
  created: Scalars['Instant']['output']
  currentLocation?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  status: FulfillmentStatus
}

export type FulfillmentMap = {
  __typename?: 'FulfillmentMap'
  bulkSendAvailable?: Maybe<Scalars['Boolean']['output']>
  displayPrice: Scalars['Int']['output']
  displaySubscriberPrice: Scalars['Int']['output']
  fulfillmentPartnerId: Scalars['ObjectId']['output']
  fulfillmentPartnerName: Scalars['String']['output']
  fulfillmentType: FulfillmentType
  inventory?: Maybe<Inventory>
  itemCode: Scalars['String']['output']
  maximumOrderableQuantity?: Maybe<Scalars['Int']['output']>
  price: Scalars['Int']['output']
  priceStructure?: Maybe<PriceStructure>
  shippingOptions?: Maybe<Array<ShippingOption>>
  supplierPartAuxiliaryId?: Maybe<Scalars['String']['output']>
  supplierPartId?: Maybe<Scalars['String']['output']>
}

export enum FulfillmentStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Delivered = 'DELIVERED',
  DeliveredAssumed = 'DELIVERED_ASSUMED',
  DeliveryError = 'DELIVERY_ERROR',
  None = 'NONE',
  OrderApproved = 'ORDER_APPROVED',
  OrderDenied = 'ORDER_DENIED',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  Pending = 'PENDING',
  PendingDelivery = 'PENDING_DELIVERY',
  PendingUserApproval = 'PENDING_USER_APPROVAL',
  Placed = 'PLACED',
  PostalAddressRefresh = 'POSTAL_ADDRESS_REFRESH',
  PostalDeliveryDonated = 'POSTAL_DELIVERY_DONATED',
  PostalDeliveryEmail = 'POSTAL_DELIVERY_EMAIL',
  PostalDeliveryEmailError = 'POSTAL_DELIVERY_EMAIL_ERROR',
  PostalDeliveryExpired = 'POSTAL_DELIVERY_EXPIRED',
  PostalDeliveryRejected = 'POSTAL_DELIVERY_REJECTED',
  Processing = 'PROCESSING',
  ProcessingError = 'PROCESSING_ERROR',
  ProcessingInternal = 'PROCESSING_INTERNAL',
  ServerErrorBackoff = 'SERVER_ERROR_BACKOFF',
  Shipped = 'SHIPPED',
  Waitlist = 'WAITLIST',
}

export enum FulfillmentType {
  Donation = 'DONATION',
  Electronic = 'ELECTRONIC',
  EventFee = 'EVENT_FEE',
  Physical = 'PHYSICAL',
}

export type GenericFilterInput = {
  filter?: InputMaybe<StringFilterInput>
  key: Scalars['String']['input']
}

export type GiftEmailReminderSettings = {
  __typename?: 'GiftEmailReminderSettings'
  expirationSoonReminders?: Maybe<Array<Scalars['Int']['output']>>
}

export type GiftEmailReminderSettingsInput = {
  expirationSoonReminders?: InputMaybe<Array<Scalars['Int']['input']>>
}

export enum Granularity {
  All = 'ALL',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY',
}

export enum GroupBy {
  All = 'ALL',
  CrmAccount = 'CRM_ACCOUNT',
  Postal = 'POSTAL',
  Team = 'TEAM',
  User = 'USER',
}

export type ImageReference = {
  __typename?: 'ImageReference'
  description?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type ImageReferenceInput = {
  description?: InputMaybe<Scalars['String']['input']>
  url: Scalars['String']['input']
}

export type InitialBalanceNetSuiteSyncInfo = {
  __typename?: 'InitialBalanceNetSuiteSyncInfo'
  initializedAt?: Maybe<Scalars['Instant']['output']>
}

export type InstantFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Instant']['input']>>>
  eq?: InputMaybe<Scalars['Instant']['input']>
  exists?: InputMaybe<Scalars['Boolean']['input']>
  ge?: InputMaybe<Scalars['Instant']['input']>
  gt?: InputMaybe<Scalars['Instant']['input']>
  le?: InputMaybe<Scalars['Instant']['input']>
  lt?: InputMaybe<Scalars['Instant']['input']>
}

export type IntFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  eq?: InputMaybe<Scalars['Int']['input']>
  ge?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  le?: InputMaybe<Scalars['Int']['input']>
  lt?: InputMaybe<Scalars['Int']['input']>
}

export type IntegerInputFilter = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  eq?: InputMaybe<Scalars['Int']['input']>
  ge?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  le?: InputMaybe<Scalars['Int']['input']>
  lt?: InputMaybe<Scalars['Int']['input']>
  ne?: InputMaybe<Scalars['Int']['input']>
}

export type IntegrationConnection = {
  __typename?: 'IntegrationConnection'
  accountId?: Maybe<Scalars['ObjectId']['output']>
  authenticationFulfillmentPartnerId?: Maybe<Scalars['ObjectId']['output']>
  authenticationUserId?: Maybe<Scalars['ObjectId']['output']>
  system?: Maybe<Scalars['String']['output']>
}

export type IntegrationConnectionFilterInput = {
  system?: InputMaybe<StringFilterInput>
}

export type IntegrationCustomField = {
  __typename?: 'IntegrationCustomField'
  field: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type IntegrationCustomFieldInput = {
  field: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type IntegrationDataMapping = {
  __typename?: 'IntegrationDataMapping'
  externalFieldName?: Maybe<Scalars['String']['output']>
  postalIoFieldName: Scalars['String']['output']
  remap?: Maybe<Scalars['Object']['output']>
  staticValue?: Maybe<Scalars['String']['output']>
}

export type IntegrationDataMappingInput = {
  externalFieldName?: InputMaybe<Scalars['String']['input']>
  postalIoFieldName: Scalars['String']['input']
  remap?: InputMaybe<Scalars['Object']['input']>
  staticValue?: InputMaybe<Scalars['String']['input']>
}

export type IntegrationSync = {
  __typename?: 'IntegrationSync'
  createCustomFields?: Maybe<Array<IntegrationCustomField>>
  createEnabled?: Maybe<Scalars['Boolean']['output']>
  defaultLeadSourceValue?: Maybe<Scalars['String']['output']>
  id: Scalars['ObjectId']['output']
  lastRun?: Maybe<Scalars['Instant']['output']>
  leadSourceFieldName?: Maybe<Scalars['String']['output']>
  mappings?: Maybe<Array<IntegrationDataMapping>>
  nextRun?: Maybe<Scalars['Instant']['output']>
  objectType: DataObjectType
  pollingTimeMins?: Maybe<Scalars['Int']['output']>
  pushMappings?: Maybe<Array<IntegrationDataMapping>>
  status: Status
  syncTime?: Maybe<Scalars['Instant']['output']>
  system: Scalars['String']['output']
  workStatus?: Maybe<BackgroundTaskStatus>
}

export type IntegrationSyncFilterInput = {
  objectType?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  system?: InputMaybe<StringFilterInput>
}

export type IntegrationSyncInput = {
  createCustomFields?: InputMaybe<Array<IntegrationCustomFieldInput>>
  createEnabled?: InputMaybe<Scalars['Boolean']['input']>
  defaultLeadSourceValue?: InputMaybe<Scalars['String']['input']>
  leadSourceFieldName?: InputMaybe<Scalars['String']['input']>
  mappings?: InputMaybe<Array<IntegrationDataMappingInput>>
  pushMappings?: InputMaybe<Array<IntegrationDataMappingInput>>
}

export type IntegrationTrigger = {
  __typename?: 'IntegrationTrigger'
  action: TriggerAction
  approvedPostalId?: Maybe<Scalars['ObjectId']['output']>
  approvedPostalVariantId?: Maybe<Scalars['ObjectId']['output']>
  attribution?: Maybe<Attribution>
  config?: Maybe<TriggerConfiguration>
  created: ActionRecord
  deliveryEmail?: Maybe<Scalars['Boolean']['output']>
  deliveryEmailTemplateId?: Maybe<Scalars['ObjectId']['output']>
  emailSubjectLine?: Maybe<Scalars['String']['output']>
  filters?: Maybe<Array<TriggerFilter>>
  formFieldList?: Maybe<Array<FormField>>
  giftEmailReminderSettings?: Maybe<GiftEmailReminderSettings>
  giftMessage?: Maybe<Scalars['String']['output']>
  hitCount: Scalars['Int']['output']
  id: Scalars['ObjectId']['output']
  itemCustomizationInputs?: Maybe<Array<ItemCustomizationInput>>
  landingPageCustomization?: Maybe<LandingPageCustomization>
  meetingRequestSetting?: Maybe<MeetingRequestSetting>
  name: Scalars['String']['output']
  physicalMessage?: Maybe<Scalars['String']['output']>
  playbookDefinitionId?: Maybe<Scalars['ObjectId']['output']>
  recipientEmailSettings?: Maybe<RecipientEmailSettings>
  sendAsContactOwner?: Maybe<Scalars['Boolean']['output']>
  sendAsUser?: Maybe<Scalars['ObjectId']['output']>
  spendAs?: Maybe<SpendAs>
  status: Status
  systemName: Scalars['String']['output']
  teamId?: Maybe<Scalars['ObjectId']['output']>
  userId: Scalars['ObjectId']['output']
  userLink?: Maybe<UserLink>
}

export type IntegrationTriggerFilterInput = {
  name?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  systemName?: InputMaybe<StringFilterInput>
  userId?: InputMaybe<ObjectIdFilterInput>
}

export type IntegrationTriggerInput = {
  action: TriggerAction
  approvedPostalId?: InputMaybe<Scalars['ObjectId']['input']>
  approvedPostalVariantId?: InputMaybe<Scalars['ObjectId']['input']>
  attribution?: InputMaybe<AttributionInput>
  config?: InputMaybe<TriggerConfigurationInput>
  deliveryEmail?: InputMaybe<Scalars['Boolean']['input']>
  deliveryEmailTemplateId?: InputMaybe<Scalars['ObjectId']['input']>
  emailSubjectLine?: InputMaybe<Scalars['String']['input']>
  filters?: InputMaybe<Array<TriggerFilterInput>>
  formFieldList?: InputMaybe<Array<FormFieldInput>>
  giftEmailReminderSettings?: InputMaybe<GiftEmailReminderSettingsInput>
  giftMessage?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ObjectId']['input']>
  itemCustomizationInputs?: InputMaybe<Array<ItemCustomizationInputInput>>
  landingPageCustomization?: InputMaybe<LandingPageCustomizationInput>
  meetingRequestSetting?: InputMaybe<MeetingRequestSetting>
  name: Scalars['String']['input']
  physicalMessage?: InputMaybe<Scalars['String']['input']>
  playbookDefinitionId?: InputMaybe<Scalars['ObjectId']['input']>
  recipientEmailSettings?: InputMaybe<RecipientEmailSettingsInput>
  sendAsContactOwner?: InputMaybe<Scalars['Boolean']['input']>
  sendAsUser?: InputMaybe<Scalars['ObjectId']['input']>
  spendAs?: InputMaybe<SpendAsInput>
  status: Status
  systemName: Scalars['String']['input']
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}

export enum IntegrationTriggerOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  HitCountAsc = 'hitCount_ASC',
  HitCountDesc = 'hitCount_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type IntegrationTriggerResult = {
  __typename?: 'IntegrationTriggerResult'
  action: TriggerAction
  contactId?: Maybe<Scalars['ObjectId']['output']>
  contactName?: Maybe<Scalars['String']['output']>
  created: ActionRecord
  fulfillmentId?: Maybe<Scalars['ObjectId']['output']>
  id: Scalars['ObjectId']['output']
  notes?: Maybe<Scalars['String']['output']>
  playbookInstanceId?: Maybe<Scalars['ObjectId']['output']>
  playbookName?: Maybe<Scalars['String']['output']>
  postalName?: Maybe<Scalars['String']['output']>
  result: Result
  systemName: Scalars['String']['output']
  teamId?: Maybe<Scalars['ObjectId']['output']>
  triggerId: Scalars['ObjectId']['output']
  triggerName: Scalars['String']['output']
  userId: Scalars['ObjectId']['output']
  userLink?: Maybe<UserLink>
}

export type IntegrationTriggerResultFilterInput = {
  contactName?: InputMaybe<StringFilterInput>
  created?: InputMaybe<InstantFilterInput>
  result?: InputMaybe<StringFilterInput>
  system?: InputMaybe<StringFilterInput>
  triggerId?: InputMaybe<ObjectIdFilterInput>
}

export type Inventory = {
  __typename?: 'Inventory'
  available?: Maybe<Scalars['Int']['output']>
  ordered?: Maybe<Scalars['Int']['output']>
}

export type Invite = {
  __typename?: 'Invite'
  created: ActionRecord
  emailAddress: Scalars['String']['output']
  id: Scalars['ObjectId']['output']
  invitedByUserName?: Maybe<Scalars['String']['output']>
  roles?: Maybe<Array<Scalars['String']['output']>>
  status: InviteStatus
  teamId?: Maybe<Scalars['ObjectId']['output']>
  teamName?: Maybe<Scalars['String']['output']>
}

export type InviteFilterInput = {
  emailAddress?: InputMaybe<StringFilterInput>
  id?: InputMaybe<StringFilterInput>
  invitedByUserId?: InputMaybe<ObjectIdFilterInput>
  invitedByUserName?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  teamId?: InputMaybe<ObjectIdFilterInput>
}

export enum InviteOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type InviteResult = {
  __typename?: 'InviteResult'
  emailAddress: Scalars['String']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  invite?: Maybe<Invite>
  result: Result
}

export enum InviteStatus {
  Complete = 'COMPLETE',
  Pending = 'PENDING',
}

export type InviteUserInput = {
  emailAddresses?: InputMaybe<Array<Scalars['String']['input']>>
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type IpLookupTimeZone = {
  __typename?: 'IpLookupTimeZone'
  code?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
}

export type ItemCustomization = {
  __typename?: 'ItemCustomization'
  characterLimit?: Maybe<Scalars['Int']['output']>
  customizableByRecipient?: Maybe<Scalars['Boolean']['output']>
  customizableBySender?: Maybe<Scalars['Boolean']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  fileTypesAccepted?: Maybe<Array<Scalars['String']['output']>>
  heightRecommendation?: Maybe<Scalars['Int']['output']>
  id: Scalars['String']['output']
  required?: Maybe<Scalars['Boolean']['output']>
  type?: Maybe<ItemCustomizationType>
  value?: Maybe<Scalars['Object']['output']>
  variantId?: Maybe<Scalars['ObjectId']['output']>
  widthRecommendation?: Maybe<Scalars['Int']['output']>
}

export type ItemCustomizationInput = {
  __typename?: 'ItemCustomizationInput'
  id: Scalars['String']['output']
  value: Scalars['Object']['output']
  variantId?: Maybe<Scalars['ObjectId']['output']>
}

export type ItemCustomizationInputInput = {
  id: Scalars['String']['input']
  value: Scalars['Object']['input']
  variantId?: InputMaybe<Scalars['ObjectId']['input']>
}

export enum ItemCustomizationType {
  File = 'FILE',
  Image = 'IMAGE',
  Location = 'LOCATION',
  Text = 'TEXT',
}

export enum ItemStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Delivered = 'DELIVERED',
  DeliveredAssumed = 'DELIVERED_ASSUMED',
  DeliveryFailed = 'DELIVERY_FAILED',
  Failed = 'FAILED',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  Pending = 'PENDING',
  Placed = 'PLACED',
  ProcessingInternal = 'PROCESSING_INTERNAL',
  Shipped = 'SHIPPED',
}

export enum ItemType {
  AccountCollection = 'ACCOUNT_COLLECTION',
  AccountEvent = 'ACCOUNT_EVENT',
  AccountProduct = 'ACCOUNT_PRODUCT',
  CollectionProduct = 'COLLECTION_PRODUCT',
  Draft = 'DRAFT',
  MarketplaceEvent = 'MARKETPLACE_EVENT',
  MarketplaceEventPrivate = 'MARKETPLACE_EVENT_PRIVATE',
  MarketplaceProduct = 'MARKETPLACE_PRODUCT',
  MarketplaceProductPrivate = 'MARKETPLACE_PRODUCT_PRIVATE',
  StoreProduct = 'STORE_PRODUCT',
  UserCollection = 'USER_COLLECTION',
  UserFavorite = 'USER_FAVORITE',
  UserFavoriteCollection = 'USER_FAVORITE_COLLECTION',
  UserFavoriteEvent = 'USER_FAVORITE_EVENT',
  UserFavoritePostal = 'USER_FAVORITE_POSTAL',
}

export type LandingPageCustomization = {
  __typename?: 'LandingPageCustomization'
  body?: Maybe<Scalars['String']['output']>
  headerText?: Maybe<Scalars['String']['output']>
  includeHeadshot?: Maybe<Scalars['Boolean']['output']>
  includeSenderName?: Maybe<Scalars['Boolean']['output']>
}

export type LandingPageCustomizationInput = {
  body?: InputMaybe<Scalars['String']['input']>
  headerText?: InputMaybe<Scalars['String']['input']>
  includeHeadshot?: InputMaybe<Scalars['Boolean']['input']>
  includeSenderName?: InputMaybe<Scalars['Boolean']['input']>
}

export type Ledger = {
  __typename?: 'Ledger'
  accountId: Scalars['ObjectId']['output']
  amount: Scalars['Int']['output']
  billingAccountId: Scalars['ObjectId']['output']
  created: ActionRecord
  currentBalance: Scalars['Int']['output']
  id: Scalars['ObjectId']['output']
  memo?: Maybe<Scalars['String']['output']>
  previousBalance: Scalars['Int']['output']
  relatedId?: Maybe<Scalars['ObjectId']['output']>
  relatedType?: Maybe<LedgerRelatedType>
  type: LedgerRecordType
  userId?: Maybe<Scalars['ObjectId']['output']>
  userLink?: Maybe<UserLink>
}

export type LedgerFilterInput = {
  accountId?: InputMaybe<ObjectIdFilterInput>
  billingAccountId?: InputMaybe<ObjectIdFilterInput>
  created?: InputMaybe<InstantFilterInput>
  id?: InputMaybe<ObjectIdFilterInput>
  relatedId?: InputMaybe<ObjectIdFilterInput>
  type?: InputMaybe<StringFilterInput>
  userId?: InputMaybe<ObjectIdFilterInput>
}

export enum LedgerOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum LedgerRecordType {
  CustomerDeposit = 'CUSTOMER_DEPOSIT',
  CustomerTransfer = 'CUSTOMER_TRANSFER',
  ExternalWithdrawal = 'EXTERNAL_WITHDRAWAL',
  ManualAdjustment = 'MANUAL_ADJUSTMENT',
  PostalDeposit = 'POSTAL_DEPOSIT',
  RefundDeposit = 'REFUND_DEPOSIT',
  SalesWithdrawal = 'SALES_WITHDRAWAL',
}

export enum LedgerRelatedType {
  AddFundsIntent = 'ADD_FUNDS_INTENT',
  ApprovedPostal = 'APPROVED_POSTAL',
  CreditFundsIntent = 'CREDIT_FUNDS_INTENT',
  PostalFulfillment = 'POSTAL_FULFILLMENT',
  TransferFundsIntent = 'TRANSFER_FUNDS_INTENT',
}

export type LinkApprovalSettings = {
  __typename?: 'LinkApprovalSettings'
  autoAction: AutoAction
  autoActionDaysAfter: Scalars['Int']['output']
}

export type LinkApprovalSettingsInput = {
  autoAction: AutoAction
  autoActionDaysAfter: Scalars['Int']['input']
}

export enum LinkType {
  Account = 'ACCOUNT',
  Partner = 'PARTNER',
  Team = 'TEAM',
  User = 'USER',
}

export type LocationData = {
  __typename?: 'LocationData'
  countryCode?: Maybe<Scalars['String']['output']>
  countryCode3?: Maybe<Scalars['String']['output']>
  countryName?: Maybe<Scalars['String']['output']>
  regionCode?: Maybe<Scalars['String']['output']>
  regionName?: Maybe<Scalars['String']['output']>
  timeZone?: Maybe<IpLookupTimeZone>
}

export type MagicEvent = {
  __typename?: 'MagicEvent'
  eventLengthMinutes: Scalars['Int']['output']
  maximumAttendees?: Maybe<Scalars['Int']['output']>
  minimumAttendees?: Maybe<Scalars['Int']['output']>
  requirements?: Maybe<Array<Requirements>>
  talentCompany?: Maybe<Scalars['String']['output']>
  talentEmailAddress: Scalars['String']['output']
  talentName: Scalars['String']['output']
  talentPhoneNumber: Scalars['String']['output']
}

export type MagicEventInstance = {
  __typename?: 'MagicEventInstance'
  calendarRequestedDate?: Maybe<Scalars['Instant']['output']>
  cancellationCustomization?: Maybe<EventEmailCustomization>
  confirmationCustomization?: Maybe<EventEmailCustomization>
  dateTimeChangedCustomization?: Maybe<EventEmailCustomization>
  eventDateTime?: Maybe<Scalars['Instant']['output']>
  eventLengthMinutes?: Maybe<Scalars['Int']['output']>
  lastRegistrationDateTime?: Maybe<Scalars['Instant']['output']>
  meetingLink?: Maybe<Scalars['String']['output']>
  meetingLinkChangedCustomization?: Maybe<EventEmailCustomization>
  reminderTodayCustomization?: Maybe<EventEmailCustomization>
  reminderTomorrowCustomization?: Maybe<EventEmailCustomization>
  requestedAttendeeCount: Scalars['Int']['output']
  requestedByEmail: Scalars['String']['output']
  requestedByMessage: Scalars['String']['output']
  requestedByName: Scalars['String']['output']
  requestedByPhone: Scalars['String']['output']
  requestedDates?: Maybe<Array<Scalars['Instant']['output']>>
  sendCancelledAlert?: Maybe<Scalars['Boolean']['output']>
  sendDateTimeChanged?: Maybe<Scalars['Boolean']['output']>
  sendDeliveredAlert?: Maybe<Scalars['Boolean']['output']>
  sendEmailConfirmation?: Maybe<Scalars['Boolean']['output']>
  sendInviteExpiringAlert?: Maybe<Scalars['Boolean']['output']>
  sendMeetingLinkChanged?: Maybe<Scalars['Boolean']['output']>
  sendReminderDayBefore?: Maybe<Scalars['Boolean']['output']>
  sendReminderDayOf?: Maybe<Scalars['Boolean']['output']>
  sendShippedAlert?: Maybe<Scalars['Boolean']['output']>
  spotsRemaining?: Maybe<Scalars['Int']['output']>
  status: MagicEventStatus
}

export type MagicEventInstanceInput = {
  calendarRequestedDate?: InputMaybe<Scalars['Instant']['input']>
  cancellationCustomization?: InputMaybe<EventEmailCustomizationInput>
  confirmationCustomization?: InputMaybe<EventEmailCustomizationInput>
  dateTimeChangedCustomization?: InputMaybe<EventEmailCustomizationInput>
  meetingLink?: InputMaybe<Scalars['String']['input']>
  meetingLinkChangedCustomization?: InputMaybe<EventEmailCustomizationInput>
  reminderTodayCustomization?: InputMaybe<EventEmailCustomizationInput>
  reminderTomorrowCustomization?: InputMaybe<EventEmailCustomizationInput>
  requestedAttendeeCount: Scalars['Int']['input']
  requestedByEmail: Scalars['String']['input']
  requestedByMessage: Scalars['String']['input']
  requestedByName: Scalars['String']['input']
  requestedByPhone: Scalars['String']['input']
  requestedDates?: InputMaybe<Array<Scalars['Instant']['input']>>
  sendCancelledAlert?: InputMaybe<Scalars['Boolean']['input']>
  sendDateTimeChanged?: InputMaybe<Scalars['Boolean']['input']>
  sendDeliveredAlert?: InputMaybe<Scalars['Boolean']['input']>
  sendEmailConfirmation?: InputMaybe<Scalars['Boolean']['input']>
  sendInviteExpiringAlert?: InputMaybe<Scalars['Boolean']['input']>
  sendMeetingLinkChanged?: InputMaybe<Scalars['Boolean']['input']>
  sendReminderDayBefore?: InputMaybe<Scalars['Boolean']['input']>
  sendReminderDayOf?: InputMaybe<Scalars['Boolean']['input']>
  sendShippedAlert?: InputMaybe<Scalars['Boolean']['input']>
}

export enum MagicEventStatus {
  AcceptingInvites = 'ACCEPTING_INVITES',
  Cancelled = 'CANCELLED',
  CancelRequested = 'CANCEL_REQUESTED',
  Complete = 'COMPLETE',
  ConfirmedBillingIssue = 'CONFIRMED_BILLING_ISSUE',
  ConfirmedNeedsData = 'CONFIRMED_NEEDS_DATA',
  PendingConfirmation = 'PENDING_CONFIRMATION',
  RegistrationClosed = 'REGISTRATION_CLOSED',
}

export type MagicLink = {
  __typename?: 'MagicLink'
  approvedPostalId: Scalars['ObjectId']['output']
  attribution?: Maybe<Attribution>
  created: ActionRecord
  distributionChannel?: Maybe<Scalars['String']['output']>
  expirationDate?: Maybe<Scalars['Instant']['output']>
  formFieldList?: Maybe<Array<FormField>>
  id: Scalars['ObjectId']['output']
  itemCustomizationInputs?: Maybe<Array<ItemCustomizationInput>>
  landingPageCustomization?: Maybe<LandingPageCustomization>
  lastExecution?: Maybe<Scalars['Instant']['output']>
  lastView?: Maybe<Scalars['Instant']['output']>
  linkUrl: Scalars['String']['output']
  maxExecutions?: Maybe<Scalars['Int']['output']>
  meetingRequestSetting?: Maybe<MeetingRequestSetting>
  message: Scalars['String']['output']
  metrics: BasicMetrics
  name: Scalars['String']['output']
  physicalMessage?: Maybe<Scalars['String']['output']>
  recipientEmailSettings?: Maybe<RecipientEmailSettings>
  requiresApproval?: Maybe<Scalars['Boolean']['output']>
  requiresUuid?: Maybe<Scalars['Boolean']['output']>
  sendAsContactOwner?: Maybe<Scalars['Boolean']['output']>
  sendAsUser?: Maybe<Scalars['ObjectId']['output']>
  spendAs?: Maybe<SpendAs>
  status: MagicLinkStatus
  teamId?: Maybe<Scalars['ObjectId']['output']>
  updated?: Maybe<ActionRecord>
  userLink?: Maybe<UserLink>
  variantId: Scalars['ObjectId']['output']
}

export type MagicLinkFilterInput = {
  approvedPostalId?: InputMaybe<ObjectIdFilterInput>
  id?: InputMaybe<ObjectIdFilterInput>
  name?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  userId?: InputMaybe<ObjectIdFilterInput>
}

export type MagicLinkInput = {
  approvedPostalId: Scalars['ObjectId']['input']
  attribution?: InputMaybe<AttributionInput>
  deliveryEmailTemplateId?: InputMaybe<Scalars['ObjectId']['input']>
  distributionChannel?: InputMaybe<Scalars['String']['input']>
  expirationDate?: InputMaybe<Scalars['Instant']['input']>
  formFieldList?: InputMaybe<Array<FormFieldInput>>
  itemCustomizationInputs?: InputMaybe<Array<ItemCustomizationInputInput>>
  landingPageCustomization?: InputMaybe<LandingPageCustomizationInput>
  maxExecutions?: InputMaybe<Scalars['Int']['input']>
  meetingRequestSetting?: InputMaybe<MeetingRequestSetting>
  message: Scalars['String']['input']
  name: Scalars['String']['input']
  physicalMessage?: InputMaybe<Scalars['String']['input']>
  recipientEmailSettings?: InputMaybe<RecipientEmailSettingsInput>
  requiresApproval?: InputMaybe<Scalars['Boolean']['input']>
  requiresUuid?: InputMaybe<Scalars['Boolean']['input']>
  sendAsContactOwner?: InputMaybe<Scalars['Boolean']['input']>
  sendAsUser?: InputMaybe<Scalars['ObjectId']['input']>
  spendAs?: InputMaybe<SpendAsInput>
  status: MagicLinkStatus
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  variantId: Scalars['ObjectId']['input']
}

export enum MagicLinkOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  ExpirationDateAsc = 'expirationDate_ASC',
  ExpirationDateDesc = 'expirationDate_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MetricsLinkExecutionsAsc = 'metrics_linkExecutions_ASC',
  MetricsLinkExecutionsDesc = 'metrics_linkExecutions_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RequiresApprovalAsc = 'requiresApproval_ASC',
  RequiresApprovalDesc = 'requiresApproval_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UserLinkFullNameAsc = 'userLink_fullName_ASC',
  UserLinkFullNameDesc = 'userLink_fullName_DESC',
}

export enum MagicLinkStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
}

export type MagicLinkUpdateInput = {
  approvedPostalId?: InputMaybe<Scalars['ObjectId']['input']>
  attribution?: InputMaybe<AttributionInput>
  deliveryEmailTemplateId?: InputMaybe<Scalars['ObjectId']['input']>
  distributionChannel?: InputMaybe<Scalars['String']['input']>
  expirationDate?: InputMaybe<Scalars['Instant']['input']>
  formFieldList?: InputMaybe<Array<FormFieldInput>>
  itemCustomizationInputs?: InputMaybe<Array<ItemCustomizationInputInput>>
  landingPageCustomization?: InputMaybe<LandingPageCustomizationInput>
  maxExecutions?: InputMaybe<Scalars['Int']['input']>
  meetingRequestSetting?: InputMaybe<MeetingRequestSetting>
  message?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  physicalMessage?: InputMaybe<Scalars['String']['input']>
  recipientEmailSettings?: InputMaybe<RecipientEmailSettingsInput>
  requiresApproval?: InputMaybe<Scalars['Boolean']['input']>
  sendAsContactOwner?: InputMaybe<Scalars['Boolean']['input']>
  sendAsUser?: InputMaybe<Scalars['ObjectId']['input']>
  spendAs?: InputMaybe<SpendAsInput>
  status?: InputMaybe<MagicLinkStatus>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  variantId?: InputMaybe<Scalars['ObjectId']['input']>
}

export enum MarketplaceCategory {
  Alcohol = 'ALCOHOL',
  DirectMail = 'DIRECT_MAIL',
  Flowers = 'FLOWERS',
  GiftCards = 'GIFT_CARDS',
  Other = 'OTHER',
  Swag = 'SWAG',
}

export type MarketplaceFilter = {
  __typename?: 'MarketplaceFilter'
  name: Scalars['String']['output']
  options?: Maybe<Array<MarketplaceFilterOption>>
}

export type MarketplaceFilterOption = {
  __typename?: 'MarketplaceFilterOption'
  count: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type MarketplaceFilterOptionV2 = {
  __typename?: 'MarketplaceFilterOptionV2'
  count: Scalars['Int']['output']
  name: Scalars['String']['output']
  ref?: Maybe<Scalars['String']['output']>
  value: Scalars['String']['output']
}

export type MarketplaceFilterV2 = {
  __typename?: 'MarketplaceFilterV2'
  name: Scalars['String']['output']
  options: Array<MarketplaceFilterOptionV2>
  title: Scalars['String']['output']
  type: FilterType
}

export type MarketplaceProduct = {
  __typename?: 'MarketplaceProduct'
  allowedPlans?: Maybe<Array<Scalars['String']['output']>>
  brandName?: Maybe<Scalars['String']['output']>
  category: Scalars['String']['output']
  currency?: Maybe<Currency>
  customizable?: Maybe<Scalars['Boolean']['output']>
  description: Scalars['String']['output']
  designTemplate?: Maybe<DesignTemplate>
  estimatedShippingTime?: Maybe<EstimatedShippingTime>
  eventAvailabilityDates?: Maybe<Array<Scalars['String']['output']>>
  eventDetails?: Maybe<MagicEvent>
  geographicMarkets?: Maybe<Array<Scalars['String']['output']>>
  id: Scalars['ObjectId']['output']
  imageUrls?: Maybe<Array<ImageReference>>
  itemCustomizationList?: Maybe<Array<ItemCustomization>>
  itemPreview?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  phoneNumberRequired?: Maybe<Scalars['Boolean']['output']>
  starterTemplate?: Maybe<Scalars['Boolean']['output']>
  status: Status
  subCategory?: Maybe<Array<Scalars['String']['output']>>
  type?: Maybe<Scalars['String']['output']>
  useCases?: Maybe<Array<Scalars['String']['output']>>
  variantOrderFlexibility?: Maybe<Scalars['Boolean']['output']>
  variants?: Maybe<Array<ProductVariant>>
}

export type MarketplaceProductFilterInput = {
  attributeFilters?: InputMaybe<Array<GenericFilterInput>>
  brandName?: InputMaybe<StringFilterInput>
  category?: InputMaybe<StringFilterInput>
  currency?: InputMaybe<StringFilterInput>
  eventAvailabilityDates?: InputMaybe<StringFilterInput>
  geographicMarkets?: InputMaybe<StringFilterInput>
  id?: InputMaybe<ObjectIdFilterInput>
  name?: InputMaybe<StringFilterInput>
  q?: InputMaybe<Scalars['String']['input']>
  restrictPlans?: InputMaybe<Scalars['Boolean']['input']>
  subCategory?: InputMaybe<StringFilterInput>
  type?: InputMaybe<StringFilterInput>
  variants_displayPrice?: InputMaybe<IntegerInputFilter>
  variants_fulfillmentPartnerList_bulkSendAvailable?: InputMaybe<BooleanFilterInput>
  variants_fulfillmentPartnerList_displayPrice?: InputMaybe<IntegerInputFilter>
  variants_fulfillmentPartnerList_displaySubscriberPrice?: InputMaybe<IntegerInputFilter>
  variants_fulfillmentPartnerList_fulfillmentPartnerId?: InputMaybe<ObjectIdFilterInput>
  variants_fulfillmentPartnerList_fulfillmentPartnerName?: InputMaybe<StringFilterInput>
  variants_fulfillmentPartnerList_shippingOptions_price?: InputMaybe<IntegerInputFilter>
}

export type MarketplaceSearchFilter = {
  name: Scalars['String']['input']
  values: Array<Scalars['String']['input']>
}

export type MarketplaceSearchQueryInput = {
  filters?: InputMaybe<Array<MarketplaceSearchFilter>>
  limit?: InputMaybe<Scalars['Int']['input']>
  queryString?: InputMaybe<Scalars['String']['input']>
  searchContext: SearchContext
  skip?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<MarketplaceSort>
}

export type MarketplaceSearchResponse = {
  __typename?: 'MarketplaceSearchResponse'
  filters?: Maybe<Array<MarketplaceFilterV2>>
  results: Array<SearchableProduct>
  summary: ResultsSummary
}

export enum MarketplaceSort {
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  PublishedDesc = 'published_DESC',
  RatingDesc = 'rating_DESC',
  RecommendedDesc = 'recommended_DESC',
}

export type MarketplaceSuggestQueryInput = {
  queryString: Scalars['String']['input']
  searchContext: SearchContext
}

/** Options for requesting a meeting */
export enum MeetingRequestSetting {
  /** Ask for a meeting after recipient accepts postal. */
  AskAfter = 'ASK_AFTER',
  /** Do not request a meeting. */
  No = 'NO',
  /** Require a meeting before recipient accepts postal. */
  RequireBefore = 'REQUIRE_BEFORE',
}

export type MeetingSettings = {
  __typename?: 'MeetingSettings'
  availabilityBlockIcal: Scalars['String']['output']
  calendar?: Maybe<ConnectedCalendar>
  dateRange: Scalars['Int']['output']
  enabled: Scalars['Boolean']['output']
  meetingDurationMins: Scalars['Int']['output']
  meetingName: Scalars['String']['output']
  meetingRequestDefault: MeetingRequestSetting
  schedulingLink?: Maybe<Scalars['String']['output']>
  videoMeetingLink?: Maybe<Scalars['String']['output']>
}

export type MeetingSettingsInput = {
  availabilityBlockIcal: Scalars['String']['input']
  calendar?: InputMaybe<ConnectedCalendarInput>
  dateRange: Scalars['Int']['input']
  enabled: Scalars['Boolean']['input']
  meetingDurationMins: Scalars['Int']['input']
  meetingName: Scalars['String']['input']
  meetingRequestDefault: MeetingRequestSetting
  schedulingLink?: InputMaybe<Scalars['String']['input']>
  videoMeetingLink?: InputMaybe<Scalars['String']['input']>
}

export type MessageTemplate = {
  __typename?: 'MessageTemplate'
  id: Scalars['ObjectId']['output']
  name: Scalars['String']['output']
  sharedWith?: Maybe<ShareLink>
  templateText: Scalars['String']['output']
}

export type MessageTemplateFilterInput = {
  id?: InputMaybe<StringFilterInput>
  name?: InputMaybe<StringFilterInput>
  sharedWith?: InputMaybe<StringFilterInput>
  templateText?: InputMaybe<StringFilterInput>
}

export enum MessageTemplateOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type Module = {
  __typename?: 'Module'
  meta?: Maybe<Scalars['Object']['output']>
  name: Scalars['String']['output']
  permissions?: Maybe<Scalars['Object']['output']>
}

export type Mutation = {
  __typename?: 'Mutation'
  addFunds: BackgroundTask
  addFundsV2: BackgroundTask
  addProductAccess: User
  adjustUserBudget: BudgetRemaining
  bulkApproveOrders: BackgroundTask
  bulkContactAddToCampaign: BackgroundTask
  bulkContactAddToList: BackgroundTask
  bulkContactAddToPlaybook: BackgroundTask
  bulkContactDelete: BackgroundTask
  bulkContactRemoveFromCampaign: BackgroundTask
  bulkContactRemoveFromList: BackgroundTask
  bulkContactUpdatePlaybookStatus: BackgroundTask
  bulkCreateApprovedPostal: BackgroundTask
  bulkDenyOrders: BackgroundTask
  bulkEditApprovedPostal: BackgroundTask
  bulkPlaybookStatusUpdate: BackgroundTask
  bulkProductAccessAdd: BackgroundTask
  bulkProductAccessRemove: BackgroundTask
  bulkProductAccessUpdate: BackgroundTask
  bulkRetryFailedABMCampaignOrders: BackgroundTask
  bulkTagAdd: BackgroundTask
  bulkTagRemove: BackgroundTask
  cancelBackgroundTask: BackgroundTask
  cancelEventRequest: ApiStatus
  cancelOrder: ApiStatus
  cancelTransferIntent: TransferIntent
  changePassword: Scalars['Boolean']['output']
  completeDataImport: BackgroundTask
  completeThirdPartyTask: Scalars['Boolean']['output']
  confirmTransferIntent: TransferIntent
  contactEventsTeam: ContactEventsResponse
  createApprovedPostal: ApprovedPostalResponse
  createBillingAccount: BillingAccount
  createBlocklistEntry: BlocklistEntry
  createCampaign: Campaign
  createContactList: ContactList
  createEmailPreviewUrls?: Maybe<Array<EmailPreviewUrl>>
  createFavoriteItem: FavoriteItem
  createMagicLink: MagicLink
  createMessageTemplate: MessageTemplate
  createPlaybook: PlaybookInstance
  createPlaybookDefinition: PlaybookDefinition
  createSampleMarketplaceData: Scalars['Int']['output']
  createSavedSend: SavedSend
  createSetupIntent: Scalars['String']['output']
  createTag: Tag
  createTeam: Team
  createTransferIntent: TransferIntent
  createUpdateIntegrationTrigger: IntegrationTrigger
  deleteApprovedPostalAndAllAutomations: BackgroundTask
  deleteBillingAccount: BillingAccount
  deleteBlocklistEntry: BlocklistEntry
  deleteCampaign: Campaign
  deleteContact: Contact
  deleteContactList: ContactList
  deleteContactUnsubscribe: Scalars['Boolean']['output']
  deleteExternalCampaignMapping: Scalars['Boolean']['output']
  deleteFavoriteItem: FavoriteItem
  deleteIntegration: Scalars['Boolean']['output']
  deleteIntegrationTrigger: Scalars['Boolean']['output']
  deleteInvite: Invite
  deleteMagicLink: MagicLink
  deleteMessageTemplate: MessageTemplate
  deleteSavedSend: SavedSend
  deleteTag: Tag
  deleteTeam?: Maybe<ApiStatus>
  deleteUserAction: Scalars['Boolean']['output']
  deleteUserAuth: Scalars['Boolean']['output']
  executeOrderEvent: PostalFulfillment
  generateContactUnsubscribeReport: BackgroundTask
  generateLedgerReport: BackgroundTask
  generateOrderReport: BackgroundTask
  invite?: Maybe<Array<Maybe<InviteResult>>>
  orderPostal: PostalFulfillment
  productRequest: ApiStatus
  recordUserAction: Scalars['Boolean']['output']
  removeProductAccess: User
  removeUser: User
  removeUserTeam: User
  requestAddFundsByInvoice: ApiStatus
  requestAddFundsByInvoiceV2: RequestFundsByInvoiceV2Response
  resetOkta: Account
  resetOneLogin: Account
  retryBackgroundTask: BackgroundTask
  saveAsset: Asset
  saveExternalCampaignMapping: ExtCampaignMapping
  sendForgotPassword: Scalars['Boolean']['output']
  setDefaultPaymentMethod: BillingAccount
  setupCreditCard: Scalars['Boolean']['output']
  setupCustomIntegration?: Maybe<Array<IntegrationSync>>
  setupDataSource?: Maybe<Array<IntegrationSync>>
  setupOkta: Account
  setupOneLogin: Account
  signup: Account
  startRecipe?: Maybe<Scalars['Boolean']['output']>
  startTrial: StartTrialResponse
  stopRecipe?: Maybe<Scalars['Boolean']['output']>
  submitForm?: Maybe<ApiStatus>
  syncBillingAccountPaymentPartner: BillingAccount
  updateAccount: Account
  updateApprovedPostal: ApprovedPostalResponse
  updateAsset: Asset
  updateBillingAccount: BillingAccount
  updateBlocklistEntry: BlocklistEntry
  updateCampaign: Campaign
  updateContact: Contact
  updateContactList: ContactList
  updateIntegrationSync: IntegrationSync
  updateIntegrationSyncStatus: IntegrationSync
  updateMagicLink: MagicLink
  updateMessageTemplate: MessageTemplate
  updatePlaybook: PlaybookInstance
  updatePlaybookDefinition: PlaybookDefinition
  updateRolePermissions?: Maybe<RolePermission>
  updateRoles: User
  updateRolesV2?: Maybe<Array<RoleAssignmentResult>>
  updateSavedSend: SavedSend
  updateSelf: User
  updateTeam: Team
  updateUser: User
  updateUserNotifications: User
  updateUserTeam: User
  upsertContact: Contact
}

export type MutationAddFundsArgs = {
  amount: Scalars['Int']['input']
  billingAccountId: Scalars['ObjectId']['input']
}

export type MutationAddFundsV2Args = {
  input: AddFundsV2Input
}

export type MutationAddProductAccessArgs = {
  id: Scalars['ObjectId']['input']
  product?: InputMaybe<Scalars['String']['input']>
  roles?: InputMaybe<Array<Scalars['String']['input']>>
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type MutationAdjustUserBudgetArgs = {
  input: AdjustUserBudgetInput
}

export type MutationBulkApproveOrdersArgs = {
  fulfillmentIds: Array<Scalars['ObjectId']['input']>
}

export type MutationBulkContactAddToCampaignArgs = {
  campaignId: Scalars['ObjectId']['input']
  campaignName: Scalars['String']['input']
  filter?: InputMaybe<ContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput>>
  v2filter?: InputMaybe<SearchableContactFilterInput>
}

export type MutationBulkContactAddToListArgs = {
  data: ContactListMapInput
  filter?: InputMaybe<ContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput>>
  v2filter?: InputMaybe<SearchableContactFilterInput>
}

export type MutationBulkContactAddToPlaybookArgs = {
  filter?: InputMaybe<ContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput>>
  playbookDefinitionId: Scalars['ObjectId']['input']
  v2filter?: InputMaybe<SearchableContactFilterInput>
}

export type MutationBulkContactDeleteArgs = {
  filter?: InputMaybe<ContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput>>
  v2filter?: InputMaybe<SearchableContactFilterInput>
}

export type MutationBulkContactRemoveFromCampaignArgs = {
  campaignId: Scalars['ObjectId']['input']
  filter?: InputMaybe<ContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput>>
  v2filter?: InputMaybe<SearchableContactFilterInput>
}

export type MutationBulkContactRemoveFromListArgs = {
  data: ContactListMapInput
  filter?: InputMaybe<ContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput>>
  v2filter?: InputMaybe<SearchableContactFilterInput>
}

export type MutationBulkContactUpdatePlaybookStatusArgs = {
  filter?: InputMaybe<ContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput>>
  playbookDefinitionId: Scalars['ObjectId']['input']
  playbookStatus?: InputMaybe<PlaybookStatus>
  v2filter?: InputMaybe<SearchableContactFilterInput>
}

export type MutationBulkCreateApprovedPostalArgs = {
  data: BulkApprovedPostalUpdateInput
  marketplaceProductIds: Array<Scalars['ObjectId']['input']>
}

export type MutationBulkDenyOrdersArgs = {
  fulfillmentIds: Array<Scalars['ObjectId']['input']>
}

export type MutationBulkEditApprovedPostalArgs = {
  approvedPostalIds: Array<Scalars['ObjectId']['input']>
  data: BulkApprovedPostalUpdateInput
}

export type MutationBulkPlaybookStatusUpdateArgs = {
  filter?: InputMaybe<PlaybookInstanceFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  playbookStatus?: InputMaybe<PlaybookStatus>
}

export type MutationBulkProductAccessAddArgs = {
  data: ProductAccessInput
  filter?: InputMaybe<UserFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationBulkProductAccessRemoveArgs = {
  filter?: InputMaybe<UserFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type MutationBulkProductAccessUpdateArgs = {
  data: ProductAccessInput
  filter?: InputMaybe<UserFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationBulkRetryFailedAbmCampaignOrdersArgs = {
  campaignId: Scalars['ObjectId']['input']
  skipDuplicateOrderCheck?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationBulkTagAddArgs = {
  data: TagInput
  filter?: InputMaybe<ContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput>>
  v2filter?: InputMaybe<SearchableContactFilterInput>
}

export type MutationBulkTagRemoveArgs = {
  data: TagInput
  filter?: InputMaybe<ContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput>>
  v2filter?: InputMaybe<SearchableContactFilterInput>
}

export type MutationCancelBackgroundTaskArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationCancelEventRequestArgs = {
  id: Scalars['ObjectId']['input']
  reason: Scalars['String']['input']
}

export type MutationCancelOrderArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationCancelTransferIntentArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationChangePasswordArgs = {
  data: ChangePasswordInput
}

export type MutationCompleteDataImportArgs = {
  data: FileDataMappingInput
}

export type MutationCompleteThirdPartyTaskArgs = {
  contactId: Scalars['ObjectId']['input']
  system: Scalars['String']['input']
  taskId: Scalars['String']['input']
}

export type MutationConfirmTransferIntentArgs = {
  id: Scalars['ObjectId']['input']
  localTimeZone?: InputMaybe<Scalars['String']['input']>
}

export type MutationContactEventsTeamArgs = {
  approvedPostalId: Scalars['ObjectId']['input']
  message?: InputMaybe<Scalars['String']['input']>
  requestType: ContactEventsRequestType
  requestedDate?: InputMaybe<Scalars['Instant']['input']>
}

export type MutationCreateApprovedPostalArgs = {
  data: ApprovedPostalUpdateInput
  marketplaceProductId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type MutationCreateBillingAccountArgs = {
  data: CreateBillingAccountInput
}

export type MutationCreateBlocklistEntryArgs = {
  data: BlocklistEntryInput
}

export type MutationCreateCampaignArgs = {
  data: CampaignInput
}

export type MutationCreateContactListArgs = {
  data: ContactListInput
}

export type MutationCreateEmailPreviewUrlsArgs = {
  data?: InputMaybe<EmailPreviewRequestInput>
  renderSubjectLine?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationCreateFavoriteItemArgs = {
  data: FavoriteItemInput
}

export type MutationCreateMagicLinkArgs = {
  data: MagicLinkInput
}

export type MutationCreateMessageTemplateArgs = {
  data: NewMessageTemplateInput
}

export type MutationCreatePlaybookArgs = {
  contactId: Scalars['ObjectId']['input']
  playbookDefinitionId: Scalars['ObjectId']['input']
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type MutationCreatePlaybookDefinitionArgs = {
  data: PlaybookDefinitionInput
}

export type MutationCreateSavedSendArgs = {
  data: SavedSendInput
}

export type MutationCreateSetupIntentArgs = {
  billingAccountId: Scalars['ObjectId']['input']
}

export type MutationCreateTagArgs = {
  data: TagInput
}

export type MutationCreateTeamArgs = {
  data: CreateTeamInput
}

export type MutationCreateTransferIntentArgs = {
  input: CreateTransferIntentInput
}

export type MutationCreateUpdateIntegrationTriggerArgs = {
  data: IntegrationTriggerInput
}

export type MutationDeleteApprovedPostalAndAllAutomationsArgs = {
  approvedPostalId: Scalars['ObjectId']['input']
}

export type MutationDeleteBillingAccountArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationDeleteBlocklistEntryArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationDeleteCampaignArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationDeleteContactArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationDeleteContactListArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationDeleteContactUnsubscribeArgs = {
  emailAddress: Scalars['String']['input']
}

export type MutationDeleteExternalCampaignMappingArgs = {
  campaignId: Scalars['String']['input']
  system: Scalars['String']['input']
}

export type MutationDeleteFavoriteItemArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationDeleteIntegrationArgs = {
  systemName: Scalars['String']['input']
}

export type MutationDeleteIntegrationTriggerArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationDeleteInviteArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationDeleteMagicLinkArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationDeleteMessageTemplateArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationDeleteSavedSendArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationDeleteTagArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationDeleteTeamArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationDeleteUserActionArgs = {
  name: Scalars['String']['input']
}

export type MutationDeleteUserAuthArgs = {
  authMethod: Scalars['String']['input']
}

export type MutationExecuteOrderEventArgs = {
  eventName: OrderEventNames
  fulfillmentId: Scalars['ObjectId']['input']
}

export type MutationGenerateContactUnsubscribeReportArgs = {
  filter: ContactUnsubscribeFilterInput
  name?: InputMaybe<Scalars['String']['input']>
}

export type MutationGenerateLedgerReportArgs = {
  filter: LedgerFilterInput
  name?: InputMaybe<Scalars['String']['input']>
}

export type MutationGenerateOrderReportArgs = {
  filter: PostalFulfillmentFilterInput
  name?: InputMaybe<Scalars['String']['input']>
}

export type MutationInviteArgs = {
  data: InviteUserInput
}

export type MutationOrderPostalArgs = {
  data: PostalOrderRequestInput
}

export type MutationProductRequestArgs = {
  id: Scalars['ObjectId']['input']
  reason?: InputMaybe<Scalars['String']['input']>
}

export type MutationRecordUserActionArgs = {
  name: Scalars['String']['input']
}

export type MutationRemoveProductAccessArgs = {
  id: Scalars['ObjectId']['input']
  productAccessId: Scalars['ObjectId']['input']
}

export type MutationRemoveUserArgs = {
  id: Scalars['ObjectId']['input']
  productAccessId: Scalars['ObjectId']['input']
}

export type MutationRemoveUserTeamArgs = {
  id: Scalars['ObjectId']['input']
  productAccessId: Scalars['ObjectId']['input']
}

export type MutationRequestAddFundsByInvoiceArgs = {
  amountCents: Scalars['Int']['input']
  billingAccountId: Scalars['ObjectId']['input']
}

export type MutationRequestAddFundsByInvoiceV2Args = {
  input: RequestFundsByInvoiceV2Request
}

export type MutationRetryBackgroundTaskArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationSaveAssetArgs = {
  assetType?: InputMaybe<AssetType>
  name: Scalars['String']['input']
  requestId: Scalars['String']['input']
}

export type MutationSaveExternalCampaignMappingArgs = {
  campaignId: Scalars['String']['input']
  mapping: ExtCampaignMappingInput
  system: Scalars['String']['input']
}

export type MutationSendForgotPasswordArgs = {
  userId: Scalars['ObjectId']['input']
}

export type MutationSetDefaultPaymentMethodArgs = {
  id: Scalars['ObjectId']['input']
  input: SetDefaultPaymentMethodInput
}

export type MutationSetupCreditCardArgs = {
  billingAccountId: Scalars['ObjectId']['input']
  card: CreditCardPaymentInput
}

export type MutationSetupCustomIntegrationArgs = {
  baseUrl: Scalars['String']['input']
  clientId: Scalars['String']['input']
  clientSecret: Scalars['String']['input']
  systemName: CustomIntegrationNames
}

export type MutationSetupDataSourceArgs = {
  systemName: Scalars['String']['input']
}

export type MutationSetupOktaArgs = {
  data: OktaIntegrationInput
}

export type MutationSetupOneLoginArgs = {
  data: OneLoginIntegrationInput
}

export type MutationSignupArgs = {
  data: SignupInput
}

export type MutationStartRecipeArgs = {
  id: Scalars['String']['input']
}

export type MutationStopRecipeArgs = {
  id: Scalars['String']['input']
}

export type MutationSubmitFormArgs = {
  type: FormType
  variables: Scalars['Object']['input']
}

export type MutationSyncBillingAccountPaymentPartnerArgs = {
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateAccountArgs = {
  data: UpdateAccountInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateApprovedPostalArgs = {
  data: ApprovedPostalUpdateInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateAssetArgs = {
  data: AssetInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateBillingAccountArgs = {
  data: UpdateBillingAccountInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateBlocklistEntryArgs = {
  data: BlocklistEntryInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateCampaignArgs = {
  data: CampaignInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateContactArgs = {
  data: ContactUpdateInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateContactListArgs = {
  data: ContactListInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateIntegrationSyncArgs = {
  data: IntegrationSyncInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateIntegrationSyncStatusArgs = {
  id: Scalars['ObjectId']['input']
  status: Status
}

export type MutationUpdateMagicLinkArgs = {
  data: MagicLinkUpdateInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateMessageTemplateArgs = {
  data: UpdateMessageTemplateInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdatePlaybookArgs = {
  data: PlaybookInstanceUpdateInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdatePlaybookDefinitionArgs = {
  data: PlaybookDefinitionUpdateInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateRolePermissionsArgs = {
  addPermissions?: InputMaybe<Array<Scalars['String']['input']>>
  removePermissions?: InputMaybe<Array<Scalars['String']['input']>>
  role: Scalars['String']['input']
}

export type MutationUpdateRolesArgs = {
  id: Scalars['ObjectId']['input']
  productAccessId: Scalars['ObjectId']['input']
  roles?: InputMaybe<Array<Scalars['String']['input']>>
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateRolesV2Args = {
  id: Scalars['ObjectId']['input']
  product?: InputMaybe<Scalars['String']['input']>
  roles?: InputMaybe<Array<Scalars['String']['input']>>
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type MutationUpdateSavedSendArgs = {
  data: UpdateSavedSendInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateSelfArgs = {
  data: UpdateSelfInput
}

export type MutationUpdateTeamArgs = {
  data: UpdateTeamInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateUserArgs = {
  data: UpdateUserInput
  id: Scalars['ObjectId']['input']
}

export type MutationUpdateUserNotificationsArgs = {
  data: UserNotificationsInput
}

export type MutationUpdateUserTeamArgs = {
  id: Scalars['ObjectId']['input']
  productAccessId: Scalars['ObjectId']['input']
  teamId: Scalars['ObjectId']['input']
}

export type MutationUpsertContactArgs = {
  data: ContactInput
}

export type NamedProductAccess = {
  __typename?: 'NamedProductAccess'
  accountDisabled?: Maybe<Scalars['Boolean']['output']>
  accountName?: Maybe<Scalars['String']['output']>
  productAccess: ProductAccess
  teamName?: Maybe<Scalars['String']['output']>
}

export type NewContact = {
  __typename?: 'NewContact'
  emailAddress?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  phones?: Maybe<Array<Maybe<NewPhoneMap>>>
}

export type NewContactInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phones?: InputMaybe<Array<InputMaybe<NewPhoneMapInput>>>
}

export type NewMessageTemplateInput = {
  name: Scalars['String']['input']
  sharedWith?: InputMaybe<ShareLinkInput>
  templateText: Scalars['String']['input']
}

export type NewPhoneMap = {
  __typename?: 'NewPhoneMap'
  phoneNumber?: Maybe<Scalars['String']['output']>
  type?: Maybe<PhoneType>
}

export type NewPhoneMapInput = {
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<PhoneType>
}

export enum NotificationCategory {
  BalanceLow = 'BALANCE_LOW',
  BudgetLow = 'BUDGET_LOW',
  EmailOpens = 'EMAIL_OPENS',
  EventRegistrationAccepted = 'EVENT_REGISTRATION_ACCEPTED',
  EventRegistrationSent = 'EVENT_REGISTRATION_SENT',
  ItemAccepted = 'ITEM_ACCEPTED',
  ItemDelivered = 'ITEM_DELIVERED',
  ItemLowStock = 'ITEM_LOW_STOCK',
  ItemNoStock = 'ITEM_NO_STOCK',
  LandingPageViews = 'LANDING_PAGE_VIEWS',
  MagicLinkApproval = 'MAGIC_LINK_APPROVAL',
  MarketingUpdate = 'MARKETING_UPDATE',
  MeetingAccepted = 'MEETING_ACCEPTED',
  NewCollectionApproved = 'NEW_COLLECTION_APPROVED',
  NewEventApproved = 'NEW_EVENT_APPROVED',
  NewItemApproved = 'NEW_ITEM_APPROVED',
  OrderIssue = 'ORDER_ISSUE',
  ProcessingError = 'PROCESSING_ERROR',
  PurchasedSwagStoreItem = 'PURCHASED_SWAG_STORE_ITEM',
  QrCodeScan = 'QR_CODE_SCAN',
  Sends = 'SENDS',
}

export type ObjectIdFilterInput = {
  all?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>
  arrayeq?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>
  eq?: InputMaybe<Scalars['ObjectId']['input']>
  exists?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']['input']>>>
  ne?: InputMaybe<Scalars['ObjectId']['input']>
}

export type ObjectList = {
  __typename?: 'ObjectList'
  done?: Maybe<Scalars['Boolean']['output']>
  records?: Maybe<Array<Maybe<Scalars['Object']['output']>>>
  totalSize?: Maybe<Scalars['Int']['output']>
}

export enum ObjectType {
  Event = 'EVENT',
  Gift = 'GIFT',
  Internal = 'INTERNAL',
  Store = 'STORE',
}

export type OktaIntegration = {
  __typename?: 'OktaIntegration'
  baseUrl?: Maybe<Scalars['String']['output']>
  clientId?: Maybe<Scalars['String']['output']>
}

export type OktaIntegrationInput = {
  baseUrl: Scalars['String']['input']
  clientId: Scalars['String']['input']
  clientSecret: Scalars['String']['input']
}

export type OneLoginIntegration = {
  __typename?: 'OneLoginIntegration'
  baseUrl?: Maybe<Scalars['String']['output']>
  clientId?: Maybe<Scalars['String']['output']>
}

export type OneLoginIntegrationInput = {
  baseUrl: Scalars['String']['input']
  clientId: Scalars['String']['input']
  clientSecret: Scalars['String']['input']
}

export enum OrderAction {
  Allow = 'ALLOW',
  Block = 'BLOCK',
}

export type OrderCost = {
  __typename?: 'OrderCost'
  budgetExpense?: Maybe<Scalars['Int']['output']>
  currency?: Maybe<Currency>
  postalFee?: Maybe<Scalars['Int']['output']>
  shipping?: Maybe<Scalars['Int']['output']>
  subtotal?: Maybe<Scalars['Int']['output']>
  tax?: Maybe<Scalars['Int']['output']>
  taxEstimated?: Maybe<Scalars['Boolean']['output']>
  thirdPartyFee?: Maybe<Scalars['Int']['output']>
  total?: Maybe<Scalars['Int']['output']>
}

export enum OrderEventNames {
  OrderSubmittedEvent = 'OrderSubmittedEvent',
  RequestTransactionEvent = 'RequestTransactionEvent',
  SkipDuplicateOrderEvent = 'SkipDuplicateOrderEvent',
}

export enum OrderIssue {
  BillingNotSetup = 'BILLING_NOT_SETUP',
  BlocklistMatch = 'BLOCKLIST_MATCH',
  DuplicateOrder = 'DUPLICATE_ORDER',
  EmailIntegrationIssue = 'EMAIL_INTEGRATION_ISSUE',
  ExpiredDeliveryEmail = 'EXPIRED_DELIVERY_EMAIL',
  InsufficientBudget = 'INSUFFICIENT_BUDGET',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  InternalOrderIssue = 'INTERNAL_ORDER_ISSUE',
  InvalidAddress = 'INVALID_ADDRESS',
  InvalidBudget = 'INVALID_BUDGET',
  NoAddress = 'NO_ADDRESS',
  NoEmail = 'NO_EMAIL',
  NoPhone = 'NO_PHONE',
  OrderRulesViolation = 'ORDER_RULES_VIOLATION',
  OrderVariableDataMissing = 'ORDER_VARIABLE_DATA_MISSING',
}

export type OrderPreview = {
  __typename?: 'OrderPreview'
  billingAccountId?: Maybe<Scalars['ObjectId']['output']>
  budgetPassed?: Maybe<Scalars['Boolean']['output']>
  budgetRemaining?: Maybe<Scalars['Int']['output']>
  cost: OrderCost
  currency?: Maybe<Currency>
  duplicateOrder?: Maybe<Scalars['Boolean']['output']>
  emailIntegrationSuccess?: Maybe<Scalars['Boolean']['output']>
  firstDeliveryEstimated: Scalars['Instant']['output']
  fundsPassed?: Maybe<Scalars['Boolean']['output']>
  fundsRemaining?: Maybe<Scalars['Int']['output']>
  hardBlock?: Maybe<Scalars['Boolean']['output']>
  lastDeliveryEstimated: Scalars['Instant']['output']
  orderBlocked?: Maybe<Scalars['Boolean']['output']>
  orderBlockedReason?: Maybe<Scalars['String']['output']>
  skipConfirmation?: Maybe<Scalars['Boolean']['output']>
  spendAs?: Maybe<SpendAs>
  totalContacts: Scalars['Int']['output']
}

export type OrderPreviewV2 = {
  __typename?: 'OrderPreviewV2'
  billingAccountId?: Maybe<Scalars['ObjectId']['output']>
  budgetRemaining?: Maybe<Scalars['Int']['output']>
  contactValidationIssues?: Maybe<Array<ContactValidationIssue>>
  cost?: Maybe<OrderCost>
  currency?: Maybe<Currency>
  fundsRemaining?: Maybe<Scalars['Int']['output']>
  generalValidationIssues?: Maybe<Array<ValidationIssue>>
  spendAs?: Maybe<SpendAs>
  totalContacts?: Maybe<Scalars['Int']['output']>
  validationPassed: Scalars['Boolean']['output']
}

export type OrderPreviewV2Request = {
  approvedPostalId: Scalars['ObjectId']['input']
  deliveryEmail: Scalars['Boolean']['input']
  quantity?: InputMaybe<Scalars['Int']['input']>
  spendAs?: InputMaybe<SpendAsInput>
  variantId: Scalars['ObjectId']['input']
}

export type OrderRule = {
  __typename?: 'OrderRule'
  action: OrderAction
  filters?: Maybe<Array<OrderRuleFilter>>
}

export type OrderRuleFilter = {
  __typename?: 'OrderRuleFilter'
  field: Scalars['String']['output']
  filter: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type OrderRules = {
  __typename?: 'OrderRules'
  defaultAction: OrderAction
  id: Scalars['ObjectId']['output']
  name: Scalars['String']['output']
  reasonText: Scalars['String']['output']
  rules?: Maybe<Array<OrderRule>>
}

export type PartnerPaymentMethod = {
  __typename?: 'PartnerPaymentMethod'
  billingDetails?: Maybe<BillingPaymentMethodInfo>
  card?: Maybe<CardPaymentMethod>
  partnerId: Scalars['String']['output']
}

export enum PaymentMethodType {
  Ach = 'ACH',
  CreditCard = 'CREDIT_CARD',
  DebitCard = 'DEBIT_CARD',
  Other = 'OTHER',
}

export type PaymentPartnerInfo = {
  __typename?: 'PaymentPartnerInfo'
  customerId: Scalars['String']['output']
  lastSyncedAt?: Maybe<Scalars['Instant']['output']>
  paymentMethods?: Maybe<Array<PartnerPaymentMethod>>
  type: PaymentPartnerType
}

export enum PaymentPartnerType {
  Mock = 'MOCK',
  Stripe = 'STRIPE',
}

export enum PaymentTerm {
  CreditCard = 'CREDIT_CARD',
  Net15 = 'NET15',
  Net30 = 'NET30',
  Net60 = 'NET60',
  Other = 'OTHER',
}

export type PermissionDefinition = {
  __typename?: 'PermissionDefinition'
  description: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type PhoneMap = {
  __typename?: 'PhoneMap'
  created: ActionRecord
  phoneNumber: Scalars['String']['output']
  type: PhoneType
}

export type PhoneMapInput = {
  phoneNumber: Scalars['String']['input']
  type: PhoneType
}

export enum PhoneType {
  Cell = 'CELL',
  Home = 'HOME',
  Other = 'OTHER',
  Work = 'WORK',
}

export type PlanInfo = {
  numUsers: Scalars['Int']['input']
  postalPlan: PostalPlan
}

export type PlaybookDefinition = {
  __typename?: 'PlaybookDefinition'
  attribution?: Maybe<Attribution>
  currency?: Maybe<Currency>
  id: Scalars['ObjectId']['output']
  name?: Maybe<Scalars['String']['output']>
  status?: Maybe<Status>
  steps: Array<Maybe<PlaybookStepDefinition>>
  teamId?: Maybe<Scalars['ObjectId']['output']>
}

export type PlaybookDefinitionFilterInput = {
  name?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
}

export type PlaybookDefinitionInput = {
  attribution?: InputMaybe<AttributionInput>
  currency?: InputMaybe<Currency>
  name: Scalars['String']['input']
  status: Status
  steps: Array<InputMaybe<PlaybookStepDefinitionInput>>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}

export enum PlaybookDefinitionOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type PlaybookDefinitionUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Status>
  steps?: InputMaybe<Array<InputMaybe<PlaybookStepDefinitionUpdateInput>>>
}

export type PlaybookInstance = {
  __typename?: 'PlaybookInstance'
  attribution?: Maybe<Attribution>
  contactId: Scalars['ObjectId']['output']
  contactName?: Maybe<Scalars['String']['output']>
  errMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['ObjectId']['output']
  metrics?: Maybe<BasicMetrics>
  name?: Maybe<Scalars['String']['output']>
  nextExecutionDate?: Maybe<Scalars['Instant']['output']>
  nextStepToExecute?: Maybe<Scalars['Int']['output']>
  playbookDefinitionId: Scalars['ObjectId']['output']
  playbookStatus?: Maybe<PlaybookStatus>
  steps: Array<Maybe<PlaybookStepInstance>>
  teamId?: Maybe<Scalars['ObjectId']['output']>
  triggerId?: Maybe<Scalars['ObjectId']['output']>
}

export type PlaybookInstanceFilterInput = {
  contactId?: InputMaybe<ObjectIdFilterInput>
  name?: InputMaybe<StringFilterInput>
  playbookDefinitionId?: InputMaybe<ObjectIdFilterInput>
  playbookStatus?: InputMaybe<StringFilterInput>
}

export type PlaybookInstanceInput = {
  contactId: Scalars['ObjectId']['input']
  errMessage?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  nextExecutionDate?: InputMaybe<Scalars['Instant']['input']>
  nextStepToExecute?: InputMaybe<Scalars['Int']['input']>
  playbookDefinitionId: Scalars['ObjectId']['input']
  playbookStatus?: InputMaybe<PlaybookStatus>
  steps: Array<InputMaybe<PlaybookStepInstanceInput>>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}

export enum PlaybookInstanceOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type PlaybookInstanceUpdateInput = {
  playbookStatus?: InputMaybe<PlaybookStatus>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type PlaybookMap = {
  __typename?: 'PlaybookMap'
  created?: Maybe<ActionRecord>
  name: Scalars['String']['output']
  nextStepToExecute?: Maybe<Scalars['Int']['output']>
  playbookDefinitionId: Scalars['ObjectId']['output']
  playbookInstanceId: Scalars['ObjectId']['output']
  playbookStatus: PlaybookStatus
}

export enum PlaybookStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Queued = 'QUEUED',
}

export type PlaybookStepDefinition = {
  __typename?: 'PlaybookStepDefinition'
  approvedPostalId: Scalars['ObjectId']['output']
  delay: Scalars['Int']['output']
  delayUnit: ChronoUnit
  deliveryEmail?: Maybe<Scalars['Boolean']['output']>
  deliveryEmailTemplateId?: Maybe<Scalars['ObjectId']['output']>
  displayPrice?: Maybe<Scalars['Int']['output']>
  displaySubscriberPrice?: Maybe<Scalars['Int']['output']>
  emailSubjectLine?: Maybe<Scalars['String']['output']>
  formFieldList?: Maybe<Array<FormField>>
  giftEmailReminderSettings?: Maybe<GiftEmailReminderSettings>
  giftMessage?: Maybe<Scalars['String']['output']>
  itemCustomizationInputs?: Maybe<Array<ItemCustomizationInput>>
  landingPageCustomization?: Maybe<LandingPageCustomization>
  meetingRequestSetting?: Maybe<MeetingRequestSetting>
  physicalMessage?: Maybe<Scalars['String']['output']>
  productName?: Maybe<Scalars['String']['output']>
  recipientEmailSettings?: Maybe<RecipientEmailSettings>
  sendAsContactOwner?: Maybe<Scalars['Boolean']['output']>
  sendAsUser?: Maybe<Scalars['ObjectId']['output']>
  spendAs?: Maybe<SpendAs>
  type: PlaybookStepType
  variantId: Scalars['ObjectId']['output']
}

export type PlaybookStepDefinitionInput = {
  approvedPostalId: Scalars['ObjectId']['input']
  delay: Scalars['Int']['input']
  delayUnit: ChronoUnit
  deliveryEmail?: InputMaybe<Scalars['Boolean']['input']>
  deliveryEmailTemplateId?: InputMaybe<Scalars['ObjectId']['input']>
  displayPrice?: InputMaybe<Scalars['Int']['input']>
  displaySubscriberPrice?: InputMaybe<Scalars['Int']['input']>
  emailSubjectLine?: InputMaybe<Scalars['String']['input']>
  formFieldList?: InputMaybe<Array<FormFieldInput>>
  giftEmailReminderSettings?: InputMaybe<GiftEmailReminderSettingsInput>
  giftMessage?: InputMaybe<Scalars['String']['input']>
  itemCustomizationInputs?: InputMaybe<Array<ItemCustomizationInputInput>>
  landingPageCustomization?: InputMaybe<LandingPageCustomizationInput>
  meetingRequestSetting?: InputMaybe<MeetingRequestSetting>
  physicalMessage?: InputMaybe<Scalars['String']['input']>
  recipientEmailSettings?: InputMaybe<RecipientEmailSettingsInput>
  sendAsContactOwner?: InputMaybe<Scalars['Boolean']['input']>
  sendAsUser?: InputMaybe<Scalars['ObjectId']['input']>
  spendAs?: InputMaybe<SpendAsInput>
  type: PlaybookStepType
  variantId: Scalars['ObjectId']['input']
}

export type PlaybookStepDefinitionUpdateInput = {
  approvedPostalId?: InputMaybe<Scalars['ObjectId']['input']>
  attribution?: InputMaybe<AttributionInput>
  delay?: InputMaybe<Scalars['Int']['input']>
  delayUnit?: InputMaybe<ChronoUnit>
  deliveryEmail?: InputMaybe<Scalars['Boolean']['input']>
  deliveryEmailTemplateId?: InputMaybe<Scalars['ObjectId']['input']>
  displayPrice?: InputMaybe<Scalars['Int']['input']>
  displaySubscriberPrice?: InputMaybe<Scalars['Int']['input']>
  emailSubjectLine?: InputMaybe<Scalars['String']['input']>
  formFieldList?: InputMaybe<Array<FormFieldInput>>
  giftEmailReminderSettings?: InputMaybe<GiftEmailReminderSettingsInput>
  giftMessage?: InputMaybe<Scalars['String']['input']>
  itemCustomizationInputs?: InputMaybe<Array<ItemCustomizationInputInput>>
  landingPageCustomization?: InputMaybe<LandingPageCustomizationInput>
  meetingRequestSetting?: InputMaybe<MeetingRequestSetting>
  physicalMessage?: InputMaybe<Scalars['String']['input']>
  recipientEmailSettings?: InputMaybe<RecipientEmailSettingsInput>
  sendAsContactOwner?: InputMaybe<Scalars['Boolean']['input']>
  sendAsUser?: InputMaybe<Scalars['ObjectId']['input']>
  spendAs?: InputMaybe<SpendAsInput>
  type?: InputMaybe<PlaybookStepType>
  variantId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type PlaybookStepInstance = {
  __typename?: 'PlaybookStepInstance'
  approvedPostalId: Scalars['ObjectId']['output']
  delay: Scalars['Int']['output']
  delayUnit: ChronoUnit
  deliveryEmail?: Maybe<Scalars['Boolean']['output']>
  errMessage?: Maybe<Scalars['String']['output']>
  formFieldList?: Maybe<Array<FormField>>
  fulfillmentId?: Maybe<Scalars['ObjectId']['output']>
  fulfillmentStatus?: Maybe<FulfillmentStatus>
  giftEmailReminderSettings?: Maybe<GiftEmailReminderSettings>
  giftMessage?: Maybe<Scalars['String']['output']>
  itemCustomizationInputs?: Maybe<Array<ItemCustomizationInput>>
  landingPageCustomization?: Maybe<LandingPageCustomization>
  meetingRequestSetting?: Maybe<MeetingRequestSetting>
  physicalMessage?: Maybe<Scalars['String']['output']>
  playbookStatus?: Maybe<PlaybookStatus>
  recipientEmailSettings?: Maybe<RecipientEmailSettings>
  sendAsContactOwner?: Maybe<Scalars['Boolean']['output']>
  sendAsUser?: Maybe<Scalars['ObjectId']['output']>
  spendAs?: Maybe<SpendAs>
  type: PlaybookStepType
  variantId: Scalars['ObjectId']['output']
}

export type PlaybookStepInstanceInput = {
  approvedPostalId: Scalars['ObjectId']['input']
  delay: Scalars['Int']['input']
  delayUnit: ChronoUnit
  deliveryEmail?: InputMaybe<Scalars['Boolean']['input']>
  errMessage?: InputMaybe<Scalars['String']['input']>
  fulfillmentId?: InputMaybe<Scalars['ObjectId']['input']>
  fulfillmentStatus?: InputMaybe<FulfillmentStatus>
  giftMessage?: InputMaybe<Scalars['String']['input']>
  meetingRequestSetting?: InputMaybe<MeetingRequestSetting>
  physicalMessage?: InputMaybe<Scalars['String']['input']>
  playbookStatus?: InputMaybe<PlaybookStatus>
  sendAsContactOwner?: InputMaybe<Scalars['Boolean']['input']>
  sendAsUser?: InputMaybe<Scalars['ObjectId']['input']>
  type: PlaybookStepType
  variantId: Scalars['ObjectId']['input']
}

export enum PlaybookStepType {
  Order = 'ORDER',
}

export enum PostalDeliveryEmailSetting {
  Always = 'ALWAYS',
  DefaultOff = 'DEFAULT_OFF',
  DefaultOn = 'DEFAULT_ON',
  Never = 'NEVER',
}

export enum PostalDeliveryExpireAction {
  CancelOrder = 'CANCEL_ORDER',
  DonateOrder = 'DONATE_ORDER',
  SendOrder = 'SEND_ORDER',
}

export type PostalFulfillment = {
  __typename?: 'PostalFulfillment'
  accountId: Scalars['ObjectId']['output']
  approvalRequiredInfo?: Maybe<ApprovalRequiredInfo>
  approvedPostalId?: Maybe<Scalars['ObjectId']['output']>
  attributes?: Maybe<Scalars['Object']['output']>
  campaignId?: Maybe<Scalars['ObjectId']['output']>
  campaignName?: Maybe<Scalars['String']['output']>
  collection?: Maybe<Scalars['Boolean']['output']>
  collectionName?: Maybe<Scalars['String']['output']>
  contactId?: Maybe<Scalars['ObjectId']['output']>
  cost?: Maybe<OrderCost>
  created: ActionRecord
  deliveryDate?: Maybe<Scalars['Instant']['output']>
  deliveryEmail?: Maybe<Scalars['Boolean']['output']>
  deliveryPartner?: Maybe<Scalars['String']['output']>
  emailSubjectLine?: Maybe<Scalars['String']['output']>
  failureReason?: Maybe<FailureReason>
  formFieldList?: Maybe<Array<FormField>>
  formFieldValueList?: Maybe<Array<FormFieldPair>>
  fulfillmentPartnerId?: Maybe<Scalars['ObjectId']['output']>
  fulfillmentPartnerOrderId?: Maybe<Scalars['String']['output']>
  fulfillments?: Maybe<Array<Fulfillment>>
  giftEmailReminderSettings?: Maybe<GiftEmailReminderSettings>
  giftMessage?: Maybe<Scalars['String']['output']>
  history?: Maybe<Array<FulfillmentHistory>>
  id: Scalars['ObjectId']['output']
  itemCustomizationList?: Maybe<Array<ItemCustomization>>
  itemName: Scalars['String']['output']
  landingPageCustomization?: Maybe<LandingPageCustomization>
  magicLinkId?: Maybe<Scalars['ObjectId']['output']>
  magicLinkName?: Maybe<Scalars['String']['output']>
  marketplaceProductId?: Maybe<Scalars['ObjectId']['output']>
  meetingRequestSetting?: Maybe<MeetingRequestSetting>
  physicalMessage?: Maybe<Scalars['String']['output']>
  playbookDefinitionId?: Maybe<Scalars['ObjectId']['output']>
  playbookId?: Maybe<Scalars['ObjectId']['output']>
  playbookName?: Maybe<Scalars['String']['output']>
  previewImage?: Maybe<Scalars['String']['output']>
  recipientEmailSettings?: Maybe<RecipientEmailSettings>
  sendAs?: Maybe<UserLink>
  sendAsUserId?: Maybe<Scalars['ObjectId']['output']>
  sendType?: Maybe<SendType>
  shipTo?: Maybe<Address>
  shipToCompanyName?: Maybe<Scalars['String']['output']>
  shipToEmail?: Maybe<Scalars['String']['output']>
  shipToName?: Maybe<Scalars['String']['output']>
  shipToTitle?: Maybe<Scalars['String']['output']>
  spendAs?: Maybe<SpendAs>
  stage?: Maybe<Scalars['String']['output']>
  status: FulfillmentStatus
  targetDeliveryDate?: Maybe<Scalars['Instant']['output']>
  teamId?: Maybe<Scalars['ObjectId']['output']>
  thankYouMessage?: Maybe<Scalars['String']['output']>
  trackingNumber?: Maybe<Scalars['String']['output']>
  trackingUrl?: Maybe<Scalars['String']['output']>
  triggerId?: Maybe<Scalars['ObjectId']['output']>
  type?: Maybe<ObjectType>
  userId: Scalars['ObjectId']['output']
  userLink?: Maybe<UserLink>
  variantId?: Maybe<Scalars['ObjectId']['output']>
}

export type PostalFulfillmentAction = {
  __typename?: 'PostalFulfillmentAction'
  color?: Maybe<Scalars['String']['output']>
  eventName?: Maybe<OrderEventNames>
  name?: Maybe<Scalars['String']['output']>
  tooltip?: Maybe<Scalars['String']['output']>
}

export type PostalFulfillmentFilterInput = {
  approvedPostalId?: InputMaybe<ObjectIdFilterInput>
  campaignId?: InputMaybe<ObjectIdFilterInput>
  contactId?: InputMaybe<ObjectIdFilterInput>
  created?: InputMaybe<InstantFilterInput>
  failureReason?: InputMaybe<StringFilterInput>
  fulfillments_quantity?: InputMaybe<IntFilterInput>
  id?: InputMaybe<ObjectIdFilterInput>
  itemName?: InputMaybe<StringFilterInput>
  magicLinkId?: InputMaybe<ObjectIdFilterInput>
  playbookDefinitionId?: InputMaybe<ObjectIdFilterInput>
  playbookId?: InputMaybe<ObjectIdFilterInput>
  sendType?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  teamId?: InputMaybe<ObjectIdFilterInput>
  userId?: InputMaybe<ObjectIdFilterInput>
}

export enum PostalFulfillmentOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type PostalOrderRequestInput = {
  approvedPostalId: Scalars['ObjectId']['input']
  approvedPostalVariantId: Scalars['ObjectId']['input']
  attribution?: InputMaybe<AttributionInput>
  contactId: Scalars['ObjectId']['input']
  deliveryEmail?: InputMaybe<Scalars['Boolean']['input']>
  deliveryEmailMessage?: InputMaybe<Scalars['String']['input']>
  designTemplate?: InputMaybe<DesignTemplateInput>
  emailSubjectLine?: InputMaybe<Scalars['String']['input']>
  emailTemplateId?: InputMaybe<Scalars['ObjectId']['input']>
  formFieldList?: InputMaybe<Array<FormFieldInput>>
  giftEmailReminderSettings?: InputMaybe<GiftEmailReminderSettingsInput>
  giftMessage?: InputMaybe<Scalars['String']['input']>
  itemCustomizationInputs?: InputMaybe<Array<ItemCustomizationInputInput>>
  landingPageCustomization?: InputMaybe<LandingPageCustomizationInput>
  meetingRequestSetting?: InputMaybe<MeetingRequestSetting>
  physicalMessage?: InputMaybe<Scalars['String']['input']>
  quantity?: InputMaybe<Scalars['Int']['input']>
  recipientEmailSettings?: InputMaybe<RecipientEmailSettingsInput>
  sendAsContactOwner?: InputMaybe<Scalars['Boolean']['input']>
  sendAsUser?: InputMaybe<Scalars['ObjectId']['input']>
  sendType?: InputMaybe<SendType>
  shipToAddress?: InputMaybe<AddressInput>
  skipDuplicateOrderCheck?: InputMaybe<Scalars['Boolean']['input']>
  spendAs?: InputMaybe<SpendAsInput>
}

export enum PostalPlan {
  Business = 'BUSINESS',
  Enterprise = 'ENTERPRISE',
  None = 'NONE',
  Payg = 'PAYG',
  Platform = 'PLATFORM',
  Professional = 'PROFESSIONAL',
}

export type PrepayCalculationResult = {
  __typename?: 'PrepayCalculationResult'
  feeInfo: AddFundsFeeInfo
  grossPaymentAmount: Scalars['Int']['output']
  netPaymentAmount: Scalars['Int']['output']
}

export type PreviewAddFundsInput = {
  amount: Scalars['Int']['input']
  paymentPartnerType?: InputMaybe<PaymentPartnerType>
}

export type PreviewGiftEmailAcceptancePageInput = {
  approvedPostalId: Scalars['ObjectId']['input']
  contactId?: InputMaybe<Scalars['ObjectId']['input']>
  landingPageCustomization?: InputMaybe<LandingPageCustomizationInput>
  message?: InputMaybe<Scalars['String']['input']>
  sendAsUser?: InputMaybe<Scalars['ObjectId']['input']>
  variantId: Scalars['ObjectId']['input']
}

export type PreviewGiftEmailAcceptancePageResponse = {
  __typename?: 'PreviewGiftEmailAcceptancePageResponse'
  htmlUrl: Scalars['String']['output']
}

export type PreviewMagicLinkResponse = {
  __typename?: 'PreviewMagicLinkResponse'
  htmlUrl: Scalars['String']['output']
  previewImageMobileUrl?: Maybe<Scalars['String']['output']>
  previewImageUrl?: Maybe<Scalars['String']['output']>
}

export enum PriceStructure {
  FlatFee = 'FLAT_FEE',
  PerAttendee = 'PER_ATTENDEE',
}

export enum Product {
  PostalIoAbm = 'POSTAL_IO_ABM',
  PostalIoAdmin = 'POSTAL_IO_ADMIN',
  PostalIoApp = 'POSTAL_IO_APP',
  PostalIoStore = 'POSTAL_IO_STORE',
  PostalIoVendor = 'POSTAL_IO_VENDOR',
  PostalIoWarehouse = 'POSTAL_IO_WAREHOUSE',
}

export type ProductAccess = {
  __typename?: 'ProductAccess'
  accountId?: Maybe<Scalars['ObjectId']['output']>
  created: ActionRecord
  id: Scalars['ObjectId']['output']
  partnerId?: Maybe<Scalars['ObjectId']['output']>
  product: Product
  roles?: Maybe<Array<Scalars['String']['output']>>
  teamId?: Maybe<Scalars['ObjectId']['output']>
  type?: Maybe<ProductAccessType>
}

export type ProductAccessFilterInput = {
  roles?: InputMaybe<StringFilterInput>
  teamId?: InputMaybe<ObjectIdFilterInput>
}

export type ProductAccessInput = {
  roles: Array<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}

export enum ProductAccessType {
  Account = 'ACCOUNT',
  Partner = 'PARTNER',
  Store = 'STORE',
  Team = 'TEAM',
}

export type ProductVariant = {
  __typename?: 'ProductVariant'
  attributes?: Maybe<Scalars['Object']['output']>
  containsPerishables?: Maybe<Scalars['Boolean']['output']>
  description?: Maybe<Scalars['String']['output']>
  displayPrice?: Maybe<Scalars['Int']['output']>
  flatFees?: Maybe<Scalars['Int']['output']>
  fulfillmentPartnerList?: Maybe<Array<FulfillmentMap>>
  giftMessageSupported?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ObjectId']['output']
  imageUrls?: Maybe<Array<ImageReference>>
  manufacturerPartId?: Maybe<Scalars['String']['output']>
  minimumTransactions?: Maybe<Scalars['Int']['output']>
  physicalMessageSupported?: Maybe<Scalars['Boolean']['output']>
  size?: Maybe<Scalars['String']['output']>
  status: Status
  upcCode?: Maybe<Scalars['String']['output']>
  variantName: Scalars['String']['output']
}

export enum QuarterCalculationMethod {
  Block_90Days = 'BLOCK_90_DAYS',
  Calendar = 'CALENDAR',
  OffsetMonths = 'OFFSET_MONTHS',
}

export type Query = {
  __typename?: 'Query'
  accessToken?: Maybe<TokenResponse>
  assets?: Maybe<Array<Maybe<Asset>>>
  autocompleteContacts: ContactSearchResponse
  basicAnalyticsV2?: Maybe<Array<BasicMetrics>>
  billingAccounts?: Maybe<Array<BillingAccount>>
  checkAutomationsTiedToPlaybook?: Maybe<Array<Automations>>
  checkAutomationsTiedToPostal?: Maybe<Array<Automations>>
  countPostalFulfillments: Scalars['Int']['output']
  downloadContactData: Scalars['String']['output']
  downloadReport?: Maybe<Scalars['String']['output']>
  eventLimitCheck: EventLimitResult
  eventLimitDetails?: Maybe<Array<CountAggregationResult>>
  fetchTransactionPdf: BackgroundTask
  getAccount: Account
  getAccountManager: CorpCrmUser
  getAdditionalEmailReminderOptions?: Maybe<Array<DropdownOptions>>
  getApprovedPostal: ApprovedPostal
  getApprovedPostalBrandsCategories: BrandsCategories
  getApprovedPostalFilters?: Maybe<Array<MarketplaceFilter>>
  getBackgroundTaskById?: Maybe<BackgroundTask>
  getBalanceRemaining?: Maybe<Array<BillingAccountBalance>>
  getBillingAccount: BillingAccount
  getBlockFreeBusy?: Maybe<Array<BlockFreeBusy>>
  getBlocklistEntry: BlocklistEntry
  getBrandsCategories: BrandsCategories
  getBudgetRemaining: BudgetRemaining
  getBudgetRemainingSummary: UsersBudgetSummary
  getCachedCurrencyConfigs?: Maybe<Array<CachedCurrencyConfig>>
  getCampaign: Campaign
  getConnections?: Maybe<Array<Connection>>
  getContact: Contact
  getContactByIntegration: Contact
  getCrmAccountByIdentifier: CrmAccount
  getCrmPostalCustomFields: CrmPostalCustomFieldResponse
  getCrmReport: CrmReport
  getDailyFreeBusy?: Maybe<Array<DayFreeBusy>>
  getDataList: CrmDataList
  getExternalCampaignMapping: ExtCampaignMapping
  getFavoriteItem: FavoriteItem
  getFunds?: Maybe<Scalars['Int']['output']>
  getIntegrationConnection: IntegrationConnection
  getIntegrationTrigger: IntegrationTrigger
  getLedger: Ledger
  getLocationData: LocationData
  getMagicLink: MagicLink
  getMarketplaceFilters?: Maybe<Array<MarketplaceFilter>>
  getMarketplaceProduct: MarketplaceProduct
  getMaxPhysicalNoteCharacters: Scalars['Int']['output']
  getOrderRules: OrderRules
  getPlaybookDefinition: PlaybookDefinition
  getPlaybookInstance: PlaybookInstance
  getPostalFulfillment: PostalFulfillment
  getPostalFulfillmentActions?: Maybe<Array<Maybe<PostalFulfillmentAction>>>
  getRecipe: Recipe
  getRecipes?: Maybe<Array<Recipe>>
  getRolePermissions?: Maybe<RolePermission>
  getSavedSend: SavedSend
  getSequenceSteps: ExternalSequenceStepResponse
  getSequences: ExternalSequenceResponse
  getTeamById: Team
  getTransferIntent: TransferIntent
  getTrialExpiredStats: TrialExpiredStats
  getUser: User
  getWorkatoJWT: Scalars['String']['output']
  invites?: Maybe<Array<Maybe<Invite>>>
  magicLinkAnalytics?: Maybe<Array<BasicMetrics>>
  marketplaceSearch: MarketplaceSearchResponse
  marketplaceSuggest: MarketplaceSearchResponse
  me: UserAccount
  messageTemplates?: Maybe<Array<MessageTemplate>>
  modules?: Maybe<Array<Maybe<Module>>>
  namedProductAccessList?: Maybe<Array<Maybe<NamedProductAccess>>>
  objectAnalytics: BasicMetrics
  ping: Scalars['Boolean']['output']
  playbookAnalytics?: Maybe<Array<BasicMetrics>>
  postalAnalytics?: Maybe<Array<BasicMetrics>>
  postalAnalyticsV2?: Maybe<Array<ApprovedPostalAnalytics>>
  previewAddFunds: PrepayCalculationResult
  previewEvent: EventPreview
  previewGiftEmailAcceptancePage: PreviewGiftEmailAcceptancePageResponse
  previewMagicLink: PreviewMagicLinkResponse
  previewOrder: OrderPreview
  previewOrderV2: OrderPreviewV2
  productAccessList?: Maybe<Array<Maybe<ProductAccess>>>
  searchActivityStream?: Maybe<Array<ActivityStream>>
  searchAddFundsIntents?: Maybe<Array<AddFundsIntent>>
  searchApprovedPostals?: Maybe<Array<ApprovedPostal>>
  searchAssets?: Maybe<Array<Asset>>
  searchBackgroundTasks?: Maybe<Array<BackgroundTask>>
  searchBillingAccounts?: Maybe<Array<BillingAccount>>
  searchBlocklistEntrys?: Maybe<Array<BlocklistEntry>>
  searchBudgetHistory: BudgetHistorySearchResponse
  searchCampaigns?: Maybe<Array<Campaign>>
  searchContactLists?: Maybe<Array<ContactList>>
  searchContactPostalFulfillments?: Maybe<Array<PostalFulfillment>>
  searchContactsV2: ContactSearchResponseV2
  searchEmailTemplates?: Maybe<Array<EmailTemplate>>
  searchEventPostalFulfillments?: Maybe<Array<PostalFulfillment>>
  searchFavoriteItems?: Maybe<Array<FavoriteItem>>
  searchIntegrationConnection?: Maybe<Array<IntegrationConnection>>
  searchIntegrationSync?: Maybe<Array<IntegrationSync>>
  searchIntegrationTriggerResults?: Maybe<Array<IntegrationTriggerResult>>
  searchIntegrationTriggers?: Maybe<Array<IntegrationTrigger>>
  searchLedger?: Maybe<Array<Ledger>>
  searchMagicLinks?: Maybe<Array<MagicLink>>
  searchMarketplaceProducts?: Maybe<Array<MarketplaceProduct>>
  searchMessageTemplates?: Maybe<Array<MessageTemplate>>
  searchNoAccessUsers: UserSearchResponse
  searchPlaybookDefinitions?: Maybe<Array<PlaybookDefinition>>
  searchPlaybookInstances?: Maybe<Array<PlaybookInstance>>
  searchPostalFulfillments?: Maybe<Array<PostalFulfillment>>
  searchReportOutputInstances?: Maybe<Array<ReportOutputInstance>>
  searchSavedSends?: Maybe<Array<SavedSend>>
  searchTags?: Maybe<Array<Tag>>
  searchTransferIntents?: Maybe<Array<TransferIntent>>
  searchUsers: UserSearchResponse
  teams?: Maybe<Array<Team>>
  triggerAnalytics?: Maybe<Array<BasicMetrics>>
  userAnalyticsV2?: Maybe<Array<UserAnalytics>>
  verifyAddress: Address
  verifyEmailConnection: Scalars['Boolean']['output']
}

export type QueryAccessTokenArgs = {
  id?: InputMaybe<Scalars['ObjectId']['input']>
}

export type QueryAutocompleteContactsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  prefix: Scalars['String']['input']
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QueryBasicAnalyticsV2Args = {
  config: AnalyticsRequestConfig
}

export type QueryBillingAccountsArgs = {
  filter?: InputMaybe<BillingAccountFilterInput>
}

export type QueryCheckAutomationsTiedToPlaybookArgs = {
  playbookDefinitionId: Scalars['ObjectId']['input']
}

export type QueryCheckAutomationsTiedToPostalArgs = {
  approvedPostalId: Scalars['ObjectId']['input']
}

export type QueryCountPostalFulfillmentsArgs = {
  filter?: InputMaybe<PostalFulfillmentFilterInput>
}

export type QueryDownloadContactDataArgs = {
  contactId: Scalars['ObjectId']['input']
}

export type QueryDownloadReportArgs = {
  reportOutputInstanceId: Scalars['ObjectId']['input']
}

export type QueryEventLimitCheckArgs = {
  eventDate: Scalars['Instant']['input']
}

export type QueryFetchTransactionPdfArgs = {
  billingAccountId: Scalars['ObjectId']['input']
  billingTransactionType: BillingTransactionType
  entityId: Scalars['ObjectId']['input']
}

export type QueryGetAdditionalEmailReminderOptionsArgs = {
  sendDate?: InputMaybe<Scalars['Instant']['input']>
}

export type QueryGetApprovedPostalArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetApprovedPostalFiltersArgs = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>
  category?: InputMaybe<Scalars['String']['input']>
  statuses?: InputMaybe<Array<Status>>
}

export type QueryGetBackgroundTaskByIdArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetBillingAccountArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetBlockFreeBusyArgs = {
  marketplaceProductId: Scalars['ObjectId']['input']
  startDate: Scalars['Instant']['input']
  timeZone: Scalars['String']['input']
}

export type QueryGetBlocklistEntryArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetBudgetRemainingArgs = {
  accountId: Scalars['ObjectId']['input']
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  userId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type QueryGetBudgetRemainingSummaryArgs = {
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type QueryGetCachedCurrencyConfigsArgs = {
  filter?: InputMaybe<CachedCurrencyConfigFilterInput>
}

export type QueryGetCampaignArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetContactArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetContactByIntegrationArgs = {
  contactType?: InputMaybe<ContactType>
  id: Scalars['String']['input']
  systemName: Scalars['String']['input']
}

export type QueryGetCrmAccountByIdentifierArgs = {
  crmIdentifier: Scalars['String']['input']
}

export type QueryGetCrmPostalCustomFieldsArgs = {
  system: Scalars['String']['input']
  type: CustomFieldDataListType
}

export type QueryGetCrmReportArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>
  refresh?: InputMaybe<Scalars['Boolean']['input']>
  reportType: CrmReportType
  startDate?: InputMaybe<Scalars['String']['input']>
  system: Scalars['String']['input']
}

export type QueryGetDailyFreeBusyArgs = {
  endDate: Scalars['Instant']['input']
  marketplaceProductId: Scalars['ObjectId']['input']
  startDate: Scalars['Instant']['input']
  timeZone: Scalars['String']['input']
}

export type QueryGetDataListArgs = {
  searchString?: InputMaybe<Scalars['String']['input']>
  system: Scalars['String']['input']
  type: DataListType
}

export type QueryGetExternalCampaignMappingArgs = {
  campaignId: Scalars['String']['input']
  system: Scalars['String']['input']
}

export type QueryGetFavoriteItemArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetFundsArgs = {
  billingAccountId: Scalars['ObjectId']['input']
}

export type QueryGetIntegrationConnectionArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetIntegrationTriggerArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetLedgerArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetMagicLinkArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetMarketplaceFiltersArgs = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>
  category?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetMarketplaceProductArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetMaxPhysicalNoteCharactersArgs = {
  approvedPostalId: Scalars['ObjectId']['input']
  variantId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type QueryGetOrderRulesArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetPlaybookDefinitionArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetPlaybookInstanceArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetPostalFulfillmentArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetPostalFulfillmentActionsArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetRecipeArgs = {
  id: Scalars['String']['input']
}

export type QueryGetRolePermissionsArgs = {
  role: Scalars['String']['input']
}

export type QueryGetSavedSendArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetSequenceStepsArgs = {
  sequenceId?: InputMaybe<Scalars['String']['input']>
  system: Scalars['String']['input']
}

export type QueryGetSequencesArgs = {
  name?: InputMaybe<Scalars['String']['input']>
  system: Scalars['String']['input']
}

export type QueryGetTeamByIdArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetTransferIntentArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetUserArgs = {
  id: Scalars['ObjectId']['input']
}

export type QueryGetWorkatoJwtArgs = {
  test?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryInvitesArgs = {
  filter?: InputMaybe<InviteFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<InviteOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QueryMagicLinkAnalyticsArgs = {
  endDate?: InputMaybe<Scalars['Instant']['input']>
  granularity: Granularity
  magicLinkId?: InputMaybe<Scalars['ObjectId']['input']>
  startDate?: InputMaybe<Scalars['Instant']['input']>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  userId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type QueryMarketplaceSearchArgs = {
  query?: InputMaybe<MarketplaceSearchQueryInput>
}

export type QueryMarketplaceSuggestArgs = {
  query?: InputMaybe<MarketplaceSuggestQueryInput>
}

export type QueryMessageTemplatesArgs = {
  filter?: InputMaybe<MessageTemplateFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<MessageTemplateOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QueryObjectAnalyticsArgs = {
  filter?: InputMaybe<AnalyticsFilterInput>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  type: DataObjectType
  userId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type QueryPlaybookAnalyticsArgs = {
  endDate?: InputMaybe<Scalars['Instant']['input']>
  granularity: Granularity
  playbookDefinitionId?: InputMaybe<Scalars['ObjectId']['input']>
  startDate?: InputMaybe<Scalars['Instant']['input']>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  userId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type QueryPostalAnalyticsArgs = {
  endDate?: InputMaybe<Scalars['Instant']['input']>
  granularity: Granularity
  startDate?: InputMaybe<Scalars['Instant']['input']>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  userId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type QueryPostalAnalyticsV2Args = {
  config: AnalyticsRequestConfig
}

export type QueryPreviewAddFundsArgs = {
  input: PreviewAddFundsInput
}

export type QueryPreviewEventArgs = {
  attendeeCount: Scalars['Int']['input']
  marketplaceProductId: Scalars['ObjectId']['input']
  productVariantId: Scalars['ObjectId']['input']
}

export type QueryPreviewGiftEmailAcceptancePageArgs = {
  data: PreviewGiftEmailAcceptancePageInput
}

export type QueryPreviewMagicLinkArgs = {
  approvedPostalId: Scalars['ObjectId']['input']
  landingPageCustomization?: InputMaybe<LandingPageCustomizationInput>
  message: Scalars['String']['input']
  variantId: Scalars['ObjectId']['input']
}

export type QueryPreviewOrderArgs = {
  approvedPostalId: Scalars['ObjectId']['input']
  approvedPostalVariantId: Scalars['ObjectId']['input']
  count?: InputMaybe<Scalars['Int']['input']>
  deliveryEmail?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<ContactFilterInput>
  orderType?: InputMaybe<DataObjectType>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput>>
  quantity?: InputMaybe<Scalars['Int']['input']>
  spendAs?: InputMaybe<SpendAsInput>
  v2filter?: InputMaybe<SearchableContactFilterInput>
}

export type QueryPreviewOrderV2Args = {
  contactFilters?: InputMaybe<Array<SearchableContactFilterInput>>
  request: OrderPreviewV2Request
}

export type QuerySearchActivityStreamArgs = {
  filter?: InputMaybe<ActivityStreamFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<ActivityStreamOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchAddFundsIntentsArgs = {
  filter?: InputMaybe<AddFundsIntentFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<AddFundsIntentOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchApprovedPostalsArgs = {
  filter?: InputMaybe<ApprovedPostalFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<DefaultOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchAssetsArgs = {
  filter?: InputMaybe<AssetFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<AssetOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchBackgroundTasksArgs = {
  filter?: InputMaybe<BackgroundTaskFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<BackgroundTaskOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchBillingAccountsArgs = {
  filter?: InputMaybe<BillingAccountFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<BillingAccountOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchBlocklistEntrysArgs = {
  filter?: InputMaybe<BlocklistEntryFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<BlocklistEntryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchBudgetHistoryArgs = {
  filter?: InputMaybe<BudgetHistoryFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<BudgetHistoryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchCampaignsArgs = {
  filter?: InputMaybe<CampaignFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<CampaignOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchContactListsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchContactPostalFulfillmentsArgs = {
  contactId: Scalars['ObjectId']['input']
  filter?: InputMaybe<PostalFulfillmentFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<PostalFulfillmentOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchContactsV2Args = {
  filter?: InputMaybe<SearchableContactFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<SearchableContactOrderByInput>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput>>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchEmailTemplatesArgs = {
  filter?: InputMaybe<EmailTemplateFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<GenericFilterInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchEventPostalFulfillmentsArgs = {
  filter?: InputMaybe<PostalFulfillmentFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<PostalFulfillmentOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchFavoriteItemsArgs = {
  filter?: InputMaybe<FavoriteItemFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<DefaultOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchIntegrationConnectionArgs = {
  filter?: InputMaybe<IntegrationConnectionFilterInput>
}

export type QuerySearchIntegrationSyncArgs = {
  filter?: InputMaybe<IntegrationSyncFilterInput>
}

export type QuerySearchIntegrationTriggerResultsArgs = {
  filter?: InputMaybe<IntegrationTriggerResultFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchIntegrationTriggersArgs = {
  filter?: InputMaybe<IntegrationTriggerFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<IntegrationTriggerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchLedgerArgs = {
  filter?: InputMaybe<LedgerFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<LedgerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchMagicLinksArgs = {
  filter?: InputMaybe<MagicLinkFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<MagicLinkOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchMarketplaceProductsArgs = {
  filter?: InputMaybe<MarketplaceProductFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<DefaultOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchMessageTemplatesArgs = {
  filter?: InputMaybe<MessageTemplateFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<MessageTemplateOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchNoAccessUsersArgs = {
  filter?: InputMaybe<UserFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<UserOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchPlaybookDefinitionsArgs = {
  filter?: InputMaybe<PlaybookDefinitionFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<PlaybookDefinitionOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchPlaybookInstancesArgs = {
  filter?: InputMaybe<PlaybookInstanceFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<PlaybookInstanceOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchPostalFulfillmentsArgs = {
  filter?: InputMaybe<PostalFulfillmentFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<PostalFulfillmentOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchReportOutputInstancesArgs = {
  filter?: InputMaybe<ReportOutputFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<ReportOutputOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchSavedSendsArgs = {
  filter?: InputMaybe<SavedSendFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<SavedSendOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchTagsArgs = {
  filter?: InputMaybe<TagFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchTransferIntentsArgs = {
  filter?: InputMaybe<TransferIntentFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<TransferIntentOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchUsersArgs = {
  filter?: InputMaybe<UserFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<UserOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QueryTeamsArgs = {
  filter?: InputMaybe<TeamFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<TeamOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type QueryTriggerAnalyticsArgs = {
  endDate?: InputMaybe<Scalars['Instant']['input']>
  granularity: Granularity
  startDate?: InputMaybe<Scalars['Instant']['input']>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  triggerId?: InputMaybe<Scalars['ObjectId']['input']>
  userId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type QueryUserAnalyticsV2Args = {
  config: AnalyticsRequestConfig
}

export type QueryVerifyAddressArgs = {
  data: VerifyAddressInput
}

export type QueryVerifyEmailConnectionArgs = {
  ssoMethodName: Scalars['String']['input']
}

export type QueryResults = {
  __typename?: 'QueryResults'
  label?: Maybe<Scalars['String']['output']>
  result?: Maybe<ObjectList>
}

export type Recipe = {
  __typename?: 'Recipe'
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  jobFailedCount: Scalars['Int']['output']
  jobSucceededCount: Scalars['Int']['output']
  lastRunAt?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  running: Scalars['Boolean']['output']
  stopCause?: Maybe<Scalars['String']['output']>
  stoppedAt?: Maybe<Scalars['String']['output']>
}

export type RecipientEmailSettings = {
  __typename?: 'RecipientEmailSettings'
  deliveredEmailsOn?: Maybe<Scalars['Boolean']['output']>
  shippedEmailsOn?: Maybe<Scalars['Boolean']['output']>
}

export type RecipientEmailSettingsInput = {
  deliveredEmailsOn?: InputMaybe<Scalars['Boolean']['input']>
  shippedEmailsOn?: InputMaybe<Scalars['Boolean']['input']>
}

export enum ReportDataType {
  Postal = 'POSTAL',
  User = 'USER',
}

export type ReportOutputFilterInput = {
  type: DataObjectType
}

export type ReportOutputInstance = {
  __typename?: 'ReportOutputInstance'
  fileName: Scalars['String']['output']
  id: Scalars['ObjectId']['output']
  name: Scalars['String']['output']
  type: DataObjectType
}

export type ReportOutputInstanceInput = {
  fileName: Scalars['String']['input']
  type: DataObjectType
}

export enum ReportOutputOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type RequestFundsByInvoiceV2Request = {
  amountCents: Scalars['Int']['input']
  billingAccountId: Scalars['ObjectId']['input']
}

export type RequestFundsByInvoiceV2Response = {
  __typename?: 'RequestFundsByInvoiceV2Response'
  addFundsIntentId: Scalars['ObjectId']['output']
}

export enum Requirements {
  Computer = 'COMPUTER',
  InPerson = 'IN_PERSON',
  Phone = 'PHONE',
  Tablet = 'TABLET',
}

export enum Result {
  Error = 'ERROR',
  Success = 'SUCCESS',
}

export type ResultsSummary = {
  __typename?: 'ResultsSummary'
  currentPage: Scalars['Int']['output']
  hasMore: Scalars['Boolean']['output']
  totalPages?: Maybe<Scalars['Int']['output']>
  totalRecords?: Maybe<Scalars['Int']['output']>
}

export enum Role {
  AccessSelection = 'ACCESS_SELECTION',
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  None = 'NONE',
  Signup = 'SIGNUP',
  TeamAdmin = 'TEAM_ADMIN',
  User = 'USER',
}

export type RoleAssignmentResult = {
  __typename?: 'RoleAssignmentResult'
  reason?: Maybe<Scalars['String']['output']>
  result: Result
  role: Role
}

export type RolePermission = {
  __typename?: 'RolePermission'
  available: Array<PermissionDefinition>
  permissions: Array<Scalars['String']['output']>
  roleName: Scalars['String']['output']
}

export type SavedSend = {
  __typename?: 'SavedSend'
  commonSendProperties: CommonSendProperties
  created: ActionRecord
  id: Scalars['ObjectId']['output']
  savedSendBulkSendInfo?: Maybe<SavedSendBulkSendInfo>
  savedSendCampaignInfo?: Maybe<SavedSendCampaignInfo>
  savedSendMagicLinkInfo?: Maybe<SavedSendMagicLinkInfo>
  savedSendName: Scalars['String']['output']
  sendFlowStep?: Maybe<SendFlowStep>
  sendType: SavedSendType
  status: SavedSendStatus
  teamId?: Maybe<Scalars['ObjectId']['output']>
  userId: Scalars['ObjectId']['output']
  userLink?: Maybe<UserLink>
}

export type SavedSendBulkSendInfo = {
  __typename?: 'SavedSendBulkSendInfo'
  addressVerified?: Maybe<Scalars['Boolean']['output']>
  contactId?: Maybe<Scalars['ObjectId']['output']>
  newContact?: Maybe<NewContact>
  quantity?: Maybe<Scalars['Int']['output']>
  shipToAddress?: Maybe<Address>
}

export type SavedSendBulkSendInfoInput = {
  addressVerified?: InputMaybe<Scalars['Boolean']['input']>
  contactId?: InputMaybe<Scalars['ObjectId']['input']>
  newContact?: InputMaybe<NewContactInput>
  quantity?: InputMaybe<Scalars['Int']['input']>
  shipToAddress?: InputMaybe<AddressInput>
}

export type SavedSendCampaignInfo = {
  __typename?: 'SavedSendCampaignInfo'
  contactCount?: Maybe<Scalars['Int']['output']>
  contactSearchFilters?: Maybe<Array<Scalars['Object']['output']>>
  deliveryEmail?: Maybe<Scalars['Boolean']['output']>
  deliveryEmailTemplateId?: Maybe<Scalars['ObjectId']['output']>
  emailSubjectLine?: Maybe<Scalars['String']['output']>
  giftMessage?: Maybe<Scalars['String']['output']>
  scheduleDate?: Maybe<Scalars['Instant']['output']>
  useSameMessage?: Maybe<Scalars['Boolean']['output']>
}

export type SavedSendCampaignInfoInput = {
  contactSearchFilters?: InputMaybe<Array<SearchableContactFilterInput>>
  deliveryEmail?: InputMaybe<Scalars['Boolean']['input']>
  deliveryEmailTemplateId?: InputMaybe<Scalars['ObjectId']['input']>
  emailSubjectLine?: InputMaybe<Scalars['String']['input']>
  giftMessage?: InputMaybe<Scalars['String']['input']>
  scheduleDate?: InputMaybe<Scalars['Instant']['input']>
  useSameMessage?: InputMaybe<Scalars['Boolean']['input']>
}

export type SavedSendFilterInput = {
  commonSendProperties_name?: InputMaybe<StringFilterInput>
  savedSendBulkSendInfo_quantity?: InputMaybe<IntFilterInput>
  savedSendCampaignInfo_contactCount?: InputMaybe<IntFilterInput>
  savedSendName?: InputMaybe<StringFilterInput>
  sendFlowStep?: InputMaybe<StringFilterInput>
  sendType?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
}

export type SavedSendInput = {
  commonSendProperties: CommonSendPropertiesInput
  savedSendBulkSendInfo?: InputMaybe<SavedSendBulkSendInfoInput>
  savedSendCampaignInfo?: InputMaybe<SavedSendCampaignInfoInput>
  savedSendMagicLinkInfo?: InputMaybe<SavedSendMagicLinkInfoInput>
  savedSendName: Scalars['String']['input']
  sendFlowStep?: InputMaybe<SendFlowStep>
  sendType: SavedSendType
  status: SavedSendStatus
}

export type SavedSendMagicLinkInfo = {
  __typename?: 'SavedSendMagicLinkInfo'
  expirationDate?: Maybe<Scalars['Instant']['output']>
  maxExecutions?: Maybe<Scalars['Int']['output']>
  message?: Maybe<Scalars['String']['output']>
  requiresApproval?: Maybe<Scalars['Boolean']['output']>
  status?: Maybe<MagicLinkStatus>
}

export type SavedSendMagicLinkInfoInput = {
  expirationDate?: InputMaybe<Scalars['Instant']['input']>
  maxExecutions?: InputMaybe<Scalars['Int']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  requiresApproval?: InputMaybe<Scalars['Boolean']['input']>
  status?: InputMaybe<MagicLinkStatus>
}

export enum SavedSendOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SavedSendNameAsc = 'savedSendName_ASC',
  SavedSendNameDesc = 'savedSendName_DESC',
}

export enum SavedSendStatus {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  SentDraft = 'SENT_DRAFT',
  Template = 'TEMPLATE',
}

export enum SavedSendType {
  BulkSend = 'BULK_SEND',
  Direct = 'DIRECT',
  GiftEmail = 'GIFT_EMAIL',
  MagicLink = 'MAGIC_LINK',
}

export type SearchContext = {
  accountId?: InputMaybe<Scalars['ObjectId']['input']>
  itemTypes: Array<ItemType>
  storeId?: InputMaybe<Scalars['ObjectId']['input']>
  teamIds?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  userId?: InputMaybe<Scalars['ObjectId']['input']>
}

export type SearchableContact = {
  __typename?: 'SearchableContact'
  campaignStatus?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  campaigns?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  city?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  companyName?: Maybe<Scalars['String']['output']>
  costInCents?: Maybe<Scalars['Int']['output']>
  country?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  created?: Maybe<Scalars['Instant']['output']>
  emailAddress?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ObjectId']['output']
  itemsSent?: Maybe<Scalars['Int']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  lastSend?: Maybe<Scalars['Instant']['output']>
  lists?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  ownerId?: Maybe<Scalars['String']['output']>
  playbooks?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  postalCode?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  state?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  title?: Maybe<Scalars['String']['output']>
  updated?: Maybe<Scalars['Instant']['output']>
  verifiedAddress?: Maybe<Scalars['Boolean']['output']>
}

export type SearchableContactFilterInput = {
  campaignStatus?: InputMaybe<StringFilterInput>
  campaigns?: InputMaybe<StringFilterInput>
  city?: InputMaybe<StringFilterInput>
  companyName?: InputMaybe<StringFilterInput>
  country?: InputMaybe<StringFilterInput>
  created?: InputMaybe<InstantFilterInput>
  emailAddress?: InputMaybe<StringFilterInput>
  firstName?: InputMaybe<StringFilterInput>
  id?: InputMaybe<StringFilterInput>
  lastName?: InputMaybe<StringFilterInput>
  lastSend?: InputMaybe<InstantFilterInput>
  lists?: InputMaybe<StringFilterInput>
  ownerId?: InputMaybe<StringFilterInput>
  playbooks?: InputMaybe<StringFilterInput>
  postalCode?: InputMaybe<StringFilterInput>
  q?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<StringFilterInput>
  tags?: InputMaybe<StringFilterInput>
  title?: InputMaybe<StringFilterInput>
}

export enum SearchableContactOrderByInput {
  CostInCentsAsc = 'costInCents_ASC',
  CostInCentsDesc = 'costInCents_DESC',
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ItemsSentAsc = 'itemsSent_ASC',
  ItemsSentDesc = 'itemsSent_DESC',
  LastSendAsc = 'lastSend_ASC',
  LastSendDesc = 'lastSend_DESC',
  QAsc = 'q_ASC',
  QDesc = 'q_DESC',
}

export type SearchableProduct = {
  __typename?: 'SearchableProduct'
  brandName?: Maybe<Scalars['String']['output']>
  categories?: Maybe<Array<Scalars['String']['output']>>
  containsPerishables?: Maybe<Scalars['Boolean']['output']>
  contexts: Array<Scalars['String']['output']>
  currency: Currency
  description: Scalars['String']['output']
  estimatedShippingTime?: Maybe<Scalars['String']['output']>
  eventDate?: Maybe<Scalars['Instant']['output']>
  id: Scalars['String']['output']
  imageUrls: Array<Scalars['String']['output']>
  inventory?: Maybe<Scalars['Int']['output']>
  itemCount?: Maybe<Scalars['Int']['output']>
  itemId: Scalars['String']['output']
  itemName: Scalars['String']['output']
  itemType: ItemType
  lastRegistrationDate?: Maybe<Scalars['Instant']['output']>
  magicEventStatus?: Maybe<MagicEventStatus>
  maxPrice: Scalars['Int']['output']
  minCost: Scalars['Int']['output']
  minPrice: Scalars['Int']['output']
  postalPreferred?: Maybe<Scalars['Boolean']['output']>
  productGroupIds?: Maybe<Array<Scalars['String']['output']>>
  rating?: Maybe<Scalars['Float']['output']>
  status: Status
  title: Scalars['String']['output']
}

export enum SendFlowStep {
  ChooseMethod = 'CHOOSE_METHOD',
  ContactSelection = 'CONTACT_SELECTION',
  ItemCustomization = 'ITEM_CUSTOMIZATION',
  OrderPreview = 'ORDER_PREVIEW',
  SendCustomization = 'SEND_CUSTOMIZATION',
}

export enum SendType {
  BulkSend = 'BULK_SEND',
  Direct = 'DIRECT',
  GiftEmail = 'GIFT_EMAIL',
  MagicLink = 'MAGIC_LINK',
  Storefront = 'STOREFRONT',
  Subscription = 'SUBSCRIPTION',
  Trigger = 'TRIGGER',
}

export type SetDefaultPaymentMethodInput = {
  partnerPaymentMethodId: Scalars['String']['input']
  paymentPartnerType: PaymentPartnerType
}

export type Settings = {
  __typename?: 'Settings'
  billingAccountId?: Maybe<Scalars['ObjectId']['output']>
  billingAccountIds?: Maybe<Array<BillingAccountMap>>
  budget?: Maybe<Budget>
}

export type SettingsInput = {
  billingAccountId?: InputMaybe<Scalars['ObjectId']['input']>
  billingAccountIds?: InputMaybe<Array<BillingAccountMapInput>>
  budget?: InputMaybe<BudgetInput>
}

export enum SfdcTriggerTypes {
  Custom = 'CUSTOM',
  Event = 'EVENT',
  Opportunity = 'OPPORTUNITY',
  Task = 'TASK',
}

export type ShareLink = {
  __typename?: 'ShareLink'
  target: Scalars['ObjectId']['output']
  type: LinkType
}

export type ShareLinkInput = {
  target: Scalars['ObjectId']['input']
  type: LinkType
}

export type ShippingOption = {
  __typename?: 'ShippingOption'
  name: Scalars['String']['output']
  price: Scalars['Int']['output']
}

export type SignupInput = {
  accountName: Scalars['String']['input']
  collectedData?: InputMaybe<Scalars['Object']['input']>
  country: Scalars['String']['input']
  state: Scalars['String']['input']
}

export enum SortBy {
  AcceptedDesc = 'ACCEPTED_DESC',
  DateAsc = 'DATE_ASC',
  GiftEmailSentDesc = 'GIFT_EMAIL_SENT_DESC',
  LinkUniqViewDesc = 'LINK_UNIQ_VIEW_DESC',
  QueuedDesc = 'QUEUED_DESC',
}

export type SpendAs = {
  __typename?: 'SpendAs'
  teamId?: Maybe<Scalars['ObjectId']['output']>
  userId: Scalars['ObjectId']['output']
}

export type SpendAsInput = {
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  userId: Scalars['ObjectId']['input']
}

export type StartTrialResponse = {
  __typename?: 'StartTrialResponse'
  subscriptionId?: Maybe<Scalars['String']['output']>
}

export enum Status {
  Active = 'ACTIVE',
  Delete = 'DELETE',
  Disabled = 'DISABLED',
  OutOfStock = 'OUT_OF_STOCK',
  Pending = 'PENDING',
  VendorDisabled = 'VENDOR_DISABLED',
}

export type StreamCallout = {
  __typename?: 'StreamCallout'
  action?: Maybe<Scalars['String']['output']>
  dataId?: Maybe<Scalars['ObjectId']['output']>
  dataType?: Maybe<DataObjectType>
  targetText: Scalars['String']['output']
  text: Scalars['String']['output']
}

export type StringFilterInput = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  arrayeq?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  beginsWith?: InputMaybe<Scalars['String']['input']>
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  contains?: InputMaybe<Scalars['String']['input']>
  eq?: InputMaybe<Scalars['String']['input']>
  exists?: InputMaybe<Scalars['Boolean']['input']>
  ge?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  le?: InputMaybe<Scalars['String']['input']>
  lt?: InputMaybe<Scalars['String']['input']>
  ne?: InputMaybe<Scalars['String']['input']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  notContains?: InputMaybe<Scalars['String']['input']>
}

export type Tag = {
  __typename?: 'Tag'
  created: ActionRecord
  id: Scalars['ObjectId']['output']
  name: Scalars['String']['output']
  updated?: Maybe<ActionRecord>
}

export type TagFilterInput = {
  name?: InputMaybe<StringFilterInput>
}

export type TagInput = {
  name: Scalars['String']['input']
}

export type Team = {
  __typename?: 'Team'
  accountId: Scalars['ObjectId']['output']
  created?: Maybe<ActionRecord>
  id: Scalars['ObjectId']['output']
  name: Scalars['String']['output']
  parentTeamId?: Maybe<Scalars['ObjectId']['output']>
  settings?: Maybe<Settings>
  status: Status
}

export type TeamFilterInput = {
  id?: InputMaybe<StringFilterInput>
  name?: InputMaybe<StringFilterInput>
  settings_billingAccountId?: InputMaybe<ObjectIdFilterInput>
  settings_billingAccountIds?: InputMaybe<BillingAccountMapFilterInput>
  status?: InputMaybe<StringFilterInput>
  userId?: InputMaybe<TeamFilterInput>
}

export enum TeamOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type TokenResponse = {
  __typename?: 'TokenResponse'
  refreshToken?: Maybe<Scalars['String']['output']>
  token: Scalars['String']['output']
}

export enum TransactionRelatedType {
  ApprovedPostal = 'APPROVED_POSTAL',
  PostalFulfillment = 'POSTAL_FULFILLMENT',
}

export type TransferIntent = {
  __typename?: 'TransferIntent'
  accountId: Scalars['ObjectId']['output']
  comment: Scalars['String']['output']
  created?: Maybe<ActionRecord>
  fromAmount: Scalars['Int']['output']
  fromBillingAccountId: Scalars['ObjectId']['output']
  fromCurrency: Currency
  fromDebitMemoId?: Maybe<Scalars['String']['output']>
  fromDebitMemoNumber?: Maybe<Scalars['String']['output']>
  fxInfo?: Maybe<ForeignExchangeInfo>
  history?: Maybe<Array<TransferIntentHistory>>
  id: Scalars['ObjectId']['output']
  status: TransferIntentStatus
  toAmount: Scalars['Int']['output']
  toBillingAccountId: Scalars['ObjectId']['output']
  toCreditMemoId?: Maybe<Scalars['String']['output']>
  toCreditMemoNumber?: Maybe<Scalars['String']['output']>
  toCurrency: Currency
  updated?: Maybe<ActionRecord>
  userId: Scalars['ObjectId']['output']
}

export type TransferIntentFilterInput = {
  accountId?: InputMaybe<ObjectIdFilterInput>
  comment?: InputMaybe<StringFilterInput>
  created?: InputMaybe<InstantFilterInput>
  fromAmount?: InputMaybe<IntFilterInput>
  fromBillingAccountId?: InputMaybe<ObjectIdFilterInput>
  fromCurrency?: InputMaybe<StringFilterInput>
  fromDebitMemoId?: InputMaybe<StringFilterInput>
  fromDebitMemoNumber?: InputMaybe<StringFilterInput>
  id?: InputMaybe<ObjectIdFilterInput>
  status?: InputMaybe<StringFilterInput>
  toAmount?: InputMaybe<IntFilterInput>
  toBillingAccountId?: InputMaybe<ObjectIdFilterInput>
  toCreditMemoId?: InputMaybe<StringFilterInput>
  toCreditMemoNumber?: InputMaybe<StringFilterInput>
  toCurrency?: InputMaybe<StringFilterInput>
  updated?: InputMaybe<InstantFilterInput>
  userId?: InputMaybe<ObjectIdFilterInput>
}

export type TransferIntentHistory = {
  __typename?: 'TransferIntentHistory'
  created: Scalars['Instant']['output']
  notes?: Maybe<Scalars['String']['output']>
  status: TransferIntentStatus
}

export enum TransferIntentOrderByInput {
  AccountIdAsc = 'accountId_ASC',
  AccountIdDesc = 'accountId_DESC',
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  FromAmountAsc = 'fromAmount_ASC',
  FromAmountDesc = 'fromAmount_DESC',
  FromBillingAccountIdAsc = 'fromBillingAccountId_ASC',
  FromBillingAccountIdDesc = 'fromBillingAccountId_DESC',
  FromCurrencyAsc = 'fromCurrency_ASC',
  FromCurrencyDesc = 'fromCurrency_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  ToAmountAsc = 'toAmount_ASC',
  ToAmountDesc = 'toAmount_DESC',
  ToBillingAccountIdAsc = 'toBillingAccountId_ASC',
  ToBillingAccountIdDesc = 'toBillingAccountId_DESC',
  ToCurrencyAsc = 'toCurrency_ASC',
  ToCurrencyDesc = 'toCurrency_DESC',
  UpdatedAsc = 'updated_ASC',
  UpdatedDesc = 'updated_DESC',
  UserIdAsc = 'userId_ASC',
  UserIdDesc = 'userId_DESC',
}

export enum TransferIntentStatus {
  BalanceError = 'BALANCE_ERROR',
  BalanceSuccess = 'BALANCE_SUCCESS',
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Created = 'CREATED',
  UploadError = 'UPLOAD_ERROR',
  UploadPending = 'UPLOAD_PENDING',
  UploadProcessing = 'UPLOAD_PROCESSING',
  UploadSuccess = 'UPLOAD_SUCCESS',
}

export type TrialExpiredStats = {
  __typename?: 'TrialExpiredStats'
  campaignsCreated: Scalars['Int']['output']
  contactsCreated: Scalars['Int']['output']
  postalsSent: Scalars['Int']['output']
}

export enum TriggerAction {
  Nothing = 'NOTHING',
  SendPostal = 'SEND_POSTAL',
  StartPlaybook = 'START_PLAYBOOK',
  StopPlaybook = 'STOP_PLAYBOOK',
}

export type TriggerConfiguration = {
  __typename?: 'TriggerConfiguration'
  instanceId?: Maybe<Scalars['String']['output']>
  recipeId?: Maybe<Scalars['String']['output']>
  recipeName?: Maybe<Scalars['String']['output']>
  sequenceId?: Maybe<Scalars['String']['output']>
  sequenceStepId?: Maybe<Scalars['String']['output']>
}

export type TriggerConfigurationInput = {
  instanceId?: InputMaybe<Scalars['String']['input']>
  recipeId?: InputMaybe<Scalars['String']['input']>
  recipeName?: InputMaybe<Scalars['String']['input']>
  sequenceId?: InputMaybe<Scalars['String']['input']>
  sequenceStepId?: InputMaybe<Scalars['String']['input']>
}

export type TriggerFilter = {
  __typename?: 'TriggerFilter'
  field: Scalars['String']['output']
  filter: TriggerFilterTypes
  value: Scalars['String']['output']
}

export type TriggerFilterInput = {
  field: Scalars['String']['input']
  filter: TriggerFilterTypes
  value: Scalars['String']['input']
}

export enum TriggerFilterTypes {
  DateEquals = 'DateEquals',
  DateIsAfter = 'DateIsAfter',
  DateIsBefore = 'DateIsBefore',
  DoesNotExist = 'DoesNotExist',
  Empty = 'Empty',
  Exists = 'Exists',
  NotEmpty = 'NotEmpty',
  NumberEqualGreaterThan = 'NumberEqualGreaterThan',
  NumberEqualLessThan = 'NumberEqualLessThan',
  NumberEquals = 'NumberEquals',
  NumberGreaterThan = 'NumberGreaterThan',
  NumberLessThan = 'NumberLessThan',
  StringContains = 'StringContains',
  StringEndsWith = 'StringEndsWith',
  StringEquals = 'StringEquals',
  StringNotContains = 'StringNotContains',
  StringNotEquals = 'StringNotEquals',
  StringStartsWith = 'StringStartsWith',
}

export type UpdateAccountInput = {
  accountContactSettings?: InputMaybe<AccountContactSettingsInput>
  address?: InputMaybe<AddressInput>
  addressRefreshDays?: InputMaybe<Scalars['Int']['input']>
  addressRefreshEnabled?: InputMaybe<Scalars['Boolean']['input']>
  addressRefreshExpirationDays?: InputMaybe<Scalars['Int']['input']>
  addressRefreshExpireAction?: InputMaybe<PostalDeliveryExpireAction>
  billingAccountIds?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  brandingInstance?: InputMaybe<BrandingInstanceInput>
  budget?: InputMaybe<BudgetInput>
  displayName?: InputMaybe<Scalars['String']['input']>
  emailTemplateId?: InputMaybe<Scalars['ObjectId']['input']>
  eventRequestNotificationEmails?: InputMaybe<Array<Scalars['String']['input']>>
  fiscalQuarterSetup?: InputMaybe<FiscalQuarterSetupInput>
  forceAuthenticationMethod?: InputMaybe<Scalars['String']['input']>
  giftEmailFont?: InputMaybe<Scalars['String']['input']>
  giftEmailPrimaryColor?: InputMaybe<Scalars['String']['input']>
  giftEmailSecondaryColor?: InputMaybe<Scalars['String']['input']>
  giftEmailTemplateId?: InputMaybe<Scalars['ObjectId']['input']>
  giftEmailTertiaryColor?: InputMaybe<Scalars['String']['input']>
  linkApprovalSettings?: InputMaybe<LinkApprovalSettingsInput>
  marketplaceRequestNotificationEmails?: InputMaybe<Array<Scalars['String']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  notificationSettings?: InputMaybe<AccountNotificationSettingsInput>
  postalDeliveryEmailSetting?: InputMaybe<PostalDeliveryEmailSetting>
  postalDeliveryExpirationDays?: InputMaybe<Scalars['Int']['input']>
  postalDeliveryExpireAction?: InputMaybe<PostalDeliveryExpireAction>
  sendSettings?: InputMaybe<AccountSendSettingsInput>
  sessionTimeoutMinutes?: InputMaybe<Scalars['Int']['input']>
  settings?: InputMaybe<SettingsInput>
  supportEnabled?: InputMaybe<Scalars['Instant']['input']>
  userCanViewEvents?: InputMaybe<Scalars['Boolean']['input']>
  userCanViewMarketplace?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateBillingAccountInput = {
  autoReload?: InputMaybe<Scalars['Boolean']['input']>
  autoReloadAmount?: InputMaybe<Scalars['Int']['input']>
  autoReloadLowLimit?: InputMaybe<Scalars['Int']['input']>
  billingContactInfo?: InputMaybe<BillingContactInfoInput>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateMessageTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>
  sharedWith?: InputMaybe<ShareLinkInput>
  templateText?: InputMaybe<Scalars['String']['input']>
}

export type UpdateSavedSendInput = {
  commonSendProperties?: InputMaybe<CommonSendPropertiesInput>
  savedSendBulkSendInfo?: InputMaybe<SavedSendBulkSendInfoInput>
  savedSendCampaignInfo?: InputMaybe<SavedSendCampaignInfoInput>
  savedSendMagicLinkInfo?: InputMaybe<SavedSendMagicLinkInfoInput>
  savedSendName?: InputMaybe<Scalars['String']['input']>
  sendFlowStep?: InputMaybe<SendFlowStep>
  sendType?: InputMaybe<SavedSendType>
  status?: InputMaybe<SavedSendStatus>
}

export type UpdateSelfInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>
  emailDigestSettings?: InputMaybe<EmailDigestSettingsInput>
  emailSignatureHtml?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  handwritingName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  meetingLink?: InputMaybe<Scalars['String']['input']>
  meetingSettings?: InputMaybe<MeetingSettingsInput>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  profilePicture?: InputMaybe<Scalars['Url']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UpdateTeamInput = {
  billingAccountIds?: InputMaybe<Array<Scalars['ObjectId']['input']>>
  budget?: InputMaybe<BudgetInput>
  department?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  parentTeamId?: InputMaybe<Scalars['ObjectId']['input']>
  settings?: InputMaybe<SettingsInput>
}

export type UpdateUserInput = {
  accountLockedUntil?: InputMaybe<Scalars['Instant']['input']>
  authenticationFailures?: InputMaybe<Scalars['Int']['input']>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  emailSignatureHtml?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  meetingLink?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  profilePicture?: InputMaybe<Scalars['Url']['input']>
  status?: InputMaybe<Status>
  title?: InputMaybe<Scalars['String']['input']>
}

export type User = {
  __typename?: 'User'
  accountLockedUntil?: Maybe<Scalars['Instant']['output']>
  auth?: Maybe<Array<Maybe<AuthenticationMethod>>>
  authenticationFailures?: Maybe<Scalars['Int']['output']>
  created?: Maybe<ActionRecord>
  crmUserMap?: Maybe<Array<CrmUser>>
  emailAddress: Scalars['String']['output']
  emailDigestSettings?: Maybe<EmailDigestSettings>
  firstName?: Maybe<Scalars['String']['output']>
  handwritingName?: Maybe<Scalars['String']['output']>
  id: Scalars['ObjectId']['output']
  lastName?: Maybe<Scalars['String']['output']>
  meetingLink?: Maybe<Scalars['String']['output']>
  notifications?: Maybe<UserNotifications>
  phoneNumber?: Maybe<Scalars['String']['output']>
  productAccess?: Maybe<Array<ProductAccess>>
  profilePicture?: Maybe<Scalars['Url']['output']>
  status: Status
  title?: Maybe<Scalars['String']['output']>
  userName: Scalars['String']['output']
}

export type UserAccount = {
  __typename?: 'UserAccount'
  accountName?: Maybe<Scalars['String']['output']>
  authTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  completedActions?: Maybe<Scalars['Object']['output']>
  created: ActionRecord
  currencyList?: Maybe<Array<Currency>>
  emailAddress: Scalars['String']['output']
  emailDigestSettings?: Maybe<EmailDigestSettings>
  emailSignatureHtml?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  handwritingName?: Maybe<Scalars['String']['output']>
  id: Scalars['ObjectId']['output']
  lastName: Scalars['String']['output']
  meetingLink?: Maybe<Scalars['String']['output']>
  meetingSettings?: Maybe<MeetingSettings>
  notifications?: Maybe<UserNotifications>
  phoneNumber?: Maybe<Scalars['String']['output']>
  productAccess?: Maybe<Array<ProductAccess>>
  profilePicture?: Maybe<Scalars['Url']['output']>
  teamName?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  updated?: Maybe<ActionRecord>
}

export type UserAnalytics = {
  __typename?: 'UserAnalytics'
  accepted?: Maybe<Scalars['Int']['output']>
  activeMagicLinks?: Maybe<Scalars['Int']['output']>
  bounces?: Maybe<Scalars['Int']['output']>
  campaignsSent?: Maybe<Scalars['Int']['output']>
  cancelled?: Maybe<Scalars['Int']['output']>
  completed?: Maybe<Scalars['Int']['output']>
  costInCents?: Maybe<Scalars['Int']['output']>
  date?: Maybe<Scalars['Instant']['output']>
  delivered?: Maybe<Scalars['Int']['output']>
  deliveryEmailAccepted?: Maybe<Scalars['Int']['output']>
  deliveryEmailClicked?: Maybe<Scalars['Int']['output']>
  deliveryEmailDeclined?: Maybe<Scalars['Int']['output']>
  deliveryEmailDonated?: Maybe<Scalars['Int']['output']>
  deliveryEmailExpired?: Maybe<Scalars['Int']['output']>
  deliveryEmailFailed?: Maybe<Scalars['Int']['output']>
  deliveryEmailOpened?: Maybe<Scalars['Int']['output']>
  deliveryEmailSent?: Maybe<Scalars['Int']['output']>
  deliveryEmailUnsubscribed?: Maybe<Scalars['Int']['output']>
  eventsBooked?: Maybe<Scalars['Int']['output']>
  failed?: Maybe<Scalars['Int']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  fundsAdded?: Maybe<Scalars['Int']['output']>
  gmvCAD?: Maybe<Scalars['Int']['output']>
  gmvEUR?: Maybe<Scalars['Int']['output']>
  gmvGBP?: Maybe<Scalars['Int']['output']>
  gmvUSD?: Maybe<Scalars['Int']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  linkExecutions?: Maybe<Scalars['Int']['output']>
  linkExpiredViews?: Maybe<Scalars['Int']['output']>
  linkMaxExeViews?: Maybe<Scalars['Int']['output']>
  linkNewContact?: Maybe<Scalars['Int']['output']>
  linkTotalViews?: Maybe<Scalars['Int']['output']>
  linkUniqViews?: Maybe<Scalars['Int']['output']>
  meetingsBooked?: Maybe<Scalars['Int']['output']>
  newContact?: Maybe<Scalars['Int']['output']>
  playbooksStarted?: Maybe<Scalars['Int']['output']>
  queued?: Maybe<Scalars['Int']['output']>
  sent?: Maybe<Scalars['Int']['output']>
  socialShare?: Maybe<Scalars['Int']['output']>
  started?: Maybe<Scalars['Int']['output']>
  successful?: Maybe<Scalars['Int']['output']>
  teamId?: Maybe<Scalars['ObjectId']['output']>
  teamName?: Maybe<Scalars['String']['output']>
  thankYou?: Maybe<Scalars['Int']['output']>
  touches?: Maybe<Scalars['Int']['output']>
  triggersExecuted?: Maybe<Scalars['Int']['output']>
  userId?: Maybe<Scalars['ObjectId']['output']>
  userName?: Maybe<Scalars['String']['output']>
}

export type UserBudgetSummary = {
  __typename?: 'UserBudgetSummary'
  active: Scalars['Boolean']['output']
  budgetRemaining: Scalars['Int']['output']
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  totalAdjustments: Scalars['Int']['output']
  totalSpend: Scalars['Int']['output']
  userId: Scalars['String']['output']
}

export type UserFilterInput = {
  created?: InputMaybe<InstantFilterInput>
  emailAddress?: InputMaybe<StringFilterInput>
  firstName?: InputMaybe<StringFilterInput>
  id?: InputMaybe<StringFilterInput>
  lastName?: InputMaybe<StringFilterInput>
  productAccess?: InputMaybe<ProductAccessFilterInput>
  productAccess_roles?: InputMaybe<StringFilterInput>
  productAccess_teamId?: InputMaybe<ObjectIdFilterInput>
  status?: InputMaybe<StringFilterInput>
  userName?: InputMaybe<StringFilterInput>
}

export type UserLink = {
  __typename?: 'UserLink'
  fullName: Scalars['String']['output']
  userName: Scalars['String']['output']
}

export enum UserNotificationChannel {
  Email = 'EMAIL',
  Slack = 'SLACK',
  Sms = 'SMS',
}

export type UserNotifications = {
  __typename?: 'UserNotifications'
  autoReloadFailuresChannels?: Maybe<Array<Maybe<UserNotificationChannel>>>
  autoReloadFailuresNotificationsOn?: Maybe<Scalars['Boolean']['output']>
  balanceLowChannels?: Maybe<Array<Maybe<UserNotificationChannel>>>
  balanceLowNotificationsOn?: Maybe<Scalars['Boolean']['output']>
  budgetLowChannels?: Maybe<Array<Maybe<UserNotificationChannel>>>
  budgetLowNotificationsOn?: Maybe<Scalars['Boolean']['output']>
  giftEmailAcceptedChannels?: Maybe<Array<Maybe<UserNotificationChannel>>>
  giftEmailAcceptedNotificationsOn?: Maybe<Scalars['Boolean']['output']>
  landingPageExceptionChannels?: Maybe<Array<UserNotificationChannel>>
  landingPageExceptionNotificationOn?: Maybe<Scalars['Boolean']['output']>
  lastBalanceLowNotificationSent?: Maybe<Scalars['Instant']['output']>
  lastBudgetLowNotificationSent?: Maybe<Scalars['Instant']['output']>
  magicLinkAcceptedChannels?: Maybe<Array<Maybe<UserNotificationChannel>>>
  magicLinkAcceptedNotificationsOn?: Maybe<Scalars['Boolean']['output']>
  notificationsOn?: Maybe<Scalars['Boolean']['output']>
  orderDeliveredChannels?: Maybe<Array<Maybe<UserNotificationChannel>>>
  orderDeliveredNotificationsOn?: Maybe<Scalars['Boolean']['output']>
  orderIssuesChannels?: Maybe<Array<Maybe<UserNotificationChannel>>>
  orderIssuesNotificationsOn?: Maybe<Scalars['Boolean']['output']>
  thankYouChannels?: Maybe<Array<UserNotificationChannel>>
  thankYouNotificationsOn?: Maybe<Scalars['Boolean']['output']>
}

export type UserNotificationsInput = {
  autoReloadFailuresChannels?: InputMaybe<Array<UserNotificationChannel>>
  autoReloadFailuresNotificationsOn?: InputMaybe<Scalars['Boolean']['input']>
  balanceLowChannels?: InputMaybe<Array<UserNotificationChannel>>
  balanceLowNotificationsOn?: InputMaybe<Scalars['Boolean']['input']>
  budgetLowChannels?: InputMaybe<Array<UserNotificationChannel>>
  budgetLowNotificationsOn?: InputMaybe<Scalars['Boolean']['input']>
  giftEmailAcceptedChannels?: InputMaybe<Array<UserNotificationChannel>>
  giftEmailAcceptedNotificationsOn?: InputMaybe<Scalars['Boolean']['input']>
  landingPageExceptionChannels?: InputMaybe<Array<UserNotificationChannel>>
  landingPageExceptionNotificationOn?: InputMaybe<Scalars['Boolean']['input']>
  magicLinkAcceptedChannels?: InputMaybe<Array<UserNotificationChannel>>
  magicLinkAcceptedNotificationsOn?: InputMaybe<Scalars['Boolean']['input']>
  orderDeliveredChannels?: InputMaybe<Array<UserNotificationChannel>>
  orderDeliveredNotificationsOn?: InputMaybe<Scalars['Boolean']['input']>
  orderIssuesChannels?: InputMaybe<Array<UserNotificationChannel>>
  orderIssuesNotificationsOn?: InputMaybe<Scalars['Boolean']['input']>
  thankYouChannels?: InputMaybe<Array<UserNotificationChannel>>
  thankYouNotificationsOn?: InputMaybe<Scalars['Boolean']['input']>
}

export enum UserOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UserNameAsc = 'userName_ASC',
  UserNameDesc = 'userName_DESC',
}

export type UserSearchResponse = {
  __typename?: 'UserSearchResponse'
  resultsSummary: ResultsSummary
  users?: Maybe<Array<User>>
}

export type UsersBudgetSummary = {
  __typename?: 'UsersBudgetSummary'
  accountId: Scalars['ObjectId']['output']
  budget?: Maybe<Budget>
  summaries: Array<Maybe<UserBudgetSummary>>
  teamId?: Maybe<Scalars['ObjectId']['output']>
  totalAdjustments: Scalars['Int']['output']
  totalSpend: Scalars['Int']['output']
}

export type ValidationIssue = {
  __typename?: 'ValidationIssue'
  issue: OrderIssue
  reason: Scalars['String']['output']
  validationName: Scalars['String']['output']
}

export enum ValidationType {
  OptionalBoolean = 'OPTIONAL_BOOLEAN',
  OptionalCsv = 'OPTIONAL_CSV',
  OptionalCurrency = 'OPTIONAL_CURRENCY',
  OptionalEnum = 'OPTIONAL_ENUM',
  OptionalInteger = 'OPTIONAL_INTEGER',
  OptionalObjectId = 'OPTIONAL_OBJECT_ID',
  OptionalString = 'OPTIONAL_STRING',
  RequiredBoolean = 'REQUIRED_BOOLEAN',
  RequiredCsv = 'REQUIRED_CSV',
  RequiredCurrency = 'REQUIRED_CURRENCY',
  RequiredEnum = 'REQUIRED_ENUM',
  RequiredInteger = 'REQUIRED_INTEGER',
  RequiredObjectId = 'REQUIRED_OBJECT_ID',
  RequiredShippingOption = 'REQUIRED_SHIPPING_OPTION',
  RequiredString = 'REQUIRED_STRING',
}

export enum VendorType {
  CustomProducts = 'CUSTOM_PRODUCTS',
  Fulfillment = 'FULFILLMENT',
}

export type VerifyAddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  address3?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
}

export enum WebhookGroup {
  ApprovedPostalStatus = 'approved_postal_status',
  BudgetStatus = 'budget_status',
  CampaignStatus = 'campaign_status',
  GetDeliveryStatus = 'get_delivery_status',
  GetPostalStatus = 'get_postal_status',
  GiftEmailStatus = 'gift_email_status',
  IntegrationStatus = 'integration_status',
  ItemRemoved = 'item_removed',
  MagicLinkRequiresApproval = 'magic_link_requires_approval',
  NewCollection = 'new_collection',
  NewEvent = 'new_event',
  NewEventRegistrant = 'new_event_registrant',
  NewItem = 'new_item',
  NewPostalCreated = 'new_postal_created',
  PostalIssue = 'postal_issue',
  PostalMessages = 'postal_messages',
}

export type AddFundsMutationVariables = Exact<{
  billingAccountId: Scalars['ObjectId']['input']
  amount: Scalars['Int']['input']
}>

export type AddFundsMutation = {
  __typename?: 'Mutation'
  addFunds: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type AddFundsV2MutationVariables = Exact<{
  input: AddFundsV2Input
}>

export type AddFundsV2Mutation = {
  __typename?: 'Mutation'
  addFundsV2: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type AddProductAccessMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  roles?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>
  product?: InputMaybe<Scalars['String']['input']>
}>

export type AddProductAccessMutation = {
  __typename?: 'Mutation'
  addProductAccess: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    userName: string
    emailAddress: string
    profilePicture?: string | null
    phoneNumber?: string | null
    title?: string | null
    meetingLink?: string | null
    status: Status
    accountLockedUntil?: any | null
    authenticationFailures?: number | null
    handwritingName?: string | null
    auth?: Array<{
      __typename?: 'AuthenticationMethod'
      method: string
      version?: string | null
      expires?: any | null
      updated?: any | null
      created?: any | null
    } | null> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    productAccess?: Array<{
      __typename?: 'ProductAccess'
      id: string
      accountId?: string | null
      teamId?: string | null
      partnerId?: string | null
      product: Product
      roles?: Array<string> | null
      type?: ProductAccessType | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    }> | null
    crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null
    notifications?: {
      __typename?: 'UserNotifications'
      notificationsOn?: boolean | null
      balanceLowNotificationsOn?: boolean | null
      balanceLowChannels?: Array<UserNotificationChannel | null> | null
      lastBalanceLowNotificationSent?: any | null
      budgetLowNotificationsOn?: boolean | null
      budgetLowChannels?: Array<UserNotificationChannel | null> | null
      lastBudgetLowNotificationSent?: any | null
      giftEmailAcceptedNotificationsOn?: boolean | null
      giftEmailAcceptedChannels?: Array<UserNotificationChannel | null> | null
      magicLinkAcceptedNotificationsOn?: boolean | null
      magicLinkAcceptedChannels?: Array<UserNotificationChannel | null> | null
      orderDeliveredNotificationsOn?: boolean | null
      orderDeliveredChannels?: Array<UserNotificationChannel | null> | null
      thankYouNotificationsOn?: boolean | null
      thankYouChannels?: Array<UserNotificationChannel> | null
      landingPageExceptionNotificationOn?: boolean | null
      landingPageExceptionChannels?: Array<UserNotificationChannel> | null
      autoReloadFailuresNotificationsOn?: boolean | null
      autoReloadFailuresChannels?: Array<UserNotificationChannel | null> | null
      orderIssuesNotificationsOn?: boolean | null
      orderIssuesChannels?: Array<UserNotificationChannel | null> | null
    } | null
    emailDigestSettings?: {
      __typename?: 'EmailDigestSettings'
      processingErrors?: boolean | null
      ordersRequiringUserApproval?: boolean | null
      itemOutOfStock?: boolean | null
      itemLowStock?: boolean | null
      marketingUpdates?: boolean | null
      newItemApproved?: boolean | null
      newCollectionApproved?: boolean | null
      newEventApproved?: boolean | null
      itemRemoved?: boolean | null
      frequency?: EmailDigestFrequency | null
    } | null
  }
}

export type AdjustUserBudgetMutationVariables = Exact<{
  input: AdjustUserBudgetInput
}>

export type AdjustUserBudgetMutation = {
  __typename?: 'Mutation'
  adjustUserBudget: {
    __typename?: 'BudgetRemaining'
    budgetRemaining?: number | null
    totalSpend?: number | null
    totalAdjustments?: number | null
    applicableBudget?: {
      __typename?: 'ApplicableBudget'
      accountId?: string | null
      teamId?: string | null
      billingAccountId?: string | null
      budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    } | null
  }
}

export type BulkApproveOrdersMutationVariables = Exact<{
  fulfillmentIds: Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']
}>

export type BulkApproveOrdersMutation = {
  __typename?: 'Mutation'
  bulkApproveOrders: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkContactAddToCampaignMutationVariables = Exact<{
  campaignId: Scalars['ObjectId']['input']
  campaignName: Scalars['String']['input']
  filter?: InputMaybe<ContactFilterInput>
  v2filter?: InputMaybe<SearchableContactFilterInput>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput> | SearchableContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']>
}>

export type BulkContactAddToCampaignMutation = {
  __typename?: 'Mutation'
  bulkContactAddToCampaign: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkContactAddToListMutationVariables = Exact<{
  data: ContactListMapInput
  filter?: InputMaybe<ContactFilterInput>
  v2filter?: InputMaybe<SearchableContactFilterInput>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput> | SearchableContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']>
}>

export type BulkContactAddToListMutation = {
  __typename?: 'Mutation'
  bulkContactAddToList: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkContactAddToPlaybookMutationVariables = Exact<{
  playbookDefinitionId: Scalars['ObjectId']['input']
  filter?: InputMaybe<ContactFilterInput>
  v2filter?: InputMaybe<SearchableContactFilterInput>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput> | SearchableContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']>
}>

export type BulkContactAddToPlaybookMutation = {
  __typename?: 'Mutation'
  bulkContactAddToPlaybook: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkContactDeleteMutationVariables = Exact<{
  filter?: InputMaybe<ContactFilterInput>
  v2filter?: InputMaybe<SearchableContactFilterInput>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput> | SearchableContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']>
}>

export type BulkContactDeleteMutation = {
  __typename?: 'Mutation'
  bulkContactDelete: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkContactRemoveFromCampaignMutationVariables = Exact<{
  campaignId: Scalars['ObjectId']['input']
  filter?: InputMaybe<ContactFilterInput>
  v2filter?: InputMaybe<SearchableContactFilterInput>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput> | SearchableContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']>
}>

export type BulkContactRemoveFromCampaignMutation = {
  __typename?: 'Mutation'
  bulkContactRemoveFromCampaign: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkContactRemoveFromListMutationVariables = Exact<{
  data: ContactListMapInput
  filter?: InputMaybe<ContactFilterInput>
  v2filter?: InputMaybe<SearchableContactFilterInput>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput> | SearchableContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']>
}>

export type BulkContactRemoveFromListMutation = {
  __typename?: 'Mutation'
  bulkContactRemoveFromList: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkContactUpdatePlaybookStatusMutationVariables = Exact<{
  playbookDefinitionId: Scalars['ObjectId']['input']
  playbookStatus?: InputMaybe<PlaybookStatus>
  filter?: InputMaybe<ContactFilterInput>
  v2filter?: InputMaybe<SearchableContactFilterInput>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput> | SearchableContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']>
}>

export type BulkContactUpdatePlaybookStatusMutation = {
  __typename?: 'Mutation'
  bulkContactUpdatePlaybookStatus: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkCreateApprovedPostalMutationVariables = Exact<{
  marketplaceProductIds: Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']
  data: BulkApprovedPostalUpdateInput
}>

export type BulkCreateApprovedPostalMutation = {
  __typename?: 'Mutation'
  bulkCreateApprovedPostal: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkDenyOrdersMutationVariables = Exact<{
  fulfillmentIds: Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']
}>

export type BulkDenyOrdersMutation = {
  __typename?: 'Mutation'
  bulkDenyOrders: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkEditApprovedPostalMutationVariables = Exact<{
  approvedPostalIds: Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']
  data: BulkApprovedPostalUpdateInput
}>

export type BulkEditApprovedPostalMutation = {
  __typename?: 'Mutation'
  bulkEditApprovedPostal: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkPlaybookStatusUpdateMutationVariables = Exact<{
  playbookStatus?: InputMaybe<PlaybookStatus>
  filter?: InputMaybe<PlaybookInstanceFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']>
}>

export type BulkPlaybookStatusUpdateMutation = {
  __typename?: 'Mutation'
  bulkPlaybookStatusUpdate: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkProductAccessAddMutationVariables = Exact<{
  data: ProductAccessInput
  filter?: InputMaybe<UserFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']>
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>
}>

export type BulkProductAccessAddMutation = {
  __typename?: 'Mutation'
  bulkProductAccessAdd: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkProductAccessRemoveMutationVariables = Exact<{
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  filter?: InputMaybe<UserFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']>
}>

export type BulkProductAccessRemoveMutation = {
  __typename?: 'Mutation'
  bulkProductAccessRemove: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkProductAccessUpdateMutationVariables = Exact<{
  data: ProductAccessInput
  filter?: InputMaybe<UserFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']>
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>
}>

export type BulkProductAccessUpdateMutation = {
  __typename?: 'Mutation'
  bulkProductAccessUpdate: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkRetryFailedAbmCampaignOrdersMutationVariables = Exact<{
  campaignId: Scalars['ObjectId']['input']
  skipDuplicateOrderCheck?: InputMaybe<Scalars['Boolean']['input']>
}>

export type BulkRetryFailedAbmCampaignOrdersMutation = {
  __typename?: 'Mutation'
  bulkRetryFailedABMCampaignOrders: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkTagAddMutationVariables = Exact<{
  data: TagInput
  filter?: InputMaybe<ContactFilterInput>
  v2filter?: InputMaybe<SearchableContactFilterInput>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput> | SearchableContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']>
}>

export type BulkTagAddMutation = {
  __typename?: 'Mutation'
  bulkTagAdd: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type BulkTagRemoveMutationVariables = Exact<{
  data: TagInput
  filter?: InputMaybe<ContactFilterInput>
  v2filter?: InputMaybe<SearchableContactFilterInput>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput> | SearchableContactFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input']>
}>

export type BulkTagRemoveMutation = {
  __typename?: 'Mutation'
  bulkTagRemove: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type CancelBackgroundTaskMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type CancelBackgroundTaskMutation = {
  __typename?: 'Mutation'
  cancelBackgroundTask: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type CancelEventRequestMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  reason: Scalars['String']['input']
}>

export type CancelEventRequestMutation = {
  __typename?: 'Mutation'
  cancelEventRequest: { __typename?: 'ApiStatus'; status: string }
}

export type CancelOrderMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type CancelOrderMutation = { __typename?: 'Mutation'; cancelOrder: { __typename?: 'ApiStatus'; status: string } }

export type CancelTransferIntentMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type CancelTransferIntentMutation = {
  __typename?: 'Mutation'
  cancelTransferIntent: {
    __typename?: 'TransferIntent'
    id: string
    accountId: string
    userId: string
    status: TransferIntentStatus
    fromBillingAccountId: string
    toBillingAccountId: string
    fromAmount: number
    toAmount: number
    comment: string
    fromCurrency: Currency
    toCurrency: Currency
    toCreditMemoId?: string | null
    toCreditMemoNumber?: string | null
    fromDebitMemoId?: string | null
    fromDebitMemoNumber?: string | null
    fxInfo?: { __typename?: 'ForeignExchangeInfo'; timestamp: any; rate: string; apiProvider: string } | null
    history?: Array<{
      __typename?: 'TransferIntentHistory'
      status: TransferIntentStatus
      notes?: string | null
      created: any
    }> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type ChangePasswordMutationVariables = Exact<{
  data: ChangePasswordInput
}>

export type ChangePasswordMutation = { __typename?: 'Mutation'; changePassword: boolean }

export type CompleteDataImportMutationVariables = Exact<{
  data: FileDataMappingInput
}>

export type CompleteDataImportMutation = {
  __typename?: 'Mutation'
  completeDataImport: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type CompleteThirdPartyTaskMutationVariables = Exact<{
  system: Scalars['String']['input']
  contactId: Scalars['ObjectId']['input']
  taskId: Scalars['String']['input']
}>

export type CompleteThirdPartyTaskMutation = { __typename?: 'Mutation'; completeThirdPartyTask: boolean }

export type ConfirmTransferIntentMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  localTimeZone?: InputMaybe<Scalars['String']['input']>
}>

export type ConfirmTransferIntentMutation = {
  __typename?: 'Mutation'
  confirmTransferIntent: {
    __typename?: 'TransferIntent'
    id: string
    accountId: string
    userId: string
    status: TransferIntentStatus
    fromBillingAccountId: string
    toBillingAccountId: string
    fromAmount: number
    toAmount: number
    comment: string
    fromCurrency: Currency
    toCurrency: Currency
    toCreditMemoId?: string | null
    toCreditMemoNumber?: string | null
    fromDebitMemoId?: string | null
    fromDebitMemoNumber?: string | null
    fxInfo?: { __typename?: 'ForeignExchangeInfo'; timestamp: any; rate: string; apiProvider: string } | null
    history?: Array<{
      __typename?: 'TransferIntentHistory'
      status: TransferIntentStatus
      notes?: string | null
      created: any
    }> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type ContactEventsTeamMutationVariables = Exact<{
  approvedPostalId: Scalars['ObjectId']['input']
  requestType: ContactEventsRequestType
  message?: InputMaybe<Scalars['String']['input']>
  requestedDate?: InputMaybe<Scalars['Instant']['input']>
}>

export type ContactEventsTeamMutation = {
  __typename?: 'Mutation'
  contactEventsTeam: { __typename?: 'ContactEventsResponse'; eventsEmailFailed: boolean; vendorEmailFailed: boolean }
}

export type CreateApprovedPostalMutationVariables = Exact<{
  marketplaceProductId?: InputMaybe<Scalars['ObjectId']['input']>
  data: ApprovedPostalUpdateInput
}>

export type CreateApprovedPostalMutation = {
  __typename?: 'Mutation'
  createApprovedPostal: {
    __typename?: 'ApprovedPostalResponse'
    postal: {
      __typename?: 'ApprovedPostal'
      id: string
      userId?: string | null
      ownerId?: string | null
      teamIds?: Array<string> | null
      name: string
      displayName?: string | null
      description: string
      brandName?: string | null
      status: Status
      category: string
      type?: string | null
      subCategory?: Array<string> | null
      useCases?: Array<string> | null
      marketplaceProductId: string
      variantOrderFlexibility?: boolean | null
      collection?: boolean | null
      currency?: Currency | null
      customizable?: boolean | null
      geographicMarkets?: Array<string> | null
      imageUrls?: Array<{ __typename?: 'ImageReference'; url: string; description?: string | null }> | null
      variants?: Array<{
        __typename?: 'ApprovedProductVariant'
        id: string
        marketplaceProductId?: string | null
        approvedPostalId?: string | null
        productName?: string | null
        variantName: string
        displayName?: string | null
        description?: string | null
        status: Status
        price: number
        displayPrice: number
        flatFees?: number | null
        displaySubscriberPrice: number
        shippingPriceEstimateMin?: number | null
        shippingPriceEstimateMax?: number | null
        giftMessageSupported?: boolean | null
        physicalMessageSupported?: boolean | null
        containsPerishables?: boolean | null
        imageUrls?: Array<{ __typename?: 'ImageReference'; url: string; description?: string | null }> | null
      }> | null
      designTemplate?: {
        __typename?: 'DesignTemplate'
        dpi: number
        front?: Array<{
          __typename?: 'ElementConfig'
          name: string
          description?: string | null
          hidden?: boolean | null
          locked?: boolean | null
          noPdfOutput?: boolean | null
          settings?: any | null
          customizable?: boolean | null
          location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
          resize?: {
            __typename?: 'ElementResize'
            minWidth: number
            minHeight: number
            maxWidth: number
            maxHeight: number
          } | null
          boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
        }> | null
        back?: Array<{
          __typename?: 'ElementConfig'
          name: string
          description?: string | null
          hidden?: boolean | null
          locked?: boolean | null
          noPdfOutput?: boolean | null
          settings?: any | null
          customizable?: boolean | null
          location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
          resize?: {
            __typename?: 'ElementResize'
            minWidth: number
            minHeight: number
            maxWidth: number
            maxHeight: number
          } | null
          boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
        }> | null
        outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
      } | null
      event?: {
        __typename?: 'MagicEventInstance'
        requestedByName: string
        requestedByEmail: string
        requestedByPhone: string
        requestedByMessage: string
        requestedAttendeeCount: number
        spotsRemaining?: number | null
        calendarRequestedDate?: any | null
        requestedDates?: Array<any> | null
        eventDateTime?: any | null
        eventLengthMinutes?: number | null
        status: MagicEventStatus
        lastRegistrationDateTime?: any | null
        meetingLink?: string | null
        sendEmailConfirmation?: boolean | null
        sendReminderDayBefore?: boolean | null
        sendReminderDayOf?: boolean | null
        sendCancelledAlert?: boolean | null
        sendInviteExpiringAlert?: boolean | null
        sendMeetingLinkChanged?: boolean | null
        sendDateTimeChanged?: boolean | null
        sendShippedAlert?: boolean | null
        sendDeliveredAlert?: boolean | null
        confirmationCustomization?: {
          __typename?: 'EventEmailCustomization'
          subjectLine?: string | null
          body?: string | null
        } | null
        reminderTomorrowCustomization?: {
          __typename?: 'EventEmailCustomization'
          subjectLine?: string | null
          body?: string | null
        } | null
        reminderTodayCustomization?: {
          __typename?: 'EventEmailCustomization'
          subjectLine?: string | null
          body?: string | null
        } | null
        cancellationCustomization?: {
          __typename?: 'EventEmailCustomization'
          subjectLine?: string | null
          body?: string | null
        } | null
        meetingLinkChangedCustomization?: {
          __typename?: 'EventEmailCustomization'
          subjectLine?: string | null
          body?: string | null
        } | null
        dateTimeChangedCustomization?: {
          __typename?: 'EventEmailCustomization'
          subjectLine?: string | null
          body?: string | null
        } | null
      } | null
      eventDetails?: {
        __typename?: 'MagicEvent'
        eventLengthMinutes: number
        minimumAttendees?: number | null
        maximumAttendees?: number | null
        requirements?: Array<Requirements> | null
        talentEmailAddress: string
        talentName: string
        talentPhoneNumber: string
        talentCompany?: string | null
      } | null
      metrics?: {
        __typename?: 'BasicMetrics'
        queued?: number | null
        failed?: number | null
        sent?: number | null
        deliveryEmailAccepted?: number | null
        deliveryEmailSent?: number | null
        deliveryEmailOpened?: number | null
        deliveryEmailClicked?: number | null
        deliveryEmailDonated?: number | null
        deliveryEmailDeclined?: number | null
        deliveryEmailUnsubscribed?: number | null
        deliveryEmailFailed?: number | null
        deliveryEmailExpired?: number | null
        accepted?: number | null
        delivered?: number | null
        bounces?: number | null
        touches?: number | null
        costInCents?: number | null
        gmvUSD?: number | null
        gmvCAD?: number | null
        gmvEUR?: number | null
        gmvGBP?: number | null
        started?: number | null
        completed?: number | null
        cancelled?: number | null
        successful?: number | null
        campaignsSent?: number | null
        playbooksStarted?: number | null
        triggersExecuted?: number | null
        linkTotalViews?: number | null
        linkUniqViews?: number | null
        linkMaxExeViews?: number | null
        linkExpiredViews?: number | null
        linkExecutions?: number | null
        fundsAdded?: number | null
        eventsBooked?: number | null
        meetingsBooked?: number | null
        thankYou?: number | null
        socialShare?: number | null
        newContact?: number | null
        linkNewContact?: number | null
        activeMagicLinks?: number | null
        date?: any | null
      } | null
      attribution?: {
        __typename?: 'Attribution'
        marketoProgramId?: string | null
        marketoProgramName?: string | null
        marketoCampaignId?: string | null
        marketoCampaignName?: string | null
        sfdcCampaignId?: string | null
        sfdcCampaignName?: string | null
        sdfcSandboxCampaignId?: string | null
        sfdcSandboxCampaignName?: string | null
        eloquaCampaignId?: string | null
        eloquaCampaignName?: string | null
      } | null
      itemCustomizationList?: Array<{
        __typename?: 'ItemCustomization'
        id: string
        displayName?: string | null
        type?: ItemCustomizationType | null
        customizableBySender?: boolean | null
        customizableByRecipient?: boolean | null
        required?: boolean | null
        value?: any | null
        characterLimit?: number | null
        widthRecommendation?: number | null
        heightRecommendation?: number | null
        fileTypesAccepted?: Array<string> | null
        variantId?: string | null
      }> | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      eventFeeSettings?: {
        __typename?: 'EventFeeSettings'
        flatFee?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      } | null
    }
    previewGenerationTask?: {
      __typename?: 'BackgroundTask'
      id: string
      relatedObjectId?: string | null
      name: string
      taskAction: string
      type: BackgroundTaskType
      status: BackgroundTaskStatus
      processedCount: number
      successCount: number
      errorCount: number
      taskStarted?: any | null
      taskCompleted?: any | null
      taskErrored?: any | null
      outputs?: any | null
      errors?: Array<{
        __typename?: 'BackgroundOperationError'
        code: string
        count: number
        message?: string | null
      }> | null
    } | null
  }
}

export type CreateBillingAccountMutationVariables = Exact<{
  data: CreateBillingAccountInput
}>

export type CreateBillingAccountMutation = {
  __typename?: 'Mutation'
  createBillingAccount: {
    __typename?: 'BillingAccount'
    id: string
    name: string
    status: Status
    type: BillingAccountType
    linkedAccountIdentifier?: string | null
    balance?: number | null
    prepaymentProcessing: boolean
    prepaymentBackgroundTaskId?: string | null
    currentPrepaymentAmount?: number | null
    autoReload?: boolean | null
    autoReloadAmount?: number | null
    autoReloadLowLimit?: number | null
    currency?: Currency | null
    incomingInvoiceAmount?: number | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    defaultPaymentMethod?: {
      __typename?: 'DefaultPaymentMethod'
      id?: string | null
      partnerId?: string | null
      paymentPartnerType?: PaymentPartnerType | null
      type?: PaymentMethodType | null
      autoReloadOk: boolean
      autoReloadFailures: number
      lastFailedAutoReloadAttempt?: any | null
      lastUnlocked?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    } | null
    incomingInvoiceActionRecord?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    billingContactInfo?: {
      __typename?: 'BillingContactInfo'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      address?: {
        __typename?: 'BillingContactAddress'
        line1?: string | null
        line2?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
      } | null
    } | null
    paymentPartners?: Array<{
      __typename?: 'PaymentPartnerInfo'
      type: PaymentPartnerType
      customerId: string
      lastSyncedAt?: any | null
      paymentMethods?: Array<{
        __typename?: 'PartnerPaymentMethod'
        partnerId: string
        billingDetails?: {
          __typename?: 'BillingPaymentMethodInfo'
          name?: string | null
          email?: string | null
          phone?: string | null
          address?: {
            __typename?: 'BillingContactAddress'
            line1?: string | null
            line2?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
          } | null
        } | null
        card?: {
          __typename?: 'CardPaymentMethod'
          expirationMonth: number
          expirationYear: number
          last4: string
          brand?: string | null
        } | null
      }> | null
    }> | null
    initialNetsuiteBalance?: { __typename?: 'InitialBalanceNetSuiteSyncInfo'; initializedAt?: any | null } | null
  }
}

export type CreateBlocklistEntryMutationVariables = Exact<{
  data: BlocklistEntryInput
}>

export type CreateBlocklistEntryMutation = {
  __typename?: 'Mutation'
  createBlocklistEntry: {
    __typename?: 'BlocklistEntry'
    id: string
    accountId: string
    dataObjectType: DataObjectType
    fieldName: string
    entry: string
    filterType: BlocklistFilterType
  }
}

export type CreateCampaignMutationVariables = Exact<{
  data: CampaignInput
}>

export type CreateCampaignMutation = {
  __typename?: 'Mutation'
  createCampaign: {
    __typename?: 'Campaign'
    id: string
    userId: string
    name: string
    campaignType?: CampaignType | null
    status: CampaignStatus
    scheduleDate?: any | null
    cancelDate?: any | null
    errorDate?: any | null
    completeDate?: any | null
    giftMessage?: string | null
    backgroundTaskId?: string | null
    playbookId?: string | null
    approvedPostalId?: string | null
    variantId?: string | null
    totalContacts?: number | null
    totalContactsAttempted?: number | null
    deliveryEmail?: boolean | null
    deliveryEmailTemplateId?: string | null
    physicalMessage?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    emailSubjectLine?: string | null
    sendAsContactOwner?: boolean | null
    sendAsUser?: string | null
    teamId?: string | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    designTemplate?: {
      __typename?: 'DesignTemplate'
      dpi: number
      front?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      back?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
    } | null
    metrics?: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    } | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationInputs?: Array<{
      __typename?: 'ItemCustomizationInput'
      id: string
      value: any
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
  }
}

export type CreateContactListMutationVariables = Exact<{
  data: ContactListInput
}>

export type CreateContactListMutation = {
  __typename?: 'Mutation'
  createContactList: {
    __typename?: 'ContactList'
    id: string
    name: string
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type CreateEmailPreviewUrlsMutationVariables = Exact<{
  data?: InputMaybe<EmailPreviewRequestInput>
  renderSubjectLine?: InputMaybe<Scalars['Boolean']['input']>
}>

export type CreateEmailPreviewUrlsMutation = {
  __typename?: 'Mutation'
  createEmailPreviewUrls?: Array<{
    __typename?: 'EmailPreviewUrl'
    url?: string | null
    htmlUrl: string
    previewType: string
    emailType: string
    emailName: string
  }> | null
}

export type CreateFavoriteItemMutationVariables = Exact<{
  data: FavoriteItemInput
}>

export type CreateFavoriteItemMutation = {
  __typename?: 'Mutation'
  createFavoriteItem: {
    __typename?: 'FavoriteItem'
    id: string
    userId: string
    teamId?: string | null
    approvedPostalId: string
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
  }
}

export type CreateMagicLinkMutationVariables = Exact<{
  data: MagicLinkInput
}>

export type CreateMagicLinkMutation = {
  __typename?: 'Mutation'
  createMagicLink: {
    __typename?: 'MagicLink'
    id: string
    teamId?: string | null
    name: string
    linkUrl: string
    status: MagicLinkStatus
    expirationDate?: any | null
    maxExecutions?: number | null
    message: string
    approvedPostalId: string
    variantId: string
    distributionChannel?: string | null
    lastView?: any | null
    lastExecution?: any | null
    physicalMessage?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    sendAsContactOwner?: boolean | null
    sendAsUser?: string | null
    requiresApproval?: boolean | null
    requiresUuid?: boolean | null
    metrics: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    }
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationInputs?: Array<{
      __typename?: 'ItemCustomizationInput'
      id: string
      value: any
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
  }
}

export type CreateMessageTemplateMutationVariables = Exact<{
  data: NewMessageTemplateInput
}>

export type CreateMessageTemplateMutation = {
  __typename?: 'Mutation'
  createMessageTemplate: {
    __typename?: 'MessageTemplate'
    id: string
    name: string
    templateText: string
    sharedWith?: { __typename?: 'ShareLink'; type: LinkType; target: string } | null
  }
}

export type CreatePlaybookMutationVariables = Exact<{
  playbookDefinitionId: Scalars['ObjectId']['input']
  contactId: Scalars['ObjectId']['input']
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}>

export type CreatePlaybookMutation = {
  __typename?: 'Mutation'
  createPlaybook: {
    __typename?: 'PlaybookInstance'
    id: string
    name?: string | null
    playbookDefinitionId: string
    playbookStatus?: PlaybookStatus | null
    contactId: string
    contactName?: string | null
    triggerId?: string | null
    errMessage?: string | null
    nextStepToExecute?: number | null
    nextExecutionDate?: any | null
    teamId?: string | null
    steps: Array<{
      __typename?: 'PlaybookStepInstance'
      type: PlaybookStepType
      playbookStatus?: PlaybookStatus | null
      errMessage?: string | null
      delay: number
      delayUnit: ChronoUnit
      approvedPostalId: string
      variantId: string
      giftMessage?: string | null
      deliveryEmail?: boolean | null
      fulfillmentId?: string | null
      fulfillmentStatus?: FulfillmentStatus | null
      physicalMessage?: string | null
      meetingRequestSetting?: MeetingRequestSetting | null
      sendAsContactOwner?: boolean | null
      sendAsUser?: string | null
      spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      itemCustomizationInputs?: Array<{
        __typename?: 'ItemCustomizationInput'
        id: string
        value: any
        variantId?: string | null
      }> | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      landingPageCustomization?: {
        __typename?: 'LandingPageCustomization'
        headerText?: string | null
        body?: string | null
        includeHeadshot?: boolean | null
        includeSenderName?: boolean | null
      } | null
      recipientEmailSettings?: {
        __typename?: 'RecipientEmailSettings'
        shippedEmailsOn?: boolean | null
        deliveredEmailsOn?: boolean | null
      } | null
      giftEmailReminderSettings?: {
        __typename?: 'GiftEmailReminderSettings'
        expirationSoonReminders?: Array<number> | null
      } | null
    } | null>
    metrics?: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
  }
}

export type CreatePlaybookDefinitionMutationVariables = Exact<{
  data: PlaybookDefinitionInput
}>

export type CreatePlaybookDefinitionMutation = {
  __typename?: 'Mutation'
  createPlaybookDefinition: {
    __typename?: 'PlaybookDefinition'
    id: string
    teamId?: string | null
    status?: Status | null
    name?: string | null
    currency?: Currency | null
    steps: Array<{
      __typename?: 'PlaybookStepDefinition'
      type: PlaybookStepType
      delay: number
      delayUnit: ChronoUnit
      approvedPostalId: string
      variantId: string
      giftMessage?: string | null
      deliveryEmail?: boolean | null
      displayPrice?: number | null
      displaySubscriberPrice?: number | null
      productName?: string | null
      deliveryEmailTemplateId?: string | null
      physicalMessage?: string | null
      meetingRequestSetting?: MeetingRequestSetting | null
      emailSubjectLine?: string | null
      sendAsContactOwner?: boolean | null
      sendAsUser?: string | null
      spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      itemCustomizationInputs?: Array<{
        __typename?: 'ItemCustomizationInput'
        id: string
        value: any
        variantId?: string | null
      }> | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      landingPageCustomization?: {
        __typename?: 'LandingPageCustomization'
        headerText?: string | null
        body?: string | null
        includeHeadshot?: boolean | null
        includeSenderName?: boolean | null
      } | null
      recipientEmailSettings?: {
        __typename?: 'RecipientEmailSettings'
        shippedEmailsOn?: boolean | null
        deliveredEmailsOn?: boolean | null
      } | null
      giftEmailReminderSettings?: {
        __typename?: 'GiftEmailReminderSettings'
        expirationSoonReminders?: Array<number> | null
      } | null
    } | null>
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
  }
}

export type CreateSampleMarketplaceDataMutationVariables = Exact<{ [key: string]: never }>

export type CreateSampleMarketplaceDataMutation = { __typename?: 'Mutation'; createSampleMarketplaceData: number }

export type CreateSavedSendMutationVariables = Exact<{
  data: SavedSendInput
}>

export type CreateSavedSendMutation = {
  __typename?: 'Mutation'
  createSavedSend: {
    __typename?: 'SavedSend'
    id: string
    savedSendName: string
    userId: string
    teamId?: string | null
    status: SavedSendStatus
    sendType: SavedSendType
    sendFlowStep?: SendFlowStep | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    commonSendProperties: {
      __typename?: 'CommonSendProperties'
      name?: string | null
      approvedPostalId: string
      variantId?: string | null
      physicalMessage?: string | null
      meetingRequestSetting?: MeetingRequestSetting | null
      sendAsContactOwner?: boolean | null
      sendAsUser?: string | null
      spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      itemCustomizationInputs?: Array<{
        __typename?: 'ItemCustomizationInput'
        id: string
        value: any
        variantId?: string | null
      }> | null
      landingPageCustomization?: {
        __typename?: 'LandingPageCustomization'
        headerText?: string | null
        body?: string | null
        includeHeadshot?: boolean | null
        includeSenderName?: boolean | null
      } | null
      recipientEmailSettings?: {
        __typename?: 'RecipientEmailSettings'
        shippedEmailsOn?: boolean | null
        deliveredEmailsOn?: boolean | null
      } | null
      giftEmailReminderSettings?: {
        __typename?: 'GiftEmailReminderSettings'
        expirationSoonReminders?: Array<number> | null
      } | null
    }
    savedSendMagicLinkInfo?: {
      __typename?: 'SavedSendMagicLinkInfo'
      expirationDate?: any | null
      maxExecutions?: number | null
      message?: string | null
      requiresApproval?: boolean | null
      status?: MagicLinkStatus | null
    } | null
    savedSendCampaignInfo?: {
      __typename?: 'SavedSendCampaignInfo'
      deliveryEmail?: boolean | null
      useSameMessage?: boolean | null
      emailSubjectLine?: string | null
      giftMessage?: string | null
      deliveryEmailTemplateId?: string | null
      scheduleDate?: any | null
      contactSearchFilters?: Array<any> | null
      contactCount?: number | null
    } | null
    savedSendBulkSendInfo?: {
      __typename?: 'SavedSendBulkSendInfo'
      quantity?: number | null
      contactId?: string | null
      addressVerified?: boolean | null
      shipToAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
      newContact?: {
        __typename?: 'NewContact'
        firstName?: string | null
        lastName?: string | null
        emailAddress?: string | null
        phones?: Array<{
          __typename?: 'NewPhoneMap'
          phoneNumber?: string | null
          type?: PhoneType | null
        } | null> | null
      } | null
    } | null
  }
}

export type CreateSetupIntentMutationVariables = Exact<{
  billingAccountId: Scalars['ObjectId']['input']
}>

export type CreateSetupIntentMutation = { __typename?: 'Mutation'; createSetupIntent: string }

export type CreateTagMutationVariables = Exact<{
  data: TagInput
}>

export type CreateTagMutation = {
  __typename?: 'Mutation'
  createTag: {
    __typename?: 'Tag'
    id: string
    name: string
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type CreateTeamMutationVariables = Exact<{
  data: CreateTeamInput
}>

export type CreateTeamMutation = {
  __typename?: 'Mutation'
  createTeam: {
    __typename?: 'Team'
    id: string
    name: string
    status: Status
    accountId: string
    parentTeamId?: string | null
    settings?: {
      __typename?: 'Settings'
      billingAccountId?: string | null
      billingAccountIds?: Array<{
        __typename?: 'BillingAccountMap'
        currency: Currency
        billingAccountId: string
      }> | null
      budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    } | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type CreateTransferIntentMutationVariables = Exact<{
  input: CreateTransferIntentInput
}>

export type CreateTransferIntentMutation = {
  __typename?: 'Mutation'
  createTransferIntent: {
    __typename?: 'TransferIntent'
    id: string
    accountId: string
    userId: string
    status: TransferIntentStatus
    fromBillingAccountId: string
    toBillingAccountId: string
    fromAmount: number
    toAmount: number
    comment: string
    fromCurrency: Currency
    toCurrency: Currency
    toCreditMemoId?: string | null
    toCreditMemoNumber?: string | null
    fromDebitMemoId?: string | null
    fromDebitMemoNumber?: string | null
    fxInfo?: { __typename?: 'ForeignExchangeInfo'; timestamp: any; rate: string; apiProvider: string } | null
    history?: Array<{
      __typename?: 'TransferIntentHistory'
      status: TransferIntentStatus
      notes?: string | null
      created: any
    }> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type CreateUpdateIntegrationTriggerMutationVariables = Exact<{
  data: IntegrationTriggerInput
}>

export type CreateUpdateIntegrationTriggerMutation = {
  __typename?: 'Mutation'
  createUpdateIntegrationTrigger: {
    __typename?: 'IntegrationTrigger'
    id: string
    userId: string
    teamId?: string | null
    name: string
    systemName: string
    status: Status
    action: TriggerAction
    playbookDefinitionId?: string | null
    approvedPostalId?: string | null
    approvedPostalVariantId?: string | null
    giftMessage?: string | null
    deliveryEmail?: boolean | null
    hitCount: number
    deliveryEmailTemplateId?: string | null
    physicalMessage?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    emailSubjectLine?: string | null
    sendAsContactOwner?: boolean | null
    sendAsUser?: string | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    filters?: Array<{ __typename?: 'TriggerFilter'; field: string; filter: TriggerFilterTypes; value: string }> | null
    config?: {
      __typename?: 'TriggerConfiguration'
      sequenceId?: string | null
      sequenceStepId?: string | null
      instanceId?: string | null
      recipeId?: string | null
      recipeName?: string | null
    } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationInputs?: Array<{
      __typename?: 'ItemCustomizationInput'
      id: string
      value: any
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
  }
}

export type DeleteApprovedPostalAndAllAutomationsMutationVariables = Exact<{
  approvedPostalId: Scalars['ObjectId']['input']
}>

export type DeleteApprovedPostalAndAllAutomationsMutation = {
  __typename?: 'Mutation'
  deleteApprovedPostalAndAllAutomations: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type DeleteBillingAccountMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type DeleteBillingAccountMutation = {
  __typename?: 'Mutation'
  deleteBillingAccount: {
    __typename?: 'BillingAccount'
    id: string
    name: string
    status: Status
    type: BillingAccountType
    linkedAccountIdentifier?: string | null
    balance?: number | null
    prepaymentProcessing: boolean
    prepaymentBackgroundTaskId?: string | null
    currentPrepaymentAmount?: number | null
    autoReload?: boolean | null
    autoReloadAmount?: number | null
    autoReloadLowLimit?: number | null
    currency?: Currency | null
    incomingInvoiceAmount?: number | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    defaultPaymentMethod?: {
      __typename?: 'DefaultPaymentMethod'
      id?: string | null
      partnerId?: string | null
      paymentPartnerType?: PaymentPartnerType | null
      type?: PaymentMethodType | null
      autoReloadOk: boolean
      autoReloadFailures: number
      lastFailedAutoReloadAttempt?: any | null
      lastUnlocked?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    } | null
    incomingInvoiceActionRecord?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    billingContactInfo?: {
      __typename?: 'BillingContactInfo'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      address?: {
        __typename?: 'BillingContactAddress'
        line1?: string | null
        line2?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
      } | null
    } | null
    paymentPartners?: Array<{
      __typename?: 'PaymentPartnerInfo'
      type: PaymentPartnerType
      customerId: string
      lastSyncedAt?: any | null
      paymentMethods?: Array<{
        __typename?: 'PartnerPaymentMethod'
        partnerId: string
        billingDetails?: {
          __typename?: 'BillingPaymentMethodInfo'
          name?: string | null
          email?: string | null
          phone?: string | null
          address?: {
            __typename?: 'BillingContactAddress'
            line1?: string | null
            line2?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
          } | null
        } | null
        card?: {
          __typename?: 'CardPaymentMethod'
          expirationMonth: number
          expirationYear: number
          last4: string
          brand?: string | null
        } | null
      }> | null
    }> | null
    initialNetsuiteBalance?: { __typename?: 'InitialBalanceNetSuiteSyncInfo'; initializedAt?: any | null } | null
  }
}

export type DeleteBlocklistEntryMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type DeleteBlocklistEntryMutation = {
  __typename?: 'Mutation'
  deleteBlocklistEntry: {
    __typename?: 'BlocklistEntry'
    id: string
    accountId: string
    dataObjectType: DataObjectType
    fieldName: string
    entry: string
    filterType: BlocklistFilterType
  }
}

export type DeleteCampaignMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type DeleteCampaignMutation = {
  __typename?: 'Mutation'
  deleteCampaign: {
    __typename?: 'Campaign'
    id: string
    userId: string
    name: string
    campaignType?: CampaignType | null
    status: CampaignStatus
    scheduleDate?: any | null
    cancelDate?: any | null
    errorDate?: any | null
    completeDate?: any | null
    giftMessage?: string | null
    backgroundTaskId?: string | null
    playbookId?: string | null
    approvedPostalId?: string | null
    variantId?: string | null
    totalContacts?: number | null
    totalContactsAttempted?: number | null
    deliveryEmail?: boolean | null
    deliveryEmailTemplateId?: string | null
    physicalMessage?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    emailSubjectLine?: string | null
    sendAsContactOwner?: boolean | null
    sendAsUser?: string | null
    teamId?: string | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    designTemplate?: {
      __typename?: 'DesignTemplate'
      dpi: number
      front?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      back?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
    } | null
    metrics?: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    } | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationInputs?: Array<{
      __typename?: 'ItemCustomizationInput'
      id: string
      value: any
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
  }
}

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type DeleteContactMutation = {
  __typename?: 'Mutation'
  deleteContact: {
    __typename?: 'Contact'
    id: string
    firstName: string
    lastName?: string | null
    title?: string | null
    emailAddress?: string | null
    ownerId?: string | null
    type: ContactType
    companyName?: string | null
    lastSend?: any | null
    lastActivity?: any | null
    tags?: Array<string> | null
    hasActivePlaybooks: boolean
    phones?: Array<{
      __typename?: 'PhoneMap'
      phoneNumber: string
      type: PhoneType
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    } | null> | null
    addresses?: Array<{
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null> | null
    ownerLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    metrics: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    }
    lists?: Array<{ __typename?: 'ContactListMap'; name: string; userId: string; listId: string }> | null
    campaigns?: Array<{
      __typename?: 'CampaignContact'
      campaignId: string
      campaignName?: string | null
      processed?: any | null
      postalFulfillmentId?: string | null
      status?: FulfillmentStatus | null
    }> | null
    playbooks?: Array<{
      __typename?: 'PlaybookMap'
      playbookDefinitionId: string
      playbookInstanceId: string
      name: string
      playbookStatus: PlaybookStatus
      nextStepToExecute?: number | null
      created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    }> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    deleted?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    mappings?: Array<{
      __typename?: 'ExternalMapping'
      system: string
      id: string
      owner?: string | null
      profileUrl?: string | null
      accountId?: string | null
      companyId?: string | null
      created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    }> | null
  }
}

export type DeleteContactListMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type DeleteContactListMutation = {
  __typename?: 'Mutation'
  deleteContactList: {
    __typename?: 'ContactList'
    id: string
    name: string
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type DeleteContactUnsubscribeMutationVariables = Exact<{
  emailAddress: Scalars['String']['input']
}>

export type DeleteContactUnsubscribeMutation = { __typename?: 'Mutation'; deleteContactUnsubscribe: boolean }

export type DeleteExternalCampaignMappingMutationVariables = Exact<{
  system: Scalars['String']['input']
  campaignId: Scalars['String']['input']
}>

export type DeleteExternalCampaignMappingMutation = { __typename?: 'Mutation'; deleteExternalCampaignMapping: boolean }

export type DeleteFavoriteItemMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type DeleteFavoriteItemMutation = {
  __typename?: 'Mutation'
  deleteFavoriteItem: {
    __typename?: 'FavoriteItem'
    id: string
    userId: string
    teamId?: string | null
    approvedPostalId: string
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
  }
}

export type DeleteIntegrationMutationVariables = Exact<{
  systemName: Scalars['String']['input']
}>

export type DeleteIntegrationMutation = { __typename?: 'Mutation'; deleteIntegration: boolean }

export type DeleteIntegrationTriggerMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type DeleteIntegrationTriggerMutation = { __typename?: 'Mutation'; deleteIntegrationTrigger: boolean }

export type DeleteInviteMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type DeleteInviteMutation = {
  __typename?: 'Mutation'
  deleteInvite: {
    __typename?: 'Invite'
    id: string
    teamId?: string | null
    teamName?: string | null
    invitedByUserName?: string | null
    emailAddress: string
    status: InviteStatus
    roles?: Array<string> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
  }
}

export type DeleteMagicLinkMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type DeleteMagicLinkMutation = {
  __typename?: 'Mutation'
  deleteMagicLink: {
    __typename?: 'MagicLink'
    id: string
    teamId?: string | null
    name: string
    linkUrl: string
    status: MagicLinkStatus
    expirationDate?: any | null
    maxExecutions?: number | null
    message: string
    approvedPostalId: string
    variantId: string
    distributionChannel?: string | null
    lastView?: any | null
    lastExecution?: any | null
    physicalMessage?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    sendAsContactOwner?: boolean | null
    sendAsUser?: string | null
    requiresApproval?: boolean | null
    requiresUuid?: boolean | null
    metrics: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    }
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationInputs?: Array<{
      __typename?: 'ItemCustomizationInput'
      id: string
      value: any
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
  }
}

export type DeleteMessageTemplateMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type DeleteMessageTemplateMutation = {
  __typename?: 'Mutation'
  deleteMessageTemplate: {
    __typename?: 'MessageTemplate'
    id: string
    name: string
    templateText: string
    sharedWith?: { __typename?: 'ShareLink'; type: LinkType; target: string } | null
  }
}

export type DeleteSavedSendMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type DeleteSavedSendMutation = {
  __typename?: 'Mutation'
  deleteSavedSend: {
    __typename?: 'SavedSend'
    id: string
    savedSendName: string
    userId: string
    teamId?: string | null
    status: SavedSendStatus
    sendType: SavedSendType
    sendFlowStep?: SendFlowStep | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    commonSendProperties: {
      __typename?: 'CommonSendProperties'
      name?: string | null
      approvedPostalId: string
      variantId?: string | null
      physicalMessage?: string | null
      meetingRequestSetting?: MeetingRequestSetting | null
      sendAsContactOwner?: boolean | null
      sendAsUser?: string | null
      spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      itemCustomizationInputs?: Array<{
        __typename?: 'ItemCustomizationInput'
        id: string
        value: any
        variantId?: string | null
      }> | null
      landingPageCustomization?: {
        __typename?: 'LandingPageCustomization'
        headerText?: string | null
        body?: string | null
        includeHeadshot?: boolean | null
        includeSenderName?: boolean | null
      } | null
      recipientEmailSettings?: {
        __typename?: 'RecipientEmailSettings'
        shippedEmailsOn?: boolean | null
        deliveredEmailsOn?: boolean | null
      } | null
      giftEmailReminderSettings?: {
        __typename?: 'GiftEmailReminderSettings'
        expirationSoonReminders?: Array<number> | null
      } | null
    }
    savedSendMagicLinkInfo?: {
      __typename?: 'SavedSendMagicLinkInfo'
      expirationDate?: any | null
      maxExecutions?: number | null
      message?: string | null
      requiresApproval?: boolean | null
      status?: MagicLinkStatus | null
    } | null
    savedSendCampaignInfo?: {
      __typename?: 'SavedSendCampaignInfo'
      deliveryEmail?: boolean | null
      useSameMessage?: boolean | null
      emailSubjectLine?: string | null
      giftMessage?: string | null
      deliveryEmailTemplateId?: string | null
      scheduleDate?: any | null
      contactSearchFilters?: Array<any> | null
      contactCount?: number | null
    } | null
    savedSendBulkSendInfo?: {
      __typename?: 'SavedSendBulkSendInfo'
      quantity?: number | null
      contactId?: string | null
      addressVerified?: boolean | null
      shipToAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
      newContact?: {
        __typename?: 'NewContact'
        firstName?: string | null
        lastName?: string | null
        emailAddress?: string | null
        phones?: Array<{
          __typename?: 'NewPhoneMap'
          phoneNumber?: string | null
          type?: PhoneType | null
        } | null> | null
      } | null
    } | null
  }
}

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type DeleteTagMutation = {
  __typename?: 'Mutation'
  deleteTag: {
    __typename?: 'Tag'
    id: string
    name: string
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type DeleteTeamMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type DeleteTeamMutation = {
  __typename?: 'Mutation'
  deleteTeam?: { __typename?: 'ApiStatus'; status: string } | null
}

export type DeleteUserActionMutationVariables = Exact<{
  name: Scalars['String']['input']
}>

export type DeleteUserActionMutation = { __typename?: 'Mutation'; deleteUserAction: boolean }

export type DeleteUserAuthMutationVariables = Exact<{
  authMethod: Scalars['String']['input']
}>

export type DeleteUserAuthMutation = { __typename?: 'Mutation'; deleteUserAuth: boolean }

export type ExecuteOrderEventMutationVariables = Exact<{
  fulfillmentId: Scalars['ObjectId']['input']
  eventName: OrderEventNames
}>

export type ExecuteOrderEventMutation = {
  __typename?: 'Mutation'
  executeOrderEvent: {
    __typename?: 'PostalFulfillment'
    id: string
    accountId: string
    userId: string
    teamId?: string | null
    contactId?: string | null
    shipToName?: string | null
    shipToTitle?: string | null
    shipToCompanyName?: string | null
    campaignName?: string | null
    shipToEmail?: string | null
    deliveryEmail?: boolean | null
    campaignId?: string | null
    playbookId?: string | null
    playbookDefinitionId?: string | null
    playbookName?: string | null
    triggerId?: string | null
    magicLinkId?: string | null
    magicLinkName?: string | null
    itemName: string
    emailSubjectLine?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    previewImage?: string | null
    giftMessage?: string | null
    physicalMessage?: string | null
    thankYouMessage?: string | null
    marketplaceProductId?: string | null
    approvedPostalId?: string | null
    variantId?: string | null
    fulfillmentPartnerId?: string | null
    fulfillmentPartnerOrderId?: string | null
    status: FulfillmentStatus
    stage?: string | null
    targetDeliveryDate?: any | null
    deliveryDate?: any | null
    deliveryPartner?: string | null
    trackingNumber?: string | null
    trackingUrl?: string | null
    attributes?: any | null
    collection?: boolean | null
    collectionName?: string | null
    sendAsUserId?: string | null
    failureReason?: FailureReason | null
    type?: ObjectType | null
    sendType?: SendType | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    shipTo?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
    cost?: {
      __typename?: 'OrderCost'
      subtotal?: number | null
      shipping?: number | null
      tax?: number | null
      thirdPartyFee?: number | null
      postalFee?: number | null
      taxEstimated?: boolean | null
      budgetExpense?: number | null
      total?: number | null
      currency?: Currency | null
    } | null
    history?: Array<{
      __typename?: 'FulfillmentHistory'
      status: FulfillmentStatus
      notes?: string | null
      currentLocation?: string | null
      created: any
    }> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    sendAs?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationList?: Array<{
      __typename?: 'ItemCustomization'
      id: string
      displayName?: string | null
      type?: ItemCustomizationType | null
      customizableBySender?: boolean | null
      customizableByRecipient?: boolean | null
      required?: boolean | null
      value?: any | null
      characterLimit?: number | null
      widthRecommendation?: number | null
      heightRecommendation?: number | null
      fileTypesAccepted?: Array<string> | null
      variantId?: string | null
    }> | null
    formFieldValueList?: Array<{ __typename?: 'FormFieldPair'; field: string; value: any }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
    approvalRequiredInfo?: {
      __typename?: 'ApprovalRequiredInfo'
      approvalRequiredReason: ApprovalRequiredReason
      autoAction?: AutoAction | null
      autoActionDate?: any | null
    } | null
    fulfillments?: Array<{
      __typename?: 'Fulfillment'
      id?: string | null
      marketplaceProductId?: string | null
      approvedPostalId?: string | null
      variantId?: string | null
      quantity?: number | null
      trackingNumber?: string | null
      trackingCompany?: string | null
      trackingUrl?: string | null
      status?: ItemStatus | null
      targetDeliveryDate?: any | null
      deliveryDate?: any | null
      cost?: {
        __typename?: 'OrderCost'
        subtotal?: number | null
        shipping?: number | null
        tax?: number | null
        thirdPartyFee?: number | null
        postalFee?: number | null
        taxEstimated?: boolean | null
        budgetExpense?: number | null
        total?: number | null
        currency?: Currency | null
      } | null
      actualCost?: {
        __typename?: 'OrderCost'
        subtotal?: number | null
        shipping?: number | null
        tax?: number | null
        thirdPartyFee?: number | null
        postalFee?: number | null
        taxEstimated?: boolean | null
        budgetExpense?: number | null
        total?: number | null
        currency?: Currency | null
      } | null
    }> | null
  }
}

export type GenerateContactUnsubscribeReportMutationVariables = Exact<{
  filter: ContactUnsubscribeFilterInput
  name?: InputMaybe<Scalars['String']['input']>
}>

export type GenerateContactUnsubscribeReportMutation = {
  __typename?: 'Mutation'
  generateContactUnsubscribeReport: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type GenerateLedgerReportMutationVariables = Exact<{
  filter: LedgerFilterInput
  name?: InputMaybe<Scalars['String']['input']>
}>

export type GenerateLedgerReportMutation = {
  __typename?: 'Mutation'
  generateLedgerReport: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type GenerateOrderReportMutationVariables = Exact<{
  filter: PostalFulfillmentFilterInput
  name?: InputMaybe<Scalars['String']['input']>
}>

export type GenerateOrderReportMutation = {
  __typename?: 'Mutation'
  generateOrderReport: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type InviteMutationVariables = Exact<{
  data: InviteUserInput
}>

export type InviteMutation = {
  __typename?: 'Mutation'
  invite?: Array<{
    __typename?: 'InviteResult'
    emailAddress: string
    result: Result
    errorMessage?: string | null
    invite?: {
      __typename?: 'Invite'
      id: string
      teamId?: string | null
      teamName?: string | null
      invitedByUserName?: string | null
      emailAddress: string
      status: InviteStatus
      roles?: Array<string> | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    } | null
  } | null> | null
}

export type OrderPostalMutationVariables = Exact<{
  data: PostalOrderRequestInput
}>

export type OrderPostalMutation = {
  __typename?: 'Mutation'
  orderPostal: {
    __typename?: 'PostalFulfillment'
    id: string
    accountId: string
    userId: string
    teamId?: string | null
    contactId?: string | null
    shipToName?: string | null
    shipToTitle?: string | null
    shipToCompanyName?: string | null
    campaignName?: string | null
    shipToEmail?: string | null
    deliveryEmail?: boolean | null
    campaignId?: string | null
    playbookId?: string | null
    playbookDefinitionId?: string | null
    playbookName?: string | null
    triggerId?: string | null
    magicLinkId?: string | null
    magicLinkName?: string | null
    itemName: string
    emailSubjectLine?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    previewImage?: string | null
    giftMessage?: string | null
    physicalMessage?: string | null
    thankYouMessage?: string | null
    marketplaceProductId?: string | null
    approvedPostalId?: string | null
    variantId?: string | null
    fulfillmentPartnerId?: string | null
    fulfillmentPartnerOrderId?: string | null
    status: FulfillmentStatus
    stage?: string | null
    targetDeliveryDate?: any | null
    deliveryDate?: any | null
    deliveryPartner?: string | null
    trackingNumber?: string | null
    trackingUrl?: string | null
    attributes?: any | null
    collection?: boolean | null
    collectionName?: string | null
    sendAsUserId?: string | null
    failureReason?: FailureReason | null
    type?: ObjectType | null
    sendType?: SendType | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    shipTo?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
    cost?: {
      __typename?: 'OrderCost'
      subtotal?: number | null
      shipping?: number | null
      tax?: number | null
      thirdPartyFee?: number | null
      postalFee?: number | null
      taxEstimated?: boolean | null
      budgetExpense?: number | null
      total?: number | null
      currency?: Currency | null
    } | null
    history?: Array<{
      __typename?: 'FulfillmentHistory'
      status: FulfillmentStatus
      notes?: string | null
      currentLocation?: string | null
      created: any
    }> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    sendAs?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationList?: Array<{
      __typename?: 'ItemCustomization'
      id: string
      displayName?: string | null
      type?: ItemCustomizationType | null
      customizableBySender?: boolean | null
      customizableByRecipient?: boolean | null
      required?: boolean | null
      value?: any | null
      characterLimit?: number | null
      widthRecommendation?: number | null
      heightRecommendation?: number | null
      fileTypesAccepted?: Array<string> | null
      variantId?: string | null
    }> | null
    formFieldValueList?: Array<{ __typename?: 'FormFieldPair'; field: string; value: any }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
    approvalRequiredInfo?: {
      __typename?: 'ApprovalRequiredInfo'
      approvalRequiredReason: ApprovalRequiredReason
      autoAction?: AutoAction | null
      autoActionDate?: any | null
    } | null
    fulfillments?: Array<{
      __typename?: 'Fulfillment'
      id?: string | null
      marketplaceProductId?: string | null
      approvedPostalId?: string | null
      variantId?: string | null
      quantity?: number | null
      trackingNumber?: string | null
      trackingCompany?: string | null
      trackingUrl?: string | null
      status?: ItemStatus | null
      targetDeliveryDate?: any | null
      deliveryDate?: any | null
      cost?: {
        __typename?: 'OrderCost'
        subtotal?: number | null
        shipping?: number | null
        tax?: number | null
        thirdPartyFee?: number | null
        postalFee?: number | null
        taxEstimated?: boolean | null
        budgetExpense?: number | null
        total?: number | null
        currency?: Currency | null
      } | null
      actualCost?: {
        __typename?: 'OrderCost'
        subtotal?: number | null
        shipping?: number | null
        tax?: number | null
        thirdPartyFee?: number | null
        postalFee?: number | null
        taxEstimated?: boolean | null
        budgetExpense?: number | null
        total?: number | null
        currency?: Currency | null
      } | null
    }> | null
  }
}

export type ProductRequestMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  reason?: InputMaybe<Scalars['String']['input']>
}>

export type ProductRequestMutation = {
  __typename?: 'Mutation'
  productRequest: { __typename?: 'ApiStatus'; status: string }
}

export type RecordUserActionMutationVariables = Exact<{
  name: Scalars['String']['input']
}>

export type RecordUserActionMutation = { __typename?: 'Mutation'; recordUserAction: boolean }

export type RemoveProductAccessMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  productAccessId: Scalars['ObjectId']['input']
}>

export type RemoveProductAccessMutation = {
  __typename?: 'Mutation'
  removeProductAccess: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    userName: string
    emailAddress: string
    profilePicture?: string | null
    phoneNumber?: string | null
    title?: string | null
    meetingLink?: string | null
    status: Status
    accountLockedUntil?: any | null
    authenticationFailures?: number | null
    handwritingName?: string | null
    auth?: Array<{
      __typename?: 'AuthenticationMethod'
      method: string
      version?: string | null
      expires?: any | null
      updated?: any | null
      created?: any | null
    } | null> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    productAccess?: Array<{
      __typename?: 'ProductAccess'
      id: string
      accountId?: string | null
      teamId?: string | null
      partnerId?: string | null
      product: Product
      roles?: Array<string> | null
      type?: ProductAccessType | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    }> | null
    crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null
    notifications?: {
      __typename?: 'UserNotifications'
      notificationsOn?: boolean | null
      balanceLowNotificationsOn?: boolean | null
      balanceLowChannels?: Array<UserNotificationChannel | null> | null
      lastBalanceLowNotificationSent?: any | null
      budgetLowNotificationsOn?: boolean | null
      budgetLowChannels?: Array<UserNotificationChannel | null> | null
      lastBudgetLowNotificationSent?: any | null
      giftEmailAcceptedNotificationsOn?: boolean | null
      giftEmailAcceptedChannels?: Array<UserNotificationChannel | null> | null
      magicLinkAcceptedNotificationsOn?: boolean | null
      magicLinkAcceptedChannels?: Array<UserNotificationChannel | null> | null
      orderDeliveredNotificationsOn?: boolean | null
      orderDeliveredChannels?: Array<UserNotificationChannel | null> | null
      thankYouNotificationsOn?: boolean | null
      thankYouChannels?: Array<UserNotificationChannel> | null
      landingPageExceptionNotificationOn?: boolean | null
      landingPageExceptionChannels?: Array<UserNotificationChannel> | null
      autoReloadFailuresNotificationsOn?: boolean | null
      autoReloadFailuresChannels?: Array<UserNotificationChannel | null> | null
      orderIssuesNotificationsOn?: boolean | null
      orderIssuesChannels?: Array<UserNotificationChannel | null> | null
    } | null
    emailDigestSettings?: {
      __typename?: 'EmailDigestSettings'
      processingErrors?: boolean | null
      ordersRequiringUserApproval?: boolean | null
      itemOutOfStock?: boolean | null
      itemLowStock?: boolean | null
      marketingUpdates?: boolean | null
      newItemApproved?: boolean | null
      newCollectionApproved?: boolean | null
      newEventApproved?: boolean | null
      itemRemoved?: boolean | null
      frequency?: EmailDigestFrequency | null
    } | null
  }
}

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  productAccessId: Scalars['ObjectId']['input']
}>

export type RemoveUserMutation = {
  __typename?: 'Mutation'
  removeUser: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    userName: string
    emailAddress: string
    profilePicture?: string | null
    phoneNumber?: string | null
    title?: string | null
    meetingLink?: string | null
    status: Status
    accountLockedUntil?: any | null
    authenticationFailures?: number | null
    handwritingName?: string | null
    auth?: Array<{
      __typename?: 'AuthenticationMethod'
      method: string
      version?: string | null
      expires?: any | null
      updated?: any | null
      created?: any | null
    } | null> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    productAccess?: Array<{
      __typename?: 'ProductAccess'
      id: string
      accountId?: string | null
      teamId?: string | null
      partnerId?: string | null
      product: Product
      roles?: Array<string> | null
      type?: ProductAccessType | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    }> | null
    crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null
    notifications?: {
      __typename?: 'UserNotifications'
      notificationsOn?: boolean | null
      balanceLowNotificationsOn?: boolean | null
      balanceLowChannels?: Array<UserNotificationChannel | null> | null
      lastBalanceLowNotificationSent?: any | null
      budgetLowNotificationsOn?: boolean | null
      budgetLowChannels?: Array<UserNotificationChannel | null> | null
      lastBudgetLowNotificationSent?: any | null
      giftEmailAcceptedNotificationsOn?: boolean | null
      giftEmailAcceptedChannels?: Array<UserNotificationChannel | null> | null
      magicLinkAcceptedNotificationsOn?: boolean | null
      magicLinkAcceptedChannels?: Array<UserNotificationChannel | null> | null
      orderDeliveredNotificationsOn?: boolean | null
      orderDeliveredChannels?: Array<UserNotificationChannel | null> | null
      thankYouNotificationsOn?: boolean | null
      thankYouChannels?: Array<UserNotificationChannel> | null
      landingPageExceptionNotificationOn?: boolean | null
      landingPageExceptionChannels?: Array<UserNotificationChannel> | null
      autoReloadFailuresNotificationsOn?: boolean | null
      autoReloadFailuresChannels?: Array<UserNotificationChannel | null> | null
      orderIssuesNotificationsOn?: boolean | null
      orderIssuesChannels?: Array<UserNotificationChannel | null> | null
    } | null
    emailDigestSettings?: {
      __typename?: 'EmailDigestSettings'
      processingErrors?: boolean | null
      ordersRequiringUserApproval?: boolean | null
      itemOutOfStock?: boolean | null
      itemLowStock?: boolean | null
      marketingUpdates?: boolean | null
      newItemApproved?: boolean | null
      newCollectionApproved?: boolean | null
      newEventApproved?: boolean | null
      itemRemoved?: boolean | null
      frequency?: EmailDigestFrequency | null
    } | null
  }
}

export type RemoveUserTeamMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  productAccessId: Scalars['ObjectId']['input']
}>

export type RemoveUserTeamMutation = {
  __typename?: 'Mutation'
  removeUserTeam: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    userName: string
    emailAddress: string
    profilePicture?: string | null
    phoneNumber?: string | null
    title?: string | null
    meetingLink?: string | null
    status: Status
    accountLockedUntil?: any | null
    authenticationFailures?: number | null
    handwritingName?: string | null
    auth?: Array<{
      __typename?: 'AuthenticationMethod'
      method: string
      version?: string | null
      expires?: any | null
      updated?: any | null
      created?: any | null
    } | null> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    productAccess?: Array<{
      __typename?: 'ProductAccess'
      id: string
      accountId?: string | null
      teamId?: string | null
      partnerId?: string | null
      product: Product
      roles?: Array<string> | null
      type?: ProductAccessType | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    }> | null
    crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null
    notifications?: {
      __typename?: 'UserNotifications'
      notificationsOn?: boolean | null
      balanceLowNotificationsOn?: boolean | null
      balanceLowChannels?: Array<UserNotificationChannel | null> | null
      lastBalanceLowNotificationSent?: any | null
      budgetLowNotificationsOn?: boolean | null
      budgetLowChannels?: Array<UserNotificationChannel | null> | null
      lastBudgetLowNotificationSent?: any | null
      giftEmailAcceptedNotificationsOn?: boolean | null
      giftEmailAcceptedChannels?: Array<UserNotificationChannel | null> | null
      magicLinkAcceptedNotificationsOn?: boolean | null
      magicLinkAcceptedChannels?: Array<UserNotificationChannel | null> | null
      orderDeliveredNotificationsOn?: boolean | null
      orderDeliveredChannels?: Array<UserNotificationChannel | null> | null
      thankYouNotificationsOn?: boolean | null
      thankYouChannels?: Array<UserNotificationChannel> | null
      landingPageExceptionNotificationOn?: boolean | null
      landingPageExceptionChannels?: Array<UserNotificationChannel> | null
      autoReloadFailuresNotificationsOn?: boolean | null
      autoReloadFailuresChannels?: Array<UserNotificationChannel | null> | null
      orderIssuesNotificationsOn?: boolean | null
      orderIssuesChannels?: Array<UserNotificationChannel | null> | null
    } | null
    emailDigestSettings?: {
      __typename?: 'EmailDigestSettings'
      processingErrors?: boolean | null
      ordersRequiringUserApproval?: boolean | null
      itemOutOfStock?: boolean | null
      itemLowStock?: boolean | null
      marketingUpdates?: boolean | null
      newItemApproved?: boolean | null
      newCollectionApproved?: boolean | null
      newEventApproved?: boolean | null
      itemRemoved?: boolean | null
      frequency?: EmailDigestFrequency | null
    } | null
  }
}

export type RequestAddFundsByInvoiceMutationVariables = Exact<{
  amountCents: Scalars['Int']['input']
  billingAccountId: Scalars['ObjectId']['input']
}>

export type RequestAddFundsByInvoiceMutation = {
  __typename?: 'Mutation'
  requestAddFundsByInvoice: { __typename?: 'ApiStatus'; status: string }
}

export type RequestAddFundsByInvoiceV2MutationVariables = Exact<{
  input: RequestFundsByInvoiceV2Request
}>

export type RequestAddFundsByInvoiceV2Mutation = {
  __typename?: 'Mutation'
  requestAddFundsByInvoiceV2: { __typename?: 'RequestFundsByInvoiceV2Response'; addFundsIntentId: string }
}

export type ResetOktaMutationVariables = Exact<{ [key: string]: never }>

export type ResetOktaMutation = {
  __typename?: 'Mutation'
  resetOkta: {
    __typename?: 'Account'
    id: string
    name: string
    domains?: Array<string> | null
    partnerIds?: Array<string> | null
    plan: string
    seats?: number | null
    brandingLogoEnabled?: boolean | null
    seatsAssigned?: number | null
    displayName?: string | null
    primaryLogoUrl?: string | null
    status: Status
    subscriptionBillingAccountId?: string | null
    subscriptionStartDate?: any | null
    subscriptionEndDate?: any | null
    billingTermStartDate?: any | null
    billingTermEndDate?: any | null
    trialSubscription?: boolean | null
    subscriptions?: Array<string> | null
    postalDeliveryEmailSetting?: PostalDeliveryEmailSetting | null
    postalDeliveryExpireAction?: PostalDeliveryExpireAction | null
    postalDeliveryExpirationDays?: number | null
    giftEmailTemplateId?: string | null
    emailTemplateId?: string | null
    giftEmailFont?: string | null
    giftEmailPrimaryColor?: string | null
    giftEmailSecondaryColor?: string | null
    giftEmailTertiaryColor?: string | null
    addressRefreshEnabled?: boolean | null
    addressRefreshDays?: number | null
    addressRefreshExpireAction?: PostalDeliveryExpireAction | null
    addressRefreshExpirationDays?: number | null
    sessionTimeoutMinutes?: number | null
    forceAuthenticationMethod?: string | null
    userCanViewMarketplace?: boolean | null
    userCanViewEvents?: boolean | null
    eventRequestNotificationEmails?: Array<string> | null
    marketplaceRequestNotificationEmails?: Array<string> | null
    supportEnabled?: any | null
    sfdcPackageVersion?: string | null
    address?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
    settings?: {
      __typename?: 'Settings'
      billingAccountId?: string | null
      billingAccountIds?: Array<{
        __typename?: 'BillingAccountMap'
        currency: Currency
        billingAccountId: string
      }> | null
      budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    } | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    sendSettings?: {
      __typename?: 'AccountSendSettings'
      bulkSendEnabled?: boolean | null
      disclaimerInfo?: { __typename?: 'DisclaimerInfo'; enabled?: boolean | null; disclaimer?: string | null } | null
    } | null
    notificationSettings?: {
      __typename?: 'AccountNotificationSettings'
      balanceLowNotificationThreshold?: number | null
      budgetLowNotificationThreshold?: number | null
    } | null
    accountContactSettings?: { __typename?: 'AccountContactSettings'; redactAddress?: boolean | null } | null
    oktaIntegration?: { __typename?: 'OktaIntegration'; clientId?: string | null; baseUrl?: string | null } | null
    oneLoginIntegration?: {
      __typename?: 'OneLoginIntegration'
      clientId?: string | null
      baseUrl?: string | null
    } | null
    linkApprovalSettings?: {
      __typename?: 'LinkApprovalSettings'
      autoAction: AutoAction
      autoActionDaysAfter: number
    } | null
    fiscalQuarterSetup?: {
      __typename?: 'FiscalQuarterSetup'
      method: QuarterCalculationMethod
      configuration?: string | null
    } | null
    brandingInstance?: { __typename?: 'BrandingInstance'; whiteLogoUrl?: string | null } | null
  }
}

export type ResetOneLoginMutationVariables = Exact<{ [key: string]: never }>

export type ResetOneLoginMutation = {
  __typename?: 'Mutation'
  resetOneLogin: {
    __typename?: 'Account'
    id: string
    name: string
    domains?: Array<string> | null
    partnerIds?: Array<string> | null
    plan: string
    seats?: number | null
    brandingLogoEnabled?: boolean | null
    seatsAssigned?: number | null
    displayName?: string | null
    primaryLogoUrl?: string | null
    status: Status
    subscriptionBillingAccountId?: string | null
    subscriptionStartDate?: any | null
    subscriptionEndDate?: any | null
    billingTermStartDate?: any | null
    billingTermEndDate?: any | null
    trialSubscription?: boolean | null
    subscriptions?: Array<string> | null
    postalDeliveryEmailSetting?: PostalDeliveryEmailSetting | null
    postalDeliveryExpireAction?: PostalDeliveryExpireAction | null
    postalDeliveryExpirationDays?: number | null
    giftEmailTemplateId?: string | null
    emailTemplateId?: string | null
    giftEmailFont?: string | null
    giftEmailPrimaryColor?: string | null
    giftEmailSecondaryColor?: string | null
    giftEmailTertiaryColor?: string | null
    addressRefreshEnabled?: boolean | null
    addressRefreshDays?: number | null
    addressRefreshExpireAction?: PostalDeliveryExpireAction | null
    addressRefreshExpirationDays?: number | null
    sessionTimeoutMinutes?: number | null
    forceAuthenticationMethod?: string | null
    userCanViewMarketplace?: boolean | null
    userCanViewEvents?: boolean | null
    eventRequestNotificationEmails?: Array<string> | null
    marketplaceRequestNotificationEmails?: Array<string> | null
    supportEnabled?: any | null
    sfdcPackageVersion?: string | null
    address?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
    settings?: {
      __typename?: 'Settings'
      billingAccountId?: string | null
      billingAccountIds?: Array<{
        __typename?: 'BillingAccountMap'
        currency: Currency
        billingAccountId: string
      }> | null
      budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    } | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    sendSettings?: {
      __typename?: 'AccountSendSettings'
      bulkSendEnabled?: boolean | null
      disclaimerInfo?: { __typename?: 'DisclaimerInfo'; enabled?: boolean | null; disclaimer?: string | null } | null
    } | null
    notificationSettings?: {
      __typename?: 'AccountNotificationSettings'
      balanceLowNotificationThreshold?: number | null
      budgetLowNotificationThreshold?: number | null
    } | null
    accountContactSettings?: { __typename?: 'AccountContactSettings'; redactAddress?: boolean | null } | null
    oktaIntegration?: { __typename?: 'OktaIntegration'; clientId?: string | null; baseUrl?: string | null } | null
    oneLoginIntegration?: {
      __typename?: 'OneLoginIntegration'
      clientId?: string | null
      baseUrl?: string | null
    } | null
    linkApprovalSettings?: {
      __typename?: 'LinkApprovalSettings'
      autoAction: AutoAction
      autoActionDaysAfter: number
    } | null
    fiscalQuarterSetup?: {
      __typename?: 'FiscalQuarterSetup'
      method: QuarterCalculationMethod
      configuration?: string | null
    } | null
    brandingInstance?: { __typename?: 'BrandingInstance'; whiteLogoUrl?: string | null } | null
  }
}

export type RetryBackgroundTaskMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type RetryBackgroundTaskMutation = {
  __typename?: 'Mutation'
  retryBackgroundTask: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type SaveAssetMutationVariables = Exact<{
  assetType?: InputMaybe<AssetType>
  name: Scalars['String']['input']
  requestId: Scalars['String']['input']
}>

export type SaveAssetMutation = {
  __typename?: 'Mutation'
  saveAsset: {
    __typename?: 'Asset'
    id: string
    type: AssetType
    name: string
    status: Status
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type SaveExternalCampaignMappingMutationVariables = Exact<{
  system: Scalars['String']['input']
  campaignId: Scalars['String']['input']
  mapping: ExtCampaignMappingInput
}>

export type SaveExternalCampaignMappingMutation = {
  __typename?: 'Mutation'
  saveExternalCampaignMapping: {
    __typename?: 'ExtCampaignMapping'
    id: string
    system: string
    campaignId: string
    setCampaignCost?: boolean | null
    opened?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    accepted?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    queued?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    shipped?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    delivered?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    bounced?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    meetingBooked?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    expired?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    failed?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    declined?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    donated?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
  }
}

export type SendForgotPasswordMutationVariables = Exact<{
  userId: Scalars['ObjectId']['input']
}>

export type SendForgotPasswordMutation = { __typename?: 'Mutation'; sendForgotPassword: boolean }

export type SetDefaultPaymentMethodMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  input: SetDefaultPaymentMethodInput
}>

export type SetDefaultPaymentMethodMutation = {
  __typename?: 'Mutation'
  setDefaultPaymentMethod: {
    __typename?: 'BillingAccount'
    id: string
    name: string
    status: Status
    type: BillingAccountType
    linkedAccountIdentifier?: string | null
    balance?: number | null
    prepaymentProcessing: boolean
    prepaymentBackgroundTaskId?: string | null
    currentPrepaymentAmount?: number | null
    autoReload?: boolean | null
    autoReloadAmount?: number | null
    autoReloadLowLimit?: number | null
    currency?: Currency | null
    incomingInvoiceAmount?: number | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    defaultPaymentMethod?: {
      __typename?: 'DefaultPaymentMethod'
      id?: string | null
      partnerId?: string | null
      paymentPartnerType?: PaymentPartnerType | null
      type?: PaymentMethodType | null
      autoReloadOk: boolean
      autoReloadFailures: number
      lastFailedAutoReloadAttempt?: any | null
      lastUnlocked?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    } | null
    incomingInvoiceActionRecord?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    billingContactInfo?: {
      __typename?: 'BillingContactInfo'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      address?: {
        __typename?: 'BillingContactAddress'
        line1?: string | null
        line2?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
      } | null
    } | null
    paymentPartners?: Array<{
      __typename?: 'PaymentPartnerInfo'
      type: PaymentPartnerType
      customerId: string
      lastSyncedAt?: any | null
      paymentMethods?: Array<{
        __typename?: 'PartnerPaymentMethod'
        partnerId: string
        billingDetails?: {
          __typename?: 'BillingPaymentMethodInfo'
          name?: string | null
          email?: string | null
          phone?: string | null
          address?: {
            __typename?: 'BillingContactAddress'
            line1?: string | null
            line2?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
          } | null
        } | null
        card?: {
          __typename?: 'CardPaymentMethod'
          expirationMonth: number
          expirationYear: number
          last4: string
          brand?: string | null
        } | null
      }> | null
    }> | null
    initialNetsuiteBalance?: { __typename?: 'InitialBalanceNetSuiteSyncInfo'; initializedAt?: any | null } | null
  }
}

export type SetupCreditCardMutationVariables = Exact<{
  billingAccountId: Scalars['ObjectId']['input']
  card: CreditCardPaymentInput
}>

export type SetupCreditCardMutation = { __typename?: 'Mutation'; setupCreditCard: boolean }

export type SetupCustomIntegrationMutationVariables = Exact<{
  systemName: CustomIntegrationNames
  clientId: Scalars['String']['input']
  clientSecret: Scalars['String']['input']
  baseUrl: Scalars['String']['input']
}>

export type SetupCustomIntegrationMutation = {
  __typename?: 'Mutation'
  setupCustomIntegration?: Array<{
    __typename?: 'IntegrationSync'
    id: string
    system: string
    status: Status
    lastRun?: any | null
    nextRun?: any | null
    syncTime?: any | null
    pollingTimeMins?: number | null
    workStatus?: BackgroundTaskStatus | null
    objectType: DataObjectType
    createEnabled?: boolean | null
    leadSourceFieldName?: string | null
    defaultLeadSourceValue?: string | null
    mappings?: Array<{
      __typename?: 'IntegrationDataMapping'
      postalIoFieldName: string
      externalFieldName?: string | null
      staticValue?: string | null
      remap?: any | null
    }> | null
    pushMappings?: Array<{
      __typename?: 'IntegrationDataMapping'
      postalIoFieldName: string
      externalFieldName?: string | null
      staticValue?: string | null
      remap?: any | null
    }> | null
    createCustomFields?: Array<{ __typename?: 'IntegrationCustomField'; field: string; value: string }> | null
  }> | null
}

export type SetupDataSourceMutationVariables = Exact<{
  systemName: Scalars['String']['input']
}>

export type SetupDataSourceMutation = {
  __typename?: 'Mutation'
  setupDataSource?: Array<{
    __typename?: 'IntegrationSync'
    id: string
    system: string
    status: Status
    lastRun?: any | null
    nextRun?: any | null
    syncTime?: any | null
    pollingTimeMins?: number | null
    workStatus?: BackgroundTaskStatus | null
    objectType: DataObjectType
    createEnabled?: boolean | null
    leadSourceFieldName?: string | null
    defaultLeadSourceValue?: string | null
    mappings?: Array<{
      __typename?: 'IntegrationDataMapping'
      postalIoFieldName: string
      externalFieldName?: string | null
      staticValue?: string | null
      remap?: any | null
    }> | null
    pushMappings?: Array<{
      __typename?: 'IntegrationDataMapping'
      postalIoFieldName: string
      externalFieldName?: string | null
      staticValue?: string | null
      remap?: any | null
    }> | null
    createCustomFields?: Array<{ __typename?: 'IntegrationCustomField'; field: string; value: string }> | null
  }> | null
}

export type SetupOktaMutationVariables = Exact<{
  data: OktaIntegrationInput
}>

export type SetupOktaMutation = {
  __typename?: 'Mutation'
  setupOkta: {
    __typename?: 'Account'
    id: string
    name: string
    domains?: Array<string> | null
    partnerIds?: Array<string> | null
    plan: string
    seats?: number | null
    brandingLogoEnabled?: boolean | null
    seatsAssigned?: number | null
    displayName?: string | null
    primaryLogoUrl?: string | null
    status: Status
    subscriptionBillingAccountId?: string | null
    subscriptionStartDate?: any | null
    subscriptionEndDate?: any | null
    billingTermStartDate?: any | null
    billingTermEndDate?: any | null
    trialSubscription?: boolean | null
    subscriptions?: Array<string> | null
    postalDeliveryEmailSetting?: PostalDeliveryEmailSetting | null
    postalDeliveryExpireAction?: PostalDeliveryExpireAction | null
    postalDeliveryExpirationDays?: number | null
    giftEmailTemplateId?: string | null
    emailTemplateId?: string | null
    giftEmailFont?: string | null
    giftEmailPrimaryColor?: string | null
    giftEmailSecondaryColor?: string | null
    giftEmailTertiaryColor?: string | null
    addressRefreshEnabled?: boolean | null
    addressRefreshDays?: number | null
    addressRefreshExpireAction?: PostalDeliveryExpireAction | null
    addressRefreshExpirationDays?: number | null
    sessionTimeoutMinutes?: number | null
    forceAuthenticationMethod?: string | null
    userCanViewMarketplace?: boolean | null
    userCanViewEvents?: boolean | null
    eventRequestNotificationEmails?: Array<string> | null
    marketplaceRequestNotificationEmails?: Array<string> | null
    supportEnabled?: any | null
    sfdcPackageVersion?: string | null
    address?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
    settings?: {
      __typename?: 'Settings'
      billingAccountId?: string | null
      billingAccountIds?: Array<{
        __typename?: 'BillingAccountMap'
        currency: Currency
        billingAccountId: string
      }> | null
      budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    } | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    sendSettings?: {
      __typename?: 'AccountSendSettings'
      bulkSendEnabled?: boolean | null
      disclaimerInfo?: { __typename?: 'DisclaimerInfo'; enabled?: boolean | null; disclaimer?: string | null } | null
    } | null
    notificationSettings?: {
      __typename?: 'AccountNotificationSettings'
      balanceLowNotificationThreshold?: number | null
      budgetLowNotificationThreshold?: number | null
    } | null
    accountContactSettings?: { __typename?: 'AccountContactSettings'; redactAddress?: boolean | null } | null
    oktaIntegration?: { __typename?: 'OktaIntegration'; clientId?: string | null; baseUrl?: string | null } | null
    oneLoginIntegration?: {
      __typename?: 'OneLoginIntegration'
      clientId?: string | null
      baseUrl?: string | null
    } | null
    linkApprovalSettings?: {
      __typename?: 'LinkApprovalSettings'
      autoAction: AutoAction
      autoActionDaysAfter: number
    } | null
    fiscalQuarterSetup?: {
      __typename?: 'FiscalQuarterSetup'
      method: QuarterCalculationMethod
      configuration?: string | null
    } | null
    brandingInstance?: { __typename?: 'BrandingInstance'; whiteLogoUrl?: string | null } | null
  }
}

export type SetupOneLoginMutationVariables = Exact<{
  data: OneLoginIntegrationInput
}>

export type SetupOneLoginMutation = {
  __typename?: 'Mutation'
  setupOneLogin: {
    __typename?: 'Account'
    id: string
    name: string
    domains?: Array<string> | null
    partnerIds?: Array<string> | null
    plan: string
    seats?: number | null
    brandingLogoEnabled?: boolean | null
    seatsAssigned?: number | null
    displayName?: string | null
    primaryLogoUrl?: string | null
    status: Status
    subscriptionBillingAccountId?: string | null
    subscriptionStartDate?: any | null
    subscriptionEndDate?: any | null
    billingTermStartDate?: any | null
    billingTermEndDate?: any | null
    trialSubscription?: boolean | null
    subscriptions?: Array<string> | null
    postalDeliveryEmailSetting?: PostalDeliveryEmailSetting | null
    postalDeliveryExpireAction?: PostalDeliveryExpireAction | null
    postalDeliveryExpirationDays?: number | null
    giftEmailTemplateId?: string | null
    emailTemplateId?: string | null
    giftEmailFont?: string | null
    giftEmailPrimaryColor?: string | null
    giftEmailSecondaryColor?: string | null
    giftEmailTertiaryColor?: string | null
    addressRefreshEnabled?: boolean | null
    addressRefreshDays?: number | null
    addressRefreshExpireAction?: PostalDeliveryExpireAction | null
    addressRefreshExpirationDays?: number | null
    sessionTimeoutMinutes?: number | null
    forceAuthenticationMethod?: string | null
    userCanViewMarketplace?: boolean | null
    userCanViewEvents?: boolean | null
    eventRequestNotificationEmails?: Array<string> | null
    marketplaceRequestNotificationEmails?: Array<string> | null
    supportEnabled?: any | null
    sfdcPackageVersion?: string | null
    address?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
    settings?: {
      __typename?: 'Settings'
      billingAccountId?: string | null
      billingAccountIds?: Array<{
        __typename?: 'BillingAccountMap'
        currency: Currency
        billingAccountId: string
      }> | null
      budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    } | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    sendSettings?: {
      __typename?: 'AccountSendSettings'
      bulkSendEnabled?: boolean | null
      disclaimerInfo?: { __typename?: 'DisclaimerInfo'; enabled?: boolean | null; disclaimer?: string | null } | null
    } | null
    notificationSettings?: {
      __typename?: 'AccountNotificationSettings'
      balanceLowNotificationThreshold?: number | null
      budgetLowNotificationThreshold?: number | null
    } | null
    accountContactSettings?: { __typename?: 'AccountContactSettings'; redactAddress?: boolean | null } | null
    oktaIntegration?: { __typename?: 'OktaIntegration'; clientId?: string | null; baseUrl?: string | null } | null
    oneLoginIntegration?: {
      __typename?: 'OneLoginIntegration'
      clientId?: string | null
      baseUrl?: string | null
    } | null
    linkApprovalSettings?: {
      __typename?: 'LinkApprovalSettings'
      autoAction: AutoAction
      autoActionDaysAfter: number
    } | null
    fiscalQuarterSetup?: {
      __typename?: 'FiscalQuarterSetup'
      method: QuarterCalculationMethod
      configuration?: string | null
    } | null
    brandingInstance?: { __typename?: 'BrandingInstance'; whiteLogoUrl?: string | null } | null
  }
}

export type SignupMutationVariables = Exact<{
  data: SignupInput
}>

export type SignupMutation = {
  __typename?: 'Mutation'
  signup: {
    __typename?: 'Account'
    id: string
    name: string
    domains?: Array<string> | null
    partnerIds?: Array<string> | null
    plan: string
    seats?: number | null
    brandingLogoEnabled?: boolean | null
    seatsAssigned?: number | null
    displayName?: string | null
    primaryLogoUrl?: string | null
    status: Status
    subscriptionBillingAccountId?: string | null
    subscriptionStartDate?: any | null
    subscriptionEndDate?: any | null
    billingTermStartDate?: any | null
    billingTermEndDate?: any | null
    trialSubscription?: boolean | null
    subscriptions?: Array<string> | null
    postalDeliveryEmailSetting?: PostalDeliveryEmailSetting | null
    postalDeliveryExpireAction?: PostalDeliveryExpireAction | null
    postalDeliveryExpirationDays?: number | null
    giftEmailTemplateId?: string | null
    emailTemplateId?: string | null
    giftEmailFont?: string | null
    giftEmailPrimaryColor?: string | null
    giftEmailSecondaryColor?: string | null
    giftEmailTertiaryColor?: string | null
    addressRefreshEnabled?: boolean | null
    addressRefreshDays?: number | null
    addressRefreshExpireAction?: PostalDeliveryExpireAction | null
    addressRefreshExpirationDays?: number | null
    sessionTimeoutMinutes?: number | null
    forceAuthenticationMethod?: string | null
    userCanViewMarketplace?: boolean | null
    userCanViewEvents?: boolean | null
    eventRequestNotificationEmails?: Array<string> | null
    marketplaceRequestNotificationEmails?: Array<string> | null
    supportEnabled?: any | null
    sfdcPackageVersion?: string | null
    address?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
    settings?: {
      __typename?: 'Settings'
      billingAccountId?: string | null
      billingAccountIds?: Array<{
        __typename?: 'BillingAccountMap'
        currency: Currency
        billingAccountId: string
      }> | null
      budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    } | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    sendSettings?: {
      __typename?: 'AccountSendSettings'
      bulkSendEnabled?: boolean | null
      disclaimerInfo?: { __typename?: 'DisclaimerInfo'; enabled?: boolean | null; disclaimer?: string | null } | null
    } | null
    notificationSettings?: {
      __typename?: 'AccountNotificationSettings'
      balanceLowNotificationThreshold?: number | null
      budgetLowNotificationThreshold?: number | null
    } | null
    accountContactSettings?: { __typename?: 'AccountContactSettings'; redactAddress?: boolean | null } | null
    oktaIntegration?: { __typename?: 'OktaIntegration'; clientId?: string | null; baseUrl?: string | null } | null
    oneLoginIntegration?: {
      __typename?: 'OneLoginIntegration'
      clientId?: string | null
      baseUrl?: string | null
    } | null
    linkApprovalSettings?: {
      __typename?: 'LinkApprovalSettings'
      autoAction: AutoAction
      autoActionDaysAfter: number
    } | null
    fiscalQuarterSetup?: {
      __typename?: 'FiscalQuarterSetup'
      method: QuarterCalculationMethod
      configuration?: string | null
    } | null
    brandingInstance?: { __typename?: 'BrandingInstance'; whiteLogoUrl?: string | null } | null
  }
}

export type StartRecipeMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type StartRecipeMutation = { __typename?: 'Mutation'; startRecipe?: boolean | null }

export type StartTrialMutationVariables = Exact<{ [key: string]: never }>

export type StartTrialMutation = {
  __typename?: 'Mutation'
  startTrial: { __typename?: 'StartTrialResponse'; subscriptionId?: string | null }
}

export type StopRecipeMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type StopRecipeMutation = { __typename?: 'Mutation'; stopRecipe?: boolean | null }

export type SubmitFormMutationVariables = Exact<{
  type: FormType
  variables: Scalars['Object']['input']
}>

export type SubmitFormMutation = {
  __typename?: 'Mutation'
  submitForm?: { __typename?: 'ApiStatus'; status: string } | null
}

export type SyncBillingAccountPaymentPartnerMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type SyncBillingAccountPaymentPartnerMutation = {
  __typename?: 'Mutation'
  syncBillingAccountPaymentPartner: {
    __typename?: 'BillingAccount'
    id: string
    name: string
    status: Status
    type: BillingAccountType
    linkedAccountIdentifier?: string | null
    balance?: number | null
    prepaymentProcessing: boolean
    prepaymentBackgroundTaskId?: string | null
    currentPrepaymentAmount?: number | null
    autoReload?: boolean | null
    autoReloadAmount?: number | null
    autoReloadLowLimit?: number | null
    currency?: Currency | null
    incomingInvoiceAmount?: number | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    defaultPaymentMethod?: {
      __typename?: 'DefaultPaymentMethod'
      id?: string | null
      partnerId?: string | null
      paymentPartnerType?: PaymentPartnerType | null
      type?: PaymentMethodType | null
      autoReloadOk: boolean
      autoReloadFailures: number
      lastFailedAutoReloadAttempt?: any | null
      lastUnlocked?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    } | null
    incomingInvoiceActionRecord?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    billingContactInfo?: {
      __typename?: 'BillingContactInfo'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      address?: {
        __typename?: 'BillingContactAddress'
        line1?: string | null
        line2?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
      } | null
    } | null
    paymentPartners?: Array<{
      __typename?: 'PaymentPartnerInfo'
      type: PaymentPartnerType
      customerId: string
      lastSyncedAt?: any | null
      paymentMethods?: Array<{
        __typename?: 'PartnerPaymentMethod'
        partnerId: string
        billingDetails?: {
          __typename?: 'BillingPaymentMethodInfo'
          name?: string | null
          email?: string | null
          phone?: string | null
          address?: {
            __typename?: 'BillingContactAddress'
            line1?: string | null
            line2?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
          } | null
        } | null
        card?: {
          __typename?: 'CardPaymentMethod'
          expirationMonth: number
          expirationYear: number
          last4: string
          brand?: string | null
        } | null
      }> | null
    }> | null
    initialNetsuiteBalance?: { __typename?: 'InitialBalanceNetSuiteSyncInfo'; initializedAt?: any | null } | null
  }
}

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: UpdateAccountInput
}>

export type UpdateAccountMutation = {
  __typename?: 'Mutation'
  updateAccount: {
    __typename?: 'Account'
    id: string
    name: string
    domains?: Array<string> | null
    partnerIds?: Array<string> | null
    plan: string
    seats?: number | null
    brandingLogoEnabled?: boolean | null
    seatsAssigned?: number | null
    displayName?: string | null
    primaryLogoUrl?: string | null
    status: Status
    subscriptionBillingAccountId?: string | null
    subscriptionStartDate?: any | null
    subscriptionEndDate?: any | null
    billingTermStartDate?: any | null
    billingTermEndDate?: any | null
    trialSubscription?: boolean | null
    subscriptions?: Array<string> | null
    postalDeliveryEmailSetting?: PostalDeliveryEmailSetting | null
    postalDeliveryExpireAction?: PostalDeliveryExpireAction | null
    postalDeliveryExpirationDays?: number | null
    giftEmailTemplateId?: string | null
    emailTemplateId?: string | null
    giftEmailFont?: string | null
    giftEmailPrimaryColor?: string | null
    giftEmailSecondaryColor?: string | null
    giftEmailTertiaryColor?: string | null
    addressRefreshEnabled?: boolean | null
    addressRefreshDays?: number | null
    addressRefreshExpireAction?: PostalDeliveryExpireAction | null
    addressRefreshExpirationDays?: number | null
    sessionTimeoutMinutes?: number | null
    forceAuthenticationMethod?: string | null
    userCanViewMarketplace?: boolean | null
    userCanViewEvents?: boolean | null
    eventRequestNotificationEmails?: Array<string> | null
    marketplaceRequestNotificationEmails?: Array<string> | null
    supportEnabled?: any | null
    sfdcPackageVersion?: string | null
    address?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
    settings?: {
      __typename?: 'Settings'
      billingAccountId?: string | null
      billingAccountIds?: Array<{
        __typename?: 'BillingAccountMap'
        currency: Currency
        billingAccountId: string
      }> | null
      budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    } | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    sendSettings?: {
      __typename?: 'AccountSendSettings'
      bulkSendEnabled?: boolean | null
      disclaimerInfo?: { __typename?: 'DisclaimerInfo'; enabled?: boolean | null; disclaimer?: string | null } | null
    } | null
    notificationSettings?: {
      __typename?: 'AccountNotificationSettings'
      balanceLowNotificationThreshold?: number | null
      budgetLowNotificationThreshold?: number | null
    } | null
    accountContactSettings?: { __typename?: 'AccountContactSettings'; redactAddress?: boolean | null } | null
    oktaIntegration?: { __typename?: 'OktaIntegration'; clientId?: string | null; baseUrl?: string | null } | null
    oneLoginIntegration?: {
      __typename?: 'OneLoginIntegration'
      clientId?: string | null
      baseUrl?: string | null
    } | null
    linkApprovalSettings?: {
      __typename?: 'LinkApprovalSettings'
      autoAction: AutoAction
      autoActionDaysAfter: number
    } | null
    fiscalQuarterSetup?: {
      __typename?: 'FiscalQuarterSetup'
      method: QuarterCalculationMethod
      configuration?: string | null
    } | null
    brandingInstance?: { __typename?: 'BrandingInstance'; whiteLogoUrl?: string | null } | null
  }
}

export type UpdateApprovedPostalMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: ApprovedPostalUpdateInput
}>

export type UpdateApprovedPostalMutation = {
  __typename?: 'Mutation'
  updateApprovedPostal: {
    __typename?: 'ApprovedPostalResponse'
    postal: {
      __typename?: 'ApprovedPostal'
      id: string
      userId?: string | null
      ownerId?: string | null
      teamIds?: Array<string> | null
      name: string
      displayName?: string | null
      description: string
      brandName?: string | null
      status: Status
      category: string
      type?: string | null
      subCategory?: Array<string> | null
      useCases?: Array<string> | null
      marketplaceProductId: string
      variantOrderFlexibility?: boolean | null
      collection?: boolean | null
      currency?: Currency | null
      customizable?: boolean | null
      geographicMarkets?: Array<string> | null
      imageUrls?: Array<{ __typename?: 'ImageReference'; url: string; description?: string | null }> | null
      variants?: Array<{
        __typename?: 'ApprovedProductVariant'
        id: string
        marketplaceProductId?: string | null
        approvedPostalId?: string | null
        productName?: string | null
        variantName: string
        displayName?: string | null
        description?: string | null
        status: Status
        price: number
        displayPrice: number
        flatFees?: number | null
        displaySubscriberPrice: number
        shippingPriceEstimateMin?: number | null
        shippingPriceEstimateMax?: number | null
        giftMessageSupported?: boolean | null
        physicalMessageSupported?: boolean | null
        containsPerishables?: boolean | null
        imageUrls?: Array<{ __typename?: 'ImageReference'; url: string; description?: string | null }> | null
      }> | null
      designTemplate?: {
        __typename?: 'DesignTemplate'
        dpi: number
        front?: Array<{
          __typename?: 'ElementConfig'
          name: string
          description?: string | null
          hidden?: boolean | null
          locked?: boolean | null
          noPdfOutput?: boolean | null
          settings?: any | null
          customizable?: boolean | null
          location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
          resize?: {
            __typename?: 'ElementResize'
            minWidth: number
            minHeight: number
            maxWidth: number
            maxHeight: number
          } | null
          boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
        }> | null
        back?: Array<{
          __typename?: 'ElementConfig'
          name: string
          description?: string | null
          hidden?: boolean | null
          locked?: boolean | null
          noPdfOutput?: boolean | null
          settings?: any | null
          customizable?: boolean | null
          location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
          resize?: {
            __typename?: 'ElementResize'
            minWidth: number
            minHeight: number
            maxWidth: number
            maxHeight: number
          } | null
          boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
        }> | null
        outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
      } | null
      event?: {
        __typename?: 'MagicEventInstance'
        requestedByName: string
        requestedByEmail: string
        requestedByPhone: string
        requestedByMessage: string
        requestedAttendeeCount: number
        spotsRemaining?: number | null
        calendarRequestedDate?: any | null
        requestedDates?: Array<any> | null
        eventDateTime?: any | null
        eventLengthMinutes?: number | null
        status: MagicEventStatus
        lastRegistrationDateTime?: any | null
        meetingLink?: string | null
        sendEmailConfirmation?: boolean | null
        sendReminderDayBefore?: boolean | null
        sendReminderDayOf?: boolean | null
        sendCancelledAlert?: boolean | null
        sendInviteExpiringAlert?: boolean | null
        sendMeetingLinkChanged?: boolean | null
        sendDateTimeChanged?: boolean | null
        sendShippedAlert?: boolean | null
        sendDeliveredAlert?: boolean | null
        confirmationCustomization?: {
          __typename?: 'EventEmailCustomization'
          subjectLine?: string | null
          body?: string | null
        } | null
        reminderTomorrowCustomization?: {
          __typename?: 'EventEmailCustomization'
          subjectLine?: string | null
          body?: string | null
        } | null
        reminderTodayCustomization?: {
          __typename?: 'EventEmailCustomization'
          subjectLine?: string | null
          body?: string | null
        } | null
        cancellationCustomization?: {
          __typename?: 'EventEmailCustomization'
          subjectLine?: string | null
          body?: string | null
        } | null
        meetingLinkChangedCustomization?: {
          __typename?: 'EventEmailCustomization'
          subjectLine?: string | null
          body?: string | null
        } | null
        dateTimeChangedCustomization?: {
          __typename?: 'EventEmailCustomization'
          subjectLine?: string | null
          body?: string | null
        } | null
      } | null
      eventDetails?: {
        __typename?: 'MagicEvent'
        eventLengthMinutes: number
        minimumAttendees?: number | null
        maximumAttendees?: number | null
        requirements?: Array<Requirements> | null
        talentEmailAddress: string
        talentName: string
        talentPhoneNumber: string
        talentCompany?: string | null
      } | null
      metrics?: {
        __typename?: 'BasicMetrics'
        queued?: number | null
        failed?: number | null
        sent?: number | null
        deliveryEmailAccepted?: number | null
        deliveryEmailSent?: number | null
        deliveryEmailOpened?: number | null
        deliveryEmailClicked?: number | null
        deliveryEmailDonated?: number | null
        deliveryEmailDeclined?: number | null
        deliveryEmailUnsubscribed?: number | null
        deliveryEmailFailed?: number | null
        deliveryEmailExpired?: number | null
        accepted?: number | null
        delivered?: number | null
        bounces?: number | null
        touches?: number | null
        costInCents?: number | null
        gmvUSD?: number | null
        gmvCAD?: number | null
        gmvEUR?: number | null
        gmvGBP?: number | null
        started?: number | null
        completed?: number | null
        cancelled?: number | null
        successful?: number | null
        campaignsSent?: number | null
        playbooksStarted?: number | null
        triggersExecuted?: number | null
        linkTotalViews?: number | null
        linkUniqViews?: number | null
        linkMaxExeViews?: number | null
        linkExpiredViews?: number | null
        linkExecutions?: number | null
        fundsAdded?: number | null
        eventsBooked?: number | null
        meetingsBooked?: number | null
        thankYou?: number | null
        socialShare?: number | null
        newContact?: number | null
        linkNewContact?: number | null
        activeMagicLinks?: number | null
        date?: any | null
      } | null
      attribution?: {
        __typename?: 'Attribution'
        marketoProgramId?: string | null
        marketoProgramName?: string | null
        marketoCampaignId?: string | null
        marketoCampaignName?: string | null
        sfdcCampaignId?: string | null
        sfdcCampaignName?: string | null
        sdfcSandboxCampaignId?: string | null
        sfdcSandboxCampaignName?: string | null
        eloquaCampaignId?: string | null
        eloquaCampaignName?: string | null
      } | null
      itemCustomizationList?: Array<{
        __typename?: 'ItemCustomization'
        id: string
        displayName?: string | null
        type?: ItemCustomizationType | null
        customizableBySender?: boolean | null
        customizableByRecipient?: boolean | null
        required?: boolean | null
        value?: any | null
        characterLimit?: number | null
        widthRecommendation?: number | null
        heightRecommendation?: number | null
        fileTypesAccepted?: Array<string> | null
        variantId?: string | null
      }> | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      eventFeeSettings?: {
        __typename?: 'EventFeeSettings'
        flatFee?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      } | null
    }
    previewGenerationTask?: {
      __typename?: 'BackgroundTask'
      id: string
      relatedObjectId?: string | null
      name: string
      taskAction: string
      type: BackgroundTaskType
      status: BackgroundTaskStatus
      processedCount: number
      successCount: number
      errorCount: number
      taskStarted?: any | null
      taskCompleted?: any | null
      taskErrored?: any | null
      outputs?: any | null
      errors?: Array<{
        __typename?: 'BackgroundOperationError'
        code: string
        count: number
        message?: string | null
      }> | null
    } | null
  }
}

export type UpdateAssetMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: AssetInput
}>

export type UpdateAssetMutation = {
  __typename?: 'Mutation'
  updateAsset: {
    __typename?: 'Asset'
    id: string
    type: AssetType
    name: string
    status: Status
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type UpdateBillingAccountMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: UpdateBillingAccountInput
}>

export type UpdateBillingAccountMutation = {
  __typename?: 'Mutation'
  updateBillingAccount: {
    __typename?: 'BillingAccount'
    id: string
    name: string
    status: Status
    type: BillingAccountType
    linkedAccountIdentifier?: string | null
    balance?: number | null
    prepaymentProcessing: boolean
    prepaymentBackgroundTaskId?: string | null
    currentPrepaymentAmount?: number | null
    autoReload?: boolean | null
    autoReloadAmount?: number | null
    autoReloadLowLimit?: number | null
    currency?: Currency | null
    incomingInvoiceAmount?: number | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    defaultPaymentMethod?: {
      __typename?: 'DefaultPaymentMethod'
      id?: string | null
      partnerId?: string | null
      paymentPartnerType?: PaymentPartnerType | null
      type?: PaymentMethodType | null
      autoReloadOk: boolean
      autoReloadFailures: number
      lastFailedAutoReloadAttempt?: any | null
      lastUnlocked?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    } | null
    incomingInvoiceActionRecord?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    billingContactInfo?: {
      __typename?: 'BillingContactInfo'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      address?: {
        __typename?: 'BillingContactAddress'
        line1?: string | null
        line2?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
      } | null
    } | null
    paymentPartners?: Array<{
      __typename?: 'PaymentPartnerInfo'
      type: PaymentPartnerType
      customerId: string
      lastSyncedAt?: any | null
      paymentMethods?: Array<{
        __typename?: 'PartnerPaymentMethod'
        partnerId: string
        billingDetails?: {
          __typename?: 'BillingPaymentMethodInfo'
          name?: string | null
          email?: string | null
          phone?: string | null
          address?: {
            __typename?: 'BillingContactAddress'
            line1?: string | null
            line2?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
          } | null
        } | null
        card?: {
          __typename?: 'CardPaymentMethod'
          expirationMonth: number
          expirationYear: number
          last4: string
          brand?: string | null
        } | null
      }> | null
    }> | null
    initialNetsuiteBalance?: { __typename?: 'InitialBalanceNetSuiteSyncInfo'; initializedAt?: any | null } | null
  }
}

export type UpdateBlocklistEntryMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: BlocklistEntryInput
}>

export type UpdateBlocklistEntryMutation = {
  __typename?: 'Mutation'
  updateBlocklistEntry: {
    __typename?: 'BlocklistEntry'
    id: string
    accountId: string
    dataObjectType: DataObjectType
    fieldName: string
    entry: string
    filterType: BlocklistFilterType
  }
}

export type UpdateCampaignMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: CampaignInput
}>

export type UpdateCampaignMutation = {
  __typename?: 'Mutation'
  updateCampaign: {
    __typename?: 'Campaign'
    id: string
    userId: string
    name: string
    campaignType?: CampaignType | null
    status: CampaignStatus
    scheduleDate?: any | null
    cancelDate?: any | null
    errorDate?: any | null
    completeDate?: any | null
    giftMessage?: string | null
    backgroundTaskId?: string | null
    playbookId?: string | null
    approvedPostalId?: string | null
    variantId?: string | null
    totalContacts?: number | null
    totalContactsAttempted?: number | null
    deliveryEmail?: boolean | null
    deliveryEmailTemplateId?: string | null
    physicalMessage?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    emailSubjectLine?: string | null
    sendAsContactOwner?: boolean | null
    sendAsUser?: string | null
    teamId?: string | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    designTemplate?: {
      __typename?: 'DesignTemplate'
      dpi: number
      front?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      back?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
    } | null
    metrics?: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    } | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationInputs?: Array<{
      __typename?: 'ItemCustomizationInput'
      id: string
      value: any
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
  }
}

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: ContactUpdateInput
}>

export type UpdateContactMutation = {
  __typename?: 'Mutation'
  updateContact: {
    __typename?: 'Contact'
    id: string
    firstName: string
    lastName?: string | null
    title?: string | null
    emailAddress?: string | null
    ownerId?: string | null
    type: ContactType
    companyName?: string | null
    lastSend?: any | null
    lastActivity?: any | null
    tags?: Array<string> | null
    hasActivePlaybooks: boolean
    phones?: Array<{
      __typename?: 'PhoneMap'
      phoneNumber: string
      type: PhoneType
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    } | null> | null
    addresses?: Array<{
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null> | null
    ownerLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    metrics: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    }
    lists?: Array<{ __typename?: 'ContactListMap'; name: string; userId: string; listId: string }> | null
    campaigns?: Array<{
      __typename?: 'CampaignContact'
      campaignId: string
      campaignName?: string | null
      processed?: any | null
      postalFulfillmentId?: string | null
      status?: FulfillmentStatus | null
    }> | null
    playbooks?: Array<{
      __typename?: 'PlaybookMap'
      playbookDefinitionId: string
      playbookInstanceId: string
      name: string
      playbookStatus: PlaybookStatus
      nextStepToExecute?: number | null
      created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    }> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    deleted?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    mappings?: Array<{
      __typename?: 'ExternalMapping'
      system: string
      id: string
      owner?: string | null
      profileUrl?: string | null
      accountId?: string | null
      companyId?: string | null
      created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    }> | null
  }
}

export type UpdateContactListMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: ContactListInput
}>

export type UpdateContactListMutation = {
  __typename?: 'Mutation'
  updateContactList: {
    __typename?: 'ContactList'
    id: string
    name: string
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type UpdateIntegrationSyncMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: IntegrationSyncInput
}>

export type UpdateIntegrationSyncMutation = {
  __typename?: 'Mutation'
  updateIntegrationSync: {
    __typename?: 'IntegrationSync'
    id: string
    system: string
    status: Status
    lastRun?: any | null
    nextRun?: any | null
    syncTime?: any | null
    pollingTimeMins?: number | null
    workStatus?: BackgroundTaskStatus | null
    objectType: DataObjectType
    createEnabled?: boolean | null
    leadSourceFieldName?: string | null
    defaultLeadSourceValue?: string | null
    mappings?: Array<{
      __typename?: 'IntegrationDataMapping'
      postalIoFieldName: string
      externalFieldName?: string | null
      staticValue?: string | null
      remap?: any | null
    }> | null
    pushMappings?: Array<{
      __typename?: 'IntegrationDataMapping'
      postalIoFieldName: string
      externalFieldName?: string | null
      staticValue?: string | null
      remap?: any | null
    }> | null
    createCustomFields?: Array<{ __typename?: 'IntegrationCustomField'; field: string; value: string }> | null
  }
}

export type UpdateIntegrationSyncStatusMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  status: Status
}>

export type UpdateIntegrationSyncStatusMutation = {
  __typename?: 'Mutation'
  updateIntegrationSyncStatus: {
    __typename?: 'IntegrationSync'
    id: string
    system: string
    status: Status
    lastRun?: any | null
    nextRun?: any | null
    syncTime?: any | null
    pollingTimeMins?: number | null
    workStatus?: BackgroundTaskStatus | null
    objectType: DataObjectType
    createEnabled?: boolean | null
    leadSourceFieldName?: string | null
    defaultLeadSourceValue?: string | null
    mappings?: Array<{
      __typename?: 'IntegrationDataMapping'
      postalIoFieldName: string
      externalFieldName?: string | null
      staticValue?: string | null
      remap?: any | null
    }> | null
    pushMappings?: Array<{
      __typename?: 'IntegrationDataMapping'
      postalIoFieldName: string
      externalFieldName?: string | null
      staticValue?: string | null
      remap?: any | null
    }> | null
    createCustomFields?: Array<{ __typename?: 'IntegrationCustomField'; field: string; value: string }> | null
  }
}

export type UpdateMagicLinkMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: MagicLinkUpdateInput
}>

export type UpdateMagicLinkMutation = {
  __typename?: 'Mutation'
  updateMagicLink: {
    __typename?: 'MagicLink'
    id: string
    teamId?: string | null
    name: string
    linkUrl: string
    status: MagicLinkStatus
    expirationDate?: any | null
    maxExecutions?: number | null
    message: string
    approvedPostalId: string
    variantId: string
    distributionChannel?: string | null
    lastView?: any | null
    lastExecution?: any | null
    physicalMessage?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    sendAsContactOwner?: boolean | null
    sendAsUser?: string | null
    requiresApproval?: boolean | null
    requiresUuid?: boolean | null
    metrics: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    }
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationInputs?: Array<{
      __typename?: 'ItemCustomizationInput'
      id: string
      value: any
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
  }
}

export type UpdateMessageTemplateMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: UpdateMessageTemplateInput
}>

export type UpdateMessageTemplateMutation = {
  __typename?: 'Mutation'
  updateMessageTemplate: {
    __typename?: 'MessageTemplate'
    id: string
    name: string
    templateText: string
    sharedWith?: { __typename?: 'ShareLink'; type: LinkType; target: string } | null
  }
}

export type UpdatePlaybookMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: PlaybookInstanceUpdateInput
}>

export type UpdatePlaybookMutation = {
  __typename?: 'Mutation'
  updatePlaybook: {
    __typename?: 'PlaybookInstance'
    id: string
    name?: string | null
    playbookDefinitionId: string
    playbookStatus?: PlaybookStatus | null
    contactId: string
    contactName?: string | null
    triggerId?: string | null
    errMessage?: string | null
    nextStepToExecute?: number | null
    nextExecutionDate?: any | null
    teamId?: string | null
    steps: Array<{
      __typename?: 'PlaybookStepInstance'
      type: PlaybookStepType
      playbookStatus?: PlaybookStatus | null
      errMessage?: string | null
      delay: number
      delayUnit: ChronoUnit
      approvedPostalId: string
      variantId: string
      giftMessage?: string | null
      deliveryEmail?: boolean | null
      fulfillmentId?: string | null
      fulfillmentStatus?: FulfillmentStatus | null
      physicalMessage?: string | null
      meetingRequestSetting?: MeetingRequestSetting | null
      sendAsContactOwner?: boolean | null
      sendAsUser?: string | null
      spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      itemCustomizationInputs?: Array<{
        __typename?: 'ItemCustomizationInput'
        id: string
        value: any
        variantId?: string | null
      }> | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      landingPageCustomization?: {
        __typename?: 'LandingPageCustomization'
        headerText?: string | null
        body?: string | null
        includeHeadshot?: boolean | null
        includeSenderName?: boolean | null
      } | null
      recipientEmailSettings?: {
        __typename?: 'RecipientEmailSettings'
        shippedEmailsOn?: boolean | null
        deliveredEmailsOn?: boolean | null
      } | null
      giftEmailReminderSettings?: {
        __typename?: 'GiftEmailReminderSettings'
        expirationSoonReminders?: Array<number> | null
      } | null
    } | null>
    metrics?: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
  }
}

export type UpdatePlaybookDefinitionMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: PlaybookDefinitionUpdateInput
}>

export type UpdatePlaybookDefinitionMutation = {
  __typename?: 'Mutation'
  updatePlaybookDefinition: {
    __typename?: 'PlaybookDefinition'
    id: string
    teamId?: string | null
    status?: Status | null
    name?: string | null
    currency?: Currency | null
    steps: Array<{
      __typename?: 'PlaybookStepDefinition'
      type: PlaybookStepType
      delay: number
      delayUnit: ChronoUnit
      approvedPostalId: string
      variantId: string
      giftMessage?: string | null
      deliveryEmail?: boolean | null
      displayPrice?: number | null
      displaySubscriberPrice?: number | null
      productName?: string | null
      deliveryEmailTemplateId?: string | null
      physicalMessage?: string | null
      meetingRequestSetting?: MeetingRequestSetting | null
      emailSubjectLine?: string | null
      sendAsContactOwner?: boolean | null
      sendAsUser?: string | null
      spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      itemCustomizationInputs?: Array<{
        __typename?: 'ItemCustomizationInput'
        id: string
        value: any
        variantId?: string | null
      }> | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      landingPageCustomization?: {
        __typename?: 'LandingPageCustomization'
        headerText?: string | null
        body?: string | null
        includeHeadshot?: boolean | null
        includeSenderName?: boolean | null
      } | null
      recipientEmailSettings?: {
        __typename?: 'RecipientEmailSettings'
        shippedEmailsOn?: boolean | null
        deliveredEmailsOn?: boolean | null
      } | null
      giftEmailReminderSettings?: {
        __typename?: 'GiftEmailReminderSettings'
        expirationSoonReminders?: Array<number> | null
      } | null
    } | null>
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
  }
}

export type UpdateRolePermissionsMutationVariables = Exact<{
  role: Scalars['String']['input']
  addPermissions?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  removePermissions?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
}>

export type UpdateRolePermissionsMutation = {
  __typename?: 'Mutation'
  updateRolePermissions?: {
    __typename?: 'RolePermission'
    roleName: string
    permissions: Array<string>
    available: Array<{ __typename?: 'PermissionDefinition'; name: string; description: string }>
  } | null
}

export type UpdateRolesMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  productAccessId: Scalars['ObjectId']['input']
  roles?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateRolesMutation = {
  __typename?: 'Mutation'
  updateRoles: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    userName: string
    emailAddress: string
    profilePicture?: string | null
    phoneNumber?: string | null
    title?: string | null
    meetingLink?: string | null
    status: Status
    accountLockedUntil?: any | null
    authenticationFailures?: number | null
    handwritingName?: string | null
    auth?: Array<{
      __typename?: 'AuthenticationMethod'
      method: string
      version?: string | null
      expires?: any | null
      updated?: any | null
      created?: any | null
    } | null> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    productAccess?: Array<{
      __typename?: 'ProductAccess'
      id: string
      accountId?: string | null
      teamId?: string | null
      partnerId?: string | null
      product: Product
      roles?: Array<string> | null
      type?: ProductAccessType | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    }> | null
    crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null
    notifications?: {
      __typename?: 'UserNotifications'
      notificationsOn?: boolean | null
      balanceLowNotificationsOn?: boolean | null
      balanceLowChannels?: Array<UserNotificationChannel | null> | null
      lastBalanceLowNotificationSent?: any | null
      budgetLowNotificationsOn?: boolean | null
      budgetLowChannels?: Array<UserNotificationChannel | null> | null
      lastBudgetLowNotificationSent?: any | null
      giftEmailAcceptedNotificationsOn?: boolean | null
      giftEmailAcceptedChannels?: Array<UserNotificationChannel | null> | null
      magicLinkAcceptedNotificationsOn?: boolean | null
      magicLinkAcceptedChannels?: Array<UserNotificationChannel | null> | null
      orderDeliveredNotificationsOn?: boolean | null
      orderDeliveredChannels?: Array<UserNotificationChannel | null> | null
      thankYouNotificationsOn?: boolean | null
      thankYouChannels?: Array<UserNotificationChannel> | null
      landingPageExceptionNotificationOn?: boolean | null
      landingPageExceptionChannels?: Array<UserNotificationChannel> | null
      autoReloadFailuresNotificationsOn?: boolean | null
      autoReloadFailuresChannels?: Array<UserNotificationChannel | null> | null
      orderIssuesNotificationsOn?: boolean | null
      orderIssuesChannels?: Array<UserNotificationChannel | null> | null
    } | null
    emailDigestSettings?: {
      __typename?: 'EmailDigestSettings'
      processingErrors?: boolean | null
      ordersRequiringUserApproval?: boolean | null
      itemOutOfStock?: boolean | null
      itemLowStock?: boolean | null
      marketingUpdates?: boolean | null
      newItemApproved?: boolean | null
      newCollectionApproved?: boolean | null
      newEventApproved?: boolean | null
      itemRemoved?: boolean | null
      frequency?: EmailDigestFrequency | null
    } | null
  }
}

export type UpdateRolesV2MutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  product?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  roles?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateRolesV2Mutation = {
  __typename?: 'Mutation'
  updateRolesV2?: Array<{
    __typename?: 'RoleAssignmentResult'
    role: Role
    result: Result
    reason?: string | null
  }> | null
}

export type UpdateSavedSendMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: UpdateSavedSendInput
}>

export type UpdateSavedSendMutation = {
  __typename?: 'Mutation'
  updateSavedSend: {
    __typename?: 'SavedSend'
    id: string
    savedSendName: string
    userId: string
    teamId?: string | null
    status: SavedSendStatus
    sendType: SavedSendType
    sendFlowStep?: SendFlowStep | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    commonSendProperties: {
      __typename?: 'CommonSendProperties'
      name?: string | null
      approvedPostalId: string
      variantId?: string | null
      physicalMessage?: string | null
      meetingRequestSetting?: MeetingRequestSetting | null
      sendAsContactOwner?: boolean | null
      sendAsUser?: string | null
      spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      itemCustomizationInputs?: Array<{
        __typename?: 'ItemCustomizationInput'
        id: string
        value: any
        variantId?: string | null
      }> | null
      landingPageCustomization?: {
        __typename?: 'LandingPageCustomization'
        headerText?: string | null
        body?: string | null
        includeHeadshot?: boolean | null
        includeSenderName?: boolean | null
      } | null
      recipientEmailSettings?: {
        __typename?: 'RecipientEmailSettings'
        shippedEmailsOn?: boolean | null
        deliveredEmailsOn?: boolean | null
      } | null
      giftEmailReminderSettings?: {
        __typename?: 'GiftEmailReminderSettings'
        expirationSoonReminders?: Array<number> | null
      } | null
    }
    savedSendMagicLinkInfo?: {
      __typename?: 'SavedSendMagicLinkInfo'
      expirationDate?: any | null
      maxExecutions?: number | null
      message?: string | null
      requiresApproval?: boolean | null
      status?: MagicLinkStatus | null
    } | null
    savedSendCampaignInfo?: {
      __typename?: 'SavedSendCampaignInfo'
      deliveryEmail?: boolean | null
      useSameMessage?: boolean | null
      emailSubjectLine?: string | null
      giftMessage?: string | null
      deliveryEmailTemplateId?: string | null
      scheduleDate?: any | null
      contactSearchFilters?: Array<any> | null
      contactCount?: number | null
    } | null
    savedSendBulkSendInfo?: {
      __typename?: 'SavedSendBulkSendInfo'
      quantity?: number | null
      contactId?: string | null
      addressVerified?: boolean | null
      shipToAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
      newContact?: {
        __typename?: 'NewContact'
        firstName?: string | null
        lastName?: string | null
        emailAddress?: string | null
        phones?: Array<{
          __typename?: 'NewPhoneMap'
          phoneNumber?: string | null
          type?: PhoneType | null
        } | null> | null
      } | null
    } | null
  }
}

export type UpdateSelfMutationVariables = Exact<{
  data: UpdateSelfInput
}>

export type UpdateSelfMutation = {
  __typename?: 'Mutation'
  updateSelf: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    userName: string
    emailAddress: string
    profilePicture?: string | null
    phoneNumber?: string | null
    title?: string | null
    meetingLink?: string | null
    status: Status
    accountLockedUntil?: any | null
    authenticationFailures?: number | null
    handwritingName?: string | null
    auth?: Array<{
      __typename?: 'AuthenticationMethod'
      method: string
      version?: string | null
      expires?: any | null
      updated?: any | null
      created?: any | null
    } | null> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    productAccess?: Array<{
      __typename?: 'ProductAccess'
      id: string
      accountId?: string | null
      teamId?: string | null
      partnerId?: string | null
      product: Product
      roles?: Array<string> | null
      type?: ProductAccessType | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    }> | null
    crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null
    notifications?: {
      __typename?: 'UserNotifications'
      notificationsOn?: boolean | null
      balanceLowNotificationsOn?: boolean | null
      balanceLowChannels?: Array<UserNotificationChannel | null> | null
      lastBalanceLowNotificationSent?: any | null
      budgetLowNotificationsOn?: boolean | null
      budgetLowChannels?: Array<UserNotificationChannel | null> | null
      lastBudgetLowNotificationSent?: any | null
      giftEmailAcceptedNotificationsOn?: boolean | null
      giftEmailAcceptedChannels?: Array<UserNotificationChannel | null> | null
      magicLinkAcceptedNotificationsOn?: boolean | null
      magicLinkAcceptedChannels?: Array<UserNotificationChannel | null> | null
      orderDeliveredNotificationsOn?: boolean | null
      orderDeliveredChannels?: Array<UserNotificationChannel | null> | null
      thankYouNotificationsOn?: boolean | null
      thankYouChannels?: Array<UserNotificationChannel> | null
      landingPageExceptionNotificationOn?: boolean | null
      landingPageExceptionChannels?: Array<UserNotificationChannel> | null
      autoReloadFailuresNotificationsOn?: boolean | null
      autoReloadFailuresChannels?: Array<UserNotificationChannel | null> | null
      orderIssuesNotificationsOn?: boolean | null
      orderIssuesChannels?: Array<UserNotificationChannel | null> | null
    } | null
    emailDigestSettings?: {
      __typename?: 'EmailDigestSettings'
      processingErrors?: boolean | null
      ordersRequiringUserApproval?: boolean | null
      itemOutOfStock?: boolean | null
      itemLowStock?: boolean | null
      marketingUpdates?: boolean | null
      newItemApproved?: boolean | null
      newCollectionApproved?: boolean | null
      newEventApproved?: boolean | null
      itemRemoved?: boolean | null
      frequency?: EmailDigestFrequency | null
    } | null
  }
}

export type UpdateTeamMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: UpdateTeamInput
}>

export type UpdateTeamMutation = {
  __typename?: 'Mutation'
  updateTeam: {
    __typename?: 'Team'
    id: string
    name: string
    status: Status
    accountId: string
    parentTeamId?: string | null
    settings?: {
      __typename?: 'Settings'
      billingAccountId?: string | null
      billingAccountIds?: Array<{
        __typename?: 'BillingAccountMap'
        currency: Currency
        billingAccountId: string
      }> | null
      budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    } | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  data: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    userName: string
    emailAddress: string
    profilePicture?: string | null
    phoneNumber?: string | null
    title?: string | null
    meetingLink?: string | null
    status: Status
    accountLockedUntil?: any | null
    authenticationFailures?: number | null
    handwritingName?: string | null
    auth?: Array<{
      __typename?: 'AuthenticationMethod'
      method: string
      version?: string | null
      expires?: any | null
      updated?: any | null
      created?: any | null
    } | null> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    productAccess?: Array<{
      __typename?: 'ProductAccess'
      id: string
      accountId?: string | null
      teamId?: string | null
      partnerId?: string | null
      product: Product
      roles?: Array<string> | null
      type?: ProductAccessType | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    }> | null
    crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null
    notifications?: {
      __typename?: 'UserNotifications'
      notificationsOn?: boolean | null
      balanceLowNotificationsOn?: boolean | null
      balanceLowChannels?: Array<UserNotificationChannel | null> | null
      lastBalanceLowNotificationSent?: any | null
      budgetLowNotificationsOn?: boolean | null
      budgetLowChannels?: Array<UserNotificationChannel | null> | null
      lastBudgetLowNotificationSent?: any | null
      giftEmailAcceptedNotificationsOn?: boolean | null
      giftEmailAcceptedChannels?: Array<UserNotificationChannel | null> | null
      magicLinkAcceptedNotificationsOn?: boolean | null
      magicLinkAcceptedChannels?: Array<UserNotificationChannel | null> | null
      orderDeliveredNotificationsOn?: boolean | null
      orderDeliveredChannels?: Array<UserNotificationChannel | null> | null
      thankYouNotificationsOn?: boolean | null
      thankYouChannels?: Array<UserNotificationChannel> | null
      landingPageExceptionNotificationOn?: boolean | null
      landingPageExceptionChannels?: Array<UserNotificationChannel> | null
      autoReloadFailuresNotificationsOn?: boolean | null
      autoReloadFailuresChannels?: Array<UserNotificationChannel | null> | null
      orderIssuesNotificationsOn?: boolean | null
      orderIssuesChannels?: Array<UserNotificationChannel | null> | null
    } | null
    emailDigestSettings?: {
      __typename?: 'EmailDigestSettings'
      processingErrors?: boolean | null
      ordersRequiringUserApproval?: boolean | null
      itemOutOfStock?: boolean | null
      itemLowStock?: boolean | null
      marketingUpdates?: boolean | null
      newItemApproved?: boolean | null
      newCollectionApproved?: boolean | null
      newEventApproved?: boolean | null
      itemRemoved?: boolean | null
      frequency?: EmailDigestFrequency | null
    } | null
  }
}

export type UpdateUserNotificationsMutationVariables = Exact<{
  data: UserNotificationsInput
}>

export type UpdateUserNotificationsMutation = {
  __typename?: 'Mutation'
  updateUserNotifications: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    userName: string
    emailAddress: string
    profilePicture?: string | null
    phoneNumber?: string | null
    title?: string | null
    meetingLink?: string | null
    status: Status
    accountLockedUntil?: any | null
    authenticationFailures?: number | null
    handwritingName?: string | null
    auth?: Array<{
      __typename?: 'AuthenticationMethod'
      method: string
      version?: string | null
      expires?: any | null
      updated?: any | null
      created?: any | null
    } | null> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    productAccess?: Array<{
      __typename?: 'ProductAccess'
      id: string
      accountId?: string | null
      teamId?: string | null
      partnerId?: string | null
      product: Product
      roles?: Array<string> | null
      type?: ProductAccessType | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    }> | null
    crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null
    notifications?: {
      __typename?: 'UserNotifications'
      notificationsOn?: boolean | null
      balanceLowNotificationsOn?: boolean | null
      balanceLowChannels?: Array<UserNotificationChannel | null> | null
      lastBalanceLowNotificationSent?: any | null
      budgetLowNotificationsOn?: boolean | null
      budgetLowChannels?: Array<UserNotificationChannel | null> | null
      lastBudgetLowNotificationSent?: any | null
      giftEmailAcceptedNotificationsOn?: boolean | null
      giftEmailAcceptedChannels?: Array<UserNotificationChannel | null> | null
      magicLinkAcceptedNotificationsOn?: boolean | null
      magicLinkAcceptedChannels?: Array<UserNotificationChannel | null> | null
      orderDeliveredNotificationsOn?: boolean | null
      orderDeliveredChannels?: Array<UserNotificationChannel | null> | null
      thankYouNotificationsOn?: boolean | null
      thankYouChannels?: Array<UserNotificationChannel> | null
      landingPageExceptionNotificationOn?: boolean | null
      landingPageExceptionChannels?: Array<UserNotificationChannel> | null
      autoReloadFailuresNotificationsOn?: boolean | null
      autoReloadFailuresChannels?: Array<UserNotificationChannel | null> | null
      orderIssuesNotificationsOn?: boolean | null
      orderIssuesChannels?: Array<UserNotificationChannel | null> | null
    } | null
    emailDigestSettings?: {
      __typename?: 'EmailDigestSettings'
      processingErrors?: boolean | null
      ordersRequiringUserApproval?: boolean | null
      itemOutOfStock?: boolean | null
      itemLowStock?: boolean | null
      marketingUpdates?: boolean | null
      newItemApproved?: boolean | null
      newCollectionApproved?: boolean | null
      newEventApproved?: boolean | null
      itemRemoved?: boolean | null
      frequency?: EmailDigestFrequency | null
    } | null
  }
}

export type UpdateUserTeamMutationVariables = Exact<{
  id: Scalars['ObjectId']['input']
  productAccessId: Scalars['ObjectId']['input']
  teamId: Scalars['ObjectId']['input']
}>

export type UpdateUserTeamMutation = {
  __typename?: 'Mutation'
  updateUserTeam: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    userName: string
    emailAddress: string
    profilePicture?: string | null
    phoneNumber?: string | null
    title?: string | null
    meetingLink?: string | null
    status: Status
    accountLockedUntil?: any | null
    authenticationFailures?: number | null
    handwritingName?: string | null
    auth?: Array<{
      __typename?: 'AuthenticationMethod'
      method: string
      version?: string | null
      expires?: any | null
      updated?: any | null
      created?: any | null
    } | null> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    productAccess?: Array<{
      __typename?: 'ProductAccess'
      id: string
      accountId?: string | null
      teamId?: string | null
      partnerId?: string | null
      product: Product
      roles?: Array<string> | null
      type?: ProductAccessType | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    }> | null
    crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null
    notifications?: {
      __typename?: 'UserNotifications'
      notificationsOn?: boolean | null
      balanceLowNotificationsOn?: boolean | null
      balanceLowChannels?: Array<UserNotificationChannel | null> | null
      lastBalanceLowNotificationSent?: any | null
      budgetLowNotificationsOn?: boolean | null
      budgetLowChannels?: Array<UserNotificationChannel | null> | null
      lastBudgetLowNotificationSent?: any | null
      giftEmailAcceptedNotificationsOn?: boolean | null
      giftEmailAcceptedChannels?: Array<UserNotificationChannel | null> | null
      magicLinkAcceptedNotificationsOn?: boolean | null
      magicLinkAcceptedChannels?: Array<UserNotificationChannel | null> | null
      orderDeliveredNotificationsOn?: boolean | null
      orderDeliveredChannels?: Array<UserNotificationChannel | null> | null
      thankYouNotificationsOn?: boolean | null
      thankYouChannels?: Array<UserNotificationChannel> | null
      landingPageExceptionNotificationOn?: boolean | null
      landingPageExceptionChannels?: Array<UserNotificationChannel> | null
      autoReloadFailuresNotificationsOn?: boolean | null
      autoReloadFailuresChannels?: Array<UserNotificationChannel | null> | null
      orderIssuesNotificationsOn?: boolean | null
      orderIssuesChannels?: Array<UserNotificationChannel | null> | null
    } | null
    emailDigestSettings?: {
      __typename?: 'EmailDigestSettings'
      processingErrors?: boolean | null
      ordersRequiringUserApproval?: boolean | null
      itemOutOfStock?: boolean | null
      itemLowStock?: boolean | null
      marketingUpdates?: boolean | null
      newItemApproved?: boolean | null
      newCollectionApproved?: boolean | null
      newEventApproved?: boolean | null
      itemRemoved?: boolean | null
      frequency?: EmailDigestFrequency | null
    } | null
  }
}

export type UpsertContactMutationVariables = Exact<{
  data: ContactInput
}>

export type UpsertContactMutation = {
  __typename?: 'Mutation'
  upsertContact: {
    __typename?: 'Contact'
    id: string
    firstName: string
    lastName?: string | null
    title?: string | null
    emailAddress?: string | null
    ownerId?: string | null
    type: ContactType
    companyName?: string | null
    lastSend?: any | null
    lastActivity?: any | null
    tags?: Array<string> | null
    hasActivePlaybooks: boolean
    phones?: Array<{
      __typename?: 'PhoneMap'
      phoneNumber: string
      type: PhoneType
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    } | null> | null
    addresses?: Array<{
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null> | null
    ownerLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    metrics: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    }
    lists?: Array<{ __typename?: 'ContactListMap'; name: string; userId: string; listId: string }> | null
    campaigns?: Array<{
      __typename?: 'CampaignContact'
      campaignId: string
      campaignName?: string | null
      processed?: any | null
      postalFulfillmentId?: string | null
      status?: FulfillmentStatus | null
    }> | null
    playbooks?: Array<{
      __typename?: 'PlaybookMap'
      playbookDefinitionId: string
      playbookInstanceId: string
      name: string
      playbookStatus: PlaybookStatus
      nextStepToExecute?: number | null
      created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    }> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    deleted?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    mappings?: Array<{
      __typename?: 'ExternalMapping'
      system: string
      id: string
      owner?: string | null
      profileUrl?: string | null
      accountId?: string | null
      companyId?: string | null
      created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    }> | null
  }
}

export type AccessTokenQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ObjectId']['input']>
}>

export type AccessTokenQuery = {
  __typename?: 'Query'
  accessToken?: { __typename?: 'TokenResponse'; token: string; refreshToken?: string | null } | null
}

export type AssetsQueryVariables = Exact<{ [key: string]: never }>

export type AssetsQuery = {
  __typename?: 'Query'
  assets?: Array<{
    __typename?: 'Asset'
    id: string
    type: AssetType
    name: string
    status: Status
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  } | null> | null
}

export type AutocompleteContactsQueryVariables = Exact<{
  prefix: Scalars['String']['input']
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type AutocompleteContactsQuery = {
  __typename?: 'Query'
  autocompleteContacts: {
    __typename?: 'ContactSearchResponse'
    contacts?: Array<{
      __typename?: 'Contact'
      id: string
      firstName: string
      lastName?: string | null
      title?: string | null
      emailAddress?: string | null
      ownerId?: string | null
      type: ContactType
      companyName?: string | null
      lastSend?: any | null
      lastActivity?: any | null
      tags?: Array<string> | null
      hasActivePlaybooks: boolean
      phones?: Array<{
        __typename?: 'PhoneMap'
        phoneNumber: string
        type: PhoneType
        created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
      } | null> | null
      addresses?: Array<{
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null> | null
      ownerLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
      metrics: {
        __typename?: 'BasicMetrics'
        queued?: number | null
        failed?: number | null
        sent?: number | null
        deliveryEmailAccepted?: number | null
        deliveryEmailSent?: number | null
        deliveryEmailOpened?: number | null
        deliveryEmailClicked?: number | null
        deliveryEmailDonated?: number | null
        deliveryEmailDeclined?: number | null
        deliveryEmailUnsubscribed?: number | null
        deliveryEmailFailed?: number | null
        deliveryEmailExpired?: number | null
        accepted?: number | null
        delivered?: number | null
        bounces?: number | null
        touches?: number | null
        costInCents?: number | null
        gmvUSD?: number | null
        gmvCAD?: number | null
        gmvEUR?: number | null
        gmvGBP?: number | null
        started?: number | null
        completed?: number | null
        cancelled?: number | null
        successful?: number | null
        campaignsSent?: number | null
        playbooksStarted?: number | null
        triggersExecuted?: number | null
        linkTotalViews?: number | null
        linkUniqViews?: number | null
        linkMaxExeViews?: number | null
        linkExpiredViews?: number | null
        linkExecutions?: number | null
        fundsAdded?: number | null
        eventsBooked?: number | null
        meetingsBooked?: number | null
        thankYou?: number | null
        socialShare?: number | null
        newContact?: number | null
        linkNewContact?: number | null
        activeMagicLinks?: number | null
        date?: any | null
      }
      lists?: Array<{ __typename?: 'ContactListMap'; name: string; userId: string; listId: string }> | null
      campaigns?: Array<{
        __typename?: 'CampaignContact'
        campaignId: string
        campaignName?: string | null
        processed?: any | null
        postalFulfillmentId?: string | null
        status?: FulfillmentStatus | null
      }> | null
      playbooks?: Array<{
        __typename?: 'PlaybookMap'
        playbookDefinitionId: string
        playbookInstanceId: string
        name: string
        playbookStatus: PlaybookStatus
        nextStepToExecute?: number | null
        created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      }> | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
      updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      deleted?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      mappings?: Array<{
        __typename?: 'ExternalMapping'
        system: string
        id: string
        owner?: string | null
        profileUrl?: string | null
        accountId?: string | null
        companyId?: string | null
        created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      }> | null
    }> | null
    resultsSummary: {
      __typename?: 'ResultsSummary'
      hasMore: boolean
      currentPage: number
      totalRecords?: number | null
      totalPages?: number | null
    }
  }
}

export type BasicAnalyticsV2QueryVariables = Exact<{
  config: AnalyticsRequestConfig
}>

export type BasicAnalyticsV2Query = {
  __typename?: 'Query'
  basicAnalyticsV2?: Array<{
    __typename?: 'BasicMetrics'
    queued?: number | null
    failed?: number | null
    sent?: number | null
    deliveryEmailAccepted?: number | null
    deliveryEmailSent?: number | null
    deliveryEmailOpened?: number | null
    deliveryEmailClicked?: number | null
    deliveryEmailDonated?: number | null
    deliveryEmailDeclined?: number | null
    deliveryEmailUnsubscribed?: number | null
    deliveryEmailFailed?: number | null
    deliveryEmailExpired?: number | null
    accepted?: number | null
    delivered?: number | null
    bounces?: number | null
    touches?: number | null
    costInCents?: number | null
    gmvUSD?: number | null
    gmvCAD?: number | null
    gmvEUR?: number | null
    gmvGBP?: number | null
    started?: number | null
    completed?: number | null
    cancelled?: number | null
    successful?: number | null
    campaignsSent?: number | null
    playbooksStarted?: number | null
    triggersExecuted?: number | null
    linkTotalViews?: number | null
    linkUniqViews?: number | null
    linkMaxExeViews?: number | null
    linkExpiredViews?: number | null
    linkExecutions?: number | null
    fundsAdded?: number | null
    eventsBooked?: number | null
    meetingsBooked?: number | null
    thankYou?: number | null
    socialShare?: number | null
    newContact?: number | null
    linkNewContact?: number | null
    activeMagicLinks?: number | null
    date?: any | null
  }> | null
}

export type BillingAccountsQueryVariables = Exact<{
  filter?: InputMaybe<BillingAccountFilterInput>
}>

export type BillingAccountsQuery = {
  __typename?: 'Query'
  billingAccounts?: Array<{
    __typename?: 'BillingAccount'
    id: string
    name: string
    status: Status
    type: BillingAccountType
    linkedAccountIdentifier?: string | null
    balance?: number | null
    prepaymentProcessing: boolean
    prepaymentBackgroundTaskId?: string | null
    currentPrepaymentAmount?: number | null
    autoReload?: boolean | null
    autoReloadAmount?: number | null
    autoReloadLowLimit?: number | null
    currency?: Currency | null
    incomingInvoiceAmount?: number | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    defaultPaymentMethod?: {
      __typename?: 'DefaultPaymentMethod'
      id?: string | null
      partnerId?: string | null
      paymentPartnerType?: PaymentPartnerType | null
      type?: PaymentMethodType | null
      autoReloadOk: boolean
      autoReloadFailures: number
      lastFailedAutoReloadAttempt?: any | null
      lastUnlocked?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    } | null
    incomingInvoiceActionRecord?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    billingContactInfo?: {
      __typename?: 'BillingContactInfo'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      address?: {
        __typename?: 'BillingContactAddress'
        line1?: string | null
        line2?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
      } | null
    } | null
    paymentPartners?: Array<{
      __typename?: 'PaymentPartnerInfo'
      type: PaymentPartnerType
      customerId: string
      lastSyncedAt?: any | null
      paymentMethods?: Array<{
        __typename?: 'PartnerPaymentMethod'
        partnerId: string
        billingDetails?: {
          __typename?: 'BillingPaymentMethodInfo'
          name?: string | null
          email?: string | null
          phone?: string | null
          address?: {
            __typename?: 'BillingContactAddress'
            line1?: string | null
            line2?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
          } | null
        } | null
        card?: {
          __typename?: 'CardPaymentMethod'
          expirationMonth: number
          expirationYear: number
          last4: string
          brand?: string | null
        } | null
      }> | null
    }> | null
    initialNetsuiteBalance?: { __typename?: 'InitialBalanceNetSuiteSyncInfo'; initializedAt?: any | null } | null
  }> | null
}

export type CheckAutomationsTiedToPlaybookQueryVariables = Exact<{
  playbookDefinitionId: Scalars['ObjectId']['input']
}>

export type CheckAutomationsTiedToPlaybookQuery = {
  __typename?: 'Query'
  checkAutomationsTiedToPlaybook?: Array<{
    __typename?: 'Automations'
    type: DataObjectType
    hasAutomations: boolean
    total?: number | null
    hasMore?: boolean | null
    items?: Array<{ __typename?: 'AutomationItem'; id: string; name?: string | null } | null> | null
  }> | null
}

export type CheckAutomationsTiedToPostalQueryVariables = Exact<{
  approvedPostalId: Scalars['ObjectId']['input']
}>

export type CheckAutomationsTiedToPostalQuery = {
  __typename?: 'Query'
  checkAutomationsTiedToPostal?: Array<{
    __typename?: 'Automations'
    type: DataObjectType
    hasAutomations: boolean
    total?: number | null
    hasMore?: boolean | null
    items?: Array<{ __typename?: 'AutomationItem'; id: string; name?: string | null } | null> | null
  }> | null
}

export type CountPostalFulfillmentsQueryVariables = Exact<{
  filter?: InputMaybe<PostalFulfillmentFilterInput>
}>

export type CountPostalFulfillmentsQuery = { __typename?: 'Query'; countPostalFulfillments: number }

export type DownloadContactDataQueryVariables = Exact<{
  contactId: Scalars['ObjectId']['input']
}>

export type DownloadContactDataQuery = { __typename?: 'Query'; downloadContactData: string }

export type DownloadReportQueryVariables = Exact<{
  reportOutputInstanceId: Scalars['ObjectId']['input']
}>

export type DownloadReportQuery = { __typename?: 'Query'; downloadReport?: string | null }

export type EventLimitCheckQueryVariables = Exact<{
  eventDate: Scalars['Instant']['input']
}>

export type EventLimitCheckQuery = {
  __typename?: 'Query'
  eventLimitCheck: {
    __typename?: 'EventLimitResult'
    eventAllowed: boolean
    eventLimit: number
    eventLimitTimeFrame: Granularity
    eventLimitUsed: number
  }
}

export type EventLimitDetailsQueryVariables = Exact<{ [key: string]: never }>

export type EventLimitDetailsQuery = {
  __typename?: 'Query'
  eventLimitDetails?: Array<{ __typename?: 'CountAggregationResult'; date: any; count: number }> | null
}

export type FetchTransactionPdfQueryVariables = Exact<{
  billingAccountId: Scalars['ObjectId']['input']
  entityId: Scalars['ObjectId']['input']
  billingTransactionType: BillingTransactionType
}>

export type FetchTransactionPdfQuery = {
  __typename?: 'Query'
  fetchTransactionPdf: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }
}

export type GetAccountQueryVariables = Exact<{ [key: string]: never }>

export type GetAccountQuery = {
  __typename?: 'Query'
  getAccount: {
    __typename?: 'Account'
    id: string
    name: string
    domains?: Array<string> | null
    partnerIds?: Array<string> | null
    plan: string
    seats?: number | null
    brandingLogoEnabled?: boolean | null
    seatsAssigned?: number | null
    displayName?: string | null
    primaryLogoUrl?: string | null
    status: Status
    subscriptionBillingAccountId?: string | null
    subscriptionStartDate?: any | null
    subscriptionEndDate?: any | null
    billingTermStartDate?: any | null
    billingTermEndDate?: any | null
    trialSubscription?: boolean | null
    subscriptions?: Array<string> | null
    postalDeliveryEmailSetting?: PostalDeliveryEmailSetting | null
    postalDeliveryExpireAction?: PostalDeliveryExpireAction | null
    postalDeliveryExpirationDays?: number | null
    giftEmailTemplateId?: string | null
    emailTemplateId?: string | null
    giftEmailFont?: string | null
    giftEmailPrimaryColor?: string | null
    giftEmailSecondaryColor?: string | null
    giftEmailTertiaryColor?: string | null
    addressRefreshEnabled?: boolean | null
    addressRefreshDays?: number | null
    addressRefreshExpireAction?: PostalDeliveryExpireAction | null
    addressRefreshExpirationDays?: number | null
    sessionTimeoutMinutes?: number | null
    forceAuthenticationMethod?: string | null
    userCanViewMarketplace?: boolean | null
    userCanViewEvents?: boolean | null
    eventRequestNotificationEmails?: Array<string> | null
    marketplaceRequestNotificationEmails?: Array<string> | null
    supportEnabled?: any | null
    sfdcPackageVersion?: string | null
    address?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
    settings?: {
      __typename?: 'Settings'
      billingAccountId?: string | null
      billingAccountIds?: Array<{
        __typename?: 'BillingAccountMap'
        currency: Currency
        billingAccountId: string
      }> | null
      budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    } | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    sendSettings?: {
      __typename?: 'AccountSendSettings'
      bulkSendEnabled?: boolean | null
      disclaimerInfo?: { __typename?: 'DisclaimerInfo'; enabled?: boolean | null; disclaimer?: string | null } | null
    } | null
    notificationSettings?: {
      __typename?: 'AccountNotificationSettings'
      balanceLowNotificationThreshold?: number | null
      budgetLowNotificationThreshold?: number | null
    } | null
    accountContactSettings?: { __typename?: 'AccountContactSettings'; redactAddress?: boolean | null } | null
    oktaIntegration?: { __typename?: 'OktaIntegration'; clientId?: string | null; baseUrl?: string | null } | null
    oneLoginIntegration?: {
      __typename?: 'OneLoginIntegration'
      clientId?: string | null
      baseUrl?: string | null
    } | null
    linkApprovalSettings?: {
      __typename?: 'LinkApprovalSettings'
      autoAction: AutoAction
      autoActionDaysAfter: number
    } | null
    fiscalQuarterSetup?: {
      __typename?: 'FiscalQuarterSetup'
      method: QuarterCalculationMethod
      configuration?: string | null
    } | null
    brandingInstance?: { __typename?: 'BrandingInstance'; whiteLogoUrl?: string | null } | null
  }
}

export type GetAccountManagerQueryVariables = Exact<{ [key: string]: never }>

export type GetAccountManagerQuery = {
  __typename?: 'Query'
  getAccountManager: {
    __typename?: 'CorpCrmUser'
    firstName: string
    lastName: string
    emailAddress: string
    calendlyLink: string
  }
}

export type GetAdditionalEmailReminderOptionsQueryVariables = Exact<{
  sendDate?: InputMaybe<Scalars['Instant']['input']>
}>

export type GetAdditionalEmailReminderOptionsQuery = {
  __typename?: 'Query'
  getAdditionalEmailReminderOptions?: Array<{
    __typename?: 'DropdownOptions'
    value: any
    text?: string | null
    metaData?: any | null
  }> | null
}

export type GetApprovedPostalQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetApprovedPostalQuery = {
  __typename?: 'Query'
  getApprovedPostal: {
    __typename?: 'ApprovedPostal'
    id: string
    userId?: string | null
    ownerId?: string | null
    teamIds?: Array<string> | null
    name: string
    displayName?: string | null
    description: string
    brandName?: string | null
    status: Status
    category: string
    type?: string | null
    subCategory?: Array<string> | null
    useCases?: Array<string> | null
    marketplaceProductId: string
    variantOrderFlexibility?: boolean | null
    collection?: boolean | null
    currency?: Currency | null
    customizable?: boolean | null
    geographicMarkets?: Array<string> | null
    imageUrls?: Array<{ __typename?: 'ImageReference'; url: string; description?: string | null }> | null
    variants?: Array<{
      __typename?: 'ApprovedProductVariant'
      id: string
      marketplaceProductId?: string | null
      approvedPostalId?: string | null
      productName?: string | null
      variantName: string
      displayName?: string | null
      description?: string | null
      status: Status
      price: number
      displayPrice: number
      flatFees?: number | null
      displaySubscriberPrice: number
      shippingPriceEstimateMin?: number | null
      shippingPriceEstimateMax?: number | null
      giftMessageSupported?: boolean | null
      physicalMessageSupported?: boolean | null
      containsPerishables?: boolean | null
      imageUrls?: Array<{ __typename?: 'ImageReference'; url: string; description?: string | null }> | null
    }> | null
    designTemplate?: {
      __typename?: 'DesignTemplate'
      dpi: number
      front?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      back?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
    } | null
    event?: {
      __typename?: 'MagicEventInstance'
      requestedByName: string
      requestedByEmail: string
      requestedByPhone: string
      requestedByMessage: string
      requestedAttendeeCount: number
      spotsRemaining?: number | null
      calendarRequestedDate?: any | null
      requestedDates?: Array<any> | null
      eventDateTime?: any | null
      eventLengthMinutes?: number | null
      status: MagicEventStatus
      lastRegistrationDateTime?: any | null
      meetingLink?: string | null
      sendEmailConfirmation?: boolean | null
      sendReminderDayBefore?: boolean | null
      sendReminderDayOf?: boolean | null
      sendCancelledAlert?: boolean | null
      sendInviteExpiringAlert?: boolean | null
      sendMeetingLinkChanged?: boolean | null
      sendDateTimeChanged?: boolean | null
      sendShippedAlert?: boolean | null
      sendDeliveredAlert?: boolean | null
      confirmationCustomization?: {
        __typename?: 'EventEmailCustomization'
        subjectLine?: string | null
        body?: string | null
      } | null
      reminderTomorrowCustomization?: {
        __typename?: 'EventEmailCustomization'
        subjectLine?: string | null
        body?: string | null
      } | null
      reminderTodayCustomization?: {
        __typename?: 'EventEmailCustomization'
        subjectLine?: string | null
        body?: string | null
      } | null
      cancellationCustomization?: {
        __typename?: 'EventEmailCustomization'
        subjectLine?: string | null
        body?: string | null
      } | null
      meetingLinkChangedCustomization?: {
        __typename?: 'EventEmailCustomization'
        subjectLine?: string | null
        body?: string | null
      } | null
      dateTimeChangedCustomization?: {
        __typename?: 'EventEmailCustomization'
        subjectLine?: string | null
        body?: string | null
      } | null
    } | null
    eventDetails?: {
      __typename?: 'MagicEvent'
      eventLengthMinutes: number
      minimumAttendees?: number | null
      maximumAttendees?: number | null
      requirements?: Array<Requirements> | null
      talentEmailAddress: string
      talentName: string
      talentPhoneNumber: string
      talentCompany?: string | null
    } | null
    metrics?: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    itemCustomizationList?: Array<{
      __typename?: 'ItemCustomization'
      id: string
      displayName?: string | null
      type?: ItemCustomizationType | null
      customizableBySender?: boolean | null
      customizableByRecipient?: boolean | null
      required?: boolean | null
      value?: any | null
      characterLimit?: number | null
      widthRecommendation?: number | null
      heightRecommendation?: number | null
      fileTypesAccepted?: Array<string> | null
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    eventFeeSettings?: {
      __typename?: 'EventFeeSettings'
      flatFee?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    } | null
  }
}

export type GetApprovedPostalBrandsCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetApprovedPostalBrandsCategoriesQuery = {
  __typename?: 'Query'
  getApprovedPostalBrandsCategories: {
    __typename?: 'BrandsCategories'
    currencies?: Array<{ __typename?: 'MarketplaceFilterOption'; name: string; count: number }> | null
    shipTo?: Array<{ __typename?: 'MarketplaceFilterOption'; name: string; count: number }> | null
    brands?: Array<{ __typename?: 'MarketplaceFilterOption'; name: string; count: number }> | null
    categories?: Array<{
      __typename?: 'MarketplaceFilter'
      name: string
      options?: Array<{ __typename?: 'MarketplaceFilterOption'; name: string; count: number }> | null
    }> | null
    useCases?: Array<{ __typename?: 'MarketplaceFilterOption'; name: string; count: number }> | null
  }
}

export type GetApprovedPostalFiltersQueryVariables = Exact<{
  category?: InputMaybe<Scalars['String']['input']>
  categories?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  statuses?: InputMaybe<Array<Status> | Status>
}>

export type GetApprovedPostalFiltersQuery = {
  __typename?: 'Query'
  getApprovedPostalFilters?: Array<{
    __typename?: 'MarketplaceFilter'
    name: string
    options?: Array<{ __typename?: 'MarketplaceFilterOption'; name: string; count: number }> | null
  }> | null
}

export type GetBackgroundTaskByIdQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetBackgroundTaskByIdQuery = {
  __typename?: 'Query'
  getBackgroundTaskById?: {
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  } | null
}

export type GetBalanceRemainingQueryVariables = Exact<{ [key: string]: never }>

export type GetBalanceRemainingQuery = {
  __typename?: 'Query'
  getBalanceRemaining?: Array<{
    __typename?: 'BillingAccountBalance'
    billingAccountId: string
    currency: Currency
    amount: number
  }> | null
}

export type GetBillingAccountQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetBillingAccountQuery = {
  __typename?: 'Query'
  getBillingAccount: {
    __typename?: 'BillingAccount'
    id: string
    name: string
    status: Status
    type: BillingAccountType
    linkedAccountIdentifier?: string | null
    balance?: number | null
    prepaymentProcessing: boolean
    prepaymentBackgroundTaskId?: string | null
    currentPrepaymentAmount?: number | null
    autoReload?: boolean | null
    autoReloadAmount?: number | null
    autoReloadLowLimit?: number | null
    currency?: Currency | null
    incomingInvoiceAmount?: number | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    defaultPaymentMethod?: {
      __typename?: 'DefaultPaymentMethod'
      id?: string | null
      partnerId?: string | null
      paymentPartnerType?: PaymentPartnerType | null
      type?: PaymentMethodType | null
      autoReloadOk: boolean
      autoReloadFailures: number
      lastFailedAutoReloadAttempt?: any | null
      lastUnlocked?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    } | null
    incomingInvoiceActionRecord?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    billingContactInfo?: {
      __typename?: 'BillingContactInfo'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      address?: {
        __typename?: 'BillingContactAddress'
        line1?: string | null
        line2?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
      } | null
    } | null
    paymentPartners?: Array<{
      __typename?: 'PaymentPartnerInfo'
      type: PaymentPartnerType
      customerId: string
      lastSyncedAt?: any | null
      paymentMethods?: Array<{
        __typename?: 'PartnerPaymentMethod'
        partnerId: string
        billingDetails?: {
          __typename?: 'BillingPaymentMethodInfo'
          name?: string | null
          email?: string | null
          phone?: string | null
          address?: {
            __typename?: 'BillingContactAddress'
            line1?: string | null
            line2?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
          } | null
        } | null
        card?: {
          __typename?: 'CardPaymentMethod'
          expirationMonth: number
          expirationYear: number
          last4: string
          brand?: string | null
        } | null
      }> | null
    }> | null
    initialNetsuiteBalance?: { __typename?: 'InitialBalanceNetSuiteSyncInfo'; initializedAt?: any | null } | null
  }
}

export type GetBlockFreeBusyQueryVariables = Exact<{
  marketplaceProductId: Scalars['ObjectId']['input']
  startDate: Scalars['Instant']['input']
  timeZone: Scalars['String']['input']
}>

export type GetBlockFreeBusyQuery = {
  __typename?: 'Query'
  getBlockFreeBusy?: Array<{ __typename?: 'BlockFreeBusy'; type: FreeBusy; start: any; end: any }> | null
}

export type GetBlocklistEntryQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetBlocklistEntryQuery = {
  __typename?: 'Query'
  getBlocklistEntry: {
    __typename?: 'BlocklistEntry'
    id: string
    accountId: string
    dataObjectType: DataObjectType
    fieldName: string
    entry: string
    filterType: BlocklistFilterType
  }
}

export type GetBrandsCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetBrandsCategoriesQuery = {
  __typename?: 'Query'
  getBrandsCategories: {
    __typename?: 'BrandsCategories'
    currencies?: Array<{ __typename?: 'MarketplaceFilterOption'; name: string; count: number }> | null
    shipTo?: Array<{ __typename?: 'MarketplaceFilterOption'; name: string; count: number }> | null
    brands?: Array<{ __typename?: 'MarketplaceFilterOption'; name: string; count: number }> | null
    categories?: Array<{
      __typename?: 'MarketplaceFilter'
      name: string
      options?: Array<{ __typename?: 'MarketplaceFilterOption'; name: string; count: number }> | null
    }> | null
    useCases?: Array<{ __typename?: 'MarketplaceFilterOption'; name: string; count: number }> | null
  }
}

export type GetBudgetRemainingQueryVariables = Exact<{
  accountId: Scalars['ObjectId']['input']
  userId?: InputMaybe<Scalars['ObjectId']['input']>
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}>

export type GetBudgetRemainingQuery = {
  __typename?: 'Query'
  getBudgetRemaining: {
    __typename?: 'BudgetRemaining'
    budgetRemaining?: number | null
    totalSpend?: number | null
    totalAdjustments?: number | null
    applicableBudget?: {
      __typename?: 'ApplicableBudget'
      accountId?: string | null
      teamId?: string | null
      billingAccountId?: string | null
      budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    } | null
  }
}

export type GetBudgetRemainingSummaryQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
}>

export type GetBudgetRemainingSummaryQuery = {
  __typename?: 'Query'
  getBudgetRemainingSummary: {
    __typename?: 'UsersBudgetSummary'
    accountId: string
    teamId?: string | null
    totalSpend: number
    totalAdjustments: number
    budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    summaries: Array<{
      __typename?: 'UserBudgetSummary'
      userId: string
      firstName?: string | null
      lastName?: string | null
      active: boolean
      budgetRemaining: number
      totalSpend: number
      totalAdjustments: number
    } | null>
  }
}

export type GetCachedCurrencyConfigsQueryVariables = Exact<{
  filter?: InputMaybe<CachedCurrencyConfigFilterInput>
}>

export type GetCachedCurrencyConfigsQuery = {
  __typename?: 'Query'
  getCachedCurrencyConfigs?: Array<{
    __typename?: 'CachedCurrencyConfig'
    currency: Currency
    billingAccountEnabled: boolean
    productEnabled: boolean
  }> | null
}

export type GetCampaignQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetCampaignQuery = {
  __typename?: 'Query'
  getCampaign: {
    __typename?: 'Campaign'
    id: string
    userId: string
    name: string
    campaignType?: CampaignType | null
    status: CampaignStatus
    scheduleDate?: any | null
    cancelDate?: any | null
    errorDate?: any | null
    completeDate?: any | null
    giftMessage?: string | null
    backgroundTaskId?: string | null
    playbookId?: string | null
    approvedPostalId?: string | null
    variantId?: string | null
    totalContacts?: number | null
    totalContactsAttempted?: number | null
    deliveryEmail?: boolean | null
    deliveryEmailTemplateId?: string | null
    physicalMessage?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    emailSubjectLine?: string | null
    sendAsContactOwner?: boolean | null
    sendAsUser?: string | null
    teamId?: string | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    designTemplate?: {
      __typename?: 'DesignTemplate'
      dpi: number
      front?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      back?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
    } | null
    metrics?: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    } | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationInputs?: Array<{
      __typename?: 'ItemCustomizationInput'
      id: string
      value: any
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
  }
}

export type GetConnectionsQueryVariables = Exact<{ [key: string]: never }>

export type GetConnectionsQuery = {
  __typename?: 'Query'
  getConnections?: Array<{
    __typename?: 'Connection'
    id: string
    name: string
    provider: string
    authorizedAt?: string | null
    authorizationStatus?: string | null
    authorizationError?: string | null
  }> | null
}

export type GetContactQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetContactQuery = {
  __typename?: 'Query'
  getContact: {
    __typename?: 'Contact'
    id: string
    firstName: string
    lastName?: string | null
    title?: string | null
    emailAddress?: string | null
    ownerId?: string | null
    type: ContactType
    companyName?: string | null
    lastSend?: any | null
    lastActivity?: any | null
    tags?: Array<string> | null
    hasActivePlaybooks: boolean
    phones?: Array<{
      __typename?: 'PhoneMap'
      phoneNumber: string
      type: PhoneType
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    } | null> | null
    addresses?: Array<{
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null> | null
    ownerLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    metrics: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    }
    lists?: Array<{ __typename?: 'ContactListMap'; name: string; userId: string; listId: string }> | null
    campaigns?: Array<{
      __typename?: 'CampaignContact'
      campaignId: string
      campaignName?: string | null
      processed?: any | null
      postalFulfillmentId?: string | null
      status?: FulfillmentStatus | null
    }> | null
    playbooks?: Array<{
      __typename?: 'PlaybookMap'
      playbookDefinitionId: string
      playbookInstanceId: string
      name: string
      playbookStatus: PlaybookStatus
      nextStepToExecute?: number | null
      created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    }> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    deleted?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    mappings?: Array<{
      __typename?: 'ExternalMapping'
      system: string
      id: string
      owner?: string | null
      profileUrl?: string | null
      accountId?: string | null
      companyId?: string | null
      created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    }> | null
  }
}

export type GetContactByIntegrationQueryVariables = Exact<{
  systemName: Scalars['String']['input']
  id: Scalars['String']['input']
  contactType?: InputMaybe<ContactType>
}>

export type GetContactByIntegrationQuery = {
  __typename?: 'Query'
  getContactByIntegration: {
    __typename?: 'Contact'
    id: string
    firstName: string
    lastName?: string | null
    title?: string | null
    emailAddress?: string | null
    ownerId?: string | null
    type: ContactType
    companyName?: string | null
    lastSend?: any | null
    lastActivity?: any | null
    tags?: Array<string> | null
    hasActivePlaybooks: boolean
    phones?: Array<{
      __typename?: 'PhoneMap'
      phoneNumber: string
      type: PhoneType
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    } | null> | null
    addresses?: Array<{
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null> | null
    ownerLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    metrics: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    }
    lists?: Array<{ __typename?: 'ContactListMap'; name: string; userId: string; listId: string }> | null
    campaigns?: Array<{
      __typename?: 'CampaignContact'
      campaignId: string
      campaignName?: string | null
      processed?: any | null
      postalFulfillmentId?: string | null
      status?: FulfillmentStatus | null
    }> | null
    playbooks?: Array<{
      __typename?: 'PlaybookMap'
      playbookDefinitionId: string
      playbookInstanceId: string
      name: string
      playbookStatus: PlaybookStatus
      nextStepToExecute?: number | null
      created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    }> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    deleted?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    mappings?: Array<{
      __typename?: 'ExternalMapping'
      system: string
      id: string
      owner?: string | null
      profileUrl?: string | null
      accountId?: string | null
      companyId?: string | null
      created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    }> | null
  }
}

export type GetCrmAccountByIdentifierQueryVariables = Exact<{
  crmIdentifier: Scalars['String']['input']
}>

export type GetCrmAccountByIdentifierQuery = {
  __typename?: 'Query'
  getCrmAccountByIdentifier: {
    __typename?: 'CrmAccount'
    id: string
    accountId: string
    name?: string | null
    domain?: string | null
    crmCreated?: any | null
    crmIdentifier: string
    ownerId?: string | null
    crmOwner?: string | null
    parentId?: string | null
    closedWonDollars?: number | null
    system?: string | null
    addresses?: Array<{
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null> | null
    metrics?: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    } | null
  }
}

export type GetCrmPostalCustomFieldsQueryVariables = Exact<{
  system: Scalars['String']['input']
  type: CustomFieldDataListType
}>

export type GetCrmPostalCustomFieldsQuery = {
  __typename?: 'Query'
  getCrmPostalCustomFields: {
    __typename?: 'CrmPostalCustomFieldResponse'
    items?: Array<{
      __typename?: 'CrmPostalCustomFieldResponseItem'
      crmField: string
      postalField: CrmAccountPushFields
      present: boolean
    }> | null
  }
}

export type GetCrmReportQueryVariables = Exact<{
  system: Scalars['String']['input']
  reportType: CrmReportType
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  refresh?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetCrmReportQuery = {
  __typename?: 'Query'
  getCrmReport: {
    __typename?: 'CrmReport'
    reportType?: CrmReportType | null
    created?: any | null
    source?: string | null
    properties?: any | null
    queryResults?: Array<{
      __typename?: 'QueryResults'
      label?: string | null
      result?: {
        __typename?: 'ObjectList'
        records?: Array<any | null> | null
        done?: boolean | null
        totalSize?: number | null
      } | null
    } | null> | null
  }
}

export type GetDailyFreeBusyQueryVariables = Exact<{
  marketplaceProductId: Scalars['ObjectId']['input']
  startDate: Scalars['Instant']['input']
  endDate: Scalars['Instant']['input']
  timeZone: Scalars['String']['input']
}>

export type GetDailyFreeBusyQuery = {
  __typename?: 'Query'
  getDailyFreeBusy?: Array<{
    __typename?: 'DayFreeBusy'
    date: any
    dateString: string
    availableBlocks: number
  }> | null
}

export type GetDataListQueryVariables = Exact<{
  system: Scalars['String']['input']
  type: DataListType
  searchString?: InputMaybe<Scalars['String']['input']>
}>

export type GetDataListQuery = {
  __typename?: 'Query'
  getDataList: {
    __typename?: 'CrmDataList'
    items?: Array<{
      __typename?: 'CrmDataListItem'
      id: string
      name: string
      values?: Array<{
        __typename?: 'CrmDataListItem'
        id: string
        name: string
        values?: Array<{
          __typename?: 'CrmDataListItem'
          id: string
          name: string
          values?: Array<{ __typename?: 'CrmDataListItem'; id: string; name: string }> | null
        }> | null
      }> | null
    }> | null
  }
}

export type GetExternalCampaignMappingQueryVariables = Exact<{
  system: Scalars['String']['input']
  campaignId: Scalars['String']['input']
}>

export type GetExternalCampaignMappingQuery = {
  __typename?: 'Query'
  getExternalCampaignMapping: {
    __typename?: 'ExtCampaignMapping'
    id: string
    system: string
    campaignId: string
    setCampaignCost?: boolean | null
    opened?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    accepted?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    queued?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    shipped?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    delivered?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    bounced?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    meetingBooked?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    expired?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    failed?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    declined?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
    donated?: { __typename?: 'EventMapping'; status: string; active: boolean } | null
  }
}

export type GetFavoriteItemQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetFavoriteItemQuery = {
  __typename?: 'Query'
  getFavoriteItem: {
    __typename?: 'FavoriteItem'
    id: string
    userId: string
    teamId?: string | null
    approvedPostalId: string
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
  }
}

export type GetFundsQueryVariables = Exact<{
  billingAccountId: Scalars['ObjectId']['input']
}>

export type GetFundsQuery = { __typename?: 'Query'; getFunds?: number | null }

export type GetIntegrationConnectionQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetIntegrationConnectionQuery = {
  __typename?: 'Query'
  getIntegrationConnection: {
    __typename?: 'IntegrationConnection'
    accountId?: string | null
    system?: string | null
    authenticationUserId?: string | null
    authenticationFulfillmentPartnerId?: string | null
  }
}

export type GetIntegrationTriggerQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetIntegrationTriggerQuery = {
  __typename?: 'Query'
  getIntegrationTrigger: {
    __typename?: 'IntegrationTrigger'
    id: string
    userId: string
    teamId?: string | null
    name: string
    systemName: string
    status: Status
    action: TriggerAction
    playbookDefinitionId?: string | null
    approvedPostalId?: string | null
    approvedPostalVariantId?: string | null
    giftMessage?: string | null
    deliveryEmail?: boolean | null
    hitCount: number
    deliveryEmailTemplateId?: string | null
    physicalMessage?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    emailSubjectLine?: string | null
    sendAsContactOwner?: boolean | null
    sendAsUser?: string | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    filters?: Array<{ __typename?: 'TriggerFilter'; field: string; filter: TriggerFilterTypes; value: string }> | null
    config?: {
      __typename?: 'TriggerConfiguration'
      sequenceId?: string | null
      sequenceStepId?: string | null
      instanceId?: string | null
      recipeId?: string | null
      recipeName?: string | null
    } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationInputs?: Array<{
      __typename?: 'ItemCustomizationInput'
      id: string
      value: any
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
  }
}

export type GetLedgerQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetLedgerQuery = {
  __typename?: 'Query'
  getLedger: {
    __typename?: 'Ledger'
    id: string
    userId?: string | null
    accountId: string
    billingAccountId: string
    type: LedgerRecordType
    amount: number
    previousBalance: number
    currentBalance: number
    relatedId?: string | null
    relatedType?: LedgerRelatedType | null
    memo?: string | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
  }
}

export type GetLocationDataQueryVariables = Exact<{ [key: string]: never }>

export type GetLocationDataQuery = {
  __typename?: 'Query'
  getLocationData: {
    __typename?: 'LocationData'
    countryName?: string | null
    countryCode?: string | null
    countryCode3?: string | null
    regionName?: string | null
    regionCode?: string | null
    timeZone?: { __typename?: 'IpLookupTimeZone'; id?: string | null; code?: string | null } | null
  }
}

export type GetMagicLinkQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetMagicLinkQuery = {
  __typename?: 'Query'
  getMagicLink: {
    __typename?: 'MagicLink'
    id: string
    teamId?: string | null
    name: string
    linkUrl: string
    status: MagicLinkStatus
    expirationDate?: any | null
    maxExecutions?: number | null
    message: string
    approvedPostalId: string
    variantId: string
    distributionChannel?: string | null
    lastView?: any | null
    lastExecution?: any | null
    physicalMessage?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    sendAsContactOwner?: boolean | null
    sendAsUser?: string | null
    requiresApproval?: boolean | null
    requiresUuid?: boolean | null
    metrics: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    }
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationInputs?: Array<{
      __typename?: 'ItemCustomizationInput'
      id: string
      value: any
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
  }
}

export type GetMarketplaceFiltersQueryVariables = Exact<{
  category?: InputMaybe<Scalars['String']['input']>
  categories?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
}>

export type GetMarketplaceFiltersQuery = {
  __typename?: 'Query'
  getMarketplaceFilters?: Array<{
    __typename?: 'MarketplaceFilter'
    name: string
    options?: Array<{ __typename?: 'MarketplaceFilterOption'; name: string; count: number }> | null
  }> | null
}

export type GetMarketplaceProductQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetMarketplaceProductQuery = {
  __typename?: 'Query'
  getMarketplaceProduct: {
    __typename?: 'MarketplaceProduct'
    id: string
    name: string
    description: string
    brandName?: string | null
    category: string
    type?: string | null
    subCategory?: Array<string> | null
    useCases?: Array<string> | null
    allowedPlans?: Array<string> | null
    variantOrderFlexibility?: boolean | null
    starterTemplate?: boolean | null
    itemPreview?: boolean | null
    status: Status
    phoneNumberRequired?: boolean | null
    eventAvailabilityDates?: Array<string> | null
    currency?: Currency | null
    customizable?: boolean | null
    geographicMarkets?: Array<string> | null
    estimatedShippingTime?: EstimatedShippingTime | null
    variants?: Array<{
      __typename?: 'ProductVariant'
      id: string
      variantName: string
      description?: string | null
      upcCode?: string | null
      manufacturerPartId?: string | null
      status: Status
      size?: string | null
      giftMessageSupported?: boolean | null
      physicalMessageSupported?: boolean | null
      attributes?: any | null
      minimumTransactions?: number | null
      flatFees?: number | null
      displayPrice?: number | null
      containsPerishables?: boolean | null
      imageUrls?: Array<{ __typename?: 'ImageReference'; url: string; description?: string | null }> | null
      fulfillmentPartnerList?: Array<{
        __typename?: 'FulfillmentMap'
        fulfillmentType: FulfillmentType
        fulfillmentPartnerId: string
        fulfillmentPartnerName: string
        itemCode: string
        price: number
        displayPrice: number
        displaySubscriberPrice: number
        supplierPartId?: string | null
        supplierPartAuxiliaryId?: string | null
        priceStructure?: PriceStructure | null
        bulkSendAvailable?: boolean | null
        maximumOrderableQuantity?: number | null
        shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null
        inventory?: { __typename?: 'Inventory'; ordered?: number | null; available?: number | null } | null
      }> | null
    }> | null
    imageUrls?: Array<{ __typename?: 'ImageReference'; url: string; description?: string | null }> | null
    designTemplate?: {
      __typename?: 'DesignTemplate'
      dpi: number
      front?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      back?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
    } | null
    eventDetails?: {
      __typename?: 'MagicEvent'
      eventLengthMinutes: number
      minimumAttendees?: number | null
      maximumAttendees?: number | null
      requirements?: Array<Requirements> | null
      talentEmailAddress: string
      talentName: string
      talentPhoneNumber: string
      talentCompany?: string | null
    } | null
    itemCustomizationList?: Array<{
      __typename?: 'ItemCustomization'
      id: string
      displayName?: string | null
      type?: ItemCustomizationType | null
      customizableBySender?: boolean | null
      customizableByRecipient?: boolean | null
      required?: boolean | null
      value?: any | null
      characterLimit?: number | null
      widthRecommendation?: number | null
      heightRecommendation?: number | null
      fileTypesAccepted?: Array<string> | null
      variantId?: string | null
    }> | null
  }
}

export type GetMaxPhysicalNoteCharactersQueryVariables = Exact<{
  approvedPostalId: Scalars['ObjectId']['input']
  variantId?: InputMaybe<Scalars['ObjectId']['input']>
}>

export type GetMaxPhysicalNoteCharactersQuery = { __typename?: 'Query'; getMaxPhysicalNoteCharacters: number }

export type GetOrderRulesQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetOrderRulesQuery = {
  __typename?: 'Query'
  getOrderRules: {
    __typename?: 'OrderRules'
    id: string
    name: string
    reasonText: string
    defaultAction: OrderAction
    rules?: Array<{
      __typename?: 'OrderRule'
      action: OrderAction
      filters?: Array<{ __typename?: 'OrderRuleFilter'; field: string; filter: string; value: string }> | null
    }> | null
  }
}

export type GetPlaybookDefinitionQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetPlaybookDefinitionQuery = {
  __typename?: 'Query'
  getPlaybookDefinition: {
    __typename?: 'PlaybookDefinition'
    id: string
    teamId?: string | null
    status?: Status | null
    name?: string | null
    currency?: Currency | null
    steps: Array<{
      __typename?: 'PlaybookStepDefinition'
      type: PlaybookStepType
      delay: number
      delayUnit: ChronoUnit
      approvedPostalId: string
      variantId: string
      giftMessage?: string | null
      deliveryEmail?: boolean | null
      displayPrice?: number | null
      displaySubscriberPrice?: number | null
      productName?: string | null
      deliveryEmailTemplateId?: string | null
      physicalMessage?: string | null
      meetingRequestSetting?: MeetingRequestSetting | null
      emailSubjectLine?: string | null
      sendAsContactOwner?: boolean | null
      sendAsUser?: string | null
      spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      itemCustomizationInputs?: Array<{
        __typename?: 'ItemCustomizationInput'
        id: string
        value: any
        variantId?: string | null
      }> | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      landingPageCustomization?: {
        __typename?: 'LandingPageCustomization'
        headerText?: string | null
        body?: string | null
        includeHeadshot?: boolean | null
        includeSenderName?: boolean | null
      } | null
      recipientEmailSettings?: {
        __typename?: 'RecipientEmailSettings'
        shippedEmailsOn?: boolean | null
        deliveredEmailsOn?: boolean | null
      } | null
      giftEmailReminderSettings?: {
        __typename?: 'GiftEmailReminderSettings'
        expirationSoonReminders?: Array<number> | null
      } | null
    } | null>
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
  }
}

export type GetPlaybookInstanceQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetPlaybookInstanceQuery = {
  __typename?: 'Query'
  getPlaybookInstance: {
    __typename?: 'PlaybookInstance'
    id: string
    name?: string | null
    playbookDefinitionId: string
    playbookStatus?: PlaybookStatus | null
    contactId: string
    contactName?: string | null
    triggerId?: string | null
    errMessage?: string | null
    nextStepToExecute?: number | null
    nextExecutionDate?: any | null
    teamId?: string | null
    steps: Array<{
      __typename?: 'PlaybookStepInstance'
      type: PlaybookStepType
      playbookStatus?: PlaybookStatus | null
      errMessage?: string | null
      delay: number
      delayUnit: ChronoUnit
      approvedPostalId: string
      variantId: string
      giftMessage?: string | null
      deliveryEmail?: boolean | null
      fulfillmentId?: string | null
      fulfillmentStatus?: FulfillmentStatus | null
      physicalMessage?: string | null
      meetingRequestSetting?: MeetingRequestSetting | null
      sendAsContactOwner?: boolean | null
      sendAsUser?: string | null
      spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      itemCustomizationInputs?: Array<{
        __typename?: 'ItemCustomizationInput'
        id: string
        value: any
        variantId?: string | null
      }> | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      landingPageCustomization?: {
        __typename?: 'LandingPageCustomization'
        headerText?: string | null
        body?: string | null
        includeHeadshot?: boolean | null
        includeSenderName?: boolean | null
      } | null
      recipientEmailSettings?: {
        __typename?: 'RecipientEmailSettings'
        shippedEmailsOn?: boolean | null
        deliveredEmailsOn?: boolean | null
      } | null
      giftEmailReminderSettings?: {
        __typename?: 'GiftEmailReminderSettings'
        expirationSoonReminders?: Array<number> | null
      } | null
    } | null>
    metrics?: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
  }
}

export type GetPostalFulfillmentQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetPostalFulfillmentQuery = {
  __typename?: 'Query'
  getPostalFulfillment: {
    __typename?: 'PostalFulfillment'
    id: string
    accountId: string
    userId: string
    teamId?: string | null
    contactId?: string | null
    shipToName?: string | null
    shipToTitle?: string | null
    shipToCompanyName?: string | null
    campaignName?: string | null
    shipToEmail?: string | null
    deliveryEmail?: boolean | null
    campaignId?: string | null
    playbookId?: string | null
    playbookDefinitionId?: string | null
    playbookName?: string | null
    triggerId?: string | null
    magicLinkId?: string | null
    magicLinkName?: string | null
    itemName: string
    emailSubjectLine?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    previewImage?: string | null
    giftMessage?: string | null
    physicalMessage?: string | null
    thankYouMessage?: string | null
    marketplaceProductId?: string | null
    approvedPostalId?: string | null
    variantId?: string | null
    fulfillmentPartnerId?: string | null
    fulfillmentPartnerOrderId?: string | null
    status: FulfillmentStatus
    stage?: string | null
    targetDeliveryDate?: any | null
    deliveryDate?: any | null
    deliveryPartner?: string | null
    trackingNumber?: string | null
    trackingUrl?: string | null
    attributes?: any | null
    collection?: boolean | null
    collectionName?: string | null
    sendAsUserId?: string | null
    failureReason?: FailureReason | null
    type?: ObjectType | null
    sendType?: SendType | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    shipTo?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
    cost?: {
      __typename?: 'OrderCost'
      subtotal?: number | null
      shipping?: number | null
      tax?: number | null
      thirdPartyFee?: number | null
      postalFee?: number | null
      taxEstimated?: boolean | null
      budgetExpense?: number | null
      total?: number | null
      currency?: Currency | null
    } | null
    history?: Array<{
      __typename?: 'FulfillmentHistory'
      status: FulfillmentStatus
      notes?: string | null
      currentLocation?: string | null
      created: any
    }> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    sendAs?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationList?: Array<{
      __typename?: 'ItemCustomization'
      id: string
      displayName?: string | null
      type?: ItemCustomizationType | null
      customizableBySender?: boolean | null
      customizableByRecipient?: boolean | null
      required?: boolean | null
      value?: any | null
      characterLimit?: number | null
      widthRecommendation?: number | null
      heightRecommendation?: number | null
      fileTypesAccepted?: Array<string> | null
      variantId?: string | null
    }> | null
    formFieldValueList?: Array<{ __typename?: 'FormFieldPair'; field: string; value: any }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
    approvalRequiredInfo?: {
      __typename?: 'ApprovalRequiredInfo'
      approvalRequiredReason: ApprovalRequiredReason
      autoAction?: AutoAction | null
      autoActionDate?: any | null
    } | null
    fulfillments?: Array<{
      __typename?: 'Fulfillment'
      id?: string | null
      marketplaceProductId?: string | null
      approvedPostalId?: string | null
      variantId?: string | null
      quantity?: number | null
      trackingNumber?: string | null
      trackingCompany?: string | null
      trackingUrl?: string | null
      status?: ItemStatus | null
      targetDeliveryDate?: any | null
      deliveryDate?: any | null
      cost?: {
        __typename?: 'OrderCost'
        subtotal?: number | null
        shipping?: number | null
        tax?: number | null
        thirdPartyFee?: number | null
        postalFee?: number | null
        taxEstimated?: boolean | null
        budgetExpense?: number | null
        total?: number | null
        currency?: Currency | null
      } | null
      actualCost?: {
        __typename?: 'OrderCost'
        subtotal?: number | null
        shipping?: number | null
        tax?: number | null
        thirdPartyFee?: number | null
        postalFee?: number | null
        taxEstimated?: boolean | null
        budgetExpense?: number | null
        total?: number | null
        currency?: Currency | null
      } | null
    }> | null
  }
}

export type GetPostalFulfillmentActionsQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetPostalFulfillmentActionsQuery = {
  __typename?: 'Query'
  getPostalFulfillmentActions?: Array<{
    __typename?: 'PostalFulfillmentAction'
    eventName?: OrderEventNames | null
    name?: string | null
    tooltip?: string | null
    color?: string | null
  } | null> | null
}

export type GetRecipeQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetRecipeQuery = {
  __typename?: 'Query'
  getRecipe: {
    __typename?: 'Recipe'
    id: string
    name: string
    description: string
    running: boolean
    jobSucceededCount: number
    jobFailedCount: number
    lastRunAt?: string | null
    stoppedAt?: string | null
    stopCause?: string | null
  }
}

export type GetRecipesQueryVariables = Exact<{ [key: string]: never }>

export type GetRecipesQuery = {
  __typename?: 'Query'
  getRecipes?: Array<{
    __typename?: 'Recipe'
    id: string
    name: string
    description: string
    running: boolean
    jobSucceededCount: number
    jobFailedCount: number
    lastRunAt?: string | null
    stoppedAt?: string | null
    stopCause?: string | null
  }> | null
}

export type GetRolePermissionsQueryVariables = Exact<{
  role: Scalars['String']['input']
}>

export type GetRolePermissionsQuery = {
  __typename?: 'Query'
  getRolePermissions?: {
    __typename?: 'RolePermission'
    roleName: string
    permissions: Array<string>
    available: Array<{ __typename?: 'PermissionDefinition'; name: string; description: string }>
  } | null
}

export type GetSavedSendQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetSavedSendQuery = {
  __typename?: 'Query'
  getSavedSend: {
    __typename?: 'SavedSend'
    id: string
    savedSendName: string
    userId: string
    teamId?: string | null
    status: SavedSendStatus
    sendType: SavedSendType
    sendFlowStep?: SendFlowStep | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    commonSendProperties: {
      __typename?: 'CommonSendProperties'
      name?: string | null
      approvedPostalId: string
      variantId?: string | null
      physicalMessage?: string | null
      meetingRequestSetting?: MeetingRequestSetting | null
      sendAsContactOwner?: boolean | null
      sendAsUser?: string | null
      spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      itemCustomizationInputs?: Array<{
        __typename?: 'ItemCustomizationInput'
        id: string
        value: any
        variantId?: string | null
      }> | null
      landingPageCustomization?: {
        __typename?: 'LandingPageCustomization'
        headerText?: string | null
        body?: string | null
        includeHeadshot?: boolean | null
        includeSenderName?: boolean | null
      } | null
      recipientEmailSettings?: {
        __typename?: 'RecipientEmailSettings'
        shippedEmailsOn?: boolean | null
        deliveredEmailsOn?: boolean | null
      } | null
      giftEmailReminderSettings?: {
        __typename?: 'GiftEmailReminderSettings'
        expirationSoonReminders?: Array<number> | null
      } | null
    }
    savedSendMagicLinkInfo?: {
      __typename?: 'SavedSendMagicLinkInfo'
      expirationDate?: any | null
      maxExecutions?: number | null
      message?: string | null
      requiresApproval?: boolean | null
      status?: MagicLinkStatus | null
    } | null
    savedSendCampaignInfo?: {
      __typename?: 'SavedSendCampaignInfo'
      deliveryEmail?: boolean | null
      useSameMessage?: boolean | null
      emailSubjectLine?: string | null
      giftMessage?: string | null
      deliveryEmailTemplateId?: string | null
      scheduleDate?: any | null
      contactSearchFilters?: Array<any> | null
      contactCount?: number | null
    } | null
    savedSendBulkSendInfo?: {
      __typename?: 'SavedSendBulkSendInfo'
      quantity?: number | null
      contactId?: string | null
      addressVerified?: boolean | null
      shipToAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
      newContact?: {
        __typename?: 'NewContact'
        firstName?: string | null
        lastName?: string | null
        emailAddress?: string | null
        phones?: Array<{
          __typename?: 'NewPhoneMap'
          phoneNumber?: string | null
          type?: PhoneType | null
        } | null> | null
      } | null
    } | null
  }
}

export type GetSequenceStepsQueryVariables = Exact<{
  system: Scalars['String']['input']
  sequenceId?: InputMaybe<Scalars['String']['input']>
}>

export type GetSequenceStepsQuery = {
  __typename?: 'Query'
  getSequenceSteps: {
    __typename?: 'ExternalSequenceStepResponse'
    steps?: Array<{ __typename?: 'ExternalSequenceStep'; id: string; name: string }> | null
  }
}

export type GetSequencesQueryVariables = Exact<{
  system: Scalars['String']['input']
  name?: InputMaybe<Scalars['String']['input']>
}>

export type GetSequencesQuery = {
  __typename?: 'Query'
  getSequences: {
    __typename?: 'ExternalSequenceResponse'
    sequences?: Array<{ __typename?: 'ExternalSequence'; id: string; name: string; updatedAt?: any | null }> | null
  }
}

export type GetTeamByIdQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetTeamByIdQuery = {
  __typename?: 'Query'
  getTeamById: {
    __typename?: 'Team'
    id: string
    name: string
    status: Status
    accountId: string
    parentTeamId?: string | null
    settings?: {
      __typename?: 'Settings'
      billingAccountId?: string | null
      billingAccountIds?: Array<{
        __typename?: 'BillingAccountMap'
        currency: Currency
        billingAccountId: string
      }> | null
      budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    } | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type GetTransferIntentQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetTransferIntentQuery = {
  __typename?: 'Query'
  getTransferIntent: {
    __typename?: 'TransferIntent'
    id: string
    accountId: string
    userId: string
    status: TransferIntentStatus
    fromBillingAccountId: string
    toBillingAccountId: string
    fromAmount: number
    toAmount: number
    comment: string
    fromCurrency: Currency
    toCurrency: Currency
    toCreditMemoId?: string | null
    toCreditMemoNumber?: string | null
    fromDebitMemoId?: string | null
    fromDebitMemoNumber?: string | null
    fxInfo?: { __typename?: 'ForeignExchangeInfo'; timestamp: any; rate: string; apiProvider: string } | null
    history?: Array<{
      __typename?: 'TransferIntentHistory'
      status: TransferIntentStatus
      notes?: string | null
      created: any
    }> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }
}

export type GetTrialExpiredStatsQueryVariables = Exact<{ [key: string]: never }>

export type GetTrialExpiredStatsQuery = {
  __typename?: 'Query'
  getTrialExpiredStats: {
    __typename?: 'TrialExpiredStats'
    contactsCreated: number
    postalsSent: number
    campaignsCreated: number
  }
}

export type GetUserQueryVariables = Exact<{
  id: Scalars['ObjectId']['input']
}>

export type GetUserQuery = {
  __typename?: 'Query'
  getUser: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    userName: string
    emailAddress: string
    profilePicture?: string | null
    phoneNumber?: string | null
    title?: string | null
    meetingLink?: string | null
    status: Status
    accountLockedUntil?: any | null
    authenticationFailures?: number | null
    handwritingName?: string | null
    auth?: Array<{
      __typename?: 'AuthenticationMethod'
      method: string
      version?: string | null
      expires?: any | null
      updated?: any | null
      created?: any | null
    } | null> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    productAccess?: Array<{
      __typename?: 'ProductAccess'
      id: string
      accountId?: string | null
      teamId?: string | null
      partnerId?: string | null
      product: Product
      roles?: Array<string> | null
      type?: ProductAccessType | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    }> | null
    crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null
    notifications?: {
      __typename?: 'UserNotifications'
      notificationsOn?: boolean | null
      balanceLowNotificationsOn?: boolean | null
      balanceLowChannels?: Array<UserNotificationChannel | null> | null
      lastBalanceLowNotificationSent?: any | null
      budgetLowNotificationsOn?: boolean | null
      budgetLowChannels?: Array<UserNotificationChannel | null> | null
      lastBudgetLowNotificationSent?: any | null
      giftEmailAcceptedNotificationsOn?: boolean | null
      giftEmailAcceptedChannels?: Array<UserNotificationChannel | null> | null
      magicLinkAcceptedNotificationsOn?: boolean | null
      magicLinkAcceptedChannels?: Array<UserNotificationChannel | null> | null
      orderDeliveredNotificationsOn?: boolean | null
      orderDeliveredChannels?: Array<UserNotificationChannel | null> | null
      thankYouNotificationsOn?: boolean | null
      thankYouChannels?: Array<UserNotificationChannel> | null
      landingPageExceptionNotificationOn?: boolean | null
      landingPageExceptionChannels?: Array<UserNotificationChannel> | null
      autoReloadFailuresNotificationsOn?: boolean | null
      autoReloadFailuresChannels?: Array<UserNotificationChannel | null> | null
      orderIssuesNotificationsOn?: boolean | null
      orderIssuesChannels?: Array<UserNotificationChannel | null> | null
    } | null
    emailDigestSettings?: {
      __typename?: 'EmailDigestSettings'
      processingErrors?: boolean | null
      ordersRequiringUserApproval?: boolean | null
      itemOutOfStock?: boolean | null
      itemLowStock?: boolean | null
      marketingUpdates?: boolean | null
      newItemApproved?: boolean | null
      newCollectionApproved?: boolean | null
      newEventApproved?: boolean | null
      itemRemoved?: boolean | null
      frequency?: EmailDigestFrequency | null
    } | null
  }
}

export type GetWorkatoJwtQueryVariables = Exact<{
  test?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetWorkatoJwtQuery = { __typename?: 'Query'; getWorkatoJWT: string }

export type InvitesQueryVariables = Exact<{
  filter?: InputMaybe<InviteFilterInput>
  orderBy?: InputMaybe<InviteOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type InvitesQuery = {
  __typename?: 'Query'
  invites?: Array<{
    __typename?: 'Invite'
    id: string
    teamId?: string | null
    teamName?: string | null
    invitedByUserName?: string | null
    emailAddress: string
    status: InviteStatus
    roles?: Array<string> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
  } | null> | null
}

export type MagicLinkAnalyticsQueryVariables = Exact<{
  granularity: Granularity
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  userId?: InputMaybe<Scalars['ObjectId']['input']>
  magicLinkId?: InputMaybe<Scalars['ObjectId']['input']>
  startDate?: InputMaybe<Scalars['Instant']['input']>
  endDate?: InputMaybe<Scalars['Instant']['input']>
}>

export type MagicLinkAnalyticsQuery = {
  __typename?: 'Query'
  magicLinkAnalytics?: Array<{
    __typename?: 'BasicMetrics'
    queued?: number | null
    failed?: number | null
    sent?: number | null
    deliveryEmailAccepted?: number | null
    deliveryEmailSent?: number | null
    deliveryEmailOpened?: number | null
    deliveryEmailClicked?: number | null
    deliveryEmailDonated?: number | null
    deliveryEmailDeclined?: number | null
    deliveryEmailUnsubscribed?: number | null
    deliveryEmailFailed?: number | null
    deliveryEmailExpired?: number | null
    accepted?: number | null
    delivered?: number | null
    bounces?: number | null
    touches?: number | null
    costInCents?: number | null
    gmvUSD?: number | null
    gmvCAD?: number | null
    gmvEUR?: number | null
    gmvGBP?: number | null
    started?: number | null
    completed?: number | null
    cancelled?: number | null
    successful?: number | null
    campaignsSent?: number | null
    playbooksStarted?: number | null
    triggersExecuted?: number | null
    linkTotalViews?: number | null
    linkUniqViews?: number | null
    linkMaxExeViews?: number | null
    linkExpiredViews?: number | null
    linkExecutions?: number | null
    fundsAdded?: number | null
    eventsBooked?: number | null
    meetingsBooked?: number | null
    thankYou?: number | null
    socialShare?: number | null
    newContact?: number | null
    linkNewContact?: number | null
    activeMagicLinks?: number | null
    date?: any | null
  }> | null
}

export type MarketplaceSearchQueryVariables = Exact<{
  query?: InputMaybe<MarketplaceSearchQueryInput>
}>

export type MarketplaceSearchQuery = {
  __typename?: 'Query'
  marketplaceSearch: {
    __typename?: 'MarketplaceSearchResponse'
    results: Array<{
      __typename?: 'SearchableProduct'
      id: string
      itemId: string
      itemType: ItemType
      title: string
      itemName: string
      description: string
      rating?: number | null
      currency: Currency
      categories?: Array<string> | null
      imageUrls: Array<string>
      productGroupIds?: Array<string> | null
      inventory?: number | null
      status: Status
      magicEventStatus?: MagicEventStatus | null
      itemCount?: number | null
      brandName?: string | null
      postalPreferred?: boolean | null
      containsPerishables?: boolean | null
      estimatedShippingTime?: string | null
      minCost: number
      minPrice: number
      maxPrice: number
      eventDate?: any | null
      lastRegistrationDate?: any | null
      contexts: Array<string>
    }>
    filters?: Array<{
      __typename?: 'MarketplaceFilterV2'
      name: string
      title: string
      type: FilterType
      options: Array<{
        __typename?: 'MarketplaceFilterOptionV2'
        name: string
        value: string
        ref?: string | null
        count: number
      }>
    }> | null
    summary: {
      __typename?: 'ResultsSummary'
      hasMore: boolean
      currentPage: number
      totalRecords?: number | null
      totalPages?: number | null
    }
  }
}

export type MarketplaceSuggestQueryVariables = Exact<{
  query?: InputMaybe<MarketplaceSuggestQueryInput>
}>

export type MarketplaceSuggestQuery = {
  __typename?: 'Query'
  marketplaceSuggest: {
    __typename?: 'MarketplaceSearchResponse'
    results: Array<{
      __typename?: 'SearchableProduct'
      id: string
      itemId: string
      itemType: ItemType
      title: string
      itemName: string
      description: string
      rating?: number | null
      currency: Currency
      categories?: Array<string> | null
      imageUrls: Array<string>
      productGroupIds?: Array<string> | null
      inventory?: number | null
      status: Status
      magicEventStatus?: MagicEventStatus | null
      itemCount?: number | null
      brandName?: string | null
      postalPreferred?: boolean | null
      containsPerishables?: boolean | null
      estimatedShippingTime?: string | null
      minCost: number
      minPrice: number
      maxPrice: number
      eventDate?: any | null
      lastRegistrationDate?: any | null
      contexts: Array<string>
    }>
    filters?: Array<{
      __typename?: 'MarketplaceFilterV2'
      name: string
      title: string
      type: FilterType
      options: Array<{
        __typename?: 'MarketplaceFilterOptionV2'
        name: string
        value: string
        ref?: string | null
        count: number
      }>
    }> | null
    summary: {
      __typename?: 'ResultsSummary'
      hasMore: boolean
      currentPage: number
      totalRecords?: number | null
      totalPages?: number | null
    }
  }
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserAccount'
    id: string
    firstName: string
    lastName: string
    emailAddress: string
    profilePicture?: string | null
    phoneNumber?: string | null
    title?: string | null
    meetingLink?: string | null
    accountName?: string | null
    teamName?: string | null
    authTypes?: Array<string | null> | null
    handwritingName?: string | null
    completedActions?: any | null
    emailSignatureHtml?: string | null
    currencyList?: Array<Currency> | null
    productAccess?: Array<{
      __typename?: 'ProductAccess'
      id: string
      accountId?: string | null
      teamId?: string | null
      partnerId?: string | null
      product: Product
      roles?: Array<string> | null
      type?: ProductAccessType | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    }> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    notifications?: {
      __typename?: 'UserNotifications'
      notificationsOn?: boolean | null
      balanceLowNotificationsOn?: boolean | null
      balanceLowChannels?: Array<UserNotificationChannel | null> | null
      lastBalanceLowNotificationSent?: any | null
      budgetLowNotificationsOn?: boolean | null
      budgetLowChannels?: Array<UserNotificationChannel | null> | null
      lastBudgetLowNotificationSent?: any | null
      giftEmailAcceptedNotificationsOn?: boolean | null
      giftEmailAcceptedChannels?: Array<UserNotificationChannel | null> | null
      magicLinkAcceptedNotificationsOn?: boolean | null
      magicLinkAcceptedChannels?: Array<UserNotificationChannel | null> | null
      orderDeliveredNotificationsOn?: boolean | null
      orderDeliveredChannels?: Array<UserNotificationChannel | null> | null
      thankYouNotificationsOn?: boolean | null
      thankYouChannels?: Array<UserNotificationChannel> | null
      landingPageExceptionNotificationOn?: boolean | null
      landingPageExceptionChannels?: Array<UserNotificationChannel> | null
      autoReloadFailuresNotificationsOn?: boolean | null
      autoReloadFailuresChannels?: Array<UserNotificationChannel | null> | null
      orderIssuesNotificationsOn?: boolean | null
      orderIssuesChannels?: Array<UserNotificationChannel | null> | null
    } | null
    emailDigestSettings?: {
      __typename?: 'EmailDigestSettings'
      processingErrors?: boolean | null
      ordersRequiringUserApproval?: boolean | null
      itemOutOfStock?: boolean | null
      itemLowStock?: boolean | null
      marketingUpdates?: boolean | null
      newItemApproved?: boolean | null
      newCollectionApproved?: boolean | null
      newEventApproved?: boolean | null
      itemRemoved?: boolean | null
      frequency?: EmailDigestFrequency | null
    } | null
    meetingSettings?: {
      __typename?: 'MeetingSettings'
      enabled: boolean
      meetingDurationMins: number
      dateRange: number
      availabilityBlockIcal: string
      meetingRequestDefault: MeetingRequestSetting
      meetingName: string
      schedulingLink?: string | null
      videoMeetingLink?: string | null
      calendar?: {
        __typename?: 'ConnectedCalendar'
        userId: string
        provider: CalendarProvider
        timeZone: string
        calendarId: string
        busyByDefault: boolean
        scheduleDayLeadTime: number
        scheduleHourLeadTime: number
        scheduleBuffer: number
        scheduleBufferUnits: ChronoUnit
      } | null
    } | null
  }
}

export type MessageTemplatesQueryVariables = Exact<{
  filter?: InputMaybe<MessageTemplateFilterInput>
  orderBy?: InputMaybe<MessageTemplateOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type MessageTemplatesQuery = {
  __typename?: 'Query'
  messageTemplates?: Array<{
    __typename?: 'MessageTemplate'
    id: string
    name: string
    templateText: string
    sharedWith?: { __typename?: 'ShareLink'; type: LinkType; target: string } | null
  }> | null
}

export type ModulesQueryVariables = Exact<{ [key: string]: never }>

export type ModulesQuery = {
  __typename?: 'Query'
  modules?: Array<{ __typename?: 'Module'; name: string; permissions?: any | null; meta?: any | null } | null> | null
}

export type NamedProductAccessListQueryVariables = Exact<{ [key: string]: never }>

export type NamedProductAccessListQuery = {
  __typename?: 'Query'
  namedProductAccessList?: Array<{
    __typename?: 'NamedProductAccess'
    accountDisabled?: boolean | null
    accountName?: string | null
    teamName?: string | null
    productAccess: {
      __typename?: 'ProductAccess'
      id: string
      accountId?: string | null
      teamId?: string | null
      partnerId?: string | null
      product: Product
      roles?: Array<string> | null
      type?: ProductAccessType | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    }
  } | null> | null
}

export type ObjectAnalyticsQueryVariables = Exact<{
  type: DataObjectType
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  userId?: InputMaybe<Scalars['ObjectId']['input']>
  filter?: InputMaybe<AnalyticsFilterInput>
}>

export type ObjectAnalyticsQuery = {
  __typename?: 'Query'
  objectAnalytics: {
    __typename?: 'BasicMetrics'
    queued?: number | null
    failed?: number | null
    sent?: number | null
    deliveryEmailAccepted?: number | null
    deliveryEmailSent?: number | null
    deliveryEmailOpened?: number | null
    deliveryEmailClicked?: number | null
    deliveryEmailDonated?: number | null
    deliveryEmailDeclined?: number | null
    deliveryEmailUnsubscribed?: number | null
    deliveryEmailFailed?: number | null
    deliveryEmailExpired?: number | null
    accepted?: number | null
    delivered?: number | null
    bounces?: number | null
    touches?: number | null
    costInCents?: number | null
    gmvUSD?: number | null
    gmvCAD?: number | null
    gmvEUR?: number | null
    gmvGBP?: number | null
    started?: number | null
    completed?: number | null
    cancelled?: number | null
    successful?: number | null
    campaignsSent?: number | null
    playbooksStarted?: number | null
    triggersExecuted?: number | null
    linkTotalViews?: number | null
    linkUniqViews?: number | null
    linkMaxExeViews?: number | null
    linkExpiredViews?: number | null
    linkExecutions?: number | null
    fundsAdded?: number | null
    eventsBooked?: number | null
    meetingsBooked?: number | null
    thankYou?: number | null
    socialShare?: number | null
    newContact?: number | null
    linkNewContact?: number | null
    activeMagicLinks?: number | null
    date?: any | null
  }
}

export type PingQueryVariables = Exact<{ [key: string]: never }>

export type PingQuery = { __typename?: 'Query'; ping: boolean }

export type PlaybookAnalyticsQueryVariables = Exact<{
  granularity: Granularity
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  userId?: InputMaybe<Scalars['ObjectId']['input']>
  playbookDefinitionId?: InputMaybe<Scalars['ObjectId']['input']>
  startDate?: InputMaybe<Scalars['Instant']['input']>
  endDate?: InputMaybe<Scalars['Instant']['input']>
}>

export type PlaybookAnalyticsQuery = {
  __typename?: 'Query'
  playbookAnalytics?: Array<{
    __typename?: 'BasicMetrics'
    queued?: number | null
    failed?: number | null
    sent?: number | null
    deliveryEmailAccepted?: number | null
    deliveryEmailSent?: number | null
    deliveryEmailOpened?: number | null
    deliveryEmailClicked?: number | null
    deliveryEmailDonated?: number | null
    deliveryEmailDeclined?: number | null
    deliveryEmailUnsubscribed?: number | null
    deliveryEmailFailed?: number | null
    deliveryEmailExpired?: number | null
    accepted?: number | null
    delivered?: number | null
    bounces?: number | null
    touches?: number | null
    costInCents?: number | null
    gmvUSD?: number | null
    gmvCAD?: number | null
    gmvEUR?: number | null
    gmvGBP?: number | null
    started?: number | null
    completed?: number | null
    cancelled?: number | null
    successful?: number | null
    campaignsSent?: number | null
    playbooksStarted?: number | null
    triggersExecuted?: number | null
    linkTotalViews?: number | null
    linkUniqViews?: number | null
    linkMaxExeViews?: number | null
    linkExpiredViews?: number | null
    linkExecutions?: number | null
    fundsAdded?: number | null
    eventsBooked?: number | null
    meetingsBooked?: number | null
    thankYou?: number | null
    socialShare?: number | null
    newContact?: number | null
    linkNewContact?: number | null
    activeMagicLinks?: number | null
    date?: any | null
  }> | null
}

export type PostalAnalyticsQueryVariables = Exact<{
  granularity: Granularity
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  userId?: InputMaybe<Scalars['ObjectId']['input']>
  startDate?: InputMaybe<Scalars['Instant']['input']>
  endDate?: InputMaybe<Scalars['Instant']['input']>
}>

export type PostalAnalyticsQuery = {
  __typename?: 'Query'
  postalAnalytics?: Array<{
    __typename?: 'BasicMetrics'
    queued?: number | null
    failed?: number | null
    sent?: number | null
    deliveryEmailAccepted?: number | null
    deliveryEmailSent?: number | null
    deliveryEmailOpened?: number | null
    deliveryEmailClicked?: number | null
    deliveryEmailDonated?: number | null
    deliveryEmailDeclined?: number | null
    deliveryEmailUnsubscribed?: number | null
    deliveryEmailFailed?: number | null
    deliveryEmailExpired?: number | null
    accepted?: number | null
    delivered?: number | null
    bounces?: number | null
    touches?: number | null
    costInCents?: number | null
    gmvUSD?: number | null
    gmvCAD?: number | null
    gmvEUR?: number | null
    gmvGBP?: number | null
    started?: number | null
    completed?: number | null
    cancelled?: number | null
    successful?: number | null
    campaignsSent?: number | null
    playbooksStarted?: number | null
    triggersExecuted?: number | null
    linkTotalViews?: number | null
    linkUniqViews?: number | null
    linkMaxExeViews?: number | null
    linkExpiredViews?: number | null
    linkExecutions?: number | null
    fundsAdded?: number | null
    eventsBooked?: number | null
    meetingsBooked?: number | null
    thankYou?: number | null
    socialShare?: number | null
    newContact?: number | null
    linkNewContact?: number | null
    activeMagicLinks?: number | null
    date?: any | null
  }> | null
}

export type PostalAnalyticsV2QueryVariables = Exact<{
  config: AnalyticsRequestConfig
}>

export type PostalAnalyticsV2Query = {
  __typename?: 'Query'
  postalAnalyticsV2?: Array<{
    __typename?: 'ApprovedPostalAnalytics'
    name?: string | null
    approvedPostalId?: string | null
    previewImageUrl?: string | null
    firstName?: string | null
    lastName?: string | null
    userName?: string | null
    teamName?: string | null
    userId?: string | null
    teamId?: string | null
    queued?: number | null
    failed?: number | null
    sent?: number | null
    deliveryEmailAccepted?: number | null
    deliveryEmailSent?: number | null
    deliveryEmailOpened?: number | null
    deliveryEmailClicked?: number | null
    deliveryEmailDonated?: number | null
    deliveryEmailDeclined?: number | null
    deliveryEmailUnsubscribed?: number | null
    deliveryEmailFailed?: number | null
    deliveryEmailExpired?: number | null
    accepted?: number | null
    delivered?: number | null
    bounces?: number | null
    touches?: number | null
    costInCents?: number | null
    gmvUSD?: number | null
    gmvCAD?: number | null
    gmvEUR?: number | null
    gmvGBP?: number | null
    started?: number | null
    completed?: number | null
    cancelled?: number | null
    successful?: number | null
    campaignsSent?: number | null
    playbooksStarted?: number | null
    triggersExecuted?: number | null
    linkTotalViews?: number | null
    linkUniqViews?: number | null
    linkMaxExeViews?: number | null
    linkExpiredViews?: number | null
    linkExecutions?: number | null
    fundsAdded?: number | null
    eventsBooked?: number | null
    meetingsBooked?: number | null
    thankYou?: number | null
    socialShare?: number | null
    newContact?: number | null
    linkNewContact?: number | null
    activeMagicLinks?: number | null
    date?: any | null
  }> | null
}

export type PreviewAddFundsQueryVariables = Exact<{
  input: PreviewAddFundsInput
}>

export type PreviewAddFundsQuery = {
  __typename?: 'Query'
  previewAddFunds: {
    __typename?: 'PrepayCalculationResult'
    grossPaymentAmount: number
    netPaymentAmount: number
    feeInfo: { __typename?: 'AddFundsFeeInfo'; totalFee: number; percentFee: number; flatFee: number }
  }
}

export type PreviewEventQueryVariables = Exact<{
  marketplaceProductId: Scalars['ObjectId']['input']
  productVariantId: Scalars['ObjectId']['input']
  attendeeCount: Scalars['Int']['input']
}>

export type PreviewEventQuery = {
  __typename?: 'Query'
  previewEvent: {
    __typename?: 'EventPreview'
    minimumSpend?: number | null
    fundsRemaining?: number | null
    fundsPassed?: boolean | null
    budgetRemaining?: number | null
    budgetPassed?: boolean | null
    variableCost: {
      __typename?: 'OrderCost'
      subtotal?: number | null
      shipping?: number | null
      tax?: number | null
      thirdPartyFee?: number | null
      postalFee?: number | null
      taxEstimated?: boolean | null
      budgetExpense?: number | null
      total?: number | null
      currency?: Currency | null
    }
    flatFees: {
      __typename?: 'OrderCost'
      subtotal?: number | null
      shipping?: number | null
      tax?: number | null
      thirdPartyFee?: number | null
      postalFee?: number | null
      taxEstimated?: boolean | null
      budgetExpense?: number | null
      total?: number | null
      currency?: Currency | null
    }
  }
}

export type PreviewGiftEmailAcceptancePageQueryVariables = Exact<{
  data: PreviewGiftEmailAcceptancePageInput
}>

export type PreviewGiftEmailAcceptancePageQuery = {
  __typename?: 'Query'
  previewGiftEmailAcceptancePage: { __typename?: 'PreviewGiftEmailAcceptancePageResponse'; htmlUrl: string }
}

export type PreviewMagicLinkQueryVariables = Exact<{
  approvedPostalId: Scalars['ObjectId']['input']
  variantId: Scalars['ObjectId']['input']
  message: Scalars['String']['input']
  landingPageCustomization?: InputMaybe<LandingPageCustomizationInput>
}>

export type PreviewMagicLinkQuery = {
  __typename?: 'Query'
  previewMagicLink: {
    __typename?: 'PreviewMagicLinkResponse'
    htmlUrl: string
    previewImageUrl?: string | null
    previewImageMobileUrl?: string | null
  }
}

export type PreviewOrderQueryVariables = Exact<{
  approvedPostalId: Scalars['ObjectId']['input']
  approvedPostalVariantId: Scalars['ObjectId']['input']
  orderType?: InputMaybe<DataObjectType>
  filter?: InputMaybe<ContactFilterInput>
  v2filter?: InputMaybe<SearchableContactFilterInput>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput> | SearchableContactFilterInput>
  count?: InputMaybe<Scalars['Int']['input']>
  deliveryEmail?: InputMaybe<Scalars['Boolean']['input']>
  spendAs?: InputMaybe<SpendAsInput>
  quantity?: InputMaybe<Scalars['Int']['input']>
}>

export type PreviewOrderQuery = {
  __typename?: 'Query'
  previewOrder: {
    __typename?: 'OrderPreview'
    totalContacts: number
    firstDeliveryEstimated: any
    lastDeliveryEstimated: any
    fundsRemaining?: number | null
    fundsPassed?: boolean | null
    budgetRemaining?: number | null
    budgetPassed?: boolean | null
    duplicateOrder?: boolean | null
    skipConfirmation?: boolean | null
    orderBlocked?: boolean | null
    orderBlockedReason?: string | null
    hardBlock?: boolean | null
    currency?: Currency | null
    billingAccountId?: string | null
    emailIntegrationSuccess?: boolean | null
    cost: {
      __typename?: 'OrderCost'
      subtotal?: number | null
      shipping?: number | null
      tax?: number | null
      thirdPartyFee?: number | null
      postalFee?: number | null
      taxEstimated?: boolean | null
      budgetExpense?: number | null
      total?: number | null
      currency?: Currency | null
    }
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
  }
}

export type PreviewOrderV2QueryVariables = Exact<{
  request: OrderPreviewV2Request
  contactFilters?: InputMaybe<Array<SearchableContactFilterInput> | SearchableContactFilterInput>
}>

export type PreviewOrderV2Query = {
  __typename?: 'Query'
  previewOrderV2: {
    __typename?: 'OrderPreviewV2'
    totalContacts?: number | null
    fundsRemaining?: number | null
    budgetRemaining?: number | null
    currency?: Currency | null
    billingAccountId?: string | null
    validationPassed: boolean
    cost?: {
      __typename?: 'OrderCost'
      subtotal?: number | null
      shipping?: number | null
      tax?: number | null
      thirdPartyFee?: number | null
      postalFee?: number | null
      taxEstimated?: boolean | null
      budgetExpense?: number | null
      total?: number | null
      currency?: Currency | null
    } | null
    generalValidationIssues?: Array<{
      __typename?: 'ValidationIssue'
      validationName: string
      reason: string
      issue: OrderIssue
    }> | null
    contactValidationIssues?: Array<{
      __typename?: 'ContactValidationIssue'
      contactId: string
      contactName: string
      validationIssues?: Array<{
        __typename?: 'ValidationIssue'
        validationName: string
        reason: string
        issue: OrderIssue
      }> | null
    }> | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
  }
}

export type ProductAccessListQueryVariables = Exact<{ [key: string]: never }>

export type ProductAccessListQuery = {
  __typename?: 'Query'
  productAccessList?: Array<{
    __typename?: 'ProductAccess'
    id: string
    accountId?: string | null
    teamId?: string | null
    partnerId?: string | null
    product: Product
    roles?: Array<string> | null
    type?: ProductAccessType | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
  } | null> | null
}

export type SearchActivityStreamQueryVariables = Exact<{
  filter?: InputMaybe<ActivityStreamFilterInput>
  orderBy?: InputMaybe<ActivityStreamOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchActivityStreamQuery = {
  __typename?: 'Query'
  searchActivityStream?: Array<{
    __typename?: 'ActivityStream'
    acknowledgedBy?: string | null
    type: ActivityType
    name: string
    message: string
    count?: number | null
    webhookGroup?: string | null
    category?: NotificationCategory | null
    callouts?: Array<{
      __typename?: 'StreamCallout'
      targetText: string
      text: string
      action?: string | null
      dataType?: DataObjectType | null
      dataId?: string | null
    }> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
  }> | null
}

export type SearchAddFundsIntentsQueryVariables = Exact<{
  filter?: InputMaybe<AddFundsIntentFilterInput>
  orderBy?: InputMaybe<AddFundsIntentOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchAddFundsIntentsQuery = {
  __typename?: 'Query'
  searchAddFundsIntents?: Array<{
    __typename?: 'AddFundsIntent'
    id: string
    accountId: string
    userId: string
    status: AddFundsStatus
    billingAccountId: string
    currency?: Currency | null
    postalFulfillmentId?: string | null
    partnerPaymentMethodId?: string | null
    paymentPartnerType?: PaymentPartnerType | null
    grossPaymentAmount: number
    netPaymentAmount: number
    type: AddFundsType
    metadata?: any | null
    lastPayByInvoiceCheck?: any | null
    feeInfo: { __typename?: 'AddFundsFeeInfo'; totalFee: number; percentFee: number; flatFee: number }
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }> | null
}

export type SearchApprovedPostalsQueryVariables = Exact<{
  filter?: InputMaybe<ApprovedPostalFilterInput>
  orderBy?: InputMaybe<DefaultOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchApprovedPostalsQuery = {
  __typename?: 'Query'
  searchApprovedPostals?: Array<{
    __typename?: 'ApprovedPostal'
    id: string
    userId?: string | null
    ownerId?: string | null
    teamIds?: Array<string> | null
    name: string
    displayName?: string | null
    description: string
    brandName?: string | null
    status: Status
    category: string
    type?: string | null
    subCategory?: Array<string> | null
    useCases?: Array<string> | null
    marketplaceProductId: string
    variantOrderFlexibility?: boolean | null
    collection?: boolean | null
    currency?: Currency | null
    customizable?: boolean | null
    geographicMarkets?: Array<string> | null
    imageUrls?: Array<{ __typename?: 'ImageReference'; url: string; description?: string | null }> | null
    variants?: Array<{
      __typename?: 'ApprovedProductVariant'
      id: string
      marketplaceProductId?: string | null
      approvedPostalId?: string | null
      productName?: string | null
      variantName: string
      displayName?: string | null
      description?: string | null
      status: Status
      price: number
      displayPrice: number
      flatFees?: number | null
      displaySubscriberPrice: number
      shippingPriceEstimateMin?: number | null
      shippingPriceEstimateMax?: number | null
      giftMessageSupported?: boolean | null
      physicalMessageSupported?: boolean | null
      containsPerishables?: boolean | null
      imageUrls?: Array<{ __typename?: 'ImageReference'; url: string; description?: string | null }> | null
    }> | null
    designTemplate?: {
      __typename?: 'DesignTemplate'
      dpi: number
      front?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      back?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
    } | null
    event?: {
      __typename?: 'MagicEventInstance'
      requestedByName: string
      requestedByEmail: string
      requestedByPhone: string
      requestedByMessage: string
      requestedAttendeeCount: number
      spotsRemaining?: number | null
      calendarRequestedDate?: any | null
      requestedDates?: Array<any> | null
      eventDateTime?: any | null
      eventLengthMinutes?: number | null
      status: MagicEventStatus
      lastRegistrationDateTime?: any | null
      meetingLink?: string | null
      sendEmailConfirmation?: boolean | null
      sendReminderDayBefore?: boolean | null
      sendReminderDayOf?: boolean | null
      sendCancelledAlert?: boolean | null
      sendInviteExpiringAlert?: boolean | null
      sendMeetingLinkChanged?: boolean | null
      sendDateTimeChanged?: boolean | null
      sendShippedAlert?: boolean | null
      sendDeliveredAlert?: boolean | null
      confirmationCustomization?: {
        __typename?: 'EventEmailCustomization'
        subjectLine?: string | null
        body?: string | null
      } | null
      reminderTomorrowCustomization?: {
        __typename?: 'EventEmailCustomization'
        subjectLine?: string | null
        body?: string | null
      } | null
      reminderTodayCustomization?: {
        __typename?: 'EventEmailCustomization'
        subjectLine?: string | null
        body?: string | null
      } | null
      cancellationCustomization?: {
        __typename?: 'EventEmailCustomization'
        subjectLine?: string | null
        body?: string | null
      } | null
      meetingLinkChangedCustomization?: {
        __typename?: 'EventEmailCustomization'
        subjectLine?: string | null
        body?: string | null
      } | null
      dateTimeChangedCustomization?: {
        __typename?: 'EventEmailCustomization'
        subjectLine?: string | null
        body?: string | null
      } | null
    } | null
    eventDetails?: {
      __typename?: 'MagicEvent'
      eventLengthMinutes: number
      minimumAttendees?: number | null
      maximumAttendees?: number | null
      requirements?: Array<Requirements> | null
      talentEmailAddress: string
      talentName: string
      talentPhoneNumber: string
      talentCompany?: string | null
    } | null
    metrics?: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    itemCustomizationList?: Array<{
      __typename?: 'ItemCustomization'
      id: string
      displayName?: string | null
      type?: ItemCustomizationType | null
      customizableBySender?: boolean | null
      customizableByRecipient?: boolean | null
      required?: boolean | null
      value?: any | null
      characterLimit?: number | null
      widthRecommendation?: number | null
      heightRecommendation?: number | null
      fileTypesAccepted?: Array<string> | null
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    eventFeeSettings?: {
      __typename?: 'EventFeeSettings'
      flatFee?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    } | null
  }> | null
}

export type SearchAssetsQueryVariables = Exact<{
  filter?: InputMaybe<AssetFilterInput>
  orderBy?: InputMaybe<AssetOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchAssetsQuery = {
  __typename?: 'Query'
  searchAssets?: Array<{
    __typename?: 'Asset'
    id: string
    type: AssetType
    name: string
    status: Status
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }> | null
}

export type SearchBackgroundTasksQueryVariables = Exact<{
  filter?: InputMaybe<BackgroundTaskFilterInput>
  orderBy?: InputMaybe<BackgroundTaskOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchBackgroundTasksQuery = {
  __typename?: 'Query'
  searchBackgroundTasks?: Array<{
    __typename?: 'BackgroundTask'
    id: string
    relatedObjectId?: string | null
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null
    taskCompleted?: any | null
    taskErrored?: any | null
    outputs?: any | null
    errors?: Array<{
      __typename?: 'BackgroundOperationError'
      code: string
      count: number
      message?: string | null
    }> | null
  }> | null
}

export type SearchBillingAccountsQueryVariables = Exact<{
  filter?: InputMaybe<BillingAccountFilterInput>
  orderBy?: InputMaybe<BillingAccountOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchBillingAccountsQuery = {
  __typename?: 'Query'
  searchBillingAccounts?: Array<{
    __typename?: 'BillingAccount'
    id: string
    name: string
    status: Status
    type: BillingAccountType
    linkedAccountIdentifier?: string | null
    balance?: number | null
    prepaymentProcessing: boolean
    prepaymentBackgroundTaskId?: string | null
    currentPrepaymentAmount?: number | null
    autoReload?: boolean | null
    autoReloadAmount?: number | null
    autoReloadLowLimit?: number | null
    currency?: Currency | null
    incomingInvoiceAmount?: number | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    defaultPaymentMethod?: {
      __typename?: 'DefaultPaymentMethod'
      id?: string | null
      partnerId?: string | null
      paymentPartnerType?: PaymentPartnerType | null
      type?: PaymentMethodType | null
      autoReloadOk: boolean
      autoReloadFailures: number
      lastFailedAutoReloadAttempt?: any | null
      lastUnlocked?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    } | null
    incomingInvoiceActionRecord?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    billingContactInfo?: {
      __typename?: 'BillingContactInfo'
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phone?: string | null
      address?: {
        __typename?: 'BillingContactAddress'
        line1?: string | null
        line2?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
      } | null
    } | null
    paymentPartners?: Array<{
      __typename?: 'PaymentPartnerInfo'
      type: PaymentPartnerType
      customerId: string
      lastSyncedAt?: any | null
      paymentMethods?: Array<{
        __typename?: 'PartnerPaymentMethod'
        partnerId: string
        billingDetails?: {
          __typename?: 'BillingPaymentMethodInfo'
          name?: string | null
          email?: string | null
          phone?: string | null
          address?: {
            __typename?: 'BillingContactAddress'
            line1?: string | null
            line2?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
          } | null
        } | null
        card?: {
          __typename?: 'CardPaymentMethod'
          expirationMonth: number
          expirationYear: number
          last4: string
          brand?: string | null
        } | null
      }> | null
    }> | null
    initialNetsuiteBalance?: { __typename?: 'InitialBalanceNetSuiteSyncInfo'; initializedAt?: any | null } | null
  }> | null
}

export type SearchBlocklistEntrysQueryVariables = Exact<{
  filter?: InputMaybe<BlocklistEntryFilterInput>
  orderBy?: InputMaybe<BlocklistEntryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchBlocklistEntrysQuery = {
  __typename?: 'Query'
  searchBlocklistEntrys?: Array<{
    __typename?: 'BlocklistEntry'
    id: string
    accountId: string
    dataObjectType: DataObjectType
    fieldName: string
    entry: string
    filterType: BlocklistFilterType
  }> | null
}

export type SearchBudgetHistoryQueryVariables = Exact<{
  filter?: InputMaybe<BudgetHistoryFilterInput>
  orderBy?: InputMaybe<BudgetHistoryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchBudgetHistoryQuery = {
  __typename?: 'Query'
  searchBudgetHistory: {
    __typename?: 'BudgetHistorySearchResponse'
    data?: Array<{
      __typename?: 'BudgetHistory'
      id: string
      accountId: string
      teamId?: string | null
      userId?: string | null
      billingAccountId?: string | null
      type: BudgetHistoryType
      oldRemaining?: number | null
      amount?: number | null
      usageTransactionId?: string | null
      relatedId?: string | null
      relatedType?: TransactionRelatedType | null
      drawdownRefundId?: string | null
      created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
      oldBudget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
      newBudget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
      currentBudget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    }> | null
    resultsSummary: {
      __typename?: 'ResultsSummary'
      hasMore: boolean
      currentPage: number
      totalRecords?: number | null
      totalPages?: number | null
    }
  }
}

export type SearchCampaignsQueryVariables = Exact<{
  filter?: InputMaybe<CampaignFilterInput>
  orderBy?: InputMaybe<CampaignOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchCampaignsQuery = {
  __typename?: 'Query'
  searchCampaigns?: Array<{
    __typename?: 'Campaign'
    id: string
    userId: string
    name: string
    campaignType?: CampaignType | null
    status: CampaignStatus
    scheduleDate?: any | null
    cancelDate?: any | null
    errorDate?: any | null
    completeDate?: any | null
    giftMessage?: string | null
    backgroundTaskId?: string | null
    playbookId?: string | null
    approvedPostalId?: string | null
    variantId?: string | null
    totalContacts?: number | null
    totalContactsAttempted?: number | null
    deliveryEmail?: boolean | null
    deliveryEmailTemplateId?: string | null
    physicalMessage?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    emailSubjectLine?: string | null
    sendAsContactOwner?: boolean | null
    sendAsUser?: string | null
    teamId?: string | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    designTemplate?: {
      __typename?: 'DesignTemplate'
      dpi: number
      front?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      back?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
    } | null
    metrics?: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    } | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationInputs?: Array<{
      __typename?: 'ItemCustomizationInput'
      id: string
      value: any
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
  }> | null
}

export type SearchContactListsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchContactListsQuery = {
  __typename?: 'Query'
  searchContactLists?: Array<{
    __typename?: 'ContactList'
    id: string
    name: string
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }> | null
}

export type SearchContactPostalFulfillmentsQueryVariables = Exact<{
  contactId: Scalars['ObjectId']['input']
  filter?: InputMaybe<PostalFulfillmentFilterInput>
  orderBy?: InputMaybe<PostalFulfillmentOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchContactPostalFulfillmentsQuery = {
  __typename?: 'Query'
  searchContactPostalFulfillments?: Array<{
    __typename?: 'PostalFulfillment'
    id: string
    accountId: string
    userId: string
    teamId?: string | null
    contactId?: string | null
    shipToName?: string | null
    shipToTitle?: string | null
    shipToCompanyName?: string | null
    campaignName?: string | null
    shipToEmail?: string | null
    deliveryEmail?: boolean | null
    campaignId?: string | null
    playbookId?: string | null
    playbookDefinitionId?: string | null
    playbookName?: string | null
    triggerId?: string | null
    magicLinkId?: string | null
    magicLinkName?: string | null
    itemName: string
    emailSubjectLine?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    previewImage?: string | null
    giftMessage?: string | null
    physicalMessage?: string | null
    thankYouMessage?: string | null
    marketplaceProductId?: string | null
    approvedPostalId?: string | null
    variantId?: string | null
    fulfillmentPartnerId?: string | null
    fulfillmentPartnerOrderId?: string | null
    status: FulfillmentStatus
    stage?: string | null
    targetDeliveryDate?: any | null
    deliveryDate?: any | null
    deliveryPartner?: string | null
    trackingNumber?: string | null
    trackingUrl?: string | null
    attributes?: any | null
    collection?: boolean | null
    collectionName?: string | null
    sendAsUserId?: string | null
    failureReason?: FailureReason | null
    type?: ObjectType | null
    sendType?: SendType | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    shipTo?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
    cost?: {
      __typename?: 'OrderCost'
      subtotal?: number | null
      shipping?: number | null
      tax?: number | null
      thirdPartyFee?: number | null
      postalFee?: number | null
      taxEstimated?: boolean | null
      budgetExpense?: number | null
      total?: number | null
      currency?: Currency | null
    } | null
    history?: Array<{
      __typename?: 'FulfillmentHistory'
      status: FulfillmentStatus
      notes?: string | null
      currentLocation?: string | null
      created: any
    }> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    sendAs?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationList?: Array<{
      __typename?: 'ItemCustomization'
      id: string
      displayName?: string | null
      type?: ItemCustomizationType | null
      customizableBySender?: boolean | null
      customizableByRecipient?: boolean | null
      required?: boolean | null
      value?: any | null
      characterLimit?: number | null
      widthRecommendation?: number | null
      heightRecommendation?: number | null
      fileTypesAccepted?: Array<string> | null
      variantId?: string | null
    }> | null
    formFieldValueList?: Array<{ __typename?: 'FormFieldPair'; field: string; value: any }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
    approvalRequiredInfo?: {
      __typename?: 'ApprovalRequiredInfo'
      approvalRequiredReason: ApprovalRequiredReason
      autoAction?: AutoAction | null
      autoActionDate?: any | null
    } | null
    fulfillments?: Array<{
      __typename?: 'Fulfillment'
      id?: string | null
      marketplaceProductId?: string | null
      approvedPostalId?: string | null
      variantId?: string | null
      quantity?: number | null
      trackingNumber?: string | null
      trackingCompany?: string | null
      trackingUrl?: string | null
      status?: ItemStatus | null
      targetDeliveryDate?: any | null
      deliveryDate?: any | null
      cost?: {
        __typename?: 'OrderCost'
        subtotal?: number | null
        shipping?: number | null
        tax?: number | null
        thirdPartyFee?: number | null
        postalFee?: number | null
        taxEstimated?: boolean | null
        budgetExpense?: number | null
        total?: number | null
        currency?: Currency | null
      } | null
      actualCost?: {
        __typename?: 'OrderCost'
        subtotal?: number | null
        shipping?: number | null
        tax?: number | null
        thirdPartyFee?: number | null
        postalFee?: number | null
        taxEstimated?: boolean | null
        budgetExpense?: number | null
        total?: number | null
        currency?: Currency | null
      } | null
    }> | null
  }> | null
}

export type SearchContactsV2QueryVariables = Exact<{
  filter?: InputMaybe<SearchableContactFilterInput>
  orfilters?: InputMaybe<Array<SearchableContactFilterInput> | SearchableContactFilterInput>
  orderBy?: InputMaybe<SearchableContactOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchContactsV2Query = {
  __typename?: 'Query'
  searchContactsV2: {
    __typename?: 'ContactSearchResponseV2'
    searchableContacts?: Array<{
      __typename?: 'SearchableContact'
      id: string
      firstName?: string | null
      lastName?: string | null
      title?: string | null
      companyName?: string | null
      ownerId?: string | null
      emailAddress?: string | null
      verifiedAddress?: boolean | null
      lastSend?: any | null
      created?: any | null
      updated?: any | null
      itemsSent?: number | null
      costInCents?: number | null
      tags?: Array<string | null> | null
      campaigns?: Array<string | null> | null
      campaignStatus?: Array<string | null> | null
      playbooks?: Array<string | null> | null
      lists?: Array<string | null> | null
      city?: Array<string | null> | null
      state?: Array<string | null> | null
      postalCode?: Array<string | null> | null
      country?: Array<string | null> | null
    }> | null
    resultsSummary: {
      __typename?: 'ResultsSummary'
      hasMore: boolean
      currentPage: number
      totalRecords?: number | null
      totalPages?: number | null
    }
  }
}

export type SearchEmailTemplatesQueryVariables = Exact<{
  filter?: InputMaybe<EmailTemplateFilterInput>
  orderBy?: InputMaybe<GenericFilterInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchEmailTemplatesQuery = {
  __typename?: 'Query'
  searchEmailTemplates?: Array<{
    __typename?: 'EmailTemplate'
    id: string
    name: string
    defaultContent: {
      __typename?: 'EmailTemplateContent'
      type?: string | null
      html?: string | null
      text?: string | null
    }
    contentList?: Array<{
      __typename?: 'EmailTemplateContent'
      type?: string | null
      html?: string | null
      text?: string | null
    }> | null
  }> | null
}

export type SearchEventPostalFulfillmentsQueryVariables = Exact<{
  filter?: InputMaybe<PostalFulfillmentFilterInput>
  orderBy?: InputMaybe<PostalFulfillmentOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchEventPostalFulfillmentsQuery = {
  __typename?: 'Query'
  searchEventPostalFulfillments?: Array<{
    __typename?: 'PostalFulfillment'
    id: string
    accountId: string
    userId: string
    teamId?: string | null
    contactId?: string | null
    shipToName?: string | null
    shipToTitle?: string | null
    shipToCompanyName?: string | null
    campaignName?: string | null
    shipToEmail?: string | null
    deliveryEmail?: boolean | null
    campaignId?: string | null
    playbookId?: string | null
    playbookDefinitionId?: string | null
    playbookName?: string | null
    triggerId?: string | null
    magicLinkId?: string | null
    magicLinkName?: string | null
    itemName: string
    emailSubjectLine?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    previewImage?: string | null
    giftMessage?: string | null
    physicalMessage?: string | null
    thankYouMessage?: string | null
    marketplaceProductId?: string | null
    approvedPostalId?: string | null
    variantId?: string | null
    fulfillmentPartnerId?: string | null
    fulfillmentPartnerOrderId?: string | null
    status: FulfillmentStatus
    stage?: string | null
    targetDeliveryDate?: any | null
    deliveryDate?: any | null
    deliveryPartner?: string | null
    trackingNumber?: string | null
    trackingUrl?: string | null
    attributes?: any | null
    collection?: boolean | null
    collectionName?: string | null
    sendAsUserId?: string | null
    failureReason?: FailureReason | null
    type?: ObjectType | null
    sendType?: SendType | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    shipTo?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
    cost?: {
      __typename?: 'OrderCost'
      subtotal?: number | null
      shipping?: number | null
      tax?: number | null
      thirdPartyFee?: number | null
      postalFee?: number | null
      taxEstimated?: boolean | null
      budgetExpense?: number | null
      total?: number | null
      currency?: Currency | null
    } | null
    history?: Array<{
      __typename?: 'FulfillmentHistory'
      status: FulfillmentStatus
      notes?: string | null
      currentLocation?: string | null
      created: any
    }> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    sendAs?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationList?: Array<{
      __typename?: 'ItemCustomization'
      id: string
      displayName?: string | null
      type?: ItemCustomizationType | null
      customizableBySender?: boolean | null
      customizableByRecipient?: boolean | null
      required?: boolean | null
      value?: any | null
      characterLimit?: number | null
      widthRecommendation?: number | null
      heightRecommendation?: number | null
      fileTypesAccepted?: Array<string> | null
      variantId?: string | null
    }> | null
    formFieldValueList?: Array<{ __typename?: 'FormFieldPair'; field: string; value: any }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
    approvalRequiredInfo?: {
      __typename?: 'ApprovalRequiredInfo'
      approvalRequiredReason: ApprovalRequiredReason
      autoAction?: AutoAction | null
      autoActionDate?: any | null
    } | null
    fulfillments?: Array<{
      __typename?: 'Fulfillment'
      id?: string | null
      marketplaceProductId?: string | null
      approvedPostalId?: string | null
      variantId?: string | null
      quantity?: number | null
      trackingNumber?: string | null
      trackingCompany?: string | null
      trackingUrl?: string | null
      status?: ItemStatus | null
      targetDeliveryDate?: any | null
      deliveryDate?: any | null
      cost?: {
        __typename?: 'OrderCost'
        subtotal?: number | null
        shipping?: number | null
        tax?: number | null
        thirdPartyFee?: number | null
        postalFee?: number | null
        taxEstimated?: boolean | null
        budgetExpense?: number | null
        total?: number | null
        currency?: Currency | null
      } | null
      actualCost?: {
        __typename?: 'OrderCost'
        subtotal?: number | null
        shipping?: number | null
        tax?: number | null
        thirdPartyFee?: number | null
        postalFee?: number | null
        taxEstimated?: boolean | null
        budgetExpense?: number | null
        total?: number | null
        currency?: Currency | null
      } | null
    }> | null
  }> | null
}

export type SearchFavoriteItemsQueryVariables = Exact<{
  filter?: InputMaybe<FavoriteItemFilterInput>
  orderBy?: InputMaybe<DefaultOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchFavoriteItemsQuery = {
  __typename?: 'Query'
  searchFavoriteItems?: Array<{
    __typename?: 'FavoriteItem'
    id: string
    userId: string
    teamId?: string | null
    approvedPostalId: string
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
  }> | null
}

export type SearchIntegrationConnectionQueryVariables = Exact<{
  filter?: InputMaybe<IntegrationConnectionFilterInput>
}>

export type SearchIntegrationConnectionQuery = {
  __typename?: 'Query'
  searchIntegrationConnection?: Array<{
    __typename?: 'IntegrationConnection'
    accountId?: string | null
    system?: string | null
    authenticationUserId?: string | null
    authenticationFulfillmentPartnerId?: string | null
  }> | null
}

export type SearchIntegrationSyncQueryVariables = Exact<{
  filter?: InputMaybe<IntegrationSyncFilterInput>
}>

export type SearchIntegrationSyncQuery = {
  __typename?: 'Query'
  searchIntegrationSync?: Array<{
    __typename?: 'IntegrationSync'
    id: string
    system: string
    status: Status
    lastRun?: any | null
    nextRun?: any | null
    syncTime?: any | null
    pollingTimeMins?: number | null
    workStatus?: BackgroundTaskStatus | null
    objectType: DataObjectType
    createEnabled?: boolean | null
    leadSourceFieldName?: string | null
    defaultLeadSourceValue?: string | null
    mappings?: Array<{
      __typename?: 'IntegrationDataMapping'
      postalIoFieldName: string
      externalFieldName?: string | null
      staticValue?: string | null
      remap?: any | null
    }> | null
    pushMappings?: Array<{
      __typename?: 'IntegrationDataMapping'
      postalIoFieldName: string
      externalFieldName?: string | null
      staticValue?: string | null
      remap?: any | null
    }> | null
    createCustomFields?: Array<{ __typename?: 'IntegrationCustomField'; field: string; value: string }> | null
  }> | null
}

export type SearchIntegrationTriggerResultsQueryVariables = Exact<{
  filter?: InputMaybe<IntegrationTriggerResultFilterInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchIntegrationTriggerResultsQuery = {
  __typename?: 'Query'
  searchIntegrationTriggerResults?: Array<{
    __typename?: 'IntegrationTriggerResult'
    id: string
    userId: string
    teamId?: string | null
    triggerName: string
    triggerId: string
    contactId?: string | null
    contactName?: string | null
    playbookName?: string | null
    postalName?: string | null
    fulfillmentId?: string | null
    playbookInstanceId?: string | null
    systemName: string
    action: TriggerAction
    result: Result
    notes?: string | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
  }> | null
}

export type SearchIntegrationTriggersQueryVariables = Exact<{
  filter?: InputMaybe<IntegrationTriggerFilterInput>
  orderBy?: InputMaybe<IntegrationTriggerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchIntegrationTriggersQuery = {
  __typename?: 'Query'
  searchIntegrationTriggers?: Array<{
    __typename?: 'IntegrationTrigger'
    id: string
    userId: string
    teamId?: string | null
    name: string
    systemName: string
    status: Status
    action: TriggerAction
    playbookDefinitionId?: string | null
    approvedPostalId?: string | null
    approvedPostalVariantId?: string | null
    giftMessage?: string | null
    deliveryEmail?: boolean | null
    hitCount: number
    deliveryEmailTemplateId?: string | null
    physicalMessage?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    emailSubjectLine?: string | null
    sendAsContactOwner?: boolean | null
    sendAsUser?: string | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    filters?: Array<{ __typename?: 'TriggerFilter'; field: string; filter: TriggerFilterTypes; value: string }> | null
    config?: {
      __typename?: 'TriggerConfiguration'
      sequenceId?: string | null
      sequenceStepId?: string | null
      instanceId?: string | null
      recipeId?: string | null
      recipeName?: string | null
    } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationInputs?: Array<{
      __typename?: 'ItemCustomizationInput'
      id: string
      value: any
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
  }> | null
}

export type SearchLedgerQueryVariables = Exact<{
  filter?: InputMaybe<LedgerFilterInput>
  orderBy?: InputMaybe<LedgerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchLedgerQuery = {
  __typename?: 'Query'
  searchLedger?: Array<{
    __typename?: 'Ledger'
    id: string
    userId?: string | null
    accountId: string
    billingAccountId: string
    type: LedgerRecordType
    amount: number
    previousBalance: number
    currentBalance: number
    relatedId?: string | null
    relatedType?: LedgerRelatedType | null
    memo?: string | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
  }> | null
}

export type SearchMagicLinksQueryVariables = Exact<{
  filter?: InputMaybe<MagicLinkFilterInput>
  orderBy?: InputMaybe<MagicLinkOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchMagicLinksQuery = {
  __typename?: 'Query'
  searchMagicLinks?: Array<{
    __typename?: 'MagicLink'
    id: string
    teamId?: string | null
    name: string
    linkUrl: string
    status: MagicLinkStatus
    expirationDate?: any | null
    maxExecutions?: number | null
    message: string
    approvedPostalId: string
    variantId: string
    distributionChannel?: string | null
    lastView?: any | null
    lastExecution?: any | null
    physicalMessage?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    sendAsContactOwner?: boolean | null
    sendAsUser?: string | null
    requiresApproval?: boolean | null
    requiresUuid?: boolean | null
    metrics: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    }
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationInputs?: Array<{
      __typename?: 'ItemCustomizationInput'
      id: string
      value: any
      variantId?: string | null
    }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
  }> | null
}

export type SearchMarketplaceProductsQueryVariables = Exact<{
  filter?: InputMaybe<MarketplaceProductFilterInput>
  orderBy?: InputMaybe<DefaultOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchMarketplaceProductsQuery = {
  __typename?: 'Query'
  searchMarketplaceProducts?: Array<{
    __typename?: 'MarketplaceProduct'
    id: string
    name: string
    description: string
    brandName?: string | null
    category: string
    type?: string | null
    subCategory?: Array<string> | null
    useCases?: Array<string> | null
    allowedPlans?: Array<string> | null
    variantOrderFlexibility?: boolean | null
    starterTemplate?: boolean | null
    itemPreview?: boolean | null
    status: Status
    phoneNumberRequired?: boolean | null
    eventAvailabilityDates?: Array<string> | null
    currency?: Currency | null
    customizable?: boolean | null
    geographicMarkets?: Array<string> | null
    estimatedShippingTime?: EstimatedShippingTime | null
    variants?: Array<{
      __typename?: 'ProductVariant'
      id: string
      variantName: string
      description?: string | null
      upcCode?: string | null
      manufacturerPartId?: string | null
      status: Status
      size?: string | null
      giftMessageSupported?: boolean | null
      physicalMessageSupported?: boolean | null
      attributes?: any | null
      minimumTransactions?: number | null
      flatFees?: number | null
      displayPrice?: number | null
      containsPerishables?: boolean | null
      imageUrls?: Array<{ __typename?: 'ImageReference'; url: string; description?: string | null }> | null
      fulfillmentPartnerList?: Array<{
        __typename?: 'FulfillmentMap'
        fulfillmentType: FulfillmentType
        fulfillmentPartnerId: string
        fulfillmentPartnerName: string
        itemCode: string
        price: number
        displayPrice: number
        displaySubscriberPrice: number
        supplierPartId?: string | null
        supplierPartAuxiliaryId?: string | null
        priceStructure?: PriceStructure | null
        bulkSendAvailable?: boolean | null
        maximumOrderableQuantity?: number | null
        shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null
        inventory?: { __typename?: 'Inventory'; ordered?: number | null; available?: number | null } | null
      }> | null
    }> | null
    imageUrls?: Array<{ __typename?: 'ImageReference'; url: string; description?: string | null }> | null
    designTemplate?: {
      __typename?: 'DesignTemplate'
      dpi: number
      front?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      back?: Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null
        hidden?: boolean | null
        locked?: boolean | null
        noPdfOutput?: boolean | null
        settings?: any | null
        customizable?: boolean | null
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?: {
          __typename?: 'ElementResize'
          minWidth: number
          minHeight: number
          maxWidth: number
          maxHeight: number
        } | null
        boundary?: { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number } | null
      }> | null
      outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
    } | null
    eventDetails?: {
      __typename?: 'MagicEvent'
      eventLengthMinutes: number
      minimumAttendees?: number | null
      maximumAttendees?: number | null
      requirements?: Array<Requirements> | null
      talentEmailAddress: string
      talentName: string
      talentPhoneNumber: string
      talentCompany?: string | null
    } | null
    itemCustomizationList?: Array<{
      __typename?: 'ItemCustomization'
      id: string
      displayName?: string | null
      type?: ItemCustomizationType | null
      customizableBySender?: boolean | null
      customizableByRecipient?: boolean | null
      required?: boolean | null
      value?: any | null
      characterLimit?: number | null
      widthRecommendation?: number | null
      heightRecommendation?: number | null
      fileTypesAccepted?: Array<string> | null
      variantId?: string | null
    }> | null
  }> | null
}

export type SearchMessageTemplatesQueryVariables = Exact<{
  filter?: InputMaybe<MessageTemplateFilterInput>
  orderBy?: InputMaybe<MessageTemplateOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchMessageTemplatesQuery = {
  __typename?: 'Query'
  searchMessageTemplates?: Array<{
    __typename?: 'MessageTemplate'
    id: string
    name: string
    templateText: string
    sharedWith?: { __typename?: 'ShareLink'; type: LinkType; target: string } | null
  }> | null
}

export type SearchNoAccessUsersQueryVariables = Exact<{
  filter?: InputMaybe<UserFilterInput>
  orderBy?: InputMaybe<UserOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchNoAccessUsersQuery = {
  __typename?: 'Query'
  searchNoAccessUsers: {
    __typename?: 'UserSearchResponse'
    users?: Array<{
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      userName: string
      emailAddress: string
      profilePicture?: string | null
      phoneNumber?: string | null
      title?: string | null
      meetingLink?: string | null
      status: Status
      accountLockedUntil?: any | null
      authenticationFailures?: number | null
      handwritingName?: string | null
      auth?: Array<{
        __typename?: 'AuthenticationMethod'
        method: string
        version?: string | null
        expires?: any | null
        updated?: any | null
        created?: any | null
      } | null> | null
      created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      productAccess?: Array<{
        __typename?: 'ProductAccess'
        id: string
        accountId?: string | null
        teamId?: string | null
        partnerId?: string | null
        product: Product
        roles?: Array<string> | null
        type?: ProductAccessType | null
        created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
      }> | null
      crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null
      notifications?: {
        __typename?: 'UserNotifications'
        notificationsOn?: boolean | null
        balanceLowNotificationsOn?: boolean | null
        balanceLowChannels?: Array<UserNotificationChannel | null> | null
        lastBalanceLowNotificationSent?: any | null
        budgetLowNotificationsOn?: boolean | null
        budgetLowChannels?: Array<UserNotificationChannel | null> | null
        lastBudgetLowNotificationSent?: any | null
        giftEmailAcceptedNotificationsOn?: boolean | null
        giftEmailAcceptedChannels?: Array<UserNotificationChannel | null> | null
        magicLinkAcceptedNotificationsOn?: boolean | null
        magicLinkAcceptedChannels?: Array<UserNotificationChannel | null> | null
        orderDeliveredNotificationsOn?: boolean | null
        orderDeliveredChannels?: Array<UserNotificationChannel | null> | null
        thankYouNotificationsOn?: boolean | null
        thankYouChannels?: Array<UserNotificationChannel> | null
        landingPageExceptionNotificationOn?: boolean | null
        landingPageExceptionChannels?: Array<UserNotificationChannel> | null
        autoReloadFailuresNotificationsOn?: boolean | null
        autoReloadFailuresChannels?: Array<UserNotificationChannel | null> | null
        orderIssuesNotificationsOn?: boolean | null
        orderIssuesChannels?: Array<UserNotificationChannel | null> | null
      } | null
      emailDigestSettings?: {
        __typename?: 'EmailDigestSettings'
        processingErrors?: boolean | null
        ordersRequiringUserApproval?: boolean | null
        itemOutOfStock?: boolean | null
        itemLowStock?: boolean | null
        marketingUpdates?: boolean | null
        newItemApproved?: boolean | null
        newCollectionApproved?: boolean | null
        newEventApproved?: boolean | null
        itemRemoved?: boolean | null
        frequency?: EmailDigestFrequency | null
      } | null
    }> | null
    resultsSummary: {
      __typename?: 'ResultsSummary'
      hasMore: boolean
      currentPage: number
      totalRecords?: number | null
      totalPages?: number | null
    }
  }
}

export type SearchPlaybookDefinitionsQueryVariables = Exact<{
  filter?: InputMaybe<PlaybookDefinitionFilterInput>
  orderBy?: InputMaybe<PlaybookDefinitionOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchPlaybookDefinitionsQuery = {
  __typename?: 'Query'
  searchPlaybookDefinitions?: Array<{
    __typename?: 'PlaybookDefinition'
    id: string
    teamId?: string | null
    status?: Status | null
    name?: string | null
    currency?: Currency | null
    steps: Array<{
      __typename?: 'PlaybookStepDefinition'
      type: PlaybookStepType
      delay: number
      delayUnit: ChronoUnit
      approvedPostalId: string
      variantId: string
      giftMessage?: string | null
      deliveryEmail?: boolean | null
      displayPrice?: number | null
      displaySubscriberPrice?: number | null
      productName?: string | null
      deliveryEmailTemplateId?: string | null
      physicalMessage?: string | null
      meetingRequestSetting?: MeetingRequestSetting | null
      emailSubjectLine?: string | null
      sendAsContactOwner?: boolean | null
      sendAsUser?: string | null
      spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      itemCustomizationInputs?: Array<{
        __typename?: 'ItemCustomizationInput'
        id: string
        value: any
        variantId?: string | null
      }> | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      landingPageCustomization?: {
        __typename?: 'LandingPageCustomization'
        headerText?: string | null
        body?: string | null
        includeHeadshot?: boolean | null
        includeSenderName?: boolean | null
      } | null
      recipientEmailSettings?: {
        __typename?: 'RecipientEmailSettings'
        shippedEmailsOn?: boolean | null
        deliveredEmailsOn?: boolean | null
      } | null
      giftEmailReminderSettings?: {
        __typename?: 'GiftEmailReminderSettings'
        expirationSoonReminders?: Array<number> | null
      } | null
    } | null>
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
  }> | null
}

export type SearchPlaybookInstancesQueryVariables = Exact<{
  filter?: InputMaybe<PlaybookInstanceFilterInput>
  orderBy?: InputMaybe<PlaybookInstanceOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchPlaybookInstancesQuery = {
  __typename?: 'Query'
  searchPlaybookInstances?: Array<{
    __typename?: 'PlaybookInstance'
    id: string
    name?: string | null
    playbookDefinitionId: string
    playbookStatus?: PlaybookStatus | null
    contactId: string
    contactName?: string | null
    triggerId?: string | null
    errMessage?: string | null
    nextStepToExecute?: number | null
    nextExecutionDate?: any | null
    teamId?: string | null
    steps: Array<{
      __typename?: 'PlaybookStepInstance'
      type: PlaybookStepType
      playbookStatus?: PlaybookStatus | null
      errMessage?: string | null
      delay: number
      delayUnit: ChronoUnit
      approvedPostalId: string
      variantId: string
      giftMessage?: string | null
      deliveryEmail?: boolean | null
      fulfillmentId?: string | null
      fulfillmentStatus?: FulfillmentStatus | null
      physicalMessage?: string | null
      meetingRequestSetting?: MeetingRequestSetting | null
      sendAsContactOwner?: boolean | null
      sendAsUser?: string | null
      spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      itemCustomizationInputs?: Array<{
        __typename?: 'ItemCustomizationInput'
        id: string
        value: any
        variantId?: string | null
      }> | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      landingPageCustomization?: {
        __typename?: 'LandingPageCustomization'
        headerText?: string | null
        body?: string | null
        includeHeadshot?: boolean | null
        includeSenderName?: boolean | null
      } | null
      recipientEmailSettings?: {
        __typename?: 'RecipientEmailSettings'
        shippedEmailsOn?: boolean | null
        deliveredEmailsOn?: boolean | null
      } | null
      giftEmailReminderSettings?: {
        __typename?: 'GiftEmailReminderSettings'
        expirationSoonReminders?: Array<number> | null
      } | null
    } | null>
    metrics?: {
      __typename?: 'BasicMetrics'
      queued?: number | null
      failed?: number | null
      sent?: number | null
      deliveryEmailAccepted?: number | null
      deliveryEmailSent?: number | null
      deliveryEmailOpened?: number | null
      deliveryEmailClicked?: number | null
      deliveryEmailDonated?: number | null
      deliveryEmailDeclined?: number | null
      deliveryEmailUnsubscribed?: number | null
      deliveryEmailFailed?: number | null
      deliveryEmailExpired?: number | null
      accepted?: number | null
      delivered?: number | null
      bounces?: number | null
      touches?: number | null
      costInCents?: number | null
      gmvUSD?: number | null
      gmvCAD?: number | null
      gmvEUR?: number | null
      gmvGBP?: number | null
      started?: number | null
      completed?: number | null
      cancelled?: number | null
      successful?: number | null
      campaignsSent?: number | null
      playbooksStarted?: number | null
      triggersExecuted?: number | null
      linkTotalViews?: number | null
      linkUniqViews?: number | null
      linkMaxExeViews?: number | null
      linkExpiredViews?: number | null
      linkExecutions?: number | null
      fundsAdded?: number | null
      eventsBooked?: number | null
      meetingsBooked?: number | null
      thankYou?: number | null
      socialShare?: number | null
      newContact?: number | null
      linkNewContact?: number | null
      activeMagicLinks?: number | null
      date?: any | null
    } | null
    attribution?: {
      __typename?: 'Attribution'
      marketoProgramId?: string | null
      marketoProgramName?: string | null
      marketoCampaignId?: string | null
      marketoCampaignName?: string | null
      sfdcCampaignId?: string | null
      sfdcCampaignName?: string | null
      sdfcSandboxCampaignId?: string | null
      sfdcSandboxCampaignName?: string | null
      eloquaCampaignId?: string | null
      eloquaCampaignName?: string | null
    } | null
  }> | null
}

export type SearchPostalFulfillmentsQueryVariables = Exact<{
  filter?: InputMaybe<PostalFulfillmentFilterInput>
  orderBy?: InputMaybe<PostalFulfillmentOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchPostalFulfillmentsQuery = {
  __typename?: 'Query'
  searchPostalFulfillments?: Array<{
    __typename?: 'PostalFulfillment'
    id: string
    accountId: string
    userId: string
    teamId?: string | null
    contactId?: string | null
    shipToName?: string | null
    shipToTitle?: string | null
    shipToCompanyName?: string | null
    campaignName?: string | null
    shipToEmail?: string | null
    deliveryEmail?: boolean | null
    campaignId?: string | null
    playbookId?: string | null
    playbookDefinitionId?: string | null
    playbookName?: string | null
    triggerId?: string | null
    magicLinkId?: string | null
    magicLinkName?: string | null
    itemName: string
    emailSubjectLine?: string | null
    meetingRequestSetting?: MeetingRequestSetting | null
    previewImage?: string | null
    giftMessage?: string | null
    physicalMessage?: string | null
    thankYouMessage?: string | null
    marketplaceProductId?: string | null
    approvedPostalId?: string | null
    variantId?: string | null
    fulfillmentPartnerId?: string | null
    fulfillmentPartnerOrderId?: string | null
    status: FulfillmentStatus
    stage?: string | null
    targetDeliveryDate?: any | null
    deliveryDate?: any | null
    deliveryPartner?: string | null
    trackingNumber?: string | null
    trackingUrl?: string | null
    attributes?: any | null
    collection?: boolean | null
    collectionName?: string | null
    sendAsUserId?: string | null
    failureReason?: FailureReason | null
    type?: ObjectType | null
    sendType?: SendType | null
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    shipTo?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
    cost?: {
      __typename?: 'OrderCost'
      subtotal?: number | null
      shipping?: number | null
      tax?: number | null
      thirdPartyFee?: number | null
      postalFee?: number | null
      taxEstimated?: boolean | null
      budgetExpense?: number | null
      total?: number | null
      currency?: Currency | null
    } | null
    history?: Array<{
      __typename?: 'FulfillmentHistory'
      status: FulfillmentStatus
      notes?: string | null
      currentLocation?: string | null
      created: any
    }> | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    sendAs?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
    itemCustomizationList?: Array<{
      __typename?: 'ItemCustomization'
      id: string
      displayName?: string | null
      type?: ItemCustomizationType | null
      customizableBySender?: boolean | null
      customizableByRecipient?: boolean | null
      required?: boolean | null
      value?: any | null
      characterLimit?: number | null
      widthRecommendation?: number | null
      heightRecommendation?: number | null
      fileTypesAccepted?: Array<string> | null
      variantId?: string | null
    }> | null
    formFieldValueList?: Array<{ __typename?: 'FormFieldPair'; field: string; value: any }> | null
    formFieldList?: Array<{
      __typename?: 'FormField'
      name: string
      type: FieldType
      isRequired?: boolean | null
      min?: any | null
      max?: any | null
      options?: Array<any> | null
    }> | null
    landingPageCustomization?: {
      __typename?: 'LandingPageCustomization'
      headerText?: string | null
      body?: string | null
      includeHeadshot?: boolean | null
      includeSenderName?: boolean | null
    } | null
    recipientEmailSettings?: {
      __typename?: 'RecipientEmailSettings'
      shippedEmailsOn?: boolean | null
      deliveredEmailsOn?: boolean | null
    } | null
    giftEmailReminderSettings?: {
      __typename?: 'GiftEmailReminderSettings'
      expirationSoonReminders?: Array<number> | null
    } | null
    approvalRequiredInfo?: {
      __typename?: 'ApprovalRequiredInfo'
      approvalRequiredReason: ApprovalRequiredReason
      autoAction?: AutoAction | null
      autoActionDate?: any | null
    } | null
    fulfillments?: Array<{
      __typename?: 'Fulfillment'
      id?: string | null
      marketplaceProductId?: string | null
      approvedPostalId?: string | null
      variantId?: string | null
      quantity?: number | null
      trackingNumber?: string | null
      trackingCompany?: string | null
      trackingUrl?: string | null
      status?: ItemStatus | null
      targetDeliveryDate?: any | null
      deliveryDate?: any | null
      cost?: {
        __typename?: 'OrderCost'
        subtotal?: number | null
        shipping?: number | null
        tax?: number | null
        thirdPartyFee?: number | null
        postalFee?: number | null
        taxEstimated?: boolean | null
        budgetExpense?: number | null
        total?: number | null
        currency?: Currency | null
      } | null
      actualCost?: {
        __typename?: 'OrderCost'
        subtotal?: number | null
        shipping?: number | null
        tax?: number | null
        thirdPartyFee?: number | null
        postalFee?: number | null
        taxEstimated?: boolean | null
        budgetExpense?: number | null
        total?: number | null
        currency?: Currency | null
      } | null
    }> | null
  }> | null
}

export type SearchReportOutputInstancesQueryVariables = Exact<{
  filter?: InputMaybe<ReportOutputFilterInput>
  orderBy?: InputMaybe<ReportOutputOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchReportOutputInstancesQuery = {
  __typename?: 'Query'
  searchReportOutputInstances?: Array<{
    __typename?: 'ReportOutputInstance'
    id: string
    name: string
    type: DataObjectType
    fileName: string
  }> | null
}

export type SearchSavedSendsQueryVariables = Exact<{
  filter?: InputMaybe<SavedSendFilterInput>
  orderBy?: InputMaybe<SavedSendOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchSavedSendsQuery = {
  __typename?: 'Query'
  searchSavedSends?: Array<{
    __typename?: 'SavedSend'
    id: string
    savedSendName: string
    userId: string
    teamId?: string | null
    status: SavedSendStatus
    sendType: SavedSendType
    sendFlowStep?: SendFlowStep | null
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null
    commonSendProperties: {
      __typename?: 'CommonSendProperties'
      name?: string | null
      approvedPostalId: string
      variantId?: string | null
      physicalMessage?: string | null
      meetingRequestSetting?: MeetingRequestSetting | null
      sendAsContactOwner?: boolean | null
      sendAsUser?: string | null
      spendAs?: { __typename?: 'SpendAs'; teamId?: string | null; userId: string } | null
      formFieldList?: Array<{
        __typename?: 'FormField'
        name: string
        type: FieldType
        isRequired?: boolean | null
        min?: any | null
        max?: any | null
        options?: Array<any> | null
      }> | null
      itemCustomizationInputs?: Array<{
        __typename?: 'ItemCustomizationInput'
        id: string
        value: any
        variantId?: string | null
      }> | null
      landingPageCustomization?: {
        __typename?: 'LandingPageCustomization'
        headerText?: string | null
        body?: string | null
        includeHeadshot?: boolean | null
        includeSenderName?: boolean | null
      } | null
      recipientEmailSettings?: {
        __typename?: 'RecipientEmailSettings'
        shippedEmailsOn?: boolean | null
        deliveredEmailsOn?: boolean | null
      } | null
      giftEmailReminderSettings?: {
        __typename?: 'GiftEmailReminderSettings'
        expirationSoonReminders?: Array<number> | null
      } | null
    }
    savedSendMagicLinkInfo?: {
      __typename?: 'SavedSendMagicLinkInfo'
      expirationDate?: any | null
      maxExecutions?: number | null
      message?: string | null
      requiresApproval?: boolean | null
      status?: MagicLinkStatus | null
    } | null
    savedSendCampaignInfo?: {
      __typename?: 'SavedSendCampaignInfo'
      deliveryEmail?: boolean | null
      useSameMessage?: boolean | null
      emailSubjectLine?: string | null
      giftMessage?: string | null
      deliveryEmailTemplateId?: string | null
      scheduleDate?: any | null
      contactSearchFilters?: Array<any> | null
      contactCount?: number | null
    } | null
    savedSendBulkSendInfo?: {
      __typename?: 'SavedSendBulkSendInfo'
      quantity?: number | null
      contactId?: string | null
      addressVerified?: boolean | null
      shipToAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
      newContact?: {
        __typename?: 'NewContact'
        firstName?: string | null
        lastName?: string | null
        emailAddress?: string | null
        phones?: Array<{
          __typename?: 'NewPhoneMap'
          phoneNumber?: string | null
          type?: PhoneType | null
        } | null> | null
      } | null
    } | null
  }> | null
}

export type SearchTagsQueryVariables = Exact<{
  filter?: InputMaybe<TagFilterInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchTagsQuery = {
  __typename?: 'Query'
  searchTags?: Array<{
    __typename?: 'Tag'
    id: string
    name: string
    created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }> | null
}

export type SearchTransferIntentsQueryVariables = Exact<{
  filter?: InputMaybe<TransferIntentFilterInput>
  orderBy?: InputMaybe<TransferIntentOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchTransferIntentsQuery = {
  __typename?: 'Query'
  searchTransferIntents?: Array<{
    __typename?: 'TransferIntent'
    id: string
    accountId: string
    userId: string
    status: TransferIntentStatus
    fromBillingAccountId: string
    toBillingAccountId: string
    fromAmount: number
    toAmount: number
    comment: string
    fromCurrency: Currency
    toCurrency: Currency
    toCreditMemoId?: string | null
    toCreditMemoNumber?: string | null
    fromDebitMemoId?: string | null
    fromDebitMemoNumber?: string | null
    fxInfo?: { __typename?: 'ForeignExchangeInfo'; timestamp: any; rate: string; apiProvider: string } | null
    history?: Array<{
      __typename?: 'TransferIntentHistory'
      status: TransferIntentStatus
      notes?: string | null
      created: any
    }> | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
    updated?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }> | null
}

export type SearchUsersQueryVariables = Exact<{
  filter?: InputMaybe<UserFilterInput>
  orderBy?: InputMaybe<UserOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchUsersQuery = {
  __typename?: 'Query'
  searchUsers: {
    __typename?: 'UserSearchResponse'
    users?: Array<{
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      userName: string
      emailAddress: string
      profilePicture?: string | null
      phoneNumber?: string | null
      title?: string | null
      meetingLink?: string | null
      status: Status
      accountLockedUntil?: any | null
      authenticationFailures?: number | null
      handwritingName?: string | null
      auth?: Array<{
        __typename?: 'AuthenticationMethod'
        method: string
        version?: string | null
        expires?: any | null
        updated?: any | null
        created?: any | null
      } | null> | null
      created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
      productAccess?: Array<{
        __typename?: 'ProductAccess'
        id: string
        accountId?: string | null
        teamId?: string | null
        partnerId?: string | null
        product: Product
        roles?: Array<string> | null
        type?: ProductAccessType | null
        created: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null }
      }> | null
      crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null
      notifications?: {
        __typename?: 'UserNotifications'
        notificationsOn?: boolean | null
        balanceLowNotificationsOn?: boolean | null
        balanceLowChannels?: Array<UserNotificationChannel | null> | null
        lastBalanceLowNotificationSent?: any | null
        budgetLowNotificationsOn?: boolean | null
        budgetLowChannels?: Array<UserNotificationChannel | null> | null
        lastBudgetLowNotificationSent?: any | null
        giftEmailAcceptedNotificationsOn?: boolean | null
        giftEmailAcceptedChannels?: Array<UserNotificationChannel | null> | null
        magicLinkAcceptedNotificationsOn?: boolean | null
        magicLinkAcceptedChannels?: Array<UserNotificationChannel | null> | null
        orderDeliveredNotificationsOn?: boolean | null
        orderDeliveredChannels?: Array<UserNotificationChannel | null> | null
        thankYouNotificationsOn?: boolean | null
        thankYouChannels?: Array<UserNotificationChannel> | null
        landingPageExceptionNotificationOn?: boolean | null
        landingPageExceptionChannels?: Array<UserNotificationChannel> | null
        autoReloadFailuresNotificationsOn?: boolean | null
        autoReloadFailuresChannels?: Array<UserNotificationChannel | null> | null
        orderIssuesNotificationsOn?: boolean | null
        orderIssuesChannels?: Array<UserNotificationChannel | null> | null
      } | null
      emailDigestSettings?: {
        __typename?: 'EmailDigestSettings'
        processingErrors?: boolean | null
        ordersRequiringUserApproval?: boolean | null
        itemOutOfStock?: boolean | null
        itemLowStock?: boolean | null
        marketingUpdates?: boolean | null
        newItemApproved?: boolean | null
        newCollectionApproved?: boolean | null
        newEventApproved?: boolean | null
        itemRemoved?: boolean | null
        frequency?: EmailDigestFrequency | null
      } | null
    }> | null
    resultsSummary: {
      __typename?: 'ResultsSummary'
      hasMore: boolean
      currentPage: number
      totalRecords?: number | null
      totalPages?: number | null
    }
  }
}

export type TeamsQueryVariables = Exact<{
  filter?: InputMaybe<TeamFilterInput>
  orderBy?: InputMaybe<TeamOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type TeamsQuery = {
  __typename?: 'Query'
  teams?: Array<{
    __typename?: 'Team'
    id: string
    name: string
    status: Status
    accountId: string
    parentTeamId?: string | null
    settings?: {
      __typename?: 'Settings'
      billingAccountId?: string | null
      billingAccountIds?: Array<{
        __typename?: 'BillingAccountMap'
        currency: Currency
        billingAccountId: string
      }> | null
      budget?: { __typename?: 'Budget'; amount: number; mode: BudgetMode; duration: BudgetDuration } | null
    } | null
    created?: { __typename?: 'ActionRecord'; actor?: string | null; dateTime?: any | null } | null
  }> | null
}

export type TriggerAnalyticsQueryVariables = Exact<{
  granularity: Granularity
  teamId?: InputMaybe<Scalars['ObjectId']['input']>
  userId?: InputMaybe<Scalars['ObjectId']['input']>
  triggerId?: InputMaybe<Scalars['ObjectId']['input']>
  startDate?: InputMaybe<Scalars['Instant']['input']>
  endDate?: InputMaybe<Scalars['Instant']['input']>
}>

export type TriggerAnalyticsQuery = {
  __typename?: 'Query'
  triggerAnalytics?: Array<{
    __typename?: 'BasicMetrics'
    queued?: number | null
    failed?: number | null
    sent?: number | null
    deliveryEmailAccepted?: number | null
    deliveryEmailSent?: number | null
    deliveryEmailOpened?: number | null
    deliveryEmailClicked?: number | null
    deliveryEmailDonated?: number | null
    deliveryEmailDeclined?: number | null
    deliveryEmailUnsubscribed?: number | null
    deliveryEmailFailed?: number | null
    deliveryEmailExpired?: number | null
    accepted?: number | null
    delivered?: number | null
    bounces?: number | null
    touches?: number | null
    costInCents?: number | null
    gmvUSD?: number | null
    gmvCAD?: number | null
    gmvEUR?: number | null
    gmvGBP?: number | null
    started?: number | null
    completed?: number | null
    cancelled?: number | null
    successful?: number | null
    campaignsSent?: number | null
    playbooksStarted?: number | null
    triggersExecuted?: number | null
    linkTotalViews?: number | null
    linkUniqViews?: number | null
    linkMaxExeViews?: number | null
    linkExpiredViews?: number | null
    linkExecutions?: number | null
    fundsAdded?: number | null
    eventsBooked?: number | null
    meetingsBooked?: number | null
    thankYou?: number | null
    socialShare?: number | null
    newContact?: number | null
    linkNewContact?: number | null
    activeMagicLinks?: number | null
    date?: any | null
  }> | null
}

export type UserAnalyticsV2QueryVariables = Exact<{
  config: AnalyticsRequestConfig
}>

export type UserAnalyticsV2Query = {
  __typename?: 'Query'
  userAnalyticsV2?: Array<{
    __typename?: 'UserAnalytics'
    firstName?: string | null
    lastName?: string | null
    userName?: string | null
    teamName?: string | null
    userId?: string | null
    teamId?: string | null
    queued?: number | null
    failed?: number | null
    sent?: number | null
    deliveryEmailAccepted?: number | null
    deliveryEmailSent?: number | null
    deliveryEmailOpened?: number | null
    deliveryEmailClicked?: number | null
    deliveryEmailDonated?: number | null
    deliveryEmailDeclined?: number | null
    deliveryEmailUnsubscribed?: number | null
    deliveryEmailFailed?: number | null
    deliveryEmailExpired?: number | null
    accepted?: number | null
    delivered?: number | null
    bounces?: number | null
    touches?: number | null
    costInCents?: number | null
    gmvUSD?: number | null
    gmvCAD?: number | null
    gmvEUR?: number | null
    gmvGBP?: number | null
    started?: number | null
    completed?: number | null
    cancelled?: number | null
    successful?: number | null
    campaignsSent?: number | null
    playbooksStarted?: number | null
    triggersExecuted?: number | null
    linkTotalViews?: number | null
    linkUniqViews?: number | null
    linkMaxExeViews?: number | null
    linkExpiredViews?: number | null
    linkExecutions?: number | null
    fundsAdded?: number | null
    eventsBooked?: number | null
    meetingsBooked?: number | null
    thankYou?: number | null
    socialShare?: number | null
    newContact?: number | null
    linkNewContact?: number | null
    activeMagicLinks?: number | null
    date?: any | null
  }> | null
}

export type VerifyAddressQueryVariables = Exact<{
  data: VerifyAddressInput
}>

export type VerifyAddressQuery = {
  __typename?: 'Query'
  verifyAddress: {
    __typename?: 'Address'
    entryName?: string | null
    address1?: string | null
    address2?: string | null
    address3?: string | null
    city?: string | null
    state?: string | null
    postalCode?: string | null
    country?: string | null
    preferred: boolean
    source?: AddressSource | null
    status?: AddressStatus | null
    statusReason?: string | null
    systems?: Array<string> | null
    uspsAddress?: {
      __typename?: 'Address'
      entryName?: string | null
      address1?: string | null
      address2?: string | null
      address3?: string | null
      city?: string | null
      state?: string | null
      postalCode?: string | null
      country?: string | null
      preferred: boolean
      source?: AddressSource | null
      status?: AddressStatus | null
      statusReason?: string | null
      systems?: Array<string> | null
      uspsAddress?: {
        __typename?: 'Address'
        entryName?: string | null
        address1?: string | null
        address2?: string | null
        address3?: string | null
        city?: string | null
        state?: string | null
        postalCode?: string | null
        country?: string | null
        preferred: boolean
        source?: AddressSource | null
        status?: AddressStatus | null
        statusReason?: string | null
        systems?: Array<string> | null
        uspsAddress?: {
          __typename?: 'Address'
          entryName?: string | null
          address1?: string | null
          address2?: string | null
          address3?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          country?: string | null
          preferred: boolean
          source?: AddressSource | null
          status?: AddressStatus | null
          statusReason?: string | null
          systems?: Array<string> | null
          uspsAddress?: {
            __typename?: 'Address'
            entryName?: string | null
            address1?: string | null
            address2?: string | null
            address3?: string | null
            city?: string | null
            state?: string | null
            postalCode?: string | null
            country?: string | null
            preferred: boolean
            source?: AddressSource | null
            status?: AddressStatus | null
            statusReason?: string | null
            systems?: Array<string> | null
          } | null
        } | null
      } | null
    } | null
  }
}

export type VerifyEmailConnectionQueryVariables = Exact<{
  ssoMethodName: Scalars['String']['input']
}>

export type VerifyEmailConnectionQuery = { __typename?: 'Query'; verifyEmailConnection: boolean }

export const AddFundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addFunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingAccountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addFunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billingAccountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'billingAccountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'amount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddFundsMutation, AddFundsMutationVariables>
export const AddFundsV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addFundsV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddFundsV2Input' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addFundsV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddFundsV2Mutation, AddFundsV2MutationVariables>
export const AddProductAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addProductAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roles' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendNotification' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'product' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addProductAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roles' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roles' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sendNotification' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sendNotification' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'product' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'product' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountLockedUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationFailures' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auth' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'crmUserMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionNotificationOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesChannels' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailDigestSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'processingErrors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersRequiringUserApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemOutOfStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemLowStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketingUpdates' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newItemApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newCollectionApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newEventApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemRemoved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddProductAccessMutation, AddProductAccessMutationVariables>
export const AdjustUserBudgetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adjustUserBudget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AdjustUserBudgetInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adjustUserBudget' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'budgetRemaining' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSpend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalAdjustments' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicableBudget' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'budget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdjustUserBudgetMutation, AdjustUserBudgetMutationVariables>
export const BulkApproveOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkApproveOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fulfillmentIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkApproveOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fulfillmentIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fulfillmentIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkApproveOrdersMutation, BulkApproveOrdersMutationVariables>
export const BulkContactAddToCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkContactAddToCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'campaignId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'campaignName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkContactAddToCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'campaignId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'campaignName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'v2filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orfilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkContactAddToCampaignMutation, BulkContactAddToCampaignMutationVariables>
export const BulkContactAddToListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkContactAddToList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactListMapInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkContactAddToList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'v2filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orfilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkContactAddToListMutation, BulkContactAddToListMutationVariables>
export const BulkContactAddToPlaybookDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkContactAddToPlaybook' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'playbookDefinitionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkContactAddToPlaybook' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'playbookDefinitionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'playbookDefinitionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'v2filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orfilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkContactAddToPlaybookMutation, BulkContactAddToPlaybookMutationVariables>
export const BulkContactDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkContactDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkContactDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'v2filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orfilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkContactDeleteMutation, BulkContactDeleteMutationVariables>
export const BulkContactRemoveFromCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkContactRemoveFromCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'campaignId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkContactRemoveFromCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'campaignId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'v2filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orfilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkContactRemoveFromCampaignMutation, BulkContactRemoveFromCampaignMutationVariables>
export const BulkContactRemoveFromListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkContactRemoveFromList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactListMapInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkContactRemoveFromList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'v2filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orfilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkContactRemoveFromListMutation, BulkContactRemoveFromListMutationVariables>
export const BulkContactUpdatePlaybookStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkContactUpdatePlaybookStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'playbookDefinitionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'playbookStatus' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlaybookStatus' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkContactUpdatePlaybookStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'playbookDefinitionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'playbookDefinitionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'playbookStatus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'playbookStatus' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'v2filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orfilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkContactUpdatePlaybookStatusMutation, BulkContactUpdatePlaybookStatusMutationVariables>
export const BulkCreateApprovedPostalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkCreateApprovedPostal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'marketplaceProductIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BulkApprovedPostalUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkCreateApprovedPostal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketplaceProductIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'marketplaceProductIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkCreateApprovedPostalMutation, BulkCreateApprovedPostalMutationVariables>
export const BulkDenyOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkDenyOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fulfillmentIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkDenyOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fulfillmentIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fulfillmentIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkDenyOrdersMutation, BulkDenyOrdersMutationVariables>
export const BulkEditApprovedPostalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkEditApprovedPostal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BulkApprovedPostalUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkEditApprovedPostal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvedPostalIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkEditApprovedPostalMutation, BulkEditApprovedPostalMutationVariables>
export const BulkPlaybookStatusUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkPlaybookStatusUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'playbookStatus' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlaybookStatus' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlaybookInstanceFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkPlaybookStatusUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'playbookStatus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'playbookStatus' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkPlaybookStatusUpdateMutation, BulkPlaybookStatusUpdateMutationVariables>
export const BulkProductAccessAddDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkProductAccessAdd' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductAccessInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendNotification' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkProductAccessAdd' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sendNotification' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sendNotification' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkProductAccessAddMutation, BulkProductAccessAddMutationVariables>
export const BulkProductAccessRemoveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkProductAccessRemove' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkProductAccessRemove' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkProductAccessRemoveMutation, BulkProductAccessRemoveMutationVariables>
export const BulkProductAccessUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkProductAccessUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductAccessInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendNotification' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkProductAccessUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sendNotification' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sendNotification' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkProductAccessUpdateMutation, BulkProductAccessUpdateMutationVariables>
export const BulkRetryFailedAbmCampaignOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkRetryFailedABMCampaignOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'campaignId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skipDuplicateOrderCheck' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkRetryFailedABMCampaignOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'campaignId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skipDuplicateOrderCheck' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skipDuplicateOrderCheck' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BulkRetryFailedAbmCampaignOrdersMutation,
  BulkRetryFailedAbmCampaignOrdersMutationVariables
>
export const BulkTagAddDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkTagAdd' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'TagInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkTagAdd' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'v2filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orfilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkTagAddMutation, BulkTagAddMutationVariables>
export const BulkTagRemoveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkTagRemove' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'TagInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkTagRemove' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'v2filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orfilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkTagRemoveMutation, BulkTagRemoveMutationVariables>
export const CancelBackgroundTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelBackgroundTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelBackgroundTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelBackgroundTaskMutation, CancelBackgroundTaskMutationVariables>
export const CancelEventRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelEventRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelEventRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelEventRequestMutation, CancelEventRequestMutationVariables>
export const CancelOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelOrderMutation, CancelOrderMutationVariables>
export const CancelTransferIntentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelTransferIntent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelTransferIntent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCreditMemoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCreditMemoNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromDebitMemoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromDebitMemoNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fxInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'apiProvider' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'history' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelTransferIntentMutation, CancelTransferIntentMutationVariables>
export const ChangePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'changePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChangePasswordInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChangePasswordMutation, ChangePasswordMutationVariables>
export const CompleteDataImportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'completeDataImport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FileDataMappingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeDataImport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompleteDataImportMutation, CompleteDataImportMutationVariables>
export const CompleteThirdPartyTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'completeThirdPartyTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeThirdPartyTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'system' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'taskId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompleteThirdPartyTaskMutation, CompleteThirdPartyTaskMutationVariables>
export const ConfirmTransferIntentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'confirmTransferIntent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'localTimeZone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmTransferIntent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'localTimeZone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'localTimeZone' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCreditMemoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCreditMemoNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromDebitMemoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromDebitMemoNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fxInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'apiProvider' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'history' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmTransferIntentMutation, ConfirmTransferIntentMutationVariables>
export const ContactEventsTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'contactEventsTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactEventsRequestType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestedDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactEventsTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvedPostalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestedDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestedDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'eventsEmailFailed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vendorEmailFailed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactEventsTeamMutation, ContactEventsTeamMutationVariables>
export const CreateApprovedPostalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createApprovedPostal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'marketplaceProductId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApprovedPostalUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createApprovedPostal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketplaceProductId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'marketplaceProductId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'postal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subCategory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useCases' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrls' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantOrderFlexibility' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrls' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'flatFees' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displaySubscriberPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shippingPriceEstimateMin' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shippingPriceEstimateMax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'giftMessageSupported' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'physicalMessageSupported' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'containsPerishables' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'designTemplate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'front' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resize' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'boundary' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'back' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resize' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'boundary' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'dpi' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outputSize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'event' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'requestedByName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requestedByEmail' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requestedByPhone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requestedByMessage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requestedAttendeeCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'spotsRemaining' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'calendarRequestedDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requestedDates' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventDateTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventLengthMinutes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastRegistrationDateTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendEmailConfirmation' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendReminderDayBefore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendReminderDayOf' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendCancelledAlert' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendInviteExpiringAlert' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendMeetingLinkChanged' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendDateTimeChanged' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendShippedAlert' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendDeliveredAlert' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'confirmationCustomization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reminderTomorrowCustomization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reminderTodayCustomization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cancellationCustomization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'meetingLinkChangedCustomization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateTimeChangedCustomization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'eventLengthMinutes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimumAttendees' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximumAttendees' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requirements' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'talentEmailAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'talentName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'talentPhoneNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'talentCompany' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attribution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizableBySender' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizableByRecipient' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'characterLimit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'widthRecommendation' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'heightRecommendation' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileTypesAccepted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'geographicMarkets' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventFeeSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'flatFee' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewGenerationTask' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateApprovedPostalMutation, CreateApprovedPostalMutationVariables>
export const CreateBillingAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBillingAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateBillingAccountInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBillingAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedAccountIdentifier' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentProcessing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentBackgroundTaskId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPrepaymentAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadLowLimit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultPaymentMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentPartnerType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadOk' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailures' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFailedAutoReloadAttempt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastUnlocked' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'incomingInvoiceAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'incomingInvoiceActionRecord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingContactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentPartners' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentMethods' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingDetails' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'card' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationMonth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationYear' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastSyncedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'initialNetsuiteBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'initializedAt' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBillingAccountMutation, CreateBillingAccountMutationVariables>
export const CreateBlocklistEntryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBlocklistEntry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BlocklistEntryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBlocklistEntry' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataObjectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filterType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBlocklistEntryMutation, CreateBlocklistEntryMutationVariables>
export const CreateCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CampaignInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduleDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundTaskId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'designTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'front' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'back' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'dpi' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outputSize' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContacts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContactsAttempted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCampaignMutation, CreateCampaignMutationVariables>
export const CreateContactListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createContactList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactListInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createContactList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateContactListMutation, CreateContactListMutationVariables>
export const CreateEmailPreviewUrlsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEmailPreviewUrls' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmailPreviewRequestInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'renderSubjectLine' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEmailPreviewUrls' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'renderSubjectLine' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'renderSubjectLine' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'htmlUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previewType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEmailPreviewUrlsMutation, CreateEmailPreviewUrlsMutationVariables>
export const CreateFavoriteItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFavoriteItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FavoriteItemInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFavoriteItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFavoriteItemMutation, CreateFavoriteItemMutationVariables>
export const CreateMagicLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMagicLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MagicLinkInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMagicLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxExecutions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'distributionChannel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastExecution' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'requiresApproval' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requiresUuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMagicLinkMutation, CreateMagicLinkMutationVariables>
export const CreateMessageTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMessageTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'NewMessageTemplateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMessageTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templateText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedWith' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'target' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>
export const CreatePlaybookDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPlaybook' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'playbookDefinitionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPlaybook' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'playbookDefinitionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'playbookDefinitionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errMessage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'steps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delayUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spendAs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationInputs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPageCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientEmailSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextStepToExecute' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextExecutionDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePlaybookMutation, CreatePlaybookMutationVariables>
export const CreatePlaybookDefinitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPlaybookDefinition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlaybookDefinitionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPlaybookDefinition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'steps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delayUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displaySubscriberPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spendAs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationInputs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPageCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientEmailSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePlaybookDefinitionMutation, CreatePlaybookDefinitionMutationVariables>
export const CreateSampleMarketplaceDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSampleMarketplaceData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'createSampleMarketplaceData' } }],
      },
    },
  ],
} as unknown as DocumentNode<CreateSampleMarketplaceDataMutation, CreateSampleMarketplaceDataMutationVariables>
export const CreateSavedSendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSavedSend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SavedSendInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSavedSend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'savedSendName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendFlowStep' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commonSendProperties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spendAs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationInputs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPageCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientEmailSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendMagicLinkInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requiresApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendCampaignInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useSameMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduleDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactSearchFilters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactCount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendBulkSendInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipToAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressVerified' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newContact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phones' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSavedSendMutation, CreateSavedSendMutationVariables>
export const CreateSetupIntentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSetupIntent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingAccountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSetupIntent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billingAccountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'billingAccountId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>
export const CreateTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'TagInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTagMutation, CreateTagMutationVariables>
export const CreateTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateTeamInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentTeamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingAccountIds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'budget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTeamMutation, CreateTeamMutationVariables>
export const CreateTransferIntentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTransferIntent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateTransferIntentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTransferIntent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCreditMemoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCreditMemoNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromDebitMemoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromDebitMemoNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fxInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'apiProvider' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'history' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTransferIntentMutation, CreateTransferIntentMutationVariables>
export const CreateUpdateIntegrationTriggerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUpdateIntegrationTrigger' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationTriggerInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUpdateIntegrationTrigger' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'systemName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filter' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalVariantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hitCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sequenceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sequenceStepId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'instanceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipeName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUpdateIntegrationTriggerMutation, CreateUpdateIntegrationTriggerMutationVariables>
export const DeleteApprovedPostalAndAllAutomationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteApprovedPostalAndAllAutomations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteApprovedPostalAndAllAutomations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvedPostalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteApprovedPostalAndAllAutomationsMutation,
  DeleteApprovedPostalAndAllAutomationsMutationVariables
>
export const DeleteBillingAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteBillingAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBillingAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedAccountIdentifier' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentProcessing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentBackgroundTaskId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPrepaymentAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadLowLimit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultPaymentMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentPartnerType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadOk' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailures' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFailedAutoReloadAttempt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastUnlocked' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'incomingInvoiceAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'incomingInvoiceActionRecord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingContactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentPartners' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentMethods' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingDetails' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'card' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationMonth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationYear' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastSyncedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'initialNetsuiteBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'initializedAt' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteBillingAccountMutation, DeleteBillingAccountMutationVariables>
export const DeleteBlocklistEntryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteBlocklistEntry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBlocklistEntry' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataObjectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filterType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteBlocklistEntryMutation, DeleteBlocklistEntryMutationVariables>
export const DeleteCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduleDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundTaskId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'designTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'front' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'back' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'dpi' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outputSize' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContacts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContactsAttempted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCampaignMutation, DeleteCampaignMutationVariables>
export const DeleteContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phones' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ownerLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastActivity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaigns' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'processed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFulfillmentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'playbooks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookInstanceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextStepToExecute' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasActivePlaybooks' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteContactMutation, DeleteContactMutationVariables>
export const DeleteContactListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteContactList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteContactList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteContactListMutation, DeleteContactListMutationVariables>
export const DeleteContactUnsubscribeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteContactUnsubscribe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteContactUnsubscribe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteContactUnsubscribeMutation, DeleteContactUnsubscribeMutationVariables>
export const DeleteExternalCampaignMappingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteExternalCampaignMapping' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'campaignId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteExternalCampaignMapping' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'system' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'campaignId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteExternalCampaignMappingMutation, DeleteExternalCampaignMappingMutationVariables>
export const DeleteFavoriteItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFavoriteItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFavoriteItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFavoriteItemMutation, DeleteFavoriteItemMutationVariables>
export const DeleteIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'systemName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'systemName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'systemName' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>
export const DeleteIntegrationTriggerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteIntegrationTrigger' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteIntegrationTrigger' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteIntegrationTriggerMutation, DeleteIntegrationTriggerMutationVariables>
export const DeleteInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invitedByUserName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteInviteMutation, DeleteInviteMutationVariables>
export const DeleteMagicLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteMagicLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMagicLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxExecutions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'distributionChannel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastExecution' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'requiresApproval' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requiresUuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteMagicLinkMutation, DeleteMagicLinkMutationVariables>
export const DeleteMessageTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteMessageTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMessageTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templateText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedWith' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'target' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>
export const DeleteSavedSendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteSavedSend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSavedSend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'savedSendName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendFlowStep' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commonSendProperties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spendAs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationInputs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPageCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientEmailSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendMagicLinkInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requiresApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendCampaignInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useSameMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduleDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactSearchFilters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactCount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendBulkSendInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipToAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressVerified' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newContact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phones' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSavedSendMutation, DeleteSavedSendMutationVariables>
export const DeleteTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTagMutation, DeleteTagMutationVariables>
export const DeleteTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTeamMutation, DeleteTeamMutationVariables>
export const DeleteUserActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteUserAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserAction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserActionMutation, DeleteUserActionMutationVariables>
export const DeleteUserAuthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteUserAuth' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authMethod' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserAuth' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authMethod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authMethod' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserAuthMutation, DeleteUserAuthMutationVariables>
export const ExecuteOrderEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'executeOrderEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fulfillmentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'eventName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderEventNames' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'executeOrderEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fulfillmentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fulfillmentId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eventName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'eventName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipTo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToCompanyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'magicLinkId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'magicLinkName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previewImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYouMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerOrderId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cost' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'history' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentLocation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPartner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'failureReason' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableBySender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableByRecipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'characterLimit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'widthRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileTypesAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldValueList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalRequiredInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'approvalRequiredReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoActionDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actualCost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingCompany' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExecuteOrderEventMutation, ExecuteOrderEventMutationVariables>
export const GenerateContactUnsubscribeReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateContactUnsubscribeReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactUnsubscribeFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateContactUnsubscribeReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateContactUnsubscribeReportMutation,
  GenerateContactUnsubscribeReportMutationVariables
>
export const GenerateLedgerReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateLedgerReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LedgerFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateLedgerReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateLedgerReportMutation, GenerateLedgerReportMutationVariables>
export const GenerateOrderReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateOrderReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateOrderReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateOrderReportMutation, GenerateOrderReportMutationVariables>
export const InviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'invite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InviteUserInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invite' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invitedByUserName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteMutation, InviteMutationVariables>
export const OrderPostalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'orderPostal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalOrderRequestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderPostal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipTo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToCompanyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'magicLinkId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'magicLinkName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previewImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYouMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerOrderId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cost' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'history' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentLocation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPartner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'failureReason' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableBySender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableByRecipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'characterLimit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'widthRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileTypesAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldValueList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalRequiredInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'approvalRequiredReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoActionDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actualCost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingCompany' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderPostalMutation, OrderPostalMutationVariables>
export const ProductRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'productRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reason' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductRequestMutation, ProductRequestMutationVariables>
export const RecordUserActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'recordUserAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recordUserAction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecordUserActionMutation, RecordUserActionMutationVariables>
export const RemoveProductAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeProductAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productAccessId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeProductAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productAccessId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productAccessId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountLockedUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationFailures' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auth' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'crmUserMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionNotificationOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesChannels' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailDigestSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'processingErrors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersRequiringUserApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemOutOfStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemLowStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketingUpdates' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newItemApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newCollectionApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newEventApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemRemoved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveProductAccessMutation, RemoveProductAccessMutationVariables>
export const RemoveUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productAccessId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productAccessId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productAccessId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountLockedUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationFailures' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auth' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'crmUserMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionNotificationOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesChannels' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailDigestSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'processingErrors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersRequiringUserApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemOutOfStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemLowStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketingUpdates' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newItemApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newCollectionApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newEventApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemRemoved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveUserMutation, RemoveUserMutationVariables>
export const RemoveUserTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeUserTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productAccessId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productAccessId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productAccessId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountLockedUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationFailures' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auth' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'crmUserMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionNotificationOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesChannels' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailDigestSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'processingErrors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersRequiringUserApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemOutOfStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemLowStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketingUpdates' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newItemApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newCollectionApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newEventApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemRemoved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveUserTeamMutation, RemoveUserTeamMutationVariables>
export const RequestAddFundsByInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestAddFundsByInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amountCents' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingAccountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestAddFundsByInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'amountCents' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'amountCents' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billingAccountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'billingAccountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestAddFundsByInvoiceMutation, RequestAddFundsByInvoiceMutationVariables>
export const RequestAddFundsByInvoiceV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestAddFundsByInvoiceV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestFundsByInvoiceV2Request' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestAddFundsByInvoiceV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'addFundsIntentId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestAddFundsByInvoiceV2Mutation, RequestAddFundsByInvoiceV2MutationVariables>
export const ResetOktaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resetOkta' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetOkta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domains' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seats' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brandingLogoEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seatsAssigned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryLogoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingAccountIds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'budget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trialSubscription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryEmailSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bulkSendEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disclaimerInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailFont' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailPrimaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailSecondaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTertiaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationThreshold' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationThreshold' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountContactSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'redactAddress' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oktaIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oneLoginIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionTimeoutMinutes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'forceAuthenticationMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewMarketplace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewEvents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sfdcPackageVersion' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkApprovalSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoActionDaysAfter' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fiscalQuarterSetup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuration' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brandingInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'whiteLogoUrl' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetOktaMutation, ResetOktaMutationVariables>
export const ResetOneLoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resetOneLogin' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetOneLogin' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domains' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seats' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brandingLogoEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seatsAssigned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryLogoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingAccountIds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'budget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trialSubscription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryEmailSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bulkSendEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disclaimerInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailFont' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailPrimaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailSecondaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTertiaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationThreshold' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationThreshold' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountContactSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'redactAddress' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oktaIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oneLoginIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionTimeoutMinutes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'forceAuthenticationMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewMarketplace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewEvents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sfdcPackageVersion' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkApprovalSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoActionDaysAfter' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fiscalQuarterSetup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuration' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brandingInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'whiteLogoUrl' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetOneLoginMutation, ResetOneLoginMutationVariables>
export const RetryBackgroundTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'retryBackgroundTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retryBackgroundTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RetryBackgroundTaskMutation, RetryBackgroundTaskMutationVariables>
export const SaveAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assetType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveAssetMutation, SaveAssetMutationVariables>
export const SaveExternalCampaignMappingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveExternalCampaignMapping' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'campaignId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mapping' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ExtCampaignMappingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveExternalCampaignMapping' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'system' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'campaignId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mapping' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mapping' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'setCampaignCost' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'opened' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accepted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'queued' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipped' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'delivered' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bounced' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meetingBooked' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'expired' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'failed' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'declined' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'donated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveExternalCampaignMappingMutation, SaveExternalCampaignMappingMutationVariables>
export const SendForgotPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendForgotPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendForgotPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendForgotPasswordMutation, SendForgotPasswordMutationVariables>
export const SetDefaultPaymentMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setDefaultPaymentMethod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SetDefaultPaymentMethodInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDefaultPaymentMethod' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedAccountIdentifier' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentProcessing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentBackgroundTaskId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPrepaymentAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadLowLimit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultPaymentMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentPartnerType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadOk' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailures' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFailedAutoReloadAttempt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastUnlocked' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'incomingInvoiceAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'incomingInvoiceActionRecord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingContactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentPartners' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentMethods' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingDetails' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'card' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationMonth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationYear' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastSyncedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'initialNetsuiteBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'initializedAt' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetDefaultPaymentMethodMutation, SetDefaultPaymentMethodMutationVariables>
export const SetupCreditCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setupCreditCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingAccountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'card' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreditCardPaymentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setupCreditCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billingAccountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'billingAccountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'card' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'card' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetupCreditCardMutation, SetupCreditCardMutationVariables>
export const SetupCustomIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setupCustomIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'systemName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomIntegrationNames' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'baseUrl' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setupCustomIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'systemName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'systemName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientSecret' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientSecret' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'baseUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'baseUrl' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastRun' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextRun' } },
                { kind: 'Field', name: { kind: 'Name', value: 'syncTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pollingTimeMins' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'postalIoFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'staticValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remap' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pushMappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'postalIoFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'staticValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remap' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'workStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createCustomFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadSourceFieldName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultLeadSourceValue' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetupCustomIntegrationMutation, SetupCustomIntegrationMutationVariables>
export const SetupDataSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setupDataSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'systemName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setupDataSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'systemName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'systemName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastRun' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextRun' } },
                { kind: 'Field', name: { kind: 'Name', value: 'syncTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pollingTimeMins' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'postalIoFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'staticValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remap' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pushMappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'postalIoFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'staticValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remap' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'workStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createCustomFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadSourceFieldName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultLeadSourceValue' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetupDataSourceMutation, SetupDataSourceMutationVariables>
export const SetupOktaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setupOkta' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OktaIntegrationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setupOkta' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domains' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seats' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brandingLogoEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seatsAssigned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryLogoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingAccountIds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'budget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trialSubscription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryEmailSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bulkSendEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disclaimerInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailFont' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailPrimaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailSecondaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTertiaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationThreshold' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationThreshold' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountContactSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'redactAddress' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oktaIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oneLoginIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionTimeoutMinutes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'forceAuthenticationMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewMarketplace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewEvents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sfdcPackageVersion' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkApprovalSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoActionDaysAfter' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fiscalQuarterSetup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuration' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brandingInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'whiteLogoUrl' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetupOktaMutation, SetupOktaMutationVariables>
export const SetupOneLoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setupOneLogin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OneLoginIntegrationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setupOneLogin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domains' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seats' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brandingLogoEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seatsAssigned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryLogoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingAccountIds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'budget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trialSubscription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryEmailSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bulkSendEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disclaimerInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailFont' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailPrimaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailSecondaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTertiaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationThreshold' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationThreshold' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountContactSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'redactAddress' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oktaIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oneLoginIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionTimeoutMinutes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'forceAuthenticationMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewMarketplace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewEvents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sfdcPackageVersion' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkApprovalSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoActionDaysAfter' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fiscalQuarterSetup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuration' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brandingInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'whiteLogoUrl' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetupOneLoginMutation, SetupOneLoginMutationVariables>
export const SignupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'signup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SignupInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domains' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seats' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brandingLogoEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seatsAssigned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryLogoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingAccountIds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'budget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trialSubscription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryEmailSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bulkSendEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disclaimerInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailFont' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailPrimaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailSecondaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTertiaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationThreshold' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationThreshold' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountContactSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'redactAddress' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oktaIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oneLoginIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionTimeoutMinutes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'forceAuthenticationMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewMarketplace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewEvents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sfdcPackageVersion' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkApprovalSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoActionDaysAfter' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fiscalQuarterSetup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuration' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brandingInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'whiteLogoUrl' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignupMutation, SignupMutationVariables>
export const StartRecipeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'startRecipe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startRecipe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StartRecipeMutation, StartRecipeMutationVariables>
export const StartTrialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'startTrial' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startTrial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StartTrialMutation, StartTrialMutationVariables>
export const StopRecipeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'stopRecipe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopRecipe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StopRecipeMutation, StopRecipeMutationVariables>
export const SubmitFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'submitForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'FormType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'variables' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Object' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'variables' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'variables' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitFormMutation, SubmitFormMutationVariables>
export const SyncBillingAccountPaymentPartnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'syncBillingAccountPaymentPartner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncBillingAccountPaymentPartner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedAccountIdentifier' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentProcessing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentBackgroundTaskId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPrepaymentAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadLowLimit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultPaymentMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentPartnerType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadOk' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailures' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFailedAutoReloadAttempt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastUnlocked' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'incomingInvoiceAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'incomingInvoiceActionRecord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingContactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentPartners' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentMethods' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingDetails' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'card' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationMonth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationYear' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastSyncedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'initialNetsuiteBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'initializedAt' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SyncBillingAccountPaymentPartnerMutation,
  SyncBillingAccountPaymentPartnerMutationVariables
>
export const UpdateAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAccountInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domains' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seats' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brandingLogoEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seatsAssigned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryLogoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingAccountIds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'budget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trialSubscription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryEmailSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bulkSendEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disclaimerInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailFont' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailPrimaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailSecondaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTertiaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationThreshold' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationThreshold' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountContactSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'redactAddress' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oktaIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oneLoginIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionTimeoutMinutes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'forceAuthenticationMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewMarketplace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewEvents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sfdcPackageVersion' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkApprovalSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoActionDaysAfter' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fiscalQuarterSetup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuration' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brandingInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'whiteLogoUrl' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAccountMutation, UpdateAccountMutationVariables>
export const UpdateApprovedPostalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateApprovedPostal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApprovedPostalUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateApprovedPostal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'postal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subCategory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useCases' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrls' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantOrderFlexibility' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrls' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'flatFees' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displaySubscriberPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shippingPriceEstimateMin' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shippingPriceEstimateMax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'giftMessageSupported' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'physicalMessageSupported' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'containsPerishables' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'designTemplate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'front' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resize' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'boundary' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'back' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resize' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'boundary' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'dpi' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outputSize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'event' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'requestedByName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requestedByEmail' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requestedByPhone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requestedByMessage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requestedAttendeeCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'spotsRemaining' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'calendarRequestedDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requestedDates' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventDateTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventLengthMinutes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastRegistrationDateTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendEmailConfirmation' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendReminderDayBefore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendReminderDayOf' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendCancelledAlert' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendInviteExpiringAlert' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendMeetingLinkChanged' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendDateTimeChanged' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendShippedAlert' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sendDeliveredAlert' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'confirmationCustomization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reminderTomorrowCustomization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reminderTodayCustomization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cancellationCustomization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'meetingLinkChangedCustomization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateTimeChangedCustomization' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'eventLengthMinutes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimumAttendees' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximumAttendees' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'requirements' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'talentEmailAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'talentName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'talentPhoneNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'talentCompany' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attribution' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizableBySender' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizableByRecipient' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'characterLimit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'widthRecommendation' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'heightRecommendation' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileTypesAccepted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'geographicMarkets' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventFeeSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'flatFee' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewGenerationTask' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateApprovedPostalMutation, UpdateApprovedPostalMutationVariables>
export const UpdateAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAssetMutation, UpdateAssetMutationVariables>
export const UpdateBillingAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBillingAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateBillingAccountInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBillingAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedAccountIdentifier' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentProcessing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentBackgroundTaskId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPrepaymentAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadLowLimit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultPaymentMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentPartnerType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadOk' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailures' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFailedAutoReloadAttempt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastUnlocked' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'incomingInvoiceAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'incomingInvoiceActionRecord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingContactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentPartners' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentMethods' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingDetails' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'card' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationMonth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationYear' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastSyncedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'initialNetsuiteBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'initializedAt' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBillingAccountMutation, UpdateBillingAccountMutationVariables>
export const UpdateBlocklistEntryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBlocklistEntry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BlocklistEntryInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBlocklistEntry' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataObjectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filterType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBlocklistEntryMutation, UpdateBlocklistEntryMutationVariables>
export const UpdateCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CampaignInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduleDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundTaskId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'designTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'front' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'back' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'dpi' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outputSize' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContacts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContactsAttempted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCampaignMutation, UpdateCampaignMutationVariables>
export const UpdateContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phones' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ownerLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastActivity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaigns' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'processed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFulfillmentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'playbooks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookInstanceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextStepToExecute' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasActivePlaybooks' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateContactMutation, UpdateContactMutationVariables>
export const UpdateContactListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateContactList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactListInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateContactList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateContactListMutation, UpdateContactListMutationVariables>
export const UpdateIntegrationSyncDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateIntegrationSync' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationSyncInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIntegrationSync' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastRun' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextRun' } },
                { kind: 'Field', name: { kind: 'Name', value: 'syncTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pollingTimeMins' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'postalIoFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'staticValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remap' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pushMappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'postalIoFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'staticValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remap' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'workStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createCustomFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadSourceFieldName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultLeadSourceValue' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateIntegrationSyncMutation, UpdateIntegrationSyncMutationVariables>
export const UpdateIntegrationSyncStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateIntegrationSyncStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Status' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIntegrationSyncStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastRun' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextRun' } },
                { kind: 'Field', name: { kind: 'Name', value: 'syncTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pollingTimeMins' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'postalIoFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'staticValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remap' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pushMappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'postalIoFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'staticValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remap' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'workStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createCustomFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadSourceFieldName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultLeadSourceValue' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateIntegrationSyncStatusMutation, UpdateIntegrationSyncStatusMutationVariables>
export const UpdateMagicLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMagicLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MagicLinkUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMagicLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxExecutions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'distributionChannel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastExecution' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'requiresApproval' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requiresUuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMagicLinkMutation, UpdateMagicLinkMutationVariables>
export const UpdateMessageTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMessageTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMessageTemplateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMessageTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templateText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedWith' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'target' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>
export const UpdatePlaybookDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePlaybook' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlaybookInstanceUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePlaybook' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errMessage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'steps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delayUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spendAs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationInputs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPageCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientEmailSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextStepToExecute' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextExecutionDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePlaybookMutation, UpdatePlaybookMutationVariables>
export const UpdatePlaybookDefinitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePlaybookDefinition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlaybookDefinitionUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePlaybookDefinition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'steps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delayUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displaySubscriberPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spendAs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationInputs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPageCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientEmailSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePlaybookDefinitionMutation, UpdatePlaybookDefinitionMutationVariables>
export const UpdateRolePermissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateRolePermissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addPermissions' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'removePermissions' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRolePermissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'role' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addPermissions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'addPermissions' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'removePermissions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'removePermissions' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'roleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'available' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRolePermissionsMutation, UpdateRolePermissionsMutationVariables>
export const UpdateRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productAccessId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roles' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendNotification' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productAccessId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productAccessId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roles' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roles' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sendNotification' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sendNotification' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountLockedUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationFailures' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auth' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'crmUserMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionNotificationOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesChannels' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailDigestSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'processingErrors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersRequiringUserApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemOutOfStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemLowStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketingUpdates' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newItemApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newCollectionApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newEventApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemRemoved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRolesMutation, UpdateRolesMutationVariables>
export const UpdateRolesV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateRolesV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'product' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'roles' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendNotification' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRolesV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'product' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'product' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roles' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'roles' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sendNotification' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sendNotification' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRolesV2Mutation, UpdateRolesV2MutationVariables>
export const UpdateSavedSendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSavedSend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSavedSendInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSavedSend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'savedSendName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendFlowStep' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commonSendProperties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spendAs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationInputs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPageCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientEmailSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendMagicLinkInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requiresApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendCampaignInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useSameMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduleDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactSearchFilters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactCount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendBulkSendInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipToAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressVerified' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newContact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phones' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSavedSendMutation, UpdateSavedSendMutationVariables>
export const UpdateSelfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSelf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSelfInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSelf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountLockedUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationFailures' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auth' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'crmUserMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionNotificationOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesChannels' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailDigestSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'processingErrors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersRequiringUserApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemOutOfStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemLowStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketingUpdates' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newItemApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newCollectionApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newEventApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemRemoved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSelfMutation, UpdateSelfMutationVariables>
export const UpdateTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateTeamInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentTeamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingAccountIds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'budget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTeamMutation, UpdateTeamMutationVariables>
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountLockedUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationFailures' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auth' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'crmUserMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionNotificationOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesChannels' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailDigestSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'processingErrors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersRequiringUserApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemOutOfStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemLowStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketingUpdates' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newItemApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newCollectionApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newEventApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemRemoved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>
export const UpdateUserNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserNotificationsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserNotifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountLockedUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationFailures' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auth' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'crmUserMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionNotificationOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesChannels' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailDigestSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'processingErrors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersRequiringUserApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemOutOfStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemLowStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketingUpdates' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newItemApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newCollectionApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newEventApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemRemoved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserNotificationsMutation, UpdateUserNotificationsMutationVariables>
export const UpdateUserTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productAccessId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productAccessId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productAccessId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountLockedUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationFailures' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auth' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'crmUserMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionNotificationOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesChannels' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailDigestSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'processingErrors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersRequiringUserApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemOutOfStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemLowStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketingUpdates' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newItemApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newCollectionApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newEventApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemRemoved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserTeamMutation, UpdateUserTeamMutationVariables>
export const UpsertContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phones' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ownerLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastActivity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaigns' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'processed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFulfillmentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'playbooks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookInstanceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextStepToExecute' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasActivePlaybooks' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertContactMutation, UpsertContactMutationVariables>
export const AccessTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'accessToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accessToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccessTokenQuery, AccessTokenQueryVariables>
export const AssetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assets' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssetsQuery, AssetsQueryVariables>
export const AutocompleteContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'autocompleteContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'prefix' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autocompleteContacts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'prefix' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'prefix' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phones' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addresses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ownerLink' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastSend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastActivity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lists' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaigns' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'processed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalFulfillmentId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'playbooks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'playbookInstanceId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'nextStepToExecute' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasActivePlaybooks' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleted' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mappings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'profileUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resultsSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AutocompleteContactsQuery, AutocompleteContactsQueryVariables>
export const BasicAnalyticsV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'basicAnalyticsV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'config' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AnalyticsRequestConfig' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basicAnalyticsV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'config' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'config' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BasicAnalyticsV2Query, BasicAnalyticsV2QueryVariables>
export const BillingAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'billingAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingAccountFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billingAccounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedAccountIdentifier' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentProcessing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentBackgroundTaskId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPrepaymentAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadLowLimit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultPaymentMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentPartnerType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadOk' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailures' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFailedAutoReloadAttempt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastUnlocked' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'incomingInvoiceAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'incomingInvoiceActionRecord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingContactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentPartners' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentMethods' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingDetails' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'card' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationMonth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationYear' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastSyncedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'initialNetsuiteBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'initializedAt' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BillingAccountsQuery, BillingAccountsQueryVariables>
export const CheckAutomationsTiedToPlaybookDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkAutomationsTiedToPlaybook' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'playbookDefinitionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkAutomationsTiedToPlaybook' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'playbookDefinitionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'playbookDefinitionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAutomations' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckAutomationsTiedToPlaybookQuery, CheckAutomationsTiedToPlaybookQueryVariables>
export const CheckAutomationsTiedToPostalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkAutomationsTiedToPostal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkAutomationsTiedToPostal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvedPostalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasAutomations' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckAutomationsTiedToPostalQuery, CheckAutomationsTiedToPostalQueryVariables>
export const CountPostalFulfillmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'countPostalFulfillments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countPostalFulfillments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountPostalFulfillmentsQuery, CountPostalFulfillmentsQueryVariables>
export const DownloadContactDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'downloadContactData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadContactData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DownloadContactDataQuery, DownloadContactDataQueryVariables>
export const DownloadReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'downloadReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportOutputInstanceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportOutputInstanceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportOutputInstanceId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DownloadReportQuery, DownloadReportQueryVariables>
export const EventLimitCheckDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'eventLimitCheck' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'eventDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventLimitCheck' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eventDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'eventDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'eventAllowed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventLimit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventLimitTimeFrame' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventLimitUsed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventLimitCheckQuery, EventLimitCheckQueryVariables>
export const EventLimitDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'eventLimitDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventLimitDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventLimitDetailsQuery, EventLimitDetailsQueryVariables>
export const FetchTransactionPdfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fetchTransactionPdf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingAccountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingTransactionType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingTransactionType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fetchTransactionPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billingAccountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'billingAccountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'entityId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'entityId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billingTransactionType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'billingTransactionType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchTransactionPdfQuery, FetchTransactionPdfQueryVariables>
export const GetAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAccount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domains' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seats' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brandingLogoEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seatsAssigned' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryLogoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingAccountIds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'budget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingTermEndDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trialSubscription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryEmailSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalDeliveryExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bulkSendEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disclaimerInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailFont' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailPrimaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailSecondaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftEmailTertiaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpireAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressRefreshExpirationDays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationThreshold' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationThreshold' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountContactSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'redactAddress' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oktaIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oneLoginIntegration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionTimeoutMinutes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'forceAuthenticationMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewMarketplace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userCanViewEvents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceRequestNotificationEmails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sfdcPackageVersion' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkApprovalSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoActionDaysAfter' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fiscalQuarterSetup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuration' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brandingInstance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'whiteLogoUrl' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAccountQuery, GetAccountQueryVariables>
export const GetAccountManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAccountManager' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAccountManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'calendlyLink' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAccountManagerQuery, GetAccountManagerQueryVariables>
export const GetAdditionalEmailReminderOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAdditionalEmailReminderOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sendDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAdditionalEmailReminderOptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sendDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sendDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metaData' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAdditionalEmailReminderOptionsQuery, GetAdditionalEmailReminderOptionsQueryVariables>
export const GetApprovedPostalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApprovedPostal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getApprovedPostal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useCases' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageUrls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantOrderFlexibility' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variants' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrls' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'flatFees' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displaySubscriberPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shippingPriceEstimateMin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shippingPriceEstimateMax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessageSupported' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessageSupported' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'containsPerishables' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'designTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'front' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'back' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'dpi' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outputSize' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'requestedByName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requestedByEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requestedByPhone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requestedByMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requestedAttendeeCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spotsRemaining' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'calendarRequestedDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requestedDates' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventDateTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventLengthMinutes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastRegistrationDateTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendEmailConfirmation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendReminderDayBefore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendReminderDayOf' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendCancelledAlert' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendInviteExpiringAlert' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendMeetingLinkChanged' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendDateTimeChanged' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendShippedAlert' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendDeliveredAlert' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmationCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reminderTomorrowCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reminderTodayCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cancellationCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'meetingLinkChangedCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTimeChangedCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eventDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'eventLengthMinutes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minimumAttendees' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maximumAttendees' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requirements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentEmailAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentPhoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentCompany' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableBySender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableByRecipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'characterLimit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'widthRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileTypesAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geographicMarkets' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eventFeeSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flatFee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApprovedPostalQuery, GetApprovedPostalQueryVariables>
export const GetApprovedPostalBrandsCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApprovedPostalBrandsCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getApprovedPostalBrandsCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipTo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brands' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'useCases' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApprovedPostalBrandsCategoriesQuery, GetApprovedPostalBrandsCategoriesQueryVariables>
export const GetApprovedPostalFiltersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApprovedPostalFilters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'category' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'categories' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Status' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getApprovedPostalFilters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'category' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categories' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'categories' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApprovedPostalFiltersQuery, GetApprovedPostalFiltersQueryVariables>
export const GetBackgroundTaskByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBackgroundTaskById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBackgroundTaskById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBackgroundTaskByIdQuery, GetBackgroundTaskByIdQueryVariables>
export const GetBalanceRemainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBalanceRemaining' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBalanceRemaining' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBalanceRemainingQuery, GetBalanceRemainingQueryVariables>
export const GetBillingAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBillingAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBillingAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedAccountIdentifier' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentProcessing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentBackgroundTaskId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPrepaymentAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadLowLimit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultPaymentMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentPartnerType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadOk' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailures' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFailedAutoReloadAttempt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastUnlocked' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'incomingInvoiceAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'incomingInvoiceActionRecord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingContactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentPartners' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentMethods' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingDetails' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'card' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationMonth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationYear' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastSyncedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'initialNetsuiteBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'initializedAt' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBillingAccountQuery, GetBillingAccountQueryVariables>
export const GetBlockFreeBusyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBlockFreeBusy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'marketplaceProductId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'timeZone' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBlockFreeBusy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketplaceProductId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'marketplaceProductId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timeZone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'timeZone' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBlockFreeBusyQuery, GetBlockFreeBusyQueryVariables>
export const GetBlocklistEntryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBlocklistEntry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBlocklistEntry' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataObjectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filterType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBlocklistEntryQuery, GetBlocklistEntryQueryVariables>
export const GetBrandsCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBrandsCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBrandsCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipTo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brands' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'useCases' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBrandsCategoriesQuery, GetBrandsCategoriesQueryVariables>
export const GetBudgetRemainingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBudgetRemaining' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBudgetRemaining' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'budgetRemaining' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSpend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalAdjustments' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicableBudget' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'budget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBudgetRemainingQuery, GetBudgetRemainingQueryVariables>
export const GetBudgetRemainingSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBudgetRemainingSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBudgetRemainingSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'budget' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSpend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalAdjustments' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summaries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetRemaining' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSpend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalAdjustments' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBudgetRemainingSummaryQuery, GetBudgetRemainingSummaryQueryVariables>
export const GetCachedCurrencyConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCachedCurrencyConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CachedCurrencyConfigFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCachedCurrencyConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingAccountEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCachedCurrencyConfigsQuery, GetCachedCurrencyConfigsQueryVariables>
export const GetCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduleDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundTaskId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'designTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'front' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'back' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'dpi' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outputSize' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContacts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContactsAttempted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCampaignQuery, GetCampaignQueryVariables>
export const GetConnectionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getConnections' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getConnections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorizedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorizationStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorizationError' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetConnectionsQuery, GetConnectionsQueryVariables>
export const GetContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phones' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ownerLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastActivity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaigns' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'processed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFulfillmentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'playbooks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookInstanceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextStepToExecute' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasActivePlaybooks' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetContactQuery, GetContactQueryVariables>
export const GetContactByIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getContactByIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'systemName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactType' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getContactByIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'systemName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'systemName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contactType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phones' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ownerLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastActivity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaigns' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'processed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFulfillmentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'playbooks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookInstanceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextStepToExecute' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasActivePlaybooks' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetContactByIntegrationQuery, GetContactByIntegrationQueryVariables>
export const GetCrmAccountByIdentifierDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCrmAccountByIdentifier' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'crmIdentifier' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCrmAccountByIdentifier' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'crmIdentifier' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'crmIdentifier' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crmCreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'crmOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'closedWonDollars' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCrmAccountByIdentifierQuery, GetCrmAccountByIdentifierQueryVariables>
export const GetCrmPostalCustomFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCrmPostalCustomFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomFieldDataListType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCrmPostalCustomFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'system' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'crmField' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalField' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'present' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCrmPostalCustomFieldsQuery, GetCrmPostalCustomFieldsQueryVariables>
export const GetCrmReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCrmReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CrmReportType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'refresh' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCrmReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'system' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'refresh' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'refresh' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reportType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'queryResults' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'records' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'done' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalSize' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCrmReportQuery, GetCrmReportQueryVariables>
export const GetDailyFreeBusyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDailyFreeBusy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'marketplaceProductId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'timeZone' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDailyFreeBusy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketplaceProductId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'marketplaceProductId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timeZone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'timeZone' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateString' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableBlocks' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDailyFreeBusyQuery, GetDailyFreeBusyQueryVariables>
export const GetDataListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDataList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DataListType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchString' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDataList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'system' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchString' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchString' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'values' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataListQuery, GetDataListQueryVariables>
export const GetExternalCampaignMappingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getExternalCampaignMapping' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'campaignId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getExternalCampaignMapping' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'system' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'campaignId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'setCampaignCost' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'opened' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accepted' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'queued' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipped' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'delivered' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bounced' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meetingBooked' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'expired' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'failed' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'declined' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'donated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetExternalCampaignMappingQuery, GetExternalCampaignMappingQueryVariables>
export const GetFavoriteItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFavoriteItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFavoriteItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFavoriteItemQuery, GetFavoriteItemQueryVariables>
export const GetFundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'billingAccountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'billingAccountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'billingAccountId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFundsQuery, GetFundsQueryVariables>
export const GetIntegrationConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIntegrationConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIntegrationConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationFulfillmentPartnerId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIntegrationConnectionQuery, GetIntegrationConnectionQueryVariables>
export const GetIntegrationTriggerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIntegrationTrigger' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getIntegrationTrigger' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'systemName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filter' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalVariantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hitCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sequenceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sequenceStepId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'instanceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipeName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIntegrationTriggerQuery, GetIntegrationTriggerQueryVariables>
export const GetLedgerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLedger' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getLedger' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previousBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'memo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLedgerQuery, GetLedgerQueryVariables>
export const GetLocationDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLocationData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getLocationData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'countryName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode3' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regionName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'timeZone' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLocationDataQuery, GetLocationDataQueryVariables>
export const GetMagicLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMagicLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMagicLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxExecutions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'distributionChannel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastExecution' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'requiresApproval' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requiresUuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMagicLinkQuery, GetMagicLinkQueryVariables>
export const GetMarketplaceFiltersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMarketplaceFilters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'category' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'categories' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMarketplaceFilters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'category' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categories' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'categories' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMarketplaceFiltersQuery, GetMarketplaceFiltersQueryVariables>
export const GetMarketplaceProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMarketplaceProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMarketplaceProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useCases' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variants' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upcCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'manufacturerPartId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrls' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessageSupported' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessageSupported' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfillmentPartnerList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displaySubscriberPrice' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shippingOptions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'supplierPartId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'supplierPartAuxiliaryId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inventory' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'ordered' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'available' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceStructure' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bulkSendAvailable' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximumOrderableQuantity' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minimumTransactions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'flatFees' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'containsPerishables' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'allowedPlans' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageUrls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'variantOrderFlexibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'starterTemplate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemPreview' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'designTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'front' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'back' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'dpi' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outputSize' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumberRequired' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eventDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'eventLengthMinutes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minimumAttendees' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maximumAttendees' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requirements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentEmailAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentPhoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentCompany' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'eventAvailabilityDates' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableBySender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableByRecipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'characterLimit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'widthRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileTypesAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geographicMarkets' } },
                { kind: 'Field', name: { kind: 'Name', value: 'estimatedShippingTime' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMarketplaceProductQuery, GetMarketplaceProductQueryVariables>
export const GetMaxPhysicalNoteCharactersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMaxPhysicalNoteCharacters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'variantId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMaxPhysicalNoteCharacters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvedPostalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'variantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'variantId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMaxPhysicalNoteCharactersQuery, GetMaxPhysicalNoteCharactersQueryVariables>
export const GetOrderRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrderRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrderRules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reasonText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultAction' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rules' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'filter' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrderRulesQuery, GetOrderRulesQueryVariables>
export const GetPlaybookDefinitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPlaybookDefinition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPlaybookDefinition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'steps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delayUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displaySubscriberPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spendAs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationInputs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPageCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientEmailSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPlaybookDefinitionQuery, GetPlaybookDefinitionQueryVariables>
export const GetPlaybookInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPlaybookInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPlaybookInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errMessage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'steps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delayUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spendAs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationInputs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPageCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientEmailSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextStepToExecute' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextExecutionDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPlaybookInstanceQuery, GetPlaybookInstanceQueryVariables>
export const GetPostalFulfillmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPostalFulfillment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPostalFulfillment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipTo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToCompanyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'magicLinkId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'magicLinkName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previewImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYouMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerOrderId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cost' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'history' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentLocation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPartner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'failureReason' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableBySender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableByRecipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'characterLimit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'widthRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileTypesAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldValueList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalRequiredInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'approvalRequiredReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoActionDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actualCost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingCompany' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPostalFulfillmentQuery, GetPostalFulfillmentQueryVariables>
export const GetPostalFulfillmentActionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPostalFulfillmentActions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPostalFulfillmentActions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'eventName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tooltip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPostalFulfillmentActionsQuery, GetPostalFulfillmentActionsQueryVariables>
export const GetRecipeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRecipe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRecipe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'running' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobSucceededCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobFailedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastRunAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stopCause' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRecipeQuery, GetRecipeQueryVariables>
export const GetRecipesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRecipes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRecipes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'running' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobSucceededCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobFailedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastRunAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stoppedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stopCause' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRecipesQuery, GetRecipesQueryVariables>
export const GetRolePermissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRolePermissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRolePermissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'role' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'roleName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'available' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRolePermissionsQuery, GetRolePermissionsQueryVariables>
export const GetSavedSendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSavedSend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSavedSend' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'savedSendName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendFlowStep' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commonSendProperties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spendAs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationInputs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPageCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientEmailSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendMagicLinkInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requiresApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendCampaignInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useSameMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduleDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactSearchFilters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactCount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendBulkSendInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipToAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressVerified' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newContact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phones' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSavedSendQuery, GetSavedSendQueryVariables>
export const GetSequenceStepsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSequenceSteps' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sequenceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSequenceSteps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'system' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sequenceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sequenceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'steps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSequenceStepsQuery, GetSequenceStepsQueryVariables>
export const GetSequencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSequences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSequences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'system' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'system' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sequences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSequencesQuery, GetSequencesQueryVariables>
export const GetTeamByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTeamById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTeamById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentTeamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingAccountIds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'budget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTeamByIdQuery, GetTeamByIdQueryVariables>
export const GetTransferIntentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTransferIntent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTransferIntent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCreditMemoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCreditMemoNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromDebitMemoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromDebitMemoNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fxInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'apiProvider' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'history' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTransferIntentQuery, GetTransferIntentQueryVariables>
export const GetTrialExpiredStatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTrialExpiredStats' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTrialExpiredStats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactsCreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalsSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignsCreated' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTrialExpiredStatsQuery, GetTrialExpiredStatsQueryVariables>
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountLockedUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationFailures' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auth' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'crmUserMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionNotificationOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesChannels' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailDigestSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'processingErrors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersRequiringUserApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemOutOfStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemLowStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketingUpdates' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newItemApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newCollectionApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newEventApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemRemoved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>
export const GetWorkatoJwtDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getWorkatoJWT' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'test' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getWorkatoJWT' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'test' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'test' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWorkatoJwtQuery, GetWorkatoJwtQueryVariables>
export const InvitesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'invites' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'InviteFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'InviteOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invites' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invitedByUserName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvitesQuery, InvitesQueryVariables>
export const MagicLinkAnalyticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'magicLinkAnalytics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'granularity' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Granularity' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'magicLinkId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'magicLinkAnalytics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'granularity' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'granularity' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'magicLinkId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'magicLinkId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MagicLinkAnalyticsQuery, MagicLinkAnalyticsQueryVariables>
export const MarketplaceSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'marketplaceSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceSearchQueryInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketplaceSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productGroupIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inventory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicEventStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalPreferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'containsPerishables' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'estimatedShippingTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastRegistrationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contexts' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ref' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarketplaceSearchQuery, MarketplaceSearchQueryVariables>
export const MarketplaceSuggestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'marketplaceSuggest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceSuggestQueryInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketplaceSuggest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageUrls' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productGroupIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inventory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicEventStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalPreferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'containsPerishables' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'estimatedShippingTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastRegistrationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contexts' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ref' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarketplaceSuggestQuery, MarketplaceSuggestQueryVariables>
export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authTypes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedActions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYouChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionNotificationOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesNotificationsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesChannels' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailDigestSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'processingErrors' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ordersRequiringUserApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemOutOfStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemLowStock' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketingUpdates' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newItemApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newCollectionApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newEventApproved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemRemoved' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSignatureHtml' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meetingSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingDurationMins' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateRange' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availabilityBlockIcal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestDefault' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'calendarId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'busyByDefault' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scheduleDayLeadTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scheduleHourLeadTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scheduleBuffer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scheduleBufferUnits' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'schedulingLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'videoMeetingLink' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyList' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>
export const MessageTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'messageTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageTemplateFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageTemplateOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templateText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedWith' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'target' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageTemplatesQuery, MessageTemplatesQueryVariables>
export const ModulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'modules' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModulesQuery, ModulesQueryVariables>
export const NamedProductAccessListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'namedProductAccessList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'namedProductAccessList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountDisabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productAccess' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NamedProductAccessListQuery, NamedProductAccessListQueryVariables>
export const ObjectAnalyticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'objectAnalytics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DataObjectType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AnalyticsFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'objectAnalytics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ObjectAnalyticsQuery, ObjectAnalyticsQueryVariables>
export const PingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ping' },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ping' } }] },
    },
  ],
} as unknown as DocumentNode<PingQuery, PingQueryVariables>
export const PlaybookAnalyticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'playbookAnalytics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'granularity' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Granularity' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'playbookDefinitionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'playbookAnalytics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'granularity' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'granularity' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'playbookDefinitionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'playbookDefinitionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlaybookAnalyticsQuery, PlaybookAnalyticsQueryVariables>
export const PostalAnalyticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'postalAnalytics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'granularity' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Granularity' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postalAnalytics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'granularity' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'granularity' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PostalAnalyticsQuery, PostalAnalyticsQueryVariables>
export const PostalAnalyticsV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'postalAnalyticsV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'config' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AnalyticsRequestConfig' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postalAnalyticsV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'config' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'config' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previewImageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PostalAnalyticsV2Query, PostalAnalyticsV2QueryVariables>
export const PreviewAddFundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'previewAddFunds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PreviewAddFundsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewAddFunds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'grossPaymentAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'feeInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'flatFee' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'netPaymentAmount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreviewAddFundsQuery, PreviewAddFundsQueryVariables>
export const PreviewEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'previewEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'marketplaceProductId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productVariantId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attendeeCount' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketplaceProductId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'marketplaceProductId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productVariantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productVariantId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attendeeCount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attendeeCount' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'minimumSpend' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variableCost' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flatFees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fundsRemaining' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundsPassed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetRemaining' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetPassed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreviewEventQuery, PreviewEventQueryVariables>
export const PreviewGiftEmailAcceptancePageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'previewGiftEmailAcceptancePage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PreviewGiftEmailAcceptancePageInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewGiftEmailAcceptancePage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'htmlUrl' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreviewGiftEmailAcceptancePageQuery, PreviewGiftEmailAcceptancePageQueryVariables>
export const PreviewMagicLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'previewMagicLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'variantId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'landingPageCustomization' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LandingPageCustomizationInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewMagicLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvedPostalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'variantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'variantId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'landingPageCustomization' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'landingPageCustomization' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'htmlUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previewImageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previewImageMobileUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreviewMagicLinkQuery, PreviewMagicLinkQueryVariables>
export const PreviewOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'previewOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalVariantId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DataObjectType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryEmail' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'spendAs' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SpendAsInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'quantity' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvedPostalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'approvedPostalVariantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'approvedPostalVariantId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'v2filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'v2filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orfilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'count' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryEmail' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryEmail' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'spendAs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'spendAs' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'quantity' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'quantity' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cost' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContacts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstDeliveryEstimated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastDeliveryEstimated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundsRemaining' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundsPassed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetRemaining' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetPassed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duplicateOrder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skipConfirmation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderBlocked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderBlockedReason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hardBlock' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailIntegrationSuccess' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreviewOrderQuery, PreviewOrderQueryVariables>
export const PreviewOrderV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'previewOrderV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'request' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderPreviewV2Request' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactFilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewOrderV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'request' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactFilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contactFilters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cost' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContacts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundsRemaining' } },
                { kind: 'Field', name: { kind: 'Name', value: 'budgetRemaining' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'generalValidationIssues' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'validationName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'issue' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactValidationIssues' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'validationIssues' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'validationName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'issue' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'validationPassed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PreviewOrderV2Query, PreviewOrderV2QueryVariables>
export const ProductAccessListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'productAccessList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productAccessList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductAccessListQuery, ProductAccessListQueryVariables>
export const SearchActivityStreamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchActivityStream' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityStreamFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityStreamOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchActivityStream' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'callouts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'targetText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dataId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'webhookGroup' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchActivityStreamQuery, SearchActivityStreamQueryVariables>
export const SearchAddFundsIntentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchAddFundsIntents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddFundsIntentFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddFundsIntentOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchAddFundsIntents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalFulfillmentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'partnerPaymentMethodId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentPartnerType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'grossPaymentAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'feeInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'flatFee' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'netPaymentAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPayByInvoiceCheck' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAddFundsIntentsQuery, SearchAddFundsIntentsQueryVariables>
export const SearchApprovedPostalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchApprovedPostals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApprovedPostalFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DefaultOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchApprovedPostals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useCases' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageUrls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantOrderFlexibility' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variants' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrls' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'flatFees' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displaySubscriberPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shippingPriceEstimateMin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shippingPriceEstimateMax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessageSupported' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessageSupported' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'containsPerishables' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'designTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'front' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'back' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'dpi' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outputSize' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'requestedByName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requestedByEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requestedByPhone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requestedByMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requestedAttendeeCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'spotsRemaining' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'calendarRequestedDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requestedDates' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventDateTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventLengthMinutes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastRegistrationDateTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendEmailConfirmation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendReminderDayBefore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendReminderDayOf' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendCancelledAlert' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendInviteExpiringAlert' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendMeetingLinkChanged' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendDateTimeChanged' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendShippedAlert' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendDeliveredAlert' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmationCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reminderTomorrowCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reminderTodayCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cancellationCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'meetingLinkChangedCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTimeChangedCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eventDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'eventLengthMinutes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minimumAttendees' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maximumAttendees' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requirements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentEmailAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentPhoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentCompany' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableBySender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableByRecipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'characterLimit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'widthRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileTypesAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geographicMarkets' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eventFeeSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flatFee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchApprovedPostalsQuery, SearchApprovedPostalsQueryVariables>
export const SearchAssetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchAssets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchAssets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAssetsQuery, SearchAssetsQueryVariables>
export const SearchBackgroundTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchBackgroundTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BackgroundTaskFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BackgroundTaskOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchBackgroundTasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchBackgroundTasksQuery, SearchBackgroundTasksQueryVariables>
export const SearchBillingAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchBillingAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingAccountFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BillingAccountOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchBillingAccounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedAccountIdentifier' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'balance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentProcessing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepaymentBackgroundTaskId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPrepaymentAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoReloadLowLimit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultPaymentMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentPartnerType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadOk' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailures' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastFailedAutoReloadAttempt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastUnlocked' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'incomingInvoiceAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'incomingInvoiceActionRecord' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingContactInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentPartners' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentMethods' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingDetails' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'card' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationMonth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationYear' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastSyncedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'initialNetsuiteBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'initializedAt' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchBillingAccountsQuery, SearchBillingAccountsQueryVariables>
export const SearchBlocklistEntrysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchBlocklistEntrys' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BlocklistEntryFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BlocklistEntryOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchBlocklistEntrys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataObjectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filterType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchBlocklistEntrysQuery, SearchBlocklistEntrysQueryVariables>
export const SearchBudgetHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchBudgetHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetHistoryFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetHistoryOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchBudgetHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'oldBudget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newBudget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'oldRemaining' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentBudget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'usageTransactionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'relatedId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'relatedType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'drawdownRefundId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resultsSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchBudgetHistoryQuery, SearchBudgetHistoryQueryVariables>
export const SearchCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CampaignFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CampaignOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchCampaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduleDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completeDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundTaskId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'designTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'front' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'back' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'dpi' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outputSize' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContacts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalContactsAttempted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchCampaignsQuery, SearchCampaignsQueryVariables>
export const SearchContactListsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchContactLists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchContactLists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchContactListsQuery, SearchContactListsQueryVariables>
export const SearchContactPostalFulfillmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchContactPostalFulfillments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchContactPostalFulfillments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contactId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipTo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToCompanyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'magicLinkId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'magicLinkName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previewImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYouMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerOrderId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cost' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'history' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentLocation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPartner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'failureReason' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableBySender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableByRecipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'characterLimit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'widthRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileTypesAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldValueList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalRequiredInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'approvalRequiredReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoActionDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actualCost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingCompany' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchContactPostalFulfillmentsQuery, SearchContactPostalFulfillmentsQueryVariables>
export const SearchContactsV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchContactsV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactFilterInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchableContactOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchContactsV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orfilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orfilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchableContacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'verifiedAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastSend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaigns' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lists' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resultsSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchContactsV2Query, SearchContactsV2QueryVariables>
export const SearchEmailTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchEmailTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmailTemplateFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'GenericFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchEmailTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultContent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchEmailTemplatesQuery, SearchEmailTemplatesQueryVariables>
export const SearchEventPostalFulfillmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchEventPostalFulfillments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchEventPostalFulfillments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipTo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToCompanyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'magicLinkId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'magicLinkName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previewImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYouMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerOrderId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cost' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'history' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentLocation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPartner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'failureReason' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableBySender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableByRecipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'characterLimit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'widthRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileTypesAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldValueList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalRequiredInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'approvalRequiredReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoActionDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actualCost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingCompany' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchEventPostalFulfillmentsQuery, SearchEventPostalFulfillmentsQueryVariables>
export const SearchFavoriteItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchFavoriteItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FavoriteItemFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DefaultOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFavoriteItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchFavoriteItemsQuery, SearchFavoriteItemsQueryVariables>
export const SearchIntegrationConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchIntegrationConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationConnectionFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchIntegrationConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationFulfillmentPartnerId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchIntegrationConnectionQuery, SearchIntegrationConnectionQueryVariables>
export const SearchIntegrationSyncDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchIntegrationSync' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationSyncFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchIntegrationSync' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastRun' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextRun' } },
                { kind: 'Field', name: { kind: 'Name', value: 'syncTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pollingTimeMins' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'postalIoFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'staticValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remap' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pushMappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'postalIoFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'externalFieldName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'staticValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remap' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'workStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objectType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createCustomFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadSourceFieldName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultLeadSourceValue' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchIntegrationSyncQuery, SearchIntegrationSyncQueryVariables>
export const SearchIntegrationTriggerResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchIntegrationTriggerResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationTriggerResultFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchIntegrationTriggerResults' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'triggerName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookInstanceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'systemName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchIntegrationTriggerResultsQuery, SearchIntegrationTriggerResultsQueryVariables>
export const SearchIntegrationTriggersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchIntegrationTriggers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationTriggerFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationTriggerOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchIntegrationTriggers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'systemName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filter' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalVariantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hitCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sequenceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sequenceStepId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'instanceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipeId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipeName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchIntegrationTriggersQuery, SearchIntegrationTriggersQueryVariables>
export const SearchLedgerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchLedger' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LedgerFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LedgerOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchLedger' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previousBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'relatedType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'memo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchLedgerQuery, SearchLedgerQueryVariables>
export const SearchMagicLinksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchMagicLinks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MagicLinkFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MagicLinkOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchMagicLinks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxExecutions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'distributionChannel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastExecution' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationInputs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'requiresApproval' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requiresUuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchMagicLinksQuery, SearchMagicLinksQueryVariables>
export const SearchMarketplaceProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchMarketplaceProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceProductFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DefaultOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchMarketplaceProducts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'useCases' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variants' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upcCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'manufacturerPartId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrls' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessageSupported' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessageSupported' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfillmentPartnerList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displaySubscriberPrice' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shippingOptions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'supplierPartId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'supplierPartAuxiliaryId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inventory' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'ordered' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'available' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceStructure' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bulkSendAvailable' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximumOrderableQuantity' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minimumTransactions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'flatFees' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'containsPerishables' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'allowedPlans' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageUrls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'variantOrderFlexibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'starterTemplate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemPreview' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'designTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'front' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'back' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boundary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'dpi' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outputSize' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumberRequired' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eventDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'eventLengthMinutes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minimumAttendees' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maximumAttendees' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requirements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentEmailAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentPhoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'talentCompany' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'eventAvailabilityDates' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableBySender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableByRecipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'characterLimit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'widthRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileTypesAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'geographicMarkets' } },
                { kind: 'Field', name: { kind: 'Name', value: 'estimatedShippingTime' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchMarketplaceProductsQuery, SearchMarketplaceProductsQueryVariables>
export const SearchMessageTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchMessageTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageTemplateFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageTemplateOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchMessageTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templateText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedWith' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'target' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchMessageTemplatesQuery, SearchMessageTemplatesQueryVariables>
export const SearchNoAccessUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchNoAccessUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchNoAccessUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountLockedUntil' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authenticationFailures' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'auth' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productAccess' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'crmUserMap' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notifications' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thankYouNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thankYouChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionNotificationOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesChannels' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailDigestSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'processingErrors' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ordersRequiringUserApproval' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'itemOutOfStock' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'itemLowStock' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marketingUpdates' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newItemApproved' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newCollectionApproved' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newEventApproved' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'itemRemoved' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resultsSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchNoAccessUsersQuery, SearchNoAccessUsersQueryVariables>
export const SearchPlaybookDefinitionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchPlaybookDefinitions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlaybookDefinitionFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlaybookDefinitionOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchPlaybookDefinitions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'steps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delayUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displaySubscriberPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spendAs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationInputs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPageCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientEmailSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchPlaybookDefinitionsQuery, SearchPlaybookDefinitionsQueryVariables>
export const SearchPlaybookInstancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchPlaybookInstances' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlaybookInstanceFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PlaybookInstanceOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchPlaybookInstances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errMessage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'steps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbookStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delay' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delayUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spendAs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationInputs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPageCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientEmailSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextStepToExecute' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextExecutionDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoProgramName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketoCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdfcSandboxCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sfdcSandboxCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eloquaCampaignName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchPlaybookInstancesQuery, SearchPlaybookInstancesQueryVariables>
export const SearchPostalFulfillmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchPostalFulfillments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchPostalFulfillments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipTo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToCompanyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipToEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookDefinitionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbookName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'magicLinkId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'magicLinkName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previewImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYouMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerOrderId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cost' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'history' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentLocation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryPartner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'collection' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sendAsUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'spendAs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'failureReason' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemCustomizationList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableBySender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customizableByRecipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'characterLimit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'widthRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heightRecommendation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileTypesAccepted' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldValueList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formFieldList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPageCustomization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipientEmailSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approvalRequiredInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'approvalRequiredReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoAction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoActionDate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fulfillments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actualCost' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalFee' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetExpense' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingCompany' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchPostalFulfillmentsQuery, SearchPostalFulfillmentsQueryVariables>
export const SearchReportOutputInstancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchReportOutputInstances' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportOutputFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportOutputOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchReportOutputInstances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchReportOutputInstancesQuery, SearchReportOutputInstancesQueryVariables>
export const SearchSavedSendsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchSavedSends' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SavedSendFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SavedSendOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchSavedSends' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'savedSendName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sendFlowStep' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commonSendProperties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingRequestSetting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsContactOwner' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sendAsUser' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spendAs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formFieldList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRequired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'options' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemCustomizationInputs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPageCustomization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'headerText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeHeadshot' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includeSenderName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientEmailSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shippedEmailsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deliveredEmailsOn' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'giftEmailReminderSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expirationSoonReminders' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendMagicLinkInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxExecutions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requiresApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendCampaignInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useSameMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailSubjectLine' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailTemplateId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduleDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactSearchFilters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactCount' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedSendBulkSendInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipToAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'addressVerified' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newContact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phones' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchSavedSendsQuery, SearchSavedSendsQueryVariables>
export const SearchTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TagFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchTags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchTagsQuery, SearchTagsQueryVariables>
export const SearchTransferIntentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchTransferIntents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransferIntentFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TransferIntentOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchTransferIntents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toBillingAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCurrency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCreditMemoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'toCreditMemoNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromDebitMemoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fromDebitMemoNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fxInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'apiProvider' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'history' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updated' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchTransferIntentsQuery, SearchTransferIntentsQueryVariables>
export const SearchUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accountLockedUntil' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authenticationFailures' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'auth' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productAccess' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'crmUserMap' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notifications' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'orderDeliveredChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thankYouNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'thankYouChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionNotificationOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'landingPageExceptionChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'autoReloadFailuresChannels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesNotificationsOn' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'orderIssuesChannels' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailDigestSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'processingErrors' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ordersRequiringUserApproval' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'itemOutOfStock' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'itemLowStock' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'marketingUpdates' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newItemApproved' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newCollectionApproved' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newEventApproved' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'itemRemoved' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resultsSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalRecords' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchUsersQuery, SearchUsersQueryVariables>
export const TeamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'teams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TeamFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TeamOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentTeamId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingAccountIds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billingAccountId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'budget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamsQuery, TeamsQueryVariables>
export const TriggerAnalyticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'triggerAnalytics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'granularity' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Granularity' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'triggerId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Instant' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'triggerAnalytics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'granularity' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'granularity' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'triggerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'triggerId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriggerAnalyticsQuery, TriggerAnalyticsQueryVariables>
export const UserAnalyticsV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userAnalyticsV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'config' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AnalyticsRequestConfig' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAnalyticsV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'config' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'config' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'queued' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailAccepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailOpened' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDonated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailDeclined' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailUnsubscribed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailFailed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpired' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accepted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'delivered' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bounces' } },
                { kind: 'Field', name: { kind: 'Name', value: 'touches' } },
                { kind: 'Field', name: { kind: 'Name', value: 'costInCents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvUSD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvCAD' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvEUR' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gmvGBP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'started' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignsSent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'playbooksStarted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'triggersExecuted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkTotalViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUniqViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkMaxExeViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExpiredViews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkExecutions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fundsAdded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meetingsBooked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thankYou' } },
                { kind: 'Field', name: { kind: 'Name', value: 'socialShare' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkNewContact' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeMagicLinks' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserAnalyticsV2Query, UserAnalyticsV2QueryVariables>
export const VerifyAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'verifyAddress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'VerifyAddressInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyAddress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uspsAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uspsAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uspsAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uspsAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'entryName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyAddressQuery, VerifyAddressQueryVariables>
export const VerifyEmailConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'verifyEmailConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ssoMethodName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyEmailConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ssoMethodName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ssoMethodName' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyEmailConnectionQuery, VerifyEmailConnectionQueryVariables>
