import { Box, Divider, Flex, Image, keyframes, SimpleGrid } from '@chakra-ui/react'
import { FontWeight, UiHeading4 } from '@postal-io/postal-ui'
import backgroundImage from 'assets/animations/account-creation/background.png'
import integrationsImage from 'assets/animations/account-creation/integrations.png'
import marketplaceImage from 'assets/animations/account-creation/marketplace.png'
import playbooksImage from 'assets/animations/account-creation/playbooks.png'
import swagImage from 'assets/animations/account-creation/swag.png'
import triggersImage from 'assets/animations/account-creation/triggers.png'
import postalLogoImage from 'assets/images/postal-logo.svg'
import React from 'react'

const IMAGE_LIST = [
  { title: 'Marketplace', src: marketplaceImage },
  { title: 'Subscriptions', src: playbooksImage },
  { title: 'Triggers', src: triggersImage },
  { title: 'Integrations', src: integrationsImage },
  { title: 'Swag', src: swagImage },
]

const FACTOR = 100 / (IMAGE_LIST.length + 1)

const generateFade = (idx: number) => {
  return keyframes({
    [`${idx * FACTOR}%`]: {
      opacity: 0.5,
    },
    [`${idx * FACTOR + FACTOR}% `]: {
      opacity: 1,
    },
    [`${100 - FACTOR}`]: {
      opacity: 1,
    },
  })
}

interface RegisterLoadingProps {
  isLoading: boolean
}

export const RegisterLoading: React.FC<RegisterLoadingProps> = ({ isLoading }) => {
  return (
    <Box>
      {isLoading && (
        <Box
          pos="fixed"
          w="100vw"
          h="100vh"
          top="0"
          left="0"
          bg="white"
          zIndex={9999}
        >
          <Flex
            justifyContent="center"
            position="relative"
            h="calc(100vh)"
            backgroundImage={backgroundImage}
            backgroundPosition="bottom center"
            backgroundSize="cover"
          >
            <Flex
              pos="absolute"
              top={8}
              flexDir="column"
              align="center"
              textAlign="center"
              maxW="100%"
              p={8}
            >
              <Image
                src={postalLogoImage}
                display="inline-block"
                maxW={48}
                mt={16}
              />
              <Divider
                w="160px"
                mt={16}
              />
              <UiHeading4
                color="shades.700"
                fontWeight="500"
                mt={4}
              >
                We&apos;re creating your Postal account.
                <br />
                Hang tight and please don&apos;t refresh your browser.
              </UiHeading4>
              <Divider w="160px" />
              <SimpleGrid
                mt={16}
                columns={{ base: 1, sm: 3, md: 5 }}
                spacing={8}
                justifyContent="center"
              >
                {IMAGE_LIST.map((item, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      opacity: 0.5,
                      animation: `${generateFade(idx)} 3s infinite ease-in-out`,
                    }}
                  >
                    <Image
                      src={item.src}
                      w="160px"
                    />
                    <UiHeading4
                      fontWeight={FontWeight.SemiBold}
                      color="primary.500"
                      mt={2}
                    >
                      {item.title}
                    </UiHeading4>
                  </Box>
                ))}
              </SimpleGrid>
            </Flex>
          </Flex>
        </Box>
      )}
    </Box>
  )
}
