import type { Column } from '@postal-io/postal-ui'
import { UiDate, UiTruncate, ZLink, ZMoney } from '@postal-io/postal-ui'
import type { PostalFulfillment } from 'api'
import { Role } from 'api'
import { ZStatusTag } from 'components/Common/ZComponents'
import { useAcl } from 'hooks'
import { statusMapper } from 'lib'
import React from 'react'
import { Link } from 'react-router-dom'

interface CleanedPostalFulfillmentProps extends PostalFulfillment {
  onItemClick: () => void
}

interface LinkProps {
  name?: string | null
  id?: string | null
}

interface ColumnLinkProps {
  name?: string | null
  to?: string
}
const ColumnLink: React.FC<ColumnLinkProps> = ({ name, to }) => {
  return to ? (
    <ZLink
      to={to}
      state={{ returnTo: 'Order Report' }}
      as={Link}
    >
      <UiTruncate
        length={35}
        text={name || ''}
        showTooltip
      ></UiTruncate>
    </ZLink>
  ) : (
    <UiTruncate
      length={35}
      text={name || ''}
      showTooltip
    ></UiTruncate>
  )
}

export const UserLinkV2 = ({ name, id }: LinkProps) => {
  const { hasPermission } = useAcl()
  const to = !!id && hasPermission('users.read') ? `/users/${id}` : undefined
  return (
    <ColumnLink
      to={to}
      name={name}
    />
  )
}

const ItemLink = ({ name, id }: LinkProps) => {
  const { hasRole } = useAcl()
  const to = !!id && hasRole(Role.User) ? `/reporting/orders/${id}` : undefined
  return (
    <ColumnLink
      to={to}
      name={name}
    />
  )
}

const ContactLink = ({ name, id }: LinkProps) => {
  const { hasPermission } = useAcl()
  const to = !!id && hasPermission('contacts.read') ? `/contacts/${id}` : undefined
  return (
    <ColumnLink
      to={to}
      name={name}
    />
  )
}

const CampaignLink = ({ name, id }: LinkProps) => {
  const { hasPermission } = useAcl()
  const to = !!id && hasPermission('campaigns.read') ? `/campaigns/${id}` : undefined
  return (
    <ColumnLink
      to={to}
      name={name}
    />
  )
}

const MagicLinkLink = ({ name, id }: LinkProps) => {
  const { aclCheck } = useAcl()
  const to = !!id && aclCheck({ module: 'links.read', feature: 'links' }) ? `/links/${id}` : undefined
  return (
    <ColumnLink
      to={to}
      name={name}
    />
  )
}

const CollectionLink = ({ name, id }: LinkProps) => {
  const { aclCheck } = useAcl()
  const to =
    !!id && aclCheck({ module: 'collections.read', feature: 'collections ' }) ? `/collections/${id}` : undefined
  return (
    <ColumnLink
      to={to}
      name={name}
    />
  )
}

export const columnsV2: Column[] = [
  {
    label: 'Date',
    orderBy: 'created',
    render: ({ created }: PostalFulfillment) => <UiDate date={created?.dateTime} />,
    rowProps: {
      w: '100px',
      maxW: '100px',
    },
  },
  {
    label: 'Item',
    render: ({ id, itemName }: CleanedPostalFulfillmentProps) => (
      <ItemLink
        name={itemName}
        id={id}
      />
    ),
    rowProps: {
      w: '140px',
      maxW: '140px',
    },
  },
  {
    label: 'Collection',
    render: ({ collectionName, approvedPostalId }: CleanedPostalFulfillmentProps) => {
      return (
        <CollectionLink
          name={collectionName}
          id={approvedPostalId}
        />
      )
    },
    rowProps: {
      w: '120px',
      maxW: '120px',
    },
  },
  {
    label: 'Contact',
    render: ({ shipToName, contactId }: CleanedPostalFulfillmentProps) => (
      <ContactLink
        name={shipToName}
        id={contactId}
        data-private
      />
    ),
    rowProps: {
      w: '120px',
      maxW: '120px',
    },
  },
  {
    label: 'User',
    render: ({ userId, userLink }: CleanedPostalFulfillmentProps) => (
      <UserLinkV2
        name={userLink?.fullName}
        id={userId}
      />
    ),
    rowProps: {
      w: '120px',
      maxW: '120px',
    },
  },
  {
    key: 'sendAs.fullName',
    label: 'Sent as',
    render: ({ sendAs, sendAsUserId }: CleanedPostalFulfillmentProps) => (
      <UserLinkV2
        name={sendAs?.fullName}
        id={sendAsUserId}
      />
    ),
    rowProps: {
      w: '120px',
      maxW: '120px',
    },
  },
  {
    label: 'Campaign',
    render: ({ campaignName, campaignId }: CleanedPostalFulfillmentProps) => (
      <CampaignLink
        name={campaignName}
        id={campaignId}
      />
    ),
    rowProps: {
      w: '120px',
      maxW: '120px',
    },
  },
  {
    key: 'magicLinkName',
    label: 'MagicLink',
    render: ({ magicLinkName, magicLinkId }: CleanedPostalFulfillmentProps) => (
      <MagicLinkLink
        name={magicLinkName}
        id={magicLinkId}
      />
    ),
    rowProps: {
      w: '120px',
      maxW: '120px',
    },
  },
  {
    label: 'Cost',
    render: ({ cost }: PostalFulfillment) => (
      <ZMoney
        cents={cost?.total as number}
        currency={cost?.currency}
      />
    ),
    rowProps: {
      w: '100px',
      maxW: '100px',
    },
  },
  {
    label: 'Status',
    render: ({ status, id }: CleanedPostalFulfillmentProps) => {
      const { color, backgroundColor, text } = statusMapper(status)
      return (
        <ZLink
          to={`/reporting/orders/${id}`}
          as={Link}
        >
          <ZStatusTag
            px={4}
            color={color}
            backgroundColor={backgroundColor}
            label={text}
            p={2}
          />
        </ZLink>
      )
    },
    rowProps: {
      w: '140px',
      maxW: '140px',
    },
  },
]
