import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiSelectTypeaheadProps } from '@postal-io/postal-ui'
import { useAlertError, ZSelectTypeahead } from '@postal-io/postal-ui'
import { orderBy } from 'natural-orderby'
import { useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import type { Team } from '../../api'
import { Status, TeamsDocument } from '../../api'

const NO_CHANGE_OPTION = { name: 'No Changes To Teams', id: 'no changes' }
const ALL_TEAMS_OPTION = { name: 'All Teams', id: 'all teams' }

export function AutoCompleteTeams(
  props: UiSelectTypeaheadProps<Team | any, true> & { includeNoChangesOption?: boolean }
) {
  const [search, setSearch] = useState(props.inputValue || props.defaultInputValue || '')
  const { value, onChange, isDisabled, includeNoChangesOption, ...rest } = props

  const [debounced] = useDebounce(search, 400)

  const variables = useMemo(() => {
    return {
      filter: {
        name: debounced ? { contains: debounced } : undefined,
        status: { eq: Status.Active },
      },
      limit: 20,
    }
  }, [debounced])

  const valueVariables = useMemo(() => {
    return {
      filter: {
        id: { in: value || [] },
        status: { eq: Status.Active },
      },
    }
  }, [value])

  const query = useGraphqlQuery(TeamsDocument, variables)
  const valueQuery = useGraphqlQuery(TeamsDocument, valueVariables, {
    enabled: !!value?.length,
    staleTime: 60 * 1000,
  })

  const teamListFromValue = useMemo(() => {
    return value?.length ? valueQuery.data?.teams : null
  }, [value?.length, valueQuery.data?.teams])

  const teamsList = useMemo(() => {
    // don't append extra options if there are no teams to be shown
    if (!query.data?.teams) return []

    const standardOptions = [ALL_TEAMS_OPTION, ...orderBy(query.data?.teams || [], ['name'])]
    return includeNoChangesOption ? [NO_CHANGE_OPTION, ...standardOptions] : standardOptions
  }, [includeNoChangesOption, query.data?.teams])

  useAlertError(query.error)

  const handleOnChange = useCallback(
    (val: any, action: any) => {
      // special cases for No Changes to Teams & All Teams
      if (action.action === 'select-option' && action.option === NO_CHANGE_OPTION) return onChange?.(undefined!, action)
      if (action.action === 'select-option' && action.option === ALL_TEAMS_OPTION) return onChange?.([], action)

      onChange?.(
        val.map((t: Team) => t.id),
        action
      )
    },
    [onChange]
  )

  const handleInput = useCallback(
    (val: any, meta: any) => {
      setSearch(val)
      props.onInputChange && props.onInputChange(val, meta)
    },
    [props]
  )

  return (
    <ZSelectTypeahead
      rootProps={{ zIndex: 9999, cursor: isDisabled ? 'not-allowed' : 'default' }}
      data-testid="AutoCompleteTeam"
      options={teamsList}
      getOptionLabel={(t) => t.name}
      getOptionValue={(t) => t.id}
      onInputChange={handleInput}
      isLoading={query.isLoading}
      placeholder={includeNoChangesOption && !value ? 'No Changes to Teams' : 'All Teams'}
      noOptionsMessage={() => 'No Teams Found'}
      // @ts-ignore
      isMulti
      onChange={handleOnChange}
      value={teamListFromValue}
      isDisabled={isDisabled}
      {...rest}
    />
  )
}
