import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiLoading } from '@postal-io/postal-ui'
import { GetAccountDocument, GetTeamByIdDocument } from 'api'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AccountPage } from './AccountPage'

export function AccountPageRoute() {
  const params = useParams()
  const teamId = params.teamId

  const getAccountQuery = useGraphqlQuery(GetAccountDocument, undefined)
  const account = useMemo(() => getAccountQuery?.data?.getAccount, [getAccountQuery?.data?.getAccount])
  const teamQuery = useGraphqlQuery(
    GetTeamByIdDocument,
    { id: teamId as string },
    { enabled: !!teamId && teamId !== 'default' }
  )
  if (getAccountQuery.isLoading || teamQuery.isLoading) return <UiLoading />

  return (
    <AccountPage
      account={account!}
      team={teamQuery.data?.getTeamById}
      maxW="500px"
      hideUsers
    />
  )
}
