import { useCallback, useState } from 'react'

const DEFAULT_RESET_TIME = 1000
export function useAutoResettingTrigger(resetDelay: number = DEFAULT_RESET_TIME) {
  const [value, setValueRaw] = useState<boolean>(false)
  const [resetTimer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null)

  const setValue = useCallback(
    (newVal: boolean) => {
      setValueRaw(newVal)
      // clear existing timeout
      if (resetTimer) clearTimeout(resetTimer)
      // kick off the reset timer
      if (newVal) setTimer(setTimeout(() => setValueRaw(false), resetDelay))
    },
    [resetTimer, resetDelay, setValueRaw]
  )

  return [value, setValue] as [value: boolean, setValue: (newVal: boolean) => void]
}
