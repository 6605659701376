import { Box, Slide } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { UiTooltip, useAlerts, ZButton } from '@postal-io/postal-ui'
import { BulkSelectedItemsPopover, CenteredBox } from 'components/Common'
import { CATEGORY } from 'components/Postals'
import { NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import {
  PageTitle,
  POSTAL_INVALIDATIONS,
  useApprovedPostalVersion,
  useBackgroundQueue,
  useCollectionPermissions,
  useSession,
} from 'hooks'
import { useBulkSelect } from 'hooks/useBulkSelect'
import { some } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import type { ApprovedPostal, ApprovedProductVariant, Attribution, Currency } from '../../api'
import { CreateApprovedPostalDocument, Status, UpdateApprovedPostalDocument } from '../../api'
import { useMaxCollectionItems } from './Collection'
import { CollectionItemOptionsSelect } from './CollectionItemOptionsSelect'
import { CollectionItemSelect } from './CollectionItemSelect'
import { CollectionProperties } from './CollectionProperties'
import type { EitherItem, EitherVariant } from './utils'
import {
  addItemFieldsToVariant,
  getApprovedInputItemFromVariant,
  groupCollectionItems,
  isItemApprovedPostal,
  sortVariants,
} from './utils'

const eitherItemIdentityFn: (item: EitherItem) => string = (item) =>
  (item as ApprovedPostal).marketplaceProductId ? `approved: ${item.id}` : item.id

enum Step {
  Properties = 'properties',
  Items = 'items',
  Options = 'options',
  // the last 2 steps don't have an actual UI, just represent a behavior (label & API call)
  CreateCollection = 'create',
  AddItems = 'addItems',
}

const stepLabels = {
  [Step.Properties]: 'Create a Collection',
  [Step.Items]: 'Select an Item(s)',
  [Step.Options]: 'Select Options',
  [Step.CreateCollection]: 'Create Collection',
  [Step.AddItems]: 'Add Items to Collection',
}

/**
 * This component is meant to either create a collection or add items to an existing collection.
 * It has different steps in a different order depending upon the entry point.
 */

const CREATE_FLOW_STEPS = [Step.Properties, Step.Items, Step.Options, Step.CreateCollection]
const CREATE_FROM_BULK_SELECT_STEPS = [Step.Properties, Step.Options, Step.CreateCollection]
const ADD_ITEMS_STEPS = [Step.Items, Step.Options, Step.AddItems]

export interface CollectionCreateState {
  attribution?: Attribution
  currency?: Currency
  description?: string
  displayName?: string
  displayNameDirty?: boolean
  enabled: boolean
  name?: string
  ownerId?: string
  step: Step
  teamIds?: any
}

interface CollectionCreateUpdateProps {
  collection?: ApprovedPostal
}

export const CollectionCreateUpdate: React.FC<CollectionCreateUpdateProps> = ({ collection }) => {
  const transform = useApprovedPostalVersion()

  // get items from state
  const { state: locationState } = useLocation() as any
  const { items, returnTo } = locationState ?? {}

  const { session } = useSession()
  const { canCreateShared } = useCollectionPermissions()
  const navigate = useNavigate()

  const onComplete = useCallback(
    (collection: ApprovedPostal) => {
      navigate(`/collections/${collection.id}`, { state: { returnTo: 'Collections' } })
    },
    [navigate]
  )

  const Alert = useAlerts()

  const steps = useMemo(
    () => (collection ? ADD_ITEMS_STEPS : items ? CREATE_FROM_BULK_SELECT_STEPS : CREATE_FLOW_STEPS),
    [collection, items]
  )

  const [state, setState] = useImmer<CollectionCreateState>({
    enabled: true,
    step: steps[0],
    currency: collection?.currency ?? items?.[0]?.currency ?? undefined,
    // default to self if we can't make sharable collections
    ownerId: canCreateShared ? null : session.userId,
  })

  const stepIndex = useMemo(() => steps.indexOf(state.step), [state.step, steps])
  const nextStep = useMemo(() => steps[stepIndex + 1], [steps, stepIndex])
  const prevStep = useMemo(() => steps[stepIndex - 1], [steps, stepIndex])

  const scrollRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    scrollRef.current?.scrollIntoView()
  }, [state.step])

  // Don't allow the user to go over their item limit
  const MAX_ITEMS = useMaxCollectionItems()
  const maxAllowedItems = useMemo(
    () => MAX_ITEMS - (collection ? groupCollectionItems(collection)?.length : 0),
    [MAX_ITEMS, collection]
  )

  const {
    handleBulkSelect,
    isBulkSelected,
    bulkSelected: selectedItems,
    setBulkSelected: setSelectedItems,
  } = useBulkSelect<EitherItem>({ identityFn: eitherItemIdentityFn, initialItems: items, maxItems: maxAllowedItems })

  const {
    handleBulkSelect: handleSelectVariant,
    isBulkSelected: isVariantSelected,
    bulkSelected: selectedVariants,
    setBulkSelected: setSelectedVariants,
  } = useBulkSelect<EitherVariant>()

  const { queue, invalidate } = useBackgroundQueue()
  const createApprovedPostal = useGraphqlMutation(CreateApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.createApprovedPostal.previewGenerationTask)
      invalidate(POSTAL_INVALIDATIONS)
    },
  })
  const updateApprovedPostal = useGraphqlMutation(UpdateApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.updateApprovedPostal.previewGenerationTask)
      invalidate(POSTAL_INVALIDATIONS)
    },
  })

  const [selectedAttribution, setSelectedAttribution] = useState(state.attribution)

  const createCollection = useCallback(async () => {
    if (!selectedItems.length || !selectedVariants.length || !state.name || !state.displayName) {
      return Alert.warning('An item, At least one option, a collection name, and a display name are required.')
    }

    const marketplaceProductId =
      (selectedItems[0] as ApprovedPostal)?.marketplaceProductId ?? (selectedItems[0]?.id as string)

    const items = selectedVariants.map((v) => getApprovedInputItemFromVariant(v as any))

    try {
      // if we have a teamId, it means we are a user or team admin.
      // In this case we want to lock down this collection to this team.
      const teamIds = !!session.teamId ? [session.teamId] : state.teamIds
      const res = await createApprovedPostal.mutateAsync({
        marketplaceProductId,
        data: {
          name: state.name,
          displayName: state.displayName,
          attribution: selectedAttribution,
          status: state.enabled ? Status.Active : Status.Disabled,
          collection: true,
          ownerId: state.ownerId,
          teamIds,
          ...transform(CATEGORY.Collection, { items }),
        },
      })
      Alert.success('Collection Created')
      onComplete(res.createApprovedPostal.postal)
    } catch (err) {
      Alert.error(err)
    }
  }, [
    Alert,
    createApprovedPostal,
    onComplete,
    selectedAttribution,
    selectedItems,
    selectedVariants,
    session.teamId,
    state.displayName,
    state.enabled,
    state.name,
    state.ownerId,
    state.teamIds,
    transform,
  ])

  const addItemsToCollection = useCallback(async () => {
    if (!collection) return Alert.error('No Collection provided to add items to!')

    // prune out any existing variants of the added items to ensure no double counting
    const baseVariants =
      collection.variants
        ?.filter((v) => v.status === Status.Active)
        .filter(
          (v) =>
            !some(selectedItems, (item) =>
              isItemApprovedPostal(item) ? v.approvedPostalId === item.id : v.marketplaceProductId === item.id
            )
        ) ?? []

    const items = [...baseVariants, ...selectedVariants].map((v) => getApprovedInputItemFromVariant(v as any))

    try {
      const res = await updateApprovedPostal.mutateAsync({
        id: collection.id,
        data: {
          ...transform(CATEGORY.Collection, { items }),
        },
      })
      Alert.success(selectedItems.length > 1 ? 'Items Added' : 'Item Added')
      onComplete(res.updateApprovedPostal.postal)
    } catch (err) {
      Alert.error(err)
    }
  }, [Alert, collection, onComplete, selectedItems, selectedVariants, transform, updateApprovedPostal])

  const next = useCallback(
    () =>
      nextStep === Step.CreateCollection
        ? createCollection()
        : nextStep === Step.AddItems
        ? addItemsToCollection()
        : setState((draft) => void (draft.step = nextStep)),
    [addItemsToCollection, createCollection, nextStep, setState]
  )
  const handleBack = useCallback(
    () =>
      prevStep ? setState((draft) => void (draft.step = prevStep)) : returnTo ? navigate(-1) : navigate(`/collections`),
    [navigate, prevStep, returnTo, setState]
  )

  const handleSelectSingleItem = (item: EitherItem) => {
    setSelectedItems([item])
    setSelectedVariants(
      sortVariants(item.variants as ApprovedProductVariant[]).map((v) => addItemFieldsToVariant(item, v))
    )
    setState((draft) => {
      draft.step = Step.Options
    })
  }

  const errorMessage = useMemo(() => {
    switch (state.step) {
      case Step.Properties:
        if (!state.name) return 'Please enter a collection name'
        if (!state.displayName) return 'Please enter a display name'
        if (!state.currency) return 'Please select a currency'
        return
      case Step.Items:
        if (!selectedItems.length) return 'Please select at least one item'
        return
      case Step.Options:
        if (!selectedVariants.length) return 'Please select at least one option'
        return
      default:
    }
  }, [state.step, state.name, state.displayName, state.currency, selectedItems.length, selectedVariants.length])

  const nextLabel = useMemo(() => stepLabels[nextStep], [nextStep])
  const backLabel = useMemo(() => `Back to ${stepLabels[prevStep] ?? returnTo ?? 'Collections'}`, [prevStep, returnTo])

  return (
    <>
      <PageTitle title={stepLabels[state.step]} />
      <Box ref={scrollRef} />
      <SecondaryNavbar
        maxWidth="1280px"
        px={8}
        left={
          <NavbarBackButton
            onClick={handleBack}
            label={backLabel}
          />
        }
        right={
          <>
            {state.step === Step.Items && !!selectedItems.length && (
              <BulkSelectedItemsPopover
                selectedItems={selectedItems}
                onSelect={handleBulkSelect}
              />
            )}
            <UiTooltip
              label={errorMessage}
              shouldWrapChildren
            >
              <ZButton
                colorScheme="atomicBlue"
                isDisabled={!!errorMessage}
                onClick={next}
                isLoading={createApprovedPostal.isLoading || updateApprovedPostal.isLoading}
              >
                {nextLabel}
              </ZButton>
            </UiTooltip>
          </>
        }
        header={stepLabels[state.step]}
      />
      <CenteredBox
        isLoaded
        my={10}
      >
        {/* ----------- Collection Properties Form ----------- */}
        <Slide
          direction="right"
          in={state.step === Step.Properties}
          style={{ position: 'relative' }}
          unmountOnExit
        >
          <CollectionProperties
            next={next}
            items={items}
            errorMessage={errorMessage}
            nextLabel={nextLabel}
            state={state}
            setState={setState}
            selectedAttribution={selectedAttribution}
            setSelectedAttribution={setSelectedAttribution}
          />
        </Slide>

        {/* ----------- Select an Item(s) View ----------- */}
        <Slide
          direction="right"
          in={state.step === Step.Items}
          style={{ position: 'relative' }}
          unmountOnExit
        >
          <CollectionItemSelect
            onSelectItem={handleSelectSingleItem}
            handleBulkSelect={handleBulkSelect}
            isBulkSelected={isBulkSelected}
            restrictCurrency={state.currency}
          />
        </Slide>

        {/* ----------- Select Options View ----------- */}
        <Slide
          direction="right"
          in={state.step === Step.Options}
          style={{ position: 'relative', width: '100%' }}
          unmountOnExit
        >
          <CollectionItemOptionsSelect
            selectedItems={selectedItems}
            isVariantSelected={isVariantSelected}
            handleSelectVariant={handleSelectVariant}
            setSelectedVariants={setSelectedVariants}
          />
        </Slide>
      </CenteredBox>
    </>
  )
}
