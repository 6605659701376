import { Container } from '@chakra-ui/react'
import { PageTitle } from 'hooks'
import { ActivityStreamFilter } from './ActivityStreamFilter'
import { ActivityStreamList } from './ActivityStreamList'
import { useActivityStreamFilter } from './useActivityStreamFilter'

export const ActivityStream = () => {
  const { filter, ...filterState } = useActivityStreamFilter({})

  return (
    <>
      <PageTitle
        title="Recent Activity"
        section="Reporting"
      />
      <ActivityStreamFilter {...filterState} />
      <Container
        maxW="1440px"
        p={0}
      >
        <ActivityStreamList {...filter} />
      </Container>
    </>
  )
}
