import { Box, ScaleFade, Wrap, WrapItem } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  useInfiniteScroll,
  ZButton,
  ZSelectPopover,
  ZSelectPopoverBody,
  ZSelectPopoverContent,
  ZSelectPopoverFooter,
  ZSelectPopoverItem,
  ZSelectPopoverItems,
  ZSelectPopoverSearch,
  ZSelectPopoverTrigger,
  ZTag,
  ZTagCloseButton,
  ZTagLabel,
  ZText,
} from '@postal-io/postal-ui'
import { GetAccountDocument, Status, TeamsDocument } from 'api'
import { DEFAULT_ACCOUNT_TEAM_ID } from 'lib'
import { useMemo, useState } from 'react'
import { useWindowSize } from 'react-use'
import useMeasure from 'react-use-measure'
import { useDebounce } from 'use-debounce'

interface MiniTeamV2 {
  id: string
  name: string
}

const LIMIT = 20

export const TeamsFilterSearch: React.FC<{
  value?: string[]
  onChange: (teamIds: string[]) => void
  isFirst?: boolean
  isLast?: boolean
}> = ({ value, onChange, isFirst, isLast }) => {
  const [search, setSearch] = useState('')
  const [debounced] = useDebounce(search, 400)

  // ZSelectPopoverItems expects an empty array for no value, lets memoize it here
  const realValue = useMemo(() => value ?? [], [value])

  const variables = useMemo(() => {
    return {
      filter: {
        name: debounced ? { contains: debounced } : undefined,
        status: { eq: Status.Active },
      },
      limit: LIMIT,
    }
  }, [debounced])

  const selectedTeamsQuery = useGraphqlInfiniteQuery(
    TeamsDocument,
    {
      filter: { id: { in: realValue }, status: { eq: Status.Active } },
      limit: LIMIT,
    },
    { keepPreviousData: true, enabled: !!realValue.length }
  )

  const teamQuery = useGraphqlInfiniteQuery(TeamsDocument, variables)
  const accountQuery = useGraphqlQuery(GetAccountDocument)

  const isLoading = teamQuery.isLoading || accountQuery.isLoading

  const teams: MiniTeamV2[] = useMemo(() => {
    const accountTeam: MiniTeamV2 = {
      id: DEFAULT_ACCOUNT_TEAM_ID,
      name: accountQuery?.data?.getAccount?.name || 'Default Team',
    }
    const realTeams = teamQuery.mergedData?.teams?.map((t) => ({ id: t.id, name: t.name })) || []
    return [accountTeam, ...realTeams]
  }, [accountQuery?.data?.getAccount?.name, teamQuery.mergedData?.teams])

  const tagTeamList: MiniTeamV2[] = useMemo(() => {
    const accountTeam: MiniTeamV2 = {
      id: DEFAULT_ACCOUNT_TEAM_ID,
      name: accountQuery?.data?.getAccount?.name || 'Default Team',
    }
    const realTeams = selectedTeamsQuery.mergedData?.teams?.map((t) => ({ id: t.id, name: t.name })) || []
    return [accountTeam, ...realTeams]
  }, [accountQuery?.data?.getAccount?.name, selectedTeamsQuery.mergedData?.teams])

  const { bottomRef } = useInfiniteScroll({
    hasMore: teamQuery.hasNextPage,
    loadMore: teamQuery.fetchNextPage,
    loading: teamQuery.isFetching,
  })

  const [ref, { top }] = useMeasure()
  const { height } = useWindowSize()
  const maxH = useMemo(() => `calc(${height - top}px - 5rem)`, [height, top])

  const handleRemove = (team: MiniTeamV2) => {
    const updatedValue = realValue.filter((t) => t !== team.id)
    onChange(updatedValue)
  }

  const tagTeams = useMemo(() => {
    return tagTeamList.filter((t) => realValue?.includes(t.id))
  }, [realValue, tagTeamList])

  const title = useMemo(
    () => (realValue.length ? `Team${realValue.length > 1 ? 's' : ''}` : 'Select a Team'),
    [realValue.length]
  )

  return (
    <ZSelectPopover
      placement="bottom-start"
      offset={[-1, 20]}
      autoFocus
    >
      <ZSelectPopoverTrigger
        isFirst={isFirst}
        isLast={isLast}
        color="atomicGray.500"
        fontWeight="normal"
      >
        {!!realValue.length && (
          <ZText
            color="atomicBlue.400"
            mr={1}
          >{`(${realValue.length}) `}</ZText>
        )}
        {title}
      </ZSelectPopoverTrigger>
      <ZSelectPopoverContent
        maxH={maxH}
        ref={ref}
        data-testid="TeamsFilterSearch_popover"
      >
        <ZSelectPopoverBody>
          <ZSelectPopoverSearch
            onChange={({ target }) => setSearch(target.value)}
            value={search}
            placeholder="Search"
          />
          <Wrap
            w="100%"
            p={4}
            borderBottomColor="atomicGray.100"
            borderBottomWidth="2px"
          >
            {tagTeams?.length ? (
              tagTeams?.map((team) => (
                <ScaleFade
                  key={team.id}
                  initialScale={0.8}
                  in={true}
                >
                  <WrapItem>
                    <ZTag
                      colorScheme="atomicGray"
                      variant="outline"
                      p={2}
                      size="md"
                      onClick={() => handleRemove(team)}
                      _hover={{ cursor: 'pointer' }}
                    >
                      <ZTagLabel
                        fontWeight="bold"
                        color="atomicGray.500"
                        userSelect="none"
                      >
                        {team.name}
                      </ZTagLabel>
                      <ZTagCloseButton />
                    </ZTag>
                  </WrapItem>
                </ScaleFade>
              ))
            ) : (
              <ZText
                p={2}
                color="atomicGray.500"
                userSelect="none"
              >
                Showing all teams
              </ZText>
            )}
          </Wrap>
          <ZSelectPopoverItems
            onChange={onChange}
            value={realValue}
            overflow="scroll"
          >
            {teams.map((team) => {
              return (
                <ZSelectPopoverItem
                  key={team.id}
                  value={team.id}
                >
                  <ZText
                    ml={1}
                    fontWeight="bold"
                    fontSize="md"
                    color="atomicGray.600"
                    userSelect="none"
                  >
                    {team.name}
                  </ZText>
                </ZSelectPopoverItem>
              )
            })}
          </ZSelectPopoverItems>
        </ZSelectPopoverBody>

        <ZSelectPopoverFooter
          borderTopColor="atomicGray.100"
          borderTopWidth="2px"
        >
          {!isLoading && <Box ref={bottomRef} />}
          <ZButton
            variant="link"
            colorScheme="atomicGray"
            onClick={() => onChange([])}
            display={realValue?.length ? 'block' : 'none'}
          >
            Clear
          </ZButton>
        </ZSelectPopoverFooter>
      </ZSelectPopoverContent>
    </ZSelectPopover>
  )
}
