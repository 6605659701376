import type { ApprovedPostal, PlaybookStepDefinition } from 'api'
import { ChronoUnit, PlaybookStepType } from 'api'
import { PostalSendType } from 'components/PostalSend/postalSendHelpers'
import type { PostalSendContext } from 'components/PostalSend/usePostalSend'
import { PostalSendMethod } from 'components/PostalSend/usePostalSend'

export const getPlaybookStepFromContext = (context: PostalSendContext) =>
  ({
    type: PlaybookStepType.Order,
    delay: context.delay,
    delayUnit: ChronoUnit.Days,
    approvedPostalId: context.postal.id,
    variantId: context.variant?.id,
    giftMessage: context.giftMessage,
    deliveryEmail: context.deliveryEmail,
    displayPrice: context.variant?.displayPrice,
    displaySubscriberPrice: context.variant?.displaySubscriberPrice,
    // TODO: Missing field - deliveryEmailTemplateId
    physicalMessage: context.physicalMessage,
    meetingRequestSetting: context.meetingRequestSetting,
    emailSubjectLine: context.emailSubjectLine,
    sendAsContactOwner: context.sendAsContactOwner,
    sendAsUser: context.sendAsUser,
    spendAs: { userId: context.spendAsUserId, teamId: context.spendAsTeamId },
    itemCustomizationInputs: context.itemCustomizationInputs,
    formFieldList: context.formFieldList,
    landingPageCustomization: {
      headerText: context.landingPageHeaderText,
      body: context.landingPageBody,
      includeHeadshot: context.landingPageIncludeHeadshot,
      includeSenderName: context.landingPageIncludeSenderName,
    },
    recipientEmailSettings: { shippedEmailsOn: context.shippedEmailsOn, deliveredEmailsOn: context.deliveredEmailsOn },
  } as PlaybookStepDefinition)

export const getContextFromPlaybookStep = ({
  step,
  postal,
}: {
  step?: PlaybookStepDefinition | null
  postal?: ApprovedPostal
}) =>
  ({
    type: PostalSendType.PlaybookStep,
    method: step && (step?.deliveryEmail ? PostalSendMethod.Email : PostalSendMethod.Direct),
    postal,
    variant: postal?.variants?.find((v) => v.id === step?.variantId),
    delay: step?.delay,
    giftMessage: step?.giftMessage,
    physicalMessage: step?.physicalMessage,
    meetingRequestSetting: step?.meetingRequestSetting,
    emailSubjectLine: step?.emailSubjectLine,
    sendAsContactOwner: step?.sendAsContactOwner,
    sendAsUser: step?.sendAsUser,
    spendAsUserId: step?.spendAs?.userId ?? undefined,
    spendAsTeamId: step?.spendAs?.teamId ?? undefined,
    itemCustomizationInputs: step?.itemCustomizationInputs,
    formFieldList: step?.formFieldList,
    landingPageBody: step?.landingPageCustomization?.body,
    landingPageHeaderText: step?.landingPageCustomization?.headerText,
    landingPageIncludeHeadshot: step?.landingPageCustomization?.includeHeadshot,
    landingPageIncludeSenderName: step?.landingPageCustomization?.includeSenderName,
    deliveredEmailsOn: step?.recipientEmailSettings?.deliveredEmailsOn,
    shippedEmailsOn: step?.recipientEmailSettings?.shippedEmailsOn,
  } as PostalSendContext)
