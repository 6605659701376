import type { UiAlertProps } from '@postal-io/postal-ui'
import { UiAlert, UiLink } from '@postal-io/postal-ui'
import React, { useMemo } from 'react'
import { useHasSeats } from '../../hooks'

interface UserSeatsAlertProps extends Omit<UiAlertProps, 'status'> {
  userCount?: number
  status?: UiAlertProps['status']
}
export const UserSeatsAlert: React.FC<UserSeatsAlertProps> = ({ userCount = 1, status = 'info', ...rest }) => {
  const { hasMoreSeats, requestSeats } = useHasSeats()
  const hasSeats = useMemo(() => hasMoreSeats(userCount), [hasMoreSeats, userCount])
  if (hasSeats) return null
  return (
    <UiAlert
      status={status}
      title="User Seats Limit"
      {...rest}
    >
      Upgrade your postal account through our <UiLink onClick={requestSeats}>Sales Team</UiLink> to access more User
      Seats.
    </UiAlert>
  )
}
