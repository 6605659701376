import type { BasicMetrics } from 'api'

type Metrics = BasicMetrics | null

export const calculateCpt = (metrics?: Metrics): number => {
  const costInCents = metrics?.costInCents || 0
  const touches = metrics?.touches || 0
  return costInCents / touches || 0
}

export const getChangeMetric = (current?: null | number, previous?: null | number) => {
  return (((current || 0) - (previous || 0)) / (current || 1)) * 100
}
