import { Box, Divider, Flex, Skeleton, SkeletonText } from '@chakra-ui/react'
import { ZButton, ZText } from '@postal-io/postal-ui'
import type { ApprovedPostal, ApprovedProductVariant } from 'api'
import { PostalImageCarousel } from 'components/Postal'
import { ApprovedVariantSelect } from 'components/Postals'
import { CATEGORY } from 'components/Postals/data'
import { AnalyticsEventV2, useAnalyticsSend } from 'hooks'
import React, { useMemo } from 'react'
import {
  MdKeyboardArrowLeft,
  MdOutlineCalendarToday,
  MdOutlineGrass,
  MdOutlineLocalShipping,
  MdOutlineNote,
  MdSend,
} from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ExtHeader } from '../Main/ExtHeader'

interface ExtMarketplacePostalInfoProps {
  isLoading: boolean
  postal: ApprovedPostal | undefined
  physicalMessageSupported: boolean | undefined
  containsPerishables: boolean | undefined
  bulkSendAvailable: boolean | undefined
  selectedVariant: ApprovedProductVariant | undefined
  setSelectedVariant: (variant: ApprovedProductVariant) => void
  onNext: Function
}

export const ExtMarketplacePostalInfo: React.FC<ExtMarketplacePostalInfoProps> = (props) => {
  const {
    isLoading,
    postal,
    physicalMessageSupported,
    containsPerishables,
    bulkSendAvailable,
    selectedVariant,
    setSelectedVariant,
    onNext,
  } = props

  const navigate = useNavigate()
  const sendAnalytics = useAnalyticsSend()

  const onBack = () => {
    sendAnalytics({ event: AnalyticsEventV2.ExtensionSendFlowBackClicked })
    navigate(`/extension/marketplace`)
  }

  const orderFlexibility = useMemo(
    () => !!postal?.variantOrderFlexibility || postal?.category === CATEGORY.Collection,
    [postal?.category, postal?.variantOrderFlexibility]
  )

  return (
    <Box p={4}>
      <ExtHeader>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="30px"
          height="30px"
          bg="atomicGray.50"
          borderRadius="50%"
          _hover={{ bg: 'atomicGray.100', cursor: 'pointer' }}
          onClick={onBack}
        >
          <MdKeyboardArrowLeft
            size="16px"
            color="#8492A6"
          />
        </Box>
      </ExtHeader>

      <Box
        minHeight="100vh"
        py={5}
      >
        <ZText
          size="md"
          mb={5}
        >
          Product Information
        </ZText>
        <Skeleton
          isLoaded={!isLoading}
          minH="500px"
        >
          <PostalImageCarousel
            postal={postal}
            containerProps={{
              borderWidth: 0,
              maxH: { base: '300px', md: '350px', lg: '400px' },
              borderBottomWidth: '1px',
            }}
            thumbnailsProps={{ px: 0, py: 5 }}
            imageProps={{ objectFit: 'contain' }}
          />
        </Skeleton>
        {isLoading ? (
          <SkeletonText noOfLines={10} />
        ) : (
          <>
            <Box
              pl="10px"
              pr="20px"
              mt="20px"
            >
              <ZText
                size="lg"
                mb="10px"
              >
                {postal?.name}
              </ZText>
              <ZText
                size="sm"
                color="atomicGray.600"
              >
                {postal?.description}
              </ZText>
            </Box>

            <Flex
              gap={3}
              flexDirection="column"
              mt={5}
            >
              {physicalMessageSupported && (
                <Flex gap={3}>
                  <MdOutlineNote size="20px" />
                  <ZText>Accepts physical note</ZText>
                </Flex>
              )}
              <Flex gap={3}>
                <MdOutlineCalendarToday size="20px" />
                <ZText>Ships on certain days</ZText>
              </Flex>
              {containsPerishables && (
                <Flex gap={3}>
                  <MdOutlineGrass size="20px" />
                  <ZText>Contains perishables</ZText>
                </Flex>
              )}
              {bulkSendAvailable && (
                <Flex gap={3}>
                  <MdOutlineLocalShipping size="20px" />
                  <ZText>Available for bulk shipping</ZText>
                </Flex>
              )}
            </Flex>

            <Divider
              my={5}
              borderTop={0}
              color="atomicGray.200"
              sx={{ marginLeft: '-16px', width: 'calc(100% + 32px)' }}
            />

            {postal && (
              <ApprovedVariantSelect
                postal={postal}
                selectedVariant={selectedVariant}
                setVariant={setSelectedVariant}
                orderFlexibility={orderFlexibility}
              />
            )}
          </>
        )}
      </Box>
      <Box
        display="flex"
        position="fixed"
        justifyContent="flex-end"
        left={4}
        bottom={4}
        right={4}
        zIndex={10}
      >
        <ZButton
          autoFocus
          isDisabled={isLoading}
          isLoading={isLoading}
          ml={2}
          minW="100px"
          leftIcon={
            <MdSend
              fontSize="lg"
              color="white"
            />
          }
          colorScheme="atomicBlue"
          sx={{
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08)',
            borderRadius: '66px',
          }}
          onClick={() => onNext()}
        >
          <ZText
            color="whites.400"
            size="md"
          >
            Next
          </ZText>
        </ZButton>
      </Box>
    </Box>
  )
}
