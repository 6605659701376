import { Flex, Text } from '@chakra-ui/react'
import { UiLoading } from '@postal-io/postal-ui'
import api from 'api/rest'
import { authResponseChannel } from 'components/Extension/broadcast'
import { AnalyticsEventV2, clearProductAccessId, clearSession, useAnalyticsSend } from 'hooks'
import jwtDecode from 'jwt-decode'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export const ExtSso: React.FC = () => {
  const params = useParams()
  const token = params.token as string
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const sendAnalytics = useAnalyticsSend()

  const login = useCallback(async () => {
    if (message) return
    try {
      setLoading(true)
      const res = (await api.loginSso(token)) as any
      const { roles } = jwtDecode(res.token) as any
      if (!roles || roles.length === 0) {
        setMessage('Please ask your Adminstrator for access to Postal')
        clearSession()
        clearProductAccessId()
        setLoading(false)
      } else if (roles.includes('SIGNUP') || roles.includes('ACCESS_SELECTION')) {
        clearSession()
        clearProductAccessId()
        setMessage('You must complete signup in the Postal web app before can use this extension.')
        setLoading(false)
      } else {
        sendAnalytics({ event: AnalyticsEventV2.ExtensionLogin, data: { type: 'SSO' } })
        authResponseChannel.postMessage(res)
        setMessage('You can now close this window.')
        window.close()
        setLoading(false)
      }
    } catch (err) {
      console.error(err)
      setMessage(`Error logging in. ${err.message}`)
      setLoading(false)
    }
  }, [message, sendAnalytics, token])

  useEffect(() => {
    login()
  }, [login])

  return loading ? (
    <UiLoading />
  ) : (
    <Flex
      justifyContent="center"
      alignItems="center"
      p={8}
      w="100%"
    >
      <Text
        fontSize="lg"
        color="gray.700"
      >
        {message}
      </Text>
    </Flex>
  )
}
