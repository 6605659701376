/* eslint-disable no-template-curly-in-string */
export const messageVariables = [
  { id: '1', description: `Contact's First Name`, variable: '${contact.firstName}' },
  { id: '2', description: `Contact's Last Name`, variable: '${contact.lastName}' },
  { id: '3', description: `Contact's Email`, variable: '${contact.emailAddress}' },
  { id: '4', description: `Contact's Job Title`, variable: '${contact.title}' },
  { id: '5', description: `Contact's Company`, variable: '${contact.companyName}' },
  { id: '6', description: `Contact's Phone Number`, variable: '${contact.phoneNumber}' },
  { id: '7', description: `Contact's Owner Name`, variable: '${contact.ownerName}' },
  { id: '8', description: 'Your First Name', variable: '${user.firstName}' },
  { id: '9', description: 'Your Last Name', variable: '${user.lastName}' },
  { id: '10', description: 'Your Email', variable: '${user.emailAddress}' },
  { id: '11', description: 'Your Phone', variable: '${user.phoneNumber}' },
  { id: '12', description: 'Your Title', variable: '${user.title}' },
  { id: '13', description: 'Your Meeting Link', variable: '${user.meetingLink}' },
  { id: '14', description: 'Your Company Name', variable: '${account.displayName}' },
  { id: '15', description: 'Approved Item Name', variable: '${postal.itemName}' },
]
