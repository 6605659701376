import type { ImageProps } from '@chakra-ui/react'
import { Image } from '@chakra-ui/react'
import type { ApprovedPostal } from 'api'
import { useAssets, usePostalImages } from 'hooks'
import React from 'react'
export interface ExtPostalImageProps extends ImageProps {
  postal?: ApprovedPostal
}
export const ExtPostalImage: React.FC<ExtPostalImageProps> = ({ postal, ...rest }) => {
  const [image] = usePostalImages(postal)
  const { assetUrlSrc } = useAssets()
  const img = assetUrlSrc(image?.url)
  if (!img.src) return null
  return (
    <Image
      {...img}
      {...rest}
    />
  )
}
