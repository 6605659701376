import type { FlexProps, IconProps, LinkProps } from '@chakra-ui/react'
import { Flex, Icon, Link } from '@chakra-ui/react'
import type { ExternalMapping } from 'api'
import { ExternalProviders } from 'components/Integrations'
import React from 'react'

interface SystemLinkProps extends IconProps {
  system: string
  mappings?: ExternalMapping[] | null
  rootProps?: LinkProps
}

export const SystemLink: React.FC<SystemLinkProps> = ({ system, mappings, rootProps, ...rest }) => {
  const provider = ExternalProviders.find((p) => p.system === system)

  const href =
    mappings?.find((mapping: ExternalMapping) => {
      return mapping.system === system
    })?.profileUrl || provider?.link

  return (
    <Link
      href={href}
      isExternal
      {...rootProps}
    >
      <Icon
        as={provider?.icon}
        {...rest}
      />
    </Link>
  )
}

interface SystemBadgeProps extends FlexProps {
  system: string
  mappings?: ExternalMapping[] | null
}

export const SystemBadge: React.FC<SystemBadgeProps> = ({ system, mappings, ...rest }) => {
  return (
    <Flex
      w="100%"
      justify="center"
      {...rest}
    >
      <SystemLink
        key={system}
        system={system}
        mappings={mappings}
        width="80px"
        h="auto"
      />
    </Flex>
  )
}
