import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import { useColor, ZHeading, ZText } from '@postal-io/postal-ui'
import type { Address, Contact } from 'api'
import { hasVerifiedAddress } from 'components/Contacts/ContactsData'
import React from 'react'
import { MdCheckCircleOutline } from 'react-icons/md'

interface ExtContactProfileProps extends BoxProps {
  contact: Contact
}
export const ExtContactProfile: React.FC<ExtContactProfileProps> = ({ contact, ...rest }) => {
  const { firstName, lastName, companyName, title, addresses } = contact
  const isVerified = hasVerifiedAddress(addresses as Address[])
  const { colorCode } = useColor()

  return (
    <Box {...rest}>
      <Flex
        columnGap="15px"
        alignItems="center"
      >
        <ZHeading size="h4">{`${firstName} ${lastName}`}</ZHeading>
        {isVerified && (
          <Flex
            bg="atomicBlue.10"
            borderRadius={31}
            padding="3px 8px"
            alignItems="center"
            columnGap="5px"
            height="22px"
          >
            <MdCheckCircleOutline
              size={14}
              color={colorCode('atomicBlue.800')}
            />
            <ZText
              size="sm"
              color="atomicBlue.800"
            >
              Verified
            </ZText>
          </Flex>
        )}
      </Flex>
      <ZText
        size="md"
        color="atomicGray.500"
      >
        {title && `${title}`}
        {companyName && ` at ${companyName}`}
      </ZText>
    </Box>
  )
}
