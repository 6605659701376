import { isNumber } from 'lodash'
import { useMemo } from 'react'

const noop = () => {}

export interface HiddenInputProps {
  value: any
  isRequired?: boolean
  isDisabled?: boolean
  onFocus?: () => void
}
export const HiddenInput: React.FC<HiddenInputProps> = ({ value, isDisabled, isRequired, onFocus }) => {
  const hasValue = useMemo(() => {
    return Array.isArray(value) ? !!value.length : isNumber(value) ? true : !!value
  }, [value])

  if (!!isDisabled) return null

  return (
    <input
      type="text"
      value={hasValue ? '1' : ''}
      onChange={noop}
      required={!!isRequired}
      autoComplete="off"
      style={{
        height: '1px',
        opacity: 0,
        width: '100%',
        position: 'absolute',
      }}
      onFocus={onFocus}
    />
  )
}
