import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlertError } from '@postal-io/postal-ui'
import { isPostalDisabled, PostalSendType } from 'components/PostalSend/postalSendHelpers'
import { PostalSendLoading } from 'components/PostalSend/PostalSendLoading'
import { PostalUnavailable } from 'components/PostalSend/PostalUnavailable'
import { useNavigateSendFlowParams, usePostalPermissions, useRouteBack } from 'hooks'
import React, { useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GetApprovedPostalDocument } from '../../api'
import { PostalSend } from '../PostalSend'

export const ApprovedPostalSend: React.FC = () => {
  const { approvedPostalId } = useParams() as any
  const navigate = useNavigate()
  const { pathname } = useLocation() as any

  const { method, variantId, returnTo, returnPath } = useNavigateSendFlowParams()

  const back = useRouteBack(returnTo, returnPath ?? pathname.replace(/((\/send)|(\/link))(\/\w*)?$/, ''))
  const onNavigateBack = () => (back.path ? navigate(back.path) : navigate(-1))

  const { data, error, isLoading } = useGraphqlQuery(GetApprovedPostalDocument, { id: approvedPostalId })
  const postal = useMemo(() => data?.getApprovedPostal, [data?.getApprovedPostal])

  const { defaultNonLinkSendMethod } = usePostalPermissions(postal)

  const variant = postal?.variants?.find((v) => v.id === variantId)

  const onExitLabel = returnTo ? `Exit to ${returnTo}` : undefined

  function onExit() {
    returnPath ? navigate(returnPath) : onNavigateBack()
  }

  useAlertError(error)

  if (!postal) return null

  if (isLoading)
    return (
      <PostalSendLoading
        onNavigateBack={onNavigateBack}
        backLabel={back.title}
      />
    )

  return isPostalDisabled(postal) ? (
    <PostalUnavailable
      isOpen
      onClose={onNavigateBack}
    />
  ) : (
    <PostalSend
      method={method ?? defaultNonLinkSendMethod}
      type={PostalSendType.Postal}
      postal={postal}
      variant={variant}
      onNavigateBack={onNavigateBack}
      navigateBackLabel={back.title}
      onExit={onExit}
      onExitLabel={onExitLabel}
    />
  )
}
