import React, { useMemo } from 'react'
import { Line } from 'react-konva'

export const GRID_COLOR = '#ddd'
export const GRID_CENTER_COLOR = '#000'
export const GRID_SIZE = 20
export const GRID_STROKE_WIDTH = 0.5

export interface GridProps {
  width: number
  height: number
  gridSize?: number
  gridColor?: string
}

export const Grid: React.FC<GridProps> = ({ width, height, gridSize = GRID_SIZE, gridColor = GRID_COLOR }) => {
  const lines = useMemo(() => {
    const cache = []

    const vertical = [width / 2, 0, width / 2, height]
    const horizontal = [0, height / 2, width, height / 2]
    cache.push([vertical, horizontal])

    const boxes = []
    for (let i = 0; i < height / gridSize; i++) {
      const points = [0, Math.round(i * gridSize), width, Math.round(i * gridSize)]
      boxes.push(points)
    }
    for (let i = 0; i < width / gridSize; i++) {
      const points = [Math.round(i * gridSize), 0, Math.round(i * gridSize), height]
      boxes.push(points)
    }
    cache.push(boxes)

    return cache
  }, [gridSize, height, width])

  const [center, boxes] = lines

  return (
    <>
      {boxes.map((points, idx) => (
        <Line
          key={`box-${idx}`}
          points={points}
          stroke={gridColor}
          strokeWidth={GRID_STROKE_WIDTH}
        />
      ))}
      {center.map((points, idx) => (
        <Line
          key={`center-${idx}`}
          points={points}
          stroke={GRID_CENTER_COLOR}
          strokeWidth={GRID_STROKE_WIDTH}
        />
      ))}
    </>
  )
}
