import { Stack } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiFormControl,
  useAlerts,
  ZFormLabel,
  ZInput,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
} from '@postal-io/postal-ui'
import type { UpdateUserInput, User } from 'api'
import { UpdateUserDocument } from 'api'
import { AnalyticsEvent, useAnalyticsSend } from 'hooks'
import React from 'react'
import { useImmer } from 'use-immer'

interface UserEditProps {
  isOpen: boolean
  onClose: () => void
  user: User
}

export const UserEdit: React.FC<UserEditProps> = ({ isOpen, onClose, user }) => {
  const Alert = useAlerts()
  const sendAnalytics = useAnalyticsSend()

  const updateUser = useGraphqlMutation(UpdateUserDocument, {
    onSuccess: () => sendAnalytics({ event: AnalyticsEvent.UserAccountUpdated }),
  })

  const [form, setForm] = useImmer<UpdateUserInput>({
    firstName: user.firstName,
    lastName: user.lastName,
    title: user.title,
    phoneNumber: user.phoneNumber,
    meetingLink: user.meetingLink,
    status: user.status,
    emailAddress: user.emailAddress,
  })

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      await updateUser.mutateAsync({ id: user.id, data: form })
      Alert.success('User Updated')
      onClose()
    } catch (e) {
      Alert.error(e)
    }
  }

  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target
    setForm((draft: any) => void (draft[name] = value))
  }

  return (
    <ZModal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ZModalOverlay />
      <ZModalContent>
        <form onSubmit={handleOnSubmit}>
          <ZModalHeader>Edit User Profile</ZModalHeader>
          <ZModalCloseButton />
          <ZModalBody>
            <Stack spacing={8}>
              <UiFormControl
                id="firstName"
                isRequired
              >
                <ZFormLabel>First Name</ZFormLabel>
                <ZInput
                  name="firstName"
                  onChange={handleInput}
                  value={form.firstName || ''}
                />
              </UiFormControl>
              <UiFormControl
                id="lastName"
                isRequired
              >
                <ZFormLabel>Last Name</ZFormLabel>
                <ZInput
                  name="lastName"
                  onChange={handleInput}
                  value={form.lastName || ''}
                />
              </UiFormControl>
              <UiFormControl
                id="emailAddress"
                isRequired
              >
                <ZFormLabel>Email</ZFormLabel>
                <ZInput
                  onChange={handleInput}
                  value={form.emailAddress || ''}
                  name="emailAddress"
                />
              </UiFormControl>
              <UiFormControl id="title">
                <ZFormLabel>Title</ZFormLabel>
                <ZInput
                  name="title"
                  onChange={handleInput}
                  value={form.title || ''}
                />
              </UiFormControl>
              <UiFormControl id="phoneNumber">
                <ZFormLabel>Phone</ZFormLabel>
                <ZInput
                  name="phoneNumber"
                  onChange={handleInput}
                  value={form.phoneNumber || ''}
                />
              </UiFormControl>
              <UiFormControl id="meetingLink">
                <ZFormLabel>Meeting Link</ZFormLabel>
                <ZInput
                  name="meetingLink"
                  onChange={handleInput}
                  value={form.meetingLink || ''}
                />
              </UiFormControl>
            </Stack>
          </ZModalBody>
          <ZModalButtons
            confirmText="Update User"
            confirmProps={{
              type: 'submit',
            }}
            isConfirmDisabled={updateUser.isLoading}
            isConfirmLoading={updateUser.isLoading}
            onClose={onClose}
          />
        </form>
      </ZModalContent>
    </ZModal>
  )
}
