import { Box } from '@chakra-ui/react'
import { humanize } from '@postal-io/postal-ui'
import { CenteredBox } from 'components/Common'
import { useMe, usePostalFilters, useSession } from 'hooks'
import { StorageKeys } from 'lib'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import type { MarketplaceSearchQueryInput, SearchContext } from '../../api/index'
import { Status } from '../../api/index'
import { MARKETPLACE_APPROVED_ITEM_TYPES, MARKETPLACE_ITEM_TYPES } from './data'
import { MarketplaceSearchBar } from './MarketplaceSearchBar'
import { MarketplaceContext, MarketplaceView, MARKETPLACE_INITIAL_STATE, useMarketplace } from './useMarketplace'

// convert a string value into a marketplace v2 filter value
export const getMarketplaceV2Filter = (val: string) => [
  {
    name: humanize(val),
    value: val,
  },
]

export function useSearchContext() {
  const {
    session: { userId, accountId, teamId },
  } = useSession()

  const context = {
    accountId,
    userId,
  } as SearchContext

  if (teamId) context.teamIds = [teamId]

  return context
}

export const MarketplacePage: React.FC = () => {
  const scrollTopRef = useRef<HTMLDivElement>(null)

  // for legacy usage
  const location = useLocation()
  const { routerState, pathname } = location as any

  const { state, setNumFilters, setNumResults, setNumResultsText } = useMarketplace({
    initialState: MARKETPLACE_INITIAL_STATE,
  })

  // const [state, setState] = useImmer<State>(initialState)

  const { approvedCurrencies } = useMe()

  const defaultVariables = useMemo(() => {
    const variables: any = {
      filters: {
        status: [Status.Active],
      },
      searchContext: {
        itemTypes: state.view === MarketplaceView.Postals ? MARKETPLACE_APPROVED_ITEM_TYPES : MARKETPLACE_ITEM_TYPES,
      },
    }

    if (!isEmpty(approvedCurrencies)) variables.currency = approvedCurrencies
    return variables
  }, [approvedCurrencies, state.view])

  const searchContext = useSearchContext()

  const staticVariables = useMemo(() => {
    return { searchContext }
  }, [searchContext])

  const marketplaceFilters = usePostalFilters<MarketplaceSearchQueryInput>({
    isV2: true,
    defaultVariables,
    persistKey: StorageKeys.MarketplaceFilter,
    staticVariables,
  })

  // sometimes we are still using the router state to filters to this page
  useEffect(() => {
    if (!!routerState && Object.keys(routerState).length) {
      Object.entries(routerState).forEach((entry) => {
        marketplaceFilters.updateFilter(entry[0] as string, getMarketplaceV2Filter(entry[1] as string), 0)
      })
    }
    if (state.view === MarketplaceView.Home) {
      marketplaceFilters.clearFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.view])

  useEffect(() => {
    if (!pathname.includes('/items/marketplace')) return
    const searchParams = new URLSearchParams(location.search)
    const vendor = searchParams.get('vendor')
    // set the vendor in marketplace filters and remove everything
    // else so we can see all the products
    if (vendor) {
      marketplaceFilters.clearFilters()
      marketplaceFilters.updateFilter('brandName', getMarketplaceV2Filter(vendor), 0)
    }
    // lets remove this to avoid confusion, this shouldn't trigger a navigation
    // with react-router
    window.history.replaceState(null, '', window.location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    setNumFilters(Object.keys(marketplaceFilters.filters).length ?? 0)
  }, [marketplaceFilters.filters, setNumFilters])

  return (
    <CenteredBox isLoaded>
      <MarketplaceContext.Provider
        value={{
          state,
          scrollTopRef,
          setNumFilters,
          setNumResults,
          setNumResultsText,
          marketplaceFilters,
        }}
      >
        <Box ref={scrollTopRef} />
        <MarketplaceSearchBar />
        <Outlet />
      </MarketplaceContext.Provider>
    </CenteredBox>
  )
}
