import type { SkeletonProps } from '@chakra-ui/react'
import { AspectRatio, Box, Flex, HStack, SkeletonCircle, useBreakpointValue } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiSlideProps } from '@postal-io/postal-ui'
import {
  UiLiftUpEffect,
  UiSlide,
  UiSlideCard,
  UiSlideCards,
  UiSlideContainer,
  UiSlideControls,
  useAlertError,
  ZHeading,
  ZText,
} from '@postal-io/postal-ui'
import { GetBrandsCategoriesDocument } from 'api'
import { chunk } from 'lodash'
import sortBy from 'lodash/sortBy'
import React, { useMemo } from 'react'
import { useAcl } from '../../hooks/useAcl'
import { CATEGORIES_ORDER, CATEGORY, CATEGORY_DEFAULT_IMAGE_MAP } from '../Postals/data'

interface CategoryCircleProps extends SkeletonProps {
  isLoading?: boolean
  imageUrl?: string
}

const CategoryCircle: React.FC<CategoryCircleProps> = ({ isLoading, children, imageUrl, onClick, ...rest }) => {
  return (
    <SkeletonCircle
      data-testid="Marketplace_CategoryCard_Generic"
      w="100%"
      h="100%"
      isLoaded={!isLoading}
      {...rest}
    >
      <UiLiftUpEffect borderRadius="full">
        <AspectRatio ratio={1}>
          <Box
            borderRadius="full"
            bgColor="atomicBlue.300"
            color="white"
            cursor="pointer"
            backgroundImage={imageUrl}
            backgroundSize="cover"
            backgroundColor="white"
            backgroundPosition="center"
            boxShadow="card"
            onClick={onClick}
          />
        </AspectRatio>
      </UiLiftUpEffect>
    </SkeletonCircle>
  )
}

interface MarketplaceSectionCategoriesV2Props extends Omit<UiSlideProps, 'onSelect'> {
  isLoading?: boolean
  onSelect: (category: string) => void
}

export const MarketplaceSectionCategories: React.FC<MarketplaceSectionCategoriesV2Props> = ({
  isLoading,
  onSelect,
  ...rest
}) => {
  const { hasFeature } = useAcl()
  const hasEvents = hasFeature('events')
  const chunkSize = useBreakpointValue({ base: 1, md: 3, lg: 5, xl: 9 }) ?? 0

  const getBrandsCategories = useGraphqlQuery(GetBrandsCategoriesDocument)

  const { categories } = getBrandsCategories.data?.getBrandsCategories || {}

  // sort the categories by the CATEGORIES_ORDER defined in data
  // if we don't have the swag or events feature enabled, then hide it
  const sortedCategories = useMemo(() => {
    if (!categories) return []
    const ordered = categories.map((c) => ({ ...c, order: CATEGORIES_ORDER.indexOf(c.name) }))
    const filtered = ordered.filter((c) => {
      // hide holiday category when not applicable
      if (c.name === CATEGORY.Holiday) return false
      // only include categories defined in the CATEGORIES_ORDER list
      if (!CATEGORIES_ORDER.includes(c.name)) return false
      if (!hasEvents) {
        return c.name !== CATEGORY.Events
      }
      return c
    })
    return sortBy(filtered, 'order')
  }, [categories, hasEvents])

  useAlertError(getBrandsCategories.error)

  const categoryGroups = useMemo(() => {
    return chunk(sortedCategories, chunkSize)
  }, [chunkSize, sortedCategories])

  return (
    <UiSlide {...rest}>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <ZHeading
          as="h2"
          size="h5"
          w="100%"
          variant="bold"
        >
          Shop by category
        </ZHeading>

        <UiSlideControls />
      </Flex>

      <UiSlideContainer
        pt={6}
        mx={-5}
      >
        <UiSlideCards>
          {categoryGroups?.map((group, idx) => (
            <UiSlideCard
              key={idx}
              id={`card-${idx}`}
            >
              <HStack
                px={5}
                key={idx}
                justifyContent={group.length < chunkSize ? 'flex-start' : 'space-between'}
                spacing={5}
              >
                {group.map((category) => {
                  return (
                    <Flex
                      key={category.name}
                      w="143px"
                      flexDirection="column"
                      alignItems="center"
                      gap={2}
                    >
                      <CategoryCircle
                        imageUrl={CATEGORY_DEFAULT_IMAGE_MAP[category.name]}
                        onClick={() => onSelect(category.name)}
                        isLoading={isLoading}
                      />
                      <ZText
                        color="atomicGray.500"
                        whiteSpace="nowrap"
                      >
                        {category.name}
                      </ZText>
                    </Flex>
                  )
                })}
              </HStack>
            </UiSlideCard>
          ))}
        </UiSlideCards>
      </UiSlideContainer>
    </UiSlide>
  )
}
