import type { TextProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'
import { LexendFontWeight, ZMoney, ZText } from '@postal-io/postal-ui'
import type { ApprovedProductVariant } from 'api'
import React, { useMemo } from 'react'

interface ExtMarketplaceTextPriceRangeV2Props extends TextProps {
  item: any
}

export const ExtMarketplaceTextPriceRange: React.FC<ExtMarketplaceTextPriceRangeV2Props> = ({ item }) => {
  const priceRange = useMemo(() => {
    const variants = item?.variants as ApprovedProductVariant[]
    const priceObj = variants?.reduce(
      (obj, v) => {
        const variantPrice = v?.price
        if (variantPrice < obj.min) obj.min = variantPrice
        if (variantPrice > obj.max) obj.max = variantPrice
        return obj
      },
      { min: 999999, max: 0 } as { min: number; max: number }
    )

    return priceObj
  }, [item?.variants])

  const estimatedShippingCost = useMemo(() => item?.variants?.[0]?.shippingPriceEstimateMax, [item?.variants])

  return (
    <Flex overflow="hidden">
      <ZMoney
        cents={priceRange?.min}
        size="sm"
        fontWeight={LexendFontWeight.Bold}
        color="atomicGray.700"
      />
      <ZText
        size="sm"
        color="atomicGray.700"
      >
        &nbsp;-&nbsp;
      </ZText>
      <ZMoney
        cents={priceRange?.max}
        size="sm"
        fontWeight={LexendFontWeight.Bold}
        color="atomicGray.700"
      />
      <ZText
        size="sm"
        color="atomicGray.500"
      >
        &nbsp;+&nbsp;
        <ZMoney
          cents={estimatedShippingCost ?? 0}
          size="sm"
          color="atomicGray.500"
        />
        &nbsp;shipping
      </ZText>
    </Flex>
  )
}
