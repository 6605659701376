import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Grid, useBreakpointValue, Wrap } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { ZHeading, ZLink } from '@postal-io/postal-ui'
import type { MarketplaceProduct } from 'api'
import { SearchMarketplaceProductsDocument } from 'api'
import { AnalyticsEventV2, useAnalyticsSend } from 'hooks'
import { shuffle } from 'lodash'
import React, { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CATEGORIES_ITEMS_ONLY,
  MARKETPLACE_INTERNATIONAL_SHIP_TO,
  MARKETPLACE_V2_INTERNATIONAL_SHIP_TO,
} from '../Postals/data'
import { OldMarketplaceCardProduct } from './MarketplaceCardProduct'
import { getMarketplaceV2Filter } from './MarketplacePage'
import { MarketplaceContext } from './useMarketplace'

interface MarketplaceSectionInternationalV2Props extends Omit<BoxProps, 'onSelect'> {
  isLoading?: boolean
  onSelect: (product: MarketplaceProduct) => void
}

export const MarketplaceSectionInternational: React.FC<MarketplaceSectionInternationalV2Props> = ({
  isLoading,
  onSelect,
  ...rest
}) => {
  const navigate = useNavigate()
  const numColumns = useBreakpointValue({ base: 1, md: 3, xl: 5 })
  const sendAnalytics = useAnalyticsSend()

  const {
    marketplaceFilters: { updateFilter },
  } = useContext(MarketplaceContext)

  const searchMarketplaceProducts = useGraphqlQuery(SearchMarketplaceProductsDocument, {
    filter: { geographicMarkets: { in: MARKETPLACE_INTERNATIONAL_SHIP_TO }, category: { in: CATEGORIES_ITEMS_ONLY } },
    // use larger LIMIT to make shuffling possible
    limit: 50,
  })
  const marketplaceProducts = useMemo(() => {
    return searchMarketplaceProducts?.data?.searchMarketplaceProducts ?? []
  }, [searchMarketplaceProducts?.data?.searchMarketplaceProducts])

  const randomMarketplaceProducts = useMemo(
    () => shuffle([...marketplaceProducts, ...marketplaceProducts, ...marketplaceProducts]),
    [marketplaceProducts]
  ).slice(0, numColumns === 1 ? 5 : numColumns === 3 ? 9 : 10)

  const handleClickShipTo = (shipTo: { label: string; value: string }) => {
    sendAnalytics({
      event: AnalyticsEventV2.Marketplace_InternationalCountry_Selected,
      data: { shipTo: shipTo.label },
    })
    updateFilter('shipToCountries', getMarketplaceV2Filter(shipTo.value), 0)
    navigate('/items/marketplace')
  }

  const handleSelectInternationalItem = (item: MarketplaceProduct) => {
    sendAnalytics({
      event: AnalyticsEventV2.Marketplace_InternationalItem_Selected,
      data: { id: item.id, name: item.name },
    })
    onSelect(item)
  }

  return (
    <Box
      w="100%"
      {...rest}
    >
      <Flex
        w="100%"
        align="baseline"
        gap={10}
      >
        <ZHeading
          as="h2"
          size="h5"
          variant="bold"
          mb={6}
          noOfLines={1}
        >
          International Items (Popular Items)
        </ZHeading>
        <Wrap
          spacing={5}
          mb={6}
        >
          {MARKETPLACE_V2_INTERNATIONAL_SHIP_TO.map((shipTo) => (
            <ZLink
              key={shipTo.value}
              color="atomicGray.500"
              variant="bold"
              onClick={() => handleClickShipTo(shipTo)}
            >
              {shipTo.label}
            </ZLink>
          ))}
        </Wrap>
      </Flex>
      <Grid
        templateColumns={{ base: '1fr', md: '1fr 1fr 1fr', lg: 'repeat(5, 1fr)' }}
        gap={5}
      >
        {randomMarketplaceProducts.map((product, index) => (
          <OldMarketplaceCardProduct
            key={index}
            isLoading={isLoading}
            variant="collapse"
            product={product}
            onSelect={handleSelectInternationalItem}
          />
        ))}
      </Grid>
    </Box>
  )
}
