import type { BoxProps, DrawerProps } from '@chakra-ui/react'
import { Box, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex } from '@chakra-ui/react'
import { overlayProps } from '@postal-io/postal-ui'
import type { PropsWithChildren, ReactElement } from 'react'
import React from 'react'

// const XS_HEIGHT = 600

interface DrawerDrawerProps extends DrawerProps {
  header?: ReactElement
  buttons?: ReactElement
  bodyProps?: BoxProps
}

export const ModalDrawer: React.FC<PropsWithChildren<DrawerDrawerProps>> = ({
  header,
  buttons,
  bodyProps,
  children,
  ...rest
}) => {
  return (
    <Drawer
      size="lg"
      {...rest}
    >
      <DrawerOverlay {...overlayProps} />
      <DrawerContent borderRadius="10px 10px 0px 0px">
        <DrawerHeader>{header}</DrawerHeader>
        <DrawerBody {...bodyProps}>
          <Flex
            w="100%"
            h="100%"
            flexDir="column"
          >
            {children}
            <Box h="1px">&nbsp;</Box>
          </Flex>
        </DrawerBody>
        {buttons && (
          <Box
            p={4}
            pos="fixed"
            bottom={0}
            left={0}
            right={0}
            display="flex"
            justifyContent="flex-end"
            gap={2}
          >
            {buttons}
          </Box>
        )}
      </DrawerContent>
    </Drawer>
  )
}
