import { Container, Grid } from '@chakra-ui/react'
import { PageTitle } from 'hooks'
import { ReportingFilter, useReportingFilter } from '../ReportingFilter'
import { EngagementCard } from './EngagementCard'
import { GiftEmailSendsCard } from './GiftEmailSendsCard'
import { GiftEmailStatsCard } from './GiftEmailStatsCard'
import { MagicLinkStatsCard } from './MagicLinkStatsCard'
import { MeetingsBookedStatsCard } from './MeetingsBookedStatsCard'
import { PopularSendsCard } from './PopularSendsCard'
import { SpendCard } from './SpendCard'

export const Overview = () => {
  const { filter, ...filterState } = useReportingFilter({})

  return (
    <>
      <PageTitle
        title="Overview"
        section="Reporting"
      />
      <ReportingFilter {...filterState} />

      <Container
        maxW="1440px"
        px={0}
      >
        <Grid
          templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
          gap={8}
          maxW="1440px"
          zIndex={0}
        >
          <GiftEmailSendsCard
            startDate={filter.startDate}
            endDate={filter.endDate}
            teamIds={filter.teamIds}
            userIds={filter.userIds}
            groupBy={filter.groupBy}
            gridColumn="1 / 2"
            zIndex={0}
          />
          <SpendCard
            startDate={filter.startDate}
            endDate={filter.endDate}
            teamIds={filter.teamIds}
            userIds={filter.userIds}
            groupBy={filter.groupBy}
            gridColumn="2 / 3"
            zIndex={0}
          />
          <MeetingsBookedStatsCard
            startDate={filter.startDate}
            endDate={filter.endDate}
            teamIds={filter.teamIds}
            userIds={filter.userIds}
            gridColumn="1 / 2"
            zIndex={0}
          />
          <EngagementCard
            startDate={filter.startDate}
            endDate={filter.endDate}
            teamIds={filter.teamIds}
            userIds={filter.userIds}
            gridColumn="2 / 3"
            zIndex={0}
          />

          <PopularSendsCard
            startDate={filter.startDate}
            endDate={filter.endDate}
            teamIds={filter.teamIds}
            userIds={filter.userIds}
            gridColumn="1 / 3"
            zIndex={0}
          />
          <GiftEmailStatsCard
            startDate={filter.startDate}
            endDate={filter.endDate}
            teamIds={filter.teamIds}
            userIds={filter.userIds}
            gridColumn="1 / 2"
            zIndex={0}
          />

          <MagicLinkStatsCard
            startDate={filter.startDate}
            endDate={filter.endDate}
            teamIds={filter.teamIds}
            userIds={filter.userIds}
            gridColumn="2 / 3"
            zIndex={0}
            h="min-content"
          />
        </Grid>
      </Container>
    </>
  )
}
