import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlFetch, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZButtonProps } from '@postal-io/postal-ui'
import { ZButton } from '@postal-io/postal-ui'
import {
  CreateEmailPreviewUrlsDocument,
  GetAccountDocument,
  PreviewGiftEmailAcceptancePageDocument,
  PreviewMagicLinkDocument,
} from 'api'
import { AnalyticsEventV2, useAnalyticsSend } from 'hooks'
import React, { useCallback, useMemo, useState } from 'react'
import { MdOutlineVisibility } from 'react-icons/md'
import { useMeasure } from 'react-use'
import { useExtension } from '../../hooks/useExtension'
import { Iframe } from '../Common'
import type { PostalSendContext } from './usePostalSend'
import { giftMessageTitle, PostalSendMethod } from './usePostalSend'
import { useTemplatePreview } from './useTemplatePreview'

interface PostalReviewPopOverProps extends ZButtonProps {
  context: PostalSendContext
  isLandingPage?: boolean
}

export const PostalReviewPopOver: React.FC<PostalReviewPopOverProps> = ({ context, isLandingPage, ...rest }) => {
  const accountQuery = useGraphqlQuery(GetAccountDocument)
  const account = useMemo(() => accountQuery.data?.getAccount, [accountQuery.data?.getAccount])
  const disclosure = useDisclosure()
  const [ref, { height }] = useMeasure<HTMLDivElement>()
  const { contacts, postal, variant, method, sendAsUser } = context
  const sendAnalytics = useAnalyticsSend()
  const { isExtension } = useExtension()
  const [previewUrl, setPreviewUrl] = useState<string>()

  const { emailSubjectPreview, giftMessagePreview, landingPageBodyPreview, landingPageHeaderPreview } =
    useTemplatePreview(context)

  const isLink = method === PostalSendMethod.Link

  // Email Message is required - don't show preview if not in state
  const isDisabled = useMemo(() => {
    return !isLink && !isLandingPage && !context.giftMessage ? true : false
  }, [context.giftMessage, isLandingPage, isLink])

  const previewMagicLink = useGraphqlFetch(PreviewMagicLinkDocument)
  const createPreviewGiftEmailAcceptancePage = useGraphqlFetch(PreviewGiftEmailAcceptancePageDocument)
  const createEmailPreviewUrls = useGraphqlFetch(CreateEmailPreviewUrlsDocument)
  const includeHeadshot = context.landingPageIncludeHeadshot
  const includeSenderName = context.landingPageIncludeSenderName

  const fetchPreviewLink = useCallback(async () => {
    if (isLink) {
      const query = await previewMagicLink({
        approvedPostalId: postal?.id,
        variantId: variant?.id as string,
        message: giftMessagePreview,
        landingPageCustomization: {
          headerText: landingPageHeaderPreview,
          body: landingPageBodyPreview,
          includeHeadshot: includeHeadshot,
          includeSenderName: includeSenderName,
        },
      })
      const url = query?.previewMagicLink.htmlUrl || ''
      setPreviewUrl(query?.previewMagicLink.htmlUrl || '')
      return url
    } else if (isLandingPage) {
      const query = await createPreviewGiftEmailAcceptancePage({
        data: {
          contactId: contacts?.items?.[0]?.id,
          approvedPostalId: postal?.id,
          variantId: variant?.id as string,
          message: giftMessagePreview,
          sendAsUser: sendAsUser,
          landingPageCustomization: {
            headerText: landingPageHeaderPreview,
            body: landingPageBodyPreview,
            includeHeadshot: includeHeadshot,
            includeSenderName: includeSenderName,
          },
        },
      })
      const url = query.previewGiftEmailAcceptancePage.htmlUrl || ''
      setPreviewUrl(url)
      return url
    } else {
      const previewQuery = await createEmailPreviewUrls({
        data: {
          contactId: contacts?.items?.[0]?.id,
          giftMessage: giftMessagePreview,
          emailSubjectLine: emailSubjectPreview,
          approvedPostalId: postal?.id,
          approvedPostalVariantId: variant?.id,
          emailTemplateId: account?.emailTemplateId,
          sendAsUser: sendAsUser,
        },
      })
      const url = previewQuery.createEmailPreviewUrls?.[0]?.htmlUrl || ''
      setPreviewUrl(url)
      return url
    }
  }, [
    isLink,
    isLandingPage,
    previewMagicLink,
    postal?.id,
    variant?.id,
    giftMessagePreview,
    landingPageHeaderPreview,
    landingPageBodyPreview,
    includeHeadshot,
    includeSenderName,
    createPreviewGiftEmailAcceptancePage,
    contacts?.items,
    sendAsUser,
    createEmailPreviewUrls,
    emailSubjectPreview,
    account?.emailTemplateId,
  ])

  const title = giftMessageTitle(context)

  const handleClick = async () => {
    if (isLandingPage) sendAnalytics({ event: AnalyticsEventV2.SendFlow_LandingPagePreview_Clicked })
    else sendAnalytics({ event: AnalyticsEventV2.SendFlow_GiftEmailPreview_Clicked })

    const _previewUrl = await fetchPreviewLink()

    if (isExtension) {
      window.open(_previewUrl, 'postal-preview', 'width=600,height=800,noopener=true,noreferrer=true')
    } else disclosure.onToggle()
  }

  return (
    <>
      <ZButton
        leftIcon={<MdOutlineVisibility size="16px" />}
        variant="link"
        onClick={handleClick}
        alignItems="center"
        fontSize="body-md"
        color="atomicBlue.400"
        _hover={{ color: 'atomicBlue.600' }}
        mt={3}
        p={0}
        h="unset"
        isDisabled={isDisabled}
        {...rest}
      >
        Preview
      </ZButton>

      <Modal
        size="cover"
        scrollBehavior="inside"
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        closeOnEsc
        closeOnOverlayClick
      >
        <ModalOverlay />
        <ModalContent maxH="100%">
          <ModalHeader>{!!isLandingPage ? 'Landing Page' : title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody ref={ref}>
            <Iframe
              src={previewUrl}
              minH={height}
              maxW="90rem"
              mx="auto"
              borderRadius={5}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
