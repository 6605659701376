import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import { UiCard, UiCardHeader } from '@postal-io/postal-ui'
import { Granularity, TriggerAnalyticsDocument } from 'api'
import { sub } from 'date-fns'
import React, { useEffect, useMemo } from 'react'

// We need to get this analytics query to give us data
const enabled = false
export interface TriggerStatsProps extends UiCardProps {
  triggerId: string
}
export const TriggerStats: React.FC<TriggerStatsProps> = ({ triggerId, ...rest }) => {
  const startDate = useMemo(() => sub(new Date(), { months: 12 }), [])
  const getAnalytics = useGraphqlQuery(
    TriggerAnalyticsDocument,
    {
      triggerId,
      granularity: Granularity.All,
      startDate,
    },
    { enabled: enabled }
  )

  useEffect(() => {
    if (enabled) console.log(getAnalytics.data)
  }, [getAnalytics.data])

  if (!enabled) return null

  return (
    <UiCard {...rest}>
      <UiCardHeader>Stats</UiCardHeader>
      <p>Will Go Here</p>
    </UiCard>
  )
}
