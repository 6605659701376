import { Box, Flex } from '@chakra-ui/react'
import {
  UiButton,
  UiFormControl,
  UiGoogleCaptchaPolicies,
  UiInput,
  UiLink,
  useAlerts,
  ZHeading,
  ZText,
} from '@postal-io/postal-ui'
import { PageTitle, useRecaptcha, useSession } from 'hooks'
import { isLocalOrTestEnvironment } from 'lib'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { ConfirmEmail } from './ConfirmEmail'
import { LogIn } from './Login'
import { LogInHeaderBlack } from './LogInHeader'
import { MainWithMarketingPane } from './Main'

const ForgotPassword: React.FC<{ toggle: () => void }> = ({ toggle }) => {
  const [form, setForm] = useImmer({ userName: '' })
  const [resetSent, setResetSent] = useState('')
  const [loading, setLoading] = useState(false)
  const Alert = useAlerts()
  const { forgotPassword } = useSession()
  const { executeRecaptcha } = useRecaptcha()
  const navigate = useNavigate()

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const { userName } = form
    setLoading(true)
    try {
      const siteVerify = (await executeRecaptcha?.('forgotPassword')) || ''
      if (userName && siteVerify) {
        await forgotPassword({ userName, siteVerify })
        Alert.success('Password reset is on the way')
        setResetSent(userName)
        if (isLocalOrTestEnvironment) {
          setTimeout(() => navigate(`/forgotpw/${siteVerify}`), 2000)
        }
      } else {
        Alert.error('An error has occured')
      }
    } catch (err) {
      Alert.warning(err)
    } finally {
      setLoading(false)
    }
  }

  if (resetSent)
    return (
      <ConfirmEmail
        emailAddress={resetSent}
        isReset={!!resetSent}
      />
    )

  return (
    <>
      <PageTitle title="Forgot Password" />
      <LogInHeaderBlack>
        <Flex
          direction="column"
          w="100%"
          maxW="500px"
          alignItems="center"
        >
          <ZHeading
            fontWeight={600}
            as="h1"
            size="md"
            color="white"
            pt={4}
            textAlign="center"
            mt={4}
          >
            Forgot Password
          </ZHeading>
          <ZText
            my={8}
            color="white"
          >
            Please enter your registered email address below and we will send you a password reset link to your email.
          </ZText>
          <Box w="100%">
            <form onSubmit={onSubmit}>
              <UiFormControl
                id="userName"
                mb={4}
              >
                <UiInput
                  name="userName"
                  type="email"
                  backgroundColor="none"
                  color="white"
                  _placeholder={{ color: 'white' }}
                  size="lg"
                  fontSize="body-md"
                  height="50px"
                  placeholder="Email Address"
                  autoCapitalize="off"
                  isRequired
                  value={form.userName}
                  onChange={(e) => setForm((draft) => void (draft.userName = e.target.value))}
                />
              </UiFormControl>
              <UiLink
                onClick={toggle}
                color="atomicBlue.100"
              >
                I remember my password
              </UiLink>
              <UiButton
                type="submit"
                mt={6}
                w="100%"
                colorScheme="secondary"
                textTransform="none"
                size="md"
                isDisabled={loading}
                isLoading={loading}
              >
                Reset Password
              </UiButton>
              <UiGoogleCaptchaPolicies
                linkProps={{ fontSize: 'xs', fontWeight: 'hairline', color: 'secondary.500' }}
                mt={5}
                maxW="350px"
                textAlign="center"
                mx="auto"
                color="white"
                fontSize="xs"
                fontWeight="hairline"
              />
            </form>
          </Box>
        </Flex>
      </LogInHeaderBlack>
    </>
  )
}

export const SignIn = () => {
  const [forgot, setForgot] = useState(false)
  const toggle = () => setForgot(!forgot)

  return (
    <>
      <PageTitle title="Sign In" />

      <MainWithMarketingPane>
        {forgot ? <ForgotPassword toggle={toggle} /> : <LogIn toggle={toggle} />}
      </MainWithMarketingPane>
    </>
  )
}
