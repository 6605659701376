import { Box, Grid } from '@chakra-ui/react'
import type { ZCardProps } from '@postal-io/postal-ui'
import { UiSeparator, ZCard, ZCardHeader } from '@postal-io/postal-ui'
import { PopularSendsGiftEmailsColumn } from './PopularSendsGiftEmailsColumn'
import { PopularSendsMagicLinksColumn } from './PopularSendsMagicLinksColumn'
import { PopularSendsOverallColumn } from './PopularSendsOverallColumn'

export const MIN_ENTRIES = 4

interface PopularSendsCardV2Props extends ZCardProps {
  startDate: Date
  endDate: Date
  teamIds?: string[]
  userIds?: string[]
}

export const PopularSendsCard: React.FC<PopularSendsCardV2Props> = ({
  startDate,
  endDate,
  teamIds,
  userIds,
  ...rest
}) => {
  return (
    <ZCard
      variant="form"
      {...rest}
    >
      <ZCardHeader
        display="flex"
        p={4}
        pb={2}
        fontSize="lg"
      >
        Most Popular Sends
      </ZCardHeader>
      <Grid
        templateColumns={{ base: '1fr', lg: '1fr 4rem 1fr 4rem 1fr' }}
        mt={4}
        p={4}
      >
        <Box>
          <ZCardHeader>Gift Email</ZCardHeader>
          <UiSeparator
            separator={
              <Box
                h="1px"
                bg="atomicGray.200"
              />
            }
          >
            <PopularSendsGiftEmailsColumn
              startDate={startDate}
              endDate={endDate}
              teamIds={teamIds}
              userIds={userIds}
            />
          </UiSeparator>
        </Box>
        <Box
          w="1px"
          h="100%"
          bg="gray.200"
          justifySelf="center"
        />
        <Box>
          <ZCardHeader>MagicLink</ZCardHeader>
          <UiSeparator
            separator={
              <Box
                h="1px"
                bg="gray.200"
              />
            }
          >
            <PopularSendsMagicLinksColumn
              startDate={startDate}
              endDate={endDate}
              teamIds={teamIds}
              userIds={userIds}
            />
          </UiSeparator>
        </Box>
        <Box
          w="1px"
          h="100%"
          bg="gray.200"
          justifySelf="center"
        />
        <Box>
          <ZCardHeader>Overall Accepted</ZCardHeader>
          <UiSeparator
            separator={
              <Box
                h="1px"
                bg="gray.200"
              />
            }
          >
            <PopularSendsOverallColumn
              startDate={startDate}
              endDate={endDate}
              teamIds={teamIds}
              userIds={userIds}
            />
          </UiSeparator>
        </Box>
      </Grid>
    </ZCard>
  )
}
