export enum CustomEmail {
  Confirmation = 'confirmationCustomization',
  InviteExpiring = 'inviteExpiringCustomization',
  ReminderTomorrow = 'reminderTomorrowCustomization',
  ReminderToday = 'reminderTodayCustomization',
  Cancellation = 'cancellationCustomization',
  MeetingLinkChanged = 'meetingLinkChangedCustomization',
  DateTimeChanged = 'dateTimeChangedCustomization',
}

export const CustomEmailConfig = {
  [CustomEmail.Confirmation]: {
    key: 'EVENT_REGISTERED',
    subjectLine: `Confirmed for {{accountName}}'s {{postalName}} on {{eventDate}}`,
    body: `<p style="line-height: 175%">
  <strong><span style="font-size: 18px">You’re In!</span></strong>
  <img
    goomoji="1f389"
    data-goomoji="1f389"
    style="margin: 0 0.2ex; vertical-align: middle; max-height: 24px"
    alt="🎉"
    src="https://mail.google.com/mail/e/1f389"
    data-image-whitelisted=""
  />
</p>
<p>Thanks for confirming your registration.</p>
<h4 style="margin-top: 10px">Event Details</h4>
<blockquote
  style="border-left: 10px solid #ccc; margin: 0.5em 2px; padding: 0.5em 10px"
>
  <p style="margin: 4px"><strong>{{postalName}}</strong></p>
  <p style="margin: 4px">{{eventDate}} at {{eventTime}}</p>
  <p style="margin: 4px">
    Event Access Link: <a href="{{meetingLink}}">{{meetingLink}}</a>
  </p>
</blockquote>
<p>
  You can also access the event link in the attached calendar invite. We’ll send
  you reminders leading up to the event.
</p>`,
  },
  [CustomEmail.InviteExpiring]: {
    key: 'EVENT_INVITE_EXPIRING',
    subjectLine: null,
    body: null,
  },
  [CustomEmail.ReminderToday]: {
    key: 'EVENT_REMINDER_TODAY',
    subjectLine: `{{accountName}}'s {{postalName}} is today!`,
    body: `<p>Hi {{recipientFirstName}},</p>
<p>Today is the day! We'll see you at the {{postalName}} at {{eventTime}}</p>
<p>See you then!</p>
<h4 style="margin-top: 10px">Event Details</h4>
<blockquote
  style="border-left: 10px solid #ccc; margin: 0.5em 2px; padding: 0.5em 10px"
>
  <p style="margin: 4px"><strong>{{postalName}}</strong></p>
  <p style="margin: 4px">{{eventDate}} at {{eventTime}}</p>
  <p style="margin: 4px">
    Event Access Link: <a href="{{meetingLink}}">{{meetingLink}}</a>
  </p>
</blockquote>
<p>{{senderFirstName}} at {{accountName}}</p>`,
  },
  [CustomEmail.ReminderTomorrow]: {
    key: 'EVENT_REMINDER_TOMORROW',
    subjectLine: `{{accountName}}'s {{postalName}} is tomorrow`,
    body: `<p>Hi {{recipientFirstName}},</p>
<p>Looking forward to seeing you at the {{postalName}} tomorrow!</p>
<p>You can access tomorrow's experience through the link below.</p>
<h4 style="margin-top: 10px">Event Details</h4>
<blockquote
  style="border-left: 10px solid #ccc; margin: 0.5em 2px; padding: 0.5em 10px"
>
  <p style="margin: 4px"><strong>{{postalName}}</strong></p>
  <p style="margin: 4px">{{eventDate}} at {{eventTime}}</p>
  <p style="margin: 4px">
    Event Access Link: <a href="{{meetingLink}}">{{meetingLink}}</a>
  </p>
</blockquote>
<p>See you then!</p>
<p>{{senderFirstName}} at {{accountName}}</p>`,
  },
  [CustomEmail.Cancellation]: {
    key: 'EVENT_CANCELLED',
    subjectLine: `Cancelled - {{accountName}}'s {{postalName}} on {{eventDate}}`,
    body: `<p style="line-height: 175%">
  <strong><span style="font-size: 18px">Event Cancelled</span></strong>
</p>
<p>Hi {{recipientFirstName}},</p>
<p>
  We regret to inform you that the {{postalName}} that was scheduled to take
  place on {{eventDate}} at {{eventTime}} has been cancelled. We apologize for
  any inconvenience.
</p>
<p>Best Regards,</p>
<p>{{senderFirstName}} at {{accountName}}</p>`,
  },
  [CustomEmail.MeetingLinkChanged]: {
    key: 'EVENT_MEETING_LINK_CHANGED',
    subjectLine: `Meeting Link Changed - {{accountName}}'s {{postalName}} on {{eventDate}}`,
    body: `<p style="line-height: 175%">
  <strong
    ><span style="font-size: 18px">Event Meeting Link Changed</span></strong
  >!
</p>
<p>Hi {{recipientFirstName}},</p>
<p>Here is the new meeting <a href="{{meetingLink}}">link</a></p>
<p>Best Regards,</p>
<p>{{senderFirstName}} at {{accountName}}</p>`,
  },
  [CustomEmail.DateTimeChanged]: {
    key: 'EVENT_DATE_TIME_CHANGED',
    subjectLine: `Your Upcoming Event Has Changed`,
    body: `<p style="line-height: 175%">
  <strong><span style="font-size: 18px">Event Date/Time Changed!</span></strong>
</p>
<p>Hi {{recipientFirstName}},</p>
<p>
  Heads up! We had to make some changes to the date and time of your upcoming
  event. Please see the updated details below:
</p>
<h4 style="margin-top: 10px">Event Details</h4>
<blockquote
  style="border-left: 10px solid #ccc; margin: 0.5em 2px; padding: 0.5em 10px"
>
  <p style="margin: 4px"><strong>{{postalName}}</strong></p>
  <p style="margin: 4px">{{eventDate}} at {{eventTime}}</p>
  <p style="margin: 4px">
    Event Access Link: <a href="{{meetingLink}}">{{meetingLink}}</a>
  </p>
</blockquote>
<p>Best Regards,</p>
<p>{{senderFirstName}} at {{accountName}}</p>`,
  },
}

export const customEmailVariables = {
  accountName: 'The business name',
  accountLogo: 'The business logo',
  primaryColor: 'The branding primary color',
  secondaryColor: 'The branding secondary color',
  tertiaryColor: 'The branding tertiary color',
  postalName: 'The name of the Item or Event',
  eventDate: 'The date of the Event',
  eventTime: 'The time of the Event',
  meetingLink: 'The video link to the Event',
  recipientFirstName: 'The first name of the recipient',
  recipientLastName: 'The last name of the recipient',
  recipientFullName: 'The full name of the recipient',
  senderFirstName: 'The first name of the sender',
  senderLastName: 'The last name of the sender',
  senderFullName: 'The full name of the sender',
}
