import { Flex } from '@chakra-ui/react'
import { UiLoading, ZButton, ZLink, ZSidebar, ZSidebarBanner } from '@postal-io/postal-ui'
import { CenteredBox } from 'components/Common'
import { useProvider } from 'components/Integrations/useProvider'
import { SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { PageTitle } from 'hooks'
import type { PropsWithChildren } from 'react'
import React from 'react'
import { MdOutlineChevronLeft, MdOutlineDelete, MdOutlineSaveAlt } from 'react-icons/md'
import { Navigate, useParams } from 'react-router-dom'
import { IntegrationSyncLoader, IntegrationWorkato } from '../Integrations'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

export const IntegrationEntry = () => {
  const { systemName }: any = useParams()
  const { loading, provider } = useProvider(systemName)

  if (loading) return <UiLoading />

  if (provider?.integration) return <IntegrationSyncLoader provider={provider} />
  if (provider?.workato) return <IntegrationWorkato provider={provider} />

  return <Navigate to="/integrations" />
}

interface IntegrationLayoutProps {
  isDirty: boolean
  isLoading: boolean
  onBack: () => void
  onSave: () => void
  onReset: () => void
  onRemove: () => void
  title: string
  canDelete: boolean
}

export const IntegrationLayout: React.FC<PropsWithChildren<IntegrationLayoutProps>> = ({
  isDirty,
  onBack,
  onReset,
  onSave,
  isLoading,
  title,
  canDelete,
  onRemove,
  children,
}) => {
  return (
    <>
      {!isDirty ? (
        <SecondaryNavbar
          maxWidth="1400px"
          left={
            <Flex
              alignItems="center"
              h="52px"
              color="white"
            >
              <ZLink
                color="white"
                onClick={onBack}
                fontSize="14px"
                fontWeight="bold"
                display="flex"
                alignItems="center"
              >
                <MdOutlineChevronLeft size="28px" />
                Back to Integrations
              </ZLink>
            </Flex>
          }
        />
      ) : (
        <SecondaryNavbar
          maxWidth="1400px"
          left={
            <Flex
              alignItems="center"
              h="52px"
              color="white"
            >
              <ZButton
                variant="link"
                color="white"
                onClick={onReset}
                fontSize="14px"
                fontWeight="bold"
                isDisabled={isLoading}
              >
                Reset
              </ZButton>
            </Flex>
          }
          right={
            <Flex
              alignItems="center"
              h="52px"
              color="white"
            >
              <ZButton
                variant="link"
                color="white"
                onClick={onSave}
                display="flex"
                gap={2}
                alignItems="center"
                fontWeight="bold"
                isDisabled={isLoading}
                isLoading={isLoading}
              >
                <MdOutlineSaveAlt size="24px" />
                Save
              </ZButton>
            </Flex>
          }
        />
      )}
      <CenteredBox isLoaded>
        <ZSidebar
          sidebarBlocks={<ProfileSidebarBlocks />}
          m={0}
          p={0}
        >
          <ZSidebarBanner
            title={title}
            actions={
              canDelete && (
                <ZButton
                  variant="link"
                  color="atomicBlue.400"
                  leftIcon={<MdOutlineDelete size="24px" />}
                  size="sm"
                  height="21px"
                  onClick={onRemove}
                >
                  Remove Integration
                </ZButton>
              )
            }
          />
          <PageTitle
            title={title}
            section="Integrations"
          />
          {children}
        </ZSidebar>
      </CenteredBox>
    </>
  )
}
