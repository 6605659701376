import { FormControl } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { humanize, SelectTypeaheadStylesV2, useAlertError, ZFormLabel } from '@postal-io/postal-ui'
import { RequiredSelectTypeahead } from 'components/AutoComplete/RequiredSelectTypeahead'
import { getExternalProvider, recipeNameMap } from 'components/Integrations'
import React, { useMemo } from 'react'
import { GetRecipesDocument } from '../../api'

interface TriggerEditRecipeV2Props {
  state: Record<string, any>
  setState: (f: (draft: any) => any) => void
}

export const TriggerEditRecipe: React.FC<TriggerEditRecipeV2Props> = ({ state, setState }) => {
  const provider = getExternalProvider(state.systemName)

  // Get recipes if we have chosen a sequence
  const recipeQuery = useGraphqlQuery(GetRecipesDocument)
  useAlertError(recipeQuery.error)
  const recipes = useMemo(
    () =>
      recipeQuery?.data?.getRecipes?.filter((r) => r.name.startsWith(provider?.workato?.recipePrefix + 'trigger-')) ||
      [],
    [provider?.workato?.recipePrefix, recipeQuery?.data?.getRecipes]
  )

  const handleChange = (e: any) => {
    const { value } = e
    setState((draft) => {
      draft.config = { recipeName: value.name, recipeId: value.id }
    })
  }

  const recipeOptions =
    recipes
      ?.map((recipe) => ({
        label:
          recipeNameMap[recipe.name as keyof typeof recipeNameMap] ??
          humanize(recipe.name.replace(provider?.workato?.recipePrefix + 'trigger-', '')),
        value: recipe,
      }))
      .filter(Boolean) ?? []

  return (
    <>
      <FormControl>
        <ZFormLabel htmlFor="recipe">Event</ZFormLabel>
        <RequiredSelectTypeahead
          id="recipe"
          name="recipe"
          placeholder={
            recipeQuery.isLoading ? 'Loading...' : recipeOptions.length ? 'Select...' : 'No Events Available'
          }
          value={recipeOptions.find((option) => option.value.id === state.config?.recipeId)}
          onChange={handleChange}
          options={recipeOptions}
          isDisabled={!recipes.length}
          {...SelectTypeaheadStylesV2}
        />
      </FormControl>
    </>
  )
}
