import { Box, useDisclosure } from '@chakra-ui/react'
import { UiCollapse, ZLink } from '@postal-io/postal-ui'
import { useMemo } from 'react'
import { MdArrowDropDown } from 'react-icons/md'
import useMeasure from 'react-use-measure'

const DEFAULT_CLAMP_HEIGHT = 70
const DEFAULT_FADE_HEIGHT = 20

/**
 * This component will height-clamp its child content if it's too tall (height > clampHeight),
 * fade the bottom out and allow the user to expand it. If it's not too tall, it will simply wrap
 * the content in a Box element and display it normally.
 */
interface ClampAndFadeProps {
  clampHeight?: number
  fadeHeight?: number
}
export const ClampAndFade: React.FC<React.PropsWithChildren<ClampAndFadeProps>> = ({
  clampHeight = DEFAULT_CLAMP_HEIGHT,
  fadeHeight = DEFAULT_FADE_HEIGHT,
  children,
}) => {
  const disclosure = useDisclosure()
  const [measureRef, measure] = useMeasure()

  const collapsingNecessary = useMemo(() => (measure.height ?? 0) > clampHeight, [measure.height, clampHeight])

  return collapsingNecessary ? (
    <>
      <UiCollapse
        startingHeight={`${clampHeight}px`}
        in={disclosure.isOpen}
        style={{ position: 'relative' }}
      >
        {!disclosure.isOpen && (
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            boxShadow={`0px ${-2 * fadeHeight}px ${fadeHeight}px ${-fadeHeight}px inset white`}
          />
        )}
        <Box ref={measureRef}>{children}</Box>
      </UiCollapse>
      <Box
        position="relative"
        mt={disclosure.isOpen ? 0 : -5}
        zIndex={2}
        textAlign="center"
        transition="0.2s margin-top"
      >
        <ZLink
          onClick={disclosure.onToggle}
          bg="white"
          borderRadius={5}
          p={1}
          whiteSpace="nowrap"
          display="inline-flex"
          justifyContent="center"
          alignItems="center"
        >
          {disclosure.isOpen ? (
            <>
              See Less <MdArrowDropDown style={{ fontSize: '18px', transform: 'rotate(180deg)' }} />
            </>
          ) : (
            <>
              See More <MdArrowDropDown style={{ fontSize: '18px' }} />
            </>
          )}
        </ZLink>
      </Box>
    </>
  ) : (
    <Box ref={measureRef}>{children}</Box>
  )
}
