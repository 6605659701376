import { useGraphqlFetch, useGraphqlMutation } from '@postal-io/postal-graphql'
import { downloadLink, useAlerts } from '@postal-io/postal-ui'
import type { BackgroundTask } from 'api'
import {
  DownloadReportDocument,
  GenerateContactUnsubscribeReportDocument,
  GenerateLedgerReportDocument,
  GenerateOrderReportDocument,
} from 'api'
import { format } from 'date-fns'
import { useBackgroundQueue } from 'hooks'
import { useState } from 'react'

export enum ReportType {
  Order = 'Order',
  Unsubscribe = 'Unsubscribe',
  Ledger = 'Ledger',
}

const documentMap: { [key: string]: any } = {
  [ReportType.Order]: GenerateOrderReportDocument,
  [ReportType.Unsubscribe]: GenerateContactUnsubscribeReportDocument,
  [ReportType.Ledger]: GenerateLedgerReportDocument,
}

type ReportKey = 'generateOrderReport' | 'reportOuputInstanceId' | 'generateLedgerReport'

export const useReport = (type: ReportType) => {
  const Alert = useAlerts()
  const { queue, invalidate } = useBackgroundQueue()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const generateReport = useGraphqlMutation(documentMap[type], {
    onSuccess: () => invalidate(['searchReportOutputInstances'], 2500),
  })
  const downloadReport = useGraphqlFetch(DownloadReportDocument)

  const createReport = async (filter: any) => {
    setIsLoading(true)
    const date = format(new Date(), 'MM-dd-yyyy hh-mm aaa')
    const name = `Postal ${type} Report ${date}.xlsx`

    // callback to fire when the report task is complete
    const handleComplete = async (task: BackgroundTask) => {
      const reportOutputInstanceId = task.outputs?.reportOutputInstanceId
      if (!reportOutputInstanceId) return
      try {
        const res = await downloadReport({ reportOutputInstanceId })
        if (res.downloadReport) {
          downloadLink(res.downloadReport, name)
        }
      } catch (err) {
        Alert.error(err)
      }
    }

    // generate the report and pass the download callback to it
    try {
      const res = await generateReport.mutateAsync({ filter: filter || {}, name })
      const reportKey = Object.keys(res)?.[0] as ReportKey
      queue(res?.[reportKey], handleComplete)
      Alert.success('Your report is being generated and will download shortly.')
    } catch (err) {
      Alert.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  return { createReport, isLoading }
}
