import { sessionStorage, UiLoading, useAlerts } from '@postal-io/postal-ui'
import { StorageKeys } from 'lib'
import React, { useEffect, useRef } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AnalyticsEventV2, getAnalyticsSessionInfo, sendAnalyticsEvent, useSession } from '../../hooks'

export const Sso: React.FC = () => {
  const params = useParams()
  const token = params.token as string
  const navigate = useNavigate()
  const Alert = useAlerts()
  const [search] = useSearchParams()
  const returnTo = search.get('returnTo') ?? sessionStorage.getItem(StorageKeys.AppRedirectPath)
  const { loginSso, removeSession } = useSession()
  const ref = useRef(false)

  useEffect(() => {
    if (ref.current) return
    ref.current = true
    loginSso(token)
      .then((session: any) => {
        sendAnalyticsEvent(AnalyticsEventV2.Engage_Login, { ...getAnalyticsSessionInfo(session), type: 'SSO' })
        sessionStorage.removeItem(StorageKeys.AppRedirectPath)
        navigate(returnTo || '/')
      })
      .catch((err: Error) => {
        if (err.message.includes('has no roles')) {
          navigate('/error/no_roles')
        } else {
          Alert.error(err)
          removeSession()
          navigate('/sign-in')
        }
      })
  }, [Alert, loginSso, navigate, removeSession, returnTo, token])

  return <UiLoading />
}
