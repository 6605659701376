import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Grid, theme } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiFormControl,
  useAlerts,
  ZCard,
  ZFormLabel,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZText,
} from '@postal-io/postal-ui'
import { CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import type { PaymentMethod, StripeCardNumberElement } from '@stripe/stripe-js'
import type { BillingAccount } from 'api'

import {
  CreateSetupIntentDocument,
  PaymentPartnerType,
  SetDefaultPaymentMethodDocument,
  SyncBillingAccountPaymentPartnerDocument,
} from 'api'
import { AnalyticsEventV2, useAnalyticsSend, useRecaptcha } from 'hooks'
import { getStripe } from 'lib/billing'
import React, { useEffect, useState } from 'react'

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY!

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      'color': theme.colors.gray['700'],
      'textTransform': 'capitalize',
      'fontSize': theme.fontSizes.md,
      'fontFamily': theme.fonts.body,
      'height': 'unset',
      '::placeholder': {
        color: theme.colors.gray['400'],
      },
    },
  },
}

interface EditCreditCardStripeProps {
  billingAccount?: BillingAccount
  onComplete: () => void
  onClose: () => void
}

export const EditCreditCardStripe: React.FC<EditCreditCardStripeProps> = (props) => {
  const stripePromise = getStripe(STRIPE_KEY)

  return (
    <Elements stripe={stripePromise}>
      <EditCreditCardStripeContent {...props} />
    </Elements>
  )
}

export const EditCreditCardStripeContent: React.FC<EditCreditCardStripeProps> = ({
  billingAccount,
  onComplete,
  onClose,
}) => {
  const { executeRecaptcha } = useRecaptcha()

  const stripe = useStripe()
  const elements = useElements()

  const [isLoading, setIsLoading] = useState(false)

  const Alert = useAlerts()
  const sendAnalytics = useAnalyticsSend()

  const syncBillingAccountPaymentPartner = useGraphqlMutation(SyncBillingAccountPaymentPartnerDocument)
  const setDefaultPaymentMetbod = useGraphqlMutation(SetDefaultPaymentMethodDocument)
  const createSetupIntent = useGraphqlMutation(CreateSetupIntentDocument)

  const onSubmit = async () => {
    try {
      if (!billingAccount?.id) throw new Error('Billing Account was not provided')

      // we need a recaptcha here
      const siteVerify = (await executeRecaptcha?.('stripeSetupIntent')) || ''
      if (!siteVerify) throw new Error('Recaptcha failed')

      setIsLoading(true)

      // stripe SDK things
      const cardElement = elements?.getElement(CardNumberElement) as StripeCardNumberElement

      if (!stripe || !cardElement) {
        throw new Error('Something went wrong. Please refresh the page and try again.')
      }

      const newSetupIntent = await createSetupIntent.mutateAsync({ billingAccountId: billingAccount.id })
      const clientSecret = newSetupIntent.createSetupIntent

      const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: { card: cardElement },
      })
      if (error) throw new Error(error.message ?? 'An error has occured while processing this payment method')

      const { payment_method } = setupIntent
      // for some reason payment_method can either be an object (PaymentMethod) or just the id (string)
      const paymentMethodId = (payment_method as PaymentMethod)?.id ?? payment_method

      // sync billing accounts
      await syncBillingAccountPaymentPartner.mutateAsync({ id: billingAccount.id })

      await setDefaultPaymentMetbod.mutateAsync({
        id: billingAccount.id,
        input: { partnerPaymentMethodId: paymentMethodId as string, paymentPartnerType: PaymentPartnerType.Stripe },
      })
      sendAnalytics({ event: AnalyticsEventV2.Billing_AddCard })
      Alert.success('Payment Method Updated.')
      onComplete()
    } catch (err) {
      Alert.error(err)
    }
    setIsLoading(false)
  }

  const [cardError, setCardError] = useState('')
  const [expiryError, setExpiryError] = useState('')
  const [cvcError, setCvcError] = useState('')

  // create & mount stripe elements & error listeners
  const [stripeMounted, setStripeMounted] = useState(false)
  useEffect(() => {
    if (stripeMounted || !elements) return

    const cardNumber = elements.create('cardNumber', CARD_ELEMENT_OPTIONS)
    const cardExpiry = elements.create('cardExpiry', CARD_ELEMENT_OPTIONS)
    const cardCvc = elements.create('cardCvc', CARD_ELEMENT_OPTIONS)

    cardNumber.on('change', (e) => setCardError(e.error?.message ?? ''))
    cardExpiry.on('change', (e) => setExpiryError(e.error?.message ?? ''))
    cardCvc.on('change', (e) => setCvcError(e.error?.message ?? ''))

    cardNumber.mount('#stripe-card-number')
    cardExpiry.mount('#stripe-card-expiry')
    cardCvc.mount('#stripe-card-cvc')

    setStripeMounted(true)

    setTimeout(() => cardNumber?.focus(), 500)
  }, [elements, stripeMounted])

  return (
    <ZModalContent>
      <ZModalHeader>Change Payment Method</ZModalHeader>
      <ZModalCloseButton />
      <ZModalBody>
        <ZCard variant="form">
          <Flex
            direction="column"
            justifyContent="center"
            p={8}
          >
            <UiFormControl
              mb={8}
              id="number"
              isRequired
            >
              <ZFormLabel>Card Number</ZFormLabel>
              <StripeInputWrapper
                invalid={!!cardError}
                w="100%"
              >
                <Box id="stripe-card-number" />
              </StripeInputWrapper>
              {!!cardError && (
                <ZText
                  fontSize="sm"
                  color="atomicRed.500"
                >
                  {cardError}
                </ZText>
              )}
            </UiFormControl>
            <Grid
              templateColumns={{ base: '1fr', sm: '1fr 1fr' }}
              gridGap={2}
            >
              <UiFormControl
                id="expiration"
                minWidth="115px"
                isRequired
              >
                <ZFormLabel>Expiration Date</ZFormLabel>
                <StripeInputWrapper invalid={!!expiryError}>
                  <Box id="stripe-card-expiry" />
                </StripeInputWrapper>
                {!!expiryError && (
                  <ZText
                    fontSize="sm"
                    color="atomicRed.500"
                  >
                    {expiryError}
                  </ZText>
                )}
              </UiFormControl>
              <UiFormControl
                minWidth="115px"
                id="code"
                isRequired
              >
                <ZFormLabel>Security Code</ZFormLabel>
                <StripeInputWrapper invalid={!!cvcError}>
                  <Box id="stripe-card-cvc" />
                </StripeInputWrapper>
                {!!cvcError && (
                  <ZText
                    fontSize="sm"
                    color="atomicRed.500"
                  >
                    {cvcError}
                  </ZText>
                )}
              </UiFormControl>
            </Grid>
          </Flex>
        </ZCard>
      </ZModalBody>
      <ZModalButtons
        isConfirmDisabled={isLoading || !!cardError || !!expiryError || !!cvcError}
        isConfirmLoading={isLoading}
        onConfirm={() => onSubmit()}
        confirmText="Add Card"
        onClose={onClose}
      />
    </ZModalContent>
  )
}

const StripeInputWrapper: React.FC<BoxProps & { invalid: boolean }> = (props) => (
  <Box
    px={4}
    py={2.5}
    h="40px"
    border="1px solid"
    borderColor={props.invalid ? 'atomicRed.400' : 'atomicGray.200'}
    _hover={{ borderColor: props.invalid ? undefined : 'atomicGray.400' }}
    transition="0.2s border-color"
    borderRadius={3}
    {...props}
  />
)
