interface GetSsoEndpointParams {
  endpoint: string
  params?: Record<string, string>
  mode?: string
}
export function getSsoEndpoint({ endpoint, mode, params }: GetSsoEndpointParams) {
  const path = `/api/auth/provider/${endpoint}`
  const url = new URL(path, process.env.REACT_APP_SSO_URL ?? window.location.origin)
  const combinedMode = [mode, process.env.REACT_APP_SSO_MODE].filter(Boolean).join('-')
  url.searchParams.set('product', process.env.REACT_APP_PRODUCT_ID ?? '')
  url.searchParams.set('mode', combinedMode)
  Object.entries(params ?? {}).forEach(([key, val]) => {
    url.searchParams.set(key, val)
  })
  return url.toString()
}
