import img_01 from './1.jpeg'
import img_02 from './2.jpeg'
import img_03 from './3.jpeg'
import img_04 from './4.jpeg'
import img_05 from './5.jpeg'
import img_06 from './6.jpeg'
import img_07 from './7.jpeg'
import img_08 from './8.jpeg'
import img_09 from './9.jpeg'
import postalBasics from './basics.png'
import conciergeForm from './concierge-form.png'
import conciergeHero from './concierge-hero.png'
import postalConcierge from './concierge.png'
import customCocktail from './custom.png'
import postalPremium from './premium.png'
import startNewProject from './start-new-project.jpg'
import warehouseForm from './warehouse-form.png'
import warehousing from './warehousing.jpg'

const CONCIERGE_IMGS = [
  img_01,
  img_02,
  img_03,
  img_04,
  img_05,
  img_06,
  img_07,
  img_08,
  img_09,
  // img_10
]

export {
  customCocktail,
  CONCIERGE_IMGS,
  postalBasics,
  postalPremium,
  postalConcierge,
  conciergeForm,
  warehouseForm,
  conciergeHero,
  startNewProject,
  warehousing,
}
