// MODIFIED FROM: https://github.com/konvajs/use-image/blob/master/index.js
import { printElementSrc } from 'api/rest'
import { reloadSession } from 'hooks'
import { useEffect, useState } from 'react'

const defaultState = { image: undefined, status: 'loading' }
const MAX_RETRIES = 2

export const useImage = (element: any) => {
  const [retries, setRetries] = useState(0)
  const [{ image, status }, setState] = useState<{
    image: HTMLImageElement | undefined
    status: string
  }>(defaultState)

  useEffect(() => {
    if (!element) return

    const url = printElementSrc(element)
    const img = document.createElement('img')

    const onLoad = () => {
      setState({ image: img, status: 'loaded' })
    }

    const onError = async () => {
      setState({ image: undefined, status: 'failed' })
      if (retries > MAX_RETRIES - 1) return
      try {
        await reloadSession()
        const newSrc = printElementSrc(element)
        if (newSrc !== img.src) {
          img.src = newSrc
        }
      } catch (err) {
        // nothing
      } finally {
        setRetries(retries + 1)
      }
    }

    img.addEventListener('load', onLoad)
    img.addEventListener('error', onError)

    img.src = url

    return function cleanup() {
      img.removeEventListener('load', onLoad)
      img.removeEventListener('error', onError)
      setState(defaultState)
    }
  }, [element, retries])

  return [image, status]
}
