import { Grid } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { ZSidebar, ZSidebarBanner } from '@postal-io/postal-ui'
import { GetAccountDocument } from 'api'
import { CenteredBox } from 'components/Common'
import { SecurityOkta } from 'components/Security/SecurityOkta'
import { SecuritySettings } from 'components/Security/SecuritySettings'
import { PageTitle, useAcl } from 'hooks'
import React, { useMemo } from 'react'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

export const Security: React.FC = () => {
  const getAccount = useGraphqlQuery(GetAccountDocument)
  const account = useMemo(() => getAccount?.data?.getAccount, [getAccount?.data?.getAccount])
  const { hasFeature, hasPermission } = useAcl()
  const canRead = hasPermission('security.read')
  const canOkta = hasFeature('okta')

  if (!canRead) return null
  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner title="Security" />
        <PageTitle title="Security" />
        <Grid
          gap={8}
          templateColumns={{ base: '1fr', lg: '2fr 3fr' }}
        >
          <SecuritySettings account={account} />
          {canOkta && <SecurityOkta account={account} />}
        </Grid>
      </ZSidebar>
    </CenteredBox>
  )
}
