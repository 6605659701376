import { CheckboxGroup, Flex, Grid, Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiDate,
  UiFormControl,
  useAlertError,
  useAlerts,
  ZButton,
  ZCard,
  ZCheckbox,
  ZFormLabel,
  ZInput,
  ZInputDate,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
  ZModalOverlay,
  ZSelect,
  ZSidebar,
  ZSidebarBanner,
  ZText,
  ZTextarea,
} from '@postal-io/postal-ui'
import { FormType, GetAccountDocument, SubmitFormDocument } from 'api'
import { CenteredBox } from 'components/Common'
import { ZAlert } from 'components/Common/ZComponents'
import { parseISO } from 'date-fns'
import { PageTitle, useAcl, useCompletedActions } from 'hooks'
import { CompletedAction } from 'hooks/useCompletedActions'
import type { ReactText } from 'react'
import React, { useMemo } from 'react'
import { useImmer } from 'use-immer'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

const SERVICES = ['Picking and packing', 'Kitting', 'Packaging', 'Other (notate in box below)']

export const Warehousing: React.FC = () => {
  const conciergeForm = useGraphqlMutation(SubmitFormDocument)
  const showSuccess = useDisclosure()
  const [form, setForm] = useImmer<Record<string, any>>({
    frequency: 'one-time send',
    totalSends: 1,
    services: [],
  })
  const { hasPermission, hasFeature } = useAcl()
  const hasWarehouse = hasFeature('warehouse') && hasPermission('warehouse.create')

  const getAccountQuery = useGraphqlQuery(GetAccountDocument)
  const isTrial = getAccountQuery.data?.getAccount?.trialSubscription

  const { completedActions, markComplete } = useCompletedActions()

  const formSubmittedDate = useMemo(() => {
    const date = completedActions[CompletedAction.WarehousingForm]?.['$date']
    return date ? parseISO(date) : undefined
  }, [completedActions])

  const Alert = useAlerts()
  useAlertError(conciergeForm.error)

  const handleFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setForm((draft: Record<string, any>) => {
      draft[name] = value
    })
  }

  const handleSends = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((draft: Record<string, any>) => {
      draft['totalSends'] = Number(e.target.value)
    })
  }

  const handleServices = (value: ReactText[]) => {
    setForm((draft: Record<string, any>) => {
      draft['services'] = value
    })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!form.totalSends || !form.userMessage || !form.frequency || !form.warehouseDate || form.services.length === 0)
      return Alert.error('Please complete all form fields')

    try {
      await conciergeForm.mutateAsync({
        type: FormType.Warehouse,
        variables: { ...form, services: form.services.join(', ') },
      })
      markComplete(CompletedAction.WarehousingForm)
      showSuccess.onOpen()
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <>
      <CenteredBox isLoaded>
        <ZSidebar
          sidebarBlocks={<ProfileSidebarBlocks />}
          m={0}
          p={0}
        >
          <ZSidebarBanner title="Warehousing" />
          <PageTitle title="Warehousing" />

          <Stack spacing={8}>
            {!!formSubmittedDate && (
              <ZAlert
                status="info"
                hideClose
              >
                Your last request was sent on <UiDate date={formSubmittedDate} />. Please wait two business days before
                re-submitting.
              </ZAlert>
            )}
            {isTrial ? (
              <ZAlert
                status="info"
                hideClose
                fontSize="lg"
              >
                <ZText
                  fontSize="md"
                  color="inherit"
                >
                  Warehousing is an add-on feature, not available during the trial. Please contact your Account
                  Executive for details and to learn more.
                </ZText>
              </ZAlert>
            ) : !hasWarehouse ? (
              <ZAlert
                status="info"
                hideClose
                fontSize="lg"
              >
                <ZText
                  fontSize="md"
                  color="inherit"
                >
                  Your organization does not currently have access to Postal Warehousing. To request more information
                  and pricing for Postal Warehousing, please fill out this{' '}
                  <ZLink
                    fontSize="inherit"
                    href="https://forms.gle/7xdyt1fawyGP6kTb7"
                    target="_blank"
                  >
                    form
                  </ZLink>
                  .
                </ZText>
              </ZAlert>
            ) : (
              <ZCard
                variant="form"
                isLoading={getAccountQuery.isLoading}
              >
                <form
                  onSubmit={handleSubmit}
                  style={{ width: '100%' }}
                >
                  <Grid
                    gap={8}
                    p={8}
                    gridTemplateColumns="1fr 1fr"
                    width="100%"
                  >
                    <UiFormControl
                      maxWidth="100%"
                      id="frequency"
                      isRequired
                      gridColumn="span 2"
                    >
                      <ZFormLabel fontSize="md">
                        Is this a one time send or would you like to send these items over time?
                      </ZFormLabel>
                      <ZSelect
                        name="frequency"
                        value={form.frequency}
                        onChange={handleFormInput}
                      >
                        <option value="one-time send">One-time send</option>
                        <option value="send over time">Send over time</option>
                      </ZSelect>
                    </UiFormControl>
                    <UiFormControl
                      maxWidth="100%"
                      id="warehouseDate"
                      isRequired
                    >
                      <ZFormLabel
                        fontSize="md"
                        htmlFor="time"
                      >
                        When would you like to warehouse these items?
                      </ZFormLabel>
                      <ZInputDate
                        value={form.warehouseDate}
                        height="40px"
                        onChange={handleFormInput}
                        name="warehouseDate"
                        isRequired
                      />
                    </UiFormControl>
                    <UiFormControl
                      maxWidth="100%"
                      id="totalSends"
                      isRequired
                    >
                      <ZFormLabel fontSize="md">How many items are you planning to warehouse?</ZFormLabel>
                      <ZInput
                        min={1}
                        defaultValue={1}
                        type="number"
                        value={form.totalSends}
                        onChange={handleSends}
                        name="totalSends"
                        isRequired
                      />
                    </UiFormControl>
                    <UiFormControl
                      maxWidth="100%"
                      id="services"
                      gridColumn="span 2"
                    >
                      <ZFormLabel fontSize="md">Please select which services you will need for your items:</ZFormLabel>
                      <CheckboxGroup
                        value={form.services}
                        onChange={handleServices}
                      >
                        <Stack
                          spacing={2}
                          direction="column"
                        >
                          {SERVICES.map((service) => (
                            <ZCheckbox
                              key={`service-dropdown-${service}`}
                              colorScheme="atomicBlue"
                              value={service}
                            >
                              <ZText color="atomicGray.600">{service}</ZText>
                            </ZCheckbox>
                          ))}
                        </Stack>
                      </CheckboxGroup>
                    </UiFormControl>

                    <ZAlert
                      hideClose
                      w="full"
                      gridColumn="span 2"
                      status="info"
                    >
                      Please make a copy of this{' '}
                      <ZLink
                        href="https://docs.google.com/spreadsheets/d/1NyLaEknXcjN0tKZWzJiGqD8G0uKO6SgEfT5rXHaNnMQ/edit#gid=1972111912"
                        target="_blank"
                      >
                        spreadsheet
                      </ZLink>{' '}
                      and paste a link to your completed version in the box below. Watch{' '}
                      <ZLink
                        href="https://www.loom.com/share/873b14b277274f3bb102a413afa7ac1a"
                        target="_blank"
                      >
                        this video
                      </ZLink>{' '}
                      to learn more about the Postal Warehouse process.
                    </ZAlert>

                    <UiFormControl
                      maxWidth="100%"
                      id="userMessage"
                      gridColumn="span 2"
                      isRequired
                    >
                      <ZFormLabel fontSize="md">
                        Tell us more about the details of your project and what you’re trying to achieve. Please provide
                        any examples or links.
                      </ZFormLabel>
                      <ZTextarea
                        width="100%"
                        value={form.userMessage}
                        name="userMessage"
                        onChange={handleFormInput}
                        isRequired
                      />
                    </UiFormControl>

                    <Flex
                      justifyContent="flex-start"
                      width="full"
                    >
                      <ZButton
                        type="submit"
                        minW="250px"
                        size="md"
                        colorScheme="atomicBlue"
                        isDisabled={conciergeForm.isLoading}
                        isLoading={conciergeForm.isLoading}
                      >
                        Send Request
                      </ZButton>
                    </Flex>
                  </Grid>
                </form>
              </ZCard>
            )}
          </Stack>
        </ZSidebar>
      </CenteredBox>
      {showSuccess.isOpen && (
        <ZModal
          onClose={showSuccess.onClose}
          isOpen={showSuccess.isOpen}
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Request Sent</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <ZText fontSize="md">
                Your information was sent to our concierge. We will reach out to you within 48 hours.
              </ZText>
            </ZModalBody>
            <ZModalFooter justifyContent="flex-end">
              <ZButton
                variant="ghost"
                colorScheme="atomicGray"
                minW="140px"
                onClick={showSuccess.onClose}
              >
                Close
              </ZButton>
            </ZModalFooter>
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}
