import type { BoxProps } from '@chakra-ui/react'
import { AspectRatio, Box, Flex, Grid, Icon, useDisclosure } from '@chakra-ui/react'
import {
  UiEnhancedTooltip,
  UiMenu,
  UiMenuButton,
  UiMenuList,
  UiScaleEffect,
  UiSlideImage,
  UiTooltip,
  UiTruncate,
  ZButton,
  ZHeading,
  ZText,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, ApprovedProductVariant } from 'api'
import { ZLink } from 'components/Common/ZComponents'
import { ApprovedVariantSelectDropdown } from 'components/Postals/ApprovedVariantSelect'
import type { PostalSendContext } from 'components/PostalSend/usePostalSend'
import { hasOrderFlexibility } from 'components/PostalSend/usePostalSend'
import { useAssets, usePostalImages } from 'hooks'
import React from 'react'
import { MdOutlineArrowDropDown, MdOutlineEdit } from 'react-icons/md'
import { PostalSelectItem } from './PostalSelectItem'
import { usePostalSendFieldErrors } from './usePostalSendFieldErrors'

interface PostalSendSidebarItemInfoProps {
  context?: PostalSendContext
  postal?: ApprovedPostal
  selectedVariant?: ApprovedProductVariant
  setPostal?: (postal: ApprovedPostal) => void
  setVariant?: (variant: ApprovedProductVariant) => void
}
export const PostalSendSidebarItemInfo: React.FC<PostalSendSidebarItemInfoProps> = ({
  context,
  postal,
  selectedVariant,
  setPostal,
  setVariant,
}) => {
  return (
    <Grid
      templateColumns="1fr 4fr"
      gridGap="10px"
      pb={5}
    >
      <PostalSendSidebarProductSelector
        context={context}
        postal={postal}
        setPostal={setPostal}
        selectedVariant={selectedVariant}
      />
      <Flex
        direction="column"
        justifyContent="center"
      >
        <ZHeading
          as="h4"
          size="h6"
        >
          <UiTruncate
            length={60}
            text={postal?.displayName ?? postal?.name ?? ''}
          />
        </ZHeading>
        {context && (
          <PostalSendSidebarVariantSelect
            context={context}
            selectedVariant={selectedVariant}
            postal={postal}
            setVariant={setVariant}
          />
        )}
      </Flex>
    </Grid>
  )
}

interface PostalSendSidebarProductSelectorProps
  extends Pick<PostalSendSidebarItemInfoProps, 'context' | 'postal' | 'selectedVariant' | 'setPostal'> {}
const PostalSendSidebarProductSelector: React.FC<PostalSendSidebarProductSelectorProps> = ({
  context,
  postal,
  selectedVariant,
  setPostal,
}) => {
  const selectPostal = useDisclosure()

  return (
    <>
      <Box position="relative">
        <PostalSendSidebarImageDisplay
          postal={postal}
          selectedVariant={selectedVariant}
        />
        {context && (
          <Flex
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            transition="0.2s"
            opacity={0}
            bg="rgba(100,100,100,.5)"
            borderRadius={3}
            _hover={{ opacity: 1, backdropFilter: 'blur(5px)' }}
            p={2}
            flexDir="column"
            justifyContent="center"
            alignItems="center"
          >
            <UiScaleEffect>
              <UiEnhancedTooltip
                label="Change Item"
                isDisabled={selectPostal.isOpen}
              >
                <ZButton
                  variant="outline"
                  borderRadius="50%"
                  minW="0"
                  w={10}
                  h={10}
                  color="whiteAlpha.900"
                  borderColor="whiteAlpha.900"
                  borderWidth="2px"
                  _hover={{ bg: 'blackAlpha.100' }}
                  _active={{ bg: 'blackAlpha.200' }}
                  fontSize="body-lg"
                  onClick={selectPostal.onOpen}
                >
                  <Icon as={MdOutlineEdit} />
                </ZButton>
              </UiEnhancedTooltip>
            </UiScaleEffect>
          </Flex>
        )}
      </Box>
      {selectPostal.isOpen && (
        <PostalSelectItem
          isOpen={selectPostal.isOpen}
          onClose={selectPostal.onClose}
          onSelect={setPostal}
        />
      )}
    </>
  )
}

interface PostalSendSidebarImageDisplayProps extends BoxProps {
  postal?: ApprovedPostal
  selectedVariant?: ApprovedProductVariant
}
export const PostalSendSidebarImageDisplay: React.FC<PostalSendSidebarImageDisplayProps> = ({
  postal,
  selectedVariant,
  ...rest
}) => {
  const { assetUrlSrc } = useAssets()
  const postalImages = usePostalImages(postal)
  const [imageSrc] = selectedVariant?.imageUrls?.length ? selectedVariant.imageUrls : postalImages
  const imageProps = assetUrlSrc(imageSrc?.url)

  return (
    <AspectRatio
      ratio={1}
      w="100px"
      {...rest}
    >
      <UiSlideImage
        objectFit="contain"
        decoding="async"
        border="1px solid"
        borderColor="atomicGray.200"
        borderRadius={5}
        {...imageProps}
      />
    </AspectRatio>
  )
}

interface PostalSendSidebarVariantSelectProps
  extends Pick<PostalSendSidebarItemInfoProps, 'context' | 'postal' | 'selectedVariant' | 'setVariant'> {}
const PostalSendSidebarVariantSelect: React.FC<PostalSendSidebarVariantSelectProps> = ({
  context,
  postal,
  selectedVariant,
  setVariant,
}) => {
  const disclosure = useDisclosure()
  const variantSelectErrorProps = usePostalSendFieldErrors({ context, field: 'variant' })
  const orderFlexibility = hasOrderFlexibility(context)

  return (
    <UiMenu {...disclosure}>
      <UiTooltip
        label="Select different option"
        isDisabled={disclosure.isOpen || orderFlexibility}
        hasArrow
        shouldWrapChildren
      >
        <UiMenuButton
          {...variantSelectErrorProps}
          as={ZLink}
          minHeight="unset"
          p={0}
          border="none"
          cursor="pointer"
          fontWeight="normal"
          color="atomicGray.500"
          _hover={{ color: 'atomicGray.600' }}
          display="inline-block"
          flexGrow={0}
          fontSize="sm"
          whiteSpace="nowrap"
          position="relative"
          userSelect="none"
        >
          {orderFlexibility ? (
            'Show Available Options'
          ) : (
            <UiTruncate
              length={35}
              text={context?.variant?.variantName}
            />
          )}
          <ZText
            as="span"
            position="relative"
            top={0.5}
            display="inline-block"
            ml={1}
            color="inherit"
            fontWeight={700}
            onClick={disclosure.onToggle}
          >
            <MdOutlineArrowDropDown style={{ display: 'inline-block' }} />
          </ZText>
        </UiMenuButton>
      </UiTooltip>
      <UiMenuList
        boxShadow="0 0 10px rgba(0,0,0,.2)"
        maxH="320px"
        overflowY="scroll"
        zIndex={9}
      >
        <ApprovedVariantSelectDropdown
          mt={0}
          context={context}
          postal={postal}
          selectedVariant={selectedVariant}
          onVariantSelect={(variant: ApprovedProductVariant) => {
            setVariant?.(variant)
            setTimeout(disclosure.onClose, 300)
          }}
          orderFlexibility={orderFlexibility}
        />
      </UiMenuList>
    </UiMenu>
  )
}
