import { Box, Portal } from '@chakra-ui/react'
import { useAcl } from 'hooks'
import { isLocalOrTestEnvironment } from 'lib'
import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const SfdcChat = () => {
  const { hasFeature } = useAcl()
  const location = useLocation()
  if (
    isLocalOrTestEnvironment ||
    location.pathname.startsWith('/swag-') ||
    location.pathname.startsWith('/extension') ||
    !hasFeature('sfdcChat')
  ) {
    return null
  }

  return <SfdcLoader />
}

export const SfdcLoader = () => {
  const handleMessage = useCallback((e: any) => {
    const origin = window.location.origin
    if (e.origin !== origin) return

    const hasFrameHeight = e.data.hasOwnProperty('frameHeight')
    const hasFrameWidth = e.data.hasOwnProperty('frameWidth')

    if (hasFrameHeight || hasFrameWidth) {
      const chatFrame = document.getElementById('sfdc-chat')
      if (chatFrame) {
        if (hasFrameHeight) chatFrame.style.height = e.data.frameHeight + 20 + 'px'
        if (hasFrameWidth) chatFrame.style.width = e.data.frameWidth + 20 + 'px'
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [handleMessage])

  return (
    <Portal>
      <Box
        position="fixed"
        bottom={0}
        left={0}
        zIndex={20000}
      >
        <Box
          as="iframe"
          id="sfdc-chat"
          src="/sfdc.html"
          w="100%"
          h="100%"
          border="none"
        />
      </Box>
    </Portal>
  )
}
