import { Box, Flex, Icon } from '@chakra-ui/react'
import { ZHeading, ZLink, ZText } from '@postal-io/postal-ui'
import brandHeroImage from 'assets/images/ABM_Nobubbles.png'
import type { PropsWithChildren } from 'react'
import React, { useMemo } from 'react'
import { MdArrowForward } from 'react-icons/md'
import { useWindowSize } from 'react-use'

export const MainBasic: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      flexDir="column"
      minH="100vh"
      w="100%"
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        w="100%"
      >
        {children}
      </Flex>
    </Flex>
  )
}

const ULTRAWIDE_BREAKPOINT = 2200

export const MainWithMarketingPane: React.FC<PropsWithChildren> = ({ children }) => {
  const { width } = useWindowSize()
  // We want an extra large breakpoint not incorporated into chakra yet
  const isUltrawideScreen = useMemo(() => width > ULTRAWIDE_BREAKPOINT, [width])

  return (
    <Flex
      flexDir="column"
      h="100vh"
      w="100%"
    >
      <Flex direction="row">
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          w="100%"
          flexShrink={0}
        >
          <Box
            h="100vh"
            w={isUltrawideScreen ? '50%' : { base: '0', lg: '60%', xl: '65%' }}
            display="flex"
            justifyContent="flex-end"
          >
            <Box maxW={`${ULTRAWIDE_BREAKPOINT * 0.65}px`}>
              <MarketingPane />
            </Box>
          </Box>
          <Box
            h="100vh"
            w={isUltrawideScreen ? '50%' : { base: '100%', lg: '40%', xl: '35%' }}
            bg="atomicGray.900"
            overflowY="scroll"
          >
            <Box maxW={{ base: 'none', lg: `${ULTRAWIDE_BREAKPOINT * 0.35}px` }}>{children}</Box>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

const MarketingPane: React.FC = () => (
  <>
    <style>
      {
        '.fontweight-300 {font-weight: 300 !important;} .fontweight-400 {font-weight: 400 !important;} .fontweight-700 {font-weight: 700 !important;}'
      }
    </style>
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      h="100vh"
    >
      <Box w="70%">
        <img
          src={brandHeroImage}
          alt="engage-hero"
          style={{ margin: 'auto', width: '100%', maxWidth: '70vh' }}
        />
        <Box
          mx="8%"
          mt={2}
        >
          <ZText
            display="inline-block"
            className="fontweight-400"
            fontSize="13px"
            bg="atomicBlue.400"
            color="white"
            borderRadius={4}
            px={2}
            py={0.5}
            mb={1}
          >
            FEATURED
          </ZText>
          <ZHeading
            as="h1"
            fontSize={{ 'base': '32px', '2xl': '42px' }}
            className="fontweight-700"
            mb={2}
          >
            Efficiently Nurture Your Most Valued Accounts
          </ZHeading>
          <ZText
            className="fontweight-300"
            fontSize={{ 'base': '14px', '2xl': '18px' }}
            mb={6}
          >
            Run targeted offline campaigns and get comprehensive reporting with Postal ABM. Focus your budget on
            accounts that will drive the most conversions.
          </ZText>
          <ZLink
            href="https://www.postal.com/solutions/abm"
            fontSize={{ base: '14px', xl: '18px' }}
            className="fontweight-400"
            color="atomicBlue.400"
            _hover={{ color: 'atomicBlue.600' }}
          >
            Learn More{' '}
            <Icon
              as={MdArrowForward}
              verticalAlign="middle"
              mb={1}
            />
          </ZLink>
        </Box>
      </Box>
    </Flex>
  </>
)
