import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import type { UiButtonSsoProps } from '@postal-io/postal-ui'
import { UiButtonSso, UiIconButtonSso, useAlerts } from '@postal-io/postal-ui'
import { getSsoEndpoint } from 'lib'
import React from 'react'
import { ExternalProviders } from '../Integrations'

/*
  TODO: make UiButtonSso and UiIconButtonSso more generic, they shouldn't know about
  routes and icons.  Pass in icon and handle onClick here.
*/

export interface SsoOptionProps extends BoxProps {
  params?: Record<string, string>
  isDisabled?: boolean
  onClick?: (e: any) => void
}

const providers = ExternalProviders.filter((p) => p.sso?.enabled)

export const SsoOption: React.FC<SsoOptionProps> = ({ onClick, isDisabled, params, ...props }) => {
  const Alert = useAlerts()

  const handleClick = (name: UiButtonSsoProps['name']) => {
    const provider = providers.find((p) => p?.sso?.name === name)
    const endpoint = provider?.sso?.endpoint
    if (!endpoint) return Alert.warning('This provider is currently unavailable')

    const url = getSsoEndpoint({ endpoint, params })
    window.location.href = url
    onClick && onClick(provider)
  }
  return (
    <Box
      w="100%"
      {...props}
    >
      <Flex
        direction="column"
        mx="auto"
      >
        <UiButtonSso
          data-testid="Button_Sso_google"
          isDisabled={isDisabled}
          mb={4}
          name="google"
          w="100%"
          onClick={() => handleClick('google')}
        />
        <UiButtonSso
          data-testid="Button_Sso_salesforce"
          isDisabled={isDisabled}
          mb={4}
          name="salesforce"
          w="100%"
          onClick={() => handleClick('salesforce')}
        />
        <UiButtonSso
          data-testid="Button_Sso_microsoft"
          isDisabled={isDisabled}
          mb={4}
          name="microsoft"
          w="100%"
          onClick={() => handleClick('microsoft')}
        />
      </Flex>
    </Box>
  )
}

export const SsoIconOption: React.FC<SsoOptionProps> = ({ onClick, isDisabled, params, ...props }) => {
  const Alert = useAlerts()

  const handleClick = (name: UiButtonSsoProps['name']) => {
    const provider = providers.find((p) => p?.sso?.name === name)
    const endpoint = provider?.sso?.endpoint
    if (!endpoint) return Alert.warning('This provider is currently unavailable')

    const url = getSsoEndpoint({ endpoint, params })
    window.location.href = url
    onClick && onClick(provider)
  }

  return (
    <Box {...props}>
      <UiIconButtonSso
        data-testid="Button_Sso_google"
        isDisabled={isDisabled}
        name="google"
        onClick={() => handleClick('google')}
        fontSize="4xl"
        width="51px"
        height="51px"
        borderRadius="50%"
        borderColor="atomicGray.300"
        mr={2}
      />
      <UiIconButtonSso
        data-testid="Button_Sso_salesforce"
        isDisabled={isDisabled}
        name="salesforce"
        onClick={() => handleClick('salesforce')}
        fontSize="5xl"
        width="51px"
        height="51px"
        borderRadius="50%"
        borderColor="atomicGray.300"
        mr={2}
      />
      <UiIconButtonSso
        data-testid="Button_Sso_microsoft"
        isDisabled={isDisabled}
        name="microsoft"
        onClick={() => handleClick('microsoft')}
        fontSize="4xl"
        width="51px"
        height="51px"
        borderRadius="50%"
        borderColor="atomicGray.300"
      />
    </Box>
  )
}
