import { Box, Flex, FormControl, Grid, InputGroup, InputRightElement, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import {
  UiButtonScrollTop,
  UiSidePanelContainer,
  useAlertError,
  useColor,
  useInfiniteScroll,
  ZButton,
  ZInput,
  ZInputLeftIcon,
  ZProductCard,
  ZText,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, MarketplaceProductFilterInput } from 'api'
import { SearchApprovedPostalsDocument, Status } from 'api'
import { MarketplaceCardPostal } from 'components/Marketplace/MarketplaceCardPostal'
import {
  APPROVED_POSTAL_FILTER_INPUT_KEYS,
  CATEGORIES_ITEMS_ONLY,
  ITEM_CATEGORY_EXCLUSIONS,
  MARKETPLACE_CARD_MIN_WIDTH,
} from 'components/Postals/data'
import { AnalyticsEventV2, useAnalyticsEvent, useAnalyticsSend, useExtension, useMe, usePostalFilters } from 'hooks'
import { usePostalFavoriteStatus } from 'hooks/usePostalFavoriteStatus'
import { StorageKeys } from 'lib'
import { every, pick, some } from 'lodash'
import { useMemo } from 'react'
import { MdFilterList, MdKeyboardArrowLeft, MdOutlineSearch } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ExtFilter } from '../Common'
import { ExtHeader } from '../Main/ExtHeader'

const LIMIT = 20

export const ExtMarketplaceViewPostals = () => {
  const { contactId } = useExtension()
  const navigate = useNavigate()
  const panelFilter = useDisclosure()
  const { colorCode } = useColor()
  const { approvedCurrencies } = useMe()

  const defaultVariables = useMemo(() => {
    return {
      category: { in: CATEGORIES_ITEMS_ONLY },
      status: { eq: Status.Active },
      currency: { in: approvedCurrencies },
    }
  }, [approvedCurrencies])

  const { filters, graphqlFilter, updateFilter, clearFilters } = usePostalFilters<MarketplaceProductFilterInput>({
    defaultVariables,
    persistKey: StorageKeys.ExtMarketplaceFilter,
  })

  const filter = useMemo(() => pick(graphqlFilter, APPROVED_POSTAL_FILTER_INPUT_KEYS), [graphqlFilter])

  // load approved postals
  const searchPostals = useGraphqlInfiniteQuery(
    SearchApprovedPostalsDocument,
    {
      filter,
      limit: LIMIT,
    }
    // { keepPreviousData: true }
  )
  useAlertError(searchPostals.error)

  const postals = useMemo(
    () => searchPostals.mergedData?.searchApprovedPostals ?? [],
    [searchPostals.mergedData?.searchApprovedPostals]
  )

  // hack for outOfStock checkbox
  // would be better to update status on Product level or use variants_status filter but those options N/A ATM
  const postalsWithOutOfStockFilter = useMemo(() => {
    return postals.filter((postal) =>
      some(postal?.variants, (v) => (filters.outOfStock ? true : v.status === Status.Active))
    )
  }, [filters.outOfStock, postals])

  const { postalsWithFavoriteStatus } = usePostalFavoriteStatus(postalsWithOutOfStockFilter)

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchPostals.hasNextPage,
    loadMore: searchPostals.fetchNextPage,
    loading: searchPostals.isFetching,
  })

  const handleBack = () => {
    sendAnalytics({ event: AnalyticsEventV2.ExtensionSendFlowBackClicked })
    if (contactId) {
      navigate(`/extension/contacts/${contactId}`)
    } else {
      navigate(`/extension/`)
    }
  }

  const sendAnalytics = useAnalyticsSend()

  const handleSelect = (postal: ApprovedPostal) => {
    sendAnalytics({ event: AnalyticsEventV2.ExtensionSendFlowItemSelected })
    navigate(`/extension/marketplace/${postal.id}`)
  }

  useAnalyticsEvent({ event: AnalyticsEventV2.ExtensionSendFlowSelectItemStep })

  const numActiveFilters = Object.keys(filters).filter((k) => k !== 'q').length

  return (
    <Box p={4}>
      <ExtHeader>
        <Flex
          alignItems="center"
          columnGap="10px"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="30px"
            height="30px"
            bg="atomicGray.50"
            borderRadius="50%"
            _hover={{ bg: 'atomicGray.100', cursor: 'pointer' }}
            onClick={handleBack}
          >
            <MdKeyboardArrowLeft
              size="16px"
              color="#8492A6"
            />
          </Box>
          <ZText size="lg">Marketplace</ZText>
        </Flex>
      </ExtHeader>

      <FormControl
        id="name"
        my={4}
      >
        <InputGroup>
          <ZInputLeftIcon
            icon={
              <MdOutlineSearch
                size="16px"
                color={colorCode('atomicGray.500')}
              />
            }
          />
          <ZInput
            name="search"
            placeholder="Search"
            value={filters?.q ?? ''}
            onChange={(e: any) => updateFilter('q', e.target.value)}
          />

          <InputRightElement mr={2}>
            <Flex
              alignItems="center"
              justifyContent="center"
              columnGap="5px"
              cursor="pointer"
              onClick={panelFilter.onOpen}
              color="atomicGray.500"
            >
              <Box
                bg="atomicBlue.400"
                width="18px"
                height="18px"
                borderRadius="9px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                opacity={numActiveFilters === 0 ? 0 : 1}
              >
                <ZText color="white">{numActiveFilters}</ZText>
              </Box>

              <MdFilterList size="16px" />
            </Flex>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      {/* <Box color="atomicGray.500">
          <MdFilterList size="16px" />
        </Box> */}
      {panelFilter.isOpen && (
        <ExtFilter
          isOpen
          onClose={panelFilter.onClose}
          filterType="ApprovedPostal"
          filters={filters}
          onUpdate={updateFilter}
          excludeCategories={ITEM_CATEGORY_EXCLUSIONS}
          approvedCurrencies={approvedCurrencies}
          showDraft
          bottomBlock={
            <Flex columnGap="10px">
              <ZButton
                colorScheme="atomicBlue"
                borderRadius="3px"
                onClick={() => panelFilter.onClose()}
              >
                Show {postals ? (postals.length === 0 ? 0 : postals.length + '+') : 0} Items
              </ZButton>
              {Object.keys(filters).length !== 0 && (
                <ZButton
                  variant="ghost"
                  colorScheme="atomicGray"
                  borderRadius="3px"
                  onClick={() => clearFilters()}
                >
                  Clear All
                </ZButton>
              )}
            </Flex>
          }
        />
      )}
      <UiSidePanelContainer gridGap={16}>
        <Flex
          direction="column"
          w="100%"
          ref={topRef}
          style={{ scrollMargin: '20px' }}
        >
          <Grid
            templateColumns={`repeat(auto-fill, minmax(150px, 1fr))`}
            gap={4}
          >
            {postalsWithFavoriteStatus?.map((postal: any) => {
              const isDisabled = every(postal.variants?.map((v: any) => v.status !== Status.Active))
              return (
                <MarketplaceCardPostal
                  key={postal.id}
                  postal={postal}
                  onSelect={isDisabled ? undefined : handleSelect}
                  favoriteItemId={postal.favoriteItemId}
                  truncateLength={33}
                  isDisabled={isDisabled}
                />
              )
            })}
            {searchPostals.isFetching && postals.length < 1 ? (
              <ZProductCard
                isLoading
                h={MARKETPLACE_CARD_MIN_WIDTH}
                w={MARKETPLACE_CARD_MIN_WIDTH}
                startColor="atomicGray.50"
                endColor="atomicGray.200"
              />
            ) : postals.length < 1 ? (
              <ZText>No available items.</ZText>
            ) : null}
          </Grid>
          {postals.length >= LIMIT && (
            <Box
              ref={bottomRef}
              mb={8}
              position="relative"
            >
              <UiButtonScrollTop
                onClick={scrollTop}
                position="absolute"
                top="0px"
                right="0px"
                isLoading={searchPostals.isFetching}
                aria-label="scroll button"
              />
            </Box>
          )}
        </Flex>
      </UiSidePanelContainer>
    </Box>
  )
}
