import { Box, Flex } from '@chakra-ui/react'
import type { Column } from '@postal-io/postal-ui'
import { UiCheckboxIcon, UiLink, UiTooltip } from '@postal-io/postal-ui'
import type { PostalFulfillment } from 'api'
import { FulfillmentStatus } from 'api'
import { statusMapper } from 'lib'
import { Link } from 'react-router-dom'
import { SendType } from '../../api/index'
import { ZLink, ZStatusTag } from '../Common/ZComponents'

export const EVENT_CARD_MIN_WIDTH = 250

export const EVENT_SUCCESS_LIST = [
  FulfillmentStatus.Placed,
  FulfillmentStatus.Confirmed,
  FulfillmentStatus.Shipped,
  FulfillmentStatus.PendingDelivery,
  FulfillmentStatus.OutForDelivery,
  FulfillmentStatus.Delivered,
  FulfillmentStatus.DeliveredAssumed,
  FulfillmentStatus.Processing,
]

export const columns: Column[] = [
  {
    key: 'contactName',
    label: 'CONTACT',
    render: ({ contactName, contactId, isContactOwner }: any) => (
      <UiTooltip
        label="This isn't your contact"
        closeOnClick={false}
        isDisabled={isContactOwner}
      >
        <Box>
          <UiLink
            as={Link}
            to={`/contacts/${contactId}`}
            state={{ returnTo: 'Event' }}
            isDisabled={!isContactOwner}
            data-private
          >
            {contactName}
          </UiLink>
        </Box>
      </UiTooltip>
    ),
  },
  {
    label: 'INVITE ACCEP.',
    render: ({ status }: PostalFulfillment) => {
      const hasInvite = EVENT_SUCCESS_LIST.includes(status)
      return (
        <Flex justifyContent="center">
          <UiCheckboxIcon isChecked={hasInvite} />
        </Flex>
      )
    },
  },
  {
    key: 'type',
    label: 'TYPE',
    render: ({ id, postalId, magicLinkId, campaignId, campaignName, magicLinkName, triggerId, playbookId }: any) => {
      if (magicLinkId) {
        return (
          <UiTooltip
            aria-label={magicLinkName}
            label={magicLinkName}
          >
            <UiLink
              as={Link}
              to={`/links/${magicLinkId}`}
              state={{ returnTo: 'Event' }}
              whiteSpace="nowrap"
            >
              MagicLink
            </UiLink>
          </UiTooltip>
        )
      } else if (campaignId) {
        return (
          <UiTooltip
            aria-label={campaignName}
            label={campaignName}
          >
            <UiLink
              as={Link}
              to={`/campaigns/${campaignId}`}
              state={{ returnTo: 'Event' }}
              whiteSpace="nowrap"
            >
              Campaign
            </UiLink>
          </UiTooltip>
        )
      } else if (playbookId) {
        return (
          <UiTooltip
            aria-label="Subscription"
            label="Subscription"
          >
            <UiLink
              to={`/subscriptions/${playbookId}`}
              state={{ returnTo: 'Event' }}
              as={Link}
            >
              Email
            </UiLink>
          </UiTooltip>
        )
      } else if (triggerId) {
        return (
          <UiTooltip
            aria-label="Trigger"
            label="Trigger"
          >
            <UiLink
              to={`/triggers`}
              state={{ returnTo: 'Event' }}
              as={Link}
            >
              Trigger
            </UiLink>
          </UiTooltip>
        )
      } else {
        return (
          <UiTooltip
            aria-label="Email Invitation"
            label="Direct Email Invitation"
          >
            <UiLink
              to={`/events/postals/${postalId}/${id}`}
              state={{ returnTo: 'Event' }}
              as={Link}
            >
              Email
            </UiLink>
          </UiTooltip>
        )
      }
    },
  },
  {
    label: 'STATUS',
    render: ({ status, id, sendType }: any) => {
      const { text, backgroundColor, color } = statusMapper(status)

      const modText = text === 'Item Email' ? 'Event Email' : text
      const sendPath =
        sendType === SendType.GiftEmail
          ? 'emails'
          : sendType === SendType.Direct
          ? 'direct'
          : sendType === SendType.Subscription
          ? 'subscriptions'
          : sendType === SendType.Trigger
          ? 'triggers'
          : 'links'

      return (
        <ZLink
          href={`/orders/${sendPath}/${id}`}
          as={Link}
        >
          <ZStatusTag
            minW="150px"
            textTransform="none"
            color={color}
            backgroundColor={backgroundColor}
            label={modText}
          />
        </ZLink>
      )
    },
  },
]
