import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import { UiSSDataTable, useAlertError } from '@postal-io/postal-ui'
import React, { useMemo } from 'react'
import { Role, SearchEventPostalFulfillmentsDocument } from '../../../api'
import { useAcl, useSession } from '../../../hooks'
import { columns } from '../data'

export interface ApprovedPostalEventAttendeesProps {
  approvedPostalId?: string
}

const LIMIT = 100

export const EventsApprovedPostalEventAttendees: React.FC<ApprovedPostalEventAttendeesProps> = ({
  approvedPostalId,
}) => {
  const {
    session: { userId },
  } = useSession()
  const { hasRole } = useAcl()

  const filter = useMemo(() => {
    return { filter: { approvedPostalId: { eq: approvedPostalId } }, limit: LIMIT }
  }, [approvedPostalId])

  const { mergedData, isFetching, error, hasNextPage, fetchNextPage } = useGraphqlInfiniteQuery(
    SearchEventPostalFulfillmentsDocument,
    filter,
    { enabled: !!approvedPostalId && !!userId }
  )

  const canReadContacts = hasRole(Role.Manager)

  const eventFulfillments = useMemo(() => {
    return mergedData?.searchEventPostalFulfillments
  }, [mergedData?.searchEventPostalFulfillments])

  const normalizedFulfillments = useMemo(() => {
    return (
      eventFulfillments?.map((item) => {
        return {
          id: item.id,
          sendType: item.sendType,
          postalId: item.approvedPostalId,
          status: item.status,
          contactName: item.shipToName,
          contactId: item.contactId,
          magicLinkName: item.magicLinkName,
          magicLinkId: item.magicLinkId,
          campaignId: item.campaignId,
          campaignName: item.campaignName,
          playbookId: item.playbookId,
          triggerId: item.triggerId,
          isContactOwner: item.userId === userId || canReadContacts,
        }
      }) || []
    )
  }, [eventFulfillments, canReadContacts, userId])

  useAlertError(error)

  return (
    <UiSSDataTable
      mt={4}
      variant="list"
      isLoading={isFetching}
      columns={columns}
      rows={normalizedFulfillments}
      rowKey="id"
      filter={filter}
      fetchMore={fetchNextPage}
      hasMore={hasNextPage}
    />
  )
}
