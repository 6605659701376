import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Link as ChakraLink,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import { LexendFontWeight, UiSkeleton, UiSSDataTable, useGraphqlFilter } from '@postal-io/postal-ui'
import type { SearchIntegrationTriggersQueryVariables } from 'api'
import { SearchIntegrationTriggersDocument } from 'api'
import { ZLink, zMultiSelectCellStyles, zMultiSelectStyles } from 'components/Common/ZComponents'
import { AnalyticsEvent, PageTitle, useAcl, useAnalyticsEvent } from 'hooks'
import React, { useMemo, useRef } from 'react'
import { MdPersonAddAlt, MdSearch } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { CenteredBox } from '../Common/CenteredBox'
import { TriggersFilter } from '../Triggers/TriggersFilter'
import { TriggersHeader } from '../Triggers/TriggersHeader'
import { TriggersTeaser } from '../Triggers/TriggersTeaser'
import { useTriggerProviders } from '../Triggers/useTriggerProviders'
import { columns, transforms } from './data'

const DEBOUNCE = 400
const staticVariables = { limit: 100 }

export const Triggers: React.FC = () => {
  const informDisclosure = useDisclosure()
  const { hasPermission } = useAcl()
  const navigate = useNavigate()
  const { providers } = useTriggerProviders()
  const cancelRef = useRef(null)

  useAnalyticsEvent({ event: AnalyticsEvent.TriggersPageViewed })

  const searchCount = useGraphqlQuery(SearchIntegrationTriggersDocument, { limit: 1 })
  const hasTriggers = useMemo(
    () => !!searchCount.data?.searchIntegrationTriggers?.length,
    [searchCount.data?.searchIntegrationTriggers?.length]
  )

  const graphqlFilter = useGraphqlFilter<SearchIntegrationTriggersQueryVariables>({
    transforms,
    staticVariables,
    debounce: DEBOUNCE,
  })
  const searchTriggers = useGraphqlInfiniteQuery(SearchIntegrationTriggersDocument, graphqlFilter.variables)

  const triggers = useMemo(
    () => searchTriggers.mergedData?.searchIntegrationTriggers ?? [],
    [searchTriggers.mergedData?.searchIntegrationTriggers]
  )

  const filteredColumns = useMemo(() => {
    return hasPermission('users.read') ? columns : columns.filter((c) => c.label !== 'Owner')
  }, [hasPermission])

  const createTrigger = () => navigate('/triggers/edit')

  return (
    <>
      <PageTitle title="Triggers" />
      <TriggersHeader
        header="Triggers"
        right={
          <ZLink
            color="atomicBlue.400"
            fontWeight={LexendFontWeight.Bold}
            onClick={createTrigger}
          >
            <MdPersonAddAlt
              size={20}
              style={{ marginRight: 5 }}
            />
            Create a Trigger
          </ZLink>
        }
      />

      <UiSkeleton
        mt={4}
        isLoaded={!searchCount.isLoading}
      >
        {!hasTriggers ? (
          <TriggersTeaser handleClick={!!providers.length ? createTrigger : informDisclosure.onOpen} />
        ) : (
          <CenteredBox isLoaded>
            <Flex>
              <InputGroup mr={2}>
                <InputLeftElement
                  h="38px"
                  color="gray.500"
                  width="unset"
                  pl={0}
                >
                  <MdSearch size={24} />
                </InputLeftElement>
                <Input
                  mr={2}
                  pl={8}
                  fontWeight={LexendFontWeight.Regular}
                  onChange={(e: any) => graphqlFilter.setFilter({ key: 'name', value: e.target.value })}
                  placeholder="Search Triggers"
                  value={graphqlFilter.filter.name || ''}
                  color="gray.500"
                  variant="unstyled"
                  _focusVisible={{
                    boxShadow: 'none !important',
                  }}
                />
              </InputGroup>
              <TriggersFilter graphqlFilter={graphqlFilter} />
            </Flex>
            <UiSSDataTable
              mt={3}
              rows={triggers}
              columns={filteredColumns}
              hasMore={searchTriggers.hasNextPage}
              fetchMore={searchTriggers.fetchNextPage}
              isLoading={searchTriggers.isFetching}
              rowKey="id"
              filter={graphqlFilter.variables.filter}
              orderBy={graphqlFilter.orderBy}
              onOrderBy={graphqlFilter.setOrderBy}
              sx={{
                ...zMultiSelectStyles,
                ...zMultiSelectCellStyles,
                border: 'none',
                boxShadow: 'none',
              }}
            />
          </CenteredBox>
        )}
      </UiSkeleton>
      <AlertDialog
        size="lg"
        isOpen={informDisclosure.isOpen}
        onClose={informDisclosure.onClose}
        leastDestructiveRef={cancelRef}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Integration Required</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Stack spacing={4}>
              <Text size="lg">It looks like you don't have any integrations setup yet.</Text>
              {hasPermission('triggers.create') ? (
                <Text size="lg">
                  Head on over to our{' '}
                  <ChakraLink
                    as={Link}
                    to="/integrations"
                    size="lg"
                  >
                    Integrations
                  </ChakraLink>{' '}
                  page to get started.
                </Text>
              ) : (
                <Text fontSize="lg">Ask your administrator to setup your favorite integration to get started.</Text>
              )}
            </Stack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              minW={32}
              onClick={informDisclosure.onClose}
            >
              Ok
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
