import { Flex } from '@chakra-ui/react'
import { UiIconPostalEngageLogo, UiIconPostalLogo } from '@postal-io/postal-ui'
import type { PropsWithChildren } from 'react'

export const LogInHeaderWhite: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      w="100%"
      maxW="600px"
      mx="auto"
      mt={12}
      px={5}
    >
      <UiIconPostalLogo
        color="primary.500"
        fontSize="200px"
        h="auto"
      />

      {children}
    </Flex>
  )
}

export const LogInHeaderBlack: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      direction="column"
      width="100%"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      bg="atomicGray.900"
    >
      <Flex width="100%">
        <UiIconPostalEngageLogo
          color="white"
          fontSize="200px"
          h="auto"
          mx="auto"
        />
      </Flex>
      {children}
    </Flex>
  )
}
