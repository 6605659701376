import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import { ZHeading, ZText } from '@postal-io/postal-ui'
import { ZAlert } from 'components/Common/ZComponents'
import { ExistingMagicLinkDisplay } from 'components/Postal/ExistingMagicLinkDisplay'
import type { PostalSendContext } from 'components/PostalSend/usePostalSend'
import { PostalSendMethod, PostalSendType } from 'components/PostalSend/usePostalSend'
import { useExtension } from 'hooks'
import React, { useMemo } from 'react'

interface PostalSendMethodHeaderProps extends BoxProps {
  context?: PostalSendContext
}

export const PostalSendMethodHeader: React.FC<PostalSendMethodHeaderProps> = ({ context, ...rest }) => {
  const { isExtension } = useExtension()
  const isMagicLink = useMemo(() => context?.method === PostalSendMethod.Link, [context])

  const heading = useMemo(() => {
    if (isMagicLink) return 'MagicLink'
    if (context?.method === PostalSendMethod.BulkSend) return 'Bulk Send'
    else if (context?.deliveryEmail) return 'Send a Personalized Email'
    else if (!context?.deliveryEmail) return 'Send Directly'
  }, [context, isMagicLink])

  const subheading = useMemo(() => {
    if (isMagicLink) return 'Create and customize your MagicLink landing page for your recipients'
    if (context?.method === PostalSendMethod.BulkSend)
      return 'Order multiple of one item to be sent to a single location'
    else if (context?.deliveryEmail)
      return 'Select your recipient(s) and customize your email and landing page messaging'
    else if (!context?.deliveryEmail)
      return 'Surprise and delight your recipients with a direct send of an item from the Marketplace'
  }, [context, isMagicLink])

  return (
    <>
      <ZHeading
        size="h5"
        position="relative"
        mx={{ base: 4, md: 10 }}
        {...rest}
      >
        {heading}
        {!isExtension && context?.method === PostalSendMethod.Link && !context?.link && (
          <ExistingMagicLinkDisplay
            position="absolute"
            right={0}
            top={2.5}
            postal={context?.postal}
          />
        )}
      </ZHeading>
      <ZText
        mt={2.5}
        fontSize="sm"
        color="atomicGray.500"
        mx={{ base: 4, md: 10 }}
        {...rest}
      >
        {subheading}
      </ZText>
      {context?.type === PostalSendType.Postal &&
        context?.method === PostalSendMethod.Direct &&
        context?.postal?.variantOrderFlexibility && (
          <Box
            mt={5}
            mb={0}
            mx={{ base: 0, md: 10 }}
            {...rest}
          >
            <ZAlert
              status="info"
              hideClose
            >
              Note: Recipients cannot choose an option when sending directly. Please select one for them using the
              sidebar on the right.
            </ZAlert>
          </Box>
        )}
      <Box h={8} />
    </>
  )
}
