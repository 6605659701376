import { Grid } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiFormControl, UiToggle, useAlertError, useAlerts, ZCard, ZCardHeader, ZFormLabel } from '@postal-io/postal-ui'
import type { UserNotifications } from 'api'
import { MeDocument, Role, UpdateUserNotificationsDocument, UserNotificationChannel } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { useAcl } from 'hooks'
import type { ChangeEvent } from 'react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'

const defaultChannels = {
  balanceLowChannels: [UserNotificationChannel.Email],
  budgetLowChannels: [UserNotificationChannel.Email],
  giftEmailAcceptedChannels: [UserNotificationChannel.Email],
  magicLinkAcceptedChannels: [UserNotificationChannel.Email],
  orderDeliveredChannels: [UserNotificationChannel.Email],
  autoReloadFailuresChannels: [UserNotificationChannel.Email],
  orderIssuesChannels: [UserNotificationChannel.Email],
}
export const UserNotificationsDisplay: React.FC = (props) => {
  const Alert = useAlerts()
  const meQuery = useGraphqlQuery(MeDocument)
  const { hasRole } = useAcl()
  const isAdmin = hasRole(Role.Admin)

  const notifications = useMemo(
    () => meQuery.data?.me?.notifications || ({} as UserNotifications),
    [meQuery.data?.me?.notifications]
  )

  const [form, setForm] = useImmer<Record<string, any>>({})

  const resetForm = useCallback(() => {
    setForm((draft: UserNotifications) => {
      draft.balanceLowNotificationsOn = notifications.balanceLowNotificationsOn
      draft.budgetLowNotificationsOn = notifications.budgetLowNotificationsOn
      draft.giftEmailAcceptedNotificationsOn = notifications.giftEmailAcceptedNotificationsOn
      draft.magicLinkAcceptedNotificationsOn = notifications.magicLinkAcceptedNotificationsOn
      draft.orderDeliveredNotificationsOn = notifications.orderDeliveredNotificationsOn
      draft.autoReloadFailuresNotificationsOn = notifications.autoReloadFailuresNotificationsOn
      draft.orderIssuesNotificationsOn = notifications.orderIssuesNotificationsOn
    })
  }, [
    notifications.balanceLowNotificationsOn,
    notifications.budgetLowNotificationsOn,
    notifications.giftEmailAcceptedNotificationsOn,
    notifications.magicLinkAcceptedNotificationsOn,
    notifications.orderDeliveredNotificationsOn,
    notifications.autoReloadFailuresNotificationsOn,
    notifications.orderIssuesNotificationsOn,
    setForm,
  ])

  useEffect(() => {
    resetForm()
  }, [resetForm])

  const updateNotifications = useGraphqlMutation(UpdateUserNotificationsDocument)

  const { hasFeature } = useAcl()

  const handleChange = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = target
    setForm((draft: Record<string, any>) => {
      draft[name] = checked
    })
    const data = { ...defaultChannels, ...form, [name]: checked } as any
    try {
      await updateNotifications.mutateAsync({ data })
      Alert.success('Setting Updated')
    } catch (err) {
      Alert.error(err)
      resetForm()
    }
  }

  useAlertError(meQuery.error)
  useAlertError(updateNotifications.error)

  const labelProps = {
    fontSize: 'md',
    fontWeight: 'normal',
    color: 'atomicGray.600',
    m: 0,
    ml: 4,
  }

  return (
    <>
      <ZCard
        isLoading={meQuery.isLoading}
        variant="form"
        {...props}
      >
        <ZCardHeader
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          p={8}
          pb={0}
          gap={2}
        >
          Notifications
          <ZInfoTooltip
            hasArrow
            label="Send me an email when the following actions occur."
          />
        </ZCardHeader>

        <Grid
          gap={8}
          p={8}
        >
          <UiFormControl
            id="balanceLowNotificationsOn"
            display="flex"
            alignItems="center"
          >
            <UiToggle
              size="lg"
              name="balanceLowNotificationsOn"
              isChecked={!!form.balanceLowNotificationsOn}
              isDisabled={updateNotifications.isLoading}
              onChange={handleChange}
              colorScheme="atomicBlue"
            />
            <ZFormLabel {...labelProps}>Balance Low</ZFormLabel>
          </UiFormControl>

          <UiFormControl
            id="budgetLowNotificationsOn"
            display="flex"
            alignItems="center"
          >
            <UiToggle
              size="lg"
              name="budgetLowNotificationsOn"
              isChecked={!!form.budgetLowNotificationsOn}
              isDisabled={updateNotifications.isLoading}
              onChange={handleChange}
              colorScheme="atomicBlue"
            />
            <ZFormLabel {...labelProps}>Budget Low</ZFormLabel>
          </UiFormControl>
          <UiFormControl
            id="giftEmailAcceptedNotificationsOn"
            display="flex"
            alignItems="center"
          >
            <UiToggle
              size="lg"
              name="giftEmailAcceptedNotificationsOn"
              isChecked={!!form.giftEmailAcceptedNotificationsOn}
              isDisabled={updateNotifications.isLoading}
              onChange={handleChange}
              colorScheme="atomicBlue"
            />
            <ZFormLabel {...labelProps}>Gift Email Acceptance</ZFormLabel>
          </UiFormControl>
          {hasFeature('links') && (
            <UiFormControl
              id="magicLinkAcceptedNotificationsOn"
              display="flex"
              alignItems="center"
            >
              <UiToggle
                size="lg"
                name="magicLinkAcceptedNotificationsOn"
                isChecked={!!form.magicLinkAcceptedNotificationsOn}
                isDisabled={updateNotifications.isLoading}
                onChange={handleChange}
                colorScheme="atomicBlue"
              />
              <ZFormLabel {...labelProps}>MagicLink Acceptance</ZFormLabel>
            </UiFormControl>
          )}
          <UiFormControl
            id="orderDeliveredNotificationsOn"
            display="flex"
            alignItems="center"
          >
            <UiToggle
              size="lg"
              name="orderDeliveredNotificationsOn"
              isChecked={!!form.orderDeliveredNotificationsOn}
              isDisabled={updateNotifications.isLoading}
              onChange={handleChange}
              colorScheme="atomicBlue"
            />
            <ZFormLabel {...labelProps}>Order Delivered</ZFormLabel>
          </UiFormControl>
          {isAdmin && (
            <UiFormControl
              id="autoReloadFailuresNotificationsOn"
              display="flex"
              alignItems="center"
            >
              <UiToggle
                size="lg"
                name="autoReloadFailuresNotificationsOn"
                isChecked={!!form.autoReloadFailuresNotificationsOn}
                isDisabled={updateNotifications.isLoading}
                onChange={handleChange}
                colorScheme="atomicBlue"
              />
              <ZFormLabel {...labelProps}>Auto Reload Failures</ZFormLabel>
            </UiFormControl>
          )}

          {hasFeature('orderBlockedEmail') && (
            <UiFormControl
              id="orderIssuesNotificationsOn"
              display="flex"
              alignItems="center"
            >
              <UiToggle
                size="lg"
                name="orderIssuesNotificationsOn"
                isChecked={!!form.orderIssuesNotificationsOn}
                isDisabled={updateNotifications.isLoading}
                onChange={handleChange}
                colorScheme="atomicBlue"
              />
              <ZFormLabel {...labelProps}>
                Order Issues{' '}
                <ZInfoTooltip
                  ml={1}
                  hasArrow
                  label="Issues pertaining to orders blocked by the admin-set blocklist"
                />
              </ZFormLabel>
            </UiFormControl>
          )}
        </Grid>
      </ZCard>
    </>
  )
}
