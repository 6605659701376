import { Stack, Text } from '@chakra-ui/react'
import {
  UiTable,
  UiTbody,
  UiTd,
  UiTh,
  UiThead,
  UiTr,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
} from '@postal-io/postal-ui'
import React from 'react'

export interface TriggerEditFiltersHelpV2Props {
  isOpen: boolean
  onClose: () => void
}
export const TriggerEditFiltersHelp: React.FC<TriggerEditFiltersHelpV2Props> = ({ isOpen, onClose }) => {
  return (
    <ZModal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
    >
      <ZModalOverlay />
      <ZModalContent>
        <ZModalHeader>Trigger Filters</ZModalHeader>
        <ZModalCloseButton />
        <ZModalBody>
          <Stack spacing={4}>
            <Text>
              Filters are used to determine when a value is set or when a transition has occurred in a data set.{' '}
              <i>You must provide at least one filter for a trigger.</i>
            </Text>
            <Text>
              If you want the trigger to fire when a value is set, you can use the <strong>Current Value</strong> on a
              field. Anytime the current value meets the filter criteria, the trigger is fired.
            </Text>
            <Text>
              If you want the trigger to fire when a transition happens, then you can use a{' '}
              <strong>Previous Value</strong> and a <strong>Current Value</strong> filter on the <strong>same</strong>{' '}
              field. Anytime the field transitions from the previous value to the current value, the trigger is fired.
            </Text>
            <Text>
              Below is an example of detecting when an <strong>Opportunity</strong> changes the{' '}
              <strong>Stage Name</strong> from anything into the <strong>Closed Won</strong> state.
            </Text>
            <UiTable>
              <UiThead>
                <UiTr>
                  <UiTh>Field</UiTh>
                  <UiTh>Transition</UiTh>
                  <UiTh>Filter</UiTh>
                  <UiTh>Value</UiTh>
                </UiTr>
              </UiThead>
              <UiTbody>
                <UiTr>
                  <UiTd>Stage Name</UiTd>
                  <UiTd>Previous Value</UiTd>
                  <UiTd>String Not Equals</UiTd>
                  <UiTd>Closed Won</UiTd>
                </UiTr>
                <UiTr>
                  <UiTd>Stage Name</UiTd>
                  <UiTd>Current Value</UiTd>
                  <UiTd>String Equals</UiTd>
                  <UiTd>Closed Won</UiTd>
                </UiTr>
              </UiTbody>
            </UiTable>
          </Stack>
        </ZModalBody>
      </ZModalContent>
    </ZModal>
  )
}
