import { Box, Flex, SimpleGrid, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiMenu,
  UiMenuButton,
  UiMenuItem,
  UiMenuList,
  UiTextTruncate,
  useColor,
  ZButton,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import type { Team } from 'api'
import { BudgetMode, GetTeamByIdDocument, GetUserDocument, Role } from 'api'
import { useAcl, useBalance, useBudget, useMe, useSession, useTeamWithBudget } from 'hooks'
import React, { useCallback, useEffect, useMemo } from 'react'
import { MdCheckCircle, MdOutlineArrowDropDown, MdOutlineSavings } from 'react-icons/md'
import type { BillingSpendAsProps } from './BillingSpendAs'
import { validTeamFilter } from './BillingSpendAs'

export const BillingSpendAsTeam: React.FC<BillingSpendAsProps> = ({ onChangeTeam, onChangeUser, userId, teamId }) => {
  const { hasFeature, hasRole } = useAcl()
  const teamDisclosure = useDisclosure()
  const { session } = useSession()
  const { Color } = useColor()
  const { me } = useMe()
  const canSpendAs = hasFeature('budgetDropDown')
  const isAdmin = hasRole(Role.Admin)

  const selectedUserQuery = useGraphqlQuery(GetUserDocument, { id: userId as string }, { enabled: !!userId })

  const selectedUser = useMemo(() => selectedUserQuery.data?.getUser, [selectedUserQuery.data?.getUser])

  const selectedUserTeams = useMemo(
    () => selectedUser?.productAccess?.filter(validTeamFilter) || [],
    [selectedUser?.productAccess]
  )

  const sessionTeamQuery = useGraphqlQuery(GetTeamByIdDocument, { id: session.teamId }, { enabled: !!session.teamId })

  const { teamName, teamBudget, teamBudgetMode, teamBalance } = useTeamWithBudget(teamId, userId)

  const entityName = useMemo(() => {
    return session.teamId ? sessionTeamQuery.data?.getTeamById?.name : me?.accountName
  }, [me?.accountName, session.teamId, sessionTeamQuery.data?.getTeamById?.name])

  const { showBudget, budgetRemaining } = useBudget()
  const { showBalance, balanceRemaining } = useBalance()
  const budgetDisplayValue = useMemo(() => {
    if (teamName) return teamBudgetMode === BudgetMode.Unlimited ? teamBalance : teamBudget
    return showBudget ? budgetRemaining : showBalance ? balanceRemaining : 0
  }, [teamName, teamBudgetMode, teamBalance, teamBudget, showBudget, showBalance, balanceRemaining, budgetRemaining])

  const onSelect = (team: Team | null) => {
    onChangeTeam && onChangeTeam(team)
    setTimeout(() => teamDisclosure.onClose?.(), 300)
  }

  /*
    If a non-admin gets to this page, we want to preset the userId and teamId that
    they will send as.  This is so we know to show the teamId list which would allow
    them to pick a different team.
  */
  useEffect(() => {
    if (canSpendAs && !userId) {
      onChangeUser && onChangeUser(session.userId)
      onChangeTeam && onChangeTeam(session.teamId || null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UiMenu
      {...teamDisclosure}
      matchWidth
    >
      <UiMenuButton
        as={ZButton}
        pr={3}
        height="40px"
        borderRadius={3}
        width="100%"
        fontSize="sm"
        variant="outline"
        position="relative"
        color="atomicGray.800"
        bg="white"
        borderColor="atomicGray.200"
        _hover={{ bg: 'white', borderColor: 'atomicGray.400' }}
        textAlign="left"
        leftIcon={
          <MdOutlineSavings
            viewBox="2 2 20 20"
            fontSize="16px"
          />
        }
        rightIcon={
          <MdOutlineArrowDropDown
            viewBox="2 2 20 20"
            size="15px"
            style={{
              color: Color('atomicGray.500'),
              transition: 'transform 0.2s',
            }}
          />
        }
      >
        <Flex
          w="100%"
          justifyContent="space-between"
        >
          <ZText fontWeight="normal">{teamName || entityName}</ZText>
          <ZMoney
            fontWeight="bold"
            cents={budgetDisplayValue}
            color="atomicGray.500"
          />
        </Flex>
      </UiMenuButton>
      <UiMenuList boxShadow="0 0 15px rgba(0,0,0,.1)">
        {selectedUserTeams.map((product) => (
          <TeamSelectRow
            selected={product?.teamId === teamId}
            onSelect={onSelect}
            key={product?.id}
            accountId={session?.accountId}
            accountName={session?.accountName}
            teamId={product?.teamId as string}
            userId={selectedUser?.id as string}
            selectedTeamId={teamId}
            selectedUserId={userId}
            isAdmin={isAdmin}
          />
        ))}
        {!selectedUserTeams.length && <Text px={4}>No teams available</Text>}
      </UiMenuList>
    </UiMenu>
  )
}

export interface TeamSelectRowProps {
  accountId: string
  accountName?: string
  teamId: string | null
  userId: string
  isAdmin: boolean
  selected: boolean
  selectedTeamId?: string | null
  selectedUserId?: string
  onSelect: (team: Team | null) => void
}
export const TeamSelectRow: React.FC<TeamSelectRowProps> = ({
  accountName,
  teamId,
  userId,
  isAdmin,
  selected,
  selectedTeamId,
  selectedUserId,
  onSelect,
}) => {
  const { Color } = useColor()
  const { team, teamName, teamBudget, teamBudgetMode, teamBalance, teamLoaded } = useTeamWithBudget(teamId, userId)

  const { teamName: selectedTeamName } = useTeamWithBudget(selectedTeamId, selectedUserId)

  const onHandleSelectTeam = useCallback(() => {
    onSelect(team!)
  }, [onSelect, team])

  // populate any spend as data on load if it's missing
  useEffect(() => {
    if (!teamLoaded) return
    if (selectedTeamId === teamId && !selectedTeamName) onHandleSelectTeam()
  }, [teamLoaded, selectedTeamId, selectedTeamName, teamId, onHandleSelectTeam])

  return (
    <UiMenuItem
      onClick={onHandleSelectTeam}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={1}
      px={3}
      _hover={{ bg: 'atomicGray.10' }}
      cursor="pointer"
      borderRadius={3}
    >
      <Box
        wordBreak="break-all"
        width="200px"
      >
        <UiTextTruncate
          fontSize="sm"
          fontWeight={selected ? 'bold' : 'normal'}
          whiteSpace="nowrap"
          fontFamily="Lexend"
          display="flex"
          alignItems="center"
        >
          {teamName ?? accountName}
          {selected && (
            <MdCheckCircle style={{ marginLeft: '5px', display: 'inline-block', fill: Color('atomicBlue.400') }} />
          )}
        </UiTextTruncate>
      </Box>

      <SimpleGrid
        columns={2}
        width="130px"
        spacingY={0.5}
      >
        <Box>
          <ZText
            fontSize="xs"
            color="atomicGray.500"
          >
            Budget
          </ZText>
        </Box>
        <Box>
          <ZText
            fontSize="xs"
            color="gray.600"
            textAlign="right"
            whiteSpace="nowrap"
          >
            {teamBudgetMode !== BudgetMode.Unlimited ? (
              <ZMoney
                fontSize="xs"
                fontWeight={selected ? 'bold' : 'normal'}
                amount={(teamBudget ?? 0) / 100}
              />
            ) : (
              'Disabled'
            )}
          </ZText>
        </Box>

        {(isAdmin || (!isAdmin && teamBudgetMode === BudgetMode.Unlimited)) && (
          <>
            <Box>
              <ZText
                fontSize="xs"
                color="atomicGray.500"
                pr={2}
              >
                Balance
              </ZText>
            </Box>
            <Box>
              <ZText
                textAlign="right"
                color="atomicGray.600"
                whiteSpace="nowrap"
              >
                <ZMoney
                  fontSize="xs"
                  fontWeight={selected ? 'bold' : 'normal'}
                  amount={(teamBalance ?? 0) / 100}
                />
              </ZText>
            </Box>
          </>
        )}
      </SimpleGrid>
    </UiMenuItem>
  )
}
