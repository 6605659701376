import type { StackProps } from '@chakra-ui/react'
import { Container, Image, Link, VStack } from '@chakra-ui/react'
import { UiCard, ZButton, ZHeading, ZText } from '@postal-io/postal-ui'
import ABMOrdersPlaceholderImage from 'assets/abm/abm-orders-placeholder.png'
import { MdArrowForward } from 'react-icons/md'

const ABMUpsellURL =
  'https://docs.google.com/forms/d/e/1FAIpQLSeWnURyyfgc4QC6wWc_8xvmoeAhgDgQvc_LKmIRxpf6EVFuNA/viewform'

export const ABMOrdersPlaceholder: React.FC<StackProps> = () => {
  return (
    <Container maxW="2xl">
      <UiCard>
        <VStack
          gap={4}
          textAlign="center"
        >
          <Image
            src={ABMOrdersPlaceholderImage}
            maxWidth="500px"
            width="100%"
          />
          <ZHeading
            fontWeight="bold"
            fontSize="heading-xl"
          >
            Efficiently Nurture Your Most Valued Accounts
          </ZHeading>
          <ZText>
            Run targeted offline campaigns and get comprehensive reporting with Postal ABM. Focus your budget on
            accounts that will drive the most conversions.
          </ZText>
          <Link
            href={ABMUpsellURL}
            isExternal
          >
            <ZButton
              width="150px"
              colorScheme="atomicBlue"
              rightIcon={<MdArrowForward />}
            >
              Learn More
            </ZButton>
          </Link>
        </VStack>
      </UiCard>
    </Container>
  )
}
