import { UiLoading } from '@postal-io/postal-ui'
import { RouteErrorElement } from 'components/Common/ErrorBoundary'
import { Extension } from 'components/Extension'
import { ExtAccountTimeline } from 'components/Extension/Accounts'
import { ExtContact, ExtContactEmail, ExtContactIntegration } from 'components/Extension/Contact'
import { ExtDrafts } from 'components/Extension/Drafts'
import { ExtLinksDisplay } from 'components/Extension/Links/ExtLinksDisplay'
import { ExtSelectItem } from 'components/Extension/Links/ExtSelectItem'
import { ExtMarketplacePostalPage, ExtMarketplaceViewPostals, ExtViewFavorites } from 'components/Extension/Marketplace'
import { ExtContactPostalSendV2 } from 'components/Extension/Marketplace/ExtContactPostalSendV2'
import { ExtLinkPostalSendV2 } from 'components/Extension/Marketplace/ExtLinkPostalSendV2'
import { ExtSignIn } from 'components/Extension/Session/ExtSignIn'
import { ExtSso } from 'components/Extension/Session/ExtSso'

import { ContactPostalSend } from 'components/Postals'
import { useAcl } from 'hooks'
import { StorageKeys } from 'lib'
import { useMemo } from 'react'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ExtensionRoot from './ExtensionRoot'
import { requirePermissions } from './session'

export function ExtensionRoutes() {
  const { hasPermission, hasFeature, isLoading } = useAcl()
  const routes = useMemo(() => {
    return [
      {
        path: '/extension',
        children: [
          {
            path: 'sso/:token',
            element: <ExtSso />,
          },
          {
            path: 'sign-in',
            element: <ExtSignIn />,
            async loader({ request }: LoaderFunctionArgs) {
              const searchParams = new URL(request.url).searchParams
              const returnTo = searchParams.get('returnTo')
              if (returnTo) sessionStorage.setItem(StorageKeys.AppRedirectPath, returnTo)
              return new Response()
            },
          },
          {
            path: '*',
            element: <Extension />,
            children: [
              {
                path: 'marketplace/:approvedPostalId',
                element: <ExtMarketplacePostalPage />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('postals.read'))
                  return new Response()
                },
              },
              {
                path: 'marketplace/:approvedPostalId/send/*',
                element: <ExtContactPostalSendV2 />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('postals.send'))
                  return new Response()
                },
              },
              {
                path: 'marketplace',
                element: <ExtMarketplaceViewPostals />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('postals.read'))
                  return new Response()
                },
              },
              {
                path: 'favorites',
                element: <ExtViewFavorites />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('postals.read'))
                  return new Response()
                },
              },
              {
                path: 'magiclinks',
                element: <ExtLinksDisplay />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('links.read'))
                  return new Response()
                },
              },
              {
                path: 'magiclinks/select',
                element: <ExtSelectItem />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('postals.send'))
                  return new Response()
                },
              },
              {
                path: 'link/:approvedPostalId/*',
                element: <ExtLinkPostalSendV2 />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('postals.send'))
                  return new Response()
                },
              },
              {
                path: 'drafts',
                element: <ExtDrafts />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('campaigns.read'))
                  return new Response()
                },
              },
              {
                path: 'contacts/:systemName/:contactType/:contactId',
                element: <ExtContactIntegration />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('contacts.read'))
                  return new Response()
                },
              },
              {
                path: 'contacts/email/:emailAddress',
                element: <ExtContactEmail />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('contacts.read'))
                  return new Response()
                },
              },
              {
                path: 'contacts/:contactId',
                element: <ExtContact />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('contacts.read'))
                  return new Response()
                },
              },
              {
                path: 'contacts/:contactId/send/:approvedPostalId',
                element: <ContactPostalSend />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('postals.send'))
                  return new Response()
                },
              },
              {
                path: 'accounts/:crmIdentifier/timeline',
                element: <ExtAccountTimeline />,
                // what permission?
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('contacts.read') && hasFeature('abmV1'))
                  return new Response()
                },
              },
              {
                element: <ExtContact />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('contacts.read'))
                  return new Response()
                },
                index: true,
              },
              {
                path: '*',
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('contacts.read'))
                  return new Response()
                },
                element: <ExtContact />,
              },
            ],
          },
        ],
      },
      {
        path: '*',
        async loader() {
          throw new Response(null, { status: 404 })
        },
      },
    ]
  }, [hasFeature, hasPermission])

  const router = createBrowserRouter([
    {
      element: <ExtensionRoot />,
      errorElement: <RouteErrorElement resetPath="/extension/" />,
      children: routes,
    },
  ])

  return isLoading ? <UiLoading /> : <RouterProvider router={router} />
}
