import type { BoxProps } from '@chakra-ui/react'
import { Box, ListItem, UnorderedList } from '@chakra-ui/react'
import { humanize, UiDate, Validation } from '@postal-io/postal-ui'
import type { IntegrationTrigger } from 'api'
import { isValid, parseISO } from 'date-fns'
import React from 'react'
import { TriggerDetailsRow } from './TriggerDetailsRow'
import { getNonTypeFilters, getPrefix, getTypeFilter, stripPrefix } from './TriggerEditFilters'

const FilterMap: any = {
  Empty: 'is empty',
  NotEmpty: 'is not empty',
  Exists: 'exists',
  DoesNotExist: 'does not exist',
  StringContains: 'contains',
  StringNotContains: 'does not contain',
  StringEquals: 'is equal to',
  StringNotEquals: 'is not equal to',
  StringStartsWith: 'starts with',
  StringEndsWith: 'ends with',
  NumberEquals: '=',
  NumberLessThan: '<',
  NumberGreaterThan: '>',
  NumberEqualLessThan: '<=',
  NumberEqualGreaterThan: '>=',
  DateEquals: 'is',
  DateIsBefore: 'is before',
  DateIsAfter: 'is after',
}

const getDateOrString = (value: string) => {
  // const isValidISOString = new Date(value).toISOString() === value
  if (Validation.isNumeric(value)) return value
  const date = parseISO(value)
  return isValid(date) ? <UiDate date={date} /> : value
}

interface TriggerDetailsFiltersProps extends BoxProps {
  trigger?: IntegrationTrigger
}
export const TriggerDetailsFilters: React.FC<TriggerDetailsFiltersProps> = ({ trigger, ...rest }) => {
  const typeFilter = getTypeFilter(trigger?.filters)
  const nonTypeFilters = getNonTypeFilters(trigger?.filters).map((f: any, idx: number) => ({
    ...f,
    idx,
  }))

  if (!trigger?.filters?.length) return null

  return (
    <Box {...rest}>
      <TriggerDetailsRow label={humanize(typeFilter.value)}>
        <UnorderedList>
          {nonTypeFilters.map((filter: any, idx: number) => {
            return (
              <ListItem key={idx}>
                {getPrefix(filter.field) === 'new' ? 'Current' : 'Previous'}{' '}
                <strong>{stripPrefix(filter.field)}</strong> {FilterMap[filter.filter]} {getDateOrString(filter.value)}
              </ListItem>
            )
          })}
        </UnorderedList>
      </TriggerDetailsRow>
    </Box>
  )
}
