import { Divider, Stack } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiFormControl,
  UiRangeDatePicker,
  ZFormLabel,
  ZSelect,
  ZSelectPopover,
  ZSelectPopoverBody,
  ZSelectPopoverContent,
  ZSelectPopoverTrigger,
} from '@postal-io/postal-ui'
import { GetAccountDocument, Granularity } from 'api'
import { endOfDay, startOfDay, startOfMonth, startOfQuarter, startOfWeek, startOfYear } from 'date-fns'
import type { ChangeEvent } from 'react'
import React, { useCallback, useMemo } from 'react'
import { DateRangeOption } from './data'

export interface DateFilterV2Props {
  startDate?: Date
  endDate?: Date
  granularity: Granularity
  showGranularity?: boolean
  dateRangeOption: DateRangeOption
}
interface DateFilterSearchV2Props extends DateFilterV2Props {
  onChange: (data: DateFilterV2Props) => void
  isFirst?: boolean
  isLast?: boolean
}
export const DateFilterSearch: React.FC<DateFilterSearchV2Props> = ({
  startDate,
  endDate,
  granularity,
  showGranularity,
  dateRangeOption,
  onChange,
  isFirst,
  isLast,
}) => {
  const account = useGraphqlQuery(GetAccountDocument)
  const created = useMemo(
    () => (account?.data?.getAccount?.created?.dateTime as Date) || startOfYear(new Date()),
    [account?.data?.getAccount?.created?.dateTime]
  )

  const handleDateRange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const dateRangeOption = e.target.value as DateRangeOption
      const endDate = endOfDay(new Date())
      switch (dateRangeOption) {
        case DateRangeOption.Today:
          onChange({ startDate: startOfDay(new Date()), endDate, granularity, dateRangeOption })
          break
        case DateRangeOption.ThisWeek:
          onChange({ startDate: startOfWeek(new Date()), endDate, granularity, dateRangeOption })
          break
        case DateRangeOption.ThisMonth:
          onChange({ startDate: startOfMonth(new Date()), endDate, granularity, dateRangeOption })
          break
        case DateRangeOption.ThisQuarter:
          onChange({ startDate: startOfQuarter(new Date()), endDate, granularity, dateRangeOption })
          break
        case DateRangeOption.ThisYear:
          onChange({ startDate: startOfYear(new Date()), endDate, granularity, dateRangeOption })
          break
        case DateRangeOption.AllTime:
          onChange({ startDate: created, endDate, granularity, dateRangeOption })
          break
        case DateRangeOption.Custom:
          onChange({ startDate, endDate, granularity, dateRangeOption: DateRangeOption.Custom })
      }
    },
    [created, granularity, onChange, startDate]
  )

  const handleCustomRange = useCallback(
    (date: Date[] = []) => {
      const [sDate, eDate] = date
      if (!sDate || !eDate) return
      onChange({
        startDate: sDate,
        endDate: eDate,
        granularity,
        dateRangeOption: DateRangeOption.Custom,
      })
    },
    [granularity, onChange]
  )

  const handleGranularity = useCallback(
    ({ target }: ChangeEvent<HTMLSelectElement>) => {
      const granularity = target.value as Granularity
      onChange({ startDate, endDate, granularity, dateRangeOption })
    },
    [dateRangeOption, endDate, onChange, startDate]
  )

  const dateRange = useMemo(() => (!!startDate && !!endDate ? [startDate, endDate] : undefined), [endDate, startDate])

  const dateRangeDescription = useMemo(() => {
    if (dateRangeOption !== DateRangeOption.Custom) return dateRangeOption
    if (!startDate || !endDate) return dateRangeOption
    return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
  }, [dateRangeOption, endDate, startDate])

  return (
    <ZSelectPopover
      placement="bottom-start"
      offset={[-1, 20]}
      autoFocus
    >
      <ZSelectPopoverTrigger
        isFirst={isFirst}
        isLast={isLast}
        color="atomicGray.500"
        fontWeight="normal"
      >
        {dateRangeDescription}
      </ZSelectPopoverTrigger>
      <ZSelectPopoverContent
        bg="white"
        data-testid="DateFilterSearch_popover"
      >
        <ZSelectPopoverBody
          p={4}
          overflow="visible"
        >
          <Stack
            spacing={4}
            divider={<Divider />}
          >
            <UiFormControl id="dateRangeOption">
              <ZFormLabel fontSize="md">Date Range</ZFormLabel>
              <ZSelect
                value={dateRangeOption}
                onChange={handleDateRange}
              >
                <option value={DateRangeOption.AllTime}>All Time</option>
                <option value={DateRangeOption.Today}>Today</option>
                <option value={DateRangeOption.ThisWeek}>This Week</option>
                <option value={DateRangeOption.ThisMonth}>This Month</option>
                <option value={DateRangeOption.ThisQuarter}>This Quarter</option>
                <option value={DateRangeOption.ThisYear}>This Year</option>
                <option value={DateRangeOption.Custom}>Custom Date Range</option>
              </ZSelect>
            </UiFormControl>
            {dateRangeOption === DateRangeOption.Custom && (
              <UiFormControl id="customDateRange">
                <ZFormLabel fontSize="md ">Custom Date Range</ZFormLabel>
                <UiRangeDatePicker
                  value={dateRange}
                  onChange={handleCustomRange}
                  color="atomicGray.500"
                  fontFamily="Lexend"
                  fontWeight="normal"
                  isRequired
                />
              </UiFormControl>
            )}
            {showGranularity && (
              <UiFormControl id="frequency">
                <ZFormLabel fontSize="md">Frequency</ZFormLabel>
                <ZSelect
                  size="md"
                  onChange={handleGranularity}
                  value={granularity}
                >
                  <option value={Granularity.All}>All Time</option>
                  <option value={Granularity.Daily}>Daily</option>
                  <option value={Granularity.Weekly}>Weekly</option>
                  <option value={Granularity.Monthly}>Monthly</option>
                  <option value={Granularity.Yearly}>Yearly</option>
                </ZSelect>
              </UiFormControl>
            )}
          </Stack>
        </ZSelectPopoverBody>
      </ZSelectPopoverContent>
    </ZSelectPopover>
  )
}
