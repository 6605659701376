import { CalendarIcon, ChatIcon } from '@chakra-ui/icons'
import { Stack, Text } from '@chakra-ui/react'
import { UiAlert, UiButton } from '@postal-io/postal-ui'
import { ProductRequestButton } from 'components/Postal/ProductRequestButton'
import { useAcl, useHelp } from 'hooks'
import React, { useMemo } from 'react'

export enum EventsDisabledReason {
  NoPermission = 'NoPermission',
  TrialSubscription = 'TrialSubscription',
  NoCredits = 'NoCredits',
  InvalidDateSelected = 'InvalidDateSelected',
}

interface EventDisabledProps {
  reason: EventsDisabledReason | null
  setSelectedDate: (selectedDate: undefined) => void
  eventLimit?: number
  eventLimitUsed?: number
  productId: string
}

export const EventDisabled: React.FC<EventDisabledProps> = ({
  reason,
  setSelectedDate,
  eventLimit = 0,
  eventLimitUsed = 0,
  productId,
}) => {
  const { hasPermission } = useAcl()
  const canCreate = hasPermission('events.create')
  // const { isOpen, onOpen, onClose } = useDisclosure()

  const { sendMessage } = useHelp()

  const eventsLeft = useMemo(() => Math.max(eventLimit - eventLimitUsed, 0), [eventLimit, eventLimitUsed])

  return (
    <>
      <Stack
        spacing={12}
        textAlign="center"
        mt={4}
      >
        {canCreate && reason !== EventsDisabledReason.TrialSubscription && (
          <UiAlert
            status="info"
            hideClose
            mb={4}
          >
            You have {eventsLeft} out of {eventLimit} events remaining
          </UiAlert>
        )}
        {reason === EventsDisabledReason.TrialSubscription ? (
          <Text
            fontSize="xl"
            fontWeight="semibold"
            color="gray.700"
            textAlign="center"
            mb={5}
          >
            Events aren’t available in-trial.
            <br />
            Contact Sales to sign-up to gain access.
          </Text>
        ) : reason === EventsDisabledReason.NoCredits ? (
          <Text
            fontSize="lg"
            fontWeight="semibold"
            color="gray.700"
            textAlign="center"
            mb={5}
          >
            No more events are available. Please contact Sales to add credits.
          </Text>
        ) : reason === EventsDisabledReason.InvalidDateSelected ? (
          <Text
            fontSize="lg"
            fontWeight="semibold"
            color="gray.700"
            textAlign="center"
            mb={5}
          >
            This date is not available. Please select a different one.
          </Text>
        ) : reason === EventsDisabledReason.NoPermission ? (
          <Text
            fontSize="lg"
            fontWeight="semibold"
            color="gray.700"
            textAlign="center"
            mb={5}
          >
            This event hasn't been scheduled yet for your team. Please submit a request to your Admin for approval.
          </Text>
        ) : null}
        {reason === EventsDisabledReason.TrialSubscription ? (
          <>
            <UiButton
              alignSelf="center"
              size="lg"
              colorScheme="primary"
              leftIcon={<ChatIcon />}
              onClick={() => sendMessage(`I'd like to sign up for events.`)}
            >
              Contact Sales
            </UiButton>
          </>
        ) : reason === EventsDisabledReason.NoCredits ? (
          <UiButton
            alignSelf="center"
            size="lg"
            colorScheme="primary"
            leftIcon={<ChatIcon />}
            onClick={() => sendMessage(`I'd like to sign up for events.`)}
          >
            Contact Sales
          </UiButton>
        ) : reason === EventsDisabledReason.InvalidDateSelected ? (
          <UiButton
            alignSelf="center"
            size="lg"
            colorScheme="primary"
            leftIcon={<CalendarIcon />}
            onClick={() => setSelectedDate(undefined)}
          >
            Select Another Date
          </UiButton>
        ) : (
          reason === EventsDisabledReason.NoPermission && (
            <ProductRequestButton
              alignSelf="center"
              productId={productId}
              productType="Event"
            />
          )
        )}
      </Stack>
      {/* <UpgradeAccount isOpen={isOpen} onClose={onClose} /> */}
    </>
  )
}
