import { Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiButtonProps, UiCardProps } from '@postal-io/postal-ui'
import {
  useAlerts,
  ZButton,
  ZCard,
  ZCardHeader,
  ZHeading,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import React, { useMemo } from 'react'
import { DeleteUserAuthDocument, VerifyEmailConnectionDocument } from '../../api'
import { useEmailIntegrations } from '../../hooks'
import type { ExternalProvider } from '../Integrations'

export const UserEmailIntegrations: React.FC<UiCardProps> = (props) => {
  const { availableEmailProviders, isLoading } = useEmailIntegrations()

  if (!availableEmailProviders?.length) return null

  return (
    <>
      <ZCard
        isLoading={isLoading}
        variant="form"
        h="100%"
        {...props}
        position="relative"
      >
        <ZCardHeader
          p={8}
          justifyContent="flex-start"
          gap={2}
        >
          <ZHeading size="h6">Email Integration</ZHeading>
          <ZInfoTooltip label="When Email Integration is enabled, all Gift Emails can be sent directly from your email account through your email provider" />
        </ZCardHeader>

        <Stack
          spacing={8}
          px={8}
          pb={8}
          isInline
        >
          {availableEmailProviders.map((provider) => (
            <UserEmailIntegrationV2
              provider={provider}
              key={provider.system}
            />
          ))}
        </Stack>
      </ZCard>
    </>
  )
}

interface UserEmailIntegrationV2Props extends Omit<UiButtonProps, 'children'> {
  provider: ExternalProvider
}
enum UserEmailIntegrationStatus {
  Active = 'Active',
  Reconnect = 'Reconnect',
  Disabled = 'Disabled',
}
export const UserEmailIntegrationV2: React.FC<UserEmailIntegrationV2Props> = ({ provider, ...rest }) => {
  const integrations = useEmailIntegrations()
  const verifyQuery = useGraphqlQuery(
    VerifyEmailConnectionDocument,
    { ssoMethodName: provider.email?.authType || '' },
    { enabled: integrations.isEnabled(provider) && !!provider?.email?.authType }
  )

  const removeIntegration = useDisclosure()
  const Alert = useAlerts()

  const isLoading = verifyQuery.isLoading || integrations.isLoading

  const disableEmail = useGraphqlMutation(DeleteUserAuthDocument)

  const status = useMemo(() => {
    if (!integrations.isEnabled(provider) || verifyQuery.isLoading) {
      return UserEmailIntegrationStatus.Disabled
    }
    return verifyQuery.data?.verifyEmailConnection
      ? UserEmailIntegrationStatus.Active
      : UserEmailIntegrationStatus.Reconnect
  }, [integrations, provider, verifyQuery.data?.verifyEmailConnection, verifyQuery.isLoading])

  const [colorScheme, buttonText] = useMemo(() => {
    switch (status) {
      case UserEmailIntegrationStatus.Active:
        return ['atomicBlue', 'Active']
      case UserEmailIntegrationStatus.Disabled:
        return ['atomicGray', 'Disabled']
      case UserEmailIntegrationStatus.Reconnect:
        return ['atomicYellow', 'Reconnect']
      default:
        return ['atomicGray', 'Loading...']
    }
  }, [status])

  const handleAuthRemoval = async (provider: ExternalProvider) => {
    try {
      if (provider.email?.authType) {
        disableEmail.mutateAsync({ authMethod: provider.email?.authType })
      }
      Alert.info(`${provider.name} Email integration disabled.`)
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleRemove = () => {
    removeIntegration.onClose()
    handleAuthRemoval(provider)
  }

  const handleReconnect = () => {
    removeIntegration.onClose()
    integrations.enableIntegration(provider)
  }

  const handleClick = () => {
    if (integrations.isEnabled(provider)) {
      removeIntegration.onOpen()
    } else {
      handleReconnect()
    }
  }

  return (
    <>
      <ZButton
        data-testid={`UserEmailIntegration_button_${provider.name}`}
        maxW="250px"
        w="100%"
        colorScheme={colorScheme}
        leftIcon={<provider.icon />}
        size="md"
        variant="subtle"
        borderWidth="1px"
        borderColor="atomicGray.300"
        justifyContent="flex-start"
        onClick={handleClick}
        isDisabled={isLoading}
        isLoading={isLoading}
        title={provider.name}
        {...rest}
      >
        <ZText color="inherit">{buttonText}</ZText>
      </ZButton>

      <ZModal
        size="4xl"
        isOpen={removeIntegration.isOpen}
        onClose={removeIntegration.onClose}
      >
        <ZModalOverlay />
        <ZModalContent>
          <ZModalHeader>{provider.name} Integration</ZModalHeader>
          <ZModalCloseButton />
          <ZModalBody
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <ZText fontSize="md">
              Would you like to
              <ZLink
                onClick={handleReconnect}
                colorScheme="atomicBlue"
                mx={1}
                fontSize="md"
              >
                reconnect
              </ZLink>
              or
              <ZLink
                onClick={handleRemove}
                colorScheme="atomicBlue"
                mx={1}
                fontSize="md"
              >
                disable
              </ZLink>
              this integration?
            </ZText>
          </ZModalBody>
          <ZModalFooter justifyContent="flex-end">
            <ZButton
              variant="ghost"
              colorScheme="atomicGray"
              onClick={removeIntegration.onClose}
              minW="140px"
            >
              Close
            </ZButton>
          </ZModalFooter>
        </ZModalContent>
      </ZModal>
    </>
  )
}
