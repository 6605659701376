import { Flex, Grid, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlMutation } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import {
  internalProgressBarProps,
  internalTableProps,
  SelectTypeaheadStylesV2,
  UiSSDataTable,
  useAlertError,
  useAlerts,
  ZCard,
  ZCardButton,
  ZCardButtons,
  ZCardHeader,
  ZCardTitle,
  ZConfirm,
  ZSelect,
  ZText,
} from '@postal-io/postal-ui'
import { capitalize } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { MdOutlineAdd, MdOutlineDelete } from 'react-icons/md'
import type { PlaybookDefinition, PlaybookInstance as PlaybookInstanceFragment, SearchableContact } from '../../api'
import { BulkPlaybookStatusUpdateDocument, PlaybookStatus, SearchPlaybookInstancesDocument, Status } from '../../api'
import { CONTACT_INVALIDATIONS, useAcl, useBackgroundQueue } from '../../hooks'
import { AutoCompleteContact } from '../AutoComplete'
import { ACTIVE_STATUS, columns, COMPLETED_STATUS } from './data'
import { PlaybookInstance } from './PlaybookInstance'
import { PlaybookInstancesCreate } from './PlaybookInstancesCreate'

const PAGE_SIZE = 10
const INSTANCES_LIMIT = 100

interface PlaybookInstancesV2Props extends ZCardProps {
  playbook?: PlaybookDefinition
}
export const PlaybookInstances: React.FC<PlaybookInstancesV2Props> = ({ playbook, ...rest }) => {
  const { hasPermission } = useAcl()
  const canSendPlaybook = hasPermission('postals.send')
  const canAddContacts = canSendPlaybook && playbook?.status === Status.Active
  const createInstances = useDisclosure()
  const cancelInstances = useDisclosure()
  const showInstance = useDisclosure()
  const Alert = useAlerts()

  const [selectedInstance, setSelectedInstance] = useState<PlaybookInstanceFragment>()
  const [selectedContact, setSelectedContact] = useState<SearchableContact | undefined | null>()
  const [statusFilter, setStatusFilter] = useState<string>('')

  const handleStatusFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    setStatusFilter(value)
  }

  const variables = useMemo(() => {
    const data = {
      filter: { playbookDefinitionId: { eq: playbook?.id } },
      limit: INSTANCES_LIMIT,
    } as any
    if (selectedContact) data.filter.contactId = { eq: selectedContact.id }
    if (statusFilter) data.filter.playbookStatus = { eq: statusFilter }
    return data
  }, [playbook?.id, selectedContact, statusFilter])

  const { mergedData, isFetching, refetch, hasNextPage, fetchNextPage, error } = useGraphqlInfiniteQuery(
    SearchPlaybookInstancesDocument,
    variables
  )

  const { queue, invalidate } = useBackgroundQueue()
  const updateInstanceStatus = useGraphqlMutation(BulkPlaybookStatusUpdateDocument, {
    onSuccess: (data) => {
      queue(data?.bulkPlaybookStatusUpdate)
      invalidate(CONTACT_INVALIDATIONS)
    },
  })

  const handleChangeInstance = useCallback(
    async (id: string, playbookStatus: PlaybookStatus) => {
      try {
        await updateInstanceStatus.mutateAsync({ playbookStatus, ids: [id] })
        Alert.success(`Subscription instance updated`)
      } catch (err) {
        Alert.error(err)
      }
    },
    [Alert, updateInstanceStatus]
  )

  const handleCancelInstances = async () => {
    try {
      await updateInstanceStatus.mutateAsync({
        playbookStatus: PlaybookStatus.Cancelled,
        filter: {
          playbookDefinitionId: { eq: playbook?.id },
          playbookStatus: { in: ACTIVE_STATUS },
        },
      })
      cancelInstances.onClose()
      Alert.warning('All instances will be cancelled in the background.')
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleClick = useCallback(
    (instance: PlaybookInstanceFragment) => {
      setSelectedInstance(instance)
      showInstance.onOpen()
    },
    [showInstance]
  )

  const instances = useMemo(() => {
    return (
      mergedData?.searchPlaybookInstances?.map((instance) => {
        return {
          ...instance,
          onClick: () => handleClick(instance),
          actions: [
            {
              title: `Cancel Subscription`,
              onClick: () => handleChangeInstance(instance.id, PlaybookStatus.Cancelled),
              isHidden: !canSendPlaybook || COMPLETED_STATUS.includes(instance.playbookStatus),
            },
            {
              title: `Activate Subscription`,
              onClick: () => handleChangeInstance(instance.id, PlaybookStatus.Active),
              isHidden: !canSendPlaybook || instance.playbookStatus !== PlaybookStatus.Cancelled,
            },
          ],
        }
      }) || []
    )
  }, [canSendPlaybook, handleChangeInstance, handleClick, mergedData?.searchPlaybookInstances])

  useAlertError(error)

  return (
    <>
      <ZCard
        variant="dash"
        borderWidth="1px"
        borderColor="atomicGray.200"
        {...rest}
      >
        <ZCardHeader>
          <ZCardTitle>Contacts</ZCardTitle>
          <ZCardButtons>
            {canAddContacts && (
              <ZCardButton
                aria-label="Add contact"
                icon={<MdOutlineAdd />}
                onClick={createInstances.onOpen}
                color="gray.600"
              />
            )}
            {canSendPlaybook && (
              <ZCardButton
                aria-label="Cancel all button"
                icon={<MdOutlineDelete />}
                onClick={cancelInstances.onOpen}
                color="gray.600"
              />
            )}
          </ZCardButtons>
        </ZCardHeader>
        <Flex
          direction="column"
          maxW="100%"
        >
          <Grid
            templateColumns="300px 300px"
            gap={4}
          >
            <AutoCompleteContact
              onChange={(contact) => setSelectedContact(contact)}
              {...SelectTypeaheadStylesV2}
            />
            <ZSelect
              name="status"
              id="status"
              value={statusFilter}
              onChange={handleStatusFilter}
            >
              <option value="">Select a status</option>
              {Object.values(PlaybookStatus).map((status, idx) => (
                <option
                  key={idx}
                  value={status}
                >
                  {capitalize(status)}
                </option>
              ))}
            </ZSelect>
          </Grid>
          <UiSSDataTable
            variant="list"
            isLoading={isFetching}
            mt={4}
            columns={columns}
            rows={instances}
            rowKey="id"
            pageSize={PAGE_SIZE}
            filter={variables.filter}
            hasMore={hasNextPage}
            fetchMore={fetchNextPage}
            tableProps={internalTableProps}
            progressBarProps={internalProgressBarProps}
          />
        </Flex>
      </ZCard>
      {createInstances.isOpen && (
        <PlaybookInstancesCreate
          playbook={playbook}
          onComplete={refetch}
          {...createInstances}
        />
      )}
      {showInstance.isOpen && selectedInstance && (
        <PlaybookInstance
          instance={selectedInstance}
          {...showInstance}
        />
      )}
      <ZConfirm
        title="Remove all Instances"
        isOpen={cancelInstances.isOpen}
        onConfirm={handleCancelInstances}
        onClose={cancelInstances.onClose}
        buttonColor="red"
      >
        <ZText textAlign="justify">
          You may remove all the active instances in this subscription at once. If you choose to do this, no more items
          will be sent out for any of the contacts.
        </ZText>
        <ZText
          textAlign="justify"
          mt={4}
        >
          You may re-activate them one at a time after the fact.
        </ZText>
        <ZText
          textAlign="justify"
          mt={4}
        >
          Are you sure you want to <strong>Remove All Instances</strong> of this subscription?
        </ZText>
      </ZConfirm>
    </>
  )
}
