import { Box } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import { useAlertError, ZCard, ZCardHeader, ZText } from '@postal-io/postal-ui'
import { formatLongNumber } from 'lib'
import React, { useMemo } from 'react'
import { CrmReportType, GetCrmReportDocument } from '../../../api'

interface ClosedWonV2Props extends ZCardProps {
  refresh: boolean
  system: string
}
export const ClosedWon: React.FC<ClosedWonV2Props> = ({ refresh, system, ...rest }) => {
  const query = useGraphqlQuery(
    GetCrmReportDocument,
    {
      system,
      reportType: CrmReportType.ClosedWonDeals,
      refresh,
    },
    { enabled: !!system, staleTime: 1000 * 60 }
  )
  useAlertError(query.error)

  const data = useMemo(() => {
    const record = query.data?.getCrmReport?.queryResults?.find((f) => f?.label === 'opportunity_data')?.result?.records
    return (record?.[0]?.count_opportunity ?? 0) as number
  }, [query.data?.getCrmReport])

  return (
    <ZCard
      display="flex"
      flexDirection="column"
      variant="form"
      {...rest}
    >
      <ZCardHeader p={4}>Closed Won Deals Influenced by Postal</ZCardHeader>
      <Box p={4}>
        <ZText
          fontSize={{ base: '6vw', lg: '3vw' }}
          color="atomicBlue.400"
          fontWeight="normal"
          textAlign="center"
        >
          {formatLongNumber(data)}
        </ZText>
      </Box>
    </ZCard>
  )
}
