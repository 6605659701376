// Taken from extension
export enum BroadcastKeys {
  AuthResponse = 'postal:authResponse',
  AuthLogout = 'postal:authLogout',
}

// Mock for browsers that don't support Broadcast Channel
class BroadcastChannelMock {
  name = 'Mock'
  onmessage = () => {}
  postMessage() {}
  close() {}
}

const Channel = typeof window.BroadcastChannel === 'undefined' ? BroadcastChannelMock : window.BroadcastChannel

export const authResponseChannel = new Channel(BroadcastKeys.AuthResponse)
export const authLogoutChannel = new Channel(BroadcastKeys.AuthLogout)
