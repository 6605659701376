import React, { forwardRef } from 'react'
import { Rect } from 'react-konva'

export const FOLD_LINE_COLOR = '#000000'
export const FOLD_LINE_WIDTH = 1
export const DASH = [6, 6]

export interface FoldLineProps {
  element: any
}

export const FoldLine: React.FC<FoldLineProps> = forwardRef(({ element, ...rest }, ref: any) => {
  const { x, y, width, height } = element?.location || {}
  return (
    <Rect
      ref={ref}
      x={x}
      y={y}
      width={width}
      height={height}
      stroke={FOLD_LINE_COLOR}
      strokeWidth={FOLD_LINE_WIDTH}
      dash={DASH}
      {...rest}
    />
  )

  /*
  For some reason the first line is not showing on these templates.
  but it seems to work with a rect?

  const points = [x, y, x + width, y + height]
  return (
    <Line
      ref={ref}
      points={points}
      stroke={lineColor}
      strokeWidth={lineWidth}
      {...rest}
    />
  )
  */
})
