import { Box, Collapse, Fade, Flex, HStack, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiMenu,
  UiMenuButton,
  UiMenuItem,
  UiMenuList,
  UiTruncate,
  useColor,
  ZButton,
  ZCard,
  ZCardBody,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import type { Currency } from 'api'
import {
  BudgetMode,
  GetBudgetRemainingDocument,
  GetFundsDocument,
  GetTeamByIdDocument,
  GetUserDocument,
  Role,
} from 'api'
import type { PostalSendContext, PostalSendEventV2 } from 'components/PostalSend/usePostalSend'
import { getSendMethodColor } from 'components/PostalSend/usePostalSend'
import { useAcl, useExtension, useSession, useTeamOrAccountSettings } from 'hooks'
import React, { useCallback, useEffect, useMemo } from 'react'
import {
  MdCheckCircle,
  MdOutlineArrowDropDown,
  MdOutlineFileDownload,
  MdOutlineSavings,
  MdOutlineSwitchLeft,
} from 'react-icons/md'

interface PostalSendSidebarSpendAsProps {
  context?: PostalSendContext
  subtotal?: number
  send: (evt: PostalSendEventV2) => void
  hasFunds?: boolean | null
  handleSaveDraft?: () => void
}
export const PostalSendSidebarSpendAs: React.FC<PostalSendSidebarSpendAsProps> = ({
  context,
  subtotal,
  send,
  hasFunds,
  handleSaveDraft,
}) => {
  const { Color } = useColor()
  const { hasFeature } = useAcl()
  const canSpendAs = hasFeature('budgetDropdown')
  const disclosure = useDisclosure()

  const { isExtension } = useExtension()

  const { spendAsTeamBalance, spendAsTeamBudget, spendAsTeamBudgetMode, spendAsTeamName } = context ?? {}

  const insufficientBudget = useMemo(() => {
    // if no spendAsTeamName, we haven't initialized the spendas details yet
    if (!spendAsTeamName) return false
    if (spendAsTeamBudgetMode === BudgetMode.Unlimited) return false
    return (spendAsTeamBudget ?? 0) < (subtotal ?? 0)
  }, [spendAsTeamBudget, spendAsTeamBudgetMode, spendAsTeamName, subtotal])

  const insufficientBalance = useMemo(() => {
    // only show insufficient balance if budget is sufficient (otherwise insufficient budget takes precedence)
    if (insufficientBudget) return false
    if (!spendAsTeamName) return false
    if (hasFunds !== undefined) return !hasFunds
    return (spendAsTeamBalance ?? 0) < (subtotal ?? 0)
  }, [spendAsTeamName, insufficientBudget, hasFunds, spendAsTeamBalance, subtotal])

  const fundsDisplayValue = useMemo(() => {
    if (insufficientBalance) return spendAsTeamBalance
    return spendAsTeamBudgetMode === BudgetMode.Unlimited ? spendAsTeamBalance : spendAsTeamBudget
  }, [insufficientBalance, spendAsTeamBalance, spendAsTeamBudgetMode, spendAsTeamBudget])

  const insufficientFunds = insufficientBalance || insufficientBudget
  const isInteractive = canSpendAs && !insufficientFunds

  if (!context) return null
  return (
    <>
      <UiMenu
        {...disclosure}
        matchWidth
      >
        <UiMenuButton
          as={ZButton}
          h="40px"
          pr={4}
          pl={4}
          width="100%"
          fontSize="sm"
          variant="outline"
          position="relative"
          pointerEvents={isInteractive ? 'all' : 'none'}
          color="atomicGray.800"
          borderColor="atomicGray.200"
          _hover={{ bg: 'white', borderColor: 'atomicGray.400' }}
          bg="white"
          borderBottomLeftRadius={insufficientFunds ? '0' : ''}
          borderBottomRightRadius={insufficientFunds ? '0' : ''}
          leftIcon={
            <MdOutlineSavings
              viewBox="2 2 20 20"
              fontSize="16px"
            />
          }
          rightIcon={
            isInteractive ? (
              <MdOutlineArrowDropDown
                viewBox="2 2 20 20"
                style={{
                  color: Color('atomicGray.500'),
                  transform: disclosure.isOpen ? 'rotate(-180deg)' : '',
                  transition: 'transform 0.2s',
                }}
              />
            ) : undefined
          }
        >
          <Flex
            w="100%"
            justifyContent="space-between"
          >
            <ZText fontWeight={500}>{context.spendAsTeamName}</ZText>
            <ZText color="atomicYellow.800">
              {insufficientBalance && context.spendAsTeamBudgetMode !== BudgetMode.Unlimited && 'Balance '}
              <ZMoney
                fontWeight={insufficientFunds ? 600 : 500}
                cents={fundsDisplayValue}
                currency={context.postal?.currency}
                color={insufficientFunds ? 'atomicYellow.800' : 'atomicGray.500'}
              />
            </ZText>
          </Flex>
          <Fade in={disclosure.isOpen}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              pl={5}
              display="flex"
              alignItems="center"
              bg="white"
              _hover={{ bg: 'atomicGray.50' }}
            >
              <ZText fontWeight={500}>Select a team to Spend As</ZText>
            </Box>
          </Fade>
        </UiMenuButton>
        <UiMenuList boxShadow="0 0 15px rgba(0,0,0,.1)">
          <PostalSpendAsDropdown
            context={context}
            send={send}
            subtotal={subtotal ?? 0}
            onClose={disclosure.onClose}
          />
        </UiMenuList>
      </UiMenu>
      <Collapse
        in={insufficientFunds}
        unmountOnExit
      >
        <ZCard
          m={0}
          p="20px !important"
          variant="form"
          borderRadius="0 0 3px 3px"
          borderTop="none"
          background="atomicYellow.10"
        >
          <ZCardBody p={5}>
            <ZText
              fontWeight={600}
              color="atomicYellow.800"
            >
              <strong>Insufficient {insufficientBalance ? 'Balance' : 'Budget'}!</strong> Choose a way forward:
              <ZButton
                width="100%"
                mt={2}
                variant="outline"
                borderColor="atomicYellow.300"
                bg="white"
                color="atomicGray.600"
                textAlign="left"
                onClick={disclosure.onOpen}
                leftIcon={
                  <MdOutlineSwitchLeft
                    size="18px"
                    color={Color('atomicYellow.600')}
                  />
                }
              >
                <ZText width="100%">Switch Teams</ZText>
              </ZButton>
              {/* HIDE FOR EXT - MORE TESTING */}
              {!isExtension && handleSaveDraft && (
                <ZButton
                  width="100%"
                  mt={2}
                  variant="outline"
                  borderColor="atomicYellow.300"
                  bg="white"
                  color="atomicGray.600"
                  textAlign="left"
                  onClick={handleSaveDraft}
                  leftIcon={
                    <MdOutlineFileDownload
                      size="18px"
                      color={Color('atomicYellow.600')}
                    />
                  }
                >
                  <ZText width="100%">Save as Draft</ZText>
                </ZButton>
              )}
            </ZText>
          </ZCardBody>
        </ZCard>
      </Collapse>
    </>
  )
}

interface PostalSpendAsDropdownProps {
  context: PostalSendContext
  send: (evt: PostalSendEventV2) => void
  onClose?: () => void
  subtotal: number
}
export const PostalSpendAsDropdown: React.FC<PostalSpendAsDropdownProps> = ({ context, send, onClose, subtotal }) => {
  const { hasRole, hasFeature } = useAcl()
  const { session } = useSession()
  const canSpendAs = hasFeature('budgetDropDown')
  const isAdmin = hasRole(Role.Admin)

  const PRODUCT_ID = process.env.REACT_APP_PRODUCT_ID

  const selectedUserQuery = useGraphqlQuery(
    GetUserDocument,
    { id: context.spendAsUserId as string },
    { enabled: !!context.spendAsUserId }
  )
  const selectedUser = useMemo(() => selectedUserQuery.data?.getUser, [selectedUserQuery.data?.getUser])

  // only user accounts can send
  const selectedUserTeams = useMemo(() => {
    return (
      selectedUser?.productAccess?.filter((account) => {
        return account.product === PRODUCT_ID && account.roles?.includes(Role.User)
      }) || []
    )
  }, [PRODUCT_ID, selectedUser?.productAccess])

  const onSelect = (data: any) => {
    send({ type: 'SET_SPEND_AS_TEAM_ID', data })
    setTimeout(() => onClose?.(), 300)
  }

  /*
    If a non-admin gets to this page, we want to preset the userId and teamId that
    they will send as.  This is so we know to show the teamId list which would allow
    them to pick a different team.
  */
  useEffect(() => {
    if (canSpendAs && !context.spendAsUserId) {
      send({ type: 'SET_SPEND_AS_USER_ID', data: { userId: session.userId } })
      send({ type: 'SET_SPEND_AS_TEAM_ID', data: { teamId: session.teamId || null } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {selectedUserTeams.map((product) => (
        <SidebarSelectTeamRow
          selected={product?.teamId === context?.spendAsTeamId}
          onSelect={onSelect}
          key={product?.id}
          accountId={session?.accountId}
          accountName={session?.accountName}
          teamId={product?.teamId as string}
          userId={selectedUser?.id as string}
          isAdmin={isAdmin}
          subtotal={subtotal}
          context={context}
          currency={context.postal?.currency}
        />
      ))}
      {!selectedUserTeams.length && <ZText px={4}>No teams available</ZText>}
    </>
  )
}

export interface SidebarSelectTeamRowProps {
  accountId: string
  accountName?: string
  teamId: string | null
  userId: string
  isAdmin: boolean
  selected: boolean
  onSelect: (data: any) => void
  context: PostalSendContext
  subtotal: number
  currency?: Currency | null
}
export const SidebarSelectTeamRow: React.FC<SidebarSelectTeamRowProps> = ({
  accountId,
  accountName,
  teamId,
  userId,
  isAdmin,
  selected,
  context,
  onSelect,
  subtotal,
  currency,
}) => {
  const { isLoading: budgetLoading, data: budget } = useGraphqlQuery(GetBudgetRemainingDocument, {
    accountId,
    teamId,
    userId,
  })
  const { isLoading: teamLoading, data: team } = useGraphqlQuery(
    GetTeamByIdDocument,
    { id: teamId as string },
    { enabled: !!teamId }
  )

  // get the balance
  const settings = useTeamOrAccountSettings({ teamId })
  const billingAccountId = useMemo(
    () => settings?.billingAccountIds?.find((acct) => acct.currency === currency)?.billingAccountId,
    [currency, settings?.billingAccountIds]
  )
  const { isLoading: fundsLoading, data: getFunds } = useGraphqlQuery(
    GetFundsDocument,
    { billingAccountId: billingAccountId as string },
    { enabled: !!billingAccountId }
  )

  const amt = budget?.getBudgetRemaining?.budgetRemaining ?? 0
  const budgetMode = budget?.getBudgetRemaining?.applicableBudget?.budget?.mode

  const onHandleSelectTeam = useCallback(() => {
    onSelect({
      teamId,
      teamName: team?.getTeamById?.name || accountName,
      teamBudget: amt,
      teamBudgetMode: budgetMode,
      teamBalance: getFunds?.getFunds ?? 0,
    })
  }, [accountName, amt, budgetMode, getFunds, onSelect, team?.getTeamById?.name, teamId])

  // populate any spend as data on load if it's missing (replaces code beneath it)
  useEffect(() => {
    if (budgetLoading || teamLoading || fundsLoading) return
    // in case spendAsTeamId is undefined and teamId is null
    const teamIdMatch = context.spendAsTeamId === teamId || (!context.spendAsTeamId && !teamId)
    if (teamIdMatch && !context.spendAsTeamName) onHandleSelectTeam()
  }, [budgetLoading, teamLoading, fundsLoading, context, teamId, onHandleSelectTeam])

  const teamBalance = getFunds?.getFunds ?? 0
  const hasInsifficientBudget = budgetMode !== BudgetMode.Unlimited && amt < subtotal
  const hasInsufficientBalance = isAdmin && teamBalance < subtotal

  return (
    <UiMenuItem
      onClick={onHandleSelectTeam}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={1}
      px={3}
      _hover={{ bg: 'atomicGray.10' }}
      cursor="pointer"
      borderRadius={3}
    >
      <SidebarTeamRow
        brandingColor={getSendMethodColor(context)}
        selected={selected}
        name={team?.getTeamById?.name || accountName || ''}
        budget={amt}
        budgetMode={budgetMode}
        balance={teamBalance}
        currency={currency}
        insufficientBalance={hasInsufficientBalance}
        insufficientBudget={hasInsifficientBudget}
        isAdmin={isAdmin}
      />
    </UiMenuItem>
  )
}

interface SidebarTeamRowProps {
  selected: boolean
  brandingColor: string
  insufficientBudget: boolean
  insufficientBalance?: boolean
  name: string
  budget?: number
  balance?: number
  currency?: Currency | null
  isAdmin: boolean
  budgetMode?: BudgetMode
}
export const SidebarTeamRow: React.FC<SidebarTeamRowProps> = ({
  brandingColor,
  selected,
  name,
  budget,
  balance,
  currency,
  insufficientBalance,
  insufficientBudget,
  isAdmin,
  budgetMode,
}) => {
  const { Color } = useColor()
  const balanceColor = useMemo(() => {
    if (insufficientBalance) return 'atomicRed.600'
    if (insufficientBudget) return 'atomicRed.600'
    return 'atomicGray.600'
  }, [insufficientBalance, insufficientBudget])
  return (
    <>
      <HStack width="200px">
        <UiTruncate
          length={selected ? 23 : 27}
          text={name}
          showTooltip
          fontSize="sm"
          fontWeight={selected ? 'bold' : 'normal'}
          color={insufficientBalance || insufficientBudget ? 'atomicYellow.700' : 'default'}
          whiteSpace="nowrap"
          fontFamily="Lexend"
          display="flex"
          alignItems="center"
        />
        {selected && (
          <MdCheckCircle style={{ marginLeft: '5px', display: 'inline-block', fill: Color(brandingColor) }} />
        )}
      </HStack>

      <SimpleGrid
        columns={2}
        width="130px"
        spacingY={0.5}
      >
        <Box>
          <ZText
            fontSize="xs"
            color="atomicGray.500"
          >
            Budget
          </ZText>
        </Box>
        <Box>
          <ZText
            fontSize="xs"
            color="gray.600"
            textAlign="right"
            whiteSpace="nowrap"
          >
            {budgetMode !== BudgetMode.Unlimited ? (
              <ZMoney
                fontSize="xs"
                fontWeight={selected ? 'bold' : 'normal'}
                amount={(budget ?? 0) / 100}
                color={balanceColor}
                currency={currency}
              />
            ) : (
              'Disabled'
            )}
          </ZText>
        </Box>

        {(isAdmin || (!isAdmin && budgetMode === BudgetMode.Unlimited)) && (
          <>
            <Box>
              <ZText
                fontSize="xs"
                color="atomicGray.500"
                pr={2}
              >
                Balance
              </ZText>
            </Box>
            <Box>
              <ZText
                textAlign="right"
                color={insufficientBalance ? 'red.500' : 'gray.600'}
                whiteSpace="nowrap"
              >
                <ZMoney
                  fontSize="xs"
                  fontWeight={selected ? 'bold' : 'normal'}
                  amount={(balance ?? 0) / 100}
                  color={balanceColor}
                  currency={currency}
                />
              </ZText>
            </Box>
          </>
        )}
      </SimpleGrid>
    </>
  )
}
