import { CopyIcon } from '@chakra-ui/icons'
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import {
  humanize,
  UiDate,
  UiDateTime,
  UiDescriptionList,
  UiDescriptionListItem,
  ZButton,
  ZCard,
  ZCardHeader,
} from '@postal-io/postal-ui'
import type { MagicLink } from 'api'
import { GetAccountDocument, GetTeamByIdDocument, GetUserDocument, MagicLinkStatus } from 'api'
import { SendAsLink, useAcl, useCopyMagicLink, useMe, useSendAs } from 'hooks'
import { QRCodeCanvas } from 'qrcode.react'
import React, { useMemo } from 'react'
import { MdOutlineBlock, MdOutlineCheckCircleOutline } from 'react-icons/md'
import { LinkPreview } from './LinkPreview'

interface LinkOrderV2Props extends ZCardProps {
  link?: MagicLink
  isInModal?: boolean
}

export const LinkDetails: React.FC<LinkOrderV2Props> = ({ link, isInModal, ...rest }) => {
  const me = useMe()
  const copyLink = useCopyMagicLink()
  const { hasFeature } = useAcl()
  const canSpendAs = hasFeature('budgetDropdown')
  const hasQrDesign = hasFeature('qrCodeDesign')
  const getTeam = useGraphqlQuery(GetTeamByIdDocument, { id: link?.teamId as string }, { enabled: !!link?.teamId })
  const getAccount = useGraphqlQuery(GetAccountDocument, undefined, { enabled: !!link && !!link.teamId })
  const getSpendAsTeam = useGraphqlQuery(
    GetTeamByIdDocument,
    { id: link?.spendAs?.teamId as string },
    { enabled: !!link && !!link?.spendAs?.teamId }
  )
  const spendAsTeamName = useMemo(
    () =>
      getSpendAsTeam?.data?.getTeamById?.name ||
      getTeam?.data?.getTeamById?.name ||
      getAccount?.data?.getAccount?.name ||
      '',
    [getSpendAsTeam?.data?.getTeamById?.name, getAccount?.data?.getAccount?.name, getTeam?.data?.getTeamById?.name]
  )

  const teamName = useMemo(
    () => getTeam?.data?.getTeamById?.name || getAccount?.data?.getAccount?.name || '',
    [getAccount?.data?.getAccount?.name, getTeam?.data?.getTeamById?.name]
  )

  const user = useGraphqlQuery(
    GetUserDocument,
    { id: link?.spendAs?.userId as string },
    { enabled: !!link?.spendAs?.userId && link?.spendAs?.userId !== me?.me?.id }
  )
  const userName = useMemo(
    () => user?.data && `${user?.data?.getUser?.firstName} ${user?.data?.getUser?.lastName}`,
    [user]
  )

  const { isExternal } = useSendAs({
    sendAsContactOwner: link?.sendAsContactOwner,
    sendAsUser: link?.sendAsUser,
  })

  if (!link) return <ZCard isLoading />

  return (
    <>
      <ZCard
        variant="form"
        {...rest}
      >
        <ZCardHeader
          p={8}
          pb={0}
        >
          Details
        </ZCardHeader>
        <SimpleGrid
          columns={4}
          spacing={8}
          p={8}
          sx={{
            '& dt': { fontSize: 'sm', fontWeight: 300, color: 'atomicGray.500' },
            '& dd': { fontSize: 'sm', fontWeight: 300, color: 'atomicGray.900' },
          }}
        >
          <UiDescriptionList>
            <UiDescriptionListItem
              title="Owner"
              data-testid="owner"
            >
              {link.userLink?.fullName}
            </UiDescriptionListItem>
          </UiDescriptionList>
          <UiDescriptionList>
            <UiDescriptionListItem
              title="Team"
              data-testid="owner"
            >
              {teamName}
            </UiDescriptionListItem>
          </UiDescriptionList>
          {isExternal && (
            <UiDescriptionList>
              <UiDescriptionListItem
                title="Send As"
                data-testid="sendAs"
              >
                <SendAsLink
                  sendAsContactOwner={link.sendAsContactOwner}
                  sendAsUser={link.sendAsUser}
                />
              </UiDescriptionListItem>
            </UiDescriptionList>
          )}
          <UiDescriptionList>
            <UiDescriptionListItem
              title="Status"
              data-testid="status"
              display="flex"
              alignItems="center"
              gap={1}
            >
              {link.status === MagicLinkStatus.Active ? (
                <Box color="atomicBlue.400">
                  <MdOutlineCheckCircleOutline
                    color="inherit"
                    size="20px"
                  />
                </Box>
              ) : (
                <Box color="atomicGray.300">
                  <MdOutlineBlock
                    color="inherit"
                    size="20px"
                  />
                </Box>
              )}
              <Box as="span">{link.status === MagicLinkStatus.Active ? 'Enabled' : 'Disabled'}</Box>
            </UiDescriptionListItem>
          </UiDescriptionList>
          {!isInModal && (
            <UiDescriptionList>
              <UiDescriptionListItem
                title="Actions"
                data-testid="url"
              >
                <Flex alignItems="center">
                  <CopyIcon mr={2} />
                  <ZButton
                    variant="link"
                    title={link.linkUrl}
                    onClick={() => copyLink(link.linkUrl)}
                  >
                    Share URL
                  </ZButton>
                </Flex>
                <LinkPreview
                  link={link}
                  mt={2}
                />
              </UiDescriptionListItem>
            </UiDescriptionList>
          )}
          <UiDescriptionList>
            <UiDescriptionListItem
              title="Created On"
              data-testid="created"
            >
              <UiDate date={link.created.dateTime} />
            </UiDescriptionListItem>
          </UiDescriptionList>
          <UiDescriptionList>
            <UiDescriptionListItem
              title="Expires On"
              data-testid="expirationDate"
            >
              <Flex direction="column">
                <UiDateTime
                  date={link.expirationDate}
                  fallback="Never"
                />
              </Flex>
            </UiDescriptionListItem>
          </UiDescriptionList>
          <UiDescriptionList>
            <UiDescriptionListItem
              title="Redemptions"
              data-testid="redemptions"
            >
              {link.maxExecutions ? link.metrics.linkExecutions : link.metrics.sent}
            </UiDescriptionListItem>
          </UiDescriptionList>
          <UiDescriptionList>
            <UiDescriptionListItem
              title="Order Limit"
              data-testid="orderLimit"
            >
              {link.maxExecutions || 'Unlimited'}
            </UiDescriptionListItem>
          </UiDescriptionList>
          <UiDescriptionList>
            <UiDescriptionListItem
              title="Last Order"
              data-testid="lastExecution"
            >
              <UiDate
                date={link.lastExecution}
                fallback="N/A"
              />
            </UiDescriptionListItem>
          </UiDescriptionList>
          <UiDescriptionList>
            <UiDescriptionListItem
              title="Last View"
              data-testid="lastView"
            >
              <UiDate
                date={link.lastView}
                fallback="N/A"
              />
            </UiDescriptionListItem>
          </UiDescriptionList>

          {hasFeature('meetingSettings') && !!link.meetingRequestSetting && (
            <UiDescriptionList>
              <UiDescriptionListItem
                title="Meeting Request"
                data-testid="meetingRequest"
              >
                {humanize(link.meetingRequestSetting)}
              </UiDescriptionListItem>
            </UiDescriptionList>
          )}
          {!isInModal && link?.linkUrl && (
            <UiDescriptionList>
              <UiDescriptionListItem
                title="QR Code"
                data-testid="qrCode"
              >
                <QRCodeCanvas
                  value={link?.linkUrl}
                  size={150}
                  imageSettings={
                    hasQrDesign
                      ? {
                          src: '/android-chrome-256x256.png',
                          height: 24,
                          width: 24,
                          excavate: true,
                        }
                      : undefined
                  }
                />
              </UiDescriptionListItem>
            </UiDescriptionList>
          )}
          {canSpendAs && (
            <UiDescriptionList>
              <UiDescriptionListItem
                title={`Spent ${userName ? 'As' : 'From'}`}
                data-testid="spendAs"
              >
                {userName ? (
                  <>
                    <Text fontWeight="semibold">{userName}</Text> from{' '}
                    <Text fontWeight="semibold">{spendAsTeamName}</Text>
                  </>
                ) : (
                  <Text>{spendAsTeamName}</Text>
                )}
              </UiDescriptionListItem>
            </UiDescriptionList>
          )}
          <UiDescriptionList>
            <UiDescriptionListItem title="Total Views">{link.metrics.linkTotalViews}</UiDescriptionListItem>
          </UiDescriptionList>
        </SimpleGrid>
      </ZCard>
    </>
  )
}
