import {
  AspectRatio,
  Box,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import type { UiMenuItemProps } from '@postal-io/postal-ui'
import { FontWeight, UiMenuItem, UiTruncate, useColor, ZHeading, ZMoney, ZText } from '@postal-io/postal-ui'
import type { Currency, FulfillmentMap, ImageReference } from 'api'
import { CATEGORY } from 'components/Postals'
import { PostalSendMethod, SEND_METHOD_COLORS } from 'components/PostalSend/usePostalSend'
import { useAssets } from 'hooks'
import { useMemo } from 'react'
import { MdCheckCircle } from 'react-icons/md'

type BaseVariantType = {
  id: string
  description?: string | null
  displayPrice?: number | null
  variantName: string
  fulfillmentPartnerList?: FulfillmentMap[] | null
  imageUrls?: ImageReference[] | null
}

type BasePostalType = {
  variants?: BaseVariantType[] | null
  category: string
  currency?: Currency | null
}

interface CollectionVariantOptionProps<T, U> extends UiMenuItemProps {
  variant: T
  item: U
  isSelected?: boolean
  onClick?: () => void
  brandingColor?: string
  nameCharacters?: number
  // multi select styling is slightly different with unselected items having a grayed out checkmark
  isMulti?: boolean
  // larger checkbox for certain views
  largeCheckbox?: boolean
}

export const CollectionVariantOption = <T extends BaseVariantType, U extends BasePostalType>({
  variant,
  item,
  isSelected,
  name,
  onClick,
  brandingColor = SEND_METHOD_COLORS[PostalSendMethod.Email],
  nameCharacters = 40,
  isMulti,
  largeCheckbox,
  ...rest
}: CollectionVariantOptionProps<T, U>) => {
  const [imageUrl] = variant.imageUrls || []
  const { assetUrlSrc } = useAssets()
  const { src, fallbackSrc } = assetUrlSrc(imageUrl?.url)

  const { Color } = useColor()

  const fontWeight = useMemo(() => (isSelected && !isMulti ? 'bold' : 'normal'), [isMulti, isSelected])

  const parentVariant = item?.variants?.find((v) => v.id === variant.id)
  const [fulfillmentMap] = parentVariant?.fulfillmentPartnerList || []
  const inventory = fulfillmentMap?.inventory?.available

  const shippingOptionsPrices =
    fulfillmentMap?.shippingOptions?.map((so) => so.price).sort((priceA, priceB) => priceA - priceB) ?? []
  const shippingEstimate = shippingOptionsPrices[0] ?? 0

  return (
    <UiMenuItem
      p={0}
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'default'}
      _hover={{ bg: 'atomicBlue.5' }}
      _focusVisible={{ boxShadow: 'none !important' }}
      {...rest}
    >
      <Popover
        trigger="hover"
        placement="left"
        openDelay={onClick ? 1000 : 300}
      >
        <PopoverTrigger>
          <Box
            data-testid={`PostalVariantOption_card_${isSelected ? 'selected' : 'unselected'}`}
            mb={0}
            w="100%"
            h="50px"
            py={2}
            px={3}
            borderRadius={3}
          >
            <Flex
              flexDir="row"
              width="100%"
              height={8}
              alignItems="center"
            >
              {!isMulti && imageUrl && (
                <AspectRatio
                  boxSize="full"
                  flexShrink={0}
                  ratio={1}
                  width={10}
                  height={10}
                  mr={2}
                  border="1px solid"
                  borderColor="atomicGray.200"
                >
                  <Image
                    boxSize="full"
                    alt={imageUrl.description || ''}
                    src={src}
                    fallbackSrc={fallbackSrc}
                  />
                </AspectRatio>
              )}
              {isMulti && (
                <MdCheckCircle
                  size={largeCheckbox ? '24px' : '20px'}
                  style={{
                    display: 'inline-block',
                    margin: '0px 12px 0 0px',
                    transition: '0.2s color',
                    flexShrink: '0',
                  }}
                  color={isSelected ? brandingColor : Color('atomicGray.200')}
                />
              )}
              <Flex
                flexDir="column"
                justifyContent="center"
                overflow="hidden"
                flexGrow={1}
                flexShrink={1}
                mr={5}
              >
                <ZText
                  fontSize="sm"
                  fontWeight={fontWeight}
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                >
                  <UiTruncate
                    text={variant.variantName}
                    length={nameCharacters}
                  />
                  {isSelected && !isMulti && (
                    <MdCheckCircle
                      size="16px"
                      style={{ display: 'inline-block', margin: '0px 0 -3px 5px', transition: '0.2s color' }}
                      color={brandingColor}
                    />
                  )}
                </ZText>
                {typeof inventory === 'number' && (
                  <ZText
                    color="atomicGray.500"
                    fontSize="xs"
                    fontWeight={350}
                  >
                    Quantity: {inventory}
                  </ZText>
                )}
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                textAlign="right"
              >
                <ZMoney
                  fontWeight={fontWeight}
                  fontSize="xs"
                  cents={variant.displayPrice}
                  currency={item.currency}
                />
                {/* Hide shipping estimate information if this is not item for an event */}
                {item.category !== CATEGORY.Events && (
                  <ZText
                    color="atomicGray.500"
                    fontSize="xs"
                    fontWeight={350}
                  >
                    {!shippingEstimate ? (
                      'FREE'
                    ) : (
                      <>
                        ~
                        <ZMoney
                          fontSize="xs"
                          color="atomicGray.500"
                          cents={shippingEstimate}
                          currency={item.currency}
                          placeholder="$0"
                          round
                        />
                      </>
                    )}{' '}
                    Shipping
                  </ZText>
                )}
              </Flex>
            </Flex>
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            borderRadius={10}
            p={5}
            width="unset"
          >
            <PopoverArrow />
            <PopoverBody>
              <Flex direction="row">
                {imageUrl && (
                  <Image
                    width="150px"
                    height="full"
                    mr={5}
                    borderRadius={5}
                    border="1px solid"
                    borderColor="atomicGray.200"
                    flexShrink={0}
                    overflow="hidden"
                    alt={imageUrl.description || ''}
                    src={src}
                    fallbackSrc={fallbackSrc}
                  />
                )}
                <Box
                  maxW="300px"
                  display="flex"
                  flexDir="column"
                  justifyContent="center"
                >
                  <ZHeading
                    size="h6"
                    fontWeight={FontWeight.Bold}
                    fontSize="md"
                    mt={-1}
                  >
                    {name}
                  </ZHeading>
                  {variant.description && (
                    <ZText
                      as="p"
                      fontSize="xs"
                      fontWeight={350}
                      color="atomicGray.600"
                      mt={2}
                    >
                      {variant.description}
                    </ZText>
                  )}
                </Box>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </UiMenuItem>
  )
}
