import type { UiSelectTypeaheadProps } from '@postal-io/postal-ui'
import { countries, getSelectStylesV2, SelectTypeaheadStylesV2, UiSelectTypeahead } from '@postal-io/postal-ui'
import type { CountryInput } from 'hooks/useGetCountry'
import { useGetCountry } from 'hooks/useGetCountry'
import { useEffect, useState } from 'react'

export type AutoCompleteCountryProps = UiSelectTypeaheadProps<CountryInput> & {
  countryName?: string | null
  animation?: string
}

export const AutoCompleteCountry: React.FC<AutoCompleteCountryProps> = ({
  countryName,
  onChange,
  animation,
  ...rest
}) => {
  const [internalValue, setInternalValue] = useState<CountryInput | null>()
  const country = useGetCountry(countryName)

  useEffect(() => {
    if (country) {
      setInternalValue(country)
      onChange && onChange(country, { action: 'select-option', option: country })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  const handleChange = (country: CountryInput | null, action: any) => {
    setInternalValue(country)
    onChange && onChange(country, action)
  }

  return (
    <UiSelectTypeahead
      data-testid="AutoCompleteCountry"
      options={countries}
      getOptionLabel={(country) => country.name}
      getOptionValue={(country) => country.iso3}
      value={internalValue}
      onChange={handleChange}
      {...SelectTypeaheadStylesV2}
      styles={animation ? zAutocompleteStylesWithAnimation(animation) : getSelectStylesV2}
      {...rest}
    />
  )
}

// if you want an input animation
const zAutocompleteStylesWithAnimation = (animation: string) => {
  return {
    ...getSelectStylesV2,
    control: (provided: any) => ({
      ...getSelectStylesV2.control(provided),
      animation,
    }),
  }
}
