import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerFooterButtons,
  UiDrawerHeader,
  UiDrawerOverlay,
  UiSubNavbar,
  useAlerts,
} from '@postal-io/postal-ui'
import type { PlaybookDefinition } from 'api'
import { BulkContactAddToPlaybookDocument } from 'api'
import { CONTACT_INVALIDATIONS, useBackgroundQueue } from 'hooks'
import React, { useCallback, useState } from 'react'
import { SelectContacts } from '../Contacts'

interface PlaybookInstancesCreateProps {
  isOpen: boolean
  onOpen?: () => void
  onClose: () => void
  onComplete: () => void
  playbook?: PlaybookDefinition
}

export const PlaybookInstancesCreate: React.FC<PlaybookInstancesCreateProps> = ({
  isOpen,
  onClose,
  onComplete,
  playbook,
}) => {
  const Alert = useAlerts()
  const handleComplete = useCallback(() => onComplete && onComplete(), [onComplete])

  const { queue, invalidate } = useBackgroundQueue()
  const bulkContactAddToPlaybook = useGraphqlMutation(BulkContactAddToPlaybookDocument, {
    onSuccess: (data) => {
      queue(data.bulkContactAddToPlaybook, handleComplete)
      invalidate(CONTACT_INVALIDATIONS)
    },
  })

  const [selectedContacts, setSelectedContacts] = useState<any>({ filter: {}, count: 0 })

  const handleSelectedContacts = (contacts: any) => setSelectedContacts(contacts)

  const handleSubmit = async () => {
    if (selectedContacts.count === 0) return Alert.error('Please select at least one Contact')
    try {
      await bulkContactAddToPlaybook.mutateAsync({
        playbookDefinitionId: playbook?.id as string,
        v2filter: selectedContacts.filter,
      })
      onClose()
      Alert.success(`Contacts added to $subscription`)
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <>
      <UiDrawer
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
      >
        <UiDrawerOverlay />
        <UiDrawerContent>
          <UiDrawerCloseButton />
          <UiDrawerHeader>Assign Subscription</UiDrawerHeader>
          <UiSubNavbar />
          <UiDrawerBody>
            <SelectContacts onSelect={handleSelectedContacts} />
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiDrawerFooterButtons>
              <UiButton
                colorScheme="gray"
                onClick={onClose}
              >
                Cancel
              </UiButton>
              <UiButton onClick={handleSubmit}>Assign Subscription</UiButton>
            </UiDrawerFooterButtons>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
    </>
  )
}
