import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { CATEGORY } from 'components/Postals/data'
import { useAcl, useMe, usePostalFilters } from 'hooks'
import { StorageKeys } from 'lib'
import { isEmpty } from 'lodash'
import type { Dispatch } from 'react'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router'
import { useLocation, useMatch } from 'react-router-dom'
import type { ApprovedPostalFilterInput, MarketplaceProductFilterInput } from '../../api/index'
import { GetApprovedPostalFiltersDocument, MagicEventStatus, Status } from '../../api/index'
import { CenteredBox } from '../Common/CenteredBox'
import { EventsSearchBar } from './EventsSearchBar'

const USER_EVENT_STATUS = [MagicEventStatus.AcceptingInvites, MagicEventStatus.RegistrationClosed]

const ADMIN_EVENT_STATUS = [
  MagicEventStatus.AcceptingInvites,
  MagicEventStatus.RegistrationClosed,
  MagicEventStatus.PendingConfirmation,
  MagicEventStatus.ConfirmedNeedsData,
  MagicEventStatus.ConfirmedBillingIssue,
]

export enum EventsView {
  Products = 'PRODUCTS',
  Postals = 'POSTALS',
}

interface EventsContextProps {
  view: EventsView | null
  eventFilters: any
  filterResultsText?: string
  setFilterResultsCount?: Dispatch<React.SetStateAction<number | null>>
}

export const EventsContext = createContext<EventsContextProps>({
  view: null,
  eventFilters: {},
  filterResultsText: undefined,
})

export const EventsPage: React.FC = () => {
  const { state: routerState } = useLocation() as any
  const { marketplaceProductId } = routerState ?? {}
  const [fiterResultsCount, setFilterResultsCount] = useState<number | null>(null)
  const isProducts = useMatch('/events/marketplace')
  const isPostals = useMatch('/events/postals')

  const view = useMemo(
    () => (isProducts ? EventsView.Products : isPostals ? EventsView.Postals : null),
    [isPostals, isProducts]
  )

  const { aclCheck } = useAcl()
  const canCreate = aclCheck({ module: 'events.create' })
  const { approvedCurrencies } = useMe()

  // SHARED FILTERS

  const staticVariables = useMemo(() => {
    const variables: any = {
      category: { eq: CATEGORY.Events },
      status: { in: canCreate ? [Status.Active, Status.Disabled] : [Status.Active] },
    }
    // Here we are making sure that we are filtering the items based on the available
    // currencies the user has.  At some point we my want to show all products and
    // then prompt them to add a currency
    if (!isEmpty(approvedCurrencies)) variables.currency = { in: approvedCurrencies }
    return variables
  }, [approvedCurrencies, canCreate])

  // to determine default eventStatus for Events
  const getApprovedPostalFilters = useGraphqlQuery(GetApprovedPostalFiltersDocument, {
    category: CATEGORY.Events,
    statuses: canCreate ? [Status.Active, Status.Disabled] : [Status.Active],
  })
  // set an initial Event status depending on the Role of the user and what is available for them to select from.
  const eventStatus = useMemo(() => {
    const options = getApprovedPostalFilters.data?.getApprovedPostalFilters
      ?.find((f) => f.name === 'eventStatus')
      ?.options?.map((o) => o.name)
    const available = canCreate
      ? ADMIN_EVENT_STATUS.filter((f) => options?.includes(f))
      : USER_EVENT_STATUS.filter((f) => options?.includes(f))
    return available?.length ? available : undefined
  }, [canCreate, getApprovedPostalFilters.data?.getApprovedPostalFilters])

  const initialFilters = useMemo(() => ({ marketplaceProductId }), [marketplaceProductId])

  const eventFilters = usePostalFilters<MarketplaceProductFilterInput | ApprovedPostalFilterInput>({
    staticVariables,
    initialFilters,
    persistKey: StorageKeys.EventsFilter,
  })

  useEffect(() => {
    if (!!eventStatus && view === EventsView.Postals) {
      eventFilters.updateFilter('eventStatus', eventStatus, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventStatus, view])

  // update filters from router state
  useEffect(() => {
    if (!!routerState && Object.keys(routerState).length) {
      Object.entries(routerState).forEach(([key, val]) => {
        if (!key.startsWith('_')) eventFilters.updateFilter(key as string, val)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPostals, isProducts])

  const filterResultsText = useMemo(() => {
    return fiterResultsCount === null
      ? '1,000+ results'
      : fiterResultsCount === 0
      ? '0 results'
      : `${Intl.NumberFormat().format(fiterResultsCount)}+ results based on your filters`
  }, [fiterResultsCount])

  return (
    <CenteredBox isLoaded>
      <EventsContext.Provider value={{ view, eventFilters, filterResultsText, setFilterResultsCount }}>
        <EventsSearchBar />
        <Outlet />
      </EventsContext.Provider>
    </CenteredBox>
  )
}
