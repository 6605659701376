import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import { UiSSDataTable } from '@postal-io/postal-ui'
import type { SearchableContact, SearchableContactFilterInput } from 'api'
import { SearchContactsV2Document } from 'api'
import { ZDialog, zMultiSelectStyles, ZSearchBar } from 'components/Common/ZComponents'
import React, { useMemo, useState } from 'react'
import { BASIC_COLUMNS } from './data'

const LIMIT = 1000

export interface MultiSelectSelectedTableV2Props {
  title?: string
  isOpen: boolean
  onClose: () => void
  orfilters: SearchableContactFilterInput[]
  onSelect?: (contact: SearchableContact) => void
}
export const MultiSelectSelectedTable: React.FC<MultiSelectSelectedTableV2Props> = ({
  title = 'Contacts',
  isOpen,
  onClose,
  orfilters,
  onSelect,
}) => {
  const [q, setQ] = useState<string>('')

  const filter = useMemo(
    () => ({
      q: q ? (q.includes(' ') ? q : q + '*') : null,
    }),
    [q]
  )

  const searchContacts = useGraphqlInfiniteQuery(SearchContactsV2Document, {
    orfilters,
    filter,
    limit: LIMIT,
  })
  const rows = useMemo(
    () =>
      searchContacts.mergedData?.searchContactsV2.searchableContacts?.map((contact) => ({
        ...contact,
        onClick: onSelect ? () => onSelect(contact) : undefined,
      })) || [],
    [onSelect, searchContacts.mergedData?.searchContactsV2.searchableContacts]
  )
  const totalRecords = useMemo(
    () => searchContacts.mergedData?.searchContactsV2?.resultsSummary?.totalRecords,
    [searchContacts.mergedData?.searchContactsV2?.resultsSummary?.totalRecords]
  )

  return (
    <ZDialog
      title={`${title} (${totalRecords})`}
      size="6xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ZSearchBar
        searchString={q}
        setSearchString={setQ}
        mb={2}
      />
      <UiSSDataTable
        pt={0}
        isLoading={searchContacts.isFetching}
        columns={BASIC_COLUMNS}
        rows={rows}
        rowKey="id"
        hasMore={searchContacts.hasNextPage}
        fetchMore={searchContacts.fetchNextPage}
        filter={orfilters}
        totalRecords={totalRecords}
        sx={{ ...zMultiSelectStyles }}
      />
    </ZDialog>
  )
}
