import type { ImageProps } from '@chakra-ui/react'
import { Image } from '@chakra-ui/react'
import ashley from 'assets/handwriting/v2/ashley.webp'
import ben from 'assets/handwriting/v2/ben.webp'
import erik from 'assets/handwriting/v2/erik.webp'
import felicia from 'assets/handwriting/v2/felicia.webp'
import grace from 'assets/handwriting/v2/grace.webp'
import nick from 'assets/handwriting/v2/nick.webp'
import patricia from 'assets/handwriting/v2/patricia.webp'
import richie from 'assets/handwriting/v2/richie.webp'
import React from 'react'

interface PreviewsProps {
  [index: string]: string
}

const previews: PreviewsProps = { ashley, ben, erik, grace, richie, patricia, nick, felicia }

export interface FontProps {
  name: string
  code: string
}

export interface FontPreviewProps extends ImageProps {
  font: FontProps
}

export const FontPreview: React.FC<FontPreviewProps> = ({ font, ...rest }) => {
  return (
    <Image
      src={previews[font?.code]}
      alt={font.name}
      w="100%"
      {...rest}
    />
  )
}

export const HUMAN = 'HUMAN'
export const SYSTEM = 'SYSTEM'

export const TYPES = {
  HUMAN,
  SYSTEM,
}

export const FONTS = [
  {
    name: 'The Ashley',
    code: 'ashley',
    type: HUMAN,
  },
  {
    name: 'The Ben',
    code: 'ben',
    type: HUMAN,
  },
  {
    name: 'The Erik',
    code: 'erik',
    type: HUMAN,
  },
  {
    name: 'The Grace',
    code: 'grace',
    type: HUMAN,
  },
  {
    name: 'The Richie',
    code: 'richie',
    type: HUMAN,
  },
  {
    name: 'The Patricia',
    code: 'patricia',
    type: HUMAN,
  },
  {
    name: 'The Nick',
    code: 'nick',
    type: HUMAN,
  },
  {
    name: 'The Felicia',
    code: 'felicia',
    type: HUMAN,
  },
  {
    name: 'Amatic',
    code: 'AmaticSC',
    type: SYSTEM,
    variants: [
      { name: 'Regular', code: 'Regular' },
      { name: 'Bold', code: 'Bold' },
    ],
  },
  {
    name: 'Anton',
    code: 'Anton',
    type: SYSTEM,
    variants: [{ name: 'Regular', code: 'Regular' }],
  },
  {
    name: 'Archivo Black',
    code: 'ArchivoBlack',
    type: SYSTEM,
    variants: [{ name: 'Regular', code: 'Regular' }],
  },
  {
    name: 'Caveat',
    code: 'Caveat',
    type: SYSTEM,
    variants: [
      { name: 'Regular', code: 'Regular' },
      { name: 'Bold', code: 'Bold' },
    ],
  },
  {
    name: 'Didact Gothic',
    code: 'DidactGothic',
    type: SYSTEM,
    variants: [{ name: 'Regular', code: 'Regular' }],
  },
  {
    name: 'DM Sans',
    code: 'DMSans',
    type: SYSTEM,
    variants: [
      { name: 'Regular', code: 'Regular' },
      { name: 'Bold', code: 'Bold' },
    ],
  },
  {
    name: 'Fredoka One',
    code: 'FredokaOne',
    type: SYSTEM,
    variants: [{ name: 'Regular', code: 'Regular' }],
  },
  {
    name: 'Inter',
    code: 'Inter',
    type: SYSTEM,
    variants: [
      { name: 'Thin', code: 'Thin' },
      { name: 'Regular', code: 'Regular' },
      { name: 'Bold', code: 'Bold' },
    ],
  },
  {
    name: 'Lobster',
    code: 'Lobster',
    type: SYSTEM,
    variants: [{ name: 'Regular', code: 'Regular' }],
  },
  {
    name: 'Lora',
    code: 'Lora',
    type: SYSTEM,
    variants: [
      { name: 'Regular', code: 'Regular' },
      { name: 'Bold', code: 'Bold' },
    ],
  },
  {
    name: 'Molot',
    code: 'Molot',
    type: SYSTEM,
    variants: [{ name: 'Regular', code: 'Regular' }],
  },
  {
    name: 'Sans',
    code: 'OpenSans',
    type: SYSTEM,
    variants: [
      { name: 'Regular', code: 'Regular' },
      { name: 'Bold', code: 'Bold' },
      { name: 'Extra Bold', code: 'ExtraBold' },
      { name: 'Bold Italic', code: 'BoldItalic' },
      { name: 'Extra Bold Italic', code: 'ExtraBoldItalic' },
      { name: 'Italic', code: 'Italic' },
      { name: 'Light', code: 'Light' },
      { name: 'Light Italic', code: 'LightItalic' },
      { name: 'Semi Bold', code: 'SemiBold' },
      { name: 'Semi Bold Italic', code: 'SemiBoldItalic' },
    ],
  },
  {
    name: 'Muli',
    code: 'Muli',
    type: SYSTEM,
    variants: [
      { name: 'Regular', code: 'Regular' },
      { name: 'Black', code: 'Black' },
      { name: 'Black Italic', code: 'BlackItalic' },
      { name: 'Bold', code: 'Bold' },
      { name: 'Bold Italic', code: 'BoldItalic' },
      { name: 'Extra Bold', code: 'ExtraBold' },
      { name: 'Extra Bold Italic', code: 'ExtraBoldItalic' },
      { name: 'Light', code: 'Light' },
      { name: 'Extra Light', code: 'ExtraLight' },
      { name: 'Extra Light Italic', code: 'ExtraLightItalic' },
      { name: 'Italic', code: 'Italic' },
      { name: 'Light Italic', code: 'LightItalic' },
      { name: 'Medium', code: 'Medium' },
      { name: 'Medium Italic', code: 'MediumItalic' },
      { name: 'Semi Bold', code: 'SemiBold' },
      { name: 'Semi Bold Italic', code: 'SemiBoldItalic' },
    ],
  },
  {
    name: 'Oswald',
    code: 'Oswald',
    type: SYSTEM,
    variants: [
      { name: 'Regular', code: 'Regular' },
      { name: 'Bold', code: 'Bold' },
      { name: 'Semi Bold', code: 'SemiBold' },
      { name: 'Medium', code: 'Medium' },
      { name: 'Semi Bold Italic', code: 'SemiBoldItalic' },
      { name: 'Light', code: 'Light' },
      { name: 'Extra Light', code: 'ExtraLight' },
    ],
  },
  {
    name: 'PT Sans Narrow',
    code: 'PTSansNarrow',
    type: SYSTEM,
    variants: [
      { name: 'Regular', code: 'Regular' },
      { name: 'Bold', code: 'Bold' },
    ],
  },
  {
    name: 'Pacifico',
    code: 'Pacifico',
    type: SYSTEM,
    variants: [{ name: 'Regular', code: 'Regular' }],
  },
  {
    name: 'Playfair Display',
    code: 'PlayfairDisplay',
    type: SYSTEM,
    variants: [
      { name: 'Regular', code: 'Regular' },
      { name: 'Black', code: 'Black' },
      { name: 'Black Italic', code: 'BlackItalic' },
      { name: 'Bold', code: 'Bold' },
      { name: 'Bold Italic', code: 'BoldItalic' },
      { name: 'Semi Bold', code: 'SemiBold' },
      { name: 'Extra Bold', code: 'ExtraBold' },
      { name: 'Medium', code: 'Medium' },
      { name: 'Semi Bold Italic', code: 'SemiBoldItalic' },
    ],
  },
  {
    name: 'Quattrocento Sans',
    code: 'QuattrocentoSans',
    type: SYSTEM,
    variants: [
      { name: 'Regular', code: 'Regular' },
      { name: 'Bold', code: 'Bold' },
      { name: 'Bold Italic', code: 'BoldItalic' },
      { name: 'Italic', code: 'Italic' },
    ],
  },
  {
    name: 'Quicksand',
    code: 'Quicksand',
    type: SYSTEM,
    variants: [
      { name: 'Regular', code: 'Regular' },
      { name: 'Bold', code: 'Bold' },
      { name: 'Semi Bold', code: 'SemiBold' },
      { name: 'Medium', code: 'Medium' },
      { name: 'Light', code: 'Light' },
    ],
  },
  {
    name: 'Raleway',
    code: 'Raleway',
    type: SYSTEM,
    variants: [
      { name: 'Regular', code: 'Regular' },
      { name: 'Black', code: 'Black' },
      { name: 'Black Italic', code: 'BlackItalic' },
      { name: 'Bold', code: 'Bold' },
      { name: 'Extra Bold', code: 'ExtraBold' },
      { name: 'Extra Bold Italic', code: 'ExtraBoldItalic' },
      { name: 'Light', code: 'Light' },
      { name: 'Extra Light', code: 'ExtraLight' },
      { name: 'Extra Light Italic', code: 'ExtraLightItalic' },
      { name: 'Italic', code: 'Italic' },
      { name: 'Light Italic', code: 'LightItalic' },
      { name: 'Medium', code: 'Medium' },
      { name: 'Medium Italic', code: 'MediumItalic' },
      { name: 'Semi Bold', code: 'SemiBold' },
      { name: 'Semi Bold Italic', code: 'SemiBoldItalic' },
      { name: 'Thin', code: 'Thin' },
      { name: 'Thin Italic', code: 'ThinItalic' },
    ],
  },
  {
    name: 'Righteous',
    code: 'Righteous',
    type: SYSTEM,
    variants: [{ name: 'Regular', code: 'Regular' }],
  },
  {
    name: 'Roboto',
    code: 'Roboto',
    type: SYSTEM,
    variants: [{ name: 'Regular', code: 'Regular' }],
  },
  {
    name: 'Roboto Slab',
    code: 'RobotoSlab',
    type: SYSTEM,
    variants: [
      { name: 'Regular', code: 'Regular' },
      { name: 'Black', code: 'Black' },
      { name: 'Bold', code: 'Bold' },
      { name: 'Extra Bold', code: 'ExtraBold' },
      { name: 'Extra Light', code: 'ExtraLight' },
      { name: 'Light', code: 'Light' },
      { name: 'Medium', code: 'Medium' },
      { name: 'Semi Bold', code: 'SemiBold' },
      { name: 'Thin', code: 'Thin' },
    ],
  },
]

export const HUMAN_FONTS = FONTS.filter((f) => f.type === HUMAN)
export const SYSTEM_FONTS = FONTS.filter((f) => f.type === SYSTEM)
