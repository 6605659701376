import { Collapse } from '@chakra-ui/react'
import { ZCard, ZCardBody, ZFlowButton, ZText } from '@postal-io/postal-ui'
import type { PostalSendContext } from 'components/PostalSend/usePostalSend'
import { PostalSendMethod } from 'components/PostalSend/usePostalSend'
import React, { useMemo } from 'react'
import { MdChevronRight, MdLink, MdOutlineArrowBack, MdOutlineArrowUpward, MdSend } from 'react-icons/md'
import { shakeAnimation, SHAKE_ANIMATION_NAME } from './usePostalSendFieldErrors'

/**
 * Send Sidebar Next Step Button
 *
 * This button is meant for the PostalSendSidebar. It supports error messages as well as error highlighting
 * Error behavior depends on if a onDisabledClick method is provided
 */
interface PostalSendSidebarNextStepButtonProps {
  context?: PostalSendContext
  onClick: () => void
  errorMessage?: string
  onDisabledClick?: () => void
  brandingColor: string
  labelText?: string
  isLoading?: boolean
}
export const PostalSendSidebarNextStepButton: React.FC<PostalSendSidebarNextStepButtonProps> = ({
  context,
  onClick,
  errorMessage,
  onDisabledClick,
  brandingColor,
  labelText,
  isLoading,
}) => {
  const showFormError = context?.highlightFieldWithError
  const fieldWithError = context?.fieldWithError

  const buttonDisabled = (!onDisabledClick && !!errorMessage) || showFormError || isLoading
  // dropdown will display longer error messages
  const dropdownOpen = (errorMessage?.length ?? 0) > 35

  const buttonText = useMemo(() => {
    // form error message can be resolved and we point them in the right direction on click
    if (errorMessage && dropdownOpen) return onDisabledClick ? 'Please address the error to continue' : 'Disabled'
    // if the dropdown is showing a long error message
    if (errorMessage && !dropdownOpen) return onDisabledClick ? errorMessage + ' to continue' : errorMessage
    // if no errors then show the normal text
    return labelText
  }, [dropdownOpen, errorMessage, labelText, onDisabledClick])

  const bgColor = useMemo(() => (errorMessage ? 'atomicGray.100' : brandingColor), [errorMessage, brandingColor])
  const color = useMemo(() => (errorMessage ? 'atomicGray.500' : 'white'), [errorMessage])

  const LeftIcon = useMemo(() => {
    if (showFormError) {
      return ['quantity', 'variant'].includes(fieldWithError!) ? (
        <MdOutlineArrowUpward size="16px" />
      ) : (
        <MdOutlineArrowBack size="16px" />
      )
    }

    return context?.method === PostalSendMethod.Link ? (
      <MdLink
        viewBox=" 2 2 20 20"
        size="16px"
      />
    ) : (
      <MdSend size="16px" />
    )
  }, [context?.method, fieldWithError, showFormError])

  return (
    <>
      <style>{shakeAnimation}</style>
      <ZFlowButton
        mt={2}
        width="100%"
        fontSize="sm"
        fontWeight="bold"
        color={color}
        bg={bgColor}
        opacity={buttonDisabled ? '1 !important' : undefined}
        _hover={{ bg: bgColor, opacity: 0.8 }}
        _active={{ bg: bgColor, opacity: 0.9 }}
        borderBottomLeftRadius={dropdownOpen ? '0' : ''}
        borderBottomRightRadius={dropdownOpen ? '0' : ''}
        leftIcon={LeftIcon}
        rightIcon={showFormError ? undefined : <MdChevronRight />}
        onClick={errorMessage ? onDisabledClick : onClick}
        isDisabled={buttonDisabled}
        isLoading={isLoading}
        animation={showFormError ? `${SHAKE_ANIMATION_NAME} 0.3s` : ''}
      >
        {buttonText}
      </ZFlowButton>
      <Collapse in={dropdownOpen}>
        <ZCard
          m={0}
          p="20px !important"
          variant="form"
          borderRadius="0 0 3px 3px"
          borderTop="none"
          background="atomicYellow.10"
        >
          <ZCardBody p={5}>
            <>
              <ZText
                display="flex"
                fontWeight={600}
                color="atomicYellow.800"
              >
                {errorMessage}
              </ZText>
            </>
          </ZCardBody>
        </ZCard>
      </Collapse>
    </>
  )
}
