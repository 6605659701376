import { FormControl, HStack, InputGroup } from '@chakra-ui/react'
import type { UiSubNavbarProps } from '@postal-io/postal-ui'
import { UiToggle, ZFormLabel, ZInput, ZInputLeftIcon, ZSubNavbar } from '@postal-io/postal-ui'
import { useAcl } from 'hooks/useAcl'
import type { ChangeEvent } from 'react'
import React, { useContext, useRef } from 'react'
import { MdSearch } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { EventsContext, EventsView } from './EventsPage'

export const EventsSearchBar: React.FC<UiSubNavbarProps> = (props) => {
  const { aclCheck } = useAcl()
  const navigate = useNavigate()
  const searchRef = useRef<any>(null)
  const hasEventsMarketplace = aclCheck({ module: 'events.marketplace' })
  const {
    view,
    eventFilters: { filters, updateFilter },
  } = useContext(EventsContext)

  const handleToggle = () => {
    if (view === EventsView.Postals) {
      navigate('/events/marketplace')
    } else {
      navigate('/events/postals')
    }
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => updateFilter?.('q', e.target.value)

  return (
    <ZSubNavbar
      left={
        <FormControl id="myEvents">
          <HStack spacing={3}>
            <UiToggle
              name="myEvents"
              isChecked={view === EventsView.Postals}
              onChange={handleToggle}
              isDisabled={!hasEventsMarketplace}
            />

            <ZFormLabel color="atomicGray.500">My Events</ZFormLabel>
          </HStack>
        </FormControl>
      }
      center={
        <FormControl id="search">
          <InputGroup>
            <ZInputLeftIcon icon={<MdSearch size="16px" />} />
            <ZInput
              ref={searchRef}
              name="search"
              placeholder="Search"
              w="440px"
              value={filters?.q ?? ''}
              onChange={handleSearch}
            />
          </InputGroup>
        </FormControl>
      }
      // right={
      //   <ZButton
      //     variant="ghost"
      //     leftIcon={<MdClose color={colorCode('atomicGray.400')} />}
      //     onClick={handleClearFilters}
      //     borderColor="atomicGray.100"
      //     fontSize="body-md"
      //   >
      //     Clear filters
      //   </ZButton>
      // }
      px={0}
      gridProps={{ py: 4 }}
      {...props}
    />
  )
}
