import { Text, Tooltip } from '@chakra-ui/react'
import type { Column } from '@postal-io/postal-ui'
import { humanize, UiDate, UiLink } from '@postal-io/postal-ui'
import type { IntegrationTriggerResult } from 'api'
import { Result, TriggerAction } from 'api'
import { NavLink as RouterLink } from 'react-router-dom'

export const PLAYBOOK_ACTIONS = [TriggerAction.StartPlaybook, TriggerAction.StopPlaybook]

export const triggerColumns: Column[] = [
  {
    key: 'contactName',
    label: 'Contact',
    render: ({ contactName, contactId }: IntegrationTriggerResult) => (
      <UiLink
        to={`/contacts/${contactId}`}
        as={RouterLink}
      >
        {contactName}
      </UiLink>
    ),
  },
  {
    key: 'userLink',
    label: 'Contact Owner',
    render: ({ contactOwner, userId }: any) => (
      <UiLink
        to={`/users/${userId}`}
        as={RouterLink}
      >
        {humanize(contactOwner)}
      </UiLink>
    ),
  },
  {
    key: 'created',
    label: 'Initiated',
    render: ({ created }: IntegrationTriggerResult) => <UiDate date={created.dateTime} />,
  },
  {
    key: 'result',
    label: 'Result',
    render: ({ result, notes }: IntegrationTriggerResult) => (
      <>
        <Tooltip
          zIndex={9999}
          px={2}
          placement="auto"
          hasArrow
          label={`${notes}`}
          aria-label="trigger result"
        >
          <Text color={result === Result.Success ? 'tertiary.500' : 'red.500'}>{humanize(result)}</Text>
        </Tooltip>
      </>
    ),
  },
]

export const filteredTriggerColumns: Column[] = [
  {
    key: 'contactName',
    label: 'Contact',
    render: ({ contactName, contactId }: IntegrationTriggerResult) => (
      <UiLink
        to={`/contacts/${contactId}`}
        as={RouterLink}
      >
        {contactName}
      </UiLink>
    ),
  },
  {
    key: 'created',
    label: 'Initiated',
    render: ({ created }: IntegrationTriggerResult) => <UiDate date={created.dateTime} />,
  },
  {
    key: 'result',
    label: 'Result',
    render: ({ result, notes }: IntegrationTriggerResult) => (
      <>
        <Tooltip
          zIndex={9999}
          px={2}
          placement="auto"
          hasArrow
          label={`${notes}`}
          aria-label="trigger result notes"
        >
          <Text color={result === Result.Success ? 'tertiary.500' : 'red.500'}>{humanize(result)}</Text>
        </Tooltip>
      </>
    ),
  },
]
