import { Box, Container, Divider, Flex, FormControl, Grid, HStack, Stack } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButtonScrollTop,
  UiSidePanelContainer,
  UiSkeleton,
  UiToggle,
  useAlertError,
  useInfiniteScroll,
  ZFormLabel,
} from '@postal-io/postal-ui'
import { MarketplaceCardPostal } from 'components/Marketplace/MarketplaceCardPostal'
import { CATEGORY, MARKETPLACE_CARD_MIN_WIDTH } from 'components/Postals'
import { dequal } from 'dequal'
import { AnalyticsEvent, useAnalyticsEvent, useCollectionPermissions, useMe } from 'hooks'
import { usePostalFavoriteStatus } from 'hooks/usePostalFavoriteStatus'
import { StorageKeys } from 'lib'
import { cloneDeep, isEmpty, omit } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import type { ApprovedPostal, ApprovedPostalFilterInput } from '../../api'
import { SearchApprovedPostalsDocument, Status } from '../../api'
import { usePostalFilters } from '../../hooks/usePostalFilters'
import { SidePanelFilter, SubnavFilters } from '../Common'
import { CollectionLoading, CollectionNotFound } from './CollectionCard'
import { CollectionsEmpty } from './CollectionsEmpty'
import { CollectionsSearchBar } from './CollectionsSearchBar'
import { Owner } from './data'
import { CollectionsContext, COLLECTIONS_INITIAL_STATE, useCollections } from './useCollections'

const LIMIT = 20

export const INITIAL_COLLECTIONS_FILTERS = { status: Status.Active, owner: Owner.Shared }
export const isInitialCollectionsFilter = (filters: Record<string, any>) =>
  !dequal(filters, INITIAL_COLLECTIONS_FILTERS)

const SUB_NAV_IGNORED = ['category', 'status', 'owner']

export const Collections: React.FC = () => {
  const navigate = useNavigate()
  const { me } = useMe()
  const { canCreate, canCreateShared, hasUserCollections } = useCollectionPermissions()
  const { approvedCurrencies } = useMe()

  const { state, setNumFilters, setNumResults } = useCollections({
    initialState: COLLECTIONS_INITIAL_STATE,
  })

  // filters will NOT override these
  const staticVariables = useMemo(() => {
    return { category: { eq: CATEGORY.Collection } }
  }, [])

  const collectionsFilters = usePostalFilters<ApprovedPostalFilterInput>({
    initialFilters: INITIAL_COLLECTIONS_FILTERS,
    staticVariables,
    persistKey: StorageKeys.CollectionsFilter,
  })

  // override currency
  // override status based on the type of user
  const graphqlVariables = useMemo(() => {
    const variables = cloneDeep(collectionsFilters.graphqlFilter)
    if (isEmpty(variables.currency) && !isEmpty(approvedCurrencies)) {
      variables.currency = { in: approvedCurrencies }
    }
    if (isEmpty(variables.status)) {
      variables.status =
        canCreateShared || (canCreate && collectionsFilters.filters.owner === Owner.Me)
          ? { in: [Status.Active, Status.Disabled] }
          : { in: [Status.Active] }
    }
    return variables
  }, [
    approvedCurrencies,
    canCreate,
    canCreateShared,
    collectionsFilters.filters.owner,
    collectionsFilters.graphqlFilter,
  ])

  const searchPostals = useGraphqlInfiniteQuery(SearchApprovedPostalsDocument, {
    filter: graphqlVariables,
    limit: LIMIT,
  })

  // override subnav filters to lock in category
  const subnavFilters = useMemo(() => omit(collectionsFilters.filters, SUB_NAV_IGNORED), [collectionsFilters.filters])

  useAlertError(searchPostals.error)

  const collections = useMemo(() => {
    return searchPostals.mergedData?.searchApprovedPostals || []
  }, [searchPostals.mergedData?.searchApprovedPostals])

  const { postalsWithFavoriteStatus: collectionsWithFavoriteStatus } = usePostalFavoriteStatus(collections)

  const hasOneSharedFilters = useMemo(() => {
    const variables = {} as ApprovedPostalFilterInput
    if (!isEmpty(approvedCurrencies)) variables.currency = { in: approvedCurrencies }
    variables.status = canCreateShared ? { in: [Status.Active, Status.Disabled] } : { in: [Status.Active] }
    variables.ownerId = { in: [null] }
    return variables
  }, [approvedCurrencies, canCreateShared])

  const hasOneQueryShared = useGraphqlQuery(SearchApprovedPostalsDocument, {
    limit: 1,
    filter: hasOneSharedFilters,
  })

  const hasOnePrivateFilters = useMemo(() => {
    const variables = {} as ApprovedPostalFilterInput
    if (!isEmpty(approvedCurrencies)) variables.currency = { in: approvedCurrencies }
    variables.status = { in: [Status.Active, Status.Disabled] }
    variables.ownerId = { eq: me?.id }
    return variables
  }, [approvedCurrencies, me?.id])

  const hasOneQueryPrivate = useGraphqlQuery(
    SearchApprovedPostalsDocument,
    { limit: 1, filter: hasOnePrivateFilters },
    { enabled: hasUserCollections }
  )

  const hasOneItem = useMemo(() => {
    const sharedCount = hasOneQueryShared.data?.searchApprovedPostals?.length || 0
    const privateCount = hasOneQueryPrivate.data?.searchApprovedPostals?.length || 0
    return !!(sharedCount + privateCount)
  }, [hasOneQueryPrivate.data?.searchApprovedPostals?.length, hasOneQueryShared.data?.searchApprovedPostals?.length])

  const openCollection = (collection: ApprovedPostal) => {
    navigate(`/collections/${collection.id}`, { state: { returnTo: 'Collections' } })
  }

  const createCollection = () => {
    navigate(`/collections/create`, { state: { returnTo: 'Collections' } })
  }

  useAnalyticsEvent({ event: AnalyticsEvent.MarketplaceCollectionPageViewed })

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchPostals.hasNextPage,
    loadMore: searchPostals.fetchNextPage,
    loading: searchPostals.isFetching,
  })

  useEffect(() => {
    setNumFilters(Object.keys(collectionsFilters.filters).filter((f) => !SUB_NAV_IGNORED.includes(f)).length ?? 0)
  }, [collectionsFilters.filters, setNumFilters])

  useEffect(() => {
    setNumResults?.(collections.length)
  }, [collections.length, setNumResults])

  return (
    <CollectionsContext.Provider value={{ state, collectionsFilters }}>
      <Container
        maxW="calc(1440px + 4rem)"
        px={8}
        pt={0}
      >
        <CollectionsSearchBar onCreate={createCollection} />
        <Divider mb={5} />
        <SubnavFilters
          data-testid="Collection_Marketplace_SubnavFilters"
          filters={subnavFilters}
          onUpdate={collectionsFilters.updateFilter}
          onClear={() =>
            collectionsFilters.clearFilters({
              ...INITIAL_COLLECTIONS_FILTERS,
              owner: collectionsFilters.filters.owner,
            })
          }
          mb={-1}
          display={!!state.numFilters ? 'none' : undefined}
        />
        <UiSkeleton isLoaded={!hasOneQueryShared.isLoading && !hasOneQueryPrivate.isLoading}>
          {!hasOneItem ? (
            <CollectionsEmpty />
          ) : (
            <UiSidePanelContainer>
              <Stack spacing={6}>
                <SidePanelFilter
                  filters={collectionsFilters.filters}
                  onUpdate={collectionsFilters.updateFilter}
                  filterType="Collections"
                  restrictCategory={CATEGORY.Collection}
                  approvedCurrencies={approvedCurrencies}
                  topBlock={<ShowMyCollectionsToggle {...collectionsFilters} />}
                  showDraft
                />
              </Stack>
              <Flex
                direction="column"
                w="100%"
                ref={topRef}
                style={{ scrollMargin: '20px' }}
              >
                <Grid
                  templateColumns={`repeat(auto-fill, minmax(${MARKETPLACE_CARD_MIN_WIDTH}px, 1fr))`}
                  gap={4}
                >
                  {collectionsWithFavoriteStatus?.map((collection) => (
                    <MarketplaceCardPostal
                      key={collection.id}
                      favoriteItemId={collection.favoriteItemId}
                      postal={collection!}
                      buttonText="View this Collection"
                      onSelect={() => openCollection(collection)}
                    />
                  ))}
                  {!searchPostals.isLoading && !collectionsWithFavoriteStatus?.length && (
                    <CollectionNotFound
                      includingDrafts={collectionsFilters.filters.draft}
                      owner={collectionsFilters.filters.owner}
                    />
                  )}
                  {searchPostals.isLoading && <CollectionLoading />}
                </Grid>
                {(collectionsWithFavoriteStatus?.length ?? 0) >= LIMIT && (
                  <Box
                    ref={bottomRef}
                    position="relative"
                  >
                    <UiButtonScrollTop
                      onClick={scrollTop}
                      position="absolute"
                      top="0px"
                      right="0px"
                      isLoading={searchPostals.isFetching}
                      aria-label="scroll button"
                    />
                  </Box>
                )}
              </Flex>
            </UiSidePanelContainer>
          )}
        </UiSkeleton>
      </Container>
    </CollectionsContext.Provider>
  )
}

const ShowMyCollectionsToggle: React.FC<{ filters: any; updateFilter: any }> = ({ filters, updateFilter }) => (
  <FormControl id="isApproved">
    <HStack
      spacing={3}
      mt={3}
      mb={8}
    >
      <FormControl>
        <UiToggle
          colorScheme="atomicBlue"
          name="Show My Collections"
          isChecked={filters.owner === Owner.Me}
          onChange={() => updateFilter('owner', filters.owner === Owner.Shared ? Owner.Me : Owner.Shared, 0)}
        />
      </FormControl>
      <ZFormLabel color="atomicGray.500">Show My Collections</ZFormLabel>
    </HStack>
  </FormControl>
)
