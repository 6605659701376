import { MinusIcon } from '@chakra-ui/icons'
import {
  Box,
  CheckboxGroup,
  Collapse,
  Divider,
  Flex,
  Grid,
  HStack,
  RadioGroup,
  SimpleGrid,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import {
  UiConfirm,
  UiFormControl,
  UiIconButton,
  UiToggle,
  UiTooltip,
  useAlerts,
  ZButton,
  ZCard,
  ZCardHeader,
  ZCheckbox,
  ZFormLabel,
  ZInput,
  ZRadio,
  ZSelect,
  ZText,
} from '@postal-io/postal-ui'
import type { UpdateSelfInput } from 'api'
import { CalendarProvider, MeetingRequestSetting, UpdateSelfDocument } from 'api'
import { ZFormLabelWithTooltip } from 'components/Common/ZComponents'
import { dequal } from 'dequal'
import { useCalendarIntegrations } from 'hooks'
import { cloneDeep, set } from 'lodash'
import React from 'react'
import { MdOutlineAdd, MdOutlineDelete, MdOutlineQueryBuilder } from 'react-icons/md'
import type { Updater } from 'use-immer'
import { createEvent, DayOfWeek, toIcal } from './schedule'
import { UserCalendarProviders } from './UserCalendarProviders'
import type { MeetingSettingFormState } from './userMeetingSettingsData'
import { MEETING_REQUEST_TOOLTIP } from './userMeetingSettingsData'
const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

const isInternalCalendar = (provider?: CalendarProvider) => {
  return (
    provider === CalendarProvider.Google ||
    provider === CalendarProvider.Microsoft ||
    provider === CalendarProvider.Test
  )
}

interface UserMeetingSettingsEditProps extends Omit<UiCardProps, 'me'> {
  state: MeetingSettingFormState
  setState: Updater<MeetingSettingFormState>
}
export const UserMeetingSettingsEdit: React.FC<UserMeetingSettingsEditProps> = ({ state, setState, ...rest }) => {
  const removeDisclosure = useDisclosure()
  const Alert = useAlerts()
  const timeZone = state.current.calendar?.timeZone || localTimeZone

  const { getCalendarProvider } = useCalendarIntegrations()
  const calendarProvider = getCalendarProvider(state.current.calendar?.provider)

  const addEvent = React.useCallback(() => {
    setState((draft) => {
      draft.current.events.push(createEvent())
    })
  }, [setState])

  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, valueAsNumber } = target
    setState((draft: any) => {
      switch (name) {
        case 'enabled':
          draft.current[name] = checked
          break
        case 'meetingName':
        case 'videoMeetingLink':
        case 'schedulingLink':
          draft.current[name] = value
          break
        case 'dateRange':
          draft.current[name] = valueAsNumber
          break
      }
    })
  }

  const handleRequestDefault = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    setState((draft) => void (draft.current.meetingRequestDefault = value as MeetingRequestSetting))
  }

  const handleDuration = (val: string) => {
    setState((draft) => {
      draft.current.meetingDurationMins = Number(val)
    })
  }

  const handleDays = (id: string, val: (string | number | undefined)[]) => {
    const byDayOfWeek = val.filter(Boolean).map(String) as DayOfWeek[]
    setState((draft) => {
      const idx = draft.current.events?.findIndex((e) => e.id === id)
      draft.current.events[idx].byDayOfWeek = byDayOfWeek
    })
  }

  const handleTime = (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setState((draft) => {
      const idx = draft.current.events.findIndex((e) => e.id === id)
      draft.current.events[idx][name as 'startTime' | 'endTime'] = value
    })
  }

  const handleCalendar = (name: string, value: any) => {
    setState((draft: any) => {
      draft.current.calendar = draft.current.calendar || {}
      draft.current.calendar[name] = value
    })
  }

  const [toRemove, setToRemove] = React.useState<string>()
  const handleRemove = () => {
    setState((draft) => {
      const idx = draft.current.events.findIndex((e) => e.id === toRemove)
      draft.current.events.splice(idx, 1)
      if (!draft.current.events.length) {
        draft.current.events.push(createEvent())
      }
    })
    setToRemove(undefined)
    removeDisclosure.onClose()
  }

  const updateSelf = useGraphqlMutation(UpdateSelfDocument)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const { events, ...meetingSettings } = state.current

    const data = { meetingSettings } as UpdateSelfInput

    // check we have the event days filled in because we can't use
    // html validation on CheckboxGroup
    if (isInternalCalendar(meetingSettings.calendar?.provider) && events.some((e) => !e.byDayOfWeek.length)) {
      return Alert.warning('Please select at least one available day')
    }

    // set this to a string if its not an internal calendar as its required
    // but we don't want to be validating here because they can't see it
    const ical = isInternalCalendar(meetingSettings.calendar?.provider)
      ? toIcal(events, meetingSettings?.calendar?.timeZone || localTimeZone)
      : ''

    set(data, 'meetingSettings.availabilityBlockIcal', ical)

    try {
      await updateSelf.mutateAsync({ data })

      if (!state.current.enabled) {
        Alert.info('Your settings are saved. Please enable them before requesting meeting bookings.')
      } else {
        Alert.success('Meeting Settings Saved')
      }
      window.scroll({ top: 0, behavior: 'smooth' })
    } catch (err) {
      Alert.error(err)
    }
  }

  const isDirty = React.useMemo(() => !dequal(state.current, state.original), [state])

  const reset = React.useCallback(
    () => setState((draft) => void (draft.current = cloneDeep(draft.original))),
    [setState]
  )

  // if state is dirty scroll to buttons
  const scrollRef = React.useRef<any>()
  React.useEffect(() => {
    if (isDirty && scrollRef.current) {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
      }, 500)
    }
  }, [isDirty])

  const options = [
    { label: 'Mon', value: DayOfWeek.MO },
    { label: 'Tue', value: DayOfWeek.TU },
    { label: 'Wed', value: DayOfWeek.WE },
    { label: 'Thu', value: DayOfWeek.TH },
    { label: 'Fri', value: DayOfWeek.FR },
    { label: 'Sat', value: DayOfWeek.SA },
    { label: 'Sun', value: DayOfWeek.SU },
  ]

  const labelProps = {
    fontSize: 'md',
    fontWeight: 350,
    color: 'atomicGray.600',
    gap: 1,
    m: 0,
    sx: {
      '& > span': {
        ml: 0,
      },
    },
  }

  return (
    <>
      <ZCard
        variant="form"
        {...rest}
      >
        <ZCardHeader
          p={8}
          pb={0}
          justifyContent="space-between"
        >
          Meeting Settings
          <Box>
            <UiFormControl
              display="flex"
              id="enabled"
              alignItems="center"
            >
              <ZFormLabel
                fontSize="lg"
                color="atomicGray.600"
                m={0}
              >
                <UiTooltip
                  label="Enable or disable scheduling a meeting when sending an item"
                  placement="top"
                  shouldWrapChildren
                >
                  {state.current.enabled ? 'Enabled' : 'Disabled'}
                </UiTooltip>
              </ZFormLabel>
              <UiToggle
                size="lg"
                ml={2}
                name="enabled"
                colorScheme="atomicBlue"
                isChecked={!!state.current.enabled}
                onChange={handleInput}
              />
            </UiFormControl>
          </Box>
        </ZCardHeader>

        <form onSubmit={handleSubmit}>
          <Box p={8}>
            <ZText
              mb={4}
              fontSize="lg"
              color="atomicGray.600"
            >
              Choose Calendar Provider
            </ZText>
            <UserCalendarProviders
              selected={state.current?.calendar?.provider}
              onSelect={(provider) => handleCalendar('provider', provider)}
            />
          </Box>

          <Divider />

          {/* external calendars need to set these */}
          {!calendarProvider?.calendar.sync && (
            <SimpleGrid
              columns={2}
              spacing={8}
              p={8}
            >
              <UiFormControl
                id="schedulingLink"
                isRequired
              >
                <ZFormLabelWithTooltip
                  tooltipLabel={`The URL to your ${calendarProvider?.name} scheduling page`}
                  {...labelProps}
                >
                  {calendarProvider?.name} Link
                </ZFormLabelWithTooltip>

                <ZInput
                  name="schedulingLink"
                  value={state.current.schedulingLink ?? ''}
                  onChange={handleInput}
                />
              </UiFormControl>

              <UiFormControl
                id="meetingRequestDefault"
                isRequired
              >
                <ZFormLabelWithTooltip
                  tooltipLabel={MEETING_REQUEST_TOOLTIP}
                  tooltipProps={{
                    whiteSpace: 'break-spaces',
                  }}
                  {...labelProps}
                >
                  Default meeting requests
                </ZFormLabelWithTooltip>
                <ZSelect
                  onChange={handleRequestDefault}
                  value={state.current.meetingRequestDefault ?? ''}
                >
                  <option value={MeetingRequestSetting.No}>Off</option>
                  <option value={MeetingRequestSetting.RequireBefore}>Before Item Acceptance</option>
                  <option value={MeetingRequestSetting.AskAfter}>After Item Acceptance</option>
                </ZSelect>
              </UiFormControl>
            </SimpleGrid>
          )}

          {/* internal calendars need to set these */}
          {!!calendarProvider?.calendar.sync && (
            <>
              <SimpleGrid
                columns={2}
                spacing={8}
                p={8}
              >
                <UiFormControl
                  id="meetingName"
                  isRequired
                >
                  <ZFormLabelWithTooltip
                    tooltipLabel="The name of the meeting on your calendar"
                    {...labelProps}
                  >
                    Meeting name
                  </ZFormLabelWithTooltip>
                  <ZInput
                    name="meetingName"
                    value={state.current.meetingName ?? ''}
                    onChange={handleInput}
                  />
                </UiFormControl>
                <UiFormControl
                  id="videoMeetingLink"
                  isRequired
                >
                  <ZFormLabelWithTooltip
                    tooltipLabel="The URL to your video meetings"
                    {...labelProps}
                  >
                    Video Meeting link
                  </ZFormLabelWithTooltip>
                  <ZInput
                    name="videoMeetingLink"
                    value={state.current.videoMeetingLink ?? ''}
                    onChange={handleInput}
                  />
                </UiFormControl>
                <UiFormControl
                  id="meetingRequestDefault"
                  isRequired
                >
                  <ZFormLabelWithTooltip
                    tooltipLabel={MEETING_REQUEST_TOOLTIP}
                    tooltipProps={{
                      whiteSpace: 'break-spaces',
                    }}
                    {...labelProps}
                  >
                    Default meeting requests
                  </ZFormLabelWithTooltip>
                  <ZSelect
                    onChange={handleRequestDefault}
                    value={state.current.meetingRequestDefault ?? ''}
                  >
                    <option value={MeetingRequestSetting.No}>Off</option>
                    <option value={MeetingRequestSetting.RequireBefore}>Before Item Acceptance</option>
                    <option value={MeetingRequestSetting.AskAfter}>After Item Acceptance</option>
                  </ZSelect>
                </UiFormControl>
                <UiFormControl
                  id="meetingDurationMins"
                  isRequired
                >
                  <ZFormLabelWithTooltip
                    tooltipLabel="How long you want the meetings to last"
                    {...labelProps}
                  >
                    Meeting duration
                  </ZFormLabelWithTooltip>
                  <RadioGroup
                    name="meetingDurationMins"
                    value={
                      typeof state.current.meetingDurationMins === 'number'
                        ? String(state.current.meetingDurationMins)
                        : ''
                    }
                    height="40px"
                    onChange={handleDuration}
                  >
                    <HStack
                      spacing={4}
                      border="1px"
                      borderColor="atomicGray.200"
                      borderRadius="3px"
                      _hover={{ borderColor: 'atomicGray.300' }}
                      px={4}
                      height="40px"
                    >
                      <ZRadio
                        value="15"
                        isRequired={state.current.enabled}
                      >
                        15 min
                      </ZRadio>
                      <ZRadio
                        value="30"
                        isRequired={state.current.enabled}
                      >
                        30 min
                      </ZRadio>
                      <ZRadio
                        value="45"
                        isRequired={state.current.enabled}
                      >
                        45 min
                      </ZRadio>
                      <ZRadio
                        value="60"
                        isRequired={state.current.enabled}
                      >
                        60 min
                      </ZRadio>
                    </HStack>
                  </RadioGroup>
                </UiFormControl>
                <UiFormControl
                  id="dateRange"
                  isRequired
                >
                  <ZFormLabelWithTooltip
                    tooltipLabel="For example, a contact can only schedule a meeting up to 7 days out rather than 6 months out"
                    {...labelProps}
                  >
                    Max days in future
                  </ZFormLabelWithTooltip>
                  <ZInput
                    min={0}
                    type="number"
                    onChange={handleInput}
                    name="dateRange"
                    value={state.current.dateRange ?? ''}
                  />
                </UiFormControl>
                <UiFormControl
                  id="scheduleDayLeadTime"
                  isRequired
                >
                  <ZFormLabelWithTooltip
                    tooltipLabel="For example, a contact can only schedule a meeting the next day rather than the same day"
                    {...labelProps}
                  >
                    Day lead time
                  </ZFormLabelWithTooltip>
                  <ZInput
                    type="number"
                    min={0}
                    onChange={(e: any) => handleCalendar('scheduleDayLeadTime', e.target.valueAsNumber)}
                    name="scheduleDayLeadTime"
                    value={state.current.calendar?.scheduleDayLeadTime ?? ''}
                  />
                </UiFormControl>
                <UiFormControl
                  id="scheduleHourLeadTime"
                  isRequired
                >
                  <ZFormLabelWithTooltip
                    tooltipLabel="For example, a contact can only schedule a meeting an hour later rather than scheduling immediately"
                    {...labelProps}
                  >
                    Hour lead time
                  </ZFormLabelWithTooltip>
                  <ZInput
                    type="number"
                    min={0}
                    onChange={(e: any) => handleCalendar('scheduleHourLeadTime', e.target.valueAsNumber)}
                    name="scheduleHourLeadTime"
                    value={state.current.calendar?.scheduleHourLeadTime ?? ''}
                  />
                </UiFormControl>
                <UiFormControl
                  id="scheduleBuffer"
                  isRequired
                >
                  <ZFormLabelWithTooltip
                    tooltipLabel="Provides a buffer time between meetings in order to avoid back to back meetings"
                    {...labelProps}
                  >
                    Meeting buffer minutes
                  </ZFormLabelWithTooltip>
                  <ZInput
                    type="number"
                    min={0}
                    onChange={(e: any) => handleCalendar('scheduleBuffer', e.target.valueAsNumber)}
                    name="scheduleBuffer"
                    value={state.current.calendar?.scheduleBuffer ?? ''}
                  />
                </UiFormControl>
              </SimpleGrid>

              <Divider />

              <Box p={8}>
                <ZText
                  fontSize="lg"
                  color="atomicGray.600"
                >
                  Timezone
                </ZText>
                <ZText
                  mt={2}
                  fontSize="md"
                  display="flex"
                  alignItems="center"
                  color="atomicGray.600"
                >
                  <MdOutlineQueryBuilder
                    size="24px"
                    style={{ marginRight: '8px' }}
                  />
                  You are viewing times in <strong style={{ marginLeft: '4px' }}>{timeZone}</strong>. Your invitees will
                  see your availability in their local timezone.
                </ZText>
              </Box>
              <Box>
                <ZText
                  px={8}
                  display="flex"
                  alignItems="center"
                  fontSize="lg"
                  color="atomicGray.600"
                >
                  Available time blocks
                  <UiIconButton
                    title="Add a time block"
                    aria-label="Add a time block"
                    colorScheme="atomicGray"
                    color="inherit"
                    icon={<MdOutlineAdd size="24px" />}
                    variant="ghost"
                    onClick={addEvent}
                  />
                </ZText>

                <Wrap
                  spacing={8}
                  p={8}
                  pt={0}
                >
                  {state.current.events?.map((event, idx) => {
                    return (
                      <WrapItem
                        key={`${idx}-${event.id}`}
                        w="100%"
                      >
                        <Box
                          borderRadius="3px"
                          borderColor="atomicGray.100"
                          borderWidth="1px"
                          p={6}
                          key={event.id}
                          position="relative"
                          w="100%"
                        >
                          <UiIconButton
                            aria-label="delete"
                            icon={<MdOutlineDelete size="24px" />}
                            variant="ghost"
                            position="absolute"
                            onClick={() => {
                              setToRemove(event.id)
                              removeDisclosure.onOpen()
                            }}
                            top={0}
                            right={0}
                            colorScheme="atomicGray"
                            color="inherit"
                          />
                          <Wrap spacing={12}>
                            <WrapItem flex={1}>
                              <UiFormControl
                                h="100%"
                                display="flex"
                                flexDir="column"
                                justifyContent="space-between"
                              >
                                <ZFormLabel {...labelProps}>Days</ZFormLabel>
                                <CheckboxGroup
                                  size="md"
                                  value={(event.byDayOfWeek as string[]) || []}
                                  onChange={(val) => handleDays(event.id, val)}
                                >
                                  <HStack
                                    spacing={4}
                                    height="40px"
                                    display="flex"
                                    alignItems="center"
                                  >
                                    {options.map((option, idx) => {
                                      return (
                                        <ZCheckbox
                                          data-testid={`Day-${option.label}`}
                                          value={option.value}
                                          key={`${idx}-${option.value}`}
                                          colorScheme="atomicBlue"
                                        >
                                          <ZText color="atomicGray.600">{option.label}</ZText>
                                        </ZCheckbox>
                                      )
                                    })}
                                  </HStack>
                                </CheckboxGroup>
                              </UiFormControl>
                            </WrapItem>
                            <WrapItem>
                              <Grid
                                templateColumns="1fr 50px 1fr"
                                alignItems="center"
                              >
                                <UiFormControl
                                  id={`startTime-${idx}`}
                                  isRequired
                                >
                                  <ZFormLabel {...labelProps}>From</ZFormLabel>
                                  <ZInput
                                    aria-label={`startTime-${event.id}`}
                                    name="startTime"
                                    type="time"
                                    value={event.startTime ?? ''}
                                    onChange={(val: any) => handleTime(event.id, val)}
                                  />
                                </UiFormControl>
                                <MinusIcon
                                  mx="auto"
                                  mt={6}
                                />
                                <UiFormControl
                                  id={`endTime-${idx}`}
                                  isRequired
                                >
                                  <ZFormLabel {...labelProps}>To</ZFormLabel>
                                  <ZInput
                                    aria-label={`endTime-${event.id}`}
                                    name="endTime"
                                    type="time"
                                    value={event.endTime ?? ''}
                                    min={event.startTime || undefined}
                                    onChange={(val: any) => handleTime(event.id, val)}
                                  />
                                </UiFormControl>
                              </Grid>
                            </WrapItem>
                          </Wrap>
                        </Box>
                      </WrapItem>
                    )
                  })}
                </Wrap>
              </Box>
            </>
          )}

          <Box
            p={8}
            pt={0}
          >
            <Collapse in={isDirty}>
              <Flex
                alignItems="center"
                justifyContent="space-between"
              >
                <ZButton
                  size="md"
                  colorScheme="atomicBlue"
                  type="submit"
                  minW="200px"
                  justifyContent="center"
                >
                  Save
                </ZButton>
                <ZButton
                  size="md"
                  colorScheme="atomicGray"
                  variant="ghost"
                  onClick={reset}
                  minW="200px"
                  justifyContent="center"
                >
                  Reset
                </ZButton>
              </Flex>
            </Collapse>
          </Box>
        </form>
      </ZCard>

      <Box ref={scrollRef} />

      <UiConfirm
        title="Remove Date Set"
        isOpen={removeDisclosure.isOpen}
        onClose={removeDisclosure.onClose}
        onConfirm={handleRemove}
      >
        Are you sure you want to remove this date set?
      </UiConfirm>
    </>
  )
}
