import type { BoxProps } from '@chakra-ui/react'
import { Box, SimpleGrid } from '@chakra-ui/react'
import { objectCastInt, ZFormLabel, ZInput, ZLink, ZText } from '@postal-io/postal-ui'
import type { ElementConfig } from 'api'
import round from 'lodash/round'
import type { PropsWithChildren } from 'react'
import React, { useMemo } from 'react'
import type { DesignTemplateDispatch } from './designTemplateReducer'
import { HorizontalList } from './HorizontalList'

export interface ListItemProps {
  title: string
}
const ListItem: React.FC<PropsWithChildren<ListItemProps>> = ({ title, children }) => {
  return (
    <>
      <ZText
        as="span"
        fontWeight="semibold"
        fontSize="sm"
        color="gray.600"
      >
        {title}
      </ZText>
      <ZText as="span">{children}</ZText>
    </>
  )
}

interface StaticPositionProps {
  location: ElementConfig['location']
}
const StaticPosition: React.FC<StaticPositionProps> = ({ location }) => {
  return (
    <HorizontalList>
      <ListItem title="Position">
        {round(location.x, 2)}, {round(location.y, 2)}
      </ListItem>
      <ListItem title="Dimensions">
        {round(location.width, 2)}x{round(location.height, 2)}
      </ListItem>
    </HorizontalList>
  )
}

const MIN_HEIGHT = 10
const MIN_WIDTH = 10

interface EditablePositionProps {
  location: ElementConfig['location']
  boundary: ElementConfig['boundary']
  onChange: (location: ElementConfig['location']) => void
  canvasWidth: number
  canvasHeight: number
}

const EditablePosition: React.FC<EditablePositionProps> = ({
  location,
  boundary,
  onChange,
  canvasHeight,
  canvasWidth,
}) => {
  const { minX = 0, maxX = Infinity, minY = 0, maxY = Infinity } = boundary || {}

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, valueAsNumber } = e.target
    const newLocation = { ...location, [name]: valueAsNumber }
    onChange(objectCastInt(newLocation))
  }

  const handleFill = () => {
    onChange({ x: 0, y: 0, width: Math.ceil(canvasWidth), height: Math.ceil(canvasHeight) })
  }

  return (
    <HorizontalList>
      <ZFormLabel htmlFor="x">Position</ZFormLabel>

      <SimpleGrid
        columns={2}
        spacing={4}
      >
        <ZInput
          type="number"
          name="x"
          placeholder="x"
          value={location.x}
          onChange={handleInput}
          isRequired
          min={minX}
          max={maxX}
        />
        <ZInput
          type="number"
          name="y"
          placeholder="y"
          value={location.y}
          onChange={handleInput}
          isRequired
          min={minY}
          max={maxY}
        />
      </SimpleGrid>
      <ZFormLabel
        htmlFor="x"
        display="flex"
        flexDir="column"
        alignItems="flex-start"
      >
        Dimensions
        <ZLink
          size="xs"
          onClick={handleFill}
        >
          Fill Canvas
        </ZLink>
      </ZFormLabel>
      <SimpleGrid
        columns={2}
        spacing={4}
      >
        <ZInput
          type="number"
          name="width"
          placeholder="width"
          value={location.width}
          onChange={handleInput}
          isRequired
          min={MIN_WIDTH}
        />
        <ZInput
          type="number"
          name="height"
          placeholder="height"
          value={location.height}
          onChange={handleInput}
          isRequired
          min={MIN_HEIGHT}
        />
      </SimpleGrid>
    </HorizontalList>
  )
}

interface ElementLocationProps extends BoxProps {
  element: ElementConfig
  dispatch: (event: DesignTemplateDispatch) => void
  canvasWidth: number
  canvasHeight: number
}
export const ElementLocation: React.FC<ElementLocationProps> = ({
  element,
  dispatch,
  canvasWidth,
  canvasHeight,
  ...rest
}) => {
  const { location, boundary } = element || {}

  //  Can we move this element
  const isMovable = useMemo(() => {
    if (!location) return false
    if (!boundary) return true
    const { minX, maxX, minY, maxY } = boundary
    return !(location.x === minX && location.x === maxX && location.y === minY && location.y === maxY)
  }, [boundary, location])

  const handleChange = (newLocation: ElementConfig['location']) => {
    dispatch({ type: 'EDIT', payload: { ...element, location: newLocation } })
  }

  if (!element) return null

  return (
    <Box {...rest}>
      {isMovable ? (
        <EditablePosition
          location={location}
          boundary={boundary}
          onChange={handleChange}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
        />
      ) : (
        <StaticPosition location={location} />
      )}
    </Box>
  )
}
