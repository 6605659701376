import { AlertProvider } from '@postal-io/postal-ui'
import type * as Ably from 'ably/promises'
import { App } from 'components/App'
import { ErrorBoundary } from 'components/Common/ErrorBoundary'
import { BrandingProvider, SessionProvider } from 'hooks'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import React from 'react'
import ReactDOM from 'react-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import './api/query'
import { queryClient } from './api/query'

if (!window.navigator.userAgent.match(/Edge\//)) {
  const ENVIRONMENT = process.env.NODE_ENV || 'development'
  const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY
  const LAUNCH_DARKLY_KEY = process.env.REACT_APP_LAUNCH_DARKLY_KEY
  const VERSION = process.env.REACT_APP_TAG_NAME || process.env.REACT_APP_SHORT_SHA

  window.postalVersion = VERSION || ENVIRONMENT
  window.postalEnvironment = ENVIRONMENT

  const runDevtools = !window?.Cypress?.version && ENVIRONMENT !== 'production'

  const initLaunchDarkly = async () => {
    if (!LAUNCH_DARKLY_KEY) return React.Fragment
    return asyncWithLDProvider({
      clientSideID: LAUNCH_DARKLY_KEY,
      user: { key: 'anonymous' },
      options: { useReport: true },
    })
  }

  // TODO
  // We should switch to the new render, but this is breaking things.  Maybe
  // this is because of launch darkly - read the docs for react 18
  initLaunchDarkly().then((LDProvider) => {
    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <BrandingProvider>
          <ErrorBoundary>
            <SessionProvider>
              <AlertProvider>
                <GoogleReCaptchaProvider
                  reCaptchaKey={RECAPTCHA_KEY ?? ''}
                  scriptProps={{ nonce: '7e6d36ae12ce81578f3f61fb0b871750' }}
                >
                  <LDProvider>
                    <App />
                  </LDProvider>
                </GoogleReCaptchaProvider>
              </AlertProvider>
            </SessionProvider>
          </ErrorBoundary>
        </BrandingProvider>
        {runDevtools && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>,
      document.getElementById('root')
    )
  })
}

declare global {
  interface Window {
    analytics: any
    google: any
    Ably: Ably.Realtime
    postalVersion: string
    postalEnvironment: string
    Cypress: any
  }
}
