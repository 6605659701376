import type { ImageProps } from '@chakra-ui/react'
import { Box, Image } from '@chakra-ui/react'
import { UiLink, UiSkeleton } from '@postal-io/postal-ui'
import type { ApprovedPostal } from 'api'
import { CATEGORY } from 'components/Postals'
import { usePrintPreview } from 'hooks'
import React, { useMemo, useState } from 'react'
import { ExtPostalImage } from './ExtPostalImage'

interface ExtPostalPreviewProps extends ImageProps {
  postal?: ApprovedPostal
  message?: string
}
export const ExtPostalPreview: React.FC<ExtPostalPreviewProps> = ({ postal, message, ...rest }) => {
  const { front, back } = usePrintPreview(postal?.designTemplate, {
    userMessage: message,
  })
  const [side, setSide] = useState('front')

  const styles = {
    w: '100%',
    mx: 'auto',
    boxShadow: 'md',
    maxH: { base: '300px', md: '350px', lg: '400px' },
  }

  const currentSrc = useMemo(() => {
    return (side === 'front' ? front : back) || ''
  }, [back, side, front])

  if (!postal) return null

  if (postal.category === CATEGORY.DirectMail) {
    if (!currentSrc)
      return (
        <UiSkeleton
          isLoaded={false}
          {...rest}
        />
      )
    return (
      <Box>
        <Image
          src={currentSrc}
          objectFit="contain"
          {...styles}
          {...rest}
        />
        <Box
          w="100%"
          textAlign="center"
          mt={2}
        >
          {side === 'front' ? (
            <UiLink onClick={() => setSide('back')}>Show Back</UiLink>
          ) : (
            <UiLink onClick={() => setSide('front')}>Show Front</UiLink>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <ExtPostalImage
      postal={postal}
      objectFit="contain"
      {...styles}
      {...rest}
    />
  )
}
