import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { GetLocationDataDocument } from 'api'
import React, { useEffect, useRef } from 'react'
import type { StateInputProps } from './StateInput'
import { StateInput } from './StateInput'

export const AutoCompleteState: React.FC<StateInputProps> = ({ onChange, value, ...rest }) => {
  const { data } = useGraphqlQuery(GetLocationDataDocument, undefined, { enabled: !value })
  const { regionName, regionCode } = data?.getLocationData || {}

  const autoFilled = useRef(!!value)

  useEffect(() => {
    if (regionName && regionCode && !value && !autoFilled.current) {
      autoFilled.current = true
      onChange?.(regionCode)
    }
  }, [onChange, regionCode, regionName, value])

  return (
    <StateInput
      value={value}
      onChange={onChange}
      {...rest}
    />
  )
}
