import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlertError } from '@postal-io/postal-ui'
import { isNumber } from 'lodash'
import { useCallback, useMemo } from 'react'
import { GetAccountDocument } from '../api'
import { useHelp } from '../hooks'

export const useHasSeats = () => {
  const accountQuery = useGraphqlQuery(GetAccountDocument)

  const { seats, seatsAssigned } = useMemo(() => {
    const account = accountQuery.data?.getAccount
    return { seats: account?.seats, seatsAssigned: account?.seatsAssigned }
  }, [accountQuery.data?.getAccount])

  const seatsAvailable = useMemo(() => {
    if (isNumber(seats) && isNumber(seatsAssigned)) {
      return seats - seatsAssigned
    }
  }, [seats, seatsAssigned])

  const hasUserSeats = useMemo(() => {
    return isNumber(seatsAvailable) ? seatsAvailable > 0 : true
  }, [seatsAvailable])

  const hasMoreSeats = useCallback(
    (users = 1) => {
      return isNumber(seatsAvailable) ? seatsAvailable >= users : true
    },
    [seatsAvailable]
  )

  const { sendMessage } = useHelp()
  const requestSeats = () => sendMessage(`I'd like to invite more users to my team.`)

  useAlertError(accountQuery.error)

  return {
    isLoading: accountQuery.isLoading,
    seats,
    seatsAssigned,
    seatsAvailable,
    hasUserSeats,
    hasMoreSeats,
    requestSeats,
  }
}
