import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, SimpleGrid, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { createId } from '@paralleldrive/cuid2'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { Column } from '@postal-io/postal-ui'
import {
  FontWeight,
  internalProgressBarProps,
  internalTableProps,
  UiCheckboxIcon,
  UiDataTable,
  UiDate,
  UiTruncate,
  ZButton,
  ZCard,
  ZCheckbox,
  ZHeading,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZMoney,
  ZTag,
  ZText,
} from '@postal-io/postal-ui'
import type { PostalFulfillment } from 'api'
import {
  BulkApproveOrdersDocument,
  CancelOrderDocument,
  FulfillmentStatus,
  GetAccountDocument,
  GetTeamByIdDocument,
  GetUserDocument,
} from 'api'
import { useAcl, useBackgroundQueue, useMe } from 'hooks'
import { statusMapper } from 'lib'
import { isDate } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { MessagePreview } from '../Common'
import { UserLinkV2 } from '../Reporting'

const questionColumns: Column[] = [
  { label: 'Question', render: ({ field }) => <Text>{field}</Text>, headerProps: { w: '30%' } },
  {
    label: 'Answer',
    render: ({ value }: any) => {
      if (isDate(value)) return <UiDate date={value} />
      if (value === 'true' || value === 'false' || value === true || value === false) {
        return <UiCheckboxIcon isChecked={value} />
      }
      return <Text>{value}</Text>
    },
  },
]

const columns: Column[] = [
  {
    label: 'Date',
    render: ({ created }: any) => (
      <UiDate
        date={created}
        fallback=""
      />
    ),
    headerProps: { w: '30%' },
  },
  {
    label: 'Notes',
    render: ({ notes }: any) => (
      <Text
        whiteSpace="normal"
        data-private
      >
        {notes}
      </Text>
    ),
  },
]

const STATUS_COLOR_MAP: { [key: string]: string } = {
  SUCCESS: 'green.500',
  INFO: 'blue.500',
  ERROR: 'red.500',
}

const CANCEL_STATUS = [FulfillmentStatus.PostalDeliveryEmail, FulfillmentStatus.PostalAddressRefresh]

interface PostalFulFillmentCardProps extends BoxProps {
  postalFulfillment: PostalFulfillment
  onRetryComplete?: () => void
  onComplete?: () => void
}

export const PostalFulfillmentCard: React.FC<PostalFulFillmentCardProps> = ({
  postalFulfillment,
  onRetryComplete,
  onComplete,
  ...rest
}) => {
  const {
    history: statusHistory,
    status,
    itemName,
    cost,
    userLink,
    userId,
    teamId,
    deliveryEmail,
    giftMessage,
    trackingNumber,
    trackingUrl,
    collectionName,
    deliveryDate,
    id,
    emailSubjectLine,
    physicalMessage,
    magicLinkId,
    thankYouMessage,
    sendAs,
    spendAs,
    sendAsUserId,
    formFieldValueList,
  } = postalFulfillment

  const navigate = useNavigate()
  const cancelPostal = useDisclosure()
  const { hasPermission, hasFeature } = useAcl()

  const canSend = hasPermission('postals.send')
  const canApproveLinks = hasFeature('manuallyApproveLinks')
  const canSpendAs = hasFeature('budgetDropDown')

  const canRetry = useMemo(() => {
    return canSend && status !== FulfillmentStatus.ServerErrorBackoff
  }, [canSend, status])

  const canCancel = useMemo(() => {
    return canSend && CANCEL_STATUS.includes(status)
  }, [canSend, status])

  const enhancedHistory = useMemo(
    () =>
      statusHistory?.map((h) => ({
        ...h,
        id: createId(),
        status: h.notes || statusMapper(h.status).text,
      })) || [],
    [statusHistory]
  )

  const questionRows = useMemo(() => {
    return formFieldValueList?.map((field) => ({ ...field, id: createId() })).filter((field) => !!field.value) || []
  }, [formFieldValueList])

  const mappedStatus = statusMapper(status)

  const cancelOrder = useGraphqlMutation(CancelOrderDocument)

  const handleGoToOrder = () => {
    const path = deliveryEmail ? `/orders/emails/${id}` : `/orders/direct/${id}`
    navigate(path)
  }

  const handleCancelItem = async () => {
    cancelPostal.onClose()
    await cancelOrder.mutateAsync({ id })
    //Closes modal
    onComplete && onComplete()
  }

  const { queue } = useBackgroundQueue()

  const approveOrders = useGraphqlMutation(BulkApproveOrdersDocument)

  const handleApproveOrder = useCallback(() => {
    approveOrders
      .mutateAsync({ fulfillmentIds: [id] })
      .then((d) => queue(d.bulkApproveOrders))
      .then(() => onComplete && onComplete())
  }, [approveOrders, queue, id, onComplete])

  const me = useMe()
  const team = useGraphqlQuery(
    GetTeamByIdDocument,
    { id: spendAs?.teamId || (teamId as string) },
    { enabled: !!spendAs?.teamId || !!teamId }
  )
  const getAccount = useGraphqlQuery(GetAccountDocument, undefined, { enabled: !spendAs?.teamId && !teamId })
  const teamName = useMemo(() => {
    return !!spendAs?.teamId || !!teamId ? team?.data?.getTeamById?.name : getAccount?.data?.getAccount?.name
  }, [getAccount?.data?.getAccount?.name, spendAs?.teamId, team?.data?.getTeamById?.name, teamId])

  const user = useGraphqlQuery(
    GetUserDocument,
    { id: spendAs?.userId as string },
    {
      enabled: !!spendAs?.userId && spendAs?.userId !== me?.me?.id,
    }
  )
  const userName = useMemo(
    () => user?.data && `${user?.data?.getUser?.firstName} ${user?.data?.getUser?.lastName}`,
    [user]
  )

  const isLoading = !postalFulfillment

  return (
    <>
      <ZCard
        mb={{ base: 2, md: 4 }}
        w="100%"
        isLoading={isLoading}
        variant="form"
        {...rest}
      >
        <Stack spacing={8}>
          <Flex
            alignItems="center"
            bg="atomicGray.10"
            borderTopLeftRadius="10px"
            borderTopRightRadius="10px"
            flexWrap="wrap"
          >
            <SimpleGrid
              columns={{ base: 1, lg: 2 }}
              width="100%"
              spacingY={2}
              p={4}
            >
              <ZHeading
                flexWrap="wrap"
                mb={0}
                display="flex"
                alignItems="center"
                size="h5"
                fontWeight="bold"
                textAlign={{ base: 'center', lg: 'left' }}
                p={4}
              >
                <UiTruncate
                  length={60}
                  text={itemName}
                  showTooltip
                  w="100%"
                />
              </ZHeading>
              <Flex
                alignItems="center"
                justifyContent={{ base: 'center', lg: 'flex-end' }}
              >
                <ZMoney
                  color="atomicGray.500"
                  fontWeight="bold"
                  fontSize="xl"
                  cents={cost?.total || 0}
                  currency={cost?.currency}
                />
                <ZTag
                  ml={8}
                  colorScheme="atomicBlue"
                  whiteSpace="nowrap"
                  bg={STATUS_COLOR_MAP[mappedStatus.type]}
                  size="sm"
                  fontWeight="bold"
                  px={4}
                >
                  {mappedStatus.text}
                </ZTag>
                {collectionName && (
                  <ZTag
                    ml={4}
                    backgroundColor="white"
                    color="atomicGray.500"
                    borderColor="atomicGray.500"
                    border="2px"
                    whiteSpace="nowrap"
                    bg={STATUS_COLOR_MAP[mappedStatus.type]}
                    size="sm"
                    fontWeight="bold"
                    px={4}
                  >
                    Collection
                  </ZTag>
                )}
              </Flex>
            </SimpleGrid>

            {(canRetry || canCancel) && (
              <Flex
                p={4}
                justifyContent="center"
                w="100%"
              >
                <ZButton
                  size="md"
                  colorScheme="atomicBlue"
                  onClick={handleGoToOrder}
                >
                  Go to Order Details
                </ZButton>
                {canCancel && (
                  <ZButton
                    variant="ghost"
                    ml={2}
                    size="md"
                    colorScheme="atomicRed"
                    onClick={cancelPostal.onOpen}
                  >
                    Cancel Order
                  </ZButton>
                )}
                {magicLinkId && canApproveLinks && status === FulfillmentStatus.OrderDenied && (
                  <ZButton
                    ml={2}
                    size="md"
                    colorScheme="atomicBlue"
                    onClick={handleApproveOrder}
                  >
                    Approve Order
                  </ZButton>
                )}
              </Flex>
            )}
          </Flex>

          <Box>
            <ZText
              fontSize="md"
              fontWeight="bold"
              px={8}
            >
              Order History
            </ZText>
            <UiDataTable
              columns={columns}
              rows={enhancedHistory}
              rowKey="id"
              variant="list"
              w="100%"
              tableProps={internalTableProps}
              progressBarProps={internalProgressBarProps}
              SelectCheckbox={ZCheckbox}
              HeaderButton={ZButton}
              p={8}
            />
          </Box>

          {questionRows?.length && (
            <Box>
              <ZText
                fontSize="md"
                color="gray.700"
                fontWeight={FontWeight.Bold}
                px={8}
              >
                Form Values
              </ZText>
              <UiDataTable
                columns={questionColumns}
                rows={questionRows}
                rowKey="id"
                variant="list"
                w="100%"
                tableProps={internalTableProps}
                progressBarProps={internalProgressBarProps}
                SelectCheckbox={ZCheckbox}
                HeaderButton={ZButton}
                p={4}
              />
            </Box>
          )}

          {/* checking deliveryEmail prevents showing the message if it was saved to the Postal but not used */}
          {/* one exception is MagicLink where deliveryEmail is false but we still using giftMessage for landing page */}
          {((deliveryEmail && giftMessage) || (magicLinkId && giftMessage)) && (
            <Box>
              <ZText
                fontSize="md"
                fontWeight="bold"
                px={8}
              >
                Email Message
              </ZText>
              <ZText px={8}>
                <MessagePreview
                  message={giftMessage}
                  fontWeight="normal"
                />
              </ZText>
            </Box>
          )}

          {emailSubjectLine && (
            <Box>
              <ZText
                fontSize="md"
                fontWeight="bold"
                px={8}
              >
                Email Subject Line
              </ZText>
              <ZText px={8}>
                <MessagePreview
                  message={emailSubjectLine}
                  fontWeight="normal"
                />
              </ZText>
            </Box>
          )}

          {physicalMessage && (
            <Box>
              <ZText
                fontSize="md"
                fontWeight="bold"
                px={8}
              >
                Physical Message
              </ZText>
              <ZText px={8}>
                <MessagePreview message={physicalMessage} />
              </ZText>
            </Box>
          )}

          {thankYouMessage && (
            <Box>
              <ZText
                fontSize="md"
                fontWeight="bold"
                p={4}
              >
                Thank You Message
              </ZText>
              <ZText px={8}>
                <MessagePreview message={thankYouMessage} />
              </ZText>
            </Box>
          )}

          <Flex
            justify="space-between"
            bg="atomicGray.10"
            p={8}
            flexWrap="wrap"
            style={{ gap: '1rem' }}
          >
            <Flex>
              <ZText
                mr={2}
                fontWeight="bold"
                color="atomicGray.500"
              >
                Est. Delivery:
              </ZText>
              <ZText>
                <UiDate
                  date={deliveryDate}
                  fallback="Unknown"
                />
              </ZText>
            </Flex>

            {trackingNumber && (
              <Flex>
                <ZText
                  mr={2}
                  fontWeight="bold"
                >
                  Tracking number:
                </ZText>

                {trackingUrl ? (
                  <ZLink
                    mr={2}
                    fontWeight="bold"
                    href={trackingUrl}
                    isExternal
                  >
                    {trackingNumber}
                  </ZLink>
                ) : (
                  <ZText>{trackingNumber}</ZText>
                )}
              </Flex>
            )}

            {userLink?.fullName && (
              <Flex alignItems="baseline">
                <ZText
                  mr={2}
                  fontWeight="bold"
                  color="atomicGray.500"
                >
                  Owner:
                </ZText>
                <ZText color="atomicBlue.400">
                  <UserLinkV2
                    id={userId}
                    name={userLink.fullName}
                  />
                </ZText>
              </Flex>
            )}

            {sendAs?.fullName && (
              <Flex alignItems="baseline">
                <ZText
                  mr={2}
                  fontWeight="bold"
                  color="atomicGray.500"
                >
                  Sent As:
                </ZText>
                <ZText color="atomicBlue.400">
                  <UserLinkV2
                    id={sendAsUserId}
                    name={sendAs.fullName}
                  />
                </ZText>
              </Flex>
            )}

            {!!spendAs && canSpendAs && (
              <Flex alignItems="baseline">
                <ZText
                  mr={2}
                  fontWeight="bold"
                  color="atomicGray.500"
                >
                  Spent {userName ? 'As' : 'From'}:
                </ZText>
                {userName && (
                  <>
                    <ZText color="atomicBlue.400">
                      <UserLinkV2
                        id={spendAs?.userId}
                        name={userName}
                      />
                    </ZText>
                    <ZText mx={1}>from</ZText>
                  </>
                )}
                <ZText
                  fontWeight="bold"
                  fontSize="md"
                >
                  {teamName}
                </ZText>
              </Flex>
            )}
          </Flex>
        </Stack>
      </ZCard>
      {cancelPostal.isOpen && (
        <ZModal
          isOpen={cancelPostal.isOpen}
          onClose={cancelPostal.onClose}
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Cancel Order</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody textAlign="center">
              <ZText>Are you sure you want to cancel this order?</ZText>
            </ZModalBody>
            <ZModalButtons
              onConfirm={handleCancelItem}
              onClose={cancelPostal.onClose}
              cancelText="Close"
              confirmText="Cancel Order"
              confirmProps={{
                colorScheme: 'atomicRed',
              }}
            />
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}
