import type { BoxProps } from '@chakra-ui/react'
import { Box, Collapse, Fade, Text } from '@chakra-ui/react'
import { FontWeight, ZLink } from '@postal-io/postal-ui'
import { Role } from 'api'
import { useAcl } from 'hooks'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useNeedsApproval } from './useNeedsApproval'

interface NeedsApprovalBannerProps extends BoxProps {
  magiclinkId?: string
  limit?: number
  ownerId?: string
}

export const NeedsApprovalBanner: React.FC<NeedsApprovalBannerProps> = ({ magiclinkId, ownerId, ...rest }) => {
  const { hasFeature, hasRole } = useAcl()
  const canApproveLinks = hasFeature('manuallyApproveLinks') && hasRole(Role.User)
  const navigate = useNavigate()
  const { totalNeedsAction } = useNeedsApproval(magiclinkId, ownerId)

  const needsActionMessage = `${magiclinkId ? 'This link has' : 'You have'} ${totalNeedsAction} order${
    totalNeedsAction > 1 ? 's' : ''
  } that require${totalNeedsAction <= 1 ? 's' : ''} approval.`

  const handleReviewOrders = () =>
    navigate('/links/approval', {
      state: {
        returnToPath: '/links',
        returnToText: 'Links',
      },
    })

  return (
    <>
      <Collapse in={canApproveLinks && totalNeedsAction > 0}>
        <Fade in>
          <Box
            p={8}
            mb={8}
            sx={{
              'background': 'transparent',
              'border': '2px solid #FFECCC',
              'borderRadius': '8px',
              '& .chakra-alert__desc': {
                width: '100%',
              },
            }}
            {...rest}
          >
            <Box
              display="flex"
              alignItems="center"
              width="100%"
            >
              <Box flex={1}>
                <Text fontWeight={FontWeight.Bold}>{needsActionMessage}</Text>
              </Box>
              <ZLink
                fontWeight="normal"
                onClick={handleReviewOrders}
                color="atomicYellow.800"
              >
                Review Now
              </ZLink>
            </Box>
          </Box>
        </Fade>
      </Collapse>
    </>
  )
}
