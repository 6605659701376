import type { BoxProps } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  useAlerts,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
} from '@postal-io/postal-ui'
import { BudgetDuration, BudgetMode, CreateTeamDocument } from 'api'
import { AnalyticsEvent, useAnalyticsSend } from 'hooks'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import type { TeamSettingsStateV2 } from './TeamSettings'
import { budgetOutV2, TeamSettings } from './TeamSettings'

interface TeamCreateProps extends BoxProps {
  isOpen: boolean
  onClose: () => void
  onComplete?: () => void
}

export const TeamCreate: React.FC<TeamCreateProps> = ({ isOpen, onClose, onComplete }) => {
  const Alert = useAlerts()
  const [state, setState] = useImmer<TeamSettingsStateV2>({
    name: '',
    department: '',
    budgetMode: BudgetMode.Unlimited,
  })
  const navigate = useNavigate()
  const sendAnalytics = useAnalyticsSend()

  const createTeam = useGraphqlMutation(CreateTeamDocument, {
    onSuccess: () => sendAnalytics({ event: AnalyticsEvent.TeamCreated }),
  })

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!state.billingAccount || !state.name) {
      Alert.warning('Name and Billing Account are required')
      return
    }
    const data = {
      name: state.name,
      department: state.department || '',
      settings: {
        billingAccountIds: [state.billingAccount],
        budget: {
          duration: state.budgetDuration || BudgetDuration.Monthly,
          amount: budgetOutV2(state.budgetAmount),
          mode: state.budgetMode || BudgetMode.Unlimited,
        },
      },
    }
    try {
      await createTeam.mutateAsync({ data })
      Alert.success(`${data.name} Created`)
      onClose()
      onComplete ? onComplete() : navigate('/teams')
    } catch (error) {
      Alert.error(error)
    }
  }

  return (
    <>
      <ZModal
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ZModalOverlay />
        <ZModalContent>
          <form onSubmit={handleSubmit}>
            <ZModalHeader>Create Team</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <TeamSettings
                isLoading={createTeam.isLoading}
                state={state}
                setState={setState}
              />
            </ZModalBody>
            <ZModalButtons
              confirmText="Create Team"
              isConfirmLoading={createTeam.isLoading}
              isConfirmDisabled={createTeam.isLoading}
              confirmProps={{
                type: 'submit',
              }}
              onClose={onClose}
            />
          </form>
        </ZModalContent>
      </ZModal>
    </>
  )
}
