import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { humanize, ZButton, ZCard, ZModal, ZModalOverlay, ZText } from '@postal-io/postal-ui'
import type { BillingAccount, PartnerPaymentMethod } from 'api'
import { AnalyticsEventV2, useAnalyticsSend } from 'hooks'
import React from 'react'
import { EditCreditCardStripe } from './EditCreditCardStripe'

enum CardBrand {
  Visa = 'visa',
  Mastercard = 'mastercard',
  AmericanExpress = 'amex',
  Discover = 'discover',
  DinersClub = 'diners',
  JCB = 'jcb',
  UnionPay = 'unionpay',
}

const getHumanizedCardBrand = (card?: string | null) => {
  if (card === CardBrand.AmericanExpress) return 'American Express'
  if (card === CardBrand.DinersClub) return 'Diners Club'
  if (card === CardBrand.JCB) return 'JCB'
  if (card === CardBrand.UnionPay) return 'UnionPay'
  return humanize(card ?? 'Credit Card')
}

interface PaymentMethodComponentProps extends BoxProps {
  billingAccount: BillingAccount
  partnerPaymentMethod?: PartnerPaymentMethod
  onUpdate?: () => void
}
export const PaymentMethodComponent: React.FC<PaymentMethodComponentProps> = ({
  billingAccount,
  partnerPaymentMethod,
  onUpdate,
  ...rest
}) => {
  const sendAnalytics = useAnalyticsSend()

  const editCard = useDisclosure()
  const handleOnComplete = () => {
    editCard.onClose()
    onUpdate && onUpdate()
  }

  let paymentName: string | null | undefined
  let paymentHiddenNumber: string | null | undefined

  const card = partnerPaymentMethod?.card
  if (card) {
    paymentName = getHumanizedCardBrand(card?.brand)
    // amex has a 15 digit pattern
    paymentHiddenNumber =
      card?.brand === CardBrand.AmericanExpress ? `**** ****** *${card?.last4}` : `**** **** **** ${card?.last4}`
  }

  return (
    <>
      <Box {...rest}>
        <ZCard
          isLoading={!billingAccount}
          variant="form"
        >
          <ZText
            fontSize="md"
            fontWeight="bold"
            p={4}
            pb={0}
          >
            {paymentName || 'No Payment Method Available'}
          </ZText>
          <Flex
            gap={8}
            flexDirection="column"
          >
            <ZText
              fontSize="md"
              p={4}
              data-private
            >
              {paymentHiddenNumber}
            </ZText>
            <ZButton
              variant="link"
              color="atomicBlue.400"
              _hover={{ color: 'atomicBlue.600' }}
              size="sm"
              onClick={() => {
                sendAnalytics({ event: AnalyticsEventV2.Billing_ChangePaymentMethod })
                editCard.onToggle()
              }}
              alignSelf="flex-end"
              p={4}
            >
              Change Payment Method
            </ZButton>
          </Flex>
        </ZCard>
      </Box>
      <ZModal
        size="xl"
        isOpen={editCard.isOpen}
        onClose={editCard.onClose}
      >
        <ZModalOverlay />
        <EditCreditCardStripe
          billingAccount={billingAccount}
          onComplete={handleOnComplete}
          onClose={editCard.onClose}
        />
      </ZModal>
    </>
  )
}
