import { NotAllowedIcon } from '@chakra-ui/icons'
import { Button, Icon, Tag, TagLabel, TagLeftIcon, useDisclosure } from '@chakra-ui/react'
import type { UiCardProps, ZCardProps } from '@postal-io/postal-ui'
import { useAlerts, ZCard } from '@postal-io/postal-ui'
import type { IntegrationSync } from 'api'
import { DataObjectType, Status } from 'api'
import api from 'api/rest'
import React, { useMemo, useState } from 'react'
import { MdCompareArrows, MdDone, MdEdit } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import type { ExternalProvider } from './data'
import { ExternalSystem } from './data'
import { MarketoIntegration } from './MarketoIntegration'

interface IntegrationCardV2Props extends ZCardProps {
  provider: ExternalProvider
  isAuthenticated: boolean
  isLoading: boolean
  integrationSyncs: IntegrationSync[]
  onClick: () => void
}

export const IntegrationCardV2: React.FC<IntegrationCardV2Props> = ({
  provider,
  isAuthenticated,
  isLoading,
  integrationSyncs,
  onClick,
  ...rest
}) => {
  const userIntegration = useMemo(() => {
    return integrationSyncs.find((i) => [DataObjectType.Product, DataObjectType.User].includes(i.objectType))
  }, [integrationSyncs])

  const isActive = useMemo(() => userIntegration?.status === Status.Active, [userIntegration?.status])
  const isDisabled = !provider?.integration?.enabled
  const opacity = !isDisabled ? 1 : 0.8
  const handleEdit = async () => !isDisabled && onClick()

  return isDisabled || userIntegration || isAuthenticated ? (
    <ZCard
      isLoading={isLoading}
      opacity={opacity}
      variant="form"
      alignItems="center"
      {...rest}
    >
      {!isDisabled && isActive ? (
        <Tag
          mt="30px"
          size="md"
          bg="vendorGreen.600"
          borderRadius="full"
        >
          <TagLeftIcon
            bg="white"
            borderRadius="full"
            color="vendorGreen.600"
            as={MdDone}
          />
          <TagLabel
            fontSize="12px"
            fontWeight="bold"
            color="white"
          >
            Enabled
          </TagLabel>
        </Tag>
      ) : (
        <Tag
          mt="30px"
          size="md"
          bg="atomicGray.50"
          borderRadius="full"
        >
          <TagLeftIcon
            color="atomicGray.700"
            as={NotAllowedIcon}
          />
          <TagLabel
            fontSize="12px"
            fontWeight="bold"
            color="atomicGray.700"
          >
            {isDisabled ? 'Coming Soon' : isActive ? 'Enabled' : 'Disabled'}
          </TagLabel>
        </Tag>
      )}
      <Icon
        as={provider.icon}
        fontSize="125px"
        display="block"
        mx="auto"
      />
      <Button
        m={8}
        mt={0}
        variant="solid"
        leftIcon={!isDisabled ? <MdEdit size="16px" /> : <NotAllowedIcon color="inherit" />}
        size="md"
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={handleEdit}
      >
        {isDisabled ? 'Coming Soon' : 'Edit'}
      </Button>
    </ZCard>
  ) : (
    <PreConnectCardV2
      isLoading={isLoading}
      provider={provider}
      {...rest}
    />
  )
}

interface PreConnectCardV2Props extends UiCardProps {
  provider: ExternalProvider
  isLoading: boolean
}

export const PreConnectCardV2: React.FC<PreConnectCardV2Props> = ({
  provider,
  isLoading,
  ...rest
}: PreConnectCardV2Props) => {
  const Alert = useAlerts()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const isMarketo = provider.system === ExternalSystem.Marketo
  const showMarketo = useDisclosure()

  const integrate = () => {
    return isMarketo ? showMarketo.onOpen() : integrateOath()
  }

  const integrateMarketo = () => {
    showMarketo.onClose()
    navigate(`/integrations/${provider.system}`)
  }

  const integrateOath = async () => {
    try {
      setLoading(true)
      await api.oathPreconnect(provider.system).then((res: any) => (window.location.href = res?.target))
    } catch (e) {
      setLoading(false)
      Alert.error(e)
    }
  }

  return (
    <>
      <ZCard
        isLoading={isLoading}
        variant="form"
        alignItems="center"
        {...rest}
      >
        <Tag
          mt="30px"
          size="md"
          bg="atomicGray.50"
          borderRadius="full"
        >
          <TagLeftIcon
            color="atomicGray.700"
            as={MdCompareArrows}
          />
          <TagLabel
            fontSize="12px"
            fontWeight="bold"
            color="atomicGray.700"
          >
            Not Connected
          </TagLabel>
        </Tag>
        <Icon
          as={provider.icon}
          fontSize="125px"
          display="block"
          mx="auto"
          maxH="125px"
        />
        <Button
          m={8}
          mt={0}
          variant="outline"
          colorScheme="atomicBlue"
          onClick={integrate}
          isDisabled={loading}
          leftIcon={<MdEdit size="16px" />}
          size="md"
          isLoading={loading}
          display="flex"
        >
          Configure
        </Button>
      </ZCard>
      {showMarketo.isOpen && (
        <MarketoIntegration
          onComplete={integrateMarketo}
          {...showMarketo}
        />
      )}
    </>
  )
}
