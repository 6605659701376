import { Grid } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'
import React from 'react'

export const HorizontalList: React.FC<PropsWithChildren> = ({ children, ...rest }) => {
  return (
    <Grid
      templateColumns="130px 2fr"
      alignItems="center"
      gridGap={4}
      {...rest}
    >
      {children}
    </Grid>
  )
}
