import { Flex, Grid, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  joinStrings,
  UiMenu,
  UiMenuButton,
  UiMenuItem,
  UiMenuList,
  useAlerts,
  ZButton,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZSidebar,
  ZSidebarBanner,
  ZText,
} from '@postal-io/postal-ui'
import type { User } from 'api'
import { GetUserDocument, SendForgotPasswordDocument, Status, UpdateUserDocument } from 'api'
import { CenteredBox } from 'components/Common'
import { SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { UserTeams } from 'components/User/UserTeams'
import { AnalyticsEvent, useAcl, useAnalyticsSend, useSession } from 'hooks'
import { MdMoreHoriz, MdOutlineChevronLeft } from 'react-icons/md'
import { useParams } from 'react-router'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

import { UserEdit } from 'components/User/UserEdit'
import { UserProfile } from 'components/User/UserProfile'
import { UserStats } from 'components/User/UserStats'
import { useNavigate } from 'react-router-dom'

export const UserIndividual = () => {
  const { userId } = useParams() as any
  const Alert = useAlerts()
  const editUser = useDisclosure()
  const sendPasswordReset = useDisclosure()
  const disableConfirm = useDisclosure()
  const { aclCheck, hasPermission } = useAcl()
  const navigate = useNavigate()

  const {
    session: { userId: selfId },
  } = useSession()

  const canUpdateUser = hasPermission('users.update') && userId !== selfId

  const { data, isLoading: userLoading } = useGraphqlQuery(GetUserDocument, { id: userId })
  const user = (data?.getUser || {}) as User

  const sendAnalytics = useAnalyticsSend()

  const updateUser = useGraphqlMutation(UpdateUserDocument, {
    onSuccess: () => sendAnalytics({ event: AnalyticsEvent.UserAccountUpdated }),
  })

  const disableUser = async () => {
    try {
      await updateUser.mutateAsync({ id: userId, data: { status: Status.Disabled } })
      Alert.warning('User is Disabled')
      disableConfirm.onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const enableUser = async () => {
    try {
      await updateUser.mutateAsync({ id: userId, data: { status: Status.Active } })
      Alert.success('User is Enabled')
    } catch (err) {
      Alert.error(err)
    }
  }

  const toggleUserStatus = async () => {
    user?.status === Status.Active ? disableConfirm.onOpen() : enableUser()
  }

  const sendReset = useGraphqlMutation(SendForgotPasswordDocument)

  const sendIt = async () => {
    try {
      await sendReset.mutateAsync({ userId })
      sendPasswordReset.onClose()
      Alert.success('Password Reset email was sent')
    } catch (err) {
      Alert.error(err)
    }
  }

  const actions = [
    { title: 'Edit User', onClick: () => editUser.onOpen() },
    {
      title: 'Send Password Reset',
      onClick: () => sendPasswordReset.onOpen(),
      isHidden: !aclCheck({ module: 'users.update' }),
    },
    {
      title: `${user?.status === Status.Active ? 'Disable' : 'Enable'} User`,
      onClick: toggleUserStatus,
      isHidden: !canUpdateUser,
    },
  ]

  const title = joinStrings([user?.firstName, user?.lastName], ' ', 'Loading...')

  const handleBack = () => navigate('/users')

  return (
    <>
      <SecondaryNavbar
        left={
          <Flex
            alignItems="center"
            h="52px"
            color="white"
          >
            <ZLink
              color="white"
              onClick={handleBack}
              fontSize="14px"
              fontWeight="bold"
              display="flex"
              alignItems="center"
            >
              <MdOutlineChevronLeft size="28px" />
              Back to Users
            </ZLink>
          </Flex>
        }
      />
      <CenteredBox isLoaded>
        <ZSidebar
          sidebarBlocks={<ProfileSidebarBlocks />}
          m={0}
          p={0}
        >
          <ZSidebarBanner
            title={title}
            actions={
              <UiMenu placement="bottom">
                <UiMenuButton
                  data-testid="UserV2_actionMenu"
                  as={ZButton}
                  colorScheme="atomicBlue"
                  color="atomicBlue.400"
                  variant="link"
                  h="21px"
                >
                  <MdMoreHoriz size="24px" />
                </UiMenuButton>
                <UiMenuList>
                  {actions
                    .filter((action) => !action.isHidden)
                    .map((action) => (
                      <UiMenuItem
                        key={action.title}
                        onClick={action.onClick}
                      >
                        <ZText fontSize="md">{action.title}</ZText>
                      </UiMenuItem>
                    ))}
                </UiMenuList>
              </UiMenu>
            }
          />
          <Grid
            gap={8}
            px={8}
            templateColumns={{ base: 'minmax(0, 1fr)', lg: 'minmax(0, 1fr) minmax(0, 1fr)' }}
          >
            <UserStats userId={userId} />
            <UserProfile
              userId={userId}
              isLoading={userLoading}
              isOpen={editUser.isOpen}
              onOpen={editUser.onOpen}
              onClose={editUser.onClose}
            />
            <UserTeams
              userId={userId}
              gridColumn="span 2"
            />
          </Grid>
        </ZSidebar>
      </CenteredBox>
      {aclCheck({ module: 'users.update' }) && sendPasswordReset.isOpen && (
        <ZModal
          isOpen={sendPasswordReset.isOpen}
          onClose={sendPasswordReset.onClose}
          size="4xl"
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Send Password Reset</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <ZText
                textAlign="center"
                fontSize="md"
              >
                Please confirm that you'd like to send a password reset email to this user.
              </ZText>
            </ZModalBody>
            <ZModalButtons
              onConfirm={sendIt}
              onClose={sendPasswordReset.onClose}
              isConfirmLoading={sendReset.isLoading}
              isConfirmDisabled={sendReset.isLoading}
            />
          </ZModalContent>
        </ZModal>
      )}
      {aclCheck({ module: 'users.update' }) && disableConfirm.isOpen && (
        <ZModal
          isOpen={disableConfirm.isOpen}
          onClose={disableConfirm.onClose}
          size="4xl"
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Disable User</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <ZText
                textAlign="center"
                fontSize="md"
              >
                Please confirm that you'd like to disable this user.
              </ZText>
            </ZModalBody>
            <ZModalButtons
              confirmText="Disable User"
              onConfirm={disableUser}
              confirmProps={{
                colorScheme: 'atomicRed',
              }}
              onClose={disableConfirm.onClose}
              isConfirmLoading={updateUser.isLoading}
              isConfirmDisabled={updateUser.isLoading}
            />
          </ZModalContent>
        </ZModal>
      )}

      {editUser.isOpen && (
        <UserEdit
          isOpen={editUser.isOpen}
          onClose={editUser.onClose}
          user={{ ...user }}
        />
      )}
    </>
  )
}
