import { Box, Flex, StatNumber } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import { UiStat, UiStatGroup, UiStatLabel, ZCard, ZCardHeader, ZText } from '@postal-io/postal-ui'
import { DataObjectType, Granularity, UserAnalyticsV2Document } from 'api'
import { useMemo } from 'react'
import { NoDataOverlay } from '../NoDataOverlay'
interface EngagementCardV2Props extends ZCardProps {
  startDate: Date
  endDate: Date
  teamIds?: string[]
  userIds?: string[]
}

export const EngagementCard: React.FC<EngagementCardV2Props> = ({ startDate, endDate, teamIds, userIds, ...rest }) => {
  const query = useGraphqlQuery(UserAnalyticsV2Document, {
    config: {
      startDate,
      endDate,
      teamIds,
      userIds,
      granularity: Granularity.All,
      type: DataObjectType.User,
    },
  })

  const { thankYou, socialShare, newContact } = useMemo(() => {
    const data = query.data?.userAnalyticsV2 || []
    return data.reduce((sum, user) => {
      sum.thankYou = (sum.thankYou || 0) + (user.thankYou || 0)
      sum.socialShare = (sum.socialShare || 0) + (user.socialShare || 0)
      sum.newContact = (sum.newContact || 0) + (user.newContact || 0)
      return sum
    }, {})
  }, [query.data?.userAnalyticsV2])

  const showNoDataOverlayV2 = useMemo(
    () => !query.isFetching && !thankYou && !socialShare && !newContact,
    [newContact, query.isFetching, socialShare, thankYou]
  )

  return (
    <ZCard
      isLoading={query.isLoading}
      isFetching={query.isFetching}
      variant="form"
      {...rest}
    >
      {showNoDataOverlayV2 && <NoDataOverlay />}
      <ZCardHeader
        display="flex"
        alignItems="center"
        p={4}
        pb={2}
        fontSize="lg"
      >
        Engagement
      </ZCardHeader>
      <Flex
        w="100%"
        pt={16}
        minH="260px"
        justify="center"
      >
        <UiStatGroup
          flexWrap="wrap"
          textAlign="center"
          justifyContent="space-around"
          w="100%"
          fontFamily="Lexend"
        >
          <UiStat>
            <UiStatLabel
              fontSize="md"
              maxW="200px"
              h="42px"
              textTransform="unset"
              mb={4}
            >
              <ZText
                fontSize="inherit"
                color="inherit"
              >
                Thank Yous
              </ZText>
              <Box />
            </UiStatLabel>
            <StatNumber fontWeight="normal">{thankYou ?? 0}</StatNumber>
          </UiStat>
          <UiStat>
            <UiStatLabel
              fontSize="md"
              maxW="200px"
              h="42px"
              textTransform="unset"
              mb={4}
            >
              <ZText
                fontSize="inherit"
                color="inherit"
              >
                Social Shares
              </ZText>
            </UiStatLabel>
            <StatNumber fontWeight="normal">{socialShare ?? 0}</StatNumber>
          </UiStat>
          <UiStat>
            <UiStatLabel
              fontSize="md"
              maxW="180px"
              h="42px"
              textTransform="unset"
              mb={4}
            >
              <ZText
                fontSize="inherit"
                color="inherit"
              >
                New Contacts Generated
              </ZText>
            </UiStatLabel>
            <StatNumber fontWeight="normal">{newContact ?? 0}</StatNumber>
          </UiStat>
        </UiStatGroup>
      </Flex>
    </ZCard>
  )
}
