import { AddIcon } from '@chakra-ui/icons'
import type { GridProps } from '@chakra-ui/react'
import { Box, Divider, Flex, FormControl, Grid, HStack, Stack, useDisclosure, VStack } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  humanize,
  SelectTypeaheadStylesV2,
  UiAlert,
  UiIconButton,
  ZButton,
  ZFormLabel,
  ZInput,
  ZInputDate,
  ZText,
} from '@postal-io/postal-ui'
import { RequiredSelectTypeahead } from 'components/AutoComplete/RequiredSelectTypeahead'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { capitalize } from 'lodash'
import { orderBy } from 'natural-orderby'
import React, { Fragment, useCallback, useMemo } from 'react'
import { MdDeleteOutline } from 'react-icons/md'
import type { DataListType } from '../../api'
import { GetDataListDocument, SfdcTriggerTypes, TriggerFilterTypes } from '../../api'
import { TriggerEditFiltersHelp } from './TriggerEditFiltersHelp'

const TRIGGER_TYPES = Object.values(SfdcTriggerTypes)
const FILTER_TYPES = Object.values(TriggerFilterTypes)
const EMPTY_FILTER_TYPES = [
  TriggerFilterTypes.Empty,
  TriggerFilterTypes.NotEmpty,
  TriggerFilterTypes.Exists,
  TriggerFilterTypes.DoesNotExist,
]

export const getTypeFilter = (filters: any) => {
  return filters?.find?.((f: any) => f.field === 'type')
}

export const getNonTypeFilters = (filters: any) => {
  return filters?.filter?.((f: any) => f.field !== 'type') || []
}

// strip old/new prefix
export const stripPrefix = (value?: string | null) => {
  if (!value) return ''
  if (value?.startsWith('.')) return value.replace(/^\./, '')
  if (value.startsWith('new.') || value.startsWith('old.')) return value.replace(/^(new|old)\./, '')
  return value
}

export const getPrefix = (value?: string | null) => {
  const firstPart = value?.split?.('.')?.[0] || ''
  return firstPart === 'new' || firstPart === 'old' ? firstPart : ''
}

const isValueRequired = (row: any) => {
  return !EMPTY_FILTER_TYPES.includes(row.filter)
}

const blankRow = { field: '', filter: '', value: '' }

export interface TriggerEditFiltersV2Props {
  state: any
  setState: (f: (draft: any) => any) => void
}
export const TriggerEditFilters: React.FC<TriggerEditFiltersV2Props> = ({ state, setState }) => {
  const filterHelp = useDisclosure()

  const typeFilter = useMemo(() => getTypeFilter(state.filters), [state.filters])
  const nonTypeFilters = useMemo(() => getNonTypeFilters(state.filters) || [], [state.filters])

  const addRow = (row = blankRow) => {
    setState((draft) => {
      draft.filters = draft.filters || []
      draft.filters.push(row)
    })
  }

  const removeRow = (idx: number) => {
    setState((draft) => {
      const nonTypes = getNonTypeFilters(draft.filters)
      nonTypes.splice(idx, 1)
      draft.filters = [getTypeFilter(draft.filters), ...nonTypes]
    })
  }

  const handleChange = (name: string, value: any, idx: number) => {
    setState((draft) => {
      const nonTypes = getNonTypeFilters(draft.filters)
      const row = nonTypes[idx]
      switch (name) {
        case 'filter':
          const prevFilter = row.filter
          row.filter = value
          // reset the value field if the filter changes significantly
          if (EMPTY_FILTER_TYPES.includes(value)) {
            row.value = ''
          } else if (prevFilter.startsWith('Date') && !value.startsWith('Date')) {
            row.value = ''
          } else if (prevFilter.startsWith('Number') && !value.startsWith('Number')) {
            row.value = ''
          } else if (prevFilter.startsWith('String') && !value.startsWith('String')) {
            row.value = ''
          }
          break
        case 'field':
          const prefix = getPrefix(row.field)
          const prevField = stripPrefix(row.field)
          const newField = stripPrefix(value)
          row.field = `${prefix}.${newField}`
          if (prevField !== newField) row.value = ''
          break
        case 'prefix':
          row.field = `${value}.${stripPrefix(row.field)}`
          break
        case 'value':
          row.value = value || ''
          break
      }
      draft.filters = [getTypeFilter(draft.filters), ...nonTypes]
    })
  }

  const handleType = (e: any) => {
    const { value } = e
    setState((draft) => {
      const current = getTypeFilter(draft.filters)
      if (current && current?.value !== value) {
        draft.filters = [{ ...current, value }]
      } else if (current) {
        draft.filters = [{ ...current, value }, ...getNonTypeFilters(draft.filters)]
      } else {
        draft.filters = draft.filters || []
        draft.filters.unshift({
          field: 'type',
          filter: TriggerFilterTypes.StringEquals,
          value,
        })
      }
      if (draft.filters.length === 1) draft.filters.push(blankRow)
    })
  }

  const options = TRIGGER_TYPES.map((type) => ({ label: capitalize(type), value: type }))

  return (
    <>
      <Stack spacing={8}>
        <FormControl>
          <ZFormLabel htmlFor="trigger-type">Trigger Type</ZFormLabel>
          <RequiredSelectTypeahead
            name="filter"
            onChange={handleType}
            options={options}
            value={options.find((option) => option.value === typeFilter?.value)}
            placeholder={!!typeFilter?.value ? undefined : 'Select a Type'}
            {...SelectTypeaheadStylesV2}
          />
        </FormControl>
        {!!typeFilter?.value && (
          <VStack spacing={0}>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              verticalAlign="center"
              w="100%"
            >
              <HStack>
                <ZText>Trigger Filters</ZText>
                <Box
                  _hover={{ cursor: 'pointer' }}
                  onClick={filterHelp.onOpen}
                >
                  <ZInfoTooltip label="Filter Help" />
                </Box>
              </HStack>
              <ZButton
                fontSize="sm"
                leftIcon={<AddIcon />}
                ml="auto"
                onClick={() => addRow()}
                variant="link"
              >
                Add a Filter
              </ZButton>
            </Flex>
            <TriggerEditFiltersTableV2
              systemName={state.systemName}
              filterType={typeFilter?.value}
              filters={nonTypeFilters}
              removeRow={removeRow}
              handleChange={handleChange}
            />
          </VStack>
        )}
      </Stack>
      {filterHelp.isOpen && <TriggerEditFiltersHelp {...filterHelp} />}
    </>
  )
}

interface TriggerEditFiltersTableV2Props extends GridProps {
  systemName: string
  filterType: string
  filters?: any[]
  handleChange: (name: string, value: any, idx: number) => void
  removeRow: (idx: number) => void
}
const TriggerEditFiltersTableV2: React.FC<TriggerEditFiltersTableV2Props> = ({
  systemName,
  filterType,
  filters,
  handleChange,
  removeRow,
  ...rest
}) => {
  const variables = useMemo(() => {
    return { system: systemName, type: `metadata_${capitalize(filterType)}` as DataListType }
  }, [systemName, filterType])

  const getDataList = useGraphqlQuery(GetDataListDocument, variables, {
    enabled: !!filterType && !!systemName,
  })

  const items = useMemo(() => {
    return orderBy(getDataList?.data?.getDataList?.items || [], ['name'])
  }, [getDataList?.data?.getDataList?.items])

  const rowValues = useCallback(
    (row: any) => {
      return items.find((item) => item.id === stripPrefix(row.field))?.values
    },
    [items]
  )

  const itemOptions = items.map((item) => ({ label: item.name, value: item.id }))
  const valueTypeOptions = [
    { label: 'Current Value', value: 'new' },
    { label: 'Previous Value', value: 'old' },
  ]
  const filterOptions = FILTER_TYPES.map((type) => ({ label: humanize(type), value: type }))
  const getRowValueOptions = (row: any) =>
    rowValues(row)?.map((value: any) => ({
      label: value.name,
      value: value.id,
    }))

  return (
    <Box left={56}>
      {!!getDataList.error && (
        <UiAlert
          status="warning"
          mb={8}
        >
          {getDataList.error?.message?.replace?.(/^Exception.*: /, '')}
        </UiAlert>
      )}
      {filters?.map((row: any, idx: number) => {
        return (
          <Box
            w="calc(100% + 56px)"
            key={idx}
          >
            <Grid
              templateColumns="1fr 1fr 40px"
              gridGap={4}
              alignItems="center"
              mt={idx === 0 ? 0 : 4}
              mb={4}
              key={idx}
              {...rest}
            >
              <Fragment key={idx}>
                {!!items?.length ? (
                  <RequiredSelectTypeahead
                    name="field"
                    value={itemOptions.find((option) => option.value === stripPrefix(row.field))}
                    onChange={(e: any) => handleChange('field', e.value, idx)}
                    options={itemOptions}
                    placeholder={!!stripPrefix(row.field) ? undefined : 'Select Field'}
                    isDisabled={getDataList.isFetching}
                    {...SelectTypeaheadStylesV2}
                  />
                ) : (
                  <ZInput
                    name="field"
                    value={stripPrefix(row.field)}
                    onChange={({ target: { name, value } }: any) => handleChange(name, value, idx)}
                    placeholder="Enter Field"
                    isRequired={!items.length}
                    isDisabled={getDataList.isFetching}
                  />
                )}
                <RequiredSelectTypeahead
                  name="prefix"
                  onChange={(e: any) => handleChange('prefix', e.value, idx)}
                  options={valueTypeOptions}
                  placeholder={!!getPrefix(row.field) ? undefined : 'Value Type'}
                  value={valueTypeOptions.find((option) => option.value === getPrefix(row.field))}
                  {...SelectTypeaheadStylesV2}
                />
                <UiIconButton
                  aria-label="delete-row"
                  icon={<MdDeleteOutline size={20} />}
                  size="sm"
                  variant="ghost"
                  onClick={() => removeRow(idx)}
                />
                <RequiredSelectTypeahead
                  name="filter"
                  onChange={(e: any) => handleChange('filter', e.value, idx)}
                  options={filterOptions}
                  placeholder={!!row.filter ? undefined : 'Select Filter'}
                  value={filterOptions.find((option) => option.value === row.filter)}
                  {...SelectTypeaheadStylesV2}
                />
                {!!rowValues(row)?.length ? (
                  <RequiredSelectTypeahead
                    name="value"
                    value={getRowValueOptions(row)?.find((option) => option.value === row.value)}
                    onChange={(e: any) => handleChange('value', e.value, idx)}
                    options={getRowValueOptions(row)}
                    placeholder={!!row.value ? undefined : 'Select Value'}
                    isDisabled={!isValueRequired(row)}
                    {...SelectTypeaheadStylesV2}
                  />
                ) : row.filter?.startsWith?.('Date') ? (
                  <ZInputDate
                    name="value"
                    value={row.value || ''}
                    onChange={({ target: { name, value } }) => handleChange(name, value, idx)}
                    isRequired={isValueRequired(row)}
                  />
                ) : (
                  <ZInput
                    name="value"
                    type={row.filter?.startsWith?.('Number') ? 'number' : 'text'}
                    value={row.value || ''}
                    onChange={({ target: { name, value } }: any) => handleChange(name, value, idx)}
                    placeholder="Value"
                    isRequired={isValueRequired(row)}
                    isDisabled={!isValueRequired(row)}
                  />
                )}
                <Box /> {/* Spacer div to balance delete icon */}
              </Fragment>
            </Grid>
            {idx !== filters.length - 1 && <Divider border="1px solid red" />}
          </Box>
        )
      })}
    </Box>
  )
}
