import { Box } from '@chakra-ui/react'
import { ResponsivePie } from '@nivo/pie'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import { useAlertError, ZCard, ZCardHeader } from '@postal-io/postal-ui'
import { formatLongNumber, formatShortNumber } from 'lib'
import React, { useMemo } from 'react'
import { ReportingTooltip } from '.'
import { CrmReportType, GetCrmReportDocument } from '../../../api'
import { NoDataOverlay } from '../NoDataOverlay'
import { CenterValue } from './CenterValue'

interface OpportunitiesV2Props extends ZCardProps {
  refresh: boolean
  system: string
}
export const Opportunities: React.FC<OpportunitiesV2Props> = ({ refresh, system, ...rest }) => {
  const query = useGraphqlQuery(
    GetCrmReportDocument,
    {
      system,
      reportType: CrmReportType.OpportunitiesByRepresentative,
      refresh,
    },
    { enabled: !!system, staleTime: 1000 * 60 }
  )
  useAlertError(query.error)

  const data = useMemo(() => {
    const records = query.data?.getCrmReport?.queryResults?.find((f) => f?.label === 'opportunity_data')?.result
      ?.records
    if (!records) return []
    return records
      .filter((r) => !!r.owner_name && !!r.count_opportunity)
      .map((r) => ({
        id: r.owner_name,
        value: r.count_opportunity,
        label: r.owner_name,
      }))
  }, [query.data?.getCrmReport])

  const aggregateData = useMemo(() => {
    const records = query.data?.getCrmReport?.queryResults?.find((f) => f?.label === 'opportunity_data')?.result
      ?.records
    if (!records) return []
    return records.find((r) => !r.owner_name)
  }, [query.data?.getCrmReport])

  return (
    <ZCard
      isLoading={query.isLoading}
      variant="form"
      {...rest}
    >
      <ZCardHeader p={4}>Postal Influenced Opps by Rep</ZCardHeader>
      <Box h={{ base: '50vw', lg: '24vw' }}>
        {data.length ? (
          <>
            <CenterValue value={formatShortNumber(aggregateData.count_opportunity)} />
            <ResponsivePie
              data={data}
              colors={{ scheme: 'set3' }}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.7}
              padAngle={1.5}
              cornerRadius={10}
              activeInnerRadiusOffset={-8}
              activeOuterRadiusOffset={8}
              borderWidth={3}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 5]] }}
              tooltip={({ datum: { color, data } }) => (
                <ReportingTooltip
                  nameLabel="Opportunity Owner"
                  name={data.label}
                  valueLabel="Record Count"
                  value={data.value}
                  formattedValue={formatLongNumber(data.value)}
                  totalAmount={aggregateData.count_opportunity}
                  formattedTotal={formatShortNumber(aggregateData.count_opportunity)}
                  color={color}
                />
              )}
            />
          </>
        ) : (
          <NoDataOverlay />
        )}
      </Box>
    </ZCard>
  )
}
