import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Grid, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { Column } from '@postal-io/postal-ui'
import {
  GraphqlFilterTransform,
  internalProgressBarProps,
  internalTableProps,
  joinStrings,
  UiSelectButton,
  UiSSDataTable,
  useAlertError,
  useGraphqlFilter,
  ZButton,
  ZCard,
  ZCardBody,
  ZCheckbox,
  ZInput,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import type { SearchUsersQueryVariables, User } from 'api'
import { BudgetMode, GetBudgetRemainingDocument, SearchUsersDocument, Status } from 'api'
import { MenuUserAccountUpdate } from 'components/AutoComplete/MenuUserAccountUpdate'
import { cloneDeep, set } from 'lodash'
import type { ChangeEvent } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import { MdOutlineBlock, MdOutlineCheckCircleOutline, MdOutlineSwapVert } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useAcl } from '../../hooks'
import { MenuUserRole } from '../AutoComplete'
import { UserSeatsAlert } from '../Users'
import type { SelectedUsers } from '../Users/UsersData'
import { buttonsV2, DEFAULT_ROLES } from '../Users/UsersData'
import { BulkTeamUserUpdate } from './BulkTeamUserUpdate'
import { TeamUserAdd } from './TeamUserAdd'
import { TeamUserAddBudget } from './TeamUserAddBudget'

interface UserLinkProps {
  user: User
}
const UserLink: React.FC<UserLinkProps> = ({ user }) => {
  const navigate = useNavigate()
  const onClick = () => navigate(`/users/${user.id}`)
  return <ZLink onClick={onClick}>{joinStrings([user.firstName, user.lastName], ' ')}</ZLink>
}

interface UserBudgetProps {
  accountId?: string
  userId: string
  teamId?: string
  onClick: (userId: string) => () => void
}

const UserBudget: React.FC<UserBudgetProps> = ({ accountId, userId, teamId, onClick }) => {
  const { data: budgetData } = useGraphqlQuery(
    GetBudgetRemainingDocument,
    { accountId: accountId as string, userId, teamId },
    {
      enabled: !!accountId,
    }
  )

  return (
    <Flex
      alignItems="center"
      gap={1}
    >
      <ZMoney cents={budgetData?.getBudgetRemaining?.budgetRemaining ?? 0} />
      <ZLink
        display="inline-flex"
        alignItems="center"
        fontSize="xs"
        color="atomicBlue.400"
        title="Add budget for this user"
        onClick={onClick(userId)}
      >
        <MdOutlineSwapVert
          size="20px"
          color="inherit"
        />
        Adjust
      </ZLink>
    </Flex>
  )
}

const LIMIT = 100

const transforms = {
  'productAccess.teamId': GraphqlFilterTransform.Equal,
  'productAccess.roles': GraphqlFilterTransform.In,
  'lastName': GraphqlFilterTransform.Contains,
  'firstName': GraphqlFilterTransform.Contains,
  'userName': GraphqlFilterTransform.Contains,
}

interface TeamUsersProps extends BoxProps {
  teamId?: string
  accountId?: string
}
export const TeamUsers: React.FC<TeamUsersProps> = ({ teamId, accountId, ...rest }) => {
  const addUser = useDisclosure()
  const teamUpdate = useDisclosure()
  const addBudgetDisclosure = useDisclosure()
  const { hasPermission, hasFeature } = useAcl()
  const canUpdate = hasPermission('users.update')
  const [adjustUserBudget, setAdjustUserBudget] = useState<string | undefined>()

  const canAdjustUserBudget = hasFeature('additionalBudget')

  const { data: budgetData } = useGraphqlQuery(
    GetBudgetRemainingDocument,
    { accountId: accountId as string, teamId },
    { enabled: !!accountId }
  )

  const budgetMode = useMemo(
    () => budgetData?.getBudgetRemaining?.applicableBudget?.budget?.mode,
    [budgetData?.getBudgetRemaining]
  )

  const handleInput = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFilter({ key: target.name, value: target.value })
  }

  const staticVariables = useMemo(() => {
    return {
      filter: {
        productAccess: { teamId: { eq: teamId ? teamId : null } },
      },
      limit: LIMIT,
    }
  }, [teamId])

  const { variables, filter, setFilter, orderBy, setOrderBy } = useGraphqlFilter<SearchUsersQueryVariables>({
    transforms,
    staticVariables,
    debounce: 400,
  })

  // If the roles field is empty, then we want to specifically ask for the usable roles to avoid
  // showing no access users.
  const newVariables = useMemo(() => {
    const newVars = cloneDeep(variables)
    if (!variables.filter?.productAccess?.roles?.in?.length) {
      set(newVars, 'filter.productAccess.roles.in', DEFAULT_ROLES)
    }
    return newVars
  }, [variables])

  const searchUsers = useGraphqlInfiniteQuery(SearchUsersDocument, newVariables)
  const users = useMemo(
    () => searchUsers.mergedData?.searchUsers?.users || [],
    [searchUsers.mergedData?.searchUsers?.users]
  )
  const totalRecords = useMemo(
    () => searchUsers?.mergedData?.searchUsers?.resultsSummary?.totalRecords,
    [searchUsers?.mergedData?.searchUsers?.resultsSummary?.totalRecords]
  )

  const handleAddBudgetToUser = useCallback(
    (userId: string) => () => {
      setAdjustUserBudget(userId)
      addBudgetDisclosure.onOpen()
    },
    [addBudgetDisclosure]
  )

  const handleCloseAddBudget = () => {
    setAdjustUserBudget(undefined)
    addBudgetDisclosure.onClose()
  }

  interface NormalizedUser {
    id: string
    teamId?: string
    accountId?: string
    user: User
    handleAddBudgetToUser: (userId: string) => () => void
  }
  const normalizedUsers = useMemo(() => {
    return users.map((user) => ({
      id: user.id,
      teamId: teamId,
      accountId,
      user,
      handleAddBudgetToUser,
    })) as NormalizedUser[]
  }, [teamId, users, accountId, handleAddBudgetToUser])

  useAlertError(searchUsers.error)

  //Selected users from the data table
  const [selectedUsers, setSelectedUsers] = useState<SelectedUsers>()
  const buttonsEnabled = !!selectedUsers?.users?.length

  const onSelect = useCallback(
    (selected: NormalizedUser[]) => {
      if (selected.length === users.length) {
        setSelectedUsers({
          users: selected.map((s) => s.user),
          filter: newVariables.filter || {},
          count: totalRecords || selected.length || 0,
        })
      } else {
        setSelectedUsers({
          users: selected.map((s) => s.user),
          filter: { id: { in: selected.map((c) => c.id) } },
          count: selected.length,
        })
      }
    },
    [totalRecords, users.length, newVariables.filter]
  )

  const onSelectButton = (type: string) => {
    switch (type) {
      case 'TEAM_UPDATE':
        return teamUpdate.onOpen()
      default:
    }
  }

  const columns: Column[] = useMemo(
    () =>
      [
        {
          label: 'Name',
          render: ({ user }: { user: User }) => <UserLink user={user} />,
        },
        { key: 'user.userName', label: 'User Name' },
        {
          label: 'Roles',
          render: ({ teamId, user }: any) => {
            return (
              <MenuUserAccountUpdate
                user={user}
                teamId={teamId}
                minW="210px"
              />
            )
          },
        },
        {
          label: 'Budget',
          render: ({ id, teamId, accountId, handleAddBudgetToUser }: any) => {
            return (
              <UserBudget
                userId={id}
                teamId={teamId}
                accountId={accountId}
                onClick={handleAddBudgetToUser}
              />
            )
          },
        },
        {
          label: 'Status',
          render: ({ user }: any) => {
            return (
              <Flex
                alignItems="center"
                gap={1}
              >
                {user.status === Status.Active ? (
                  <Box color="atomicBlue.400">
                    <MdOutlineCheckCircleOutline
                      size="20px"
                      color="inherit"
                      style={{
                        transform: 'translateY(-1px)',
                      }}
                    />
                  </Box>
                ) : (
                  <Box color="atomicGray.500">
                    <MdOutlineBlock
                      size="20px"
                      color="inherit"
                      style={{
                        transform: 'translateY(-1px)',
                      }}
                    />
                  </Box>
                )}
                <ZText>{user.status === Status.Active ? 'Enabled' : 'Disabled'}</ZText>
              </Flex>
            )
          },
        },
      ].filter((col) => (col.label === 'Budget' ? canAdjustUserBudget && budgetMode === BudgetMode.PerUser : true)),
    [canAdjustUserBudget, budgetMode]
  )

  return (
    <>
      <Box {...rest}>
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          h="40px"
          mb={4}
        >
          {canUpdate && (
            <Flex
              justifyContent="flex-start"
              alignItems="center"
              h="40px"
            >
              {buttonsV2?.map(({ type, placement, ...button }) => (
                <UiSelectButton
                  h="40px"
                  w="40px"
                  fontSize="26px"
                  border="none"
                  aria-label={`UserTable ${type} button`}
                  key={type}
                  placement={placement}
                  isDisabled={!buttonsEnabled}
                  onClick={() => onSelectButton(type)}
                  colorScheme="atomicGray"
                  color="atomicGray.500"
                  {...button}
                />
              ))}
            </Flex>
          )}
          <Grid
            gap={4}
            templateColumns="repeat(auto-fit, minmax(100px, 1fr))"
            alignItems="end"
            w="100%"
            mb={8}
          >
            <ZInput
              type="search"
              name="lastName"
              onChange={handleInput}
              value={filter.lastName || ''}
              placeholder="Search Last Name"
              aria-label="Search Last Name"
            />
            <ZInput
              type="search"
              name="firstName"
              onChange={handleInput}
              value={filter.firstName || ''}
              placeholder="Search First Name"
              aria-label="Search First Name"
            />
            <ZInput
              type="search"
              name="userName"
              onChange={handleInput}
              value={filter.userName || ''}
              placeholder="Search Email"
              aria-label="Search Email Address"
            />
            <MenuUserRole
              value={filter['productAccess.roles'] || []}
              onChange={(roles) => setFilter({ key: 'productAccess.roles', value: roles })}
              teamId={teamId}
              w="100%"
              h="40px"
              as={ZButton}
              fontSize="14px"
              fontWeight="normal"
              color="atomicGray.600"
              textAlign="left"
            />

            <ZButton
              onClick={addUser.onOpen}
              colorScheme="atomicBlue"
            >
              Add User
            </ZButton>
          </Grid>
        </Flex>
        <ZCard variant="form">
          <ZCardBody p={8}>
            <UiSSDataTable
              isLoading={searchUsers.isFetching}
              columns={columns}
              rows={normalizedUsers}
              rowKey="id"
              hasMore={searchUsers.hasNextPage}
              fetchMore={searchUsers.fetchNextPage}
              filter={variables.filter}
              orderBy={orderBy}
              onOrderBy={setOrderBy}
              showSelect={canUpdate}
              onSelect={onSelect}
              SelectCheckbox={ZCheckbox}
              HeaderButton={ZButton}
              tableProps={internalTableProps}
              progressBarProps={internalProgressBarProps}
              variant="list"
            />
          </ZCardBody>
        </ZCard>
      </Box>
      {canUpdate && selectedUsers && teamUpdate.isOpen && (
        <BulkTeamUserUpdate
          isOpen={teamUpdate.isOpen}
          onClose={teamUpdate.onClose}
          users={selectedUsers}
          teamId={teamId}
          onComplete={() => setSelectedUsers(undefined)}
        />
      )}
      {addUser.isOpen && (
        <ZModal
          isOpen={addUser.isOpen}
          onClose={addUser.onClose}
          size="md"
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Add Team Membership</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody pb={8}>
              <UserSeatsAlert mb={8} />
              <ZText
                mb={4}
                textAlign="center"
                fontSize="md"
              >
                Please select the <strong>team</strong> and <strong>roles</strong> you would like granted to this user.
              </ZText>
              <TeamUserAdd
                teamId={teamId}
                onUpdate={addUser.onClose}
                onClose={addUser.onClose}
                mb={0}
              />
            </ZModalBody>
          </ZModalContent>
        </ZModal>
      )}
      {addBudgetDisclosure.isOpen && adjustUserBudget ? (
        <TeamUserAddBudget
          userId={adjustUserBudget}
          teamId={teamId}
          accountId={accountId}
          isOpen={addBudgetDisclosure.isOpen}
          onClose={handleCloseAddBudget}
        />
      ) : null}
    </>
  )
}
