import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, HStack, MenuButton } from '@chakra-ui/react'
import { UiMenu, UiMenuItem, UiMenuList, UiMoney, UiTag, UiTagLabel, ZHeading, ZText } from '@postal-io/postal-ui'
import type { ApprovedPostal } from 'api'
import { TeamTags } from 'components/Common'
import { ZStatusTag } from 'components/Common/ZComponents'
import { statusMapper } from 'lib'
import { MdChevronLeft } from 'react-icons/md'
import { getPriceRange, groupCollectionItems } from './utils'

interface StatProps extends BoxProps {
  label: string
}

// const COLOR = 'white'
// const COLOR = '#62EFA7'

const Stat: React.FC<StatProps> = ({ label, children }) => {
  return (
    <Flex alignItems="center">
      <ZHeading
        as="h6"
        fontSize="body-md"
        color="atomicGray.500"
        fontWeight="bold"
        mr={3}
      >
        {label}
      </ZHeading>
      <ZText fontSize="lg">{children}</ZText>
    </Flex>
  )
}

interface CollectionStatProps extends BoxProps {
  collection: ApprovedPostal
  updateStatusAction: any
}

export const CollectionStats: React.FC<CollectionStatProps> = ({ collection, updateStatusAction, ...rest }) => {
  const itemCount = groupCollectionItems(collection).length
  const { minPrice, maxPrice, hasMixedPrice } = getPriceRange(collection.variants)
  const { text, color, backgroundColor } = statusMapper(collection?.status)

  const acceptanceRate = collection?.metrics?.touches

  return (
    <Box {...rest}>
      <HStack
        spacing={10}
        mb={10}
        h="100%"
      >
        {collection?.status && (
          <Stat label="Status">
            {updateStatusAction.isHidden ? (
              <ZStatusTag
                color={color}
                backgroundColor={backgroundColor}
                label={text}
                verticalAlign="middle"
              />
            ) : (
              <UiMenu placement="bottom">
                <MenuButton
                  p={0}
                  m={0}
                  minW={0}
                  border="none"
                  borderRadius={10}
                  lineHeight="initial"
                  _expanded={{ filter: 'brightness(1.1)' }}
                  _hover={{ filter: 'brightness(1.05)' }}
                  _focus={{ filter: 'brightness(1.02)' }}
                >
                  <UiTag
                    fontSize="body-sm"
                    fontWeight="bold"
                    alignSelf="right"
                    width="120px"
                    p={2}
                    justifyContent="center"
                    alignItems="center"
                    minH="0px"
                    h="1.5rem"
                    minW="5rem"
                    borderRadius="12px"
                    lineHeight="14px"
                    color={color}
                    backgroundColor={backgroundColor}
                  >
                    <UiTagLabel
                      w="100%"
                      textAlign="center"
                      display="flex"
                      justifyContent="center"
                    >
                      {text}
                      <MdChevronLeft
                        style={{ fontSize: '14px', transform: 'rotate(-90deg)', marginLeft: 5, marginRight: -15 }}
                      />
                    </UiTagLabel>
                  </UiTag>
                </MenuButton>
                <UiMenuList>
                  <UiMenuItem
                    px={5}
                    fontSize="sm"
                    fontWeight="normal"
                    onClick={updateStatusAction.onClick}
                  >
                    {updateStatusAction.title}
                  </UiMenuItem>
                </UiMenuList>
              </UiMenu>
            )}
          </Stat>
        )}
        <Stat label="Items">{itemCount}</Stat>
        <Stat label={hasMixedPrice ? 'Price range' : 'Price'}>
          <Flex wrap="nowrap">
            <UiMoney
              currency={collection?.currency}
              cents={minPrice}
              round
            />

            {hasMixedPrice && (
              <>
                -
                <UiMoney
                  currency={collection?.currency}
                  cents={maxPrice}
                  round
                />
              </>
            )}
          </Flex>
        </Stat>
        {collection.currency && <Stat label="Currency">{collection.currency}</Stat>}
        {collection.teamIds && (
          <Stat label="Teams">
            <HStack>
              <TeamTags
                bg="atomicBlue.10"
                color="atomicBlue.800"
                teamIds={collection.teamIds}
              />
            </HStack>
          </Stat>
        )}
        <Stat label="Sent">{acceptanceRate}</Stat>
      </HStack>
    </Box>
  )
}
