import { Box, Flex, Text } from '@chakra-ui/react'
import {
  sessionStorage,
  UiButton,
  UiDivider,
  UiFormControl,
  UiGoogleCaptchaPolicies,
  UiInput,
  UiLink,
  useAlerts,
  ZInputPassword,
} from '@postal-io/postal-ui'
import { zLoginInputStyles, zLoginPasswordStyles } from 'components/Common/ZComponents'
import { AnalyticsEventV2, getAnalyticsSessionInfo, sendAnalyticsEvent, useSession } from 'hooks'
import { StorageKeys } from 'lib'
import React, { useState } from 'react'
import { Link as RouteLink, useNavigate, useSearchParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { LogInHeaderBlack } from './LogInHeader'
import { SsoIconOption } from './SsoOption'

interface State {
  userName: string
  password: string
}
export const LogIn: React.FC<{ toggle: () => void }> = ({ toggle }) => {
  const { loginPassword } = useSession()
  const [loading, setLoading] = useState(false)
  const Alert = useAlerts()
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const returnTo = search.get('returnTo') ?? sessionStorage.getItem(StorageKeys.AppRedirectPath)
  const [form, setForm] = useImmer<State>({ userName: '', password: '' })

  const login = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      setLoading(true)
      const session = await loginPassword(form)
      sendAnalyticsEvent(AnalyticsEventV2.Engage_Login, { ...getAnalyticsSessionInfo(session), type: 'PASSWORD' })
      sessionStorage.removeItem(StorageKeys.AppRedirectPath)
      navigate(returnTo ?? '/')
    } catch (err) {
      if (err.message.includes('has no roles')) {
        navigate('/error/no_roles')
      } else {
        Alert.warning(err)
        setLoading(false)
      }
    }
  }

  return (
    <>
      <LogInHeaderBlack>
        <Flex
          direction="column"
          w="80%"
          maxW="500px"
          alignItems="center"
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            width="100%"
            mt={10}
            flexWrap="wrap"
          >
            <Text
              as="span"
              fontWeight="normal"
              color="white"
              fontSize="sm"
              whiteSpace="nowrap"
              m={2}
            >
              Single Sign-on:
            </Text>
            <SsoIconOption mx={2} />
          </Flex>

          <UiDivider
            text="Or"
            textColor="white"
            borderColor="atomicGray.500"
            border="1px"
            flexProps={{ w: '100%' }}
            my={12}
          />

          <Flex
            direction="column"
            w="100%"
          >
            <form onSubmit={login}>
              <UiFormControl
                id="userName"
                mb={5}
              >
                <UiInput
                  data-testid="signin_email"
                  {...zLoginInputStyles}
                  w="100%"
                  name="userName"
                  type="email"
                  placeholder="Email Address"
                  autoCapitalize="off"
                  isRequired
                  value={form.userName}
                  onChange={(e) => setForm((draft) => void (draft.userName = e.target.value))}
                />
              </UiFormControl>
              <UiFormControl id="password">
                <ZInputPassword
                  {...zLoginInputStyles}
                  {...zLoginPasswordStyles}
                  name="password"
                  data-testid="signin_password"
                  placeholder="Password"
                  isRequired
                  minLength={6}
                  autoComplete="new-password"
                  value={form.password}
                  onChange={(e) => setForm((draft) => void (draft.password = e.target.value))}
                />
              </UiFormControl>
              <UiLink
                float="left"
                onClick={toggle}
                my={4}
                color="atomicBlue.100"
              >
                I forgot my password
              </UiLink>

              <UiButton
                type="submit"
                mt={6}
                w="100%"
                h="56px"
                colorScheme="secondary"
                size="body-lg"
                textTransform="none"
                isDisabled={loading}
                isLoading={loading}
              >
                Login
              </UiButton>
            </form>
            <Box
              mx={12}
              mt={5}
            >
              <Text
                textAlign="center"
                fontWeight="normal"
                fontSize="link"
                color="white"
              >
                Don&apos;t have an account?
                <UiLink
                  ml={1}
                  as={RouteLink}
                  to="/sign-up"
                  fontWeight="normal"
                  color="atomicBlue.400"
                >
                  Sign up
                </UiLink>
              </Text>
            </Box>
            <UiGoogleCaptchaPolicies
              linkProps={{ fontSize: 'xs', fontWeight: 'hairline', color: 'atomicBlue.400' }}
              mt={5}
              maxW="350px"
              textAlign="center"
              mx="auto"
              color="white"
              fontSize="xs"
              fontWeight="hairline"
            />
          </Flex>
        </Flex>
      </LogInHeaderBlack>
    </>
  )
}
