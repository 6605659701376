import { HStack, Slide } from '@chakra-ui/react'
import { UiButton } from '@postal-io/postal-ui'
import type { Currency } from 'api'
import { PostalSelectItemContent } from 'components/PostalSend/PostalSelectItem'
import { useAcl, useMe } from 'hooks'
import type { PropsWithChildren } from 'react'
import { useMemo, useState } from 'react'
import { CollectionItemSelectMarketplace } from './CollectionItemSelectMarketplace'
import type { EitherItem } from './utils'

export interface CollectionItemSelectProps {
  onSelectItem: (item: EitherItem) => void
  restrictCurrency?: Currency
  handleBulkSelect?: (item: EitherItem) => void
  isBulkSelected?: (item: EitherItem) => boolean
}
export const CollectionItemSelect: React.FC<CollectionItemSelectProps> = ({
  onSelectItem,
  restrictCurrency,
  handleBulkSelect,
  isBulkSelected,
}) => {
  const { hasPermission } = useAcl()
  const hasMarketplace = hasPermission('postals.create')
  const [tabIndex, setTabIndex] = useState(hasMarketplace ? 0 : 1)

  const { approvedCurrencies: defaultCurrencies } = useMe()
  const approvedCurrencies = useMemo(
    () => (restrictCurrency ? [restrictCurrency] : defaultCurrencies),
    [defaultCurrencies, restrictCurrency]
  )

  return (
    <>
      {hasMarketplace && (
        <HStack
          borderBottom="1px solid rgba(0,0,0,.2)"
          m={8}
          mt={-8}
        >
          <TabButton
            isActive={tabIndex === 0}
            onClick={() => setTabIndex(0)}
          >
            Marketplace
          </TabButton>
          <TabButton
            isActive={tabIndex === 1}
            onClick={() => setTabIndex(1)}
          >
            Approved Items
          </TabButton>
        </HStack>
      )}
      <Slide
        in={tabIndex === 0}
        direction="left"
        style={{ position: 'relative' }}
        unmountOnExit
      >
        <CollectionItemSelectMarketplace
          approvedCurrencies={approvedCurrencies}
          onSelect={onSelectItem}
          onBulkSelect={handleBulkSelect as any}
          isBulkSelected={isBulkSelected}
        />
      </Slide>
      <Slide
        in={tabIndex === 1}
        direction="right"
        style={{ position: 'relative' }}
        unmountOnExit
      >
        <PostalSelectItemContent
          approvedCurrencies={approvedCurrencies}
          onSelect={onSelectItem}
          onBulkSelect={handleBulkSelect}
          isBulkSelected={isBulkSelected}
          isCreatingCollection
        />
      </Slide>
    </>
  )
}

interface TabButtonProps {
  isActive: boolean
  onClick: () => void
}
const TabButton: React.FC<PropsWithChildren<TabButtonProps>> = ({ isActive, onClick, children }) => (
  <UiButton
    variant="naked"
    fontSize="md"
    px={{ base: 0, sm: 1 }}
    mr={{ base: '25px', xl: '50px' }}
    height="55px"
    fontWeight={600}
    color={{
      sm: isActive ? 'atomicBlue.400' : 'atomicGray.800',
    }}
    _hover={{ color: 'atomicBlue.400' }}
    onClick={onClick}
    borderRadius={0}
    transition="0.2s border-width, 0.2s color"
    borderBottomWidth={{ base: 0, sm: isActive ? '3px' : '0px' }}
    borderColor={'atomicBlue.400'}
  >
    {children}
  </UiButton>
)
