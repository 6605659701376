import { FormControl, Heading, HStack, Icon, InputGroup } from '@chakra-ui/react'
import type { UiSubNavbarProps } from '@postal-io/postal-ui'
import { ZInput, ZInputLeftIcon, ZLink, ZSubNavbar } from '@postal-io/postal-ui'
import { useCollectionPermissions } from 'hooks'
import type { ChangeEvent } from 'react'
import React, { useContext } from 'react'
import { MdAddCircleOutline, MdSearch } from 'react-icons/md'
import { CollectionsContext } from './useCollections'

export const CollectionsSearchBar: React.FC<UiSubNavbarProps & { onCreate: () => void }> = ({ onCreate, ...props }) => {
  const {
    collectionsFilters: { filters, updateFilter },
  } = useContext(CollectionsContext)

  const { canCreate } = useCollectionPermissions()

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => updateFilter?.('q', e.target.value)

  return (
    <ZSubNavbar
      left={
        <Heading
          as="h2"
          fontSize="h5"
        >
          Collections
        </Heading>
      }
      center={
        <FormControl id="search">
          <InputGroup>
            <ZInputLeftIcon icon={<MdSearch size="16px" />} />
            <ZInput
              name="search"
              placeholder="Search"
              w="440px"
              value={filters?.q ?? ''}
              onChange={handleSearch}
            />
          </InputGroup>
        </FormControl>
      }
      right={
        <HStack spacing={5}>
          {canCreate && (
            <ZLink
              onClick={onCreate}
              display="flex"
              alignItems="center"
            >
              <Icon
                as={MdAddCircleOutline}
                mr={2}
                verticalAlign="middle"
              />{' '}
              Create a Collection
            </ZLink>
          )}
        </HStack>
      }
      px={0}
      gridProps={{ py: 4 }}
      {...props}
    />
  )
}
