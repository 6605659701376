import { Divider, List, ListItem } from '@chakra-ui/react'
import { ZActionLink } from '@postal-io/postal-ui'
import { useAcl, useBulkSendPermissions } from 'hooks'
import { Permission } from 'lib/permissions'
import { useMemo } from 'react'
import { MdOutlineDashboard, MdPalette } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'
import { OrdersTypes, ORDERS_TYPE_CONFIG } from './data'

export const OrdersSidebarBlocks = () => {
  const { hasBulkSend } = useBulkSendPermissions()
  const { pathname } = useLocation()
  const isActivePath = (path: string) => pathname === path
  const { hasFeature, hasPermission } = useAcl()

  const hasSwagEditor = hasFeature('swagEditor') && hasPermission(Permission.SwagEditor)

  const ordersConfigs = useMemo(
    () =>
      hasBulkSend
        ? Object.values(ORDERS_TYPE_CONFIG)
        : Object.keys(ORDERS_TYPE_CONFIG)
            .filter((key) => key !== OrdersTypes.BulkSends)
            .map((key) => ORDERS_TYPE_CONFIG[key as keyof typeof ORDERS_TYPE_CONFIG]),
    [hasBulkSend]
  )

  return (
    <List
      spacing={4}
      position="sticky"
      top={5}
    >
      <ListItem>
        <ZActionLink
          as={Link}
          leftIcon={<MdOutlineDashboard />}
          isActive={isActivePath('/orders')}
          to="/orders"
        >
          Overview
        </ZActionLink>
      </ListItem>
      <Divider />
      {ordersConfigs.map(({ path, text, icon: Icon }) => (
        <ListItem key={path}>
          <ZActionLink
            as={Link}
            leftIcon={<Icon />}
            isActive={isActivePath(path)}
            to={path}
          >
            {text}
          </ZActionLink>
        </ListItem>
      ))}
      {hasSwagEditor && (
        <ListItem>
          <ZActionLink
            as={Link}
            leftIcon={<MdPalette />}
            isActive={isActivePath('/swag-creator/account/orders')}
            to="/swag-creator/account/orders"
          >
            Swag
          </ZActionLink>
        </ListItem>
      )}
    </List>
  )
}
