import type { Campaign } from 'api'
import { useMemo } from 'react'
const CAN_EDIT_STATUS = ['PENDING', 'SCHEDULED', 'CANCELED']

export const canEditCampaign = (campaign: Campaign) => CAN_EDIT_STATUS.includes(campaign?.status)

export const useCampaignHelper = (campaign: Campaign | undefined) => {
  const canEdit = useMemo(() => campaign && canEditCampaign(campaign), [campaign])
  return { canEdit }
}
