import { Flex, Heading, Text } from '@chakra-ui/react'
import { UiButton, useAlerts } from '@postal-io/postal-ui'
import { PageTitle, useSession } from 'hooks'
import React, { useState } from 'react'
import type { InviteInfo } from './types'

interface WelcomeExistingUserProps {
  inviteId: string
  inviteInfo: InviteInfo
}

export const WelcomeExistingUser: React.FC<WelcomeExistingUserProps> = ({ inviteId, inviteInfo }) => {
  const Alert = useAlerts()
  const [loading, setLoading] = useState(false)
  const { inviteComplete } = useSession()
  const { accountName, inviterFirstName, inviterLastName, teamName } = inviteInfo

  const onSubmit = async () => {
    setLoading(true)
    try {
      await inviteComplete({
        id: inviteId,
        firstName: inviterFirstName,
        lastName: inviterLastName,
      })
      Alert.success('Invitation Accepted')
      setLoading(false)
      window.location.href = '/'
    } catch (err) {
      Alert.warning(err)
      setLoading(false)
    }
  }

  return (
    <>
      <PageTitle
        title="Invitation"
        section="Welcome"
      />
      <Flex
        w="80%"
        maxW="500px"
        alignItems="center"
        flexDir="column"
        textAlign="center"
        color="white"
      >
        <Heading
          fontWeight={600}
          as="h1"
          size="md"
          my={12}
          textAlign="center"
          whiteSpace="nowrap"
        >
          Welcome!
        </Heading>
        <Text>
          You have been invited by{' '}
          <strong>
            {inviterFirstName} {inviterLastName}
          </strong>{' '}
          to join{' '}
          {teamName && (
            <>
              the <strong>{teamName}</strong> Team of{' '}
            </>
          )}
          <strong>{accountName}</strong> @ Postal.
        </Text>
        <UiButton
          type="submit"
          colorScheme="secondary"
          mt={12}
          size="body-lg"
          h="56px"
          w="100%"
          textTransform="none"
          isDisabled={loading}
          isLoading={loading}
          onClick={onSubmit}
        >
          Accept Invitation
        </UiButton>
      </Flex>
    </>
  )
}
