import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Grid } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiTruncate, ZText } from '@postal-io/postal-ui'
import React, { useMemo } from 'react'
import { MdOutlineLocalShipping } from 'react-icons/md'
import type { ApprovedPostal, MagicEvent, MagicEventInstance } from '../../../api'
import { GetMarketplaceProductDocument } from '../../../api'
import { EventDateTime, EventDuration, EventType } from '../EventInfo'
import { EventsApprovedPostalEventParticipants } from './EventsApprovedPostalEventParticipants'
import { EventsApprovedPostalEventRegistration } from './EventsApprovedPostalEventRegistration'

interface EventsApprovedPostalEventInfoV2Props extends BoxProps {
  postal: ApprovedPostal
}

export const EventsApprovedPostalEventInfo: React.FC<EventsApprovedPostalEventInfoV2Props> = ({ postal, ...rest }) => {
  const getMarketplaceProduct = useGraphqlQuery(
    GetMarketplaceProductDocument,
    { id: postal.marketplaceProductId },
    { enabled: !!postal.marketplaceProductId }
  )

  const eventDetails = useMemo(() => {
    return getMarketplaceProduct.data?.getMarketplaceProduct.eventDetails || ({} as MagicEvent)
  }, [getMarketplaceProduct.data?.getMarketplaceProduct.eventDetails])

  const event = postal.event || ({} as MagicEventInstance)
  const max = event.requestedAttendeeCount || eventDetails.maximumAttendees
  const eventLength = event.eventLengthMinutes || eventDetails.eventLengthMinutes

  return (
    <Box {...rest}>
      <Grid
        templateColumns="repeat(3, 1fr)"
        gap={6}
      >
        {event.eventDateTime && <EventDateTime dateTime={event.eventDateTime} />}
        <EventDuration duration={eventLength} />
        <EventsApprovedPostalEventParticipants
          spotsRemaining={event.spotsRemaining}
          maximumAttendees={max}
        />
        {!!eventDetails.requirements?.length && <EventType requirements={eventDetails.requirements} />}
        <EventsApprovedPostalEventRegistration
          status={event.status}
          lastRegistrationDateTime={event.lastRegistrationDateTime}
        />
        {postal.geographicMarkets?.length ? (
          <Flex
            alignItems="flex-start"
            gap={2}
            maxW="230px"
          >
            <MdOutlineLocalShipping size="20px" />
            <ZText overflowWrap="anywhere">
              Ships to:{' '}
              <UiTruncate
                length={100}
                text={postal.geographicMarkets?.join(', ')}
              />
            </ZText>
          </Flex>
        ) : null}
      </Grid>
    </Box>
  )
}
