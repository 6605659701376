import { Divider, Flex, Grid, Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiPagination,
  UiProgressBar,
  useAlertError,
  useAlerts,
  usePagination,
  ZCard,
  ZCardHeader,
  ZConfirm,
  ZModalDialog,
  ZText,
} from '@postal-io/postal-ui'
import type { ActivityStreamFilterInput, ActivityType } from 'api'
import { ActivityStreamOrderByInput, GetContactDocument, SearchActivityStreamDocument } from 'api'
import { ZStatusTag } from 'components/Common/ZComponents'
import { format as dateFnsFormat } from 'date-fns'
import { useAcl, useHelp, useNavigateRetryOrder } from 'hooks'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ContactEdit } from '../../Contact'
import { ActivityStreamItem } from './ActivityStreamItem'

const STATUS_MAP = {
  DELIVERED: {
    color: 'vendorGreen.50',
    hoverColor: 'vendorGreen.100',
    text: 'Delivered',
  },
  ACTION: {
    color: 'atomicYellow.50',
    hoverColor: 'atomicYellow.100',
    text: 'Action',
  },
  UPDATE: {
    color: 'atomicBlue.50',
    hoverColor: 'vendorGreen.100',
    text: 'Update',
  },
  TEAM: {
    color: 'atomicPurple.50',
    hoverColor: 'vendorGreen.100',
    text: 'Team',
  },
} as any

const LIMIT = 300
const PAGE_SIZE = 10

interface ActivityStreamListV2Props {
  teamIds?: string[]
  userIds?: string[]
  types?: ActivityType[]
}

export const ActivityStreamList: React.FC<ActivityStreamListV2Props> = ({ teamIds, userIds, types }) => {
  const Alert = useAlerts()
  const navigate = useNavigate()
  const editContact = useDisclosure()
  const askAdmin = useDisclosure()
  const updateBudget = useDisclosure()
  const updateFunds = useDisclosure()
  const updateProfile = useDisclosure()
  const autoFix = useDisclosure()

  const filter = useMemo(() => {
    const newFilter: ActivityStreamFilterInput = { parentActivityId: { eq: null } }
    if (teamIds?.length) newFilter.teamId = { in: teamIds }
    if (userIds?.length) newFilter.userId = { in: userIds }
    if (types?.length) newFilter.type = { in: types }
    return newFilter
  }, [teamIds, types, userIds])

  const [contactId, setContactId] = useState('')

  const { data, isLoading, isFetching, error } = useGraphqlQuery(
    SearchActivityStreamDocument,
    {
      filter,
      orderBy: ActivityStreamOrderByInput.CreatedDesc,
      limit: LIMIT,
    },
    { keepPreviousData: true }
  )

  useAlertError(error)

  const [items, pager] = usePagination(data?.searchActivityStream ?? [], PAGE_SIZE)

  const getContact = useGraphqlQuery(GetContactDocument, { id: contactId }, { enabled: !!contactId })
  const contact = getContact.data?.getContact
  useAlertError(getContact.error)

  const openContactEdit = (contactId: string) => {
    setContactId(contactId)
    editContact.onOpen()
  }

  const { hasPermission } = useAcl()
  const contactPermission = hasPermission('contacts.update')
  const budgetPermission = hasPermission('billing.update')

  const { sendMessage } = useHelp()

  const retryOrderLink = useNavigateRetryOrder()

  const handleBudegetNav = () => navigate('/billing/budgeting', { state: { returnTo: 'Recent Activity' } })
  const handleFundsNav = () => navigate('/billing/accounts', { state: { returnTo: 'Recent Activity' } })
  const handleProfileNav = () => navigate(`/profile`, { state: { returnTo: 'Recent Activity' } })

  const handleActionResponse = (callouts: any[], type: string, action?: string) => {
    const calloutAction = action
      ? callouts?.find((item) => item.action === action)
      : callouts?.find((item) => item.action)

    if (type === 'ACTION' && calloutAction) {
      switch (calloutAction?.action) {
        case 'contact.support':
          return sendMessage(`I need help with an issue.`)
        case 'autoFix':
          return autoFix.onOpen()
        case 'user.profile':
          return updateProfile.onOpen()
        case 'update.contact':
          return contactPermission
            ? openContactEdit(calloutAction.dataId)
            : Alert.warning('Update contact permission denied')
        case 'increase.budget':
          return budgetPermission ? updateBudget.onOpen() : askAdmin.onOpen()
        case 'increase.funds':
          return budgetPermission ? updateFunds.onOpen() : askAdmin.onOpen()
        case 'postalFulfillment.retryOrder':
          navigate(retryOrderLink({ orderId: calloutAction.dataId, returnTo: 'Activity Stream' }))
          return
        default:
          return
      }
    }
    return
  }

  return (
    <ZCard
      isLoading={isLoading}
      variant="form"
      display="flex"
      flexFlow="column"
    >
      {isFetching && (
        <UiProgressBar
          pos="absolute"
          top={0}
          left={0}
          right={0}
        />
      )}

      <ZCardHeader
        fontSize="lg"
        fontWeight="normal"
        p={4}
      >
        Recent Activity
      </ZCardHeader>

      {items?.length < 1 && !isFetching ? (
        <ZText
          flex="1 0"
          fontSize="lg"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={8}
        >
          No Recent Activity Found
        </ZText>
      ) : (
        <Stack
          divider={<Divider />}
          mt={2}
          px={4}
        >
          {items?.map((item, idx) => {
            const hasActionButton = item.type === 'ACTION' && item.callouts?.find((item) => item.action)
            return (
              <Grid
                key={idx}
                templateColumns="7fr 150px 1fr"
                data-testid="ActivityStreamList_item"
                alignItems="center"
                gap={8}
                py={2}
              >
                <ActivityStreamItem
                  message={item.message}
                  count={item.count}
                  callouts={item.callouts}
                  onClick={handleActionResponse}
                />
                <Flex
                  fontSize="sm"
                  color="atomicGray.500"
                  gap={4}
                >
                  <ZText color="inherit">{dateFnsFormat(new Date(item.created?.dateTime), 'M/d/y')}</ZText>
                  <ZText color="inherit">{dateFnsFormat(new Date(item.created?.dateTime), 'h:mm aaa')}</ZText>
                </Flex>
                <ZStatusTag
                  label={STATUS_MAP[item.type].text}
                  colorScheme={STATUS_MAP[item.type].color}
                  borderColor={STATUS_MAP[item.type].color}
                  borderWidth="2px"
                  fontWeight="normal"
                  _hover={{
                    cursor: hasActionButton ? 'pointer' : 'default',
                    borderColor: STATUS_MAP[item.type].color,
                    backgroundColor: hasActionButton ? 'white' : STATUS_MAP[item.type].color,
                    color: hasActionButton ? STATUS_MAP[item.type].color : 'unset',
                  }}
                  onClick={() => handleActionResponse(item?.callouts ?? [], item.type)}
                />
              </Grid>
            )
          })}
        </Stack>
      )}

      <Flex
        justifyContent="center"
        alignItems="flex-end"
        pt={pager.pages > 0 ? 4 : 0}
        flex={pager.pages ? '1 0' : undefined}
      >
        <UiPagination {...pager} />
      </Flex>

      {contactId && contact && editContact.isOpen && (
        <ContactEdit
          contact={contact}
          isOpen={editContact.isOpen}
          onClose={editContact.onClose}
          onComplete={() => setContactId('')}
        />
      )}
      {askAdmin.isOpen && (
        <ZModalDialog
          title="Contact Admin"
          isOpen={askAdmin.isOpen}
          onClose={askAdmin.onClose}
        >
          <ZText>Please contact your Administrator to resolve this action.</ZText>
        </ZModalDialog>
      )}
      {autoFix.isOpen && (
        <ZModalDialog
          title="Automatic Task"
          isOpen={autoFix.isOpen}
          onClose={autoFix.onClose}
        >
          <ZText>
            The system will automatically retry implementing your order. If the order processing continues to fail, you
            will be alerted.
          </ZText>
        </ZModalDialog>
      )}
      {updateBudget.isOpen && (
        <ZConfirm
          title="Update Budget"
          isOpen={updateBudget.isOpen}
          onConfirm={handleBudegetNav}
          onClose={updateBudget.onClose}
        >
          <ZText>
            This action will navigate you to your{' '}
            <ZText
              display="inline"
              fontWeight="bold"
            >
              Budget
            </ZText>{' '}
            page.
          </ZText>
        </ZConfirm>
      )}
      {updateFunds.isOpen && (
        <ZConfirm
          title="Update Funds"
          isOpen={updateFunds.isOpen}
          onConfirm={handleFundsNav}
          onClose={updateFunds.onClose}
        >
          <ZText>
            This action will navigate you to your{' '}
            <ZText
              display="inline"
              fontWeight="bold"
            >
              Funds
            </ZText>{' '}
            page.
          </ZText>
        </ZConfirm>
      )}
      {updateProfile.isOpen && (
        <ZConfirm
          title="Update Profile"
          isOpen={updateProfile.isOpen}
          onConfirm={handleProfileNav}
          onClose={updateProfile.onClose}
        >
          <ZText>
            This action will navigate you to your{' '}
            <ZText
              display="inline"
              fontWeight="bold"
            >
              Profile
            </ZText>{' '}
            page.
          </ZText>
        </ZConfirm>
      )}
    </ZCard>
  )
}
