import { Box, Flex, FormControl, Grid, InputGroup, Spinner } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButtonScrollTop,
  UiSidePanelContainer,
  useAlertError,
  useInfiniteScroll,
  ZInput,
  ZInputLeftIcon,
  ZText,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, ApprovedPostalFilterInput } from 'api'
import { DefaultOrderByInput, SearchApprovedPostalsDocument, SearchFavoriteItemsDocument } from 'api'
import { MarketplaceCardPostal } from 'components/Marketplace/MarketplaceCardPostal'
import { useExtension, usePostalFilters } from 'hooks'
import { MAX_FAVORITES } from 'hooks/usePostalFavoriteStatus'
import { uniqBy } from 'lodash'
import { useMemo, useState } from 'react'
import { MdKeyboardArrowLeft, MdOutlineSearch } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ExtHeader } from '../Main/ExtHeader'

const LIMIT = 6

export const ExtViewFavorites = () => {
  const { contactId } = useExtension()

  const navigate = useNavigate()
  const [searchName, setSearchName] = useState('')

  // load approved postals
  const searchFavorites = useGraphqlInfiniteQuery(
    SearchFavoriteItemsDocument,
    {
      limit: MAX_FAVORITES,
      orderBy: DefaultOrderByInput.IdAsc,
    }
    // { keepPreviousData: true }
  )
  useAlertError(searchFavorites.error)

  const favorites = useMemo(
    () => searchFavorites.mergedData?.searchFavoriteItems ?? [],
    [searchFavorites.mergedData?.searchFavoriteItems]
  )

  const staticVariables = useMemo(
    () => ({
      id: { in: favorites?.map((fav) => fav.approvedPostalId) },
      name: { contains: searchName },
    }),
    [favorites, searchName]
  )

  const filter = usePostalFilters<ApprovedPostalFilterInput>({
    staticVariables,
  })

  const postalsQuery = useGraphqlQuery(SearchApprovedPostalsDocument, { filter: filter.graphqlFilter })

  const postals = useMemo(
    () => postalsQuery.data?.searchApprovedPostals ?? [],
    [postalsQuery.data?.searchApprovedPostals]
  )

  useAlertError(postalsQuery.error)

  const favoritePostals = useMemo(
    () =>
      uniqBy(favorites, 'approvedPostalId')
        .map((f) => {
          const matchedPostal = postals.find((p) => p.id === f.approvedPostalId)
          return {
            ...matchedPostal,
            favoriteItemId: f.id,
          }
        })
        .filter((p) => !!p.id),
    [favorites, postals]
  )

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchFavorites.hasNextPage,
    loadMore: searchFavorites.fetchNextPage,
    loading: searchFavorites.isFetching,
  })

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearchName(value)
  }

  const handleBack = () => {
    if (contactId) {
      navigate(`/extension/contacts/${contactId}`)
    } else {
      navigate(`/extension/`)
    }
  }

  const handleSelect = (postal: ApprovedPostal) => {
    navigate(`/extension/marketplace/${postal.id}`)
  }

  return (
    <Box p={4}>
      <ExtHeader>
        <Flex
          alignItems="center"
          columnGap="10px"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="30px"
            height="30px"
            bg="atomicGray.50"
            borderRadius="50%"
            _hover={{ bg: 'atomicGray.100', cursor: 'pointer' }}
            onClick={handleBack}
          >
            <MdKeyboardArrowLeft
              size="16px"
              color="#8492A6"
            />
          </Box>
          <ZText size="lg">Favorites</ZText>
        </Flex>
      </ExtHeader>
      <FormControl
        id="searchName"
        my={4}
      >
        <InputGroup>
          <ZInputLeftIcon icon={<MdOutlineSearch size="16px" />} />
          <ZInput
            placeholder="Search"
            name="searchName"
            value={searchName}
            onChange={handleSearchInput}
          />
        </InputGroup>
      </FormControl>
      <UiSidePanelContainer gridGap={16}>
        <Flex
          direction="column"
          w="100%"
          ref={topRef}
          style={{ scrollMargin: '20px' }}
        >
          {(searchFavorites.isFetching || postalsQuery.isFetching) && favoritePostals.length < 1 && (
            <Box
              textAlign="center"
              mt={4}
            >
              <Spinner />
            </Box>
          )}
          {!(searchFavorites.isFetching || postalsQuery.isFetching) && favoritePostals.length < 1 && (
            <ZText fontSize="md">No available items</ZText>
          )}
          <Grid
            templateColumns={`repeat(auto-fill, minmax(150px, 1fr))`}
            gap={4}
          >
            {favoritePostals?.map((postal: any) => (
              <MarketplaceCardPostal
                key={postal.id}
                postal={postal}
                onSelect={handleSelect}
                favoriteItemId={postal.favoriteItemId}
                truncateLength={33}
              />
            ))}
          </Grid>
          {favoritePostals.length >= LIMIT && (
            <Box
              ref={bottomRef}
              mb={8}
              position="relative"
            >
              <UiButtonScrollTop
                onClick={scrollTop}
                position="absolute"
                top="0px"
                right="0px"
                isLoading={searchFavorites.isFetching || postalsQuery.isFetching}
                aria-label="scroll button"
              />
            </Box>
          )}
        </Flex>
      </UiSidePanelContainer>
    </Box>
  )
}
