import { Fade, Flex, VStack } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlertError, ZCard, ZLink, ZMoney, ZText } from '@postal-io/postal-ui'
import type { BillingAccount } from 'api'
import { Currency, GetBillingAccountDocument } from 'api'
import { useAcl } from 'hooks'
import { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface TeamAccountBlockProps {
  billingAccountId?: string
}

export const TeamAccountBlock: React.FC<TeamAccountBlockProps> = ({ billingAccountId }) => {
  const [state, setState] = useState<'DEFAULT' | 'HOVER'>('DEFAULT')
  const navigate = useNavigate()

  const { hasPermission } = useAcl()
  const canUpdate = hasPermission('billing.update')

  const getBillingAccount = useGraphqlQuery(GetBillingAccountDocument, { id: billingAccountId as string })
  const { name, currency, balance }: Partial<BillingAccount> = useMemo(
    () => getBillingAccount.data?.getBillingAccount ?? {},
    [getBillingAccount.data?.getBillingAccount]
  )
  useAlertError(getBillingAccount.error)

  const { pathname } = useLocation()

  const handleEditAccount = () => {
    const returnTo = pathname.startsWith('/teams') ? 'Team' : 'Funds'
    navigate(`/billing/accounts/${billingAccountId}`, { state: { returnTo } })
  }

  const UsdCover = () => {
    return (
      <Fade in={canUpdate && state === 'HOVER'}>
        <Flex
          as="button"
          pos="absolute"
          zIndex={1}
          justify="center"
          align="center"
          borderRadius="2px"
          top={0}
          left={0}
          bg="rgba(255,255,255,0.9)"
          w="100%"
          h="100%"
          color="atomicBlue.400"
          onClick={handleEditAccount}
        >
          <ZText
            color="inherit"
            fontWeight="bold"
          >
            Edit Account
          </ZText>
        </Flex>
      </Fade>
    )
  }

  const NonUsdCover = () => {
    return (
      <Fade in={canUpdate && state === 'HOVER'}>
        <Flex
          py={4}
          px={4}
          pos="absolute"
          borderRadius="2px"
          zIndex={1}
          justify="center"
          align="center"
          borderLeft="5px solid"
          borderColor="atomicRed.200"
          top={0}
          left={0}
          bg="rgb(255,255,255)"
          w="100%"
          h="100%"
        >
          <ZText
            fontWeight="bold"
            fontSize="sm"
            lineHeight="18px"
          >
            You will need to add funds via invoicing before you can send with this account.{' '}
            <ZLink
              fontSize="sm"
              onClick={handleEditAccount}
            >
              Click here
            </ZLink>{' '}
            to start the process.
          </ZText>
        </Flex>
      </Fade>
    )
  }

  return (
    <>
      <ZCard
        pos="relative"
        onMouseOver={() => setState('HOVER')}
        onMouseLeave={() => setState('DEFAULT')}
        overflow="hidden"
        variant="form"
        borderRadius="2px"
      >
        <Flex flexDirection={{ base: 'column', xl: 'row' }}>
          <Flex
            p={4}
            flexBasis="50%"
            alignItems="center"
            borderRight="1px solid"
            borderColor="atomicGray.100"
            borderRightWidth={{ base: 0, xl: '1px' }}
          >
            <VStack
              spacing={2}
              w="100%"
            >
              <ZText
                fontSize="14px"
                fontWeight={350}
                wordBreak="break-word"
              >
                {name}
              </ZText>
            </VStack>
          </Flex>
          <Flex
            p={4}
            justify="center"
            align="center"
            flexDir="column"
            borderColor="atomicGray.100"
            borderTopWidth={{ base: '1px', xl: 0 }}
          >
            <ZText
              fontSize="14px"
              fontWeight="bold"
              whiteSpace="nowrap"
            >
              Current Balance
            </ZText>
            <ZMoney
              cents={balance ?? 0}
              locale={currency ?? undefined}
              currency={currency}
              color="atomicGray.500"
              fontWeight={500}
              fontSize="23px"
              round
            />
          </Flex>
        </Flex>
        {canUpdate && (
          <>{currency === Currency.Usd ? <UsdCover /> : (balance ?? 0) > 0 ? <UsdCover /> : <NonUsdCover />}</>
        )}
      </ZCard>
    </>
  )
}
