import { Container, Stack } from '@chakra-ui/react'
import { PageTitle } from 'hooks'
import { Outlet } from 'react-router-dom'
import { OrdersTable } from './OrdersTable'

export const OrderReport = () => {
  return (
    <>
      <PageTitle
        title="Order Report"
        section="Reporting"
      />
      <Container
        maxW="1440px"
        px={0}
        pt={2}
      >
        <Stack
          spacing={8}
          data-testid="orderReport"
        >
          <OrdersTable />
        </Stack>
      </Container>
      <Outlet />
    </>
  )
}
