import type { FlexProps } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalFooter,
  UiModalFooterButtons,
  UiModalHeader,
  UiModalOverlay,
} from '@postal-io/postal-ui'
import { CreateEmailPreviewUrlsDocument, GetAccountDocument } from 'api'
import React, { useMemo } from 'react'
import { useMeasure } from 'react-use'
import type { ApprovedPostal } from '../../api/index'
import { Iframe } from '../Common'
import type { CustomEmail } from './customEmailData'
import { CustomEmailConfig } from './customEmailData'

interface CustomizeEmailMessagingPreviewProps extends FlexProps {
  emailType: CustomEmail
  postal: ApprovedPostal
  variantId?: string
  isOpen: boolean
  onClose: () => void
}

export const CustomizeEmailMessagingPreview: React.FC<CustomizeEmailMessagingPreviewProps> = ({
  emailType,
  postal,
  variantId,
  isOpen,
  onClose,
}) => {
  const [ref, { height }] = useMeasure()

  const accountQuery = useGraphqlQuery(GetAccountDocument)
  const account = useMemo(() => accountQuery.data?.getAccount, [accountQuery.data?.getAccount])

  const previewQuery = useGraphqlQuery(CreateEmailPreviewUrlsDocument, {
    data: {
      approvedPostalId: postal.id,
      approvedPostalVariantId: variantId,
      emailTemplateId: account?.emailTemplateId,
    },
    renderSubjectLine: true,
  })

  const previewUrl = useMemo(() => {
    const preview = previewQuery.data?.createEmailPreviewUrls?.find(
      (url) => url.emailType === CustomEmailConfig[emailType].key
    )
    return preview?.htmlUrl || ''
  }, [previewQuery.data?.createEmailPreviewUrls, emailType])

  return (
    <>
      <UiModal
        size="full"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <UiModalOverlay />
        <UiModalContent>
          <UiModalHeader>
            Email Messaging Preview
            <UiModalCloseButton />
          </UiModalHeader>
          <UiModalBody
            minH="400px"
            /* @ts-ignore */
            ref={ref}
          >
            <Iframe
              src={previewUrl}
              minH={height}
              maxW="90rem"
              mx="auto"
              bg="gray.100"
            />
          </UiModalBody>
          <UiModalFooter>
            <UiModalFooterButtons>
              <UiButton
                colorScheme="gray"
                onClick={onClose}
              >
                Done
              </UiButton>
            </UiModalFooterButtons>
          </UiModalFooter>
        </UiModalContent>
      </UiModal>
    </>
  )
}
