import { ZFormLabel, ZSelect } from '@postal-io/postal-ui'
import React from 'react'
import { HorizontalList } from './HorizontalList'

const drawModeOptions = ['NONE', 'FILL', 'CONTAIN', 'COVER', 'SCALE_DOWN']

export interface ImageEditProps {
  settings: any
  onChange: (e: any) => void
}

export const ImageEdit: React.FC<ImageEditProps> = ({ settings = {}, onChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => onChange({ ...settings, drawMode: e.target.value })
  if (!settings?.drawMode) return null
  return (
    <HorizontalList>
      <ZFormLabel htmlFor="imageDrawMode">Draw Mode</ZFormLabel>
      <ZSelect
        size="sm"
        name="drawMode"
        id="imageDrawMode"
        onChange={handleChange}
        value={settings.drawMode}
      >
        {drawModeOptions.map((mode) => (
          <option
            key={mode}
            value={mode}
          >
            {mode}
          </option>
        ))}
      </ZSelect>
    </HorizontalList>
  )
}
