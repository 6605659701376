import { Box, ButtonGroup, Flex, Icon, useDisclosure } from '@chakra-ui/react'
import {
  arrayMoveImmutable,
  UiIconButton,
  UiSortableDragHandle,
  UiSortableDragHandleIcon,
  UiSortableItem,
  UiSortableList,
  ZButton,
  ZConfirm,
  ZText,
} from '@postal-io/postal-ui'
import React, { useMemo, useState } from 'react'
import { MdOutlineDelete, MdOutlineLock, MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md'
import { getElementDisplayName } from './Assets'

const iconProps = {
  w: '24px',
  h: '24px',
}

export interface ItemProps {
  element: any
  onClick: (e: any) => void
  isActive: boolean
}

const Item: React.FC<ItemProps> = ({ element, onClick, isActive }) => {
  const handleClick = () => onClick(element)
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      border="1px"
      borderColor="gray.200"
      borderRadius="6px"
      py={2}
      px={3}
      mb={2}
    >
      <Box
        data-testid="Layers_Button_Lock"
        as={MdOutlineLock}
      />
      <ZButton
        flex="3"
        variant="link"
        minW={0}
        fontWeight="normal"
        fontSize="md"
        onClick={handleClick}
        isActive={isActive}
      >
        {getElementDisplayName(element.name)}
      </ZButton>
      <ButtonGroup spacing={0}>
        <UiIconButton
          aria-label="hide or view layer"
          data-testid={element.hidden ? 'Layers_Button_EyeSlash' : 'Layers_Button_Eye'}
          size="xs"
          icon={
            element.hidden ? (
              <Icon
                as={MdOutlineVisibilityOff}
                {...iconProps}
              />
            ) : (
              <Icon
                as={MdOutlineVisibility}
                {...iconProps}
              />
            )
          }
          isDisabled
          variant="link"
          color={element.hidden ? 'atomicGray.400' : 'atomicBlue.400'}
        />
        <UiIconButton
          aria-label="remove layer"
          data-testid="Layers_Button_Trash"
          size="xs"
          icon={
            <Icon
              as={MdOutlineDelete}
              {...iconProps}
            />
          }
          isDisabled
          variant="link"
          color="atomicBlue.400"
        />
      </ButtonGroup>
    </Flex>
  )
}

export interface SortableItemProps extends ItemProps {
  onRemove: (e: any) => void
  onVisibility: (e: any) => void
}

const SortableItem = ({ element, isActive, onClick, onRemove, onVisibility }: SortableItemProps) => {
  const handleClick = () => onClick(element)
  const handleRemove = () => onRemove(element)
  const handleVisibility = () => onVisibility(element)

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      border="1px"
      borderColor="gray.200"
      borderRadius="6px"
      py={2}
      px={3}
      mb={2}
    >
      <UiSortableDragHandle
        id={element.id}
        data-testid="Layers_Button_Drag"
      >
        <UiSortableDragHandleIcon
          cursor="grab"
          boxSize="20px"
          aria-label="Drag step"
          color="secondary.500"
          mr={4}
        />
      </UiSortableDragHandle>
      <ZButton
        flex="3"
        variant="link"
        minW={0}
        fontWeight="normal"
        fontSize="md"
        onClick={handleClick}
        isActive={isActive}
      >
        {getElementDisplayName(element.name)}
      </ZButton>
      <ButtonGroup spacing={0}>
        <UiIconButton
          aria-label="hide or view layer"
          data-testid={element.hidden ? 'Layers_Button_EyeSlash' : 'Layers_Button_Eye'}
          size="xs"
          icon={
            element.hidden ? (
              <Icon
                as={MdOutlineVisibilityOff}
                {...iconProps}
              />
            ) : (
              <Icon
                as={MdOutlineVisibility}
                {...iconProps}
              />
            )
          }
          onClick={handleVisibility}
          variant="link"
          color={element.hidden ? 'atomicGray.400' : 'atomicBlue.400'}
        />
        <UiIconButton
          aria-label="remove layer"
          data-testid="Layers_Button_Trash"
          size="xs"
          icon={
            <Icon
              as={MdOutlineDelete}
              {...iconProps}
            />
          }
          onClick={handleRemove}
          variant="link"
          color="atomicBlue.400"
        />
      </ButtonGroup>
    </Flex>
  )
}

export interface LayersProps {
  elements: any[]
  dispatch: (e: { type: string; payload: any }) => void
  selected: any
}
export const Layers: React.FC<LayersProps> = ({ elements = [], dispatch, selected }) => {
  const [removeElement, setRemoveElement] = useState(null)
  const { isOpen, onClose, onOpen } = useDisclosure()

  const reversedElements = useMemo(() => {
    return [...elements.filter((e) => !e.locked), ...elements.filter((e) => e.locked)].reverse()
  }, [elements])

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const payload = arrayMoveImmutable([...reversedElements], oldIndex, newIndex).reverse()
    dispatch({ type: 'ORDER', payload })
    document.body.style.cursor = 'default'
  }

  const handleClick = (element: any) => {
    dispatch({ type: 'TOGGLE_SELECTED', payload: element })
  }

  const handleRemove = (element: any) => {
    setRemoveElement(element)
    onOpen()
  }

  const doRemove = () => {
    removeElement && dispatch({ type: 'REMOVE', payload: removeElement })
    setRemoveElement(null)
    onClose()
  }

  const handleVisibility = (element: any) => {
    dispatch({ type: 'TOGGLE_VISIBILITY', payload: element })
  }

  return (
    <Box p={6}>
      {elements.length > 0 && (
        <ZText
          mb={2}
          fontSize="md"
        >
          Top
        </ZText>
      )}

      <UiSortableList
        items={reversedElements}
        onSortEnd={onSortEnd}
        restrict="y"
      >
        {reversedElements.map((element) => {
          return element.locked ? (
            <Item
              onClick={handleClick}
              isActive={selected?.id === element.id}
              key={element.id}
              element={element}
            />
          ) : (
            <UiSortableItem
              id={element.id}
              key={element.id}
              isDisabled={!!element.locked}
              useDragHandle
              bg="white"
            >
              <SortableItem
                element={element}
                isActive={selected?.id === element.id}
                onClick={handleClick}
                onRemove={handleRemove}
                onVisibility={handleVisibility}
              />
            </UiSortableItem>
          )
        })}
      </UiSortableList>
      <ZConfirm
        title="Confirm Deletion"
        isOpen={isOpen}
        onClose={onClose}
        buttonColor="atomicRed"
        buttonText="Delete"
        onConfirm={doRemove}
      >
        <ZText>Are you sure you want to delete this layer?</ZText>
      </ZConfirm>
    </Box>
  )
}
