import { useGraphqlFetch } from '@postal-io/postal-graphql'
import { downloadLink, useAlerts } from '@postal-io/postal-ui'
import type { BackgroundTask } from 'api'
import { BillingTransactionType, FetchTransactionPdfDocument } from 'api'
import api from 'api/rest'
import { useBackgroundQueue } from 'hooks'
import { useState } from 'react'

export enum ReportType {
  Order = 'Order',
  Unsubscribe = 'Unsubscribe',
}

export const useDownloadPdf = () => {
  const Alert = useAlerts()
  const { queue } = useBackgroundQueue()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchPdfQuery = useGraphqlFetch(FetchTransactionPdfDocument)

  const createDownload = async (addFundsIntentId: string, billingAccountId: string) => {
    setIsLoading(true)
    // callback for when the pdf has completed fetching
    const handleComplete = async (task: BackgroundTask) => {
      const fileName = task.outputs?.fileName // this is populated after the file is saved to the cloud
      if (!fileName) return
      try {
        // Get transactionId out of file name
        const transactionId = fileName.slice(fileName.indexOf('/') + 1, fileName.indexOf('.pdf'))

        const name = `billing_receipt_${transactionId}.pdf`
        const link = await api.previewPdf(transactionId)
        downloadLink(link, name)
      } catch (err) {
        Alert.error(err)
      }
    }

    // fetch the pdf into storage and pass the download callback to it
    const variables = {
      billingAccountId: billingAccountId,
      billingTransactionType: BillingTransactionType.AddFundsIntent,
      entityId: addFundsIntentId,
    }
    try {
      const backgroundTask = await fetchPdfQuery(variables)
      queue(backgroundTask?.fetchTransactionPdf, handleComplete)
      Alert.success('Your receipt will download shortly.')
    } catch (err) {
      Alert.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  return { createDownload, isLoading }
}
