import { Box, Flex, Text } from '@chakra-ui/react'
import {
  passwordError,
  UiButton,
  UiFormControl,
  UiGoogleCaptchaPolicies,
  UiInput,
  UiLink,
  ZInputPassword,
} from '@postal-io/postal-ui'
import { zLoginInputStyles, zLoginPasswordStyles } from 'components/Common/ZComponents'
import type { ReactElement } from 'react'
import React from 'react'
import { Link } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { useImmer } from 'use-immer'
import { LogInHeaderBlack } from './LogInHeader'
import { MainWithMarketingPane } from './Main'
import type { SignUpState } from './SignUp'
import { SsoIconOption } from './SsoOption'

const VERTICAL_BREAKPOINT_PIXELS = 900

export interface SignUpPageProps {
  highlights: string[]
  mainHeader: ReactElement
  subtitle?: string
  onSubmit: (data: SignUpState) => void
  handleSsoCallback: () => void
  loading: boolean
}

export const SignUpPage: React.FC<SignUpPageProps> = ({
  onSubmit,
  handleSsoCallback,
  loading,
  mainHeader,
  subtitle,
  highlights,
}) => {
  const { height } = useWindowSize()
  const [form, setForm] = useImmer<SignUpState>({
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    password2: '',
  })

  const error = passwordError({
    password: form.password,
    password2: form.password2,
    emailAddress: form.emailAddress,
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(form)
  }

  const isDisabled = !!form.password && !!error

  return (
    <MainWithMarketingPane>
      <LogInHeaderBlack>
        <Flex
          flexDir="column"
          alignItems="stretch"
          w="80%"
          maxW="600px"
        >
          <Box mt={10}>{mainHeader}</Box>

          {height > VERTICAL_BREAKPOINT_PIXELS && (
            <Box>
              {highlights.map((item, idx) => (
                <Text
                  key={idx}
                  fontSize={{ base: 'md', sm: 'body-md' }}
                  fontWeight="hairline"
                  color="white"
                  py={1}
                >
                  - {item}
                </Text>
              ))}
              {subtitle && (
                <Text
                  mt={4}
                  fontSize={{ base: 'md', lg: 'body-lg', xl: 'lg' }}
                  mb={6}
                  fontWeight="bold"
                  color="white"
                >
                  {subtitle}
                </Text>
              )}
            </Box>
          )}

          <Flex direction="column">
            <Flex
              alignItems="center"
              justifyContent="center"
              mb="32px"
            >
              <Text
                mr={2}
                fontWeight="hairline"
                color="white"
                fontSize="md"
              >
                Single Sign-on:
              </Text>
              <SsoIconOption onClick={handleSsoCallback} />
            </Flex>
            <Flex
              alignItems="center"
              mb="29px"
            >
              <Box
                width="60%"
                borderBottom="1px"
                borderBottomColor="rgba(255,255,255,0.2)"
              />
              <Text
                mx={6}
                textAlign="center"
                fontWeight="hairline"
                fontSize="md"
                color="white"
              >
                Or
              </Text>
              <Box
                width="60%"
                borderBottom="1px"
                borderBottomColor="rgba(255,255,255,0.2)"
              />
            </Flex>
            <form onSubmit={handleSubmit}>
              <Flex justifyContent="space-between">
                <UiFormControl
                  mb={4}
                  w="48%"
                  id="firstName"
                  isRequired
                >
                  <UiInput
                    {...zLoginInputStyles}
                    backgroundColor="none"
                    color="white"
                    size="lg"
                    data-testid="firstName"
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    value={form.firstName}
                    onChange={(e) => setForm((draft) => void (draft.firstName = e.target.value))}
                  />
                </UiFormControl>
                <UiFormControl
                  mb={4}
                  w="48%"
                  id="lastName"
                  isRequired
                >
                  <UiInput
                    {...zLoginInputStyles}
                    backgroundColor="none"
                    color="white"
                    size="lg"
                    data-testid="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    value={form.lastName}
                    onChange={(e) => setForm((draft) => void (draft.lastName = e.target.value))}
                  />
                </UiFormControl>
              </Flex>
              <UiFormControl
                mb={4}
                id="emailAddress"
                isRequired
              >
                <UiInput
                  {...zLoginInputStyles}
                  backgroundColor="none"
                  color="white"
                  size="lg"
                  data-testid="email"
                  name="emailAddress"
                  type="email"
                  placeholder="Email Address"
                  value={form.emailAddress}
                  onChange={(e) => setForm((draft) => void (draft.emailAddress = e.target.value))}
                />
              </UiFormControl>
              <UiFormControl
                mb={4}
                id="password"
                isRequired
              >
                <ZInputPassword
                  {...zLoginInputStyles}
                  {...zLoginPasswordStyles}
                  backgroundColor="none"
                  color="white"
                  size="lg"
                  data-testid="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="new-password"
                  value={form.password}
                  onChange={(e) =>
                    setForm((draft) => {
                      draft.password = e.target.value
                      draft.password2 = e.target.value
                    })
                  }
                />
              </UiFormControl>
              {!!form.password && error && (
                <Text
                  my={1}
                  fontSize="sm"
                  color="red.300"
                >
                  {error}
                </Text>
              )}
              <UiButton
                data-testid="continue"
                type="submit"
                mt={4}
                w="100%"
                colorScheme="secondary"
                isDisabled={loading || isDisabled}
                isLoading={loading}
                height="58px"
              >
                Sign Up Now
              </UiButton>
            </form>
            <Text
              fontSize="link"
              mt={4}
              color="white"
              textAlign="center"
            >
              Already have an account?
              <UiLink
                ml={2}
                as={Link}
                fontWeight="normal"
                to="/sign-in"
                color="atomicBlue.400"
              >
                Sign in
              </UiLink>
            </Text>
            <UiGoogleCaptchaPolicies
              linkProps={{ fontSize: 'xs', fontWeight: 'hairline', color: 'atomicBlue.400' }}
              mt={5}
              maxW="350px"
              textAlign="center"
              mx="auto"
              color="white"
              fontSize="xs"
              fontWeight="hairline"
            />
          </Flex>
        </Flex>
      </LogInHeaderBlack>
    </MainWithMarketingPane>
  )
}
