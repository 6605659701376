import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlerts } from '@postal-io/postal-ui'
import type { Role } from 'api'
import { GetRolePermissionsDocument, UpdateRolePermissionsDocument } from 'api'
import { Permission } from 'lib/permissions'
import { useCallback, useMemo } from 'react'
import { useAcl } from './useAcl'

export function usePermissionsV2Update(role: Role) {
  const Alert = useAlerts()
  const { hasPermission } = useAcl()
  const isRoleManager = hasPermission(Permission.RoleManager)
  const getRolePermissions = useGraphqlQuery(GetRolePermissionsDocument, { role, enabled: isRoleManager })
  const updateRolePermissions = useGraphqlMutation(UpdateRolePermissionsDocument)

  const rolePerms = useMemo(
    () => getRolePermissions.data?.getRolePermissions?.permissions,
    [getRolePermissions.data?.getRolePermissions?.permissions]
  )
  const availableRolePerms = useMemo(
    () => getRolePermissions.data?.getRolePermissions?.available.map((perm) => perm.name),
    [getRolePermissions.data?.getRolePermissions?.available]
  )

  const checkPermission = useCallback(
    (permission: Permission) => {
      return isRoleManager ? rolePerms?.includes(permission) : undefined
    },
    [isRoleManager, rolePerms]
  )

  const setPermission = useCallback(
    async (permission: Permission, value: boolean) => {
      if (!isRoleManager) return Alert.error('You do not have permission to perform this action')
      if (!availableRolePerms?.includes(permission))
        return Alert.error(`${permission} permission not available for the ${role} role`)

      // TODO: Fix this when we have base permission info available
      const addPermissions = value ? [permission] : []
      const removePermissions = [] as string[]
      try {
        const res = await updateRolePermissions.mutateAsync({ role, addPermissions, removePermissions })
        return res
      } catch (err) {
        Alert.error(err.message)
      }
    },
    [Alert, availableRolePerms, isRoleManager, role, updateRolePermissions]
  )

  return {
    permissionsLoading: getRolePermissions.isLoading || updateRolePermissions.isLoading,
    checkPermission,
    setPermission,
  }
}
