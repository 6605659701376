import { Text } from '@chakra-ui/react'

// all this highlight copy is super outdated so we're just gonna remove it for now
export const HIGHLIGHTS_1 = [
  // `Gain access to a marketplace of unique events
  //  Have an on-demand virtual subscription
  //  Enhance your relationships and create authentic connections`,
]

export const HIGHLIGHTS_2 = [
  // 'Gain access to a curated marketplace of over 300 unique gifts and experiences',
  // 'Make your digital marketing efforts more effective',
  // 'Use direct mail to build and accelerate your sales pipeline',
]

export const MAIN_HEADER_1 = (
  <>
    <Text
      fontSize={{ base: '1.2rem', sm: 'xl', xl: '2xl' }}
      color="white"
      lineHeight={1}
    >
      Curated events that will
    </Text>
    <Text
      fontSize={{ base: '1.2rem', sm: 'xl', xl: '2xl' }}
      fontWeight="bold"
      color="white"
      mb={6}
    >
      delight your customers
    </Text>
  </>
)

export const MAIN_HEADER_2 = (
  <Text
    mb={6}
    fontSize={{ base: '1.2rem', sm: '1.3rem', xl: '1.4rem' }}
    color="white"
    textAlign="center"
    lineHeight={1.2}
  >
    Delight your customers with unique, curated gifts and experiences that will drive ROI
  </Text>
)
