import { Box } from '@chakra-ui/react'
import type { Column } from '@postal-io/postal-ui'
import {
  humanize,
  internalProgressBarProps,
  internalTableProps,
  UiDataTable,
  UiDate,
  UiDateTime,
  UiStatusTag,
  UiTruncate,
  ZButton,
  ZCheckbox,
  ZLink,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import type { TransferIntent } from 'api'
import { TransferIntentStatus } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { getCurrencySymbol } from 'lib'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import type { BillingAccount } from '../../api/index'

const TRANSFER_INTENT_STATUS_MAP: any = {
  [TransferIntentStatus.Created]: { label: 'Created', colorScheme: 'primary.500' },
  [TransferIntentStatus.BalanceSuccess]: { label: 'Complete', colorScheme: 'tertiary.500' },
  [TransferIntentStatus.BalanceError]: { label: 'Balance Error', colorScheme: 'red.500' },
  [TransferIntentStatus.UploadPending]: { label: 'Complete', colorScheme: 'tertiary.500' },
  [TransferIntentStatus.UploadProcessing]: {
    label: 'Complete',
    colorScheme: 'tertiary.500',
  },
  [TransferIntentStatus.UploadSuccess]: { label: 'Complete', colorScheme: 'tertiary.500' },
  [TransferIntentStatus.UploadError]: { label: 'Upload Error', colorScheme: 'red.500' },
  [TransferIntentStatus.Complete]: { label: 'Complete', colorScheme: 'tertiary.500' },
  [TransferIntentStatus.Canceled]: { label: 'Cancelled', colorScheme: 'orange.500' },
}

interface TransferIntentColumn extends TransferIntent {
  fromBillingAccount: BillingAccount
  toBillingAccount: BillingAccount
}

const TransferIntentHistoryTable: React.FC<{ intent: TransferIntentColumn }> = ({ intent }) => (
  <Box>
    <UiStatusTag
      minW="120px"
      label={humanize(intent.status)}
      {...TRANSFER_INTENT_STATUS_MAP?.[intent.status]}
    />
  </Box>
)

interface DisplayFxRateProps {
  intent: TransferIntentColumn
}
export const DisplayFxRate: React.FC<DisplayFxRateProps> = ({ intent: { fxInfo, fromCurrency, toCurrency } }) =>
  fxInfo ? (
    // @ts-ignore
    <ZInfoTooltip
      hasArrow
      label={
        <Box color="white">
          <ZText color="inherit">Provider: {fxInfo.apiProvider}</ZText>
          <ZText color="inherit">
            At: <UiDateTime date={fxInfo.timestamp} />
          </ZText>
        </Box>
      }
    >
      <Box color="white">
        <ZText color="inherit">{fxInfo.rate}</ZText>
        <ZText
          color="atomicGray.10"
          fontSize="xs"
        >
          {getCurrencySymbol(fromCurrency)} → {getCurrencySymbol(toCurrency)}
        </ZText>
      </Box>
    </ZInfoTooltip>
  ) : (
    <ZText color="atomicGray.400">N/A</ZText>
  )

interface DisplayTransferAmountProps {
  intent: TransferIntentColumn
}
export const DisplayTransferAmount: React.FC<DisplayTransferAmountProps> = ({
  intent: { fromAmount, fromCurrency, toAmount, toCurrency },
}) => (
  <>
    <ZMoney
      cents={fromAmount}
      currency={fromCurrency}
    />
    {toCurrency !== fromCurrency && (
      <ZText
        color="atomicGray.500"
        fontSize="xs"
      >
        <ZMoney
          cents={toAmount}
          currency={toCurrency}
        />
      </ZText>
    )}
  </>
)

const TRANSFER_HISTORY_COLUMNS: Column[] = [
  {
    label: 'From Account',
    key: 'fromBillingAccount',
    render: ({ fromBillingAccount }: TransferIntentColumn) => (
      <ZLink
        as={Link}
        to={`/billing/accounts/${fromBillingAccount?.id}`}
        state={{ returnTo: 'Transfers' }}
      >
        <UiTruncate
          text={fromBillingAccount?.name}
          showTooltip
        />
      </ZLink>
    ),
  },
  {
    label: 'To Account',
    key: 'toBillingAccount',
    render: ({ toBillingAccount }: TransferIntentColumn) => (
      <ZLink
        as={Link}
        to={`/billing/accounts/${toBillingAccount?.id}`}
        state={{ returnTo: 'Transfers' }}
      >
        <UiTruncate
          text={toBillingAccount?.name}
          showTooltip
        />
      </ZLink>
    ),
  },
  {
    label: 'Status',
    key: 'status',
    render: (intent: TransferIntentColumn) => <TransferIntentHistoryTable intent={intent} />,
  },
  {
    label: 'Amount',
    key: 'fromAmount',
    render: (intent: TransferIntentColumn) => <DisplayTransferAmount intent={intent} />,
  },
  {
    label: 'FX Rate',
    key: 'fxInfo',
    render: (intent: TransferIntentColumn) => <DisplayFxRate intent={intent} />,
    rowProps: {
      minW: '120px',
    },
  },
  {
    label: 'Date',
    key: 'created',
    render: ({ created }: TransferIntentColumn) => (
      <UiDate
        date={created?.dateTime || ''}
        fallback="-"
      />
    ),
  },
  {
    label: 'Notes',
    key: 'comment',
  },
]

interface BalanceTransferHistoryTableProps {
  billingAccounts?: BillingAccount[]
  transferIntents?: TransferIntent[]
}

export const BalanceTransferHistoryTable: React.FC<BalanceTransferHistoryTableProps> = ({
  billingAccounts,
  transferIntents,
}) => {
  const billingAccountsMap = useMemo(() => {
    return billingAccounts?.reduce((obj, account) => ({ ...obj, [account.id]: account }), {} as any) ?? {}
  }, [billingAccounts])

  const rows = useMemo(() => {
    if (!transferIntents) return []
    return transferIntents.map((intent) => {
      return {
        ...intent,
        fromBillingAccount: billingAccountsMap?.[intent.fromBillingAccountId],
        toBillingAccount: billingAccountsMap?.[intent.toBillingAccountId],
      }
    })
  }, [billingAccountsMap, transferIntents])

  const transferHistoryColumns = useMemo(
    () =>
      transferIntents?.reduce((anyFxRates, intent) => anyFxRates || !!intent.fxInfo, false)
        ? TRANSFER_HISTORY_COLUMNS
        : TRANSFER_HISTORY_COLUMNS.filter((col) => col.label !== 'FX Rate'),
    [transferIntents]
  )

  return (
    <UiDataTable
      variant="list"
      rowKey="id"
      columns={transferHistoryColumns}
      rows={rows}
      pageSize={20}
      p={8}
      pt={0}
      tableProps={internalTableProps}
      progressBarProps={internalProgressBarProps}
      SelectCheckbox={ZCheckbox}
      HeaderButton={ZButton}
    />
  )
}
