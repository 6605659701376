import type { Module, Role } from 'api'
import type { Permission } from 'lib/permissions'
import camelCase from 'lodash/camelCase'
import { useCallback } from 'react'

/**
 * Launch Darkly helpers.
 *
 * The feature flags come over as camelCase from LD
 * and they are organized by a prefix of `module` or `feature` depending on
 * the type of flag.
 */
const ldFeature = (name: string) => camelCase(`feature-${name}`)

const isEmptyField = (v: any) => {
  return (
    (typeof v === 'string' && v.trim().length === 0) ||
    (Array.isArray(v) && v.length === 0) ||
    v === undefined ||
    v === null
  )
}

/**
 * Acl Filter
 *
 * Generic interface used to filter an object based on
 * module, feature, flag, or role properties
 */
export interface AclFilter extends Record<string, any> {
  module?: ModulePermission | ModulePermission[] | null
  feature?: string | string[] | null
  flag?: string | string[] | null
  role?: Role | Role[] | null
  enabled?: boolean
}

type ModuleName = string
type PermissionName = string
export type ModulePermission = `${ModuleName}.${PermissionName}`

/**
 * useAclCheck
 *
 * a Hook for checking the currently logged in user's permissions
 *
 * const {
 *   aclCheck,
 *   aclFilter,
 *   flags,
 *   getMeta,
 *   getModule,
 *   hasFeature,
 *   hasFlag,
 *   hasPermission,
 *   hasRole,
 *   modules,
 * } = useAclCheck({roles, flags, modules})
 *
 * const { hasPermission, aclCheck, getMeta } = useAcl()
 * const canRead = hasPermission('profile.read')
 * const canDoThing = aclCheck({ feature: 'thing', module: 'postals.send' })
 * const maxItems = getMeta('collections')?.maximumItems
 *
 */
interface UseAclCheckProps {
  roles: Role[]
  flags: Record<string, any>
  modules: Module[]
  subscriptions?: String[]
  permissions?: Permission[]
}

export const useAclCheck = ({ roles, flags, modules, permissions, subscriptions }: UseAclCheckProps) => {
  const hasRole = useCallback((role: Role) => !!roles.includes(role), [roles])
  const hasSubscription = useCallback((subscription: string) => subscriptions?.includes(subscription), [subscriptions])
  const hasFeature = useCallback(
    (name: string, defaultValue = true) => {
      const value = flags[ldFeature(name)]
      return value === true ? true : value === false ? false : defaultValue
    },
    [flags]
  )
  const hasFlag = useCallback(
    (name: string, defaultValue = true) => {
      const value = flags[name]
      return value === true ? true : value === false ? false : defaultValue
    },
    [flags]
  )
  const getModule = useCallback(
    (name: string) => {
      return modules.find((m: any) => m?.name === name)
    },
    [modules]
  )
  const hasPermission = useCallback(
    (name: ModulePermission | Permission) => {
      // V2 permissions
      if (!name.includes('.')) {
        return !!permissions?.includes(name as Permission)
      }

      // V1
      const [mod, perm] = name.split('.')
      return !!getModule(mod)?.permissions?.[perm]
    },
    [getModule, permissions]
  )

  const getMeta = useCallback((name: string) => (getModule(name)?.meta ?? {}) as Record<string, any>, [getModule])

  const aclCheck = useCallback(
    (permObj: AclFilter) => {
      return Object.keys(permObj).every((key) => {
        const value = permObj[key]
        if (isEmptyField(value)) return true
        switch (key) {
          case 'feature':
            return Array.isArray(value) ? value.every((v) => hasFeature(v, true)) : hasFeature(value)
          case 'flag':
            return Array.isArray(value) ? value.every((v) => hasFlag(v, true)) : hasFlag(value)
          case 'role':
            return Array.isArray(value) ? value.every(hasRole) : hasRole(value)
          case 'module':
            return Array.isArray(value) ? value.every(hasPermission) : hasPermission(value)
          case 'enabled':
            return Array.isArray(value) ? value.every((v) => !!v) : !!value
          default:
            return true
        }
      })
    },
    [hasFeature, hasFlag, hasRole, hasPermission]
  )

  const aclFilter = useCallback(<T extends AclFilter>(items: T[]) => items.filter(aclCheck), [aclCheck])

  return {
    aclCheck,
    aclFilter,
    flags,
    getMeta,
    getModule,
    hasFeature,
    hasFlag,
    hasPermission,
    hasRole,
    modules,
    hasSubscription,
  }
}
