import type { UiTagProps } from '@postal-io/postal-ui'
import { UiTag } from '@postal-io/postal-ui'
import React from 'react'
import type { Attribution } from '../../api'
import { ExternalSystem, getExternalProvider } from './data'

export interface AttributionTagProps extends UiTagProps {
  system: string
  name: string
}

export const AttributionTag: React.FC<AttributionTagProps> = ({ system, name, ...rest }) => {
  return (
    <UiTag
      size="lg"
      {...rest}
    >
      {system}: {name}
    </UiTag>
  )
}

export function getAttribution(attribution?: Attribution | null) {
  if (attribution?.sfdcCampaignId) {
    return {
      system: getExternalProvider(ExternalSystem.Salesforce)?.name || 'sfdc',
      name: attribution.sfdcCampaignName || attribution.sfdcCampaignId,
    }
  }

  if (attribution?.marketoCampaignId) {
    return {
      system: getExternalProvider(ExternalSystem.Marketo)?.name || 'marketo',
      name: attribution.marketoCampaignName || attribution.marketoCampaignId,
    }
  }

  if (attribution?.marketoProgramId) {
    return {
      system: getExternalProvider(ExternalSystem.Marketo)?.name || 'marketo',
      name: attribution.marketoProgramName || attribution.marketoProgramId,
    }
  }

  if (attribution?.eloquaCampaignId) {
    return {
      system: getExternalProvider(ExternalSystem.Eloqua)?.name || 'eloqua',
      name: attribution.eloquaCampaignName || attribution.eloquaCampaignId,
    }
  }

  return undefined
}
