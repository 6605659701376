import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiLoading } from '@postal-io/postal-ui'
import { GetContactByIntegrationDocument } from 'api'
import { useExtension } from 'hooks/useExtension'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ExtContactCreate } from './ExtContactCreate'
import { ExtContactView } from './ExtContactView'

export const ExtContactIntegration: React.FC = () => {
  const params = useParams() as any
  const { contactId, setContactId } = useExtension()

  const getContact = useGraphqlQuery(
    GetContactByIntegrationDocument,
    { id: params?.contactId, systemName: params?.systemName, contactType: params?.contactType },
    { useErrorBoundary: false, enabled: !!params?.contactId && !!params?.systemName && !!params?.contactType }
  )
  const contact = useMemo(() => {
    return getContact.data?.getContactByIntegration
  }, [getContact.data?.getContactByIntegration])

  useEffect(() => {
    if (contact?.id && contact.id !== contactId) setContactId(contact?.id)
  }, [contact?.id, contactId, setContactId])

  if (getContact.isLoading) return <UiLoading />

  if (!contact) return <ExtContactCreate />

  return <ExtContactView contact={contact} />
}
