import {
  CampaignStatus,
  FulfillmentStatus,
  LedgerRecordType,
  MagicEventStatus,
  MagicLinkStatus,
  SavedSendStatus,
  Status,
} from 'api'
import uniq from 'lodash/uniq'

export const STATUS_TYPE_MAP: {
  [key: string]: {
    type: string
    // legacy
    color: string
    backgroundColor: string
    // new
    colorScheme: string
  }
} = {
  SUCCESS: {
    type: 'SUCCESS',
    color: 'vendorGreen.900',
    backgroundColor: 'vendorGreen.10',
    colorScheme: 'vendorGreen',
  },
  INFO: {
    type: 'INFO',
    color: 'atomicBlue.700',
    backgroundColor: 'atomicBlue.10',
    colorScheme: 'atomicBlue',
  },
  ERROR: { type: 'ERROR', color: 'atomicRed.600', backgroundColor: 'atomicRed.50', colorScheme: 'atomicRed' },
  WARNING: {
    type: 'WARNING',
    color: 'atomicYellow.800',
    backgroundColor: 'atomicYellow.50',
    colorScheme: 'atomicYellow',
  },
  PENDING_USER_APPROVAL: {
    // type is used by chakra for stuff; WARNING is closest one that makes sense
    type: 'WARNING',
    color: 'atomicYellow.700',
    backgroundColor: 'atomicYellow.50',
    colorScheme: 'atomicYellow',
  },
  DISABLED: {
    type: 'INFO',
    color: 'atomicGray.500',
    backgroundColor: 'atomicGray.50',
    colorScheme: 'atomicGray',
  },
}

export interface StatusMapResult {
  text: string
  type: string
  backgroundColor?: string
  color?: string
  colorScheme: string
}
export interface StatusMapProps {
  [key: string]: StatusMapResult
}

const statusMap: StatusMapProps = {
  [FulfillmentStatus.None]: { text: 'None', ...STATUS_TYPE_MAP.INFO },
  [FulfillmentStatus.Pending]: { text: 'Processing', ...STATUS_TYPE_MAP.INFO },
  [FulfillmentStatus.PendingUserApproval]: { text: 'Pending Approval', ...STATUS_TYPE_MAP.INFO },
  [FulfillmentStatus.PostalAddressRefresh]: { text: 'Pending Address', ...STATUS_TYPE_MAP.INFO },
  [FulfillmentStatus.PostalDeliveryEmail]: { text: 'Postal Email', ...STATUS_TYPE_MAP.SUCCESS },
  [FulfillmentStatus.PostalDeliveryEmailError]: { text: 'Email Error', ...STATUS_TYPE_MAP.ERROR },
  [FulfillmentStatus.PostalDeliveryRejected]: { text: 'Gift Declined', ...STATUS_TYPE_MAP.ERROR },
  [FulfillmentStatus.PostalDeliveryDonated]: { text: 'Gift Donated', ...STATUS_TYPE_MAP.SUCCESS },
  [FulfillmentStatus.PostalDeliveryExpired]: { text: 'Gift Expired', ...STATUS_TYPE_MAP.ERROR },
  [FulfillmentStatus.Processing]: { text: 'Processing', ...STATUS_TYPE_MAP.INFO },
  [FulfillmentStatus.ProcessingInternal]: { text: 'Processing', ...STATUS_TYPE_MAP.INFO },
  [FulfillmentStatus.ProcessingError]: { text: 'Processing Error', ...STATUS_TYPE_MAP.ERROR },
  [FulfillmentStatus.Placed]: { text: 'Processing', ...STATUS_TYPE_MAP.INFO },
  [FulfillmentStatus.Confirmed]: { text: 'Processing', ...STATUS_TYPE_MAP.INFO },
  [FulfillmentStatus.ServerErrorBackoff]: { text: 'Processing', ...STATUS_TYPE_MAP.INFO },
  [FulfillmentStatus.Shipped]: { text: 'Shipped', ...STATUS_TYPE_MAP.SUCCESS },
  [FulfillmentStatus.PendingDelivery]: { text: 'In Transit', ...STATUS_TYPE_MAP.INFO },
  [FulfillmentStatus.OrderApproved]: { text: 'Order Approved', ...STATUS_TYPE_MAP.INFO },
  [FulfillmentStatus.OrderDenied]: { text: 'Order Denied', ...STATUS_TYPE_MAP.WARNING },
  [FulfillmentStatus.OutForDelivery]: { text: 'Out for Delivery', ...STATUS_TYPE_MAP.INFO },
  [FulfillmentStatus.Delivered]: { text: 'Delivered', ...STATUS_TYPE_MAP.SUCCESS },
  [FulfillmentStatus.DeliveredAssumed]: { text: 'Delivered', ...STATUS_TYPE_MAP.SUCCESS },
  [FulfillmentStatus.DeliveryError]: { text: 'Delivery Error', ...STATUS_TYPE_MAP.ERROR },
  [FulfillmentStatus.Cancelled]: { text: 'Cancelled', ...STATUS_TYPE_MAP.ERROR },
  [FulfillmentStatus.Waitlist]: { text: 'Waitlist', ...STATUS_TYPE_MAP.WARNING },
  [FulfillmentStatus.PendingUserApproval]: { text: 'Needs Approval', ...STATUS_TYPE_MAP.PENDING_USER_APPROVAL },
  [FulfillmentStatus.OrderApproved]: { text: 'Order Approved', ...STATUS_TYPE_MAP.SUCCESS },
  [FulfillmentStatus.OrderDenied]: { text: 'Order Denied', ...STATUS_TYPE_MAP.ERROR },
  [SavedSendStatus.Deleted]: { text: 'Deleted', ...STATUS_TYPE_MAP.ERROR },
  [SavedSendStatus.Draft]: { text: 'Draft', ...STATUS_TYPE_MAP.SUCCESS },
  [SavedSendStatus.SentDraft]: { text: 'Sent Draft', ...STATUS_TYPE_MAP.WARNING },
  [SavedSendStatus.Template]: { text: 'Template', ...STATUS_TYPE_MAP.INFO },
  // Campaign Statuses
  [CampaignStatus.Cancelled]: { text: 'Cancelled', ...STATUS_TYPE_MAP.ERROR },
  [CampaignStatus.Complete]: { text: 'Complete', ...STATUS_TYPE_MAP.INFO },
  [CampaignStatus.Error]: { text: 'Error', ...STATUS_TYPE_MAP.ERROR },
  [CampaignStatus.Pending]: { text: 'Pending', ...STATUS_TYPE_MAP.INFO },
  [CampaignStatus.Processing]: { text: 'Processing', ...STATUS_TYPE_MAP.INFO },
  [CampaignStatus.Scheduled]: { text: 'Scheduled', ...STATUS_TYPE_MAP.INFO },
  // ABM-only Campaign Statuses
  [CampaignStatus.PausedBySystem]: { text: 'Paused by System', ...STATUS_TYPE_MAP.INFO },
  [CampaignStatus.PausedByUser]: { text: 'Paused by System', ...STATUS_TYPE_MAP.INFO },
  [CampaignStatus.Active]: { text: 'Active', ...STATUS_TYPE_MAP.SUCCESS },
  // MagicLink Statuses
  [MagicLinkStatus.Active]: { text: 'Active', ...STATUS_TYPE_MAP.INFO },
  [MagicLinkStatus.Disabled]: { text: 'Disabled', ...STATUS_TYPE_MAP.WARNING },
  // Events
  [MagicEventStatus.AcceptingInvites]: { text: 'Accepting Invites', ...STATUS_TYPE_MAP.SUCCESS },
  [MagicEventStatus.CancelRequested]: { text: 'Cancel Requested', ...STATUS_TYPE_MAP.WARNING },
  [MagicEventStatus.Cancelled]: { text: 'Cancelled', ...STATUS_TYPE_MAP.ERROR },
  [MagicEventStatus.Complete]: { text: 'Complete', ...STATUS_TYPE_MAP.SUCCESS },
  [MagicEventStatus.ConfirmedBillingIssue]: { text: 'Billing Issue', ...STATUS_TYPE_MAP.WARNING },
  [MagicEventStatus.ConfirmedNeedsData]: { text: 'Needs Data', ...STATUS_TYPE_MAP.INFO },
  [MagicEventStatus.PendingConfirmation]: { text: 'Pending Confirmation', ...STATUS_TYPE_MAP.INFO },
  [MagicEventStatus.RegistrationClosed]: { text: 'Registration Closed', ...STATUS_TYPE_MAP.INFO },
  // Approved Postal
  [Status.Active]: { text: 'Enabled', ...STATUS_TYPE_MAP.INFO },
  [Status.Delete]: { text: 'Deleted', ...STATUS_TYPE_MAP.ERROR },
  [Status.Disabled]: { text: 'Draft', ...STATUS_TYPE_MAP.DISABLED },
  [Status.OutOfStock]: { text: 'Out of Stock', ...STATUS_TYPE_MAP.WARNING },
  [Status.VendorDisabled]: { text: 'Vendor Disabled', ...STATUS_TYPE_MAP.WARNING },
  // Ledger Record Type
  // Campaign Statuses
  [LedgerRecordType.CustomerDeposit]: { text: 'Deposit', ...STATUS_TYPE_MAP.SUCCESS },
  [LedgerRecordType.CustomerTransfer]: { text: 'Transfer', ...STATUS_TYPE_MAP.INFO },
  [LedgerRecordType.ExternalWithdrawal]: { text: 'Withdrawal', ...STATUS_TYPE_MAP.INFO },
  [LedgerRecordType.ManualAdjustment]: { text: 'Adjustment', ...STATUS_TYPE_MAP.INFO },
  [LedgerRecordType.PostalDeposit]: { text: 'Deposit', ...STATUS_TYPE_MAP.INFO },
  [LedgerRecordType.RefundDeposit]: { text: 'Deposit', ...STATUS_TYPE_MAP.INFO },
  [LedgerRecordType.SalesWithdrawal]: { text: 'Withdrawal', ...STATUS_TYPE_MAP.INFO },
}

const getStatusType = (type: string) =>
  Object.entries(statusMap)
    .filter(([_, v]) => v.type === type)
    .map(([k, _]) => k)

export const FULFILLMENT_SUCCESS = getStatusType('SUCCESS')
export const FULFILLMENT_INFO = getStatusType('INFO')
export const FULFILLMENT_WARNING = getStatusType('WARNING')
export const FULFILLMENT_ERROR = getStatusType('ERROR')

export const statusMapper = (
  status:
    | undefined
    | null
    | FulfillmentStatus
    | SavedSendStatus
    | CampaignStatus
    | MagicLinkStatus
    | MagicEventStatus
    | Status
    | LedgerRecordType
): StatusMapResult => {
  const DEFAULT = { text: 'Status Unknown', ...STATUS_TYPE_MAP.INFO }
  return status ? statusMap[status] || DEFAULT : DEFAULT
}

export const uniqueTextStatuses = uniq(Object.values(statusMap).map((status) => status.text)).sort((a, b) =>
  a < b ? -1 : 0
)

// create map of status text => array of related Fulfillment Statuses for use in query
export const reverseStatusTextMap = Object.keys(statusMap).reduce((newMap, status) => {
  const { text } = statusMap[status]
  if (!newMap[text]) newMap[text] = []
  newMap[text].push(status)
  return newMap
}, {} as any)
