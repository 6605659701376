import type { PopoverProps } from '@chakra-ui/react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Slide,
  Stack,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import type { UiDialogProps } from '@postal-io/postal-ui'
import {
  FontWeight,
  joinStrings,
  UiButton,
  UiTag,
  UiTagLabel,
  ZButton,
  ZCard,
  ZCardBody,
  ZText,
} from '@postal-io/postal-ui'
import type { SearchableContact, SearchableContactFilterInput } from 'api'
import { FulfillmentStatus } from 'api'
import { ZDialog, ZLink, zPopoverContentStyles } from 'components/Common/ZComponents'
import { AddressVerifiedBadgeV2 } from 'components/Contact/AddressVerifiedBadgeV2'
import { ContactCampaigns } from 'components/Contact/ContactCampaigns'
import { ContactInfo } from 'components/Contact/ContactInfo'
import { ContactPlaybookInstances } from 'components/Contact/ContactPlaybookInstances'
import type { MultiSelectContactsState } from 'components/MultiSelectContacts'
import type { MultiSelectFilter } from 'components/MultiSelectContacts/useMultiSelect'
import { PostalFulfillmentsTable } from 'components/PostalFulfillments'
import { PostalFulfillmentsList } from 'components/PostalFulfillments/PostalFulfillmentList'
import { PostalSendMethod, SEND_METHOD_COLORS } from 'components/PostalSend/postalSendHelpers'
import { useAcl } from 'hooks'
import { camelCase, startCase } from 'lodash'
import type { PropsWithChildren } from 'react'
import React, { useMemo, useRef, useState } from 'react'
import { useExtension } from '../../hooks/useExtension'

interface MultiSelectContactsDisplayProps {
  multiSelectState?: MultiSelectContactsState
  totalCount?: number
  filters?: MultiSelectFilter<SearchableContactFilterInput>[]
  contacts?: SearchableContact[]
  hasContacts?: boolean
  onViewAll?: () => void
  removeContact?: (contact: SearchableContact) => void
  removeFilter?: (filter: SearchableContactFilterInput) => void
  viewContact: (contact: SearchableContact) => void
  onClearAll?: () => void
  canRemoveAllContacts?: boolean
}
export const MultiSelectSelectedTags: React.FC<MultiSelectContactsDisplayProps> = ({
  multiSelectState,
  totalCount,
  filters,
  contacts,
  hasContacts,
  onViewAll,
  removeContact,
  removeFilter,
  viewContact,
  onClearAll,
  canRemoveAllContacts,
  ...rest
}) => {
  const { isExtension } = useExtension()
  const clearAllDisclosure = useDisclosure()
  const contactViewDisclosure = useDisclosure()
  const cancelRef = useRef(null)

  const isLastItem = useMemo(
    () => (filters?.length ?? 0) + (contacts?.length ?? 0) === 1,
    [filters?.length, contacts?.length]
  )
  const [selectedContact, setSelectedContact] = useState<SearchableContact | undefined>()

  return (
    <>
      <Flex
        mt={4}
        justifyContent="space-between"
      >
        <ZText
          fontSize="md"
          color="atomicGray.600"
          fontWeight={FontWeight.Bold}
        >
          {new Intl.NumberFormat('en-US').format(totalCount ?? 0)} Recipients
        </ZText>
        {(totalCount ?? 0) > 1 && (
          <HStack
            divider={
              <Divider
                orientation="vertical"
                borderColor="atomicGray.200"
              />
            }
          >
            {canRemoveAllContacts && (
              <ZLink
                fontSize="xs"
                color="atomicGray.500"
                fontWeight={FontWeight.Bold}
                onClick={clearAllDisclosure.onOpen}
              >
                Clear
              </ZLink>
            )}
            <ZLink
              fontSize="xs"
              color="atomicGray.500"
              fontWeight={FontWeight.Bold}
              onClick={onViewAll}
            >
              See All
            </ZLink>
          </HStack>
        )}
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        bg="white"
        borderRadius="md"
        borderColor="gray.200"
        py={4}
        maxH="9.2rem"
        overflow="scroll"
        {...rest}
      >
        <Wrap spacing={4}>
          {filters?.map((filter) => (
            <MultiSelectDisplayTag
              key={JSON.stringify(filter.filter)}
              tooltipContents={
                <FilterTooltipContents
                  remove={() => removeFilter?.(filter.filter)}
                  disableRemove={!canRemoveAllContacts && isLastItem}
                  filter={filter}
                />
              }
            >
              Search Results ({filter.totalRecords})
            </MultiSelectDisplayTag>
          ))}
          {contacts?.map((contact) => (
            <MultiSelectDisplayTag
              isDisabled={isExtension}
              key={contact.id}
              tooltipContents={
                <ContactTooltipContents
                  remove={() => removeContact?.(contact)}
                  disableRemove={!canRemoveAllContacts && isLastItem}
                  contact={contact}
                  onContactDetailsView={() => {
                    setSelectedContact(contact)
                    contactViewDisclosure.onOpen()
                  }}
                />
              }
            >
              {contact.firstName} {contact.lastName?.slice(0, 1)}
            </MultiSelectDisplayTag>
          ))}
          {hasContacts && !contacts?.length && !filters?.length && (
            <ZText
              color="gray.400"
              fontSize="sm"
              py={0.5}
            >
              No Contacts Added
            </ZText>
          )}
        </Wrap>
      </Flex>
      {clearAllDisclosure.isOpen && onClearAll && (
        <AlertDialog
          size="md"
          closeOnEsc
          closeOnOverlayClick
          isCentered
          leastDestructiveRef={cancelRef}
          {...clearAllDisclosure}
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogCloseButton />
            <AlertDialogHeader>Remove all Contacts</AlertDialogHeader>
            <AlertDialogBody>Sure you want to clear all selected contacts?</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                onClick={() => {
                  onClearAll()
                  clearAllDisclosure.onClose()
                }}
                minW={32}
              >
                Remove All
              </Button>
              <Button
                ref={cancelRef}
                variant="ghost"
                colorScheme="atomicGray"
                onClick={clearAllDisclosure.onClose}
                minW={32}
              >
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
      {contactViewDisclosure.isOpen && (
        <ContactDetailsModal
          {...contactViewDisclosure}
          contactId={selectedContact?.id as string}
        />
      )}
    </>
  )
}

interface MultiSelectDisplayTagProps extends PopoverProps {
  tooltipContents?: any
  isDisabled?: boolean
}
const MultiSelectDisplayTag: React.FC<PropsWithChildren<MultiSelectDisplayTagProps>> = ({
  children,
  tooltipContents,
  isDisabled,
  ...rest
}) => {
  const popoverDisclosure = useDisclosure({ isOpen: isDisabled ? false : undefined })
  // const [timer, setTimer] = useState<NodeJS.Timeout>()

  // const onMouseLeave = () => {
  //   // give the user 300ms to mouse back over before closing
  //   setTimer(setTimeout(popoverDisclosure.onClose, 500))
  // }

  // const onMouseEnter = () => {
  //   timer && clearTimeout(timer)
  // }

  return (
    <>
      <Popover
        {...popoverDisclosure}
        isLazy
        {...rest}
      >
        <PopoverTrigger>
          <WrapItem
          // onMouseLeave={onMouseLeave}
          // onMouseEnter={onMouseEnter}
          >
            <UiTag
              color="atomicGray.600"
              bg="atomicGray.10"
              _hover={isDisabled ? undefined : { bg: 'atomicGray.50' }}
              cursor={isDisabled ? 'revert' : 'pointer'}
            >
              <UiTagLabel
                userSelect="none"
                display="flex"
                justifyContent="center"
                position="relative"
                minW="60px"
              >
                <ZText
                  data-private
                  fontSize="xs"
                  px={2}
                  py={1}
                >
                  {children}
                </ZText>
                <ZText
                  position="absolute"
                  top={0}
                  bottom={0}
                  left={0}
                  right={0}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="xs"
                  bg="atomicGray.50"
                  opacity={0}
                  _hover={isDisabled ? undefined : { opacity: 1, transition: '0.3s 0.3s opacity' }}
                >
                  More Info
                </ZText>
              </UiTagLabel>
            </UiTag>
          </WrapItem>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            sx={zPopoverContentStyles}
            // onMouseLeave={onMouseLeave}
            // onMouseEnter={onMouseEnter}
          >
            <PopoverArrow />
            <PopoverCloseButton color="atomicGray.500" />
            {tooltipContents}
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  )
}

interface ContactTooltipContentsProps {
  contact: SearchableContact
  remove?: () => void
  disableRemove?: boolean
  onContactDetailsView: () => void
}
const ContactTooltipContents: React.FC<ContactTooltipContentsProps> = ({
  contact,
  remove,
  disableRemove,
  onContactDetailsView,
}) => (
  <Stack spacing={1}>
    <ZText
      data-private
      fontWeight={FontWeight.Bold}
    >
      {joinStrings([contact.firstName, contact.lastName])}
    </ZText>
    {contact.title && (
      <ZText
        fontWeight={FontWeight.Regular}
        data-private
      >
        {contact.title}
      </ZText>
    )}
    {contact.companyName && (
      <ZText
        fontWeight={FontWeight.Regular}
        data-private
      >
        {contact.companyName}
      </ZText>
    )}
    {contact.emailAddress && (
      <ZText
        data-private
        color="atomicBlue.600"
      >
        {contact.emailAddress}
      </ZText>
    )}
    {contact.tags?.length && (
      <Box>
        {contact.tags.slice(0, 6).map((tag) => (
          <WrapItem
            key={tag}
            mt={1}
            mr={2}
            display="inline-block"
          >
            <UiTag
              color="atomicGray.600"
              bg="atomicGray.10"
              // _hover={{ bg: 'atomicGray.50' }}
              // cursor="pointer"
            >
              <UiTagLabel
                userSelect="none"
                px={2}
                py={1}
                position="relative"
              >
                <ZText
                  data-private
                  fontSize="xs"
                >
                  {tag}
                </ZText>
              </UiTagLabel>
            </UiTag>
          </WrapItem>
        ))}
      </Box>
    )}
    <Flex
      flexDir="row"
      alignItems="center"
      py={4}
    >
      <AddressVerifiedBadgeV2
        mr={2}
        display="inline-block"
        isVerified={!!contact.verifiedAddress}
      />{' '}
      <ZText
        fontWeight={350}
        fontSize="sm"
        color="atomicGray.800"
      >
        {contact.verifiedAddress ? 'Verified Address' : 'Address Not Verified'}
      </ZText>
    </Flex>
    <PostalFulfillmentsList
      contactId={contact.id}
      statuses={[FulfillmentStatus.DeliveredAssumed, FulfillmentStatus.Delivered]}
    />
    <ZButton
      fontSize="sm"
      mt={1}
      variant="solid"
      color="atomicYellow.800"
      bg="atomicYellow.50"
      _hover={{ bg: 'atomicYellow.100' }}
      justifyContent="center"
      disabled={disableRemove}
      onClick={remove}
    >
      {disableRemove ? 'Cannot Remove All Contacts' : 'Remove'}
    </ZButton>
    <ZButton
      fontSize="sm"
      mt={1}
      variant="naked"
      color="atomicBlue.600"
      _hover={{ color: 'atomicBlue.800' }}
      justifyContent="center"
      onClick={onContactDetailsView}
    >
      View Contact
    </ZButton>
  </Stack>
)

enum direction {
  'left' = 'left',
  'right' = 'right',
}
const getOtherDirection = (dir: direction) => (dir === direction.left ? direction.right : direction.left)

enum Tab {
  'Orders' = 'Orders',
  'GroupOrders' = 'Group Orders',
  'Subscriptions' = 'Subscriptions',
}

const SMALL_PAGE_SIZE = 5

interface ContactDetailsModalProps extends Omit<UiDialogProps, 'title' | 'children'> {
  contactId: string
}
export const ContactDetailsModal: React.FC<ContactDetailsModalProps> = ({ contactId, ...rest }) => {
  const { hasPermission } = useAcl()
  const canReadPlaybooks = hasPermission('playbooks.read')

  const tabs = useMemo(() => {
    const tabList = []
    tabList.push(Tab.Orders)
    tabList.push(Tab.GroupOrders)
    if (canReadPlaybooks) tabList.push(Tab.Subscriptions)
    return tabList
  }, [canReadPlaybooks])

  const [selectedTab, setSelectedTab] = useState<Tab>(tabs[0])

  const [slideDirection, setSlideDirection] = useState<direction>(direction.right)

  const handleTabChange = (newTab: Tab) => {
    setSlideDirection(tabs.indexOf(selectedTab) > tabs.indexOf(newTab) ? direction.left : direction.right)
    setSelectedTab(newTab)
  }

  const getSlideDirection = (cond: boolean) => (cond ? slideDirection : getOtherDirection(slideDirection))

  return (
    <ZDialog
      {...rest}
      title="Contact Details"
      size="6xl"
      scrollBehavior="outside"
    >
      <Stack
        spacing={5}
        overflow="hidden"
      >
        <ContactInfo contactId={contactId} />
        <ZCard variant="form">
          <ZCardBody pt={{ base: 5, md: 5 }}>
            <Box
              borderBottom="2px solid #E0E6ED"
              mb={3}
              mt={-4}
            >
              {tabs.map((tab) => (
                <ZTabButton
                  isActive={selectedTab === tab}
                  onClick={() => handleTabChange(tab)}
                >
                  {tab}
                </ZTabButton>
              ))}
            </Box>
            <Slide
              direction={getSlideDirection(selectedTab === Tab.Orders)}
              in={selectedTab === Tab.Orders}
              style={{ position: 'relative' }}
              unmountOnExit
            >
              <PostalFulfillmentsTable
                contactId={contactId}
                useContactSearch={true}
                pageSize={SMALL_PAGE_SIZE}
              />
            </Slide>
            <Slide
              direction={getSlideDirection(selectedTab === Tab.GroupOrders)}
              in={selectedTab === Tab.GroupOrders}
              style={{ position: 'relative' }}
              unmountOnExit
            >
              <ContactCampaigns
                contactId={contactId}
                pageSize={SMALL_PAGE_SIZE}
              />
            </Slide>
            <Slide
              direction={getSlideDirection(selectedTab === Tab.Subscriptions)}
              in={selectedTab === Tab.Subscriptions && canReadPlaybooks}
              style={{ position: 'relative' }}
              unmountOnExit
            >
              <ContactPlaybookInstances
                contactId={contactId}
                pageSize={SMALL_PAGE_SIZE}
              />
            </Slide>
          </ZCardBody>
        </ZCard>
      </Stack>
    </ZDialog>
  )
}

interface FilterTooltipContentsProps {
  filter: MultiSelectFilter<SearchableContactFilterInput>
  remove?: () => void
  disableRemove?: boolean
}
const FilterTooltipContents: React.FC<FilterTooltipContentsProps> = ({ filter, remove, disableRemove }) => (
  <Stack
    spacing={1}
    mb={2}
  >
    <ZText
      data-private
      fontWeight={FontWeight.Bold}
    >
      Search Results ({filter.totalRecords} contacts)
    </ZText>
    {Object.keys(filter.filter)
      .filter((key) => !['id'].includes(key))
      .map((key) => (
        <ZText
          fontWeight="normal"
          key={key}
        >
          <>
            - {key === 'q' ? 'General Search' : startCase(camelCase(key as string))}: "
            {filter.filter[key as keyof SearchableContactFilterInput]}"
          </>
        </ZText>
      ))}
    {!Object.keys(filter.filter).length && <ZText fontWeight="normal">(Includes All Contacts)</ZText>}
    <ZButton
      fontSize="sm"
      mt={1}
      variant="solid"
      color="atomicYellow.800"
      bg="atomicYellow.50"
      _hover={{ bg: 'atomicYellow.100' }}
      justifyContent="center"
      disabled={disableRemove}
      onClick={remove}
    >
      {disableRemove ? 'Cannot Remove All Contacts' : 'Remove'}
    </ZButton>
  </Stack>
)

interface ZTabButtonProps {
  isActive: boolean
  onClick: () => void
  flow?: PostalSendMethod
  children: any
}
export const ZTabButton: React.FC<ZTabButtonProps> = ({
  isActive,
  onClick,
  flow = PostalSendMethod.Email,
  children,
}) => (
  <UiButton
    variant="naked"
    fontSize="md"
    px={1}
    mr={{ base: '25px', xl: '50px' }}
    height="55px"
    fontWeight={600}
    color={isActive ? SEND_METHOD_COLORS[flow] : 'atomicGray.800'}
    _hover={{ color: SEND_METHOD_COLORS[flow] }}
    onClick={onClick}
    borderRadius={0}
    transition="0.2s border-width, 0.2s color"
    borderBottomWidth={isActive ? '3px' : '0px'}
    borderColor={SEND_METHOD_COLORS[flow]}
  >
    <ZText
      as="span"
      fontSize="md"
      fontWeight="bold"
      color="inherit"
    >
      {children}
    </ZText>
  </UiButton>
)
