import {
  AspectRatio,
  Box,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import type { UiMenuItemProps } from '@postal-io/postal-ui'
import { FontWeight, UiMenuItem, UiTruncate, useColor, ZHeading, ZMoney, ZText } from '@postal-io/postal-ui'
import type { ImageReference } from 'api'
import { ZAlert } from 'components/Common/ZComponents'
import { PostalSendMethod, SEND_METHOD_COLORS } from 'components/PostalSend/usePostalSend'
import { useAssets } from 'hooks'
import React, { useMemo } from 'react'
import { MdCheckCircle } from 'react-icons/md'

interface PostalVariantOptionProps extends UiMenuItemProps {
  description?: string | null
  imageUrl?: ImageReference
  inventory?: number | null
  isLoading?: boolean
  isSelected?: boolean
  name: string
  onClick?: () => void
  displayPrice?: number
  flatFees?: number
  currency?: string
  shippingEstimate?: number | null
  hideShipping?: boolean
  brandingColor?: string
  nameCharacters?: number
  // multi select styling is slightly different with unselected items having a grayed out checkmark
  isMulti?: boolean
  // larger checkbox for certain views
  largeCheckbox?: boolean
  isDisabled?: boolean
  disabledMessage?: string
}

// const DEFAULT_LINE_CLAMP = 4
// const MIN_WIDTH_TO_SHOW_IMG_PREVIEW_TEXT = 310

export const PostalVariantOption: React.FC<PostalVariantOptionProps> = ({
  description,
  imageUrl,
  inventory,
  isLoading,
  isSelected,
  name,
  onClick,
  displayPrice,
  flatFees,
  currency,
  shippingEstimate,
  hideShipping,
  brandingColor = SEND_METHOD_COLORS[PostalSendMethod.Email],
  nameCharacters = 40,
  isMulti,
  largeCheckbox,
  isDisabled,
  disabledMessage,
  ...rest
}) => {
  const { Color } = useColor()
  const { assetUrlSrc } = useAssets()
  const { src, fallbackSrc } = assetUrlSrc(imageUrl?.url)

  const fontWeight = useMemo(() => (isSelected && !isMulti ? 'bold' : 'normal'), [isMulti, isSelected])

  return (
    <UiMenuItem
      p={0}
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'default'}
      _hover={{ bg: 'atomicBlue.5' }}
      _focusVisible={{ boxShadow: 'none !important' }}
      isDisabled={isDisabled}
      {...rest}
    >
      <Popover
        trigger="hover"
        placement="left"
        openDelay={onClick ? 1000 : 300}
      >
        <PopoverTrigger>
          <Box
            data-testid={`PostalVariantOption_card_${isSelected ? 'selected' : 'unselected'}`}
            mb={0}
            w="100%"
            h="50px"
            py={2}
            px={3}
            borderRadius={3}
          >
            <Flex
              flexDir="row"
              width="100%"
              height={8}
              alignItems="center"
            >
              {!isMulti && imageUrl && (
                <AspectRatio
                  boxSize="full"
                  flexShrink={0}
                  ratio={1}
                  width={10}
                  height={10}
                  mr={2}
                  border="1px solid"
                  borderColor="atomicGray.200"
                >
                  <Image
                    boxSize="full"
                    alt={imageUrl.description || ''}
                    src={src}
                    fallbackSrc={fallbackSrc}
                  />
                </AspectRatio>
              )}
              {isMulti && (
                <MdCheckCircle
                  size={largeCheckbox ? '24px' : '20px'}
                  style={{
                    display: 'inline-block',
                    margin: '0px 12px 0 0px',
                    transition: '0.2s color',
                    flexShrink: '0',
                  }}
                  color={isSelected ? brandingColor : Color('atomicGray.200')}
                />
              )}
              <Flex
                flexDir="column"
                justifyContent="center"
                overflow="hidden"
                flexGrow={1}
                flexShrink={1}
                mr={5}
              >
                <ZText
                  fontSize="sm"
                  fontWeight={fontWeight}
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                >
                  <UiTruncate
                    text={name}
                    length={nameCharacters}
                  />
                  {isSelected && !isMulti && (
                    <MdCheckCircle
                      size="16px"
                      style={{ display: 'inline-block', margin: '0px 0 -3px 5px', transition: '0.2s color' }}
                      color={brandingColor}
                    />
                  )}
                </ZText>
                {typeof inventory === 'number' && (
                  <ZText
                    color="atomicGray.500"
                    fontSize="xs"
                    fontWeight={350}
                  >
                    Quantity: {inventory}
                  </ZText>
                )}
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                textAlign="right"
              >
                <ZMoney
                  fontWeight={fontWeight}
                  fontSize="xs"
                  cents={displayPrice}
                  currency={currency}
                />
                {!hideShipping && (
                  <ZText
                    color="atomicGray.500"
                    fontSize="xs"
                    fontWeight={350}
                  >
                    {!shippingEstimate ? (
                      'FREE'
                    ) : (
                      <>
                        ~
                        <ZMoney
                          fontSize="xs"
                          color="atomicGray.500"
                          cents={shippingEstimate}
                          currency={currency}
                          placeholder="$0"
                          round
                        />
                      </>
                    )}{' '}
                    Shipping
                  </ZText>
                )}
              </Flex>
            </Flex>
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            borderRadius={10}
            p={5}
            width="unset"
          >
            <PopoverArrow />
            <PopoverBody>
              <Flex direction="row">
                {imageUrl && (
                  <Image
                    width="150px"
                    height="full"
                    mr={5}
                    borderRadius={5}
                    border="1px solid"
                    borderColor="atomicGray.200"
                    flexShrink={0}
                    overflow="hidden"
                    alt={imageUrl.description || ''}
                    src={src}
                    fallbackSrc={fallbackSrc}
                  />
                )}
                <Box
                  maxW="300px"
                  display="flex"
                  flexDir="column"
                  justifyContent="center"
                >
                  <ZHeading
                    size="h6"
                    fontWeight={FontWeight.Bold}
                    fontSize="md"
                    mt={-1}
                  >
                    {name}
                  </ZHeading>
                  {description && (
                    <ZText
                      as="p"
                      fontSize="xs"
                      fontWeight={350}
                      color="atomicGray.600"
                      mt={2}
                    >
                      {isDisabled && (
                        <ZAlert
                          status={'info'}
                          hideClose
                        >
                          {disabledMessage}
                        </ZAlert>
                      )}
                      {!isDisabled && description}
                    </ZText>
                  )}
                </Box>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </UiMenuItem>
  )
}
