import type { ModalProps } from '@chakra-ui/react'
import { Box, chakra, Divider, Flex, HStack, useDisclosure, VStack } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiFormControl,
  UiToggle,
  useAlertError,
  useAlerts,
  useColor,
  ZCard,
  ZCardHeader,
  ZFormLabel,
  ZHeading,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import type { Connection } from 'api'
import { GetConnectionsDocument, GetRecipesDocument, StartRecipeDocument, StopRecipeDocument } from 'api'
import { ZAlert } from 'components/Common/ZComponents'
import type { ExternalProvider } from 'components/Integrations'
import { WorkatoWidget } from 'components/Integrations'
import { dequal } from 'dequal'
import { useAcl } from 'hooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { Navigate, useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { IntegrationLayout } from '../Profile'
import { isAuthorizedConnection } from './helpers'
import { IntegrationMessaging } from './IntegrationSettings'

type RecipeType = 'user' | 'account' | 'contact'

interface FormState {
  user: boolean
  account: boolean
  contact: boolean
}

interface IntegrationWorkatoProps {
  provider: ExternalProvider
}
export const IntegrationWorkato: React.FC<IntegrationWorkatoProps> = ({ provider }) => {
  const navigate = useNavigate()
  const { colorCode } = useColor()
  const { hasPermission } = useAcl()
  const removeDisclosure = useDisclosure()
  const Alert = useAlerts()

  const getRecipes = useGraphqlQuery(GetRecipesDocument, undefined, { staleTime: 0 })
  const getConnections = useGraphqlQuery(GetConnectionsDocument)

  const connection = useMemo(() => {
    return getConnections.data?.getConnections?.find((p) => {
      if (!provider.workato?.connectionPrefix) return false
      return p.name.startsWith(provider.workato.connectionPrefix)
    })
  }, [getConnections.data?.getConnections, provider.workato?.connectionPrefix])

  useAlertError(getRecipes.error)

  const recipes = useMemo(() => {
    return (getRecipes.data?.getRecipes || []).filter((recipe) => {
      if (!provider.workato) return false
      return recipe.name.startsWith(provider.workato.recipePrefix)
    })
  }, [getRecipes.data?.getRecipes, provider.workato])

  const userRecipe = useMemo(() => {
    return recipes.find((recipe) => recipe.name.startsWith(provider.workato?.recipePrefix + 'user_'))
  }, [provider.workato, recipes])

  const contactRecipes = useMemo(() => {
    return recipes.filter((recipe) => recipe.name.startsWith(provider.workato?.recipePrefix + 'contact_'))
  }, [provider.workato, recipes])

  const accountRecipes = useMemo(() => {
    return recipes.filter((recipe) => recipe.name.startsWith(provider.workato?.recipePrefix + 'account_'))
  }, [provider.workato, recipes])

  const original = useMemo(() => {
    return {
      user: !!userRecipe?.running,
      account: accountRecipes.every((r) => r.running),
      contact: contactRecipes.every((r) => r.running),
    }
  }, [accountRecipes, contactRecipes, userRecipe])

  const [form, setForm] = useImmer<FormState>(original)

  const isDirty = useMemo(() => {
    return !dequal(original, form)
  }, [form, original])

  const startRecipe = useGraphqlMutation(StartRecipeDocument)
  const stopRecipe = useGraphqlMutation(StopRecipeDocument)
  const [isUpdating, setIsUpdating] = useState(false)

  const canDelete = hasPermission('integrations.delete')

  const handleBack = () => navigate('/integrations')

  const handleReset = useCallback(() => {
    setForm(() => original)
  }, [original, setForm])

  // update data when inbound data changes
  useEffect(() => {
    handleReset()
  }, [handleReset])

  const handleToggle = useCallback(
    (type: RecipeType) => {
      setForm((draft) => {
        const newValue = !draft[type]
        draft[type] = newValue
        if (type === 'user') {
          draft['account'] = newValue
          draft['contact'] = newValue
        } else if (newValue === true) {
          draft['user'] = true
        }
      })
    },
    [setForm]
  )

  const handleSubmit = useCallback(async () => {
    const toStart: string[] = []
    const toStop: string[] = []

    if (userRecipe) {
      if (!original.user && form.user) {
        toStart.push(userRecipe.id)
      } else if (original.user && !form.user) {
        toStop.push(userRecipe.id)
      }
    }

    if (!original.account && form.account) {
      accountRecipes.forEach((r) => toStart.push(r.id))
    } else if (original.account && !form.account) {
      accountRecipes.forEach((r) => toStop.push(r.id))
    }

    if (!original.contact && form.contact) {
      contactRecipes.forEach((r) => toStart.push(r.id))
    } else if (original.contact && !form.contact) {
      contactRecipes.forEach((r) => toStop.push(r.id))
    }

    setIsUpdating(true)
    try {
      const starts = toStart.map((id) => startRecipe.mutateAsync({ id }))
      const stops = toStop.map((id) => stopRecipe.mutateAsync({ id }))
      await Promise.all([...starts, ...stops])
      Alert.success('Changes Saved!')
    } catch (err) {
      setForm(() => original)
      Alert.error(err)
    } finally {
      setIsUpdating(false)
    }
  }, [
    Alert,
    accountRecipes,
    contactRecipes,
    form.account,
    form.contact,
    form.user,
    original,
    setForm,
    startRecipe,
    stopRecipe,
    userRecipe,
  ])

  const handleDisconnect = useCallback(() => {
    Promise.all(recipes.map((r) => stopRecipe.mutateAsync({ id: r.id })))
      .then(() => {
        Alert.success(`${provider.name} Disconnected`)
        navigate('/integrations')
        removeDisclosure.onClose()
      })
      .catch((err) => console.error(err))
  }, [Alert, navigate, provider.name, recipes, removeDisclosure, stopRecipe])

  if (getConnections.data && !isAuthorizedConnection(connection)) {
    Alert.warning('Integration Disconnected')
    return <Navigate to="/integrations" />
  }

  if (!userRecipe && !getRecipes.isLoading && provider.workato?.userSync) {
    return (
      <IntegrationLayout
        isDirty={false}
        isLoading={false}
        onBack={handleBack}
        onReset={() => {}}
        onSave={() => {}}
        canDelete={false}
        title={provider.name}
        onRemove={() => {}}
      >
        <ZAlert
          h="200px"
          size="lg"
          status="warning"
          hideClose
          justifyContent="center"
        >
          <chakra.span fontSize="xl">
            Something is wrong with this integration. Please contact our support team.
          </chakra.span>
        </ZAlert>
      </IntegrationLayout>
    )
  }

  return (
    <>
      <IntegrationLayout
        isDirty={isDirty}
        isLoading={isUpdating}
        onBack={handleBack}
        onReset={handleReset}
        onSave={handleSubmit}
        canDelete={canDelete}
        title={provider.name}
        onRemove={removeDisclosure.onOpen}
      >
        <ZCard
          isLoading={getRecipes.isLoading}
          variant="form"
          pb={10}
        >
          <ZCardHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={8}
            pb={0}
            gap={2}
          >
            Integration Settings
            {!userRecipe && (
              <HStack alignItems="center">
                <MdCheckCircle
                  fontSize="20px"
                  color={colorCode('atomicBlue.400')}
                />
                <ZText>Enabled</ZText>
              </HStack>
            )}
          </ZCardHeader>

          {userRecipe && (
            <>
              <Box p={8}>
                <ZHeading
                  size="h6"
                  mb={4}
                >
                  Activate
                </ZHeading>
                <UiFormControl
                  display="flex"
                  alignItems="center"
                  id="user-recipe"
                >
                  <UiToggle
                    size="lg"
                    name="user-recipe"
                    isChecked={form.user}
                    onChange={() => handleToggle('user')}
                    isDisabled={isUpdating}
                    colorScheme="atomicBlue"
                  />
                  <ZFormLabel
                    fontWeight="bold"
                    fontSize="md"
                    m={0}
                    ml={4}
                  >
                    {form.user ? 'Enabled' : 'Disabled'}
                  </ZFormLabel>
                </UiFormControl>
              </Box>

              <Divider />
            </>
          )}

          <Box p={8}>
            <IntegrationMessaging provider={provider} />
          </Box>

          {(!!accountRecipes.length || !!contactRecipes.length) && (
            <>
              <Divider />

              <VStack
                align="flex-start"
                spacing={8}
                p={8}
              >
                {!!accountRecipes.length && (
                  <UiFormControl id="account-recipe">
                    <ZHeading
                      size="h6"
                      mb={4}
                    >
                      Account Sync
                    </ZHeading>
                    <Flex align="center">
                      <UiToggle
                        size="lg"
                        name="account-recipe"
                        isChecked={form.account}
                        onChange={() => handleToggle('account')}
                        isDisabled={isUpdating}
                        colorScheme="atomicBlue"
                      />
                      <ZFormLabel
                        fontWeight="bold"
                        fontSize="md"
                        m={0}
                        ml={4}
                      >
                        {form.account ? 'Enabled' : 'Disabled'}
                      </ZFormLabel>
                    </Flex>
                  </UiFormControl>
                )}

                {!!contactRecipes.length && (
                  <UiFormControl id="contact-recipe">
                    <ZHeading
                      size="h6"
                      mb={4}
                    >
                      Contact Sync
                    </ZHeading>
                    <Flex align="center">
                      <UiToggle
                        size="lg"
                        name="contact-recipe"
                        isChecked={form.contact}
                        onChange={() => handleToggle('contact')}
                        isDisabled={isUpdating}
                        colorScheme="atomicBlue"
                      />
                      <ZFormLabel
                        fontWeight="bold"
                        fontSize="md"
                        m={0}
                        ml={4}
                      >
                        {form.contact ? 'Enabled' : 'Disabled'}
                      </ZFormLabel>
                    </Flex>
                  </UiFormControl>
                )}
              </VStack>
            </>
          )}
        </ZCard>
      </IntegrationLayout>
      {removeDisclosure.isOpen && connection && (
        <RemoveWidget
          provider={provider}
          connection={connection}
          onDisconnect={handleDisconnect}
          {...removeDisclosure}
        />
      )}
    </>
  )
}

interface RemoveWidgetProps extends Omit<ModalProps, 'children'> {
  provider: ExternalProvider
  connection: Connection
  onDisconnect: () => void
}
const RemoveWidget: React.FC<RemoveWidgetProps> = ({ provider, connection, onDisconnect, ...rest }) => {
  return (
    <ZModal
      size="5xl"
      scrollBehavior="inside"
      {...rest}
    >
      <ZModalOverlay />
      <ZModalContent>
        <ZModalHeader>{`Update ${provider.name}`}</ZModalHeader>
        <ZModalCloseButton />
        <ZModalBody
          w="100%"
          maxW="1200px"
          mx="auto"
          pt={0}
          pb={8}
          px={8}
        >
          <WorkatoWidget
            key={connection.id}
            connection={connection}
            onDisconnect={onDisconnect}
          />
        </ZModalBody>
      </ZModalContent>
    </ZModal>
  )
}
