import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlertError } from '@postal-io/postal-ui'
import { PostalSendType } from 'components/PostalSend/data'
import { PostalSendMethod } from 'components/PostalSend/postalSendHelpers'
import { PostalSendLoading } from 'components/PostalSend/PostalSendLoading'
import { useNavigateRetryCampaignParams } from 'hooks'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import type { SearchableContactFilterInput } from '../../api'
import {
  FulfillmentStatus,
  GetApprovedPostalDocument,
  GetCampaignDocument,
  SearchContactsV2Document,
  Status,
} from '../../api'
import { FAILED_LIST, SUCCESS_LIST } from '../Campaign/CampaignData'
import { PostalSend, PostalUnavailable } from '../PostalSend'

export enum CampaignCloneType {
  Failed = 'failed',
  DeliveryExpired = 'expired',
  Succeeded = 'success',
  All = 'all',
}

export const CampaignRetry: React.FC = () => {
  const navigate = useNavigate()
  const { campaignId, returnTo, returnPath, cloneType } = useNavigateRetryCampaignParams()

  const exitLabel = `Exit to ${returnTo}`
  const backLabel = `Back to ${returnTo}`

  const campaignQuery = useGraphqlQuery(GetCampaignDocument, { id: campaignId }, { enabled: !!campaignId })
  const campaign = useMemo(() => campaignQuery.data?.getCampaign, [campaignQuery.data?.getCampaign])

  const contactFilter: SearchableContactFilterInput = useMemo(() => {
    switch (cloneType) {
      case CampaignCloneType.Failed:
        return { campaignStatus: { in: FAILED_LIST.map((s) => [campaignId, s].join('_')) } }
      case CampaignCloneType.DeliveryExpired:
        return { campaignStatus: { eq: [campaignId, FulfillmentStatus.PostalDeliveryExpired].join('_') } }
      case CampaignCloneType.Succeeded:
        return { campaignStatus: { in: SUCCESS_LIST.map((s) => [campaignId, s].join('_')) } }
      case CampaignCloneType.All:
        return { campaigns: { eq: campaignId } }
      default:
        return {}
    }
  }, [campaignId, cloneType])

  const searchContacts = useGraphqlQuery(
    SearchContactsV2Document,
    { filter: contactFilter, limit: 200 },
    { enabled: !!campaignId }
  )
  const totalRecords = searchContacts?.data?.searchContactsV2?.resultsSummary?.totalRecords || 0

  const sendFilter = useMemo(() => {
    return totalRecords > 200
      ? { filters: [{ filter: contactFilter, totalRecords }] }
      : { items: searchContacts.data?.searchContactsV2?.searchableContacts || [] }
  }, [contactFilter, searchContacts.data?.searchContactsV2?.searchableContacts, totalRecords])

  const getApprovedPostal = useGraphqlQuery(
    GetApprovedPostalDocument,
    { id: campaign?.approvedPostalId as string },
    { enabled: !!campaign?.approvedPostalId }
  )
  const approvedPostal = useMemo(
    () => getApprovedPostal.data?.getApprovedPostal,
    [getApprovedPostal.data?.getApprovedPostal]
  )
  const variant = useMemo(
    () => approvedPostal?.variants?.find((v) => v.id === campaign?.variantId),
    [approvedPostal?.variants, campaign?.variantId]
  )
  const isDisabled = approvedPostal?.status !== Status.Active

  useAlertError(getApprovedPostal.error)

  if (campaignQuery.isLoading || getApprovedPostal.isLoading || searchContacts.isLoading) {
    return (
      <PostalSendLoading
        onNavigateBack={() => navigate(returnPath)}
        backLabel={backLabel}
      />
    )
  }

  if (isDisabled) {
    return (
      <PostalUnavailable
        isOpen
        onClose={() => navigate(returnPath)}
      />
    )
  }

  return (
    <PostalSend
      method={campaign?.deliveryEmail ? PostalSendMethod.Email : PostalSendMethod.Direct}
      onNavigateBack={() => navigate(returnPath)}
      navigateBackLabel={backLabel}
      onExitLabel={exitLabel}
      postal={approvedPostal!}
      variant={isDisabled ? undefined : variant}
      name={`${campaign?.name} - COPY`}
      contacts={sendFilter}
      type={PostalSendType.Campaign}
      // gift email message
      deliveryEmail={campaign?.deliveryEmail}
      giftMessage={campaign?.giftMessage}
      landingPageHeaderText={campaign?.landingPageCustomization?.headerText}
      landingPageBody={campaign?.landingPageCustomization?.body}
      landingPageIncludeHeadshot={!!campaign?.landingPageCustomization?.includeHeadshot}
      landingPageIncludeSenderName={!!campaign?.landingPageCustomization?.includeSenderName}
      formFieldList={campaign?.formFieldList}
      // physical message
      usePhysicalMessage={!!campaign?.physicalMessage}
      physicalMessage={campaign?.physicalMessage}
      sendAsUser={campaign?.sendAsUser}
      sendAsContactOwner={campaign?.sendAsContactOwner}
      meetingRequestSetting={campaign?.meetingRequestSetting}
      itemCustomizationInputs={campaign?.itemCustomizationInputs}
    />
  )
}
