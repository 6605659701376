import { Box } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlMutation } from '@postal-io/postal-graphql'
import type { Column, OrderByProps, ZCardProps } from '@postal-io/postal-ui'
import {
  internalProgressBarProps,
  internalTableProps,
  UiDate,
  UiIconMenuMore,
  UiMenu,
  UiMenuButton,
  UiMenuItem,
  UiMenuList,
  UiSSDataTable,
  UiTruncate,
  useAlertError,
  ZButton,
  ZCard,
  ZCardButtons,
  ZCardHeader,
  ZCardTitle,
  ZCheckbox,
  ZLink,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import type { PostalFulfillment } from 'api'
import {
  BulkApproveOrdersDocument,
  BulkDenyOrdersDocument,
  FulfillmentStatus,
  SearchContactPostalFulfillmentsDocument,
  SearchPostalFulfillmentsDocument,
} from 'api'
import { ZStatusTag } from 'components/Common/ZComponents'
import { useAcl, useBackgroundQueue } from 'hooks'
import { statusMapper } from 'lib'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

const LIMIT = 100
const PAGE_SIZE = 10

const DEFAULT_SORT = { key: 'created', direction: 'desc' } as OrderByProps

const STATUS_COLOR_MAP: { [key: string]: string } = {
  SUCCESS: 'green.500',
  INFO: 'blue.500',
  ERROR: 'red.500',
  PENDING_USER_APPROVAL: 'orange.500',
}

export interface PostalFulfillmentRow extends PostalFulfillment {
  onItemClick: () => void
  onOrderApprove?: (id: string) => void
  onOrderDeny?: (id: string) => void
}

const DEFAULT_COLUMNS: Column[] = [
  {
    key: 'created.dateTime',
    label: 'Date',
    orderBy: 'created',
    render: ({ created }: PostalFulfillmentRow) => <UiDate date={created?.dateTime} />,
  },
  {
    key: 'itemName',
    label: 'Item',
    render: ({ itemName }: PostalFulfillmentRow) => (
      // <ZLink onClick={onItemClick}>
      <UiTruncate
        length={30}
        text={itemName}
        showTooltip
      ></UiTruncate>
      // </ZLink>
    ),
  },
  {
    key: 'contactId',
    label: 'Contact',
    render: ({ contactId, shipToName }: PostalFulfillmentRow) => (
      <ZLink
        as={Link}
        to={`/contacts/${contactId}`}
      >
        {shipToName}
      </ZLink>
    ),
  },
  {
    key: 'cost.total',
    label: 'Cost',
    render: ({ cost }: PostalFulfillmentRow) => {
      return (
        <ZMoney
          cents={cost?.total || 0}
          currency={cost?.currency}
        />
      )
    },
  },
  {
    key: 'status',
    label: 'Status',
    render: ({ status, onItemClick }: PostalFulfillmentRow) => {
      const { type, text } = statusMapper(status)
      return (
        <ZButton
          onClick={onItemClick}
          variant="unstyled"
        >
          <ZStatusTag
            colorScheme={STATUS_COLOR_MAP[type]}
            label={text}
          />
        </ZButton>
      )
    },
    headerProps: {
      minW: '198px',
    },
  },
]

const MAGICLINK_APPROVAL_COLUMN = {
  key: 'magicLinkApproval',
  label: '',
  render: ({ status, id, onOrderApprove, onOrderDeny }: PostalFulfillmentRow) =>
    status === FulfillmentStatus.PendingUserApproval ? (
      <UiMenu placement="bottom">
        <UiMenuButton
          data-testid="menu_button"
          variant="link"
        >
          <UiIconMenuMore />
        </UiMenuButton>
        <UiMenuList>
          <UiMenuItem onClick={() => onOrderApprove!(id)}>Approve Order</UiMenuItem>
          <UiMenuItem onClick={() => onOrderDeny!(id)}>Deny Order</UiMenuItem>
        </UiMenuList>
      </UiMenu>
    ) : (
      <Box
        width="48px"
        height={0}
      />
    ),
}

interface PostalFulfillmentsV2Props extends ZCardProps {
  userId?: string
  contactId?: string
  magicLinkId?: string
  campaignId?: string
  playbookId?: string
  playbookDefinitionId?: string
  columns?: Column[]
  useContactSearch?: boolean
}

export const PostalFulfillments: React.FC<PostalFulfillmentsV2Props> = ({
  userId,
  contactId,
  magicLinkId,
  campaignId,
  playbookId,
  playbookDefinitionId,
  columns,
  useContactSearch,
  ...rest
}) => {
  const { hasFeature } = useAcl()
  const { queue } = useBackgroundQueue()
  const [hideDenied, setHideDenied] = useState<boolean>(true)

  const [orderBy, setOrderBy] = useState(DEFAULT_SORT)
  const orderByString = useMemo(() => {
    return orderBy?.key && orderBy.direction ? `${orderBy.key}_${orderBy.direction.toUpperCase()}` : undefined
  }, [orderBy.direction, orderBy.key])

  const hasMagicLinkApproval = hasFeature('manuallyApproveLinks')

  const myColumns = useMemo(() => {
    return columns || (magicLinkId && hasMagicLinkApproval)
      ? [...DEFAULT_COLUMNS, MAGICLINK_APPROVAL_COLUMN]
      : DEFAULT_COLUMNS
  }, [columns, hasMagicLinkApproval, magicLinkId])

  const hasMagicLink = hasFeature('links')
  const filteredColumns = useMemo(() => {
    return hasMagicLink ? myColumns : myColumns.filter((c: any) => c.key !== 'magicLinkName')
  }, [hasMagicLink, myColumns])

  const variables = useMemo(() => {
    const _variables = {
      filter: {},
      limit: LIMIT,
      orderBy: orderByString,
    } as any
    if (contactId) {
      if (useContactSearch) {
        _variables.contactId = contactId
      } else {
        _variables.filter.contactId = { eq: contactId }
      }
    }
    if (userId) _variables.filter.userId = { eq: userId }
    if (magicLinkId) {
      _variables.filter.magicLinkId = { eq: magicLinkId }
      if (hideDenied) {
        _variables.filter.status = { ne: FulfillmentStatus.OrderDenied }
      }
    }
    if (campaignId) _variables.filter.campaignId = { eq: campaignId }
    if (playbookId) _variables.filter.playbookId = { eq: playbookId }
    if (playbookDefinitionId) _variables.filter.playbookDefinitionId = { eq: playbookDefinitionId }
    return _variables
  }, [
    campaignId,
    contactId,
    magicLinkId,
    orderByString,
    playbookDefinitionId,
    playbookId,
    useContactSearch,
    userId,
    hideDenied,
  ])

  const hasSearchProp =
    !!contactId || !!userId || !!magicLinkId || !!campaignId || !!playbookId || !!playbookDefinitionId

  // search postal fulfillments
  const searchPostalFulfillments = useGraphqlInfiniteQuery(SearchPostalFulfillmentsDocument, variables, {
    enabled: !useContactSearch && hasSearchProp,
  })

  // search all contact related fulfillments
  const searchContactPostalFulfillments = useGraphqlInfiniteQuery(SearchContactPostalFulfillmentsDocument, variables, {
    enabled: !!useContactSearch && hasSearchProp,
  })

  const { isFetching, error, hasNextPage, fetchNextPage } = useMemo(() => {
    return useContactSearch ? searchContactPostalFulfillments : searchPostalFulfillments
  }, [searchContactPostalFulfillments, searchPostalFulfillments, useContactSearch])

  // we need to return the data depending on what query we are using
  const postalFulfillments = useMemo(() => {
    return useContactSearch
      ? searchContactPostalFulfillments.mergedData?.searchContactPostalFulfillments
      : searchPostalFulfillments.mergedData?.searchPostalFulfillments
  }, [
    searchContactPostalFulfillments.mergedData?.searchContactPostalFulfillments,
    searchPostalFulfillments.mergedData?.searchPostalFulfillments,
    useContactSearch,
  ])

  const approveOrders = useGraphqlMutation(BulkApproveOrdersDocument)
  const denyOrders = useGraphqlMutation(BulkDenyOrdersDocument)

  const handleApproveOrder = useCallback(
    (id: string) => {
      approveOrders.mutateAsync({ fulfillmentIds: [id] }).then((d) => queue(d.bulkApproveOrders))
    },
    [approveOrders, queue]
  )

  const handleDenyOrder = useCallback(
    (id: string) => {
      denyOrders.mutateAsync({ fulfillmentIds: [id] }).then((d) => queue(d.bulkDenyOrders))
    },
    [denyOrders, queue]
  )

  const handleHideDenyCheck = () => {
    setHideDenied((p) => !p)
  }

  // const handleItemClick = useCallback((row: PostalFulfillment) => null, [rowItemDisclosure])

  const normalizedRows = useMemo(() => {
    return (
      postalFulfillments?.map((fulfillment) => {
        return {
          ...fulfillment,
          // onItemClick: () => handleItemClick(fulfillment),
          ...(magicLinkId
            ? {
                onOrderApprove: (id: string) => handleApproveOrder(id),
                onOrderDeny: (id: string) => handleDenyOrder(id),
              }
            : {}),
        }
      }) ?? []
    )
  }, [postalFulfillments, handleApproveOrder, handleDenyOrder, magicLinkId])

  useAlertError(error)

  return (
    <>
      <ZCard
        variant="dash"
        borderWidth="1px"
        borderColor="atomicGray.200"
        {...rest}
      >
        <ZCardHeader>
          <ZCardTitle>Orders</ZCardTitle>
          <ZCardButtons>
            <ZText
              color="atomicGray.500"
              mr={2}
            >
              Hide Denied Orders
            </ZText>
            <ZCheckbox
              colorScheme="atomicBlue"
              isChecked={hideDenied}
              defaultChecked={hideDenied}
              onChange={handleHideDenyCheck}
            />
          </ZCardButtons>
        </ZCardHeader>
        <UiSSDataTable
          mt={4}
          columns={filteredColumns}
          rows={normalizedRows}
          rowKey="id"
          pageSize={PAGE_SIZE}
          variant="list"
          hasMore={hasNextPage}
          isLoading={isFetching}
          fetchMore={fetchNextPage}
          filter={variables.filter}
          orderBy={orderBy}
          onOrderBy={setOrderBy}
          tableProps={internalTableProps}
          progressBarProps={internalProgressBarProps}
        />
      </ZCard>
    </>
  )
}
