import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import { ZText } from '@postal-io/postal-ui'
import type { Contact } from 'api'
import React from 'react'

interface ExtContactStatsProps extends BoxProps {
  contact: Contact
}
export const ExtContactStats: React.FC<ExtContactStatsProps> = ({ contact, ...rest }) => {
  const metrics = contact?.metrics || {}

  const getDateString = (date: Date) => {
    const dateObj = new Date(date)
    return `${('0' + dateObj.getMonth()).slice(-2)}/${('0' + dateObj.getDate()).slice(-2)}/${dateObj.getFullYear()}`
  }

  return (
    <Box {...rest}>
      <Flex columnGap="14px">
        <Flex>
          <ZText
            size="md"
            color="atomicGray.500"
          >
            {getDateString(contact?.created?.dateTime)}
          </ZText>
          <ZText
            size="md"
            color="atomicGray.600"
          >
            &nbsp;Added
          </ZText>
        </Flex>
        <Flex>
          <ZText
            size="md"
            color="atomicGray.500"
          >
            {metrics.touches || 0}
          </ZText>
          <ZText
            size="md"
            color="atomicGray.600"
          >
            &nbsp;Items
          </ZText>
        </Flex>
      </Flex>
    </Box>
  )
}
