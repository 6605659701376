import { Box, Divider, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { UiLink, UiSeparator, useAlerts } from '@postal-io/postal-ui'
import type { AddressInput, Contact } from 'api'
import { ContactType, UpdateContactDocument } from 'api'
import { ZBasicDialogButtons, ZDialog } from 'components/Common/ZComponents'
import { CONTACT_INVALIDATIONS, useBackgroundQueue } from 'hooks'
import sortBy from 'lodash/sortBy'
import React, { useMemo, useState } from 'react'
import { ContactAddressBlock } from './ContactAddressBlock'
import { ContactCreateEdit } from './ContactCreateEdit'

interface ContactAddressListV2Props {
  contact: Contact
}

export const ContactAddressList: React.FC<ContactAddressListV2Props> = ({ contact }) => {
  const Alert = useAlerts()
  const removeAddress = useDisclosure()
  const editAddress = useDisclosure()
  const setPreferredAddress = useDisclosure()

  const [selectedAddrIndx, setSelectedAddrIndx] = useState<number | undefined>(undefined)

  const showMore = useDisclosure()

  const { invalidate } = useBackgroundQueue()
  const updateContact = useGraphqlMutation(UpdateContactDocument, {
    onSuccess: () => invalidate(CONTACT_INVALIDATIONS),
  })

  //These addresses always need to be normalized to AddressInputs if changes are being made or new addresses being added.
  const addresses = useMemo(() => {
    return (
      (sortBy(contact?.addresses || [], (item) => (item?.preferred ? 0 : 1)) as AddressInput[]).map((item) => {
        return {
          entryName: item?.entryName,
          address1: item?.address1,
          address2: item?.address2,
          address3: item?.address3,
          city: item?.city,
          state: item?.state,
          postalCode: item?.postalCode,
          country: item?.country,
          preferred: item?.preferred,
          source: item?.source,
          status: item?.status,
        }
      }) || []
    )
  }, [contact?.addresses])

  const handleRemoveAddress = async () => {
    const addressInputs = addresses as AddressInput[]
    addressInputs.splice(Number(selectedAddrIndx), 1)
    try {
      await updateContact.mutateAsync({
        id: contact?.id,
        data: { type: ContactType.Contact, addresses: addressInputs },
      })

      setSelectedAddrIndx(undefined)
      removeAddress.onClose()
      Alert.success('Address removed')
    } catch (err) {
      Alert.error(err)
    }
  }

  const removeAddressHandler = (idx: number) => {
    setSelectedAddrIndx(idx)
    removeAddress.onOpen()
  }

  const preferredAddressHandler = (idx: number) => {
    setSelectedAddrIndx(idx)
    setPreferredAddress.onOpen()
  }

  const handleEditAddress = (idx?: number) => {
    //reset local state
    setSelectedAddrIndx(undefined)

    if (typeof idx === 'number') {
      setSelectedAddrIndx(idx)
      editAddress.onOpen()
    }
  }

  const handleMakePreferred = async () => {
    const addressInputs = addresses.map((address, idx) => ({
      ...address,
      preferred: idx === selectedAddrIndx,
    }))

    try {
      await updateContact.mutateAsync({
        id: contact?.id,
        data: {
          type: ContactType.Contact,
          addresses: addressInputs as AddressInput[],
        },
      })
      setSelectedAddrIndx(undefined)
      setPreferredAddress.onClose()
      Alert.success('Address Set as Default')
    } catch (err) {
      Alert.error(err)
    }
  }

  const [firstAddress, ...restAddresses] = addresses

  const handleOnClose = () => {
    setSelectedAddrIndx(undefined)
    editAddress.onClose()
  }

  return (
    <Box>
      <UiSeparator separator={<Divider my={4} />}>
        {firstAddress && (
          <ContactAddressBlock
            address={firstAddress}
            mappings={contact?.mappings}
            onRemove={() => removeAddressHandler(0)}
            onPrefer={() => preferredAddressHandler(0)}
            onEdit={() => handleEditAddress(0)}
          />
        )}
        {showMore.isOpen &&
          restAddresses.map((address, idx) => {
            return (
              <ContactAddressBlock
                key={idx}
                address={address}
                mappings={contact?.mappings}
                onRemove={() => removeAddressHandler(idx + 1)}
                onPrefer={() => preferredAddressHandler(idx + 1)}
                onEdit={() => handleEditAddress(idx + 1)}
              />
            )
          })}
      </UiSeparator>
      <Flex
        alignItems="center"
        mt={4}
      >
        {addresses.length > 1 && (
          <Box>
            <UiLink onClick={showMore.onToggle}>{showMore.isOpen ? 'Show Less' : 'Show More'}</UiLink>
          </Box>
        )}
      </Flex>
      <ZDialog
        isOpen={removeAddress.isOpen}
        onClose={removeAddress.onClose}
        size="lg"
        title="Remove Address"
      >
        <Text>
          Are you sure you want to <strong>Remove</strong> this address?
        </Text>
        <ZBasicDialogButtons
          onClose={removeAddress.onClose}
          onConfirm={handleRemoveAddress}
          confirmColorScheme="atomicRed"
          confirmText="Remove"
        />
      </ZDialog>
      <ZDialog
        size="lg"
        title="Set Default Address"
        isOpen={setPreferredAddress.isOpen}
        onClose={setPreferredAddress.onClose}
      >
        <Text>
          Are you sure you want to make this address <strong>Preferred</strong>?
        </Text>
        <ZBasicDialogButtons
          onClose={setPreferredAddress.onClose}
          onConfirm={handleMakePreferred}
          confirmColorScheme="atomicRed"
          confirmText="Confirm"
        />
      </ZDialog>
      {editAddress.isOpen && (
        <ContactCreateEdit
          contact={contact}
          addresses={addresses}
          initialView="address"
          isOpen={editAddress.isOpen}
          onClose={handleOnClose}
          selectedTabIndex={selectedAddrIndx}
        />
      )}
    </Box>
  )
}
