import type { UseGraphqlFilterResponse } from '@postal-io/postal-ui'
import { ZButton, ZInput } from '@postal-io/postal-ui'

import { useAcl } from 'hooks'
import type { ChangeEvent, PropsWithChildren } from 'react'
import React from 'react'
import type { MiniTeam } from '../../hooks'
import { AutoCompleteTeam, MenuUserRole } from '../AutoComplete'

type UsersFilterV2Props = Pick<UseGraphqlFilterResponse, 'filter' | 'setFilter'>

export const UsersFilter: React.FC<UsersFilterV2Props & PropsWithChildren> = ({ filter, setFilter, children }) => {
  const { hasPermission } = useAcl()
  const canReadTeams = hasPermission('teams.read')

  const handleInput = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFilter({ key: target.name, value: target.value })
  }

  const handleTeam = (team: MiniTeam | null) => {
    setFilter({ key: 'productAccess.teamId', value: team?.teamId || team?.accountId })
  }

  return (
    <>
      <ZInput
        type="search"
        name="firstName"
        onChange={handleInput}
        value={filter.firstName || ''}
        placeholder="Search First Name"
        aria-label="Search First Name"
      />
      <ZInput
        type="search"
        name="lastName"
        onChange={handleInput}
        value={filter.lastName || ''}
        placeholder="Search Last Name"
        aria-label="Search Last Name"
      />
      <ZInput
        type="search"
        name="userName"
        onChange={handleInput}
        value={filter.userName || ''}
        placeholder="Search Email"
        aria-label="Search Email"
      />
      {canReadTeams && (
        <AutoCompleteTeam
          onChange={handleTeam}
          rootProps={{
            minW: '200px',
            minHeight: '40px',
            height: '40px',
            fontSize: '14px',
            borderColor: 'atomicGray.600',
            borderRadius: '3px',
          }}
        />
      )}
      <MenuUserRole
        value={filter['productAccess.roles'] || []}
        onChange={(roles) => setFilter({ key: 'productAccess.roles', value: roles })}
        w="100%"
        h="40px"
        fontSize="14px"
        fontWeight="normal"
        color="atomicGray.600"
        textAlign="left"
        as={ZButton}
      />
      {children}
    </>
  )
}
