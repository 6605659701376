import { Currency } from 'api'
import books from 'assets/categories/books2.jpeg'
import charity from 'assets/categories/charity.png'
import directmail from 'assets/categories/direct-mail3.jpeg'
import drink from 'assets/categories/drink.png'
import electronics from 'assets/categories/electronics2.jpeg'
import everything from 'assets/categories/everything.png'
import flowers2 from 'assets/categories/flowers2.jpeg'
import food from 'assets/categories/food2.jpeg'
import giftcard from 'assets/categories/giftcard2.jpeg'
import giftsets from 'assets/categories/giftsets.jpeg'
import handwritten from 'assets/categories/handwritten.png'
import health2 from 'assets/categories/health2.jpeg'
import holidays from 'assets/categories/holidays.jpg'
import office from 'assets/categories/office-essentials.jpeg'
import pet from 'assets/categories/pet.jpeg'
import sports from 'assets/categories/sports2.png'
import swag from 'assets/categories/swag.png'
import HOLIDAY_IMAGE from 'assets/featured/Postal_AppBanner_February.jpg'
import PARTNER_IMAGE from 'assets/featured/Postal_AppBanner_July.jpg'
import ST_PATRICK_IMAGE from 'assets/featured/st-patrick.png'
import { LEGACY_CURRENCIES } from 'components/Billing/BillingAccountCreate'

export const MARKETPLACE_CARD_MIN_WIDTH = 250

export const MARKETPLACE_V2_INTERNATIONAL_SHIP_TO = [
  { label: 'Canada', value: 'CAN' },
  { label: 'United Kingdom', value: 'UK' },
  { label: 'Australia', value: 'AUS' },
  { label: 'France', value: 'FRA' },
  { label: 'New Zealand', value: 'NZL' },
]
export const MARKETPLACE_INTERNATIONAL_SHIP_TO = ['Canada', 'United Kingdom', 'Australia', 'France', 'New Zealand']

export const MARKETPLACE_SECTION_EVENT_TYPES = [
  'Cocktail',
  'Coffee',
  'Cooking',
  'Comedy',
  'Baking',
  'Plants',
  'Cheese',
  'Wine Tasting',
  'Beer & Spirits',
  'Food',
  'Trivia',
  'Chocolate',
  'Fitness',
  'Games',
]

// For new subCategory filters
export const MARKETPLACE_SECTION_EVENT_SUBCATEGORY_LINKS: { [k: string]: string[] } = {
  'Cocktail': ['Cocktail'],
  'Coffee': ['Coffee'],
  'Cooking': ['Cooking'],
  'Comedy': ['Comedy'],
  'Baking': ['Baking'],
  'Plants': ['Plants'],
  'Cheese': ['Cheese'],
  'Wine Tasting': ['Wine Tasting'],
  'Beer & Spirits': ['Beer & Spirits'],
  'Food': ['Food'],
  'Trivia': ['Trivia'],
  'Chocolate': ['Chocolate'],
  'Fitness': ['Fitness'],
  'Games': ['Games'],
}

// TODO: Remove this when marketplace search V2 fully rolled out
export const BEST_SELLERS_BY_PRICE: { heading: string; text: string; priceRange: { min?: number; max?: number } }[] = [
  {
    heading: 'Under',
    text: '$25',
    priceRange: {
      max: 25,
    },
  },
  {
    heading: 'Between',
    text: '$25-$50',
    priceRange: {
      min: 25,
      max: 50,
    },
  },
  {
    heading: 'Between',
    text: '$50-$75',
    priceRange: {
      min: 50,
      max: 75,
    },
  },
  {
    heading: 'Between',
    text: '$75-$150',
    priceRange: {
      min: 75,
      max: 150,
    },
  },
  {
    heading: 'Over',
    text: '$150',
    priceRange: {
      min: 150,
    },
  },
]

export const BEST_SELLERS_BY_PRICE_RANGE: { heading: string; text: string; price: string }[] = [
  {
    heading: 'Under',
    text: '$10',
    price: '$',
  },
  {
    heading: 'Between',
    text: '$10-$25',
    price: '$$',
  },
  {
    heading: 'Between',
    text: '$25-$75',
    price: '$$$',
  },
  {
    heading: 'Over',
    text: '$75',
    price: '$$$$',
  },
]

export enum CATEGORY {
  Holidays = 'Holidays',
  Books = 'Books',
  Charities = 'Charities',
  DirectMail = 'Direct Mail',
  DrinkAccessories = 'Drinks & Accessories',
  Electronics = 'Electronics',
  Events = 'Events',
  FlowersPlants = 'Flowers & Plants',
  Food = 'Food',
  GiftCards = 'Gift Cards',
  HealthWellness = 'Health & Wellness',
  Health = 'Health and Wellness',
  Home = 'Home',
  Sports = 'Sports',
  Swag = 'Swag',
  GreetingCards = 'Greeting Cards',
  Games = 'Games',
  KitchenOutdoor = 'Kitchen & Outdoor',
  Pets = 'Pets',
  CandlesFragrances = 'Candles & Fragrances',
  Apparel = 'Apparel',
  AccessoriesJewelry = 'Accessories & Jewelry',
  OfficeEssentials = 'Office Essentials',
  GiftBoxesBaskets = 'Gift Boxes & Baskets',

  //Legacy
  GiftSets = 'Gift Sets',
  Treats = 'Treats',
  Handwritten = 'Handwritten',
  HolidayGifts = 'Holiday Gifts',
  EverythingElse = 'Everything Else',
  Flowers = 'Flowers',
  Drink = 'Drink',
  WorkFromHome = 'Work from Home',

  //only in CORE?
  PersonalizedGifts = 'Personalized Gifts',
  Holiday = 'Holiday',
  Collection = 'Collection',
}

export const CATEGORY_DEFAULT_IMAGE_MAP: { [index: string]: string } = {
  [CATEGORY.Holiday]: holidays,
  [CATEGORY.DrinkAccessories]: drink,
  [CATEGORY.Food]: food,
  [CATEGORY.Books]: books,
  [CATEGORY.GiftBoxesBaskets]: giftsets,
  [CATEGORY.OfficeEssentials]: office,
  [CATEGORY.Charities]: charity,
  [CATEGORY.Electronics]: electronics,
  [CATEGORY.FlowersPlants]: flowers2,
  [CATEGORY.Pets]: pet,
  [CATEGORY.Sports]: sports,
  [CATEGORY.HealthWellness]: health2,
  [CATEGORY.GiftCards]: giftcard,
  [CATEGORY.Swag]: swag,
  [CATEGORY.Handwritten]: handwritten,
  [CATEGORY.DirectMail]: directmail,
  [CATEGORY.EverythingElse]: everything,
}

export const CATEGORIES_ORDER = Object.keys(CATEGORY_DEFAULT_IMAGE_MAP)

export enum FeaturedDataType {
  Holiday = 'HOLIDAY',
  December = 'DECEMBER',
  Partner = 'PARTNER',
  StPatrick = 'ST_PATRICK',
}

type GetFeaturedData = {
  title: string
  name: string
  description: string
  url: string
  filter: { key: string; value: string[] }
  image: string
  cta: string
} & Record<string, any>

export const getFeaturedData = (type: string): GetFeaturedData => {
  switch (type) {
    case FeaturedDataType.Holiday:
      return {
        title: `Celebrate Women-Owned Businesses`,
        name: `Celebrate Women-Owned Businesses`,
        description: `Make supporting businesses that matter a year-round priority. Shop Loved & Found for an array of unique gifts that leave a lasting impression and drive outcomes.`,
        url: '/items/marketplace?vendor=Loved%20and%20Found',
        filter: { key: 'categories', value: [] },
        image: HOLIDAY_IMAGE,
        cta: 'Shop Now',
      }
    case FeaturedDataType.StPatrick:
      return {
        title: `Seasonal Feature`,
        name: `Celebrate St. Patrick’s Day`,
        description: `Let someone know you feel <strong>lucky</strong> to work with them with our curated selection of festive Irish gifts! From whiskey oak-aged to perfection to interactive mixology classes, the Postal Marketplace has everything you need for St. Patrick’s Day on March 17th.`,
        url: `/items/marketplace`,
        filter: { key: 'categories', value: [CATEGORY.Drink] },
        image: ST_PATRICK_IMAGE,
        cta: `Shop Now`,
      }
    case FeaturedDataType.December:
    case FeaturedDataType.Partner:
    default:
      return {
        title: `Give Baseball Fans Something to Cheer About`,
        name: `Give Baseball Fans Something to Cheer About`,
        description: `Knock it out of the park with prospects and customers this summer by sending a hand-made wooden bat or pennant showcasing their favorite MLB team from Pillbox Bat Co.`,
        url: `/items/marketplace?vendor=Pillbox%20Bat%20Company`,
        filter: { key: 'categories', value: [] },
        image: PARTNER_IMAGE,
        cta: 'Shop Now',
      }
  }
}

export const ITEM_CATEGORY_EXCLUSIONS = [CATEGORY.Events]
export const MARKETPLACE_CATEGORY_EXCLUSIONS = [CATEGORY.Events, CATEGORY.Collection]
export const BULK_ITEM_CATEGORY_EXCLUSIONS = [...ITEM_CATEGORY_EXCLUSIONS, CATEGORY.DirectMail]
export const CATEGORIES_ITEMS_ONLY = Object.values(CATEGORY).filter((c) => !ITEM_CATEGORY_EXCLUSIONS.includes(c))
export const CATEGORIES_BULK_ITEMS = Object.values(CATEGORY).filter((c) => !BULK_ITEM_CATEGORY_EXCLUSIONS.includes(c))
export const CURRENCY_LIST = Object.values(Currency).filter((c) => LEGACY_CURRENCIES.includes(c))

export const MARKETPLACE_PRODUCT_FILTER_INPUT_KEYS = [
  'attributeFilters',
  'brandName',
  'category',
  'currency',
  'eventAvailabilityDates',
  'geographicMarkets',
  'id',
  'name',
  'q',
  'restrictPlans',
  'subCategory',
  'type',
  'variants_displayPrice',
  'variants_fulfillmentPartnerList_displayPrice',
  'variants_fulfillmentPartnerList_displaySubscriberPrice',
]

export const APPROVED_POSTAL_FILTER_INPUT_KEYS = [
  'id',
  'userId',
  'ownerId',
  'name',
  'teamIds',
  'status',
  'brandName',
  'category',
  'currency',
  'type',
  'subCategory',
  'geographicMarkets',
  'marketplaceProductId',
  'attributeFilters',
  'variants_displayPrice',
  'variants_displaySubscriberPrice',
  'collection',
  'event_status',
  'q',
]
