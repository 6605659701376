/* eslint-disable react/prop-types */
import type { BoxProps } from '@chakra-ui/react'
import { Box, Collapse, Flex, SimpleGrid, Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiChangeEvent, UiGenericChangeEvent } from '@postal-io/postal-ui'
import {
  humanize,
  UiFormControl,
  UiIconButton,
  UiRadioButtonGroup,
  useAlerts,
  ZButton,
  ZCard,
  ZFormLabel,
  ZInput,
  ZInputMoney,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
  ZModalOverlay,
  ZMoney,
  ZRadioButton,
  ZText,
} from '@postal-io/postal-ui'
import { BillingAccountsEdit } from 'components/Billing/BillingAccountsEdit'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { TeamAccountBlock } from 'components/Teams/TeamAccountBlock'
import { TeamUsers } from 'components/Teams/TeamUsers'
import { useAcl } from 'hooks'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { MdOutlineInfo } from 'react-icons/md'
import { useImmer } from 'use-immer'
import type { Account, Team } from '../../api'
import {
  BudgetDuration,
  BudgetMode,
  GetBudgetRemainingDocument,
  UpdateAccountDocument,
  UpdateTeamDocument,
} from '../../api'
import { BudgetHelp } from './BudgetHelp'

const DURATIONS = [BudgetDuration.Monthly, BudgetDuration.Quarterly, BudgetDuration.Yearly]
const MIN_AMOUNT = 1
const MAX_AMOUNT = 1000000

const budgetIn = (amount?: number) => Number(amount || 0)
const budgetOut = (amount?: number) => Number(amount || 0)

interface AccountStateProps {
  budgetAmount?: number
  budgetMode?: BudgetMode
  budgetDuration?: BudgetDuration
  dirty: boolean
  name: string
}

interface AccountPageProps extends BoxProps {
  account: Account
  team?: Team
  hideUsers?: boolean
}

export const AccountPage: React.FC<AccountPageProps> = ({ account, team, hideUsers, ...rest }) => {
  const Alert = useAlerts()
  const showBudgetHelp = useDisclosure()
  const editBillingAccounts = useDisclosure()
  const editTeam = useDisclosure()
  const { hasPermission } = useAcl()
  const canUpdate = hasPermission('billing.update')

  const { name, billingAccountIds, budgetAmount, budgetDuration, budgetMode } = useMemo(() => {
    const item = team ?? account
    return {
      name: item.name,
      billingAccountIds: item.settings?.billingAccountIds || [],
      budgetAmount: item.settings?.budget?.amount,
      budgetDuration: item.settings?.budget?.duration,
      budgetMode: item.settings?.budget?.mode,
    }
  }, [account, team])

  // cache for incoming data
  const [state, setState] = useImmer<AccountStateProps>({ dirty: false, name })

  // reset state to inbound props
  const handleReset = useCallback(() => {
    setState((draft) => {
      draft.name = name
      draft.budgetAmount = budgetIn(budgetAmount)
      draft.budgetDuration = budgetDuration
      draft.budgetMode = budgetMode
      draft.dirty = false
    })
  }, [budgetAmount, budgetDuration, budgetMode, name, setState])

  // reset state on inbound prop changes
  useEffect(() => {
    handleReset()
  }, [handleReset])

  // if state is dirty scroll to buttons
  const scrollRef = useRef<any>()
  useEffect(() => {
    if (state.dirty && scrollRef.current) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
    }
  }, [state.dirty])

  const getBudgetRemaining = useGraphqlQuery(GetBudgetRemainingDocument, {
    accountId: account.id,
    teamId: team?.id,
  })

  const budgetRemaining = Number(getBudgetRemaining.data?.getBudgetRemaining?.budgetRemaining || 0)

  // updating data
  const updateTeam = useGraphqlMutation(UpdateTeamDocument)
  const updateAccount = useGraphqlMutation(UpdateAccountDocument)

  // handle update delegate depending on type of account
  const update = async () => {
    if (!state.name) return Alert.warning('Account name cannot be empty')
    if (state.budgetMode !== BudgetMode.Unlimited && !state.budgetAmount) {
      return Alert.warning('Please specify budget amount')
    }
    const newData = {
      name: state.name,
      settings: {
        billingAccountIds,
        budget: {
          duration: state.budgetDuration || BudgetDuration.Monthly,
          amount: budgetOut(state.budgetAmount),
          mode: state.budgetMode || BudgetMode.Unlimited,
        },
      },
    }

    try {
      team
        ? await updateTeam.mutateAsync({ id: team.id, data: newData })
        : await updateAccount.mutateAsync({ id: account.id, data: newData })
      Alert.success(`${newData.name} Updated`)
    } catch (error) {
      Alert.error(error)
    }
  }

  // Handle form change
  const handleChange = ({ target }: UiGenericChangeEvent) => {
    setState((draft) => {
      switch (target.name) {
        case 'budgetDuration':
          draft.budgetDuration = target.value as BudgetDuration
          break
        case 'budgetMode':
          draft.budgetMode = target.value as BudgetMode
          break
        case 'budgetAmount':
          draft.budgetAmount = Number(target.value)
          break
        case 'name':
          draft.name = target.value as string
          break
        default:
      }
      draft.dirty =
        draft.name !== name ||
        draft.budgetDuration !== budgetDuration ||
        draft.budgetMode !== budgetMode ||
        draft.budgetAmount !== budgetIn(budgetAmount)
    })
  }

  // Handle form change
  const handleMoneyChange = ({ value }: UiChangeEvent<number>) => {
    setState((draft) => {
      draft.budgetAmount = Number(value)

      draft.dirty =
        draft.name !== name ||
        draft.budgetDuration !== budgetDuration ||
        draft.budgetMode !== budgetMode ||
        draft.budgetAmount !== budgetIn(budgetAmount)
    })
  }

  const isLoading = getBudgetRemaining.isLoading || updateTeam.isLoading || updateAccount.isLoading

  return (
    <>
      <ZCard
        isLoading={isLoading}
        display="flex"
        flexDir="column"
        minH="100%"
        variant="form"
        {...rest}
      >
        <Stack
          spacing={10}
          flex={1}
        >
          <Box
            p={8}
            pb={0}
            height="80px"
          >
            <ZFormLabel
              htmlFor="account-name"
              fontSize="sm"
              m={0}
              color="atomicGray.700"
            >
              Team Name
            </ZFormLabel>
            {!!team ? (
              <ZInput
                id="account-name"
                name="name"
                value={state.name}
                onChange={handleChange}
                height="26px"
                fontWeight={500}
                variant="outline"
                px={2}
                m={0}
              />
            ) : (
              <ZText
                fontWeight={500}
                fontSize="md"
                color="atomicGray.700"
              >
                {name || 'Main Account'}
              </ZText>
            )}
          </Box>
          <Box px={8}>
            <ZText
              fontWeight={500}
              color="atomicGray.700"
              display="flex"
              alignItems="center"
              mb={2}
            >
              Billing Account(s)
              {canUpdate && (
                <>
                  <ZInfoTooltip
                    ml={2}
                    hasArrow
                    label="You can attach multiple billing accounts to this team, so they can access different currencies to send."
                  />
                  <ZLink
                    ml={2}
                    onClick={editBillingAccounts.onOpen}
                    color="atomicBlue.400"
                  >
                    Edit
                  </ZLink>
                </>
              )}
            </ZText>
            <Stack spacing={4}>
              {billingAccountIds?.map((obj) => (
                <TeamAccountBlock
                  key={obj.billingAccountId}
                  billingAccountId={obj.billingAccountId}
                />
              ))}
            </Stack>
          </Box>
          <Box px={8}>
            <ZFormLabel
              htmlFor="budgetMode"
              fontWeight={500}
              color="atomicGray.700"
              display="flex"
              alignItems="center"
              mb={2}
            >
              Budget Mode
              <UiIconButton
                title="Budget Mode Help"
                aria-label="Budget Mode Help"
                colorScheme="atomicGray"
                color="atomicGray.300"
                ml={0}
                p={0}
                icon={<MdOutlineInfo size="18px" />}
                variant="link"
                onClick={showBudgetHelp.onOpen}
              />
            </ZFormLabel>
            <UiRadioButtonGroup
              id="budgetMode"
              name="budgetMode"
              value={state.budgetMode}
              defaultValue={state.budgetMode}
              display="grid"
              maxWidth="100%"
              gridTemplateColumns="repeat(3, 1fr)"
              onChange={handleChange}
              gridRowGap={2}
              spacing={{
                base: 0,
                xl: 2,
              }}
              sx={{
                '& label': {
                  base: {
                    gridColumn: 'span 3',
                  },
                  xl: {
                    gridColumn: 'span 1',
                  },
                },
              }}
            >
              <ZRadioButton value={BudgetMode.Unlimited}>Disabled</ZRadioButton>
              <ZRadioButton
                data-testid="radio-button-pooled"
                value={BudgetMode.Pooled}
              >
                Pooled
              </ZRadioButton>
              <ZRadioButton value={BudgetMode.PerUser}>Per User</ZRadioButton>
            </UiRadioButtonGroup>
          </Box>
          {state.budgetMode !== BudgetMode.Unlimited && (
            <Box px={8}>
              <ZFormLabel
                htmlFor="budgetDuration"
                fontWeight={500}
                color="atomicGray.700"
                display="flex"
                alignItems="center"
                mb={2}
              >
                Budget Duration
              </ZFormLabel>
              <UiRadioButtonGroup
                id="budgetDuration"
                name="budgetDuration"
                value={state.budgetDuration}
                defaultValue={state.budgetDuration}
                display="grid"
                maxWidth="100%"
                gridTemplateColumns="repeat(3, 1fr)"
                onChange={handleChange}
                gridRowGap={2}
                spacing={{
                  base: 0,
                  xl: 2,
                }}
                sx={{
                  '& label': {
                    base: {
                      gridColumn: 'span 3',
                    },
                    xl: {
                      gridColumn: 'span 1',
                    },
                  },
                }}
              >
                {DURATIONS.map((duration) => (
                  <ZRadioButton
                    key={duration}
                    value={duration}
                  >
                    {humanize(duration)}
                  </ZRadioButton>
                ))}
              </UiRadioButtonGroup>
            </Box>
          )}
          {state.budgetMode !== BudgetMode.Unlimited && (
            <UiFormControl px={8}>
              <ZFormLabel
                htmlFor="budgetAmount"
                fontWeight={500}
                color="atomicGray.700"
                display="flex"
                alignItems="center"
                mb={2}
              >
                Budget Amount
              </ZFormLabel>
              <Flex alignItems="center">
                <ZInputMoney
                  name="budgetAmount"
                  id="budgetAmount"
                  type="number"
                  min={MIN_AMOUNT}
                  max={MAX_AMOUNT}
                  isDisabled={updateAccount.isLoading}
                  isRequired
                  value={state.budgetAmount}
                  onChange={handleMoneyChange}
                />
              </Flex>
            </UiFormControl>
          )}

          {state.budgetMode === BudgetMode.Pooled && (
            <Box px={8}>
              <ZText
                fontWeight={500}
                fontSize="14px"
                display="flex"
                alignItems="center"
                gap={2}
              >
                Remaining Budget
                <ZInfoTooltip
                  hasArrow
                  label="The budget the team has to spend over a given duration. This amount can be lower or higher than the actual funds loaded, and it serves as a safeguard to prevent over-spending."
                />
              </ZText>
              <ZMoney
                fontSize="33px"
                color="atomicGray.500"
                fontWeight={500}
                cents={budgetRemaining ?? 0}
              />
            </Box>
          )}
        </Stack>

        <Flex
          flexDir="column"
          justifyContent="flex-end"
          mt={8}
          p={4}
          borderTopWidth="1px"
          borderTopColor="atomicGray.100"
        >
          {!state.dirty && !hideUsers && (
            <ZLink
              onClick={editTeam.onOpen}
              fontSize="md"
              textAlign="center"
              color="atomicBlue.400"
              p={4}
              m={0}
            >
              Manage Users
            </ZLink>
          )}

          <Collapse in={state.dirty}>
            <SimpleGrid
              columns={2}
              spacing={4}
              m={0}
            >
              <ZButton
                size="md"
                colorScheme="atomicGray"
                variant="outline"
                onClick={handleReset}
                w="full"
                h="100%"
                justifyContent="center"
                p={4}
                m={0}
                lineHeight={1.3}
              >
                Reset
              </ZButton>
              <ZButton
                size="md"
                colorScheme="atomicBlue"
                onClick={update}
                w="full"
                h="100%"
                justifyContent="center"
                p={4}
                m={0}
                lineHeight={1.3}
              >
                Save
              </ZButton>
            </SimpleGrid>
          </Collapse>
        </Flex>
      </ZCard>

      {editTeam.isOpen && (
        <ZModal
          size="6xl"
          isOpen={editTeam.isOpen}
          onClose={editTeam.onClose}
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>{state.name}</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <TeamUsers
                teamId={team?.id}
                accountId={account.id}
              />
            </ZModalBody>
            <ZModalFooter justifyContent="flex-end">
              <ZButton
                colorScheme="atomicGray"
                onClick={editTeam.onClose}
                variant="ghost"
                minW="140px"
              >
                Close
              </ZButton>
            </ZModalFooter>
          </ZModalContent>
        </ZModal>
      )}

      {showBudgetHelp.isOpen && (
        <BudgetHelp
          isOpen={showBudgetHelp.isOpen}
          onClose={showBudgetHelp.onClose}
        />
      )}
      <BillingAccountsEdit
        account={account}
        team={team}
        isOpen={editBillingAccounts.isOpen}
        onClose={editBillingAccounts.onClose}
      />
    </>
  )
}
