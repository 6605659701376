import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiSelectTypeaheadCreatableProps, UiSelectTypeaheadProps } from '@postal-io/postal-ui'
import { UiSelectTypeahead, UiSelectTypeaheadCreatable, useAlertError, useAlerts } from '@postal-io/postal-ui'
import { orderBy } from 'natural-orderby'
import { useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import type { Tag } from '../../api'
import { CreateTagDocument, SearchTagsDocument } from '../../api'

export function AutoCompleteTags(props: UiSelectTypeaheadProps<Tag, true>) {
  const [search, setSearch] = useState(props.inputValue || props.defaultInputValue || '')
  const [debounced] = useDebounce(search, 400)

  const variables = useMemo(() => {
    return { filter: { name: debounced ? { contains: debounced } : undefined }, limit: 20 }
  }, [debounced])

  const query = useGraphqlQuery(SearchTagsDocument, variables)
  useAlertError(query.error)

  const items = useMemo(() => orderBy(query.data?.searchTags || [], ['name']), [query.data?.searchTags])

  const handleInput = useCallback(
    (val: any, meta: any) => {
      setSearch(val)
      props.onInputChange && props.onInputChange(val, meta)
    },
    [props]
  )

  return (
    <UiSelectTypeahead
      data-testid="AutoCompleteTags"
      options={items}
      getOptionLabel={(t) => t.name}
      getOptionValue={(t) => t.id}
      onInputChange={handleInput}
      isLoading={query.isLoading}
      placeholder="Search Tags"
      noOptionsMessage={() => 'No Tags Found'}
      isMulti
      {...props}
    />
  )
}

/*
  We are converting the id on these tags to be the same as the name.

  This is done because contacts don't have a reference to tag objects, they just
  have an array of strings. In order to set an initial value, we need to pass in
  an array of objects without an id.

  The tag names returned to a user are unique so we are safe here in assuming that
  the name can be referrenced as an id.
*/
export function AutoCompleteTagsCreatable(
  props: UiSelectTypeaheadCreatableProps<Tag, true> & {
    onCreate?: (tag: Tag) => void
  }
) {
  const query = useGraphqlQuery(SearchTagsDocument)
  const mutation = useGraphqlMutation(CreateTagDocument)
  useAlertError(query.error)

  const Alert = useAlerts()

  const items = useMemo(() => {
    return orderBy(query.data?.searchTags || [], ['name']).map((tag) => ({ ...tag, id: tag.name }))
  }, [query.data?.searchTags])

  const handleCreate = async (value: string) => {
    try {
      const res = await mutation.mutateAsync({ data: { name: value } })
      Alert.success('Tag created')
      props.onCreate && props.onCreate(res.createTag)
    } catch (err) {
      Alert.warning(err)
    }
  }

  const { onCreateOption, onCreate, ...rest } = props

  return (
    <UiSelectTypeaheadCreatable
      data-testid="AutoCompleteTagsCreatable"
      name="tag"
      options={items}
      getOptionLabel={(t) => t.name}
      getOptionValue={(t) => t.id}
      isLoading={query.isLoading}
      placeholder="Search Tags"
      onCreateOption={handleCreate}
      getNewOptionData={(id, name) => ({ id, name } as Tag)}
      isMulti
      {...rest}
    />
  )
}
