import { joinStrings } from '@postal-io/postal-ui'
import type { Contact, SearchableContact, User } from '../api'

export const getUserString = (user?: User) => {
  if (!user) return ''
  return joinStrings([user.firstName, user.lastName, `(${user.userName || user.emailAddress})`])
}

export const getContactString = (contact?: Contact | SearchableContact) => {
  if (!contact) return ''
  return joinStrings([contact.firstName, contact.lastName], undefined, '')
}

export const getItemName = (item?: any) => {
  if (!item) return ''
  return item?.name || ''
}
