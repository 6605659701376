import type { BoxProps } from '@chakra-ui/react'
import { Avatar, Box, Flex, Grid, useClipboard } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import {
  UiDangerous,
  useAlertError,
  useAlerts,
  ZCard,
  ZCardBody,
  ZCardButton,
  ZCardHeader,
  ZHeading,
  ZLink,
  ZText,
} from '@postal-io/postal-ui'
import type { User, UserAccount } from 'api'
import { GetUserDocument, MeDocument, Role, Status } from 'api'
import { useAcl } from 'hooks'
import React, { useEffect, useMemo } from 'react'
import { MdOutlineBlock, MdOutlineCheckCircleOutline, MdOutlineEdit } from 'react-icons/md'
import { useSession } from '../../hooks/useSession'
import { UserProfileEdit } from '../Profile/UserProfileEdit'
import { UserABMToggle } from './UserABMToggle'
import { UserEdit } from './UserEdit'

interface RowProps extends BoxProps {
  label?: string
}
const Row: React.FC<RowProps> = ({ label, children, ...rest }) => {
  return (
    <Box
      mt={8}
      {...rest}
    >
      <ZText
        size="sm"
        color="atomicGray.500"
        whiteSpace="nowrap"
      >
        {label}
      </ZText>
      <ZText fontSize="14px">{children || '-'}</ZText>
    </Box>
  )
}

interface UserProfileProps extends UiCardProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  userId: string
}

export const UserProfile: React.FC<UserProfileProps> = ({ userId, isOpen, onOpen, onClose, ...rest }) => {
  const Alert = useAlerts()
  const { hasRole, hasSubscription } = useAcl()
  const { session } = useSession()
  const { onCopy, value: url, setValue: setUrl } = useClipboard('')

  useEffect(() => {
    if (!url || !onCopy) return
    onCopy()
    Alert.success('Meeting link copied')
    setUrl('')
  }, [url, onCopy, Alert, setUrl])

  const isMe = userId === session.userId
  const canUpdateABM = hasRole(Role.Admin) && hasSubscription('POSTAL_ABM_ACCESS')

  const meQuery = useGraphqlQuery(MeDocument)
  useAlertError(meQuery.error)

  const getUserQuery = useGraphqlQuery(GetUserDocument, { id: userId as string })
  useAlertError(getUserQuery.error)

  const isLoading = meQuery.isLoading || getUserQuery.isLoading

  const user = useMemo(() => {
    return ((isMe ? meQuery.data?.me : getUserQuery.data?.getUser) || {}) as User & UserAccount
  }, [getUserQuery.data?.getUser, isMe, meQuery.data?.me])

  return (
    <>
      <ZCard
        isLoading={isLoading}
        variant="form"
        {...rest}
      >
        <ZCardHeader
          display="flex"
          alignItems="flex-start"
          p={8}
          pb={0}
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <Avatar
              mr={4}
              size="lg"
              borderWidth="1px"
              borderColor="atomicGray.300"
              src={user.profilePicture || ''}
            />
            <ZHeading size="h6">
              {user.firstName} {user.lastName}
            </ZHeading>
          </Box>
          <ZCardButton
            aria-label="edit button"
            icon={<MdOutlineEdit size="28px" />}
            color="atomicBlue.400"
            onClick={onOpen}
          />
        </ZCardHeader>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          p={8}
        >
          <Grid
            templateColumns="1fr 1fr"
            width="100%"
          >
            <Row label="Email">{user.emailAddress}</Row>
            {!isMe && (
              <Row label="Status">
                <Flex
                  alignItems="center"
                  gap={1}
                >
                  {user?.status === Status.Active ? (
                    <Box color="atomicBlue.400">
                      <MdOutlineCheckCircleOutline
                        size="20px"
                        color="inherit"
                        style={{
                          transform: 'translateY(-1px)',
                        }}
                      />
                    </Box>
                  ) : (
                    <Box color="atomicGray.500">
                      <MdOutlineBlock
                        size="20px"
                        color="inherit"
                        style={{
                          transform: 'translateY(-1px)',
                        }}
                      />
                    </Box>
                  )}
                  <ZText>{user?.status === Status.Active ? 'Enabled' : 'Disabled'}</ZText>
                </Flex>
              </Row>
            )}
            {!!user.title && <Row label="Title">{user.title}</Row>}
            {!!user.phoneNumber && <Row label="Phone">{user.phoneNumber}</Row>}
            {!!user.meetingLink && (
              <Row label="Meeting Link">
                <ZLink
                  title={user.meetingLink}
                  onClick={() => setUrl(user?.meetingLink ?? '')}
                >
                  Share URL
                </ZLink>
              </Row>
            )}
          </Grid>
          {isMe && !!user?.emailSignatureHtml && (
            <ZCard
              variant="form"
              mt={8}
              width="full"
            >
              <ZCardBody
                p={4}
                gridColumn="span 2"
              >
                <UiDangerous
                  html={user?.emailSignatureHtml || ''}
                  width="100%"
                  mt={{ base: 8, xl: 20 }}
                />
              </ZCardBody>
            </ZCard>
          )}
          {canUpdateABM && <UserABMToggle userId={userId} />}
        </Flex>
      </ZCard>
      {isMe ? (
        <UserProfileEdit
          user={user}
          isOpen={isOpen}
          onClose={onClose}
        />
      ) : (
        <UserEdit
          user={user}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  )
}
