import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Fade,
  Flex,
  Grid,
  Image,
  Link,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiDateTime,
  UiEasyDataTable,
  useAlertError,
  useAlerts,
  ZCard,
  ZCardBody,
  ZCardDivider,
  ZCardHeader,
  ZHeading,
  ZText,
} from '@postal-io/postal-ui'
import type { Campaign, FulfillmentHistory } from 'api'
import {
  BulkRetryFailedAbmCampaignOrdersDocument,
  CampaignType,
  CancelOrderDocument,
  FulfillmentStatus,
  GetMagicLinkDocument,
  Role,
  SearchContactsV2Document,
  SearchPostalFulfillmentsDocument,
} from 'api'
import { FAILED_LIST, SUCCESS_LIST } from 'components/Campaign/CampaignData'
import { useCampaignHelper } from 'components/Campaign/campaignHelper'
import { CenteredBox } from 'components/Common'
import { NavbarActionMenu, NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { useAcl, useAssets, useBackgroundQueue, useMe, useNavigateRetryCampaign, useNavigateRetryOrder } from 'hooks'
import React, { useMemo, useRef } from 'react'
import {
  MdChevronRight,
  MdLink,
  MdLoop,
  MdOutlineCancel,
  MdOutlineDoubleArrow,
  MdOutlineEdit,
  MdOutlineFactCheck,
  MdOutlineLibraryAddCheck,
  MdOutlineStyle,
  MdRefresh,
  MdSend,
  MdSwapHoriz,
} from 'react-icons/md'
import { useMatch, useNavigate, useParams } from 'react-router'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import type { Address, MagicLinkStatus } from '../../api/index'
import {
  CampaignStatus,
  GetApprovedPostalDocument,
  GetCampaignDocument,
  GetPlaybookDefinitionDocument,
  GetPostalFulfillmentDocument,
} from '../../api/index'
import { usePostalImages } from '../../hooks/usePostalImages'
import { statusMapper } from '../../lib/statusMapper'
import {
  ZActionList,
  ZCardStripe,
  ZCardStripeHeading,
  ZDateTimeHoverDisplay,
  ZFormLabel,
  ZStatusTag,
} from '../Common/ZComponents'
import { CampaignCloneType } from './CampaignRetry'
import { getSendTypeStyles, OrdersTypes } from './data'
import { GroupOrderEdit } from './GroupOrderEdit'
import type { OrderAttributesProps } from './OrderAttributes'
import { OrderAttributes } from './OrderAttributes'
import { OrderRecipientBlock } from './OrderRecipientBlock'
import { OrderRecipientsBlock } from './OrderRecipientsBlock'
import { OrdersContent } from './OrdersCategory'
import { TYPED_ORDERS_COLUMNS } from './ordersColumns'

const CANCEL_STATUS = [FulfillmentStatus.PostalDeliveryEmail, FulfillmentStatus.PostalAddressRefresh]

export const Order: React.FC = () => {
  const { orderId, campaignId, magicLinkId }: any = useParams()
  const Alert = useAlerts()
  const navigate = useNavigate()
  const { hasRole, hasFeature } = useAcl()
  const { me } = useMe()
  const { queue, invalidate } = useBackgroundQueue()
  const cancelRef = useRef(null)

  const isMagicLinkApproval = !!useMatch('/orders/links/approvals/*')
  const isAnyMagicLink = !!useMatch('/orders/links/*')
  const isMagicLink = !isMagicLinkApproval && isAnyMagicLink

  const isGiftEmail = !!useMatch('/orders/emails/*')
  const isDirect = !!useMatch('/orders/direct/*')
  const isBulkSend = !!useMatch('/orders/bulk/*')
  const isSubscription = !!useMatch('/orders/subscriptions/*')
  const isTrigger = !!useMatch('/orders/triggers/*')
  // a group order is the only item that would have a campaignId in the params
  const isGroupOrder = !!campaignId

  const isAutomation = isMagicLinkApproval || isMagicLink || isSubscription || isTrigger

  const { assetUrlSrc } = useAssets()
  const { state }: any = useLocation()
  const isSuccess = useMemo(() => state?.isSuccess, [state])

  // if this is a postal fulfillment
  const getPostalFulfillmentQuery = useGraphqlQuery(
    GetPostalFulfillmentDocument,
    {
      id: orderId,
    },
    { enabled: !!orderId }
  )
  const postalFulfillment = useMemo(
    () => getPostalFulfillmentQuery?.data?.getPostalFulfillment,
    [getPostalFulfillmentQuery?.data?.getPostalFulfillment]
  )
  const {
    approvedPostalId: fulfillmentPostalId,
    previewImage,
    thankYouMessage,
    shipToName,
    shipTo,
    triggerId,
    playbookDefinitionId,
  } = postalFulfillment ?? {}

  // if this is a campaign
  const getCampaignQuery = useGraphqlQuery(GetCampaignDocument, { id: campaignId }, { enabled: isGroupOrder })
  const campaign = useMemo(() => getCampaignQuery?.data?.getCampaign, [getCampaignQuery?.data?.getCampaign])
  const { approvedPostalId: campaignPostalId, name: campaignName } = campaign ?? {}

  // if this is a magicLink
  const getMagicLinkQuery = useGraphqlQuery(GetMagicLinkDocument, { id: magicLinkId }, { enabled: !!magicLinkId })
  const magicLink = useMemo(() => getMagicLinkQuery?.data?.getMagicLink, [getMagicLinkQuery?.data?.getMagicLink])
  const { approvedPostalId: magicLinkApprovedPostalId } = magicLink ?? {}

  // get the postal id from either one
  const approvedPostalId = fulfillmentPostalId || campaignPostalId || magicLinkApprovedPostalId

  // get the approved postal using that id
  const getApprovedPostalQuery = useGraphqlQuery(
    GetApprovedPostalDocument,
    { id: approvedPostalId as string },
    { enabled: !!approvedPostalId }
  )
  const approvedPostal = useMemo(
    () => getApprovedPostalQuery?.data?.getApprovedPostal,
    [getApprovedPostalQuery?.data?.getApprovedPostal]
  )
  const { name } = approvedPostal ?? {}

  const postalImages = usePostalImages(approvedPostal)

  // if this is a subscription
  const getPlaybookDefinitionQuery = useGraphqlQuery(
    GetPlaybookDefinitionDocument,
    { id: playbookDefinitionId as string },
    { enabled: !!playbookDefinitionId }
  )
  const playbookDefinition = useMemo(
    () => getPlaybookDefinitionQuery?.data?.getPlaybookDefinition,
    [getPlaybookDefinitionQuery?.data?.getPlaybookDefinition]
  )

  // use preview image from fulfillment, or first image from postal (for campaigns)
  const srcProps = {
    ...assetUrlSrc(previewImage || postalImages?.[0]?.url, { h: 100, w: 'auto' }),
    h: 100,
  }

  const successMessage = useMemo(() => {
    if (!isSuccess) return ''
    if (isMagicLink) return 'Success! Your MagicLink has been created.'
    else if (isGroupOrder && (isDirect || !campaign?.deliveryEmail)) return 'Success! Your items are on their way!'
    else if (isDirect) return 'Success! Your item is on the way!'
    else if (isGiftEmail && isGroupOrder) return 'Success! Your emails are on their way!'
    else if (isBulkSend) return 'Success! Your items have been ordered!'
    else return 'Success! Your email is on the way!'
  }, [isSuccess, isMagicLink, isDirect, isGroupOrder, isGiftEmail, isBulkSend, campaign?.deliveryEmail])

  const status = useMemo(() => {
    return campaign?.status ?? postalFulfillment?.status ?? magicLink?.status
  }, [campaign?.status, magicLink?.status, postalFulfillment?.status])

  const cancelPostal = useDisclosure()
  const groupOrderEdit = useDisclosure()
  const { hasPermission } = useAcl()
  const { canEdit } = useCampaignHelper(campaign)

  const canSend = hasPermission('postals.send')
  const canCreateCampaigns = hasPermission('campaigns.create')
  const isABMCampaign = campaign?.campaignType === CampaignType.Abm
  const isABMGroupSend = campaign?.campaignType === CampaignType.AbmClassic

  // const canApproveLinks = hasFeature('manuallyApproveLinks')
  // const canSpendAs = hasFeature('budgetDropDown')

  const canRetry = useMemo(() => {
    return canSend && status !== FulfillmentStatus.ServerErrorBackoff && !isGroupOrder
  }, [canSend, isGroupOrder, status])
  const canCancel = useMemo(() => {
    return canSend && CANCEL_STATUS.includes(status as FulfillmentStatus)
  }, [canSend, status])

  const cancelOrder = useGraphqlMutation(CancelOrderDocument)
  const handleCancelItem = async () => {
    cancelPostal.onClose()
    await cancelOrder.mutateAsync({ id: orderId })
    //Closes modal
    // onComplete && onComplete()
  }

  // what kind of order was this during the send flow? or use null for automations
  const sendType = useMemo(() => {
    return isABMCampaign
      ? OrdersTypes.ABM
      : isGroupOrder
      ? campaign?.deliveryEmail
        ? OrdersTypes.Emails
        : OrdersTypes.Direct
      : isMagicLink
      ? OrdersTypes.Links
      : isDirect
      ? OrdersTypes.Direct
      : isBulkSend
      ? OrdersTypes.BulkSends
      : isGiftEmail
      ? OrdersTypes.Emails
      : isSubscription
      ? OrdersTypes.Subscriptions
      : isTrigger
      ? OrdersTypes.Triggers
      : null
  }, [
    isABMCampaign,
    isGroupOrder,
    campaign?.deliveryEmail,
    isMagicLink,
    isDirect,
    isBulkSend,
    isGiftEmail,
    isSubscription,
    isTrigger,
  ])

  const { color, icon: Icon, heading } = getSendTypeStyles(sendType)

  const canView = postalFulfillment?.id === me?.id || hasRole(Role.Manager)

  const { hasFailure, hasDeliveryExpired, hasSuccess, isCampaignRetry, isABMCampaignRetry } = useCampaignRetryOptions({
    campaignId,
  })

  const retryCampaignLink = useNavigateRetryCampaign()
  const retryOrderLink = useNavigateRetryOrder()

  const handleCampaignRetry = (type: CampaignCloneType) => {
    navigate(retryCampaignLink({ campaignId, cloneType: type, returnTo: 'Group Order' }))
  }

  const handleOrderRetry = () => {
    navigate(retryOrderLink({ orderId, returnTo: 'Order' }))
  }

  const retryFailedABMOrders = useGraphqlMutation(BulkRetryFailedAbmCampaignOrdersDocument, {
    onSuccess: (data) => {
      queue(data.bulkRetryFailedABMCampaignOrders)
      invalidate(['searchPostalFulfillments'])
    },
    // alert for failed background task
    onError: (error) => {
      Alert.error(error)
    },
  })

  const handleRetryFailedABMOrders = async () => {
    try {
      await retryFailedABMOrders.mutateAsync({ campaignId: campaignId })
      Alert.success('Re-sending failed orders')
    } catch (err) {
      Alert.error(err)
    }
  }

  const isAnythingLoading =
    getPostalFulfillmentQuery.isLoading || getCampaignQuery.isLoading || getApprovedPostalQuery.isLoading

  const actions = [
    {
      title: 'Re-send failed orders',
      onClick: handleRetryFailedABMOrders,
      viewBox: '3 3 18 18',
      icon: MdOutlineDoubleArrow,
      isHidden: !canCreateCampaigns || !isABMCampaignRetry || !hasFeature('abmResendFailedOrders'),
    },
    {
      title: 'Re-send failed orders',
      onClick: () => handleCampaignRetry(CampaignCloneType.Failed),
      viewBox: '3 3 18 18',
      icon: MdOutlineDoubleArrow,
      isHidden: !canCreateCampaigns || !isCampaignRetry || !hasFailure || isABMCampaign,
    },
    {
      title: 'Re-send expired gifts',
      onClick: () => handleCampaignRetry(CampaignCloneType.DeliveryExpired),
      viewBox: '3 3 18 18',
      icon: MdLoop,
      isHidden: !canCreateCampaigns || !isCampaignRetry || !hasDeliveryExpired || isABMCampaign,
    },
    {
      title: 'Re-send successful orders',
      onClick: () => handleCampaignRetry(CampaignCloneType.Succeeded),
      viewBox: '3 3 18 18',
      icon: MdOutlineLibraryAddCheck,
      isHidden: !canCreateCampaigns || !isCampaignRetry || !hasSuccess || isABMCampaign,
    },
    {
      title: 'Re-send Group Order',
      onClick: () => handleCampaignRetry(CampaignCloneType.All),
      viewBox: '3 3 18 18',
      icon: MdOutlineFactCheck,
      isHidden: !canCreateCampaigns || canEdit || !isGroupOrder || isABMCampaign,
    },
    {
      title: 'Edit Group Order',
      onClick: groupOrderEdit.onOpen,
      icon: MdOutlineEdit,
      isHidden: !canEdit || isABMCampaign,
    },
    {
      title: 'Order Again',
      onClick: () => handleOrderRetry(),
      icon: MdSend,
      isHidden: !canRetry || status === FulfillmentStatus.ProcessingError || isABMCampaign,
    },
    {
      title: 'Retry Order',
      onClick: () => handleOrderRetry(),
      icon: MdRefresh,
      viewBox: '3 3 18 18',
      isHidden: !canRetry || status !== FulfillmentStatus.ProcessingError || isABMCampaign,
    },
    {
      title: 'View MagicLink',
      onClick: () => navigate(`/links/${postalFulfillment?.magicLinkId}`),
      icon: MdLink,
      viewBox: '3 3 18 18',
      isHidden: !isMagicLink || !canView,
    },
    {
      title: 'View Trigger',
      onClick: () => navigate(`/triggers/${postalFulfillment?.triggerId}`),
      icon: MdSwapHoriz,
      viewBox: '3 3 18 18',
      isHidden: !isTrigger || !canView,
    },
    {
      title: `View Subscription`,
      onClick: () => navigate(`/subscriptions/${postalFulfillment?.playbookDefinitionId}`),
      icon: MdOutlineStyle,
      viewBox: '3 3 18 18',
      isHidden: !isSubscription || !canView,
    },
    {
      title: 'Cancel Order',
      onClick: cancelPostal.onOpen,
      icon: MdOutlineCancel,
      isHidden: !canCancel,
    },
  ]

  const { header, backTo, backToLabel } = useMemo(
    (): { header: string; backTo: string; backToLabel: string } =>
      isABMCampaign
        ? { header: 'ABM Campaign', backTo: '/orders/abm', backToLabel: 'Back to ABM' }
        : isABMGroupSend
        ? { header: 'ABM Group Email', backTo: '/orders/abm', backToLabel: 'Back to ABM' }
        : isGroupOrder && campaign?.deliveryEmail
        ? { header: 'Group Email Order', backTo: '/orders/emails', backToLabel: 'Back to Emails' }
        : isGroupOrder && !campaign?.deliveryEmail
        ? { header: 'Group Direct Order', backTo: '/orders/direct', backToLabel: 'Back to Directs' }
        : isMagicLinkApproval
        ? { header: 'MagicLink Approval', backTo: '/links/approval', backToLabel: 'Back to MagicLink Approvals' }
        : isMagicLink
        ? { header: 'MagicLink Redemption', backTo: '/orders/links', backToLabel: 'Back to MagicLinks' }
        : isDirect
        ? { header: 'Direct Order', backTo: '/orders/direct', backToLabel: 'Back to Directs' }
        : isGiftEmail
        ? { header: 'Personalized Email Order', backTo: '/orders/emails', backToLabel: 'Back to Emails' }
        : isSubscription
        ? { header: 'Subscription Order', backTo: '/orders/subscriptions', backToLabel: 'Back to Subscriptions' }
        : { header: 'Order', backTo: '/orders', backToLabel: 'Back to Orders' },
    [
      campaign?.deliveryEmail,
      isABMCampaign,
      isABMGroupSend,
      isDirect,
      isGiftEmail,
      isGroupOrder,
      isMagicLink,
      isMagicLinkApproval,
      isSubscription,
    ]
  )

  const maxWidth = actions.length ? '1080px' : '900px'

  return (
    <>
      <SecondaryNavbar
        px={8}
        farRight={<NavbarActionMenu actionItems={[]} />}
        left={
          <NavbarBackButton
            onClick={() => navigate(backTo)}
            label={backToLabel}
          />
        }
        header={header}
        onExit={() => (isSuccess ? navigate('/items') : navigate(-1))}
        onExitLabel={isSuccess ? 'Exit to Marketplace' : 'Exit'}
      />
      <CenteredBox
        maxW={maxWidth}
        pt={0}
        isLoaded={true}
      >
        <Flex
          align="center"
          h={isSuccess ? 24 : 16}
        >
          <Fade in={!isAnythingLoading && isSuccess}>
            <ZHeading
              size="h6"
              color={color}
            >
              {successMessage}
            </ZHeading>
          </Fade>
        </Flex>
        <Flex
          gap={8}
          w="100%"
        >
          <Stack
            spacing={5}
            flexGrow={1}
            maxW="900px"
          >
            <ZCard
              variant="stripe"
              colorScheme="atomicGray"
              border="1px solid"
              borderColor="atomicGray.200"
              isFetching={isAnythingLoading}
              // minH of magicLink order - helps loader
              minH="319px"
              flexGrow={1}
            >
              <ZCardStripe
                color={color}
                justifyContent="left"
              >
                <ZCardStripeHeading>
                  <>
                    <Icon />
                    <Box ml={2}>{heading}</Box>
                  </>
                </ZCardStripeHeading>
              </ZCardStripe>

              <ZCardBody mt="40px">
                <HeaderBlock
                  sendType={sendType}
                  scheduleDate={campaign?.scheduleDate}
                  automationId={magicLinkId || triggerId || playbookDefinitionId}
                  srcProps={srcProps}
                  isSuccess={isSuccess}
                  status={status}
                  name={campaignName || name}
                  postalFulfillment={postalFulfillment}
                  approvedPostal={approvedPostal}
                  magicLink={magicLink}
                  campaign={campaign}
                  playbookDefinition={playbookDefinition}
                />
              </ZCardBody>

              <ThankYouMessageBlock thankYouMessage={thankYouMessage} />

              <RecipientsBlock
                campaignId={campaignId}
                campaignStatus={campaign?.status}
                scheduleDate={campaign?.scheduleDate}
                shipTo={shipTo}
                shipToName={shipToName}
                isAutomation={isAutomation}
                isLoading={isAnythingLoading}
              />
              {orderId && <StatusHistoryBlock orderId={orderId} />}
            </ZCard>
          </Stack>
          <Fade in={!isAnythingLoading}>
            <ZActionList
              actions={actions}
              height="unset"
            />
          </Fade>
        </Flex>
      </CenteredBox>
      {groupOrderEdit.isOpen && (
        <GroupOrderEdit
          campaignId={campaignId}
          isDirect={isDirect}
          {...groupOrderEdit}
        />
      )}
      <AlertDialog
        size="md"
        isOpen={cancelPostal.isOpen}
        onClose={cancelPostal.onClose}
        leastDestructiveRef={cancelRef}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Cancel Order</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>Are you sure you want to cancel this order?</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              onClick={handleCancelItem}
              minW={32}
            >
              Confirm
            </Button>
            <Button
              ref={cancelRef}
              variant="ghost"
              colorScheme="atomicGray"
              onClick={cancelPostal.onClose}
              minW={32}
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

/**
 * Header
 */

interface HeaderBlockProps extends OrderAttributesProps {
  sendType?: OrdersTypes | null
  scheduleDate?: Date | null
  automationId?: string
  srcProps: any
  isSuccess?: boolean
  status?: FulfillmentStatus | MagicLinkStatus | CampaignStatus
  name?: string
}

const HeaderBlock: React.FC<HeaderBlockProps> = ({
  sendType,
  scheduleDate,
  automationId,
  srcProps,
  isSuccess,
  status,
  name,
  ...orderAttributesProps
}) => {
  const { text, ...tagProps } = statusMapper(status)

  return (
    <Grid
      gap={5}
      gridTemplateColumns="100px 1fr 120px"
    >
      <Image
        h="100px"
        w="100px"
        objectFit="cover"
        borderRadius={3}
        border="1px solid"
        borderColor="atomicGray.200"
        {...srcProps}
      />

      <Stack
        justifyContent="center"
        flexGrow={1}
      >
        <ZHeading
          as="h2"
          size="h5"
          color="atomicGray.800"
        >
          {name}
        </ZHeading>
        <OrderAttributes
          mt={5}
          {...orderAttributesProps}
        />
      </Stack>

      <AutomationLink
        sendType={sendType}
        automationId={automationId}
      />
      {!status ? null : (
        <ZStatusTag
          color="atomicGray.900"
          label={text}
          minW="max-content"
          {...tagProps}
        />
      )}
    </Grid>
  )
}

/**
 * Automation Link
 */

interface AutomationLinkProps {
  sendType?: OrdersTypes | null
  automationId?: string
}

const AutomationLink: React.FC<AutomationLinkProps> = ({ sendType, automationId }) => {
  const { automationPath, color, item } = getSendTypeStyles(sendType)

  return !!automationId ? (
    <Link
      as={RouterLink}
      to={`${automationPath}/${automationId}`}
      color={color}
      w="max-content"
      display="flex"
      alignItems="center"
    >
      View {item}
      <MdChevronRight size={24} />
    </Link>
  ) : null
}

/**
 * Thanks bro
 */

interface ThankYouMessageBlockProps {
  thankYouMessage?: string | null
}

const ThankYouMessageBlock: React.FC<ThankYouMessageBlockProps> = ({ thankYouMessage }) => {
  if (!thankYouMessage) return null

  return (
    <>
      <ZCardDivider />
      <ZCardBody>
        <ZHeading
          size="h6"
          mb={6}
        >
          Message
        </ZHeading>
        <ZFormLabel
          color="atomicGray.500"
          variant="bold"
        >
          Thank you message
        </ZFormLabel>
        <ZText>{thankYouMessage}</ZText>
      </ZCardBody>
    </>
  )
}

interface RecipientsBlockProps {
  campaignId?: string
  campaignStatus?: CampaignStatus
  scheduleDate?: Date
  shipTo?: Address | null
  shipToName?: string | null
  isAutomation?: boolean
  isLoading?: boolean
}

const RecipientsBlock: React.FC<RecipientsBlockProps> = ({
  campaignId,
  campaignStatus,
  scheduleDate,
  shipTo,
  shipToName,
  isAutomation,
  isLoading,
}) => {
  const isGroupOrder = !!campaignId

  // don't show recipients for automations
  if (isAutomation) return null

  return (
    <>
      <ZCardDivider />
      <ZCardBody>
        {isGroupOrder ? (
          campaignStatus === CampaignStatus.Scheduled ? (
            <OrderRecipientsBlock
              campaignId={campaignId}
              scheduleDate={scheduleDate}
            />
          ) : (
            <>
              <ZCardHeader
                display="flex"
                alignItems="center"
                fontWeight="normal"
              >
                Recipients
                {scheduleDate && (
                  <ZText
                    color="atomicGray.500"
                    fontWeight="bold"
                    float="right"
                  >
                    {campaignStatus === CampaignStatus.Complete ? 'Sent ' : 'Scheduled for '}
                    <UiDateTime date={scheduleDate} />
                  </ZText>
                )}
              </ZCardHeader>
              <Box mx={-6}>
                <OrdersContent
                  columns={TYPED_ORDERS_COLUMNS}
                  campaignId={campaignId}
                />
              </Box>
            </>
          )
        ) : (
          <OrderRecipientBlock
            shipTo={shipTo}
            shipToName={shipToName}
            isLoading={isLoading}
          />
        )}
      </ZCardBody>
    </>
  )
}

/**
 * Status History
 */

const HISTORY_COLUMNS = [
  {
    label: 'Date',
    key: 'created',
    render: (item: FulfillmentHistory & { newDay: boolean }) => {
      return (
        <ZDateTimeHoverDisplay
          date={item.created}
          defaultDisplay={item.newDay ? 'date' : 'time'}
        />
      )
    },
    headerProps: {
      display: 'none',
    },
    rowProps: {
      width: '25%',
      border: 'none',
    },
  },
  {
    label: 'Status',
    key: 'status',
    render: (item: FulfillmentHistory) => {
      const { text, color, backgroundColor } = statusMapper(item.status)
      return (
        <ZStatusTag
          label={text}
          color={color}
          backgroundColor={backgroundColor}
        />
      )
    },
    headerProps: {
      display: 'none',
    },
    rowProps: {
      width: '25%',
      border: 'none',
    },
  },
  {
    label: 'Notes',
    key: 'notes',
    render: (item: FulfillmentHistory) => {
      return (
        <ZText
          fontSize="body-sm"
          color="atomicGray.600"
        >
          {item.notes}
        </ZText>
      )
    },
    headerProps: {
      display: 'none',
    },
    rowProps: {
      width: '75%',
      border: 'none',
    },
  },
]

const StatusHistoryBlock: React.FC<{ orderId: string }> = ({ orderId }) => {
  const fulfillment = useGraphqlQuery(GetPostalFulfillmentDocument, { id: orderId })
  const history = useMemo(() => {
    const history = fulfillment.data?.getPostalFulfillment.history
    return history?.map((item, index) => ({
      ...item,
      newDay: new Date(item.created).toDateString() !== new Date(history[index - 1]?.created).toDateString(),
    }))
  }, [fulfillment.data?.getPostalFulfillment.history])

  // don't show history if no history
  if (!orderId || !history) return null

  return (
    <Box>
      <ZCardDivider mb={5} />
      <ZCardHeader fontWeight="normal">History</ZCardHeader>
      <UiEasyDataTable
        boxShadow="none"
        border="none"
        mx={-8}
        sx={{
          '& thead th ': { display: 'none' },
          '& tbody td': { borderBottomColor: 'transparent', px: 8 },
          '& tbody tr:last-of-type td': { pb: 5 },
        }}
        rows={history}
        rowKey="created"
        columns={HISTORY_COLUMNS}
      />
    </Box>
  )
}

export function useCampaignRetryOptions({ campaignId }: { campaignId: string }) {
  const searchSuccess = useGraphqlQuery(
    SearchContactsV2Document,
    {
      filter: { campaignStatus: { in: SUCCESS_LIST.map((s) => [campaignId, s].join('_')) } },
      limit: 1,
    },
    { enabled: !!campaignId }
  )
  const hasSuccess = useMemo(
    () => !!searchSuccess.data?.searchContactsV2?.searchableContacts?.length,
    [searchSuccess.data?.searchContactsV2?.searchableContacts?.length]
  )

  const searchFailures = useGraphqlQuery(
    SearchContactsV2Document,
    {
      filter: { campaignStatus: { in: FAILED_LIST.map((s) => [campaignId, s].join('_')) } },
      limit: 1,
    },
    { enabled: !!campaignId }
  )
  const hasFailure = useMemo(
    () => !!searchFailures.data?.searchContactsV2?.searchableContacts?.length,
    [searchFailures.data?.searchContactsV2?.searchableContacts?.length]
  )

  const searchDeliveryExpired = useGraphqlQuery(
    SearchContactsV2Document,
    {
      filter: { campaignStatus: { eq: [campaignId, FulfillmentStatus.PostalDeliveryExpired].join('_') } },
      limit: 1,
    },
    { enabled: !!campaignId }
  )
  const hasDeliveryExpired = useMemo(
    () => !!searchDeliveryExpired.data?.searchContactsV2?.searchableContacts?.length,
    [searchDeliveryExpired.data?.searchContactsV2?.searchableContacts?.length]
  )

  const getCampaign = useGraphqlQuery(GetCampaignDocument, { id: campaignId }, { enabled: !!campaignId })
  const campaign = (getCampaign?.data?.getCampaign as Campaign) || {}

  const isCampaignRetry =
    campaign.status === CampaignStatus.Complete ||
    campaign.status === CampaignStatus.Error ||
    campaign.status === CampaignStatus.Cancelled

  const ABMCampaignPostalFulfillmentsQuery = useGraphqlQuery(
    SearchPostalFulfillmentsDocument,
    { filter: { campaignId: { eq: campaignId } } },
    { enabled: !!campaignId && campaign.campaignType === CampaignType.Abm }
  )
  useAlertError(ABMCampaignPostalFulfillmentsQuery.error)

  const isABMCampaignRetry =
    campaign.campaignType === CampaignType.Abm &&
    (ABMCampaignPostalFulfillmentsQuery?.data?.searchPostalFulfillments?.filter(
      (p) => p.status === FulfillmentStatus.ProcessingError
    )?.length ?? 0) > 0

  return {
    hasSuccess,
    hasFailure,
    hasDeliveryExpired,
    isCampaignRetry,
    isABMCampaignRetry,
  }
}
