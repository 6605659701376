import { Grid, Icon, Link, SimpleGrid, Text } from '@chakra-ui/react'
import {
  UiButton,
  UiCard,
  UiCardHeader,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerFooterButtons,
  UiDrawerHeader,
  UiDrawerOverlay,
  UiIconIllustrator,
  UiIconPhotoshop,
  UiSubNavbar,
} from '@postal-io/postal-ui'
import React from 'react'

enum MailTypes {
  POSTCARD = 'POSTCARD',
  BROCHURE = 'BROCHURE',
  NOTECARD = 'NOTECARD',
}

enum FileTypes {
  ILLUSTRATOR = 'ILLUSTRATOR',
  PHOTOSHOP = 'PHOTOSHOP',
}

interface DownloadFile {
  fileType: FileTypes
  mailType: MailTypes
  url: string
  title: string
}

const ImageMap = {
  [FileTypes.ILLUSTRATOR]: UiIconIllustrator,
  [FileTypes.PHOTOSHOP]: UiIconPhotoshop,
}

const DOWNLOAD_FILES: DownloadFile[] = [
  {
    fileType: FileTypes.ILLUSTRATOR,
    mailType: MailTypes.POSTCARD,
    url: 'https://storage.cloud.google.com/postal-design-templates/Illustrator/4x6-postcard-postaltemplate.ai',
    title: 'Combined',
  },
  {
    fileType: FileTypes.ILLUSTRATOR,
    mailType: MailTypes.BROCHURE,
    url: 'https://storage.cloud.google.com/postal-design-templates/Illustrator/7x15-brochure-postaltemplate.ai',
    title: 'Combined',
  },
  {
    fileType: FileTypes.ILLUSTRATOR,
    mailType: MailTypes.NOTECARD,
    url: 'https://storage.cloud.google.com/postal-design-templates/Illustrator/8x6-notecard-postaltemplate.ai',
    title: 'Combined',
  },
  {
    fileType: FileTypes.PHOTOSHOP,
    mailType: MailTypes.POSTCARD,
    url: 'https://storage.cloud.google.com/postal-design-templates/Photoshop/4x6postcard-back.psd',
    title: 'Back',
  },
  {
    fileType: FileTypes.PHOTOSHOP,
    mailType: MailTypes.POSTCARD,
    url: 'https://storage.cloud.google.com/postal-design-templates/Photoshop/4x6postcard-front.psd',
    title: 'Front',
  },
  {
    fileType: FileTypes.PHOTOSHOP,
    mailType: MailTypes.BROCHURE,
    url: 'https://storage.cloud.google.com/postal-design-templates/Photoshop/7x15-brochure-inside.psd',
    title: 'Inside',
  },
  {
    fileType: FileTypes.PHOTOSHOP,
    mailType: MailTypes.BROCHURE,
    url: 'https://storage.cloud.google.com/postal-design-templates/Photoshop/7x15-brochure-outside.psd',
    title: 'Outside',
  },
  {
    fileType: FileTypes.PHOTOSHOP,
    mailType: MailTypes.NOTECARD,
    url: 'https://storage.cloud.google.com/postal-design-templates/Photoshop/8x6-notecard-inside.psd',
    title: 'Inside',
  },
  {
    fileType: FileTypes.PHOTOSHOP,
    mailType: MailTypes.NOTECARD,
    url: 'https://storage.cloud.google.com/postal-design-templates/Photoshop/8x6-notecard-outside.psd',
    title: 'Outside',
  },
]

interface DesignTemplatesProps {
  isOpen: boolean
  onClose: () => void
}

interface DownloadCardProps {
  downloadFile: DownloadFile
}
const DownloadCard: React.FC<DownloadCardProps> = ({ downloadFile }) => {
  return (
    <Link
      href={downloadFile.url}
      isExternal
      w="100%"
    >
      <Icon
        as={ImageMap[downloadFile.fileType]}
        h="50px"
        w="50px"
        mx="auto"
        display="block"
      />
      <Text
        fontSize="md"
        fontWeight="semibold"
        textAlign="center"
      >
        {downloadFile.title}
      </Text>
    </Link>
  )
}

export const DesignTemplates: React.FC<DesignTemplatesProps> = ({ isOpen, onClose }) => {
  const notecards = DOWNLOAD_FILES.filter((d) => d.mailType === MailTypes.NOTECARD)
  const postcards = DOWNLOAD_FILES.filter((d) => d.mailType === MailTypes.POSTCARD)
  const brochures = DOWNLOAD_FILES.filter((d) => d.mailType === MailTypes.BROCHURE)

  return (
    <>
      <UiDrawer
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
      >
        <UiDrawerOverlay />
        <UiDrawerContent>
          <UiDrawerCloseButton />
          <UiDrawerHeader>Design Templates</UiDrawerHeader>
          <UiSubNavbar />
          <UiDrawerBody>
            <Grid
              gap={8}
              p={8}
              data-testid="Marketplace_DesignTemplate_Grid"
            >
              <UiCard>
                <UiCardHeader textAlign="center">Notecard</UiCardHeader>
                <SimpleGrid
                  columns={3}
                  spacing={8}
                >
                  {notecards.map((downloadFile, idx) => {
                    return (
                      <DownloadCard
                        key={idx}
                        downloadFile={downloadFile}
                      />
                    )
                  })}
                </SimpleGrid>
              </UiCard>
              <UiCard>
                <UiCardHeader textAlign="center">Postcard</UiCardHeader>
                <SimpleGrid
                  columns={3}
                  spacing={8}
                >
                  {postcards.map((downloadFile, idx) => {
                    return (
                      <DownloadCard
                        key={idx}
                        downloadFile={downloadFile}
                      />
                    )
                  })}
                </SimpleGrid>
              </UiCard>
              <UiCard>
                <UiCardHeader textAlign="center">Brochure</UiCardHeader>
                <SimpleGrid
                  columns={3}
                  spacing={8}
                >
                  {brochures.map((downloadFile, idx) => {
                    return (
                      <DownloadCard
                        key={idx}
                        downloadFile={downloadFile}
                      />
                    )
                  })}
                </SimpleGrid>
              </UiCard>
            </Grid>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiDrawerFooterButtons>
              <UiButton
                colorScheme="gray"
                onClick={onClose}
              >
                Close
              </UiButton>
            </UiDrawerFooterButtons>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
    </>
  )
}
