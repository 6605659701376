import { Flex, Heading, Stack, Text, useDisclosure, Wrap, WrapItem } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { UiConfirm, UiDialog, UiLink, useAlerts } from '@postal-io/postal-ui'
import type { AutomationItem, Automations } from 'api'
import {
  DataObjectType,
  DeleteCampaignDocument,
  DeleteIntegrationTriggerDocument,
  DeleteMagicLinkDocument,
  Status,
  UpdatePlaybookDefinitionDocument,
} from 'api'
import { useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

export interface AutomationInfoProps {
  automations: Automations[]
}

export const AutomationInfo: React.FC<AutomationInfoProps> = ({ automations }) => {
  return (
    <>
      <Heading
        as="h2"
        fontSize="lg"
        mb={8}
        color="gray.600"
      >
        This item is also used by these automations. They will also be deleted:
      </Heading>

      <Stack
        spacing={4}
        data-testid="automationList"
        mb={8}
      >
        <AutomationInfoItem
          type="Playbook"
          automations={automations}
        />
        <AutomationInfoItem
          type="Trigger"
          automations={automations}
        />
        <AutomationInfoItem
          type="MagicLink"
          automations={automations}
        />
        <AutomationInfoItem
          type="Campaign"
          automations={automations}
        />
      </Stack>

      <Heading
        as="h2"
        fontSize="lg"
        color="gray.600"
      >
        Are you sure you want to proceed?
      </Heading>
    </>
  )
}

interface AutomationInfoItemProps {
  type: string
  automations?: Automations[]
}

const AutomationInfoItem: React.FC<AutomationInfoItemProps> = ({ type, automations }) => {
  const relatedAutomations = useMemo(() => {
    switch (type) {
      case 'Playbook':
        return (
          (automations?.filter((i) => i.type === DataObjectType[type])?.find(Boolean)?.items as AutomationItem[]) || []
        )
      case 'Trigger':
      case 'MagicLink':
      case 'Campaign':
        return (
          (automations?.filter((i) => i.type === DataObjectType[type])?.find(Boolean)?.items as AutomationItem[]) || []
        )

      default:
        return []
    }
  }, [automations, type])

  const basePath = useMemo(() => {
    switch (type) {
      case 'Playbook':
        return '/subscriptions'
      case 'Trigger':
        return '/triggers'
      case 'MagicLink':
        return '/links'
      case 'Campaign':
        return '/campaigns'
    }
  }, [type])

  return relatedAutomations.length ? (
    <Flex direction="column">
      <Flex
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          fontSize="lg"
          fontWeight="semibold"
        >
          {type === 'Playbook' ? 'Subscription' : type}
        </Text>
      </Flex>
      <Wrap spacing={4}>
        {relatedAutomations?.map((automation) => {
          return (
            <WrapItem key={automation?.id}>
              <UiLink
                as={Link}
                to={`${basePath}/${automation.id}`}
                isExternal
              >
                {automation?.name}
              </UiLink>
            </WrapItem>
          )
        })}
      </Wrap>
    </Flex>
  ) : null
}

interface AutomationItemComponentProps {
  type: string
  automations?: Automations[]
}

export const AutomationItemComponent: React.FC<AutomationItemComponentProps> = ({ type, automations }) => {
  const deleteTrigger = useGraphqlMutation(DeleteIntegrationTriggerDocument)
  const updatePlaybookDefinition = useGraphqlMutation(UpdatePlaybookDefinitionDocument)
  const deleteMagicLink = useGraphqlMutation(DeleteMagicLinkDocument)
  const deleteCampaign = useGraphqlMutation(DeleteCampaignDocument)

  const [selectedItem, setSelectedItem] = useState<AutomationItem>()

  const itemDisclosure = useDisclosure()
  const itemDeleteDisclosure = useDisclosure()

  const navigate = useNavigate()
  const Alert = useAlerts()

  let itemsList: AutomationItem[] | [] = []

  switch (type) {
    case 'Playbook':
    case 'Trigger':
    case 'MagicLink':
    case 'Campaign':
      itemsList =
        (automations?.filter((i) => i.type === DataObjectType[type])?.find(Boolean)?.items as AutomationItem[]) || []
      break
    default:
      itemsList = []
  }

  const handleNav = (itemId: string) => {
    itemDisclosure.onClose()
    const formattedString = type.toLowerCase() + 's'
    switch (type) {
      case 'MagicLink':
        navigate(`/links/${itemId}`)
        break
      default:
        navigate(`/${formattedString}/${itemId}`)
    }
  }

  const handleCleanUp = () => {
    itemDeleteDisclosure.onClose()
    itemDisclosure.onClose()
  }

  const handleDelete = async (type: string, itemId: string) => {
    switch (type) {
      case 'Playbook':
        try {
          await updatePlaybookDefinition.mutateAsync({
            id: itemId,
            data: { status: Status.Delete },
          })
          Alert.success(`Subscription Removed`)
          handleCleanUp()
        } catch (err) {
          Alert.error(`Error removing $subscription: `, err)
        }
        break
      case 'Trigger':
        try {
          await deleteTrigger.mutateAsync({ id: itemId })
          Alert.success('Trigger Removed')
          handleCleanUp()
        } catch (err) {
          Alert.error('Error removing trigger: ', err)
        }
        break
      case 'MagicLink':
        try {
          await deleteMagicLink.mutateAsync({ id: itemId })
          Alert.success('MagicLink Removed')
          handleCleanUp()
        } catch (err) {
          Alert.error('Error removing MagicLink: ', err)
        }
        break
      case 'Campaign':
        try {
          await deleteCampaign.mutateAsync({ id: itemId })
          Alert.success('Campaign Removed')
          handleCleanUp()
        } catch (err) {
          Alert.error('Error removing campaign: ', err)
        }
        break
    }
  }

  const handleLinkClick = (item: AutomationItem) => {
    setSelectedItem(item)
    itemDisclosure.onOpen()
  }

  const mutationLoading =
    deleteCampaign.isLoading ||
    deleteMagicLink.isLoading ||
    deleteTrigger.isLoading ||
    updatePlaybookDefinition.isLoading

  return !!itemsList.length ? (
    <>
      <Flex direction="column">
        <Flex
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize="lg"
            fontWeight="semibold"
          >
            {type}
          </Text>
        </Flex>
        <Wrap spacing={4}>
          {itemsList?.map((item) => {
            return (
              <WrapItem key={item?.id}>
                <UiLink onClick={() => handleLinkClick(item)}>{item?.name}</UiLink>
              </WrapItem>
            )
          })}
        </Wrap>
      </Flex>

      {itemDisclosure.isOpen && selectedItem && (
        <UiDialog
          size="2xl"
          status="info"
          title="Item Automation"
          onClose={itemDisclosure.onClose}
          isOpen={itemDisclosure.isOpen}
        >
          <Text fontSize="lg">
            Would you like to{' '}
            <UiLink
              fontSize="lg"
              onClick={itemDeleteDisclosure.onOpen}
            >
              delete
            </UiLink>{' '}
            the{' '}
            <strong>
              {selectedItem?.name} {type}
            </strong>{' '}
            or{' '}
            <UiLink
              fontSize="lg"
              onClick={() => handleNav(selectedItem?.id)}
            >
              navigate
            </UiLink>{' '}
            to the {type} details page?
          </Text>
        </UiDialog>
      )}

      {itemDeleteDisclosure.isOpen && selectedItem && (
        <UiConfirm
          title="Delete Automation"
          isOpen={itemDeleteDisclosure.isOpen}
          onConfirm={() => handleDelete(type, selectedItem?.id)}
          onClose={itemDeleteDisclosure.onClose}
          buttonColor="red"
          buttonText="Delete Automation"
          isLoading={mutationLoading}
          isDisabled={mutationLoading}
          size="xl"
        >
          <Text fontSize="lg">This action will delete this automation.</Text>
        </UiConfirm>
      )}
    </>
  ) : null
}
