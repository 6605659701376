import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  GraphqlFilterTransform,
  useAlertError,
  useGraphqlFilter,
  ZCard,
  ZCardHeader,
  ZText,
} from '@postal-io/postal-ui'
import { BillingAccountsDocument, SearchTransferIntentsDocument, Status, TransferIntentStatus } from 'api'
import { BalanceTransferHistoryFilter } from './BalanceTransferHistoryFilter'
import { BalanceTransferHistoryTable } from './BalanceTransferHistoryTable'

const HIDDEN_STATUSES = [TransferIntentStatus.Canceled, TransferIntentStatus.Created]

const transforms = {
  fromBillingAccountId: GraphqlFilterTransform.In,
  toBillingAccountId: GraphqlFilterTransform.In,
  created: GraphqlFilterTransform.Between,
}

const staticVariables = {
  limit: 100,
  filter: { status: { nin: HIDDEN_STATUSES } },
}

export const BalanceTransferHistoryCard = () => {
  // generate a list of account options and for mapping data in the table
  const billingAccountsQuery = useGraphqlQuery(BillingAccountsDocument, {
    filter: { status: { in: [Status.Active, null] }, type: { eq: 'FUNDS' } },
  })
  const billingAccounts = billingAccountsQuery.data?.billingAccounts ?? []
  useAlertError(billingAccountsQuery.error)

  // for filtering the table
  const { filter, setFilter, resetFilter, filterCount, variables } = useGraphqlFilter({
    staticVariables,
    transforms,
  })
  const searchTransferIntentsQuery = useGraphqlQuery(SearchTransferIntentsDocument, variables, {
    keepPreviousData: true,
  })
  const transferIntents = searchTransferIntentsQuery?.data?.searchTransferIntents ?? []
  useAlertError(searchTransferIntentsQuery.error)

  return (
    <ZCard
      isLoading={searchTransferIntentsQuery.isLoading}
      isFetching={searchTransferIntentsQuery.isFetching}
      variant="form"
    >
      <ZCardHeader
        display="flex"
        flexDir="column"
        alignItems="flex-start"
        p={8}
      >
        <ZText
          fontSize="16px"
          fontWeight={400}
          color="inherit"
        >
          Transfer History
        </ZText>
        <BalanceTransferHistoryFilter
          filter={filter}
          filterCount={filterCount}
          setFilter={setFilter}
          resetFilter={resetFilter}
          billingAccounts={billingAccounts}
        />
      </ZCardHeader>
      <BalanceTransferHistoryTable
        billingAccounts={billingAccounts}
        transferIntents={transferIntents}
      />
    </ZCard>
  )
}
