import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiInputProps, UiStateInputProps } from '@postal-io/postal-ui'
import { UiCountryInput, UiStateInput } from '@postal-io/postal-ui'
import { GetLocationDataDocument } from 'api'
import React, { useEffect, useMemo, useRef } from 'react'
import type { Updater } from 'use-immer'

interface AutofillStateInputProps extends UiStateInputProps {
  name?: string
  countryName?: string
  form?: any
  setForm?: Updater<any>
}
export const AutofillStateInput: React.FC<AutofillStateInputProps> = ({
  name = 'state',
  countryName,
  form,
  setForm,
  ...rest
}) => {
  const { data } = useGraphqlQuery(GetLocationDataDocument, undefined, { enabled: !form?.state })
  const geoLocation = data?.getLocationData

  const ref = useRef(false)

  useEffect(() => {
    if (form?.state) ref.current = true
  }, [form?.state])

  useEffect(() => {
    if (!ref.current && !form?.state && geoLocation?.regionCode) {
      setForm?.((draft: any) => void (draft[name] = geoLocation.regionCode))
    }
  }, [form?.state, geoLocation?.regionCode, name, setForm])

  const country = useMemo(
    () => countryName ?? geoLocation?.countryCode3 ?? undefined,
    [countryName, geoLocation?.countryCode3]
  )

  return (
    <UiStateInput
      data-testid="AutoCompleteState"
      isRequired
      name={name}
      value={form?.state || ''}
      country={country}
      onChange={(e) => setForm && setForm((draft: any) => void (draft[name] = e.target.value))}
      {...rest}
    />
  )
}

interface AutofillCountryInputProps extends UiInputProps {
  name?: string
  form?: any
  setForm?: Updater<any>
}
export const AutofillCountryInput: React.FC<AutofillCountryInputProps> = ({
  name = 'country',
  form,
  setForm,
  ...rest
}) => {
  const { data } = useGraphqlQuery(GetLocationDataDocument)
  const geoLocation = data?.getLocationData

  const ref = useRef(false)

  // set the form state with geoLocation if its not already set
  // but only do this once
  useEffect(() => {
    if (!ref.current && geoLocation?.countryCode3) {
      ref.current = true
      setForm && setForm((draft: any) => void (draft[name] = geoLocation.countryCode3))
    }
  }, [name, geoLocation?.countryCode3, setForm])

  return (
    <UiCountryInput
      isRequired
      name={name}
      value={form?.country}
      onChange={(e) => setForm && setForm((draft: any) => void (draft[name] = e.target.value))}
      {...rest}
    />
  )
}
