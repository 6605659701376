import { Box, Flex, Stack, useDisclosure, Wrap, WrapItem } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiTag, UiTextarea, UiToggle, ZButton, ZCardBody, ZHeading, ZText } from '@postal-io/postal-ui'
import type { FormFieldInput } from 'api'
import { GetAccountDocument } from 'api'
import { ZFormLabel, ZInputRichText, ZInputTextWithVariables } from 'components/Common/ZComponents'
import { MessageVariablesDrawer } from 'components/Messages/MessageVariablesDrawer'
import { DEFAULT_FORM_FIELDS_V2, PostalEventCustomFields } from 'components/Postal/PostalEventCustomFields'
import { CATEGORY } from 'components/Postals/data'
import { PostalReviewPopOver } from 'components/PostalSend/PostalReviewPopOver'
import { useAcl, useMe, useSendAs } from 'hooks'
import { correctHtmlInterruptedMessageVariables, getMessageVariablesErrors, getParsingError } from 'lib'
import React, { useMemo } from 'react'
import { useExtension } from '../../hooks/useExtension'
import { messageVariables } from '../Messages'
import type { PostalCustomizeV2Props } from './PostalCustomize'
import { InlineErrorDisplay, MessageVariablesButton } from './PostalCustomizeEmail'
import {
  DEFAULT_LANDING_PAGE_HEADER,
  getSendMethodColor,
  landingPageBodyPlaceholder,
  SendAsType,
} from './usePostalSend'
import { usePostalSendFieldErrors } from './usePostalSendFieldErrors'

export const PostalCustomizeLandingPage: React.FC<PostalCustomizeV2Props> = ({ context, send }) => {
  const { hasFeature } = useAcl()
  const { isExtension } = useExtension()
  const canUseRichText = hasFeature('richTextEditor')
  const seeMessageVariables = useDisclosure()
  const customFieldsDisclosure = useDisclosure()

  const { me } = useMe()
  const { user: sendAsUser } = useSendAs({
    sendAsContactOwner: context.sendAsContactOwner,
    sendAsUser: context.sendAsUser,
  })

  const getAccount = useGraphqlQuery(GetAccountDocument)
  const accountName = useMemo(() => {
    const { displayName, name } = getAccount.data?.getAccount || {}
    return displayName || name || 'COMPANY'
  }, [getAccount.data?.getAccount])

  const sendAsName = useMemo(() => {
    return (context.sendAsType === SendAsType.User && sendAsUser?.firstName) || me?.firstName || me?.lastName || 'USER'
  }, [context.sendAsType, me?.firstName, me?.lastName, sendAsUser?.firstName])

  const headerPlaceholder = DEFAULT_LANDING_PAGE_HEADER(
    sendAsName,
    accountName,
    context?.postal?.category === CATEGORY.Events
  )

  const handleCustomFieldsConfirm = (fieldInfo: FormFieldInput[]) => {
    send({ type: 'SET_FORM_FIELDS', data: fieldInfo })
    customFieldsDisclosure.onClose()
  }

  const landingPageBodyError = useMemo(
    () =>
      //Handles variable formatting issues
      getParsingError(context.landingPageBody || '', canUseRichText) ||
      //handles variable data issues
      getMessageVariablesErrors(context.landingPageBody || '', sendAsUser || me),
    [context.landingPageBody, canUseRichText, sendAsUser, me]
  )
  const landingPageHeaderError = useMemo(
    () =>
      //Handles variable formatting issues
      getParsingError(context.landingPageHeaderText || '') ||
      //handles variable data issues
      getMessageVariablesErrors(context.landingPageHeaderText || '', sendAsUser || me),
    [context.landingPageHeaderText, me, sendAsUser]
  )

  const handleHeaderText = (newVal: string) => {
    send({ type: 'SET_LANDING_PAGE_HEADER_TEXT', data: newVal })
  }

  const handleBody = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    send({ type: 'SET_LANDING_PAGE_BODY', data: e.target.value })
  }

  const handleBodyRichText = (v: string) => {
    send({ type: 'SET_LANDING_PAGE_BODY', data: correctHtmlInterruptedMessageVariables(v) })
  }

  const handleIncludeHeadshot = (e: React.ChangeEvent<HTMLInputElement>) => {
    send({ type: 'SET_LANDING_PAGE_INCLUDE_HEADSHOT', data: e.target.checked })
  }

  const handleIncludeSenderName = (e: React.ChangeEvent<HTMLInputElement>) => {
    send({ type: 'SET_LANDING_PAGE_INCLUDE_SENDER_NAME', data: e.target.checked })
  }

  const landingPageHeaderTextErrorProps = usePostalSendFieldErrors({ context, field: 'landingPageHeaderText' })
  const landingPageBodyErrorProps = usePostalSendFieldErrors({ context, field: 'landingPageBody' })

  return (
    <>
      <ZCardBody pt={{ base: 6, md: 6 }}>
        <ZHeading
          as="h3"
          size="h6"
          pb={8}
          display="flex"
          color="atomicGray.800"
          justifyContent="space-between"
          alignItems="center"
        >
          Landing Page
          <PostalReviewPopOver
            context={context}
            isLandingPage
          />
        </ZHeading>
        <Stack spacing={5}>
          <Box>
            <ZFormLabel>Header</ZFormLabel>
            <ZInputTextWithVariables
              {...landingPageHeaderTextErrorProps}
              placeholder={headerPlaceholder}
              data-testid="PostalCustomizeGiftEmail_HeaderText_Input"
              value={context.landingPageHeaderText || ''}
              onChange={handleHeaderText}
              isInvalid={!!landingPageHeaderError}
              messageVariables={messageVariables}
              colorScheme={getSendMethodColor(context).replace(/\.\d00/, '')}
            />
            <InlineErrorDisplay error={landingPageHeaderError} />
          </Box>

          <Box>
            <Flex justifyContent="space-between">
              <ZFormLabel>Message</ZFormLabel>
              <Box textAlign="right">
                <MessageVariablesButton onClick={seeMessageVariables.onOpen} />
              </Box>
            </Flex>

            {canUseRichText ? (
              <ZInputRichText
                {...landingPageBodyErrorProps}
                messageVariables={messageVariables}
                placeholder={landingPageBodyPlaceholder(context)}
                data-testid="PostalCustomizeGiftEmail_BodyText_Input"
                value={context.landingPageBody || ''}
                onChange={handleBodyRichText}
                isInvalid={!!landingPageBodyError}
                colorScheme={getSendMethodColor(context).replace(/\.\d00/, '')}
              />
            ) : (
              <UiTextarea
                {...landingPageBodyErrorProps}
                minH="200"
                placeholder={landingPageBodyPlaceholder(context)}
                data-testid="PostalCustomizeGiftEmail_BodyText_Input"
                value={context.landingPageBody || ''}
                onChange={handleBody}
                isInvalid={!!landingPageBodyError}
              />
            )}
            <InlineErrorDisplay error={landingPageBodyError} />
          </Box>

          <Box>
            <Flex
              justifyContent="space-between"
              alignItems="baseline"
              flexWrap="wrap"
              mb={5}
            >
              <ZText
                fontSize="sm"
                color="atomicGray.600"
                fontWeight="bold"
                flex={1}
              >
                Form Fields
              </ZText>

              <ZButton
                display={isExtension ? 'none' : 'inline'}
                fontSize="body-md"
                color="atomicBlue.400"
                _hover={{ color: 'atomicBlue.600' }}
                variant="link"
                p={0}
                h="unset"
                onClick={customFieldsDisclosure.onOpen}
              >
                Customize Fields
              </ZButton>
            </Flex>

            <Wrap spacing={5}>
              {(context.formFieldList || DEFAULT_FORM_FIELDS_V2).map((item, idx) => {
                return (
                  <WrapItem key={`${item.name}-${idx}`}>
                    <UiTag
                      size="sm"
                      textAlign="center"
                    >
                      {item.name}
                    </UiTag>
                  </WrapItem>
                )
              })}
            </Wrap>
          </Box>
          <Box>
            <Stack
              my={5}
              spacing={8}
            >
              <ZText
                fontSize="sm"
                color="atomicGray.600"
                fontWeight="bold"
                flex={1}
              >
                Landing Page Customization
              </ZText>
              <Flex justifyContent="space-between">
                <ZText>Include headshot on landing page</ZText>
                <UiToggle
                  name="includeHeadshot"
                  isChecked={context.landingPageIncludeHeadshot}
                  onChange={handleIncludeHeadshot}
                />
              </Flex>
              <Flex
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <ZText>Include sender name and company name on landing page</ZText>
                <UiToggle
                  name="includeHeadshot"
                  isChecked={context.landingPageIncludeSenderName}
                  onChange={handleIncludeSenderName}
                />
              </Flex>
            </Stack>
          </Box>
        </Stack>
      </ZCardBody>

      {customFieldsDisclosure.isOpen && (
        <PostalEventCustomFields
          {...customFieldsDisclosure}
          onConfirm={handleCustomFieldsConfirm}
          customFields={context.formFieldList}
        />
      )}

      {seeMessageVariables.isOpen && (
        <MessageVariablesDrawer
          isOpen={seeMessageVariables.isOpen}
          onClose={seeMessageVariables.onClose}
          method={context.method}
        />
      )}
    </>
  )
}
