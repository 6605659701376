import type { Column } from '@postal-io/postal-ui'
import { LexendFontWeight, UiActionMenu, UiDate, UiStatusTag, ZButton, ZLink, ZText } from '@postal-io/postal-ui'
import { capitalize } from 'lodash'
import { Link } from 'react-router-dom'

export const ACTIVE_STATUS: any[] = ['ACTIVE', 'QUEUED', 'PROCESSING']
export const COMPLETED_STATUS: any[] = ['CANCELLED', 'COMPLETED', 'FAILED']

export const STATUS_COLOR_MAP: Record<string, string> = {
  ACTIVE: 'green.500',
  QUEUED: 'green.500',
  PROCESSING: 'green.500',
  CANCELLED: 'blue.400',
  COMPLETED: 'blue.500',
  FAILED: 'red.500',
}

export const columns: Column[] = [
  {
    label: 'Name',
    render: ({ contactId, contactName }: any) => {
      return (
        <ZLink
          as={Link}
          to={`/contacts/${contactId}`}
        >
          {contactName || 'N/A'}
        </ZLink>
      )
    },
  },
  {
    label: 'Steps',
    render: (item: any) => `${item.nextStepToExecute} of ${item.steps.length}`,
  },
  {
    key: 'nextExecutionDate',
    label: 'Next Send',
    render: ({ nextExecutionDate }: any) => {
      return (
        <ZText>
          <UiDate
            date={nextExecutionDate}
            fallback="N/A"
          />
        </ZText>
      )
    },
  },
  {
    label: 'Status',
    render: (instance: any) => {
      return (
        <ZButton
          onClick={instance.onClick}
          variant="unstyled"
        >
          <UiStatusTag
            minW="100px"
            px={4}
            fontWeight={LexendFontWeight.Regular}
            colorScheme={STATUS_COLOR_MAP[instance.playbookStatus]}
            label={capitalize(instance.playbookStatus)}
          />
        </ZButton>
      )
    },
  },
  {
    key: 'action',
    label: 'Action',
    render: ({ actions }: any) => <UiActionMenu actionItems={actions} />,
  },
]
