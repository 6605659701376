import { CenteredBox } from 'components/Common'
import { CATEGORIES_ITEMS_ONLY } from 'components/Postals'
import { useMe, usePostalFilters } from 'hooks'
import { StorageKeys } from 'lib'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import type { MarketplaceProductFilterInput } from '../../api/index'
import { Status } from '../../api/index'
import { OldMarketplaceSearchBar } from './OldMarketplaceSearchBar'
import { MarketplaceContext, MarketplaceView, MARKETPLACE_INITIAL_STATE, useMarketplace } from './useMarketplace'

export const OldMarketplacePage: React.FC = () => {
  // for legacy usage
  const location = useLocation()
  const { routerState, pathname } = location as any

  const { state, setNumFilters, setNumResults } = useMarketplace({
    initialState: MARKETPLACE_INITIAL_STATE,
  })

  // const [state, setState] = useImmer<State>(initialState)

  const { approvedCurrencies } = useMe()

  const defaultVariables = useMemo(() => {
    const variables: any = {
      status: { in: [Status.Active] },
    }
    variables.category = { in: CATEGORIES_ITEMS_ONLY }
    if (!isEmpty(approvedCurrencies)) variables.currency = { in: approvedCurrencies }
    return variables
  }, [approvedCurrencies])

  const marketplaceFilters = usePostalFilters<MarketplaceProductFilterInput>({
    defaultVariables,
    persistKey: StorageKeys.MarketplaceFilter,
  })

  // sometimes we are still using the router state to filters to this page
  useEffect(() => {
    if (!!routerState && Object.keys(routerState).length) {
      Object.entries(routerState).forEach((entry) => {
        marketplaceFilters.updateFilter(entry[0] as string, entry[1], 0)
      })
    }
    if (state.view === MarketplaceView.Home) {
      marketplaceFilters.clearFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.view])

  useEffect(() => {
    if (!pathname.includes('/items/marketplace')) return
    const searchParams = new URLSearchParams(location.search)
    const vendor = searchParams.get('vendor')
    // set the vendor in marketplace filters and remove everything
    // else so we can see all the products
    if (vendor) {
      marketplaceFilters.clearFilters()
      marketplaceFilters.updateFilter('fulfillmentPartnerName', vendor, 0)
    }
    // lets remove this to avoid confusion, this shouldn't trigger a navigation
    // with react-router
    window.history.replaceState(null, '', window.location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    setNumFilters(Object.keys(marketplaceFilters.filters).length ?? 0)
  }, [marketplaceFilters.filters, setNumFilters])

  return (
    <CenteredBox isLoaded>
      <MarketplaceContext.Provider
        value={{
          state,
          setNumFilters,
          setNumResults,
          marketplaceFilters,
        }}
      >
        <OldMarketplaceSearchBar />
        <Outlet />
      </MarketplaceContext.Provider>
    </CenteredBox>
  )
}
