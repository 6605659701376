import { Box, FormControl, Icon, InputGroup, Link, SimpleGrid } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  LexendFontWeight,
  useAlerts,
  ZButton,
  ZFormLabel,
  ZHeading,
  ZInput,
  ZLogoMinimal,
  ZText,
} from '@postal-io/postal-ui'
import { ContactType, UpsertContactDocument } from 'api'
import { AnalyticsEventV2, CONTACT_INVALIDATIONS, useAnalyticsSend, useBackgroundQueue } from 'hooks'
import React, { useEffect } from 'react'
import { MdOutlineAdd } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { ExtHeader } from '../Main/ExtHeader'

interface State {
  firstName: string
  lastName: string
  title: string
  companyName: string
  emailAddress: string
  type: ContactType
}

export const ExtContactCreate: React.FC = () => {
  const Alert = useAlerts()
  const navigate = useNavigate()
  const { emailAddress } = useParams() as any

  const sendAnalytics = useAnalyticsSend()

  const [form, setForm] = useImmer<State>({
    firstName: '',
    lastName: '',
    title: '',
    companyName: '',
    emailAddress: '',
    type: ContactType.Contact,
  })

  useEffect(() => {
    if (emailAddress) {
      setForm((draft: any) => void (draft.emailAddress = emailAddress))
    }
  }, [emailAddress, setForm])

  const { invalidate } = useBackgroundQueue()
  const createContact = useGraphqlMutation(UpsertContactDocument, {
    onSuccess: () => invalidate(CONTACT_INVALIDATIONS, 1000),
  })

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setForm((draft: any) => {
      draft[name] = value
    })
  }

  const onSubmit = async (e: React.FormEvent) => {
    sendAnalytics({ event: AnalyticsEventV2.ExtensionContactAddButton })
    e.preventDefault()
    try {
      const res = await createContact.mutateAsync({ data: form })
      Alert.success('Contact Created')
      navigate(`/extension/contacts/${res.upsertContact?.id}`)
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <Box p={4}>
      <ExtHeader>
        <Link
          href={'/items'}
          isExternal
          p={1}
          borderRadius=".25rem"
          _hover={{ bg: 'rgba(213,215,218,.3)' }}
        >
          <Icon
            as={ZLogoMinimal}
            color="gray.900"
            fontSize="103px"
            h="auto"
          />
        </Link>
      </ExtHeader>
      <ZText
        size="md"
        mt={10}
      >
        No Contact found
      </ZText>
      <ZHeading
        size="h4"
        mb={8}
      >
        Add Contact
      </ZHeading>
      <ZText
        size="md"
        mb={5}
      >
        Add contact manually
      </ZText>
      <form
        id="ContactCreate_form"
        onSubmit={onSubmit}
      >
        <SimpleGrid
          columns={1}
          spacing={4}
          pb="60px"
        >
          <FormControl
            id="firstName"
            isRequired
          >
            <ZFormLabel>First Name (required)</ZFormLabel>
            <InputGroup>
              <ZInput
                name="firstName"
                value={form.firstName}
                onChange={handleInput}
                data-private
              />
            </InputGroup>
          </FormControl>
          <FormControl
            id="lastName"
            isRequired
          >
            <ZFormLabel
              fontSize={14}
              lineHeight={3}
              fontWeight={400}
              mb={2}
            >
              Last Name (required)
            </ZFormLabel>
            <InputGroup>
              <ZInput
                name="lastName"
                value={form.lastName}
                onChange={handleInput}
                data-private
              />
            </InputGroup>
          </FormControl>
          <FormControl
            id="emailAddress"
            isRequired
          >
            <ZFormLabel
              fontSize={14}
              lineHeight={3}
              fontWeight={400}
              mb={2}
            >
              Email (required)
            </ZFormLabel>
            <InputGroup>
              <ZInput
                name="emailAddress"
                value={form.emailAddress}
                onChange={handleInput}
                data-private
              />
            </InputGroup>
          </FormControl>
          <FormControl id="title">
            <ZFormLabel
              fontSize={14}
              lineHeight={3}
              fontWeight={400}
              mb={2}
            >
              Title
            </ZFormLabel>
            <InputGroup>
              <ZInput
                name="title"
                value={form.title}
                onChange={handleInput}
              />
            </InputGroup>
          </FormControl>
          <FormControl id="companyName">
            <ZFormLabel
              fontSize={14}
              lineHeight={3}
              fontWeight={400}
              mb={2}
            >
              Company
            </ZFormLabel>
            <InputGroup>
              <ZInput
                name="companyName"
                value={form.companyName}
                onChange={handleInput}
              />
            </InputGroup>
          </FormControl>
        </SimpleGrid>
        <Box
          display="flex"
          position="fixed"
          bottom={4}
          right={4}
        >
          <ZButton
            ml={2}
            leftIcon={
              <MdOutlineAdd
                fontSize="lg"
                color="WhiteAlpha.50"
              />
            }
            bg="atomicBlue.400"
            border={1}
            borderStyle="solid"
            borderColor="atomicBlue.400"
            type="submit"
            isDisabled={createContact.isLoading}
            isLoading={createContact.isLoading}
            /* @ts-ignore */
            form="ContactCreate_form"
            sx={{
              boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '66px',
            }}
            _hover={{ bg: 'atomicBlue.500' }}
            _active={{ bg: 'atomicBlue.500' }}
          >
            <ZText
              color="whiteAlpha.900"
              size="md"
              fontWeight={LexendFontWeight.Bold}
            >
              Add Contact
            </ZText>
          </ZButton>
        </Box>
      </form>
    </Box>
  )
}
