import { Box, HStack, Slide, Stack, useDisclosure } from '@chakra-ui/react'
import { graphqlFetch, useGraphqlQuery } from '@postal-io/postal-graphql'
import { downloadFile, useAlertError, useAlerts, ZCard, ZCardBody } from '@postal-io/postal-ui'
import { CenteredBox } from 'components/Common'
import { ZActionList } from 'components/Common/ZComponents'
import { ContactsCreatePlaybookInstances } from 'components/Contacts/ContactsCreatePlaybookInstances'
import { ZTabButton } from 'components/MultiSelectContacts/MultiSelectSelectedTags'
import { PostalFulfillmentsTable } from 'components/PostalFulfillments'
import { PostalSelectItem } from 'components/PostalSend/PostalSelectItem'
import { NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { PageTitle, useAcl, useNavigateSendFlow, useRouteBack } from 'hooks'
import { getContactString } from 'lib'
import React, { useCallback, useMemo, useState } from 'react'
import {
  MdOutlineDelete,
  MdOutlineEdit,
  MdOutlineFileDownload,
  MdOutlineSend,
  MdOutlineSubscriptions,
} from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import type { ApprovedPostal } from '../../api'
import { DownloadContactDataDocument, GetContactDocument } from '../../api'
import { ContactCampaigns } from './ContactCampaigns'
import { ContactCreateEdit } from './ContactCreateEdit'
import { ContactDelete } from './ContactDelete'
import { ContactInfo } from './ContactInfo'
import { ContactPlaybookInstances } from './ContactPlaybookInstances'

enum direction {
  'left' = 'left',
  'right' = 'right',
}
const getOtherDirection = (dir: direction) => (dir === direction.left ? direction.right : direction.left)

enum Tab {
  'Orders' = 'Orders',
  'GroupOrders' = 'Group Orders',
  'Subscriptions' = 'Subscriptions',
}

export const Contact: React.FC<any> = () => {
  const { contactId } = useParams() as any
  const Alert = useAlerts()
  const sendPostal = useDisclosure()
  const editProfile = useDisclosure()
  const deleteContact = useDisclosure()
  const addPlaybook = useDisclosure()

  const getContactQuery = useGraphqlQuery(GetContactDocument, { id: contactId })
  const contact = getContactQuery?.data?.getContact
  useAlertError(getContactQuery.error)

  const { hasPermission } = useAcl()

  const canUpdateContacts = hasPermission('contacts.update')
  const canDeleteContacts = hasPermission('contacts.delete')
  const canReadPlaybooks = hasPermission('playbooks.read')
  const canSendPostal = hasPermission('postals.send')
  const navigate = useNavigate()

  const handleDownload = async () => {
    const fileName = `${getContactString(contact)}.json`
    try {
      const res = await graphqlFetch(DownloadContactDataDocument, { contactId })
      downloadFile(res.downloadContactData, fileName, 'application/json')
    } catch (err) {
      Alert.error(err)
    }
  }

  const title = getContactString(contact) || 'Loading...'

  const actions = [
    { title: 'Edit Contact', icon: MdOutlineEdit, onClick: editProfile.onOpen, isHidden: !canUpdateContacts },
    {
      title: 'Add Subscription',
      icon: MdOutlineSubscriptions,
      onClick: addPlaybook.onOpen,
      isHidden: !canSendPostal,
    },
    { title: 'Send Item', icon: MdOutlineSend, onClick: sendPostal.onOpen, isHidden: !canSendPostal },
    { title: 'Download Contact', icon: MdOutlineFileDownload, onClick: handleDownload },
    { title: 'Delete Contact', icon: MdOutlineDelete, onClick: deleteContact.onOpen, isHidden: !canDeleteContacts },
  ]

  const back = useRouteBack('Contacts', '/contacts')

  const ordersTabs = useMemo(() => {
    const tabList = []
    tabList.push(Tab.Orders)
    tabList.push(Tab.GroupOrders)
    if (canReadPlaybooks) tabList.push(Tab.Subscriptions)
    return tabList
  }, [canReadPlaybooks])

  const [selectedTab, setSelectedTab] = useState<Tab>(ordersTabs[0])

  const [slideDirection, setSlideDirection] = useState<direction>(direction.right)

  const handleTabChange = (newTab: Tab) => {
    setSlideDirection(ordersTabs.indexOf(selectedTab) > ordersTabs.indexOf(newTab) ? direction.left : direction.right)
    setSelectedTab(newTab)
  }

  const getSlideDirection = (cond: boolean) => (cond ? slideDirection : getOtherDirection(slideDirection))

  const sendFlowLink = useNavigateSendFlow()

  const handleSelectItem = useCallback(
    (postal: ApprovedPostal) => {
      sendPostal.onClose()
      if (contact) {
        navigate(
          sendFlowLink(`/contacts/send/${postal.id}`, {
            returnTo: 'Contact',
            contactFilter: { items: [contact] },
          })
        )
      }
    },
    [contact, navigate, sendFlowLink, sendPostal]
  )

  return (
    <>
      <PageTitle
        title={title}
        section="Contacts"
      />
      <SecondaryNavbar
        px={8}
        left={
          <NavbarBackButton
            onClick={() => (back.path ? navigate(back.path) : navigate(-1))}
            label={back.title}
          />
        }
        header="Contact Info"
      />
      <CenteredBox
        pt={8}
        isLoaded
      >
        <HStack
          gap={8}
          alignItems="stretch"
        >
          <Stack
            spacing={5}
            overflow="hidden"
            flexGrow={1}
          >
            <ContactInfo contactId={contactId} />
            <ZCard variant="form">
              <ZCardBody pt={{ base: 5, md: 5 }}>
                <Box
                  borderBottom="2px solid #E0E6ED"
                  mb={3}
                  mt={-4}
                >
                  {ordersTabs.map((tab) => (
                    <ZTabButton
                      key={tab}
                      isActive={selectedTab === tab}
                      onClick={() => handleTabChange(tab)}
                    >
                      {tab}
                    </ZTabButton>
                  ))}
                </Box>
                <Slide
                  direction={getSlideDirection(selectedTab === Tab.Orders)}
                  in={selectedTab === Tab.Orders}
                  style={{ position: 'relative' }}
                  unmountOnExit
                >
                  <PostalFulfillmentsTable
                    contactId={contactId}
                    useContactSearch={true}
                  />
                </Slide>
                <Slide
                  direction={getSlideDirection(selectedTab === Tab.GroupOrders)}
                  in={selectedTab === Tab.GroupOrders}
                  style={{ position: 'relative' }}
                  unmountOnExit
                >
                  <ContactCampaigns contactId={contactId} />
                </Slide>
                <Slide
                  direction={getSlideDirection(selectedTab === Tab.Subscriptions)}
                  in={selectedTab === Tab.Subscriptions && canReadPlaybooks}
                  style={{ position: 'relative' }}
                  unmountOnExit
                >
                  <ContactPlaybookInstances contactId={contactId} />
                </Slide>
              </ZCardBody>
            </ZCard>
          </Stack>
          <ZActionList
            actions={actions}
            height="unset"
          />
        </HStack>
      </CenteredBox>

      {contact && canSendPostal && sendPostal.isOpen && (
        <PostalSelectItem
          isOpen={sendPostal.isOpen}
          onClose={sendPostal.onClose}
          onSelect={handleSelectItem}
        />
      )}
      {contact && editProfile.isOpen && (
        <ContactCreateEdit
          contact={contact}
          isOpen={editProfile.isOpen}
          onClose={editProfile.onClose}
          onComplete={() => getContactQuery?.refetch()}
        />
      )}
      {contact && deleteContact.isOpen && (
        <ContactDelete
          contact={contact}
          isOpen={deleteContact.isOpen}
          onClose={deleteContact.onClose}
          onComplete={() => navigate('/contacts')}
        />
      )}
      {contact && addPlaybook.isOpen && (
        <ContactsCreatePlaybookInstances
          contact={contact}
          isOpen={addPlaybook.isOpen}
          onClose={addPlaybook.onClose}
        />
      )}
    </>
  )
}
