import {
  Badge,
  Box,
  chakra,
  Collapse,
  Divider,
  Fade,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import type { ZProductCardProps } from '@postal-io/postal-ui'
import {
  UiMenu,
  UiMenuItem,
  UiScaleEffect,
  UiSpinner,
  UiTruncate,
  useColor,
  ZButton,
  ZConfirm,
  ZProductCard,
  ZProductCardBody,
  ZProductCardContent,
  ZProductCardImage,
  ZProductCardImageContainer,
  ZProductCardImageHover,
  ZProductCardImageIcons,
  ZProductCardImageIconSelect,
} from '@postal-io/postal-ui'
import type { ApprovedProductVariant, ProductVariant } from 'api'
import { Status } from 'api'
import { ZAlert, ZStatusTag } from 'components/Common/ZComponents'
import { MarketplaceApprovedPostalPriceRange } from 'components/Marketplace/MarketplaceTextPriceRange'
import { ApprovedPostalOption } from 'components/Postal/ApprovedVariantOption'
import { PostalVariantOption } from 'components/Postal/PostalVariantOption'
import { CATEGORY } from 'components/Postals'
import { useAssets, useExtension } from 'hooks'
import { every } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { IconContext } from 'react-icons'
import { MdCheckCircle, MdOutlineDelete, MdOutlineEdit } from 'react-icons/md'
import { useCollectionItemPossibleVariants } from './useCollections'
import type { CollectionItem, EitherVariant } from './utils'

const StatusTags = {
  draft: {
    label: 'Draft',
    color: 'atomicBlue.900',
    bg: 'atomicBlue.10',
  },
  outOfStock: {
    label: 'Out of Stock',
    color: 'atomicGray.900',
    bg: 'atomicGray.10',
  },
  approvedItem: {
    label: 'Approved Item',
    color: 'atomicGray.900',
    bg: 'atomicGray.10',
  },
}

// const cardProps = {
//   p: 0,
//   m: 0,
//   w: CARD_WIDTH,
//   h: CARD_HEIGHT,
// }
// const buttonProps = {
//   border: '2px solid',
//   borderColor: 'currentColor',
//   bg: 'transparent',
//   transition: '0.2s color, 0.2s transform',
//   sx: { '&:hover': { bg: 'transparent', transform: 'scale(1.05)' } },
// }
// const iconProps = {
//   // color: 'white',
//   boxSize: '40px',
//   p: 2,
// }

interface HoverElementProps {
  onEdit: () => void
  onRemove: () => void
  hideUtilityButtons?: boolean
  buttonText?: string
}
const HoverElement: React.FC<HoverElementProps> = ({ onEdit, onRemove, hideUtilityButtons }) => {
  const { isExtension } = useExtension()

  return (
    <IconContext.Provider value={{ size: '20px', color: 'black' }}>
      <Flex
        h="100%"
        flexDir="column"
        justifyContent="center"
        gap={{ base: 4, sm: 5 }}
        p={5}
      >
        <Box />
        {!hideUtilityButtons && !isExtension && (
          <Flex
            justifyContent="center"
            gap={{ base: 2, sm: 4 }}
          >
            {onEdit && (
              <UiScaleEffect>
                <IconButton
                  aria-label="Edit Item"
                  size="md"
                  borderRadius="full"
                  bg="whiteAlpha.700"
                  _hover={{ bg: 'whiteAlpha.800' }}
                  _active={{ bg: 'whiteAlpha.900' }}
                  icon={<MdOutlineEdit />}
                  onClick={onEdit}
                />
              </UiScaleEffect>
            )}
            {onRemove && (
              <UiScaleEffect>
                <IconButton
                  aria-label="Delete Item"
                  size="md"
                  borderRadius="full"
                  bg="whiteAlpha.700"
                  _hover={{ bg: 'whiteAlpha.800' }}
                  _active={{ bg: 'whiteAlpha.900' }}
                  icon={<MdOutlineDelete />}
                  onClick={onRemove}
                />
              </UiScaleEffect>
            )}
          </Flex>
        )}
      </Flex>
    </IconContext.Provider>
  )
}

interface CollectionItemCardProps extends Omit<ZProductCardProps, 'onSelect'> {
  item: CollectionItem
  isBulkSelected?: boolean
  favoriteItemId?: string | null
  onUpdateVariants?: (variants: EitherVariant[]) => any
  onEdit?: (item: CollectionItem) => any
  onRemove?: () => void
  onSelect?: (item: CollectionItem) => any
  onBulkSelect?: (item: CollectionItem) => any
  buttonText?: string
  hideUtilityButtons?: boolean
  truncateLength?: number
}

export const CollectionItemCard: React.FC<CollectionItemCardProps> = ({
  item,
  isBulkSelected,
  onUpdateVariants,
  onEdit,
  onRemove,
  onSelect,
  onBulkSelect,
  buttonText,
  hideUtilityButtons,
  favoriteItemId,
  truncateLength = 40,
  ...rest
}) => {
  // const { hasRole } = useAcl()
  // const hasFavorites = hasRole(Role.User)

  const removeDisclosure = useDisclosure()

  const { assetUrlSrc } = useAssets()
  const srcProps = assetUrlSrc(item.defaultImage)

  const handleSelect = useCallback(() => onSelect?.(item), [item, onSelect])

  const canBulkSelect = !!onBulkSelect

  const handleBulkSelect = () => {
    onBulkSelect?.(item)
  }

  const statusTag = useMemo(() => {
    if (item.approvedPostalId) return StatusTags.approvedItem
    if (!item.variants?.filter((v) => v.status === Status.Active).length) return StatusTags.outOfStock
    return false
  }, [item.approvedPostalId, item.variants])

  const { isExtension } = useExtension()

  // since we aren't using a mouse on tablets, skip hover view and open item instead
  const enableHover = useBreakpointValue(isExtension ? { base: true, sm: true } : { base: false, lg: true })

  return (
    <>
      <Fade in>
        <ZProductCard
          defaultIsOpen={isBulkSelected}
          cursor={rest.isDisabled ? 'not-allowed' : 'revert'}
          {...rest}
        >
          <ZProductCardContent>
            <ZProductCardImageContainer
              borderWidth="1px"
              borderColor="atomicGray.100"
              onClick={enableHover ? undefined : handleSelect}
            >
              <ZProductCardImageIcons
                justifyContent="space-between"
                zIndex={2}
              >
                {canBulkSelect ? (
                  <ZProductCardImageIconSelect
                    isSelected={isBulkSelected}
                    onClick={handleBulkSelect}
                  />
                ) : (
                  <Box />
                )}
                {statusTag ? (
                  <ZStatusTag
                    {...statusTag}
                    border="1px solid var(--chakra-colors-atomicGray-200)"
                    mt={3}
                    mr={4}
                  />
                ) : // ) : hasFavorites ? (
                //   <UiEnhancedTooltip
                //     label={isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
                //     mt={-2}
                //     mr={4}
                //     openDelay={1000}
                //     hasArrow
                //     isDisabled={isLoading}
                //     shouldWrapChildren
                //   >
                //     <ZProductCardImageIconFavorite
                //       isSelected={isFavorite}
                //       onClick={toggleFavorite}
                //     />
                //   </UiEnhancedTooltip>
                null}
              </ZProductCardImageIcons>
              <ZProductCardImage {...srcProps} />
              {!rest.isDisabled && (
                <ZProductCardImageHover
                  jsx={
                    <HoverElement
                      buttonText={buttonText}
                      hideUtilityButtons={hideUtilityButtons || !enableHover}
                      onEdit={() => onEdit?.(item)}
                      onRemove={removeDisclosure.onOpen}
                    />
                  }
                />
              )}
            </ZProductCardImageContainer>
            <ZProductCardBody>
              <Flex
                mt={2}
                flexDir="column"
                alignItems="left"
                gap={0}
                cursor={rest.isDisabled ? 'not-allowed' : 'default'}
              >
                <chakra.span
                  color="atomicGray.700"
                  noOfLines={1}
                  maxW="325px"
                  display="flex"
                  justifyContent="space-between"
                >
                  <UiTruncate
                    text={item.productName}
                    length={truncateLength}
                  />
                  <OptionSelect
                    item={item}
                    onUpdateVariants={onUpdateVariants}
                    onRemove={onRemove}
                  />
                </chakra.span>
                <MarketplaceApprovedPostalPriceRange item={item} />
              </Flex>
            </ZProductCardBody>
          </ZProductCardContent>
        </ZProductCard>
      </Fade>
      {removeDisclosure.isOpen && (
        <ZConfirm
          {...removeDisclosure}
          buttonColor="atomicRed"
          onConfirm={() => void (onRemove ? onRemove() : undefined)}
          title="Are you sure?"
        >
          Sure you want to remove <strong>{item.productName}</strong> and its variants from this collection?
        </ZConfirm>
      )}
    </>
  )
}

const SELECT_ALL_THRESHOLD_ITEMS = 5

interface OptionSelectProps {
  item: CollectionItem
  onUpdateVariants: any
  onRemove: any
}
const OptionSelect: React.FC<OptionSelectProps> = ({ item, onUpdateVariants, onRemove }) => {
  const disclosure = useDisclosure()
  const removeDisclosure = useDisclosure()
  const [updateLoading, setUpdateLoading] = useState<boolean>(false)
  const { approvedItem, marketplaceItem, isLoading, availableVariants } = useCollectionItemPossibleVariants({
    item,
    disable: !disclosure.isOpen,
  })

  const [selectedVariants, setSelectedVariants] = useState(item.variants as EitherVariant[])

  const handleSelect = (variant: EitherVariant) => {
    if (selectedVariants.some((v) => v.id === variant.id)) {
      const newVariants = selectedVariants.filter((v) => v.id !== variant.id)
      setSelectedVariants(newVariants)
    } else {
      setSelectedVariants([...selectedVariants, variant])
    }
  }

  const hasChanges = useMemo(
    () =>
      selectedVariants.length !== item.variants?.length ||
      !every(selectedVariants, (s) => item.variants.find((v) => s.id === v.id)),
    [item.variants, selectedVariants]
  )

  const onConfirm = async () => {
    if (!selectedVariants.length) removeDisclosure.onOpen()
    else {
      setUpdateLoading(true)
      await onUpdateVariants(selectedVariants)
      setUpdateLoading(false)
      disclosure.onClose()
    }
  }

  const onRemoveConfirm = async () => {
    setUpdateLoading(true)
    await onRemove()
    setUpdateLoading(false)
    onClose()
  }

  const onClose = () => {
    disclosure.onClose()
    setSelectedVariants(item.variants)
  }

  const variants = useMemo(
    () =>
      availableVariants?.map((v) => ({ ...v, isSelected: selectedVariants.some((selected) => selected.id === v.id) })),
    [availableVariants, selectedVariants]
  )

  /**
   * Select all
   */
  const allSelected = useMemo(
    () => every(availableVariants, (v: EitherVariant) => selectedVariants.find((selected) => selected.id === v.id)),
    [availableVariants, selectedVariants]
  )

  const handleClickSelectAll = () => setSelectedVariants(allSelected ? [] : availableVariants ?? [])

  return (
    <>
      <Popover
        {...disclosure}
        onClose={onClose}
        placement="bottom"
        isLazy
      >
        <PopoverTrigger>
          <Badge
            textAlign="center"
            bg="atomicBlue.400"
            color="white"
            borderRadius="50%"
            w="18px"
            h="18px"
            fontSize="body-sm"
            _hover={{ bg: 'atomicBlue.600' }}
            cursor="pointer"
            userSelect="none"
            px={0}
          >
            {item.variants.length}
          </Badge>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            width="unset"
            minWidth="300px"
            maxWidth="380px"
          >
            <PopoverArrow />
            <PopoverBody>
              {isLoading ? (
                <LoadingSpinner />
              ) : availableVariants?.length ? (
                <>
                  <UiMenu isOpen>
                    <Box
                      maxHeight="330px"
                      overflowY="scroll"
                    >
                      {(variants?.length ?? 0) >= SELECT_ALL_THRESHOLD_ITEMS && (
                        <>
                          <UiMenuItem
                            onClick={handleClickSelectAll}
                            py={3}
                            _hover={{ bg: 'atomicBlue.5' }}
                          >
                            <Icon
                              as={MdCheckCircle}
                              fontSize="20px"
                              display="inline-block"
                              margin="0px 12px 0 0px"
                              transition="0.2s color"
                              flexShrink="0"
                              color={allSelected ? 'atomicBlue.400' : 'atomicGray.200'}
                            />
                            <Text fontSize="body-md">Select All</Text>
                          </UiMenuItem>
                          <Divider
                            mx={3}
                            my={1}
                          />
                        </>
                      )}
                      {variants?.map((variant) => (
                        <EitherVariantOption
                          key={variant.id}
                          marketplaceItem={marketplaceItem}
                          approvedItem={approvedItem}
                          variant={variant}
                          handleSelect={() => handleSelect(variant)}
                          isMulti
                        />
                      ))}
                    </Box>
                  </UiMenu>
                  <Collapse in={hasChanges}>
                    <ConfirmAndCloseButtons
                      onClose={onClose}
                      onConfirm={onConfirm}
                      isLoading={updateLoading}
                    />
                  </Collapse>
                </>
              ) : (
                <ZAlert
                  status="warning"
                  hideClose
                >
                  This item is currently unavailable.
                </ZAlert>
              )}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      {removeDisclosure.isOpen && (
        <ZConfirm
          title="Remove this Item"
          {...removeDisclosure}
          buttonColor="atomicRed"
          buttonText="Remove item"
          onClose={() => {
            removeDisclosure.onClose()
            onClose()
          }}
          onConfirm={onRemoveConfirm}
          isLoading={updateLoading}
        >
          Sure you want to remove all <strong>{item.productName}</strong> options? This will remove the item from the
          collection.
        </ZConfirm>
      )}
    </>
  )
}

const EitherVariantOption: React.FC<any> = ({ approvedItem, marketplaceItem, variant, handleSelect, ...rest }) => {
  const { Color } = useColor()

  if (marketplaceItem) {
    const { inventory, shippingOptions } = (variant as ProductVariant).fulfillmentPartnerList?.[0] ?? {}

    return (
      <PostalVariantOption
        key={variant.id}
        onClick={() => handleSelect(variant)}
        isSelected={variant.isSelected}
        brandingColor={Color('atomicBlue.400')}
        currency={marketplaceItem.currency as string}
        name={variant.variantName}
        description={variant.description}
        imageUrl={variant.imageUrls?.[0]}
        hideShipping={marketplaceItem.category === CATEGORY.Events}
        displayPrice={variant.displayPrice || 0}
        shippingEstimate={shippingOptions?.[0]?.price}
        inventory={inventory?.available}
        isLoading={false}
        nameCharacters={30}
        {...rest}
      />
    )
  }
  if (approvedItem)
    return (
      <ApprovedPostalOption
        key={variant.id}
        variant={variant as ApprovedProductVariant}
        marketplaceProductId={approvedItem?.marketplaceProductId!}
        onClick={() => handleSelect(variant)}
        category={approvedItem?.category}
        isSelected={variant.isSelected}
        brandingColor={Color('atomicBlue.400')}
        nameCharacters={30}
        {...rest}
      />
    )

  return null
}

const ConfirmAndCloseButtons: React.FC<{ onClose: () => void; onConfirm: () => void; isLoading: boolean }> = ({
  onClose,
  onConfirm,
  isLoading,
}) => (
  <Flex
    justifyContent="flex-start"
    mt={3}
    mb={1}
  >
    <ZButton
      w="50px"
      h="30px"
      fontSize="body-sm"
      px={3}
      mr={3}
      colorScheme="atomicBlue"
      _hover={{ bg: 'atomicBlue.500' }}
      _active={{ bg: 'atomicBlue.600' }}
      onClick={onConfirm}
      isLoading={isLoading}
    >
      Save
    </ZButton>
    <ZButton
      w="60px"
      h="30px"
      fontSize="body-sm"
      px={3}
      variant="ghost"
      onClick={onClose}
    >
      Cancel
    </ZButton>
  </Flex>
)

const LoadingSpinner: React.FC = () => (
  <Flex
    justifyContent="center"
    p={3}
  >
    <UiSpinner
      size="2xl"
      w="70px"
      h="70px"
      thickness={'5px'}
      color="atomicBlue.400"
    />
  </Flex>
)
