import { SendFlowStep } from 'api'
import { useSession } from 'hooks'
import { logger } from 'lib'
import { useCallback } from 'react'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'

export enum AnalyticsEvent {
  UserInvited = 'User Invited',
  TeamCreated = 'Team Created',
  TeamUpdated = 'Team Updated',
  UserAccountUpdated = 'User Account Updated',
  BillingAccountViewed = 'Billing Account Viewed',
  SignedIn = 'Signed In',
  SignedOut = 'Signed Out',
  MarketplaceCategorySelected = 'Marketplace Category Selected',
  MarketplaceFilterSelected = 'Marketplace Filter Selected',
  ProductViewed = 'Product Viewed',
  IntegrationsViewed = 'Integrations Viewed',
  IntegrationSelected = 'Integration Selected',
  HandwritingStyleSelected = 'Handwriting Style Selected',
  OrderReportExported = 'Order Report Exported',
  CampaignCreated = 'Campaign Created',
  SignupViewed = 'Signup View',
  SignupComplete = 'Signup Complete',
  RegisterViewed = 'Register View',
  RegisterComplete = 'Register Complete',
  ContactsPageViewed = 'Viewed Contacts Page',
  CampaignsPageViewed = 'Viewed Campaigns Page',
  // Marketplace
  MarketplacePageClicked = 'Marketplace_Clicked',
  MarketplaceAllItemsPageViewed = 'Viewed All Items Page',
  MarketplaceMyItemsPageViewed = 'Viewed My Items Page',
  MarketplaceCollectionPageViewed = 'Viewed Collection Page',
  MarketplaceSendMyItemClicked = 'Send My Item Clicked',
  MarketplaceSendDirectMyItemClicked = 'Send Direct My Item Clicked',
  MarketplaceCreateMagicLinkMyItemClicked = 'Create MagicLink My Item Clicked',
  MarketplaceEditMyItemClicked = 'Edit My Item Clicked',
  MarketplaceViewMyItemClicked = 'View My Item Clicked',
  MarketplaceViewItemClicked = 'View Item Clicked',
  MarketplaceApproveItemClicked = 'Approve Item Clicked',
  // Events
  EventsPageViewed = 'Viewed Events Page',
  EventsAllItemsPageViewed = 'Viewed All Events Page',
  EventsMyEventsPageViewed = 'Viewed My Events Page',
  EventsMyEventClicked = 'My Event Clicked',
  EventsAllItemsEventClicked = 'Event Clicked',
  // Automations
  MagicLinkPageViewed = 'Viewed MagicLink Page',
  SubscriptionsPageViewed = 'Viewed Subscriptions Page',
  TriggersPageViewed = 'Viewed Triggers Page',
  // Reporting
  ReportingPageViewed = 'Viewed Reporting Page',
  ReportingOrderReportExportClicked = 'Order Report Export Clicked',
  // Extension - Taken from extension
  ExtensionContactAddButton = 'Extension Add Contact Button Clicked',
  ExtensionContactCreateButton = 'Extension Create Contact Button Clicked',
  ExtensionInjectedButton = 'Extension Injected Button Clicked',
  ExtensionCRMContactOpen = 'Extension CRM Contact Page Open',
  ExtensionLinkCopyButton = 'Extension Copy Link Button Clicked',
  ExtensionLinkAddButton = 'Extension Add Link Button Clicked',
  ExtensionLinkCreateButton = 'Extension Create Link Button Clicked',
  ExtensionSendItemButtonClicked = 'Extension Send Item Button Clicked',
  ExtensionSendFlowItemSelected = 'Extension Send Flow Item Selected',
  ExtensionSendFlowItemSearchFilter = 'Extension Send Flow Item Search Filter',
  ExtensionSendFlowSelectItemStep = 'Extension Send Flow Select Item Step',
  ExtensionSendFlowConfigurationStep = 'Extension Send Flow Configuration Step',
  ExtensionSendFlowCustomizeItemStep = 'Extension Send Flow Customize Item Step',
  ExtensionSendFlowReviewStep = 'Extension Send Flow Review Step',
  ExtensionSendFlowSendClicked = 'Extension Send Flow Send Clicked',
  ExtensionSendFlowNextClicked = 'Extension Send Flow Next Clicked',
  ExtensionSendFlowBackClicked = 'Extension Send Flow Back Clicked',
  ExtensionCampaignCreateButton = 'Extension Create Campaign Button Clicked',
  ExtensionCampaignCreated = 'Extension Campaign Created',
  ExtensionCampaignContactAdd = 'Extension Contact Added to Campaign',
  ExtensionCampaignContactRemove = 'Extension Contact Removed from Campaign',
  ExtensionLogin = 'Extension_Login',
  ExtensionLogout = 'Extension_Logout',
}

export enum AnalyticsEventV2 {
  Billing_ChangePaymentMethod = 'Billing_ChangePaymentMethod',
  Billing_AddCard = 'Billing_AddCard',
  Engage_Login = 'Engage_Login',
  Engage_Logout = 'Engage_Logout',
  Contacts_AddContact = 'Contacts_AddContact',
  // Send flow
  SendFlow_RequireApproval_Toggle = 'SendFlow_RequireApproval_Toggle',
  SendFlow_LandingPage_Toggle = 'SendFlow_LandingPage_Toggle',
  SendFlow_LandingPagePreview_Clicked = 'SendFlow_LandingPagePreview_Clicked',
  SendFlow_PhysicalCard_Toggle = 'SendFlow_PhysicalCard_Toggle',
  SendFlow_SpendAs_Selected = 'SendFlow_SpendAs_Selected',
  SendFlow_SendAs_Selected = 'SendFlow_SendAs_Selected',
  SendFlow_GiftEmailPreview_Clicked = 'SendFlow_GiftEmailPreview_Clicked',
  SendFlow_PersonalizedEmail_Clicked = 'SendFlow_PersonalizedEmail_Clicked',
  SendFlow_MagicLink_Clicked = 'SendFlow_MagicLink_Clicked',
  SendFlow_Direct_Clicked = 'SendFlow_Direct_Clicked',
  SendFlow_BulkSend_Clicked = 'SendFlow_BulkSend_Clicked',
  SendFlow_ConfirmAndSend_Clicked = 'SendFlow_ConfirmAndSend_Clicked',
  SendFlow_SaveDraft_Clicked = 'SendFlow_SaveDraft_Clicked',
  Product_SendThisItem_Clicked = 'Product_SendThisItem_Clicked',
  Marketplace_Search = 'Marketplace_Search',
  Product_RequestItem_Clicked = 'Product_RequestItem_Clicked',
  Product_ApproveItem_Clicked = 'Product_ApproveItem_Clicked',
  Product_SaveItem_Clicked = 'Product_SaveItem_Clicked',
  Product_AddToFavorites = 'Product_AddToFavorites',
  Concierge_LetsGetStarted_Clicked = 'Concierge_LetsGetStarted_Clicked',
  Concierge_RequestForm_Submitted = 'Concierge_RequestForm_Submitted',
  Marketplace_Category_Selected = 'Marketplace_Category_Selected',
  Marketplace_BestSellers_Selected = 'Marketplace_BestSellers_Selected',
  Marketplace_InternationalItem_Selected = 'Marketplace_InternationalItem_Selected',
  Marketplace_InternationalCountry_Selected = 'Marketplace_InternationalCountry_Selected',
  Marketplace_Event_Clicked = 'Marketplace_Event_Clicked',
  Marketplace_EventCategory_Clicked = 'Marketplace_EventCategory_Clicked',
  Marketplace_ApprovedItems_Clicked = 'Marketplace_ApprovedItems_Clicked',
  Marketplace_BrowseProducts_Clicked = 'Marketplace_BrowseProducts_Clicked',
  // Swag editor clickage
  Marketplace_SwagEditor = 'Marketplace_SwagEditor',
  NavBar_SwagEditor = 'NavBar_SwagEditor',
  // Extension
  ExtensionContactAddButton = 'Extension Add Contact Button Clicked',
  ExtensionInjectedButton = 'Extension Injected Button Clicked',
  ExtensionCRMContactOpen = 'Extension CRM Contact Page Open',
  ExtensionLinkCopyButton = 'Extension Copy Link Button Clicked',
  ExtensionLinkAddButton = 'Extension Add Link Button Clicked',
  ExtensionLinkCreateButton = 'Extension Create Link Button Clicked',
  ExtensionSendItemButtonClicked = 'Extension Send Item Button Clicked',
  ExtensionSendFlowItemSelected = 'Extension Send Flow Item Selected',
  ExtensionSendFlowItemSearchFilter = 'Extension Send Flow Item Search Filter',
  ExtensionSendFlowSelectItemStep = 'Extension Send Flow Select Item Step',
  ExtensionSendFlowSelectOptionStep = 'Extension Send Flow Select Option Step',
  ExtensionSendFlowConfigurationStep = 'Extension Send Flow Configuration Step',
  ExtensionSendFlowCustomizeItemStep = 'Extension Send Flow Customize Item Step',
  ExtensionSendFlowReviewStep = 'Extension Send Flow Review Step',
  ExtensionSendFlowSendClicked = 'Extension Send Flow Send Clicked',
  ExtensionSendFlowNextClicked = 'Extension Send Flow Next Clicked',
  ExtensionSendFlowBackClicked = 'Extension Send Flow Back Clicked',
  ExtensionLogin = 'Extension_Login',
  ExtensionLogout = 'Extension_Logout',
}

// can't import this
enum PostalSendMethod {
  Email = 'Email',
  Direct = 'Direct',
  Link = 'Link',
  BulkSend = 'BulkSend',
}

export const ANALYTICS_EVENT_V2: { [k: string]: AnalyticsEventV2 } = {
  [PostalSendMethod.BulkSend]: AnalyticsEventV2.SendFlow_BulkSend_Clicked,
  [PostalSendMethod.Direct]: AnalyticsEventV2.SendFlow_Direct_Clicked,
  [PostalSendMethod.Email]: AnalyticsEventV2.SendFlow_PersonalizedEmail_Clicked,
  [PostalSendMethod.Link]: AnalyticsEventV2.SendFlow_MagicLink_Clicked,
  [SendFlowStep.OrderPreview]: AnalyticsEventV2.SendFlow_ConfirmAndSend_Clicked,
}

export const getAnalyticsEventV2 = (k?: PostalSendMethod | SendFlowStep | null) => {
  return !!k ? ANALYTICS_EVENT_V2[k as string] : undefined
}

export const sendAnalyticsEvent = (event: AnalyticsEvent | AnalyticsEventV2, payload?: Record<string, any>) => {
  typeof window.analytics?.track === 'function' ? window.analytics?.track(event, payload || {}) : logger(event, payload)
}

export const getAnalyticsSessionInfo = (session: Record<string, any>) => ({
  accountId: session?.accountId,
  accountName: session?.accountName,
  email: session?.userName,
  firstName: session?.firstName,
  groupId: session?.accountId,
  lastName: session?.lastName,
  plan: session?.plan,
  product: session?.product,
  roles: session?.roles,
  teamId: session?.teamId,
  teamName: session?.teamName,
})

export interface UseAnalyticsEventProps {
  event: AnalyticsEvent | AnalyticsEventV2
  data?: Record<string, any>
  disabled?: boolean
}
export const useAnalyticsEvent = ({ event, data, disabled }: UseAnalyticsEventProps) => {
  const { session } = useSession()
  useDeepCompareEffectNoCheck(() => {
    const payload = {
      ...getAnalyticsSessionInfo(session),
      ...(data || {}),
    }
    if (!disabled) sendAnalyticsEvent(event, payload)
  }, [event, data, session, disabled])
}

export const useAnalyticsSend = () => {
  const { session } = useSession()
  const send = useCallback(
    ({ event, data }: Omit<UseAnalyticsEventProps, 'disabled'>) => {
      const payload = {
        ...getAnalyticsSessionInfo(session),
        ...(data || {}),
      }
      sendAnalyticsEvent(event, payload)
    },
    [session]
  )
  return send
}
