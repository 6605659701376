export interface PostalSendEventV2 {
  type:
    | 'BACK'
    | 'NEXT'
    | 'SET_NAME'
    | 'SET_DATE'
    | 'SET_CONTACTS'
    | 'SET_NEW_CONTACT'
    | 'SET_USE_DELIVERY_EMAIL'
    | 'SET_USE_PHYSICAL_MESSAGE'
    | 'SET_GIFT_MESSAGE'
    | 'SET_PHYSICAL_MESSAGE'
    | 'SET_USE_SAME_MESSAGE'
    | 'SET_POSTAL'
    | 'SET_VARIANT'
    | 'SET_PARENT_VARIANT'
    | 'SET_MAX_EXECUTIONS'
    | 'SET_MAX_CHARACTERS'
    | 'SET_ENABLED'
    | 'SET_DELAY'
    | 'SET_QUANTITY'
    | 'SET_EMAIL_SUBJECT_LINE'
    | 'SET_SEND_AS'
    | 'SET_MEETING_REQUEST'
    | 'SET_CUSTOMIZE_ITEM'
    | 'SET_FORM_FIELDS'
    | 'SET_LANDING_PAGE_HEADER_TEXT'
    | 'SET_LANDING_PAGE_BODY'
    | 'SET_LANDING_PAGE_INCLUDE_HEADSHOT'
    | 'SET_LANDING_PAGE_INCLUDE_SENDER_NAME'
    | 'SET_SPEND_AS_TEAM_ID'
    | 'SET_SPEND_AS_USER_ID'
    | 'SET_LINK_NEEDS_APPROVAL'
    | 'SET_SHIPPED_EMAIL'
    | 'SET_DELIVERED_EMAIL'
    | 'SET_SEND_METHOD'
    | 'SET_SHIP_TO_ADDRESS'
    | 'SET_SHIP_TO_ADDRESS_VERIFIED'
    | 'HIGHLIGHT_FIELD_WITH_ERROR'
  data?: any
}

/*
                 Note - Send type is once again immutable.

  Postal:   Postal was passed in - allow all send methods
  Link:     No contacts selected, requires max recipients and name - used for editing links only. link method only
  Campaign: This is a campaign no matter how many users selected - direct/email only
  Contact:  Contacts were passed in and should be static - direct/email only
  Retry:    Contact and Postal passed in and should be static - direct/email/bulk send only
  Trigger:  No contacts should be selected - direct/email only
*/
export enum PostalSendType {
  Campaign = 'Campaign',
  Contact = 'Contact',
  Postal = 'Postal',
  Retry = 'Retry',
  Trigger = 'Trigger',
  Link = 'Link',
  PlaybookStep = 'PlaybookStep',
}

export enum PostalSendMethod {
  Email = 'Email',
  Direct = 'Direct',
  Link = 'Link',
  BulkSend = 'BulkSend',
}

// POSTAL REVIEW

export const AUTOMATION_TYPES: Array<PostalSendType | undefined> = [
  PostalSendType.Link,
  PostalSendType.PlaybookStep,
  PostalSendType.Trigger,
]
export const SKIP_CONFIRM_TYPES: Array<PostalSendType | undefined> = [
  PostalSendType.PlaybookStep,
  PostalSendType.Trigger,
]
export const SEND_METHODS_WITH_CONTACTS_STEP: Array<PostalSendMethod | undefined> = [
  PostalSendMethod.Direct,
  PostalSendMethod.Email,
]

// EVENT NOTIFICATION EMAILS

// keys returned on preview data
export enum EventEmail {
  'EVENT_REGISTERED' = 'EVENT_REGISTERED',
  'EVENT_REMINDER_TOMORROW' = 'EVENT_REMINDER_TOMORROW',
  'EVENT_REMINDER_TODAY' = 'EVENT_REMINDER_TODAY',
  'EVENT_INVITE_EXPIRING' = 'EVENT_INVITE_EXPIRING',
  'EVENT_CANCELLED' = 'EVENT_CANCELLED',
  'EVENT_MEETING_LINK_CHANGED' = 'EVENT_MEETING_LINK_CHANGED',
  'EVENT_DATE_TIME_CHANGED' = 'EVENT_DATE_TIME_CHANGED',
  'EVENT_KIT_SHIPPED' = 'EVENT_KIT_SHIPPED',
  'EVENT_KIT_DELIVERED' = 'EVENT_KIT_DELIVERED',
}

//  map preview keys to magic event instant keys
export const eventEmailNotificationMap = {
  [EventEmail.EVENT_REGISTERED]: 'sendEmailConfirmation',
  [EventEmail.EVENT_REMINDER_TODAY]: 'sendReminderDayOf',
  [EventEmail.EVENT_INVITE_EXPIRING]: 'sendInviteExpiringAlert',
  [EventEmail.EVENT_REMINDER_TOMORROW]: 'sendReminderDayBefore',
  [EventEmail.EVENT_CANCELLED]: 'sendCancelledAlert',
  [EventEmail.EVENT_MEETING_LINK_CHANGED]: 'sendMeetingLinkChanged',
  [EventEmail.EVENT_DATE_TIME_CHANGED]: 'sendDateTimeChanged',
  [EventEmail.EVENT_KIT_SHIPPED]: 'sendShippedAlert',
  [EventEmail.EVENT_KIT_DELIVERED]: 'sendDeliveredAlert',
}

export type EventEmailType =
  | EventEmail.EVENT_REGISTERED
  | EventEmail.EVENT_REMINDER_TODAY
  | EventEmail.EVENT_INVITE_EXPIRING
  | EventEmail.EVENT_REMINDER_TOMORROW
  | EventEmail.EVENT_CANCELLED
  | EventEmail.EVENT_MEETING_LINK_CHANGED
  | EventEmail.EVENT_DATE_TIME_CHANGED
  | EventEmail.EVENT_KIT_SHIPPED
  | EventEmail.EVENT_KIT_DELIVERED
