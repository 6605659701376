import { cleanText, ZFormLabel, ZInput } from '@postal-io/postal-ui'
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { HorizontalList } from './HorizontalList'

const DEBOUNCE = 400

export interface QrCodeEditProps {
  settings: any
  onChange: (e: any) => void
}

export const QrCodeEdit: React.FC<QrCodeEditProps> = ({ settings = {}, onChange }) => {
  const [cache, setCache] = useState(settings)

  const debouncedCallback = useDebouncedCallback(
    (name: string, value: string) => onChange({ ...settings, [name]: value }),
    DEBOUNCE
  )

  useEffect(() => {
    setCache(settings)
  }, [settings])

  const handleText = (name: string, value = '') => {
    const string = cleanText(value)
    setCache({ ...settings, [name]: string })
    debouncedCallback(name, string)
  }

  return (
    <>
      <HorizontalList>
        <ZFormLabel htmlFor="value">URL</ZFormLabel>
        <ZInput
          id="value"
          name="value"
          value={cache.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleText('value', e.target.value)}
        />
      </HorizontalList>
    </>
  )
}
