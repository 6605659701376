import { useSession } from 'hooks'
import { compact } from 'lodash'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY
const ENVIRONMENT = process.env.REACT_APP_DEPLOY_ENV

export const isExcludedUser = (userName?: string) => {
  if (!userName) return true
  if (userName.includes('monitor@postal.io')) return true
  return false
}

export const Analytics = () => {
  if (!SEGMENT_KEY) return null
  return <AnalyticsLoader />
}

const AnalyticsLoader = () => {
  const { pathname } = useLocation()
  const { session } = useSession()
  const initRef = useRef(null)

  const isExtension = pathname.startsWith('/extension')

  useEffect(() => {
    if (!session?.userId || !session?.accountId) return
    if (initRef.current === session.userId) return

    initRef.current = session.userId
    window.analytics?.identify(session.userId, {
      email: session.userName,
      accountId: session.accountId,
      accountName: session.accountName,
      teamId: session.teamId,
      teamName: session.teamName,
      plan: session.plan,
      product: session.product,
      roles: session.roles,
      firstName: session.firstName,
      lastName: session.lastName,
      full_name: compact([session.firstName, session.lastName]).join(' '),
      environment: ENVIRONMENT,
    })

    window.analytics?.group(session.accountId, {
      accountId: session.accountId,
      accountName: session.accountName,
      name: session.accountName,
      plan: session.plan,
      product: session.product,
      environment: ENVIRONMENT,
    })

    return () => {
      window.analytics?.reset()
    }
  }, [
    session?.accountId,
    session?.accountName,
    session?.firstName,
    session?.lastName,
    session?.plan,
    session?.product,
    session?.roles,
    session?.teamId,
    session?.teamName,
    session?.userId,
    session?.userName,
  ])

  // track each page request when the route changes
  useEffect(() => {
    if (isExtension) return // Disabled PER https://postalio.atlassian.net/browse/CE-112
    if (!session?.sessionUuid) return
    if (!pathname) return
    if (isExcludedUser(session?.userName)) return

    window.analytics?.page({ sessionUuid: session?.sessionUuid })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return null
}
