import { useEffect, useState } from 'react'
import { useAcl } from '../../hooks'
import type { ExternalProvider } from './data'
import { ExternalProviders } from './data'

export const useProvider = (endpoint: string) => {
  const { aclCheck, isLoading } = useAcl()
  const [loading, setLoading] = useState(true)
  const [provider, setProvider] = useState<ExternalProvider>()
  useEffect(() => {
    if (isLoading) return
    const extProvider = ExternalProviders.find((p) => p.system === endpoint)
    if (
      (extProvider?.integration && aclCheck(extProvider.integration)) ||
      (extProvider?.workato && aclCheck(extProvider.workato))
    ) {
      setProvider(extProvider)
    }
    setLoading(false)
  }, [aclCheck, endpoint, isLoading])
  return { loading, provider }
}
