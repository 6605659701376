import { Grid, GridItem, useDisclosure } from '@chakra-ui/react'
import { ZButton, ZSidebar, ZSidebarBanner } from '@postal-io/postal-ui'
import { CenteredBox } from 'components/Common'
import { Handwriting } from 'components/Handwriting/Handwriting'
import { UserEmailIntegrations } from 'components/Profile/UserEmailIntegrations'
import { UserProfile } from 'components/User/UserProfile'
import { UserStats } from 'components/User/UserStats'
import { UserTeams } from 'components/User/UserTeams'
import { useAcl, useSession } from 'hooks'
import React from 'react'
import { MdOutlinePersonOutline } from 'react-icons/md'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

export const Profile: React.FC = () => {
  const {
    session: { userId },
  } = useSession()
  // const editAccounts = useDisclosure()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { hasPermission } = useAcl()

  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        p={0}
        m={0}
      >
        <ZSidebarBanner
          title="Profile"
          actions={
            <ZButton
              variant="link"
              color="atomicBlue.400"
              leftIcon={<MdOutlinePersonOutline size="24px" />}
              size="sm"
              height="21px"
              onClick={onOpen}
            >
              Update Profile
            </ZButton>
          }
        />
        <Grid
          gap={8}
          templateColumns={{
            base: 'minmax(0, 1fr)',
            xl: 'repeat(4, minmax(0, 1fr))',
          }}
        >
          {hasPermission('postals.send') && (
            <GridItem colSpan={{ xl: 2 }}>
              <UserStats userId={userId} />
            </GridItem>
          )}
          <GridItem
            colSpan={{ xl: 2 }}
            rowSpan={{ xl: 2 }}
          >
            <UserProfile
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              h="100%"
              userId={userId}
            />
          </GridItem>

          <GridItem
            colSpan={{ xl: 2 }}
            {...(hasPermission('postals.send') ? {} : { rowSpan: 2 })}
          >
            <UserEmailIntegrations />
          </GridItem>

          <GridItem colSpan={{ xl: 4 }}>
            <UserTeams
              userId={userId}
              h="100%"
            />
          </GridItem>

          {hasPermission('handwriting.read') && (
            <GridItem colSpan={{ xl: 4 }}>
              <Handwriting />
            </GridItem>
          )}
        </Grid>
      </ZSidebar>
    </CenteredBox>
  )
}
