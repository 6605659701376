import { Link, Text } from '@chakra-ui/react'
import { Fragment, useCallback, useMemo } from 'react'

const SPLIT_REGEXP = /({\s?[_a-zA-Z]+\s?})/
const KEY_REGEXP = /{\s?([_a-zA-Z]+)\s?}/

interface GetUrlProps {
  dataId: string
  dataType: string
}

const getUrl = ({ dataId, dataType }: GetUrlProps) => {
  if (!dataId || !dataType) {
    return ''
  }
  switch (dataType) {
    case 'CAMPAIGN':
      return `/campaigns/${dataId}`
    case 'PRODUCT':
      return `/items/marketplace/${dataId}`
    case 'CONTACT':
      return `/contacts/${dataId}`
    case 'USER':
      return `/users/${dataId}`
    case 'POSTAL':
      return `/items/postals/${dataId}`
    case 'FULFILLMENT':
      return `/reporting/orders/${dataId}`
    case 'EVENT':
      return `/events/postals/${dataId}`
    case 'COLLECTION':
      return `/items/collections/${dataId}`
    case 'MAGIC_LINK':
      return `/links/${dataId}`
    case 'BILLING_ACCOUNT':
      return `/billing/accounts/${dataId}`
    default:
      return ''
  }
}

export interface ExtAccountTimelineTextProps {
  message: string
  count?: number | null
  callouts?: any[] | null
}

export const ExtAccountTimelineText = ({ message = '', count, callouts }: ExtAccountTimelineTextProps) => {
  const calloutMap = useMemo(() => {
    return callouts?.reduce((obj, item) => {
      obj[item.targetText] = item
      return obj
    }, {})
  }, [callouts])

  const parseElement = useCallback(
    (str: string) => {
      const matches = str.match(KEY_REGEXP)
      if (!matches) return str
      const key = matches[1]
      const { dataId, dataType, text } = calloutMap?.[key] ?? {}

      const url = getUrl({ dataId, dataType })

      return url ? (
        <Link
          fontSize="inherit"
          display="inline"
          href={url}
          isExternal
        >
          {text}
        </Link>
      ) : (
        <Text
          fontSize="inherit"
          fontWeight="bold"
          display="inline"
        >
          {text}
        </Text>
      )
    },
    // eslint-disable-next-line
    [calloutMap]
  )

  return (
    <Text
      fontSize="body-md"
      display="block"
      gap={1}
      w="100%"
    >
      {message?.split(SPLIT_REGEXP).map((element, idx) => {
        return <Fragment key={idx}>{parseElement(element)}</Fragment>
      })}
      {count && count > 1 && ` ${count - 1} similar messages found.`}
    </Text>
  )
}
