import { Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiActionMenu,
  UiButton,
  UiConfirm,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerFooterButtons,
  UiDrawerHeader,
  UiDrawerOverlay,
  UiFormControl,
  UiFormLabel,
  UiInput,
  UiSubNavbar,
  useAlerts,
} from '@postal-io/postal-ui'
import type { ContactList } from 'api'
import { DeleteContactListDocument, UpdateContactListDocument } from 'api'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'

interface State {
  name: string
}

interface ContactListEditProps {
  isOpen: boolean
  onClose: () => void
  onUpdate?: (e?: any) => void
  list: ContactList
}

export const ContactListEdit: React.FC<ContactListEditProps> = ({ isOpen, onClose, onUpdate, list }) => {
  const [form, setForm] = useImmer<State>({ name: list.name })

  const confirmRemove = useDisclosure()

  const navigate = useNavigate()
  const deleteList = useGraphqlMutation(DeleteContactListDocument)
  const updateList = useGraphqlMutation(UpdateContactListDocument)
  const Alert = useAlerts()

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      const { data }: any = await updateList.mutateAsync({ id: list.id, data: form })
      Alert.success('List Updated')
      navigate(`/contacts/list/${list.id}`)
      onUpdate && onUpdate(data?.updateList)
      onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleRemove = async () => {
    try {
      await deleteList.mutateAsync({ id: list.id })
      Alert.warning('List Deleted')
      navigate(`/contacts`)
      onUpdate && onUpdate()
      onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const actionItems = [
    {
      title: 'Delete List',
      onClick: confirmRemove.onOpen,
    },
  ]

  const isLoading = updateList.isLoading || deleteList.isLoading

  return (
    <>
      <UiDrawer
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
      >
        <UiDrawerOverlay />
        <form onSubmit={onSubmit}>
          <UiDrawerContent>
            <UiDrawerCloseButton />
            <UiDrawerHeader>Edit List Details</UiDrawerHeader>
            <UiSubNavbar right={<UiActionMenu actionItems={actionItems} />} />
            <UiDrawerBody>
              <UiFormControl id="name">
                <UiFormLabel>Name</UiFormLabel>
                <UiInput
                  name="name"
                  isRequired
                  value={form.name}
                  onChange={(e) => setForm((draft) => void (draft.name = e.target.value))}
                  type="text"
                />
              </UiFormControl>
            </UiDrawerBody>
            <UiDrawerFooter>
              <UiDrawerFooterButtons>
                <UiButton
                  colorScheme="gray"
                  onClick={onClose}
                  isDisabled={isLoading}
                  isLoading={isLoading}
                >
                  Cancel
                </UiButton>
                <UiButton
                  type="submit"
                  isDisabled={isLoading}
                  isLoading={isLoading}
                >
                  Update List
                </UiButton>
              </UiDrawerFooterButtons>
            </UiDrawerFooter>
          </UiDrawerContent>
        </form>
      </UiDrawer>
      <UiConfirm
        title="Remove Contact List"
        isOpen={confirmRemove.isOpen}
        onConfirm={handleRemove}
        onClose={confirmRemove.onClose}
        isLoading={deleteList.isLoading}
        buttonColor="red"
        buttonText="Remove"
      >
        <Text>
          Are you sure you want to <strong>Remove</strong> the <strong>{list.name}</strong> contact list?
        </Text>
        <Text mt={2}>NOTE: This action does not delete any contacts.</Text>
      </UiConfirm>
    </>
  )
}
