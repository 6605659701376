import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiLoading } from '@postal-io/postal-ui'
import { GetContactDocument } from 'api'
import { useExtension } from 'hooks'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ExtContactCreate } from './ExtContactCreate'
import { ExtContactView } from './ExtContactView'

export const ExtContact: React.FC = () => {
  const { contactId, setContactId } = useExtension()
  const params = useParams() as any

  const { data, isLoading, error } = useGraphqlQuery(
    GetContactDocument,
    { id: params.contactId },
    { useErrorBoundary: false, enabled: !!params.contactId }
  )
  const contact = data?.getContact

  useEffect(() => {
    if (contact?.id && contact?.id !== contactId) setContactId(contact?.id)
  }, [contact?.id, contactId, setContactId])

  if (isLoading) return <UiLoading />

  if (!contact || !!error) return <ExtContactCreate />

  return <ExtContactView contact={contact} />
}
