import { Flex } from '@chakra-ui/react'
import { ZText } from '@postal-io/postal-ui'
import type { Budget } from 'api'
import { BudgetHistoryType } from 'api'
import React from 'react'
import { BudgetCompact } from './BudgetCompact'

export interface BudgetCompareV2Props {
  oldBudget?: Budget | null
  newBudget?: Budget | null
  type?: BudgetHistoryType | null
}

export const BudgetCompare: React.FC<BudgetCompareV2Props> = ({ oldBudget, newBudget, type }) => {
  if (type === BudgetHistoryType.Changed) {
    return (
      <Flex
        direction="column"
        alignItems="center"
      >
        <BudgetCompact
          budget={oldBudget as Budget}
          color="atomicGray.500"
        />
        <BudgetCompact budget={newBudget as Budget} />
      </Flex>
    )
  } else {
    return <ZText>-</ZText>
  }
}
