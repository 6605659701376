import type { UiResponsiveCardProps } from '@postal-io/postal-ui'
import {
  UiResponsiveCard,
  UiResponsiveCardBody,
  UiResponsiveCardEffect,
  UiResponsiveCardImage,
  UiResponsiveCardTitle,
} from '@postal-io/postal-ui'
import React from 'react'

interface CategoryCardProps extends Omit<UiResponsiveCardProps, 'onClick'> {
  name: string
  imageUrl: string
  onClick: (name: string) => void
}

export const CategoryCard: React.FC<CategoryCardProps> = ({ name, imageUrl, onClick, ...rest }) => {
  const handleClick = () => onClick(name)

  return (
    <UiResponsiveCard
      isLoading={!name}
      {...rest}
    >
      <UiResponsiveCardEffect>
        <UiResponsiveCardBody
          onClick={handleClick}
          borderRadius="0px"
          borderWidth="3px"
          boxShadow="none"
        >
          <UiResponsiveCardImage src={imageUrl} />
        </UiResponsiveCardBody>
      </UiResponsiveCardEffect>
      <UiResponsiveCardTitle
        onClick={handleClick}
        mt={1}
        fontSize="lg"
        textAlign="center"
        color="gray.700"
      >
        {name}
      </UiResponsiveCardTitle>
    </UiResponsiveCard>
  )
}
