import { Flex } from '@chakra-ui/react'
import { UiDateTime, ZText } from '@postal-io/postal-ui'
import React from 'react'
import { MdCalendarViewDay } from 'react-icons/md'
import { MagicEventStatus } from '../../../api'

export const EventsApprovedPostalEventRegistration: React.FC<{
  lastRegistrationDateTime?: Date | null
  status: MagicEventStatus | null
}> = ({ lastRegistrationDateTime, status }) => {
  return (
    <Flex
      alignItems="flex-start"
      gap={2}
    >
      <MdCalendarViewDay size="20px" />
      {status === MagicEventStatus.RegistrationClosed ? (
        <ZText
          whiteSpace="nowrap"
          variant="bold"
        >
          Registration is closed
        </ZText>
      ) : lastRegistrationDateTime ? (
        <ZText as="span">
          Invitations expire on{' '}
          <UiDateTime
            date={lastRegistrationDateTime || undefined}
            format={{
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            }}
          />
          {' at '}
          <UiDateTime
            date={lastRegistrationDateTime || undefined}
            format={{
              hour: 'numeric',
              minute: 'numeric',
              timeZoneName: 'short',
            }}
          />
        </ZText>
      ) : (
        <ZText>TBD</ZText>
      )}
    </Flex>
  )
}
