import { Box, Flex } from '@chakra-ui/react'
import { ZMoney, ZText } from '@postal-io/postal-ui'
import type { Budget } from 'api'
import { BudgetHistoryType, BudgetMode } from 'api'
import { isNumber } from 'lodash'
import React from 'react'

export interface BudgetRemainingCompareV2Props {
  oldRemaining?: number | null
  amount?: number | null
  type?: BudgetHistoryType | null
  currentBudget?: Budget | null
}

export const BudgetRemainingCompare: React.FC<BudgetRemainingCompareV2Props> = ({
  oldRemaining,
  amount,
  type,
  currentBudget,
}) => {
  let newRemaining = 0
  if (isNumber(oldRemaining) && isNumber(amount)) {
    if (type === BudgetHistoryType.RemainingIncreased) {
      newRemaining = oldRemaining + amount
    } else {
      newRemaining = oldRemaining - amount
    }
  }

  if (type === BudgetHistoryType.RemainingIncreased || type === BudgetHistoryType.RemainingReduced) {
    return (
      <Flex direction="column">
        <Box>
          {currentBudget?.mode === BudgetMode.Unlimited ? (
            <ZText
              color="atomicGray.500"
              textDecoration="line-through"
            >
              ∞
            </ZText>
          ) : (
            <ZMoney
              cents={oldRemaining}
              color="atomicGray.500"
              textDecoration="line-through"
            />
          )}
        </Box>
        <Box>{currentBudget?.mode === BudgetMode.Unlimited ? <ZText>∞</ZText> : <ZMoney cents={newRemaining} />}</Box>
      </Flex>
    )
  } else if (type === BudgetHistoryType.Reset) {
    return <ZMoney cents={currentBudget?.amount} />
  } else {
    return <ZText>-</ZText>
  }
}
