import { Box, Flex, Grid, Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  omitDeep,
  sanitize,
  UiDangerous,
  UiIconParticipants,
  useAlertError,
  useAlerts,
  ZCard,
  ZCardBody,
  ZHeading,
  ZText,
} from '@postal-io/postal-ui'
import { NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { PageTitle, POSTAL_INVALIDATIONS, useApprovedPostalVersion, useBackgroundQueue, useSession } from 'hooks'
import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import type { ApprovedPostalUpdateInput, MarketplaceProduct } from '../../api'
import { CreateApprovedPostalDocument, GetMarketplaceProductDocument, Status } from '../../api'
import { CenteredBox } from '../Common/CenteredBox'
import { EventDuration, EventType } from './EventInfo'
import { EventInitiatedModal } from './EventInitiatedModal'
import { EventsBanner } from './EventsBanner'
import { EventsMarketplaceProductEventForm } from './EventsMarketplaceProductEventForm'

export const EventsMarketplaceProductPage: React.FC = () => {
  const transform = useApprovedPostalVersion()
  const { session } = useSession()
  const [approvedPostalId, setApprovedPostalId] = useState<string>('')
  const { marketplaceProductId } = useParams() as any

  const loadingModal = useDisclosure()
  const eventdialog = useDisclosure()

  const Alert = useAlerts()

  const marketplaceQuery = useGraphqlQuery(
    GetMarketplaceProductDocument,
    { id: marketplaceProductId },
    { enabled: !!marketplaceProductId }
  )

  const product = useMemo(
    () => marketplaceQuery.data?.getMarketplaceProduct as MarketplaceProduct,
    [marketplaceQuery.data?.getMarketplaceProduct]
  )
  useAlertError(marketplaceQuery.error)

  const { queue, invalidate } = useBackgroundQueue()

  // add delayed invalidation because ES doesn't sync in realtime
  const createPostal = useGraphqlMutation(CreateApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.createApprovedPostal.previewGenerationTask)
      invalidate(POSTAL_INVALIDATIONS)
    },
  })

  const navigate = useNavigate()

  const handleEventClick = () => {
    approvedPostalId && navigate(`/events/postals/${approvedPostalId}`)
  }

  const onUpdate = async (data: ApprovedPostalUpdateInput) => {
    if (!product) return
    try {
      loadingModal.onOpen()
      const res = await createPostal.mutateAsync({
        marketplaceProductId: product.id,
        data: {
          name: data.name,
          displayName: data.displayName || data.name,
          description: data.description ? sanitize(data.description) : undefined,
          status: Status.Disabled,
          designTemplate: data.designTemplate ? omitDeep(data.designTemplate, 'id') : undefined,
          // If we have a teamId in session, it means we are a team admin
          // and we will want to lock down this event to that team
          teamIds: !!session.teamId ? [session.teamId] : data.teamIds,
          event: data.event,
          attribution: data.attribution,
          ...transform(product.category, {
            items: data.variants?.map((v) => ({ marketplaceProductId: product.id, variant: v })),
            variants: data.variants?.filter(Boolean),
          }),
          eventFeeSettings: data?.eventFeeSettings,
        },
      })
      const approvedPostalResponse = res.createApprovedPostal?.postal
      Alert.success(`New event created`)
      setApprovedPostalId(approvedPostalResponse.id)
      eventdialog.onOpen()
      loadingModal.onClose()
    } catch (err) {
      loadingModal.onClose()
      Alert.error(err)
    }
  }

  const { eventDetails } = product ?? {}
  const { eventLengthMinutes, minimumAttendees, maximumAttendees, requirements } = eventDetails ?? {}

  return (
    <>
      <PageTitle
        title={product?.name}
        section="Marketplace Event"
      />
      <SecondaryNavbar
        left={
          <NavbarBackButton
            onClick={() => navigate('/events/marketplace')}
            label="Back to All Events"
          />
        }
        header="Event Information"
      />
      <CenteredBox
        pt={8}
        isLoaded
      >
        <Grid
          w="100%"
          templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
          gap={8}
        >
          <ZCard
            variant="form"
            overflow="hidden"
          >
            <EventsBanner postal={product} />
            <ZCardBody>
              {product && (
                <Stack
                  spacing={8}
                  pb={8}
                >
                  <Box>
                    <ZHeading
                      as="h1"
                      size="h5"
                    >
                      {product.name}
                    </ZHeading>

                    <ZText
                      mt={4}
                      whiteSpace="break-spaces"
                      color="atomicGray.500"
                    >
                      <UiDangerous html={product.description} />
                    </ZText>
                  </Box>
                  <Box>
                    <ZHeading
                      as="h2"
                      mb={4}
                      size="h6"
                    >
                      Event Information
                    </ZHeading>
                    <Box>
                      <Grid
                        templateColumns="repeat(4, 1fr)"
                        gap={4}
                      >
                        <EventDuration duration={eventLengthMinutes} />
                        <Flex alignItems="flex-start">
                          <UiIconParticipants
                            w="20px"
                            h="20px"
                            color="gray.800"
                            mr={6}
                          />
                          <ZText whiteSpace="nowrap">
                            {(maximumAttendees || 0) > 0
                              ? `Up to ${maximumAttendees} participants`
                              : 'Unlimited participants'}
                            {(minimumAttendees || 0) > 0 && (
                              <>
                                <br />
                                minimum of {minimumAttendees}
                              </>
                            )}
                          </ZText>
                        </Flex>
                        {!!requirements?.length && <EventType requirements={requirements} />}
                      </Grid>
                    </Box>
                  </Box>
                </Stack>
              )}
            </ZCardBody>
          </ZCard>
          {product && (
            <EventsMarketplaceProductEventForm
              product={product}
              onSubmit={onUpdate}
            />
          )}
        </Grid>
      </CenteredBox>
      {approvedPostalId && eventdialog.isOpen && (
        <EventInitiatedModal
          title="Thanks for reaching out!"
          isOpen={eventdialog.isOpen}
          onClose={handleEventClick}
        />
      )}
    </>
  )
}
