import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import { useGraphqlFilter, ZCard, ZCardBody, ZCardDivider } from '@postal-io/postal-ui'
import { STATIC_VARIABLES } from 'components/MultiSelectContacts/data'
import { PostalSendMethodHeader } from 'components/PostalSend/PostalSendMethodHeader'
import type { PostalSendContext } from 'components/PostalSend/usePostalSend'
import { usePostalSendFieldErrors } from 'components/PostalSend/usePostalSendFieldErrors'
import type { PropsWithChildren } from 'react'
import React from 'react'
import type { SearchableContact, SearchableContactFilterInput, SearchContactsV2QueryVariables } from '../../api'
import { defaultSort, qSort, TRANSFORMS } from '../Contacts/ContactsData'
import { PostalSendMultiSelectContactsTable } from '../MultiSelectContacts/PostalSendMultiSelectContactsTable'
import type { MultiSelectFilter, UseMultiSelect } from '../MultiSelectContacts/useMultiSelect'

export interface MultiSelectContactsState {
  items: SearchableContact[]
  filters: MultiSelectFilter<SearchableContactFilterInput>[]
  orfilters: SearchableContactFilterInput[]
  totalRecords: number
}
export interface PostalSelectContactsV2Props extends BoxProps {
  initialItems?: SearchableContact[]
  initialFilters?: MultiSelectFilter<SearchableContactFilterInput>[]
  state: UseMultiSelect<SearchableContact, SearchableContactFilterInput>
  context: PostalSendContext
}

export const PostalSelectContactsV2: React.FC<PropsWithChildren<PostalSelectContactsV2Props>> = ({
  initialItems,
  initialFilters,
  context,
  state,
  ...rest
}) => {
  // GRAPHQL FILTERING
  const graphqlFilter = useGraphqlFilter<SearchContactsV2QueryVariables>({
    transforms: TRANSFORMS,
    debounce: 400,
    staticVariables: STATIC_VARIABLES,
  })

  // SEARCH HELPER
  const handleSearch = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target
    graphqlFilter.setFilter({ key: 'q', value })
    graphqlFilter.setOrderBy(value ? qSort : defaultSort)
  }

  // ERROR HANDLING
  const { ref: inputRef, animation } = usePostalSendFieldErrors({ context, field: 'contacts' })

  return (
    <Box {...rest}>
      <ZCard
        variant="form"
        pt={4}
        borderWidth={{ base: 0, md: 1 }}
      >
        <ZCardBody>
          <PostalSendMethodHeader
            mx={0}
            context={context}
          />
          <ZCardDivider />
          <PostalSendMultiSelectContactsTable
            {...{ inputRef, animation }}
            filter={graphqlFilter.filter}
            variables={graphqlFilter.variables}
            state={state}
            graphqlFilter={graphqlFilter}
            onSearch={handleSearch}
            showSelectedCount={false}
          />
        </ZCardBody>
      </ZCard>
    </Box>
  )
}
