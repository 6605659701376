import { Box, Flex, Grid, Image } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiSeparatorProps } from '@postal-io/postal-ui'
import { UiSeparator, UiTruncate, useAlertError, ZHeading, ZText } from '@postal-io/postal-ui'
import type { ApprovedPostalAnalytics } from 'api'
import { DataObjectType, Granularity, GroupBy, PostalAnalyticsV2Document, SortBy } from 'api'
import { useAssets } from 'hooks'
import { useMemo } from 'react'
import { NoDataOverlay } from '../NoDataOverlay'
import type { PlaceholderPostalAnalyticsV2 } from './data'
import { MetricV2, PLACEHOLDER_POSTAL_ANALYTICS_V2 } from './data'

interface PopularSendsOverallColumnV2Props extends UiSeparatorProps {
  startDate: Date
  endDate: Date
  teamIds?: string[]
  userIds?: string[]
}

export const PopularSendsOverallColumn: React.FC<PopularSendsOverallColumnV2Props> = ({
  startDate,
  endDate,
  teamIds,
  userIds,
  ...rest
}) => {
  const overallAcceptedAnalyticsQuery = useGraphqlQuery(
    PostalAnalyticsV2Document,
    {
      config: {
        startDate,
        endDate,
        teamIds,
        userIds,
        granularity: Granularity.All,
        type: DataObjectType.Postal,
        sortBy: SortBy.AcceptedDesc,
        groupBy: GroupBy.Postal,
        limit: 5,
      },
    },
    { keepPreviousData: true }
  )
  const overallAcceptedAnalytics = useMemo(
    () => overallAcceptedAnalyticsQuery?.data?.postalAnalyticsV2 ?? [],
    [overallAcceptedAnalyticsQuery]
  )
  useAlertError(overallAcceptedAnalyticsQuery.error)

  const showPlaceholder = useMemo(() => {
    return !overallAcceptedAnalytics.reduce((sum, cur) => sum + (cur?.accepted || 0), 0)
  }, [overallAcceptedAnalytics])

  const analytics = useMemo(() => {
    return showPlaceholder ? PLACEHOLDER_POSTAL_ANALYTICS_V2[MetricV2.OverallAccepted] : overallAcceptedAnalytics
  }, [overallAcceptedAnalytics, showPlaceholder])

  return (
    <Box position="relative">
      {showPlaceholder && <NoDataOverlay />}
      <UiSeparator
        separator={
          <Box
            h="1px"
            bg="gray.200"
          />
        }
        {...rest}
      >
        {analytics.map((analytics, idx) => (
          <PopularSendsItemV2
            key={idx}
            analytics={analytics}
          />
        ))}
      </UiSeparator>
    </Box>
  )
}

interface PopularSendsItemV2Props {
  analytics: ApprovedPostalAnalytics | PlaceholderPostalAnalyticsV2
}

export const PopularSendsItemV2: React.FC<PopularSendsItemV2Props> = ({ analytics }) => {
  const { accepted, name, placeholderImage, previewImageUrl } = (analytics ?? {}) as any

  const { assetUrlSrc } = useAssets()
  const { src, fallbackSrc } = assetUrlSrc(previewImageUrl, { fit: 'crop', crop: 'edges', w: 80, h: 80 })

  return (
    <Grid
      templateColumns="80px 3fr"
      gap={4}
      py={4}
    >
      <Box
        borderRadius="postal"
        borderWidth="1px"
        borderColor="atomicGray.300"
        boxSizing="border-box"
        overflow="hidden"
        display="flex"
        justifyContent="center"
        alignItems="center"
        h="80px"
      >
        <Image
          src={placeholderImage || src}
          fallbackSrc={fallbackSrc}
          objectFit="fill"
        />
      </Box>
      <Box>
        <ZText
          color="atomicGray.800"
          fontWeight="bold"
        >
          <UiTruncate
            text={name as string}
            length={src ? 33 : 55}
          />
        </ZText>
        <Flex
          justifyContent="space-between"
          pt={2}
        >
          <Box>
            <ZText
              color="atomicGray.500"
              fontSize="sm"
            >
              Accepted
            </ZText>
            <ZHeading
              fontSize="xl"
              fontWeight="normal"
            >
              {accepted || 0}
            </ZHeading>
          </Box>
        </Flex>
      </Box>
    </Grid>
  )
}
