import { Text } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import { FontWeight, UiButton, UiCard } from '@postal-io/postal-ui'
import React, { useMemo } from 'react'
import { EventLimitCheckDocument, GetAccountDocument, Granularity } from '../../api'
import { useAcl, useHelp } from '../../hooks'

export const EventsMarketplaceProductSidebarBanner: React.FC<UiCardProps> = ({ ...rest }) => {
  const { aclCheck } = useAcl()
  const canCreate = aclCheck({ module: 'events.create' })

  const eventDate = useMemo(() => new Date(), [])
  const getAccountQuery = useGraphqlQuery(GetAccountDocument, undefined, { enabled: !!canCreate })
  const account = useMemo(() => getAccountQuery.data?.getAccount, [getAccountQuery.data?.getAccount])

  const eventLimitQuery = useGraphqlQuery(EventLimitCheckDocument, { eventDate }, { enabled: !!canCreate })
  const limits = useMemo(() => eventLimitQuery?.data?.eventLimitCheck, [eventLimitQuery?.data?.eventLimitCheck])

  const content = useMemo(() => {
    if (account?.trialSubscription) {
      return <Text textAlign="center">Events aren’t available during trial</Text>
    }

    if (limits?.eventLimitTimeFrame === Granularity.All && limits?.eventLimit > -1) {
      const eventsLeft = Math.max(limits.eventLimit - limits.eventLimitUsed, 0)
      return (
        <Text textAlign="center">
          You have {eventsLeft} out of {limits.eventLimit} events remaining
        </Text>
      )
    }
  }, [account?.trialSubscription, limits?.eventLimit, limits?.eventLimitTimeFrame, limits?.eventLimitUsed])

  const { sendMessage } = useHelp()

  if (!content || !canCreate) return null

  return (
    <UiCard
      mb={4}
      color="white"
      bg="tertiary.500"
      p={4}
      borderWidth="4px"
      borderColor="tertiary.100"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      fontWeight={FontWeight.Bold}
      {...rest}
    >
      {content}
      <UiButton
        variant="outline"
        borderColor="white"
        borderWidth="1px"
        borderRadius="md"
        color="white"
        mt={4}
        size="sm"
        _hover={{ bg: 'tertiary.500' }}
        onClick={() => sendMessage(`I'd like to sign up for events.`)}
      >
        Contact Sales
      </UiButton>
    </UiCard>
  )
}
