import { isEmptyValue } from '@postal-io/postal-ui'
import { dequal } from 'dequal'
import { useMe, useSession } from 'hooks'
import { some } from 'lodash'
import { useMemo } from 'react'
import type { PostalSendContext } from './usePostalSend'
import { PostalSendType, SendAsType } from './usePostalSend'

const discardFalseyValues = (value: any) => (isEmptyValue(value) || !value ? undefined : value)

const isChanged = (valueA: any, valueB: any) => discardFalseyValues(valueA) !== discardFalseyValues(valueB)

export function usePostalChangeDetection(context: PostalSendContext) {
  const { me } = useMe()
  const { session } = useSession()

  const hasChanges = useMemo(() => {
    // TODO: sort out retry change detection
    if (context.type === PostalSendType.Retry) return false

    // flatten this for better legibility
    const draft = context.draft && {
      ...context.draft,
      ...context.draft?.savedSendCampaignInfo,
      ...context.draft?.commonSendProperties,
      ...context.draft?.savedSendMagicLinkInfo,
    }

    const original = context.link ?? draft ?? ({} as any)

    const changes = [
      // isChanged(context.maxExecutions ?? 1, original?.maxExecutions ?? 1),
      isChanged(context.giftMessage, original?.giftMessage),
      isChanged(context.emailSubjectLine, original?.emailSubjectLine),
      isChanged(context.landingPageHeaderText, original?.landingPageCustomization?.headerText),
      isChanged(context.landingPageBody, original?.landingPageCustomization?.body),
      isChanged(context.sendAsType === SendAsType.ContactOwner, original?.sendAsContactOwner),
      isChanged(context.sendAsUser, original?.sendAsUser),
      isChanged(context.spendAsUserId, original?.spendAs?.userId ?? me?.id),
      isChanged(context.spendAsTeamId, original?.spendAs?.teamId ?? session.teamId),
      isChanged(context.contacts?.orfilters?.length, original?.contactSearchFilters?.length),
      isChanged(context.contacts?.orfilters?.[0]?.id?.in?.length, original?.contactSearchFilters?.[0]?.id?.in?.length),
      draft && isChanged(context.name, original?.name),
      draft && !dequal(context.formFieldList, original?.formFieldList),
      draft && isChanged(context.postal?.id, original?.approvedPostalId),
      draft && isChanged(context.variant?.id, original?.variantId),
    ]
    // console.log('changes', changes)
    // console.log('orfilters', discardFalseyValues(context.contacts?.orfilters?.length))
    // console.log('saved orfilters', discardFalseyValues(draft?.savedSendCampaignInfo?.contactSearchFilters?.length))
    return some(changes)
  }, [context, me?.id, session?.teamId])

  return hasChanges
}
