import { ButtonGroup, ModalFooter } from '@chakra-ui/react'
import {
  ZButton,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import React from 'react'

interface EventInitiatedModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
}

export const EventInitiatedModal: React.FC<EventInitiatedModalProps> = ({ isOpen, onClose, title }) => {
  return (
    <ZModal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ZModalOverlay />
      <ZModalContent>
        <ZModalCloseButton />
        <ZModalHeader>{title}</ZModalHeader>
        <ZModalBody>
          <ZText
            size="lg"
            mb={4}
          >
            We have set up a{' '}
            <ZLink
              size="lg"
              onClick={onClose}
            >
              temporary event
            </ZLink>{' '}
            here.
          </ZText>
          <ZText size="lg">
            Once approved, we will activate the event and you are then free to add a meeting link and invite people.
          </ZText>
        </ZModalBody>
        <ModalFooter>
          <ButtonGroup
            justifyContent="right"
            w="100%"
          >
            <ZButton
              colorScheme="atomicGray"
              variant="ghost"
              onClick={onClose}
            >
              Close
            </ZButton>
          </ButtonGroup>
        </ModalFooter>
      </ZModalContent>
    </ZModal>
  )
}
