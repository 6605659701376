import { Flex, SimpleGrid } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import { humanize, UiDate, UiToggle, useAlerts, ZCard, ZCardHeader } from '@postal-io/postal-ui'
import type { IntegrationTrigger } from 'api'
import { CreateUpdateIntegrationTriggerDocument, Status, TriggerAction } from 'api'
import { UserLinkLink } from 'components/Common'
import { recipeNameMap } from 'components/Integrations'
import React, { useEffect, useState } from 'react'
import { SystemLink } from '../Contact'
import { TriggerDetailsFilters } from './TriggerDetailsFilters'
import { TriggerDetailsProgram } from './TriggerDetailsProgram'
import { TriggerDetailsRow } from './TriggerDetailsRow'
import { TriggerDetailsSequence } from './TriggerDetailsSequence'

export interface TriggerDetailsV2Props extends UiCardProps {
  trigger?: IntegrationTrigger
}

export const TriggerDetails: React.FC<TriggerDetailsV2Props> = ({ trigger, ...rest }) => {
  const updateTrigger = useGraphqlMutation(CreateUpdateIntegrationTriggerDocument)
  const Alert = useAlerts()
  const [status, setStatus] = useState<Status>()

  useEffect(() => {
    if (trigger?.status) setStatus(trigger?.status)
  }, [trigger?.status])

  const handleStatus = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!trigger) return
    const newStatus = e.target.checked ? Status.Active : Status.Disabled
    setStatus(newStatus)
    const { hitCount, created, userId, userLink, ...rest } = trigger
    try {
      await updateTrigger.mutateAsync({ data: { ...rest, status: newStatus } })
      newStatus === Status.Active ? Alert.success('Trigger is enabled') : Alert.warning('Trigger is disabled')
    } catch (err) {
      Alert.error(err)
    }
  }
  const displayAction = (action: string | undefined) => {
    switch (action) {
      case TriggerAction.StartPlaybook:
        return 'Start Subscription'
      case TriggerAction.StopPlaybook:
        return 'Stop Subscription'
      default:
        return humanize(action)
    }
  }
  const displayEvent = (recipe: string | undefined) =>
    recipeNameMap[recipe as keyof typeof recipeNameMap] ?? humanize(recipe?.replace(/^.*trigger-/, ''))

  return (
    <ZCard
      border="1px"
      borderColor="gray.200"
      isLoading={!trigger}
      {...rest}
    >
      <ZCardHeader>
        <Flex
          justifyContent="space-between"
          alignItems="baseline"
          flex={1}
        >
          Trigger Details
          <SystemLink
            system={trigger?.systemName || ''}
            w="60px"
            h="auto"
          />
        </Flex>
      </ZCardHeader>
      <SimpleGrid
        columns={2}
        spacing={8}
      >
        <TriggerDetailsRow label={status === Status.Active ? 'Enabled' : 'Disabled'}>
          <UiToggle
            mt={2}
            colorScheme="atomicBlue"
            name="status"
            isChecked={status === Status.Active}
            isDisabled={updateTrigger.isLoading}
            onChange={handleStatus}
          />
        </TriggerDetailsRow>
        <TriggerDetailsRow
          label="Owner"
          justifySelf="start"
        >
          <UserLinkLink
            color="atomicBlue.400"
            userId={trigger?.userId}
            userLink={trigger?.userLink}
            fallback="N/A"
          />
        </TriggerDetailsRow>
        {trigger?.config?.recipeName && (
          <TriggerDetailsRow label="Event">{displayEvent(trigger?.config?.recipeName)}</TriggerDetailsRow>
        )}
        <TriggerDetailsRow label="Action">{displayAction(trigger?.action)}</TriggerDetailsRow>
        <TriggerDetailsRow label="Created">
          <UiDate date={trigger?.created?.dateTime} />
        </TriggerDetailsRow>
      </SimpleGrid>
      <TriggerDetailsFilters
        trigger={trigger}
        mt={8}
      />
      <TriggerDetailsProgram
        trigger={trigger}
        mt={8}
      />
      <TriggerDetailsSequence
        trigger={trigger}
        mt={8}
      />
    </ZCard>
  )
}
