import type { BoxProps } from '@chakra-ui/react'
import {
  Box,
  chakra,
  createIcon,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  shouldForwardProp,
  SkeletonText,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiButtonProps, UiCardProps } from '@postal-io/postal-ui'
import {
  UiButton,
  UiFormControl,
  UiToggle,
  useAlertError,
  useAlerts,
  ZButton,
  ZCard,
  ZCardHeader,
  ZFormLabel,
  ZHeading,
  ZLink,
} from '@postal-io/postal-ui'
import type { IntegrationSync } from 'api'
import { CustomFieldDataListType, GetCrmPostalCustomFieldsDocument, UpdateIntegrationSyncDocument } from 'api'
import { ZAlert, ZInfoTooltip } from 'components/Common/ZComponents'
import { isValidMotionProp, motion } from 'framer-motion'
import React, { useMemo, useState } from 'react'
import { MdDone, MdRefresh } from 'react-icons/md'
import type { ExternalProvider } from './data'

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

// Leaving this here until can confirm the CSS is correct - then move to postal-ui
interface WiggleProps extends BoxProps {
  isOpen?: boolean
}
const Wiggle: React.FC<WiggleProps> = ({ isOpen, children }) => {
  if (!isOpen) return <>{children}</>
  return (
    <ChakraBox
      // adopted from "headShake" https://animate.style/
      animate={{
        translateX: [0, -6, 5, -3, 2, 0],
        rotateY: [0, -9, 7, -5, 3, 0],
        animationTimingFunction: 'ease-in-out',
        transition: { duration: 0.5, times: [0, 0.065, 0.185, 0.315, 0.435, 0.5] },
      }}
      children={children}
    />
  )
}

const HELP_CENTER_ABM_LINK = 'https://help.postal.com/helpcenter/s/article/Syncing-Postal-Account-Data-to-Salesforce'

enum RefreshStatus {
  Normal = 'Normal',
  Refetching = 'Refetching',
  Completed = 'Completed',
}

interface IntegrationAbmProps extends UiCardProps {
  provider: ExternalProvider
  accountIntegration: IntegrationSync | undefined
}

export const IntegrationAbm: React.FC<IntegrationAbmProps> = ({ provider, accountIntegration, ...rest }) => {
  const Alert = useAlerts()
  const customFieldsDisclosure = useDisclosure()
  const animateDisclosure = useDisclosure()
  const [refreshStatus, setRefreshStatus] = useState<RefreshStatus>(RefreshStatus.Normal)

  const updateIntegrationSync = useGraphqlMutation(UpdateIntegrationSyncDocument)

  const getCrmPostalCustomFieldsQuery = useGraphqlQuery(GetCrmPostalCustomFieldsDocument, {
    system: provider.system,
    type: CustomFieldDataListType.MetadataAccount,
  })
  useAlertError(getCrmPostalCustomFieldsQuery.error)

  const crmPostalCustomFields = useMemo(
    () => getCrmPostalCustomFieldsQuery.data?.getCrmPostalCustomFields.items?.filter((v) => v.present === true) || [],
    [getCrmPostalCustomFieldsQuery.data?.getCrmPostalCustomFields]
  )

  const handleRefreshData = async () => {
    setRefreshStatus(RefreshStatus.Refetching)
    try {
      await getCrmPostalCustomFieldsQuery.refetch()
      await updateIntegrationSync.mutateAsync({
        id: accountIntegration?.id || '',
        data: {
          pushMappings: crmPostalCustomFields.map((field) => ({
            externalFieldName: field.crmField,
            postalIoFieldName: field.postalField,
          })),
        },
      })
      setRefreshStatus(RefreshStatus.Completed)
      setTimeout(() => setRefreshStatus(RefreshStatus.Normal), 3000)
    } catch (err) {
      setRefreshStatus(RefreshStatus.Normal)
      Alert.error(err)
    }
  }

  const handleToggleSync = async (e: any) => {
    if (e.target.checked) {
      try {
        await getCrmPostalCustomFieldsQuery.refetch()
        await updateIntegrationSync.mutateAsync({
          id: accountIntegration?.id || '',
          data: {
            pushMappings: crmPostalCustomFields.map((field) => ({
              externalFieldName: field.crmField,
              postalIoFieldName: field.postalField,
            })),
          },
        })
      } catch (err) {
        Alert.error(err)
      }
    } else {
      try {
        await updateIntegrationSync.mutateAsync({
          id: accountIntegration?.id || '',
          data: { pushMappings: [] },
        })
      } catch (err) {
        Alert.error(err)
      }
    }
  }

  // correct fields are available in SFDC
  const hasSFDCCustomFields = crmPostalCustomFields.length > 0
  // push mappings are active in Postal
  const isSyncPostalData = !!accountIntegration?.pushMappings?.length

  const handleClickDisabled = () => {
    if (!hasSFDCCustomFields) {
      animateDisclosure.onOpen()
      setTimeout(animateDisclosure.onClose, 2000)
    }
  }

  const isLoading = getCrmPostalCustomFieldsQuery.isLoading || updateIntegrationSync.isLoading

  return (
    <>
      <ZCard
        variant="form"
        isFetching={isLoading}
        {...rest}
      >
        <ZCardHeader
          fontWeight="normal"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={8}
          pb={0}
        >
          <Flex
            alignItems="center"
            gap={2}
          >
            ABM
            <ZInfoTooltip
              textAlign="justify"
              label={`Turning on this ABM toggle will enable syncing Postal Engagement data to the account object in ${provider.name}.`}
            />
          </Flex>
          {isSyncPostalData && hasSFDCCustomFields && (
            <RefreshButton
              status={refreshStatus}
              onRefresh={handleRefreshData}
            />
          )}
        </ZCardHeader>
        <Box p={8}>
          <ZHeading
            size="h6"
            mb={6}
          >
            Sync Postal Data to {provider.name}
          </ZHeading>
          <UiFormControl
            display="flex"
            alignItems="center"
            id={`integration-abm-sync`}
            onClick={handleClickDisabled}
          >
            <UiToggle
              size="lg"
              isChecked={isSyncPostalData}
              onChange={handleToggleSync}
              colorScheme="atomicBlue"
              isDisabled={crmPostalCustomFields.length === 0}
            />
            <ZFormLabel
              fontWeight="bold"
              fontSize="md"
              m={0}
              ml={2}
            >
              {isSyncPostalData ? 'Enabled' : 'Disabled'}
            </ZFormLabel>
          </UiFormControl>
          <Box mt={6}>
            {getCrmPostalCustomFieldsQuery.isLoading ? (
              <SkeletonText
                noOfLines={2}
                skeletonHeight={4}
              />
            ) : hasSFDCCustomFields ? (
              <Text>
                We found{` `}
                <Link onClick={customFieldsDisclosure.onOpen}>{crmPostalCustomFields.length} fields</Link>
                {` `}on the account object in Salesforce. We will begin writing Postal data to these fields.
              </Text>
            ) : (
              <Wiggle isOpen={animateDisclosure.isOpen}>
                <ZAlert
                  status="warning"
                  mt={5}
                  hideClose={true}
                >
                  A {provider.name} Admin needs to set up fields in {provider.name} to Sync Postal data.{' '}
                  <ZLink
                    href={HELP_CENTER_ABM_LINK}
                    isExternal
                  >
                    Click Here
                  </ZLink>{' '}
                  to learn more.
                </ZAlert>
              </Wiggle>
            )}
          </Box>
        </Box>
      </ZCard>
      <Modal
        isOpen={customFieldsDisclosure.isOpen}
        onClose={customFieldsDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent
          px={4}
          py={6}
        >
          <ModalHeader
            fontSize="lg"
            textAlign="center"
          >
            {provider.name} Fields
          </ModalHeader>
          <ModalCloseButton
            top={9}
            right={10}
          />
          <ModalBody>
            <Text mb={5}>
              We found the following fields in {provider.name}. These field match our required naming convention. We
              will begin writing Postal data to these fields.{` `}
              <Link
                href={HELP_CENTER_ABM_LINK}
                isExternal
              >
                Click here
              </Link>
              {` `}to learn more.
            </Text>
            <RefreshButton
              status={refreshStatus}
              onRefresh={handleRefreshData}
              mb={5}
            />
            <VStack
              spacing={2}
              alignItems="flex-start"
              mb={5}
            >
              {crmPostalCustomFields.map((field) => (
                <Text fontWeight="bold">{field.crmField}</Text>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="flex-start">
            <UiButton
              colorScheme="atomicBlue"
              onClick={customFieldsDisclosure.onClose}
            >
              Ok
            </UiButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const UiIconFetching = createIcon({
  displayName: 'UiIconFetching',
  viewBox: '0 0 24 26',
  path: (
    <path
      d="M20.475 5.217C18.3 3.042 15.315 1.692 12 1.692 5.37 1.692.015 7.062.015 13.692.015 20.322 5.37 25.692 12 25.692 17.595 25.692 22.26 21.867 23.595 16.692H20.475C19.245 20.187 15.915 22.692 12 22.692 7.035 22.692 3 18.657 3 13.692 3 8.727 7.035 4.692 12 4.692 14.49 4.692 16.71 5.727 18.33 7.362L13.5 12.192H24V1.692L20.475 5.217Z"
      fill="#29AFFF"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 12 13"
        to="360 12 13"
        dur=".65s"
        repeatCount="indefinite"
      />
    </path>
  ),
})

interface RefreshButtonProps extends UiButtonProps {
  status: RefreshStatus
  onRefresh: () => void
}

const RefreshButton: React.FC<RefreshButtonProps> = ({ status, onRefresh, ...rest }) => {
  switch (status) {
    case RefreshStatus.Normal:
      return (
        <ZButton
          variant="link"
          color="atomicBlue.400"
          leftIcon={<MdRefresh size="24px" />}
          size="sm"
          onClick={onRefresh}
          isActive={false}
          {...rest}
        >
          Refresh Data
        </ZButton>
      )
    case RefreshStatus.Refetching:
      return (
        <ZButton
          variant="link"
          color="atomicBlue.400"
          leftIcon={<UiIconFetching />}
          size="sm"
          {...rest}
        >
          Data Refreshing
        </ZButton>
      )
    case RefreshStatus.Completed:
      return (
        <ZButton
          variant="link"
          color="atomicBlue.400"
          leftIcon={<MdDone size="24px" />}
          size="sm"
          {...rest}
        >
          Complete
        </ZButton>
      )
  }
}
