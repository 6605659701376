import { Box } from '@chakra-ui/react'
import type { UiSelectTypeaheadCreatableProps, UiSelectTypeaheadProps } from '@postal-io/postal-ui'
import { ZSelectTypeahead, ZSelectTypeaheadCreatable } from '@postal-io/postal-ui'
import { useCallback, useMemo, useRef } from 'react'
import { HiddenInput } from '../Common'

interface AutoCompleteMultiSelectCreatableProps extends UiSelectTypeaheadCreatableProps<any, any> {
  isRequired?: boolean
}

export function AutoCompleteMultiSelectCreatable(props: AutoCompleteMultiSelectCreatableProps) {
  const { value, onChange, options, isRequired, ...rest } = props

  // react-select requires options and values to be objects
  const fakeValue = useMemo(() => (value ? value?.map((v: any) => ({ label: v, value: v })) : null), [value])
  const fakeOptions = useMemo(() => options?.map((o: any) => ({ label: o, value: o })), [options])

  const handleChange = useCallback(
    (val: any, action: any) => {
      onChange?.(
        val.map((v: any) => v.value),
        action
      )
    },
    [onChange]
  )

  const handleCreate = useCallback(
    (val: any) => {
      onChange?.([...(value || []), val], {
        action: 'select-option',
        option: { label: val, value: val },
      })
    },
    [onChange, value]
  )

  const ref = useRef<HTMLElement>(null)

  return (
    <Box>
      <ZSelectTypeaheadCreatable
        options={fakeOptions}
        onCreateOption={handleCreate}
        getNewOptionData={(val: any) => ({ label: `Create "${val}"`, value: val })}
        isValidNewOption={(val: any) => !!val?.trim()}
        value={fakeValue}
        onChange={handleChange}
        isMulti
        inputRef={ref}
        {...rest}
      />
      <HiddenInput
        value={value}
        isRequired={isRequired}
        isDisabled={rest.isDisabled}
        onFocus={() => ref.current?.focus()}
      />
    </Box>
  )
}

interface AutoCompleteSelectCreatableProps extends UiSelectTypeaheadCreatableProps<any, false> {
  isRequired?: boolean
}

export function AutoCompleteSelectCreatable(props: AutoCompleteSelectCreatableProps) {
  const { value, onChange, options, isRequired, ...rest } = props

  // react-select requires options and values to be objects
  const fakeValue = useMemo(() => (value ? { label: value, value: value } : null), [value])
  const fakeOptions = useMemo(() => options?.map((o: any) => ({ label: o, value: o })), [options])

  const handleChange = useCallback(
    (val: any, action: any) => {
      onChange?.(val?.value, action)
    },
    [onChange]
  )

  const handleCreate = useCallback(
    (val: any) => {
      onChange?.(val, {
        action: 'select-option',
        option: { label: val, value: val },
      })
    },
    [onChange]
  )

  const ref = useRef<HTMLElement>(null)

  return (
    <Box>
      <ZSelectTypeaheadCreatable
        options={fakeOptions}
        onCreateOption={handleCreate}
        getNewOptionData={(val: any) => ({ label: `Create "${val}"`, value: val })}
        isValidNewOption={(val: any) => !!val?.trim()}
        value={fakeValue}
        onChange={handleChange}
        inputRef={ref}
        {...rest}
      />
      <HiddenInput
        value={value}
        isRequired={isRequired}
        isDisabled={rest.isDisabled}
        onFocus={() => ref.current?.focus()}
      />
    </Box>
  )
}

export interface AutoCompleteSelectProps extends UiSelectTypeaheadProps<any> {
  isRequired?: boolean
}

export function AutoCompleteSelect(props: AutoCompleteSelectProps) {
  const { value, onChange, options, isRequired, ...rest } = props

  // react-select requires options and values to be objects
  const fakeValue = useMemo(() => (value ? { label: value, value: value } : null), [value])
  const fakeOptions = useMemo(() => options?.map((o: any) => ({ label: o, value: o })), [options])

  const handleChange = useCallback(
    (val: any, action: any) => {
      onChange?.(val?.value, action)
    },
    [onChange]
  )

  return (
    <ZSelectTypeahead
      options={fakeOptions}
      value={fakeValue}
      onChange={handleChange}
      {...rest}
    />
  )
}

export interface AutoCompleteMultiSelectProps extends UiSelectTypeaheadProps<any, any> {
  isRequired?: boolean
}

export function AutoCompleteMultiSelect(props: AutoCompleteMultiSelectProps) {
  const { value, onChange, options, isRequired, ...rest } = props

  // react-select requires options and values to be objects
  const fakeValue = useMemo(() => (value ? value?.map((v: any) => ({ label: v, value: v })) : null), [value])
  const fakeOptions = useMemo(() => options?.map((o: any) => ({ label: o, value: o })), [options])

  const handleChange = useCallback(
    (val: any, action: any) => {
      onChange?.(
        val.map((v: any) => v.value),
        action
      )
    },
    [onChange]
  )

  return (
    <ZSelectTypeahead
      options={fakeOptions}
      value={fakeValue}
      onChange={handleChange}
      isMulti
      {...rest}
    />
  )
}
