import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import {
  UiButton,
  UiFormControl,
  UiFormLabel,
  UiInput,
  UiLink,
  UiSwitch,
  UiText,
  useAlerts,
} from '@postal-io/postal-ui'
import { AutoCompleteCountry } from 'components/AutoComplete/AutoCompleteCountry'
import { zLoginAutocompleteStyles, zLoginInputStyles } from 'components/Common/ZComponents'
import type { CountryInput } from 'hooks'
import React, { useState } from 'react'
import { useImmer } from 'use-immer'
import { AutofillStateInput } from './AutofillStateInput'

interface CreateAccountProps {
  hasAccount: boolean
  onComplete: (e: any) => void
}

interface RegisterFormProps {
  accountName: string
  phoneNumber: string
  state: string
  country: string
}

export const RegistrationForm: React.FC<CreateAccountProps> = ({ hasAccount, onComplete }) => {
  const Alert = useAlerts()

  const [form, setForm] = useImmer<RegisterFormProps>({
    accountName: '',
    phoneNumber: '',
    state: '',
    country: '',
  })

  const [toggle, setToggle] = useState(false)

  const handleFormInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target
    setForm((draft: any) => void (draft[name] = value))
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const newData = { ...form }

    if (!hasAccount && (!newData.state || !newData.country)) {
      return Alert.warning('Please select a state')
    }
    await onComplete(newData)
  }

  const handleCountryChange = (value: CountryInput | null) => {
    setForm && setForm((draft: any) => void (draft.country = value?.iso3 || ''))
  }

  return (
    <Box w="80%">
      <Text
        textAlign="center"
        fontSize="2xl"
        fontWeight="bold"
        color="white"
        my={10}
      >
        Complete your registration
      </Text>

      <form
        onSubmit={onSubmit}
        style={{ width: '100%' }}
      >
        <Box
          mb={hasAccount ? 16 : 8}
          mt={4}
        >
          {hasAccount ? (
            <Box>
              <UiText
                fontSize="xl"
                mb={6}
              >
                Please accept the Terms and Conditions to get started using Postal!
              </UiText>
            </Box>
          ) : (
            <Box>
              <Grid
                templateColumns={{ base: '1fr', sm: '1fr 1fr' }}
                gridGap={3}
              >
                <UiFormControl
                  id="accountName"
                  isRequired
                >
                  <UiFormLabel
                    fontWeight="bold"
                    color="white"
                  >
                    Company Name
                  </UiFormLabel>
                  <UiInput
                    {...zLoginInputStyles}
                    name="accountName"
                    value={form.accountName}
                    onChange={handleFormInput}
                  />
                </UiFormControl>

                <UiFormControl id="phoneNumber">
                  <UiFormLabel
                    fontWeight="bold"
                    color="white"
                  >
                    Phone
                  </UiFormLabel>
                  <UiInput
                    {...zLoginInputStyles}
                    name="phoneNumber"
                    value={form.phoneNumber}
                    onChange={handleFormInput}
                  />
                </UiFormControl>

                <UiFormControl id="state">
                  <UiFormLabel
                    fontWeight="bold"
                    color="white"
                  >
                    State
                  </UiFormLabel>
                  <AutofillStateInput
                    {...zLoginInputStyles}
                    form={form}
                    setForm={setForm}
                    onChange={handleFormInput}
                  />
                </UiFormControl>

                <UiFormControl id="country">
                  <UiFormLabel
                    fontWeight="bold"
                    color="white"
                  >
                    Country
                  </UiFormLabel>
                  <AutoCompleteCountry
                    styles={zLoginAutocompleteStyles}
                    onChange={handleCountryChange}
                  />
                </UiFormControl>
              </Grid>
            </Box>
          )}
        </Box>
        <UiFormControl
          id="terms"
          isRequired
        >
          <Flex alignItems="center">
            <UiSwitch
              name="terms"
              color="tertiary"
              size="lg"
              mr={2}
              onChange={() => setToggle(!toggle)}
            />

            <UiFormLabel
              fontSize="link"
              color="white"
            >
              <span>Agree to </span>
              <UiLink
                href="https://postal.com/terms-of-service/"
                isExternal
              >
                Terms &amp; Conditions
              </UiLink>
              <span> / </span>
              <UiLink
                href="https://postal.com/privacy-policy/"
                isExternal
              >
                Privacy Policy
              </UiLink>
            </UiFormLabel>
          </Flex>
        </UiFormControl>

        <Box textAlign="center">
          <UiButton
            type="submit"
            colorScheme="secondary"
            mt={8}
            size="lg"
            w="100%"
            isDisabled={!toggle}
          >
            Start using Postal
          </UiButton>
        </Box>
      </form>
    </Box>
  )
}
