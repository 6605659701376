import { useDisclosure } from '@chakra-ui/react'
import {
  UiTabList,
  UiTabPanel,
  UiTabPanels,
  UiTabs,
  ZButton,
  ZSidebar,
  ZSidebarBanner,
  ZTab,
} from '@postal-io/postal-ui'
import { CenteredBox } from 'components/Common'
import { UsersDisabledTable, UserSeatsAlert, UsersInviteTable, UsersTable } from 'components/Users'
import { UserInvite } from 'components/Users/UserInvite'
import { PageTitle, useAcl } from 'hooks'
import { StorageKeys } from 'lib'
import React, { useEffect, useMemo, useState } from 'react'
import { MdOutlinePersonOutline } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

export const Users: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { hasPermission } = useAcl()
  const userCreatePermission = hasPermission('users.create')

  const tabs = useMemo(() => {
    const _tabs = ['/users/active', '/users/disabled']
    if (userCreatePermission) _tabs.push('/users/invitations')
    return _tabs
  }, [userCreatePermission])

  const tabIndex = useMemo(() => tabs.findIndex((path) => pathname.startsWith(path)), [pathname, tabs])

  useEffect(() => {
    if (tabIndex > -1) return
    const prev = tabs.find((t) => t === sessionStorage.getItem(StorageKeys.UsersTab))
    const next = prev || tabs[0]
    navigate(next)
  }, [navigate, tabIndex, tabs])

  useEffect(() => {
    if (tabIndex > -1) sessionStorage.setItem(StorageKeys.UsersTab, tabs[tabIndex])
  }, [tabIndex, tabs])

  const handleTabChange = (idx: number) => {
    navigate(tabs[idx])
  }

  // handle invite
  const inviteUser = useDisclosure()
  const [inviteSent, setInviteSent] = useState(false)
  const openInvite = () => {
    setInviteSent(false)
    inviteUser.onOpen()
  }
  const closeInvite = () => {
    inviteUser.onClose()
    if (inviteSent) navigate('/users/invitations')
  }
  const handleInvite = () => {
    setInviteSent(true)
    closeInvite()
  }

  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <UserSeatsAlert />
        <ZSidebarBanner
          title="Users"
          actions={
            <ZButton
              variant="link"
              color="atomicBlue.400"
              leftIcon={<MdOutlinePersonOutline size="24px" />}
              size="sm"
              height="21px"
              onClick={openInvite}
            >
              Invite User
            </ZButton>
          }
        />

        {tabIndex > -1 && (
          <UiTabs
            index={tabIndex}
            onChange={handleTabChange}
          >
            <UiTabList mb={4}>
              <ZTab>Active Users</ZTab>
              <ZTab>Inactive Users</ZTab>
              {userCreatePermission && <ZTab>Invitations</ZTab>}
            </UiTabList>
            <UiTabPanels>
              <UiTabPanel>
                {tabIndex === 0 && (
                  <>
                    <PageTitle title="Active Users" />
                    <UsersTable />
                  </>
                )}
              </UiTabPanel>
              <UiTabPanel>
                {tabIndex === 1 && (
                  <>
                    <PageTitle title="Inactive Users" />
                    <UsersDisabledTable />
                  </>
                )}
              </UiTabPanel>
              {userCreatePermission && (
                <UiTabPanel>
                  {tabIndex === 2 && (
                    <>
                      <PageTitle title="User Invitations" />
                      <UsersInviteTable />
                    </>
                  )}
                </UiTabPanel>
              )}
            </UiTabPanels>
          </UiTabs>
        )}
        {userCreatePermission && inviteUser.isOpen && (
          <UserInvite
            isOpen={inviteUser.isOpen}
            onCreate={handleInvite}
            onClose={closeInvite}
          />
        )}
      </ZSidebar>
    </CenteredBox>
  )
}
