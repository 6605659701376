import { Box, Collapse, Fade, Flex, FormControl, Grid, HStack, InputGroup, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiSubNavbarProps } from '@postal-io/postal-ui'
import {
  UiButtonScrollTop,
  UiSidePanelContainer,
  UiTooltip,
  useAlertError,
  useColor,
  useInfiniteScroll,
  ZButton,
  ZHeading,
  ZInput,
  ZInputLeftIcon,
  ZProductCard,
  ZSubNavbar,
  ZText,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, ApprovedPostalFilterInput } from 'api'
import { DefaultOrderByInput, SearchApprovedPostalsDocument, SearchFavoriteItemsDocument } from 'api'
import { useMaxCollectionItems } from 'components/Collections'
import { BulkSelectActionBar, BulkSelectNavbar, CenteredBox, SidePanelFilter, SubnavFilters } from 'components/Common'
import { NoPostal } from 'components/Postal'
import { AnalyticsEvent, PageTitle, useAcl, useAnalyticsEvent, useMe, usePostalFilters } from 'hooks'
import { useBulkSelect } from 'hooks/useBulkSelect'
import { useNavbarOverride } from 'hooks/useNavbarOverride'
import { MAX_FAVORITES } from 'hooks/usePostalFavoriteStatus'
import { cloneDeep, omit, some, uniqBy } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { MdAddCircleOutline, MdClose, MdOutlineEdit, MdSearch } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useKey } from 'react-use'
import { ITEM_CATEGORY_EXCLUSIONS, MARKETPLACE_CARD_MIN_WIDTH } from '../Postals/data'
import { MarketplaceBulkEdit } from './MarketplaceBulkEdit'
import { MarketplaceCardPostal } from './MarketplaceCardPostal'

export const ViewFavorites = () => {
  const navigate = useNavigate()
  const { approvedCurrencies } = useMe()
  const { hasPermission } = useAcl()
  const canCreate = hasPermission('postals.create')

  const bulkEditDisclosure = useDisclosure()
  const actionBarDisclosure = useDisclosure()

  const { bulkSelected, setBulkSelected, handleBulkSelect, handleCancelBulkSelect, isBulkSelected } =
    useBulkSelect<ApprovedPostal>()

  //Check to see if user has at least one postal
  const hasOneVariables = useMemo(() => {
    return {
      limit: 1,
      filter: {},
    }
  }, [])

  const hasOneQuery = useGraphqlQuery(SearchFavoriteItemsDocument, hasOneVariables)
  const hasNoItems = useMemo(
    () => !hasOneQuery.data?.searchFavoriteItems?.length,
    [hasOneQuery.data?.searchFavoriteItems?.length]
  )

  // load approved postals
  const searchFavorites = useGraphqlInfiniteQuery(
    SearchFavoriteItemsDocument,
    {
      limit: MAX_FAVORITES,
      orderBy: DefaultOrderByInput.IdAsc,
    }
    // { keepPreviousData: true }
  )
  useAlertError(searchFavorites.error)

  const favorites = useMemo(
    () => searchFavorites.mergedData?.searchFavoriteItems ?? [],
    [searchFavorites.mergedData?.searchFavoriteItems]
  )

  // set initial filters
  const staticVariables = useMemo(() => ({ id: { in: favorites?.map((fav) => fav.approvedPostalId) } }), [favorites])

  const filter = usePostalFilters<ApprovedPostalFilterInput>({
    staticVariables,
  })
  // don't show status (taken from Postals but here too)
  const subnavFilters = omit(cloneDeep(filter.filters), ['status', 'teamIds', 'outOfStock'])

  const postalsQuery = useGraphqlQuery(SearchApprovedPostalsDocument, { filter: filter.graphqlFilter })

  const postals = useMemo(
    () => postalsQuery.data?.searchApprovedPostals ?? [],
    [postalsQuery.data?.searchApprovedPostals]
  )

  useAlertError(postalsQuery.error)

  const favoritePostals = useMemo(
    () =>
      uniqBy(favorites, 'approvedPostalId')
        .map((f) => {
          const matchedPostal = postals.find((p) => p.id === f.approvedPostalId)
          return {
            ...matchedPostal,
            favoriteItemId: f.id,
          }
        })
        .filter((p) => !!p.id),
    [favorites, postals]
  )

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchFavorites.hasNextPage,
    loadMore: searchFavorites.fetchNextPage,
    loading: searchFavorites.isFetching,
  })

  const handleSelect = useCallback(
    (postal: ApprovedPostal) => {
      // sendAnalytics({
      //   event: AnalyticsEvent.Favorites,
      //   data: { marketplaceProductId: postal.marketplaceProductId, itemName: postal.name },
      // })
      navigate(`/items/postals/${postal.id}`, { state: { returnTo: 'Favorites' } })
    },
    [navigate]
  )

  // open action bar when products are selected in bulk
  useEffect(() => {
    if (bulkSelected.length > 0) actionBarDisclosure.onOpen()
    else actionBarDisclosure.onClose()
    return () => actionBarDisclosure.onClose()
  }, [actionBarDisclosure, bulkSelected.length])

  useAnalyticsEvent({ event: AnalyticsEvent.MarketplaceMyItemsPageViewed })

  // deselect all products
  useKey('Escape', handleCancelBulkSelect)

  // const isLoading = useDebounce(searchPostals.isLoading || hasOneQuery.isLoading, 600)
  const isLoading = searchFavorites.isLoading || postalsQuery.isLoading || hasOneQuery.isLoading
  const hasFilters = useMemo(() => !!Object.keys(omit(filter.graphqlFilter, 'id')).length, [filter.graphqlFilter])
  const numResultsText = useMemo(
    () =>
      postalsQuery.isFetching
        ? ''
        : hasFilters
        ? `${postals.length} results based on your filters`
        : `${postals.length} Favorite Items`,
    [hasFilters, postals.length, postalsQuery.isFetching]
  )
  const multiCurrencySelection = useMemo(
    () => some(bulkSelected, (item) => item.currency !== bulkSelected[0].currency),
    [bulkSelected]
  )
  const MAX_COLLECTION_ITEMS = useMaxCollectionItems()
  const collectionCreateError = useMemo(() => {
    if (multiCurrencySelection) return 'Multiple currencies selected. Only one currency is allowed per collection.'
    if (bulkSelected.length > MAX_COLLECTION_ITEMS)
      return `Cannot create a Collection with over ${MAX_COLLECTION_ITEMS} items.`
  }, [MAX_COLLECTION_ITEMS, bulkSelected.length, multiCurrencySelection])

  useNavbarOverride(
    !!bulkSelected.length && (
      <BulkSelectNavbar
        isEverythingSelected={bulkSelected.length === favoritePostals.length}
        selectedItems={bulkSelected}
        onSelect={handleBulkSelect}
        onSelectAll={() => setBulkSelected(postals)}
        onDeselectAll={handleCancelBulkSelect}
      >
        <>
          <ZButton
            colorScheme="atomicBlue"
            onClick={bulkEditDisclosure.onOpen}
            isDisabled={bulkSelected.length < 1}
            autoFocus
            leftIcon={<MdOutlineEdit fontSize="18px" />}
          >
            Edit
          </ZButton>
          <UiTooltip
            label={collectionCreateError}
            shouldWrapChildren
          >
            <ZButton
              variant="naked"
              color="white"
              _hover={{ color: '#FFFC' }}
              px={0}
              onClick={() =>
                navigate('/collections/create', {
                  state: { items: bulkSelected, returnTo: 'Favorites' },
                })
              }
              isDisabled={!!collectionCreateError}
              leftIcon={<MdAddCircleOutline fontSize="18px" />}
            >
              Create Collection
            </ZButton>
          </UiTooltip>
        </>
      </BulkSelectNavbar>
    ),
    [bulkSelected, setBulkSelected, favoritePostals, multiCurrencySelection, collectionCreateError]
  )

  return (
    <>
      <PageTitle
        title="Favorites"
        section="Favorites"
      />
      <CenteredBox isLoaded>
        {!hasOneQuery.isLoading && hasNoItems ? (
          <FavoritesEmpty />
        ) : (
          <>
            <FavoritesSearchBarV2
              filters={filter.filters}
              handleSearch={(e: React.ChangeEvent<HTMLInputElement>) => filter.updateFilter?.('q', e.target.value)}
              handleClearFilters={() => filter.clearFilters()}
              numResultsText={numResultsText}
            />
            <Collapse in={(Object.keys(filter.filters).length ?? 0) > 0}>
              <SubnavFilters
                data-testid="Marketplace_SubnavFilters"
                filters={subnavFilters}
                onUpdate={filter.updateFilter}
                onClear={filter.clearFilters}
              />
            </Collapse>
            <UiSidePanelContainer gridGap={16}>
              <SidePanelFilter
                filterType="ApprovedPostal"
                filters={filter.filters}
                onUpdate={filter.updateFilter}
                excludeCategories={ITEM_CATEGORY_EXCLUSIONS}
                approvedCurrencies={approvedCurrencies}
                excludeFilters={['favorites']}
                showDraft
              />
              <Flex
                direction="column"
                w="100%"
                ref={topRef}
                style={{ scrollMargin: '20px' }}
              >
                <Grid
                  ref={topRef}
                  templateColumns={`repeat(auto-fill, minmax(${MARKETPLACE_CARD_MIN_WIDTH}px, 1fr))`}
                  gap={4}
                >
                  {!isLoading
                    ? favoritePostals?.map((postal: any) => (
                        <MarketplaceCardPostal
                          key={postal.id}
                          postal={postal}
                          onSelect={handleSelect}
                          favoriteItemId={postal.favoriteItemId}
                          isBulkSelected={isBulkSelected(postal)}
                          onBulkSelect={canCreate ? handleBulkSelect : undefined}
                          truncateLength={33}
                        />
                      ))
                    : favorites.map((_) => (
                        <ZProductCard
                          isLoading
                          h={MARKETPLACE_CARD_MIN_WIDTH}
                          w={MARKETPLACE_CARD_MIN_WIDTH}
                          startColor="atomicGray.50"
                          endColor="atomicGray.200"
                        />
                      ))}
                  {!searchFavorites.isFetching && !favoritePostals.length && <NoPostal />}
                </Grid>
              </Flex>
              {favoritePostals.length >= MAX_FAVORITES && (
                <Box
                  ref={bottomRef}
                  mb={8}
                  position="relative"
                >
                  <UiButtonScrollTop
                    onClick={scrollTop}
                    position="absolute"
                    top="0px"
                    right="0px"
                    isLoading={isLoading}
                    aria-label="scroll button"
                  />
                </Box>
              )}
            </UiSidePanelContainer>
          </>
        )}

        <BulkSelectActionBar
          isOpen={actionBarDisclosure.isOpen}
          selectedLength={bulkSelected.length}
          isEverythingSelected={bulkSelected.length === favoritePostals.length}
          selectedItemsText={`(${bulkSelected.length} Item${bulkSelected.length !== 1 ? 's' : ''} selected)`}
          onSelectAll={() => setBulkSelected(postals)}
          onDeselectAll={handleCancelBulkSelect}
        >
          <HStack spacing={2}>
            <ZButton
              minWidth="175px"
              colorScheme="atomicBlue"
              onClick={bulkEditDisclosure.onOpen}
              isDisabled={bulkSelected.length < 1}
              autoFocus
            >
              Edit
            </ZButton>
            <ZButton
              minWidth="175px"
              variant="subtle"
              colorScheme="atomicGray"
              onClick={handleCancelBulkSelect}
            >
              Cancel
            </ZButton>
          </HStack>
        </BulkSelectActionBar>
      </CenteredBox>
      {bulkEditDisclosure.isOpen && (
        <MarketplaceBulkEdit
          type="Update"
          items={bulkSelected}
          isOpen={bulkEditDisclosure.isOpen}
          onClose={bulkEditDisclosure.onClose}
          onSuccess={handleCancelBulkSelect}
        />
      )}
    </>
  )
}

const FavoritesEmpty: React.FC = () => {
  const navigate = useNavigate()
  const { aclCheck } = useAcl()
  const hasMarketplace = aclCheck({ module: 'postals.marketplace' })

  return (
    <CenteredBox
      isLoaded
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <ZHeading
        as="h2"
        size="h5"
        color="atomicGray.600"
        pb={5}
      >
        You haven't favorited anything yet.
      </ZHeading>
      <ZText
        color="atomicGray.500"
        fontWeight="bold"
      >
        When you favorite an item it will appear here.{' '}
      </ZText>
      {hasMarketplace ? (
        <ZButton
          mt={5}
          colorScheme="atomicBlue"
          onClick={() => navigate('/items')}
        >
          Take me to the Marketplace
        </ZButton>
      ) : (
        <ZButton
          mt={5}
          colorScheme="atomicBlue"
          onClick={() => navigate('/items/postals')}
        >
          Take me to My Items
        </ZButton>
      )}
    </CenteredBox>
  )
}
interface FavoritesSearchBarV2Props extends UiSubNavbarProps {
  handleSearch: any
  filters: any
  handleClearFilters: () => void
  numResultsText: string
}
const FavoritesSearchBarV2: React.FC<FavoritesSearchBarV2Props> = ({
  handleSearch,
  filters,
  handleClearFilters,
  numResultsText,
  ...rest
}) => {
  const { colorCode } = useColor()

  const showClearFilters = useMemo(() => !!(Object.keys(filters).length ?? 0), [filters])

  return (
    <ZSubNavbar
      left={
        <ZHeading
          as="h2"
          size="h5"
        >
          Favorites
        </ZHeading>
      }
      center={
        <FormControl id="search">
          <InputGroup>
            <ZInputLeftIcon icon={<MdSearch size="16px" />} />
            <ZInput
              name="search"
              placeholder="Search"
              w="440px"
              value={filters?.q ?? ''}
              onChange={handleSearch}
            />
          </InputGroup>
        </FormControl>
      }
      right={
        <>
          {showClearFilters ? (
            <ZButton
              variant="link"
              color="atomicGray.500"
              leftIcon={<MdClose color={colorCode('atomicGray.500')} />}
              onClick={handleClearFilters}
              p={0}
              fontWeight="normal"
              fontSize="body-md"
            >
              Clear filters
            </ZButton>
          ) : (
            <Fade in>
              <ZText color="atomicGray.500">{numResultsText}</ZText>
            </Fade>
          )}
        </>
      }
      px={0}
      gridProps={{ py: 6 }}
      {...rest}
    />
  )
}
