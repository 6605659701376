import type { BoxProps } from '@chakra-ui/react'
import { Box, Skeleton } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  LexendFontWeight,
  UiAccordion,
  UiAccordionButton,
  UiAccordionIcon,
  UiAccordionItem,
  UiAccordionPanel,
  UiHorizontalList,
  useAlertError,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import type { MagicLink } from 'api'
import { GetApprovedPostalDocument } from 'api'
import React from 'react'
import { ExtPostalPreview } from '../Postal/ExtPostalPreview'
import { ExtLinkStats } from './ExtLinkStats'

interface ExtLinkPostalProps extends BoxProps {
  link?: MagicLink
}

export const ExtLinkPostal: React.FC<ExtLinkPostalProps> = ({ link, ...rest }) => {
  const { data, isLoading, error } = useGraphqlQuery(
    GetApprovedPostalDocument,
    { id: link?.approvedPostalId as string },
    { enabled: !!link?.approvedPostalId }
  )
  const postal = data?.getApprovedPostal

  useAlertError(error)

  const postalVariant = postal?.variants?.find((item) => item.id === link?.variantId)

  return (
    <Skeleton
      isLoaded={!isLoading}
      {...rest}
    >
      <ExtPostalPreview
        postal={postal}
        borderRadius="10px"
      />
      <ExtLinkStats
        link={link}
        mt={4}
      />
      <UiAccordion
        defaultIndex={[0]}
        allowMultiple
        mt={5}
      >
        <UiAccordionItem border={0}>
          <h2>
            <UiAccordionButton
              px={0}
              borderBottom={1}
              borderBottomStyle="solid"
              borderBottomColor="atomicGray.200"
              _hover={{ bg: 'WhiteAlpha.50' }}
            >
              <Box
                flex="1"
                textAlign="left"
                fontWeight={LexendFontWeight.Bold}
              >
                Product Information
              </Box>
              <UiAccordionIcon />
            </UiAccordionButton>
          </h2>
          <UiAccordionPanel
            pb={4}
            px={0}
          >
            <UiHorizontalList
              templateColumns="1fr 3fr"
              gap={4}
              mt={2}
            >
              <ZText
                size="md"
                color="atomicGray.500"
              >
                Postal
              </ZText>
              <ZText size="md">{postal?.name}</ZText>
              <ZText
                size="md"
                color="atomicGray.500"
              >
                Option
              </ZText>
              <ZText size="md">{postalVariant?.variantName}</ZText>
              <ZText
                size="md"
                color="atomicGray.500"
              >
                Brand
              </ZText>
              <ZText size="md">{postal?.brandName}</ZText>
              <ZText
                size="md"
                color="atomicGray.500"
              >
                Category
              </ZText>
              <ZText size="md">{postal?.category}</ZText>
              <ZText
                size="md"
                color="atomicGray.500"
              >
                Price
              </ZText>
              <ZText size="md">
                <ZMoney cents={postalVariant?.displayPrice || 0} />
              </ZText>
            </UiHorizontalList>
          </UiAccordionPanel>
        </UiAccordionItem>
      </UiAccordion>
    </Skeleton>
  )
}
