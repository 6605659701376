import { Flex, Grid, Image, Text } from '@chakra-ui/react'
import { FontWeight, UiButton } from '@postal-io/postal-ui'
import triggerGraphic from 'assets/images/trigger-graphic.png'
import React from 'react'

export const TriggersTeaser: React.FC<{ handleClick: () => void; isDisabled?: boolean }> = ({
  handleClick,
  ...rest
}) => (
  <Grid
    templateColumns={{ base: '1fr', lg: '1fr 2fr' }}
    alignItems="center"
    minH="70vh"
    justifyItems="center"
  >
    <Flex
      direction="column"
      ml={{ base: 0, lg: 20 }}
      p={{ base: 5, lg: 0 }}
    >
      <Text
        fontSize="3xl"
        fontWeight={FontWeight.Bold}
        textAlign={{ base: 'center', lg: 'left' }}
        mb={5}
        color="gray.600"
      >
        Triggers
      </Text>
      <Text
        mb={5}
        textAlign={{ base: 'center', lg: 'left' }}
      >
        Automate actions based on events from your CRM or sales engagement tool.
      </Text>

      <Flex
        w="100%"
        justify={{ base: 'center', lg: 'flex-start' }}
        align="center"
        {...rest}
      >
        <UiButton
          width="50%"
          onClick={handleClick}
          size="lg"
          colorScheme="tertiary"
        >
          Create a trigger
        </UiButton>
      </Flex>
    </Flex>

    <Image
      display={{ base: 'none', lg: 'block' }}
      objectFit="fill"
      p={5}
      marginY="auto"
      h="90%"
      src={triggerGraphic}
      objectPosition="center"
    />
  </Grid>
)
