import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiLoading } from '@postal-io/postal-ui'
import { GetAccountDocument } from 'api'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { TeamUsers } from './TeamUsers'

export function TeamUsersRoute() {
  const data = useParams()
  const teamId = data.teamId
  const getAccountQuery = useGraphqlQuery(GetAccountDocument, undefined)
  const account = useMemo(() => getAccountQuery?.data?.getAccount, [getAccountQuery?.data?.getAccount])
  if (getAccountQuery.isLoading) return <UiLoading />

  return (
    <TeamUsers
      teamId={data.teamId === 'default' ? undefined : teamId}
      accountId={account?.id}
    />
  )
}
