import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlertError } from '@postal-io/postal-ui'
import type { Settings } from 'api'
import { BudgetMode, Currency, GetAccountDocument, GetBudgetRemainingDocument, GetTeamByIdDocument, Role } from 'api'
import { useMemo } from 'react'
import { GetBalanceRemainingDocument } from '../api/index'
import { useAcl } from './useAcl'
import { useSession } from './useSession'

export const getCurrencySymbol = (currencyCode?: string | null) => {
  return currencyCode === Currency.Usd
    ? '$'
    : currencyCode === Currency.Cad
    ? 'CA$'
    : currencyCode === Currency.Eur
    ? '€'
    : currencyCode === Currency.Gbp
    ? '£'
    : null
}

// return the primary currency associated with a Settings object from a team or account
export const usePrimaryCurrency = (settings?: Settings | null) => {
  // if multiple accounts, it's likely the first account setup is the primary currency
  const currencyCode = useMemo(() => {
    const { billingAccountIds } = settings ?? {}
    // default to USD if it exists, otherwise the currency of the first billing acct
    if (billingAccountIds?.find((acct) => acct.currency === Currency.Usd)) return Currency.Usd
    else return billingAccountIds?.[0]?.currency ?? Currency.Usd
  }, [settings])

  return { currencyCode }
}

// get Settings object of the teamId specified. if teamId is null, will return account settings.
export const useTeamOrAccountSettings = ({ teamId }: { teamId: string | null }) => {
  const getTeamById = useGraphqlQuery(GetTeamByIdDocument, { id: teamId! }, { enabled: !!teamId })
  const getAccount = useGraphqlQuery(GetAccountDocument, undefined, { enabled: !teamId })
  const team = useMemo(() => getTeamById?.data?.getTeamById, [getTeamById?.data?.getTeamById])
  const account = useMemo(() => getAccount?.data?.getAccount, [getAccount?.data?.getAccount])

  return teamId ? team?.settings : account?.settings
}

export const useBudget = () => {
  const { hasRole } = useAcl()

  const {
    session: { userId, accountId, teamId },
  } = useSession()

  const { data: budgetData } = useGraphqlQuery(
    GetBudgetRemainingDocument,
    { accountId, userId, teamId },
    { enabled: !!accountId }
  )

  // currency for team/acct
  const settings = useTeamOrAccountSettings({ teamId })
  const { currencyCode } = usePrimaryCurrency(settings)

  const budgetRemaining = budgetData?.getBudgetRemaining?.budgetRemaining
  const budgetMode = useMemo(
    () => budgetData?.getBudgetRemaining?.applicableBudget?.budget?.mode,
    [budgetData?.getBudgetRemaining?.applicableBudget?.budget?.mode]
  )

  const showBudget = useMemo(() => {
    if (budgetMode === BudgetMode.Unlimited) return false
    if (budgetMode === BudgetMode.PerUser) return hasRole(Role.User)
    if (budgetMode === BudgetMode.Pooled) return true
  }, [budgetMode, hasRole])

  return { budgetRemaining, budgetMode, showBudget, currencyCode }
}

export const useBalance = () => {
  const getBalanceRemainingQuery = useGraphqlQuery(GetBalanceRemainingDocument, undefined, {})
  const getBalanceRemaining = getBalanceRemainingQuery.data?.getBalanceRemaining
  useAlertError(getBalanceRemainingQuery.error)

  const billingAccount = useMemo(() => {
    // give preference to USD
    const sorted = getBalanceRemaining?.sort((a) => (a.currency === Currency.Usd ? -1 : 1))

    // should we look for something else with a number in it?
    // for now we are just returning the US if it exists
    return sorted?.[0] ?? null
  }, [getBalanceRemaining])

  const { amount: balanceRemaining, currency: currencyCode } = billingAccount ?? {}
  const { showBudget } = useBudget()

  // use balance when budget is disabled
  const showBalance = useMemo(() => !showBudget && balanceRemaining !== undefined, [balanceRemaining, showBudget])

  return {
    showBalance,
    balanceRemaining,
    currencyCode,
  }
}
