import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { Column } from '@postal-io/postal-ui'
import { internalProgressBarProps, internalTableProps, UiDataTable, useAlertError, ZButton } from '@postal-io/postal-ui'
import type { ReportOutputInstance } from 'api'
import { DataObjectType, SearchReportOutputInstancesDocument } from 'api'
import { DownloadButton } from './DownloadButton'

const columns: Column[] = [
  { key: 'name', label: 'Name', rowProps: { width: '50%', maxW: '400px' } },
  {
    label: 'Download',
    render: ({ id, name }: ReportOutputInstance) => (
      <DownloadButton
        fileName={name}
        reportOutputInstanceId={id}
      />
    ),
    rowProps: { width: '50%', maxW: '400px' },
  },
]

const LIMIT = 100
const variables = { filter: { type: DataObjectType.Postal }, limit: LIMIT }

export const SavedReportsTable = () => {
  const { data, isLoading, error } = useGraphqlQuery(SearchReportOutputInstancesDocument, variables)
  const reportOutputInstances = data?.searchReportOutputInstances ?? []

  useAlertError(error)
  return (
    <UiDataTable
      variant="list"
      isLoading={isLoading}
      columns={columns}
      rows={reportOutputInstances}
      rowKey="id"
      tableProps={internalTableProps}
      progressBarProps={internalProgressBarProps}
      HeaderButton={ZButton}
    />
  )
}
