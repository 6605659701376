import { Flex } from '@chakra-ui/react'
import { humanize, UiDateTime, ZText } from '@postal-io/postal-ui'
import { Requirements } from 'api'
import React, { useMemo } from 'react'
import { MdMobileScreenShare, MdTimer } from 'react-icons/md'

export const EventDateTime: React.FC<{ dateTime?: string | null }> = ({ dateTime }) => {
  return (
    <Flex
      alignItems="flex-start"
      gap={2}
    >
      <MdTimer size="20px" />
      {dateTime && (
        <ZText>
          <UiDateTime
            date={dateTime || undefined}
            format={{
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              timeZoneName: 'short',
            }}
          />
        </ZText>
      )}
    </Flex>
  )
}

export const EventDuration: React.FC<{ duration?: number | null }> = ({ duration }) => {
  return (
    <Flex
      alignItems="flex-start"
      gap={2}
    >
      <MdTimer size="20px" />

      {duration && <ZText>{duration} minutes</ZText>}
    </Flex>
  )
}

export const EventType: React.FC<{ requirements?: Requirements[] | null }> = ({ requirements }) => {
  const requirementsText = useMemo(() => {
    return (
      (requirements?.includes(Requirements.InPerson) && requirements.length === 1 ? 'Join ' : 'Join from your ') +
      requirements
        ?.map((r, idx) => {
          const humanized = humanize(r).toLowerCase()
          const isLast = idx === requirements.length - 1
          return requirements.length > 1 && isLast ? `or ${humanized}` : humanized
        })
        .join(requirements.length > 2 ? ', ' : ' ')
    )
  }, [requirements])

  return (
    <Flex
      alignItems="flex-start"
      gap={2}
    >
      <MdMobileScreenShare size="20px" />
      <ZText>{requirementsText}</ZText>
    </Flex>
  )
}
