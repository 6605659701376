import type { FlexProps, IconButtonProps } from '@chakra-ui/react'
import { Flex, IconButton, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiTooltip,
  useAlerts,
  ZButton,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import type { CalendarProvider } from 'api'
import { DeleteUserAuthDocument, VerifyEmailConnectionDocument } from 'api'
import type { ExternalCalendarProvider } from 'hooks'
import { useCalendarIntegrations } from 'hooks'
import React from 'react'

enum UserCalendarProviderStatus {
  Active = 'active',
  Reconnect = 'reconnect',
  Disabled = 'disabled',
}

export interface UserCalendarProviderProps extends Omit<IconButtonProps, 'onClick' | 'aria-label'> {
  provider: ExternalCalendarProvider
  isSelected?: boolean
  onClick: (provider: ExternalCalendarProvider) => void
}
export const UserCalendarProviderV2: React.FC<UserCalendarProviderProps> = ({
  provider,
  isSelected,
  boxSize = '100px',
  onClick,
  ...rest
}) => {
  const { syncEnabled, enableSync, syncLoading } = useCalendarIntegrations()
  const toggleConnect = useDisclosure()
  const Alert = useAlerts()

  const isSyncEnabled = syncEnabled(provider)

  const verifyQuery = useGraphqlQuery(
    VerifyEmailConnectionDocument,
    { ssoMethodName: provider.calendar.authType || '' },
    { enabled: isSyncEnabled && !!provider.calendar.authType }
  )

  const disableCalendar = useGraphqlMutation(DeleteUserAuthDocument)

  const status = React.useMemo(() => {
    if (!provider.calendar.endpoint) return UserCalendarProviderStatus.Active
    if (!isSyncEnabled || verifyQuery.isLoading) return UserCalendarProviderStatus.Disabled
    return verifyQuery.data?.verifyEmailConnection
      ? UserCalendarProviderStatus.Active
      : UserCalendarProviderStatus.Reconnect
  }, [isSyncEnabled, provider.calendar.endpoint, verifyQuery.data?.verifyEmailConnection, verifyQuery.isLoading])

  const icon = React.useMemo(() => {
    const size = `calc(${boxSize} / 2)`
    return (
      <provider.icon
        h={size}
        w={size}
      />
    )
  }, [boxSize, provider])

  const borderColor = React.useMemo(() => {
    if (!isSelected) return 'atomicGray.200'
    switch (status) {
      case UserCalendarProviderStatus.Active:
        return 'atomicBlue.500'
      case UserCalendarProviderStatus.Disabled:
        return 'atomicRed.500'
      case UserCalendarProviderStatus.Reconnect:
        return 'atomicYellow.500'
      default:
        return 'atomicGray.400'
    }
  }, [isSelected, status])

  const tooltip = React.useMemo(() => {
    if (!provider.calendar.sync) return provider.name
    return `${provider.name} (${status})`
  }, [provider.calendar.sync, provider.name, status])

  const handleClick = () => {
    if (!provider.calendar.endpoint) return onClick(provider)
    if (!isSelected && status === UserCalendarProviderStatus.Active) return onClick(provider)
    toggleConnect.onOpen()
  }

  const handleConnect = async () => {
    await enableSync(provider)
  }

  const handleRemove = () => {
    handleAuthRemoval()
    toggleConnect.onClose()
  }

  const handleAuthRemoval = async () => {
    try {
      if (provider.calendar.authType) {
        disableCalendar.mutateAsync({ authMethod: provider.calendar.authType })
      }
      Alert.info(`${provider.name} integration disabled.`)
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <>
      <UiTooltip
        aria-label={tooltip}
        label={tooltip}
      >
        <IconButton
          aria-label={provider.name}
          icon={icon}
          variant="ghost"
          isRound
          isLoading={verifyQuery.isLoading}
          isDisabled={verifyQuery.isLoading}
          borderWidth={isSelected ? '5px' : '1px'}
          borderColor={borderColor}
          boxSize={boxSize}
          onClick={handleClick}
          {...rest}
        />
      </UiTooltip>
      {toggleConnect.isOpen && (
        <ZModal
          size="4xl"
          isOpen={toggleConnect.isOpen}
          onClose={toggleConnect.onClose}
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Connect to {provider.name}</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody
              textAlign="center"
              pb={4}
            >
              {isSelected ? (
                <ZText
                  fontSize="md"
                  fontWeight="normal"
                  my={4}
                >
                  Would you like to{' '}
                  <UiButton
                    onClick={handleConnect}
                    fontSize="md"
                    variant="link"
                    colorScheme="atomicBlue"
                    color="atomicBlue.400"
                  >
                    reconnect
                  </UiButton>{' '}
                  or{' '}
                  <ZLink
                    onClick={handleRemove}
                    fontSize="md"
                    variant="link"
                    colorScheme="atomicBlue"
                    color="atomicBlue.400"
                  >
                    disable
                  </ZLink>{' '}
                  this integration?
                </ZText>
              ) : (
                <ZText
                  fontSize="md"
                  fontWeight="normal"
                  my={4}
                >
                  Authorization is required.{' '}
                  <ZLink
                    onClick={handleConnect}
                    fontSize="md"
                    variant="link"
                    isDisabled={syncLoading}
                    colorScheme="atomicBlue"
                    color="atomicBlue.400"
                  >
                    Connect
                  </ZLink>{' '}
                  to {provider.name} to authorize to your {provider.name} calendar for use.
                </ZText>
              )}
            </ZModalBody>
            <ZModalFooter justifyContent="flex-end">
              <ZButton
                variant="ghost"
                colorScheme="atomicGray"
                minW="140px"
                onClick={toggleConnect.onClose}
              >
                Close
              </ZButton>
            </ZModalFooter>
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}

export interface UserCalendarProvidersProps extends Omit<FlexProps, 'onSelect'> {
  selected?: CalendarProvider
  onSelect: (provider: CalendarProvider) => void
  boxSize?: IconButtonProps['boxSize']
}
export const UserCalendarProviders: React.FC<UserCalendarProvidersProps> = ({
  selected,
  onSelect,
  boxSize,
  ...rest
}) => {
  const { availableCalendarProvidersV2 } = useCalendarIntegrations()

  const handleSelect = (provider: ExternalCalendarProvider) => onSelect(provider.calendar?.name)

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      gridGap={8}
      {...rest}
    >
      {availableCalendarProvidersV2.map((provider) => {
        return (
          <UserCalendarProviderV2
            key={provider.calendar.name}
            provider={provider}
            onClick={handleSelect}
            isSelected={selected === provider.calendar.name}
            boxSize={boxSize}
          />
        )
      })}
    </Flex>
  )
}
