import {
  Flex,
  FormControl,
  Grid,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import type { UseGraphqlFilterResponse } from '@postal-io/postal-ui'
import { LexendFontWeight, SelectTypeaheadStylesV2, UiSelectTypeahead, ZInputDate } from '@postal-io/postal-ui'
import { Result } from 'api'
import React from 'react'
import { MdFilterList } from 'react-icons/md'

export interface TriggerActivityFilterV2Props {
  graphqlFilter: UseGraphqlFilterResponse
}

export const TriggerActivityFilter: React.FC<TriggerActivityFilterV2Props> = ({ graphqlFilter }) => {
  const { isOpen, onClose, onToggle } = useDisclosure()

  const handleInput = (input: { key: string; value: any }) => {
    graphqlFilter.setFilter(input)
  }

  const statusOptions = [
    {
      label: 'Success',
      value: Result.Success,
    },
    {
      label: 'Error',
      value: Result.Error,
    },
  ]

  return (
    <Popover
      arrowSize={30}
      closeOnBlur={false} // Prevent dialog from closing on date selection
      isOpen={isOpen}
      gutter={16}
      onClose={onClose}
      placement="left"
    >
      <PopoverTrigger>
        <IconButton
          aria-label="Filter"
          color="gray.500"
          onClick={onToggle}
          size="sm"
          variant="ghost"
        >
          <MdFilterList size={20} />
        </IconButton>
      </PopoverTrigger>
      <PopoverContent
        borderRadius={20}
        pb={24}
        pt={4}
        px={10}
        w={600}
      >
        <PopoverArrow />
        <PopoverBody>
          <Flex>
            <Text
              mx="auto"
              color="grey.900"
              fontSize={21}
              fontWeight={LexendFontWeight.Regular}
            >
              Filter
            </Text>
          </Flex>
          <PopoverCloseButton
            colorScheme="grey.300"
            onClick={onClose}
            right={5}
            top={8}
          />
          <Grid
            templateColumns={{ base: '1fr' }}
            columnGap={8}
            rowGap={7}
            mt={4}
          >
            <FormControl id="created">
              <ZInputDate
                isRange
                name="created"
                placeholder="Search Date"
                onChange={({ target: { key, value } }) => handleInput({ key, value })}
                value={graphqlFilter.filter.created || []}
              />
            </FormControl>
            <FormControl id="name">
              <UiSelectTypeahead
                name="result"
                onChange={(e) => handleInput({ key: 'result', value: e?.value })}
                options={statusOptions}
                placeholder="Status"
                value={statusOptions.find((option) => option.value === graphqlFilter.filter.result)}
                {...SelectTypeaheadStylesV2}
              />
            </FormControl>
          </Grid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
