import { Box, Collapse, Divider, Flex, Stack } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import { UiFormControl, UiToggle, useAlerts, ZCard, ZCardHeader, ZFormLabel } from '@postal-io/postal-ui'
import type { Account, User } from 'api'
import { GetAccountDocument, Role, UpdateAccountDocument } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { dequal } from 'dequal'
import { useAcl, usePermissionsV2Update } from 'hooks'
import { Permission } from 'lib/permissions'
import { cloneDeep } from 'lodash'
import type { ChangeEvent } from 'react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'
import { AccountSettingsUsersSelect } from './AccountSettingsUsersSelect'

export const AccountUserAccessBlock: React.FC<UiCardProps> = (props) => {
  const getAccount = useGraphqlQuery(GetAccountDocument)
  const account = useMemo(() => getAccount.data?.getAccount, [getAccount.data?.getAccount])

  const {
    checkPermission: checkUserPermission,
    setPermission: setUserPermission,
    permissionsLoading,
  } = usePermissionsV2Update(Role.User)

  const updateAccount = useGraphqlMutation(UpdateAccountDocument)
  const Alert = useAlerts()
  const [form, setForm] = useImmer<Record<string, any>>({})
  const { aclCheck, hasFeature } = useAcl()

  const hasEvents = aclCheck({ module: 'events.read' })
  const hasVisibility = hasFeature('marketplaceVisibility')
  const hasBulkSend = hasFeature('bulkSend')
  const hasSwagEditor = hasFeature('swagEditor')
  const usersCanUseSwagEditor = checkUserPermission(Permission.SwagEditor)

  const resetForm = useCallback(() => {
    setForm((draft: Account & { usersCanUseSwagEditor: boolean }) => {
      draft.userCanViewMarketplace = account?.userCanViewMarketplace
      draft.userCanViewEvents = account?.userCanViewEvents
      draft.usersCanUseSwagEditor = !!usersCanUseSwagEditor
      draft.marketplaceRequestNotificationEmails = account?.marketplaceRequestNotificationEmails || []
      draft.eventRequestNotificationEmails = account?.eventRequestNotificationEmails || []
      draft.accountContactSettings = account?.accountContactSettings || {}
      draft.sendSettings = account?.sendSettings || {}
    })
  }, [account, setForm, usersCanUseSwagEditor])

  useEffect(() => {
    resetForm()
  }, [resetForm])

  const handleChange = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!account) return
    const { name, checked } = target
    setForm((draft: any) => ({ ...getNewAccountObject(draft, name, checked), usersCanUseSwagEditor }))
    const data = getNewAccountObject(form, name, checked) as any
    try {
      await updateAccount.mutateAsync({ id: account.id, data })
      Alert.success('Setting Updated')
    } catch (err) {
      Alert.error(err)
      resetForm()
    }
  }

  const handlePermissionsChange = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = target
    setForm((draft: any) => void (draft[name] = checked))
    try {
      setUserPermission(Permission.SwagEditor, checked)
      Alert.success('Setting Updated')
    } catch (err) {
      Alert.error(err)
      resetForm()
    }
  }

  // handle any nested fields
  const getNewAccountObject = (account: Record<string, any>, fieldName: string, checked: boolean) => {
    //prune non-account fields out of account doc
    const { usersCanUseSwagEditor: _, ...newAccount } = cloneDeep(account)

    if (fieldName === 'bulkSendEnabled') {
      newAccount.sendSettings = newAccount.sendSettings ?? {}
      newAccount.sendSettings[fieldName] = checked
    } else newAccount[fieldName] = checked

    return newAccount
  }

  const setList = async (
    list: 'marketplaceRequestNotificationEmails' | 'eventRequestNotificationEmails',
    users: User[]
  ) => {
    if (!account) return
    if (dequal(account[list], users)) return
    const data = { [list]: users }
    try {
      await updateAccount.mutateAsync({ id: account.id, data })
      Alert.success('Setting Updated')
    } catch (err) {
      Alert.error(err)
    }
  }

  if (!hasVisibility && !hasBulkSend && !hasSwagEditor) return null

  return (
    <ZCard
      isLoading={getAccount.isLoading}
      variant="form"
      {...props}
    >
      <ZCardHeader
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        p={8}
        pb={4}
        gap={2}
      >
        Configure User Access
      </ZCardHeader>
      <Stack
        spacing={12}
        p={8}
        divider={<Divider my={0} />}
      >
        {hasVisibility && (
          <Box>
            <UiFormControl id="userCanViewMarketplace">
              <Flex
                justifyContent="space-between"
                alignItems="center"
              >
                <ZFormLabel m={0}>
                  Users can view all items
                  <ZInfoTooltip
                    hasArrow
                    label="When toggled on, Users can view all Marketplace items.  They can only send approved items, but will be able to request a product of interest from an Admin."
                  />
                </ZFormLabel>

                <UiToggle
                  size="md"
                  name="userCanViewMarketplace"
                  isChecked={!!form.userCanViewMarketplace}
                  isDisabled={getAccount.isLoading}
                  onChange={handleChange}
                  colorScheme="atomicBlue"
                />
              </Flex>
            </UiFormControl>
            <Collapse
              in={form.userCanViewMarketplace}
              unmountOnExit
            >
              <UiFormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={8}
                mb={2}
              >
                <ZFormLabel m={0}>Item Request Email Recipients</ZFormLabel>
                <AccountSettingsUsersSelect
                  testid="marketplaceRequestNotificationEmails"
                  value={form.marketplaceRequestNotificationEmails}
                  onChange={(userIds) =>
                    setForm((draft) => {
                      draft.marketplaceRequestNotificationEmails = userIds
                    })
                  }
                  onClose={() => {
                    setList('marketplaceRequestNotificationEmails', form.marketplaceRequestNotificationEmails)
                  }}
                />
              </UiFormControl>
            </Collapse>
          </Box>
        )}
        {hasVisibility && hasEvents && (
          <Box>
            <UiFormControl id="userCanViewEvents">
              <Flex
                justifyContent="space-between"
                alignItems="center"
              >
                <ZFormLabel m={0}>
                  Users can view all events
                  <ZInfoTooltip
                    hasArrow
                    label="When toggled on, Users can view all events.  They cannot book events, but will be able to request an event of interest from an Admin."
                  />
                </ZFormLabel>

                <UiToggle
                  size="lg"
                  name="userCanViewEvents"
                  isChecked={!!form.userCanViewEvents}
                  isDisabled={getAccount.isLoading}
                  onChange={handleChange}
                  colorScheme="atomicBlue"
                />
              </Flex>
            </UiFormControl>
            <Collapse
              in={form.userCanViewEvents}
              unmountOnExit
            >
              <UiFormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={8}
                mb={2}
              >
                <ZFormLabel m={0}>Event Request Email Recipients</ZFormLabel>
                <AccountSettingsUsersSelect
                  testid="eventRequestNotificationEmails"
                  value={form.eventRequestNotificationEmails}
                  onChange={(userIds) =>
                    setForm((draft) => {
                      draft.eventRequestNotificationEmails = userIds
                    })
                  }
                  onClose={() => {
                    setList('eventRequestNotificationEmails', form.eventRequestNotificationEmails)
                  }}
                />
              </UiFormControl>
            </Collapse>
          </Box>
        )}
        {hasBulkSend && (
          <Box>
            <UiFormControl id="bulkSendEnabled">
              <Flex
                justifyContent="space-between"
                alignItems="center"
              >
                <ZFormLabel m={0}>
                  Users can Bulk Send Items
                  <ZInfoTooltip label="Only certain Items are available for Bulk Send" />
                </ZFormLabel>
                <UiToggle
                  size="lg"
                  name="bulkSendEnabled"
                  isChecked={!!form.sendSettings?.bulkSendEnabled}
                  isDisabled={getAccount.isLoading}
                  onChange={handleChange}
                  colorScheme="atomicBlue"
                />
              </Flex>
            </UiFormControl>
          </Box>
        )}
        {hasSwagEditor && (
          <Box>
            <UiFormControl id="usersCanUseSwagEditor">
              <Flex
                justifyContent="space-between"
                alignItems="center"
              >
                <ZFormLabel m={0}>
                  Users can use Swag Creator
                  <ZInfoTooltip label="When toggled on, Users can access & use the Postal Swag Creator. When toggled off, only Admins can access & use the Swag Creator." />
                </ZFormLabel>
                <UiToggle
                  size="lg"
                  name="usersCanUseSwagEditor"
                  isChecked={form.usersCanUseSwagEditor}
                  isDisabled={permissionsLoading}
                  onChange={handlePermissionsChange}
                  colorScheme="atomicBlue"
                />
              </Flex>
            </UiFormControl>
          </Box>
        )}
      </Stack>
    </ZCard>
  )
}
