import type { UiButtonProps } from '@postal-io/postal-ui'
import { ZButton } from '@postal-io/postal-ui'
import React from 'react'
import { MdOutlineDownload } from 'react-icons/md'
import { AnalyticsEvent, ReportType, useAnalyticsSend, useReport } from '../../../hooks'

interface ExportButtonProps extends Omit<UiButtonProps, 'children'> {
  filter: any
}
export const ExportButton: React.FC<ExportButtonProps> = ({ filter, ...rest }) => {
  const { createReport, isLoading } = useReport(ReportType.Order)
  const sendAnalytics = useAnalyticsSend()

  const handleCreateReport = () => {
    sendAnalytics({ event: AnalyticsEvent.ReportingOrderReportExportClicked })
    createReport(filter)
  }

  return (
    <ZButton
      leftIcon={<MdOutlineDownload size="22px" />}
      colorScheme="atomicBlue"
      minW="150px"
      onClick={handleCreateReport}
      isLoading={isLoading}
      isDisabled={isLoading}
      {...rest}
    >
      Export
    </ZButton>
  )
}
