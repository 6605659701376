import type { DrawerProps } from '@chakra-ui/react'
import { Box, Drawer, DrawerBody, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import React from 'react'

// const XS_HEIGHT = 600

interface SimpleDrawerProps extends DrawerProps {
  header?: ReactNode
  buttons?: ReactNode
  isOpen: boolean
  onClose: () => void
}
export const SimpleDrawer: React.FC<SimpleDrawerProps> = ({ header, buttons, children, isOpen, onClose, ...rest }) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick
      placement="bottom"
      {...rest}
    >
      <DrawerOverlay />
      <DrawerContent borderTopRadius="10px">
        <DrawerBody p={4}>
          {header}
          <Box
            maxHeight={400}
            overflowY="auto"
          >
            {children}
          </Box>
          {buttons && <Box>{buttons}</Box>}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
