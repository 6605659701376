import type { GridProps } from '@chakra-ui/react'
import {
  Box,
  ButtonGroup,
  Collapse,
  Divider,
  Flex,
  Grid,
  IconButton,
  Stack,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiFormControl,
  UiGoogleCaptchaPolicies,
  UiTag,
  useAlertError,
  useAlerts,
  ZButton,
  ZCard,
  ZCardHeader,
  ZFormLabel,
  ZHeading,
  ZLink,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import type { BillingAccount } from 'api'
import {
  BillingAccountType,
  Currency,
  GetFundsDocument,
  Status,
  TeamsDocument,
  UpdateBillingAccountDocument,
} from 'api'
import { dequal } from 'dequal'
import { useAcl } from 'hooks'
import React, { useEffect, useMemo } from 'react'
import { MdOutlineEdit } from 'react-icons/md'
import { useImmer } from 'use-immer'
import type { BillingEditFormProps } from '../Profile/BillingAccount'
import { DEFAULT_VALUES } from '../Profile/BillingAccount'
import { BalanceTransferCreateModal } from './BalanceTransferCreateModal'
import { BillingAddFunds } from './BillingAddFunds'
import { BillingAutoReload, MAX_AMOUNT } from './BillingAutoReload'
import { BillingContactInfoEdit } from './BillingContactInfoEdit'
import { BillingRequestByInvoice } from './BillingRequestByInvoice'
import { PaymentMethodComponent } from './paymentMethods/PaymentMethodComponent'

export const findPaymentPartner = (acct?: BillingAccount) => {
  const partnerId = acct?.defaultPaymentMethod?.partnerId ?? ''
  const partners = acct?.paymentPartners

  const defaultPaymentPartner =
    partners?.find((p) => p.paymentMethods?.find((m) => m.partnerId === partnerId)) ??
    acct?.paymentPartners?.[acct?.paymentPartners?.length - 1]

  return defaultPaymentPartner
}

export const findPartnerPaymentMethod = (acct?: BillingAccount) => {
  const defaultPaymentPartner = findPaymentPartner(acct)
  const partnerId = acct?.defaultPaymentMethod?.partnerId

  const paymentMethod =
    defaultPaymentPartner?.paymentMethods?.find((m) => m.partnerId === partnerId) ??
    defaultPaymentPartner?.paymentMethods?.[0]

  return paymentMethod
}

interface BillingAccountDetailsProps extends GridProps {
  billingAccount: BillingAccount
  isLoading?: boolean
}

export const BillingAccountDetails: React.FC<BillingAccountDetailsProps> = ({ billingAccount, isLoading, ...rest }) => {
  const Alert = useAlerts()
  const balanceTransferModal = useDisclosure()
  const billingContactInfoEdit = useDisclosure()
  const { hasFeature } = useAcl()
  const hasTransferFunds = hasFeature('transferFunds')
  const canEditBillingContact = hasFeature('editBillingAccountContact')

  const [form, setForm] = useImmer<BillingEditFormProps>({
    original: {},
    current: {},
  })
  const isDirty = useMemo(() => !dequal(form.current, form.original), [form])

  useEffect(() => {
    setForm((draft) => {
      // eslint-disable-next-line prefer-const
      let { autoReload, autoReloadAmount, autoReloadLowLimit, name } = billingAccount || DEFAULT_VALUES
      draft.original = {
        autoReload,
        autoReloadAmount: !!autoReloadAmount && autoReloadAmount > MAX_AMOUNT ? MAX_AMOUNT : autoReloadAmount,
        autoReloadLowLimit: !!autoReloadLowLimit && autoReloadLowLimit > MAX_AMOUNT ? MAX_AMOUNT : autoReloadLowLimit,
        name,
      }
      draft.current = {
        autoReload,
        autoReloadAmount: !!autoReloadAmount && autoReloadAmount > MAX_AMOUNT ? MAX_AMOUNT : autoReloadAmount,
        autoReloadLowLimit: !!autoReloadLowLimit && autoReloadLowLimit > MAX_AMOUNT ? MAX_AMOUNT : autoReloadLowLimit,
        name,
      }
    })
  }, [billingAccount, setForm])

  const updateBillingAccount = useGraphqlMutation(UpdateBillingAccountDocument)

  const teamsVariables = useMemo(() => {
    return {
      filter: {
        settings_billingAccountIds: { billingAccountId: { eq: billingAccount?.id } },
        status: { eq: Status.Active },
      },
    }
  }, [billingAccount?.id])

  const teamsQuery = useGraphqlQuery(TeamsDocument, teamsVariables)
  useAlertError(teamsQuery.error)
  const teamNames = useMemo(() => teamsQuery.data?.teams?.map((t) => t.name) || [], [teamsQuery.data?.teams])

  const isFundsAccount = billingAccount?.type === BillingAccountType.Funds
  const getFunds = useGraphqlQuery(
    GetFundsDocument,
    { billingAccountId: billingAccount?.id as string },
    { enabled: isFundsAccount && !!billingAccount?.id }
  )
  useAlertError(getFunds.error)
  const zouraFunds = useMemo(() => getFunds.data?.getFunds, [getFunds.data?.getFunds])

  const handleReset = async () => setForm({ ...form, current: form.original })

  const handleSubmit = async () => {
    if (!billingAccount) return
    const convertedAmts = { ...form.current }
    if (typeof convertedAmts.autoReloadAmount !== 'number' || typeof convertedAmts.autoReloadLowLimit !== 'number')
      return
    try {
      await updateBillingAccount.mutateAsync({ id: billingAccount.id, data: convertedAmts })
      Alert.success('Billing Account Updated')
    } catch (err) {
      Alert.error(err)
    }
  }

  const isUSBillingAccount = useMemo(() => billingAccount?.currency === Currency.Usd, [billingAccount?.currency])

  return (
    <>
      <Grid
        flex="900px 0 1"
        templateColumns={{
          base: '1fr',
          xl: `repeat(auto-fit, minmax(0, 1fr))`,
        }}
        justifyItems="center"
        gap={8}
        {...rest}
      >
        {!!billingAccount && !isUSBillingAccount && !isFundsAccount && (
          <ZCard
            w="100%"
            maxW="900px"
            mx="auto"
            h="fit-content"
            variant="form"
          >
            <ZCardHeader
              fontSize="md"
              fontWeight="bold"
            >
              Subscription Account
            </ZCardHeader>
            {teamNames?.length ? (
              <Wrap spacing={2}>
                {teamNames.map((name, idx) => (
                  <UiTag
                    key={`${name}-${idx}`}
                    fontWeight="bold"
                    px={4}
                    py={2}
                  >
                    {name}
                  </UiTag>
                ))}
              </Wrap>
            ) : (
              <ZText>
                Your subscription is handled via invoices. Please contact support for questions and concerns.
              </ZText>
            )}
          </ZCard>
        )}

        {isFundsAccount && (
          <Stack
            spacing={8}
            w="100%"
            maxW="900px"
            mx="auto"
          >
            <ZCard
              h="fit-content"
              isLoading={isLoading}
              variant="form"
            >
              <ZCardHeader
                fontSize="lg"
                fontWeight="normal"
                color="atomicGray.600"
                p={8}
                pb={0}
              >
                Current Balance
              </ZCardHeader>
              {zouraFunds ? (
                <>
                  <ZMoney
                    cents={zouraFunds}
                    currency={billingAccount?.currency}
                    fontSize="5xl"
                    fontWeight="bold"
                    color="atomicGray.500"
                    px={8}
                  />
                  {hasTransferFunds && zouraFunds > 0 && (
                    <ZLink
                      onClick={balanceTransferModal.onOpen}
                      p={8}
                    >
                      Set up a balance transfer
                    </ZLink>
                  )}
                </>
              ) : (
                <ZText
                  p={8}
                  color="atomicGray.600"
                >
                  You currently have no funds available.
                </ZText>
              )}
            </ZCard>
            <ZCard
              w="100%"
              isLoading={teamsQuery.isFetching}
              variant="form"
            >
              <ZCardHeader
                fontSize="lg"
                fontWeight="normal"
                color="atomicGray.600"
                p={8}
                pb={0}
              >
                Active Teams
              </ZCardHeader>
              {teamNames?.length ? (
                <Wrap
                  spacing={2}
                  p={8}
                >
                  {teamNames.map((name, idx) => (
                    <UiTag
                      key={`${name}-${idx}`}
                      fontWeight="normal"
                      fontSize="sm"
                      color="atomicGray.600"
                      bgColor="atomicGray.10"
                      px={2}
                      py={1}
                    >
                      {name}
                    </UiTag>
                  ))}
                </Wrap>
              ) : (
                <ZText
                  p={8}
                  color="atomicGray.600"
                >
                  You have no active teams for this account.
                </ZText>
              )}
            </ZCard>
          </Stack>
        )}

        {isUSBillingAccount && (
          <ZCard
            w="100%"
            maxW="900px"
            mx="auto"
            variant="form"
          >
            <ZCardHeader
              fontSize="lg"
              color="atomicGray.600"
              p={8}
              pb={0}
            >
              Payment Method
            </ZCardHeader>
            <VStack
              spacing={8}
              p={8}
            >
              <UiFormControl>
                <ZFormLabel
                  fontSize="md"
                  fontWeight="normal"
                  color="atomicGray.600"
                  pb={2}
                >
                  Credit Card
                </ZFormLabel>
                <PaymentMethodComponent
                  partnerPaymentMethod={findPartnerPaymentMethod(billingAccount)}
                  billingAccount={billingAccount}
                />
              </UiFormControl>

              {isFundsAccount && (
                <UiFormControl>
                  <BillingAddFunds
                    billingAccount={billingAccount}
                    hasPaymentAccount={!!findPartnerPaymentMethod(billingAccount)?.partnerId}
                    paymentPartnerType={findPaymentPartner(billingAccount)?.type}
                    partnerPaymentMethod={findPartnerPaymentMethod(billingAccount)}
                  />
                </UiFormControl>
              )}

              {isFundsAccount && (
                <UiFormControl>
                  <BillingAutoReload
                    billingAccount={billingAccount}
                    form={form}
                    setForm={setForm}
                  />
                </UiFormControl>
              )}

              {isDirty && <Divider my={0} />}

              <Collapse in={isDirty}>
                <Flex
                  justifyContent="center"
                  w="100%"
                >
                  <ButtonGroup
                    spacing={8}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <ZButton
                      size="md"
                      colorScheme="atomicBlue"
                      justifyContent="center"
                      width="125px"
                      onClick={handleSubmit}
                    >
                      Save
                    </ZButton>
                    <ZButton
                      size="md"
                      variant="ghost"
                      colorScheme="atomicGray"
                      justifyContent="center"
                      width="125px"
                      onClick={handleReset}
                    >
                      Reset
                    </ZButton>
                  </ButtonGroup>
                </Flex>
              </Collapse>

              <Divider my={0} />

              <UiGoogleCaptchaPolicies />
            </VStack>
          </ZCard>
        )}

        {(isFundsAccount || billingAccount?.billingContactInfo) && (
          <Stack
            spacing={8}
            w="100%"
            maxW="900px"
            mx="auto"
          >
            {isFundsAccount && <BillingRequestByInvoice billingAccount={billingAccount} />}

            {canEditBillingContact && billingAccount?.billingContactInfo && (
              <ZCard
                w="100%"
                maxW="900px"
                mx="auto"
                h="fit-content"
                variant="form"
              >
                <ZCardHeader
                  p={8}
                  pb={0}
                  fontSize="lg"
                  color="atomicGray.600"
                >
                  Contact Info
                  <IconButton
                    aria-label="edit text"
                    variant="link"
                    color="atomicBlue.400"
                    _hover={{ color: 'atomicBlue.600' }}
                    minW="0px"
                    onClick={billingContactInfoEdit.onOpen}
                  >
                    <MdOutlineEdit size="22px" />
                  </IconButton>
                </ZCardHeader>
                <Stack
                  spacing={2}
                  p={8}
                >
                  {billingAccount.billingContactInfo?.firstName && (
                    <ZText>{`${billingAccount.billingContactInfo?.firstName} ${billingAccount.billingContactInfo.lastName}`}</ZText>
                  )}
                  {billingAccount.billingContactInfo?.email && (
                    <ZText>{billingAccount.billingContactInfo?.email}</ZText>
                  )}
                  {billingAccount.billingContactInfo?.phone && (
                    <ZText>{billingAccount.billingContactInfo?.phone}</ZText>
                  )}
                  {billingAccount.billingContactInfo?.address && (
                    <Box>
                      <ZHeading
                        as="p"
                        size="h6"
                        my={5}
                      >
                        Address
                      </ZHeading>
                      <ZText>{billingAccount.billingContactInfo?.address.line1}</ZText>
                      {billingAccount.billingContactInfo?.address.line2 && (
                        <ZText>{billingAccount.billingContactInfo?.address.line2}</ZText>
                      )}
                      <ZText>
                        {billingAccount.billingContactInfo?.address.city},{' '}
                        {billingAccount.billingContactInfo?.address.state}{' '}
                        {billingAccount.billingContactInfo?.address.postalCode}
                      </ZText>
                      <ZText>{billingAccount.billingContactInfo?.address.country}</ZText>
                    </Box>
                  )}
                </Stack>
              </ZCard>
            )}
          </Stack>
        )}
      </Grid>
      {balanceTransferModal.isOpen && (
        <BalanceTransferCreateModal
          fromBillingAccountId={billingAccount?.id}
          {...balanceTransferModal}
        />
      )}
      {billingContactInfoEdit.isOpen && billingAccount && (
        <BillingContactInfoEdit
          billingAccount={billingAccount}
          {...billingContactInfoEdit}
        />
      )}
    </>
  )
}
