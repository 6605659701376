import type { GridProps } from '@chakra-ui/react'
import { Flex, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import type { BillingAccount } from 'api'
import { useAcl } from 'hooks'
import { useSearchParams } from 'react-router-dom'
import { BillingAccountDetails } from './BillingAccountDetails'
import { BillingAccountLedger } from './BillingAccountLedger'
import { BillingAccountReceipts } from './BillingAccountReceipts'

interface BillingAccountOverviewProps extends GridProps {
  billingAccount: BillingAccount
  isLoading?: boolean
}

export const BillingAccountOverview: React.FC<BillingAccountOverviewProps> = ({
  billingAccount,
  isLoading,
  ...rest
}) => {
  const { hasFeature } = useAcl()
  const hasLedger = hasFeature('ledger')

  const [searchParams, setSearchParams] = useSearchParams({ tab: String(0) })

  return (
    <>
      <Flex justifyContent="center">
        <Stack w="100%">
          <Tabs
            colorScheme="atomicBlue"
            index={Number(searchParams.get('tab'))}
            onChange={(idx) => setSearchParams((prev) => ({ ...prev, tab: String(idx) }))}
            isLazy
          >
            <TabList>
              <Tab>Account Details</Tab>
              {!hasLedger ? <Tab>Receipts</Tab> : <Tab>Ledger</Tab>}
            </TabList>
            <TabPanels pt={4}>
              <TabPanel>
                <BillingAccountDetails
                  billingAccount={billingAccount}
                  isLoading={isLoading}
                  {...rest}
                />
              </TabPanel>
              {!hasLedger ? (
                <TabPanel>
                  <BillingAccountReceipts
                    billingAccount={billingAccount}
                    isLoading={isLoading}
                  />
                </TabPanel>
              ) : (
                <TabPanel>
                  <BillingAccountLedger
                    billingAccount={billingAccount}
                    isLoading={isLoading}
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </Stack>
      </Flex>
    </>
  )
}
