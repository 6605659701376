import { useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { omitDeep, sanitize, useAlerts } from '@postal-io/postal-ui'
import type { ApprovedPostal, ApprovedPostalUpdateInput, MarketplaceProduct, ProductVariant } from 'api'
import { GetApprovedPostalDocument, GetMarketplaceProductDocument, Status, UpdateApprovedPostalDocument } from 'api'
import { CATEGORY } from 'components/Postals'
import { POSTAL_INVALIDATIONS, useApprovedPostalVersion, useBackgroundQueue } from 'hooks'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MarketplacePostalEditor } from './MarketplacePostalEditor'

export const MarketplaceApprovedPostalEdit: React.FC = () => {
  const transform = useApprovedPostalVersion()
  const Alert = useAlerts()
  const loadingModal = useDisclosure()
  const { approvedPostalId } = useParams() as any
  const navigate = useNavigate()

  const approvedPostalQuery = useGraphqlQuery(GetApprovedPostalDocument, { id: approvedPostalId })
  const postal = useMemo(
    () => approvedPostalQuery.data?.getApprovedPostal,
    [approvedPostalQuery.data?.getApprovedPostal]
  )

  const marketplaceProductQuery = useGraphqlQuery(
    GetMarketplaceProductDocument,
    { id: postal?.marketplaceProductId as string },
    { enabled: !!postal?.marketplaceProductId }
  )

  const activeVariants = useMemo(
    () => postal?.variants?.filter((v) => v.status === Status.Active) || [],
    [postal?.variants]
  )

  const availableVariants = useMemo(() => {
    return (
      marketplaceProductQuery.data?.getMarketplaceProduct?.variants?.filter((v) => v.status === Status.Active) || []
    )
  }, [marketplaceProductQuery.data?.getMarketplaceProduct?.variants])

  const { queue, invalidate } = useBackgroundQueue()
  const updatePostal = useGraphqlMutation(UpdateApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.updateApprovedPostal.previewGenerationTask)
      invalidate(POSTAL_INVALIDATIONS)
    },
  })

  const onUpdate = async ({
    name,
    displayName,
    description,
    isActive,
    designTemplate,
    variants,
    teamIds,
    event,
    attribution,
  }: any) => {
    if (!postal) return

    const data: ApprovedPostalUpdateInput = {
      name,
      displayName,
      description: sanitize(description),
      designTemplate: omitDeep(designTemplate, 'id'),
      teamIds,
      event,
      attribution,
      ...transform(postal.category, {
        items: variants?.map((variant: string) => ({ marketplaceProductId: postal.marketplaceProductId, variant })),
        variants,
      }),
    }
    if (postal.category !== CATEGORY.Events) {
      data.status = isActive ? Status.Active : Status.Disabled
    }

    try {
      loadingModal.onOpen()
      await updatePostal.mutateAsync({ id: postal.id, data })
      Alert.success(`Approved Item Updated`)
      loadingModal.onClose()
      navigate(`/items/postals/${approvedPostalId}`)
    } catch (err) {
      loadingModal.onClose()
      Alert.error(err)
    }
  }

  if (!postal) return null

  return (
    <MarketplacePostalEditor
      postal={postal as MarketplaceProduct | ApprovedPostal}
      activeVariants={activeVariants as ProductVariant[]}
      availableVariants={availableVariants}
      isLoading={loadingModal.isOpen}
      onUpdate={onUpdate}
    />
  )
}
