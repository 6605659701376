import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiLoading, useAlerts } from '@postal-io/postal-ui'
import { CATEGORY } from 'components/Postals'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetApprovedPostalDocument } from '../../api'

export const RedirectItem: React.FC = () => {
  const { id } = useParams() as any
  const navigate = useNavigate()
  const Alert = useAlerts()
  const getApprovedPostal = useGraphqlQuery(GetApprovedPostalDocument, { id })

  const { state } = useLocation() as any

  useEffect(() => {
    if (getApprovedPostal.isLoading) return

    const item = getApprovedPostal?.data?.getApprovedPostal
    if (!item || getApprovedPostal.error) {
      Alert.warning('Item Not Found')
      navigate(-1)
      return
    }

    switch (item.category) {
      case CATEGORY.Events:
        navigate(`/events/postals/${item.id}`, state)
        break
      case CATEGORY.Collection:
        navigate(`/items/collections/${item.id}`, state)
        break
      default:
        navigate(`/items/postals/${item.id}`, state)
    }
  }, [
    Alert,
    getApprovedPostal?.data?.getApprovedPostal,
    getApprovedPostal.error,
    getApprovedPostal.isLoading,
    navigate,
    state,
  ])

  return <UiLoading />
}
