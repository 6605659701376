import { Box } from '@chakra-ui/react'
import type { UiSelectTypeaheadProps } from '@postal-io/postal-ui'
import { UiSelectTypeahead } from '@postal-io/postal-ui'
import { HiddenInput } from 'components/Common'
import React, { useRef } from 'react'

interface RequiredSelectTypeaheadProps extends UiSelectTypeaheadProps<any, any> {
  isRequired?: boolean
}

export const RequiredSelectTypeahead: React.FC<RequiredSelectTypeaheadProps> = ({
  isClearable,
  isDisabled,
  isRequired,
  value,
  ...rest
}) => {
  const ref = useRef<HTMLElement>(null)

  return (
    <Box position="relative">
      <UiSelectTypeahead
        inputRef={ref}
        isClearable={isClearable ?? false}
        isDisabled={isDisabled}
        value={value}
        {...rest}
      />
      <HiddenInput
        isDisabled={isDisabled}
        isRequired={isRequired ?? true}
        onFocus={() => ref.current?.focus()}
        value={value ? 'value' : undefined}
      />
    </Box>
  )
}
