import { Divider, FormControl, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiCard,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerFooterButtons,
  UiDrawerHeader,
  UiDrawerOverlay,
  UiFormLabel,
  UiInput,
  UiInputPlace,
  UiStateInput,
  UiSwitch,
  useAlerts,
} from '@postal-io/postal-ui'
import type { Address, AddressInput, ContactInput, ContactUpdateInput } from 'api'
import { AddressSource, ContactType, UpdateContactDocument } from 'api'
import { AutoCompleteCountry } from 'components/AutoComplete/AutoCompleteCountry'
import { CONTACT_INVALIDATIONS, useBackgroundQueue } from 'hooks'
import React, { useEffect } from 'react'
import type { Updater } from 'use-immer'
import { useImmer } from 'use-immer'
import { VerifyAddress } from './VerifyAddress'

interface ContactAddressEditProps {
  addresses: AddressInput[] | null
  setFormAddresses?: Updater<ContactInput> | Updater<ContactUpdateInput>
  contactId?: string
  selectedIndx?: number
  isOpen: boolean
  onClose: () => void
}

const defaultForm = {
  entryName: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  preferred: false,
  source: AddressSource.Manual,
}

export const ContactAddressEdit: React.FC<ContactAddressEditProps> = ({
  selectedIndx,
  contactId,
  isOpen,
  addresses,
  setFormAddresses,
  onClose,
}) => {
  const Alert = useAlerts()
  const verifyAddress = useDisclosure()

  const [form, setForm] = useImmer<Address>(defaultForm)

  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = target

    if (name === 'preferred') {
      setForm((draft: Record<string, any>) => void (draft.preferred = checked))
    } else {
      setForm((draft: Record<string, any>) => void (draft[name] = value))
    }
  }

  useEffect(() => {
    if (typeof selectedIndx === 'number') {
      const selected = addresses?.find((_item, idx) => idx === selectedIndx) as Address
      setForm(selected)
    }
  }, [addresses, selectedIndx, setForm])

  const { invalidate } = useBackgroundQueue()
  const updateContact = useGraphqlMutation(UpdateContactDocument, {
    onSuccess: () => invalidate(CONTACT_INVALIDATIONS),
  })

  const onVerifiedAdd = async (verifyAddress: Address) => {
    const newAddress = { ...verifyAddress } as AddressInput

    //normalize fields from form
    newAddress.source = AddressSource.Manual
    newAddress.preferred = addresses?.length === 0 ? true : form?.preferred
    newAddress.entryName = form?.entryName

    let addressInputs = addresses || []

    if (newAddress?.preferred && addressInputs.length > 0) {
      addressInputs = addressInputs.map((address) => {
        return { ...address, preferred: false }
      })
    }

    //Add new Address to the address input array
    addressInputs = [...addressInputs, { ...newAddress }]

    const hasPreferred = addressInputs.some((item) => item.preferred)
    if (!hasPreferred) {
      return Alert.warning('Please select a preferred address.')
    }

    if (setFormAddresses) {
      setFormAddresses((draft: Record<string, any>) => void (draft.addresses = addressInputs))
      onClose()
    }
  }

  const onVerifiedUpdate = async (verifiedAddress: Address) => {
    let addressInputs = addresses?.map((address, idx) => {
      if (idx === selectedIndx) {
        return {
          ...verifiedAddress,
          //These values are coming back from the updated form fields
          source: form?.source || AddressSource.Manual,
          entryName: form?.entryName,
          preferred: form?.preferred || false,
        }
      } else {
        return { ...address }
      }
    })

    //if the selected address has been updated and is prefered, remap addresses)
    if (form?.preferred) {
      addressInputs = addressInputs?.map((address, idx) => {
        return { ...address, preferred: idx === selectedIndx }
      })
    }

    if (setFormAddresses) {
      setFormAddresses((draft: Record<string, any>) => void (draft.addresses = addressInputs))
      onClose()
    } else {
      const hasPreferred = addressInputs?.some((item) => item.preferred)
      if (addressInputs?.length && !hasPreferred) {
        return Alert.warning('Please select a preferred address.')
      }

      //There is a mixture of AddressInputs and Address normalization is needed befor submitting.
      const cleanedAddressInputs = addressInputs?.map((item) => {
        return {
          entryName: item?.entryName,
          address1: item?.address1,
          address2: item?.address2,
          address3: item?.address3,
          city: item?.city,
          state: item?.state,
          postalCode: item?.postalCode,
          country: item?.country,
          preferred: item?.preferred,
          source: item?.source,
          status: item?.status,
        }
      }) as AddressInput[]

      try {
        await updateContact.mutateAsync({
          id: contactId as string,
          data: {
            type: ContactType.Contact,
            addresses: cleanedAddressInputs as AddressInput[],
          },
        })
        onClose()
      } catch (err) {
        Alert.error(err)
      }
    }
  }

  const handleVerfiedSubmit = (verfiedAddress: any) => {
    if (typeof selectedIndx === 'number') {
      onVerifiedUpdate(verfiedAddress)
    } else {
      onVerifiedAdd(verfiedAddress)
    }
  }

  const handleUpdateAddress = (address: Partial<Address>) => {
    const dataCopy = { ...address, preferred: form?.preferred || false }
    setForm(dataCopy)
  }

  const handleVerify = (e: React.FormEvent) => {
    e.preventDefault()
    verifyAddress.onOpen()
  }

  return (
    <>
      <UiDrawer
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
      >
        <UiDrawerOverlay />
        <form onSubmit={handleVerify}>
          <UiDrawerContent>
            <UiDrawerCloseButton />
            <UiDrawerHeader>{typeof selectedIndx === 'number' ? 'Edit Contact Address' : 'Add Address'}</UiDrawerHeader>
            <UiDrawerBody>
              <UiCard position="relative">
                <FormControl
                  display="flex"
                  alignItems="center"
                  id="preferred"
                >
                  <UiSwitch
                    onChange={handleInput}
                    data-testid="preferredCheckAdd"
                    isChecked={addresses?.length === 0 ? true : form?.preferred}
                    size="lg"
                    name="preferred"
                  />
                  <UiFormLabel ml={2}>Preferred Address</UiFormLabel>
                </FormControl>
                <SimpleGrid
                  columns={2}
                  spacing={4}
                  mt={4}
                >
                  <FormControl id="search">
                    <UiFormLabel>Search Google for an Address</UiFormLabel>
                    <UiInputPlace
                      onChange={(addr: Partial<Address>) => {
                        handleUpdateAddress(addr)
                      }}
                    />
                  </FormControl>
                  <FormControl id="entryName">
                    <UiFormLabel>Address Label</UiFormLabel>
                    <UiInput
                      onChange={handleInput}
                      value={form?.entryName || ''}
                      name="entryName"
                      placeholder="Work, Home, etc.."
                      data-private
                    />
                  </FormControl>
                </SimpleGrid>

                <Divider my={8} />

                <SimpleGrid
                  columns={2}
                  spacing={4}
                >
                  <FormControl
                    id="address1"
                    isRequired
                  >
                    <UiFormLabel>Street Address 1</UiFormLabel>
                    <UiInput
                      onChange={handleInput}
                      value={form?.address1 || ''}
                      name="address1"
                      data-private
                    />
                  </FormControl>

                  <FormControl id="address2">
                    <UiFormLabel>Street Address 2</UiFormLabel>
                    <UiInput
                      onChange={handleInput}
                      value={form?.address2 || ''}
                      name="address2"
                      data-private
                    />
                  </FormControl>

                  <FormControl
                    id="city"
                    isRequired
                  >
                    <UiFormLabel>City</UiFormLabel>
                    <UiInput
                      onChange={handleInput}
                      value={form?.city || ''}
                      name="city"
                      data-private
                    />
                  </FormControl>

                  <FormControl
                    id="postalCode"
                    isRequired
                  >
                    <UiFormLabel>Postal Code</UiFormLabel>
                    <UiInput
                      onChange={handleInput}
                      value={form?.postalCode || ''}
                      name="postalCode"
                      data-private
                    />
                  </FormControl>

                  <FormControl
                    id="state"
                    isRequired
                  >
                    <UiFormLabel>State</UiFormLabel>
                    <UiStateInput
                      onChange={handleInput}
                      country={form?.country || undefined}
                      value={form?.state || ''}
                      name="state"
                      data-private
                    />
                  </FormControl>

                  <FormControl id="country">
                    <UiFormLabel>Country</UiFormLabel>
                    <AutoCompleteCountry
                      data-private
                      countryName={form.country}
                      onChange={(country) => setForm((draft) => void (draft.country = country?.iso3 || ''))}
                    />
                  </FormControl>
                </SimpleGrid>
              </UiCard>
            </UiDrawerBody>

            <UiDrawerFooter>
              <UiDrawerFooterButtons>
                <UiButton
                  colorScheme="gray"
                  onClick={onClose}
                >
                  Cancel
                </UiButton>
                <UiButton
                  type="submit"
                  isLoading={updateContact.isLoading}
                  isDisabled={updateContact.isLoading}
                >
                  {typeof selectedIndx === 'number' ? 'Update' : 'Add'} Address
                </UiButton>
              </UiDrawerFooterButtons>
            </UiDrawerFooter>
          </UiDrawerContent>
        </form>
      </UiDrawer>
      {verifyAddress.isOpen && form && (
        <VerifyAddress
          address={form}
          onVerified={handleVerfiedSubmit}
          {...verifyAddress}
        />
      )}
    </>
  )
}
