import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import { UiButton, UiInputSearch, UiSSDataTable, useGraphqlFilter } from '@postal-io/postal-ui'
import type { SearchableContact, SearchContactsV2QueryVariables } from 'api'
import { SearchContactsV2Document } from 'api'
import { useAcl } from 'hooks'
import React, { useMemo } from 'react'
import { ContactCreate } from '../Contact/ContactCreate'
import { defaultSort, initialState, qSort, TRANSFORMS } from './ContactsData'
import { ContactsFilter } from './ContactsFilter'
import { COLUMNS, DEFAULT_FILTER, STATIC_VARIABLES } from './SelectContactsData'
import type { SelectContactsState } from './useSelectContacts'

/*
  Pass back contacts and filter to **onSelect** callback
    - If ALL contacts are selected, send the raw filter, without limit/skip
    - If SOME contacts are selected, do an id IN filter
  onSelect({
    contacts: [{}, {}, {}],
    filter: {}
  })
*/
export interface SelectContactsProps {
  onSelect: (e: SelectContactsState) => void
}

export const SelectContacts: React.FC<SelectContactsProps> = ({ onSelect, ...rest }) => {
  const { hasFeature } = useAcl()
  const canSearch = hasFeature('search')

  const graphqlFilter = useGraphqlFilter<SearchContactsV2QueryVariables>({
    transforms: TRANSFORMS,
    debounce: 400,
    staticVariables: STATIC_VARIABLES,
    initialState,
  })

  const searchContacts = useGraphqlInfiniteQuery(SearchContactsV2Document, graphqlFilter.variables)

  const contacts = useMemo(
    () => searchContacts.mergedData?.searchContactsV2?.searchableContacts || [],
    [searchContacts.mergedData?.searchContactsV2?.searchableContacts]
  )
  const summary = useMemo(
    () => searchContacts.mergedData?.searchContactsV2?.resultsSummary,
    [searchContacts.mergedData?.searchContactsV2?.resultsSummary]
  )

  const handleSelect = (selected: SearchableContact[]) => {
    if (selected.length === contacts.length) {
      onSelect({
        filter: graphqlFilter.variables.filter || DEFAULT_FILTER,
        count: summary?.totalRecords || 0,
        contacts: contacts,
      })
    } else {
      onSelect({
        filter: { id: { in: selected.map((c) => c.id) } },
        count: selected.length,
        contacts: selected,
      })
    }
  }

  const handleSearch = ({ target }: any) => {
    const { value } = target
    graphqlFilter.setFilter({ key: 'q', value })
    graphqlFilter.setOrderBy(value ? qSort : defaultSort)
  }

  const addContact = useDisclosure()

  return (
    <>
      <Box {...rest}>
        <ContactsFilter
          {...graphqlFilter}
          showLists
        />
        <Flex
          alignItems="center"
          mt={4}
        >
          {canSearch && (
            <UiInputSearch
              rootProps={{ flex: 2, mr: 4, size: 'lg', minW: '250px' }}
              placeholder="Search Contacts"
              value={graphqlFilter.filter.q || ''}
              onChange={handleSearch}
            />
          )}
          <UiButton
            size="lg"
            colorScheme="primary"
            onClick={addContact.onOpen}
          >
            Create Contact
          </UiButton>
        </Flex>
        <UiSSDataTable
          isLoading={searchContacts.isFetching}
          mt={4}
          columns={COLUMNS}
          rows={contacts}
          rowKey="id"
          showSelect={true}
          onSelect={handleSelect}
          hasMore={searchContacts.hasNextPage}
          fetchMore={searchContacts.fetchNextPage}
          filter={graphqlFilter.variables.filter}
          totalRecords={summary?.totalRecords}
        />
      </Box>
      {addContact.isOpen && (
        <ContactCreate
          isOpen={addContact.isOpen}
          onClose={addContact.onClose}
        />
      )}
    </>
  )
}
