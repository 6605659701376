import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { PlaybookDefinition } from 'api'
import { GetApprovedPostalDocument } from 'api'
import { isPostalDisabled } from 'components/PostalSend/postalSendHelpers'
import { PostalSendLoading } from 'components/PostalSend/PostalSendLoading'
import { PostalUnavailable } from 'components/PostalSend/PostalUnavailable'
import type { PostalSendContext } from 'components/PostalSend/usePostalSend'
import { usePostalPermissions, useRouteBack } from 'hooks'
import { getContextFromPlaybookStep, getPlaybookStepFromContext } from 'lib/playbooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { PostalSend } from '../PostalSend'

export const PlaybookDefinitionStepPostalSend: React.FC = () => {
  const navigate = useNavigate()
  const { playbookStepIdx, playbookId, approvedPostalId } = useParams() as any

  const { state } = useLocation() as any
  // store the location state in memory since it seems to disappear
  const [currentPlaybookState] = useState<PlaybookDefinition>(state?.playbook)
  const currentStepState = currentPlaybookState?.steps?.[playbookStepIdx]

  // are we editing or creating?
  const location = useLocation()
  const mode = useMemo(
    () => (location.pathname.startsWith('/subscriptions/create') ? 'create' : 'edit'),
    [location.pathname]
  )

  const postalQuery = useGraphqlQuery(GetApprovedPostalDocument, { id: approvedPostalId })
  const postal = useMemo(() => postalQuery.data?.getApprovedPostal, [postalQuery.data?.getApprovedPostal])

  const defaultBackUrl = mode === 'create' ? `/subscriptions/create` : `/subscriptions/${playbookId}/edit`
  const back = useRouteBack('Subscription', defaultBackUrl)
  const onNavigateBack = useCallback(() => {
    back.path ? navigate(back.path, { state: { updatedPlaybook: currentPlaybookState } }) : navigate(-1)
  }, [back.path, currentPlaybookState, navigate])

  const { defaultNonLinkSendMethod } = usePostalPermissions(postal)

  const context = getContextFromPlaybookStep({ step: currentStepState, postal })

  const handleUpdateStep = (context: PostalSendContext) => {
    const newSteps = currentPlaybookState?.steps ?? []
    newSteps.splice(playbookStepIdx, 1, getPlaybookStepFromContext(context))

    const updatedPlaybook = {
      ...currentPlaybookState,
      steps: newSteps,
    }

    // pass updated state back to create/edit modal
    navigate(mode === 'edit' ? `/subscriptions/${playbookId}/edit` : '/subscriptions/create', {
      state: { updatedPlaybook },
    })
  }

  useEffect(() => {
    // we're missing the current playbook state => user refreshed page and purged it. go back to the edit page
    if (mode === 'edit' && !currentPlaybookState) onNavigateBack()
  }, [currentPlaybookState, mode, onNavigateBack])

  if (postalQuery.isLoading) {
    return (
      <PostalSendLoading
        onNavigateBack={onNavigateBack}
        backLabel={back.title}
      />
    )
  }

  if (!postal || isPostalDisabled(postal)) {
    return (
      <PostalUnavailable
        isOpen
        onClose={onNavigateBack}
      />
    )
  }

  return (
    <PostalSend
      {...context}
      method={context.method ?? defaultNonLinkSendMethod}
      onNavigateBack={onNavigateBack}
      onExit={onNavigateBack}
      navigateBackLabel={back.title}
      onComplete={handleUpdateStep}
    />
  )
}
