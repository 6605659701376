import type { GridProps } from '@chakra-ui/react'
import { UiImageCollage } from '@postal-io/postal-ui'
import type { ApprovedPostal, DesignTemplate, MarketplaceProduct } from 'api'
import { useAssets, usePostalImages, usePrintPreview } from 'hooks'
import { useMemo } from 'react'
import fallbackSrc from '../../assets/fallback.png'

export interface PostalImageCollageProps extends GridProps {
  postal: ApprovedPostal | MarketplaceProduct
}

export const PostalImageCollage: React.FC<PostalImageCollageProps> = ({ postal, ...rest }) => {
  const { assetUrlSrc } = useAssets()

  const postalImages = usePostalImages(postal)

  const images = postalImages.map((image) => {
    const { src, fallbackSrc } = assetUrlSrc(image.url)
    return {
      src,
      fallbackSrc,
      title: image.description || '',
    }
  })

  return (
    <UiImageCollage
      imageUrls={images}
      w="100%"
      {...rest}
    />
  )
}

export interface PostalImageCollageDirectMailProps extends PostalImageCollageProps {
  designTemplate?: DesignTemplate | null
  userMessage?: string
}

export const PostalImageCollageDirectMail: React.FC<PostalImageCollageDirectMailProps> = ({
  designTemplate,
  userMessage,
  ...rest
}) => {
  const { front, back } = usePrintPreview(designTemplate, {
    userMessage,
  })
  const images = useMemo(() => {
    return [
      { url: front, alt: 'Side 1', title: 'Side 1' },
      { url: back, alt: 'Side 2', title: 'Side 2' },
    ].map((img) => ({
      ...img,
      src: img.url,
      fallbackSrc,
    }))
  }, [back, front])

  return (
    <UiImageCollage
      imageUrls={images}
      w="100%"
      {...rest}
    />
  )
}
