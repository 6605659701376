import {
  Flex,
  FormControl,
  Grid,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import type { UseGraphqlFilterResponse } from '@postal-io/postal-ui'
import { LexendFontWeight, SelectTypeaheadStylesV2, UiSelectTypeahead, ZFormLabel } from '@postal-io/postal-ui'
import { Status } from 'api'
import React from 'react'
import { MdFilterList } from 'react-icons/md'
import { useTriggerProviders } from './useTriggerProviders'

/**
 * Provides a form used to set query param filters.  The results are
 * pushed back to the caller via the onChange callback.  This function
 * is debounced according to the debounce prop.
 *
 * It would be nice to extract some of this logic into re-usable componens
 */

export interface TriggersFilterProps {
  graphqlFilter: UseGraphqlFilterResponse
}

export const TriggersFilter: React.FC<TriggersFilterProps> = ({ graphqlFilter }) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const { providers } = useTriggerProviders()

  const integrationOptions = providers.map((provider) => ({ label: provider.name, value: provider.system }))

  const statusOptions = [
    { label: 'Active', value: Status.Active },
    { label: 'Disabled', value: Status.Disabled },
  ]

  return (
    <Popover
      arrowSize={30}
      isOpen={isOpen}
      gutter={16}
      onClose={onClose}
      placement="left"
    >
      <PopoverTrigger>
        <IconButton
          aria-label="Filter"
          color="gray.500"
          onClick={onToggle}
          size="sm"
          variant="ghost"
        >
          <MdFilterList size={20} />
        </IconButton>
      </PopoverTrigger>
      <PopoverContent
        borderRadius={20}
        pb={24}
        pt={4}
        px={10}
        w={600}
      >
        <PopoverArrow />
        <PopoverBody>
          <Flex>
            <Text
              mx="auto"
              color="grey.900"
              fontSize={21}
              fontWeight={LexendFontWeight.Regular}
            >
              Filter
            </Text>
          </Flex>
          <PopoverCloseButton
            colorScheme="grey.300"
            onClick={onClose}
            right={5}
            top={8}
          />
          <Grid
            templateColumns={{ base: '1fr' }}
            columnGap={8}
            rowGap={7}
            mt={4}
          >
            <FormControl id="integration">
              <ZFormLabel>Integration</ZFormLabel>
              <UiSelectTypeahead
                name="systemName"
                value={integrationOptions.find((option) => option.value === graphqlFilter.filter.systemName)}
                placeholder="Select Integration"
                onChange={(e: any) => graphqlFilter.setFilter({ key: 'systemName', value: e?.value })}
                options={integrationOptions}
                {...SelectTypeaheadStylesV2}
              />
            </FormControl>
            <FormControl id="status">
              <ZFormLabel>Status</ZFormLabel>
              <UiSelectTypeahead
                name="status"
                value={statusOptions.find((option) => option.value === graphqlFilter.filter.status)}
                placeholder="Select Status"
                onChange={(e: any) => graphqlFilter.setFilter({ key: 'status', value: e?.value })}
                options={statusOptions}
                {...SelectTypeaheadStylesV2}
              />
            </FormControl>
          </Grid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
