import { Stack } from '@chakra-ui/react'
import { ZCard, ZCardDivider } from '@postal-io/postal-ui'
import type { PostalSendContext, PostalSendEventV2 } from 'components/PostalSend/usePostalSend'
import {
  canHaveRecipientNotifications,
  canIncludeLandingPage,
  PostalSendMethod,
} from 'components/PostalSend/usePostalSend'
import { AnalyticsEventV2, useAnalyticsEvent, useExtension } from 'hooks'
import React from 'react'
import { PostalCustomizeBulkSend } from './PostalCustomizeBulkSend'
import { PostalCustomizeCampaign } from './PostalCustomizeCampaign'
import { PostalCustomizeDelay } from './PostalCustomizeDelay'
import { PostalCustomizeEmail } from './PostalCustomizeEmail'
import { PostalCustomizeLandingPage } from './PostalCustomizeLandingPage'
import { PostalCustomizeLink } from './PostalCustomizeLink'
import { PostalCustomizeMeeting } from './PostalCustomizeMeeting'
import { PostalCustomizePhysicalMessage } from './PostalCustomizePhysicalMessage'
import { PostalCustomizeRecipientNotifications } from './PostalCustomizeRecipientNotifications'
import { PostalCustomizeSendingOptions } from './PostalCustomizeSendingOptions'
import { PostalSendMethodHeader } from './PostalSendMethodHeader'

export interface PostalCustomizeV2Props {
  context: PostalSendContext
  send: (evt: PostalSendEventV2) => void
}

/**
 * Master customization module
 */
export const PostalCustomize: React.FC<PostalCustomizeV2Props> = ({ context, send }) => {
  const { isExtension } = useExtension()

  useAnalyticsEvent({ event: AnalyticsEventV2.ExtensionSendFlowConfigurationStep, disabled: !isExtension })

  return (
    <Stack spacing={8}>
      {/* First card with send method header & most customization options */}
      <ZCard
        variant="form"
        pt={{ base: 4, md: 8 }}
        borderWidth={{ base: 0, md: 1 }}
      >
        <Stack
          spacing={0}
          divider={<ZCardDivider />}
        >
          <PostalSendMethodHeader context={context} />

          {/* Note: the show/hide logic must be at this level to work well with the dividers in the Stack element */}
          {context.method === PostalSendMethod.Link && (
            <PostalCustomizeLink
              context={context}
              send={send}
            />
          )}

          {context.method === PostalSendMethod.Email && (
            <PostalCustomizeEmail
              context={context}
              send={send}
            />
          )}

          {canIncludeLandingPage(context) && (
            <PostalCustomizeLandingPage
              context={context}
              send={send}
            />
          )}

          {context.method === PostalSendMethod.BulkSend && (
            <PostalCustomizeBulkSend
              context={context}
              send={send}
            />
          )}

          {canHaveRecipientNotifications(context) && (
            <PostalCustomizeRecipientNotifications
              context={context}
              send={send}
            />
          )}
        </Stack>
      </ZCard>

      {/* Other specialized cards - these will hide themselves if not applicable */}
      <PostalCustomizePhysicalMessage
        context={context}
        send={send}
      />
      <PostalCustomizeDelay
        context={context}
        send={send}
      />
      <PostalCustomizeMeeting
        context={context}
        send={send}
      />
      <PostalCustomizeCampaign
        context={context}
        send={send}
      />
      <PostalCustomizeSendingOptions
        context={context}
        send={send}
      />
    </Stack>
  )
}
