import {
  humanize,
  ZButton,
  ZSelectPopover,
  ZSelectPopoverBody,
  ZSelectPopoverContent,
  ZSelectPopoverFooter,
  ZSelectPopoverItem,
  ZSelectPopoverItems,
  ZSelectPopoverTrigger,
  ZText,
} from '@postal-io/postal-ui'
import { ActivityType } from 'api'
import { useMemo } from 'react'

const ACTIVITY_TYPES = [ActivityType.Action, ActivityType.Delivered, ActivityType.Update]

export const ActivityStreamTypeFilter: React.FC<{
  value?: string[]
  onChange: (types: ActivityType[]) => void
  isFirst?: boolean
  isLast?: boolean
}> = ({ value, onChange, isFirst, isLast }) => {
  const title = useMemo(
    () => (value?.length ? `Type${value?.length > 1 ? 's' : ''}` : 'Select a Type'),
    [value?.length]
  )

  return (
    <ZSelectPopover
      placement="bottom-start"
      size="lg"
    >
      <ZSelectPopoverTrigger
        isFirst={isFirst}
        isLast={isLast}
        color="atomicGray.500"
        fontWeight="normal"
      >
        {!!value?.length && (
          <ZText
            color="atomicBlue.400"
            mr={1}
          >{`(${value?.length}) `}</ZText>
        )}
        {title}
      </ZSelectPopoverTrigger>
      <ZSelectPopoverContent bg="white">
        <ZSelectPopoverBody>
          <ZSelectPopoverItems
            onChange={onChange}
            value={value}
            overflow="scroll"
          >
            {ACTIVITY_TYPES.map((type) => {
              return (
                <ZSelectPopoverItem
                  key={type}
                  value={type}
                >
                  <ZText
                    ml={2}
                    fontWeight="normal"
                    fontSize="lg"
                    color="atomicGray.600"
                  >
                    {humanize(type)}
                  </ZText>
                </ZSelectPopoverItem>
              )
            })}
          </ZSelectPopoverItems>
        </ZSelectPopoverBody>

        <ZSelectPopoverFooter
          borderTopColor="atomicGray.100"
          borderTopWidth="2px"
        >
          <ZButton
            variant="link"
            onClick={() => onChange([])}
            w="100%"
            mx="auto"
            display={value?.length ? 'block' : 'none'}
          >
            Clear
          </ZButton>
        </ZSelectPopoverFooter>
      </ZSelectPopoverContent>
    </ZSelectPopover>
  )
}
