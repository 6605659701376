import { createId } from '@paralleldrive/cuid2'

export interface CreateElementProps {
  id: string
  name: string
  location: any
  settings: any
}

export const createElement = (
  { id = createId(), name, location = {}, settings = {} }: { id: string; name: string; location: any; settings: any },
  pos = { x: 0, y: 0 }
) => {
  const element = {
    id,
    name,
    location: {
      x: pos.x,
      y: pos.y,
      ...location,
    },
  }
  switch (name) {
    case 'Text':
      return {
        ...element,
        settings: {
          randomLeftOffset: true,
          // eslint-disable-next-line no-template-curly-in-string
          handwritingName: '${user.handwritingName}',
          horizontalAlignment: 'LEFT',
          verticalAlignment: 'TOP',
          color: '#000000',
          fontSize: 20,
          spacingModifier: -8,
          sizeToFit: true,

          text: 'Example text. We recommend you keep your messaging simple, so that it looks clean and authentic.',
          ...settings,
        },
      }
    case 'UserMessage':
      return {
        ...element,
        settings: {
          randomLeftOffset: true,
          // eslint-disable-next-line no-template-curly-in-string
          handwritingName: '${user.handwritingName}',
          horizontalAlignment: 'LEFT',
          verticalAlignment: 'TOP',
          color: '#000000',
          fontSize: 20,
          spacingModifier: -8,
          sizeToFit: true,
          // eslint-disable-next-line no-template-curly-in-string
          text: '${userMessage}',
          ...settings,
        },
      }
    case 'Border':
      return {
        ...element,
        settings: {
          thicknessPixels: 8,
          color: '#125D8C',
          ...settings,
        },
      }
    case 'Image':
      return {
        ...element,
        settings: {
          drawMode: 'SCALE_DOWN',
          ...settings,
        },
      }
    case 'QrCode':
      return {
        ...element,
        settings: {
          value: 'http://www.postal.com',
          ...settings,
        },
        location: {
          ...location,
          x: pos.x,
          y: pos.y,
          width: 50,
          height: 50,
        },
      }
    case 'Logo':
      return {
        ...element,
        settings: {
          drawMode: 'SCALE_DOWN',
          ...settings,
        },
        location: {
          ...location,
          x: pos.x,
          y: pos.y,
          width: 250,
          height: 75,
        },
      }
    default:
      throw new Error('Invalid Type')
  }
}
