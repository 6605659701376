import type { GridProps } from '@chakra-ui/react'
import { Box, Flex, Grid, Image, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlertError, ZLink, ZText } from '@postal-io/postal-ui'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import type { PostalAnalyticsV2QueryVariables } from '../../../api/index'
import { DataObjectType, GroupBy, PostalAnalyticsV2Document, SortBy } from '../../../api/index'
import { useAssets } from '../../../hooks'
import { MetricV2, PLACEHOLDER_POSTAL_ANALYTICS_V2 } from '../Overview/data'

const LIMIT = 5

const ENABLE_IMAGES = false

interface SummaryPopularSendsItemProps {
  metricType: MetricV2
  postalAnalytic: any
}

const SummaryPopularSendsItem: React.FC<SummaryPopularSendsItemProps> = ({ metricType, postalAnalytic }) => {
  const { approvedPostalId, name, placeholderImage, previewImageUrl } = postalAnalytic as any

  const { assetUrlSrc } = useAssets()
  const { src, fallbackSrc } = assetUrlSrc(previewImageUrl, { fit: 'crop', crop: 'edges', w: 80, h: 80 })

  const metricTypeMap = useMemo(
    () => ({
      [MetricV2.OverallAccepted]: postalAnalytic.accepted,
      [MetricV2.MagicLinks]: postalAnalytic.linkUniqViews,
      [MetricV2.GiftEmails]: postalAnalytic.deliveryEmailAccepted,
    }),
    [postalAnalytic.accepted, postalAnalytic.deliveryEmailAccepted, postalAnalytic.linkUniqViews]
  )

  const value = useMemo(() => metricTypeMap[metricType], [metricType, metricTypeMap])

  const showImage = (!!src || !!placeholderImage) && ENABLE_IMAGES

  const navigate = useNavigate()

  const handleLink = (e: any) => {
    e.preventDefault()
    navigate(`/items/postals/${approvedPostalId}`, { state: { returnTo: 'Reporting Summary' } })
  }

  return (
    <>
      <Grid
        templateColumns={showImage ? '1fr 3fr' : '1fr'}
        gap={2}
        mb={showImage ? 6 : 2}
        w="100%"
      >
        {showImage && (
          <Box
            borderRadius="postal"
            borderWidth="1px"
            borderColor="atomicGray.300"
            boxSizing="border-box"
            overflow="hidden"
          >
            <Image
              src={src}
              fallbackSrc={fallbackSrc}
            />
          </Box>
        )}
        <Box
          overflow="hidden"
          pb={2}
        >
          <ZLink
            isDisabled={!showImage}
            opacity={1}
            color="atomicGray.700"
            fontSize="sm"
            fontWeight="normal"
            onClick={handleLink}
            title={name || ''}
          >
            <ZText color="inherit">{name}</ZText>
          </ZLink>
          <Flex
            alignItems="flex-start"
            justifyContent="space-between"
            w="100%"
            color="atomicGray.500"
          >
            <ZText
              fontSize="sm"
              color="inherit"
            >
              Accepted
            </ZText>
            <ZText
              fontSize="sm"
              color="inherit"
            >
              {value ?? 0}
            </ZText>
          </Flex>
        </Box>
      </Grid>
    </>
  )
}

interface SummaryPopularSendsV2Props extends GridProps {
  userId?: string
  teamId?: string
  metricType: MetricV2
  showPlaceholder: boolean
  startDate: Date
  endDate: Date
}

const metricTypeSortByMap = {
  [MetricV2.OverallAccepted]: SortBy.AcceptedDesc,
  [MetricV2.MagicLinks]: SortBy.LinkUniqViewDesc,
  [MetricV2.GiftEmails]: SortBy.GiftEmailSentDesc,
}

export const SummaryPopularSends: React.FC<SummaryPopularSendsV2Props> = ({
  userId,
  teamId,
  metricType,
  showPlaceholder,
  startDate,
  endDate,
}) => {
  const variables = useMemo(() => {
    const query: PostalAnalyticsV2QueryVariables = {
      config: {
        startDate,
        endDate,
        type: DataObjectType.Postal,
        limit: LIMIT,
        sortBy: metricTypeSortByMap[metricType],
        groupBy: GroupBy.Postal,
      },
    }
    if (userId) query.config.userIds = [userId]
    if (teamId) query.config.teamIds = [teamId]
    return query
  }, [endDate, metricType, startDate, teamId, userId])

  const postalAnalyticsQuery = useGraphqlQuery(PostalAnalyticsV2Document, variables, {
    keepPreviousData: true,
    enabled: (!!userId || !!teamId) && !showPlaceholder,
  })

  const postalAnalytics = useMemo(() => {
    return showPlaceholder
      ? PLACEHOLDER_POSTAL_ANALYTICS_V2[metricType]
      : postalAnalyticsQuery?.data?.postalAnalyticsV2 ?? []
  }, [metricType, postalAnalyticsQuery?.data?.postalAnalyticsV2, showPlaceholder])

  useAlertError(postalAnalyticsQuery.error)

  return (
    <Box>
      {postalAnalytics.length === 0 && !showPlaceholder ? (
        <ZText textAlign="center">Insufficient data</ZText>
      ) : postalAnalyticsQuery.isFetching ? (
        <Grid templateColumns="1fr 3fr">
          <SkeletonCircle />
          <SkeletonText
            w="75%"
            noOfLines={2}
            spacing={4}
            skeletonHeight={2}
          />
        </Grid>
      ) : (
        postalAnalytics.map((postalAnalytic, idx) => (
          <SummaryPopularSendsItem
            key={idx}
            metricType={metricType}
            postalAnalytic={postalAnalytic}
          />
        ))
      )}
    </Box>
  )
}
