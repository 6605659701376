import { Text } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { UiConfirm, useAlerts } from '@postal-io/postal-ui'
import type { ContactList, SearchableContactFilterInput } from 'api'
import { BulkContactAddToListDocument, BulkContactRemoveFromListDocument, CreateContactListDocument } from 'api'
import { CONTACT_INVALIDATIONS, useBackgroundQueue, useSession } from 'hooks'
import React, { useMemo } from 'react'

interface ContactsFavoriteV2Props {
  selectedList?: ContactList
  favoriteList?: ContactList
  isOpen: boolean
  onClose: () => void
  onComplete: () => void
  count: number
  filters?: SearchableContactFilterInput[]
}

export const ContactsFavorite: React.FC<ContactsFavoriteV2Props> = ({
  isOpen,
  selectedList,
  favoriteList,
  onClose,
  onComplete,
  count,
  filters,
}) => {
  const {
    session: { userId },
  } = useSession()

  const Alert = useAlerts()

  const isFavoriteListSelected = useMemo(
    () => selectedList && selectedList.id === favoriteList?.id,
    [favoriteList?.id, selectedList]
  )

  const { queue, invalidate } = useBackgroundQueue()

  // mutations
  const addToFavorites = useGraphqlMutation(BulkContactAddToListDocument, {
    onSuccess: (data) => {
      queue(data.bulkContactAddToList)
      invalidate(CONTACT_INVALIDATIONS)
    },
  })

  const removeFromFavorites = useGraphqlMutation(BulkContactRemoveFromListDocument, {
    onSuccess: (data) => {
      queue(data.bulkContactRemoveFromList)
      invalidate(CONTACT_INVALIDATIONS)
    },
  })
  const createContactList = useGraphqlMutation(CreateContactListDocument)

  const isLoading = addToFavorites.isLoading || createContactList.isLoading || removeFromFavorites.isLoading

  const addFavorite = async () => {
    const res = await createContactList.mutateAsync({ data: { name: 'Favorites' } })
    return res?.createContactList
  }

  const onConfirm = async () => {
    try {
      const theList = favoriteList || (await addFavorite())
      if (!theList) return Alert.error('Error Creating Favorites List')

      const variables = {
        data: {
          name: theList.name,
          listId: theList.id,
          userId,
        },
        orfilters: filters,
      }

      if (isFavoriteListSelected) {
        await removeFromFavorites.mutateAsync(variables)
        Alert.success('Contacts removed from favorites')
      } else {
        await addToFavorites.mutateAsync(variables)
        Alert.success('Contacts added to favorites')
      }
    } catch (err) {
      Alert.error('Error saving Favorites')
    } finally {
      onComplete()
      onClose()
    }
  }

  return (
    <UiConfirm
      title={isFavoriteListSelected ? 'Remove from Favorites' : 'Add to Favorites'}
      isOpen={isOpen}
      onConfirm={onConfirm}
      onClose={onClose}
      isLoading={isLoading}
    >
      <Text>
        Are you sure you want to {isFavoriteListSelected ? 'remove' : 'add'} {count > 1 ? 'these' : 'this'} {count}{' '}
        {count > 1 ? 'contacts' : 'contact'} {isFavoriteListSelected ? 'from' : 'to'} favorites?
      </Text>
    </UiConfirm>
  )
}
