import type { LinkProps } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { ZLink } from '@postal-io/postal-ui'
import type { UserLink } from 'api'
import { useAcl } from 'hooks'
import { Link } from 'react-router-dom'

export interface UserLinkLinkProps extends LinkProps {
  userId?: string | null
  userLink?: UserLink | null
  fallback?: string | null
}
export const UserLinkLink = ({ userId, userLink, fallback, ...rest }: UserLinkLinkProps) => {
  const { hasPermission } = useAcl()
  if (!userLink?.fullName) return fallback ? <Text>{fallback}</Text> : null
  if (!userId || !hasPermission('users.read')) return <Text>{userLink.fullName}</Text>
  return (
    <ZLink
      as={Link}
      to={{ pathname: `/users/${userId}`, state: { returnTo: 'Triggers' } }}
      {...rest}
    >
      {userLink.fullName}
    </ZLink>
  )
}
