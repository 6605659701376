import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  FormControl,
} from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiSelect, useAlerts, ZFormLabel, ZInput, ZInputDate } from '@postal-io/postal-ui'
import type { CampaignInput } from 'api'
import { CampaignStatus, GetCampaignDocument, UpdateCampaignDocument } from 'api'
import { format, parseISO, startOfTomorrow } from 'date-fns'
import React, { useEffect, useRef } from 'react'
import { useImmer } from 'use-immer'

interface GroupOrderEditProps {
  isOpen: boolean
  isDirect: boolean
  onClose: () => void
  campaignId?: string
}

export const GroupOrderEdit: React.FC<GroupOrderEditProps> = ({ isOpen, isDirect, onClose, campaignId }) => {
  const getCampaign = useGraphqlQuery(GetCampaignDocument, { id: campaignId as string })
  const updateCampaign = useGraphqlMutation(UpdateCampaignDocument)
  const today = format(new Date(), 'yyyy-MM-dd')
  const cancelRef = useRef(null)

  const [form, setForm] = useImmer<CampaignInput>({ name: '', status: CampaignStatus.Pending })

  useEffect(() => {
    const campaign = getCampaign.data?.getCampaign
    if (!campaign) return
    setForm(() => ({
      name: campaign.name,
      status: campaign.status,
      scheduleDate: campaign.scheduleDate ? parseISO(campaign.scheduleDate) : undefined,
    }))
  }, [getCampaign.data?.getCampaign, setForm])

  const Alert = useAlerts()

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!form.scheduleDate && form.status !== CampaignStatus.Pending) {
      return Alert.error('Please enter a valid date or change the group order to Pending status.')
    }
    if (form.status === CampaignStatus.Processing || form.status === CampaignStatus.Complete) {
      return Alert.error('This group order has already been processed and can no longer be modified.')
    }
    try {
      await updateCampaign.mutateAsync({ id: campaignId as string, data: form })
      Alert.success('Group order data updated')
      onClose()
    } catch (e) {
      Alert.error(e)
    }
  }
  return (
    <AlertDialog
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Update Group Order Info</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form
            onSubmit={onSubmit}
            id="campaignForm"
          >
            <Flex
              justifyContent="center"
              direction="column"
            >
              <FormControl
                id="name"
                isRequired
              >
                <ZFormLabel mb={{ base: 1, md: 1 }}>Name</ZFormLabel>
                <ZInput
                  mb={5}
                  name="name"
                  type="text"
                  value={form.name}
                  onChange={(e: any) => setForm((draft) => void (draft.name = e.target.value))}
                />
              </FormControl>
              <FormControl
                id="scheduleDate"
                mb={5}
              >
                <ZFormLabel mb={{ base: 1, md: 1 }}>Start Date</ZFormLabel>
                <ZInputDate
                  name="scheduleDate"
                  value={form.scheduleDate || ''}
                  onChange={(e) => setForm((draft) => void (draft.scheduleDate = e.target.value))}
                  data-min-date={isDirect ? format(startOfTomorrow(), 'yyyy-MM-dd') : today}
                  enableTime={!isDirect}
                />
              </FormControl>
              <FormControl id="status">
                <ZFormLabel mb={{ base: 1, md: 1 }}>Status</ZFormLabel>
                <UiSelect
                  mb={15}
                  h="40px"
                  name="status"
                  value={form.status}
                  onChange={(e) => setForm((draft) => void (draft.status = e.target.value as CampaignStatus))}
                >
                  <option value={CampaignStatus.Pending}>Pending</option>
                  <option value={CampaignStatus.Scheduled}>Scheduled</option>
                  <option value={CampaignStatus.Cancelled}>Canceled</option>
                </UiSelect>
              </FormControl>
            </Flex>
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            type="submit"
            onClick={onSubmit}
            minW={32}
          >
            Update Group Order
          </Button>
          <Button
            ref={cancelRef}
            variant="ghost"
            colorScheme="atomicGray"
            onClick={onClose}
            minW={32}
          >
            Cancel
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
