import type { FlexProps } from '@chakra-ui/react'
import { Flex, Spacer } from '@chakra-ui/react'
import { ZLink, ZText } from '@postal-io/postal-ui'
import { GroupBy, Role } from 'api'
import { StickyElementWithShadowV2 } from 'components/Common/StickyElementWithShadowV2'
import { useAcl } from 'hooks'
import React, { useCallback, useMemo } from 'react'
import { DateRangeOption } from './data'
import type { DateFilterV2Props } from './DateFilterSearch'
import { DateFilterSearch } from './DateFilterSearch'
import type { GroupByOptionV2 } from './GroupByFilter'
import { GroupByFilter } from './GroupByFilter'
import { TeamsFilterSearch } from './TeamsFilterSearch'
import type { UseReportingFilterV2 } from './useReportingFilter'
import { UsersFilterSearch } from './UsersFilterSearch'

// TODO:
// one must have a User role to send things, which is required to have any stats
// perhaps we want to switch to that?  not sure leaving here to think about it
// const ROLES = [Role.User]

const ROLES = [Role.Admin, Role.TeamAdmin, Role.Manager, Role.User]

type ReportingFilterV2Props = FlexProps & Omit<UseReportingFilterV2, 'filter'>

export const ReportingFilter: React.FC<ReportingFilterV2Props> = ({
  state,
  setState,
  showGranularity,
  resetState,
  isChanged,
  ...rest
}) => {
  const { hasPermission } = useAcl()

  // only admins should be able to create teams
  const canFilterTeams = hasPermission('teams.create')

  // managers, team admins, and admins should fulfill this permission
  // NOTE: if we are grouping by teams, we do not show the user filter
  const canFilterUsers = hasPermission('users.query')

  // if groupBy is changed to TEAM then we need to clear
  // out the previously selected users
  const handleGroupByFilterV2 = useCallback(
    (groupBy: GroupByOptionV2) => {
      setState((draft) => {
        draft.groupBy = groupBy
        if (groupBy === GroupBy.Team) draft.userIds = []
      })
    },
    [setState]
  )

  const handleTeamsFilter = useCallback(
    (teamIds: string[]) => setState((draft) => void (draft.teamIds = teamIds)),
    [setState]
  )

  const handleUsersFilter = useCallback(
    (userIds: string[]) => setState((draft) => void (draft.userIds = userIds)),
    [setState]
  )

  const handleDateFilter = useCallback(
    ({ startDate, endDate, granularity, dateRangeOption }: DateFilterV2Props) => {
      setState((draft) => {
        if (startDate) draft.startDate = startDate
        if (endDate) draft.endDate = endDate
        draft.granularity = granularity
        draft.dateRangeOption = dateRangeOption
      })
    },
    [setState]
  )

  const dateRangeDescription = useMemo(() => {
    if (state.dateRangeOption !== DateRangeOption.Custom) return state.dateRangeOption
    if (!state.startDate || !state.endDate) return state.dateRangeOption
    return `${state.startDate.toLocaleDateString()} - ${state.endDate.toLocaleDateString()}`
  }, [state.dateRangeOption, state.endDate, state.startDate])

  return (
    <StickyElementWithShadowV2
      pb={2}
      px={0}
      zIndex={2}
    >
      <Flex
        alignItems="center"
        flexWrap="wrap"
        gridGap={4}
        {...rest}
      >
        {canFilterTeams && canFilterUsers && (
          <Flex alignItems="center">
            <ZText
              fontWeight="normal"
              color="atomicGray.500"
              userSelect="none"
              pr={4}
            >
              Display by:
            </ZText>
            <GroupByFilter
              groupBy={state.groupBy}
              onChange={handleGroupByFilterV2}
            />
          </Flex>
        )}

        <Flex alignItems="center">
          <ZText
            fontWeight="normal"
            color="atomicGray.500"
            userSelect="none"
            pr={4}
          >
            Filter by:
          </ZText>
          <Flex gap={2}>
            {canFilterTeams && (
              <TeamsFilterSearch
                value={state.teamIds}
                onChange={handleTeamsFilter}
                isFirst
                isLast
              />
            )}
            {canFilterUsers && state.groupBy === GroupBy.User && (
              <UsersFilterSearch
                value={state.userIds}
                onChange={handleUsersFilter}
                teamIds={state.teamIds}
                roles={ROLES}
                isFirst
                isLast
              />
            )}
            <DateFilterSearch
              startDate={state.startDate}
              endDate={state.endDate}
              granularity={state.granularity}
              showGranularity={showGranularity}
              dateRangeOption={state.dateRangeOption}
              onChange={handleDateFilter}
              isFirst
              isLast
            />
          </Flex>
          {isChanged && (
            <ZLink
              onClick={resetState}
              ml={4}
            >
              Reset All
            </ZLink>
          )}
        </Flex>

        <Spacer />
        <ZText color="atomicGray.500">{dateRangeDescription}</ZText>
      </Flex>
    </StickyElementWithShadowV2>
  )
}
