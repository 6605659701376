import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react'
import { FontWeight, UiButton, UiCard, UiCardHeader } from '@postal-io/postal-ui'
import { useAcl } from 'hooks'
import React from 'react'
import teaserImage from '../../assets/images/magiclinks-teaser.png'

export interface LinkTeaserProps {
  onClick?: () => void
}

export const LinkTeaser: React.FC<LinkTeaserProps> = ({ onClick }) => {
  const handleClick = () => {
    onClick && onClick()
  }

  const { hasPermission } = useAcl()
  const canCreate = hasPermission('links.create')

  return (
    <UiCard
      p={0}
      backgroundColor="white"
      width="95%"
      mx="auto"
      h="70vh"
      maxW="95rem"
    >
      <Grid
        templateColumns={{ base: '1fr', xl: '1fr 3fr' }}
        h="100%"
        w="100%"
      >
        <Flex
          py={8}
          backgroundColor="header.dark"
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Flex
            direction="column"
            alignItems="center"
          >
            <UiCardHeader
              color="white"
              fontSize={{ base: '2xl', md: '4xl' }}
              fontWeight={FontWeight.Bold}
            >
              MagicLink
            </UiCardHeader>
            <Text
              w="90%"
              color="white"
              fontSize={{ base: 'lg', md: 'xl' }}
              textAlign="center"
            >
              The easiest way to send an Item.{' '}
            </Text>
            {!canCreate && (
              <Text
                w="90%"
                color="white"
                fontSize={{ base: 'lg', md: 'xl' }}
                textAlign="center"
                mt={6}
              >
                A User role is required to create a MagicLink.
              </Text>
            )}
          </Flex>
          {canCreate ? (
            <UiButton
              colorScheme="tertiary"
              size="lg"
              width="200px"
              onClick={handleClick}
              mb={4}
            >
              Create a MagicLink
            </UiButton>
          ) : (
            <Box />
          )}
        </Flex>
        <Image
          p={4}
          mx="auto"
          width={{ base: '90%', xl: '100%' }}
          h="100%"
          minH="400px"
          alignSelf={{ base: 'center', xl: 'flex-end' }}
          src={teaserImage}
          objectFit="contain"
        />
      </Grid>
    </UiCard>
  )
}
