import { SimpleGrid } from '@chakra-ui/react'
import { ZCard, ZCardBody, ZCardHeader, ZSelect } from '@postal-io/postal-ui'
import { MeetingRequestSetting } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import type { PostalSendContext, PostalSendEventV2 } from 'components/PostalSend/usePostalSend'
import { useAcl, useMe } from 'hooks'
import React from 'react'
import { MEETING_REQUEST_TOOLTIP } from '../Profile/userMeetingSettingsData'
import { PostalSendMethod } from './postalSendHelpers'

interface PostalCustomizeMeetingV2Props {
  context: PostalSendContext
  send: (evt: PostalSendEventV2) => void
}

export const PostalCustomizeMeeting: React.FC<PostalCustomizeMeetingV2Props> = ({ context, send, ...rest }) => {
  const { me } = useMe()
  const { hasFeature } = useAcl()

  const handleRequestDefault = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = target
    send({ type: 'SET_MEETING_REQUEST', data: value })
  }

  if (
    !hasFeature('meetingSettings') ||
    !me?.meetingSettings?.enabled ||
    ![PostalSendMethod.Email, PostalSendMethod.Link].includes(context.method)
  ) {
    return null
  }

  return (
    <ZCard
      variant="form"
      pt={{ base: 4, md: 8 }}
      borderWidth={{ base: 0, md: 1 }}
      {...rest}
    >
      <ZCardBody>
        <ZCardHeader
          justifyContent="flex-start"
          alignItems="center"
          gap={2}
          mb={8}
        >
          Meeting Request
          <ZInfoTooltip
            label={MEETING_REQUEST_TOOLTIP}
            whiteSpace="break-spaces"
          />
        </ZCardHeader>
        <SimpleGrid
          columns={2}
          spacing={8}
        >
          <ZSelect
            name="meetingSettings"
            onChange={handleRequestDefault}
            value={context.meetingRequestSetting || ''}
          >
            <option value={MeetingRequestSetting.No}>Off</option>
            <option value={MeetingRequestSetting.RequireBefore}>Before Item Acceptance</option>
            <option value={MeetingRequestSetting.AskAfter}>After Item Acceptance</option>
          </ZSelect>
        </SimpleGrid>
      </ZCardBody>
    </ZCard>
  )
}
