import type { BoxProps } from '@chakra-ui/react'
import type { GraphqlFilterState } from '@postal-io/postal-ui'
import type { PostalSendContext } from 'components/PostalSend/usePostalSend'
import React from 'react'
import type { SearchableContact, SearchableContactFilterInput } from '../../api'
import { defaultSort, qSort } from '../Contacts/ContactsData'
import { MultiSelectContactsTable } from './MultiSelectContactsTable'
import type { MultiSelectFilter, UseMultiSelect } from './useMultiSelect'

export interface MultiSelectContactsState {
  filters: MultiSelectFilter<SearchableContactFilterInput>[]
  items: SearchableContact[]
  orfilters: SearchableContactFilterInput[]
  totalRecords: number
}

export interface MultiSelectContactsV3Props extends BoxProps {
  context?: PostalSendContext
  graphqlFilter: GraphqlFilterState
  state: UseMultiSelect<SearchableContact, SearchableContactFilterInput>
}

export const MultiSelectContacts: React.FC<MultiSelectContactsV3Props> = ({ graphqlFilter, state }) => {
  const handleSearch = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target
    graphqlFilter.setFilter({ key: 'q', value })
    graphqlFilter.setOrderBy(value ? qSort : defaultSort)
  }

  return (
    <>
      <MultiSelectContactsTable
        filter={graphqlFilter.filter}
        graphqlFilter={graphqlFilter}
        variables={graphqlFilter.variables}
        state={state}
        onSearch={handleSearch}
      />
    </>
  )
}
