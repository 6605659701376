import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react'
import { UiButton, UiTable, UiTableContainer, UiTbody, UiTd, UiTr, useAlerts } from '@postal-io/postal-ui'
import React, { useEffect } from 'react'
import { customEmailVariables } from './customEmailData'

export const CustomEmailVariables: React.FC = () => {
  const { onCopy, value, setValue } = useClipboard('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const Alert = useAlerts()

  useEffect(() => {
    if (!value || !onCopy) return
    onCopy()
    Alert.success(`Copied ${value} to clipboard`)
    setValue('')
    onClose()
  }, [onCopy, value, Alert, onClose, setValue])

  const handleVariable = (variable: string) => {
    setValue(`{{${variable}}}`)
  }

  return (
    <Popover
      trigger="click"
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <UiButton
          variant="link"
          onClick={onOpen}
        >
          Email Variables
        </UiButton>
      </PopoverTrigger>
      <PopoverContent
        zIndex={99999}
        minW="fit-content"
        bg="app.bg"
      >
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Personalize Your Email with Variables</PopoverHeader>
        <PopoverBody bg="app.bg">
          <UiTableContainer variant="default">
            <UiTable>
              <UiTbody>
                {Object.entries(customEmailVariables).map(([variable, description], idx) => (
                  <UiTr
                    key={idx}
                    {...(idx % 2 === 0 && { bg: 'shades.10' })}
                    _hover={{ bg: 'primary.300', color: 'white' }}
                  >
                    <UiTd
                      p={2}
                      onClick={() => handleVariable(variable)}
                      fontWeight="semibold"
                    >
                      {`{{${variable}}}`}
                    </UiTd>
                    <UiTd
                      p={2}
                      onClick={() => handleVariable(variable)}
                      whiteSpace="nowrap"
                    >
                      {description}
                    </UiTd>
                  </UiTr>
                ))}
              </UiTbody>
            </UiTable>
          </UiTableContainer>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
