import { compact } from 'lodash'
import * as LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import React, { useEffect, useRef } from 'react'
import { useSession } from '../../hooks'

export const LogRocketInit = ({ appId }: { appId?: string }) => {
  if (!appId) return null
  return <LogRocketInitLoader appId={appId} />
}

const LogRocketInitLoader: React.FC<{ appId: string }> = ({ appId }) => {
  const { session } = useSession()
  const initRef = useRef(null)

  useEffect(() => {
    if (!appId) return
    if (!session?.userName || !session?.userId) return
    if (initRef.current === session.userId) return

    initRef.current = session.userId

    LogRocket.init(appId, { release: window.postalVersion })
    setupLogRocketReact(LogRocket)

    LogRocket.identify(session.userId, {
      name: compact([session.firstName, session.lastName]).join(' '),
      email: session.userName,
      accountId: session.accountId,
      accountName: session.accountName,
      productAccessId: session.productAccessId,
      plan: session.plan,
      roles: session.roles?.join(', '),
      teamId: session.teamId,
      teamName: session.teamName,
      product: session.product,
    })
  }, [
    appId,
    session.accountId,
    session.accountName,
    session.firstName,
    session.lastName,
    session.plan,
    session.product,
    session.productAccessId,
    session.roles,
    session.teamId,
    session.teamName,
    session.userId,
    session.userName,
  ])

  return null
}
