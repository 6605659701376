import { useGraphqlFetch } from '@postal-io/postal-graphql'
import { useAlerts } from '@postal-io/postal-ui'
import { GetAccountDocument, MeDocument } from '../../api'
import type { PostalSendContext, PostalSendEventV2 } from './usePostalSend'

export const usePostalSendServices = () => {
  const Alert = useAlerts()
  const getAccount = useGraphqlFetch(GetAccountDocument)
  const getMe = useGraphqlFetch(MeDocument)

  const notifyError = (ctx: PostalSendContext, evt: PostalSendEventV2) => {
    Alert.warning(evt?.data?.message || 'Please enter all required fields')
  }

  const loadAccount = async () => {
    const account = await getAccount()
    const me = await getMe()
    return { account: account.getAccount, me: me.me }
  }

  return { notifyError, loadAccount }
}
