import { Flex, StatNumber } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import { UiStat, UiStatGroup, UiStatHelpText, UiStatLabel, ZCard, ZCardHeader } from '@postal-io/postal-ui'
import { DataObjectType, Granularity, UserAnalyticsV2Document } from 'api'
import { ZStatArrow } from 'components/Common/ZComponents'
import { differenceInMilliseconds, subMilliseconds } from 'date-fns'
import { getChangeMetric } from 'lib'
import { useMemo } from 'react'
import { NoDataOverlay } from '../NoDataOverlay'

interface MagicLinkStatsCardV2Props extends ZCardProps {
  startDate: Date
  endDate: Date
  teamIds?: string[]
  userIds?: string[]
}

export const MagicLinkStatsCard: React.FC<MagicLinkStatsCardV2Props> = ({
  startDate,
  endDate,
  teamIds,
  userIds,
  ...rest
}) => {
  const curQuery = useGraphqlQuery(UserAnalyticsV2Document, {
    config: {
      startDate,
      endDate,
      teamIds,
      userIds,
      granularity: Granularity.All,
      type: DataObjectType.User,
    },
  })
  const { linkUniqViews, linkExecutions, linkNewContact } = useMemo(() => {
    const data = curQuery.data?.userAnalyticsV2 || []
    return data.reduce((sum, user) => {
      sum.linkUniqViews = (sum.linkUniqViews || 0) + (user.linkUniqViews || 0)
      sum.linkExecutions = (sum.linkExecutions || 0) + (user.linkExecutions || 0)
      sum.linkNewContact = (sum.linkNewContact || 0) + (user.linkNewContact || 0)
      return sum
    }, {})
  }, [curQuery.data?.userAnalyticsV2])

  const newStartDate = useMemo(() => {
    const diff = differenceInMilliseconds(endDate, startDate)
    return subMilliseconds(startDate, diff)
  }, [endDate, startDate])

  const prevQuery = useGraphqlQuery(UserAnalyticsV2Document, {
    config: {
      startDate: newStartDate,
      endDate: startDate,
      teamIds,
      userIds,
      granularity: Granularity.All,
      type: DataObjectType.User,
    },
  })

  const {
    linkUniqViews: linkUniqViewsPrev,
    linkExecutions: linkExecutionsPrev,
    linkNewContact: linkNewContactPrev,
  } = useMemo(() => {
    const data = prevQuery.data?.userAnalyticsV2 || []
    return data.reduce((sum, user) => {
      sum.linkUniqViews = (sum.linkUniqViews || 0) + (user.linkUniqViews || 0)
      sum.linkExecutions = (sum.linkExecutions || 0) + (user.linkExecutions || 0)
      sum.linkNewContact = (sum.linkNewContact || 0) + (user.linkNewContact || 0)
      return sum
    }, {})
  }, [prevQuery.data?.userAnalyticsV2])

  const { linkUniqViewsChange, linkExecutionsChange, linkNewContactChange } = useMemo(() => {
    return {
      linkUniqViewsChange: getChangeMetric(linkUniqViews, linkUniqViewsPrev),
      linkExecutionsChange: getChangeMetric(linkExecutions, linkExecutionsPrev),
      linkNewContactChange: getChangeMetric(linkNewContact, linkNewContactPrev),
    }
  }, [linkUniqViews, linkUniqViewsPrev, linkExecutions, linkExecutionsPrev, linkNewContact, linkNewContactPrev])

  const showNoDataOverlayV2 = useMemo(
    () => !curQuery.isFetching && !linkUniqViews,
    [curQuery.isFetching, linkUniqViews]
  )

  const statHelpTextProps = {
    d: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
    mt: 1,
    fontWeight: 'normal',
    color: 'atomicGray.600',
  }

  return (
    <ZCard
      isLoading={prevQuery.isLoading}
      isFetching={prevQuery.isFetching}
      variant="form"
      {...rest}
    >
      {showNoDataOverlayV2 && <NoDataOverlay />}

      <ZCardHeader
        display="flex"
        alignItems="center"
        p={4}
        pb={0}
      >
        MagicLinks
      </ZCardHeader>
      <Flex
        w="100%"
        pt={16}
        minH="260px"
        justify="center"
      >
        <UiStatGroup
          flexWrap="wrap"
          textAlign="center"
          justifyContent="space-around"
          fontFamily="Lexend"
          w="100%"
        >
          <UiStat>
            <UiStatLabel
              fontSize="md"
              maxW="200px"
              h="42px"
              fontWeight="normal"
              textTransform="unset"
              mb={4}
            >
              Views
            </UiStatLabel>
            <StatNumber fontWeight="normal">{linkUniqViews ?? 0}</StatNumber>
            <UiStatHelpText {...statHelpTextProps}>
              <ZStatArrow
                color={linkUniqViewsChange >= 0 ? 'vendorGreen.500' : 'atomicRed.500'}
                type={linkUniqViewsChange >= 0 ? 'increase' : 'decrease'}
              />
              {Math.round(linkUniqViewsChange)}%
            </UiStatHelpText>
          </UiStat>
          <UiStat>
            <UiStatLabel
              fontSize="md"
              maxW="200px"
              h="42px"
              fontWeight="normal"
              textTransform="unset"
              mb={4}
            >
              Accepted
            </UiStatLabel>
            <StatNumber fontWeight="normal">{linkExecutions ?? 0}</StatNumber>
            <UiStatHelpText {...statHelpTextProps}>
              <ZStatArrow
                color={linkExecutionsChange >= 0 ? 'vendorGreen.500' : 'atomicRed.500'}
                type={linkExecutionsChange >= 0 ? 'increase' : 'decrease'}
              />
              {Math.round(linkExecutionsChange)}%
            </UiStatHelpText>
          </UiStat>
          <UiStat>
            <UiStatLabel
              fontSize="md"
              maxW="180px"
              h="42px"
              fontWeight="normal"
              textTransform="unset"
              mb={4}
            >
              New Contacts Generated
            </UiStatLabel>
            <StatNumber fontWeight="normal">{linkNewContact ?? 0}</StatNumber>
            <UiStatHelpText {...statHelpTextProps}>
              <ZStatArrow
                color={linkNewContactChange >= 0 ? 'vendorGreen.500' : 'atomicRed.500'}
                type={linkNewContactChange >= 0 ? 'increase' : 'decrease'}
              />
              {Math.round(linkNewContactChange)}%
            </UiStatHelpText>
          </UiStat>
        </UiStatGroup>
      </Flex>
    </ZCard>
  )
}
