import { Box, Grid, Heading, Image, Text } from '@chakra-ui/react'
import { UiButton } from '@postal-io/postal-ui'
import image from 'assets/images/app-error.svg'
import { clearProductAccessId, clearSession, useQueryParams } from 'hooks'
import { useParams } from 'react-router-dom'
import { FORCED_AUTHENTICATION_TYPES } from '../Security'

const getRequiredType = (type?: string) => {
  return FORCED_AUTHENTICATION_TYPES.find((t) => t.id === type)?.label
}

const DEFAULTS = {
  title: 'Account Disabled',
  message:
    'Oh no. It looks like you no longer have access to this application. Please try logging in again or ask your administrator for access',
  action: 'Sign in Again',
  returnTo: '/sign-in',
  reset: true,
}

type NoAccessParams = typeof DEFAULTS

const merge = (params?: Record<string, string>, defaults?: Partial<NoAccessParams>) => {
  return {
    title: params?.title || defaults?.title || DEFAULTS.title,
    message: params?.message || defaults?.message || DEFAULTS.title,
    action: params?.action || defaults?.action || DEFAULTS.action,
    returnTo: params?.returnTo || defaults?.returnTo || DEFAULTS.returnTo,
    reset: defaults?.reset === false ? false : DEFAULTS.reset,
  }
}

const getError = (errorType?: string, params?: Record<string, string>) => {
  let vars: Partial<NoAccessParams> = {}
  switch (errorType) {
    case 'disabled_user':
      vars = {
        message: `Your account is currently disabled. Please reach out to your administrator.`,
      }
      break
    case 'sso_consent':
      vars = {
        title: 'Consent Required',
        message: `You must agree to share you basic profile information at your authentication provider to continue. Please try logging in again. If this problem persists please contact support.`,
      }
      break
    case 'sso_confirm_access':
      vars = {
        title: 'Confirm Access',
        message: `Please try to login again. We were unable to log you in via Single Sign-on. If the problem persists, please confirm with your IT Team that you are in the appropriate groups to access this application.`,
      }
      break
    case 'auth_blocked':
      const required = getRequiredType(params?.required_type)
      const using = !!required ? ` using ${required}.` : '.'
      vars = {
        title: 'Authentication Type',
        message: `The authentication method used to login is not supported. Please try again${using}`,
      }
      break
    case 'auth_failed':
      vars = {
        title: 'Authentication Failed',
        message: `There was a problem authenticating you with the third-party, please try again`,
        action: 'Try Again',
        returnTo: '/profile',
        reset: false,
      }
      break
  }
  return merge(params, vars)
}

export const NoAccess = () => {
  const { errorType } = useParams()
  const { params } = useQueryParams() as any

  const { title, message, action, returnTo, reset } = getError(errorType, params)

  const onClick = () => {
    if (reset) {
      clearSession()
      clearProductAccessId()
    }
    window.location.href = returnTo
  }

  return (
    <Grid
      templateColumns={{ base: '1fr', lg: '1fr 2fr' }}
      textAlign="center"
      alignItems="center"
      justifyItems="center"
      h="75vh"
      maxW="90rem"
      marginX="auto"
    >
      <Grid
        gridTemplateRows="repeat(3, auto)"
        alignContent="center"
        gridGap={8}
        p={8}
      >
        <Heading
          as="h1"
          fontSize="5xl"
          lineHeight={1}
          color="primary.500"
          whiteSpace="nowrap"
          textTransform="uppercase"
        >
          {title}
        </Heading>
        <Text
          fontSize="lg"
          color="gray.600"
        >
          {message}
        </Text>
        <UiButton
          onClick={onClick}
          colorScheme="tertiary"
          size="lg"
          w="100%"
          mt={4}
        >
          {action}
        </UiButton>
      </Grid>
      <Box>
        <Image
          src={image}
          maxW="600px"
        />
      </Box>
    </Grid>
  )
}
