import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

interface useNavigateLinkEditOptions {
  linkId: string
  returnTo: string
  returnPath?: string
}
export function useNavigateLinkEdit() {
  const location = useLocation()

  return useCallback(
    (options: useNavigateLinkEditOptions) => {
      const search = new URLSearchParams()
      search.set('returnTo', options.returnTo)
      search.set('returnPath', options.returnPath ?? location.pathname)
      return `/links/${options.linkId}/edit?${search}`
    },
    [location.pathname]
  )
}

export function useNavigateLinkEditParams() {
  const params = useParams()
  const [search, setSearch] = useSearchParams()

  const [state] = useState(() => {
    const linkId = params.linkId as string
    const returnTo = search.get('returnTo') as string
    const returnPath = search.get('returnPath') as string
    return { linkId, returnTo, returnPath }
  })

  useEffect(() => {
    setSearch({})
  }, [setSearch])

  return state
}
