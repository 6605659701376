import type { IconProps } from '@chakra-ui/react'
import { UiIconPriceTag, UiIconTag } from '@postal-io/postal-ui'
import { postalBasics, postalConcierge, postalPremium } from 'assets'

export const PRICE_RANGE = []
export const TIMELINE = []
export const PLACEHOLDER = 'Provide some details to get things started. Types of Postals, your use case(s), etc.'

export type FormOptionV2 = 'CONCIERGE_FORM' | 'WAREHOUSE_FORM'

type ConciergeHeroType = {
  imageUrl: string
  title: string
  color: string
  text: string
  linkText: string
  priceRange: string
  timeRange: string
  url: string
  params?: Record<string, any>
}

export const POSTALPATH_HERO_LIST: ConciergeHeroType[] = [
  {
    imageUrl: postalBasics,
    title: 'Postal Basics',
    color: '#00A0FF',
    text: 'Want something ',
    linkText: 'quick and personalized?',
    priceRange: '$5 - $250',
    timeRange: 'Instant - 4 Days',
    url: '/items/marketplace',
    params: { useCases: ['BASICS'] },
  },
  {
    imageUrl: postalPremium,
    title: 'Postal Premium',
    color: '#4CB981',
    text: 'Looking for something ',
    linkText: 'branded with a fast turnaround?',
    priceRange: '$30 - $150',
    timeRange: '1 to 2 Weeks',
    url: '/items/marketplace',
    params: { useCases: ['PREMIUM'] },
  },
  {
    imageUrl: postalConcierge,
    title: 'Postal Concierge',
    color: '#125D8C',
    text: 'Work with us to ',
    linkText: 'design the perfect experience.',
    priceRange: '$ - $$$',
    timeRange: '4 to 8 Weeks',
    url: '/items/concierge',
  },
]

type ConsiergeInfoType = {
  icon: React.FC<IconProps>
  title: string
  titleColor: string
  text: string
  textColor: string
}

export const CONSIERGE_INFO: ConsiergeInfoType[] = [
  {
    icon: UiIconPriceTag,
    title: 'price range',
    titleColor: '#125D8C',
    text: '$ - $$$',
    textColor: '#29404E',
  },
  {
    icon: UiIconTag,
    title: 'time to deliver',
    titleColor: '#125D8C',
    text: '4 to 8 weeks',
    textColor: '#29404E',
  },
]
