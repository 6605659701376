import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiLoading } from '@postal-io/postal-ui'
import { GetApprovedPostalDocument } from 'api'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { CollectionCreateUpdate } from './CollectionCreateUpdate'

export function CollectionAddRoute() {
  const params = useParams()
  const getApprovedPostal = useGraphqlQuery(GetApprovedPostalDocument, { id: params.approvedPostalId as string })
  const collection = useMemo(
    () => getApprovedPostal.data?.getApprovedPostal,
    [getApprovedPostal.data?.getApprovedPostal]
  )
  return getApprovedPostal.isLoading ? <UiLoading /> : <CollectionCreateUpdate collection={collection} />
}
