import { Helmet } from 'lib'

const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY
const SNIPPET_VERSION = '4.1.0'

export const Segment = () => {
  if (!SEGMENT_KEY) return null

  return (
    <Helmet>
      <script
        nonce="7e6d36ae12ce81578f3f61fb0b871750"
        id="segment-init"
      >
        {`
!(function () {
  var analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error("Segment snippet included twice.");
    else {
      analytics.invoked = !0;
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on",
      ];
      analytics.factory = function (t) {
        return function () {
          var e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      };
      for (var t = 0; t < analytics.methods.length; t++) {
        var e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = function (t, e) {
        var n = document.createElement("script");
        n.type = "text/javascript";
        n.async = !0;
        n.src =
          "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
        var a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(n, a);
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = '${SNIPPET_VERSION}';
      analytics.load('${SEGMENT_KEY}');
    }
})();
    `}
      </script>
    </Helmet>
  )
}
