import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import type { UiMenuButtonProps } from '@postal-io/postal-ui'
import { humanize, UiMenu, UiMenuButton, UiMenuItemOption, UiMenuList, UiMenuOptionGroup } from '@postal-io/postal-ui'
import { dequal } from 'dequal'
import { useAcl, useSession } from 'hooks'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Role } from '../../api'

interface MenuUserRoleProps extends Omit<UiMenuButtonProps, 'onChange' | 'children'> {
  isDisabled?: boolean
  value: Role[]
  onChange: (roles: Role[]) => void
  rootProps?: BoxProps
  teamId?: string
  userId?: string
}

const ROLES = [Role.User, Role.Manager, Role.TeamAdmin, Role.Admin]

export const MenuUserRole: React.FC<MenuUserRoleProps> = ({
  isDisabled,
  value,
  onChange,
  rootProps,
  teamId,
  userId,
  ...rest
}) => {
  const rolesTitle = value.length ? value.map(humanize).join(', ') : 'Select Roles'
  const { session } = useSession()
  const { hasFeature, hasRole } = useAcl()
  const isAdmin = hasRole(Role.Admin)

  const hasTeamAdmin = hasFeature('teamAdmin')

  const isSelf = !!userId && userId === session.userId

  const roleDisabled = useCallback(
    (role: Role) => {
      switch (role) {
        case Role.Admin:
          return isSelf
        case Role.TeamAdmin:
          return isSelf && !isAdmin
        default:
          return false
      }
    },
    [isAdmin, isSelf]
  )

  const filterRoles = useCallback(
    (roles: Role[]) => {
      return roles.filter((role) => {
        switch (role) {
          case Role.Admin:
            return !teamId
          case Role.TeamAdmin:
            return hasTeamAdmin && !!teamId
          default:
            return true
        }
      })
    },
    [hasTeamAdmin, teamId]
  )

  // remove hidden roles
  const availableRoles = useMemo(() => filterRoles(ROLES), [filterRoles])

  // filter the values passed back to make sure they don't add an Admin
  // to a team, or add a Team Admin to the account
  const handleChange = useCallback((values: any) => onChange(filterRoles(values)), [filterRoles, onChange])

  // filter roles on inbound teamId change
  useEffect(() => {
    const filteredValue = filterRoles(value)
    if (!dequal(filteredValue, value)) onChange(filteredValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  return (
    <Box {...rootProps}>
      <UiMenu closeOnSelect={false}>
        <UiMenuButton
          isDisabled={isDisabled}
          bg="white"
          data-testid="MenuUserRole_button"
          {...rest}
        >
          {rolesTitle}
        </UiMenuButton>
        <UiMenuList data-testid="MenuUserRole_menu">
          <UiMenuOptionGroup
            type="checkbox"
            onChange={handleChange}
            value={value}
          >
            {availableRoles.map((role) => (
              <UiMenuItemOption
                key={role}
                value={role}
                isDisabled={roleDisabled(role)}
              >
                {humanize(role)}
              </UiMenuItemOption>
            ))}
          </UiMenuOptionGroup>
        </UiMenuList>
      </UiMenu>
    </Box>
  )
}
