import { Box, Flex } from '@chakra-ui/react'
import { UiFooter, UiFooterLink } from '@postal-io/postal-ui'
import { BackgroundProvider } from 'hooks'
import type { ReactNode } from 'react'
import { createContext, useMemo, useState } from 'react'
import { matchPath, Outlet, useLocation } from 'react-router-dom'
import { BackgroundTasks } from './BackgroundTasks'
import { IntegrationTopbar } from './IntegrationTopbar'
import { Navbar } from './Navbar'
import { Timeout } from './Timeout'

const OBJECT_ID = ':objectId'

export const Main = () => {
  const [navbarOverride, setNavbarOverride] = useState<ReactNode>()
  const location = useLocation()

  // keeping this logging for now to test all those routes
  const hideNavbar = useMemo(() => {
    if (!!navbarOverride) return true
    const res = !!NO_NAVBAR_PATHS.some((path) => {
      const match = matchPath(path, location.pathname)
      let result = !!match
      if (match && match.pattern.path.includes(OBJECT_ID) && !isObjectId(match.params.objectId)) {
        result = false
      }
      return result
    })
    return res
  }, [location.pathname, navbarOverride])

  return (
    <>
      <BackgroundProvider>
        <NavbarContext.Provider value={{ navbarOverride, setNavbarOverride }}>
          <Flex
            direction="column"
            minH="calc(100vh + 50px)"
            alignItems="stretch"
            backgroundColor="white"
            pb={8}
          >
            <IntegrationTopbar />
            {!hideNavbar && <Navbar />}
            {!!navbarOverride && navbarOverride}
            <Box
              width="full"
              flexGrow={1}
              minW={0}
            >
              <Outlet />
            </Box>
          </Flex>
        </NavbarContext.Provider>
        <Footer />
        <Timeout />
        <BackgroundTasks />
      </BackgroundProvider>
    </>
  )
}

function Footer() {
  return (
    <UiFooter
      version={window.postalVersion}
      bg="atomicGray.900"
    >
      <UiFooterLink
        isExternal
        title="privacy policy"
        path="https://postal.com/privacy-policy/"
      />
      <UiFooterLink
        isExternal
        title="terms of service"
        path="https://postal.com/terms-of-service/"
      />
      <UiFooterLink
        isExternal
        title="acceptable use policy"
        path="https://postal.com/acceptable-use-policy/"
      />
      <UiFooterLink
        isExternal
        title="platform terms of service"
        path="https://postal.com/platform-usage-terms/"
      />
    </UiFooter>
  )
}

interface NavbarContextProps {
  navbarOverride?: any | ReactNode | null
  setNavbarOverride: any | ((navbar: ReactNode) => void) | null
}
export const NavbarContext = createContext<NavbarContextProps>({
  navbarOverride: null,
  setNavbarOverride: null,
})

function isObjectId(value: string | undefined) {
  if (!value) return false
  const re = /^[0-9a-fA-F]{24}$/
  return re.test(value)
}

const NO_NAVBAR_PATHS = [
  '/billing/accounts/:objectId',
  '/collections/:objectId/*',
  '/collections/create/*',
  '/contacts/:objectId/*',
  '/contacts/send/:objectId/*',
  '/events/marketplace/:objectId/*',
  '/events/postals/:objectId/*',
  '/integrations/:system',
  '/items/marketplace/:objectId/*',
  '/items/postals/:objectId/*',
  '/links/:objectId/*',
  '/links/approval/*',
  '/orders/bulk/:objectId',
  '/orders/direct/:objectId',
  '/orders/drafts/:objectId/*',
  '/orders/emails/:objectId',
  '/orders/group/:objectId',
  '/orders/links/:objectId',
  '/orders/links/approvals/:objectId',
  '/orders/retry-group/*',
  '/orders/retry/*',
  '/orders/subscriptions/:objectId',
  '/orders/triggers/:objectId',
  '/subscriptions/create',
  '/subscriptions/:objectId/*',
  '/subscriptions/create/step/:stepIdx/send/:objectId/*',
  '/teams/:objectId/*',
  '/teams/default/*',
  '/triggers/:objectId/*',
  '/triggers/edit',
  '/users/:objectId/*',
]
