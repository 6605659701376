import { ItemType } from 'api'

// Items shown in marketplace
export const MARKETPLACE_ITEM_TYPES = [ItemType.MarketplaceProduct, ItemType.MarketplaceProductPrivate] as any
export const MARKETPLACE_APPROVED_ITEM_TYPES = [ItemType.AccountProduct] as any

// All approved postal item types
export const APPROVED_ITEM_TYPES = [
  ItemType.AccountProduct,
  ItemType.AccountCollection,
  ItemType.AccountEvent,
  ItemType.UserCollection,
] as any

export const COLLECTION_ITEM_TYPES = [ItemType.AccountCollection, ItemType.UserCollection] as any

export const EVENT_ITEM_TYPES = [
  ItemType.MarketplaceEvent,
  ItemType.MarketplaceEventPrivate,
  ItemType.AccountEvent,
] as any
