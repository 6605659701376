import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlertError } from '@postal-io/postal-ui'
import { GetApprovedPostalDocument } from 'api'
import { PostalSend } from 'components/PostalSend'
import { isPostalDisabled, PostalSendMethod, PostalSendType } from 'components/PostalSend/postalSendHelpers'
import { PostalSendLoading } from 'components/PostalSend/PostalSendLoading'
import { PostalUnavailable } from 'components/PostalSend/PostalUnavailable'
import { AnalyticsEventV2, useAnalyticsSend } from 'hooks'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const ExtLinkPostalSendV2: React.FC = () => {
  const navigate = useNavigate()
  const { approvedPostalId } = useParams() as any

  const sendAnalytics = useAnalyticsSend()

  const backLabel = 'Back to MagicLinks'

  function goBack() {
    navigate('/extension/magiclinks')
  }

  const onNavigateBack = () => {
    sendAnalytics({ event: AnalyticsEventV2.ExtensionSendFlowBackClicked })
    goBack()
  }

  const { data, error, isLoading } = useGraphqlQuery(
    GetApprovedPostalDocument,
    { id: approvedPostalId },
    { enabled: !!approvedPostalId }
  )
  const postal = useMemo(() => data?.getApprovedPostal, [data?.getApprovedPostal])

  useAlertError(error)

  if (isLoading)
    return (
      <PostalSendLoading
        onNavigateBack={goBack}
        backLabel={backLabel}
      />
    )

  return isPostalDisabled(postal) ? (
    <PostalUnavailable
      isOpen
      onClose={goBack}
    />
  ) : (
    <PostalSend
      method={PostalSendMethod.Link}
      restrictedMethods={[PostalSendMethod.Link]}
      type={PostalSendType.Link}
      postal={postal!}
      onNavigateBack={onNavigateBack}
      navigateBackLabel={backLabel}
      onComplete={goBack}
    />
  )
}
