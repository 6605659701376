import { Flex } from '@chakra-ui/react'
import type { UiSubNavbarProps } from '@postal-io/postal-ui'
import { ZHeading, ZSubNavbar } from '@postal-io/postal-ui'
import React from 'react'
import type { StringDecoder } from 'string_decoder'

const NAVBAR_COLOR = '#FFFFFF'

interface ContactsHeaderProps extends UiSubNavbarProps {
  header?: string
  onExitLabel?: StringDecoder
}
export const ContactsHeader: React.FC<ContactsHeaderProps> = ({ header, left, right, ...rest }) => (
  <ZSubNavbar
    position="sticky"
    top={0}
    bg={NAVBAR_COLOR}
    zIndex={3}
    borderBottom="1px"
    borderColor="atomicGray.200"
    {...rest}
    left={
      <Flex
        alignItems="center"
        h="52px"
      >
        {left}
        <ZHeading
          size="h1"
          as="h1"
          fontSize="lg"
          color="inherit"
        >
          {header}
        </ZHeading>
      </Flex>
    }
    right={right}
  />
)
