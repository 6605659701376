import { Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react'
import { ZFormLabel } from '@postal-io/postal-ui'
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { ColorSelector } from './ColorSelector'
import { HorizontalList } from './HorizontalList'

const MIN_THICKNESS = 1
const MAX_THICKNESS = 30

const DEBOUNCE = 400

interface BorderEditProps {
  settings: any
  onChange: (e: any) => void
}

export const BorderEdit: React.FC<BorderEditProps> = ({ settings = {}, onChange }) => {
  const [cache, setCache] = useState(settings)

  const debouncedCallback = useDebouncedCallback(
    (name: string, value: number) => onChange({ ...settings, [name]: value }),
    DEBOUNCE
  )

  useEffect(() => {
    setCache(settings)
  }, [settings])

  const handleDebounce = (name: string, value: number) => {
    setCache({ ...cache, [name]: value })
    debouncedCallback(name, value)
  }

  const handleColor = (color: string) => {
    onChange({ ...settings, color })
  }

  return (
    <HorizontalList>
      <ZFormLabel htmlFor="color">Color</ZFormLabel>
      <ColorSelector
        value={settings.color}
        onChange={handleColor}
      />
      <ZFormLabel htmlFor="thicknessPixels">Thickness</ZFormLabel>
      <Slider
        defaultValue={settings.thicknessPixels}
        value={cache.thicknessPixels}
        onChange={(value) => handleDebounce('thicknessPixels', value)}
        min={MIN_THICKNESS}
        max={MAX_THICKNESS}
      >
        <SliderTrack />
        <SliderFilledTrack />
        <SliderThumb
          fontSize="sm"
          width="32px"
          height="20px"
        >
          {cache.thicknessPixels}
        </SliderThumb>
      </Slider>
    </HorizontalList>
  )
}
