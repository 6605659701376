import { endOfDay, startOfDay, startOfMonth, startOfQuarter, startOfWeek, startOfYear, sub } from 'date-fns'

export enum DateRangeOption {
  AllTime = 'All Time',
  Today = 'Today',
  ThisWeek = 'This Week',
  ThisMonth = 'This Month',
  Last4Weeks = 'Last 4 Weeks',
  ThisQuarter = 'This Quarter',
  ThisYear = 'This Year',
  Custom = 'Custom',
}

export enum QuarterCalculationMethod {
  Block_90Days = 'BLOCK_90_DAYS',
  Calendar = 'CALENDAR',
  OffsetMonths = 'OFFSET_MONTHS',
}

type ParseDataRangeOption = {
  dateRangeOption: DateRangeOption
  customStartDate?: Date
  customEndDate?: Date
  fiscalMethod?: QuarterCalculationMethod | null
  fiscalConfiguration?: string | null
}
export function parseDateRangeOption(props: ParseDataRangeOption) {
  const { dateRangeOption, customStartDate, customEndDate, fiscalMethod, fiscalConfiguration } = props
  let startDate = new Date('2020-01-01')
  let endDate = endOfDay(new Date())

  switch (dateRangeOption) {
    case DateRangeOption.AllTime:
      break
    case DateRangeOption.Today:
      startDate = startOfDay(new Date())
      break
    case DateRangeOption.ThisWeek:
      startDate = startOfWeek(new Date())
      break
    case DateRangeOption.ThisMonth:
      startDate = startOfMonth(new Date())
      break
    case DateRangeOption.Last4Weeks:
      startDate = startOfDay(sub(new Date(), { weeks: 4 }))
      break
    case DateRangeOption.ThisQuarter:
      startDate = startOfQuarter(new Date())
      // bump month or apply custom date if fiscal method requires
      startDate = getFiscalStartDate({
        startDate,
        fiscalMethod,
        fiscalConfiguration,
        dateRangeOption,
      })
      break
    case DateRangeOption.ThisYear:
      startDate = startOfYear(new Date())
      // bump month or apply custom date if fiscal method requires
      startDate = getFiscalStartDate({
        startDate,
        fiscalMethod,
        fiscalConfiguration,
        dateRangeOption,
      })
      break
    case DateRangeOption.Custom:
      if (customStartDate) startDate = new Date(customStartDate)
      if (customEndDate) endDate = new Date(endDate)
  }
  return { startDate: startDate.toISOString(), endDate: endDate.toISOString() }
}

export const getFiscalStartDate = (
  props: { startDate: Date } & Pick<ParseDataRangeOption, 'fiscalMethod' | 'fiscalConfiguration' | 'dateRangeOption'>
): Date => {
  const { fiscalMethod, fiscalConfiguration, dateRangeOption } = props
  let { startDate } = props

  switch (fiscalMethod) {
    case QuarterCalculationMethod.OffsetMonths:
      const offset = typeof fiscalConfiguration === 'string' ? Number(fiscalConfiguration) : 0
      const offsetMonth =
        dateRangeOption === DateRangeOption.ThisQuarter
          ? getOffsetStartOfQuarter(offset)
          : dateRangeOption === DateRangeOption.ThisYear
          ? offset
          : 0
      const offsetYear =
        dateRangeOption === DateRangeOption.ThisQuarter
          ? startDate.getFullYear()
          : dateRangeOption === DateRangeOption.ThisYear
          ? getOffsetYear(offset)
          : 0
      startDate.setMonth(offsetMonth)
      startDate.setFullYear(offsetYear)
      break
    case QuarterCalculationMethod.Block_90Days:
      startDate = new Date(fiscalConfiguration as string)
      break
  }

  return startDate
}

const getOffsetStartOfQuarter = (offsetMonth: number) => {
  const today = new Date()
  const currentMonth = today.getMonth()
  const fiscalYearStartMonth = offsetMonth % 11

  // Determine which quarter we're currently in
  let currentQuarter
  if (currentMonth >= fiscalYearStartMonth && currentMonth <= fiscalYearStartMonth + 2) {
    currentQuarter = 0
  } else if (currentMonth >= fiscalYearStartMonth + 3 && currentMonth <= fiscalYearStartMonth + 5) {
    currentQuarter = 3
  } else if (currentMonth >= fiscalYearStartMonth + 6 && currentMonth <= fiscalYearStartMonth + 8) {
    currentQuarter = 6
  } else {
    currentQuarter = 9
  }
  // Calculate the first month of the current quarter
  const firstMonthOfQuarter = (fiscalYearStartMonth + currentQuarter) % 12

  return firstMonthOfQuarter
}

const getOffsetYear = (offsetMonth: number) => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()
  return offsetMonth > currentMonth ? currentYear - 1 : currentYear
}
