import { Flex } from '@chakra-ui/react'
import { LexendFontWeight, ZButton, ZText } from '@postal-io/postal-ui'
import type { MagicLink } from 'api'
import { AnalyticsEventV2, useAnalyticsSend, useCopyMagicLink } from 'hooks'
import React from 'react'
import { MdClose, MdContentCopy } from 'react-icons/md'
import { ModalDrawer } from '../Common'
import { ExtIconButton } from '../Common/ExtIconButton'
import { ExtLinkPostal } from './ExtLinkPostal'
import { ExtLinkInfo } from './index'

interface ExtLinkDetailsProps {
  link?: MagicLink
  isOpen: boolean
  onClose: () => void
}
export const ExtLinkDetails: React.FC<ExtLinkDetailsProps> = ({ link, isOpen, onClose }) => {
  const copyLink = useCopyMagicLink()

  const sendAnalytics = useAnalyticsSend()

  const onCopyLink = () => {
    sendAnalytics({ event: AnalyticsEventV2.ExtensionLinkCopyButton })
    copyLink(link?.linkUrl)
  }

  // const onSend = () => {}

  return (
    <ModalDrawer
      header={
        <Flex
          justify="space-between"
          align="baseline"
        >
          <Flex>
            <ZText size="md">MagicLink</ZText>
            <ZText
              ml={4}
              size="md"
              color="vendorGreen.900"
              fontWeight={LexendFontWeight.Bold}
            >
              • Active
            </ZText>
          </Flex>
          <ExtIconButton
            icon={<MdClose />}
            onClick={onClose}
          />
        </Flex>
      }
      isOpen={isOpen}
      onClose={onClose}
      buttons={
        <>
          <ZButton
            leftIcon={
              <MdContentCopy
                fontSize="lg"
                color="white"
              />
            }
            colorScheme="atomicBlue"
            onClick={onCopyLink}
            sx={{
              boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '66px',
            }}
          >
            <ZText
              color="white"
              size="md"
              fontWeight={LexendFontWeight.Bold}
            >
              Share URL
            </ZText>
          </ZButton>
        </>
      }
    >
      <ExtLinkPostal link={link} />
      <ExtLinkInfo
        link={link}
        mt={4}
      />
    </ModalDrawer>
  )
}
