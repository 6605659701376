import type { FlexProps } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Icon,
  Image,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  useDisclosure,
  Wrap,
} from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type {} from '@postal-io/postal-ui'
import {
  humanize,
  UiCard,
  UiDropzone,
  UiTruncate,
  useAlerts,
  useAlertWarning,
  ZButton,
  ZConfirm,
  ZHeading,
  ZText,
} from '@postal-io/postal-ui'
import { AssetType, SaveAssetDocument, SearchAssetsDocument, Status, UpdateAssetDocument } from 'api'
import { assetUrl, uploadAsset } from 'api/rest'
import { reloadSession, useAssets } from 'hooks'
import React, { useCallback, useEffect, useState } from 'react'
import {
  MdBorderStyle,
  MdDeleteOutline,
  MdDragIndicator,
  MdHelpOutline,
  MdLooks,
  MdMessage,
  MdOutlineUpload,
  MdQrCodeScanner,
  MdTextFields,
} from 'react-icons/md'

const ICON_MAP: { [key: string]: any } = {
  Border: MdBorderStyle,
  Text: MdTextFields,
  UserMessage: MdMessage,
  QrCode: MdQrCodeScanner,
  Logo: MdLooks,
}

const ASSETS_FILTER = {
  filter: {
    type: { eq: 'IMAGE' },
    status: { eq: 'ACTIVE' },
  },
}

export const getElementDisplayName = (name: string) => (name === 'QrCode' ? 'QR Code' : humanize(name))

const AssetElement = (props: any) => {
  const { name } = props
  return (
    <Asset {...props}>
      <Icon
        as={MdDragIndicator}
        transform="rotate(90deg)"
        w="22px"
        h="22px"
        color="atomicGray.400"
      />
      <Icon
        as={ICON_MAP[name]}
        w="22px"
        h="22px"
        color="atomicGray.600"
      />
      <ZText
        fontSize="xs"
        whiteSpace="nowrap"
      >
        {getElementDisplayName(name)}
      </ZText>
    </Asset>
  )
}

// this will attempt to refresh the session
// on a loading error
const MAX_RETRIES = 2
const AssetImage = ({ id }: { id: string }) => {
  const [src, setState] = useState<string | undefined>()
  const [retries, setRetries] = useState(0)

  const { assetSrc } = useAssets()
  const setSrc = useCallback(() => setState(assetSrc(id, { w: 200 }).src), [assetSrc, id])

  useEffect(() => {
    setSrc()
  }, [setSrc])

  const handleError = async () => {
    if (retries > MAX_RETRIES - 1) return
    try {
      await reloadSession()
      setSrc()
    } catch (err) {
      // nothing
    } finally {
      setRetries(retries + 1)
    }
  }
  return (
    <Image
      id={`asset-${id}`}
      w="full"
      h="full"
      objectFit="cover"
      objectPosition="center"
      borderRadius="md"
      key={id}
      src={src}
      ignoreFallback
      onError={handleError}
    />
  )
}

interface AssetProps extends FlexProps {
  onDrag: (e: any) => any
  name: 'Text' | 'UserMessage' | 'Border' | 'Image' | 'QrCode' | 'Logo'
  settings: any
  type: string
}

const Asset: React.FC<AssetProps> = ({ onDrag, name, settings, type, children, ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isDragging, setIsDragging] = useState<boolean>(false)
  // "delete" asset by setting status to DISABLED
  const updateAsset = useGraphqlMutation(UpdateAssetDocument)
  const Alert = useAlerts()

  const handleDragStart = () => {
    const ref = document.getElementById(`asset-${settings?.id}`) as any
    const location = { width: ref?.width || 200, height: ref?.height || 200 }
    setIsDragging(true)
    onDrag({ name, settings, location })
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }

  const handleMouseEnter = () => (document.body.style.cursor = 'grab')
  const handleMouseLeave = () => (document.body.style.cursor = '')

  const handleDeleteAsset = async () => {
    try {
      await updateAsset.mutateAsync({
        id: settings?.id,
        data: { status: Status.Disabled },
      })
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <>
      <Flex
        p={4}
        position="relative"
        flexDir="column"
        justifyContent="space-between"
        alignItems="center"
        border={isDragging ? '2px' : '1px'}
        borderColor={isDragging ? 'atomicBlue.400' : 'atomicGray.400'}
        borderRadius="md"
        draggable
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        w="calc(33.3333% - var(--chakra-space-2-5, 1rem))"
        sx={{ aspectRatio: '1 / 1' }}
        {...rest}
      >
        {type === 'IMAGE' && (
          <Icon
            as={MdDeleteOutline}
            data-testid="Assets_Uploaded_Trash"
            name="delete"
            cursor="pointer"
            position="absolute"
            top="5px"
            right="5px"
            fontSize="14px"
            color="atomicRed.400"
            onClick={onOpen}
          />
        )}
        {children}
      </Flex>
      <ZConfirm
        title="Remove Image"
        isOpen={isOpen}
        onConfirm={handleDeleteAsset}
        onClose={onClose}
        buttonColor="atomicRed"
        buttonText="Delete"
      >
        <ZText>Do you want to delete this image?</ZText>
      </ZConfirm>
    </>
  )
}

interface AssetsProps {
  onDrag: (e: any) => Promise<void> | void
}

export const Assets: React.FC<AssetsProps> = ({ onDrag }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { data, isLoading: assetLoading, error: assetError } = useGraphqlQuery(SearchAssetsDocument, ASSETS_FILTER)

  const saveAsset = useGraphqlMutation(SaveAssetDocument)

  const assets = data?.searchAssets || []

  const Alert = useAlerts()

  useAlertWarning(assetError)

  const onDrop = async (files: any) => {
    if (files.length < 1) return Alert.warning('Please drop only supported file types')
    const [{ name }] = files
    setIsLoading(true)
    try {
      const [{ requestId }]: any = await uploadAsset(files)
      await saveAsset.mutateAsync({ assetType: AssetType.Image, name, requestId })
      setIsLoading(false)
    } catch (err) {
      Alert.warning(err)
      setIsLoading(false)
    }
  }

  if (assetLoading) return <UiCard isLoading={true} />

  return (
    <Box p={6}>
      <ZHeading
        fontSize="md"
        mb={1}
      >
        Elements
      </ZHeading>
      <ZText
        as="blockquote"
        color="atomicGray.500"
        mb={6}
      >
        Drag and drop any asset below to the template design to add it to your postal.
      </ZText>
      <Wrap
        justify="center"
        spacing={2.5}
        mb={6}
      >
        <AssetElement
          name="Text"
          onDrag={onDrag}
        />
        <AssetElement
          name="UserMessage"
          onDrag={onDrag}
        />
        <AssetElement
          name="Border"
          onDrag={onDrag}
        />
        <AssetElement
          name="QrCode"
          onDrag={onDrag}
        />
        <AssetElement
          name="Logo"
          onDrag={onDrag}
        />
      </Wrap>

      <ZHeading
        fontSize="md"
        mb={1}
        display="flex"
        alignItems="center"
        gap={1}
      >
        Images
        <Popover
          trigger="hover"
          placement="bottom"
        >
          <PopoverTrigger>
            {/* Wrapped in box for ref reasons */}
            <Box color="atomicGray.400">
              <MdHelpOutline size="20px" />
            </Box>
          </PopoverTrigger>
          <PopoverContent
            zIndex={6}
            color="atomicGray.600"
          >
            <PopoverHeader
              fontSize="md"
              fontWeight="normal"
              p={2}
            >
              Minimum Sizes for a Full Background:
            </PopoverHeader>
            <PopoverArrow />
            <PopoverBody
              fontSize="sm"
              fontWeight="normal"
              p={2}
            >
              <List
                styleType="disc"
                listStylePosition="outside"
                pl="15px"
                spacing={2}
              >
                <ListItem>Postcard 4x6 - 1700px by 2500px</ListItem>
                <ListItem>Notecard 4x6 folded - 2900px by 6050px</ListItem>
                <ListItem>Brochure 5x7 folded - 2500px by 3300px</ListItem>
              </List>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </ZHeading>
      <ZText
        as="blockquote"
        color="atomicGray.500"
        mb={6}
      >
        Upload files or choose from the image library below.
      </ZText>
      <UiDropzone
        onDrop={onDrop}
        isLoading={isLoading}
        accept={{
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'image/gif': ['.gif'],
          'image/svg+xml': ['.svg'],
        }}
        multiple={false}
        data-testid="Assets_Dropzone"
        background="transparent"
        borderColor="atomicGray.300"
        borderRadius="10px"
        borderWidth="1px"
        borderStyle="dashed"
      >
        <ZText color="atomicGray.500">
          Drag an image from your desktop here to store it in your assets library
          <small> (or click to select)</small>
        </ZText>
        <Box
          as="em"
          mt={2}
          color="atomicGray.400"
          fontSize="xs"
        >
          Print output requires high resolution images. Only .jpg, .png, .svg, or .gif files are accepted.
        </Box>
        <ZButton
          mt={4}
          variant="outline"
          colorScheme="atomicGray"
          leftIcon={<MdOutlineUpload size="20px" />}
        >
          Upload File
        </ZButton>
      </UiDropzone>
      <SimpleGrid
        columns={3}
        spacing={2.5}
        mt={4}
      >
        {assets.map((asset, idx) => {
          const { id, type, name } = asset
          return (
            <Asset
              data-testid={`Assets_Uploaded${idx}`}
              key={id}
              name="Image"
              onDrag={onDrag}
              settings={{ id, assetUrl: assetUrl(id) }}
              type={type}
              w="100%"
              p={0}
              border="none"
              textAlign="left"
            >
              <AssetImage id={id} />
              <UiTruncate
                text={name}
                fontSize="xs"
                alignSelf="flex-start"
                mt={1}
                mb={2}
                length={14}
              />
            </Asset>
          )
        })}
      </SimpleGrid>
    </Box>
  )
}
