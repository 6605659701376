import events from '../events/events.png'
import airBuds from './airBuds.jpg'
import alcohol from './alcohol.png'
import amazonCard from './amazonCard.png'
import fit from './fit.png'
import meats from './meats.jpg'
import spoonful from './spoonful.png'
import starbucks from './starbucks.png'
import swell from './swell.jpg'

const eventImages = [events]

//Just an array to map over easily.
const postalImages = [alcohol, amazonCard, fit, airBuds, meats, spoonful, starbucks, swell]

export { postalImages, eventImages }
