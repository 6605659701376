import { CheckIcon, ChevronDownIcon, ChevronUpIcon, WarningIcon } from '@chakra-ui/icons'
import type { BoxProps } from '@chakra-ui/react'
import {
  Box,
  ButtonGroup,
  Collapse,
  Divider,
  Flex,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import {
  UiFormControl,
  UiIconPostalEdit2,
  UiInputNumber,
  UiSortableDragHandle,
  UiSortableDragHandleIcon,
  UiTruncate,
  ZCard,
  ZCardButton,
  ZConfirm,
  ZText,
} from '@postal-io/postal-ui'
import type { ApprovedPostal } from 'api'
import { Status } from 'api'
import { CATEGORY } from 'components/Postals'
import { SendAsLink, useAcl, useSendAs } from 'hooks'
import React, { useRef, useState } from 'react'
import { MdDeleteOutline } from 'react-icons/md'
import { PostalDirectMailCarousel, PostalImageCarousel } from '../Postal'

interface RowProps extends BoxProps {
  label?: string
}
const Row: React.FC<RowProps> = ({ label, children, ...rest }) => {
  return (
    <Box {...rest}>
      <ZText fontWeight="semibold">{label}</ZText>
      <Box>{children}</Box>
    </Box>
  )
}

interface PlaybookDefinitionStepV2Props extends BoxProps {
  key?: string | number
  step: any
  stepNumber: number
  day?: number
  index: number
  showDragger?: boolean
  isDelayTrouble: boolean
  isDelayMoved?: boolean
  deleteStep?: (e: number) => void
  handleEditStep?: (s: any, i: number) => void
  setStep?: (stepIdx: number, newStep: any) => void
}
export const PlaybookDefinitionStep: React.FC<PlaybookDefinitionStepV2Props> = ({
  index,
  day,
  step,
  stepNumber,
  showDragger,
  isDelayTrouble,
  isDelayMoved,
  deleteStep,
  handleEditStep,
  setStep,
  ...rest
}: PlaybookDefinitionStepV2Props) => {
  const { hasPermission } = useAcl()
  const canEdit = hasPermission('playbooks.create')
  const deleteDisclosure = useDisclosure()
  const [delay, setDelay] = useState(step?.delay ?? 0)
  const [toggle, setToggle] = useState(false)

  const delayTic = useRef(delay)

  let postalInfo: ApprovedPostal | undefined

  if (step?.approvedPostalId) {
    postalInfo = step?.approvedPostal
  } else if (step?.hasOwnProperty('postal')) {
    postalInfo = step?.postal
  }

  const variant = postalInfo?.variants?.find((v) => v.id === step.variantId)
  const availableVariants = postalInfo?.variants?.filter((v) => v.status === Status.Active)

  const isDirectMail = postalInfo?.category === CATEGORY.DirectMail

  const handleToggle = () => {
    if (delay !== step?.delay) handleChangeDelay('Toggle')
    setToggle(!toggle)
  }

  const onConfirm = () => {
    deleteDisclosure.onClose()
    return deleteStep && deleteStep(index)
  }

  const handleChangeDelay = (e: any) => {
    if (!setStep) return
    if (e.type === 'blur' || (e.type === 'keydown' && e?.key === 'Enter') || e === 'Submit' || e === 'Toggle') {
      setStep(stepNumber - 1, { ...step, delay })
    }
  }

  const postalName = postalInfo?.name

  const { isExternal } = useSendAs({
    sendAsContactOwner: step.sendAsContactOwner,
    sendAsUser: step.sendAsUser,
  })

  return (
    <>
      <ZCard
        data-testid={`PlaybookDefinitionStep_step${stepNumber}`}
        isLoading={!postalInfo}
        borderColor="atomicGray.200"
        borderWidth="1px"
        borderRadius="4px"
        w="100%"
        minH="40px"
        p={2}
        gap={0}
        {...rest}
      >
        <Flex
          w="100%"
          h="100%"
          alignItems="center"
          justifyContent="space-between"
          pl={4}
        >
          <Flex
            cursor="pointer"
            alignItems="center"
            fontSize="lg"
            color="atomicGray.600"
            onClick={handleToggle}
          >
            {showDragger && (
              <UiSortableDragHandle
                id={step.id}
                onMouseDown={() => setToggle(false)}
              >
                <UiSortableDragHandleIcon
                  cursor="grab"
                  boxSize="20px"
                  aria-label="Drag step"
                  color="atomicBlue.500"
                  mr={4}
                />
              </UiSortableDragHandle>
            )}

            <ZText
              size="lg"
              variant="bold"
              color="atomicGray.500"
              whiteSpace="nowrap"
            >
              Day {day}
            </ZText>
            <ZText
              ml={2}
              size="lg"
              whiteSpace="nowrap"
            >
              <UiTruncate
                text={postalName}
                length={45}
                showTooltip
              />
            </ZText>

            {isDelayTrouble && showDragger && !toggle && (
              <Tooltip
                data-testid="PlaybookDefinitionStep_tooltip_delay"
                px={2}
                bg="red.400"
                placement="top"
                hasArrow
                label="Warning: Delay may be too short"
                aria-label="Delay"
              >
                <WarningIcon
                  boxSize="15px"
                  color="red.400"
                  name="warning"
                  m={2}
                />
              </Tooltip>
            )}

            {isDelayMoved && showDragger && !toggle && (
              <Tooltip
                data-testid="PlaybookDefinitionStep_tooltip_check"
                px={2}
                bg="atomicBlue.500"
                placement="top"
                hasArrow
                label="Check delay for accuracy"
                aria-label="Delay Check"
              >
                <WarningIcon
                  boxSize="15px"
                  color="atomicBlue.500"
                  name="warning"
                  my={2}
                  ml={2}
                />
              </Tooltip>
            )}
          </Flex>
          <ButtonGroup spacing={1}>
            <ZCardButton
              aria-label="Toggle Step"
              icon={toggle ? <ChevronUpIcon /> : <ChevronDownIcon />}
              onClick={handleToggle}
            />
            {canEdit && deleteStep && (
              <ZCardButton
                aria-label="Delete step"
                icon={<MdDeleteOutline />}
                onClick={deleteDisclosure.onOpen}
              />
            )}
            {canEdit && showDragger && (
              <ZCardButton
                aria-label="Edit step"
                icon={<UiIconPostalEdit2 />}
                onClick={() => handleEditStep?.(step, stepNumber - 1)}
              />
            )}
          </ButtonGroup>
        </Flex>
        <Collapse in={toggle}>
          <Divider mt={1} />
          <Grid
            templateColumns={{ base: '100%', xl: 'calc(40% - 1rem) calc(60% - 1rem)' }}
            gridGap="2rem"
            p={2}
          >
            <Box
              mt={4}
              maxW="400px"
            >
              {isDirectMail ? (
                <PostalDirectMailCarousel
                  userMessage={step?.physicalMessage || step?.giftMessage}
                  designTemplate={postalInfo?.designTemplate}
                />
              ) : (
                <PostalImageCarousel
                  postal={postalInfo}
                  containerProps={{ maxH: '400px' }}
                />
              )}
            </Box>
            <Stack spacing={4}>
              <Row label="Item">
                <ZText>
                  <UiTruncate
                    text={postalInfo?.name}
                    showTooltip
                  />
                </ZText>
              </Row>
              {isExternal && (
                <Row label="Send As">
                  <ZText>
                    <SendAsLink
                      sendAsUser={step.sendAsUser}
                      sendAsContactOwner={step.sendAsContactOwner}
                    />
                  </ZText>
                </Row>
              )}
              <Row label="Option">
                <ZText>
                  <UiTruncate
                    text={
                      postalInfo?.variantOrderFlexibility
                        ? availableVariants?.map((v) => v.variantName).join(', ')
                        : variant?.variantName
                    }
                    showTooltip
                  />
                </ZText>
              </Row>
              <Row label="Day Sent">
                <ZText>Day {day !== undefined ? day - Number(step?.delay) + Number(delay) : 0} from the start</ZText>
              </Row>
              <Row label="Delay (days)">
                <Flex w="100%">
                  <UiFormControl id={`stepDelay-${stepNumber}`}>
                    {setStep && showDragger ? (
                      <Grid
                        borderWidth="2px"
                        borderColor="atomicBlue.500"
                        templateColumns="1fr 1fr"
                        gridGap={1}
                      >
                        <UiInputNumber
                          defaultValue={delay}
                          onKeyDown={handleChangeDelay}
                          onChange={(value: string | number) => setDelay(value || 0)}
                          onBlur={handleChangeDelay}
                          inputFieldProps={{
                            _focus: { borderWidth: 'none' },
                            border: 'none',
                            borderRadius: '0px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                          }}
                        />
                        <IconButton
                          color="white"
                          border="none"
                          backgroundColor="atomicBlue.500"
                          isDisabled={delayTic.current === delay}
                          _hover={{
                            backgroundColor: delayTic.current === delay ? 'atomicBlue.600' : 'atomicBlue.500',
                          }}
                          aria-label="submit"
                          icon={<CheckIcon />}
                          borderRadius={0}
                          onClick={() => handleChangeDelay('Submit')}
                        />
                      </Grid>
                    ) : (
                      <Box>
                        <ZText>
                          {delay === 0
                            ? 'No delay set'
                            : delay === 1
                            ? `${delay} day after previous send`
                            : ` ${delay} days after previous send`}
                        </ZText>
                      </Box>
                    )}
                  </UiFormControl>
                </Flex>
              </Row>
            </Stack>
          </Grid>
          {isDelayTrouble && showDragger && (
            <Box
              borderWidth="3px"
              p={4}
              borderColor="red.100"
              backgroundColor="atomicGray.50"
              mt={2}
              mx="auto"
            >
              <ZText>
                <strong>Warning:</strong> The previous step contains a Direct Mail item and may arrive after this
                current postal. Please set the Delay of this step to at least 7 days.
              </ZText>
            </Box>
          )}
        </Collapse>
      </ZCard>
      <ZConfirm
        title={`Delete Subscription Step`}
        isOpen={deleteDisclosure.isOpen}
        onConfirm={onConfirm}
        onClose={deleteDisclosure.onClose}
      >
        <ZText textAlign="center">
          This action will remove this step from the subscription. This change isn&apos;t permanent until the
          subscription is updated.
        </ZText>
      </ZConfirm>
    </>
  )
}
