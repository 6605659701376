import { Divider, SimpleGrid } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiGenericChangeEvent } from '@postal-io/postal-ui'
import {
  UiFormControl,
  UiRadioButtonGroup,
  useAlertError,
  useAlerts,
  ZCard,
  ZCardHeader,
  ZFormLabel,
  ZRadioButton,
  ZSelect,
  ZSidebar,
  ZSidebarBanner,
} from '@postal-io/postal-ui'
import type { Account, PostalDeliveryEmailSetting, PostalDeliveryExpireAction } from 'api'
import { GetAccountDocument, UpdateAccountDocument } from 'api'
import { CenteredBox } from 'components/Common'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { PageTitle } from 'hooks'
import React, { useMemo } from 'react'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

const DEFAULT_DAYS = 7
const MAX_DAYS = 14
const DAY_OPTIONS = [...Array(MAX_DAYS).keys()].map((i) => `${i + 1}`)

export const GiftEmails: React.FC = () => {
  const Alert = useAlerts()
  const getAccount = useGraphqlQuery(GetAccountDocument)
  const { id, postalDeliveryEmailSetting, postalDeliveryExpirationDays, postalDeliveryExpireAction } = useMemo(() => {
    return getAccount.data?.getAccount || ({} as Account)
  }, [getAccount.data?.getAccount])

  const updateAccount = useGraphqlMutation(UpdateAccountDocument)

  interface DataProps {
    [index: string]: PostalDeliveryEmailSetting | PostalDeliveryExpireAction | number
  }

  const handleChange = async ({ target: { name, value } }: UiGenericChangeEvent) => {
    const data: DataProps = {}
    if (name === 'postalDeliveryExpirationDays') {
      data[name] = Number(value)
    } else {
      data[name] = value as PostalDeliveryEmailSetting | PostalDeliveryExpireAction
    }
    try {
      await updateAccount.mutateAsync({ id, data })
      Alert.success('Settings updated')
    } catch (err) {
      Alert.error(err)
    }
  }

  useAlertError(getAccount.error)
  useAlertError(updateAccount.error)

  const isDisabled = postalDeliveryEmailSetting === 'NEVER'
  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner title="Personalized Emails" />
        <PageTitle title="Personalized Emails" />
        <SimpleGrid
          columns={1}
          spacing={8}
        >
          <ZCard
            isLoading={getAccount.isLoading}
            variant="form"
          >
            <ZCardHeader
              w="100%"
              p={8}
              pb={0}
              justifyContent="flex-start"
              alignItems="center"
              gap={2}
            >
              Personalized Email Settings
              <ZInfoTooltip label="These emails are sent before an item is shipped. They give the recipient the ability to accept or reject the item and also update their contact information." />
            </ZCardHeader>
            <UiFormControl
              id="postalDeliveryEmailSetting"
              p={8}
            >
              <ZFormLabel
                fontSize="lg"
                mb={4}
              >
                Do you want gift recipients to confirm by email before accepting gifts?
              </ZFormLabel>
              <UiRadioButtonGroup
                name="postalDeliveryEmailSetting"
                value={postalDeliveryEmailSetting as string}
                defaultValue={postalDeliveryEmailSetting as string}
                colorScheme="atomicBlue"
                onChange={handleChange}
                gap={1}
              >
                <ZRadioButton
                  minW={{ base: '100%', lg: '200px' }}
                  value="ALWAYS"
                >
                  Always
                </ZRadioButton>
                <ZRadioButton
                  minW={{ base: '100%', lg: '200px' }}
                  value="DEFAULT_ON"
                >
                  Default On
                </ZRadioButton>
                <ZRadioButton
                  minW={{ base: '100%', lg: '200px' }}
                  value="DEFAULT_OFF"
                >
                  Default Off
                </ZRadioButton>
                <ZRadioButton
                  minW={{ base: '100%', lg: '200px' }}
                  value="NEVER"
                >
                  Never
                </ZRadioButton>
              </UiRadioButtonGroup>
            </UiFormControl>

            <Divider />

            <UiFormControl
              id="postalDeliveryExpireAction"
              p={8}
              isDisabled={isDisabled}
            >
              <ZFormLabel
                fontSize="lg"
                mb={4}
              >
                If the recipient does not respond to the personalized email, what should happen?
              </ZFormLabel>
              <UiRadioButtonGroup
                name="postalDeliveryExpireAction"
                value={postalDeliveryExpireAction as string}
                defaultValue={postalDeliveryExpireAction as string}
                colorScheme="atomicBlue"
                onChange={handleChange}
                gap={1}
              >
                <ZRadioButton
                  minW={{ base: '100%', lg: '200px' }}
                  value="CANCEL_ORDER"
                >
                  Cancel Order
                </ZRadioButton>
                <ZRadioButton
                  minW={{ base: '100%', lg: '200px' }}
                  value="SEND_ORDER"
                >
                  Send Anyways!
                </ZRadioButton>
              </UiRadioButtonGroup>
            </UiFormControl>

            <Divider />

            <UiFormControl
              id="postalDeliveryExpirationDays"
              isDisabled={isDisabled}
              p={8}
            >
              <ZFormLabel
                fontSize="lg"
                mb={4}
              >
                How much time does the recipient have to respond to personalized emails? Does not include weekends.
              </ZFormLabel>
              <ZSelect
                name="postalDeliveryExpirationDays"
                value={postalDeliveryExpirationDays || DEFAULT_DAYS}
                onChange={handleChange}
                maxW="300px"
              >
                {DAY_OPTIONS.map((days) => {
                  return (
                    <option
                      value={days}
                      key={days}
                    >
                      {days} Business Days
                    </option>
                  )
                })}
              </ZSelect>
            </UiFormControl>
          </ZCard>
        </SimpleGrid>
      </ZSidebar>
    </CenteredBox>
  )
}
