import { Stack, StatNumber } from '@chakra-ui/react'
import {
  UiCard,
  UiCardHeader,
  UiDate,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerHeader,
  UiDrawerOverlay,
  UiMoney,
  UiStat,
  UiStatGroup,
  UiStatLabel,
  UiStatText,
  UiStatusTag,
  UiSubNavbar,
} from '@postal-io/postal-ui'
import type { PlaybookInstance as PlaybookInstanceType } from 'api'
import { capitalize } from 'lodash'
import React from 'react'
import { PostalFulfillmentsTable } from '../PostalFulfillments'
import { STATUS_COLOR_MAP } from './data'

export interface PlaybookInstanceProps {
  isOpen: boolean
  onClose: () => void
  instance: PlaybookInstanceType
}
export const PlaybookInstance: React.FC<PlaybookInstanceProps> = ({ instance, isOpen, onClose }) => {
  return (
    <UiDrawer
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton />
        <UiDrawerHeader>{instance.contactName || `Subscription Instance`}</UiDrawerHeader>
        <UiSubNavbar />
        <UiDrawerBody>
          <Stack spacing={8}>
            <UiCard>
              <UiCardHeader>Details</UiCardHeader>
              <UiStatGroup textAlign="center">
                <UiStat>
                  <UiStatLabel>Completed</UiStatLabel>
                  <StatNumber>
                    {instance.nextStepToExecute || 0} / {instance.steps.length}
                  </StatNumber>
                </UiStat>
                <UiStat>
                  <UiStatLabel>Successful</UiStatLabel>
                  <StatNumber>{instance.metrics?.successful}</StatNumber>
                </UiStat>
                <UiStat>
                  <UiStatLabel>Failed</UiStatLabel>
                  <StatNumber>{instance.metrics?.failed}</StatNumber>
                </UiStat>
                <UiStat>
                  <UiStatLabel whiteSpace="nowrap">Cost</UiStatLabel>
                  <UiMoney
                    cents={instance.metrics?.costInCents}
                    fontSize="2xl"
                  />
                </UiStat>
              </UiStatGroup>
              <UiStatGroup mt={8}>
                <UiStat>
                  <UiStatLabel>Next Send</UiStatLabel>
                  <UiDate
                    date={instance.nextExecutionDate}
                    fontSize="2xl"
                  />
                </UiStat>
                <UiStat>
                  <UiStatLabel>Status</UiStatLabel>
                  <UiStatusTag
                    mt={2}
                    minW="100px"
                    px={4}
                    colorScheme={STATUS_COLOR_MAP[instance.playbookStatus || '']}
                    label={capitalize(instance.playbookStatus || '')}
                  />
                </UiStat>
                <UiStat>
                  <UiStatLabel>Error</UiStatLabel>
                  <UiStatText fontSize={instance.errMessage ? 'lg' : '2xl'}>{instance.errMessage || '-'}</UiStatText>
                </UiStat>
              </UiStatGroup>
            </UiCard>
            <PostalFulfillmentsTable playbookId={instance.id} />
          </Stack>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  )
}
