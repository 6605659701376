import { Button, Flex, HStack, Skeleton, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import type { Column, GraphqlFilterState } from '@postal-io/postal-ui'
import {
  GraphqlFilterTransform,
  internalProgressBarProps,
  internalTableProps,
  UiDate,
  UiSSDataTable,
  UiTooltip,
  UiTruncate,
  useAlertError,
  useColor,
  useGraphqlFilter,
  ZButton,
  ZCard,
  ZCardBody,
  ZHeading,
  ZLink,
  ZSubNavbar,
  ZText,
} from '@postal-io/postal-ui'

import type { ApprovedPostal, MagicLink, SearchMagicLinksQueryVariables } from 'api'
import { MagicLinkStatus, Role, SearchMagicLinksDocument } from 'api'
import { PostalSelectItem } from 'components/PostalSend/PostalSelectItem'
import { PostalSendMethod } from 'components/PostalSend/postalSendHelpers'
import {
  AnalyticsEvent,
  PageTitle,
  useAcl,
  useAnalyticsEvent,
  useCopyMagicLink,
  useNavigateLinkEdit,
  useNavigateSendFlow,
  useSession,
} from 'hooks'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MdAddCircleOutline, MdCheck, MdLink, MdNotInterested, MdOutlineEdit } from 'react-icons/md'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useKey } from 'react-use'
import { CenteredBox } from '../Common/CenteredBox'
import { ZStatusTag } from '../Common/ZComponents'
import { SpentFromColumn } from './LinksBulkApproveOrders'
import { LinksFilter } from './LinksFilter'
import { LinkTeaser } from './LinkTeaser'
import { NeedsApprovalBanner } from './NeedsApprovalBanner'

const LIMIT = 100

const transforms = {
  name: GraphqlFilterTransform.Contains,
  status: GraphqlFilterTransform.Equal,
  userId: GraphqlFilterTransform.Equal,
  approvedPostalId: GraphqlFilterTransform.Equal,
}

const INITIAL_STATE = { orderBy: { key: 'created', direction: 'desc' } } as GraphqlFilterState

export const Links: React.FC = () => {
  const { hasPermission, hasRole, hasFeature } = useAcl()
  const canCreate = hasPermission('links.create')
  const canApproveLinks = hasFeature('manuallyApproveLinks') && hasRole(Role.User)
  const createLink = useDisclosure()
  const { ownerId } = useParams() as any
  const copyLink = useCopyMagicLink()
  const { session } = useSession()
  const { state } = useLocation() as any
  const { colorCode } = useColor()
  const actionBarDisclosure = useDisclosure()
  const [selectedMagicLinks, setSelectedMagicLinks] = useState<MagicLink[]>([])
  const editLink = useNavigateLinkEdit()

  useAnalyticsEvent({ event: AnalyticsEvent.MagicLinkPageViewed })

  const handleCancelBulkSelect = () => setSelectedMagicLinks([])

  // deselect all products
  useKey('Escape', handleCancelBulkSelect)

  const isManager = hasRole(Role.Manager)

  const columns = useMemo(() => {
    const cols: Column[] = [
      {
        key: 'created',
        label: 'Created Date',
        orderBy: 'created',
        headerProps: {
          minW: '160px',
        },
        render: ({ created }: MagicLink) =>
          created ? (
            <UiDate
              fontFamily="Lexend"
              fontSize="sm"
              fontWeight="normal"
              color={colorCode('atomicGray.900')}
              date={created.dateTime}
            />
          ) : (
            <ZText size="sm">N/A</ZText>
          ),
      },
      {
        key: 'name',
        label: 'Name',
        orderBy: 'name',
        headerProps: {
          w: '180px',
        },
        render: ({ id, name }: MagicLink) => {
          return (
            <ZLink
              fontSize="sm"
              as={Link}
              to={`/links/${id}`}
              state={{ returnTo: 'MagicLinks' }}
            >
              <UiTruncate
                text={name}
                length={35}
              />
            </ZLink>
          )
        },
      },
      {
        key: 'status',
        label: 'Status',
        orderBy: 'status',
        render: ({ status }: MagicLink) => {
          return (
            <HStack spacing={2}>
              {status === MagicLinkStatus.Active ? (
                <MdCheck
                  size="16px"
                  color={colorCode('vendorGreen.500')}
                />
              ) : (
                <MdNotInterested
                  size="16px"
                  color={colorCode('atomicGray.900')}
                />
              )}
              <ZText size="md">{status === MagicLinkStatus.Active ? 'Enabled' : 'Disabled'}</ZText>
            </HStack>
          )
        },
      },

      {
        label: 'Redemptions',
        orderBy: 'metrics_linkExecutions',
        render: ({ maxExecutions, metrics }: MagicLink) => {
          return (
            <Flex gap={1}>
              <ZText size="md">{maxExecutions ? `${metrics.linkExecutions}/${maxExecutions}` : metrics.sent}</ZText>
            </Flex>
          )
        },
      },
      {
        key: 'spentFrom',
        label: 'Spent From',
        headerProps: {
          minW: '160px',
        },
        render: ({ spendAs }: MagicLink) => <SpentFromColumn teamId={spendAs?.teamId!} />,
      },
      {
        key: 'expirationDate',
        label: 'Expires On',
        orderBy: 'expirationDate',
        headerProps: {
          minW: '160px',
        },
        render: ({ expirationDate }: MagicLink) =>
          expirationDate ? (
            <UiDate
              fontSize="sm"
              fontWeight="normal"
              color="atomicGray.900"
              date={expirationDate}
            />
          ) : (
            <ZText size="md">Never</ZText>
          ),
      },

      {
        key: 'linkUrl',
        label: 'Link',
        render: ({ linkUrl }: MagicLink) => {
          return (
            <UiTooltip label="Share URL">
              <Button
                variant="link"
                data-testid="copy-link"
                colorScheme="atomicBlue"
                _hover={{ color: 'atomicBlue.600' }}
                title={linkUrl}
                onClick={() => copyLink(linkUrl)}
              >
                <MdLink size="20px" />
              </Button>
            </UiTooltip>
          )
        },
      },
      {
        key: 'linkEdit',
        label: 'Edit',
        render: ({ id }: MagicLink) => {
          return (
            <UiTooltip label="Edit MagicLink">
              <ZLink
                color="atomicBlue.400"
                as={Link}
                to={editLink({ linkId: id, returnTo: 'Links' })}
              >
                <MdOutlineEdit size="20px" />
              </ZLink>
            </UiTooltip>
          )
        },
      },
    ] as any[]
    if (isManager && ownerId !== 'me') {
      cols.splice(3, 0, {
        key: 'userLink.fullName',
        orderBy: 'userLink_fullName',
        label: 'Owner',
        render: ({ userLink }) => <ZText size="md">{userLink.fullName}</ZText>,
      })
    }
    if (canApproveLinks) {
      cols.splice(7, 0, {
        key: 'userLink.requiresApproval',
        label: 'Requires Approval',
        orderBy: 'requiresApproval',
        headerProps: {
          minW: '190px',
        },
        render: ({ requiresApproval }: MagicLink) => {
          const label = requiresApproval ? 'On' : requiresApproval ? 'Approval Needed' : 'Off'
          return (
            label && (
              <ZStatusTag
                minW={12}
                w={12}
                color={requiresApproval ? 'atomicBlue.800' : 'atomicGray.800'}
                bg={requiresApproval ? 'atomicBlue.10' : 'none'}
                label={label}
              />
            )
          )
        },
      })
    }
    return cols
  }, [canApproveLinks, colorCode, copyLink, editLink, isManager, ownerId])

  // force ownerId depending on path
  const staticVariables = useMemo(() => {
    const filter = {} as any
    if (ownerId) filter.userId = { eq: ownerId === 'me' ? session.userId : ownerId }
    return { filter, limit: LIMIT }
  }, [ownerId, session.userId])

  const initialState = useMemo(
    () => ({ ...INITIAL_STATE, filter: { approvedPostalId: state?.approvedPostalId } }),
    [state?.approvedPostalId]
  )

  const { variables, filter, setFilter, orderBy, setOrderBy } = useGraphqlFilter<SearchMagicLinksQueryVariables>({
    transforms,
    initialState,
    staticVariables,
    debounce: 400,
  })

  const { isLoading, isFetching, error, mergedData, hasNextPage, fetchNextPage } = useGraphqlInfiniteQuery(
    SearchMagicLinksDocument,
    variables,
    { keepPreviousData: true }
  )

  useAlertError(error)

  const magicLinks = useMemo(() => mergedData?.searchMagicLinks || [], [mergedData?.searchMagicLinks])

  // const { data: ordersData } = useGraphqlQuery(
  //   SearchPostalFulfillmentsDocument,
  //   { filter: { magicLinkId: { in: magicLinks.map((l) => l.id) } } },
  //   { enabled: magicLinks.length > 0 }
  // )

  // const linksWithApprovalMetrics = useMemo(() => {
  //   const metricsGrouped =
  //     ordersData?.searchPostalFulfillments?.reduce((prev, cur) => {
  //       const id = cur.magicLinkId as string
  //       prev[id] = prev[id] || { needsAction: 0, approved: 0, denied: 0 }
  //       if (cur.status === FulfillmentStatus.PendingUserApproval) prev[id].needsAction += 1
  //       if (
  //         cur.status === FulfillmentStatus.OrderApproved ||
  //         cur.history?.some((h) => h.status === FulfillmentStatus.OrderApproved)
  //       )
  //         prev[id].approved += 1
  //       if (
  //         cur.status === FulfillmentStatus.OrderDenied ||
  //         cur.history?.some((h) => h.status === FulfillmentStatus.OrderDenied)
  //       )
  //         prev[id].denied += 1

  //       return prev
  //     }, {} as any) || {}

  //   return magicLinks.map((l) => ({
  //     ...l,
  //     metrics: {
  //       ...l.metrics,
  //       ...(metricsGrouped[l.id] ?? { needsAction: 0, approved: 0, denied: 0 }),
  //     },
  //   }))
  // }, [ordersData?.searchPostalFulfillments, magicLinks])

  const showTeaser = useMemo(() => {
    return isEmpty(variables?.filter) && !magicLinks.length
  }, [magicLinks.length, variables?.filter])

  const title = !!ownerId ? (ownerId === 'me' ? 'My MagicLinks' : 'MagicLinks') : 'All MagicLinks'

  const sendFlowLink = useNavigateSendFlow()
  const navigate = useNavigate()

  const handleSelectItem = useCallback(
    (postal: ApprovedPostal) => {
      createLink.onClose()
      navigate(
        sendFlowLink(`/items/postals/${postal.id}/send`, {
          returnTo: 'MagicLinks',
          sendMethod: PostalSendMethod.Link,
        })
      )
    },
    [createLink, navigate, sendFlowLink]
  )

  // open bulk action bar when table items are selected
  useEffect(() => {
    if (selectedMagicLinks.length && !actionBarDisclosure.isOpen) {
      actionBarDisclosure.onOpen()
    } else if (selectedMagicLinks.length === 0) {
      actionBarDisclosure.onClose()
    }
  }, [actionBarDisclosure, selectedMagicLinks.length])

  return (
    <>
      <PageTitle title={title} />

      <CenteredBox isLoaded>
        <ZSubNavbar
          px={0}
          left={<ZHeading size="h5">MagicLinks</ZHeading>}
          right={
            <ZButton
              px={0}
              color="atomicBlue.500"
              fontWeight="normal"
              variant="link"
              leftIcon={<MdAddCircleOutline />}
              onClick={createLink.onOpen}
              hidden={!canCreate}
            >
              Create a new link
            </ZButton>
          }
          borderBottom="1px"
          borderColor="atomicGray.200"
          mb={8}
        />
        {showTeaser ? (
          <Skeleton isLoaded={!isLoading}>
            <LinkTeaser onClick={createLink.onOpen} />
          </Skeleton>
        ) : (
          <>
            {canApproveLinks && <NeedsApprovalBanner ownerId={ownerId} />}
            <ZCard variant="form">
              <ZCardBody p={8}>
                <LinksFilter
                  setFilter={setFilter}
                  filter={filter}
                  canFilterOwner={isManager && !ownerId}
                  onCreate={canCreate ? createLink.onOpen : undefined}
                />

                <UiSSDataTable
                  rowKey="id"
                  variant="list"
                  columns={columns}
                  orderBy={orderBy}
                  onOrderBy={setOrderBy}
                  fetchMore={fetchNextPage}
                  hasMore={hasNextPage}
                  filter={variables.filter}
                  rows={magicLinks}
                  isLoading={isFetching}
                  tableProps={internalTableProps}
                  progressBarProps={internalProgressBarProps}
                  HeaderButton={ZButton}
                  mt={5}
                />
              </ZCardBody>
            </ZCard>
          </>
        )}
      </CenteredBox>
      {createLink.isOpen && (
        <PostalSelectItem
          {...createLink}
          onSelect={handleSelectItem}
        />
      )}
    </>
  )
}
