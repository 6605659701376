import { Box, chakra, Grid, Icon, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiText, useAlerts, ZButton } from '@postal-io/postal-ui'
import type { ApprovedPostalInputItem, ApprovedProductVariant } from 'api'
import { CreateApprovedPostalDocument, GetApprovedPostalDocument, Status, UpdateApprovedPostalDocument } from 'api'
import { CollectionItemEdit } from 'components/Collections/CollectionItemEdit'
import { CenteredBox } from 'components/Common'
import { ZBasicDialogButtons, ZDialog } from 'components/Common/ZComponents'
import { PostalSendMethod } from 'components/PostalSend/postalSendHelpers'
import {
  PageTitle,
  POSTAL_INVALIDATIONS,
  useAcl,
  useApprovedPostalVersion,
  useBackgroundQueue,
  useCollectionPermissions,
  useNavigateSendFlow,
} from 'hooks'
import React, { useCallback, useMemo, useState } from 'react'
import { MdOutlineEdit, MdSend } from 'react-icons/md'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { CATEGORY, MARKETPLACE_CARD_MIN_WIDTH } from '../Postals'
import { NavbarActionMenu, NavbarBackButton, SecondaryNavbar } from '../PostalSend/SecondaryNavbar'
import { CollectionCardCreate } from './CollectionCard'
import { CollectionItemCard } from './CollectionItemCard'
import { CollectionSettingsEdit } from './CollectionSettingsEdit'
import { CollectionStats } from './CollectionStats'
import { CARD_HEIGHT } from './data'
import type { CollectionItem, EitherVariant } from './utils'
import { getApprovedInputItemFromVariant, getVariantsWithoutItem, groupCollectionItems, sortVariants } from './utils'

const DEFAULT_MAX_COLLECTION_ITEMS = 12
// make a hook for this since it's used in many places
export function useMaxCollectionItems() {
  const { getMeta } = useAcl()
  return getMeta('collections')?.maximumItems || DEFAULT_MAX_COLLECTION_ITEMS
}

export const Collection: React.FC = () => {
  const transform = useApprovedPostalVersion()
  const { approvedPostalId } = useParams() as any
  const Alert = useAlerts()
  const navigate = useNavigate()
  const settingsDislosure = useDisclosure()
  const statusUpdateDislosure = useDisclosure()
  const removeCollectionDisclosure = useDisclosure()
  const editItemDisclosure = useDisclosure()
  const cloneCollectionDisclosure = useDisclosure()
  const [selectedItem, setSelectedItem] = useState<CollectionItem>()

  const getApprovedPostal = useGraphqlQuery(GetApprovedPostalDocument, { id: approvedPostalId })
  const collection = useMemo(
    () => getApprovedPostal.data?.getApprovedPostal,
    [getApprovedPostal.data?.getApprovedPostal]
  )

  const { canSend, canUpdate, canDelete, canLink } = useCollectionPermissions(collection)

  const { queue, invalidate } = useBackgroundQueue()
  const updateCollection = useGraphqlMutation(UpdateApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.updateApprovedPostal.previewGenerationTask)
      invalidate(POSTAL_INVALIDATIONS)
    },
  })
  const createCollection = useGraphqlMutation(CreateApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.createApprovedPostal.previewGenerationTask)
      invalidate(POSTAL_INVALIDATIONS)
    },
  })

  const MAX_ITEMS = useMaxCollectionItems()
  const MAX_ITEMS_MESSAGE = `This collection has reached its limit of items (${MAX_ITEMS})`

  const items = collection ? groupCollectionItems(collection) : []
  const canAddItems = items.length < MAX_ITEMS

  const handleAddItem = () => {
    canAddItems
      ? navigate(`/collections/${approvedPostalId}/add`, { state: { returnTo: 'Collection' } })
      : Alert.warning(MAX_ITEMS_MESSAGE)
  }

  const handleEditItem = (item: CollectionItem) => {
    setSelectedItem(item)
    editItemDisclosure.onOpen()
  }

  const updateVariants = async (newItems: ApprovedPostalInputItem[]) =>
    updateCollection.mutateAsync({
      id: approvedPostalId,
      data: {
        status: newItems.length === 0 ? Status.Disabled : collection?.status,
        ...transform(collection?.category ?? CATEGORY.Collection, { items: newItems }),
      },
    })

  const updateItemVariants = async (item: CollectionItem, updatedVariants: EitherVariant[]) => {
    if (!collection) return
    // start fresh with all the other variants excluding the current item
    const newItems = getVariantsWithoutItem(collection, item)

    // then add the new variants in
    updatedVariants.forEach((v) => newItems.push(getApprovedInputItemFromVariant(v as ApprovedProductVariant, item)))

    try {
      await updateVariants(newItems)
      Alert.success(`Item Options Updated`)
    } catch (err) {
      Alert.error(err)
    }
  }

  const removeItem = async (item?: CollectionItem) => {
    if (!collection || !(selectedItem || item)) return
    const newItems = getVariantsWithoutItem(collection, item ?? selectedItem!)

    try {
      await updateVariants(newItems)
      Alert.success(`Item Removed`)
    } catch (err) {
      Alert.error(err)
    }
  }

  const updateCollectionStatus = useCallback(
    async (status: Status) => {
      if (!collection) return
      try {
        await updateCollection.mutateAsync({
          id: approvedPostalId,
          data: { status, ...transform(collection.category) },
        })
        Alert.success(`Collection ${status === Status.Active ? 'Enabled' : 'Disabled'}`)
      } catch (err) {
        Alert.error(err)
      }
    },
    [Alert, approvedPostalId, collection, transform, updateCollection]
  )

  const handleDelete = async () => {
    if (!collection) return
    try {
      await updateCollection.mutateAsync({
        id: collection.id,
        data: { status: Status.Delete, ...transform(collection.category) },
      })
      Alert.warning('Collection Deleted')
      navigate(`/collections`)
    } catch (e) {
      Alert.error(e)
    }
  }

  const handleCloneCollection = async () => {
    if (!collection) return
    const items = sortVariants(collection.variants).map((v) => getApprovedInputItemFromVariant(v))

    try {
      if (!collection) throw new Error('Error loading collection.')
      if (!items?.length) throw new Error('At least one item is required.')

      const res = await createCollection.mutateAsync({
        marketplaceProductId: collection?.marketplaceProductId,
        data: {
          name: `Copy - ${collection.name}`,
          displayName: collection.displayName || collection.name,
          ownerId: collection.ownerId,
          teamIds: collection.teamIds,
          description: collection.description,
          status: Status.Disabled,
          attribution: collection.attribution,
          collection: true,
          ...transform(CATEGORY.Collection, { items }),
        },
      })
      Alert.success('Collection Cloned')
      cloneCollectionDisclosure.onClose()
      navigate(`/collections/${res?.createApprovedPostal?.postal?.id}`)
    } catch (err) {
      Alert.error(err)
    }
  }

  const sendFlowLink = useNavigateSendFlow()

  const handleAction = useCallback(
    (type: string) => {
      if (!collection) return
      switch (type) {
        case 'SEND': {
          canSend && navigate(sendFlowLink(`/collections/${collection.id}/send`, { returnTo: 'Collection' }))
          break
        }
        case 'EDIT':
          canUpdate && settingsDislosure.onOpen()
          break
        case 'DELETE':
          canDelete && removeCollectionDisclosure.onOpen()
          break
        case 'CREATE_LINK': {
          canLink &&
            navigate(
              sendFlowLink(`/collections/${collection.id}/send`, {
                returnTo: 'Collection',
                sendMethod: PostalSendMethod.Link,
              })
            )
          break
        }
        default:
      }
    },
    [
      collection,
      canUpdate,
      settingsDislosure,
      canDelete,
      removeCollectionDisclosure,
      canSend,
      navigate,
      sendFlowLink,
      canLink,
    ]
  )

  const actionItems = useMemo(() => {
    if (!collection) return []
    return [
      {
        title: `${collection.status === Status.Active ? 'Disable' : 'Enable'} Collection`,
        onClick: statusUpdateDislosure.onOpen,
        isHidden: !canUpdate,
      },
      {
        title: 'Send Collection',
        onClick: () => handleAction('SEND'),
        isHidden: !canSend,
      },
      {
        title: 'Create MagicLink',
        onClick: () => handleAction('CREATE_LINK'),
        isHidden: !canLink,
      },
      {
        title: 'Edit Settings',
        onClick: settingsDislosure.onOpen,
        isHidden: !canUpdate,
      },
      {
        title: `Clone Collection`,
        onClick: cloneCollectionDisclosure.onOpen,
        isHidden: !canUpdate,
      },
      {
        title: 'Delete Collection',
        onClick: () => handleAction('DELETE'),
        isHidden: !canDelete,
      },
    ]
  }, [
    canDelete,
    canLink,
    canSend,
    canUpdate,
    cloneCollectionDisclosure.onOpen,
    collection,
    settingsDislosure.onOpen,
    handleAction,
    statusUpdateDislosure.onOpen,
  ])

  return (
    <>
      <>
        <PageTitle
          title={collection?.name}
          section="Collections"
        />
        <SecondaryNavbar
          farRight={
            <>
              {canSend && (
                <ZButton
                  variant="solid"
                  colorScheme="atomicBlue"
                  leftIcon={<MdSend fontSize="lg" />}
                  onClick={() => handleAction('SEND')}
                >
                  Send this Collection
                </ZButton>
              )}
              {canUpdate && (
                <ZButton
                  variant="ghost"
                  colorScheme="white"
                  _hover={{ opacity: 0.8 }}
                  leftIcon={<Icon as={MdOutlineEdit} />}
                  onClick={() => handleAction('EDIT')}
                >
                  Edit Settings
                </ZButton>
              )}
              <NavbarActionMenu actionItems={actionItems} />
            </>
          }
          hideHelpCenterButton
          left={
            <NavbarBackButton
              onClick={() => navigate('/collections')}
              label="Back to Collections"
            />
          }
          header={`Collection - ${collection?.name ?? 'Loading...'}`}
        />
        <CenteredBox
          isLoaded={!getApprovedPostal.isLoading}
          pt={8}
        >
          {collection && (
            <CollectionStats
              collection={collection}
              updateStatusAction={actionItems.find((i) => i.onClick === statusUpdateDislosure.onOpen)}
            />
          )}
          <Box
            flexGrow={1}
            flexShrink={1}
          >
            <Grid
              templateColumns={`repeat(auto-fill, minmax(${MARKETPLACE_CARD_MIN_WIDTH}px, 1fr))`}
              gap={4}
            >
              {canUpdate && (
                <CollectionCardCreate
                  onClick={handleAddItem}
                  errorMessage={!canAddItems ? MAX_ITEMS_MESSAGE : undefined}
                  width="100%"
                  height={CARD_HEIGHT}
                  maxItems={MAX_ITEMS}
                />
              )}
              {items?.map((item) => (
                <CollectionItemCard
                  key={item.approvedPostalId ?? item.marketplaceProductId}
                  item={item}
                  onUpdateVariants={canUpdate ? (variants) => updateItemVariants(item, variants) : undefined}
                  onEdit={canUpdate ? () => handleEditItem(item) : undefined}
                  onRemove={canUpdate ? () => removeItem(item) : undefined}
                  truncateLength={30}
                />
              ))}
            </Grid>
          </Box>
        </CenteredBox>
        {settingsDislosure.isOpen && collection && (
          <CollectionSettingsEdit
            onEdit={settingsDislosure.onClose}
            isOpen={settingsDislosure.isOpen}
            onClose={settingsDislosure.onClose}
            postal={collection}
          />
        )}
        {editItemDisclosure.isOpen && !!selectedItem && !!collection && (
          <CollectionItemEdit
            collection={collection}
            item={selectedItem}
            onEdit={editItemDisclosure.onClose}
            isOpen={editItemDisclosure.isOpen}
            onClose={editItemDisclosure.onClose}
          />
        )}
        {canDelete && removeCollectionDisclosure.isOpen && (
          <ZDialog
            size="lg"
            title="Delete Collection"
            onClose={removeCollectionDisclosure.onClose}
            isOpen={removeCollectionDisclosure.isOpen}
            // isLoading={updateCollection.isLoading}
          >
            <UiText>
              Are you sure you want to <chakra.strong color="atomicRed.500">Delete</chakra.strong> this Collection?
            </UiText>
            <ZBasicDialogButtons
              onClose={removeCollectionDisclosure.onClose}
              onConfirm={handleDelete}
              confirmText="Delete"
              confirmColorScheme="atomicRed"
            />
          </ZDialog>
        )}
        {canUpdate && statusUpdateDislosure.isOpen && (
          <ZDialog
            size="lg"
            title={collection?.status === Status.Active ? 'Disable Collection' : 'Enable Collection'}
            onClose={statusUpdateDislosure.onClose}
            isOpen={statusUpdateDislosure.isOpen}
          >
            <UiText>
              Are you sure you want to{' '}
              <chakra.strong>{collection?.status === Status.Active ? 'Disable' : 'Enable'}</chakra.strong> this
              Collection?{' '}
              {collection?.status === Status.Active &&
                'Nobody will be able to redeem this collection while it is in Draft status.'}
            </UiText>
            <ZBasicDialogButtons
              onClose={statusUpdateDislosure.onClose}
              onConfirm={() => {
                updateCollectionStatus(collection?.status === Status.Active ? Status.Disabled : Status.Active)
                statusUpdateDislosure.onClose()
              }}
              confirmText={collection?.status === Status.Active ? 'Disable' : 'Enable'}
              confirmColorScheme={collection?.status === Status.Active ? 'atomicRed' : 'atomicBlue'}
            />
          </ZDialog>
        )}
        {canUpdate && cloneCollectionDisclosure.isOpen && (
          <ZDialog
            size="lg"
            title="Clone Collection"
            isOpen={cloneCollectionDisclosure.isOpen}
            onClose={cloneCollectionDisclosure.onClose}
            // isLoading={createCollection.isLoading}
          >
            <Text>
              This action will make a DRAFT copy of <strong>{collection?.name}</strong> with the same items. Do you want
              to continue?
            </Text>
            <ZBasicDialogButtons
              onClose={cloneCollectionDisclosure.onClose}
              onConfirm={handleCloneCollection}
              confirmText="Clone Collection"
            />
          </ZDialog>
        )}
      </>
    </>
  )
}
