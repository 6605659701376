import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Divider, Flex, Grid, Stack, Wrap } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps, UiInputProps } from '@postal-io/postal-ui'
import {
  UiSkeleton,
  useAlerts,
  useColor,
  ZButton,
  ZCardHeader,
  ZFormLabel,
  ZInput,
  ZLink,
  ZText,
} from '@postal-io/postal-ui'
import { GetAccountDocument, UpdateAccountDocument } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { useImmer } from 'use-immer'

export const DEFAULT_POSTAL_COLORS = {
  PRIMARY: '#222222',
  SECONDARY: '#29AFFF',
  TERTIARY: '#E4F6FF',
}

interface AccountColors {
  giftEmailPrimaryColor?: string | null
  giftEmailSecondaryColor?: string | null
  giftEmailTertiaryColor?: string | null
}
type ColorAttribute = 'giftEmailPrimaryColor' | 'giftEmailSecondaryColor' | 'giftEmailTertiaryColor'

export const Colors: React.FC<UiCardProps> = (props) => {
  const Alert = useAlerts()
  const { data, isLoading } = useGraphqlQuery(GetAccountDocument)
  const account = data?.getAccount
  const updateAccount = useGraphqlMutation(UpdateAccountDocument)
  const [colors, setColors] = useImmer<AccountColors>({})
  const { Color } = useColor()

  const defaultColors = useMemo(
    () => ({
      primary: DEFAULT_POSTAL_COLORS.PRIMARY,
      secondary: DEFAULT_POSTAL_COLORS.SECONDARY,
      tertiary: DEFAULT_POSTAL_COLORS.TERTIARY,
    }),
    []
  )

  useEffect(() => {
    if (!account) return
    setColors((draft) => {
      draft.giftEmailPrimaryColor = account?.giftEmailPrimaryColor ?? defaultColors.primary
      draft.giftEmailSecondaryColor = account?.giftEmailSecondaryColor ?? defaultColors.secondary
      draft.giftEmailTertiaryColor = account?.giftEmailTertiaryColor ?? defaultColors.tertiary
    })
  }, [account, defaultColors.primary, defaultColors.secondary, defaultColors.tertiary, setColors])

  const saveColors = useDebouncedCallback(async () => {
    if (!account) return
    try {
      await updateAccount.mutateAsync({ id: account.id, data: { ...colors } })
      Alert.success('Color Saved')
    } catch (err) {
      Alert.error(err)
    }
  }, 500)

  const handleChange = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const name = target.name as ColorAttribute
    const value = target.value

    if (Color(value).contrast(Color('#ffffff')) < 1.5) {
      return Alert.warning(
        'Color cannot be saved. Please select a darker color to allow for optimal contrast on Gift Emails.'
      )
    }

    setColors((draft) => {
      draft[name] = value
    })
    await saveColors()
  }

  if (isLoading) return <UiSkeleton isLoaded={false} />

  return (
    <Grid
      templateColumns={{ base: '1fr', xl: '1fr 1fr' }}
      gap={8}
      {...props}
    >
      <Box>
        <ZCardHeader
          fontSize="lg"
          fontWeight={350}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          gap={2}
        >
          Brand Colors
          <ZInfoTooltip label="These colors will be used in your gift and event invite workflows, including emails and landing pages." />
        </ZCardHeader>
        <Flex mt={8}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="90px"
            mr={2}
          >
            <ColorPicker
              value={colors.giftEmailPrimaryColor || '#ffffff'}
              name="giftEmailPrimaryColor"
              onChange={handleChange}
              id="primaryColor"
            />
            <ZFormLabel
              data-testid="primaryColor"
              htmlFor="primaryColor"
              fontSize="xs"
              fontWeight={350}
              color="atomicGray.500"
              p={0}
              whiteSpace="nowrap"
              m={0}
              mt={4}
              gap={1}
            >
              Primary
              <ZInfoTooltip label="The main color at the top of your gift emails and landing pages. Recommended to avoid light colors." />
            </ZFormLabel>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            w="90px"
            mr={2}
          >
            <ColorPicker
              value={colors.giftEmailSecondaryColor || '#ffffff'}
              name="giftEmailSecondaryColor"
              onChange={handleChange}
              id="secondaryColor"
            />
            <ZFormLabel
              data-testid="secondaryColor"
              htmlFor="secondaryColor"
              fontSize="xs"
              fontWeight={350}
              color="atomicGray.500"
              p={0}
              whiteSpace="nowrap"
              m={0}
              mt={4}
              gap={1}
            >
              Secondary
              <ZInfoTooltip label="The color of buttons on gift emails and landing pages. Recommended to avoid lighter colors that may conflict with the white text on a button." />
            </ZFormLabel>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            w="90px"
          >
            <ColorPicker
              value={colors.giftEmailTertiaryColor || '#ffffff'}
              name="giftEmailTertiaryColor"
              onChange={handleChange}
              id="tertiaryColor"
            />
            <ZFormLabel
              data-testid="tertiaryColor"
              htmlFor="tertiaryColor"
              fontSize="xs"
              fontWeight={350}
              color="atomicGray.500"
              p={0}
              whiteSpace="nowrap"
              m={0}
              mt={4}
              gap={1}
            >
              Tertiary
              <ZInfoTooltip label="The background of your gift emails. Lighter colors are recommended." />
            </ZFormLabel>
          </Box>
        </Flex>
        <Box mt={8}>
          <ZLink
            to={'/account/email-templates'}
            as={Link}
            color="atomicBlue.400"
            fontWeight={350}
          >
            Preview and update your email templates{' '}
            <ChevronRightIcon
              h="20px"
              w="20px"
            />
          </ZLink>
        </Box>
      </Box>

      <Box>
        <ZCardHeader
          justifyContent="flex-start"
          alignItems="center"
          gap={2}
        >
          Sample Elements
          <ZInfoTooltip label="These are examples of elements found inside your email templates, using the branding colors you have selected." />
        </ZCardHeader>
        <Wrap
          spacing={2}
          mt={8}
        >
          <ZButton
            minW="100px"
            size="medium"
            justifyContent="center"
            py={1}
            borderRadius="50px"
            bg={colors.giftEmailPrimaryColor as string}
          >
            Primary
          </ZButton>
          <ZButton
            minW="100px"
            variant="outline"
            size="medium"
            justifyContent="center"
            py={1}
            borderRadius="50px"
            color={colors.giftEmailPrimaryColor as string}
          >
            Primary
          </ZButton>
          <ZButton
            minW="100px"
            size="medium"
            justifyContent="center"
            py={1}
            borderRadius="50px"
            bg={colors.giftEmailSecondaryColor as string}
          >
            Secondary
          </ZButton>
          <ZButton
            minW="100px"
            variant="outline"
            size="medium"
            justifyContent="center"
            py={1}
            borderRadius="50px"
            color={colors.giftEmailSecondaryColor as string}
          >
            Secondary
          </ZButton>
          <ZButton
            minW="100px"
            size="medium"
            justifyContent="center"
            py={1}
            borderRadius="50px"
            bg={colors.giftEmailTertiaryColor as string}
          >
            Tertiary
          </ZButton>
          <ZButton
            minW="100px"
            variant="outline"
            size="medium"
            justifyContent="center"
            py={1}
            borderRadius="50px"
            color={colors.giftEmailTertiaryColor as string}
          >
            Tertiary
          </ZButton>
        </Wrap>
        <Divider
          my={4}
          mt={6}
        />
        <Stack spacing={2}>
          <ZText
            color={colors.giftEmailPrimaryColor as string}
            fontSize="md"
            fontWeight={500}
          >
            This is sample text using primary color.
          </ZText>
          <ZText
            fontSize="md"
            color={colors.giftEmailSecondaryColor as string}
            fontWeight={500}
          >
            This is sample text using secondary color.
          </ZText>
          <ZText
            fontSize="md"
            color={colors.giftEmailTertiaryColor as string}
            fontWeight={500}
          >
            This is sample text using tertiary color.
          </ZText>
        </Stack>
      </Box>
    </Grid>
  )
}

interface ColorPickerProps extends UiInputProps {
  id?: string
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ value, onChange, name, id, w = 71, h = 71, ...rest }) => {
  return (
    <Box
      position="relative"
      p={0}
      m={0}
      w={w}
      h={h}
      {...rest}
    >
      <ZFormLabel
        htmlFor={id}
        position="absolute"
        p={0}
        top={0}
        right={0}
        bottom={0}
        left={0}
        width="100%"
        height="100%"
        cursor="pointer"
        display="flex"
        borderRadius={100}
        bgColor={value as any}
        zIndex={9999}
      />
      <ZInput
        type="color"
        id={id}
        value={value}
        name={name}
        onChange={onChange}
        visibility="hidden"
        width={0}
        height={0}
      />
    </Box>
  )
}
