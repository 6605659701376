import type { BoxProps } from '@chakra-ui/react'
import { FormControl, Stack } from '@chakra-ui/react'
import { ZFormLabel, ZInputPassword, ZPasswordChecklist } from '@postal-io/postal-ui'
import { ZAlert } from 'components/Common/ZComponents'
import React from 'react'
import type { Updater } from 'use-immer'

export interface PasswordState {
  currentPassword: string
  newPassword1: string
  newPassword2: string
}
interface UserProfileEditPasswordProps extends BoxProps {
  form: PasswordState
  setForm: Updater<PasswordState>
  emailAddress: string
  requireCurrentPassword?: boolean
}

export const UserProfileEditPassword: React.FC<UserProfileEditPasswordProps> = ({
  form,
  setForm,
  emailAddress,
  requireCurrentPassword,
  ...rest
}) => {
  return (
    <>
      {!requireCurrentPassword && (
        <ZAlert
          status="info"
          mb={6}
        >
          You are currently logging in with SSO. You may set a password below to login with that password instead of
          SSO. If you wish to change your SSO password, please login to your provider.
        </ZAlert>
      )}
      <Stack
        spacing={6}
        {...rest}
      >
        {requireCurrentPassword && (
          <FormControl
            id="currentPassword"
            isRequired
          >
            <ZFormLabel>Current Password</ZFormLabel>
            <ZInputPassword
              name="currentPassword"
              placeholder="Current Password"
              onChange={(e) => setForm((draft) => void (draft.currentPassword = e.target.value))}
              value={form.currentPassword}
              autoComplete="current-password"
            />
          </FormControl>
        )}
        <FormControl
          id="newPassword1"
          isRequired
        >
          <ZFormLabel>New Password</ZFormLabel>
          <ZInputPassword
            id="newPassword1"
            name="newPassword1"
            placeholder="New Password"
            value={form.newPassword1}
            autoComplete="new-password"
            onChange={(e) => setForm((draft) => void (draft.newPassword1 = e.target.value))}
          />
        </FormControl>
        <FormControl
          id="newPassword2"
          isRequired
        >
          <ZFormLabel>Re-enter Password</ZFormLabel>
          <ZInputPassword
            name="newPassword2"
            placeholder="Re-enter Password"
            value={form.newPassword2}
            autoComplete="new-password"
            onChange={(e) => setForm((draft) => void (draft.newPassword2 = e.target.value))}
          />
        </FormControl>
        <ZPasswordChecklist
          password={form.newPassword1}
          password2={form.newPassword2}
          emailAddress={emailAddress}
          my={16}
        />
      </Stack>
    </>
  )
}
