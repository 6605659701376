import React, { forwardRef } from 'react'
import { Rect } from 'react-konva'

const selectedProps = {
  shadowBlur: 10,
  opacity: 0.75,
}

export interface BorderProps {
  element: any
  isEditable?: boolean
  isSelected?: boolean
}

export const Border: React.FC<BorderProps> = forwardRef(
  ({ element, isEditable = false, isSelected = false, ...rest }, ref: any) => {
    const { location, settings, hidden } = element
    const { x, y, width, height } = location
    const { color = '#125D8C', thicknessPixels = 3 } = settings

    const handleMouseEnter = (e: any) => {
      if (!isEditable) return
      e.target.shadowBlur(10)
      e.target.opacity(0.75)
      e.target.parent.draw()
      document.body.style.cursor = 'pointer'
    }

    const handleMouseLeave = (e: any) => {
      if (!isEditable) return
      if (!isSelected) {
        e.target.shadowBlur(0)
        e.target.opacity(1)
        e.target.parent.draw()
      }
      document.body.style.cursor = 'default'
    }

    return (
      <Rect
        ref={ref}
        x={x}
        y={y}
        stroke={color}
        strokeWidth={thicknessPixels}
        width={width}
        height={height}
        {...(isSelected && selectedProps)}
        fillEnabled={false}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        visible={!hidden}
        {...rest}
      />
    )
  }
)
