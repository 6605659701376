import { Flex, Stack } from '@chakra-ui/react'
import { UiToggle, ZCardBody, ZHeading } from '@postal-io/postal-ui'
import { ZFormLabel, ZInfoTooltip, ZInlineFormControl } from 'components/Common/ZComponents'
import React from 'react'
import { getSendMethodColor } from './usePostalSend'
import type { PostalCustomizeV2Props } from './PostalCustomize'

export const PostalCustomizeRecipientNotifications: React.FC<PostalCustomizeV2Props> = ({ context, send }) => {
  const handleToggleShippedEmail = () => {
    send({ type: 'SET_SHIPPED_EMAIL', data: !context?.shippedEmailsOn })
  }

  const handleToggleDeliveredEmail = () => {
    send({ type: 'SET_DELIVERED_EMAIL', data: !context?.deliveredEmailsOn })
  }

  return (
    <ZCardBody>
      <ZHeading
        as="h3"
        size="h6"
      >
        Recipient Notifications
      </ZHeading>
      <Stack
        spacing={5}
        mt={8}
      >
        <ZInlineFormControl>
          <ZFormLabel
            htmlFor="default"
            onClick={(e) => e.preventDefault()}
          >
            Send Shipped Email
            <ZInfoTooltip
              label="Send an email to your recipient when the item has shipped."
              ml={2}
            />
          </ZFormLabel>
          <Flex justifyContent="flex-end">
            <UiToggle
              colorScheme={getSendMethodColor(context).replace(/\.\d00/, '')}
              id="shippedEmailsOn"
              name="shippedEmailsOn"
              isChecked={context?.shippedEmailsOn}
              onChange={handleToggleShippedEmail}
              size="lg"
              mr={2}
            />
          </Flex>
        </ZInlineFormControl>
        <ZInlineFormControl>
          <ZFormLabel
            htmlFor="default"
            onClick={(e) => e.preventDefault()}
          >
            Send Delivered Email
            <ZInfoTooltip
              label="Send an email to your recipient when the item has been marked delivered."
              ml={2}
            />
          </ZFormLabel>
          <Flex justifyContent="flex-end">
            <UiToggle
              colorScheme={getSendMethodColor(context).replace(/\.\d00/, '')}
              id="deliveredEmailsOn"
              name="deliveredEmailsOn"
              isChecked={context?.deliveredEmailsOn}
              onChange={handleToggleDeliveredEmail}
              size="lg"
              mr={2}
            />
          </Flex>
        </ZInlineFormControl>
      </Stack>
    </ZCardBody>
  )
}
