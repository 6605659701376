import type { BoxProps, FlexProps } from '@chakra-ui/react'
import { Box, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react'
import { UiIconPostalEngageLogo, useColor, ZFlowButton } from '@postal-io/postal-ui'
import ExtABMPlaceholderImage from 'assets/extension/ext-abm-placeholder-image.png'
import { MdCheckCircle, MdChevronRight, MdSend } from 'react-icons/md'

const CheckboxItem: React.FC<FlexProps> = ({ children, ...rest }) => {
  const { colorCode } = useColor()
  return (
    <Flex
      width="100%"
      alignItems="center"
      gap={2}
      {...rest}
    >
      <MdCheckCircle
        color={colorCode('atomicBlue.400')}
        size="20px"
      />
      <Text fontSize="body-lg">{children}</Text>
    </Flex>
  )
}

export const ExtABMPlaceholder: React.FC<BoxProps> = (props) => {
  return (
    <Box
      p={8}
      {...props}
    >
      <Flex justifyContent="center">
        <Text
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize="body-lg"
          gap={4}
        >
          <span>ABM from</span>
          <UiIconPostalEngageLogo
            fontSize="175px"
            h="auto"
            mx="auto"
          />
        </Text>
      </Flex>
      <Image
        mt={4}
        w="full"
        src={ExtABMPlaceholderImage}
      />
      <Heading
        mt={4}
        textAlign="center"
        fontSize="heading-xs"
      >
        New! Postal ABM shows detailed engagement from contacts in a timeline view. You can see which key contacts have
        engaged (and when) across an account. Contact your Postal Admin about adding Postal ABM to get access to these
        key insights.
      </Heading>

      <VStack
        w="100%"
        mt={8}
      >
        <CheckboxItem>Enterprise analytics</CheckboxItem>
        <CheckboxItem>Account-level ROI insights</CheckboxItem>
        <CheckboxItem>ABM campaigns at scale</CheckboxItem>
        <CheckboxItem>Automated intent campaigns</CheckboxItem>
      </VStack>

      <ZFlowButton
        mt={12}
        colorScheme="atomicBlue"
        leftIcon={<MdSend />}
        rightIcon={<MdChevronRight />}
        onClick={() => window.open('https://www.postal.com/solutions/abm', '_blank')}
      >
        Learn More
      </ZFlowButton>
    </Box>
  )
}
