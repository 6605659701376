import { Flex, Grid, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  humanize,
  internalProgressBarProps,
  internalTableProps,
  joinStrings,
  UiSelectButton,
  UiSSDataTable,
  useAlertError,
  useGraphqlFilter,
  ZButton,
  ZCard,
  ZCardBody,
  ZCheckbox,
  ZLink,
} from '@postal-io/postal-ui'
import type { SearchUsersQueryVariables, User } from 'api'
import { GetAccountDocument, SearchUsersDocument, Status, TeamsDocument } from 'api'
import { useAcl, useSession } from 'hooks'
import { getUserTeamIds } from 'lib'
import { cloneDeep, set } from 'lodash'
import uniq from 'lodash/uniq'
import { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { UserAccountsEdit } from '../User/UserAccountsEdit'
import { BulkUserAccountUpdate } from './BulkUserAccountUpdate'
import type { SelectedUsers } from './UsersData'
import { buttonsV2, columnsV2, DEFAULT_ROLES, transforms } from './UsersData'
import { UsersFilter } from './UsersFilter'

const LIMIT = 100
const staticVariables = { status: { eq: Status.Active }, limit: LIMIT }

const PRODUCT_ID = process.env.REACT_APP_PRODUCT_ID

export const UsersTable = () => {
  const { hasPermission } = useAcl()
  const canUpdate = hasPermission('users.update')
  const teamUpdate = useDisclosure()
  const editRoles = useDisclosure()
  const [selectedUser, setSelectedUser] = useState<User>()

  const { variables, filter, setFilter, orderBy, setOrderBy } = useGraphqlFilter<SearchUsersQueryVariables>({
    transforms,
    staticVariables,
    debounce: 400,
  })

  const { session } = useSession()

  // If the accountId and the teamId selected match, then we are looking for someone with no
  // teamId, which is handled by searching for null
  //
  // If the roles field is empty, then we want to specifically ask for the usable roles to avoid
  // showing no access users.
  const newVariables = useMemo(() => {
    const newVars = cloneDeep(variables)
    if (variables.filter?.productAccess?.teamId?.eq === session.accountId) {
      set(newVars, 'filter.productAccess.teamId.eq', null)
    }
    if (!variables.filter?.productAccess?.roles?.in?.length) {
      set(newVars, 'filter.productAccess.roles.in', DEFAULT_ROLES)
    }
    return newVars
  }, [session.accountId, variables])

  const searchUsers = useGraphqlInfiniteQuery(SearchUsersDocument, newVariables)
  const { users, totalRecords } = useMemo(() => {
    return {
      users: (searchUsers.mergedData?.searchUsers?.users as User[]) || [],
      totalRecords: searchUsers.mergedData?.searchUsers?.resultsSummary?.totalRecords,
    }
  }, [searchUsers.mergedData?.searchUsers?.resultsSummary?.totalRecords, searchUsers.mergedData?.searchUsers?.users])

  const teamIds = useMemo(() => {
    const ids: string[] = []
    users.forEach((user) => getUserTeamIds(user).forEach((id) => ids.push(id)))
    return uniq(ids)
  }, [users])

  // get teams associated with all the found users
  const teamVariables = useMemo(() => ({ filter: { id: { in: teamIds }, status: { eq: Status.Active } } }), [teamIds])
  const teamsQuery = useGraphqlQuery(TeamsDocument, teamVariables, { enabled: !!teamIds.length })
  const teams = useMemo(() => teamsQuery.data?.teams || [], [teamsQuery.data?.teams])
  const getTeam = useCallback((teamId: string) => teams.find(({ id }: any) => id === teamId), [teams])

  const accountQuery = useGraphqlQuery(GetAccountDocument)
  const account = accountQuery.data?.getAccount

  const handleRoles = useCallback(
    (user: User) => {
      setSelectedUser(user)
      editRoles.onOpen()
    },
    [editRoles]
  )
  const normalizedUsers = useMemo(() => {
    return users.map((item) => {
      const products =
        item.productAccess?.filter((access) => {
          return access.product === PRODUCT_ID && !!access.roles?.length
        }) || []

      const teams = uniq(
        products.map(({ teamId }) => (teamId ? getTeam(teamId)?.name : account?.name)).filter(Boolean)
      ).join(' / ')

      const roles = uniq(
        products
          .map(({ roles }) => roles)
          .filter(Boolean)
          .flat()
      )
        .map((role) => humanize(role ?? ''))
        .join(' / ')

      return {
        id: item.id,
        name: (
          <ZLink
            to={`/users/${item.id}`}
            as={Link}
          >
            {joinStrings([item.firstName, item.lastName])}
          </ZLink>
        ),
        userName: item.userName,
        teams,
        roles: <ZLink onClick={() => handleRoles(item)}>{roles}</ZLink>,
        status: item.status,
      }
    })
  }, [account?.name, getTeam, handleRoles, users])

  //Selected users from the data table
  const [selectedUsers, setSelectedUsers] = useState<SelectedUsers>()

  const onSelect = useCallback(
    (selected: User[]) => {
      if (selected.length === users.length) {
        setSelectedUsers({
          users: selected,
          filter: newVariables.filter || {},
          count: totalRecords || selected.length || 0,
        })
      } else {
        setSelectedUsers({
          users: selected,
          filter: { id: { in: selected.map((c) => c.id) } },
          count: selected.length,
        })
      }
    },
    [totalRecords, users.length, newVariables.filter]
  )

  const onSelectButton = (type: string) => {
    switch (type) {
      case 'TEAM_UPDATE':
        return teamUpdate.onOpen()
      default:
    }
  }

  const handleEditRoles = () => {
    setSelectedUser(undefined)
  }

  useAlertError(searchUsers.error)
  const buttonsEnabled = !!selectedUsers?.users?.length

  return (
    <>
      <Flex
        justifyContent="flex-start"
        alignItems="flex-start"
        h="40px"
      >
        {canUpdate && (
          <Flex
            justifyContent="flex-start"
            alignItems="center"
            mr={2}
            h="40px"
          >
            {buttonsV2?.map(({ type, placement, ...button }) => (
              <UiSelectButton
                h="40px"
                w="40px"
                fontSize="26px"
                border="none"
                aria-label={`UserTable ${type} button`}
                key={type}
                placement={placement}
                isDisabled={!buttonsEnabled}
                onClick={() => onSelectButton(type)}
                colorScheme="atomicGray"
                color="atomicGray.500"
                {...button}
              />
            ))}
          </Flex>
        )}
        <Grid
          gap={4}
          templateColumns="repeat(auto-fit, minmax(200px, 1fr))"
          alignItems="end"
          w="100%"
        >
          <UsersFilter
            filter={filter}
            setFilter={setFilter}
          ></UsersFilter>
        </Grid>
      </Flex>
      <ZCard
        mt={4}
        variant="form"
      >
        <ZCardBody p={8}>
          <UiSSDataTable
            isLoading={searchUsers.isFetching}
            columns={columnsV2}
            rows={normalizedUsers as any}
            rowKey="id"
            showSelect={canUpdate}
            onSelect={onSelect}
            hasMore={searchUsers.hasNextPage}
            fetchMore={searchUsers.fetchNextPage}
            filter={variables.filter}
            orderBy={orderBy}
            onOrderBy={setOrderBy}
            totalRecords={totalRecords}
            variant="list"
            SelectCheckbox={ZCheckbox}
            HeaderButton={ZButton}
            tableProps={internalTableProps}
            progressBarProps={internalProgressBarProps}
          />
        </ZCardBody>
      </ZCard>

      {canUpdate && selectedUsers && teamUpdate.isOpen && (
        <BulkUserAccountUpdate
          isOpen={teamUpdate.isOpen}
          onClose={teamUpdate.onClose}
          users={selectedUsers}
          onComplete={() => setSelectedUsers(undefined)}
        />
      )}
      {canUpdate && editRoles.isOpen && !!selectedUser && (
        <UserAccountsEdit
          userId={selectedUser.id}
          firstName={selectedUser.firstName}
          lastName={selectedUser.lastName}
          isOpen={editRoles.isOpen}
          onClose={editRoles.onClose}
          onComplete={handleEditRoles}
        />
      )}
    </>
  )
}
