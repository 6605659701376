import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { GetAccountDocument } from 'api'
import { useQueryParams, useSession } from 'hooks'
import { useCallback } from 'react'

const SUPPORT_URL = 'https://postal.com/contact-us/'

export const useHelp = () => {
  const { path } = useQueryParams()
  const { session } = useSession()
  const getAccount = useGraphqlQuery(GetAccountDocument)

  const sendMessage = useCallback(
    (message?: string, reason?: string) => {
      const url = path(SUPPORT_URL, {
        your_message: message?.replace('.', ''),
        firstname: session?.firstName,
        lastname: session?.lastName,
        email: session?.userName,
        company: getAccount?.data?.getAccount?.name,
        contact_dropdown: reason,
      })
      window.open(url, 'postal-support', 'noopener=true,noreferrer=true')
    },
    [getAccount?.data?.getAccount?.name, path, session?.firstName, session?.lastName, session?.userName]
  )

  const navigateAutoFill = useCallback(
    (navUrl: string) => {
      const url = path(navUrl, {
        firstname: session?.firstName,
        lastname: session?.lastName,
        email: session?.userName,
        company: getAccount?.data?.getAccount?.name,
      })
      window.open(url, 'postal-support', 'noopener=true,noreferrer=true')
    },
    [getAccount?.data?.getAccount?.name, path, session?.firstName, session?.lastName, session?.userName]
  )

  const openHelp = () => {
    window.open('https://help.postal.com/', 'postal-help', 'noopener=true,noreferrer=true')
  }

  return { sendMessage, navigateAutoFill, openHelp }
}
