import type { BoxProps } from '@chakra-ui/react'
import { Box, Grid, Image, List, ListItem, SimpleGrid } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiDropzone,
  UiSkeleton,
  useAlertError,
  useAlerts,
  ZButton,
  ZCard,
  ZCardButton,
  ZCardHeader,
  ZText,
} from '@postal-io/postal-ui'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import React, { useState } from 'react'
import { MdOutlineEdit } from 'react-icons/md'
import type { SearchAssetsQueryVariables } from '../../api'
import {
  AssetOrderByInput,
  AssetType,
  GetAccountDocument,
  SaveAssetDocument,
  SearchAssetsDocument,
  Status,
  UpdateAccountDocument,
} from '../../api'
import { assetUrl, uploadAsset } from '../../api/rest'
import { useAssets } from '../../hooks'
import { DEFAULT_POSTAL_COLORS } from './Colors'

const variables: SearchAssetsQueryVariables = {
  filter: { type: { eq: AssetType.Logo }, status: { eq: Status.Active } },
  orderBy: AssetOrderByInput.CreatedDesc,
}

export const CustomLogo: React.FC<BoxProps> = (props) => {
  const [isLoadingDrop, setIsLoadingDrop] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [secondaryIsLoadingDrop, setSecondaryIsLoadingDrop] = useState(false)
  const [secondaryIsLoaded, setSecondaryIsLoaded] = useState(false)
  const Alert = useAlerts()
  const { data, isLoading, error } = useGraphqlQuery(SearchAssetsDocument, variables)
  const saveAsset = useGraphqlMutation(SaveAssetDocument)
  const updateAccount = useGraphqlMutation(UpdateAccountDocument)
  const { data: accountData, isLoading: accountLoading } = useGraphqlQuery(GetAccountDocument)
  const account = accountData?.getAccount

  const { assetSrc, assetUrlSrc } = useAssets()
  const { src, fallbackSrc } = assetSrc(data?.searchAssets?.[0]?.id, { w: 500 })
  const { src: secondarySrc, fallbackSrc: secondaryFallbackSrc } = assetUrlSrc(account?.brandingInstance?.whiteLogoUrl)

  const primaryBackground = account?.giftEmailPrimaryColor ?? DEFAULT_POSTAL_COLORS.PRIMARY

  useAlertError(error)

  const onDrop = async (files: any) => {
    if (files.length < 1) return Alert.warning('Please drop only supported file types')
    const [{ name }] = files
    setIsLoadingDrop(true)
    try {
      const [{ requestId }]: any = await uploadAsset(files)
      await saveAsset.mutateAsync({ assetType: AssetType.Logo, name, requestId })
      Alert.success('Success! Your new logo has been uploaded.')
    } catch (err) {
      Alert.warning(err)
    } finally {
      setIsLoadingDrop(false)
    }
  }

  const onDropWhiteLogo = async (files: any) => {
    if (files.length < 1) return Alert.warning('Please drop only supported file types')
    const [{ name }] = files
    setSecondaryIsLoadingDrop(true)
    try {
      const [{ requestId }]: any = await uploadAsset(files)
      const newAsset = await saveAsset.mutateAsync({ assetType: AssetType.Image, name, requestId })
      await updateAccount.mutateAsync({
        id: account?.id!,
        data: {
          brandingInstance: {
            whiteLogoUrl: assetUrl(newAsset.saveAsset.id),
          },
        },
      })
      Alert.success('Success! Your new logo has been uploaded.')
    } catch (err) {
      Alert.warning(err)
    } finally {
      setSecondaryIsLoadingDrop(false)
    }
  }

  if (isLoading) return <ZCard isLoading />

  return (
    <Box {...props}>
      <SimpleGrid
        columns={2}
        spacing={8}
      >
        <Box>
          <ZCardHeader
            mb={8}
            justifyContent="flex-start"
            alignItems="center"
            gap={2}
          >
            Main Logo
            <ZInfoTooltip label="Your logo will be included in your gift and event invite workflows, including emails and landing pages, and can easily be included in your direct mail designs." />
          </ZCardHeader>

          {!!src ? (
            <UiSkeleton
              isLoaded={isLoaded}
              w="full"
            >
              <Grid
                templateColumns="fit-content(100%) 28px"
                gridGap={2}
              >
                {!isLoadingDrop && (
                  <Box p={8}>
                    <Image
                      data-testid="customlogo-image"
                      src={src}
                      fallbackSrc={fallbackSrc}
                      maxH="400px"
                      w="100%"
                      onLoad={() => setIsLoaded(true)}
                    />
                  </Box>
                )}
                <UiDropzone
                  onDrop={onDrop}
                  isLoading={isLoadingDrop}
                  accept={{
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/png': ['.png'],
                    'image/gif': ['.gif'],
                    'image/svg+xml': ['.svg'],
                  }}
                  data-testid="dropZone"
                  background="transparent"
                  borderStyle="none"
                  color="atomicBlue.500"
                >
                  <ZCardButton
                    aria-label="edit button"
                    icon={<MdOutlineEdit size="28px" />}
                    color="atomicBlue.500"
                    variant="link"
                    pl="10px"
                  />
                </UiDropzone>
              </Grid>
            </UiSkeleton>
          ) : (
            <UiSkeleton isLoaded>
              <UiDropzone
                onDrop={onDrop}
                isLoading={isLoadingDrop}
                accept={{
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/png': ['.png'],
                  'image/gif': ['.gif'],
                  'image/svg+xml': ['.svg'],
                }}
                data-testid="dropZone"
                background="transparent"
                borderColor="atomicGray.300"
                borderRadius="10px"
                borderWidth="1px"
                borderStyle="dashed"
              >
                <ZText color="atomicGray.700">Drag files here or click to select</ZText>
                <ZText color="atomicGray.500">(Only .jpg, .png, .svg, or .gif files are accepted.)</ZText>
                <ZButton
                  mt={4}
                  variant="outline"
                  colorScheme="atomicGray"
                >
                  Upload Files
                </ZButton>
              </UiDropzone>
              <ZText
                mt={4}
                mb={2}
                color="atomicGray.500"
                fontSize="xs"
              >
                Example logo dimensions that work well:{' '}
              </ZText>
              <List spacing={2}>
                <ListItem>
                  <ZText
                    color="atomicGray.700"
                    fontSize="xs"
                  >
                    1372 x 359 pixels (width by height)
                  </ZText>
                </ListItem>
              </List>
            </UiSkeleton>
          )}
        </Box>

        {/* Secondary white logo */}
        <Box>
          <ZCardHeader
            mb={8}
            justifyContent="flex-start"
            alignItems="center"
            gap={2}
          >
            Secondary White Logo
            <ZInfoTooltip label="Please upload a white logo on a transparent background. This logo will be used on dark backgrounds within email templates and landing pages." />
          </ZCardHeader>

          {!!secondarySrc ? (
            <UiSkeleton isLoaded={secondaryIsLoaded}>
              <Grid
                templateColumns="fit-content(100%) 28px"
                gridGap={2}
              >
                {!secondaryIsLoadingDrop && (
                  <Box
                    backgroundColor={primaryBackground}
                    p={8}
                  >
                    <Image
                      data-testid="customlogo-image"
                      src={secondarySrc}
                      fallbackSrc={secondaryFallbackSrc}
                      maxH="400px"
                      w="100%"
                      onLoad={() => setSecondaryIsLoaded(true)}
                      backgroundColor={primaryBackground}
                    />
                  </Box>
                )}
                <UiDropzone
                  onDrop={onDropWhiteLogo}
                  isLoading={secondaryIsLoadingDrop}
                  accept={{
                    'image/png': ['.png'],
                    'image/svg+xml': ['.svg'],
                  }}
                  data-testid="dropZone"
                  background="transparent"
                  borderStyle="none"
                  color="atomicBlue.500"
                >
                  <MdOutlineEdit size="28px" />
                </UiDropzone>
              </Grid>
            </UiSkeleton>
          ) : (
            <UiSkeleton isLoaded={!accountLoading}>
              <UiDropzone
                onDrop={onDropWhiteLogo}
                isLoading={secondaryIsLoadingDrop}
                accept={{
                  'image/png': ['.png'],
                  'image/svg+xml': ['.svg'],
                }}
                data-testid="dropZone"
                background="transparent"
                borderColor="atomicGray.300"
                borderRadius="10px"
                borderWidth="1px"
                borderStyle="dashed"
              >
                <ZText color="atomicGray.700">Drag files here or click to select</ZText>
                <ZText color="atomicGray.500">(Only .png or .svg files are accepted.)</ZText>
                <ZButton
                  mt={4}
                  variant="outline"
                  colorScheme="atomicGray"
                >
                  Upload Files
                </ZButton>
              </UiDropzone>
              <ZText
                mt={4}
                mb={2}
                color="atomicGray.500"
                fontSize="xs"
              >
                Example logo dimensions that work well:{' '}
              </ZText>
              <List spacing={2}>
                <ListItem>
                  <ZText
                    color="atomicGray.700"
                    fontSize="xs"
                  >
                    1372 x 359 pixels (width by height)
                  </ZText>
                </ListItem>
              </List>
            </UiSkeleton>
          )}
        </Box>
      </SimpleGrid>
    </Box>
  )
}
