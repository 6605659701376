import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { isEmptyValue, useAlertError } from '@postal-io/postal-ui'
import { PostalSend } from 'components/PostalSend/PostalSend'
import { isPostalDisabled, PostalSendMethod, PostalSendType } from 'components/PostalSend/postalSendHelpers'
import type { ContactId } from 'components/PostalSend/usePostalSend'
import { useNavigateRetryOrderParams } from 'hooks'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  GetApprovedPostalDocument,
  GetContactDocument,
  GetPostalFulfillmentDocument,
  SendFlowStep,
  SendType,
} from '../../api'
import { PostalSendLoading } from './PostalSendLoading'
import { PostalUnavailable } from './PostalUnavailable'

export const RetryPostal: React.FC = () => {
  const navigate = useNavigate()

  const { orderId, returnTo, returnPath } = useNavigateRetryOrderParams()

  const exitLabel = `Exit to ${returnTo}`
  const backLabel = `Back to ${returnTo}`
  const onNavigateBack = () => (returnPath ? navigate(returnPath) : undefined)

  const postalFulfillmentQuery = useGraphqlQuery(GetPostalFulfillmentDocument, { id: orderId })
  const postalFulfillment = postalFulfillmentQuery.data?.getPostalFulfillment

  //If the action send type is PostalSendType === Retry, then we only have id's for the contacts. Need to query for the given contactId
  const contactData = useGraphqlQuery(
    GetContactDocument,
    { id: postalFulfillment?.contactId as string },
    { enabled: !!postalFulfillment?.contactId }
  )

  const singleContact = contactData.data?.getContact as ContactId

  const contacts = useMemo(() => {
    return { items: postalFulfillment ? [{ ...singleContact }] : [], filters: [] }
  }, [postalFulfillment, singleContact])

  //If this is a retry order, we want to user to reconfirm the delivery email process
  const deliveryEmail = useMemo(() => {
    if (postalFulfillment?.magicLinkId || postalFulfillment?.triggerId) return undefined
    return postalFulfillment?.deliveryEmail
  }, [postalFulfillment?.deliveryEmail, postalFulfillment?.magicLinkId, postalFulfillment?.triggerId])

  const approvedPostalQuery = useGraphqlQuery(
    GetApprovedPostalDocument,
    { id: postalFulfillment?.approvedPostalId as string },
    { enabled: !!postalFulfillment, useErrorBoundary: false }
  )

  const approvedPostal = useMemo(
    () => approvedPostalQuery.data?.getApprovedPostal,
    [approvedPostalQuery.data?.getApprovedPostal]
  )

  const variant = useMemo(
    () => approvedPostal?.variants?.find((v) => v.id === postalFulfillment?.variantId),
    [approvedPostal?.variants, postalFulfillment?.variantId]
  )

  const quantity = useMemo(
    () => postalFulfillment?.fulfillments?.[0].quantity ?? undefined,
    [postalFulfillment?.fulfillments]
  )

  const isLoading = postalFulfillmentQuery.isLoading || approvedPostalQuery.isLoading || contactData.isLoading

  useAlertError(postalFulfillmentQuery.error)

  // change this to send the actual input when it exists
  const itemCustomizationInputs = useMemo(() => {
    return postalFulfillment?.itemCustomizationList
      ?.filter((i) => !isEmptyValue(i.value))
      .map((i) => ({ id: i.id, value: i.value }))
  }, [postalFulfillment?.itemCustomizationList])

  if (isLoading) {
    return (
      <PostalSendLoading
        backLabel={backLabel}
        onNavigateBack={onNavigateBack}
      />
    )
  }

  if (isPostalDisabled(approvedPostal)) {
    return (
      <PostalUnavailable
        isOpen
        onClose={onNavigateBack}
      />
    )
  }

  return (
    <PostalSend
      onNavigateBack={onNavigateBack}
      navigateBackLabel={exitLabel}
      onExitLabel={exitLabel}
      postal={approvedPostal!}
      variant={variant}
      stepToRecall={SendFlowStep.SendCustomization}
      contacts={contacts}
      method={
        postalFulfillment?.deliveryEmail
          ? PostalSendMethod.Email
          : quantity !== undefined && postalFulfillment?.sendType === SendType.BulkSend
          ? PostalSendMethod.BulkSend
          : PostalSendMethod.Direct
      }
      quantity={quantity}
      shipToAddress={quantity ? postalFulfillment?.shipTo! : undefined}
      giftMessage={postalFulfillment?.giftMessage}
      landingPageHeaderText={postalFulfillment?.landingPageCustomization?.headerText}
      landingPageBody={postalFulfillment?.landingPageCustomization?.body}
      landingPageIncludeHeadshot={!!postalFulfillment?.landingPageCustomization?.includeHeadshot}
      landingPageIncludeSenderName={!!postalFulfillment?.landingPageCustomization?.includeSenderName}
      formFieldList={postalFulfillment?.formFieldList}
      deliveryEmail={deliveryEmail}
      usePhysicalMessage={!!postalFulfillment?.physicalMessage}
      physicalMessage={postalFulfillment?.physicalMessage}
      type={PostalSendType.Retry}
      emailSubjectLine={postalFulfillment?.emailSubjectLine}
      sendAsUser={postalFulfillment?.sendAsUserId}
      meetingRequestSetting={postalFulfillment?.meetingRequestSetting}
      itemCustomizationInputs={itemCustomizationInputs}
    />
  )
}
