import { FormControl } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { SelectTypeaheadStylesV2, useAlertError, ZFormLabel } from '@postal-io/postal-ui'
import { RequiredSelectTypeahead } from 'components/AutoComplete/RequiredSelectTypeahead'
import React from 'react'
import { GetSequencesDocument, GetSequenceStepsDocument } from '../../api'

interface TriggerEditSequenceV2Props {
  state: Record<string, any>
  setState: (f: (draft: any) => any) => void
}

export const TriggerEditSequence: React.FC<TriggerEditSequenceV2Props> = ({ state, setState }) => {
  // Get sequences if we choose a sequence enabled integration
  const sequenceQuery = useGraphqlQuery(GetSequencesDocument, { system: state.systemName })
  const sequences = sequenceQuery.data?.getSequences?.sequences || []
  useAlertError(sequenceQuery.error)

  // Get steps if we have chosen a sequence
  const stepsQuery = useGraphqlQuery(
    GetSequenceStepsDocument,
    { system: state.systemName, sequenceId: state.config?.sequenceId },
    { enabled: !!state.config?.sequenceId }
  )
  useAlertError(stepsQuery.error)
  const steps = stepsQuery?.data?.getSequenceSteps?.steps || []

  const handleChange = (name: string) => (e: any) => {
    const { value } = e
    setState((draft) => {
      switch (name) {
        case 'sequenceId':
          // reset the sequenceStepId
          draft.config = { sequenceId: value }
          break
        case 'sequenceStepId':
          draft.config = draft.config || {}
          draft.config.sequenceStepId = value
          break
        default:
      }
    })
  }

  const sequenceOptions = [
    {
      label: sequenceQuery.isLoading
        ? 'Loading....'
        : `Select a ${state.systemName === 'salesloft' ? 'Cadence' : 'Sequence'}`,
      value: '',
    },
    ...(sequences
      ?.map((sequence) => ({
        label: sequence.name,
        value: sequence.id,
      }))
      .filter(Boolean) ?? []),
  ]

  const stepOptions = [
    { label: stepsQuery.isLoading ? 'Loading...' : 'Select a step', value: '' },
    ...(steps
      ?.map((step) => ({
        label: step.name,
        value: step.id,
      }))
      .filter(Boolean) ?? []),
  ]

  return (
    <>
      <FormControl>
        <ZFormLabel
          htmlFor="sequenceId"
          fontSize="md"
        >
          {state.systemName === 'salesloft' ? 'Cadence' : 'Sequence'}
        </ZFormLabel>
        <RequiredSelectTypeahead
          id="sequenceId"
          name="sequenceId"
          value={sequenceOptions.find((option) => option.value === state.config?.sequenceId)}
          onChange={handleChange('sequenceId')}
          options={sequenceOptions}
          isDisabled={!sequences.length}
          {...SelectTypeaheadStylesV2}
        />
      </FormControl>
      <FormControl>
        <ZFormLabel
          htmlFor="sequenceStepId"
          fontSize="md"
        >
          Step
        </ZFormLabel>
        <RequiredSelectTypeahead
          id="sequenceStepId"
          name="sequenceStepId"
          value={stepOptions.find((option) => option.value === state.config?.sequenceStepId)}
          onChange={handleChange('sequenceStepId')}
          options={stepOptions}
          isDisabled={!state.config?.sequenceId || !steps.length}
          {...SelectTypeaheadStylesV2}
        />
      </FormControl>
    </>
  )
}
