import type { ContainerProps } from '@chakra-ui/react'
import { Container } from '@chakra-ui/react'
import useMeasure from 'react-use-measure'

/**
 * This element will stick to the top of the screen and conditionally cast a shadow/show a bottom border
 * to visually separate the content in the element from the content in the rest of the page. It uses
 * separate elements to cast and hide the shadow when in the initial position and some z-index trickery.
 *
 * Note: sticky elements only move within their parent elements, so make sure the parent element won't confine
 * the motion of this element.
 */

export const StickyElementWithShadowV2: React.FC<ContainerProps> = ({ children, zIndex, boxShadow, ...rest }) => {
  const [ref, { height: contentHeight }] = useMeasure()
  const baseZindex = Number(zIndex ?? 2)

  const commonProps = {
    w: '100%',
    maxW: '100%',
    bg: 'white',
  }

  return (
    <>
      {/* top shadow hiding element */}
      <Container
        {...commonProps}
        {...rest}
        h={6}
        p={0}
        mb={0}
        position="relative"
        zIndex={baseZindex + 1}
      />
      {/* shadow casting element */}
      <Container
        {...commonProps}
        top={0}
        {...rest}
        my={0}
        py={0}
        pt={2}
        boxShadow={boxShadow ?? '0 14px 10px -8px rgba(0, 0, 0, 0.2),0 12px 6px -6px rgba(0, 0, 0, 0.08)'}
        h={`${contentHeight}px`}
        zIndex={baseZindex}
        position="sticky"
      />
      {/* content container */}
      <Container
        {...commonProps}
        top={2}
        {...rest}
        my={0}
        position="sticky"
        mt={`${-contentHeight}px`}
        zIndex={baseZindex + 2}
        ref={ref}
      >
        {children}
      </Container>
      {/* bottom shadow hiding element */}
      <Container
        {...commonProps}
        {...rest}
        h={6}
        p={0}
        mt="-1px"
        position="relative"
        zIndex={baseZindex + 1}
      />
    </>
  )
}
