import { ViewIcon } from '@chakra-ui/icons'
import type { FlexProps } from '@chakra-ui/react'
import {
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { FontWeight } from '@postal-io/postal-ui'
import React from 'react'

interface ImagePopoverProps extends FlexProps {
  src?: string
  fallbackSrc?: string
  hidePreviewText?: boolean
}
export const ImagePopover: React.FC<ImagePopoverProps> = ({ src, fallbackSrc, hidePreviewText, ...rest }) => {
  if (!src) return null

  return (
    <Popover
      trigger="hover"
      placement="top"
    >
      <PopoverTrigger>
        <Flex
          alignItems="center"
          {...rest}
        >
          <ViewIcon
            color="primary.500"
            boxSize="20px"
          />
          <Text
            color="primary.500"
            fontSize="xs"
            ml={2}
            textTransform="uppercase"
            fontWeight={FontWeight.Bold}
            hidden={hidePreviewText}
          >
            Preview
          </Text>
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody p={2}>
          <Image
            maxW="600px"
            maxH="600px"
            w="100%"
            h="100%"
            objectFit="contain"
            src={src}
            fallbackSrc={fallbackSrc}
            alt="preview image"
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
