import { CheckCircleIcon } from '@chakra-ui/icons'
import type { BoxProps } from '@chakra-ui/react'
import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

interface AddressVerifiedBadgeProps extends BoxProps {
  isVerified: boolean
}

export const AddressVerifiedBadge: React.FC<AddressVerifiedBadgeProps> = ({ isVerified, ...rest }) => {
  if (!isVerified) return null
  return (
    <Flex
      w="100%"
      {...rest}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        textAlign="center"
      >
        <CheckCircleIcon
          boxSize="24px"
          color="tertiary.500"
          display="block"
        />
        <Text
          fontSize="sm"
          color="tertiary.500"
          fontWeight={500}
          mt={2}
        >
          Address Verified
        </Text>
      </Flex>
    </Flex>
  )
}
