import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { useMemo } from 'react'
import type { ApprovedPostal } from '../api'
import { Status, TeamsDocument } from '../api'

export const usePostalTeams = (postal?: ApprovedPostal | null) => {
  // get the teams associated with this postal
  const teamsVariables = useMemo(
    () => ({ filter: { id: { in: postal?.teamIds }, status: { eq: Status.Active } } }),
    [postal?.teamIds]
  )
  const teamsQuery = useGraphqlQuery(TeamsDocument, teamsVariables, {
    enabled: !!postal?.teamIds?.length,
  })
  const teams = useMemo(() => teamsQuery.data?.teams || [], [teamsQuery.data?.teams])

  const teamNames = useMemo(() => teams.map((team) => team.name), [teams])

  return { teams, teamNames, isLoading: teamsQuery.isLoading }
}
