import type { BoxProps } from '@chakra-ui/react'
import {
  Divider,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { UiStateInput, UiToggle, useAlerts, usePlace, ZButton, ZInput } from '@postal-io/postal-ui'
import type { Address, AddressInput, ContactInput, ContactUpdateInput } from 'api'
import { AddressSource, ContactType, UpdateContactDocument } from 'api'
import { AutoCompleteCountry } from 'components/AutoComplete/AutoCompleteCountry'
import { zAutocompleteStyles, ZFormLabel } from 'components/Common/ZComponents'
import type { PostalSendContext } from 'components/PostalSend/usePostalSend'
import { usePostalSendFieldErrors } from 'components/PostalSend/usePostalSendFieldErrors'
import { CONTACT_INVALIDATIONS, useBackgroundQueue } from 'hooks'
import React, { useEffect } from 'react'
import { MdOutlineLocationOn, MdSearch } from 'react-icons/md'
import type { Updater } from 'use-immer'
import { useImmer } from 'use-immer'
import { VerifyAddress } from './VerifyAddress'

export interface AddressFormProps extends BoxProps {
  addresses: AddressInput[] | null
  contactId?: string
  onClose: () => void
  selectedIndex?: number
  setFormAddresses?: Updater<ContactInput> | Updater<ContactUpdateInput>
}

export const defaultAddressForm = {
  entryName: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  state: '',
  postalCode: '',
  country: 'USA',
  preferred: false,
  source: AddressSource.Manual,
}

export const AddressForm: React.FC<AddressFormProps> = ({
  addresses,
  contactId,
  onClose,
  selectedIndex,
  setFormAddresses,
}) => {
  const Alert = useAlerts()
  const verifyAddress = useDisclosure()

  const [form, setForm] = useImmer<Address>(defaultAddressForm)

  const isUpdatingAddress = typeof selectedIndex === 'number'

  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name, value } = target
    if (name === 'preferred') {
      setForm((draft: Record<string, any>) => void (draft.preferred = checked))
    } else {
      setForm((draft: Record<string, any>) => void (draft[name] = value))
    }
  }

  useEffect(() => {
    if (isUpdatingAddress) {
      const selectedAddress = addresses?.find((_item, idx) => idx === selectedIndex) as Address
      setForm(selectedAddress)
    }
  }, [addresses, isUpdatingAddress, selectedIndex, setForm])

  const { invalidate } = useBackgroundQueue()
  const updateContact = useGraphqlMutation(UpdateContactDocument, {
    onSuccess: () => invalidate(CONTACT_INVALIDATIONS),
  })

  const onVerifiedAdd = async (verifyAddress: Address) => {
    const newAddress = { ...verifyAddress } as AddressInput

    //normalize fields from form
    newAddress.source = AddressSource.Manual
    newAddress.preferred = addresses?.length === 0 ? true : form?.preferred
    newAddress.entryName = form?.entryName

    let addressInputs = addresses || []

    if (newAddress?.preferred && addressInputs.length > 0) {
      addressInputs = addressInputs.map((address) => {
        return { ...address, preferred: false }
      })
    }

    //Add new Address to the address input array
    addressInputs = [...addressInputs, { ...newAddress }]

    const hasPreferred = addressInputs.some((item) => item.preferred)
    if (!hasPreferred) {
      return Alert.warning('Please select a preferred address.')
    }

    if (setFormAddresses) {
      setFormAddresses((draft: Record<string, any>) => void (draft.addresses = addressInputs))
      onClose()
    }
  }

  const onVerifiedUpdate = async (verifiedAddress: Address) => {
    let addressInputs = addresses?.map((address, idx) => {
      if (idx === selectedIndex) {
        return {
          ...verifiedAddress,
          //These values are coming back from the updated form fields
          source: form?.source || AddressSource.Manual,
          entryName: form?.entryName,
          preferred: form?.preferred || false,
        }
      } else {
        return { ...address }
      }
    })

    //if the selected address has been updated and is prefered, remap addresses)
    if (form?.preferred) {
      addressInputs = addressInputs?.map((address, idx) => {
        return { ...address, preferred: idx === selectedIndex }
      })
    }

    if (setFormAddresses) {
      setFormAddresses((draft: Record<string, any>) => void (draft.addresses = addressInputs))
      onClose()
    } else {
      const hasPreferred = addressInputs?.some((item) => item.preferred)
      if (addressInputs?.length && !hasPreferred) {
        return Alert.warning('Please select a preferred address.')
      }

      //There is a mixture of AddressInputs and Address normalization is needed before submitting.
      const cleanedAddressInputs = addressInputs?.map((item) => {
        return {
          entryName: item?.entryName,
          address1: item?.address1,
          address2: item?.address2,
          address3: item?.address3,
          city: item?.city,
          state: item?.state,
          postalCode: item?.postalCode,
          country: item?.country,
          preferred: item?.preferred,
          source: item?.source,
          status: item?.status,
        }
      }) as AddressInput[]

      try {
        await updateContact.mutateAsync({
          id: contactId as string,
          data: {
            type: ContactType.Contact,
            addresses: cleanedAddressInputs as AddressInput[],
          },
        })
        onClose()
      } catch (err) {
        Alert.error(err)
      }
    }
  }

  const handleVerifiedSubmit = (verfiedAddress: any) => {
    if (isUpdatingAddress) {
      onVerifiedUpdate(verfiedAddress)
    } else {
      onVerifiedAdd(verfiedAddress)
    }
  }

  const handleVerify = (e: React.FormEvent) => {
    e.preventDefault()
    verifyAddress.onOpen()
  }

  return (
    <>
      <form onSubmit={handleVerify}>
        <FormControl
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          id="preferred"
        >
          <UiToggle
            colorScheme="atomicBlue"
            data-testid="preferredCheckAdd"
            isChecked={addresses?.length === 0 ? true : form?.preferred}
            mt={3}
            name="preferred"
            onChange={handleInput}
            size="lg"
          >
            <Text
              color="gray.500"
              fontSize={14}
              fontWeight="normal"
            >
              Preferred Address
            </Text>
          </UiToggle>
        </FormControl>
        <AddressFormInput
          form={form}
          setForm={setForm}
          includeAddressLabel
        />
        <HStack
          justifyContent="space-between"
          mt={20}
          spacing={4}
        >
          <ZButton
            bgColor="blue.400"
            display="flex"
            isDisabled={updateContact.isLoading}
            isLoading={updateContact.isLoading}
            justifyContent="center"
            type="submit"
            w="167px"
          >
            {`${isUpdatingAddress ? 'Update' : 'Add'} Address`}
          </ZButton>
          <ZButton
            colorScheme="gray"
            onClick={onClose}
            variant="link"
          >
            Cancel
          </ZButton>
        </HStack>
      </form>
      {verifyAddress.isOpen && form && (
        <VerifyAddress
          address={form}
          onVerified={handleVerifiedSubmit}
          {...verifyAddress}
        />
      )}
    </>
  )
}

interface AddressFormInputProps {
  context?: PostalSendContext
  form: Address
  setForm: (updater: ((draft: Partial<Address>) => void) | Address) => void
  includeAddressLabel?: boolean
}
export const AddressFormInput: React.FC<AddressFormInputProps> = ({ form, setForm, includeAddressLabel, context }) => {
  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target
    setForm((draft: Record<string, any>) => void (draft[name] = value))
  }

  const handleUpdateAddress = (address: Partial<Address>) => {
    const dataCopy = { ...address, preferred: form?.preferred || false }
    setForm(dataCopy)
  }
  const { ref } = usePlace((addr: Partial<Address>) => {
    handleUpdateAddress(addr)
  })
  // prevent accidental form submission when hitting enter key in search bar
  const handleKeyDown = function (e: React.KeyboardEvent<any>) {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  // Only used for send flow
  const address1ErrorProps = usePostalSendFieldErrors({ context, field: 'shipToAddress.address1' })
  const cityErrorProps = usePostalSendFieldErrors({ context, field: 'shipToAddress.city' })
  const postalCodeErrorProps = usePostalSendFieldErrors({ context, field: 'shipToAddress.postalCode' })
  const stateErrorProps = usePostalSendFieldErrors({ context, field: 'shipToAddress.state' })
  const countryErrorProps = usePostalSendFieldErrors({ context, field: 'shipToAddress.country' })

  return (
    <>
      {includeAddressLabel ? (
        <SimpleGrid
          columns={2}
          mt={8}
          spacing={4}
        >
          <FormControl id="search">
            <ZFormLabel fontWeight="normal">Search for an Address</ZFormLabel>
            <InputGroup>
              <InputRightElement
                h="40px"
                w="40px"
                color="atomicGray.500"
              >
                <MdSearch
                  color="atomicGray.500"
                  size={18}
                />
              </InputRightElement>
              <Input
                height="40px"
                backgroundColor="white"
                type="search"
                placeholder="Search a location"
                ref={ref}
                data-testid="places"
                onKeyDown={handleKeyDown}
              />
            </InputGroup>
          </FormControl>
          <FormControl id="entryName">
            <ZFormLabel fontWeight="normal">Address Label</ZFormLabel>
            <ZInput
              onChange={handleInput}
              value={form?.entryName || ''}
              name="entryName"
              placeholder="Work, Home, etc.."
              data-private
            />
          </FormControl>
        </SimpleGrid>
      ) : (
        <FormControl id="search">
          {/* <ZFormLabel fontWeight="normal">Search for an Address</ZFormLabel> */}
          <InputGroup>
            <InputLeftElement
              h="40px"
              // w="40px"
              color="atomicGray.500"
            >
              <MdOutlineLocationOn
                color="atomicGray.500"
                size={18}
              />
            </InputLeftElement>
            <Input
              height="40px"
              backgroundColor="white"
              type="search"
              placeholder="Search a location"
              ref={ref}
              data-testid="places"
              onKeyDown={handleKeyDown}
            />
          </InputGroup>
        </FormControl>
      )}
      <Divider my={8} />
      <SimpleGrid
        columns={2}
        spacing={4}
      >
        <FormControl id="address1">
          <ZFormLabel fontWeight="normal">Street Address 1</ZFormLabel>
          <ZInput
            {...address1ErrorProps}
            onChange={handleInput}
            value={form?.address1 || ''}
            name="address1"
            data-private
          />
        </FormControl>
        <FormControl id="address2">
          <ZFormLabel fontWeight="normal">Street Address 2</ZFormLabel>
          <ZInput
            onChange={handleInput}
            value={form?.address2 || ''}
            name="address2"
            data-private
          />
        </FormControl>
        <FormControl id="city">
          <ZFormLabel fontWeight="normal">City</ZFormLabel>
          <ZInput
            {...cityErrorProps}
            onChange={handleInput}
            value={form?.city || ''}
            name="city"
            data-private
          />
        </FormControl>
        <FormControl id="postalCode">
          <ZFormLabel fontWeight="normal">Postal Code</ZFormLabel>
          <ZInput
            {...postalCodeErrorProps}
            onChange={handleInput}
            value={form?.postalCode || ''}
            name="postalCode"
            data-private
          />
        </FormControl>
        <FormControl id="state">
          <ZFormLabel fontWeight="normal">State</ZFormLabel>
          <UiStateInput
            {...stateErrorProps}
            onChange={handleInput}
            country={form?.country || undefined}
            value={form?.state || ''}
            name="state"
            data-private
            height="40px"
          />
        </FormControl>
        <FormControl id="country">
          <ZFormLabel fontWeight="normal">Country</ZFormLabel>
          <AutoCompleteCountry
            inputRef={countryErrorProps.ref}
            animation={countryErrorProps.animation}
            styles={zAutocompleteStyles}
            data-private
            countryName={form?.country}
            onChange={(country) => setForm((draft) => void (draft.country = country?.iso3 || ''))}
          />
        </FormControl>
      </SimpleGrid>
    </>
  )
}
