import type { FlexProps, IconButtonProps } from '@chakra-ui/react'
import { Box, Fade, HStack, IconButton } from '@chakra-ui/react'
import type { UiTooltipProps } from '@postal-io/postal-ui'
import { UiTooltip } from '@postal-io/postal-ui'
import type { ContactList } from 'api'
import type { ReactElement } from 'react'
import React, { useMemo } from 'react'
import {
  MdFavoriteBorder,
  MdLabelOutline,
  MdMenuBook,
  MdOutlineContacts,
  MdOutlineDelete,
  MdSend,
} from 'react-icons/md'
import type { ModulePermission } from '../../hooks'
import { useAcl } from '../../hooks'

interface ContactButton {
  'type': string
  'icon': ReactElement
  'label': string
  'data-testid'?: string
  'placement'?: UiTooltipProps['placement']
  'module'?: ModulePermission
}
interface ContactsButtonsV2Props extends FlexProps {
  selectedList?: ContactList
  favoriteList?: ContactList
  isDisabled: boolean
  onSelectButton: (type: string) => void
}
export const ContactsButtons: React.FC<ContactsButtonsV2Props> = ({
  selectedList,
  favoriteList,
  onSelectButton,
  isDisabled,
  ...rest
}) => {
  const isFavoriteSelected = useMemo(
    () => selectedList && favoriteList?.id === selectedList?.id,
    [favoriteList?.id, selectedList]
  )
  const isNonFavoriteSelected = useMemo(() => selectedList && !isFavoriteSelected, [isFavoriteSelected, selectedList])
  const { aclFilter } = useAcl()

  const postalButtons: ContactButton[] = useMemo(() => {
    return aclFilter([
      {
        'type': 'POSTAL',
        'icon': <MdSend size={20} />,
        'label': 'Send Item',
        'data-testid': 'sendPostalIcon',
        'placement': 'top',
        'module': 'postals.send',
      },
      {
        'type': 'SUBSCRIPTION',
        'icon': <MdMenuBook size={20} />,
        'label': `Send Subscription`,
        'data-testid': 'playbookIcon',
        'placement': 'top',
        'module': 'playbooks.create',
      },
    ])
  }, [aclFilter])

  const buttons: ContactButton[] = useMemo(() => {
    return aclFilter([
      {
        'type': 'FAVORITE',
        'icon': <MdFavoriteBorder size={20} />,
        'label': isFavoriteSelected ? 'Remove from Favorites' : 'Add to Favorites',
        'data-testid': 'favoriteAddIcon',
        'placement': 'top',
      },
      {
        'type': 'CONTACT_LIST',
        'icon': <MdOutlineContacts size={20} />,
        'label': isNonFavoriteSelected ? 'Remove from List' : 'Update Lists',
        'data-testid': 'listAddIcon',
        'placement': 'top',
      },
      {
        'type': 'TAGS',
        'icon': <MdLabelOutline size={20} />,
        'label': 'Update Tags',
        'data-testid': 'tagAddIcon',
        'placement': 'top',
      },
      {
        'type': 'DELETE',
        'icon': <MdOutlineDelete size={20} />,
        'label': 'Delete Contacts',
        'data-testid': 'deleteIcon',
        'placement': 'top',
      },
    ])
  }, [aclFilter, isFavoriteSelected, isNonFavoriteSelected])

  const buttonProps: Partial<IconButtonProps> = {
    bgColor: 'white',
    border: '1px solid',
    borderColor: 'gray.200',
    color: 'gray.500',
    height: '40px',
    isRound: true,
    size: 'sm',
    width: '40px',
  }

  return (
    <Fade
      in={!isDisabled}
      unmountOnExit
    >
      <Box
        bottom={20}
        boxShadow="postalShadow"
        left="50%"
        position="fixed"
        transform={isDisabled ? 'translate3d(-50%, 10px, 0)' : 'translate3d(-50%, 0, 0)'}
        transition="0.2s transform"
        {...rest}
      >
        <HStack
          bg="gray.900"
          border="10px solid"
          borderColor="blue.50"
          borderRadius="50px"
          px="20px"
          py="10px"
        >
          <Box mr={postalButtons.length ? 6 : 0}>
            {postalButtons.map(({ label, type, ...button }, idx) => (
              <UiTooltip
                key={type}
                label={label}
              >
                <IconButton
                  _hover={{ bg: 'atomicGray.300', borderColor: 'atomicGray.300' }}
                  aria-label={label}
                  mr={idx === buttons.length - 1 ? 0 : 3}
                  onClick={() => onSelectButton(type)}
                  {...buttonProps}
                  {...button}
                />
              </UiTooltip>
            ))}
          </Box>
          {buttons.map(({ label, type, ...button }, idx) => (
            <UiTooltip
              key={type}
              label={label}
            >
              <IconButton
                _hover={{ bg: 'atomicGray.300', borderColor: 'atomicGray.300' }}
                aria-label={label}
                mr={idx === buttons.length - 1 ? 0 : 3}
                onClick={() => onSelectButton(type)}
                {...buttonProps}
                {...button}
              />
            </UiTooltip>
          ))}
        </HStack>
      </Box>
    </Fade>
  )
}
