import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import { SavedSendOrderByInput, SavedSendStatus, SearchSavedSendsDocument } from 'api'
import { merge } from 'lodash'

export const useDrafts = (overrides: any) => {
  const variables = merge(
    {
      filter: { status: { eq: SavedSendStatus.Draft } },
      orderBy: SavedSendOrderByInput.CreatedDesc,
    },
    overrides
  )
  const drafts = useGraphqlInfiniteQuery(SearchSavedSendsDocument, variables)

  return {
    ...drafts,
    variables,
    data: drafts.mergedData?.searchSavedSends || [],
  }
}
