import { Divider, Flex, Stack } from '@chakra-ui/react'
import { ZHeading } from '@postal-io/postal-ui'
import React, { useEffect } from 'react'
import type { ApprovedPostal, MarketplaceProduct } from '../../api'
import { Status } from '../../api'
import {
  CollectionItemOptionsApproved,
  CollectionItemOptionsApprovedMultiVersion,
} from './CollectionItemOptionsApproved'
import {
  CollectionItemOptionsMarketplace,
  CollectionItemOptionsMarketplaceMultiVersion,
} from './CollectionItemOptionsMarketplace'
import type { EitherItem, EitherVariant } from './utils'
import { addItemFieldsToVariant, isItemApprovedPostal } from './utils'

interface CollectionItemOptionsSelectProps {
  selectedItems: EitherItem[]
  isVariantSelected: (v: EitherVariant) => boolean
  handleSelectVariant: (v: EitherVariant) => void
  setSelectedVariants: (v: EitherVariant[]) => void
}
export const CollectionItemOptionsSelect: React.FC<CollectionItemOptionsSelectProps> = ({
  selectedItems,
  isVariantSelected,
  handleSelectVariant,
  setSelectedVariants,
}) => {
  // autoselect first active variant of each item (maybe better to select all active variants?)
  useEffect(() => {
    const initialSelectedVariants = selectedItems.flatMap((item) =>
      ((item.variants as EitherVariant[]) ?? [])
        .filter((v) => v.status === Status.Active)
        .map((v) => addItemFieldsToVariant(item, v))
    )
    setSelectedVariants(initialSelectedVariants)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return selectedItems.length > 1 ? (
    <>
      <ZHeading
        fontSize="lg"
        textAlign="center"
        my={10}
      >
        Please select the options for each item below.
      </ZHeading>
      <Stack
        maxW="900px"
        mx="auto"
        divider={<Divider />}
      >
        {selectedItems.map((item) =>
          isItemApprovedPostal(item) ? (
            <CollectionItemOptionsApprovedMultiVersion
              key={item.id}
              approvedItem={item as ApprovedPostal}
              isBulkSelected={isVariantSelected}
              onSelect={handleSelectVariant}
            />
          ) : (
            <CollectionItemOptionsMarketplaceMultiVersion
              key={item.id}
              marketplaceItem={item as MarketplaceProduct}
              isBulkSelected={isVariantSelected}
              onSelect={handleSelectVariant}
            />
          )
        )}
      </Stack>
    </>
  ) : selectedItems.length === 1 ? (
    <Flex
      maxW="1440px"
      mx="auto"
    >
      {isItemApprovedPostal(selectedItems[0]) ? (
        <CollectionItemOptionsApproved
          approvedItem={selectedItems[0] as ApprovedPostal}
          isBulkSelected={isVariantSelected}
          onSelect={handleSelectVariant}
          mt={8}
        />
      ) : (
        <CollectionItemOptionsMarketplace
          marketplaceItem={selectedItems[0] as MarketplaceProduct}
          isBulkSelected={isVariantSelected}
          onSelect={handleSelectVariant}
          mt={8}
        />
      )}
    </Flex>
  ) : null
}
