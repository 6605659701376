import type { BoxProps, HeadingProps } from '@chakra-ui/react'
import { Box, Heading } from '@chakra-ui/react'
import { UiRenderRichText } from '@postal-io/postal-ui'
import React from 'react'

export const Header: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading
      as="h3"
      size="md"
      mb={4}
      fontWeight="semibold"
      color="gray.800"
      fontFamily="body"
      {...rest}
    >
      {children}
    </Heading>
  )
}

interface MessagePreviewProps extends BoxProps {
  message?: string | null
}

export const MessagePreview: React.FC<MessagePreviewProps> = ({ message, children, ...rest }) => {
  return (
    <Box {...rest}>
      <Header>{children}</Header>
      <Box
        py={2}
        whiteSpace="break-spaces"
      >
        <UiRenderRichText html={message || ''} />
      </Box>
    </Box>
  )
}
