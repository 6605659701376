import { Box } from '@chakra-ui/react'
import { ZText } from '@postal-io/postal-ui'
import React from 'react'

interface ReportingTooltipV2Props {
  nameLabel?: string
  name: string
  valueLabel?: string
  value: number
  formattedValue?: string
  totalAmount: number
  formattedTotal?: string
  color: string
}
export const ReportingTooltip: React.FC<ReportingTooltipV2Props> = ({
  nameLabel,
  name,
  valueLabel,
  value,
  formattedValue,
  totalAmount,
  formattedTotal,
  color,
}) => {
  const percentage = Math.round((1000 * value) / totalAmount) / 10

  return (
    <Box
      p={5}
      bg="gray.50"
      borderRadius={5}
      border="1px solid rgba(0,0,0,.05)"
      boxShadow="lg"
    >
      <Box
        display="flex"
        flexDir="row"
        alignItems="stretch"
        p={3}
        pt={0}
        borderBottom="1px solid rgba(0,0,0,.2)"
      >
        <Box
          bg={color}
          w={1.5}
          borderRadius={5}
          ml={-3.5}
          mr={2}
        />
        <Box display="block">
          <ZText
            fontWeight="bold"
            color="atomicGray.500"
            display="block"
            pb={1}
          >
            {nameLabel}
          </ZText>
          <ZText
            fontWeight="bold"
            color="atomicGray.900"
            pb={1}
          >
            {name}
          </ZText>
        </Box>
      </Box>
      <Box
        p={3}
        pb={0}
        display="flex"
        justifyContent="space-between"
      >
        <ZText
          fontWeight="bold"
          color="atomicGray.500"
          pr={8}
        >
          {valueLabel}
        </ZText>
        <Box
          display="flex"
          flexDir="column"
          alignItems="flex-end"
        >
          <ZText
            fontWeight="bold"
            color="atomicGray.900"
          >
            {formattedValue ?? value}
          </ZText>
          {totalAmount && (
            <ZText
              fontSize="xs"
              color="atomicGray.500"
            >
              ({percentage}% of {formattedTotal ?? totalAmount})
            </ZText>
          )}
        </Box>
      </Box>
    </Box>
  )
}
