import {
  addGraphqlBackgroundTaskInvalidations,
  addGraphqlMutationInvalidations,
  addGraphqlQueryStaleTimes,
  handleUseErrorBoundary,
  setGraphqlEndpoint,
  setGraphqlFetchMiddleware,
} from '@postal-io/postal-graphql'
import { MutationCache, QueryCache, QueryClient } from 'react-query'
import { getAuthToken } from './rest'

const queryCache = new QueryCache()
const mutationCache = new MutationCache()
export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 500,
      notifyOnChangeProps: 'tracked',
      staleTime: 10 * 1000,
      useErrorBoundary: handleUseErrorBoundary,
    },
  },
})

/*
  The default staleTime is set above.  We can override it on certain operations
  that we run often but change rarely.  With the proper invalidations below it should
  not cause any issues
*/
addGraphqlQueryStaleTimes({
  me: 60 * 1000,
  modules: 60 * 1000,
  getAccount: 60 * 1000,
  billingAccounts: 60 * 1000,
})

export const GRAPHQL_ENDPOINT = '/api/user/graphql'

/*
  Middleware used to set the auth tokens on each request
*/
export const authMiddleware = async (headers: Headers) => {
  const token = await getAuthToken()
  headers.set('authorization', `Bearer ${token}`)
  return headers
}
/*
  Configure useGraphql
*/
setGraphqlEndpoint(GRAPHQL_ENDPOINT)
setGraphqlFetchMiddleware(authMiddleware)

/*
  Set Mutation to Query invalidations

  This is done manually because its too inaccurate to fully understand the relationship
  between the data sets.

  The first argument is the mutation name, the second argument is one or more queries
  that should be invalidated.
 */
addGraphqlMutationInvalidations({
  addFunds: ['getFunds', 'billingAccounts', 'getBillingAccount', 'getBalanceRemaining'],
  addFundsV2: ['getFunds', 'billingAccounts', 'getBillingAccount', 'getBalanceRemaining'],
  addProductAccess: ['searchUsers', 'getUser', 'me', 'namedProductAccessList', 'searchNoAccessUsers'],
  cancelOrder: [
    'getPostalFulfillment',
    'searchPostalFulfillments',
    'searchContactPostalFulfillments',
    'searchEventPostalFulfillments',
    'getBudgetRemaining',
  ],
  changePassword: ['me'],
  completeDataImport: ['searchContacts', 'searchContactsV2'],
  completeThirdPartyTask: [],
  confirmTransferIntent: [
    'searchTransferIntents',
    'getTransferIntent',
    'billingAccounts',
    'getBillingAccount',
    'getBalanceRemaining',
    'getFunds',
  ],
  createApprovedPostal: ['searchApprovedPostals', 'marketplaceSearch'],
  createBillingAccount: ['billingAccounts', 'getAccount'],
  createBlocklistEntry: ['searchBlocklistEntrys', 'getBlocklistEntry'],
  createCampaign: ['searchCampaigns', 'checkAutomationsTiedToPostal'],
  createContactList: ['searchContactLists'],
  createEmailPreviewUrls: [],
  createFavoriteItem: ['searchFavoriteItems', 'getFavoriteItem'],
  createMagicLink: ['searchMagicLinks', 'getMagicLink', 'checkAutomationsTiedToPostal'],
  createMessageTemplate: ['messageTemplates', 'searchMessageTemplates'],
  createPlaybook: ['searchPlaybookInstances'],
  createPlaybookDefinition: ['searchPlaybookDefinitions'],
  createSampleMarketplaceData: [],
  createTag: ['searchTags'],
  createTeam: ['teams'],
  createTransferIntent: ['searchTransferIntents', 'getTransferIntent'],
  createUpdateIntegrationTrigger: [
    'searchIntegrationTriggers',
    'searchIntegrationTriggerResults',
    'getIntegrationTrigger',
  ],
  deleteApprovedPostalAndAllAutomations: ['getApprovedPostal', 'searchApprovedPostals', 'marketplaceSearch'],
  deleteBillingAccount: ['billingAccounts'],
  deleteBlocklistEntry: ['searchBlocklistEntrys', 'getBlocklistEntry'],
  deleteCampaign: ['searchCampaigns', 'checkAutomationsTiedToPostal', 'checkAutomationsTiedToPlaybook'],
  deleteContact: ['searchContacts', 'searchContactsV2'],
  deleteContactList: ['searchContactLists'],
  deleteFavoriteItem: ['searchFavoriteItems', 'getFavoriteItem'],
  deleteIntegration: ['searchIntegrationSync', 'me'],
  deleteIntegrationTrigger: [
    'searchIntegrationTriggers',
    'searchIntegrationTriggerResults',
    'checkAutomationsTiedToPostal',
    'checkAutomationsTiedToPlaybook',
  ],
  deleteInvite: ['invites'],
  deleteMagicLink: ['searchMagicLinks', 'checkAutomationsTiedToPostal', 'checkAutomationsTiedToPlaybook'],
  deleteMessageTemplate: ['messageTemplates', 'searchMessageTemplates'],
  deleteTag: ['searchTags'],
  deleteTeam: ['teams'],
  deleteTransferIntent: ['searchTransferIntents', 'getTransferIntent'],
  deleteUserAction: ['me'],
  deleteUserAuth: ['me'],
  generateOrderReport: ['searchReportOutputInstances'],
  invite: ['invites'],
  notifyPaymentMethodCreated: ['getPaymentMethods'],
  orderPostal: [
    'searchPostalFulfillments',
    'searchEventPostalFulfillments',
    'getPostalFulfillment',
    'searchContactPostalFulfillments',
    'getBudgetRemaining',
    'getBudgetRemainingSummary',
    'getFunds',
  ],
  recordUserAction: ['me'],
  removeProductAccess: ['searchUsers', 'getUser', 'me', 'namedProductAccessList', 'searchNoAccessUsers'],
  removeUser: ['searchUsers', 'me', 'getUser', 'searchNoAccessUsers'],
  removeUserTeam: ['searchUsers', 'getUser', 'me', 'searchNoAccessUsers'],
  resetOkta: ['getAccount'],
  retryBackgroundTask: ['searchBackgroundTasks', 'getBackgroundTaskById'],
  returnSignatureForCreatePaymentMethod: [],
  saveAsset: ['searchAssets', 'assets'],
  setDefaultPaymentMethod: ['getBillingAccount'],
  setupCustomIntegration: ['searchIntegrationSync', 'me'],
  setupDataSource: ['searchIntegrationSync', 'me'],
  setupOkta: ['getAccount'],
  syncBillingAccountPaymentPartner: ['getBillingAccount'],
  signup: ['getAccount', 'me'],
  startTrial: ['getAccount', 'me'],
  updateAccount: ['getAccount', 'getBudgetRemaining', 'me', 'getTeamById'],
  updateApprovedPostal: ['searchApprovedPostals', 'getApprovedPostal', 'marketplaceSearch'],
  updateAsset: ['searchAssets', 'assets'],
  updateBillingAccount: ['billingAccounts', 'getAccount', 'getBillingAccount'],
  updateBlocklistEntry: ['searchBlocklistEntrys', 'getBlocklistEntry'],
  updateCampaign: ['searchCampaigns', 'getCampaign'],
  updateCollection: ['getApprovedPostal', 'searchApprovedPostals', 'marketplaceSearch'],
  updateContact: ['getContact', 'searchContacts', 'searchContactsV2'],
  updateContactList: ['searchContactLists'],
  updateIntegrationSync: ['searchIntegrationSync'],
  updateIntegrationSyncStatus: ['searchIntegrationSync'],
  updateMagicLink: ['searchMagicLinks', 'getMagicLink'],
  updateMessageTemplate: ['messageTemplates', 'searchMessageTemplates'],
  updateRolePermissions: ['getRolePermissions'],
  updatePlaybook: ['getPlaybookInstance', 'searchPlaybookInstances'],
  updatePlaybookDefinition: [
    'searchPlaybookDefinitions',
    'getPlaybookDefinition',
    'checkAutomationsTiedToPostal',
    'checkAutomationsTiedToPlaybook',
  ],
  updateRoles: ['searchUsers', 'getUser', 'me', 'searchNoAccessUsers'],
  updateRolesV2: ['searchUsers', 'getUser', 'me', 'searchNoAccessUsers'],
  updateSelf: ['me'],
  updateTeam: ['teams', 'getTeamById', 'getBudgetRemaining'],
  updateUser: ['searchUsers', 'getUser', 'me', 'searchNoAccessUsers'],
  updateUserNotifications: ['me'],
  updateUserTeam: ['searchUsers', 'getUser', 'searchNoAccessUsers'],
  upsertContact: ['getContact', 'searchContacts', 'searchContactsV2'],
  adjustUserBudget: ['getBudgetRemaining', 'searchUsers'],
  startRecipe: ['getRecipes', 'getRecipe'],
  stopRecipe: ['getRecipes', 'getRecipe'],
})

/*
  Set Background Query Mutation to Query invalidations
 */
addGraphqlBackgroundTaskInvalidations({
  addFunds: [
    'getFunds',
    'previewOrder',
    'getBillingAccount',
    'getBalanceRemaining',
    'billingAccounts',
    'getBalanceRemaining',
  ],
  addFundsV2: [
    'getFunds',
    'previewOrder',
    'getBillingAccount',
    'getBalanceRemaining',
    'billingAccounts',
    'getBalanceRemaining',
  ],
  bulkCreateApprovedPostal: ['searchApprovedPostals', 'marketplaceSearch'],
  bulkEditApprovedPostal: ['searchApprovedPostals', 'getApprovedPostal', 'marketplaceSearch'],
  bulkContactAddToCampaign: ['searchContacts', 'searchContactsV2', 'getContact', 'getCampaign'],
  bulkContactAddToList: ['searchContacts', 'searchContactsV2', 'getContact'],
  bulkContactAddToPlaybook: [
    'getContact',
    'searchContacts',
    'searchContactsV2',
    'getPlaybookInstance',
    'searchPlaybookInstances',
  ],
  bulkContactDelete: ['searchContacts', 'searchContactsV2'],
  bulkContactRemoveFromCampaign: ['searchContacts', 'searchContactsV2', 'getCampaign', 'getContact'],
  bulkContactRemoveFromList: ['searchContacts', 'searchContactsV2', 'getContact'],
  bulkContactUpdatePlaybookStatus: [
    'getPlaybookInstance',
    'searchPlaybookInstances',
    'getContact',
    'searchContacts',
    'searchContactsV2',
  ],
  bulkPlaybookStatusUpdate: [
    'getPlaybookInstance',
    'searchPlaybookInstances',
    'getContact',
    'searchContacts',
    'searchContactsV2',
  ],
  bulkProductAccessAdd: ['searchUsers', 'getUser', 'me', 'searchNoAccessUsers'],
  bulkProductAccessRemove: ['searchUsers', 'getUser', 'me', 'searchNoAccessUsers'],
  bulkProductAccessUpdate: ['searchUsers', 'getUser', 'me', 'searchNoAccessUsers'],
  bulkTagAdd: ['searchTags', 'searchContacts', 'searchContactsV2', 'getContact'],
  bulkTagRemove: ['searchTags', 'searchContacts', 'searchContactsV2', 'getContact'],
  cancelBackgroundTask: ['searchBackgroundTasks', 'getBackgroundTaskById'],
  deleteApprovedPostalAndAllAutomations: ['getApprovedPostal', 'searchApprovedPostals', 'marketplaceSearch'],
  retryBackgroundTask: ['searchBackgroundTasks', 'getBackgroundTaskById'],
  completeDataImport: ['searchContacts', 'searchContactsV2'],
  contactImport: ['searchContacts', 'searchContactsV2'],
  generateDesignPreviews: ['searchApprovedPostals', 'getApprovedPostal', 'marketplaceSearch'],
  generateOrderReport: ['searchReportOutputInstances'],
  bulkApproveOrders: ['searchPostalFulfillments', 'getPostalFulfillment', 'searchMagicLinks'],
  bulkDenyOrders: ['searchPostalFulfillments', 'getPostalFulfillment', 'searchMagicLinks'],
})
