import type { FlexProps } from '@chakra-ui/react'
import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlMutation } from '@postal-io/postal-graphql'
import type { GraphqlFilterState, UiSelectButtonProps } from '@postal-io/postal-ui'
import {
  GraphqlFilterTransform,
  internalProgressBarProps,
  internalTableProps,
  OrderByDirection,
  UiSelectButton,
  UiSSDataTable,
  useAlertError,
  useAlerts,
  useGraphqlFilter,
  ZButton,
  ZCard,
  ZCardBody,
  ZCheckbox,
  ZInput,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZSelect,
  ZText,
} from '@postal-io/postal-ui'
import type { Invite, InvitesQueryVariables } from 'api'
import { DeleteInviteDocument, InvitesDocument, InviteStatus } from 'api'
import { useAcl } from 'hooks'
import { set } from 'lodash'
import type { ChangeEvent, ReactElement } from 'react'
import React, { useMemo, useState } from 'react'
import { MdDeleteOutline } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { inviteColumnsV2 } from './UsersData'

const transforms = {
  emailAddress: GraphqlFilterTransform.Contains,
  status: GraphqlFilterTransform.Equal,
}
const initialState = {
  orderBy: { key: 'created', direction: OrderByDirection.Descending },
} as GraphqlFilterState
const debounce = 400

export const UsersInviteTable: React.FC<FlexProps> = (props) => {
  const Alert = useAlerts()
  const [selected, setSelected] = useState<any[]>([])
  const { hasPermission } = useAcl()
  const canCreate = hasPermission('users.create')
  const teamId = useParams()?.teamId

  const staticVariables = useMemo(() => {
    const vars: InvitesQueryVariables = { limit: 100 }
    // if teamId is default, we want to find only users invited to the main account
    if (teamId) set(vars, 'filter.teamId.eq', teamId === 'default' ? null : teamId)
    return vars
  }, [teamId])

  const graphqlFilter = useGraphqlFilter({ staticVariables, transforms, debounce, initialState })

  const invitesQuery = useGraphqlInfiniteQuery(InvitesDocument, graphqlFilter.variables)

  const invites = useMemo(() => {
    return (invitesQuery.mergedData?.invites || []) as Invite[]
  }, [invitesQuery.mergedData?.invites])

  const deleteInvitation = useGraphqlMutation(DeleteInviteDocument)

  const buttons: {
    type: string
    icon: ReactElement
    label: string
    placement: UiSelectButtonProps['placement']
  }[] = [
    {
      type: 'REMOVE',
      icon: <MdDeleteOutline />,
      label: 'Remove Invitation',
      placement: 'top',
    },
  ]

  const removeInvite = useDisclosure()

  const onSelectButton = () => {
    canCreate && selected.length > 0 && removeInvite.onOpen()
  }

  const handleRemove = async () => {
    try {
      const res = await Promise.allSettled(selected.map(({ id }) => deleteInvitation.mutateAsync({ id })))
      const hasErrors = res.some((r) => r.status === 'rejected')
      if (hasErrors) {
        Alert.error('There was a problem removing some invitations.  Please try again.')
      } else {
        Alert.success('Invitations removed')
      }
      setSelected([])
      removeInvite.onClose()
    } catch (e) {
      Alert.error('There was a problem removing some invitations.  Please try again.')
      removeInvite.onClose()
    }
  }

  useAlertError(invitesQuery.error)

  return (
    <>
      <Flex
        justifyContent="space-between"
        h="40px"
        {...props}
      >
        <Box
          mr={2}
          h="40px"
          display="flex"
          alignItems="center"
        >
          {buttons?.map(({ type, placement, ...button }) => (
            <UiSelectButton
              h="40px"
              w="40px"
              fontSize="26px"
              border="none"
              aria-label={`UserTable ${type} button`}
              key={type}
              placement={placement}
              isDisabled={selected?.length < 1}
              onClick={() => onSelectButton()}
              colorScheme="atomicGray"
              color="atomicGray.500"
              {...button}
            />
          ))}
        </Box>
        <Box w="100%">
          <ZInput
            w="100%"
            value={graphqlFilter.filter.search}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              graphqlFilter.setFilter({ key: 'emailAddress', value: e.target.value })
            }
            placeholder="Search for Invitations"
          />
        </Box>
        <ZSelect
          maxW="300px"
          ml={4}
          placeholder="Select a Status"
          value={graphqlFilter.filter.status}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            graphqlFilter.setFilter({ key: 'status', value: e.target.value })
          }
        >
          <option value={InviteStatus.Pending}>Pending</option>
          <option value={InviteStatus.Complete}>Complete</option>
        </ZSelect>
      </Flex>
      <ZCard
        variant="form"
        mt={4}
      >
        <ZCardBody p={8}>
          <UiSSDataTable
            columns={inviteColumnsV2}
            rows={invites}
            showSelect={true}
            variant="list"
            onSelect={setSelected}
            isLoading={invitesQuery.isFetching}
            fetchMore={invitesQuery.fetchNextPage}
            hasMore={invitesQuery.hasNextPage}
            filter={graphqlFilter.variables.filter}
            orderBy={graphqlFilter.orderBy}
            onOrderBy={graphqlFilter.setOrderBy}
            rowKey="id"
            SelectCheckbox={ZCheckbox}
            HeaderButton={ZButton}
            tableProps={internalTableProps}
            progressBarProps={internalProgressBarProps}
          />
        </ZCardBody>
      </ZCard>
      {canCreate && removeInvite.isOpen && (
        <ZModal
          size="lg"
          isOpen={removeInvite.isOpen}
          onClose={removeInvite.onClose}
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Remove Invitations</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <ZText textAlign="center">
                Are you sure you want to remove
                {selected.length === 1 ? ' this invitation' : ` these ${selected.length} invitations`}?
              </ZText>
            </ZModalBody>
            <ZModalButtons
              onConfirm={handleRemove}
              confirmProps={{ colorScheme: 'atomicRed' }}
              confirmText="Remove"
              onClose={removeInvite.onClose}
            />
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}
