import type { FlexProps } from '@chakra-ui/react'
import { Box, Flex, Stack } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiSkeleton,
  useAlerts,
  ZButton,
  ZInput,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import { InviteDocument, Role } from 'api'
import type { MiniTeam } from 'hooks'
import { AnalyticsEvent, useAcl, useAnalyticsSend } from 'hooks'
import type { FormEvent } from 'react'
import React, { useMemo } from 'react'
import { useImmer } from 'use-immer'
import { AutoCompleteTeam, MenuUserRole } from '../AutoComplete'
import { UserSeatsAlert } from './UserSeatsAlert'

interface UserInviteProps extends FlexProps {
  isOpen: boolean
  onClose: () => void
  onCreate?: () => void
  teamId?: string
}

interface State {
  searchTeam: string
  emailAddress: string
  roles: Role[]
  team?: MiniTeam
}
export const UserInvite: React.FC<UserInviteProps> = ({ teamId, isOpen, onClose, onCreate, ...rest }) => {
  const Alert = useAlerts()

  const [state, setState] = useImmer<State>({
    searchTeam: '',
    emailAddress: '',
    roles: [Role.User],
  })

  const { hasPermission } = useAcl()
  const canCreate = hasPermission('users.create')

  const sendAnalytics = useAnalyticsSend()

  const invite = useGraphqlMutation(InviteDocument, {
    onSuccess: () => sendAnalytics({ event: AnalyticsEvent.UserInvited }),
  })

  const selectedTeamId = teamId || state?.team?.teamId
  const hasTeam = !!teamId || !!state.team

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (!state.emailAddress || !hasTeam || !state.roles.length) {
      return Alert.warning('An email address, team, and role are required')
    }

    // make sure we have the correct roles
    const roles = selectedTeamId
      ? state.roles.filter((v: any) => v !== Role.Admin)
      : state.roles.filter((v: any) => v !== Role.TeamAdmin)

    // if default is passed in we are going to invite to the account
    const inviteTeamId = selectedTeamId === 'default' ? undefined : selectedTeamId

    const data = {
      teamId: inviteTeamId,
      roles,
      emailAddresses: [state.emailAddress],
    }
    try {
      const res = await invite.mutateAsync({ data })
      const err = res.invite?.find((result) => !!result?.errorMessage)
      if (err) {
        Alert.warning(err.errorMessage || 'There was an error on this invitation.')
      } else {
        Alert.success(`Invitation sent to ${state.emailAddress}`)
      }
      setState((draft) => {
        draft.emailAddress = ''
      })
      onCreate && onCreate()
    } catch (err) {
      Alert.error(err)
    }
  }

  const isDisabled = useMemo(() => {
    return !state.emailAddress || !hasTeam || !state.roles.length || invite.isLoading
  }, [hasTeam, invite.isLoading, state.emailAddress, state.roles.length])

  if (!canCreate) return null

  return (
    <ZModal
      size="md"
      onClose={onClose}
      isOpen={isOpen}
    >
      <ZModalOverlay />
      <ZModalContent>
        <ZModalHeader>Invite User</ZModalHeader>
        <ZModalCloseButton />
        <ZModalBody pb={8}>
          <UiSkeleton
            isLoaded={true}
            {...rest}
          >
            <UserSeatsAlert mb={8} />

            <ZText
              mb={8}
              textAlign="center"
              fontSize="md"
            >
              Please select the{' '}
              {!teamId && (
                <>
                  <strong>team</strong> and{' '}
                </>
              )}
              <strong>roles</strong> you would like granted to each user. The user will be sent an invitation to join
              the team.
            </ZText>

            <form onSubmit={handleSubmit}>
              <Stack
                spacing={8}
                mb={16}
              >
                <Box mt={4}>
                  <ZInput
                    value={state.emailAddress}
                    placeholder="Email Address"
                    isRequired
                    type="email"
                    onChange={(e: any) => setState((draft) => void (draft.emailAddress = e.target.value))}
                    minW="250px"
                  />
                </Box>

                {!teamId && (
                  <AutoCompleteTeam
                    rootProps={{ ml: 4, mt: 4, minW: '250px' }}
                    inputValue={state.searchTeam}
                    onInputChange={(value: string) => setState((draft) => void (draft.searchTeam = value))}
                    value={state.team || null}
                    onChange={(team) => setState((draft) => void (draft.team = team || undefined))}
                  />
                )}

                <MenuUserRole
                  as={ZButton}
                  fontSize="sm"
                  w="full"
                  pl={4}
                  textAlign="left"
                  fontWeight="normal"
                  h="40px"
                  rootProps={{ ml: 4, mt: 4, height: '40px' }}
                  value={state.roles}
                  onChange={(newRoles) => setState((draft) => void (draft.roles = newRoles))}
                  teamId={selectedTeamId}
                />
              </Stack>
              <Flex justifyContent="space-between">
                <ZButton
                  type="submit"
                  isDisabled={isDisabled}
                  colorScheme="atomicBlue"
                  minW="140px"
                >
                  Invite to Team
                </ZButton>
                <ZButton
                  colorScheme="atomicGray"
                  variant="ghost"
                  onClick={onClose}
                  minW="140px"
                >
                  Cancel
                </ZButton>
              </Flex>
            </form>
          </UiSkeleton>
        </ZModalBody>
      </ZModalContent>
    </ZModal>
  )
}
