import { List, ListItem } from '@chakra-ui/react'
import { ZActionLink } from '@postal-io/postal-ui'
import type { SidebarDataLink } from 'components/Common'
import { useAcl, useSession } from 'hooks'
import { useMemo } from 'react'
import type { IconType } from 'react-icons'
import {
  MdOutlineApartment,
  MdOutlineCancelPresentation,
  MdOutlineColorLens,
  MdOutlineContactMail,
  MdOutlineFlare,
  MdOutlineGroup,
  MdOutlineGroups,
  MdOutlineLock,
  MdOutlineMarkEmailRead,
  MdOutlineMarkunreadMailbox,
  MdOutlineMeetingRoom,
  MdOutlineNotifications,
  MdOutlinePerson,
  MdOutlineReceiptLong,
  MdOutlineSavings,
} from 'react-icons/md'
import { Link, NavLink, useLocation } from 'react-router-dom'

export interface SidebarDataLinkV2 extends SidebarDataLink {
  icon?: IconType
}

export const ProfileSidebarBlocks = () => {
  const { pathname } = useLocation()
  const { session } = useSession()
  const { hasFeature } = useAcl()

  const links: SidebarDataLinkV2[] = useMemo(() => {
    return [
      {
        title: 'You',
        to: '/profile',
        exact: true,
        icon: MdOutlinePerson,
      },
      {
        title: 'Users',
        to: '/users/active',
        module: 'teams.create',
        icon: MdOutlineGroups,
      },
      {
        title: 'Teams',
        to: '/teams',
        module: 'teams.read',
        enabled: !session.teamId,
        icon: MdOutlineGroup,
      },
      {
        title: 'Team',
        to: `/teams/${session.teamId}`,
        module: 'teams.read',
        enabled: !!session.teamId,
        icon: MdOutlineGroup,
      },
      {
        title: 'Branding',
        to: '/account/branding',
        module: 'account.update',
        icon: MdOutlineColorLens,
      },
      {
        title: 'Integrations',
        to: '/integrations',
        module: 'integrations.read',
        icon: MdOutlineFlare,
      },
      {
        title: 'Billing',
        to: '/billing/accounts',
        extraPaths: ['/billing'],
        module: 'billing.update',
        icon: MdOutlineReceiptLong,
        children: [
          {
            title: 'Accounts',
            to: '/billing/accounts',
          },
          {
            title: 'Team Budgets',
            to: '/billing/funds',
          },
          {
            title: 'Transfers',
            to: '/billing/transfers',
            feature: 'transferFunds',
          },
        ],
      },
      {
        title: 'Budget History',
        to: '/budget/history',
        feature: 'budgetHistory',
        icon: MdOutlineSavings,
      },
      {
        title: 'Account Settings',
        to: '/account/info',
        module: 'account.update',
        icon: MdOutlineContactMail,
      },
      // Notifications V2
      {
        title: 'Notification Settings',
        to: '/notifications/general',
        icon: MdOutlineNotifications,
        feature: 'slackIntegration',
      },
      {
        title: 'Saved Messages',
        to: '/saved-messages',
        module: 'messages.create',
        icon: MdOutlineMarkEmailRead,
      },
      // Notifications V1
      !hasFeature('slackIntegration')
        ? {
            title: 'Email Settings',
            to: '/account/notifications',
            icon: MdOutlineMarkunreadMailbox,
            extraPaths: ['/account/gift-emails', '/account/email-templates'],
            children: [
              {
                title: 'Notifications',
                to: '/account/notifications',
              },
              {
                title: 'Personalized Emails',
                to: '/account/personalized-emails',
                module: 'account.update',
              },
              {
                title: 'Email Templates',
                to: '/account/email-templates',
                module: 'account.update',
              },
            ],
          }
        : // Notifications V2
          {
            title: 'Email Settings',
            to: '/account/email-settings',
            icon: MdOutlineMarkunreadMailbox,
            extraPaths: ['/account/gift-emails', '/account/email-templates'],
            children: [
              {
                title: 'General',
                to: '/account/email-settings',
              },
              {
                title: 'Personalized Emails',
                to: '/account/personalized-emails',
                module: 'account.update',
              },
              {
                title: 'Email Templates',
                to: '/account/email-templates',
                module: 'account.update',
              },
            ],
          },
      {
        title: 'Email Blocklist',
        to: '/account/blocklist',
        module: 'account.update',
        feature: 'orderBlockedEmail',
        icon: MdOutlineCancelPresentation,
      },
      {
        title: 'Meeting Settings',
        to: '/profile/meetings',
        feature: 'meetingSettings',
        icon: MdOutlineMeetingRoom,
      },
      {
        title: 'Security',
        to: '/account/security',
        module: 'security.read',
        icon: MdOutlineLock,
      },
      {
        title: 'Warehousing',
        to: '/account/warehousing',
        module: 'account.update',
        feature: 'warehouse',
        icon: MdOutlineApartment,
      },
    ]
  }, [hasFeature, session.teamId])

  const { aclFilter } = useAcl()
  const filteredLinks = useMemo(() => {
    return aclFilter(links).map((link) => ({ ...link, children: aclFilter(link.children || []) }))
  }, [aclFilter, links])

  const isActivePath = (link: Omit<SidebarDataLinkV2, 'icon'>, exact: boolean = false) => {
    const urls = [link.to, ...(link.children ? link.children.map((ch: Omit<SidebarDataLinkV2, 'icon'>) => ch.to) : [])]
    return !!urls.find((pl) => (exact ? pathname === pl : pathname.includes(pl)))
  }
  return (
    <List spacing={4}>
      {filteredLinks.map((pl: SidebarDataLinkV2, idx) => (
        <ListItem key={`${pl.to}-${idx}`}>
          <ZActionLink
            as={NavLink}
            {...(pl?.icon && { leftIcon: <pl.icon /> })}
            isActive={isActivePath(pl, pl.exact)}
            to={pl.to}
            pb={isActivePath(pl) && pl.children?.length ? 2 : 0}
            end={pl.exact}
          >
            {pl.title}
          </ZActionLink>
          {isActivePath(pl) &&
            pl.children &&
            pl.children.map((ch, i) => (
              <ZActionLink
                key={`${ch.to}-${idx}-${i}`}
                as={Link}
                isActive={isActivePath(ch)}
                to={ch.to}
                ml={10}
                py={2}
              >
                {ch.title}
              </ZActionLink>
            ))}
        </ListItem>
      ))}
    </List>
  )
}
