import type { ContainerProps, SkeletonProps } from '@chakra-ui/react'
import { Container, forwardRef } from '@chakra-ui/react'
import { UiSkeleton } from '@postal-io/postal-ui'

export const CenteredBox = forwardRef<ContainerProps & Pick<SkeletonProps, 'isLoaded'>, 'div'>(
  ({ children, isLoaded, ...rest }, ref) => {
    return (
      <UiSkeleton
        ref={ref}
        isLoaded={isLoaded}
      >
        <Container
          maxW="calc(1440px + 4rem)"
          px={8}
          pt={0}
          bg="white"
          minH="400px"
          {...rest}
        >
          {children}
        </Container>
      </UiSkeleton>
    )
  }
)
