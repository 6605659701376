import React from 'react'
import { Navigate } from 'react-router'
import { useLocation } from 'react-router-dom'

export const V2PathRedirect: React.FC = () => {
  const { pathname } = useLocation()
  const redirectPath = pathname.replace(/^\/v2/, '')

  return <Navigate to={redirectPath} />
}
