import { NotAllowedIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Grid,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Slide,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import type { Column, GraphqlFilterState, UiDialogProps } from '@postal-io/postal-ui'
import {
  GraphqlFilterTransform,
  UiCheckboxIcon,
  UiLink,
  UiTooltip,
  useAlertError,
  useColor,
  useGraphqlFilter,
  ZCard,
  ZCardBody,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, MagicLink, SearchMagicLinksQueryVariables } from 'api'
import { MagicLinkStatus, Role, SearchMagicLinksDocument, SendFlowStep } from 'api'
import { ZCardStripe, ZCardStripeHeading, ZDialog, ZLink, ZSSDataTable } from 'components/Common/ZComponents'
import { BasicPostalImageCarousel } from 'components/Postal'
import { PostalFulfillmentsTable } from 'components/PostalFulfillments'
import { PostalSendMethod, SEND_METHOD_COLORS } from 'components/PostalSend/postalSendHelpers'
import { AnalyticsEvent, useAcl, useAnalyticsEvent, useCopyMagicLink, useSession } from 'hooks'
import { QRCodeCanvas } from 'qrcode.react'
import React, { useCallback, useMemo, useState } from 'react'
import { MdLink, MdOutlineAddToPhotos, MdOutlineEdit, MdOutlineRemoveRedEye, MdQrCodeScanner } from 'react-icons/md'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { LinkClone } from './LinkClone'
import { LinkDetails } from './LinkDetails'
import { LinkPreview } from './LinkPreview'

const LIMIT = 100

const transforms = {
  name: GraphqlFilterTransform.Contains,
  status: GraphqlFilterTransform.Equal,
  userId: GraphqlFilterTransform.Equal,
  approvedPostalId: GraphqlFilterTransform.Equal,
}

const INITIAL_STATE = { orderBy: { key: 'created', direction: 'desc' } } as GraphqlFilterState

interface LinksDisplayDialogProps extends Omit<UiDialogProps, 'title' | 'children'> {
  title?: string
  postal: ApprovedPostal
}

export const LinksDisplayDialog: React.FC<LinksDisplayDialogProps> = ({ postal, ...rest }) => {
  const { hasPermission, hasRole } = useAcl()
  const canCreate = hasPermission('links.create')
  const cloneLink = useDisclosure()
  const navigate = useNavigate()
  const { ownerId } = useParams() as any
  const [selectedLink, setSelectedLink] = useState<MagicLink | undefined>(undefined)
  const [linkToClone, setLinkToClone] = useState<MagicLink | undefined>(undefined)
  const copyLink = useCopyMagicLink()
  const { session } = useSession()
  const { state } = useLocation() as any
  const { Color } = useColor()
  const linkColor = SEND_METHOD_COLORS[PostalSendMethod.Link]

  useAnalyticsEvent({ event: AnalyticsEvent.MagicLinkPageViewed })

  const isManager = hasRole(Role.Manager)

  const onClone = (link?: MagicLink) => {
    cloneLink.onClose()
    navigate(`/links/${link?.id}`)
  }

  const handleLinkClone = useCallback(
    (link: MagicLink) => {
      setLinkToClone(link)
      cloneLink.onOpen()
    },
    [setLinkToClone, cloneLink]
  )

  const columns = useMemo(() => {
    const cols: Column[] = [
      {
        key: 'name',
        label: 'MagicLink Name',
        render: (link: MagicLink) => {
          return (
            <UiTooltip
              placement="top"
              hasArrow
              label="View MagicLink"
            >
              <UiLink
                color={linkColor}
                onClick={() => setSelectedLink(link)}
                // to={{ pathname: `/links/${id}`, state: { returnTo: 'MagicLinks' } }}
              >
                {link.name}
              </UiLink>
            </UiTooltip>
          )
        },
      },
      {
        key: 'owner',
        label: 'Owner',
        render: ({ userLink }: MagicLink) => userLink?.fullName,
      },
      {
        label: 'Orders',
        render: ({ maxExecutions, metrics }: MagicLink) => {
          return maxExecutions ? `${metrics.linkExecutions}/${maxExecutions}` : metrics.sent
        },
      },
      // {
      //   key: 'expirationDate',
      //   label: 'Expires On',
      //   render: ({ expirationDate }: any) => (
      //     <UiDate
      //       date={expirationDate}
      //       fallback="Never"
      //     />
      //   ),
      // },
      //   },
      //   key: 'created',
      //   label: 'Created On',
      //   orderBy: 'created',
      //   render: ({ created }: any) => (
      //     <UiDate
      //       date={created.dateTime}
      //       fallback="N/A"
      //     />
      //   ),
      // },
      {
        key: 'status',
        label: 'Status',
        render: ({ status }: MagicLink) => {
          return (
            <>
              {status === MagicLinkStatus.Active ? (
                <UiCheckboxIcon
                  mr={2}
                  mt="-3px"
                  fontSize="16px"
                  isChecked
                  color={linkColor}
                />
              ) : (
                <NotAllowedIcon
                  mr={2}
                  mt="-3px"
                  fontSize="16px"
                  color="atomicGray.500"
                />
              )}
              <Box as="span">{status === MagicLinkStatus.Active ? 'Enabled' : 'Disabled'}</Box>
            </>
          )
        },
      },
      {
        key: 'actions',
        label: 'Actions',
        render: (link: MagicLink) => {
          return (
            <Flex>
              <UiTooltip
                placement="top"
                hasArrow
                label="Share URL"
              >
                <UiLink
                  title={link.linkUrl}
                  onClick={() => copyLink(link.linkUrl)}
                  mr={2}
                >
                  <MdLink
                    color={Color('atomicGray.500')}
                    size="18px"
                  />
                </UiLink>
              </UiTooltip>
              {canCreate && (
                <UiTooltip
                  placement="top"
                  hasArrow
                  label="Clone Link"
                >
                  <UiLink onClick={() => handleLinkClone(link)}>
                    <MdOutlineAddToPhotos
                      color={Color('atomicGray.500')}
                      size="18px"
                    />
                  </UiLink>
                </UiTooltip>
              )}
            </Flex>
          )
        },
      },
    ] as any[]
    if (isManager && ownerId !== 'me') {
      cols.splice(1, 0, { key: 'userLink.fullName', label: 'Owner' })
    }
    // if (canApproveLinks) {
    //   cols.splice(6, 0, {
    //     key: 'userLink.requiresApproval',
    //     label: 'Requires Approval',
    //     render: ({ requiresApproval, metrics }: any) => {
    //       const label =
    //         requiresApproval && metrics.needsAction === 0
    //           ? 'On'
    //           : requiresApproval && metrics.needsAction > 0
    //           ? 'Approval Needed'
    //           : 'Off'
    //       return (
    //         label && (
    //           <UiStatusTag
    //             colorScheme="orange.500"
    //             minW="150px"
    //             label={label}
    //           />
    //         )
    //       )
    //     },
    //   })
    // }
    return cols
  }, [Color, canCreate, copyLink, handleLinkClone, isManager, linkColor, ownerId])

  // const actions: { title: string; onClick: () => void; isHidden: boolean }[] = [
  //   {
  //     title: 'Create MagicLink',
  //     onClick: () => createLink.onOpen(),
  //     isHidden: !canCreate,
  //   },
  // ]

  // force ownerId depending on path
  const staticVariables = useMemo(() => {
    const filter = {} as any
    if (ownerId) filter.userId = { eq: ownerId === 'me' ? session.userId : ownerId }
    return { filter, limit: LIMIT }
  }, [ownerId, session.userId])

  const initialState = useMemo(() => ({ ...INITIAL_STATE, filter: { approvedPostalId: postal.id } }), [postal.id])

  const { variables, orderBy, setOrderBy } = useGraphqlFilter<SearchMagicLinksQueryVariables>({
    transforms,
    initialState,
    staticVariables,
    debounce: 400,
  })

  const { isFetching, error, mergedData, hasNextPage, fetchNextPage } = useGraphqlInfiniteQuery(
    SearchMagicLinksDocument,
    variables
  )

  useAlertError(error)

  const links = useMemo(() => mergedData?.searchMagicLinks || [], [mergedData?.searchMagicLinks])

  // const { data: ordersData } = useGraphqlQuery(
  //   SearchPostalFulfillmentsDocument,
  //   { filter: { magicLinkId: { in: links.map((l) => l.id) } } },
  //   { enabled: links.length > 0 }
  // )

  // const linksWithApprovalMetrics = useMemo(() => {
  //   const metricsGrouped =
  //     ordersData?.searchPostalFulfillments?.reduce((prev, cur) => {
  //       const id = cur.magicLinkId as string
  //       prev[id] = prev[id] || { needsAction: 0, approved: 0, denied: 0 }
  //       if (cur.status === FulfillmentStatus.PendingUserApproval) prev[id].needsAction += 1
  //       if (
  //         cur.status === FulfillmentStatus.OrderApproved ||
  //         cur.history?.some((h) => h.status === FulfillmentStatus.OrderApproved)
  //       )
  //         prev[id].approved += 1
  //       if (
  //         cur.status === FulfillmentStatus.OrderDenied ||
  //         cur.history?.some((h) => h.status === FulfillmentStatus.OrderDenied)
  //       )
  //         prev[id].denied += 1

  //       return prev
  //     }, {} as any) || {}

  //   return links.map((l) => ({
  //     ...l,
  //     metrics: {
  //       ...l.metrics,
  //       ...(metricsGrouped[l.id] ?? { needsAction: 0, approved: 0, denied: 0 }),
  //     },
  //   }))
  // }, [ordersData?.searchPostalFulfillments, links])

  // const totalNeedsAction = useMemo(
  //   () => Object.values(linksWithApprovalMetrics).reduce((prev, cur) => cur.metrics.needsAction + prev, 0) || 0,
  //   [linksWithApprovalMetrics]
  // )

  // const needsActionMessage = `You have ${totalNeedsAction} order${totalNeedsAction > 1 ? 's' : ''} that require${
  //   totalNeedsAction <= 1 ? 's' : ''
  // } approval.`

  return (
    <ZDialog
      title={selectedLink?.name ?? `Existing MagicLinks ${hasNextPage ? '(many)' : `(${links.length})`}`}
      showBackButton={!!selectedLink}
      onBack={() => setSelectedLink(undefined)}
      {...rest}
    >
      <>
        {/* <LinksFilter
            setFilter={setFilter}
            filter={filter}
            canFilterOwner={isManager && !ownerId}
            onCreate={canCreate ? createLink.onOpen : undefined}
          /> */}
        <ZCard
          variant="form"
          boxShadow="none"
          px="30px !important"
          pb="25px !important"
          pt="20px !important"
          position="relative"
        >
          <ZCardStripe color={linkColor}>
            <ZCardStripeHeading>
              <MdLink style={{ marginRight: '5px', marginTop: '1px' }} /> MagicLink
            </ZCardStripeHeading>
          </ZCardStripe>
          <ZCardBody p={0}>
            <Grid
              templateColumns="1fr 4fr"
              gridGap="10px"
            >
              <Box>
                <BasicPostalImageCarousel
                  postal={postal}
                  imageProps={{ objectFit: 'cover', ratio: 1 }}
                  containerProps={{ width: '100%', borderRadius: 5 }}
                />
              </Box>
              <Flex
                direction="column"
                justifyContent="center"
              >
                <Text fontSize="lg">{postal?.displayName}</Text>
                {state && state.step !== SendFlowStep.ContactSelection && (
                  <Text
                    fontSize="sm"
                    color="gray.600"
                  >
                    {postal?.displayName}
                  </Text>
                )}
              </Flex>
            </Grid>
          </ZCardBody>
        </ZCard>
        <Slide
          direction="left"
          in={!selectedLink}
          style={{ position: 'relative' }}
          unmountOnExit
        >
          <ZSSDataTable
            mt={5}
            rowKey="id"
            columns={columns}
            orderBy={orderBy}
            onOrderBy={setOrderBy}
            fetchMore={fetchNextPage}
            hasMore={hasNextPage}
            filter={variables.filter}
            rows={links}
            isLoading={isFetching}
          />
        </Slide>
        <Slide
          direction="right"
          in={!!selectedLink}
          style={{ position: 'relative' }}
          unmountOnExit
        >
          <Grid
            mt={5}
            gap={8}
            templateColumns={{ base: '4fr 1fr', lg: 'calc(75% - 1rem) calc(25% - 1rem)' }}
          >
            <Stack spacing={8}>
              {/* <LinkStats link={selectedLink} /> */}
              <LinkDetails
                // py="20px !important"
                sx={{
                  '& dt': { fontSize: 'sm', fontWeight: 300, color: 'atomicGray.500' },
                  '& dd': { fontSize: 'sm', fontWeight: 300, color: 'atomicGray.900' },
                }}
                isInModal
                boxShadow="none"
                link={selectedLink}
              />
              {/* <ItemOrCollectionBlock
                boxShadow="none"
                approvedPostalId={selectedLink?.approvedPostalId}
                variantId={selectedLink?.variantId}
              /> */}
              <PostalFulfillmentsTable
                mt={5}
                py="20px !important"
                sx={{
                  '& th, td': { pl: 0, fontSize: 'sm', fontWeight: 300 },
                }}
                boxShadow="none"
                magicLinkId={selectedLink?.id}
              />
            </Stack>
            <Box height="100%">
              <Stack
                spacing={6}
                position="sticky"
                top={0}
                pt={2}
              >
                <ZLink onClick={() => copyLink(selectedLink?.linkUrl ?? '')}>
                  <MdLink
                    size="18px"
                    style={{ marginRight: 10, color: Color('atomicGray.500') }}
                  />{' '}
                  Share URL
                </ZLink>
                <ZLink onClick={() => selectedLink && handleLinkClone(selectedLink)}>
                  <MdOutlineAddToPhotos
                    size="18px"
                    style={{ marginRight: 10, color: Color('atomicGray.500') }}
                  />{' '}
                  Clone MagicLink
                </ZLink>
                <Popover placement="left">
                  <PopoverTrigger>
                    <ZLink>
                      <MdQrCodeScanner
                        size="18px"
                        style={{ marginRight: 10, color: Color('atomicGray.500') }}
                      />{' '}
                      View QR Code
                    </ZLink>
                  </PopoverTrigger>
                  <PopoverContent width="unset">
                    <PopoverArrow />
                    <PopoverCloseButton data-testid="ContactsImport_popover_close" />
                    <PopoverBody p={10}>
                      <QRCodeCanvas
                        value={selectedLink?.linkUrl || ''}
                        size={150}
                        imageSettings={{
                          src: '/android-chrome-256x256.png',
                          height: 24,
                          width: 24,
                          excavate: false,
                        }}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
                <LinkPreview
                  display="inline-block"
                  _hover={{ textDecoration: 'none' }}
                  link={selectedLink as MagicLink}
                >
                  <ZLink>
                    <MdOutlineRemoveRedEye
                      size="18px"
                      style={{ marginRight: 10, color: Color('atomicGray.500') }}
                    />{' '}
                    Preview Landing Page
                  </ZLink>
                </LinkPreview>
                <ZLink onClick={() => selectedLink && navigate(`/links/${selectedLink.id}/edit`)}>
                  <MdOutlineEdit
                    size="18px"
                    style={{ marginRight: 10, color: Color('atomicGray.500') }}
                  />{' '}
                  Edit MagicLink
                </ZLink>
              </Stack>
            </Box>
          </Grid>
        </Slide>

        {linkToClone && canCreate && cloneLink.isOpen && (
          <LinkClone
            link={linkToClone}
            isOpen={cloneLink.isOpen}
            onClose={cloneLink.onClose}
            onClone={onClone}
          />
        )}
        {/* {createLink.isOpen && (
        <LinkEdit
          {...createLink}
          onComplete={() => refetch()}
        />
      )}

      {bulkApproveDenyOrders.isOpen && (
        <LinkOrdersApproveReject
          magicLinkIds={linksWithApprovalMetrics.map((l) => l.id)}
          isOpen={bulkApproveDenyOrders.isOpen}
          onClose={bulkApproveDenyOrders.onClose}
        />
      )} */}
      </>
    </ZDialog>
  )
}
