import type { FlexProps } from '@chakra-ui/react'
import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlFetch, useGraphqlPaginatedQuery } from '@postal-io/postal-graphql'
import { UiButton, UiEasyDataTable, UiEasyPagination, UiTooltip } from '@postal-io/postal-ui'
import type {
  ResultsSummary,
  SearchableContact,
  SearchableContactFilterInput,
  SearchContactsV2QueryVariables,
} from 'api'
import { SearchContactsV2Document } from 'api'
import { zMultiSelectCellStyles, zMultiSelectStyles } from 'components/Common/ZComponents'
import { ContactCreateEdit } from 'components/Contact/ContactCreateEdit'
import { ContactsFilter } from 'components/Contacts/ContactsFilter'
import React, { useEffect, useMemo, useState } from 'react'
import { MdAdd, MdSearch } from 'react-icons/md'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { COLUMNS, FETCH_LIMIT, STALE_TIME } from './data'
import type { UseMultiSelect } from './useMultiSelect'

interface DataTableHeaderProps {
  filter: any
  inputRef: any
  addContact: any
  showSelectedCount: boolean
  graphqlFilter?: any
  handleAddFilter: any
  onSearch: any
  showSelectAll: any
  state: any
  summary: any
}
const DataTableHeader: React.FC<DataTableHeaderProps> = ({
  filter,
  inputRef,
  addContact,
  showSelectedCount,
  graphqlFilter,
  handleAddFilter,
  onSearch,
  showSelectAll,
  state,
  summary,
}) => {
  return (
    <Box mt={5}>
      <Flex mb={1}>
        <InputGroup>
          <InputLeftElement
            color="gray.500"
            width="unset"
            pl={0}
            height="100%"
          >
            <MdSearch size={24} />
          </InputLeftElement>
          <Input
            ref={inputRef}
            mr={2}
            pl={8}
            onChange={onSearch}
            placeholder="Search Contacts"
            value={filter.q || ''}
            color="gray.500"
            variant="unstyled"
            _focusVisible={{
              boxShadow: 'none !important',
            }}
          />
        </InputGroup>
        <ButtonGroup>
          <UiTooltip
            title="Open Advanced Filters"
            hasArrow
            placement="top"
            shouldWrapChildren
          >
            <ContactsFilter {...graphqlFilter} />
          </UiTooltip>
          <UiTooltip
            title="Add Contact"
            hasArrow
            placement="top"
            shouldWrapChildren
          >
            <IconButton
              aria-label="Add"
              color="gray.500"
              variant="ghost"
              size="sm"
              onClick={addContact.onOpen}
            >
              <MdAdd size={20} />
            </IconButton>
          </UiTooltip>
        </ButtonGroup>
      </Flex>
      <HStack
        mb={-2}
        spacing={4}
        divider={
          <Divider
            orientation="vertical"
            borderColor="gray.700"
            borderWidth="1px"
            h="1rem"
          />
        }
        w="100%"
        whiteSpace="nowrap"
      >
        {showSelectedCount && (
          <Text
            color={!!state.totalRecords ? 'gray.400' : 'transparent'}
            fontSize="sm"
          >
            {state.totalRecords} contacts selected
          </Text>
        )}
        {showSelectAll && (
          <UiButton
            variant="link"
            onClick={handleAddFilter}
            textTransform="uppercase"
            fontSize="xs"
          >
            Select all {summary.totalRecords} contacts from this search
          </UiButton>
        )}
      </HStack>
    </Box>
  )
}

export interface MultiSelectContactsTableV2Props extends FlexProps {
  filter: any
  inputRef?: React.Ref<HTMLInputElement>
  variables: SearchContactsV2QueryVariables
  state: UseMultiSelect<SearchableContact, SearchableContactFilterInput>
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  graphqlFilter: any
  showSelectedCount?: boolean
}
export const PostalSendMultiSelectContactsTable: React.FC<MultiSelectContactsTableV2Props> = ({
  filter,
  inputRef,
  variables,
  state,
  onSearch,
  graphqlFilter,
  showSelectedCount = true,
  ...rest
}) => {
  const addContact = useDisclosure()

  const columns = useBreakpointValue({
    'base': COLUMNS.filter((c) => ['Name', 'Company', 'Verified'].includes(c.label)),
    'xl': COLUMNS.filter((c) => ['Name', 'Title', 'Company', 'Verified'].includes(c.label)),
    '2xl': COLUMNS.filter((c) => ['Name', 'Email', 'Title', 'Company', 'Verified'].includes(c.label)),
  })

  // setup state
  const [showSelectAll, setShowSelectAll] = useState(false)

  const fetchContacts = useGraphqlFetch(SearchContactsV2Document)

  // get the filter key and filter results from the current graphql query
  const currentFilter = useMemo(() => state.getFilter(variables.filter), [state, variables.filter])

  // GRAPHQL QUERIES
  const { page, setPage, prefetchPage, hasNextPage, data, isFetching } = useGraphqlPaginatedQuery(
    SearchContactsV2Document,
    variables,
    { staleTime: STALE_TIME }
  )

  // current page of contacts
  const rows = useMemo(
    () => data?.searchContactsV2.searchableContacts || [],
    [data?.searchContactsV2.searchableContacts]
  )

  // current summary of search contacts results
  const summary = useMemo(() => {
    const results = data?.searchContactsV2?.resultsSummary || ({} as ResultsSummary)
    return {
      totalPages: results.totalPages || 0,
      totalRecords: results.totalRecords || 0,
      hasMore: results.hasMore || false,
    }
  }, [data?.searchContactsV2?.resultsSummary])

  // a row is passed in on an individual select, nothing for a select all
  const handleSelect = (isChecked: boolean, row?: SearchableContact) => {
    row ? handleSelectRow(isChecked, row) : handleSelectAll(isChecked)
  }

  // handle data table clicking a row
  const handleSelectRow = (isChecked: boolean, row: SearchableContact) => {
    if (!!currentFilter?.totalRecords) {
      // we are in select all, lets select everything on page and unselect this row
      state.addItems(rows)
      state.removeItem(row)
      state.removeFilter(currentFilter.filter)
    } else if (isChecked) {
      state.addItem(row)
    } else {
      state.removeItem(row)
    }
    setShowSelectAll(false)
  }

  // this is called when someone wants to add everything that matches the search
  const handleAddFilter = async () => {
    if (!summary.totalRecords) return
    const res = await fetchContacts({ ...variables, limit: FETCH_LIMIT }, { staleTime: STALE_TIME })
    const contacts = res.searchContactsV2?.searchableContacts || []
    const totalRecords = res.searchContactsV2?.resultsSummary?.totalRecords || 0
    if (!totalRecords) return

    if (contacts.length < totalRecords) {
      // we don't have all the contacts, add as a filter
      state.removeItems(contacts)
      state.addFilter(variables.filter, totalRecords)
    } else {
      // we got all the contacts, lets just add them as individual selects
      state.addItems(contacts)
    }
    setShowSelectAll(false)
  }

  // handle data table clicking the select all checkbox
  const handleSelectAll = (isChecked: boolean) => {
    if (!!currentFilter?.totalRecords) {
      // we are in select all, lets unselect all
      state.removeFilter(variables.filter)
      setShowSelectAll(false)
    } else if (isChecked) {
      state.addItems(rows)
      setShowSelectAll(true)
    } else {
      state.removeItems(rows)
      setShowSelectAll(false)
    }
  }

  // reset to page 0 when the filter changes
  useDeepCompareEffect(() => {
    setPage(0)
    setShowSelectAll(false)
  }, [variables.filter])

  // remove select all option if the page is changed
  useEffect(() => {
    setShowSelectAll(false)
    prefetchPage(page + 1)
    prefetchPage(Math.max(page - 1, 0))
    // prefetchPage causing this to fire repeatedly if not excluded from dep array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <Box
      borderRadius={5}
      {...rest}
    >
      <DataTableHeader
        inputRef={inputRef}
        onSearch={onSearch}
        filter={filter}
        addContact={addContact}
        state={state}
        showSelectAll={showSelectAll}
        handleAddFilter={handleAddFilter}
        summary={summary}
        graphqlFilter={graphqlFilter}
        showSelectedCount={showSelectedCount}
      />
      <UiEasyDataTable
        mt={4}
        border="none"
        isLoading={isFetching}
        rowKey="id"
        rows={rows}
        columns={columns ?? []}
        selected={state.items}
        onSelect={handleSelect}
        allSelected={!!currentFilter?.totalRecords}
        boxShadow="none"
        borderRadius="4px"
        sx={{ ...zMultiSelectStyles, ...zMultiSelectCellStyles }}
      />

      <UiEasyPagination
        onPrefetchPage={prefetchPage}
        onSetPage={setPage}
        currentPage={page}
        totalPages={summary.totalPages}
        hasNextPage={hasNextPage}
      />

      {addContact.isOpen && (
        <ContactCreateEdit
          isOpen={addContact.isOpen}
          onClose={addContact.onClose}
        />
      )}
    </Box>
  )
}
