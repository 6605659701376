import type { BoxProps } from '@chakra-ui/react'
import { Box, useClipboard } from '@chakra-ui/react'
import { UiTruncate, useAlerts } from '@postal-io/postal-ui'
import { ZLink } from 'components/Common/ZComponents'
import React from 'react'

interface ApprovedPostalEventMeetingInfoProps extends BoxProps {
  meetingLink?: string | null
  onClick?: () => void
}

export const EventsApprovedPostalEventMeetingInfo: React.FC<ApprovedPostalEventMeetingInfoProps> = ({
  meetingLink,
  onClick,
  ...rest
}) => {
  const { onCopy, setValue: setUrl } = useClipboard('')
  const Alert = useAlerts()

  const handleCopy = () => {
    setUrl(meetingLink ?? '')
    setTimeout(onCopy)
    Alert.success('Meeting link copied to clipboard')
  }

  return (
    <Box
      mt={2}
      {...rest}
    >
      {meetingLink ? (
        <ZLink
          fontSize="body-lg"
          variant="link"
          onClick={handleCopy}
          fontWeight="bold"
        >
          <UiTruncate
            text={meetingLink}
            length={50}
          />
        </ZLink>
      ) : onClick ? (
        <ZLink
          fontSize="body-lg"
          onClick={onClick}
          fontWeight="bold"
        >
          Please add a meeting link
        </ZLink>
      ) : (
        'TBD'
      )}
    </Box>
  )
}
