import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiLoading, useAlerts } from '@postal-io/postal-ui'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useLocation, useNavigate } from 'react-router-dom'
import { SearchUsersDocument } from '../../api'

export const RedirectUser: React.FC = () => {
  const { id } = useParams() as any
  const navigate = useNavigate()
  const Alert = useAlerts()
  const getUser = useGraphqlQuery(SearchUsersDocument, {
    filter: { userName: { eq: id } },
    limit: 1,
  })

  const { state } = useLocation() as any

  useEffect(() => {
    if (getUser.isLoading) return

    const user = getUser?.data?.searchUsers?.users?.[0]
    if (!user || getUser.error) {
      Alert.warning('User not found')
      navigate(-1)
      return
    }

    navigate(`/users/${user.id}`, state)
  }, [Alert, getUser?.data?.searchUsers?.users, getUser.error, getUser.isLoading, navigate, state])

  return <UiLoading />
}
