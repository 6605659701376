import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnalyticsEventV2, getAnalyticsSessionInfo, sendAnalyticsEvent } from './useAnalytics'
import { useSession } from './useSession'

export const useLogout = () => {
  const navigate = useNavigate()
  const { logout } = useSession()

  return useCallback(async () => {
    const session = await logout()
    sendAnalyticsEvent(AnalyticsEventV2.Engage_Logout, getAnalyticsSessionInfo(session))
    navigate('/sign-in')
  }, [logout, navigate])
}
