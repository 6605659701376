/*
  { elements: [{}], selected: 1, showGrid: false, snapGrid: false, gridSize: 20 }
*/

export interface DesignTemplateReducerStateProps {
  elements: any[]
  selected: number | null
  showGrid: boolean
  snapGrid: boolean
  gridSize: number
  warning?: string
}

export type DesignTemplateReducerType =
  | 'TOGGLE_GRID'
  | 'RESIZE_GRID'
  | 'TOGGLE_SNAP_GRID'
  | 'HIDE'
  | 'SHOW'
  | 'TOGGLE_VISIBILITY'
  | 'SELECT'
  | 'UNSELECT'
  | 'TOGGLE_SELECTED'
  | 'RESIZE'
  | 'MOVE'
  | 'ADD'
  | 'EDIT'
  | 'REMOVE'
  | 'ORDER'
  | 'WARN'

export interface DesignTemplateDispatch {
  type: string
  payload?: any
}

export const designTemplateReducer = (
  state: DesignTemplateReducerStateProps,
  { type, payload }: { type: DesignTemplateReducerType; payload: any }
) => {
  switch (type) {
    case 'TOGGLE_GRID':
      state.showGrid = !state.showGrid
      if (!state.showGrid) state.snapGrid = false
      break
    case 'RESIZE_GRID':
      state.gridSize = payload
      break
    case 'TOGGLE_SNAP_GRID':
      state.snapGrid = !state.snapGrid
      if (state.snapGrid) state.showGrid = true
      break
    case 'HIDE': {
      const element = state.elements.find((e) => e.id === payload.id)
      if (element) element.hidden = true
      break
    }
    case 'SHOW': {
      const element = state.elements.find((e) => e.id === payload.id)
      if (element) element.hidden = false
      break
    }
    case 'TOGGLE_VISIBILITY': {
      const element = state.elements.find((e) => e.id === payload.id)
      if (element) element.hidden = !element.hidden
      break
    }
    case 'SELECT':
      state.selected = payload.id
      break
    case 'UNSELECT':
      state.selected = null
      break
    case 'TOGGLE_SELECTED':
      state.selected = payload.id === state.selected ? null : payload.id
      break
    case 'RESIZE':
    case 'MOVE':
      state.elements = state.elements.map((e) => (state.selected === e.id ? payload : e))
      break
    case 'ADD': {
      const locked = state.elements.filter((e) => e.locked)
      const unlocked = state.elements.filter((e) => !e.locked)
      state.elements = [...unlocked, payload, ...locked]
      state.selected = payload.id
      break
    }
    case 'EDIT':
      state.elements = state.elements.map((e) => (e.id === payload.id ? payload : e))
      break
    case 'REMOVE':
      state.elements = state.elements.filter((e) => e.id !== payload.id)
      state.selected = payload.id ? null : state.selected
      break
    case 'ORDER':
      state.elements = payload
      break
    case 'WARN':
      state.warning = payload
      break
    default:
      return state
  }
}

export type DesignTemplateElementTypes =
  | 'AddressLabel'
  | 'Border'
  | 'FoldLine'
  | 'Image'
  | 'Logo'
  | 'Postage'
  | 'QrCode'
  | 'ReservedArea'
  | 'Text'
  | 'UserMessage'
  | 'UspsImb'
  | 'UspsBlock'

export interface DesignTemplateElementProps {
  name: DesignTemplateElementTypes
  description: string
  location: {
    x: number
    y: number
    width: number
    height: number
  }
  boundary: {
    minX: number
    maxX: number
    minY: number
    maxY: number
  }
  resize: {
    minWidth: number
    maxWidth: number
    minHeight: number
    maxHeight: number
  }
  settings: {
    drawMode: string
    assetUrl: string
    thicknessPixels: number
    color: string
    randomLeftOffset: boolean
    handwritingName: string
    fontSize: number
    spacingModifier: number
    text: string
  }
  customizable: boolean
  locked: boolean
}

export interface DesignTemplateProps {
  front: DesignTemplateElementProps[]
  back: DesignTemplateElementProps[]
  dpi: number
  outputSize: {
    width: number
    height: number
  }
}
