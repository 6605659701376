import type { MeetingSettingsInput } from '../../api'
import type { Event } from './schedule'

export interface MeetingSettingState extends Omit<MeetingSettingsInput, 'availabilityBlockIcal'> {
  events: Event[]
}

export interface MeetingSettingFormState {
  original: MeetingSettingState
  current: MeetingSettingState
}

export const MEETING_REQUEST_TOOLTIP = `The default setting for meeting requests, which you can override on individual sends.

Off:
Do not request a meeting from contacts when accepting an item.

Before Item Acceptance:
Contacts MUST schedule a meeting before they can accept an item.

After Item Acceptance:
Contacts are asked to schedule a meeting after accepting an item.
`
