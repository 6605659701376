import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlertError } from '@postal-io/postal-ui'
import type { Settings } from 'api'
import { GetAccountDocument, ProductAccessType, Status, TeamsDocument } from 'api'
import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'

export interface MiniTeam {
  id: string
  accountId: string
  name: string
  teamId?: string
  created?: string
  settings?: Settings | null
  status: Status
  kind: ProductAccessType
}

export const useMiniTeams = (search?: string, limit = 20) => {
  const [debounce] = useDebounce(search, 400)

  const variables = useMemo(() => {
    return {
      filter: {
        name: debounce ? { contains: debounce } : undefined,
        status: { eq: Status.Active },
      },
      limit,
    }
  }, [debounce, limit])

  const teamsQuery = useGraphqlInfiniteQuery(TeamsDocument, variables)
  const accountQuery = useGraphqlQuery(GetAccountDocument)

  // Merge the account object and all the teams together
  const teams: MiniTeam[] = useMemo(() => {
    const account = accountQuery?.data?.getAccount
    const _teams = teamsQuery?.mergedData?.teams || []
    const all = []
    if (account && (!debounce || account.name.toLowerCase().includes(debounce.toLowerCase()))) {
      all.push({
        id: account.id,
        accountId: account.id,
        name: account.name,
        created: account.created?.dateTime,
        status: account.status,
        settings: account.settings,
        kind: ProductAccessType.Account,
      })
    }
    _teams.forEach((team) => {
      all.push({
        id: `${team.accountId}:${team.id}`,
        accountId: team.accountId,
        name: team.name,
        teamId: team.id,
        created: team.created?.dateTime,
        status: team.status,
        settings: team.settings,
        kind: ProductAccessType.Team,
      })
    })
    return all
  }, [accountQuery?.data?.getAccount, debounce, teamsQuery?.mergedData?.teams])

  const isLoading = useMemo(
    () => teamsQuery.isLoading || accountQuery.isLoading,
    [accountQuery.isLoading, teamsQuery.isLoading]
  )
  const isFetching = useMemo(
    () => teamsQuery.isFetching || accountQuery.isFetching,
    [accountQuery.isFetching, teamsQuery.isFetching]
  )

  useAlertError(accountQuery.error)
  useAlertError(teamsQuery.error)

  return {
    isLoading,
    isFetching,
    hasNextPage: teamsQuery.hasNextPage,
    fetchNextPage: teamsQuery.fetchNextPage,
    teams,
  }
}
