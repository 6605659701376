import { FormControl, Stack } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiAlert,
  UiButton,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerFooterButtons,
  UiDrawerHeader,
  UiDrawerOverlay,
  UiFormLabel,
  UiInput,
  UiLink,
  useAlerts,
} from '@postal-io/postal-ui'
import { useHelp } from 'hooks'
import React from 'react'
import { useImmer } from 'use-immer'
import type { SetupCustomIntegrationMutationVariables } from '../../api'
import { CustomIntegrationNames, SetupCustomIntegrationDocument } from '../../api'

const initialState: any = {
  clientId: '',
  clientSecret: '',
  baseUrl: '',
  systemName: CustomIntegrationNames.Marketo,
}

interface MarketoIntegrationProps {
  isOpen: boolean
  onClose: () => void
  onComplete: () => void
}
export const MarketoIntegration: React.FC<MarketoIntegrationProps> = ({ isOpen, onClose, onComplete }) => {
  const Alert = useAlerts()

  const [state, setState] = useImmer<Record<string, any>>(initialState)

  const setupCustomIntegration = useGraphqlMutation(SetupCustomIntegrationDocument)

  const { sendMessage } = useHelp()

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target
    setState((draft: Record<string, any>) => {
      draft[name] = value
    })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await setupCustomIntegration.mutateAsync(state as SetupCustomIntegrationMutationVariables)
      Alert.success('Marketo integration connected')
      onComplete()
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <UiDrawer
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <form onSubmit={handleSubmit}>
        <UiDrawerContent>
          <UiDrawerCloseButton />
          <UiDrawerHeader>Marketo Integration</UiDrawerHeader>
          <UiDrawerBody>
            <Stack
              spacing={8}
              mt={8}
            >
              <UiAlert status="info">
                Before connecting, ensure that you have everything setup properly in Marketo by going through our{' '}
                <UiLink
                  isExternal
                  href="https://help.postal.com/helpcenter/s/article/Marketo-Integration"
                >
                  Integration Setup Article.
                </UiLink>{' '}
                If you need additional assistance, please{' '}
                <UiLink onClick={() => sendMessage(`I need help setting up Marketo`)}>Contact Support</UiLink>
              </UiAlert>

              <FormControl>
                <UiFormLabel htmlFor="clientId">Client ID (required)</UiFormLabel>
                <UiInput
                  id="clientId"
                  name="clientId"
                  value={state?.clientId}
                  onChange={handleChange}
                  isRequired
                />
              </FormControl>
              <FormControl>
                <UiFormLabel htmlFor="clientSecret">Client Secret (required)</UiFormLabel>
                <UiInput
                  id="clientSecret"
                  name="clientSecret"
                  value={state?.clientSecret}
                  onChange={handleChange}
                  isRequired
                />
              </FormControl>
              <FormControl>
                <UiFormLabel htmlFor="baseUrl">Endpoint URL (required)</UiFormLabel>
                <UiInput
                  id="baseUrl"
                  name="baseUrl"
                  value={state?.baseUrl}
                  onChange={handleChange}
                  isRequired
                />
              </FormControl>
            </Stack>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiDrawerFooterButtons>
              <UiButton
                colorScheme="gray"
                onClick={onClose}
                isDisabled={setupCustomIntegration.isLoading}
              >
                Cancel
              </UiButton>
              <UiButton
                type="submit"
                isDisabled={setupCustomIntegration.isLoading}
                isLoading={setupCustomIntegration.isLoading}
              >
                Connect to Marketo
              </UiButton>
            </UiDrawerFooterButtons>
          </UiDrawerFooter>
        </UiDrawerContent>
      </form>
    </UiDrawer>
  )
}
