import { CheckIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Grid,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { FontWeight, UiCard, UiDangerous, ZHeading, ZMoney, ZText } from '@postal-io/postal-ui'
import type { ImageReference } from 'api'
import { useAssets } from 'hooks'
import { isNumber } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { MdOutlineLocalShipping } from 'react-icons/md'
import useMeasure from 'react-use-measure'

import { ImagePopover } from './ImagePopover'

interface PostalVariantCardProps {
  description?: string | null
  imageUrl?: ImageReference
  inventory?: number | null
  isLoading?: boolean
  isSelected?: boolean
  name: string
  onClick?: () => void
  displayPrice?: number
  flatFees?: number
  currency?: string
  shippingEstimate?: number | null
  hideShipping?: boolean
}

const DEFAULT_LINE_CLAMP = 4
const MIN_WIDTH_TO_SHOW_IMG_PREVIEW_TEXT = 310

export const PostalVariantCard: React.FC<PostalVariantCardProps> = ({
  description,
  imageUrl,
  inventory,
  isLoading,
  isSelected,
  name,
  onClick,
  displayPrice,
  flatFees,
  currency,
  shippingEstimate,
  hideShipping,
  ...rest
}) => {
  const { assetUrlSrc } = useAssets()
  const { src, fallbackSrc } = assetUrlSrc(imageUrl?.url)
  const [widthMeasureRef, { width }] = useMeasure()

  const [isClamped, setIsClamped] = useState(false)

  const clampRef: any = useCallback((node: HTMLDivElement) => {
    setIsClamped(node === null ? false : node.scrollHeight > node.clientHeight)
  }, [])

  const priceWords = useMemo(() => {
    if (displayPrice && flatFees) {
      return (
        <Flex
          alignItems="center"
          as="span"
        >
          <ZMoney
            currency={currency}
            cents={displayPrice}
            round={false}
            color="atomicBlue.500"
            fontSize="2xl"
            fontWeight="bold"
          />
          <Box
            ml={2}
            as="span"
            fontWeight="normal"
            fontSize="sm"
            color="gray.600"
          >
            (+
            <ZMoney
              currency={currency}
              cents={flatFees}
              round={false}
            />
            )
          </Box>
        </Flex>
      )
    } else if (displayPrice) {
      return (
        <Flex
          width="100%"
          data-testid="PostalVariantCard_priceWords_shipping"
        >
          {!hideShipping && (
            <Flex
              ml={2}
              alignItems="center"
              borderRight="1px solid var(--chakra-colors-gray-400)"
              px={2}
            >
              <ZHeading
                size="h6"
                color="atomicGray.500"
                mr={2}
                fontWeight={FontWeight.Bold}
                title="Estimated shipping cost"
              >
                {!shippingEstimate ? (
                  'FREE'
                ) : (
                  <>
                    ~
                    <ZMoney
                      cents={shippingEstimate}
                      currency={currency}
                      placeholder="$0"
                      round
                    />
                  </>
                )}
              </ZHeading>
              <MdOutlineLocalShipping
                color="var(--chakra-colors-gray-400)"
                size={24}
              />
            </Flex>
          )}

          <ZMoney
            ml={2}
            size="h6"
            color="atomicBlue.500"
            fontWeight="bold"
            cents={displayPrice}
            currency={currency}
            round
          />
        </Flex>
      )
    } else if (flatFees) {
      return (
        <Flex
          alignItems="center"
          as="span"
        >
          <ZMoney
            currency={currency}
            cents={flatFees}
            color="atomicBlue.500"
            fontSize="2xl"
            fontWeight="bold"
            round
          />
          <Box
            ml={2}
            as="span"
            fontWeight="normal"
            fontSize="sm"
            color="gray.600"
          >
            flat fee
          </Box>
        </Flex>
      )
    }
  }, [displayPrice, flatFees, currency, hideShipping, shippingEstimate])

  return (
    <>
      <UiCard
        data-testid={`PostalVariantCard_card_${isSelected ? 'selected' : 'unselected'}`}
        mb={0}
        w="100%"
        h="100%"
        minH="200px"
        boxShadow="sm"
        borderRadius="md"
        isLoading={isLoading}
        p={0}
        onClick={onClick}
        cursor={onClick ? 'pointer' : 'default'}
        {...rest}
      >
        <Grid
          templateColumns={`1fr${onClick ? ' 30px' : ''}`}
          h="100%"
          ref={widthMeasureRef}
        >
          <Flex
            display="flex"
            flexDir="column"
            alignItems="stretch"
            p={4}
          >
            <ZText
              variant="bold"
              mb={0}
              textAlign="left"
            >
              <Box
                as="span"
                mb={1}
              >
                {name}
              </Box>
            </ZText>
            {description && (
              <Box
                ref={clampRef}
                whiteSpace="break-spaces"
                mt={2}
                overflow="hidden"
                display="-webkit-box"
                style={{
                  WebkitLineClamp: DEFAULT_LINE_CLAMP,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                <UiDangerous html={description} />
              </Box>
            )}
            <Box flexGrow={1} />
            <Flex
              justifyContent="space-between"
              alignItems="baseline"
              py={1}
            >
              {isClamped && (
                <Popover
                  trigger="hover"
                  placement="top"
                >
                  <PopoverTrigger>
                    <Button
                      variant="link"
                      fontSize="sm"
                      fontWeight="normal"
                    >
                      View More
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody
                      p={8}
                      whiteSpace="break-spaces"
                    >
                      <UiDangerous html={description as string} />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
              {isNumber(inventory) && (
                <Box textTransform="uppercase">
                  <ZText
                    as="span"
                    size="xs"
                    color="atomicGray.500"
                    mr={2}
                  >
                    quantity:
                  </ZText>
                  <ZText
                    as="span"
                    size="sm"
                    color="atomicGray.500"
                  >
                    {inventory}
                  </ZText>
                </Box>
              )}
            </Flex>
            <Flex
              justifyContent="space-between"
              alignItems="baseline"
              w="100%"
            >
              {src ? (
                <ImagePopover
                  src={src}
                  fallbackSrc={fallbackSrc}
                  hidePreviewText={width < MIN_WIDTH_TO_SHOW_IMG_PREVIEW_TEXT}
                />
              ) : (
                <Box></Box>
              )}
              <Box>{priceWords}</Box>
            </Flex>
          </Flex>

          {onClick && (
            <Flex
              height="100%"
              w="30px"
              backgroundColor={!isSelected ? 'gray.100' : !onClick && isSelected ? 'atomicBlue.200' : 'atomicBlue.500'}
              alignItems="center"
              justifyContent="center"
              boxShadow="sm"
              borderTopRightRadius="md"
              borderBottomRightRadius="md"
              borderColor={!isSelected ? 'gray.500' : !onClick && isSelected ? 'atomicBlue.200' : 'atomicBlue.500'}
            >
              {isSelected ? (
                <CheckIcon
                  color="white"
                  boxSize="16px"
                />
              ) : (
                <></>
              )}
            </Flex>
          )}
        </Grid>
      </UiCard>
    </>
  )
}
