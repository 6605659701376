import type { Passwords } from '@postal-io/postal-ui'
import { useAlerts } from '@postal-io/postal-ui'
import { PageTitle, useSession } from 'hooks'
import jwtDecode from 'jwt-decode'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SetPassword } from '../Session'

interface WelcomePasswordProps {
  token: string
}

export const WelcomePassword: React.FC<WelcomePasswordProps> = ({ token }) => {
  const [loading, setLoading] = useState(false)
  const { signUpPassword } = useSession()
  const Alert = useAlerts()
  const navigate = useNavigate()

  const userName = useMemo(() => {
    try {
      return (jwtDecode(token) as any).userName
    } catch (err) {}
  }, [token])

  const onSubmit = async ({ password, password2 }: Passwords) => {
    setLoading(true)
    try {
      await signUpPassword({ token, password, password2 })
      Alert.success('Your account is created!')
      navigate('/')
    } catch (err) {
      setLoading(false)
      Alert.warning(err)
    }
  }

  return (
    <>
      <PageTitle
        title="Set Password"
        section="Welcome"
      />
      <SetPassword
        onSubmit={onSubmit}
        isLoading={loading}
        emailAddress={userName}
        showSso={false}
      />
    </>
  )
}
