import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { PostalFulfillmentFilterInput, SearchPostalFulfillmentsQueryVariables } from 'api'
import { FulfillmentStatus, PostalFulfillmentOrderByInput, Role, SearchPostalFulfillmentsDocument } from 'api'
import { useAcl } from 'hooks'
import { useMemo } from 'react'

export const useNeedsApproval = (magiclinkId?: string, ownerId?: string) => {
  const { hasRole, hasFeature } = useAcl()
  const canApproveLinks = hasFeature('manuallyApproveLinks') && hasRole(Role.User)
  const staticVariables: SearchPostalFulfillmentsQueryVariables = useMemo(() => {
    const filter = {} as PostalFulfillmentFilterInput
    if (ownerId) {
      filter.userId = {
        eq: ownerId,
      }
    }

    if (magiclinkId) {
      filter.magicLinkId = {
        eq: magiclinkId,
      }
    }

    filter.status = {
      eq: FulfillmentStatus.PendingUserApproval,
    }

    return {
      filter,
      orderBy: PostalFulfillmentOrderByInput.CreatedDesc,
    }
  }, [magiclinkId, ownerId])

  const { data: ordersData } = useGraphqlQuery(SearchPostalFulfillmentsDocument, staticVariables, {
    enabled: canApproveLinks,
  })

  const totalNeedsAction = useMemo(() => ordersData?.searchPostalFulfillments?.length || 0, [ordersData])

  return {
    totalNeedsAction,
  }
}
