import { useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZButtonProps } from '@postal-io/postal-ui'
import { UiEnhancedTooltip, ZButton } from '@postal-io/postal-ui'
import { LinksDisplayDialog } from 'components/MagicLinks'
import { PostalSendMethod, SEND_METHOD_COLORS } from 'components/PostalSend/postalSendHelpers'
import React, { useMemo } from 'react'
import { MdOutlineAssistant } from 'react-icons/md'
import type { ApprovedPostal } from '../../api'
import { MagicLinkOrderByInput, MagicLinkStatus, SearchMagicLinksDocument, Status } from '../../api'
import { usePostalPermissions, useSession } from '../../hooks'
import { isMagicLinkValid } from '../../lib'

export interface ExistingMagicLinkDisplayProps extends ZButtonProps {
  postal?: ApprovedPostal
  isDisabled?: boolean
}
export const ExistingMagicLinkDisplay: React.FC<ExistingMagicLinkDisplayProps> = ({ postal, isDisabled, ...rest }) => {
  const { session } = useSession()
  const { canLink } = usePostalPermissions(postal)
  const disclosure = useDisclosure()

  const options = useMemo(() => {
    return postal?.variants?.filter((v) => v.status === Status.Active) || []
  }, [postal?.variants])

  const isActive = postal?.status === Status.Active && canLink && !!options.length

  const searchMagicLinks = useGraphqlQuery(
    SearchMagicLinksDocument,
    {
      filter: {
        approvedPostalId: { eq: postal?.id },
        userId: { eq: session.userId },
        status: { eq: MagicLinkStatus.Active },
      },
      orderBy: MagicLinkOrderByInput.IdAsc,
    },
    { enabled: isActive }
  )

  const count = useMemo(
    () => searchMagicLinks?.data?.searchMagicLinks?.filter(isMagicLinkValid)?.length ?? 0,
    [searchMagicLinks?.data?.searchMagicLinks]
  )

  if (!isActive || !count) return null

  return (
    <>
      <UiEnhancedTooltip
        isDisabled={disclosure.isOpen}
        hasArrow
        placement="top"
        openDelay={500}
        label="Show Links"
      >
        <ZButton
          variant="link"
          fontSize="sm"
          height="unset"
          p={0}
          onClick={disclosure.onOpen}
          isDisabled={searchMagicLinks.isLoading || isDisabled}
          color={SEND_METHOD_COLORS[PostalSendMethod.Link]}
          _hover={{ color: SEND_METHOD_COLORS[PostalSendMethod.Link].replace(/\d00/, '600') }}
          {...rest}
        >
          <MdOutlineAssistant
            size="18px"
            style={{ marginRight: 10 }}
          />
          {count} existing {count === 1 ? 'link' : 'links'}
        </ZButton>
      </UiEnhancedTooltip>
      {disclosure.isOpen && (
        <LinksDisplayDialog
          postal={postal}
          {...disclosure}
        />
      )}
    </>
  )
}
