import type { WrapProps } from '@chakra-ui/react'
import { useDisclosure, Wrap } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalHeader,
  UiModalOverlay,
  UiSlide,
  UiSlideArrows,
  UiSlideCard,
  UiSlideCards,
  UiSlideContainer,
  UiSlideDots,
  UiSlideTitle,
  UiTag,
} from '@postal-io/postal-ui'
import { CreateEmailPreviewUrlsDocument, GetAccountDocument } from 'api'
import React, { useMemo } from 'react'
import { useMeasure } from 'react-use'
import { Iframe } from '../Common'
import type { EventEmailType } from './data'
import { EventEmail } from './data'
import type { PostalSendContext } from './usePostalSend'
import { useTemplatePreview } from './useTemplatePreview'

export interface PostalReviewEmailNotificationsV2Props extends WrapProps {
  context: PostalSendContext
}

export const PostalReviewEmailNotifications: React.FC<PostalReviewEmailNotificationsV2Props> = ({
  context,
  ...rest
}) => {
  const emailNotifications = useMemo(() => {
    const notifications: { emailType: EventEmailType; label: string }[] = []
    const e = context?.postal?.event
    if (e?.sendEmailConfirmation)
      notifications.push({
        emailType: EventEmail.EVENT_REGISTERED,
        label: 'Confirmation Email',
      })
    if (e?.sendReminderDayBefore)
      notifications.push({
        emailType: EventEmail.EVENT_REMINDER_TOMORROW,
        label: 'Reminder - Day Before',
      })
    if (e?.sendReminderDayOf)
      notifications.push({
        emailType: EventEmail.EVENT_REMINDER_TODAY,
        label: 'Reminder - Day of',
      })
    if (e?.sendCancelledAlert)
      notifications.push({
        emailType: EventEmail.EVENT_CANCELLED,
        label: 'Event Cancelled Alert',
      })
    if (e?.sendInviteExpiringAlert)
      notifications.push({
        emailType: EventEmail.EVENT_INVITE_EXPIRING,
        label: 'Event Expiring Alert',
      })
    if (e?.sendMeetingLinkChanged)
      notifications.push({
        emailType: EventEmail.EVENT_MEETING_LINK_CHANGED,
        label: 'Meeting Link Update Alert',
      })
    if (e?.sendDateTimeChanged)
      notifications.push({
        emailType: EventEmail.EVENT_DATE_TIME_CHANGED,
        label: 'Date/Time Update Alert',
      })
    if (e?.sendShippedAlert)
      notifications.push({
        emailType: EventEmail.EVENT_KIT_SHIPPED,
        label: 'Event Kit Shipped Alert',
      })
    if (e?.sendDeliveredAlert)
      notifications.push({
        emailType: EventEmail.EVENT_KIT_DELIVERED,
        label: 'Event Kit Delivered Alert',
      })
    return notifications
  }, [context?.postal?.event])

  const [ref, { height }] = useMeasure()

  const accountQuery = useGraphqlQuery(GetAccountDocument)
  const account = useMemo(() => accountQuery.data?.getAccount, [accountQuery.data?.getAccount])

  const { contacts, postal, sendAsUser, variant } = context

  const { emailSubjectPreview, giftMessagePreview } = useTemplatePreview(context)

  const previewQuery = useGraphqlQuery(
    CreateEmailPreviewUrlsDocument,
    {
      data: {
        contactId: contacts?.items?.[0]?.id,
        giftMessage: giftMessagePreview,
        emailSubjectLine: emailSubjectPreview,
        approvedPostalId: postal?.id,
        approvedPostalVariantId: variant?.id,
        emailTemplateId: account?.emailTemplateId,
        sendAsUser: sendAsUser,
      },
    },
    { enabled: !accountQuery.isLoading }
  )

  const previews = useMemo(() => {
    return (
      previewQuery?.data?.createEmailPreviewUrls?.filter((preview) => {
        return emailNotifications.find((notification) => {
          return preview.emailType === notification.emailType && preview.previewType === 'fullscreen'
        })
      }) || []
    )
  }, [emailNotifications, previewQuery?.data?.createEmailPreviewUrls])

  const disclosure = useDisclosure()

  return (
    <>
      <Wrap
        spacing={4}
        {...rest}
      >
        {emailNotifications.map((notification) => (
          <UiTag key={notification.label}>{notification.label}</UiTag>
        ))}
      </Wrap>
      <UiModal
        size="full"
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        scrollBehavior="inside"
      >
        <UiModalOverlay />
        <UiModalContent>
          <UiModalHeader>
            Event Notifications
            <UiModalCloseButton />
          </UiModalHeader>
          <UiModalBody
            bg="gray.200"
            p={0}
            /* @ts-ignore */
            ref={ref}
          >
            <UiSlide>
              <UiSlideContainer
                maxW="7xl"
                mx="auto"
                bg="white"
              >
                <UiSlideCards>
                  {previews.map((preview, idx) => {
                    const title =
                      emailNotifications.find((n) => n.emailType === preview.emailType)?.label || preview.emailName
                    return (
                      <UiSlideCard key={idx}>
                        <UiSlideTitle fontSize="lg">{title}</UiSlideTitle>
                        <Iframe
                          pt={9}
                          src={preview.htmlUrl}
                          h={height}
                        />
                      </UiSlideCard>
                    )
                  })}
                </UiSlideCards>
                <UiSlideArrows />
                <UiSlideDots />
              </UiSlideContainer>
            </UiSlide>
          </UiModalBody>
        </UiModalContent>
      </UiModal>
    </>
  )
}
