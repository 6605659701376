import { AddIcon } from '@chakra-ui/icons'
import { Flex, FormControl, SimpleGrid, Stack, StackDivider, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiCard,
  UiConfirm,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerFooterButtons,
  UiDrawerHeader,
  UiDrawerOverlay,
  UiFormLabel,
  UiInput,
  useAlerts,
} from '@postal-io/postal-ui'
import type { AddressInput, ContactInput, PhoneMapInput, Tag } from 'api'
import { ContactType, PhoneType, UpsertContactDocument } from 'api'
import { CONTACT_INVALIDATIONS, useBackgroundQueue, useMe } from 'hooks'
import React, { useState } from 'react'
import { useImmer } from 'use-immer'
import { AutoCompleteTagsCreatable } from '../AutoComplete/AutoCompleteTags'
import { ContactAddressEdit } from './ContactAddressEdit'
import { ContactEditAddressBlock } from './ContactEditAddressBlock'
import { ContactEditPhoneBlock } from './ContactEditPhoneBlock'

interface ContactCreateProps {
  isOpen: boolean
  onClose: () => void
  onComplete?: () => void
}

export const ContactCreate: React.FC<ContactCreateProps> = ({ isOpen, onClose, onComplete }) => {
  const Alert = useAlerts()
  const editAddress = useDisclosure()
  const setPreferredAddress = useDisclosure()

  const { me } = useMe()

  const [form, setForm] = useImmer<ContactInput>({
    firstName: '',
    lastName: '',
    emailAddress: '',
    title: '',
    companyName: '',
    ownerId: me?.id,
    phones: [],
    addresses: [],
    type: ContactType.Contact,
    tags: [],
  })

  const addPhone = () => {
    setForm((draft) => void draft.phones?.push({ phoneNumber: '', type: PhoneType.Work }))
  }

  // address stuff
  const [addressRemoveIndex, setAddressRemoveIndex] = useState<number>()
  const [addressTabIndex, setAddressTabIndex] = useState<number | undefined>(0)
  const removeAddress = useDisclosure()

  const removeAddressHandler = (idx: number) => {
    setAddressRemoveIndex(idx)
    removeAddress.onOpen()
  }

  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target
    setForm((draft: Record<string, any>) => void (draft[name] = value))
  }

  const handleRemoveAddress = () => {
    const addressInputs = form.addresses || []
    const addresses = addressInputs.filter((_item, idx) => idx !== addressRemoveIndex)
    setForm((draft) => void (draft.addresses = addresses))
    setAddressTabIndex(0)
    removeAddress.onClose()
  }

  const preferredAddressHandler = (idx: number) => {
    setAddressTabIndex(idx)
    setPreferredAddress.onOpen()
  }

  const handleMakePreferred = () => {
    const addressInputs = form.addresses?.map((address, idx) => ({
      ...address,
      preferred: idx === addressTabIndex,
    }))
    setForm((draft: Record<string, any>) => void (draft.addresses = addressInputs))
    setPreferredAddress.onClose()
  }

  const handleClose = () => {
    addressTabIndex ? setAddressTabIndex(addressTabIndex) : setAddressTabIndex(0)
    editAddress.onClose()
  }

  const { invalidate } = useBackgroundQueue()
  const createContact = useGraphqlMutation(UpsertContactDocument, {
    onSuccess: () => invalidate(CONTACT_INVALIDATIONS),
  })

  const handleEditAddress = (idx?: number) => {
    //reset local state - if there is no idx then this is a new address add
    setAddressTabIndex(undefined)
    if (typeof idx === 'number') {
      setAddressTabIndex(idx)
    }
    editAddress.onOpen()
  }

  /*
    we are setting the id the same as tag name because we don't have id and
    AutoCompleteTagCreatable is setup to handle this to compensate
  */
  const [tags, setTags] = useState(() => form?.tags?.map((tag) => ({ id: tag, name: tag } as Tag)) || [])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const dataCopy = { ...form }

    // filter and normalize phones - if editing a phoneList. It is possible to have a mix of PhoneMap and PhoneMapImput types
    dataCopy.phones =
      dataCopy?.phones
        ?.filter((phone) => phone?.phoneNumber)
        .map((phone: any) => {
          return { phoneNumber: phone?.phoneNumber, type: phone?.type } as PhoneMapInput
        }) || []

    // normalize tags
    dataCopy.tags = tags.map(({ name }) => name)

    //Check to ensure that a preferred address is choosen before updating
    const hasPreferred = form.addresses?.some((item) => item?.preferred)
    if (form.addresses?.length && !hasPreferred) {
      return Alert.warning('Please select a preferred address.')
    }

    try {
      await createContact.mutateAsync({ data: dataCopy as ContactInput })
      Alert.success('Contact Created')
      onClose()
      onComplete && onComplete()
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <>
      <UiDrawer
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
      >
        <UiDrawerOverlay />
        <UiDrawerContent>
          <UiDrawerCloseButton />
          <UiDrawerHeader>{'Create Contact'}</UiDrawerHeader>
          <UiDrawerBody p={8}>
            <UiCard>
              <form
                id="edit-contact-form"
                onSubmit={handleSubmit}
              >
                <SimpleGrid
                  columns={2}
                  spacing={4}
                >
                  <FormControl
                    id="firstName"
                    isRequired
                  >
                    <UiFormLabel>First Name</UiFormLabel>
                    <UiInput
                      value={form.firstName || ''}
                      name="firstName"
                      onChange={handleInput}
                      data-private
                    />
                  </FormControl>

                  <FormControl
                    id="lastName"
                    isRequired
                  >
                    <UiFormLabel>Last Name</UiFormLabel>
                    <UiInput
                      value={form.lastName || ''}
                      name="lastName"
                      onChange={handleInput}
                      data-private
                    />
                  </FormControl>

                  <FormControl
                    id="emailAddress"
                    isRequired
                  >
                    <UiFormLabel>Email</UiFormLabel>
                    <UiInput
                      type="email"
                      value={form.emailAddress || ''}
                      name="emailAddress"
                      onChange={handleInput}
                      data-private
                    />
                  </FormControl>

                  <FormControl id="title">
                    <UiFormLabel>Title</UiFormLabel>
                    <UiInput
                      value={form.title || ''}
                      name="title"
                      onChange={handleInput}
                    />
                  </FormControl>

                  <FormControl id="companyName">
                    <UiFormLabel>Company</UiFormLabel>
                    <UiInput
                      name="companyName"
                      value={form.companyName || ''}
                      onChange={handleInput}
                    />
                  </FormControl>

                  <FormControl id="tags">
                    <UiFormLabel>Tags</UiFormLabel>
                    <AutoCompleteTagsCreatable
                      inputId="tags"
                      value={tags}
                      onChange={(tags: any) => setTags([...tags])}
                      onCreate={(newTag) => setTags((prev) => [...prev, newTag])}
                    />
                  </FormControl>
                </SimpleGrid>
              </form>
            </UiCard>

            <UiCard
              data-testid="contact-edit-phones"
              my={8}
              position="relative"
            >
              <Stack
                spacing={8}
                divider={<StackDivider borderColor="gray.200" />}
              >
                {form.phones?.map((phone: any, idx: number) => (
                  <ContactEditPhoneBlock
                    key={idx}
                    phone={phone}
                    idx={idx}
                    setForm={setForm}
                    form={form}
                  />
                ))}
                <Flex justifyContent="center">
                  <UiButton
                    leftIcon={<AddIcon />}
                    onClick={addPhone}
                    w="45%"
                  >
                    Add a Phone Number
                  </UiButton>
                </Flex>
              </Stack>
            </UiCard>

            <UiCard data-testid="contact-edit-addresses">
              <Stack
                spacing={8}
                divider={<StackDivider borderColor="gray.200" />}
              >
                {form?.addresses?.filter(Boolean).map((address, idx) => {
                  return (
                    <ContactEditAddressBlock
                      key={idx}
                      address={address as AddressInput}
                      idx={idx}
                      handleEditAddress={handleEditAddress}
                      removeAddressHandler={removeAddressHandler}
                      preferredAddressHandler={preferredAddressHandler}
                    />
                  )
                })}
                <Flex justifyContent="center">
                  <UiButton
                    leftIcon={<AddIcon />}
                    onClick={() => handleEditAddress()}
                    w="45%"
                  >
                    Add an Address
                  </UiButton>
                </Flex>
              </Stack>
            </UiCard>
          </UiDrawerBody>

          <UiDrawerFooter>
            <UiDrawerFooterButtons>
              <UiButton
                colorScheme="gray"
                onClick={onClose}
              >
                Cancel
              </UiButton>
              <UiButton
                form="edit-contact-form"
                type="submit"
                isDisabled={createContact.isLoading}
                isLoading={createContact.isLoading}
              >
                Create Contact
              </UiButton>
            </UiDrawerFooterButtons>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
      <UiConfirm
        title="Remove Address"
        isOpen={removeAddress.isOpen}
        onConfirm={handleRemoveAddress}
        onClose={removeAddress.onClose}
        buttonColor="red"
        buttonText="Remove"
      >
        <Text>
          Are you sure you want to <strong>Remove</strong> this address?
        </Text>
      </UiConfirm>

      <UiConfirm
        title="Set Default Address"
        isOpen={setPreferredAddress.isOpen}
        onConfirm={handleMakePreferred}
        onClose={setPreferredAddress.onClose}
        buttonColor="red"
        buttonText="Confirm"
      >
        <Text>
          Are you sure you want to make this address <strong>Preferred</strong>?
        </Text>
      </UiConfirm>
      {editAddress.isOpen && (
        <ContactAddressEdit
          selectedIndx={addressTabIndex}
          setFormAddresses={setForm}
          addresses={(form?.addresses as AddressInput[]) || []}
          onClose={handleClose}
          isOpen={editAddress.isOpen}
        />
      )}
    </>
  )
}
