import { Box, Divider, Flex, Stack } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import {
  UiFormControl,
  UiToggle,
  useAlerts,
  ZCard,
  ZCardHeader,
  ZFormLabel,
  ZInfoTooltip,
  ZSelect,
} from '@postal-io/postal-ui'
import type { Account, UpdateAccountInput } from 'api'
import { AutoAction, GetAccountDocument, UpdateAccountDocument } from 'api'
import { useAcl } from 'hooks'
import type { ChangeEvent } from 'react'
import React, { useEffect } from 'react'
import { useImmer } from 'use-immer'

export const AccountSettingsConfigBlock: React.FC<UiCardProps> = (props) => {
  const Alert = useAlerts()
  const { hasFeature } = useAcl()

  const hasMagicLinkAutoApproval = hasFeature('manuallyApproveLinks')
  const hasRedactContactAddresses = hasFeature('hideContacts')

  const getAccountQuery = useGraphqlQuery(GetAccountDocument)
  const { id, accountContactSettings, linkApprovalSettings } = getAccountQuery?.data?.getAccount ?? ({} as Account)

  const [form, setForm] = useImmer<Record<string, any>>({})

  useEffect(() => {
    if (getAccountQuery?.data?.getAccount?.linkApprovalSettings) {
      setForm((draft) => {
        draft.linkApprovalSettings = linkApprovalSettings
        draft.accountContactSettings = accountContactSettings
      })
    }
  }, [getAccountQuery?.data?.getAccount]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateAccount = useGraphqlMutation(UpdateAccountDocument)

  const handleChangeContactSettings = async ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
    setForm((draft) => {
      draft.accountContactSettings = { ...accountContactSettings, [name]: checked }
    })

    const data = { accountContactSettings: { ...accountContactSettings, [name]: checked } }

    saveChanges({ data })
  }

  const handleAutoApproveSelect = async ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    setForm((draft) => {
      draft.linkApprovalSettings = {
        autoActionDaysAfter: Number(value),
      }
    })

    const data = {
      linkApprovalSettings: {
        autoActionDaysAfter: Number(value),
        autoAction: Number(value) === 0 ? AutoAction.DoNothing : AutoAction.Approve,
      },
    }

    saveChanges({ data })
  }

  const saveChanges = async ({ data }: { data: UpdateAccountInput }) => {
    try {
      await updateAccount.mutateAsync({ id, data }).then(() => {
        Alert.success('Changes Saved')
      })
    } catch (e) {
      Alert.error(e)
    }
  }

  if (!hasMagicLinkAutoApproval && !hasRedactContactAddresses) return null

  return (
    <ZCard
      pos="relative"
      variant="form"
      {...props}
    >
      <ZCardHeader p={8}>Account Configurations</ZCardHeader>

      <form>
        <Stack
          spacing={8}
          divider={<Divider my={0} />}
          px={8}
          pb={8}
        >
          {hasMagicLinkAutoApproval && (
            <UiFormControl>
              <ZFormLabel
                htmlFor="autoApproveSelect"
                fontSize="body-md"
              >
                MagicLink Auto Approve
              </ZFormLabel>
              <ZSelect
                id="autoApproveSelect"
                onChange={handleAutoApproveSelect}
                value={form.linkApprovalSettings?.autoActionDaysAfter}
              >
                <option value="0">Never</option>
                <option value="2">After 2 Business Days</option>
                <option value="5">After 5 Business Days</option>
                <option value="10">After 10 Business Days</option>
              </ZSelect>
            </UiFormControl>
          )}
          {hasRedactContactAddresses && (
            <Box>
              <UiFormControl id="redactAddress">
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ZFormLabel m={0}>
                    Redact Contact addresses
                    <ZInfoTooltip label="When toggled on, all Contact addresses will appear blurred and will be non-editable for Users and Managers. Admins can still view and edit Contact addresses." />
                  </ZFormLabel>
                  <UiToggle
                    size="lg"
                    name="redactAddress"
                    isChecked={!!form.accountContactSettings?.redactAddress}
                    isDisabled={getAccountQuery.isLoading}
                    onChange={handleChangeContactSettings}
                    colorScheme="atomicBlue"
                  />
                </Flex>
              </UiFormControl>
            </Box>
          )}
        </Stack>
      </form>
    </ZCard>
  )
}
