import type { FlexProps, GridProps } from '@chakra-ui/react'
import { Flex, Grid } from '@chakra-ui/react'
import type { ZTextProps } from '@postal-io/postal-ui'
import { ZText } from '@postal-io/postal-ui'

export const SummaryLabel: React.FC<ZTextProps> = ({ children, ...rest }) => {
  return (
    <ZText
      fontSize="md"
      fontWeight="bold"
      {...rest}
    >
      {children}
    </ZText>
  )
}

interface SummaryColProps extends FlexProps {
  idx?: number
}

export const SummaryCol: React.FC<SummaryColProps> = ({ children, idx, ...rest }) => {
  const bg = (idx ?? 1) % 2 === 0 ? 'atomicGray.10' : 'none'

  const borderWidth = (idx ?? 1) % 2 === 0 ? 0 : '1px'
  return (
    <Flex
      w="100%"
      minW="225px"
      justifyContent="flex-start"
      flexDir="column"
      px={6}
      py={6}
      bg={idx !== undefined ? bg : 'unset'}
      borderColor="atomicGray.300"
      borderLeftWidth={idx !== undefined ? borderWidth : 0}
      borderRightWidth={idx !== undefined ? borderWidth : 0}
      fontWeight="normal"
      fontSize="md"
      {...rest}
    >
      {children}
    </Flex>
  )
}

interface SummaryRowProps extends GridProps {
  columns: number
}

export const SummaryRow: React.FC<SummaryRowProps> = ({ columns, children }) => {
  return (
    <Grid
      templateColumns={`repeat(${columns + 1 || 0}, 1fr)`}
      gridColumnGap={4}
      justifyItems="center"
      w="min-content"
    >
      {children}
    </Grid>
  )
}

interface SummaryInfoProps {
  data: any
}

export const SummaryInfo: React.FC<SummaryInfoProps> = ({ data }) => {
  return (
    <>
      {Object.entries(data)?.map(([k, v]) => (
        <Grid
          key={k}
          templateColumns="4fr 1fr"
          w="100%"
          alignItems="center"
          fontWeight="normal"
          _notLast={{
            pb: 2,
          }}
        >
          <ZText fontSize="sm">{k}</ZText>
          <ZText
            fontSize="sm"
            justifySelf="end"
          >
            {v as any}
          </ZText>
        </Grid>
      ))}
    </>
  )
}
