import { Box, Flex, FormControl, Grid, InputGroup, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButtonScrollTop,
  UiCard,
  UiSkeleton,
  useAlertError,
  useColor,
  useInfiniteScroll,
  ZButton,
  ZInput,
  ZInputLeftIcon,
  ZText,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, ApprovedPostalFilterInput, Currency } from 'api'
import { MagicEventStatus, SearchApprovedPostalsDocument, Status } from 'api'
import { MarketplaceCardPostal } from 'components/Marketplace/MarketplaceCardPostal'
import { CATEGORY } from 'components/Postals'
import { ApprovedPostalsEmpty } from 'components/Postals/ApprovedPostalsEmpty'
import { MARKETPLACE_CARD_MIN_WIDTH } from 'components/Postals/data'
import { usePostalFilters } from 'hooks'
import { cloneDeep, isEmpty, some } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { MdFilterList, MdKeyboardArrowLeft, MdOutlineSearch } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { ExtFilter } from '../Common'
import { ExtHeader } from '../Main/ExtHeader'

interface ExtSelectItemContentProps {
  category?: string
  selectedPostal?: ApprovedPostal
  onSelect?: (payload: any) => void
  approvedCurrencies?: Currency[]
}

const EXCLUDE_FILTERS = ['status', 'eventStatus']
const LIMIT = 20

export const ExtSelectItemContent: React.FC<ExtSelectItemContentProps> = ({
  category,
  // selectedPostal,
  onSelect,
  approvedCurrencies,
}) => {
  const { colorCode } = useColor()
  const panelFilter = useDisclosure()
  // set initial filters
  const initialFilters = useMemo(() => ({ categories: category ? [category] : undefined }), [category])

  // filters will override these
  const defaultVariables = useMemo(() => {
    const variables: ApprovedPostalFilterInput = { status: { eq: Status.Active } }
    if (!isEmpty(approvedCurrencies)) {
      variables.currency = { in: approvedCurrencies }
    }
    return variables
  }, [approvedCurrencies])

  const filter = usePostalFilters<ApprovedPostalFilterInput>({
    initialFilters,
    defaultVariables,
  })

  // if we pick the event category, and only the event category,
  // then make sure we only look for accepting invites
  //
  // if we pick multiple categories, we are unable to hide these and
  // return results from the other categories
  const graphqlFilter = useMemo(() => {
    const categories = filter.graphqlFilter?.category?.in || []
    if (categories.length === 1 && categories[0] === CATEGORY.Events) {
      const newFilter = cloneDeep(filter.graphqlFilter)
      newFilter.event_status = { eq: MagicEventStatus.AcceptingInvites }
      return newFilter
    } else {
      return filter.graphqlFilter
    }
  }, [filter.graphqlFilter])

  useEffect(() => {
    if (category) filter.updateFilter('category', category)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category])

  // debounce filter to accomodate typing input
  const [debouncedFilter] = useDebounce(graphqlFilter, 400)

  // load approved postals
  const searchPostals = useGraphqlInfiniteQuery(SearchApprovedPostalsDocument, {
    filter: debouncedFilter,
    limit: LIMIT,
  })
  useAlertError(searchPostals.error)

  // we are filtering extra events in here in case they couldn't be filtered
  // in the graphql query
  const postals = useMemo(
    () =>
      searchPostals.mergedData?.searchApprovedPostals?.filter((p) => {
        // no active variants => out of stock
        if (!some(p.variants?.map((v) => v.status === Status.Active))) return false
        return !p.event?.status || p.event.status === MagicEventStatus.AcceptingInvites
      }) ?? [],
    [searchPostals.mergedData?.searchApprovedPostals]
  )

  //Check to see if user has at least one postal
  const hasOneVariables = useMemo(() => {
    return {
      limit: 1,
      filter: { status: { eq: Status.Active }, currency: { in: approvedCurrencies } },
    }
  }, [approvedCurrencies])

  const hasOneQuery = useGraphqlQuery(SearchApprovedPostalsDocument, hasOneVariables)
  const hasOneItem = useMemo(
    () => !hasOneQuery.data?.searchApprovedPostals?.length,
    [hasOneQuery.data?.searchApprovedPostals?.length]
  )

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchPostals.hasNextPage,
    loadMore: searchPostals.fetchNextPage,
    loading: searchPostals.isFetching,
  })

  // const subnavFilters = omit(filter.filters, 'status')

  return (
    <>
      <Box>
        <UiSkeleton isLoaded={!hasOneQuery.isLoading}>
          {hasOneItem ? (
            <ApprovedPostalsEmpty />
          ) : (
            <Box>
              <FormControl
                id="search"
                mb={5}
              >
                {/* <SidePanelHeader
                  canClear={!!filter.filters?.q}
                  onClear={() => filter.updateFilter('q', '')}
                  title="Search"
                /> */}
                <InputGroup>
                  <ZInputLeftIcon
                    icon={
                      <MdOutlineSearch
                        size="16px"
                        color={colorCode('atomicGray.500')}
                      />
                    }
                  />
                  <ZInput
                    name="search"
                    placeholder="Search"
                    value={filter.filters?.q ?? ''}
                    onChange={(e: any) => filter.updateFilter('q', e.target.value)}
                  />
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    columnGap="5px"
                    cursor="pointer"
                    position="absolute"
                    right="10px"
                    top="12px"
                    onClick={panelFilter.onOpen}
                    color="atomicGray.500"
                  >
                    {Object.keys(filter.filters).length !== 0 && (
                      <Box
                        bg="atomicBlue.400"
                        width="18px"
                        height="18px"
                        borderRadius="9px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <ZText color="white">{Object.keys(filter.filters).length}</ZText>
                      </Box>
                    )}
                    <MdFilterList size="16px" />
                  </Flex>
                </InputGroup>
              </FormControl>
              {panelFilter.isOpen && (
                <ExtFilter
                  isOpen
                  onClose={panelFilter.onClose}
                  filterType="ApprovedPostal"
                  filters={filter.filters}
                  onUpdate={filter.updateFilter}
                  excludeFilters={EXCLUDE_FILTERS}
                  approvedCurrencies={approvedCurrencies}
                  bottomBlock={
                    <Flex columnGap="10px">
                      <ZButton
                        colorScheme="atomicBlue"
                        borderRadius="3px"
                        onClick={() => panelFilter.onClose()}
                      >
                        Show {postals ? (postals.length === 0 ? 0 : postals.length + '+') : 0} Items
                      </ZButton>
                      {Object.keys(filter.filters).length !== 0 && (
                        <ZButton
                          variant="ghost"
                          colorScheme="atomicGray"
                          borderRadius="3px"
                          onClick={() => filter.clearFilters()}
                        >
                          Clear All
                        </ZButton>
                      )}
                    </Flex>
                  }
                />
              )}
              {/* <SidePanelFilter
                position="sticky"
                top="100px"
                filterType="ApprovedPostal"
                filters={filter.filters}
                onUpdate={filter.updateFilter}
                excludeFilters={EXCLUDE_FILTERS}
                approvedCurrencies={approvedCurrencies}
                showDraft
              /> */}
              <Flex
                direction="column"
                w="100%"
                ref={topRef}
                style={{ scrollMargin: '20px' }}
              >
                {!searchPostals.isFetching && postals.length < 1 && <ZText fontSize="md">No available items</ZText>}
                <Grid
                  templateColumns={`repeat(auto-fill, minmax(150px, 1fr))`}
                  columnGap={8}
                  rowGap={4}
                >
                  {postals.map((item: any) => (
                    <MarketplaceCardPostal
                      key={item.id}
                      postal={item}
                      buttonText="Select this Item"
                      hideUtilityButtons
                      onSelect={onSelect}
                    />
                  ))}

                  {searchPostals.isFetching && postals.length < 1 && (
                    <UiCard
                      isLoading
                      h={MARKETPLACE_CARD_MIN_WIDTH}
                      w={MARKETPLACE_CARD_MIN_WIDTH}
                    />
                  )}
                </Grid>
                {(searchPostals.mergedData?.searchApprovedPostals?.length ?? 0) >= LIMIT && (
                  <Box
                    ref={bottomRef}
                    mb={8}
                    position="relative"
                  >
                    <UiButtonScrollTop
                      onClick={scrollTop}
                      position="absolute"
                      top="0px"
                      right="0px"
                      isLoading={searchPostals.isFetching}
                      aria-label="scroll button"
                    />
                  </Box>
                )}
              </Flex>
            </Box>
          )}
        </UiSkeleton>
      </Box>
    </>
  )
}

export const ExtSelectItem: React.FC = () => {
  const navigate = useNavigate()
  return (
    <Box p={4}>
      <ExtHeader mb={4}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="30px"
          height="30px"
          bg="atomicGray.50"
          borderRadius="50%"
          _hover={{ bg: 'atomicGray.100', cursor: 'pointer' }}
          onClick={() => navigate('/extension/magiclinks')}
        >
          <MdKeyboardArrowLeft
            size="16px"
            color="#8492A6"
          />
        </Box>
      </ExtHeader>
      <ExtSelectItemContent onSelect={(postal) => navigate(`/extension/link/${postal.id}`)} />
    </Box>
  )
}
