import { Text } from '@chakra-ui/react'
import type { UiAlertProps, UiLinkProps } from '@postal-io/postal-ui'
import { UiAlert, UiLink } from '@postal-io/postal-ui'
import type { Currency } from 'api'
import { ZAlert } from 'components/Common/ZComponents'
import { useSession } from 'hooks'
import React from 'react'
import { Link } from 'react-router-dom'

const HELP_URL = 'https://help.postal.com/helpcenter/s/article/How-to-Link-a-Team-to-Multiple-Billing-Accounts'

const HelpLink: React.FC<UiLinkProps> = (props) => {
  return (
    <UiLink
      isExternal
      href={HELP_URL}
      {...props}
    >
      Learn More
    </UiLink>
  )
}

const BillingLink: React.FC<UiLinkProps> = (props) => {
  const { session } = useSession()
  const accountId = session.teamId || 'default'
  return (
    <UiLink
      as={Link}
      to={`/teams/${accountId}/settings`}
      {...props}
    >
      billing account
    </UiLink>
  )
}

export interface CurrencyAlertProps extends Omit<UiAlertProps, 'status'> {
  currency: Currency
  status?: UiAlertProps['status']
}

export const CurrencyAlert: React.FC<CurrencyAlertProps> = ({ currency, status = 'info' }) => {
  return (
    <UiAlert
      status={status}
      hideClose
    >
      <Text>
        This item is sent using <strong>{currency}</strong> to send. Please attach a <BillingLink /> for this currency
        to your team and load funds before sending. <HelpLink />
      </Text>
    </UiAlert>
  )
}

interface TeamCurrencyAlertProps extends Omit<UiAlertProps, 'status'> {
  currency: Currency
  status?: UiAlertProps['status']
}
export const TeamCurrencyAlert: React.FC<TeamCurrencyAlertProps> = ({ currency, status = 'info', ...rest }) => {
  return (
    <ZAlert
      status={status}
      hideClose
      {...rest}
    >
      This item is sent using <strong>{currency}</strong> currency. Some of the teams you selected will need access to
      this currency before being able to see the item and send it. <HelpLink />
    </ZAlert>
  )
}
