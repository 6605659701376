import type { WrapProps } from '@chakra-ui/react'
import { useDisclosure, Wrap, WrapItem } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import { UiSSDataTable, UiTag, ZFlowButton } from '@postal-io/postal-ui'
import React, { useMemo } from 'react'
import { MdAdd, MdRemove } from 'react-icons/md'
import { SearchContactsV2Document } from '../../api'
import { BASIC_COLUMNS } from '../MultiSelectContacts/data'
import type { PostalSendContext } from './usePostalSend'

const LIMIT = 20
const NUM_TAGS = 10
export interface PostalReviewContactsV2Props extends WrapProps {
  context: PostalSendContext
}
export const PostalReviewContacts: React.FC<PostalReviewContactsV2Props> = ({ context, ...rest }) => {
  const viewContacts = useDisclosure()

  const searchContacts = useGraphqlInfiniteQuery(SearchContactsV2Document, {
    orfilters: context.contacts?.orfilters,
    limit: LIMIT,
  })

  const contacts = useMemo(
    () => searchContacts?.mergedData?.searchContactsV2?.searchableContacts || [],
    [searchContacts?.mergedData?.searchContactsV2?.searchableContacts]
  )

  const totalRecords = useMemo(
    () => searchContacts.mergedData?.searchContactsV2?.resultsSummary?.totalRecords,
    [searchContacts.mergedData?.searchContactsV2?.resultsSummary?.totalRecords]
  )
  return (
    <>
      <Wrap
        spacing={4}
        data-testid="PostalReview_contacts"
        {...rest}
      >
        {contacts.slice(0, NUM_TAGS).map((contact, idx) => (
          <WrapItem key={idx}>
            <UiTag
              bg="atomicGray.10"
              w="max-content"
              justifyContent="center"
              borderRadius="100px"
              fontSize="xs"
            >{`${contact.firstName} ${contact.lastName}`}</UiTag>
          </WrapItem>
        ))}
      </Wrap>

      {contacts.length <= NUM_TAGS ? null : (
        <ZFlowButton
          mt={5}
          bg="atomicBlue.10"
          color="atomicBlue.900"
          _hover={{ bg: 'atomicBlue.50' }}
          _active={{ bg: 'atomicBlue.100' }}
          leftIcon={viewContacts.isOpen ? <MdRemove /> : <MdAdd />}
          onClick={viewContacts.onToggle}
        >
          {viewContacts.isOpen ? 'Hide' : 'Show'} all
        </ZFlowButton>
      )}

      {viewContacts.isOpen && (
        <UiSSDataTable
          boxShadow="none"
          mt={5}
          w="100%"
          isLoading={searchContacts.isFetching}
          columns={BASIC_COLUMNS}
          rows={contacts}
          rowKey="id"
          hasMore={searchContacts.hasNextPage}
          fetchMore={searchContacts.fetchNextPage}
          filter={context.contacts?.orfilters}
          totalRecords={totalRecords}
        />
      )}
    </>
  )
}
