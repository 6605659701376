import { Box, Flex, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import {
  GraphqlFilterTransform,
  UiButtonScrollTop,
  useGraphqlFilter,
  useInfiniteScroll,
  ZButton,
  ZCard,
  ZCardBody,
  ZInputSearch,
  ZLink,
  ZSidebar,
  ZSidebarBanner,
  ZText,
} from '@postal-io/postal-ui'
import type { MessageTemplate, SearchMessageTemplatesQueryVariables } from 'api'
import { SearchMessageTemplatesDocument } from 'api'
import { CenteredBox } from 'components/Common'
import { MessagesBrick, MessagesDelete, MessagesEditCreate } from 'components/Messages'
import { PageTitle, useAcl, useSession } from 'hooks'
import { set } from 'lodash'
import React, { useMemo, useState } from 'react'
import { MdOutlineMarkEmailRead } from 'react-icons/md'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

const LIMIT = 10
const transforms = {
  name: GraphqlFilterTransform.Contains,
  templateText: GraphqlFilterTransform.Contains,
}

export const SavedMessages: React.FC = () => {
  const { hasPermission } = useAcl()
  const { session } = useSession()
  const canUpdate = hasPermission('messages.update')
  const canDelete = hasPermission('messages.delete')

  const staticVariables = useMemo(() => {
    const vars = { limit: LIMIT, filter: {} } as SearchMessageTemplatesQueryVariables
    if (session.teamId) {
      set(vars, 'filter.sharedWith.eq', `TM${session.teamId}`)
    }
    return vars
  }, [session.teamId])

  const graphqlFilter = useGraphqlFilter<SearchMessageTemplatesQueryVariables>({
    transforms,
    debounce: 400,
    staticVariables,
  })

  const searchMessages = useGraphqlInfiniteQuery(SearchMessageTemplatesDocument, graphqlFilter.variables)
  const messages = useMemo(() => {
    return searchMessages.mergedData?.searchMessageTemplates || []
  }, [searchMessages.mergedData?.searchMessageTemplates])

  const [selectedMessage, setSelectedMessage] = useState<MessageTemplate | undefined>()
  const createMessage = useDisclosure()
  const deleteMessage = useDisclosure()

  const handleCloseEditCreate = () => {
    createMessage.onClose()
    setSelectedMessage(undefined)
  }

  const handleEdit = (msg: MessageTemplate): void => {
    setSelectedMessage(msg)
    createMessage.onOpen()
  }

  const handleDelete = (msg: MessageTemplate): void => {
    setSelectedMessage(msg)
    deleteMessage.onOpen()
  }

  const handleCloseDelete = () => {
    deleteMessage.onClose()
    setSelectedMessage(undefined)
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    graphqlFilter.setFilter({ key: name, value })
  }

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchMessages.hasNextPage,
    loadMore: searchMessages.fetchNextPage,
    loading: searchMessages.isFetching,
  })
  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner
          title="Saved Messages"
          actions={
            <ZButton
              variant="link"
              color="atomicBlue.400"
              leftIcon={<MdOutlineMarkEmailRead size="24px" />}
              size="sm"
              height="21px"
              onClick={createMessage.onOpen}
            >
              Create Message
            </ZButton>
          }
        />
        <PageTitle title="Branding" />
        <Box>
          <Flex
            justifyContent="space-between"
            gap={8}
            mb={8}
          >
            <ZInputSearch
              minW="300px"
              type="search"
              name="name"
              onChange={handleInput}
              value={graphqlFilter.filter.name || ''}
              placeholder="Search Template Name"
              aria-label="Search Template Name"
            />
            <ZInputSearch
              minW="300px"
              type="search"
              name="templateText"
              onChange={handleInput}
              value={graphqlFilter.filter.templateText || ''}
              placeholder="Search Template Body"
              aria-label="Search Template Body"
            />
          </Flex>
          <Box
            ref={topRef}
            style={{ scrollMargin: '20px' }}
          />
          {!messages.length && (
            <ZCard variant="form">
              <ZCardBody
                p={8}
                display="flex"
                justifyContent="center"
              >
                <ZText fontSize="lg">
                  No messages found. Would you like to{' '}
                  <ZLink
                    onClick={createMessage.onOpen}
                    color="atomicBlue.400"
                    fontSize="inherit"
                  >
                    create
                  </ZLink>{' '}
                  one?
                </ZText>
              </ZCardBody>
            </ZCard>
          )}
          <SimpleGrid
            minChildWidth="500px"
            spacing={8}
            mb={4}
          >
            {messages.map((message) => (
              <MessagesBrick
                key={message.id}
                isAdmin={canUpdate}
                message={message}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            ))}
          </SimpleGrid>
        </Box>
        {messages.length >= LIMIT && (
          <Box
            ref={bottomRef}
            position="relative"
          >
            <UiButtonScrollTop
              onClick={scrollTop}
              position="absolute"
              top="0"
              right="0"
              isLoading={searchMessages.isFetching}
              aria-label="scroll button"
            />
          </Box>
        )}
        {canUpdate && createMessage.isOpen && (
          <MessagesEditCreate
            isOpen={createMessage.isOpen}
            onClose={handleCloseEditCreate}
            message={selectedMessage}
          />
        )}
        {canDelete && deleteMessage.isOpen && (
          <MessagesDelete
            isOpen={deleteMessage.isOpen}
            onClose={handleCloseDelete}
            messageId={selectedMessage?.id}
          />
        )}
      </ZSidebar>
    </CenteredBox>
  )
}
