import { useAlerts } from '@postal-io/postal-ui'
import React from 'react'
import type { CalendarProvider } from '../api'
import api from '../api/rest'
import type { ExternalProvider } from '../components/Integrations'
import { ExternalProviders, ExternalProvidersV2 } from '../components/Integrations'
import { useAcl } from './useAcl'
import { useMe } from './useMe'

export type ExternalCalendarProvider = ExternalProvider & { calendar: CalendarProvider }

export const useCalendarIntegrations = () => {
  const { aclCheck } = useAcl()
  const Alert = useAlerts()
  const { me } = useMe()

  const availableCalendarProviders = React.useMemo(() => {
    const calendars = ExternalProviders.filter((p) => !!p.calendar?.enabled && aclCheck(p.calendar))
    return (calendars || []) as ExternalCalendarProvider[]
  }, [aclCheck])

  const availableCalendarProvidersV2 = React.useMemo(() => {
    const calendars = ExternalProvidersV2.filter((p) => !!p.calendar?.enabled && aclCheck(p.calendar))
    return (calendars || []) as ExternalCalendarProvider[]
  }, [aclCheck])

  const getCalendarProvider = React.useCallback(
    (provider?: CalendarProvider) => {
      return availableCalendarProviders.find((p) => p.calendar.name === provider)
    },
    [availableCalendarProviders]
  )

  // is integration enabled
  const syncEnabled = (provider?: ExternalCalendarProvider) => {
    if (!provider?.calendar.authType) return false
    return me?.authTypes?.includes(provider.calendar.authType)
  }

  // enable integration
  const [syncLoading, setSyncLoading] = React.useState(false)
  const enableSync = async (provider?: ExternalCalendarProvider) => {
    if (!provider?.calendar.endpoint) return
    try {
      setSyncLoading(true)
      const res = await api.oathPreconnect(provider.calendar.endpoint)
      if (res?.target) {
        window.location.href = res?.target
      } else {
        window.location.reload()
      }
    } catch (e) {
      setSyncLoading(false)
      Alert.error(e)
    }
  }

  return {
    availableCalendarProviders,
    availableCalendarProvidersV2,
    getCalendarProvider,
    enableSync,
    syncEnabled,
    syncLoading,
  }
}
