import { ZText } from '@postal-io/postal-ui'
import { useSendAs } from 'hooks'
import React from 'react'
import type { PostalSendContext } from './usePostalSend'

export interface PostalReviewSendAsV2Props {
  context: PostalSendContext
}

export const PostalReviewSendAs: React.FC<PostalReviewSendAsV2Props> = ({ context }) => {
  const { sendAsContactOwner, sendAsUser } = context
  const { label } = useSendAs({ sendAsContactOwner, sendAsUser })
  return (
    <>
      <ZText>{label}</ZText>
    </>
  )
}
