import type { UiSelectTypeaheadProps } from '@postal-io/postal-ui'
import { SelectTypeaheadStylesV2, UiSelectTypeahead, useAlertError } from '@postal-io/postal-ui'
import { useAcl, useUserOmniSearch } from 'hooks'
import { getUserString } from 'lib'
import { set } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import type { Role, User } from '../../api'
import { Status } from '../../api'

export type AutoCompleteUserV2Props = UiSelectTypeaheadProps<User> & { roles?: Role[] }

export function AutoCompleteUser(props: AutoCompleteUserV2Props) {
  const { onInputChange, isDisabled, roles, ...rest } = props

  const { hasPermission } = useAcl()
  const canRead = hasPermission('users.query')

  const [search, setSearch] = useState(props.inputValue || props.defaultInputValue || '')
  const [debounced] = useDebounce(search, 400)

  const baseQuery = useMemo(() => {
    const query = {
      filter: {
        status: { eq: Status.Active },
      },
      limit: 20,
    }
    if (roles && roles.length) {
      set(query, 'filter.productAccess.roles.in', roles)
    }
    return query
  }, [roles])

  const query = useUserOmniSearch(debounced, baseQuery)
  useAlertError(query.error)

  const handleInput = useCallback(
    (val: any, meta: any) => {
      setSearch(val)
      props.onInputChange && props.onInputChange(val, meta)
    },
    [props]
  )

  return (
    <UiSelectTypeahead
      data-testid="AutoCompleteUser"
      options={query.results}
      getOptionLabel={getUserString}
      getOptionValue={(t) => t.id}
      onInputChange={handleInput}
      isLoading={query.isLoading}
      placeholder="Search Users"
      noOptionsMessage={() => 'No Users Found'}
      isDisabled={props.isDisabled || !canRead}
      {...SelectTypeaheadStylesV2}
      {...rest}
    />
  )
}
