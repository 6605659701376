import { Box } from '@chakra-ui/react'
import type { UiDialogProps } from '@postal-io/postal-ui'
import {
  FontWeight,
  ZButton,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import React from 'react'

export interface BudgetHelpProps {
  status?: UiDialogProps['status']
  isOpen: boolean
  onClose: () => void
}

export const BudgetModeHelp = {
  PerUser: `Each team member has their own individual budget and they can spend until their
  budget is reached or the available balance is empty.`,
  Pooled: `All team members share a budget and each member can spend until either the shared budget is
  reached or the available balance is empty.`,
  Unlimited: `There is no budget and team members can spend until the available balance is empty.`,
}

export const BudgetHelp: React.FC<BudgetHelpProps> = ({ isOpen, onClose }) => {
  return (
    <ZModal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
    >
      <ZModalOverlay />
      <ZModalContent>
        <ZModalHeader>Budget Modes</ZModalHeader>
        <ZModalCloseButton />
        <ZModalBody pb={8}>
          <ZText
            fontSize="md"
            color="atomicGray.600"
          >
            Budgets are used to limit user spending.
          </ZText>
          <Box as="dl">
            <Box
              as="dt"
              fontWeight={FontWeight.Bold}
              mt={4}
            >
              Disabled
            </Box>
            <Box
              as="dd"
              ml={4}
            >
              {BudgetModeHelp.Unlimited}
            </Box>
            <Box
              as="dt"
              fontWeight={FontWeight.Bold}
              mt={4}
            >
              Pooled
            </Box>
            <Box
              as="dd"
              ml={4}
            >
              {BudgetModeHelp.Pooled}
            </Box>
            <Box
              as="dt"
              fontWeight={FontWeight.Bold}
              mt={4}
            >
              Per User
            </Box>
            <Box
              as="dd"
              ml={4}
            >
              {BudgetModeHelp.PerUser}
            </Box>
          </Box>
        </ZModalBody>
        <ZModalFooter justifyContent="flex-end">
          <ZButton
            variant="ghost"
            colorScheme="atomicGray"
            minW="140px"
            onClick={onClose}
          >
            Close
          </ZButton>
        </ZModalFooter>
      </ZModalContent>
    </ZModal>
  )
}
