import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import { UiSSDataTable, useAlertError } from '@postal-io/postal-ui'
import { CampaignOrderByInput, CampaignType, GetContactDocument, SearchCampaignsDocument } from 'api'
import { zMultiSelectStyles } from 'components/Common/ZComponents'
import { ALL_CAMPAIGNS_COLUMNS } from 'components/Orders/ordersColumns'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

const LIMIT = 100
const PAGE_SIZE = 10
interface ContactCampaignsV2Props extends UiCardProps {
  contactId: string | undefined
  pageSize?: number
}

export const ContactCampaigns: React.FC<ContactCampaignsV2Props> = ({ contactId, pageSize = PAGE_SIZE }) => {
  const navigate = useNavigate()

  const { data: contactData, error: contactError } = useGraphqlQuery(GetContactDocument, { id: contactId as string })
  const campaignIds = contactData?.getContact?.campaigns?.map((c) => c.campaignId) || []

  const { mergedData, error, hasNextPage, fetchNextPage, isFetching } = useGraphqlInfiniteQuery(
    SearchCampaignsDocument,
    {
      filter: { id: { in: campaignIds }, campaignType: { in: [CampaignType.Engage, null] } },
      orderBy: CampaignOrderByInput.CreatedDesc,
      limit: LIMIT,
    },
    { enabled: !!campaignIds.length }
  )
  const campaigns = useMemo(() => mergedData?.searchCampaigns || [], [mergedData?.searchCampaigns])

  const onClickItemName = useCallback((id: string) => navigate(`/orders/group/${id}`), [navigate])

  const rows = useMemo(() => campaigns.map((c) => ({ ...c, onClickItemName })), [campaigns, onClickItemName])

  useAlertError(error)
  useAlertError(contactError)

  return (
    <>
      <UiSSDataTable
        mx={-6}
        width="calc(100% + 48px)"
        variant="list"
        columns={ALL_CAMPAIGNS_COLUMNS}
        rows={rows}
        rowKey="id"
        hasMore={hasNextPage}
        isLoading={isFetching}
        fetchMore={fetchNextPage}
        pageSize={pageSize}
        sx={zMultiSelectStyles}
      />
      {/* {campaigns.length > 0 && (
        <Box mt={4}>
          <UiLink
            fontFamily="Lexend"
            color="atomicBlue.400"
            as={Link}
            to="/campaigns"
          >
            View All
            <ArrowForwardIcon ml={2} />
          </UiLink>
        </Box>
      )} */}
    </>
  )
}
