import { castDraft } from 'immer'
import { useCallback, useMemo } from 'react'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import { useImmer } from 'use-immer'
import { stableValueHash } from '../../lib'
import type { MultiSelectContactsState } from './MultiSelectContacts'

interface GenericItem extends Record<string, any> {
  id: string
}

type GenericFilter = Record<string, any>

export interface MultiSelectFilter<P extends GenericFilter> {
  filter: P
  totalRecords: number
}

export interface MultiSelectState<T extends GenericItem, P extends GenericFilter> {
  items: Map<string, T>
  filters: Map<string, MultiSelectFilter<P>>
}

export interface UseMultiSelectProps<T extends GenericItem, P extends GenericFilter> {
  initialItems?: T[]
  initialFilters?: MultiSelectFilter<P>[]
  onUpdate?: (data: Partial<MultiSelectContactsState>) => void
}

export interface GenerateOrfilterProps<T extends GenericItem, P extends GenericFilter> {
  items?: T[]
  filters?: MultiSelectFilter<P>[]
}
export const generateOrfilter = <T extends GenericItem, P extends GenericFilter>({
  items,
  filters,
}: GenerateOrfilterProps<T, P>) => {
  const orfilters = []
  if (items?.length) {
    const idFilter = { id: { in: items.map((c) => c.id) } } as unknown as P
    orfilters.push(idFilter)
  }
  filters?.forEach((item) => {
    if (item.totalRecords > 0) orfilters.push(item.filter)
  })
  return orfilters
}

export const useMultiSelect = <T extends GenericItem, P extends GenericFilter>(props?: UseMultiSelectProps<T, P>) => {
  const [selected, setSelected] = useImmer<MultiSelectState<T, P>>(() => {
    const state = { items: new Map(), filters: new Map() }
    props?.initialItems?.forEach((item) => state.items.set(item.id, item))
    props?.initialFilters?.forEach((filter) => state.filters.set(stableValueHash(filter), filter))
    return state
  })

  const totalRecords = useMemo(() => {
    return Array.from(selected.filters.values()).reduce((sum, filter) => {
      return sum + filter.totalRecords
    }, selected.items.size || 0)
  }, [selected])

  const orfilters: P[] = useMemo(() => {
    return generateOrfilter({
      items: Array.from(selected.items.values()),
      filters: Array.from(selected.filters.values()),
    })
  }, [selected.filters, selected.items])

  const addItem = useCallback(
    (item: T) => {
      setSelected((draft) => {
        draft.items.set(item.id, castDraft(item))
      })
    },
    [setSelected]
  )

  const removeItem = useCallback(
    (item: T) => {
      setSelected((draft) => {
        draft.items.delete(item.id)
      })
    },
    [setSelected]
  )

  const addItems = useCallback(
    (items: T[]) => {
      setSelected((draft) => {
        items.forEach((item) => draft.items.set(item.id, castDraft(item)))
      })
    },
    [setSelected]
  )

  const removeItems = useCallback(
    (items: T[]) => {
      setSelected((draft) => {
        items.forEach((item) => draft.items.delete(item.id))
      })
    },
    [setSelected]
  )

  const clearItems = useCallback(() => {
    setSelected((draft) => {
      draft.items.clear()
    })
  }, [setSelected])

  const getFilter = useCallback(
    (filter: P | undefined | null) => {
      return selected.filters.get(stableValueHash(filter || {}))
    },
    [selected.filters]
  )

  const addFilter = useCallback(
    (filter: P | undefined | null, totalRecords: number) => {
      const newFilter = castDraft(filter || ({} as P))
      setSelected((draft) => {
        draft.filters.set(stableValueHash(newFilter), { filter: newFilter, totalRecords })
      })
    },
    [setSelected]
  )

  const removeFilter = useCallback(
    (filter: P | undefined | null) => {
      setSelected((draft) => {
        draft.filters.delete(stableValueHash(filter || {}))
      })
    },
    [setSelected]
  )

  const clearFilters = useCallback(() => {
    setSelected((draft) => {
      draft.filters.clear()
    })
  }, [setSelected])

  const clear = useCallback(() => {
    setSelected((draft) => {
      draft.filters.clear()
      draft.items.clear()
    })
  }, [setSelected])

  const items = useMemo(() => {
    return Array.from(selected.items.values())
  }, [selected.items])

  const filters = useMemo(() => {
    return Array.from(selected.filters.values())
  }, [selected.filters])

  useDeepCompareEffectNoCheck(() => {
    if (!props?.onUpdate) return
    props.onUpdate({
      items,
      orfilters,
      totalRecords,
      filters,
    })
  }, [items, orfilters, totalRecords, filters])

  return {
    items,
    addItem,
    addItems,
    removeItem,
    removeItems,
    clearItems,
    filters,
    getFilter,
    addFilter,
    removeFilter,
    clearFilters,
    selected,
    totalRecords,
    orfilters,
    clear,
  }
}

export interface UseMultiSelect<T extends GenericItem, P extends GenericFilter> {
  items: T[]
  addItem: (item: T) => void
  addItems: (items: T[]) => void
  removeItem: (item: T) => void
  removeItems: (item: T[]) => void
  clearItems: () => void
  filters: MultiSelectFilter<P>[]
  getFilter: (filter: P | undefined | null) => MultiSelectFilter<P> | undefined
  addFilter: (filter: P | undefined | null, totalRecords: number) => void
  removeFilter: (filter: P | undefined | null) => void
  clearFilters: () => void
  selected: MultiSelectState<T, P>
  totalRecords: number
  orfilters: P[]
  clear: () => void
}
