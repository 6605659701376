import { UiLoading } from '@postal-io/postal-ui'
import React, { useEffect } from 'react'
import { useLogout } from '../hooks'

export const Logout: React.FC = () => {
  const logout = useLogout()

  useEffect(() => {
    logout()
  }, [logout])

  return <UiLoading />
}
