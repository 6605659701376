import type { StackProps } from '@chakra-ui/react'
import { Box, Grid, Text } from '@chakra-ui/react'
import { ZMoney, ZText } from '@postal-io/postal-ui'
import type { Budget } from 'api'
import { capitalize, startCase } from 'lodash'
import React from 'react'

export interface BudgetCompactV2Props extends StackProps {
  budget?: Budget | null
}

export const BudgetCompact: React.FC<BudgetCompactV2Props> = ({ budget, color, ...rest }) => {
  if (budget) {
    return (
      <Grid
        gridTemplateColumns="repeat(3, 80px)"
        position="relative"
        {...rest}
      >
        <ZMoney
          cents={budget?.amount}
          fontSize="sm"
          color={color}
          textAlign="left"
        />
        <ZText
          color={color}
          textAlign="center"
        >
          {capitalize(budget?.duration)}
        </ZText>
        <ZText
          color={color}
          textAlign="center"
        >
          {capitalize(startCase(budget?.mode))}
        </ZText>
        <Box
          w="95%"
          h="1px"
          bgColor={color}
          position="absolute"
          top="50%"
        />
      </Grid>
    )
  } else {
    return <Text>-</Text>
  }
}
