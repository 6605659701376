import { Flex, Portal, useDisclosure } from '@chakra-ui/react'
import { UiMenu, UiMenuButton, UiMenuList, UiTruncate, useColor, ZButton, ZText } from '@postal-io/postal-ui'
import type { ApprovedProductVariant, ProductVariant } from 'api'
import { ApprovedPostalOption } from 'components/Postal/ApprovedVariantOption'

import { MdOutlineArrowDropDown } from 'react-icons/md'

interface MarketplaceProductVariantSelectorProps {
  availableVariants: ProductVariant[]
  handleVariant: (variant: ProductVariant) => void
  selectedVariants: ProductVariant[]
  marketplaceProductId: string
  category: string
}

export const MarketplaceProductVariantSelector: React.FC<MarketplaceProductVariantSelectorProps> = ({
  availableVariants,
  handleVariant,
  selectedVariants,
  marketplaceProductId,
  category,
}) => {
  const { Color } = useColor()
  const variantDisclosure = useDisclosure()

  const isSelected = ({ id: selectedId }: { id: string }) =>
    selectedVariants.some(({ id }: ProductVariant) => id === selectedId)

  return (
    <UiMenu
      {...variantDisclosure}
      matchWidth
      placement="bottom-end"
      closeOnSelect={false}
    >
      <UiMenuButton
        as={ZButton}
        h="40px"
        pr={4}
        pl={4}
        width="100%"
        fontSize="sm"
        variant="outline"
        color="atomicGray.800"
        borderRadius={3}
        borderColor="atomicGray.200"
        _hover={{ bg: 'white', borderColor: 'atomicGray.400' }}
        rightIcon={
          <MdOutlineArrowDropDown
            viewBox="2 2 20 20"
            style={{
              color: Color('atomicGray.500'),
              transform: variantDisclosure.isOpen ? 'rotate(-180deg)' : '',
              transition: 'transform 0.2s',
            }}
          />
        }
        isDisabled={!availableVariants.length}
        onClick={variantDisclosure.onToggle}
      >
        <Flex
          width="100%"
          justifyContent="space-between"
          color="atomicGray.800"
        >
          <ZText textAlign="left">
            <UiTruncate
              text={selectedVariants
                .slice(0, 2)
                .map((v) => v.variantName)
                .join(', ')}
            />

            {selectedVariants.length > 2 ? (
              <>
                , <strong>{selectedVariants.length - 2} more</strong>
              </>
            ) : (
              selectedVariants.length === 0 && <ZText color="atomicGray.500">(No Options selected)</ZText>
            )}
          </ZText>
        </Flex>
      </UiMenuButton>
      <Portal>
        <UiMenuList
          boxShadow="0 0 15px rgba(0,0,0,.1)"
          maxH="320px"
          overflowY="scroll"
          zIndex={100}
        >
          {availableVariants.map((variant) => (
            <ApprovedPostalOption
              key={variant.id}
              variant={variant as ApprovedProductVariant}
              marketplaceProductId={marketplaceProductId}
              onClick={() => handleVariant(variant)}
              category={category}
              isSelected={isSelected(variant)}
              brandingColor="atomicBlue.400"
            />
          ))}
        </UiMenuList>
      </Portal>
    </UiMenu>
  )
}
