import { Box, Grid, Text } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  humanize,
  useAlerts,
  ZButton,
  ZInput,
  ZLogoMinimal,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
  ZModalOverlay,
  ZSelect,
} from '@postal-io/postal-ui'
import type { ExtCampaignMappingInput } from 'api'
import {
  DataListType,
  GetDataListDocument,
  GetExternalCampaignMappingDocument,
  SaveExternalCampaignMappingDocument,
} from 'api'
import { useAcl } from 'hooks'
import React, { Fragment, useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'
import { ExternalProviders } from './data'

type MAPPING_KEY =
  | 'opened'
  | 'accepted'
  | 'queued'
  | 'shipped'
  | 'delivered'
  | 'bounced'
  | 'expired'
  | 'failed'
  | 'declined'

const EVENT_MAPPING_KEYS: MAPPING_KEY[] = [
  'opened',
  'accepted',
  'queued',
  'shipped',
  'delivered',
  'bounced',
  'expired',
  'failed',
  'declined',
]

export interface AttributionMappingV2Props {
  system: string
  campaignId: string
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}
export const AttributionMapping: React.FC<AttributionMappingV2Props> = ({
  system,
  campaignId,
  isOpen,
  onOpen,
  onClose,
}) => {
  const Alert = useAlerts()
  const { hasPermission } = useAcl()
  const canUpdate = hasPermission('triggers.update')

  const [state, setState] = useImmer<ExtCampaignMappingInput>({
    opened: undefined,
    accepted: undefined,
    queued: undefined,
    shipped: undefined,
    delivered: undefined,
    bounced: undefined,
    expired: undefined,
    failed: undefined,
    declined: undefined,
  })

  // Available Status
  const getStatus = useGraphqlQuery(
    GetDataListDocument,
    {
      system,
      type: DataListType.CampaignStatuses,
      searchString: campaignId,
    },
    { enabled: canUpdate }
  )
  const statuses = useMemo(() => {
    return getStatus?.data?.getDataList.items || []
  }, [getStatus?.data?.getDataList])

  // Mapping
  const getMapping = useGraphqlQuery(
    GetExternalCampaignMappingDocument,
    { system, campaignId },
    { enabled: canUpdate, retry: 0 }
  )
  const mapping = useMemo(() => {
    return getMapping?.data?.getExternalCampaignMapping
  }, [getMapping?.data?.getExternalCampaignMapping])

  // when mapping loads, save to state
  useEffect(() => {
    if (mapping) {
      const { id, system, campaignId, ...rest } = mapping
      setState(() => rest)
    }
  }, [mapping, setState])

  // if we get an error that the campaign isn't mapped, then automatically open this
  // mapping edit dialog, because we really want the user to fill this out.
  useEffect(() => {
    if (!getMapping.isLoading && getMapping.error?.message?.includes('This campaign has not been mapped yet')) {
      onOpen()
    }
  }, [getMapping.error?.message, getMapping.isLoading, onOpen])

  const saveMapping = useGraphqlMutation(SaveExternalCampaignMappingDocument)

  const handleStatus = (key: MAPPING_KEY, status?: string) => {
    setState((draft) => {
      if (!status) {
        draft[key] = undefined
      } else {
        draft[key] = { status, active: true }
      }
    })
  }

  const handleConfirm = async () => {
    try {
      await saveMapping.mutateAsync({ system, campaignId, mapping: state })
      Alert.success('Mapping Saved')
      onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const SystemIcon = ExternalProviders.find((p) => p.system === system)?.icon

  return (
    <ZModal
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <form>
        <ZModalOverlay />
        <ZModalContent
          borderRadius="8px"
          p={8}
          pt={4}
        >
          <ZModalHeader
            textAlign="center"
            mb={4}
          >
            Edit Status Mapping
          </ZModalHeader>
          <ZModalCloseButton m={4} />
          <ZModalBody>
            <Grid
              templateColumns="2fr 1fr 2fr"
              columnGap={0}
              rowGap={8}
            >
              {/* header columns */}
              <ZLogoMinimal
                w="120px"
                h="50px"
                p={2}
                mx="auto"
              />
              <Box />
              {SystemIcon && (
                <SystemIcon
                  w="100%"
                  h="50px"
                />
              )}
              {EVENT_MAPPING_KEYS.map((key) => {
                return (
                  <Fragment key={key}>
                    <ZInput
                      value={humanize(key)}
                      isReadOnly
                    />
                    <Text
                      textAlign="center"
                      fontWeight="semibold"
                      fontSize="2xl"
                      color="tertiary.500"
                    >
                      =
                    </Text>
                    <ZSelect
                      value={state[key]?.status || ''}
                      onChange={(e) => handleStatus(key, e.target.value)}
                    >
                      <option value="">-- Ignore --</option>
                      {statuses.map((status) => {
                        return (
                          <option
                            key={status.id}
                            value={status.name}
                          >
                            {status.name}
                          </option>
                        )
                      })}
                    </ZSelect>
                  </Fragment>
                )
              })}
            </Grid>
          </ZModalBody>
          <ZModalFooter
            justifyContent="space-between"
            mt={8}
          >
            <ZButton
              type="submit"
              colorScheme="atomicBlue"
              isDisabled={saveMapping.isLoading}
              onClick={handleConfirm}
              minW="140px"
            >
              Save Mapping
            </ZButton>
            <ZButton
              colorScheme="atomicGray"
              variant="ghost"
              onClick={onClose}
              minW="140px"
            >
              Cancel
            </ZButton>
          </ZModalFooter>
        </ZModalContent>
      </form>
    </ZModal>
  )
}
