import type { Connection, IntegrationSync } from '../../api'
import { DataObjectType, Status } from '../../api'

export const isActiveIntegration = (integration?: IntegrationSync) => {
  return integration?.status === Status.Active
}

export const isUserIntegration = (integration: IntegrationSync) => {
  return [DataObjectType.Product, DataObjectType.User].includes(integration.objectType)
}
export const isContactIntegration = (integration: IntegrationSync) => {
  return [DataObjectType.Contact, DataObjectType.Lead].includes(integration.objectType)
}

export const isAuthorizedConnection = (connection?: Connection) => connection?.authorizationStatus === 'success'

export interface IntegrationFormProps {
  current: IntegrationSync[]
  original: IntegrationSync[]
}
