import type { UiTimelineConfig } from '@postal-io/postal-ui'
import { UiTimelineEventStatus } from '@postal-io/postal-ui'
import { NotificationCategory } from 'api'
import {
  MdOutlineCalendarToday,
  MdOutlineEmail,
  MdOutlineEvent,
  MdOutlineImage,
  MdOutlineQrCodeScanner,
  MdOutlineRedeem,
} from 'react-icons/md'

export const EXT_ACCOUNT_TIMELINE_CONFIG: UiTimelineConfig = {
  icons: {
    [NotificationCategory.Sends]: { icon: MdOutlineEmail, status: UiTimelineEventStatus.Default },
    [NotificationCategory.EmailOpens]: {
      icon: MdOutlineEmail,
      status: UiTimelineEventStatus.Success,
    },
    [NotificationCategory.LandingPageViews]: {
      icon: MdOutlineImage,
      status: UiTimelineEventStatus.Info,
    },
    [NotificationCategory.MeetingAccepted]: {
      icon: MdOutlineCalendarToday,
      status: UiTimelineEventStatus.Success,
    },
    [NotificationCategory.EventRegistrationSent]: {
      icon: MdOutlineEvent,
      status: UiTimelineEventStatus.Info,
    },
    [NotificationCategory.EventRegistrationAccepted]: {
      icon: MdOutlineEvent,
      status: UiTimelineEventStatus.Success,
    },
    [NotificationCategory.QrCodeScan]: {
      icon: MdOutlineQrCodeScanner,
      status: UiTimelineEventStatus.Info,
    },
    [NotificationCategory.ItemAccepted]: {
      icon: MdOutlineRedeem,
      status: UiTimelineEventStatus.Success,
    },
  },
}

export const EXT_ACCOUNT_TIMELINE_CATEGORIES: string[] = [
  'SENDS',
  'EMAIL_OPENS',
  'LANDING_PAGE_VIEWS',
  'MEETING_ACCEPTED',
  'EVENT_REGISTRATION_SENT',
  'EVENT_REGISTRATION_ACCEPTED',
  'QR_CODE_SCAN',
  'ITEM_ACCEPTED',
]
