import { Grid } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiSkeleton, useAlertError, ZSidebar, ZSidebarBanner } from '@postal-io/postal-ui'
import type { BillingAccount } from 'api'
import { BillingAccountsDocument, BillingAccountType, PostalPlan, Status } from 'api'
import { BillingAccountCard, NewBillingAccountCard } from 'components/Billing/BillingAccountCard'
import { BillingAccountCreate } from 'components/Billing/BillingAccountCreate'
import { CenteredBox } from 'components/Common'
import { PageTitle, useSession } from 'hooks'
import React, { useMemo } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

export const BillingAccounts: React.FC = () => {
  const { session } = useSession()
  const navigate = useNavigate()
  const canCreateAccount = session?.plan && session?.plan !== PostalPlan.Payg

  // Only show active billing accounts.
  // Treat 'null' as active since 'status' field was added later so not all billing accounts will have it set.
  const getAccounts = useGraphqlQuery(BillingAccountsDocument, {
    filter: { status: { in: [Status.Active, null] } },
  })
  useAlertError(getAccounts.error)

  const billingAccounts = useMemo(() => {
    const accounts = getAccounts.data?.billingAccounts || []
    // subscription accounts are deprecated
    return accounts.filter((account) => account.type !== BillingAccountType.Subscription)
  }, [getAccounts.data?.billingAccounts])

  // strange
  const route = useMatch('/billing/accounts/new')
  const isNew = !!route

  const handleClose = () => navigate('/billing/accounts')
  const handleCreate = (account: BillingAccount) => {
    navigate(`/billing/accounts/${account.id}`)
  }
  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner title="Billing Accounts" />
        <PageTitle title="Billing Accounts" />
        <UiSkeleton isLoaded={!getAccounts.isLoading}>
          <Grid
            templateColumns={{
              base: 'repeat(3, 1fr)',
              md: 'repeat(3, 1fr)',
              sm: '1fr',
            }}
            gridAutoRows="max-content"
            gridGap={8}
            alignItems="center"
            alignContent="center"
          >
            {canCreateAccount && <NewBillingAccountCard />}
            {billingAccounts.map((account) => {
              return (
                <BillingAccountCard
                  key={account.id}
                  billingAccount={account}
                />
              )
            })}
          </Grid>
        </UiSkeleton>
        {isNew && (
          <BillingAccountCreate
            isOpen
            onClose={handleClose}
            onCreate={handleCreate}
          />
        )}
      </ZSidebar>
    </CenteredBox>
  )
}
