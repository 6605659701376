import { Box, Link, SlideFade, Stack } from '@chakra-ui/react'
import { UiIconSwag, ZButton, ZLink, ZText } from '@postal-io/postal-ui'
import swagEditorPreview from 'assets/images/swag-editor-preview.jpg'
import { useAcl } from 'hooks'
import { Helmet } from 'lib'
import { Permission } from 'lib/permissions'
import { useMemo } from 'react'
import { RemoveScroll } from 'react-remove-scroll'

const SWAG_IFRAME_SRC = process.env.REACT_APP_SWAG_IFRAME

export function useHasSwagEditor() {
  const { hasFeature, hasPermission } = useAcl()
  return useMemo(
    () => hasFeature('swagEditor') && hasPermission(Permission.SwagEditor) && !!SWAG_IFRAME_SRC,
    [hasFeature, hasPermission]
  )
}

export const SwagEditor = () => {
  const { hasFeature, hasPermission } = useAcl()
  const hasWarehouse = hasFeature('warehouse') && hasPermission('warehouse.read')

  if (!SWAG_IFRAME_SRC) return null

  return (
    <>
      <RemoveScroll style={{ height: 0 }}>&nbsp;</RemoveScroll>
      {hasWarehouse ? (
        <>
          <Box id="swag-iframe" />
          <Helmet>
            <script
              nonce="7e6d36ae12ce81578f3f61fb0b871750"
              defer
              src={SWAG_IFRAME_SRC}
            ></script>
          </Helmet>
        </>
      ) : (
        <AccountNeedsWarehousing />
      )}
    </>
  )
}

const WAREHOUSING_REQUEST_FORM_URL = 'https://forms.gle/88DYkTVyTC71gVy29'

const AccountNeedsWarehousing: React.FC = () => (
  <Box
    position="relative"
    w="100%"
    h="calc(100vh - 60px)"
    textAlign="center"
  >
    <img
      src={swagEditorPreview}
      alt="swag-creator-preview-backdrop"
      style={{ width: '100%', maxWidth: '1440px', margin: 'auto' }}
    />
    <Box
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      backgroundImage={swagEditorPreview}
      backdropFilter="blur(10px)"
      bg="#888A"
    >
      <SlideFade in>
        <Stack
          alignItems="center"
          spacing={10}
          mt={20}
          borderRadius={10}
          p={10}
          w="500px"
          mx="auto"
          bg="white"
          boxShadow="0 0 20px rgba(0,0,0,.2)"
        >
          <UiIconSwag
            fontSize="69px"
            color="atomicGray.400"
          />
          <ZText
            fontSize="body-lg"
            textAlign="center"
          >
            Warehousing is required to use Postal's Swag Creator. Please fill out{' '}
            <ZLink
              href={WAREHOUSING_REQUEST_FORM_URL}
              target="_blank"
              fontSize="body-lg"
            >
              this form
            </ZLink>{' '}
            to request access and learn more:
          </ZText>
          <ZButton
            as={Link}
            // @ts-ignore-next-line
            href={WAREHOUSING_REQUEST_FORM_URL}
            display="inline-flex"
            target="_blank"
            colorScheme="atomicBlue"
            transition="0.2s background"
            flexGrow={0}
            mt={2}
          >
            Request Access
          </ZButton>
        </Stack>
      </SlideFade>
    </Box>
  </Box>
)
