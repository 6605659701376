import { extendTheme } from '@chakra-ui/react'
import { generatePalette, joinStrings, uiTheme, UiThemeProvider } from '@postal-io/postal-ui'
import 'focus-visible/dist/focus-visible'
import { CustomColors, Helmet, logger } from 'lib'
import type { PropsWithChildren } from 'react'
import { createContext, useCallback, useContext, useLayoutEffect, useState } from 'react'
import { StoreNotFound, StorePending } from '../components/Common/StoreError'

export enum AppType {
  Default = 'Default',
  Store = 'Store',
  PendingStore = 'PendingStore',
  UnknownStore = 'UnknownStore',
}

export interface BrandingSettings {
  type?: AppType
  title?: string
  primaryColor?: string
  secondaryColor?: string
  tertiaryColor?: string
  navForeground?: string
  navBackground?: string
  linkColor?: string
}

const DEFAULT_SETTINGS = {
  type: AppType.Default,
  title: 'Postal',
}

const BrandingContext = createContext<BrandingSettings>(DEFAULT_SETTINGS)

// const AppTypeMap: Record<number, AppType> = {
//   200: AppType.Store,
//   201: AppType.Default,
//   204: AppType.PendingStore,
//   404: AppType.UnknownStore
// }

const getBrandingSettings = async () => {
  // const res = await fetch('/api/user/branding')
  // const config = res.status === 200 ? await res.json() : {}
  // const type = AppTypeMap[res.status] ?? AppType.Default
  // return { ...DEFAULT_SETTINGS, type, ...config } as BrandingSettings
  return DEFAULT_SETTINGS
}

const generateTheme = (settings?: BrandingSettings) => {
  if (!settings) return {}
  const overrides = { colors: {} } as any
  if (settings.primaryColor) {
    overrides.colors.primary = generatePalette(settings.primaryColor)
  }
  if (settings.secondaryColor) {
    overrides.colors.secondary = generatePalette(settings.secondaryColor)
  }
  if (settings.tertiaryColor) {
    overrides.colors.tertiary = generatePalette(settings.tertiaryColor)
  }
  if (settings.navBackground) {
    overrides.colors.header = {
      dark: settings.navBackground,
      light: settings.navBackground,
    }
  }
  if (settings.navForeground) {
    overrides.colors.navbarLink = settings.navForeground
  }
  overrides.colors.sky = generatePalette(CustomColors.Sky)
  overrides.colors.cyan = generatePalette(CustomColors.Cyan)
  overrides.colors.purple = generatePalette(CustomColors.Purple)
  overrides.colors.turquoise = generatePalette(CustomColors.Turquoise)
  overrides.colors.forest = generatePalette(CustomColors.Forest)
  overrides.colors.slate = generatePalette(CustomColors.Slate)

  // remove when this is updated in postal-ui
  overrides.components = {
    Stat: {
      baseStyle: {
        number: { fontWeight: 500 },
      },
    },
  }

  return extendTheme(overrides, uiTheme)
}

interface PageTitleProps {
  title?: string
  section?: string
}
export const PageTitle = ({ title, section }: PageTitleProps) => {
  const branding = useBranding()
  if (!title && !section) return null

  const pageTitle = joinStrings([title, section, branding.title], ' - ')

  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  )
}

export const BrandingProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [branding, setBranding] = useState<BrandingSettings>()
  const [theme, setTheme] = useState<any>(uiTheme)

  const refetch = useCallback(() => {
    getBrandingSettings().then((settings) => {
      setTheme(generateTheme(settings))
      setBranding(settings)
    })
  }, [])

  useLayoutEffect(() => {
    refetch()
  }, [refetch])

  logger('THEME', theme)

  return (
    <UiThemeProvider theme={theme}>
      {!branding ? null : (
        <>
          <Helmet>
            <title>{branding.title}</title>
          </Helmet>

          <BrandingContext.Provider value={branding}>
            {branding.type === AppType.PendingStore ? (
              <StorePending />
            ) : branding.type === AppType.UnknownStore ? (
              <StoreNotFound />
            ) : (
              children
            )}
          </BrandingContext.Provider>
        </>
      )}
    </UiThemeProvider>
  )
}

export const useBranding = () => useContext(BrandingContext)
