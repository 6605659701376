import { useExplorerPlugin } from '@graphiql/plugin-explorer'
import { authMiddleware, GRAPHQL_ENDPOINT } from 'api/query'
import { GraphiQL } from 'graphiql'
import 'graphiql/graphiql.css'
import { useState } from 'react'
import './styles.css'

async function fetcher(graphQLParams: any) {
  const headers = await authMiddleware(new Headers({ 'content-type': 'application/json' }))
  const data = await fetch(GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers,
    body: JSON.stringify(graphQLParams),
    credentials: 'same-origin',
  })
  return data.json().catch(() => data.text())
}

export default function GraphiQLApp() {
  const [query, setQuery] = useState('')
  const explorerPlugin = useExplorerPlugin({ query, onEdit: setQuery })
  return (
    <div id="graphiql-wrapper">
      <GraphiQL
        fetcher={fetcher}
        query={query}
        onEditQuery={setQuery}
        plugins={[explorerPlugin]}
      />
    </div>
  )
}
