import type { BoxProps } from '@chakra-ui/react'
import { Box, FormControl, Grid, SimpleGrid, StatNumber, VStack } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import {
  UiInfoTooltip,
  UiMoney,
  UiStat,
  UiStatGroup,
  UiStatLabel,
  useAlerts,
  ZButton,
  ZCard,
  ZCardBody,
  ZCheckbox,
  ZFormLabel,
  ZHeading,
  ZInput,
  ZInputDate,
  ZLink,
  ZText,
  ZTextarea,
} from '@postal-io/postal-ui'
import type {
  ApprovedPostalUpdateInput,
  EventLimitResult,
  EventPreview,
  MagicEventInstance,
  MarketplaceProduct,
  ProductVariant,
  SpendAs,
} from 'api'
import {
  EventLimitCheckDocument,
  GetAccountDocument,
  Granularity,
  MeDocument,
  PreviewEventDocument,
  PriceStructure,
  Status,
} from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { addDays, format } from 'date-fns'
import { useAcl } from 'hooks'
import { set } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useImmer } from 'use-immer'
import { MarketplaceProductVariantCard } from '../Postal/MarketplaceProductVariantCard'
import { EventDisabled, EventsDisabledReason } from './EventDisabled'
import { EventsSpendAs } from './EventsSpendAs'

interface MarketplaceProductEventFormProps extends Omit<ZCardProps, 'onSubmit'> {
  product: MarketplaceProduct
  onSubmit: (data: ApprovedPostalUpdateInput) => Promise<unknown>
}

export const EventsMarketplaceProductEventForm: React.FC<MarketplaceProductEventFormProps> = ({
  product,
  onSubmit,
  ...rest
}) => {
  const { hasFeature } = useAcl()
  const [selectedDate, setSelectedDate] = useState<any>(new Date())
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false)
  const hasBudgetDropDown = hasFeature('budgetDropDown')

  const [isSubmitting, setIsSubmitting] = useState(false)

  const Alert = useAlerts()

  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  // CHECK PERMISSIONS
  const { hasPermission } = useAcl()
  const canCreate = hasPermission('events.create')

  // form state
  const [form, setForm] = useImmer<Record<string, any>>({
    requestedAttendeeCount: product.eventDetails?.minimumAttendees || 10,
  } as any)

  // data fetching
  const meQuery = useGraphqlQuery(MeDocument)
  const meData = useMemo(() => meQuery?.data?.me, [meQuery?.data?.me])

  const [debouncedAttendeeCount] = useDebounce(form.requestedAttendeeCount, 500)
  const previewEventQuery = useGraphqlQuery(
    PreviewEventDocument,
    {
      marketplaceProductId: product.id,
      productVariantId: form.variantId,
      attendeeCount: debouncedAttendeeCount,
    },
    { enabled: canCreate && !!form.variantId && !!debouncedAttendeeCount }
  )
  const previewData = useMemo(() => {
    return previewEventQuery.data?.previewEvent || ({} as EventPreview)
  }, [previewEventQuery.data?.previewEvent])

  // initial form auto-fill
  useEffect(() => {
    if (!meData) return
    setForm((draft: MagicEventInstance) => {
      draft.requestedByEmail = meData.emailAddress
      draft.requestedByName = [meData.firstName, meData.lastName].join(' ')
      draft.requestedByPhone = meData.phoneNumber || ''
    })
  }, [meData, setForm])

  // HANDLE VARIANTS
  const activeVariants = useMemo(
    () => product.variants?.filter((v) => v.status === Status.Active) || [],
    [product.variants]
  )
  const handleVariant = useCallback(
    (product: ProductVariant) => {
      setForm((draft: any) => {
        draft.variantId = product.id
      })
    },
    [setForm]
  )
  // select the first variant if it is the only one.
  useEffect(() => {
    if (activeVariants.length === 1) handleVariant(activeVariants[0])
  }, [activeVariants, handleVariant])

  const getAccountQuery = useGraphqlQuery(GetAccountDocument)

  const eventLimitCheckQuery = useGraphqlQuery(
    EventLimitCheckDocument,
    { eventDate: selectedDate },
    { enabled: canCreate && !!selectedDate }
  )
  const { eventAllowed, eventLimit, eventLimitTimeFrame, eventLimitUsed } = useMemo(
    () => eventLimitCheckQuery.data?.eventLimitCheck ?? ({} as EventLimitResult),
    [eventLimitCheckQuery.data?.eventLimitCheck]
  )
  const eventsDisabledReason = useMemo(() => {
    const trialSubscription = getAccountQuery.data?.getAccount?.trialSubscription
    if (!canCreate) {
      return EventsDisabledReason.NoPermission
    } else if (trialSubscription === true) {
      // user has trial subscription
      return EventsDisabledReason.TrialSubscription
    } else if (!eventAllowed && eventLimitTimeFrame === Granularity.All) {
      // events not allowed and Granularity.All === all credits used up
      return EventsDisabledReason.NoCredits
    } else if (!eventAllowed && eventLimitTimeFrame && selectedDate) {
      // events not allowed and eventLimitTimeFrame is defined but not "All"
      // === used up for a specific Month or other Granularity
      return EventsDisabledReason.InvalidDateSelected
    } else {
      // no reason is found to disable - show the form
      return null
    }
  }, [canCreate, eventAllowed, eventLimitTimeFrame, getAccountQuery.data?.getAccount?.trialSubscription, selectedDate])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setForm((draft: any) => {
      draft[name] = value
    })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const { variantId, requestedDate1, requestedDate2, requestedDate3, eventFeeSettings, ...event } = form
    if (!variantId) {
      Alert.error('Please choose one option for your event.')
      return
    }

    const requestedDates = [requestedDate1, requestedDate2, requestedDate3].filter(Boolean)
    if (!event.calendarRequestedDate && requestedDates.length < 2) {
      return Alert.error('Please choose a date and time from the calendar or request two additional dates.')
    }

    const data = {
      variants: [variantId],
      event: {
        ...event,
        requestedDates: requestedDates.length ? requestedDates : undefined,
        calendarRequestedDate: event.calendarRequestedDate || undefined,
      },
      eventFeeSettings,
    }

    setIsSubmitting(true)
    await onSubmit(data as ApprovedPostalUpdateInput)
    setIsSubmitting(false)
  }

  const eventMinMax = useMemo(() => {
    const { minimumAttendees, maximumAttendees } = product.eventDetails || {}
    const words = [`${minimumAttendees || 1} minimum`]
    if (maximumAttendees) words.push(`${maximumAttendees} maximum`)
    return words.join(' and ') + ' attendees for this event.'
  }, [product.eventDetails])

  const estimatedPrice = useMemo(() => {
    const minimumCost = Math.round(previewData?.minimumSpend || 0)
    const variableCosts = Math.round(previewData.variableCost?.total || 0)
    const fixedCosts = Math.round(previewData.flatFees?.total || 0)
    const totalCost = Math.max(minimumCost, variableCosts + fixedCosts)

    const explanations = []
    if (variableCosts) {
      explanations.push(`$${Math.round(variableCosts / debouncedAttendeeCount / 100)} per attendee.`)
    }
    if (fixedCosts) {
      explanations.push(`$${fixedCosts / 100} flat fee.`)
    }
    if (minimumCost) {
      explanations.push(`$${minimumCost / 100} minimum spend`)
    }
    return {
      totalCost,
      explanation: explanations.join(' '),
    }
  }, [debouncedAttendeeCount, previewData.flatFees?.total, previewData?.minimumSpend, previewData.variableCost?.total])

  const onChangeAgreeToTerms = () => setAgreeToTerms((prev) => !prev)

  // to show / hide spendAs field
  const canUpdateSpendAs = useMemo(
    () =>
      hasBudgetDropDown &&
      product?.variants?.some((v) =>
        v.fulfillmentPartnerList?.some((f) => f.priceStructure === PriceStructure.FlatFee)
      ),
    [hasBudgetDropDown, product?.variants]
  )

  const handleSpendAs = ({ userId, teamId }: SpendAs) => {
    setForm((draft: Record<string, any>) => {
      set(draft, 'eventFeeSettings.flatFee.userId', userId)
      set(draft, 'eventFeeSettings.flatFee.teamId', teamId)
    })
  }
  const isLoading = getAccountQuery.isLoading || eventLimitCheckQuery.isLoading

  if (isLoading) {
    return (
      <ZCard
        variant="form"
        height="100%"
        {...rest}
        isLoading={true}
      />
    )
  }

  if (eventsDisabledReason) {
    return (
      <ZCard
        variant="form"
        height="100%"
        {...rest}
      >
        <ZCardBody>
          <EventDisabled
            reason={eventsDisabledReason}
            setSelectedDate={setSelectedDate}
            eventLimit={eventLimit}
            eventLimitUsed={eventLimitUsed}
            productId={product.id}
          />
        </ZCardBody>
      </ZCard>
    )
  }

  return (
    <ZCard
      variant="form"
      height="100%"
      display="grid"
      py={[8, 8, 8]}
      {...rest}
    >
      <ZCardBody justifySelf="center">
        <form onSubmit={handleSubmit}>
          <VStack
            spacing={8}
            align="flex-start"
            maxW="720px"
          >
            <ZHeading
              size="h4"
              lineHeight="normal"
            >
              I am interested in setting up an event with {product?.name}
            </ZHeading>

            <SimpleGrid
              columns={{ base: 1, lg: 3 }}
              spacing={4}
              mb={4}
              w="100%"
            >
              <FormControl
                id="requestedByName"
                isRequired
              >
                <ZFormLabel>Name</ZFormLabel>
                <ZInput
                  w="100%"
                  name="requestedByName"
                  value={form.requestedByName || ''}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl
                id="requestedByEmail"
                isRequired
              >
                <ZFormLabel>Email</ZFormLabel>
                <ZInput
                  name="requestedByEmail"
                  type="email"
                  value={form.requestedByEmail || ''}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl
                id="requestedByPhone"
                isRequired
              >
                <ZFormLabel>Phone</ZFormLabel>
                <ZInput
                  name="requestedByPhone"
                  value={form.requestedByPhone || ''}
                  onChange={handleChange}
                />
              </FormControl>
            </SimpleGrid>
            <FormControl
              id="requestedByMessage"
              isRequired
            >
              <ZFormLabel>Message</ZFormLabel>
              <ZTextarea
                placeholder="Please include a message to the event provider about your event."
                name="requestedByMessage"
                value={form.requestedByMessage}
                onChange={handleChange}
              />
            </FormControl>
            <Box>
              <ZHeading
                size="h5"
                mb={2}
              >
                Choose your preferred dates and time
                <ZInfoTooltip
                  hasArrow
                  ml={2}
                  label="We will do our best to accommodate your preference. We will reach out to you within 2 business days to confirm the exact date and time of your event."
                />
              </ZHeading>
              <ZText
                as="i"
                color="atomicGray.500"
                mb={2}
              >
                Times shown in {localTimeZone}
              </ZText>
            </Box>
            <FormDateInputs
              form={form}
              handleChange={handleChange}
            />

            {canUpdateSpendAs && (
              <FormControl
                id="spendAs"
                zIndex={1000}
              >
                <EventsSpendAs
                  value={form.eventFeeSettings?.flatFee}
                  onChange={handleSpendAs}
                />
              </FormControl>
            )}

            <Box width="100%">
              <ZHeading size="h5">{activeVariants.length > 1 ? 'Select an Option' : 'Option'}</ZHeading>

              <Grid
                templateColumns="repeat(auto-fit, minmax(250px, .33fr))"
                gridGap="1rem"
                mt={4}
              >
                {(activeVariants as any[])?.map((variant) => {
                  return (
                    <MarketplaceProductVariantCard
                      key={variant.id}
                      variant={variant}
                      category={product.category}
                      isSelected={variant.id === form.variantId}
                      onClick={handleVariant}
                    />
                  )
                })}
              </Grid>
            </Box>

            <ZCard
              variant="form"
              mt={8}
              width="100%"
            >
              <ZCardBody>
                <UiStatGroup>
                  <UiStat justifyContent="center">
                    <UiStatLabel
                      display="flex"
                      alignItems="center"
                    >
                      Estimated Attendees
                      <UiInfoTooltip
                        label={eventMinMax}
                        ml={2}
                        placement="top"
                      />
                    </UiStatLabel>
                    <ZInput
                      mt={2}
                      textAlign="center"
                      id="requestedAttendeeCount"
                      name="requestedAttendeeCount"
                      type="number"
                      fontSize="2xl"
                      w="100px"
                      color="shades.700"
                      variant="flushed"
                      display="inline-block"
                      min={product.eventDetails?.minimumAttendees || 1}
                      max={product.eventDetails?.maximumAttendees || undefined}
                      borderBottomColor="shades.100"
                      borderBottomWidth="5px"
                      isRequired
                      value={form.requestedAttendeeCount}
                      onChange={handleChange}
                    />
                  </UiStat>
                  <UiStat justifyContent="center">
                    <UiStatLabel
                      mb={2}
                      display="flex"
                      alignItems="center"
                    >
                      Estimated Cost
                      <UiInfoTooltip
                        ml={2}
                        label={estimatedPrice.explanation}
                        placement="top"
                      />
                    </UiStatLabel>
                    <StatNumber fontSize="2xl">
                      <UiMoney
                        cents={estimatedPrice.totalCost}
                        currency={product.currency}
                      />
                    </StatNumber>
                  </UiStat>
                </UiStatGroup>
              </ZCardBody>
            </ZCard>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <ZCheckbox
                name="agreeToTerms"
                id="agreeToTerms"
                colorScheme="atomicBlue"
                isChecked={agreeToTerms}
                onChange={onChangeAgreeToTerms}
              >
                <ZText display="inline">I agree to the</ZText>{' '}
                <ZLink
                  href="https://help.postal.com/helpcenter/s/article/Events-Terms-Conditions"
                  target="_blank"
                >
                  Postal Events Terms & Conditions
                </ZLink>
              </ZCheckbox>
            </Box>

            <ZButton
              mt={8}
              colorScheme="atomicBlue"
              type="submit"
              alignSelf="center"
              isDisabled={!agreeToTerms || isSubmitting}
              isLoading={isSubmitting}
            >
              Book Your Event
            </ZButton>
          </VStack>
        </form>
      </ZCardBody>
    </ZCard>
  )
}

interface FormDateInputsProps extends BoxProps {
  form: any
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const FormDateInputs: React.FC<FormDateInputsProps> = ({ form, handleChange, ...rest }) => {
  const oneDay = format(addDays(new Date(), 7), 'yyyy-MM-dd')
  const twoDay = format(addDays(new Date(), 8), 'yyyy-MM-dd')
  const threeDay = format(addDays(new Date(), 9), 'yyyy-MM-dd')

  return (
    <SimpleGrid
      columns={{ base: 1, lg: 3 }}
      spacing={4}
      {...rest}
    >
      <FormControl
        id="requestedDate1"
        isRequired={!form.calendarRequestedDate}
      >
        <ZFormLabel>Preferred Date 1</ZFormLabel>
        <ZInputDate
          placeholder="First choice"
          data-testid="requestedDate1"
          mb={2}
          enableTime
          name="requestedDate1"
          value={form.requestedDate1}
          onChange={handleChange}
          data-min-date={oneDay}
        />
      </FormControl>
      <FormControl
        id="requestedDate2"
        isRequired={!form.calendarRequestedDate}
      >
        <ZFormLabel>Preferred Date 2</ZFormLabel>
        <ZInputDate
          placeholder="Second choice"
          data-testid="requestedDate2"
          enableTime
          name="requestedDate2"
          value={form.requestedDate2}
          onChange={handleChange}
          data-min-date={twoDay}
        />
      </FormControl>
      <FormControl id="requestedDate3">
        <ZFormLabel>Preferred Date 3</ZFormLabel>
        <ZInputDate
          placeholder="Third choice (optional)"
          enableTime
          name="requestedDate3"
          value={form.requestedDate3}
          onChange={handleChange}
          data-min-date={threeDay}
        />
      </FormControl>
    </SimpleGrid>
  )
}
