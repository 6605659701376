import { useSession } from 'hooks'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { isLocalOrTestEnvironment } from 'lib'
import type React from 'react'
import { useEffect, useRef } from 'react'

export const LaunchDarkly: React.FC = () => {
  const { session } = useSession()
  const ldClient = useLDClient()
  const ldRef = useRef(false)

  useEffect(() => {
    if (!session?.userId) return
    if (ldRef.current === session.userId) return
    if (!ldClient) return

    const identification = isLocalOrTestEnvironment
      ? {
          key: 'engage-automated-testing',
          email: 'engage-automated-testing@postal.dev',
        }
      : {
          key: session.userId,
          email: session.userName,
          custom: {
            accountId: session.accountId,
            productAccessId: session.productAccessId,
            plan: session.plan,
            roles: session.roles,
          },
        }

    ldClient.identify(identification).finally(() => {
      ldRef.current = session.userId
    })

    return () => {
      ldRef.current = false
    }
  }, [ldClient, session])

  return null
}
