import { useAlerts } from '@postal-io/postal-ui'
import { useCallback, useState } from 'react'

const defaultIdentityFn = (item: any) => item.id

export function useBulkSelect<T>(
  { identityFn, initialItems, maxItems }: { identityFn: (item: T) => string; initialItems?: T[]; maxItems?: number } = {
    identityFn: defaultIdentityFn,
  }
) {
  const Alert = useAlerts()
  const [bulkSelected, setBulkSelected] = useState<T[]>(initialItems ?? [])

  const isBulkSelected = useCallback(
    (item: T) => {
      return bulkSelected.some((s) => identityFn(s) === identityFn(item))
    },
    [identityFn, bulkSelected]
  )

  const handleBulkSelect = useCallback(
    (item: T) => {
      const found = !!bulkSelected.find((p) => identityFn(p) === identityFn(item))
      if (found) {
        setBulkSelected(bulkSelected.filter((p) => identityFn(p) !== identityFn(item)))
      } else {
        if (maxItems && bulkSelected.length >= maxItems) return Alert.error('Maximum number of items reached')
        setBulkSelected([...bulkSelected, item])
      }
    },
    [Alert, bulkSelected, identityFn, maxItems]
  )

  const handleCancelBulkSelect = () => setBulkSelected([])

  return { handleBulkSelect, isBulkSelected, handleCancelBulkSelect, bulkSelected, setBulkSelected }
}
