import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  chakra,
  Grid,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlerts } from '@postal-io/postal-ui'
import {
  CreateUpdateIntegrationTriggerDocument,
  DeleteIntegrationTriggerDocument,
  GetIntegrationTriggerDocument,
  TriggerAction,
} from 'api'
import { CenteredBox } from 'components/Common'
import { getExternalProvider } from 'components/Integrations'
import { NavbarActionMenu, NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { PageTitle, useAcl, useSession } from 'hooks'
import React, { useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PlaybookActionCardV2, PostalActionCardV2 } from './ActionCard'
import { TriggerActivity } from './TriggerActivity'
import { PLAYBOOK_ACTIONS } from './triggerData'
import { TriggerDetails } from './TriggerDetails'
import { TriggerStats } from './TriggerStats'

export const Trigger: React.FC = () => {
  const Alert = useAlerts()
  const { hasPermission } = useAcl()
  const { session } = useSession()
  const { triggerId } = useParams() as any
  const cancelRef = useRef(null)

  const getTrigger = useGraphqlQuery(GetIntegrationTriggerDocument, { id: triggerId })
  const trigger = useMemo(() => getTrigger?.data?.getIntegrationTrigger, [getTrigger?.data?.getIntegrationTrigger])
  const updateTrigger = useGraphqlMutation(CreateUpdateIntegrationTriggerDocument)

  const provider = trigger && getExternalProvider(trigger?.systemName)
  const isMyTrigger = trigger?.userId === session.userId && provider?.trigger?.requiresIntegration === false
  const canUpdate = isMyTrigger || hasPermission('triggers.update')
  const canDelete = isMyTrigger || hasPermission('triggers.delete')

  const navigate = useNavigate()

  const isPostal = !!trigger?.action && trigger.action === TriggerAction.SendPostal
  const isPlaybook = !!trigger?.action && PLAYBOOK_ACTIONS.includes(trigger.action)

  const deleteDisclosure = useDisclosure()
  const deleteTrigger = useGraphqlMutation(DeleteIntegrationTriggerDocument)

  const handleRemovePostalOrPlaybook = async () => {
    const { hitCount, created, userId, userLink, ...rest } = trigger!
    const data = {
      ...rest,
      action: TriggerAction.Nothing,
      approvedPostalId: undefined,
      approvedPostalVariantId: undefined,
      giftMessage: undefined,
      deliveryEmail: undefined,
      physicalMessage: undefined,
      meetingRequestSetting: undefined,
      emailSubjectLine: undefined,
      sendAsContactOwner: undefined,
      sendAsUser: undefined,
      itemCustomizationInputs: undefined,
      playbookDefinitionId: undefined,
      newAction: undefined,
      landingPageHeaderText: undefined,
      landingPageBody: undefined,
      landingPageIncludeHeadshot: undefined,
      landingPageIncludeSenderName: undefined,
      formFieldList: undefined,
      spendAsTeamId: undefined,
      spendAsUserId: undefined,
    }

    try {
      await updateTrigger.mutateAsync({ data })
      Alert.success('Trigger Saved')
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleDelete = async () => {
    try {
      await deleteTrigger.mutateAsync({ id: triggerId })
      Alert.warning('Trigger Removed')
      deleteDisclosure.onClose()
      navigate('/triggers')
    } catch (err) {
      Alert.error(err)
    }
  }

  const openEditTrigger = (action?: TriggerAction) => {
    let path = ''
    switch (action) {
      case TriggerAction.StartPlaybook:
      case TriggerAction.StopPlaybook:
        path = '/subscription'
        break
      case TriggerAction.Nothing:
        path = '/none'
        break
    }
    navigate(`/triggers/${triggerId}/edit${path}`)
    return action
  }

  const actions = [
    {
      title: 'Edit Trigger',
      onClick: openEditTrigger,
      isHidden: !canUpdate,
    },
    {
      title: 'Delete Trigger',
      onClick: deleteDisclosure.onOpen,
      isHidden: !canDelete,
    },
  ]

  return (
    <>
      <PageTitle
        title={trigger?.name}
        section="Triggers"
      />
      <SecondaryNavbar
        farRight={<NavbarActionMenu actionItems={actions} />}
        left={
          <NavbarBackButton
            onClick={() => navigate('/triggers')}
            label="Back to Triggers"
          />
        }
        header={trigger?.name}
      />
      <CenteredBox isLoaded>
        <Grid
          mt={8}
          gap={8}
          templateColumns={{ base: 'minmax(0, 1fr)', lg: 'minmax(0, 3fr) minmax(0, 4fr)' }}
        >
          <Stack spacing={8}>
            <TriggerStats triggerId={triggerId} />
            <TriggerDetails trigger={trigger} />
            {isPostal && trigger?.action && (
              <PostalActionCardV2
                approvedPostalId={trigger?.approvedPostalId as string}
                approvedPostalVariantId={trigger?.approvedPostalVariantId as string}
                action={trigger?.action}
                giftMessage={trigger?.giftMessage || ''}
                physicalMessage={trigger?.physicalMessage || ''}
                emailSubjectLine={trigger?.emailSubjectLine || ''}
                sendAsContactOwner={trigger?.sendAsContactOwner}
                sendAsUser={trigger?.sendAsUser}
                onChange={openEditTrigger}
                onEdit={openEditTrigger}
                onRemove={handleRemovePostalOrPlaybook}
                borderRadius={10}
              />
            )}
            {isPlaybook && trigger?.action && (
              <PlaybookActionCardV2
                playbookDefinitionId={trigger?.playbookDefinitionId as string}
                action={trigger?.action}
                onChange={openEditTrigger}
                onRemove={handleRemovePostalOrPlaybook}
                borderRadius={10}
              />
            )}
          </Stack>
          <TriggerActivity trigger={trigger} />
        </Grid>
      </CenteredBox>
      {canDelete && deleteDisclosure.isOpen && (
        <AlertDialog
          size="lg"
          leastDestructiveRef={cancelRef}
          onClose={deleteDisclosure.onClose}
          isOpen={deleteDisclosure.isOpen}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>Delete Trigger</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              Are you sure you want to <chakra.strong color="atomicRed.500">Delete</chakra.strong> this Trigger?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                colorScheme="atomicRed"
                onClick={handleDelete}
                minW={32}
              >
                Delete
              </Button>
              <Button
                ref={cancelRef}
                variant="ghost"
                colorScheme="atomicGray"
                onClick={deleteDisclosure.onClose}
                minW={32}
              >
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  )
}
