import { ButtonGroup, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiCard,
  ZButton,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
  ZModalOverlay,
} from '@postal-io/postal-ui'
import { PostalFulfillmentCard } from 'components/Postal/PostalFulfillmentCard'
import React, { useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GetPostalFulfillmentDocument } from '../../../api'

export const PostalFulfillment: React.FC = () => {
  const { postalFulfillmentId } = useParams() as any
  const { data, isLoading: fulFillmentLoading } = useGraphqlQuery(GetPostalFulfillmentDocument, {
    id: postalFulfillmentId,
  })
  const postalFulfillment = useMemo(() => data?.getPostalFulfillment, [data?.getPostalFulfillment])

  const disclosure = useDisclosure({ defaultIsOpen: true })
  const location = useLocation()
  const back = location.pathname.split('/').slice(0, -1).join('/')
  const navigate = useNavigate()

  const onClose = () => {
    disclosure.onClose()
    navigate(back)
  }

  const isLoading = fulFillmentLoading
  return (
    <ZModal
      size="6xl"
      scrollBehavior="inside"
      isOpen={disclosure.isOpen}
      onClose={onClose}
    >
      <ZModalOverlay />
      <ZModalContent>
        <ZModalCloseButton />
        <ZModalHeader>View Order</ZModalHeader>
        <ZModalBody>
          {isLoading || !postalFulfillment ? (
            <UiCard
              minH="400px"
              isLoading
            />
          ) : (
            <PostalFulfillmentCard
              postalFulfillment={postalFulfillment}
              onComplete={onClose}
              mb={0}
            />
          )}
        </ZModalBody>
        <ZModalFooter>
          <ButtonGroup
            justifyContent="flex-end"
            w="100%"
          >
            <ZButton
              variant="ghost"
              minW="140px"
              onClick={onClose}
            >
              Close
            </ZButton>
          </ButtonGroup>
        </ZModalFooter>
      </ZModalContent>
    </ZModal>
  )
}
