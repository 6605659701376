import { useGraphqlFetch, useGraphqlMutation } from '@postal-io/postal-graphql'
import { useAlerts } from '@postal-io/postal-ui'
import { CreateFavoriteItemDocument, DeleteFavoriteItemDocument, SearchFavoriteItemsDocument } from 'api'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

const DEBOUNCE = 1000

type favoriteStatusToggleArgs = {
  favoriteItemId?: string | null
  approvedPostalId: string
}
export function usePostalFavoriteStatusToggle({ approvedPostalId, favoriteItemId }: favoriteStatusToggleArgs) {
  const Alert = useAlerts()
  const [displayedIsFavorite, setDisplayedIsFavorite] = useState<boolean>(!!favoriteItemId)
  const addFavorite = useGraphqlMutation(CreateFavoriteItemDocument)
  const deleteFavorite = useGraphqlMutation(DeleteFavoriteItemDocument)
  const fetchFavoriteItems = useGraphqlFetch(SearchFavoriteItemsDocument)

  useEffect(() => {
    // reset displayed value
    setDisplayedIsFavorite(!!favoriteItemId)
  }, [favoriteItemId])

  // get the most up to date favorite status & favoriteItemId
  const getFavoriteItem = async () => {
    const favoriteItems = await fetchFavoriteItems({
      filter: { approvedPostalId: { eq: approvedPostalId } },
      limit: 1,
    })
    return favoriteItems.searchFavoriteItems?.[0]
  }

  const removeFromFavorites = async () => {
    const favoriteItem = await getFavoriteItem()
    if (!favoriteItem) return
    await deleteFavorite.mutateAsync({ id: favoriteItem.id })
  }

  const addToFavorites = async () => {
    const favoriteItem = await getFavoriteItem()
    if (favoriteItem) return
    await addFavorite.mutateAsync({ data: { approvedPostalId } })
  }

  const debouncedSetFavoriteStatus = useDebouncedCallback(async (val: boolean) => {
    try {
      val ? await addToFavorites() : await removeFromFavorites()
    } catch (e) {
      Alert.error(e.message)
      setDisplayedIsFavorite(!val)
    }
  }, DEBOUNCE)

  const toggleFavorite = async () => {
    // set the displayed state immediately
    setDisplayedIsFavorite(!displayedIsFavorite)
    // give the user a second to toggle back before enacting the toggle
    debouncedSetFavoriteStatus(!displayedIsFavorite)
  }

  return {
    toggleFavorite,
    isFavorite: displayedIsFavorite,
    isLoading: addFavorite.isLoading || deleteFavorite.isLoading,
  }
}
