// TODO: considering combining this with usePostalFilters
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { sessionStorage } from '@postal-io/postal-ui'
import { GetApprovedPostalDocument, GetMarketplaceProductDocument, Status } from 'api'
import { useAcl } from 'hooks'
import { identity, pickBy } from 'lodash'
import { createContext, useCallback, useEffect, useMemo } from 'react'
import { useImmerReducer } from 'use-immer'
import type { CollectionItem } from './utils'

interface CollectionsContextProps {
  state: MarketplaceState
  setNumFilters?: (numFilters: number) => void
  setNumResults?: (numResults: number | null) => void
  collectionsFilters: any
}

export const COLLECTIONS_INITIAL_STATE: any = {
  numFilters: 0,
  numResults: null,
  numResultsText: null,
}

export const CollectionsContext = createContext<CollectionsContextProps>({
  state: COLLECTIONS_INITIAL_STATE,
  collectionsFilters: {},
})

export type MarketplaceState = {
  numFilters: number
  numResults: number | null
  numResultsText: string | null
}

export enum UseCollectionAction {
  SetNumFilters = 'SET_NUM_FILTERS',
  SetNumResults = 'SET_NUM_RESULTS',
  ResetState = 'RESET_STATE',
}

interface PayloadProps {
  type: UseCollectionAction
  value?: any
}

const reducer = (draft: MarketplaceState, { type, ...payload }: PayloadProps) => {
  const { value } = payload

  switch (type) {
    case UseCollectionAction.SetNumFilters:
      draft.numFilters = value
      break

    case UseCollectionAction.SetNumResults:
      draft.numResults = value
      draft.numResultsText =
        value === null
          ? ''
          : value === 0
          ? '0 results'
          : `${Intl.NumberFormat().format(value)}+ results based on your filters`
      break

    case UseCollectionAction.ResetState:
      draft = value
      break

    default:
      return draft
  }
}

interface UseCollectionsProps {
  /**
   * set the initial state of the Marketplace pages
   **/
  initialState?: any
  /**
   * persist state to local storage
   */
  persistKey?: string
}
export const useCollections = ({ initialState, persistKey }: UseCollectionsProps) => {
  const setInitialState = useCallback(
    (initialState = {}): MarketplaceState => {
      let storedState = {}
      try {
        if (persistKey) storedState = JSON.parse(sessionStorage.getItem(persistKey) || '{}')
      } catch (e) {}
      return pickBy(Object.assign({}, initialState, storedState), identity) as MarketplaceState
    },
    [persistKey]
  )

  const [state, dispatch] = useImmerReducer<MarketplaceState, PayloadProps, typeof setInitialState>(
    reducer,
    initialState,
    setInitialState
  )

  // every time we change the filters, write to localStorage
  useEffect(() => {
    if (persistKey) sessionStorage.setItem(persistKey, JSON.stringify(state))
  }, [state, persistKey])

  const setNumFilters = useCallback(
    (numFilters: number) => {
      dispatch({ type: UseCollectionAction.SetNumFilters, value: numFilters })
    },
    [dispatch]
  )

  const setNumResults = useCallback(
    (numResults: number | null) => {
      dispatch({ type: UseCollectionAction.SetNumResults, value: numResults })
    },
    [dispatch]
  )

  const resetState = useCallback(() => {
    dispatch({ type: UseCollectionAction.ResetState, value: initialState })
  }, [dispatch, initialState])

  return { state, setNumFilters, setNumResults, resetState }
}

export const useCollectionItemPossibleVariants = ({ item, disable }: { item: CollectionItem; disable?: boolean }) => {
  const { hasPermission } = useAcl()
  const hasMarketplace = hasPermission('postals.create')

  /**
   * If if there is no approvedPostalId, this is a marketplace item.
   */
  const marketplaceQuery = useGraphqlQuery(
    GetMarketplaceProductDocument,
    { id: item.marketplaceProductId },
    { enabled: !item.approvedPostalId && hasMarketplace && !disable }
  )

  const marketplaceItem = useMemo(() => {
    return marketplaceQuery.data?.getMarketplaceProduct
  }, [marketplaceQuery.data?.getMarketplaceProduct])

  /**
   * If there is an approvedPostalId, this is an approved item.
   */
  const approvedQuery = useGraphqlQuery(
    GetApprovedPostalDocument,
    { id: item.approvedPostalId as string },
    { enabled: !!item.approvedPostalId && !disable }
  )
  const approvedItem = useMemo(() => {
    return approvedQuery.data?.getApprovedPostal
  }, [approvedQuery.data?.getApprovedPostal])

  const availableVariants = useMemo(() => {
    if (!item.approvedPostalId && hasMarketplace)
      return marketplaceItem?.variants?.filter((v) => v.status !== Status.Disabled)
    else return approvedItem?.variants?.filter((v) => v.status !== Status.Disabled)
  }, [approvedItem?.variants, hasMarketplace, item.approvedPostalId, marketplaceItem?.variants])

  return {
    approvedItem,
    marketplaceItem,
    availableVariants,
    isLoading: marketplaceQuery.isLoading || approvedQuery.isLoading,
  }
}
