import { Box, ButtonGroup, Collapse, Flex } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiChangeEvent } from '@postal-io/postal-ui'
import {
  UiFormControl,
  useAlertError,
  useAlerts,
  ZButton,
  ZCard,
  ZCardHeader,
  ZFormLabel,
  ZInputMoney,
} from '@postal-io/postal-ui'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import type { FormEvent } from 'react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'
import type { Account } from '../../../api'
import { GetAccountDocument, UpdateAccountDocument } from '../../../api'

interface FormState {
  budgetLowNotificationThreshold: number
  balanceLowNotificationThreshold: number
  dirty: boolean
}

export const AccountNotifications: React.FC = (props) => {
  const Alert = useAlerts()
  const getAccount = useGraphqlQuery(GetAccountDocument)

  const { id, notificationSettings } = useMemo(() => {
    return getAccount.data?.getAccount || ({} as Account)
  }, [getAccount.data?.getAccount])

  const [form, setForm] = useImmer<FormState>({
    budgetLowNotificationThreshold: notificationSettings?.budgetLowNotificationThreshold ?? 0,
    balanceLowNotificationThreshold: notificationSettings?.balanceLowNotificationThreshold ?? 0,
    dirty: false,
  })

  // reset form state based on known current value
  const handleReset = useCallback(() => {
    if (!notificationSettings) return
    setForm((draft: Record<string, any>) => {
      draft.budgetLowNotificationThreshold = notificationSettings.budgetLowNotificationThreshold
      draft.balanceLowNotificationThreshold = notificationSettings.balanceLowNotificationThreshold
      draft.dirty = false
    })
  }, [notificationSettings, setForm])

  const handleChange = ({ key, value }: UiChangeEvent<number>) => {
    setForm((draft: Record<string, any>) => {
      draft[key] = value
      draft.dirty =
        draft.budgetLowNotificationThreshold !== notificationSettings?.budgetLowNotificationThreshold ||
        draft.balanceLowNotificationThreshold !== notificationSettings?.balanceLowNotificationThreshold
    })
  }

  // initial state load
  useEffect(() => {
    handleReset()
  }, [handleReset])

  const updateAccount = useGraphqlMutation(UpdateAccountDocument)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = {
      notificationSettings: {
        budgetLowNotificationThreshold: form.budgetLowNotificationThreshold,
        balanceLowNotificationThreshold: form.balanceLowNotificationThreshold,
      },
    }
    try {
      await updateAccount.mutateAsync({ id, data })
      Alert.success('Settings updated')
    } catch (err) {
      Alert.error(err)
    }
  }

  useAlertError(getAccount.error)
  useAlertError(updateAccount.error)

  return (
    <ZCard
      isLoading={getAccount.isLoading}
      variant="form"
      {...props}
    >
      <ZCardHeader
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        p={8}
        pb={0}
        gap={2}
      >
        Notification Thresholds
        <ZInfoTooltip
          hasArrow
          label="Set the thresholds for all user notifications in the account."
        />
      </ZCardHeader>
      <Box p={8}>
        <form
          onSubmit={handleSubmit}
          name="notifications"
        >
          <UiFormControl id="budgetLowNotificationThreshold">
            <ZFormLabel fontSize="lg">Budget Low Threshold</ZFormLabel>
            <Flex alignItems="center">
              <ZInputMoney
                name="budgetLowNotificationThreshold"
                min={0}
                isDisabled={updateAccount.isLoading}
                isRequired
                value={form.budgetLowNotificationThreshold}
                onChange={handleChange}
              />
            </Flex>
          </UiFormControl>

          <UiFormControl
            mt={8}
            mb={12}
            id="balanceLowNotificationThreshold"
          >
            <ZFormLabel fontSize="lg">Balance Low Threshold</ZFormLabel>
            <Flex alignItems="center">
              <ZInputMoney
                name="balanceLowNotificationThreshold"
                min={0}
                isDisabled={updateAccount.isLoading}
                isRequired
                value={form.balanceLowNotificationThreshold}
                onChange={handleChange}
              />
            </Flex>
          </UiFormControl>
          <Collapse in={form.dirty}>
            <ButtonGroup
              w="100%"
              justifyContent="space-between"
            >
              <ZButton
                type="submit"
                colorScheme="atomicBlue"
                minW="120px"
              >
                Save
              </ZButton>
              <ZButton
                colorScheme="atomicGray"
                variant="ghost"
                onClick={handleReset}
                minW="120px"
              >
                Reset
              </ZButton>
            </ButtonGroup>
          </Collapse>
        </form>
      </Box>
    </ZCard>
  )
}
