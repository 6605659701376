import { Box, Flex, Grid, Image } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiSeparatorProps } from '@postal-io/postal-ui'
import { UiSeparator, UiTruncate, useAlertError, ZHeading, ZText } from '@postal-io/postal-ui'
import type { ApprovedPostalAnalytics } from 'api'
import { DataObjectType, Granularity, GroupBy, PostalAnalyticsV2Document, SortBy } from 'api'
import { useAssets } from 'hooks'
import { useMemo } from 'react'
import { NoDataOverlay } from '../NoDataOverlay'
import type { PlaceholderPostalAnalyticsV2 } from './data'
import { MetricV2, PLACEHOLDER_POSTAL_ANALYTICS_V2 } from './data'

interface PopularSendGiftEmailsColumnV2Props extends UiSeparatorProps {
  startDate: Date
  endDate: Date
  teamIds?: string[]
  userIds?: string[]
}

export const PopularSendsGiftEmailsColumn: React.FC<PopularSendGiftEmailsColumnV2Props> = ({
  startDate,
  endDate,
  teamIds,
  userIds,
  ...rest
}) => {
  const giftEmailAnalyticsQuery = useGraphqlQuery(
    PostalAnalyticsV2Document,
    {
      config: {
        startDate,
        endDate,
        teamIds,
        userIds,
        granularity: Granularity.All,
        type: DataObjectType.Postal,
        sortBy: SortBy.GiftEmailSentDesc,
        groupBy: GroupBy.Postal,
        limit: 5,
      },
    },
    { keepPreviousData: true }
  )

  const giftEmailAnalytics = useMemo(
    () => giftEmailAnalyticsQuery?.data?.postalAnalyticsV2 ?? [],
    [giftEmailAnalyticsQuery]
  )

  const showPlaceholder = useMemo(() => {
    return !giftEmailAnalytics.reduce((sum, cur) => sum + (cur?.deliveryEmailSent || 0), 0)
  }, [giftEmailAnalytics])

  const analytics = useMemo(() => {
    return showPlaceholder ? PLACEHOLDER_POSTAL_ANALYTICS_V2[MetricV2.GiftEmails] : giftEmailAnalytics
  }, [giftEmailAnalytics, showPlaceholder])

  useAlertError(giftEmailAnalyticsQuery.error)

  return (
    <Box position="relative">
      {showPlaceholder && <NoDataOverlay />}
      <UiSeparator
        separator={
          <Box
            h="1px"
            bg="atomicGray.200"
          />
        }
        {...rest}
      >
        {analytics.map((analytics, idx) => (
          <PopularSendsItemV2
            key={idx}
            analytics={analytics}
          />
        ))}
      </UiSeparator>
    </Box>
  )
}

interface PopularSendsItemV2Props {
  analytics: ApprovedPostalAnalytics | PlaceholderPostalAnalyticsV2
}

export const PopularSendsItemV2: React.FC<PopularSendsItemV2Props> = ({ analytics }) => {
  const { deliveryEmailAccepted, deliveryEmailSent, name, placeholderImage, previewImageUrl } = (analytics ?? {}) as any

  const rate = Math.round(((deliveryEmailAccepted || 0) / (deliveryEmailSent || 1)) * 100)

  const { assetUrlSrc } = useAssets()
  const { src, fallbackSrc } = assetUrlSrc(previewImageUrl, { fit: 'crop', crop: 'edges', w: 80, h: 80 })

  return (
    <Grid
      templateColumns="80px 3fr"
      gap={4}
      py={4}
    >
      <Box
        borderRadius="postal"
        borderWidth="1px"
        borderColor="atomicGray.300"
        boxSizing="border-box"
        overflow="hidden"
        display="flex"
        justifyContent="center"
        alignItems="center"
        h="80px"
      >
        <Image
          src={placeholderImage || src}
          fallbackSrc={fallbackSrc}
          objectFit="fill"
        />
      </Box>
      <Box>
        <ZText
          fontWeight="bold"
          color="atomicGray.800"
        >
          <UiTruncate
            text={name as string}
            length={src ? 33 : 55}
          />
        </ZText>
        <Flex
          justifyContent="space-between"
          pt={2}
        >
          <Box>
            <ZText
              color="atomicGray.500"
              fontSize="sm"
              fontWeight="normal"
            >
              Sent
            </ZText>
            <ZHeading
              fontSize="xl"
              fontWeight="normal"
            >
              {deliveryEmailSent || 0}
            </ZHeading>
          </Box>
          <Box>
            <ZText
              color="atomicGray.500"
              fontSize="sm"
              fontWeight="normal"
            >
              Accepted
            </ZText>
            <ZHeading
              fontSize="xl"
              fontWeight="normal"
            >
              {deliveryEmailAccepted || 0}
            </ZHeading>
          </Box>
          <Box>
            <ZText
              color="atomicGray.500"
              fontSize="sm"
              fontWeight="normal"
            >
              Rate
            </ZText>
            <ZHeading
              fontSize="xl"
              fontWeight="normal"
            >
              {rate}%
            </ZHeading>
          </Box>
        </Flex>
      </Box>
    </Grid>
  )
}
