import { DeleteIcon, InfoIcon } from '@chakra-ui/icons'
import type { FlexProps, ModalProps } from '@chakra-ui/react'
import {
  AspectRatio,
  Box,
  ButtonGroup,
  Flex,
  Grid,
  IconButton,
  Image,
  ModalFooter,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { createId } from '@paralleldrive/cuid2'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  arrayMoveImmutable,
  UiCard,
  UiConfirm,
  UiDialog,
  UiDropzone,
  UiIconButton,
  UiLink,
  UiSortableItem,
  UiSortableList,
  useAlerts,
  ZButton,
  ZHeading,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import { uploadAsset } from 'api/rest'
import { POSTAL_INVALIDATIONS, useApprovedPostalVersion, useAssets, useBackgroundQueue } from 'hooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type { ApprovedPostal, ImageReference } from '../../api'
import { AssetType, SaveAssetDocument, UpdateApprovedPostalDocument } from '../../api'
import { EventBannerImagesV2 } from './EventsBanner'

interface AssetImage extends ImageReference {
  id: string
}

interface AssetProps extends FlexProps {
  image: AssetImage
  onDelete: () => void
}
const Asset: React.FC<AssetProps> = ({ children, image, onDelete, ...rest }) => {
  const { assetUrlSrc } = useAssets()
  const { src, fallbackSrc } = assetUrlSrc(image.url)

  return (
    <>
      <Box
        position="relative"
        borderRadius="md"
        w="100%"
        h="100%"
        bg="gray.200"
        {...rest}
      >
        <IconButton
          icon={
            <DeleteIcon
              boxSize="30px"
              p={1}
            />
          }
          data-testid="EventImage_delete"
          aria-label="Delete Image"
          cursor="pointer"
          position="absolute"
          top={0}
          right={0}
          bg="rgba(255,255,255,0.3)"
          color="black"
          _hover={{ bg: 'rgba(0,0,0,0.8)', color: 'white' }}
          onMouseDown={() => onDelete()}
        ></IconButton>
        <Image
          src={src}
          fallbackSrc={fallbackSrc}
          alt={image.description || 'Image'}
          objectFit="cover"
          w="100%"
          h="100%"
          maxH="250px"
          borderRadius="md"
        />
      </Box>
    </>
  )
}
export interface ApprovedPostalEventImagesEditProps extends Omit<ModalProps, 'children'> {
  postal: ApprovedPostal
}
export const EventsApprovedPostalImagesEdit: React.FC<ApprovedPostalEventImagesEditProps> = ({ postal, ...props }) => {
  const transform = useApprovedPostalVersion()
  const Alert = useAlerts()
  const saveAsset = useGraphqlMutation(SaveAssetDocument)
  const [deleteImage, setDeleteImage] = useState<AssetImage>()
  const deleteDisclosure = useDisclosure()
  const imageInfoDisclosure = useDisclosure()

  const { queue, invalidate } = useBackgroundQueue()
  const updatePostal = useGraphqlMutation(UpdateApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.updateApprovedPostal.previewGenerationTask)
      invalidate(POSTAL_INVALIDATIONS)
    },
  })

  const [images, setImages] = useState<AssetImage[]>([])
  const { assetUrlSrc } = useAssets()

  const reset = useCallback(() => {
    setImages(postal?.imageUrls?.map((image) => ({ ...image, id: createId() })) || [])
  }, [postal?.imageUrls])

  // reset the saved images when new data comes in
  useEffect(() => {
    reset()
  }, [reset])

  const imageUrls = useMemo(() => {
    return images.map((image) => assetUrlSrc(image.url))
  }, [assetUrlSrc, images])

  const onSortStart = () => {
    document.body.style.cursor = 'move'
  }

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setImages((cur) => arrayMoveImmutable(cur, oldIndex, newIndex))
    document.body.style.cursor = ''
  }

  const onDrop = async (files: any) => {
    if (files.length < 1) return Alert.warning('Please drop only supported file types')
    const [{ name }] = files
    try {
      const res = (await uploadAsset(files)) as any
      const { requestId } = res.find(Boolean)
      const created = await saveAsset.mutateAsync({
        assetType: AssetType.ApprovedPostalImage,
        name,
        requestId,
      })
      const newAssetId = created?.saveAsset?.id
      if (newAssetId) {
        setImages((cur) => [...cur, { id: createId(), url: `asset://${newAssetId}` }])
      } else {
        Alert.warning('There was an error uploading the image, please try again.')
      }
    } catch (err) {
      console.error(err)
      Alert.warning(err)
    }
  }

  const onDelete = (image: AssetImage) => {
    setDeleteImage(image)
    deleteDisclosure.onOpen()
  }

  const handleDelete = () => {
    setImages((cur) => cur.filter((i) => i.id !== deleteImage?.id))
    deleteDisclosure.onClose()
  }

  const handleSave = async () => {
    try {
      await updatePostal.mutateAsync({
        id: postal.id,
        data: {
          imageUrls: images.map(({ id, ...img }) => img),
          ...transform(postal.category),
        },
      })
      Alert.success('Images Updated')
      props.onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const isLoading = saveAsset.isLoading || updatePostal.isLoading

  return (
    <>
      <ZModal
        size="6xl"
        scrollBehavior="inside"
        {...props}
      >
        <ZModalOverlay />
        <ZModalContent>
          <ZModalCloseButton />
          <ZModalHeader>Edit Event Images</ZModalHeader>
          <ZModalBody
            display="flex"
            flexDir="column"
            pb={8}
          >
            <EventBannerImagesV2 images={imageUrls} />
            <UiCard
              mt={8}
              bg="app.bg"
              flexGrow={1}
              display="flex"
              alignItems="center"
              flexDir="column"
            >
              <Flex alignItems="center">
                <ZHeading
                  size="h4"
                  mb={2}
                >
                  Event Images
                  <UiIconButton
                    onClick={imageInfoDisclosure.onOpen}
                    ml={4}
                    aria-label="event image size suggestions"
                    size="sm"
                    backgroundColor="atomicGray.100"
                    icon={
                      <InfoIcon
                        boxSize="25px"
                        color="atomicGray.900"
                      />
                    }
                    _hover={{ backgroundColor: 'gray.300' }}
                  />
                </ZHeading>
              </Flex>
              <ZText
                size="lg"
                maxW="500px"
                textAlign="center"
              >
                Your event landing page will show the first five images.
                <br />
                Click and drag the images below to reorder them.
              </ZText>
              <UiSortableList
                items={images}
                onSortStart={onSortStart}
                onSortEnd={onSortEnd}
                restrict="xp"
              >
                <Grid
                  templateColumns="repeat(auto-fit, minmax(100px, 1fr))"
                  gridGap={8}
                  w="100%"
                  mt={8}
                >
                  {images.map((image) => {
                    return (
                      <UiSortableItem
                        key={image.id}
                        id={image.id}
                        isDisabled={isLoading}
                      >
                        <AspectRatio ratio={3 / 4}>
                          <Asset
                            onDelete={() => onDelete(image)}
                            image={image}
                          />
                        </AspectRatio>
                      </UiSortableItem>
                    )
                  })}
                  <UiDropzone
                    onDrop={onDrop}
                    isLoading={saveAsset.isLoading}
                    isDisabled={isLoading}
                    accept={{
                      'image/jpeg': ['.jpg', '.jpeg'],
                      'image/png': ['.png'],
                      'image/gif': ['.gif'],
                      'image/svg+xml': ['.svg'],
                    }}
                    multiple={false}
                    data-testid="EventImages_Dropzone"
                    borderRadius="md"
                    minH="150px"
                  >
                    <ZText
                      color="gray.500"
                      textAlign="center"
                    >
                      Drag an image from your desktop here or click to select
                    </ZText>
                  </UiDropzone>
                </Grid>
              </UiSortableList>
            </UiCard>
          </ZModalBody>
          <ModalFooter
            pt={8}
            mt={0}
            bg="atomicGray.10"
            borderBottomRadius="20px"
          >
            <ButtonGroup
              w="100%"
              justifyContent="right"
              gap={2}
            >
              <ZButton
                colorScheme="atomicBlue"
                onClick={handleSave}
                isDisabled={isLoading}
                isLoading={updatePostal.isLoading}
              >
                Save Images
              </ZButton>
              <ZButton
                colorScheme="atomicPurple"
                onClick={reset}
              >
                Reset
              </ZButton>
              <ZButton
                variant="ghost"
                colorScheme="atomicGray"
                onClick={props.onClose}
              >
                Cancel
              </ZButton>
            </ButtonGroup>
          </ModalFooter>
        </ZModalContent>
      </ZModal>
      <UiConfirm
        title="Remove Image"
        isOpen={deleteDisclosure.isOpen}
        onConfirm={handleDelete}
        onClose={deleteDisclosure.onClose}
        buttonColor="red"
        buttonText="Delete"
      >
        <ZText>Are you sure you want to delete this image?</ZText>
      </UiConfirm>
      {imageInfoDisclosure.isOpen && <ImageInfoModal {...imageInfoDisclosure} />}
    </>
  )
}

const ImageInfoModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  return (
    <UiDialog
      title="Image Size Suggestions"
      isOpen={isOpen}
      onClose={onClose}
      status="info"
      size="lg"
    >
      <VStack
        spacing={4}
        alignItems="flex-start"
        mb={5}
      >
        <ZText>
          For 1 image - <strong>1500w X 550h</strong>
        </ZText>
        <ZText>
          For 2 images - <strong>800w X 1050h</strong>
        </ZText>
        <ZText>
          For 3 images - <strong>800w X 890h</strong>
        </ZText>
        <ZText>
          For 4 images - <strong>800w X 890h</strong> for the first two images, then <strong>800w X 450h</strong> for
          the remaining two images
        </ZText>
        <ZText>
          For 5 images - <strong>600w X 800h</strong>
        </ZText>
      </VStack>
      <ZText fontSize="lg">
        {' '}
        For more information, please refer to the help article found{' '}
        <UiLink
          href="https://help.postal.com/helpcenter/s/article/Custom-Event-Images-Guide"
          fontSize="lg"
          isExternal
        >
          here
        </UiLink>
        .
      </ZText>
    </UiDialog>
  )
}
