import { Fade, Flex, Slide, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiDataTable,
  UiTabList,
  UiTabPanel,
  UiTabPanels,
  UiTabs,
  ZButton,
  ZCard,
  ZCardBody,
  ZCardHeader,
  ZInputSearch,
  ZLink,
  ZModal,
  ZModalBackButton,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZMoney,
  ZTab,
  ZText,
  internalProgressBarProps,
  internalTableProps,
  useAlertError,
  useAlerts,
} from '@postal-io/postal-ui'
import type { Account, BillingAccount, BillingAccountMap, Team } from 'api'
import {
  BillingAccountType,
  BillingAccountsDocument,
  Currency,
  GetCachedCurrencyConfigsDocument,
  Status,
  UpdateAccountDocument,
  UpdateTeamDocument,
} from 'api'
import { useAcl } from 'hooks'
import cloneDeep from 'lodash/cloneDeep'
import React, { useMemo, useState } from 'react'
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import { LEGACY_CURRENCIES } from './BillingAccountCreate'
import { BillingAccountCreateInline } from './BillingAccountCreateInline'

interface BillingAccountsEditProps {
  account: Account
  team?: Team
  isOpen: boolean
  onClose: () => void
}

type CurrencyMap = {
  [currency: string]: BillingAccount[]
}

export const Q2_NEW_CURRENCIES = ['SGD', 'MXN', 'AUD']

export const BillingAccountsEdit: React.FC<BillingAccountsEditProps> = ({ account, team, isOpen, onClose }) => {
  const Alert = useAlerts()
  const addBillingDisclosure = useDisclosure()
  const { hasFeature } = useAcl()
  const hasCurrencyConfig = hasFeature('currency-config')
  const hasQ2NewCurrencies = hasFeature('q2NewCurrencies')

  // After currency config has been completely rolled out this can be removed.
  const legacyCurrencyList = Object.values(Currency)
    .sort((a, b) => (a < b ? 1 : -1))
    .filter((currency) => LEGACY_CURRENCIES.includes(currency))
    .filter((currency) => hasQ2NewCurrencies || !Q2_NEW_CURRENCIES.includes(currency))

  const currencyConfigQuery = useGraphqlQuery(GetCachedCurrencyConfigsDocument, {
    filter: { billingAccountEnabled: true },
  })
  useAlertError(currencyConfigQuery.error)

  const currencyList = useMemo(() => {
    // Map to currency
    const currencies = currencyConfigQuery.data?.getCachedCurrencyConfigs?.map((config) => config.currency) ?? []
    return hasCurrencyConfig ? currencies : legacyCurrencyList
  }, [currencyConfigQuery.data?.getCachedCurrencyConfigs, hasCurrencyConfig, legacyCurrencyList])

  const { billingAccountIds } = useMemo(() => {
    const item = team ?? account
    return { billingAccountIds: item.settings?.billingAccountIds || [] }
  }, [account, team])

  const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(undefined)
  const [selectedIds, setSelectedIds] = useState<BillingAccountMap[]>(billingAccountIds ?? [])
  const [filter, setFilter] = useState<string>('')

  const handleNewAccount = (input: BillingAccount) => {
    const normalizedInput = {
      currency: input.currency,
      billingAccountId: input.id,
    } as BillingAccountMap

    //see if there are already any of the same currency types
    const foundItems = selectedIds.some((item) => item.currency === input.currency)

    if (foundItems) {
      const newIds = selectedIds.map((item) => (item.currency === input.currency ? normalizedInput : item))
      setSelectedIds(newIds)
    } else {
      setSelectedIds([...selectedIds, normalizedInput])
    }
  }

  const billingAccountsQuery =
    useGraphqlQuery(BillingAccountsDocument, {
      filter: { status: { in: [Status.Active, null] }, type: { eq: BillingAccountType.Funds } },
    }) ?? {}
  const billingAccounts = billingAccountsQuery.data?.billingAccounts

  const updateAccount = useGraphqlMutation(UpdateAccountDocument)
  useAlertError(updateAccount.error)

  const updateTeam = useGraphqlMutation(UpdateTeamDocument)
  useAlertError(updateAccount.error)

  const { currencyMap } = useMemo(() => {
    const currencyMap =
      billingAccounts?.reduce((_currencyMap, account) => {
        const { currency } = account
        if (!currency) return _currencyMap
        if (!_currencyMap[currency]) _currencyMap[currency] = []
        if (account.name.toLowerCase().includes(filter.toLowerCase())) {
          _currencyMap[currency].push(account)
        }
        return _currencyMap
      }, {} as CurrencyMap) ?? {}
    return {
      currencyColumns: Object.keys(currencyMap).sort((key) => (key === Currency.Usd ? -1 : 1)),
      currencyMap,
    }
  }, [billingAccounts, filter])

  const handleFilterAccounts = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value)
  }

  const handleSelectAccount = (account: BillingAccount) => {
    // de-select any currently selected items for that currency only; then add new item
    let wasSelected = false
    const newSelectedIds = cloneDeep(selectedIds).filter((obj) => {
      if (obj.billingAccountId === account.id) wasSelected = true
      if (obj.currency === account.currency) return false
      else return true
    })

    if (!wasSelected) {
      newSelectedIds.push({
        currency: account.currency ?? Currency.Usd,
        billingAccountId: account.id,
      })
    }
    setSelectedIds(newSelectedIds)
  }

  const handleSaveAccounts = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      if (selectedIds.length === 0) {
        throw new Error('At least one Billing Account is required')
      }

      // check that only one billing account per currency was selected
      const { result: validNumItemsPerCurrency } = selectedIds.reduce(
        (acc, item) => {
          const c = item.currency
          if (!acc[c]) acc[c] = 0
          ++acc[c]
          if (acc[c] > 1) acc.result = false
          return acc
        },
        { result: true, map: {} } as any
      )

      if (!validNumItemsPerCurrency) {
        throw new Error('Only one Billing Account can be used for each currency')
      }

      if (team) {
        await updateTeam.mutateAsync({
          id: team.id,
          data: {
            settings: {
              ...team.settings,
              billingAccountIds: selectedIds,
            },
          },
        })
      } else if (account) {
        await updateAccount.mutateAsync({
          id: account.id,
          data: {
            settings: {
              ...account.settings,
              billingAccountIds: selectedIds,
            },
          },
        })
      }
      onClose()
    } catch (err) {
      Alert.warning(err)
    }
  }

  const handleClose = () => {
    setSelectedIds(billingAccountIds ?? [])
    onClose()
  }

  const handleAddAcctModal = (currency: string) => {
    setSelectedCurrency(currency)
    addBillingDisclosure.onOpen()
  }

  const isLoading = billingAccountsQuery.isFetching
  const orderedCurrencyList = useMemo(() => {
    return currencyList.map((currency) => {
      const currencyItems = !!currencyMap[currency] ? [...currencyMap[currency]] : []
      const selectedItem = currencyItems
        ?.filter((item) => selectedIds.map((id) => id.billingAccountId).includes(item.id))
        .find(Boolean)
      if (selectedItem) {
        const foundIndex = currencyItems?.indexOf(selectedItem)
        currencyItems.splice(foundIndex, 1)
        currencyItems.unshift(selectedItem)
      }
      return { [currency]: currencyItems } as Record<string, BillingAccount[] | []>
    })
  }, [currencyList, currencyMap, selectedIds])

  const handleBack = () => {
    setSelectedCurrency(undefined)
    addBillingDisclosure.onClose()
  }

  const ACCOUNT_COLUMNS = useMemo(
    () => [
      {
        label: 'Name',
        key: 'name',
        render: ({ name, id }: BillingAccount) => {
          const isSelected = selectedIds.map((obj) => obj.billingAccountId).includes(id)
          return <ZText color={isSelected ? 'atomicBlue.400' : 'atomicGray.600'}>{name}</ZText>
        },
      },
      {
        label: 'Balance',
        key: 'balance',
        render: ({ balance, id, currency }: BillingAccount) => {
          const isSelected = selectedIds.map((obj) => obj.billingAccountId).includes(id)
          return (
            <ZMoney
              cents={balance}
              color={isSelected ? 'atomicBlue.400' : 'atomicGray.600'}
              currency={currency}
            />
          )
        },
        rowProps: {
          w: '200px',
        },
      },
    ],
    [selectedIds]
  )

  return (
    <>
      <ZModal
        size="6xl"
        isOpen={isOpen}
        onClose={handleClose}
        scrollBehavior="inside"
      >
        <ZModalOverlay />
        <ZModalContent>
          <form
            onSubmit={handleSaveAccounts}
            id="BillingAccounsEdit_form"
            style={{ overflow: 'scroll' }}
          >
            <Fade
              in={addBillingDisclosure.isOpen && !!selectedCurrency}
              unmountOnExit
            >
              <ZModalBackButton
                onClick={handleBack}
                label="Back to billing accounts"
              />
            </Fade>
            <ZModalHeader>
              {addBillingDisclosure.isOpen && !!selectedCurrency
                ? 'Create New Billing Account'
                : 'Edit Billing Account'}
            </ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <Slide
                in={addBillingDisclosure.isOpen && !!selectedCurrency}
                style={{ position: 'relative', width: '100%' }}
                direction="right"
                unmountOnExit
              >
                <BillingAccountCreateInline
                  isOpen={addBillingDisclosure.isOpen && !!selectedCurrency}
                  onCreate={handleNewAccount}
                  currency={selectedCurrency}
                  account={account}
                  onClose={handleBack}
                />
              </Slide>

              <Slide
                in={!(addBillingDisclosure.isOpen && !!selectedCurrency)}
                style={{ position: 'relative', width: '100%' }}
                direction="left"
                unmountOnExit
              >
                <Flex
                  flexDir="column"
                  align="center"
                  bg="atomicGray.10"
                  color="atomicGray.600"
                  p={8}
                  mb={8}
                >
                  <ZText
                    textAlign="center"
                    color="inherit"
                    fontSize="md"
                  >
                    Link multiple accounts to start sending internationally. You can only link one account per currency.
                    <br />
                    <ZLink
                      href="https://help.postal.com/helpcenter/s/article/How-to-Link-a-Team-to-Multiple-Billing-Accounts"
                      isExternal
                      fontSize="inherit"
                    >
                      Click here
                    </ZLink>{' '}
                    to see full documentation.
                  </ZText>
                </Flex>
                <ZInputSearch
                  rootProps={{ width: '100%' }}
                  placeholder="Filter Accounts"
                  value={filter}
                  onChange={handleFilterAccounts}
                  pr={10}
                />
                <UiTabs>
                  <UiTabList>
                    {orderedCurrencyList.map((accountObj) => {
                      const currency = Object.keys(accountObj)[0]

                      return <ZTab key={currency}>{currency}</ZTab>
                    })}
                  </UiTabList>
                  <UiTabPanels pt={4}>
                    {orderedCurrencyList.map((acc) => {
                      const currency = Object.keys(acc)[0]
                      const accountList = acc[currency]

                      return (
                        <UiTabPanel key={`tab-panel-${currency}`}>
                          <ZCard variant="form">
                            <ZCardHeader
                              justifyContent="flex-end"
                              p={8}
                              pb={4}
                            >
                              <ZButton
                                variant="link"
                                size="sm"
                                colorScheme="atomicBlue"
                                color="atomicBlue.400"
                                onClick={() => handleAddAcctModal(currency)}
                                iconSpacing={1}
                                leftIcon={
                                  <MdOutlineAddCircleOutline
                                    size="18px"
                                    style={{ transform: 'translateY(-1px)' }}
                                  />
                                }
                              >
                                Add {currency} Account
                              </ZButton>
                            </ZCardHeader>
                            <ZCardBody
                              p={8}
                              pt={0}
                            >
                              <UiDataTable
                                variant="list"
                                w="full"
                                pageSize={20}
                                columns={ACCOUNT_COLUMNS}
                                rows={accountList}
                                HeaderButton={ZButton}
                                rowKey="id"
                                tableProps={internalTableProps}
                                progressBarProps={internalProgressBarProps}
                                rowHoverColor="atomicGray.10"
                                onClick={handleSelectAccount}
                              />
                            </ZCardBody>
                          </ZCard>
                        </UiTabPanel>
                      )
                    })}
                  </UiTabPanels>
                </UiTabs>
                <ZModalButtons
                  confirmText="Save"
                  confirmProps={{
                    type: 'submit',
                  }}
                  onClose={handleClose}
                  isConfirmDisabled={isLoading}
                  isConfirmLoading={isLoading}
                  px={0}
                />
              </Slide>
            </ZModalBody>
          </form>
        </ZModalContent>
      </ZModal>
    </>
  )
}
