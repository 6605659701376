import { CheckCircleIcon } from '@chakra-ui/icons'
import { Box, Flex } from '@chakra-ui/react'
import {
  UiDateTime,
  UiIconBamboo,
  UiIconCalendly2,
  UiIconChiliPiper,
  UiIconEloqua,
  UiIconGoogle,
  UiIconHubspot,
  UiIconMarketo,
  UiIconMicrosoft,
  UiIconOutreach,
  UiIconPostalPlane,
  UiIconSalesforce,
  UiIconSalesforceSandbox,
  UiIconSalesloftV2,
  UiIconSlack,
  UiIconSwagup,
  UiIconWorkday,
  UiIconZapier,
  ZLogoMark2,
} from '@postal-io/postal-ui'
import type { IntegrationSync } from 'api'
import { CalendarProvider as CalendarProviderEnum } from 'api'
import type { AclFilter } from 'hooks'
import { isLocalOrTestEnvironment } from 'lib'
import { capitalize } from 'lodash'
import React from 'react'
import { MdOutlineCheckCircleOutline } from 'react-icons/md'
import { UiIconDynamics } from './UiIconDynamics'

// Workato recipe display names
export const recipeNameMap = {
  'COM-workday-trigger-new_role': 'Promotion/New Role',
  'COM-workday-trigger-birthday': 'Birthday',
  'COM-workday-trigger-anniversary': 'Work Anniversary',
  'COM-workday-trigger-new_hire': 'New Hire',
}

export enum TriggerType {
  'Filter' = 'Filter',
  'Sequence' = 'Sequence',
  'Program' = 'Program',
  'External' = 'External',
  'Recipe' = 'Recipe',
}

export enum IntegrationType {
  'User' = 'User',
  'Product' = 'Product',
}

export enum ExternalSystem {
  Google = 'google',
  Microsoft = 'microsoft',
  Eloqua = 'eloqua',
  HubSpot = 'hubspot',
  Marketo = 'marketo',
  Outreach = 'outreach',
  SalesLoft = 'salesloft',
  Salesforce = 'sfdc',
  SalesforceSandbox = 'sfdcsandbox',
  Slack = 'slack',
  SwagUp = 'swagup',
  Calendly = 'calendly',
  ChiliPiper = 'chilipiper',
  Workday = 'workday',
  Bamboo = 'bamboo',
  Zapier = 'zapier',
  MicrosoftDynamics = 'dynamics',
  Test = 'test',
}

export interface GenericProvider extends AclFilter {
  enabled?: boolean
}

export interface TriggerProvider extends GenericProvider {
  type: TriggerType
  requiresIntegration: boolean
}

export interface IntegrationProvider extends GenericProvider {
  endpoint: string
  authType: string
  type: IntegrationType
  hasContactCreate?: boolean
  hasAttribution?: boolean
}

export interface WorkatoIntegrationProvider extends GenericProvider {
  connectionPrefix: string
  recipePrefix: string
  hasContactCreate?: boolean
  hasAttribution?: boolean
  userSync?: boolean
}

export interface SsoProvider extends GenericProvider {
  name: string
  endpoint: string
  authType: string
}

export interface EmailProvider extends GenericProvider {
  endpoint: string
  authType: string
}

export interface CalendarProvider extends GenericProvider {
  name: CalendarProviderEnum
  sync: boolean
  endpoint?: string
  authType?: string
}

export interface ExternalProvider {
  system: ExternalSystem
  name: string
  link?: string
  icon: React.ElementType
  trigger?: TriggerProvider
  integration?: IntegrationProvider
  workato?: WorkatoIntegrationProvider
  sso?: SsoProvider
  email?: EmailProvider
  calendar?: CalendarProvider
}

export const ExternalProviders: ExternalProvider[] = [
  {
    system: ExternalSystem.Slack,
    name: 'Slack',
    icon: UiIconSlack,
    integration: {
      endpoint: 'slack',
      type: IntegrationType.User,
      enabled: true,
      authType: 'sso:slack',
      feature: 'slack',
    },
    trigger: {
      type: TriggerType.External,
      enabled: true,
      feature: 'slack',
      module: 'triggers.create',
      requiresIntegration: true,
    },
  },
  {
    system: ExternalSystem.Eloqua,
    name: 'Eloqua',
    icon: UiIconEloqua,
    link: 'https://login.eloqua.com',
    integration: {
      endpoint: 'eloqua',
      type: IntegrationType.User,
      enabled: true,
      authType: 'sso:eloqua',
      feature: 'eloqua',
    },
    trigger: {
      type: TriggerType.External,
      enabled: true,
      feature: 'eloqua',
      module: 'triggers.create',
      requiresIntegration: true,
    },
  },
  {
    system: ExternalSystem.HubSpot,
    name: 'HubSpot',
    icon: UiIconHubspot,
    link: 'https://hubspot.com',
    integration: {
      endpoint: 'hubspot',
      type: IntegrationType.User,
      authType: 'sso:hubspot',
      enabled: true,
      hasContactCreate: true,
    },
    trigger: {
      type: TriggerType.External,
      enabled: true,
      module: 'triggers.create',
      requiresIntegration: true,
    },
  },
  {
    system: ExternalSystem.Zapier,
    name: 'Zapier',
    icon: UiIconZapier,
    link: 'https://zapier.com',
    trigger: {
      type: TriggerType.External,
      enabled: true,
      feature: 'userZapier',
      module: 'postals.send',
      requiresIntegration: false,
    },
  },
  {
    system: ExternalSystem.Marketo,
    name: 'Marketo',
    icon: UiIconMarketo,
    link: 'https://marketo.com',
    integration: {
      endpoint: 'marketo',
      type: IntegrationType.User,
      authType: 'sso:marketo',
      enabled: true,
      feature: 'marketo',
    },
    trigger: {
      type: TriggerType.Program,
      enabled: true,
      feature: 'marketo',
      module: 'triggers.create',
      requiresIntegration: true,
    },
  },
  {
    system: ExternalSystem.Outreach,
    name: 'Outreach',
    icon: UiIconOutreach,
    link: 'https://outreach.io',
    integration: {
      endpoint: 'outreach',
      type: IntegrationType.User,
      authType: 'sso:outreach',
      enabled: true,
    },
    trigger: {
      type: TriggerType.Sequence,
      enabled: true,
      module: 'triggers.create',
      requiresIntegration: true,
    },
  },
  {
    system: ExternalSystem.SalesLoft,
    name: 'SalesLoft',
    icon: UiIconSalesloftV2,
    link: 'https://salesloft.com',
    integration: {
      endpoint: 'salesloft',
      type: IntegrationType.User,
      authType: 'sso:salesloft',
      enabled: true,
      feature: 'salesloft',
    },
    trigger: {
      type: TriggerType.Sequence,
      enabled: true,
      feature: 'salesloft',
      module: 'triggers.create',
      requiresIntegration: true,
    },
  },
  {
    system: ExternalSystem.MicrosoftDynamics,
    name: 'Microsoft Dynamics',
    icon: UiIconDynamics,
    workato: {
      connectionPrefix: 'Microsoft Dynamics',
      recipePrefix: 'COM-microsoft_dynamics-',
      enabled: true,
      feature: 'dynamics',
      userSync: true,
    },
  },
  {
    system: ExternalSystem.Salesforce,
    name: 'Salesforce',
    icon: UiIconSalesforce,
    link: 'https://salesforce.com',
    integration: {
      endpoint: 'sfdc',
      type: IntegrationType.User,
      authType: 'sso:sfdc',
      enabled: true,
      hasContactCreate: true,
      hasAttribution: true,
    },
    trigger: {
      type: TriggerType.Filter,
      enabled: true,
      feature: 'sfdcTriggers',
      module: 'triggers.create',
      requiresIntegration: true,
    },
    sso: {
      name: 'salesforce',
      endpoint: 'sfdc',
      authType: 'sso:sfdc',
      enabled: true,
    },
  },
  {
    system: ExternalSystem.SalesforceSandbox,
    name: 'Salesforce Sandbox',
    icon: UiIconSalesforceSandbox,
    link: 'https://salesforce.com',
    integration: {
      endpoint: 'sfdcsandbox',
      type: IntegrationType.User,
      authType: 'sso:sfdcsandbox',
      enabled: true,
      feature: 'sfdcSandbox',
      hasContactCreate: true,
      hasAttribution: true,
    },
    trigger: {
      type: TriggerType.Filter,
      enabled: true,
      feature: ['sfdcSandbox', 'sfdcTriggers'],
      module: 'triggers.create',
      requiresIntegration: true,
    },
  },
  {
    system: ExternalSystem.Google,
    name: 'Google',
    icon: UiIconGoogle,
    sso: {
      name: 'google',
      endpoint: 'google',
      authType: 'sso:google',
      enabled: true,
    },
    email: {
      endpoint: 'google_email',
      authType: 'sso:google_email',
      feature: 'googleEmail',
      enabled: true,
    },
    calendar: {
      name: CalendarProviderEnum.Google,
      sync: true,
      enabled: true,
      endpoint: 'google_email',
      authType: 'sso:google_email',
      feature: 'googleCalendar',
    },
  },
  {
    system: ExternalSystem.Microsoft,
    name: 'Microsoft',
    icon: UiIconMicrosoft,
    sso: {
      name: 'microsoft',
      endpoint: 'azuread',
      authType: 'sso:azuread',
      enabled: true,
    },
    email: {
      endpoint: 'microsoft_email',
      authType: 'sso:microsoft_email',
      enabled: true,
      feature: 'microsoftEmail',
    },
    calendar: {
      name: CalendarProviderEnum.Microsoft,
      sync: true,
      enabled: true,
      endpoint: 'microsoft_email',
      authType: 'sso:microsoft_email',
      feature: 'microsoftCalendar',
    },
  },
  {
    system: ExternalSystem.Workday,
    name: 'Workday',
    icon: UiIconWorkday,
    link: 'https://workday.com',
    workato: {
      connectionPrefix: 'Workday',
      recipePrefix: 'COM-workday-',
      enabled: true,
      feature: 'workday',
    },
    trigger: {
      type: TriggerType.Recipe,
      enabled: true,
      feature: 'workday',
      module: 'triggers.create',
      requiresIntegration: true,
    },
  },
  {
    system: ExternalSystem.Bamboo,
    name: 'BambooHR',
    icon: UiIconBamboo,
    link: 'https://bamboohr.com',
    workato: {
      connectionPrefix: 'Bamboo',
      recipePrefix: 'COM-bamboo-',
      enabled: true,
      feature: 'bamboo',
    },
    trigger: {
      type: TriggerType.Recipe,
      enabled: true,
      feature: 'bamboo',
      module: 'triggers.create',
      requiresIntegration: true,
    },
  },
  {
    system: ExternalSystem.SwagUp,
    name: 'SwagUp',
    icon: UiIconSwagup,
    link: 'https://swagup.com',
    integration: {
      endpoint: 'swagup',
      type: IntegrationType.Product,
      authType: 'sso:swagup',
      enabled: true,
      feature: 'swag',
      module: 'swag.create',
    },
  },
  {
    system: ExternalSystem.Calendly,
    name: 'Calendly',
    icon: UiIconCalendly2,
    calendar: {
      name: CalendarProviderEnum.Calendly,
      sync: false,
      enabled: true,
      feature: 'calendly',
    },
  },
  {
    system: ExternalSystem.ChiliPiper,
    name: 'Chili Piper',
    icon: UiIconChiliPiper,
    calendar: {
      name: CalendarProviderEnum.ChiliPiper,
      sync: false,
      enabled: true,
      feature: 'chiliPiper',
    },
  },
  {
    system: ExternalSystem.Test,
    name: 'Test',
    icon: UiIconPostalPlane,
    calendar: {
      name: CalendarProviderEnum.Test,
      sync: true,
      enabled: isLocalOrTestEnvironment,
    },
  },
]

export const ExternalProvidersV2 = ExternalProviders.filter((p, idx) => idx < ExternalProviders.length - 1).concat({
  system: ExternalSystem.Test,
  name: 'Test',
  icon: ZLogoMark2,
  calendar: {
    name: CalendarProviderEnum.Test,
    sync: true,
    enabled: isLocalOrTestEnvironment,
  },
})

export const getExternalProvider = (system: string) => {
  return ExternalProviders.find((p) => p.system === system)
}

export const INTEGRATION_HISTORY_COLUMNS = [
  {
    key: 'system',
    label: 'System Name',
    render: ({ system }: IntegrationSync) => getExternalProvider(system)?.name ?? capitalize(system),
  },
  {
    key: 'objectType',
    label: 'Object Type',
    render: ({ objectType }: IntegrationSync) => capitalize(objectType.toLowerCase()),
  },
  {
    key: 'status',
    label: 'Enabled',
    render: ({ status }: IntegrationSync) =>
      status === 'ACTIVE' && (
        <Flex
          justifyContent="center"
          w="72px"
        >
          <CheckCircleIcon
            fontSize="lg"
            color="tertiary.500"
          />
        </Flex>
      ),
  },
  {
    key: 'syncTime',
    label: 'Last Sync Time',
    render: ({ syncTime }: IntegrationSync) => (
      <UiDateTime
        date={syncTime}
        format={{ dateStyle: 'medium', timeStyle: 'short' }}
        fallback="-"
      />
    ),
  },
  {
    key: 'lastRun',
    label: 'Last Run',
    render: ({ lastRun }: IntegrationSync) => (
      <UiDateTime
        date={lastRun}
        format={{ dateStyle: 'medium', timeStyle: 'short' }}
        fallback="-"
      />
    ),
  },
  {
    key: 'nextRun',
    label: 'Next Run',
    render: ({ nextRun }: IntegrationSync) => (
      <UiDateTime
        date={nextRun}
        format={{ dateStyle: 'medium', timeStyle: 'short' }}
        fallback="-"
      />
    ),
  },
]

export const INTEGRATION_HISTORY_COLUMNSV2 = [
  {
    key: 'system',
    label: 'System Name',
    render: ({ system }: IntegrationSync) => getExternalProvider(system)?.name ?? capitalize(system),
  },
  {
    key: 'objectType',
    label: 'Object Type',
    render: ({ objectType }: IntegrationSync) => capitalize(objectType.toLowerCase()),
  },
  {
    key: 'status',
    label: 'Enabled',
    render: ({ status }: IntegrationSync) =>
      status === 'ACTIVE' && (
        <Flex
          justifyContent="center"
          w="72px"
        >
          <Box color="atomicBlue.400">
            <MdOutlineCheckCircleOutline
              size="20px"
              color="inherit"
            />
          </Box>
        </Flex>
      ),
  },
  {
    key: 'syncTime',
    label: 'Last Sync Time',
    render: ({ syncTime }: IntegrationSync) => (
      <UiDateTime
        date={syncTime}
        format={{ dateStyle: 'medium', timeStyle: 'short' }}
        fallback="-"
      />
    ),
  },
  {
    key: 'lastRun',
    label: 'Last Run',
    render: ({ lastRun }: IntegrationSync) => (
      <UiDateTime
        date={lastRun}
        format={{ dateStyle: 'medium', timeStyle: 'short' }}
        fallback="-"
      />
    ),
  },
  {
    key: 'nextRun',
    label: 'Next Run',
    render: ({ nextRun }: IntegrationSync) => (
      <UiDateTime
        date={nextRun}
        format={{ dateStyle: 'medium', timeStyle: 'short' }}
        fallback="-"
      />
    ),
  },
]
