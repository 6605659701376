import { Box, Center, Checkbox, Flex, Text } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { UiButton, UiDialog, useAlerts } from '@postal-io/postal-ui'
import React, { useState } from 'react'
import type { ContactList, SearchableContactFilterInput } from '../../api'
import { BulkContactAddToListDocument, BulkContactRemoveFromListDocument } from '../../api'
import { CONTACT_INVALIDATIONS, useBackgroundQueue, useSession } from '../../hooks'
import { AutoCompleteContactListCreatable } from '../AutoComplete'

interface ContactsBulkListV2Props {
  isOpen: boolean
  onClose: () => void
  onComplete: () => void
  count: number
  filters: SearchableContactFilterInput[]
  selectedList?: ContactList
}

export const ContactsBulkList: React.FC<ContactsBulkListV2Props> = ({
  isOpen,
  onClose,
  onComplete,
  count,
  filters,
  selectedList,
}) => {
  const [selected, setSelected] = useState<ContactList[]>(selectedList ? [selectedList] : [])
  const [action, setAction] = useState<'add' | 'remove'>('add')
  const Alert = useAlerts()
  const { session } = useSession()

  const { queue, invalidate } = useBackgroundQueue()

  // mutations
  const bulkListAdd = useGraphqlMutation(BulkContactAddToListDocument)
  const bulkListRemove = useGraphqlMutation(BulkContactRemoveFromListDocument)

  const addLists = () => {
    const promises = selected.map((list) =>
      bulkListAdd
        .mutateAsync({
          data: { name: list.name, userId: session.userId, listId: list.id },
          orfilters: filters,
        })
        .then((data) => queue(data.bulkContactAddToList))
    )
    return Promise.all(promises).finally(() => invalidate(CONTACT_INVALIDATIONS))
  }

  const removeLists = () => {
    const promises = selected.map((list) =>
      bulkListRemove
        .mutateAsync({
          data: { name: list.name, userId: session.userId, listId: list.id },
          orfilters: filters,
        })
        .then((data) => queue(data.bulkContactRemoveFromList))
    )
    return Promise.all(promises).finally(() => invalidate(CONTACT_INVALIDATIONS))
  }

  const contactWord = count > 1 ? 'Contacts' : 'Contact'

  const handleAction = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setAction(checked ? 'remove' : 'add')
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (selected.length < 1) return Alert.warning('Please select at least one list')
    try {
      !!selectedList || action === 'remove' ? await removeLists() : await addLists()
      Alert.success(`Lists updated for ${count} ${contactWord}`)
    } catch (err) {
      Alert.error(err)
    } finally {
      onComplete()
      onClose()
    }
  }

  return (
    <UiDialog
      status="info"
      title={`Update Lists for ${count} ${contactWord}`}
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
    >
      <Box>
        <Text
          mb={4}
          textAlign="center"
        >
          {selectedList ? (
            <span>
              Do you want to remove the selected contacts from the <strong>{selectedList.name}</strong> list?
            </span>
          ) : (
            <span>
              Please select the <strong>lists</strong> to update on each Contact
            </span>
          )}
        </Text>
        <form onSubmit={handleSubmit}>
          {!selectedList && (
            <Center>
              <Checkbox
                size="md"
                name="sendNotification"
                id="sendNotification"
                isChecked={action === 'remove'}
                onChange={handleAction}
              >
                Remove these lists
              </Checkbox>
            </Center>
          )}
          <Flex
            alignItems="bottom"
            justifyContent="center"
            flexWrap="wrap"
            mt={4}
            mb={-4}
          >
            <AutoCompleteContactListCreatable
              rootProps={{ ml: 4, mb: 4, minW: '300px' }}
              isDisabled={bulkListAdd.isLoading || bulkListRemove.isLoading || !!selectedList}
              value={selected}
              onChange={(tags) => setSelected([...tags])}
              onCreate={(tag) => setSelected((selected) => [...selected, tag])}
              getNewOptionData={(id, name) => ({ id: name, name } as ContactList)}
            />
            <UiButton
              ml={4}
              mb={4}
              type="submit"
              isDisabled={bulkListAdd.isLoading || bulkListRemove.isLoading}
            >
              {!!selectedList ? 'Remove From List' : 'Update Lists'}
            </UiButton>
          </Flex>
        </form>
      </Box>
    </UiDialog>
  )
}
