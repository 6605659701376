import { useClipboard } from '@chakra-ui/react'
import { useAlerts } from '@postal-io/postal-ui'
import { useCallback, useEffect } from 'react'

/*
  Not a huge fan of all these effects. I wish onCopy accepted a value, that would make this so much easier.
  Maybe we should make our own?

  Anyway, I think this will be more stable as we are sometimes calling this in event handlers.
*/

export const useCopyMagicLink = () => {
  const { onCopy, setValue, value, hasCopied } = useClipboard('')
  const Alert = useAlerts()

  useEffect(() => {
    if (hasCopied) {
      Alert.success('MagicLink URL copied to Clipboard', { title: 'Share your Link' })
      setValue('')
    }
  }, [Alert, hasCopied, setValue])

  useEffect(() => {
    if (value) onCopy()
  }, [onCopy, value])

  return useCallback(
    (url?: string) => {
      if (!url) return Alert.warning('Oh no.  Something went wrong, please try again.')
      if (
        window.location.origin.startsWith('http://localhost') ||
        window.location.origin.startsWith('http://127.0.0.1')
      ) {
        const newURL = new URL(new URL(url).pathname, window.location.origin)
        setValue(newURL.toString())
      } else {
        setValue(url)
      }
    },
    [Alert, setValue]
  )
}
