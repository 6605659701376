import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import { ZHeading, ZMoney, ZText } from '@postal-io/postal-ui'
import type { MagicLink } from 'api'
import { calculateCpt } from 'lib'
import React from 'react'

interface ExtLinkStatsProps extends BoxProps {
  link?: MagicLink
}

export const ExtLinkStats: React.FC<ExtLinkStatsProps> = ({ link, ...rest }) => {
  const metrics = link?.metrics

  return (
    <Box {...rest}>
      <ZHeading
        size="h4"
        mb="10px"
      >
        {link?.name || 'n/a'}
      </ZHeading>
      <Flex columnGap={5}>
        <ZText
          size="md"
          color="atomicGray.500"
        >
          {/* {metrics?.linkExecutions || 0} Orders */}
          {link?.maxExecutions || 0} Orders
        </ZText>
        <ZText
          size="md"
          color="atomicGray.500"
        >
          <ZMoney
            cents={metrics?.costInCents || 0}
            color="atomicGray.500"
          />{' '}
          Total Cost
        </ZText>
        <ZText
          size="md"
          color="atomicGray.500"
        >
          <ZMoney
            cents={calculateCpt(metrics)}
            color="atomicGray.500"
          />{' '}
          CPO
        </ZText>
      </Flex>
    </Box>
  )
}
