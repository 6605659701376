import { Text } from '@chakra-ui/react'
import {
  joinStrings,
  ZCard,
  ZCardBody,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
} from '@postal-io/postal-ui'
import React from 'react'
import { UserAccountAdd } from './UserAccountAdd'
import { UserAccountsTable } from './UserAccountsTable'

interface UserAccountsEditProps {
  userId: string
  firstName?: string | null
  lastName?: string | null
  isOpen: boolean
  onClose: () => void
  onComplete?: () => void
}

export const UserAccountsEdit: React.FC<UserAccountsEditProps> = ({ userId, firstName, lastName, isOpen, onClose }) => {
  const title = joinStrings([firstName, lastName], ' ', 'Team Membership')

  return (
    <ZModal
      size="3xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ZModalOverlay />
      <ZModalContent>
        <ZModalHeader>{title}</ZModalHeader>
        <ZModalCloseButton />
        <ZModalBody pb={8}>
          <ZCard
            variant="form"
            mb={8}
          >
            <ZCardBody p={8}>
              <Text
                mb={4}
                textAlign="center"
              >
                Please select the <strong>team</strong> and <strong>roles</strong> you would like granted to this user.
              </Text>
              <UserAccountAdd userId={userId} />
            </ZCardBody>
          </ZCard>
          <ZCard
            variant="form"
            m={0}
          >
            <ZCardBody p={8}>
              <UserAccountsTable
                userId={userId}
                variant="list"
              />
            </ZCardBody>
          </ZCard>
        </ZModalBody>
      </ZModalContent>
    </ZModal>
  )
}
