import { Box, Flex } from '@chakra-ui/react'
import { ZLink } from '@postal-io/postal-ui'
import { ColorPicker } from 'components/Branding/Colors'
import React, { useMemo } from 'react'
import { MdOutlinePalette } from 'react-icons/md'

export interface ColorSelectorProps {
  value: string
  onChange: (color: string) => void
}

const BLACK = '#000000'
const BLUE = '#235591'
const RED = '#ac3235'
const GREEN = '#239127'

export const ColorSelector: React.FC<ColorSelectorProps> = ({ value, onChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    onChange(value || BLACK)
  }

  const linkProps = {
    w: '38px',
    h: '38px',
    borderRadius: 'full',
  }

  const selectedColor = (color: string) => {
    return color === value
      ? {
          borderWidth: '10px',
          borderColor: color,
        }
      : {
          bgColor: color,
        }
  }

  const isCustomColor = useMemo(() => ![BLACK, BLUE, RED, GREEN].includes(value), [value])

  const customColorSelected = useMemo(() => {
    return isCustomColor
      ? {
          _after: {
            content: '""',
            display: 'block',
            width: '50%',
            height: '50%',
            zIndex: 9999,
            borderRadius: 'full',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
          },
        }
      : {}
  }, [isCustomColor])

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
      >
        <ZLink
          {...selectedColor('#000000')}
          onClick={() => onChange(BLACK)}
          {...linkProps}
        />
        <ZLink
          {...selectedColor('#235591')}
          onClick={() => onChange(BLUE)}
          {...linkProps}
        />
        <ZLink
          {...selectedColor('#ac3235')}
          onClick={() => onChange(RED)}
          {...linkProps}
        />
        <ZLink
          {...selectedColor('#239127')}
          onClick={() => onChange(GREEN)}
          {...linkProps}
        />
        <Box
          position="relative"
          w="38px"
          h="38px"
        >
          {!isCustomColor && (
            <Flex
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              justifyContent="center"
              alignItems="center"
              color="white"
              zIndex={10000}
              bgColor="rgba(0,0,0,.25)"
              borderRadius="full"
              pointerEvents="none"
            >
              <MdOutlinePalette size="22px" />
            </Flex>
          )}
          <ColorPicker
            value={value}
            onChange={handleChange}
            id="textColorPicker"
            w="38px"
            h="38px"
            {...customColorSelected}
          />
        </Box>
      </Flex>
    </Box>
  )
}
