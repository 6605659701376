import type { BoxProps } from '@chakra-ui/react'
import {
  AspectRatio,
  Fade,
  Flex,
  Grid,
  HStack,
  Icon,
  IconButton,
  Kbd,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { UiActionBar, UiIconSelectAll, UiSlideImage, UiTooltip, UiTruncate, ZLink, ZText } from '@postal-io/postal-ui'
import type { ApprovedPostal, MarketplaceProduct, SearchableProduct } from 'api'
import type { EitherItem } from 'components/Collections/utils'
import { MarketplaceApprovedPostalPriceRange } from 'components/Marketplace/MarketplaceTextPriceRange'
import { SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { useAssets, usePostalImages } from 'hooks'
import type { PropsWithChildren } from 'react'
import React, { useMemo } from 'react'
import { MdClose, MdSelectAll } from 'react-icons/md'

/**
 * Action bar - the one at the bottom of the screen. Deprecated soon and moving to top of the screen navbar style.
 */

interface BulkSelectActionBarProps {
  isOpen?: boolean
  selectedLength?: number
  isEverythingSelected?: boolean
  selectedItemsText: string
  onSelectAll?: () => void
  onDeselectAll?: () => void
}

export const BulkSelectActionBar: React.FC<PropsWithChildren<BulkSelectActionBarProps>> = ({
  isOpen,
  isEverythingSelected,
  selectedItemsText,
  onSelectAll,
  onDeselectAll,
  children,
}) => {
  const handleSelectAll = () => {
    if (isEverythingSelected) onDeselectAll?.()
    else onSelectAll?.()
  }

  const label = useMemo(() => (isEverythingSelected ? 'De-select All' : 'Select All'), [isEverythingSelected])

  return (
    <UiActionBar
      gridProps={{ border: 'none' }}
      backgroundColor="atomicGray.900"
      isOpen={isOpen}
      left={
        <HStack spacing={2}>
          {onSelectAll && (
            <>
              <UiTooltip
                label={label}
                placement="top"
                hasArrow
                closeOnClick={false}
              >
                <IconButton
                  aria-label={label}
                  icon={
                    <UiIconSelectAll
                      fontSize="4xl"
                      color="white"
                    />
                  }
                  variant="unstyled"
                  onClick={handleSelectAll}
                />
              </UiTooltip>
              <ZText
                fontSize="body-lg"
                variant="bold"
                color="white"
              >
                {label}
              </ZText>
            </>
          )}
          <ZText
            fontSize="body-lg"
            color="white"
          >
            {selectedItemsText}
          </ZText>
        </HStack>
      }
      center={<>{children}</>}
      right={
        <ZText
          fontSize="body-lg"
          variant="bold"
          color="white"
        >
          <Kbd
            mr={2}
            bg="none"
            borderColor="white"
          >
            click
          </Kbd>
          to select,
          <Kbd
            ml={2}
            mr={2}
            bg="none"
            borderColor="white"
          >
            esc
          </Kbd>
          to deselect all
        </ZText>
      }
    />
  )
}

/**
 * This is the contextual navbar that will replace the main navbar at the top of the screen when items are being bulk selected.
 */
interface BulkSelectNavbarProps {
  isOpen?: boolean
  isEverythingSelected?: boolean
  selectedItems?: ApprovedPostal[] | MarketplaceProduct[] | SearchableProduct[]
  onSelect?: (item: any) => void
  onSelectAll?: () => void
  onDeselectAll?: () => void
}
export const BulkSelectNavbar: React.FC<PropsWithChildren<BulkSelectNavbarProps>> = ({
  isEverythingSelected,
  selectedItems,
  onSelect,
  onSelectAll,
  onDeselectAll,
  children,
}) => {
  const handleSelectAll = () => {
    if (isEverythingSelected) onDeselectAll?.()
    else onSelectAll?.()
  }

  const label = useMemo(() => (isEverythingSelected ? 'De-select All' : 'Select All'), [isEverythingSelected])

  return (
    <SecondaryNavbar
      zIndex={4}
      hideHelpCenterButton
      left={
        <Fade in>
          <ZLink
            color="white"
            _hover={{ color: '#FFFC' }}
            onClick={onDeselectAll}
          >
            Cancel (esc)
          </ZLink>
        </Fade>
      }
      right={
        <Fade in>
          <HStack spacing={5}>
            <Flex>
              {onSelectAll && (
                <ZLink
                  display="inline-flex"
                  color="white"
                  _hover={{ color: '#FFFC' }}
                  alignItems="center"
                  aria-label={label}
                  onClick={handleSelectAll}
                >
                  <MdSelectAll style={{ fontSize: '20px', marginRight: '5px' }} />
                  {label}
                </ZLink>
              )}
              <BulkSelectedItemsPopover
                selectedItems={selectedItems}
                onSelect={onSelect}
              />
            </Flex>
            {children}
          </HStack>
        </Fade>
      }
    />
  )
}

const TWO_COLUMNS_THRESHOLD = 6

/**
 * This is an element to display the items currently selected.
 */
interface BulkSelectedItemsPopoverProps {
  selectedItems?: EitherItem[] | SearchableProduct[]
  onSelect?: (item: any) => void
}
export const BulkSelectedItemsPopover: React.FC<BulkSelectedItemsPopoverProps> = ({ selectedItems, onSelect }) => {
  const disclosure = useDisclosure()

  return (
    <Popover
      {...disclosure}
      arrowSize={15}
    >
      <UiTooltip
        label="View selected Items"
        isDisabled={disclosure.isOpen}
        shouldWrapChildren
      >
        <PopoverTrigger>
          <ZLink
            color="atomicBlue.400"
            _hover={{ color: 'atomicBlue.200' }}
            ml={2}
            userSelect="none"
          >
            {`(${selectedItems?.length ?? 0} selected)`}
          </ZLink>
        </PopoverTrigger>
      </UiTooltip>
      <PopoverContent
        width={(selectedItems?.length ?? 0) > TWO_COLUMNS_THRESHOLD ? '670px' : '370px'}
        mt={2}
        borderRadius={6}
      >
        <PopoverArrow />
        <PopoverBody
          color="atomicGray.600"
          p={8}
          pr={2}
        >
          <Grid
            gap={2.5}
            pr={6}
            templateColumns={(selectedItems?.length ?? 0) > TWO_COLUMNS_THRESHOLD ? '1fr 1fr' : '1fr'}
            maxH="510px"
            overflowY="scroll"
            overflowX="hidden"
          >
            {selectedItems?.map((item) => (
              <BulkSelectedItemDisplay
                key={`${item.id}${(item as ApprovedPostal).marketplaceProductId}`}
                item={item}
                onSelect={onSelect}
              />
            ))}
          </Grid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

interface BulkSelectedItemDisplayProps {
  item: EitherItem | SearchableProduct
  onSelect?: (item: any) => void
}
const BulkSelectedItemDisplay: React.FC<BulkSelectedItemDisplayProps> = ({ item, onSelect }) => (
  <HStack
    p={2.5}
    alignItems="flex-start"
    spacing={2.5}
    borderWidth="1px"
    borderColor="atomicGray.200"
    borderRadius={4}
  >
    <BulkSelectImageDisplay
      flexShrink={0}
      w="50px"
      item={item}
    />
    <Stack
      flex={1}
      alignItems="stretch"
      sx={{ '& .deselect-button': { opacity: 0 } }}
      _hover={{ '& .deselect-button': { opacity: 1 } }}
      spacing={2}
    >
      <ZText
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        display="inline-flex"
        justifyContent="space-between"
        flexGrow={1}
      >
        <UiTruncate
          fontSize="body-sm"
          color="atomicGray.800"
          text={(item as EitherItem).name ?? (item as SearchableProduct).title}
          length={30}
          showTooltip
        />
        {onSelect && (
          <UiTooltip
            label="Remove"
            shouldWrapChildren
          >
            <Icon
              fontSize="lg"
              color="atomicGray.600"
              className="deselect-button"
              cursor="pointer"
              as={MdClose}
              onClick={() => onSelect(item)}
              _hover={{ bg: 'atomicGray.50' }}
              transition="0.2s"
            />
          </UiTooltip>
        )}
      </ZText>
      <Flex
        justifyContent="space-between"
        flexGrow={1}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        color="atomicGray.500"
      >
        <ZText
          fontSize="body-sm"
          color="atomicGray.500"
        >
          <UiTruncate
            text={(item as EitherItem).brandName ?? ''}
            length={20}
          />
        </ZText>
        <MarketplaceApprovedPostalPriceRange
          fontSize="body-sm"
          color="atomicGray.600"
          fontWeight="bold"
          item={item as ApprovedPostal}
          excludeShipping
        />
      </Flex>
    </Stack>
  </HStack>
)

interface BulkSelectImageDisplayProps extends BoxProps {
  item: EitherItem | SearchableProduct
}
const BulkSelectImageDisplay: React.FC<BulkSelectImageDisplayProps> = ({ item, ...rest }) => {
  const { assetUrlSrc } = useAssets()
  const [imageSrc] = usePostalImages(item as ApprovedPostal) ?? item.imageUrls
  const imageProps = assetUrlSrc(imageSrc?.url ?? imageSrc)

  return (
    <AspectRatio
      ratio={1}
      w="100px"
      {...rest}
    >
      <UiSlideImage
        objectFit="contain"
        decoding="async"
        border="1px solid"
        borderColor="atomicGray.200"
        borderRadius={5}
        {...imageProps}
      />
    </AspectRatio>
  )
}
