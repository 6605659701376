import { Box, Collapse, Divider, Flex, Stack } from '@chakra-ui/react'
import { ZHeading, ZMoney, ZText } from '@postal-io/postal-ui'
import type { ApprovedProductVariant } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import type { PostalSendContext } from 'components/PostalSend/usePostalSend'
import { PostalSendMethod, PostalSendType } from 'components/PostalSend/usePostalSend'
import React from 'react'
import { OrderReviewRow } from './PostalReviewCost'

export const getMaxPriceEstimate = (v: ApprovedProductVariant) =>
  v.displayPrice + (v.shippingPriceEstimateMax ?? v.shippingPriceEstimateMin ?? 0)

interface PostalSendSidebarCostBreakdownProps {
  context?: PostalSendContext
  subtotal?: number
  currency?: string | null
  hasContacts?: boolean
  variantToShowPriceOf?: ApprovedProductVariant
  numberOfRecipients?: number
}
export const PostalSendSidebarCostBreakdown: React.FC<PostalSendSidebarCostBreakdownProps> = ({
  context,
  subtotal,
  currency,
  hasContacts,
  variantToShowPriceOf,
  numberOfRecipients,
}) => {
  return (
    <>
      <Collapse
        in={context && context.method === PostalSendMethod.BulkSend}
        unmountOnExit
      >
        <BulkSendCostBreakdown
          currency={currency}
          variantToShowPriceOf={variantToShowPriceOf}
        />
      </Collapse>
      <Collapse
        in={!!context && context.method !== PostalSendMethod.BulkSend}
        unmountOnExit
      >
        <NonBulkSendCostBreakdown
          context={context}
          currency={currency}
          hasContacts={hasContacts}
          variantToShowPriceOf={variantToShowPriceOf}
        />
      </Collapse>
      <Divider
        mb={5}
        width="unset"
        border={1}
        borderColor="atomicGray.200"
        opacity={1}
      />
      {variantToShowPriceOf?.displayPrice !== undefined && (
        <TotalCostDisplay
          subtotal={subtotal}
          currency={currency}
          numberOfRecipients={numberOfRecipients}
          variantToShowPriceOf={variantToShowPriceOf}
        />
      )}
    </>
  )
}

interface BulkSendCostBreakdownProps
  extends Pick<PostalSendSidebarCostBreakdownProps, 'currency' | 'variantToShowPriceOf'> {}
const BulkSendCostBreakdown: React.FC<BulkSendCostBreakdownProps> = ({ currency, variantToShowPriceOf }) => (
  <>
    <Stack
      spacing={5}
      py={5}
    >
      <OrderReviewRow label="Item Cost">
        <ZMoney
          fontWeight="bold"
          cents={variantToShowPriceOf?.displayPrice}
          currency={currency}
          fontSize="body-md"
        />
      </OrderReviewRow>
      <OrderReviewRow
        label={
          <Flex
            alignItems="center"
            as="span"
          >
            Shipping Estimate
            <ZInfoTooltip
              ml={1}
              label="This is an approximation for shipping. We will refund the difference for shipping within 1 business day."
            />
          </Flex>
        }
      >
        <ZMoney
          fontWeight="bold"
          cents={variantToShowPriceOf?.shippingPriceEstimateMin}
          currency={currency}
          fontSize="body-md"
        />
        {(variantToShowPriceOf?.shippingPriceEstimateMax ?? 0) >
          (variantToShowPriceOf?.shippingPriceEstimateMin ?? 0) && (
          <>
            {' - '}
            <ZMoney
              fontWeight="bold"
              cents={variantToShowPriceOf?.shippingPriceEstimateMax}
              currency={currency}
            />
          </>
        )}
      </OrderReviewRow>
    </Stack>
  </>
)

interface NonBulkSendCostBreakdownProps
  extends Pick<PostalSendSidebarCostBreakdownProps, 'context' | 'hasContacts' | 'currency' | 'variantToShowPriceOf'> {}
const NonBulkSendCostBreakdown: React.FC<NonBulkSendCostBreakdownProps> = ({
  context,
  currency,
  hasContacts,
  variantToShowPriceOf,
}) => (
  <Stack
    spacing={5}
    py={5}
  >
    <OrderReviewRow label="Item Cost">
      <ZMoney
        fontWeight="bold"
        cents={variantToShowPriceOf?.displayPrice}
        currency={currency}
        fontSize="body-md"
      />
    </OrderReviewRow>

    <OrderReviewRow label="Shipping Estimate">
      <ZMoney
        fontWeight="bold"
        cents={variantToShowPriceOf?.shippingPriceEstimateMin}
        currency={currency}
        fontSize="body-md"
      />
      {(variantToShowPriceOf?.shippingPriceEstimateMax ?? 0) >
        (variantToShowPriceOf?.shippingPriceEstimateMin ?? 0) && (
        <>
          {' - '}
          <ZMoney
            fontWeight="bold"
            cents={variantToShowPriceOf?.shippingPriceEstimateMax}
            currency={currency}
          />
        </>
      )}
    </OrderReviewRow>

    {context?.type !== PostalSendType.PlaybookStep && (
      <OrderReviewRow label={hasContacts ? 'Recipients' : 'Order Limit'}>
        <ZText fontWeight="bold">
          x
          {new Intl.NumberFormat('en-US').format(
            hasContacts ? context?.contacts?.totalRecords ?? 0 : context?.maxExecutions ?? 0
          )}
        </ZText>
      </OrderReviewRow>
    )}
  </Stack>
)

interface TotalCostDisplayProps
  extends Pick<
    PostalSendSidebarCostBreakdownProps,
    'numberOfRecipients' | 'subtotal' | 'hasContacts' | 'currency' | 'variantToShowPriceOf'
  > {}
const TotalCostDisplay: React.FC<TotalCostDisplayProps> = ({
  numberOfRecipients,
  subtotal,
  currency,
  variantToShowPriceOf,
}) => (
  <Stack>
    <Flex justifyContent="space-between">
      <Box>
        <ZText
          variant="bold"
          color="atomicGray.600"
        >
          {numberOfRecipients ? 'Subtotal' : 'Price Per Item'}
        </ZText>
        <ZText
          fontSize="xs"
          color="atomicGray.500"
        >
          * Tax may vary based on recipients
        </ZText>
      </Box>
      <ZHeading
        size="h4"
        textAlign="right"
        color="atomicGray.600"
      >
        <ZMoney
          fontSize="inherit"
          cents={
            !!numberOfRecipients
              ? subtotal
              : getMaxPriceEstimate(variantToShowPriceOf ?? ({} as ApprovedProductVariant))
          }
          currency={currency}
        />
      </ZHeading>
    </Flex>
  </Stack>
)
