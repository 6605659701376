import { SimpleGrid, useDisclosure } from '@chakra-ui/react'
import type { UseGraphqlFilterResponse } from '@postal-io/postal-ui'
import { UiFormControl, ZFormLabel, ZInputDate } from '@postal-io/postal-ui'
import type { ApprovedPostal } from 'api'
import { Role } from 'api'
import { AutoCompleteSelect } from 'components/AutoComplete/AutoCompleteSelect'
import { ZDataTableFilter } from 'components/Common/ZComponents'
import { useAcl } from 'hooks'
import { reverseStatusTextMap, uniqueTextStatuses } from 'lib'
import { isEmpty } from 'lodash'
import type { ReactElement } from 'react'
import React from 'react'
import { useImmer } from 'use-immer'
import {
  AutoCompleteCampaign,
  AutoCompleteContact,
  AutoCompleteItem,
  AutoCompleteMagicLink,
  AutoCompleteTeam,
  AutoCompleteUser,
} from '../../AutoComplete'
import { DEFAULT_ROLES } from '../../Users/UsersData'

export interface OrdersFilterV2Props
  extends Pick<
    UseGraphqlFilterResponse,
    'filter' | 'setFilter' | 'resetFilter' | 'meta' | 'setMeta' | 'resetMeta' | 'filterCount'
  > {
  right?: ReactElement<any>
}

export const OrdersFilter: React.FC<OrdersFilterV2Props> = ({
  filter,
  setFilter,
  resetFilter,
  filterCount,
  meta,
  setMeta,
  resetMeta,
  right,
}) => {
  const { isOpen, onToggle } = useDisclosure()
  const { hasFeature, hasPermission, hasRole } = useAcl()

  const isAdmin = hasRole(Role.Admin)

  // used to store the form input search terms
  const [search, setSearch] = useImmer<Record<string, any>>({})

  // handle changes in regular input
  const handleCreated = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = target
    const val = isEmpty(value) ? undefined : value
    setSearch((draft: Record<string, any>) => {
      draft[name] = val
    })
    setFilter({ key: name, value: val })
  }

  // const handleStatus = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
  //   const { name, value } = target
  //   const statuses = isEmpty(value) ? undefined : reverseStatusTextMap[value]
  //   setSearch((draft: Record<string, any>) => {
  //     draft[name] = value
  //   })
  //   setFilter({ key: name, value: statuses })
  // }

  const handleStatus = (status: string) => {
    const statuses = isEmpty(status) ? undefined : reverseStatusTextMap[status]
    setSearch((draft: Record<string, any>) => {
      draft.status = status
    })
    setFilter({ key: 'status', value: statuses })
  }

  const handleReset = () => {
    resetFilter()
    resetMeta()
  }

  return (
    <ZDataTableFilter
      filterCount={filterCount}
      onReset={handleReset}
      isOpen={isOpen}
      onToggle={onToggle}
      right={right}
      pb={0}
    >
      <SimpleGrid
        minChildWidth="300px"
        spacing={4}
      >
        <UiFormControl id="status">
          <ZFormLabel>Status</ZFormLabel>
          <AutoCompleteSelect
            options={uniqueTextStatuses}
            onChange={handleStatus}
            getOptionLabel={(status) => status.value}
            value={search.status ?? null}
            placeholder="Select Status"
          />
        </UiFormControl>

        <UiFormControl id="created">
          <ZFormLabel>Created</ZFormLabel>
          <ZInputDate
            name="created"
            onChange={handleCreated}
            value={filter.created}
            isRange
          />
        </UiFormControl>

        {hasPermission('postals.read') && (
          <UiFormControl id="search-item">
            <ZFormLabel>Search Item</ZFormLabel>
            <AutoCompleteItem
              inputId="search-item"
              value={meta.approvedPostalId || ''}
              onChange={(item: ApprovedPostal | null) => {
                setFilter({ key: 'approvedPostalId', value: item?.id })
                setMeta({ key: 'approvedPostalId', value: item })
              }}
            />
          </UiFormControl>
        )}

        {hasPermission('users.query') && (
          <UiFormControl id="search-user">
            <ZFormLabel>Search Users</ZFormLabel>
            <AutoCompleteUser
              inputId="search-user"
              value={meta.userId || ''}
              onChange={(item) => {
                setFilter({ key: 'userId', value: item?.id })
                setMeta({ key: 'userId', value: item })
              }}
              roles={DEFAULT_ROLES}
            />
          </UiFormControl>
        )}

        {isAdmin && hasPermission('teams.read') && (
          <UiFormControl id="search-user">
            <ZFormLabel>Search Teams</ZFormLabel>
            <AutoCompleteTeam
              inputId="search-teams"
              value={meta.teamId || ''}
              onChange={(team) => {
                setFilter({ key: 'teamId', value: team?.teamId })
                setMeta({ key: 'teamId', value: team })
              }}
            />
          </UiFormControl>
        )}

        {hasPermission('campaigns.read') && (
          <UiFormControl id="search-campaign">
            <ZFormLabel>Campaign</ZFormLabel>
            <AutoCompleteCampaign
              inputId="search-campaign"
              value={meta.campaignId || ''}
              onChange={(item) => {
                setFilter({ key: 'campaignId', value: item?.id })
                setMeta({ key: 'campaignId', value: item })
              }}
            />
          </UiFormControl>
        )}

        {hasPermission('contacts.read') && (
          <UiFormControl id="search-contact">
            <ZFormLabel>Contact</ZFormLabel>
            <AutoCompleteContact
              inputId="search-contact"
              value={meta.contactId || ''}
              onChange={(item) => {
                setFilter({ key: 'contactId', value: item?.id })
                setMeta({ key: 'contactId', value: item })
              }}
            />
          </UiFormControl>
        )}

        {hasPermission('links.read') && hasFeature('links') && (
          <UiFormControl id="search-magiclink">
            <ZFormLabel>MagicLink</ZFormLabel>
            <AutoCompleteMagicLink
              inputId="search-magiclink"
              value={meta.magicLinkId || ''}
              onChange={(item) => {
                setFilter({ key: 'magicLinkId', value: item?.id })
                setMeta({ key: 'magicLinkId', value: item })
              }}
            />
          </UiFormControl>
        )}
      </SimpleGrid>
    </ZDataTableFilter>
  )
}
