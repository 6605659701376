import type { BoxProps } from '@chakra-ui/react'
import {
  Box,
  Flex,
  FormControl,
  IconButton,
  Image,
  InputGroup,
  List,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  LexendFontWeight,
  UiButtonScrollTop,
  useAlertError,
  useInfiniteScroll,
  ZButton,
  ZInput,
  ZInputLeftIcon,
  ZText,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, MagicLink } from 'api'
import { MagicLinkOrderByInput, SearchApprovedPostalsDocument, SearchMagicLinksDocument } from 'api'
import { AnalyticsEventV2, useAnalyticsSend, useAssets, useCopyMagicLink, useExtension } from 'hooks'
import React, { useMemo, useState } from 'react'
import { MdContentCopy, MdKeyboardArrowLeft, MdLink, MdOutlineInventory2, MdOutlineSearch } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ExtMarketplaceTextPriceRange } from '../Common/ExtMarketplaceTextPriceRange'
import { ExtHeader } from '../Main/ExtHeader'
import { ExtLinkDetails } from './ExtLinkDetails'

const LIMIT = 10

interface TypeApprovedPostalItem extends ApprovedPostal {
  previewImageSrc: string
  previewImageFallback: string | undefined
}
type TypeApprovedPostalsMap = {
  [key: string]: TypeApprovedPostalItem
}

export const ExtLinksDisplay: React.FC<BoxProps> = (props) => {
  const navigate = useNavigate()

  const [searchName, setSearchName] = useState('')
  // const { hasPermission, hasFeature } = useAcl()
  // const hasMagicLinks = hasPermission('links.read') && hasFeature('links')
  const { assetUrlSrc } = useAssets()

  const [link, setLink] = useState<MagicLink | undefined>()
  const linkDisclosure = useDisclosure()

  const searchMagicLinks = useGraphqlInfiniteQuery(SearchMagicLinksDocument, {
    filter: { name: { contains: searchName } },
    orderBy: MagicLinkOrderByInput.CreatedDesc,
    limit: LIMIT,
  })

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchMagicLinks.hasNextPage,
    loadMore: searchMagicLinks.fetchNextPage,
    loading: searchMagicLinks.isFetching,
  })

  // const linkStatus = (item: MagicLink) => getLinkStatus(item)

  const LINKS = useMemo(() => searchMagicLinks.mergedData?.searchMagicLinks || [], [searchMagicLinks.mergedData])

  const arrPostalIds = searchMagicLinks.mergedData?.searchMagicLinks?.map((link) => link.approvedPostalId) || []

  const { data } = useGraphqlQuery(SearchApprovedPostalsDocument, {
    filter: { id: { in: arrPostalIds } },
  })

  const approvedPostals: TypeApprovedPostalsMap = useMemo(
    () =>
      data?.searchApprovedPostals?.reduce((total, approvedPostal) => {
        const { src, fallbackSrc } = assetUrlSrc(approvedPostal.imageUrls?.[0]?.url, {
          width: 55,
          height: 55,
          fit: 'clip',
        })
        return {
          ...total,
          [approvedPostal.id]: {
            previewImageSrc: src,
            previewImageFallback: fallbackSrc,
            ...approvedPostal,
          },
        }
      }, {}) || {},
    [assetUrlSrc, data]
  )

  const copyLink = useCopyMagicLink()

  const sendAnalytics = useAnalyticsSend()

  const { contactId } = useExtension()

  const handleLinkSelect = (link: MagicLink) => {
    setLink(link)
    linkDisclosure.onOpen()
  }

  const handleCopyLink = (linkUrl: string) => {
    if (!linkUrl) return
    sendAnalytics({ event: AnalyticsEventV2.ExtensionLinkCopyButton })
    copyLink(linkUrl)
  }

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearchName(value)
  }

  const handleBack = () => {
    if (contactId) {
      navigate(`/extension/contacts/${contactId}`)
    } else {
      navigate(`/extension/`)
    }
  }

  useAlertError(searchMagicLinks.error)

  return (
    <>
      <Box
        p={4}
        {...props}
      >
        <ExtHeader>
          <Flex
            alignItems="center"
            columnGap="10px"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="30px"
              height="30px"
              bg="atomicGray.50"
              borderRadius="50%"
              _hover={{ bg: 'atomicGray.100', cursor: 'pointer' }}
              onClick={handleBack}
            >
              <MdKeyboardArrowLeft
                size="16px"
                color="#8492A6"
              />
            </Box>
            <ZText size="lg">MagicLinks</ZText>
          </Flex>
        </ExtHeader>

        <FormControl
          id="searchName"
          my={4}
        >
          <InputGroup>
            <ZInputLeftIcon icon={<MdOutlineSearch size="16px" />} />
            <ZInput
              placeholder="Search"
              name="searchName"
              value={searchName}
              onChange={handleSearchInput}
            />
          </InputGroup>
        </FormControl>
        <List ref={topRef}>
          {searchMagicLinks.isFetching && LINKS.length < 1 && (
            <Box
              textAlign="center"
              mt={4}
            >
              <Spinner sx={{ margin: '0 auto' }} />
            </Box>
          )}
          {!searchMagicLinks.isFetching && LINKS.length < 1 && <Text>No results</Text>}
          {LINKS.map((link) => {
            const approvedPostal =
              link.approvedPostalId in approvedPostals ? approvedPostals[link.approvedPostalId] : null
            return (
              <Flex
                key={link.id}
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex
                  alignItems="center"
                  columnGap={4}
                  mb={4}
                >
                  {approvedPostal && approvedPostal.previewImageSrc ? (
                    <Image
                      src={approvedPostal?.previewImageSrc}
                      width="55px"
                      height="55px"
                      borderWidth={1}
                      borderStyle="solid"
                      borderColor="atomicGray.100"
                      borderRadius="5px"
                      onClick={() => handleLinkSelect(link)}
                      _hover={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="55px"
                      height="55px"
                      borderWidth={1}
                      borderStyle="solid"
                      borderColor="atomicGray.100"
                      borderRadius="5px"
                      onClick={() => handleLinkSelect(link)}
                      _hover={{ cursor: 'pointer' }}
                    >
                      <MdOutlineInventory2 size="25px" />
                    </Box>
                  )}
                  <Box>
                    <ZText
                      size="md"
                      color="atomicGray.700"
                      onClick={() => handleLinkSelect(link)}
                      _hover={{ cursor: 'pointer' }}
                    >
                      {link.name}
                    </ZText>
                    <ZText
                      size="sm"
                      color="atomicGray.500"
                    >
                      {approvedPostal?.brandName}
                    </ZText>
                    <ExtMarketplaceTextPriceRange item={approvedPostal} />
                  </Box>
                </Flex>
                <Flex
                  alignItems="center"
                  columnGap={5}
                  color="atomicGray.500"
                >
                  {/* <MdLink size="16px" /> */}
                  <IconButton
                    colorScheme="atomicGray"
                    variant="ghost"
                    aria-label="Copy MagicLink"
                    icon={<MdContentCopy size="16px" />}
                    onClick={() => handleCopyLink(link.linkUrl)}
                  />
                </Flex>
              </Flex>
            )
          })}
          {LINKS.length >= LIMIT && (
            <Box
              ref={bottomRef}
              mb={8}
              position="relative"
            >
              <UiButtonScrollTop
                onClick={scrollTop}
                position="absolute"
                top="0px"
                right="0px"
                isLoading={searchMagicLinks.isFetching}
                aria-label="scroll button"
              />
            </Box>
          )}
        </List>
      </Box>
      {linkDisclosure.isOpen && (
        <ExtLinkDetails
          link={link}
          isOpen={linkDisclosure.isOpen}
          onClose={linkDisclosure.onClose}
        />
      )}
      <Box
        display="flex"
        position="fixed"
        bottom={4}
        right={4}
      >
        <ZButton
          ml={2}
          leftIcon={
            <MdLink
              fontSize="lg"
              color="white"
            />
          }
          colorScheme="atomicBlue"
          sx={{
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08)',
            borderRadius: '66px',
          }}
          onClick={() => {
            sendAnalytics({ event: AnalyticsEventV2.ExtensionLinkAddButton })
            navigate(`/extension/magiclinks/select`)
          }}
        >
          <ZText
            color="whites.400"
            size="md"
            fontWeight={LexendFontWeight.Bold}
          >
            Create a MagicLink
          </ZText>
        </ZButton>
      </Box>
    </>
  )
}
