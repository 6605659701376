import type { BoxProps } from '@chakra-ui/react'
import type { ZCardProps } from '@postal-io/postal-ui'
import { ZButton, ZCard, ZText } from '@postal-io/postal-ui'
import React from 'react'
import { MdAddCircleOutline, MdEdit } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

interface BillingAccountCardProps extends BoxProps {
  billingAccount: {
    id: string
    name: string
    type: string
  }
}

export const BillingAccountCard: React.FC<BillingAccountCardProps> = ({ billingAccount }) => {
  const navigate = useNavigate()
  const handleClick = () => navigate(`/billing/accounts/${billingAccount.id}`)

  return (
    <>
      <ZCard
        isLoading={!billingAccount}
        p={8}
        variant="form"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        h="100%"
      >
        <ZText
          textAlign="center"
          fontWeight={350}
          fontSize="md"
          color="atomicGray.700"
          p={8}
        >
          {billingAccount.name}
        </ZText>
        <ZButton
          mb={8}
          size="sm"
          p={2}
          colorScheme="atomicGray"
          color="atomicGray.400"
          borderColor="atomicGray.400"
          variant="outline"
          onClick={handleClick}
          leftIcon={<MdEdit />}
        >
          Edit Account
        </ZButton>
      </ZCard>
    </>
  )
}

export const NewBillingAccountCard: React.FC<ZCardProps> = (props) => {
  const navigate = useNavigate()
  const handleClick = () => navigate('/billing/accounts/new')

  return (
    <ZCard
      variant="form"
      onClick={handleClick}
      p={8}
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      h="100%"
      {...props}
    >
      <ZText
        p={8}
        fontWeight={350}
        fontSize="md"
        color="atomicGray.500"
      >
        New Account
      </ZText>
      <ZButton
        mb={8}
        onClick={handleClick}
        variant="outline"
        p={2}
        colorScheme="atomicBlue"
        color="atomicBlue.400"
        borderColor="atomicBlue.400"
        size="sm"
        leftIcon={<MdAddCircleOutline />}
      >
        Add a billing Account
      </ZButton>
    </ZCard>
  )
}
