import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useAcl } from './useAcl'

export const autoPrefixQuery = (query = '') => {
  if (!query) return ''
  const specialCharacters = ['+', '|', '-', '"', '*', '(', ')', '~']
  return specialCharacters.find((char) => String(query).includes(char)) ? query : `${query}*`
}

export const useQFilter = (initialValue = '', debounce = 400) => {
  // search is behind a LD flag
  const { hasFeature } = useAcl()
  const enabled = hasFeature('search')

  // handle the input string
  const [query, setQuery] = useState(initialValue)
  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => setQuery(target.value)

  // debounce input changes to create a q filter
  const [q, setQ] = useState(autoPrefixQuery(query))
  const debounceQuery = useDebouncedCallback(() => {
    setQ(autoPrefixQuery(query))
  }, debounce)

  useEffect(() => {
    debounceQuery()
  }, [query, debounceQuery])

  return { enabled, query, setQuery, onChange, q }
}
