import { Button, Icon, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react'
import type { ZCardProps } from '@postal-io/postal-ui'
import { ZCard } from '@postal-io/postal-ui'
import React from 'react'
import { MdCompareArrows, MdEdit } from 'react-icons/md'
import type { ExternalProvider } from './data'
import { PreConnectCardV2 } from './IntegrationCardV2'

interface IntegrationCardSlackProps extends ZCardProps {
  provider: ExternalProvider
  isConnected: boolean
  isLoading: boolean
  onDisconnect: () => void
}

export const IntegrationCardSlack: React.FC<IntegrationCardSlackProps> = ({
  provider,
  isConnected,
  isLoading,
  onDisconnect,
  ...rest
}) => {
  return isConnected ? (
    <ZCard
      isLoading={isLoading}
      variant="form"
      alignItems="center"
      {...rest}
    >
      <Tag
        mt="30px"
        size="md"
        bg="vendorGreen.600"
        borderRadius="full"
      >
        <TagLeftIcon
          bg="white"
          borderRadius="full"
          color="vendorGreen.600"
          as={MdCompareArrows}
        />
        <TagLabel
          fontSize="12px"
          fontWeight="bold"
          color="white"
        >
          Connected
        </TagLabel>
      </Tag>
      <Icon
        as={provider.icon}
        fontSize="125px"
        display="block"
        mx="auto"
        maxH="125px"
      />
      <Button
        m={8}
        mt={0}
        size="md"
        variant="solid"
        colorScheme="atomicBlue"
        leftIcon={<MdEdit size="16px" />}
        isLoading={isLoading}
        onClick={onDisconnect}
      >
        Disconnect
      </Button>
    </ZCard>
  ) : (
    <PreConnectCardV2
      isLoading={isLoading}
      provider={provider}
      {...rest}
    />
  )
}
