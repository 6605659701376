import { Box, Container } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiProgressBar, UiSkeleton, useAlertError, ZHeading, ZMoney, ZSelect, ZText } from '@postal-io/postal-ui'
import { PageTitle } from 'hooks'
import { DEFAULT_ACCOUNT_TEAM_ID } from 'lib'
import { orderBy } from 'lodash'
import { useMemo, useRef, useState } from 'react'
import { useScroll } from 'react-use'
import { DataObjectType, GroupBy, UserAnalyticsV2Document } from '../../../api/index'
import { useSession } from '../../../hooks/useSession'
import { NoDataOverlay } from '../NoDataOverlay'
import { MetricV2, PLACEHOLDER_USER_ANALYTICS_V2 } from '../Overview/data'
import { ReportingFilter, useReportingFilter } from '../ReportingFilter'
import { hasUserDataV2 } from '../ReportingFilter/useReportingFilter'
import { SummaryPopularSends } from './SummaryPopularSends'
import { SummaryCol, SummaryInfo, SummaryLabel, SummaryRow } from './SummaryTable'

const metricOptions = [MetricV2.OverallAccepted, MetricV2.MagicLinks, MetricV2.GiftEmails]

export const Summary = () => {
  const { session } = useSession()
  const [metricType, setMetricV2Type] = useState<MetricV2>(MetricV2.OverallAccepted)
  const scrollRef = useRef(null)
  const { x } = useScroll(scrollRef)

  const { filter, ...filterState } = useReportingFilter({})
  const { startDate, endDate, teamIds, userIds } = filter

  const { data, error, isFetching, isLoading } = useGraphqlQuery(
    UserAnalyticsV2Document,
    { config: { startDate, endDate, teamIds, userIds, type: DataObjectType.User, groupBy: filter.groupBy } },
    { keepPreviousData: true, staleTime: 0 }
  )
  const userAnalyticsArr = useMemo(() => data?.userAnalyticsV2 ?? [], [data?.userAnalyticsV2])
  useAlertError(error)

  const showPlaceholder = useMemo(() => !hasUserDataV2(userAnalyticsArr, 'queued'), [userAnalyticsArr])

  // we don't need to reduce here because we are keeping the data according to team entries
  const userAnalytics = useMemo(() => {
    return showPlaceholder
      ? PLACEHOLDER_USER_ANALYTICS_V2
      : orderBy(userAnalyticsArr || [], filter.groupBy === GroupBy.Team ? 'teamName' : ['firstName', 'lastName'])
  }, [filter.groupBy, showPlaceholder, userAnalyticsArr])

  const handleMetricV2Type = (e: React.ChangeEvent<HTMLSelectElement>) => setMetricV2Type(e.target.value as any)

  const showSkeleton = isLoading || (userAnalytics.length === 0 && isFetching)

  const getItemName = (item: any) => {
    if (filter.groupBy === GroupBy.Team) return item.teamId === null ? session.accountName : item.teamName
    return `${item.firstName} ${item.lastName}`
  }

  const stickyColumnProps: any = useMemo(
    () => ({
      position: 'sticky',
      left: 0,
      bg: 'white',
      boxShadow: x > 5 ? 'md' : 'none',
    }),
    [x]
  )

  return (
    <>
      <PageTitle
        title="Overview"
        section="Reporting"
      />
      <ReportingFilter {...filterState} />
      <Container
        maxW="1440px"
        px={0}
        pos="relative"
        overflowX="scroll"
        ref={scrollRef}
      >
        {showPlaceholder && <NoDataOverlay />}

        <UiSkeleton
          isLoaded={!showSkeleton}
          position="relative"
          minH="800px"
          w="100%"
        >
          <Box
            position="absolute"
            top={0}
            w="100%"
            height="0.375rem"
            transform="translateY(-0.375rem)"
          >
            {isFetching && !isLoading && <UiProgressBar height="100%" />}
          </Box>
          {/* HEADINGS */}
          <SummaryRow columns={userAnalytics.length}>
            <SummaryCol
              pt={6}
              pb={2}
              {...stickyColumnProps}
            />
            {userAnalytics.map((a, idx) => {
              const bg = (idx ?? 1) % 2 === 0 ? 'atomicGray.10' : 'none'
              const borderWidth = (idx ?? 1) % 2 === 0 ? 0 : '1px'
              return (
                <SummaryCol
                  key={idx}
                  p={6}
                  bg={bg}
                  borderTopRadius="10px"
                  boxSizing="border-box"
                  borderWidth={borderWidth}
                  borderColor="atomicGray.300"
                  borderBottom="none"
                >
                  <ZHeading fontSize="md">{getItemName(a)}</ZHeading>
                </SummaryCol>
              )
            })}
          </SummaryRow>

          {/* ACCEPTED */}
          <SummaryRow columns={userAnalytics.length}>
            <SummaryCol {...stickyColumnProps}>
              <SummaryLabel>Accepted</SummaryLabel>
            </SummaryCol>
            {userAnalytics.map((a, idx) => (
              <SummaryCol
                key={idx}
                idx={idx}
              >
                <ZText fontSize="lg">{a.accepted}</ZText>
              </SummaryCol>
            ))}
          </SummaryRow>

          {/* ENGAGEMENT */}
          <SummaryRow columns={userAnalytics.length}>
            <SummaryCol {...stickyColumnProps}>
              <SummaryLabel>Engagement</SummaryLabel>
            </SummaryCol>
            {userAnalytics.map((a, idx) => (
              <SummaryCol
                key={idx}
                idx={idx}
                py={6}
              >
                <SummaryInfo
                  data={{
                    'Thank yous': a.thankYou,
                    'Social share': a.socialShare,
                    'New contacts': a.newContact,
                  }}
                />
              </SummaryCol>
            ))}
          </SummaryRow>

          {/* SPEND */}
          <SummaryRow columns={userAnalytics.length}>
            <SummaryCol {...stickyColumnProps}>
              <SummaryLabel>Spend</SummaryLabel>
            </SummaryCol>
            {userAnalytics.map((a, idx) => (
              <SummaryCol
                key={idx}
                idx={idx}
              >
                <ZMoney
                  cents={a.costInCents ?? 0}
                  round
                />
              </SummaryCol>
            ))}
          </SummaryRow>

          {/* MAGICLINNK */}
          <SummaryRow columns={userAnalytics.length}>
            <SummaryCol {...stickyColumnProps}>
              <SummaryLabel>MagicLink</SummaryLabel>
            </SummaryCol>
            {userAnalytics.map((a, idx) => (
              <SummaryCol
                key={idx}
                idx={idx}
                py={6}
              >
                <SummaryInfo data={{ Viewed: a.linkUniqViews ?? 0, Accepted: a.linkExecutions ?? 0 }} />
              </SummaryCol>
            ))}
          </SummaryRow>

          {/* AVG ITEM COST */}
          <SummaryRow columns={userAnalytics.length}>
            <SummaryCol {...stickyColumnProps}>
              <SummaryLabel>Avg Item Cost</SummaryLabel>
            </SummaryCol>
            {userAnalytics.map((a, idx) => (
              <SummaryCol
                key={idx}
                idx={idx}
              >
                <ZMoney
                  cents={(a?.costInCents ?? 0) / (a.accepted || 1)}
                  round
                />
              </SummaryCol>
            ))}
          </SummaryRow>

          {/* MEETINGS BOOKED */}
          <SummaryRow columns={userAnalytics.length}>
            <SummaryCol {...stickyColumnProps}>
              <SummaryLabel>Meetings Booked</SummaryLabel>
            </SummaryCol>
            {userAnalytics.map((a, idx) => (
              <SummaryCol
                key={idx}
                idx={idx}
              >
                <ZText>{a.meetingsBooked}</ZText>
              </SummaryCol>
            ))}
          </SummaryRow>

          {/* POPULAR ITEMS */}
          <SummaryRow columns={userAnalytics.length}>
            <SummaryCol {...stickyColumnProps}>
              <SummaryLabel>Popular Items</SummaryLabel>
              <ZSelect
                size="xs"
                w="full"
                value={metricType}
                onChange={handleMetricV2Type}
                alignSelf="flex-start"
                isDisabled={showPlaceholder}
                mt={4}
              >
                {metricOptions.map((option) => (
                  <option
                    key={option}
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </ZSelect>
            </SummaryCol>
            {userAnalytics.map((a, idx) => {
              const borderWidth = (idx ?? 1) % 2 === 0 ? 0 : '1px'
              return (
                <SummaryCol
                  key={`${a.userId}-${a.teamId}`}
                  idx={idx}
                  borderBottomRadius="10px"
                  borderBottomWidth={borderWidth}
                >
                  <SummaryPopularSends
                    metricType={metricType}
                    showPlaceholder={showPlaceholder}
                    userId={filter.groupBy === GroupBy.User ? (a.userId as string) : undefined}
                    teamId={filter.groupBy === GroupBy.Team ? a.teamId || DEFAULT_ACCOUNT_TEAM_ID : undefined}
                    startDate={filter.startDate}
                    endDate={filter.endDate}
                  />
                </SummaryCol>
              )
            })}
          </SummaryRow>
        </UiSkeleton>
      </Container>
    </>
  )
}
