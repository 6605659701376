import {
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
} from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiButton } from '@postal-io/postal-ui'
import { BackgroundTaskStatus, GetBackgroundTaskByIdDocument } from 'api'
import type { QueuedBackgroundTask } from 'hooks'
import { useBackgroundQueue, useBackgroundTaskInvalidation } from 'hooks'
import React, { useEffect } from 'react'

const DEFAULT_TRIGGERS = [BackgroundTaskStatus.Completed, BackgroundTaskStatus.Cancelled, BackgroundTaskStatus.Error]

// We don't actually display anything with this element, just using for state mgmt
const BackgroundTask: React.FC<QueuedBackgroundTask> = ({ task, callback, triggers = DEFAULT_TRIGGERS }) => {
  const invalidateCache = useBackgroundTaskInvalidation()
  const { remove } = useBackgroundQueue()
  const { data } = useGraphqlQuery(GetBackgroundTaskByIdDocument, { id: task.id }, { refetchInterval: 1000 })

  useEffect(() => {
    if (!data) return
    const updated = data.getBackgroundTaskById

    // triggers determine which statuses invoke the callback
    if (triggers?.includes(updated?.status as BackgroundTaskStatus) && updated && callback) {
      callback(updated)
    }

    switch (updated?.status) {
      case BackgroundTaskStatus.Completed:
        invalidateCache(updated.taskAction)
        remove(updated)
        break
      case BackgroundTaskStatus.Cancelled:
        remove(task)
        break
      case BackgroundTaskStatus.Error:
        console.error(`Background Task Error: ${JSON.stringify(updated.errors)}`)
        remove(updated)
        break
      case BackgroundTaskStatus.InProgress:
      case BackgroundTaskStatus.Pending:
      case BackgroundTaskStatus.Queued:
        // do nothing
        break
      default:
        remove(task)
    }
  }, [callback, data, invalidateCache, remove, task, triggers])
  return null
}

export const ExtBackgroundTasks = () => {
  const { tasks }: { tasks: QueuedBackgroundTask[] } = useBackgroundQueue()
  if (tasks.length === 0) return null

  return (
    <>
      <Popover
        placement="top"
        returnFocusOnClose={false}
      >
        <PopoverTrigger>
          <UiButton
            zIndex={9999}
            w="40px"
            h="40px"
            p={1}
            m={0}
            boxShadow="postalShadow"
            rounded="full"
            aria-label="thing"
            colorScheme="primary"
            position="fixed"
            bottom="20px"
            right="20px"
          >
            <Spinner
              size="md"
              speed=".75s"
            />
          </UiButton>
        </PopoverTrigger>
        <PopoverContent
          zIndex={4}
          color="gray.800"
        >
          <PopoverCloseButton />
          <PopoverArrow />
          <PopoverHeader
            fontSize="sm"
            fontWeight="normal"
          >
            Background Tasks
          </PopoverHeader>
          <PopoverBody
            fontSize="sm"
            fontWeight="normal"
          >
            <List
              styleType="disc"
              listStylePosition="outside"
              paddingLeft="15px"
            >
              {tasks.map(({ task }, idx) => (
                <ListItem key={idx}>{task.name}</ListItem>
              ))}
            </List>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      {tasks.map(({ task, callback, triggers }, idx) => (
        <BackgroundTask
          key={idx}
          task={task}
          callback={callback}
          triggers={triggers}
        />
      ))}
    </>
  )
}
