import { useCallback } from 'react'
import { assetSrc as assetIdToSrc, assetUrlToSrc } from '../api/rest'
import { useSession } from './useSession'

/*
  resize the image so its not too large and slow
  see more: https://docs.imgix.com/apis/rendering/size/fit#max
*/
const SRC_DEFAULTS = { w: 2000, fit: 'max', auto: 'compress' }
const FALLBACK_DEFAULTS = { blur: 100, px: 8, auto: 'compress' }

const hasFallback = !!process.env.REACT_APP_IMAGE_URL

export const useAssets = () => {
  const { session } = useSession()

  const assetUrlSrc = useCallback(
    (url?: string | null, options?: Record<string, string | number>) => {
      const baseParams = { accountId: session.accountId, assetUrl: url }
      const srcParams = { ...baseParams, ...SRC_DEFAULTS, ...options }
      const fallbackParams = { ...baseParams, ...FALLBACK_DEFAULTS, ...options }
      return {
        src: assetUrlToSrc(srcParams) || '',
        fallbackSrc: hasFallback ? assetUrlToSrc(fallbackParams) : undefined,
      }
    },
    [session.accountId]
  )

  const assetSrc = useCallback(
    (id?: string | null, options?: Record<string, string | number>) => {
      const baseParams = { accountId: session.accountId, assetId: id }
      const srcParams = { ...baseParams, ...SRC_DEFAULTS, ...options }
      const fallbackParams = { ...baseParams, ...FALLBACK_DEFAULTS, ...options }
      return {
        src: assetIdToSrc(srcParams) || '',
        fallbackSrc: hasFallback ? assetIdToSrc(fallbackParams) : undefined,
      }
    },
    [session.accountId]
  )

  return { assetUrlSrc, assetSrc }
}
