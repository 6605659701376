import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  useDisclosure,
  useEditableControls,
  VStack,
} from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  useAlertError,
  useAlerts,
  ZButton,
  ZHeading,
  ZInput,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZSidebar,
  ZSidebarBanner,
  ZText,
} from '@postal-io/postal-ui'
import {
  BillingAccountType,
  DeleteBillingAccountDocument,
  GetBillingAccountDocument,
  UpdateBillingAccountDocument,
} from 'api'
import { MIN_AMOUNT } from 'components/Billing/'
import { BillingAccountOverview } from 'components/Billing/BillingAccountOverview'
import { CenteredBox } from 'components/Common'
import { SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { AnalyticsEvent, PageTitle, useAcl, useAnalyticsEvent } from 'hooks'
import { Permission } from 'lib/permissions'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MdCancel, MdCheck, MdOutlineChevronLeft, MdOutlineDelete, MdOutlineEdit } from 'react-icons/md'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

type FormState = {
  autoReload?: boolean | null
  autoReloadAmount?: number | null
  autoReloadLowLimit?: number | null
  name?: string
}

export interface BillingEditFormProps {
  current: FormState
  original: FormState
}

export const DEFAULT_VALUES = {
  autoReload: false,
  autoReloadAmount: MIN_AMOUNT,
  autoReloadLowLimit: MIN_AMOUNT,
  name: '',
}

interface EditableControlsProps {
  handleSubmit: () => void
  isDisabled: boolean
  isLoading: boolean
}

const EditableControls: React.FC<EditableControlsProps> = ({ handleSubmit, isDisabled, isLoading }) => {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls()

  const onClickSave = useCallback(
    (e: React.MouseEvent) => {
      const { onClick } = getSubmitButtonProps()
      onClick && onClick(e as any)
      handleSubmit && handleSubmit()
    },
    [getSubmitButtonProps, handleSubmit]
  )

  return (
    <ButtonGroup
      spacing={2}
      ml={2}
    >
      {isEditing ? (
        <>
          <IconButton
            aria-label="save text"
            variant="link"
            color="atomicBlue.400"
            minW="0px"
            {...getSubmitButtonProps()}
            onClick={onClickSave}
            disabled={isDisabled}
            isLoading={isLoading}
          >
            <MdCheck size="22px" />
          </IconButton>
          <IconButton
            aria-label="cancel editing text"
            variant="link"
            color="atomicGray.400"
            minW="0px"
            {...getCancelButtonProps()}
            disabled={isLoading}
            isLoading={isLoading}
          >
            <MdCancel size="22px" />
          </IconButton>
        </>
      ) : (
        <IconButton
          aria-label="edit text"
          variant="link"
          color="atomicBlue.400"
          _hover={{ color: 'atomicBlue.600' }}
          minW="0px"
          isLoading={isLoading}
          {...getEditButtonProps()}
        >
          <MdOutlineEdit size="22px" />
        </IconButton>
      )}
    </ButtonGroup>
  )
}

interface EditableTitleProps {
  title: string
  handleSubmit: (name: string) => void
  isLoading: boolean
}

const EditableTitle: React.FC<EditableTitleProps> = ({ title, handleSubmit, isLoading }) => {
  const [titleValue, setTitleValue] = useState<string>(title)

  const handleOnSubmit = useCallback(() => {
    if (!titleValue.length) {
      setTitleValue(title)
      return
    }

    handleSubmit && handleSubmit(titleValue)
  }, [handleSubmit, title, titleValue])

  return (
    <Editable
      value={titleValue}
      onChange={(v: string) => setTitleValue(v)}
      onSubmit={handleOnSubmit}
      display="flex"
      alignItems="center"
    >
      <ZHeading
        as={EditablePreview}
        size="h5"
        color="atomicGray.700"
        m={0}
        p={0}
      />
      <ZInput
        as={EditableInput}
        fontSize="xl"
        p={0}
        fontFamily="Lexend"
        variant="unstyled"
        h="26px"
        minW="300px"
        textAlign="left"
      />
      <EditableControls
        isLoading={isLoading}
        isDisabled={!titleValue.length}
        handleSubmit={() => handleSubmit(titleValue)}
      />
    </Editable>
  )
}

export const BillingAccount = () => {
  const Alert = useAlerts()
  const navigate = useNavigate()
  const { hasPermission } = useAcl()
  const hasNetsuite = hasPermission(Permission.NetsuiteEnabled)

  const canDelete = hasPermission('billing.delete')
  const confirmDelete = useDisclosure()
  const { billingAccountId } = useParams()

  useAnalyticsEvent({ event: AnalyticsEvent.BillingAccountViewed })

  const [, setForm] = useImmer<BillingEditFormProps>({
    original: { ...DEFAULT_VALUES },
    current: { ...DEFAULT_VALUES },
  })

  const getBillingAccount = useGraphqlQuery(
    GetBillingAccountDocument,
    { id: billingAccountId as string },
    { enabled: !!billingAccountId }
  )
  const billingAccount = useMemo(
    () => getBillingAccount.data?.getBillingAccount,
    [getBillingAccount.data?.getBillingAccount]
  )
  useAlertError(getBillingAccount.error)

  const updateBillingAccount = useGraphqlMutation(UpdateBillingAccountDocument)

  const handleEditBillingAccountName = useCallback(
    async (name: string) => {
      try {
        await updateBillingAccount.mutateAsync({ id: billingAccountId as string, data: { name } })
        Alert.success('Account name successfully updated.')
      } catch (e) {
        Alert.error(e)
      }
    },
    [updateBillingAccount, billingAccountId, Alert]
  )

  useEffect(() => {
    setForm((draft) => {
      // eslint-disable-next-line prefer-const
      let { autoReload, autoReloadAmount, autoReloadLowLimit, name } = billingAccount || DEFAULT_VALUES
      autoReloadAmount = (autoReloadAmount ?? 0) / 100
      autoReloadLowLimit = (autoReloadLowLimit ?? 0) / 100
      draft.original = { autoReload, autoReloadAmount, autoReloadLowLimit, name }
      draft.current = { autoReload, autoReloadAmount, autoReloadLowLimit, name }
    })
  }, [billingAccount, setForm])

  const isFundsAccount = billingAccount?.type === BillingAccountType.Funds
  const deleteBillingAccount = useGraphqlMutation(DeleteBillingAccountDocument)

  const handleDelete = async () => {
    try {
      confirmDelete.onClose()
      await deleteBillingAccount.mutateAsync({ id: billingAccount?.id as string })
      navigate('/billing/accounts')
      Alert.warning('Billing Account Deleted')
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleBack = () => navigate('/billing/accounts')

  // netsuite doesn't do subscriptions
  if (hasNetsuite && billingAccount?.type === BillingAccountType.Subscription)
    return <Navigate to={'/billing/accounts'} />

  return (
    <>
      <SecondaryNavbar
        left={
          <Flex
            alignItems="center"
            h="52px"
            color="white"
          >
            <ZLink
              color="white"
              onClick={handleBack}
              fontSize="14px"
              fontWeight="bold"
              display="flex"
              alignItems="center"
            >
              <MdOutlineChevronLeft size="28px" />
              Back to Accounts
            </ZLink>
          </Flex>
        }
      />
      <CenteredBox
        py={0}
        isLoaded
      >
        <ZSidebar
          sidebarBlocks={<ProfileSidebarBlocks />}
          m={0}
          p={0}
        >
          <PageTitle
            title={billingAccount?.name}
            section="Billing Accounts"
          />
          <ZSidebarBanner
            title={
              billingAccount?.name ? (
                <EditableTitle
                  title={billingAccount?.name}
                  handleSubmit={handleEditBillingAccountName}
                  isLoading={updateBillingAccount.isLoading}
                />
              ) : (
                'Account'
              )
            }
            actions={
              (canDelete || isFundsAccount) && (
                <ZButton
                  variant="link"
                  color="atomicBlue.400"
                  leftIcon={<MdOutlineDelete size="24px" />}
                  size="sm"
                  height="21px"
                  onClick={confirmDelete.onOpen}
                >
                  Delete Account
                </ZButton>
              )
            }
          />

          {billingAccount && (
            <BillingAccountOverview
              billingAccount={billingAccount}
              isLoading={getBillingAccount.isLoading}
            />
          )}
          <ZModal
            size="2xl"
            isOpen={confirmDelete.isOpen}
            onClose={confirmDelete.onClose}
          >
            <ZModalOverlay />
            <ZModalContent>
              <ZModalHeader>Confirm Delete Billing Account</ZModalHeader>
              <ZModalCloseButton />
              <ZModalBody>
                <VStack
                  spacing={4}
                  alignItems="flex-start"
                >
                  <ZText fontSize="md">Deleting a billing account will hide it and make it unusable by teams. </ZText>
                  <ZText fontSize="md">
                    Most of the time, you should only delete a billing account if it was created by mistake. Recovering
                    a deleted billing account will require a support ticket.{' '}
                  </ZText>
                  <ZText fontSize="md">
                    Are you <em>absolutely</em> sure you want to delete <strong>{billingAccount?.name}</strong>?
                  </ZText>
                </VStack>
              </ZModalBody>
              <ZModalButtons
                confirmText="Delete"
                confirmProps={{
                  colorScheme: 'atomicRed',
                }}
                onConfirm={handleDelete}
                onClose={confirmDelete.onClose}
              />
            </ZModalContent>
          </ZModal>
        </ZSidebar>
      </CenteredBox>
    </>
  )
}
