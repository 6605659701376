import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiSelectTypeaheadProps } from '@postal-io/postal-ui'
import { useAlertError, ZSelectTypeahead } from '@postal-io/postal-ui'
import { useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import type { Campaign } from '../../api'
import { CampaignType, SearchCampaignsDocument } from '../../api'

export function AutoCompleteCampaign(props: UiSelectTypeaheadProps<Campaign>) {
  const [search, setSearch] = useState(props.inputValue || props.defaultInputValue || '')
  const [debounced] = useDebounce(search, 400)

  const variables = useMemo(() => {
    return {
      filter: {
        name: debounced ? { contains: debounced } : undefined,
        campaignType: { in: [CampaignType.Engage, null] },
      },
      limit: 20,
    }
  }, [debounced])

  const query = useGraphqlQuery(SearchCampaignsDocument, variables)
  useAlertError(query.error)

  const items = useMemo(() => query.data?.searchCampaigns ?? [], [query.data?.searchCampaigns])

  const handleInput = useCallback(
    (val: any, meta: any) => {
      setSearch(val)
      props.onInputChange && props.onInputChange(val, meta)
    },
    [props]
  )

  const { onInputChange, ...rest } = props

  return (
    <ZSelectTypeahead
      data-testid="AutoCompleteCampaign"
      options={items}
      getOptionLabel={(t) => t.name}
      getOptionValue={(t) => t.id}
      onInputChange={handleInput}
      isLoading={query.isLoading}
      placeholder="Search Campaigns"
      noOptionsMessage={() => 'No Campaigns Found'}
      {...rest}
    />
  )
}
