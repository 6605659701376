import { Box, Fade, Flex, Grid, Heading } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiChangeEvent, UiGenericChangeEvent } from '@postal-io/postal-ui'
import {
  FontWeight,
  UiDateTime,
  UiFormControl,
  UiSkeleton,
  useAlertError,
  useAlerts,
  ZButton,
  ZCard,
  ZFormLabel,
  ZInputMoney,
  ZModal,
  ZModalBackButton,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZMoney,
  ZSelect,
  ZText,
  ZTextarea,
} from '@postal-io/postal-ui'
import type { TransferIntent } from 'api'
import { BillingAccountsDocument, CreateTransferIntentDocument, Status } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { isNumber } from 'lodash'
import type { ChangeEvent, FormEvent } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import type { Updater } from 'use-immer'
import { useImmer } from 'use-immer'
import type { BillingAccount } from '../../api/index'
import { ConfirmTransferIntentDocument } from '../../api/index'

enum View {
  Form = 'FORM',
  Confirm = 'CONFIRM',
}

interface FormProps {
  fromBillingAccountId: string
  toBillingAccountId: string
  amount: number
  comment: string
}

interface BalanceTransferCreateModalProps {
  fromBillingAccountId?: string
  isOpen: boolean
  onClose: () => void
}

export const BalanceTransferCreateModal: React.FC<BalanceTransferCreateModalProps> = ({
  fromBillingAccountId,
  isOpen,
  onClose,
}) => {
  const Alert = useAlerts()
  const navigate = useNavigate()
  const [view, setView] = useState<View>(View.Form)
  const [form, setForm] = useImmer<Record<string, any>>({ fromBillingAccountId })

  // Only show active FUNDS billing accounts.
  // Treat 'null' as active since 'status' field was added later so not all billing accounts will have it set.
  const billingAccountsQuery = useGraphqlQuery(BillingAccountsDocument, {
    filter: { status: { in: [Status.Active, null] }, type: { eq: 'FUNDS' } },
  })
  const billingAccounts = billingAccountsQuery.data?.billingAccounts ?? []
  useAlertError(billingAccountsQuery.error)

  const createTransferIntentMutation = useGraphqlMutation(CreateTransferIntentDocument)
  const confirmTransferIntentMutation = useGraphqlMutation(ConfirmTransferIntentDocument)

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (view === View.Form) {
      setView(View.Confirm)
      return
    }

    const fromBillingAccount = billingAccounts.find((account) => account.id === fromBillingAccountId)

    // ensure have enough money
    if ((fromBillingAccount?.balance ?? 0) < form.amount) {
      return Alert.warning('Amount exceeds current balance. Please adjust and try again.')
    }

    try {
      const res = await createTransferIntentMutation.mutateAsync({ input: form as FormProps })
      await confirmTransferIntentMutation.mutateAsync({ id: res.createTransferIntent?.id })
      Alert.success('Transfer confirmed')
      setForm({})
      onClose()
      navigate('/billing/transfers')
    } catch (err) {
      Alert.error(err)
    }
  }

  const isLoading = billingAccountsQuery.isLoading

  const handleBack = () => setView?.(View.Form)

  return (
    <ZModal
      size="6xl"
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
    >
      <ZModalOverlay />

      <ZModalContent>
        <form onSubmit={handleSubmit}>
          <Fade
            in={view === View.Confirm}
            unmountOnExit
          >
            <ZModalBackButton
              onClick={handleBack}
              label="Back"
            />
          </Fade>
          <ZModalHeader>{view === View.Form ? 'Set up a transfer' : 'Review and Confirm'}</ZModalHeader>
          <ZModalCloseButton />
          <ZModalBody>
            <UiSkeleton isLoaded={!isLoading}>
              {view === View.Form ? (
                <FormView
                  form={form}
                  setForm={setForm}
                  billingAccounts={billingAccounts}
                />
              ) : (
                <ConfirmViewV2
                  form={form}
                  billingAccounts={billingAccounts}
                />
              )}
            </UiSkeleton>
          </ZModalBody>
          <ZModalButtons>
            {view === View.Form ? (
              <ZButton
                size="md"
                type="submit"
                colorScheme="atomicBlue"
                minW="125px"
              >
                Set up transfer
              </ZButton>
            ) : (
              <ZButton
                type="submit"
                colorScheme="atomicBlue"
                size="md"
                minW="125px"
                isLoading={createTransferIntentMutation.isLoading}
              >
                Confirm
              </ZButton>
            )}
          </ZModalButtons>
        </form>
      </ZModalContent>
    </ZModal>
  )
}

interface FormViewProps {
  form: Record<string, any>
  setForm: Updater<Record<string, any>>
  billingAccounts?: BillingAccount[]
}

const FormView: React.FC<FormViewProps> = ({ form, setForm, billingAccounts: fromBillingAccounts }) => {
  const fromBillingAccount = useMemo(
    () => fromBillingAccounts?.find((a) => a.id === form.fromBillingAccountId),
    [form.fromBillingAccountId, fromBillingAccounts]
  )

  // until we support transfer between different currencies
  const toBillingAccounts = useMemo(() => {
    return fromBillingAccounts?.filter(
      (account) => account.currency === fromBillingAccount?.currency && account.id !== form.fromBillingAccountId
    )
  }, [form.fromBillingAccountId, fromBillingAccount?.currency, fromBillingAccounts])

  // if only one entry for "Transfer To", go ahead and select it
  useEffect(() => {
    if (toBillingAccounts?.length === 1) {
      setForm((draft: any) => void (draft.toBillingAccountId = toBillingAccounts[0].id))
    }
  }, [setForm, toBillingAccounts])

  const handleInput = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | UiGenericChangeEvent
  ) => {
    setForm((draft: any) => void (draft[e.target.name] = e.target.value))
  }

  const handleInputMoney = ({ key, value }: UiChangeEvent<number>) => {
    setForm((draft: any) => void (draft[key] = value))
  }

  return (
    <>
      <Grid
        templateColumns="repeat(3, 1fr)"
        columnGap={4}
        rowGap={6}
      >
        <UiFormControl
          id="fromBillingAccountId"
          isRequired
        >
          <Flex justifyContent="space-between">
            <ZFormLabel>Transfer From</ZFormLabel>
            {isNumber(fromBillingAccount?.balance) && (
              <ZMoney
                cents={fromBillingAccount?.balance}
                fontWeight={FontWeight.SemiBold}
                fontSize="sm"
              />
            )}
          </Flex>
          <ZSelect
            name="fromBillingAccountId"
            value={form.fromBillingAccountId ?? ''}
            onChange={handleInput}
            placeholder="Select an account"
          >
            {fromBillingAccounts?.map((account) => (
              <option
                key={account.id}
                value={account.id}
              >
                {account.name}
              </option>
            ))}
          </ZSelect>
        </UiFormControl>
        <UiFormControl
          id="toBillingAccountId"
          isRequired
        >
          <ZFormLabel>Transfer To</ZFormLabel>
          <ZSelect
            name="toBillingAccountId"
            value={form.toBillingAccountId ?? ''}
            onChange={handleInput}
            placeholder="Select an account"
          >
            {toBillingAccounts?.map((account) => (
              <option
                key={account.id}
                value={account.id}
              >
                {account.name}
              </option>
            ))}
          </ZSelect>
        </UiFormControl>
        <UiFormControl
          id="amount"
          isRequired
        >
          <ZFormLabel>Amount</ZFormLabel>
          <ZInputMoney
            name="amount"
            value={form.amount ?? ''}
            onChange={handleInputMoney}
            max={fromBillingAccount?.balance as number}
          />
        </UiFormControl>
        <UiFormControl
          id="comment"
          gridColumn="1 / 4"
          isRequired
        >
          <ZFormLabel>Notes</ZFormLabel>
          <ZTextarea
            name="comment"
            value={form.comment ?? ''}
            onChange={handleInput}
          />
        </UiFormControl>
      </Grid>
    </>
  )
}

interface ConfirmViewProps {
  form: Record<string, any>
  billingAccounts?: BillingAccount[]
}

export const ConfirmViewV2: React.FC<ConfirmViewProps> = ({ form, billingAccounts }) => {
  const fromBillingAccount = useMemo(
    () => billingAccounts?.find((account) => account.id === form.fromBillingAccountId),
    [billingAccounts, form.fromBillingAccountId]
  )
  const toBillingAccount = useMemo(
    () => billingAccounts?.find((account) => account.id === form.toBillingAccountId),
    [billingAccounts, form.toBillingAccountId]
  )

  return (
    <>
      <Grid
        templateColumns="repeat(4, 1fr)"
        columnGap={4}
        rowGap={12}
        mx={16}
        mt={8}
      >
        <Flex
          flexDir="column"
          alignItems="center"
        >
          <ZFormLabel
            fontSize="md"
            fontWeight={400}
          >
            Transfer From
          </ZFormLabel>
          <Heading
            size="sm"
            textAlign="center"
            color="atomicGray.600"
          >
            {fromBillingAccount?.name}
          </Heading>
        </Flex>
        <Flex
          flexDir="column"
          alignItems="center"
        >
          <ZFormLabel
            fontSize="md"
            fontWeight={400}
          >
            Transfer To
          </ZFormLabel>
          <Heading
            size="sm"
            textAlign="center"
            color="atomicGray.600"
          >
            {toBillingAccount?.name}
          </Heading>
        </Flex>
        <Flex
          flexDir="column"
          alignItems="center"
        >
          <ZFormLabel
            fontSize="md"
            fontWeight={400}
          >
            Amount
          </ZFormLabel>
          <Heading
            size="sm"
            textAlign="center"
            color="atomicGray.600"
          >
            <ZMoney cents={form.amount} />
          </Heading>
        </Flex>
        <Flex
          flexDir="column"
          alignItems="center"
        >
          <ZFormLabel
            fontSize="md"
            fontWeight={400}
          >
            Date
          </ZFormLabel>
          <Heading
            size="sm"
            textAlign="center"
            color="atomicGray.600"
          >
            <UiDateTime
              date={new Date()}
              format={{ dateStyle: 'short' }}
            />
          </Heading>
        </Flex>

        <ZCard
          gridColumn="1 / 5"
          border="none"
          bg="atomicGray.50"
          boxShadow="none"
        >
          <ZText
            textAlign="justify"
            fontSize="md"
            color="atomicGray.600"
          >
            By clicking confirm you acknowledge the transfer of funds in the amount of <ZMoney cents={form.amount} />{' '}
            from <strong>{fromBillingAccount?.name}</strong> to <strong>{toBillingAccount?.name}</strong> effective{' '}
            <UiDateTime
              date={new Date()}
              format={{ dateStyle: 'short' }}
            />
            .
          </ZText>
        </ZCard>
      </Grid>
    </>
  )
}

interface FxRateInfoTooltipProps {
  transferIntent?: TransferIntent
}
const FxRateInfoTooltip: React.FC<FxRateInfoTooltipProps> = ({ transferIntent }) => (
  <ZInfoTooltip
    placement="top"
    hasArrow
    label={
      <>
        Foreign Exchange Rate:{` `}
        <ZText
          as="span"
          fontWeight={FontWeight.Bold}
          color="atomicGray.400"
        >
          <ZMoney
            cents={100}
            currency={transferIntent?.fromCurrency}
            color="inherit"
          />{' '}
          ={' '}
          <ZMoney
            cents={Math.floor(100 * Number(transferIntent?.fxInfo?.rate))}
            currency={transferIntent?.toCurrency}
            color="inherit"
          />
          {/* Provide extra precision over the 2 decimal places that ZMoney provides to better indicate the exchange rate */}
          <ZText
            color="inherit"
            fontWeight="normal"
            as="span"
          >
            {transferIntent?.fxInfo?.rate.replace(/\d*\.\d{2}/, '')}
          </ZText>
        </ZText>
      </>
    }
  />
)

interface ForeignConfirmViewProps {
  transferIntent?: TransferIntent
  billingAccounts?: BillingAccount[]
}

export const ForeignConfirmViewV2: React.FC<ForeignConfirmViewProps> = ({ transferIntent, billingAccounts }) => {
  const fromBillingAccount = useMemo(
    () => billingAccounts?.find((account) => account.id === transferIntent?.fromBillingAccountId),
    [billingAccounts, transferIntent?.fromBillingAccountId]
  )
  const toBillingAccount = useMemo(
    () => billingAccounts?.find((account) => account.id === transferIntent?.toBillingAccountId),
    [billingAccounts, transferIntent?.toBillingAccountId]
  )

  return (
    <>
      <Grid
        templateColumns="repeat(2, 1fr)"
        columnGap={4}
        rowGap={12}
        mx={16}
        mt={8}
      >
        <Flex
          flexDir="column"
          alignItems="left"
          bg="atomicGray.10"
          borderRadius="3px"
          p={8}
        >
          <ZFormLabel
            fontSize="md"
            fontWeight={400}
          >
            From
          </ZFormLabel>
          <Heading
            size="sm"
            mb={1}
          >
            {fromBillingAccount?.name}
          </Heading>
          <ZText
            color="atomicGray.600"
            mb={5}
          >
            BALANCE:{' '}
            <ZMoney
              color="atomicGray.600"
              fontWeight={FontWeight.SemiBold}
              cents={fromBillingAccount?.balance}
              currency={fromBillingAccount?.currency}
            />
          </ZText>
          <ZText
            color="atomicGray.600"
            display="inline-flex"
            alignItems="center"
            gap={2}
          >
            FX rate <FxRateInfoTooltip transferIntent={transferIntent} />
          </ZText>
          <ZText color="atomicGray.800">
            {transferIntent?.fxInfo?.rate}{' '}
            <ZText
              ml={1}
              as="span"
              fontWeight={FontWeight.Bold}
              color="atomicGray.400"
            >
              (
              <ZMoney
                cents={transferIntent?.fromAmount}
                currency={transferIntent?.fromCurrency}
              />
              {` → `}
              <ZMoney
                cents={transferIntent?.toAmount}
                currency={transferIntent?.toCurrency}
              />
              )
            </ZText>
          </ZText>
          <Box
            px={4}
            py={2}
            mt={8}
            bg="white"
            borderRadius={6}
          >
            <ZText
              color="atomicGray.500"
              fontSize="xs"
            >
              Rate provided on <UiDateTime date={transferIntent?.fxInfo?.timestamp} /> <br />
              by {transferIntent?.fxInfo?.apiProvider}
            </ZText>
          </Box>
        </Flex>
        <Flex
          flexDir="column"
          alignItems="left"
          bg="white"
          p={8}
        >
          <ZFormLabel
            fontSize="md"
            fontWeight={400}
          >
            To
          </ZFormLabel>
          <Heading
            size="sm"
            mb={1}
          >
            {toBillingAccount?.name}
          </Heading>
          <ZText
            color="atomicGray.600"
            mb={5}
          >
            BALANCE:{' '}
            <ZMoney
              color="atomicBlue.600"
              fontWeight={FontWeight.SemiBold}
              cents={toBillingAccount?.balance}
              currency={toBillingAccount?.currency}
            />
          </ZText>
          <ZText color="atomicGray.500">Transfer/convenience Fees</ZText>
          <ZMoney
            color="atomicGray.800"
            cents={0}
            currency={toBillingAccount?.currency}
          />
        </Flex>
      </Grid>

      <ZCard
        gridColumn="1 / 5"
        boxShadow="none"
        px={16}
      >
        <ZText
          textAlign="justify"
          fontSize="md"
          lineHeight={2}
        >
          By clicking confirm you acknowledge the transfer of funds in the amount of{' '}
          <strong>
            <ZMoney
              cents={transferIntent?.fromAmount}
              currency={transferIntent?.fromCurrency}
            />{' '}
            <ZText
              color="atomicBlue.600"
              as="span"
            >
              (
              <ZMoney
                cents={transferIntent?.toAmount}
                currency={transferIntent?.toCurrency}
              />
              )
            </ZText>
          </strong>{' '}
          from <strong>{fromBillingAccount?.name}</strong> to <strong>{toBillingAccount?.name}</strong> effective{' '}
          <UiDateTime
            fontWeight={FontWeight.Bold}
            date={new Date()}
            format={{ dateStyle: 'short' }}
          />
          .
        </ZText>
      </ZCard>
    </>
  )
}
