import type { FlexProps } from '@chakra-ui/react'
import { Box, Flex, Grid, useDisclosure, VStack } from '@chakra-ui/react'
import {
  UiButton,
  UiDangerous,
  UiFormControl,
  UiFormLabel,
  UiInput,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalFooter,
  UiModalFooterButtons,
  UiModalHeader,
  UiModalOverlay,
  UiTextarea,
  useImmerWithMemory,
} from '@postal-io/postal-ui'
import { ConfirmClose } from 'components/Common/ConfirmClose'
import React, { useMemo, useState } from 'react'
import { CustomEmail, CustomEmailConfig } from './customEmailData'
import { CustomEmailVariables } from './CustomEmailVariables'
import { useCustomEmailPreview } from './useCustomEmailPreview'

const MenuLinkProps = {
  variant: 'link',
  borderRadius: 'none',
  pl: 2,
  _active: {
    borderLeft: '2px solid',
    borderLeftColor: 'primary.500',
    color: 'primary.700',
  },
}

interface CustomizeEmailMessagingProps extends FlexProps {
  form: Record<string, any>
  eventDateTime?: string
  isOpen: boolean
  onClose: () => void
  onSubmit?: any
  isLoading?: boolean
}

export const CustomizeEmailMessaging: React.FC<CustomizeEmailMessagingProps> = ({
  isLoading,
  form,
  eventDateTime,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { state, setState, isChanged } = useImmerWithMemory<Record<string, any>>(form.event)
  const confirmationDisclosure = useDisclosure()

  const handleCloseAttempt = () => {
    if (isChanged) confirmationDisclosure.onOpen()
    else onClose()
  }

  const [emailType, setEmailType] = useState<CustomEmail>(CustomEmail.Confirmation)
  const currentSubject = useMemo(
    () => state[emailType].subjectLine || CustomEmailConfig[emailType]?.subjectLine || '',
    [emailType, state]
  )
  const currentBody = useMemo(
    () => state[emailType].body || CustomEmailConfig[emailType]?.body || '',
    [emailType, state]
  )

  const isCustomSubject = useMemo(() => {
    return currentSubject !== CustomEmailConfig[emailType]?.subjectLine
  }, [currentSubject, emailType])

  const isCustomBody = useMemo(() => {
    return currentBody !== CustomEmailConfig[emailType]?.body
  }, [currentBody, emailType])

  const handleCustomizationInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    const [emailType, field] = name.split('.')
    if (!emailType || !field) return
    setState((draft: Record<string, any>) => {
      draft[emailType][field] = value
    })
  }

  const resetSubject = () => {
    handleCustomizationInput({ target: { name: `${emailType}.subjectLine`, value: null } } as any)
  }

  const resetBody = () => {
    handleCustomizationInput({ target: { name: `${emailType}.body`, value: null } } as any)
  }

  const { subjectLinePreview, bodyPreview } = useCustomEmailPreview({
    itemName: form.name,
    subjectLine: currentSubject,
    body: currentBody,
    eventMeetingLink: state.meetingLink,
    eventDateTime: eventDateTime,
  })

  return (
    <>
      <UiModal
        size="full"
        isOpen={isOpen}
        onClose={handleCloseAttempt}
        scrollBehavior="inside"
      >
        <UiModalOverlay />
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit(state)
          }}
        >
          <UiModalContent>
            <UiModalHeader>
              Customize Email Messaging
              <UiModalCloseButton />
            </UiModalHeader>
            <UiModalBody>
              <Grid
                templateColumns={{ base: '1fr', lg: '200px 1fr 1fr' }}
                gap={8}
              >
                <VStack
                  spacing={4}
                  align="flex-start"
                >
                  <UiButton
                    isActive={emailType === CustomEmail.Confirmation}
                    {...MenuLinkProps}
                    onClick={() => setEmailType(CustomEmail.Confirmation)}
                  >
                    Event Confirmation
                  </UiButton>
                  <UiButton
                    isActive={emailType === CustomEmail.ReminderTomorrow}
                    {...MenuLinkProps}
                    onClick={() => setEmailType(CustomEmail.ReminderTomorrow)}
                  >
                    Reminder Day Before
                  </UiButton>
                  <UiButton
                    isActive={emailType === CustomEmail.ReminderToday}
                    {...MenuLinkProps}
                    onClick={() => setEmailType(CustomEmail.ReminderToday)}
                  >
                    Reminder Day Of
                  </UiButton>
                  <UiButton
                    isActive={emailType === CustomEmail.Cancellation}
                    {...MenuLinkProps}
                    onClick={() => setEmailType(CustomEmail.Cancellation)}
                  >
                    Cancellation Alert
                  </UiButton>
                  <UiButton
                    isActive={emailType === CustomEmail.DateTimeChanged}
                    {...MenuLinkProps}
                    onClick={() => setEmailType(CustomEmail.DateTimeChanged)}
                  >
                    Date/Time Change
                  </UiButton>
                  <UiButton
                    isActive={emailType === CustomEmail.MeetingLinkChanged}
                    {...MenuLinkProps}
                    onClick={() => setEmailType(CustomEmail.MeetingLinkChanged)}
                  >
                    Meeting Link Change
                  </UiButton>
                </VStack>

                <Box h="100%">
                  <UiFormControl id="subjectLine">
                    <Flex
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <UiFormLabel>Email Subject Line</UiFormLabel>
                      {isCustomSubject && (
                        <UiButton
                          variant="link"
                          onClick={resetSubject}
                        >
                          Set Default
                        </UiButton>
                      )}
                    </Flex>
                    <UiInput
                      name={`${emailType}.subjectLine`}
                      value={currentSubject}
                      onChange={handleCustomizationInput}
                    />
                  </UiFormControl>
                  <UiFormControl
                    id="body"
                    mt={4}
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="baseline"
                    >
                      <UiFormLabel>Email Body</UiFormLabel>
                      <Box>
                        <CustomEmailVariables />
                        {isCustomBody && (
                          <UiButton
                            ml={4}
                            variant="link"
                            onClick={resetBody}
                          >
                            Set Default
                          </UiButton>
                        )}
                      </Box>
                    </Flex>
                    <UiTextarea
                      name={`${emailType}.body`}
                      minH="600px"
                      value={currentBody}
                      onChange={handleCustomizationInput}
                    />
                  </UiFormControl>
                </Box>
                <Flex
                  data-testid="CustomizeEmailMessaging_emailPreview"
                  flexDir="column"
                  h="100%"
                >
                  <UiDangerous
                    html={subjectLinePreview}
                    fontWeight="semibold"
                  />
                  <Box
                    as="iframe"
                    h="100%"
                    w="100%"
                    flexGrow={1}
                    borderWidth="1px"
                    borderRadius="md"
                    srcDoc={bodyPreview}
                    mt={1}
                  />
                </Flex>
              </Grid>
            </UiModalBody>
            <UiModalFooter>
              <UiModalFooterButtons>
                <UiButton
                  colorScheme="gray"
                  onClick={handleCloseAttempt}
                >
                  Cancel
                </UiButton>
                {isChanged && (
                  <UiButton
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={isLoading}
                  >
                    Apply Changes
                  </UiButton>
                )}
              </UiModalFooterButtons>
            </UiModalFooter>
          </UiModalContent>
        </form>
      </UiModal>
      <ConfirmClose
        {...confirmationDisclosure}
        onConfirm={onClose}
      />
    </>
  )
}
