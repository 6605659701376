import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlertError } from '@postal-io/postal-ui'
import { isPostalDisabled, PostalSendType } from 'components/PostalSend/postalSendHelpers'
import { PostalSendLoading } from 'components/PostalSend/PostalSendLoading'
import { PostalUnavailable } from 'components/PostalSend/PostalUnavailable'
import { useNavigateSendFlowParams, usePostalPermissions, useRouteBack } from 'hooks'
import React, { useMemo } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { GetApprovedPostalDocument } from '../../api'
import { PostalSend } from '../PostalSend'

export const ContactPostalSend: React.FC = () => {
  const { approvedPostalId } = useParams() as any
  const navigate = useNavigate()

  const getApprovedPostal = useGraphqlQuery(GetApprovedPostalDocument, { id: approvedPostalId })
  const postal = useMemo(() => getApprovedPostal.data?.getApprovedPostal, [getApprovedPostal.data?.getApprovedPostal])
  useAlertError(getApprovedPostal.error)

  const { method, variantId, returnTo, returnPath, contactFilter } = useNavigateSendFlowParams()

  const { defaultNonLinkSendMethod } = usePostalPermissions(postal)

  const location = useLocation()
  const defaultBackPath = location.pathname.replace(/((\/send)|(\/link))(\/\w*)?$/, '')
  const back = useRouteBack(returnTo, returnPath ?? defaultBackPath)
  const onNavigateBack = () => (back.path ? navigate(back.path) : navigate(-1))

  const variant = postal?.variants?.find((v) => v.id === variantId)
  const onExitLabel = returnTo ? `Exit to ${returnTo}` : undefined

  function onExit() {
    returnPath ? navigate(returnPath) : onNavigateBack()
  }

  // No contact info to go off of => redirect back to contact page. User probably refreshed on a group send.
  if (!contactFilter) return <Navigate to={back.path ?? defaultBackPath} />

  if (getApprovedPostal.isLoading)
    return (
      <PostalSendLoading
        onNavigateBack={onNavigateBack}
        backLabel={back.title}
      />
    )

  return isPostalDisabled(postal) ? (
    <PostalUnavailable
      isOpen
      onClose={onNavigateBack}
    />
  ) : (
    <PostalSend
      method={method ?? defaultNonLinkSendMethod}
      type={PostalSendType.Contact}
      postal={postal!}
      variant={variant}
      contacts={contactFilter}
      onNavigateBack={onNavigateBack}
      navigateBackLabel={back.title}
      onExit={onExit}
      onExitLabel={onExitLabel}
    />
  )
}
