import { CloseIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import type {
  BoxProps,
  FlexProps,
  FormControlProps,
  FormLabelProps,
  IconButtonProps,
  TooltipProps,
} from '@chakra-ui/react'
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Badge,
  Box,
  Button,
  CloseButton,
  Flex,
  Grid,
  HStack,
  Icon,
  ModalBody,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  ScaleFade,
  Stack,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react'
import type {
  UiAlertProps,
  UiDataTableFilterProps,
  UiDialogProps,
  UiInputRichTextProps,
  UiInputTextWithVariablesProps,
  UiLinkProps,
  UiSSDataTableProps,
  UiStatusTagProps,
  UiTextareaWithVariablesProps,
  UiTooltipProps,
  ZButtonProps,
  ZTextProps,
} from '@postal-io/postal-ui'
import {
  UiCollapse,
  UiDate,
  UiEnhancedTooltip,
  UiFormControl,
  UiFormLabel,
  UiIconButton,
  UiInputRichText,
  UiInputTextWithVariables,
  UiLink,
  UiModal,
  UiModalCloseButton,
  UiModalContent,
  UiModalOverlay,
  UiProgressBar,
  UiSSDataTable,
  UiTag,
  UiTagLabel,
  UiTextareaWithVariables,
  UiTextTruncate,
  UiTime,
  UiTooltip,
  ZButton,
  ZHeading,
  ZInput,
  ZText,
} from '@postal-io/postal-ui'
import type { PostalFulfillment } from 'api'
import type { AutoCompleteUserV2Props } from 'components/AutoComplete'
import { AutoCompleteUser } from 'components/AutoComplete'
import type { LegacyRef, PropsWithChildren } from 'react'
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import type { IconBaseProps } from 'react-icons'
import type { IconType } from 'react-icons/lib'
import {
  MdNorthEast,
  MdOutlineChevronLeft,
  MdOutlineInfo,
  MdOutlineSettingsInputComponent,
  MdOutlineUndo,
  MdSearch,
  MdSouthWest,
} from 'react-icons/md'
import { useDebouncedCallback } from 'use-debounce'

import borderImageError from 'assets/images/border-images/alert-dashed-border-error.svg'
import borderImageInfo from 'assets/images/border-images/alert-dashed-border-info.svg'
import borderImageSuccess from 'assets/images/border-images/alert-dashed-border-success.svg'
import borderImageWarning from 'assets/images/border-images/alert-dashed-border-warning.svg'

export const ZInlineFormControl: React.FC<FormControlProps> = (props) => (
  <UiFormControl
    w="100%"
    display="grid"
    gridTemplateColumns="1fr 1fr"
    // this will hide tooltips until hover, but let's not just yet
    // sx={{
    //   '& label .chakra-icon': {
    //     opacity: 0,
    //     transition: '0.2s opacity, 0.2s transform',
    //     transitionDelay: '1s',
    //     transform: 'translateX(-10px)',
    //   },
    //   '&:hover label .chakra-icon, :focus label .chakra-icon': {
    //     opacity: 1,
    //     transitionDelay: '0.5s',
    //     transform: 'translateX(0)',
    //   },
    // }}
    {...props}
  />
)

export const ZResponsiveFormControl: React.FC<FormControlProps> = (props) => (
  <UiFormControl
    w="100%"
    display="grid"
    gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
    {...props}
  />
)

export const ZFormLabel: React.FC<FormLabelProps> = ({ children, ...rest }) => (
  <UiFormLabel
    fontFamily="Lexend"
    display="flex"
    alignItems="center"
    fontSize="sm"
    color="atomicGray.600"
    {...rest}
  >
    {children}
  </UiFormLabel>
)

interface ZInfoTooltipProps extends Omit<TooltipProps, 'aria-label' | 'children'> {
  'aria-label'?: string
}

export const ZInfoTooltip: React.FC<ZInfoTooltipProps> = forwardRef((props, ref: React.Ref<HTMLDivElement>) => {
  const { color = 'atomicGray.400', boxSize = '18px', ml, mr, mt, mb, ...rest } = props
  const placement = useBreakpointValue({ base: 'bottom', desktop: 'right' })
  return (
    <Tooltip
      ref={ref}
      zIndex={9999}
      py={1}
      px={2}
      placement={placement as any}
      {...rest}
    >
      <Box
        color={color}
        ml={ml}
        mr={mr}
        mt={mt}
        mb={mb}
      >
        <MdOutlineInfo
          size={boxSize as string}
          color="inherit"
        />
      </Box>
    </Tooltip>
  )
})

interface ZFormLabelWithTooltipProps extends FormLabelProps {
  tooltipLabel: string
  rootProps?: FlexProps
  tooltipProps?: Omit<UiTooltipProps, 'children'>
}

export const ZFormLabelWithTooltip: React.FC<PropsWithChildren<ZFormLabelWithTooltipProps>> = ({
  tooltipLabel,
  rootProps,
  tooltipProps,
  children,
  ...rest
}) => {
  return (
    <Flex
      alignItems="center"
      mb={1}
      {...rootProps}
    >
      <ZFormLabel {...rest}>{children}</ZFormLabel>
      <ZInfoTooltip
        label={tooltipLabel}
        placement="top"
        ml={2}
        {...tooltipProps}
      />
    </Flex>
  )
}

// interface ZDialogProps extends UiDialogProps {
//   showBackButton?: boolean
//   onBack?: () => void
// }
// export const ZDialog: React.FC<ZDialogProps> = ({ children, title, showBackButton, onBack, bodyProps, ...rest }) => (
//   <UiModal
//     size="6xl"
//     scrollBehavior="outside"
//     {...rest}
//   >
//     <UiModalOverlay />
//     <UiModalContent
//       bg="white"
//       position="relative"
//     >
//       <ScaleFade
//         in={showBackButton}
//         unmountOnExit
//       >
//         <PostalSendSubnavBackButton
//           position="absolute"
//           top={5}
//           left={4}
//           bg="atomicGray.100"
//           color="atomicGray.500"
//           _hover={{ bg: 'atomicGray.300', color: 'atomicGray.600' }}
//           onClick={() => onBack && onBack()}
//         />
//       </ScaleFade>

//       <UiEnhancedTooltip
//         hasArrow
//         placement="top"
//         label="close"
//         openDelay={500}
//       >
//         <UiModalCloseButton
//           top={6}
//           right={5}
//           fontSize="sm"
//           color="atomicGray.500"
//           _hover={{ color: 'atomicGray.600', bg: 'none' }}
//         />
//       </UiEnhancedTooltip>
//       <UiModalHeader
//         bg="none"
//         color="atomicGray.900"
//         p={7}
//         verticalAlign="middle"
//       >
//         <ZHeading
//           size="h4"
//           fontSize="xl"
//           fontWeight="normal"
//         >
//           {title}
//         </ZHeading>
//       </UiModalHeader>
//       <UiModalBody
//         pt="0 !important"
//         px="60px !important"
//         {...bodyProps}
//       >
//         {children}
//       </UiModalBody>
//     </UiModalContent>
//   </UiModal>
// )

interface ZBasicDialogButtonsProps {
  onClose: () => void
  confirmText?: string
  confirmColorScheme?: string
  confirmProps?: ZButtonProps
  cancelText?: string
  isConfirmDisabled?: boolean
  onConfirm: (() => void) | ((e: React.FormEvent) => void)
}
export const ZBasicDialogButtons: React.FC<ZBasicDialogButtonsProps> = ({
  onClose,
  confirmText = 'Confirm',
  confirmColorScheme = 'atomicBlue',
  confirmProps,
  cancelText = 'Cancel',
  isConfirmDisabled,
  onConfirm,
}) => (
  <Flex
    mt={10}
    justifyContent="space-between"
  >
    <ZButton
      variant="solid"
      justifyContent="center"
      colorScheme={confirmColorScheme}
      onClick={onConfirm}
      disabled={isConfirmDisabled}
      px={4}
      minW="160px"
      autoFocus
      {...confirmProps}
    >
      {confirmText}
    </ZButton>
    <ZButton
      ml={2}
      variant="link"
      _hover={{ color: 'atomicGray.600' }}
      justifyContent="center"
      onClick={onClose}
    >
      {cancelText}
    </ZButton>
  </Flex>
)

interface ZLinkProps extends Omit<ZTextProps, 'onClick'> {
  href?: string
  onClick?: UiLinkProps['onClick']
}
export const ZLink: React.FC<ZLinkProps> = forwardRef(({ children, onClick, href, ml, ...rest }, ref) => (
  <UiLink
    ref={ref as LegacyRef<HTMLAnchorElement>}
    onClick={onClick}
    href={href}
    userSelect="none"
    ml={ml}
  >
    <ZText
      display="flex"
      alignItems="center"
      fontWeight={rest.fontWeight ?? 350}
      color={rest?.color || 'atomicGray.500'}
      {...rest}
    >
      {children}
    </ZText>
  </UiLink>
))

export function ZSSDataTable<T extends Record<string, any>>(props: UiSSDataTableProps<T>) {
  return (
    <UiSSDataTable
      boxShadow="none"
      border="1px solid"
      borderColor="atomicGray.200"
      borderRadius={10}
      sx={{
        '& thead th ': { fontSize: 'sm', fontWeight: 700, pt: 4, pb: 2.5, px: 8 },
        '& tbody td': { borderBottomColor: 'transparent', px: 8 },
        '& tbody tr:last-of-type td': { pb: 5 },
      }}
      {...props}
    />
  )
}

export const ZCardStripe: React.FC<PropsWithChildren<BoxProps | { color: string }>> = ({
  color,
  children,
  ...rest
}) => (
  <Box
    position="absolute"
    top={0}
    left={0}
    right={0}
    px={5}
    height="40px"
    borderTopLeftRadius={10}
    borderTopRightRadius={10}
    bg={color}
    display="flex"
    justifyContent="right"
    alignItems="center"
    {...rest}
  >
    {children}
  </Box>
)

export const ZCardStripeHeading: React.FC<BoxProps> = (props) => (
  <ZHeading
    size="h6"
    fontSize="sm"
    color="white"
    display="flex"
    alignItems="center"
    {...props}
  />
)

const getDashedBorderImageUrl = (status: string) => {
  switch (status) {
    case 'warning':
      return borderImageWarning
    case 'error':
      return borderImageError
    case 'success':
      return borderImageSuccess
    case 'info':
    default:
      return borderImageInfo
  }
}

const ALERT_COLORS = {
  info: {
    borderColor: 'atomicBlue.100',
    bg: 'atomicBlue.5',
    contentColor: 'atomicBlue.700',
  },
  success: {
    borderColor: 'vendorGreen.100',
    bg: 'vendorGreen.5',
    contentColor: 'vendorGreen.800',
  },
  warning: {
    borderColor: 'atomicYellow.100',
    bg: '#FFFBF4',
    contentColor: 'atomicYellow.700',
  },
  error: {
    borderColor: 'atomicRed.100',
    bg: 'atomicRed.5',
    contentColor: 'atomicRed.700',
  },
  update: {
    borderColor: 'atomicGray.100',
    bg: 'atomicGray.5',
    contentColor: 'atomicGray.700',
  },
}

export const ZAlert: React.FC<React.PropsWithChildren<UiAlertProps>> = ({
  status = 'warning',
  title,
  isLoading,
  onClose,
  children,
  hideClose,
  ...props
}) => {
  const [toggle, setToggle] = useState(true)
  const { bg, contentColor } = useMemo(() => ALERT_COLORS[status], [status])

  const handleClick = () => {
    setToggle(false)
    onClose && onClose()
  }

  return toggle ? (
    <>
      {isLoading && <UiProgressBar />}
      <Alert
        status={status}
        borderRadius={5}
        backgroundImage={getDashedBorderImageUrl(status)}
        bgColor={bg}
        alignItems="center"
        p={5}
        {...props}
      >
        <InfoOutlineIcon
          color={contentColor}
          mr={3}
        />
        {title && (
          <AlertTitle mr={2}>
            <ZText color={contentColor}>{title}</ZText>
          </AlertTitle>
        )}
        {children && (
          <AlertDescription mr={2}>
            <ZText color={contentColor}>{children}</ZText>
          </AlertDescription>
        )}
        {!hideClose && (
          <UiTooltip
            hasArrow
            label="Dismiss Alert"
            openDelay={500}
          >
            <CloseButton
              color={contentColor}
              onClick={handleClick}
            />
          </UiTooltip>
        )}
      </Alert>
    </>
  ) : null
}

export const ZStatusHistory: React.FC<{ fulfillment: PostalFulfillment } & UiStatusTagProps> = ({
  fulfillment,
  label,
  ...rest
}) => {
  // show latest item first & decide whether a new date is to be shown
  const statusHistory = useMemo(() => {
    const history = [...(fulfillment.history ?? [])].sort((a, b) => (a.created > b.created ? -1 : 1))
    return history.map((item, index) => ({
      ...item,
      newDay: new Date(item.created).toDateString() !== new Date(history[index - 1]?.created).toDateString(),
    }))
  }, [fulfillment.history])

  return (
    <Popover isLazy>
      <PopoverTrigger>
        <Button
          variant="naked"
          p={0}
          minW={0}
          h="unset"
          _hover={{ opacity: 0.8 }}
        >
          <ZStatusTag
            label={label}
            {...rest}
          />
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          sx={zPopoverContentStyles}
          w="350px"
        >
          <PopoverCloseButton color="atomicGray.500" />
          <ZHeading
            as="h6"
            fontSize="body-lg"
            color="atomicGray.500"
            borderBottom="1px solid"
            borderBottomColor="atomicGray.200"
            mt={-2.5}
            mb={2.5}
            pb={2.5}
          >
            Details
          </ZHeading>
          <PopoverArrow />

          <Stack spacing={5}>
            {statusHistory.map((item) => (
              <HStack
                key={item.created}
                spacing={5}
                alignItems="center"
                sx={zDateTimeHoverParentStyles}
              >
                <ZDateTimeHoverDisplay
                  defaultDisplay={item.newDay ? 'date' : 'time'}
                  date={item.created}
                />
                <ZText
                  color="atomicGray.600"
                  fontSize="body-sm"
                  overflowWrap="anywhere"
                >
                  {item.notes}
                </ZText>
              </HStack>
            ))}
            {!statusHistory.length && <ZText textAlign="center">No history present</ZText>}
          </Stack>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

// these should be on the parent hover element
export const zDateTimeHoverParentStyles = {
  '& .show-on-hover': { maxH: 0, opacity: 0, fontSize: 'body-sm', transition: '0.2s' },
  '&:hover .show-on-hover': { maxH: '20px', opacity: 0.7 },
}
interface ZDateTimeHoverDisplayProps {
  defaultDisplay: 'date' | 'time'
  date: Date
}
export const ZDateTimeHoverDisplay: React.FC<ZDateTimeHoverDisplayProps> = ({ date, defaultDisplay }) => {
  return (
    <Stack
      spacing={-1}
      my={-2}
      justifyContent="flex-end"
      width="80px"
      cursor="default"
      flexShrink={0}
      alignItems="stretch"
      sx={zDateTimeHoverParentStyles}
    >
      <UiDate
        className={defaultDisplay === 'time' ? 'show-on-hover' : ''}
        date={date}
        fontFamily="Lexend"
        color="atomicGray.500"
        fontSize="body-md"
      />
      <UiTime
        className={defaultDisplay === 'date' ? 'show-on-hover' : ''}
        date={date}
        fontFamily="Lexend"
        color="atomicGray.500"
        fontSize="body-md"
      />
    </Stack>
  )
}

export const ZStatusTag: React.FC<UiStatusTagProps> = ({ colorScheme, label, ...rest }) => {
  return (
    <UiTag
      color="atomicGray.900"
      fontFamily="Lexend"
      fontSize="body-sm"
      fontWeight="bold"
      alignSelf="right"
      width="120px"
      p={2}
      justifyContent="center"
      alignItems="center"
      minH="0px"
      h="1.5rem"
      minW="5rem"
      borderRadius="12px"
      lineHeight="14px"
      bg={colorScheme}
      borderColor={colorScheme}
      {...rest}
    >
      <UiTagLabel
        w="100%"
        textAlign="center"
      >
        {label}
      </UiTagLabel>
    </UiTag>
  )
}

interface ZHiddenSearchBarProps extends BoxProps {
  setSearchString: (searchStr: string) => void
  searchString: string
  debounce?: number
  placeholder?: string
  buttonProps?: ZButtonProps
  iconProps?: IconBaseProps
}
export const ZHiddenSearchBar: React.FC<ZHiddenSearchBarProps> = ({
  setSearchString,
  searchString,
  debounce = 400,
  placeholder = 'Search...',
  buttonProps,
  iconProps,
  ...rest
}) => {
  const [value, setValue] = useState<string>('')
  const [focused, setFocus] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const debouncedSetSearchString = useDebouncedCallback(setSearchString, debounce)

  // reset when input value resets
  useEffect(() => void (searchString === '' && setValue('')), [searchString])

  const isOpen = true // focused || !!value

  return (
    <Flex
      border="1px solid"
      borderColor={focused ? 'atomicGray.400' : isOpen ? 'atomicGray.200' : 'transparent'}
      _hover={{ borderColor: focused ? 'atomicGray.400' : isOpen ? 'atomicGray.400' : 'transparent' }}
      transition="0.2s border-color"
      borderRadius={3}
      height={10}
      my={-3}
      pl={3}
      position="relative"
      alignItems="center"
      onClick={() => {
        inputRef.current?.focus()
      }}
      {...rest}
    >
      {isOpen ? (
        <ZButton
          variant="link"
          p={0}
          onClick={() => {
            inputRef.current?.focus()
          }}
          _hover={{ color: 'atomicGray.500' }}
          cursor={isOpen ? 'default' : 'pointer'}
          leftIcon={
            <MdSearch
              size="24px"
              {...iconProps}
            />
          }
          isDisabled
          _disabled={{ 'cursor': 'default', '&:hover': { color: 'atomicGray.500' } }}
          ml={0}
          mr={0}
          minW="auto"
          w={5}
        />
      ) : (
        <UiTooltip
          label="Search"
          isDisabled={isOpen}
          hasArrow
          openDelay={500}
          shouldWrapChildren
        >
          <ZButton
            variant="link"
            p={0}
            onClick={() => {
              inputRef.current?.focus()
            }}
            _hover={{ color: 'atomicGray.600' }}
            // cursor={isOpen ? 'default' : 'pointer'}
            leftIcon={
              <MdSearch
                size="24px"
                {...iconProps}
              />
            }
            isDisabled={isOpen}
            _disabled={{ 'cursor': 'default', '&:hover': { color: 'atomicGray.500' } }}
            ml={0}
            mr={0}
            minW="auto"
            w={5}
            {...buttonProps}
          />
        </UiTooltip>
      )}
      <Flex
        w={isOpen ? '250px' : '0'}
        overflow="hidden"
        transition="0.2s width"
        alignItems="center"
      >
        <ZInput
          minW="unset"
          border="none"
          borderRadius={0}
          _focus={{ border: 'none', outline: 'none' }}
          p={0}
          pl={2}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onKeyDown={(e: any) => {
            if (e.key === 'Escape') {
              setValue('')
              setSearchString('')
              inputRef.current?.blur()
            }
          }}
          flexGrow={1}
          flexShrink={1}
          variant="unstyled"
          ref={inputRef}
          value={value}
          onChange={(e: any) => {
            setValue(e.target.value)
            debouncedSetSearchString(e.target.value)
          }}
          placeholder={placeholder}
        />
        {value && (
          <UiTooltip
            label="clear"
            hasArrow
            openDelay={1000}
          >
            <ZLink
              variant="link"
              aria-label="clear-search"
              onClick={() => {
                setValue('')
                setSearchString('')
              }}
              h="38px"
              p={3}
            >
              <CloseIcon />
            </ZLink>
          </UiTooltip>
        )}
      </Flex>
    </Flex>
  )
}

interface ZSearchBarProps extends BoxProps {
  setSearchString: (searchStr: string) => void
  searchString: string
  debounce?: number
  placeholder?: string
  buttonProps?: ZButtonProps
  iconProps?: IconBaseProps
}
export const ZSearchBar: React.FC<ZSearchBarProps> = ({
  setSearchString,
  searchString,
  debounce = 400,
  placeholder = 'Search...',
  buttonProps,
  iconProps,
  ...rest
}) => {
  const [value, setValue] = useState<string>('')
  const [focused, setFocus] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const debouncedSetSearchString = useDebouncedCallback(setSearchString, debounce)

  // reset when input value resets
  useEffect(() => void (searchString === '' && setValue('')), [searchString])

  return (
    <Flex
      border="1px solid"
      borderColor={focused ? 'atomicGray.400' : 'atomicGray.200'}
      _hover={{ borderColor: focused ? 'atomicGray.400' : 'atomicGray.400' }}
      transition="0.2s border-color"
      borderRadius={3}
      height={10}
      my={-3}
      pl={3}
      position="relative"
      alignItems="center"
      {...rest}
    >
      <Icon
        as={MdSearch}
        w={5}
        fontSize="24px"
        color="atomicGray.500"
      />
      <Flex
        overflow="hidden"
        alignItems="center"
        flexGrow={1}
        flexShrink={1}
      >
        <ZInput
          ref={inputRef}
          minW="unset"
          border="none"
          borderRadius={0}
          _focus={{ border: 'none', outline: 'none' }}
          p={0}
          pl={2}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onKeyDown={(e: any) => {
            if (e.key === 'Escape') {
              e.preventDefault()
              inputRef.current?.blur()
              setValue('')
              setSearchString('')
            }
          }}
          flexGrow={1}
          flexShrink={1}
          variant="unstyled"
          value={value}
          onChange={(e: any) => {
            setValue(e.target.value)
            debouncedSetSearchString(e.target.value)
          }}
          placeholder={placeholder}
        />
        {value && (
          <UiTooltip
            label="clear"
            hasArrow
            openDelay={1000}
          >
            <ZLink
              variant="link"
              aria-label="clear-search"
              onClick={() => {
                setValue('')
                setSearchString('')
              }}
              h="38px"
              p={3}
            >
              <CloseIcon />
            </ZLink>
          </UiTooltip>
        )}
      </Flex>
    </Flex>
  )
}

// input styling for the login page
export const zLoginInputStyles = {
  size: 'lg',
  color: 'white',
  backgroundColor: 'none',
  _placeholder: { color: 'white' },
  _focus: { color: 'white' },
  fontSize: 'body-md',
  height: '50px',
  fontWeight: 'normal',
  borderRadius: 2,
  borderTopRightRadius: 2,
  borderBottomRightRadius: 2,
}
export const zLoginPasswordStyles = {
  position: 'relative' as any, // for some reason this prop throws type errors
  iconProps: {
    fontSize: 'body-md',
    color: 'atomicGray.900',
    position: 'absolute' as any, // for some reason this prop throws type errors
    right: '10px',
    top: '9px',
    height: 8,
    width: 8,
    minW: 0,
    borderRadius: 0,
    sx: { '& svg': { height: '16px' } },
  },
}
export const zLoginAutocompleteStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: '50px',
    borderRadius: '2px',
    cursor: 'pointer',
    color: 'white',
    background: 'transparent',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontFamily: 'Lexend',
    fontSize: '14px',
    fontWeight: 'normal',
    paddingLeft: '12px',
    color: 'white !important',
  }),
  singleValue: (provided: any) => ({ ...provided, color: 'white' }),
  indicatorSeparator: (provided: any) => ({ ...provided, background: 'transparent' }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    'color': 'var(--chakra-colors-atomicGray-500)',
    '&:hover': {
      color: 'var(--chakra-colors-atomicGray-500)',
    },
    'paddingLeft': 0,
    '& svg': { fill: 'transparent', width: 0 },
    '&:after': {
      content: '""',
      height: 1,
      width: 1,
      border: '4px solid',
      borderColor: 'transparent',
      borderTopColor: 'currentColor',
      marginRight: 7,
      marginTop: 8,
    },
  }),
}

// styling rules to get this stupid thing to look right
export const zAutocompleteStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: '40px',
    borderRadius: '3px',
    cursor: 'pointer',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontFamily: 'Lexend',
    fontSize: '14px',
    fontWeight: 'normal',
    paddingLeft: '12px',
  }),
  indicatorSeparator: (provided: any) => ({ ...provided, background: 'transparent' }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    'color': 'var(--chakra-colors-atomicGray-500)',
    '&:hover': {
      color: 'var(--chakra-colors-atomicGray-500)',
    },
    'paddingLeft': 0,
    '& svg': { fill: 'transparent', width: 0 },
    '&:after': {
      content: '""',
      height: 1,
      width: 1,
      border: '4px solid',
      borderColor: 'transparent',
      borderTopColor: 'currentColor',
      marginRight: 7,
      marginTop: 8,
    },
  }),
}
// if you want an input animation
const zAutocompleteStylesWithAnimation = (animation: string) => {
  return {
    ...zAutocompleteStyles,
    control: (provided: any) => ({
      ...zAutocompleteStyles.control(provided),
      animation,
    }),
  }
}

const SingleValue = ({ data: { firstName, lastName, emailAddress } }: any) => {
  return (
    <Flex
      position="absolute"
      left={4}
      flexDir="column"
      justifyContent="space-around"
      w="92%"
    >
      <ZText
        color="atomicGray.600"
        fontWeight="normal"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        mb={-1}
      >
        {firstName} {lastName}
      </ZText>
      <ZText
        as="span"
        color="atomicGray.500"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        display="inline-block"
        fontSize="10px"
      >
        {emailAddress}
      </ZText>
    </Flex>
  )
}
type SelectUserRowProps = {
  name: string
  email: string
}
const SelectUserRow: React.FC<SelectUserRowProps> = ({ name, email }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <Box
        flex={1}
        flexDir="column"
      >
        <ZText color="atomicGray.600">
          <UiTextTruncate fontSize="md">{name}</UiTextTruncate>
        </ZText>
        {email && (
          <ZText
            fontSize="12px"
            color="gray.500"
          >
            {email}
          </ZText>
        )}
      </Box>
    </Box>
  )
}

export const ZAutoCompleteUser: React.FC<AutoCompleteUserV2Props & { animation?: string }> = (props) => (
  <AutoCompleteUser
    styles={props.animation ? zAutocompleteStylesWithAnimation(props.animation) : zAutocompleteStyles}
    components={{ SingleValue }}
    isClearable={false}
    formatOptionLabel={(u) => (
      <SelectUserRow
        name={`${u.firstName} ${u.lastName}`}
        email={u.userName}
      />
    )}
    {...props}
  />
)

/**
 * Sticky action list
 */

type ActionListItem = {
  title: string
  icon?: IconType
  onClick?: () => void
  viewBox?: string
  isHidden?: boolean
}
interface ZActionListProps extends BoxProps {
  actions: ActionListItem[]
  iconSize?: string
}
export const ZActionList: React.FC<ZActionListProps> = ({ actions, top = '70px', ...rest }) => (
  <Box
    width="200px"
    height="100%"
    {...rest}
  >
    <Stack
      spacing={6}
      position="sticky"
      top={top}
      pt={2}
    >
      {actions
        .filter((a) => !a.isHidden)
        .map((action) => (
          <ZLink
            key={action.title}
            onClick={action.onClick}
            whiteSpace="nowrap"
          >
            <Icon
              as={action.icon}
              viewBox={action.viewBox}
              marginRight={2}
              color="atomicGray.500"
            />{' '}
            {action.title}
          </ZLink>
        ))}
    </Stack>
  </Box>
)

/**
 * Restyling rich text components
 */
export const ZInputTextWithVariables = forwardRef(
  (props: UiInputTextWithVariablesProps, ref: React.Ref<HTMLInputElement>) => (
    <UiInputTextWithVariables
      ref={ref}
      height={10}
      fontFamily="Lexend"
      color="atomicGray.600"
      fontSize="sm"
      fontWeight="normal"
      sx={{ '& p': { lineHeight: '22px' } }}
      {...props}
    />
  )
)

export const ZTextareaWithVariables = forwardRef(
  (props: UiTextareaWithVariablesProps, ref: React.Ref<HTMLInputElement>) => (
    <UiTextareaWithVariables
      ref={ref}
      fontFamily="Lexend"
      fontSize="sm"
      color="atomicGray.600"
      fontWeight="normal"
      sx={{ '& p': { lineHeight: '22px' } }}
      minH="200px"
      {...props}
    />
  )
)

export const ZInputRichText = forwardRef((props: UiInputRichTextProps, ref: React.Ref<HTMLInputElement>) => (
  <UiInputRichText
    ref={ref}
    fontFamily="Lexend"
    fontSize="sm"
    color="atomicGray.600"
    fontWeight="normal"
    sx={{ '& strong': { fontWeight: 600 } }}
    minH={200}
    {...props}
  />
))

// table padding & styling
export const zMultiSelectStyles = {
  // '& table': {
  //   borderSpacing: '0 10px !important',
  //   borderCollapse: 'separate !important',
  // },
  '& th, td': {
    border: 'none',
  },
  // '& tr td:first-of-type': {
  //   borderRadius: '3px 0 0 3px',
  // },
  // '& tr td:last-of-type': {
  //   borderRadius: '0 3px 3px 0',
  // },
  '& thead': {
    mb: 5,
  },
  '& tr > td': {
    py: 5,
  },
  '& tr.selected': {
    'bg': 'atomicBlue.5',
    'fill': 'atomicBlue.400',
    '& > td, > td > p, > td > div, > td > time': {
      color: 'atomicBlue.400',
      // fontWeight: `$"bold" !important`,
    },
    '& > td span > svg': { color: 'var(--chakra-colors-atomicBlue-400) !important', opacity: 0.7 },
  },
  '& .chakra-checkbox__control': {
    'borderColor': 'atomicGray.500',
    '&[data-checked]': {
      bg: 'atomicBlue.400',
      borderColor: 'atomicBlue.400',
    },
    '&[data-indeterminate]': {
      bg: 'atomicBlue.400',
      borderColor: 'atomicBlue.400',
    },
  },
  '& thead tr:last-child': {
    background: 'gray.50',
  },
}
// This will apply z styles to old style table cells
// but also override their color and font weights
export const zMultiSelectCellStyles = {
  '& p': {
    fontFamily: 'Lexend',
    fontWeight: `$"normal" !important`,
    color: 'atomicGray.600',
  },
}

export const zPopoverContentStyles = {
  'p': 5,
  'borderRadius': 10,
  'borderColor': 'atomicGray.200',
  'boxShadow': '0 0 10px rgba(0,0,0,.1)',
  '&:focus': { boxShadow: '0 0 10px rgba(0,0,0,.1) !important' },
}

export const ZDialogButtonsContainer: React.FC<FlexProps> = ({ children, ...rest }) => (
  <Flex
    justifyContent="space-between"
    mt={16}
    {...rest}
  >
    {children}
  </Flex>
)

export interface ZDialogButtonsProps extends FlexProps {
  onConfirm?: () => void
  confirmText?: string
  confirmProps?: ZButtonProps
  isConfirmLoading?: boolean
  isConfirmDisabled?: boolean
  onClose?: () => void
  cancelText?: string
  cancelProps?: ZButtonProps
}

/**
Renders buttons for ZDialog. By default, it has two buttons: Confirm on the left, cancel on the right. If you pass in children,
it will override the default buttons.
 */

export const ZDialogButtons: React.FC<ZDialogButtonsProps> = ({
  onConfirm,
  confirmText = 'Confirm',
  confirmProps,
  isConfirmLoading,
  isConfirmDisabled,
  onClose,
  cancelText = 'Cancel',
  cancelProps,
  children,
  ...rest
}) => {
  return (
    <ZDialogButtonsContainer {...rest}>
      {!children && (
        <>
          <ZButton
            {...(onConfirm && { onClick: onConfirm })}
            {...(isConfirmLoading && { isLoading: isConfirmLoading })}
            {...(isConfirmDisabled && { isDisabled: isConfirmDisabled })}
            colorScheme="atomicBlue"
            minW="100px"
            {...confirmProps}
          >
            {confirmText}
          </ZButton>
          <ZButton
            onClick={onClose}
            colorScheme="atomicGray"
            variant="ghost"
            minW="100px"
            {...cancelProps}
          >
            {cancelText}
          </ZButton>
        </>
      )}
      {children && children}
    </ZDialogButtonsContainer>
  )
}

interface ZDialogProps extends Omit<UiDialogProps, 'onClose'> {
  showBackButton?: boolean
  titleProps?: BoxProps
  onBack?: () => void
  hideClose?: boolean
  onClose?: () => void
}
export const ZDialog: React.FC<ZDialogProps> = ({
  children,
  title,
  showBackButton,
  onBack,
  bodyProps,
  titleProps,
  hideClose,
  onClose,
  ...rest
}) => (
  <UiModal
    size="6xl"
    scrollBehavior="outside"
    onClose={onClose ?? (() => {})}
    {...rest}
  >
    <UiModalOverlay />
    <UiModalContent
      bg="white"
      position="relative"
      display="flex"
      flexDir="column"
    >
      <Grid
        gridTemplateColumns="32px auto 32px"
        alignContent="start"
        px={7}
        py={10}
      >
        <Box>
          <ScaleFade
            in={showBackButton}
            unmountOnExit
          >
            <ZDialogBackButton
              bg="atomicGray.50"
              color="atomicGray.400"
              _hover={{ bg: 'atomicGray.100', color: 'atomicGray.500' }}
              onClick={() => onBack && onBack()}
            />
          </ScaleFade>
        </Box>
        <ZHeading
          size="h4"
          fontSize="xl"
          fontWeight="bold"
          color="atomicGray.800"
          display="inline-flex"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          px={4}
          {...titleProps}
        >
          {title}
        </ZHeading>
        {!hideClose && (
          <Flex>
            <UiEnhancedTooltip
              hasArrow
              placement="top"
              label="Close"
              openDelay={500}
            >
              <UiModalCloseButton
                fontSize="xs"
                color="atomicGray.600"
                position="unset"
                _hover={{ color: 'atomicGray.500', bg: 'none' }}
              />
            </UiEnhancedTooltip>
          </Flex>
        )}
      </Grid>
      <ModalBody
        px={14}
        pb={10}
        {...bodyProps}
      >
        {children}
      </ModalBody>
    </UiModalContent>
  </UiModal>
)

export interface ZDialogBackButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  onClick: () => void
  label?: string
}

export const ZDialogBackButton: React.FC<ZDialogBackButtonProps> = ({ onClick, label = 'Go Back', ...rest }) => (
  <UiEnhancedTooltip
    label={label}
    hasArrow
    mt={2}
  >
    <UiIconButton
      aria-label={'go-back'}
      onClick={onClick}
      bg="white"
      color="atomicGray.700"
      borderRadius="50%"
      _hover={{ bg: 'white', opacity: 0.8 }}
      _active={{ bg: 'white', opacity: 0.9 }}
      minW="unset"
      h={8}
      w={8}
      mr={5}
      fontSize="2xl"
      {...rest}
    >
      <MdOutlineChevronLeft color="inherit" />
    </UiIconButton>
  </UiEnhancedTooltip>
)

export interface ZStatArrowProps extends BoxProps {
  type: 'increase' | 'decrease'
  size?: string
}

export const ZStatArrow: React.FC<ZStatArrowProps> = ({ type, size = '16px', ...rest }) => {
  return (
    <Box {...rest}>
      {type === 'increase' ? (
        <MdNorthEast
          color="inherit"
          size={size}
        />
      ) : (
        <MdSouthWest
          color="inherit"
          size={size}
        />
      )}
    </Box>
  )
}

export const ZDataTableFilter: React.FC<React.PropsWithChildren<UiDataTableFilterProps>> = ({
  filterCount = 0,
  isOpen,
  onToggle,
  children,
  onReset,
  closeOnReset = true,
  right,
  collapseProps,
  ...rest
}) => {
  const doReset = () => {
    onReset()
    if (isOpen && closeOnReset) onToggle()
  }

  return (
    <>
      <Flex
        justify="space-between"
        align="flex-end"
        px={0}
        pt={2}
        {...rest}
      >
        <Flex
          alignItems="center"
          gap={2}
        >
          <ZButton
            colorScheme="atomicGray"
            fontSize="sm"
            ml={0}
            borderWidth="none"
            variant="link"
            onClick={onToggle}
            gap={2}
            px={0}
          >
            <MdOutlineSettingsInputComponent size="20px" />
            {isOpen ? 'Hide' : 'Show'} Filters
          </ZButton>
          {filterCount > 0 && (
            <>
              <Badge
                rounded="full"
                backgroundColor="atomicBlue.400"
                color="white"
                variant="solid"
                px="0.5rem"
              >
                <ZText
                  color="inherit"
                  fontSize="inherit"
                >
                  {filterCount}
                </ZText>
              </Badge>
              <ZButton
                leftIcon={<MdOutlineUndo size="20px" />}
                variant="link"
                color="atomicGray.500"
                fontSize="sm"
                size="md"
                _hover={{ textDecoration: 'none' }}
                onClick={doReset}
              >
                Reset
              </ZButton>
            </>
          )}
        </Flex>
        <Box>{right}</Box>
      </Flex>
      <UiCollapse
        in={isOpen}
        style={{ overflow: isOpen ? 'visible' : 'hidden' }}
        {...collapseProps}
      >
        {children}
      </UiCollapse>
    </>
  )
}
