import { Box, Fade, Flex, Grid, Link, Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiAlert, UiDangerous, UiLink, ZButton, ZCard, ZCardBody, ZHeading, ZText } from '@postal-io/postal-ui'
import type { ApprovedPostal, Attribution, DesignTemplate, MarketplaceProduct, ProductVariant } from 'api'
import { FulfillmentType, GetAccountDocument, GetMarketplaceProductDocument, Status, TeamsDocument } from 'api'
import { CenteredBox } from 'components/Common'
import type { DesignTemplateProps } from 'components/DesignTemplate/designTemplateReducer'
import { PostalImageCollage } from 'components/Postal'
import { CATEGORY } from 'components/Postals'
import { PageTitle, useAcl, useHelp, useSession } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import {
  MdOutlineCalendarToday,
  MdOutlineEdit,
  MdOutlineGrass,
  MdOutlineLocalShipping,
  MdOutlineNote,
} from 'react-icons/md'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { TeamCurrencyAlert } from '../Postal/ProductAlerts'
import { NavbarBackButton, SecondaryNavbar } from '../PostalSend/SecondaryNavbar'
import { MarketplaceProductDesignEditor } from './MarketplaceProductDesignEditor'
import { MarketplaceProductSidebar } from './MarketplaceProductSidebar'

export interface ItemApproveFormState {
  name: string
  displayName: string
  description: string
  isActive: Boolean
  designTemplate?: DesignTemplate | null
  variants: ProductVariant[]
  teamIds: any[]
  attribution?: Attribution | null
}

interface MarketplacePostalEditorProps {
  postal?: ApprovedPostal | MarketplaceProduct
  activeVariants: ProductVariant[]
  availableVariants: ProductVariant[]
  isLoading?: boolean
  onUpdate: (data: any) => void
}

export const MarketplacePostalEditor: React.FC<MarketplacePostalEditorProps> = ({
  postal,
  activeVariants,
  availableVariants,
  onUpdate,
  isLoading,
}) => {
  const { session } = useSession()
  const { hasFeature } = useAcl()
  const hasMarketplaceV2 = hasFeature('marketplaceV2')
  const editDesign = useDisclosure()
  const formDisclosure = useDisclosure({ defaultIsOpen: !!(postal as ApprovedPostal)?.marketplaceProductId })

  const navigate = useNavigate()

  const [state, setState] = useImmer<ItemApproveFormState>({
    name: '',
    displayName: '',
    description: '',
    isActive: false,
    variants: [],
    teamIds: [],
  })

  const vendor = useMemo(() => {
    // marketplace V2 searches vendor off of the brandName value
    if (hasMarketplaceV2) return postal?.brandName
    let name = ''
    for (const variant of activeVariants) {
      for (const partner of variant.fulfillmentPartnerList || []) {
        if (partner.fulfillmentPartnerName) {
          name = partner.fulfillmentPartnerName
          break
        }
      }
    }
    return name
  }, [activeVariants, hasMarketplaceV2, postal?.brandName])

  const fulfillmentType = useMemo(() => {
    let type
    for (const variant of activeVariants) {
      for (const partner of variant.fulfillmentPartnerList || []) {
        if (partner.fulfillmentType) {
          type = partner.fulfillmentType
          break
        }
      }
    }
    return type
  }, [activeVariants])

  // If we're editing an approved postal, we need the data for the original marketplace
  // product to do things.
  const marketplaceProductQuery = useGraphqlQuery(
    GetMarketplaceProductDocument,
    { id: (postal as ApprovedPostal)?.marketplaceProductId ?? '' },
    { enabled: !!(postal as ApprovedPostal)?.marketplaceProductId }
  )
  const marketplaceProduct = useMemo(
    () => marketplaceProductQuery?.data?.getMarketplaceProduct ?? (postal as MarketplaceProduct),
    [marketplaceProductQuery, postal]
  )

  const estimatedShippingCost = useMemo(
    () => marketplaceProduct?.variants?.[0]?.fulfillmentPartnerList?.[0].shippingOptions?.[0]?.price,
    [marketplaceProduct?.variants]
  )

  useEffect(() => {
    // lets cast this to make it easier to work with things below
    const approved = postal as ApprovedPostal
    setState((draft) => {
      draft.name = approved?.name
      draft.displayName = approved?.displayName || approved?.name
      draft.description = approved?.description
      draft.designTemplate = approved?.designTemplate
      draft.isActive = approved?.status === Status.Active
      draft.variants = activeVariants
      draft.teamIds = approved?.teamIds || []
      draft.attribution = approved?.attribution
    })
  }, [activeVariants, postal, setState])

  const isDirectMail = postal?.category === CATEGORY.DirectMail

  const accountQuery = useGraphqlQuery(GetAccountDocument)
  const account = useMemo(() => accountQuery?.data?.getAccount, [accountQuery?.data?.getAccount])

  //get selected Teams
  const teamsQuery = useGraphqlQuery(TeamsDocument, {
    filter: {
      id: { in: state.teamIds },
      status: { eq: Status.Active },
    },
  })
  const teams = useMemo(() => teamsQuery.data?.teams || [], [teamsQuery.data?.teams])

  // look at the account and each selected team and determine if they have a billing account with
  // the currency that is required in this postal
  const hasCurrency = useMemo(() => {
    const accountHasCurrency = account?.settings?.billingAccountIds?.some((b) => b.currency === postal?.currency)
    const teamsHaveCurrency = teams.every((t) =>
      t.settings?.billingAccountIds?.some((b) => b.currency === postal?.currency)
    )
    return !!accountHasCurrency && !!teamsHaveCurrency
  }, [account?.settings?.billingAccountIds, postal?.currency, teams])

  const { sendMessage } = useHelp()

  const bulkSendAvailable = useMemo(
    () => marketplaceProduct?.variants?.some((v) => v.fulfillmentPartnerList?.some((p) => p.bulkSendAvailable)),
    [marketplaceProduct?.variants]
  )

  const physicalMessageSupported = useMemo(
    () => marketplaceProduct?.variants?.some((v) => v.physicalMessageSupported),
    [marketplaceProduct?.variants]
  )

  const containsPerishables = useMemo(
    () => marketplaceProduct?.variants?.some((v) => v.containsPerishables),
    [marketplaceProduct?.variants]
  )

  const handleDesign = (designTemplate: DesignTemplate) => {
    setState((draft) => void (draft.designTemplate = designTemplate))
    editDesign.onClose()
  }

  const [side] = useState('front')

  const hasPlan = useMemo(() => {
    const allowedPlans = marketplaceProduct?.allowedPlans || []
    return !allowedPlans.length || allowedPlans.includes(session.plan)
  }, [marketplaceProduct?.allowedPlans, session.plan])

  return !editDesign.isOpen ? (
    <>
      <PageTitle
        title={postal?.name}
        section="Marketplace"
      />
      {!hasPlan && (
        <UiAlert status="info">
          Upgrade your postal account through our{' '}
          <UiLink onClick={() => sendMessage(`Let's talk about a new plan.`)}>Sales Team</UiLink> to access this item.
        </UiAlert>
      )}

      <SecondaryNavbar
        left={
          <NavbarBackButton
            onClick={() =>
              (postal as ApprovedPostal)?.marketplaceProductId ? navigate(-1) : navigate('/items/marketplace')
            }
            label={`Back to ${(postal as ApprovedPostal)?.marketplaceProductId ? postal?.name : 'Marketplace'}`}
          />
        }
        right={
          !!postal?.designTemplate && isDirectMail ? (
            <Fade in={formDisclosure.isOpen}>
              <ZButton
                variant="link"
                color="white"
                leftIcon={<MdOutlineEdit size="18px" />}
                onClick={editDesign.onOpen}
              >
                Edit the design
              </ZButton>
            </Fade>
          ) : undefined
        }
        header="Product Information"
      />
      <CenteredBox isLoaded>
        {postal?.currency && !hasCurrency && (
          <TeamCurrencyAlert
            currency={postal?.currency}
            mb={8}
          />
        )}
        <Grid
          templateColumns={{ base: '1fr', lg: '1fr 400px' }}
          gap={{ base: 16 }}
          mt={8}
        >
          <Box>
            <ZCard
              variant="form"
              overflow="hidden"
              isLoading={!postal && marketplaceProductQuery.isLoading}
            >
              <ZCardBody p={8}>
                <Stack gap={8}>
                  {postal?.imageUrls?.length ? <PostalImageCollage postal={postal} /> : null}

                  <Stack gap={1}>
                    <ZHeading size="h4">{postal?.name}</ZHeading>
                    <ZText
                      whiteSpace="break-spaces"
                      color="atomicGray.500"
                    >
                      <UiDangerous html={postal?.description} />
                    </ZText>
                  </Stack>
                  <Grid
                    templateColumns={{ base: '1fr 1fr', xl: 'repeat(4, 1fr)' }}
                    rowGap={4}
                  >
                    {physicalMessageSupported && (
                      <Flex gap={2}>
                        <MdOutlineNote size="20px" />
                        <ZText>Accepts physical note</ZText>
                      </Flex>
                    )}

                    {fulfillmentType === FulfillmentType.Physical && (
                      <Flex gap={2}>
                        <MdOutlineCalendarToday size="20px" />
                        <ZText>Ships on certain days</ZText>
                      </Flex>
                    )}

                    {containsPerishables && (
                      <Flex gap={2}>
                        <MdOutlineGrass size="20px" />
                        <ZText>Contains perishables</ZText>
                      </Flex>
                    )}
                    {bulkSendAvailable && (
                      <Flex gap={2}>
                        <MdOutlineLocalShipping size="20px" />
                        <ZText>Available for bulk shipping</ZText>
                      </Flex>
                    )}
                  </Grid>
                  {!!vendor && (
                    <ZText textAlign="right">
                      View more items by{' '}
                      <Link
                        as={RouterLink}
                        to={`/items/marketplace?vendor=${vendor}`}
                      >
                        {vendor}
                      </Link>
                    </ZText>
                  )}
                </Stack>
              </ZCardBody>
            </ZCard>
          </Box>
          <MarketplaceProductSidebar
            postal={postal}
            estimatedShippingCost={estimatedShippingCost ?? 0}
            activeVariants={activeVariants}
            availableVariants={availableVariants}
            isOpen={formDisclosure.isOpen}
            onOpen={formDisclosure.onOpen}
            isLoading={isLoading}
            onUpdate={onUpdate}
            state={state}
            setState={setState}
          />
        </Grid>
      </CenteredBox>
    </>
  ) : (
    <MarketplaceProductDesignEditor
      onClose={editDesign.onClose}
      onSave={handleDesign}
      name={postal?.name}
      designTemplate={state.designTemplate as DesignTemplateProps}
      initialSide={side}
    />
  )
}
