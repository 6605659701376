import { Box, Flex, Grid, Heading, HStack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  humanize,
  UiConfirm,
  UiDate,
  UiIconPostalEdit2,
  UiSkeleton,
  useAlertError,
  useAlerts,
  ZCard,
  ZCardBody,
  ZHeading,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, ApprovedProductVariant, MagicEventStatus } from 'api'
import {
  CheckAutomationsTiedToPostalDocument,
  ContactEventsTeamDocument,
  DeleteApprovedPostalAndAllAutomationsDocument,
  GetApprovedPostalDocument,
  Status,
} from 'api'
import { NavbarButtonV2 } from 'components/Common/NavbarLink'
import { ZStatusTag } from 'components/Common/ZComponents'
import { PostalSendSidebar } from 'components/PostalSend'
import { PostalSendMethod } from 'components/PostalSend/postalSendHelpers'
import { NavbarActionMenu, NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import {
  LOCKED_EVENT_STATUSES,
  PageTitle,
  POSTAL_INVALIDATIONS,
  SENDABLE_EVENT_STATUSES,
  useBackgroundQueue,
  useMe,
  useNavigateSendFlow,
  usePostalPermissions,
} from 'hooks'
import { statusMapper } from 'lib'
import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CenteredBox } from '../Common/CenteredBox'
import { AutomationInfo } from '../Postal/AutomationInfo'
import { PostalClone } from '../Postal/PostalClone'
import { CurrencyAlert } from '../Postal/ProductAlerts'
import { ContactEventsTeamModal } from './ContactEventsTeamModal'
import { EventsApprovedPostalData, EventsApprovedPostalInstanceData } from './EventsApprovedPostalSections'
import { EventsBanner } from './EventsBanner'
import { RequestCancellationModal } from './RequestCancellationModal'
import { RequestDateChangeModal } from './RequestDateChangeModal'

export const EventsApprovedPostalPage: React.FC = () => {
  const { approvedPostalId } = useParams() as any
  const navigate = useNavigate()
  const Alert = useAlerts()

  const deletePostal = useDisclosure()
  const clonePostal = useDisclosure()
  const requestCancellation = useDisclosure()
  const requestDateChange = useDisclosure()
  const contactEvents = useDisclosure()

  const approvedPostalQuery = useGraphqlQuery(
    GetApprovedPostalDocument,
    { id: approvedPostalId },
    { enabled: !!approvedPostalId }
  )

  const postal = useMemo(
    () => approvedPostalQuery.data?.getApprovedPostal,
    [approvedPostalQuery.data?.getApprovedPostal]
  )

  const [selectedVariant, setSelectedVariant] = useState<ApprovedProductVariant | undefined>(
    postal?.variants?.length ? (postal?.variants[0] as ApprovedProductVariant) : undefined
  )

  const { hasCurrency } = useMe()

  const [warningLevel, _] = useState<'info' | 'warning' | 'error'>('info')

  const { canLink, canContact, canDelete, canUpdate, canSendGiftEmail, canSendDirectly, canCancel } =
    usePostalPermissions(postal)

  const automationsQuery = useGraphqlQuery(
    CheckAutomationsTiedToPostalDocument,
    { approvedPostalId: postal?.id as string },
    { enabled: !!postal?.id && canDelete }
  )

  const currentAutomations = useMemo(() => {
    return automationsQuery.data?.checkAutomationsTiedToPostal?.filter((item) => item.hasAutomations) ?? []
  }, [automationsQuery])

  const { invalidate, queue } = useBackgroundQueue()
  const deletePostalAndAutomations = useGraphqlMutation(DeleteApprovedPostalAndAllAutomationsDocument, {
    onSuccess: (data) => {
      invalidate(POSTAL_INVALIDATIONS)
      queue(data.deleteApprovedPostalAndAllAutomations)
    },
  })

  useAlertError(approvedPostalQuery.error)
  useAlertError(deletePostalAndAutomations.error)

  const handleDelete = async () => {
    if (!postal) return
    try {
      await deletePostalAndAutomations.mutateAsync({ approvedPostalId: postal.id })
      Alert.success('Approved Item Deleted')
      navigate('/events/postals')
    } catch (e) {
      Alert.error(e)
    }
  }

  const submitContactRequest = useGraphqlMutation(ContactEventsTeamDocument, {
    onSuccess: (data) => {
      if (data.contactEventsTeam.eventsEmailFailed && data.contactEventsTeam.vendorEmailFailed) {
        Alert.error('Sorry, there was an issue submitting your request. Please try again or contact support for help')
      } else {
        Alert.success('Your request was submitted')
      }
    },
    onError: (error) => {
      Alert.error(error)
    },
  })

  type ActionTypes =
    | 'EDIT'
    | 'SEND'
    | 'SEND_DIRECT'
    | 'CREATE_LINK'
    | 'CLONE'
    | 'DELETE'
    | 'CANCEL_EVENT'
    | 'CHANGE_DATE'
    | 'CONTACT_EVENTS'

  const sendFlowLink = useNavigateSendFlow()

  const handleAction = (type: ActionTypes) => {
    switch (type) {
      case 'EDIT':
        canUpdate && navigate(`/events/postals/${approvedPostalId}/edit`)
        break
      case 'SEND':
        if (!canSendGiftEmail) return
        navigate(
          sendFlowLink(`/events/postals/${approvedPostalId}/send`, {
            returnTo: 'My Event',
            sendMethod: PostalSendMethod.Email,
          })
        )
        break
      case 'SEND_DIRECT':
        if (!canSendDirectly) return
        navigate(
          sendFlowLink(`/events/postals/${approvedPostalId}/send`, {
            returnTo: 'My Event',
            sendMethod: PostalSendMethod.Direct,
          })
        )
        break
      case 'CREATE_LINK':
        if (!canLink) return
        navigate(
          sendFlowLink(`/events/postals/${approvedPostalId}/send`, {
            returnTo: 'My Event',
            sendMethod: PostalSendMethod.Link,
          })
        )
        break
      case 'CLONE':
        canUpdate && clonePostal.onOpen()
        break
      case 'DELETE':
        canDelete && deletePostal.onOpen()
        break
      case 'CANCEL_EVENT':
        canCancel && postal && requestCancellation.onOpen()
        break
      case 'CHANGE_DATE':
        canContact && requestDateChange.onOpen()
        break
      case 'CONTACT_EVENTS':
        canContact && contactEvents.onOpen()
        break
      default:
    }
  }

  const actions = [
    {
      title: 'Send an Invite',
      onClick: () => handleAction('SEND'),
      isHidden: !canSendGiftEmail,
    },
    {
      title: 'Add Attendees',
      onClick: () => handleAction('SEND_DIRECT'),
      isHidden: !canSendDirectly,
    },
    {
      title: 'Create a MagicLink',
      onClick: () => handleAction('CREATE_LINK'),
      isHidden: !canLink,
    },
    {
      title: 'Clone Event',
      onClick: () => handleAction('CLONE'),
      isHidden: !canUpdate,
    },
    {
      title: 'Request Cancellation',
      onClick: () => handleAction('CANCEL_EVENT'),
      isHidden: !canCancel,
    },
    {
      title: 'Delete Event',
      onClick: () => handleAction('DELETE'),
      isHidden: !canDelete,
    },
    {
      title: 'Request Date Change',
      onClick: () => handleAction('CHANGE_DATE'),
      isHidden: !canContact && !LOCKED_EVENT_STATUSES.includes(postal?.event?.status),
    },
    {
      title: 'Contact Postal Events',
      onClick: () => handleAction('CONTACT_EVENTS'),
      isHidden: !canContact,
    },
  ]

  const activeVariants = useMemo(() => postal && postal.variants?.filter((v) => v.status === Status.Active), [postal])

  const disclosure = useDisclosure({ defaultIsOpen: true })

  const onClone = (postal: ApprovedPostal) => {
    clonePostal.onClose()
    disclosure.onClose()
    navigate(`/events/postals/${postal.id}`)
  }

  const errorMessage = useMemo(() => {
    if ((activeVariants?.length ?? 0) === 0) return 'Out of Stock'
    if (postal?.status === Status.Disabled) return 'Inactive Event'
    // This works for these statuses
    if (LOCKED_EVENT_STATUSES.includes(postal?.event?.status)) return `Event ${humanize(postal?.event?.status)}`
    // Default error message - Show disabled 'Send Invitations' button
    if (!SENDABLE_EVENT_STATUSES.includes(postal?.event?.status)) return 'Send Invitations'
    return undefined
  }, [activeVariants?.length, postal?.event?.status, postal?.status])

  const hasAutomations = currentAutomations.length > 0

  const { text: label, color, backgroundColor } = statusMapper(postal?.event?.status as MagicEventStatus)

  const PostalDisplayV2: React.FC = () => (
    <>
      <SecondaryNavbar
        zIndex={100}
        right={
          <HStack
            spacing={5}
            display={{ base: 'none', md: 'flex' }}
          >
            {canUpdate && (
              <NavbarButtonV2
                leftIcon={<UiIconPostalEdit2 />}
                onClick={() => handleAction('EDIT')}
              >
                Edit Event
              </NavbarButtonV2>
            )}
          </HStack>
        }
        farRight={<NavbarActionMenu actionItems={actions} />}
        left={
          <NavbarBackButton
            onClick={() => navigate('/events/postals')}
            label="Back to My Events"
          />
        }
        header="Event Information"
      />
      <CenteredBox
        pt={8}
        isLoaded
      >
        <Grid
          w="100%"
          templateColumns={{
            base: '1fr',
            lg: '1fr 376px',
          }}
          gridGap={8}
        >
          <Grid gap={8}>
            <ZCard
              variant="form"
              overflow="hidden"
            >
              <EventsBanner
                postal={postal}
                canUpdate={canUpdate}
              />

              <ZCardBody>
                <Flex
                  w="100%"
                  justify="space-between"
                  align="center"
                  gap={8}
                >
                  <ZHeading size="h3">{postal?.name}</ZHeading>
                  <ZHeading size="h6">
                    <Flex gap={2}>
                      <ZStatusTag
                        label={label}
                        color={color}
                        backgroundColor={backgroundColor}
                        w="max-content"
                      />
                      <UiDate
                        date={postal?.event?.eventDateTime}
                        format={{ dateStyle: 'medium' }}
                        fallback="Date: TBD"
                      />
                    </Flex>
                  </ZHeading>
                </Flex>
              </ZCardBody>
              {postal && (
                <EventsApprovedPostalData
                  postal={postal}
                  onEdit={() => handleAction('EDIT')}
                />
              )}
            </ZCard>

            {postal && (
              <EventsApprovedPostalInstanceData
                postal={postal}
                onEdit={() => handleAction('EDIT')}
              />
            )}
          </Grid>

          <PostalSendSidebar
            postal={postal}
            onNext={() => {
              navigate(
                sendFlowLink(`/events/postals/${approvedPostalId}/send`, {
                  returnTo: 'My Event',
                  sendMethod: canLink ? PostalSendMethod.Link : undefined,
                  variantId: selectedVariant?.id,
                })
              )
            }}
            sending={false}
            setVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
            nextStepButtonText="Send Invitations"
            errorMessage={errorMessage}
          />
        </Grid>
      </CenteredBox>
    </>
  )

  return (
    <>
      <PageTitle
        title={postal?.name}
        section="Items"
      />
      {!!postal?.currency && !hasCurrency(postal.currency) && (
        <CurrencyAlert
          currency={postal.currency}
          status={warningLevel}
        />
      )}

      {postal && <PostalDisplayV2 />}

      {postal && canUpdate && clonePostal.isOpen && (
        <PostalClone
          postal={postal}
          onClose={clonePostal.onClose}
          isOpen={clonePostal.isOpen}
          onClone={onClone}
        />
      )}
      {canDelete && deletePostal.isOpen && (
        <UiConfirm
          status="warning"
          title={`Delete Event ${hasAutomations ? ' & Related Integrations' : ''}`}
          isOpen={deletePostal.isOpen}
          onConfirm={handleDelete}
          onClose={deletePostal.onClose}
          buttonColor="red"
          buttonText="Delete"
          isLoading={deletePostalAndAutomations.isLoading || automationsQuery.isFetching}
          size="2xl"
        >
          <UiSkeleton isLoaded={!automationsQuery.isFetching}>
            <Box minH="100px">
              {hasAutomations ? (
                <AutomationInfo automations={currentAutomations} />
              ) : (
                <Heading
                  as="h2"
                  fontSize="lg"
                  mt={4}
                  color="gray.600"
                >
                  Are you sure you want to <strong>Delete</strong> this Event?
                </Heading>
              )}
            </Box>
          </UiSkeleton>
        </UiConfirm>
      )}
      {canCancel && (
        <RequestCancellationModal
          approvedPostalId={approvedPostalId}
          isOpen={requestCancellation.isOpen}
          onClose={requestCancellation.onClose}
          submitRequest={submitContactRequest}
        />
      )}
      {canContact && (
        <RequestDateChangeModal
          approvedPostalId={approvedPostalId}
          isOpen={requestDateChange.isOpen}
          onClose={requestDateChange.onClose}
          submitRequest={submitContactRequest}
        />
      )}
      {canContact && (
        <ContactEventsTeamModal
          approvedPostalId={approvedPostalId}
          isOpen={contactEvents.isOpen}
          onClose={contactEvents.onClose}
          submitRequest={submitContactRequest}
        />
      )}
    </>
  )
}
