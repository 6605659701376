import { chakra, Fade } from '@chakra-ui/react'
import type { ZProductCardProps } from '@postal-io/postal-ui'
import {
  ZProductCard,
  ZProductCardBody,
  ZProductCardContent,
  ZProductCardImage,
  ZProductCardImageContainer,
  ZProductCardImageIcons,
} from '@postal-io/postal-ui'
import { ZLink, ZStatusTag } from 'components/Common/ZComponents'
import { statusMapper } from 'lib'
import React, { useCallback } from 'react'
import type { ApprovedPostal, MagicEventStatus } from '../../api/index'
import { useAssets } from '../../hooks'
import { usePostalImages } from '../../hooks/usePostalImages'
import { EventsApprovedPostalPriceRangeV2 } from './EventsPriceRange'

interface EventsCardPostalV2Props extends Omit<ZProductCardProps, 'onSelect'> {
  postal: ApprovedPostal
  onSelect?: (postal: ApprovedPostal) => any
}

export const EventsCardPostal: React.FC<EventsCardPostalV2Props> = ({ postal, onSelect, ...rest }) => {
  const { assetUrlSrc } = useAssets()
  const image = usePostalImages(postal)
  const srcProps = assetUrlSrc(image?.[0]?.url, { w: 350, h: 350, fit: 'clip' })

  const handleSelect = useCallback(() => onSelect?.(postal), [postal, onSelect])

  const { text, backgroundColor, color } = statusMapper(postal?.event?.status as MagicEventStatus)
  return (
    <Fade in>
      <ZProductCard
        size="tall"
        hoverStyle="lift"
        {...rest}
      >
        <ZProductCardContent>
          <ZProductCardImageContainer onClick={handleSelect}>
            <ZProductCardImageIcons
              justifyContent="right"
              pr={2}
            >
              <ZStatusTag
                w="max-content"
                boxShadow="md"
                label={text}
                bg={backgroundColor}
                color={color}
              />
            </ZProductCardImageIcons>
            <ZProductCardImage {...srcProps} />
          </ZProductCardImageContainer>
          <ZProductCardBody>
            <ZLink
              mt={4}
              flexDir="column"
              alignItems="left"
              gap={0}
              lineHeight="short"
              onClick={handleSelect}
            >
              <chakra.p
                color="atomicGray.700"
                noOfLines={1}
                maxW="325px"
              >
                {postal.name}
              </chakra.p>
              <EventsApprovedPostalPriceRangeV2 postal={postal} />
            </ZLink>
          </ZProductCardBody>
        </ZProductCardContent>
      </ZProductCard>
    </Fade>
  )
}
