import type { BoxProps, ButtonProps, TextProps } from '@chakra-ui/react'
import { Box, chakra, Flex, FormControl, Grid, Icon, InputGroup, useDisclosure } from '@chakra-ui/react'
import {
  UiMenu,
  UiMenuButton,
  UiMenuList,
  UiSkeleton,
  UiTooltip,
  ZInput,
  ZInputLeftIcon,
  ZText,
} from '@postal-io/postal-ui'
import { sortBy } from 'lodash'
import React, { useMemo, useRef, useState } from 'react'
import { MdCheckCircle, MdOutlinePeople, MdSearch } from 'react-icons/md'
import { useWindowSize } from 'react-use'
import useMeasure from 'react-use-measure'
import type { NamedProductAccess } from '../../api'

interface AccountPickerProps extends Omit<BoxProps, 'onSelect'> {
  productAccessId: string
  productAccessList: NamedProductAccess[]
  onSelect: (product: NamedProductAccess) => void
  isLoading?: boolean
  headerText?: string
}

export const AccountPicker: React.FC<AccountPickerProps> = ({
  productAccessId,
  productAccessList,
  onSelect,
  isLoading,
  ...rest
}) => {
  const accounts = useMemo(() => {
    const map = new Map<string, NamedProductAccess[]>()
    sortBy(productAccessList, ['accountName', 'teamName']).forEach((a) => {
      if (!a) return
      const teams = map.get(a.productAccess.accountId || '') || []
      teams.push(a)
      if (a.productAccess.accountId) map.set(a.productAccess.accountId, teams)
    })
    return Array.from(map.values())
  }, [productAccessList])

  const currentAccount = useMemo(() => {
    return productAccessList.find((account) => account.productAccess.id === productAccessId)
  }, [productAccessId, productAccessList])

  return (
    <UiSkeleton
      h="100%"
      minH="100px"
      isLoaded={!isLoading}
      {...rest}
    >
      <Grid
        columnGap={2}
        rowGap={8}
        gridTemplateColumns={`repeat(${Math.min(accounts.length, 3)},1fr)`}
        pt={8}
      >
        {accounts.map((teams: NamedProductAccess[], idx: number) => {
          const mainAccount = teams.find((a) => !a.teamName)
          const teamAccounts = teams.filter((a) => !!a.teamName)
          const accountName = teams?.[0]?.accountName || ''
          const isMainActive = currentAccount?.productAccess?.id === mainAccount?.productAccess?.id

          const accountDisabled = !!teams?.[0]?.accountDisabled
          const canSelectMainAccount = !accountDisabled && !!mainAccount && !isMainActive
          const errorMessage = accountDisabled ? 'This Account is disabled.' : false

          return (
            <UiTooltip
              label={errorMessage}
              placement="top"
            >
              <Flex
                direction="column"
                key={`${accountName}-${idx}`}
                opacity={errorMessage ? 0.6 : 1}
              >
                <Flex
                  onClick={canSelectMainAccount ? () => onSelect(mainAccount) : undefined}
                  alignItems="center"
                  mb={-1}
                  p={2.5}
                  borderRadius={4}
                  data-testid="main-account"
                  as="span"
                  // border="1px"
                  // borderColor={isMainActive ? 'atomicBlue.400' : 'transparent'}
                  _hover={
                    canSelectMainAccount
                      ? {
                          borderColor: 'atomicGray.200',
                          cursor: canSelectMainAccount ? 'pointer' : 'default !important',
                          color: !isMainActive ? 'atomicGray.600' : '',
                        }
                      : {}
                  }
                >
                  <ZText
                    fontSize="lg"
                    fontWeight={isMainActive ? 'bold' : 'normal'}
                    display="flex"
                    alignItems="center"
                    cursor={canSelectMainAccount ? 'pointer' : 'default'}
                    _hover={{
                      opacity: canSelectMainAccount ? '0.65' : '1',
                    }}
                  >
                    {accountName}
                    {isMainActive && (
                      <Icon
                        ml={1}
                        as={MdCheckCircle}
                        color="atomicBlue.400"
                      />
                    )}
                  </ZText>
                </Flex>
                {teamAccounts.map((item: NamedProductAccess) => {
                  const isTeamActive = currentAccount?.productAccess?.id === item?.productAccess?.id
                  return (
                    <AccountListItem
                      key={item.productAccess.id}
                      item={item}
                      onSelect={onSelect}
                      isActive={isTeamActive}
                      isDisabled={accountDisabled}
                      icon={
                        <Icon
                          mr={1}
                          as={MdOutlinePeople}
                          fontSize="lg"
                          opacity="0.4"
                        />
                      }
                    />
                  )
                })}
              </Flex>
            </UiTooltip>
          )
        })}
      </Grid>
    </UiSkeleton>
  )
}

interface AccountPickerDropdownProps extends Omit<ButtonProps, 'onSelect'> {
  productAccessId: string
  productAccessList: NamedProductAccess[]
  onSelect: (product: NamedProductAccess) => void
  isLoading?: boolean
  headerText?: string
}
export const AccountPickerDropdown: React.FC<AccountPickerDropdownProps> = ({
  productAccessId,
  productAccessList,
  onSelect,
  isLoading,
  ...rest
}) => {
  const disclosure = useDisclosure()
  const [searchString, setSearchString] = useState('')
  const focusRef = useRef<HTMLInputElement>(null)

  const filteredProductAccess = useMemo(
    () =>
      productAccessList.filter(
        (access) =>
          access.accountName?.toLowerCase().includes(searchString.toLowerCase()) ||
          access.teamName?.toLowerCase().includes(searchString.toLowerCase())
      ),
    [productAccessList, searchString]
  )

  const accounts = useMemo(() => {
    const map = new Map<string, NamedProductAccess[]>()
    sortBy(filteredProductAccess, ['accountName', 'teamName']).forEach((a) => {
      if (!a) return
      const teams = map.get(a.productAccess.accountId || '') || []
      teams.push(a)
      if (a.productAccess.accountId) map.set(a.productAccess.accountId, teams)
    })
    return Array.from(map.values())
  }, [filteredProductAccess])

  const currentAccount = useMemo(() => {
    return productAccessList.find((account) => account.productAccess.id === productAccessId)
  }, [productAccessId, productAccessList])

  const onOpen = () => {
    disclosure.onOpen()
    setSearchString('')
    setTimeout(() => focusRef.current?.focus(), 200)
  }

  const [ref, { top }] = useMeasure()
  const { height } = useWindowSize()
  const maxH = useMemo(() => `calc(${height - top}px - 2rem)`, [height, top])

  return (
    <UiMenu
      matchWidth
      {...disclosure}
      onOpen={onOpen}
    >
      <UiMenuButton
        w="340px"
        textAlign="left"
        {...rest}
      >
        Choose an Account...
      </UiMenuButton>
      <UiMenuList
        maxH={maxH}
        display="flex"
        flexDir="column"
      >
        <FormControl
          px={5}
          py={3}
          ref={ref}
        >
          <InputGroup>
            <ZInputLeftIcon icon={<MdSearch size="16px" />} />
            <ZInput
              ref={focusRef}
              placeholder="Search"
              value={searchString}
              onChange={({ target: { value } }) => setSearchString(value)}
            />
          </InputGroup>
        </FormControl>
        <Box overflowY="scroll">
          {accounts.map((teams: NamedProductAccess[], idx: number) => {
            const mainAccount = teams.find((a) => !a.teamName)
            const teamAccounts = teams.filter((a) => !!a.teamName)
            const accountName = teams?.[0]?.accountName || ''
            const isMainActive = currentAccount?.productAccess?.id === mainAccount?.productAccess?.id

            const accountDisabled = !!teams?.[0]?.accountDisabled
            const canSelectMainAccount = !accountDisabled && !!mainAccount && !isMainActive
            const errorMessage = accountDisabled ? 'This Account is disabled.' : false

            return (
              <UiTooltip
                label={errorMessage}
                placement="top"
              >
                <Flex
                  direction="column"
                  key={`${accountName}-${idx}`}
                  opacity={errorMessage ? 0.6 : 1}
                >
                  <Flex
                    onClick={canSelectMainAccount ? () => onSelect(mainAccount) : undefined}
                    alignItems="center"
                    mb={-1}
                    py={2.5}
                    px={5}
                    borderRadius={4}
                    data-testid="main-account"
                    as="span"
                    _hover={
                      canSelectMainAccount
                        ? {
                            borderColor: 'atomicGray.200',
                            cursor: canSelectMainAccount ? 'pointer' : 'default',
                            color: !isMainActive ? 'atomicGray.600' : '',
                          }
                        : {}
                    }
                  >
                    <ZText
                      fontSize="lg"
                      fontWeight={isMainActive ? 'bold' : 'normal'}
                      display="flex"
                      alignItems="center"
                      cursor={canSelectMainAccount ? 'pointer' : 'default'}
                      _hover={{
                        opacity: !isMainActive ? '0.65' : '1',
                      }}
                    >
                      {accountName}
                      {isMainActive && (
                        <Icon
                          ml={1}
                          as={MdCheckCircle}
                          color="atomicBlue.400"
                        />
                      )}
                    </ZText>
                  </Flex>
                  {teamAccounts.map((item: NamedProductAccess) => {
                    const isTeamActive = currentAccount?.productAccess?.id === item?.productAccess?.id
                    return (
                      <AccountListItem
                        key={item.productAccess.id}
                        item={item}
                        onSelect={onSelect}
                        isActive={isTeamActive}
                        isDisabled={accountDisabled}
                      />
                    )
                  })}
                </Flex>
              </UiTooltip>
            )
          })}
          {!accounts.length && (
            <ZText
              textAlign="center"
              color="atomicGray.500"
              p={5}
            >
              (No Results)
            </ZText>
          )}
        </Box>
      </UiMenuList>
    </UiMenu>
  )
}

interface AccountListItemProps extends Omit<TextProps, 'onSelect'> {
  item: NamedProductAccess
  isActive: boolean
  isDisabled: boolean
  onSelect: (e: NamedProductAccess) => void
  icon?: any
}
const AccountListItem: React.FC<AccountListItemProps> = ({ item, onSelect, isActive, isDisabled, icon }) => {
  const isClickable = !isActive && !isDisabled
  return (
    <>
      {item.teamName && (
        <ZText
          onClick={() => isClickable && onSelect(item)}
          mb={-1}
          p={2.5}
          pl={icon ? 2.5 : 8}
          borderRadius={4}
          fontWeight={isActive ? 'bold' : 'normal'}
          _hover={isClickable ? { borderColor: 'atomicGray.200' } : undefined}
        >
          <chakra.span
            _hover={{
              opacity: isClickable ? '0.65' : '1',
            }}
            cursor={isClickable ? 'pointer' : 'default'}
            display="flex"
            alignItems="center"
          >
            {icon}
            {item.teamName}
            {isActive && (
              <Icon
                ml={1}
                as={MdCheckCircle}
                color="atomicBlue.400"
              />
            )}
          </chakra.span>
        </ZText>
      )}
    </>
  )
}
