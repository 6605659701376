import { SkeletonText } from '@chakra-ui/react'
import { joinStrings, ZHeading, ZText } from '@postal-io/postal-ui'
import type { Address } from 'api'
import React from 'react'

export interface OrderRecipientBlockProps {
  shipTo?: Address | null
  shipToName?: string | null
  isLoading?: boolean
}

export const OrderRecipientBlock: React.FC<OrderRecipientBlockProps> = ({ shipTo, shipToName, isLoading }) => {
  const { address1, address2, address3, city, state, postalCode } = shipTo ?? {}

  return (
    <>
      <ZHeading
        size="h6"
        mb={6}
        color="atomicGray.700"
      >
        Recipient
      </ZHeading>

      {isLoading ? (
        <SkeletonText
          w="300px"
          noOfLines={3}
        />
      ) : (
        <>
          <ZText
            size="sm"
            color="atomicGray.700"
          >
            {shipToName}
          </ZText>

          {!shipTo ? (
            <ZText
              size="sm"
              color="atomicGray.700"
              variant="bold"
            >
              No Address
            </ZText>
          ) : (
            address1 && (
              <ZText
                size="sm"
                data-private
              >
                {address1}
              </ZText>
            )
          )}
          {address2 && (
            <ZText
              size="sm"
              color="atomicGray.700"
              data-private
            >
              {address2}
            </ZText>
          )}
          {address3 && (
            <ZText
              size="sm"
              color="atomicGray.700"
              data-private
            >
              {address3}
            </ZText>
          )}
          {(city || state || postalCode) && (
            <ZText
              size="sm"
              color="atomicGray.700"
              data-private
            >
              {joinStrings([city, state], ', ')} {postalCode}
            </ZText>
          )}
        </>
      )}
    </>
  )
}
