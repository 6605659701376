import { ScaleFade, Wrap, WrapItem } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import {
  ZButton,
  ZSelectPopover,
  ZSelectPopoverBody,
  ZSelectPopoverContent,
  ZSelectPopoverFooter,
  ZSelectPopoverItem,
  ZSelectPopoverItems,
  ZSelectPopoverSearch,
  ZSelectPopoverTrigger,
  ZTag,
  ZTagCloseButton,
  ZTagLabel,
  ZText,
} from '@postal-io/postal-ui'
import type { Role, User } from 'api'
import { SearchUsersDocument, Status } from 'api'
import { useUserOmniSearch } from 'hooks'
import { DEFAULT_ACCOUNT_TEAM_ID } from 'lib'
import { useMemo, useState } from 'react'
import { useWindowSize } from 'react-use'
import useMeasure from 'react-use-measure'
import { useDebounce } from 'use-debounce'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'

const LIMIT = 20

export const UsersFilterSearch: React.FC<{
  value?: string[]
  teamIds?: string[]
  roles?: Role[]
  onChange: (userIds: string[]) => void
  isFirst?: boolean
  isLast?: boolean
}> = ({ value, onChange, teamIds, roles, isFirst, isLast }) => {
  const [search, setSearch] = useState('')
  const [debounced] = useDebounce(search, 400)

  // on this query, the account teamId should be null
  const realTeamIds = useMemo(() => {
    return teamIds?.map((id) => (id === DEFAULT_ACCOUNT_TEAM_ID ? null : id))
  }, [teamIds])

  // UiPopoverItems expects an empty array for no values, lets memoize this here
  const realValue = useMemo(() => value ?? [], [value])

  // if teamIds change, reset the selected users
  useDeepCompareEffectNoCheck(() => {
    if (teamIds?.length && realValue.length) onChange([])
  }, [teamIds])

  const baseVariables = useMemo(() => {
    return {
      filter: {
        productAccess_roles: !!roles?.length ? { in: roles } : undefined,
        productAccess_teamId: !!realTeamIds?.length ? { in: realTeamIds } : undefined,
        status: { eq: Status.Active },
      },
      limit: LIMIT,
    }
  }, [realTeamIds, roles])

  const selectedUsersQuery = useGraphqlInfiniteQuery(
    SearchUsersDocument,
    {
      filter: {
        id: { in: realValue },
        productAccess_roles: !!roles?.length ? { in: roles } : undefined,
        productAccess_teamId: !!realTeamIds?.length ? { in: realTeamIds } : undefined,
        status: { eq: Status.Active },
      },
      limit: LIMIT,
    },
    { keepPreviousData: true, enabled: !!realValue.length }
  )

  const searchUsers = useUserOmniSearch(debounced, baseVariables)

  const users = searchUsers.results

  const tagUsersList = useMemo(() => {
    return selectedUsersQuery.mergedData?.searchUsers.users?.filter((u) => realValue?.includes(u.id)) || []
  }, [selectedUsersQuery.mergedData?.searchUsers.users, realValue])

  // Temporarily removing the infinite scroll functionality while fake omni search is in place
  // const { bottomRef } = useInfiniteScroll({
  //   hasMore: searchUsers.hasNextPage,
  //   loadMore: searchUsers.fetchNextPage,
  //   loading: searchUsers.isFetching,
  // })

  const [ref, { top }] = useMeasure()
  const { height } = useWindowSize()
  const maxH = useMemo(() => `calc(${height - top}px - 5rem)`, [height, top])

  const handleRemove = (user: User) => {
    const updatedValue = realValue.filter((i) => i !== user.id)
    onChange(updatedValue)
  }

  const title = useMemo(
    () => (realValue.length ? `User${realValue.length > 1 ? 's' : ''}` : 'Select a User'),
    [realValue.length]
  )

  return (
    <ZSelectPopover
      placement="bottom-start"
      size="lg"
      autoFocus
    >
      <ZSelectPopoverTrigger
        isFirst={isFirst}
        isLast={isLast}
        color="atomicGray.500"
        fontWeight="normal"
      >
        {!!realValue.length && (
          <ZText
            color="atomicBlue.400"
            mr={1}
          >{`(${realValue.length}) `}</ZText>
        )}
        {title}
      </ZSelectPopoverTrigger>
      <ZSelectPopoverContent
        maxH={maxH}
        ref={ref}
        data-testid="UsersFilterSearch_popover"
      >
        <ZSelectPopoverBody>
          <ZSelectPopoverSearch
            onChange={({ target }) => setSearch(target.value)}
            value={search}
            placeholder="Search Username"
          />
          <Wrap
            w="100%"
            p={4}
            borderBottomColor="atomicGray.100"
            borderBottomWidth="2px"
          >
            {tagUsersList?.length ? (
              tagUsersList?.map((user) => (
                <ScaleFade
                  key={user.id}
                  initialScale={0.8}
                  in={true}
                >
                  <WrapItem>
                    <ZTag
                      colorScheme="atomicGray"
                      variant="outline"
                      p={2}
                      size="md"
                      onClick={() => handleRemove(user)}
                      _hover={{ cursor: 'pointer' }}
                    >
                      <ZTagLabel
                        fontWeight="bold"
                        color="atomicGray.500"
                        userSelect="none"
                      >
                        {`${user.firstName} ${user?.lastName?.slice(0, 1)}.`}
                      </ZTagLabel>
                      <ZTagCloseButton />
                    </ZTag>
                  </WrapItem>
                </ScaleFade>
              ))
            ) : (
              <ZText
                p={2}
                color="atomicGray.500"
                userSelect="none"
              >
                Showing all users
              </ZText>
            )}
          </Wrap>
          <ZSelectPopoverItems
            onChange={onChange}
            value={realValue}
            overflow="scroll"
          >
            {users.map((user) => {
              return (
                <ZSelectPopoverItem
                  key={user.id}
                  value={user.id}
                  my={-0.5}
                >
                  <ZText
                    ml={1}
                    fontWeight="bold"
                    fontSize="md"
                    color="atomicGray.600"
                    userSelect="none"
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </ZText>
                  <ZText
                    ml={1}
                    fontWeight="normal"
                    fontSize="sm"
                    color="atomicGray.500"
                  >
                    {user.userName}
                  </ZText>
                </ZSelectPopoverItem>
              )
            })}
          </ZSelectPopoverItems>
        </ZSelectPopoverBody>
        <ZSelectPopoverFooter
          borderTopColor="atomicGray.100"
          borderTopWidth="2px"
        >
          {/* {!searchUsers.isLoading && <Box ref={bottomRef} />} */}
          <ZButton
            variant="link"
            colorScheme="atomicGray"
            onClick={() => onChange([])}
            display={realValue?.length ? 'block' : 'none'}
          >
            Clear
          </ZButton>
        </ZSelectPopoverFooter>
      </ZSelectPopoverContent>
    </ZSelectPopover>
  )
}
