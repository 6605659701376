import { UiLoading, useAlerts } from '@postal-io/postal-ui'
import { AnalyticsEvent, PageTitle, useAnalyticsEvent, useAnalyticsSend, useRecaptcha, useSession } from 'hooks'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isLocalOrTestEnvironment } from 'lib'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ConfirmEmail } from './ConfirmEmail'
import { MainWithMarketingPane } from './Main'
import { HIGHLIGHTS_1, HIGHLIGHTS_2, MAIN_HEADER_1, MAIN_HEADER_2 } from './signUpData'
import { SignUpPage } from './SignUpPage'

const RESTRICTED_SIGNUP_URL = 'https://www.postal.com/request-demo'

const errorMessages: { [key: string]: string } = {
  corp: 'Please select a business email address. Non-corporate SSO connections are not allowed.',
  domain: 'We are currently in a closed beta. Please reach out to sales@postal.com to be put on the list.',
  invalid: 'Please enter a valid email address.',
  unknown: 'Error registering email address.  Please try again.',
}

enum PageTypes {
  general = 'general',
  events = 'events',
  gifting = 'gifting',
  store = 'store',
}
const DEFAULT_PAGE_TYPE = PageTypes.gifting

export interface SignUpState {
  firstName: string
  lastName: string
  emailAddress: string
  password: string
  password2: string
}

export const SignUp: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [redirectLoader, setRedirectLoader] = useState(false)
  const { executeRecaptcha } = useRecaptcha()
  const params = useParams()
  const errorType = params.errorType
  const flags = useFlags()
  const Alert = useAlerts()
  const { signUp } = useSession()
  const navigate = useNavigate()
  const { pageType = DEFAULT_PAGE_TYPE } = useParams() as any
  const [form, setForm] = useState<SignUpState>()

  useEffect(() => {
    if (errorType) {
      Alert.error(errorMessages[errorType] || errorMessages.unknown)
      navigate(`/sign-up/${pageType}`)
    }
  }, [Alert, errorType, navigate, pageType])

  const sendAnalytics = useAnalyticsSend()

  const onSubmit = async (data: SignUpState) => {
    setLoading(true)

    const { firstName, lastName, emailAddress, password } = data

    try {
      const siteVerify = (await executeRecaptcha?.('signUp')) || ''
      if (siteVerify && lastName && emailAddress && firstName && password) {
        await signUp({
          firstName,
          lastName,
          emailAddress,
          siteVerify,
          password,
          password2: password,
        })
        sendAnalytics({ event: AnalyticsEvent.SignupComplete, data: { type: pageType } })
        setForm(data)

        if (isLocalOrTestEnvironment) {
          setTimeout(() => navigate(`/verify/${siteVerify}`), 2000)
        }
        Alert.success('Signup email is on the way')
      } else {
        Alert.error('An error has occured')
      }
    } catch (err) {
      Alert.warning(err)
    } finally {
      setLoading(false)
    }
  }

  const handleSsoCallback = () => {
    sendAnalytics({ event: AnalyticsEvent.SignupComplete, data: { type: pageType } })
  }

  useAnalyticsEvent({ event: AnalyticsEvent.SignupViewed, data: { type: pageType } })

  useEffect(() => {
    if (flags['featureRestrictedSignup'] === true) {
      setRedirectLoader(true)
      window.location.href = RESTRICTED_SIGNUP_URL
    }
  }, [flags, pageType])

  if (redirectLoader) return <UiLoading />

  if (form)
    return (
      <>
        <PageTitle title="Confirm Email" />
        <MainWithMarketingPane>
          <ConfirmEmail
            firstName={form.firstName}
            emailAddress={form.emailAddress}
            resend={() => onSubmit(form)}
          />
        </MainWithMarketingPane>
      </>
    )

  if (pageType === PageTypes.events) {
    return (
      <>
        <PageTitle title="Sign Up" />
        <SignUpPage
          onSubmit={onSubmit}
          handleSsoCallback={handleSsoCallback}
          loading={loading}
          mainHeader={MAIN_HEADER_1}
          highlights={HIGHLIGHTS_1}
        />
      </>
    )
  } else {
    return (
      <>
        <PageTitle title="Sign Up" />
        <SignUpPage
          onSubmit={onSubmit}
          handleSsoCallback={handleSsoCallback}
          loading={loading}
          mainHeader={MAIN_HEADER_2}
          highlights={HIGHLIGHTS_2}
        />
      </>
    )
  }
}
