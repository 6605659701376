import { AttachmentIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  HStack,
  Slide,
  SlideFade,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { GraphqlError, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiEnhancedTooltip,
  UiIconButton,
  UiTooltip,
  useAlerts,
  useLocalStorage,
  useLocalStorageToggle,
  ZCheckbox,
  ZConfirm,
  ZInput,
  ZText,
} from '@postal-io/postal-ui'
import type { ApprovedProductVariant, SearchableContact, SearchableContactFilterInput } from 'api'
import { GetMarketplaceProductDocument, PreviewOrderDocument, Role, SendFlowStep } from 'api'
import { CenteredBox } from 'components/Common'
import { ZLink } from 'components/Common/ZComponents'
import { ExtHeader } from 'components/Extension/Main/ExtHeader'
import { useMultiSelect } from 'components/MultiSelectContacts/useMultiSelect'
import { PostalCustomize, PostalSendSidebar } from 'components/PostalSend/index'
import { PostalAddFunds } from 'components/PostalSend/PostalAddFunds'
import { PostalSendLoading } from 'components/PostalSend/PostalSendLoading'
import { NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { invalidFieldAnimationStyles } from 'components/PostalSend/usePostalSendFieldErrors'
import { AnalyticsEventV2, useAcl, useAnalyticsSend, useExtension, usePostalProductPermissions } from 'hooks'
import * as LogRocket from 'logrocket'
import type { PropsWithChildren } from 'react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  MdKeyboardArrowLeft,
  MdOutlineCalendarViewWeek,
  MdOutlineDelete,
  MdOutlineDrafts,
  MdOutlineEditCalendar,
  MdOutlineLocalShipping,
  MdOutlineMarkunreadMailbox,
  MdOutlineSaveAlt,
} from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAnalyticsEventV2 } from '../../hooks/useAnalytics'
import { StorageKeys } from '../../lib/storageKeys'
import { PostalCustomizeItem } from './PostalCustomizeItem'
import { PostalReview } from './PostalReview'
import { PostalSelectContactsV2 } from './PostalSelectContacts'
import {
  getDefaultDraftName,
  hasContactSelect,
  pagePathRegExp,
  pagePaths,
  SEND_METHOD_COLORS,
  usePostalSendGraphql,
} from './postalSendHelpers'
import { usePostalSendServices } from './postalSendServices'
import { usePostalChangeDetection } from './usePostalChangeDetection'
import type { PostalSendContext } from './usePostalSend'
import { pageTitles, PostalSendMethod, PostalSendType, SKIP_CONFIRM_TYPES, usePostalSend } from './usePostalSend'

// Show only Email & Direct options for these
const NON_LINK_SEND_TYPES = [
  PostalSendType.Retry,
  PostalSendType.Campaign,
  PostalSendType.Contact,
  PostalSendType.Trigger,
  PostalSendType.PlaybookStep,
]
const NON_BULK_SEND_TYPES = [PostalSendType.Campaign, PostalSendType.Trigger, PostalSendType.PlaybookStep]

// Split send methods into groups to allow animating when changing method
const SEND_METHOD_GROUP_1 = [PostalSendMethod.Link, PostalSendMethod.Direct]
const SEND_METHOD_GROUP_2 = Object.values(PostalSendMethod).filter((method) => !SEND_METHOD_GROUP_1.includes(method))

enum direction {
  'left' = 'left',
  'right' = 'right',
}

const getOtherDirection = (dir: direction) => (dir === direction.left ? direction.right : direction.left)

const getSendMethodLabel = (
  method: PostalSendMethod,
  isEvent: boolean,
  isExtension: boolean = false
): string | undefined => {
  if (!isEvent) {
    switch (method) {
      case PostalSendMethod.Link:
        return 'MagicLink'
      case PostalSendMethod.Direct:
        return 'Direct'
      case PostalSendMethod.Email:
        return isExtension ? 'Email' : 'Personalized Email'
      case PostalSendMethod.BulkSend:
        return 'Bulk Send'
    }
  } else {
    switch (method) {
      case PostalSendMethod.Link:
        return 'MagicLink'
      case PostalSendMethod.Email:
        return 'Send Invitations'
      case PostalSendMethod.Direct:
        return 'Add Invitees'
    }
  }
}

const getMethodIcon = (method: PostalSendMethod, isEvent: boolean) => {
  if (!isEvent) {
    switch (method) {
      case PostalSendMethod.Link:
        return <AttachmentIcon />
      case PostalSendMethod.Email:
        return <MdOutlineDrafts size="22px" />
      case PostalSendMethod.Direct:
        return <MdOutlineMarkunreadMailbox size="22px" />
      case PostalSendMethod.BulkSend:
        return <MdOutlineLocalShipping size="24px" />
    }
  } else {
    switch (method) {
      case PostalSendMethod.Email:
        return <MdOutlineEditCalendar size="22px" />
      case PostalSendMethod.Direct:
        return <MdOutlineCalendarViewWeek size="22px" />
    }
  }
}
interface SendTabSelectorProps {
  isActive: boolean
  isDisabled: boolean
  onClick: () => void
  method: PostalSendMethod
  disabledToolTip: String
}
const SendTabSelector: React.FC<PropsWithChildren<SendTabSelectorProps>> = ({
  isActive,
  isDisabled,
  onClick,
  method,
  disabledToolTip,
  children,
}) => {
  const button = (
    <UiButton
      variant="naked"
      fontSize="md"
      px={{ base: 0, md: 1 }}
      mr={{ base: '25px', xl: '50px' }}
      height="55px"
      fontWeight={600}
      color={{
        base: isActive ? SEND_METHOD_COLORS[method] : 'atomicGray.400',
        md: isActive ? SEND_METHOD_COLORS[method] : 'atomicGray.800',
      }}
      _hover={{ color: SEND_METHOD_COLORS[method] }}
      onClick={onClick}
      borderRadius={0}
      transition="0.2s border-width, 0.2s color"
      borderBottomWidth={{ base: 0, md: isActive ? '3px' : '0px' }}
      borderColor={SEND_METHOD_COLORS[method]}
      isDisabled={isDisabled}
    >
      {children}
    </UiButton>
  )
  if (isDisabled) {
    return (
      <UiTooltip
        label={disabledToolTip}
        hasArrow
        openDelay={750}
      >
        {button}
      </UiTooltip>
    )
  }
  return button
}

export interface PostalSendProps extends PostalSendContext {
  onComplete?: (context?: any) => void
  onNavigateBack: () => void
  navigateBackLabel?: string
  onExit?: () => void
  onExitLabel?: string
  stepToRecall?: SendFlowStep | null
}

export const PostalSend: React.FC<PostalSendProps> = (props) => {
  const {
    type,
    draft,
    contacts,
    postal,
    link,
    variant,
    giftMessage,
    physicalMessage,
    usePhysicalMessage,
    useSameMessage,
    name,
    date,
    deliveryEmail,
    onComplete,
    maxExecutions,
    enabled,
    emailSubjectLine,
    delay,
    sendAsContactOwner,
    sendAsUser,
    meetingRequestSetting,
    itemCustomizationInputs,
    formFieldList,
    landingPageHeaderText,
    landingPageBody,
    landingPageIncludeHeadshot,
    landingPageIncludeSenderName,
    spendAsTeamId,
    spendAsUserId,
    linkNeedsApproval,
    shippedEmailsOn,
    deliveredEmailsOn,
    method,
    restrictedMethods,
    quantity,
    shipToAddress,
    verifiedShipToAddress,
    newContact,
    onNavigateBack,
    navigateBackLabel,
    onExit,
    onExitLabel,
    furthestStep,
    stepToRecall,
  } = props

  const addFunds = useDisclosure()
  const confirmSendDisclosure = useDisclosure()
  const confirmDuplicateOrderDisclosure = useDisclosure()
  const draftDisclosure = useDisclosure()
  const exitDisclosure = useDisclosure()
  const confirmDeleteDraft = useDisclosure()
  const { loadAccount } = usePostalSendServices()
  const { canSendDirectly, canSendGiftEmail, canLink, canBulkSend, requiresRecipientCustomization } =
    usePostalProductPermissions(postal)
  const Alert = useAlerts()
  const sendAnalytics = useAnalyticsSend()
  const cancelRef = useRef(null)

  const [disableConfirmSend, setDisableConfirmSend] = useLocalStorage(StorageKeys.MarketplaceDisableConfirm, false)
  const handleClickDisableConfirmSend = () => setDisableConfirmSend(!disableConfirmSend)

  const { hasRole } = useAcl()

  // NEW HOOK HOTNESS
  const { state, send } = usePostalSend({
    initialContext: {
      type,
      draft,
      method,
      restrictedMethods,
      link,
      contacts,
      quantity,
      shipToAddress,
      verifiedShipToAddress,
      newContact,
      postal,
      variant,
      giftMessage,
      physicalMessage,
      useSameMessage,
      usePhysicalMessage,
      name,
      date,
      deliveryEmail,
      maxExecutions,
      enabled,
      emailSubjectLine,
      delay,
      sendAsContactOwner,
      sendAsUser,
      meetingRequestSetting,
      itemCustomizationInputs,
      formFieldList,
      landingPageHeaderText,
      landingPageBody,
      landingPageIncludeHeadshot,
      landingPageIncludeSenderName,
      spendAsTeamId,
      spendAsUserId,
      linkNeedsApproval,
      shippedEmailsOn,
      deliveredEmailsOn,
      furthestStep,
    },
    stepToRecall,
    loadAccount,
  })

  const { isExtension } = useExtension()

  const { isOpen: goToOrdersPageAfterDraft, onToggle: handleGoToOrdersPageAfterDraft } = useLocalStorageToggle(
    StorageKeys.ToOrdersPage,
    false
  )

  const location = useLocation()
  const navigate = useNavigate()

  const navigateToOrdersPage = () => navigate('/orders')

  const { sendPostalOrder, sendCampaignOrder, handleLinkComplete, handleSaveDraft, handleDeleteDraft, isLoading } =
    usePostalSendGraphql({
      onComplete,
      onSaveDraft: goToOrdersPageAfterDraft ? navigateToOrdersPage : onExit ? onExit : onNavigateBack,
    })

  const isMethodDisabled = (method: PostalSendMethod) => {
    return method === PostalSendMethod.Direct && requiresRecipientCustomization
  }

  const getDisabledTooltip = (method: PostalSendMethod) => {
    if (method === PostalSendMethod.Direct && requiresRecipientCustomization) {
      return 'This item requires recipient customization'
    }
    return ''
  }

  useEffect(() => {
    // throw the current step into the URL bar for pendo. we may allow the user to navigate back/forth at a later stage
    if (state.isLoading) return
    const newRoute = location.pathname.replace(pagePathRegExp, `/${pagePaths[state.value]}`)
    navigate(newRoute, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value, state.isLoading])

  const hasContacts = hasContactSelect(state.context)

  const multiSelectState = useMultiSelect<SearchableContact, SearchableContactFilterInput>({
    initialItems: state.context?.contacts?.items,
    initialFilters: state.context?.contacts?.filters,
    onUpdate: (data) => setTimeout(() => send({ type: 'SET_CONTACTS', data })),
  })

  const previewOrderVariables = useMemo(() => {
    return {
      approvedPostalId: state.context?.postal?.id,
      approvedPostalVariantId: state.context?.variant?.id as string,
      count: hasContacts ? undefined : state.context?.method === PostalSendMethod.BulkSend ? state.context.quantity : 1,
      deliveryEmail: state.context?.deliveryEmail,
      orfilters: hasContacts ? state.context?.contacts?.orfilters : undefined,
      ...(state.context?.spendAsUserId
        ? {
            spendAs: {
              teamId: state.context.spendAsTeamId,
              userId: state.context.spendAsUserId,
            },
          }
        : {}),
    }
  }, [
    state.context?.postal?.id,
    state.context?.variant?.id,
    state.context?.method,
    state.context?.quantity,
    state.context?.deliveryEmail,
    state.context?.contacts?.orfilters,
    state.context.spendAsUserId,
    state.context.spendAsTeamId,
    hasContacts,
  ])

  const canPreviewOrder = hasRole(Role.User) || hasRole(Role.Manager)

  const previewOrderQuery = useGraphqlQuery(PreviewOrderDocument, previewOrderVariables, {
    enabled: canPreviewOrder && state.step === SendFlowStep.OrderPreview,
    cacheTime: 0,
  })

  // TODO: Remove this after 1 billion dollar bug is squashed
  useEffect(() => {
    if (!hasContacts || !canPreviewOrder || state.step !== SendFlowStep.OrderPreview || previewOrderVariables.count) {
      return
    }
    if (!previewOrderVariables.orfilters?.length) {
      LogRocket.captureMessage('OrderPreviewWithoutContactFilter', {
        extra: { context: JSON.stringify(state.context) },
      })
    }
  }, [
    canPreviewOrder,
    hasContacts,
    previewOrderVariables.count,
    previewOrderVariables.orfilters?.length,
    state.context,
    state.step,
  ])

  const previewOrder = previewOrderQuery?.data?.previewOrder
  const { duplicateOrder, fundsPassed: hasFunds } = previewOrder ?? {}
  const previewOrderError = previewOrderQuery.error?.extensions?.postalErrorMessage || previewOrderQuery?.error?.message

  const skipConfirmation = useMemo(() => {
    // do not confirm for triggers or playbook steps
    if (SKIP_CONFIRM_TYPES.includes(state.context.type)) return true
    // 'soft' confim skip - do not confirm if user or BE has indicated to skip and other checks have passed
    if (hasFunds && !duplicateOrder && (disableConfirmSend || previewOrder?.skipConfirmation)) return true
    return false
  }, [disableConfirmSend, duplicateOrder, hasFunds, previewOrder?.skipConfirmation, state.context.type])

  const handleOrder = () => {
    return isExtension && state.context.method !== PostalSendMethod.Link
      ? sendPostalOrder(state.context)
      : state.context.method === PostalSendMethod.Link
      ? handleLinkComplete(state.context)
      : state.context.isCampaign
      ? sendCampaignOrder(state.context)
      : SKIP_CONFIRM_TYPES.includes(state.context.type) && onComplete
      ? onComplete(state.context)
      : sendPostalOrder(state.context)
  }

  const onConfirm = () => {
    try {
      handleOrder()
      confirmSendDisclosure.onClose()
      confirmDuplicateOrderDisclosure.onClose()
    } catch (err) {
      Alert.error('Error sending item ', err)
    }
  }

  const handleConfirm = () => {
    if (isExtension) {
      const event =
        state.context.method === PostalSendMethod.Link
          ? AnalyticsEventV2.ExtensionLinkCreateButton
          : AnalyticsEventV2.ExtensionSendFlowSendClicked
      sendAnalytics({ event })
    }

    if (skipConfirmation) {
      onConfirm()
    } else if (!hasFunds) {
      addFunds.onOpen()
    } else if (duplicateOrder) {
      confirmDuplicateOrderDisclosure.onOpen()
    } else {
      confirmSendDisclosure.onOpen()
    }
  }

  const handleAddFundsConfirm = () => {
    addFunds.onClose()
    if (duplicateOrder) confirmDuplicateOrderDisclosure.onOpen()
    else if (skipConfirmation) onConfirm()
    else confirmSendDisclosure.onOpen()
  }

  const hasUnsavedChanges = usePostalChangeDetection(state.context)

  const [draftName, setDraftName] = useState<string>('')
  const draftNameInputRef = useRef<HTMLInputElement>(null)
  const onSaveDraft = () => {
    const draftName = state.context.draft?.savedSendName ?? getDefaultDraftName(state.context)
    sendAnalytics({ event: AnalyticsEventV2.SendFlow_SaveDraft_Clicked })
    setDraftName(draftName)
    draftDisclosure.onOpen()
    setTimeout(() => draftNameInputRef.current?.select())
  }

  const { orderBlocked, hardBlock, emailIntegrationSuccess: _ } = previewOrder || {}
  const preventOrder = !!(orderBlocked && !state.context.deliveryEmail && hasContacts) || !!hardBlock

  const sendConfirmButtonText = useMemo(() => {
    if (duplicateOrder) return 'Send Again'
    if (state.context.type === PostalSendType.Trigger) return 'Update Trigger'
    if (state.context.method === PostalSendMethod.Link) return `${state?.context?.link ? 'Update' : 'Create'} MagicLink`
    return 'Send'
  }, [duplicateOrder, state.context?.link, state.context.method, state.context.type])

  // for parent/product info
  const productQuery = useGraphqlQuery(
    GetMarketplaceProductDocument,
    { id: state.context.postal?.marketplaceProductId },
    { enabled: !!state.context.postal?.marketplaceProductId }
  )
  const product = useMemo(() => productQuery.data?.getMarketplaceProduct, [productQuery.data?.getMarketplaceProduct])

  const handleVariantSelect = (variant: ApprovedProductVariant) => {
    send({ type: 'SET_VARIANT', data: variant })
    const parentVariant = product?.variants?.find((v) => v.id === variant.id)
    if (parentVariant) {
      send({ type: 'SET_PARENT_VARIANT', data: parentVariant })
    } else {
      console.error('No Parent Variant found for variantId', variant?.id)
    }
  }

  // initialize parent variant
  useEffect(() => {
    if (state.context.variant && !state.context.parentVariant) {
      const parentVariant = product?.variants?.find((v) => v.id === state.context?.variant?.id)
      send({ type: 'SET_PARENT_VARIANT', data: parentVariant })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.context.variant, state.context.parentVariant, product])

  const isEvent = useMemo(() => postal?.category === 'Events', [postal?.category])

  const sendMethodTabs = useMemo(() => {
    const tabList = []
    if (canLink && !NON_LINK_SEND_TYPES.includes(type)) tabList.push(PostalSendMethod.Link)
    if (canSendGiftEmail) tabList.push(PostalSendMethod.Email)
    if (canSendDirectly || requiresRecipientCustomization) tabList.push(PostalSendMethod.Direct)
    if (canBulkSend && !NON_BULK_SEND_TYPES.includes(type)) tabList.push(PostalSendMethod.BulkSend)
    if (restrictedMethods) return tabList.filter((t) => restrictedMethods.includes(t))
    return tabList
  }, [canLink, type, canSendGiftEmail, canSendDirectly, canBulkSend, restrictedMethods, requiresRecipientCustomization])

  const showSendMethodTabs = useMemo(
    // do not show sendMethodTabs if we are editing a link as there are no other options
    () => {
      if (!isExtension) {
        return state.stepIndex === 0 && !state.context.link
      } else {
        return state.step !== SendFlowStep.OrderPreview && sendMethodTabs.length > 1
      }
    },
    [isExtension, sendMethodTabs.length, state.context.link, state.step, state.stepIndex]
  )

  const [slideDirection, setSlideDirection] = useState<direction>(direction.right)

  const handleSendFlowChange = (newMethod: PostalSendMethod) => {
    if (!state?.context?.method) return
    setSlideDirection(
      sendMethodTabs.indexOf(state?.context?.method) > sendMethodTabs.indexOf(newMethod)
        ? direction.left
        : direction.right
    )
    send({ type: 'SET_SEND_METHOD', data: newMethod })

    const event = getAnalyticsEventV2(newMethod)
    if (event && !isExtension) sendAnalytics({ event })
  }

  const handleExit = () => (hasUnsavedChanges ? exitDisclosure.onOpen() : onExit ? onExit() : onNavigateBack())

  const handleNext = () => {
    if (isExtension) sendAnalytics({ event: AnalyticsEventV2.ExtensionSendFlowNextClicked })
    setSlideDirection(direction.right)
    send('NEXT')
  }
  const handleBack = () => {
    if (isExtension) sendAnalytics({ event: AnalyticsEventV2.ExtensionSendFlowBackClicked })
    setSlideDirection(direction.left)
    send('BACK')
  }

  // if cond is true, it's transitioning in. if cond is false it will be transitioning out
  const getSlideDirection = (cond: boolean) => (cond ? slideDirection : getOtherDirection(slideDirection))

  const handleNextStep = () => {
    // Analytics
    const event = getAnalyticsEventV2(state.step)
    if (event && !isExtension) sendAnalytics({ event })

    // Update State
    state.step === SendFlowStep.OrderPreview ? handleConfirm() : handleNext()
  }

  const { hasPermission } = useAcl()
  const canSend = hasPermission('postals.send')

  if (
    state.context.type &&
    state.context.type !== PostalSendType.PlaybookStep &&
    state.context.type !== PostalSendType.Trigger &&
    !canSend
  ) {
    const error = new GraphqlError()
    error.message = 'User Role required for Sending'
    error.status = 404
    throw error
  }

  if (state.isLoading) {
    return (
      <PostalSendLoading
        onNavigateBack={onNavigateBack}
        backLabel=""
      />
    )
  }

  return (
    <>
      {/* field highilighting animation */}
      <style>{invalidFieldAnimationStyles}</style>
      {!isExtension ? (
        <SecondaryNavbar
          left={
            <NavbarBackButton
              onClick={() => (state?.prevStep ? handleBack() : onNavigateBack())}
              label={state?.prevStep ? `Back to ${state?.prevStepTitle}` : navigateBackLabel}
            />
          }
          header={state.isLoading ? 'Loading...' : pageTitles[state?.step]}
          right={
            <>
              {!state.context.link && (
                <ZLink
                  fontWeight="bold"
                  color="white"
                  onClick={onSaveDraft}
                >
                  <MdOutlineSaveAlt
                    size="20px"
                    style={{ marginRight: '8px' }}
                  />
                  {draft ? 'Update Draft' : 'Save Draft'}
                </ZLink>
              )}
              {draft && (
                <UiEnhancedTooltip
                  label="Delete Draft"
                  hasArrow
                  mt={2}
                >
                  <UiIconButton
                    aria-label={'delete-draft'}
                    color="white"
                    bg="transparent"
                    borderRadius="50%"
                    _hover={{ color: 'white', bg: 'transparent', opacity: 0.8 }}
                    minW="unset"
                    h={8}
                    onClick={confirmDeleteDraft.onOpen}
                  >
                    <MdOutlineDelete size="22px" />
                  </UiIconButton>
                </UiEnhancedTooltip>
              )}
            </>
          }
          onExit={handleExit}
          onExitLabel={onExitLabel}
          mb={8}
        />
      ) : (
        <ExtHeader
          onSaveDraft={onSaveDraft}
          hasDraft={!!state.context.draft}
          p={4}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="30px"
            height="30px"
            bg="atomicGray.50"
            borderRadius="50%"
            _hover={{ bg: 'atomicGray.100', cursor: 'pointer' }}
            onClick={() => (state?.prevStep ? handleBack() : onNavigateBack())}
          >
            <MdKeyboardArrowLeft
              size="16px"
              color="#8492A6"
            />
          </Box>
        </ExtHeader>
      )}
      <CenteredBox
        px={{ base: 0, md: 8 }}
        isLoaded={!state.isLoading}
      >
        <Grid
          w="100%"
          templateColumns={{
            base: '1fr',
            lg: 'calc(100% - 450px) 400px',
            xl: 'calc(100% - 470px) calc(400px)',
          }}
          gridGap={{ base: '50px', xl: '70px' }}
        >
          <Box>
            <SlideFade
              offsetY={-8}
              reverse
              unmountOnExit
              style={{ position: 'relative' }}
              in={showSendMethodTabs}
            >
              <Box
                borderBottom={{ base: 0, md: '2px solid #E0E6ED' }}
                mt={-4}
                px={{ base: 4, md: 0 }}
                mb={{ base: 0, md: 8 }}
              >
                {sendMethodTabs.map((method) => (
                  <SendTabSelector
                    key={method}
                    method={method}
                    isActive={state.context?.method === method}
                    isDisabled={isMethodDisabled(method)}
                    onClick={() => handleSendFlowChange(method)}
                    disabledToolTip={getDisabledTooltip(method)}
                  >
                    {!isExtension && getMethodIcon(method, isEvent)}
                    <ZText
                      as="span"
                      ml={{ base: 0, md: 2 }}
                      fontSize="md"
                      fontWeight="bold"
                      color="inherit"
                    >
                      {getSendMethodLabel(method, isEvent, isExtension)}
                    </ZText>
                  </SendTabSelector>
                ))}
              </Box>
            </SlideFade>
            <Box overflow="hidden">
              <Slide
                direction={getSlideDirection(
                  state.step === SendFlowStep.ContactSelection && SEND_METHOD_GROUP_1.includes(state.context.method!)
                )}
                in={state.step === SendFlowStep.ContactSelection && SEND_METHOD_GROUP_1.includes(state.context.method!)}
                style={{ position: 'relative' }}
                unmountOnExit
              >
                <PostalSelectContactsV2
                  state={multiSelectState}
                  context={state?.context}
                  mb={5}
                />
              </Slide>

              <Slide
                direction={getSlideDirection(
                  state.step === SendFlowStep.ContactSelection && SEND_METHOD_GROUP_2.includes(state.context.method!)
                )}
                in={state.step === SendFlowStep.ContactSelection && SEND_METHOD_GROUP_2.includes(state.context.method!)}
                style={{ position: 'relative' }}
                unmountOnExit
              >
                <PostalSelectContactsV2
                  state={multiSelectState}
                  context={state?.context}
                  mb={5}
                />
              </Slide>
              <Slide
                direction={getSlideDirection(
                  state.step === SendFlowStep.SendCustomization && SEND_METHOD_GROUP_1.includes(state.context.method!)
                )}
                in={
                  state.step === SendFlowStep.SendCustomization && SEND_METHOD_GROUP_1.includes(state.context.method!)
                }
                style={{ position: 'relative' }}
                unmountOnExit
              >
                <PostalCustomize
                  context={state.context}
                  send={send}
                />
              </Slide>
              <Slide
                direction={getSlideDirection(
                  state.step === SendFlowStep.SendCustomization && SEND_METHOD_GROUP_2.includes(state.context.method!)
                )}
                in={
                  state.step === SendFlowStep.SendCustomization && SEND_METHOD_GROUP_2.includes(state.context.method!)
                }
                style={{ position: 'relative' }}
                unmountOnExit
              >
                <PostalCustomize
                  context={state.context}
                  send={send}
                />
              </Slide>

              <Slide
                direction={getSlideDirection(state.step === SendFlowStep.ItemCustomization)}
                in={state.step === SendFlowStep.ItemCustomization}
                style={{ position: 'relative' }}
                unmountOnExit
              >
                <PostalCustomizeItem
                  context={state.context}
                  send={send}
                />
              </Slide>

              <Slide
                direction={getSlideDirection(state.step === SendFlowStep.OrderPreview)}
                in={state.step === SendFlowStep.OrderPreview}
                style={{ position: 'relative' }}
                unmountOnExit
              >
                <PostalReview
                  context={state.context}
                  previewOrder={previewOrder}
                  preventOrder={preventOrder}
                  error={previewOrderError}
                />
              </Slide>
            </Box>
          </Box>
          <PostalSendSidebar
            postal={state.context.postal}
            sending={true}
            state={state}
            send={send}
            context={state.context}
            previewOrder={previewOrder}
            onNext={handleNextStep}
            nextStepButtonText={state.nextStepTitle}
            hasFunds={hasFunds}
            setVariant={handleVariantSelect}
            selectedVariant={state.context.variant}
            multiSelectState={multiSelectState}
            handleSaveDraft={onSaveDraft}
            errorMessage={state.context.errorMessage}
            onDisabledNextClick={() => send({ type: 'HIGHLIGHT_FIELD_WITH_ERROR' })}
            isLoading={isLoading || previewOrderQuery.isLoading}
          />
          {addFunds.isOpen && (
            <PostalAddFunds
              orderInfo={previewOrder}
              isOpen={addFunds.isOpen}
              onComplete={handleAddFundsConfirm}
              onClose={addFunds.onClose}
              loading={previewOrderQuery.isLoading}
            />
          )}

          <AlertDialog
            size={{ base: 'full', md: 'lg' }}
            leastDestructiveRef={cancelRef}
            isCentered
            {...confirmSendDisclosure}
          >
            <AlertDialogOverlay />
            <AlertDialogContent>
              <AlertDialogHeader>Confirm Send</AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>
                {state?.context?.method === PostalSendMethod.Link ? (
                  <Text>
                    By clicking {sendConfirmButtonText}, I acknowledge that I have reviewed all of the information and I
                    am ready to {state?.context?.link ? 'update' : 'create'} this MagicLink.
                  </Text>
                ) : (
                  <Text>
                    By clicking {sendConfirmButtonText}, I acknowledge that I have reviewed all of the information and I
                    am ready to send.
                  </Text>
                )}
                <FormControl
                  id="disableConfirmSend"
                  mt={8}
                >
                  <HStack>
                    <Checkbox
                      size="lg"
                      colorScheme="atomicBlue"
                      name="disableConfirmSend"
                      isChecked={disableConfirmSend}
                      onChange={handleClickDisableConfirmSend}
                    />
                    <FormLabel mb={0}>Don't ask me again</FormLabel>
                  </HStack>
                </FormControl>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  minW={32}
                  onClick={onConfirm}
                >
                  {sendConfirmButtonText}
                </Button>
                <Button
                  ref={cancelRef}
                  minW={32}
                  variant="ghost"
                  colorScheme="atomicGray"
                  onClick={confirmSendDisclosure.onClose}
                >
                  Cancel
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>

          <AlertDialog
            size={{ base: 'full', md: 'lg' }}
            leastDestructiveRef={cancelRef}
            isCentered
            {...confirmDuplicateOrderDisclosure}
          >
            <AlertDialogOverlay />
            <AlertDialogContent>
              <AlertDialogHeader>Confirm Duplicate</AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>
                <Text>
                  Looks like you’ve sent this item to this contact recently. Are you sure you’d like to send it again?
                </Text>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  minW={32}
                  onClick={onConfirm}
                >
                  {duplicateOrder
                    ? 'Send Again'
                    : !hasContacts
                    ? `${state?.context?.link ? 'Update' : 'Create'} MagicLink`
                    : 'Send'}
                </Button>
                <Button
                  ref={cancelRef}
                  minW={32}
                  variant="ghost"
                  colorScheme="atomicGray"
                  onClick={confirmDuplicateOrderDisclosure.onClose}
                >
                  Cancel
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>

          <AlertDialog
            size={{ base: 'full', md: 'lg' }}
            leastDestructiveRef={cancelRef}
            isCentered
            {...exitDisclosure}
          >
            <AlertDialogOverlay />
            <AlertDialogContent>
              <AlertDialogHeader>Confirm Exit</AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>
                <Text>This page contains unsaved changes. Save a draft or exit without saving to continue.</Text>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  minW={32}
                  onClick={() => {
                    exitDisclosure.onClose()
                    handleSaveDraft(state.context, state.context.draft?.savedSendName)
                  }}
                >
                  Quick Save Draft
                </Button>
                <Button
                  ref={cancelRef}
                  minW={32}
                  variant="ghost"
                  colorScheme="atomicGray"
                  onClick={onNavigateBack}
                >
                  Exit without Saving
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>

          <AlertDialog
            size={{ base: 'full', md: 'lg' }}
            leastDestructiveRef={cancelRef}
            isCentered
            {...draftDisclosure}
          >
            <AlertDialogOverlay />
            <AlertDialogContent>
              <AlertDialogHeader>{state.context.draft ? 'Update Draft' : 'Save Draft'}</AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>
                <FormControl>
                  <FormLabel>Draft Name</FormLabel>
                  <ZInput
                    placeholder="Enter a name"
                    ref={draftNameInputRef}
                    value={draftName}
                    onChange={({ target: { value } }: any) => setDraftName(value)}
                    onKeyDown={({ key }: any) => key === 'Enter' && handleSaveDraft(state.context, draftName)}
                  />
                  <FormHelperText>Please enter a name to save your draft.</FormHelperText>
                </FormControl>
                <HStack mt={4}>
                  <ZCheckbox
                    defaultChecked={goToOrdersPageAfterDraft}
                    onChange={handleGoToOrdersPageAfterDraft}
                    display={{ base: 'none', md: 'revert' }}
                  />
                  <FormLabel>Go to orders page</FormLabel>
                </HStack>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  minW={32}
                  onClick={() => handleSaveDraft(state.context, draftName)}
                >
                  {state.context.draft ? 'Update and Exit' : 'Save and Exit'}
                </Button>
                <Button
                  ref={cancelRef}
                  minW={32}
                  variant="ghost"
                  colorScheme="atomicGray"
                  onClick={draftDisclosure.onClose}
                >
                  Cancel
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>

          <ZConfirm
            {...confirmDeleteDraft}
            title="Delete Draft"
            onConfirm={() => handleDeleteDraft(state.context)}
            buttonText="Delete"
          >
            <ZText fontSize="md">Are you sure you would like to delete this draft? This cannot be undone.</ZText>
          </ZConfirm>
        </Grid>
      </CenteredBox>
    </>
  )
}
