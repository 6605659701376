import type { GridProps } from '@chakra-ui/react'
import { IconButton } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import type { Column, GraphqlFilterState } from '@postal-io/postal-ui'
import {
  internalTableProps,
  UiDateTime,
  UiMoney,
  UiSSDataTable,
  useAlertError,
  useGraphqlFilter,
  ZCard,
  ZCardBody,
  ZText,
} from '@postal-io/postal-ui'
import type { AddFundsIntent, BillingAccount, SearchAddFundsIntentsQueryVariables } from 'api'
import { AddFundsStatus, AddFundsType, SearchAddFundsIntentsDocument } from 'api'
import { ZStatusTag } from 'components/Common/ZComponents'
import { useDownloadPdf } from 'hooks/useDownloadPdf'
import { STATUS_TYPE_MAP } from 'lib'
import React, { useEffect, useMemo } from 'react'
import { MdOutlinePrint } from 'react-icons/md'

interface BillingAccountReceiptsProps extends GridProps {
  billingAccount: BillingAccount
  isLoading?: boolean
}

const DOWNLOADABLE_RECEIPT_STATUSES = [
  AddFundsStatus.AwaitingPayment,
  AddFundsStatus.Complete,
  AddFundsStatus.PaymentSuccess,
  AddFundsStatus.Canceled,
]

const ADD_FUNDS_INTENTS_INITIAL_STATE = {
  filter: {},
  orderBy: { key: 'created', direction: 'desc' },
} as GraphqlFilterState

const ADD_FUNDS_INTENT_STATUS_MAP = {
  [AddFundsStatus.Created]: {
    label: 'Processing',
    ...STATUS_TYPE_MAP.INFO,
  },
  [AddFundsStatus.Processing]: {
    label: 'Processing',
    ...STATUS_TYPE_MAP.INFO,
  },
  [AddFundsStatus.AwaitingPayment]: {
    label: 'Processing',
    ...STATUS_TYPE_MAP.INFO,
  },
  [AddFundsStatus.PaymentError]: {
    label: 'Error',
    ...STATUS_TYPE_MAP.ERROR,
  },
  [AddFundsStatus.PaymentSuccess]: {
    label: 'Processing',
    ...STATUS_TYPE_MAP.INFO,
  },
  [AddFundsStatus.BalanceError]: {
    label: 'Error',
    ...STATUS_TYPE_MAP.ERROR,
  },
  [AddFundsStatus.BalanceSuccess]: {
    label: 'Processing',
    ...STATUS_TYPE_MAP.INFO,
  },
  [AddFundsStatus.Complete]: {
    label: 'Success',
    ...STATUS_TYPE_MAP.SUCCESS,
  },
  [AddFundsStatus.Canceled]: {
    label: 'Canceled',
    ...STATUS_TYPE_MAP.DISABLED,
  },
}

const ADD_FUNDS_INTENTS_TYPE_MAP = {
  [AddFundsType.Prepayment]: {
    label: 'Manual - Credit Card',
  },
  [AddFundsType.AutoReloadPreDrawdown]: {
    label: 'Auto Reload - Credit Card',
  },
  [AddFundsType.AutoReloadPostDrawdown]: {
    label: 'Auto Reload - Credit Card',
  },
  [AddFundsType.BrandCreditCard]: {
    label: 'Brand Payment',
  },
  [AddFundsType.PayByInvoice]: {
    label: 'Manual - Invoice',
  },
}

export const BillingAccountReceipts: React.FC<BillingAccountReceiptsProps> = ({ billingAccount, isLoading }) => {
  const staticFilterVariables = useMemo(
    () => ({ filter: { billingAccountId: { eq: billingAccount.id } }, limit: 100 }),
    [billingAccount]
  )

  const graphqlFilter = useGraphqlFilter<SearchAddFundsIntentsQueryVariables>({
    initialState: ADD_FUNDS_INTENTS_INITIAL_STATE,
    staticVariables: staticFilterVariables,
    isManual: true,
  })

  useEffect(() => {
    graphqlFilter.transform()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphqlFilter.orderBy])

  const searchAddFundsIntents = useGraphqlInfiniteQuery(SearchAddFundsIntentsDocument, graphqlFilter.variables)
  useAlertError(searchAddFundsIntents.error)
  const addFundsIntents = searchAddFundsIntents.mergedData?.searchAddFundsIntents ?? []

  const { createDownload } = useDownloadPdf()

  const BILLING_RECEIPTS_COLUMNS: Column[] = [
    {
      key: 'date',
      label: 'Date',
      orderBy: 'created',
      render: ({ created }: AddFundsIntent) => {
        return (
          <UiDateTime
            date={created?.dateTime}
            fallback=""
          />
        )
      },
    },
    {
      key: 'amount.charged',
      label: 'Amount Charged',
      render: ({ grossPaymentAmount, currency }: AddFundsIntent) => {
        return (
          <UiMoney
            cents={grossPaymentAmount}
            currency={currency}
          />
        )
      },
    },
    {
      key: 'type',
      label: 'Type',
      render: ({ type }: AddFundsIntent) => {
        return <ZText>{ADD_FUNDS_INTENTS_TYPE_MAP[type].label}</ZText>
      },
    },
    {
      key: 'status',
      label: 'Status',
      render: ({ status }: AddFundsIntent) => {
        const statusTag = ADD_FUNDS_INTENT_STATUS_MAP[status]
        return (
          <ZStatusTag
            minW="100px"
            px={4}
            {...statusTag}
          />
        )
      },
    },
    {
      key: 'action',
      label: 'Action',
      render: ({ id, status }: AddFundsIntent) => {
        return (
          <IconButton
            aria-label="download receipt"
            variant="link"
            color="atomicBlue.400"
            _hover={{ color: 'atomicBlue.600' }}
            onClick={() => {
              createDownload(id, billingAccount.id)
            }}
            pl={4}
            icon={<MdOutlinePrint size="22px" />}
            isDisabled={!DOWNLOADABLE_RECEIPT_STATUSES.includes(status)}
          />
        )
      },
    },
  ]

  return (
    <ZCard
      variant="form"
      mt={4}
    >
      <ZCardBody p={8}>
        <UiSSDataTable
          isLoading={isLoading}
          columns={BILLING_RECEIPTS_COLUMNS}
          rows={addFundsIntents}
          rowKey="id"
          fetchMore={searchAddFundsIntents.fetchNextPage}
          hasMore={searchAddFundsIntents.hasNextPage}
          filter={graphqlFilter.variables.filter}
          orderBy={graphqlFilter.orderBy}
          onOrderBy={graphqlFilter.setOrderBy}
          tableProps={internalTableProps}
          border="none"
          boxShadow="none"
        />
      </ZCardBody>
    </ZCard>
  )
}
