import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Image, Stack } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { LexendFontWeight, UiIconButton, UiIconPlaybook, UiTruncate, ZCard, ZLink, ZText } from '@postal-io/postal-ui'
import React from 'react'
import { MdOutlineEdit } from 'react-icons/md'
import { GetApprovedPostalDocument, GetPlaybookDefinitionDocument, TriggerAction } from '../../api'
import { SendAsLink, useAssets, useSendAs } from '../../hooks'

interface ActionCardV2Props extends Omit<BoxProps, 'onChange'> {
  title?: string
  description?: string
  assetUrl?: string
  isLoading?: boolean
  giftMessage?: string
  physicalMessage?: string
  emailSubjectLine?: string
  action: TriggerAction
  sendAsContactOwner?: boolean | null
  sendAsUser?: string | null
  onChange: (action: TriggerAction) => void
  onEdit?: (action: TriggerAction) => void
  onRemove?: () => void
}

// Selected action item for playbooks, postals
export const ActionCard: React.FC<ActionCardV2Props> = ({
  assetUrl,
  title,
  description,
  action,
  onChange,
  onEdit,
  onRemove,
  giftMessage,
  physicalMessage,
  isLoading,
  emailSubjectLine,
  sendAsContactOwner,
  sendAsUser,
  ...rest
}) => {
  const { assetUrlSrc } = useAssets()
  const { src, fallbackSrc } = assetUrlSrc(assetUrl, {
    w: 350,
    h: 350,
    fit: 'clip',
  })

  const { isExternal } = useSendAs({ sendAsContactOwner, sendAsUser })

  if (isLoading) return <ZCard isLoading />

  return (
    <Box
      border="1px"
      borderColor="gray.200"
      borderRadius={3}
      padding={5}
      {...rest}
    >
      <Flex alignItems="center">
        {src ? (
          <Image
            src={src}
            fallbackSrc={fallbackSrc}
            alt={title}
            borderRadius={3}
            objectFit="cover"
            w="69px"
            h="69px"
          />
        ) : (
          <UiIconPlaybook
            boxSize="150px"
            p={4}
          />
        )}
        <Flex
          flexDirection="column"
          justify="space-around"
          p={2}
        >
          <Flex direction="row">
            <UiTruncate
              as="p"
              fontFamily="Lexend"
              fontSize="sm"
              fontWeight={LexendFontWeight.Regular}
              length={45}
              showTooltip
              text={title}
            />
            {onEdit && (
              <UiIconButton
                variant="link"
                fontSize="sm"
                aria-label="Edit postal configuration"
                icon={<MdOutlineEdit />}
                color="atomicBlue.400"
                onClick={() => onEdit && onEdit(action)}
              />
            )}
          </Flex>
          <UiTruncate
            as="p"
            fontFamily="Lexend"
            fontSize="xs"
            fontWeight={LexendFontWeight.Regular}
            length={100}
            text={description}
          />
        </Flex>
      </Flex>
      {isExternal && (
        <ZText
          fontSize="sm"
          mt={4}
        >
          Send As:{' '}
          <SendAsLink
            sendAsContactOwner={sendAsContactOwner}
            sendAsUser={sendAsUser}
          />
        </ZText>
      )}

      {giftMessage && (
        <Stack
          mt={4}
          spacing={4}
        >
          <ZText fontSize="sm">Gift Message</ZText>
          {emailSubjectLine && <ZText mb={4}>{emailSubjectLine}</ZText>}
          <ZText
            fontSize="xs"
            whiteSpace="break-spaces"
          >
            {giftMessage}
          </ZText>
        </Stack>
      )}
      {physicalMessage && physicalMessage !== giftMessage && (
        <Stack
          mt={4}
          spacing={4}
        >
          <ZText fontSize="sm">Physical Message</ZText>
          <ZText
            fontSize="xs"
            whiteSpace="break-spaces"
          >
            {physicalMessage}
          </ZText>
        </Stack>
      )}
      <Flex
        justify="space-between"
        mt={4}
      >
        <ZLink
          color="atomicBlue.400"
          onClick={() => onChange(action)}
        >
          Choose Another {action === TriggerAction.SendPostal ? 'Item' : 'Subscription'}
        </ZLink>
        <ZLink
          color="atomicBlue.400"
          onClick={() => (onRemove ? onRemove() : onChange(TriggerAction.Nothing))}
        >
          Remove {action === TriggerAction.SendPostal ? 'Item' : 'Subscription'}
        </ZLink>
      </Flex>
    </Box>
  )
}
interface PostalActionCardV2Props extends Omit<BoxProps, 'onChange'> {
  approvedPostalId: string
  approvedPostalVariantId: string
  action: TriggerAction
  giftMessage?: string
  physicalMessage?: string
  emailSubjectLine?: string
  sendAsContactOwner?: boolean | null
  sendAsUser?: string | null
  onChange: (action: TriggerAction) => void
  onEdit: (action: TriggerAction) => void
  onRemove?: () => void
}
export const PostalActionCardV2: React.FC<PostalActionCardV2Props> = ({
  approvedPostalId,
  approvedPostalVariantId,
  onChange,
  onEdit,
  onRemove,
  action,
  giftMessage,
  physicalMessage,
  emailSubjectLine,
  sendAsContactOwner,
  sendAsUser,
  ...rest
}) => {
  // POSTAL
  const { data, isLoading } = useGraphqlQuery(GetApprovedPostalDocument, { id: approvedPostalId })
  const postal = data?.getApprovedPostal
  const variant = postal?.variants?.find((v) => v.id === approvedPostalVariantId)
  const assetUrl = postal?.imageUrls?.[0]?.url
  const hasVariantFlexibility = postal?.variantOrderFlexibility

  return (
    <ActionCard
      title={postal?.name}
      assetUrl={assetUrl}
      description={hasVariantFlexibility ? 'Recipient Choice' : variant?.variantName}
      action={action}
      onChange={onChange}
      onEdit={onEdit}
      onRemove={onRemove}
      isLoading={isLoading}
      giftMessage={giftMessage}
      physicalMessage={physicalMessage}
      emailSubjectLine={emailSubjectLine}
      sendAsContactOwner={sendAsContactOwner}
      sendAsUser={sendAsUser}
      {...rest}
    />
  )
}

interface PlaybookActionCardV2Props extends Omit<BoxProps, 'onChange'> {
  playbookDefinitionId: string
  action: TriggerAction
  onChange: (action: TriggerAction) => void
  onRemove?: () => void
}
export const PlaybookActionCardV2: React.FC<PlaybookActionCardV2Props> = ({
  playbookDefinitionId,
  action,
  onChange,
  onRemove,
  ...rest
}) => {
  const { data, isLoading } = useGraphqlQuery(GetPlaybookDefinitionDocument, {
    id: playbookDefinitionId,
  })
  const playbookDefinition = data?.getPlaybookDefinition
  return (
    <ActionCard
      title={playbookDefinition?.name || ''}
      isLoading={isLoading}
      action={action}
      onChange={onChange}
      onRemove={onRemove}
      {...rest}
    />
  )
}
