import { Box, Divider, Flex, HStack, StatNumber } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import {
  calculateCost,
  UiMoney,
  UiStat,
  UiStatDate,
  UiStatLabel,
  UiTagStack,
  ZCard,
  ZCardBody,
  ZHeading,
  ZLink,
  ZText,
} from '@postal-io/postal-ui'
import type { Address, BasicMetrics, Contact, PhoneMap } from 'api'
import { GetContactDocument } from 'api'
import { ZAlert } from 'components/Common/ZComponents'
import React from 'react'
import { hasVerifiedAddress } from '../Contacts/ContactsData'
import { AddressVerifiedBadge } from './AddressVerifiedBadge'
import { ContactAddressList } from './ContactAddressList'
import { SystemBadge } from './SystemBadge'

interface ContactInfoV2Props extends UiCardProps {
  contactId?: string
}

type PhoneType = PhoneMap | undefined

export const ContactInfo: React.FC<ContactInfoV2Props> = ({ contactId, ...rest }) => {
  const getContactQuery = useGraphqlQuery(GetContactDocument, { id: contactId as string }, { enabled: !!contactId })
  const contact = getContactQuery.data?.getContact as Contact
  const { firstName, lastName, emailAddress, companyName, title, addresses, phones, tags, ownerLink } = contact || {}

  const metrics = contact?.metrics as BasicMetrics
  const phone = (phones?.find((p: any) => p?.type === 'WORK') || phones?.[0]) as PhoneType
  const isVerified = hasVerifiedAddress(addresses as Address[])

  return (
    <ZCard
      variant="form"
      isLoading={getContactQuery.isLoading}
      minWidth="225px"
      p={0}
      pb={0}
      lineHeight={1.5}
      {...rest}
    >
      <ZCardBody pt={{ base: 8, md: 8 }}>
        <Flex justifyContent="space-between">
          <Box fontSize="body-md">
            <ZHeading
              data-private
              fontWeight="normal"
              as="h2"
              size="h4"
              mb={3}
            >
              {firstName || lastName ? `${firstName} ${lastName}` : 'No Name'}
            </ZHeading>
            {title && <ZText>{title}</ZText>}
            {companyName && <ZText>{companyName}</ZText>}
            {emailAddress && (
              <ZText
                data-private
                color="blue.500"
              >
                {emailAddress}
              </ZText>
            )}
            {phone?.phoneNumber && <ZText data-private>{phone.phoneNumber}</ZText>}
            {ownerLink?.fullName && (
              <ZText
                as="span"
                display="block"
                fontWeight="normal"
              >
                Owner: {ownerLink.fullName}
              </ZText>
            )}
            {
              <UiTagStack
                tags={tags}
                mt={0}
              />
            }
          </Box>
          <Box w="75px">
            <AddressVerifiedBadge isVerified={isVerified} />
            {!!contact?.mappings?.length && isVerified && <Divider my={3} />}
            {contact?.mappings?.map((mapping) => {
              return (
                <SystemBadge
                  key={mapping.system}
                  system={mapping.system}
                  mappings={contact.mappings}
                  mt={1}
                />
              )
            })}
          </Box>
        </Flex>
        <HStack
          my={10}
          spacing={5}
          justifyContent="flex-start"
          whiteSpace="nowrap"
          fontFamily="Lexend"
        >
          <UiStat flexGrow={0}>
            <UiStatLabel
              fontSize="body-md"
              fontWeight="normal"
              display="inline"
              textTransform="none"
              mr={2}
            >
              Date Added
            </UiStatLabel>
            <UiStatDate
              fontWeight="bold"
              fontSize="body-md"
              display="inline"
              data-testid={`dateAdded`}
              fallback=""
              date={contact?.created?.dateTime}
            ></UiStatDate>
          </UiStat>
          <UiStat flexGrow={0}>
            <UiStatLabel
              fontSize="body-md"
              fontWeight="normal"
              display="inline"
              textTransform="none"
              mr={2}
            >
              Items
            </UiStatLabel>
            <StatNumber
              fontWeight="bold"
              fontSize="body-md"
              display="inline"
              data-testid={`touches`}
            >
              {metrics?.touches || 0}
            </StatNumber>
          </UiStat>
          <UiStat flexGrow={0}>
            <UiStatLabel
              fontSize="body-md"
              fontWeight="normal"
              display="inline"
              textTransform="none"
              mr={2}
            >
              Total Spend
            </UiStatLabel>
            <StatNumber
              fontWeight="bold"
              fontSize="body-md"
              display="inline"
              data-testid={`cpt`}
            >
              <UiMoney amount={calculateCost(metrics)} />
            </StatNumber>
          </UiStat>
        </HStack>
        <ContactAddressList contact={contact} />
        <ZAlert
          status="info"
          alignItems="center"
          p={{ base: 2, md: 4 }}
          hideClose
        >
          <ZText fontSize="sm">
            Looking for an alternate address? Try{' '}
            <ZLink
              href="https://www.truepeoplesearch.com/"
              isExternal
            >
              True People Search
            </ZLink>{' '}
            or{' '}
            <ZLink
              href="https://www.beenverified.com/"
              isExternal
            >
              Been Verified
            </ZLink>
            .
          </ZText>
        </ZAlert>
      </ZCardBody>
    </ZCard>
  )
}
