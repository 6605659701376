import { Box, Grid, Heading, Image, Stack, Text } from '@chakra-ui/react'
import image from 'assets/images/app-error.svg'
import React from 'react'

interface ErrorPageProps {
  title: string
  message: string
}
const ErrorPage: React.FC<ErrorPageProps> = ({ title, message }) => {
  return (
    <Grid
      templateColumns="1fr 2fr"
      alignItems="center"
      justifyItems="center"
      h="100vh"
      maxW="90rem"
      marginX="auto"
      gap={8}
      p={8}
    >
      <Stack spacing={8}>
        <Heading
          as="h1"
          fontSize="5xl"
          lineHeight={1}
          color="primary.500"
          whiteSpace="nowrap"
        >
          {title}
        </Heading>
        <Text
          fontSize="lg"
          color="gray.600"
        >
          {message}
        </Text>
      </Stack>
      <Box>
        <Image src={image} />
      </Box>
    </Grid>
  )
}

export const StoreNotFound = () => {
  return (
    <ErrorPage
      title="NOT FOUND"
      message="Oh no. This store wasn't found, please check the URL and try again."
    />
  )
}

export const StorePending = () => {
  return (
    <ErrorPage
      title="COMING SOON"
      message="This store is coming soon!"
    />
  )
}
