import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react'
import { UiButton, UiCard } from '@postal-io/postal-ui'
import { useAcl } from 'hooks'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { eventImages, postalImages } from '../../assets/postals'

export const ApprovedPostalsEmpty: React.FC<{ isEvents?: boolean }> = ({ isEvents }) => {
  const navigate = useNavigate()
  const { hasPermission } = useAcl()
  const isAdmin = hasPermission('postals.create')

  return (
    <>
      <UiCard
        p={0}
        maxW="1600px"
        overflow="hidden"
        mx={'auto'}
      >
        <Grid
          gridTemplateColumns={{ base: '1fr', lg: '.5fr 1fr' }}
          alignContent="center"
          gap={8}
        >
          <Flex
            backgroundColor="header.dark"
            direction="column"
            justifyContent="center"
            alignItems="center"
            p={{ base: 4, lg: 0 }}
          >
            <Text
              fontSize="26px"
              width="75%"
              fontWeight={900}
              color="white"
              textAlign="center"
              mb={'30px'}
            >
              You currently have no {isEvents ? 'scheduled Events' : 'approved Items'}
            </Text>
            <>
              {isAdmin ? (
                <>
                  <Text
                    color="white"
                    width="65%"
                    fontSize="md"
                    mb={'38px'}
                    fontWeight={600}
                    textAlign="center"
                  >
                    Browse the Marketplace and find the {isEvents ? 'Events' : 'Items'} that are right for you and your
                    team.
                  </Text>
                  <UiButton
                    size="lg"
                    minW="221px"
                    m={5}
                    colorScheme="tertiary"
                    onClick={() => navigate(isEvents ? '/events/marketplace' : '/items/featured')}
                  >
                    Go to the Marketplace
                  </UiButton>
                </>
              ) : (
                <Text
                  color="white"
                  width="60%"
                  fontSize="md"
                  fontWeight={600}
                  textAlign="center"
                >
                  Contact your admin to make specific {isEvents ? 'Event' : 'Item'} requests
                </Text>
              )}
            </>
          </Flex>

          <Box
            display={{ base: 'none', sm: 'block' }}
            py={8}
          >
            {isEvents ? (
              <Image
                src={eventImages[0]}
                objectFit="contain"
              />
            ) : (
              <Grid
                justifyContent="center"
                py={'32px'}
                px={5}
                width="100%"
                alignItems="center"
                gridTemplateColumns="repeat(4, 190px)"
                gap={3}
              >
                {(isEvents ? eventImages : postalImages).map((item, idx) => (
                  <Box
                    h="190px"
                    w="190px"
                    key={idx}
                  >
                    <Image
                      h="100%"
                      src={item}
                      objectFit="cover"
                      objectPosition="center"
                      borderColor="gray.100"
                      borderWidth="1px"
                    />
                  </Box>
                ))}
              </Grid>
            )}
          </Box>
        </Grid>
      </UiCard>
    </>
  )
}
