import type { UserAnalytics } from 'api'
import { Granularity, GroupBy } from 'api'
import { endOfDay, isValid, parseISO, startOfMonth } from 'date-fns'
import { dequal } from 'dequal'
import { produce } from 'immer'
import { isArray, isDate, isEmpty, isObject, isString } from 'lodash'
import merge from 'lodash/merge'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useImmer } from 'use-immer'
import { DateRangeOption } from './data'
import type { GroupByOptionV2 } from './GroupByFilter'

export const hasUserDataV2 = (arr?: UserAnalytics[] | null, field?: keyof UserAnalytics) =>
  (arr?.reduce((sum, a) => sum + (a?.[field || 'sent'] ?? 0), 0) ?? 0) > 0

export interface ReportingFilterV2State {
  startDate: Date
  endDate: Date
  dateRangeOption: DateRangeOption
  granularity: Granularity
  teamIds?: string[]
  userIds?: string[]
  groupBy?: GroupByOptionV2
  isChanged?: boolean
}

const isEmptyField = (v: any) => {
  if (isDate(v)) return !isValid(v)
  if (isString(v) || isArray(v) || isObject(v)) return isEmpty(v)
  return !v
}

const defaultDebounce = 400

export const getDefaultState = () =>
  ({
    startDate: startOfMonth(new Date()),
    endDate: endOfDay(new Date()),
    granularity: Granularity.Weekly,
    dateRangeOption: DateRangeOption.ThisMonth,
    groupBy: GroupBy.User,
  } as ReportingFilterV2State)

// remove any empty values
const transformVariables = (state: ReportingFilterV2State) => {
  return produce(state, (draft) => {
    Object.keys(draft).forEach((key) => {
      const _key = key as keyof ReportingFilterV2State
      const value = state[_key] as any
      if (isEmptyField(value)) delete draft[_key]
    })
  })
}

interface UseReportingFilterV2Props {
  persistKey?: string
  debounce?: number
}

export const useReportingFilter = ({ persistKey, debounce }: UseReportingFilterV2Props) => {
  const defaultState = useRef(getDefaultState())
  const [state, setState] = useImmer<ReportingFilterV2State>(() => {
    let storedState: any = {}
    try {
      if (persistKey) {
        // dates are converted to strings when saving to sessionStorage
        storedState = JSON.parse(sessionStorage.getItem(persistKey) || '{}')
        if (storedState.startDate) storedState.startDate = parseISO(storedState.startDate)
        if (storedState.endDate) storedState.endDate = parseISO(storedState.endDate)
      }
    } catch (e) {
      console.error(e)
    }
    return merge({}, defaultState.current, storedState)
  })

  const [filter, setFilter] = useState<ReportingFilterV2State>(transformVariables(state))
  const setDebounced = useDebouncedCallback(setFilter, debounce || defaultDebounce)
  const resetState = useCallback(() => setState(() => defaultState.current), [setState])

  // we are watching state changes and then updating the filters via debounce
  useEffect(() => {
    setDebounced(transformVariables(state))
  }, [setDebounced, state])

  // every time we change the filters, write to localStorage
  useEffect(() => {
    if (persistKey) sessionStorage.setItem(persistKey, JSON.stringify(state))
  }, [state, persistKey])

  const isChanged = useMemo(() => {
    if (state.teamIds?.length || state.userIds?.length) return true
    return !dequal(
      {
        dateRangeOption: defaultState.current.dateRangeOption,
        granularity: defaultState.current.granularity,
      },
      {
        dateRangeOption: state.dateRangeOption,
        granularity: state.granularity,
      }
    )
  }, [state])

  return {
    state,
    setState,
    resetState,
    filter,
    isChanged,
  }
}

export type UseReportingFilterV2 = ReturnType<typeof useReportingFilter> & {
  showGranularity?: boolean
}
