import {
  MdDrafts,
  MdInsertChartOutlined,
  MdLink,
  MdMailOutline,
  MdOutlineCardGiftcard,
  MdOutlineDrafts,
  MdOutlineFlare,
  MdOutlineLocalShipping,
  MdOutlineMarkunreadMailbox,
  MdOutlineStyle,
  MdOutlineSwapHoriz,
} from 'react-icons/md'
import { PostalSendMethod, SEND_METHOD_COLORS } from '../PostalSend/postalSendHelpers'

export enum OrdersTypes {
  All = 'ALL',
  Drafts = 'DRAFTS',
  Emails = 'EMAILS',
  Links = 'LINKS',
  Direct = 'DIRECT',
  BulkSends = 'BULK',
  Subscriptions = 'SUBSCRIPTIONS',
  Triggers = 'TRIGGERS',
  ABM = 'ABM',
}

export const GROUP_ORDERS_TYPES = [OrdersTypes.Emails, OrdersTypes.Direct, OrdersTypes.All]

export const ORDERS_TYPE_CONFIG = {
  [OrdersTypes.All]: {
    path: '/orders/all',
    text: 'All Orders',
    icon: MdOutlineFlare,
  },
  [OrdersTypes.Drafts]: {
    path: '/orders/drafts',
    text: 'Drafts',
    icon: MdOutlineDrafts,
  },
  [OrdersTypes.Emails]: {
    path: '/orders/emails',
    text: 'Emails',
    icon: MdMailOutline,
  },
  [OrdersTypes.Links]: {
    path: '/orders/links',
    text: 'MagicLinks',
    icon: MdLink,
  },
  [OrdersTypes.Direct]: {
    path: '/orders/direct',
    text: 'Direct',
    icon: MdOutlineMarkunreadMailbox,
  },
  [OrdersTypes.BulkSends]: {
    path: '/orders/bulk',
    text: 'Bulk Sends',
    icon: MdOutlineLocalShipping,
  },
  [OrdersTypes.Subscriptions]: {
    path: '/orders/subscriptions',
    text: 'Subscriptions',
    icon: MdOutlineStyle,
  },
  [OrdersTypes.Triggers]: {
    path: '/orders/triggers',
    text: 'Triggers',
    icon: MdOutlineSwapHoriz,
  },
  [OrdersTypes.ABM]: {
    path: '/orders/abm',
    text: 'ABM',
    icon: MdInsertChartOutlined,
  },
}

export const SEND_TYPE_STYLES: Record<OrdersTypes, any> = {
  [OrdersTypes.Emails]: {
    color: SEND_METHOD_COLORS[PostalSendMethod.Email],
    icon: MdMailOutline,
    heading: 'Personalized Email',
    colorScheme: 'atomicBlue',
  },
  [OrdersTypes.Links]: {
    color: SEND_METHOD_COLORS[PostalSendMethod.Link],
    icon: MdLink,
    heading: 'MagicLink Redemption',
    item: 'MagicLink Redemption',
    colorScheme: 'atomicPurple',
    automationPath: '/links',
  },
  [OrdersTypes.Direct]: {
    color: SEND_METHOD_COLORS[PostalSendMethod.Direct],
    icon: MdOutlineMarkunreadMailbox,
    heading: 'Direct Send',
    colorScheme: 'vendorGreen',
  },
  [OrdersTypes.BulkSends]: {
    color: SEND_METHOD_COLORS[PostalSendMethod.BulkSend],
    icon: MdOutlineLocalShipping,
    heading: 'Bulk Send',
    colorScheme: 'vendorGreen',
  },
  [OrdersTypes.Subscriptions]: {
    color: 'black',
    icon: MdOutlineStyle,
    heading: 'Subscription',
    item: 'Subscription',
    colorScheme: 'atomicGray',
    automationPath: '/subscriptions',
  },
  [OrdersTypes.Triggers]: {
    color: 'black',
    icon: MdOutlineSwapHoriz,
    heading: 'Trigger',
    item: 'Trigger',
    colorScheme: 'atomicBlue',
    automationPath: '/triggers',
  },
  [OrdersTypes.ABM]: {
    color: 'black',
    icon: MdOutlineSwapHoriz,
    heading: 'ABM Campaign Orders',
    item: 'ABM Campaign',
    colorScheme: 'atomicGray',
  },
  [OrdersTypes.Drafts]: {
    color: 'black',
    icon: MdDrafts,
    heading: 'Draft',
    colorScheme: 'atomicBlue',
  },
  [OrdersTypes.All]: {
    color: 'black',
    icon: MdDrafts,
    colorScheme: 'atomicBlue',
  },
  [OrdersTypes.ABM]: {
    color: SEND_METHOD_COLORS[PostalSendMethod.Email],
    icon: MdMailOutline,
    heading: 'ABM Campaign',
    colorScheme: 'atomicBlue',
  },
}

export const getSendTypeStyles = (sendType?: OrdersTypes | null) => {
  return sendType
    ? SEND_TYPE_STYLES[sendType]
    : { color: 'atomicGray.600', colorScheme: 'atomicGray', icon: MdOutlineCardGiftcard, heading: 'Group Order' }
}
