import { ButtonGroup, Container, Flex, FormControl, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiAlert, UiCard, UiDivider, useAlertError, useAlerts, ZButton, ZFormLabel, ZInput } from '@postal-io/postal-ui'
import type { Currency, PlaybookDefinition } from 'api'
import {
  CreatePlaybookDefinitionDocument,
  GetPlaybookDefinitionDocument,
  Status,
  UpdatePlaybookDefinitionDocument,
} from 'api'
import { PostalSelectItem } from 'components/PostalSend/PostalSelectItem'
import { NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { useAcl } from 'hooks'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MdOutlineAddCircle } from 'react-icons/md'
import { useLocation, useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import type { ApprovedPostal } from '../../api/index'
import { PlaybookDefinitionSortableSteps } from './PlaybookDefinitionSortableSteps'

const date = new Date().toLocaleDateString()

export const PlaybookDefinitionEdit: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const playbookId = params.playbookId as string

  function goBack() {
    !!playbookId ? navigate(`/subscriptions/${playbookId}`) : navigate('/subscriptions')
  }

  const backLabel = !!playbookId ? 'Back to Subscription' : 'Back to Subscriptions'

  const { state } = useLocation() as any
  // catch updated playbook state passed back from send flow
  const { updatedPlaybook: currentPlaybookState } = state ?? {}

  const { hasPermission, hasFeature } = useAcl()
  const hasCreate = hasPermission('playbooks.create')
  const hasCurrency = hasFeature('internationalization')

  const Alert = useAlerts()

  const [name, setName] = useState<any>(`New Subscription  - ` + date)
  const [steps, setSteps] = useState<any[]>([])
  const [currency] = useState<Currency>()
  const selectItemDisclosure = useDisclosure()

  const createPlaybookDefinition = useGraphqlMutation(CreatePlaybookDefinitionDocument)
  const updatePlaybookDefinition = useGraphqlMutation(UpdatePlaybookDefinitionDocument)

  const { data, error } = useGraphqlQuery(
    GetPlaybookDefinitionDocument,
    { id: playbookId },
    { enabled: !!playbookId && !currentPlaybookState }
  )
  const playbook = useMemo(
    // use current updated playbook state passed back from send flow
    () => currentPlaybookState ?? data?.getPlaybookDefinition,
    [currentPlaybookState, data?.getPlaybookDefinition]
  )
  useAlertError(error)

  const sortedStepsRef = useRef(playbook?.steps)

  useEffect(() => {
    if (playbook) {
      setSteps(playbook.steps ?? [])
      setName(playbook.name)
    }
  }, [playbook])

  const addStep = () => {
    selectItemDisclosure.onOpen()
  }

  const handleEditStep = (step: PlaybookDefinition, idx: any) => {
    const { approvedPostalId } = steps[idx]
    const url = playbookId
      ? `/subscriptions/${playbook?.id}/step/${idx}/send/${approvedPostalId}`
      : `/subscriptions/create/step/${idx}/send/${approvedPostalId}`
    navigate(url, { state: { playbook: { id: playbook?.id, steps, name } } })
  }

  const deleteStep = (_idx: number) => {
    setSteps([...steps?.filter((_, idx) => idx !== _idx)])
  }

  const handleSubmit = async () => {
    if (!name) return Alert.error('Please name this playbook')
    if (!steps?.length) return Alert.error('There are no steps in your Playbook')
    const normalizedSteps = steps.map(({ productName, ...step }) => step)

    try {
      if (playbookId) {
        await updatePlaybookDefinition.mutateAsync({
          id: playbook?.id ?? '',
          data: { name, steps: normalizedSteps },
        })
        Alert.success(`Subscription updated`)
        navigate(`/subscriptions/${playbookId}`)
      } else {
        const created = await createPlaybookDefinition.mutateAsync({
          data: {
            name,
            steps,
            currency,
            status: Status.Active,
          },
        })

        Alert.success(`Subscription Created`)
        navigate(`/subscriptions/${created.createPlaybookDefinition?.id}`)
      }
    } catch (err) {
      Alert.error(err)
    }
  }

  // if the playbook has a currency set use that, otherwise restrict
  // to the currency that was registered in the callback so long as
  // a step exists
  const approvedCurrencies = useMemo(() => {
    return playbook?.currency && steps.length ? [playbook?.currency] : undefined
  }, [playbook?.currency, steps.length])

  const isLoading = updatePlaybookDefinition.isLoading || createPlaybookDefinition.isLoading

  return (
    <>
      <SecondaryNavbar
        zIndex={100}
        // maxWidth and px match the page content
        maxWidth="100rem"
        px={{ md: 4, lg: 8, xl: 16 }}
        left={
          <NavbarBackButton
            onClick={goBack}
            label={backLabel}
          />
        }
        header={playbookId ? playbook?.name ?? '' : 'New Subscription'}
      />

      <Container
        maxW="800px"
        p={8}
      >
        {hasCurrency && approvedCurrencies?.[0] && (
          <UiAlert
            mb={8}
            status="info"
            title={`Please note: subscriptions are restricted to a single currency. Subsequent steps will be limited to ${approvedCurrencies?.[0]}.`}
          />
        )}
        <UiCard>
          <Flex
            w="100%"
            flexDir="column"
            gridGap={8}
          >
            <FormControl id="name">
              <ZFormLabel fontSize="lg">Subscription Name</ZFormLabel>
              <ZInput
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl id="steps">
              <ZFormLabel fontSize="lg">Subscription Steps</ZFormLabel>
              {steps.length && (
                <PlaybookDefinitionSortableSteps
                  steps={steps}
                  isOpen
                  setSteps={setSteps}
                  handleEditStep={handleEditStep}
                  deleteStep={deleteStep}
                  sortedStepsRef={sortedStepsRef}
                />
              )}
            </FormControl>
            {hasCreate && (
              <ZButton
                mt={4}
                w="100%"
                leftIcon={<MdOutlineAddCircle size="20px" />}
                onClick={addStep}
                colorScheme="atomicBlue"
                variant="outline"
              >
                Add Step
              </ZButton>
            )}

            <UiDivider
              color="atomicGray.200"
              my={4}
            />

            <ButtonGroup spacing={4}>
              <ZButton
                colorScheme="atomicBlue"
                width="full"
                data-testid="create-edit-subscription"
                isDisabled={!steps.length || !name || isLoading || !hasCreate}
                onClick={handleSubmit}
              >
                {!!playbookId ? 'Update Subscription' : 'Create Subscription'}
              </ZButton>
              <ZButton
                colorScheme="atomicGray"
                isDisabled={isLoading}
                mt={2}
                onClick={goBack}
                variant="ghost"
                width="full"
              >
                Cancel
              </ZButton>
            </ButtonGroup>
          </Flex>
        </UiCard>
      </Container>

      <PostalSelectItem
        approvedCurrencies={approvedCurrencies}
        {...selectItemDisclosure}
        onSelect={(postal: ApprovedPostal) => {
          navigate(
            playbookId
              ? `/subscriptions/${playbook?.id}/step/${steps.length}/send/${postal.id}`
              : `/subscriptions/create/step/${steps.length}/send/${postal.id}`,
            { state: { playbook: { ...playbook, name } } }
          )
        }}
      />
    </>
  )
}
