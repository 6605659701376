import type { WrapProps } from '@chakra-ui/react'
import { Wrap, WrapItem } from '@chakra-ui/react'
import { UiDate, ZMoney, ZText } from '@postal-io/postal-ui'
import type { ApprovedPostal, Campaign, MagicLink, PlaybookDefinition, PostalFulfillment } from 'api'
import { Status } from 'api'
import { isNumber } from 'lodash'
import React from 'react'

export interface OrderAttributesProps extends WrapProps {
  postalFulfillment?: PostalFulfillment
  approvedPostal?: ApprovedPostal
  campaign?: Campaign
  magicLink?: MagicLink
  playbookDefinition?: PlaybookDefinition
}

export const OrderAttributes: React.FC<OrderAttributesProps> = ({
  postalFulfillment,
  approvedPostal,
  campaign,
  magicLink,
  ...rest
}) => {
  const { cost, fulfillments } = postalFulfillment ?? {}
  const { type, variantOrderFlexibility, variants } = approvedPostal ?? {}

  const { quantity } = fulfillments?.[0] ?? {}
  const hasPostalFulfillment = !!postalFulfillment
  const isMagicLink = !!magicLink && !hasPostalFulfillment

  const selectedVariant = !!campaign
    ? approvedPostal?.variants?.find((v) => v.id === campaign?.variantId)
    : approvedPostal?.variants?.find((v) => v.id === postalFulfillment?.variantId)

  return (
    <Wrap
      spacingX={8}
      spacingY={2}
      {...rest}
    >
      <OrderAttribute label="Type">{type || '-'}</OrderAttribute>
      {!!campaign && <OrderAttribute label="Orders">{campaign?.metrics?.accepted ?? 0}</OrderAttribute>}
      {isMagicLink && <OrderAttribute label="Order Limit">{magicLink?.maxExecutions ?? 'None'}</OrderAttribute>}
      {!!campaign && (
        <OrderAttribute label="Cost per order">
          <ZMoney
            ml={3}
            fontSize="body-sm"
            color="atomicGray.600"
            cents={(campaign?.metrics?.accepted ?? 0) / (campaign?.metrics?.costInCents ?? 1)}
          />
        </OrderAttribute>
      )}
      <OrderAttribute label={!variantOrderFlexibility ? 'Selected Option' : 'Recipient Choice'}>
        {!variantOrderFlexibility
          ? selectedVariant?.variantName
          : variants
              ?.filter((item) => item.status === Status.Active)
              .map((v) => v.variantName)
              .join(', ')}
      </OrderAttribute>
      {isMagicLink && (
        <OrderAttribute label="Expiration">
          <UiDate
            date={magicLink.expirationDate}
            fallback="Never"
          />
        </OrderAttribute>
      )}
      {quantity && <OrderAttribute label="Quantity">{new Intl.NumberFormat('en-US').format(quantity)}</OrderAttribute>}
      {isNumber(cost?.total) && (
        <OrderAttribute label="Total">
          <ZMoney
            color="atomicGray.600"
            fontSize="body-sm"
            cents={cost?.total}
            currency={cost?.currency}
          />
        </OrderAttribute>
      )}
    </Wrap>
  )
}

export const OrderAttribute: React.FC<React.PropsWithChildren<{ label: string }>> = ({ label, children }) => (
  <WrapItem alignItems="baseline">
    <ZText
      as="span"
      size="sm"
      color="atomicGray.500"
      fontWeight="bold"
    >
      {label}
    </ZText>
    <ZText
      ml={3}
      size="sm"
      as="span"
      color="atomicGray.600"
    >
      {children}
    </ZText>
  </WrapItem>
)
