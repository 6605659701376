import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Stack, useDisclosure } from '@chakra-ui/react'
import { UiMenu, UiMenuButton, UiMenuList, UiMoney, useColor, ZButton, ZHeading, ZText } from '@postal-io/postal-ui'
import type { ApprovedPostal, ApprovedProductVariant } from 'api'
import { Status } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { ApprovedPostalOption } from 'components/Postal/ApprovedVariantOption'
import { getSendMethodColor } from 'components/PostalSend/postalSendHelpers'
import type { PostalSendContext } from 'components/PostalSend/usePostalSend'
import { PostalSendMethod } from 'components/PostalSend/usePostalSend'
import React, { useCallback, useEffect, useMemo } from 'react'
import { MdOutlineArrowDropDown } from 'react-icons/md'

interface ApprovedVariantSelectV2Props extends BoxProps {
  context?: PostalSendContext
  postal: ApprovedPostal
  setVariant: (variant: ApprovedProductVariant) => void
  selectedVariant?: ApprovedProductVariant
  orderFlexibility?: boolean
}
export const ApprovedVariantSelect: React.FC<ApprovedVariantSelectV2Props> = ({
  context,
  postal,
  setVariant,
  selectedVariant,
  orderFlexibility,
  ...rest
}) => {
  const { Color } = useColor()
  const disclosure = useDisclosure()

  const hasVariants = useMemo(
    () => !!postal?.variants?.filter((v) => v.status === Status.Active)?.length,
    [postal?.variants]
  )

  const buttonText = useMemo(() => {
    if (selectedVariant) return selectedVariant.variantName
    if (hasVariants) return orderFlexibility ? 'See Options' : 'Select an Option'
    return 'No Options available'
  }, [orderFlexibility, selectedVariant, hasVariants])

  const onVariantSelect = useCallback(
    (variant: ApprovedProductVariant) => {
      setVariant(variant)
      setTimeout(disclosure.onClose, 200)
    },
    [setVariant, disclosure]
  )

  return (
    <>
      <Box
        mb={5}
        {...rest}
      >
        <ZHeading
          variant="h5"
          mb={2.5}
          fontSize={{ base: 'h6', lg: 'sm' }}
        >
          {orderFlexibility ? (
            <Flex align="center">
              Options Available to Recipient
              <ZInfoTooltip
                placement="top"
                ml={2}
                label="Recipients will choose from available options when accepting the order."
              />
            </Flex>
          ) : (
            'Select an Option'
          )}
        </ZHeading>
        <UiMenu
          {...disclosure}
          matchWidth
          closeOnSelect={false}
        >
          <UiMenuButton
            as={ZButton}
            h="40px"
            pr={4}
            pl={4}
            width="100%"
            fontSize="sm"
            variant="outline"
            color="atomicGray.800"
            borderRadius={3}
            borderColor="atomicGray.200"
            _hover={{ bg: 'white', borderColor: 'atomicGray.400' }}
            rightIcon={
              <MdOutlineArrowDropDown
                viewBox="2 2 20 20"
                style={{
                  color: Color('atomicGray.500'),
                  transform: disclosure.isOpen ? 'rotate(-180deg)' : '',
                  transition: 'transform 0.2s',
                }}
              />
            }
            isDisabled={!hasVariants}
            onClick={disclosure.onToggle}
          >
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              color="atomicGray.800"
            >
              <ZText textAlign="left">{buttonText}</ZText>
              {selectedVariant && (
                <UiMoney
                  textAlign="right"
                  cents={selectedVariant.displayPrice}
                  currency={postal.currency}
                />
              )}
            </Flex>
          </UiMenuButton>
          <UiMenuList
            boxShadow="0 0 15px rgba(0,0,0,.1)"
            maxH="320px"
            overflowY="scroll"
            maxW="calc(100vw - 32px)"
          >
            <ApprovedVariantSelectDropdown
              context={context}
              postal={postal}
              selectedVariant={orderFlexibility ? undefined : selectedVariant}
              onVariantSelect={orderFlexibility ? undefined : onVariantSelect}
            />
          </UiMenuList>
        </UiMenu>
        {/* ) : canEdit ? (
        <UiAlert status="warning">
          This product is currently unavailable. It may become available in the future, so we recommend you keep it as
          approved. You can update its status to “draft” if you don’t want users to see it while it is unavailable.
        </UiAlert>
      ) : (
        <UiAlert status="warning">This item is currently unavailable.</UiAlert>
      )} */}
      </Box>
      {/* <Divider
        color="atomicGray.200"
        mb={5}
      /> */}
    </>
  )
}

interface VariantOptions {
  variant: ApprovedProductVariant
  isDisabled: boolean
  disabledMessage?: string
}

interface ApprovedVariantSelectDropdownProps extends BoxProps {
  context?: PostalSendContext
  postal?: ApprovedPostal
  onVariantSelect?: (variant: ApprovedProductVariant) => void
  selectedVariant?: ApprovedProductVariant
  orderFlexibility?: boolean
}
export const ApprovedVariantSelectDropdown: React.FC<ApprovedVariantSelectDropdownProps> = ({
  context,
  postal,
  onVariantSelect,
  selectedVariant,
  orderFlexibility,
}) => {
  // We may only select an ACTIVE variant to utilize in a campaign or fulfillment.
  // if it is a direct send, disable any variants that require recipient customization
  const variantOptions: VariantOptions[] = useMemo(() => {
    // Returns a message if the variant is disabled, else it is undefined
    const getVariantDisabledMessage = (variant: ApprovedProductVariant) => {
      if (context?.method !== PostalSendMethod.Direct) {
        return undefined
      }
      let message = undefined
      postal?.itemCustomizationList?.forEach((itemCustomization) => {
        if (
          itemCustomization.variantId === variant.id &&
          itemCustomization.customizableByRecipient &&
          itemCustomization.required
        ) {
          message = 'This item cannot be sent directly because it requires recipient customization.'
        }
      })
      return message
    }

    const variants = (postal?.variants?.filter((v) => v.status === Status.Active) || []).map((v) => ({
      variant: v,
      isDisabled: !!getVariantDisabledMessage(v),
      disabledMessage: getVariantDisabledMessage(v),
    }))
    return variants
  }, [postal?.variants, postal?.itemCustomizationList, context?.method])

  /*
    Automatically select the first non-disabled variant if:
    - orderFlexibility is turned on
    - we only have one variant available
    - we have a selected variant that is not Active
  */
  useEffect(() => {
    const selectedVariantOptions = variantOptions.filter(({ variant }) => variant.id === selectedVariant?.id)?.[0]
    if (selectedVariant?.status === Status.Active && !selectedVariantOptions?.isDisabled) {
      return
    }

    if (orderFlexibility || variantOptions.length === 1 || !selectedVariant || selectedVariantOptions?.isDisabled) {
      const nonDisabledVariants = variantOptions.filter(({ isDisabled }) => isDisabled === false)
      const [firstVariant] = nonDisabledVariants
      onVariantSelect && firstVariant && onVariantSelect(firstVariant.variant)
    }
  }, [selectedVariant, onVariantSelect, orderFlexibility, variantOptions, context?.method])

  const isSelected = ({ id }: { id: string }) => !orderFlexibility && selectedVariant?.id === id

  return (
    <>
      {/* <Stack
        bg="white"
        p={2}
        {...rest}
      > */}
      {/* <ZHeading
          size="h6"
          fontSize="xs"
          color="atomicGray.500"
          ml={3}
          mr={1}
          pb={2}
          borderBottom="1px solid"
          borderBottomColor="atomicGray.200"
        >
          Variant Select
        </ZHeading> */}
      {/* <UiEnhancedTooltip
          hasArrow
          placement="top"
          label="close"
          openDelay={500}
        >
          <CloseIcon
            onClick={onClose}
            cursor="pointer"
            position="absolute"
            top={1}
            right={3}
            fontSize={8}
            color="atomicGray.500"
            _hover={{ color: 'atomicGray.600', bg: 'none' }}
          />
        </UiEnhancedTooltip> */}
      <Stack
        my={-2}
        p={2}
        spacing={2}
      >
        {variantOptions.map(({ variant, isDisabled, disabledMessage }) => (
          <ApprovedPostalOption
            key={variant.id}
            variant={variant}
            marketplaceProductId={postal?.marketplaceProductId as string}
            onClick={orderFlexibility ? undefined : () => onVariantSelect?.(variant)}
            category={postal?.category}
            isSelected={isSelected(variant)}
            brandingColor={getSendMethodColor(context)}
            isDisabled={isDisabled}
            disabledMessage={isDisabled ? disabledMessage : ''}
          />
        ))}
      </Stack>
      {/* </Stack> */}
    </>
  )
}
