import { VStack } from '@chakra-ui/react'
import type { MarketplaceProduct } from 'api'
import { AnalyticsEventV2, useAnalyticsSend } from 'hooks'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { CATEGORY } from '../Postals/data'
import { MarketplaceItemHero } from './MarketplaceItemHero'
import { getMarketplaceV2Filter } from './MarketplacePage'
import { MarketplaceSectionCategories } from './MarketplaceSectionCategories'
import { MarketplaceSectionEvents } from './MarketplaceSectionEvents'
import { MarketplaceSectionInternational } from './MarketplaceSectionInternational'
import { MarketplaceSectionPopularItems } from './MarketplaceSectionPopularItems'
import { MarketplaceSectionSwag } from './MarketplaceSectionSwag'
import { MarketplaceContext } from './useMarketplace'

export const MarketplaceViewHome: React.FC = () => {
  const navigate = useNavigate()
  const sendAnalytics = useAnalyticsSend()

  const {
    marketplaceFilters: { updateFilter },
  } = useContext(MarketplaceContext)

  const handleSelectCategory = (category: string) => {
    sendAnalytics({ event: AnalyticsEventV2.Marketplace_Category_Selected, data: { category } })

    if (category === CATEGORY.Events) {
      navigate('/events')
      return
    }

    if (category === CATEGORY.Swag) {
      updateFilter('categories', getMarketplaceV2Filter(category))
      navigate('/items/postals')
      return
    }

    // default case
    updateFilter('categories', getMarketplaceV2Filter(category), 0)
    navigate('/items/marketplace')
  }

  const handleSelectProduct = (product: MarketplaceProduct) => {
    navigate(`/items/marketplace/${product.id}`, {
      state: { category: product.category, returnTo: 'Marketplace' },
    })
  }

  const handleSelectEvent = (product: MarketplaceProduct) => {
    navigate(`/events/marketplace/${product.id}`, {
      state: { returnTo: 'Marketplace' },
    })
  }

  return (
    <VStack gap={20}>
      <MarketplaceItemHero />

      <MarketplaceSectionCategories onSelect={handleSelectCategory} />

      <MarketplaceSectionSwag onSelect={() => handleSelectCategory(CATEGORY.Swag)} />

      <MarketplaceSectionPopularItems onSelect={handleSelectProduct} />

      <MarketplaceSectionInternational onSelect={handleSelectProduct} />

      <MarketplaceSectionEvents onSelect={handleSelectEvent} />
    </VStack>
  )
}
