import { Box, Container } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiSkeleton, UiTab, UiTabList, UiTabs } from '@postal-io/postal-ui'
import { GetAccountDocument } from 'api'
import { AnalyticsEvent, useAcl, useAnalyticsEvent } from 'hooks'
import { isLocalOrTestEnvironment } from 'lib'
import { useMemo } from 'react'
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { CenteredBox } from '../Common'

export const Reporting = () => {
  const { hasFeature } = useAcl()
  const accountQuery = useGraphqlQuery(GetAccountDocument)
  const hasOrderReport = hasFeature('orderReport')
  const hasActivityStream = hasFeature('reportingActivityStream')
  const hasOverview = hasFeature('reportingOverview')
  const hasSummary = hasFeature('reportingSummary')

  useAnalyticsEvent({ event: AnalyticsEvent.ReportingPageViewed })

  // FOR NOW WE ARE ONLY SHOWING THIS TO SFDC MANAGED PACKAGE CUSTOMERS
  const hasImpact =
    hasFeature('reportingImpact') &&
    (isLocalOrTestEnvironment || !!accountQuery?.data?.getAccount?.sfdcPackageVersion?.startsWith('v2'))

  const routes = useMemo(() => {
    const initRoutes = [
      {
        path: '/reporting/overview',
        title: 'Overview',
        enabled: hasOverview,
      },
      {
        path: '/reporting/summary',
        title: 'Summary',
        enabled: hasSummary,
      },
      {
        path: '/reporting/impact',
        title: 'Impact and ROI',
        enabled: hasImpact,
      },
      {
        path: '/reporting/recent-activity',
        title: 'Recent Activity',
        enabled: hasActivityStream,
      },
      {
        path: '/reporting/orders',
        title: 'Order Report',
        enabled: hasOrderReport,
      },
      {
        path: '/reporting/exports',
        title: 'Recent Exports',
        enabled: hasOrderReport,
      },
    ]
    return initRoutes.filter((routes) => routes.enabled)
  }, [hasActivityStream, hasImpact, hasOrderReport, hasOverview, hasSummary])

  const location = useLocation()
  const navigate = useNavigate()

  const tabIndex = useMemo(() => {
    return routes.findIndex((route) => matchPath(route.path, location.pathname))
  }, [location.pathname, routes])

  const handleTabChange = (index: number) => {
    const path = routes[index]?.path as string
    if (path) navigate(path)
  }

  return (
    <CenteredBox
      isLoaded={!accountQuery.isLoading}
      mt={4}
    >
      <UiSkeleton isLoaded={tabIndex > -1}>
        <Container
          maxW="1800px"
          px={0}
        >
          <UiTabs
            defaultIndex={tabIndex}
            tabIndex={tabIndex}
            onChange={handleTabChange}
          >
            <UiTabList>
              {routes.map((route) => (
                <UiTab key={route.path}>{route.title}</UiTab>
              ))}
            </UiTabList>
          </UiTabs>
        </Container>
        <Box>
          <Outlet />
        </Box>
      </UiSkeleton>
    </CenteredBox>
  )
}
