import { Flex, Image, Text } from '@chakra-ui/react'
import { ZButton, ZCard } from '@postal-io/postal-ui'
import collectionsImage from 'assets/images/collection.svg'
import React from 'react'
import { MdChevronRight } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useCollectionPermissions } from '../../hooks'

export const CollectionsEmpty: React.FC<{ isEvents?: boolean }> = () => {
  const { canCreate } = useCollectionPermissions()
  const navigate = useNavigate()

  return (
    <>
      <Flex justifyContent="center">
        <ZCard
          width="700px"
          variant="form"
          justifyContent="center"
          alignItems="center"
          p={2}
        >
          <Flex
            bg="atomicBlue.100"
            borderColor="atomicBlue.300"
            borderRadius="50%"
            borderStyle="1px solid"
            h="50px"
            w="50px"
            justifyContent="center"
            alignItems="center"
            my={8}
          >
            <Image src={collectionsImage} />
          </Flex>
          <Text
            fontSize="18px"
            fontWeight="bold"
            color="atomicGray.900"
            textAlign="center"
          >
            No Collections available
          </Text>
          <>
            {canCreate ? (
              <>
                <Text
                  fontSize="md"
                  textAlign="center"
                  mt={5}
                >
                  Create collections of items to send,
                  <br /> where recipients can choose the item they want.
                </Text>
                <ZButton
                  m={5}
                  mb={9}
                  px={5}
                  colorScheme="atomicBlue"
                  onClick={() => navigate('/collections/create')}
                  textTransform="none"
                >
                  Create a Collection
                  <MdChevronRight style={{ fontSize: '20px', marginLeft: '10px' }} />
                </ZButton>
              </>
            ) : (
              <Text
                fontSize="md"
                textAlign="center"
                m={5}
                mb={9}
              >
                Currently, you don’t have any collections available to send. <br />
                Your admin can approve them for you.
              </Text>
            )}
          </>
        </ZCard>
      </Flex>
    </>
  )
}
