import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import {
  UiButton,
  UiDivider,
  UiFormControl,
  UiFormLabel,
  UiInput,
  UiLink,
  UiSwitch,
  useAlerts,
} from '@postal-io/postal-ui'
import { zLoginInputStyles } from 'components/Common/ZComponents'
import { PageTitle, useSession } from 'hooks'
import React, { useState } from 'react'
import { useImmer } from 'use-immer'
import { SsoIconOption } from '../Session/SsoOption'
import type { InviteInfo } from './types'

interface WelcomeNewUserProps {
  inviteId: string
  inviteInfo: InviteInfo
  onComplete: (e: string) => void
}

interface FormProps {
  terms: boolean
  firstName: string
  lastName: string
}

export const WelcomeNewUser: React.FC<WelcomeNewUserProps> = ({ inviteId, inviteInfo, onComplete }) => {
  const Alert = useAlerts()
  const [loading, setLoading] = useState(false)
  const { inviteComplete } = useSession()

  const [form, setForm] = useImmer<FormProps>({ terms: true, firstName: '', lastName: '' })

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const { firstName, lastName } = form

    setLoading(true)
    try {
      const { token } = await inviteComplete({ id: inviteId, firstName, lastName })
      setLoading(false)
      onComplete(token)
    } catch (err) {
      Alert.warning(err)
      setLoading(false)
    }
  }

  const { accountName, inviterFirstName, inviterLastName, teamName } = inviteInfo

  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target
    setForm((draft: any) => void (draft[name] = value))
  }

  return (
    <>
      <PageTitle
        title="Register"
        section="Welcome"
      />
      <Flex
        w="80%"
        maxW="500px"
        alignItems="center"
        flexDir="column"
        textAlign="center"
        color="white"
      >
        <Heading
          fontWeight={600}
          as="h1"
          size="md"
          my={12}
          textAlign="center"
          whiteSpace="nowrap"
        >
          Welcome!
        </Heading>
        <Text>
          You have been invited by{' '}
          <strong>
            {inviterFirstName} {inviterLastName}
          </strong>{' '}
          to join{' '}
          {teamName && (
            <>
              the <strong>{teamName}</strong> Team of{' '}
            </>
          )}
          <strong>{accountName}</strong> @ Postal. Please login with your SSO provider or complete the form below to
          complete registration.
        </Text>

        <Flex
          flexDir="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
          mt={10}
        >
          <Text
            as="span"
            fontWeight="normal"
            color="white"
            fontSize="sm"
            whiteSpace="nowrap"
            m={2}
          >
            Single Sign-on:
          </Text>
          <SsoIconOption
            width="fit-content"
            mx={2}
            justifySelf="center"
            params={{ inviteId }}
            isDisabled={!form.terms}
          />
        </Flex>
        <UiDivider
          text="Or"
          textColor="white"
          borderColor="atomicGray.500"
          border="1px"
          flexProps={{ w: '100%' }}
          my={12}
        />
        <Box
          w="100%"
          mb={10}
        >
          <form onSubmit={onSubmit}>
            <Stack spacing={4}>
              <UiFormControl
                id="firstName"
                isRequired
                color="white"
              >
                <UiInput
                  {...zLoginInputStyles}
                  name="firstName"
                  autoFocus
                  type="text"
                  placeholder="First Name"
                  onChange={handleInput}
                />
              </UiFormControl>

              <UiFormControl
                id="lastName"
                color="white"
                isRequired
              >
                <UiInput
                  {...zLoginInputStyles}
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  onChange={handleInput}
                />
              </UiFormControl>
            </Stack>
            <UiDivider
              my={12}
              borderColor="atomicGray.500"
              border="1px"
              flexProps={{ w: '100%' }}
            />

            <UiFormControl
              display="flex"
              textAlign="center"
              id="terms"
              color="white"
              isRequired
            >
              <UiSwitch
                isRequired
                name="terms"
                size="lg"
                color="tertiary"
                isChecked={form.terms}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setForm((draft) => void (draft.terms = e.target.checked))
                }
              />
              <UiFormLabel
                ml={2}
                fontSize="1rem"
                color="white"
              >
                <span>Agree to </span>
                <UiLink
                  href="https://postal.com/terms-of-service/"
                  isExternal
                >
                  Terms &amp; Conditions
                </UiLink>
                <span> / </span>
                <UiLink
                  href="https://postal.com/privacy-policy/"
                  isExternal
                >
                  Privacy Policy
                </UiLink>
              </UiFormLabel>
            </UiFormControl>

            <Box textAlign="center">
              <UiButton
                type="submit"
                colorScheme="secondary"
                mt={8}
                h="56px"
                size="body-lg"
                w="full"
                isDisabled={loading}
                isLoading={loading}
              >
                Continue
              </UiButton>
            </Box>
          </form>
        </Box>
      </Flex>
    </>
  )
}
