import type { UiConfirmProps } from '@postal-io/postal-ui'
import { UiConfirm } from '@postal-io/postal-ui'
import React from 'react'

interface ConfirmCloseProps extends Omit<UiConfirmProps, 'children'> {
  thingWithChanges?: string
  children?: React.ReactNode
}
export const ConfirmClose = (props: ConfirmCloseProps) => {
  return (
    <UiConfirm
      isCentered
      cancelButtonText="Go Back"
      cancelButtonColor="primary"
      buttonText="Close"
      buttonColor="gray"
      closeOnEsc
      closeOnOverlayClick
      {...props}
    >
      {props.children ||
        `This ${props.thingWithChanges ?? 'form'} has unsaved changes. Sure you want to close without saving?`}
    </UiConfirm>
  )
}
