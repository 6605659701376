import { Grid } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiSkeleton, ZButton, ZCard, ZText } from '@postal-io/postal-ui'
import type { PlaybookDefinition } from 'api'
import { SearchPlaybookDefinitionsDocument } from 'api'
import { CenteredBox } from 'components/Common'
import { PlaybookDefinitionCard } from 'components/PlaybookDefinitions/PlaybookDefinitionCard'
import { NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { PageTitle } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface TriggerEditSelectPlaybookV2Props {
  onNavigateBack: () => void
  onSelect: (playbook?: PlaybookDefinition) => void
}

export const TriggerEditSelectPlaybook: React.FC<TriggerEditSelectPlaybookV2Props> = ({ onNavigateBack, onSelect }) => {
  const [selected, setSelected] = useState<PlaybookDefinition>()
  const { data, isLoading } = useGraphqlQuery(SearchPlaybookDefinitionsDocument)
  const playbookDefinitions = useMemo(() => {
    return (data?.searchPlaybookDefinitions as PlaybookDefinition[]) || []
  }, [data?.searchPlaybookDefinitions])

  useEffect(() => {
    if (selected) {
      onSelect(selected)
    }
  }, [onSelect, selected])

  const navigate = useNavigate()

  const handleCreatePlaybook = () => navigate(`/subscriptions`)

  return (
    <>
      <PageTitle title={`Select Subscription`} />
      <SecondaryNavbar
        left={
          <NavbarBackButton
            onClick={onNavigateBack}
            label="Back to Edit Trigger"
          />
        }
        header={`Select Subscription`}
      />
      <CenteredBox
        isLoaded
        mt={8}
      >
        <Grid
          templateColumns="repeat(auto-fit, 350px)"
          gridGap={8}
          mt={8}
          pb={4}
          justifyContent="center"
        >
          {playbookDefinitions?.map((item) => (
            <PlaybookDefinitionCard
              key={item.id}
              definition={item}
              onSelect={() => setSelected(item)}
              buttonText={`Select Subscription`}
              isSelected={item.id === selected?.id}
            />
          ))}
          {isLoading && (
            <UiSkeleton
              borderRadius={10}
              borderColor="gray.200"
              borderWidth={1}
              w="400px"
              h="400px"
            />
          )}
          {!isLoading && !playbookDefinitions?.length && (
            <ZCard
              borderRadius={10}
              borderColor="gray.200"
              borderWidth={1}
              justifyContent="center"
              w="400px"
            >
              <ZText textAlign="center">No Subscriptions Found </ZText>
              <ZButton
                colorScheme="atomicBlue"
                onClick={handleCreatePlaybook}
                mt={4}
              >
                Create a Subscription
              </ZButton>
            </ZCard>
          )}
        </Grid>
      </CenteredBox>
    </>
  )
}
