import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { FontWeight, UiGoogleCaptchaPolicies, UiMoney, ZCard, ZFormLabel } from '@postal-io/postal-ui'
import type { OrderPreview } from 'api'
import { GetBillingAccountDocument } from 'api'
import { findPartnerPaymentMethod, findPaymentPartner } from 'components/Billing/BillingAccountDetails'
import { BillingAddFunds } from 'components/Billing/BillingAddFunds'
import { PaymentMethodComponent } from 'components/Billing/paymentMethods/PaymentMethodComponent'
import { useAcl } from 'hooks'
import React, { useRef } from 'react'

interface PostalAddFundsV2Props {
  isOpen: boolean
  loading?: boolean
  orderInfo?: OrderPreview
  onClose: () => void
  onComplete: () => void
}

export const PostalAddFunds: React.FC<PostalAddFundsV2Props> = ({
  isOpen,
  loading,
  onClose,
  onComplete,
  orderInfo,
}) => {
  const cancelRef = useRef(null)
  const { hasPermission } = useAcl()
  const hasBilling = hasPermission('billing.update')

  const { billingAccountId, cost, fundsPassed, fundsRemaining } = orderInfo ?? {}

  const getBillingAccount = useGraphqlQuery(
    GetBillingAccountDocument,
    { id: billingAccountId as string },
    { enabled: !!billingAccountId && !!hasBilling }
  )
  const billingAccount = getBillingAccount.data?.getBillingAccount

  const fundsAvailable = fundsRemaining || 0

  // this is coming in as an int
  const orderCost = cost?.total || 0

  // handle all these queries
  const isLoading = getBillingAccount.isLoading || loading

  return (
    <>
      <AlertDialog
        size={{ base: 'full', md: '3xl' }}
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Add Funds</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Flex
              alignItems="center"
              direction="column"
            >
              <Stack
                minW={{ md: '500px' }}
                spacing={4}
              >
                <Text>
                  Adding funds to your account allows you to order and send items. Think of it as a credit system where
                  you would order against those credits.
                </Text>
                <Text>
                  You are free to add funds to your balance just before you send an Item or start a campaign, or add per
                  month, quarter, etc...
                </Text>
                <Text>All unused balances are fully refundable if/when the account is ever canceled.</Text>
              </Stack>
            </Flex>

            {hasBilling ? (
              <Flex
                direction="column"
                minW={{ md: '500px' }}
                mt={8}
              >
                <ZFormLabel fontSize="md">Current Balance</ZFormLabel>
                <Flex alignItems="center">
                  <Text
                    color="atomicGray.600"
                    fontWeight="bold"
                    fontSize="xl"
                    mr={4}
                  >
                    <UiMoney
                      cents={fundsAvailable || 0}
                      currency={billingAccount?.currency}
                    />
                  </Text>
                  <Text
                    color="atomicGray.500"
                    fontSize="lg"
                  >
                    <UiMoney
                      cents={orderCost}
                      currency={billingAccount?.currency}
                    />{' '}
                    needed
                  </Text>
                </Flex>

                {billingAccountId && (
                  <BillingAddFunds
                    billingAccount={billingAccount}
                    hasPaymentAccount={!!findPartnerPaymentMethod(billingAccount)?.partnerId}
                    paymentPartnerType={findPaymentPartner(billingAccount)?.type}
                    partnerPaymentMethod={findPartnerPaymentMethod(billingAccount)}
                  />
                )}

                <Text
                  mt={8}
                  fontSize="lg"
                  fontWeight="semibold"
                  fontFamily="body"
                  mb={1}
                  color="gray.600"
                >
                  Payment Method
                </Text>

                {getBillingAccount.isLoading && !billingAccount && <ZCard isLoading />}

                {!getBillingAccount.isLoading && billingAccount && (
                  <PaymentMethodComponent
                    mt={4}
                    partnerPaymentMethod={findPartnerPaymentMethod(billingAccount)}
                    billingAccount={billingAccount}
                  />
                )}
              </Flex>
            ) : (
              <Flex
                h="100%"
                justifyContent="center"
                alignItems="center"
                mb={10}
              >
                <Text
                  textAlign="center"
                  color="gray.600"
                  fontSize="2xl"
                  fontWeight={FontWeight.Bold}
                  p={4}
                >
                  Please contact your administrator to add funds.
                </Text>
              </Flex>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            {hasBilling && (
              <>
                <Button
                  minW={32}
                  onClick={onComplete}
                  isLoading={isLoading}
                  isDisabled={isLoading || !fundsPassed}
                >
                  Save and Send
                </Button>

                <Button
                  ref={cancelRef}
                  variant="ghost"
                  colorScheme="atomicGray"
                  onClick={onClose}
                  minW={32}
                >
                  Cancel
                </Button>
              </>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <UiGoogleCaptchaPolicies />
    </>
  )
}
