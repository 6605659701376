import { Flex } from '@chakra-ui/react'
import { ZText } from '@postal-io/postal-ui'
import React from 'react'
import { MdInsertEmoticon } from 'react-icons/md'

export const EventsApprovedPostalEventParticipants: React.FC<{
  maximumAttendees: number | null | undefined
  spotsRemaining: number | null | undefined
}> = ({ maximumAttendees, spotsRemaining }) => {
  const accepted = (maximumAttendees ?? 0) - (spotsRemaining ?? 0)
  return (
    <Flex
      alignItems="flex-start"
      gap={2}
    >
      <MdInsertEmoticon size="20px" />
      <ZText flexWrap="wrap">
        <ZText as="span">
          {accepted}
          {maximumAttendees ? ` of ${maximumAttendees}` : undefined}
        </ZText>
        <ZText as="span">
          {' '}
          participants
          <br />
          have accepted
        </ZText>
      </ZText>
    </Flex>
  )
}
