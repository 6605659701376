import { objectCastInt } from '@postal-io/postal-ui'
import React, { useEffect, useMemo, useRef } from 'react'
import { Transformer } from 'react-konva'
import { Border } from './Border'
import { CUT_SIZE } from './CutLine'
import { FoldLine } from './FoldLine'
import { Image } from './Image'
import { ReservedArea } from './ReservedArea'
import { Text } from './Text'
import { useRotation } from './useRotation'

const CUT_ALERT = 'It is recommended to extend your artwork beyond the cut line to ensure no whitespace after the cut'

// return a value within the constraints
export const getConstrainedValue = (min: number, max: number, val: number) => {
  return val < min ? min : val > max ? max : val
}

const MIN_HEIGHT = 10
const MIN_WIDTH = 10

const ELEMENT_MAP: { [key: string]: any } = {
  AddressLabel: Image,
  Border: Border,
  FoldLine: FoldLine,
  Image: Image,
  Logo: Image,
  Postage: Image,
  QrCode: Image,
  ReservedArea: ReservedArea,
  Text: Text,
  UserMessage: Text,
  UspsImb: Image,
  UspsBlock: Image,
}

export interface ElementProps {
  element: any
  isSelected?: boolean
  isEditable?: boolean
  snapGrid?: boolean
  gridSize?: number
  onSelect?: (e: any) => void
  dispatch?: any // (e: { type: string; payload?: any }) => void
}

export const Element: React.FC<ElementProps> = ({
  element,
  isSelected = false,
  isEditable = false,
  snapGrid,
  gridSize,
  dispatch,
  onSelect,
  ...rest
}) => {
  const { location, boundary } = element
  const elementRef = useRef<any>()
  const transformerRef = useRef<any>()
  const cursorRef = useRef<any>()

  //  Can we move this element
  const isMovable = useMemo(() => {
    if (!boundary) return true
    const { minX, maxX, minY, maxY } = boundary
    return !(location.x === minX && location.x === maxX && location.y === minY && location.y === maxY)
  }, [boundary, location.x, location.y])

  // Set new attrs to canvas when the element changes
  useEffect(() => {
    elementRef.current && elementRef.current.setAttrs(element.location)
  }, [element])

  // when selected set the transformer to the element
  useEffect(() => {
    if (isEditable && isSelected && isMovable && transformerRef.current) {
      transformerRef.current.setNodes([elementRef.current])
      transformerRef.current.getLayer().batchDraw()
    }
  }, [isSelected, isMovable, isEditable])

  const handleClick = () => {
    if (isEditable) {
      dispatch?.({ type: 'TOGGLE_SELECTED', payload: element })
      onSelect && onSelect(element)
    }
  }

  const handleDragStart = () => {
    if (!isEditable || !isSelected || !isMovable) return
    cursorRef.current = document.body.style.cursor
    document.body.style.cursor = 'move'
  }

  const handleDrag = (pos: { x: number; y: number }) => {
    // if not selected or draggable prevent the move
    if (!isEditable || !isSelected || !isMovable) return { x: location.x, y: location.y }

    const stage = elementRef.current.getStage()
    const scaleX = stage.scaleX()
    const scaleY = stage.scaleY()
    const elementWidth = elementRef.current.width()
    const elementHeight = elementRef.current.height()
    const stageWidth = stage.width()
    const stageHeight = stage.height()

    //  return new pos based on boundaries
    const {
      minX = 0,
      maxX = (stageWidth - elementWidth * scaleX) / scaleX,
      minY = 0,
      maxY = (stageHeight - elementHeight * scaleY) / scaleY,
    } = boundary || {}

    const newPos = {
      x: getConstrainedValue(minX * scaleX, maxX * scaleX, pos.x),
      y: getConstrainedValue(minY * scaleY, maxY * scaleY, pos.y),
    }
    return newPos
  }

  const cutCheck = (newLocation: Record<string, number>) => {
    const stage = elementRef.current.getStage()
    const scaleX = stage.scaleX()
    const scaleY = stage.scaleY()
    const stageWidth = stage.width() / scaleX
    const stageHeight = stage.height() / scaleY

    const minPad = CUT_SIZE / 1.5
    const maxPad = CUT_SIZE * 1.5

    const xLeft = newLocation.x
    const xRight = stageWidth - newLocation.x - newLocation.width
    const yTop = newLocation.y
    const yBottom = stageHeight - newLocation.y - newLocation.height

    if (
      (xLeft > minPad && xLeft < maxPad) ||
      (xRight > minPad && xRight < maxPad) ||
      (yTop > minPad && yTop < maxPad) ||
      (yBottom > minPad && yBottom < maxPad)
    ) {
      dispatch?.({ type: 'WARN', payload: CUT_ALERT })
    }
  }

  const handleDragEnd = (e: any) => {
    if (!isEditable || !isSelected || !isMovable) return
    const location = {
      x: e.target.x(),
      y: e.target.y(),
      width: e.target.width(),
      height: e.target.height(),
    }
    if (snapGrid && gridSize) {
      location.x = Math.round(e.target.x() / gridSize) * gridSize
      location.y = Math.round(e.target.y() / gridSize) * gridSize
    }
    document.body.style.cursor = cursorRef.current || ''
    cursorRef.current = undefined
    const newLocation = objectCastInt(location)
    cutCheck(newLocation)
    dispatch?.({ type: 'MOVE', payload: { ...element, location: newLocation } })
  }

  //Original type is Box, but react-konva doesnt export it from the lib
  type boxType = { x: number; y: number; width: number; height: number; rotation: number }

  const handleTransform = (oldBox: boxType, newBox: boxType) => {
    // if not selected or resizable prevent the transform
    if (!isEditable || !isSelected || !isMovable) return oldBox

    // get canvas size and scale
    const stage = elementRef.current.getStage()
    const stageWidth = stage.width()
    const stageHeight = stage.height()

    const { minX = 0, minY = 0 } = boundary || {}

    if (newBox.width < MIN_WIDTH || newBox.height < MIN_HEIGHT) return oldBox

    // normalize things that are out of bounds
    if (newBox.x < minX) {
      newBox.width = newBox.width - (minX - newBox.x)
      newBox.x = minX
    }
    if (newBox.x + newBox.width > stageWidth) {
      newBox.width = stageWidth - newBox.x
    }
    if (newBox.y < minY) {
      newBox.height = newBox.height - (minY - newBox.y)
      newBox.y = minY
    }
    if (newBox.y + newBox.height > stageHeight) {
      newBox.height = stageHeight - newBox.y
    }

    return newBox
  }

  const handleTransformEnd = (e: any) => {
    if (!isEditable && !isSelected && !isMovable) return
    // transformer scales the node NOT its width or height
    const location = objectCastInt({
      x: e.target.x(),
      y: e.target.y(),
      width: e.target.width() * e.target.scaleX(),
      height: e.target.height() * e.target.scaleY(),
    })

    if (snapGrid && gridSize) {
      location.x = Math.round(location.x / gridSize) * gridSize
      location.y = Math.round(location.y / gridSize) * gridSize
      location.width = Math.round(location.width / gridSize) * gridSize
      location.height = Math.round(location.height / gridSize) * gridSize
    }

    // set new attrs and reset scale
    e.target.setAttrs(location)
    e.target.scaleX(1)
    e.target.scaleY(1)

    cutCheck(location)
    dispatch?.({ type: 'RESIZE', payload: { ...element, location } })
  }

  // Get component based on element name
  const Component = ELEMENT_MAP[element.name]

  const { rotation, offsetY } = useRotation(elementRef, element)

  if (!isEditable) {
    return (
      <Component
        element={element}
        ref={elementRef}
        rotation={rotation}
        offsetY={offsetY}
      />
    )
  }

  return (
    <>
      <Component
        element={element}
        ref={elementRef}
        onClick={handleClick}
        draggable={isSelected && isMovable}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        dragBoundFunc={handleDrag}
        onTransformEnd={handleTransformEnd}
        isEditable={isEditable}
        isSelected={isSelected}
        rotation={rotation}
        offsetY={offsetY}
        {...rest}
      />
      {isEditable && isSelected && isMovable && (
        <Transformer
          ref={transformerRef}
          anchorSize={10}
          padding={10}
          borderStrokeWidth={1}
          borderStroke="#29AFFF"
          anchorStroke="#29AFFF"
          rotateEnabled={false}
          resizeEnabled={isMovable}
          boundBoxFunc={handleTransform}
        />
      )}
    </>
  )
}
