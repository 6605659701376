import type { SpendAs, Team, User } from 'api'
import { BillingSpendAs } from 'components/Common'
import React from 'react'

export interface EventsSpendAsV2Props {
  value?: SpendAs | null
  onChange: (spendAs: SpendAs) => void
}
export const EventsSpendAs: React.FC<EventsSpendAsV2Props> = ({ value, onChange }) => {
  const handleChangeUser = (user?: User | null) => {
    onChange({ ...value, userId: user?.id as string })
  }

  const handleChangeTeam = (team?: Team | null) => {
    if (value?.userId) onChange({ ...value, teamId: team?.id })
  }

  return (
    <BillingSpendAs
      userId={value?.userId}
      teamId={value?.teamId}
      onChangeUser={handleChangeUser}
      onChangeTeam={handleChangeTeam}
    />
  )
}
