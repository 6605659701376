import { Box, Flex, Grid, Link, Stack } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiDangerous,
  UiEnhancedTooltip,
  UiTagStack,
  ZCard,
  ZCardBody,
  ZCardDivider,
  ZHeading,
  ZProductCardImageIconFavorite,
  ZText,
} from '@postal-io/postal-ui'
import type { ApprovedPostal } from 'api'
import { FulfillmentType, GetMarketplaceProductDocument, Role, Status } from 'api'
import { useAcl, usePostalProductPermissions, usePostalTeams } from 'hooks'
import { usePostalFavoriteStatusToggle } from 'hooks/useFavoriteStatusToggle'
import { useFavoriteItemId } from 'hooks/usePostalFavoriteStatus'
import React, { useMemo } from 'react'
import { MdOutlineCalendarToday, MdOutlineGrass, MdOutlineLocalShipping, MdOutlineNote } from 'react-icons/md'
import { Link as RouterLink } from 'react-router-dom'
import { AttributionTag, getAttribution } from '../Integrations'
import { PostalImageCollage } from './PostalImageCollage'
import { StatusTag } from './StatusTag'

const favoritesIconStyles = {
  variant: 'ghost',
  fontSize: '26px',
  border: 'none',
  backgroundColor: 'none',
  _hover: { border: 'none' },
  _active: { border: 'none' },
  _focus: { border: 'none' },
}

interface ApprovedPostalProps {
  postal?: ApprovedPostal
}

export const ApprovedPostalGeneralView: React.FC<ApprovedPostalProps> = ({ postal }) => {
  const { hasRole, aclCheck } = useAcl()
  const hasFavorites = hasRole(Role.User)
  const { canUpdate, canBulkSend } = usePostalProductPermissions(postal)
  const attribution = getAttribution(postal?.attribution)
  const favoriteItemId = useFavoriteItemId(postal)
  const { toggleFavorite, isFavorite, isLoading } = usePostalFavoriteStatusToggle({
    favoriteItemId,
    approvedPostalId: postal?.id ?? '',
  })

  const hasMarketplace = aclCheck({ module: 'postals.marketplace' })
  const marketplaceQuery = useGraphqlQuery(
    GetMarketplaceProductDocument,
    { id: postal?.marketplaceProductId as string },
    { enabled: !!postal?.marketplaceProductId && hasMarketplace }
  )

  const vendor = useMemo(() => {
    const activeVariants = marketplaceQuery.data?.getMarketplaceProduct?.variants?.filter(
      (v) => v.status === Status.Active
    )
    if (!activeVariants?.length) return ''

    let name = ''
    for (const variant of activeVariants) {
      for (const partner of variant.fulfillmentPartnerList || []) {
        if (partner.fulfillmentPartnerName) {
          name = partner.fulfillmentPartnerName
          break
        }
      }
    }
    return name
  }, [marketplaceQuery.data?.getMarketplaceProduct?.variants])

  const fulfillmentType = useMemo(() => {
    const activeVariants = marketplaceQuery.data?.getMarketplaceProduct?.variants?.filter(
      (v) => v.status === Status.Active
    )
    if (!activeVariants?.length) return
    let type
    for (const variant of activeVariants) {
      for (const partner of variant.fulfillmentPartnerList || []) {
        if (partner.fulfillmentType) {
          type = partner.fulfillmentType
          break
        }
      }
    }
    return type
  }, [marketplaceQuery.data?.getMarketplaceProduct?.variants])

  const postalTeams = usePostalTeams(postal)

  const physicalMessageSupported = useMemo(
    () => postal?.variants?.some((v) => v.physicalMessageSupported),
    [postal?.variants]
  )

  const containsPerishables = useMemo(() => postal?.variants?.some((v) => v.containsPerishables), [postal?.variants])

  return (
    <ZCard
      variant="form"
      overflow="hidden"
      mt={5}
      isLoading={!postal}
    >
      <ZCardBody position="relative">
        <Stack spacing={8}>
          <Box>
            <PostalImageCollage postal={postal as ApprovedPostal} />
          </Box>

          {hasFavorites && (
            <Box
              position="absolute"
              right={8}
              top={2}
              zIndex={2}
            >
              <UiEnhancedTooltip
                label={isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
                mt={1}
                mr={4}
                openDelay={1000}
                hasArrow
                isDisabled={isLoading}
                shouldWrapChildren
              >
                <ZProductCardImageIconFavorite
                  {...favoritesIconStyles}
                  fontSize="30px"
                  isSelected={!!isFavorite}
                  onClick={toggleFavorite}
                />
              </UiEnhancedTooltip>
            </Box>
          )}

          <ZHeading size="h4">
            {postal?.name}
            {postal?.status !== Status.Active && (
              <StatusTag
                status={postal?.status as Status}
                float="right"
              />
            )}
          </ZHeading>
          <ZText
            whiteSpace="break-spaces"
            color="atomicGray.500"
          >
            <UiDangerous html={postal?.description} />
          </ZText>

          <Grid
            templateColumns="1fr 1fr"
            rowGap={4}
            pr={16}
          >
            {physicalMessageSupported && (
              <Flex gap={2}>
                <MdOutlineNote size="20px" />
                <ZText>Accepts physical note</ZText>
              </Flex>
            )}

            {fulfillmentType === FulfillmentType.Physical && (
              <Flex gap={2}>
                <MdOutlineCalendarToday size="20px" />
                <ZText>Ships on certain days</ZText>
              </Flex>
            )}

            {containsPerishables && (
              <Flex gap={2}>
                <MdOutlineGrass size="20px" />
                <ZText>Contains perishables</ZText>
              </Flex>
            )}
            {canBulkSend && (
              <Flex gap={2}>
                <MdOutlineLocalShipping size="20px" />
                <ZText>Available for bulk shipping</ZText>
              </Flex>
            )}
          </Grid>

          {!!attribution && (
            <>
              <ZCardDivider />

              <Box>
                <ZHeading
                  size="h6"
                  mb={2}
                >
                  External Campaign
                </ZHeading>
                <AttributionTag {...attribution} />
              </Box>
            </>
          )}

          {canUpdate && !postalTeams.isLoading && (
            <>
              <ZCardDivider />

              <Box>
                <ZHeading
                  size="h6"
                  mb={2}
                >
                  Available Teams
                </ZHeading>
                {!!postalTeams.teamNames.length ? (
                  <UiTagStack tags={postalTeams.teamNames} />
                ) : (
                  <UiTagStack tags={['All Teams']} />
                )}
              </Box>
            </>
          )}

          {!!vendor && (
            <ZText textAlign="right">
              View more items by{' '}
              <Link
                as={RouterLink}
                to={`/items/marketplace?vendor=${vendor}`}
              >
                {vendor}
              </Link>
            </ZText>
          )}
        </Stack>
      </ZCardBody>
    </ZCard>
  )
}
