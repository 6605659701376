import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { APPROVED_ITEM_TYPES, COLLECTION_ITEM_TYPES, EVENT_ITEM_TYPES } from 'components/Marketplace/data'
import { CATEGORY } from 'components/Postals'
import { Permission } from 'lib/permissions'
import { useMemo } from 'react'
import type { SearchableProduct } from '../api'
import { GetAccountDocument, PostalDeliveryEmailSetting, Status } from '../api'
import { useAcl } from './useAcl'
import { getdefaultNonLinkSendMethod } from './usePostalPermissions'
import { useSession } from './useSession'

export const SENDABLE_STATUSES = [Status.Active] as any

export const convertSearchContextsToTeamIds = (contexts?: String[]) =>
  contexts?.filter((c) => c.match(/\/team\/\w*\/products/)).map((c) => c.replace(/\/team\/(\w*)\/products/, '$1'))

export const useSearchableProductPermissions = (product?: SearchableProduct | null) => {
  const { hasPermission, hasFeature } = useAcl()
  const { session } = useSession()

  const getAccount = useGraphqlQuery(GetAccountDocument)
  const account = useMemo(() => getAccount.data?.getAccount, [getAccount.data?.getAccount])

  const teamIds = useMemo(() => convertSearchContextsToTeamIds(product?.contexts), [product?.contexts])

  const isCollection = useMemo(() => COLLECTION_ITEM_TYPES.includes(product?.itemType), [product?.itemType])
  const isMyProduct = useMemo(
    () => !!product?.contexts.find((c) => c.match(`/users/${session.userId}/products`)),
    [product?.contexts, session.userId]
  )

  const hasTeamAdmin = hasFeature('teamAdmin')
  const hasLinks = hasFeature('links')

  const isEvent = useMemo(() => EVENT_ITEM_TYPES.includes(product?.itemType!), [product?.itemType])

  // If we have a teamId on our session, we must be a team admin.
  // Therefore we can only edit this product if we are the only team that uses it
  // if its a marketplace product, that does not apply
  const isTeamPostal = useMemo(() => {
    if (!product) return false
    if (!hasTeamAdmin) return false
    // if (!product?.marketplaceProductId) return true
    return teamIds?.length === 1 && teamIds[0] === session.teamId
  }, [hasTeamAdmin, product, session.teamId, teamIds])

  const canCreate = useMemo(() => {
    if (!hasPermission('postals.create')) return false
    if (!!session.teamId) return hasTeamAdmin
    return true
  }, [hasPermission, hasTeamAdmin, session.teamId])

  const canContact = useMemo(() => {
    if (!product) return false
    if (!isEvent) return false
    if (!hasPermission(Permission.EventManager)) return false
    return true
  }, [product, isEvent, hasPermission])

  //permissions
  const canUpdate = useMemo(() => {
    if (!product) return false
    if (!APPROVED_ITEM_TYPES.includes(product.itemType)) return false
    // TODO: somehow check userId if it's a user collection
    if (isCollection) return isMyProduct || hasPermission('postals.update')
    if (!hasPermission('postals.update')) return false
    // TODO: somehow check event status if it's an event
    // if (isEvent && LOCKED_EVENT_STATUSES.includes(product?.eventStatus)) return false
    if (!!session.teamId) return isTeamPostal
    return true
  }, [product, isCollection, isMyProduct, hasPermission, session.teamId, isTeamPostal])

  const canDelete = useMemo(() => {
    if (!product) return false
    if (isCollection) return isMyProduct || hasPermission('postals.delete')
    if (!hasPermission('postals.delete')) return false
    if (EVENT_ITEM_TYPES.includes(product.itemType)) return false
    if (!!session.teamId) return isTeamPostal
    return true
  }, [product, isCollection, isMyProduct, hasPermission, session.teamId, isTeamPostal])

  const canSend = useMemo(() => {
    if (!product) return false
    if (!hasPermission('postals.send')) return false
    if (!SENDABLE_STATUSES.includes(product.status)) return false
    if (!APPROVED_ITEM_TYPES.includes(product.itemType)) return false
    // TODO: get event status
    // if (isEvent && !SENDABLE_EVENT_STATUSES.includes(product.event?.status)) return false
    return true
  }, [hasPermission, product])

  const canSendGiftEmail = useMemo(() => {
    if (!canSend) return false
    if (product?.categories?.includes(CATEGORY.DirectMail)) return false
    return true
  }, [canSend, product?.categories])

  const canSendDirectly = useMemo(() => {
    if (!canSend) return false
    if (!account) return false
    return account.postalDeliveryEmailSetting !== PostalDeliveryEmailSetting.Always
  }, [account, canSend])

  const canLink = useMemo(() => {
    if (!product) return false
    if (!hasLinks) return false
    if (!canSend) return false
    // TODO: Revisit this -> should we also check for product?.designTemplate in here? - Nate & Jonathan
    if (product?.categories?.includes(CATEGORY.DirectMail)) return false
    return true
  }, [canSend, hasLinks, product])

  // const canCancel = useMemo(() => {
  //   if (!product) return false
  //   if (!isEvent) return false
  //   if (!product.event?.status) return false
  //   if (!CANCELLABLE_EVENT_STATUSES.includes(product?.event?.status)) return false
  //   if (!hasPermission(Permission.EventManager)) return false
  //   return true
  // }, [isEvent, product, hasPermission])

  const defaultNonLinkSendMethod = useMemo(() => {
    return getdefaultNonLinkSendMethod({ account, canSendGiftEmail })
  }, [account, canSendGiftEmail])

  return {
    canCreate,
    canContact,
    canUpdate,
    canDelete,
    canSend,
    canSendGiftEmail,
    canSendDirectly,
    canLink,
    defaultNonLinkSendMethod,
  }
}
