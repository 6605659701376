import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAcl } from 'hooks'
import { useCallback, useMemo } from 'react'
import type { Currency } from '../api'
import { GetCachedCurrencyConfigsDocument, MeDocument, Role } from '../api'

// we are not restricting currencies for admins because they could
// end up assign it to other teams that do have that currency
// I can't remember why I added this flag - maybe we were undecided
// at the time.  Maybe we can just remove this.
const RESTRICT_CURRENCY = false

export const useMe = () => {
  const { hasPermission, hasRole } = useAcl()
  // team admins can only assign to the team they are logged in as
  // so they will be treated like a regular user here
  const isAdmin = hasPermission('postals.create') && hasRole(Role.Admin)

  const query = useGraphqlQuery(MeDocument)

  const me = useMemo(() => query?.data?.me, [query?.data?.me])

  // Get all currencies allowed to be used by products
  const adminMarketplaceCurrenciesQuery = useGraphqlQuery(
    GetCachedCurrencyConfigsDocument,
    { filter: { productEnabled: true } },
    { enabled: isAdmin }
  )

  // Map CachedCurrencyConfig to Currency
  const adminMarketplaceCurrencies = useMemo(
    () => adminMarketplaceCurrenciesQuery?.data?.getCachedCurrencyConfigs?.map((x) => x.currency),
    [adminMarketplaceCurrenciesQuery?.data?.getCachedCurrencyConfigs]
  )

  const hasCurrency = useCallback(
    (currency?: Currency | null) => (currency ? !!me?.currencyList?.includes(currency) : true),
    [me?.currencyList]
  )

  const approvedCurrencies = useMemo(() => {
    return isAdmin && !RESTRICT_CURRENCY ? adminMarketplaceCurrencies || [] : me?.currencyList || []
  }, [isAdmin, me?.currencyList, adminMarketplaceCurrencies])

  const restrictedCurrencies = useMemo(() => {
    return me?.currencyList || []
  }, [me?.currencyList])

  return { me, hasCurrency, approvedCurrencies, restrictedCurrencies, isLoading: query.isLoading }
}
