import { Stack, Text } from '@chakra-ui/react'
import type { Column, GraphqlFilterState } from '@postal-io/postal-ui'
import {
  GraphqlFilterTransform,
  OrderByDirection,
  UiDateTime,
  UiTooltip,
  UiTruncate,
  ZLink,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import type { BudgetHistory } from 'api'
import { BudgetHistoryType } from 'api'
import { ZStatusTag } from 'components/Common/ZComponents'
import { isNumber } from 'lodash'
import { Link } from 'react-router-dom'
import { BudgetCompact } from './BudgetCompact'
import { BudgetCompare } from './BudgetCompare'
import { BudgetRemainingCompare } from './BudgetRemainingCompare'

interface TypeMapResult {
  text: string
  colorScheme: string
  tooltip: string
}

interface TypeMap {
  [key: string]: TypeMapResult
}

export const BUDGET_HISTORY_TYPE_MAP: TypeMap = {
  CHANGED: {
    text: 'Changed',
    colorScheme: 'atomicBlue.50',
    tooltip: 'Budget was changed by an admin',
  },
  RESET: {
    text: 'Reset',
    colorScheme: 'atomicYellow.50',
    tooltip: 'Budget is reset on the 1st day of month, quarter, or year at 12am UTC',
  },
  REMAINING_REDUCED: {
    text: 'Drawdown',
    colorScheme: 'atomicRed.100',
    tooltip: 'Remaining budget was decremented due to a drawdown action (e.g. sending an item)',
  },
  REMAINING_INCREASED: {
    text: 'Refund',
    colorScheme: 'atomicPurple.50',
    tooltip: 'Remaining budget was increased due to a refund (e.g. recepient declined a gift)',
  },
  ADJUSTMENT: {
    text: 'Adjustment',
    colorScheme: 'atomicGreen.50',
    tooltip: 'Budget has been adjusted by an Admin for a single user & budget period',
  },
  UNKNOWN: { text: 'Unknown', colorScheme: 'atomicGray.50', tooltip: '' },
}

export const BUDGET_HISTORY_LIMIT = 100

export const BUDGET_HISTORY_INITIAL_STATE: GraphqlFilterState = {
  filter: {},
  orderBy: { key: 'created', direction: OrderByDirection.Descending },
  meta: {},
  limit: BUDGET_HISTORY_LIMIT,
}

export const BUDGET_HISTORY_TRANSFORMS = {
  accountId: GraphqlFilterTransform.Equal,
  teamId: GraphqlFilterTransform.Equal,
  userId: GraphqlFilterTransform.In,
  type: GraphqlFilterTransform.Equal,
}

export interface BudgetHistoryRow extends BudgetHistory {
  onItemClick: () => void
  itemName?: string
  shipToName?: string
  contactId?: string
}

export const BUDGET_HISTORY_COLUMNS: Column[] = [
  {
    key: 'created.dateTime',
    label: 'Time',
    orderBy: 'created',
    render: ({ created }: BudgetHistoryRow) => (
      <UiDateTime
        date={created?.dateTime}
        format={{ dateStyle: 'short', timeStyle: 'short' }}
      />
    ),
    rowProps: {
      minW: '155px',
    },
  },
  {
    key: 'type',
    label: 'Type',
    render: ({ type }: BudgetHistoryRow) => {
      return (
        <UiTooltip
          aria-label={BUDGET_HISTORY_TYPE_MAP[type].tooltip}
          label={BUDGET_HISTORY_TYPE_MAP[type].tooltip}
          placement="right"
          hasArrow
          shouldWrapChildren={true}
          fontFamily="Lexend"
          fontWeight="normal"
        >
          <ZStatusTag
            minW="100px"
            px={4}
            bgColor={BUDGET_HISTORY_TYPE_MAP[type].colorScheme}
            label={BUDGET_HISTORY_TYPE_MAP[type].text}
          />
        </UiTooltip>
      )
    },
  },
  {
    key: 'remaining',
    label: 'Remaining',
    render: ({ oldRemaining, amount, type, currentBudget }: BudgetHistoryRow) => {
      return (
        <BudgetRemainingCompare
          oldRemaining={oldRemaining}
          amount={amount}
          type={type}
          currentBudget={currentBudget}
        />
      )
    },
  },
  {
    key: 'amount',
    label: 'Amount',
    render: ({ amount, type }: BudgetHistoryRow) => {
      if (isNumber(amount)) {
        return (
          <>
            <Stack
              isInline
              spacing={1}
            >
              {type === BudgetHistoryType.RemainingReduced && <ZText>(</ZText>}
              <ZMoney cents={amount} />
              {type === BudgetHistoryType.RemainingReduced && <ZText>)</ZText>}
            </Stack>
          </>
        )
      } else {
        return <Text>-</Text>
      }
    },
  },
  {
    key: 'budget',
    label: 'Budget',
    render: ({ oldBudget, newBudget, currentBudget, type }: BudgetHistoryRow) => {
      switch (type) {
        case BudgetHistoryType.Changed:
          return (
            <BudgetCompare
              oldBudget={oldBudget}
              newBudget={newBudget}
              type={type}
            />
          )

        case BudgetHistoryType.RemainingIncreased:
        case BudgetHistoryType.RemainingReduced:
        case BudgetHistoryType.Reset:
          return <BudgetCompact budget={currentBudget} />

        default:
          return <ZText>-</ZText>
      }
    },
  },
  {
    key: 'item',
    label: 'Item',
    render: ({ itemName, onItemClick }: BudgetHistoryRow) => {
      if (!itemName) return null
      return (
        <ZLink onClick={onItemClick}>
          <UiTruncate
            length={30}
            text={itemName}
            showTooltip
          ></UiTruncate>
        </ZLink>
      )
    },
  },
  {
    key: 'contact',
    label: 'Contact',
    render: ({ contactId, shipToName }: BudgetHistoryRow) => {
      if (!contactId) return null
      return (
        <ZLink
          as={Link}
          to={`/contacts/${contactId}`}
          data-private
        >
          {shipToName ?? '-'}
        </ZLink>
      )
    },
  },
]
