import { Box } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ApprovedProductVariant } from 'api'
import { GetApprovedPostalDocument, GetMarketplaceProductDocument } from 'api'
import { AnalyticsEventV2, useAnalyticsEvent, useAnalyticsSend, useNavigateSendFlow } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ExtMarketplacePostalInfo } from './ExtMarketplacePostalInfo'

export const ExtMarketplacePostalPage: React.FC = () => {
  const navigate = useNavigate()

  const sendFlowLink = useNavigateSendFlow()

  const { approvedPostalId } = useParams() as any

  const approvedPostalQuery = useGraphqlQuery(
    GetApprovedPostalDocument,
    { id: approvedPostalId },
    { enabled: !!approvedPostalId }
  )

  const postal = useMemo(
    () => approvedPostalQuery.data?.getApprovedPostal,
    [approvedPostalQuery.data?.getApprovedPostal]
  )

  const getProductByIdQuery = useGraphqlQuery(
    GetMarketplaceProductDocument,
    { id: postal?.marketplaceProductId as string },
    { enabled: !!postal?.marketplaceProductId }
  )

  const bulkSendAvailable = useMemo(
    () =>
      getProductByIdQuery?.data?.getMarketplaceProduct?.variants?.some((v) =>
        v.fulfillmentPartnerList?.some((p) => p.bulkSendAvailable)
      ),
    [getProductByIdQuery?.data?.getMarketplaceProduct?.variants]
  )

  const physicalMessageSupported = useMemo(
    () => postal?.variants?.some((v) => v.physicalMessageSupported),
    [postal?.variants]
  )

  const containsPerishables = useMemo(() => postal?.variants?.some((v) => v.containsPerishables), [postal?.variants])

  const [selectedVariant, setSelectedVariant] = useState<ApprovedProductVariant | undefined>(
    postal?.variants?.length ? postal?.variants?.[0] : undefined
  )

  useEffect(() => {
    setSelectedVariant(postal?.variants?.length ? postal?.variants?.[0] : undefined)
  }, [postal])

  useAnalyticsEvent({ event: AnalyticsEventV2.ExtensionSendFlowSelectOptionStep })

  const sendAnalytics = useAnalyticsSend()

  const handleNext = () => {
    sendAnalytics({ event: AnalyticsEventV2.ExtensionSendFlowNextClicked })
    navigate(
      sendFlowLink(`/extension/marketplace/${approvedPostalId}/send`, {
        returnTo: 'Marketplace',
        variantId: selectedVariant?.id,
      })
    )
  }

  return (
    <Box pb={12}>
      <ExtMarketplacePostalInfo
        isLoading={approvedPostalQuery.isLoading || getProductByIdQuery.isLoading}
        postal={postal}
        physicalMessageSupported={physicalMessageSupported}
        containsPerishables={containsPerishables}
        bulkSendAvailable={bulkSendAvailable}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
        onNext={handleNext}
      />
    </Box>
  )
}
