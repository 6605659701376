/* eslint-disable no-template-curly-in-string */
import { sanitize } from '@postal-io/postal-ui'
import type {
  Account,
  Address,
  ApprovedPostal,
  ApprovedProductVariant,
  BudgetMode,
  FormField,
  ItemCustomizationInput,
  MagicLink,
  ProductVariant,
  SavedSend,
  UserAccount,
} from 'api'
import { MeetingRequestSetting, SendFlowStep } from 'api'
import type { MultiSelectContactsState } from 'components/MultiSelectContacts'
import type { QuickCreateContactForm } from 'components/PostalSend/PostalCustomizeBulkSend'
import { INVALID_FIELD_ANIMATION_DURATION } from 'components/PostalSend/usePostalSendFieldErrors'
import { useAutoResettingTrigger } from 'hooks/useAutoResettingTrigger'
import { camelCase, cloneDeep } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useImmer } from 'use-immer'
import { AUTOMATION_TYPES } from './data'
import {
  canHaveRecipientNotifications,
  canToggleGiftEmail,
  checkForErrors,
  defaultCampaignName,
  defaultLinkName,
  getActiveVariants,
  getFinalStepLabel,
  getLandingStep,
  getMaxBulkSendQuantity,
  giftEmailRequired,
  pageTitles,
  physicalMessageRequired,
  PostalSendMethod,
  PostalSendType,
  SendAsType,
  shouldHaveContactsStep,
  shouldHaveCustomizeStep,
  shouldHaveItemStep,
  STARTING_BULK_SEND_QUANTITY,
} from './postalSendHelpers'

export * from './postalSendHelpers'

/**
 * Database of actions pulled from send machine. Turns out it's convenient to keep it this way
 * since we can extract a lot of the business logic out of the hook and even into a different file if we wanted to
 */

// an action function will accept a current state, options, and return an object of fields to update
type PostalSendAction = (ctx: PostalSendContext, options?: any) => Partial<PostalSendContext>

// the assign function converts an action function into something we can plug into immer
type AssignActionFunction = (fn: PostalSendAction) => (options: any) => void

const actionList = (assign: AssignActionFunction) => ({
  /**
   * Complex actions
   */

  // The purpose of this function is to use the postalDeliveryEmailSetings
  // set on the account to modify the initial context as well as setup some
  // additional defaults
  loadAccountData: assign((ctx, { data: { account, me } }) => {
    const newContext = cloneDeep(ctx) as any

    // set default email settings
    newContext.postalDeliveryEmailSetting =
      account?.postalDeliveryEmailSetting || ctx.postalDeliveryEmailSetting || 'DEFAULT_ON'

    if (!me.meetingSettings?.enabled) {
      newContext.meetingRequestSetting = MeetingRequestSetting.No
    } else if (!ctx.meetingRequestSetting) {
      newContext.meetingRequestSetting = me.meetingSettings?.meetingRequestDefault || MeetingRequestSetting.No
    }

    if (newContext.deliveryEmail === false || newContext.deliveryEmail === true) {
      // allow passed in values to flow through
    } else if (giftEmailRequired(newContext)) {
      newContext.deliveryEmail = true
    } else if (newContext.giftMessage) {
      newContext.deliveryEmail = true
    } else {
      // set default based on account settings
      switch (newContext.postalDeliveryEmailSetting) {
        case 'ALWAYS':
        case 'DEFAULT_ON':
          newContext.deliveryEmail = true
          break
        case 'NEVER':
        case 'DEFAULT_OFF':
          newContext.deliveryEmail = false
          break
        default:
      }
    }

    // set usePhysicalMessage if we have physical message
    if (newContext.physicalMessage || physicalMessageRequired(newContext)) {
      newContext.usePhysicalMessage = true
    }

    // This is when we are retrying a direct mail that was using the old giftMessage param
    // at some point we can remove this logic
    if (physicalMessageRequired(newContext) && newContext.giftMessage && !newContext.physicalMessage) {
      newContext.physicalMessage = newContext.giftMessage
      newContext.giftMessage = undefined
    }

    // If pre-selected contacts are sent in, set this to state, we are emulating the useMultiSelect
    // state here in case we are skipping contacts later
    if (ctx.contacts?.items?.length || ctx.contacts?.filters?.length) {
      const items = ctx.contacts?.items || []
      const filters = ctx.contacts?.filters || []
      const totalRecords = filters.reduce((sum, cur) => sum + cur.totalRecords, items.length)
      const orfilters = []
      if (items.length) orfilters.push({ id: { in: items.map((i) => i.id) } })
      if (filters.length) filters.forEach((f) => orfilters.push(f.filter))
      newContext.contacts = { items, filters, totalRecords, orfilters }
    }

    // mark this as a campaign if we have more than 1 contact or the correct type
    if (ctx.type === PostalSendType.Campaign || (newContext.contacts?.totalRecords || 0) > 1) {
      newContext.isCampaign = true
      newContext.name = ctx.name || defaultCampaignName(ctx)
    }

    // setup link defaults
    if (ctx.method === PostalSendMethod.Link) {
      newContext.name = ctx.name || defaultLinkName(ctx)
      newContext.maxExecutions = ctx.maxExecutions || 1
      newContext.enabled = ctx.enabled !== false
    }

    newContext.landingPageIncludeHeadshot = ctx.landingPageIncludeHeadshot !== false
    newContext.landingPageIncludeSenderName = ctx.landingPageIncludeSenderName !== false

    // setup playbook defaults
    if (ctx.type === PostalSendType.PlaybookStep) {
      newContext.delay = ctx.delay ?? 0
    }

    // check to be sure that variant is actually still available or
    // pick one if not provided
    if (ctx.postal) {
      newContext.variant = ctx.postal?.variants?.find((v: ApprovedProductVariant) => v.id === ctx.variant?.id)
      if (!newContext.variant) {
        const [firstVariant] = getActiveVariants(ctx.postal)
        newContext.variant = firstVariant
      }
    }

    // import original form field list attached to postal into state
    if (ctx.postal?.formFieldList?.length) {
      newContext.formFieldList = cloneDeep(ctx.postal.formFieldList)
    }

    if (ctx.sendAsUser) {
      newContext.sendAsType = SendAsType.User
    } else if (ctx.sendAsContactOwner || ctx.type === PostalSendType.Trigger) {
      newContext.sendAsType = SendAsType.ContactOwner
      newContext.sendAsContactOwner = true
    } else {
      newContext.sendAsType = SendAsType.Self
    }

    // Bulk send starting quantity - only used for bulk sends
    newContext.quantity = ctx.quantity ?? STARTING_BULK_SEND_QUANTITY

    return newContext
  }),

  setContacts: assign((ctx, { data }) => {
    if (!data) return {}
    const { method, type, postal } = ctx
    const newContext: PostalSendContext = { type, method, postal }
    // derive whatever contact info we need
    newContext.contacts = data as MultiSelectContactsState

    // set campaign data if needed
    const isCampaign = (newContext.contacts.totalRecords || 0) > 1 || ctx.type === PostalSendType.Campaign
    if (!AUTOMATION_TYPES.includes(ctx.type)) {
      newContext.isCampaign = isCampaign
      if (isCampaign) newContext.name = ctx.name || defaultCampaignName(ctx)
    }
    return newContext
  }),
  setPostal: assign((ctx, { data }) => {
    if (!data) return {}
    const newContext: PostalSendContext = { type: ctx.type, method: ctx.method, postal: data }

    if (giftEmailRequired(newContext)) {
      newContext.deliveryEmail = true
    }

    if (physicalMessageRequired(newContext)) {
      newContext.usePhysicalMessage = true
    }

    // check to be sure that variant is actually still available or
    // pick one if not provided
    newContext.variant = data?.variants?.find((v: ApprovedProductVariant) => v.id === ctx.variant?.id)
    if (!newContext.variant) {
      const [firstVariant] = getActiveVariants(data)
      newContext.variant = firstVariant
    }

    return newContext
  }),
  setUsePhysicalMessage: assign((ctx, { data }) => {
    const { method, type, postal } = ctx
    const newContext: PostalSendContext = { usePhysicalMessage: data, type, method, postal }
    if (newContext.usePhysicalMessage) {
      newContext.useSameMessage = !ctx.physicalMessage
      newContext.physicalMessage = newContext.useSameMessage ? ctx.giftMessage : ctx.physicalMessage
    } else {
      newContext.useSameMessage = false
      newContext.physicalMessage = undefined
    }
    return newContext
  }),
  setSendAs: assign((_, { data }) => {
    const { sendAsType, sendAsUser } = data
    switch (sendAsType) {
      case SendAsType.Self:
        return { sendAsType, sendAsUser: undefined, sendAsContactOwner: false }
      case SendAsType.ContactOwner:
        return { sendAsType, sendAsUser: undefined, sendAsContactOwner: true }
      case SendAsType.User:
        return { sendAsType, sendAsUser, sendAsContactOwner: false }
      default:
        return { sendAsType: SendAsType.User, sendAsUser: undefined, sendAsContactOwner: false }
    }
  }),
  setSpendAsTeamId: assign((_, { data }) => {
    return {
      spendAsTeamId: data.teamId,
      spendAsTeamName: data.teamName,
      spendAsTeamBudget: data.teamBudget,
      spendAsTeamBudgetMode: data.teamBudgetMode,
      spendAsTeamBalance: data.teamBalance,
    }
  }),
  setSpendAsUserId: assign((_, { data }) => {
    return {
      spendAsUserId: data.userId,
      ...(!data.userId
        ? {
            spendAsTeamId: undefined,
            spendAsTeamName: undefined,
            spendAsTeamBudget: undefined,
            spendAsTeamBalance: undefined,
          }
        : {}),
    }
  }),
  setSendMethod: assign((ctx, { data }) => {
    const newContext = {} as any

    // setup link defaults
    if (data === PostalSendMethod.Link) {
      newContext.name = ctx.name || defaultLinkName(ctx)
      newContext.maxExecutions = ctx.maxExecutions || 1
      newContext.enabled = ctx.enabled !== false
    }

    newContext.landingPageIncludeHeadshot = ctx.landingPageIncludeHeadshot !== false
    newContext.landingPageIncludeSenderName = ctx.landingPageIncludeSenderName !== false

    if (data === PostalSendMethod.BulkSend) {
      // Bulk send starting quantity - only used for bulk sends
      newContext.quantity = ctx.quantity ?? STARTING_BULK_SEND_QUANTITY
    }

    // Recipient notifications default to on.
    if (canHaveRecipientNotifications(ctx)) {
      newContext.shippedEmailsOn = ctx.shippedEmailsOn ?? true
      newContext.deliveredEmailsOn = ctx.deliveredEmailsOn ?? true
    }

    return {
      ...newContext,
      method: data,
      deliveryEmail: data === PostalSendMethod.Email,
      useSameMessage: data === PostalSendMethod.Email && ctx.useSameMessage,
      // reset furthest step when send flow changes
      // furthestStep: SendFlowStep.ChooseMethod,
    }
  }),
  setUseSameMessage: assign((ctx, { data: useSameMessage }) => {
    return {
      useSameMessage,
      physicalMessage: useSameMessage ? ctx.giftMessage : ctx.physicalMessage,
    }

    // Not sure if we need this
    // return {
    //   useSameMessage,
    //   physicalMessage: useSameMessage
    //     ? ctx.landingPageBody
    //       ? stripHTML(ctx.landingPageBody)
    //       : ctx.giftMessage
    //     : ctx.physicalMessage,
    // }
  }),
  setUseDeliveryEmail: assign((ctx, { data }) => {
    return {
      deliveryEmail: canToggleGiftEmail(ctx) ? data : ctx.deliveryEmail,
      useSameMessage: !data ? false : ctx.useSameMessage,
    }
  }),
  updateFurthestStep: assign((ctx, { data: { steps, nextStep, forceUpdate } }) => {
    const nextStepIsFurtherAlong = steps?.indexOf(nextStep) > steps?.indexOf(ctx.furthestStep)
    return { furthestStep: nextStepIsFurtherAlong || forceUpdate ? nextStep : ctx.furthestStep }
  }),
  setGiftMessage: assign((ctx, { data }) => {
    return {
      giftMessage: data,
      physicalMessage: ctx.useSameMessage ? data : ctx.physicalMessage,
    }
  }),
  setQuantity: assign((ctx, { data }) => {
    return {
      // allow undefined & 1 to let the user delete & type in a quantity - we will throw an error if quantity < MIN_BULK_SEND_QUANTITY
      quantity: data === undefined ? undefined : Math.min(Math.max(parseInt(data), 1), getMaxBulkSendQuantity(ctx)),
    }
  }),
  setPhysicalMessage: assign((_ctx, { data }) => {
    return { physicalMessage: data, useSameMessage: !data }
  }),
  setShipToAddress: assign((_ctx, { data }) => {
    return { shipToAddress: data, verifiedShipToAddress: false }
  }),
  setShipToAddressVerified: assign((_ctx, { data }) => {
    return { shipToAddress: data, verifiedShipToAddress: true }
  }),

  /**
   * Basic beezy actions - First Immer candidates
   */

  setMaxExecutions: assign((_ctx, { data }) => {
    return { maxExecutions: !data ? undefined : Number(data) }
  }),
  setMaxCharacters: assign((_ctx, { data }) => {
    return { maxPhysicalCharacters: !data ? undefined : Number(data) }
  }),
  setMeetingRequest: assign((_ctx, { data }) => {
    return { meetingRequestSetting: data }
  }),
  setEnabled: assign((_ctx, { data }) => {
    return { enabled: Boolean(data) }
  }),
  setDelay: assign((_, { data }) => {
    return { delay: data < 0 ? 0 : data }
  }),
  sanitizeData: assign((ctx) => {
    return { giftMessage: ctx.giftMessage ? sanitize(ctx.giftMessage) : ctx.giftMessage }
  }),
  setEmailSubjectLine: assign((_, { data }) => {
    return { emailSubjectLine: data }
  }),
  setShippedEmail: assign((_, { data }) => {
    return { shippedEmailsOn: data }
  }),
  setDeliveredEmail: assign((_, { data }) => {
    return { deliveredEmailsOn: data }
  }),
  setLandingPageHeaderText: assign((_, { data }) => {
    return { landingPageHeaderText: data }
  }),
  setLandingPageBody: assign((_, { data }) => {
    return { landingPageBody: data }
  }),
  setLandingPageIncludeHeadshot: assign((_, { data }) => {
    return { landingPageIncludeHeadshot: data }
  }),
  setLandingPageIncludeSenderName: assign((_, { data }) => {
    return { landingPageIncludeSenderName: data }
  }),
  setLinkNeedsApproval: assign((_, { data }) => {
    return { linkNeedsApproval: data }
  }),
  setCustomizeItem: assign((_, { data }) => {
    return { itemCustomizationInputs: data }
  }),
  setFormFields: assign((_, { data }) => {
    return { formFieldList: data }
  }),
  setName: assign((_ctx, { data }) => {
    return { name: data }
  }),
  setDate: assign((_ctx, { data }) => {
    return { date: data }
  }),
  setVariant: assign((_ctx, { data }) => {
    return { variant: data }
  }),
  setParentVariant: assign((_ctx, { data }) => {
    return { parentVariant: data }
  }),
  setNewContact: assign((_ctx, { data }) => {
    return { newContact: data }
  }),
})

export interface ContactId {
  id: string
  [key: string]: unknown
}
export interface PostalSendContext {
  type: PostalSendType
  method: PostalSendMethod
  restrictedMethods?: PostalSendMethod[]
  postal: ApprovedPostal
  variant?: ApprovedProductVariant
  // for more variant info
  parentVariant?: ProductVariant
  // if we're editing a draft
  draft?: SavedSend
  // if we're editing a link
  link?: MagicLink
  name?: string
  date?: any
  enabled?: boolean
  maxPhysicalCharacters?: number
  maxExecutions?: number
  // bulk send
  shipToAddress?: Address | null
  verifiedShipToAddress?: boolean
  quantity?: number
  newContact?: QuickCreateContactForm
  // gift emails & messages
  deliveryEmail?: boolean | null
  emailSubjectLine?: string | null
  giftMessage?: string | null
  landingPageHeaderText?: string | null
  landingPageBody?: string | null
  landingPageIncludeHeadshot?: boolean
  landingPageIncludeSenderName?: boolean
  postalDeliveryEmailSetting?: string
  // gift emails & links
  formFieldList?: FormField[] | null
  linkNeedsApproval?: boolean | null
  // physical messages
  physicalMessageSupported?: boolean
  usePhysicalMessage?: boolean
  physicalMessage?: string | null
  useSameMessage?: boolean
  contacts?: Partial<MultiSelectContactsState>
  // playbooks
  delay?: number
  playbookStepIdx?: number
  // campaigns
  isCampaign?: boolean
  sendAsType?: SendAsType
  sendAsUser?: string | null
  sendAsContactOwner?: boolean | null
  meetingRequestSetting?: MeetingRequestSetting | null
  itemCustomizationInputs?: ItemCustomizationInput[] | null
  // spend as
  spendAsTeamId?: string
  spendAsTeamName?: string
  spendAsTeamBudget?: number
  spendAsTeamBudgetMode?: BudgetMode
  spendAsTeamBalance?: number
  spendAsUserId?: string
  // furthest step the user has been to - used to recall last step in a draft
  furthestStep?: SendFlowStep
  // shipped/delivered emails
  shippedEmailsOn?: boolean
  deliveredEmailsOn?: boolean
  // errors
  errorMessage?: string
  fieldWithError?: string
  highlightFieldWithError?: boolean
}

interface usePostalSendArgs {
  initialContext: PostalSendContext
  stepToRecall?: SendFlowStep | null
  loadAccount: () => Promise<{ account: Account; me: UserAccount }>
}
export function usePostalSend({ loadAccount, stepToRecall, initialContext }: usePostalSendArgs) {
  const [context, setContext] = useImmer<PostalSendContext>(initialContext ?? null)
  const [step, setStep] = useState<SendFlowStep>(SendFlowStep.ChooseMethod)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /**
   * Business logic
   */
  const assign: AssignActionFunction = useCallback(
    (action) => (options) => {
      const newStateOverrides = action(context, options)
      setContext((ctx) => ({ ...ctx, ...newStateOverrides }))
    },
    [setContext, context]
  )

  const actions = useMemo(() => actionList(assign), [assign])

  /**
   * Step logic
   */
  const steps = useMemo(() => {
    const s = []
    if (shouldHaveContactsStep(context)) s.push(SendFlowStep.ContactSelection)
    if (shouldHaveCustomizeStep(context)) s.push(SendFlowStep.SendCustomization)
    if (shouldHaveItemStep(context)) s.push(SendFlowStep.ItemCustomization)
    s.push(SendFlowStep.OrderPreview)
    return s
  }, [context])

  const stepIndex = steps.indexOf(step)
  const nextStep = useMemo(() => steps[stepIndex + 1] ?? null, [steps, stepIndex])
  const prevStep = useMemo(() => steps[stepIndex - 1], [steps, stepIndex])

  const next = useCallback(() => {
    setStep(nextStep!)
    actions.updateFurthestStep({ data: { steps, nextStep } })
  }, [steps, actions, nextStep, setStep])
  const prev = useCallback(() => setStep(prevStep), [setStep, prevStep])

  // reset to first step of new method whenever user selects different method
  useEffect(() => {
    setStep(steps[0])
    actions.updateFurthestStep({ data: { nextStep, forceUpdate: true } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.method])

  /**
   * Errors
   */
  const [highlightFieldWithError, setHighlightFieldWithError] = useAutoResettingTrigger(
    INVALID_FIELD_ANIMATION_DURATION
  )

  const error = useMemo(() => {
    const key = step as keyof typeof checkForErrors
    const error = checkForErrors[key]?.(context)
    if (!error) setHighlightFieldWithError(false)
    else return error
  }, [step, context, setHighlightFieldWithError])

  /**
   * API
   */
  const send = useCallback(
    (args: any) => {
      if (args === 'NEXT') return next()
      if (args === 'BACK') return prev()
      if (args?.type === 'HIGHLIGHT_FIELD_WITH_ERROR') return setHighlightFieldWithError(true)
      const key = camelCase(args.type) as keyof typeof actions
      actions[key](args)
    },
    [actions, next, prev, setHighlightFieldWithError]
  )

  /**
   *  Recall send step from drafts
   */
  const [stepRecalled, setStepRecalled] = useState<boolean>(false)
  useEffect(() => {
    if (!stepRecalled && !isLoading && stepToRecall) {
      setStepRecalled(true)
      const recalledStep = getLandingStep(context, steps, stepToRecall)
      setStep(recalledStep)
    }
  }, [stepRecalled, context, setStep, steps, step, isLoading, stepToRecall])

  /**
   *  Loading account info & initializing send flow
   */
  const [initialized, setInitialized] = useState<boolean>(false)
  useEffect(() => {
    ;(async function () {
      if (!initialized) {
        setInitialized(true)
        const accountInfo = await loadAccount()
        actions.loadAccountData({ data: accountInfo })
        actions.setSendMethod({ data: context.method })
        setStep(steps[0])
        setIsLoading(false)
      }
    })()
  }, [step, steps, setStep, loadAccount, send, actions, initialized, context])

  return {
    state: {
      context: { ...context, errorMessage: error?.message, fieldWithError: error?.field, highlightFieldWithError },
      isLoading,
      value: step,
      step,
      stepIndex,
      nextStep,
      prevStep,
      nextStepTitle: nextStep === null ? getFinalStepLabel(context) : pageTitles[nextStep],
      prevStepTitle: pageTitles[prevStep],
    },
    send,
    actions,
  }
}
