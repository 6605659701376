import { SimpleGrid } from '@chakra-ui/react'
import { ZSidebar, ZSidebarBanner } from '@postal-io/postal-ui'
import { CenteredBox } from 'components/Common'
import { RecipientNotifications } from 'components/Notifications/V2/RecipientNotifications'
import { PageTitle } from 'hooks'
import React from 'react'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

export const EmailSettings: React.FC = () => {
  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner title="General" />
        <PageTitle title="General" />
        <SimpleGrid
          spacing={8}
          columns={{ base: 1, lg: 2 }}
        >
          <RecipientNotifications />
        </SimpleGrid>
      </ZSidebar>
    </CenteredBox>
  )
}
