import { FormControl, SimpleGrid } from '@chakra-ui/react'
import { ZInput } from '@postal-io/postal-ui'
import type { ContactInput, Tag } from 'api'
import { PhoneType } from 'api'
import { AutoCompleteTagsCreatable } from 'components/AutoComplete'
import { zAutocompleteStyles, ZFormLabel } from 'components/Common/ZComponents'
import React from 'react'

export interface ContactFormProps {
  form: ContactInput
  handleSubmit: (e: React.FormEvent) => void
  setForm: Function
  setTags: (tags: Tag[] | ((prev: any) => any[])) => void
  tags: any
}

export const ContactForm: React.FC<ContactFormProps> = ({ form, handleSubmit, setForm, setTags, tags }) => {
  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target
    setForm((draft: Record<string, any>) => void (draft[name] = value))
  }

  const handlePhoneInput = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value } = target
    setForm((draft: any) => {
      draft.phones = draft.phones || []
      if (draft.phones[0]) {
        draft.phones[0].phoneNumber = value
      } else {
        draft.phones.push({ phoneNumber: value, type: PhoneType.Work })
      }
    })
  }

  return (
    <form
      id="edit-contact-form"
      onSubmit={handleSubmit}
    >
      <SimpleGrid
        columns={2}
        spacing={4}
        mt={30}
      >
        <FormControl
          id="firstName"
          isRequired
        >
          <ZFormLabel fontWeight="normal">First Name</ZFormLabel>
          <ZInput
            value={form.firstName || ''}
            name="firstName"
            onChange={handleInput}
            data-private
          />
        </FormControl>
        <FormControl
          id="lastName"
          isRequired
        >
          <ZFormLabel fontWeight="normal">Last Name</ZFormLabel>
          <ZInput
            value={form.lastName || ''}
            name="lastName"
            onChange={handleInput}
            data-private
          />
        </FormControl>
      </SimpleGrid>
      <FormControl
        id="title"
        mt={4}
      >
        <ZFormLabel fontWeight="normal">Title</ZFormLabel>
        <ZInput
          value={form.title || ''}
          name="title"
          onChange={handleInput}
        />
      </FormControl>
      <SimpleGrid
        columns={2}
        spacing={4}
        mt={4}
      >
        <FormControl
          id="emailAddress"
          isRequired
        >
          <ZFormLabel fontWeight="normal">Email</ZFormLabel>
          <ZInput
            type="email"
            value={form.emailAddress || ''}
            name="emailAddress"
            onChange={handleInput}
            data-private
          />
        </FormControl>
        <FormControl id="phoneNumber">
          <ZFormLabel fontWeight="normal">Phone</ZFormLabel>
          <ZInput
            value={form.phones?.[0]?.phoneNumber || ''}
            name="phoneNumber"
            onChange={handlePhoneInput}
          />
        </FormControl>
        <FormControl id="companyName">
          <ZFormLabel fontWeight="normal">Company</ZFormLabel>
          <ZInput
            name="companyName"
            value={form.companyName || ''}
            onChange={handleInput}
          />
        </FormControl>
        <FormControl id="tags">
          <ZFormLabel fontWeight="normal">Tags</ZFormLabel>
          <AutoCompleteTagsCreatable
            styles={zAutocompleteStyles}
            inputId="tags"
            value={tags}
            onChange={(tags) => setTags([...tags])}
            onCreate={(newTag: any) => setTags((prev) => [...prev, newTag])}
          />
        </FormControl>
      </SimpleGrid>
    </form>
  )
}
