import type { Column } from '@postal-io/postal-ui'
import { GraphqlFilterTransform, humanize, UiCheckboxIcon, UiDate, ZLink } from '@postal-io/postal-ui'
import type { IntegrationTrigger } from 'api'
import { TriggerAction } from 'api'
import { UserLinkLink } from 'components/Common'
import { Link } from 'react-router-dom'
import { SystemLink } from '../Contact'

export const transforms = {
  systemName: GraphqlFilterTransform.Equal,
  status: GraphqlFilterTransform.Equal,
  name: GraphqlFilterTransform.Contains,
}

const displayAction = (action: string) => {
  switch (action) {
    case TriggerAction.StartPlaybook:
      return 'Start Subscription'
    case TriggerAction.StopPlaybook:
      return 'Stop Subscription'
    default:
      return humanize(action)
  }
}

export const columns: Column[] = [
  {
    label: 'Name',
    render: ({ name, id }: IntegrationTrigger) => {
      return (
        <ZLink
          color="atomicBlue.400"
          to={`/triggers/${id}`}
          as={Link}
        >
          {name}
        </ZLink>
      )
    },
  },
  {
    label: 'Owner',
    render: ({ userId, userLink }: IntegrationTrigger) => (
      <UserLinkLink
        color="atomicBlue.400"
        userId={userId}
        userLink={userLink}
      />
    ),
  },
  {
    label: 'System',
    render: ({ systemName }: IntegrationTrigger) => (
      <SystemLink
        system={systemName}
        w="60px"
        h="auto"
      />
    ),
  },
  {
    label: 'Enabled',
    render: ({ status }: IntegrationTrigger) => <UiCheckboxIcon isChecked={status === 'ACTIVE'} />,
  },
  {
    key: 'action',
    label: 'Action',
    render: ({ action }: IntegrationTrigger) => displayAction(action),
  },
  {
    key: 'created.dateTime',
    label: 'Created',
    render: ({ created }: IntegrationTrigger) => <UiDate date={created.dateTime} />,
  },
  {
    key: 'hitCount',
    label: 'Hit Count',
  },
]
