import { Box } from '@chakra-ui/react'
import { ZText } from '@postal-io/postal-ui'
import React from 'react'

interface CenterValueV2Props {
  value: string
}
export const CenterValue: React.FC<CenterValueV2Props> = ({ value }) => (
  <Box
    position="absolute"
    top={2}
    bottom={0}
    left={0}
    right={0}
    display="flex"
    flexDir="column"
    justifyContent="center"
    textAlign="center"
  >
    <ZText
      fontSize={{ base: '5vw', lg: '2vw' }}
      fontWeight="normal"
      color="atomicGray.700"
      opacity="0.7"
    >
      {value}
    </ZText>
  </Box>
)
