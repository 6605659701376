import { ViewIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  Grid,
  HStack,
  Stack,
  Text,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  sanitize,
  SelectTypeaheadStylesV2,
  UiCard,
  UiIconButton,
  UiTag,
  useAlerts,
  useImmerWithMemory,
  ZCheckbox,
  ZFormLabel,
  ZHeading,
  ZInput,
  ZLink,
  ZTextarea,
} from '@postal-io/postal-ui'
import type { ApprovedPostalUpdateInput, Attribution, FormFieldInput, MagicEventInstanceInput, SpendAs } from 'api'
import {
  GetApprovedPostalDocument,
  GetMarketplaceProductDocument,
  MagicEventStatus,
  PriceStructure,
  UpdateApprovedPostalDocument,
} from 'api'
import { ConfirmClose } from 'components/Common/ConfirmClose'
import { PostalEventCustomFields } from 'components/Postal'
import { POSTAL_INVALIDATIONS, useAcl, useApprovedPostalVersion, useBackgroundQueue, useSession } from 'hooks'
import { get, set } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AutoCompleteTeams } from '../AutoComplete'
import { ZInfoTooltip } from '../Common/ZComponents'
import { AttributionSelect } from '../Integrations/AttributionSelect'
import { CustomEmail } from '../Postal/customEmailData'
import { CustomizeEmailMessaging } from '../Postal/CustomizeEmailMessaging'
import { CustomizeEmailMessagingPreview } from '../Postal/CustomizeEmailMessagingPreview'
import { EventsSpendAs } from './EventsSpendAs'

const SPEND_AS_STATUSES: any[] = [
  MagicEventStatus.PendingConfirmation,
  MagicEventStatus.ConfirmedNeedsData,
  MagicEventStatus.ConfirmedBillingIssue,
]

export const EventsApprovedPostalEdit: React.FC = () => {
  const transform = useApprovedPostalVersion()
  const previewDisclosure = useDisclosure()
  const disclosure = useDisclosure({ defaultIsOpen: true })
  const confirmationDisclosure = useDisclosure()
  const customEmailMessagingDisclosure = useDisclosure()
  const [emailType, setEmailType] = useState<CustomEmail>(CustomEmail.Confirmation)
  const loadingAlertDialog = useDisclosure()
  const customFieldDisclosure = useDisclosure()
  const { hasFeature } = useAcl()
  const { session } = useSession()
  const navigate = useNavigate()
  const cancelRef = useRef(null)

  // if there is a teamId in session, this team admin cannot assign teams
  const hasTeamSelect = hasFeature('teamPostals') && !session.teamId

  const hasCustomFields = hasFeature('customForms')
  const hasEventEmails = hasFeature('eventEmails')
  const hasBudgetDropDown = hasFeature('budgetDropDown')

  const { approvedPostalId } = useParams() as any

  const Alert = useAlerts()

  const approvedPostalQuery = useGraphqlQuery(GetApprovedPostalDocument, { id: approvedPostalId })
  const postal = useMemo(
    () => approvedPostalQuery.data?.getApprovedPostal,
    [approvedPostalQuery.data?.getApprovedPostal]
  )
  const isRegistrationClosed = postal?.event?.status === MagicEventStatus.RegistrationClosed

  const getProductQuery = useGraphqlQuery(
    GetMarketplaceProductDocument,
    { id: postal?.marketplaceProductId as string },
    { enabled: !!postal?.marketplaceProductId }
  )
  const eventDetails = useMemo(
    () => getProductQuery.data?.getMarketplaceProduct.eventDetails,
    [getProductQuery.data?.getMarketplaceProduct.eventDetails]
  )

  const { state: form, setState: setForm, resetState, isChanged } = useImmerWithMemory<ApprovedPostalUpdateInput>({})

  useEffect(() => {
    if (!postal) return
    resetState({
      name: postal.name,
      displayName: postal.displayName || postal.name,
      description: postal.description,
      teamIds: postal.teamIds || [],
      attribution: postal.attribution,
      formFieldList: postal.formFieldList || [],
      event: {
        requestedByName: postal.event?.requestedByName || '',
        requestedByEmail: postal.event?.requestedByEmail || '',
        requestedByPhone: postal.event?.requestedByPhone || '',
        requestedAttendeeCount: postal.event?.requestedAttendeeCount || 1,
        requestedByMessage: postal.event?.requestedByMessage || '',
        meetingLink: postal.event?.meetingLink,
        sendEmailConfirmation: postal?.event?.sendEmailConfirmation,
        sendReminderDayBefore: postal?.event?.sendReminderDayBefore,
        sendReminderDayOf: postal?.event?.sendReminderDayOf,
        sendCancelledAlert: postal?.event?.sendCancelledAlert,
        sendInviteExpiringAlert: postal?.event?.sendInviteExpiringAlert,
        sendMeetingLinkChanged: postal?.event?.sendMeetingLinkChanged,
        sendDateTimeChanged: postal?.event?.sendDateTimeChanged,
        sendShippedAlert: postal?.event?.sendShippedAlert,
        sendDeliveredAlert: postal?.event?.sendDeliveredAlert,
        confirmationCustomization: {
          subjectLine: postal?.event?.confirmationCustomization?.subjectLine,
          body: postal?.event?.confirmationCustomization?.body,
        },
        reminderTomorrowCustomization: {
          subjectLine: postal?.event?.reminderTomorrowCustomization?.subjectLine,
          body: postal?.event?.reminderTomorrowCustomization?.body,
        },
        reminderTodayCustomization: {
          subjectLine: postal?.event?.reminderTodayCustomization?.subjectLine,
          body: postal?.event?.reminderTodayCustomization?.body,
        },
        cancellationCustomization: {
          subjectLine: postal?.event?.cancellationCustomization?.subjectLine,
          body: postal?.event?.cancellationCustomization?.body,
        },
        meetingLinkChangedCustomization: {
          subjectLine: postal?.event?.meetingLinkChangedCustomization?.subjectLine,
          body: postal?.event?.meetingLinkChangedCustomization?.body,
        },
        dateTimeChangedCustomization: {
          subjectLine: postal?.event?.dateTimeChangedCustomization?.subjectLine,
          body: postal?.event?.dateTimeChangedCustomization?.body,
        },
      },
      eventFeeSettings: postal?.eventFeeSettings,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postal])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target

    switch (name) {
      case 'meetingLink':
      case 'requestedAttendeeCount':
        setForm((draft: Record<string, any>) => void (draft.event[name] = value))
        break
      default:
        setForm((draft: Record<string, any>) => void (draft[name] = value))
    }
  }

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setForm((draft: Record<string, any>) => {
      draft.event[name] = checked
    })
  }

  const handleAttribution = (attribution: Attribution) => {
    setForm((draft: Record<string, any>) => {
      draft.attribution = attribution
    })
  }

  const { queue, invalidate } = useBackgroundQueue()
  const updatePostal = useGraphqlMutation(UpdateApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.updateApprovedPostal.previewGenerationTask)
      invalidate(POSTAL_INVALIDATIONS)
    },
  })

  const onCloseConfirm = () => {
    disclosure.onClose()
    navigate(`/events/postals/${approvedPostalId}`)
  }

  const onCloseAttempt = () => {
    if (isChanged) confirmationDisclosure.onOpen()
    else onCloseConfirm()
  }

  const handleCustomFieldConfirm = (fieldInfo: FormFieldInput[]) => {
    setForm((draft) => void (draft.formFieldList = fieldInfo))
    customFieldDisclosure.onClose()
  }

  const sanitizeProp = (data: any, prop: string) => {
    set(data, prop, sanitize(get(data, prop, '')).trim())
  }

  const handleSubmit = async (e: React.FormEvent, callback?: any) => {
    e.preventDefault()

    if (!postal) return

    const data = cloneDeep(form) as any
    sanitizeProp(data, 'event.confirmationCustomization.body')
    sanitizeProp(data, 'event.reminderTomorrowCustomization.body')
    sanitizeProp(data, 'event.reminderTodayCustomization.body')
    sanitizeProp(data, 'event.cancellationCustomization.body')
    sanitizeProp(data, 'event.reminderTodayCustomization.body')
    sanitizeProp(data, 'event.dateTimeChangedCustomization.body')

    try {
      loadingAlertDialog.onOpen()
      await updatePostal.mutateAsync({ id: postal.id, data: { ...data, ...transform(postal.category) } })
      Alert.success(`Event Updated`)
      loadingAlertDialog.onClose()
      callback?.()
    } catch (err) {
      loadingAlertDialog.onClose()
      Alert.error(err)
    }
  }

  const handleMessagingSubmit = (event: MagicEventInstanceInput) => {
    setForm((draft) => void (draft.event = event))
    customEmailMessagingDisclosure.onClose()
  }

  const handleEventSubmit = async (e: React.FormEvent) => {
    handleSubmit(e, () => navigate(`/events/postals/${approvedPostalId}`))
  }

  const handlePreview = (e: React.MouseEvent<any>, emailType: CustomEmail) => {
    e.preventDefault()
    setEmailType(emailType)
    previewDisclosure.onOpen()
  }

  const handleSpendAs = ({ userId, teamId }: SpendAs) => {
    setForm((draft: Record<string, any>) => {
      set(draft, 'eventFeeSettings.flatFee.userId', userId)
      set(draft, 'eventFeeSettings.flatFee.teamId', teamId)
    })
  }

  // to show / hide spendAs field
  const canUpdateSpendAs = useMemo(
    () =>
      hasBudgetDropDown &&
      getProductQuery?.data?.getMarketplaceProduct?.variants?.some(
        (v) =>
          v.fulfillmentPartnerList?.some((f) => f.priceStructure === PriceStructure.FlatFee) &&
          SPEND_AS_STATUSES.includes(postal?.event?.status)
      ),
    [getProductQuery?.data?.getMarketplaceProduct?.variants, hasBudgetDropDown, postal?.event?.status]
  )

  if (!postal) return null

  return (
    <>
      <AlertDialog
        size="3xl"
        isOpen={disclosure.isOpen}
        onClose={onCloseAttempt}
        closeOnEsc
        closeOnOverlayClick
        blockScrollOnMount={false}
        isCentered
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogCloseButton />
          <AlertDialogHeader>{postal?.name}</AlertDialogHeader>
          <AlertDialogBody>
            <form
              id="PostalEventEdit_form"
              onSubmit={handleEventSubmit}
            >
              <Stack
                spacing={8}
                maxW="650px"
                mx="auto"
              >
                <FormControl
                  id="name"
                  isRequired
                >
                  <ZFormLabel data-testid="event-name">Name</ZFormLabel>
                  <ZInput
                    name="name"
                    value={form.name || ''}
                    onChange={handleInput}
                  />
                </FormControl>
                <FormControl>
                  <ZFormLabel id="displayName">
                    Display Name
                    <ZInfoTooltip label="This name is included in your event invites, the landing page, and in the calendar hold" />
                  </ZFormLabel>
                  <ZInput
                    id="postal-displayName"
                    aria-label="Display Name"
                    name="displayName"
                    type="text"
                    isRequired
                    value={form.displayName || ''}
                    onChange={handleInput}
                  />
                </FormControl>
                <FormControl
                  id="meetingLink"
                  isRequired
                >
                  <ZFormLabel>
                    Meeting/Event Access Link or Location
                    <ZInfoTooltip label="The link to your virtual meeting (such as a Zoom link)" />
                  </ZFormLabel>
                  <ZInput
                    name="meetingLink"
                    type="url"
                    value={form.event?.meetingLink || ''}
                    onChange={handleInput}
                  />
                </FormControl>
                <FormControl
                  id="requestedAttendeeCount"
                  isRequired
                >
                  <ZFormLabel>
                    Maximum Attendees
                    <ZInfoTooltip label="You may limit the total number of attendees allowed" />
                  </ZFormLabel>
                  <ZInput
                    name="requestedAttendeeCount"
                    type="number"
                    value={form.event?.requestedAttendeeCount || ''}
                    onChange={handleInput}
                    min={eventDetails?.minimumAttendees || 1}
                    max={eventDetails?.maximumAttendees || undefined}
                    isDisabled={isRegistrationClosed}
                  />
                </FormControl>
                {hasTeamSelect && (
                  <FormControl id="teamIds">
                    <ZFormLabel>
                      Teams
                      <ZInfoTooltip label="You can restrict sending invitations to certain teams" />
                    </ZFormLabel>
                    <AutoCompleteTeams
                      value={form.teamIds || []}
                      onChange={(data) => setForm((draft: any) => void (draft.teamIds = data))}
                      isDisabled={isRegistrationClosed}
                      {...SelectTypeaheadStylesV2}
                    />
                  </FormControl>
                )}

                {canUpdateSpendAs && (
                  <FormControl id="spendAs">
                    <EventsSpendAs
                      value={form.eventFeeSettings?.flatFee}
                      onChange={handleSpendAs}
                    />
                  </FormControl>
                )}

                <FormControl
                  id="description"
                  isRequired
                >
                  <ZFormLabel>
                    Description
                    <ZInfoTooltip label="This description will be shown on the invitation landing page" />
                  </ZFormLabel>
                  <ZTextarea
                    name="description"
                    minH="400px"
                    value={form.description || ''}
                    onChange={handleInput}
                  />
                </FormControl>

                <AttributionSelect
                  attribution={postal.attribution}
                  onChange={handleAttribution}
                  menuPlacement="top"
                  isDisabled={isRegistrationClosed}
                />

                <FormControl>
                  <ZHeading
                    size="h6"
                    display="flex"
                    alignItems="center"
                    gap={2}
                  >
                    Email Notifications
                    <ZInfoTooltip label="Enable or disable event notification emails" />
                  </ZHeading>
                  <Grid
                    templateColumns="1fr 1fr"
                    mt={2}
                    mb={4}
                  >
                    <HStack
                      minH="32px"
                      alignItems="center"
                      spacing={2}
                    >
                      <ZCheckbox
                        colorScheme="atomicBlue"
                        name="sendEmailConfirmation"
                        id="sendEmailConfirmation"
                        isChecked={!!form.event?.sendEmailConfirmation}
                        onChange={handleCheckbox}
                      />
                      <ZFormLabel>Send Email Confirmation</ZFormLabel>
                      <UiIconButton
                        size="xs"
                        variant="ghost"
                        aria-label="Preview Confirmation Email"
                        icon={
                          <ViewIcon
                            color="primary.500"
                            fontSize="xl"
                          />
                        }
                        onClick={(e: any) => handlePreview(e, CustomEmail.Confirmation)}
                      />
                    </HStack>

                    <HStack
                      minH="32px"
                      alignItems="center"
                      spacing={2}
                    >
                      <ZCheckbox
                        colorScheme="atomicBlue"
                        name="sendInviteExpiringAlert"
                        id="sendInviteExpiringAlert"
                        isChecked={!!form.event?.sendInviteExpiringAlert}
                        onChange={handleCheckbox}
                      />
                      <ZFormLabel>Send Invite Expiring Alert</ZFormLabel>
                      <UiIconButton
                        size="xs"
                        variant="ghost"
                        aria-label="Send Invite Expiring Email"
                        icon={
                          <ViewIcon
                            color="primary.500"
                            fontSize="xl"
                          />
                        }
                        onClick={(e: any) => handlePreview(e, CustomEmail.InviteExpiring)}
                      />
                    </HStack>

                    <HStack
                      minH="32px"
                      alignItems="center"
                      spacing={2}
                    >
                      <ZCheckbox
                        colorScheme="atomicBlue"
                        name="sendReminderDayBefore"
                        id="sendReminderDayBefore"
                        isChecked={!!form.event?.sendReminderDayBefore}
                        onChange={handleCheckbox}
                      />
                      <ZFormLabel>Send Reminder Day Before</ZFormLabel>
                      <UiIconButton
                        size="xs"
                        variant="ghost"
                        aria-label="Preview Reminder Day Before"
                        icon={
                          <ViewIcon
                            color="primary.500"
                            fontSize="xl"
                          />
                        }
                        onClick={(e: any) => handlePreview(e, CustomEmail.ReminderTomorrow)}
                      />
                    </HStack>

                    <HStack
                      minH="32px"
                      alignItems="center"
                      spacing={2}
                    >
                      <ZCheckbox
                        colorScheme="atomicBlue"
                        name="sendCancelledAlert"
                        id="sendCancelledAlert"
                        isChecked={!!form.event?.sendCancelledAlert}
                        onChange={handleCheckbox}
                      />
                      <ZFormLabel>Send Cancellation Alert</ZFormLabel>
                      <UiIconButton
                        size="xs"
                        variant="ghost"
                        aria-label="Preview Cancellation Alert"
                        icon={
                          <ViewIcon
                            color="primary.500"
                            fontSize="xl"
                          />
                        }
                        onClick={(e: any) => handlePreview(e, CustomEmail.Cancellation)}
                      />
                    </HStack>

                    <HStack
                      minH="32px"
                      alignItems="center"
                      spacing={2}
                    >
                      <ZCheckbox
                        colorScheme="atomicBlue"
                        name="sendReminderDayOf"
                        id="sendReminderDayOf"
                        isChecked={!!form.event?.sendReminderDayOf}
                        onChange={handleCheckbox}
                      />
                      <ZFormLabel>Send Reminder Day Of</ZFormLabel>
                      <UiIconButton
                        size="xs"
                        variant="ghost"
                        aria-label="Preview Reminder Day Of"
                        icon={
                          <ViewIcon
                            color="primary.500"
                            fontSize="xl"
                          />
                        }
                        onClick={(e: any) => handlePreview(e, CustomEmail.ReminderToday)}
                      />
                    </HStack>

                    <HStack
                      minH="32px"
                      alignItems="center"
                      spacing={2}
                    >
                      <ZCheckbox
                        colorScheme="atomicBlue"
                        name="sendMeetingLinkChanged"
                        id="sendMeetingLinkChanged"
                        isChecked={!!form.event?.sendMeetingLinkChanged}
                        onChange={handleCheckbox}
                      />
                      <ZFormLabel>Send Meeting Link Changed</ZFormLabel>
                      <UiIconButton
                        size="xs"
                        variant="ghost"
                        aria-label="Preview Meeting Link Changed"
                        icon={
                          <ViewIcon
                            color="primary.500"
                            fontSize="xl"
                          />
                        }
                        onClick={(e: any) => handlePreview(e, CustomEmail.MeetingLinkChanged)}
                      />
                    </HStack>

                    <HStack
                      minH="32px"
                      alignItems="center"
                      spacing={2}
                    >
                      <ZCheckbox
                        colorScheme="atomicBlue"
                        name="sendDateTimeChanged"
                        id="sendDateTimeChanged"
                        isChecked={!!form.event?.sendDateTimeChanged}
                        onChange={handleCheckbox}
                      />
                      <ZFormLabel>Send Date/Time Changed</ZFormLabel>
                      <UiIconButton
                        size="xs"
                        variant="ghost"
                        aria-label="Date/Time Changed"
                        icon={
                          <ViewIcon
                            color="primary.500"
                            fontSize="xl"
                          />
                        }
                        onClick={(e: any) => handlePreview(e, CustomEmail.DateTimeChanged)}
                      />
                    </HStack>
                    <HStack
                      minH="32px"
                      alignItems="center"
                      spacing={2}
                    >
                      <ZCheckbox
                        colorScheme="atomicBlue"
                        name="sendShippedAlert"
                        id="sendShippedAlert"
                        isChecked={!!form.event?.sendShippedAlert}
                        onChange={handleCheckbox}
                      />
                      <ZFormLabel>Send Event Kit Shipped Alert</ZFormLabel>
                    </HStack>
                    <HStack
                      minH="32px"
                      alignItems="center"
                      spacing={2}
                    >
                      <ZCheckbox
                        colorScheme="atomicBlue"
                        name="sendDeliveredAlert"
                        id="sendDeliveredAlert"
                        isChecked={!!form.event?.sendDeliveredAlert}
                        onChange={handleCheckbox}
                      />
                      <ZFormLabel>Send Event Kit Delivered Alert</ZFormLabel>
                    </HStack>
                  </Grid>

                  {hasEventEmails && (
                    <ZLink
                      alignSelf="flex-start"
                      fontWeight="bold"
                      onClick={customEmailMessagingDisclosure.onOpen}
                    >
                      Customize Email Messaging
                    </ZLink>
                  )}
                </FormControl>

                {hasCustomFields && (
                  <FormControl id="customResgistrationForm">
                    <ZHeading
                      size="h6"
                      mb={2}
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      Registration Form
                      <ZInfoTooltip label="Customize which fields are requested and required, for recipients to accept the event invite" />
                    </ZHeading>

                    {!!form.formFieldList?.length && (
                      <UiCard mb={2}>
                        <Text
                          fontSize="xl"
                          fontWeight="semibold"
                          mb={4}
                        >
                          Custom Fields Added:
                        </Text>
                        <Wrap spacing={4}>
                          {form.formFieldList.map((item, idx) => {
                            return (
                              <WrapItem key={`${item.name}-${idx}`}>
                                <UiTag
                                  size="sm"
                                  textAlign="center"
                                >
                                  {item.name}
                                </UiTag>
                              </WrapItem>
                            )
                          })}
                        </Wrap>
                      </UiCard>
                    )}
                    <ZLink
                      fontWeight="bold"
                      onClick={customFieldDisclosure.onOpen}
                    >
                      Customize Form Fields
                    </ZLink>
                  </FormControl>
                )}
              </Stack>
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              type="submit"
              form="PostalEventEdit_form"
              isLoading={loadingAlertDialog.isOpen}
              isDisabled={loadingAlertDialog.isOpen}
              minW={32}
            >
              Save This Event
            </Button>
            <Button
              ref={cancelRef}
              variant="ghost"
              colorScheme="atomicGray"
              onClick={onCloseAttempt}
              minW={32}
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {customEmailMessagingDisclosure.isOpen && (
        <CustomizeEmailMessaging
          {...customEmailMessagingDisclosure}
          form={form}
          eventDateTime={postal?.event?.eventDateTime}
          onSubmit={handleMessagingSubmit}
          isLoading={loadingAlertDialog.isOpen}
        />
      )}
      {previewDisclosure.isOpen && (
        <CustomizeEmailMessagingPreview
          emailType={emailType}
          postal={postal}
          variantId={postal?.variants?.[0]?.id}
          {...previewDisclosure}
        />
      )}
      {customFieldDisclosure.isOpen && (
        <PostalEventCustomFields
          {...customFieldDisclosure}
          onConfirm={handleCustomFieldConfirm}
          customFields={form.formFieldList}
        />
      )}
      <ConfirmClose
        {...confirmationDisclosure}
        onConfirm={onCloseConfirm}
        thingWithChanges="Event"
      />
    </>
  )
}
