import type { ModalProps } from '@chakra-ui/react'
import { Flex, Stack } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { UiFormControl, UiToggle, useAlerts, ZFormLabel, ZInput, ZText } from '@postal-io/postal-ui'
import { zAutocompleteStyles, ZBasicDialogButtons, ZDialog, ZInfoTooltip } from 'components/Common/ZComponents'
import { AttributionSelect } from 'components/Integrations/AttributionSelect'
import { POSTAL_INVALIDATIONS, useAcl, useApprovedPostalVersion, useBackgroundQueue, useSession } from 'hooks'
import type { ChangeEvent } from 'react'
import React, { useMemo, useState } from 'react'
import { useImmer } from 'use-immer'
import type { ApprovedPostal } from '../../api'
import { Role, Status, UpdateApprovedPostalDocument } from '../../api'
import { AutoCompleteTeams } from '../AutoComplete'

interface CollectionSettingsEditProps extends Omit<ModalProps, 'children'> {
  postal: ApprovedPostal
  onEdit: (item: ApprovedPostal) => void
}

export const CollectionSettingsEdit: React.FC<CollectionSettingsEditProps> = ({ postal, isOpen, onClose, onEdit }) => {
  const transform = useApprovedPostalVersion()
  const { session } = useSession()
  const { hasFeature, hasRole } = useAcl()
  const isAdmin = hasRole(Role.Admin)

  // if there is a teamId in session, this team admin cannot assign teams
  const hasTeamSelect = hasFeature('teamPostals') && !session.teamId

  const [form, setForm] = useImmer<Record<string, any>>({
    name: postal.name,
    displayName: postal.displayName,
    enabled: postal.status === Status.Active,
    teamIds: postal.teamIds || [],
    attribution: postal.attribution || '',
    ownerId: postal.ownerId,
  })
  const Alert = useAlerts()

  const { queue, invalidate } = useBackgroundQueue()
  const updateApprovedPostal = useGraphqlMutation(UpdateApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.updateApprovedPostal.previewGenerationTask)
      invalidate(POSTAL_INVALIDATIONS)
    },
  })

  const attribution = useMemo(() => {
    return (postal as ApprovedPostal).attribution
  }, [postal])

  const [selectedAttribution, setSelectedAttribution] = useState(attribution)

  const updateCollection = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!form.name) {
      return Alert.warning('Please add a collection name')
    }
    try {
      const res = await updateApprovedPostal.mutateAsync({
        id: postal.id,
        data: {
          name: form.name,
          displayName: form.displayName,
          status: form.enabled ? Status.Active : Status.Disabled,
          teamIds: form.teamIds,
          attribution: selectedAttribution,
          ownerId: form.ownerId,
          ...transform(postal.category),
        },
      })

      Alert.success('Collection Updated')
      onEdit(res.updateApprovedPostal.postal)
      onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target

    setForm((draft: Record<string, any>) => {
      draft[name] = value
    })
  }

  const handleToggle = ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
    setForm((draft: Record<string, any>) => {
      draft[name] = checked
    })
  }

  return (
    <ZDialog
      size="xl"
      title="Collection Settings"
      isOpen={isOpen}
      onClose={onClose}
    >
      <form
        id="PostalCollectionEdit_form"
        onSubmit={updateCollection}
        style={{ width: '100%', marginBottom: '1rem' }}
      >
        <Stack spacing={8}>
          <UiFormControl
            id="name"
            isRequired
          >
            <ZFormLabel mb={{ base: 1, md: 1 }}>Collection Name</ZFormLabel>
            <ZInput
              name="name"
              value={form.name || ''}
              onChange={handleChange}
              isRequired
            />
          </UiFormControl>
          <UiFormControl
            id="displayName"
            isRequired
          >
            <Flex alignItems="left">
              <ZFormLabel mb={{ base: 1, md: 1 }}>Display Name</ZFormLabel>
              <ZInfoTooltip label="This name will be shown externally to your contacts, in gift emails and landing pages" />
            </Flex>
            <ZInput
              name="displayName"
              value={form.displayName || ''}
              onChange={handleChange}
              isRequired
            />
          </UiFormControl>
          <UiFormControl id="enableCollection">
            <UiToggle
              name="enabled"
              isChecked={form.enabled}
              onChange={handleToggle}
              colorScheme="atomicBlue"
            >
              <ZText>Enable Collection</ZText>
            </UiToggle>
          </UiFormControl>
          {hasTeamSelect && !form.ownerId && isAdmin && (
            <UiFormControl id="teamIds">
              <ZFormLabel mb={{ base: 1, md: 1 }}>
                Teams
                <ZInfoTooltip label="You can restrict this collection to certain teams" />
              </ZFormLabel>
              <AutoCompleteTeams
                value={form.teamIds}
                onChange={(data) => setForm((draft) => void (draft.teamIds = data))}
                styles={zAutocompleteStyles}
              />
            </UiFormControl>
          )}
        </Stack>
      </form>
      {isAdmin && (
        <AttributionSelect
          width="100%"
          mt={10}
          textAlign="left"
          attribution={attribution}
          onChange={setSelectedAttribution}
        />
      )}
      <ZBasicDialogButtons
        confirmProps={{ type: 'submit', form: 'PostalCollectionEdit_form' }}
        onClose={onClose}
        onConfirm={updateCollection}
        confirmText="Save"
      />
    </ZDialog>
  )
}
