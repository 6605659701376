import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { sessionStorage } from '@postal-io/postal-ui'
import { ItemType, SearchFavoriteItemsDocument, Status } from 'api'
import { Owner } from 'components/Collections/data'
import { format, isValid, parseISO } from 'date-fns'
import { dequal } from 'dequal'
import { identity, pickBy } from 'lodash'
import _isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useImmerReducer } from 'use-immer'
import { useMe } from './useMe'

const DEBOUNCE_DELAY = 600

const isEmpty = (val: any) => !isNumber(val) && _isEmpty(val)

// These are special keys that can go in an attribute filter
// and will be handled on the backend
const attributeKeyMap = (key: string) => {
  switch (key) {
    case 'useCases':
      return key
    case 'shippingOptions':
      return `variants_fulfillmentPartnerList_shippingOptions_name`
    case 'fulfillmentType':
      return `variants_fulfillmentPartnerList_fulfillmentType`
    case 'fulfillmentPartnerName':
      return `variants_fulfillmentPartnerList_fulfillmentPartnerName`
    case 'cardIncluded':
      return `variants_physicalMessageSupported`
    default:
      return `variants_filters_${key}`
  }
}

interface PayloadProps {
  type: string
  name?: string
  value?: any
}

const filterReducer = (draft: any, { type, ...payload }: PayloadProps) => {
  const { name, value } = payload || {}

  switch (type) {
    case 'UPDATE': {
      // remove price if we have an object with only undefined values
      if (name === 'price' && isEmpty(value?.min) && isEmpty(value?.max)) {
        delete draft[name]
        return
      }

      if (name === 'eventAvailabilityDates') {
        if (!value) {
          delete draft[name]
        } else if (typeof value === 'string') {
          draft[name] = value
        } else if (isValid(value)) {
          // this is the format that flatpickr understands that
          // is also human readable
          draft[name] = format(value, 'yyyy-MM-dd')
        } else {
          delete draft[name]
        }
        return
      }

      // if the value is empty remove from filters
      if (name && isEmpty(value) && typeof value !== 'boolean') {
        delete draft[name]
        return
      }

      // set the new value for this filter
      if (name) draft[name] = value
      return
    }
    case 'CLEAR':
      return value || {}
    default:
      return draft
  }
}

const gqlFilterGenerator = (
  filters: any,
  userId: any,
  favoriteApprovedPostalIds: any,
  defaultVariables: any,
  staticVariables: any
) => {
  const attributeFilters: Record<string, any>[] = []
  const filterVariables: Record<string, any> = {}
  Object.entries(filters).forEach(([key, val]: [key: string, val: any]) => {
    // a false draft toggle value needs to come through

    if (isEmpty(val) && !['draft', 'favorites'].includes(key)) return

    const defaultValue = Array.isArray(val) ? { in: [val].flat() } : { eq: val }

    switch (key) {
      case 'q':
        filterVariables[key] = val
        break
      case 'id':
      case 'marketplaceProductId':
      case 'name':
      case 'brandName':
      case 'currency':
      case 'status':
      case 'teamIds':
      case 'outOfStock':
        filterVariables[key] = defaultValue
        break
      case 'categories':
        filterVariables.category = defaultValue
        break
      case 'type': {
        filterVariables.type = { in: val }
        break
      }
      case 'subCategory': {
        filterVariables.subCategory = { eq: val }
        break
      }
      case 'eventStatus':
        filterVariables.event_status = defaultValue
        break
      case 'shipTo':
        filterVariables.geographicMarkets = defaultValue
        break
      case 'eventAvailabilityDates':
        filterVariables.eventAvailabilityDates = { eq: format(parseISO(val), 'MM/dd/yyyy') }
        break
      case 'restrictPlans':
        filterVariables.restrictPlans = { eq: true }
        break
      case 'draft':
        filterVariables.status = val ? { in: [Status.Active, Status.Disabled] } : { eq: Status.Active }
        break
      case 'favorites':
        filterVariables.id = val ? { in: favoriteApprovedPostalIds } : undefined
        break
      case 'owner':
        switch (val) {
          case Owner.Me:
            filterVariables.ownerId = { eq: userId }
            break
          case Owner.Shared:
            filterVariables.ownerId = { in: [null] }
            break
          case Owner.SharedAndMe:
            filterVariables.ownerId = { in: [null, userId] }
            break
          default:
            filterVariables.ownerId = null
            break
        }
        break
      case 'price':
        if (!val.min && !val.max) return
        let value: any
        if (val.min && val.max) {
          value = { between: [val.min * 100, val.max * 100] }
        } else if (val.min) {
          value = { ge: val.min * 100 }
        } else if (val.max) {
          value = { le: val.max * 100 }
        }
        filterVariables.variants_displayPrice = value
        break
      case 'cardIncluded':
        if (Array.isArray(val) && val.includes('Card Included')) {
          attributeFilters.push({ key: attributeKeyMap(key), filter: { eq: true } })
        }
        break
      // only works if we are using DISABLED status on Product level, which we are not
      // case 'outOfStock':
      //   filterVariables.status =
      //     val === true
      //       ? filterVariables.status
      //       : // if checkbox is turned off, ensure that nothing gets through
      //         filterVariables.status?.filter(
      //           (s: Status) => ![Status.Delete, Status.Disabled, Status.OutOfStock].includes(s)
      //         )
      //   break
      default:
        attributeFilters.push({ key: attributeKeyMap(key), filter: defaultValue })
        break
    }
  })
  filterVariables.attributeFilters = attributeFilters

  const merged = Object.assign({}, defaultVariables, filterVariables, staticVariables)
  return pickBy(merged, (obj) => !isEmpty(obj))
}

const gqlFilterGeneratorMarketplaceV2 = (
  inputFilters: any,
  userId: any,
  _: any,
  defaultVariables: any,
  staticVariables: any
) => {
  const filters: Record<string, any> = { status: [] }
  const searchContext: Record<string, any> = {}
  let queryString

  // Preload filters with status
  filters.status.push(Status.Active)

  Object.entries(inputFilters).forEach(([key, val]: [key: string, val: any]) => {
    // a false draft toggle value needs to come through

    if (isEmpty(val) && !['showDraft', 'showOutOfStock', 'favoritePostals', 'favoriteCollections'].includes(key)) return

    const defaultValue = [val].flat().map((v) => v.value)

    switch (key) {
      case 'q':
        queryString = val
        break

      case 'teamIds':
        searchContext.teamIds = val
        break

      case 'currency':
        filters[key] = val
        break

      // Status toggles
      case 'showOutOfStock':
        filters.status.push(Status.OutOfStock)
        break
      case 'showDraft':
        filters.status.push(Status.Disabled)
        break

      // Favorites toggle
      case 'favoritePostals':
        searchContext.itemTypes = [ItemType.UserFavoritePostal]
        break
      case 'favoriteCollections':
        searchContext.itemTypes = [ItemType.UserFavoriteCollection]
        break

      default:
        filters[key] = defaultValue
        break
    }
  })

  // filters
  const mergedFilters = Object.assign({}, defaultVariables?.filters, filters, staticVariables?.filters)
  const prunedFilters = pickBy(mergedFilters, (obj) => !isEmpty(obj))
  const searchQueryInputFilters = Object.entries(prunedFilters).map((f) => ({ name: f[0], values: f[1] }))

  // search context
  const mergedContext = Object.assign(
    {},
    defaultVariables?.searchContext,
    searchContext,
    staticVariables?.searchContext
  )
  const prunedContext = pickBy(mergedContext, (obj) => !isEmpty(obj))

  return {
    queryString: staticVariables.queryString || queryString,
    filters: searchQueryInputFilters,
    searchContext: prunedContext,
  }
}

interface UsePostalFiltersProps {
  /**
   * set the initial state of the filters
   **/
  initialFilters?: any
  /**
   * default graphql variables that are overwritten by filters
   */
  defaultVariables?: any
  /**
   * static graphql variables that are NOT overwritten by filters
   */
  staticVariables?: Record<string, any>
  /**
   * persist filters to local storage
   */
  persistKey?: string
  /**
   * marketplace search v2 has different filters
   */
  isV2?: boolean
}
export const usePostalFilters = <T extends any = {}>({
  initialFilters,
  defaultVariables,
  staticVariables,
  persistKey,
  isV2,
}: UsePostalFiltersProps) => {
  const [delay, setDelay] = useState<number>(DEBOUNCE_DELAY)

  const { me } = useMe()
  const setInitialState = useCallback(
    (initialState = {}) => {
      let storedState = {}
      try {
        if (persistKey) storedState = JSON.parse(sessionStorage.getItem(persistKey) || '{}')
      } catch (e) {}
      return pickBy(Object.assign({}, initialState, storedState), identity)
    },
    [persistKey]
  )

  const [filters, dispatch] = useImmerReducer<Record<string, any>, PayloadProps, typeof setInitialState>(
    filterReducer,
    initialFilters,
    setInitialState
  )

  const favoritesQuery = useGraphqlQuery(SearchFavoriteItemsDocument, undefined, { enabled: !isV2 })
  const favoriteApprovedPostalIds = useMemo(
    () => favoritesQuery.data?.searchFavoriteItems?.map((p) => p.approvedPostalId) ?? [],
    [favoritesQuery.data?.searchFavoriteItems]
  )

  // every time we change the filters, write to localStorage
  useEffect(() => {
    if (persistKey) sessionStorage.setItem(persistKey, JSON.stringify(filters))
  }, [filters, persistKey])

  // Transform the filters into graphql variables every time it is updated
  const graphqlFilter = useMemo(() => {
    const gqlFilterGen = isV2 ? gqlFilterGeneratorMarketplaceV2 : gqlFilterGenerator
    return gqlFilterGen(filters, me?.id, favoriteApprovedPostalIds, defaultVariables, staticVariables) as T
  }, [filters, defaultVariables, staticVariables, isV2, favoriteApprovedPostalIds, me?.id])

  const updateFilter = useCallback(
    (name: string, value: any, delay?: number) => {
      setDelay(isNumber(delay) ? delay : DEBOUNCE_DELAY)
      dispatch({ type: 'UPDATE', name, value })
    },
    [dispatch]
  )

  const clearFilters = useCallback(
    (value?: Record<string, any>) => {
      dispatch({ type: 'CLEAR', value })
    },
    [dispatch]
  )

  const [debouncedGraphqlFilter] = useDebounce(graphqlFilter, delay, { equalityFn: dequal })

  return { filters, graphqlFilter: debouncedGraphqlFilter, updateFilter, clearFilters }
}

export type UsePostalFiltersV2Response = ReturnType<typeof usePostalFilters>
