import { Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react'
import { ZInput } from '@postal-io/postal-ui'

const sliderProps = {
  track: {
    bgColor: 'atomicGray.100',
    h: '2px',
  },
  filledTrack: {
    bgColor: 'atomicBlue.400',
    h: '4px',
    borderRadius: '4px',
  },
  thumb: {
    width: '24px',
    height: '24px',
    borderWidth: '1px',
    borderColor: 'atomicGray.100',
    boxShadow: '0px 0.8px 0px rgba(47, 51, 55, 0.1), 0px 0.8px 4px rgba(47, 51, 55, 0.1)',
  },
  input: {
    type: 'number',
    w: '38px',
    fontSize: 'sm',
    ml: 2,
    p: 2,
  },
}

interface DesignEditorSliderProps {
  cacheValue: number
  value: number
  min: number
  max: number
  onChange: (value: number) => void
}

export const DesignEditorSlider: React.FC<DesignEditorSliderProps> = ({ cacheValue, value, min, max, onChange }) => {
  return (
    <Flex>
      <Slider
        defaultValue={value || min}
        value={cacheValue}
        onChange={onChange}
        min={min}
        max={max}
        flex={1}
      >
        <SliderTrack {...sliderProps.track} />
        <SliderFilledTrack {...sliderProps.filledTrack} />
        <SliderThumb {...sliderProps.thumb} />
      </Slider>
      <ZInput
        {...sliderProps.input}
        value={Math.round(cacheValue || 0)}
        textAlign="center"
      />
    </Flex>
  )
}
