import { CheckCircleIcon } from '@chakra-ui/icons'
import { Stack, Text } from '@chakra-ui/react'
import type { Column, GraphqlFilterState, OrderByProps } from '@postal-io/postal-ui'
import {
  GraphqlFilterTransform,
  joinStrings,
  UiDate,
  UiLink,
  UiStopPropagation,
  UiTag,
  UiTooltip,
} from '@postal-io/postal-ui'
import type { Address, SearchableContact } from 'api'
import { AddressStatus } from 'api'
import { Link } from 'react-router-dom'

export const LIMIT = 100

export const qSort = { key: 'q', direction: 'desc' } as OrderByProps
export const defaultSort = { key: 'created', direction: 'desc' } as OrderByProps
export const initialState = { orderBy: defaultSort } as GraphqlFilterState

export const columns: Column[] = [
  {
    label: 'Name',
    render: ({ id, firstName, lastName }: SearchableContact) => (
      <UiStopPropagation>
        <UiLink
          as={Link}
          to={`/contacts/${id}`}
          state={{ returnTo: 'Contacts' }}
          data-private
        >
          {joinStrings([firstName, lastName])}
        </UiLink>
      </UiStopPropagation>
    ),
  },
  {
    key: 'email',
    label: 'Email',
    render: ({ emailAddress }: SearchableContact) => emailAddress,
  },
  { key: 'title', label: 'Title' },
  { key: 'companyName', label: 'Company' },
  {
    key: 'created',
    label: 'Created',
    orderBy: 'created',
    render: ({ created }: SearchableContact) => <UiDate date={created} />,
  },
  {
    key: 'lastSend',
    label: 'Last Send',
    orderBy: 'lastSend',
    render: ({ lastSend }: SearchableContact) => <UiDate date={lastSend} />,
  },
  {
    key: 'addressVerified',
    label: 'Address Verified',
    render: ({ verifiedAddress }: SearchableContact) => {
      return (
        verifiedAddress && (
          <CheckCircleIcon
            boxSize="20px"
            color="tertiary.500"
          />
        )
      )
    },
  },
  { key: 'itemsSent', label: 'Items', orderBy: 'itemsSent' },
  {
    label: 'Campaigns',
    render: ({ campaigns }: SearchableContact) => campaigns?.length || 0,
  },
  {
    label: 'Tags',
    render: ({ tags }: SearchableContact) => {
      if (!tags?.length) return ''
      return (
        <UiTooltip
          label={tags.join(', ')}
          aria-label={tags.join(', ') || ''}
        >
          <Stack
            isInline
            spacing={1}
            alignItems="baseline"
          >
            {tags.slice(0, 2)?.map((t) => (
              <UiTag
                key={t}
                size="sm"
              >
                {t}
              </UiTag>
            ))}
            {tags.length > 2 && (
              <Text
                as="span"
                fontSize="xs"
              >
                +{tags.length - 2}
              </Text>
            )}
          </Stack>
        </UiTooltip>
      )
    },
  },
]

// table search keys
export const searchKeys = ['title', 'firstName', 'lastName', 'companyName', 'tags']

const VERIFIED_ADDRESS_STATUSES = [AddressStatus.Matched, AddressStatus.MatchedOverride]

export const hasVerifiedAddress = (data?: AddressStatus | Address | Address[] | null) => {
  if (!data) return false
  // data is an Array
  if (Array.isArray(data)) {
    return !!data?.find((a: Address) => a.status && VERIFIED_ADDRESS_STATUSES.includes(a.status))
  }
  // data is an object
  else if (typeof data === 'object') {
    return VERIFIED_ADDRESS_STATUSES.includes(data.status as AddressStatus)
    // data is just the AddressStatus
  } else {
    return VERIFIED_ADDRESS_STATUSES.includes(data as AddressStatus)
  }
}

export const TRANSFORMS = {
  firstName: GraphqlFilterTransform.BeginsWith,
  lastName: GraphqlFilterTransform.BeginsWith,
  companyName: GraphqlFilterTransform.Contains,
  title: GraphqlFilterTransform.Contains,
  emailAddress: GraphqlFilterTransform.Contains,
  city: GraphqlFilterTransform.BeginsWith,
  state: GraphqlFilterTransform.BeginsWith,
  postalCode: GraphqlFilterTransform.BeginsWith,
  ownerId: GraphqlFilterTransform.Equal,
  created: GraphqlFilterTransform.Between,
  lastSend: GraphqlFilterTransform.Between,
  tags: GraphqlFilterTransform.In,
  lists: GraphqlFilterTransform.Equal,
}
