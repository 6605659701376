import { AspectRatio, Box, Collapse, Divider, Flex, Grid, Stack, Wrap, WrapItem } from '@chakra-ui/react'
import {
  sanitize,
  SelectTypeaheadStylesV2,
  UiSkeleton,
  UiSlideImage,
  UiToggle,
  UiTruncate,
  useAlerts,
  ZFlowButton,
  ZFormLabel,
  ZHeading,
  ZInput,
  ZMoney,
  ZTag,
  ZTagLabel,
  ZText,
  ZTextarea,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, MarketplaceProduct, ProductVariant } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { AttributionSelect } from 'components/Integrations/AttributionSelect'
import { ProductRequestButton } from 'components/Postal/ProductRequestButton'
import {
  AnalyticsEvent,
  AnalyticsEventV2,
  useAcl,
  useAnalyticsEvent,
  useAnalyticsSend,
  useAssets,
  useSession,
} from 'hooks'
import React from 'react'
import { MdChevronRight, MdOutlineVerified } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import type { Updater } from 'use-immer'
import { AutoCompleteTeams } from '../AutoComplete'
import type { ItemApproveFormState } from './MarketplaceProductPage'
import { MarketplaceProductVariantSelector } from './MarketplaceProductVariantSelector'

interface MarketplaceProductSidebarProps {
  postal?: MarketplaceProduct | ApprovedPostal
  activeVariants: ProductVariant[]
  availableVariants: ProductVariant[]
  isOpen: boolean
  onOpen: () => void
  isLoading?: boolean
  onUpdate: (data: any) => void
  state: ItemApproveFormState
  setState: Updater<ItemApproveFormState>
  estimatedShippingCost: number
}

export const MarketplaceProductSidebar: React.FC<MarketplaceProductSidebarProps> = ({
  postal: product,
  activeVariants,
  availableVariants,
  isOpen,
  onOpen,
  isLoading,
  onUpdate,
  state,
  setState,
  estimatedShippingCost,
}) => {
  // const transform = useApprovedPostalVersion()
  const Alert = useAlerts()
  const { hasPermission, hasFeature } = useAcl()
  const { marketplaceProductId } = useParams() as any
  const { session } = useSession()
  const { assetUrlSrc } = useAssets()

  const canCreate = hasPermission('postals.create')

  // add or remove the variant from selected
  const handleVariant = (variant: ProductVariant) => {
    setState((draft) => {
      draft.variants = draft.variants.some(({ id }: ProductVariant) => id === variant.id)
        ? draft.variants.filter(({ id }) => id !== variant.id)
        : [...draft.variants, variant]
    })
  }

  const imageProps = assetUrlSrc(product?.imageUrls?.[0]?.url)

  // if there is a teamId in session, this team admin cannot assign teams
  const hasTeamSelect = hasFeature('teamPostals') && !session.teamId

  const sendAnalytics = useAnalyticsSend()
  useAnalyticsEvent({ event: AnalyticsEvent.ProductViewed, data: { marketplaceProductId } })

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    sendAnalytics({ event: AnalyticsEventV2.Product_SaveItem_Clicked })
    if (!state.variants.length) return Alert.warning('Please select at least 1 variant')
    onUpdate({ ...state, variants: state.variants.map((v) => v.id), description: sanitize(state.description) })
  }

  return (
    <Stack
      spacing={4}
      position="relative"
    >
      <Box
        position="sticky"
        top={20}
      >
        <UiSkeleton
          isLoaded={!isLoading}
          minH="220px"
          w="100%"
          mb={2}
        >
          {!!product && !!activeVariants.length && (
            <>
              <Stack>
                <ZHeading
                  fontSize="lg"
                  mb={2}
                >
                  Choose your options
                </ZHeading>
                <MarketplaceProductVariantSelector
                  availableVariants={availableVariants}
                  selectedVariants={state.variants}
                  handleVariant={handleVariant}
                  marketplaceProductId={(product as ApprovedPostal)?.marketplaceProductId ?? product.id}
                  category={product.category}
                />
              </Stack>
              <Divider
                w="100%"
                my={4}
              />
            </>
          )}
          <Grid
            templateColumns={product?.imageUrls?.length ? '70px 1fr' : '1fr'}
            templateRows="auto auto"
            gridColumnGap={2}
            gridRowGap={1}
            mb={4}
          >
            {product?.imageUrls?.length ? (
              <Box
                height="70px"
                gridRow="span 2"
              >
                <AspectRatio
                  ratio={1}
                  w="70px"
                >
                  <UiSlideImage
                    objectFit="contain"
                    decoding="async"
                    border="1px solid"
                    borderColor="atomicGray.200"
                    borderRadius={5}
                    {...imageProps}
                  />
                </AspectRatio>
              </Box>
            ) : null}
            <ZText fontSize="lg">{product?.name}</ZText>
            <Wrap>
              {state?.variants.slice(0, 2).map((variant) => (
                <WrapItem key={variant.id}>
                  <ZTag
                    size="sm"
                    fontWeight="bold"
                    colorScheme="atomicBlue"
                  >
                    <ZTagLabel>
                      <UiTruncate
                        text={variant.variantName}
                        length={35}
                      />
                    </ZTagLabel>
                  </ZTag>
                </WrapItem>
              ))}
              {state?.variants.length > 2 && (
                <WrapItem>
                  <ZTag
                    size="sm"
                    fontWeight="bold"
                    colorScheme="atomicBlue"
                    backgroundColor="transparent"
                  >
                    {state?.variants.length - 2} more
                  </ZTag>
                </WrapItem>
              )}
            </Wrap>
          </Grid>
          <Flex
            justifyContent="space-between"
            mb={2}
          >
            <ZText
              fontWeight="bold"
              fontSize="xs"
            >
              Standard shipping
            </ZText>
            <ZMoney
              fontSize="sm"
              cents={estimatedShippingCost}
              currency={product?.currency}
            />
          </Flex>
        </UiSkeleton>
        <Divider />
        <Collapse
          in={isOpen}
          transition={{ enter: { duration: 0.8 } }}
          style={{ marginTop: 0, padding: '1px' }}
        >
          <Stack
            py={8}
            spacing={8}
          >
            <Flex justifyContent="space-between">
              <ZText
                fontWeight="bold"
                fontSize="sm"
              >
                Status
              </ZText>
              <UiToggle
                colorScheme="atomicBlue"
                name="isActive"
                isChecked={!!state.isActive}
                onChange={(e: any) => {
                  setState((draft) => void (draft.isActive = e.target.checked))
                }}
                id="postal-status"
                fontSize="sm"
              >
                Active
              </UiToggle>
            </Flex>
            {hasTeamSelect && (
              <Box>
                <ZFormLabel gap={1}>
                  Teams
                  <ZInfoTooltip
                    placement="top"
                    label="You can restrict this item to certain teams"
                  />
                </ZFormLabel>

                <AutoCompleteTeams
                  rootProps={{ minW: '250px' }}
                  value={state.teamIds}
                  onChange={(teamIds) => setState((draft: any) => void (draft.teamIds = teamIds))}
                  {...SelectTypeaheadStylesV2}
                />
              </Box>
            )}
          </Stack>
          <Divider />
          <Stack
            spacing={4}
            py={4}
          >
            <Box>
              <ZFormLabel htmlFor="postal-name">Name</ZFormLabel>
              <ZInput
                id="postal-name"
                aria-label="Name"
                name="name"
                type="text"
                isRequired
                value={state.name}
                onChange={(e: any) => setState((draft) => void (draft.name = e.target.value))}
              />
            </Box>
            <Box>
              <ZFormLabel
                htmlFor="postal-displayName"
                gap={1}
              >
                Display Name
                <ZInfoTooltip label="This name will be shown externally to your contacts, in gift emails and landing pages" />
              </ZFormLabel>
              <ZInput
                id="postal-displayName"
                aria-label="Display Name"
                name="displayName"
                type="text"
                isRequired
                value={state.displayName}
                onChange={(e: any) => setState((draft) => void (draft.displayName = e.target.value))}
              />
            </Box>
            <Box pb={2}>
              <ZFormLabel
                htmlFor="postal-description"
                gap={1}
              >
                Description
                <ZInfoTooltip label="The description will be shown externally to your contacts, in gift emails and landing pages" />
              </ZFormLabel>
              <ZTextarea
                id="postal-description"
                name="description"
                aria-label="Description"
                isRequired
                minH="200px"
                value={state.description}
                onChange={(e) => setState((draft) => void (draft.description = e.target.value))}
              />
            </Box>
            <Divider />
            <AttributionSelect
              menuPlacement="top"
              attribution={state.attribution}
              onChange={(attribution) => {
                setState((draft) => void (draft.attribution = attribution))
              }}
              py={4}
              showToggleLabel
            />
            <Divider />
          </Stack>
        </Collapse>
        {canCreate ? (
          <>
            <ZFlowButton
              mt={4}
              colorScheme="atomicBlue"
              rightIcon={
                <MdChevronRight
                  style={{
                    transform: isOpen ? 'rotate(0deg)' : 'rotate(90deg)',
                    transition: 'transform linear 75ms',
                  }}
                  size="22px"
                />
              }
              leftIcon={<MdOutlineVerified size="20px" />}
              isDisabled={!state?.variants.length || isLoading}
              isLoading={isLoading}
              onClick={isOpen ? onSubmit : onOpen}
            >
              {(product as ApprovedPostal)?.marketplaceProductId ? 'Update' : 'Approve'}
            </ZFlowButton>
          </>
        ) : (
          <Box>
            <ProductRequestButton
              w="100%"
              productId={marketplaceProductId}
            />
          </Box>
        )}
      </Box>
    </Stack>
  )
}
