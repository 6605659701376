import type { BoxProps } from '@chakra-ui/react'
import { FormControl, SimpleGrid, useClipboard } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  cleanText,
  UiTable,
  UiTableContainer,
  UiTbody,
  UiTd,
  UiTr,
  useAlerts,
  ZCard,
  ZFormLabel,
  ZInput,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZTextarea,
} from '@postal-io/postal-ui'
import type { MessageTemplate, ShareLink } from 'api'
import { CreateMessageTemplateDocument, LinkType, Role, UpdateMessageTemplateDocument } from 'api'
import { useAcl, useSession } from 'hooks'
import React, { useEffect } from 'react'
import { useImmer } from 'use-immer'
import { messageVariables } from './messageVariables'

interface MessagesEditCreateProps extends BoxProps {
  isOpen: boolean
  onClose: () => void
  onComplete?: () => void
  message?: MessageTemplate
  ref?: React.Ref<any>
}

export const MessagesEditCreate: React.FC<MessagesEditCreateProps> = ({ isOpen, onClose, onComplete, message }) => {
  const { onCopy, value, setValue } = useClipboard('')
  const Alert = useAlerts()

  const {
    session: { accountId, teamId, userId },
  } = useSession()

  const { hasRole } = useAcl()

  const [state, setState] = useImmer<{ name: string; templateText: string }>({
    name: message?.name || '',
    templateText: message?.templateText || '',
  })

  const handleClickVariable = (variable: string) => {
    setValue(variable)
  }

  useEffect(() => {
    if (!value || !onCopy) return
    onCopy()
    Alert.success(`Copied ${value} to clipboard`)
    setValue('')
  }, [value, Alert, onCopy, setValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setState((draft: Record<string, any>) => void (draft[name] = value))
  }

  const updateMessageTemplate = useGraphqlMutation(UpdateMessageTemplateDocument)
  const createMessageTemplate = useGraphqlMutation(CreateMessageTemplateDocument)

  const isLoading = updateMessageTemplate.isLoading || createMessageTemplate.isLoading

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!state.name || !state.templateText) {
      return Alert.warning('Please complete all fields.')
    }

    // default sharedWith for message create
    const defaultSharedWith = {} as ShareLink
    if (hasRole(Role.Admin) && !!accountId) {
      defaultSharedWith.type = LinkType.Account
      defaultSharedWith.target = accountId
    } else if (hasRole(Role.TeamAdmin) && !!teamId) {
      defaultSharedWith.type = LinkType.Team
      defaultSharedWith.target = teamId
    } else {
      defaultSharedWith.type = LinkType.User
      defaultSharedWith.target = userId
    }

    const data = {
      ...state,
      templateText: cleanText(state.templateText),
      sharedWith: message?.sharedWith ?? defaultSharedWith,
    }

    try {
      if (message) {
        await updateMessageTemplate.mutateAsync({ id: message.id, data })
      } else {
        await createMessageTemplate.mutateAsync({ data })
      }
      Alert.success('Message saved.')
      onClose()
      onComplete && onComplete()
    } catch (e) {
      Alert.error(e)
    }
  }

  return (
    <>
      <ZModal
        isOpen={isOpen}
        onClose={onClose}
        size="4xl"
      >
        <ZModalOverlay />
        <ZModalContent>
          <form onSubmit={handleOnSubmit}>
            <ZModalHeader>{message ? `Edit ${message.name}` : 'Create a new Template'}</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <SimpleGrid
                columns={1}
                spacing={5}
              >
                <FormControl
                  id="name"
                  isRequired
                >
                  <ZFormLabel fontSize={20}>Message Title</ZFormLabel>
                  <ZInput
                    value={state.name}
                    onChange={handleChange}
                    name="name"
                    placeholder="Message Title"
                  />
                </FormControl>
                <FormControl
                  id="templateText"
                  isRequired
                >
                  <ZFormLabel fontSize={20}>Message Template</ZFormLabel>
                  <ZTextarea
                    size="lg"
                    minH="250px"
                    onChange={handleChange}
                    value={state.templateText}
                    name="templateText"
                    placeholder="Example text. We recommend you keep your messaging simple, so that it looks clean and authentic."
                  />
                  <ZFormLabel
                    fontSize={20}
                    marginTop="19px"
                  >
                    Available Variables
                  </ZFormLabel>
                  <ZCard variant="form">
                    <UiTableContainer
                      variant="list"
                      borderRadius="inherit"
                    >
                      <UiTable variant="list">
                        <UiTbody>
                          {messageVariables.map((row) => (
                            <UiTr
                              key={row.id}
                              // {...(+row.id % 2 === 0 && { bg: 'atomicGray.10' })}
                              cursor="pointer"
                              _hover={{
                                bg: 'atomicGray.10',
                              }}
                              onClick={() => handleClickVariable(row.variable)}
                            >
                              <UiTd p={2}>{row.description}</UiTd>
                              <UiTd p={2}>{row.variable}</UiTd>
                            </UiTr>
                          ))}
                        </UiTbody>
                      </UiTable>
                    </UiTableContainer>
                  </ZCard>
                </FormControl>
              </SimpleGrid>
            </ZModalBody>
            <ZModalButtons
              confirmText={message ? 'Update Template' : 'Create Template'}
              confirmProps={{
                type: 'submit',
              }}
              isConfirmDisabled={isLoading}
              isConfirmLoading={isLoading}
              onClose={onClose}
            />
          </form>
        </ZModalContent>
      </ZModal>
    </>
  )
}
