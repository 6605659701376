import { Box, Flex } from '@chakra-ui/react'
import type { ZCardProps } from '@postal-io/postal-ui'
import { UiLink, UiTooltip, useColor, ZCard, ZCardBody, ZCardHeader, ZText } from '@postal-io/postal-ui'
import { ZLink, ZSearchBar } from 'components/Common/ZComponents'
import { useMemo } from 'react'
import {
  MdLink,
  MdMailOutline,
  MdOutlineDrafts,
  MdOutlineFilterList,
  MdOutlineFlare,
  MdOutlineLocalShipping,
  MdOutlineMarkunreadMailbox,
  MdOutlineStyle,
  MdOutlineSwapHoriz,
} from 'react-icons/md'
import { Link } from 'react-router-dom'
import type { OrdersTypes } from './data'
import { ORDERS_TYPE_CONFIG } from './data'
import type { OrdersTabsProps } from './OrdersCategory'

const ORDERS_TYPE_CARD_STYLES: Record<OrdersTypes, any> = {
  ALL: {
    borderColor: 'atomicGray.400', // old color 'atomicBlue.500',
    iconColor: 'atomicGray.500', // old color 'atomicBlue.500',
    icon: MdOutlineFlare,
    heading: 'Orders',
    colorScheme: 'atomicBlue',
  },
  DRAFTS: {
    borderColor: 'atomicGray.400', // old color 'atomicGray.100',
    iconColor: 'atomicGray.500', // old color 'atomicGray.300',
    icon: MdOutlineDrafts,
    heading: 'Drafts',
    colorScheme: 'atomicGray',
  },
  EMAILS: {
    borderColor: 'atomicGray.400', // old color 'atomicBlue.200',
    iconColor: 'atomicGray.500', // old color 'atomicBlue.600',
    icon: MdMailOutline,
    heading: 'Emails',
    colorScheme: 'atomicBlue',
  },
  LINKS: {
    borderColor: 'atomicGray.400', // old color 'atomicPurple.300',
    iconColor: 'atomicGray.500', // old color 'atomicPurple.600',
    icon: MdLink,
    heading: 'MagicLinks',
    colorScheme: 'atomicPurple',
  },
  DIRECT: {
    borderColor: 'atomicGray.400', // old color 'vendorGreen.200',
    iconColor: 'atomicGray.500', // old color 'vendorGreen.600',
    icon: MdOutlineMarkunreadMailbox,
    heading: 'Directs',
    colorScheme: 'vendorGreen',
  },
  BULK: {
    borderColor: 'atomicGray.400', // old color 'vendorGreen.200',
    iconColor: 'atomicGray.500', // old color 'vendorGreen.600',
    icon: MdOutlineLocalShipping,
    heading: 'Bulk Sends',
    colorScheme: 'vendorGreen',
  },
  SUBSCRIPTIONS: {
    borderColor: 'atomicGray.400', // old color 'atomicGray.400',
    iconColor: 'atomicGray.500', // old color 'atomicGray.400',
    icon: MdOutlineStyle,
    heading: 'Subscriptions',
    colorScheme: 'atomicGray',
  },
  TRIGGERS: {
    borderColor: 'atomicGray.400', // old color 'atomicBlue.50',
    iconColor: 'atomicGray.500', // old color 'atomicBlue.50',
    icon: MdOutlineSwapHoriz,
    heading: 'Triggers',
    colorScheme: 'atomicBlue',
  },
  ABM: {
    borderColor: 'atomicGray.400',
    iconColor: 'atomicGray.500',
    icon: MdMailOutline,
    heading: 'Campaigns',
    colorScheme: 'atomicGray',
  },
}

interface OrderTypeHeadingProps extends ZCardProps {
  type: OrdersTypes
  showAllLink?: boolean
  tabs?: (props: OrdersTabsProps) => JSX.Element
  headerRightContent?: JSX.Element
}
export const OrdersCard: React.FC<OrderTypeHeadingProps> = ({
  type,
  children,
  tabs,
  showAllLink,
  headerRightContent = null,
  ...rest
}) => {
  const { colorCode } = useColor()

  const { iconColor, icon: Icon, heading, groupHeading, borderColor } = ORDERS_TYPE_CARD_STYLES[type]

  return (
    <ZCard
      p={0}
      pb={[2, 2, 2, 2]}
      pt={[1.5, 1.5, 1.5, 1.5]}
      gap={0}
      variant="form"
      {...rest}
    >
      <Box
        pos="absolute"
        top={0}
        left={0}
        right={0}
        bg={borderColor}
        h="6px"
        borderTopLeftRadius="10px"
        borderTopRightRadius="10px"
      />
      <ZCardHeader
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={0}
        mx={8}
        py={4}
        borderBottom="1px solid var(--chakra-colors-gray-200)"
      >
        {tabs ? (
          tabs({ color: colorCode(iconColor), icon: Icon, baseLabel: heading, groupLabel: groupHeading })
        ) : (
          <Box
            display="flex"
            alignItems="center"
          >
            <Icon color={colorCode(iconColor)} />
            <ZText
              fontSize="lg"
              fontWeight="light"
              ml={2}
            >
              {heading}
            </ZText>
          </Box>
        )}
        {headerRightContent}
      </ZCardHeader>
      <ZCardBody
        p={4}
        px={8}
        pb={4}
        m={0}
      >
        {children}
      </ZCardBody>
    </ZCard>
  )
}

interface FilterGroupOrdersButtonProps {
  isActive: boolean
  onClick: () => void
}
const FilterGroupOrdersButton: React.FC<FilterGroupOrdersButtonProps> = ({ isActive, onClick }) => (
  <UiTooltip
    label="Show future scheduled group orders"
    openDelay={500}
    hasArrow
    mt={3}
    ml={4}
  >
    <ZLink
      borderRadius="50%"
      ml={4}
      mr={-3}
      my={-3}
      h={10}
      w={10}
      justifyContent="center"
      color={isActive ? 'atomicBlue.400' : 'atomicGray.500'}
      border="2px solid"
      borderColor={isActive ? 'atomicBlue.400' : 'transparent'}
      transition="0.2s border, 0.2s color, 0.2s margin-left"
      onClick={onClick}
    >
      <MdOutlineFilterList size="22px" />
    </ZLink>
  </UiTooltip>
)

interface useOrdersCardHeaderContentArgs {
  ordersType: OrdersTypes
  showAllLink?: boolean
  showGroupOrders?: boolean
  searchString?: string
  setSearchString?: (str: string) => void
  scheduledOrders?: boolean
  setScheduledOrders?: (val: boolean) => void
}
export function useOrdersCardHeaderContent({
  ordersType,
  showAllLink,
  showGroupOrders,
  searchString,
  setSearchString,
  scheduledOrders,
  setScheduledOrders,
}: useOrdersCardHeaderContentArgs) {
  const headers = useMemo(
    () =>
      showAllLink || searchString === undefined || setSearchString === undefined ? (
        <UiLink
          fontFamily="Lexend"
          as={Link}
          fontSize="sm"
          fontWeight="normal"
          to={ORDERS_TYPE_CONFIG[ordersType].path}
        >
          Show All
        </UiLink>
      ) : (
        <Flex>
          <ZSearchBar
            mt={-2}
            width="250px"
            searchString={searchString}
            setSearchString={setSearchString}
          />
          {showGroupOrders && scheduledOrders !== undefined && setScheduledOrders && (
            <FilterGroupOrdersButton
              isActive={scheduledOrders}
              onClick={() => setScheduledOrders(!scheduledOrders)}
            />
          )}
        </Flex>
      ),
    [scheduledOrders, ordersType, searchString, setScheduledOrders, setSearchString, showAllLink, showGroupOrders]
  )

  return headers
}
