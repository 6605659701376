import { Stack, Text } from '@chakra-ui/react'
import type { UiEasyDataTableColumn } from '@postal-io/postal-ui'
import {
  joinStrings,
  UiDate,
  UiLink,
  UiStopPropagation,
  UiTag,
  UiTooltip,
  UiTruncate,
  ZLink,
  ZText,
} from '@postal-io/postal-ui'
import type { SearchableContact } from 'api'
import { AddressVerifiedBadgeV2 } from 'components/Contact/AddressVerifiedBadgeV2'
import { Link } from 'react-router-dom'

export const LIMIT = 10
export const STATIC_VARIABLES = { limit: LIMIT }
export const STALE_TIME = 1000 * 30
export const FETCH_LIMIT = 200

export const COLUMNS: UiEasyDataTableColumn<SearchableContact>[] = [
  {
    label: 'Name',
    render: ({ clickable, id, firstName, lastName }: SearchableContact & { clickable?: boolean }) =>
      clickable ? (
        <UiStopPropagation>
          <UiLink
            fontFamily="Lexend"
            color="atomicBlue.400"
            fontWeight="bold"
            as={Link}
            to={`/contacts/${id}`}
            state={{ returnTo: 'Contacts' }}
            data-private
          >
            {joinStrings([firstName, lastName])}
          </UiLink>
        </UiStopPropagation>
      ) : (
        <ZText data-private>{joinStrings([firstName, lastName])}</ZText>
      ),
  },
  {
    label: 'Email',
    render: ({ emailAddress }) => (
      <Text data-private>
        <UiTruncate
          text={emailAddress || ''}
          showTooltip
        />
      </Text>
    ),
  },
  {
    label: 'Title',
    render: ({ title }) => <Text>{title || '-'}</Text>,
  },
  {
    label: 'Company',
    render: ({ companyName }) => <Text>{companyName || '-'}</Text>,
  },
  {
    label: 'Created',
    render: ({ created }) => <UiDate date={created} />,
  },
  {
    label: 'Last Send',
    render: ({ lastSend }) => <UiDate date={lastSend} />,
  },
  {
    label: 'Verified',
    render: ({ verifiedAddress }) => (
      <AddressVerifiedBadgeV2
        ml={3.5}
        isVerified={!!verifiedAddress}
      />
    ),
  },
  {
    label: 'Items',
    render: ({ itemsSent }) => <Text>{itemsSent || '0'}</Text>,
  },
  {
    label: 'Campaigns',
    render: ({ campaigns }: SearchableContact) => campaigns?.length || 0,
  },
  {
    label: 'Tags',
    render: ({ tags }: SearchableContact) => {
      if (!tags?.length) return ''
      return (
        <UiTooltip
          label={tags.join(', ')}
          aria-label={tags.join(', ') || ''}
        >
          <Stack
            isInline
            spacing={1}
            alignItems="baseline"
          >
            {tags.slice(0, 2)?.map((t) => (
              <UiTag
                key={t}
                size="sm"
              >
                {t}
              </UiTag>
            ))}
            {tags.length > 2 && (
              <Text
                as="span"
                fontSize="xs"
              >
                +{tags.length - 2}
              </Text>
            )}
          </Stack>
        </UiTooltip>
      )
    },
  },
]

export const BASIC_COLUMNS: UiEasyDataTableColumn<SearchableContact>[] = [
  {
    label: 'Name',
    render: ({ onClick, firstName, lastName }: SearchableContact & { onClick?: () => void }) =>
      onClick ? (
        <ZLink onClick={onClick}>{joinStrings([firstName, lastName])}</ZLink>
      ) : (
        <ZText data-private>{joinStrings([firstName, lastName])}</ZText>
      ),
  },
  {
    label: 'Email',
    render: ({ emailAddress }) => <ZText data-private>{emailAddress}</ZText>,
  },
  {
    label: 'Title',
    render: ({ title }) => <ZText>{title || '-'}</ZText>,
  },
  {
    label: 'Company',
    render: ({ companyName }) => <ZText>{companyName || '-'}</ZText>,
  },
  {
    label: 'Created',
    render: ({ created }) => (
      <UiDate
        fontFamily="Lexend"
        date={created}
      />
    ),
  },
]
