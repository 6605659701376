import type { UiTagProps } from '@postal-io/postal-ui'
import { FontWeight, UiTag } from '@postal-io/postal-ui'
import type { Status } from 'api'
import React from 'react'
import { statusMap } from './PostalCard'

interface StatusTagProps extends UiTagProps {
  status: Status
  statusMap?: any
}
export const StatusTag: React.FC<StatusTagProps> = ({ status, statusMap: tagMap, ...rest }) => {
  const map = (tagMap ? tagMap[status] : statusMap[status]) || {}
  if (!map.color || !map.label) return null
  return (
    <UiTag
      textTransform="uppercase"
      backgroundColor={map.color}
      color="white"
      fontSize="xs"
      fontWeight={FontWeight.Bold}
      zIndex={1}
      px={4}
      {...rest}
    >
      {map.label}
    </UiTag>
  )
}
