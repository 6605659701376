import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { parseISO } from 'date-fns'
import Mustache from 'mustache'
import { useMemo } from 'react'
import { GetAccountDocument, MeDocument } from '../../api'

const DEFAULT_FONT = 'Arial'

const parseTemplate = (content: string, view: any) => {
  try {
    return Mustache.render(content, view)
  } catch (err) {
    return 'ERROR. Is there an unclosed tag such as `{{recipientFirstName`? Please go back and check your message.'
  }
}

export const getPreviewDocument = (title: string, body: string, fontFamily?: string | null) => {
  return `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <style>
    body {
      font-family: ${fontFamily || DEFAULT_FONT};
    }
  </style>
  <title>${title}</title>
</head>
<body>
${body}
</body>
</html>`
}

export interface UseCustomEmailPreviewProps {
  itemName: string
  eventDateTime?: string
  eventMeetingLink?: string
  subjectLine: string
  body: string
}
export const useCustomEmailPreview = ({
  itemName,
  eventDateTime,
  eventMeetingLink,
  subjectLine,
  body,
}: UseCustomEmailPreviewProps) => {
  const meQuery = useGraphqlQuery(MeDocument)
  const me = useMemo(() => meQuery.data?.me, [meQuery.data?.me])

  const accountQuery = useGraphqlQuery(GetAccountDocument)
  const account = useMemo(() => accountQuery.data?.getAccount, [accountQuery.data?.getAccount])

  const date = useMemo(() => (eventDateTime ? parseISO(eventDateTime) : new Date()), [eventDateTime])

  const view = useMemo(() => {
    return {
      // account
      accountName: account?.displayName || account?.name || 'Initech',
      accountLogo: account?.primaryLogoUrl,
      primaryColor: account?.giftEmailPrimaryColor || '#F5F8FA',
      secondaryColor: account?.giftEmailSecondaryColor || 'header.dark',
      tertiaryColor: account?.giftEmailTertiaryColor || '#EFF1F3',

      // item
      postalName: itemName,
      userMessage: 'Example user message creating during sending the item.',
      eventDate: date.toLocaleDateString([], { month: 'long', day: 'numeric', year: 'numeric' }),
      eventTime: date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', timeZoneName: 'short' }),
      meetingLink: eventMeetingLink || 'https://example.com',

      // contact
      recipientFirstName: 'Jane',
      recipientLastName: 'Doe',
      recipientFullName: 'Jane Doe',

      // me
      senderFirstName: me?.firstName,
      senderLastName: me?.lastName,
      senderFullName: [me?.firstName, me?.lastName].join(' '),
      signatureHtml: me?.emailSignatureHtml,
    }
  }, [
    account?.displayName,
    account?.giftEmailPrimaryColor,
    account?.giftEmailSecondaryColor,
    account?.giftEmailTertiaryColor,
    account?.name,
    account?.primaryLogoUrl,
    date,
    eventMeetingLink,
    itemName,
    me?.emailSignatureHtml,
    me?.firstName,
    me?.lastName,
  ])

  const subjectLinePreview = useMemo(() => parseTemplate(subjectLine, view), [subjectLine, view])
  const bodyPreview = useMemo(
    () => getPreviewDocument(subjectLinePreview, parseTemplate(body, view), account?.giftEmailFont),
    [account?.giftEmailFont, body, subjectLinePreview, view]
  )

  return { subjectLinePreview, bodyPreview }
}
