import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import type { PostalFulfillmentFilterInput } from 'api'
import { PostalFulfillmentOrderByInput, SearchPostalFulfillmentsDocument } from 'api'

export const useFulfillments = (limit: number, filter: PostalFulfillmentFilterInput) => {
  const variables = {
    filter,
    limit,
    orderBy: PostalFulfillmentOrderByInput.CreatedDesc,
  }
  const { mergedData, hasNextPage, hasPreviousPage, fetchNextPage, error, isLoading, isFetching } =
    useGraphqlInfiniteQuery(SearchPostalFulfillmentsDocument, variables, { enabled: filter !== null })

  return {
    data: mergedData?.searchPostalFulfillments || [],
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    error,
    isLoading,
    isFetching,
    variables,
  }
}
