import { ButtonGroup, Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiButtonProps } from '@postal-io/postal-ui'
import {
  UiFormControl,
  useAlerts,
  ZButton,
  ZFlowButton,
  ZFormLabel,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
  ZModalOverlay,
  ZText,
  ZTextarea,
} from '@postal-io/postal-ui'
import React, { useMemo, useState } from 'react'
import { MdCheckCircle, MdChevronRight, MdOutlineAddBox, MdThumbUpAlt } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ProductRequestDocument, SearchApprovedPostalsDocument, Status } from '../../api'
import { AnalyticsEventV2, useAnalyticsSend } from '../../hooks/useAnalytics'
import { ZLink } from '../Common/ZComponents'

interface ProductRequestButtonProps extends Omit<UiButtonProps, 'children'> {
  productId: string
  productType?: 'Event' | 'Item'
}

export const ProductRequestButton: React.FC<ProductRequestButtonProps> = ({
  productId,
  productType = 'Item',
  ...rest
}) => {
  const sendAnalytics = useAnalyticsSend()
  const Alert = useAlerts()
  const [reqReason, setReqReason] = useState('')
  const navigate = useNavigate()

  const searchPostals = useGraphqlQuery(
    SearchApprovedPostalsDocument,
    { filter: { marketplaceProductId: { eq: productId }, status: { eq: Status.Active } } },
    { enabled: !!productId }
  )
  const postals = useMemo(
    () => searchPostals.data?.searchApprovedPostals ?? [],
    [searchPostals.data?.searchApprovedPostals]
  )

  const numAlreadyApproved = useMemo(() => postals.length, [postals])
  const alreadyApproved = useMemo(() => !!numAlreadyApproved, [numAlreadyApproved])

  const requestProduct = useGraphqlMutation(ProductRequestDocument)

  const showConfirm = useDisclosure()
  const showRequest = useDisclosure()

  const doRequestProduct = async () => {
    try {
      await requestProduct.mutateAsync({ id: productId, reason: reqReason })
      setReqReason('')
      Alert.success('Your request has been sent.')
    } catch (error) {
      Alert.error(error)
    }
  }

  const handleRequest = async () => {
    sendAnalytics({ event: AnalyticsEventV2.Product_RequestItem_Clicked, data: { productId, productType } })
    if (alreadyApproved) return showConfirm.onOpen()
    else return showRequest.onOpen()
  }

  const handleRequestConfirm = async () => {
    showRequest.onClose()
    await doRequestProduct()
  }

  const handleConfirm = async () => {
    showConfirm.onClose()
    await doRequestProduct()
  }

  const handleMarketplaceNav = () => {
    navigate(`/${productType.toLowerCase()}s/postals`, { state: { marketplaceProductId: productId } })
  }

  const handlePostalNav = () => {
    navigate(`/${productType.toLowerCase()}s/postals/${postals[0]?.id}`)
  }

  return (
    <>
      <ZFlowButton
        colorScheme="atomicBlue"
        rightIcon={requestProduct.isSuccess ? <MdThumbUpAlt size="22px" /> : <MdChevronRight size="22px" />}
        leftIcon={requestProduct.isSuccess ? <MdCheckCircle size="20px" /> : <MdOutlineAddBox size="20px" />}
        isDisabled={searchPostals.isLoading || requestProduct.isLoading || requestProduct.isSuccess}
        isLoading={searchPostals.isLoading || requestProduct.isLoading}
        onClick={handleRequest}
        {...rest}
      >
        {productType === 'Item' ? (
          requestProduct.isSuccess ? (
            'Item requested!'
          ) : (
            <>Request {productType}</>
          )
        ) : (
          <>Send Request to Admin</>
        )}
      </ZFlowButton>

      <ZModal
        size="lg"
        {...showRequest}
      >
        <ZModalOverlay />
        <ZModalContent>
          <ZModalCloseButton />
          <ZModalHeader>Request Sent</ZModalHeader>
          <ZModalBody>
            <Stack spacing={8}>
              <ZText fontSize="md">Thanks for your request!</ZText>
              <ZText fontSize="md">
                We will forward your request for this event to your Admin. Please provide any additional information
                relevant to your request, including suggested dates and times for your event.
              </ZText>
              <UiFormControl>
                <ZFormLabel>Additional Information</ZFormLabel>
                <ZTextarea
                  placeholder="(Optional) Include a note to your Admin(s)"
                  value={reqReason}
                  onChange={(e) => setReqReason(e.target.value)}
                  minH={36}
                />
              </UiFormControl>
            </Stack>
          </ZModalBody>
          <ZModalFooter>
            <ButtonGroup
              w="100%"
              justifyContent="space-between"
            >
              <ZButton
                colorScheme="atomicBlue"
                onClick={handleRequestConfirm}
              >
                Send Request
              </ZButton>
              <ZButton
                colorScheme="atomicGray"
                variant="ghost"
                onClick={showRequest.onClose}
              >
                Cancel
              </ZButton>
            </ButtonGroup>
          </ZModalFooter>
        </ZModalContent>
      </ZModal>

      <ZModal
        size="lg"
        {...showConfirm}
      >
        <ZModalOverlay />
        <ZModalContent>
          <ZModalCloseButton />
          <ZModalHeader>Item Already Available</ZModalHeader>
          <ZModalBody>
            <Stack spacing={8}>
              <ZText fontSize="md">Thanks for the request!</ZText>
              <ZText fontSize="md">
                This {productType.toLowerCase()} has already been approved for your team.{' '}
                {numAlreadyApproved === 1 ? (
                  <>
                    <ZLink
                      display="inline"
                      onClick={handlePostalNav}
                      fontSize="md"
                      color="atomicBlue"
                      fontWeight="bold"
                    >
                      Click here
                    </ZLink>{' '}
                    to view it.
                  </>
                ) : (
                  <>
                    <ZLink
                      display="inline"
                      onClick={handleMarketplaceNav}
                      fontSize="md"
                      color="atomicBlue"
                      fontWeight="bold"
                    >
                      {' '}
                      Click here
                    </ZLink>{' '}
                    to view them.
                  </>
                )}
              </ZText>
              <ZText fontSize="md">Would you like to send another request to the Admin of your team?</ZText>
            </Stack>
          </ZModalBody>
          <ZModalFooter>
            <ButtonGroup
              w="100%"
              justifyContent="space-between"
            >
              <ZButton
                colorScheme="atomicBlue"
                onClick={handleConfirm}
              >
                Send Request
              </ZButton>
              <ZButton
                colorScheme="atomicGray"
                variant="ghost"
                onClick={showConfirm.onClose}
              >
                Cancel
              </ZButton>
            </ButtonGroup>
          </ZModalFooter>
        </ZModalContent>
      </ZModal>
    </>
  )
}
