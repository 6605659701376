import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiMenuItemProps } from '@postal-io/postal-ui'
import { useAlertError, useColor } from '@postal-io/postal-ui'
import { CATEGORY } from 'components/Postals'
import React from 'react'
import type { ApprovedProductVariant } from '../../api'
import { GetMarketplaceProductDocument } from '../../api'
import { PostalVariantOption } from './PostalVariantOption'

interface ApprovedPostalOptionProps extends Omit<UiMenuItemProps, 'onClick'> {
  variant: ApprovedProductVariant
  marketplaceProductId: string
  isSelected?: boolean
  category?: string
  onClick?: (variant: ApprovedProductVariant) => void
  brandingColor: string
  isDisabled?: boolean
  disabledMessage?: string
  nameCharacters?: number
  isMulti?: boolean
  largeCheckbox?: boolean
}

export const ApprovedPostalOption: React.FC<ApprovedPostalOptionProps> = ({
  marketplaceProductId,
  variant,
  isSelected = false,
  onClick,
  category,
  brandingColor,
  ...rest
}) => {
  const { Color } = useColor()
  const { data, isLoading, error } = useGraphqlQuery(GetMarketplaceProductDocument, {
    id: marketplaceProductId,
  })

  useAlertError(error)

  const marketplaceProduct = data?.getMarketplaceProduct
  const parentVariant = marketplaceProduct?.variants?.find((v) => v.id === variant.id)
  const [fulfillmentMap] = parentVariant?.fulfillmentPartnerList || []

  const [imageUrl] = variant.imageUrls || []

  const inventory = fulfillmentMap?.inventory?.available

  const handleClick = () => onClick && onClick(variant)

  const shippingOptionsPrices =
    fulfillmentMap?.shippingOptions?.map((so) => so.price).sort((priceA, priceB) => priceA - priceB) ?? []
  const shippingEstimate = shippingOptionsPrices[0] ?? 0

  return (
    <PostalVariantOption
      currency={marketplaceProduct?.currency as string}
      name={variant.variantName}
      description={variant.description}
      imageUrl={imageUrl}
      hideShipping={category === CATEGORY.Events}
      displayPrice={variant.displayPrice || 0}
      shippingEstimate={shippingEstimate}
      inventory={inventory}
      isLoading={isLoading}
      isSelected={isSelected}
      onClick={onClick ? handleClick : undefined}
      brandingColor={Color(brandingColor)}
      {...rest}
    />
  )
}
