import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import type { UiButtonSsoProps } from '@postal-io/postal-ui'
import { UiIconGoogle, UiIconMicrosoft, UiIconSalesforce, useAlerts, ZFlowButton, ZText } from '@postal-io/postal-ui'
import { ExternalProviders } from 'components/Integrations'
import { getSsoEndpoint } from 'lib'
import React from 'react'
import { MdChevronRight } from 'react-icons/md'

const providers = ExternalProviders.filter((p) => p.sso?.enabled)

export interface SsoOptionProps extends BoxProps {
  params?: Record<string, any>
  isDisabled?: boolean
  onClick?: (e: any) => void
}

export const SsoOption: React.FC<SsoOptionProps> = ({ onClick, isDisabled, params, ...props }) => {
  const Alert = useAlerts()

  const handleClick = (name: UiButtonSsoProps['name']) => {
    const provider = providers.find((p) => p?.sso?.name === name)
    const endpoint = provider?.sso?.endpoint
    if (!endpoint) return Alert.warning('This provider is currently unavailable')

    const url = getSsoEndpoint({ endpoint, params, mode: 'extension' })
    window.open(url, 'postal-sso', 'width=600,height=600,noopener=true,noreferrer=true')
    onClick && onClick(provider)
  }

  return (
    <Box
      w="100%"
      {...props}
    >
      <Flex
        direction="column"
        rowGap="10px"
        mx="auto"
      >
        <ZFlowButton
          data-testid="Button_Sso_google"
          isDisabled={isDisabled}
          name="google"
          w="full"
          onClick={() => handleClick('google')}
          leftIcon={
            <UiIconGoogle
              width="21px"
              height="21px"
              mr={3}
            />
          }
          rightIcon={<MdChevronRight />}
          bg="atomicGray.10"
          color="atomicGray.900"
          pl={5}
          _hover={{ bg: 'atomicGray.100' }}
        >
          <ZText size="md">Sign in with Google</ZText>
        </ZFlowButton>
        <ZFlowButton
          data-testid="Button_Sso_microsoft"
          isDisabled={isDisabled}
          name="microsoft"
          w="full"
          onClick={() => handleClick('microsoft')}
          leftIcon={
            <UiIconMicrosoft
              width="21px"
              height="21px"
              mr={3}
            />
          }
          rightIcon={<MdChevronRight />}
          bg="atomicGray.10"
          color="atomicGray.900"
          pl={5}
          _hover={{ bg: 'atomicGray.100' }}
        >
          <ZText size="md">Sign in with Microsoft</ZText>
        </ZFlowButton>
        <ZFlowButton
          data-testid="Button_Sso_salesforce"
          isDisabled={isDisabled}
          name="salesforce"
          w="full"
          onClick={() => handleClick('salesforce')}
          leftIcon={
            <UiIconSalesforce
              width="21px"
              height="21px"
              mr={3}
            />
          }
          rightIcon={<MdChevronRight />}
          bg="atomicGray.10"
          color="atomicGray.900"
          pl={5}
          _hover={{ bg: 'atomicGray.100' }}
        >
          <ZText size="md">Sign in with Salesforce</ZText>
        </ZFlowButton>
      </Flex>
    </Box>
  )
}
