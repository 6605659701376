import { Box, Fade, Flex, useBreakpointValue } from '@chakra-ui/react'
import type { ZProductCardProps } from '@postal-io/postal-ui'
import {
  UiScaleEffect,
  UiTruncate,
  ZButton,
  ZProductCard,
  ZProductCardBody,
  ZProductCardContent,
  ZProductCardImage,
  ZProductCardImageContainer,
  ZProductCardImageHover,
  ZProductCardImageIcons,
  ZProductCardImageIconSelect,
  ZText,
} from '@postal-io/postal-ui'
import { ZLink, ZStatusTag } from 'components/Common/ZComponents'
import React, { useCallback, useMemo } from 'react'
import type { MarketplaceProduct, SearchableProduct } from '../../api'
import { Status } from '../../api'
import { useAssets } from '../../hooks'
import { usePostalImages } from '../../hooks/usePostalImages'
import { MarketplaceProductPriceRange, SearchableProductPriceRange } from './MarketplaceTextPriceRange'

interface HoverElementProps {
  onSelect: () => void
  buttonText: string
}

const HoverElement: React.FC<HoverElementProps> = ({ onSelect, buttonText }) => (
  <Flex
    h="100%"
    flexDir="column"
    justifyContent="flex-end"
    gap={5}
    p={5}
  >
    <Box />

    <UiScaleEffect w="100%">
      <ZButton
        justifyContent="center"
        w="100%"
        fontSize="sm"
        fontWeight="bold"
        variant="outline"
        color="whiteAlpha.900"
        borderColor="whiteAlpha.900"
        borderWidth="2px"
        _hover={{ bg: 'blackAlpha.100' }}
        _active={{ bg: 'blackAlpha.200' }}
        onClick={onSelect}
      >
        {buttonText}
      </ZButton>
    </UiScaleEffect>
  </Flex>
)
interface OldMarketplaceCardProductProps extends Omit<ZProductCardProps, 'onSelect'> {
  product: MarketplaceProduct
  isBulkSelected?: boolean
  buttonText?: string
  truncateLength?: number
  onSelect?: (item: MarketplaceProduct) => any
  onBulkSelect?: (item: MarketplaceProduct) => any
}

export const OldMarketplaceCardProduct: React.FC<OldMarketplaceCardProductProps> = ({
  product,
  isBulkSelected,
  buttonText = 'View this Item',
  truncateLength = 32,
  onSelect,
  onBulkSelect,
  ...rest
}) => {
  const { assetUrlSrc } = useAssets()
  const image = usePostalImages(product)
  const srcProps = assetUrlSrc(image?.[0]?.url, { w: 350, h: 350, fit: 'clip' })

  const handleSelect = useCallback(() => onSelect?.(product), [product, onSelect])
  const handleBulkSelect = () => onBulkSelect?.(product)

  const canBulkSelect = !!onBulkSelect

  const outOfStock = useMemo(
    () => !product.variants?.filter((v) => v.status === Status.Active).length,
    [product.variants]
  )

  // since we aren't using a mouse on tablets, skip hover view and open item instead
  const enableHover = useBreakpointValue({ base: false, lg: true })

  return (
    <Fade in>
      <ZProductCard
        defaultIsOpen={isBulkSelected}
        {...rest}
      >
        <ZProductCardContent>
          <ZProductCardImageContainer
            borderWidth="1px"
            borderColor="atomicGray.100"
            onClick={enableHover ? undefined : handleSelect}
          >
            <ZProductCardImageIcons justifyContent="space-between">
              {canBulkSelect ? (
                <ZProductCardImageIconSelect
                  isSelected={isBulkSelected}
                  onClick={handleBulkSelect}
                  cursor="default"
                />
              ) : (
                <Box />
              )}
              {outOfStock && (
                <ZStatusTag
                  label="Out of Stock"
                  mt={3}
                  mr={4}
                />
              )}
            </ZProductCardImageIcons>
            <ZProductCardImage {...srcProps} />
            <ZProductCardImageHover
              jsx={
                <HoverElement
                  onSelect={handleSelect}
                  buttonText={buttonText}
                />
              }
            />
          </ZProductCardImageContainer>
          <ZProductCardBody>
            <ZLink
              mt={2}
              color="atomicGray.700"
              flexDir="column"
              alignItems="left"
              fontSize="inherit"
              lineHeight={rest.variant === 'collapse' ? 'shorter' : 'revert'}
              onClick={handleSelect}
              noOfLines={1}
            >
              {/* TODO: find a better way to solve overflow bug on collapsing cards, instead of manually adjusting truncate length */}
              <UiTruncate
                text={product.name}
                length={truncateLength}
              />
            </ZLink>
            <ZText
              color="atomicGray.500"
              lineHeight={rest.variant === 'collapse' ? 'shorter' : 'revert'}
            >
              <UiTruncate
                text={product.brandName as string}
                length={truncateLength}
              />
            </ZText>
            <MarketplaceProductPriceRange item={product} />
          </ZProductCardBody>
        </ZProductCardContent>
      </ZProductCard>
    </Fade>
  )
}

interface MarketplaceCardProductProps extends Omit<ZProductCardProps, 'onSelect'> {
  product: SearchableProduct
  isBulkSelected?: boolean
  buttonText?: string
  truncateLength?: number
  onSelect?: (item: SearchableProduct) => any
  onBulkSelect?: (item: SearchableProduct) => any
}

export const MarketplaceCardProduct: React.FC<MarketplaceCardProductProps> = ({
  product,
  isBulkSelected,
  buttonText = 'View this Item',
  truncateLength = 32,
  onSelect,
  onBulkSelect,
  ...rest
}) => {
  const { assetUrlSrc } = useAssets()
  const image = product.imageUrls || []
  const srcProps = assetUrlSrc(image?.[0], { w: 350, h: 350, fit: 'clip' })

  const handleSelect = useCallback(() => onSelect?.(product), [product, onSelect])
  const handleBulkSelect = () => onBulkSelect?.(product)

  const canBulkSelect = !!onBulkSelect

  const outOfStock = useMemo(() => !product.inventory || product.status === Status.OutOfStock, [product])

  // since we aren't using a mouse on tablets, skip hover view and open item instead
  const enableHover = useBreakpointValue({ base: false, lg: true })

  return (
    <Fade in>
      <ZProductCard
        defaultIsOpen={isBulkSelected}
        {...rest}
      >
        <ZProductCardContent>
          <ZProductCardImageContainer
            borderWidth="1px"
            borderColor="atomicGray.100"
            onClick={enableHover ? undefined : handleSelect}
          >
            <ZProductCardImageIcons justifyContent="space-between">
              {canBulkSelect ? (
                <ZProductCardImageIconSelect
                  isSelected={isBulkSelected}
                  onClick={handleBulkSelect}
                  cursor="default"
                />
              ) : (
                <Box />
              )}
              {outOfStock && (
                <ZStatusTag
                  label="Out of Stock"
                  mt={3}
                  mr={4}
                />
              )}
            </ZProductCardImageIcons>
            <ZProductCardImage {...srcProps} />
            <ZProductCardImageHover
              jsx={
                <HoverElement
                  onSelect={handleSelect}
                  buttonText={buttonText}
                />
              }
            />
          </ZProductCardImageContainer>
          <ZProductCardBody>
            <ZLink
              mt={2}
              color="atomicGray.700"
              flexDir="column"
              alignItems="left"
              fontSize="inherit"
              lineHeight={rest.variant === 'collapse' ? 'shorter' : 'revert'}
              onClick={handleSelect}
              noOfLines={1}
            >
              {/* TODO: find a better way to solve overflow bug on collapsing cards, instead of manually adjusting truncate length */}
              <UiTruncate
                text={product.title}
                length={truncateLength}
              />
            </ZLink>
            <ZText
              color="atomicGray.500"
              lineHeight={rest.variant === 'collapse' ? 'shorter' : 'revert'}
            >
              <UiTruncate
                text={'product.brandName' as string}
                length={truncateLength}
              />
            </ZText>
            <SearchableProductPriceRange product={product} />
          </ZProductCardBody>
        </ZProductCardContent>
      </ZProductCard>
    </Fade>
  )
}
