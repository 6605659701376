import type { Passwords } from '@postal-io/postal-ui'
import { useAlerts } from '@postal-io/postal-ui'
import { PageTitle, useSession } from 'hooks'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LogInHeaderBlack } from './LogInHeader'
import { MainWithMarketingPane } from './Main'
import { SetPassword } from './SetPassword'

export const ResetPassword = () => {
  const { requestId } = useParams() as any
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const { resetPassword } = useSession()

  const Alert = useAlerts()

  const onSubmit = async ({ password }: Passwords) => {
    setLoading(true)
    try {
      await resetPassword({ requestId, password })
      Alert.success('Your password is changed!')
      setLoading(false)
      navigate('/sign-in')
    } catch (err) {
      setLoading(false)
      if (err.message.startsWith('REQUEST was')) {
        Alert.warning('This password reset link has expired')
        navigate('/sign-in')
      } else {
        Alert.warning(err)
      }
    }
  }

  return (
    <>
      <PageTitle title="Reset Password" />

      <MainWithMarketingPane>
        <LogInHeaderBlack>
          <SetPassword
            onSubmit={onSubmit}
            isLoading={loading}
          />
        </LogInHeaderBlack>
      </MainWithMarketingPane>
    </>
  )
}
