import type { FlexProps } from '@chakra-ui/react'
import { Grid } from '@chakra-ui/react'
import React from 'react'
import type { IntegrationTrigger } from '../../api'
import { TriggerDetailsRow } from './TriggerDetailsRow'

interface TriggerDetailsSequenceProps extends FlexProps {
  trigger?: IntegrationTrigger
}
export const TriggerDetailsSequence: React.FC<TriggerDetailsSequenceProps> = ({ trigger, ...rest }) => {
  const sequenceId = trigger?.config?.sequenceId
  const stepId = trigger?.config?.sequenceStepId

  if (!sequenceId && !stepId) return null

  return (
    <Grid
      templateColumns="1fr 1fr"
      {...rest}
    >
      <TriggerDetailsRow
        label="Sequence ID"
        justifySelf="start"
      >
        {sequenceId || '-'}
      </TriggerDetailsRow>
      <TriggerDetailsRow
        label="Step ID"
        justifySelf="end"
        textAlign="right"
      >
        {stepId || '-'}
      </TriggerDetailsRow>
    </Grid>
  )
}
