export function combineSearchParams(searchParams: URLSearchParams, params: Record<string, string | undefined | null>) {
  const newParams = new URLSearchParams(searchParams)
  Object.entries(params).forEach(([key, value]) => {
    newParams.delete(key)
    if (value) {
      newParams.append(key, value)
    }
  })
  return newParams
}
