import { Box, Grid, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { UiConfirm, useAlerts, ZButton, ZCard, ZCardHeader, ZFormLabel, ZInput, ZInputDate } from '@postal-io/postal-ui'
import { DeleteContactUnsubscribeDocument } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { ReportType, useReport } from 'hooks'
import React, { useState } from 'react'
import { MdDownload } from 'react-icons/md'

export const RecipientNotifications: React.FC = (props) => {
  const Alert = useAlerts()

  const unsubscribeDisclosure = useDisclosure()
  const [dateRange, setDateRange] = useState<string[]>()
  const [contactEmail, setContactEmail] = useState('')
  const { createReport, isLoading } = useReport(ReportType.Unsubscribe)

  const unsubscribeContactEmail = useGraphqlMutation(DeleteContactUnsubscribeDocument)

  const handleDateRange = (e: any) => setDateRange(e.target.value)
  const handleExport = async () => {
    try {
      await createReport({ created: { between: dateRange } })
      setDateRange(undefined)
    } catch (err) {
      Alert.error('There was an error downloading the unsubscribe list: ', err)
    }
  }

  const handleContactUnsubscribe = async () => {
    if (!contactEmail.length) return Alert.warning('Please provide a valid email address')
    try {
      await unsubscribeContactEmail.mutateAsync({ emailAddress: contactEmail })

      if (unsubscribeContactEmail.data?.deleteContactUnsubscribe === false) {
        Alert.error('This email address was not found.')
      } else {
        Alert.success('Contact unsubscribed.')
      }
      setContactEmail('')
      unsubscribeDisclosure.onClose()
    } catch (err) {
      Alert.error('There was an error removing this contact: ', err)
    }
  }

  const unsubscribeIsLoading = unsubscribeContactEmail.isLoading

  return (
    <>
      <ZCard
        {...props}
        variant="form"
      >
        <ZCardHeader
          display="flex"
          alignItems="center"
          p={8}
          pb={0}
          gap={2}
          justifyContent="flex-start"
        >
          Recipient Information
          <ZInfoTooltip
            hasArrow
            label="Download information about recipient actions"
          />
        </ZCardHeader>

        <Box p={8}>
          <ZFormLabel htmlFor="dateRange">Contacts who have unsubscribed from Postal emails</ZFormLabel>
          <Grid
            templateColumns={{ base: '1fr', xl: '1fr 150px' }}
            columnGap={4}
            mb={8}
          >
            <ZInputDate
              isRange
              name="dateRange"
              id="dateRange"
              value={dateRange}
              placeholder="Date Range"
              aria-label="Date Range"
              onChange={handleDateRange}
              height="40px"
            />
            <ZButton
              onClick={handleExport}
              leftIcon={<MdDownload size="20px" />}
              colorScheme="atomicBlue"
              isLoading={isLoading}
              isDisabled={isLoading}
              justifyContent="center"
              w="100%"
            >
              Download
            </ZButton>
          </Grid>
          <ZFormLabel htmlFor="emailUnsubscribe">Unsubscribe contact</ZFormLabel>
          <Grid
            templateColumns={{ base: '1fr', xl: '1fr 150px' }}
            columnGap={4}
          >
            <ZInput
              name="emailUnsubscribe"
              id="emailUnsubscribe"
              value={contactEmail}
              placeholder="Contact email address"
              aria-label="Contact email address"
              onChange={({ target }: any) => setContactEmail(target.value)}
              mb={4}
            />
            <ZButton
              isLoading={unsubscribeIsLoading}
              isDisabled={unsubscribeIsLoading}
              w="100%"
              onClick={unsubscribeDisclosure.onOpen}
              justifyContent="center"
              colorScheme="atomicBlue"
            >
              Submit
            </ZButton>
          </Grid>
        </Box>
      </ZCard>
      {unsubscribeDisclosure.isOpen && (
        <UiConfirm
          title="Unsubscribe Contact Email"
          isOpen={unsubscribeDisclosure.isOpen}
          onConfirm={handleContactUnsubscribe}
          onClose={unsubscribeDisclosure.onClose}
          buttonColor="red"
          buttonText="Remove"
        >
          <Text>
            Are you sure you want to <strong>unsubscribe</strong> this contact?
          </Text>
        </UiConfirm>
      )}
    </>
  )
}
