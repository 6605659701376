import { Container, Stack } from '@chakra-ui/react'
import { ZButton, ZLink, ZText } from '@postal-io/postal-ui'
import { DesignTemplateEdit } from 'components/DesignTemplate'
import type { DesignTemplateProps } from 'components/DesignTemplate/designTemplateReducer'
import { dequal } from 'dequal'
import { PageTitle } from 'hooks'
import React, { useMemo, useState } from 'react'
import { MdSaveAlt } from 'react-icons/md'
import { NavbarBackButton, SecondaryNavbar } from '../PostalSend/SecondaryNavbar'

interface SideLinkProps {
  currentSide: string
  setSide: (e: string) => void
}

const SideLinks: React.FC<SideLinkProps> = ({ currentSide, setSide }) => {
  return currentSide === 'front' ? (
    <Stack
      isInline
      align="center"
      spacing={8}
    >
      <ZText
        fontSize="md"
        fontWeight="bold"
      >
        Side 1
      </ZText>
      <ZText
        color="atomicGray.400"
        fontSize="lg"
      >
        |
      </ZText>
      <ZLink
        fontSize="md"
        onClick={() => setSide('back')}
        color="atomicBlue.400"
      >
        Side 2
      </ZLink>
    </Stack>
  ) : (
    <Stack
      isInline
      align="center"
      spacing={8}
    >
      <ZLink
        fontSize="md"
        onClick={() => setSide('front')}
        color="atomicBlue.400"
      >
        Side 1
      </ZLink>
      <ZText
        color="atomicGray.400"
        fontSize="lg"
      >
        |
      </ZText>
      <ZText
        fontSize="md"
        fontWeight="bold"
      >
        Side 2
      </ZText>
    </Stack>
  )
}

interface MarketplaceProductDesignEditorProps {
  name?: string
  designTemplate?: DesignTemplateProps
  initialSide: string
  onClose: () => void
  onSave: (e: any) => void
}

export const MarketplaceProductDesignEditor: React.FC<MarketplaceProductDesignEditorProps> = ({
  name,
  designTemplate,
  initialSide = 'front',
  onClose,
  onSave,
}) => {
  const [cache, setCache] = useState(designTemplate)
  const [side, setSide] = useState(initialSide)

  // only update the cache if its not deepEqual
  // this is to avoid a rendering loop
  const handleChange = (elements: any) => {
    const newCache = { ...cache, ...elements }
    if (!dequal(cache, newCache)) setCache(newCache)
  }

  const handleSave = () => onSave(cache)

  const title = useMemo(() => `Edit Design${name ? ` - ${name}` : ''}`, [name])
  return (
    <>
      <PageTitle
        title={title}
        section="Marketplace"
      />
      <SecondaryNavbar
        // maxWidth and px match the page content
        maxWidth="100rem"
        px={{ md: 4, lg: 8, xl: 16 }}
        left={
          <NavbarBackButton
            onClick={onClose}
            label={`Back to ${name}`}
          />
        }
        right={
          <ZButton
            colorScheme="atomicBlue"
            leftIcon={<MdSaveAlt size="18px" />}
            onClick={handleSave}
          >
            Save Design
          </ZButton>
        }
        header={title}
      />
      <Container
        maxW="full"
        m={0}
        p={0}
      >
        <Stack alignItems="center">
          {side === 'front' && (
            <DesignTemplateEdit
              designTemplate={cache as DesignTemplateProps}
              side={side}
              onChange={handleChange}
              sideSelector={
                <SideLinks
                  currentSide={side}
                  setSide={setSide}
                />
              }
            />
          )}
          {side === 'back' && (
            <DesignTemplateEdit
              designTemplate={cache as DesignTemplateProps}
              side={side}
              onChange={handleChange}
              sideSelector={
                <SideLinks
                  currentSide={side}
                  setSide={setSide}
                />
              }
            />
          )}
        </Stack>
      </Container>
    </>
  )
}
