import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { UiLoadingModal, useAlerts } from '@postal-io/postal-ui'
import { ZBasicDialogButtons, ZDialog } from 'components/Common/ZComponents'
import React from 'react'
import type { ApprovedPostal, MagicLink } from '../../api'
import { CreateMagicLinkDocument, MagicLinkStatus } from '../../api'

export interface LinkCloneProps {
  link: MagicLink
  postal?: ApprovedPostal
  isOpen: boolean
  onClose: () => void
  onClone: (link?: MagicLink) => void
}
/*
  new links come in with possibly the postal selected
  existing links should come in with a link
*/
export const LinkClone: React.FC<LinkCloneProps> = ({ link, postal: _, isOpen, onClose, onClone }) => {
  const Alert = useAlerts()

  const createLink = useGraphqlMutation(CreateMagicLinkDocument)

  const onConfirm = async () => {
    const {
      expirationDate,
      maxExecutions,
      message,
      physicalMessage,
      approvedPostalId,
      variantId,
      sendAsContactOwner,
      sendAsUser,
      meetingRequestSetting,
      itemCustomizationInputs,
      formFieldList,
      landingPageCustomization,
      spendAs,
      requiresApproval,
    } = link

    const newLink = {
      name: `${link.name} - CLONE`,
      status: MagicLinkStatus.Disabled,
      expirationDate,
      maxExecutions,
      message,
      physicalMessage,
      approvedPostalId,
      variantId,
      sendAsContactOwner,
      sendAsUser,
      meetingRequestSetting,
      itemCustomizationInputs,
      formFieldList,
      landingPageCustomization,
      spendAs,
      requiresApproval,
    }

    try {
      const res = await createLink.mutateAsync({ data: newLink })
      onClone && onClone(res.createMagicLink)
      onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  if (createLink.isLoading) {
    return <UiLoadingModal isOpen={true} />
  }

  return (
    <ZDialog
      size="xl"
      title="Clone MagicLink"
      isOpen={isOpen}
      onClose={onClose}
      // isLoading={createLink.isLoading}
    >
      This action will make an <strong>INACTIVE</strong> copy of <strong>{link.name}</strong> with the same settings. Do
      you want to continue?
      <ZBasicDialogButtons
        onClose={onClose}
        onConfirm={onConfirm}
        confirmText="Clone MagicLink"
      />
    </ZDialog>
  )
}
