import { Flex, Grid, Text } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiCard,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerFooterButtons,
  UiDrawerHeader,
  UiDrawerOverlay,
  useAlerts,
} from '@postal-io/postal-ui'
import type { Contact, PlaybookDefinition, SearchableContact, SearchableContactFilterInput } from 'api'
import {
  BulkContactAddToPlaybookDocument,
  CreatePlaybookDocument,
  SearchPlaybookDefinitionsDocument,
  Status,
} from 'api'
import { PlaybookDefinitionCard } from 'components/PlaybookDefinitions/PlaybookDefinitionCard'
import { CONTACT_INVALIDATIONS, useBackgroundQueue } from 'hooks'
import React, { useState } from 'react'

interface ContactsCreatePlaybookInstanceV2Props {
  contact?: SearchableContact | Contact
  contacts?: SearchableContact[]
  filters?: SearchableContactFilterInput[]
  isOpen: boolean
  onClose: () => void
}

export const ContactsCreatePlaybookInstances: React.FC<ContactsCreatePlaybookInstanceV2Props> = ({
  contact,
  contacts,
  filters,
  isOpen,
  onClose,
}) => {
  const [isSelected, setIsSelected] = useState('')
  const Alert = useAlerts()

  const { data } = useGraphqlQuery(SearchPlaybookDefinitionsDocument, {
    filter: { status: { eq: Status.Active } },
  })

  const createPlaybook = useGraphqlMutation(CreatePlaybookDocument)

  const { queue, invalidate } = useBackgroundQueue()
  const bulkContactAdd = useGraphqlMutation(BulkContactAddToPlaybookDocument, {
    onSuccess: (data) => {
      queue(data.bulkContactAddToPlaybook)
      invalidate(CONTACT_INVALIDATIONS)
    },
  })

  const playBookDefs = (data?.searchPlaybookDefinitions as PlaybookDefinition[]) || []

  const handleAddPlaybook = async () => {
    if (!isSelected) return Alert.error(`Please select a subscription`)
    try {
      if (contact) {
        await createPlaybook.mutateAsync({
          playbookDefinitionId: isSelected,
          contactId: contact?.id,
        })
        Alert.success(`Contact was added to subscription`)
      } else if (contacts && contacts?.length > 0) {
        const selectedContacts = contacts.map((item) => item.id)
        await bulkContactAdd.mutateAsync({
          playbookDefinitionId: isSelected,
          ids: selectedContacts,
          orfilters: filters,
        })
        Alert.success(`${selectedContacts?.length} new contacts were added to a subscription`)
      } else {
        Alert.error('Please select at least one Contact to add')
      }
    } catch (err) {
      Alert.error(err)
    }
    onClose()
  }

  return (
    <>
      <UiDrawer
        size="2xl"
        isOpen={isOpen}
        onClose={onClose}
      >
        <UiDrawerOverlay />
        <UiDrawerContent>
          <UiDrawerCloseButton />
          <UiDrawerHeader>Add Contacts To Subscription</UiDrawerHeader>

          <UiDrawerBody>
            <Grid
              templateColumns="repeat(auto-fit, 350px)"
              gridGap={8}
              mt={8}
              pb={4}
              justifyContent="center"
            >
              {playBookDefs?.map((item) => (
                <PlaybookDefinitionCard
                  key={item.id}
                  definition={item}
                  onSelect={() => setIsSelected(item.id)}
                  isSelected={item.id === isSelected}
                  buttonText={`Select Subscription`}
                />
              ))}
              {!playBookDefs?.length && (
                <UiCard>
                  <Flex
                    w="100%"
                    h="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text>No Subscriptions Found</Text>
                  </Flex>
                </UiCard>
              )}
            </Grid>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiDrawerFooterButtons>
              <UiButton
                colorScheme="gray"
                onClick={onClose}
              >
                Cancel
              </UiButton>
              <UiButton onClick={handleAddPlaybook}>Add To Subscription</UiButton>
            </UiDrawerFooterButtons>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
    </>
  )
}

// interface DefinitionWrapperProps extends FlexProps {
//   isSelected: boolean
//   onSelect: () => void
// }

// const DefinitionWrapper: React.FC<DefinitionWrapperProps> = ({
//   children,
//   isSelected,
//   onSelect
// }) => {
//   return (
//     <Box
//       onClick={onSelect}
//       justifyContent="center"
//       backgroundColor="white"
//       alignItems="center"
//       display="flex"
//       flexDirection="column"
//       width="350px"
//       height="350px"
//       borderRadius="4px"
//       _hover={{
//         cursor: isSelected ? 'not-allowed' : 'pointer',
//         borderColor: isSelected ? 'green.500' : 'gray.400'
//       }}
//       borderWidth="3px"
//       borderColor={isSelected ? 'green.500' : 'gray.200'}
//     >
//       {children}
//     </Box>
//   )
// }
