import type { FormControlProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import type { UiChangeEvent } from '@postal-io/postal-ui'
import { UiFormControl, UiToggle, ZFormLabel, ZInputMoney, ZText } from '@postal-io/postal-ui'
import type { BillingAccount } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import React from 'react'
import type { Updater } from 'use-immer'
import type { BillingEditFormProps } from '../Profile/BillingAccount'

export const MIN_AMOUNT = 10000
export const MAX_AMOUNT = 100000

interface BillingReloadProps extends FormControlProps {
  billingAccount?: BillingAccount
  form: BillingEditFormProps
  setForm: Updater<BillingEditFormProps>
}

export const BillingAutoReload: React.FC<BillingReloadProps> = ({ billingAccount, form, setForm, ...rest }) => {
  const toggleSwitch = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((draft) => void (draft.current.autoReload = target.checked))
  }

  const handleReloadAmount = ({ value }: UiChangeEvent<number>) => {
    if (form?.current.autoReloadAmount !== value) {
      setForm((draft) => void (draft.current.autoReloadAmount = value))
    }
  }

  const handleBalanceMin = ({ value }: UiChangeEvent<number>) => {
    if (form?.current.autoReloadLowLimit !== value) {
      setForm((draft) => void (draft.current.autoReloadLowLimit = value))
    }
  }

  const tooltip = form.current.autoReload
    ? 'Auto-reload is turned on, to ensure your account always has funds available for what you send. You can turn it off if you prefer to manually add funds.'
    : 'Auto-reload is off. We recommend you turn it on to ensure your account always has funds available for what you send.'

  return (
    <>
      <UiFormControl
        id="autoReload"
        {...rest}
      >
        <ZFormLabel
          fontSize="md"
          display="flex"
          alignItems="center"
          color="atomicGray.600"
          pb={2}
          gap={2}
        >
          Auto Reload{' '}
          <ZInfoTooltip
            label={tooltip}
            placement="right"
            hasArrow
          />
        </ZFormLabel>
        <Box mb={8}>
          <Flex alignItems="center">
            <UiToggle
              name="autoReload"
              size="lg"
              isChecked={!!form?.current.autoReload}
              onChange={toggleSwitch}
              colorScheme="atomicBlue"
              mr={4}
            />
            <ZText fontSize="md">Enabled</ZText>
          </Flex>
        </Box>
        <Box>
          <UiFormControl
            id="autoReloadAmount"
            display="flex"
            // alignItems="center"
            isDisabled={!form?.current.autoReload}
            mb={8}
            flexDir="column"
          >
            <ZFormLabel fontSize="md">Reload Amount</ZFormLabel>
            <ZInputMoney
              isDisabled={!form?.current.autoReload}
              name="autoReloadAmount"
              min={MIN_AMOUNT}
              max={MAX_AMOUNT}
              value={form.current.autoReloadAmount as number}
              onChange={handleReloadAmount}
            />
          </UiFormControl>
          <UiFormControl
            id="autoReloadLowerLimit"
            display="flex"
            // alignItems="center"
            isDisabled={!form?.current.autoReload}
            flexDir="column"
          >
            <ZFormLabel fontSize="md">When balance falls below</ZFormLabel>

            <ZInputMoney
              isDisabled={!form?.current.autoReload}
              name="autoReloadLowerLimit"
              min={MIN_AMOUNT}
              max={MAX_AMOUNT}
              value={form.current.autoReloadLowLimit as number}
              onChange={handleBalanceMin}
            />
          </UiFormControl>
        </Box>
      </UiFormControl>
    </>
  )
}
