import { Box } from '@chakra-ui/react'
import { ResponsiveFunnel } from '@nivo/funnel'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import { useAlertError, ZCard, ZCardHeader, ZText } from '@postal-io/postal-ui'
import React, { useMemo } from 'react'
import { ReportingTooltip } from '.'
import { CrmReportType, GetCrmReportDocument } from '../../../api'
import { formatLongPrice, formatShortPrice } from '../../../lib'
import { NoDataOverlay } from '../NoDataOverlay'

interface OpenPipelineV2Props extends ZCardProps {
  refresh: boolean
  system: string
}
export const OpenPipeline: React.FC<OpenPipelineV2Props> = ({ refresh, system, ...rest }) => {
  const query = useGraphqlQuery(
    GetCrmReportDocument,
    {
      system,
      reportType: CrmReportType.OpenPipeline,
      refresh,
    },
    { enabled: !!system, staleTime: 1000 * 60 }
  )
  useAlertError(query.error)

  const data = useMemo(() => {
    const records = query.data?.getCrmReport?.queryResults?.find((f) => f?.label === 'opportunity_data')?.result
      ?.records
    if (!records) return []
    return records
      .filter((r) => !!r.stage_name && !!r.sum_amount)
      .map((r) => ({
        id: r.stage_name,
        value: r.sum_amount,
        label: r.stage_name,
      }))
  }, [query.data?.getCrmReport])

  const aggregateData = useMemo(() => {
    const records = query.data?.getCrmReport?.queryResults?.find((f) => f?.label === 'opportunity_data')?.result
      ?.records
    if (!records) return []
    return records.find((r) => !r.stage_name)
  }, [query.data?.getCrmReport])

  const colors = [
    '#49BCAE',
    '#5C9CBB',
    '#6E7BC8',
    '#805AD5',
    '#606CE0',
    '#407DEA',
    '#208FF5',
    '#00A0FF',
    '#0EAFE8',
    '#1CBFD0',
    '#29CEB9',
  ]

  return (
    <ZCard
      isLoading={query.isLoading}
      variant="form"
      {...rest}
    >
      <ZCardHeader p={4}>Open Pipeline Influenced by Postal</ZCardHeader>
      <Box h="calc(100% - 50px)">
        {data.length ? (
          <>
            <FunnelChartLabels labels={data.map((datum) => datum.label)} />
            <ResponsiveFunnel
              data={data}
              margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              interpolation="linear"
              shapeBlending={0}
              colors={colors}
              labelColor="white"
              borderWidth={0}
              beforeSeparatorLength={100}
              beforeSeparatorOffset={20}
              afterSeparatorLength={100}
              afterSeparatorOffset={20}
              currentPartSizeExtension={10}
              tooltip={({ part: { color, data } }) => (
                <ReportingTooltip
                  nameLabel="Stage"
                  name={data.label}
                  valueLabel="Total Amount"
                  value={data.value}
                  formattedValue={formatLongPrice(data.value)}
                  totalAmount={aggregateData.sum_amount}
                  formattedTotal={formatShortPrice(aggregateData.sum_amount)}
                  color={color}
                />
              )}
              motionConfig="gentle"
              valueFormat="=-.2s"
            />
          </>
        ) : (
          <NoDataOverlay />
        )}
      </Box>
    </ZCard>
  )
}

interface FunnelChartLabelsProps {
  labels: string[]
}
const FunnelChartLabels: React.FC<FunnelChartLabelsProps> = ({ labels }) => (
  <Box
    position="absolute"
    top={20}
    right={4}
    bottom={6}
    display="flex"
    flexDir="column"
    justifyContent="stretch"
  >
    {labels.map((label) => (
      <Box
        key={label}
        textAlign="right"
        flexGrow={1}
        height={`${100 / labels.length}%`}
        verticalAlign="middle"
        display="flex"
        flexDir="column"
        justifyContent="center"
      >
        <ZText fontSize={{ base: '1.25vw', lg: '.75vw' }}>{label}</ZText>
      </Box>
    ))}
  </Box>
)
