import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import {
  LexendFontWeight,
  UiButtonScrollTop,
  useAlertError,
  useInfiniteScroll,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import { PostalFulfillmentOrderByInput, SearchPostalFulfillmentsDocument } from 'api'
import { GENERAL_SEND_TYPE_INFO_MAP } from 'components/Orders/ordersColumns'
import { useAssets } from 'hooks'
import { statusMapper } from 'lib'
import React, { useMemo } from 'react'

const STATUS_COLOR_MAP: { [key: string]: string } = {
  SUCCESS: 'green.500',
  INFO: 'blue.500',
  ERROR: 'red.500',
}

interface ExtContactProfileAcitivityProps extends BoxProps {
  contactId: string
}

const LIMIT = 10

export const ExtContactProfileActivity: React.FC<ExtContactProfileAcitivityProps> = (props) => {
  const { assetUrlSrc } = useAssets()

  const searchPostalFulfillments = useGraphqlInfiniteQuery(
    SearchPostalFulfillmentsDocument,
    {
      filter: { contactId: { eq: props.contactId } },
      limit: LIMIT,
      orderBy: PostalFulfillmentOrderByInput.CreatedDesc,
    },
    { enabled: !!props?.contactId }
  )

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchPostalFulfillments.hasNextPage,
    loadMore: searchPostalFulfillments.fetchNextPage,
    loading: searchPostalFulfillments.isFetching,
  })

  const postalFulfillments = useMemo(
    () =>
      searchPostalFulfillments.mergedData?.searchPostalFulfillments?.map((fulfillment) => {
        const { src, fallbackSrc } = assetUrlSrc(fulfillment.previewImage)
        return {
          previewImageSrc: src,
          previewImageFallback: fallbackSrc,
          ...fulfillment,
        }
      }) || [],
    [assetUrlSrc, searchPostalFulfillments.mergedData]
  )

  useAlertError(searchPostalFulfillments.error)

  return (
    <Box ref={topRef}>
      {postalFulfillments.length === 0 && (
        <ZText
          py={3}
          size="md"
          color="atomicGray.700"
        >
          No Recent Activity
        </ZText>
      )}
      {postalFulfillments.map((fulfillment) => {
        return (
          <Flex
            key={fulfillment.id}
            columnGap={4}
            py={3}
          >
            <Box
              backgroundImage={fulfillment.previewImageSrc}
              backgroundPosition="center"
              backgroundSize="cover"
              width="55px"
              height="55px"
              borderWidth={1}
              borderStyle="solid"
              borderColor="atomicGray.100"
              borderRadius="5px"
            />
            <Box>
              <ZText
                size="md"
                color="atomicGray.700"
              >
                {fulfillment.itemName}
              </ZText>
              {fulfillment.sendType && (
                <ZText
                  size="sm"
                  color="atomicGray.500"
                  textTransform="capitalize"
                >
                  {GENERAL_SEND_TYPE_INFO_MAP[fulfillment.sendType].text}
                </ZText>
              )}
              <Flex>
                <ZMoney
                  cents={fulfillment.cost?.total || 0}
                  size="sm"
                  fontWeight={LexendFontWeight.Bold}
                  color="atomicGray.700"
                />
                <ZText
                  size="sm"
                  color={STATUS_COLOR_MAP[statusMapper(fulfillment.status).type]}
                >
                  &nbsp;{statusMapper(fulfillment.status).text}
                </ZText>
              </Flex>
            </Box>
          </Flex>
        )
      })}
      {postalFulfillments.length >= LIMIT && (
        <Box
          ref={bottomRef}
          mb={8}
          position="relative"
        >
          <UiButtonScrollTop
            onClick={scrollTop}
            position="absolute"
            top="0px"
            right="0px"
            isLoading={searchPostalFulfillments.isFetching}
            aria-label="scroll button"
          />
        </Box>
      )}
    </Box>
  )
}
