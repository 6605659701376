import type { BoxProps } from '@chakra-ui/react'
import { Badge, Box, Flex } from '@chakra-ui/react'
import { UiAlert, ZButton, ZCard, ZCardBody, ZCardHeader, ZText } from '@postal-io/postal-ui'
import type { ApprovedPostal } from 'api'
import { MagicEventStatus } from 'api'
import { usePostalPermissions } from 'hooks'
import type { ReactNode } from 'react'
import React, { useMemo } from 'react'
import { ExportButton } from '../../Reporting/OrderReport/ExportButton'
import { EventsApprovedPostalEventAttendees } from './EventsApprovedPostalEventAttendees'
import { EventsApprovedPostalEventRequestInfo } from './EventsApprovedPostalEventRequestInfo'

interface EventsApprovedPostalInstanceDataV2Props extends BoxProps {
  postal: ApprovedPostal
  onEdit?: () => void
}

export const EventsApprovedPostalInstanceData: React.FC<EventsApprovedPostalInstanceDataV2Props> = ({
  postal,
  onEdit,
  ...rest
}) => {
  const { canUpdate } = usePostalPermissions(postal)

  const event = postal.event
  const eventStatus = postal?.event?.status

  const filter = useMemo(() => {
    return { approvedPostalId: { eq: postal?.id } }
  }, [postal?.id])

  let componentData: ReactNode

  switch (eventStatus) {
    case MagicEventStatus.PendingConfirmation:
      componentData = (
        <>
          <ZCard borderWidth="1px">
            <ZCardHeader>Attendees</ZCardHeader>
            <ZText mb={2}>You will be able to add Attendees once the event has been approved.</ZText>
          </ZCard>
          <ZCard
            mt={8}
            borderWidth="1px"
          >
            <EventsApprovedPostalEventRequestInfo event={event} />
          </ZCard>
        </>
      )
      break
    case MagicEventStatus.ConfirmedBillingIssue:
    case MagicEventStatus.ConfirmedNeedsData:
      componentData = (
        <ZCard borderWidth="1px">
          {canUpdate && eventStatus === MagicEventStatus.ConfirmedBillingIssue && (
            <UiAlert
              mb={4}
              status="error"
              title="There was an issue with your billing information. An event manager will reach out to you with more details."
            />
          )}
          <ZCardHeader>Attendees</ZCardHeader>
          {canUpdate ? (
            <>
              <ZText>
                You will be able to add Attendees once you add a meeting link, custom message, and approve the event.
              </ZText>
            </>
          ) : (
            <ZText>You will be able to add Attendees once the event has been approved.</ZText>
          )}
          {canUpdate && eventStatus === MagicEventStatus.ConfirmedNeedsData && (
            <ZButton
              w="max-content"
              colorScheme="vendorGreen"
              onClick={onEdit}
            >
              Setup Event
            </ZButton>
          )}
        </ZCard>
      )
      break

    case MagicEventStatus.Cancelled:
    case MagicEventStatus.Complete:
    case MagicEventStatus.RegistrationClosed:
    case MagicEventStatus.AcceptingInvites:
      componentData = (
        <ZCard variant="form">
          <ZCardBody>
            <Flex
              mb={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex alignItems="center">
                <ZCardHeader>Attendees</ZCardHeader>
                <ExportButton
                  ml={3}
                  variant="outline"
                  filter={filter}
                />
              </Flex>
              {eventStatus === MagicEventStatus.Complete && (
                <Badge
                  p={2}
                  backgroundColor="atomicBlue.50"
                  color="atomicBlue.800"
                >
                  Complete
                </Badge>
              )}
              {eventStatus === MagicEventStatus.Cancelled && (
                <Badge
                  p={2}
                  backgroundColor="atomicRed.10"
                  color="atomicRed.800"
                >
                  Cancelled
                </Badge>
              )}
            </Flex>
            <EventsApprovedPostalEventAttendees approvedPostalId={postal?.id} />
          </ZCardBody>
        </ZCard>
      )
      break
  }

  return <Box {...rest}>{componentData}</Box>
}
