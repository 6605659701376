import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiSelectTypeaheadCreatableProps, UiSelectTypeaheadProps } from '@postal-io/postal-ui'
import { UiSelectTypeahead, UiSelectTypeaheadCreatable, useAlertError, useAlerts } from '@postal-io/postal-ui'
import { orderBy } from 'natural-orderby'
import { useMemo } from 'react'
import type { ContactList } from '../../api'
import { CreateContactListDocument, SearchContactListsDocument } from '../../api'

export function AutoCompleteContactList(props: UiSelectTypeaheadProps<ContactList, true>) {
  const query = useGraphqlQuery(SearchContactListsDocument, { limit: 100 })
  useAlertError(query.error)

  const items = useMemo(() => orderBy(query.data?.searchContactLists || [], ['name']), [query.data?.searchContactLists])

  return (
    <UiSelectTypeahead
      data-testid="AutoCompleteContactList"
      options={items}
      getOptionLabel={(t) => t.name}
      getOptionValue={(t) => t.id}
      isLoading={query.isLoading}
      placeholder="Search Lists"
      noOptionsMessage={() => 'No Lists Found'}
      isMulti
      {...props}
    />
  )
}

/*
  We are converting the id on these tags to be the same as the name.

  This is done because contacts don't have a reference to tag objects, they just
  have an array of strings. In order to set an initial value, we need to pass in
  an array of objects without an id.

  The tag names returned to a user are unique so we are safe here in assuming that
  the name can be referrenced as an id.
*/
export function AutoCompleteContactListCreatable(
  props: UiSelectTypeaheadCreatableProps<ContactList, true> & {
    onCreate?: (list: ContactList) => void
  }
) {
  const query = useGraphqlQuery(SearchContactListsDocument, { limit: 100 })
  const mutation = useGraphqlMutation(CreateContactListDocument)
  useAlertError(query.error)

  const Alert = useAlerts()

  const items = useMemo(() => {
    return orderBy(query.data?.searchContactLists?.filter((list) => list.name.toLowerCase() !== 'favorites') || [], [
      'name',
    ])
  }, [query.data?.searchContactLists])

  const handleCreate = async (value: string) => {
    try {
      const res = await mutation.mutateAsync({ data: { name: value } })
      Alert.success('List created')
      props.onCreate && props.onCreate(res.createContactList)
    } catch (err) {
      Alert.warning(err)
    }
  }

  const { onCreateOption, onCreate, ...rest } = props

  return (
    <UiSelectTypeaheadCreatable
      data-testid="AutoCompleteContactListCreatable"
      name="contactList"
      options={items}
      getOptionLabel={(t) => t.name}
      getOptionValue={(t) => t.id}
      isLoading={query.isLoading}
      placeholder="Search Lists"
      onCreateOption={handleCreate}
      isMulti
      {...rest}
    />
  )
}
