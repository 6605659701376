import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiInputProps } from '@postal-io/postal-ui'
import { UiInput } from '@postal-io/postal-ui'
import React, { useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import type { SearchUsersQueryVariables } from '../../api'
import { SearchNoAccessUsersDocument } from '../../api'

interface DatalistNoAccessUserProps extends UiInputProps {
  rootProps?: BoxProps
}
export const DatalistNoAccessUser: React.FC<DatalistNoAccessUserProps> = ({ rootProps, ...rest }) => {
  const [debounced] = useDebounce(rest.value, 400)
  const variables = useMemo<SearchUsersQueryVariables>(() => {
    return {
      filter: { userName: debounced ? { contains: debounced as string } : undefined },
      limit: 20,
    }
  }, [debounced])
  const query = useGraphqlQuery(SearchNoAccessUsersDocument, variables)
  const items = useMemo(() => query.data?.searchNoAccessUsers.users || [], [query.data?.searchNoAccessUsers])
  return (
    <Box {...rootProps}>
      <UiInput
        list="users-list"
        type="email"
        placeholder="Email Address"
        {...rest}
      />
      <datalist id="users-list">
        {items.map((user) => {
          return (
            <option
              key={user.id}
              value={user.userName}
            />
          )
        })}
      </datalist>
    </Box>
  )
}
