import { Box, Flex, Grid } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButtonScrollTop,
  UiSidePanelContainer,
  UiSkeleton,
  useAlertError,
  useInfiniteScroll,
  ZProductCard,
} from '@postal-io/postal-ui'
import { CATEGORY } from 'components/Postals'
import { APPROVED_POSTAL_FILTER_INPUT_KEYS } from 'components/Postals/data'
import { AnalyticsEvent, useAnalyticsEvent, useAnalyticsSend } from 'hooks'
import { cloneDeep, omit, pick } from 'lodash'
import { useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import type { ApprovedPostal } from '../../api'
import { SearchApprovedPostalsDocument } from '../../api'
import { SidePanelFilter, SubnavFilters } from '../Common'
import { ApprovedPostalsEmpty } from '../Postals'
import { EVENT_CARD_MIN_WIDTH } from './data'
import { EventNotFound } from './EventCard'
import { EventsCardPostal } from './EventsCardPostal'
import { EventsContext } from './EventsPage'

const LIMIT = 20

const INCLUDE_FILTERS = ['shipTo', 'currency', 'fulfillmentType', 'eventStatus', 'type']

export const EventsViewPostals = () => {
  const navigate = useNavigate()
  useAnalyticsEvent({ event: AnalyticsEvent.EventsMyEventsPageViewed })

  const { eventFilters, setFilterResultsCount } = useContext(EventsContext)
  const numFilters = Object.keys(eventFilters.filters ?? []).length

  // override subnav filters to lock in category
  const subnavFilters = useMemo(() => omit(cloneDeep(eventFilters.filters), ['category']), [eventFilters.filters])

  const filter = useMemo(
    () => pick(eventFilters.graphqlFilter, APPROVED_POSTAL_FILTER_INPUT_KEYS),
    [eventFilters.graphqlFilter]
  )

  // load approved postals
  const searchPostals = useGraphqlInfiniteQuery(SearchApprovedPostalsDocument, {
    filter,
    limit: LIMIT,
  })
  const postals = useMemo(
    () => searchPostals.mergedData?.searchApprovedPostals || [],
    [searchPostals.mergedData?.searchApprovedPostals]
  )
  useAlertError(searchPostals.error)

  //Check to see if user has at least one event
  const hasOneQuery = useGraphqlQuery(SearchApprovedPostalsDocument, {
    limit: 1,
    filter: { category: { eq: CATEGORY.Events } },
  })
  const isEmpty = useMemo(
    () => !hasOneQuery.data?.searchApprovedPostals?.length,
    [hasOneQuery.data?.searchApprovedPostals?.length]
  )
  useAlertError(hasOneQuery.error)

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchPostals.hasNextPage,
    loadMore: searchPostals.fetchNextPage,
    loading: searchPostals.isFetching,
  })

  const sendAnalytics = useAnalyticsSend()

  const handleSelectPostal = (item: ApprovedPostal) => {
    sendAnalytics({
      event: AnalyticsEvent.EventsMyEventClicked,
      data: { marketplaceProductId: item.marketplaceProductId, itemName: item.name },
    })
    navigate(`/events/postals/${item.id}`, { state: { returnTo: 'My Events' } })
  }

  useEffect(() => {
    setFilterResultsCount?.(postals.length)
  }, [postals.length, setFilterResultsCount])

  return (
    <>
      <SubnavFilters
        data-testid="Events_Postals_SubnavFilters"
        filters={subnavFilters}
        onUpdate={eventFilters.updateFilter}
        onClear={eventFilters.clearFilters}
        display={numFilters < 1 ? 'none' : undefined}
      />
      <UiSkeleton isLoaded={!hasOneQuery.isLoading}>
        {isEmpty ? (
          <ApprovedPostalsEmpty isEvents />
        ) : (
          <UiSidePanelContainer>
            <SidePanelFilter
              filters={eventFilters.filters}
              onUpdate={eventFilters.updateFilter}
              filterType="ApprovedPostal"
              restrictCategory={CATEGORY.Events}
              includeFilters={INCLUDE_FILTERS}
              excludeFilters={['outOfStock']}
            />
            <Flex
              direction="column"
              w="100%"
              ref={topRef}
              style={{ scrollMargin: '20px' }}
            >
              <Grid
                templateColumns={`repeat(auto-fill, minmax(${EVENT_CARD_MIN_WIDTH}px, 1fr))`}
                gap={8}
              >
                {postals.map((item) => (
                  <EventsCardPostal
                    key={item.id}
                    postal={item}
                    onSelect={handleSelectPostal}
                  />
                ))}
                {searchPostals.isFetching && postals.length < 1 && (
                  <ZProductCard
                    isLoading
                    size="tall"
                    w={EVENT_CARD_MIN_WIDTH}
                    startColor="atomicGray.50"
                    endColor="atomicGray.200"
                  />
                )}
                {!searchPostals.isFetching && postals.length < 1 && <EventNotFound />}
              </Grid>
              {postals.length >= LIMIT && (
                <Box
                  ref={bottomRef}
                  position="relative"
                >
                  <UiButtonScrollTop
                    onClick={scrollTop}
                    position="absolute"
                    top="0px"
                    right="0px"
                    isLoading={searchPostals.isFetching}
                    aria-label="scroll button"
                  />
                </Box>
              )}
            </Flex>
          </UiSidePanelContainer>
        )}
      </UiSkeleton>
    </>
  )
}
