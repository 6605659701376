import { Box, Flex, Grid } from '@chakra-ui/react'
import { createId } from '@paralleldrive/cuid2'
import {
  objectCastInt,
  UiTabList,
  UiTabPanel,
  UiTabPanels,
  UiTabs,
  useAlerts,
  useTimeTravel,
  ZTab,
  ZText,
} from '@postal-io/postal-ui'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import type { ReactElement } from 'react'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Layer, Stage } from 'react-konva'
import { useDebouncedCallback } from 'use-debounce'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Assets } from './Assets'
import { BleedLine } from './BleedLine'
import { CutLine } from './CutLine'
import type { DesignTemplateProps } from './designTemplateReducer'
import { designTemplateReducer } from './designTemplateReducer'
import { Element } from './Element'
import { ElementEdit } from './ElementEdit'
import { createElement } from './elementFactory'
import { Grid as CanvasGrid, GRID_SIZE } from './Grid'
import { Layers } from './Layers'
import { ToolbarButtons } from './ToolbarButtons'

const MAX_SCALE = 1.5

export interface DesignTemplateEditProps {
  designTemplate: DesignTemplateProps
  side: 'front' | 'back'
  onChange: (e: any) => void
  sideSelector?: ReactElement
}

export const DesignTemplateEdit: React.FC<DesignTemplateEditProps> = ({
  designTemplate,
  side,
  sideSelector,
  onChange,
}) => {
  // TAB CONTROLS
  const [tabIndex, setTabIndex] = React.useState(0)
  const handleTabsChange = (index: number) => setTabIndex(index)

  const Alert = useAlerts()

  const ref = useRef<any>()

  const { dpi, outputSize, [side]: elements } = designTemplate
  const [scale, setScale] = useState(1)

  const canvasWidth = outputSize.width / (dpi / 100)
  const canvasHeight = outputSize.height / (dpi / 100)

  const calculateScale = useDebouncedCallback(() => {
    if (!ref.current) return
    const rect = ref.current.getBoundingClientRect()
    const desiredWidth = rect.width
    const scale = desiredWidth / canvasWidth
    setScale(Math.min(scale, MAX_SCALE))
  }, 10)

  useLayoutEffect(() => {
    window.addEventListener('resize', calculateScale)
    calculateScale()
    return () => window.removeEventListener('resize', calculateScale)
  }, [calculateScale])

  const { state, dispatch, canUndo, canRedo, canReset } = useTimeTravel(
    designTemplateReducer,
    {
      elements: elements.map((e: any) => ({ ...e, id: e.id || createId() })),
      selected: null,
      showGrid: false,
      snapGrid: false,
      gridSize: GRID_SIZE,
    },
    {
      exclude: ['TOGGLE_GRID', 'RESIZE_GRID', 'TOGGLE_SNAP_GRID', 'TOGGLE_SELECTED', 'SELECT', 'UNSELECT', 'WARN'],
      log: true,
    }
  )

  useEffect(() => {
    const message = state.warning
    if (message) {
      dispatch({ type: 'WARN', payload: '' })
      Alert.warning(message)
    }
  }, [Alert, dispatch, state.warning])

  // only send back changes if the new template is not deepEqual
  useDeepCompareEffect(() => {
    onChange && onChange({ [side]: state.elements })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [side, state.elements])

  const selectedElement = state.elements.find((e: any) => e.id === state.selected)

  const dropRef = useRef<any>()
  const stageRef = useRef<any>()

  const handleDragStart = (obj: any): void => {
    dropRef.current = obj
    document.body.style.cursor = 'grabbing'
  }

  const handleDragEnd = () => {
    document.body.style.cursor = ''
  }

  const handleDragOver = (e: React.DragEvent<any>) => e.preventDefault()

  const handleDrop = (e: any) => {
    if (!dropRef.current) return
    stageRef.current.setPointersPositions(e)
    const { x, y } = stageRef.current.getPointerPosition()
    const location = { x: x / scale, y: y / scale }
    const element = createElement(dropRef.current, objectCastInt(location))
    dropRef.current = null
    dispatch({ type: 'ADD', payload: element })
    setTabIndex(1)
  }

  const handleSelectClick = () => setTabIndex(1)

  const handleWrapperClick = (e: any) => {
    const IGNORE_CLICKS = ['CANVAS', 'BUTTON', 'SVG', 'PATH']
    const tagName = e.target.tagName?.toUpperCase()
    /* @ts-ignore */
    if (!IGNORE_CLICKS.includes(tagName)) dispatch({ type: 'UNSELECT' })
  }

  // Not sure if there's anywhere else in the app that has tabs styled like this.
  // We may want to add this to library later on.
  const tabProps = {
    px: 4,
    _notFirst: { ml: 0 },
    sx: { '& button': { p: 2 } },
  }

  return (
    <Grid
      bg="white"
      templateColumns={{
        base: '1fr',
        xl: '1fr 400px',
      }}
      gridGap={32}
      w="100%"
      pl={32}
      justifyItems="center"
      h="100%"
    >
      <Flex
        bg="white"
        w="100%"
        overflowY={{ base: 'visible', xl: 'scroll' }}
        ref={ref}
        flexDir="column"
        alignItems="center"
        onClick={handleWrapperClick}
        pt={4}
      >
        {sideSelector ? sideSelector : null}
        <ToolbarButtons
          element={selectedElement}
          canUndo={canUndo}
          canRedo={canRedo}
          canReset={canReset}
          showGrid={!!state.showGrid}
          snapGrid={!!state.snapGrid}
          dispatch={dispatch}
          mt={4}
        />
        <Flex
          justifyContent="space-between"
          w={canvasWidth * scale}
          position="relative"
          mb={2}
        >
          <ZText
            fontWeight="bold"
            fontSize="xs"
            display="flex"
            gap={1}
            color="atomicGray.600"
          >
            Bleed Line
            <ZInfoTooltip
              size="14px"
              label="The bleed line is where all artwork must extend to in order to ensure there are no white spaces left over after the cut"
            />
          </ZText>
          <ZText
            fontWeight="bold"
            fontSize="xs"
            display="flex"
            gap={1}
            position="absolute"
            top={14}
            left={8}
            zIndex={100}
            color="atomicGray.600"
          >
            Cut Line
            <ZInfoTooltip
              size="14px"
              label="The cut line is the where the printer targets the cut"
            />
          </ZText>
        </Flex>
        <Grid
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          w="100%"
          justifyItems="center"
        >
          {scale && (
            <Stage
              width={canvasWidth * scale}
              height={canvasHeight * scale}
              ref={stageRef}
              scaleX={scale}
              scaleY={scale}
            >
              <Layer>
                {state.elements.map((element: any) => {
                  return (
                    <Element
                      key={element.id}
                      element={element}
                      isEditable={true}
                      isSelected={element.id === state.selected}
                      dispatch={dispatch}
                      snapGrid={state.showGrid && state.snapGrid}
                      gridSize={state.gridSize}
                      onSelect={handleSelectClick}
                    />
                  )
                })}
              </Layer>
              <Layer>
                <CutLine
                  width={canvasWidth}
                  height={canvasHeight}
                />
                <BleedLine
                  width={canvasWidth}
                  height={canvasHeight}
                />
                {state.showGrid && (
                  <CanvasGrid
                    width={canvasWidth}
                    height={canvasHeight}
                    gridSize={state.gridSize}
                  />
                )}
              </Layer>
            </Stage>
          )}
        </Grid>
      </Flex>
      <Box
        bg="white"
        borderLeftWidth={{ base: 0, xl: '1px' }}
        borderColor="atomicGray.400"
        w="100%"
        maxW="800px"
        overflowY={{ base: 'visible', xl: 'scroll' }}
      >
        <UiTabs
          isFitted
          index={tabIndex}
          onChange={handleTabsChange}
        >
          <UiTabList
            borderColor="atomicGray.400"
            borderBottomWidth="1px"
          >
            <ZTab {...tabProps}>Media</ZTab>
            <ZTab {...tabProps}>Element</ZTab>
            <ZTab {...tabProps}>Layers</ZTab>
          </UiTabList>
          <UiTabPanels mb={16}>
            <UiTabPanel p={0}>
              <Assets onDrag={handleDragStart} />
            </UiTabPanel>
            <UiTabPanel p={0}>
              <ElementEdit
                element={selectedElement}
                dispatch={dispatch}
                canvasHeight={canvasHeight}
                canvasWidth={canvasWidth}
              />
            </UiTabPanel>
            <UiTabPanel p={0}>
              <Layers
                dispatch={dispatch}
                elements={state.elements}
                selected={selectedElement}
              />
            </UiTabPanel>
          </UiTabPanels>
        </UiTabs>
      </Box>
    </Grid>
  )
}
