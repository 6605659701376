import { Grid } from '@chakra-ui/react'
import { ZInputDate, ZLink, ZSelectTypeahead } from '@postal-io/postal-ui'
import type { BillingAccount } from 'api'
import { truncate } from 'lodash'
import { useCallback, useMemo } from 'react'

interface Option {
  label: string
  value: string
  name: string
}

interface BalanceTransferHistoryFilterProps {
  filter: Record<string, any>
  filterCount: number
  setFilter: ({ key, value }: any) => void
  resetFilter?: (filter?: Record<string, any> | undefined) => void
  billingAccounts: BillingAccount[]
}

export const BalanceTransferHistoryFilter: React.FC<BalanceTransferHistoryFilterProps> = ({
  filter,
  filterCount,
  setFilter,
  resetFilter,
  billingAccounts,
}) => {
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ key: e.target.name, value: e.target.value })
  }

  const handleSelect = useCallback(
    (key: any, value: any) =>
      setFilter({
        key,
        value,
      }),
    [setFilter]
  )

  // FROM BILLING ACCOUNT
  const fromBillingAccountOptions: Option[] = useMemo(() => {
    return billingAccounts?.map((account) => ({
      label: truncate(account.name, { length: 25 }),
      value: account.id,
      name: 'fromBillingAccountId',
    }))
  }, [billingAccounts])

  const fromBillingAccountValue = useMemo(() => {
    return fromBillingAccountOptions?.find((option) => option.value === filter.fromBillingAccountId)
  }, [fromBillingAccountOptions, filter.fromBillingAccountId])

  // TO BILLING ACCOUNT
  const toBillingAccountOptions: Option[] = useMemo(() => {
    return billingAccounts
      .filter((account) => account.id !== filter.fromBillingAccountId)
      .map((account) => ({
        label: truncate(account.name, { length: 25 }),
        value: account.id,
        name: 'toBillingAccountId',
      }))
  }, [billingAccounts, filter.fromBillingAccountId])

  const toBillingAccountValue = useMemo(() => {
    return toBillingAccountOptions?.find((option) => option.value === filter.toBillingAccountId)
  }, [toBillingAccountOptions, filter.toBillingAccountId])

  return (
    <Grid
      templateColumns="1fr 1fr 1fr 100px"
      gap={4}
      w="100%"
    >
      <ZSelectTypeahead
        value={fromBillingAccountValue}
        options={fromBillingAccountOptions}
        onChange={(option) => handleSelect('fromBillingAccountId', option?.value)}
        getOptionLabel={(t) => t.label}
        getOptionValue={(t) => t.value}
        placeholder="From Account..."
        noOptionsMessage={() => 'No Account Found'}
        rootProps={{ fontSize: 'md', fontWeight: 'medium' }}
      />
      <ZSelectTypeahead
        value={toBillingAccountValue}
        options={toBillingAccountOptions}
        onChange={(option) => handleSelect('toBillingAccountId', option?.value)}
        getOptionLabel={(t) => t.label}
        getOptionValue={(t) => t.value}
        placeholder="To Account..."
        noOptionsMessage={() => 'No Account Found'}
        rootProps={{ fontSize: 'md', fontWeight: 'medium' }}
      />
      <ZInputDate
        isRange
        name="created"
        id="created"
        value={filter.created ?? ''}
        placeholder="Date Range"
        aria-label="Date Range"
        onChange={handleInput}
        fontWeight="normal"
        height="40px"
      />

      <ZLink
        alignSelf="center"
        color="atomicBlue.400"
        onClick={() => resetFilter?.()}
        isDisabled={filterCount === 0}
        textAlign="center"
      >
        Clear All
      </ZLink>
    </Grid>
  )
}
