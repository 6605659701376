import { NavbarContext } from 'components/Main'
import { useContext, useEffect } from 'react'

/**
 * Allows one to pass a contextual navbar in.
 *
 * NOTE: YOU MUST MANUALLY ADD YOUR DEPS, THE LINTER WILL NOT DO THIS FOR YOU.
 * Not adding deps will cause an infinite loop.
 */
export const useNavbarOverride = (navbar: any, deps: any[]) => {
  if (!deps) throw new Error('Please add a dependency array.')

  const { setNavbarOverride } = useContext(NavbarContext)

  useEffect(() => {
    setNavbarOverride(navbar)
    return () => setNavbarOverride()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
