import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { LexendFontWeight, UiSkeleton, useAlerts, ZButton, ZText } from '@postal-io/postal-ui'
import type { NamedProductAccess } from 'api'
import { NamedProductAccessListDocument } from 'api'
import { SimpleDrawer } from 'components/Common'
import { useSession } from 'hooks'
import { compact, isEmpty, sortBy, uniq } from 'lodash'
import React, { useCallback, useMemo } from 'react'

interface ExtAccountPickerProps {
  isOpen: boolean
  onClose: () => void
}

export const ExtAccountPicker: React.FC<ExtAccountPickerProps> = ({ isOpen, onClose }) => {
  const Alert = useAlerts()

  const namedProductAccessList = useGraphqlQuery(NamedProductAccessListDocument)

  // we seem to be getting some bad data back here so lets
  // filter for all the things we need
  const filteredAccounts = useMemo(() => {
    return sortBy(
      namedProductAccessList.data?.namedProductAccessList?.filter((item) => {
        return (
          item?.productAccess?.product === process.env.REACT_APP_PRODUCT_ID &&
          !isEmpty(item?.productAccess?.roles) &&
          !isEmpty(item?.productAccess?.id) &&
          !isEmpty(item?.accountName)
        )
      }) || [],
      ['accountName', 'itemName']
    ) as NamedProductAccess[]
  }, [namedProductAccessList.data?.namedProductAccessList])

  const showAccountName = useMemo(() => {
    return uniq(filteredAccounts.map((f) => f.accountName).filter(Boolean)).length > 1
  }, [filteredAccounts])

  const accountName = useCallback(
    (account: NamedProductAccess) => {
      return showAccountName
        ? compact([account.accountName, account.teamName]).join(' - ')
        : account.teamName || account.accountName
    },
    [showAccountName]
  )

  const { session, switchSession } = useSession()

  const { productAccessId } = session

  const switchAccount = async (account: NamedProductAccess) => {
    if (account.productAccess.id === productAccessId) return
    try {
      await switchSession(account.productAccess.id, '/extension')
    } catch (err) {
      Alert.error(err)
    } finally {
      onClose()
    }
  }

  const currentAccount = useMemo(() => {
    return filteredAccounts.find((account) => account.productAccess.id === productAccessId) as NamedProductAccess
  }, [filteredAccounts, productAccessId])

  const accountNest = useMemo(() => {
    const bigObj = {} as any
    filteredAccounts.forEach((item: any) => {
      bigObj[item.accountName] = bigObj[item.accountName] || []
      bigObj[item.accountName].push(item)
    })
    return bigObj
  }, [filteredAccounts])

  const ACCOUNT_NAMES = Object.keys(accountNest)
  const ACCOUNT_COLORS = ['atomicBlue.400', 'atomicBlue.600', 'atomicBlue.800'] // ['primary', 'secondary', 'tertiary']

  return (
    <SimpleDrawer
      header={
        <Box
          pb="5px"
          borderBottom="2px solid #E0E6ED"
        >
          <ZText
            size="lg"
            fontWeight={LexendFontWeight.Bold}
            color="atomicGray.900"
            pb="5px"
          >
            Switch Account
          </ZText>
          <Flex columnGap="5px">
            <ZText color="atomicGray.500">Currently logged in as </ZText>
            <ZText color="atomicGray.600">{currentAccount && accountName(currentAccount)}</ZText>
          </Flex>
        </Box>
      }
      isOpen={isOpen}
      onClose={onClose}
      buttons={
        <ZButton
          bg="white"
          border={1}
          borderStyle="solid"
          borderColor="atomicGray.300"
          borderRadius="3px"
          width="100%"
          onClick={onClose}
          _hover={{
            bg: 'atomicGray.10',
            borderColor: 'atomicGray.300',
          }}
        >
          <ZText
            color="atomicGray.600"
            fontWeight={LexendFontWeight.Bold}
          >
            Cancel
          </ZText>
        </ZButton>
      }
    >
      <UiSkeleton
        h="100%"
        minH="400px"
        isLoaded={!namedProductAccessList.isLoading}
      >
        <Stack
          spacing={0}
          px={0}
          py={4}
        >
          {ACCOUNT_NAMES.map((item: string, idx: number) => {
            const color = ACCOUNT_COLORS[idx % ACCOUNT_COLORS.length]
            const accountList = accountNest[item]
            const mainAccount = accountList.find((item: NamedProductAccess) => !item.teamName)

            const isActive = currentAccount?.productAccess?.id === mainAccount?.productAccess?.id

            return (
              <Flex
                direction="column"
                key={`${item}-${idx}`}
              >
                <Flex
                  alignItems="center"
                  columnGap={3}
                  p={3}
                  mb={3}
                  bg={isActive ? 'atomicBlue.10' : 'white'}
                  borderRadius="3px"
                >
                  <AccountNameIcon
                    name={item}
                    color={color}
                  />
                  <ZText
                    color={isActive ? 'atomicGray.900' : 'atomicGray.600'}
                    cursor={!isActive ? 'pointer' : ''}
                    onClick={() => {
                      !isActive && switchAccount(mainAccount)
                    }}
                  >
                    {item}
                  </ZText>
                </Flex>
                {accountList.map((item: NamedProductAccess) => {
                  return (
                    <AccountListItem
                      key={item.productAccess.id}
                      item={item}
                      onSelect={switchAccount}
                      currentAccount={currentAccount}
                    />
                  )
                })}
              </Flex>
            )
          })}
        </Stack>
      </UiSkeleton>
    </SimpleDrawer>
  )
}

const AccountListItem: React.FC<{
  item: NamedProductAccess
  currentAccount: NamedProductAccess
  onSelect: (e: NamedProductAccess) => void
}> = ({ item, onSelect, currentAccount }) => {
  const isActive = currentAccount?.productAccess?.id === item.productAccess.id

  return (
    <>
      {item.teamName && (
        <ZText
          ml={9}
          fontSize="md"
          onClick={() => {
            !isActive && onSelect(item)
          }}
          cursor={!isActive ? 'pointer' : ''}
          color={!isActive ? 'atomicGray.600' : 'atomicGray.900'}
          mb={3}
        >
          {item.teamName}
        </ZText>
      )}
    </>
  )
}

const AccountNameIcon: React.FC<{ name: string; color: string }> = ({ name, color = 'atomicBlue.800' }) => {
  const firstLetter = name[0]
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      p={3}
      boxSize="12px"
      borderRadius="100%"
      backgroundColor={color}
      // border="2px"
      // borderColor="gray.200"
      // mr={2}
    >
      <Text
        fontSize="sm"
        fontWeight="bold"
        color="white"
        textTransform="uppercase"
      >
        {firstLetter}
      </Text>
    </Flex>
  )
}
