import { Stack } from '@chakra-ui/react'
import { UiDangerous, UiTagStack, ZCardBody, ZCardDivider, ZCardHeader, ZText } from '@postal-io/postal-ui'
import { usePostalPermissions, usePostalTeams } from 'hooks'
import React from 'react'
import type { ApprovedPostal } from '../../../api'
import { MagicEventStatus } from '../../../api'
import { AttributionTag, getAttribution } from '../../Integrations'
import { EventsApprovedPostalEventInfo } from './EventsApprovedPostalEventInfo'
import { EventsApprovedPostalEventMeetingInfo } from './EventsApprovedPostalEventMeetingInfo'

interface EventsApprovedPostalDataV2Props {
  postal: ApprovedPostal
  onEdit?: () => void
}

export const EventsApprovedPostalData: React.FC<EventsApprovedPostalDataV2Props> = ({ postal, onEdit }) => {
  const { canUpdate } = usePostalPermissions(postal)
  const { description } = postal

  const { status, meetingLink } = postal.event ?? {}

  const attribution = getAttribution(postal.attribution)

  const postalTeams = usePostalTeams(postal)

  return (
    <Stack>
      <ZCardBody>
        <ZText
          mt={2}
          whiteSpace="break-spaces"
        >
          <UiDangerous html={description} />
        </ZText>
      </ZCardBody>

      <ZCardDivider />

      <ZCardBody>
        <EventsApprovedPostalEventInfo postal={postal} />
      </ZCardBody>

      {status !== MagicEventStatus.PendingConfirmation && (
        <>
          <ZCardDivider />
          <ZCardBody>
            <ZCardHeader>Meeting Link</ZCardHeader>
            <EventsApprovedPostalEventMeetingInfo
              meetingLink={meetingLink}
              onClick={canUpdate ? onEdit : undefined}
            />
          </ZCardBody>
        </>
      )}

      {!!attribution && (
        <>
          <ZCardDivider />
          <ZCardBody>
            <ZCardHeader mb={2}>External Campaign</ZCardHeader>
            <AttributionTag {...attribution} />
          </ZCardBody>
        </>
      )}

      {canUpdate && !postalTeams.isLoading && (
        <>
          <ZCardDivider />
          <ZCardBody>
            <ZCardHeader>Available Teams</ZCardHeader>
            {!!postalTeams.teamNames?.length ? (
              <UiTagStack tags={postalTeams.teamNames} />
            ) : (
              <UiTagStack tags={['All Teams']} />
            )}
          </ZCardBody>
        </>
      )}
    </Stack>
  )
}
