import { SimpleGrid } from '@chakra-ui/react'
import { ZSidebar, ZSidebarBanner } from '@postal-io/postal-ui'
import { CenteredBox } from 'components/Common'
import { AccountNotifications } from 'components/Notifications/V1/AccountNotifications'
import { EmailDigestSettingsDisplay } from 'components/Notifications/V1/EmailDigestSettingsDisplay'
import { RecipientNotifications } from 'components/Notifications/V1/RecipientNotifications'
import { UserNotificationsDisplay } from 'components/Notifications/V1/UserNotificationsDisplay'
import { PageTitle, useAcl } from 'hooks'
import React from 'react'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

export const Notifications: React.FC = () => {
  const { hasPermission, hasFeature } = useAcl()
  const hasAccountNotifications = hasPermission('account.update')
  const hasEmailDigest = hasFeature('emailDigest')
  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner title="Notifications" />
        <PageTitle title="Notifications" />
        <SimpleGrid
          spacing={8}
          columns={{ base: 1, lg: hasAccountNotifications ? 2 : 1 }}
        >
          {hasAccountNotifications && <AccountNotifications />}
          <UserNotificationsDisplay />
          <RecipientNotifications />
          {hasEmailDigest && <EmailDigestSettingsDisplay />}
        </SimpleGrid>
      </ZSidebar>
    </CenteredBox>
  )
}
