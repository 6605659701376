import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import type { Column } from '@postal-io/postal-ui'
import {
  internalProgressBarProps,
  internalTableProps,
  UiButtonScrollTop,
  UiDate,
  UiSSDataTable,
  useInfiniteScroll,
  ZButton,
  ZCard,
  ZCardBody,
  ZCheckbox,
  ZSidebar,
  ZSidebarBanner,
} from '@postal-io/postal-ui'
import { CenteredBox } from 'components/Common'
import { ZHiddenSearchBar } from 'components/Common/ZComponents'
import type { MiniTeam } from 'hooks'
import { PageTitle, useAcl, useMiniTeams } from 'hooks'
import React, { useState } from 'react'
import { MdOutlineGroup } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { TeamCreate } from '../Teams/TeamCreate'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

const LIMIT = 10

const columns: Column[] = [
  { key: 'name', label: 'Name' },
  // {
  //   label: 'Active',
  //   render: ({ status }: any) => <UiCheckboxIcon isChecked={status === Status.Active} />
  // },
  {
    key: 'created',
    label: 'Created',
    render: ({ created }: any) => (
      <UiDate
        date={created}
        fallback=""
      />
    ),
  },
]

export const Teams: React.FC = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState<string>('')
  const { hasPermission } = useAcl()
  const canCreate = hasPermission('teams.create')
  const teamsQuery = useMiniTeams(search, LIMIT)
  const createTeam = useDisclosure()

  const handleUpdateTeam = (team: MiniTeam) => {
    navigate(`/teams/${team.teamId || 'default'}`)
  }

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: teamsQuery.hasNextPage,
    loadMore: teamsQuery.fetchNextPage,
    loading: teamsQuery.isFetching,
  })

  return (
    <CenteredBox
      isLoaded
      pt={0}
      ref={topRef}
    >
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <PageTitle title="Teams" />
        <ZSidebarBanner
          title="Teams"
          actions={
            <Flex
              alignItems="center"
              gap={8}
            >
              <ZHiddenSearchBar
                searchString={search}
                setSearchString={setSearch}
                buttonProps={{
                  color: 'atomicBlue.400',
                  _hover: {
                    color: 'atomicBlue.600',
                  },
                }}
              />
              {canCreate && (
                <ZButton
                  variant="link"
                  color="atomicBlue.400"
                  leftIcon={<MdOutlineGroup size="24px" />}
                  size="sm"
                  height="21px"
                  onClick={createTeam.onOpen}
                >
                  Create Team
                </ZButton>
              )}
            </Flex>
          }
        />
        <Flex flexDir="column">
          <ZCard variant="form">
            <ZCardBody p={8}>
              <UiSSDataTable
                variant="list"
                columns={columns}
                rows={teamsQuery.teams}
                isLoading={teamsQuery.isFetching}
                rowKey={'id'}
                onClick={handleUpdateTeam}
                tableProps={internalTableProps}
                progressBarProps={internalProgressBarProps}
                SelectCheckbox={ZCheckbox}
                HeaderButton={ZButton}
                pageSize={1000}
              />
            </ZCardBody>
          </ZCard>
        </Flex>
        {teamsQuery.teams.length >= LIMIT && (
          <Box
            ref={bottomRef}
            position="relative"
          >
            <UiButtonScrollTop
              onClick={scrollTop}
              position="absolute"
              top="0px"
              right="0px"
              isLoading={teamsQuery.isFetching}
              aria-label="scroll button"
            />
          </Box>
        )}
        {createTeam.isOpen && canCreate && <TeamCreate {...createTeam} />}
      </ZSidebar>
    </CenteredBox>
  )
}
