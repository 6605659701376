import { Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  useAlerts,
  ZButton,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import { capitalize } from 'lodash'
import React, { useMemo } from 'react'
import type { Role, User } from '../../api'
import { UpdateRolesV2Document } from '../../api'
import { useAcl, useSession } from '../../hooks'
import { MenuUserRole } from './MenuUserRole'

const PRODUCT_ID = process.env.REACT_APP_PRODUCT_ID

interface MenuUserAccountUpdateProps {
  user: User
  teamId?: string
  onUpdate?: (results?: any) => void
  minW?: string
}
export const MenuUserAccountUpdate: React.FC<MenuUserAccountUpdateProps> = ({
  user,
  teamId,
  onUpdate,
  minW = '250px',
}) => {
  const { session } = useSession()
  const Alert = useAlerts()
  const confirmRemove = useDisclosure()

  const { hasPermission } = useAcl()
  const canUpdate = hasPermission('users.update')

  const updateRoles = useGraphqlMutation(UpdateRolesV2Document)

  const roles = useMemo(() => {
    const productAccess = user.productAccess?.find((p) => {
      if (p.product !== PRODUCT_ID) return false
      if (p.accountId !== session.accountId) return false
      return teamId ? p.teamId === teamId : !p.teamId
    })
    return (productAccess?.roles || []) as Role[]
  }, [session.accountId, teamId, user.productAccess])

  const handleChange = async (newRoles: any) => {
    newRoles.length < 1 ? confirmRemove.onOpen() : update(newRoles)
  }

  const update = async (newRoles: any) => {
    if (!canUpdate) return
    const variables = { id: user.id, roles: newRoles, teamId }
    try {
      await updateRoles.mutateAsync(variables)
      Alert.success('Roles Updated')
      confirmRemove.onClose()
      onUpdate && onUpdate()
    } catch (err) {
      Alert.error(err)
    }
  }

  if (!canUpdate) {
    const title = roles.length ? roles.map(capitalize).join(', ') : 'No Access'
    return <Text textTransform="capitalize">{title}</Text>
  }

  return (
    <>
      <MenuUserRole
        as={ZButton}
        minW={minW}
        fontSize="14px"
        height="40px"
        textAlign="left"
        isDisabled={updateRoles.isLoading}
        value={roles}
        onChange={handleChange}
        teamId={teamId}
        userId={user.id}
        fontWeight="normal"
      />
      {confirmRemove.isOpen && (
        <ZModal
          isOpen={confirmRemove.isOpen}
          onClose={confirmRemove.onClose}
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Remove Team Membership</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <ZText>This action will remove this user from the team. You can always add them back a later time.</ZText>
              <ZText mt={4}>
                Are you sure you want to <strong>Remove</strong> this user from this team?
              </ZText>
            </ZModalBody>
            <ZModalButtons
              confirmText="Remove"
              confirmProps={{
                colorScheme: 'atomicRed',
              }}
              onConfirm={() => update([])}
              onClose={confirmRemove.onClose}
            />
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}
