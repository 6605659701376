import { Flex } from '@chakra-ui/react'
import { ZLink } from '@postal-io/postal-ui'
import type { ActivityType } from 'api'
import { Role } from 'api'
import { StickyElementWithShadowV2 } from 'components/Common/StickyElementWithShadowV2'
import { useAcl } from 'hooks'
import React, { useCallback, useMemo } from 'react'
import { TeamsFilterSearch, UsersFilterSearch } from '../ReportingFilter'
import { ActivityStreamTypeFilter } from './ActivityStreamTypeFilter'
import type { UseActivityStreamFilterV2 } from './useActivityStreamFilter'

// TODO:
// one must have a User role to send things, which is required to have any stats
// perhaps we want to switch to that?  not sure leaving here to think about it
// const ROLES = [Role.User]

const ROLES = [Role.Admin, Role.TeamAdmin, Role.Manager, Role.User]

export const ActivityStreamFilter: React.FC<Omit<UseActivityStreamFilterV2, 'filter'>> = ({
  state,
  setState,
  resetState,
}) => {
  const { hasPermission } = useAcl()

  // only admins should be able to create teams
  const canFilterTeams = hasPermission('teams.create')

  // managers, team admins, and admins should fulfill this permission
  const canFilterUsers = hasPermission('users.query')

  const showReset = useMemo(() => {
    return !!state.teamIds?.length || !!state.userIds?.length || !!state.types?.length
  }, [state.teamIds?.length, state.types?.length, state.userIds?.length])

  const handleTeamsFilter = useCallback(
    (teamIds: string[]) => setState((draft) => void (draft.teamIds = teamIds)),
    [setState]
  )

  const handleUsersFilter = useCallback(
    (userIds: string[]) => setState((draft) => void (draft.userIds = userIds)),
    [setState]
  )

  const handleActivityFilter = useCallback(
    (types: ActivityType[]) => setState((draft) => void (draft.types = types)),
    [setState]
  )

  if (!canFilterTeams && !canFilterUsers) return null

  return (
    <StickyElementWithShadowV2
      pb={2}
      px={0}
      zIndex={2}
    >
      <Flex
        alignItems="center"
        flexWrap="wrap"
        gridGap={4}
      >
        {canFilterTeams && (
          <TeamsFilterSearch
            value={state.teamIds}
            onChange={handleTeamsFilter}
            isFirst
            isLast
          />
        )}
        {canFilterUsers && (
          <UsersFilterSearch
            value={state.userIds}
            onChange={handleUsersFilter}
            teamIds={state.teamIds}
            roles={ROLES}
            isFirst
            isLast
          />
        )}
        <ActivityStreamTypeFilter
          value={state.types}
          onChange={handleActivityFilter}
          isFirst
          isLast
        />
        {showReset && (
          <ZLink
            onClick={resetState}
            ml={4}
          >
            Reset All
          </ZLink>
        )}
      </Flex>
    </StickyElementWithShadowV2>
  )
}
