import type { FlexProps } from '@chakra-ui/react'
import { Flex, Stack } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiSkeleton, useAlertError, useAlerts, ZButton } from '@postal-io/postal-ui'
import { GetUserDocument, Result, Role, UpdateRolesV2Document } from 'api'
import { SendUserEmailCheckbox } from 'components/Users/SendUserEmailCheckbox'
import type { MiniTeam } from 'hooks'
import { useAcl, useSession } from 'hooks'
import type { FormEvent } from 'react'
import React from 'react'
import { useImmer } from 'use-immer'
import { AutoCompleteTeam, MenuUserRole } from '../AutoComplete'
import { UserSeatsAlert } from '../Users'

const PRODUCT_ID = process.env.REACT_APP_PRODUCT_ID

interface UserAccountAddProps extends FlexProps {
  userId: string
  onUpdate?: (team: MiniTeam) => void
  onClose?: () => void
}
interface State {
  search: string
  roles: Role[]
  team?: MiniTeam
  sendNotification?: boolean
}
export const UserAccountAdd: React.FC<UserAccountAddProps> = ({ userId, onUpdate, onClose, ...rest }) => {
  const Alert = useAlerts()

  const { session } = useSession()
  const [state, setState] = useImmer<State>({ search: '', roles: [Role.User] })

  const { hasPermission } = useAcl()
  const canUpdate = hasPermission('users.update')

  const userQuery = useGraphqlQuery(GetUserDocument, { id: userId })
  const user = userQuery.data?.getUser
  const updateRoles = useGraphqlMutation(UpdateRolesV2Document)

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (!state.team || !user || !state.roles.length) {
      return Alert.warning('A team, and role are required')
    }

    // get any existing products for this team or account
    const existingProducts =
      user.productAccess?.filter((product) => {
        if (product.product !== PRODUCT_ID) return false
        if (product.accountId !== session.accountId) return false
        return state.team?.teamId ? product.teamId === state.team.teamId : !product.teamId
      }) || []

    // add User in addition to any existing roles for this team or account
    const newRoles = new Set<string>(state.roles)
    existingProducts.forEach((p) => p.roles?.forEach((r) => newRoles.add(r)))

    try {
      const res = await updateRoles.mutateAsync({
        id: user.id,
        teamId: state.team.teamId,
        roles: Array.from(newRoles),
        sendNotification: !!state.sendNotification,
      })

      setState((draft) => {
        draft.search = ''
        delete draft.team
      })

      // parse results
      const error = res.updateRolesV2?.find((r) => r.result !== Result.Success)
      error ? Alert.warning(error.reason || 'Not all Roles were added') : Alert.success('Team added to User')
      // update parent
      onUpdate && onUpdate(state.team)
    } catch (err) {
      Alert.error(err)
    }
  }

  useAlertError(userQuery.error)

  const actionDisabled = !state.team || !user || !state.roles.length || updateRoles.isLoading

  if (!canUpdate) return null

  return (
    <UiSkeleton
      isLoaded={!userQuery.isLoading}
      {...rest}
    >
      <UserSeatsAlert mb={8} />
      <form onSubmit={handleSubmit}>
        <Stack
          spacing={8}
          mb={16}
        >
          <AutoCompleteTeam
            rootProps={{ mt: 4, minW: '250px' }}
            inputValue={state.search}
            onInputChange={(value: string) => setState((draft) => void (draft.search = value))}
            value={state.team || null}
            onChange={(team: MiniTeam | null) => setState((draft) => void (draft.team = team || undefined))}
          />

          <MenuUserRole
            rootProps={{ mt: 4 }}
            textAlign="left"
            h="40px"
            as={ZButton}
            pl={4}
            fontWeight="normal"
            color="atomicGray.600"
            fontSize="14px"
            isDisabled={updateRoles.isLoading}
            value={state.roles}
            onChange={(newRoles) => setState((draft) => void (draft.roles = newRoles))}
            teamId={state.team?.teamId}
            userId={userId}
            w="full"
          />
          <SendUserEmailCheckbox
            isChecked={!!state.sendNotification}
            onChange={(e) => setState((draft) => void (draft.sendNotification = e.target.checked))}
            justifyContent="flex-start"
            mb={16}
          />
        </Stack>
        <Flex justifyContent="space-between">
          <ZButton
            type="submit"
            colorScheme="atomicBlue"
            isDisabled={actionDisabled}
            minW="140px"
          >
            Update Roles
          </ZButton>
          {onClose && (
            <ZButton
              colorScheme="atomicGray"
              variant="ghost"
              onClick={onClose}
              minW="140px"
            >
              Cancel
            </ZButton>
          )}
        </Flex>
      </form>
    </UiSkeleton>
  )
}
