import type { ActivityType } from 'api'
import { isValid, parseISO } from 'date-fns'
import { produce } from 'immer'
import { isArray, isDate, isEmpty, isObject, isString } from 'lodash'
import merge from 'lodash/merge'
import { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useImmer } from 'use-immer'

export interface ActivityFilterV2State {
  teamIds?: string[]
  userIds?: string[]
  types?: ActivityType[]
}

const isEmptyField = (v: any) => {
  if (isDate(v)) return !isValid(v)
  if (isString(v) || isArray(v) || isObject(v)) return isEmpty(v)
  return !v
}

const defaultDebounce = 400
const defaultState: ActivityFilterV2State = {}

// remove any empty values
const transformVariables = (state: ActivityFilterV2State) => {
  return produce(state, (draft) => {
    Object.keys(draft).forEach((key) => {
      const _key = key as keyof ActivityFilterV2State
      const value = state[_key] as any
      if (isEmptyField(value)) delete draft[_key]
    })
  })
}

interface UseReportingFilterProps {
  persistKey?: string
  debounce?: number
}
export const useActivityStreamFilter = ({ persistKey, debounce }: UseReportingFilterProps) => {
  const [state, setState] = useImmer<ActivityFilterV2State>(() => {
    let storedState: any = {}
    try {
      if (persistKey) {
        // dates are converted to strings when saving to sessionStorage
        storedState = JSON.parse(sessionStorage.getItem(persistKey) || '{}')
        if (storedState.startDate) storedState.startDate = parseISO(storedState.startDate)
        if (storedState.endDate) storedState.endDate = parseISO(storedState.endDate)
      }
    } catch (e) {
      console.error(e)
    }
    return merge({}, defaultState, storedState)
  })

  const [filter, setFilter] = useState<ActivityFilterV2State>(transformVariables(state))
  const setDebounced = useDebouncedCallback(setFilter, debounce || defaultDebounce)
  const resetState = useCallback(() => setState(() => defaultState), [setState])

  // we are watching state changes and then updating the filters via debounce
  useEffect(() => {
    setDebounced(transformVariables(state))
  }, [setDebounced, state])

  // every time we change the filters, write to localStorage
  useEffect(() => {
    if (persistKey) sessionStorage.setItem(persistKey, JSON.stringify(state))
  }, [state, persistKey])

  return {
    state,
    setState,
    resetState,
    filter,
  }
}

export type UseActivityStreamFilterV2 = ReturnType<typeof useActivityStreamFilter>
