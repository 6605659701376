import { Box, Flex, Grid, HStack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButtonScrollTop,
  UiSidePanelContainer,
  UiTooltip,
  useAlertError,
  useInfiniteScroll,
  ZButton,
  ZProductCard,
} from '@postal-io/postal-ui'
import type { ApprovedPostal } from 'api'
import { DefaultOrderByInput, Role, SearchApprovedPostalsDocument, Status } from 'api'
import { useMaxCollectionItems } from 'components/Collections'
import { BulkSelectActionBar, BulkSelectNavbar } from 'components/Common'
import { SidePanelFilter } from 'components/Common/SidePanelFilter'
import { SubnavFilters } from 'components/Common/SubnavFilters'
import { AnalyticsEvent, useAcl, useAnalyticsEvent, useAnalyticsSend, useMe } from 'hooks'
import { useBulkSelect } from 'hooks/useBulkSelect'
import { useNavbarOverride } from 'hooks/useNavbarOverride'
import { usePostalFavoriteStatus } from 'hooks/usePostalFavoriteStatus'
import { cloneDeep, omit, pick, some } from 'lodash'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { MdAddCircleOutline, MdOutlineEdit } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { useKey } from 'react-use'
import { NoPostal } from '../Postal'
import { ApprovedPostalsEmpty } from '../Postals/ApprovedPostalsEmpty'
import {
  APPROVED_POSTAL_FILTER_INPUT_KEYS,
  CATEGORIES_ITEMS_ONLY,
  ITEM_CATEGORY_EXCLUSIONS,
  MARKETPLACE_CARD_MIN_WIDTH,
} from '../Postals/data'
import { MarketplaceBulkEdit } from './MarketplaceBulkEdit'
import { MarketplaceCardPostal } from './MarketplaceCardPostal'
import { MarketplaceContext } from './useMarketplace'

const LIMIT = 20

export const OldMarketplaceViewPostals = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { approvedCurrencies } = useMe()
  const sendAnalytics = useAnalyticsSend()
  const { hasPermission, hasFeature, hasRole } = useAcl()
  const canCreate = hasPermission('postals.create')
  const canCreateCollection = canCreate || (hasFeature('userCreatedCollections') && hasRole(Role.User))

  // for semantics
  const canBulkSelect = canCreate || canCreateCollection

  const bulkEditDisclosure = useDisclosure()
  const actionBarDisclosure = useDisclosure()

  const { handleBulkSelect, isBulkSelected, handleCancelBulkSelect, bulkSelected, setBulkSelected } =
    useBulkSelect<ApprovedPostal>()

  const multiCurrencySelection = useMemo(
    () => some(bulkSelected, (item) => item.currency !== bulkSelected[0].currency),
    [bulkSelected]
  )

  //Check to see if user has at least one postal
  const hasOneVariables = useMemo(() => {
    return {
      limit: 1,
      filter: {
        category: { in: CATEGORIES_ITEMS_ONLY },
        status: { in: canCreate ? [Status.Active, Status.Disabled] : [Status.Active] },
        currency: { in: approvedCurrencies },
      },
    }
  }, [approvedCurrencies, canCreate])

  const hasOneQuery = useGraphqlQuery(SearchApprovedPostalsDocument, hasOneVariables)
  const hasNoItems = useMemo(
    () => !hasOneQuery.data?.searchApprovedPostals?.length,
    [hasOneQuery.data?.searchApprovedPostals?.length]
  )

  const { state, setNumResults, marketplaceFilters } = useContext(MarketplaceContext)
  const { graphqlFilter, filters, updateFilter, clearFilters } = marketplaceFilters

  // don't show status
  const subnavFilters = omit(cloneDeep(filters), ['status'])

  const filter = useMemo(() => pick(graphqlFilter, APPROVED_POSTAL_FILTER_INPUT_KEYS), [graphqlFilter])

  // load approved postals
  const searchPostals = useGraphqlInfiniteQuery(
    SearchApprovedPostalsDocument,
    {
      filter,
      limit: LIMIT,
      // most recently approved items first
      orderBy: DefaultOrderByInput.CreatedDesc,
    }
    // { keepPreviousData: true }
  )
  useAlertError(searchPostals.error)

  const postals = useMemo(
    () => searchPostals.mergedData?.searchApprovedPostals ?? [],
    [searchPostals.mergedData?.searchApprovedPostals]
  )

  // hack for outOfStock checkbox
  // would be better to update status on Product level or use variants_status filter but those options N/A ATM
  const postalsWithOutOfStockFilter = useMemo(() => {
    return postals.filter((postal) =>
      some(postal?.variants, (v) => (filters.outOfStock ? true : v.status === Status.Active))
    )
  }, [filters.outOfStock, postals])

  const { postalsWithFavoriteStatus } = usePostalFavoriteStatus(postalsWithOutOfStockFilter)

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchPostals.hasNextPage,
    loadMore: searchPostals.fetchNextPage,
    loading: searchPostals.isFetching,
  })

  const handleSelect = useCallback(
    (postal: ApprovedPostal) => {
      sendAnalytics({
        event: AnalyticsEvent.MarketplaceViewMyItemClicked,
        data: { marketplaceProductId: postal.marketplaceProductId, itemName: postal.name },
      })

      navigate(`${location.pathname}/${postal.id}`, { state: { returnTo: 'My Items' } })
    },
    [location.pathname, navigate, sendAnalytics]
  )

  // open action bar when products are selected in bulk
  useEffect(() => {
    if (bulkSelected.length > 0) actionBarDisclosure.onOpen()
    else actionBarDisclosure.onClose()
    return () => actionBarDisclosure.onClose()
  }, [actionBarDisclosure, bulkSelected.length])

  useAnalyticsEvent({ event: AnalyticsEvent.MarketplaceMyItemsPageViewed })
  useAnalyticsEvent({ event: AnalyticsEvent.MarketplaceFilterSelected, data: { filter } })

  // deselect all products
  useKey('Escape', handleCancelBulkSelect)

  useEffect(() => {
    setNumResults?.(postals.length)
  }, [postals.length, setNumResults])

  // const isLoading = useDebounce(searchPostals.isLoading || hasOneQuery.isLoading, 600)
  const isFetching = searchPostals.isFetching || hasOneQuery.isFetching

  const MAX_COLLECTION_ITEMS = useMaxCollectionItems()
  const collectionCreateError = useMemo(() => {
    if (multiCurrencySelection) return 'Multiple currencies selected. Only one currency is allowed per collection.'
    if (bulkSelected.length > MAX_COLLECTION_ITEMS)
      return `Cannot create a Collection with over ${MAX_COLLECTION_ITEMS} items.`
  }, [MAX_COLLECTION_ITEMS, bulkSelected.length, multiCurrencySelection])

  // contextual navbar if bulk selecting
  useNavbarOverride(
    !!bulkSelected.length && (
      <BulkSelectNavbar
        isEverythingSelected={bulkSelected.length === postalsWithOutOfStockFilter.length}
        selectedItems={bulkSelected}
        onSelect={handleBulkSelect}
        onSelectAll={() => setBulkSelected(postalsWithOutOfStockFilter)}
        onDeselectAll={handleCancelBulkSelect}
      >
        <>
          {canCreate && (
            <ZButton
              colorScheme="atomicBlue"
              onClick={bulkEditDisclosure.onOpen}
              isDisabled={bulkSelected.length < 1}
              autoFocus
              leftIcon={<MdOutlineEdit fontSize="18px" />}
            >
              Edit
            </ZButton>
          )}
          {canCreateCollection && (
            <UiTooltip
              label={collectionCreateError}
              shouldWrapChildren
            >
              <ZButton
                variant="naked"
                color="white"
                _hover={{ color: '#FFFC' }}
                px={0}
                onClick={() =>
                  navigate('/collections/create', {
                    state: { items: bulkSelected, returnTo: 'Items' },
                  })
                }
                isDisabled={!!collectionCreateError}
                leftIcon={<MdAddCircleOutline fontSize="18px" />}
              >
                Create Collection
              </ZButton>
            </UiTooltip>
          )}
        </>
      </BulkSelectNavbar>
    ),
    [bulkSelected, setBulkSelected, postalsWithOutOfStockFilter, multiCurrencySelection, collectionCreateError]
  )

  return (
    <>
      <SubnavFilters
        filters={subnavFilters}
        onUpdate={updateFilter}
        onClear={clearFilters}
        display={!!state?.numFilters ? 'none' : undefined}
      />
      {!searchPostals.isFetching && hasNoItems ? (
        <ApprovedPostalsEmpty />
      ) : (
        <UiSidePanelContainer gridGap={16}>
          <SidePanelFilter
            filterType="ApprovedPostal"
            filters={filters}
            onUpdate={updateFilter}
            excludeCategories={ITEM_CATEGORY_EXCLUSIONS}
            approvedCurrencies={approvedCurrencies}
            showDraft
          />
          <Flex
            direction="column"
            w="100%"
            ref={topRef}
            style={{ scrollMargin: '20px' }}
          >
            <Grid
              templateColumns={`repeat(auto-fill, minmax(${MARKETPLACE_CARD_MIN_WIDTH}px, 1fr))`}
              gap={4}
            >
              {postalsWithFavoriteStatus?.map((postal: any) => (
                <MarketplaceCardPostal
                  key={postal.id}
                  postal={postal}
                  onSelect={handleSelect}
                  favoriteItemId={postal.favoriteItemId}
                  isBulkSelected={isBulkSelected(postal)}
                  onBulkSelect={canBulkSelect ? handleBulkSelect : undefined}
                  truncateLength={33}
                />
              ))}

              {isFetching && postals.length < 1 ? (
                <ZProductCard
                  isLoading
                  h={MARKETPLACE_CARD_MIN_WIDTH}
                  w={MARKETPLACE_CARD_MIN_WIDTH}
                  startColor="atomicGray.50"
                  endColor="atomicGray.200"
                />
              ) : postals.length < 1 ? (
                <NoPostal />
              ) : null}
            </Grid>
            {postals.length >= LIMIT && (
              <Box
                ref={bottomRef}
                mb={8}
                position="relative"
              >
                <UiButtonScrollTop
                  onClick={scrollTop}
                  position="absolute"
                  top="0px"
                  right="0px"
                  isLoading={searchPostals.isFetching}
                  aria-label="scroll button"
                />
              </Box>
            )}
          </Flex>
        </UiSidePanelContainer>
      )}

      <BulkSelectActionBar
        isOpen={actionBarDisclosure.isOpen}
        selectedLength={bulkSelected.length}
        isEverythingSelected={bulkSelected.length === postalsWithOutOfStockFilter.length}
        selectedItemsText={`(${bulkSelected.length} Item${bulkSelected.length !== 1 ? 's' : ''} selected)`}
        onSelectAll={() => setBulkSelected(postalsWithOutOfStockFilter)}
        onDeselectAll={handleCancelBulkSelect}
      >
        <HStack spacing={2}>
          <ZButton
            minWidth="175px"
            colorScheme="atomicBlue"
            onClick={bulkEditDisclosure.onOpen}
            isDisabled={bulkSelected.length < 1}
            autoFocus
          >
            Edit
          </ZButton>
          <ZButton
            minWidth="175px"
            variant="subtle"
            colorScheme="atomicGray"
            onClick={handleCancelBulkSelect}
          >
            Cancel
          </ZButton>
        </HStack>
      </BulkSelectActionBar>

      {bulkEditDisclosure.isOpen && (
        <MarketplaceBulkEdit
          type="Update"
          items={bulkSelected}
          isOpen={bulkEditDisclosure.isOpen}
          onClose={bulkEditDisclosure.onClose}
          onSuccess={handleCancelBulkSelect}
        />
      )}
    </>
  )
}
