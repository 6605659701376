import { ScrollTop, sessionStorage } from '@postal-io/postal-ui'
import { authLogoutChannel, authResponseChannel } from 'components/Extension/broadcast'
import { Analytics } from 'components/ThirdParty/Analytics'
import { LaunchDarkly } from 'components/ThirdParty/LaunchDarkly'
import { LogRocketInit } from 'components/ThirdParty/LogRocketInit'
import { Segment } from 'components/ThirdParty/Segment'
import { AnalyticsEventV2, ExtensionProvider, getAnalyticsSessionInfo, sendAnalyticsEvent, useSession } from 'hooks'
import { StorageKeys } from 'lib'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

export default function ExtensionRoot() {
  console.log('ExtensionRoot')

  const { replaceSession, removeSession } = useSession()
  const navigate = useNavigate()

  useEffect(() => {
    authResponseChannel.onmessage = async (event) => {
      const session = await replaceSession(event.data)
      sendAnalyticsEvent(AnalyticsEventV2.ExtensionLogin, { ...getAnalyticsSessionInfo(session), type: 'SSO' })
      const returnTo = sessionStorage.getItem(StorageKeys.AppRedirectPath)
      sessionStorage.removeItem(StorageKeys.AppRedirectPath)
      navigate(returnTo ?? '/extension/')
    }
  }, [navigate, replaceSession])

  useEffect(() => {
    authLogoutChannel.onmessage = () => {
      removeSession()
      navigate('/extension/')
    }
  }, [navigate, removeSession, replaceSession])

  return (
    <>
      <ExtensionProvider>
        <Outlet />
      </ExtensionProvider>
      <ScrollTop excludedPaths={[]} />
      <Segment />
      <Analytics />
      <LaunchDarkly />
      <LogRocketInit appId={process.env.REACT_APP_EXTENSION_LOG_ROCKET_KEY} />
    </>
  )
}
