import { Container, Grid, Skeleton } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { LexendFontWeight, ZButton, ZHeading, ZHoverCard, ZSubNavbar, ZText } from '@postal-io/postal-ui'
import type { PlaybookDefinition } from 'api'
import { SearchPlaybookDefinitionsDocument, Status } from 'api'
import { AnalyticsEvent, PageTitle, useAcl, useAnalyticsEvent } from 'hooks'
import { useMemo } from 'react'
import { MdMenuBook } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { PlaybookDefinitionCard, PlaybookDefinitionCardCreateV2 } from './PlaybookDefinitionCard'
import { PlaybookDefinitionsStats } from './PlaybookDefinitionsStats'

export const PlaybookDefinitionsPage: React.FC = () => {
  const navigate = useNavigate()
  const { hasPermission } = useAcl()
  const canCreate = hasPermission('playbooks.create')

  useAnalyticsEvent({ event: AnalyticsEvent.SubscriptionsPageViewed })

  const { data, isFetching, isLoading } = useGraphqlQuery(SearchPlaybookDefinitionsDocument, {
    filter: { status: { ne: Status.Delete } },
  })
  const playbookDefinitions = useMemo(() => {
    return (
      (data?.searchPlaybookDefinitions as PlaybookDefinition[])?.filter((p) =>
        canCreate ? true : p.status === Status.Active
      ) || []
    )
  }, [canCreate, data?.searchPlaybookDefinitions])

  const viewPlaybook = (id: string) => navigate(`/subscriptions/${id}`)

  const openCreateModal = () => navigate(`/subscriptions/create`)

  return (
    <>
      <PageTitle title="Subscriptions" />
      <Container
        maxW="1440px"
        p={8}
        pt={0}
        mt={4}
      >
        <ZSubNavbar
          borderBottom="none"
          left={<ZHeading size="h4">Subscriptions</ZHeading>}
          right={
            <>
              {canCreate && (
                <ZButton
                  px={0}
                  color="atomicBlue.500"
                  fontWeight={LexendFontWeight.Regular}
                  variant="link"
                  leftIcon={<MdMenuBook />}
                  onClick={openCreateModal}
                >
                  Create a Subscription
                </ZButton>
              )}
            </>
          }
        />
        <ZSubNavbar
          left={<ZText color="atomicGray.600">Create subscriptions using items</ZText>}
          right={<PlaybookDefinitionsStats />}
          gridProps={{ minH: '40px', alignItems: 'flex-start' }}
        />

        <Skeleton
          isLoaded
          mt={8}
        >
          <Grid
            templateColumns={`repeat(auto-fill, minmax(300px, 1fr))`}
            gap={4}
          >
            {isLoading && <ZHoverCard isLoading={isFetching} />}

            {canCreate && !isLoading && <PlaybookDefinitionCardCreateV2 onCreate={openCreateModal} />}

            {playbookDefinitions.map((item) => (
              <PlaybookDefinitionCard
                key={item.id}
                definition={item}
                onSelect={() => viewPlaybook(item.id)}
                isFetching={isFetching}
              />
            ))}
          </Grid>
        </Skeleton>
      </Container>
    </>
  )
}
