import type { BoxProps } from '@chakra-ui/react'
import { Box, Collapse, Divider, Flex, Grid, HStack, Stack, useDisclosure } from '@chakra-ui/react'
import {
  UiAlert,
  UiDangerous,
  UiMenu,
  UiMenuButton,
  UiMenuList,
  UiMoney,
  useColor,
  ZButton,
  ZCard,
  ZCardBody,
  ZHeading,
  ZText,
} from '@postal-io/postal-ui'
import { ClampAndFade } from 'components/Common/ClampAndFade'
import { ZAlert, ZInfoTooltip } from 'components/Common/ZComponents'
import { ApprovedPostalOption } from 'components/Postal/ApprovedVariantOption'
import { PostalSendSidebarImageDisplay } from 'components/PostalSend/PostalSendSidebarItemInfo'
import { listItemsWithMaxLength } from 'lib'
import { some } from 'lodash'
import React, { useMemo } from 'react'
import { MdOutlineArrowDropDown } from 'react-icons/md'
import type { ApprovedPostal } from '../../api'
import { Status } from '../../api'
import { PostalImageCollage } from '../Postal'
import type { EitherVariant } from './utils'
import { addItemFieldsToVariant, getPriceRange } from './utils'

interface CollectionItemOptionsApprovedV2Props extends Omit<BoxProps, 'onSelect'> {
  approvedItem: ApprovedPostal
  isBulkSelected: (variant: EitherVariant) => boolean
  onSelect: (variant: EitherVariant) => void
}

export const CollectionItemOptionsApproved: React.FC<CollectionItemOptionsApprovedV2Props> = ({
  approvedItem,
  isBulkSelected,
  onSelect,
  ...rest
}) => {
  const availableVariants = useMemo(
    () => approvedItem.variants?.filter((v) => v.status === Status.Active) || [],
    [approvedItem?.variants]
  )

  return (
    <Box
      h="100%"
      w="100%"
      {...rest}
    >
      <Grid
        w="100%"
        templateColumns="calc(60% - 1rem) calc(40% - 1rem)"
        gridGap="2rem"
      >
        <ZCard
          variant="form"
          overflow="hidden"
        >
          <ZCardBody p={0}>
            <Box p={8}>
              <PostalImageCollage postal={approvedItem} />
            </Box>
            <Box p={8}>
              <ZHeading
                as="h2"
                size="h5"
              >
                {approvedItem.name}
              </ZHeading>
              <ZText
                my={2}
                color="gray.700"
                whiteSpace="break-spaces"
              >
                <UiDangerous html={approvedItem.description} />
              </ZText>
            </Box>
          </ZCardBody>
        </ZCard>
        <Box>
          <ZHeading
            mb={8}
            as="h2"
            size="h6"
          >
            <Flex alignItems="center">
              Select Available Options
              <ZInfoTooltip
                placement="right"
                ml={2}
                label="Recipients will choose from available options when accepting the order."
              />
            </Flex>
            <ZText
              pt={2}
              fontSize="body-sm"
            >
              Any applicable shipping or sales tax will be added when you send.
            </ZText>
          </ZHeading>

          {availableVariants?.length ? (
            <UiMenu isOpen>
              <Stack divider={<Divider />}>
                {availableVariants?.map((variant) => {
                  return (
                    <ApprovedPostalOption
                      key={variant.id}
                      variant={variant}
                      marketplaceProductId={approvedItem.marketplaceProductId}
                      onClick={() => onSelect(variant)}
                      category={approvedItem.category}
                      isSelected={isBulkSelected(variant)}
                      brandingColor="atomicBlue.400"
                      isMulti
                      largeCheckbox
                      mx={-3}
                      width="unset"
                    />
                  )
                })}
              </Stack>
            </UiMenu>
          ) : (
            <UiAlert status="warning">This item is currently unavailable.</UiAlert>
          )}
        </Box>
      </Grid>
    </Box>
  )
}

export const CollectionItemOptionsApprovedMultiVersion: React.FC<CollectionItemOptionsApprovedV2Props> = ({
  approvedItem,
  isBulkSelected,
  onSelect,
  ...rest
}) => {
  const disclosure = useDisclosure()
  const { Color } = useColor()

  const availableVariants = useMemo(
    () => approvedItem.variants?.filter((v) => v.status === Status.Active) || [],
    [approvedItem?.variants]
  )

  const selectedVariants = useMemo(() => availableVariants.filter(isBulkSelected), [availableVariants, isBulkSelected])

  const buttonText = useMemo(
    () =>
      listItemsWithMaxLength(
        selectedVariants.map((v) => v.variantName),
        28
      ) || 'Select options',
    [selectedVariants]
  )

  const { minPrice, maxPrice, hasMixedPrice } = getPriceRange(selectedVariants)

  return (
    <Box
      w="100%"
      {...rest}
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        w="100%"
      >
        <HStack
          p={8}
          pl={0}
          alignItems="flex-start"
          spacing={2.5}
        >
          <PostalSendSidebarImageDisplay
            flexShrink={0}
            postal={approvedItem}
          />
          <Box>
            <ZHeading
              mt={2}
              as="h2"
              size="h6"
            >
              {approvedItem.name}
            </ZHeading>
            <ClampAndFade>
              <ZText
                my={2}
                fontSize="sm"
                color="atomicGray.600"
                whiteSpace="break-spaces"
              >
                <UiDangerous html={approvedItem.description} />
              </ZText>
            </ClampAndFade>
          </Box>
        </HStack>

        {availableVariants?.length ? (
          <UiMenu
            {...disclosure}
            matchWidth
            closeOnSelect={false}
          >
            <UiMenuButton
              as={ZButton}
              h="40px"
              mt={14}
              pr={4}
              pl={4}
              width="330px"
              fontSize="sm"
              variant="outline"
              color="atomicGray.800"
              borderRadius={3}
              borderColor="atomicGray.200"
              _hover={{ bg: 'white', borderColor: 'atomicGray.400' }}
              _focus={{ bg: 'white', borderColor: 'atomicGray.500' }}
              rightIcon={
                <MdOutlineArrowDropDown
                  viewBox="2 2 20 20"
                  style={{
                    color: Color('atomicGray.500'),
                    transform: disclosure.isOpen ? 'rotate(-180deg)' : '',
                    transition: 'transform 0.2s',
                  }}
                />
              }
              onClick={disclosure.onToggle}
            >
              <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                color="atomicGray.800"
              >
                <ZText textAlign="left">{buttonText}</ZText>
                <Box>
                  {!!minPrice && (
                    <UiMoney
                      fontWeight="normal"
                      textAlign="right"
                      cents={minPrice}
                      round
                      currency={approvedItem.currency}
                    />
                  )}

                  {hasMixedPrice && (
                    <>
                      {' - '}
                      <UiMoney
                        fontWeight="normal"
                        textAlign="right"
                        cents={maxPrice}
                        round
                        currency={approvedItem.currency}
                      />
                    </>
                  )}
                </Box>
              </Flex>
            </UiMenuButton>
            <UiMenuList p={2}>
              {availableVariants?.map((variant) => {
                return (
                  <ApprovedPostalOption
                    key={variant.id}
                    variant={variant}
                    marketplaceProductId={approvedItem.marketplaceProductId}
                    onClick={() => onSelect(addItemFieldsToVariant(approvedItem, variant))}
                    category={approvedItem.category}
                    isSelected={isBulkSelected(variant)}
                    brandingColor="atomicBlue.400"
                    isMulti
                  />
                )
              })}
            </UiMenuList>
          </UiMenu>
        ) : (
          <UiAlert status="warning">This item is currently unavailable.</UiAlert>
        )}
      </Flex>
      <Collapse in={!some(availableVariants, isBulkSelected) && !disclosure.isOpen}>
        <ZAlert
          {...rest}
          status="warning"
          hideClose
          mb={5}
        >
          This item will be excluded from the collection if you do not select any options.
        </ZAlert>
      </Collapse>
    </Box>
  )
}
