import { Box, HStack, RadioGroup } from '@chakra-ui/react'
import { ZRadio, ZText } from '@postal-io/postal-ui'
import { GroupBy } from '../../../api/index'

export type GroupByOptionV2 = GroupBy.Team | GroupBy.User

interface GroupByFilterV2Props {
  groupBy?: GroupBy
  onChange: (groupBy: GroupByOptionV2) => void
}

export const radioStackStyleV2 = {}

// export const radioStackStyleV2 = {
//   'color': 'header.dark',
//   '& label': {
//     '& > span.chakra-radio__label': {
//       marginInlineStart: '5px',
//     },
//     '& > span.chakra-radio__control': {
//       'width': '15px',
//       'height': '15px',
//       'border': '2px solid',
//       'borderColor': 'gray.300',
//       '&[data-checked]': {
//         'border': '2.5px solid',
//         'borderColor': 'blue.900',
//         'backgroundColor': 'unset',
//         '&::before': {
//           width: '7px',
//           height: '7px',
//           color: 'blue.900',
//         },
//       },
//     },
//   },
// }

export const GroupByFilter: React.FC<GroupByFilterV2Props> = ({ groupBy, onChange }) => {
  return (
    <Box mr={8}>
      <RadioGroup
        value={groupBy}
        name="group_by"
        onChange={(value) => onChange(value as GroupByOptionV2)}
      >
        <HStack
          direction="row"
          sx={radioStackStyleV2}
        >
          <ZRadio value={GroupBy.User}>
            <ZText
              color="atomicGray.500"
              fontSize="sm"
            >
              User
            </ZText>
          </ZRadio>
          <ZRadio
            value={GroupBy.Team}
            mr={0.5}
          >
            <ZText
              color="atomicGray.500"
              fontSize="sm"
            >
              Team
            </ZText>
          </ZRadio>
        </HStack>
      </RadioGroup>
    </Box>
  )
}
