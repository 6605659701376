import { UiLoading, useAlerts } from '@postal-io/postal-ui'
import { PageTitle, useSession } from 'hooks'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainWithMarketingPane } from '../Session'
import { LogInHeaderBlack } from '../Session/LogInHeader'
import type { InviteInfo } from './types'
import { WelcomeExistingUser } from './WelcomeExistingUser'
import { WelcomeNewUser } from './WelcomeNewUser'
import { WelcomePassword } from './WelcomePassword'

export const Welcome: React.FC = () => {
  const params = useParams()
  const inviteId = params.inviteId as string
  const [token, setToken] = useState('')
  const [inviteInfo, setInviteInfo] = useState<InviteInfo>()
  const [loading, setLoading] = useState(true)
  const { getInviteInfo } = useSession()
  const Alert = useAlerts()
  const navigate = useNavigate()

  const ref = useRef(false)
  useEffect(() => {
    if (inviteInfo || ref.current) return
    ref.current = true
    getInviteInfo(inviteId)
      .then((info: InviteInfo) => setInviteInfo(info))
      .catch((err: Error) => {
        console.error(err)
        Alert.error(`This link has Expired.`)
        navigate('/')
      })
      .finally(() => setLoading(false))
  }, [Alert, getInviteInfo, inviteId, inviteInfo, navigate])

  const existingUser = inviteInfo?.type === 'EXISTING_USER'

  if (loading || !inviteInfo) return <UiLoading />

  return (
    <>
      <PageTitle title="Welcome" />
      <MainWithMarketingPane>
        <LogInHeaderBlack>
          {token ? (
            <WelcomePassword token={token} />
          ) : existingUser ? (
            <WelcomeExistingUser
              inviteId={inviteId}
              inviteInfo={inviteInfo}
            />
          ) : (
            <WelcomeNewUser
              inviteId={inviteId}
              inviteInfo={inviteInfo}
              onComplete={setToken}
            />
          )}
        </LogInHeaderBlack>
      </MainWithMarketingPane>
    </>
  )
}
