import { FormHelperText, Stack } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiFormControl,
  useAlerts,
  ZFormLabel,
  ZInput,
  ZInputPlace,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import type { Account, UpdateAccountInput } from 'api'
import { AddressSource, UpdateAccountDocument } from 'api'
import { AutoCompleteState } from 'components/AutoComplete'
import type { CountryInput } from 'hooks'
import type { ChangeEvent } from 'react'
import React, { useEffect } from 'react'
import { useImmer } from 'use-immer'
import { AutoCompleteCountry } from '../AutoComplete/AutoCompleteCountry'

interface CompanyEditProps {
  isOpen: boolean
  onClose: () => void
  onComplete?: () => void
  accountData: Account
}

interface FormProps {
  displayName: string
  address1: string
  address2: string
  city: string
  state: string
  country: string
  postalCode: string
  preferred: boolean
  source: string
}

export const CompanyEdit: React.FC<CompanyEditProps> = ({ isOpen, onClose, onComplete, accountData }) => {
  const Alert = useAlerts()

  const [form, setForm] = useImmer<FormProps>({
    displayName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    preferred: false,
    source: AddressSource.Manual,
  })

  // set form data based on inbound data
  useEffect(() => {
    const { displayName, address } = accountData
    setForm(() => ({
      displayName,
      address1: address?.address1 || '',
      address2: address?.address2 || '',
      city: address?.city || '',
      state: address?.state || '',
      country: address?.country || '',
      postalCode: address?.postalCode || '',
      preferred: !!address?.preferred,
      source: address?.source || AddressSource.Manual,
    }))
  }, [accountData, setForm])

  const updateAddress = (address: any) => {
    Object.keys(address).forEach((key) => {
      const val = (address as any)[key]
      setForm((draft: any) => void (draft[key] = val))
    })
  }

  const handleInput = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target
    setForm((draft: any) => void (draft[name] = value))
  }

  const handleStateInputChange = (state: any) => {
    setForm((draft: any) => {
      draft.state = state
    })
  }

  const updateAccount = useGraphqlMutation(UpdateAccountDocument)

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const { displayName, ...address } = form

    try {
      await updateAccount.mutateAsync({
        id: accountData?.id,
        data: { displayName, address } as UpdateAccountInput,
      })
      onComplete && onComplete()
      onClose()
      Alert.success('Account Updated')
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleCountryChange = (value: CountryInput | null) => {
    setForm && setForm((draft: any) => void (draft.country = value?.iso3 || ''))
  }

  const labelProps = {
    fontSize: 'md',
    fontWeight: 500,
    color: 'atomicGray.600',
    mb: 1,
  }

  return (
    <>
      {isOpen && (
        <ZModal
          isOpen={isOpen}
          onClose={onClose}
          size="2xl"
        >
          <ZModalOverlay />
          <ZModalContent>
            <form
              onSubmit={onSubmit}
              id="companyEditForm"
            >
              <ZModalHeader>Edit Company Info</ZModalHeader>
              <ZModalCloseButton />
              <ZModalBody>
                <Stack spacing={4}>
                  <UiFormControl
                    id="name"
                    mb={4}
                  >
                    <ZFormLabel {...labelProps}>Account Name</ZFormLabel>
                    <ZText
                      fontWeight={500}
                      fontSize="lg"
                      color="atomicGray.800"
                    >
                      {accountData?.name || ''}
                    </ZText>
                    <FormHelperText>This account name will show up in your invoices</FormHelperText>
                  </UiFormControl>
                  <UiFormControl id="displayName">
                    <ZFormLabel {...labelProps}>Display Name</ZFormLabel>
                    <ZInput
                      name="displayName"
                      type="ZText"
                      value={form.displayName}
                      onChange={handleInput}
                    />
                    <FormHelperText>This customer-facing name will show up in your emails and items</FormHelperText>
                  </UiFormControl>
                  <UiFormControl id="address-search">
                    <ZFormLabel {...labelProps}>Search Google for an Address</ZFormLabel>
                    <ZInputPlace onChange={(addr: any) => updateAddress(addr)} />
                  </UiFormControl>
                  <UiFormControl id="address1">
                    <ZFormLabel {...labelProps}>Street Address 1</ZFormLabel>
                    <ZInput
                      name="address1"
                      value={form.address1}
                      onChange={handleInput}
                    />
                  </UiFormControl>
                  <UiFormControl id="address2">
                    <ZFormLabel {...labelProps}>Street Address 2</ZFormLabel>
                    <ZInput
                      name="address2"
                      value={form.address2}
                      onChange={handleInput}
                    />
                  </UiFormControl>
                  <UiFormControl id="city">
                    <ZFormLabel {...labelProps}>City</ZFormLabel>
                    <ZInput
                      name="city"
                      value={form.city}
                      onChange={handleInput}
                    />
                  </UiFormControl>
                  <UiFormControl id="state">
                    <ZFormLabel {...labelProps}>State</ZFormLabel>
                    <AutoCompleteState
                      country={form.country}
                      value={form.state}
                      onChange={handleStateInputChange}
                    />
                  </UiFormControl>
                  <UiFormControl id="country">
                    <ZFormLabel {...labelProps}>Country</ZFormLabel>
                    <AutoCompleteCountry
                      countryName={form.country}
                      onChange={handleCountryChange}
                    />
                  </UiFormControl>
                  <UiFormControl id="postalCode">
                    <ZFormLabel {...labelProps}>Postal Code</ZFormLabel>
                    <ZInput
                      name="postalCode"
                      value={form.postalCode}
                      onChange={handleInput}
                    />
                  </UiFormControl>
                </Stack>
              </ZModalBody>

              <ZModalButtons
                confirmProps={{
                  type: 'submit',
                }}
                confirmText="Save"
                isConfirmLoading={updateAccount.isLoading}
                isConfirmDisabled={updateAccount.isLoading}
                onClose={onClose}
              />
            </form>
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}
