import type { BoxProps } from '@chakra-ui/react'
import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Image,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import type { AddressProps } from '@postal-io/postal-ui'
import { UiButton, UiDropzone, UiInput, UiInputPlace, UiTextarea, useAlerts } from '@postal-io/postal-ui'
import type { ItemCustomization } from 'api'
import { AssetType, ItemCustomizationType, SaveAssetDocument } from 'api'
import { uploadAsset } from 'api/rest'
import { MessagesDrawer, MessageVariablesDrawer } from 'components/Messages'
import { AnalyticsEventV2, useAnalyticsEvent, useAssets, useExtension } from 'hooks'
import { produce } from 'immer'
import compact from 'lodash/compact'
import React, { useCallback, useState } from 'react'
import picture from '../../assets/images/picture.svg'
import type { PostalSendEventV2 } from './data'
import type { PostalSendContext } from './usePostalSend'
import { validItemCustomizations } from './postalSendHelpers'

const NON_COLLECTION_ID = 'DEFAULT'

type GroupedCustomizations = Map<
  string,
  {
    name: string
    image: {
      src?: string
      fallbackSrc?: string
    }
    configs: Map<string, Set<ItemCustomization>>
  }
>

export interface PostalCustomizeItemProps extends BoxProps {
  context: PostalSendContext
  send: (evt: PostalSendEventV2) => void
}

export const PostalCustomizeItem: React.FC<PostalCustomizeItemProps> = ({ context, send, ...rest }) => {
  const { isExtension } = useExtension()

  useAnalyticsEvent({ event: AnalyticsEventV2.ExtensionSendFlowCustomizeItemStep, disabled: !isExtension })

  const { assetUrlSrc } = useAssets()
  /*
    This groups the customizable configs based on the marketplace product they are attached to.
    In the view we can iterate these and keep them contained.

    NOTE: In the view we will pop off the first config so we don't show duplicates

    NOTE: the marketplaceProductId is NON_COLLECTION_ID if the item customization is not a collection
    and therefore does nat contain a variantId.
    {
      [marketplaceProductId]: {
        name: 'Product Name',
        image: {src: 'https://', fallbackSrc: 'https://'}
        configs: {
          [config.id]: [config1, config2]
        }
      }
    }
  */
  const grouped = React.useMemo(() => {
    const postal = context?.postal
    const map: GroupedCustomizations = new Map()
    const configs = validItemCustomizations(context).filter((c) => !!c?.customizableBySender)
    configs?.forEach((config) => {
      const variant = postal?.variants?.find((v) => v.id === config.variantId)
      const name = variant?.productName || postal?.name || ''
      const image = assetUrlSrc(variant?.imageUrls?.[0]?.url || postal?.imageUrls?.[0]?.url)
      const marketplaceProductId = variant?.marketplaceProductId || NON_COLLECTION_ID
      const itemMap = map.get(marketplaceProductId) || { name, image, configs: new Map() }
      const configSet = itemMap.configs.get(config.id) || new Set()
      configSet.add(config)
      itemMap.configs.set(config.id, configSet)
      map.set(marketplaceProductId, itemMap)
    })
    return map
  }, [assetUrlSrc, context])

  /*
    Get the value from the itemCustomizationInputs that matches the itemCustomization
    config we are looking for.

    The first result is good enough since we will be updating all of them in parallel.
  */
  const getConfigValue = useCallback(
    (config: ItemCustomization) => {
      return context.itemCustomizationInputs?.find((input) => {
        return input.id === config.id && input.variantId === config.variantId
      })?.value
    },
    [context.itemCustomizationInputs]
  )

  /*
    This is going to find and change all the item customization configs that are
    related to the single edit in the view.

    There could be more than one if you have two variants from the same marketplace
    product in a collection.
  */
  const handleChange = useCallback(
    (marketplaceProductId: string, configId: string, value: any) => {
      const configs = grouped.get(marketplaceProductId)?.configs.get(configId)
      if (!configs?.size) return
      const data = produce(context.itemCustomizationInputs || [], (draft) => {
        configs.forEach(({ id, variantId }) => {
          const existing = draft.find((c) => id === c.id && variantId === c.variantId)
          if (existing) {
            existing.value = value
          } else {
            draft.push({ id, variantId, value })
          }
        })
      })
      send({ type: 'SET_CUSTOMIZE_ITEM', data })
    },
    [context.itemCustomizationInputs, grouped, send]
  )

  return (
    <Box
      h="100%"
      px={8}
      {...rest}
    >
      <Stack
        spacing={16}
        divider={<StackDivider borderColor="gray.200" />}
      >
        {Array.from(grouped).map(([marketplaceProductId, group]) => {
          return (
            <Box
              key={marketplaceProductId}
              w="100%"
            >
              <Grid
                gridTemplateColumns="300px 1fr"
                gap={16}
              >
                <Box>
                  <Image
                    h="100%"
                    w="100%"
                    maxH="275px"
                    borderRadius="lg"
                    border="1px solid #F2F2F2"
                    fit="cover"
                    {...group.image}
                  />
                  <Text
                    mt={4}
                    noOfLines={1}
                    fontWeight="semibold"
                    textAlign="center"
                  >
                    {group.name}
                  </Text>
                </Box>
                <Stack spacing={8}>
                  {/* Here we are popping the first config in the set to avoid dups in the view */}
                  {Array.from(group.configs).map(([configId, [config]]) => (
                    <CustomizeField
                      key={`${marketplaceProductId}-${configId}`}
                      config={config}
                      value={getConfigValue(config)}
                      onChange={(val) => handleChange(marketplaceProductId, configId, val)}
                    />
                  ))}
                </Stack>
              </Grid>
            </Box>
          )
        })}
      </Stack>
    </Box>
  )
}

interface CustomizeFieldProps {
  config: ItemCustomization
  value: any
  onChange: (value: any) => void
}

const CustomizeField: React.FC<CustomizeFieldProps> = (props) => {
  switch (props.config.type) {
    case ItemCustomizationType.Text:
      return <CustomizeTextField {...props} />
    case ItemCustomizationType.Image:
      return <CustomizeImageField {...props} />
    case ItemCustomizationType.File:
      return <CustomizeFileField {...props} />
    case ItemCustomizationType.Location:
      return <CustomizeLocationField {...props} />
    default:
      return null
  }
}

const CustomizeLabel: React.FC<{ config: ItemCustomization }> = ({ config }) => {
  return (
    <Box mb={4}>
      <FormLabel
        fontWeight="semibold"
        fontSize="lg"
        m={0}
      >
        {config.id}
        {!!config.characterLimit && (
          <Box
            as="span"
            ml={2}
            fontSize="sm"
            fontWeight="normal"
            whiteSpace="nowrap"
            color="gray.600"
          >
            (max: {config.characterLimit})
          </Box>
        )}
      </FormLabel>
      <FormHelperText
        m={0}
        ml="1px"
      >
        {config.displayName}
      </FormHelperText>
    </Box>
  )
}

interface CustomizeFieldInput extends BoxProps {
  config: ItemCustomization
  value: any
  onChange: (value: any) => void
}
const CustomizeTextField: React.FC<CustomizeFieldInput> = ({ config, value, onChange, ...rest }) => {
  const showVariables = useDisclosure()
  const showMessages = useDisclosure()
  const isTextArea = !config.characterLimit || config.characterLimit > 99

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(e.target.value)
  }

  return (
    <>
      <FormControl
        id={config.id}
        isRequired={!!config.required && !config.customizableByRecipient}
        {...rest}
      >
        <CustomizeLabel config={config} />
        {isTextArea ? (
          <UiTextarea
            name={config.id}
            value={value ?? ''}
            onChange={handleChange}
            placeholder={config.id}
            maxLength={config.characterLimit || undefined}
            minH="200px"
          />
        ) : (
          <UiInput
            name={config.id}
            value={value ?? ''}
            onChange={handleChange}
            placeholder={config.id}
            maxLength={config.characterLimit || undefined}
          />
        )}
        <Box textAlign="right">
          <UiButton
            variant="link"
            onClick={showVariables.onOpen}
          >
            Message Variables
          </UiButton>
          <UiButton
            ml={4}
            variant="link"
            onClick={showMessages.onOpen}
          >
            Saved Message
          </UiButton>
        </Box>
      </FormControl>
      <MessageVariablesDrawer
        isOpen={showVariables.isOpen}
        onClose={showVariables.onClose}
      />
      <MessagesDrawer
        isOpen={showMessages.isOpen}
        onClose={showMessages.onClose}
        onSelect={(template) => {
          onChange(template.templateText)
          showMessages.onClose()
        }}
      />
    </>
  )
}

const CustomizeImageField: React.FC<CustomizeFieldInput> = ({ config, value, onChange, ...rest }) => {
  const Alert = useAlerts()
  const saveAsset = useGraphqlMutation(SaveAssetDocument)
  const { assetUrlSrc } = useAssets()

  const acceptedFileTypes = React.useMemo(
    () =>
      config.fileTypesAccepted?.length
        ? config.fileTypesAccepted
        : ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'],
    [config.fileTypesAccepted]
  )

  const fileTypes = React.useMemo(
    () =>
      acceptedFileTypes.reduce((obj, type) => {
        obj[type] = []
        return obj
      }, {} as Record<string, string[]>),
    [acceptedFileTypes]
  )
  const fileTypesDescription = React.useMemo(
    () => acceptedFileTypes.map((a) => a.replace(/^image\//, '').replace('+xml', '')).join(', '),
    [acceptedFileTypes]
  )

  const imageSrc = React.useMemo(() => value && assetUrlSrc(value).src, [assetUrlSrc, value])

  const onDrop = async (files: any) => {
    if (files.length < 1) return Alert.warning('Please drop only supported file types')
    const [{ name }] = files
    try {
      const res = (await uploadAsset(files)) as any
      const { requestId } = res.find(Boolean)
      const created = await saveAsset.mutateAsync({
        assetType: AssetType.Image,
        name,
        requestId,
      })
      const saveRes = created?.saveAsset || {}
      if (saveRes.id) {
        onChange(`asset://${saveRes.id}`)
      } else {
        Alert.warning('There was an error uploading the image, please try again.')
      }
    } catch (err) {
      console.error(err)
      Alert.warning(err)
    }
  }

  return (
    <FormControl
      id={config.id}
      isRequired={!!config.required && !config.customizableByRecipient}
      {...rest}
    >
      <CustomizeLabel config={config} />
      <Grid
        templateColumns={{ base: '1fr', lg: imageSrc ? '300px 1fr' : '1fr' }}
        gap={8}
      >
        {!!imageSrc && (
          <Image
            src={imageSrc}
            objectFit="scale-down"
            borderWidth="6px"
            borderColor="shades.50"
            borderStyle="solid"
          />
        )}

        <UiDropzone
          onDrop={onDrop}
          isLoading={saveAsset.isLoading}
          isDisabled={saveAsset.isLoading}
          accept={fileTypes}
          multiple={false}
          data-testid="PostalCustomizeItem_Dropzone"
          minH="150px"
          borderRadius="md"
          bg="white"
        >
          <Image
            src={picture}
            mb={2}
          />
          <Text
            color="gray.500"
            textAlign="center"
          >
            Drag an image from your desktop here or click to select
          </Text>
          {!!fileTypesDescription && (
            <Text
              color="gray.500"
              textAlign="center"
            >
              ({fileTypesDescription})
            </Text>
          )}
        </UiDropzone>
      </Grid>
    </FormControl>
  )
}

const CustomizeFileField: React.FC<CustomizeFieldInput> = ({ config, value, onChange, ...rest }) => {
  const Alert = useAlerts()
  const saveAsset = useGraphqlMutation(SaveAssetDocument)

  const acceptedFileTypes = React.useMemo(
    () => (config.fileTypesAccepted?.length ? config.fileTypesAccepted : []),
    [config.fileTypesAccepted]
  )
  const fileTypes = React.useMemo(
    () =>
      acceptedFileTypes.reduce((obj, type) => {
        obj[type] = []
        return obj
      }, {} as Record<string, string[]>),
    [acceptedFileTypes]
  )
  const fileTypesDescription = React.useMemo(
    () => acceptedFileTypes.map((a) => a.replace(/^\w\//, '')).join(', '),
    [acceptedFileTypes]
  )

  const [fileName, setFileName] = useState<string>()

  const onDrop = async (files: any) => {
    if (files.length < 1) return Alert.warning('Please drop only supported file types')
    const [{ name }] = files
    try {
      const res = (await uploadAsset(files)) as any
      const { requestId } = res.find(Boolean)
      const created = await saveAsset.mutateAsync({
        assetType: AssetType.File,
        name,
        requestId,
      })
      const saveRes = created?.saveAsset || {}
      if (saveRes.id) {
        setFileName(saveRes.name || saveRes.id)
        onChange(`asset://${saveRes.id}`)
      } else {
        Alert.warning('There was an error uploading the file, please try again.')
      }
    } catch (err) {
      console.error(err)
      Alert.warning(err)
    }
  }

  return (
    <FormControl
      id={config.id}
      isRequired={!!config.required && !config.customizableByRecipient}
      {...rest}
    >
      <CustomizeLabel config={config} />
      <Grid
        templateColumns={{ base: '1fr', lg: !!value ? '300px 1fr' : '1fr' }}
        gap={8}
      >
        {!!value && (
          <Flex
            justifyContent="center"
            alignItems="center"
            fontWeight="semibold"
            borderColor="shades.50"
            borderWidth="1px"
          >
            File {fileName || 'Uploaded'}
          </Flex>
        )}

        <UiDropzone
          onDrop={onDrop}
          isLoading={saveAsset.isLoading}
          isDisabled={saveAsset.isLoading}
          accept={fileTypes}
          multiple={false}
          data-testid="PostalCustomizeItem_Dropzone"
          minH="150px"
          borderRadius="md"
          bg="white"
        >
          <Text
            color="gray.500"
            textAlign="center"
          >
            Drag a file from your desktop here or click to select
          </Text>
          {!!fileTypesDescription && (
            <Text
              color="gray.500"
              textAlign="center"
            >
              ({fileTypesDescription})
            </Text>
          )}
        </UiDropzone>
      </Grid>
    </FormControl>
  )
}

const CustomizeLocationField: React.FC<CustomizeFieldInput> = ({ config, value, onChange, ...rest }) => {
  const handleChange = (addr: AddressProps) => {
    const parts = [addr?.address1, addr?.city, addr?.state, addr?.postalCode, addr?.country]
    onChange(compact(parts).join(', '))
  }

  return (
    <FormControl
      id={config.id}
      isRequired={!!config.required && !config.customizableByRecipient}
      {...rest}
    >
      <CustomizeLabel config={config} />

      {!value && <UiInputPlace onChange={handleChange} />}
      {!!value && (
        <Box
          p={3}
          borderWidth="1px"
          borderRadius="md"
          borderColor="gray.200"
          fontWeight="semibold"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          bg="white"
        >
          {value}
          <UiButton
            variant="link"
            float="right"
            onClick={() => onChange('')}
          >
            Edit
          </UiButton>
        </Box>
      )}
    </FormControl>
  )
}
