import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { useAlerts, ZCard, ZCardHeader, ZHeading } from '@postal-io/postal-ui'
import { UpdateSelfDocument } from 'api'
import { AnalyticsEvent, useAnalyticsSend } from 'hooks'
import React from 'react'
import type { FontProps } from './Fonts'
import { FontPreview } from './Fonts'

interface HandwritingBlockV2Props {
  font: FontProps
  isSelected: boolean
}

export const HandwritingBlock: React.FC<HandwritingBlockV2Props> = ({ font, isSelected }) => {
  const Alert = useAlerts()

  const sendAnalytics = useAnalyticsSend()

  const updateSelf = useGraphqlMutation(UpdateSelfDocument, {
    onSuccess: () => {
      sendAnalytics({ event: AnalyticsEvent.HandwritingStyleSelected, data: { handwritingName: font.code } })
    },
  })

  const onClick = async (font: FontProps) => {
    if (isSelected) return
    try {
      await updateSelf.mutateAsync({ data: { handwritingName: font.code } })

      Alert.success(`Handwriting updated to ${font.name}`)
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <ZCard
      variant="form"
      _hover={{
        cursor: isSelected ? 'not-allowed' : 'pointer',
        borderColor: 'atomicBlue.200',
      }}
      position="relative"
      borderColor={isSelected ? 'atomicBlue.400' : 'default'}
      onClick={() => onClick(font)}
    >
      <ZCardHeader
        p={6}
        pb={0}
      >
        <ZHeading
          size="h6"
          fontSize="16px"
          color="atomicGray.500"
          fontWeight={350}
        >
          {font.name}
        </ZHeading>
      </ZCardHeader>
      <FontPreview
        font={font}
        p={4}
      />
    </ZCard>
  )
}
