import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { CATEGORY } from 'components/Postals'
import { useMemo } from 'react'
import type { ApprovedPostal } from '../api'
import { GetAccountDocument, PostalDeliveryEmailSetting, Role, Status } from '../api'
import { useAcl } from './useAcl'
import { getdefaultNonLinkSendMethod } from './usePostalPermissions'
import { useSession } from './useSession'

export const useCollectionPermissions = (collection?: ApprovedPostal | null) => {
  const { hasPermission, hasFeature, hasRole } = useAcl()
  const getAccount = useGraphqlQuery(GetAccountDocument)
  const account = useMemo(() => getAccount.data?.getAccount, [getAccount.data?.getAccount])
  const { session } = useSession()
  const hasLinks = hasFeature('links')
  const hasUserCollections = hasRole(Role.User) && hasFeature('userCreatedCollections')

  const isCollection = useMemo(() => collection?.category === CATEGORY.Collection, [collection?.category])

  const isMyCollection = useMemo(() => {
    return hasUserCollections && collection?.ownerId === session.userId
  }, [collection?.ownerId, hasUserCollections, session.userId])

  const canCreateShared = useMemo(() => {
    return hasPermission('postals.create')
  }, [hasPermission])

  const canCreate = useMemo(() => {
    return hasUserCollections || canCreateShared
  }, [canCreateShared, hasUserCollections])

  const canUpdate = useMemo(() => {
    return isCollection && (isMyCollection || hasPermission('postals.update'))
  }, [isCollection, isMyCollection, hasPermission])

  const canDelete = useMemo(() => {
    return isCollection && (isMyCollection || hasPermission('postals.delete'))
  }, [isCollection, isMyCollection, hasPermission])

  const canSend = useMemo(() => {
    return isCollection && collection?.status === Status.Active && hasPermission('postals.send')
  }, [isCollection, collection?.status, hasPermission])

  const canSendGiftEmail = useMemo(() => {
    return canSend
  }, [canSend])

  const canSendDirectly = useMemo(() => {
    return canSend && account && account.postalDeliveryEmailSetting !== PostalDeliveryEmailSetting.Always
  }, [account, canSend])

  const canLink = useMemo(() => {
    return canSend && hasLinks
  }, [canSend, hasLinks])

  const defaultNonLinkSendMethod = useMemo(() => {
    return getdefaultNonLinkSendMethod({ account, canSendGiftEmail })
  }, [account, canSendGiftEmail])

  return {
    hasUserCollections,
    canCreateShared,
    canCreate,
    canUpdate,
    canDelete,
    canSend,
    canSendGiftEmail,
    canSendDirectly,
    canLink,
    defaultNonLinkSendMethod,
  }
}
