import { QuestionOutlineIcon } from '@chakra-ui/icons'
import {
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
} from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerFooterButtons,
  UiDrawerHeader,
  UiDrawerOverlay,
  UiSubNavbar,
  UiTable,
  UiTableContainer,
  UiTbody,
  UiTd,
  UiText,
  UiTh,
  UiThead,
  UiTr,
  useAlerts,
} from '@postal-io/postal-ui'
import type { FileDataMappingInput } from 'api'
import { BackgroundTaskStatus, CompleteDataImportDocument, DataObjectType } from 'api'
import { CONTACT_INVALIDATIONS, useBackgroundQueue } from 'hooks'
import React, { useEffect, useState } from 'react'

const targetFields = [
  { key: 'firstName', label: 'First Name' },
  { key: 'lastName', label: 'Last Name' },
  { key: 'companyName', label: 'Company' },
  { key: 'title', label: 'Title' },
  { key: 'emailAddress', label: 'Email' },
  { key: 'phoneNumber', label: 'Phone' },
  { key: 'address1', label: 'Address 1' },
  { key: 'address2', label: 'Address 2' },
  { key: 'address3', label: 'Address 3' },
  { key: 'city', label: 'City' },
  { key: 'state', label: 'State' },
  { key: 'postalCode', label: 'Postal Code' },
  { key: 'country', label: 'Country' },
  { key: 'listName', label: 'List' },
  { key: 'tagName', label: 'Tag' },
]

// const handle multiple import mappings

export interface ContactsImportMappingProps {
  mappings: FileDataMappingInput[]
  onClose: () => void
}

export const ContactsImportMapping: React.FC<ContactsImportMappingProps> = ({ mappings = [], onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const onComplete = () => {
    if (currentIndex < mappings.length - 1) {
      setCurrentIndex((curr) => curr + 1)
    } else {
      onClose()
    }
  }
  const currentMapping = mappings[currentIndex]
  return currentMapping ? (
    <ImportMapping
      onComplete={onComplete}
      mapping={currentMapping}
    />
  ) : null
}

export interface ImportMappingProps {
  onComplete: () => void
  mapping: FileDataMappingInput
}

// Handle a single import mapping
const ImportMapping: React.FC<ImportMappingProps> = ({ onComplete, mapping }) => {
  const loading = false
  const { columnList, requestId, source, fileName, sampleData } = mapping
  const [form, setForm] = useState<any>({})
  const Alert = useAlerts()

  const { queue, invalidate } = useBackgroundQueue()

  const saveMapping = useGraphqlMutation(CompleteDataImportDocument, {
    onSuccess: (data) => {
      queue(data.completeDataImport)
      invalidate(CONTACT_INVALIDATIONS)
    },
  })

  // reset form when a new mapping comes in
  useEffect(() => {
    const newForm = columnList?.reduce((obj: any, column) => {
      obj[column.index] = column.targetField || ''
      return obj
    }, {})
    setForm(newForm)
  }, [columnList])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setForm({ ...form, [Number(e.target.name)]: e.target.value })

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const data = {
      columnList: columnList?.map((column) => {
        return { ...column, targetField: form[column.index] }
      }),
      requestId,
      source,
      fileName,
      type: DataObjectType.Contact,
    }
    try {
      const res = await saveMapping.mutateAsync({ data })
      queue(res.completeDataImport)
      const status = res?.completeDataImport?.status
      status === BackgroundTaskStatus.Error
        ? Alert.warning(`There was an error processing this file`)
        : Alert.success(`Contacts are being processed in the background`)
      onComplete()
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <UiDrawer
      size="2xl"
      isOpen={true}
      onClose={onComplete}
    >
      <UiDrawerOverlay />
      <form onSubmit={handleSubmit}>
        <UiDrawerContent>
          <UiDrawerCloseButton />
          <UiDrawerHeader>
            Remapping
            <Popover
              trigger="hover"
              placement="right"
            >
              <PopoverTrigger>
                <QuestionOutlineIcon
                  color="tertiary.500"
                  h="18px"
                  w="16px"
                  marginLeft="7px"
                  data-testid="popOver"
                />
              </PopoverTrigger>
              <PopoverContent
                zIndex={4}
                color="gray.800"
              >
                <PopoverHeader
                  fontSize="md"
                  fontWeight="normal"
                >
                  Remapping Requirements:
                </PopoverHeader>
                <PopoverArrow />
                <PopoverBody
                  fontSize="sm"
                  fontWeight="normal"
                >
                  <List
                    styleType="disc"
                    listStylePosition="outside"
                    paddingLeft="15px"
                  >
                    <ListItem>First Name, Last Name, and Email are required to create a contact.</ListItem>
                    <ListItem>
                      Address 1 must exist in order to add City, State, Postal Code, and Country data.
                    </ListItem>
                  </List>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <UiText
              fontSize="lg"
              fontWeight="normal"
              mb={6}
            >
              Please select which values you want to remap.
              <br></br>
              Unmapped values will not be imported.
            </UiText>
          </UiDrawerHeader>
          <UiSubNavbar />
          <UiDrawerBody>
            <UiTableContainer>
              <UiTable>
                <UiThead>
                  <UiTr>
                    {columnList?.map(({ index }) => {
                      return (
                        <UiTh key={index}>
                          <Select
                            name={String(index)}
                            value={form[index]}
                            onChange={handleChange}
                            minW="100px"
                          >
                            <option value="">No Mapping</option>
                            {targetFields.map(({ key, label }) => (
                              <option
                                key={key}
                                value={key}
                              >
                                {label}
                              </option>
                            ))}
                          </Select>
                        </UiTh>
                      )
                    })}
                  </UiTr>
                </UiThead>
                <UiTbody>
                  {sampleData?.map((row, idx) => (
                    <UiTr key={idx}>
                      {row?.map((col: any, idx: number) => (
                        <UiTd
                          key={idx}
                          data-private
                        >
                          {col}
                        </UiTd>
                      ))}
                    </UiTr>
                  ))}
                </UiTbody>
              </UiTable>
            </UiTableContainer>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiDrawerFooterButtons>
              <UiButton
                colorScheme="gray"
                onClick={onComplete}
              >
                Skip
              </UiButton>
              <UiButton
                type="submit"
                isDisabled={loading}
                isLoading={loading}
              >
                Remap
              </UiButton>
            </UiDrawerFooterButtons>
          </UiDrawerFooter>
        </UiDrawerContent>
      </form>
    </UiDrawer>
  )
}
