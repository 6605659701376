import { Flex, Grid, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import type { UiChangeEvent, UiGenericChangeEvent } from '@postal-io/postal-ui'
import {
  UiFormControl,
  useAlerts,
  useImmerWithMemory,
  ZButton,
  ZFormLabel,
  ZInputMoney,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZMoney,
  ZSelectTypeahead,
  ZText,
  ZTextarea,
} from '@postal-io/postal-ui'
import type { BillingAccount, TransferIntent } from 'api'
import { CancelTransferIntentDocument, CreateTransferIntentDocument } from 'api'
import type { ChangeEvent, FormEvent } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { MdOutlineInfo } from 'react-icons/md'
import { ConfirmTransferIntentDocument } from '../../api/index'
import { ConfirmViewV2, ForeignConfirmViewV2 } from './BalanceTransferCreateModal'

interface InlineErrorDisplayProps {
  error?: string
}
const InlineErrorDisplay: React.FC<InlineErrorDisplayProps> = ({ error }) =>
  error ? (
    <Text
      textStyle="italic"
      mt={2}
      color="red.500"
    >
      {error}
    </Text>
  ) : null

type SelectAccountRowProps = {
  account: BillingAccount
  insufficientBalance?: boolean
}

const SelectAccountRow: React.FC<SelectAccountRowProps> = ({ account, insufficientBalance }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      className="select-account-row"
    >
      <Text
        fontSize="sm"
        flexShrink={1}
        mr={2}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        color={insufficientBalance ? 'gray.400' : 'gray.800'}
      >
        {account.name}
      </Text>
      <Text
        fontSize="xs"
        color={insufficientBalance ? 'red.300' : 'gray.500'}
        textAlign="right"
      >
        <ZMoney
          cents={account.balance}
          currency={account.currency}
        />
      </Text>
    </Flex>
  )
}

interface FormProps {
  fromBillingAccountId: string
  toBillingAccountId: string
  amount: number
  comment: string
}

interface BalanceTransferCreateFormProps {
  billingAccounts?: BillingAccount[]
  defaultValues?: { fromBillingAccountId?: string }
  foreignTransfer?: boolean
}

export const BalanceTransferCreateForm: React.FC<BalanceTransferCreateFormProps> = ({
  billingAccounts,
  defaultValues,
  foreignTransfer,
}) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const Alert = useAlerts()
  const confirmDomesticDisclosure = useDisclosure()
  const confirmForeignDisclosure = useDisclosure()
  const {
    state: form,
    setState: setForm,
    resetState: resetForm,
  } = useImmerWithMemory<Record<string, any>>({ ...defaultValues })
  const [transferIntent, setTransferIntent] = useState<TransferIntent | undefined>()

  const currencyCompatible = useCallback(
    (accountA: BillingAccount | null, accountB: BillingAccount | null) =>
      foreignTransfer ? accountA?.currency !== accountB?.currency : accountA?.currency === accountB?.currency,
    [foreignTransfer]
  )

  const createTransferIntentMutation = useGraphqlMutation(CreateTransferIntentDocument)
  const confirmTransferIntentMutation = useGraphqlMutation(ConfirmTransferIntentDocument)
  const cancelTransferIntentMutation = useGraphqlMutation(CancelTransferIntentDocument)

  const fromBillingAccounts = billingAccounts

  const fromBillingAccount = useMemo(
    () => fromBillingAccounts?.find((a) => a.id === form.fromBillingAccountId) ?? null,
    [form.fromBillingAccountId, fromBillingAccounts]
  )

  const amountError = useMemo(
    () =>
      fromBillingAccount && form.amount > (fromBillingAccount?.balance ?? 0)
        ? 'Amount exceeds current balance.'
        : fromBillingAccount && form.amount === 0
        ? 'Please enter an amount.'
        : '',
    [form.amount, fromBillingAccount]
  )

  // until we support transfer between different currencies
  const toBillingAccounts = useMemo(() => {
    return fromBillingAccounts?.filter(
      (account) => currencyCompatible(account, fromBillingAccount) && account.id !== form.fromBillingAccountId
    )
  }, [form?.fromBillingAccountId, fromBillingAccounts, fromBillingAccount, currencyCompatible])

  const toBillingAccount = useMemo(() => {
    const toBillingAccount = toBillingAccounts?.find((a) => a.id === form.toBillingAccountId) ?? null
    return currencyCompatible(fromBillingAccount, toBillingAccount) ? toBillingAccount : null
  }, [form?.toBillingAccountId, fromBillingAccount, toBillingAccounts, currencyCompatible])

  const handleInput = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | UiGenericChangeEvent
  ) => {
    setForm((draft: any) => void (draft[e.target.name] = e.target.value))
  }

  const handleAccountChange = (account: any, event: any) => {
    const { name } = event
    setForm((draft: any) => void (draft[name] = account?.id))
  }

  const handleInputMoney = ({ key, value }: UiChangeEvent<number>) => {
    setForm((draft: any) => void (draft[key] = value))
  }

  const cancelIntent = async () => {
    if (!transferIntent) return
    try {
      await cancelTransferIntentMutation.mutateAsync({ id: transferIntent.id })
      setTransferIntent(undefined)
    } catch (err) {
      Alert.error(err)
    }
    return true
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    try {
      // get the fx rate & info
      const res = await createTransferIntentMutation.mutateAsync({ input: form as FormProps })
      setTransferIntent(res.createTransferIntent)
      foreignTransfer ? confirmForeignDisclosure.onOpen() : confirmDomesticDisclosure.onOpen()
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleConfirm = async () => {
    if (!transferIntent) return
    try {
      await confirmTransferIntentMutation.mutateAsync({ id: transferIntent.id, localTimeZone })
      Alert.success('Transfer confirmed')
      resetForm()
      setTransferIntent(undefined)
      foreignTransfer ? confirmForeignDisclosure.onClose() : confirmDomesticDisclosure.onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  // if only one entry for "Transfer To", go ahead and select it
  useEffect(() => {
    if (toBillingAccounts?.length === 1) {
      setForm((draft: any) => void (draft.toBillingAccountId = toBillingAccounts[0].id))
    }
  }, [setForm, toBillingAccounts])

  const isLoading = createTransferIntentMutation.isLoading

  const labelProps = {
    color: 'atomicGray.500',
    fontWeight: 350,
    fontSize: '14px',
    sx: {
      '& > span': {
        ml: 0,
      },
    },
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        templateColumns="repeat(3, 1fr)"
        columnGap={4}
        rowGap={6}
        mb={6}
      >
        <UiFormControl
          id="fromBillingAccountId"
          isRequired
        >
          <ZFormLabel {...labelProps}>Transfer From</ZFormLabel>
          <ZSelectTypeahead
            data-testid="AutoCompleteTransferFromAccount"
            name="fromBillingAccountId"
            isOptionDisabled={(acct) => !acct.balance}
            isLoading={isLoading}
            value={fromBillingAccount}
            options={fromBillingAccounts}
            getOptionLabel={(t) => t.name}
            getOptionValue={(t) => t.id}
            onChange={handleAccountChange}
            formatOptionLabel={(acct) => (
              <SelectAccountRow
                account={acct}
                insufficientBalance={!acct.balance}
              />
            )}
            isClearable={false}
            placeholder="Select a From Account"
            noOptionsMessage={() => (fromBillingAccount ? 'No other Accounts Found' : 'No Accounts Found')}
          />
        </UiFormControl>
        <UiFormControl
          id="toBillingAccountId"
          isRequired
        >
          <ZFormLabel {...labelProps}>Transfer To</ZFormLabel>
          <ZSelectTypeahead
            data-testid="AutoCompleteTransferToAccount"
            name="toBillingAccountId"
            isLoading={isLoading}
            value={toBillingAccount}
            options={toBillingAccounts}
            isDisabled={!fromBillingAccount}
            getOptionLabel={(t) => t.name}
            getOptionValue={(t) => t.id}
            onChange={handleAccountChange}
            formatOptionLabel={(acct) => <SelectAccountRow account={acct} />}
            isClearable={false}
            placeholder={
              fromBillingAccount ? 'Select a To Account' : <Text color="gray.300">Select a From Account first</Text>
            }
            noOptionsMessage={() => (toBillingAccount ? 'No other valid Accounts found' : 'No valid Accounts found')}
          />
        </UiFormControl>
        <UiFormControl
          id="amount"
          isRequired
        >
          <ZFormLabel {...labelProps}>Amount</ZFormLabel>
          <ZInputMoney
            name="amount"
            value={form.amount ?? ''}
            onChange={handleInputMoney}
            currency={fromBillingAccount?.currency || undefined}
            max={fromBillingAccount?.balance as number}
            isInvalid={!!amountError}
            isDisabled={isLoading}
            h="40px"
            propsLeftElement={{ h: '40px' }}
          />
          <InlineErrorDisplay error={amountError} />
        </UiFormControl>
        <UiFormControl
          id="comment"
          gridColumn="1 / 4"
          isRequired
        >
          <ZFormLabel {...labelProps}>Notes</ZFormLabel>
          <ZTextarea
            name="comment"
            value={form.comment ?? ''}
            onChange={handleInput}
            isDisabled={isLoading}
          />
        </UiFormControl>
      </Grid>
      {foreignTransfer && (
        <ZText
          color="atomicGray.500"
          fontSize="sm"
          flexGrow={1}
          display="flex"
          alignItems="center"
          mb={6}
          gap={2}
        >
          <MdOutlineInfo style={{ marginRight: 0 }} />
          Foreign currency transfers will execute at the exchange rate evaluated on the time of the transfer request.
        </ZText>
      )}
      <ZButton
        type="submit"
        colorScheme="atomicBlue"
        px={6}
        py={3}
        size="sm"
        isDisabled={isLoading || !form.fromBillingAccountId || !form.toBillingAccountId || !!amountError}
        isLoading={isLoading}
      >
        Set up transfer
      </ZButton>
      <ZModal
        size="6xl"
        scrollBehavior="outside"
        {...confirmDomesticDisclosure}
        onClose={async () => (await cancelIntent()) && confirmDomesticDisclosure.onClose()}
      >
        <ZModalOverlay />
        <ZModalContent>
          <ZModalHeader>Review and Confirm</ZModalHeader>
          <ZModalCloseButton />
          <ZModalBody>
            <ConfirmViewV2
              form={form}
              billingAccounts={billingAccounts}
            />
          </ZModalBody>
          <ZModalButtons
            onConfirm={handleConfirm}
            isConfirmLoading={isLoading}
            isConfirmDisabled={isLoading}
            onClose={async () => (await cancelIntent()) && confirmDomesticDisclosure.onClose()}
          />
        </ZModalContent>
      </ZModal>
      <ZModal
        size="6xl"
        scrollBehavior="outside"
        {...confirmForeignDisclosure}
        onClose={async () => (await cancelIntent()) && confirmForeignDisclosure.onClose()}
      >
        <ZModalOverlay />
        <ZModalContent>
          <ZModalHeader>Confirm Foreign Transfer</ZModalHeader>
          <ZModalCloseButton />
          <ZModalBody>
            <ForeignConfirmViewV2
              transferIntent={transferIntent}
              billingAccounts={billingAccounts}
            />
          </ZModalBody>
          <ZModalButtons
            onConfirm={handleConfirm}
            isConfirmLoading={isLoading}
            isConfirmDisabled={isLoading}
            onClose={async () => (await cancelIntent()) && confirmForeignDisclosure.onClose()}
          />
        </ZModalContent>
      </ZModal>
    </form>
  )
}
