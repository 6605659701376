import { Box, Flex, VStack } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import {
  FontWeight,
  UiAlert,
  UiFormControl,
  useAlerts,
  ZButton,
  ZCard,
  ZCardHeader,
  ZFormLabel,
  ZSelect,
  ZText,
} from '@postal-io/postal-ui'
import type { Account, UpdateAccountInput } from 'api'
import { GetAccountDocument, UpdateAccountDocument } from 'api'
import type { ChangeEvent, FormEvent } from 'react'
import React, { useEffect, useMemo } from 'react'
import Countdown from 'react-countdown'
import { MdCheckCircle } from 'react-icons/md'
import { useImmer } from 'use-immer'

const SUPPORT_ENABLED_OPTIONS = [
  { text: '1 Hour', value: 1 },
  { text: '3 Hours', value: 3 },
  { text: '1 Day', value: 24 },
  { text: '1 Week', value: 168 },
  { text: 'Always Enabled', value: '' },
]

// using a future date instead of null was a workaround forwarded by backend
const DISTANT_FUTURE = new Date('08-24-2984')

export const AccountSupportAccessBlock: React.FC<UiCardProps> = (props) => {
  const Alert = useAlerts()
  const [form, setForm] = useImmer<UpdateAccountInput>({})

  const getAccountQuery = useGraphqlQuery(GetAccountDocument)
  const { id, supportEnabled } = getAccountQuery?.data?.getAccount ?? ({} as Account)
  const supportEnabledDateTime = useMemo(() => new Date(supportEnabled), [supportEnabled])

  const updateAccount = useGraphqlMutation(UpdateAccountDocument)

  useEffect(() => {
    if (getAccountQuery.isSuccess) {
      setForm(() => ({ supportEnabled: DISTANT_FUTURE }))
    }
  }, [getAccountQuery.isSuccess, setForm, supportEnabled])

  const handleSupportEnabled = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    setForm((draft: any) => {
      const dateTime = new Date()
      if (value === '') {
        draft['supportEnabled'] = DISTANT_FUTURE
      } else {
        dateTime.setHours(dateTime.getHours() + Number(value))
        draft['supportEnabled'] = dateTime
      }
    })
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    try {
      await updateAccount.mutateAsync({ id, data: form })
      Alert.success('Support Access Updated')
    } catch (err) {
      Alert.error(err)
    }
  }

  // set supportEnabled to current time
  const handleRevokeAccess = async () => {
    try {
      await updateAccount.mutateAsync({ id, data: { supportEnabled: new Date() } })
      Alert.success('Support Access Revoked')
    } catch (err) {
      Alert.error(err)
    }
  }

  const isCurrentlyEnabled = useMemo(() => {
    if (supportEnabled && supportEnabledDateTime > new Date()) return true
    else if (supportEnabled && supportEnabledDateTime < new Date()) return false
    else if (supportEnabled === null) return true
  }, [supportEnabled, supportEnabledDateTime])

  const isLoading = false
  return (
    <ZCard
      pos="relative"
      isLoading={isLoading}
      variant="form"
      {...props}
    >
      <ZCardHeader
        p={8}
        pb={0}
      >
        Support Access
      </ZCardHeader>

      <form onSubmit={handleSubmit}>
        <VStack
          spacing={8}
          p={8}
        >
          {isCurrentlyEnabled ? (
            <>
              <Flex
                alignItems="center"
                p={8}
                color="atomicBlue.400"
                gap={2}
              >
                <MdCheckCircle size="24px" />
                <ZText
                  fontSize="18px"
                  fontWeight="normal"
                  color="atomicGray.700"
                >
                  Support access enabled
                </ZText>
              </Flex>

              {supportEnabledDateTime > new Date() && (
                <>
                  <ZText
                    fontSize="xl"
                    fontWeight="semibold"
                    color="atomicGray.700"
                  >
                    Access time remaining
                  </ZText>

                  <ZText
                    color="atomicGray.500"
                    fontSize="4xl"
                    fontWeight={FontWeight.Bold}
                  >
                    <Countdown date={supportEnabled} />
                  </ZText>
                </>
              )}
              <Box
                display="flex"
                w="100%"
                justifyContent="center"
              >
                <ZButton
                  size="md"
                  colorScheme="atomicRed"
                  variant="subtle"
                  px={4}
                  py={6}
                  onClick={handleRevokeAccess}
                  fontWeight="normal"
                >
                  Revoke Access Immediately
                </ZButton>
              </Box>
            </>
          ) : (
            <>
              {isCurrentlyEnabled ? (
                <UiAlert
                  status="success"
                  hideClose
                  title="Support Access Enabled"
                />
              ) : (
                <UiAlert
                  status="warning"
                  hideClose
                  title="Support Access Disabled"
                />
              )}

              <Box w="100%">
                <UiFormControl
                  id="handleSupportEnabled"
                  w="100%"
                >
                  <ZFormLabel fontSize="lg">Enable access for</ZFormLabel>
                  <ZSelect
                    onChange={handleSupportEnabled}
                    defaultValue=""
                    w="100%"
                  >
                    {SUPPORT_ENABLED_OPTIONS.map(({ text, value }, idx) => (
                      <option
                        key={idx}
                        value={value}
                      >
                        {text}
                      </option>
                    ))}
                  </ZSelect>
                </UiFormControl>
              </Box>

              <Box>
                <ZButton
                  size="md"
                  px={4}
                  py={6}
                  colorScheme="atomicBlue"
                  type="submit"
                >
                  Enable Support Access
                </ZButton>
              </Box>
            </>
          )}
        </VStack>
      </form>
    </ZCard>
  )
}
