import { Box, Flex, FormControl, SimpleGrid, Skeleton, useClipboard, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  SelectTypeaheadStylesV2,
  UiAlert,
  UiLink,
  useAlerts,
  ZButton,
  ZFormLabel,
  ZInput,
  ZLink,
  ZText,
} from '@postal-io/postal-ui'
import type { IntegrationTrigger } from 'api'
import { CreateUpdateIntegrationTriggerDocument, GetIntegrationTriggerDocument, Role, Status, TriggerAction } from 'api'
import { RequiredSelectTypeahead } from 'components/AutoComplete/RequiredSelectTypeahead'
import { CenteredBox } from 'components/Common'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { ExternalSystem, getExternalProvider, TriggerType } from 'components/Integrations'
import type { PostalSendContext } from 'components/PostalSend'
import { SendAsType } from 'components/PostalSend'
import { NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { PLAYBOOK_ACTIONS } from 'components/Trigger/triggerData'
import { useTriggerProviders } from 'components/Triggers/useTriggerProviders'
import { PageTitle, useAcl } from 'hooks'
import type { ChangeEvent } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import type { Updater } from 'use-immer'
import { useImmer } from 'use-immer'
import { PlaybookActionCardV2, PostalActionCardV2 } from './ActionCard'
import { TriggerEditFilters } from './TriggerEditFilters'
import { TriggerEditFiltersHelp } from './TriggerEditFiltersHelp'
import { TriggerEditPostalSend } from './TriggerEditPostalSend'
import { TriggerEditProgram } from './TriggerEditProgram'
import { TriggerEditRecipe } from './TriggerEditRecipe'
import { TriggerEditSelectPlaybook } from './TriggerEditSelectPlaybook'
import { TriggerEditSequence } from './TriggerEditSequence'

type View = 'edit' | 'subscription' | 'send' | 'none'

export const TriggerEdit: React.FC = () => {
  const { triggerId } = useParams() as any
  // useGraphqlQuery (in EditTrigger) crashes when triggerId === undefined;
  // Since hooks can't be conditional, we need to separate EditTrigger and AddTrigger into their own components
  return triggerId ? <EditTrigger triggerId={triggerId} /> : <AddTrigger />
}

const EditTrigger: React.FC<{ triggerId: any }> = ({ triggerId }) => {
  const getTrigger = useGraphqlQuery(GetIntegrationTriggerDocument, { id: triggerId })
  const trigger = useMemo(() => getTrigger?.data?.getIntegrationTrigger, [getTrigger?.data?.getIntegrationTrigger])
  return (
    <TriggerEditFlow
      isLoading={getTrigger.isLoading}
      trigger={trigger}
    />
  )
}

// Wrapper component for readability
const AddTrigger: React.FC = () => {
  return <TriggerEditFlow />
}

interface TriggerEditFlowProps {
  isLoading?: boolean
  trigger?: IntegrationTrigger
}

const TriggerEditFlow: React.FC<TriggerEditFlowProps> = ({ isLoading, trigger }) => {
  // Cached form state, initialized by incoming trigger
  const [state, setState] = useImmer({} as any)
  useEffect(() => {
    setState(() => trigger)
  }, [trigger, setState])

  // Postal is selected, set the action type and required values
  const handleSelectPostal = (context: PostalSendContext) => {
    setState((draft: Record<string, any>) => {
      draft.action = draft.newAction
      draft.approvedPostalId = context.postal?.id
      draft.approvedPostalVariantId = context.variant?.id
      draft.giftMessage = context.giftMessage
      draft.deliveryEmail = context.deliveryEmail
      draft.physicalMessage = context.physicalMessage
      draft.meetingRequestSetting = context.meetingRequestSetting
      draft.emailSubjectLine = context.emailSubjectLine
      draft.sendAsContactOwner = context.sendAsContactOwner
      draft.sendAsUser = context.sendAsUser
      draft.itemCustomizationInputs = context.itemCustomizationInputs
      draft.landingPageHeaderText = context.landingPageHeaderText
      draft.landingPageBody = context.landingPageBody
      draft.landingPageIncludeHeadshot = context.landingPageIncludeHeadshot
      draft.landingPageIncludeSenderName = context.landingPageIncludeSenderName
      draft.formFieldList = context.formFieldList
      draft.spendAsTeamId = context.spendAsTeamId
      draft.spendAsUserId = context.spendAsUserId
      draft.shippedEmailsOn = context.shippedEmailsOn
      draft.deliveredEmailsOn = context.deliveredEmailsOn
      delete draft.newAction
      delete draft.playbookDefinitionId
    })
  }

  // Playbook is selected, set the action type and required values
  const handleSelectPlaybook = (playbook: any) => {
    setState((draft: Record<string, any>) => {
      draft.action = draft.newAction
      draft.playbookDefinitionId = playbook.id
      delete draft.approvedPostalId
      delete draft.approvedPostalVariantId
      delete draft.giftMessage
      delete draft.deliveryEmail
      delete draft.physicalMessage
      delete draft.meetingRequestSetting
      delete draft.emailSubjectLine
      delete draft.sendAsContactOwner
      delete draft.sendAsUser
      delete draft.itemCustomizationInputs
      delete draft.newAction
      delete draft.landingPageHeaderText
      delete draft.landingPageBody
      delete draft.landingPageIncludeHeadshot
      delete draft.landingPageIncludeSenderName
      delete draft.formFieldList
      delete draft.spendAsTeamId
      delete draft.spendAsUserId
    })
  }

  const [view, setView] = useState<View>('edit')
  const [approvedPostalId, setApprovedPostalId] = useState('')

  switch (view) {
    case 'subscription':
      return (
        <TriggerEditSelectPlaybook
          onNavigateBack={() => setView('edit')}
          onSelect={(playbook) => {
            handleSelectPlaybook(playbook)
            setView('edit')
          }}
        />
      )
    case 'send':
      return (
        <TriggerEditPostalSend
          approvedPostalId={approvedPostalId}
          onComplete={(context) => {
            handleSelectPostal(context)
            setView('edit')
          }}
          onNavigateBack={() => setView('edit')}
          trigger={trigger}
        />
      )
    case 'edit':
    case 'none':
    default:
      return (
        <TriggerEditForm
          isLoading={isLoading}
          state={state}
          setState={setState}
          setView={setView}
          setApprovedPostalId={setApprovedPostalId}
          trigger={trigger}
        />
      )
  }
}

interface TriggerEditFormProps {
  isLoading?: boolean
  state: any
  setState: Updater<any>
  setView: React.Dispatch<React.SetStateAction<View>>
  setApprovedPostalId: React.Dispatch<React.SetStateAction<string>>
  trigger?: any
}

const appExchangeLink = 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000FMna4UAD'

const TriggerEditForm: React.FC<TriggerEditFormProps> = ({
  isLoading,
  state,
  setState,
  setView,
  setApprovedPostalId,
  trigger,
}) => {
  const Alert = useAlerts()
  const filterHelp = useDisclosure()
  const navigate = useNavigate()
  const { hasRole } = useAcl()

  // Helpers
  const isPostal = (action: TriggerAction) => action === TriggerAction.SendPostal
  const isPlaybook = (action: TriggerAction) => PLAYBOOK_ACTIONS.includes(action)
  const isNothing = (action: TriggerAction) => action === TriggerAction.Nothing
  const provider = getExternalProvider(state.systemName)
  const isMarketo = provider?.system === ExternalSystem.Marketo

  const integrationTipsy = hasRole(Role.User) && !hasRole(Role.Admin)

  // Data loading
  const { providers } = useTriggerProviders()

  // Data Mutation
  const updateTrigger = useGraphqlMutation(CreateUpdateIntegrationTriggerDocument)

  // Handle form change
  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setState((draft: Record<string, any>) => {
      switch (name) {
        case 'name':
          draft.name = value
          break
        default:
      }
    })
  }

  const handleSelectChange = (name: string) => (e: any) => {
    const { value } = e
    switch (name) {
      case 'systemName':
        setState((draft: Record<string, any>) => {
          // reset most things if we change the system name
          if (value !== draft.systemName) {
            delete draft.action
            delete draft.approvedPostalId
            delete draft.approvedPostalVariantId
            delete draft.giftMessage
            delete draft.deliveryEmail
            delete draft.physicalMessage
            delete draft.meetingRequestSetting
            delete draft.emailSubjectLine
            delete draft.sendAsContactOwner
            delete draft.sendAsUser
            delete draft.itemCustomizationInputs
            delete draft.playbookDefinitionId
            delete draft.newAction
            delete draft.landingPageHeaderText
            delete draft.landingPageBody
            delete draft.landingPageIncludeHeadshot
            delete draft.landingPageIncludeSenderName
            delete draft.formFieldList
            delete draft.spendAsTeamId
            delete draft.spendAsUserId
            delete draft.filters
          }
          draft.systemName = value
        })
        break
      case 'action':
        if (isNothing(value as TriggerAction)) {
          handleNothingAction()
        } else {
          handleAction(value as TriggerAction)
        }
        break
      default:
    }
  }

  // If nothing set action and delete the leftovers
  const handleNothingAction = () => {
    setState((draft: Record<string, any>) => {
      draft.action = TriggerAction.Nothing
      delete draft.approvedPostalId
      delete draft.approvedPostalVariantId
      delete draft.giftMessage
      delete draft.deliveryEmail
      delete draft.physicalMessage
      delete draft.meetingRequestSetting
      delete draft.emailSubjectLine
      delete draft.sendAsContactOwner
      delete draft.sendAsUser
      delete draft.itemCustomizationInputs
      delete draft.playbookDefinitionId
      delete draft.newAction
      delete draft.landingPageHeaderText
      delete draft.landingPageBody
      delete draft.landingPageIncludeHeadshot
      delete draft.landingPageIncludeSenderName
      delete draft.formFieldList
      delete draft.spendAsTeamId
      delete draft.spendAsUserId
    })
  }

  // set the newAction state to handle in callback and open the workflows
  // to set the new action item
  const handleAction = (action: TriggerAction) => {
    if (isPostal(action)) {
      setView('send')
    } else if (isPlaybook(action)) {
      setView('subscription')
    } else if (isNothing(action)) {
      handleNothingAction()
    }
    setState((draft: Record<string, any>) => {
      draft.newAction = action
    })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    // should be handled in form validation, but adding here
    // in case it slips through
    if (!state.systemName) return Alert.warning('Please select an Integration')
    if (!state.name) return Alert.warning('Name is required')
    if (provider?.trigger?.type === TriggerType.Sequence && !state.config?.sequenceId) {
      return Alert.warning('Please select a Sequence')
    }
    if (provider?.trigger?.type === TriggerType.Sequence && !state.config?.sequenceStepId) {
      return Alert.warning('Please select a Step')
    }
    if (isPlaybook(state.action) && !state.playbookDefinitionId) {
      return Alert.warning(`Please select a Subscription`)
    }
    if (isPostal(state.action) && !state.approvedPostalId) {
      return Alert.warning('Please select an Item')
    }
    if (state.sendAsType === SendAsType.User && !state.sendAsUser) {
      return Alert.warning('Please select a User to send as')
    }

    if (provider?.trigger?.type === TriggerType.Filter) {
      if (!state.filters) {
        return Alert.warning('Please select an Trigger Type')
      }
      const hasTypeFilter = state.filters?.some((f: any) => f.field === 'type')
      const hasOldFilter = state.filters?.some((f: any) => f.field.startsWith('old.'))
      const hasNewFilter = state.filters?.some((f: any) => f.field.startsWith('new.'))
      if (!hasTypeFilter || (!hasOldFilter && !hasNewFilter)) {
        return filterHelp.onOpen()
      }
    }

    const { hitCount, created, userId, userLink, ...rest } = trigger || {}

    const data = {
      ...rest,
      id: state.id || undefined,
      name: state.name,
      systemName: state.systemName,
      status: state.status || Status.Active,
      filters: state.filters,
      action: state.action,
      config: state.config,
      attribution: state.attribution,
      playbookDefinitionId: state.playbookDefinitionId,
      approvedPostalId: state.approvedPostalId,
      approvedPostalVariantId: state.approvedPostalVariantId,
      giftMessage: state.giftMessage,
      deliveryEmail: state.deliveryEmail,
      physicalMessage: state.physicalMessage,
      meetingRequestSetting: state.meetingRequestSetting,
      emailSubjectLine: state.emailSubjectLine,
      sendAsContactOwner: state.sendAsContactOwner,
      sendAsUser: state.sendAsUser,
      itemCustomizationInputs: state.itemCustomizationInputs,
      landingPageCustomization: {
        headerText: state.landingPageHeaderText,
        body: state.landingPageBody,
        includeHeadshot: state.landingPageIncludeHeadshot,
        includeSenderName: state.landingPageIncludeSenderName,
      },
      recipientEmailSettings: {
        shippedEmailsOn: state.shippedEmailsOn,
        deliveredEmailsOn: state.deliveredEmailsOn,
      },
      formFieldList: state.formFieldList,
      ...(state.spendAsUserId
        ? {
            spendAs: {
              teamId: state.spendAsTeamId,
              userId: state.spendAsUserId,
            },
          }
        : {}),
    }

    try {
      await updateTrigger.mutateAsync({ data })
      Alert.success('Trigger Saved')
      onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const { onCopy, setValue: setValueToCopy } = useClipboard('')

  const copyTriggerId = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setValueToCopy(trigger?.id ?? '')
    setTimeout(onCopy)
    Alert.success('Trigger ID copied to clipboard')
  }

  const integrationOptions = providers.map((provider) => ({
    label: provider.name,
    value: provider.system,
  }))

  const actionOptions = [
    { label: 'Send an Item', value: TriggerAction.SendPostal },
    { label: `Start a Subscription`, value: TriggerAction.StartPlaybook },
    { label: `Stop a Subscription`, value: TriggerAction.StopPlaybook },
    { label: 'Do Nothing', value: TriggerAction.Nothing },
  ]

  const onClose = () => {
    navigate(`/triggers${trigger ? '/' + trigger.id : ''}`)
  }

  return (
    <>
      <PageTitle title={trigger ? 'Edit Trigger' : 'Add Trigger'} />
      <SecondaryNavbar
        maxWidth="1280px"
        px={8}
        left={
          <NavbarBackButton
            onClick={onClose}
            label={`Back to ${trigger?.name ?? 'Triggers'}`}
          />
        }
        header={trigger ? 'Edit Trigger' : 'Add Trigger'}
      />
      <CenteredBox
        isLoaded
        maxW="500px"
        px={8}
        mt={8}
      >
        {isLoading ? (
          [...Array(6)].map((_, index) => (
            <Skeleton
              h={10}
              key={index}
              mb={3}
            />
          ))
        ) : (
          <form onSubmit={handleSubmit}>
            {provider?.trigger?.type === TriggerType.External && (
              <UiAlert status="info">
                {trigger?.id
                  ? `This trigger can be enabled in ${provider?.name}`
                  : `Once saved, this trigger can be enabled in ${provider?.name}`}
              </UiAlert>
            )}
            {trigger?.id && isMarketo && (
              <UiAlert status="info">
                <UiLink
                  float="right"
                  onClick={copyTriggerId}
                >
                  Copy Trigger ID
                </UiLink>
              </UiAlert>
            )}
            <Flex
              justifyContent="center"
              py={8}
            >
              <SimpleGrid
                columns={1}
                spacing={8}
                width="100%"
              >
                <FormControl>
                  <ZFormLabel htmlFor="name">Name</ZFormLabel>
                  <ZInput
                    id="name"
                    name="name"
                    value={state.name || ''}
                    onChange={handleInput}
                    isRequired
                  />
                </FormControl>
                <FormControl>
                  <ZFormLabel htmlFor="systemName">
                    Integration
                    {integrationTipsy && (
                      <ZInfoTooltip label="Users are only able to create triggers with our Zapier integration at this time. Please contact your Admin to access the rest of our integration library." />
                    )}
                  </ZFormLabel>
                  <RequiredSelectTypeahead
                    id="systemName"
                    onChange={handleSelectChange('systemName')}
                    options={integrationOptions}
                    placeholder="Select an Integration"
                    value={integrationOptions.find((option) => option.value === state.systemName)}
                    {...SelectTypeaheadStylesV2}
                  />
                  {state.systemName === 'sfdc' && (
                    <ZText mt={2}>
                      Triggers for Salesforce require the{' '}
                      <ZLink
                        href={appExchangeLink}
                        isExternal
                      >
                        Salesforce Managed Package
                      </ZLink>{' '}
                      to be installed in order for it to work properly.
                    </ZText>
                  )}
                </FormControl>
                {provider?.trigger?.type === TriggerType.Recipe && (
                  <TriggerEditRecipe
                    state={state}
                    setState={setState}
                  />
                )}
                {state.systemName && (
                  <FormControl>
                    <ZFormLabel htmlFor="action">Trigger Action</ZFormLabel>
                    <RequiredSelectTypeahead
                      id="action"
                      onChange={handleSelectChange('action')}
                      options={actionOptions}
                      placeholder={'Select an Action'}
                      value={actionOptions.find((option) => option.value === state.action) ?? ''}
                      {...SelectTypeaheadStylesV2}
                    />
                  </FormControl>
                )}
                {provider?.trigger?.type === TriggerType.Sequence && (
                  <TriggerEditSequence
                    state={state}
                    setState={setState}
                  />
                )}
                {provider?.trigger?.type === TriggerType.Program && (
                  <TriggerEditProgram
                    state={state}
                    setState={setState}
                  />
                )}
                {provider?.trigger?.type === TriggerType.Filter && (
                  <TriggerEditFilters
                    state={state}
                    setState={setState}
                  />
                )}
                {isPostal(state.action) && (
                  <Box>
                    <PostalActionCardV2
                      approvedPostalId={state.approvedPostalId}
                      approvedPostalVariantId={state.approvedPostalVariantId}
                      action={state.action}
                      giftMessage={state.giftMessage || ''}
                      physicalMessage={state.physicalMessage}
                      emailSubjectLine={state.emailSubjectLine}
                      sendAsContactOwner={state.sendAsContactOwner}
                      sendAsUser={state.sendAsUser}
                      onChange={(action) => {
                        setApprovedPostalId('')
                        handleAction(action)
                      }}
                      onEdit={(action) => {
                        setApprovedPostalId(state.approvedPostalId)
                        handleAction(action)
                      }}
                    />
                  </Box>
                )}
                {isPlaybook(state.action) && (
                  <Box>
                    <PlaybookActionCardV2
                      playbookDefinitionId={state.playbookDefinitionId}
                      action={state.action}
                      onChange={handleAction}
                    />
                  </Box>
                )}
              </SimpleGrid>
            </Flex>
            <ZButton
              colorScheme="atomicBlue"
              isDisabled={updateTrigger.isLoading}
              type="submit"
              width="full"
              data-testid="create-edit-trigger"
            >
              {trigger?.id ? 'Update Trigger' : 'Create Trigger'}
            </ZButton>
            <ZButton
              colorScheme="atomicGray"
              isDisabled={updateTrigger.isLoading}
              mt={2}
              onClick={onClose}
              variant="ghost"
              width="full"
            >
              Cancel
            </ZButton>
          </form>
        )}
      </CenteredBox>
      {filterHelp.isOpen && <TriggerEditFiltersHelp {...filterHelp} />}
    </>
  )
}
