import { Flex, StatNumber } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import {
  UiStat,
  UiStatDate,
  UiStatGroup,
  useAlertError,
  ZCard,
  ZCardHeader,
  ZHeading,
  ZText,
} from '@postal-io/postal-ui'
import type { User } from 'api'
import { GetUserDocument, Granularity, PostalAnalyticsDocument } from 'api'
import { sub } from 'date-fns'
import { useSession } from 'hooks'
import React, { useMemo } from 'react'

interface UserStatV2Props extends UiCardProps {
  userId?: string
}

export const UserStats: React.FC<UserStatV2Props> = ({ userId, ...rest }) => {
  const {
    session: { teamId },
  } = useSession()

  const startDate = useMemo(() => sub(new Date(), { months: 12 }), [])
  const userQuery = useGraphqlQuery(GetUserDocument, { id: userId as string })
  const analyticsQuery = useGraphqlQuery(PostalAnalyticsDocument, {
    teamId,
    granularity: Granularity.All,
    startDate,
  })

  const user = (userQuery.data?.getUser as User) || {}

  const [analytics = {}] = analyticsQuery.data?.postalAnalytics || []

  useAlertError(userQuery.error)
  useAlertError(analyticsQuery.error)

  return (
    <>
      <ZCard
        isLoading={userQuery.isLoading || analyticsQuery.isLoading}
        data-testid="statsCard"
        h="min-content"
        variant="form"
        minH="100%"
        {...rest}
      >
        <ZCardHeader p={8}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
          >
            <ZHeading size="h6">Stats</ZHeading>
          </Flex>
        </ZCardHeader>

        <UiStatGroup
          px={8}
          pb={8}
          textAlign="center"
        >
          <UiStat>
            <ZText
              color="atomicGray.500"
              size="sm"
            >
              Date Added
            </ZText>
            <UiStatDate
              data-testid="dateAdded"
              fontSize={{ base: 'xl', lg: '2xl' }}
              date={user?.created?.dateTime}
              fallback=""
            ></UiStatDate>
          </UiStat>
          <UiStat>
            <ZText
              color="atomicGray.500"
              size="sm"
            >
              Items
            </ZText>
            <StatNumber
              data-testid="touches"
              fontSize={{ base: 'xl', lg: '2xl' }}
            >
              {analytics.touches || 0}
            </StatNumber>
          </UiStat>
        </UiStatGroup>
      </ZCard>
    </>
  )
}
