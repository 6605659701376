/* eslint-disable no-template-curly-in-string */
import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { cleanText, UiToggle, ZFormLabel, ZLink, ZSelect, ZTextarea } from '@postal-io/postal-ui'
import type { RefObject } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { HUMAN_FONTS, SYSTEM_FONTS } from '../Handwriting'
import { MessagesDrawer, MessageVariablesDrawer } from '../Messages'
import { ColorSelector } from './ColorSelector'
import { DesignEditorSlider } from './DesignEditorSlider'
import type { ElementEditorProps } from './ElementEdit'
import { HorizontalList } from './HorizontalList'

const DEBOUNCE = 400
const MIN_FONT_SIZE = 7
const MAX_FONT_SIZE = 120
const MIN_SPACING = -30
const MAX_SPACING = 30

const DEFAULT_HUMAN = HUMAN_FONTS[0].code
const DEFAULT_SYSTEM = `${SYSTEM_FONTS[0].code}-${SYSTEM_FONTS[0].variants && SYSTEM_FONTS[0].variants[0].code}`

export interface TextEditProps extends ElementEditorProps {
  settings: any
  onChange: (e: any) => void
}

export const TextEdit: React.FC<TextEditProps> = ({ settings = {}, elementLocation, onChange }) => {
  const [cache, setCache] = useState(settings)
  const selectMessage = useDisclosure()
  const messageVariables = useDisclosure()

  const debouncedCallback = useDebouncedCallback(
    (name: string, value: any) => onChange({ ...settings, [name]: value }),
    DEBOUNCE
  )

  useEffect(() => {
    setCache(settings)
  }, [settings])

  const handleSelectMessage = (message: { templateText: string }) => {
    onChange({ ...settings, text: message.templateText })
    selectMessage.onClose()
  }

  const handleText = (name: string, value = '') => {
    const string = cleanText(value)
    setCache({ ...settings, [name]: string })
    debouncedCallback(name, string)
  }

  const handleDebounce = (name: string, value: number) => {
    setCache({ ...settings, [name]: value })
    debouncedCallback(name, value)
  }

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    onChange({ ...settings, [name]: checked })
  }

  const handleInput = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    onChange({ ...settings, [name]: value })
  }

  const handleColor = (color: string) => {
    onChange({ ...settings, color })
  }

  const handleHuman = ({ target: { value } }: { target: { value: string } }) => {
    const handwritingName = value === 'SYSTEM' ? DEFAULT_SYSTEM : value
    onChange({ ...settings, handwritingName, randomLeftOffset: true })
  }

  const handleSystem = ({ target: { value } }: { target: { value: string } }) => {
    const handwritingName = value === 'HUMAN' ? DEFAULT_HUMAN : value
    onChange({ ...settings, handwritingName, randomLeftOffset: false })
  }

  const messageRef = useRef() as RefObject<HTMLTextAreaElement>

  return (
    <>
      <HorizontalList>
        <Box
          mt={4}
          gridColumn="span 2"
        >
          <Flex
            justifyContent="space-between"
            mb={1}
          >
            <ZLink
              textAlign="left"
              color="atomicBlue.400"
              onClick={messageVariables.onOpen}
              fontSize="xs"
            >
              Message Variables
            </ZLink>
            <ZLink
              textAlign="right"
              color="atomicBlue.400"
              onClick={selectMessage.onOpen}
              fontSize="xs"
            >
              Saved Message
            </ZLink>
          </Flex>
          <ZTextarea
            ref={messageRef}
            gridColumn="1 / 3"
            aria-label="Text"
            id="elementText"
            name="text"
            value={cache.text}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleText('text', e.target.value)}
            minH="140px"
          />
        </Box>
        {elementLocation ? <Box gridColumn="span 2">{elementLocation}</Box> : null}
        <ZFormLabel htmlFor="handwritingFont">Handwriting Font</ZFormLabel>
        <ZSelect
          size="sm"
          name="handwritingName"
          id="handwritingFont"
          onChange={handleHuman}
          value={settings.handwritingName}
        >
          <option value="SYSTEM">-- Using System Font --</option>
          <option value="${user.handwritingName}">User Default Font</option>
          {HUMAN_FONTS.map((font) => (
            <option
              key={font.code}
              value={font.code}
            >
              {font.name}
            </option>
          ))}
        </ZSelect>
        <ZFormLabel htmlFor="systemFont">System Font</ZFormLabel>
        <ZSelect
          size="sm"
          name="handwritingName"
          id="systemFont"
          onChange={handleSystem}
          value={settings.handwritingName}
        >
          <option value="HUMAN">-- Using Handwriting Font --</option>
          {SYSTEM_FONTS.map((font) => {
            return font?.variants?.map((variant) => {
              return (
                <option
                  key={`${font.code}-${variant.code}`}
                  value={`${font.code}-${variant.code}`}
                >
                  {font.name} {variant.name}
                </option>
              )
            })
          })}
        </ZSelect>
        <ZFormLabel htmlFor="horizontalAlignment">Horizontal</ZFormLabel>
        <ZSelect
          size="sm"
          name="horizontalAlignment"
          id="horizontalAlignment"
          onChange={handleInput}
          value={settings.horizontalAlignment}
        >
          <option value="LEFT">Left</option>
          <option value="CENTER">Center</option>
          <option value="RIGHT">Right</option>
        </ZSelect>
        <ZFormLabel htmlFor="verticalAlignment">Vertical</ZFormLabel>
        <ZSelect
          size="sm"
          name="verticalAlignment"
          id="verticalAlignment"
          onChange={handleInput}
          value={settings.verticalAlignment}
        >
          <option value="TOP">Top</option>
          <option value="CENTER">Center</option>
          <option value="BOTTOM">Bottom</option>
        </ZSelect>

        <ZFormLabel htmlFor="color">Pen Color</ZFormLabel>
        <ColorSelector
          value={settings.color}
          onChange={handleColor}
        />

        <ZFormLabel htmlFor="fontSize">Size</ZFormLabel>
        <DesignEditorSlider
          cacheValue={cache.fontSize}
          value={settings.fontSize}
          onChange={(value) => handleDebounce('fontSize', value + 0.01)}
          min={MIN_FONT_SIZE}
          max={MAX_FONT_SIZE}
        />
        <ZFormLabel htmlFor="spacingModifier">Spacing</ZFormLabel>
        <DesignEditorSlider
          cacheValue={cache.spacingModifier}
          value={settings.spacingModifier}
          onChange={(value) => handleDebounce('spacingModifier', value)}
          min={MIN_SPACING}
          max={MAX_SPACING}
        />
        <Box gridColumn="span 2">
          <UiToggle
            id="randomLeftOffset"
            name="randomLeftOffset"
            isChecked={!!settings.randomLeftOffset}
            onChange={handleCheckbox}
            mb={4}
            fontSize="sm"
            fontWeight="normal"
          >
            Random Offset
          </UiToggle>

          <UiToggle
            id="sizeToFit"
            name="sizeToFit"
            isChecked={!!settings.sizeToFit}
            onChange={handleCheckbox}
            fontSize="sm"
            fontWeight="normal"
          >
            Size to Fit
          </UiToggle>
        </Box>
      </HorizontalList>
      <MessagesDrawer
        isOpen={selectMessage.isOpen}
        onClose={selectMessage.onClose}
        onSelect={handleSelectMessage}
      />
      <MessageVariablesDrawer
        isOpen={messageVariables.isOpen}
        onClose={messageVariables.onClose}
        focusRef={messageRef}
      />
    </>
  )
}
