import type { UseDisclosureReturn } from '@chakra-ui/hooks'
import {
  Box,
  List,
  ListItem,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlFetch } from '@postal-io/postal-graphql'
import type { Column, UiLinkProps } from '@postal-io/postal-ui'
import {
  joinStrings,
  UiConfirm,
  UiDate,
  UiIconMenuMore,
  UiLink,
  UiMenu,
  UiMenuButton,
  UiMenuItem,
  UiMenuList,
  UiTooltip,
  UiTruncate,
  ZHeading,
  ZText,
} from '@postal-io/postal-ui'
import type { Campaign, PostalFulfillment, SavedSend, SearchableContact, SearchableContactFilterInput } from 'api'
import { FulfillmentStatus, SavedSendType, SearchContactsV2Document, SendType } from 'api'
import { ZLink, zPopoverContentStyles, ZStatusHistory, ZStatusTag } from 'components/Common/ZComponents'
import { FETCH_LIMIT } from 'components/MultiSelectContacts/data'
import { statusMapper } from 'lib'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { OrdersTypes } from './data'
import { ORDER_SEND_MAP } from './OrdersCategory'

// Get the order type by reversing the send type map
const getOrderPath = (sendType: string) =>
  Object.entries(ORDER_SEND_MAP)
    .find(([_, value]) => value === sendType)?.[0]
    ?.toLocaleLowerCase()

const OrderLink: React.FC<UiLinkProps & { to: string }> = (props) => (
  <UiLink
    fontFamily="Lexend"
    color="atomicBlue.400"
    fontWeight="bold"
    as={RouterLink}
    data-private
    {...props}
  />
)

/**
 * Individual Orders
 */

const ALL_ORDER_COLUMNS: Column[] = [
  {
    key: 'itemName',
    label: 'Item',
    render: ({ itemName, sendType, id }: PostalFulfillment) => (
      <OrderLink to={`/orders/${getOrderPath(sendType ?? '')}/${id}`}>
        <UiTruncate
          text={itemName}
          showTooltip
        />
      </OrderLink>
    ),
    rowProps: {
      width: '25%',
      border: 'none',
    },
  },
  {
    key: 'created.dateTime',
    label: 'Date',
    // orderBy: 'created',
    render: ({ created }: PostalFulfillment) => (
      <UiDate
        fontFamily="Lexend"
        color="atomicGray.900"
        date={created?.dateTime}
      />
    ),
    rowProps: {
      width: '25%',
      border: 'none',
    },
  },
  {
    key: 'sendType',
    label: 'Type',
    render: ({ sendType }: SavedSend) => <SendTypeDisplay sendType={sendType} />,
    rowProps: {
      width: '25%',
      border: 'none',
    },
  },
  {
    key: 'contactId',
    label: 'Contact',
    render: ({ contactId, shipToName }: PostalFulfillment) => (
      <OrderLink to={`/contacts/${contactId}`}>
        <UiTruncate
          text={shipToName ?? ''}
          showTooltip
        />
      </OrderLink>
    ),
    rowProps: {
      width: '25%',
      border: 'none',
    },
  },
  {
    key: 'status',
    label: 'Status',
    render: (fulfillment: PostalFulfillment) => {
      const { color, backgroundColor, text } = statusMapper(fulfillment.status)
      return (
        <ZStatusHistory
          color={color}
          backgroundColor={backgroundColor}
          label={text}
          fulfillment={fulfillment}
        />
      )
    },
    rowProps: {
      width: '25%',
      border: 'none',
    },
  },
]

export const ALL_ORDERS_COLUMNS = ALL_ORDER_COLUMNS.filter((_) => true)
export const TYPED_ORDERS_COLUMNS = ALL_ORDER_COLUMNS.filter((col) => col.label !== 'Type')

export const ABM_CAMPAIGN_COLUMNS: Column[] = [
  {
    key: 'campaignName',
    label: 'Campaign',
    render: ({ id, name }: Campaign) => (
      <OrderLink to={`/orders/group/${id}`}>
        <UiTruncate
          text={name as string}
          showTooltip
        />
      </OrderLink>
    ),
    rowProps: {
      width: '20%',
      border: 'none',
    },
  },
  {
    key: 'recipients',
    label: 'Recipients',
    render: ({ totalContacts, id }: Campaign) => (
      <TableContactsDisplay
        contactCount={totalContacts}
        filter={{ campaigns: { eq: id } }}
      />
    ),
    rowProps: {
      width: '20%',
      border: 'none',
    },
  },
  {
    key: 'created.dateTime',
    label: 'Date Created',
    render: ({ created }: Campaign) => (
      <UiDate
        fontFamily="Lexend"
        color="atomicGray.900"
        date={created?.dateTime}
      />
    ),
    rowProps: {
      width: '20%',
      border: 'none',
    },
  },

  {
    key: 'userId',
    label: 'Created By',
    render: ({ userId, userLink }: Campaign) => (
      <OrderLink to={`/users/${userId}`}>
        <UiTruncate
          text={userLink?.fullName}
          showTooltip
        />
      </OrderLink>
    ),
    rowProps: {
      width: '20%',
      border: 'none',
    },
  },
  {
    key: 'status',
    label: 'Status',
    render: (campaign: Campaign) => {
      const { color, backgroundColor, text } = statusMapper(campaign.status)
      return (
        <ZStatusTag
          color={color}
          backgroundColor={backgroundColor}
          label={text}
        />
      )
    },
    rowProps: {
      width: '20%',
      textAlign: 'right',
      border: 'none',
    },
  },
]

export const ABM_GROUP_EMAIL_COLUMNS: Column[] = [
  {
    key: 'name',
    label: 'Name',
    render: ({ name, id, onClickItemName }: CampaignRow) => (
      <UiLink
        fontFamily="Lexend"
        color="atomicBlue.400"
        fontWeight="bold"
        onClick={() => onClickItemName(id)}
      >
        <UiTruncate
          length={50}
          text={name}
          showTooltip
        />
      </UiLink>
    ),
    rowProps: {
      width: '20%',
      border: 'none',
    },
  },
  {
    key: 'recipients',
    label: 'Recipients',
    render: ({ totalContacts }: Campaign) => totalContacts ?? 0,
    rowProps: {
      width: '20%',
      border: 'none',
    },
  },
  {
    key: 'created.dateTime',
    label: 'Date Created',
    render: ({ created }: Campaign) => (
      <UiDate
        fontFamily="Lexend"
        color="atomicGray.900"
        date={created?.dateTime}
      />
    ),
    rowProps: {
      width: '20%',
      border: 'none',
    },
  },

  {
    key: 'userId',
    label: 'Created By',
    render: ({ userId, userLink }: Campaign) => (
      <OrderLink to={`/users/${userId}`}>
        <UiTruncate
          text={userLink?.fullName}
          showTooltip
        />
      </OrderLink>
    ),
    rowProps: {
      width: '20%',
      border: 'none',
    },
  },
  {
    key: 'status',
    label: 'Status',
    render: (fulfillment: PostalFulfillment) => {
      const { text, color, backgroundColor } = statusMapper(fulfillment.status)
      return (
        <ZStatusHistory
          color={color}
          backgroundColor={backgroundColor}
          label={text}
          fulfillment={fulfillment}
        />
      )
    },
    rowProps: {
      width: '20%',
      textAlign: 'right',
      border: 'none',
    },
  },
]

/**
 * MagicLink Approvals
 */
interface OrderApproveDenyProps {
  onOrderApprove?: () => void
  onOrderDeny?: () => void

  selectedFulfillmentId: string
  setSelectedFulfillmentId: (id: string) => void

  confirmApproveOrder: UseDisclosureReturn
  confirmDenyOrder: UseDisclosureReturn
}
export const MAGICLINK_APPROVALS_COLUMNS: Column[] = [
  {
    key: 'itemName',
    label: 'Item',
    render: ({ itemName, id, onClickItemName }: PostalFulfillment & { onClickItemName: (id: string) => void }) => (
      <UiLink
        fontFamily="Lexend"
        color="atomicBlue.400"
        fontWeight="bold"
        onClick={() => onClickItemName(id)}
      >
        <UiTruncate
          text={itemName}
          showTooltip
        />
      </UiLink>
    ),
    headerProps: {
      display: 'none',
    },
    rowProps: {
      width: '28%',
      border: 'none',
    },
  },
  {
    key: 'contactId',
    label: 'Contact',
    render: ({ contactId, shipToName }: PostalFulfillment) => (
      <OrderLink to={`/contacts/${contactId}`}>
        <UiTruncate
          text={shipToName ?? ''}
          showTooltip
        />
      </OrderLink>
    ),
    headerProps: {
      display: 'none',
    },
    rowProps: {
      width: '26%',
      border: 'none',
    },
  },
  {
    key: 'shipToEmail',
    label: 'Contact',
    render: ({ shipToEmail }: PostalFulfillment) => <ZText color="atomicGray.900">{shipToEmail}</ZText>,
    headerProps: {
      display: 'none',
    },
    rowProps: {
      width: '26%',
      border: 'none',
    },
  },
  {
    key: 'magicLinkApproval',
    label: '',
    render: ({
      status,
      id,
      selectedFulfillmentId,
      setSelectedFulfillmentId,
      onOrderApprove,
      onOrderDeny,
      confirmApproveOrder,
      confirmDenyOrder,
    }: PostalFulfillment & OrderApproveDenyProps) =>
      status === FulfillmentStatus.PendingUserApproval ? (
        <>
          <UiMenu placement="bottom">
            <UiMenuButton
              data-testid="menu_button"
              variant="link"
              py={0}
            >
              <UiIconMenuMore />
            </UiMenuButton>
            <UiMenuList>
              <UiMenuItem onClick={() => void setSelectedFulfillmentId(id) || confirmApproveOrder.onOpen()}>
                Approve Order
              </UiMenuItem>
              <UiMenuItem onClick={() => void setSelectedFulfillmentId(id) || confirmDenyOrder.onOpen()}>
                Deny Order
              </UiMenuItem>
            </UiMenuList>
          </UiMenu>
          {id === selectedFulfillmentId && confirmApproveOrder.isOpen && (
            <UiConfirm
              title="Order Approval"
              isOpen={confirmApproveOrder.isOpen}
              onClose={confirmApproveOrder.onClose}
              onConfirm={onOrderApprove!}
              status="info"
              buttonText="Approve"
            >
              Approve this order?
            </UiConfirm>
          )}
          {id === selectedFulfillmentId && confirmDenyOrder.isOpen && (
            <UiConfirm
              title="Order Denial"
              isOpen={confirmDenyOrder.isOpen}
              onClose={confirmDenyOrder.onClose}
              onConfirm={onOrderDeny!}
              status="warning"
              buttonText="Deny"
            >
              Deny this order?
            </UiConfirm>
          )}
        </>
      ) : (
        <Box
          width="48px"
          height={0}
        />
      ),
    headerProps: {
      display: 'none',
    },
    rowProps: {
      width: '10%',
      border: 'none',
      textAlign: 'right',
    },
  },
]

/**
 * Drafts
 */

export const DRAFTS_COLUMNS: Column[] = [
  {
    key: 'itemName',
    label: 'Item',
    render: ({ savedSendName, id }: SavedSend) => (
      <OrderLink to={`/orders/drafts/${id}`}>
        <UiTruncate
          length={30}
          text={savedSendName || ''}
          showTooltip
        />
      </OrderLink>
    ),
    rowProps: {
      width: '28%',
      border: 'none',
    },
  },
  {
    key: 'sendType',
    label: 'Type',
    render: ({ sendType }: SavedSend) => <SendTypeDisplay sendType={sendType} />,
    rowProps: {
      width: '26%',
      border: 'none',
    },
  },
  {
    key: 'contact',
    label: 'Recipients',
    render: (savedSend: SavedSend) => <SavedSendContactsDisplay {...savedSend} />,
    rowProps: {
      width: '26%',
      border: 'none',
    },
  },
  {
    key: 'created.dateTime',
    label: 'Date',
    // orderBy: 'created',
    render: ({ created }: SavedSend) => (
      <UiDate
        fontFamily="Lexend"
        color="atomicGray.900"
        date={created?.dateTime}
      />
    ),
    rowProps: {
      width: '10%',
      border: 'none',
    },
  },
]

/**
 * Campaigns (Now called Group Orders)
 */

interface CampaignRow extends Campaign {
  onClickItemName: (id: string) => void
}
const ALL_CAMPAIGN_COLUMNS: Column[] = [
  {
    key: 'name',
    label: 'Item',
    render: ({ name, id, onClickItemName }: CampaignRow) => (
      <UiLink
        fontFamily="Lexend"
        color="atomicBlue.400"
        fontWeight="bold"
        onClick={() => onClickItemName(id)}
      >
        <UiTruncate
          length={50}
          text={name}
          showTooltip
        />
      </UiLink>
    ),
    rowProps: {
      width: '28%',
      border: 'none',
    },
  },
  // {
  //   label: 'Contacts',
  //   key: 'attemptedContacts',
  //   render: ({ scheduleDate, status }: Campaign) => {
  //     return timeTracker(scheduleDate, 30) && status === CampaignStatus.Scheduled ? (
  //       <UiTimer
  //         date={scheduleDate}
  //         fontSize="sm"
  //         color="secondary.500"
  //       />
  //     ) : (
  //       <UiDate
  //         date={scheduleDate}
  //         fallback="N/A"
  //       />
  //     )
  //   },
  //   headerProps: {
  //     display: 'none',
  //   },
  //   rowProps: {
  //     width: '28%',
  //     border: 'none',
  //   },
  // },

  {
    key: 'sendType',
    label: 'Type',
    render: ({ deliveryEmail }: Campaign) => (
      <SendTypeDisplay sendType={deliveryEmail ? SendType.GiftEmail : SendType.Direct} />
    ),
    rowProps: {
      width: '25%',
      border: 'none',
    },
  },
  {
    label: 'Contacts',
    key: 'totalContactsAttempted',
    render: (campaign: Campaign) => {
      return (
        <TableContactsDisplay
          contactCount={campaign.totalContacts}
          filter={{ campaigns: { eq: campaign.id } }}
        />
      )
    },
    rowProps: {
      width: '28%',
      border: 'none',
    },
  },
  {
    label: 'Owner',
    key: 'userLink.fullName',
    render: ({ userLink, userId }: CampaignRow) => {
      return <OrderLink to={`/users/${userId}`}>{userLink?.fullName}</OrderLink>
    },
    rowProps: {
      width: '28%',
      border: 'none',
    },
  },
  // {
  //   key: 'metrics.costInCents',
  //   label: 'Cost',
  //   render: ({ metrics }: Campaign) => <UiMoney cents={metrics?.costInCents || 0} />,
  //   headerProps: {
  //     display: 'none',
  //   },
  //   rowProps: {
  //     width: '28%',
  //     border: 'none',
  //   },
  // },

  {
    key: 'status',
    label: 'Status',
    render: ({ status }: CampaignRow) => {
      const { color, backgroundColor, text } = statusMapper(status)
      return (
        <ZStatusTag
          color={color}
          backgroundColor={backgroundColor}
          label={text}
        />
      )
    },
    rowProps: {
      width: '10%',
      border: 'none',
    },
  },
]

export const TYPED_CAMPAIGNS_COLUMNS = ALL_CAMPAIGN_COLUMNS.filter((col) => col.label !== 'Type')
export const ALL_CAMPAIGNS_COLUMNS = ALL_CAMPAIGN_COLUMNS.filter((col) => col.label !== 'Owner')

/**
 * Orders column map
 */

export const ORDERS_TYPE_COLUMN_MAP = {
  [OrdersTypes.All]: {
    columns: ALL_ORDERS_COLUMNS,
    groupColumns: ALL_CAMPAIGNS_COLUMNS,
  },
  [OrdersTypes.Drafts]: {
    columns: DRAFTS_COLUMNS,
    groupColumns: undefined,
  },
  [OrdersTypes.Emails]: {
    columns: TYPED_ORDERS_COLUMNS,
    groupColumns: TYPED_CAMPAIGNS_COLUMNS,
  },
  [OrdersTypes.Links]: {
    columns: TYPED_ORDERS_COLUMNS,
    groupColumns: undefined,
  },
  [OrdersTypes.Direct]: {
    columns: TYPED_ORDERS_COLUMNS,
    groupColumns: TYPED_CAMPAIGNS_COLUMNS,
  },
  [OrdersTypes.BulkSends]: {
    columns: TYPED_ORDERS_COLUMNS,
    groupColumns: undefined,
  },
  [OrdersTypes.Subscriptions]: {
    columns: TYPED_ORDERS_COLUMNS,
    groupColumns: undefined,
  },
  [OrdersTypes.Triggers]: {
    columns: TYPED_ORDERS_COLUMNS,
    groupColumns: undefined,
  },
  [OrdersTypes.ABM]: {
    columns: ABM_CAMPAIGN_COLUMNS,
    groupColumns: ABM_GROUP_EMAIL_COLUMNS,
  },
}

/**
 * Group Contact Display
 */

const LIMIT = 100

const TableContactsDisplay: React.FC<{ contactCount?: number | null; filter: any }> = ({ contactCount, filter }) => {
  const disclosure = useDisclosure()
  const contactQuery = useGraphqlFetch(SearchContactsV2Document)

  const [contacts, setContacts] = useState<SearchableContact[] | null>(null)

  const fetchContacts = useCallback(async () => {
    if (contacts?.length || !filter) return
    try {
      const res = await contactQuery({ filter, limit: LIMIT })
      setContacts(res.searchContactsV2.searchableContacts ?? null)
    } catch (e) {
      console.error(e.message)
    }
  }, [contactQuery, contacts?.length, filter])

  useEffect(() => {
    if ((contactCount ?? undefined) === undefined) fetchContacts()
  }, [contactCount, fetchContacts])

  const displayedContactCount = useMemo(() => {
    if ((contactCount ?? undefined) !== undefined) return contactCount
    if (contacts?.length !== undefined) return contacts.length
    return undefined
  }, [contactCount, contacts?.length])
  const recipientOrRecipients = (displayedContactCount ?? 0) === 1 ? 'Recipient' : 'Recipients'

  const formattedContactCount =
    (displayedContactCount ?? undefined) !== undefined
      ? `${new Intl.NumberFormat('en-US').format(displayedContactCount!)} ${recipientOrRecipients}`
      : 'Recipients Loading...'

  return (
    <Popover
      {...disclosure}
      isLazy
    >
      <UiTooltip
        label="View Recipients"
        isDisabled={disclosure.isOpen}
        openDelay={500}
        shouldWrapChildren
        hasArrow
      >
        <PopoverAnchor>
          <ZLink
            display="inline-block"
            color="atomicGray.500"
            fontWeight="bold"
            onClick={() => {
              fetchContacts()
              disclosure.onToggle()
            }}
          >
            {formattedContactCount}
          </ZLink>
        </PopoverAnchor>
      </UiTooltip>
      <Portal>
        <PopoverContent sx={zPopoverContentStyles}>
          <PopoverArrow />
          <PopoverCloseButton color="atomicGray.900" />
          <ZHeading
            as="h6"
            fontSize="body-lg"
            color="atomicGray.900"
            borderBottom="1px solid"
            borderBottomColor="atomicGray.200"
            mt={-2.5}
            mb={2.5}
            pb={2.5}
          >
            Recipients
          </ZHeading>
          <List
            maxH="300px"
            overflowY="scroll"
          >
            {contacts !== null ? (
              contacts?.map((c) => (
                <ListItem
                  key={c.id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py={1}
                >
                  <OrderLink to={`/contacts/${c.id}`}>
                    <UiTruncate
                      text={joinStrings([c.firstName, c.lastName])}
                      showTooltip
                    />
                  </OrderLink>
                  <ZText
                    color="atomicGray.900"
                    fontSize="body-sm"
                    data-private
                  >
                    <UiTruncate text={c.emailAddress ?? ''} />
                  </ZText>
                </ListItem>
              ))
            ) : (
              <ZText
                textAlign="center"
                color="atomicGray.900"
                lineHeight="50px"
              >
                Recipients Loading...
              </ZText>
            )}
            {contacts?.length === LIMIT && (
              <ListItem>
                <ZText
                  color="atomicGray.900"
                  textAlign="center"
                >
                  (Only the first {LIMIT} Recipients shown)
                </ZText>
              </ListItem>
            )}
          </List>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

// Layer on top of contact display for saved sends
const SavedSendContactsDisplay: React.FC<SavedSend> = ({
  sendType,
  savedSendCampaignInfo,
  savedSendMagicLinkInfo,
  savedSendBulkSendInfo,
}) => {
  const contactFilters = (
    sendType === SavedSendType.BulkSend
      ? [{ id: { in: [savedSendBulkSendInfo?.contactId] } }]
      : savedSendCampaignInfo?.contactSearchFilters
  ) as SearchableContactFilterInput[]

  const individualContactFilter = contactFilters?.find((f) => f?.id?.in)
  const filterContactFilters = contactFilters?.filter((f) => f !== individualContactFilter)

  const maxExecutions = savedSendMagicLinkInfo?.maxExecutions ?? 0

  const quantity = sendType === SavedSendType.MagicLink ? maxExecutions : contactFilters?.[0]?.id?.in?.length ?? 0
  const recipientOrRecipients = quantity === 1 ? 'Recipient' : 'Recipients'
  if (filterContactFilters?.length)
    return (
      <UiTooltip label="Many Recipients - open the draft to see the whole list">
        <ZText color="atomicGray.900">{`>${FETCH_LIMIT} Recipients`}</ZText>
      </UiTooltip>
    )
  return sendType === SavedSendType.MagicLink ? (
    <ZText color="atomicGray.900">
      Limit {new Intl.NumberFormat('en-US').format(quantity)} {recipientOrRecipients}
    </ZText>
  ) : contactFilters?.length ? (
    <TableContactsDisplay
      contactCount={quantity}
      filter={individualContactFilter}
    />
  ) : (
    <ZText color="atomicGray.900">No Recipients</ZText>
  )
}

/**
 * Send Type Display
 */

const SEND_TYPE_INFO_MAP = {
  [SendType.Direct]: { text: 'Direct', description: '' },
  [SendType.GiftEmail]: { text: 'Gift Email', description: '' },
  [SendType.MagicLink]: { text: 'MagicLink', description: '' },
  [SendType.Storefront]: { text: 'Storefont', description: '' },
  [SendType.Subscription]: { text: 'Subscription', description: '' },
  [SendType.Trigger]: { text: 'Trigger', description: '' },
  [SendType.BulkSend]: { text: 'Bulk Send', description: '' },
}

export const GENERAL_SEND_TYPE_INFO_MAP = {
  [SavedSendType.MagicLink]: SEND_TYPE_INFO_MAP[SendType.MagicLink],
  [SavedSendType.Direct]: SEND_TYPE_INFO_MAP[SendType.Direct],
  [SavedSendType.GiftEmail]: SEND_TYPE_INFO_MAP[SendType.GiftEmail],
  [SavedSendType.BulkSend]: SEND_TYPE_INFO_MAP[SavedSendType.BulkSend],
  [SendType.Direct]: SEND_TYPE_INFO_MAP[SendType.Direct],
  [SendType.GiftEmail]: SEND_TYPE_INFO_MAP[SendType.GiftEmail],
  [SendType.MagicLink]: SEND_TYPE_INFO_MAP[SendType.MagicLink],
  [SendType.Storefront]: SEND_TYPE_INFO_MAP[SendType.Storefront],
  [SendType.Subscription]: SEND_TYPE_INFO_MAP[SendType.Subscription],
  [SendType.Trigger]: SEND_TYPE_INFO_MAP[SendType.Trigger],
  [SendType.BulkSend]: SEND_TYPE_INFO_MAP[SendType.BulkSend],
}

export const SendTypeDisplay: React.FC<{ sendType: SendType | SavedSendType }> = ({ sendType }) =>
  sendType ? (
    <UiTooltip
      label={GENERAL_SEND_TYPE_INFO_MAP[sendType].description}
      openDelay={200}
    >
      <ZText
        display="inline-block"
        cursor="default"
        fontWeight="normal"
        color="atomicGray.900"
      >
        {GENERAL_SEND_TYPE_INFO_MAP[sendType].text}
      </ZText>
    </UiTooltip>
  ) : null
