import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { Column } from '@postal-io/postal-ui'
import {
  humanize,
  internalProgressBarProps,
  internalTableProps,
  UiCard,
  UiDate,
  UiSSDataTable,
  useAlertError,
  ZButton,
  ZCheckbox,
} from '@postal-io/postal-ui'
import type { Account, Role, User } from 'api'
import { GetAccountDocument, GetUserDocument, Status, TeamsDocument } from 'api'
import { MenuUserAccountUpdate } from 'components/AutoComplete/MenuUserAccountUpdate'
import { useAcl } from 'hooks'
import { getUserTeamIds } from 'lib'
import React, { useCallback, useMemo } from 'react'
import { DEFAULT_ROLES } from '../Users/UsersData'

const accountColumns: Column[] = [
  { key: 'teamName', label: 'Team Name' },
  { key: 'status', label: 'Status' },
  {
    key: 'activeDate',
    label: 'Active Since',
    render: ({ activeDate }: any) => {
      return (
        <UiDate
          date={activeDate}
          fallback="N/A"
        />
      )
    },
  },
  {
    label: 'Roles',
    render: ({ user, teamId }: any) => {
      return (
        <MenuUserAccountUpdate
          user={user}
          teamId={teamId}
        />
      )
    },
  },
]

const PRODUCT_ID = process.env.REACT_APP_PRODUCT_ID
interface UserAccountsTableV2Props extends BoxProps {
  userId?: string
  variant?: 'list' | 'default'
}
export const UserAccountsTable: React.FC<UserAccountsTableV2Props> = ({ userId, variant, ...rest }) => {
  const { hasPermission } = useAcl()
  const canUpdate = hasPermission('users.update')

  const columns = useMemo(() => {
    return canUpdate ? accountColumns : accountColumns.filter((c: any) => c.label !== 'Actions')
  }, [canUpdate])

  const userQuery = useGraphqlQuery(GetUserDocument, { id: userId as string })
  const user = useMemo(() => (userQuery.data?.getUser as User) || {}, [userQuery.data?.getUser])
  const teamIds = useMemo(() => getUserTeamIds(user), [user])

  const accountQuery = useGraphqlQuery(GetAccountDocument)
  const account = useMemo(() => (accountQuery.data?.getAccount as Account) || {}, [accountQuery.data?.getAccount])

  // get the teams associated with this user
  const teamVariables = useMemo(() => ({ filter: { id: { in: teamIds }, status: { eq: Status.Active } } }), [teamIds])
  const teamQuery = useGraphqlQuery(TeamsDocument, teamVariables, { enabled: !!teamIds.length })
  const teams = useMemo(() => teamQuery.data?.teams || [], [teamQuery.data?.teams])
  const getTeam = useCallback(
    (teamId: string | undefined | null) => (teamId ? teams?.find(({ id }) => id === teamId) : undefined),
    [teams]
  )

  const normalizedTeamData = useMemo(() => {
    const products =
      user.productAccess?.filter((access) => {
        return access.product === PRODUCT_ID && access.roles?.some((role) => DEFAULT_ROLES.includes(role as Role))
      }) || []

    return (
      products.map((product) => {
        const team = getTeam(product.teamId)
        return {
          id: product.id,
          teamId: product.teamId,
          teamName: team?.name || account.name || 'Account',
          status: humanize(team?.status || user.status),
          activeDate: product.created?.dateTime,
          user,
        }
      }) || []
    )
  }, [account.name, getTeam, user])

  const loading = userQuery.isLoading || accountQuery.isLoading || teamQuery.isLoading

  useAlertError(userQuery.error)
  useAlertError(accountQuery.error)
  useAlertError(teamQuery.error)

  if (loading && !userQuery.data) return <UiCard isLoading />

  return (
    <Box
      data-testid="UserAccountsTable"
      {...rest}
    >
      <UiSSDataTable
        variant={variant}
        isLoading={userQuery.isLoading}
        columns={columns}
        rows={normalizedTeamData}
        rowKey="id"
        SelectCheckbox={ZCheckbox}
        HeaderButton={ZButton}
        tableProps={internalTableProps}
        progressBarProps={internalProgressBarProps}
      />
    </Box>
  )
}
