import { FormControl, SimpleGrid, Stack } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import type { Disclosure } from '@postal-io/postal-ui'
import { useAlerts, ZFormLabel, ZHeading, ZInput } from '@postal-io/postal-ui'
import type { BillingAccount, BillingContactInfo, UpdateBillingAccountInput } from 'api'
import { UpdateBillingAccountDocument } from 'api'
import { AutoCompleteState } from 'components/AutoComplete'
import { AutoCompleteCountry } from 'components/AutoComplete/AutoCompleteCountry'
import { ZDialog, ZDialogButtons } from 'components/Common/ZComponents'
import type { CountryInput } from 'hooks'
import { useState } from 'react'
import { useImmer } from 'use-immer'

const emptyBillingContactInfo = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: null,
} as BillingContactInfo
interface BillingContactInfoEditProps extends Disclosure {
  billingAccount: BillingAccount
}

export const BillingContactInfoEdit: React.FC<BillingContactInfoEditProps> = ({ isOpen, onClose, billingAccount }) => {
  const Alert = useAlerts()
  const [view] = useState<'contact'>('contact')
  const [form, setForm] = useImmer<BillingContactInfo>(billingAccount?.billingContactInfo ?? emptyBillingContactInfo)

  const updateBillingAccount = useGraphqlMutation(UpdateBillingAccountDocument)

  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target

    if (name.includes('.')) {
      const [property1, property2] = name.split('.')
      setForm((draft: Record<string, any>) => void (draft[property1][property2] = value))
    } else setForm((draft: Record<string, any>) => void (draft[name] = value))
  }

  const handleStateInputChange = (state: any) => {
    setForm((draft: any) => {
      draft.address = draft.address ?? {}
      draft.address.state = state || ''
    })
  }

  const handleCountryChange = (value: CountryInput | null) => {
    setForm((draft: any) => {
      draft.address = draft.address ?? {}
      draft.address.country = value?.iso3 || ''
    })
  }

  const onSubmit = async () => {
    const data = { billingContactInfo: form } as UpdateBillingAccountInput

    try {
      await updateBillingAccount.mutateAsync({ id: billingAccount.id, data })
      Alert.success('Billing Account Contact Info Updated')
      onClose()
    } catch (e) {
      Alert.error(e)
    }
  }

  return (
    <ZDialog
      title={'Edit Billing Account Contact Info'}
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
    >
      {view === 'contact' && (
        <form
          id="accountCreate"
          onSubmit={onSubmit}
        >
          <Stack spacing={5}>
            <SimpleGrid
              columns={2}
              spacing={4}
              mb={5}
            >
              <FormControl
                id="firstName"
                isRequired
              >
                <ZFormLabel>First Name</ZFormLabel>
                <ZInput
                  name="firstName"
                  value={form.firstName ?? ''}
                  onChange={handleInput}
                />
              </FormControl>

              <FormControl
                id="lastName"
                isRequired
              >
                <ZFormLabel>Last Name</ZFormLabel>
                <ZInput
                  name="lastName"
                  value={form.lastName ?? ''}
                  onChange={handleInput}
                />
              </FormControl>

              <FormControl
                id="email"
                isRequired
              >
                <ZFormLabel>Email Address</ZFormLabel>
                <ZInput
                  name="email"
                  type="email"
                  value={form.email ?? ''}
                  onChange={handleInput}
                />
              </FormControl>

              <FormControl
                id="phone"
                isRequired
              >
                <ZFormLabel>Phone Number</ZFormLabel>
                <ZInput
                  name="phone"
                  value={form.phone ?? ''}
                  onChange={handleInput}
                />
              </FormControl>
            </SimpleGrid>

            <ZHeading
              as="h3"
              size="h6"
              my={4}
            >
              Billing Address
            </ZHeading>

            <FormControl
              id="address.line1"
              isRequired
            >
              <ZFormLabel>Address</ZFormLabel>
              <ZInput
                name="address.line1"
                value={form.address?.line1 ?? ''}
                onChange={handleInput}
              />
            </FormControl>

            <FormControl id="address.line2">
              <ZFormLabel>Address Line 2</ZFormLabel>
              <ZInput
                name="address.line2"
                value={form.address?.line2 ?? ''}
                onChange={handleInput}
              />
            </FormControl>

            <SimpleGrid
              columns={2}
              spacing={4}
            >
              <FormControl
                id="address.city"
                isRequired
              >
                <ZFormLabel>City</ZFormLabel>
                <ZInput
                  name="address.city"
                  value={form.address?.city ?? ''}
                  onChange={handleInput}
                />
              </FormControl>

              <FormControl
                id="address.state"
                isRequired
              >
                <ZFormLabel>State</ZFormLabel>
                <AutoCompleteState
                  onChange={handleStateInputChange}
                  value={form.address?.state ?? ''}
                  country={form.address?.country ?? undefined}
                />
              </FormControl>

              <FormControl
                id="address.country"
                isRequired
              >
                <ZFormLabel>Country</ZFormLabel>
                <AutoCompleteCountry
                  countryName={form.address?.country ?? undefined}
                  onChange={handleCountryChange}
                />
              </FormControl>

              <FormControl
                id="address.postalCode"
                isRequired
              >
                <ZFormLabel>Postal Code</ZFormLabel>
                <ZInput
                  name="address.postalCode"
                  value={form.address?.postalCode ?? ''}
                  onChange={handleInput}
                />
              </FormControl>
            </SimpleGrid>
          </Stack>

          <ZDialogButtons
            confirmProps={{
              type: 'submit',
            }}
            confirmText="Save"
            onClose={onClose}
            isConfirmLoading={updateBillingAccount.isLoading}
          />
        </form>
      )}
    </ZDialog>
  )
}
