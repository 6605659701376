import type { FlexProps } from '@chakra-ui/react'
import { Flex, Icon } from '@chakra-ui/react'
import { ZLogoMinimal } from '@postal-io/postal-ui'
import React from 'react'
import { SignInForm } from './SignInForm'

export const ExtSignIn: React.FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <Flex
      flexDir="column"
      minH="100vh"
      w="100%"
      {...rest}
    >
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="600px"
        mx="auto"
        w="100%"
        px={4}
        pt={24}
      >
        <Icon
          as={ZLogoMinimal}
          color="atomicGray.900"
          fontSize="235px"
          h="auto"
          mb={24}
        />
        <SignInForm />
      </Flex>
    </Flex>
  )
}
