import { Heading, Icon } from '@chakra-ui/react'
import { UiLink, ZText } from '@postal-io/postal-ui'
import React from 'react'
import { MdOutlineMarkEmailRead } from 'react-icons/md'
import { LogInHeaderBlack } from './LogInHeader'

interface ConfirmEmailProps {
  firstName?: string
  emailAddress: string
  isReset?: boolean
  resend?: () => void
}

export const ConfirmEmail: React.FC<ConfirmEmailProps> = ({ firstName, emailAddress, resend }) => (
  <LogInHeaderBlack>
    <Heading
      fontWeight={600}
      as="h1"
      size="md"
      color="white"
      mt={10}
      textAlign="center"
    >
      {firstName ? `Hi ${firstName}, please confirm your email` : `Thank you, please confirm your email`}
    </Heading>
    <Icon
      as={MdOutlineMarkEmailRead}
      color="white"
      fontSize="64px"
      my={10}
      mx="auto"
    />
    <ZText
      textAlign="center"
      color="white"
    >
      We’ve sent an email to <strong>{emailAddress}</strong>
    </ZText>
    <ZText
      py={8}
      textAlign="center"
      color="white"
    >
      Click the link in the email to confirm your address and get postal.
    </ZText>
    <ZText
      textAlign="center"
      color="white"
      fontWeight="normal"
    >
      Can’t see the email? Check your spam folder or{' '}
      <UiLink
        color="atomicBlue.400"
        onClick={resend}
        fontWeight="bold"
      >
        Resend Email
      </UiLink>
      .
    </ZText>
  </LogInHeaderBlack>
)
