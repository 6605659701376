import type { MultiSelectContactsState } from 'components/MultiSelectContacts'
import type { PostalSendMethod } from 'components/PostalSend'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

interface IContact extends Record<string, any> {
  id: string
  firstName?: string | null | undefined
  lastName?: string | null | undefined
  title?: string | null | undefined
  emailAddress?: string | null | undefined
}

interface ContactFilter extends Partial<Omit<MultiSelectContactsState, 'items'>> {
  items?: IContact[] | null | undefined
}
interface useNavigateSendFlowOptions {
  returnTo: string
  returnPath?: string
  sendMethod?: PostalSendMethod
  variantId?: string
  contactFilter?: ContactFilter
}

// shorten this since we are appending to the URL
function stringifyContactFilter(filter: ContactFilter) {
  return JSON.stringify({
    ...filter,
    items: filter.items?.map(({ id, firstName = '', lastName, title, emailAddress }) => ({
      id,
      firstName,
      lastName,
      title,
      emailAddress,
    })),
  })
}

export function useNavigateSendFlow() {
  const location = useLocation()

  return useCallback(
    (path: string, options: useNavigateSendFlowOptions) => {
      const search = new URLSearchParams()
      search.set('returnTo', options.returnTo)
      search.set('returnPath', options.returnPath ?? location.pathname)
      if (options.sendMethod) search.set('sendMethod', options.sendMethod)
      if (options.variantId) search.set('variantId', options.variantId)
      if (options.contactFilter) search.set('contactFilter', stringifyContactFilter(options.contactFilter))
      return `${path}?${search}`
    },
    [location.pathname]
  )
}

export function useNavigateSendFlowParams() {
  const [search, setSearch] = useSearchParams()

  const [state] = useState(() => {
    const method = (search.get('sendMethod') as PostalSendMethod) || undefined
    const variantId = search.get('variantId') as string
    const returnTo = search.get('returnTo') ?? 'Item'
    const returnPath = search.get('returnPath') as string
    const contactFilterString = search.get('contactFilter') as string
    const contactFilter =
      typeof contactFilterString === 'string'
        ? (JSON.parse(contactFilterString) as Partial<MultiSelectContactsState>)
        : undefined
    return { method, variantId, returnTo, returnPath, contactFilter }
  })

  useEffect(() => {
    setSearch({})
  }, [setSearch])

  return state
}
