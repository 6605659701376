import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiTextProps } from '@postal-io/postal-ui'
import { joinStrings, UiLink, UiText } from '@postal-io/postal-ui'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { GetUserDocument } from '../api'
import { SendAsType } from '../components/PostalSend'
import { useAcl } from './useAcl'

interface UseSendAsProps extends Record<string, any> {
  sendAsContactOwner?: boolean | null
  sendAsUser?: string | null
}

export const useSendAs = ({ sendAsContactOwner, sendAsUser }: UseSendAsProps) => {
  const getUser = useGraphqlQuery(GetUserDocument, { id: sendAsUser as string }, { enabled: !!sendAsUser })
  const user = useMemo(() => getUser.data?.getUser, [getUser.data?.getUser])

  const { aclCheck } = useAcl()
  const canSendAs = useMemo(() => aclCheck({ module: ['users.query', 'contacts.read'], feature: 'sendAs' }), [aclCheck])

  const type = useMemo(() => {
    if (!!sendAsUser) return SendAsType.User
    if (sendAsContactOwner) return SendAsType.ContactOwner
    return SendAsType.Self
  }, [sendAsContactOwner, sendAsUser])

  const userName = useMemo(() => {
    return joinStrings([user?.firstName, user?.lastName], undefined, 'Specific User')
  }, [user?.firstName, user?.lastName])

  const label = useMemo(() => {
    switch (type) {
      case SendAsType.Self:
        return 'Myself'
      case SendAsType.ContactOwner:
        return 'Contact Owner'
      case SendAsType.User:
        return userName
    }
  }, [type, userName])

  const isExternal = useMemo(() => type === SendAsType.ContactOwner || type === SendAsType.User, [type])

  return { canSendAs, type, user, userName, label, isExternal }
}

export const SendAsLink = ({ sendAsContactOwner, sendAsUser, children, ...rest }: UseSendAsProps & UiTextProps) => {
  const { hasPermission } = useAcl()
  const canRead = hasPermission('users.read')
  const { isExternal, user, label } = useSendAs({ sendAsContactOwner, sendAsUser })

  if (!isExternal) return null
  if (!canRead || !user?.id) {
    return (
      <UiText
        as="span"
        fontWeight="normal"
        {...rest}
      >
        {label}
      </UiText>
    )
  }

  return (
    <UiLink
      to={`/users/${user.id}`}
      as={Link}
    >
      {label}
    </UiLink>
  )
}
