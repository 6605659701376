import {
  Box,
  Flex,
  FormControl,
  Grid,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiDialogProps } from '@postal-io/postal-ui'
import {
  UiButtonScrollTop,
  UiCard,
  UiSidePanelContainer,
  UiSkeleton,
  useAlertError,
  useInfiniteScroll,
  ZInput,
} from '@postal-io/postal-ui'
import type { EitherItem } from 'components/Collections/utils'
import { SidePanelFilter } from 'components/Common/SidePanelFilter'
import { SidePanelHeader } from 'components/Common/SidePanelHeader'
import { MarketplaceCardPostal } from 'components/Marketplace/MarketplaceCardPostal'
import { CATEGORY } from 'components/Postals'
import { MARKETPLACE_CARD_MIN_WIDTH } from 'components/Postals/data'
import { AnalyticsEventV2, useAnalyticsSend, useExtension, usePostalFilters } from 'hooks'
import { usePostalFavoriteStatus } from 'hooks/usePostalFavoriteStatus'
import { cloneDeep, isEmpty, some } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import type { ApprovedPostal, ApprovedPostalFilterInput, Currency } from '../../api'
import { MagicEventStatus, SearchApprovedPostalsDocument, Status } from '../../api'
import { NoPostal } from '../Postal'
import { ApprovedPostalsEmpty } from '../Postals/ApprovedPostalsEmpty'

interface PostalSelectItemContentProps {
  category?: string
  selectedPostal?: ApprovedPostal
  onSelect?: (payload: any) => void
  onBulkSelect?: (payload: EitherItem) => void
  isBulkSelected?: (item: EitherItem) => boolean
  approvedCurrencies?: Currency[]
  isCreatingCollection?: boolean
}

const EXCLUDE_FILTERS = ['status', 'eventStatus']
const LIMIT = 20

export const PostalSelectItemContent: React.FC<PostalSelectItemContentProps> = ({
  category,
  // selectedPostal,
  onSelect,
  onBulkSelect,
  isBulkSelected,
  approvedCurrencies,
  isCreatingCollection,
}) => {
  // set initial filters
  const initialFilters = useMemo(() => ({ categories: category ? [category] : undefined }), [category])

  // filters will override these
  const defaultVariables = useMemo(() => {
    const variables: ApprovedPostalFilterInput = { status: { eq: Status.Active } }
    if (!isEmpty(approvedCurrencies)) {
      variables.currency = { in: approvedCurrencies }
    }
    if (isCreatingCollection) {
      // variables.collection = {eq: false} => does not work here as many items have item.collection === null
      variables.category = { ne: CATEGORY.Collection }
    }
    return variables
  }, [approvedCurrencies, isCreatingCollection])

  const filter = usePostalFilters<ApprovedPostalFilterInput>({
    initialFilters,
    defaultVariables,
  })

  // if we pick the event category, and only the event category,
  // then make sure we only look for accepting invites
  //
  // if we pick multiple categories, we are unable to hide these and
  // return results from the other categories
  const graphqlFilter = useMemo(() => {
    const categories = filter.graphqlFilter?.category?.in || []
    if (categories.length === 1 && categories[0] === CATEGORY.Events) {
      const newFilter = cloneDeep(filter.graphqlFilter)
      newFilter.event_status = { eq: MagicEventStatus.AcceptingInvites }
      return newFilter
    } else {
      return filter.graphqlFilter
    }
  }, [filter.graphqlFilter])

  useEffect(() => {
    if (category) filter.updateFilter('category', category)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category])

  // debounce filter to accomodate typing input
  const [debouncedFilter] = useDebounce(graphqlFilter, 400)

  // load approved postals
  const searchPostals = useGraphqlInfiniteQuery(SearchApprovedPostalsDocument, {
    filter: debouncedFilter,
    limit: LIMIT,
  })
  useAlertError(searchPostals.error)

  // we are filtering extra events in here in case they couldn't be filtered
  // in the graphql query
  const postals = useMemo(
    () =>
      searchPostals.mergedData?.searchApprovedPostals?.filter((p) => {
        // no active variants => out of stock
        if (!some(p.variants?.map((v) => v.status === Status.Active))) return false
        return !p.event?.status || p.event.status === MagicEventStatus.AcceptingInvites
      }) ?? [],
    [searchPostals.mergedData?.searchApprovedPostals]
  )

  const { postalsWithFavoriteStatus } = usePostalFavoriteStatus(postals)

  //Check to see if user has at least one postal
  const hasOneVariables = useMemo(() => {
    return {
      limit: 1,
      filter: { status: { eq: Status.Active }, currency: { in: approvedCurrencies } },
    }
  }, [approvedCurrencies])

  const hasOneQuery = useGraphqlQuery(SearchApprovedPostalsDocument, hasOneVariables)
  const hasOneItem = useMemo(
    () => !hasOneQuery.data?.searchApprovedPostals?.length,
    [hasOneQuery.data?.searchApprovedPostals?.length]
  )

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchPostals.hasNextPage,
    loadMore: searchPostals.fetchNextPage,
    loading: searchPostals.isFetching,
  })

  // const subnavFilters = omit(filter.filters, 'status')

  return (
    <>
      <Box mx={8}>
        <UiSkeleton isLoaded={!hasOneQuery.isLoading}>
          {hasOneItem ? (
            <ApprovedPostalsEmpty />
          ) : (
            <UiSidePanelContainer>
              <Box position="relative">
                <FormControl
                  id="search"
                  mb={5}
                >
                  <SidePanelHeader
                    canClear={!!filter.filters?.q}
                    onClear={() => filter.updateFilter('q', '')}
                    title="Search"
                  />
                  <InputGroup>
                    <ZInput
                      name="search"
                      placeholder="Search"
                      value={filter.filters?.q ?? ''}
                      onChange={(e: any) => filter.updateFilter('q', e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
                <SidePanelFilter
                  position="sticky"
                  top="100px"
                  filterType="ApprovedPostal"
                  filters={filter.filters}
                  onUpdate={filter.updateFilter}
                  excludeFilters={EXCLUDE_FILTERS}
                  approvedCurrencies={approvedCurrencies}
                  showDraft
                />
              </Box>
              <Flex
                direction="column"
                w="100%"
                ref={topRef}
                style={{ scrollMargin: '20px' }}
              >
                <Grid
                  templateColumns={`repeat(auto-fill, minmax(${MARKETPLACE_CARD_MIN_WIDTH}px, 1fr))`}
                  columnGap={8}
                  rowGap={4}
                >
                  {postalsWithFavoriteStatus?.map((item: any) => (
                    <MarketplaceCardPostal
                      key={item.id}
                      postal={item}
                      buttonText="Select this Item"
                      hideUtilityButtons
                      onSelect={onSelect}
                      onBulkSelect={onBulkSelect ?? undefined}
                      isBulkSelected={isBulkSelected?.(item)}
                      favoriteItemId={item.favoriteItemId}
                    />
                  ))}

                  {searchPostals.isFetching && postals.length < 1 && (
                    <UiCard
                      isLoading
                      h={MARKETPLACE_CARD_MIN_WIDTH}
                      w={MARKETPLACE_CARD_MIN_WIDTH}
                    />
                  )}
                  {!searchPostals.isFetching && postals.length < 1 && <NoPostal />}
                </Grid>
                {(searchPostals.mergedData?.searchApprovedPostals?.length ?? 0) >= LIMIT && (
                  <Box
                    ref={bottomRef}
                    mb={8}
                    position="relative"
                  >
                    <UiButtonScrollTop
                      onClick={scrollTop}
                      position="absolute"
                      top="0px"
                      right="0px"
                      isLoading={searchPostals.isFetching}
                      aria-label="scroll button"
                    />
                  </Box>
                )}
              </Flex>
            </UiSidePanelContainer>
          )}
        </UiSkeleton>
      </Box>
    </>
  )
}

export const PostalSelectItem: React.FC<Omit<PostalSelectItemContentProps & UiDialogProps, 'children' | 'title'>> = ({
  category,
  selectedPostal,
  onSelect,
  approvedCurrencies,
  ...rest
}) => {
  const sendAnalytics = useAnalyticsSend()
  const { isExtension } = useExtension()

  const handleSelectItem = useCallback(
    (postal: ApprovedPostal) => {
      if (isExtension) {
        sendAnalytics({ event: AnalyticsEventV2.ExtensionSendFlowItemSelected })
      }
      rest.onClose()
      onSelect?.(postal)
    },
    [isExtension, onSelect, rest, sendAnalytics]
  )
  return (
    <Modal
      size="cover"
      scrollBehavior="inside"
      {...rest}
    >
      <ModalOverlay />
      <ModalContent maxH="100%">
        <ModalHeader>Select an Item</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PostalSelectItemContent
            category={category}
            selectedPostal={selectedPostal}
            onSelect={handleSelectItem}
            approvedCurrencies={approvedCurrencies}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
