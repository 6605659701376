import {
  Box,
  Flex,
  Grid,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiMenu,
  UiMenuButton,
  UiMenuItem,
  UiMenuList,
  useAlertError,
  useAlerts,
  useColor,
  ZButton,
  ZCard,
  ZCardBody,
  ZCardHeader,
  ZCheckbox,
  ZHeading,
  ZLink,
  ZText,
} from '@postal-io/postal-ui'
import type { MagicLink, PostalFulfillment } from 'api'
import { DeleteMagicLinkDocument, FulfillmentStatus, GetApprovedPostalDocument, GetMagicLinkDocument, Role } from 'api'
import { CenteredBox } from 'components/Common'
import { ZBasicDialogButtons, ZCardStripe, ZCardStripeHeading, ZDialog } from 'components/Common/ZComponents'
import { ALL_ORDERS_COLUMNS } from 'components/Orders/ordersColumns'
import { BasicPostalImageCarousel } from 'components/Postal'
import { PostalSendMethod, SEND_METHOD_COLORS } from 'components/PostalSend/postalSendHelpers'
import { NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { useAcl, useCopyMagicLink, useNavigateLinkEdit, useRouteBack } from 'hooks'
import { QRCodeCanvas } from 'qrcode.react'
import React, { useMemo, useState } from 'react'
import {
  MdLink,
  MdOutlineAddToPhotos,
  MdOutlineCancel,
  MdOutlineCheckCircleOutline,
  MdOutlineChevronRight,
  MdOutlineDelete,
  MdOutlineEdit,
  MdOutlineMoreHoriz,
  MdOutlineRemoveRedEye,
  MdQrCodeScanner,
} from 'react-icons/md'
import { Link as RouteLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { PostalFulfillmentsTable } from '../PostalFulfillments'
import { LinkClone } from './LinkClone'
import { LinkDetails } from './LinkDetails'
import { LinkPreview } from './LinkPreview'
import { useNeedsApproval } from './useNeedsApproval'

interface PostalFulfillmentRow extends PostalFulfillment {
  onItemClick: () => void
  onOrderApprove?: (id: string) => void
  onOrderDeny?: (id: string) => void
}

const MAGICLINK_APPROVAL_COLUMN = {
  key: 'magicLinkApproval',
  label: '',

  headerProps: {
    display: 'none',
  },
  rowProps: {
    width: '28%',
    border: 'none',
  },
  render: ({ status, id, onOrderApprove, onOrderDeny }: PostalFulfillmentRow) =>
    status === FulfillmentStatus.PendingUserApproval ? (
      <UiMenu placement="bottom">
        <UiMenuButton
          data-testid="menu_button"
          variant="link"
          color="atomicGray.400"
        >
          <MdOutlineMoreHoriz size="20px" />
        </UiMenuButton>
        <UiMenuList borderRadius="lg">
          <UiMenuItem
            onClick={() => onOrderApprove!(id)}
            fontWeight="normal"
            color="atomicGray.900"
            icon={<MdOutlineCheckCircleOutline size="18px" />}
            py={2}
            px={4}
          >
            Approve Order
          </UiMenuItem>
          <UiMenuItem
            onClick={() => onOrderDeny!(id)}
            fontWeight="normal"
            color="atomicGray.900"
            icon={<MdOutlineCancel size="18px" />}
            py={2}
            px={4}
          >
            Deny Order
          </UiMenuItem>
        </UiMenuList>
      </UiMenu>
    ) : (
      <Box
        width="48px"
        height={0}
      />
    ),
}

export const Link: React.FC = () => {
  const { linkId } = useParams() as any
  const cloneLink = useDisclosure()
  const deleteLink = useDisclosure()
  const { Color } = useColor()
  const { hasPermission, hasFeature, hasRole } = useAcl()
  const canCreate = hasPermission('links.create')
  const canUpdate = hasPermission('links.update') && hasFeature('links')
  const canDelete = hasPermission('links.delete') && hasFeature('links')
  const canApproveLinks = hasFeature('manuallyApproveLinks') && hasRole(Role.User)
  const Alert = useAlerts()
  const navigate = useNavigate()
  const [hideDenied, setHideDenied] = useState<boolean>(true)

  const getMagicLink = useGraphqlQuery(GetMagicLinkDocument, { id: linkId })
  const deleteMagicLink = useGraphqlMutation(DeleteMagicLinkDocument)
  const link = getMagicLink.data?.getMagicLink

  const { totalNeedsAction } = useNeedsApproval(linkId)

  const magicLinkUrl = useMemo(() => {
    if (!link?.linkUrl) return ''

    return link?.linkUrl + '?qrcode=true'
  }, [link])

  const handleDelete = async () => {
    if (!link) return
    try {
      await deleteMagicLink.mutateAsync({ id: link.id })
      Alert.warning('MagicLink Deleted')
      deleteLink.onClose()
      navigate('/links')
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleHideDeniedOrders = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHideDenied(e.target.checked)
  }

  useAlertError(getMagicLink.error)

  const back = useRouteBack('MagicLinks', '/links')

  const { state }: any = useLocation()
  const isSuccess = useMemo(() => state?.isSuccess, [state])

  const onClone = (link?: MagicLink) => {
    cloneLink.onClose()
    navigate(`/links/${link?.id}`)
  }

  const copyLink = useCopyMagicLink()

  const columns = useMemo(() => {
    const cols = [...ALL_ORDERS_COLUMNS.filter((c) => c.label !== 'Type')]
    if (canApproveLinks) cols.push(MAGICLINK_APPROVAL_COLUMN)
    return cols
  }, [canApproveLinks])

  const handleBulkApprove = () => {
    navigate(`/links/approval/${link?.id}`, {
      state: {
        returnToText: 'Order',
        returnToPath: `/links/${link?.id}`,
      },
    })
  }

  const editLink = useNavigateLinkEdit()

  return (
    <>
      <SecondaryNavbar
        left={
          <NavbarBackButton
            label={back.title}
            onClick={() => (back.path ? navigate(back.path) : navigate(-1))}
          />
        }
        header={`MagicLink Info - ${link?.name ?? 'Loading...'}`}
      />
      <CenteredBox
        pt={16}
        isLoaded
      >
        <Grid
          gap={8}
          w="100%"
          justifyContent="center"
          templateColumns="max-content min-content"
        >
          {isSuccess && (
            <ZHeading
              as="h2"
              fontSize="h6"
              color={SEND_METHOD_COLORS[PostalSendMethod.Link]}
              gridColumn="1 / 3"
            >
              Success! Your MagicLink has been created.
            </ZHeading>
          )}
          <Stack spacing={8}>
            {link?.approvedPostalId && <ApprovedPostalDisplayCard approvedPostalId={link?.approvedPostalId} />}
            <LinkDetails
              w="900px"
              isInModal
              boxShadow="none"
              link={link}
            />
            <ZCard variant="form">
              <ZCardHeader
                p={8}
                pb={0}
              >
                Orders
                <Flex
                  alignItems="center"
                  gap={2}
                >
                  <ZText
                    fontSize="sm"
                    color="atomicGray.600"
                  >
                    Hide denied orders
                  </ZText>

                  <ZCheckbox
                    isChecked={hideDenied}
                    onChange={handleHideDeniedOrders}
                    colorScheme="atomicBlue"
                    mr={totalNeedsAction > 0 ? 2 : 0}
                  />

                  {canApproveLinks && totalNeedsAction > 0 ? (
                    <ZButton
                      onClick={handleBulkApprove}
                      colorScheme="atomicBlue"
                      rightIcon={<MdOutlineChevronRight size="22px" />}
                      pr={2}
                    >
                      Bulk approve orders
                    </ZButton>
                  ) : null}
                </Flex>
              </ZCardHeader>
              <ZCardBody p={8}>
                <PostalFulfillmentsTable
                  magicLinkId={link?.id}
                  showHideDenied
                  hideDenied={hideDenied}
                  columns={columns}
                  containerProps={{
                    m: 0,
                    width: 'full',
                  }}
                />
              </ZCardBody>
            </ZCard>
          </Stack>
          <Box height="100%">
            <Stack
              spacing={6}
              position="sticky"
              top="80px"
              pt={2}
            >
              <ZLink onClick={() => copyLink(link?.linkUrl)}>
                <MdLink
                  size="18px"
                  style={{ marginRight: 10, color: Color('atomicGray.500') }}
                />{' '}
                Share URL
              </ZLink>
              {canCreate && (
                <ZLink onClick={cloneLink.onOpen}>
                  <MdOutlineAddToPhotos
                    size="18px"
                    style={{ marginRight: 10, color: Color('atomicGray.500') }}
                  />{' '}
                  Clone MagicLink
                </ZLink>
              )}
              <Popover placement="left">
                <PopoverTrigger>
                  <ZLink>
                    <MdQrCodeScanner
                      size="18px"
                      style={{ marginRight: 10, color: Color('atomicGray.500') }}
                    />{' '}
                    View QR Code
                  </ZLink>
                </PopoverTrigger>
                <PopoverContent width="unset">
                  <PopoverArrow />
                  <PopoverCloseButton data-testid="ContactsImport_popover_close" />
                  <PopoverBody p={10}>
                    <QRCodeCanvas
                      value={magicLinkUrl}
                      size={150}
                      imageSettings={{
                        src: '/android-chrome-256x256.png',
                        height: 24,
                        width: 24,
                        excavate: false,
                      }}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
              {link && (
                <LinkPreview
                  display="inline-block"
                  _hover={{ textDecoration: 'none' }}
                  link={link as MagicLink}
                >
                  <ZLink>
                    <MdOutlineRemoveRedEye
                      size="18px"
                      style={{ marginRight: 10, color: Color('atomicGray.500') }}
                    />{' '}
                    Preview Landing Page
                  </ZLink>
                </LinkPreview>
              )}
              {canUpdate && (
                <ZLink
                  as={RouteLink}
                  to={editLink({ linkId: linkId as string, returnTo: 'Link' })}
                >
                  <MdOutlineEdit
                    size="18px"
                    style={{ marginRight: 10, color: Color('atomicGray.500') }}
                  />{' '}
                  Edit MagicLink
                </ZLink>
              )}
              {canDelete && (
                <ZLink onClick={deleteLink.onOpen}>
                  <MdOutlineDelete
                    size="18px"
                    style={{ marginRight: 10, color: Color('atomicGray.500') }}
                  />{' '}
                  Delete MagicLink
                </ZLink>
              )}
            </Stack>
          </Box>
        </Grid>
      </CenteredBox>

      {link && canCreate && cloneLink.isOpen && (
        <LinkClone
          link={link}
          isOpen={cloneLink.isOpen}
          onClose={cloneLink.onClose}
          onClone={onClone}
        />
      )}

      {canDelete && deleteLink.isOpen && (
        <ZDialog
          size="xl"
          title="Delete MagicLink"
          isOpen={deleteLink.isOpen}
          onClose={deleteLink.onClose}
          // isLoading={deleteMagicLink.isLoading}
        >
          Are you sure you want to <strong>Delete</strong> this MagicLink?
          <ZBasicDialogButtons
            onConfirm={handleDelete}
            onClose={deleteLink.onClose}
            confirmColorScheme="atomicRed"
            confirmText="Delete"
          />
        </ZDialog>
      )}
    </>
  )
}

interface ApprovedPostalDisplayCardProps {
  approvedPostalId: string
}
export const ApprovedPostalDisplayCard: React.FC<ApprovedPostalDisplayCardProps> = ({ approvedPostalId }) => {
  const postalQuery = useGraphqlQuery(GetApprovedPostalDocument, { id: approvedPostalId })
  const postal = useMemo(() => postalQuery?.data?.getApprovedPostal, [postalQuery?.data?.getApprovedPostal])

  return (
    <ZCard
      variant="form"
      boxShadow="none"
      px="30px !important"
      pb="25px !important"
      pt="20px !important"
      position="relative"
      isFetching={postalQuery.isLoading}
    >
      <ZCardStripe color={SEND_METHOD_COLORS[PostalSendMethod.Link]}>
        <ZCardStripeHeading>
          <MdLink style={{ marginRight: '5px', marginTop: '1px' }} /> MagicLink
        </ZCardStripeHeading>
      </ZCardStripe>
      <ZCardBody p={0}>
        <Grid
          templateColumns="1fr 4fr"
          gridGap="10px"
        >
          <Box>
            <BasicPostalImageCarousel
              postal={postal}
              imageProps={{ objectFit: 'cover', ratio: 1 }}
              containerProps={{ width: '100%', borderRadius: 5 }}
            />
          </Box>
          <Flex
            direction="column"
            justifyContent="center"
          >
            <ZText fontSize="lg">{postal?.displayName}</ZText>
            <ZText
              fontSize="sm"
              color="gray.600"
            >
              {postal?.displayName}
            </ZText>
          </Flex>
        </Grid>
      </ZCardBody>
    </ZCard>
  )
}
