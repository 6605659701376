import { Box, Collapse, Divider, Flex, HStack, Icon, Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { ZHeading, ZInput, ZText } from '@postal-io/postal-ui'
import type {
  ApprovedPostal,
  ApprovedProductVariant,
  OrderPreview,
  SearchableContact,
  SearchableContactFilterInput,
} from 'api'
import { GetMarketplaceProductDocument, SendFlowStep, Status } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { MultiSelectSelectedTable } from 'components/MultiSelectContacts/MultiSelectSelectedTable'
import { MultiSelectSelectedTags } from 'components/MultiSelectContacts/MultiSelectSelectedTags'
import type { UseMultiSelect } from 'components/MultiSelectContacts/useMultiSelect'
import { ApprovedVariantSelect } from 'components/Postals'
import type { PostalSendContext, PostalSendEventV2 } from 'components/PostalSend/usePostalSend'
import {
  DEFAULT_MAX_QUANTITY,
  getMaxBulkSendQuantity,
  getSendMethodColor,
  hasContactSelect,
  hasOrderFlexibility,
  MIN_BULK_SEND_QUANTITY,
  PostalSendMethod,
  SKIP_CONFIRM_TYPES,
  STARTING_BULK_SEND_QUANTITY,
} from 'components/PostalSend/usePostalSend'
import React, { useMemo } from 'react'
import { MdOutlineAddCircleOutline, MdOutlineRemoveCircleOutline } from 'react-icons/md'
import { PostalReviewCost } from './PostalReviewCost'
import { getMaxPriceEstimate, PostalSendSidebarCostBreakdown } from './PostalSendSidebarCostBreakdown'
import { PostalSendSidebarItemInfo } from './PostalSendSidebarItemInfo'
import { PostalSendSidebarNextStepButton } from './PostalSendSidebarNextStepButton'
import { PostalSendSidebarSpendAs } from './PostalSendSidebarSpendAs'
import { usePostalSendFieldErrors } from './usePostalSendFieldErrors'

interface PostalSendSidebarProps {
  postal?: ApprovedPostal
  sending: boolean
  errorMessage?: string
  onNext: () => void
  onDisabledNextClick?: () => void
  state?: any
  send?: any
  context?: PostalSendContext
  previewOrder?: OrderPreview
  nextStepButtonText?: string
  hasFunds?: boolean | null
  setVariant: (variant: ApprovedProductVariant) => void
  selectedVariant?: ApprovedProductVariant
  multiSelectState?: UseMultiSelect<SearchableContact, SearchableContactFilterInput>
  handleSaveDraft?: () => void
  isLoading?: boolean
}
export const PostalSendSidebar: React.FC<PostalSendSidebarProps> = ({
  postal,
  sending,
  context,
  errorMessage,
  send,
  state,
  onNext,
  onDisabledNextClick,
  previewOrder,
  nextStepButtonText,
  hasFunds,
  setVariant,
  selectedVariant,
  multiSelectState,
  handleSaveDraft,
  isLoading,
}) => {
  const showAllContacts = useDisclosure()
  const hasContacts = hasContactSelect(context)
  const brandingColor = useMemo(() => getSendMethodColor(context), [context])

  const numberOfRecipients = useMemo(
    () =>
      (context?.method === PostalSendMethod.BulkSend
        ? context?.quantity
        : hasContacts
        ? context?.contacts?.totalRecords
        : context?.maxExecutions) ?? 0,
    [hasContacts, context]
  )

  const mostExpensiveVariant = useMemo(() => {
    // exclude disabled variants
    const activeVariants = postal?.variants?.filter((v) => v.status === Status.Active) || []
    const sortedVariants = [...activeVariants].sort((a, b) =>
      getMaxPriceEstimate(a) < getMaxPriceEstimate(b) ? 1 : -1
    )
    return sortedVariants[0]
  }, [postal?.variants])

  const orderFlexibility = hasOrderFlexibility(context)

  const variantToShowPriceOf = orderFlexibility ? mostExpensiveVariant : context?.variant

  const subtotal = useMemo(() => {
    if (!variantToShowPriceOf) return 0

    const shippingPriceEstimate =
      (variantToShowPriceOf.shippingPriceEstimateMax || variantToShowPriceOf.shippingPriceEstimateMin) ?? 0

    return numberOfRecipients * (variantToShowPriceOf.displayPrice + shippingPriceEstimate)
  }, [numberOfRecipients, variantToShowPriceOf])

  // Events
  const isEvent = postal?.category === 'Events'

  const getMarketplaceProductQuery = useGraphqlQuery(
    GetMarketplaceProductDocument,
    { id: postal?.marketplaceProductId as string },
    { enabled: isEvent }
  )
  const flatFees = useMemo(
    (): number | null =>
      getMarketplaceProductQuery?.data?.getMarketplaceProduct?.variants?.find((v) => v.id === selectedVariant?.id)
        ?.flatFees ?? null,
    [getMarketplaceProductQuery?.data?.getMarketplaceProduct?.variants, selectedVariant?.id]
  )

  const nextStepLabelText = useMemo(
    // change button label
    () => {
      // change text if we're about to open the add funds modal
      if (
        state &&
        state?.nextStep === null &&
        !hasFunds &&
        !isLoading &&
        !SKIP_CONFIRM_TYPES.includes(state?.context?.type)
      )
        return 'Add Funds to confirm and Send'
      else return nextStepButtonText
    },
    [hasFunds, isLoading, nextStepButtonText, state]
  )

  return (
    <Box>
      <Box
        position="sticky"
        top="60px"
        pt={4}
        px={{ base: 4, md: 0 }}
      >
        <ZHeading
          size="h6"
          mb={5}
          pb={6}
          borderBottom="2px solid #E0E6ED"
        >
          {sending ? 'Order Summary' : 'Order'}
        </ZHeading>
        {state?.step !== SendFlowStep.OrderPreview ? (
          <>
            <PostalSendSidebarItemInfo
              context={context}
              postal={postal}
              setPostal={(postal: ApprovedPostal) => send({ type: 'SET_POSTAL', data: postal })}
              selectedVariant={selectedVariant}
              setVariant={setVariant}
            />
            {postal && !sending && (
              <ApprovedVariantSelect
                context={context}
                postal={postal}
                selectedVariant={selectedVariant}
                setVariant={setVariant}
                orderFlexibility={orderFlexibility}
              />
            )}
            {sending && context?.method !== PostalSendMethod.BulkSend && (
              <Divider
                border={1}
                borderColor="atomicGray.200"
                opacity={1}
              />
            )}
            <Collapse
              in={context && hasContacts && context.method !== PostalSendMethod.BulkSend}
              unmountOnExit
            >
              <MultiSelectSelectedTags
                multiSelectState={multiSelectState}
                hasContacts={hasContacts}
                contacts={multiSelectState?.items}
                filters={multiSelectState?.filters}
                onViewAll={showAllContacts.onOpen}
                onClearAll={multiSelectState?.clear}
                totalCount={hasContacts ? multiSelectState?.totalRecords : context?.maxExecutions}
                removeContact={multiSelectState?.removeItem}
                removeFilter={multiSelectState?.removeFilter}
                viewContact={() => void true}
                canRemoveAllContacts={
                  state?.step === SendFlowStep.ChooseMethod || state?.step === SendFlowStep.ContactSelection
                }
              />
            </Collapse>
            <Collapse
              in={context && context.method === PostalSendMethod.BulkSend}
              unmountOnExit
            >
              <BulkSendQuantitySelectorRow
                context={context}
                send={send}
              />
            </Collapse>
            {flatFees === null ? (
              <PostalSendSidebarCostBreakdown
                context={context}
                subtotal={subtotal}
                currency={postal?.currency}
                hasContacts={hasContacts}
                variantToShowPriceOf={variantToShowPriceOf}
                numberOfRecipients={numberOfRecipients}
              />
            ) : (
              <Divider
                border={1}
                borderColor="atomicGray.200"
                opacity={1}
                mb={5}
              />
            )}
          </>
        ) : (
          <PostalReviewCost
            previewOrder={previewOrder}
            method={context?.method}
            hasContacts={hasContacts}
            flatFees={flatFees}
          />
        )}
        <Box mt={4}>
          {flatFees === null && (
            <PostalSendSidebarSpendAs
              context={context}
              send={send}
              hasFunds={hasFunds}
              subtotal={subtotal}
              handleSaveDraft={handleSaveDraft}
            />
          )}
          <PostalSendSidebarNextStepButton
            context={context}
            labelText={nextStepLabelText}
            onClick={onNext}
            errorMessage={errorMessage}
            onDisabledClick={onDisabledNextClick}
            brandingColor={brandingColor}
            isLoading={isLoading}
          />
        </Box>
      </Box>
      {showAllContacts.isOpen && multiSelectState && (
        <MultiSelectSelectedTable
          title="Selected Contacts"
          orfilters={multiSelectState.orfilters}
          isOpen={showAllContacts.isOpen}
          onClose={showAllContacts.onClose}
        />
      )}
    </Box>
  )
}

interface BulkSendQuantitySelectorRowProps {
  context?: PostalSendContext
  send: (evt: PostalSendEventV2) => void
}
const BulkSendQuantitySelectorRow: React.FC<BulkSendQuantitySelectorRowProps> = ({ context, send }) => (
  <Flex
    justifyContent="space-between"
    pb={5}
    borderBottom="2px solid"
    borderBottomColor="atomicGray.200"
  >
    <Stack spacing={-1}>
      <HStack spacing={1}>
        <ZText fontWeight="bold">Quantity</ZText>
        <ZInfoTooltip
          boxSize="16px"
          label="Adjust the quantity needed for your Bulk Send. The Max is contingent on available inventory"
        />
      </HStack>
      {getMaxBulkSendQuantity(context) !== DEFAULT_MAX_QUANTITY && (
        <ZText
          color="atomicGray.500"
          fontSize="body-sm"
        >
          Max: {getMaxBulkSendQuantity(context)}
        </ZText>
      )}
    </Stack>
    <QuantitySelector
      context={context}
      value={context?.quantity ?? 0}
      setValue={(value: number | string) => send({ type: 'SET_QUANTITY', data: value })}
      minValue={MIN_BULK_SEND_QUANTITY}
      maxValue={getMaxBulkSendQuantity(context)}
    />
  </Flex>
)

interface QuantitySelectorProps {
  context?: PostalSendContext
  value: number
  setValue: (value: number | string) => void
  maxValue: number
  minValue: number
}
const QuantitySelector: React.FC<QuantitySelectorProps> = ({ context, value, setValue, minValue, maxValue }) => {
  const quantityErrorProps = usePostalSendFieldErrors({ context, field: 'quantity' })

  const disableDecrement = useMemo(() => (value ?? 0) <= minValue, [minValue, value])
  const disableIncrement = useMemo(() => (value ?? 0) >= maxValue, [maxValue, value])

  return (
    <Flex alignItems="center">
      <Icon
        as={MdOutlineRemoveCircleOutline}
        fontSize="lg"
        color={disableDecrement ? 'atomicGray.200' : 'atomicGray.400'}
        cursor={disableDecrement ? 'not-allowed' : 'pointer'}
        _hover={disableDecrement ? {} : { color: 'atomicGray.600' }}
        onClick={() => !disableDecrement && setValue((value ?? 3) - 1)}
      />
      <ZInput
        {...quantityErrorProps}
        type="number"
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
        onBlur={() => !value && setValue(STARTING_BULK_SEND_QUANTITY)}
        textAlign="right"
        fontSize="body-md"
        fontWeight="bold"
        border="none"
        height={6}
        px={0}
        mx={3}
        width={((value ?? 10) + '').length * 2}
      />
      <Icon
        as={MdOutlineAddCircleOutline}
        fontSize="lg"
        color={disableIncrement ? 'atomicGray.200' : 'atomicGray.400'}
        cursor={disableIncrement ? 'not-allowed' : 'pointer'}
        _hover={disableIncrement ? {} : { color: 'atomicGray.600' }}
        onClick={() => setValue((value ?? 4) + 1)}
      />
    </Flex>
  )
}
