import { VStack } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import {
  UiFormControl,
  useAlerts,
  ZButton,
  ZCard,
  ZCardHeader,
  ZFormLabel,
  ZInput,
  ZInputDate,
  ZSelect,
} from '@postal-io/postal-ui'
import type { Account } from 'api'
import { GetAccountDocument, QuarterCalculationMethod, UpdateAccountDocument } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { format } from 'date-fns'
import { useAcl } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'

export const AccountBudgetTimelineSettingsBlock: React.FC<UiCardProps> = (props) => {
  const Alert = useAlerts()

  const { hasFeature } = useAcl()
  const hasBudgetTimelineConfig = hasFeature('budgetTimelineAdjust')

  const getAccountQuery = useGraphqlQuery(GetAccountDocument)
  const { id } = getAccountQuery?.data?.getAccount ?? ({} as Account)
  const [method, setMethod] = useState<QuarterCalculationMethod>()
  const [configuration, setConfiguration] = useState<string | number | undefined | null>()

  useEffect(() => {
    if (getAccountQuery?.data?.getAccount?.fiscalQuarterSetup) {
      setMethod(getAccountQuery?.data?.getAccount?.fiscalQuarterSetup?.method)
      setConfiguration(getAccountQuery?.data?.getAccount?.fiscalQuarterSetup?.configuration)
    }
  }, [getAccountQuery?.data?.getAccount]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateAccount = useGraphqlMutation(UpdateAccountDocument)

  const { accountMethod, accountConfiguration } = useMemo(() => {
    return {
      accountMethod: getAccountQuery?.data?.getAccount?.fiscalQuarterSetup?.method,
      accountConfiguration: getAccountQuery?.data?.getAccount?.fiscalQuarterSetup?.configuration,
    }
  }, [getAccountQuery])

  const handleNumberChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfiguration(Number(e.target.value))
  }

  const handleSelectChange = async ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    setMethod(value as any)
    setConfiguration(undefined)
  }

  const handleDateChange = async ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDate = format(new Date(value), 'yyyy-MM-dd')
    setConfiguration(formattedDate)
  }

  const handleClickSave = async () => {
    const data = {
      fiscalQuarterSetup: {
        method,
        configuration,
      },
    }

    try {
      await updateAccount.mutateAsync({ id, data } as any)
      Alert.success('Budget Timeline Settings Updated')
    } catch (e) {
      Alert.error(e)
    }
  }

  if (!hasBudgetTimelineConfig) return null

  return (
    <ZCard
      pos="relative"
      variant="form"
      {...props}
    >
      <ZCardHeader
        p={8}
        pb={0}
        justifyContent="flex-start"
        alignItems="center"
        gap={2}
      >
        Budget Timeline
        <ZInfoTooltip label="Changing this setting will adjust the start and end dates of budget periods." />
      </ZCardHeader>

      <form>
        <VStack
          spacing={8}
          p={8}
        >
          <UiFormControl>
            <ZFormLabel
              htmlFor="budgetTimelineConfigMethod"
              fontSize="md"
            >
              Method
            </ZFormLabel>
            <ZSelect
              id="budgetTimelineConfigMethod"
              onChange={handleSelectChange}
              name="method"
              value={method}
            >
              <option value={QuarterCalculationMethod.Calendar as string}>Calendar (default)</option>
              <option value={QuarterCalculationMethod.OffsetMonths as string}>Offset by Months</option>
              <option value={QuarterCalculationMethod.Block_90Days as string}>90-Day Quarters</option>
            </ZSelect>
          </UiFormControl>
          {method !== QuarterCalculationMethod.Calendar && (
            <UiFormControl>
              <ConfigurationInput
                method={method!}
                handleNumberChange={handleNumberChange}
                handleDateChange={handleDateChange}
                configuration={configuration}
              />
            </UiFormControl>
          )}

          {(accountMethod !== method || accountConfiguration !== configuration) && (
            <ZButton
              size="md"
              colorScheme="blue"
              alignSelf="flex-end"
              disabled={updateAccount.isLoading || (method !== QuarterCalculationMethod.Calendar && !configuration)}
              isLoading={updateAccount.isLoading}
              onClick={handleClickSave}
              px={8}
            >
              Save
            </ZButton>
          )}
        </VStack>
      </form>
    </ZCard>
  )
}

interface ConfigurationInputProps {
  method: QuarterCalculationMethod
  handleNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleDateChange: (a: any) => void
  configuration: string | number | null | undefined
}

const ConfigurationInput: React.FC<ConfigurationInputProps> = ({
  method,
  handleNumberChange,
  handleDateChange,
  configuration,
}) => {
  switch (method) {
    case QuarterCalculationMethod.OffsetMonths:
      return (
        <>
          <ZFormLabel
            htmlFor="budgetTimelineConfigMethod"
            gap={2}
            display="flex"
            alignItems="center"
          >
            Configuration
            <ZInfoTooltip label="Enter the number of months the start of your company's fiscal year is offset from the start of the calendar year. (e.g. if your company's fiscal year begins Feb. 1st, then put '1' here)" />
          </ZFormLabel>
          <ZInput
            type="number"
            value={Number(configuration || 0)}
            name="configuration"
            onChange={handleNumberChange}
          />
        </>
      )
    case QuarterCalculationMethod.Block_90Days:
      return (
        <>
          <ZFormLabel
            htmlFor="budgetTimelineConfigMethod"
            gap={2}
            display="flex"
            alignItems="center"
            fontSize="md"
          >
            Configuration
            <ZInfoTooltip label="Enter the start of Q1 this year (the start date of the fiscal year that begins this current calendar year)." />
          </ZFormLabel>
          <ZInputDate
            placeholder="Enter the start day of Q1 this year."
            value={configuration}
            name="configuration"
            onChange={handleDateChange}
          />
        </>
      )
    case QuarterCalculationMethod.Calendar:
    default:
      return null
  }
}
