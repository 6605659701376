import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, FormControl, IconButton, InputGroup, List, Spinner, Text } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButtonScrollTop,
  UiTruncate,
  useAlertError,
  useInfiniteScroll,
  ZInput,
  ZInputLeftIcon,
  ZText,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, SavedSend } from 'api'
import { SearchApprovedPostalsDocument } from 'api'
import { useDrafts } from 'components/Orders/hooks'
import { useAssets, useExtension } from 'hooks'
import React, { useMemo, useState } from 'react'
import { MdKeyboardArrowLeft, MdOpenInNew, MdOutlineInventory2, MdOutlineSearch } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ExtMarketplaceTextPriceRange } from '../Common/ExtMarketplaceTextPriceRange'
import { ExtHeader } from '../Main/ExtHeader'
import { ExtDraftSend } from './ExtDraftSend'

interface TypeApprovedPostalItem extends ApprovedPostal {
  previewImageSrc: string
  previewImageFallback: string | undefined
}
type TypeApprovedPostalsMap = {
  [key: string]: TypeApprovedPostalItem
}

const LIMIT = 10

export const ExtDrafts: React.FC<BoxProps> = (props) => {
  const navigate = useNavigate()
  const { contactId } = useExtension()
  const { assetUrlSrc } = useAssets()

  const [openDraftData, setOpenDraftData] = useState<{ draft: SavedSend; approvedPostal: ApprovedPostal } | undefined>()
  const [savedSendsName, setSavedSendsName] = useState<string>('')

  const {
    data: drafts,
    hasNextPage,
    fetchNextPage,
    isFetching,
    error,
  } = useDrafts({
    limit: LIMIT,
    filter: {
      savedSendName: { contains: savedSendsName ?? undefined },
      savedSendCampaignInfo_contactCount: { eq: 1 },
    },
  })
  useAlertError(error)

  const arrPostalIds = drafts?.map((draft) => draft.commonSendProperties.approvedPostalId) || []

  const { data } = useGraphqlQuery(
    SearchApprovedPostalsDocument,
    {
      filter: { id: { in: arrPostalIds } },
    },
    { enabled: !!arrPostalIds.length }
  )

  const approvedPostals: TypeApprovedPostalsMap = useMemo(
    () =>
      data?.searchApprovedPostals?.reduce((total, approvedPostal) => {
        const { src, fallbackSrc } = assetUrlSrc(approvedPostal.imageUrls?.[0]?.url, {
          width: 55,
          height: 55,
          fit: 'clip',
        })
        return {
          ...total,
          [approvedPostal.id]: {
            previewImageSrc: src,
            previewImageFallback: fallbackSrc,
            ...approvedPostal,
          },
        }
      }, {}) || {},
    [assetUrlSrc, data]
  )

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: hasNextPage,
    loadMore: fetchNextPage,
    loading: isFetching,
  })

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => setSavedSendsName(e.target.value)

  const handleBack = () => {
    if (contactId) {
      navigate(`/extension/contacts/${contactId}`)
    } else {
      navigate(`/extension/`)
    }
  }

  const handleExit = () => {
    setOpenDraftData(undefined)
    navigate('/extension/drafts')
  }

  return (
    <>
      {!!openDraftData ? (
        <ExtDraftSend
          draft={openDraftData?.draft}
          approvedPostal={openDraftData?.approvedPostal}
          onExit={handleExit}
        />
      ) : (
        <Box
          p={4}
          {...props}
        >
          <ExtHeader>
            <Flex
              alignItems="center"
              columnGap="10px"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="30px"
                height="30px"
                bg="atomicGray.50"
                borderRadius="50%"
                _hover={{ bg: 'atomicGray.100', cursor: 'pointer' }}
                onClick={handleBack}
              >
                <MdKeyboardArrowLeft
                  size="16px"
                  color="#8492A6"
                />
              </Box>
              <ZText size="lg">Drafts</ZText>
            </Flex>
          </ExtHeader>
          <FormControl
            id="savedSendsName"
            my={4}
          >
            <InputGroup>
              <ZInputLeftIcon icon={<MdOutlineSearch size="16px" />} />
              <ZInput
                placeholder="Search"
                name="savedSendsName"
                value={savedSendsName}
                onChange={handleSearchInput}
              />
            </InputGroup>
          </FormControl>

          <List ref={topRef}>
            {isFetching && drafts.length < 1 && (
              <Box
                textAlign="center"
                mt={4}
              >
                <Spinner sx={{ margin: '0 auto' }} />
              </Box>
            )}
            {!isFetching && drafts.length < 1 && <Text>No results</Text>}
            {drafts.map((draft) => {
              const approvedPostal =
                draft.commonSendProperties.approvedPostalId in approvedPostals
                  ? approvedPostals[draft.commonSendProperties.approvedPostalId]
                  : null

              return !approvedPostal ? null : (
                <Flex
                  key={draft.id}
                  justifyContent="space-between"
                  alignItems="center"
                  mb={4}
                >
                  <Flex columnGap={4}>
                    {approvedPostal?.previewImageSrc ? (
                      <Box
                        backgroundImage={approvedPostal.previewImageSrc}
                        backgroundPosition="center"
                        backgroundSize="cover"
                        width="55px"
                        height="55px"
                        borderWidth={1}
                        borderStyle="solid"
                        borderColor="atomicGray.100"
                        borderRadius="5px"
                        onClick={() => setOpenDraftData({ draft, approvedPostal })}
                        _hover={{ cursor: 'pointer' }}
                      />
                    ) : (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="55px"
                        height="55px"
                        borderWidth={1}
                        borderStyle="solid"
                        borderColor="atomicGray.100"
                        borderRadius="5px"
                        onClick={() => setOpenDraftData({ draft, approvedPostal })}
                        _hover={{ cursor: 'pointer' }}
                      >
                        <MdOutlineInventory2 size="25px" />
                      </Box>
                    )}

                    <Box>
                      <ZText
                        size="md"
                        color="atomicGray.700"
                        onClick={() => setOpenDraftData({ draft, approvedPostal })}
                        _hover={{ cursor: 'pointer' }}
                      >
                        <UiTruncate
                          text={draft.savedSendName}
                          length={35}
                        />
                      </ZText>
                      <ZText
                        size="sm"
                        color="atomicGray.500"
                      >
                        {approvedPostal?.name} - {approvedPostal?.brandName}
                      </ZText>
                      <ExtMarketplaceTextPriceRange item={approvedPostal} />
                    </Box>
                  </Flex>
                  <Flex
                    alignItems="center"
                    columnGap={5}
                    color="atomicGray.500"
                  >
                    <IconButton
                      colorScheme="atomicGray"
                      variant="ghost"
                      aria-label="Open Draft"
                      icon={<MdOpenInNew size="16px" />}
                      onClick={() => setOpenDraftData({ draft, approvedPostal })}
                    />
                  </Flex>
                </Flex>
              )
            })}
            {drafts.length >= LIMIT && (
              <Box
                ref={bottomRef}
                mb={8}
                position="relative"
              >
                <UiButtonScrollTop
                  onClick={scrollTop}
                  position="absolute"
                  top="0px"
                  right="0px"
                  isLoading={isFetching}
                  aria-label="scroll button"
                />
              </Box>
            )}
          </List>
        </Box>
      )}
    </>
  )
}
