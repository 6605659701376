import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Grid, HStack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import {
  UiButtonScrollTop,
  UiSidePanelContainer,
  UiTooltip,
  useAlertError,
  useInfiniteScroll,
  ZButton,
  ZProductCard,
} from '@postal-io/postal-ui'
import type { MarketplaceProduct } from 'api'
import { DefaultOrderByInput, Role, SearchMarketplaceProductsDocument } from 'api'
import { useMaxCollectionItems } from 'components/Collections'
import { BulkSelectActionBar, BulkSelectNavbar } from 'components/Common'
import { SidePanelFilter } from 'components/Common/SidePanelFilter'
import { SubnavFilters } from 'components/Common/SubnavFilters'
import { AnalyticsEvent, useAcl, useAnalyticsEvent, useAnalyticsSend, useMe } from 'hooks'
import { useBulkSelect } from 'hooks/useBulkSelect'
import { useNavbarOverride } from 'hooks/useNavbarOverride'
import { cloneDeep, omit, pick, some } from 'lodash'
import React, { useContext, useEffect, useMemo } from 'react'
import { MdAddCircleOutline, MdOutlineEdit } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useKey } from 'react-use'
import { NoPostal } from '../Postal'
import { ConciergeRequestCard, ProductRequestCard } from '../Postal/PostalCard'
import {
  ITEM_CATEGORY_EXCLUSIONS,
  MARKETPLACE_CARD_MIN_WIDTH,
  MARKETPLACE_PRODUCT_FILTER_INPUT_KEYS,
} from '../Postals/data'
import { DesignTemplates } from '../Postals/DesignTemplates'
import { MarketplaceProductRequest } from '../Postals/MarketplaceProductRequest'
import { MarketplaceBulkEdit } from './MarketplaceBulkEdit'
import { OldMarketplaceCardProduct } from './MarketplaceCardProduct'
import { MarketplaceContext } from './useMarketplace'

const LIMIT = 20

const PRODUCT_REQUEST_CARD_LIMIT = 5

export type MarketplaceSelectedItem = MarketplaceProduct & { isSelected?: boolean }

export const OldMarketplaceViewProducts: React.FC<BoxProps> = () => {
  const navigate = useNavigate()
  const sendAnalytics = useAnalyticsSend()

  const { hasPermission, aclCheck, hasFeature, hasRole } = useAcl()

  const canCreate = hasPermission('postals.create')
  const canCreateCollection = canCreate || (hasFeature('userCreatedCollections') && hasRole(Role.User))
  const hasMarketplace = aclCheck({ module: 'postals.marketplace' })
  const hasCollectionsV2 = hasFeature('collectionsV2')

  // for semantics
  const canBulkSelect = canCreate || canCreateCollection

  const designTemplates = useDisclosure()
  const bulkApproveDisclosure = useDisclosure()
  const actionBarDisclosure = useDisclosure()
  const productRequestDisclosure = useDisclosure()

  const { approvedCurrencies } = useMe()

  const { handleBulkSelect, isBulkSelected, handleCancelBulkSelect, bulkSelected, setBulkSelected } =
    useBulkSelect<MarketplaceProduct>()

  const multiCurrencySelection = useMemo(
    () => some(bulkSelected, (item) => item.currency !== bulkSelected[0].currency),
    [bulkSelected]
  )

  // CONCIERGE

  const goToConcierge = () => navigate('/paperplane')

  // MARKETPLACE PRODUCTS

  const { state, setNumResults, marketplaceFilters } = useContext(MarketplaceContext)
  const { filters, graphqlFilter, updateFilter, clearFilters } = marketplaceFilters

  // don't show status (taken from Postals but here too)
  const subnavFilters = omit(cloneDeep(filters), ['status', 'teamIds', 'outOfStock', 'draft'])

  const filter = useMemo(() => pick(graphqlFilter, MARKETPLACE_PRODUCT_FILTER_INPUT_KEYS), [graphqlFilter])

  const searchProducts = useGraphqlInfiniteQuery(
    SearchMarketplaceProductsDocument,
    {
      filter,
      limit: LIMIT,
      // newest items first
      orderBy: DefaultOrderByInput.CreatedDesc,
    }
    // { keepPreviousData: true }
  )
  useAlertError(searchProducts.error)

  const products = useMemo(
    () => searchProducts.mergedData?.searchMarketplaceProducts || [],
    [searchProducts.mergedData?.searchMarketplaceProducts]
  )

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchProducts.hasNextPage,
    loadMore: searchProducts.fetchNextPage,
    loading: searchProducts.isFetching,
  })

  const handleSelectProduct = (product: MarketplaceProduct) => {
    sendAnalytics({
      event: AnalyticsEvent.MarketplaceViewItemClicked,
      data: { marketplaceProductId: product.id, itemName: product.name },
    })
    navigate(`/items/marketplace/${product.id}`, { state: { returnTo: 'All Items' } })
  }

  // open action bar when products are selected in bulk
  useEffect(() => {
    if (bulkSelected.length > 0) actionBarDisclosure.onOpen()
    else actionBarDisclosure.onClose()
    return () => actionBarDisclosure.onClose()
  }, [actionBarDisclosure, bulkSelected.length])

  // deselect all products
  useKey('Escape', handleCancelBulkSelect)

  // ANALYTICS

  useAnalyticsEvent({ event: AnalyticsEvent.MarketplaceAllItemsPageViewed })
  useAnalyticsEvent({ event: AnalyticsEvent.MarketplaceFilterSelected, data: { filter } })

  useEffect(() => {
    // for products we want to use placeholder text if filters are empty; set to null
    setNumResults?.(state.numFilters > 0 ? products.length : null)
  }, [filters, products.length, setNumResults, state.numFilters])

  // const isPlaceholderData = searchProducts.isPlaceholderData
  const isFetching = searchProducts.isFetching

  const showConciergeCard = !searchProducts.isLoading && canCreate && hasMarketplace
  const showProductRequestCard = showConciergeCard && products.length < PRODUCT_REQUEST_CARD_LIMIT

  const handleClickProductRequest = () => {
    window.open('https://www.postal.com/recommend-a-product', '_blank')
  }

  const MAX_COLLECTION_ITEMS = useMaxCollectionItems()
  const collectionCreateError = useMemo(() => {
    if (multiCurrencySelection) return 'Multiple currencies selected. Only one currency is allowed per collection.'
    if (bulkSelected.length > MAX_COLLECTION_ITEMS)
      return `Cannot create a Collection with over ${MAX_COLLECTION_ITEMS} items.`
  }, [MAX_COLLECTION_ITEMS, bulkSelected.length, multiCurrencySelection])

  useNavbarOverride(
    hasCollectionsV2 && !!bulkSelected.length && (
      <BulkSelectNavbar
        isEverythingSelected={bulkSelected.length === products.length}
        selectedItems={bulkSelected}
        onSelect={handleBulkSelect}
        onSelectAll={() => setBulkSelected(products)}
        onDeselectAll={handleCancelBulkSelect}
      >
        <>
          {canCreate && (
            <ZButton
              colorScheme="atomicBlue"
              onClick={bulkApproveDisclosure.onOpen}
              isDisabled={bulkSelected.length < 1}
              autoFocus
              leftIcon={<MdOutlineEdit fontSize="18px" />}
            >
              Approve
            </ZButton>
          )}
          {canCreateCollection && (
            <UiTooltip
              label={collectionCreateError}
              shouldWrapChildren
            >
              <ZButton
                variant="naked"
                color="white"
                _hover={{ color: '#FFFC' }}
                px={0}
                onClick={() => {
                  navigate('/collections/create', { state: { items: bulkSelected, returnTo: 'Marketplace' } })
                }}
                isDisabled={!!collectionCreateError}
                leftIcon={<MdAddCircleOutline fontSize="18px" />}
              >
                Create Collection
              </ZButton>
            </UiTooltip>
          )}
        </>
      </BulkSelectNavbar>
    ),
    [bulkSelected, setBulkSelected, products, multiCurrencySelection, collectionCreateError]
  )

  return (
    <>
      <SubnavFilters
        data-testid="Marketplace_SubnavFilters"
        filters={subnavFilters}
        onUpdate={updateFilter}
        onClear={clearFilters}
        display={!!state?.numFilters ? 'none' : undefined}
      />
      <UiSidePanelContainer gridGap={16}>
        <SidePanelFilter
          filters={filters}
          onUpdate={updateFilter}
          filterType="Marketplace"
          approvedCurrencies={approvedCurrencies}
          excludeCategories={ITEM_CATEGORY_EXCLUSIONS}
          excludeFilters={['outOfStock']}
        />
        <Flex
          direction="column"
          w="100%"
          ref={topRef}
          style={{ scrollMargin: '20px' }}
        >
          <Grid
            templateColumns={`repeat(auto-fill, minmax(${MARKETPLACE_CARD_MIN_WIDTH}px, 1fr))`}
            gap={4}
          >
            {!isFetching && products.length < 1 && !showConciergeCard && <NoPostal />}
            {showProductRequestCard && <ProductRequestCard handleClick={handleClickProductRequest} />}
            {showConciergeCard && <ConciergeRequestCard handleClick={goToConcierge} />}
            {products.map((item: any) => (
              <OldMarketplaceCardProduct
                key={item.id}
                product={item}
                isBulkSelected={isBulkSelected(item)}
                onSelect={handleSelectProduct}
                onBulkSelect={canBulkSelect ? handleBulkSelect : undefined}
                truncateLength={33}
              />
            ))}

            {isFetching && products.length < 1 && (
              <ZProductCard
                isLoading
                h={MARKETPLACE_CARD_MIN_WIDTH}
                w={MARKETPLACE_CARD_MIN_WIDTH}
                startColor="atomicGray.50"
                endColor="atomicGray.200"
              />
            )}
          </Grid>
          {products.length >= LIMIT && (
            <Box
              ref={bottomRef}
              mb={8}
              position="relative"
            >
              <UiButtonScrollTop
                onClick={scrollTop}
                position="absolute"
                top="0px"
                right="0px"
                isLoading={searchProducts.isFetching}
                aria-label="scroll-up"
              />
            </Box>
          )}
        </Flex>
      </UiSidePanelContainer>
      {!hasCollectionsV2 && (
        <BulkSelectActionBar
          isOpen={actionBarDisclosure.isOpen}
          selectedLength={bulkSelected.length}
          selectedItemsText={`(${bulkSelected.length} Item${bulkSelected.length !== 1 ? 's' : ''} selected)`}
          isEverythingSelected={bulkSelected.length === products.length}
          onSelectAll={() => setBulkSelected(products)}
          onDeselectAll={handleCancelBulkSelect}
        >
          <HStack spacing={2}>
            <ZButton
              minWidth="175px"
              colorScheme="atomicBlue"
              onClick={bulkApproveDisclosure.onOpen}
              isDisabled={bulkSelected.length < 1}
              autoFocus
            >
              Approve
            </ZButton>
            <ZButton
              minWidth="175px"
              variant="subtle"
              colorScheme="atomicGray"
              onClick={handleCancelBulkSelect}
            >
              Cancel
            </ZButton>
          </HStack>
        </BulkSelectActionBar>
      )}
      {bulkApproveDisclosure.isOpen && (
        <MarketplaceBulkEdit
          type="Approve"
          items={bulkSelected}
          isOpen={bulkApproveDisclosure.isOpen}
          onClose={bulkApproveDisclosure.onClose}
          onSuccess={handleCancelBulkSelect}
        />
      )}
      {/* TODO: whereto henceforth */}
      <DesignTemplates
        isOpen={designTemplates.isOpen}
        onClose={designTemplates.onClose}
      />
      <MarketplaceProductRequest {...productRequestDisclosure} />
    </>
  )
}
