import { Box, Center, Checkbox, Flex, Text } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { UiButton, UiDialog, useAlerts } from '@postal-io/postal-ui'
import type { SearchableContactFilterInput, Tag } from 'api'
import { BulkTagAddDocument, BulkTagRemoveDocument } from 'api'
import { CONTACT_INVALIDATIONS, useBackgroundQueue } from 'hooks'
import React, { useState } from 'react'
import { AutoCompleteTagsCreatable } from '../AutoComplete'

interface ContactsBulkTagV3Props {
  isOpen: boolean
  onClose: () => void
  onComplete: () => void
  filters: SearchableContactFilterInput[]
  count: number
}

export const ContactsBulkTagV2: React.FC<ContactsBulkTagV3Props> = ({
  isOpen,
  onClose,
  onComplete,
  filters,
  count,
}) => {
  const [selected, setSelected] = useState<Tag[]>([])
  const [action, setAction] = useState<'add' | 'remove'>('add')
  const Alert = useAlerts()

  // tags
  const { queue, invalidate } = useBackgroundQueue()

  // mutations
  const bulkTagAdd = useGraphqlMutation(BulkTagAddDocument)
  const bulkTagRemove = useGraphqlMutation(BulkTagRemoveDocument)

  const addTags = () => {
    const promises = selected.map((tag) =>
      bulkTagAdd.mutateAsync({ data: { name: tag.name }, orfilters: filters }).then((data) => queue(data.bulkTagAdd))
    )
    return Promise.all(promises).finally(() => invalidate(CONTACT_INVALIDATIONS))
  }

  const removeTags = () => {
    const promises = selected.map((tag) =>
      bulkTagRemove
        .mutateAsync({ data: { name: tag.name }, orfilters: filters })
        .then((data) => queue(data.bulkTagRemove))
    )
    return Promise.all(promises).finally(() => invalidate(CONTACT_INVALIDATIONS))
  }

  const contactWord = count > 1 ? 'Contacts' : 'Contact'

  const handleAction = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setAction(checked ? 'remove' : 'add')
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (selected.length < 1) return Alert.warning('Please select at least one tag')
    try {
      action === 'add' ? await addTags() : await removeTags()
      Alert.success(`Tags updated for ${count} ${contactWord}`)
    } catch (err) {
      Alert.error(err)
    } finally {
      onComplete()
      onClose()
    }
  }

  return (
    <UiDialog
      status="info"
      title={`Update Tags for ${count} ${contactWord}`}
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
    >
      <Box>
        <Text
          mb={4}
          textAlign="center"
        >
          Please select the <strong>tags</strong> to update on each Contact
        </Text>
        <form onSubmit={handleSubmit}>
          <Center>
            <Checkbox
              size="md"
              name="sendNotification"
              id="sendNotification"
              isChecked={action === 'remove'}
              onChange={handleAction}
            >
              Remove these tags
            </Checkbox>
          </Center>
          <Flex
            alignItems="bottom"
            justifyContent="center"
            flexWrap="wrap"
            mt={4}
            mb={-4}
          >
            <AutoCompleteTagsCreatable
              rootProps={{ ml: 4, mb: 4, minW: '300px' }}
              isDisabled={bulkTagAdd.isLoading}
              value={selected}
              onChange={(tags) => setSelected([...tags])}
              onCreate={(tag) => setSelected((selected) => [...selected, tag])}
            />
            <UiButton
              ml={4}
              mb={4}
              type="submit"
              isDisabled={bulkTagAdd.isLoading || bulkTagRemove.isLoading}
            >
              Update Tags
            </UiButton>
          </Flex>
        </form>
      </Box>
    </UiDialog>
  )
}
