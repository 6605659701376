import { Box, chakra, Fade, Flex, IconButton, useBreakpointValue } from '@chakra-ui/react'
import type { ZProductCardProps } from '@postal-io/postal-ui'
import {
  UiEnhancedTooltip,
  UiScaleEffect,
  UiTruncate,
  ZButton,
  ZProductCard,
  ZProductCardBody,
  ZProductCardContent,
  ZProductCardImage,
  ZProductCardImageContainer,
  ZProductCardImageHover,
  ZProductCardImageIconFavorite,
  ZProductCardImageIcons,
  ZProductCardImageIconSelect,
} from '@postal-io/postal-ui'
import { groupCollectionItems } from 'components/Collections/utils'
import { ZLink, ZStatusTag } from 'components/Common/ZComponents'
import { PostalSendMethod } from 'components/PostalSend/postalSendHelpers'
import { usePostalFavoriteStatusToggle } from 'hooks/useFavoriteStatusToggle'
import React, { useCallback, useMemo } from 'react'
import { IconContext } from 'react-icons'
import { MdLink, MdOutlineSettings, MdSend } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import type { ApprovedPostal } from '../../api/index'
import { Role, Status } from '../../api/index'
import { AnalyticsEvent, useAcl, useAssets, useNavigateSendFlow } from '../../hooks'
import { useAnalyticsSend } from '../../hooks/useAnalytics'
import { useExtension } from '../../hooks/useExtension'
import { usePostalImages } from '../../hooks/usePostalImages'
import { usePostalPermissions } from '../../hooks/usePostalPermissions'
import { MarketplaceApprovedPostalPriceRange } from './MarketplaceTextPriceRange'

const StatusTags = {
  draft: {
    label: 'Draft',
    color: 'atomicBlue.900',
    bg: 'atomicBlue.10',
  },
  outOfStock: {
    label: 'Out of Stock',
    color: 'atomicGray.900',
    bg: 'atomicGray.10',
  },
}

interface HoverElementProps {
  postal: ApprovedPostal
  onSelect: () => void
  hideUtilityButtons?: boolean
  buttonText?: string
}

const HoverElement: React.FC<HoverElementProps> = ({
  postal,
  onSelect,
  buttonText = 'View this Item',
  hideUtilityButtons,
}) => {
  const { isExtension } = useExtension()

  const navigate = useNavigate()
  const location = useLocation()

  const sendAnalytics = useAnalyticsSend()
  const { canUpdate, canSend, canLink } = usePostalPermissions(postal)

  const sendFlowLink = useNavigateSendFlow()

  const handleAction = (type: string) => {
    switch (type) {
      case 'EDIT':
        sendAnalytics({
          event: AnalyticsEvent.MarketplaceEditMyItemClicked,
          data: { marketplaceProductId: postal.marketplaceProductId, itemName: postal.name },
        })
        navigate(`${location.pathname}/${postal.id}/edit`)
        break
      case 'SEND':
        sendAnalytics({
          event: AnalyticsEvent.MarketplaceSendMyItemClicked,
          data: { marketplaceProductId: postal.marketplaceProductId, itemName: postal.name },
        })
        navigate(
          sendFlowLink(`${location.pathname}/${postal.id}/send`, {
            returnTo: 'Approved Items',
          })
        )
        break
      case 'CREATE_LINK':
        sendAnalytics({
          event: AnalyticsEvent.MarketplaceCreateMagicLinkMyItemClicked,
          data: { marketplaceProductId: postal.marketplaceProductId, itemName: postal.name },
        })
        navigate(
          sendFlowLink(`${location.pathname}/${postal.id}/send`, {
            returnTo: 'Approved Items',
            sendMethod: PostalSendMethod.Link,
          })
        )
        break
    }
  }

  return (
    <IconContext.Provider value={{ size: '20px', color: 'black' }}>
      <Flex
        h="100%"
        flexDir="column"
        justifyContent="flex-end"
        gap={{ base: 4, sm: 5 }}
        p={5}
      >
        <Box />
        {!hideUtilityButtons && !isExtension && (
          <Flex
            justifyContent="center"
            gap={{ base: 2, sm: 4 }}
          >
            {canSend && (
              <UiScaleEffect>
                <IconButton
                  aria-label="Send Item"
                  size="md"
                  borderRadius="full"
                  bg="whiteAlpha.700"
                  _hover={{ bg: 'whiteAlpha.800' }}
                  _active={{ bg: 'whiteAlpha.900' }}
                  icon={<MdSend />}
                  onClick={() => handleAction('SEND')}
                />
              </UiScaleEffect>
            )}
            {canLink && (
              <UiScaleEffect>
                <IconButton
                  aria-label="Link Item"
                  size="md"
                  borderRadius="full"
                  bg="whiteAlpha.700"
                  _hover={{ bg: 'whiteAlpha.800' }}
                  _active={{ bg: 'whiteAlpha.900' }}
                  icon={<MdLink />}
                  onClick={() => handleAction('CREATE_LINK')}
                />
              </UiScaleEffect>
            )}
            {canUpdate && (
              <UiScaleEffect>
                <IconButton
                  aria-label="Edit Item"
                  size="md"
                  borderRadius="full"
                  bg="whiteAlpha.700"
                  _hover={{ bg: 'whiteAlpha.800' }}
                  _active={{ bg: 'whiteAlpha.900' }}
                  icon={<MdOutlineSettings />}
                  onClick={() => handleAction('EDIT')}
                  isDisabled={isExtension ? true : false}
                />
              </UiScaleEffect>
            )}
          </Flex>
        )}
        <UiScaleEffect w="100%">
          <ZButton
            justifyContent="center"
            w="100%"
            fontSize="sm"
            fontWeight="bold"
            variant="outline"
            color="whiteAlpha.900"
            borderColor="whiteAlpha.900"
            borderWidth="2px"
            _hover={{ bg: 'blackAlpha.100' }}
            _active={{ bg: 'blackAlpha.200' }}
            onClick={onSelect}
          >
            {buttonText}
          </ZButton>
        </UiScaleEffect>
      </Flex>
    </IconContext.Provider>
  )
}

interface MarketplaceCardPostalV2Props extends Omit<ZProductCardProps, 'onSelect'> {
  postal: ApprovedPostal
  isBulkSelected?: boolean
  favoriteItemId?: string | null
  onSelect?: (postal: ApprovedPostal) => any
  onBulkSelect?: (postal: ApprovedPostal) => any
  buttonText?: string
  hideUtilityButtons?: boolean
  truncateLength?: number
}

export const MarketplaceCardPostal: React.FC<MarketplaceCardPostalV2Props> = ({
  postal,
  isBulkSelected,
  onSelect,
  onBulkSelect,
  buttonText,
  hideUtilityButtons,
  favoriteItemId,
  truncateLength = 40,
  ...rest
}) => {
  const { hasRole } = useAcl()
  const hasFavorites = hasRole(Role.User)

  const { assetUrlSrc } = useAssets()
  const image = usePostalImages(postal)
  const srcProps = assetUrlSrc(image?.[0]?.url, { w: 350, h: 350, fit: 'clip' })

  const handleSelect = useCallback(() => onSelect?.(postal), [postal, onSelect])

  const { toggleFavorite, isFavorite, isLoading } = usePostalFavoriteStatusToggle({
    favoriteItemId,
    approvedPostalId: postal.id,
  })

  const canBulkSelect = !!onBulkSelect

  const handleBulkSelect = () => {
    onBulkSelect?.(postal)
  }

  const subtext = useMemo(() => {
    if (postal.collection) {
      const numItems = groupCollectionItems(postal).length
      return numItems === 1 ? `1 item` : `${numItems} items`
    }
    return postal.brandName
  }, [postal])

  const statusTag = useMemo(() => {
    if (postal.status === Status.Disabled) return StatusTags.draft
    if (!postal.variants?.filter((v) => v.status === Status.Active).length) return StatusTags.outOfStock
    return false
  }, [postal.status, postal.variants])

  const { isExtension } = useExtension()

  // since we aren't using a mouse on tablets, skip hover view and open item instead
  const enableHover = useBreakpointValue(isExtension ? { base: true, sm: true } : { base: false, lg: true })

  return (
    <Fade in>
      <ZProductCard
        defaultIsOpen={isBulkSelected}
        cursor={rest.isDisabled ? 'not-allowed' : 'revert'}
        {...rest}
      >
        <ZProductCardContent>
          <ZProductCardImageContainer
            borderWidth="1px"
            borderColor="atomicGray.100"
            onClick={enableHover ? undefined : handleSelect}
          >
            <ZProductCardImageIcons justifyContent="space-between">
              {canBulkSelect ? (
                <ZProductCardImageIconSelect
                  isSelected={isBulkSelected}
                  onClick={handleBulkSelect}
                />
              ) : (
                <Box />
              )}
              {statusTag ? (
                <ZStatusTag
                  {...statusTag}
                  border="1px solid var(--chakra-colors-atomicGray-200)"
                  mt={3}
                  mr={4}
                />
              ) : hasFavorites ? (
                <UiEnhancedTooltip
                  label={isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
                  mt={-2}
                  mr={4}
                  openDelay={1000}
                  hasArrow
                  isDisabled={isLoading}
                  shouldWrapChildren
                >
                  <ZProductCardImageIconFavorite
                    isSelected={isFavorite}
                    onClick={toggleFavorite}
                  />
                </UiEnhancedTooltip>
              ) : null}
            </ZProductCardImageIcons>
            <ZProductCardImage {...srcProps} />
            {!rest.isDisabled && (
              <ZProductCardImageHover
                jsx={
                  <HoverElement
                    buttonText={buttonText}
                    hideUtilityButtons={hideUtilityButtons || !enableHover}
                    postal={postal}
                    onSelect={handleSelect}
                  />
                }
              />
            )}
          </ZProductCardImageContainer>
          <ZProductCardBody>
            <ZLink
              mt={2}
              flexDir="column"
              alignItems="left"
              gap={0}
              onClick={handleSelect}
              cursor={rest.isDisabled ? 'not-allowed' : undefined}
            >
              <chakra.span
                color="atomicGray.700"
                noOfLines={1}
                maxW="325px"
              >
                <UiTruncate
                  text={postal.name}
                  length={truncateLength}
                />
              </chakra.span>
              <chakra.span
                color="atomicGray.500"
                noOfLines={1}
              >
                <UiTruncate
                  text={subtext as string}
                  length={truncateLength}
                />
              </chakra.span>
              <MarketplaceApprovedPostalPriceRange item={postal} />
            </ZLink>
          </ZProductCardBody>
        </ZProductCardContent>
      </ZProductCard>
    </Fade>
  )
}
