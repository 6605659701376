import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButtonScrollTop,
  UiLoading,
  UiTimeline,
  UiTimelineDay,
  UiTimelineEvent,
  UiTimelineIcon,
  useAlertError,
  useInfiniteScroll,
  ZHeading,
  ZText,
} from '@postal-io/postal-ui'
import type { ActivityStream } from 'api'
import { ActivityStreamOrderByInput, GetCrmAccountByIdentifierDocument, SearchActivityStreamDocument } from 'api'
import { useAcl, useExtension } from 'hooks'
import React, { useMemo } from 'react'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { ExtABMPlaceholder } from '../ABM'
import { ExtHeader } from '../Main/ExtHeader'
import { EXT_ACCOUNT_TIMELINE_CATEGORIES, EXT_ACCOUNT_TIMELINE_CONFIG } from './data'
import { ExtAccountTimelinePlaceholder } from './ExtAccountTimelinePlaceholder'
import { ExtAccountTimelineText } from './ExtAccountTimelineText'

const LIMIT = 100

type CleanedActivityStream = ActivityStream & { date: string }

type ActivityStreamDay = {
  date: string
} & ActivityStream

type ActivityStreamDayObj = {
  [date: string]: ActivityStreamDay[]
}

export const ExtAccountTimeline: React.FC<BoxProps> = (props) => {
  const navigate = useNavigate()
  const params = useParams()
  const crmIdentifier = params.crmIdentifier as string
  const { hasSubscription } = useAcl()
  const { contactId } = useExtension()

  const hasABM = hasSubscription('POSTAL_ABM_ACCESS')

  const getCrmAccountByIdentifierQuery = useGraphqlQuery(
    GetCrmAccountByIdentifierDocument,
    { crmIdentifier: crmIdentifier as string },
    { enabled: hasABM && !!crmIdentifier }
  )
  const crmAccount = getCrmAccountByIdentifierQuery?.data?.getCrmAccountByIdentifier
  useAlertError(getCrmAccountByIdentifierQuery.error)

  const searchActivityStreamQuery = useGraphqlInfiniteQuery(
    SearchActivityStreamDocument,
    {
      filter: {
        contactCrmMap_accountId: { eq: crmAccount?.crmIdentifier },
        category: { in: EXT_ACCOUNT_TIMELINE_CATEGORIES },
      },
      orderBy: ActivityStreamOrderByInput.CreatedDesc,
      limit: LIMIT,
    },
    { enabled: !!crmAccount?.crmIdentifier }
  )
  useAlertError(searchActivityStreamQuery.error)

  const cleanedActivityStream = useMemo(
    (): CleanedActivityStream[] | null =>
      searchActivityStreamQuery?.mergedData?.searchActivityStream?.map((item: ActivityStream) => ({
        ...item,
        date: new Date(item.created.dateTime as string).toLocaleDateString(),
      })) ?? null,
    [searchActivityStreamQuery?.mergedData?.searchActivityStream]
  )

  const isEmptyResults = cleanedActivityStream !== null && cleanedActivityStream.length === 0

  const activityStreamDays = useMemo((): string[] | null => {
    return !cleanedActivityStream
      ? null
      : [...new Set(cleanedActivityStream.map((item) => item.date))]
          ?.map((dateStr) => new Date(dateStr))
          ?.sort((a, b) => b.getTime() - a.getTime())
          ?.map((date) => date.toLocaleDateString())
  }, [cleanedActivityStream])

  const activityStreamDaysObj = useMemo(
    (): ActivityStreamDayObj | null =>
      cleanedActivityStream?.reduce((obj, item) => {
        if (!obj[item.date]) obj[item.date] = []
        obj[item.date].push(item)
        return obj
      }, {} as ActivityStreamDayObj) ?? null,
    [cleanedActivityStream]
  )

  const handleBack = () => navigate(`/extension/contacts/${contactId || ''}`)

  const { topRef, bottomRef, scrollTop } = useInfiniteScroll({
    hasMore: searchActivityStreamQuery.hasNextPage,
    loadMore: searchActivityStreamQuery.fetchNextPage,
    loading: searchActivityStreamQuery.isFetching,
  })

  return (
    <Box
      p={4}
      {...props}
    >
      <ExtHeader>
        <Flex
          alignItems="center"
          columnGap="10px"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="30px"
            height="30px"
            bg="atomicGray.50"
            borderRadius="50%"
            _hover={{ bg: 'atomicGray.100', cursor: 'pointer' }}
            onClick={handleBack}
          >
            <MdKeyboardArrowLeft
              size="16px"
              color="#8492A6"
            />
          </Box>
          {hasABM && <ZText size="lg">{crmAccount?.name}</ZText>}
        </Flex>
      </ExtHeader>
      {!hasABM ? (
        <ExtABMPlaceholder />
      ) : (
        <>
          <Box ref={topRef}>
            <ZHeading
              mt={8}
              size="h4"
            >
              Timeline
            </ZHeading>
          </Box>
          {searchActivityStreamQuery.isLoading ? (
            <UiLoading />
          ) : (
            activityStreamDays !== null && (
              <>
                <UiTimeline
                  mt={4}
                  config={EXT_ACCOUNT_TIMELINE_CONFIG}
                  variant="accordion"
                  defaultIndex={activityStreamDays.map((_, idx) => idx)}
                >
                  {isEmptyResults ? (
                    <ExtAccountTimelinePlaceholder />
                  ) : (
                    activityStreamDays.map((day, dayIdx) => (
                      <UiTimelineDay
                        key={day}
                        date={day}
                        idx={dayIdx}
                      >
                        {activityStreamDaysObj?.[day].map((item: any, idx: any) => (
                          <UiTimelineEvent key={idx}>
                            <UiTimelineIcon activity={item.category} />
                            <ExtAccountTimelineText
                              message={item.message}
                              callouts={item.callouts}
                              count={item.count}
                            />
                          </UiTimelineEvent>
                        ))}
                      </UiTimelineDay>
                    ))
                  )}
                </UiTimeline>

                {(cleanedActivityStream?.length ?? 0) >= LIMIT && (
                  <Box
                    ref={bottomRef}
                    mt={8}
                  >
                    <UiButtonScrollTop
                      onClick={scrollTop}
                      float="right"
                      isLoading={searchActivityStreamQuery.isFetching}
                      aria-label="scroll button"
                    />
                  </Box>
                )}
              </>
            )
          )}
        </>
      )}
    </Box>
  )
}
