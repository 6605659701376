import { useMemo } from 'react'
import { useLocation } from 'react-router'

/*
  This hook is meant to be used with UiSubNavbarLink.  If the path is
  undefined, it will use history to go back, rather than pushing onto the
  stack

  If the location state contains a returnTo, use this for the title and return
  undefined for the path.

  Otherwise construct the title and path that are passed in

  const back = useRouteBack('Teams', '/teams')
  <UiSubnavbarLink {...back} />
*/
export const useRouteBack = (returnTo: string, returnPath: string, prefix = 'Back to ') => {
  const { state } = useLocation() as any
  return useMemo(() => {
    return {
      title: `${prefix}${state?.returnTo || returnTo}`,
      path: !!state?.returnTo ? undefined : returnPath,
    }
  }, [prefix, returnPath, returnTo, state?.returnTo])
}
