import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import type { TagProps } from '@chakra-ui/react'
import { HStack, Icon, Wrap, WrapItem } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZSubNavbarProps } from '@postal-io/postal-ui'
import { humanize, ZButton, ZSubNavbar, ZTag, ZTagCloseButton, ZTagLabel } from '@postal-io/postal-ui'
import type { Team } from 'api'
import { GetMarketplaceProductDocument, TeamsDocument } from 'api'
import type { UsePostalFiltersV2Response } from 'hooks'
import React, { useMemo } from 'react'
import { MdClose } from 'react-icons/md'

interface SubnavFiltersV2Props extends ZSubNavbarProps {
  filters: UsePostalFiltersV2Response['filters']
  onUpdate: UsePostalFiltersV2Response['updateFilter']
  onClear: UsePostalFiltersV2Response['clearFilters']
}

export const SubnavFilters: React.FC<SubnavFiltersV2Props> = ({ filters, onUpdate, onClear, ...rest }) => {
  return (
    <ZSubNavbar
      px={0}
      gridProps={{ pt: 0, pb: 6 }}
      left={
        <HStack spacing={5}>
          {!!Object.entries(filters).length && <ClearFiltersButton onClick={() => onClear()} />}
          <Wrap alignItems="center">
            {Object.entries(filters).map(([name, value]) => (
              <FilterTags
                key={name}
                onUpdate={onUpdate}
                name={name}
                value={value}
              />
            ))}
          </Wrap>
        </HStack>
      }
      {...rest}
    />
  )
}

const ClearFiltersButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <ZButton
    variant="link"
    color="atomicGray.500"
    h="auto"
    mb={0.5}
    p={0}
    fontWeight="normal"
    fontSize="body-md"
    _hover={{ color: 'atomicGray.600' }}
    rightIcon={
      <Icon
        as={MdClose}
        fontSize="lg"
        mt="1px"
      />
    }
    onClick={onClick}
  >
    Clear filters
  </ZButton>
)

interface FilterTagsProps extends TagProps {
  name: string
  value: any
  onUpdate: (name: string, value?: any) => void
}

const FilterTags: React.FC<FilterTagsProps> = ({ name, value, onUpdate, ...rest }) => {
  const getMarketplaceProductQuery = useGraphqlQuery(
    GetMarketplaceProductDocument,
    { id: value },
    { enabled: !!value && name === 'marketplaceProductId' }
  )

  const displayValue = React.useMemo(() => {
    switch (name) {
      case 'currency':
      case 'q':
      case 'eventAvailabilityDates':
        return value
      case 'marketplaceProductId':
        return getMarketplaceProductQuery.data?.getMarketplaceProduct?.name || '...loading'
      case 'outOfStock':
      case 'showOutOfStock':
        return 'Show Out Of Stock'
      case 'draft':
      case 'showDraft':
        return 'Show Draft Items'
      case 'favorites':
      case 'favoritePostals':
      case 'favoriteCollections':
        return 'Show only Favorite Items'
      default:
        return humanize(value)
    }
  }, [getMarketplaceProductQuery.data?.getMarketplaceProduct?.name, name, value])

  if (!value || value.length === 0) return null

  const removeSingle = () => onUpdate(name)

  const removeArray = (val: any) => {
    const newVal = value.filter((v: any) => v !== val && v?.value !== val?.value)
    onUpdate(name, newVal)
  }

  const removePrice = (key: string) => {
    onUpdate(name, { ...value, [key]: undefined })
  }

  if (name === 'teamIds') {
    return (
      <TeamTags
        teamIds={value}
        onUpdate={onUpdate}
      />
    )
  }

  if (Array.isArray(value)) {
    return (
      <>
        {value.map((v) => {
          return (
            <WrapItem key={v.value ?? v}>
              <ZTag
                color="atomicGray.900"
                bg="atomicBlue.5"
                size="sm"
                onClick={() => removeArray(v)}
                _hover={{ cursor: 'pointer' }}
                {...rest}
              >
                {name === 'currency' ? (
                  <ZTagLabel>{v.toUpperCase()}</ZTagLabel>
                ) : (
                  <ZTagLabel>{v.name ?? humanize(v)}</ZTagLabel>
                )}
                <ZTagCloseButton />
              </ZTag>
            </WrapItem>
          )
        })}
      </>
    )
  }

  if (name === 'price') {
    return (
      <>
        {value.min && (
          <WrapItem>
            <ZTag
              color="atomicGray.900"
              bg="atomicBlue.5"
              size="sm"
              onClick={() => removePrice('min')}
              _hover={{ cursor: 'pointer' }}
              {...rest}
            >
              <ZTagLabel>
                <ChevronUpIcon verticalAlign="middle" />${value.min}
              </ZTagLabel>
              <ZTagCloseButton />
            </ZTag>
          </WrapItem>
        )}
        {value.max && (
          <WrapItem>
            <ZTag
              color="atomicGray.900"
              bg="atomicBlue.5"
              size="sm"
              onClick={() => removePrice('max')}
              _hover={{ cursor: 'pointer' }}
              {...rest}
            >
              <ZTagLabel>
                <ChevronDownIcon verticalAlign="middle" />${value.max}
              </ZTagLabel>
              <ZTagCloseButton />
            </ZTag>
          </WrapItem>
        )}
      </>
    )
  }

  return (
    <WrapItem>
      <ZTag
        color="atomicGray.900"
        bg="atomicBlue.5"
        size="sm"
        onClick={removeSingle}
        _hover={{ cursor: 'pointer' }}
        {...rest}
      >
        <ZTagLabel>{displayValue}</ZTagLabel>
        <ZTagCloseButton />
      </ZTag>
    </WrapItem>
  )
}

interface TeamTagsProps {
  teamIds?: string[] | null
  onUpdate?: (name: string, value?: any) => void
  bg?: string
  color?: string
}
export const TeamTags: React.FC<TeamTagsProps> = ({ teamIds, onUpdate, ...rest }) => {
  const teamsQuery = useGraphqlQuery(
    TeamsDocument,
    { filter: { id: { in: teamIds } } },
    { enabled: !!teamIds?.length, staleTime: 60 * 1000 }
  )

  const teams = useMemo(() => teamsQuery.data?.teams || [], [teamsQuery.data?.teams])

  const handleRemove = (team: Team) => {
    const newTeamIds = teams.filter((t) => t.id !== team.id).map((t) => t.id)
    onUpdate?.('teamIds', newTeamIds?.length ? newTeamIds : undefined)
  }

  if (!teams?.length) return null

  return (
    <>
      {teams.map((team) => {
        return (
          <WrapItem key={team.id}>
            <ZTag
              size="sm"
              onClick={() => handleRemove(team)}
              _hover={{ cursor: 'pointer' }}
              {...rest}
            >
              <ZTagLabel color={rest?.color}>{team.name}</ZTagLabel>
              {onUpdate && <ZTagCloseButton />}
            </ZTag>
          </WrapItem>
        )
      })}
    </>
  )
}
