import type {
  ApprovedPostal,
  ApprovedPostalInputItem,
  ApprovedProductVariant,
  Currency,
  MarketplaceProduct,
  ProductVariant,
} from 'api'
import { Status } from 'api'

export type EitherVariant = ProductVariant | ApprovedProductVariant
export type EitherItem = MarketplaceProduct | ApprovedPostal

export const isItemApprovedPostal = (item: EitherItem) => !!(item as ApprovedPostal).marketplaceProductId

export const addItemFieldsToVariant = (item: EitherItem, variant: EitherVariant) => {
  if (isItemApprovedPostal(item))
    return {
      ...variant,
      marketplaceProductId: (item as ApprovedPostal).marketplaceProductId,
      approvedPostalId: item.id,
    }
  else return { ...variant, marketplaceProductId: item.id }
}

export interface CollectionItem {
  marketplaceProductId: string
  approvedPostalId?: string
  productName: string
  description?: string
  currency?: Currency
  defaultImage?: string
  variantImages: string[]
  variants: ApprovedProductVariant[]
}

export const sortVariants = (variants: ApprovedProductVariant[] | null | undefined) => {
  return variants?.filter((v) => v.status === Status.Active)?.sort((a, b) => a.price - b.price) || []
}

export const getPriceRange = (variants: ApprovedProductVariant[] | null | undefined) => {
  if (!variants) return { minPrice: 0, maxPrice: 0, hasMixedPrice: false }
  const sortedVariants = sortVariants(variants)
  const minPrice = sortedVariants[0]?.displayPrice || 0
  const maxPrice = sortedVariants.length ? sortedVariants[sortedVariants.length - 1]?.displayPrice || 0 : 0
  const hasMixedPrice = minPrice !== maxPrice
  return { minPrice, maxPrice, hasMixedPrice }
}

const generateItemFromCollection = (collection: ApprovedPostal, variant: ApprovedProductVariant) =>
  ({
    marketplaceProductId: variant.marketplaceProductId,
    approvedPostalId: variant.approvedPostalId,
    productName: variant.productName,
    description: variant.description,
    currency: collection.currency,
    variantImages: [],
    variants: [],
  } as CollectionItem)

/*
  This groups a collection by the approvedPostalId or marketplaceProductId attached to each variant,
  only including active variants

  [
    {
      marketplaceProductId: 'xxx',
      productName: 'Starbucks Card',
      ...
      variants: [
        { variantName: '$5', ... },
        { variantName: '$10', ... }
      ]
    },
    {
      marketplaceProductId: 'yyy',
      productName: 'Everybody Lies',
      ...
      variants: [
        { variantName: 'Hardcover', ... },
        { variantName: 'Softcover', ... }
      ]
    }
  ]
*/
export const groupCollectionItems = (collection: ApprovedPostal) => {
  const sortedVariants = sortVariants(collection.variants)
  const marketplaceItemMap = new Map<string, CollectionItem>()
  const approvedItemMap = new Map<string, CollectionItem>()

  const getItemFromMap = (variant: ApprovedProductVariant) => {
    if (variant.approvedPostalId) return approvedItemMap.get(variant.approvedPostalId)
    else return marketplaceItemMap.get(variant.marketplaceProductId!)
  }

  const storeItemInMap = (item: CollectionItem) => {
    if (item.approvedPostalId) approvedItemMap.set(item.approvedPostalId, item)
    else if (item.marketplaceProductId) marketplaceItemMap.set(item.marketplaceProductId, item)
  }

  sortedVariants.forEach((variant) => {
    const item = getItemFromMap(variant) ?? generateItemFromCollection(collection, variant)

    item.variants.push(variant)

    const firstImage = variant.imageUrls?.[0]?.url
    if (firstImage) {
      item.variantImages.push(firstImage)
      if (!item.defaultImage) item.defaultImage = firstImage
    }
    storeItemInMap(item)
  })

  return [...Array.from(marketplaceItemMap.values()), ...Array.from(approvedItemMap.values())] as CollectionItem[]
}

export const getItemNames = (collection: ApprovedPostal) => {
  return groupCollectionItems(collection).map((item) => item.productName)
}

export const getApprovedInputItemFromVariant = (v: ApprovedProductVariant, item?: CollectionItem) =>
  ({
    marketplaceProductId: v.marketplaceProductId ?? (item?.marketplaceProductId as string),
    approvedPostalId: v.approvedPostalId ?? item?.approvedPostalId,
    variant: v.id,
  } as ApprovedPostalInputItem)

// exclude all variants from a given item
export const getVariantsWithoutItem = (collection: ApprovedPostal, item: CollectionItem) =>
  (collection.variants || [])
    .filter((v) => v.status === Status.Active)
    .filter((v) => v.marketplaceProductId !== item?.marketplaceProductId)
    .map((v) => getApprovedInputItemFromVariant(v))
