import { Box, Portal, ScaleFade, Wrap, WrapItem } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import {
  UiTagCloseButton,
  useInfiniteScroll,
  ZButton,
  ZSelectPopover,
  ZSelectPopoverBody,
  ZSelectPopoverContent,
  ZSelectPopoverFooter,
  ZSelectPopoverItem,
  ZSelectPopoverItems,
  ZSelectPopoverSearch,
  ZSelectPopoverTrigger,
  ZTag,
  ZTagLabel,
  ZText,
} from '@postal-io/postal-ui'
import type { User } from 'api'
import { Role, SearchUsersDocument, Status } from 'api'
import { useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'

const LIMIT = 20

const filter = {
  productAccess: { roles: { eq: Role.Admin }, teamId: { eq: null } },
  status: { eq: Status.Active },
}

export const AccountSettingsUsersSelect: React.FC<{
  value?: string[]
  onChange: (userIds: string[]) => void
  onClose: () => void
  testid?: string
}> = ({ value, onChange, onClose, testid }) => {
  const [search, setSearch] = useState('')
  const [debounced] = useDebounce(search, 400)

  // UiPopoverItems expects an empty array for no values, lets memoize this here
  const realValue = useMemo(() => value ?? [], [value])

  const selectedUsersQuery = useGraphqlInfiniteQuery(
    SearchUsersDocument,
    { filter: { id: { in: realValue }, ...filter }, limit: LIMIT },
    { keepPreviousData: true, enabled: !!realValue.length }
  )

  const searchUsers = useGraphqlInfiniteQuery(
    SearchUsersDocument,
    { filter: { userName: debounced ? { contains: debounced } : undefined, ...filter }, limit: LIMIT },
    { keepPreviousData: true }
  )

  const users = useMemo(
    () => searchUsers.mergedData?.searchUsers.users || [],
    [searchUsers.mergedData?.searchUsers.users]
  )

  const tagUsersList = useMemo(() => {
    return selectedUsersQuery.mergedData?.searchUsers.users?.filter((u) => realValue?.includes(u.id)) || []
  }, [selectedUsersQuery.mergedData?.searchUsers.users, realValue])

  const { bottomRef } = useInfiniteScroll({
    hasMore: searchUsers.hasNextPage,
    loadMore: searchUsers.fetchNextPage,
    loading: searchUsers.isFetching,
  })

  const handleRemove = (user: User) => {
    const updatedValue = realValue.filter((i) => i !== user.id)
    onChange(updatedValue)
  }

  const title = useMemo(
    () => (realValue.length ? `Admin${realValue.length > 1 ? 's' : ''}` : 'All Admins'),
    [realValue.length]
  )

  return (
    <ZSelectPopover
      size="lg"
      autoFocus
      onClose={onClose}
      placement="right-start"
    >
      <ZSelectPopoverTrigger
        isFirst
        isLast
        color="header.dark"
        borderWidth="1px"
        data-testid={`${testid}-trigger`}
      >
        {!!realValue.length && (
          <ZText
            color="atomicBlue.400"
            mr={1}
          >{`(${realValue.length}) `}</ZText>
        )}
        {title}
      </ZSelectPopoverTrigger>
      <Portal>
        <ZSelectPopoverContent maxH="52vh">
          <ZSelectPopoverBody data-testid={`${testid}-content`}>
            <ZSelectPopoverSearch
              onChange={({ target }) => setSearch(target.value)}
              value={search}
              placeholder="Search Username"
            />
            <Wrap
              w="100%"
              p={4}
              borderBottomColor="atomicGray.100"
              borderBottomWidth="2px"
            >
              {tagUsersList?.length ? (
                tagUsersList?.map((user) => (
                  <ScaleFade
                    key={user.id}
                    initialScale={0.8}
                    in={true}
                  >
                    <WrapItem>
                      <ZTag
                        rounded="none"
                        colorScheme="atomicGray"
                        py={1}
                        px={2}
                        size="sm"
                        onClick={() => handleRemove(user)}
                        _hover={{ cursor: 'pointer' }}
                      >
                        <ZTagLabel
                          fontWeight="bold"
                          userSelect="none"
                        >
                          {`${user.firstName} ${user?.lastName?.slice(0, 1)}.`}
                        </ZTagLabel>
                        <UiTagCloseButton />
                      </ZTag>
                    </WrapItem>
                  </ScaleFade>
                ))
              ) : (
                <ZText
                  p={1.5}
                  color="atomicGray.400"
                  userSelect="none"
                >
                  All Admins
                </ZText>
              )}
            </Wrap>
            <ZSelectPopoverItems
              onChange={onChange}
              value={realValue}
              overflow="scroll"
            >
              {users.map((user) => {
                return (
                  <ZSelectPopoverItem
                    key={user.id}
                    value={user.id}
                    my={-0.5}
                  >
                    <ZText
                      ml={2}
                      fontWeight="bold"
                      fontSize="md"
                    >
                      {`${user.firstName} ${user.lastName}`}
                    </ZText>
                    <ZText
                      ml={2}
                      mt={0}
                      fontWeight="bold"
                      fontSize="sm"
                      color="atomicGray.500"
                    >
                      {user.userName}
                    </ZText>
                  </ZSelectPopoverItem>
                )
              })}
            </ZSelectPopoverItems>
          </ZSelectPopoverBody>
          <ZSelectPopoverFooter
            borderTopColor="atomicGray.100"
            borderTopWidth="2px"
            // minH="3.5rem"
            pb={4}
          >
            {!searchUsers.isLoading && <Box ref={bottomRef} />}
            <ZButton
              variant="ghost"
              onClick={() => onChange([])}
              w="100%"
              // mx="auto"
              // display={realValue?.length ? 'block' : 'none'}
            >
              Clear
            </ZButton>
          </ZSelectPopoverFooter>
        </ZSelectPopoverContent>
      </Portal>
    </ZSelectPopover>
  )
}
