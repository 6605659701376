import { Alert, AlertIcon, Box, Flex, FormControl, InputGroup, Stack } from '@chakra-ui/react'
import {
  hasStorage,
  UiInfoTooltip,
  UiLink,
  useAlerts,
  ZFlowButton,
  ZFormLabel,
  ZInput,
  ZText,
} from '@postal-io/postal-ui'
import { AnalyticsEventV2, getAnalyticsSessionInfo, sendAnalyticsEvent, useSession } from 'hooks'
import React, { useState } from 'react'
import { MdChevronRight } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { SsoOption } from './SsoOption'

interface State {
  userName: string
  password: string
}

export const SignInForm: React.FC = () => {
  const { loginPassword } = useSession()
  const [loading, setLoading] = useState(false)
  const alert = useAlerts()
  const navigate = useNavigate()

  const [form, setForm] = useImmer<State>({ userName: '', password: '' })

  const sessionDisabled = !hasStorage('localStorage') && !hasStorage('sessionStorage')

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setForm((draft: any) => {
      draft[name] = value
    })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      setLoading(true)
      const session = await loginPassword(form)
      sendAnalyticsEvent(AnalyticsEventV2.ExtensionLogin, { ...getAnalyticsSessionInfo(session), type: 'PASSWORD' })
    } catch (err) {
      if (err.message.includes('has no roles')) {
        navigate('/extension/error/no_roles')
      } else {
        alert.warning(err)
        setLoading(false)
      }
    }
  }

  return (
    <>
      {sessionDisabled && (
        <Alert
          status="warning"
          mb={8}
          w="100%"
        >
          <AlertIcon />
          <Box
            as="span"
            mr={4}
          >
            Please enable third-party cookies
          </Box>
          <UiInfoTooltip
            aria-label="Help"
            label="Third party cookies are required to save your login between page loads.  Please change your chrome settings to allow third party cookies for https://app.postal.io"
          />
        </Alert>
      )}
      <Flex
        direction="column"
        w="100%"
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={5}>
            <FormControl id="userName">
              <ZFormLabel
                fontSize={12}
                lineHeight={3}
                fontWeight={400}
                mb="10px"
              >
                Email Address
              </ZFormLabel>
              <InputGroup>
                <ZInput
                  name="userName"
                  type="email"
                  placeholder="Email Address"
                  autoCapitalize="off"
                  value={form.userName}
                  onChange={handleInput}
                  isRequired
                />
              </InputGroup>
            </FormControl>
            <FormControl id="password">
              <ZFormLabel
                fontSize={12}
                lineHeight={3}
                fontWeight={400}
                mb="10px"
              >
                Password
              </ZFormLabel>
              <InputGroup>
                <ZInput
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={form.password}
                  onChange={handleInput}
                  isRequired
                />
              </InputGroup>
            </FormControl>
            <ZFlowButton
              type="submit"
              mt={5}
              w="full"
              bg="atomicBlue.400"
              size="md"
              rightIcon={<MdChevronRight fontSize="lg" />}
              isDisabled={loading}
              isLoading={loading}
              textTransform="uppercase"
              _hover={{ bg: 'atomicBlue.600' }}
            >
              Log in
            </ZFlowButton>
            <UiLink
              display="flex"
              justifyContent="right"
              href="/sign-in"
              isExternal
            >
              <ZText
                size="sm"
                color="atomicBlue.400"
              >
                I forgot my password
              </ZText>
            </UiLink>
          </Stack>
        </form>
        <SsoOption mt="60px" />
        <Box
          w="100%"
          textAlign="center"
          mt={14}
        >
          <ZText size="sm">This site is protected by reCAPTCHA. </ZText>
          <ZText size="sm">
            The{` `}
            <UiLink
              isExternal
              href="https://policies.google.com/privacy"
              fontSize="xs"
              fontWeight="400"
              color="atomicBlue.400"
            >
              Google Privacy Policy
            </UiLink>
            {` `}and{` `}
            <UiLink
              isExternal
              href="https://policies.google.com/terms"
              fontSize="xs"
              fontWeight="400"
              color="atomicBlue.400"
            >
              Terms of Service
            </UiLink>
            {` `}apply.
          </ZText>
        </Box>
      </Flex>
    </>
  )
}
