import { SettingsIcon } from '@chakra-ui/icons'
import type { AspectRatioProps, BoxProps } from '@chakra-ui/react'
import { AspectRatio, Box, Grid, Image, useDisclosure } from '@chakra-ui/react'
import type {} from '@postal-io/postal-ui'
import {
  UiIconButton,
  UiIconPostalEdit,
  UiSlide,
  UiSlideArrows,
  UiSlideCard,
  UiSlideCards,
  UiSlideContainer,
  UiSlideDots,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, MarketplaceProduct } from 'api'
import { useAssets } from 'hooks'
import React, { useMemo } from 'react'
import { useWindowSize } from 'react-use'
import { EventsApprovedPostalImagesEdit } from './EventsApprovedPostalImagesEdit'

const IMAGE_BREAKPOINT = 900
const MAX_IMAGE_HEIGHT = '550px'

interface EventsBannerV2Props extends BoxProps {
  postal?: MarketplaceProduct | ApprovedPostal | null
  canUpdate?: boolean
}

interface BannerImageProps extends AspectRatioProps {
  src?: string
  fallbackSrc?: string
}
const BannerImage: React.FC<BannerImageProps> = ({ src, fallbackSrc, ...rest }) => {
  if (!src && !fallbackSrc) return null
  return (
    <AspectRatio
      ratio={3 / 4}
      {...rest}
    >
      <Image
        src={src}
        fallbackSrc={fallbackSrc}
      />
    </AspectRatio>
  )
}

export const EventsBanner: React.FC<EventsBannerV2Props> = ({ postal, canUpdate, ...rest }) => {
  const { assetUrlSrc } = useAssets()

  const images = postal?.imageUrls?.map((i) => assetUrlSrc(i.url)) || []
  const editImages = useDisclosure()
  return (
    <>
      <EventBannerImagesV2
        images={images}
        onEdit={canUpdate ? editImages.onOpen : undefined}
        {...rest}
      />
      {postal && (
        <EventsApprovedPostalImagesEdit
          {...editImages}
          postal={postal as ApprovedPostal}
        />
      )}
    </>
  )
}

interface EventImagesBannerProps extends BoxProps {
  images: { src: string; fallbackSrc?: string }[]
  onEdit?: () => void
}
export const EventBannerImagesV2: React.FC<EventImagesBannerProps> = ({ images, onEdit, ...rest }) => {
  const { width } = useWindowSize()

  const gridTemplateColumns = useMemo(() => {
    switch (images.length) {
      case 1:
        return '1fr'
      case 2:
        return '1fr 1fr'
      case 3:
        return '1fr 1fr 1fr'
      case 4:
        return '1fr 1fr 1fr 1fr'
      default:
        return '2fr 1fr 1fr'
    }
  }, [images.length])

  const canEdit = images.length > 0 && onEdit !== undefined

  return (
    <>
      <Box
        w="100%"
        position="relative"
        {...rest}
      >
        {canEdit && (
          <UiIconButton
            borderTopLeftRadius={0}
            size="md"
            icon={
              <SettingsIcon
                boxSize="30px"
                p={1}
              />
            }
            aria-label="Edit Images"
            position="absolute"
            zIndex={1}
            bg="rgba(255,255,255,0.6)"
            color="black"
            _hover={{ bg: 'rgba(0,0,0,0.8)', color: 'white' }}
            top={0}
            left={0}
            onClick={onEdit}
          >
            <UiIconPostalEdit onClick={onEdit} />
          </UiIconButton>
        )}
        {images.length > 0 && (
          <>
            {width >= IMAGE_BREAKPOINT ? (
              <Grid
                gridTemplateColumns={gridTemplateColumns}
                gap="8px"
                overflow="hidden"
                h="100%"
                w="100%"
                maxH={MAX_IMAGE_HEIGHT}
              >
                <>
                  {images.length === 1 && (
                    <BannerImage
                      {...images[0]}
                      ratio={5 / 2}
                      w="100%"
                    />
                  )}

                  {images.length > 1 &&
                    images.length < 5 &&
                    images.map((image) => (
                      <BannerImage
                        src={image.src}
                        fallbackSrc={image.fallbackSrc}
                      />
                    ))}

                  {images.length > 4 && (
                    <>
                      <BannerImage {...images[0]} />
                      <Grid
                        gridTemplateRows="1fr 1fr"
                        gap="8px"
                      >
                        <BannerImage {...images[1]} />
                        <BannerImage {...images[2]} />
                      </Grid>
                      <Grid
                        gridTemplateRows="1fr 1fr"
                        gap="8px"
                      >
                        <BannerImage {...images[3]} />
                        <BannerImage {...images[4]} />
                      </Grid>
                    </>
                  )}
                </>
              </Grid>
            ) : (
              <UiSlide>
                <UiSlideContainer
                  maxH={MAX_IMAGE_HEIGHT}
                  bg="white"
                >
                  <UiSlideCards>
                    {images.map((image, idx) => {
                      return (
                        <UiSlideCard key={idx}>
                          <BannerImage
                            {...image}
                            objectFit="contain"
                          />
                        </UiSlideCard>
                      )
                    })}
                  </UiSlideCards>
                  <UiSlideArrows />
                  <UiSlideDots />
                </UiSlideContainer>
              </UiSlide>
            )}
          </>
        )}
      </Box>
    </>
  )
}
