import type { WrapProps } from '@chakra-ui/react'
import { SkeletonText, Wrap, WrapItem } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { ZMoney, ZText } from '@postal-io/postal-ui'
import type { BasicMetrics } from 'api'
import { Granularity, PlaybookAnalyticsDocument } from 'api'
import { sub } from 'date-fns'
import { useSession } from 'hooks'
import { calculateCpt } from 'lib'
import type { PropsWithChildren } from 'react'
import React, { useMemo } from 'react'

const basicMetrics = {
  queued: 0,
  failed: 0,
  sent: 0,
  started: 0,
  delivered: 0,
  bounces: 0,
  touches: 0,
  costInCents: 0,
  completed: 0,
} as BasicMetrics

interface StatItemProps {
  label: string
  isLoading?: boolean
}
const StatItem: React.FC<PropsWithChildren<StatItemProps>> = ({ label, isLoading, children }) => {
  return (
    <WrapItem gap={2}>
      <ZText
        variant="bold"
        color="vendorGreen.800"
      >
        {isLoading ? <SkeletonText /> : children}{' '}
      </ZText>
      <ZText>{label}</ZText>
    </WrapItem>
  )
}

interface PlaybookDefinitionsStatsV2Props extends WrapProps {
  playbookDefinitionId?: string | undefined
}
export const PlaybookDefinitionsStats: React.FC<PlaybookDefinitionsStatsV2Props> = ({
  playbookDefinitionId,
  ...rest
}) => {
  const startDate = useMemo(() => sub(new Date(), { months: 12 }), [])

  const {
    session: { teamId },
  } = useSession()

  const { data, isLoading } = useGraphqlQuery(PlaybookAnalyticsDocument, {
    granularity: Granularity.All,
    startDate,
    teamId,
    playbookDefinitionId,
  })

  const [total = basicMetrics] = data?.playbookAnalytics || []

  return (
    <Wrap
      spacing={5}
      {...rest}
    >
      <StatItem
        label="Started"
        isLoading={isLoading}
      >
        {total.started}
      </StatItem>
      <StatItem label="Completed">{total.completed}</StatItem>
      <StatItem
        label="Cost per touch"
        isLoading={isLoading}
      >
        <ZMoney
          color="vendorGreen.800"
          cents={calculateCpt(total)}
        />
      </StatItem>
      <StatItem label="Total cost">
        <ZMoney
          color="vendorGreen.800"
          cents={total.costInCents}
        />
      </StatItem>
    </Wrap>
  )
}
