import { NotAllowedIcon } from '@chakra-ui/icons'
import { Icon, useDisclosure } from '@chakra-ui/react'
import type { UiCardProps, ZCardProps } from '@postal-io/postal-ui'
import { FontWeight, useAlerts, ZButton, ZCard, ZText } from '@postal-io/postal-ui'
import type { IntegrationSync } from 'api'
import { DataObjectType, Status } from 'api'
import api from 'api/rest'
import React, { useMemo, useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import type { ExternalProvider } from './data'
import { ExternalSystem } from './data'
import { MarketoIntegration } from './MarketoIntegration'

interface IntegrationCardProps extends ZCardProps {
  provider: ExternalProvider
  isAuthenticated: boolean
  isLoading: boolean
  integrationSyncs: IntegrationSync[]
  onClick: () => void
}

export const IntegrationCard: React.FC<IntegrationCardProps> = ({
  provider,
  isAuthenticated,
  isLoading,
  integrationSyncs,
  onClick,
  ...rest
}) => {
  const userIntegration = useMemo(() => {
    return integrationSyncs.find((i) => [DataObjectType.Product, DataObjectType.User].includes(i.objectType))
  }, [integrationSyncs])

  const isActive = useMemo(() => userIntegration?.status === Status.Active, [userIntegration?.status])
  const isDisabled = !provider?.integration?.enabled
  const opacity = !isDisabled ? 1 : 0.8
  const handleEdit = async () => !isDisabled && onClick()

  return isDisabled || userIntegration || isAuthenticated ? (
    <ZCard
      isLoading={isLoading}
      opacity={opacity}
      variant="form"
      alignItems="center"
      {...rest}
    >
      <Icon
        as={provider.icon}
        fontSize="125px"
        display="block"
        mx="auto"
      />
      <ZButton
        m={8}
        variant="outline"
        alignItems="center"
        colorScheme={isActive ? 'atomicBlue' : 'atomicGray'}
        color={isActive ? 'atomicBlue.400' : 'atomicGray.400'}
        borderColor={isActive ? 'atomicBlue.400' : 'atomicGray.400'}
        fontWeight={500}
        leftIcon={isActive ? <MdCheckCircle size="16px" /> : <NotAllowedIcon color="inherit" />}
        size="md"
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={handleEdit}
      >
        <ZText
          pt={0.5}
          fontWeight={FontWeight.Bold}
          color="inherit"
        >
          {isDisabled ? 'Coming Soon' : isActive ? 'Enabled' : 'Disabled'}
        </ZText>
      </ZButton>
    </ZCard>
  ) : (
    <PreConnectCard
      isLoading={isLoading}
      provider={provider}
      {...rest}
    />
  )
}

interface PreConnectCardProps extends UiCardProps {
  provider: ExternalProvider
  isLoading: boolean
}

const PreConnectCard: React.FC<PreConnectCardProps> = ({ provider, isLoading, ...rest }: PreConnectCardProps) => {
  const Alert = useAlerts()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const isMarketo = provider.system === ExternalSystem.Marketo
  const showMarketo = useDisclosure()

  const integrate = () => {
    return isMarketo ? showMarketo.onOpen() : integrateOath()
  }

  const integrateMarketo = () => {
    showMarketo.onClose()
    navigate(`/integrations/${provider.system}`)
  }

  const integrateOath = async () => {
    try {
      setLoading(true)
      await api.oathPreconnect(provider.system).then((res: any) => (window.location.href = res?.target))
    } catch (e) {
      setLoading(false)
      Alert.error(e)
    }
  }

  return (
    <>
      <ZCard
        isLoading={isLoading}
        variant="form"
        alignItems="center"
        {...rest}
      >
        <Icon
          as={provider.icon}
          fontSize="125px"
          display="block"
          mx="auto"
        />
        <ZButton
          m={8}
          variant="outline"
          onClick={integrate}
          isDisabled={loading}
          size="md"
          colorScheme="atomicGray"
          color="atomicGray.400"
          borderColor="atomicGray.400"
          isLoading={loading}
          display="flex"
        >
          Connect to {provider.name}
        </ZButton>
      </ZCard>
      {showMarketo.isOpen && (
        <MarketoIntegration
          onComplete={integrateMarketo}
          {...showMarketo}
        />
      )}
    </>
  )
}
