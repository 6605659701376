import { Flex, Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiSkeleton,
  useAlerts,
  ZButton,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import type { FormEvent } from 'react'
import React from 'react'
import { useImmer } from 'use-immer'
import { BulkProductAccessUpdateDocument, Role } from '../../api'
import type { MiniTeam } from '../../hooks'
import { useBackgroundQueue } from '../../hooks'
import { AutoCompleteTeam, MenuUserRole } from '../AutoComplete'
import { SendUserEmailCheckbox } from './SendUserEmailCheckbox'
import type { SelectedUsers } from './UsersData'
import { UserSeatsAlert } from './UserSeatsAlert'

interface BulkUserAccountUpdateProps {
  isOpen: boolean
  onClose: () => void
  onComplete?: () => void
  users: SelectedUsers
}

interface State {
  search: string
  roles: Role[]
  team?: MiniTeam
  sendNotification?: boolean
}
export const BulkUserAccountUpdate: React.FC<BulkUserAccountUpdateProps> = ({ isOpen, onClose, onComplete, users }) => {
  const Alert = useAlerts()
  const { queue } = useBackgroundQueue()
  const confirmRemove = useDisclosure()

  const [state, setState] = useImmer<State>({
    search: '',
    roles: [Role.User],
  })

  // mutations
  const productAccessUpdate = useGraphqlMutation(BulkProductAccessUpdateDocument, {
    onSuccess: (data) => queue(data.bulkProductAccessUpdate),
  })

  const userCount = users.count
  const userWord = userCount > 1 ? 'Users' : 'User'

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (!userCount) return Alert.warning('No users were selected. Please try again.')
    if (!state.team) return Alert.warning('Please select a team')
    if (!state.roles.length) return confirmRemove.onOpen()
    await updateRoles()
  }

  const updateRoles = async () => {
    try {
      const roles = state.team?.teamId ? state.roles.filter((v: any) => v !== Role.Admin) : state.roles
      const variables: any = {
        filter: users.filter,
        data: { roles, teamId: state.team?.teamId },
        sendNotification: !!state.sendNotification,
      }
      await productAccessUpdate.mutateAsync(variables)
      Alert.success(`Updated ${userCount} ${userWord}`)
      onClose()
      onComplete && onComplete()
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <>
      <ZModal
        isOpen={isOpen}
        onClose={onClose}
        size="md"
      >
        <ZModalOverlay />
        <ZModalContent>
          <ZModalHeader>
            Updating Roles for {userCount} {userWord}
          </ZModalHeader>
          <ZModalCloseButton />
          <ZModalBody pb={8}>
            <UiSkeleton isLoaded={true}>
              <UserSeatsAlert
                mb={8}
                userCount={userCount}
              />

              <ZText
                mb={4}
                textAlign="center"
              >
                Please select the <strong>team</strong> and <strong>roles</strong> you would like set for each user.
              </ZText>

              <form onSubmit={handleSubmit}>
                <Stack
                  spacing={8}
                  mb={16}
                >
                  <MenuUserRole
                    h="40px"
                    w="full"
                    color="atomicGray.600"
                    fontSize="14px"
                    fontWeight="normal"
                    isDisabled={productAccessUpdate.isLoading}
                    value={state.roles}
                    onChange={(newRoles) => setState((draft) => void (draft.roles = newRoles))}
                    teamId={state.team?.teamId}
                    textAlign="left"
                    mt={4}
                  />
                  <AutoCompleteTeam
                    rootProps={{ ml: 4, mt: 4, minW: '250px' }}
                    inputValue={state.search}
                    onInputChange={(value: string) => setState((draft) => void (draft.search = value))}
                    value={state.team || null}
                    onChange={(team) => setState((draft) => void (draft.team = team || undefined))}
                  />
                  <SendUserEmailCheckbox
                    isChecked={!!state.sendNotification}
                    onChange={(e) => setState((draft) => void (draft.sendNotification = e.target.checked))}
                    justifyContent="flex-start"
                  />
                </Stack>
                <Flex
                  justifyContent="space-between"
                  mt={16}
                >
                  <ZButton
                    type="submit"
                    colorScheme="atomicBlue"
                    isDisabled={productAccessUpdate.isLoading}
                    minW="140px"
                  >
                    Update Roles
                  </ZButton>
                  <ZButton
                    colorScheme="atomicGray"
                    variant="ghost"
                    onClick={onClose}
                    minW="140px"
                  >
                    Cancel
                  </ZButton>
                </Flex>
              </form>
            </UiSkeleton>
          </ZModalBody>
        </ZModalContent>
      </ZModal>
      <ZModal
        size="sm"
        isOpen={confirmRemove.isOpen}
        onClose={confirmRemove.onClose}
      >
        <ZModalOverlay />
        <ZModalContent>
          <ZModalHeader>Confirm Remove Access</ZModalHeader>
          <ZModalCloseButton />
          <ZModalBody>
            <ZText>This action will remove these user from the team. You can always add them back a later time.</ZText>
            <ZText mt={4}>
              Are you sure you want to <strong>Remove</strong> these users from this team?
            </ZText>
          </ZModalBody>
          <ZModalButtons
            onConfirm={updateRoles}
            confirmProps={{
              colorScheme: 'atomicRed',
            }}
            onClose={confirmRemove.onClose}
          />
        </ZModalContent>
      </ZModal>
    </>
  )
}
