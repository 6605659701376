import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { CATEGORY } from 'components/Postals'
import { PostalSendMethod } from 'components/PostalSend/postalSendHelpers'
import { Permission } from 'lib/permissions'
import { useMemo } from 'react'
import type { Account, ApprovedPostal } from '../api'
import {
  GetAccountDocument,
  GetMarketplaceProductDocument,
  MagicEventStatus,
  PostalDeliveryEmailSetting,
  Status,
} from '../api'
import { useAcl } from './useAcl'
import { useCollectionPermissions } from './useCollectionPermissions'
import { useSession } from './useSession'

export const SENDABLE_EVENT_STATUSES = [MagicEventStatus.AcceptingInvites] as any

export const LOCKED_EVENT_STATUSES = [
  MagicEventStatus.PendingConfirmation,
  // MagicEventStatus.RegistrationClosed,
  MagicEventStatus.Complete,
  MagicEventStatus.Cancelled,
] as any

export const CANCELLABLE_EVENT_STATUSES = [
  MagicEventStatus.PendingConfirmation,
  MagicEventStatus.ConfirmedNeedsData,
  MagicEventStatus.ConfirmedBillingIssue,
  MagicEventStatus.AcceptingInvites,
  MagicEventStatus.RegistrationClosed,
]

export const getdefaultNonLinkSendMethod = ({
  account,
  canSendGiftEmail,
}: {
  account?: Account
  canSendGiftEmail: boolean
}) =>
  [PostalDeliveryEmailSetting.Always, PostalDeliveryEmailSetting.DefaultOn].includes(
    account?.postalDeliveryEmailSetting ?? PostalDeliveryEmailSetting.Always
  ) && canSendGiftEmail
    ? PostalSendMethod.Email
    : PostalSendMethod.Direct

export const usePostalPermissions = (postal?: ApprovedPostal | null) => {
  const { hasPermission, hasFeature } = useAcl()

  const collectionPermissions = useCollectionPermissions(postal)

  const getAccount = useGraphqlQuery(GetAccountDocument)
  const account = useMemo(() => getAccount.data?.getAccount, [getAccount.data?.getAccount])

  const hasVariants = useMemo(
    () => !!postal?.variants?.filter((v) => v.status === Status.Active)?.length,
    [postal?.variants]
  )

  const { session } = useSession()

  const hasTeamAdmin = hasFeature('teamAdmin')
  const hasLinks = hasFeature('links')

  const isEvent = useMemo(() => postal?.category === CATEGORY.Events, [postal?.category])
  const isCollection = useMemo(() => postal?.collection, [postal?.collection])

  // If we have a teamId on our session, we must be a team admin.
  // Therefore we can only edit this postal if we are the only team that uses it
  // if its a marketplace product, that does not apply
  const isTeamPostal = useMemo(() => {
    if (!postal) return false
    if (!hasTeamAdmin) return false
    if (!postal?.marketplaceProductId) return true
    return postal.teamIds?.length === 1 && postal.teamIds[0] === session.teamId
  }, [hasTeamAdmin, postal, session.teamId])

  const canCreate = useMemo(() => {
    if (!hasPermission('postals.create')) return false
    if (!!session.teamId) return hasTeamAdmin
    return true
  }, [hasPermission, hasTeamAdmin, session.teamId])

  const canContact = useMemo(() => {
    if (!postal) return false
    if (!isEvent) return false
    if (!hasPermission(Permission.EventManager)) return false
    return true
  }, [postal, isEvent, hasPermission])

  //permissions
  const canUpdate = useMemo(() => {
    if (!postal) return false
    if (isCollection) return collectionPermissions.canUpdate
    if (!hasPermission('postals.update')) return false
    if (isEvent && LOCKED_EVENT_STATUSES.includes(postal?.event?.status)) return false
    if (!!session.teamId) return isTeamPostal
    return true
  }, [postal, isCollection, collectionPermissions.canUpdate, hasPermission, isEvent, session.teamId, isTeamPostal])

  const canDelete = useMemo(() => {
    if (!postal) return false
    if (isCollection) return collectionPermissions.canDelete
    if (!hasPermission('postals.delete')) return false
    if (postal.category === CATEGORY.Events) return false
    if (!!session.teamId) return isTeamPostal
    return true
  }, [postal, isCollection, collectionPermissions.canDelete, hasPermission, session.teamId, isTeamPostal])

  const canSend = useMemo(() => {
    if (!postal) return false
    if (!hasVariants) return false
    if (!hasPermission('postals.send')) return false
    if (postal.status !== Status.Active) return false
    if (isEvent && !SENDABLE_EVENT_STATUSES.includes(postal.event?.status)) return false
    return true
  }, [hasPermission, hasVariants, isEvent, postal])

  const canSendGiftEmail = useMemo(() => {
    if (!canSend) return false
    if (postal?.category === CATEGORY.DirectMail) return false
    return true
  }, [canSend, postal?.category])

  // determines if recipient customization is required (need to know for direct send)
  const requiresRecipientCustomization = useMemo(() => {
    let requiresCustomization = false
    if (!isCollection) {
      // if it is not a collection, cannot send directly if recipient customization is required
      postal?.itemCustomizationList?.forEach((itemCustomization) => {
        if (itemCustomization.customizableByRecipient && itemCustomization.required) {
          requiresCustomization = true
        }
      })
    } else {
      // if it is a collection, we allow direct sending for any variant without recipient item customization
      let variantsWithoutCustomization = postal?.variants?.filter((v) => v.status === Status.Active)
      postal?.itemCustomizationList?.forEach((itemCustomization) => {
        if (itemCustomization.customizableByRecipient && itemCustomization.required) {
          variantsWithoutCustomization = variantsWithoutCustomization?.filter(
            (v) => v.id !== itemCustomization.variantId
          )
        }
      })
      requiresCustomization = variantsWithoutCustomization?.length === 0
    }
    return requiresCustomization
  }, [isCollection, postal])

  const canSendDirectly = useMemo(() => {
    if (!postal) return false
    if (!canSend) return false
    if (!account) return false
    if (requiresRecipientCustomization) return false

    return account.postalDeliveryEmailSetting !== PostalDeliveryEmailSetting.Always
  }, [account, canSend, postal, requiresRecipientCustomization])

  const canLink = useMemo(() => {
    if (!postal) return false
    if (!hasLinks) return false
    if (!canSend) return false
    // TODO: Revisit this -> should we also check for postal?.designTemplate in here? - Nate & Jonathan
    if (postal?.category === CATEGORY.DirectMail) return false
    return true
  }, [canSend, hasLinks, postal])

  const canCancel = useMemo(() => {
    if (!postal) return false
    if (!isEvent) return false
    if (!postal.event?.status) return false
    if (!CANCELLABLE_EVENT_STATUSES.includes(postal?.event?.status)) return false
    if (!hasPermission(Permission.EventManager)) return false
    return true
  }, [isEvent, postal, hasPermission])

  const defaultNonLinkSendMethod = useMemo(() => {
    return getdefaultNonLinkSendMethod({ account, canSendGiftEmail })
  }, [account, canSendGiftEmail])

  return {
    canCreate,
    canContact,
    canUpdate,
    canDelete,
    canSend,
    canSendGiftEmail,
    canSendDirectly,
    canLink,
    canCancel,
    defaultNonLinkSendMethod,
    requiresRecipientCustomization,
  }
}

// layer on top of usePostalPerms. Used for extra stuff we need to query the product for.
export const usePostalProductPermissions = (postal?: ApprovedPostal | null) => {
  const { hasBulkSend } = useBulkSendPermissions()

  const getProductById = useGraphqlQuery(
    GetMarketplaceProductDocument,
    { id: postal?.marketplaceProductId as string },
    { enabled: !!postal?.marketplaceProductId && !postal.collection }
  )
  const product = useMemo(
    () => getProductById?.data?.getMarketplaceProduct,
    [getProductById?.data?.getMarketplaceProduct]
  )

  const postalPerms = usePostalPermissions(postal)

  const canBulkSend = useMemo(() => {
    if (!hasBulkSend) return false
    if (!postalPerms.canSend) return false
    if (postal?.collection || postal?.category === CATEGORY.Collection) return false
    return product?.variants?.some((v) => v.fulfillmentPartnerList?.some((p) => p.bulkSendAvailable))
  }, [hasBulkSend, postal?.category, postal?.collection, postalPerms.canSend, product?.variants])

  return {
    ...postalPerms,
    canBulkSend,
  }
}

export const useBulkSendPermissions = () => {
  const { hasFeature } = useAcl()
  const hasBulkSendFeature = hasFeature('bulkSend')

  const getAccount = useGraphqlQuery(GetAccountDocument)
  const account = useMemo(() => getAccount.data?.getAccount, [getAccount.data?.getAccount])

  const hasBulkSend = useMemo(() => {
    if (!account?.sendSettings?.bulkSendEnabled) return false
    if (!hasBulkSendFeature) return false
    return true
  }, [account?.sendSettings?.bulkSendEnabled, hasBulkSendFeature])

  return { hasBulkSend }
}
