import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiLoading } from '@postal-io/postal-ui'
import { GetContactDocument, SearchContactsV2Document } from 'api'
import { useExtension } from 'hooks'
import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ExtContactCreate } from './ExtContactCreate'
import { ExtContactView } from './ExtContactView'

export const ExtContactEmail: React.FC = () => {
  const { emailAddress } = useParams() as any
  const { contactId, setContactId } = useExtension()

  const searchContactEmail = useGraphqlQuery(SearchContactsV2Document, {
    filter: { emailAddress: { eq: emailAddress } },
    limit: 1,
  })
  const firstContact = useMemo(
    () => searchContactEmail.data?.searchContactsV2?.searchableContacts?.[0],
    [searchContactEmail.data?.searchContactsV2?.searchableContacts]
  )

  const getContact = useGraphqlQuery(
    GetContactDocument,
    { id: firstContact?.id as string },
    { enabled: !!firstContact?.id, useErrorBoundary: false }
  )
  const contact = useMemo(() => {
    return getContact.data?.getContact
  }, [getContact.data?.getContact])

  useEffect(() => {
    if (contact?.id && contact.id !== contactId) setContactId(contact.id)
  }, [contact?.id, contactId, setContactId])

  const isLoading = searchContactEmail.isLoading || getContact.isLoading

  if (isLoading) return <UiLoading />

  if (!contact) return <ExtContactCreate />

  return <ExtContactView contact={contact} />
}
