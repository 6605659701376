import { Flex, IconButton, Tag, TagLabel } from '@chakra-ui/react'
import type { Column } from '@postal-io/postal-ui'
import { UiDateTime, UiMoney, ZLink } from '@postal-io/postal-ui'
import type { BillingAccount, Ledger } from 'api'
import { LedgerRelatedType } from 'api'
import { statusMapper } from 'lib'
import { MdOutlineDownload } from 'react-icons/md'
import { Link } from 'react-router-dom'

type LedgerRow = Ledger & {
  createDownload: (relatedId: string, billingAccount: BillingAccount) => void
  billingAccount: any
}
export const LEDGER_COLUMNS: Column[] = [
  {
    key: 'type',
    label: 'Type',
    render: ({ type }: LedgerRow) => {
      const { colorScheme, text } = statusMapper(type)
      return (
        <Tag
          colorScheme={colorScheme}
          variant="status"
        >
          <TagLabel>{text}</TagLabel>
        </Tag>
      )
    },
  },
  {
    key: 'amount',
    label: 'Amount',
    render: ({ amount }: LedgerRow) => {
      return <UiMoney cents={amount} />
    },
  },
  {
    key: 'date',
    label: 'Date',
    render: ({ created }: LedgerRow) => {
      return (
        <UiDateTime
          date={created?.dateTime}
          fallback=""
        />
      )
    },
  },
  {
    key: 'balance',
    label: 'Balance',
    render: ({ currentBalance }: LedgerRow) => {
      return <UiMoney cents={currentBalance} />
    },
  },
  {
    key: 'userId',
    label: 'User',
    render: ({ userId, userLink }: LedgerRow) => (
      <ZLink
        as={Link}
        to={`/users/${userId}`}
      >
        {userLink?.fullName ?? 'View User'}
      </ZLink>
    ),
  },
  {
    key: 'action',
    label: 'Action',
    render: ({ relatedId, relatedType, createDownload, billingAccount }: LedgerRow) => {
      return relatedType !== LedgerRelatedType.AddFundsIntent ? (
        <Flex justify="center">-</Flex>
      ) : (
        <IconButton
          aria-label="download receipt"
          variant="link"
          color="atomicGray.600"
          _hover={{ color: 'atomicGray.900' }}
          onClick={() => {
            createDownload(relatedId as string, billingAccount.id)
          }}
          pl={4}
          icon={
            <MdOutlineDownload
              color="inherit"
              size="24px"
            />
          }
        />
      )
    },
  },
]
