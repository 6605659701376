import { uniq } from 'lodash'
import type { User } from '../api'

// get the uniq teamIds from a user
export const getUserTeamIds = (user: User) => {
  const ids: string[] = []
  user?.productAccess?.forEach((access) => {
    if (access.teamId) ids.push(access.teamId)
  })
  return uniq(ids)
}
