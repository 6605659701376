import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Icon,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiCard, UiCardHeader, UiLoading, useAlerts, ZHeading, ZText } from '@postal-io/postal-ui'
import type { NamedProductAccess } from 'api'
import { NamedProductAccessListDocument, Role } from 'api'
import { approveOath, declineOath } from 'api/rest'
import { AccountPicker } from 'components/Common/AccountPicker'
import { useQueryParams, useSession } from 'hooks'
import { isEmpty, some } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MdOutlineCheckCircle } from 'react-icons/md'
import { useParams } from 'react-router-dom'

export const Authorization: React.FC = () => {
  const Alert = useAlerts()
  const { session } = useSession()
  const { params: queryParams } = useQueryParams() as any
  const routeParams = useParams() as any
  const [approveLoading, setApproveLoading] = useState(false)
  const [denyLoading, setDenyLoading] = useState(false)
  const cancelRef = useRef(null)

  const appName = routeParams.appName || queryParams.app_name || '3rd Party Application'
  const scopes = queryParams.scopes?.split(',') || []
  const roles = useMemo(() => queryParams.roles?.split(',') || [Role.Admin], [queryParams.roles])

  const [productAccessId, setProductAccessId] = useState<string>(session.productAccessId)

  const { data, isLoading } = useGraphqlQuery(NamedProductAccessListDocument)

  // we only want the right product and only if they have the correct role
  const productAccessList = useMemo(() => {
    return (
      (data?.namedProductAccessList?.filter((item) => {
        return (
          item?.productAccess?.product === process.env.REACT_APP_PRODUCT_ID &&
          !isEmpty(item?.productAccess?.roles) &&
          !isEmpty(item?.productAccess?.id) &&
          !isEmpty(item?.accountName) &&
          (roles?.length ? some(roles, (role) => item?.productAccess.roles?.includes(role)) : true)
        )
      }) as NamedProductAccess[]) || []
    )
  }, [data?.namedProductAccessList, roles])

  const canAuthorize = !!productAccessList.length

  const approve = async () => {
    if (!productAccessId) {
      return Alert.warning('Please select an account')
    }
    try {
      setApproveLoading(true)
      await approveOath(productAccessId, queryParams)
    } catch (err) {
      Alert.error(err)
    } finally {
      setApproveLoading(false)
    }
  }

  const deny = useCallback(async () => {
    try {
      setDenyLoading(true)
      await declineOath(productAccessId, queryParams)
    } catch (err) {
      Alert.error(err)
    } finally {
      setDenyLoading(false)
    }
  }, [Alert, queryParams, productAccessId])

  // salesloft requires that we handle a specific error code and automatically decline
  const shouldAutoDecline = useMemo(() => {
    return queryParams.error === 'access_denied'
  }, [queryParams.error])

  useEffect(() => {
    if (shouldAutoDecline) deny()
  }, [deny, shouldAutoDecline])

  if (isLoading || shouldAutoDecline) return <UiLoading />

  return (
    <AlertDialog
      size="3xl"
      isOpen
      onClose={deny}
      leastDestructiveRef={cancelRef}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Authorization Request</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <ZText
            color="atomicGray.600"
            mt={-4}
            textAlign="center"
          >
            <strong>{appName}</strong> is requesting permission to access your Postal account.
          </ZText>

          {!canAuthorize && (
            <UiCard
              mt={8}
              w="100%"
            >
              <UiCardHeader
                fontWeight="bold"
                mb={5}
              >
                Permission Denied
              </UiCardHeader>
              <ZText>
                We&apos;re sorry. You need to have <strong>{roles.join(' or ')}</strong> access at Postal in order to
                authorize a third party access to your account.
              </ZText>
              <Button
                mx="auto"
                colorScheme="atomicBlue"
                onClick={deny}
                mt={5}
              >
                Return to {appName}
              </Button>
            </UiCard>
          )}

          {canAuthorize && (
            <>
              {scopes.length > 0 && (
                <Box
                  mt={8}
                  w="100%"
                >
                  <ZHeading
                    as="h2"
                    fontSize="md"
                    fontWeight="bold"
                    mb={3}
                  >
                    Review Scopes
                  </ZHeading>
                  <ZText>The following scopes have been requested</ZText>
                  <UnorderedList
                    mt={4}
                    listStyleType="none"
                  >
                    {scopes.map((scope: string, index: number) => (
                      <ListItem
                        key={index}
                        display="flex"
                        alignItems="center"
                        ml={-4}
                        mb={2}
                      >
                        <Icon
                          as={MdOutlineCheckCircle}
                          fontSize="20px"
                          color="atomicBlue.400"
                          mr={2}
                        />
                        <ZText>{scope}</ZText>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              )}

              <Divider my={8} />

              <Box
                mt={4}
                w="100%"
                p={0}
              >
                <ZHeading
                  as="h3"
                  fontSize="md"
                  fontWeight="bold"
                >
                  Select an Account or Team
                </ZHeading>
                <ZText mt={3}>
                  Please select the Account or Team you would like to grant <strong>{appName}</strong> access to.
                </ZText>
                <AccountPicker
                  productAccessId={productAccessId}
                  productAccessList={productAccessList}
                  onSelect={(account) => setProductAccessId(account.productAccess.id)}
                  isLoading={isLoading}
                  headerText="Selected Account"
                />
              </Box>
              <Divider my={8} />
              <Box w="100%">
                <ZHeading
                  as="h3"
                  size="h6"
                  fontWeight="bold"
                  mb={2}
                >
                  Grant Permission
                </ZHeading>
                <ZText>
                  Please <strong>Approve</strong> or <strong>Decline</strong> access to your account. You will then be
                  redirected back to <strong>{appName}.</strong>
                </ZText>
              </Box>
            </>
          )}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            minW={32}
            onClick={approve}
            isDisabled={approveLoading}
            isLoading={approveLoading}
          >
            Approve
          </Button>
          minW={32}
          <Button
            ref={cancelRef}
            variant="ghost"
            colorScheme="atomicGray"
            minW={32}
            onClick={deny}
            isDisabled={denyLoading}
            isLoading={denyLoading}
          >
            Decline
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
