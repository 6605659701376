import { Flex } from '@chakra-ui/react'
import { ZSelect } from '@postal-io/postal-ui'
import { BudgetHistoryType } from 'api'
import { isEmpty } from 'lodash'
import React from 'react'
import { BUDGET_HISTORY_TYPE_MAP } from './BudgetHistoryData'

export interface BudgetHistoryFilterProps {
  filter: {
    type?: string
  }
  setFilter: (data: { key: string; value?: any }) => void
  isLoading?: boolean
}

export const BudgetHistoryFilter: React.FC<BudgetHistoryFilterProps> = ({ filter, setFilter }) => {
  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = target
    if (value === 'true') setFilter({ key: name, value: [true] })
    else if (value === 'false') setFilter({ key: name, value: [false, null] })
    else setFilter({ key: name, value: isEmpty(value) ? undefined : value })
  }

  return (
    <Flex justifyContent="flex-end">
      <ZSelect
        name="type"
        id="type"
        value={filter.type ?? ''}
        placeholder="Select a Type"
        onChange={handleInput}
        maxW="300px"
      >
        {Object.values(BudgetHistoryType).map((x) => (
          <option
            key={x}
            value={x}
          >
            {BUDGET_HISTORY_TYPE_MAP[x].text}
          </option>
        ))}
      </ZSelect>
    </Flex>
  )
}
