import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  sanitize,
  UiTabList,
  UiTabPanel,
  UiTabPanels,
  UiTabs,
  useAlerts,
  validatePassword,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZTab,
} from '@postal-io/postal-ui'
import type { UpdateSelfInput, UserAccount } from 'api'
import { ChangePasswordDocument, UpdateSelfDocument } from 'api'
import { AnalyticsEvent, useAnalyticsSend } from 'hooks'
import React, { useState } from 'react'
import { useImmer } from 'use-immer'
import type { PasswordState } from './UserProfileEditPassword'
import { UserProfileEditPassword } from './UserProfileEditPassword'
import { UserProfileEditUser } from './UserProfileEditUser'

interface UserProfileEditProps {
  user: UserAccount
  isOpen: boolean
  onClose: () => void
  onEdit?: () => void
}

export const UserProfileEdit: React.FC<UserProfileEditProps> = ({ user, isOpen, onClose, onEdit = () => {} }) => {
  const [form, setForm] = useImmer<UpdateSelfInput>({
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    emailAddress: user.emailAddress || '',
    phoneNumber: user.phoneNumber || '',
    title: user.title || '',
    meetingLink: user.meetingLink || '',
    emailSignatureHtml: user.emailSignatureHtml || '',
  })

  const [passwordForm, setPasswordForm] = useImmer<PasswordState>({
    currentPassword: '',
    newPassword1: '',
    newPassword2: '',
  })

  const [activeTab, setActiveTab] = useState(0)

  const isPasswordValid = validatePassword({
    password: passwordForm.newPassword1,
    password2: passwordForm.newPassword2,
    emailAddress: user.emailAddress,
  })
  const hasPasswordErrors = activeTab === 1 && !isPasswordValid
  const hasPasswordAuth = user.authTypes?.includes('password')

  const sendAnalytics = useAnalyticsSend()

  const updateSelf = useGraphqlMutation(UpdateSelfDocument, {
    onSuccess: () => sendAnalytics({ event: AnalyticsEvent.UserAccountUpdated }),
  })

  const updatePassword = useGraphqlMutation(ChangePasswordDocument, {
    onSuccess: () => sendAnalytics({ event: AnalyticsEvent.UserAccountUpdated }),
  })

  const Alert = useAlerts()

  const handleTabChange = (idx: number) => setActiveTab(idx)

  const handleUpdatePassword = async () => {
    const { currentPassword, newPassword1, newPassword2 } = passwordForm
    try {
      await updatePassword.mutateAsync({ data: { currentPassword, newPassword1, newPassword2 } })
      onEdit()
      onClose()
    } catch (err) {
      if (err.message.includes('UsernamePasswordException')) {
        Alert.error('Your current password was entered incorrectly.')
      } else if (err.message.includes('BadPasswordException')) {
        Alert.error('Please enter a new password that has not been used before.')
      } else {
        Alert.error(err)
      }
    }
  }

  const handleUpdateUser = async () => {
    const { emailSignatureHtml, ...rest } = form
    const sanitizedHTML = sanitize(emailSignatureHtml || '').trim()
    const data = { ...rest, emailSignatureHtml: sanitizedHTML }
    try {
      await updateSelf.mutateAsync({ data })
      Alert.success('Profile Updated')
      onEdit()
      onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    activeTab === 0 ? handleUpdateUser() : handleUpdatePassword()
  }

  return (
    <ZModal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
    >
      <ZModalOverlay />
      <ZModalContent>
        <form
          onSubmit={onSubmit}
          id="UserProfileEditForm"
        >
          <ZModalHeader>Update Your Profile</ZModalHeader>
          <ZModalCloseButton />
          <ZModalBody>
            <UiTabs onChange={handleTabChange}>
              <UiTabList mb={4}>
                <ZTab>My Profile</ZTab>
                <ZTab>Change Password</ZTab>
              </UiTabList>
              <UiTabPanels>
                <UiTabPanel>
                  {activeTab === 0 && (
                    <UserProfileEditUser
                      form={form}
                      setForm={setForm}
                    />
                  )}
                </UiTabPanel>
                <UiTabPanel>
                  {activeTab === 1 && (
                    <UserProfileEditPassword
                      setForm={setPasswordForm}
                      form={passwordForm}
                      emailAddress={user.emailAddress}
                      requireCurrentPassword={hasPasswordAuth}
                    />
                  )}
                </UiTabPanel>
              </UiTabPanels>
            </UiTabs>
          </ZModalBody>

          <ZModalButtons
            confirmProps={{
              type: 'submit',
            }}
            isConfirmLoading={updateSelf.isLoading || updatePassword.isLoading}
            isConfirmDisabled={updateSelf.isLoading || updatePassword.isLoading || hasPasswordErrors}
            confirmText={`Update ${activeTab === 0 ? 'Profile' : 'Password'}`}
            onClose={onClose}
          />
        </form>
      </ZModalContent>
    </ZModal>
  )
}
