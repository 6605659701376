import { Flex, Icon, Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiDialogProps } from '@postal-io/postal-ui'
import {
  PostalApp,
  UiIconMarketingABM,
  UiIconMarketingBrand,
  UiIconMarketingEngage,
  UiIconMarketingWarehouse,
  useAlerts,
  ZAppSwitcher,
  ZButton,
  ZText,
} from '@postal-io/postal-ui'
import type { Product } from 'api'
import { NamedProductAccessListDocument, Role } from 'api'
import { ZDialog } from 'components/Common/ZComponents'
import { useSession } from 'hooks'
import { useCallback, useMemo, useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import api from '../../api/rest'
import { useAcl } from '../../hooks/useAcl'

const URL_MAP = {
  [PostalApp.Engage]: '/',
  [PostalApp.Brand]: '/brand/',
  [PostalApp.ABM]: '/abm/',
  [PostalApp.Warehouse]: '/warehouse/',
}

export const AppSwitcher: React.FC<any> = () => {
  const { hasSubscription, hasRole } = useAcl()
  const { session } = useSession()
  const { accountId } = session

  const marketingModal = useDisclosure()
  const Alert = useAlerts()
  const [app, setApp] = useState<PostalApp>(PostalApp.Engage)

  const namedProductAccessListQuery = useGraphqlQuery(NamedProductAccessListDocument)
  const namedProductAccessList = useMemo(
    () => namedProductAccessListQuery.data?.namedProductAccessList,
    [namedProductAccessListQuery.data?.namedProductAccessList]
  )

  const switchApp = useCallback(
    async (app: PostalApp) => {
      const product = app as unknown as Product
      let productAccessId: string | undefined

      switch (app) {
        case PostalApp.Brand:
          if (!hasSubscription('POSTAL_BRAND_ACCESS') || !hasRole(Role.Admin)) break
          productAccessId = namedProductAccessList?.find((npa) => {
            return npa?.productAccess.product === product && !!npa.productAccess.roles?.length
          })?.productAccess.id
          break
        case PostalApp.ABM:
          if (!hasSubscription('POSTAL_ABM_ACCESS')) break
          productAccessId = namedProductAccessList?.find((npa) => {
            return (
              npa?.productAccess.product === product &&
              npa?.productAccess.accountId === accountId &&
              !!npa.productAccess.roles?.length
            )
          })?.productAccess.id
          break
        case PostalApp.Engage:
        case PostalApp.Warehouse:
          productAccessId = namedProductAccessList?.find((npa) => {
            return (
              npa?.productAccess.product === product &&
              npa?.productAccess.accountId === accountId &&
              !!npa.productAccess.roles?.length
            )
          })?.productAccess.id
      }

      if (productAccessId) {
        try {
          const res = await api.productAccessToken(productAccessId)
          const redirectUrl = `${URL_MAP[app]}sso/${res.sso_token}`
          window.open(redirectUrl, app)
        } catch (e) {
          Alert.error(`Error switching apps: ${e.message}`)
        }
      } else {
        setApp(app)
        marketingModal.onOpen()
      }
    },
    [Alert, accountId, hasRole, hasSubscription, marketingModal, namedProductAccessList]
  )

  return (
    <>
      <ZAppSwitcher
        // TODO: add warehouse in when ready
        apps={[PostalApp.Engage, PostalApp.Brand, PostalApp.ABM]}
        onSelect={switchApp}
        selectedApp={PostalApp.Engage}
      />
      <ProductMarketingModal
        {...marketingModal}
        app={app}
      />
    </>
  )
}

/**
 * product marketing modal - if the user doesn't have access yet
 */
interface ProductMarketingModalProps extends Omit<UiDialogProps, 'title' | 'children'> {
  app: PostalApp
}
const ProductMarketingModal: React.FC<ProductMarketingModalProps> = ({ app, ...rest }) => {
  const { image, header, tagline, highlights, learnMoreLinkUrl } = PRODUCT_MARKETING[app]

  return (
    <ZDialog
      size="2xl"
      title={header}
      titleProps={{ justifyContent: 'flex-start' }}
      {...rest}
    >
      <Flex flexDir="row">
        {image}
        <Flex
          flexDir="column"
          mx={8}
          mb={8}
          mr={-3}
        >
          <ZText
            fontSize="body-lg"
            fontWeight="bold"
            color="atomicGray.600"
            mb={5}
          >
            {tagline}
          </ZText>
          <Stack spacing={3}>
            {highlights.map((text) => (
              <ZText
                key={text}
                display="inline-flex"
                alignItems="center"
              >
                <Icon
                  as={MdCheckCircle}
                  color="atomicBlue.400"
                  fontSize="22px"
                  mr={2}
                />
                {text}
              </ZText>
            ))}
          </Stack>
          <ZButton
            colorScheme="atomicBlue"
            justifyContent="space-between"
            onClick={() => window.open(learnMoreLinkUrl, '_blank')}
            rightIcon={<>{`>`}</>}
            mt={10}
            mr={3}
          >
            Learn More
          </ZButton>
        </Flex>
      </Flex>
    </ZDialog>
  )
}

const PRODUCT_MARKETING = {
  [PostalApp.Engage]: {
    header: 'Get Postal Engage',
    highlights: [
      'CRM tracking & in-app ROI reporting',
      'Access to all integrations and triggers',
      'Unlimited integrations',
      'Address verification',
    ],
    image: (
      <UiIconMarketingEngage
        mt={-4}
        ml={-2}
        h="185px"
        w="auto"
      />
    ),
    tagline: 'Global gifting and experience marketplace',
    learnMoreLinkUrl: 'https://forms.gle/YJrWLzT1riWhdAeS9',
  },
  [PostalApp.Brand]: {
    header: 'Gain Access to Postal Brand',
    highlights: [
      'Includes out-of-the-box storefront themes',
      'Custom URL management',
      'Credit card checkout process',
      'Access to Paper Plane Agency',
    ],
    image: (
      <UiIconMarketingBrand
        mt={-3}
        ml={-2}
        h="180px"
        w="auto"
      />
    ),
    tagline: 'Public-facing swag storefront',
    learnMoreLinkUrl: 'https://forms.gle/TvZz5DyW9q8yuJo17',
  },
  [PostalApp.ABM]: {
    header: 'Postal ABM is now available!',
    highlights: [
      'Triggered campaigns with Salesforce data',
      'Comprehensive campaign dashboard',
      'Account-level ROI analytics',
      'Campaign & account data synced back to Salesforce',
    ],
    image: (
      <UiIconMarketingABM
        mt={-2}
        ml={-1}
        h="180px"
        w="auto"
      />
    ),
    tagline: 'Enterprise campaign management',
    learnMoreLinkUrl: 'https://forms.gle/tkzTKGmAA6FSjy5n7',
  },
  [PostalApp.Warehouse]: {
    header: 'Gain Access to the Warehousing App',
    highlights: ['Check inventory levels', 'See where inventory is located', 'Manage receiving orders and shipment'],
    image: (
      <UiIconMarketingWarehouse
        mt={-3}
        ml={-2}
        h="180px"
        w="auto"
      />
    ),
    tagline: 'View and manage your inventory',
    learnMoreLinkUrl:
      'https://docs.google.com/forms/u/1/d/e/1FAIpQLScUpxUG-JLM2zBlMik5FBHrl_cb7xlSrBBHbXg1bjTkIt3UNQ/viewform',
  },
}
