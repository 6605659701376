import { Box, Button, Circle, createIcon, Flex, Heading, Text } from '@chakra-ui/react'
import type { GraphqlError } from '@postal-io/postal-graphql'
import LogRocket from 'logrocket'
import type { PropsWithChildren } from 'react'
import { useEffect, useMemo } from 'react'
import type { FallbackProps } from 'react-error-boundary'
import { ErrorBoundary as ErrorBoundaryProvider } from 'react-error-boundary'
import { MdChevronRight, MdOutlineHome } from 'react-icons/md'
import { Form, useInRouterContext, useRouteError } from 'react-router-dom'

enum ErrorType {
  NotFoundError = 'NotFoundError',
  SubscriptionError = 'SubscriptionError',
  SessionError = 'SessionError',
  PermissionError = 'PermissionError',
  DataError = 'DataError',
  SystemError = 'SystemError',
}

interface ParsedError {
  type: ErrorType
  title: string
  message: string
  action: string
  shouldLogout: boolean
}

interface ErrorPageProps extends Omit<ParsedError, 'type'> {
  resetPath: string
}

export function RouteErrorElement({ resetPath = '/' }) {
  const error = useRouteError() as Error
  const props = useMemo(() => parseError(error), [error])

  useEffect(() => {
    console.error(error)
    handleError(error)
  }, [error])

  return (
    <ErrorPage
      {...props}
      resetPath={resetPath}
    />
  )
}

export function ErrorBoundary({ children }: PropsWithChildren) {
  return (
    <ErrorBoundaryProvider
      FallbackComponent={Fallback}
      onError={handleError}
    >
      {children}
    </ErrorBoundaryProvider>
  )
}

function Fallback({ error }: FallbackProps) {
  const props = useMemo(() => parseError(error), [error])
  const isInExtension = window.location.pathname.startsWith('/extension')

  return (
    <ErrorPage
      {...props}
      resetPath={isInExtension ? '/extension/' : '/'}
    />
  )
}

export function ErrorPage({ title, message, action, shouldLogout, resetPath }: ErrorPageProps) {
  const actionPath = shouldLogout ? '/logout' : resetPath
  const inRouter = useInRouterContext()
  // if we are in router context we can use Form, which will do an SPA transition
  const FormElement = inRouter ? Form : 'form'

  return (
    <>
      <Flex
        position="fixed"
        w="100%"
        h="100%"
        background="atomicGray.5"
      />
      <Flex
        w="100%"
        flexDirection="column"
        justifyContent="start"
        alignItems="center"
        p={8}
        pt={{ 'base': 12, '2xl': 24 }}
        zIndex={2}
      >
        <Circle
          position="absolute"
          borderWidth="31px"
          borderColor="atomicBlue.5"
          size={{ base: 'calc(100vw - 4rem)', md: '693px' }}
          textAlign="center"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          gap={{ base: 2, md: 8 }}
          padding={8}
        >
          <Flex pos="relative">
            <FlickIcon
              fontSize={{ base: '4rem', sm: '8rem' }}
              width={{ base: '35px', sm: '70px', md: '87.5px' }}
              position="absolute"
              top={{ base: -5, sm: -10 }}
              left={{ base: -8, sm: -16 }}
            />
            <Heading
              as="h1"
              fontWeight="normal"
              fontSize={{ base: '4rem', sm: '8rem', md: '10rem' }}
              lineHeight="1"
            >
              {title}
            </Heading>
          </Flex>

          <Text
            fontSize={{ base: 'body-lg' }}
            maxW={{ base: '100%', md: '450px' }}
          >
            {message}
          </Text>

          <Box
            as={FormElement}
            action={actionPath}
            method="get"
            display="contents"
          >
            <Button
              type="submit"
              mt={4}
              justifyContent="space-between"
              colorScheme="atomicBlue"
              leftIcon={<MdOutlineHome size="18px" />}
              rightIcon={<MdChevronRight size="18px" />}
              minW="170px"
              px={3}
            >
              {action}
            </Button>
          </Box>
        </Circle>
      </Flex>
    </>
  )
}

function parseError(err: Error) {
  const error = err as GraphqlError
  if (error.status === 401 || error.extensions?.httpCode === 401 || error.message === 'Session Expired') {
    return {
      type: ErrorType.SessionError,
      title: '401',
      message: 'Your session has expired.',
      shouldLogout: true,
      action: 'Login Again',
    }
  }
  if (error.status === 402 || error.extensions?.httpCode === 402 || error.message === 'Subscription Expired') {
    return {
      type: ErrorType.SubscriptionError,
      title: '402',
      message: 'Your subscription has expired.  Please contact our support team for help.',
      shouldLogout: true,
      action: 'Logout',
    }
  }
  if (error.status === 403 || error.extensions?.httpCode === 403) {
    return {
      type: ErrorType.PermissionError,
      title: '403',
      message: `You don't have access to this page.`,
      shouldLogout: false,
      action: 'Back to Home',
    }
  }
  if (error.status === 404 || error.extensions?.httpCode === 404) {
    return {
      type: ErrorType.NotFoundError,
      title: '404',
      message: `Sorry, we couldn't find the page you were looking for.`,
      shouldLogout: false,
      action: 'Back to Home',
    }
  }
  if (error.status === 400 || error.extensions?.httpCode === 400) {
    return {
      type: ErrorType.DataError,
      title: '400',
      message: `Oh no.  Unexpected data was sent to the server.  Please try again.`,
      shouldLogout: false,
      action: 'Back to Home',
    }
  }
  return {
    type: ErrorType.SystemError,
    title: '500',
    message: `Oh no.  An unexpected error has occurred.`,
    shouldLogout: false,
    action: 'Back to Home',
  }
}

function handleError(error: Error) {
  const parsedError = parseError(error)
  console.error(error)
  switch (parsedError.type) {
    case ErrorType.SessionError:
      break
    case ErrorType.SubscriptionError:
    case ErrorType.PermissionError:
    case ErrorType.SystemError:
      LogRocket.captureException(error)
      break
    case ErrorType.DataError:
    case ErrorType.NotFoundError:
      LogRocket.captureMessage(error.message)
  }
}

const FlickIcon = createIcon({
  viewBox: '0 0 69 84',
  path: (
    <svg
      width="69"
      height="84"
      viewBox="0 0 69 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.0078 19.97C62.6162 23.609 63.636 27.5619 65.8635 30.8827"
        stroke="#222222"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M55.2359 36.1635C49.727 27.9508 43.1759 20.3843 37.0055 12.6593"
        stroke="#222222"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M43.921 48.683C31.3417 39.952 17.2717 35.2082 2.9995 29.9261"
        stroke="#222222"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M36.0121 62.6118C28.2786 62.1808 20.4851 62.8613 12.7405 62.8198"
        stroke="#222222"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M34.9231 79.3596C32.2249 79.2174 29.2842 81.0826 26.6996 81.7953"
        stroke="#222222"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  ),
})
