import { Box, Flex, IconButton, Stack, StackDivider, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { useAlerts, ZButton, ZLink } from '@postal-io/postal-ui'
import type { AddressInput, Contact, ContactInput, PhoneMapInput, Tag } from 'api'
import { ContactType, PhoneType, UpsertContactDocument } from 'api'
import { ZBasicDialogButtons, ZDialog } from 'components/Common/ZComponents'
import { CONTACT_INVALIDATIONS, useBackgroundQueue, useMe } from 'hooks'
import React, { useState } from 'react'
import { MdArrowBack, MdOutlineControlPoint } from 'react-icons/md'
import { useImmer } from 'use-immer'
import { AddressForm } from './AddressForm'
import { ContactEditAddressBlock } from './ContactEditAddressBlock'
import { ContactForm } from './ContactForm'
import { useCanViewContactInfo } from './useCanViewContactInfo'

export interface ContactCreateEditProps {
  contact?: Contact
  addresses?: AddressInput[]
  initialView?: View
  isOpen: boolean
  onClose: () => void
  onComplete?: () => void
  selectedTabIndex?: number
}

type View = 'contact' | 'address'

export const ContactCreateEdit: React.FC<ContactCreateEditProps> = ({
  contact,
  addresses,
  initialView = 'contact',
  isOpen,
  onClose,
  onComplete,
  selectedTabIndex,
}) => {
  const Alert = useAlerts()
  const setPreferredAddress = useDisclosure()
  const { canViewAddresses } = useCanViewContactInfo()
  const [view, setView] = useState<View>(initialView)
  const addressList = addresses ?? contact?.addresses

  const { me } = useMe()

  const [form, setForm] = useImmer<ContactInput>({
    id: contact?.id,
    firstName: contact?.firstName || '',
    lastName: contact?.lastName || '',
    emailAddress: contact?.emailAddress,
    title: contact?.title,
    companyName: contact?.companyName,
    ownerId: me?.id,
    phones: contact?.phones || [
      {
        phoneNumber: '',
        type: PhoneType.Work,
      },
    ],
    addresses:
      (addressList as any[])?.map((address) => {
        delete address.statusReason
        delete address.systems
        delete address.uspsAddress
        return address
      }) || [],
    tags: contact?.tags || [],
    type: contact?.type || ContactType.Contact,
  })

  // address stuff
  const [addressRemoveIndex, setAddressRemoveIndex] = useState<number>()
  const [addressTabIndex, setAddressTabIndex] = useState<number | undefined>(selectedTabIndex)
  const removeAddress = useDisclosure()

  const removeAddressHandler = (idx: number) => {
    setAddressRemoveIndex(idx)
    removeAddress.onOpen()
  }

  const handleRemoveAddress = () => {
    const addressInputs = form.addresses || []
    const addresses = addressInputs.filter((_item, idx) => idx !== addressRemoveIndex)
    setForm((draft) => void (draft.addresses = addresses))
    setAddressTabIndex(undefined)
    removeAddress.onClose()
  }

  const preferredAddressHandler = (idx: number) => {
    setAddressTabIndex(idx)
    setPreferredAddress.onOpen()
  }

  const handleMakePreferred = () => {
    const addressInputs = form.addresses?.map((address, idx) => ({
      ...address,
      preferred: idx === addressTabIndex,
    }))
    setForm((draft: Record<string, any>) => void (draft.addresses = addressInputs))
    setPreferredAddress.onClose()
  }

  const { invalidate } = useBackgroundQueue()
  const createContact = useGraphqlMutation(UpsertContactDocument, {
    onSuccess: () => invalidate(CONTACT_INVALIDATIONS),
  })

  const handleEditAddress = (idx?: number) => {
    //reset local state - if there is no idx then this is a new address add
    setAddressTabIndex(undefined)
    if (typeof idx === 'number') {
      setView('address')
      setAddressTabIndex(idx)
    }
  }

  const handleBackFromAddress = () => {
    setView('contact')
    setAddressTabIndex(undefined)
  }

  /*
    we are setting the id the same as tag name because we don't have id and
    AutoCompleteTagCreatable is setup to handle this to compensate
  */
  const [tags, setTags] = useState(() => form?.tags?.map((tag) => ({ id: tag, name: tag } as Tag)) || [])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const dataCopy = { ...form }

    // filter and normalize phones - if editing a phoneList. It is possible to have a mix of PhoneMap and PhoneMapImput types
    dataCopy.phones =
      dataCopy?.phones
        ?.filter((phone) => phone?.phoneNumber)
        .map((phone: any) => {
          return { phoneNumber: phone?.phoneNumber, type: phone?.type } as PhoneMapInput
        }) || []

    // normalize tags
    dataCopy.tags = tags.map(({ name }) => name)

    //Check to ensure that a preferred address is choosen before updating
    const hasPreferred = form.addresses?.some((item) => item?.preferred)
    if (form.addresses?.length && !hasPreferred) {
      return Alert.warning('Please select a preferred address.')
    }

    try {
      await createContact.mutateAsync({ data: dataCopy as ContactInput })
      Alert.success(contact ? 'Contact Updated' : 'Contact Created')
      onClose()
      onComplete && onComplete()
    } catch (err) {
      Alert.error(err)
    }
  }
  return (
    <ZDialog
      colorScheme="gray"
      title={
        view === 'contact'
          ? contact
            ? 'Update  Contact'
            : 'Create Contact'
          : addressTabIndex !== undefined
          ? 'Update Address'
          : 'Add Address'
      }
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
    >
      {view === 'address' && (
        <>
          <AddressForm
            addresses={form.addresses as AddressInput[]}
            onClose={handleBackFromAddress}
            mt={4}
            selectedIndex={addressTabIndex}
            setFormAddresses={setForm}
          />
          <IconButton
            aria-label="back"
            borderColor="gray.300"
            borderRadius={20}
            color="gray.400"
            left={6}
            onClick={handleBackFromAddress}
            position="absolute"
            size="xs"
            top={7}
            variant="outline"
          >
            <MdArrowBack size={15} />
          </IconButton>
        </>
      )}
      {view === 'contact' && (
        <>
          <ContactForm
            form={form}
            handleSubmit={handleSubmit}
            setForm={setForm}
            setTags={setTags}
            tags={tags}
          />
          {!!form?.addresses?.filter(Boolean).length && (
            <Stack
              divider={<StackDivider borderColor="gray.200" />}
              mt={8}
              spacing={8}
            >
              {form?.addresses?.filter(Boolean).map((address, idx) => {
                return (
                  <ContactEditAddressBlock
                    key={JSON.stringify(address)}
                    address={address as AddressInput}
                    addressVisible={canViewAddresses || !contact}
                    idx={idx}
                    handleEditAddress={handleEditAddress}
                    removeAddressHandler={removeAddressHandler}
                    preferredAddressHandler={preferredAddressHandler}
                  />
                )
              })}
            </Stack>
          )}
          <Box
            alignItems="center"
            border="1px"
            borderColor="gray.200"
            borderRadius={3}
            display="flex"
            h="68px"
            justifyContent="center"
            mt={8}
            w="full"
          >
            <ZLink
              color="atomicBlue.400"
              display="flex"
              flexDirection="row"
              onClick={() => setView('address')}
            >
              <MdOutlineControlPoint
                size={20}
                style={{ marginRight: 5 }}
              />
              Add an Address
            </ZLink>
          </Box>
          <ZDialog
            isOpen={removeAddress.isOpen}
            onClose={removeAddress.onClose}
            size="lg"
            title="Remove Address"
          >
            <Text>
              Are you sure you want to <strong>Remove</strong> this address?
            </Text>
            <ZBasicDialogButtons
              onClose={removeAddress.onClose}
              onConfirm={handleRemoveAddress}
              confirmColorScheme="atomicRed"
              confirmText="Remove"
            />
          </ZDialog>
          <ZDialog
            size="lg"
            title="Set Default Address"
            isOpen={setPreferredAddress.isOpen}
            onClose={setPreferredAddress.onClose}
          >
            <Text>
              Are you sure you want to make this address <strong>Preferred</strong>?
            </Text>
            <ZBasicDialogButtons
              onClose={setPreferredAddress.onClose}
              onConfirm={handleMakePreferred}
              confirmColorScheme="atomicRed"
              confirmText="Confirm"
            />
          </ZDialog>
          <Flex
            justifyContent="space-between"
            mt={12}
          >
            <ZButton
              bgColor="blue.400"
              form="edit-contact-form"
              isDisabled={createContact.isLoading}
              isLoading={createContact.isLoading}
              justifyContent="center"
              type="submit"
              w="167px"
            >
              Save
            </ZButton>
          </Flex>
        </>
      )}
    </ZDialog>
  )
}
