import { Fade } from '@chakra-ui/react'
import type { ZProductCardProps } from '@postal-io/postal-ui'
import {
  ZProductCard,
  ZProductCardBody,
  ZProductCardContent,
  ZProductCardImage,
  ZProductCardImageContainer,
} from '@postal-io/postal-ui'
import { ZLink } from 'components/Common/ZComponents'
import React, { useCallback } from 'react'
import type { MarketplaceProduct } from '../../api'
import { useAssets } from '../../hooks'
import { usePostalImages } from '../../hooks/usePostalImages'
import { EventsProductPriceRange } from './EventsPriceRange'

interface EventsCardProductV2Props extends Omit<ZProductCardProps, 'onSelect'> {
  product: MarketplaceProduct
  onSelect?: (item: MarketplaceProduct) => any
}

export const EventsCardProduct: React.FC<EventsCardProductV2Props> = ({ product, onSelect, ...rest }) => {
  const { assetUrlSrc } = useAssets()
  const image = usePostalImages(product)
  const srcProps = assetUrlSrc(image?.[0]?.url, { w: 350, h: 350, fit: 'clip' })

  const handleSelect = useCallback(() => onSelect?.(product), [product, onSelect])

  return (
    <Fade in>
      <ZProductCard
        size="tall"
        hoverStyle="lift"
        {...rest}
      >
        <ZProductCardContent>
          <ZProductCardImageContainer onClick={handleSelect}>
            <ZProductCardImage {...srcProps} />
          </ZProductCardImageContainer>
          <ZProductCardBody>
            <ZLink
              mt={2}
              color="atomicGray.700"
              flexDir="column"
              alignItems="left"
              lineHeight="short"
              fontSize="inherit"
              maxW="325px"
              noOfLines={1}
              onClick={handleSelect}
            >
              {product.name}
            </ZLink>
            <EventsProductPriceRange product={product} />
          </ZProductCardBody>
        </ZProductCardContent>
      </ZProductCard>
    </Fade>
  )
}
