const MAX_LENGTH = 30

/**
 *
 * Takes in an array of strings and produces a list of items truncated with 'x more' if the list gets too long.
 *
 * Example input: ['item 1', 'item 2', 'item 3', 'item 4', 'item 5'] & maxLength 30 characters
 * Example output: 'item 1, item 2, item 3, 2 more'
 *
 * Example input 2: ['Super long ass item name yoooooooooooo']
 * Example output 2: '1 item'
 */

export function listItemsWithMaxLength(items: string[], maxLength: number = MAX_LENGTH) {
  const actualMaxLength = maxLength - 'x more'.length

  return items.reduce((acc, n, index, array) => {
    // we've already terminated it => max length already
    if (acc.match(/( more)|( items?)$/)) return acc

    const newAcc = !acc ? n : `${acc}, ${n}`
    if (newAcc.length > actualMaxLength) {
      return !index ? `${array.length} ${array.length > 1 ? 'items' : 'item'}` : `${acc}, ${array.length - index} more`
    }
    return newAcc
  }, '')
}
