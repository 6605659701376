import type { FlexProps } from '@chakra-ui/react'
import { Flex, useClipboard } from '@chakra-ui/react'
import { UiLink, useAlerts } from '@postal-io/postal-ui'
import React from 'react'
import type { IntegrationTrigger } from '../../api'
import { TriggerDetailsRow } from './TriggerDetailsRow'

interface TriggerDetailsProgramProps extends FlexProps {
  trigger?: IntegrationTrigger
}
export const TriggerDetailsProgram: React.FC<TriggerDetailsProgramProps> = ({ trigger, ...rest }) => {
  const programId = trigger?.attribution?.marketoProgramId
  const Alert = useAlerts()

  const { onCopy, setValue: setValueToCopy } = useClipboard('')

  const copyTriggerId = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setValueToCopy(trigger?.id ?? '')
    setTimeout(onCopy)
    Alert.success('Trigger ID copied to clipboard')
  }

  if (!programId) return null

  return (
    <Flex
      justifyContent="space-between"
      {...rest}
    >
      <TriggerDetailsRow label="Program ID">{programId}</TriggerDetailsRow>
      <UiLink
        float="right"
        onClick={copyTriggerId}
      >
        Copy Trigger ID
      </UiLink>
    </Flex>
  )
}
