import type { CurveFactory } from 'd3-shape'
import { curveLinear } from 'd3-shape'
import { useMemo } from 'react'
import type { AxisOptions, Series, SeriesFocusStatus, SeriesStyles } from 'react-charts'

export const Curve: { [k: string]: CurveFactory } = {
  Linear: curveLinear,
}

const options = {
  elementType: ['line', 'area', 'bar'],
  primaryAxisType: ['linear', 'time', 'log', 'band', 'localTime', undefined],
  primaryAxisKey: ['date', 'userName'],
  secondaryAxisType: ['linear', 'time', 'log', 'band'],
  secondaryAxesKey: ['cost', 'meetings', 'sends', 'accepted', 'userName'],
  primaryAxisPosition: ['top', 'left', 'right', 'bottom'],
  secondaryAxisPosition: ['top', 'left', 'right', 'bottom'],
  curve: [Curve.Linear],
  secondaryAxisStack: [true, false],
  primaryAxisShow: [true, false],
  secondaryAxisShow: [true, false],
  interactionMode: ['primary', 'closest'],
  tooltipGroupingMode: ['single', 'primary', 'secondary', 'series'],
  tooltipAnchor: [
    'closest',
    'top',
    'bottom',
    'left',
    'right',
    'center',
    'gridTop',
    'gridBottom',
    'gridLeft',
    'gridRight',
    'gridCenter',
    'pointer',
  ],
  tooltipAlign: [
    'auto',
    'top',
    'bottom',
    'left',
    'right',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
    'center',
  ],
  snapCursor: [true, false],
} as const

type DataType = 'time' | 'ordinal' | 'linear'
type ElementType = (typeof options)['elementType'][number]
type PrimaryAxisType = (typeof options)['primaryAxisType'][number]
type PrimaryAxisKey = (typeof options)['primaryAxisKey'][number]
type SecondaryAxisType = (typeof options)['secondaryAxisType'][number]
type SecondaryAxesKey = (typeof options)['secondaryAxesKey'][number]
type PrimaryAxisPosition = (typeof options)['primaryAxisPosition'][number]
type SecondaryAxisPosition = (typeof options)['secondaryAxisPosition'][number]
type CurveType = (typeof options)['curve'][number]

export const useChart = ({
  label,
  rows,
  elementType = 'line',
  primaryAxisType = 'time',
  primaryAxisKey = 'date',
  secondaryAxisType = 'linear',
  secondaryAxesKey = 'cost',
  primaryAxisPosition = 'bottom',
  secondaryAxisPosition = 'left',
  curve,
  getSeriesStyle,
}: {
  label?: string
  rows?: any[] | null
  dataType?: DataType
  elementType?: ElementType
  primaryAxisType?: PrimaryAxisType
  primaryAxisKey?: PrimaryAxisKey
  secondaryAxisType?: SecondaryAxisType
  secondaryAxesKey?: SecondaryAxesKey
  primaryAxisPosition?: PrimaryAxisPosition
  secondaryAxisPosition?: SecondaryAxisPosition
  curve?: CurveType

  getSeriesStyle:
    | ((
        series: Series<{
          date: Date
          cost: number
        }>,
        status: SeriesFocusStatus
      ) => SeriesStyles)
    | undefined
}) => {
  const data = useMemo(() => {
    return [
      {
        label,
        data: rows ?? [],
      },
    ]
  }, [label, rows])

  const primaryAxis = useMemo<AxisOptions<(typeof data)[number]['data'][number]>>(
    () => ({
      scaleType: primaryAxisType,
      position: primaryAxisPosition,
      getValue: (datum) => datum?.[primaryAxisKey],
    }),
    [primaryAxisKey, primaryAxisPosition, primaryAxisType]
  )

  const secondaryAxes = useMemo<AxisOptions<(typeof data)[number]['data'][number]>[]>(
    () => [
      {
        scaleType: secondaryAxisType,
        position: secondaryAxisPosition,
        getValue: (datum) => datum?.[secondaryAxesKey] as any,
        curve,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [curve, secondaryAxesKey, secondaryAxisPosition, secondaryAxisType]
  )

  return { data, primaryAxis, secondaryAxes, elementType, getSeriesStyle }
}
