import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import type { GraphqlFilterState } from '@postal-io/postal-ui'
import {
  GraphqlFilterTransform,
  internalProgressBarProps,
  internalTableProps,
  UiSSDataTable,
  useAlertError,
  useGraphqlFilter,
  ZButton,
  ZCard,
  ZCardBody,
} from '@postal-io/postal-ui'
import type { SearchPostalFulfillmentsQueryVariables } from 'api'
import { SearchPostalFulfillmentsDocument } from 'api'
import { useAcl } from 'hooks'
import { cloneDeep, set } from 'lodash'
import { useMemo } from 'react'
import { ExportButton } from './ExportButton'
import { columnsV2 } from './OrdersData'
import { OrdersFilter } from './OrdersFilter'

const LIMIT = 100

const transforms = {
  approvedPostalId: GraphqlFilterTransform.Equal,
  userId: GraphqlFilterTransform.Equal,
  teamId: GraphqlFilterTransform.Equal,
  created: GraphqlFilterTransform.Between,
  contactId: GraphqlFilterTransform.Equal,
  campaignId: GraphqlFilterTransform.Equal,
  magicLinkId: GraphqlFilterTransform.Equal,
  status: GraphqlFilterTransform.In,
}

const initialState = { orderBy: { key: 'created', direction: 'desc' } } as GraphqlFilterState
const staticVariables = { limit: LIMIT }

export const OrdersTable = () => {
  const { hasFeature } = useAcl()
  const hasMagicLink = hasFeature('links')
  const hasSendAs = hasFeature('sendAs')

  const filteredColumns = useMemo(() => {
    return columnsV2
      .filter((c) => hasMagicLink || c.key !== 'magicLinkName')
      .filter((c) => hasSendAs || c.key !== 'sendAs.fullName')
  }, [hasMagicLink, hasSendAs])

  const graphqlFilter = useGraphqlFilter<SearchPostalFulfillmentsQueryVariables>({
    transforms,
    initialState,
    staticVariables,
    debounce: 500,
  })

  // if we selected an account, it will be present on the meta state
  // here we can override this query to look for only account level
  // orders by searching for null in teamId
  const variables = useMemo(() => {
    if (graphqlFilter.meta?.teamId?.kind !== 'ACCOUNT') return graphqlFilter.variables
    const newVariables = cloneDeep(graphqlFilter.variables)
    set(newVariables, 'filter.teamId.eq', null)
    return newVariables
  }, [graphqlFilter.meta, graphqlFilter.variables])

  // search postal fulfillments
  const { mergedData, isFetching, error, hasNextPage, fetchNextPage } = useGraphqlInfiniteQuery(
    SearchPostalFulfillmentsDocument,
    variables
  )

  const postalFulfillments = useMemo(
    () => mergedData?.searchPostalFulfillments || [],
    [mergedData?.searchPostalFulfillments]
  )

  useAlertError(error)

  return (
    <>
      <OrdersFilter
        {...graphqlFilter}
        right={<ExportButton filter={graphqlFilter.variables.filter} />}
      />
      <ZCard variant="form">
        <ZCardBody p={8}>
          <UiSSDataTable
            isLoading={isFetching}
            columns={filteredColumns}
            rows={postalFulfillments}
            rowKey="id"
            variant="list"
            filter={graphqlFilter.variables.filter}
            fetchMore={fetchNextPage}
            hasMore={hasNextPage}
            orderBy={graphqlFilter.orderBy}
            onOrderBy={graphqlFilter.setOrderBy}
            tableProps={internalTableProps}
            progressBarProps={internalProgressBarProps}
            HeaderButton={ZButton}
          />
        </ZCardBody>
      </ZCard>
    </>
  )
}
