import type { ModalProps } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { UiSkeleton, useAlerts } from '@postal-io/postal-ui'
import { ZBasicDialogButtons, ZDialog } from 'components/Common/ZComponents'
import React from 'react'
import { useImmer } from 'use-immer'
import type {
  ApprovedPostal,
  ApprovedPostalInputItem,
  ApprovedProductVariant,
  MarketplaceProduct,
  ProductVariant,
} from '../../api'
import { Status, UpdateApprovedPostalDocument } from '../../api'
import { POSTAL_INVALIDATIONS, useApprovedPostalVersion, useBackgroundQueue } from '../../hooks'
import { CollectionItemOptions } from './CollectionItemOptions'
import { useCollectionItemPossibleVariants } from './useCollections'
import type { CollectionItem } from './utils'

interface CollectionItemEditV2Props extends Omit<ModalProps, 'children'> {
  collection: ApprovedPostal
  item: CollectionItem
  onEdit: (item: ApprovedPostal) => void
}

interface CollectionItemEditV2State {
  variants: ApprovedProductVariant[] | ProductVariant[]
}

export const CollectionItemEdit: React.FC<CollectionItemEditV2Props> = ({
  item,
  collection,
  isOpen,
  onClose,
  onEdit,
}) => {
  const transform = useApprovedPostalVersion()
  const Alert = useAlerts()
  const [state, setState] = useImmer<CollectionItemEditV2State>({
    variants: item.variants ?? [],
  })

  const { approvedItem, marketplaceItem, isLoading } = useCollectionItemPossibleVariants({ item })

  const { queue, invalidate } = useBackgroundQueue()
  const updateApprovedPostal = useGraphqlMutation(UpdateApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.updateApprovedPostal.previewGenerationTask)
      invalidate(POSTAL_INVALIDATIONS)
    },
  })

  const updateCollection = async () => {
    if (!state.variants.length) return Alert.warning('Please select at least one option.')

    const newItems: ApprovedPostalInputItem[] = []

    // add the existing variants that are active and are not related to the this item
    collection.variants
      ?.filter((v) => v.status === Status.Active && v.marketplaceProductId !== item.marketplaceProductId)
      ?.forEach((v) =>
        newItems.push({
          marketplaceProductId: v.marketplaceProductId as string,
          approvedPostalId: v.approvedPostalId,
          variant: v.id,
        })
      )

    // add in all the new chosen variants for the this item
    state.variants.forEach((variant: any) => {
      newItems.push({
        marketplaceProductId: item.marketplaceProductId,
        approvedPostalId: variant.approvedPostalId,
        variant: variant.id,
      })
    })

    try {
      const res = await updateApprovedPostal.mutateAsync({
        id: collection.id,
        data: transform(collection.category, { items: newItems }),
      })
      Alert.success('Collection Updated')
      onEdit(res.updateApprovedPostal.postal)
      onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleSelectOptions = (variants: typeof state.variants) => {
    setState((draft) => {
      draft.variants = variants
    })
  }

  return (
    <ZDialog
      size="full"
      title="Select Options"
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiSkeleton
        isLoaded={!isLoading}
        w="100%"
        h="100%"
      >
        {approvedItem ? (
          <CollectionItemOptions<ApprovedPostal, ApprovedProductVariant>
            item={approvedItem}
            onSelect={handleSelectOptions}
            selectedVariants={state.variants as ApprovedProductVariant[]}
          />
        ) : marketplaceItem ? (
          <CollectionItemOptions<MarketplaceProduct, ProductVariant>
            item={marketplaceItem}
            onSelect={handleSelectOptions}
            selectedVariants={state.variants as ProductVariant[]}
          />
        ) : null}
      </UiSkeleton>
      <ZBasicDialogButtons
        confirmProps={{ isLoading: updateApprovedPostal.isLoading, isDisabled: updateApprovedPostal.isLoading }}
        onConfirm={updateCollection}
        confirmText="Save Options"
        onClose={onClose}
      />
    </ZDialog>
  )
}
