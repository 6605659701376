import React, { forwardRef } from 'react'
import { Rect } from 'react-konva'

export interface ReservedAreaProps {
  element: any
}

export const ReservedArea: React.FC<ReservedAreaProps> = forwardRef(({ element, ...rest }, ref: any) => {
  const { location, settings, hidden } = element
  const { x, y, width, height } = location
  const { color = 'white', thicknessPixels = 1 } = settings || {}

  return (
    <Rect
      opacity={0.8}
      fill={color}
      fillEnabled={true}
      listening={false}
      hitStrokeWidth={0}
      ref={ref}
      x={x}
      y={y}
      stroke={color}
      strokeWidth={thicknessPixels}
      width={width}
      height={height}
      visible={!hidden}
      {...rest}
    />
  )
})
