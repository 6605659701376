/* eslint-disable react/prop-types */
import type { ProductVariant } from 'api'
import { CATEGORY } from 'components/Postals'
import React from 'react'
import { PostalVariantCard } from './PostalVariantCard'

export interface MarketplaceProductVariantCardProps {
  variant: ProductVariant
  currency?: string
  isSelected?: boolean
  category?: string
  onClick?: (variant: ProductVariant) => void
}
export const MarketplaceProductVariantCard: React.FC<MarketplaceProductVariantCardProps> = ({
  variant,
  isSelected,
  currency,
  onClick,
  category,
  ...rest
}) => {
  const [fulfillmentMap] = variant.fulfillmentPartnerList || []
  const [imageUrl] = variant.imageUrls || []
  const inventory = fulfillmentMap?.inventory?.available
  const handleClick = onClick ? () => onClick(variant) : undefined
  const shippingOptionsPrices =
    fulfillmentMap?.shippingOptions?.map((so) => so.price).sort((priceA, priceB) => priceA - priceB) ?? []
  const shippingEstimate = shippingOptionsPrices[0]

  return (
    <PostalVariantCard
      currency={currency}
      name={variant.variantName}
      description={variant.description}
      imageUrl={imageUrl}
      hideShipping={category === CATEGORY.Events}
      displayPrice={variant.displayPrice || 0}
      shippingEstimate={shippingEstimate}
      flatFees={variant.flatFees || 0}
      inventory={inventory}
      isSelected={isSelected}
      onClick={handleClick}
      {...rest}
    />
  )
}
