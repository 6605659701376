import type { FlexProps } from '@chakra-ui/react'
import { Box, FormControl, Grid, SimpleGrid, Stack } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiChangeEvent } from '@postal-io/postal-ui'
import {
  SelectTypeaheadStylesV2,
  UiSidePanel,
  UiSidePanelBlock,
  UiToggle,
  ZCurrencyCheckbox,
  ZCurrencyCheckboxGroup,
  ZInputMoney,
  humanize,
  useAlertError,
} from '@postal-io/postal-ui'
import { AutoCompleteTeams } from 'components/AutoComplete'
import { AutoCompleteMultiSelect, AutoCompleteSelect } from 'components/AutoComplete/AutoCompleteSelect'
import { Owner } from 'components/Collections/data'
import { CATEGORY } from 'components/Postals'
import { dequal } from 'dequal'
import type { UsePostalFiltersV2Response } from 'hooks'
import { useAcl } from 'hooks/useAcl'
import { isArray, isNumber } from 'lodash'
import { orderBy } from 'natural-orderby'
import type { ReactNode } from 'react'
import React, { useMemo } from 'react'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import { SidePanelHeader } from '.'
import {
  FulfillmentType,
  GetApprovedPostalBrandsCategoriesDocument,
  GetApprovedPostalFiltersDocument,
  GetBrandsCategoriesDocument,
  GetMarketplaceFiltersDocument,
  Role,
  Status,
} from '../../api'

/*
  TODO:
  - approvedCurrencies is really hard to follow
    - can we add restrictCurrency to searches?
    - pass restrictCurrency in here instead of allowed currencies?
    - when is it special?
      - Playbook SelectPostal - to match the current currency
      - CollectionItemSelect - to match the current currency
      - PostalSend - this should restrict?
    - what is different
      - admin can see all sometimes
      - everyone else comes from me.currencyList
*/

interface SearchFilter {
  name: string
  options: string[]
}

const filterAcl: Record<string, Record<string, string>> = {
  useCases: { feature: 'premium' },
  currency: { feature: 'internationalization' },
}

const RADIO_FILTERS = ['type']
const CUSTOM_FILTERS = ['currency']
const NON_HUMANIZED_FILTERS = ['currency']
const COLLECTIONS_FILTERS = ['currency', 'type']

// these filters are not cleaned up based on the available filters returned from the backend
const SPECIAL_FILTERS = [
  'status',
  'owner',
  'q',
  'price',
  'eventAvailabilityDates',
  'teamIds',
  'brandName',
  'subCategory',
  'fulfillmentPartnerName',
  'outOfStock',
  'draft',
  'favorites',
]

export interface SidePanelFilterProps extends FlexProps {
  /**
   * Allow the user to see/toggle the status filter
   */
  showDraft?: boolean
  /**
   * Form filters that will be translated into graphql variables
   */
  filters: UsePostalFiltersV2Response['filters']
  /**
   * Marketplace or ApprovedPostal
   */
  filterType: 'Marketplace' | 'ApprovedPostal' | 'Collections'
  /**
   * An array of filter key names that should be excluded from view
   */
  excludeFilters?: string[]
  /**
   * An array of filter key names that will be included in the view, excluding the rest.
   */
  includeFilters?: string[]
  /**
   * An array of category names that should be excluded from the choices
   */
  excludeCategories?: string[]
  /**
   * If this sidepanel should be restricted to a single category
   */
  restrictCategory?: string
  /**
   * Callback to update the value of a filter
   */
  onUpdate: (name: string, value: any, delay?: number) => void
  /**
   * Allowed currencies to be shown in the select
   */
  approvedCurrencies?: string[] | null
  /**
   * Block that appears above the filters
   */
  topBlock?: ReactNode
  /**
   * Block that appears below the filters
   */
  bottomBlock?: ReactNode
}

export const SidePanelFilter: React.FC<SidePanelFilterProps> = ({
  showDraft,
  filters,
  filterType,
  excludeFilters,
  includeFilters,
  excludeCategories,
  onUpdate,
  restrictCategory,
  approvedCurrencies,
  topBlock,
  bottomBlock,
  ...rest
}) => {
  const categories = useMemo(
    () => (restrictCategory ? [restrictCategory] : filters.categories || []),
    [filters.categories, restrictCategory]
  )
  const isEvent = useMemo(() => categories.length === 1 && categories.includes(CATEGORY.Events), [categories])
  const isMarketplace = useMemo(() => filterType === 'Marketplace', [filterType])
  const isApprovedPostal = useMemo(() => filterType === 'ApprovedPostal', [filterType])
  const isCollections = useMemo(() => filterType === 'Collections', [filterType])

  const { aclCheck } = useAcl()

  const canSelectTeams = useMemo(
    () => aclCheck({ module: 'teams.create', role: Role.Admin }) && (isApprovedPostal || isCollections),
    [aclCheck, isApprovedPostal, isCollections]
  )

  const handleSortFilter = (a: SearchFilter, b: SearchFilter) => {
    const nameA = a.name.toLowerCase()
    const nameB = b.name.toLowerCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  }

  const getBrandsCategories = useGraphqlQuery(GetBrandsCategoriesDocument, undefined, {
    enabled: isMarketplace && !restrictCategory,
  })
  useAlertError(getBrandsCategories.error)

  const getApprovedPostalBrandsCategories = useGraphqlQuery(GetApprovedPostalBrandsCategoriesDocument, undefined, {
    enabled: (isApprovedPostal || isCollections) && !restrictCategory,
  })
  useAlertError(getApprovedPostalBrandsCategories.error)

  const brandCategories = useMemo(() => {
    return isMarketplace
      ? getBrandsCategories.data?.getBrandsCategories || {}
      : getApprovedPostalBrandsCategories.data?.getApprovedPostalBrandsCategories || {}
  }, [
    getApprovedPostalBrandsCategories.data?.getApprovedPostalBrandsCategories,
    getBrandsCategories.data?.getBrandsCategories,
    isMarketplace,
  ])

  // Events need to see the Event statuses instead
  const canSeeDraft = useMemo(() => {
    if (!showDraft) return false
    if (aclCheck({ module: 'postals.create' })) return true
    if (isCollections) return filters.owner === Owner.Me
    return false
  }, [aclCheck, filters.owner, isCollections, showDraft])

  // Events need to send statuses to the backend or the eventStatus is not populated
  // if this is an admin, lets see all of them
  const approvedPostalFilters = useMemo(() => {
    const statuses = isEvent
      ? canSeeDraft
        ? [Status.Active, Status.Disabled]
        : [Status.Active]
      : filters.status || undefined
    return { categories, statuses }
  }, [categories, filters.status, isEvent, canSeeDraft])

  const getApprovedPostalFilters = useGraphqlQuery(GetApprovedPostalFiltersDocument, approvedPostalFilters, {
    enabled: (isApprovedPostal || isCollections) && !!categories.length,
  })
  useAlertError(getApprovedPostalFilters.error)

  const getMarketplaceFilters = useGraphqlQuery(
    GetMarketplaceFiltersDocument,
    { categories },
    { enabled: isMarketplace && !!categories.length }
  )
  useAlertError(getApprovedPostalFilters.error)

  const isLoading = useMemo(
    () =>
      getBrandsCategories.isLoading ||
      getApprovedPostalBrandsCategories.isLoading ||
      getApprovedPostalFilters.isLoading ||
      getMarketplaceFilters.isLoading,
    [
      getApprovedPostalBrandsCategories.isLoading,
      getApprovedPostalFilters.isLoading,
      getBrandsCategories.isLoading,
      getMarketplaceFilters.isLoading,
    ]
  )

  // here we build the available filters based on the data coming in
  const availableFilters = useMemo(() => {
    const topFilters = brandCategories
    const catFilters = isMarketplace
      ? getMarketplaceFilters.data?.getMarketplaceFilters || []
      : getApprovedPostalFilters.data?.getApprovedPostalFilters || []
    const available: SearchFilter[] = []

    // if we aren't restricted (Events, Collection), then add in the category select options
    if (!restrictCategory) {
      const categories = topFilters.categories || []
      available.push({
        name: 'categories',
        options: orderBy(categories.map((c) => c.name).filter((c) => !excludeCategories?.includes(c)) || []),
      })
    }

    // if we don't have a category, then push the rest of the toplevel data
    if (!categories.length) {
      available.push({
        name: 'shipTo',
        options: orderBy((topFilters.shipTo || []).map((s) => s.name).filter(Boolean)),
      })
      available.push({
        name: 'useCases',
        options: orderBy((topFilters.useCases || []).map((u) => u.name).filter(Boolean)),
      })
      available.push({
        name: 'currency',
        options: orderBy(
          (topFilters.currencies || [])
            .map((c) => c.name)
            .filter(Boolean)
            .filter((c) => (approvedCurrencies?.length ? approvedCurrencies?.includes(c) : true))
        ),
      })
    }

    // if we do have a category, push in the category filters
    if (!!categories.length) {
      // Pare down displayed filters for collections v2 since there is a lot of noise
      const additionalFilters = isCollections
        ? catFilters.filter((f) => COLLECTIONS_FILTERS.includes(f.name))
        : catFilters

      additionalFilters.forEach((f) => {
        available.push({
          name: f.name,
          options: orderBy(f.options?.map((o) => o.name).filter(Boolean) || []),
        })
      })
    }

    // make sure we have options, they are not on the exclusion list, and pass aclCheck
    return available
      .filter((f) => f.options.length)
      .filter((f) => !excludeFilters?.includes(f.name))
      .filter((f) => (!!includeFilters ? includeFilters.includes(f.name) : true))
      .filter((f) => (filterAcl[f.name] ? aclCheck(filterAcl[f.name]) : true))
  }, [
    brandCategories,
    isMarketplace,
    isCollections,
    getMarketplaceFilters.data?.getMarketplaceFilters,
    getApprovedPostalFilters.data?.getApprovedPostalFilters,
    restrictCategory,
    categories.length,
    excludeCategories,
    approvedCurrencies,
    excludeFilters,
    includeFilters,
    aclCheck,
  ])

  // cleanup when the filters or available filters change
  useDeepCompareEffectNoCheck(() => {
    // bail if we are loading to make sure we have the correct data
    if (isLoading) return

    // iterate the current filters
    Object.keys(filters).forEach((name) => {
      // skip these special filters
      if (SPECIAL_FILTERS.includes(name)) return

      const available = availableFilters.find((f) => f.name === name)
      const value = filters[name]

      // remove filters that are no longer available
      if (!available) return onUpdate(name, undefined)

      // remove filters that are no longer valid
      if (isArray(value)) {
        const newValues = value.filter((v) => available.options.includes(v))
        if (!dequal(newValues, value)) onUpdate(name, newValues)
      } else {
        if (!available.options.includes(value)) return onUpdate(name, undefined)
      }
    })
  }, [availableFilters, filters, isLoading, onUpdate])

  const radioFilters = useMemo(
    () => availableFilters.filter((f) => RADIO_FILTERS.includes(f.name)).sort(handleSortFilter),
    [availableFilters]
  )

  const checkboxFilters = useMemo(
    () =>
      availableFilters
        .filter((f) => !RADIO_FILTERS.includes(f.name) && !CUSTOM_FILTERS.includes(f.name))
        .sort(handleSortFilter),
    [availableFilters]
  )

  const customFilters = useMemo(
    () => availableFilters.filter((f) => CUSTOM_FILTERS.includes(f.name)).sort(handleSortFilter),
    [availableFilters]
  )

  const handlePrice = ({ key: name, value: valueAsNumber }: UiChangeEvent<number>) => {
    // TODO: don't divide by 100 or multiply by 100 in this file, after we update usePostalFilters
    switch (name) {
      case 'min':
        return onUpdate('price', { ...filters.price, min: (valueAsNumber ?? 0) / 100 || undefined })
      case 'max':
        return onUpdate('price', { ...filters.price, max: (valueAsNumber ?? 0) / 100 || undefined })
      default:
    }
  }

  const handleCurrency = (e: UiChangeEvent<string[]>) => onUpdate(e.key, e.value, 800)

  const handleUpdate = (name: string) => (value: any) => onUpdate(name, value)
  const handleClear = (name: string) => () => onUpdate(name, undefined)

  // const dateOptions = useMemo(() => {
  //   const now = new Date()
  //   return {
  //     'data-min-date': now.toISOString(),
  //     'data-max-date': addDays(now, 90).toISOString()
  //   }
  // }, [])

  /*
    Here we are overriding the option labels on select and multiselect components.

    This reason we are doing this is because we have business/ux decisions that
    we want to run against our dynamically created filters and it would be painful
    to change the underlying data.
  */
  const getOptionLabel = (name: string, option: any) => {
    if (NON_HUMANIZED_FILTERS.includes(name)) return option.label
    if (isEvent && name === 'fulfillmentType') {
      switch (option.label) {
        case FulfillmentType.Physical:
          return 'Physical Event Kit'
        case FulfillmentType.EventFee:
          return 'No Physical Event Kit'
      }
    }
    return humanize(option.label)
  }

  return (
    <Box>
      <UiSidePanel
        data-testid="SidePanelFilter"
        gap={32}
        {...rest}
      >
        {topBlock && <UiSidePanelBlock data-testid="SidePanelFilter-Top">{topBlock}</UiSidePanelBlock>}

        <UiSidePanelBlock
          data-testid={isLoading ? 'SidePanelFilter_Filters_loading' : 'SidePanelFilter_Filters'}
          isLoading={isLoading}
          bg="white"
          w="237px"
          h="100%"
          minH="500px"
          borderRadius="3px"
          startColor="atomicGray.50"
          endColor="atomicGray.200"
          pb={8}
        >
          <Stack spacing={6}>
            {canSelectTeams && (
              <Box>
                <SidePanelHeader
                  canClear={!!filters.teamIds?.length}
                  onClear={handleClear('teamIds')}
                  title="Teams"
                />
                <AutoCompleteTeams
                  data-testid="SidePanelFilter-Teams"
                  value={filters.teamIds ?? null}
                  onChange={handleUpdate('teamIds')}
                />
              </Box>
            )}

            <Box>
              <SidePanelHeader
                canClear={!!filters.price?.min || !!filters.price?.max}
                onClear={handleClear('price')}
                title="Price Range"
              />

              <SimpleGrid
                columns={2}
                spacing={4}
              >
                <FormControl id="min">
                  <ZInputMoney
                    name="min"
                    value={isNumber(filters.price?.min) ? filters.price?.min * 100 : undefined}
                    onChange={handlePrice}
                    min={1}
                    max={filters.price?.max}
                    placeholder="Min"
                    data-testid="SidePanelFilter-price-min"
                  />
                </FormControl>
                <FormControl id="max">
                  <ZInputMoney
                    name="max"
                    min={isNumber(filters.price?.min) ? filters.price?.min * 100 : undefined}
                    value={isNumber(filters.price?.max) ? filters.price?.max * 100 : undefined}
                    onChange={handlePrice}
                    placeholder="Max"
                    data-testid="SidePanelFilter-price-max"
                  />
                </FormControl>
              </SimpleGrid>
            </Box>

            {radioFilters.map(({ name, options }, idx) => {
              return (
                <Box key={`${name}-${idx}`}>
                  <SidePanelHeader
                    canClear={!!filters[name]}
                    onClear={handleClear(name)}
                    title={humanize(name)}
                  />
                  <AutoCompleteSelect
                    data-testid={`SidePanelFilter-${humanize(name)}`}
                    options={options}
                    value={filters[name]}
                    getOptionLabel={(o) => getOptionLabel(name, o)}
                    onChange={handleUpdate(name)}
                    placeholder={`Select ${humanize(name)}`}
                    {...SelectTypeaheadStylesV2}
                  />
                </Box>
              )
            })}

            {checkboxFilters?.map(({ name, options }, idx) => {
              return (
                <Box key={`${name}-${idx}`}>
                  <SidePanelHeader
                    canClear={!!filters[name]?.length}
                    onClear={handleClear(name)}
                    title={humanize(name)}
                  />
                  <AutoCompleteMultiSelect
                    data-testid={`SidePanelFilter-${humanize(name)}`}
                    options={options}
                    value={filters[name]}
                    onChange={handleUpdate(name)}
                    getOptionLabel={(o) => getOptionLabel(name, o)}
                    placeholder={`Select ${humanize(name)}`}
                    {...SelectTypeaheadStylesV2}
                  />
                </Box>
              )
            })}

            {customFilters?.map(({ name, options }, idx) => {
              return (
                name === 'currency' && (
                  <FormControl
                    key={`${name}-${idx}`}
                    id={name}
                  >
                    <SidePanelHeader
                      canClear={!!filters[name]?.length}
                      onClear={handleClear(name)}
                      title={humanize(name)}
                    />
                    <ZCurrencyCheckboxGroup
                      name={name}
                      value={filters[name] ?? []}
                      onChange={handleCurrency}
                    >
                      <Grid
                        gap={2}
                        templateColumns="repeat(4, minmax(40px, 60px))"
                      >
                        {options.map((option) => (
                          <ZCurrencyCheckbox
                            key={option}
                            value={option}
                          />
                        ))}
                      </Grid>
                    </ZCurrencyCheckboxGroup>
                  </FormControl>
                )
              )
            })}

            {!isMarketplace && !excludeFilters?.includes('favorites') && (
              <FormControl id="favorites">
                <SidePanelHeader
                  canClear={!!filters['favorites']}
                  onClear={handleClear('favorites')}
                  title="Show only Favorite Items"
                />
                <UiToggle
                  name="favorites"
                  isChecked={filters['favorites'] || false}
                  onChange={(e: any) => handleUpdate('favorites')(e.target.checked || undefined)}
                  colorScheme="atomicBlue"
                />
              </FormControl>
            )}

            {!excludeFilters?.includes('outOfStock') && (
              <FormControl id="outOfStock">
                <SidePanelHeader
                  canClear={!!filters['outOfStock']}
                  onClear={handleClear('outOfStock')}
                  title="Show Out of Stock"
                />
                <UiToggle
                  name="outOfStock"
                  isChecked={filters['outOfStock'] || false}
                  onChange={(e: any) => handleUpdate('outOfStock')(e.target.checked || undefined)}
                  colorScheme="atomicBlue"
                />
              </FormControl>
            )}

            {canSeeDraft && (
              <FormControl id="draft">
                <SidePanelHeader
                  canClear={!!filters['draft']}
                  onClear={handleClear('draft')}
                  title="Show Draft Items"
                />
                <UiToggle
                  name="draft"
                  isChecked={filters['draft'] || false}
                  onChange={(e: any) => handleUpdate('draft')(e.target.checked || undefined)}
                  colorScheme="atomicBlue"
                />
              </FormControl>
            )}
          </Stack>
        </UiSidePanelBlock>

        {bottomBlock && <UiSidePanelBlock data-testid="SidePanelFilter-Bottom">{bottomBlock}</UiSidePanelBlock>}
      </UiSidePanel>
    </Box>
  )
}
