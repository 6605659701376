import { Container, Fade, Flex, Grid, HStack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { GraphqlFilterState, OrderByProps, UiEasyDataTableColumn } from '@postal-io/postal-ui'
import {
  GraphqlFilterTransform,
  internalProgressBarProps,
  internalTableProps,
  UiDate,
  UiEasyDataTable,
  UiIconButton,
  UiToggle,
  UiTruncate,
  useAlerts,
  useGraphqlFilter,
  ZButton,
  ZCard,
  ZCardBody,
  ZCheckbox,
  ZConfirm,
  ZInputSearch,
  ZLink,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import type { PostalFulfillment, PostalFulfillmentFilterInput, SearchPostalFulfillmentsQueryVariables } from 'api'
import {
  BackgroundTaskStatus,
  BulkApproveOrdersDocument,
  BulkDenyOrdersDocument,
  FulfillmentStatus,
  GetAccountDocument,
  GetMagicLinkDocument,
  GetTeamByIdDocument,
  SearchPostalFulfillmentsDocument,
} from 'api'
import { NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { PageTitle, useBackgroundQueue, useSession } from 'hooks'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { LinksApproveSingleOrderModalV2 } from './LinksApproveOrdersModal'

const transforms = {
  itemName: GraphqlFilterTransform.Contains,
  status: GraphqlFilterTransform.Equal,
  userId: GraphqlFilterTransform.Equal,
  approvedPostalId: GraphqlFilterTransform.Equal,
}

const LIMIT = 100
const DEFAULT_SORT = { key: 'created', direction: 'desc' } as OrderByProps

export const SpentFromColumn: React.FC<{ teamId?: string }> = ({ teamId }) => {
  const team = useGraphqlQuery(GetTeamByIdDocument, { id: teamId ?? '' }, { enabled: !!teamId })
  const account = useGraphqlQuery(GetAccountDocument, {}, { enabled: teamId === null })

  const spentFrom = useMemo(
    () => team?.data?.getTeamById?.name ?? account?.data?.getAccount?.name,
    [team?.data?.getTeamById, account?.data?.getAccount]
  )

  return <ZText>{spentFrom}</ZText>
}
interface PostalFulfillmentColumn extends PostalFulfillment {
  handleClickLinkName: (fulfillment: PostalFulfillment) => () => void
}

const DEFAULT_COLUMNS: UiEasyDataTableColumn<PostalFulfillmentColumn>[] = [
  {
    label: 'Contact',
    render: ({ contactId, shipToName }) => (
      <ZLink
        as={RouterLink}
        to={`/contacts/${contactId}`}
        isExternal
      >
        {shipToName}
      </ZLink>
    ),
  },
  {
    label: 'Item',
    render: (fulfillment) => (
      <ZLink onClick={fulfillment.handleClickLinkName(fulfillment)}>
        <UiTruncate
          length={30}
          text={fulfillment.itemName}
          showTooltip
        ></UiTruncate>
      </ZLink>
    ),
  },
  {
    label: 'Redemption Date',
    render: (fulfillment) => <UiDate date={fulfillment?.created?.dateTime} />,
  },
  {
    label: 'Item Cost',
    render: ({ cost }) => {
      return (
        <ZMoney
          cents={cost?.total || 0}
          currency={cost?.currency}
        />
      )
    },
  },
  {
    label: 'Company Name',
    render: (fulfillment) => {
      return <ZText>{fulfillment.shipToCompanyName}</ZText>
    },
  },
  {
    label: 'Email',
    render: ({ shipToEmail }) => <ZText>{shipToEmail}</ZText>,
  },
  {
    label: 'Title',
    render: (fulfillment) => {
      return <ZText>{fulfillment.shipToTitle}</ZText>
    },
  },
  {
    label: 'Spent From',
    render: (fulfillment) => {
      return <SpentFromColumn teamId={fulfillment?.spendAs?.teamId!} />
    },
  },
]

const INITIAL_STATE = { orderBy: DEFAULT_SORT } as GraphqlFilterState

export const LinksBulkApproveOrders: React.FC = () => {
  const Alert = useAlerts()
  const [selectedOrders, setSelectedOrders] = useState<PostalFulfillmentColumn[]>([])
  const [selectSingleOrder, setSelectSingleOrder] = useState<PostalFulfillment | undefined>()
  const { queue, tasks } = useBackgroundQueue()
  const confirmApprove = useDisclosure()
  const confirmReject = useDisclosure()
  const singleOrderDisclosure = useDisclosure()
  const { magicLinkId } = useParams() as any
  const { state: routerState } = useLocation() as any
  const navigate = useNavigate()
  const { session } = useSession()

  const { data: magicLinkData } = useGraphqlQuery(GetMagicLinkDocument, { id: magicLinkId }, { enabled: !!magicLinkId })

  const tasksRunning = useMemo(
    () =>
      tasks.length >= 1 &&
      tasks[0].task?.status === BackgroundTaskStatus.Pending &&
      (tasks[0].task?.name === 'Bulk Approve Orders' || tasks[0].task?.name === 'Bulk Deny Orders'),
    [tasks]
  ) as boolean

  const approveOrders = useGraphqlMutation(BulkApproveOrdersDocument, {
    onSuccess: (data) => {
      queue(data.bulkApproveOrders, () => {
        setSelectedOrders([])
        Alert.success('Orders approved')
      })
    },
  })
  const denyOrders = useGraphqlMutation(BulkDenyOrdersDocument, {
    onSuccess: (data) => {
      queue(data.bulkDenyOrders, () => {
        setSelectedOrders([])
        Alert.info('Orders rejected')
      })
    },
  })

  const handleCancelBulkSelect = useCallback(() => {
    setSelectedOrders([])
  }, [setSelectedOrders])

  const handleClickLinkName = useCallback(
    (fulfillment: PostalFulfillment) => () => {
      setSelectSingleOrder(fulfillment)
      singleOrderDisclosure.onOpen()
    },
    [singleOrderDisclosure]
  )

  const staticVariables = useMemo(() => {
    const filter = {
      status: { eq: FulfillmentStatus.PendingUserApproval },
    } as PostalFulfillmentFilterInput

    return { filter, limit: LIMIT }
  }, [])

  const { variables, filter, setFilter } = useGraphqlFilter<SearchPostalFulfillmentsQueryVariables>({
    transforms,
    initialState: INITIAL_STATE,
    staticVariables,
    debounce: 400,
  })

  const onChangeSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ key: 'itemName', value: e.target.value })
    },
    [setFilter]
  )

  const onToggleMyOrders = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ key: 'userId', value: e.target.checked ? session.userId : undefined })
    },
    [setFilter, session]
  )

  const { mergedData, isFetching, isFetched } = useGraphqlInfiniteQuery(SearchPostalFulfillmentsDocument, variables)
  const postalFulfillments = useMemo(
    () =>
      mergedData?.searchPostalFulfillments?.map((f) => ({
        ...f,
        handleClickLinkName,
      })) ?? [],
    [mergedData?.searchPostalFulfillments, handleClickLinkName]
  )

  const handleApproveOrders = useCallback(() => {
    approveOrders
      .mutateAsync({ fulfillmentIds: selectedOrders.map((order) => order.id) })
      .catch((err) => Alert.error(err))
    confirmApprove.onClose()
  }, [Alert, approveOrders, selectedOrders, confirmApprove])

  const handleDenyOrders = useCallback(() => {
    denyOrders.mutateAsync({ fulfillmentIds: selectedOrders.map((order) => order.id) }).catch((err) => Alert.error(err))
    confirmReject.onClose()
  }, [Alert, denyOrders, selectedOrders, confirmReject])

  const handleSelectOrder = useCallback(
    (checked?: boolean, row?: any) => {
      if (row) {
        checked
          ? setSelectedOrders((prev) => [...prev, row])
          : setSelectedOrders((prev) => prev.filter((r) => r.id !== row.id))
      } else {
        checked
          ? setSelectedOrders((prev) => [...prev, ...postalFulfillments])
          : setSelectedOrders((prev) => prev.filter((c) => !postalFulfillments.some((r) => r.id === c.id)))
      }
    },
    [postalFulfillments]
  )

  // Go back to whence we came if the query is done fetching, there are no more orders, and we have a returnToPath.
  useEffect(() => {
    if (!isEmpty(filter)) return
    setTimeout(() => {
      if (isFetched && postalFulfillments.length === 0 && !!routerState?.returnToPath)
        navigate(routerState.returnToPath)
    }, 300)
  }, [postalFulfillments.length, routerState, isFetched, filter, session, navigate])

  const isLoading = tasksRunning || approveOrders.isLoading || denyOrders.isLoading

  return (
    <>
      <PageTitle
        title={`Bulk Approval${magicLinkData?.getMagicLink ? ` - ${magicLinkData?.getMagicLink?.name}` : ''}`}
      />

      <SecondaryNavbar
        px={8}
        header={`Bulk Approval${magicLinkData ? ` - ${magicLinkData?.getMagicLink?.name}` : ''}`}
        left={
          <Flex
            alignItems="center"
            h="52px"
            color="inherit"
          >
            <NavbarBackButton
              onClick={() => (routerState?.returnToPath ? navigate(routerState?.returnToPath) : navigate(-1))}
              label={`Back${routerState?.returnToText ? ` to ${routerState?.returnToText}` : ''}`}
              invertColors
            />
          </Flex>
        }
        right={
          <Fade in={selectedOrders.length >= 1}>
            <HStack spacing={4}>
              <ZButton
                minWidth="160px"
                colorScheme="atomicBlue"
                onClick={() => confirmApprove.onOpen()}
                isDisabled={!selectedOrders.length}
                isLoading={isLoading}
              >
                Approve {selectedOrders.length} Orders
              </ZButton>
              <ZButton
                minWidth="160px"
                color="atomicGray.900"
                variant="link"
                onClick={() => confirmReject.onOpen()}
                isDisabled={!selectedOrders.length}
                isLoading={isLoading}
              >
                Deny {selectedOrders.length} Orders
              </ZButton>
            </HStack>
          </Fade>
        }
        farRight={
          !!selectedOrders.length ? (
            <UiIconButton
              icon={<MdClose size="24px" />}
              color="inherit"
              variant="link"
              aria-label="cancel bulk approve selection"
              onClick={handleCancelBulkSelect}
            />
          ) : undefined
        }
        hideHelpCenterButton={!!selectedOrders.length}
        invertColors
      />

      <Container
        maxW="1440px"
        p={8}
      >
        <ZCard variant="form">
          <ZCardBody p={8}>
            <Grid
              templateColumns="1fr 1fr 1fr"
              gridGap={4}
              mb={4}
            >
              {!magicLinkId ? (
                <HStack spacing={3}>
                  <UiToggle
                    isChecked={filter.userId === session.userId}
                    onChange={onToggleMyOrders}
                    colorScheme="atomicBlue"
                    ml={1}
                  />
                  <ZText>My Orders</ZText>
                </HStack>
              ) : null}
              <ZInputSearch
                value={filter.itemName}
                onChange={onChangeSearch}
                placeholder="Search"
                pr={10}
              />
            </Grid>
            <UiEasyDataTable
              variant="list"
              columns={DEFAULT_COLUMNS}
              rows={postalFulfillments}
              isLoading={isFetching}
              onSelect={handleSelectOrder}
              selected={selectedOrders}
              rowKey="id"
              filter={filter}
              tableProps={internalTableProps}
              progressBarProps={internalProgressBarProps}
              SelectCheckbox={ZCheckbox}
            />
          </ZCardBody>
        </ZCard>
      </Container>
      <LinksApproveSingleOrderModalV2
        {...singleOrderDisclosure}
        fulfillmentId={selectSingleOrder?.id}
        message={magicLinkData?.getMagicLink?.message}
      />
      <ZConfirm
        {...confirmApprove}
        onConfirm={handleApproveOrders}
        isDisabled={isLoading}
        isLoading={isLoading}
        buttonText="Approve"
        title="Approve Orders"
      >
        Are you sure you would like to approve{' '}
        {selectedOrders.length > 1 ? `${selectedOrders.length} orders` : 'this order'}?
      </ZConfirm>
      <ZConfirm
        {...confirmReject}
        onConfirm={handleDenyOrders}
        isDisabled={isLoading}
        isLoading={isLoading}
        buttonColor="atomicRed"
        buttonText="Reject"
        title="Reject Orders"
      >
        Are you sure you would like to reject{' '}
        {selectedOrders.length > 1 ? `${selectedOrders.length} orders` : 'this order'}?
      </ZConfirm>
    </>
  )
}
