import type { ApprovedPostal, ImageReference, MarketplaceProduct } from 'api'
import { Status } from 'api'
import { CATEGORY } from 'components/Postals'
import { useMemo } from 'react'

const sortImages = (postal: any, includeAll: boolean): ImageReference[] => {
  // if we don't have any variants, just return main product images
  if (!postal?.variants?.length) return postal?.imageUrls

  // if we do have variants, compile the variant images
  const variantImages = postal.variants?.reduce((arr: any[], variant: any) => {
    const images = variant.status === Status.Active && variant?.imageUrls ? variant.imageUrls : []
    return arr.concat(images)
  }, [])

  // if there are variants but no variant images, return the product images

  if (includeAll) return [...variantImages, ...postal.imageUrls]
  return variantImages.length ? variantImages : postal.imageUrls
}

type AnyPostal = ApprovedPostal | MarketplaceProduct | null

export const usePostalImages = (postal?: AnyPostal, includeAll: boolean = false) => {
  const images = useMemo(() => {
    if (!postal) return []
    if (postal.category === CATEGORY.Events) return postal.imageUrls || []
    if ((postal as ApprovedPostal).marketplaceProductId) {
      return sortImages(postal, includeAll) || []
    }
    return postal.imageUrls || []
  }, [includeAll, postal])

  return images
}
