import { Divider, Stack } from '@chakra-ui/react'
import { ZCard, ZCardBody, ZHeading } from '@postal-io/postal-ui'
import { PostalSendMethod } from 'components/PostalSend/postalSendHelpers'
import { useAcl } from 'hooks'
import { useExtension } from 'hooks/useExtension'
import React, { useMemo } from 'react'
import type { PostalCustomizeV2Props } from './PostalCustomize'
import { PostalCustomizeSendAs } from './PostalCustomizeSendAs'
import { PostalCustomizeSpendAs } from './PostalCustomizeSpendAs'

export const PostalCustomizeSendingOptions: React.FC<PostalCustomizeV2Props> = ({ context, send }) => {
  const { aclCheck, hasFeature } = useAcl()
  const canSpendAs = hasFeature('budgetDropDown')
  const canSendAs = useMemo(
    () =>
      (aclCheck({ module: ['users.query', 'contacts.read'], feature: 'sendAs' }) && context.deliveryEmail) ||
      context.method === PostalSendMethod.Link,
    [aclCheck, context.deliveryEmail, context.method]
  )

  const { isExtension } = useExtension()

  if (!canSendAs && !canSpendAs) return null

  return (
    <>
      {isExtension && <Divider />}
      <ZCard
        pt={{ base: 4, md: 8 }}
        borderWidth={{ base: 0, md: 1 }}
        variant="form"
      >
        <ZCardBody py={{ base: 8, md: 8 }}>
          <ZHeading
            as="h2"
            size="h5"
            pb={8}
          >
            Sending Options
          </ZHeading>
          <Stack spacing={5}>
            <PostalCustomizeSendAs
              context={context}
              send={send}
            />
            <PostalCustomizeSpendAs
              context={context}
              send={send}
            />
          </Stack>
        </ZCardBody>
      </ZCard>
    </>
  )
}
