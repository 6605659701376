import { useDisclosure } from '@chakra-ui/react'
import type { ZCardProps } from '@postal-io/postal-ui'
import {
  ZCard,
  ZCardButton,
  ZCardHeader,
  ZHeading,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import { useAcl } from 'hooks'
import React from 'react'
import { MdAddCircleOutline } from 'react-icons/md'
import { UserAccountAdd } from './UserAccountAdd'
import { UserAccountsTable } from './UserAccountsTable'

interface UserTeamsV2Props extends ZCardProps {
  userId: string
}

export const UserTeams: React.FC<UserTeamsV2Props> = ({ userId, ...rest }) => {
  const { hasPermission } = useAcl()
  const editAccountsDisclosure = useDisclosure()
  return (
    <>
      <ZCard
        variant="form"
        {...rest}
      >
        <ZCardHeader
          display="flex"
          alignItems="center"
          p={8}
          pb={0}
        >
          <ZHeading
            size="h6"
            flex={1}
          >
            Teams
          </ZHeading>
          {hasPermission('users.update') && (
            <ZCardButton
              aria-label="Add Team"
              onClick={editAccountsDisclosure.onOpen}
              color="atomicBlue.400"
              icon={<MdAddCircleOutline size="24px" />}
            />
          )}
        </ZCardHeader>
        <UserAccountsTable
          userId={userId}
          p={8}
          variant="list"
        />
      </ZCard>
      {hasPermission('users.update') && editAccountsDisclosure.isOpen && (
        <ZModal
          isOpen={editAccountsDisclosure.isOpen}
          onClose={editAccountsDisclosure.onClose}
          size="md"
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Add Team Membership</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody pb={8}>
              <ZText
                mb={4}
                textAlign="center"
                fontSize="md"
              >
                Please select the <strong>team</strong> and <strong>roles</strong> you would like granted to this user.
              </ZText>
              <UserAccountAdd
                userId={userId}
                onUpdate={editAccountsDisclosure.onClose}
                onClose={editAccountsDisclosure.onClose}
                mb={4}
              />
            </ZModalBody>
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}
