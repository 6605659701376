import { Flex, Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  useAlerts,
  ZButton,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import { SendUserEmailCheckbox } from 'components/Users/SendUserEmailCheckbox'
import type { FormEvent } from 'react'
import React from 'react'
import { useImmer } from 'use-immer'
import { BulkProductAccessUpdateDocument, Role } from '../../api'
import { useBackgroundQueue } from '../../hooks'
import { MenuUserRole } from '../AutoComplete'
import { UserSeatsAlert } from '../Users'
import type { SelectedUsers } from '../Users/UsersData'

interface BulkTeamUserUpdateProps {
  isOpen: boolean
  onClose: () => void
  onComplete?: () => void
  users: SelectedUsers
  teamId?: string
}

interface State {
  search: string
  roles: Role[]
  sendNotification?: boolean
}
export const BulkTeamUserUpdate: React.FC<BulkTeamUserUpdateProps> = ({
  isOpen,
  onClose,
  onComplete,
  users,
  teamId,
}) => {
  const Alert = useAlerts()
  const { queue } = useBackgroundQueue()

  const confirmRemove = useDisclosure()

  const [state, setState] = useImmer<State>({
    search: '',
    roles: [Role.User],
  })

  // mutations
  const productAccessUpdate = useGraphqlMutation(BulkProductAccessUpdateDocument, {
    onSuccess: (data) => queue(data.bulkProductAccessUpdate),
  })

  const userCount = users.count
  const userWord = userCount > 1 ? 'Users' : 'User'

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (!userCount) return Alert.warning('No users were selected. Please try again.')
    if (!state.roles.length) return confirmRemove.onOpen()
    await updateRoles()
  }

  const updateRoles = async () => {
    try {
      const roles = teamId ? state.roles.filter((v: any) => v !== Role.Admin) : state.roles
      const variables: any = {
        filter: users.filter,
        data: { roles, teamId },
        sendNotification: !!state.sendNotification,
      }
      await productAccessUpdate.mutateAsync(variables)
      Alert.success(`Updated ${userCount} ${userWord}`)
      onClose()
      onComplete && onComplete()
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <>
      <ZModal
        isOpen={isOpen}
        onClose={onClose}
        size="md"
      >
        <ZModalOverlay />
        <ZModalContent>
          <ZModalHeader>
            Updating Roles for {userCount} {userWord}
          </ZModalHeader>
          <ZModalCloseButton />
          <ZModalBody>
            <UserSeatsAlert
              userCount={userCount}
              mb={8}
            />

            <ZText
              mb={4}
              textAlign="center"
              fontSize="md"
            >
              Please select the team and <strong>roles</strong> you would like set for each user.
            </ZText>

            <form onSubmit={handleSubmit}>
              <Stack
                spacing={8}
                mb={16}
              >
                <MenuUserRole
                  isDisabled={productAccessUpdate.isLoading}
                  value={state.roles}
                  onChange={(newRoles) => setState((draft) => void (draft.roles = newRoles))}
                  teamId={teamId}
                  height="40px"
                  w="full"
                  fontWeight="normal"
                  textAlign="left"
                  mt={4}
                />
                <SendUserEmailCheckbox
                  isChecked={!!state.sendNotification}
                  onChange={(e) => setState((draft) => void (draft.sendNotification = e.target.checked))}
                  justifyContent="flex-start"
                />
              </Stack>
              <Flex
                justifyContent="space-between"
                mt={16}
                pb={8}
              >
                <ZButton
                  type="submit"
                  colorScheme="atomicBlue"
                  isDisabled={productAccessUpdate.isLoading}
                  minW="140px"
                >
                  Update Roles
                </ZButton>
                <ZButton
                  colorScheme="atomicGray"
                  variant="ghost"
                  onClick={onClose}
                  minW="140px"
                >
                  Cancel
                </ZButton>
              </Flex>
            </form>
          </ZModalBody>
        </ZModalContent>
      </ZModal>
      <ZModal
        size="md"
        isOpen={confirmRemove.isOpen}
        onClose={confirmRemove.onClose}
      >
        <ZModalOverlay />
        <ZModalContent>
          <ZModalHeader>Confirm Remove Access</ZModalHeader>
          <ZModalCloseButton />
          <ZModalBody>
            <ZText>
              This action will remove {userCount > 1 ? 'these users' : 'this user'} from the team. You can always add
              them back a later time.
            </ZText>
            <ZText mt={4}>
              Are you sure you want to <strong>remove</strong> {userCount > 1 ? 'these users' : 'this user'} from this
              team?
            </ZText>
          </ZModalBody>
          <ZModalButtons
            confirmText="Remove"
            confirmProps={{
              colorScheme: 'atomicRed',
            }}
            onConfirm={updateRoles}
            onClose={confirmRemove.onClose}
          />
        </ZModalContent>
      </ZModal>
    </>
  )
}
