import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { countries } from '@postal-io/postal-ui'
import { GetLocationDataDocument } from 'api'
import { useMemo } from 'react'

export type CountryInput = (typeof countries)[0]

export const useGetCountry = (countryName?: string | null) => {
  const { data } = useGraphqlQuery(GetLocationDataDocument, undefined, { enabled: !countryName })
  const geoLocation = data?.getLocationData

  const foundCountry = useMemo(() => {
    return countryName
      ? countries.find((item) => item.name === countryName || item.iso2 === countryName || item.iso3 === countryName)
      : geoLocation
      ? countries.find((item) => item.iso3 === geoLocation?.countryCode3)
      : null
  }, [countryName, geoLocation])

  return foundCountry
}
