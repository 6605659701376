import { Box, Divider, Flex, useClipboard, VStack } from '@chakra-ui/react'
import type { UiAlertProps, UiCardProps } from '@postal-io/postal-ui'
import {
  humanize,
  UiFormControl,
  UiToggle,
  useAlerts,
  ZCard,
  ZCardHeader,
  ZFormLabel,
  ZHeading,
  ZLink,
  ZText,
} from '@postal-io/postal-ui'
import { ZAlert, ZInfoTooltip } from 'components/Common/ZComponents'
import { useSession } from 'hooks'
import React from 'react'
import type { IntegrationSync } from '../../api'
import { Status } from '../../api'
import type { ExternalProvider } from './data'
import { ExternalSystem } from './data'
import { isActiveIntegration } from './helpers'

interface IntegrationSettingsProps extends UiCardProps {
  provider: ExternalProvider
  userIntegration?: IntegrationSync
  nonUserIntegrations: IntegrationSync[]
  isLoading?: boolean
  isFetching?: boolean
  handleToggle: (i: IntegrationSync, isChecked: boolean) => void
}

export const IntegrationSettings: React.FC<IntegrationSettingsProps> = ({
  provider,
  userIntegration,
  nonUserIntegrations,
  isLoading,
  isFetching,
  handleToggle,
  ...rest
}) => {
  return (
    <>
      <ZCard
        isLoading={isLoading}
        variant="form"
        {...rest}
      >
        <ZCardHeader
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          p={8}
          pb={0}
          gap={2}
        >
          Integration Settings
          <ZInfoTooltip
            size="lg"
            label="Modify the settings for this integration"
          />
        </ZCardHeader>

        <Box p={8}>
          <ZHeading
            size="h6"
            mb={4}
          >
            Activate
          </ZHeading>
          <UiFormControl
            display="flex"
            alignItems="center"
            id={`integration-${userIntegration?.objectType}`}
          >
            <UiToggle
              size="lg"
              name={`integration-${userIntegration?.objectType}`}
              value={`integration-${userIntegration?.objectType}`}
              isChecked={isActiveIntegration(userIntegration)}
              onChange={(e: any) => handleToggle(userIntegration as any, e.target.checked)}
              isDisabled={isFetching}
              colorScheme="atomicBlue"
            />
            <ZFormLabel
              fontWeight="bold"
              fontSize="md"
              m={0}
              ml={2}
            >
              {isActiveIntegration(userIntegration) ? 'Enabled' : 'Disabled'}
            </ZFormLabel>
          </UiFormControl>
        </Box>
        <IntegrationWebhook provider={provider} />

        <Divider />

        <Box p={8}>
          <IntegrationMessaging provider={provider} />
        </Box>

        <Divider />

        <VStack
          align="flex-start"
          spacing={8}
          p={8}
        >
          {nonUserIntegrations?.map((integration: IntegrationSync, idx: number) => {
            return (
              <UiFormControl
                id={`integration-${integration?.objectType}-${idx}`}
                key={`integration-${integration?.objectType}-${idx}`}
              >
                <ZHeading
                  size="h6"
                  mb={4}
                >
                  Object Type: {humanize(integration.objectType)}
                </ZHeading>
                <Flex align="center">
                  <UiToggle
                    size="lg"
                    key={integration.objectType}
                    isChecked={isActiveIntegration(integration)}
                    onChange={(e: any) => handleToggle(integration, e.target.checked)}
                    isDisabled={userIntegration?.status !== Status.Active || isFetching}
                    colorScheme="atomicBlue"
                  />
                  <ZFormLabel
                    fontWeight="bold"
                    fontSize="md"
                    m={0}
                    ml={2}
                  >
                    {isActiveIntegration(integration) ? 'Enabled' : 'Disabled'}
                  </ZFormLabel>
                </Flex>
              </UiFormControl>
            )
          })}
        </VStack>
      </ZCard>
    </>
  )
}

export const IntegrationMessaging: React.FC<{ provider: ExternalProvider }> = ({ provider }) => {
  const message =
    provider.system === ExternalSystem.SwagUp ? (
      <ZText fontSize="md">
        When enabled, your <strong style={{ fontWeight: 500 }}>{provider.name}</strong> products will be automatically
        synchronized with Postal once they are ready to ship.
      </ZText>
    ) : provider.workato && !provider.workato.userSync ? (
      <ZText>
        {provider.name} integration is enabled.{' '}
        {!!provider.trigger && (
          <>
            <ZLink href="/triggers/edit">Create a trigger</ZLink> in Postal to begin sending items to employees in your{' '}
            {provider.name} instance.
          </>
        )}{' '}
        If you would like to remove this integration, click the button on the top right of this screen.
      </ZText>
    ) : (
      <ZText fontSize="md">
        When enabled, your <strong style={{ fontWeight: 500 }}>{provider.name}</strong> users will be automatically
        synchronized with Postal. Please select any additional objects you would like to synchronize from{' '}
        {provider.name}.
      </ZText>
    )

  return message
}

export const useWebhookUrl = (provider: ExternalProvider) => {
  const { session } = useSession()
  const PREFIX = `${window.location.protocol}//${window.location.host}/api/datasources/webhooks`

  switch (provider.system) {
    case ExternalSystem.Marketo:
      return `${PREFIX}/${provider.integration?.endpoint}/${session.accountId}`
    default:
  }
}

export interface IntegrationWebhookProps extends Omit<UiAlertProps, 'status'> {
  provider: ExternalProvider
}
export const IntegrationWebhook: React.FC<IntegrationWebhookProps> = ({ provider, ...rest }) => {
  const Alert = useAlerts()
  const url = useWebhookUrl(provider)
  const { onCopy, setValue: setUrlToCopy } = useClipboard('')

  const handleCopy = (e: React.MouseEvent) => {
    e.preventDefault()
    setUrlToCopy(url ?? '')
    setTimeout(onCopy)
    Alert.success('Webhook URL copied to clipboard')
  }

  if (!url) return null

  return (
    <Box
      p={8}
      pt={0}
    >
      <ZAlert
        status="info"
        hideClose
        {...rest}
      >
        <ZLink
          href=""
          onClick={handleCopy}
        >
          Copy Webhook URL
        </ZLink>
      </ZAlert>
    </Box>
  )
}
