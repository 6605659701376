import type { BoxProps } from '@chakra-ui/react'
import { Box, Collapse, Divider, Flex, Grid, HStack, Stack, useDisclosure } from '@chakra-ui/react'
import {
  UiAlert,
  UiDangerous,
  UiMenu,
  UiMenuButton,
  UiMenuList,
  UiMoney,
  useColor,
  ZButton,
  ZCard,
  ZCardBody,
  ZHeading,
  ZText,
} from '@postal-io/postal-ui'
import { ClampAndFade } from 'components/Common/ClampAndFade'
import { ZAlert, ZInfoTooltip } from 'components/Common/ZComponents'
import { PostalVariantOption } from 'components/Postal/PostalVariantOption'
import { CATEGORY } from 'components/Postals'
import { PostalSendSidebarImageDisplay } from 'components/PostalSend/PostalSendSidebarItemInfo'
import { listItemsWithMaxLength } from 'lib'
import { some } from 'lodash'
import React, { useMemo } from 'react'
import { MdOutlineArrowDropDown } from 'react-icons/md'
import type { ApprovedPostal, ApprovedProductVariant, MarketplaceProduct } from '../../api'
import { Status } from '../../api'
import { PostalImageCollage } from '../Postal'
import type { EitherVariant } from './utils'
import { addItemFieldsToVariant, getPriceRange } from './utils'

interface CollectionItemOptionsMarketplaceV2Props extends Omit<BoxProps, 'onSelect'> {
  marketplaceItem: MarketplaceProduct
  isBulkSelected: (variant: EitherVariant) => boolean
  onSelect: (variant: EitherVariant) => void
}

export const CollectionItemOptionsMarketplace: React.FC<CollectionItemOptionsMarketplaceV2Props> = ({
  marketplaceItem,
  isBulkSelected,
  onSelect,
  ...rest
}) => {
  const availableVariants = useMemo(
    () => marketplaceItem?.variants?.filter((v) => v.status === Status.Active) || [],
    [marketplaceItem?.variants]
  )

  const { Color } = useColor()

  return (
    <Box
      h="100%"
      w="100%"
      {...rest}
    >
      <Grid
        w="100%"
        templateColumns="calc(60% - 1rem) calc(40% - 1rem)"
        gridGap="2rem"
      >
        <ZCard
          variant="form"
          overflow="hidden"
        >
          <ZCardBody p={0}>
            <Box p={8}>
              <PostalImageCollage postal={marketplaceItem} />
            </Box>
            <Box p={8}>
              <ZHeading
                as="h2"
                size="h5"
              >
                {marketplaceItem.name}
              </ZHeading>
              <ZText
                my={2}
                color="gray.700"
                whiteSpace="break-spaces"
              >
                <UiDangerous html={marketplaceItem.description} />
              </ZText>
            </Box>
          </ZCardBody>
        </ZCard>
        <Box>
          <ZHeading
            mb={8}
            as="h2"
            size="h6"
          >
            <Flex alignItems="center">
              Select Available Options
              <ZInfoTooltip
                placement="right"
                ml={2}
                label="Recipients will choose from available options when accepting the order."
              />
            </Flex>
            <ZText
              pt={2}
              fontSize="body-sm"
            >
              Any applicable shipping or sales tax will be added when you send.
            </ZText>
          </ZHeading>
          {availableVariants?.length ? (
            <UiMenu isOpen>
              <Stack divider={<Divider />}>
                {availableVariants?.map((variant) => {
                  const { inventory, shippingOptions } = variant.fulfillmentPartnerList?.[0] ?? {}

                  return (
                    <PostalVariantOption
                      key={variant.id}
                      onClick={() => onSelect(addItemFieldsToVariant(marketplaceItem, variant))}
                      isSelected={isBulkSelected(variant)}
                      brandingColor={Color('atomicBlue.400')}
                      currency={marketplaceItem.currency as string}
                      name={variant.variantName}
                      description={variant.description}
                      imageUrl={variant.imageUrls?.[0]}
                      hideShipping={marketplaceItem.category === CATEGORY.Events}
                      displayPrice={variant.displayPrice || 0}
                      shippingEstimate={shippingOptions?.[0]?.price}
                      inventory={inventory?.available}
                      isLoading={false}
                      isMulti
                      largeCheckbox
                      mx={-3}
                      width="unset"
                    />
                  )
                })}
              </Stack>
            </UiMenu>
          ) : (
            <UiAlert status="warning">This item is currently unavailable.</UiAlert>
          )}
        </Box>
      </Grid>
    </Box>
  )
}

export const CollectionItemOptionsMarketplaceMultiVersion: React.FC<CollectionItemOptionsMarketplaceV2Props> = ({
  marketplaceItem,
  isBulkSelected,
  onSelect,
  ...rest
}) => {
  const optionDisclosure = useDisclosure()
  const { Color } = useColor()

  const availableVariants = useMemo(
    () => marketplaceItem?.variants?.filter((v) => v.status === Status.Active) || [],
    [marketplaceItem?.variants]
  )

  const selectedVariants = useMemo(() => availableVariants.filter(isBulkSelected), [availableVariants, isBulkSelected])

  const buttonText = useMemo(
    () =>
      listItemsWithMaxLength(
        selectedVariants.map((v) => v.variantName),
        28
      ) || 'Select options',
    [selectedVariants]
  )

  const { minPrice, maxPrice, hasMixedPrice } = getPriceRange(selectedVariants as ApprovedProductVariant[])

  return (
    <Box
      w="100%"
      {...rest}
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        w="100%"
      >
        <HStack
          p={8}
          pl={0}
          alignItems="flex-start"
          spacing={2.5}
        >
          <PostalSendSidebarImageDisplay
            flexShrink={0}
            postal={marketplaceItem as ApprovedPostal}
          />
          <Box>
            <ZHeading
              mt={2}
              as="h2"
              size="h6"
            >
              {marketplaceItem.name}
            </ZHeading>
            <ClampAndFade>
              <ZText
                my={2}
                fontSize="sm"
                color="atomicGray.600"
                whiteSpace="break-spaces"
              >
                <UiDangerous html={marketplaceItem.description} />
              </ZText>
            </ClampAndFade>
          </Box>
        </HStack>

        {availableVariants?.length ? (
          <UiMenu
            {...optionDisclosure}
            matchWidth
            closeOnSelect={false}
          >
            <UiMenuButton
              as={ZButton}
              h="40px"
              mt={14}
              pr={4}
              pl={4}
              width="330px"
              fontSize="sm"
              variant="outline"
              color="atomicGray.800"
              borderRadius={3}
              borderColor="atomicGray.200"
              _hover={{ bg: 'white', borderColor: 'atomicGray.400' }}
              _focus={{ bg: 'white', borderColor: 'atomicGray.500' }}
              rightIcon={
                <MdOutlineArrowDropDown
                  viewBox="2 2 20 20"
                  style={{
                    color: Color('atomicGray.500'),
                    transform: optionDisclosure.isOpen ? 'rotate(-180deg)' : '',
                    transition: 'transform 0.2s',
                  }}
                />
              }
              onClick={optionDisclosure.onToggle}
            >
              <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                color="atomicGray.800"
              >
                <ZText textAlign="left">{buttonText}</ZText>
                <Box>
                  {!!minPrice && (
                    <UiMoney
                      fontWeight="normal"
                      textAlign="right"
                      cents={minPrice}
                      round
                      currency={marketplaceItem.currency}
                    />
                  )}

                  {hasMixedPrice && (
                    <>
                      {' - '}
                      <UiMoney
                        fontWeight="normal"
                        textAlign="right"
                        cents={maxPrice}
                        round
                        currency={marketplaceItem.currency}
                      />
                    </>
                  )}
                </Box>
              </Flex>
            </UiMenuButton>
            <UiMenuList p={2}>
              {availableVariants?.map((variant) => {
                const { inventory, shippingOptions } = variant.fulfillmentPartnerList?.[0] ?? {}

                return (
                  <PostalVariantOption
                    key={variant.id}
                    onClick={() => onSelect(addItemFieldsToVariant(marketplaceItem, variant))}
                    isSelected={isBulkSelected(variant)}
                    brandingColor={Color('atomicBlue.400')}
                    currency={marketplaceItem.currency as string}
                    name={variant.variantName}
                    description={variant.description}
                    imageUrl={variant.imageUrls?.[0]}
                    hideShipping={marketplaceItem.category === CATEGORY.Events}
                    displayPrice={variant.displayPrice || 0}
                    shippingEstimate={shippingOptions?.[0]?.price}
                    inventory={inventory?.available}
                    isLoading={false}
                    isMulti
                  />
                )
              })}
            </UiMenuList>
          </UiMenu>
        ) : (
          <UiAlert status="warning">This item is currently unavailable.</UiAlert>
        )}
      </Flex>
      <Collapse in={!some(availableVariants, isBulkSelected) && !optionDisclosure.isOpen}>
        <ZAlert
          {...rest}
          status="warning"
          hideClose
          mb={5}
        >
          This item will be excluded from the collection if you do not select any options.
        </ZAlert>
      </Collapse>
    </Box>
  )
}
