import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiSkeleton,
  UiTabList,
  UiTabs,
  useAlerts,
  ZButton,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZSidebar,
  ZSidebarBanner,
  ZTab,
  ZText,
} from '@postal-io/postal-ui'
import { CenteredBox } from 'components/Common'
import { SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { UserSeatsAlert } from 'components/Users'
import { useAcl } from 'hooks'
import { StorageKeys } from 'lib'
import React, { useEffect, useMemo } from 'react'
import { MdOutlineChevronLeft, MdOutlineDelete } from 'react-icons/md'
import { matchPath, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { DeleteTeamDocument, GetAccountDocument, GetTeamByIdDocument, SearchUsersDocument } from '../../api'
import { DEFAULT_ROLES } from '../Users/UsersData'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

const TABS = ['members', 'invites', 'settings']

export const Team: React.FC = (props) => {
  const navigate = useNavigate()
  const { teamId } = useParams() as any

  const location = useLocation()

  const route = matchPath('/teams/:teamId/:tab', location.pathname)
  const tab = route?.params?.tab

  const isAccount = teamId === 'default'

  const Alert = useAlerts()

  const TAB_KEY = useMemo(() => {
    return `${StorageKeys.TeamTab}:${teamId}`
  }, [teamId])

  const getTeamQuery = useGraphqlQuery(GetTeamByIdDocument, { id: teamId }, { enabled: !isAccount })
  const team = useMemo(() => getTeamQuery?.data?.getTeamById, [getTeamQuery?.data?.getTeamById])

  // get account
  const getAccountQuery = useGraphqlQuery(GetAccountDocument, undefined)
  const account = useMemo(() => getAccountQuery?.data?.getAccount, [getAccountQuery?.data?.getAccount])

  const realTeam = useMemo(() => (isAccount ? account : team), [account, isAccount, team])

  const isLoading = getTeamQuery.isLoading || getAccountQuery.isLoading

  // HANDLE TABS
  const tabIndex = useMemo(() => TABS.findIndex((t) => t === tab), [tab])
  const onTabChange = (idx: number) => navigate(`/teams/${teamId}/${TABS[idx]}`)

  // DELETE
  const confirmDelete = useDisclosure()
  const { hasPermission } = useAcl()
  const canDelete = hasPermission('teams.delete') && !isAccount
  const deleteTeam = useGraphqlMutation(DeleteTeamDocument)
  const searchUsers = useGraphqlQuery(
    SearchUsersDocument,
    {
      filter: { productAccess: { teamId: { eq: teamId }, roles: { in: DEFAULT_ROLES } } },
      limit: 1,
    },
    { enabled: canDelete && confirmDelete.isOpen }
  )
  const hasUsers = useMemo(
    () => !!searchUsers.data?.searchUsers.users?.length,
    [searchUsers.data?.searchUsers.users?.length]
  )
  const onConfirm = async () => {
    try {
      await deleteTeam.mutateAsync({ id: team?.id as string })
      confirmDelete.onClose()
      navigate(`/teams`)
      Alert.info('Team Removed')
    } catch (e) {
      Alert.error(e)
    }
  }

  useEffect(() => {
    if (tabIndex > -1) return
    const prev = TABS.find((t) => t === sessionStorage.getItem(TAB_KEY))
    const next = prev || TABS[0]
    navigate(`/teams/${teamId}/${next}`)
  }, [TAB_KEY, navigate, tabIndex, teamId])

  useEffect(() => {
    if (tabIndex > -1) sessionStorage.setItem(TAB_KEY, TABS[tabIndex])
  }, [TAB_KEY, tabIndex])

  const handleBack = () => navigate('/teams')
  const teamName = realTeam?.name || team?.name || account?.name || '...Loading'

  return (
    <>
      <SecondaryNavbar
        maxWidth="1400px"
        left={
          <Flex
            alignItems="center"
            h="52px"
            color="white"
          >
            <ZLink
              color="white"
              onClick={handleBack}
              fontSize="14px"
              fontWeight="bold"
              display="flex"
              alignItems="center"
            >
              <MdOutlineChevronLeft size="28px" />
              Back to Teams
            </ZLink>
          </Flex>
        }
      />
      <CenteredBox isLoaded>
        <ZSidebar
          sidebarBlocks={<ProfileSidebarBlocks />}
          m={0}
          p={0}
        >
          <UserSeatsAlert />
          <ZSidebarBanner
            title={teamName}
            actions={
              canDelete && (
                <ZButton
                  variant="link"
                  color="atomicBlue.400"
                  leftIcon={<MdOutlineDelete size="24px" />}
                  size="sm"
                  height="21px"
                  onClick={confirmDelete.onOpen}
                >
                  Delete Team
                </ZButton>
              )
            }
          />
          <Box>
            <UiSkeleton
              isLoaded={!isLoading}
              {...props}
            >
              {tabIndex > -1 && (
                <UiTabs
                  tabIndex={tabIndex}
                  defaultIndex={tabIndex}
                  onChange={onTabChange}
                  mb={8}
                >
                  <UiTabList>
                    <ZTab>Members</ZTab>
                    <ZTab>Invitations</ZTab>
                    <ZTab>Settings</ZTab>
                  </UiTabList>
                </UiTabs>
              )}
            </UiSkeleton>
            <Outlet />
          </Box>
        </ZSidebar>
      </CenteredBox>

      {confirmDelete.isOpen && canDelete && (
        <ZModal
          size="md"
          isOpen={confirmDelete.isOpen}
          onClose={confirmDelete.onClose}
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Confirm Team Deletion</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <UiSkeleton
                isLoaded={!searchUsers.isFetching}
                textAlign="center"
              >
                {hasUsers ? (
                  <ZText fontSize="md">
                    You need to remove or reassign all users in this team before you can delete it.
                  </ZText>
                ) : (
                  <ZText fontSize="md">
                    Are you sure you want to <strong>Remove</strong> this team?
                  </ZText>
                )}
              </UiSkeleton>
            </ZModalBody>
            <ZModalButtons
              onConfirm={onConfirm}
              isConfirmLoading={searchUsers.isFetching}
              isConfirmDisabled={hasUsers}
              confirmText="Remove Team"
              confirmProps={{
                colorScheme: 'atomicRed',
              }}
              onClose={confirmDelete.onClose}
            />
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}
