import { Box, Flex, Image, List, ListItem } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import type { OrderByProps, UiCardProps, ZTextProps } from '@postal-io/postal-ui'
import { UiDate, useAlertError, ZHeading, ZMoney, ZText } from '@postal-io/postal-ui'
import type { PostalFulfillment } from 'api'
import { FulfillmentStatus, SearchContactPostalFulfillmentsDocument, SearchPostalFulfillmentsDocument } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { PostalSendMethod, SEND_METHOD_COLORS } from 'components/PostalSend/usePostalSend'
import { useAssets } from 'hooks'
import React, { useMemo } from 'react'

const LIMIT = 3

const DEFAULT_SORT = { key: 'created', direction: 'desc' } as OrderByProps

interface PostalFulfillmentsListProps extends UiCardProps {
  userId?: string
  contactId?: string
  status?: FulfillmentStatus
  statuses?: FulfillmentStatus[]
  magicLinkId?: string
  campaignId?: string
  playbookId?: string
  playbookDefinitionId?: string
  useContactSearch?: boolean
}

export const PostalFulfillmentsList: React.FC<PostalFulfillmentsListProps> = ({
  userId,
  contactId,
  magicLinkId,
  campaignId,
  playbookId,
  playbookDefinitionId,
  useContactSearch,
  status,
  statuses,
  ...rest
}) => {
  const orderByString =
    DEFAULT_SORT?.key && DEFAULT_SORT.direction
      ? `${DEFAULT_SORT.key}_${DEFAULT_SORT.direction.toUpperCase()}`
      : undefined

  const variables = useMemo(() => {
    const _variables = {
      filter: {},
      limit: LIMIT,
      orderBy: orderByString,
    } as any
    if (contactId) {
      if (useContactSearch) {
        _variables.contactId = contactId
      } else {
        _variables.filter.contactId = { eq: contactId }
      }
    }
    if (status) _variables.filter.status = { eq: status }
    if (statuses) _variables.filter.status = { in: statuses }
    if (userId) _variables.filter.userId = { eq: userId }
    if (magicLinkId) {
      _variables.filter.magicLinkId = { eq: magicLinkId }
    }
    if (campaignId) _variables.filter.campaignId = { eq: campaignId }
    if (playbookId) _variables.filter.playbookId = { eq: playbookId }
    if (playbookDefinitionId) _variables.filter.playbookDefinitionId = { eq: playbookDefinitionId }
    return _variables
  }, [
    orderByString,
    contactId,
    status,
    statuses,
    userId,
    magicLinkId,
    campaignId,
    playbookId,
    playbookDefinitionId,
    useContactSearch,
  ])

  const hasSearchProp =
    !!contactId || !!userId || !!magicLinkId || !!campaignId || !!playbookId || !!playbookDefinitionId

  // search postal fulfillments
  const searchPostalFulfillments = useGraphqlInfiniteQuery(SearchPostalFulfillmentsDocument, variables, {
    enabled: !useContactSearch && hasSearchProp,
  })

  // search all contact related fulfillments
  const searchContactPostalFulfillments = useGraphqlInfiniteQuery(SearchContactPostalFulfillmentsDocument, variables, {
    enabled: !!useContactSearch && hasSearchProp,
  })

  const { error } = useMemo(() => {
    return useContactSearch ? searchContactPostalFulfillments : searchPostalFulfillments
  }, [searchContactPostalFulfillments, searchPostalFulfillments, useContactSearch])

  // we need to return the data depending on what query we are using
  const postalFulfillments = useMemo(() => {
    return useContactSearch
      ? searchContactPostalFulfillments.mergedData?.searchContactPostalFulfillments
      : searchPostalFulfillments.mergedData?.searchPostalFulfillments
  }, [
    searchContactPostalFulfillments.mergedData?.searchContactPostalFulfillments,
    searchPostalFulfillments.mergedData?.searchPostalFulfillments,
    useContactSearch,
  ])

  useAlertError(error)

  return (
    <Box {...rest}>
      <ZHeading
        as="h4"
        fontSize="sm"
        fontWeight="normal"
        borderBottom="1px solid"
        borderBottomColor="atomicGray.200"
        pb={2.5}
      >
        Recent Activity
      </ZHeading>
      <List
        spacing={5}
        my={3}
      >
        {postalFulfillments?.map((fulfillment) => (
          <FulfillmentListItem fulfillment={fulfillment} />
        ))}
        {!postalFulfillments?.length && (
          <ListItem textAlign="center">
            <ZText color="atomicGray.500">No Recent Activity</ZText>
          </ListItem>
        )}
      </List>
    </Box>
  )
}

const FulfillmentListItem: React.FC<{ fulfillment: PostalFulfillment }> = ({ fulfillment }) => {
  const { assetUrlSrc } = useAssets()
  const srcProps = assetUrlSrc(fulfillment.previewImage, { fit: 'crop', crop: 'edges', w: 100, h: 100 })

  const deliveredDate = useMemo(
    () =>
      fulfillment.history?.find((item) =>
        [FulfillmentStatus.Delivered, FulfillmentStatus.DeliveredAssumed].includes(item.status)
      )?.created,
    [fulfillment.history]
  )

  // const estDeliveryDate = useMemo(
  //   () =>
  //     fulfillment.history?.find((item) =>
  //       [FulfillmentStatus.PendingDelivery, FulfillmentStatus.OutForDelivery].includes(item.status)
  //     )?.targetDeliveryDate,
  //   [fulfillment.history]
  // )

  return (
    <ListItem>
      <Flex alignItems="center">
        {fulfillment.previewImage && (
          <Image
            {...srcProps}
            w="55px"
            h="55px"
            borderRadius={5}
            mr={4}
          />
        )}
        <Flex
          flexDir="column"
          alignItems="left"
        >
          <ZText
            fontSize="sm"
            fontWeight="normal"
          >
            {fulfillment.itemName}
          </ZText>
          <FulfillmentTypeDisplay
            fulfillment={fulfillment}
            fontWeight={350}
          />
          <Flex alignItems="center">
            <ZMoney
              cents={fulfillment.cost?.total}
              currency={fulfillment.cost?.currency}
              mr={1}
              fontSize="xs"
            />
            <ZText
              color="atomicGray.500"
              fontWeight="normal"
              fontSize="xs"
            >
              Delivered <UiDate date={deliveredDate} />
              {fulfillment.thankYouMessage && (
                <ZInfoTooltip
                  ml={0.5}
                  boxSize="12px"
                  label={`Thank you Note: ${fulfillment.thankYouMessage}`}
                />
              )}
            </ZText>
          </Flex>
        </Flex>
      </Flex>
    </ListItem>
  )
}

interface FulfillmentTypeDisplayProps extends ZTextProps {
  fulfillment: PostalFulfillment
}
export const FulfillmentTypeDisplay: React.FC<FulfillmentTypeDisplayProps> = ({ fulfillment, ...rest }) => {
  const fulfillmentType = useMemo(() => {
    if (fulfillment.magicLinkId) return PostalSendMethod.Link
    if (fulfillment.deliveryEmail) return PostalSendMethod.Email
    return PostalSendMethod.Direct
  }, [fulfillment])

  const text = useMemo(() => {
    switch (fulfillmentType) {
      case PostalSendMethod.Link:
        return 'Magic Link'
      case PostalSendMethod.Email:
        return 'Email'
      case PostalSendMethod.Direct:
        return 'Direct'
      default:
        return 'Automation'
    }
  }, [fulfillmentType])

  const color = SEND_METHOD_COLORS[fulfillmentType] ?? 'atomicGray.800'

  return (
    <ZText
      fontSize="xs"
      color={color}
      {...rest}
    >
      {text}
    </ZText>
  )
}
