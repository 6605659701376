import { LinkIcon } from '@chakra-ui/icons'
import type { ButtonProps } from '@chakra-ui/react'
import { Box, Button, Flex, HStack, Icon, Text, useDisclosure } from '@chakra-ui/react'
import type { UiCardProps, UiLiftUpEffectProps } from '@postal-io/postal-ui'
import {
  UiCard,
  UiHeading3,
  UiIconButton,
  UiIconPostalPlane,
  UiLiftUpEffect,
  UiTooltip,
  ZButton,
} from '@postal-io/postal-ui'
import type { ApprovedPostal } from 'api'
import hoverBackgroundImage from 'assets/images/border-images/add-item-card-dashed-border.svg'
import { MARKETPLACE_CARD_MIN_WIDTH } from 'components/Postals'
import { PostalSendMethod } from 'components/PostalSend/postalSendHelpers'
import { useAcl, useCollectionPermissions, useNavigateSendFlow } from 'hooks'
import React, { useMemo } from 'react'
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import { useNavigate } from 'react-router'
import { CollectionInfo } from './CollectionInfo'
import { Owner, TOP_LEVEL_CARD_HEIGHT } from './data'

const iconProps = {
  color: 'white',
  boxSize: '40px',
  p: 2,
}

export const CollectionCardCreate: React.FC<ButtonProps & { maxItems: number; errorMessage?: string }> = ({
  maxItems,
  onClick,
  errorMessage,
  ...rest
}) => {
  return (
    <UiTooltip label={errorMessage}>
      <Button
        aria-label="Create Collection"
        variant="unstyled"
        isDisabled={!!errorMessage}
        borderRadius={5}
        w="100%"
        h="100%"
        bg="none"
        _hover={{ 'transform': 'scale(1.02)', '& .hover': { opacity: 1 } }}
        transition="0.2s filter, 0.2s transform, 0.2s background-image"
        onClick={!!errorMessage ? undefined : onClick}
        position="relative"
        {...rest}
      >
        <Box color="gray.600">
          <Icon
            as={MdOutlineAddCircleOutline}
            fontSize="40px"
            color="atomicBlue.500"
          />
          <Text
            fontSize="lg"
            fontWeight="semibold"
            color="atomicBlue.400"
          >
            Add an Item(s)
          </Text>
          <Text
            fontSize="md"
            fontWeight="normal"
            color="atomicGray.500"
          >
            Up to {maxItems}
          </Text>
        </Box>
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          className="hover"
          opacity={0}
          borderRadius={5}
          backgroundImage={hoverBackgroundImage}
          transition="0.2s opacity"
        />
      </Button>
    </UiTooltip>
  )
}

export const CollectionNotFound: React.FC<UiCardProps & { includingDrafts?: boolean; owner?: Owner }> = ({
  includingDrafts,
  owner,
  ...rest
}) => {
  const { hasFeature } = useAcl()

  const statusText = useMemo(() => (includingDrafts ? '' : 'active'), [includingDrafts])

  const ownerText = useMemo(() => {
    if (!hasFeature('userCreatedCollections')) return ''
    switch (owner) {
      case Owner.Me:
        return 'private'
      case Owner.Shared:
        return 'team'
      default:
        return ''
    }
  }, [hasFeature, owner])

  return (
    <UiCard
      h={MARKETPLACE_CARD_MIN_WIDTH}
      w={MARKETPLACE_CARD_MIN_WIDTH}
      borderRadius="0.65rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        p={4}
      >
        <UiHeading3
          size="md"
          mb={2}
          fontWeight="normal"
        >
          Not Found
        </UiHeading3>
        <Text
          color="gray.600"
          textAlign="center"
        >
          No {statusText ? <strong>{statusText}&nbsp;</strong> : ''}
          {ownerText ? <span>{ownerText}&nbsp;</span> : ''}collections were found matching this search criteria.
        </Text>
      </Flex>
    </UiCard>
  )
}

export const CollectionLoading: React.FC<UiCardProps> = (props) => {
  return (
    <UiCard
      isLoading
      h={TOP_LEVEL_CARD_HEIGHT}
      w={MARKETPLACE_CARD_MIN_WIDTH}
      borderRadius="0.65rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    />
  )
}

interface CollectionCardProps extends UiLiftUpEffectProps {
  collection: ApprovedPostal
}

export const CollectionCard: React.FC<CollectionCardProps> = ({ collection }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { canSend, canLink } = useCollectionPermissions(collection)
  const navigate = useNavigate()
  const sendFlowLink = useNavigateSendFlow()

  const handleAction = (type: string) => {
    switch (type) {
      case 'VIEW':
        navigate(`/collections/${collection?.id}`, { state: { returnTo: 'Collections' } })
        break
      case 'SEND':
        canSend && navigate(sendFlowLink(`/collections/${collection.id}/send`, { returnTo: 'Collections' }))
        break
      case 'CREATE_LINK':
        canLink &&
          navigate(
            sendFlowLink(`/collections/${collection.id}/send`, {
              returnTo: 'Collections',
              sendMethod: PostalSendMethod.Link,
            })
          )
        break
      default:
    }
  }

  return (
    <UiLiftUpEffect
      borderRadius="0.65rem"
      pos="relative"
      cursor="auto"
    >
      <UiCard
        justifyContent="center"
        alignItems="center"
        h={TOP_LEVEL_CARD_HEIGHT}
        w={MARKETPLACE_CARD_MIN_WIDTH}
        borderWidth="2px"
        borderColor="gray.100"
        boxShadow="none"
        borderRadius="0.65rem"
        display="flex"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        p={0}
      >
        <CollectionInfo collection={collection} />
        <Hoverview
          isOpen={isOpen}
          handleAction={handleAction}
          canSend={canSend}
          canLink={canLink}
        />
      </UiCard>
    </UiLiftUpEffect>
  )
}

interface HoverviewProps {
  isOpen: boolean
  handleAction: (type: string) => void
  canSend?: boolean
  canLink?: boolean
}

const Hoverview: React.FC<HoverviewProps> = ({ isOpen, canSend, canLink, handleAction }) => {
  return (
    <Flex
      position="absolute"
      w={MARKETPLACE_CARD_MIN_WIDTH}
      h={TOP_LEVEL_CARD_HEIGHT}
      direction="column"
      alignItems="center"
      justifyContent="center"
      borderRadius="0.65rem"
    >
      <Box
        pos="absolute"
        borderRadius="0.65rem"
        top={0}
        left={0}
        w="100%"
        h="100%"
        backgroundColor="white"
        opacity={isOpen ? 0.9 : 0}
        transition="opacity 200ms ease-in"
      />
      {(canSend || canLink) && (
        <HStack
          spacing={4}
          opacity={isOpen ? 1 : 0}
        >
          {canSend && (
            <UiIconButton
              colorScheme="atomicBlue"
              icon={<UiIconPostalPlane {...iconProps} />}
              aria-label="Send Item"
              title="Send Item"
              onClick={() => handleAction('SEND')}
              isRound
              boxSize="55px"
            />
          )}
          {canLink && (
            <UiIconButton
              colorScheme="secondary"
              icon={<LinkIcon {...iconProps} />}
              aria-label="Create MagicLink"
              title="Create MagicLink"
              onClick={() => handleAction('CREATE_LINK')}
              isRound
              boxSize="55px"
            />
          )}
        </HStack>
      )}
      <ZButton
        width="90%"
        colorScheme="atomicBlue"
        mx="auto"
        height="55px"
        mt={5}
        opacity={isOpen ? 1 : 0}
        position="absolute"
        bottom="15px"
        left={0}
        right={0}
        onClick={() => handleAction('VIEW')}
      >
        View Collection
      </ZButton>
    </Flex>
  )
}
