import { Grid } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiFormControl,
  UiToggle,
  useAlertError,
  useAlerts,
  ZCard,
  ZCardHeader,
  ZFormLabel,
  ZSelect,
} from '@postal-io/postal-ui'
import type { EmailDigestSettings } from 'api'
import { EmailDigestFrequency, MeDocument, UpdateSelfDocument } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'

export const EmailDigestSettingsDisplay: React.FC = (props) => {
  const Alert = useAlerts()
  const meQuery = useGraphqlQuery(MeDocument)
  const updateSelf = useGraphqlMutation(UpdateSelfDocument)

  const emailDigestSettings = useMemo(
    () => meQuery.data?.me?.emailDigestSettings || ({} as EmailDigestSettings),
    [meQuery.data?.me?.emailDigestSettings]
  )

  const [form, setForm] = useImmer<Record<string, any>>({
    frequency: EmailDigestFrequency.Never,
  })

  const resetForm = useCallback(() => {
    setForm((draft: any) => {
      draft.frequency = emailDigestSettings.frequency
      draft.processingErrors = emailDigestSettings.processingErrors
      draft.ordersRequiringUserApproval = emailDigestSettings.ordersRequiringUserApproval
      draft.itemRemoved = emailDigestSettings.itemRemoved
      draft.newItemApproved = emailDigestSettings.newItemApproved
      draft.newEventApproved = emailDigestSettings.newEventApproved
      draft.newCollectionApproved = emailDigestSettings.newCollectionApproved
    })
  }, [emailDigestSettings, setForm])

  useEffect(() => {
    resetForm()
  }, [resetForm])

  const handleChange = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = target
    setForm((draft: Record<string, any>) => {
      draft[name] = checked
    })
    const changedEmailDigestSettings = { ...form, [name]: checked } as any
    try {
      await updateSelf.mutateAsync({ data: { emailDigestSettings: changedEmailDigestSettings } })
      Alert.success('Setting Updated')
    } catch (err) {
      Alert.error(err)
      resetForm()
    }
  }

  const handleChangeSelect = async ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = target
    setForm((draft: Record<string, any>) => {
      draft[name] = value
    })
    const changedEmailDigestSettings = { ...form, [name]: value } as any
    try {
      await updateSelf.mutateAsync({ data: { emailDigestSettings: changedEmailDigestSettings } })
      Alert.success('Setting Updated')
    } catch (err) {
      Alert.error(err)
      resetForm()
    }
  }

  const canToggleSetting = [EmailDigestFrequency.Daily, EmailDigestFrequency.Weekly].includes(form.frequency)
  const toggleStyles = {
    opacity: canToggleSetting ? 1 : 0.5,
    transition: 'opacity linear 100ms',
  }

  useAlertError(meQuery.error)
  useAlertError(updateSelf.error)

  const labelProps = {
    fontSize: 'md',
    fontWeight: 'normal',
    color: 'atomicGray.600',
    m: 0,
    ml: 4,
  }

  return (
    <>
      <ZCard
        isLoading={false}
        variant="form"
        {...props}
      >
        <ZCardHeader
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          p={8}
          pb={0}
          gap={2}
        >
          Notification Summary Email
          <ZInfoTooltip
            hasArrow
            label="These settings affect the notification type and frequency of the digest email and will not affect your other email notification settings."
          />
        </ZCardHeader>

        <Grid
          gap={8}
          p={8}
        >
          <UiFormControl
            id="frequency"
            display="flex"
            flexDir="column"
            justifyContent="center"
          >
            <ZFormLabel
              fontSize="lg"
              color="atomicGray.600"
            >
              Frequency
            </ZFormLabel>
            <ZSelect
              mt={2}
              id="frequency"
              value={form.frequency}
              name="frequency"
              onChange={handleChangeSelect}
            >
              <option value={EmailDigestFrequency.Never}>Never</option>
              <option value={EmailDigestFrequency.Daily}>Daily</option>
              <option value={EmailDigestFrequency.Weekly}>Weekly</option>
            </ZSelect>
          </UiFormControl>

          <UiFormControl
            id="processingErrors"
            display="flex"
            alignItems="center"
            sx={toggleStyles}
          >
            <UiToggle
              size="lg"
              name="processingErrors"
              isChecked={!!form.processingErrors}
              isDisabled={!canToggleSetting}
              onChange={handleChange}
              colorScheme="atomicBlue"
            />
            <ZFormLabel {...labelProps}>Processing Errors</ZFormLabel>
          </UiFormControl>

          <UiFormControl
            id="ordersRequiringUserApproval"
            display="flex"
            alignItems="center"
            sx={toggleStyles}
          >
            <UiToggle
              size="lg"
              name="ordersRequiringUserApproval"
              isChecked={!!form.ordersRequiringUserApproval}
              isDisabled={!canToggleSetting}
              onChange={handleChange}
              colorScheme="atomicBlue"
            />
            <ZFormLabel {...labelProps}>MagicLink Approvals</ZFormLabel>
          </UiFormControl>

          <UiFormControl
            id="itemRemoved"
            display="flex"
            alignItems="center"
            sx={toggleStyles}
          >
            <UiToggle
              size="lg"
              name="itemRemoved"
              isChecked={!!form.itemRemoved}
              isDisabled={!canToggleSetting}
              onChange={handleChange}
              colorScheme="atomicBlue"
            />
            <ZFormLabel {...labelProps}>Item Removed</ZFormLabel>
          </UiFormControl>

          <ZCardHeader
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={0}
            color="atomicGray.600"
            sx={toggleStyles}
          >
            New Approvals
          </ZCardHeader>

          <UiFormControl
            id="newItemApproved"
            display="flex"
            alignItems="center"
            sx={toggleStyles}
            mt={-2}
          >
            <UiToggle
              size="lg"
              name="newItemApproved"
              isChecked={!!form.newItemApproved}
              isDisabled={!canToggleSetting}
              onChange={handleChange}
              colorScheme="atomicBlue"
            />
            <ZFormLabel {...labelProps}>New Item</ZFormLabel>
          </UiFormControl>

          <UiFormControl
            id="newEventApproved"
            display="flex"
            alignItems="center"
            sx={toggleStyles}
          >
            <UiToggle
              size="lg"
              name="newEventApproved"
              isChecked={!!form.newEventApproved}
              isDisabled={!canToggleSetting}
              onChange={handleChange}
              colorScheme="atomicBlue"
            />
            <ZFormLabel {...labelProps}>New Event</ZFormLabel>
          </UiFormControl>

          <UiFormControl
            id="newCollectionApproved"
            display="flex"
            alignItems="center"
            sx={toggleStyles}
          >
            <UiToggle
              size="lg"
              name="newCollectionApproved"
              isChecked={!!form.newCollectionApproved}
              isDisabled={!canToggleSetting}
              onChange={handleChange}
              colorScheme="atomicBlue"
            />
            <ZFormLabel {...labelProps}>New Collection</ZFormLabel>
          </UiFormControl>
        </Grid>
      </ZCard>
    </>
  )
}
