import { CheckCircleIcon } from '@chakra-ui/icons'
import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Tag, Text } from '@chakra-ui/react'
import { UiLink, UiSeparator, ZText } from '@postal-io/postal-ui'
import type { AddressInput } from 'api'
import { AddressSource, AddressStatus } from 'api'
import compact from 'lodash/compact'
import React from 'react'
import { hasVerifiedAddress } from '../Contacts/ContactsData'

interface ContactEditAddressBlockProps extends BoxProps {
  address: AddressInput
  addressVisible?: boolean
  idx: number
  handleEditAddress: (idx: number) => void
  removeAddressHandler: (idx: number) => void
  preferredAddressHandler: (idx: number) => void
}

export const ContactEditAddressBlock: React.FC<ContactEditAddressBlockProps> = ({
  address,
  addressVisible = true,
  idx,
  handleEditAddress,
  removeAddressHandler,
  preferredAddressHandler,
}) => {
  const isIntegrationAddress = address.source && ![AddressSource.Manual, AddressSource.Refresh].includes(address.source)

  return (
    <Box
      key={idx}
      data-testid={`contact-edit-address-${idx}`}
    >
      <Flex justifyContent="space-between">
        <Flex
          direction="column"
          {...(!addressVisible
            ? {
                position: 'relative',
                userSelect: 'none',
                p: 2,
              }
            : {})}
        >
          {address?.entryName && <Text fontWeight="bolder">{address.entryName}</Text>}
          <Text data-private>{address?.address1}</Text>
          {address?.address2 && <Text data-private>{address.address2}</Text>}
          {address?.address3 && <Text data-private>{address.address3}</Text>}
          <Text data-private>
            {compact([address.city, address.state]).join(', ')} {address.postalCode}
          </Text>
          {address?.country && <Text data-private>{address.country}</Text>}

          {addressVisible && (
            <UiSeparator
              separator={
                <Text
                  as="span"
                  color="gray.600"
                >
                  {' | '}
                </Text>
              }
            >
              <UiLink onClick={() => handleEditAddress(idx)}>Edit</UiLink>
              {(!isIntegrationAddress || !address?.source) && (
                <UiLink onClick={() => removeAddressHandler(idx)}>Remove</UiLink>
              )}
              {!address?.preferred && <UiLink onClick={() => preferredAddressHandler(idx)}>Set as default</UiLink>}
            </UiSeparator>
          )}

          {!addressVisible && <HideAddressOverlay />}
        </Flex>
        <Flex
          direction="column"
          data-testid="addressStatusLabel"
          alignItems="center"
        >
          {hasVerifiedAddress(address.status) ? (
            <Flex
              align="center"
              mb={2}
            >
              <CheckCircleIcon
                color="tertiary.500"
                boxSize="16px"
              />
              <Text
                ml={1}
                color="tertiary.500"
                display="inline"
                fontSize="sm"
              >
                {address.status === AddressStatus.MatchedOverride ? 'Manually Verified' : 'Verified'}
              </Text>
            </Flex>
          ) : (
            <Box color="red.500">Not Verified</Box>
          )}

          {address?.preferred && (
            <Tag
              data-testid="preferred-address-tag"
              w="100px"
              size="sm"
              bg="tertiary.500"
              borderRadius="full"
              color="white"
              px={4}
              py={1}
              fontWeight={500}
              justifyContent="center"
            >
              Preferred
            </Tag>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

const HideAddressOverlay: React.FC = () => (
  <Flex
    alignItems="center"
    position="absolute"
    left={0}
    top={0}
    bottom={0}
    right={0}
    backdropFilter="blur(5px)"
  >
    <ZText
      py={1}
      px={2}
      ml={5}
      borderRadius={3}
      boxShadow="0 0 10px rgba(0,0,0,.2)"
      bg="#FFFA"
      color="atomicGray.500"
      backdropFilter="blur(20px)"
    >
      Address hidden
    </ZText>
  </Flex>
)
