import { Stack } from '@chakra-ui/react'
import { ZSidebar, ZSidebarBanner } from '@postal-io/postal-ui'
import { BalanceTransferCreateCard } from 'components/Billing/BalanceTransferCreateCard'
import { BalanceTransferHistoryCard } from 'components/Billing/BalanceTransferHistoryCard'
import { CenteredBox } from 'components/Common'
import { PageTitle } from 'hooks'
import React from 'react'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

export const Transfers: React.FC = () => {
  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner title="Transfers" />
        <PageTitle title="Transfers" />
        <Stack spacing={8}>
          <BalanceTransferCreateCard />
          <BalanceTransferHistoryCard />
        </Stack>
      </ZSidebar>
    </CenteredBox>
  )
}
