import { Box, Container, Flex, Grid, VStack } from '@chakra-ui/react'
import { GraphqlMocks, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  SelectTypeaheadStylesV2,
  UiSelectTypeahead,
  UiTooltip,
  useAlertError,
  ZButton,
  ZText,
} from '@postal-io/postal-ui'
import { CrmReportType, DataObjectType, GetCrmReportDocument, SearchIntegrationSyncDocument, Status } from 'api'
import { StickyElementWithShadowV2 } from 'components/Common/StickyElementWithShadowV2'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { ExternalProviders, ExternalSystem } from 'components/Integrations'
import { getCrmReport } from 'components/Reporting/Impact/mocks'
import { formatDistance } from 'date-fns'
import { PageTitle } from 'hooks'
import { isLocalOrTestEnvironment } from 'lib'
import { useMemo, useState } from 'react'
import { MdOutlineRefresh } from 'react-icons/md'
import { useQueryClient } from 'react-query'
import { ClosedWon } from './ClosedWon'
import { OpenPipeline } from './OpenPipeline'
import { Opportunities } from './Opportunities'
import { Pipeline } from './Pipeline'
import { WonRevenue } from './WonRevenue'

if (isLocalOrTestEnvironment) GraphqlMocks.set('getCrmReport', getCrmReport)

const CachingInfoZText = (
  <>
    Salesforce reports are kept for up to 6 hours. Click the{' '}
    <ZText
      as="span"
      fontWeight="800"
      color="white"
    >
      Re-Run Report
    </ZText>{' '}
    button to the right for the most up-to-date data.
  </>
)

export const Impact = () => {
  const [refresh, setRefresh] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const [selectedSystem, setSelectedSystem] = useState<string>()

  // Figure out which systems we have available
  const systemQuery = useGraphqlQuery(SearchIntegrationSyncDocument, {
    filter: {
      objectType: { eq: DataObjectType.User },
      status: { eq: Status.Active },
      system: { in: [ExternalSystem.Salesforce, ExternalSystem.SalesforceSandbox] },
    },
  })
  const availableSystems = useMemo(
    () => systemQuery.data?.searchIntegrationSync ?? [],
    [systemQuery.data?.searchIntegrationSync]
  )
  const system = useMemo(() => selectedSystem ?? availableSystems[0]?.system, [availableSystems, selectedSystem])

  const reportQuery = useGraphqlQuery(
    GetCrmReportDocument,
    {
      system,
      reportType: CrmReportType.WonRevenue,
      refresh,
    },
    { enabled: !!system, staleTime: 1000 * 60 }
  )
  useAlertError(reportQuery.error)

  const triggerRefresh = () => {
    queryClient.invalidateQueries('getCrmReport')
    setRefresh(true)
  }

  const lastRunTime = useMemo(
    () =>
      formatDistance(new Date(reportQuery?.data?.getCrmReport?.created ?? new Date()), new Date(), { addSuffix: true }),
    [reportQuery?.data?.getCrmReport.created]
  )

  return (
    <Box>
      <PageTitle
        title="Impact and ROI"
        section="Reporting"
      />
      <StickyElementWithShadowV2
        pb={2}
        px={0}
        zIndex={2}
        overflow="visible"
      >
        <Flex
          justifyContent="flex-end"
          alignItems="center"
          flexWrap="wrap"
          gridGap={4}
        >
          {reportQuery.data?.getCrmReport && (
            <ZText
              display="inline-flex"
              alignItems="center"
              gap={1}
              color="atomicGray.500"
            >
              Report Run: {lastRunTime}
              <Box
                as="span"
                position="relative"
              >
                <ZInfoTooltip
                  placement="bottom-end"
                  label={CachingInfoZText}
                />
              </Box>
            </ZText>
          )}
          {availableSystems.length > 1 && (
            <ZText
              display="inline-flex"
              alignItems="center"
              gap={2}
              color="atomicGray.500"
            >
              System:{' '}
              <UiSelectTypeahead
                id="systemName"
                isClearable={false}
                hideSelectedOptions={false}
                onChange={(val) => val && setSelectedSystem(val?.system)}
                options={availableSystems!}
                getOptionLabel={(opt) => ExternalProviders.find((p) => p.system === opt.system)?.name!}
                getOptionValue={(opt) => opt.system}
                placeholder="Select a System"
                value={availableSystems.find((s) => s.system === system)}
                {...SelectTypeaheadStylesV2}
              />
            </ZText>
          )}
          <UiTooltip
            label="Re-Run Report"
            openDelay={500}
          >
            <ZButton
              onClick={triggerRefresh}
              variant="outline"
              colorScheme="atomicGray"
              borderColor="atomicGray.300"
              p={2}
              size="sm"
              ml={4}
            >
              <MdOutlineRefresh size="16px" />
            </ZButton>
          </UiTooltip>
        </Flex>
      </StickyElementWithShadowV2>
      <Container
        maxW="1440px"
        px={0}
        mt={2}
      >
        <Grid
          templateColumns="1fr 1fr"
          gridGap={8}
        >
          <VStack spacing={8}>
            <Box
              width="full"
              display="inline-flex"
              flexDir="column"
            >
              <WonRevenue
                mb={8}
                refresh={refresh}
                system={system}
              />
              <ClosedWon
                refresh={refresh}
                system={system}
              />
            </Box>
            <OpenPipeline
              verticalAlign="top"
              refresh={refresh}
              system={system}
              width="100%"
              h="100%"
            />
          </VStack>
          <VStack spacing={8}>
            <Pipeline
              refresh={refresh}
              system={system}
              w="100%"
            />
            <Opportunities
              refresh={refresh}
              system={system}
              w="100%"
            />
          </VStack>
        </Grid>
      </Container>
    </Box>
  )
}
