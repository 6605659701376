import type { FlexProps } from '@chakra-ui/react'
import { Box, FormControl, Grid, Stack } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiChangeEvent } from '@postal-io/postal-ui'
import {
  humanize,
  SelectTypeaheadStylesV2,
  UiSelectTypeahead,
  UiSidePanel,
  UiSidePanelBlock,
  UiToggle,
  useAlertError,
  ZCurrencyCheckbox,
  ZCurrencyCheckboxGroup,
} from '@postal-io/postal-ui'
import { AutoCompleteTeams } from 'components/AutoComplete'
import { Owner } from 'components/Collections/data'
import { CATEGORY } from 'components/Postals'
import type { UsePostalFiltersV2Response } from 'hooks'
import { useAcl } from 'hooks/useAcl'
import { identity, orderBy } from 'lodash'
import type { ReactNode } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type { MultiValue } from 'react-select'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import { SidePanelHeader } from '.'
import type { MarketplaceFilterOptionV2, MarketplaceFilterV2 } from '../../api'
import {
  FilterType,
  FulfillmentType,
  GetApprovedPostalBrandsCategoriesDocument,
  GetBrandsCategoriesDocument,
  Role,
} from '../../api'

/*
  TODO:
  - restrict currencies in all cases
  -
*/

const filterAcl: Record<string, Record<string, string>> = {
  useCases: { feature: 'premium' },
  currency: { feature: 'internationalization' },
}

// these filters are not cleaned up based on the available filters returned from the backend
// const SPECIAL_FILTERS = [
//   'status',
//   'owner',
//   'q',
//   'price',
//   'eventAvailabilityDates',
//   'teamIds',
//   'brandName',
//   'subCategory',
//   'fulfillmentPartnerName',
//   'showOutOfStock',
//   'showDraft',
//   'favorites',
// ]

// filters to show if no category selected
const NO_CATEGORY_FILTERS = [
  'categories',
  'minCost',
  'type',
  'shipToStates',
  'shipToCountries',
  'useCases',
  'currency',
  'occasion',
]

// put these at top of filters
const PRIORITY_FILTERS = ['minCost', 'categories', 'type', 'currency']
const HIDDEN_FILTERS = ['rating']

const typeRank = (type: string) => {
  switch (type) {
    case 'EQUALS':
      return 1
    case 'RANGE':
      return 1
    case 'BOOL':
      return 2
    default:
      return 3
  }
}

interface MarketplaceFilterValueV2 extends Omit<MarketplaceFilterOptionV2, 'count'> {
  count?: number
}

interface MarketplaceFilterAndValueV2 extends MarketplaceFilterV2 {
  currentValue: MarketplaceFilterValueV2
}

/*
    Here we are overriding the option labels on select and multiselect components.

    This reason we are doing this is because we have business/ux decisions that
    we want to run against our dynamically created filters and it would be painful
    to change the underlying data.
  */
const getOptionLabel = (name: string, option: MarketplaceFilterValueV2, options: any[], isEvent?: boolean) => {
  if (isEvent && name === 'fulfillmentType') {
    switch (option.value) {
      case FulfillmentType.Physical:
        return 'Physical Event Kit'
      case FulfillmentType.EventFee:
        return 'No Physical Event Kit'
    }
  }
  if (option.name && option.count !== undefined) return `${option.name} (${option.count})`
  else return option.name
}

const handleSortFilter = (a: MarketplaceFilterAndValueV2, b: MarketplaceFilterAndValueV2) => {
  const nameA = a.name.toLowerCase()
  const nameB = b.name.toLowerCase()

  const priorityIdxA = PRIORITY_FILTERS.findIndex((f) => f === a.name)
  const priorityIdxB = PRIORITY_FILTERS.findIndex((f) => f === b.name)

  if (priorityIdxA !== -1 && priorityIdxB !== -1) return priorityIdxA < priorityIdxB ? -1 : 1
  if (priorityIdxA !== -1 && priorityIdxB === -1) return -1
  if (priorityIdxA === -1 && priorityIdxB !== -1) return 1

  if (typeRank(a.type) < typeRank(b.type)) return -1
  if (typeRank(a.type) > typeRank(b.type)) return 1

  if (a.currentValue && a.type !== FilterType.Bool && !b.currentValue) return -1
  if (b.currentValue && b.type !== FilterType.Bool && !a.currentValue) return 1

  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0
}

export interface SidePanelFilterMarketplaceV2Props extends FlexProps {
  /**
   * Allow the user to see/toggle the status filter
   */
  showDraft?: boolean
  /**
   * Form filters that will be translated into graphql variables
   */
  filters: UsePostalFiltersV2Response['filters']
  /**
   * Marketplace or ApprovedPostal
   */
  filterType: 'Marketplace' | 'ApprovedPostal' | 'Collections'
  /**
   * Available filters passed in from the marketplace v2 endpoint
   */
  marketplaceV2AvailableFilters?: MarketplaceFilterV2[] | null
  /**
   * Available filters are loading
   */
  isLoading?: boolean
  /**
   * An array of filter key names that should be excluded from view
   */
  excludeFilters?: string[]
  /**
   * An array of filter key names that will be included in the view, excluding the rest.
   */
  includeFilters?: string[]
  /**
   * An array of category names that should be excluded from the choices
   */
  excludeCategories?: string[]
  /**
   * If this sidepanel should be restricted to a single category
   */
  restrictCategory?: string
  /**
   * Callback to update the value of a filter
   */
  onUpdate: (name: string, value: any, delay?: number) => void
  /**
   * Allowed currencies to be shown in the select
   */
  approvedCurrencies?: string[] | null
  /**
   * Block that appears above the filters
   */
  topBlock?: ReactNode
  /**
   * Block that appears below the filters
   */
  bottomBlock?: ReactNode
}

export const SidePanelFilterMarketplaceV2: React.FC<SidePanelFilterMarketplaceV2Props> = ({
  showDraft,
  filters,
  filterType,
  marketplaceV2AvailableFilters,
  isLoading,
  excludeFilters,
  includeFilters,
  excludeCategories,
  onUpdate,
  restrictCategory,
  approvedCurrencies,
  topBlock,
  bottomBlock,
  ...rest
}) => {
  const categories = useMemo(
    () => (restrictCategory ? [restrictCategory] : filters.categories || []),
    [filters.categories, restrictCategory]
  )
  const isEvent = useMemo(() => categories.length === 1 && categories.includes(CATEGORY.Events), [categories])
  // const isMarketplace = useMemo(() => filterType === 'Marketplace', [filterType])
  const isApprovedPostal = useMemo(() => filterType === 'ApprovedPostal', [filterType])
  const isCollections = useMemo(() => filterType === 'Collections', [filterType])

  const { aclCheck, hasFeature } = useAcl()

  const canSelectTeams = useMemo(
    () => aclCheck({ module: 'teams.create', role: Role.Admin }) && (isApprovedPostal || isCollections),
    [aclCheck, isApprovedPostal, isCollections]
  )

  // Events need to see the Event statuses instead
  const canSeeDraft = useMemo(() => {
    if (!showDraft) return false
    if (aclCheck({ module: 'postals.create' })) return true
    if (isCollections) return filters.owner === Owner.Me
    return false
  }, [aclCheck, filters.owner, isCollections, showDraft])

  // prune and process filter options
  const pruneAndValidateOptions = useCallback(
    (filterName: string, options?: MarketplaceFilterOptionV2[]) => {
      let extraFilter

      switch (filterName) {
        case 'categories':
          extraFilter = ({ value }: MarketplaceFilterOptionV2) => !excludeCategories?.includes(value)
          break
        case 'currencies':
          extraFilter = ({ value }: MarketplaceFilterOptionV2) =>
            approvedCurrencies?.length ? approvedCurrencies?.includes(value) : true
          break
        default:
      }

      const prunedOptions = options?.filter(Boolean).filter(extraFilter ?? identity)
      return orderBy(prunedOptions ?? [], 'name')
    },
    [approvedCurrencies, excludeCategories]
  )

  // here we build the available filters based on the data coming in
  const [availableFilters, setAvailableFilters] = useState<MarketplaceFilterAndValueV2[]>([])

  useEffect(() => {
    if (isLoading || !marketplaceV2AvailableFilters) return

    const available = [] as any[]

    // console.log('marketplaceV2AvailableFilters')
    // console.log(marketplaceV2AvailableFilters)

    // if we aren't restricted (Events, Collection), then add in the category select options
    // if (!restrictCategory) {
    //   // if we've specified a category, allow user to
    // const categories = catFilters.find((f) => f.name === 'categories')?.options

    //   available.push({
    //     name: 'categories',
    //     title: 'Categories',
    //     options: pruneAndValidateOptions('categories', categories),
    //     currentValue: filters['categories'] ?? [],
    //   })
    // }

    // if we don't have a category, then push the rest of the toplevel data
    // if (!filters.categories) {

    //   noCategoryFilters.forEach((f) => {
    //     available.push({
    //       name: f.name,
    //       title: f.title,
    //       options: pruneAndValidateOptions(f.name, f.options),
    //       currentValue: filters[f.name] ?? [],
    //     })
    //   })
    // }

    const shownFilters = marketplaceV2AvailableFilters.filter((f) =>
      !filters.categories ? NO_CATEGORY_FILTERS.includes(f.name) : true
    )

    shownFilters.forEach((f) => {
      available.push({
        name: f.name,
        title: f.title,
        type: f.type,
        options: pruneAndValidateOptions(f.name, f.options),
        currentValue: filters[f.name] ?? [],
      })
    })

    // Object.entries(filters).forEach(([key, value]) => {
    //   if (!available.find(a => a.name === key)) {
    //     available.push({
    //       name: key,
    //       title: humanize(key),
    //       type:
    //     })
    //   }
    // })

    // make sure we have options, they are not on the exclusion list, and pass aclCheck
    const prunedAvailable = available
      .filter((f) => f.options.length || f.currentValue?.length)
      .filter((f) => !excludeFilters?.includes(f.name))
      .filter((f) => !HIDDEN_FILTERS?.includes(f.name))
      .filter((f) => (!!includeFilters ? includeFilters.includes(f.name) : true))
      .filter((f) => (filterAcl[f.name] ? aclCheck(filterAcl[f.name]) : true))
      .sort(handleSortFilter)

    setAvailableFilters(prunedAvailable)
  }, [
    marketplaceV2AvailableFilters,
    restrictCategory,
    categories.length,
    excludeCategories,
    approvedCurrencies,
    isCollections,
    hasFeature,
    excludeFilters,
    includeFilters,
    aclCheck,
    isLoading,
    filters.categories,
    pruneAndValidateOptions,
    filters,
  ])

  // cleanup when the filters or available filters change
  useDeepCompareEffectNoCheck(() => {
    // bail if we are loading to make sure we have the correct data
    if (isLoading) return
    // iterate the current filters
    // Object.keys(filters).forEach((name) => {
    //   // skip these special filters
    //   if (SPECIAL_FILTERS.includes(name)) return
    //   const available = availableFilters.find((f) => f.name === name)
    //   const value = filters[name]
    //   // remove filters that are no longer available
    //   if (!available) return onUpdate(name, undefined)
    //   // remove filters that are no longer valid
    //   if (isArray(value)) {
    //     const newValues = value.filter((v) => available.options.find((availableF: any) => v.value === availableF))
    //     if (!dequal(newValues, value)) onUpdate(name, newValues)
    //   } else {
    //     if (!available.options.includes(value)) return onUpdate(name, undefined)
    //   }
    // })
  }, [availableFilters, filters, onUpdate])

  const handleCurrency = (e: UiChangeEvent<string[]>) => onUpdate(e.key, e.value, 800)

  const handleUpdateTeams = (value: any) => onUpdate('teamIds', value)

  const handleUpdateBoolean = (name: string) => (value: any) => onUpdate(name, value)

  const handleUpdateMultiSelect = (name: string) => (value: MultiValue<MarketplaceFilterOptionV2>) => {
    // pull the count out when the filter is selected
    const val = value.map(({ count, ...rest }) => rest)
    onUpdate(name, val)
  }

  const handleClear = (name: string) => () => onUpdate(name, undefined)

  // const dateOptions = useMemo(() => {
  //   const now = new Date()
  //   return {
  //     'data-min-date': now.toISOString(),
  //     'data-max-date': addDays(now, 90).toISOString()
  //   }
  // }, [])

  return (
    <Box>
      <UiSidePanel
        data-testid="SidePanelFilter"
        gap={32}
        {...rest}
      >
        {topBlock && <UiSidePanelBlock data-testid="SidePanelFilter-Top">{topBlock}</UiSidePanelBlock>}

        <UiSidePanelBlock
          data-testid={isLoading ? 'SidePanelFilter_Filters_loading' : 'SidePanelFilter_Filters'}
          isLoading={isLoading}
          bg="white"
          w="237px"
          h="100%"
          minH="500px"
          borderRadius="3px"
          startColor="atomicGray.50"
          endColor="atomicGray.200"
          pb={8}
        >
          <Stack spacing={6}>
            {canSelectTeams && (
              <Box>
                <SidePanelHeader
                  canClear={!!filters.teamIds?.length}
                  onClear={handleClear('teamIds')}
                  title="Teams"
                />
                <AutoCompleteTeams
                  data-testid="SidePanelFilter-Teams"
                  value={filters.teamIds ?? null}
                  onChange={handleUpdateTeams}
                />
              </Box>
            )}

            {availableFilters.map(({ name, type, title, options }, idx) => {
              if (name === 'currency')
                return (
                  <FormControl
                    key={`${name}-${idx}`}
                    id={name}
                  >
                    <SidePanelHeader
                      canClear={!!filters[name]?.length}
                      onClear={handleClear(name)}
                      title={humanize(name)}
                    />
                    <ZCurrencyCheckboxGroup
                      name={name}
                      value={filters[name] ?? []}
                      onChange={handleCurrency}
                    >
                      <Grid
                        gap={2}
                        templateColumns="repeat(4, minmax(40px, 60px))"
                      >
                        {options.map((option: any) => (
                          <ZCurrencyCheckbox
                            key={option.value}
                            value={option.value}
                          />
                        ))}
                      </Grid>
                    </ZCurrencyCheckboxGroup>
                  </FormControl>
                )

              if (type === FilterType.Equals || type === FilterType.Range)
                return (
                  <Box key={`${name}-${idx}`}>
                    <SidePanelHeader
                      canClear={!!filters[name]}
                      onClear={handleClear(name)}
                      title={title}
                    />
                    <UiSelectTypeahead
                      // @ts-ignore
                      isMulti
                      data-testid={`SidePanelFilter-${humanize(name)}`}
                      options={options}
                      value={filters[name] ?? []}
                      getOptionValue={(o) => o.value}
                      getOptionLabel={(o) => getOptionLabel(name, o, options, isEvent)}
                      onChange={handleUpdateMultiSelect(name)}
                      placeholder="--"
                      {...SelectTypeaheadStylesV2}
                    />
                  </Box>
                )

              if (type === FilterType.Bool)
                // boolean
                return (
                  <FormControl
                    py={1}
                    key={name}
                    id={name}
                  >
                    <SidePanelHeader
                      canClear={!!filters[name]}
                      onClear={() => handleClear(name)}
                      title={title}
                    />
                    <UiToggle
                      name={name}
                      isChecked={!!filters[name]}
                      onChange={(e: any) => {
                        handleUpdateMultiSelect(name)(e.target.checked)
                      }}
                      colorScheme="atomicBlue"
                    />
                  </FormControl>
                )
              return false
            })}

            {isApprovedPostal && !excludeFilters?.includes('favoritePostals') && (
              <FormControl id="favoritePostals">
                <SidePanelHeader
                  canClear={!!filters['favoritePostals']}
                  onClear={handleClear('favoritePostals')}
                  title="Show only Favorite Items"
                />
                <UiToggle
                  name="favoritePostals"
                  isChecked={filters['favoritePostals'] || false}
                  onChange={(e: any) => handleUpdateBoolean('favoritePostals')(e.target.checked || undefined)}
                  colorScheme="atomicBlue"
                />
              </FormControl>
            )}

            {isCollections && !excludeFilters?.includes('favoriteCollections') && (
              <FormControl id="favoriteCollections">
                <SidePanelHeader
                  canClear={!!filters['favoriteCollections']}
                  onClear={handleClear('favoriteCollections')}
                  title="Show only Favorite Items"
                />
                <UiToggle
                  name="favoriteCollections"
                  isChecked={filters['favoriteCollections'] || false}
                  onChange={(e: any) => handleUpdateBoolean('favoriteCollections')(e.target.checked || undefined)}
                  colorScheme="atomicBlue"
                />
              </FormControl>
            )}

            {!excludeFilters?.includes('showOutOfStock') && (
              <FormControl id="showOutOfStock">
                <SidePanelHeader
                  canClear={!!filters['showOutOfStock']}
                  onClear={handleClear('showOutOfStock')}
                  title="Show Out of Stock"
                />
                <UiToggle
                  name="showOutOfStock"
                  isChecked={filters['showOutOfStock'] || false}
                  onChange={(e: any) => handleUpdateBoolean('showOutOfStock')(e.target.checked || undefined)}
                  colorScheme="atomicBlue"
                />
              </FormControl>
            )}

            {canSeeDraft && (
              <FormControl id="showDraft">
                <SidePanelHeader
                  canClear={!!filters['showDraft']}
                  onClear={handleClear('showDraft')}
                  title="Show Draft Items"
                />
                <UiToggle
                  name="showDraft"
                  isChecked={filters['showDraft'] || false}
                  onChange={(e: any) => handleUpdateBoolean('showDraft')(e.target.checked || undefined)}
                  colorScheme="atomicBlue"
                />
              </FormControl>
            )}
          </Stack>
        </UiSidePanelBlock>

        {bottomBlock && <UiSidePanelBlock data-testid="SidePanelFilter-Bottom">{bottomBlock}</UiSidePanelBlock>}
      </UiSidePanel>
    </Box>
  )
}

// TODO: nuke this if necessary
export function useBrandsCategories({ isMarketplace, isApprovedPostal, isCollections, restrictCategory }: any) {
  const getBrandsCategories = useGraphqlQuery(GetBrandsCategoriesDocument, undefined, {
    enabled: isMarketplace && !restrictCategory,
  })
  useAlertError(getBrandsCategories.error)

  const getApprovedPostalBrandsCategories = useGraphqlQuery(GetApprovedPostalBrandsCategoriesDocument, undefined, {
    enabled: (isApprovedPostal || isCollections) && !restrictCategory,
  })
  useAlertError(getApprovedPostalBrandsCategories.error)

  const brandsCategories = useMemo(() => {
    return isMarketplace
      ? getBrandsCategories.data?.getBrandsCategories.categories || {}
      : getApprovedPostalBrandsCategories.data?.getApprovedPostalBrandsCategories.categories || {}
  }, [
    getApprovedPostalBrandsCategories.data?.getApprovedPostalBrandsCategories,
    getBrandsCategories.data?.getBrandsCategories,
    isMarketplace,
  ])

  const isLoading = useMemo(
    () => getBrandsCategories.isLoading || getApprovedPostalBrandsCategories.isLoading,
    [getApprovedPostalBrandsCategories.isLoading, getBrandsCategories.isLoading]
  )

  return { brandsCategories, isLoading }
}
