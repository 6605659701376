import type { BoxProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'
import { UiIconButton, ZCard, ZCardHeader, ZHeading, ZLink, ZText } from '@postal-io/postal-ui'
import type { MessageTemplate } from 'api'
import React, { useState } from 'react'
import { MdOutlineDelete, MdOutlineEdit } from 'react-icons/md'

interface MessagesBrickProps extends BoxProps {
  message: MessageTemplate
  isAdmin: boolean
  onEdit: (msg: MessageTemplate) => void
  onDelete: (msg: MessageTemplate) => void
}

export const MessagesBrick: React.FC<MessagesBrickProps> = ({ message, isAdmin, onEdit, onDelete }) => {
  const [expand, setExpand] = useState<boolean>(false)
  const toggleExpand = (): void => setExpand(!expand)

  const handleEdit = () => isAdmin && onEdit(message)
  const handleDelete = () => isAdmin && onDelete(message)

  return (
    <ZCard
      variant="form"
      isLoading={!message}
    >
      <Flex direction="column">
        <ZCardHeader
          justifyContent="space-between"
          alignItems="center"
          p={8}
          pb={0}
        >
          <ZHeading
            as="h3"
            size="md"
            fontWeight={500}
          >
            {message.name}
          </ZHeading>
          {isAdmin && (
            <Flex>
              <UiIconButton
                variant="ghost"
                fontSize={24}
                aria-label="Edit message template"
                icon={<MdOutlineEdit />}
                color="atomicBlue.600"
                onClick={handleEdit}
              />
              <UiIconButton
                variant="ghost"
                fontSize={24}
                aria-label="delete message template"
                icon={<MdOutlineDelete />}
                color="atomicBlue.600"
                onClick={handleDelete}
              />
            </Flex>
          )}
        </ZCardHeader>

        {(message.templateText && message?.templateText.length < 300) || expand ? (
          <ZText
            whiteSpace="break-spaces"
            mb={6}
            p={8}
          >
            {message.templateText}
          </ZText>
        ) : (
          <ZText
            whiteSpace="break-spaces"
            mb={6}
            p={8}
          >
            {message.templateText && message?.templateText.slice(0, 300)}...
          </ZText>
        )}

        {message.templateText && message.templateText.length >= 300 && (
          <ZLink
            onClick={toggleExpand}
            p={8}
          >
            {expand ? 'Collapse' : 'Expand'}
          </ZLink>
        )}
      </Flex>
    </ZCard>
  )
}
