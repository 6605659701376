export const DEFAULT_SESSION_TIMEOUT_MINUTES = 30

export interface ForcedAuthenticationType {
  id: string
  label: string
}

export const FORCED_AUTHENTICATION_TYPES: ForcedAuthenticationType[] = [
  { id: 'sso:google', label: 'Google' },
  { id: 'sso:azuread', label: 'Microsoft' },
  { id: 'sso:okta', label: 'Okta' },
  { id: 'sso:saml2', label: 'Saml' },
  { id: 'sso:sfdc', label: 'Salesforce' },
  { id: 'password', label: 'Password' },
]
