import { UiGoogleCaptchaPolicies, UiLoading, useAlerts } from '@postal-io/postal-ui'
import { useRecaptcha, useSession } from 'hooks'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const VerifyLoading = () => {
  const navigate = useNavigate()
  const { requestId } = useParams() as any

  const Alert = useAlerts()
  const { executeRecaptcha } = useRecaptcha()
  const { signUpVerify } = useSession()

  useEffect(() => {
    const verify = async () => {
      try {
        const siteVerify = (await executeRecaptcha?.('signUpVerify')) || ''
        await signUpVerify({ requestId, siteVerify })
        navigate('/register')
      } catch (err) {
        Alert.error(`This link has Expired. Please sign-in or complete sign up again`)
        navigate('/sign-up')
      }
    }
    verify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <UiLoading />
      <UiGoogleCaptchaPolicies />
    </>
  )
}
