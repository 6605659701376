import type { BoxProps } from '@chakra-ui/react'
import { Box, Grid } from '@chakra-ui/react'
import { UiDate, UiSeparator, ZCardHeader, ZText } from '@postal-io/postal-ui'
import React, { useMemo } from 'react'
import type { MagicEventInstance } from '../../../api'

interface RowProps extends BoxProps {
  label?: string
}
const Row: React.FC<RowProps> = ({ label, children, ...rest }) => {
  return (
    <Box {...rest}>
      <ZText size="lg">{label}</ZText>
      <ZText>{children}</ZText>
    </Box>
  )
}

export interface EventsApprovedPostalEventRequestInfoV2Props extends BoxProps {
  event?: MagicEventInstance | null
}

export const EventsApprovedPostalEventRequestInfo: React.FC<EventsApprovedPostalEventRequestInfoV2Props> = ({
  event,
  ...rest
}) => {
  const dates = useMemo(() => {
    return [event?.calendarRequestedDate, ...(event?.requestedDates || [])].filter(Boolean)
  }, [event?.calendarRequestedDate, event?.requestedDates])

  if (!event) return null

  return (
    <Box {...rest}>
      <ZCardHeader>Request Info</ZCardHeader>
      <Grid
        mt={4}
        templateColumns="repeat(auto-fill, minmax(250px, auto))"
        gridGap={4}
      >
        <Row label="Requested Attendees">{event.requestedAttendeeCount}</Row>
        {!!dates.length && (
          <Row label="Requested Dates">
            <UiSeparator separator=", ">
              {dates.map((date, idx) => (
                <ZText
                  as="span"
                  key={idx}
                >
                  <UiDate
                    key={idx}
                    date={date}
                  />
                </ZText>
              ))}
            </UiSeparator>
          </Row>
        )}
        <Row
          label="Requested Message"
          whiteSpace="break-spaces"
        >
          {event.requestedByMessage}
        </Row>
      </Grid>
    </Box>
  )
}
