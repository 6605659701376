import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { useAlerts } from '@postal-io/postal-ui'
import { SignupDocument, StartTrialDocument } from 'api'
import { accessToken } from 'api/rest'
import { MainWithMarketingPane } from 'components/Session'
import { AnalyticsEvent, PageTitle, useAnalyticsEvent, useAnalyticsSend, useSession } from 'hooks'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LogInHeaderBlack } from '../Session/LogInHeader'
import { RegisterLoading } from './RegisterLoading'
import { RegistrationForm } from './RegistrationForm'

export type RegistrationFormProps = {
  accountName: string
  phoneNumber: string
  number: string
  cardType: string
  expiration: string
  code: string
  postalZip: string
  state: string
  country: string
  terms: boolean
}

export const Register = () => {
  const Alert = useAlerts()
  const [loading, setLoading] = useState(false)
  const { loadSession } = useSession()
  const signUp = useGraphqlMutation(SignupDocument)
  const startTrial = useGraphqlMutation(StartTrialDocument)
  const navigate = useNavigate()

  // If we are in PRETRIAL, then we already have an account
  // so we can use this to skip things that are not needed
  const { session } = useSession()
  const [hasAccount, setHasAccount] = useState(session.plan === 'PRETRIAL')

  const sendAnalytics = useAnalyticsSend()

  // This creates the account in the DB and moves us to PRETRIAL.
  // Mutation not permitted will be thrown if we are no longer in SIGNUP.
  // This could be the case if the account signup succeeds, but the cc
  // fails and we need to retry that function.  Just ignore the error.
  //
  // After signing up, refresh the session without using the session context
  // which would reload the app. Now we should be in pretrial plan for the next call
  const handleSignUp = async ({ accountName, state, country, terms }: RegistrationFormProps) => {
    if (hasAccount) return
    const collectedData = { aboutCompany: {}, terms: terms, aboutTechnology: {} }
    try {
      await signUp.mutateAsync({ data: { accountName, country, state, collectedData } })
      await accessToken()
      setHasAccount(true)
    } catch (err) {
      if (!err.message?.includes('Mutation Not Permitted')) throw err
    }
  }

  // handles onSubmit of the form
  const onComplete = async (data: RegistrationFormProps) => {
    // start the spinner
    setLoading(true)

    try {
      // This creates an account and the billing accounts
      // and moves us into the PRETRIAL plan.
      await handleSignUp(data)

      // Start the trial, this will complete signup and move to a BUSINESS plan
      await startTrial.mutateAsync({})

      sendAnalytics({ event: AnalyticsEvent.RegisterComplete })

      // Now we can reload the session in the app session context.  This
      // should redirect us to the dashboard
      await loadSession()
      navigate('/')
    } catch (err) {
      Alert.error(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  useAnalyticsEvent({ event: AnalyticsEvent.RegisterViewed })

  return (
    <>
      <PageTitle title="Register" />
      <MainWithMarketingPane>
        <LogInHeaderBlack>
          <RegistrationForm
            onComplete={onComplete}
            hasAccount={hasAccount}
          />
          <RegisterLoading isLoading={loading} />
        </LogInHeaderBlack>
      </MainWithMarketingPane>
    </>
  )
}
