import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ApprovedPostal, SearchableProduct } from 'api'
import { Role, SearchFavoriteItemsDocument } from 'api'
import { APPROVED_ITEM_TYPES } from 'components/Marketplace/data'
import { some } from 'lodash'
import { useMemo } from 'react'
import { useAcl } from './useAcl'

export const MAX_FAVORITES = 100

export function usePostalFavoriteStatus(postals?: ApprovedPostal[]) {
  const { hasRole } = useAcl()
  const hasFavorites = hasRole(Role.User)

  const favoritesQuery = useGraphqlQuery(
    SearchFavoriteItemsDocument,
    {
      filter: {},
      limit: MAX_FAVORITES,
    },
    {
      enabled: hasFavorites,
    }
  )
  const favorites = useMemo(() => favoritesQuery.data?.searchFavoriteItems, [favoritesQuery.data?.searchFavoriteItems])

  const postalsWithFavoriteStatus = useMemo(
    () =>
      postals?.map((postal) => ({
        ...postal,
        favoriteItemId: favorites?.find((f) => f.approvedPostalId === postal.id)?.id,
      })),
    [favorites, postals]
  )

  return { postalsWithFavoriteStatus }
}

export function useSearchableProductFavoriteStatus(products?: SearchableProduct[]) {
  const { hasRole } = useAcl()
  const hasFavorites = hasRole(Role.User)
  const areAnyProductsFavoriteable = useMemo(
    () => some(products, (p) => APPROVED_ITEM_TYPES.includes(p.itemType)),
    [products]
  )

  const favoritesQuery = useGraphqlQuery(
    SearchFavoriteItemsDocument,
    {
      filter: {},
      limit: MAX_FAVORITES,
    },
    {
      enabled: hasFavorites && areAnyProductsFavoriteable,
    }
  )
  const favorites = useMemo(() => favoritesQuery.data?.searchFavoriteItems, [favoritesQuery.data?.searchFavoriteItems])

  const productsWithFavoriteStatus = useMemo(
    () =>
      products?.map((product) => ({
        ...product,
        favoriteItemId: favorites?.find(
          (f) => APPROVED_ITEM_TYPES.includes(product.itemType) && f.approvedPostalId === product.itemId
        )?.id,
      })),
    [favorites, products]
  )

  return { productsWithFavoriteStatus }
}

export function useFavoriteItemId(postal?: ApprovedPostal) {
  const { hasRole } = useAcl()
  const hasFavorites = hasRole(Role.User)

  const favoriteItemQuery = useGraphqlQuery(
    SearchFavoriteItemsDocument,
    { filter: { approvedPostalId: { eq: postal?.id } } },
    { enabled: hasFavorites && !!postal?.id }
  )
  const favoriteItemId = useMemo(
    () => favoriteItemQuery.data?.searchFavoriteItems?.[0]?.id,
    [favoriteItemQuery.data?.searchFavoriteItems]
  )
  return favoriteItemId
}
