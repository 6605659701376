import { Grid, VStack } from '@chakra-ui/react'
import { ZSidebar, ZSidebarBanner } from '@postal-io/postal-ui'
import {
  AccountBudgetTimelineSettingsBlock,
  AccountCompanyBlock,
  AccountSettingsConfigBlock,
  AccountSupportAccessBlock,
  AccountUserAccessBlock,
} from 'components/Account'
import { CenteredBox } from 'components/Common'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

import { PageTitle } from 'hooks'
import React from 'react'

export const AccountSettings: React.FC = () => {
  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner title="Account Settings" />
        <PageTitle title="Account Settings" />
        <Grid
          templateColumns={{ base: '1fr', lg: 'repeat(auto-fit, minmax(400px, 1fr))' }}
          gap={8}
          maxW="1800px"
        >
          <VStack
            spacing={8}
            alignItems="stretch"
          >
            <AccountUserAccessBlock />

            <AccountSettingsConfigBlock />

            <AccountSupportAccessBlock />
          </VStack>

          <VStack
            spacing={8}
            alignItems="stretch"
          >
            <AccountCompanyBlock />

            <AccountBudgetTimelineSettingsBlock />
          </VStack>
        </Grid>
      </ZSidebar>
    </CenteredBox>
  )
}
