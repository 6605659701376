export const LIMIT = 20
export const CARD_HEIGHT = '270px'
export const CARD_WIDTH = '250px'
export const TOP_LEVEL_CARD_HEIGHT = '370px'
export const TOP_LEVEL_CARD_WIDTH = '350px'

export enum CollectionItemTab {
  'Marketplace' = 'Marketplace',
  'Approved' = 'Approved',
}

export enum Owner {
  'Me' = 'Me',
  'Shared' = 'Shared',
  'SharedAndMe' = 'SharedAndMe',
}
