import { Heading } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiCard, UiRenderMessageWithVariables, useAlertError } from '@postal-io/postal-ui'
import type { MessageTemplate } from 'api'
import { MessageTemplatesDocument } from 'api'
import { ZDialog } from 'components/Common/ZComponents'
import React from 'react'

const NoMessages: React.FC = () => {
  return <UiCard>There are no saved messages yet</UiCard>
}

export interface MessageCardV2Props {
  message: MessageTemplate
  onClick: (e: MessageTemplate) => void
}

const MessageCardV2: React.FC<MessageCardV2Props> = ({ message, onClick }) => {
  return (
    <UiCard
      isLoading={!message}
      mb={{ base: 2, md: 4 }}
      key={message.id}
      onClick={() => onClick(message)}
      p={4}
      borderColor="atomicGray.200"
      transition="0.2s border-color"
      _hover={{ borderWidth: '1px', borderColor: 'atomicGray.400', cursor: 'pointer' }}
    >
      <Heading
        as="h3"
        size="sm"
        fontWeight="semibold"
        color="primary.500"
        mb={4}
      >
        {message.name}
      </Heading>
      <UiRenderMessageWithVariables
        whiteSpace="break-spaces"
        m={0}
        p={0}
        text={message.templateText}
      />
    </UiCard>
  )
}

export interface MessagesBodyV2Props {
  onSelect: (e: MessageTemplate) => void
}

export const MessagesBodyV2: React.FC<MessagesBodyV2Props> = ({ onSelect }) => {
  const { data, error } = useGraphqlQuery(MessageTemplatesDocument)
  useAlertError(error)
  const messages = (data?.messageTemplates as MessageTemplate[]) || []
  return (
    <>
      <Heading
        as="h3"
        fontSize="md"
        fontWeight="semibold"
        color="gray.800"
        mb={4}
      >
        Click on a Message below to save it to your Text field.
      </Heading>
      {messages.map((message) => {
        return (
          <MessageCardV2
            key={message.id}
            message={message}
            onClick={onSelect}
          />
        )
      })}
      {messages.length === 0 && <NoMessages />}
    </>
  )
}

export interface MessagesDrawerV2Props {
  isOpen: boolean
  onClose: () => void
  onSelect: (e: MessageTemplate) => void
}

export const MessagesDrawer: React.FC<MessagesDrawerV2Props> = ({ isOpen, onClose, onSelect, ...rest }) => {
  const { data, error } = useGraphqlQuery(MessageTemplatesDocument)
  const messages = (data?.messageTemplates as MessageTemplate[]) || []

  useAlertError(error)

  return (
    <ZDialog
      isOpen={isOpen}
      onClose={onClose}
      title="Saved Messages"
      {...rest}
    >
      <>
        {messages.map((message) => {
          return (
            <MessageCardV2
              key={message.id}
              message={message}
              onClick={onSelect}
            />
          )
        })}
        {messages.length === 0 && <NoMessages />}
      </>
    </ZDialog>
  )
}
