import { Box, Collapse, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiToggle, ZCard, ZCardBody, ZHeading } from '@postal-io/postal-ui'
import type { MessageTemplate } from 'api'
import { GetMaxPhysicalNoteCharactersDocument } from 'api'
import { ZFormLabel, ZInfoTooltip, ZInlineFormControl, ZTextareaWithVariables } from 'components/Common/ZComponents'
import { MessageVariablesDrawer } from 'components/Messages/MessageVariablesDrawer'
import { CATEGORY } from 'components/Postals'
import { AnalyticsEventV2, useAnalyticsSend, useMe, useSendAs } from 'hooks'
import { getMessageVariablesErrors, getParsingError } from 'lib'
import React, { useEffect, useMemo, useState } from 'react'
import { MessagesDrawer, messageVariables } from '../Messages'
import type { PostalCustomizeV2Props } from './PostalCustomize'
import { InlineErrorDisplay, MessageVariablesButton, SavedMessagesButton } from './PostalCustomizeEmail'
import {
  canIncludePhysicalMessage,
  canTogglePhysicalMessage,
  estimateCharacterCount,
  getSendMethodColor,
  giftMessageTitle,
  physicalMessagePlaceholder,
  physicalMessageRequired,
  physicalMessageTitle,
} from './usePostalSend'
import { usePostalSendFieldErrors } from './usePostalSendFieldErrors'

const maxCharTooltip = `This physical message is limited to a number of characters.

Keep in mind that message variables will differ in length depending on the data.

The estimated character count is based on 10 characters per replacement.
`

export const PostalCustomizePhysicalMessage: React.FC<PostalCustomizeV2Props> = ({ context, send }) => {
  const selectPhysicalMessage = useDisclosure()
  const seeMessageVariables = useDisclosure()
  const [physicalMessageCount, setPhysicalMessageCount] = useState(0)
  const sendAnalytics = useAnalyticsSend()

  const { me } = useMe()
  const { user: sendAsUser } = useSendAs({
    sendAsContactOwner: context.sendAsContactOwner,
    sendAsUser: context.sendAsUser,
  })

  const physicalMessageErrorProps = usePostalSendFieldErrors({ context, field: 'physicalMessage' })

  useEffect(() => {
    setPhysicalMessageCount(estimateCharacterCount(context?.physicalMessage || ''))
  }, [context?.physicalMessage])

  const physicalMessageError = useMemo(
    () =>
      //Handles message variable formatting issues
      getParsingError(context?.physicalMessage || '') ||
      //handles message variable data issues
      getMessageVariablesErrors(context.physicalMessage || '', sendAsUser || me),
    [context.physicalMessage, me, sendAsUser]
  )

  const getMaxChars = useGraphqlQuery(
    GetMaxPhysicalNoteCharactersDocument,
    {
      approvedPostalId: context?.postal?.id,
      variantId: context?.postal?.category === CATEGORY.Collection ? undefined : context?.variant?.id,
    },
    { enabled: canIncludePhysicalMessage(context) }
  )

  const maxChars = useMemo(
    () => getMaxChars.data?.getMaxPhysicalNoteCharacters,
    [getMaxChars.data?.getMaxPhysicalNoteCharacters]
  )

  const overMaxChars = useMemo(() => !!maxChars && physicalMessageCount > maxChars, [maxChars, physicalMessageCount])

  useEffect(() => {
    if ((maxChars ?? 0) !== (context.maxPhysicalCharacters ?? 0)) send({ type: 'SET_MAX_CHARACTERS', data: maxChars })
  }, [maxChars, send, context.maxPhysicalCharacters])

  const handleUsePhysicalMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    sendAnalytics({
      event: AnalyticsEventV2.SendFlow_PhysicalCard_Toggle,
      data: { usePhysicalMessage: !!e.target.checked },
    })
    send({ type: 'SET_USE_PHYSICAL_MESSAGE', data: !!e.target.checked })
  }

  const handleUseSameMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    send({ type: 'SET_USE_SAME_MESSAGE', data: !!e.target.checked })
  }

  const handlePhysicalMessage = (newVal: string) => {
    send({ type: 'SET_PHYSICAL_MESSAGE', data: newVal })
  }

  const handleSelectPhysicalMessage = ({ templateText }: MessageTemplate) => {
    send({ type: 'SET_PHYSICAL_MESSAGE', data: templateText })
    selectPhysicalMessage.onClose()
  }

  if (!context || !canIncludePhysicalMessage(context)) return null
  return (
    <>
      <ZCard
        variant="form"
        pt={{ base: 4, md: 8 }}
        borderWidth={{ base: 0, md: 1 }}
      >
        <ZCardBody>
          <ZHeading
            as="h2"
            size="h5"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Physical Card
            {canTogglePhysicalMessage(context) && (
              <Flex justifyContent="flex-end">
                <ZFormLabel
                  color="atomicGray.600"
                  fontWeight="normal"
                  fontSize="xs"
                  onClick={(e) => e.preventDefault()}
                  mb={0}
                >
                  Include a {physicalMessageTitle(context)}
                </ZFormLabel>
                <UiToggle
                  flexGrow={0}
                  textAlign="right"
                  colorScheme={getSendMethodColor(context).replace(/\.\d00/, '')}
                  ml={2}
                  size="lg"
                  name="use-physical-message"
                  id="use-physical-message"
                  isChecked={!!context.usePhysicalMessage}
                  onChange={handleUsePhysicalMessage}
                />
              </Flex>
            )}
          </ZHeading>

          <Collapse
            in={physicalMessageRequired(context) || context.usePhysicalMessage}
            unmountOnExit
          >
            <>
              {context.deliveryEmail && (
                <ZInlineFormControl mt={10}>
                  <ZFormLabel
                    fontSize="14px"
                    onClick={(e) => e.preventDefault()}
                  >
                    Use {giftMessageTitle(context)} Message
                  </ZFormLabel>
                  <Flex justifyContent="flex-end">
                    <UiToggle
                      size="lg"
                      colorScheme={getSendMethodColor(context).replace(/\.\d00/, '')}
                      name="use-same-message"
                      id="use-same-message"
                      data-testid="PostalCustomizeGiftEmail_use-same-message"
                      isChecked={!!context.useSameMessage}
                      onChange={handleUseSameMessage}
                    />
                  </Flex>
                </ZInlineFormControl>
              )}
              <Box mt={8}>
                <Flex
                  justifyContent="space-between"
                  alignItems="baseline"
                  mb={2}
                >
                  <ZFormLabel mb={0}>
                    Message on {physicalMessageTitle(context)}
                    {context.postal?.collection && (
                      <ZInfoTooltip
                        hasArrow
                        boxSize="16px"
                        ml={2}
                        mb={0.5}
                        label="Physical message will be included for items chosen that support it"
                      />
                    )}
                  </ZFormLabel>
                  <Box textAlign="right">
                    <MessageVariablesButton onClick={seeMessageVariables.onOpen} />
                    <SavedMessagesButton onClick={selectPhysicalMessage.onOpen} />
                  </Box>
                </Flex>

                <ZTextareaWithVariables
                  {...physicalMessageErrorProps}
                  fontFamily="Lexend"
                  fontSize="sm"
                  fontWeight="normal"
                  minH="200px"
                  value={context.physicalMessage || ''}
                  onChange={handlePhysicalMessage}
                  placeholder={physicalMessagePlaceholder(context)}
                  data-testid="PostalCustomizeGiftEmail_textarea_physical_message"
                  messageVariables={messageVariables}
                  colorScheme={getSendMethodColor(context).replace(/\.\d00/, '')}
                  isInvalid={!!physicalMessageError || overMaxChars}
                  // isDisabled={!physicalMessageRequired(context) && !context.usePhysicalMessage}
                />
                <InlineErrorDisplay error={physicalMessageError} />
                {!!maxChars && !physicalMessageError && (
                  <Flex
                    justifyContent="space-between"
                    mt={2}
                  >
                    <Flex alignItems="center">
                      <ZInfoTooltip
                        whiteSpace="break-spaces"
                        label={maxCharTooltip}
                        mr={2}
                        mb={0.5}
                        boxSize="16px"
                      />
                      <Text color="gray.700">Max Length: {maxChars}</Text>
                    </Flex>
                    <Text
                      color={overMaxChars ? 'red.500' : 'gray.600'}
                      fontWeight={overMaxChars ? 'semibold' : 'normal'}
                    >
                      Your estimated character count is: <strong>{physicalMessageCount}</strong>
                    </Text>
                  </Flex>
                )}
              </Box>
            </>
          </Collapse>
        </ZCardBody>
      </ZCard>

      {seeMessageVariables.isOpen && (
        <MessageVariablesDrawer
          isOpen={seeMessageVariables.isOpen}
          onClose={seeMessageVariables.onClose}
          method={context.method}
        />
      )}

      {selectPhysicalMessage.isOpen && (
        <MessagesDrawer
          isOpen={selectPhysicalMessage.isOpen}
          onClose={selectPhysicalMessage.onClose}
          onSelect={handleSelectPhysicalMessage}
        />
      )}
    </>
  )
}
