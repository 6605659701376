import { Input, Text } from '@chakra-ui/react'
import { ZCard, ZCardBody, ZFormLabel, ZHeading, ZInfoTooltip } from '@postal-io/postal-ui'
import { ZAlert, ZResponsiveFormControl } from 'components/Common/ZComponents'
import { CATEGORY } from 'components/Postals'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { PostalSendType } from './data'
import type { PostalCustomizeV2Props } from './PostalCustomize'

// Customize Playbooks: Delay
export const PostalCustomizeDelay: React.FC<PostalCustomizeV2Props> = ({ context, send, ...rest }) => {
  const { pathname } = useLocation()

  const handleDelay = (e: React.ChangeEvent<HTMLInputElement>) => {
    send({ type: 'SET_DELAY', data: e.target.value })
  }
  const isDirectMail = context.postal?.category === CATEGORY.DirectMail

  const showDirectMailWarning = isDirectMail && (context.delay || 0) <= 7

  const step = Number(pathname.match(/\/step\/(\d)/)?.[1])

  if (context.type !== PostalSendType.PlaybookStep) return null

  return (
    <ZCard
      variant="form"
      pt={{ base: 4, md: 8 }}
      borderWidth={{ base: 0, md: 1 }}
      {...rest}
    >
      <ZCardBody>
        <ZHeading
          as="h2"
          size="h5"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          Subscription Settings
        </ZHeading>
        <ZResponsiveFormControl
          id="delay"
          mt={10}
        >
          <ZFormLabel
            display="flex"
            alignItems="center"
            variant="bold"
          >
            Delay
            <ZInfoTooltip
              label="We'll wait this long after the last step to send this item."
              ml={1.5}
            />
          </ZFormLabel>
          <Text textAlign="right">
            <Input
              name="delay"
              aria-label={`Subscription Step Delay`}
              type="number"
              min={0}
              size="md"
              value={context.delay}
              onChange={handleDelay}
              maxW="100px"
              mr={2}
            />{' '}
            Days
          </Text>
        </ZResponsiveFormControl>
        <ZAlert
          status="info"
          hideClose
          mt={5}
        >
          Item will be sent {context.delay} {Number(context.delay) === 1 ? 'day' : 'days'} after{' '}
          {step === 0 ? `the Subscription start` : 'the previous step'}
        </ZAlert>
        {showDirectMailWarning && (
          <Text
            mt={5}
            color="atomicRed.500"
          >
            <strong>Please Note:</strong> Direct Mail items can take up to 7 days to deliver.
          </Text>
        )}
      </ZCardBody>
    </ZCard>
  )
}
