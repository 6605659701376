import { Box, Flex, useClipboard, VStack } from '@chakra-ui/react'
import { UiTable, UiTbody, UiTd, UiTr, useAlerts, ZCard, ZCardBody, ZText } from '@postal-io/postal-ui'
import { SimpleDrawer } from 'components/Common'
import { ZDialog } from 'components/Common/ZComponents'
import { PostalSendMethod } from 'components/PostalSend/postalSendHelpers'
import React, { useEffect, useMemo } from 'react'
import { MdClose } from 'react-icons/md'
import { useExtension } from '../../hooks/useExtension'
import { messageVariables } from './messageVariables'

export interface MessageVariablesDrawerV2Props {
  isOpen: boolean
  onClose: () => void
  focusRef?: any
  onSelect?: (variable: string) => void
  method?: PostalSendMethod
}

export const MessageVariablesDrawer: React.FC<MessageVariablesDrawerV2Props> = ({
  isOpen,
  onClose,
  onSelect,
  focusRef,
  method,
}) => {
  const { onCopy, value, setValue } = useClipboard('')
  const Alert = useAlerts()

  const { isExtension } = useExtension()

  useEffect(() => {
    if (!value || !onCopy) return
    onCopy()
    Alert.success(`Copied ${value} to clipboard`)
    setValue('')
    onClose()
  }, [onCopy, value, Alert, onClose, setValue])

  const handleVariable = (variable: string) => {
    if (onSelect) {
      onSelect(variable)
      onClose()
    } else {
      setValue(variable)
    }
  }

  const filteredVariables = useMemo(() => {
    return method === PostalSendMethod.Link
      ? messageVariables.filter((v) => !/contact./g.test(v.variable))
      : messageVariables
  }, [method])

  if (isExtension) {
    return (
      <SimpleDrawer
        header={
          <Flex
            justifyContent="space-between"
            alignItems="center"
            borderBottom="2px solid #E0E6ED"
            pb={6}
            mb={6}
          >
            <ZText
              size="lg"
              color="atomicGray.900"
            >
              All variables
            </ZText>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="30px"
              height="30px"
              bg="atomicGray.50"
              borderRadius="50%"
              _hover={{ bg: 'atomicGray.100', cursor: 'pointer' }}
              onClick={onClose}
            >
              <MdClose
                size="16px"
                color="#8492A6"
              />
            </Box>
          </Flex>
        }
        isOpen={isOpen}
        onClose={onClose}
      >
        <VStack spacing={5}>
          {filteredVariables.map((row) => (
            <Flex
              key={row.id}
              width="100%"
              justifyContent="space-between"
            >
              <ZText
                cursor="pointer"
                color="atomicGray.600"
                onClick={() => handleVariable(row.variable)}
              >
                {row.description}
              </ZText>
              <ZText
                cursor="pointer"
                size="sm"
                color="atomicGray.500"
                onClick={() => handleVariable(row.variable)}
              >
                {row.variable}
              </ZText>
            </Flex>
          ))}
        </VStack>
      </SimpleDrawer>
    )
  } else {
    return (
      <ZDialog
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={focusRef}
        title="Personalize your text with variables"
        size="3xl"
      >
        <ZCard variant="form">
          <ZCardBody p={4}>
            <UiTable p={4}>
              <UiTbody>
                {filteredVariables.map((row) => (
                  <UiTr
                    key={row.id}
                    // {...((row.id as any) % 2 === 0 && { bg: 'atomicGray.10' })}
                  >
                    <UiTd
                      borderBottom="none"
                      p={4}
                      onClick={() => handleVariable(row.variable)}
                    >
                      <ZText
                        color="atomicGray.900"
                        fontWeight="bold"
                      >
                        {row.description}
                      </ZText>
                    </UiTd>
                    <UiTd
                      borderBottom="none"
                      p={4}
                      onClick={() => handleVariable(row.variable)}
                    >
                      <ZText
                        size="sm"
                        color="atomicGray.500"
                        fontWeight="bold"
                      >
                        {row.variable}
                      </ZText>
                    </UiTd>
                  </UiTr>
                ))}
              </UiTbody>
            </UiTable>
          </ZCardBody>
        </ZCard>
      </ZDialog>
    )
  }
}
