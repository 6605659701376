import { Box, Flex, HStack, RadioGroup, StatNumber } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import { UiStat, UiStatGroup, UiStatHelpText, useColor, ZCard, ZCardHeader, ZRadio, ZText } from '@postal-io/postal-ui'
import { DataObjectType, Granularity, UserAnalyticsV2Document } from 'api'
import { ZStatArrow } from 'components/Common/ZComponents'
import { differenceInMilliseconds, subMilliseconds } from 'date-fns'
import { Curve, useChart } from 'hooks/useChart'
import { getChangeMetric } from 'lib'
import { useMemo, useState } from 'react'
import { Chart } from 'react-charts'
import { NoDataOverlay } from '../NoDataOverlay'
import { radioStackStyleV2 } from '../ReportingFilter/GroupByFilter'

interface MeetingsBookedStatsCardV2Props extends ZCardProps {
  startDate: Date
  endDate: Date
  teamIds?: string[]
  userIds?: string[]
}

export const MeetingsBookedStatsCard: React.FC<MeetingsBookedStatsCardV2Props> = ({
  startDate,
  endDate,
  teamIds,
  userIds,
  ...rest
}) => {
  const { colorCode } = useColor()
  const [granularity, setGranularity] = useState(Granularity.Weekly)

  const curQuery = useGraphqlQuery(UserAnalyticsV2Document, {
    config: {
      startDate,
      endDate,
      teamIds,
      userIds,
      granularity,
      type: DataObjectType.User,
    },
  })

  const allData = curQuery.data?.userAnalyticsV2

  const { meetingsBooked } = useMemo(() => {
    const data = curQuery.data?.userAnalyticsV2 || []
    return data.reduce((sum, user) => {
      sum.meetingsBooked = (sum.meetingsBooked || 0) + (user.meetingsBooked || 0)
      return sum
    }, {})
  }, [curQuery.data?.userAnalyticsV2])

  const newStartDate = useMemo(() => {
    const diff = differenceInMilliseconds(endDate, startDate)
    return subMilliseconds(startDate, diff)
  }, [endDate, startDate])

  const prevQuery = useGraphqlQuery(UserAnalyticsV2Document, {
    config: {
      startDate: newStartDate,
      endDate: startDate,
      teamIds,
      userIds,
      granularity,
      type: DataObjectType.User,
    },
  })

  const { meetingsBooked: meetingsBookedPrev } = useMemo(() => {
    const data = curQuery.data?.userAnalyticsV2 || []
    return data.reduce((sum, user) => {
      sum.meetingsBooked = (sum.meetingsBooked || 0) + (user.meetingsBooked || 0)
      return sum
    }, {})
  }, [curQuery.data?.userAnalyticsV2])

  const meetingBookDiff = useMemo(() => {
    return getChangeMetric(meetingsBooked, meetingsBookedPrev)
  }, [meetingsBooked, meetingsBookedPrev])

  const weeklyMeetingsBooked = useChart({
    label: 'Meetings Booked',
    rows: allData?.map((week) => ({
      date: new Date(week?.date),
      cost: week?.meetingsBooked ?? 0,
      min: 0,
    })),
    curve: Curve.Linear,
    getSeriesStyle: () => ({ color: colorCode('turquoise.500'), strokeWidth: 3 }),
  })

  const showNoDataOverlayV2 = useMemo(
    () => !curQuery.isFetching && !meetingsBooked,
    [curQuery.isFetching, meetingsBooked]
  )

  return (
    <ZCard
      isLoading={prevQuery.isLoading}
      isFetching={prevQuery.isFetching}
      variant="form"
      {...rest}
    >
      {showNoDataOverlayV2 && <NoDataOverlay />}

      <Flex
        justifyContent="space-between"
        alignItems="baseline"
      >
        <ZCardHeader
          display="flex"
          p={4}
          pb={2}
          fontSize="lg"
        >
          Meetings Booked
        </ZCardHeader>

        <UiStatGroup fontFamily="Lexend">
          <UiStat
            display="flex"
            justifyContent="flex-end"
          >
            <StatNumber
              fontWeight="normal"
              fontSize="lg"
              textAlign="right"
              p={4}
              pb={2}
            >
              {meetingsBooked ?? 0}
            </StatNumber>
            <UiStatHelpText
              px={4}
              m={0}
              display="flex"
              alignItems="center"
              gap={1}
            >
              <ZStatArrow
                color={meetingBookDiff >= 0 ? 'vendorGreen.500' : 'atomicRed.500'}
                type={meetingBookDiff >= 0 ? 'increase' : 'decrease'}
              />
              <ZText
                fontSize="xs"
                color="atomicGray.500"
              >
                {Math.round(meetingBookDiff) || 0} %
              </ZText>
            </UiStatHelpText>
          </UiStat>
        </UiStatGroup>
      </Flex>
      <Box p={4}>
        <GranularitySelect
          value={granularity}
          setValue={setGranularity}
        />
        <Flex
          w="100%"
          minH="260px"
          justify="center"
        >
          <Chart options={weeklyMeetingsBooked} />
        </Flex>
      </Box>
    </ZCard>
  )
}

interface GranularitySelectProps {
  setValue: any
  value: Granularity
}
const GranularitySelect: React.FC<GranularitySelectProps> = ({ value, setValue }) => {
  return (
    <Box
      mb={-1}
      mt={-3}
    >
      <RadioGroup
        value={value}
        name="granularity"
        transform="scale(0.7)"
        onChange={(value) => setValue(value as Granularity)}
      >
        <HStack
          direction="row"
          justifyContent="center"
          gap={2}
          sx={radioStackStyleV2}
        >
          <span>Frequency:</span>
          <ZRadio value={Granularity.Daily}>Daily</ZRadio>
          <ZRadio value={Granularity.Weekly}>Weekly</ZRadio>
          <ZRadio value={Granularity.Monthly}>Monthly</ZRadio>
          <ZRadio value={Granularity.Yearly}>Yearly</ZRadio>
          <ZRadio value={Granularity.All}>All</ZRadio>
        </HStack>
      </RadioGroup>
    </Box>
  )
}
