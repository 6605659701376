import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { joinStrings, useAlertError } from '@postal-io/postal-ui'
import { pick } from 'lodash'
import Mustache from 'mustache'
import { useMemo } from 'react'
import { GetAccountDocument, GetUserDocument, MeDocument } from '../../api'
import { PostalSendType } from './data'
import type { PostalSendContext } from './usePostalSend'

const CUSTOM_TAGS: [string, string] = ['${', '}']

const parseTemplate = (content: string, view: any) => {
  try {
    return Mustache.render(content, view, {}, CUSTOM_TAGS)
  } catch (err) {
    return 'ERROR. Is there an unclosed tag such as `${contact.firstName`? Please go back and check your message.'
  }
}

export const useTemplatePreview = (context: PostalSendContext) => {
  const meQuery = useGraphqlQuery(MeDocument)
  const me = useMemo(() => meQuery.data?.me, [meQuery.data?.me])
  useAlertError(meQuery.error)

  const sendAsUserQuery = useGraphqlQuery(
    GetUserDocument,
    { id: context.sendAsUser as string },
    { enabled: !!context.sendAsUser }
  )
  const sendAsUser = useMemo(() => sendAsUserQuery.data?.getUser, [sendAsUserQuery.data?.getUser])
  useAlertError(sendAsUserQuery.error)

  const accountQuery = useGraphqlQuery(GetAccountDocument)
  const myAccount = useMemo(() => accountQuery.data?.getAccount, [accountQuery.data?.getAccount])
  const firstContact = context?.contacts?.items?.[0]
  useAlertError(accountQuery.error)

  const contactOwnerQuery = useGraphqlQuery(
    GetUserDocument,
    { id: firstContact?.ownerId as string },
    { enabled: !!firstContact?.ownerId }
  )
  const contactOwner = useMemo(() => contactOwnerQuery.data?.getUser, [contactOwnerQuery.data?.getUser])
  useAlertError(contactOwnerQuery.error)

  const view = useMemo(() => {
    const user = pick(context.sendAsContactOwner ? contactOwner : sendAsUser ? sendAsUser : me, [
      'firstName',
      'lastName',
      'emailAddress',
      'phoneNumber',
      'title',
      'meetingLink',
    ])

    const contact = {
      firstName: firstContact?.firstName || 'Jane',
      lastName: firstContact?.lastName || 'Doe',
      emailAddress: firstContact?.emailAddress || 'contact@example.com',
      title: firstContact?.title || 'VP of Purchasing',
      companyName: firstContact?.companyName || 'Initech',
      ownerName: joinStrings(
        contactOwner ? [contactOwner.firstName, contactOwner.lastName] : [me?.firstName, me?.lastName]
      ),
    }
    const account = {
      displayName: myAccount?.displayName || myAccount?.name,
    }
    const postal = {
      itemName: context?.postal?.name,
    }
    return context.type === PostalSendType.Link ? { user, account, postal } : { user, account, postal, contact }
  }, [
    contactOwner,
    context?.postal?.name,
    context.sendAsContactOwner,
    context.type,
    firstContact?.companyName,
    firstContact?.emailAddress,
    firstContact?.firstName,
    firstContact?.lastName,
    firstContact?.title,
    me,
    myAccount?.displayName,
    myAccount?.name,
    sendAsUser,
  ])

  const emailSubjectPreview = useMemo(() => {
    return context.emailSubjectLine ? parseTemplate(context.emailSubjectLine, view) : ''
  }, [context.emailSubjectLine, view])

  const giftMessagePreview = useMemo(() => {
    return context.giftMessage ? parseTemplate(context.giftMessage, view) : ''
  }, [context.giftMessage, view])

  const physicalMessagePreview = useMemo(() => {
    return context.physicalMessage ? parseTemplate(context.physicalMessage, view) : ''
  }, [context.physicalMessage, view])

  const landingPageHeaderPreview = useMemo(() => {
    return context.landingPageHeaderText ? parseTemplate(context.landingPageHeaderText, view) : ''
  }, [context.landingPageHeaderText, view])

  const landingPageBodyPreview = useMemo(() => {
    return context.landingPageBody ? parseTemplate(context.landingPageBody, view) : ''
  }, [context.landingPageBody, view])

  return {
    emailSubjectPreview,
    giftMessagePreview,
    physicalMessagePreview,
    landingPageBodyPreview,
    landingPageHeaderPreview,
  }
}
