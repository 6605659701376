import { CheckCircleIcon } from '@chakra-ui/icons'
import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { FontWeight, joinStrings, UiLink, UiSeparator, UiTag, ZText } from '@postal-io/postal-ui'
import type { AddressInput, ExternalMapping } from 'api'
import { AddressStatus } from 'api'
import { useAcl } from 'hooks'
import React from 'react'
import { hasVerifiedAddress } from '../Contacts/ContactsData'
import { useCanViewContactInfo } from './useCanViewContactInfo'

interface ContactAddressBlockProps extends BoxProps {
  address: AddressInput | null
  mappings?: ExternalMapping[] | null
  onRemove: () => void
  onPrefer: () => void
  onEdit: () => void
}

export const ContactAddressBlock: React.FC<ContactAddressBlockProps> = ({
  address,
  mappings,
  onRemove,
  onPrefer,
  onEdit,
  children,
  ...rest
}) => {
  const { hasPermission } = useAcl()
  const { address1, address2, address3, city, state, postalCode, preferred, status } = address || {}
  const isVerified = hasVerifiedAddress(status)
  const isValid = address1 || address2 || city || state || postalCode
  const { canViewAddresses } = useCanViewContactInfo()
  const canEdit = hasPermission('contacts.update') && canViewAddresses
  const canPrefer = canEdit && !address?.preferred && isVerified && canViewAddresses

  if (!isValid) return null

  return (
    <>
      <Flex
        justifyContent="space-between"
        {...rest}
      >
        <Box>
          <Box
            fontWeight={FontWeight.Bold}
            color="gray.500"
          >
            {address?.entryName}
            {isVerified ? (
              <Box>
                <CheckCircleIcon
                  color="green.500"
                  mt="-3px"
                  boxSize="20px"
                />{' '}
                <Text
                  color="green.500"
                  display="inline"
                >
                  {status === AddressStatus.MatchedOverride ? 'Manually Verified' : 'Verified'}
                </Text>
              </Box>
            ) : (
              <Box color="red.500">Not Verified</Box>
            )}
          </Box>
          <Box
            fontWeight={FontWeight.SemiBold}
            color="gray.900"
            {...(!canViewAddresses
              ? {
                  position: 'relative',
                  userSelect: 'none',
                  p: 2,
                }
              : {})}
          >
            {address1 && <Text data-private>{address1}</Text>}
            {address2 && <Text data-private>{address2}</Text>}
            {address3 && <Text data-private>{address3}</Text>}
            {(city || state || postalCode) && (
              <Text data-private>
                {joinStrings([city, state], ', ')} {postalCode}
              </Text>
            )}
            {!canViewAddresses && <HideAddressOverlay />}
          </Box>
          <UiSeparator
            separator={
              <Text
                as="span"
                color="gray.600"
              >
                {' | '}
              </Text>
            }
          >
            {canEdit && <UiLink onClick={onEdit}>Edit</UiLink>}
            {canEdit && <UiLink onClick={onRemove}>Remove</UiLink>}
            {canPrefer && <UiLink onClick={onPrefer}>Set as default</UiLink>}
          </UiSeparator>
        </Box>
        <Flex
          flexDir="column"
          align="center"
          justify="space-between"
        >
          {preferred && (
            <UiTag
              bg="tertiary.500"
              color="white"
              fontWeight={FontWeight.Bold}
              size="sm"
              px={4}
            >
              Default
            </UiTag>
          )}
        </Flex>
      </Flex>
    </>
  )
}

const HideAddressOverlay: React.FC = () => (
  <Flex
    alignItems="center"
    position="absolute"
    left={0}
    top={0}
    bottom={0}
    right={0}
    backdropFilter="blur(5px)"
  >
    <ZText
      py={1}
      px={2}
      ml={5}
      borderRadius={3}
      boxShadow="0 0 10px rgba(0,0,0,.2)"
      bg="#FFFA"
      color="atomicGray.500"
      backdropFilter="blur(20px)"
    >
      Address hidden
    </ZText>
  </Flex>
)
