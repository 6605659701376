import { Text } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { UiConfirm, useAlerts } from '@postal-io/postal-ui'
import type { ApprovedPostal } from 'api'
import { CreateApprovedPostalDocument, Status } from 'api'
import { CATEGORY } from 'components/Postals'
import { POSTAL_INVALIDATIONS, useApprovedPostalVersion, useBackgroundQueue } from 'hooks'
import React, { useMemo } from 'react'

interface PostalCloneProps {
  isOpen: boolean
  onClose: () => void
  onClone: (postal: ApprovedPostal) => void
  postal: ApprovedPostal
}

export const PostalClone: React.FC<PostalCloneProps> = ({ isOpen, onClose, onClone, postal }) => {
  const transform = useApprovedPostalVersion()
  const Alert = useAlerts()

  const { queue, invalidate } = useBackgroundQueue()
  const createPostal = useGraphqlMutation(CreateApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.createApprovedPostal.previewGenerationTask)
      invalidate(POSTAL_INVALIDATIONS)
    },
  })

  const type = useMemo(() => {
    if (postal.category === CATEGORY.Events) return 'Event'
    if (postal.category === CATEGORY.Collection) return 'Collection'
    return 'Item'
  }, [postal.category])

  const onConfirm = async () => {
    const event = !postal.event
      ? undefined
      : {
          requestedByName: postal.event?.requestedByName,
          requestedByEmail: postal.event?.requestedByEmail,
          requestedByPhone: postal.event?.requestedByPhone,
          requestedAttendeeCount: postal.event?.requestedAttendeeCount,
          requestedByMessage: postal.event?.requestedByMessage,
          calendarRequestedDate: postal.event?.calendarRequestedDate,
          requestedDates: postal.event?.requestedDates,
          meetingLink: postal.event?.meetingLink,
          sendEmailConfirmation: postal.event?.sendEmailConfirmation,
          sendReminderDayBefore: postal.event?.sendReminderDayBefore,
          sendReminderDayOf: postal.event?.sendReminderDayOf,
          sendCancelledAlert: postal.event?.sendCancelledAlert,
          sendInviteExpiringAlert: postal.event?.sendInviteExpiringAlert,
          sendMeetingLinkChanged: postal.event?.sendMeetingLinkChanged,
          sendShippedAlert: postal.event?.sendShippedAlert,
          sendDeliveredAlert: postal.event?.sendDeliveredAlert,
        }
    try {
      const res = await createPostal.mutateAsync({
        marketplaceProductId: postal.marketplaceProductId,
        data: {
          name: `${postal.name}-CLONE`,
          displayName: postal.displayName || postal.name,
          teamIds: postal.teamIds,
          description: postal.description,
          status: Status.Disabled,
          designTemplate: postal.designTemplate,
          attribution: postal.attribution,
          collection: postal.collection,
          event,
          imageUrls: postal?.imageUrls ?? undefined,
          ...transform(postal.category, {
            items: postal.variants?.map((v) => ({ marketplaceProductId: postal.marketplaceProductId, variant: v.id })),
            variants: postal.variants?.map((v) => v.id),
          }),
        },
      })
      Alert.success(`${type} Cloned`)
      onClone(res?.createApprovedPostal.postal)
      onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <UiConfirm
      title={`Clone ${type}`}
      isOpen={isOpen}
      onConfirm={onConfirm}
      onClose={onClose}
      isLoading={createPostal.isLoading}
      buttonText={`Clone ${type}`}
    >
      <Text>
        This action will make a DRAFT copy of <strong>{postal.name}</strong> with the same settings. Do you want to
        continue?
      </Text>
    </UiConfirm>
  )
}
