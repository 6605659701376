import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
/* @ts-ignore-next-line */
import createActivityDetector from 'activity-detector'
import { GetAccountDocument } from 'api'
import { useLogout } from 'hooks'
import React, { useCallback, useEffect, useMemo } from 'react'
import { DEFAULT_SESSION_TIMEOUT_MINUTES } from '../Security'

const IDLE_WARNING = 30 * 1000 // 30 seconds

export const Timeout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const getAccount = useGraphqlQuery(GetAccountDocument)
  const sessionTimeout = useMemo(
    () => getAccount.data?.getAccount?.sessionTimeoutMinutes || DEFAULT_SESSION_TIMEOUT_MINUTES,
    [getAccount.data?.getAccount?.sessionTimeoutMinutes]
  )

  // sessionTimeout is in minutes
  const timeToIdle = useMemo(() => {
    return sessionTimeout * 60 * 1000
  }, [sessionTimeout])

  const logout = useLogout()
  const timerRef = React.useRef<NodeJS.Timeout>()
  const detectorRef = React.useRef<any>()

  const stopLogout = useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current)
    onClose()
  }, [onClose])

  const startLogout = useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current)
    timerRef.current = setTimeout(logout, IDLE_WARNING)
    onOpen()
  }, [logout, onOpen])

  const stopDetector = useCallback(() => {
    stopLogout()
    detectorRef?.current?.stop()
  }, [stopLogout])

  const startDetector = useCallback(() => {
    stopDetector()
    detectorRef.current = createActivityDetector({ timeToIdle, inactivityEvents: [] })
    detectorRef.current.on('idle', startLogout)
    detectorRef.current.on('active', stopLogout)
  }, [startLogout, stopDetector, stopLogout, timeToIdle])

  useEffect(() => {
    startDetector()
    return () => stopDetector()
  }, [startDetector, stopDetector])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Session Timeout</ModalHeader>
        <ModalBody>
          <Text>Your Session is about to expire. Click anywhere on the screen to continue.</Text>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}
