import type { BoxProps } from '@chakra-ui/react'
import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  InputGroup,
  InputLeftElement,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlFetch, useGraphqlPaginatedQuery } from '@postal-io/postal-graphql'
import type { UiEasyDataTableColumn, UseCustomizeTableV2 } from '@postal-io/postal-ui'
import {
  internalProgressBarProps,
  internalTableProps,
  UiCustomizeTableButton,
  UiCustomizeTablePopover,
  UiEasyDataTable,
  UiEasyPagination,
  useCustomizeTableV2,
  ZCheckbox,
  ZInput,
  ZLink,
  ZText,
} from '@postal-io/postal-ui'
import type {
  ResultsSummary,
  SearchableContact,
  SearchableContactFilterInput,
  SearchContactsV2QueryVariables,
} from 'api'
import { SearchContactsV2Document } from 'api'
import { ContactsFilter } from 'components/Contacts/ContactsFilter'
import { ContactsListSelect } from 'components/Contacts/ContactsListSelect'
import { StorageKeys } from 'lib'
import React, { useEffect, useMemo, useState } from 'react'
import { MdOutlineSearch } from 'react-icons/md'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { COLUMNS, FETCH_LIMIT, STALE_TIME } from './data'
import type { UseMultiSelect } from './useMultiSelect'

interface DataTableHeaderProps {
  filter: any
  graphqlFilter?: any
  handleAddFilter: any
  onSearch: any
  showSelectAll: any
  state: any
  summary: any
  customizeTableProps: UseCustomizeTableV2
}
const DataTableHeader: React.FC<DataTableHeaderProps> = ({
  customizeTableProps,
  filter,
  graphqlFilter,
  handleAddFilter,
  onSearch,
  showSelectAll,
  state,
  summary,
}) => {
  const { isOpen: filterIsOpen, onClose: filterOnClose, onToggle: filterOnToggle } = useDisclosure()

  const handleToggleFilter = () => {
    filterOnToggle()
  }

  return (
    <Box>
      <Flex>
        <InputGroup mr={2}>
          <InputLeftElement
            h="100%"
            color="atomicGray.500"
            width="unset"
            ml={1}
          >
            <MdOutlineSearch size="20px" />
          </InputLeftElement>
          <ZInput
            mr={2}
            pl={8}
            onChange={onSearch}
            placeholder="Search Contacts"
            value={filter.q || ''}
            border="none"
            _focusVisible={{
              boxShadow: 'none !important',
            }}
          />
        </InputGroup>
        <ButtonGroup>
          <ContactsListSelect />
          <ContactsFilter
            disclosure={{
              isOpen: filterIsOpen,
              onClose: filterOnClose,
              onToggle: handleToggleFilter,
            }}
            {...graphqlFilter}
          />
          <UiCustomizeTablePopover
            {...customizeTableProps}
            button={<UiCustomizeTableButton size="sm" />}
          />
        </ButtonGroup>
      </Flex>
      <HStack
        ml={2}
        py={2}
        spacing={4}
        divider={
          <Divider
            orientation="vertical"
            borderColor="atomicGray.500"
            borderWidth="1px"
            h="1rem"
          />
        }
        w="100%"
        whiteSpace="nowrap"
        visibility={!!state.totalRecords ? 'visible' : 'hidden'}
      >
        <ZText
          color="atomicGray.400"
          fontSize="sm"
        >
          {state.totalRecords} contacts selected
        </ZText>
        {showSelectAll && (
          <ZLink
            onClick={handleAddFilter}
            fontSize="sm"
            fontWeight="normal"
          >
            Select all {summary.totalRecords} contacts from this search
          </ZLink>
        )}
      </HStack>
    </Box>
  )
}

export interface MultiSelectContactsTableV3Props extends BoxProps {
  filter: any
  graphqlFilter: any
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  state: UseMultiSelect<SearchableContact, SearchableContactFilterInput>
  variables: SearchContactsV2QueryVariables
}
export const MultiSelectContactsTable: React.FC<MultiSelectContactsTableV3Props> = ({
  graphqlFilter,
  filter,
  onSearch,
  state,
  variables,
}) => {
  const customizeTableProps = useCustomizeTableV2({
    persistKey: StorageKeys.ContactsTable,
    columns: COLUMNS,
  })
  const { customColumns } = customizeTableProps

  const getVisibleColumns = (numCols?: number) =>
    customColumns
      .filter((c) => !c.isHidden)
      .slice(0, numCols)
      .map((c) => ({ label: c.label, render: c.render } as UiEasyDataTableColumn<SearchableContact>))

  const convertedColumns = useBreakpointValue({
    // For base width, if any of 'Name', 'Email', 'Title' or 'Company' is hidden, return first 3 visible rows
    'base': customColumns.slice(0, 4).some((c) => c.isHidden)
      ? getVisibleColumns(3)
      : // Else return default 'Name', 'Email', 'Company' (ie skip 'Title')
        customColumns
          .filter((c) => ['Name', 'Email', 'Company'].includes(c.label))
          .map((c) => ({ label: c.label, render: c.render } as UiEasyDataTableColumn<SearchableContact>)),
    'lg': getVisibleColumns(4),
    'xl': getVisibleColumns(5),
    '2xl': getVisibleColumns(),
  })

  // setup state
  const [showSelectAll, setShowSelectAll] = useState(false)

  const fetchContacts = useGraphqlFetch(SearchContactsV2Document)

  // get the filter key and filter results from the current graphql query
  const currentFilter = useMemo(() => state.getFilter(variables.filter), [state, variables.filter])

  // GRAPHQL QUERIES
  const { page, setPage, prefetchPage, hasNextPage, data, isFetching } = useGraphqlPaginatedQuery(
    SearchContactsV2Document,
    variables,
    { staleTime: STALE_TIME }
  )

  // current page of contacts
  const rows = useMemo(
    () => data?.searchContactsV2.searchableContacts?.map((c) => ({ ...c, clickable: true })) || [],
    [data?.searchContactsV2.searchableContacts]
  )

  // current summary of search contacts results
  const summary = useMemo(() => {
    const results = data?.searchContactsV2?.resultsSummary || ({} as ResultsSummary)
    return {
      totalPages: results.totalPages || 0,
      totalRecords: results.totalRecords || 0,
      hasMore: results.hasMore || false,
    }
  }, [data?.searchContactsV2?.resultsSummary])

  // a row is passed in on an individual select, nothing for a select all
  const handleSelect = (isChecked: boolean, row?: SearchableContact) => {
    row ? handleSelectRow(isChecked, row) : handleSelectAll(isChecked)
  }

  // handle data table clicking a row
  const handleSelectRow = (isChecked: boolean, row: SearchableContact) => {
    if (!!currentFilter?.totalRecords) {
      // we are in select all, lets select everything on page and unselect this row
      state.addItems(rows)
      state.removeItem(row)
      state.removeFilter(currentFilter.filter)
    } else if (isChecked) {
      state.addItem(row)
    } else {
      state.removeItem(row)
    }
    setShowSelectAll(false)
  }

  // this is called when someone wants to add everything that matches the search
  const handleAddFilter = async () => {
    if (!summary.totalRecords) return
    const res = await fetchContacts({ ...variables, limit: FETCH_LIMIT }, { staleTime: STALE_TIME })
    const contacts = res.searchContactsV2?.searchableContacts || []
    const totalRecords = res.searchContactsV2?.resultsSummary?.totalRecords || 0
    if (!totalRecords) return

    if (contacts.length < totalRecords) {
      // we don't have all the contacts, add as a filter
      state.removeItems(contacts)
      state.addFilter(variables.filter, totalRecords)
    } else {
      // we got all the contacts, lets just add them as individual selects
      state.addItems(contacts)
    }
    setShowSelectAll(false)
  }

  // handle data table clicking the select all checkbox
  const handleSelectAll = (isChecked: boolean) => {
    if (!!currentFilter?.totalRecords) {
      // we are in select all, lets unselect all
      state.removeFilter(variables.filter)
      setShowSelectAll(false)
    } else if (isChecked) {
      state.addItems(rows)
      setShowSelectAll(true)
    } else {
      state.removeItems(rows)
      setShowSelectAll(false)
    }
  }

  // reset to page 0 when the filter changes
  useDeepCompareEffect(() => {
    setPage(0)
    setShowSelectAll(false)
  }, [variables.filter])

  // remove select all option if the page is changed
  useEffect(() => {
    setShowSelectAll(false)
    prefetchPage(page + 1)
    prefetchPage(Math.max(page - 1, 0))
    // prefetchPage causing this to fire repeatedly if not excluded from dep array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <>
      <DataTableHeader
        onSearch={onSearch}
        filter={filter}
        graphqlFilter={graphqlFilter}
        state={state}
        showSelectAll={showSelectAll}
        handleAddFilter={handleAddFilter}
        summary={summary}
        customizeTableProps={customizeTableProps}
      />
      <UiEasyDataTable
        border="none"
        isLoading={isFetching}
        rowKey="id"
        rows={rows}
        columns={convertedColumns || []}
        selected={state.items}
        onSelect={handleSelect}
        allSelected={!!currentFilter?.totalRecords}
        boxShadow="none"
        borderRadius="4px"
        tableProps={internalTableProps}
        progressBarProps={internalProgressBarProps}
        SelectCheckbox={ZCheckbox}
      />
      <UiEasyPagination
        onPrefetchPage={prefetchPage}
        onSetPage={setPage}
        currentPage={page}
        totalPages={summary.totalPages}
        hasNextPage={hasNextPage}
      />
    </>
  )
}
