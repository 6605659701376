import { Box } from '@chakra-ui/react'
import { UiLoading } from '@postal-io/postal-ui'
import { useExtension } from 'hooks'
import { NavbarBackButton, SecondaryNavbar } from './SecondaryNavbar'

const loadingStyles = { 'position': 'relative', 'bg': 'white', '& .chakra-spinner': { color: 'atomicBlue.400' } }

interface PostalSendLoadingProps {
  onNavigateBack: () => void
  backLabel: string
}
export const PostalSendLoading: React.FC<PostalSendLoadingProps> = ({ onNavigateBack, backLabel }) => {
  const { isExtension } = useExtension()
  return (
    <>
      {!isExtension && (
        <SecondaryNavbar
          maxWidth="100rem"
          px={{ base: 4, lg: 8, xl: 16 }}
          left={
            <NavbarBackButton
              onClick={onNavigateBack}
              label={backLabel}
            />
          }
          header="Loading..."
        />
      )}
      <Box position="relative">
        {/* @ts-ignore-next-line */}
        <UiLoading sx={loadingStyles} />
      </Box>
    </>
  )
}
