import type { SlideProps } from '@chakra-ui/react'
import { Box, Grid, Slide } from '@chakra-ui/react'
import { CenteredBox } from 'components/Common'
import { PageTitle } from 'hooks'
import { Helmet } from 'lib'
import React from 'react'
import { OrdersSidebarBlocks } from '../Orders'

const SRC = process.env.REACT_APP_SWAG_IFRAME

export const SwagOrders: React.FC = () => {
  if (!SRC) return null

  return (
    <>
      <PageTitle title="Swag Orders" />
      <CenteredBox isLoaded>
        <Grid
          data-testid="UiSidebar_grid"
          gridTemplateColumns={{
            base: '100%',
            lg: `150px minmax(0,1fr)`,
          }}
          gridGap={{ base: 4, xl: 8 }}
          alignItems="start"
          as="section"
          mt={8}
        >
          <Box
            w="100%"
            h="100%"
          >
            <OrdersSidebarBlocks />
          </Box>
          <Box>
            <ZSlide in={true}>
              <>
                <Box id="swag-iframe" />
                <Helmet>
                  <script
                    nonce="7e6d36ae12ce81578f3f61fb0b871750"
                    defer
                    src={SRC}
                  ></script>
                </Helmet>
              </>
            </ZSlide>
          </Box>
        </Grid>
      </CenteredBox>
    </>
  )
}

const ZSlide: React.FC<SlideProps> = (props) => (
  <Slide
    unmountOnExit
    direction="right"
    style={{ position: 'relative' }}
    {...props}
  />
)
