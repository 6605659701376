import { Box, ButtonGroup, Icon, IconButton, useDisclosure } from '@chakra-ui/react'
import { UiEnhancedTooltip, ZConfirm, ZText } from '@postal-io/postal-ui'
import React, { useState } from 'react'
import {
  MdOutlineDelete,
  MdOutlineGridOn,
  MdOutlineRestore,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
  MdOutlineWbIridescent,
  MdRotateLeft,
  MdRotateRight,
} from 'react-icons/md'
export interface ToolbarButtonProps {
  element: any
  canReset: boolean
  canUndo: boolean
  canRedo: boolean
  showGrid: boolean
  snapGrid: boolean
  dispatch: any // (e: { type: string; payload?: any }) => void
  mt?: number
}

export const ToolbarButtons: React.FC<ToolbarButtonProps> = ({
  element,
  canReset,
  canUndo,
  canRedo,
  showGrid,
  snapGrid,
  dispatch,
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [removeElement, setRemoveElement] = useState(null)

  const onToggleVisibility = () => {
    if (element.locked) return
    dispatch({ type: 'TOGGLE_VISIBILITY', payload: element })
  }

  const onRemove = () => {
    if (element.locked) return
    setRemoveElement(element)
    onOpen()
  }

  const handleRemove = () => {
    removeElement && dispatch({ type: 'REMOVE', payload: removeElement })
    setRemoveElement(null)
    onClose()
  }

  const iconProps = {
    w: '22px',
    h: '22px',
  }

  const buttonProps = {
    variant: 'link',
    size: 'sm',
    color: 'atomicGray.600',
    // _disabled: { color: 'atomicGray.400' },
  }

  return (
    <>
      <Box
        textAlign="center"
        {...rest}
      >
        <ButtonGroup spacing={1}>
          <UiEnhancedTooltip label="Undo">
            <IconButton
              {...buttonProps}
              icon={
                <Icon
                  as={MdRotateLeft}
                  {...iconProps}
                />
              }
              isDisabled={!canUndo}
              onClick={() => dispatch({ type: 'UNDO' })}
              aria-label="Undo"
              title="Undo"
            />
          </UiEnhancedTooltip>
          <UiEnhancedTooltip label="Redo">
            <IconButton
              {...buttonProps}
              icon={
                <Icon
                  as={MdRotateRight}
                  {...iconProps}
                />
              }
              isDisabled={!canRedo}
              onClick={() => dispatch({ type: 'REDO' })}
              aria-label="Redo"
              title="Redo"
            />
          </UiEnhancedTooltip>
          <UiEnhancedTooltip label={`${element?.hidden ? 'Show' : 'Hide'} Layer`}>
            <IconButton
              {...buttonProps}
              icon={
                element?.hidden ? (
                  <Icon
                    as={MdOutlineVisibilityOff}
                    {...iconProps}
                  />
                ) : (
                  <Icon
                    as={MdOutlineVisibility}
                    {...iconProps}
                  />
                )
              }
              isDisabled={!element || element.locked}
              onClick={onToggleVisibility}
              aria-label="Toggle Visibility"
              title="Toggle Visibility"
            />
          </UiEnhancedTooltip>
          <UiEnhancedTooltip label="Delete Layer">
            <IconButton
              {...buttonProps}
              icon={
                <Icon
                  as={MdOutlineDelete}
                  {...iconProps}
                />
              }
              isDisabled={!element || element.locked}
              onClick={onRemove}
              aria-label="Delete Element"
              title="Delete Element"
            />
          </UiEnhancedTooltip>
          <ZText
            color="atomicGray.400"
            fontSize="lg"
          >
            |
          </ZText>
          <UiEnhancedTooltip label="Reset">
            <IconButton
              {...buttonProps}
              icon={
                <Icon
                  as={MdOutlineRestore}
                  {...iconProps}
                />
              }
              isDisabled={!canReset}
              onClick={() => dispatch({ type: 'RESET' })}
              aria-label="Reset"
              title="Reset"
            />
          </UiEnhancedTooltip>
          <UiEnhancedTooltip label="Toggle Grid">
            <IconButton
              {...buttonProps}
              color={showGrid ? 'atomicGray.600' : 'atomicGray.500'}
              icon={
                <Icon
                  as={MdOutlineGridOn}
                  {...iconProps}
                />
              }
              onClick={() => dispatch({ type: 'TOGGLE_GRID' })}
              aria-label="Toggle Grid"
              title="Toggle Grid"
            />
          </UiEnhancedTooltip>
          <UiEnhancedTooltip label="Toggle Grid Snapping">
            <IconButton
              {...buttonProps}
              color={snapGrid ? 'atomicGray.600' : 'atomicGray.500'}
              icon={
                <Icon
                  as={MdOutlineWbIridescent}
                  {...iconProps}
                />
              }
              onClick={() => dispatch({ type: 'TOGGLE_SNAP_GRID' })}
              aria-label="Snap To Grid"
              title="Snap To Grid"
            />
          </UiEnhancedTooltip>
        </ButtonGroup>
      </Box>
      <ZConfirm
        title="Confirm Deletion"
        isOpen={isOpen}
        onClose={onClose}
        buttonColor="atomicRed"
        buttonText="Delete"
        onConfirm={handleRemove}
      >
        <ZText fontSize="md">Are you sure you want to delete this layer?</ZText>
      </ZConfirm>
    </>
  )
}
