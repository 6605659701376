import type { TextProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'
import { ZMoney, ZText } from '@postal-io/postal-ui'
import type { CollectionItem } from 'components/Collections/utils'
import { isNumber } from 'lodash'
import React, { useMemo } from 'react'
import { Status } from '../../api'
import type { ApprovedPostal, MarketplaceProduct, SearchableProduct } from '../../api/index'

interface MarketplaceProductPriceRangeProps extends TextProps {
  item: MarketplaceProduct
}

export const MarketplaceProductPriceRange: React.FC<MarketplaceProductPriceRangeProps> = ({ item, ...rest }) => {
  const { minPrice, maxPrice } = useMemo(() => {
    return (
      item?.variants
        ?.filter((v) => v.status === Status.Active)
        .reduce((obj, v) => {
          // default to first entry of partner list
          const price = v?.fulfillmentPartnerList?.[0].displayPrice
          if (!isNumber(price)) return obj
          if (obj.minPrice === undefined || price < obj.minPrice) obj.minPrice = price
          if (price !== obj.minPrice && price > (obj.maxPrice ?? 0)) obj.maxPrice = price
          return obj
        }, {} as { minPrice?: number; maxPrice?: number }) ?? {}
    )
  }, [item?.variants])

  const estimatedShippingCost = useMemo(
    () => item?.variants?.[0]?.fulfillmentPartnerList?.[0].shippingOptions?.[0]?.price,
    [item?.variants]
  )

  return (
    <ZText {...rest}>
      <chakra.span
        color="atomicGray.700"
        fontWeight="bold"
      >
        {isNumber(minPrice) ? (
          <ZMoney
            cents={minPrice}
            currency={item.currency}
            round
          />
        ) : (
          ''
        )}
        {isNumber(minPrice) && isNumber(maxPrice) ? ' - ' : ''}
        {isNumber(maxPrice) ? (
          <ZMoney
            cents={maxPrice}
            currency={item.currency}
            round
          />
        ) : (
          ''
        )}
      </chakra.span>
      {isNumber(estimatedShippingCost) && estimatedShippingCost > 0 && (
        <chakra.span color="atomicGray.500"> +{Math.round((estimatedShippingCost ?? 0) / 100)} shipping</chakra.span>
      )}
    </ZText>
  )
}

interface SearchableProductPriceRangeProps extends TextProps {
  product: SearchableProduct
}

export const SearchableProductPriceRange: React.FC<SearchableProductPriceRangeProps> = ({ product, ...rest }) => {
  const { minPrice, maxPrice, minCost, currency } = product

  const shippingCost = minCost - minPrice
  const hasRange = minPrice !== maxPrice

  return (
    <ZText {...rest}>
      <chakra.span
        color="atomicGray.700"
        fontWeight="bold"
      >
        {isNumber(minPrice) && (
          <ZMoney
            cents={minPrice}
            currency={currency}
            round
          />
        )}
        {hasRange && (
          <>
            {' - '}
            <ZMoney
              cents={maxPrice}
              currency={currency}
              round
            />
          </>
        )}
      </chakra.span>
      {isNumber(shippingCost) && shippingCost > 0 && (
        <chakra.span color="atomicGray.500"> +{Math.round((shippingCost ?? 0) / 100)} shipping</chakra.span>
      )}
    </ZText>
  )
}

interface MarketplaceApprovedPostalPriceRangeprops extends TextProps {
  item: ApprovedPostal | CollectionItem
  excludeShipping?: boolean
}

export const MarketplaceApprovedPostalPriceRange: React.FC<MarketplaceApprovedPostalPriceRangeprops> = ({
  item,
  excludeShipping,
  ...rest
}) => {
  const { minPrice, maxPrice } = useMemo(() => {
    return (
      item?.variants
        ?.filter((v) => v.status === Status.Active)
        .reduce((obj, v) => {
          const price = v.displayPrice
          if (!isNumber(price)) return obj
          if (obj.minPrice === undefined || price < obj.minPrice) obj.minPrice = price
          if (price !== obj.minPrice && price > (obj.maxPrice ?? 0)) obj.maxPrice = price
          return obj
        }, {} as { minPrice?: number; maxPrice?: number }) ?? {}
    )
  }, [item?.variants])

  const { minShipping, maxShipping } = useMemo(() => {
    if (excludeShipping) return {}
    return (
      item?.variants?.reduce((obj, v) => {
        const minPrice = v.shippingPriceEstimateMin
        const maxPrice = v.shippingPriceEstimateMax
        if (isNumber(minPrice) && minPrice > 0 && (obj.minShipping === undefined || minPrice < obj.minShipping)) {
          obj.minShipping = minPrice
        }
        if (
          isNumber(maxPrice) &&
          maxPrice > 0 &&
          maxPrice !== obj.minShipping &&
          (obj.maxShipping === undefined || maxPrice > obj.maxShipping)
        ) {
          obj.maxShipping = maxPrice
        }
        return obj
      }, {} as { minShipping?: number; maxShipping?: number }) ?? {}
    )
  }, [excludeShipping, item?.variants])

  return (
    <ZText
      as="span"
      {...rest}
    >
      <chakra.span
        color={rest.color ?? 'atomicGray.700'}
        fontWeight={rest.fontWeight ?? 'bold'}
      >
        {isNumber(minPrice) ? (
          <ZMoney
            fontSize={rest.fontSize}
            cents={minPrice}
            currency={item.currency}
            round
          />
        ) : (
          ''
        )}
        {isNumber(minPrice) && isNumber(maxPrice) ? ' - ' : ''}
        {isNumber(maxPrice) ? (
          <ZMoney
            fontSize={rest.fontSize}
            cents={maxPrice}
            currency={item.currency}
            round
          />
        ) : (
          ''
        )}
      </chakra.span>
      {(isNumber(minShipping) || isNumber(maxShipping)) && (
        <chakra.span color="atomicGray.500">
          {' + '}
          {isNumber(minShipping) ? minShipping / 100 : ''}
          {isNumber(minPrice) && isNumber(maxShipping) ? ' - ' : ''}
          {isNumber(maxShipping) ? maxShipping / 100 : ''} shipping
        </chakra.span>
      )}
    </ZText>
  )
}
