import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiChangeEvent } from '@postal-io/postal-ui'
import {
  UiFormControl,
  UiProgressBar,
  useAlerts,
  ZButton,
  ZFormLabel,
  ZInputMoney,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import type { PartnerPaymentMethod, PaymentPartnerType } from 'api'
import { AddFundsV2Document, BackgroundTaskStatus, PreviewAddFundsDocument } from 'api'
import { ZAlert } from 'components/Common/ZComponents'
import { BILLING_INVALIDATIONS, useBackgroundQueue, useSession } from 'hooks'
import { isNumber } from 'lodash'
import React, { useMemo, useRef, useState } from 'react'

interface BillingAddFundsProps {
  billingAccount: any
  onClose?: () => void
  hasPaymentAccount?: boolean
  paymentPartnerType?: PaymentPartnerType
  partnerPaymentMethod?: PartnerPaymentMethod
}

type Status = BackgroundTaskStatus | undefined

export const BillingAddFunds: React.FC<BillingAddFundsProps> = ({
  billingAccount,
  hasPaymentAccount,
  paymentPartnerType,
  partnerPaymentMethod,
}) => {
  const confirmDisclosure = useDisclosure()
  const Alert = useAlerts()
  const [amount, setAmount] = useState<number | undefined>()
  const [status, setStatus] = useState<Status>()
  const [errors, setErrors] = useState<any[]>()

  const { queue, invalidate } = useBackgroundQueue()
  const session = useSession()

  const cancelRef = useRef(null)

  const previewQuery = useGraphqlQuery(
    PreviewAddFundsDocument,
    { input: { amount: amount as number, paymentPartnerType } },
    { enabled: isNumber(amount) }
  )
  const preview = useMemo(() => previewQuery.data?.previewAddFunds, [previewQuery.data?.previewAddFunds])

  const handleAddFundsResponse = async (data: any) => {
    const { status, errors } = data
    setStatus(status)
    if (status === BackgroundTaskStatus.Completed) {
      Alert.success('Account updated')
    } else if (status === BackgroundTaskStatus.Error) {
      setErrors(errors)
    }
    setAmount(undefined)
  }

  const addFundsV2 = useGraphqlMutation(AddFundsV2Document, {
    onSuccess: (data) => {
      queue(data.addFundsV2, handleAddFundsResponse, [BackgroundTaskStatus.Completed, BackgroundTaskStatus.Error])
      invalidate(BILLING_INVALIDATIONS)
    },
  })

  const handleSetFunds = ({ value }: UiChangeEvent<number>) => setAmount(value)

  const handleSubmitFunds = (e: React.FormEvent) => {
    e.preventDefault()
    if (!amount || amount < 0) return Alert.warning('Please provide a valid amount')
    if (amount < 100) return Alert.warning('Please provide an amount of at least $1')
    if (amount >= 10000000) return Alert.warning('Please provide an amount less than $100,000')
    confirmDisclosure.onOpen()
  }

  const onConfirm = async () => {
    if (!amount) return Alert.warning('Please provide a valid amount.')

    try {
      setStatus(BackgroundTaskStatus.Pending)
      await addFundsV2.mutateAsync({
        input: {
          billingAccountId: billingAccount?.id,
          amount,
          paymentPartnerType,
          partnerPaymentMethodId: partnerPaymentMethod?.partnerId,
        },
      })
      confirmDisclosure.onClose()
    } catch (err) {
      setStatus(BackgroundTaskStatus.Error)
      Alert.error(err)
    }
  }

  const userEmail = session?.session?.userName

  return (
    <>
      <form
        onSubmit={handleSubmitFunds}
        id="billingAddFunds"
      >
        <UiFormControl id="addFunds">
          <ZFormLabel
            fontSize="md"
            pb={2}
          >
            Add Funds
          </ZFormLabel>

          {status === BackgroundTaskStatus.Pending && (
            <Box
              mb={6}
              onClick={() => setStatus(undefined)}
            >
              <ZAlert
                status="info"
                hideClose
                position="relative"
              >
                <UiProgressBar
                  position="absolute"
                  top={0}
                  left={0}
                />
                {`Funds are being added to ${billingAccount?.name}. This process may take a few moments. Hang tight.`}
              </ZAlert>
            </Box>
          )}

          {status === BackgroundTaskStatus.Error && (
            <Box mb={6}>
              <ZAlert
                status="error"
                onClose={() => setStatus(undefined)}
              >
                {errors?.map((error) => error.message)?.join(' ') ?? 'There was an error with this request.'}
              </ZAlert>
            </Box>
          )}

          <Flex>
            <ZInputMoney
              placeholder="1000.00"
              name="addFunds"
              value={amount}
              min={1}
              onChange={handleSetFunds}
              isDisabled={status === BackgroundTaskStatus.Pending}
            />
            <ZButton
              form="billingAddFunds"
              type="submit"
              ml={3}
              width="200px"
              size="md"
              justifyContent="center"
              colorScheme="atomicBlue"
              isDisabled={!amount || !hasPaymentAccount || status === BackgroundTaskStatus.Pending}
            >
              Add Funds
            </ZButton>
          </Flex>
        </UiFormControl>
      </form>

      <AlertDialog
        size="2xl"
        isCentered
        leastDestructiveRef={cancelRef}
        {...confirmDisclosure}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogCloseButton />
          <AlertDialogHeader>Confirm Funds</AlertDialogHeader>
          <AlertDialogBody>
            <Stack
              spacing={8}
              textAlign="center"
            >
              <ZText fontSize="md">
                By clicking confirm, you acknowledge that you will be charged{' '}
                <ZMoney
                  cents={preview?.grossPaymentAmount}
                  currency={billingAccount?.currency}
                  fontSize="md"
                  fontWeight="bold"
                />{' '}
                to your card ending in {partnerPaymentMethod?.card?.last4}.
              </ZText>

              <ZText fontSize="md">
                <strong>
                  {preview?.feeInfo.percentFee}% {'('}
                  <ZMoney
                    cents={preview?.feeInfo.totalFee}
                    currency={billingAccount?.currency}
                    fontSize="md"
                    fontWeight="bold"
                  />
                  {') '}
                </strong>
                will be deducted as a credit card processing fee.
              </ZText>

              <Box
                bg="atomicBlue.10"
                borderRadius={5}
                p={5}
              >
                <ZText fontSize="md">
                  <strong>
                    <ZMoney
                      cents={preview?.netPaymentAmount}
                      currency={billingAccount?.currency}
                      fontSize="md"
                      fontWeight="inherit"
                    />
                  </strong>{' '}
                  will be added to your <strong>{billingAccount?.name}</strong>.
                </ZText>
              </Box>
              <ZText fontSize="md">
                A confirmation email will be sent to: <strong>{userEmail}</strong>. To avoid the processing fee, you may
                request to pay by <strong>invoice</strong> directly.
              </ZText>
            </Stack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              onClick={onConfirm}
              minW={32}
            >
              Confirm
            </Button>
            <Button
              ref={cancelRef}
              variant="ghost"
              colorScheme="atomicGray"
              onClick={confirmDisclosure.onClose}
              minW={32}
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
