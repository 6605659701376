import { Stack } from '@chakra-ui/react'
import { useAcl, useBulkSendPermissions } from 'hooks'
import React from 'react'
import { OrdersTypes } from './data'
import { OrdersCategory } from './OrdersCategory'

const LIMIT = 4

export const OrdersOverview: React.FC = () => {
  const { hasSubscription } = useAcl()
  const hasABM = hasSubscription('POSTAL_ABM_ACCESS')

  const { hasBulkSend } = useBulkSendPermissions()
  return (
    <Stack spacing={10}>
      <OrdersCategory
        showAllLink
        ordersType={OrdersTypes.Drafts}
        limit={LIMIT}
      />

      <OrdersCategory
        showAllLink
        ordersType={OrdersTypes.Emails}
        limit={LIMIT}
      />

      <OrdersCategory
        showAllLink
        ordersType={OrdersTypes.Links}
        limit={LIMIT}
      />

      <OrdersCategory
        showAllLink
        ordersType={OrdersTypes.Direct}
        limit={LIMIT}
      />

      {hasBulkSend && (
        <OrdersCategory
          showAllLink
          ordersType={OrdersTypes.BulkSends}
          limit={LIMIT}
        />
      )}

      <OrdersCategory
        showAllLink
        ordersType={OrdersTypes.Subscriptions}
        limit={LIMIT}
      />

      <OrdersCategory
        showAllLink
        ordersType={OrdersTypes.Triggers}
        limit={LIMIT}
      />

      {hasABM && (
        <OrdersCategory
          showAllLink
          ordersType={OrdersTypes.ABM}
          limit={LIMIT}
        />
      )}
    </Stack>
  )
}
