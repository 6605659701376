import { Box, Grid } from '@chakra-ui/react'
import { fromNow, ZButton, ZCard, ZCardBody, ZHeading, ZInput, ZInputDate } from '@postal-io/postal-ui'
import { ZAlert, ZFormLabel, ZInlineFormControl } from 'components/Common/ZComponents'
import { CATEGORY } from 'components/Postals'
import type { PostalSendEventV2 } from 'components/PostalSend/usePostalSend'
import { getSendMethodColor } from 'components/PostalSend/usePostalSend'
import { format, startOfTomorrow } from 'date-fns'
import React, { useEffect } from 'react'
import type { PostalSendContext } from './usePostalSend'
import { ensureFutureDate, nowPlusDelay, tomorrowMidnight, tomorrowMorning } from './usePostalSend'
import { usePostalSendFieldErrors } from './usePostalSendFieldErrors'

interface PostalCustomizeCampaignV2Props {
  context: PostalSendContext
  send: (evt: PostalSendEventV2) => void
}

export const PostalCustomizeCampaign: React.FC<PostalCustomizeCampaignV2Props> = ({ context, send, ...rest }) => {
  const isDirectMail = context?.postal?.category === CATEGORY.DirectMail

  const handleName = ({ target }: any) => {
    send({ type: 'SET_NAME', data: target.value })
  }

  const handleDate = ({ target }: any) => {
    const { value } = target
    send({ type: 'SET_DATE', data: ensureFutureDate(value) })
  }

  // set initial value for datepicker
  useEffect(() => {
    if (context.isCampaign && !context.date) {
      send({ type: 'SET_DATE', data: isDirectMail ? tomorrowMidnight() : tomorrowMorning() })
    }
  }, [context.date, context.isCampaign, isDirectMail, send])

  const nameErrorProps = usePostalSendFieldErrors({ context, field: 'campaignName' })
  const dateErrorProps = usePostalSendFieldErrors({ context, field: 'campaignDate' })

  if (!context.isCampaign) return null

  return (
    <ZCard
      variant="form"
      pt={{ base: 4, md: 8 }}
      borderWidth={{ base: 0, md: 1 }}
      {...rest}
    >
      <ZCardBody>
        <ZHeading
          as="h2"
          size="h5"
          mb={8}
        >
          Scheduling
        </ZHeading>

        <Grid gap={5}>
          <ZInlineFormControl
            gridTemplateColumns={{ base: '80px 1fr', lg: '1fr 1fr' }}
            id="name"
          >
            <ZFormLabel>Name</ZFormLabel>
            <ZInput
              {...nameErrorProps}
              height="40px"
              onChange={handleName}
              value={context.name}
              placeholder="Set the Name"
            />
          </ZInlineFormControl>

          <Box>
            {!isDirectMail && (
              <Box
                textAlign="right"
                mt={-3}
              >
                <ZButton
                  display="inline-block"
                  color={getSendMethodColor(context)}
                  fontSize="sm"
                  variant="link"
                  p={0}
                  h="unset"
                  onClick={() => send({ type: 'SET_DATE', data: nowPlusDelay(0) })}
                >
                  Now
                </ZButton>
                <ZButton
                  display="inline-block"
                  color={getSendMethodColor(context)}
                  ml={2}
                  fontSize="sm"
                  variant="link"
                  p={0}
                  h="unset"
                  onClick={() => send({ type: 'SET_DATE', data: tomorrowMorning() })}
                >
                  Tomorrow Morning
                </ZButton>
              </Box>
            )}
            <ZInlineFormControl
              gridTemplateColumns={{ base: '80px 1fr', lg: '1fr 1fr' }}
              id="date"
              sx={{
                '& input.chakra-input': { height: '40px', fontSize: 'sm' },
                '& .chakra-input__left-element': { 'height': '100%', '& svg': { w: '16px', h: '16px' } },
                '& .chakra-input__right-element': { 'height': '100%', '& svg': { w: '12px', h: '12px' } },
              }}
            >
              <ZFormLabel>Send On</ZFormLabel>
              <ZInputDate
                {...dateErrorProps}
                name="date"
                value={context.date}
                onChange={handleDate}
                enableTime={!isDirectMail}
                data-min-date={isDirectMail ? format(startOfTomorrow(), 'yyyy-MM-dd') : 'today'}
                type="text"
                hideClear={true}
              />
            </ZInlineFormControl>
          </Box>
          {context?.date && (
            <ZAlert
              mb={8}
              status="info"
              title={`This order will be queued up and sent ${fromNow(context.date)}`}
              hideClose
            />
          )}
        </Grid>
      </ZCardBody>
    </ZCard>
  )
}
