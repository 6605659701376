import type { FlexProps } from '@chakra-ui/react'
import { Flex, Stack } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { UiSkeleton, useAlerts, ZButton } from '@postal-io/postal-ui'
import type { User } from 'api'
import { Result, Role, UpdateRolesV2Document } from 'api'
import { AutoCompleteUser } from 'components/AutoComplete/AutoCompleteUser'
import { useAcl, useSession } from 'hooks'
import type { FormEvent } from 'react'
import React from 'react'
import { useImmer } from 'use-immer'
import { MenuUserRole } from '../AutoComplete'

import { SendUserEmailCheckbox } from 'components/Users/SendUserEmailCheckbox'

const PRODUCT_ID = process.env.REACT_APP_PRODUCT_ID

interface State {
  search: string
  user?: User
  roles: Role[]
  sendNotification?: boolean
}
interface TeamUserAddProps extends FlexProps {
  teamId?: string
  onUpdate?: (user: User) => void
  onClose?: () => void
}
export const TeamUserAdd: React.FC<TeamUserAddProps> = ({ teamId, onUpdate, onClose, ...rest }) => {
  const [state, setState] = useImmer<State>({ search: '', roles: [Role.User] })
  const Alert = useAlerts()
  const { session } = useSession()
  const { hasPermission } = useAcl()
  const canUpdate = hasPermission('users.update')
  const updateRoles = useGraphqlMutation(UpdateRolesV2Document)

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (!state.user || !state.roles.length) {
      return Alert.warning('A User and at least one Role is required.')
    }

    // get any existing products for this team or account
    const existingProducts =
      state.user.productAccess?.filter((product) => {
        if (product.product !== PRODUCT_ID) return false
        if (product.accountId !== session.accountId) return false
        return teamId ? product.teamId === teamId : !product.teamId
      }) || []

    // add User in addition to any existing roles for this team or account
    const newRoles = new Set<string>(state.roles)
    existingProducts.forEach((p) => p.roles?.forEach((r) => newRoles.add(r)))

    try {
      const res = await updateRoles.mutateAsync({
        id: state.user.id,
        teamId,
        roles: Array.from(newRoles),
        sendNotification: !!state.sendNotification,
      })
      const error = res.updateRolesV2?.find((r) => r.result !== Result.Success)
      error ? Alert.warning(error.reason || 'Not all Roles were added') : Alert.success('User added to Team')
      setState((draft) => {
        draft.search = ''
        draft.user = undefined
      })
      onUpdate && onUpdate(state.user)
    } catch (err) {
      Alert.error(err)
    }
  }

  if (!canUpdate) return null

  return (
    <UiSkeleton
      isLoaded={true}
      {...rest}
    >
      <form onSubmit={handleSubmit}>
        <Stack
          spacing={8}
          mb={16}
        >
          <AutoCompleteUser
            rootProps={{ mt: 4, minW: '250px' }}
            inputValue={state.search}
            onInputChange={(value: string) => setState((draft) => void (draft.search = value))}
            value={state.user || null}
            onChange={(team) => setState((draft) => void (draft.user = team || undefined))}
          />
          <MenuUserRole
            as={ZButton}
            isDisabled={updateRoles.isLoading}
            value={state.roles}
            onChange={(newRoles) => setState((draft) => void (draft.roles = newRoles))}
            teamId={teamId}
            fontSize="sm"
            fontWeight="normal"
            h="40px"
            w="full"
            pl={4}
            textAlign="left"
            color="atomicGray.600"
          />
          <SendUserEmailCheckbox
            mb={4}
            isChecked={!!state.sendNotification}
            onChange={(e) => setState((draft) => void (draft.sendNotification = e.target.checked))}
            justifyContent="flex-start"
          />
        </Stack>
        <Flex justifyContent="space-between">
          <ZButton
            type="submit"
            colorScheme="atomicBlue"
            isDisabled={!state.user || !state.roles.length || updateRoles.isLoading}
            minW="140px"
          >
            Update Roles
          </ZButton>
          <ZButton
            colorScheme="atomicGray"
            variant="ghost"
            onClick={onClose}
            minW="140px"
          >
            Cancel
          </ZButton>
        </Flex>
      </form>
    </UiSkeleton>
  )
}
