import { useGraphqlFetch, useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlerts } from '@postal-io/postal-ui'
import type { MultiSelectFilter } from 'components/MultiSelectContacts/useMultiSelect'
import { PostalSend, PostalSendType } from 'components/PostalSend'
import type { QuickCreateContactForm } from 'components/PostalSend/PostalCustomizeBulkSend'
import type { MultiSelectContactsState } from 'components/PostalSend/PostalSelectContacts'
import { getSendMethodFromSavedSendType } from 'components/PostalSend/postalSendHelpers'
import { PostalSendLoading } from 'components/PostalSend/PostalSendLoading'
import { omit, some } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import type { Address, ApprovedPostal, NewContact, SavedSend, SearchableContact } from '../../api'
import { GetApprovedPostalDocument, GetSavedSendDocument, SavedSendType, SearchContactsV2Document } from '../../api'

const LIMIT = 200

// const dummyDraft = {
//   commonSendProperties: {},
//   savedSendMagicLinkInfo: {},
//   savedSendCampaignInfo: {},
//   sendType: SavedSendType.Direct,
// } as SavedSend

export const Draft: React.FC = () => {
  const Alert = useAlerts()
  const { savedSendId } = useParams() as any
  const navigate = useNavigate()

  const draftQuery = useGraphqlFetch(GetSavedSendDocument)
  const [draft, setDraft] = useState<SavedSend | null>(null)

  const postalQuery = useGraphqlFetch(GetApprovedPostalDocument)
  const [postal, setPostal] = useState<ApprovedPostal | null>(null)

  useEffect(
    () =>
      void (async () => {
        try {
          if (!savedSendId) throw new Error('No Draft ID found - please try again')
          const { getSavedSend } = await draftQuery({ id: savedSendId })
          setDraft(getSavedSend)
          const { getApprovedPostal } = await postalQuery({ id: getSavedSend.commonSendProperties.approvedPostalId })
          setPostal(getApprovedPostal)
        } catch (e) {
          Alert.error(e.message)
          navigate('/orders/drafts')
        }
      })(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const variant = useMemo(
    () => postal?.variants?.find((v) => v.id === draft?.commonSendProperties?.variantId),
    [postal, draft]
  )

  const { contacts, isLoading: contactsLoading } = useDraftContacts(draft)

  const {
    commonSendProperties: commonInfo,
    savedSendMagicLinkInfo: magicLinkInfo,
    savedSendCampaignInfo: campaignInfo,
    savedSendBulkSendInfo: bulkSendInfo,
    sendType,
  } = draft ?? {}

  const sendMethod = getSendMethodFromSavedSendType(sendType ?? SavedSendType.GiftEmail)

  // we must convert some BE types to Input types here
  const newContactForm = convertNewContactToQuickCreateContactForm(bulkSendInfo?.newContact)
  const shipToAddress = convertAddressToAddressInput(bulkSendInfo?.shipToAddress)

  const isLoading = !draft || !postal || contactsLoading

  return isLoading ? (
    <PostalSendLoading
      onNavigateBack={() => navigate('/orders/drafts')}
      backLabel="Back to Drafts"
    />
  ) : (
    <PostalSend
      onNavigateBack={() => navigate('/orders/drafts')}
      navigateBackLabel="Back to Draft"
      draft={draft}
      method={sendMethod}
      type={PostalSendType.Postal}
      stepToRecall={draft.sendFlowStep}
      contacts={contacts}
      postal={postal}
      variant={variant}
      giftMessage={campaignInfo?.giftMessage}
      physicalMessage={commonInfo?.physicalMessage}
      useSameMessage={campaignInfo?.useSameMessage ?? undefined}
      usePhysicalMessage={!!commonInfo?.physicalMessage}
      name={commonInfo?.name ?? undefined}
      date={campaignInfo?.scheduleDate ?? magicLinkInfo?.expirationDate}
      deliveryEmail={campaignInfo?.deliveryEmail}
      maxExecutions={magicLinkInfo?.maxExecutions ?? undefined}
      enabled={sendType === SavedSendType.MagicLink ? true : undefined}
      emailSubjectLine={campaignInfo?.emailSubjectLine}
      sendAsContactOwner={commonInfo?.sendAsContactOwner}
      sendAsUser={commonInfo?.sendAsUser}
      // bulk sends
      quantity={bulkSendInfo?.quantity ?? undefined}
      shipToAddress={shipToAddress}
      verifiedShipToAddress={bulkSendInfo?.addressVerified ?? undefined}
      newContact={newContactForm}
      meetingRequestSetting={commonInfo?.meetingRequestSetting}
      itemCustomizationInputs={commonInfo?.itemCustomizationInputs}
      formFieldList={commonInfo?.formFieldList}
      landingPageHeaderText={commonInfo?.landingPageCustomization?.headerText}
      landingPageBody={commonInfo?.landingPageCustomization?.body}
      landingPageIncludeHeadshot={!!commonInfo?.landingPageCustomization?.includeHeadshot}
      landingPageIncludeSenderName={!!commonInfo?.landingPageCustomization?.includeSenderName}
      spendAsTeamId={commonInfo?.spendAs?.teamId as string}
      spendAsUserId={commonInfo?.spendAs?.userId}
      linkNeedsApproval={magicLinkInfo?.requiresApproval}
      shippedEmailsOn={commonInfo?.recipientEmailSettings?.shippedEmailsOn ?? undefined}
      deliveredEmailsOn={commonInfo?.recipientEmailSettings?.deliveredEmailsOn ?? undefined}
      // delay
      // loadAccount
      // notifyError
    />
  )
}

export function convertAddressToAddressInput(address?: Address | null) {
  if (!address) return undefined

  // prune fields
  const { statusReason: __, ...shipToAddress } = address

  return shipToAddress
}

export function convertNewContactToQuickCreateContactForm(newContact?: NewContact | null) {
  if (!newContact) return undefined

  const { firstName, lastName, emailAddress, phones } = newContact

  return {
    firstName,
    lastName,
    emailAddress,
    phoneNumber: phones?.[0]?.phoneNumber ?? undefined,
  } as QuickCreateContactForm
}

export function useDraftContacts(draft: SavedSend | null) {
  const Alert = useAlerts()
  const navigate = useNavigate()
  const bulkSendContactId = draft?.sendType === SavedSendType.BulkSend && draft?.savedSendBulkSendInfo?.contactId

  const originalFilters = useMemo(
    () =>
      bulkSendContactId ? [{ id: { in: [bulkSendContactId] } }] : draft?.savedSendCampaignInfo?.contactSearchFilters,
    [bulkSendContactId, draft?.savedSendCampaignInfo?.contactSearchFilters]
  )

  const [filterCounts, setFilterCounts] = useImmer<(number | null)[]>([])
  const filterCountsLoading = useMemo(() => some(filterCounts.map((f) => (f ?? null) === null)), [filterCounts])
  useEffect(() => setFilterCounts(originalFilters?.map((_) => null) ?? []), [originalFilters, setFilterCounts])

  const contactIdFilter = useMemo(
    () =>
      originalFilters
        ?.map((filter, idx) => ({ filter, totalRecords: filterCounts[idx] }))
        ?.find((f) => !!f?.filter?.id?.in) || {
        filter: { id: { in: [] } },
      },
    [originalFilters, filterCounts]
  )

  const otherContactFilters = useMemo(
    () =>
      originalFilters
        ?.map((filter, idx) => ({ filter, totalRecords: filterCounts[idx] }))
        .filter((f) => !f?.filter?.id?.in) ?? [],
    [originalFilters, filterCounts]
  )

  const contactsFetch = useGraphqlFetch(SearchContactsV2Document)

  // get the counts for each filter
  useEffect(() => {
    originalFilters?.forEach(async ({ filter }: MultiSelectFilter<SearchableContact>, index) => {
      try {
        const res = await contactsFetch({ ...filter, limit: LIMIT })
        setFilterCounts((draft) => void (draft[index] = res.searchContactsV2.resultsSummary.totalRecords ?? 0))
      } catch (e) {
        Alert.error('Error fetching contacts for this draft: ' + e.message)
        navigate('/orders/drafts')
      }
    })
  }, [
    filterCounts,
    contactsFetch,
    otherContactFilters,
    contactIdFilter,
    Alert,
    setFilterCounts,
    originalFilters,
    navigate,
  ])

  const contactsQuery = useGraphqlQuery(SearchContactsV2Document, {
    filter: omit(contactIdFilter?.filter, 'totalRecords'),
    limit: LIMIT,
  })
  const contacts = useMemo(() => contactsQuery?.data?.searchContactsV2.searchableContacts, [contactsQuery?.data])
  const totalContactCount = useMemo(
    () => otherContactFilters?.reduce((acc, f) => acc + (f.totalRecords ?? 0), 0) + (contacts?.length ?? 0),
    [otherContactFilters, contacts?.length]
  )

  return {
    contacts: {
      filters: otherContactFilters,
      items: contacts,
      totalRecords: totalContactCount,
      orfilters: [contactIdFilter, ...otherContactFilters],
    } as Partial<MultiSelectContactsState>,
    isLoading: (contactIdFilter && contactsQuery.isLoading) || filterCountsLoading,
  }
}
