import React from 'react'
import { Rect } from 'react-konva'

export const CUT_SIZE = 12
export const CUT_COLOR = '#F56565'

export interface CutLineProps {
  width: number
  height: number
}

export const CutLine: React.FC<CutLineProps> = ({ width, height }) => {
  return (
    <Rect
      x={12}
      y={12}
      stroke={CUT_COLOR}
      strokeWidth={0.75}
      dash={[6, 6]}
      dashEnabled={true}
      width={width - CUT_SIZE * 2}
      height={height - CUT_SIZE * 2}
      fillEnabled={false}
      listening={false}
    />
  )
}
