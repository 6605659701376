import { Fade } from '@chakra-ui/react'
import { UiLoading } from '@postal-io/postal-ui'
import { Role } from 'api'
import { AccountPageRoute } from 'components/Account/AccountPageRoute'
import { Authorization } from 'components/Authorization'
import { Collection, Collections } from 'components/Collections'
import { CollectionAddRoute } from 'components/Collections/CollectionAddRoute'
import { CollectionCreateUpdate } from 'components/Collections/CollectionCreateUpdate'
import { NoAccess } from 'components/Common'
import { RouteErrorElement } from 'components/Common/ErrorBoundary'
import { PostalConcierge } from 'components/Concierge'
import { Contact } from 'components/Contact/Contact'
import { Contacts } from 'components/Contacts'
import { EventsApprovedPostalEdit } from 'components/Events/EventsApprovedPostalEdit'
import { EventsApprovedPostalPage } from 'components/Events/EventsApprovedPostalPage'
import { EventsMarketplaceProductPage } from 'components/Events/EventsMarketplaceProductPage'
import { EventsPage } from 'components/Events/EventsPage'
import { EventsViewPostals } from 'components/Events/EventsViewPostals'
import { EventsViewProducts } from 'components/Events/EventsViewProducts'
import GraphiQLApp from 'components/GraphiQL/GraphiQL'
import { Logout } from 'components/Logout'
import { Links } from 'components/MagicLinks'
import { Link } from 'components/MagicLinks/Link'
import { LinkEdit } from 'components/MagicLinks/LinkEdit'
import { LinksBulkApproveOrders } from 'components/MagicLinks/LinksBulkApproveOrders'
import { Main } from 'components/Main'
import { MarketplaceApprovedPostalPage, MarketplacePage, OldMarketplacePage } from 'components/Marketplace'
import { MarketplaceApprovedPostalEdit } from 'components/Marketplace/MarketplaceApprovedPostalEdit'
import { MarketplaceProductPage } from 'components/Marketplace/MarketplaceProductPage'
import { MarketplaceViewHome } from 'components/Marketplace/MarketplaceViewHome'
import { MarketplaceViewProducts } from 'components/Marketplace/MarketplaceViewProducts'
import { OldMarketplaceViewHome } from 'components/Marketplace/OldMarketplaceViewHome'
import { OldMarketplaceViewPostals } from 'components/Marketplace/OldMarketplaceViewPostals'
import { OldMarketplaceViewProducts } from 'components/Marketplace/OldMarketplaceViewProducts'
import { ViewFavorites } from 'components/Marketplace/ViewFavorites'
import { CampaignRetry } from 'components/Orders/CampaignRetry'
import { Draft } from 'components/Orders/Draft'
import { Order } from 'components/Orders/Order'
import { Orders } from 'components/Orders/Orders'
import { PlaybookDefinitionsPage } from 'components/PlaybookDefinitions'
import { PlaybookDefinitionEdit } from 'components/PlaybookDefinitions/PlaybookDefinitionEdit'
import { PlaybookDefinitionPage } from 'components/PlaybookDefinitions/PlaybookDefinitionPage'
import { PlaybookDefinitionStepPostalSend } from 'components/PlaybookDefinitions/PlaybookDefinitionStepPostalSend'
import { ApprovedPostalSend, ContactPostalSend } from 'components/Postals'
import { RetryPostal } from 'components/PostalSend/RetryPostal'
import {
  AccountSettings,
  BillingAccount,
  BillingAccounts,
  Branding,
  BudgetHistory,
  EmailBlockList,
  EmailSettings,
  EmailTemplates,
  GiftEmails,
  IntegrationEntry,
  Integrations,
  MeetingSettings,
  NotificationSettings,
  Profile,
  SavedMessages,
  Security,
  Team,
  TeamBudgets,
  Teams,
  Transfers,
  UserIndividual,
  Users,
  Warehousing,
} from 'components/Profile'
import { Notifications } from 'components/Profile/Notifications'
import { V2PathRedirect } from 'components/Redirect'
import { RedirectItem } from 'components/Redirect/RedirectItem'
import { RedirectUser } from 'components/Redirect/RedirectUser'
import { Register } from 'components/Register'
import { OrderReport, Reporting, SavedReports } from 'components/Reporting'
import { ActivityStream } from 'components/Reporting/ActivityStream'
import { Impact } from 'components/Reporting/Impact'
import { PostalFulfillment } from 'components/Reporting/OrderReport/PostalFulfillment'
import { Overview } from 'components/Reporting/Overview'
import { Summary } from 'components/Reporting/Summary'
import { AccountDisabled, ResetPassword, SignIn, SignUp, Sso, VerifyLoading } from 'components/Session'
import { SwagEditor, SwagOrders, useHasSwagEditor } from 'components/SwagEditor'
import { TeamUsersRoute } from 'components/Teams/TeamUsersRoute'
import { Trigger, TriggerEdit } from 'components/Trigger'
import { Triggers } from 'components/Triggers/Triggers'
import { UsersInviteTable } from 'components/Users'
import { Welcome } from 'components/Welcome'
import { useAcl } from 'hooks'
import { StorageKeys } from 'lib'
import { useMemo } from 'react'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { createBrowserRouter, Navigate, redirect, RouterProvider } from 'react-router-dom'
import AppRoot from './AppRoot'
import { requirePermissions, requireUser } from './session'

// auth vs non-auth
export function AppRoutes() {
  const { hasPermission, hasFeature, hasRole, isLoading } = useAcl()
  const hasSwagEditor = useHasSwagEditor()
  const routes = useMemo(() => {
    return [
      {
        path: 'v2/*',
        element: <V2PathRedirect />,
      },
      {
        path: 'sso/:token',
        element: <Sso />,
      },
      {
        path: 'connect/:providerEndpoint',
        async loader({ params }: LoaderFunctionArgs) {
          const providerEndpoint = params.providerEndpoint
          return redirect(`/integrations/${providerEndpoint}`)
        },
      },
      {
        path: 'logout/:reason?',
        element: <Logout />,
      },
      {
        path: 'sign-in',
        element: <SignIn />,
        async loader({ request }: LoaderFunctionArgs) {
          const searchParams = new URL(request.url).searchParams
          const returnTo = searchParams.get('returnTo')
          if (returnTo) sessionStorage.setItem(StorageKeys.AppRedirectPath, returnTo)
          return new Response()
        },
      },
      {
        path: 'sign-up/:pageType?',
        element: <SignUp />,
      },
      {
        path: 'email/error/:errorType',
        element: <SignUp />,
      },
      {
        path: 'error/:errorType',
        element: <NoAccess />,
      },
      {
        path: 'disabled',
        element: <AccountDisabled />,
      },
      {
        path: 'verify/:requestId',
        element: <VerifyLoading />,
      },
      {
        path: 'forgotpw/:requestId',
        element: <ResetPassword />,
      },
      {
        path: 'welcome/:inviteId',
        element: <Welcome />,
      },
      {
        path: 'register',
        element: <Register />,
        async loader({ request }: LoaderFunctionArgs) {
          await requireUser(request)
          return new Response()
        },
      },
      {
        path: 'redirect/items/:id',
        element: <RedirectItem />,
        async loader({ request }: LoaderFunctionArgs) {
          await requirePermissions(request, hasPermission('postals.read'))
          return new Response()
        },
      },
      {
        path: 'redirect/users/:id',
        element: <RedirectUser />,
        async loader({ request }: LoaderFunctionArgs) {
          await requirePermissions(request, hasPermission('users.read'))
          return new Response()
        },
      },

      {
        path: 'graphiql',
        element: <GraphiQLApp />,
        async loader({ request }: LoaderFunctionArgs) {
          await requirePermissions(request, process.env.NODE_ENV !== 'production')
          return new Response()
        },
      },
      {
        path: 'authorization/:appName?',
        element: <Authorization />,
        async loader({ request }: LoaderFunctionArgs) {
          await requireUser(request)
          return new Response()
        },
      },
      {
        path: '*',
        element: <Main />,
        async loader({ request }: LoaderFunctionArgs) {
          await requireUser(request)
          return new Response()
        },
        children: [
          {
            index: true,
            element: <UiLoading />,
            async loader({ request }: LoaderFunctionArgs) {
              await requireUser(request)
              return redirect('/items')
            },
          },
          {
            path: 'contacts/owner/:ownerId',
            element: <Contacts />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('contacts.read'))
              return new Response()
            },
          },
          {
            path: 'contacts/list/:listId',
            element: <Contacts />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('contacts.read'))
              return new Response()
            },
          },
          {
            path: 'contacts/send/:approvedPostalId/*',
            element: <ContactPostalSend />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('contacts.read'))
              return new Response()
            },
          },
          {
            path: 'contacts/:contactId',
            element: <Contact />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('contacts.read'))
              return new Response()
            },
          },
          {
            path: 'contacts',
            element: <Contacts />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('contacts.read'))
              return new Response()
            },
          },
          {
            path: 'contacts/owner/me',
            element: <Contacts />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('contacts.read'))
              return new Response()
            },
          },
          {
            path: 'orders/drafts/:savedSendId/*',
            element: <Draft />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('campaigns.read'))
              return new Response()
            },
          },
          {
            path: 'orders',
            element: <Orders />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('campaigns.read'))
              return new Response()
            },
          },
          {
            path: 'orders/:ordersType',
            element: <Orders />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('campaigns.read'))
              return new Response()
            },
          },
          {
            path: 'orders/links/approvals',
            element: <Orders />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('campaigns.read'))
              return new Response()
            },
          },
          {
            path: 'orders/links/approvals/:orderId',
            element: <Order />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('campaigns.read'))
              return new Response()
            },
          },
          {
            path: 'orders/emails/:orderId',
            element: <Order />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('campaigns.read'))
              return new Response()
            },
          },
          {
            path: 'orders/links/:orderId',
            element: <Order />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('campaigns.read'))
              return new Response()
            },
          },
          {
            path: 'orders/direct/:orderId',
            element: <Order />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('campaigns.read'))
              return new Response()
            },
          },
          {
            path: 'orders/bulk/:orderId',
            element: <Order />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('campaigns.read'))
              return new Response()
            },
          },
          {
            path: 'orders/subscriptions/:orderId',
            element: <Order />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('campaigns.read'))
              return new Response()
            },
          },
          {
            path: 'orders/triggers/:orderId',
            element: <Order />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('campaigns.read'))
              return new Response()
            },
          },
          {
            path: 'orders/group/:campaignId',
            element: <Order />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('campaigns.read'))
              return new Response()
            },
          },
          {
            path: 'orders/retry/:orderId/*?',
            element: <RetryPostal />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.send'))
              return new Response()
            },
          },
          {
            path: 'orders/retry-group/:campaignId/*?',
            element: <CampaignRetry />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.send'))
              return new Response()
            },
          },
          {
            path: 'profile',
            element: <Profile />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('profile.read'))
              return new Response()
            },
          },
          {
            path: 'users/active',
            element: <Users />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('users.read'))
              return new Response()
            },
          },
          {
            path: 'users/disabled',
            element: <Users />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('users.read'))
              return new Response()
            },
          },
          {
            path: 'users/invitations',
            element: <Users />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('users.read'))
              return new Response()
            },
          },
          {
            path: 'users/:userId',
            element: <UserIndividual />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('users.read'))
              return new Response()
            },
          },
          {
            path: 'users',
            element: <Users />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('users.read'))
              return new Response()
            },
          },
          {
            path: 'teams',
            element: <Teams />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('teams.create'))
              return new Response()
            },
          },
          {
            path: 'teams/:teamId',
            element: <Team />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('teams.read'))
              return new Response()
            },
            children: [
              {
                path: 'members',
                element: <TeamUsersRoute />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('teams.update'))
                  return new Response()
                },
              },
              {
                path: 'invites',
                element: <UsersInviteTable />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('teams.update'))
                  return new Response()
                },
              },
              {
                path: 'settings',
                element: <AccountPageRoute />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('teams.update'))
                  return new Response()
                },
              },
              {
                path: '*',
                async loader() {
                  return redirect('members')
                },
              },
            ],
          },
          {
            path: 'account/branding',
            element: <Branding />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('account.update'))
              return new Response()
            },
          },
          {
            path: 'integrations',
            element: <Integrations />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('integrations.create'))
              return new Response()
            },
          },
          // this route doesn't exist because Slack is the only integration that doesn't have it's own page
          {
            path: 'integrations/slack',
            element: <Navigate to="/integrations" />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('integrations.create'))
              return new Response()
            },
          },
          {
            path: 'integrations/:systemName',
            element: <IntegrationEntry />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('integrations.create'))
              return new Response()
            },
          },

          {
            path: 'billing/accounts',
            element: <BillingAccounts />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('billing.update'))
              return new Response()
            },
          },
          {
            path: 'billing/accounts/new',
            element: <BillingAccounts />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('billing.update'))
              return new Response()
            },
          },
          {
            path: 'billing/accounts/:billingAccountId',
            element: <BillingAccount />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('billing.update'))
              return new Response()
            },
          },
          {
            path: 'billing/funds',
            element: <TeamBudgets />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('billing.update'))
              return new Response()
            },
          },
          {
            path: 'billing/transfers',
            element: <Transfers />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('billing.update') && hasFeature('transferFunds'))
              return new Response()
            },
          },
          {
            path: 'budget/history',
            element: <BudgetHistory />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasFeature('budgetHistory'))
              return new Response()
            },
          },
          {
            path: 'account/info',
            element: <AccountSettings />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('account.update'))
              return new Response()
            },
          },
          {
            path: 'saved-messages',
            element: <SavedMessages />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('messages.update'))
              return new Response()
            },
          },
          {
            path: 'notifications/general',
            element: <NotificationSettings />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('profile.read') && hasFeature('slackIntegration'))
              return new Response()
            },
          },
          {
            path: 'notifications/summary',
            element: <NotificationSettings />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('profile.read'))
              return new Response()
            },
          },
          // Notifications V1
          {
            path: 'account/notifications',
            element: <Notifications />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('profile.read'))
              return new Response()
            },
          },
          // Notifications V2

          {
            path: 'account/email-settings',
            element: <EmailSettings />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('profile.read'))
              return new Response()
            },
          },
          {
            path: 'account/personalized-emails',
            element: <GiftEmails />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('account.update'))
              return new Response()
            },
          },
          {
            path: 'account/email-templates',
            element: <EmailTemplates />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('account.update'))
              return new Response()
            },
          },

          {
            path: 'account/blocklist',
            element: <EmailBlockList />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('account.update'))
              return new Response()
            },
          },
          {
            path: 'profile/meetings',
            element: <MeetingSettings />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasFeature('meetingSettings'))
              return new Response()
            },
          },
          {
            path: 'account/security',
            element: <Security />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('security.read'))
              return new Response()
            },
          },
          {
            path: 'account/warehousing',
            element: <Warehousing />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('account.update'))
              return new Response()
            },
          },
          {
            path: 'paperplane',
            element: <PostalConcierge />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.read'))
              return new Response()
            },
          },
          {
            path: 'swag-creator/account/orders',
            element: <SwagOrders />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.read') && hasSwagEditor)
              return new Response()
            },
          },
          {
            //redirect from old URL
            path: 'swag-editor/account/orders',
            element: <Navigate to="/swag-creator/account/orders" />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.read') && hasSwagEditor)
              return new Response()
            },
          },
          {
            path: 'swag-creator',
            element: <SwagEditor />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.read') && hasSwagEditor)
              return new Response()
            },
          },
          {
            path: 'swag-editor',
            element: <Navigate to="/swag-creator" />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.read') && hasSwagEditor)
              return new Response()
            },
          },
          {
            path: 'items/marketplace/:marketplaceProductId',
            element: <MarketplaceProductPage />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.read'))
              return new Response()
            },
          },
          {
            path: 'items/postals/:approvedPostalId',
            element: <MarketplaceApprovedPostalPage />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.read'))
              return new Response()
            },
          },
          {
            path: 'items/postals/:approvedPostalId/edit',
            element: <MarketplaceApprovedPostalEdit />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.update'))
              return new Response()
            },
          },
          {
            path: 'items/postals/:approvedPostalId/send/*',
            element: <ApprovedPostalSend />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.send'))
              return new Response()
            },
          },
          {
            path: 'collections',
            element: <Collections />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('collections.read'))
              return new Response()
            },
          },
          {
            path: 'collections/create',
            element: <CollectionCreateUpdate />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('collections.create'))
              return new Response()
            },
          },
          {
            path: 'collections/:approvedPostalId',
            element: <Collection />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('collections.read'))
              return new Response()
            },
          },
          {
            path: 'collections/:approvedPostalId/send/*',
            element: <ApprovedPostalSend />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.send'))
              return new Response()
            },
          },
          {
            path: 'collections/:approvedPostalId/add',
            element: <CollectionAddRoute />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.send'))
              return new Response()
            },
          },
          {
            path: 'favorites',
            element: <ViewFavorites />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.read'))
              return new Response()
            },
          },
          {
            path: 'items',
            element: hasFeature('marketplaceV2') ? <MarketplacePage /> : <OldMarketplacePage />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.read'))
              return new Response()
            },
            children: [
              {
                path: 'marketplace',
                element: hasFeature('marketplaceV2') ? (
                  <Fade in>
                    <MarketplaceViewProducts />
                  </Fade>
                ) : (
                  <Fade in>
                    <OldMarketplaceViewProducts />
                  </Fade>
                ),
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('postals.marketplace'))
                  return new Response()
                },
              },
              {
                path: 'postals',
                element: hasFeature('marketplaceV2') ? (
                  <Fade in>
                    <MarketplaceViewProducts isApproved />
                  </Fade>
                ) : (
                  <Fade in>
                    <OldMarketplaceViewPostals />
                  </Fade>
                ),
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('postals.read'))
                  return new Response()
                },
              },
              {
                path: '*',
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(
                    request,
                    hasPermission('postals.marketplace') || hasPermission('postals.read')
                  )
                  if (!hasPermission('postals.marketplace')) throw redirect('postals')
                  return new Response()
                },
                element: hasFeature('marketplaceV2') ? (
                  <Fade in>
                    <MarketplaceViewHome />
                  </Fade>
                ) : (
                  <Fade in>
                    <OldMarketplaceViewHome />
                  </Fade>
                ),
              },
            ],
          },
          {
            path: 'events/postals/:approvedPostalId',
            element: <EventsApprovedPostalPage />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.read'))
              return new Response()
            },
          },
          {
            path: 'events/postals/:approvedPostalId/edit',
            element: <EventsApprovedPostalEdit />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.update'))
              return new Response()
            },
          },
          {
            path: 'events/postals/:approvedPostalId/send/*',
            element: <ApprovedPostalSend />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('postals.read'))
              return new Response()
            },
          },
          {
            path: 'events/marketplace/:marketplaceProductId',
            element: <EventsMarketplaceProductPage />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('events.marketplace'))
              return new Response()
            },
          },
          {
            path: 'events',
            element: <EventsPage />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('events.read') && hasFeature('events'))
              return new Response()
            },
            children: [
              {
                path: 'marketplace',
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('events.marketplace'))
                  return new Response()
                },
                element: (
                  <Fade in>
                    <EventsViewProducts />
                  </Fade>
                ),
              },
              {
                path: 'postals',
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('events.read'))
                  return new Response()
                },
                element: (
                  <Fade in>
                    <EventsViewPostals />
                  </Fade>
                ),
              },
              {
                path: '*',
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('events.marketplace') || hasPermission('events.read'))
                  const route = !hasPermission('events.marketplace') ? '/events/postals' : '/events/marketplace'
                  return redirect(route)
                },
              },
            ],
          },
          {
            path: 'reporting',
            element: <Reporting />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('reporting.read'))
              return new Response()
            },
            children: [
              {
                path: 'overview',
                element: <Overview />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('reporting.read') && hasFeature('reportingOverview'))
                  return new Response()
                },
              },
              {
                path: 'summary',
                element: <Summary />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('reporting.read') && hasFeature('reportingSummary'))
                  return new Response()
                },
              },
              {
                path: 'impact',
                element: <Impact />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('reporting.read') && hasFeature('reportingImpact'))
                  return new Response()
                },
              },
              {
                path: 'recent-activity',
                element: <ActivityStream />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(
                    request,
                    hasPermission('reporting.read') && hasFeature('reportingActivityStream')
                  )
                  return new Response()
                },
              },
              {
                path: 'orders',
                element: <OrderReport />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('reporting.read') && hasFeature('orderReport'))
                  return new Response()
                },
                children: [
                  {
                    path: ':postalFulfillmentId',
                    element: <PostalFulfillment />,
                    async loader({ request }: LoaderFunctionArgs) {
                      await requirePermissions(request, hasPermission('reporting.read'))
                      return new Response()
                    },
                  },
                ],
              },
              {
                path: 'exports',
                element: <SavedReports />,
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('reporting.read') && hasFeature('orderReport'))
                  return new Response()
                },
              },
              {
                path: '*',
                async loader({ request }: LoaderFunctionArgs) {
                  await requirePermissions(request, hasPermission('reporting.read'))
                  return redirect('overview')
                },
              },
            ],
          },
          {
            path: 'subscriptions/create/step/:playbookStepIdx/send/:approvedPostalId/*',
            element: <PlaybookDefinitionStepPostalSend />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('playbooks.read'))
              return new Response()
            },
          },
          {
            path: `subscriptions/:playbookId/step/:playbookStepIdx/send/:approvedPostalId/*`,
            element: <PlaybookDefinitionStepPostalSend />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('playbooks.read'))
              return new Response()
            },
          },
          {
            path: `subscriptions/:playbookId`,
            element: <PlaybookDefinitionPage />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('playbooks.read'))
              return new Response()
            },
          },
          {
            path: `subscriptions/:playbookId/edit`,
            element: <PlaybookDefinitionEdit />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('playbooks.update'))
              return new Response()
            },
          },
          {
            path: 'subscriptions',
            element: <PlaybookDefinitionsPage />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('playbooks.read'))
              return new Response()
            },
          },
          {
            path: 'subscriptions/create',
            element: <PlaybookDefinitionEdit />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('playbooks.update'))
              return new Response()
            },
          },
          {
            path: 'triggers/edit/*',
            element: <TriggerEdit />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(
                request,
                hasPermission('triggers.create') || (hasPermission('postals.send') && hasFeature('userZapier'))
              )
              return new Response()
            },
          },
          {
            path: `triggers/:triggerId`,
            element: <Trigger />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(
                request,
                hasPermission('triggers.create') || (hasPermission('postals.send') && hasFeature('userZapier'))
              )
              return new Response()
            },
          },
          {
            path: 'triggers',
            element: <Triggers />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(
                request,
                hasPermission('triggers.create') || (hasPermission('postals.send') && hasFeature('userZapier'))
              )
              return new Response()
            },
          },
          {
            path: `triggers/:triggerId/edit/*`,
            element: <TriggerEdit />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(
                request,
                hasPermission('triggers.create') || (hasPermission('postals.send') && hasFeature('userZapier'))
              )
              return new Response()
            },
          },
          {
            path: 'links',
            element: <Links />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('links.read') && hasFeature('links'))
              return new Response()
            },
          },
          {
            path: 'links/approval',
            element: <LinksBulkApproveOrders />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(
                request,
                hasPermission('links.read') && hasFeature('manuallyApproveLinks') && hasRole(Role.User)
              )
              return new Response()
            },
          },
          {
            path: 'links/approval/:magicLinkId',
            element: <LinksBulkApproveOrders />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(
                request,
                hasPermission('links.read') && hasFeature('manuallyApproveLinks') && hasRole(Role.User)
              )
              return new Response()
            },
          },
          {
            path: 'links/:linkId',
            element: <Link />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('links.read') && hasFeature('links'))
              return new Response()
            },
          },
          {
            path: 'links/:linkId/edit/*',
            element: <LinkEdit />,
            async loader({ request }: LoaderFunctionArgs) {
              await requirePermissions(request, hasPermission('links.read') && hasFeature('links'))
              return new Response()
            },
          },
          {
            path: '*',
            async loader() {
              throw new Response(null, { status: 404 })
            },
          },
        ],
      },
    ]
  }, [hasFeature, hasPermission, hasRole, hasSwagEditor])

  const router = createBrowserRouter([
    {
      element: <AppRoot />,
      errorElement: <RouteErrorElement />,
      children: routes,
    },
  ])

  return isLoading ? <UiLoading /> : <RouterProvider router={router} />
}
