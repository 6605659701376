import { Box, FormControl, HStack, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import {
  UiCheckboxIcon,
  UiToggle,
  useAlerts,
  ZCard,
  ZCardBody,
  ZCardHeader,
  ZCardTitle,
  ZConfirm,
  ZFormLabel,
  ZText,
} from '@postal-io/postal-ui'
import type { PlaybookDefinition } from 'api'
import { Status, UpdatePlaybookDefinitionDocument } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { useAcl } from 'hooks'
import React from 'react'

interface PlaybookDefinitionSettingsV2Props extends UiCardProps {
  playbook?: PlaybookDefinition
}
export const PlaybookDefinitionSettings: React.FC<PlaybookDefinitionSettingsV2Props> = ({ playbook, ...rest }) => {
  const Alert = useAlerts()
  const { hasPermission } = useAcl()
  const canUpdate = hasPermission('playbooks.update')

  const disablePlaybook = useDisclosure()

  // update playbook defintion
  const updatePlaybook = useGraphqlMutation(UpdatePlaybookDefinitionDocument)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) return disablePlaybook.onOpen()
    return handleUpdateStatus(Status.Active)
  }

  const handleUpdateStatus = async (status: Status) => {
    if (!playbook?.id) return
    try {
      await updatePlaybook.mutateAsync({ id: playbook?.id, data: { status } })
      disablePlaybook.onClose()
      Alert.success(`Subscription ${status === Status.Active ? 'enabled' : 'disabled'}`)
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <>
      <ZCard
        variant="dash"
        isLoading={!playbook}
        borderWidth="1px"
        borderColor="atomicGray.200"
        {...rest}
      >
        <ZCardHeader>
          <ZCardTitle>Settings</ZCardTitle>
        </ZCardHeader>
        <ZCardBody>
          {canUpdate ? (
            <FormControl
              mt={4}
              id="status"
            >
              <HStack spacing={3}>
                <UiToggle
                  colorScheme="atomicBlue"
                  isChecked={playbook?.status === Status.Active}
                  onChange={handleChange}
                />
                <ZFormLabel>
                  Enable Subscription{' '}
                  <ZInfoTooltip
                    label={`When a subscription is disabled, users are prevented from adding new contacts, but existing
           contacts will continue to receive items`}
                  />
                </ZFormLabel>
              </HStack>
            </FormControl>
          ) : (
            <Box mt={8}>
              <Text
                fontSize="lg"
                textAlign="center"
              >
                <UiCheckboxIcon
                  mr={2}
                  isChecked={playbook?.status === Status.Active}
                />
                {playbook?.status === Status.Active ? `Subscription Enabled` : `Subscription Disabled`}
              </Text>
            </Box>
          )}
        </ZCardBody>
      </ZCard>

      <ZConfirm
        title="Disable Subscription"
        isOpen={disablePlaybook.isOpen}
        onConfirm={() => handleUpdateStatus(Status.Disabled)}
        onClose={disablePlaybook.onClose}
        isLoading={updatePlaybook.isLoading}
        buttonText="Disable"
        buttonColor="atomicRed"
      >
        <ZText textAlign="justify"> Are you sure you want to disable this subscription?</ZText>
      </ZConfirm>
    </>
  )
}
