import { Box, Flex } from '@chakra-ui/react'
import { BackgroundProvider } from 'hooks'
import { Outlet } from 'react-router-dom'
import { ExtBackgroundTasks } from './ExtBackgroundTasks'

export const Extension = () => {
  return (
    <BackgroundProvider>
      <Flex
        direction="column"
        minH="100vh"
        alignItems="stretch"
        backgroundColor="app.bg"
      >
        <Box
          width="full"
          flexGrow={1}
          minW={0}
          p={0}
          bg="whites.400"
        >
          <Outlet />
        </Box>
      </Flex>
      <ExtBackgroundTasks />
    </BackgroundProvider>
  )
}
