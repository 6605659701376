import type { BoxProps } from '@chakra-ui/react'
import { Fade, Grid } from '@chakra-ui/react'
import { useGraphqlFetch } from '@postal-io/postal-graphql'
import { UiSelect } from '@postal-io/postal-ui'
import type { User } from 'api'
import { GetUserDocument, Role } from 'api'
import { ZAutoCompleteUser, ZFormLabel, ZInfoTooltip } from 'components/Common/ZComponents'
import { PostalSendMethod } from 'components/PostalSend/postalSendHelpers'
import type { PostalSendContext } from 'components/PostalSend/usePostalSend'
import { PostalSendType } from 'components/PostalSend/usePostalSend'
import { useAcl, useMe } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { MdArrowDropDown } from 'react-icons/md'
import { AnalyticsEventV2, useAnalyticsSend } from '../../hooks/useAnalytics'
import type { PostalCustomizeV2Props } from './PostalCustomize'
import { SendAsType } from './postalSendHelpers'
import { usePostalSendFieldErrors } from './usePostalSendFieldErrors'

const ROLES = [Role.User]

export const useCanSendAs = ({ context }: { context: PostalSendContext }) => {
  const { aclCheck } = useAcl()
  const canSendAs = useMemo(
    () =>
      [PostalSendMethod.Link, PostalSendMethod.Email].includes(context.method) &&
      aclCheck({ module: ['users.query', 'contacts.read'], feature: 'sendAs' }),
    [aclCheck, context.method]
  )
  return canSendAs
}

type PostalCustomizeSendAsV2Props = PostalCustomizeV2Props & BoxProps

export const PostalCustomizeSendAs: React.FC<PostalCustomizeSendAsV2Props> = ({ context, send }) => {
  const canSendAs = useCanSendAs({ context })
  const { me } = useMe()
  const getUser = useGraphqlFetch(GetUserDocument)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const sendAnalytics = useAnalyticsSend()
  const canSendAsSelf = context.type !== PostalSendType.Trigger
  const canSendAsContactOwner = useMemo(
    // disallow links to send as contact owner, unless currently editing a link with that option
    () => context.method !== PostalSendMethod.Link || context.link?.sendAsContactOwner,
    [context.link?.sendAsContactOwner, context.method]
  )

  const sendAsUserProps = usePostalSendFieldErrors({ context, field: 'sendAsUser' })

  // const sendAsUserInputRef = useRef<HTMLInputElement>(null)

  // we are only going to set the selectedUser on the initial page load
  // all other times will be handled by the autocomplete
  useEffect(() => {
    if (!canSendAs || !context.sendAsUser) return
    getUser({ id: context.sendAsUser }).then((data) => setSelectedUser(data.getUser))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const sendAsType = e.target.value
    if (sendAsType !== SendAsType.User) setSelectedUser(null)
    if (sendAsType === SendAsType.User) setTimeout(() => sendAsUserProps.ref.current?.focus())
    send({ type: 'SET_SEND_AS', data: { sendAsType } })
  }

  const handleSelectedUser = (user: User | null) => {
    sendAnalytics({ event: AnalyticsEventV2.SendFlow_SendAs_Selected, data: { userId: user?.id } })
    setSelectedUser(user)
    send({
      type: 'SET_SEND_AS',
      data: {
        sendAsType: context.sendAsType === SendAsType.Self ? SendAsType.User : context.sendAsType,
        sendAsUser: user?.id,
      },
    })
  }

  if (!canSendAs) return null

  return (
    <>
      <Grid
        templateColumns={{ base: '1fr', md: '120px 1fr 1fr' }}
        columnGap={8}
        rowGap={{ base: 5, md: 0 }}
      >
        <ZFormLabel
          alignItems="center"
          display="flex"
          whiteSpace="nowrap"
          variant="bold"
        >
          Send As
          <ZInfoTooltip
            ml={2}
            label="If sending as another user, the budget/balance will come from your account, but the from address and notifications will go to the specified user"
          />
        </ZFormLabel>
        <UiSelect
          cursor="pointer"
          fontFamily="Lexend"
          fontSize="sm"
          color="atomicGray.600"
          fontWeight={350}
          height="40px"
          name="sendAsType"
          value={context.sendAsType}
          onChange={handleType}
          iconColor="atomicGray.500"
          icon={<MdArrowDropDown fontSize="18px" />}
        >
          {canSendAsSelf && <option value={SendAsType.Self}>Myself</option>}
          {canSendAsContactOwner && <option value={SendAsType.ContactOwner}>Contact Owner</option>}
          <option value={SendAsType.User}>Specific User</option>
        </UiSelect>
        <Fade
          in={context.sendAsType !== SendAsType.ContactOwner}
          unmountOnExit
        >
          <ZAutoCompleteUser
            inputRef={sendAsUserProps.ref}
            animation={sendAsUserProps.animation}
            isDisabled={context.sendAsType === SendAsType.Self}
            inputId="contact-owner"
            value={context.sendAsType === SendAsType.Self ? (me as User) : selectedUser}
            onChange={handleSelectedUser}
            roles={ROLES}
            menuPlacement="top"
          />
        </Fade>
      </Grid>
    </>
  )
}
