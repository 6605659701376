import { Fade, Grid } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ProductAccess, Team, User } from 'api'
import { GetUserDocument, Role } from 'api'
import { ZAutoCompleteUser, ZFormLabel, ZInfoTooltip } from 'components/Common/ZComponents'
import { useAcl } from 'hooks'
import React from 'react'
import { BillingSpendAsTeam } from './BillingSpendAsTeam'

export const PRODUCT_ID = process.env.REACT_APP_PRODUCT_ID

export const validTeamFilter = (account: ProductAccess) =>
  account.product === PRODUCT_ID && account.roles?.includes(Role.User)

export interface BillingSpendAsProps {
  onChangeUser: (user?: User | null) => void
  onChangeTeam?: (team?: Team | null) => void
  teamId?: string | null
  userId?: string
}

export const BillingSpendAs: React.FC<BillingSpendAsProps> = ({ onChangeUser, onChangeTeam, teamId, userId }) => {
  const { hasRole } = useAcl()
  const isAdmin = hasRole(Role.Admin)
  const ROLES = [Role.User]
  const user = useGraphqlQuery(GetUserDocument, { id: userId as string }, { enabled: !!userId })
  return (
    <>
      <Grid
        templateColumns={isAdmin ? '110px 1fr 1fr' : '1fr 1fr'}
        columnGap={8}
      >
        <ZFormLabel
          display="flex"
          alignItems="center"
          height="100%"
        >
          Spend {isAdmin ? 'As' : 'From'}
          <ZInfoTooltip
            placement="right"
            label="This order will live on the budget of the team and user selected."
            ml={2}
          />
        </ZFormLabel>
        {isAdmin && (
          <ZAutoCompleteUser
            data-testid="AutoCompleteSpendAs"
            inputId="spendas-user"
            value={user?.data?.getUser}
            onChange={onChangeUser}
            menuPlacement="top"
            roles={ROLES}
          />
        )}

        <Fade
          in={!!user.isFetched}
          unmountOnExit
        >
          <BillingSpendAsTeam
            onChangeUser={onChangeUser}
            onChangeTeam={onChangeTeam}
            userId={userId}
            teamId={teamId}
          />
        </Fade>
      </Grid>
    </>
  )
}
