import { Box, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { Column, UiCardProps } from '@postal-io/postal-ui'
import {
  getPersistKey,
  internalProgressBarProps,
  internalTableProps,
  UiSSDataTable,
  useAlertError,
  useGraphqlFilter,
  ZButton,
  ZCard,
  ZCardBody,
  ZCheckbox,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
  ZModalOverlay,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, PostalFulfillment, SearchBudgetHistoryQueryVariables } from 'api'
import {
  SearchApprovedPostalsDocument,
  SearchBudgetHistoryDocument,
  SearchPostalFulfillmentsDocument,
  TransactionRelatedType,
} from 'api'
import { PostalFulfillmentCard } from 'components/Postal/PostalFulfillmentCard'
import { useSession } from 'hooks'
import { StorageKeys } from 'lib'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CATEGORY } from '../Postals'
import {
  BUDGET_HISTORY_COLUMNS,
  BUDGET_HISTORY_INITIAL_STATE,
  BUDGET_HISTORY_LIMIT,
  BUDGET_HISTORY_TRANSFORMS,
} from './BudgetHistoryData'
import { BudgetHistoryFilter } from './BudgetHistoryFilter'

const PAGE_SIZE = 10
interface BudgetHistoryTableProps extends UiCardProps {
  columns?: Column[]
}

export const BudgetHistoryTable: React.FC<BudgetHistoryTableProps> = ({ columns, ...rest }) => {
  const rowItemDisclosure = useDisclosure()
  const { session } = useSession()
  const navigate = useNavigate()
  const [postalFulfillment, setPostalFulfillment] = useState<PostalFulfillment>()

  const myColumns = useMemo(() => {
    return columns || BUDGET_HISTORY_COLUMNS
  }, [columns])

  const filteredColumns = useMemo(() => {
    return myColumns
  }, [myColumns])

  const staticVariables = useMemo(() => {
    return {
      filter: {
        accountId: { eq: session.accountId },

        // Explicitly set teamId to null if this session is not for a team,
        // otherwise records with teamId set will be included
        teamId: { eq: session.teamId ?? null },

        // Include records with matching userId or with no userId.
        // Records with no userId represent CHANGED & RESET records common to all users of that budget.
        userId: { in: [session.userId, null] },
      },
      limit: 100,
    }
  }, [session.accountId, session.teamId, session.userId])

  const graphqlFilter = useGraphqlFilter<SearchBudgetHistoryQueryVariables>({
    transforms: BUDGET_HISTORY_TRANSFORMS,
    initialState: BUDGET_HISTORY_INITIAL_STATE,
    staticVariables,
    persistKey: getPersistKey(StorageKeys.BudgetHistory, session.accountId, session.teamId, session.userId),
  })

  const searchBudgetHistory = useGraphqlInfiniteQuery(SearchBudgetHistoryDocument, graphqlFilter.variables, {
    enabled: !!session?.accountId && !!session?.userId,
  })
  const { isFetching, error, hasNextPage, fetchNextPage } = useMemo(() => searchBudgetHistory, [searchBudgetHistory])

  const { budgetHistoryRecords, totalRecords } = useMemo(() => {
    return {
      budgetHistoryRecords: searchBudgetHistory?.mergedData?.searchBudgetHistory?.data ?? [],
      totalRecords: searchBudgetHistory?.mergedData?.searchBudgetHistory?.resultsSummary?.totalRecords,
    }
  }, [
    searchBudgetHistory?.mergedData?.searchBudgetHistory?.data,
    searchBudgetHistory?.mergedData?.searchBudgetHistory?.resultsSummary?.totalRecords,
  ])

  // Get distinct array of fulfillment IDs from BudgetHistory records then query fulfillments
  const fulfillmentIds = [
    ...new Set(
      budgetHistoryRecords
        ?.filter((r) => r && r.relatedType === TransactionRelatedType.PostalFulfillment)
        ?.map((r) => r?.relatedId) ?? []
    ),
  ] as string[]

  const getFulfillmentsQuery = useGraphqlQuery(
    SearchPostalFulfillmentsDocument,
    {
      filter: { id: { in: [...fulfillmentIds] } },
      limit: BUDGET_HISTORY_LIMIT,
    },
    { enabled: fulfillmentIds?.length > 0 }
  )
  const { fulfillmentMap } = useMemo(() => {
    return {
      fulfillmentRecords: getFulfillmentsQuery.data?.searchPostalFulfillments ?? [],
      fulfillmentMap: getFulfillmentsQuery.data?.searchPostalFulfillments?.reduce((obj, cur) => {
        if (cur && cur.id) {
          obj[cur.id?.toString()] = cur
        }
        return obj
      }, {} as any),
    }
  }, [getFulfillmentsQuery.data?.searchPostalFulfillments])

  const approvedPostalIds = [
    ...new Set(
      budgetHistoryRecords
        ?.filter((r) => r && r.relatedType === TransactionRelatedType.ApprovedPostal)
        ?.map((r) => r?.relatedId) ?? []
    ),
  ] as string[]

  const getApprovedPostalsQuery = useGraphqlQuery(
    SearchApprovedPostalsDocument,
    {
      filter: { id: { in: [...approvedPostalIds] } },
      limit: BUDGET_HISTORY_LIMIT,
    },
    { enabled: approvedPostalIds?.length > 0 }
  )
  useAlertError(getApprovedPostalsQuery.error)
  const approvedPostalMap = useMemo(() => {
    return getApprovedPostalsQuery.data?.searchApprovedPostals?.reduce((obj, cur) => {
      if (cur && cur.id) {
        obj[cur.id.toString()] = cur
      }
      return obj
    }, {} as any)
  }, [getApprovedPostalsQuery.data?.searchApprovedPostals])

  const handleFulfillmentClick = useCallback(
    (row: PostalFulfillment) => {
      setPostalFulfillment(row)
      rowItemDisclosure.onOpen()
    },
    [rowItemDisclosure]
  )

  const handleApprovedPostalClick = useCallback(
    (postal: ApprovedPostal) => {
      if (postal.category !== CATEGORY.Events) {
        navigate(`/items/postals/${postal.id}`)
      } else {
        navigate(`/events/postals/${postal.id}`)
      }
    },
    [navigate]
  )

  const normalizedRows = useMemo(() => {
    return (
      budgetHistoryRecords?.map((record) => {
        const id = record?.relatedId?.toString() || ''
        switch (record.relatedType) {
          case TransactionRelatedType.ApprovedPostal:
            const approvedPostal = approvedPostalMap?.[id]
            return {
              ...record,
              onItemClick: () => handleApprovedPostalClick(approvedPostal),
              itemName: approvedPostal?.name,
            }
          case TransactionRelatedType.PostalFulfillment:
            const fulfillment = fulfillmentMap?.[id]
            return {
              ...record,
              onItemClick: () => handleFulfillmentClick(fulfillment),
              itemName: fulfillment?.itemName,
              shipToName: fulfillment?.shipToName,
              contactId: fulfillment?.contactId,
            }
          default:
            return record
        }
      }) ?? []
    )
  }, [handleFulfillmentClick, handleApprovedPostalClick, budgetHistoryRecords, approvedPostalMap, fulfillmentMap])

  useAlertError(error)

  return (
    <>
      <Box {...rest}>
        <BudgetHistoryFilter
          filter={graphqlFilter.filter}
          setFilter={graphqlFilter.setFilter as any}
          isLoading={searchBudgetHistory.isFetching}
        />
        <ZCard
          variant="form"
          mt={4}
        >
          <ZCardBody p={8}>
            <UiSSDataTable
              columns={filteredColumns}
              rows={normalizedRows}
              rowKey="id"
              pageSize={PAGE_SIZE}
              hasMore={hasNextPage}
              isLoading={isFetching}
              fetchMore={fetchNextPage}
              filter={graphqlFilter.variables.filter}
              orderBy={graphqlFilter.orderBy}
              onOrderBy={graphqlFilter.setOrderBy}
              totalRecords={totalRecords}
              SelectCheckbox={ZCheckbox}
              HeaderButton={ZButton}
              tableProps={internalTableProps}
              progressBarProps={internalProgressBarProps}
              variant="list"
            />
          </ZCardBody>
        </ZCard>
      </Box>

      {postalFulfillment && rowItemDisclosure.isOpen && (
        <ZModal
          size="6xl"
          {...rowItemDisclosure}
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>View Order</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <PostalFulfillmentCard
                postalFulfillment={postalFulfillment}
                onRetryComplete={rowItemDisclosure.onClose}
                mb={0}
              />
            </ZModalBody>
            <ZModalFooter justifyContent="flex-end">
              <ZButton
                variant="ghost"
                colorScheme="atomicGray"
                onClick={rowItemDisclosure.onClose}
                minW="140px"
              >
                Close
              </ZButton>
            </ZModalFooter>
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}
