import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  useAlerts,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import type { SearchableContact, SearchableContactFilterInput } from 'api'
import { BulkContactDeleteDocument } from 'api'
import { CONTACT_INVALIDATIONS, useBackgroundQueue } from 'hooks'
import React from 'react'

interface ContactsDeleteV2Props {
  isOpen: boolean
  onClose: () => void
  onComplete: () => void
  contacts: SearchableContact[]
  filters: SearchableContactFilterInput[]
  count: number
}

export const ContactsDelete: React.FC<ContactsDeleteV2Props> = ({ isOpen, onClose, onComplete, count, filters }) => {
  const Alert = useAlerts()

  const { queue, invalidate } = useBackgroundQueue()
  const deleteContacts = useGraphqlMutation(BulkContactDeleteDocument, {
    onSuccess: (data) => {
      queue(data.bulkContactDelete)
      invalidate(CONTACT_INVALIDATIONS)
    },
  })

  const onConfirm = async () => {
    try {
      await deleteContacts.mutateAsync({ orfilters: filters })
      Alert.warning('Contacts removed')
    } catch (err) {
      Alert.error('Error removing contacts')
    } finally {
      onComplete()
      onClose()
    }
  }

  return (
    <ZModal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ZModalOverlay />
      <ZModalContent>
        <ZModalHeader>Delete Contact{count === 1 ? '' : 's'}</ZModalHeader>
        <ZModalCloseButton />
        <ZModalBody>
          <ZText>
            Are you sure you want to <strong>Delete</strong> {count === 1 ? 'this' : 'these'} {count}{' '}
            {count > 1 ? 'contacts' : 'contact'}?
          </ZText>
        </ZModalBody>
        <ZModalButtons
          confirmText="Remove"
          confirmProps={{
            colorScheme: 'atomicRed',
          }}
          onConfirm={onConfirm}
          onClose={onClose}
        />
      </ZModalContent>
    </ZModal>
  )
}
