import type { BoxProps } from '@chakra-ui/react'
import { Avatar, Box, Flex, SkeletonCircle, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiMenuItemProps } from '@postal-io/postal-ui'
import { UiMenu, UiMenuButton, UiMenuDivider, UiMenuItem, UiMenuList, useColor, ZText } from '@postal-io/postal-ui'
import { MeDocument, Role } from 'api'
import { authLogoutChannel } from 'components/Extension/broadcast'
import { PostalBudgetDisplay } from 'components/Main/Navbar'
import {
  AnalyticsEventV2,
  sendParentMessage,
  useAcl,
  useAnalyticsSend,
  useBranding,
  useExtension,
  useSession,
} from 'hooks'
import isEmpty from 'lodash/isEmpty'
import { useMemo } from 'react'
import {
  MdClose,
  MdLogin,
  MdOutlineFavoriteBorder,
  MdOutlineLink,
  MdOutlinePhonelink,
  MdOutlineSettings,
  MdOutlineStorefront,
  MdOutlineSupervisorAccount,
} from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ExtAccountPicker } from './ExtAccountPicker'

const ZMenuItem: React.FC<UiMenuItemProps> = ({ children, ...rest }) => {
  return (
    <UiMenuItem
      _hover={{ bg: 'atomicGray.50' }}
      {...rest}
    >
      {children}
    </UiMenuItem>
  )
}

interface ExtHeaderProps extends BoxProps {
  onSaveDraft?: () => void
  hasDraft?: boolean
}
export const ExtHeader: React.FC<ExtHeaderProps> = ({ children, onSaveDraft, hasDraft, ...rest }) => {
  const pickAccount = useDisclosure()
  const { Color } = useColor()
  const navigate = useNavigate()
  const branding = useBranding()
  const { hasRole } = useAcl()
  const isUser = hasRole(Role.User)
  const isManager = hasRole(Role.Manager)
  const isAdmin = hasRole(Role.Admin)
  const { removeSession } = useSession()
  const sendAnalytics = useAnalyticsSend()
  const { contactId } = useExtension()

  const logout = () => {
    sendAnalytics({ event: AnalyticsEventV2.ExtensionLogout })
    removeSession()
    navigate('/extension/')
    authLogoutChannel.postMessage('')
  }

  const { data: meData, isLoading } = useGraphqlQuery(MeDocument)
  const me = meData?.me
  const accounts = (me?.productAccess || []).filter(({ roles }) => !isEmpty(roles))
  const showAccountPicker = accounts.length > 1

  const myFullName = useMemo(() => `${me?.firstName} ${me?.lastName}`, [me?.firstName, me?.lastName])

  const rolesText = useMemo(
    () => (isAdmin ? 'Admin' : isManager ? 'Manager' : isUser ? 'User' : null),
    [isAdmin, isManager, isUser]
  )

  const closeWindow = () => sendParentMessage({ event: 'VISIBILITY_CLOSE' })

  return (
    <>
      <Flex
        alignItems="center"
        flexDir="column"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          backgroundColor="WhiteAlpha.50"
          {...rest}
        >
          {children}
          <Box
            display="flex"
            alignItems="center"
          >
            <PostalBudgetDisplay />
            <UiMenu>
              <UiMenuButton
                boxSize="30px"
                display="block"
                minWidth="30px"
                colorScheme="WhiteAlpha.50"
                border={0}
                padding={0}
                rightIcon={<></>}
              >
                <SkeletonCircle isLoaded={!isLoading}>
                  <Avatar
                    color="white"
                    bg={branding.primaryColor || 'atomicGray.900'}
                    size="sm"
                    src={me?.profilePicture ?? undefined}
                    name={myFullName}
                  />
                </SkeletonCircle>
              </UiMenuButton>
              <UiMenuList zIndex={99}>
                <ZMenuItem>
                  <Flex
                    justify="space-between"
                    w="100%"
                    flexWrap="wrap"
                    gap={1}
                  >
                    <ZText color="atomicGray.600">
                      {me?.firstName} {me?.lastName}
                    </ZText>
                    {rolesText && <ZText color="atomicBlue.500">({rolesText})</ZText>}
                  </Flex>
                </ZMenuItem>
                <UiMenuDivider />
                {contactId && (
                  <ZMenuItem onClick={() => navigate('/extension/marketplace')}>
                    <MdOutlineStorefront
                      size="18px"
                      color={Color('atomicGray.600')}
                    />
                    <ZText
                      color="atomicGray.600"
                      ml={3}
                    >
                      Marketplace
                    </ZText>
                  </ZMenuItem>
                )}
                <ZMenuItem onClick={() => navigate('/extension/magiclinks')}>
                  <MdOutlineLink
                    size="18px"
                    color={Color('atomicGray.600')}
                  />
                  <ZText
                    color="atomicGray.600"
                    ml={3}
                  >
                    MagicLinks
                  </ZText>
                </ZMenuItem>
                {contactId && (
                  <ZMenuItem onClick={() => navigate('/extension/favorites')}>
                    <MdOutlineFavoriteBorder
                      size="18px"
                      color={Color('atomicGray.600')}
                    />
                    <ZText
                      color="atomicGray.600"
                      ml={3}
                    >
                      Favorites
                    </ZText>
                  </ZMenuItem>
                )}
                {/* HIDE FOR MORE TESTING */}
                {/* <ZMenuItem onClick={() => navigate('/extension/drafts')}>
                  <MdOutlineDrafts
                    width="18px"
                    height="16px"
                    color={Color('atomicGray.600')}
                  />
                  <ZText
                    color="atomicGray.600"
                    ml={3}
                  >
                    Drafts
                  </ZText>
                </ZMenuItem> */}
                {showAccountPicker && (
                  <ZMenuItem onClick={() => pickAccount.onOpen()}>
                    <MdOutlineSupervisorAccount
                      size="18px"
                      color={Color('atomicGray.600')}
                    />
                    <ZText
                      color="atomicGray.600"
                      ml={3}
                    >
                      Switch Accounts
                    </ZText>
                  </ZMenuItem>
                )}
                <ZMenuItem onClick={closeWindow}>
                  <MdClose
                    size="18px"
                    color={Color('atomicGray.600')}
                  />
                  <ZText
                    color="atomicGray.600"
                    ml={3}
                  >
                    Close Extension
                  </ZText>
                </ZMenuItem>
                {/* HIDE FOR MORE TESTING */}
                {/*
                <UiMenuDivider />
                <ZMenuItem
                  onClick={onSaveDraft}
                  isDisabled={!onSaveDraft}
                >
                  {hasDraft ? (
                    <MdOutlineSaveAlt
                      size="18px"
                      color={Color('atomicGray.600')}
                    />
                  ) : (
                    <MdCropFree
                      size="18px"
                      color={Color('atomicGray.600')}
                    />
                  )}
                  <ZText
                    color="atomicGray.600"
                    ml={3}
                  >
                    {hasDraft ? 'Update Draft' : 'Save as Draft'}
                  </ZText>
                  </ZMenuItem>
                  */}
                <UiMenuDivider />
                <ZMenuItem onClick={() => window.open('https://app.postal.io')}>
                  <MdOutlinePhonelink
                    size="18px"
                    color={Color('atomicGray.600')}
                  />

                  <ZText
                    color="atomicGray.600"
                    ml={3}
                  >
                    Open Postal Platform
                  </ZText>
                </ZMenuItem>
                <ZMenuItem onClick={() => window.open('https://app.postal.io/profile')}>
                  <MdOutlineSettings
                    size="18px"
                    color={Color('atomicGray.600')}
                  />
                  <ZText
                    color="atomicGray.600"
                    ml={3}
                  >
                    Settings
                  </ZText>
                </ZMenuItem>
                <UiMenuDivider />
                <ZMenuItem onClick={logout}>
                  <MdLogin
                    size="18px"
                    color={Color('atomicRed.400')}
                  />
                  <ZText
                    color="atomicRed.400"
                    ml={3}
                  >
                    Logout
                  </ZText>
                </ZMenuItem>
              </UiMenuList>
            </UiMenu>
          </Box>
        </Flex>
      </Flex>
      {showAccountPicker && (
        <ExtAccountPicker
          isOpen={pickAccount.isOpen}
          onClose={pickAccount.onClose}
        />
      )}
    </>
  )
}
