import type { ModalProps } from '@chakra-ui/react'
import { ButtonGroup, Divider, Flex, HStack, Image, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { Column, OrderByProps } from '@postal-io/postal-ui'
import {
  internalProgressBarProps,
  internalTableProps,
  UiSSDataTable,
  UiTruncate,
  useAlerts,
  ZButton,
  ZCard,
  ZCardBody,
  ZCheckbox,
  ZConfirm,
  ZHeading,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
  ZModalOverlay,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import type { PostalFulfillment } from 'api'
import {
  BackgroundTaskStatus,
  BulkApproveOrdersDocument,
  BulkDenyOrdersDocument,
  FulfillmentStatus,
  GetPostalFulfillmentDocument,
  SearchPostalFulfillmentsDocument,
} from 'api'
import { useAssets, useBackgroundQueue } from 'hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

const LIMIT = 100
const PAGE_SIZE = 10
const DEFAULT_SORT = { key: 'created', direction: 'desc' } as OrderByProps

type LinksApproveOrdersModalV2Props = Partial<ModalProps> & {
  magicLinkIds: string[]
}
export const LinksApproveOrdersModal: React.FC<LinksApproveOrdersModalV2Props> = ({
  isOpen,
  onClose,
  magicLinkIds,
}) => {
  const Alert = useAlerts()
  const [selectedOrders, setSelectedOrders] = useState<PostalFulfillment[]>([])
  const handleSelectOrder = (orders: PostalFulfillment[]) => setSelectedOrders(orders)
  const { queue, tasks } = useBackgroundQueue()
  const confirmApprove = useDisclosure()
  const confirmReject = useDisclosure()

  const tasksRunning = useMemo(
    () =>
      tasks.length >= 1 &&
      tasks[0].task?.status === BackgroundTaskStatus.Pending &&
      (tasks[0].task?.name === 'Bulk Approve Orders' || tasks[0].task?.name === 'Bulk Deny Orders'),
    [tasks]
  ) as boolean

  const approveOrders = useGraphqlMutation(BulkApproveOrdersDocument, {
    onSuccess: (data) => {
      queue(data.bulkApproveOrders, () => {
        setSelectedOrders([])
        Alert.success('Orders approved')
      })
    },
  })
  const denyOrders = useGraphqlMutation(BulkDenyOrdersDocument, {
    onSuccess: (data) => {
      queue(data.bulkDenyOrders, () => {
        setSelectedOrders([])
        Alert.info('Orders rejected')
      })
    },
  })

  const handleApproveOrders = useCallback(() => {
    approveOrders
      .mutateAsync({ fulfillmentIds: selectedOrders.map((order) => order.id) })
      .catch((err) => Alert.error(err))
    confirmApprove.onClose()
  }, [Alert, approveOrders, selectedOrders, confirmApprove])

  const handleDenyOrders = useCallback(() => {
    denyOrders.mutateAsync({ fulfillmentIds: selectedOrders.map((order) => order.id) }).catch((err) => Alert.error(err))
    confirmReject.onClose()
  }, [Alert, denyOrders, selectedOrders, confirmReject])

  const [orderBy, setOrderBy] = useState(DEFAULT_SORT)

  const orderByString = useMemo(() => {
    return orderBy?.key && orderBy.direction ? `${orderBy.key}_${orderBy.direction.toUpperCase()}` : undefined
  }, [orderBy.direction, orderBy.key])

  const { mergedData, hasNextPage, isFetching, fetchNextPage } = useGraphqlInfiniteQuery(
    SearchPostalFulfillmentsDocument,
    {
      filter: { magicLinkId: { in: magicLinkIds }, status: { eq: FulfillmentStatus.PendingUserApproval } },
      limit: LIMIT,
      orderBy: orderByString as any,
    }
  )
  const postalFulfillments = useMemo(
    () => mergedData?.searchPostalFulfillments ?? [],
    [mergedData?.searchPostalFulfillments]
  )

  useEffect(() => {
    if (postalFulfillments.length === 0) onClose?.()
  }, [postalFulfillments.length, onClose])

  const isLoading = tasksRunning || approveOrders.isLoading || denyOrders.isLoading

  return (
    <>
      <ZModal
        isOpen={isOpen!}
        onClose={onClose!}
        scrollBehavior="inside"
        size="6xl"
      >
        <ZModalOverlay />
        <ZModalContent>
          <ZModalCloseButton />
          <ZModalHeader>Approve / Deny MagicLink Orders</ZModalHeader>
          <ZModalBody>
            <ZCard variant="form">
              <ZCardBody p={8}>
                <UiSSDataTable
                  variant="list"
                  columns={DEFAULT_COLUMNS}
                  rows={postalFulfillments}
                  hasMore={hasNextPage}
                  isLoading={isFetching}
                  fetchMore={fetchNextPage}
                  orderBy={orderBy}
                  onOrderBy={setOrderBy}
                  pageSize={PAGE_SIZE}
                  showSelect
                  onSelect={handleSelectOrder}
                  rowKey="id"
                  tableProps={internalTableProps}
                  progressBarProps={internalProgressBarProps}
                  HeaderButton={ZButton}
                  SelectCheckbox={ZCheckbox}
                />
              </ZCardBody>
            </ZCard>
          </ZModalBody>
          <ZModalFooter mt={0}>
            <ButtonGroup
              w="100%"
              justifyContent="space-between"
            >
              <HStack spacing={2}>
                <ZButton
                  colorScheme="atomicBlue"
                  onClick={confirmApprove.onOpen}
                  isLoading={isLoading}
                  isDisabled={selectedOrders.length === 0 || isLoading}
                >
                  Approve Orders
                </ZButton>
                <ZButton
                  variant="ghost"
                  color="atomicGray.900"
                  colorScheme="atomicGray"
                  onClick={confirmReject.onOpen}
                  isDisabled={selectedOrders.length === 0 || isLoading}
                >
                  Reject Orders
                </ZButton>
              </HStack>
              <ZButton
                colorScheme="atomicGray"
                variant="ghost"
                onClick={onClose}
                minW="150px"
              >
                Close
              </ZButton>
            </ButtonGroup>
          </ZModalFooter>
        </ZModalContent>
      </ZModal>
      <ZConfirm
        title="Approve Orders"
        isLoading={isLoading}
        isDisabled={selectedOrders.length === 0 || isLoading}
        {...confirmApprove}
        onConfirm={handleApproveOrders}
      >
        Are you sure you would like to approve {selectedOrders.length === 1 ? 'this' : selectedOrders.length} order
        {selectedOrders.length === 1 ? '' : 's'}?
      </ZConfirm>
      <ZConfirm
        title="Reject Orders"
        isLoading={isLoading}
        isDisabled={selectedOrders.length === 0 || isLoading}
        buttonColor="atomicRed"
        buttonText="Reject"
        {...confirmReject}
        onConfirm={handleDenyOrders}
      >
        Are you sure you would like to reject {selectedOrders.length === 1 ? 'this' : selectedOrders.length} order
        {selectedOrders.length === 1 ? '' : 's'}?
      </ZConfirm>
    </>
  )
}

const DEFAULT_COLUMNS: Column[] = [
  {
    key: 'itemName',
    label: 'Item',
    render: ({ itemName, id }: PostalFulfillment) => (
      <ZLink
        as={RouterLink}
        to={{ pathname: `/links/${id}` }}
        isExternal
      >
        <UiTruncate
          length={30}
          text={itemName}
          showTooltip
        ></UiTruncate>
      </ZLink>
    ),
  },
  {
    key: 'contactId',
    label: 'Contact',
    render: ({ contactId, shipToName }: PostalFulfillment) => (
      <ZLink
        as={RouterLink}
        to={`/contacts/${contactId}`}
        isExternal
      >
        {shipToName}
      </ZLink>
    ),
  },
  {
    key: 'magicLinkName',
    label: 'Link Name',
    render: ({ magicLinkId, magicLinkName }: PostalFulfillment) => (
      <ZLink
        as={RouterLink}
        to={`/links/${magicLinkId}`}
        isExternal
      >
        {magicLinkName}
      </ZLink>
    ),
  },
  {
    key: 'shipToEmail',
    label: 'Email',
    render: ({ shipToEmail }: PostalFulfillment) => <ZText>{shipToEmail}</ZText>,
  },
  {
    key: 'cost.total',
    label: 'Cost',
    render: ({ cost }: PostalFulfillment) => {
      return (
        <ZMoney
          cents={cost?.total || 0}
          currency={cost?.currency}
        />
      )
    },
  },
]

interface LinksApproveSingleOrderModalV2Props {
  fulfillmentId?: string
  message?: string
  isOpen: boolean
  onClose: () => void
}
export const LinksApproveSingleOrderModalV2: React.FC<LinksApproveSingleOrderModalV2Props> = ({
  fulfillmentId,
  message,
  ...rest
}) => {
  const { assetUrlSrc } = useAssets()
  const Alert = useAlerts()
  const { queue, tasks } = useBackgroundQueue()

  const tasksRunning = useMemo(
    () =>
      tasks.length >= 1 &&
      tasks[0].task?.status === BackgroundTaskStatus.Pending &&
      (tasks[0].task?.name === 'Bulk Approve Orders' || tasks[0].task?.name === 'Bulk Deny Orders'),
    [tasks]
  ) as boolean

  const approveOrders = useGraphqlMutation(BulkApproveOrdersDocument, {
    onSuccess: (data) => {
      queue(data.bulkApproveOrders, () => {
        Alert.success('Order approved')
        rest.onClose()
      })
    },
  })
  const denyOrders = useGraphqlMutation(BulkDenyOrdersDocument, {
    onSuccess: (data) => {
      queue(data.bulkDenyOrders, () => {
        Alert.info('Order rejected')
        rest.onClose()
      })
    },
  })

  const getPostalFulfillment = useGraphqlQuery(
    GetPostalFulfillmentDocument,
    { id: fulfillmentId as string },
    { enabled: !!fulfillmentId }
  )

  const postalFulfillment = getPostalFulfillment.data?.getPostalFulfillment

  const handleApproveOrder = async () => {
    try {
      const id = postalFulfillment?.id
      if (!id) throw Error('Invalid order ID')
      await approveOrders.mutateAsync({ fulfillmentIds: [id] })
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleDenyOrder = async () => {
    try {
      const id = postalFulfillment?.id
      if (!id) throw Error('Invalid order ID')
      await denyOrders.mutateAsync({ fulfillmentIds: [id] })
    } catch (err) {
      Alert.error(err)
    }
  }

  const { src, fallbackSrc } = assetUrlSrc(postalFulfillment?.previewImage, {
    fit: 'crop',
    crop: 'edges',
    w: 48,
    h: 48,
  })

  if (!postalFulfillment) return null

  const isLoading = tasksRunning || approveOrders.isLoading || denyOrders.isLoading

  return (
    <ZModal
      scrollBehavior="inside"
      size="3xl"
      {...rest}
    >
      <ZModalOverlay />
      <ZModalContent>
        <ZModalCloseButton />
        <ZModalHeader>{postalFulfillment?.itemName}</ZModalHeader>
        <ZModalBody>
          <ZHeading size="h6">Contact</ZHeading>
          <HStack
            spacing={8}
            mt={4}
          >
            <ZText color="atomicGray.500">{postalFulfillment?.shipToName}</ZText>
            <ZText color="atomicBlue.500">{postalFulfillment?.shipToEmail}</ZText>
            <ZText color="atomicBlue.500">{postalFulfillment?.shipToCompanyName}</ZText>
            <ZText color="atomicGray.500">{postalFulfillment?.shipToTitle}</ZText>
          </HStack>
          <Divider my={4} />
          <ZHeading size="h6">Product</ZHeading>
          <HStack
            spacing={4}
            mt={4}
          >
            <Image
              src={src}
              fallbackSrc={fallbackSrc}
              maxW="48px"
              borderRadius="sm"
            />
            <Flex
              h="100%"
              alignItems="stretch"
              flexDir="column"
            >
              <ZText color="atomicGray.500">{postalFulfillment?.itemName}</ZText>
              <ZMoney
                fontWeight="bold"
                cents={postalFulfillment?.cost?.total}
              />
            </Flex>
          </HStack>
          {message && (
            <>
              <Divider my={4} />
              <ZHeading size="h6">Message</ZHeading>
              <ZText
                color="atomicGray.500"
                mt={4}
              >
                {message}
              </ZText>
            </>
          )}
        </ZModalBody>
        <ZModalFooter>
          <ButtonGroup
            w="100%"
            justifyContent="space-between"
          >
            <HStack spacing={2}>
              <ZButton
                colorScheme="atomicBlue"
                onClick={handleApproveOrder}
                isLoading={isLoading}
                isDisabled={isLoading}
              >
                Approve Order
              </ZButton>
              <ZButton
                variant="ghost"
                color="atomicGray.900"
                colorScheme="atomicGray"
                onClick={handleDenyOrder}
                isDisabled={isLoading}
              >
                Reject Order
              </ZButton>
            </HStack>
            <ZButton
              colorScheme="atomicGray"
              variant="ghost"
              onClick={rest.onClose}
              minW="150px"
            >
              Cancel
            </ZButton>
          </ButtonGroup>
        </ZModalFooter>
      </ZModalContent>
    </ZModal>
  )
}
