import type { CampaignCloneType } from 'components/Orders/CampaignRetry'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

interface UseNavigateRetryOrderOptions {
  orderId: string
  returnTo: string
  returnPath?: string
}
export function useNavigateRetryOrder() {
  const location = useLocation()

  return useCallback(
    (options: UseNavigateRetryOrderOptions) => {
      const search = new URLSearchParams()
      search.set('returnTo', options.returnTo)
      search.set('returnPath', options.returnPath ?? location.pathname)
      return `/orders/retry/${options.orderId}?${search}`
    },
    [location.pathname]
  )
}

export function useNavigateRetryOrderParams() {
  const params = useParams()
  const [search, setSearch] = useSearchParams()

  const [state] = useState(() => {
    const returnTo = search.get('returnTo') as string
    const returnPath = search.get('returnPath') as string
    return { orderId: params.orderId as string, returnTo, returnPath }
  })

  useEffect(() => {
    setSearch({})
  }, [setSearch])

  return state
}

interface UseNavigateRetryCampaignOptions {
  campaignId: string
  cloneType: CampaignCloneType
  returnTo: string
  returnPath?: string
}
export function useNavigateRetryCampaign() {
  const location = useLocation()

  return useCallback(
    (options: UseNavigateRetryCampaignOptions) => {
      const search = new URLSearchParams()
      search.set('returnTo', options.returnTo)
      search.set('returnPath', options.returnPath ?? location.pathname)
      search.set('cloneType', options.cloneType)
      return `/orders/retry-group/${options.campaignId}?${search}`
    },
    [location.pathname]
  )
}

export function useNavigateRetryCampaignParams() {
  const params = useParams()
  const [search, setSearch] = useSearchParams()

  const [state] = useState(() => {
    const returnTo = search.get('returnTo') as string
    const returnPath = search.get('returnPath') as string
    const cloneType = search.get('cloneType') as CampaignCloneType
    return { campaignId: params.campaignId as string, returnTo, returnPath, cloneType }
  })

  useEffect(() => {
    setSearch({})
  }, [setSearch])

  return state
}
