import { Box } from '@chakra-ui/react'
import { useLocalStorage } from '@postal-io/postal-ui'
import qs from 'query-string'
import type { PropsWithChildren } from 'react'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMeasure } from 'react-use'
import { useDebouncedCallback } from 'use-debounce'
import { parseContactUrl, StorageKeys } from '../lib'
import type { UseAnalyticsEventProps } from './useAnalytics'
import { AnalyticsEvent, useAnalyticsSend } from './useAnalytics'

declare global {
  interface Window {
    chrome: any
  }
}

interface Message {
  event: string
  refreshToken?: string
  id?: string
  url?: string
}

const PRODUCT_ID = process.env.REACT_APP_PRODUCT_ID

export const isEmbedded = () => {
  const params = qs.parse(window.location.search)
  return !!params.source
}
export const isFramed = () => window.top !== window.self
export const isOpened = () => window.opener && window.opener !== window.self

export const sendParentMessage = (message: Message) => {
  isFramed() && window.parent?.postMessage({ ...message, id: PRODUCT_ID }, '*')
}

export const ExtensionContext = createContext({ isExtension: false } as any)

export const ExtensionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [ref, { width }] = useMeasure<HTMLDivElement>()
  const sendAnalytics = useAnalyticsSend()

  const [contactId, setContactId] = useLocalStorage(StorageKeys.ExtContactId, '')
  const [url, setUrl] = useState<string>('')

  // we are going to be taking this directly from a postMessage in the new version
  // of the extension.  But, we may have clients that are not upgraded.  So, in
  // this case we will be falling back to the width method.
  const oldIsActive = useMemo(() => width > 0, [width])
  const [newIsActive, setIsActive] = useState<boolean | null>(null)
  const isActive = useMemo(
    () => (newIsActive === true || newIsActive === false ? newIsActive : oldIsActive),
    [newIsActive, oldIsActive]
  )

  const redirectTo = useCallback(
    (path: string) => {
      if (!location.pathname.startsWith(path)) navigate(path)
    },
    [location.pathname, navigate]
  )

  const debouncedRedirect = useDebouncedCallback(
    (page: string) => {
      redirectTo(page)
    },
    500,
    { leading: true }
  )

  const debouncedAnalytics = useDebouncedCallback(
    (data: UseAnalyticsEventProps) => {
      sendAnalytics(data)
    },
    500,
    { leading: true }
  )

  const handleMessage = useCallback(
    async ({ data }: MessageEvent) => {
      // the extension will pass this for added security
      if (data?.id !== PRODUCT_ID) return

      const newUrl = data?.url ?? ''

      switch (data?.event) {
        case 'PAGE_CHANGE': {
          // bail if we are already on the same page
          if (newUrl === url) return

          const page = parseContactUrl(newUrl)
          if (page?.contactId && page?.contactType && page?.systemName) {
            debouncedAnalytics({
              event: AnalyticsEvent.ExtensionCRMContactOpen,
              data: {
                domain: page.domain,
                systemName: page.systemName,
                contacType: page.contactType,
              },
            })
            debouncedRedirect(`/extension/contacts/${page.systemName}/${page.contactType}/${page.contactId}`)
            setUrl(newUrl)
          }
          break
        }
        case 'CLICK_EMAIL': {
          const page = parseContactUrl(newUrl)
          debouncedAnalytics({
            event: AnalyticsEvent.ExtensionInjectedButton,
            data: { domain: page?.domain, systemName: page?.systemName },
          })
          debouncedRedirect(`/extension/contacts/email/${data.emailAddress}`)
          setUrl(newUrl)
          break
        }
        case 'EXTENSION_TOGGLE': {
          if (data?.active === true || data?.active === false) {
            setIsActive(data.active)
          }
          break
        }
      }
    },
    [debouncedAnalytics, debouncedRedirect, url]
  )

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [handleMessage])

  const value = { isActive, contactId, setContactId, isExtension: true }

  return (
    <ExtensionContext.Provider value={value}>
      <Box
        w="100%"
        p={0}
        ref={ref}
      >
        {children}
      </Box>
    </ExtensionContext.Provider>
  )
}

export const useExtension = () => useContext(ExtensionContext)
