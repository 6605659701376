import type { BoxProps } from '@chakra-ui/react'
import { Box, Text } from '@chakra-ui/react'
import React from 'react'

interface TriggerDetailsRowProps extends BoxProps {
  label?: string
}
export const TriggerDetailsRow: React.FC<TriggerDetailsRowProps> = ({ label, children, ...rest }) => {
  return (
    <Box {...rest}>
      <Text
        fontWeight="semibold"
        whiteSpace="nowrap"
      >
        {label}
      </Text>
      <Box mt={1}>{children || '-'}</Box>
    </Box>
  )
}
