// Taken from extension

import { isEmpty } from 'lodash'
import UrlPattern from 'url-pattern'

export interface IntegrationPage {
  url: string
  domain: string
  systemName?: string
  accountId?: string
  contactId?: string
  contactType?: string
}

const matchers = [
  {
    systemName: 'sfdc',
    domain: 'force.com',
    pattern: new UrlPattern(`(*.)lightning.force.com/lightning/r/Lead/:contactId/view(*)`),
    contactType: 'LEAD',
  },
  {
    systemName: 'sfdc',
    domain: 'force.com',
    pattern: new UrlPattern(`(*.)lightning.force.com/lightning/r/Contact/:contactId/view(*)`),
    contactType: 'CONTACT',
  },
  {
    systemName: 'sfdc',
    domain: 'salesforce.com',
    pattern: new UrlPattern(`(*.)salesforce.com/:contactId(#*)`),
    contactType: 'CONTACT',
  },
  {
    systemName: 'hubspot',
    domain: 'hubspot.com',
    pattern: new UrlPattern(`(*.)hubspot.com/contacts/:accountId/contact/:contactId(/*)`),
    contactType: 'CONTACT',
  },
  {
    systemName: 'salesloft',
    domain: 'salesloft.com',
    pattern: new UrlPattern(`(*.)salesloft.com/app/people/:contactId(*)`),
    contactType: 'CONTACT',
  },
  {
    systemName: 'outreach',
    domain: 'outreach.io',
    pattern: new UrlPattern(`(*.)outreach.io/prospects/:contactId(/*)`),
    contactType: 'CONTACT',
  },
  {
    systemName: 'marketo',
    domain: 'marketo.com',
    pattern: new UrlPattern(`(*.)marketo.com/leadDatabase/loadLeadDetail(*)`),
    params: { leadId: 'contactId' },
    contactType: 'CONTACT',
  },
]

export const getDomain = (url: string) => {
  if (!url) return
  return new URL(String(url)).hostname.split('.').slice(-2).join('.')
}

const resolveParams = (url: string, params?: Record<string, string>) => {
  if (!url || !params) return

  const searchParams = new URL(url).searchParams
  const result = Object.keys(params).reduce((obj: any, key: string) => {
    obj[params[key]] = searchParams.get(key)
    return obj
  }, {})

  return result
}

export const parseContactUrl = (url: string): IntegrationPage | undefined => {
  if (!url) return
  let page = { url, domain: getDomain(url) } as IntegrationPage
  for (const { pattern, params, ...provider } of matchers) {
    // if we don't match on the pattern, continue iteration
    const { _, ...result } = pattern.match(url) || {}
    if (isEmpty(_)) continue
    const resolved = params ? resolveParams(url, params) : {}
    page = { ...page, ...result, ...resolved, ...provider }
    break
  }
  return page
}
