import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import { UiTooltip, useColor } from '@postal-io/postal-ui'
import React from 'react'
import { MdOutlineCheckCircleOutline } from 'react-icons/md'

interface AddressVerifiedBadgeV2Props extends BoxProps {
  isVerified: boolean
}

export const AddressVerifiedBadgeV2: React.FC<AddressVerifiedBadgeV2Props> = ({ isVerified, ...rest }) => {
  const { colorCode } = useColor()
  return (
    <Flex
      flexDir="column"
      textAlign="center"
      {...rest}
    >
      <UiTooltip
        label={isVerified ? 'Address Verified' : 'Address Not Verified'}
        openDelay={500}
        shouldWrapChildren
      >
        {isVerified ? (
          <MdOutlineCheckCircleOutline
            fontSize="20px"
            color={colorCode('atomicBlue.400')}
          />
        ) : (
          <Box />
        )}
      </UiTooltip>
    </Flex>
  )
}
