import type { ZFormLabelProps } from '@postal-io/postal-ui'
import { ZFormLabel } from '@postal-io/postal-ui'
import React from 'react'
import { ZLink } from './ZComponents'

export interface SidePanelHeaderV2Props extends ZFormLabelProps {
  title: string
  canClear?: boolean
  onClear?: () => void
}

export const SidePanelHeader: React.FC<SidePanelHeaderV2Props> = ({ title, canClear, onClear, ...rest }) => {
  return (
    <ZFormLabel
      as="h2"
      mb={2}
      data-testid={`SidePanelHeader-${title}`}
      display="flex"
      justifyContent="space-between"
      mr={0}
      {...rest}
    >
      {title}
      {!!onClear && !!canClear && (
        <ZLink
          fontSize="sm"
          py={0}
          height="unset"
          onClick={onClear}
        >
          clear
        </ZLink>
      )}
    </ZFormLabel>
  )
}
