import { Text } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { useAlerts } from '@postal-io/postal-ui'
import type { Contact } from 'api'
import { DeleteContactDocument } from 'api'
import { ZBasicDialogButtons, ZDialog } from 'components/Common/ZComponents'
import { CONTACT_INVALIDATIONS, useBackgroundQueue } from 'hooks'
import React from 'react'

interface ContactDeleteV2Props {
  isOpen: boolean
  onClose: () => void
  onComplete: () => void
  contact: Contact
}

export const ContactDelete: React.FC<ContactDeleteV2Props> = ({ isOpen, onClose, onComplete, contact }) => {
  const Alert = useAlerts()

  const { invalidate } = useBackgroundQueue()
  const deleteContact = useGraphqlMutation(DeleteContactDocument, {
    onSuccess: () => invalidate(CONTACT_INVALIDATIONS),
  })

  const onConfirm = async () => {
    try {
      await deleteContact.mutateAsync({ id: contact.id })
      Alert.warning('Contact removed')
    } catch (err) {
      Alert.error('Error removing contact')
    } finally {
      onClose()
      onComplete()
    }
  }

  return (
    <ZDialog
      title="Delete Contact"
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <Text data-private>
        Are you sure you want to <strong>Delete</strong> {contact.firstName} {contact.lastName}?
      </Text>
      <ZBasicDialogButtons
        confirmColorScheme="atomicRed"
        confirmText="Delete"
        onClose={onClose}
        onConfirm={onConfirm}
      />
    </ZDialog>
  )
}
