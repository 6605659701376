import type { UserAnalytics } from 'api'
import fallFavoritesImage from 'assets/reporting/popular-sends/fall-favorites.jpg'
import gingerbreadHouseKitImage from 'assets/reporting/popular-sends/gingerbread-house-kit.png'
import shimmeringSnowflakeImage from 'assets/reporting/popular-sends/shimmering-snowflake-peppermint-bark.jpg'
import theEveAnaHardImage from 'assets/reporting/popular-sends/the-eve-ana-hard.png'
import winterEscapeImage from 'assets/reporting/popular-sends/winter-escape.png'

export enum MetricV2 {
  OverallAccepted = 'Overall Accepted',
  MagicLinks = 'Magic Links',
  GiftEmails = 'Gift Emails',
}

export type PlaceholderPostalAnalyticsV2 = { name: string; placeholderImage: string; accepted: number; sent: number }

type PlaceholderPostalAnalyticsV2Map = {
  [MetricV2.GiftEmails]: PlaceholderPostalAnalyticsV2[]
  [MetricV2.MagicLinks]: PlaceholderPostalAnalyticsV2[]
  [MetricV2.OverallAccepted]: PlaceholderPostalAnalyticsV2[]
}

// when there is insufficient data, use this instead
export const PLACEHOLDER_POSTAL_ANALYTICS_V2: PlaceholderPostalAnalyticsV2Map = {
  [MetricV2.GiftEmails]: [
    {
      name: 'Gingerbread House Kit',
      placeholderImage: gingerbreadHouseKitImage,
      sent: 15,
      accepted: 8,
    },
    { name: 'Winter Escape', placeholderImage: winterEscapeImage, sent: 23, accepted: 12 },

    { name: 'Fall Favorites!', placeholderImage: fallFavoritesImage, sent: 14, accepted: 5 },
    { name: 'The Eve, Ana Hard', placeholderImage: theEveAnaHardImage, sent: 14, accepted: 4 },
    {
      name: 'Shimmering Snowflake Peppermint Bark',
      placeholderImage: shimmeringSnowflakeImage,
      sent: 11,
      accepted: 2,
    },
  ],
  [MetricV2.MagicLinks]: [
    {
      name: 'Gingerbread House Kit',
      placeholderImage: gingerbreadHouseKitImage,
      sent: 15,
      accepted: 8,
    },
    {
      name: 'Shimmering Snowflake Peppermint Bark',
      placeholderImage: shimmeringSnowflakeImage,
      sent: 13,
      accepted: 5,
    },
    { name: 'Fall Favorites!', placeholderImage: fallFavoritesImage, sent: 14, accepted: 5 },
    { name: 'The Eve, Ana Hard', placeholderImage: theEveAnaHardImage, sent: 14, accepted: 4 },
    { name: 'Winter Escape', placeholderImage: winterEscapeImage, sent: 12, accepted: 2 },
  ],
  [MetricV2.OverallAccepted]: [
    {
      name: 'Shimmering Snowflake Peppermint Bark',
      placeholderImage: shimmeringSnowflakeImage,
      sent: 55,
      accepted: 53,
    },
    {
      name: 'Gingerbread House Kit',
      placeholderImage: gingerbreadHouseKitImage,
      sent: 15,
      accepted: 8,
    },
    { name: 'Winter Escape', placeholderImage: winterEscapeImage, sent: 23, accepted: 12 },

    { name: 'Fall Favorites!', placeholderImage: fallFavoritesImage, sent: 14, accepted: 5 },
    { name: 'The Eve, Ana Hard', placeholderImage: theEveAnaHardImage, sent: 14, accepted: 4 },
  ],
}

export const PLACEHOLDER_USER_ANALYTICS_V2: UserAnalytics[] = [
  {
    firstName: 'Katie',
    lastName: '',
    userId: '000000000000000000000001',
    accepted: 750,
    deliveryEmailAccepted: 20,
    costInCents: 5025000,
    linkTotalViews: 5,
    linkExecutions: 100,
    meetingsBooked: 600,
    thankYou: 400,
    socialShare: 300,
    newContact: 50,
    linkNewContact: 50,
    linkUniqViews: 10,
    date: '2021-12-02T00:00:00Z',
    teamName: 'Sales Team',
  },
  {
    firstName: 'Jon',
    lastName: '',
    userId: '000000000000000000000002',
    accepted: 1250,
    deliveryEmailAccepted: 20,
    costInCents: 5025000,
    linkTotalViews: 5,
    linkExecutions: 200,
    linkUniqViews: 26,
    meetingsBooked: 201,
    thankYou: 324,
    socialShare: 420,
    newContact: 45,
    linkNewContact: 42,
    date: '2021-12-02T00:00:00Z',
    teamName: 'Marketing Team',
  },
  {
    firstName: 'Hayden',
    lastName: '',
    userId: '000000000000000000000003',
    accepted: 300,
    deliveryEmailAccepted: 20,
    costInCents: 5025000,
    linkTotalViews: 5,
    linkExecutions: 164,
    linkUniqViews: 23,
    meetingsBooked: 520,
    thankYou: 286,
    socialShare: 144,
    newContact: 78,
    linkNewContact: 60,
    date: '2021-12-02T00:00:00Z',
    teamName: 'SDR Team',
  },
  {
    firstName: 'Aimee',
    lastName: '',
    userId: '000000000000000000000004',
    accepted: 600,
    deliveryEmailAccepted: 20,
    costInCents: 5025000,
    linkTotalViews: 5,
    linkExecutions: 44,
    linkUniqViews: 25,
    meetingsBooked: 542,
    thankYou: 400,
    socialShare: 250,
    newContact: 60,
    linkNewContact: 20,
    date: '2021-12-02T00:00:00Z',
    teamName: 'CS Team',
  },
  {
    firstName: 'Danielle',
    lastName: '',
    userId: '000000000000000000000005',
    accepted: 200,
    deliveryEmailAccepted: 25,
    costInCents: 5025000,
    linkTotalViews: 40,
    linkExecutions: 60,
    linkUniqViews: 20,
    meetingsBooked: 505,
    thankYou: 201,
    socialShare: 150,
    newContact: 120,
    linkNewContact: 40,
    date: '2021-12-02T00:00:00Z',
    teamName: 'Recruiting Team',
  },
]
