import { CloseIcon } from '@chakra-ui/icons'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiBanner,
  UiTabList,
  UiTabPanel,
  UiTabPanels,
  UiTabs,
  UiTooltip,
  useAlertError,
  ZCard,
  ZCardHeader,
  ZTab,
  ZText,
} from '@postal-io/postal-ui'
import { BillingAccountsDocument, Status } from 'api'
import { CompletedAction, useAcl, useCompletedActions } from 'hooks'
import { forwardRef, useState } from 'react'
import { MdOutlineInfo } from 'react-icons/md'
import { BalanceTransferCreateForm } from './BalanceTransferCreateForm'
import { OldBalanceTransferCreateForm } from './OldBalanceTransferCreateForm'

const TransferPickerTab = forwardRef((props: any, ref) => (
  <ZTab
    textTransform="none"
    borderWidth="1px"
    borderColor="atomicGray.50"
    borderRadius="3px"
    py={2}
    px={4}
    _notFirst={{
      ml: 2,
    }}
    ref={ref}
    fontSize="16px"
    fontWeight={500}
    color="atomicGray.500"
    _selected={{
      color: 'atomicBlue.800',
      bg: 'atomicBlue.10',
      borderColor: 'atomicBlue.10',
    }}
    sx={{
      '& button': {
        padding: 0,
      },
    }}
    {...props}
  />
))

interface ExplanationBannerProps {
  children: any
  onDismiss?: any
}
const ExplanationBanner: React.FC<ExplanationBannerProps> = ({ children, onDismiss }) => (
  <UiBanner
    color="atomicGray.500"
    bg="atomicGray.10"
    mb={4}
    borderRadius="3px"
    px={4}
    boxShadow="none"
    borderColor="atomicGray.100"
  >
    <MdOutlineInfo
      color="#C0CCDA"
      size="18px"
    />
    <ZText
      flex={1}
      fontSize="14px"
      fontWeight={350}
      color="inherit"
      ml={2}
    >
      {children}
    </ZText>
    {onDismiss && (
      <UiTooltip label="Got it.">
        <CloseIcon
          fontSize="xs"
          ml={3}
          cursor="pointer"
          onClick={onDismiss}
        />
      </UiTooltip>
    )}
  </UiBanner>
)

export const BalanceTransferCreateCard = () => {
  const { hasFeature } = useAcl()
  const { completedActions, markComplete } = useCompletedActions()
  const [foreignTransfer, setForeignTransfer] = useState<boolean>(false)

  // Only show active FUNDS billing accounts.
  // Treat 'null' as active since 'status' field was added later so not all billing accounts will have it set.
  const billingAccountsQuery = useGraphqlQuery(
    BillingAccountsDocument,
    {
      filter: { status: { in: [Status.Active, null] }, type: { eq: 'FUNDS' } },
    },
    { keepPreviousData: true }
  )
  const billingAccounts = billingAccountsQuery.data?.billingAccounts ?? []
  useAlertError(billingAccountsQuery.error)

  const isLoading = billingAccountsQuery.isLoading

  return hasFeature('transferCurrency') ? (
    <ZCard
      isLoading={isLoading}
      variant="form"
    >
      <UiTabs p={8}>
        <UiTabList
          borderBottom="none"
          mb={5}
        >
          <TransferPickerTab
            value="Domestic"
            onClick={() => setForeignTransfer(false)}
          >
            Set up a domestic transfer
          </TransferPickerTab>
          <TransferPickerTab
            value="Foreign"
            onClick={() => setForeignTransfer(true)}
          >
            Set up a foreign currency transfer
          </TransferPickerTab>
        </UiTabList>
        <UiTabPanels>
          <UiTabPanel>
            {!completedActions.dismissDomesticTransferTip && (
              <ExplanationBanner onDismiss={() => markComplete(CompletedAction.DismissDomesticTransferTip)}>
                A domestic transfer is between two billing accounts of the same currency.
              </ExplanationBanner>
            )}
          </UiTabPanel>
          <UiTabPanel>
            {!completedActions.dismissForeignTransferTip && (
              <ExplanationBanner onDismiss={() => markComplete(CompletedAction.DismissForeignTransferTip)}>
                A foreign currency transfer exchanges one currency for another. After confirming the terms you'll get an
                email receipt with the transfer details.
              </ExplanationBanner>
            )}
          </UiTabPanel>
        </UiTabPanels>
        <BalanceTransferCreateForm
          billingAccounts={billingAccounts}
          foreignTransfer={foreignTransfer}
        />
      </UiTabs>
    </ZCard>
  ) : (
    <ZCard
      isLoading={isLoading}
      variant="form"
    >
      <ZCardHeader
        p={8}
        pb={0}
      >
        Set up a transfer
      </ZCardHeader>
      <OldBalanceTransferCreateForm billingAccounts={billingAccounts} />
    </ZCard>
  )
}
