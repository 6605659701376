import { Heading, Text } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlerts, ZButton } from '@postal-io/postal-ui'
import type { NamedProductAccess } from 'api'
import { NamedProductAccessListDocument } from 'api'
import { AccountPickerDropdown } from 'components/Common'
import { PageTitle, useSession } from 'hooks'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { LogInHeaderBlack } from './LogInHeader'
import { MainWithMarketingPane } from './Main'

export const AccountDisabled = () => {
  const { session, switchSession, logout } = useSession()
  const { accountId, accountName, productAccessId } = session
  const Alert = useAlerts()

  const namedProductAccessList = useGraphqlQuery(NamedProductAccessListDocument)

  // we seem to be getting some bad data back here so lets
  // filter for all the things we need
  const productAccessList = useMemo(() => {
    return (namedProductAccessList.data?.namedProductAccessList?.filter((item) => {
      return (
        item?.productAccess?.product === process.env.REACT_APP_PRODUCT_ID &&
        !isEmpty(item?.productAccess?.roles) &&
        !isEmpty(item?.productAccess?.id) &&
        !isEmpty(item?.accountName)
      )
    }) || []) as NamedProductAccess[]
  }, [namedProductAccessList.data?.namedProductAccessList])

  const hasOtherAccounts = useMemo(
    () => productAccessList.filter((item) => item.productAccess.accountId !== accountId),
    [accountId, productAccessList]
  )

  const switchAccount = async (account: NamedProductAccess) => {
    if (account.productAccess.id === productAccessId) return
    try {
      await switchSession(account.productAccess.id)
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <>
      <PageTitle title="Account Disabled" />

      <MainWithMarketingPane>
        <LogInHeaderBlack>
          <Heading
            color="white"
            as="h1"
            fontSize="h4"
            fontWeight="bold"
            mt={10}
            mb={2.5}
          >
            Account Disabled
          </Heading>
          {hasOtherAccounts ? (
            <>
              <Text
                color="white"
                mb={10}
              >
                The {accountName} account has been disabled.
                <br /> Please choose another account below.
              </Text>
              <AccountPickerDropdown
                productAccessList={productAccessList}
                productAccessId={productAccessId}
                onSelect={switchAccount}
              />
              <Text
                mt={10}
                color="white"
              >
                - OR -
              </Text>
            </>
          ) : (
            <Text color="white">
              The {accountName} account has been disabled.
              <br /> Please contact your admin for more info.
            </Text>
          )}
          <ZButton
            onClick={logout}
            mt={10}
            colorScheme="atomicBlue"
            minWidth="120px"
          >
            Logout
          </ZButton>
        </LogInHeaderBlack>
      </MainWithMarketingPane>
    </>
  )
}
