import { Box } from '@chakra-ui/react'
import { ZHeading } from '@postal-io/postal-ui'
import type { ReactElement } from 'react'
import { getElementDisplayName } from './Assets'
import { BorderEdit } from './BorderEdit'
import { ElementLocation } from './ElementLocation'
import { ImageEdit } from './ImageEdit'
import { QrCodeEdit } from './QrCodeEdit'
import { TextEdit } from './TextEdit'
import { UserMessageEdit } from './UserMessageEdit'
import { VoidEdit } from './VoidEdit'

export interface ElementEditorProps {
  elementLocation?: ReactElement
}

const ELEMENT_MAP: { [key: string]: any } = {
  AddressLabel: TextEdit,
  Border: BorderEdit,
  FoldLine: VoidEdit,
  Image: ImageEdit,
  Logo: ImageEdit,
  Postage: VoidEdit,
  QrCode: QrCodeEdit,
  ReservedArea: VoidEdit,
  Text: TextEdit,
  UserMessage: UserMessageEdit,
  UspsImb: VoidEdit,
  UspsBlock: VoidEdit,
}

export interface ListItemProps {
  title: string
}

const MyHeading = ({ element }: any) => {
  return (
    <ZHeading
      as="h3"
      size="md"
      p={element?.name ? 0 : 6}
    >
      {element?.name ? getElementDisplayName(element.name) : 'No Element Selected'}
    </ZHeading>
  )
}

export const ElementEdit = ({
  element,
  dispatch,
  canvasWidth,
  canvasHeight,
}: {
  element: any
  dispatch: any // (e: { type: string; payload?: any }) => void
  canvasWidth: number
  canvasHeight: number
}) => {
  if (!element) return <MyHeading />
  const { settings } = element

  const handleChange = (newSettings: any) => {
    dispatch({ type: 'EDIT', payload: { ...element, settings: newSettings } })
  }

  const Component = ELEMENT_MAP[element.name]

  return (
    <Box p={6}>
      <MyHeading element={element} />
      {element.name !== 'Text' ? (
        <ElementLocation
          element={element}
          dispatch={dispatch}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          my={4}
        />
      ) : null}
      <Component
        settings={settings || {}}
        onChange={handleChange}
        elementLocation={
          element.name === 'Text' ? (
            <ElementLocation
              element={element}
              dispatch={dispatch}
              canvasWidth={canvasWidth}
              canvasHeight={canvasHeight}
            />
          ) : null
        }
      />
    </Box>
  )
}
