import { CloseIcon } from '@chakra-ui/icons'
import type { IconButtonProps } from '@chakra-ui/react'
import { Flex, HStack, useDisclosure } from '@chakra-ui/react'
import type { UiActionMenuProps, UiSubNavbarProps } from '@postal-io/postal-ui'
import { UiActionMenu, UiEnhancedTooltip, UiIconButton, UiLink, ZHeading, ZNavbar } from '@postal-io/postal-ui'
import { useAcl } from 'hooks'
import { useAutoResettingTrigger } from 'hooks/useAutoResettingTrigger'
import { snakeCase } from 'lodash'
import type { ReactElement } from 'react'
import React, { useMemo } from 'react'
import {
  MdChevronLeft,
  MdHelpOutline,
  MdOutlineFavorite,
  MdOutlineFavoriteBorder,
  MdOutlineMoreHoriz,
} from 'react-icons/md'
import { useMatch, useNavigate } from 'react-router-dom'

const NAVBAR_COLOR = '#222222'
const INVERT_NAVBAR_COLOR = 'atomicGray.10'

export const NavbarActionMenu: React.FC<UiActionMenuProps> = ({ actionItems }) => {
  const disclosure = useDisclosure()

  return (
    <UiEnhancedTooltip
      label="Action Menu"
      shouldWrapChildren
      hasArrow
      mt={2}
      isDisabled={disclosure.isOpen}
    >
      <UiActionMenu
        data-testid="SecondaryNavbar_actionMenu"
        color="white"
        bg="none"
        borderWidth={0}
        py={1}
        pl={0}
        pr={0}
        minW={0}
        w="18px"
        title=""
        menuProps={disclosure}
        menuListProps={{ color: 'atomicGray.600' }}
        sx={{ '& .chakra-button__icon': { ml: 0 } }}
        _hover={{ color: 'gray.300' }}
        _active={{ color: 'white' }}
        actionItems={actionItems}
        rightIcon={
          <MdOutlineMoreHoriz
            size="24px"
            style={{ marginInlineStart: 0 }}
          />
        }
      />
    </UiEnhancedTooltip>
  )
}

interface NavbarBackButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  icon?: ReactElement<any>
  onClick: () => void
  label?: string
  invertColors?: boolean
}
export const NavbarBackButton: React.FC<NavbarBackButtonProps> = ({
  icon = <MdChevronLeft />,
  onClick,
  label = 'Go Back',
  invertColors,
  ...rest
}) => (
  <UiEnhancedTooltip
    label={label}
    hasArrow
    mt={2}
  >
    <UiIconButton
      aria-label={snakeCase(label)}
      onClick={onClick}
      bg={invertColors ? 'atomicGray.50' : 'white'}
      color={invertColors ? 'atomicGray.600' : NAVBAR_COLOR}
      borderRadius="50%"
      _hover={{ bg: invertColors ? 'atomicGray.50' : 'white', opacity: 0.8 }}
      _active={{ bg: invertColors ? 'atomicGray.50' : 'white', opacity: 0.9 }}
      minW="unset"
      h={8}
      w={8}
      mr={5}
      fontSize="2xl"
      {...rest}
    >
      {icon}
    </UiIconButton>
  </UiEnhancedTooltip>
)
interface NavbarCloseButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  onClick: () => void
  label?: string
}
export const NavbarCloseButton: React.FC<NavbarCloseButtonProps> = ({ onClick, label = 'Exit', ...rest }) => (
  <UiEnhancedTooltip
    label={label}
    hasArrow
    mt={2}
  >
    <UiIconButton
      aria-label={snakeCase(label)}
      onClick={onClick}
      color="white"
      bg={NAVBAR_COLOR}
      borderRadius="50%"
      _hover={{ color: 'white', opacity: 0.8 }}
      minW="unset"
      fontSize="lg"
      h={8}
      {...rest}
    >
      <CloseIcon />
    </UiIconButton>
  </UiEnhancedTooltip>
)

interface NavbarFavoritesButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  label?: string
}
export const NavbarFavoritesButton: React.FC<NavbarFavoritesButtonProps> = ({ label = 'Favorites', ...rest }) => {
  const { aclCheck } = useAcl()
  const hasMyItems = aclCheck({ module: 'postals.read' })
  const navigate = useNavigate()
  const isFavorites = useMatch('/favorites')

  const [disableTooltip, setDisableTooltip] = useAutoResettingTrigger(500)

  const color = useMemo(() => (isFavorites ? 'pink.300' : 'white'), [isFavorites])

  if (!hasMyItems) return null
  return (
    <UiEnhancedTooltip
      label={label}
      hasArrow
      isDisabled={disableTooltip}
      mt={2}
    >
      <UiIconButton
        aria-label={snakeCase(label)}
        color={color}
        onClick={() => {
          setDisableTooltip(true)
          navigate('/favorites')
        }}
        bg={NAVBAR_COLOR}
        borderRadius="50%"
        _hover={{ color, opacity: 0.8 }}
        _active={{ color, opacity: 0.9 }}
        minW="unset"
        fontSize="lg"
        h={8}
        cursor="pointer"
        {...rest}
      >
        {isFavorites ? <MdOutlineFavorite size="22px" /> : <MdOutlineFavoriteBorder size="22px" />}
      </UiIconButton>
    </UiEnhancedTooltip>
  )
}

export const NavbarHelpCenterButton: React.FC<{ invertColors?: boolean }> = ({ invertColors }) => (
  <UiEnhancedTooltip
    label="Open Help Center"
    hasArrow
    mt={2}
  >
    <UiIconButton
      as={UiLink}
      href="https://help.postal.com/helpcenter/s/"
      target="_blank"
      aria-label={'help-center'}
      color={invertColors ? 'atomicGray.900' : 'white'}
      bg="transparent"
      borderRadius="50%"
      _hover={{ color: invertColors ? 'atomicGray.900' : 'white', bg: 'transparent', opacity: 0.8 }}
      minW="unset"
      h={8}
    >
      <MdHelpOutline size="22px" />
    </UiIconButton>
  </UiEnhancedTooltip>
)

interface SecondaryNavbarProps extends UiSubNavbarProps {
  header?: string
  onExit?: () => void
  onExitLabel?: string
  hideHelpCenterButton?: boolean
  farRight?: ReactElement<any>
  invertColors?: boolean
}
export const SecondaryNavbar: React.FC<SecondaryNavbarProps> = ({
  header,
  onExit,
  onExitLabel,
  hideHelpCenterButton,
  left,
  right,
  farRight,
  maxW,
  invertColors,
  ...rest
}) => (
  <ZNavbar
    verticalAlign="middle"
    mb={0}
    position="sticky"
    top={0}
    bg={invertColors ? INVERT_NAVBAR_COLOR : NAVBAR_COLOR}
    zIndex={3}
    {...rest}
    left={
      <Flex
        alignItems="center"
        h="52px"
        color={invertColors ? NAVBAR_COLOR : 'white'}
      >
        {left}
        <ZHeading
          size="h1"
          as="h1"
          fontSize="lg"
          color="inherit"
        >
          {header}
        </ZHeading>
      </Flex>
    }
    right={
      <HStack
        spacing={5}
        alignItems="center"
        h="52px"
        color={invertColors ? NAVBAR_COLOR : 'white'}
      >
        {right}
        {!hideHelpCenterButton && <NavbarHelpCenterButton invertColors={invertColors} />}
        {farRight}
        {onExit && (
          <NavbarCloseButton
            onClick={onExit}
            label={onExitLabel}
            color={invertColors ? NAVBAR_COLOR : 'white'}
          />
        )}
      </HStack>
    }
    borderBottomWidth={invertColors ? '1px' : 0}
    borderColor="atomicGray.100"
  />
)
