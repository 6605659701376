import type { Column } from '@postal-io/postal-ui'
import { UiActionMenu, UiDate, UiStatusTag } from '@postal-io/postal-ui'
import { capitalize } from 'lodash'

const STATUS_COLOR_MAP: Record<string, string> = {
  ACTIVE: 'green.500',
  QUEUED: 'green.500',
  PROCESSING: 'green.500',
  CANCELLED: 'blue.400',
  COMPLETED: 'blue.500',
  FAILED: 'red.500',
}

export const playbookColumns: Column[] = [
  { key: 'name', label: 'Name' },
  {
    key: 'completedSteps',
    label: 'Steps',
  },
  { key: 'duration', label: 'Days' },
  {
    key: 'postalDate',
    label: 'Next Send',
    render: ({ postalDate }: any) => (
      <UiDate
        date={postalDate}
        fallback="N/A"
      />
    ),
  },
  {
    key: 'status',
    label: 'Status',
    render: ({ status }: any) => (
      <UiStatusTag
        minW="100px"
        px={4}
        colorScheme={STATUS_COLOR_MAP[status]}
        label={capitalize(status)}
      />
    ),
  },
  {
    key: 'action',
    label: 'Action',
    render: ({ actions }: any) => <UiActionMenu actionItems={actions} />,
  },
]
