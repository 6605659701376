import { getAuthToken } from 'api/rest'
import jwtDecode from 'jwt-decode'
import { redirect } from 'react-router-dom'

export interface UserSession {
  accountId: string
  accountName: string
  firstName: string
  lastName: string
  permissions: string[]
  plan: string
  product: string
  productAccessId: string
  roles: string[]
  teamId?: string
  teamName?: string
  type: string
  userId: string
  userName: string
  sessionUuid: string
}

export const getValidSession = async () => {
  try {
    const token = await getAuthToken()
    if (token) return jwtDecode(token) as UserSession
  } catch (err) {
    console.error(err)
  }
}

export async function requireUser(request: Request) {
  const session = await getValidSession()
  const url = new URL(request.url)
  if (!session) {
    const prefix = url.pathname.startsWith('/extension') ? '/extension/' : '/'
    const search = new URLSearchParams()
    if (url.pathname !== prefix) search.set('returnTo', url.pathname)
    if (!url.pathname.startsWith(`${prefix}sign-in`)) {
      throw redirect(`${prefix}sign-in?${search.toString()}`)
    }
  } else {
    if (!session.roles?.length) {
      if (!url.pathname.startsWith('/error')) throw redirect('/error/disabled_user')
    }
    if (session.plan === 'LOGIN' || session.plan === 'PRETRIAL') {
      if (!url.pathname.startsWith('/register')) throw redirect(`/register`)
    }
    if (session.plan === 'NONE') {
      if (!url.pathname.startsWith('/disabled')) throw redirect('/disabled')
    }
  }
  return { session }
}

export async function requirePermissions(request: Request, authenticated: boolean) {
  const { session } = await requireUser(request)
  if (!authenticated) throw new Response(null, { status: 404 })
  return { session }
}
