import { InfoOutlineIcon } from '@chakra-ui/icons'
import type { BoxProps } from '@chakra-ui/react'
import { Box, Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  SelectTypeaheadStylesV2,
  UiIconPostalEdit,
  UiSelectTypeahead,
  UiToggle,
  UiTooltip,
  useAlerts,
  ZFormLabel,
  ZLink,
  ZText,
} from '@postal-io/postal-ui'
import type { Attribution, CrmDataListItem } from 'api'
import { DataListType, DataObjectType, GetDataListDocument, SearchIntegrationSyncDocument, Status } from 'api'
import { useAcl } from 'hooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useImmer } from 'use-immer'
import { AttributionMapping } from './AttributionMapping'
import type { ExternalProvider } from './data'
import { ExternalProviders, ExternalSystem, getExternalProvider } from './data'

const DEFAULT_ATTRIBUTION = {}
const DEFAULT_STATE = { system: null }

export interface AttributionState {
  enabled?: boolean
  system?: ExternalProvider | null
  type?: DataListType
  id?: string
  name?: string
}
export interface AttributionSelectProps extends Omit<BoxProps, 'onChange'> {
  menuPlacement?: 'auto' | 'bottom' | 'top'
  attribution?: Attribution | null
  isDisabled?: boolean
  onChange: (state: Attribution) => void
  showToggleLabel?: boolean
  reverse?: boolean
}
export const AttributionSelect: React.FC<AttributionSelectProps> = ({
  menuPlacement = 'auto',
  attribution,
  isDisabled,
  onChange,
  showToggleLabel = false,
  reverse = false, // Shows toggle on the left and is left aligned
  ...rest
}) => {
  const [state, setState] = useImmer<AttributionState>(DEFAULT_STATE)
  const [searchString, setSearchString] = useState<string | undefined>()
  const mappingDisclosure = useDisclosure()
  const { hasPermission, hasFeature, aclCheck } = useAcl()
  const canUpdate = hasPermission('postals.create') && hasFeature('attribution')

  const Alert = useAlerts()

  const resetState = useCallback(() => {
    setState((draft) => {
      if (attribution?.marketoCampaignId) {
        draft.system = getExternalProvider(ExternalSystem.Marketo)
        draft.type = DataListType.Campaigns
        draft.id = attribution?.marketoCampaignId
        draft.name = attribution?.marketoCampaignName || attribution?.marketoCampaignId
        draft.enabled = true
      } else if (attribution?.marketoProgramId) {
        draft.system = getExternalProvider(ExternalSystem.Marketo)
        draft.type = DataListType.Programs
        draft.id = attribution?.marketoProgramId
        draft.name = attribution?.marketoProgramName || attribution?.marketoProgramId
        draft.enabled = true
      } else if (attribution?.sfdcCampaignId) {
        draft.system = getExternalProvider(ExternalSystem.Salesforce)
        draft.type = DataListType.Campaigns
        draft.id = attribution?.sfdcCampaignId
        draft.name = attribution?.sfdcCampaignName || attribution?.sfdcCampaignId
        draft.enabled = true
      } else if (attribution?.sdfcSandboxCampaignId) {
        draft.system = getExternalProvider(ExternalSystem.SalesforceSandbox)
        draft.type = DataListType.Campaigns
        draft.id = attribution?.sdfcSandboxCampaignId
        draft.name = attribution?.sfdcSandboxCampaignName || attribution?.sdfcSandboxCampaignId
        draft.enabled = true
      } else if (attribution?.eloquaCampaignId) {
        draft.system = getExternalProvider(ExternalSystem.Eloqua)
        draft.type = DataListType.Campaigns
        draft.id = attribution?.eloquaCampaignId
        draft.name = attribution?.eloquaCampaignName || attribution?.eloquaCampaignId
        draft.enabled = true
      }
    })
  }, [
    attribution?.eloquaCampaignId,
    attribution?.eloquaCampaignName,
    attribution?.marketoCampaignId,
    attribution?.marketoCampaignName,
    attribution?.marketoProgramId,
    attribution?.marketoProgramName,
    attribution?.sdfcSandboxCampaignId,
    attribution?.sfdcCampaignId,
    attribution?.sfdcCampaignName,
    attribution?.sfdcSandboxCampaignName,
    setState,
  ])

  // set on load
  useEffect(() => {
    resetState()
  }, [resetState])

  // send back changes to parent
  useEffect(() => {
    if (!state.enabled) {
      onChange(DEFAULT_ATTRIBUTION)
    } else {
      const systemName = state.system?.system
      const attr = {} as Attribution
      if (systemName === 'marketo' && state.type === DataListType.Campaigns) {
        attr.marketoCampaignId = state.id
        attr.marketoCampaignName = state.name
      } else if (systemName === 'marketo' && state.type === DataListType.Programs) {
        attr.marketoProgramId = state.id
        attr.marketoProgramName = state.name
      } else if (systemName === 'sfdc') {
        attr.sfdcCampaignId = state.id
        attr.sfdcCampaignName = state.name
      } else if (systemName === 'sfdcsandbox') {
        attr.sdfcSandboxCampaignId = state.id
        attr.sfdcSandboxCampaignName = state.name
      } else if (systemName === 'eloqua') {
        attr.eloquaCampaignId = state.id
        attr.eloquaCampaignName = state.name
      }
      onChange(attr)
    }
    // skipping onChange in case this callback isn't memo'd
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.enabled, state.id, state.name, state.system?.system, state.type])

  // Select System
  const systems = useMemo(() => {
    return ExternalProviders.filter((p) => {
      return !!p.integration?.hasAttribution && aclCheck(p.integration)
    }).map((t) => t.system)
  }, [aclCheck])

  const getSyncs = useGraphqlQuery(SearchIntegrationSyncDocument, {
    filter: {
      objectType: { eq: DataObjectType.User },
      status: { eq: Status.Active },
      system: { in: systems },
    },
  })

  const options = useMemo(() => {
    const syncs = getSyncs?.data?.searchIntegrationSync || []
    const available = syncs.map((sync) => getExternalProvider(sync.system)).filter(Boolean)
    return available as ExternalProvider[]
  }, [getSyncs?.data?.searchIntegrationSync])

  const [debouncedSearchString] = useDebounce(searchString, 1000)

  // Select Campaign
  const getCampaigns = useGraphqlQuery(
    GetDataListDocument,
    {
      system: state.system?.system as string,
      type: state.type as DataListType,
      // when searchString is defined, use a more precise query
      searchString: debouncedSearchString,
    },
    { enabled: canUpdate && !!state.system && !!state.type }
  )

  useEffect(() => {
    const error = getCampaigns.error
    if (error)
      Alert.warning('There was an error retrieving data from this integration. Please double-check your permissions.')
  }, [Alert, getCampaigns.error])

  const campaigns = useMemo(() => {
    return getCampaigns?.data?.getDataList?.items || []
  }, [getCampaigns?.data?.getDataList])

  const selectedCampaign = useMemo(() => {
    if (!state.id) return null
    // in case the campaign
    return campaigns.find((c) => c.id === state.id) ?? ({ id: state.id, name: state.name } as CrmDataListItem)
  }, [campaigns, state.id, state.name])

  const handleSystem = (system: ExternalProvider | null) => {
    switch (system?.system) {
      case ExternalSystem.Eloqua:
      case ExternalSystem.HubSpot:
      case ExternalSystem.Outreach:
      case ExternalSystem.SalesLoft:
      case ExternalSystem.Salesforce:
      case ExternalSystem.SalesforceSandbox:
        setState((draft) => {
          draft.system = system
          draft.type = DataListType.Campaigns
        })
        break
      case ExternalSystem.Marketo:
        setState((draft) => {
          draft.system = system
          draft.type = DataListType.Programs
        })
        break
      default:
        setState(() => ({ system: null }))
    }
  }

  const handleCampaign = (campaign: CrmDataListItem | null) => {
    setState((draft) => {
      draft.id = campaign?.id
      draft.name = campaign?.name
    })
  }

  const handleCampaignString = (campaign: string) => {
    if (campaign.length > 2) setSearchString(campaign)
    else setSearchString(undefined)
  }

  const handleEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((draft) => {
      draft.enabled = e.target.checked
    })
  }

  const needsMapping = !!state.system && state.system?.system !== ExternalSystem.Marketo && !!state.id

  if (!options.length || !canUpdate || isDisabled) return null

  return (
    <>
      <Box {...rest}>
        <ZFormLabel
          htmlFor="attribution-enabled"
          display="flex"
          justifyContent={reverse ? 'start' : 'space-between'}
          flexDirection={reverse ? 'row-reverse' : 'row'}
          mr={0}
          whiteSpace="normal"
        >
          <ZText color="atomicGray.600">
            Map to external campaign
            <UiTooltip
              label="Map this item to an external campaign in order to track campaign progress."
              placement="top"
            >
              <ZLink
                href="https://help.postal.com/helpcenter/s/article/Associating-Items-with-SFDC-Campaigns"
                isExternal
              >
                <InfoOutlineIcon
                  color="atomicGray.400"
                  _hover={{ color: 'atomicGray.600' }}
                  ml={2}
                />
              </ZLink>
            </UiTooltip>
          </ZText>
          {showToggleLabel ? (
            <UiToggle
              colorScheme="atomicBlue"
              id="attribution-enabled"
              name="enabled"
              isChecked={!!state.enabled}
              onChange={handleEnabled}
              fontWeight="bold"
            >
              Active
            </UiToggle>
          ) : (
            <UiToggle
              colorScheme="atomicBlue"
              id="attribution-enabled"
              name="enabled"
              isChecked={!!state.enabled}
              onChange={handleEnabled}
              mr={reverse ? 6 : 0}
            />
          )}
        </ZFormLabel>
        {!!state.enabled && (
          <>
            <Stack
              spacing={4}
              mt={6}
            >
              <UiSelectTypeahead
                menuPlacement={menuPlacement}
                options={options}
                value={state.system}
                getOptionLabel={(opt) => opt.name}
                getOptionValue={(opt) => opt.system}
                onChange={handleSystem}
                isLoading={getSyncs.isLoading}
                placeholder="Select Integration"
                isDisabled={!options.length}
                {...SelectTypeaheadStylesV2}
              />
              {!!state.system && !!state.type && (
                <UiSelectTypeahead
                  menuPlacement={menuPlacement}
                  options={campaigns}
                  getOptionLabel={(opt) => opt.name}
                  getOptionValue={(opt) => opt.id}
                  value={selectedCampaign}
                  onChange={handleCampaign}
                  onInputChange={handleCampaignString}
                  isLoading={getCampaigns.isLoading}
                  placeholder={state.type === DataListType.Campaigns ? 'Select Campaign' : 'Select Program'}
                  {...SelectTypeaheadStylesV2}
                />
              )}
            </Stack>
            {needsMapping && (
              <Box mt={4}>
                <ZLink onClick={mappingDisclosure.onOpen}>
                  <UiIconPostalEdit mr={1} />
                  Edit Mapping
                </ZLink>
              </Box>
            )}
          </>
        )}
      </Box>
      {needsMapping && !!state.enabled && (
        <AttributionMapping
          {...mappingDisclosure}
          system={state.system?.system as string}
          campaignId={state.id as string}
        />
      )}
    </>
  )
}
