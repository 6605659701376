import type { PostalSendContext } from 'components/PostalSend/usePostalSend'
import { useEffect, useMemo, useRef } from 'react'

/**
 * Error related Animations
 */

// for when the user clicks a disabled button
export const SHAKE_ANIMATION_NAME = 'postal-button-shake'
export const shakeAnimation = `
@keyframes ${SHAKE_ANIMATION_NAME} {
  0% {
      transform: translateX(-7px);
  }

  25% {
      transform: translateX(6px);
  }

  50% {
      transform: translateX(-5px);
  }

  75% {
      transform: translateX(4px);
  }

  100% {
      transform: translateX(-2px);
  }
}`

// temporarily highlights a field that is invalid
export const INVALID_FIELD_ANIMATION_DURATION = 1000
export const INVALID_FIELD_ANIMATION_NAME = 'invalid-field'
const INVALID_FIELD_ANIMATION = `${INVALID_FIELD_ANIMATION_NAME} ${INVALID_FIELD_ANIMATION_DURATION / 1000}s`
export const invalidFieldAnimationStyles = `
@keyframes ${INVALID_FIELD_ANIMATION_NAME} {
  0% {
      border-color: var(--chakra-colors-atomicYellow-600);
      box-shadow: 0 0 15px var(--chakra-colors-atomicYellow-400);
  }

  70% {
    border-color: var(--chakra-colors-atomicYellow-600);
    box-shadow: 0 0 10px var(--chakra-colors-atomicYellow-400);
  }

  100% {
      border-color: var(--chakra-colors-atomicGray-500);
      box-shadow: 0 0 0px rgba(0,0,0,0);
  }
}`

/**
 * Error prop setting hook
 */

export function usePostalSendFieldErrors({ context, field }: { context?: PostalSendContext; field: string }) {
  const { highlightFieldWithError, fieldWithError } = context ?? {}
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (highlightFieldWithError && field === fieldWithError) {
      ref?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'center', inline: 'center' })
      ref?.current?.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightFieldWithError, field])

  const fieldIsHighlighted = useMemo(
    () => highlightFieldWithError && fieldWithError === field,
    [field, fieldWithError, highlightFieldWithError]
  )

  return {
    ref,
    animation: fieldIsHighlighted ? INVALID_FIELD_ANIMATION : '',
  }
}
