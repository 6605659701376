import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Grid } from '@chakra-ui/react'
import { UiAlert, UiDangerous, UiMenu, useColor, ZCard, ZCardBody, ZHeading, ZText } from '@postal-io/postal-ui'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { useEffect, useMemo } from 'react'
import type { ApprovedPostal, Currency, FulfillmentMap, ImageReference, MarketplaceProduct } from '../../api'
import { Status } from '../../api'
import { useCollectionPermissions } from '../../hooks'
import { PostalImageCollage } from '../Postal'
import { CollectionVariantOption } from './CollectionVariantOption'

//These are important base fields that exist on both postal types
type BasePostalType = {
  id: string
  variants?: BaseVariantType[] | null
  variantOrderFlexibility?: boolean | null
  name: string
  description?: string | null
  category: string
  marketplaceProductId?: string
  currency?: Currency | null
}

type BaseVariantType = {
  id: string
  status: Status
  description?: string | null
  displayPrice?: number | null
  variantName: string
  fulfillmentPartnerList?: FulfillmentMap[] | null
  imageUrls?: ImageReference[] | null
}

interface CollectionItemOptionsProps<T, U> extends Omit<BoxProps, 'onSelect'> {
  item: T
  selectedVariants: U[]
  onSelect: (variants: U[]) => void
}

export const CollectionItemOptions = <T extends BasePostalType, U extends BaseVariantType>({
  item,
  selectedVariants,
  onSelect,
  ...rest
}: CollectionItemOptionsProps<T, U>) => {
  //Not a fan of the sequential type castings. "item" can either be an approvePostal or marketplaceProduct btthe hook only recognizes the the approvedPostal.
  const { canCreateShared } = useCollectionPermissions(
    !!item.marketplaceProductId ? (item as unknown as ApprovedPostal) : null
  )

  const availableVariants = useMemo(
    () => item.variants?.filter((v) => v.status === Status.Active) || [],
    [item?.variants]
  ) as U[]

  // If we haven't already selected something
  // If there is only 1 variant, automatically select it.
  // If we have variantOrderFlexibility, automatically select all
  useEffect(() => {
    if (!selectedVariants.length && (item.variantOrderFlexibility || availableVariants.length === 1)) {
      onSelect(availableVariants)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableVariants])

  // Toggle variant in the selected options
  const handleSelect = (variant: U) => {
    const exists = selectedVariants.some((v) => v.id === variant.id)
    if (exists) {
      onSelect(selectedVariants.filter((v) => v.id !== variant.id))
    } else {
      onSelect([...selectedVariants, variant])
    }
  }
  const { Color } = useColor()

  return (
    <Box
      h="100%"
      w="100%"
      {...rest}
    >
      <Grid
        w="100%"
        templateColumns="calc(60% - 1rem) calc(40% - 1rem)"
        gridGap="2rem"
      >
        <ZCard
          variant="form"
          overflow="hidden"
        >
          <ZCardBody p={0}>
            <Box p={8}>
              <PostalImageCollage postal={item as unknown as ApprovedPostal | MarketplaceProduct} />
            </Box>
            <Box p={8}>
              <ZHeading
                as="h2"
                size="h5"
              >
                {item.name}
              </ZHeading>
              <ZText
                my={2}
                color="gray.700"
                whiteSpace="break-spaces"
              >
                {item.description && <UiDangerous html={item?.description} />}
              </ZText>
            </Box>
          </ZCardBody>
        </ZCard>
        <ZCard variant="form">
          <ZCardBody>
            <ZHeading
              mb={8}
              as="h2"
              size="h4"
            >
              <Flex alignItems="center">
                Select Available Options
                <ZInfoTooltip
                  placement="right"
                  ml={2}
                  label="Recipients will choose from available options when accepting the order."
                />
              </Flex>
              <ZText
                pt={2}
                fontSize="body-sm"
              >
                Any applicable shipping or sales tax will be added when you send.
              </ZText>
            </ZHeading>

            {availableVariants?.length ? (
              <UiMenu isOpen>
                <Box mx={-3}>
                  {availableVariants?.map((variant) => {
                    const isSelected = selectedVariants.some((v) => v.id === variant.id)
                    return (
                      <CollectionVariantOption
                        key={variant.id}
                        variant={variant}
                        item={item}
                        onClick={() => handleSelect(variant)}
                        isSelected={isSelected}
                        brandingColor={Color('atomicBlue.400')}
                      />
                    )
                  })}
                </Box>{' '}
              </UiMenu>
            ) : canCreateShared ? (
              <UiAlert status="warning">
                This product is currently unavailable. It may become available in the future, so we recommend you keep
                it as approved. You can update its status to “draft” if you don’t want users to see it while it is
                unavailable.
              </UiAlert>
            ) : (
              <UiAlert status="warning">This item is currently unavailable.</UiAlert>
            )}
          </ZCardBody>
        </ZCard>
      </Grid>
    </Box>
  )
}
