import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { Module } from 'api'
import { ModulesDocument } from 'api'
import { useFlags } from 'launchdarkly-react-client-sdk'
import type { Permission } from 'lib/permissions'
import { useMemo } from 'react'
import { useAclCheck } from './useAclCheck'
import { useSession } from './useSession'
export type { AclFilter, ModulePermission } from './useAclCheck'

/**
 * Launch Darkly flags can be overridden by passing in environmental variables
 * Helpful for running locally with launch darkly disabled
 *
 * REACT_APP_LAUNCH_DARKLY_DISABLED="featureUserCreatedCollections,featureSomeOther"
 * REACT_APP_LAUNCH_DARKLY_ENABLED="someAmazingThing"
 */

const ldDisabled = process.env.REACT_APP_LAUNCH_DARKLY_DISABLED?.split(/,\s?/).reduce((obj, cur) => {
  if (cur) obj[cur] = false
  return obj
}, {} as Record<string, boolean>)

const ldEnabled = process.env.REACT_APP_LAUNCH_DARKLY_ENABLED?.split(/,\s?/).reduce((obj, cur) => {
  if (cur) obj[cur] = true
  return obj
}, {} as Record<string, boolean>)

/**
 * useAcl
 *
 * a Hook for checking the currently logged in user's permissions
 *
 * const {
 *   aclCheck,
 *   aclFilter,
 *   error,
 *   flags,
 *   getMeta,
 *   getModule,
 *   hasFeature,
 *   hasFlag,
 *   hasPermission,
 *   hasRole,
 *   isLoading,
 *   modules,
 *   refetch
 * } = useAcl()
 *
 * const { hasPermission, aclCheck, getMeta } = useAcl()
 * const canRead = hasPermission('profile.read')
 * const canDoThing = aclCheck({ feature: 'thing', module: 'postals.send' })
 * const maxItems = getMeta('collections')?.maximumItems
 *
 */
export const useAcl = () => {
  const { session } = useSession()
  const baseFlags = useFlags()
  const flags = useMemo(() => ({ ...baseFlags, ...ldEnabled, ...ldDisabled }), [baseFlags])
  const { error, isLoading, refetch, data } = useGraphqlQuery(ModulesDocument, {}, { enabled: !!session })
  const modules = useMemo(() => (data?.modules || []) as Module[], [data?.modules])
  const roles = useMemo(() => session?.roles || [], [session])
  const subscriptions = useMemo(() => session?.subscriptions || [], [session])
  const permissions = useMemo(() => (session?.permissions || []) as Permission[], [session?.permissions])
  const checks = useAclCheck({ roles, flags, modules, subscriptions, permissions })
  return { ...checks, error, isLoading, refetch }
}
