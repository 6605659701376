import type { BoxProps } from '@chakra-ui/react'
import { Box, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlMutation } from '@postal-io/postal-graphql'
import type { Column, OrderByProps, UiCardProps } from '@postal-io/postal-ui'
import {
  internalProgressBarProps,
  internalTableProps,
  UiDialog,
  UiSSDataTable,
  useAlertError,
  ZButton,
  ZCheckbox,
} from '@postal-io/postal-ui'
import type { PostalFulfillment } from 'api'
import {
  BulkApproveOrdersDocument,
  BulkDenyOrdersDocument,
  FulfillmentStatus,
  SearchContactPostalFulfillmentsDocument,
  SearchPostalFulfillmentsDocument,
} from 'api'

import { ALL_ORDERS_COLUMNS, MAGICLINK_APPROVALS_COLUMNS } from 'components/Orders/ordersColumns'
import { PostalFulfillmentCard } from 'components/Postal/PostalFulfillmentCard'
import { useAcl, useBackgroundQueue } from 'hooks'
import React, { useCallback, useMemo, useState } from 'react'

const LIMIT = 100
const PAGE_SIZE = 10

const DEFAULT_SORT = { key: 'created', direction: 'desc' } as OrderByProps

interface PostalFulfillmentsTableV2Props extends UiCardProps {
  userId?: string
  contactId?: string
  magicLinkId?: string
  campaignId?: string
  playbookId?: string
  playbookDefinitionId?: string
  columns?: Column[]
  useContactSearch?: boolean
  pageSize?: number
  showHideDenied?: boolean
  hideDenied?: boolean
  containerProps?: BoxProps
}

export const PostalFulfillmentsTable: React.FC<PostalFulfillmentsTableV2Props> = ({
  userId,
  contactId,
  magicLinkId,
  campaignId,
  playbookId,
  playbookDefinitionId,
  columns,
  useContactSearch,
  pageSize = PAGE_SIZE,
  showHideDenied = false,
  hideDenied,
  containerProps,
}) => {
  const { hasFeature } = useAcl()
  const { queue } = useBackgroundQueue()
  const rowItemDisclosure = useDisclosure()
  const [postalFulfillment, setPostalFulfillment] = useState<PostalFulfillment>()

  const [orderBy, setOrderBy] = useState(DEFAULT_SORT)
  const orderByString = useMemo(() => {
    return orderBy?.key && orderBy.direction ? `${orderBy.key}_${orderBy.direction.toUpperCase()}` : undefined
  }, [orderBy.direction, orderBy.key])

  const hasMagicLinkApproval = hasFeature('manuallyApproveLinks')

  const myColumns = useMemo(() => {
    return columns?.length
      ? columns
      : magicLinkId && hasMagicLinkApproval
      ? MAGICLINK_APPROVALS_COLUMNS
      : ALL_ORDERS_COLUMNS
  }, [columns, hasMagicLinkApproval, magicLinkId])

  const hasMagicLink = hasFeature('links')
  const filteredColumns = useMemo(() => {
    return hasMagicLink ? myColumns : myColumns.filter((c: any) => c.key !== 'magicLinkName')
  }, [hasMagicLink, myColumns])

  const variables = useMemo(() => {
    const _variables = {
      filter: {},
      limit: LIMIT,
      orderBy: orderByString,
    } as any
    if (contactId) {
      if (useContactSearch) {
        _variables.contactId = contactId
      } else {
        _variables.filter.contactId = { eq: contactId }
      }
    }
    if (userId) _variables.filter.userId = { eq: userId }
    if (magicLinkId) {
      _variables.filter.magicLinkId = { eq: magicLinkId }
      if (showHideDenied && hideDenied) {
        _variables.filter.status = { ne: FulfillmentStatus.OrderDenied }
      }
    }
    if (campaignId) _variables.filter.campaignId = { eq: campaignId }
    if (playbookId) _variables.filter.playbookId = { eq: playbookId }
    if (playbookDefinitionId) _variables.filter.playbookDefinitionId = { eq: playbookDefinitionId }
    return _variables
  }, [
    campaignId,
    contactId,
    magicLinkId,
    orderByString,
    playbookDefinitionId,
    playbookId,
    useContactSearch,
    userId,
    hideDenied,
    showHideDenied,
  ])

  const hasSearchProp =
    !!contactId || !!userId || !!magicLinkId || !!campaignId || !!playbookId || !!playbookDefinitionId

  // search postal fulfillments
  const searchPostalFulfillments = useGraphqlInfiniteQuery(SearchPostalFulfillmentsDocument, variables, {
    enabled: !useContactSearch && hasSearchProp,
  })

  // search all contact related fulfillments
  const searchContactPostalFulfillments = useGraphqlInfiniteQuery(SearchContactPostalFulfillmentsDocument, variables, {
    enabled: !!useContactSearch && hasSearchProp,
  })

  const { isFetching, error, hasNextPage, fetchNextPage } = useMemo(() => {
    return useContactSearch ? searchContactPostalFulfillments : searchPostalFulfillments
  }, [searchContactPostalFulfillments, searchPostalFulfillments, useContactSearch])

  // we need to return the data depending on what query we are using
  const postalFulfillments = useMemo(() => {
    return useContactSearch
      ? searchContactPostalFulfillments.mergedData?.searchContactPostalFulfillments
      : searchPostalFulfillments.mergedData?.searchPostalFulfillments
  }, [
    searchContactPostalFulfillments.mergedData?.searchContactPostalFulfillments,
    searchPostalFulfillments.mergedData?.searchPostalFulfillments,
    useContactSearch,
  ])

  const approveOrders = useGraphqlMutation(BulkApproveOrdersDocument)
  const denyOrders = useGraphqlMutation(BulkDenyOrdersDocument)

  const handleApproveOrder = useCallback(
    (id: string) => {
      approveOrders.mutateAsync({ fulfillmentIds: [id] }).then((d) => queue(d.bulkApproveOrders))
    },
    [approveOrders, queue]
  )

  const handleDenyOrder = useCallback(
    (id: string) => {
      denyOrders.mutateAsync({ fulfillmentIds: [id] }).then((d) => queue(d.bulkDenyOrders))
    },
    [denyOrders, queue]
  )

  const handleItemClick = useCallback(
    (row: PostalFulfillment) => {
      setPostalFulfillment(row)
      rowItemDisclosure.onOpen()
    },
    [rowItemDisclosure]
  )

  const normalizedRows = useMemo(() => {
    return (
      postalFulfillments?.map((fulfillment) => {
        return {
          ...fulfillment,
          onItemClick: () => handleItemClick(fulfillment),
          ...(magicLinkId
            ? {
                onOrderApprove: (id: string) => handleApproveOrder(id),
                onOrderDeny: (id: string) => handleDenyOrder(id),
              }
            : {}),
        }
      }) ?? []
    )
  }, [handleItemClick, postalFulfillments, handleApproveOrder, handleDenyOrder, magicLinkId])

  useAlertError(error)

  return (
    <>
      <Box
        mt={4}
        mx={-6}
        width="calc(100% + 48px)"
        {...containerProps}
      >
        <UiSSDataTable
          columns={filteredColumns}
          rows={normalizedRows}
          rowKey="id"
          pageSize={pageSize}
          variant="list"
          hasMore={hasNextPage}
          isLoading={isFetching}
          fetchMore={fetchNextPage}
          filter={variables.filter}
          orderBy={orderBy}
          onOrderBy={setOrderBy}
          tableProps={internalTableProps}
          progressBarProps={internalProgressBarProps}
          SelectCheckbox={ZCheckbox}
          HeaderButton={ZButton}
        />
      </Box>
      {postalFulfillment && rowItemDisclosure.isOpen && (
        <UiDialog
          size="6xl"
          title="View Order"
          {...rowItemDisclosure}
        >
          <PostalFulfillmentCard
            postalFulfillment={postalFulfillment}
            onRetryComplete={rowItemDisclosure.onClose}
            onComplete={rowItemDisclosure.onClose}
            mb={0}
          />
        </UiDialog>
      )}
    </>
  )
}
