import { GraphqlBackgroundTaskInvalidations } from '@postal-io/postal-graphql'
import type { BackgroundTask, BackgroundTaskStatus } from 'api'
import { isLocalEnvironment } from 'lib'
import isEmpty from 'lodash/isEmpty'
import type { PropsWithChildren } from 'react'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { useQueryClient } from 'react-query'

export const CONTACT_INVALIDATIONS = ['getContact', 'searchContacts', 'searchContactsV2']
export const POSTAL_INVALIDATIONS = ['getApprovedPostal', 'searchApprovedPostals', 'marketplaceSearch']
export const BILLING_INVALIDATIONS = ['getFunds', 'getBillingAccount', 'getBalanceRemaining']

export const useBackgroundTaskInvalidation = () => {
  const queryClient = useQueryClient()
  return useCallback(
    (action: string) => {
      if (isLocalEnvironment) console.group(`invalidate: ${action}`)
      GraphqlBackgroundTaskInvalidations.get(action)?.forEach((op) => {
        if (isLocalEnvironment) console.log(op)
        queryClient.invalidateQueries(op)
      })
      if (isLocalEnvironment) console.groupEnd()
    },
    [queryClient]
  )
}

export interface QueuedBackgroundTask {
  task: BackgroundTask
  callback?: (task: BackgroundTask) => void
  triggers?: BackgroundTaskStatus[]
}

const useBackgroundQueueContext = () => {
  const queryClient = useQueryClient()
  const [tasks, setTasks] = useState<QueuedBackgroundTask[]>([])
  const queue = useCallback(
    (
      task?: QueuedBackgroundTask['task'] | null,
      callback?: QueuedBackgroundTask['callback'],
      triggers?: QueuedBackgroundTask['triggers']
    ) => {
      task && !isEmpty(task) && setTasks([...tasks, { task, callback, triggers }])
    },
    [tasks]
  )

  const remove = useCallback(
    (task: BackgroundTask) => {
      setTasks(tasks.filter((t) => t.task.id !== task.id))
    },
    [tasks]
  )

  // The default delay is 2500ms because the max wait time on an interactive queue
  // is 2000ms.
  const invalidate = useCallback(
    (queries: string | string[], delay = 2500) => {
      const operations = [queries].flat()
      setTimeout(() => {
        operations.forEach((op) => queryClient.invalidateQueries(op))
      }, delay)
    },
    [queryClient]
  )

  return { tasks, queue, remove, invalidate }
}

type BackgroundQueueContext = ReturnType<typeof useBackgroundQueueContext>

const BackgroundContext = createContext<BackgroundQueueContext | null>(null)

export const BackgroundProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const value = useBackgroundQueueContext()
  return <BackgroundContext.Provider value={value}>{children}</BackgroundContext.Provider>
}
export const useBackgroundQueue = () => {
  const context = useContext(BackgroundContext) as BackgroundQueueContext
  return context
}
