import { Box, Flex, Grid, Stack, useClipboard, useDisclosure, VStack } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import {
  FontWeight,
  UiIconParty2,
  UiProgressBar,
  useAlerts,
  ZButton,
  ZCard,
  ZCardHeader,
  ZInput,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import type { BillingAccount } from 'api'
import { RequestAddFundsByInvoiceDocument, RequestAddFundsByInvoiceV2Document } from 'api'
import { ZAlert, ZInfoTooltip } from 'components/Common/ZComponents'
import { getCurrencySymbol, useAcl } from 'hooks'
import { isNumber } from 'lodash'
import React, { useEffect, useState } from 'react'

const POSTAL_NAME = 'postal.io'
const POSTAL_ACCOUNT = '011984766555'

interface BillingRequestByInvoiceProps extends ZCardProps {
  billingAccount?: BillingAccount
}

interface DataProps {
  amountCents: number
  billingAccountId: string
}

export const BillingRequestByInvoice: React.FC<BillingRequestByInvoiceProps> = ({ billingAccount, ...rest }) => {
  const { hasFeature } = useAcl()
  const noMinFundsUploadAmt = hasFeature('noMinimumFunds')
  const useV2Endpoint = hasFeature('payByInvoiceV2')

  const Alert = useAlerts()
  const confirmRequest = useDisclosure()
  const [amount, setAmount] = useState<number | null>(null)
  const [data, setData] = useState<DataProps>()

  const { onCopy: onCopyName, hasCopied: hasCopiedName } = useClipboard(POSTAL_NAME)
  const { onCopy: onCopyAccount, hasCopied: hasCopiedAccount } = useClipboard(POSTAL_ACCOUNT)
  useEffect(() => {
    if (hasCopiedName || hasCopiedAccount) Alert.success('Copied to clipboard')
  }, [Alert, hasCopiedAccount, hasCopiedName])

  const requestByInvoice = useGraphqlMutation(RequestAddFundsByInvoiceDocument)
  const requestByInvoiceV2 = useGraphqlMutation(RequestAddFundsByInvoiceV2Document)

  const mutation = useV2Endpoint ? requestByInvoiceV2 : requestByInvoice

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Number(e.target.value))
  }

  const handleSubmit = (e: React.FormEvent) => {
    if (!billingAccount) return
    requestByInvoice.reset()
    e.preventDefault()
    setData({
      amountCents: (amount ?? 0) * 100,
      billingAccountId: billingAccount.id,
    })
    confirmRequest.onOpen()
  }

  const confirmSubmit = async () => {
    if (!data) return
    try {
      await (useV2Endpoint ? requestByInvoiceV2.mutateAsync({ input: data }) : requestByInvoice.mutateAsync(data))
      setAmount(null)
    } catch (err) {}
  }

  return (
    <>
      {noMinFundsUploadAmt ? (
        <InvoiceNoLimit
          amount={amount}
          requestByInvoice={requestByInvoice}
          onCopyName={onCopyName}
          onCopyAccount={onCopyAccount}
          handleSubmit={handleSubmit}
          handleInput={handleInput}
          {...rest}
        />
      ) : (
        <InvoiceWithLimit
          billingAccount={billingAccount}
          amount={amount}
          requestByInvoice={requestByInvoice}
          onCopyName={onCopyName}
          onCopyAccount={onCopyAccount}
          handleSubmit={handleSubmit}
          handleInput={handleInput}
          {...rest}
        />
      )}

      {confirmRequest.isOpen && (
        <ZModal
          size="lg"
          {...confirmRequest}
          scrollBehavior="inside"
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Pay by Invoice</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody>
              <Stack
                spacing={4}
                w="100%"
              >
                {!mutation.isSuccess && (
                  <ZText>
                    Confirm the amount you'd like to pay:{' '}
                    <ZMoney
                      fontWeight={FontWeight.Bold}
                      amount={amount as number}
                      currency={billingAccount?.currency}
                    />
                    . We will email you an invoice.
                  </ZText>
                )}

                {!mutation.isIdle && (
                  <Box>
                    {mutation.isLoading && <UiProgressBar />}
                    <ZAlert
                      hideClose
                      status={mutation.isLoading ? 'info' : mutation.isError ? 'error' : 'success'}
                      aria-label="Request Successful"
                    >
                      {mutation.isLoading
                        ? 'Sending Request'
                        : mutation.isError
                        ? mutation.error?.extensions?.postalErrorMessage
                        : 'Your request to pay via invoice has been sent. We will email you an invoice.'}
                    </ZAlert>
                  </Box>
                )}
              </Stack>
            </ZModalBody>
            <ZModalButtons>
              {!mutation.isSuccess ? (
                <ZButton
                  minW="140px"
                  onClick={confirmSubmit}
                  isLoading={mutation.isLoading}
                  isDisabled={mutation.isLoading}
                  colorScheme="atomicBlue"
                >
                  Confirm
                </ZButton>
              ) : (
                <Box />
              )}
              <ZButton
                colorScheme="atomicGray"
                variant="ghost"
                minW="140px"
                onClick={confirmRequest.onClose}
              >
                {!mutation.isSuccess ? 'Cancel' : 'Close'}
              </ZButton>
            </ZModalButtons>
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}

interface InvoiceRequestProps {
  billingAccount?: BillingAccount
  amount: number | null
  requestByInvoice: any
  onCopyName: () => void
  onCopyAccount: () => void
  handleSubmit: (e: React.FormEvent) => void
  handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const InvoiceNoLimit: React.FC<InvoiceRequestProps> = ({
  billingAccount,
  amount,
  requestByInvoice,
  onCopyName,
  onCopyAccount,
  handleSubmit,
  handleInput,
  ...rest
}) => {
  const isInvalid = isNumber(amount) && amount < 1

  return (
    <>
      <ZCard
        h="fit-content"
        w="100%"
        maxW="900px"
        mx="auto"
        variant="form"
        {...rest}
      >
        {requestByInvoice.isSuccess ? (
          <Flex
            flexDir="column"
            alignItems="center"
            py={8}
          >
            <UiIconParty2 fontSize="100px" />
            <ZText
              fontWeight="normal"
              textAlign="center"
              size="lg"
              px={8}
              mt={4}
            >
              You'll receive an invoice within 24 hours for your funding request.
            </ZText>
            <ZButton
              size="md"
              variant="ghost"
              h="100%"
              alignSelf="flex-end"
              mx={8}
              mt={8}
              onClick={requestByInvoice.reset}
            >
              Reset
            </ZButton>
          </Flex>
        ) : (
          <>
            {' '}
            <ZCardHeader
              fontSize="lg"
              fontWeight={350}
              color="atomicGray.600"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              p={8}
              pb={0}
            >
              Pay by Invoice{' '}
              <ZInfoTooltip
                size="lg"
                label="Enter the amount you'd like to prepay. We will email you an invoice for the amount you submit."
              />
            </ZCardHeader>
            <VStack
              spacing={6}
              align="stretch"
              p={8}
            >
              <form onSubmit={handleSubmit}>
                <Grid
                  templateColumns="1fr 100px"
                  gap={4}
                >
                  <ZInput
                    type="number"
                    min={1}
                    value={amount || ''}
                    onInput={handleInput}
                    placeholder={'Enter Amount'}
                    isInvalid={isInvalid}
                  />
                  <ZButton
                    type="submit"
                    colorScheme="atomicBlue"
                    isDisabled={!amount}
                  >
                    Submit
                  </ZButton>
                </Grid>
              </form>
            </VStack>
          </>
        )}
      </ZCard>
    </>
  )
}

export const InvoiceWithLimit: React.FC<InvoiceRequestProps> = ({
  billingAccount,
  amount,
  requestByInvoice,
  onCopyName,
  onCopyAccount,
  handleSubmit,
  handleInput,
  ...rest
}) => {
  const currencySymbol = getCurrencySymbol(billingAccount?.currency)
  const isInvalid = isNumber(amount) && amount < 2000

  return (
    <>
      <ZCard
        h="fit-content"
        w="100%"
        maxW="900px"
        mx="auto"
        variant="form"
        {...rest}
      >
        {requestByInvoice.isSuccess ? (
          <Flex
            flexDir="column"
            alignItems="center"
            py={8}
          >
            <UiIconParty2 fontSize="100px" />
            <ZText
              fontWeight="normal"
              textAlign="center"
              size="lg"
              px={8}
              mt={4}
            >
              You'll receive an invoice within 24 hours for your funding request.
            </ZText>
            <Box p={8}>
              <ZText>
                Please note that the minimum amount to transfer is{' '}
                <ZMoney
                  fontWeight="bold"
                  amount={2000}
                  currency={billingAccount?.currency}
                />
              </ZText>
            </Box>
            <ZButton
              size="md"
              variant="ghost"
              h="100%"
              alignSelf="flex-end"
              mx={8}
              onClick={requestByInvoice.reset}
            >
              Reset
            </ZButton>
          </Flex>
        ) : (
          <>
            {' '}
            <ZCardHeader
              fontSize="lg"
              color="atomicGray.600"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              p={8}
              pb={0}
              gap={2}
            >
              Request to Fund by Invoice
              <ZInfoTooltip
                label={`Amounts funded via invoice must be ${currencySymbol}2,000 or larger`}
                placement="top"
                hasArrow
              />
            </ZCardHeader>
            <VStack
              spacing={6}
              align="stretch"
              p={8}
            >
              <form onSubmit={handleSubmit}>
                <Grid
                  templateColumns="1fr 100px"
                  gap={4}
                >
                  <ZInput
                    type="number"
                    min={2000}
                    size="lg"
                    value={amount || ''}
                    onInput={handleInput}
                    placeholder={`Amount - ${currencySymbol ?? ''}2,000 minimum`}
                    isInvalid={isInvalid}
                  />
                  <ZButton
                    size="md"
                    type="submit"
                    colorScheme="atomicBlue"
                    h="100%"
                    isDisabled={!amount}
                  >
                    Request
                  </ZButton>
                </Grid>
              </form>
            </VStack>
          </>
        )}
      </ZCard>
    </>
  )
}
