import { Divider } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  useAlerts,
  ZButton,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import { AccountPicker } from 'components/Common/AccountPicker'
import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import type { NamedProductAccess } from '../../api'
import { NamedProductAccessListDocument } from '../../api'
import { useSession } from '../../hooks'

interface AccountSwitcherProps {
  isOpen: boolean
  onClose: () => void
}

export const AccountSwitcher: React.FC<AccountSwitcherProps> = ({ isOpen, onClose }) => {
  const Alert = useAlerts()
  const { session, switchSession } = useSession()
  const { productAccessId } = session

  const namedProductAccessList = useGraphqlQuery(NamedProductAccessListDocument)

  // we seem to be getting some bad data back here so lets
  // filter for all the things we need
  const productAccessList = useMemo(() => {
    return (namedProductAccessList.data?.namedProductAccessList?.filter((item) => {
      return (
        item?.productAccess?.product === process.env.REACT_APP_PRODUCT_ID &&
        !isEmpty(item?.productAccess?.roles) &&
        !isEmpty(item?.productAccess?.id) &&
        !isEmpty(item?.accountName)
      )
    }) || []) as NamedProductAccess[]
  }, [namedProductAccessList.data?.namedProductAccessList])

  const switchAccount = async (account: NamedProductAccess) => {
    if (account.productAccess.id === productAccessId) return
    try {
      await switchSession(account.productAccess.id)
    } catch (err) {
      Alert.error(err)
    } finally {
      onClose()
    }
  }

  return (
    <ZModal
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ZModalOverlay />
      <ZModalContent>
        <ZModalHeader>Switch Account</ZModalHeader>
        <ZModalCloseButton />
        <ZModalBody
          pb={10}
          pt={0}
        >
          <ZText mb={5}>Please select the Account or Team you would like to switch to:</ZText>
          <Divider mb={-5} />
          <AccountPicker
            productAccessId={productAccessId}
            productAccessList={productAccessList}
            onSelect={switchAccount}
            isLoading={namedProductAccessList.isLoading}
          />
        </ZModalBody>
        <ZModalButtons
          mt={0}
          justifyContent="flex-end"
        >
          <ZButton
            variant="solid"
            colorScheme="atomicBlue"
            w="160px"
            onClick={onClose}
          >
            Cancel
          </ZButton>
        </ZModalButtons>
      </ZModalContent>
    </ZModal>
  )
}
