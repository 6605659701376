import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type {
  UiSlideContainerProps,
  UiSlideImageProps,
  UiSlideProps,
  UiSlideThumbnailsProps,
} from '@postal-io/postal-ui'
import {
  FontWeight,
  UiCard,
  UiCardHeader,
  UiDangerous,
  UiLink,
  UiMoney,
  UiSlide,
  UiSlideArrows,
  UiSlideCard,
  UiSlideCards,
  UiSlideContainer,
  UiSlideDots,
  UiSlideImage,
  UiSlideThumbnails,
  UiTextTruncate,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, DesignTemplate, MarketplaceProduct } from 'api'
import { GetApprovedPostalDocument } from 'api'
import type { CollectionItem } from 'components/Collections/utils'
import { getPriceRange, groupCollectionItems } from 'components/Collections/utils'
import { useAssets, usePostalImages, usePrintPreview } from 'hooks'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row } from '.'
import fallbackSrc from '../../assets/fallback.png'

export interface PostalDirectMailCarouselProps extends UiSlideProps {
  designTemplate?: DesignTemplate | null
  userMessage?: string
  imageProps?: UiSlideImageProps
  containerProps?: UiSlideContainerProps
}
export const PostalDirectMailCarousel: React.FC<PostalDirectMailCarouselProps> = ({
  designTemplate,
  userMessage,
  imageProps,
  containerProps,
  ...rest
}) => {
  const { front, back } = usePrintPreview(designTemplate, {
    userMessage,
  })
  const images = useMemo(() => {
    return [
      { url: front, alt: 'Side 1', title: 'Side 1' },
      { url: back, alt: 'Side 2', title: 'Side 2' },
    ]
  }, [back, front])
  return (
    <UiSlide {...rest}>
      <UiSlideContainer
        maxH="600px"
        bg="white"
        borderWidth="1px"
        borderColor="gray.200"
        {...containerProps}
      >
        <UiSlideCards>
          {images.map((image, idx) => (
            <UiSlideCard key={idx}>
              <UiSlideImage
                src={image.url}
                title={image.title}
                objectFit="contain"
                fallbackSrc={fallbackSrc}
                decoding="async"
                {...imageProps}
              />
            </UiSlideCard>
          ))}
        </UiSlideCards>
        <UiSlideArrows />
      </UiSlideContainer>
      <UiSlideThumbnails images={images.map(({ url, title }) => ({ src: url, title }))} />
    </UiSlide>
  )
}

export interface PostalImageCarouselProps extends UiSlideProps {
  postal?: ApprovedPostal | MarketplaceProduct | null
  imageProps?: UiSlideImageProps
  containerProps?: UiSlideContainerProps
  thumbnailsProps?: Omit<UiSlideThumbnailsProps, 'images'>
}
export const PostalImageCarousel: React.FC<PostalImageCarouselProps> = ({
  postal,
  imageProps,
  containerProps,
  thumbnailsProps,
  ...rest
}) => {
  const postalImages = usePostalImages(postal)
  const { assetUrlSrc } = useAssets()
  const images = postalImages.map((image) => {
    const { src, fallbackSrc } = assetUrlSrc(image.url)
    return {
      src,
      fallbackSrc,
      title: image.description || '',
    }
  })

  return (
    <UiSlide {...rest}>
      <UiSlideContainer
        maxH="600px"
        bg="white"
        borderWidth="1px"
        borderColor="gray.200"
        {...containerProps}
      >
        <UiSlideCards>
          {images.map((image, idx) => (
            <UiSlideCard key={idx}>
              <UiSlideImage
                src={image.src}
                fallbackSrc={image.fallbackSrc}
                title={image.title}
                objectFit="contain"
                decoding="async"
                {...imageProps}
              />
            </UiSlideCard>
          ))}
        </UiSlideCards>
        <UiSlideArrows />
      </UiSlideContainer>
      <UiSlideThumbnails
        images={images}
        {...thumbnailsProps}
      />
    </UiSlide>
  )
}

export interface BasicPostalImageCarouselProps extends UiSlideProps {
  postal?: ApprovedPostal | MarketplaceProduct | null
  imageProps?: UiSlideImageProps
  containerProps?: UiSlideContainerProps
}
export const BasicPostalImageCarousel: React.FC<BasicPostalImageCarouselProps> = ({
  postal,
  imageProps,
  containerProps,
  ...rest
}) => {
  const postalImages = usePostalImages(postal)
  const { assetUrlSrc } = useAssets()
  const images = postalImages.map((image) => {
    const { src, fallbackSrc } = assetUrlSrc(image.url)
    return {
      src,
      fallbackSrc,
      title: image.description || '',
    }
  })
  return (
    <UiSlide {...rest}>
      <UiSlideContainer
        maxH="600px"
        bg="white"
        borderWidth="1px"
        borderColor="gray.200"
        {...containerProps}
      >
        <UiSlideCards>
          {images.map((image, idx) => (
            <UiSlideCard key={idx}>
              <UiSlideImage
                src={image.src}
                fallbackSrc={image.fallbackSrc}
                objectFit="contain"
                decoding="async"
                {...imageProps}
              />
            </UiSlideCard>
          ))}
        </UiSlideCards>
      </UiSlideContainer>
    </UiSlide>
  )
}

export interface PostalCollectionCarouselProps extends UiSlideProps {
  approvedPostalId: string
  containerProps?: UiSlideContainerProps
}
export const PostalCollectionCarousel: React.FC<PostalCollectionCarouselProps> = ({
  approvedPostalId,
  containerProps,
  ...rest
}) => {
  const navigate = useNavigate()

  const getPostal = useGraphqlQuery(GetApprovedPostalDocument, { id: approvedPostalId })
  const collection = getPostal.data?.getApprovedPostal
  const items = collection ? groupCollectionItems(collection) : []

  const handleNav = () => {
    navigate(`/items/collections/${collection?.id}`, { state: { returnTo: 'MagicLink' } })
  }

  return (
    <UiCard
      isLoading={getPostal.isLoading}
      {...rest}
    >
      <Flex direction="column">
        <UiCardHeader>
          <Flex alignItems="center">
            <Text
              fontSize="2xl"
              mr={2}
            >
              Collection -{' '}
            </Text>
            <Text color="gray.400">{items.length} items</Text>
          </Flex>
        </UiCardHeader>
        <UiSlide>
          <UiSlideContainer bg="white">
            <UiSlideCards mt={8}>
              {items.map((item) => (
                <UiSlideCard key={item.marketplaceProductId}>
                  <CollectionItemCard item={item} />
                </UiSlideCard>
              ))}
            </UiSlideCards>
            <UiSlideArrows />
            <UiSlideDots />
          </UiSlideContainer>
        </UiSlide>
        <UiLink
          alignSelf="center"
          mt={6}
          onClick={handleNav}
        >
          <Flex alignItems="center">
            View Collection <ChevronRightIcon boxSize="20px" />
          </Flex>
        </UiLink>
      </Flex>
    </UiCard>
  )
}

interface CollectionItemCardProps {
  item: CollectionItem
}
const CollectionItemCard: React.FC<CollectionItemCardProps> = ({ item }) => {
  const { assetUrlSrc } = useAssets()
  const { src, fallbackSrc } = assetUrlSrc(item.defaultImage)
  const { minPrice, maxPrice, hasMixedPrice } = getPriceRange(item.variants)

  return (
    <Flex gridGap={8}>
      <Image
        data-testid="PostalBlock_Card_Image"
        borderWidth="2px"
        src={src}
        fallbackSrc={fallbackSrc}
        h="auto"
        w="auto"
        maxH="400px"
        objectFit="contain"
      />

      <Stack
        spacing={4}
        minW="150px"
      >
        <Row label="Name">{item.productName}</Row>
        {item.description && (
          <Row label="Description">
            <Box
              whiteSpace="break-spaces"
              mt={2}
              overflow="hidden"
              display="-webkit-box"
              style={{
                WebkitLineClamp: 4,
                WebkitBoxOrient: 'vertical',
              }}
            >
              <UiDangerous html={item.description} />
            </Box>
          </Row>
        )}
        {item.variants.length > 1 && (
          <Row label="Options">
            <UiTextTruncate>{item.variants.map((v) => v.variantName).join(', ')}</UiTextTruncate>
          </Row>
        )}
        <Row label="Price">
          <UiMoney
            fontWeight={FontWeight.Bold}
            currency={item.currency}
            cents={minPrice}
            round
            color="tertiary.500"
          />
          {hasMixedPrice && (
            <>
              <Box as="span">-</Box>
              <UiMoney
                currency={item.currency}
                fontWeight={FontWeight.Bold}
                cents={maxPrice || 0}
                round
                color="tertiary.500"
              />
            </>
          )}
        </Row>
      </Stack>
    </Flex>
  )
}
