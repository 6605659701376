import { Divider, Flex, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import { joinStrings, UiCardButton, useAlertError, ZCard, ZCardHeader, ZHeading, ZText } from '@postal-io/postal-ui'
import { GetAccountDocument } from 'api'
import React from 'react'
import { MdOutlineEdit } from 'react-icons/md'
import { CompanyEdit } from './AccountCompanyEdit'
export const AccountCompanyBlock: React.FC<UiCardProps> = (props) => {
  const editCompanyDisclosure = useDisclosure()
  const { data, isLoading, error, refetch } = useGraphqlQuery(GetAccountDocument)

  const accountData = data?.getAccount
  const address = accountData?.address
  const { address1, address2, address3, city, state, postalCode, country } = address || {}

  const hasAddress = address1 || address2 || city || state || postalCode || country

  useAlertError(error)

  return (
    <>
      <ZCard
        pos="relative"
        isLoading={isLoading}
        variant="form"
        {...props}
      >
        <ZCardHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={8}
          pb={4}
        >
          Company Info
          <UiCardButton
            aria-label="Edit company info"
            icon={<MdOutlineEdit />}
            color="atomicBlue.500"
            onClick={editCompanyDisclosure.onOpen}
            w="27px"
            h="27px"
            p={0}
            variant="link"
          />
        </ZCardHeader>

        <Stack
          spacing={8}
          p={8}
        >
          <Flex
            direction="column"
            data-testid="Account_Info_AccountName"
          >
            <ZHeading
              mb={2}
              fontSize="md"
              fontWeight={500}
            >
              Account Name
            </ZHeading>
            <ZText
              fontSize="md"
              color="atomicGray.600"
            >
              {accountData?.name || 'Not Set'}
            </ZText>
          </Flex>

          <Divider />

          <Flex
            direction="column"
            data-testid="Account_Info_DisplayName"
          >
            <ZHeading
              mb={2}
              fontSize="md"
              fontWeight={500}
            >
              Display Name
            </ZHeading>
            <ZText
              fontSize="md"
              color="atomicGray.600"
            >
              {accountData?.displayName || 'Not Set'}
            </ZText>
          </Flex>

          <Divider />

          <Flex
            direction="column"
            data-testid="Account_Info_CompanyAddress"
          >
            <ZHeading
              mb={2}
              fontSize="md"
              fontWeight={500}
            >
              Primary Company Address
            </ZHeading>
            {hasAddress ? (
              <>
                {address1 && (
                  <Text
                    fontSize="md"
                    color="atomicGray.600"
                  >
                    {address1}
                  </Text>
                )}
                {address2 && (
                  <Text
                    fontSize="md"
                    color="atomicGray.600"
                  >
                    {address2}
                  </Text>
                )}
                {address3 && (
                  <Text
                    fontSize="md"
                    color="atomicGray.600"
                  >
                    {address3}
                  </Text>
                )}
                {(city || state || postalCode) && (
                  <ZText
                    fontSize="md"
                    color="atomicGray.600"
                  >
                    {joinStrings([city, state], ', ')}
                    {(city || state) && ' '}
                    {postalCode}
                  </ZText>
                )}
                {country && (
                  <ZText
                    fontSize="md"
                    color="atomicGray.600"
                  >
                    {country}
                  </ZText>
                )}
              </>
            ) : (
              <ZText
                fontSize="md"
                color="atomicGray.600"
              >
                Not Set
              </ZText>
            )}
          </Flex>
        </Stack>
      </ZCard>
      {accountData && editCompanyDisclosure.isOpen && (
        <CompanyEdit
          isOpen={editCompanyDisclosure.isOpen}
          onClose={editCompanyDisclosure.onClose}
          onComplete={refetch}
          accountData={accountData}
        />
      )}
    </>
  )
}
