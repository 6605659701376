import { printPreview } from 'api/rest'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

export const usePrintPreview = (config: any, data?: any) => {
  const frontQuery = useQuery(
    ['printPreview', { config, side: 'front', data }],
    () => printPreview({ config, side: 'front', data }),
    { enabled: !isEmpty(config) }
  )
  const backQuery = useQuery(
    ['printPreview', { config, side: 'back', data }],
    () => printPreview({ config, side: 'back', data }),
    { enabled: !isEmpty(config) }
  )
  const front = useMemo(() => frontQuery.data || '', [frontQuery.data])
  const back = useMemo(() => backQuery.data || '', [backQuery.data])
  return { front, back }
}
