import { Flex, FormControl, VStack } from '@chakra-ui/react'
import { useAlerts, ZButton, ZFormLabel, ZText, ZTextarea } from '@postal-io/postal-ui'
import { ContactEventsRequestType } from 'api'
import { ZDialog } from 'components/Common/ZComponents'
import React from 'react'
import { useImmer } from 'use-immer'

interface ContactEventsTeamModalProps {
  approvedPostalId: string
  isOpen: boolean
  onClose: () => void
  submitRequest: any
}

export const ContactEventsTeamModal: React.FC<ContactEventsTeamModalProps> = ({
  approvedPostalId,
  isOpen,
  onClose,
  submitRequest,
}) => {
  const Alert = useAlerts()
  const [form, setForm] = useImmer<Record<string, any>>({
    message: '',
    requestedDate: '',
  })

  const handleFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setForm((draft: Record<string, any>) => {
      draft[name] = value
    })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await submitRequest.mutateAsync({
        approvedPostalId: approvedPostalId,
        message: form.message,
        requestType: ContactEventsRequestType.Contact,
      } as any)
    } catch (e) {
      Alert.error(e)
    }
    onClose()
  }

  return (
    <ZDialog
      colorScheme="gray"
      title={'Contact the Postal Events Team'}
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
    >
      <Flex
        justifyContent={'center'}
        mb={4}
      >
        <ZText fontSize={'md'}>
          Your message will be emailed to the Postal Events team. Replies to this message will be received at the email
          address associated with this account.
        </ZText>
      </Flex>
      <form onSubmit={handleSubmit}>
        <VStack spacing={8}>
          <FormControl
            maxW="700px"
            id="message"
            isRequired
          >
            <ZFormLabel>Message</ZFormLabel>
            <ZTextarea
              width="100%"
              value={form.message || ''}
              name="message"
              onChange={handleFormInput}
              placeholder={'Provide your message and any details you’d like to include to the Events team'}
            />
          </FormControl>
          <ZButton
            type="submit"
            minW="100px"
            colorScheme="atomicBlue"
            mt={8}
          >
            Submit
          </ZButton>
        </VStack>
      </form>
    </ZDialog>
  )
}
