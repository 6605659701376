import type { ApprovedPostalInputItem } from 'api'
import { CATEGORY } from 'components/Postals'
import { useCallback } from 'react'
import { useAcl } from './useAcl'

interface UseApprovedPostalVersionData {
  variants?: string[]
  items?: ApprovedPostalInputItem[]
}
export const useApprovedPostalVersion = () => {
  const { hasFeature } = useAcl()
  return useCallback(
    (category: string, data?: UseApprovedPostalVersionData) => {
      switch (category) {
        case CATEGORY.Events:
          return hasFeature('approvedPostalV2Events', false)
            ? { version: '2', items: data?.items }
            : { version: '1', variants: data?.variants }
        case CATEGORY.Collection:
          return hasFeature('userCreatedCollections')
            ? { version: '2', items: data?.items }
            : { version: '1', items: data?.items }
        default:
          return hasFeature('approvedPostalV2Items')
            ? { version: '2', items: data?.items }
            : { version: '1', variants: data?.variants }
      }
    },
    [hasFeature]
  )
}
