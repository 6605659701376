import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react'
import {
  UiButton,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerFooterButtons,
  UiDrawerHeader,
  UiDrawerOverlay,
  UiDropzone,
  UiLink,
  UiSubNavbar,
  useAlerts,
  ZButton,
  ZHeading,
  ZText,
} from '@postal-io/postal-ui'
import type { FileDataMappingInput } from 'api'
import api from 'api/rest'
import { ZLink } from 'components/Common/ZComponents'
import React, { useState } from 'react'
import { MdOutlineUploadFile } from 'react-icons/md'
import { ContactsImportMapping } from './ContactsImportMapping'

const HELP_URL = 'https://help.postal.com/helpcenter/s/article/Importing-Contacts-from-CSV-or-Excel'

export const ContactsImport: React.FC = () => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const Alert = useAlerts()
  const [isLoading, setIsLoading] = useState(false)
  const [mappings, setMappings] = useState<FileDataMappingInput[] | undefined>(undefined)

  const mappingDisclosure = useDisclosure()

  const closeAll = () => {
    setMappings(undefined)
  }

  const onDrop = async (files: any) => {
    if (files.length < 1) return Alert.warning('Please drop only supported file types')
    setIsLoading(true)
    try {
      setMappings(await api.importContacts(files))
      mappingDisclosure.onOpen()
      onClose()
    } catch (err) {
      Alert.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Popover
        closeOnBlur={false}
        isOpen={isOpen}
        onClose={onClose}
        returnFocusOnClose={false}
      >
        <PopoverTrigger>
          <ZLink
            color="atomicBlue.400"
            fontWeight="bold"
            onClick={onToggle}
          >
            <MdOutlineUploadFile
              size={20}
              style={{ marginRight: 5 }}
            />
            Import
          </ZLink>
        </PopoverTrigger>
        <PopoverContent
          minW="400px"
          data-testid="ContactsImport_popover"
          boxShadow="0 10px 15px rgba(0,0,0,.15) !important"
          mr={2}
        >
          <PopoverArrow />
          <PopoverCloseButton data-testid="ContactsImport_popover_close" />
          <PopoverBody>
            <Box p={4}>
              <ZHeading
                as="h4"
                size="h5"
                mb={5}
              >
                Import from a file
              </ZHeading>
              <ZText
                mb={6}
                color="atomicGray.500"
              >
                Need help? Check out our{' '}
                <UiLink
                  fontFamily="Lexend"
                  color="atomicBlue.400"
                  href={HELP_URL}
                  isExternal
                >
                  help article
                </UiLink>
                {', '}
                which contains an import template.
              </ZText>
              <UiDropzone
                onDrop={onDrop}
                isLoading={isLoading}
                accept={{
                  'text/csv': ['.csv', '.xls', '.xlsx'],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                }}
                data-testid="dropZone"
              >
                <p>Drag files here or click to select</p>
                <em>(Only .csv, .xls, or .xlsx files)</em>
                <ZButton
                  bg="atomicBlue.400"
                  mt={4}
                >
                  Upload Files
                </ZButton>
              </UiDropzone>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      {mappings && (
        <UiDrawer
          size="lg"
          isOpen={mappingDisclosure.isOpen}
          onClose={mappingDisclosure.onClose}
        >
          <UiDrawerOverlay />
          <UiDrawerContent>
            <UiDrawerCloseButton />
            <UiDrawerHeader>Import Contacts</UiDrawerHeader>
            <UiSubNavbar />
            <UiDrawerBody>
              <ContactsImportMapping
                mappings={mappings}
                onClose={closeAll}
              />
            </UiDrawerBody>
            <UiDrawerFooter>
              <UiDrawerFooterButtons>
                <UiButton
                  colorScheme="gray"
                  onClick={mappingDisclosure.onClose}
                >
                  Cancel
                </UiButton>
              </UiDrawerFooterButtons>
            </UiDrawerFooter>
          </UiDrawerContent>
        </UiDrawer>
      )}
    </>
  )
}
