import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Flex, HStack, Icon, MenuItem, Stack, useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiMenuButtonProps } from '@postal-io/postal-ui'
import {
  joinStrings,
  UiEnhancedTooltip,
  UiIconPostalEngageLogo,
  UiIconUser,
  UiLargeMenuMore,
  UiMenu,
  UiMenuButton,
  UiMenuDivider,
  UiMenuItem,
  UiMenuList,
  UiNavbarLogo,
  UiTruncate,
  useAlerts,
  ZButton,
  ZMoney,
  ZNavbar,
  ZText,
} from '@postal-io/postal-ui'
import type { Currency, ProductAccess } from 'api'
import { GetBudgetRemainingDocument, GetFundsDocument, GetTeamByIdDocument, Role } from 'api'
import { NavbarLink, NavbarMenuLinkV2, NavbarMenuV2, zNavbarLinkStyles } from 'components/Common/NavbarLink'
import { SidebarTeamRow } from 'components/PostalSend/PostalSendSidebarSpendAs'
import { NavbarFavoritesButton, NavbarHelpCenterButton } from 'components/PostalSend/SecondaryNavbar'
import { useHasSwagEditor } from 'components/SwagEditor'
import {
  AnalyticsEvent,
  AnalyticsEventV2,
  useAcl,
  useAnalyticsSend,
  useBudget,
  useHelp,
  useLogout,
  useMe,
  useSession,
} from 'hooks'
import isEmpty from 'lodash/isEmpty'
import { useMemo } from 'react'
import { MdOutlineArrowDropDown, MdOutlineSavings } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { useBalance, usePrimaryCurrency, useTeamOrAccountSettings } from '../../hooks/useBudget'
import { AccountSwitcher } from './AccountSwitcher'
import { AppSwitcher } from './AppSwitcher'

type NavbarLinkItem = {
  label: string
  to: string
  extraPaths?: string[]
  onClick?: () => void
}

const PRODUCT_ID = process.env.REACT_APP_PRODUCT_ID

export const Navbar = () => {
  const sendAnalytics = useAnalyticsSend()
  const navigate = useNavigate()
  const { openHelp } = useHelp()
  const { hasRole } = useAcl()

  const logout = useLogout()

  const profile = () => navigate('/profile')
  const pickAccount = useDisclosure()

  // Navlink Permissions
  const { hasFeature, hasPermission } = useAcl()
  // const isAdmin = hasPermission('billing.update')
  const hasMarketplace = hasPermission('postals.read')
  const hasEvents = hasFeature('events') && hasPermission('events.read')
  const hasContacts = hasPermission('contacts.read')
  const hasCampaigns = hasPermission('campaigns.read')
  const hasPlaybooks = hasPermission('playbooks.read')
  const hasTriggers = hasPermission('triggers.read') || (hasPermission('postals.send') && hasFeature('userZapier'))
  const hasLinks = hasPermission('links.read') && hasFeature('links')
  const hasCollections = hasPermission('collections.read')
  const hasReporting = hasPermission('reporting.read')
  const hasAppSwitcher = hasFeature('productSuiteNav')
  const hasSwagEditor = useHasSwagEditor()
  const hasFavorites = hasRole(Role.User)
  // const hasBudgetHistory = hasFeature('budgetHistory')

  const { me } = useMe()

  const filteredProductAccess = (me?.productAccess || [])
    .filter(({ product }: any) => product === PRODUCT_ID)
    .filter(({ roles }: any) => !isEmpty(roles))
  const showAccountPicker = filteredProductAccess.length > 1

  // ideally these are the top level navbar items
  const topLevelNavItems = useMemo(() => {
    const items = [] as NavbarLinkItem[]
    if (hasMarketplace)
      items.push({
        label: 'Marketplace',
        to: '/items',
        onClick: () => sendAnalytics({ event: AnalyticsEvent.MarketplacePageClicked }),
      })
    if (hasEvents) items.push({ label: 'Events', to: '/events' })
    if (hasCampaigns) items.push({ label: 'Orders', to: '/orders' })
    if (hasContacts) items.push({ label: 'Contacts', to: '/contacts' })
    if (hasReporting)
      items.push({
        label: 'Reporting',
        to: '/reporting',
      })
    return items
  }, [hasCampaigns, hasContacts, hasEvents, hasMarketplace, hasReporting, sendAnalytics])

  const overflowMenuNavItems = useMemo(() => {
    const items = [] as NavbarLinkItem[]
    if (hasLinks) items.push({ label: 'MagicLinks', to: '/links' })
    if (hasPlaybooks) items.push({ label: 'Subscriptions', to: '/subscriptions' })
    if (hasTriggers) items.push({ label: 'Triggers', to: '/triggers' })
    if (hasCollections) items.push({ label: 'Collections', to: '/collections' })
    if (hasSwagEditor)
      items.push({
        label: 'Swag Creator',
        to: '/swag-creator',
        onClick: () => sendAnalytics({ event: AnalyticsEventV2.NavBar_SwagEditor }),
      })
    items.push({ label: 'Paper Plane', to: '/paperplane' })

    return items
  }, [hasCollections, hasLinks, hasPlaybooks, hasSwagEditor, hasTriggers, sendAnalytics])

  // push any top level items that won't fit into the overflow list
  const numberOfTopLevelItems = useBreakpointValue({ base: 0, md: 3, lg: 4, xl: 5 })
  const displayedTopLevelItems = topLevelNavItems.slice(0, numberOfTopLevelItems)
  const displayedOverflowItems = [...topLevelNavItems.slice(numberOfTopLevelItems), ...overflowMenuNavItems]

  return (
    <Box>
      <ZNavbar
        sx={{
          '& a, button > span > p': {
            '&.active': {
              color: 'atomicBlue.200',
            },
          },
        }}
        fontFamily="Lexend"
        overflowX="auto"
        center={
          <UiNavbarLogo
            as={Link}
            sx={zNavbarLinkStyles}
            // @ts-ignore
            to={'/items'}
          >
            <UiIconPostalEngageLogo
              w="140px"
              h="auto"
            />
          </UiNavbarLogo>
        }
        left={
          <HStack
            spacing={5}
            display={{ base: 'none', md: 'flex' }}
          >
            {hasAppSwitcher && (
              <>
                <AppSwitcher />
                <VerticalDivider />
              </>
            )}
            {displayedTopLevelItems.map(({ label, to, onClick, extraPaths }) => (
              <NavbarLink
                key={label}
                to={to}
                onClick={onClick}
                extraPaths={extraPaths}
              >
                {label}
              </NavbarLink>
            ))}
            {displayedOverflowItems.length && (
              <NavbarMenuV2 paths={displayedOverflowItems.map((item) => [item.to, ...(item?.extraPaths ?? [])]).flat()}>
                {displayedOverflowItems.map(({ label, to }) => (
                  <NavbarMenuLinkV2
                    key={label}
                    to={to}
                  >
                    {label}
                  </NavbarMenuLinkV2>
                ))}
              </NavbarMenuV2>
            )}
          </HStack>
        }
        right={
          <HStack
            spacing={5}
            alignItems="center"
          >
            <PostalBudgetDisplay />
            {hasFavorites && <NavbarFavoritesButton />}
            <NavbarHelpCenterButton />
            <NavbarProfileMenu display={{ base: 'none', md: 'flex' }}>
              <HStack
                alignItems="center"
                spacing={10}
                px={5}
                mb={4}
              >
                <Stack spacing={-1}>
                  <ZText
                    fontSize="body-lg"
                    whiteSpace="nowrap"
                  >
                    <UiTruncate text={joinStrings([me?.firstName, me?.lastName])} />{' '}
                    <ZText
                      as="span"
                      fontSize="body-md"
                      color="atomicBlue.400"
                    >
                      {hasRole(Role.Admin) ? '(Admin)' : '(User)'}
                    </ZText>
                  </ZText>
                  {me?.teamName && (
                    <ZText
                      color="atomicGray.500"
                      whiteSpace="nowrap"
                    >
                      <UiTruncate text={me?.teamName} />
                    </ZText>
                  )}
                </Stack>
                <ZText whiteSpace="nowrap">
                  <UiTruncate text={me?.accountName ?? ''} />
                </ZText>
              </HStack>
              <UiMenuDivider />
              <MenuItem onClick={profile}>Profile</MenuItem>
              {showAccountPicker && <MenuItem onClick={pickAccount.onOpen}>Switch Account</MenuItem>}
              <MenuItem onClick={openHelp}>Help</MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </NavbarProfileMenu>
            <UiLargeMenuMore display={{ base: 'block', md: 'none' }}>
              <MenuItem onClick={profile}>Profile</MenuItem>
              {showAccountPicker && <MenuItem onClick={pickAccount.onOpen}>Switch Account</MenuItem>}

              <UiMenuDivider />
              {[...topLevelNavItems, ...overflowMenuNavItems].map(({ label, to, onClick, extraPaths }) => (
                <NavbarMenuLinkV2
                  key={label}
                  to={to}
                  extraPaths={extraPaths}
                  onClick={onClick}
                >
                  {label}
                </NavbarMenuLinkV2>
              ))}
              <UiMenuDivider />
              <MenuItem onClick={openHelp}>
                Help{' '}
                <ExternalLinkIcon
                  ml={2}
                  h="12px"
                  w="12px"
                />
              </MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </UiLargeMenuMore>
          </HStack>
        }
      />
      {showAccountPicker && pickAccount.isOpen && (
        <AccountSwitcher
          isOpen={pickAccount.isOpen}
          onClose={pickAccount.onClose}
        />
      )}
    </Box>
  )
}

const VerticalDivider: React.FC<any> = (props) => (
  <Box
    mx={2}
    h="28px"
    w="1px"
    bg="atomicGray.500"
    {...props}
  />
)

export const NavbarProfileMenu: React.FC<UiMenuButtonProps> = ({ children, ...rest }) => {
  const disclosure = useDisclosure()

  return (
    <UiMenu
      {...disclosure}
      placement="bottom-end"
    >
      <UiEnhancedTooltip
        label="Profile Menu"
        shouldWrapChildren
        hasArrow
        mt={2}
        isDisabled={disclosure.isOpen}
      >
        <UiMenuButton
          data-testid="NavbarV2_profilemenu_button"
          color="white"
          bg="none"
          borderWidth={0}
          py={3}
          pl={0}
          pr={0}
          minW={0}
          w="18px"
          title=""
          sx={{ '& .chakra-button__icon': { ml: 0 } }}
          _hover={{ color: 'gray.300' }}
          _active={{ color: 'white' }}
          rightIcon={<UiIconUser fontSize="22px" />}
          {...rest}
        />
      </UiEnhancedTooltip>
      <UiMenuList
        color="atomicBlue.400"
        borderRadius={10}
        zIndex={5}
        py={5}
        sx={{
          '& button': { px: 5, color: 'atomicBlue.400' },
          'boxShadow': '0 0 10px rgba(0,0,0,.1)',
          '&:focus': { boxShadow: '0 0 10px rgba(0,0,0,.1) !important' },
        }}
      >
        {children}
      </UiMenuList>
    </UiMenu>
  )
}

export const PostalBudgetDisplay: React.FC = () => {
  const Alert = useAlerts()
  const disclosure = useDisclosure()
  const { me } = useMe()
  const { session, switchSession } = useSession()
  const { productAccessId, accountId } = session
  const { budgetRemaining, currencyCode } = useBudget()
  const { balanceRemaining, showBalance } = useBalance()

  // get team name using teamId
  const teamQuery = useGraphqlQuery(GetTeamByIdDocument, { id: session.teamId }, { enabled: !!session.teamId })

  // team name, or defaults to account name if logged-in user does not have team
  const entityName = useMemo(() => {
    return session.teamId ? teamQuery.data?.getTeamById?.name : me?.accountName
  }, [me?.accountName, session.teamId, teamQuery.data?.getTeamById?.name])

  const teams = useMemo(
    () =>
      me?.productAccess?.filter(
        (account: ProductAccess) =>
          account.product === PRODUCT_ID && account.accountId === accountId && account.roles?.includes(Role.User)
      ),
    [me?.productAccess, accountId]
  )

  const switchTeam = async (teamId: string) => {
    if (teamId === productAccessId) return
    try {
      await switchSession(teamId)
    } catch (err) {
      Alert.error(err)
    } finally {
      disclosure.onClose()
    }
  }

  const maxNameLength = useBreakpointValue({ base: 15, sm: 30 })

  return (
    <UiMenu
      {...disclosure}
      placement="bottom-end"
    >
      <UiEnhancedTooltip
        label="Switch Team"
        isDisabled={disclosure.isOpen}
        shouldWrapChildren
      >
        <UiMenuButton
          as={ZButton}
          h="24px"
          fontSize="sm"
          variant="link"
          position="relative"
          color={{ base: 'atomicGray.900', sm: 'white !important' }}
          bg="none"
          _hover={{
            color: { base: 'atomicGray.900', sm: 'white' },
            opacity: 0.8,
            bg: 'none',
            borderColor: 'none',
          }}
          leftIcon={
            <MdOutlineSavings
              viewBox="2 2 20 20"
              fontSize="16px"
            />
          }
          rightIcon={
            <Icon
              as={MdOutlineArrowDropDown}
              fontSize="body-lg"
              viewBox="2 2 20 20"
              color="inherit"
              transform={disclosure.isOpen ? 'rotate(-180deg)' : ''}
              transition="transform 0.2s"
            />
          }
        >
          <Flex
            w="100%"
            justifyContent="space-between"
          >
            <ZText
              fontWeight={500}
              color="inherit"
              whiteSpace="nowrap"
              mr={1}
            >
              <UiTruncate
                length={maxNameLength}
                text={entityName ?? ''}
              />
            </ZText>
            <ZMoney
              display={{ base: 'inline', sm: 'none', lg: 'inline' }}
              currency={currencyCode ?? 'USD'}
              cents={showBalance ? balanceRemaining : budgetRemaining}
              color="inherit"
            />
          </Flex>
        </UiMenuButton>
      </UiEnhancedTooltip>

      <UiMenuList
        borderRadius={10}
        zIndex={5}
        maxH="min(415px, calc(100vh - 120px))"
        overflowY="scroll"
      >
        {teams?.map((team) => (
          <UiMenuItem
            key={team.teamId}
            onClick={() => switchTeam(team.id)}
          >
            <TeamRow
              teamId={team.teamId as string}
              selected={(team.teamId ?? null) === (session.teamId ?? null)}
              accountId={session.accountId}
              accountName={session.accountName}
              userId={session.userId}
            />
          </UiMenuItem>
        ))}
      </UiMenuList>
    </UiMenu>
  )
}
interface TeamRowProps {
  teamId: string
  accountId: string
  accountName: string
  userId: string
  selected: boolean
}
const TeamRow: React.FC<TeamRowProps> = ({ teamId, accountId, accountName, userId, selected }) => {
  const { hasRole } = useAcl()
  const { data: budget } = useGraphqlQuery(GetBudgetRemainingDocument, {
    accountId,
    teamId,
    userId,
  })
  const { data: team } = useGraphqlQuery(GetTeamByIdDocument, { id: teamId }, { enabled: !!teamId })

  const settings = useTeamOrAccountSettings({ teamId })
  const { currencyCode } = usePrimaryCurrency(settings)

  const billingAccountId = useMemo(
    () => settings?.billingAccountIds?.find((acct) => acct.currency === currencyCode)?.billingAccountId,
    [currencyCode, settings?.billingAccountIds]
  )
  const { data: getFunds } = useGraphqlQuery(
    GetFundsDocument,
    { billingAccountId: billingAccountId as string },
    { enabled: !!billingAccountId }
  )

  const teamBalance = getFunds?.getFunds ?? 0
  const amt = budget?.getBudgetRemaining?.budgetRemaining ?? 0
  const budgetMode = budget?.getBudgetRemaining?.applicableBudget?.budget?.mode

  return (
    <SidebarTeamRow
      brandingColor="atomicBlue.400"
      selected={selected}
      name={team?.getTeamById?.name || accountName || ''}
      budget={amt}
      budgetMode={budgetMode}
      balance={teamBalance}
      currency={currencyCode as Currency}
      isAdmin={hasRole(Role.Admin)}
      insufficientBudget={false}
    />
  )
}
