import { Box, Flex, Tooltip } from '@chakra-ui/react'
import { createId } from '@paralleldrive/cuid2'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { arrayMoveImmutable, UiSortableItem, UiSortableList, useAlerts, useColor } from '@postal-io/postal-ui'
import { SearchApprovedPostalsDocument } from 'api'
import { uniq } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { MdArrowDownward } from 'react-icons/md'
import { CATEGORY } from '../Postals'
import { PlaybookDefinitionStep } from './PlaybookDefinitionStep'

const getDayFromSteps = (steps: any[], stepIdx: number) => {
  return steps.reduce((sum, step, _stepIdx) => {
    return _stepIdx <= stepIdx ? sum + Number(step?.delay) : sum
  }, 0)
}

interface PlaybookDefinitionSortableStepsV2Props {
  steps: any[]
  sortedStepsRef?: any
  isOpen?: boolean
  handleEditStep?: (step: any, idx: number) => void
  deleteStep?: (_idx: number) => void
  setSteps?: (e: any) => void
}
export const PlaybookDefinitionSortableSteps: React.FC<PlaybookDefinitionSortableStepsV2Props> = ({
  steps,
  isOpen,
  setSteps,
  sortedStepsRef,
  handleEditStep,
  deleteStep,
}) => {
  const { colorCode } = useColor()
  const ids = useMemo(() => {
    return uniq(steps?.map((step: any) => step.approvedPostalId) || [])
  }, [steps])

  const { data: postalData } = useGraphqlQuery(
    SearchApprovedPostalsDocument,
    { filter: { id: { in: ids } } },
    { enabled: !!ids.length }
  )
  const approvedPostals = postalData?.searchApprovedPostals
  const Alert = useAlerts()
  const [isMoved, setIsMoved] = useState(false)

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setSteps && setSteps(arrayMoveImmutable(steps, oldIndex, newIndex))
  }

  const setStep = (stepIdx: number, newStep: any) => {
    const newSteps = steps.map((step, _idx) => (_idx === stepIdx ? { ...step, delay: newStep.delay } : step))
    setSteps && setSteps(newSteps)
  }

  const normalizedSteps = useMemo(() => {
    return (
      steps
        ?.map((item) => {
          const postal = approvedPostals?.find((postal) => postal?.id === item?.approvedPostalId)
          return {
            ...item,
            id: createId(),
            category: postal?.category,
            approvedPostal: postal,
          }
        })
        .filter(Boolean) ?? []
    )
  }, [approvedPostals, steps])

  const stepRefs = sortedStepsRef?.current || []

  useEffect(() => {
    if (normalizedSteps && sortedStepsRef && normalizedSteps?.length !== sortedStepsRef?.current?.length) {
      //should not cause a re-render
      sortedStepsRef.current = [...steps]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [normalizedSteps])

  useEffect(() => {
    if (
      isOpen &&
      !isMoved &&
      steps.length === stepRefs?.length &&
      !steps.every((item, idx) => item.delay === stepRefs[idx].delay)
    ) {
      setIsMoved(true)
      Alert.info('Step moved. Make sure to adjust the delays of your steps.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, sortedStepsRef, isOpen, isMoved])

  return (
    <UiSortableList
      items={normalizedSteps}
      onSortEnd={onSortEnd}
    >
      <div style={{ width: '100%' }}>
        {normalizedSteps?.map((step: any, idx: number) => (
          <Box key={step.id}>
            {idx > 0 && (
              <Flex
                data-testid="PlaybooksSortableSteps_tooltipContainer"
                justify="center"
                w="100%"
              >
                <Tooltip
                  px={2}
                  bg="secondary.500"
                  placement="left"
                  hasArrow
                  label={`${
                    step?.delay === 0 ? 'No Delay Set' : step?.delay === 1 ? '+1 Day' : '+' + step?.delay + ' Days'
                  }`}
                  aria-label="Delay"
                >
                  <Flex p={2}>
                    <MdArrowDownward
                      size="25px"
                      color={colorCode('atomicBlue.500')}
                    />
                  </Flex>
                </Tooltip>
              </Flex>
            )}

            <UiSortableItem
              key={step.id}
              id={step.id}
              useDragHandle
            >
              <Box
                position="relative"
                maxW="100%"
              >
                <PlaybookDefinitionStep
                  isDelayTrouble={normalizedSteps[idx - 1]?.category === CATEGORY.DirectMail && step?.delay < 7}
                  isDelayMoved={stepRefs[idx] && normalizedSteps[idx]?.delay !== stepRefs[idx]?.delay}
                  index={idx}
                  step={step}
                  stepNumber={idx + 1}
                  day={normalizedSteps ? getDayFromSteps(normalizedSteps, idx) : 0}
                  handleEditStep={handleEditStep}
                  setStep={setStep}
                  deleteStep={deleteStep}
                  showDragger={isOpen ? true : false}
                />
              </Box>
            </UiSortableItem>
          </Box>
        ))}
      </div>
    </UiSortableList>
  )
}
