import { Wrap, WrapItem } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlerts, ZSidebar, ZSidebarBanner } from '@postal-io/postal-ui'
import {
  DataObjectType,
  DeleteIntegrationDocument,
  GetConnectionsDocument,
  MeDocument,
  SearchIntegrationConnectionDocument,
  SearchIntegrationSyncDocument,
} from 'api'
import { CenteredBox } from 'components/Common'
import type { ExternalProvider } from 'components/Integrations'
import {
  ExternalProviders,
  ExternalSystem,
  IntegrationCard,
  IntegrationCardV2,
  IntegrationCardWorkato,
  IntegrationCardWorkatoV2,
} from 'components/Integrations'
import { IntegrationCardSlack } from 'components/Integrations/IntegrationCardSlack'
import { AnalyticsEvent, PageTitle, useAcl, useAnalyticsEvent } from 'hooks'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

export const Integrations: React.FC = () => {
  useAnalyticsEvent({ event: AnalyticsEvent.IntegrationsViewed })

  const Alert = useAlerts()
  const navigate = useNavigate()
  const { aclCheck, hasFeature } = useAcl()

  const meQuery = useGraphqlQuery(MeDocument)
  const authTypes = useMemo(() => meQuery.data?.me?.authTypes || [], [meQuery.data?.me?.authTypes])

  const SyncProviders = useMemo(() => {
    return ExternalProviders.filter(
      (p) => !!p.integration && aclCheck(p.integration) && p.system !== ExternalSystem.Slack
    )
  }, [aclCheck])

  const searchIntegrationSync = useGraphqlQuery(SearchIntegrationSyncDocument, {
    filter: {
      objectType: { in: [DataObjectType.Product, DataObjectType.User] },
      system: { in: SyncProviders.map((p) => p.system) },
    },
  })

  const integrationSyncs = useMemo(
    () => searchIntegrationSync?.data?.searchIntegrationSync || [],
    [searchIntegrationSync?.data?.searchIntegrationSync]
  )

  const getProviderIntegrationSyncs = useCallback(
    (provider: ExternalProvider) => integrationSyncs.filter((p) => p.system === provider.system) || [],
    [integrationSyncs]
  )

  const isProviderAuthenticated = useCallback(
    (provider: ExternalProvider) => {
      return authTypes.includes(provider?.integration?.authType || '')
    },
    [authTypes]
  )

  const WorkatoProviders = useMemo(() => {
    return ExternalProviders.filter((p) => !!p.workato && aclCheck(p.workato))
  }, [aclCheck])

  const handleOnEdit = (provider: ExternalProvider) => {
    navigate(`/integrations/${provider.system}`)
  }

  const getConnectionsQuery = useGraphqlQuery(GetConnectionsDocument)
  const connections = useMemo(
    () => getConnectionsQuery.data?.getConnections ?? [],
    [getConnectionsQuery.data?.getConnections]
  )

  const isLoadingSyncs = meQuery.isLoading || searchIntegrationSync.isLoading
  const isLoadingWorkato = getConnectionsQuery.isLoading

  const hasSlackIntegration = hasFeature('slackIntegration')

  const slackProvider = useMemo(() => {
    return ExternalProviders.filter(
      (p) => !!p.integration && aclCheck(p.integration) && p.system === ExternalSystem.Slack
    )?.[0]
  }, [aclCheck])

  const searchIntegrationConnectionQuery = useGraphqlQuery(
    SearchIntegrationConnectionDocument,
    {
      filter: {
        system: { eq: ExternalSystem.Slack },
      },
    },
    { enabled: hasSlackIntegration }
  )

  const isSlackConnected = !!searchIntegrationConnectionQuery.data?.searchIntegrationConnection?.length
  const isLoadingSlack = searchIntegrationConnectionQuery.isLoading

  const deleteIntegration = useGraphqlMutation(DeleteIntegrationDocument)

  const handleOnDisconnect = async (provider: ExternalProvider) => {
    if (!isSlackConnected) return

    try {
      await deleteIntegration.mutateAsync({ systemName: provider.system })
      Alert.success('Integration removed')
      searchIntegrationConnectionQuery.refetch()
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner title="Integrations" />
        <PageTitle title="Integrations" />
        <Wrap spacing={8}>
          {SyncProviders.map((provider) => {
            return (
              <WrapItem key={provider.system}>
                {hasSlackIntegration ? (
                  <IntegrationCardV2
                    w="330px"
                    provider={provider}
                    isLoading={isLoadingSyncs}
                    isAuthenticated={isProviderAuthenticated(provider)}
                    integrationSyncs={getProviderIntegrationSyncs(provider)}
                    onClick={() => handleOnEdit(provider)}
                  />
                ) : (
                  <IntegrationCard
                    w="330px"
                    provider={provider}
                    isLoading={isLoadingSyncs}
                    isAuthenticated={isProviderAuthenticated(provider)}
                    integrationSyncs={getProviderIntegrationSyncs(provider)}
                    onClick={() => handleOnEdit(provider)}
                  />
                )}
              </WrapItem>
            )
          })}
          {WorkatoProviders.map((provider) => {
            return hasSlackIntegration ? (
              <IntegrationCardWorkatoV2
                key={provider.system}
                w="330px"
                provider={provider}
                isLoading={isLoadingWorkato}
                connections={connections}
                onComplete={() => handleOnEdit(provider)}
              />
            ) : (
              <IntegrationCardWorkato
                key={provider.system}
                w="330px"
                provider={provider}
                isLoading={isLoadingWorkato}
                connections={connections}
                onComplete={() => handleOnEdit(provider)}
              />
            )
          })}
          {hasSlackIntegration && (
            <WrapItem>
              <IntegrationCardSlack
                w="330px"
                provider={slackProvider}
                isConnected={isSlackConnected}
                isLoading={isLoadingSlack}
                onDisconnect={() => handleOnDisconnect(slackProvider)}
              />
            </WrapItem>
          )}
        </Wrap>
      </ZSidebar>
    </CenteredBox>
  )
}
