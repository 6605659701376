import { SimpleGrid } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiFormControl,
  ZFormLabel,
  ZHeading,
  ZInput,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZSelect,
  useAlerts,
} from '@postal-io/postal-ui'
import type { BillingAccount, CreateBillingAccountInput } from 'api'
import { CreateBillingAccountDocument, Currency, MeDocument } from 'api'
import {
  AutoCompleteCountry,
  AutoCompleteCurrency,
  AutoCompleteState,
  CurrencyEnableFilter,
} from 'components/AutoComplete'
import type { CountryInput } from 'hooks'
import { useAcl, useGetCountry } from 'hooks'
import type { ChangeEvent } from 'react'
import React, { useMemo } from 'react'
import { useImmer } from 'use-immer'
import { Q2_NEW_CURRENCIES } from './BillingAccountsEdit'

interface FormProps {
  firstName: string
  lastName: string
  name: string
  email: string
  phone: string
  address1: string
  address2?: string
  city: string
  state: string
  country: string
  postalCode: string
  currency: Currency
}
interface BillingAccountCreateProps {
  isOpen: boolean
  onClose: () => void
  onCreate: (account: BillingAccount) => void
}

// Currencies used before adding every currency to the enum for currency config rolloout.
// After currency config has been completely rolled out this can be removed.
export const LEGACY_CURRENCIES = [
  Currency.Cad,
  Currency.Eur,
  Currency.Gbp,
  Currency.Usd,
  Currency.Aud,
  Currency.Sgd,
  Currency.Mxn,
]

export const BillingAccountCreate: React.FC<BillingAccountCreateProps> = ({ isOpen, onClose, onCreate }) => {
  const Alert = useAlerts()
  const { hasFeature } = useAcl()
  const hasCurrency = hasFeature('internationalization')
  const hasCurrencyConfig = hasCurrency && hasFeature('currency-config')
  const hasQ2NewCurrencies = hasFeature('q2NewCurrencies')

  // After currency config has been completely rolled out this can be removed.
  const currencyList = Object.values(Currency)
    .sort((a, b) => (a < b ? 1 : -1))
    .filter((currency) => LEGACY_CURRENCIES.includes(currency))
    .filter((currency) => hasQ2NewCurrencies || !Q2_NEW_CURRENCIES.includes(currency))

  const country = useGetCountry()

  const meQuery = useGraphqlQuery(MeDocument)

  const user = useMemo(() => {
    const me = meQuery.data?.me
    return {
      firstName: me?.firstName,
      lastName: me?.lastName,
      email: me?.emailAddress,
      phone: me?.phoneNumber,
    }
  }, [meQuery.data?.me])

  const [form, setForm] = useImmer<FormProps>({
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
    phone: user.phone || '',
    currency: Currency.Usd,
    address1: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    name: '',
  })

  const createBillingAccount = useGraphqlMutation(CreateBillingAccountDocument)

  const handleInput = async ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = target
    setForm((draft: any) => {
      draft[name] = value
    })
  }

  const handleCurrencyChange = (newValue: any) => {
    setForm((draft) => {
      draft.currency = newValue ?? undefined
    })
  }

  const handleStateInputChange = (state: any) => {
    setForm((draft: any) => {
      draft.state = state || ''
    })
  }

  const handleCountryChange = (value: CountryInput | null) => {
    setForm((draft: any) => {
      draft.country = value?.iso3 || ''
    })
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const {
      address1: line1,
      address2: line2,
      city,
      state,
      country,
      postalCode,
      firstName,
      lastName,
      email,
      phone,
      ...billingAccountInfo
    } = form

    const data = {
      ...billingAccountInfo,
      billingContactInfo: {
        firstName,
        lastName,
        email,
        phone,
        address: {
          line1,
          line2,
          city,
          state,
          country,
          postalCode,
        },
      },
    } as CreateBillingAccountInput

    try {
      const res = await createBillingAccount.mutateAsync({ data })
      onClose()
      Alert.success('New billing account created')
      onCreate(res.createBillingAccount)
    } catch (err) {
      Alert.error(err)
    }
  }

  const labelProps = {
    fontSize: 'md',
    mr: 0,
    color: 'atomicGray.600',
  }

  return (
    <ZModal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ZModalOverlay />
      <ZModalContent>
        <form
          id="accountCreate"
          onSubmit={onSubmit}
        >
          <ZModalHeader>Create A Billing Account</ZModalHeader>
          <ZModalCloseButton />

          <ZModalBody>
            <UiFormControl
              id="name"
              mb={5}
              isRequired
            >
              <ZFormLabel {...labelProps}>Account Name</ZFormLabel>
              <ZInput
                placeholder="Please name this account"
                name="name"
                type="text"
                value={form.name}
                onChange={handleInput}
              />
            </UiFormControl>

            {/* Only show if currency config feature is disabled */}
            {/* After currency config has been completely rolled out this can be removed. */}
            {hasCurrency && !hasCurrencyConfig && (
              <UiFormControl
                mb={5}
                isRequired
              >
                <ZFormLabel {...labelProps}>Currency</ZFormLabel>
                <ZSelect
                  defaultValue={form.currency}
                  size="lg"
                  name="currency"
                  onChange={handleInput}
                >
                  {currencyList.map((currency) => (
                    <option
                      key={currency}
                      value={currency}
                    >
                      {currency}
                    </option>
                  ))}
                </ZSelect>
              </UiFormControl>
            )}

            {hasCurrencyConfig && (
              <UiFormControl
                mb={5}
                isRequired
              >
                <ZFormLabel {...labelProps}>Currency</ZFormLabel>
                <AutoCompleteCurrency
                  currencyEnableFilter={CurrencyEnableFilter.BillingAccount}
                  name="currency"
                  onChange={handleCurrencyChange}
                />
              </UiFormControl>
            )}

            <ZHeading
              as="h3"
              size="h6"
              mb={5}
              mt="60px"
            >
              Contact Info
            </ZHeading>
            <SimpleGrid
              columns={2}
              gridGap={5}
            >
              <UiFormControl
                id="firstName"
                mb={5}
                isRequired
              >
                <ZFormLabel {...labelProps}>First Name</ZFormLabel>
                <ZInput
                  name="firstName"
                  type="text"
                  value={form.firstName}
                  onChange={handleInput}
                />
              </UiFormControl>
              <UiFormControl
                id="lastName"
                mb={5}
                isRequired
              >
                <ZFormLabel {...labelProps}>Last Name</ZFormLabel>
                <ZInput
                  name="lastName"
                  type="text"
                  value={form.lastName}
                  onChange={handleInput}
                />
              </UiFormControl>
            </SimpleGrid>

            <SimpleGrid
              columns={2}
              gridGap={5}
            >
              <UiFormControl
                id="email"
                mb={5}
                isRequired
              >
                <ZFormLabel {...labelProps}>Work Email</ZFormLabel>
                <ZInput
                  name="email"
                  type="email"
                  value={form.email}
                  onChange={handleInput}
                />
              </UiFormControl>

              <UiFormControl
                id="email"
                mb={5}
                isRequired
              >
                <ZFormLabel {...labelProps}>Phone Number</ZFormLabel>
                <ZInput
                  name="phone"
                  type="phone"
                  value={form.phone}
                  onChange={handleInput}
                />
              </UiFormControl>
            </SimpleGrid>

            <ZHeading
              as="h4"
              size="h6"
              mb={5}
              mt={10}
            >
              Billing Address
            </ZHeading>

            <UiFormControl
              id="address1"
              isRequired
              mb={5}
            >
              <ZFormLabel {...labelProps}>Address</ZFormLabel>
              <ZInput
                name="address1"
                value={form.address1 ?? ''}
                onChange={handleInput}
              />
            </UiFormControl>

            <UiFormControl
              id="address2"
              mb={5}
            >
              <ZFormLabel {...labelProps}>Address Line 2</ZFormLabel>
              <ZInput
                name="address2"
                value={form.address2 ?? ''}
                onChange={handleInput}
              />
            </UiFormControl>

            <SimpleGrid
              columns={2}
              gridGap={5}
            >
              <UiFormControl
                id="city"
                isRequired
              >
                <ZFormLabel {...labelProps}>City</ZFormLabel>
                <ZInput
                  name="city"
                  value={form.city ?? ''}
                  onChange={handleInput}
                />
              </UiFormControl>

              <UiFormControl
                id="state"
                isRequired
              >
                <ZFormLabel {...labelProps}>State</ZFormLabel>
                <AutoCompleteState
                  onChange={handleStateInputChange}
                  value={form.state}
                  country={form.country ?? country}
                />
              </UiFormControl>

              <UiFormControl
                id="country"
                isRequired
              >
                <ZFormLabel {...labelProps}>Country</ZFormLabel>
                <AutoCompleteCountry onChange={handleCountryChange} />
              </UiFormControl>

              <UiFormControl
                id="postalCode"
                isRequired
              >
                <ZFormLabel {...labelProps}>Postal Code</ZFormLabel>
                <ZInput
                  name="postalCode"
                  value={form.postalCode ?? ''}
                  onChange={handleInput}
                />
              </UiFormControl>
            </SimpleGrid>
          </ZModalBody>
          <ZModalButtons
            confirmProps={{
              type: 'submit',
            }}
            confirmText="Save"
            isConfirmDisabled={createBillingAccount.isLoading}
            isConfirmLoading={createBillingAccount.isLoading}
            onClose={onClose}
          />
        </form>
      </ZModalContent>
    </ZModal>
  )
}
