import React, { forwardRef, useRef } from 'react'
import { Image as CanvasImage } from 'react-konva'
import { useImage } from './useImage'

const selectedProps = {
  stroke: '#171923',
  strokeWidth: 2,
  strokeEnabled: true,
}

export interface ImageProps {
  element: any
  isEditable: boolean
  isSelected: boolean
}

export const Image: React.FC<ImageProps> = forwardRef(({ element, isEditable, isSelected, ...rest }, ref) => {
  const [image] = useImage(element)
  const { location, hidden } = element

  const cursorRef = useRef<any>()

  const handleMouseEnter = (e: any) => {
    if (!isEditable) {
      cursorRef.current = document.body.style.cursor
      document.body.style.cursor = 'not-allowed'
      return
    }
    // add selected props + shadow
    e.target.setAttrs(selectedProps)
    e.target.opacity(0.75)
    e.target.parent.draw()
    document.body.style.cursor = 'pointer'
  }

  const handleMouseLeave = (e: any) => {
    if (!isEditable) {
      document.body.style.cursor = cursorRef.current || 'default'
      cursorRef.current = undefined
      return
    }
    e.target.opacity(1)
    if (!isSelected) e.target.strokeEnabled(false)
    e.target.parent.draw()
    document.body.style.cursor = 'default'
  }

  return (
    <CanvasImage
      image={image}
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      visible={!hidden}
      {...(isSelected && selectedProps)}
      {...location}
      {...rest}
    />
  )
})
