import { useGraphqlFetch } from '@postal-io/postal-graphql'
import type { ZButtonProps } from '@postal-io/postal-ui'
import { downloadLink, useAlerts, ZButton } from '@postal-io/postal-ui'
import React, { useState } from 'react'
import { MdOutlineDownload } from 'react-icons/md'
import { DownloadReportDocument } from '../../../api'

interface DownloadButtonV2Props extends Omit<ZButtonProps, 'children'> {
  reportOutputInstanceId: string
  fileName: string
}
export const DownloadButton: React.FC<DownloadButtonV2Props> = ({ reportOutputInstanceId, fileName, ...rest }) => {
  const Alert = useAlerts()
  const downloadReport = useGraphqlFetch(DownloadReportDocument)
  const [loading, setLoading] = useState(false)

  const handleDownload = async () => {
    try {
      setLoading(true)
      const res = await downloadReport({ reportOutputInstanceId })
      if (res.downloadReport) downloadLink(res.downloadReport, fileName)
    } catch (err) {
      Alert.error(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ZButton
      onClick={handleDownload}
      isLoading={loading}
      isDisabled={loading}
      leftIcon={<MdOutlineDownload size="22px" />}
      colorScheme="atomicBlue"
      minW="150px"
      {...rest}
    >
      Download
    </ZButton>
  )
}
