import { isAfter, parseISO } from 'date-fns'
import { isNumber } from 'lodash'
import type { MagicLink } from '../api'
import { MagicLinkStatus } from '../api'
import { messageVariables } from '../components/Messages/messageVariables'

export const isMagicLinkValid = (link?: MagicLink) => {
  if (!link) return false

  const { status, expirationDate, maxExecutions, metrics } = link
  const linkExecutions = metrics?.linkExecutions || 0

  if (status === MagicLinkStatus.Disabled) return false
  if (isAfter(new Date(), parseISO(expirationDate))) return false
  if (isNumber(maxExecutions) && linkExecutions >= maxExecutions) return false

  return true
}

const VARIABLE_ERROR_BOILERPLATE = `Please ensure you're using valid message variables or variable substitution won't work.`
const AVAILABLE_VARIABLES = messageVariables.map((v) => v.variable)
const COMPLETE_VARIABLE_REG_EXP = /\$\{[\w.]*\}/g
const INCOMPLETE_VARIABLE_REG_EXP = /(\$\{[\w.]*)(?:[^\w.}]|$)/g
const HTML_INTERRUPTED_VARIABLE_REG_EXP =
  /((\$(<\/?[^>]*>)\{[\w.]*(<\/?[^>]*>[\w.]*)*)|(\$\{[\w.]*(<\/?[^>]*>[\w.]*)+))}/g

export const getParsingError = (str?: string | null, isRichText?: boolean) => {
  if (!str) return ''

  const interruptedVariables = isRichText && str?.match(HTML_INTERRUPTED_VARIABLE_REG_EXP)
  const incompleteVariables = str?.match(INCOMPLETE_VARIABLE_REG_EXP)
  const invalidVariables = str
    ?.match(COMPLETE_VARIABLE_REG_EXP)
    ?.filter((v) => !AVAILABLE_VARIABLES.find((a) => a === v))

  if (interruptedVariables) {
    const fixedVariable = interruptedVariables[0].replace(/<[/\w]+>/g, '')
    return `We noticed a partially styled message variable: ${fixedVariable}. Please apply any styling to the entire variable - styling applied to parts of variables will not operate correctly.`
  }
  if (incompleteVariables) {
    const incompleteVariable = incompleteVariables[0].replace(/[,\s.<]$/, '')
    return `We noticed an incomplete message variable: ${incompleteVariable}. ${VARIABLE_ERROR_BOILERPLATE}`
  }
  if (invalidVariables?.length)
    return `We noticed an invalid message variable: ${invalidVariables[0]}. ${VARIABLE_ERROR_BOILERPLATE}`
  return ''
}

export const getParsingErrorNoVariablesAllowed = (str?: string) =>
  str?.match(COMPLETE_VARIABLE_REG_EXP) ? 'Message variables are not supported in this field.' : ''

export const correctHtmlInterruptedMessageVariables = (str?: string) =>
  str?.replace(HTML_INTERRUPTED_VARIABLE_REG_EXP, (substr) => {
    const openingTags = substr.match(/<[^>]*>/g) ?? []
    const closingTags = substr.match(/<\/[^>]*>/g) ?? []
    const variable = substr.replace(/<\/?[^>]*>/g, '')
    return `${openingTags?.join('')}${variable}${closingTags?.join('')}` ?? ''
  })

/*
 * Need to validate custom variables.
 * Retrieve variables from landingPageSubject and landingPage body
 * Compare to "me" query to determine if approperate data is present.
 * */
export const getMessageVariablesErrors = (message?: string | null, meQuery?: Record<string, any>) => {
  const negligentVariables = [] as string[]
  const regex = /[^{}]+(?=})/g
  if (message?.length) {
    const variableList = message.match(regex)
    variableList?.forEach((variable) => {
      if (variable.includes('user')) {
        const [_, key] = variable.split('.')
        if (meQuery && !meQuery[key]) {
          negligentVariables.push(variable)
        }
      }
    })
  }
  if (negligentVariables.length)
    return `These variables are not defined: ${negligentVariables.join(', ')}.
    Please update them in your profile before sending. Otherwise, they will be blank.`
  return undefined
}
