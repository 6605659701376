import type { AlertDialogProps } from '@chakra-ui/react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiChangeEvent } from '@postal-io/postal-ui'
import { useAlertError, useAlerts, ZInputMoney } from '@postal-io/postal-ui'
import type { FormEvent } from 'react'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useImmer } from 'use-immer'
import type { Account } from '../../../api'
import { GetAccountDocument, UpdateAccountDocument } from '../../../api'

interface FormState {
  budgetLowNotificationThreshold: number
  balanceLowNotificationThreshold: number
  dirty: boolean
}
type BudgetBalanceThresholdsModalProps = Pick<AlertDialogProps, 'isOpen' | 'onClose'>
export const BudgetBalanceThresholdsModal: React.FC<BudgetBalanceThresholdsModalProps> = ({ isOpen, onClose }) => {
  const Alert = useAlerts()
  const cancelRef = useRef(null)

  const getAccount = useGraphqlQuery(GetAccountDocument)
  useAlertError(getAccount.error)

  const updateAccount = useGraphqlMutation(UpdateAccountDocument)
  useAlertError(updateAccount.error)

  const { id, notificationSettings } = useMemo(() => {
    return getAccount.data?.getAccount || ({} as Account)
  }, [getAccount.data?.getAccount])

  const [form, setForm] = useImmer<FormState>({
    budgetLowNotificationThreshold: notificationSettings?.budgetLowNotificationThreshold ?? 0,
    balanceLowNotificationThreshold: notificationSettings?.balanceLowNotificationThreshold ?? 0,
    dirty: false,
  })

  // reset form state based on known current value
  const handleReset = useCallback(() => {
    if (!notificationSettings) return
    setForm((draft: Record<string, any>) => {
      draft.budgetLowNotificationThreshold = notificationSettings.budgetLowNotificationThreshold
      draft.balanceLowNotificationThreshold = notificationSettings.balanceLowNotificationThreshold
      draft.dirty = false
    })
  }, [notificationSettings, setForm])

  const handleChange = ({ key, value }: UiChangeEvent<number>) => {
    setForm((draft: Record<string, any>) => {
      draft[key] = value
      draft.dirty =
        draft.budgetLowNotificationThreshold !== notificationSettings?.budgetLowNotificationThreshold ||
        draft.balanceLowNotificationThreshold !== notificationSettings?.balanceLowNotificationThreshold
    })
  }

  // initial state load
  useEffect(() => {
    handleReset()
  }, [handleReset])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = {
      notificationSettings: {
        budgetLowNotificationThreshold: form.budgetLowNotificationThreshold,
        balanceLowNotificationThreshold: form.balanceLowNotificationThreshold,
      },
    }
    try {
      await updateAccount.mutateAsync({ id, data })
      Alert.success('Settings updated')
      onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const isLoading = getAccount.isLoading || updateAccount.isLoading

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      isCentered
    >
      <AlertDialogOverlay />
      <form
        onSubmit={handleSubmit}
        name="notifications"
      >
        <AlertDialogContent>
          <AlertDialogHeader>Notification Thresholds</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <FormControl id="balanceLowNotificationThreshold">
              <FormLabel>Balance Low Threshold</FormLabel>
              <Flex alignItems="center">
                <ZInputMoney
                  name="balanceLowNotificationThreshold"
                  min={0}
                  isDisabled={updateAccount.isLoading}
                  isRequired
                  value={form.balanceLowNotificationThreshold}
                  onChange={handleChange}
                />
              </Flex>
            </FormControl>
            <FormControl
              id="budgetLowNotificationThreshold"
              mt={8}
            >
              <FormLabel>Budget Low Threshold</FormLabel>
              <Flex alignItems="center">
                <ZInputMoney
                  name="budgetLowNotificationThreshold"
                  min={0}
                  isDisabled={updateAccount.isLoading}
                  isRequired
                  value={form.budgetLowNotificationThreshold}
                  onChange={handleChange}
                />
              </Flex>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              type="submit"
              minW={32}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Save
            </Button>
            <Button
              minW={32}
              variant="ghost"
              onClick={onClose}
              ref={cancelRef}
              colorScheme="atomicGray"
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </form>
    </AlertDialog>
  )
}
