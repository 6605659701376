import type { SkeletonProps } from '@chakra-ui/react'
import { Box, Flex, Skeleton } from '@chakra-ui/react'
import { UiDangerous, ZButton, ZHeading } from '@postal-io/postal-ui'
import { useAcl } from 'hooks'
import React, { useContext } from 'react'
import { MdChevronRight } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { AnalyticsEventV2, useAnalyticsSend } from '../../hooks/useAnalytics'
import { getFeaturedData } from '../Postals/data'
import { MarketplaceContext } from './useMarketplace'

interface MarketplaceItemHeroV2Props extends SkeletonProps {
  isLoading?: boolean
}
export const MarketplaceItemHero: React.FC<MarketplaceItemHeroV2Props> = ({ isLoading, ...rest }) => {
  const { flags } = useAcl()
  const navigate = useNavigate()
  const data = getFeaturedData(flags['marketplaceFeatured'])
  const sendAnalytics = useAnalyticsSend()

  const {
    marketplaceFilters: { updateFilter },
  } = useContext(MarketplaceContext)

  const handleClick = () => {
    sendAnalytics({ event: AnalyticsEventV2.Marketplace_BrowseProducts_Clicked })
    updateFilter(data.filter.key, data.filter.value, 0)
    navigate(data.url)
  }

  return (
    <Skeleton
      w="100%"
      isLoaded={!isLoading}
      {...rest}
    >
      <Flex
        w="100%"
        minH="400px"
        p="30px"
        backgroundImage={data.image}
        backgroundPosition="left center"
        backgroundSize="cover"
        justifyContent="flex-end"
        borderRadius="3px"
      >
        <Box
          // actual is 366px
          w="400px"
          bg="whiteAlpha.900"
          backdropFilter="blur(5px)"
          p="30px"
        >
          <ZHeading
            size="h3"
            variant="bold"
            lineHeight="normal"
          >
            {data.name}
          </ZHeading>
          <Box
            mt={5}
            pr={5}
          >
            <UiDangerous
              fontSize="body-md"
              lineHeight="20px"
              fontWeight="normal"
              color="#29404E"
              maxW={{ base: '100%', xl: '630px' }}
              html={data.description}
            />
          </Box>
          <ZButton
            mt={10}
            onClick={handleClick}
            w="fit-content"
            colorScheme="atomicBlue"
            rightIcon={<MdChevronRight />}
            textTransform="none"
          >
            {data.cta}
          </ZButton>
        </Box>
      </Flex>
    </Skeleton>
  )
}
