import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'

export interface IframeProps extends Omit<BoxProps, 'onClick'> {
  src?: string
}
export const Iframe: React.FC<IframeProps> = ({ src, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  const ref = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (!isLoaded) return

    try {
      const document = ref.current?.contentWindow?.document
      if (!document) return

      // hide links and make them not clickable
      document.querySelectorAll('a').forEach((a) => {
        a.style.pointerEvents = 'none'
      })

      document.querySelectorAll('button').forEach((b) => {
        b.style.pointerEvents = 'none'
      })
    } catch (err) {
      // nothing
    }
  }, [isLoaded])

  return (
    <Box
      ref={ref}
      src={src}
      onLoad={() => setIsLoaded(true)}
      as="iframe"
      w="100%"
      h="100%"
      sandbox="allow-scripts allow-same-origin"
      {...props}
    />
  )
}
