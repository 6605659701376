import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { GetAccountDocument, Role } from 'api'
import { useAcl } from 'hooks'
import { useMemo } from 'react'

/**
 * Enterprise customers may want to hide PII of their contacts from non-admins
 */
export function useCanViewContactInfo() {
  const { hasRole } = useAcl()
  const getAccount = useGraphqlQuery(GetAccountDocument)
  const canViewAddresses = useMemo(
    () => hasRole(Role.Admin) || !getAccount.data?.getAccount.accountContactSettings?.redactAddress,
    [getAccount.data?.getAccount.accountContactSettings?.redactAddress, hasRole]
  )

  return { canViewAddresses }
}
