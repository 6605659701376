import { Box, Flex, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import type { UseGraphqlFilterResponse } from '@postal-io/postal-ui'
import {
  GraphqlFilterTransform,
  LexendFontWeight,
  UiSSDataTable,
  useAlertError,
  useGraphqlFilter,
  ZCard,
  ZCardHeader,
} from '@postal-io/postal-ui'
import type { IntegrationTrigger, IntegrationTriggerResult, SearchIntegrationTriggerResultsQueryVariables } from 'api'
import { Role, SearchIntegrationTriggerResultsDocument } from 'api'
import { zMultiSelectCellStyles, zMultiSelectStyles } from 'components/Common/ZComponents'
import { useAcl } from 'hooks'
import { merge } from 'lodash'
import type { ChangeEvent } from 'react'
import React, { useMemo } from 'react'
import { MdSearch } from 'react-icons/md'
import { TriggerActivityFilter } from './TriggerActivityFilter'
import { filteredTriggerColumns, triggerColumns } from './triggerData'

export interface TriggerActivityV2Props {
  trigger?: IntegrationTrigger
}

const transforms = {
  triggerId: GraphqlFilterTransform.Equal,
  contactName: GraphqlFilterTransform.Contains,
  result: GraphqlFilterTransform.Contains,
  created: GraphqlFilterTransform.Between,
}

const LIMIT = 100

export const TriggerActivity: React.FC<TriggerActivityV2Props> = ({ trigger, ...rest }) => {
  const { hasRole } = useAcl()
  const isAdmin = hasRole(Role.Admin) || hasRole(Role.TeamAdmin)

  const staticVariables = { limit: LIMIT }

  const graphqlFilter = useGraphqlFilter<SearchIntegrationTriggerResultsQueryVariables>({
    transforms,
    staticVariables,
    debounce: 400,
  })
  const { variables, orderBy, setOrderBy } = graphqlFilter

  // Update variables to include trigger id when it loads
  const graphqlVariables = useMemo(() => {
    return merge({}, variables, { filter: { triggerId: { eq: trigger?.id } } })
  }, [trigger?.id, variables])

  const { mergedData, isFetching, error, fetchNextPage, hasNextPage } = useGraphqlInfiniteQuery(
    SearchIntegrationTriggerResultsDocument,
    graphqlVariables,
    {
      enabled: !!trigger?.id,
    }
  )

  const triggerActivity = useMemo(() => {
    return (mergedData?.searchIntegrationTriggerResults as IntegrationTriggerResult[]) || []
  }, [mergedData?.searchIntegrationTriggerResults])

  const normalizedRows = useMemo(() => {
    return triggerActivity.map((item) => {
      return {
        id: item.id,
        contactName: item.contactName,
        contactId: item.contactId,
        userId: item.userId,
        contactOwner: item.userLink?.fullName,
        created: item.created,
        result: item.result,
        notes: item.notes,
      }
    })
  }, [triggerActivity])

  useAlertError(error)

  return (
    <ZCard
      border="1px"
      borderColor="gray.200"
      isLoading={!trigger}
      {...rest}
    >
      <ZCardHeader justifyContent="flex-start">Activity</ZCardHeader>
      <Box>
        <TriggerActivityHeader graphqlFilter={graphqlFilter} />
        <UiSSDataTable
          mt={3}
          isLoading={isFetching}
          columns={isAdmin ? triggerColumns : filteredTriggerColumns}
          rows={normalizedRows}
          rowKey="id"
          fetchMore={fetchNextPage}
          hasMore={hasNextPage}
          filter={variables.filter}
          orderBy={orderBy}
          onOrderBy={setOrderBy}
          sx={{
            ...zMultiSelectStyles,
            ...zMultiSelectCellStyles,
            border: 'none',
            boxShadow: 'none',
          }}
        />
      </Box>
    </ZCard>
  )
}

export interface TriggerActivityHeaderProps {
  graphqlFilter: UseGraphqlFilterResponse
}

export const TriggerActivityHeader: React.FC<TriggerActivityHeaderProps> = ({ graphqlFilter }) => {
  const handleInput = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    graphqlFilter.setFilter({ key: target.name, value: target.value })
  }

  return (
    <Flex>
      <InputGroup mr={2}>
        <InputLeftElement
          h="38px"
          color="gray.500"
          width="unset"
          pl={0}
        >
          <MdSearch size={24} />
        </InputLeftElement>
        <Input
          mr={2}
          pl={8}
          type="search"
          name="contactName"
          fontWeight={LexendFontWeight.Regular}
          onChange={handleInput}
          value={graphqlFilter.filter.contactName || ''}
          placeholder="Search Contacts"
          color="gray.500"
          variant="unstyled"
          _focusVisible={{
            boxShadow: 'none !important',
          }}
        />
      </InputGroup>
      <TriggerActivityFilter graphqlFilter={graphqlFilter} />
    </Flex>
  )
}
