import { Box, FormControl, Grid, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiToggle, useAlertError, useAlerts, ZLink, ZText } from '@postal-io/postal-ui'
import type { UserNotifications, UserNotificationsInput } from 'api'
import { MeDocument, Role, UpdateUserNotificationsDocument, UserNotificationChannel } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { useAcl } from 'hooks'
import type { ChangeEvent } from 'react'
import React, { useEffect, useMemo } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useImmer } from 'use-immer'
import { BudgetBalanceThresholdsModal } from './BudgetBalanceThresholdsModal'

interface NotificationGeneralSettingsProps {
  channels: UserNotificationChannel[]
}
export const NotificationGeneralSettings: React.FC<NotificationGeneralSettingsProps> = ({ channels }) => {
  const Alert = useAlerts()
  const { hasRole, hasFeature } = useAcl()
  const hasSlackIntegration = hasFeature('slackIntegration') && channels.includes(UserNotificationChannel.Slack)
  const isAdmin = hasRole(Role.Admin)
  const thresholdsDisclosure = useDisclosure()
  const [form, setForm] = useImmer<UserNotificationsInput>({})

  const meQuery = useGraphqlQuery(MeDocument)
  const notifications = useMemo(
    () => meQuery.data?.me?.notifications || ({} as UserNotifications),
    [meQuery.data?.me?.notifications]
  )
  useAlertError(meQuery.error)

  const updateNotifications = useGraphqlMutation(UpdateUserNotificationsDocument)

  const handleChange = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = target

    const [key, channel] = name.split('.') as [keyof UserNotificationsInput, UserNotificationChannel]
    const channelSet = new Set((form?.[key] ?? []) as UserNotificationChannel[])
    if (checked) channelSet.add(channel)
    else channelSet.delete(channel)
    const channelSetArr = Array.from(channelSet)
    const isEmptyChannel = channelSetArr.length === 0

    setForm((draft: any) => {
      draft[key] = channelSetArr
      if (key === 'balanceLowChannels') draft.balanceLowNotificationsOn = !isEmptyChannel
      if (key === 'budgetLowChannels') draft.budgetLowNotificationsOn = !isEmptyChannel
      if (key === 'giftEmailAcceptedChannels') draft.giftEmailAcceptedNotificationsOn = !isEmptyChannel
      if (key === 'magicLinkAcceptedChannels') draft.magicLinkAcceptedNotificationsOn = !isEmptyChannel
      if (key === 'orderDeliveredChannels') draft.orderDeliveredNotificationsOn = !isEmptyChannel
      if (key === 'autoReloadFailuresChannels') draft.autoReloadFailuresNotificationsOn = !isEmptyChannel
      if (key === 'orderIssuesChannels') draft.orderIssuesNotificationsOn = !isEmptyChannel
    })
  }

  const handleSubmit = useDebouncedCallback(async () => {
    try {
      await updateNotifications.mutateAsync({ data: form })
      Alert.success('Setting Updated')
      meQuery.refetch()
    } catch (err) {
      Alert.error(err)
    }
  }, 1000)

  useEffect(() => {
    if (Object.keys(form).length) handleSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  // checking for both values is done in part for backwards compatibility - otherwise the settings won't show correctly
  const isChecked = useMemo(() => {
    return {
      balanceLowChannels: {
        EMAIL: !!(
          notifications.balanceLowNotificationsOn &&
          notifications?.balanceLowChannels?.includes(UserNotificationChannel.Email)
        ),
        SLACK: !!(
          notifications.balanceLowNotificationsOn &&
          notifications?.balanceLowChannels?.includes(UserNotificationChannel.Slack)
        ),
      },
      budgetLowChannels: {
        EMAIL: !!(
          notifications.budgetLowNotificationsOn &&
          notifications?.budgetLowChannels?.includes(UserNotificationChannel.Email)
        ),
        SLACK: !!(
          notifications.budgetLowNotificationsOn &&
          notifications?.budgetLowChannels?.includes(UserNotificationChannel.Slack)
        ),
      },
      giftEmailAcceptedChannels: {
        EMAIL: !!(
          notifications.giftEmailAcceptedNotificationsOn &&
          notifications?.giftEmailAcceptedChannels?.includes(UserNotificationChannel.Email)
        ),
        SLACK: !!(
          notifications.giftEmailAcceptedNotificationsOn &&
          notifications?.giftEmailAcceptedChannels?.includes(UserNotificationChannel.Slack)
        ),
      },
      magicLinkAcceptedChannels: {
        EMAIL: !!(
          notifications.magicLinkAcceptedNotificationsOn &&
          notifications?.magicLinkAcceptedChannels?.includes(UserNotificationChannel.Email)
        ),
        SLACK: !!(
          notifications.magicLinkAcceptedNotificationsOn &&
          notifications?.magicLinkAcceptedChannels?.includes(UserNotificationChannel.Slack)
        ),
      },
      orderDeliveredChannels: {
        EMAIL: !!(
          notifications.orderDeliveredNotificationsOn &&
          notifications?.orderDeliveredChannels?.includes(UserNotificationChannel.Email)
        ),
        SLACK: !!(
          notifications.orderDeliveredNotificationsOn &&
          notifications?.orderDeliveredChannels?.includes(UserNotificationChannel.Slack)
        ),
      },
      autoReloadFailuresChannels: {
        EMAIL: !!(
          notifications.autoReloadFailuresNotificationsOn &&
          notifications?.autoReloadFailuresChannels?.includes(UserNotificationChannel.Email)
        ),
        SLACK: !!(
          notifications.autoReloadFailuresNotificationsOn &&
          notifications?.autoReloadFailuresChannels?.includes(UserNotificationChannel.Slack)
        ),
      },
      orderIssuesChannels: {
        EMAIL: !!(
          notifications.orderIssuesNotificationsOn &&
          notifications?.orderIssuesChannels?.includes(UserNotificationChannel.Email)
        ),
        SLACK: !!(
          notifications.orderIssuesNotificationsOn &&
          notifications?.orderIssuesChannels?.includes(UserNotificationChannel.Slack)
        ),
      },
    }
  }, [notifications])

  return (
    <>
      <ZText
        gridColumn="span 3"
        pt={4}
        pb={2}
      >
        Send me an email when the following actions occur.
      </ZText>
      <Grid
        templateColumns="1fr 131px 131px"
        gap={8}
        pb={4}
      >
        <ZText gridColumn="2 / 3">Email</ZText>
        {hasSlackIntegration && <ZText gridColumn="3 / 4">Slack</ZText>}
      </Grid>
      <Grid gap={8}>
        <Grid
          templateColumns="1fr 131px 131px"
          gap={8}
        >
          <Box>
            <ZText fontWeight="bold">Balance Low</ZText>
            <ZLink onClick={thresholdsDisclosure.onOpen}>Set balance thresholds here {'>'}</ZLink>
          </Box>
          <FormControl id="balanceLowChannels.EMAIL">
            <UiToggle
              variant="button"
              name="balanceLowChannels.EMAIL"
              isChecked={isChecked.balanceLowChannels.EMAIL}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="balanceLowChannels.SLACK">
            {hasSlackIntegration && (
              <UiToggle
                variant="button"
                name="balanceLowChannels.SLACK"
                isChecked={isChecked.balanceLowChannels.SLACK}
                onChange={handleChange}
              />
            )}
          </FormControl>

          <Box>
            <ZText fontWeight="bold">Budget Low</ZText>
            <ZLink onClick={thresholdsDisclosure.onOpen}>Set budget thresholds here {'>'}</ZLink>
          </Box>
          <FormControl id="budgetLowChannels.EMAIL">
            <UiToggle
              variant="button"
              name="budgetLowChannels.EMAIL"
              isChecked={isChecked.budgetLowChannels.EMAIL}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="budgetLowChannels.SLACK">
            {hasSlackIntegration && (
              <UiToggle
                variant="button"
                name="budgetLowChannels.SLACK"
                isChecked={isChecked.budgetLowChannels.SLACK}
                onChange={handleChange}
              />
            )}
          </FormControl>

          <ZText fontWeight="bold">Gift Email Acceptance</ZText>
          <FormControl id="giftEmailAcceptedChannels.EMAIL">
            <UiToggle
              variant="button"
              name="giftEmailAcceptedChannels.EMAIL"
              isChecked={isChecked.giftEmailAcceptedChannels.EMAIL}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="giftEmailAcceptedChannels.SLACK">
            {hasSlackIntegration && (
              <UiToggle
                variant="button"
                name="giftEmailAcceptedChannels.SLACK"
                isChecked={isChecked.giftEmailAcceptedChannels.SLACK}
                onChange={handleChange}
              />
            )}
          </FormControl>

          {hasFeature('links') && (
            <>
              <ZText fontWeight="bold">MagicLink Acceptance</ZText>
              <FormControl id="magicLinkAcceptedChannels.EMAIL">
                <UiToggle
                  variant="button"
                  name="magicLinkAcceptedChannels.EMAIL"
                  isChecked={isChecked.magicLinkAcceptedChannels.EMAIL}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="magicLinkAcceptedChannels.SLACK">
                {hasSlackIntegration && (
                  <UiToggle
                    variant="button"
                    name="magicLinkAcceptedChannels.SLACK"
                    isChecked={isChecked.magicLinkAcceptedChannels.SLACK}
                    onChange={handleChange}
                  />
                )}
              </FormControl>
            </>
          )}

          <ZText fontWeight="bold">Order Delivered</ZText>
          <FormControl id="orderDeliveredChannels.EMAIL">
            <UiToggle
              variant="button"
              name="orderDeliveredChannels.EMAIL"
              isChecked={isChecked.orderDeliveredChannels.EMAIL}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="orderDeliveredChannels.SLACK">
            {hasSlackIntegration && (
              <UiToggle
                variant="button"
                name="orderDeliveredChannels.SLACK"
                isChecked={isChecked.orderDeliveredChannels.SLACK}
                onChange={handleChange}
              />
            )}
          </FormControl>

          {isAdmin && (
            <>
              <ZText fontWeight="bold">Auto Reload Failures</ZText>
              <FormControl id="autoReloadFailuresChannels.EMAIL">
                <UiToggle
                  variant="button"
                  name="autoReloadFailuresChannels.EMAIL"
                  isChecked={isChecked.autoReloadFailuresChannels.EMAIL}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="autoReloadFailuresChannels.SLACK">
                {hasSlackIntegration && (
                  <UiToggle
                    variant="button"
                    name="autoReloadFailuresChannels.SLACK"
                    isChecked={isChecked.autoReloadFailuresChannels.SLACK}
                    onChange={handleChange}
                  />
                )}
              </FormControl>
            </>
          )}

          {hasFeature('orderBlockedEmail') && (
            <>
              <ZText
                fontWeight="bold"
                display="flex"
                alignItems="center"
              >
                Order Issues{' '}
                <ZInfoTooltip
                  ml={1}
                  display="inline-block"
                  hasArrow
                  label="Issues pertaining to orders blocked by the admin-set blocklist"
                />
              </ZText>
              <FormControl id="orderIssuesChannels.EMAIL">
                <UiToggle
                  variant="button"
                  name="orderIssuesChannels.EMAIL"
                  isChecked={isChecked.orderIssuesChannels.EMAIL}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="orderIssuesChannels.SLACK">
                {hasSlackIntegration && (
                  <UiToggle
                    variant="button"
                    name="orderIssuesChannels.SLACK"
                    isChecked={isChecked.orderIssuesChannels.SLACK}
                    onChange={handleChange}
                  />
                )}
              </FormControl>
            </>
          )}
        </Grid>
      </Grid>
      <BudgetBalanceThresholdsModal {...thresholdsDisclosure} />
    </>
  )
}
