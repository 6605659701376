import type { ImageProps } from '@chakra-ui/react'
import { Box, Flex, HStack, Image, Img, Stack, Text } from '@chakra-ui/react'
import type { UiTagProps } from '@postal-io/postal-ui'
import {
  FontWeight,
  UiButton,
  UiHeading2,
  UiHeading3,
  UiIconButton,
  UiIconPostalEngageLogo,
  UiLiftUpEffect,
  UiMoney,
  UiResponsiveCard,
  UiResponsiveCardBody,
  UiResponsiveCardElements,
  UiResponsiveCardHoverElements,
  UiResponsiveCardImage,
  UiResponsiveCardTitle,
  UiTag,
  UiTruncate,
} from '@postal-io/postal-ui'
import type { ApprovedPostal, ApprovedProductVariant, MarketplaceProduct, Status } from 'api'
import { MagicEventStatus } from 'api'
import { CATEGORY } from 'components/Postals'
import {
  AnalyticsEvent,
  useAnalyticsSend,
  useAssets,
  useNavigateSendFlow,
  usePostalImages,
  usePostalPermissions,
} from 'hooks'
import React, { useMemo } from 'react'
import { MdLink, MdOutlineLocalShipping, MdOutlineModeEditOutline, MdSend } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'

import greenCheckImage from 'assets/images/check-green.svg'
import grayCheckImage from 'assets/images/check-grey.svg'
import engagePaperPlane from 'assets/images/engage-paper-plane.svg'
import { PostalSendMethod } from 'components/PostalSend'

interface TriangleProps extends ImageProps {
  isSelected?: boolean
}

export const Triangle: React.FC<TriangleProps> = ({ isSelected, ...rest }) => {
  return (
    <button>
      <Image
        pos="absolute"
        top={0}
        right={0}
        zIndex={1}
        src={isSelected ? greenCheckImage : grayCheckImage}
        {...rest}
      />
    </button>
  )
}

export const POSTAL_CARD_WIDTH = 290

// Removed boxSize because we're using Material Design icons via react-icons.
const iconProps = {
  color: 'white',
  size: 34,
  // p: 1.5
}

export const statusMap: any = {
  // ACTIVE: {
  //   label: 'Approved',
  //   color: 'tertiary.500'
  // },
  DISABLED: {
    label: 'Draft',
    color: 'secondary.500',
  },
  PENDING: {
    label: 'Pending',
    color: 'secondary.500',
  },
  DELETE: {
    label: 'Deleted',
    color: 'red.600',
  },
  PENDING_CONFIRMATION: {
    label: 'Pending Confirmation',
    color: 'orange.400',
  },
  CONFIRMED_NEEDS_DATA: {
    label: 'Needs Data',
    color: 'yellow.400',
  },
  ACCEPTING_INVITES: {
    label: 'Accepting Invites',
    color: 'tertiary.500',
  },
  REGISTRATION_CLOSED: {
    label: 'Registration Closed',
    color: 'secondary.500',
  },
  COMPLETE: {
    label: 'Complete',
    color: 'primary.500',
  },
  CANCEL_REQUESTED: {
    label: 'Cancel Requested',
    color: 'orange.400',
  },
  CANCELLED: {
    label: 'Cancelled',
    color: 'red.600',
  },
  CONFIRMED_BILLING_ISSUE: {
    label: 'Needs Data',
    color: 'red.600',
  },
}

interface PostalStatusTagProps extends UiTagProps {
  status: Status | MagicEventStatus
}

export const PostalStatusTag: React.FC<PostalStatusTagProps> = ({ status, ...rest }) => {
  const { label, color } = statusMap[status] || {}
  if (!label || !color) return null
  return (
    <UiTag
      position="absolute"
      top="10px"
      right="10px"
      textTransform="uppercase"
      backgroundColor={color}
      color="white"
      fontSize="xs"
      fontWeight={500}
      zIndex={1}
      px={4}
      {...rest}
    >
      {label}
    </UiTag>
  )
}

export const PostalCollectionTag: React.FC<UiTagProps> = (props) => {
  return (
    <UiTag
      position="absolute"
      top="10px"
      right="10px"
      textTransform="uppercase"
      backgroundColor="white"
      borderColor="tertiary"
      border="2px"
      color="tertiary.500"
      fontSize="xs"
      fontWeight={FontWeight.Bold}
      zIndex={1}
      px={2.5}
      {...props}
    >
      Collection
    </UiTag>
  )
}

interface NonStarterTemplateProps {
  postal: ApprovedPostal
  onSelect?: (postal: ApprovedPostal) => void
  isSelected?: boolean
  buttonText?: string
  showStatus?: boolean
  onlyItems?: boolean
  onBulkSelect?: (postal: any) => void
}

export const NonStarterTemplate: React.FC<NonStarterTemplateProps> = ({
  postal,
  onSelect,
  isSelected,
  buttonText,
  showStatus,
  onlyItems,
  onBulkSelect,
}) => {
  const { name } = postal
  const imageUrls = usePostalImages(postal)
  const { canUpdate, canSend, canLink } = usePostalPermissions(postal)

  const { assetUrlSrc } = useAssets()
  const { src, fallbackSrc } = assetUrlSrc(imageUrls?.[0]?.url, {
    w: 350,
    h: 350,
    fit: 'clip',
  })
  const isAcceptingInvites = postal.event?.status === MagicEventStatus.AcceptingInvites

  const selectPostal = () => onSelect && onSelect(postal)

  const location = useLocation()
  const navigate = useNavigate()

  const sendAnalytics = useAnalyticsSend()

  // for determining number of magic links available, and retrieving magic link to copy

  const sendFlowLink = useNavigateSendFlow()

  const handleAction = (type: string) => {
    switch (type) {
      case 'EDIT':
        if (!canUpdate) return
        sendAnalytics({
          event: AnalyticsEvent.MarketplaceEditMyItemClicked,
          data: { marketplaceProductId: postal.marketplaceProductId, itemName: postal.name },
        })
        navigate(`${location.pathname}/${postal.id}/edit`)
        break
      case 'SEND':
        if (!canSend) return
        sendAnalytics({
          event: AnalyticsEvent.MarketplaceSendMyItemClicked,
          data: { marketplaceProductId: postal.marketplaceProductId, itemName: postal.name },
        })
        navigate(sendFlowLink(`${location.pathname}/${postal.id}/send`, { returnTo: 'Item' }))

        break
      case 'CREATE_LINK':
        if (!canLink) return
        sendAnalytics({
          event: AnalyticsEvent.MarketplaceCreateMagicLinkMyItemClicked,
          data: { marketplaceProductId: postal.marketplaceProductId, itemName: postal.name },
        })
        navigate(
          sendFlowLink(`${location.pathname}/${postal.id}/send`, {
            returnTo: 'Item',
            sendMethod: PostalSendMethod.Link,
          })
        )
        break
      case 'SELECT_POSTAL':
        selectPostal()
        break
      default:
    }
  }

  return (
    <UiResponsiveCard>
      <UiResponsiveCardBody
        opacity={!isAcceptingInvites && postal.event?.status ? 0.6 : 1}
        boxShadow="none"
        borderWidth="2px"
        borderColor="gray.100"
      >
        {isSelected && <Triangle isSelected />}
        <UiResponsiveCardImage
          src={src}
          fallbackSrc={fallbackSrc}
        />
        <UiResponsiveCardElements>
          {showStatus && <PostalStatusTag status={postal.status} />}
          {postal.collection && <PostalCollectionTag />}
          {postal.event?.status && <PostalStatusTag status={postal.event.status} />}
        </UiResponsiveCardElements>
        <UiResponsiveCardHoverElements>
          {/* Only show the modified hoverview if in the "My Items" page */}
          {onlyItems ? (
            <EnhancedHoverview
              postal={postal}
              isSelected={isSelected}
              buttonText={buttonText}
              handleAction={handleAction}
              canSend={onlyItems ? canSend : undefined}
              canEdit={onlyItems ? canUpdate : undefined}
              canLink={onlyItems ? canLink : undefined}
              onBulkSelect={canUpdate ? onBulkSelect : undefined}
            />
          ) : (
            <Hoverview
              postal={postal}
              onSelect={onSelect ? selectPostal : undefined}
              isSelected={isSelected as boolean}
              buttonText={buttonText}
              onBulkSelect={canUpdate ? onBulkSelect : undefined}
            />
          )}
        </UiResponsiveCardHoverElements>
      </UiResponsiveCardBody>
      <UiResponsiveCardTitle
        data-testid="PostalCard_button_name"
        onClick={onSelect ? selectPostal : undefined}
      >
        {name}
      </UiResponsiveCardTitle>
    </UiResponsiveCard>
  )
}

interface HoverviewPricingProps {
  postal: ApprovedPostal | MarketplaceProduct
}

const HoverviewPricing: React.FC<HoverviewPricingProps> = ({ postal }) => {
  const shippingPriceEstimate = useMemo(() => {
    return (
      (postal as ApprovedPostal)?.variants?.[0]?.shippingPriceEstimateMin ??
      (postal as MarketplaceProduct)?.variants?.[0]?.fulfillmentPartnerList?.[0]?.shippingOptions?.[0]?.price ??
      0
    )
  }, [postal])

  const hideShipping = postal.category === CATEGORY.Events

  const displayPrices =
    postal?.variants?.map((v) => v.displayPrice ?? 0).sort((priceA, priceB) => priceA - priceB) ?? []

  const [priceRangeMin, priceRangeMax] = Array.from(
    new Set([Math.round(displayPrices[0]), Math.round(displayPrices[displayPrices.length - 1])])
  ).filter((n) => !!n)

  return (
    <Flex
      position="absolute"
      top={0}
      left={0}
      p={4}
      width="100%"
      data-testid="PostalCard_HoverviewPricing"
    >
      <Text
        fontSize="lg"
        color="green.500"
        fontWeight={FontWeight.Bold}
      >
        <UiMoney
          cents={priceRangeMin}
          currency={postal.currency}
          round
        />
        {priceRangeMax && (
          <>
            {' '}
            -{' '}
            <UiMoney
              cents={priceRangeMax}
              currency={postal.currency}
              round
            />
          </>
        )}
      </Text>
      {!hideShipping && (
        <Flex
          ml={2}
          alignItems="center"
          borderLeft="1px solid var(--chakra-colors-gray-400)"
          px={2}
        >
          <MdOutlineLocalShipping
            color="var(--chakra-colors-gray-400)"
            size={22}
          />
          <Text
            fontSize="md"
            color="gray.400"
            ml={2}
            fontWeight={FontWeight.Bold}
            title="Estimated shipping cost"
          >
            {shippingPriceEstimate === 0 ? (
              'FREE'
            ) : (
              <>
                ~
                <UiMoney
                  cents={shippingPriceEstimate}
                  currency={postal.currency}
                  placeholder="$0"
                  round
                />
              </>
            )}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

interface HoverviewProps {
  postal: ApprovedPostal
  isSelected?: boolean
  buttonText?: string
  onSelect?: () => void
  onBulkSelect?: (postal: any) => void
}

export const Hoverview: React.FC<HoverviewProps> = ({
  postal,
  isSelected,
  buttonText = 'Select',
  onSelect,
  onBulkSelect,
}) => {
  const { variants, collection } = postal

  return (
    <Box
      backgroundColor="rgba(255,255,255, 0.9)"
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      p={4}
    >
      {onBulkSelect && (
        <Box onClick={() => onBulkSelect(postal)}>
          <Triangle />
        </Box>
      )}
      <HoverviewPricing postal={postal} />
      <Box
        position="absolute"
        top="60px"
        bottom="65px"
        left={4}
        right={4}
        overflow="hidden"
      >
        {!!collection && (
          <>
            {variants?.slice(0, 4).map((item: ApprovedProductVariant, idx: number) => {
              return (
                <Text
                  fontWeight="semibold"
                  fontSize="md"
                  color="gray.400"
                  mb={1}
                  key={`${item.variantName}-${idx}`}
                >
                  Item {idx + 1} -{' '}
                  <UiTruncate
                    text={item.variantName}
                    color="gray.600"
                    length={25}
                  />
                </Text>
              )
            })}
            {variants && variants?.length > 4 && (
              <Text
                fontWeight="semibold"
                fontSize="md"
                color="gray.400"
              >
                ... plus {variants?.length - 4} more
              </Text>
            )}
          </>
        )}
      </Box>
      {!isSelected && (
        <UiButton
          width="90%"
          colorScheme="tertiary"
          mx="auto"
          position="absolute"
          bottom="15px"
          left={0}
          right={0}
          height="55px"
          onClick={onSelect}
          isDisabled={!onSelect}
        >
          {!onSelect ? 'Item Unavailable' : buttonText}
        </UiButton>
      )}
    </Box>
  )
}

interface EnhancedHoverviewProps {
  postal: ApprovedPostal
  isSelected?: boolean
  buttonText?: string
  handleAction: (input: string) => void
  canEdit?: boolean
  canSend?: boolean
  canLink?: boolean
  onBulkSelect?: (postal: any) => void
}

export const EnhancedHoverview: React.FC<EnhancedHoverviewProps> = ({
  isSelected,
  buttonText = 'Select',
  handleAction,
  canEdit,
  canSend,
  canLink,
  postal,
  onBulkSelect,
}) => {
  return (
    <Box
      backgroundColor="rgba(255,255,255, 0.9)"
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      p={4}
    >
      {onBulkSelect && (
        <Box onClick={() => onBulkSelect(postal)}>
          <Triangle />
        </Box>
      )}
      <HoverviewPricing postal={postal} />
      {!isSelected && (
        <>
          <Flex
            pos="relative"
            justify="center"
            alignItems="center"
            mx="auto"
            top="30%"
            width="80%"
          >
            {canSend && (
              <UiIconButton
                colorScheme="tertiary"
                icon={<MdSend {...iconProps} />}
                aria-label="Send Item"
                title="Send Item"
                onClick={() => handleAction('SEND')}
                isRound
                boxSize="55px"
                m={2}
              />
            )}
            {canLink && (
              <UiIconButton
                colorScheme="secondary"
                icon={
                  <MdLink
                    {...iconProps}
                    size={38}
                  />
                }
                aria-label="Create MagicLink"
                title="Create MagicLink"
                onClick={() => handleAction('CREATE_LINK')}
                isRound
                boxSize="55px"
                m={2}
              />
            )}
            {canEdit && (
              <UiIconButton
                colorScheme="primary"
                icon={<MdOutlineModeEditOutline {...iconProps} />}
                aria-label="Edit Item"
                title="Edit Item"
                onClick={() => handleAction('EDIT')}
                isRound
                boxSize="55px"
                m={2}
              />
            )}
          </Flex>

          <UiButton
            width="90%"
            colorScheme="tertiary"
            mx="auto"
            position="absolute"
            bottom="15px"
            left={0}
            right={0}
            height="55px"
            onClick={() => handleAction('SELECT_POSTAL')}
          >
            {buttonText}
          </UiButton>
        </>
      )}
    </Box>
  )
}

interface NoPostalProps {
  onSelect?: () => void
}

export const NoPostal: React.FC<NoPostalProps> = ({ onSelect }) => {
  return (
    <UiResponsiveCard data-testid="PostalCard_NotFound">
      <UiResponsiveCardBody
        boxShadow="none"
        borderWidth="2px"
        borderColor="gray.100"
      >
        <UiResponsiveCardElements>
          <Box
            opacity={1}
            _hover={{ opacity: 0.4 }}
          >
            <Flex
              backgroundColor="rgba(255,255,255, 0.9)"
              direction="column"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              p={4}
            >
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                p={4}
              >
                <UiHeading3
                  size="md"
                  mb={2}
                  fontWeight="normal"
                >
                  Not Found
                </UiHeading3>
                <Text color="gray.600">
                  No items found matching the selected tags. Please try changing your search criteria.
                </Text>
              </Flex>
              {onSelect && (
                <UiButton
                  mt={2}
                  width="60%"
                  backgroundColor="green.500"
                  data-testid="createPostal"
                >
                  Create an Item
                </UiButton>
              )}
            </Flex>
          </Box>
        </UiResponsiveCardElements>
      </UiResponsiveCardBody>
    </UiResponsiveCard>
  )
}

interface StarterTemplateProps {
  postal: ApprovedPostal
  onSelect?: (postal: ApprovedPostal) => void
}

export const StarterTemplate: React.FC<StarterTemplateProps> = ({ postal, onSelect }) => {
  const { name } = postal
  const imageUrls = usePostalImages(postal)
  const { assetUrlSrc } = useAssets()
  const { src, fallbackSrc } = assetUrlSrc(imageUrls?.[0]?.url, { w: 350, h: 350, fit: 'clip' })

  const selectPostal = () => onSelect && onSelect(postal)

  return (
    <UiResponsiveCard onClick={selectPostal}>
      <UiResponsiveCardBody
        boxShadow="none"
        borderWidth="2px"
        borderColor="gray.100"
      >
        <UiResponsiveCardImage
          src={src}
          fallbackSrc={fallbackSrc}
        />
        <UiResponsiveCardElements>
          <Flex
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <UiHeading3
              size="md"
              fontWeight="normal"
              textAlign="center"
            >
              Create a {name} from <br />
              scratch
            </UiHeading3>
            <UiButton variant="link">Start Here</UiButton>
          </Flex>
        </UiResponsiveCardElements>
      </UiResponsiveCardBody>
      <UiResponsiveCardTitle onClick={selectPostal}>{name}</UiResponsiveCardTitle>
    </UiResponsiveCard>
  )
}

interface ProductRequestCardProps {
  handleClick: () => void
}
export const ProductRequestCard: React.FC<ProductRequestCardProps> = ({ handleClick }) => {
  return (
    <UiResponsiveCard>
      <UiLiftUpEffect>
        <UiResponsiveCardBody
          borderWidth="0px"
          boxShadow="none"
          borderColor="gray.100"
          onClick={handleClick}
        >
          <UiResponsiveCardElements
            bg="atomicBlue.10"
            userSelect="none"
          >
            <Flex
              position="absolute"
              top={10}
              bottom={10}
              left={6}
              right={6}
              borderRadius={6}
              direction="column"
              color="atomicGray.900"
              alignItems="left"
              justifyContent="space-between"
            >
              <Img
                src={engagePaperPlane}
                h="35px"
                w="fit-content"
              />
              <UiHeading2
                fontSize="32px"
                mb={0}
              >
                Recommend a product
              </UiHeading2>
            </Flex>
          </UiResponsiveCardElements>
        </UiResponsiveCardBody>
      </UiLiftUpEffect>
      <UiResponsiveCardTitle
        mt={4}
        mb={4}
        ml="1px"
        sx={{ '& p': { whiteSpace: 'normal' } }}
        textAlign="left"
        pointerEvents="none"
      >
        Don't see what you're looking for? Recommend a product
      </UiResponsiveCardTitle>
    </UiResponsiveCard>
  )
}

interface ConciergeRequestCardProps {
  handleClick: () => void
}
export const ConciergeRequestCard: React.FC<ConciergeRequestCardProps> = ({ handleClick }) => {
  return (
    <>
      <style>{`.paper-plane-text {font-weight: 700 !important;}`}</style>
      <UiResponsiveCard>
        <UiLiftUpEffect>
          <UiResponsiveCardBody
            borderWidth="0px"
            boxShadow="none"
            borderColor="gray.100"
            onClick={handleClick}
          >
            <UiResponsiveCardElements
              bg="atomicGray.900"
              userSelect="none"
            >
              <Flex
                position="absolute"
                top={10}
                bottom={10}
                left={6}
                right={6}
                borderRadius={6}
                direction="column"
                color="white"
                alignItems="left"
                justifyContent="space-between"
              >
                <Stack spacing={0}>
                  <UiHeading3
                    className="paper-plane-text"
                    fontSize="18px"
                    fontWeight="600"
                    letterSpacing={4}
                    my={0}
                  >
                    PAPER PLANE
                  </UiHeading3>
                  <HStack
                    spacing={1}
                    alignItems="center"
                  >
                    <Text fontSize="8px">by</Text>
                    <UiIconPostalEngageLogo
                      h="8px"
                      w="auto"
                    />
                  </HStack>
                </Stack>
                <UiHeading2
                  fontSize="32px"
                  mb={0}
                >
                  Elevate your brand
                </UiHeading2>
              </Flex>
            </UiResponsiveCardElements>
          </UiResponsiveCardBody>
        </UiLiftUpEffect>
        <UiResponsiveCardTitle
          mt={4}
          mb={4}
          ml="1px"
          sx={{ '& p': { whiteSpace: 'normal' } }}
          pointerEvents="none"
          textAlign="left"
        >
          Create a custom project with our team at Paper Plane Agency
        </UiResponsiveCardTitle>
      </UiResponsiveCard>
    </>
  )
}
