import { Collapse, Flex, Stack } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import {
  UiFormControl,
  useAlerts,
  ZButton,
  ZCard,
  ZCardHeader,
  ZFormLabel,
  ZInput,
  ZSelect,
} from '@postal-io/postal-ui'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { useAcl } from 'hooks'
import React, { useCallback, useEffect } from 'react'
import { useImmer } from 'use-immer'
import type { Account } from '../../api'
import { UpdateAccountDocument } from '../../api'
import { DEFAULT_SESSION_TIMEOUT_MINUTES, FORCED_AUTHENTICATION_TYPES } from './data'

interface FormState {
  sessionTimeoutMinutes?: number
  forceAuthenticationMethod?: string
  dirty?: boolean
}

const SESSION_TIMEOUT_TOOLTIP = 'The number of minutes of inactivity before the user is automatically logged off'

const AUTHENTICATION_TYPE_TOOLTIP = 'You may restrict which type of authentication methods your users may login with.'

export interface SecuritySettingsProps extends UiCardProps {
  account?: Account
}

export const SecuritySettings: React.FC<SecuritySettingsProps> = ({ account, ...rest }) => {
  const [form, setForm] = useImmer<FormState>({})
  const Alert = useAlerts()
  const { hasPermission } = useAcl()
  const canUpdate = hasPermission('security.update')

  const reset = useCallback(() => {
    if (!account) return
    setForm((draft) => {
      draft.sessionTimeoutMinutes = account.sessionTimeoutMinutes || DEFAULT_SESSION_TIMEOUT_MINUTES
      draft.forceAuthenticationMethod = account.forceAuthenticationMethod || ''
      draft.dirty = false
    })
  }, [account, setForm])

  // load data when account is changed
  useEffect(() => {
    reset()
  }, [reset])

  const handleSessionTimeout = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (value < 1) return
    setForm((draft) => {
      draft.dirty = value !== draft.sessionTimeoutMinutes
      draft.sessionTimeoutMinutes = value
    })
  }

  const handleForceAuthenticationMethod = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setForm((draft) => {
      draft.dirty = value !== draft.forceAuthenticationMethod
      draft.forceAuthenticationMethod = value || ''
    })
  }

  const updateAccount = useGraphqlMutation(UpdateAccountDocument)

  const update = async () => {
    if (!canUpdate) return
    const { dirty, ...data } = form
    try {
      await updateAccount.mutateAsync({ id: account?.id as string, data })
      Alert.success('Session Timeout Updated')
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <ZCard
      isLoading={!account}
      variant="form"
      {...rest}
    >
      <ZCardHeader
        p={8}
        pb={0}
        fontWeight="normal"
      >
        App Settings
      </ZCardHeader>
      <Stack
        spacing={8}
        p={8}
      >
        <UiFormControl
          id="sessionTimeoutMinutes"
          isDisabled={!canUpdate}
        >
          <ZFormLabel
            fontSize="md"
            gap={2}
          >
            Session Timeout Minutes
            <ZInfoTooltip label={SESSION_TIMEOUT_TOOLTIP} />
          </ZFormLabel>
          <ZInput
            name="sessionTimeoutMinutes"
            type="number"
            placeholder="Session Timeout in Minutes"
            value={form?.sessionTimeoutMinutes || ''}
            onChange={handleSessionTimeout}
            min={1}
          />
        </UiFormControl>
        <UiFormControl id="forceAuthenticationMethod">
          <ZFormLabel
            fontSize="md"
            gap={2}
          >
            Authentication Type
            <ZInfoTooltip label={AUTHENTICATION_TYPE_TOOLTIP} />
          </ZFormLabel>
          <ZSelect
            name="forceAuthenticationMethod"
            value={form.forceAuthenticationMethod || ''}
            onChange={handleForceAuthenticationMethod}
            placeholder="Allow All"
          >
            {FORCED_AUTHENTICATION_TYPES.map((auth) => {
              return (
                <option
                  key={auth.id}
                  value={auth.id}
                >
                  {auth.label}
                </option>
              )
            })}
          </ZSelect>
        </UiFormControl>
      </Stack>
      <Collapse in={form.dirty}>
        <Flex
          p={8}
          justifyContent="space-between"
        >
          <ZButton
            colorScheme="atomicBlue"
            onClick={update}
            minW="120px"
          >
            Save
          </ZButton>
          <ZButton
            size="md"
            colorScheme="atomicGray"
            variant="ghost"
            onClick={reset}
            minW="120px"
          >
            Reset
          </ZButton>
        </Flex>
      </Collapse>
    </ZCard>
  )
}
