import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { DataObjectType, GetConnectionsDocument, SearchIntegrationSyncDocument, Status } from 'api'
import { useAcl } from 'hooks'
import { useMemo } from 'react'
import { ExternalProviders } from '../Integrations'

export const useTriggerProviders = () => {
  const { aclCheck } = useAcl()

  const availableProviders = useMemo(() => {
    return ExternalProviders.filter((p) => !!p.trigger && aclCheck(p.trigger))
  }, [aclCheck])

  // search for valid, active syncs - disable create if syncs are not ACTIVE
  const integrationsQuery = useGraphqlQuery(
    SearchIntegrationSyncDocument,
    {
      filter: {
        objectType: { eq: DataObjectType.User },
        status: { eq: Status.Active },
        system: { in: availableProviders.map((p) => p.system) },
      },
    },
    { staleTime: 1000 * 60 }
  )

  const integrations = useMemo(
    () => integrationsQuery?.data?.searchIntegrationSync || [],
    [integrationsQuery?.data?.searchIntegrationSync]
  )

  // Workato connections
  const getConnections = useGraphqlQuery(GetConnectionsDocument)
  const connections = useMemo(() => getConnections.data?.getConnections || [], [getConnections.data?.getConnections])

  const providers = useMemo(() => {
    return availableProviders.filter((provider) => {
      if (!provider.trigger?.requiresIntegration) return true
      if (integrations.some((i) => i.system === provider.system)) return true
      if (provider.workato && connections.some((c) => c.name.startsWith(provider.workato!.connectionPrefix)))
        return true
      return false
    })
  }, [availableProviders, connections, integrations])

  return { providers, integrations }
}
