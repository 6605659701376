import type { BoxProps, FlexProps } from '@chakra-ui/react'
import { Box, Divider, Flex, SimpleGrid, Stack } from '@chakra-ui/react'
import { ZHeading, ZMoney, ZText } from '@postal-io/postal-ui'
import type { OrderPreview } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { PostalSendMethod } from 'components/PostalSend/postalSendHelpers'
import type { ReactElement } from 'react'
import React from 'react'

interface OrderReviewRowProps extends FlexProps {
  label: string | ReactElement
}

export const OrderReviewRow: React.FC<OrderReviewRowProps> = ({ label, children, ...rest }) => (
  <Flex
    direction="row"
    w="100%"
    justifyContent="space-between"
    {...rest}
  >
    <ZText
      color="atomicGray.500"
      fontSize="body-sm"
      fontWeight="bold"
    >
      {label}
    </ZText>
    <Box
      color="atomicGray.800"
      fontSize="body-md"
      whiteSpace="break-spaces"
      fontWeight="bold"
      textAlign="right"
    >
      {children}
    </Box>
  </Flex>
)

interface ReviewLineItemProps {
  label: string | ReactElement
  amount?: number | null
  currency?: string | null
}

export const ReviewLineItem: React.FC<ReviewLineItemProps> = ({ label, amount, currency, ...rest }) => (
  <OrderReviewRow
    label={label}
    {...rest}
  >
    <ZMoney
      fontWeight="bold"
      cents={amount || 0}
      currency={currency}
    />
  </OrderReviewRow>
)

export interface PostalReviewCostV2Props extends BoxProps {
  previewOrder?: OrderPreview
  method?: PostalSendMethod
  hasContacts?: boolean
  flatFees: number | null
}
export const PostalReviewCost: React.FC<PostalReviewCostV2Props> = ({
  previewOrder,
  method,
  hasContacts,
  flatFees,
}) => {
  if (!previewOrder || flatFees !== null) return null
  const { cost = {} } = previewOrder
  return (
    <Box>
      <Stack spacing={5}>
        <Stack spacing={5}>
          <ReviewLineItem
            label="Item Costs"
            amount={cost.subtotal}
            currency={cost.currency}
          />
          <ReviewLineItem
            label={`Estimated Vendor Sales Tax ${!hasContacts ? '*' : ''}`}
            amount={cost.tax}
            currency={cost.currency}
          />
        </Stack>

        <Divider opacity={1} />

        <Stack spacing={5}>
          <ReviewLineItem
            label="Postal Transaction Fee (off item cost)"
            amount={cost.postalFee}
            currency={cost.currency}
          />
          <ReviewLineItem
            label={
              <Flex alignItems="center">
                Shipping and Postage
                {method === PostalSendMethod.BulkSend && (
                  <ZInfoTooltip
                    ml={1}
                    label="This is an approximation for shipping. We will refund the difference for shipping within 1 business day."
                  />
                )}
              </Flex>
            }
            amount={cost.shipping}
            currency={cost.currency}
          />
        </Stack>

        <Divider opacity={1} />

        <SimpleGrid
          columns={2}
          w="100%"
        >
          <Box>
            <ZText
              color="atomicGray.600"
              fontWeight="bold"
            >
              {hasContacts ? 'Total' : 'Cost Per Item'}
            </ZText>
            <ZText
              fontSize="xs"
              display="block"
              color="atomicGray.500"
            >
              * Tax may vary based on recipients
            </ZText>
          </Box>
          <ZHeading
            size="h4"
            textAlign="right"
            color="atomicGray.600"
          >
            <ZMoney
              fontSize="inherit"
              cents={cost.total || 0}
              currency={cost.currency}
            />
          </ZHeading>
        </SimpleGrid>
      </Stack>
    </Box>
  )
}
