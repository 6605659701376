import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiSelectTypeaheadProps } from '@postal-io/postal-ui'
import { ZSelectTypeahead } from '@postal-io/postal-ui'
import { useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import type { SearchableContact } from '../../api'
import { SearchContactsV2Document } from '../../api'
import { autoPrefixQuery, useAcl } from '../../hooks'
import { getContactString } from '../../lib'

export function AutoCompleteContact(props: UiSelectTypeaheadProps<SearchableContact>) {
  const { hasFeature } = useAcl()
  const canSearch = hasFeature('search')

  const [search, setSearch] = useState(props.inputValue || props.defaultInputValue || '')
  const [debounced] = useDebounce(search, 400)

  const variables = useMemo(
    () => ({
      filter: canSearch
        ? { q: autoPrefixQuery(debounced) }
        : { emailAddress: debounced ? { contains: debounced } : undefined },
      limit: 20,
    }),
    [canSearch, debounced]
  )

  const searchContacts = useGraphqlQuery(SearchContactsV2Document, variables)
  const options = useMemo(
    () => searchContacts.data?.searchContactsV2?.searchableContacts || [],
    [searchContacts.data?.searchContactsV2?.searchableContacts]
  )

  const handleInput = useCallback(
    (val: any, meta: any) => {
      setSearch(val)
      props.onInputChange && props.onInputChange(val, meta)
    },
    [props]
  )

  const { onInputChange, isDisabled, ...rest } = props

  return (
    <ZSelectTypeahead
      data-testid="AutoCompleteContact"
      options={options}
      getOptionLabel={getContactString}
      getOptionValue={(t) => t.id}
      onInputChange={handleInput}
      isLoading={searchContacts.isLoading}
      placeholder="Select Contact"
      noOptionsMessage={() => 'No Contacts Found'}
      isDisabled={props.isDisabled || !canSearch}
      filterOption={null}
      {...rest}
    />
  )
}
