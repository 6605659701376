/**
 * V2 PERMISSIONS
 *
 * https://github.com/postal-io/permissions/blob/master/v2/POSTAL_IO_APP/permissions.yaml
 *
 */
export enum Permission {
  /**  Basic login access */
  Login = 'login',
  /**  Enables visibility to all contacts regardless of owner */
  AllContacts = 'all-contacts',
  /**  View the contacts you own */
  ContactViewer = 'contact-viewer',
  /**  Postal Engage basic access */
  Engage = 'engage',
  /**  CRM Account Queries */
  CRMAccounts = 'crm-accounts',
  /**  Send items and book events in the marketplace */
  Send = 'send',
  /**  Configure and manage integrations with third-party platforms */
  IntegrationManager = 'integration-manager',
  /**  Modify role permissions on the account */
  RoleManager = 'role-manager',
  /**  View & use Swag Editor/Swag Creator */
  SwagEditor = 'swag-editor',
  /** Ability to request changes to event or contact events team */
  EventManager = 'event-manager',
  /** Account is transitioned to Netsuite */
  NetsuiteEnabled = 'netsuite-enabled',

  /**  No description provided */
  Billing = 'billing',
  Products = 'products',
  Notifications = 'notifications',
  Webhooks = 'webhooks',
}
