import { Flex, VStack } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiChangeEvent, UiGenericChangeEvent } from '@postal-io/postal-ui'
import {
  UiFormControl,
  UiRadioButtonGroup,
  UiSkeleton,
  useAlerts,
  ZFormLabel,
  ZHeading,
  ZInputMoney,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZMoney,
  ZRadioButton,
  ZSelect,
  ZText,
} from '@postal-io/postal-ui'
import {
  AdjustUserBudgetDocument,
  BudgetDuration,
  BudgetMode,
  GetAccountDocument,
  GetBudgetRemainingDocument,
  GetTeamByIdDocument,
  GetUserDocument,
} from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import React, { useMemo, useState } from 'react'
import { useImmer } from 'use-immer'

interface TeamUserAddBudgetV2Props {
  onClose: () => void
  isOpen: boolean
  userId: string
  teamId?: string
  accountId?: string
}

type AddBudgetFormState = {
  teamId?: string
  userId: string
  amountCents?: number
}

const BUDGET_TIMEFRAME_MAP: Record<BudgetDuration, string> = {
  [BudgetDuration.Monthly]: 'month',
  [BudgetDuration.Quarterly]: 'quarter',
  [BudgetDuration.Yearly]: 'year',
}

export const TeamUserAddBudget: React.FC<TeamUserAddBudgetV2Props> = ({
  onClose,
  isOpen,
  userId,
  teamId,
  accountId,
}) => {
  const Alert = useAlerts()
  const [adjustment, setAdjustment] = useState<'add' | 'remove'>('add')
  const [form, setForm] = useImmer<AddBudgetFormState>({
    teamId,
    userId,
  })

  const adjustBudgetMutation = useGraphqlMutation(AdjustUserBudgetDocument)

  const { data: user } = useGraphqlQuery(GetUserDocument, { id: userId }, { enabled: !!userId })
  const budgetData = useGraphqlQuery(
    GetBudgetRemainingDocument,
    { accountId: accountId as string, userId, teamId: form.teamId },
    { enabled: !!accountId }
  )

  const userName = useMemo(() => `${user?.getUser?.firstName} ${user?.getUser?.lastName}`, [user?.getUser])

  const budgetRemaining = useMemo(
    () => budgetData?.data?.getBudgetRemaining?.budgetRemaining ?? 0,
    [budgetData?.data?.getBudgetRemaining?.budgetRemaining]
  )

  const totalBudget = useMemo(
    () => budgetRemaining + (form.amountCents ?? 0) * (adjustment === 'add' ? 1 : -1),
    [budgetRemaining, form.amountCents, adjustment]
  )

  const handleSelectTeam = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setForm((draft) => {
      draft.teamId = e.target.value
    })
  }

  const handleChangeAmount = ({ value }: UiChangeEvent<number>) => {
    if (adjustment === 'remove' && value! > budgetRemaining) return
    setForm((draft) => {
      draft.amountCents = Number(value)
    })
  }

  const handleChangeAdjustment = ({ target: { value } }: UiGenericChangeEvent) => {
    setAdjustment(value! as 'add' | 'remove')
    setForm((draft) => {
      draft.amountCents = undefined
    })
  }

  const handleAdjustBudget = async () => {
    try {
      await adjustBudgetMutation.mutateAsync({
        input: {
          userId: form.userId,
          teamId: form.teamId,
          amountCents: adjustment === 'add' ? form.amountCents ?? 0 : (form.amountCents ?? 0) * -1,
        },
      })
      Alert.success(`${adjustment === 'add' ? 'Added to ' : 'Removed from '}${userName}'s budget`)
      onClose()
    } catch (e) {
      console.error({ e })
      Alert.error(e)
    }
  }
  return (
    <ZModal
      onClose={onClose}
      isOpen={isOpen}
      size="md"
    >
      <ZModalOverlay />
      <ZModalContent>
        <ZModalHeader>Adjust Budget</ZModalHeader>
        <ZModalCloseButton />
        <ZModalBody>
          <VStack
            spacing={8}
            alignItems="flex-start"
            w="full"
          >
            <ZHeading
              fontSize="md"
              fontWeight="bold"
            >
              {userName}
            </ZHeading>
            <UiRadioButtonGroup
              onChange={handleChangeAdjustment}
              name="adjustmentType"
              colorScheme={adjustment === 'add' ? 'atomicBlue' : 'atomicRed'}
              value={adjustment}
            >
              <ZRadioButton value="add">Add</ZRadioButton>
              <ZRadioButton value="remove">Remove</ZRadioButton>
            </UiRadioButtonGroup>
            <VStack
              alignItems="flex-start"
              w="full"
            >
              <ZText
                fontSize="sm"
                color="atomicGray.500"
              >
                Team
              </ZText>
              <ZSelect
                value={form.teamId}
                onChange={handleSelectTeam}
                w="100%"
              >
                {user?.getUser?.productAccess?.map((p) => (
                  <UserAddBudgetTeamOption
                    key={p.id}
                    teamId={p.teamId}
                    accountId={p.accountId as string}
                  />
                ))}
              </ZSelect>
            </VStack>
            <VStack alignItems="flex-start">
              <UiSkeleton
                isLoaded={!budgetData?.isLoading}
                w="full"
              >
                <Flex
                  w="full"
                  alignItems="center"
                  gap={1}
                >
                  <ZText
                    fontSize="sm"
                    color="atomicGray.500"
                  >
                    Remaining Budget{' '}
                    <ZText
                      as="strong"
                      display="inline"
                      fontWeight="bold"
                    >
                      this{' '}
                      {BUDGET_TIMEFRAME_MAP[budgetData?.data?.getBudgetRemaining?.applicableBudget?.budget?.duration!]}
                    </ZText>
                  </ZText>
                  <ZInfoTooltip
                    label="The amount left to spend, this period."
                    hasArrow
                    placement="right"
                  />
                </Flex>
                <ZMoney
                  cents={budgetRemaining}
                  fontSize="2xl"
                  color="atomicGray.600"
                  fontWeight="bold"
                />
              </UiSkeleton>
            </VStack>
            <UiFormControl w="full">
              <ZFormLabel
                fontSize="sm"
                color="atomicGray.500"
              >
                {adjustment === 'add' ? 'Additional' : 'Removed'} Budget
              </ZFormLabel>
              <ZInputMoney
                value={form.amountCents}
                onChange={handleChangeAmount}
                min={0}
              />
            </UiFormControl>
          </VStack>
          <VStack
            alignItems="flex-start"
            mt={8}
          >
            <UiSkeleton
              isLoaded={!budgetData?.isLoading}
              w="full"
            >
              <Flex
                w="full"
                alignItems="center"
                gap={1}
              >
                <ZText
                  fontSize="sm"
                  color="atomicGray.500"
                >
                  Total Budget{' '}
                  <ZText
                    as="strong"
                    display="inline"
                    fontWeight="bold"
                  >
                    this{' '}
                    {BUDGET_TIMEFRAME_MAP[budgetData?.data?.getBudgetRemaining?.applicableBudget?.budget?.duration!]}
                  </ZText>
                </ZText>
                <ZInfoTooltip
                  label="The total amount available to spend, this period."
                  hasArrow
                  placement="right"
                />
              </Flex>
              <ZMoney
                cents={totalBudget}
                fontSize="2xl"
                color="atomicGray.600"
                fontWeight="bold"
              />
            </UiSkeleton>
          </VStack>
        </ZModalBody>
        <ZModalButtons
          onConfirm={handleAdjustBudget}
          isConfirmDisabled={!form.amountCents || adjustBudgetMutation.isLoading || budgetData?.isLoading}
          isConfirmLoading={adjustBudgetMutation.isLoading}
          confirmText={adjustment === 'add' ? 'Add Budget' : 'Remove Budget'}
          onClose={onClose}
          cancelText="Cancel"
        />
      </ZModalContent>
    </ZModal>
  )
}

const UserAddBudgetTeamOption: React.FC<{ teamId?: string | null; accountId: string }> = ({ teamId, accountId }) => {
  const { data: teamData } = useGraphqlQuery(GetTeamByIdDocument, { id: teamId as string }, { enabled: !!teamId })
  const { data: accountData } = useGraphqlQuery(GetAccountDocument, {}, { enabled: !teamId })
  const { data: budgetData } = useGraphqlQuery(
    GetBudgetRemainingDocument,
    { teamId, accountId },
    { enabled: !!accountId }
  )

  const teamName = useMemo(() => {
    return teamData?.getTeamById?.name ?? accountData?.getAccount?.displayName
  }, [teamData, accountData])

  return budgetData?.getBudgetRemaining?.applicableBudget?.budget?.mode === BudgetMode.PerUser ? (
    <option value={teamId as string}>{teamName}</option>
  ) : null
}
