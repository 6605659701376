import { Box, Divider, Flex, Icon, Popover, PopoverBody, PopoverContent, PopoverTrigger, Stack } from '@chakra-ui/react'
import { UiInputDate, UiLink, UiToggle, ZFormLabel, ZHeading, ZInput, ZText } from '@postal-io/postal-ui'
import { ZInfoTooltip, ZResponsiveFormControl } from 'components/Common/ZComponents'
import type { PostalSendContext, PostalSendEventV2 } from 'components/PostalSend/usePostalSend'
import { getSendMethodColor } from 'components/PostalSend/usePostalSend'
import { addDays, endOfDay, startOfDay } from 'date-fns'
import { useAcl } from 'hooks'
import type { ChangeEvent } from 'react'
import React from 'react'
import { MdOutlineInfo } from 'react-icons/md'
import { AnalyticsEventV2, useAnalyticsSend } from '../../hooks/useAnalytics'
import { useExtension } from '../../hooks/useExtension'
import { usePostalSendFieldErrors } from './usePostalSendFieldErrors'

interface PostalCustomizeLinkV2Props {
  context: PostalSendContext
  send: (evt: PostalSendEventV2) => void
}
export const PostalCustomizeLink: React.FC<PostalCustomizeLinkV2Props> = ({ context, send, ...rest }) => {
  const { hasFeature } = useAcl()
  const { isExtension } = useExtension()
  const canToggleApprovedLinks = hasFeature('manuallyApproveLinks')
  const sendAnalytics = useAnalyticsSend()

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    switch (target.name) {
      case 'linkEnabled':
        send({ type: 'SET_ENABLED', data: target.checked })
        break
      case 'linkName':
        send({ type: 'SET_NAME', data: target.value })
        break
      case 'linkMaxExecutions':
        send({ type: 'SET_MAX_EXECUTIONS', data: Math.max(parseInt(target?.value), 1) })
        break
      case 'linkExpirationDate':
        send({ type: 'SET_DATE', data: target.value ? endOfDay(new Date(target.value)) : null })
        break
      case 'linkNeedsApproval':
        sendAnalytics({
          event: AnalyticsEventV2.SendFlow_RequireApproval_Toggle,
          data: { needsApproval: !!target.checked },
        })
        send({ type: 'SET_LINK_NEEDS_APPROVAL', data: target.checked })
        break
    }
  }

  const maxExecutionsErrorProps = usePostalSendFieldErrors({ context, field: 'maxExecutions' })
  const nameErrorProps = usePostalSendFieldErrors({ context, field: 'linkName' })

  return (
    <>
      <Box
        px={{ base: 4, md: 10 }}
        py={8}
        {...rest}
      >
        <ZHeading size="h6">Settings</ZHeading>

        <Stack
          mt={8}
          mb={5}
          spacing={5}
        >
          <ZResponsiveFormControl id="linkName">
            <ZFormLabel>Link Name</ZFormLabel>
            <ZInput
              {...nameErrorProps}
              height="40px"
              name="linkName"
              boxSizing="border-box"
              onChange={handleChange}
              value={context.name || ''}
              placeholder="Set the MagicLink Name"
            />
          </ZResponsiveFormControl>

          <ZResponsiveFormControl id="linkMaxExecutions">
            <ZFormLabel>
              Order Limit
              <Popover
                placement="end"
                trigger="hover"
              >
                <PopoverTrigger>
                  <Icon
                    as={MdOutlineInfo}
                    data-testid="linkMaxExecutions-tooltip"
                    boxSize="18px"
                    color="atomicGray.400"
                    ml={0.5}
                  />
                </PopoverTrigger>
                <PopoverContent minW={'500px'}>
                  <PopoverBody
                    bg="rgba(3,3,20,.8)"
                    borderRadius={2}
                    p={2}
                  >
                    <ZText
                      fontSize="sm"
                      color="white"
                      fontWeight="normal"
                      whiteSpace="break-spaces"
                    >
                      The number of times a MagicLink can be redeemed in total. Learn more about MagicLinks in our{' '}
                      <UiLink
                        href="https://help.postal.com/helpcenter/s/article/Managing-Your-MagicLinks"
                        isExternal
                      >
                        Help Center
                      </UiLink>
                      .
                    </ZText>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </ZFormLabel>

            <ZInput
              {...maxExecutionsErrorProps}
              height="40px"
              type="number"
              min={1}
              name="linkMaxExecutions"
              onChange={handleChange}
              // blur the field on hover & scroll instead of change the value on scroll
              // seriously the number input change on scroll is the dumbest HTML feature ever
              onWheel={(e: any) => e.currentTarget.blur()}
              onScrollCapture={(e: any) => e.preventDefault()}
              value={context.maxExecutions || ''}
            />
          </ZResponsiveFormControl>

          <ZResponsiveFormControl
            id="linkExpirationDate"
            sx={{
              '& input.chakra-input': { height: '40px', fontSize: 'sm' },
              '& .chakra-input__left-element': { 'height': '100%', '& svg': { w: '16px', h: '16px' } },
              '& .chakra-input__right-element': { 'height': '100%', '& svg': { w: '12px', h: '12px' } },
            }}
          >
            <ZFormLabel data-testid="linkExpirationDate-label">
              Expires On
              <ZInfoTooltip
                label="Link will expire on 11:59pm of the date you choose"
                ml={0.5}
              />
            </ZFormLabel>

            <UiInputDate
              type="text"
              name="linkExpirationDate"
              value={context.date || ''}
              onChange={handleChange}
              data-min-date={addDays(startOfDay(new Date()), 1).toISOString()}
            />
          </ZResponsiveFormControl>
        </Stack>
      </Box>

      <Divider />

      <Box
        px={{ base: 4, md: 10 }}
        py={8}
        {...rest}
      >
        <Stack
          my={5}
          spacing={8}
        >
          {canToggleApprovedLinks && (
            <ZResponsiveFormControl id="linkNeedsApproval">
              <ZFormLabel onClick={(e) => e.preventDefault()}>
                Require approval before processing
                <ZInfoTooltip
                  className="info-tooltip"
                  transition="opacity: 0.5s"
                  whiteSpace="break-spaces"
                  label="Toggling this box on will require all accepted MagicLinks to be approved by the sender"
                  ml={0.5}
                  placement={isExtension ? 'bottom' : 'right'}
                />
              </ZFormLabel>
              <Flex justifyContent={{ base: 'flex-start', md: 'flex-end' }}>
                <UiToggle
                  colorScheme={getSendMethodColor(context).replace(/\.\d00/, '')}
                  size="lg"
                  name="linkNeedsApproval"
                  onChange={handleChange}
                  isChecked={context.linkNeedsApproval as boolean}
                />
              </Flex>
            </ZResponsiveFormControl>
          )}
          <ZResponsiveFormControl id="linkEnabled">
            <ZFormLabel
              onClick={(e) => e.preventDefault()}
              sx={{ whiteSpace: { base: 'normal', md: 'nowrap' } }}
            >
              Landing page is visible to anyone with the link and orders can be redeemed
            </ZFormLabel>
            <ZText
              color="atomicGray.600"
              fontWeight="normal"
              fontSize="xs"
              display="flex"
              justifyContent={{ base: 'flex-start', md: 'flex-end' }}
              alignItems="center"
            >
              Status: {context.enabled ? 'Active' : 'Disabled'}
              <UiToggle
                colorScheme={getSendMethodColor(context).replace(/\.\d00/, '')}
                ml={3}
                size="lg"
                name="linkEnabled"
                onChange={handleChange}
                isChecked={context.enabled}
              />
            </ZText>
          </ZResponsiveFormControl>
        </Stack>
      </Box>
    </>
  )
}
