import type { FlexProps } from '@chakra-ui/react'
import { Flex, Heading } from '@chakra-ui/react'
import { UiCard } from '@postal-io/postal-ui'
import { MdOutlineTimeline } from 'react-icons/md'

export const ExtAccountTimelinePlaceholder: React.FC<FlexProps> = (props) => {
  return (
    <Flex
      justify="center"
      {...props}
    >
      <UiCard
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="515px"
      >
        <Flex
          width="50px"
          height="50px"
          justifyContent="center"
          alignItems="center"
          bg="atomicBlue.100"
          borderWidth="1px"
          borderColor="atomicBlue.300"
          borderRadius="100%"
        >
          <MdOutlineTimeline
            size="30px"
            color="white"
          />
        </Flex>
        <Heading
          mt={4}
          size="xs"
        >
          Timeline view will populate once data is available.
        </Heading>
      </UiCard>
    </Flex>
  )
}
