import { Box } from '@chakra-ui/react'
import { ResponsivePie } from '@nivo/pie'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import { useAlertError, ZCard, ZCardHeader } from '@postal-io/postal-ui'
import { CrmReportType, GetCrmReportDocument } from 'api'
import { formatLongPrice, formatShortPrice } from 'lib'
import React, { useMemo } from 'react'
import { ReportingTooltip } from '.'
import { NoDataOverlay } from '../NoDataOverlay'
import { CenterValue } from './CenterValue'

interface PipelineV2Props extends ZCardProps {
  refresh: boolean
  system: string
}
export const Pipeline: React.FC<PipelineV2Props> = ({ refresh, system, ...rest }) => {
  const query = useGraphqlQuery(
    GetCrmReportDocument,
    {
      system,
      reportType: CrmReportType.PipelineInfluencedByDirectMailCampaigns,
      refresh,
    },
    { enabled: !!system, staleTime: 1000 * 60 }
  )

  useAlertError(query.error)

  const chartData = useMemo(() => {
    const records = query.data?.getCrmReport?.queryResults?.find((f) => f?.label === 'opportunity_data')?.result
      ?.records
    if (!records) return []
    return records
      .filter((r) => !!r.campaign_name && !!r.sum_amount)
      .map((r) => ({
        id: r.campaign_name,
        value: r.sum_amount,
        label: r.campaign_name,
      }))
  }, [query.data?.getCrmReport])

  const aggregateData = useMemo(() => {
    const records = query.data?.getCrmReport?.queryResults?.find((f) => f?.label === 'opportunity_data')?.result
      ?.records
    if (!records) return []
    return records.find((r) => !r.campaign_name)
  }, [query.data?.getCrmReport])

  return (
    <ZCard
      variant="form"
      {...rest}
    >
      <ZCardHeader
        whiteSpace="nowrap"
        p={4}
      >
        Pipeline Influenced by Direct Mail Campaigns
      </ZCardHeader>
      <Box h={{ base: '50vw', lg: '24vw' }}>
        {chartData.length ? (
          <>
            <CenterValue value={formatShortPrice(aggregateData.sum_amount)} />
            <ResponsivePie
              data={chartData}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.7}
              padAngle={1.5}
              cornerRadius={10}
              activeInnerRadiusOffset={-8}
              activeOuterRadiusOffset={8}
              borderWidth={3}
              colors={{ scheme: 'set2' }}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              arcLabel={({ value }) => formatShortPrice(value)}
              arcLinkLabelsSkipAngle={7}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color', modifiers: [['darker', 2]] }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 4]] }}
              valueFormat="=-.2s"
              tooltip={({ datum: { color, data } }) => (
                <ReportingTooltip
                  nameLabel="Campaign Name"
                  name={data.label}
                  valueLabel="Sum of Amount"
                  value={data.value}
                  formattedValue={formatLongPrice(data.value)}
                  totalAmount={aggregateData.sum_amount}
                  formattedTotal={formatShortPrice(aggregateData.sum_amount)}
                  color={color}
                />
              )}
            />
          </>
        ) : (
          <NoDataOverlay />
        )}
      </Box>
    </ZCard>
  )
}
