import { Box, Flex, Stack, StatNumber } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiCard,
  UiCardHeader,
  UiMoney,
  UiStat,
  UiStatGroup,
  UiStatLabel,
  useColor,
  useSessionStorage,
} from '@postal-io/postal-ui'
import type { PostalAnalyticsQueryVariables } from 'api'
import { Granularity, PostalAnalyticsDocument, ProductAccessType } from 'api'
import { endOfDay } from 'date-fns'
import type { MiniTeam } from 'hooks'
import { Curve, useChart, useChartTypes } from 'hooks'
import { calculateCpt, DEFAULT_ACCOUNT_TEAM_ID, StorageKeys } from 'lib'
import React, { useMemo } from 'react'
import { Chart } from 'react-charts'
import { ChartViewSelector } from '../ChartViewSelector'

interface DashboardStatsV2Props {
  team: MiniTeam | null
}

export const DashboardStats: React.FC<DashboardStatsV2Props> = ({ team }) => {
  const { colorCode } = useColor()
  const [priorDate, setPriorDate] = useSessionStorage(StorageKeys.DashboardStats, '3_MONTH')
  const startDate = useChartTypes(priorDate)
  const endDate = useMemo(() => endOfDay(new Date()), [])

  const filter = useMemo(() => {
    const newFilter: PostalAnalyticsQueryVariables = {
      startDate,
      endDate,
      granularity: Granularity.Weekly,
    }
    switch (team?.kind) {
      case ProductAccessType.Account:
        newFilter.teamId = DEFAULT_ACCOUNT_TEAM_ID
        break
      case ProductAccessType.Team:
        newFilter.teamId = team.teamId
        break
    }
    return newFilter
  }, [endDate, startDate, team?.kind, team?.teamId])

  const {
    data: weeklyData,
    isLoading,
    isFetching,
  } = useGraphqlQuery(PostalAnalyticsDocument, filter, { keepPreviousData: true })
  const weekly = weeklyData?.postalAnalytics

  //This is an aggregate and normalization of weekly data between the start date and end date
  const totalWeeklyAggregate = useMemo(() => {
    return {
      queued: weekly?.reduce((a, b) => a + (b.queued || 0), 0),
      failed: weekly?.reduce((a, b) => a + (b.failed || 0), 0),
      sent: weekly?.reduce((a, b) => a + (b.sent || 0), 0),
      delivered: weekly?.reduce((a, b) => a + (b.delivered || 0), 0),
      bounces: weekly?.reduce((a, b) => a + (b.bounces || 0), 0),
      touches: weekly?.reduce((a, b) => a + (b.touches || 0), 0),
      costInCents: weekly?.reduce((a, b) => a + (b.costInCents || 0), 0),
    }
  }, [weekly])

  const weeklyTotalCostOptions = useChart({
    label: 'Total Cost',
    rows: weekly?.map((week) => ({
      date: new Date(week.date),
      cost: <UiMoney cents={week.costInCents} />,
    })),
    curve: Curve.Linear,
    getSeriesStyle: () => ({ color: colorCode('secondary.500'), strokeWidth: 3 }),
  })

  const weeklyCptOptions = useChart({
    label: 'Weekly CPT',
    rows: weekly?.map((week) => ({
      date: new Date(week.date),
      cost: <UiMoney cents={calculateCpt(week)} />,
    })),
    curve: Curve.Linear,
    getSeriesStyle: () => ({ color: colorCode('tertiary.500'), strokeWidth: 3 }),
  })

  return (
    <Stack spacing={8}>
      <UiCard
        isLoading={isLoading}
        isFetching={isFetching}
      >
        <Flex direction="column">
          <ChartViewSelector
            priorDate={priorDate}
            setPriorDate={setPriorDate}
            alignSelf="flex-end"
            mb={5}
          />
          <UiStatGroup textAlign="center">
            <UiStat justifyContent="center">
              <UiStatLabel mb={2}>Total Cost</UiStatLabel>
              <StatNumber fontSize="4xl">
                <UiMoney cents={totalWeeklyAggregate.costInCents} />
              </StatNumber>
            </UiStat>
            <UiStat justifyContent="center">
              <UiStatLabel mb={2}>Total CPT</UiStatLabel>
              <StatNumber fontSize="4xl">
                <UiMoney cents={calculateCpt(totalWeeklyAggregate)} />
              </StatNumber>
            </UiStat>
            <UiStat justifyContent="center">
              <UiStatLabel mb={2}>Touches</UiStatLabel>
              <StatNumber fontSize="4xl">{totalWeeklyAggregate.touches}</StatNumber>
            </UiStat>
            <UiStat justifyContent="center">
              <UiStatLabel mb={2}>Delivered</UiStatLabel>
              <StatNumber fontSize="4xl">{totalWeeklyAggregate.delivered}</StatNumber>
            </UiStat>
          </UiStatGroup>
        </Flex>
      </UiCard>

      <UiCard
        isLoading={isLoading}
        isFetching={isFetching}
      >
        <UiCardHeader
          fontSize="2xl"
          textAlign="center"
        >
          Total Cost
        </UiCardHeader>
        <UiStat>
          <StatNumber fontSize="3xl">
            <UiMoney cents={totalWeeklyAggregate.costInCents} />
          </StatNumber>
        </UiStat>
        <Box h="300px">{weekly?.length && <Chart options={weeklyTotalCostOptions} />}</Box>
      </UiCard>

      <UiCard
        isLoading={isLoading}
        isFetching={isFetching}
      >
        <UiCardHeader
          fontSize="2xl"
          textAlign="center"
        >
          Cost Per Touch
        </UiCardHeader>
        <UiStat mt={4}>
          <StatNumber fontSize="3xl">
            <UiMoney cents={calculateCpt(totalWeeklyAggregate)} />
          </StatNumber>
        </UiStat>
        <Box h="300px">{weekly?.length && <Chart options={weeklyCptOptions} />}</Box>
      </UiCard>
    </Stack>
  )
}
