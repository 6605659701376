import { NotAllowedIcon } from '@chakra-ui/icons'
import type { IconProps } from '@chakra-ui/react'
import { Box, Flex, Icon } from '@chakra-ui/react'
import type { Column, UiSelectButtonProps } from '@postal-io/postal-ui'
import { GraphqlFilterTransform, humanize, UiCheckboxIcon, UiDate, UiLink, ZLink, ZText } from '@postal-io/postal-ui'
import type { User, UserFilterInput } from 'api'
import { Role, Status } from 'api'
import { isLocalOrTestEnvironment } from 'lib'
import { capitalize } from 'lodash'
import { FaUsersCog } from 'react-icons/fa'
import { MdOutlineBlock, MdOutlineCheckCircleOutline, MdOutlineHowToReg } from 'react-icons/md'

export interface SelectedUsers {
  users: User[]
  filter: UserFilterInput
  count: number
}

export const transforms = {
  'productAccess.teamId': GraphqlFilterTransform.Equal,
  'productAccess.roles': GraphqlFilterTransform.In,
  'lastName': GraphqlFilterTransform.Contains,
  'firstName': GraphqlFilterTransform.Contains,
  'userName': GraphqlFilterTransform.Contains,
}

export const DEFAULT_ROLES = [Role.Admin, Role.TeamAdmin, Role.Manager, Role.User]

export const columns: Column[] = [
  { label: 'Name', key: 'name' },
  { key: 'userName', label: 'User Name' },
  { key: 'teams', label: 'Teams' },
  { key: 'roles', label: 'Roles' },
  {
    label: 'Status',
    render: ({ status }: any) => {
      return (
        <>
          {status === Status.Active ? (
            <UiCheckboxIcon
              mr={2}
              mt="-3px"
              fontSize="16px"
              isChecked
            />
          ) : (
            <NotAllowedIcon
              mr={2}
              mt="-3px"
              fontSize="16px"
              color="gray.700"
            />
          )}
          <Box as="span">{status === Status.Active ? 'Enabled' : 'Disabled'}</Box>
        </>
      )
    },
  },
]

export const columnsV2: Column[] = [
  { label: 'Name', key: 'name' },
  { key: 'userName', label: 'User Name' },
  { key: 'teams', label: 'Teams' },
  { key: 'roles', label: 'Roles' },
  {
    label: 'Status',
    render: ({ status }: any) => {
      return (
        <Flex
          alignItems="center"
          gap={1}
        >
          {status === Status.Active ? (
            <Box color="atomicBlue.400">
              <MdOutlineCheckCircleOutline
                size="20px"
                color="inherit"
                style={{
                  transform: 'translateY(-1px)',
                }}
              />
            </Box>
          ) : (
            <Box color="atomicGray.500">
              <MdOutlineBlock
                size="20px"
                color="inherit"
                style={{
                  transform: 'translateY(-1px)',
                }}
              />
            </Box>
          )}
          <ZText>{status === Status.Active ? 'Enabled' : 'Disabled'}</ZText>
        </Flex>
      )
    },
  },
]

export const inviteColumns: Column[] = [
  { key: 'emailAddress', label: 'Email' },
  { label: 'Team', key: 'teamName' },
  { label: 'Invited By', key: 'invitedByUserName' },
  { label: 'Roles', render: ({ roles }: any) => roles.map(humanize).join(' / ') },
  {
    key: 'created.dateTime',
    orderBy: 'created',
    label: 'Invite Sent',
    render: ({ created }: any) => {
      return (
        <UiDate
          date={created?.dateTime}
          fallback="N/A"
        />
      )
    },
  },
  {
    label: 'Status',
    render: ({ status, id }: any) => {
      return isLocalOrTestEnvironment ? (
        <UiLink
          href={`/welcome/${id}`}
          isExternal
        >
          {capitalize(status)}
        </UiLink>
      ) : (
        capitalize(status)
      )
    },
  },
]

export const inviteColumnsV2: Column[] = [
  { key: 'emailAddress', label: 'Email' },
  { label: 'Team', key: 'teamName' },
  { label: 'Invited By', key: 'invitedByUserName' },
  { label: 'Roles', render: ({ roles }: any) => roles.map(humanize).join(' / ') },
  {
    key: 'created.dateTime',
    orderBy: 'created',
    label: 'Invite Sent',
    render: ({ created }: any) => {
      return (
        <UiDate
          date={created?.dateTime}
          fallback="N/A"
        />
      )
    },
  },
  {
    label: 'Status',
    render: ({ status, id }: any) => {
      return isLocalOrTestEnvironment ? (
        <ZLink
          href={`/welcome/${id}`}
          isExternal
        >
          {capitalize(status)}
        </ZLink>
      ) : (
        capitalize(status)
      )
    },
  },
]

export const buttons: {
  type: string
  icon: IconProps['name'] | any
  label: string
  placement: UiSelectButtonProps['placement']
}[] = [
  {
    type: 'TEAM_UPDATE',
    icon: <Icon as={FaUsersCog} />,
    label: 'Update Roles/Teams',
    placement: 'top',
  },
]

export const buttonsV2: {
  type: string
  icon: IconProps['name'] | any
  label: string
  placement: UiSelectButtonProps['placement']
}[] = [
  {
    type: 'TEAM_UPDATE',
    icon: <Icon as={MdOutlineHowToReg} />,
    label: 'Update Roles/Teams',
    placement: 'top',
  },
]
