import type { SlideProps } from '@chakra-ui/react'
import { Box, Grid, Slide } from '@chakra-ui/react'
import { CenteredBox } from 'components/Common'
import { PageTitle, useAcl } from 'hooks'
import React, { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ABMOrdersPlaceholder } from './ABMOrdersPlaceholder'
import { OrdersTypes } from './data'
import { OrdersCategory } from './OrdersCategory'
import { OrdersOverview } from './OrdersOverview'
import { OrdersSidebarBlocks } from './OrdersSidebarBlocks'

export const Orders: React.FC = () => {
  const { ownerId } = useParams() as any
  const { pathname } = useLocation()
  const { hasSubscription } = useAcl()
  const hasABM = hasSubscription('POSTAL_ABM_ACCESS')

  const pageTitle = useMemo(() => {
    switch (pathname) {
      case '/orders/drafts':
        return 'Drafts'
      case '/orders/emails':
        return 'Emails'
      case '/orders/links':
        return 'MagicLinks'
      case '/orders/direct':
        return 'Directs'
      case '/orders/subscriptions':
        return 'Subscriptions'
      case '/orders/triggers':
        return 'Triggers'
      case '/orders/group':
        return 'Group Orders'
      case '/orders/abm/campaigns':
        return 'ABM Campaigns'
      case '/orders/abm/group-emails':
        return 'ABM Group Emails'
      case '/orders':
      case '/orders/all':
      default:
        return 'Orders'
    }
  }, [pathname])

  // V2?
  const title = !!ownerId ? (ownerId === 'me' ? `My ${pageTitle}` : pageTitle) : pageTitle

  return (
    <>
      <PageTitle title={title} />
      <CenteredBox isLoaded>
        <Grid
          data-testid="UiSidebar_grid"
          gridTemplateColumns={{
            base: '100%',
            lg: `max-content minmax(0,1fr)`,
          }}
          gridGap={{ base: 4, xl: 8 }}
          alignItems="start"
          as="section"
          mt={16}
        >
          <Box
            w="100%"
            h="100%"
          >
            <OrdersSidebarBlocks />
          </Box>
          <Box>
            <ZSlide in={pathname === '/orders'}>
              <OrdersOverview />
            </ZSlide>

            <ZSlide in={pathname === '/orders/all'}>
              <OrdersCategory ordersType={OrdersTypes.All} />
            </ZSlide>

            <ZSlide in={pathname === '/orders/drafts'}>
              <OrdersCategory ordersType={OrdersTypes.Drafts} />
            </ZSlide>

            <ZSlide in={pathname === '/orders/emails'}>
              <OrdersCategory ordersType={OrdersTypes.Emails} />
            </ZSlide>

            <ZSlide in={pathname === '/orders/links'}>
              <OrdersCategory ordersType={OrdersTypes.Links} />
            </ZSlide>

            <ZSlide in={pathname === '/orders/direct'}>
              <OrdersCategory ordersType={OrdersTypes.Direct} />
            </ZSlide>

            <ZSlide in={pathname === '/orders/bulk'}>
              <OrdersCategory ordersType={OrdersTypes.BulkSends} />
            </ZSlide>

            <ZSlide in={pathname === '/orders/subscriptions'}>
              <OrdersCategory ordersType={OrdersTypes.Subscriptions} />
            </ZSlide>

            <ZSlide in={pathname === '/orders/triggers'}>
              <OrdersCategory ordersType={OrdersTypes.Triggers} />
            </ZSlide>

            <ZSlide in={pathname === '/orders/abm'}>
              {hasABM ? <OrdersCategory ordersType={OrdersTypes.ABM} /> : <ABMOrdersPlaceholder />}
            </ZSlide>
          </Box>
        </Grid>
      </CenteredBox>
    </>
  )
}

const ZSlide: React.FC<SlideProps> = (props) => (
  <Slide
    unmountOnExit
    direction="right"
    style={{ position: 'relative' }}
    {...props}
  />
)
