import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiCard, useAlertError, ZSidebar, ZSidebarBanner } from '@postal-io/postal-ui'
import { SearchIntegrationConnectionDocument, UserNotificationChannel } from 'api'
import { CenteredBox } from 'components/Common'
import { NotificationGeneralSettings } from 'components/Notifications/V2/NotificationGeneralSettings'
import { PageTitle, useAcl } from 'hooks'
import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { NotificationSummaryEmailSettings } from '../Notifications/V2/NotificationSummaryEmailSettings'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

export const NotificationSettings: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { hasFeature } = useAcl()
  const hasEmailDigest = hasFeature('emailDigest')

  const searchIntegrationConnectionQuery = useGraphqlQuery(SearchIntegrationConnectionDocument)
  useAlertError(searchIntegrationConnectionQuery.error)

  const channels = useMemo(() => {
    const _channels: UserNotificationChannel[] = []
    if (!!searchIntegrationConnectionQuery.data?.searchIntegrationConnection?.find((s) => s.system === 'slack')) {
      _channels.push(UserNotificationChannel.Slack)
    }
    return _channels
  }, [searchIntegrationConnectionQuery.data?.searchIntegrationConnection])

  const handleTabChange = (index: number) => {
    switch (index) {
      case 1:
        navigate('/notifications/summary')
        break
      default:
        navigate('/notifications/general')
    }
  }

  const tabIndex = useMemo(() => {
    switch (location.pathname) {
      case '/notifications/summary':
        return 1
      default:
        return 0
    }
  }, [location.pathname])

  const isLoading = searchIntegrationConnectionQuery.isLoading

  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner title="Notification Settings" />
        <PageTitle title="Notifications Settings" />
        <UiCard isLoading={isLoading}>
          <Tabs
            colorScheme="atomicBlue"
            defaultIndex={tabIndex}
            tabIndex={tabIndex}
            onChange={handleTabChange}
          >
            <TabList>
              <Tab>General</Tab>
              <Tab>Notification Summary Email</Tab>
            </TabList>
            <TabPanels>
              <TabPanel pt={4}>
                <NotificationGeneralSettings channels={channels} />
              </TabPanel>
              <TabPanel pt={8}>{hasEmailDigest && <NotificationSummaryEmailSettings />}</TabPanel>
            </TabPanels>
          </Tabs>
        </UiCard>
      </ZSidebar>
    </CenteredBox>
  )
}
