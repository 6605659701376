import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { GetBudgetRemainingDocument, GetFundsDocument, GetTeamByIdDocument } from 'api'
import { useSession } from './useSession'

export const useTeamWithBudget = (teamId?: string | null, userId?: string) => {
  const { session } = useSession()
  const { isLoading: budgetLoading, data: budget } = useGraphqlQuery(
    GetBudgetRemainingDocument,
    {
      accountId: session.accountId,
      teamId,
      userId,
    },
    { enabled: !!userId }
  )

  const { isLoading: teamLoading, data: team } = useGraphqlQuery(
    GetTeamByIdDocument,
    { id: teamId as string },
    { enabled: !!teamId }
  )
  const { isLoading: fundsLoading, data: getFunds } = useGraphqlQuery(
    GetFundsDocument,
    { billingAccountId: budget?.getBudgetRemaining?.applicableBudget?.billingAccountId as string },
    {
      enabled: !!budget?.getBudgetRemaining?.applicableBudget?.billingAccountId,
    }
  )

  const amt = budget?.getBudgetRemaining?.budgetRemaining ?? 0
  const budgetMode = budget?.getBudgetRemaining?.applicableBudget?.budget?.mode

  return {
    teamName: team?.getTeamById?.name,
    teamBudgetMode: budgetMode,
    teamBudget: amt,
    teamBalance: getFunds?.getFunds ?? 0,
    teamLoaded: [budgetLoading, teamLoading, fundsLoading].every(Boolean),
    team: team?.getTeamById,
  }
}
