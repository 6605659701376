export enum IndustryStandard {
  AverageOpenRate = 18,
  AverageClickRate = 2.6,
  AverageAcceptanceRate = 40,
}

export enum IndustryStandardV2 {
  AverageOpenRate = 21.5,
  AverageClickRate = 2.3,
  AverageAcceptanceRate = 40,
}

export const formatShortPrice = (dollars: number) => `$${formatShortNumber(dollars)}`

export const formatShortNumber = (number: number) => {
  if (number > 1e7) return `${Math.round(number / 1e6)}M`
  if (number > 1e6) return `${Math.round(number / 1e5) / 10}M`
  if (number > 1e4) return `${Math.round(number / 1e3)}K`
  if (number > 1e3) return `${Math.round(number / 1e2) / 10}K`
  return '' + number
}

export const formatLongPrice = (dollars: number) => `$${formatLongNumber(dollars)}`

export const formatLongNumber = (number: number) => `${new Intl.NumberFormat('en-US').format(number)}`
