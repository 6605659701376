import { Flex, SimpleGrid } from '@chakra-ui/react'
import type { UiChangeEvent } from '@postal-io/postal-ui'
import {
  UiFormControl,
  UiToggle,
  UiTooltip,
  ZCard,
  ZCardDivider,
  ZCurrencyCheckbox,
  ZCurrencyCheckboxGroup,
  ZFlowButton,
  ZFormLabel,
  ZInput,
  ZText,
} from '@postal-io/postal-ui'
import { AutoCompleteTeams } from 'components/AutoComplete'
import { zAutocompleteStyles, ZInfoTooltip } from 'components/Common/ZComponents'
import { AttributionSelect } from 'components/Integrations/AttributionSelect'
import { useAcl, useCollectionPermissions, useMe, useSession } from 'hooks'
import type { ChangeEvent } from 'react'
import React, { useEffect, useRef } from 'react'
import { MdChevronRight } from 'react-icons/md'
import type { Updater } from 'use-immer'
import type { Attribution } from '../../api'
import { Role } from '../../api'
import type { CollectionCreateState } from './CollectionCreateUpdate'

interface CollectionPropertiesProps {
  state: CollectionCreateState
  setState: Updater<CollectionCreateState>
  nextLabel: string
  next: () => void
  items: any
  errorMessage?: string
  selectedAttribution?: Attribution
  setSelectedAttribution: (a: Attribution) => void
}

export const CollectionProperties: React.FC<CollectionPropertiesProps> = ({
  state,
  setState,
  nextLabel,
  next,
  items,
  errorMessage,
  selectedAttribution,
  setSelectedAttribution,
}) => {
  const nameRef = useRef<HTMLInputElement>(null)
  const { session } = useSession()
  const { hasFeature, hasRole } = useAcl()
  const { canCreateShared } = useCollectionPermissions()
  const isAdmin = hasRole(Role.Admin)
  const bgColor = 'atomicBlue.400'
  const { approvedCurrencies } = useMe()

  useEffect(() => {
    setTimeout(() => nameRef?.current?.focus(), 500)
  }, [])

  const handleToggle = ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
    setState((draft: Record<string, any>) => {
      draft[name] = checked
    })
  }

  const handleCurrency = (e: UiChangeEvent<string[]>) => {
    setState((draft: Record<string, any>) => {
      draft.currency = e.value ? e.value[e.value.length - 1] : ''
    })
  }

  const currencyStyleProps = {
    symbol: { color: 'atomicGray.600' },
    currency: { color: 'atomicGray.500', fontSize: '14px' },
  }

  return (
    <Flex justifyContent="center">
      <ZCard
        variant="stripe"
        colorScheme="atomicGray"
        maxW="700px"
        border="1px solid"
        borderColor="atomicGray.200"
        flexGrow={1}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault()
            !errorMessage && next()
          }}
        >
          <Flex
            justify="center"
            p={8}
          >
            <SimpleGrid
              columns={1}
              spacing={8}
              width="100%"
              maxW="500px"
            >
              <UiFormControl
                id="name"
                isRequired
              >
                <ZFormLabel mb={{ base: 1, md: 1 }}>Collection Name</ZFormLabel>
                <ZInput
                  ref={nameRef}
                  name="name"
                  placeholder="This is only visible within your organization"
                  value={state.name || ''}
                  onChange={(e: any) =>
                    setState((draft) => {
                      draft.name = e.target.value
                      if (!draft.displayNameDirty) draft.displayName = e.target.value
                    })
                  }
                  isRequired
                />
              </UiFormControl>

              <UiFormControl
                id="displayName"
                isRequired
              >
                <Flex alignItems="left">
                  <ZFormLabel mb={{ base: 1, md: 1 }}>Display Name</ZFormLabel>
                  <ZInfoTooltip label="This name will be shown externally to your contacts, in gift emails and landing pages" />
                </Flex>
                <ZInput
                  name="displayName"
                  placeholder="This is the name your recipients will see"
                  value={state.displayName || ''}
                  onChange={(e: any) =>
                    setState((draft) => {
                      draft.displayNameDirty = true
                      draft.displayName = e.target.value
                    })
                  }
                  isRequired
                />
              </UiFormControl>

              <UiFormControl id="enableCollection">
                <UiToggle
                  name="enabled"
                  isChecked={state.enabled}
                  onChange={handleToggle}
                  colorScheme="atomicBlue"
                >
                  <ZText>Enable Collection</ZText>
                </UiToggle>
              </UiFormControl>

              {hasFeature('userCreatedCollections') && canCreateShared && !session.teamId && isAdmin && (
                <UiFormControl id="teamIds">
                  <ZFormLabel mb={{ base: 1, md: 1 }}>
                    Teams
                    <ZInfoTooltip
                      ml={2}
                      label="You can restrict this collection to certain teams"
                    />
                  </ZFormLabel>
                  <AutoCompleteTeams
                    value={state.teamIds}
                    onChange={(data) => setState((draft) => void (draft.teamIds = data))}
                    styles={zAutocompleteStyles}
                  />
                </UiFormControl>
              )}

              {/* hide currency selection if we've already selected items (since the currency is already determined) */}
              {!items?.length && (
                <UiFormControl
                  id="currency"
                  isRequired
                >
                  <ZFormLabel>Currency</ZFormLabel>
                  <ZCurrencyCheckboxGroup
                    name="currency"
                    value={state.currency ? [state.currency] : []}
                    onChange={handleCurrency}
                    styleProps={currencyStyleProps}
                  >
                    <SimpleGrid
                      columns={4}
                      spacing={2}
                      m={0}
                    >
                      {approvedCurrencies.map((option) => (
                        <ZCurrencyCheckbox
                          key={option}
                          value={option}
                        />
                      ))}
                    </SimpleGrid>
                  </ZCurrencyCheckboxGroup>
                </UiFormControl>
              )}

              {isAdmin && (
                <>
                  <ZCardDivider my={4} />
                  <AttributionSelect
                    fontWeight="normal"
                    textAlign="left"
                    attribution={selectedAttribution}
                    onChange={setSelectedAttribution}
                    reverse={true}
                  />
                  <ZCardDivider mt={4} />
                </>
              )}
              <input
                type="submit"
                style={{ display: 'none' }}
              />
              <UiTooltip label={errorMessage}>
                <ZFlowButton
                  color="white"
                  bg={bgColor}
                  _hover={!errorMessage ? { bg: bgColor, opacity: 0.8 } : {}}
                  _active={!errorMessage ? { bg: bgColor, opacity: 0.9 } : {}}
                  rightIcon={<MdChevronRight />}
                  onClick={next}
                  isDisabled={!!errorMessage}
                >
                  {nextLabel}
                </ZFlowButton>
              </UiTooltip>
            </SimpleGrid>
          </Flex>
        </form>
      </ZCard>
    </Flex>
  )
}
