import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { isArray } from 'lodash'
import { useCallback, useMemo } from 'react'
import { DeleteUserActionDocument, MeDocument, RecordUserActionDocument } from '../api'

export enum CompletedAction {
  AccountManager = 'accountManager',
  UpdateProfile = 'updateProfile',
  AddContacts = 'addContacts',
  Marketplace = 'marketplace',
  CreatePostal = 'createPostal',
  SendCampaign = 'sendCampaign',
  Reporting = 'reporting',
  ChromeExtension = 'chromeExtension',
  ChromeExtensionNotification = 'chromeExtensionNotification',
  DismissIntegrationTopbar = 'dismissIntegrationTopbar',
  FlipCategory = 'flipCategory',
  EventsNotification = 'eventsNotification',
  SurveyNpsRating = 'surveyNpsRating202104',
  WarehousingForm = 'WarehousingForm',
  DismissDomesticTransferTip = 'dismissDomesticTransferTip',
  DismissForeignTransferTip = 'dismissForeignTransferTip',
  DismissBulkSendRedactedAddressTip = 'dismissBulkSendRedactedAddressTip',
}

export const useCompletedActions = () => {
  const meQuery = useGraphqlQuery(MeDocument)

  const recordUserAction = useGraphqlMutation(RecordUserActionDocument)
  const deleteUserAction = useGraphqlMutation(DeleteUserActionDocument)

  const completedActions = useMemo(() => {
    return meQuery.data?.me?.completedActions || {}
  }, [meQuery.data?.me?.completedActions])

  // accepts single action or array of actions
  // ignore failures
  const markComplete = useCallback(
    async (actionsToComplete: string | string[]) => {
      const actions = isArray(actionsToComplete) ? actionsToComplete : [actionsToComplete]
      return Promise.all(actions.map((name) => recordUserAction.mutateAsync({ name })))
    },
    [recordUserAction]
  )

  //removes a users action
  const markIncomplete = useCallback(
    async (actionsToComplete: string | string[]) => {
      const actions = isArray(actionsToComplete) ? actionsToComplete : [actionsToComplete]
      for await (const action of actions) {
        try {
          await deleteUserAction.mutateAsync({ name: action })
        } catch (_) {}
      }
    },
    [deleteUserAction]
  )

  const isComplete = useCallback(
    (action: CompletedAction) => {
      return !!completedActions[action]
    },
    [completedActions]
  )

  return {
    me: meQuery.data?.me,
    isLoading: meQuery.isLoading,
    completedActions,
    markComplete,
    markIncomplete,
    isComplete,
  }
}
