import type { UiSelectTypeaheadProps } from '@postal-io/postal-ui'
import { SelectTypeaheadStylesV2, UiSelectTypeahead } from '@postal-io/postal-ui'
import { useCallback, useState } from 'react'
import type { MiniTeam } from '../../hooks'
import { useMiniTeams } from '../../hooks'
export function AutoCompleteTeam(props: UiSelectTypeaheadProps<MiniTeam>) {
  const [search, setSearch] = useState(props.inputValue || props.defaultInputValue || '')
  const { teams, isLoading } = useMiniTeams(search, 20)

  const handleInput = useCallback(
    (val: any, meta: any) => {
      setSearch(val)
      props.onInputChange && props.onInputChange(val, meta)
    },
    [props]
  )

  const { onInputChange, ...rest } = props

  return (
    <UiSelectTypeahead
      data-testid="AutoCompleteTeam"
      options={teams}
      getOptionLabel={(t) => t.name}
      getOptionValue={(t) => t.teamId || t.accountId}
      onInputChange={handleInput}
      isLoading={isLoading}
      placeholder="Search Teams"
      noOptionsMessage={() => 'No Teams Found'}
      {...SelectTypeaheadStylesV2}
      {...rest}
    />
  )
}
