import React from 'react'
import { Rect } from 'react-konva'

export const BLEED_COLOR = '#48E0A9'

export interface BleedLineProps {
  width: number
  height: number
}

export const BleedLine: React.FC<BleedLineProps> = ({ width, height }) => {
  return (
    <Rect
      x={0}
      y={0}
      stroke={BLEED_COLOR}
      strokeWidth={0.75}
      dash={[6, 6]}
      dashEnabled={true}
      width={width}
      height={height}
      fillEnabled={false}
      listening={false}
    />
  )
}
