import { Container, Stack } from '@chakra-ui/react'
import { ZCard, ZCardBody, ZCardHeader } from '@postal-io/postal-ui'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { PageTitle } from 'hooks'
import { SavedReportsTable } from './SavedReportsTable'

export const SavedReports = () => {
  return (
    <>
      <PageTitle
        title="Saved Reports"
        section="Reporting"
      />
      <Container
        maxW="1440px"
        px={0}
        mt={8}
      >
        <Stack
          spacing={8}
          data-testid="Reporting_SavedReports"
        >
          <ZCard variant="form">
            <ZCardHeader
              fontSize="lg"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              p={4}
              pb={0}
              gap={1}
            >
              Saved Exports
              <ZInfoTooltip
                aria-label="Saved Reports"
                label="View previously generated reports"
                placement="right"
                hasArrow
              />
            </ZCardHeader>
            <ZCardBody p={4}>
              <SavedReportsTable />
            </ZCardBody>
          </ZCard>
        </Stack>
      </Container>
    </>
  )
}
