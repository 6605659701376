import { NotAllowedIcon } from '@chakra-ui/icons'
import type { BoxProps } from '@chakra-ui/react'
import { Box, HStack, Icon, Wrap, WrapItem } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  humanize,
  joinStrings,
  UiAlert,
  UiCheckboxIcon,
  UiDate,
  UiDateTime,
  UiLink,
  UiRenderRichText,
  ZCard,
  ZCardBody,
  ZCardDivider,
  ZCardHeader,
  ZHeading,
  ZText,
} from '@postal-io/postal-ui'
import type { Address, AddressInput, OrderPreview } from 'api'
import { AddressStatus, FulfillmentType, GetBillingAccountDocument, GetContactDocument, Status } from 'api'
import { OrderAttribute } from 'components/Orders/OrderAttributes'
import { CATEGORY } from 'components/Postals'
import React, { useMemo } from 'react'
import { MdOutlineCheckCircle, MdOutlineEmail, MdOutlinePhone } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { AnalyticsEventV2, useAcl, useAnalyticsEvent, useExtension } from '../../hooks'
import { ZFormLabel } from '../Common/ZComponents'
import { BasicPostalImageCarousel, PostalDirectMailCarousel } from '../Postal/PostalCarousel'
import { PostalReviewContacts } from './PostalReviewContacts'
import { PostalReviewEmailNotifications } from './PostalReviewEmailNotifications'
import { PostalReviewPopOver } from './PostalReviewPopOver'
import { PostalReviewSendAs } from './PostalReviewSendAs'
import { canIncludeLandingPage, hasContactSelect, PostalSendMethod } from './postalSendHelpers'
import type { PostalSendContext, PostalSendEventV2 } from './usePostalSend'
import { giftMessageTitle, physicalMessageTitle, PostalSendType, SendAsType } from './usePostalSend'
import { useTemplatePreview } from './useTemplatePreview'

interface PostalReviewV2Props {
  context: PostalSendContext
  send?: (evt: PostalSendEventV2) => void
  error?: string | null
  previewOrder?: OrderPreview
  preventOrder?: boolean | null
}

export const PostalReview: React.FC<PostalReviewV2Props> = ({ context, error, previewOrder, preventOrder }) => {
  const { hasPermission, hasFeature } = useAcl()
  const hasBilling = hasPermission('billing.update')
  const hasMeetingDefault = hasFeature('meetingSettings')

  const hasSendAs = context.sendAsType === SendAsType.ContactOwner || context.sendAsType === SendAsType.User

  const isDirectMail = context.postal?.category === CATEGORY.DirectMail

  const hasVariantFlexibility = context.postal?.variantOrderFlexibility

  const { physicalMessagePreview } = useTemplatePreview(context)

  const { orderBlockedReason, fundsRemaining, cost, billingAccountId, emailIntegrationSuccess } = previewOrder || {}

  const fulfillmentType = context.parentVariant?.fulfillmentPartnerList?.find((p) => p.fulfillmentType)?.fulfillmentType

  const getBillingAccount = useGraphqlQuery(
    GetBillingAccountDocument,
    { id: billingAccountId as string },
    { enabled: !!billingAccountId && !!hasBilling }
  )
  const billingAccount = getBillingAccount?.data?.getBillingAccount

  // only show for magiclink
  const showFundsWarning = useMemo(() => {
    if (
      !previewOrder ||
      context.type !== PostalSendType.Link ||
      getBillingAccount.isLoading ||
      billingAccount?.autoReload
    ) {
      return false
    }
    return (fundsRemaining || 0) < (cost?.total || 0) * (context.maxExecutions || 0)
  }, [
    billingAccount?.autoReload,
    context.maxExecutions,
    context.type,
    cost?.total,
    fundsRemaining,
    getBillingAccount.isLoading,
    previewOrder,
  ])

  const showFundsMessage = useMemo(() => {
    if (hasBilling) {
      return `You don’t have enough funds to cover this MagicLink. Make sure to add to your balance in billing account "${billingAccount?.name}" so they can be redeemed.`
    } else {
      return `You don’t have enough funds to cover this MagicLink. Make sure your administrator adds to your balance so they can be redeemed.`
    }
  }, [billingAccount?.name, hasBilling])

  const eventEmailNotificationCheck = useMemo(() => {
    const e = context?.postal?.event
    return (
      e?.sendEmailConfirmation ||
      e?.sendReminderDayBefore ||
      e?.sendReminderDayOf ||
      e?.sendCancelledAlert ||
      e?.sendInviteExpiringAlert ||
      e?.sendMeetingLinkChanged
    )
  }, [context?.postal?.event])

  const isEvent = useMemo(() => context?.postal?.category === 'Events', [context?.postal?.category])

  const { isExtension } = useExtension()
  useAnalyticsEvent({ event: AnalyticsEventV2.ExtensionSendFlowReviewStep, disabled: !isExtension })

  return (
    <>
      <ZCard
        variant="form"
        borderWidth={{ base: 0, md: 1 }}
      >
        <ZCardBody pt={{ base: 8, md: 8 }}>
          <HStack
            spacing={5}
            alignItems={{ base: 'flex-start', md: 'center' }}
          >
            <Box>
              {isDirectMail ? (
                <PostalDirectMailCarousel
                  designTemplate={context.postal?.designTemplate}
                  userMessage={physicalMessagePreview}
                  containerProps={{ borderRadius: '3px', h: '100px', w: '100px' }}
                  imageProps={{ objectFit: 'cover' }}
                />
              ) : (
                <BasicPostalImageCarousel
                  postal={context.postal}
                  containerProps={{ borderRadius: '3px', h: '100px', w: '100px' }}
                  imageProps={{ objectFit: 'cover' }}
                />
              )}
            </Box>

            <Box>
              <ZHeading
                as="h2"
                size="h5"
              >
                {context.postal?.name}
              </ZHeading>
              <Wrap
                mt={5}
                spacing={8}
              >
                <OrderAttribute label="Type">{context.postal?.type || '-'}</OrderAttribute>

                <OrderAttribute label={!hasVariantFlexibility ? 'Selected Option' : 'Recipient Choice'}>
                  {!hasVariantFlexibility
                    ? context.variant?.variantName
                    : context.postal?.variants
                        ?.filter((item) => item.status === Status.Active)
                        .map((v) => v.variantName)
                        .join(', ')}
                </OrderAttribute>

                {/* {context.method === PostalSendMethod.BulkSend && (
                  <OrderAttribute label="Quantity">
                    {new Intl.NumberFormat('en-US').format(context.quantity!)}
                  </OrderAttribute>
                )} */}

                {hasMeetingDefault && !!context.meetingRequestSetting && (
                  <OrderAttribute label="Meeting Request">{humanize(context.meetingRequestSetting)}</OrderAttribute>
                )}
              </Wrap>
            </Box>
          </HStack>

          {preventOrder && (
            <UiAlert
              title={orderBlockedReason as string}
              mt={8}
              status="error"
              hideClose
            />
          )}
          {error && (
            <UiAlert
              title={error as string}
              mt={8}
              status="error"
              hideClose
            />
          )}
          {showFundsWarning && (
            <UiAlert
              mt={8}
              status="warning"
              title={showFundsMessage}
              hideClose
            />
          )}
          {emailIntegrationSuccess === false && (
            <UiAlert
              mt={8}
              status="warning"
              hideClose
            >
              Your email or calendar integration seems to be disconnected. Please{' '}
              <UiLink
                fontSize="md"
                as={Link}
                to={'/profile'}
                isExternal={isExtension}
              >
                connect
              </UiLink>{' '}
              and try again.
            </UiAlert>
          )}
        </ZCardBody>

        {context.method === PostalSendMethod.Email && <EmailInfo context={context} />}

        {context.method === PostalSendMethod.BulkSend && <BulkSendInfo context={context} />}

        {canIncludeLandingPage(context) && <LandingPageInfo context={context} />}

        {context.usePhysicalMessage && context.physicalMessage && <PhysicalMessageInfo context={context} />}

        {context.postal?.category === CATEGORY.Events && eventEmailNotificationCheck && (
          <>
            <ZCardDivider />
            <ZCardBody>
              <ZHeading size="h6">Email Notifications</ZHeading>
              <PostalReviewEmailNotifications
                mt={4}
                context={context}
              />
            </ZCardBody>
          </>
        )}

        {hasContactSelect(context) && (
          <>
            <ZCardDivider />
            <ZCardBody>
              <ZHeading size="h6">Contacts</ZHeading>
              <PostalReviewContacts
                mt={5}
                context={context}
              />
            </ZCardBody>
          </>
        )}
      </ZCard>

      {context.method === PostalSendMethod.Link && <LinkInfo context={context} />}
      {/* {context.postal?.category === CATEGORY.Events && eventEmailNotificationCheck && (
        <ZCard
          variant="form"
          mt={5}
        >
          <ZCardBody>
            <ZHeading size="h6">Event Notifications</ZHeading>
            <PostalReviewEmailNotifications context={context} />
          </ZCardBody>
        </ZCard>
      )} */}

      {(context.type === PostalSendType.Campaign || !!context.isCampaign) && <CampaignInfo context={context} />}

      {context.type === PostalSendType.PlaybookStep && <PlaybookInfo context={context} />}

      {fulfillmentType === FulfillmentType.Physical && (
        <ZCard
          variant="form"
          mt={5}
          borderWidth={{ base: 0, md: 1 }}
        >
          <ZCardBody>
            <ZHeading size="h6">Shipping</ZHeading>
            <ZText
              mt={5}
              fontWeight="bold"
              color="atomicGray.600"
            >
              {context.method === PostalSendMethod.BulkSend
                ? 'Note: Bulk Send orders require more time to pack and ship than standard Postal orders. Ship to a location that accepts items for your event or use case. Always coordinate with your venue and recipient to ensure deliverability of your items. Please note that shipping costs may vary for bulk items.'
                : context.method === PostalSendMethod.Direct
                ? `Item ships 3-5 business days after ${isEvent ? 'registration closes' : 'the order is placed'}.`
                : `Item ships 3-5 business days after ${
                    isEvent ? 'registration closes' : 'the recipient accepts the item'
                  }.`}
            </ZText>
          </ZCardBody>
        </ZCard>
      )}

      {hasSendAs && <SendingOptionsInfo context={context} />}
    </>
  )
}

const EmailInfo: React.FC<{ context: PostalSendContext }> = ({ context }) => {
  const { emailSubjectPreview, giftMessagePreview } = useTemplatePreview(context)

  return (
    <>
      <ZCardDivider />

      <ZCardBody>
        <ZCardHeader alignItems="flex-start">
          <ZHeading size="h6">{giftMessageTitle(context)}</ZHeading>
          <PostalReviewPopOver context={context} />
        </ZCardHeader>

        {context.emailSubjectLine && (
          <>
            <Box data-testid="PostalReview_emailSubjectLine">
              <ZFormLabel
                mt={10}
                color="atomicGray.500"
                fontWeight="bold"
              >
                Subject
              </ZFormLabel>
              <UiRenderRichText
                mt={3}
                fontSize="sm"
                html={emailSubjectPreview || ''}
              />
            </Box>
          </>
        )}
        {context.giftMessage && (
          <Box
            data-testid="PostalReview_giftMessage"
            mt={5}
          >
            <ZFormLabel
              color="atomicGray.500"
              fontWeight="bold"
            >
              Body
            </ZFormLabel>
            <UiRenderRichText
              mt={3}
              fontSize="sm"
              html={giftMessagePreview || ''}
            />
          </Box>
        )}
      </ZCardBody>
    </>
  )
}

const PhysicalMessageInfo: React.FC<{ context: PostalSendContext }> = ({ context }) => {
  const { physicalMessagePreview } = useTemplatePreview(context)

  return (
    <>
      <ZCardDivider />

      <ZCardBody>
        <ZHeading size="h6">{physicalMessageTitle(context)}</ZHeading>
        <Box
          data-testid="PostalReview_physicalMessage"
          mt={10}
        >
          <ZFormLabel
            color="atomicGray.500"
            fontWeight="bold"
          >
            Message
          </ZFormLabel>
          <UiRenderRichText
            mt={3}
            fontSize="sm"
            html={physicalMessagePreview || ''}
          />
        </Box>
      </ZCardBody>
    </>
  )
}

const LandingPageInfo: React.FC<{ context: PostalSendContext }> = ({ context }) => {
  const { landingPageBodyPreview, landingPageHeaderPreview } = useTemplatePreview(context)

  return (
    <>
      <ZCardDivider />

      <ZCardBody>
        <ZCardHeader alignItems="flex-start">
          <ZHeading size="h6">Landing Page</ZHeading>
          <PostalReviewPopOver
            context={context}
            isLandingPage
          />
        </ZCardHeader>
        {landingPageHeaderPreview && (
          <Box data-testid="PostalReview_landingPage_title">
            <ZFormLabel
              color="atomicGray.500"
              fontWeight="bold"
              mt={5}
            >
              Subject
            </ZFormLabel>
            <UiRenderRichText
              mt={3}
              fontSize="sm"
              html={landingPageHeaderPreview || ''}
            />
          </Box>
        )}
        {landingPageBodyPreview && (
          <Box
            data-testid="PostalReview_landingPage_body"
            mt={5}
          >
            <ZFormLabel
              color="atomicGray.500"
              fontWeight="bold"
            >
              Body
            </ZFormLabel>
            <UiRenderRichText
              mt={3}
              fontSize="sm"
              html={landingPageBodyPreview || ''}
            />
          </Box>
        )}
      </ZCardBody>
    </>
  )
}

const BulkSendInfo: React.FC<{ context: PostalSendContext }> = ({ context }) => {
  const { shipToAddress, contacts, newContact } = context
  // one contact selected === this contact selected for bulk send
  const contactId = contacts?.items?.length === 1 ? contacts?.items[0].id : undefined

  // get contact info if contact selected (otherwise contact info comes from newContact form)
  const contactQuery = useGraphqlQuery(GetContactDocument, { id: contactId as string }, { enabled: !!contactId })
  const contact = useMemo(() => contactQuery.data?.getContact, [contactQuery.data?.getContact])
  const contactPhone = contact?.phones?.find((p: any) => p?.type === 'WORK') ?? contact?.phones?.[0]

  // pick info from either existing contact or new contact info
  const contactInfo = useMemo(() => (contactId ? contact : newContact), [contact, contactId, newContact])
  const phoneNumber = useMemo(
    () => contactPhone?.phoneNumber ?? newContact?.phoneNumber ?? '',
    [contactPhone, newContact?.phoneNumber]
  )

  return (
    <>
      <ZCardDivider />

      <ZCardBody>
        <ZCardHeader alignItems="flex-start">
          <ZHeading size="h6">Bulk Send Details</ZHeading>
        </ZCardHeader>

        <HStack
          spacing={10}
          alignItems="flex-start"
        >
          <Box>
            <ZFormLabel
              mt={10}
              color="atomicGray.500"
              fontWeight="bold"
            >
              Recipient
            </ZFormLabel>
            {<ZText data-private>{joinStrings([contactInfo?.firstName, contactInfo?.lastName])}</ZText>}
            {/* <ZCardDivider my={2} /> */}
            <AddressBlock
              address={shipToAddress}
              mb={4}
            />
            {contactInfo?.emailAddress && (
              <ZText
                data-private
                fontSize="body-sm"
                display="flex"
                alignItems="center"
              >
                <Icon
                  as={MdOutlineEmail}
                  color="atomicGray.500"
                  mr={2}
                />
                {contactInfo?.emailAddress}
              </ZText>
            )}
            {phoneNumber && (
              <ZText
                data-private
                fontSize="body-sm"
                display="flex"
                alignItems="center"
              >
                <Icon
                  as={MdOutlinePhone}
                  color="atomicGray.500"
                  mr={2}
                />
                {phoneNumber}
              </ZText>
            )}
          </Box>
          <Box>
            <ZFormLabel
              mt={10}
              color="atomicGray.500"
              fontWeight="bold"
            >
              Quantity
            </ZFormLabel>
            {new Intl.NumberFormat('en-US').format(context.quantity ?? 0)}
          </Box>
        </HStack>
      </ZCardBody>
    </>
  )
}

const CampaignInfo: React.FC<{ context: PostalSendContext }> = ({ context }) => (
  <ZCard
    variant="form"
    mt={5}
    borderWidth={{ base: 0, md: 1 }}
  >
    <ZCardBody data-testid="PostalReview_campaign">
      <ZHeading size="h6">Campaign Details</ZHeading>
      <Wrap
        mt={5}
        spacing={20}
      >
        <WrapItem display="block">
          <ZFormLabel
            color="atomicGray.500"
            fontWeight="bold"
          >
            Campaign Name
          </ZFormLabel>
          <ZText>{context.name}</ZText>
        </WrapItem>

        <WrapItem display="block">
          <ZFormLabel
            color="atomicGray.500"
            fontWeight="bold"
          >
            Send On
          </ZFormLabel>
          <ZText>
            {context.postal?.category === CATEGORY.DirectMail ? (
              <UiDate date={context.date} />
            ) : (
              <UiDateTime date={context.date} />
            )}
          </ZText>
        </WrapItem>
      </Wrap>
    </ZCardBody>
  </ZCard>
)

const PlaybookInfo: React.FC<{ context: PostalSendContext }> = ({ context }) => (
  <ZCard
    variant="form"
    mt={5}
    borderWidth={{ base: 0, md: 1 }}
  >
    <ZCardBody data-testid="PostalReview_playbook">
      <ZHeading size="h6">Subscription Details</ZHeading>
      <Wrap
        mt={5}
        spacing={20}
      >
        <WrapItem display="block">
          <ZFormLabel
            color="atomicGray.500"
            fontWeight="bold"
          >
            Step Delay (in days){' '}
          </ZFormLabel>
          <ZText>{context.name}</ZText>
        </WrapItem>

        <WrapItem display="block">
          <ZFormLabel
            color="atomicGray.500"
            fontWeight="bold"
          >
            Send On
          </ZFormLabel>
          <ZText>+ {context.delay} Days</ZText>
        </WrapItem>
      </Wrap>
    </ZCardBody>
  </ZCard>
)

const LinkInfo: React.FC<{ context: PostalSendContext }> = ({ context }) => (
  <ZCard
    variant="form"
    pt={{ base: 4, md: 8 }}
    mt={4}
    borderWidth={{ base: 0, md: 1 }}
  >
    <ZCardBody>
      <ZHeading size="h6">MagicLink Details</ZHeading>

      <Wrap
        mt={5}
        spacing={20}
      >
        <WrapItem display="block">
          <ZFormLabel
            color="atomicGray.500"
            fontWeight="bold"
          >
            MagicLink Name
          </ZFormLabel>
          <ZText>{context.name}</ZText>
        </WrapItem>
        <WrapItem display="block">
          <ZFormLabel
            color="atomicGray.500"
            fontWeight="bold"
          >
            Status
          </ZFormLabel>
          <ZText
            display="flex"
            alignItems="center"
            size="md"
            gap={2}
          >
            {context.enabled ? (
              <UiCheckboxIcon
                verticalAlign="middle"
                isChecked
              />
            ) : (
              <NotAllowedIcon
                color="gray.700"
                verticalAlign="middle"
              />
            )}
            {context.enabled ? 'Enabled' : 'Disabled'}
          </ZText>
        </WrapItem>
        <WrapItem display="block">
          <ZFormLabel
            color="atomicGray.500"
            fontWeight="bold"
          >
            Order Limit
          </ZFormLabel>
          <ZText>{context.maxExecutions}</ZText>
        </WrapItem>
        <WrapItem display="block">
          <ZFormLabel
            color="atomicGray.500"
            fontWeight="bold"
          >
            Expires On
          </ZFormLabel>
          <UiDate
            color="gray.700"
            date={context.date}
            flex="66.67%"
            fallback="Never"
          />
        </WrapItem>
      </Wrap>
    </ZCardBody>
  </ZCard>
)

interface AddressBlockProps extends BoxProps {
  address?: Address | AddressInput | Address | null
}
const AddressBlock: React.FC<AddressBlockProps> = ({ address, ...rest }) => (
  <Box {...rest}>
    {address?.address1 && <ZText data-private>{address?.address1}</ZText>}
    {address?.address2 && <ZText data-private>{address?.address2}</ZText>}
    {address?.address3 && <ZText data-private>{address?.address3}</ZText>}
    {(address?.city || address?.state || address?.postalCode) && (
      <ZText data-private>
        {joinStrings([address?.city, address?.state], ', ')} {address?.postalCode}
      </ZText>
    )}
    <ZText
      fontSize="body-sm"
      color="atomicGray.500"
      display="flex"
      alignItems="center"
    >
      {address?.status === AddressStatus.Matched ? (
        <>
          Verified Address{' '}
          <Icon
            as={MdOutlineCheckCircle}
            color="atomicBlue.400"
            ml={1}
          />
        </>
      ) : (
        'Non-verified Address'
      )}
    </ZText>
  </Box>
)

const SendingOptionsInfo: React.FC<{ context: PostalSendContext }> = ({ context }) => (
  <ZCard
    variant="form"
    mt={5}
  >
    <ZCardBody>
      <ZHeading size="h6">Sending Options</ZHeading>
      <Wrap
        mt={5}
        spacing={20}
      >
        <WrapItem display="block">
          <ZFormLabel
            color="atomicGray.500"
            fontWeight="bold"
          >
            Send As
          </ZFormLabel>
          <PostalReviewSendAs
            context={context}
            data-testid="PostalReview_sendAs"
          />
        </WrapItem>
      </Wrap>
    </ZCardBody>
  </ZCard>
)
