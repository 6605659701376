import { Box, Icon, Link } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  LexendFontWeight,
  UiAccordion,
  UiAccordionButton,
  UiAccordionIcon,
  UiAccordionItem,
  UiAccordionPanel,
  useAlertError,
  ZButton,
  ZFlowButton,
  ZLogoMinimal,
  ZText,
} from '@postal-io/postal-ui'
import type { Contact } from 'api'
import { GetCrmAccountByIdentifierDocument } from 'api'
import { AnalyticsEventV2, useAcl, useAnalyticsSend } from 'hooks'
import React from 'react'
import { MdChevronRight, MdLink, MdSend, MdTimeline } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ExtHeader } from '../Main/ExtHeader'
import { ExtContactProfile } from './ExtContactProfile'
import { ExtContactProfileActivity } from './ExtContactProfileActivity'
import { ExtContactStats } from './ExtContactStats'

interface ExtContactViewProps {
  contact: Contact
}
export const ExtContactView: React.FC<ExtContactViewProps> = ({ contact }) => {
  const navigate = useNavigate()
  const { hasFeature } = useAcl()

  const crmIdentifier = contact.mappings?.find((m) => m.system === 'sfdc' && !!m.accountId)?.accountId

  const hasFeatureABM = hasFeature('abmV1')
  // otherwise it may just be a Lead; no CRmAccount data available
  const hasSFDCAcount = !!crmIdentifier

  const getCrmAccountByIdentifierQuery = useGraphqlQuery(
    GetCrmAccountByIdentifierDocument,
    { crmIdentifier: crmIdentifier as string },
    { enabled: hasFeatureABM && hasSFDCAcount }
  )
  const crmAccount = getCrmAccountByIdentifierQuery?.data?.getCrmAccountByIdentifier
  useAlertError(getCrmAccountByIdentifierQuery.error)

  const handleViewTimeline = () => navigate(`/extension/accounts/${crmIdentifier}/timeline`)
  const sendAnalytics = useAnalyticsSend()

  return (
    <Box p={4}>
      <ExtHeader>
        <Link
          href={'/items'}
          isExternal
          p={1}
          borderRadius=".25rem"
          _hover={{ bg: 'rgba(213,215,218,.3)' }}
        >
          <Icon
            as={ZLogoMinimal}
            color="gray.900"
            fontSize="103px"
            h="auto"
          />
        </Link>
      </ExtHeader>
      <ExtContactProfile
        mt={8}
        contact={contact}
      />
      <ExtContactStats contact={contact} />
      <UiAccordion
        defaultIndex={[0]}
        allowMultiple
        mt={4}
      >
        {hasFeatureABM && hasSFDCAcount && (
          <ZFlowButton
            leftIcon={<MdTimeline size="18px" />}
            rightIcon={<MdChevronRight size="18px" />}
            colorScheme="atomicBlue"
            variant="outline"
            onClick={handleViewTimeline}
          >
            See {crmAccount?.name}'s Timeline
          </ZFlowButton>
        )}

        <UiAccordionItem
          mt={3}
          borderTop={0}
        >
          <h2>
            <UiAccordionButton
              px={0}
              _hover={{ bg: 'WhiteAlpha.50' }}
            >
              <Box
                flex="1"
                textAlign="left"
              >
                Activity History
              </Box>
              <UiAccordionIcon />
            </UiAccordionButton>
          </h2>
          <UiAccordionPanel
            pb={4}
            px={0}
          >
            <ExtContactProfileActivity contactId={contact.id} />
          </UiAccordionPanel>
        </UiAccordionItem>
      </UiAccordion>
      <Box
        display="flex"
        position="fixed"
        bottom={4}
        right={4}
      >
        <ZButton
          leftIcon={
            <MdLink
              fontSize="lg"
              color="#A3AAB1"
            />
          }
          bg="atomicGray.5"
          border={1}
          borderStyle="solid"
          borderColor="atomicGray.100"
          sx={{
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08)',
            borderRadius: '66px',
          }}
          onClick={() => navigate('/extension/magiclinks')}
          _hover={{ bg: 'atomicGray.50' }}
          _active={{ bg: 'atomicGray.50' }}
        >
          <ZText
            color="atomicGray.900"
            size="md"
            fontWeight={LexendFontWeight.Bold}
          >
            MagicLinks
          </ZText>
        </ZButton>
        <ZButton
          ml={2}
          leftIcon={
            <MdSend
              fontSize="lg"
              color="white"
            />
          }
          colorScheme="atomicBlue"
          sx={{
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08)',
            borderRadius: '66px',
          }}
          onClick={() => {
            sendAnalytics({ event: AnalyticsEventV2.ExtensionSendItemButtonClicked })
            navigate('/extension/marketplace')
          }}
        >
          <ZText
            color="whites.400"
            size="md"
            fontWeight={LexendFontWeight.Bold}
          >
            Send Item
          </ZText>
        </ZButton>
      </Box>
    </Box>
  )
}
