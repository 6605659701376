import { Box, Flex, Grid } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import {
  UiButtonScrollTop,
  UiSidePanelContainer,
  useAlertError,
  useInfiniteScroll,
  ZProductCard,
} from '@postal-io/postal-ui'
import type { MarketplaceProduct } from 'api'
import { SearchMarketplaceProductsDocument } from 'api'
import { CATEGORY } from 'components/Postals'
import { MARKETPLACE_PRODUCT_FILTER_INPUT_KEYS } from 'components/Postals/data'
import { AnalyticsEvent, useAnalyticsEvent, useAnalyticsSend } from 'hooks'
import { cloneDeep, omit, pick } from 'lodash'
import React, { useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { SidePanelFilter, SubnavFilters } from '../Common'
import { EVENT_CARD_MIN_WIDTH } from './data'
import { EventNotFound } from './EventCard'
import { EventsCardProduct } from './EventsCardProduct'
import { EventsMarketplaceProductSidebarBanner } from './EventsMarketplaceProductSidebarBanner'
import { EventsContext } from './EventsPage'

const LIMIT = 20

const INCLUDE_FILTERS = ['shipTo', 'currency', 'fulfillmentType', 'type']

export const EventsViewProducts: React.FC = () => {
  const navigate = useNavigate()
  const sendAnalytics = useAnalyticsSend()

  const { eventFilters, setFilterResultsCount } = useContext(EventsContext)
  const numFilters = Object.keys(eventFilters.filters ?? []).length

  useAnalyticsEvent({ event: AnalyticsEvent.MarketplaceFilterSelected, data: { filter: eventFilters } })
  useAnalyticsEvent({ event: AnalyticsEvent.EventsAllItemsPageViewed })

  // override subnav filters to lock in category
  const subnavFilters = omit(cloneDeep(eventFilters.filters), ['category', 'eventStatus', 'teamIds'])

  const filter = useMemo(
    () => pick(eventFilters.graphqlFilter, MARKETPLACE_PRODUCT_FILTER_INPUT_KEYS),
    [eventFilters.graphqlFilter]
  )

  const searchProducts = useGraphqlInfiniteQuery(SearchMarketplaceProductsDocument, {
    filter,
    limit: LIMIT,
  })
  useAlertError(searchProducts.error)

  const products = useMemo(
    () => searchProducts.mergedData?.searchMarketplaceProducts || [],
    [searchProducts.mergedData?.searchMarketplaceProducts]
  )

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: searchProducts.hasNextPage,
    loadMore: searchProducts.fetchNextPage,
    loading: searchProducts.isFetching,
  })

  const handleSelectProduct = (item: MarketplaceProduct) => {
    sendAnalytics({
      event: AnalyticsEvent.EventsAllItemsEventClicked,
      data: { marketplaceProductId: item.id, itemName: item.name },
    })
    navigate(`/events/marketplace/${item.id}`, { state: { returnTo: 'All Events' } })
  }

  useEffect(() => {
    setFilterResultsCount?.(products.length)
  }, [products.length, setFilterResultsCount])

  return (
    <>
      <SubnavFilters
        data-testid="Event_Marketplace_SubnavFilters"
        filters={subnavFilters}
        onUpdate={eventFilters.updateFilter}
        onClear={eventFilters.clearFilters}
        display={numFilters < 1 ? 'none' : undefined}
      />
      <UiSidePanelContainer>
        <SidePanelFilter
          filters={eventFilters.filters}
          onUpdate={eventFilters.updateFilter}
          filterType="Marketplace"
          restrictCategory={CATEGORY.Events}
          includeFilters={INCLUDE_FILTERS}
          excludeFilters={['outOfStock']}
          topBlock={<EventsMarketplaceProductSidebarBanner />}
        />

        <Flex
          direction="column"
          w="100%"
          ref={topRef}
          style={{ scrollMargin: '20px' }}
        >
          <Grid
            templateColumns={`repeat(auto-fill, minmax(${EVENT_CARD_MIN_WIDTH}px, 1fr))`}
            gap={8}
          >
            {products.map((item) => (
              <EventsCardProduct
                key={item.id}
                product={item}
                onSelect={handleSelectProduct}
              />
            ))}
            {searchProducts.isFetching && products.length < 1 && (
              <ZProductCard
                isLoading
                size="tall"
                w={EVENT_CARD_MIN_WIDTH}
                startColor="atomicGray.50"
                endColor="atomicGray.200"
              />
            )}
            {!searchProducts.isFetching && products.length < 1 && <EventNotFound />}
          </Grid>
          {products.length >= LIMIT && (
            <Box
              ref={bottomRef}
              position="relative"
            >
              <UiButtonScrollTop
                onClick={scrollTop}
                position="absolute"
                top="0px"
                right="0px"
                isLoading={searchProducts.isFetching}
                aria-label="scroll button"
              />
            </Box>
          )}
        </Flex>
      </UiSidePanelContainer>
    </>
  )
}
