import { Box, Flex, Image } from '@chakra-ui/react'
import { UiText } from '@postal-io/postal-ui'
import statImage from 'assets/reporting/stats.svg'

export const NoDataOverlay = () => {
  return (
    <Box
      pos="absolute"
      top={0}
      left={0}
      bottom={0}
      right={0}
      zIndex={3}
      bg="whiteAlpha.800"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        h="100%"
      >
        <Image src={statImage} />
        <UiText
          textAlign="center"
          fontWeight="semibold"
          fontSize="2xl"
          color="gray.800"
          mt={2}
          p={2}
        >
          These stats will show once we have collected
          <br />
          enough data
        </UiText>
      </Flex>
    </Box>
  )
}
