import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiLoading } from '@postal-io/postal-ui'
import { PostalSendMethod } from 'components/PostalSend/postalSendHelpers'
import { useNavigateLinkEditParams } from 'hooks/useNavigateLinkEdit'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetApprovedPostalDocument, GetMagicLinkDocument, MagicLinkStatus } from '../../api'
import { PostalSend, PostalSendType } from '../PostalSend'

export const LinkEdit: React.FC = () => {
  const navigate = useNavigate()
  const { linkId, returnTo, returnPath } = useNavigateLinkEditParams()

  const getMagicLink = useGraphqlQuery(GetMagicLinkDocument, { id: linkId })
  const link = getMagicLink.data?.getMagicLink

  const getApprovedPostal = useGraphqlQuery(
    GetApprovedPostalDocument,
    { id: link?.approvedPostalId as string },
    { enabled: !!link?.approvedPostalId }
  )

  const approvedPostal = getApprovedPostal.data?.getApprovedPostal
  const variant = useMemo(
    () => approvedPostal?.variants?.find((v) => v.id === link?.variantId),
    [approvedPostal?.variants, link?.variantId]
  )

  if (getApprovedPostal.isLoading || getMagicLink.isLoading) return <UiLoading />

  return (
    <PostalSend
      onNavigateBack={() => navigate(returnPath)}
      navigateBackLabel={`Back to ${returnTo}`}
      onExitLabel={`Exit to ${returnTo}`}
      method={PostalSendMethod.Link}
      link={link}
      name={link?.name}
      postal={approvedPostal!}
      variant={variant}
      giftMessage={link?.message}
      physicalMessage={link?.physicalMessage}
      usePhysicalMessage={!!link?.physicalMessage}
      landingPageHeaderText={link?.landingPageCustomization?.headerText}
      landingPageBody={link?.landingPageCustomization?.body}
      landingPageIncludeHeadshot={!!link?.landingPageCustomization?.includeHeadshot}
      landingPageIncludeSenderName={!!link?.landingPageCustomization?.includeSenderName}
      date={link?.expirationDate}
      maxExecutions={link?.maxExecutions || undefined}
      enabled={link?.status === MagicLinkStatus.Disabled ? false : undefined}
      type={PostalSendType.Link}
      sendAsContactOwner={link?.sendAsContactOwner}
      sendAsUser={link?.sendAsUser}
      spendAsTeamId={link?.spendAs?.teamId as string}
      spendAsUserId={link?.spendAs?.userId}
      meetingRequestSetting={link?.meetingRequestSetting}
      itemCustomizationInputs={link?.itemCustomizationInputs}
      formFieldList={link?.formFieldList}
      linkNeedsApproval={link?.requiresApproval}
    />
  )
}
