import { Box, Slide } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import { internalTableProps, UiButton, UiSSDataTable, useAlertError, ZText } from '@postal-io/postal-ui'
import type { CampaignFilterInput } from 'api'
import { CampaignOrderByInput, CampaignStatus, CampaignType, SearchCampaignsDocument, SendType } from 'api'
import { useAcl } from 'hooks'
import { set } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import type { PostalFulfillmentFilterInput } from '../../api/index'
import { FulfillmentStatus } from '../../api/index'
import { GROUP_ORDERS_TYPES, OrdersTypes } from './data'
import { useDrafts, useFulfillments } from './hooks'
import { OrdersCard, useOrdersCardHeaderContent } from './OrdersCard'
import {
  ALL_CAMPAIGNS_COLUMNS,
  ALL_ORDERS_COLUMNS,
  ORDERS_TYPE_COLUMN_MAP,
  TYPED_CAMPAIGNS_COLUMNS,
} from './ordersColumns'

const LIMIT = 100
const PAGE_SIZE = 10

export const ORDER_SEND_MAP: { [k: string]: SendType } = {
  [OrdersTypes.Links]: SendType.MagicLink,
  [OrdersTypes.Emails]: SendType.GiftEmail,
  [OrdersTypes.Direct]: SendType.Direct,
  [OrdersTypes.Subscriptions]: SendType.Subscription,
  [OrdersTypes.Triggers]: SendType.Trigger,
  [OrdersTypes.BulkSends]: SendType.BulkSend,
}

interface OrdersCategoryProps {
  ordersType: OrdersTypes
  excludeCampaigns?: boolean
  limit?: number
  showAllLink?: boolean
  searchString?: string
}
export const OrdersCategory: React.FC<OrdersCategoryProps> = ({ ordersType, limit, showAllLink }) => {
  const [searchString, setSearchString] = useState<string>('')

  useEffect(() => {
    setSearchString('')
  }, [ordersType])

  const HeaderRightContent = useOrdersCardHeaderContent({
    ordersType,
    showAllLink,
    searchString,
    setSearchString,
  })

  return ordersType === OrdersTypes.ABM ? (
    <ABMOrdersCard
      ordersType={ordersType}
      columns={ORDERS_TYPE_COLUMN_MAP[ordersType].columns}
      groupColumns={ORDERS_TYPE_COLUMN_MAP[ordersType].groupColumns ?? TYPED_CAMPAIGNS_COLUMNS}
      limit={limit}
      showAllLink={showAllLink}
    />
  ) : GROUP_ORDERS_TYPES.includes(ordersType) ? (
    <OrdersAndGroupOrdersCard
      ordersType={ordersType}
      columns={ORDERS_TYPE_COLUMN_MAP[ordersType].columns}
      groupColumns={ORDERS_TYPE_COLUMN_MAP[ordersType].groupColumns ?? TYPED_CAMPAIGNS_COLUMNS}
      limit={limit}
      showAllLink={showAllLink}
    />
  ) : (
    <OrdersCard
      type={ordersType}
      showAllLink={showAllLink}
      headerRightContent={HeaderRightContent}
    >
      {ordersType === OrdersTypes.Drafts ? (
        <DraftsContent
          searchString={searchString}
          ordersType={ordersType}
          columns={ORDERS_TYPE_COLUMN_MAP[ordersType].columns}
          limit={limit}
        />
      ) : (
        <OrdersContent
          searchString={searchString}
          ordersType={ordersType}
          columns={ORDERS_TYPE_COLUMN_MAP[ordersType].columns}
          limit={limit}
        />
      )}
    </OrdersCard>
  )
}

interface OrdersCardTabProps {
  isActive: boolean
  onClick: () => void
  activeColor?: string
  children: any
  icon: any
}
const OrdersCardTab: React.FC<OrdersCardTabProps> = ({
  isActive,
  activeColor = 'atomicBlue.500',
  icon: Icon,
  onClick,
  children,
}) => (
  <UiButton
    variant="naked"
    fontSize="md"
    px={0}
    pt={0}
    height="55px"
    mr="50px"
    my={-4}
    fontWeight="normal"
    color={isActive ? 'atomicGray.800' : 'atomicGray.500'}
    _hover={{ color: 'atomicGray.800' }}
    onClick={onClick}
    borderRadius={0}
    transition="0.2s border-width, 0.2s color"
    borderBottomWidth={isActive ? '3px' : '0px'}
    borderColor={'atomicGray.400'}
  >
    <Icon
      size="18px"
      color={isActive ? activeColor : 'inherit'}
      opacity={isActive ? 1 : 0.3}
    />
    <ZText
      as="span"
      ml={2}
      fontSize="lg"
      color="inherit"
    >
      {children}
    </ZText>
  </UiButton>
)

export interface OrdersTabsProps {
  color: string
  icon: any
  baseLabel: string
  groupLabel?: string
}
interface OrdersAndGroupOrdersCardProps {
  ordersType: OrdersTypes
  columns: any[]
  limit?: number
  showAllLink?: boolean
  groupColumns: any[]
}
const OrdersAndGroupOrdersCard: React.FC<OrdersAndGroupOrdersCardProps> = ({
  ordersType,
  columns,
  limit,
  showAllLink,
  groupColumns,
}) => {
  const [showGroupOrders, setShowGroupOrders] = useState<boolean>(false)
  const [searchString, setSearchString] = useState<string>('')
  const [scheduledOrders, setScheduledOrders] = useState<boolean>(false)

  useEffect(() => setShowGroupOrders(false), [ordersType])

  const tabs = ({ color, icon, baseLabel, groupLabel }: OrdersTabsProps) => (
    <Box>
      <OrdersCardTab
        activeColor={color}
        icon={icon}
        isActive={!showGroupOrders}
        onClick={() => setShowGroupOrders(false)}
      >
        {baseLabel}
      </OrdersCardTab>

      <OrdersCardTab
        activeColor={color}
        icon={icon}
        isActive={showGroupOrders}
        onClick={() => setShowGroupOrders(true)}
      >
        {groupLabel ? groupLabel : `Group ${baseLabel}`}
      </OrdersCardTab>
    </Box>
  )

  const headerRightContent = useOrdersCardHeaderContent({
    ordersType,
    showAllLink,
    showGroupOrders,
    searchString,
    setSearchString,
    scheduledOrders,
    setScheduledOrders,
  })

  return (
    <OrdersCard
      type={ordersType}
      tabs={tabs}
      overflow="hidden"
      headerRightContent={headerRightContent}
    >
      <Slide
        style={{ position: 'relative' }}
        in={!showGroupOrders}
        direction="left"
        unmountOnExit
      >
        <OrdersContent
          searchString={searchString}
          ordersType={ordersType}
          columns={columns}
          limit={limit}
          excludeCampaigns
        />
      </Slide>
      <Slide
        style={{ position: 'relative' }}
        in={showGroupOrders}
        direction="right"
        unmountOnExit
      >
        <GroupOrdersContent
          searchString={searchString}
          scheduledOrders={scheduledOrders}
          ordersType={ordersType}
          columns={groupColumns}
          limit={limit}
        />
      </Slide>
    </OrdersCard>
  )
}

interface ABMOrdersCardProps {
  ordersType: OrdersTypes
  columns: any[]
  limit?: number
  showAllLink?: boolean
  groupColumns: any[]
}
const ABMOrdersCard: React.FC<ABMOrdersCardProps> = ({ ordersType, columns, limit, showAllLink, groupColumns }) => {
  const { hasFeature } = useAcl()
  const hasABMGroupEmails = hasFeature('abmGroupEmails')

  const [showGroupOrders, setShowGroupOrders] = useState<boolean>(false)
  const [searchString, setSearchString] = useState<string>('')
  const [scheduledOrders, setScheduledOrders] = useState<boolean>(false)

  useEffect(() => setShowGroupOrders(false), [ordersType])

  const tabs = ({ color, icon }: OrdersTabsProps) => (
    <Box>
      <OrdersCardTab
        activeColor={color}
        icon={icon}
        isActive={!showGroupOrders}
        onClick={() => setShowGroupOrders(false)}
      >
        Campaigns
      </OrdersCardTab>
      {hasABMGroupEmails && (
        <OrdersCardTab
          activeColor={color}
          icon={icon}
          isActive={showGroupOrders}
          onClick={() => setShowGroupOrders(true)}
        >
          Group Emails
        </OrdersCardTab>
      )}
    </Box>
  )

  const headerRightContent = useOrdersCardHeaderContent({
    ordersType,
    showAllLink,
    showGroupOrders,
    searchString,
    setSearchString,
    scheduledOrders,
    setScheduledOrders,
  })

  return (
    <OrdersCard
      type={ordersType}
      tabs={tabs}
      overflow="hidden"
      headerRightContent={headerRightContent}
    >
      <Slide
        style={{ position: 'relative' }}
        in={!showGroupOrders}
        direction="left"
        unmountOnExit
      >
        <ABMCampaignContent
          searchString={searchString}
          columns={columns}
          limit={limit}
        />
      </Slide>
      <Slide
        style={{ position: 'relative' }}
        in={showGroupOrders}
        direction="right"
        unmountOnExit
      >
        <GroupOrdersContent
          searchString={searchString}
          scheduledOrders={scheduledOrders}
          ordersType={ordersType}
          columns={groupColumns}
          limit={limit}
        />
      </Slide>
    </OrdersCard>
  )
}

interface OrdersContentProps extends Omit<OrdersCategoryProps, 'ordersType'> {
  ordersType?: OrdersTypes
  columns: any[]
  campaignId?: string
}
export const OrdersContent: React.FC<OrdersContentProps> = ({
  ordersType,
  excludeCampaigns,
  campaignId,
  columns,
  searchString,
  limit = LIMIT,
}) => {
  const [page, setPage] = useState<number>(1)

  const filter = useMemo(() => {
    const filter: PostalFulfillmentFilterInput = {}

    // OrdersTypes.All will not get used here
    const sendType = ordersType && ORDER_SEND_MAP?.[ordersType]
    if (sendType) set(filter, 'sendType.eq', sendType)
    if (excludeCampaigns) set(filter, 'campaignId.exists', false)
    if (campaignId) set(filter, 'campaignId.eq', campaignId)
    if (searchString) set(filter, 'itemName.contains', searchString)

    switch (ordersType) {
      case OrdersTypes.Links:
        set(filter, 'status.ne', FulfillmentStatus.PendingUserApproval)
        break
    }
    return filter
  }, [ordersType, excludeCampaigns, campaignId, searchString])

  const orders = useFulfillments(limit, filter)
  useAlertError(orders.error)

  useEffect(() => {
    setPage(1)
  }, [searchString])

  const showPagination = limit >= PAGE_SIZE

  return (
    <UiSSDataTable
      rowKey="id"
      columns={columns ?? ALL_ORDERS_COLUMNS}
      fetchMore={showPagination ? orders.fetchNextPage : undefined}
      hasMore={showPagination ? orders.hasNextPage : undefined}
      rows={orders.data}
      variant="list"
      pageSize={Math.min(limit, PAGE_SIZE)}
      page={page}
      setPage={setPage}
      tableProps={internalTableProps}
    />
  )
}

const DraftsContent: React.FC<OrdersContentProps> = ({ limit = LIMIT, searchString, columns }) => {
  const drafts = useDrafts({ limit: LIMIT, savedSendName: { contains: searchString } })
  useAlertError(drafts.error)

  const showPagination = limit >= PAGE_SIZE

  return (
    <UiSSDataTable
      rowKey="id"
      columns={columns}
      fetchMore={showPagination ? drafts.fetchNextPage : undefined}
      hasMore={showPagination ? drafts.hasNextPage : undefined}
      filter={drafts.variables.filter}
      rows={drafts.data}
      variant="list"
      pageSize={Math.min(limit, PAGE_SIZE)}
      tableProps={internalTableProps}
    />
  )
}

const GroupOrdersContent: React.FC<OrdersContentProps & { scheduledOrders: boolean }> = ({
  ordersType,
  searchString,
  scheduledOrders,
  columns,
  limit = LIMIT,
}) => {
  const navigate = useNavigate()

  const filter = useMemo(() => {
    const filter: CampaignFilterInput = {
      campaignType: { in: ordersType === OrdersTypes.ABM ? [CampaignType.AbmClassic] : [CampaignType.Engage, null] },
    }
    if (ordersType === OrdersTypes.Direct) set(filter, 'deliveryEmail.eq', false)
    if (ordersType === OrdersTypes.Emails) set(filter, 'deliveryEmail.eq', true)
    if (searchString) set(filter, 'name.contains', searchString)
    if (scheduledOrders) set(filter, 'status.eq', CampaignStatus.Scheduled)

    return filter
  }, [ordersType, scheduledOrders, searchString])

  const { mergedData, hasNextPage, fetchNextPage, error } = useGraphqlInfiniteQuery(SearchCampaignsDocument, {
    limit,
    filter,
    orderBy: CampaignOrderByInput.CreatedDesc,
  })
  const campaigns = useMemo(() => mergedData?.searchCampaigns ?? [], [mergedData])
  useAlertError(error)

  const onClickItemName = useCallback((id: string) => navigate(`/orders/group/${id}`), [navigate])

  const rows = useMemo(() => campaigns.map((c) => ({ ...c, onClickItemName })), [campaigns, onClickItemName])

  const showPagination = limit >= PAGE_SIZE

  return (
    <UiSSDataTable
      rowKey="id"
      columns={columns ?? ALL_CAMPAIGNS_COLUMNS}
      fetchMore={showPagination ? fetchNextPage : undefined}
      hasMore={showPagination ? hasNextPage : undefined}
      rows={rows}
      variant="list"
      pageSize={Math.min(limit, PAGE_SIZE)}
      tableProps={internalTableProps}
    />
  )
}

const ABMCampaignContent: React.FC<OrdersContentProps> = ({ searchString, columns, limit = LIMIT }) => {
  const navigate = useNavigate()
  const filter = useMemo(() => {
    const filter: CampaignFilterInput = {
      campaignType: { in: [CampaignType.Abm] },
    }
    if (searchString) set(filter, 'name.contains', searchString)

    return filter
  }, [searchString])

  const { mergedData, hasNextPage, fetchNextPage, error } = useGraphqlInfiniteQuery(SearchCampaignsDocument, {
    limit,
    filter,
    orderBy: CampaignOrderByInput.CreatedDesc,
  })
  const campaigns = useMemo(() => mergedData?.searchCampaigns ?? [], [mergedData])
  useAlertError(error)

  const onClickItemName = useCallback((id: string) => navigate(`/orders/group/${id}`), [navigate])

  const rows = useMemo(() => campaigns.map((c) => ({ ...c, onClickItemName })), [campaigns, onClickItemName])

  const showPagination = limit >= PAGE_SIZE

  return (
    <UiSSDataTable
      rowKey="id"
      columns={columns ?? ALL_CAMPAIGNS_COLUMNS}
      fetchMore={showPagination ? fetchNextPage : undefined}
      hasMore={showPagination ? hasNextPage : undefined}
      rows={rows}
      variant="list"
      pageSize={Math.min(limit, PAGE_SIZE)}
      tableProps={internalTableProps}
    />
  )
}
