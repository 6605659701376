import { Flex, Grid, Icon, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import type { Column, UiSelectButtonProps } from '@postal-io/postal-ui'
import {
  internalProgressBarProps,
  internalTableProps,
  joinStrings,
  UiSelectButton,
  UiSSDataTable,
  useAlertError,
  useGraphqlFilter,
  ZButton,
  ZCard,
  ZCardBody,
  ZCheckbox,
  ZInput,
  ZLink,
} from '@postal-io/postal-ui'
import { UserAccountsEdit } from 'components/User/UserAccountsEdit'
import type { ChangeEvent, ReactElement } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { MdOutlineHowToReg } from 'react-icons/md'
import { Link } from 'react-router-dom'
import type { SearchUsersQueryVariables, User } from '../../api'
import { SearchNoAccessUsersDocument } from '../../api'
import { useAcl } from '../../hooks'
import { BulkUserAccountUpdate } from './BulkUserAccountUpdate'
import type { SelectedUsers } from './UsersData'
import { transforms } from './UsersData'

const COLUMNS: Column[] = [
  {
    label: 'Name',
    render: (user: User) => {
      return (
        <ZLink
          to={`/users/${user.id}`}
          as={Link}
        >
          {joinStrings([user.firstName, user.lastName])}
        </ZLink>
      )
    },
  },
  { key: 'userName', label: 'User Name' },
  {
    label: 'Roles',
    render: ({ onRoles }: any) => {
      return <ZLink onClick={onRoles}>No Access</ZLink>
    },
  },
]

const BUTTONS: {
  type: string
  icon: ReactElement
  label: string
  placement: UiSelectButtonProps['placement']
}[] = [
  {
    type: 'TEAM_UPDATE',
    icon: <Icon as={MdOutlineHowToReg} />,
    label: 'Add to Team',
    placement: 'top',
  },
]

const LIMIT = 100
const staticVariables = { limit: LIMIT }

export const UsersDisabledTable = () => {
  const { hasPermission } = useAcl()
  const canUpdate = hasPermission('users.update')
  const teamUpdate = useDisclosure()
  const [selectedUser, setSelectedUser] = useState<User>()
  const editRoles = useDisclosure()

  const { variables, filter, setFilter, orderBy, setOrderBy } = useGraphqlFilter<SearchUsersQueryVariables>({
    transforms,
    staticVariables,
    debounce: 400,
  })

  const searchUsers = useGraphqlInfiniteQuery(SearchNoAccessUsersDocument, variables)
  const { users, totalRecords } = useMemo(() => {
    return {
      users: (searchUsers.mergedData?.searchNoAccessUsers?.users as User[]) || [],
      totalRecords: searchUsers.mergedData?.searchNoAccessUsers?.resultsSummary?.totalRecords,
    }
  }, [
    searchUsers.mergedData?.searchNoAccessUsers?.resultsSummary?.totalRecords,
    searchUsers.mergedData?.searchNoAccessUsers?.users,
  ])

  //Selected users from the data table
  const [selectedUsers, setSelectedUsers] = useState<SelectedUsers>()

  const handleRoles = useCallback(
    (user: User) => {
      setSelectedUser(user)
      editRoles.onOpen()
    },
    [editRoles]
  )

  const normalizedUsers = useMemo(() => {
    return users.map((user) => ({ ...user, onRoles: () => handleRoles(user) }))
  }, [handleRoles, users])

  const onSelect = (selected: User[]) => {
    setSelectedUsers({
      users: selected,
      filter: { id: { in: selected.map((c) => c.id) } },
      count: selected.length,
    })
  }

  const onSelectButton = (type: string) => {
    switch (type) {
      case 'TEAM_UPDATE':
        return teamUpdate.onOpen()
      default:
    }
  }

  const handleInput = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFilter({ key: target.name, value: target.value })
  }

  useAlertError(searchUsers.error)

  const buttonsEnabled = !!selectedUsers?.users?.length

  return (
    <>
      <Flex
        justifyContent="flex-start"
        alignItems="flex-start"
        h="40px"
      >
        {canUpdate && (
          <Flex
            justifyContent="flex-start"
            alignItems="center"
            mr={2}
            h="40px"
          >
            {BUTTONS.map(({ type, placement, ...button }) => (
              <UiSelectButton
                h="40px"
                w="40px"
                fontSize="26px"
                border="none"
                aria-label={`UserTable ${type} button`}
                key={type}
                placement={placement}
                isDisabled={!buttonsEnabled}
                onClick={() => onSelectButton(type)}
                colorScheme="atomicGray"
                color="atomicGray.500"
                {...button}
              />
            ))}
          </Flex>
        )}
        <Grid
          gap={4}
          templateColumns="repeat(auto-fit, minmax(200px, 1fr))"
          alignItems="end"
          w="100%"
        >
          <ZInput
            type="search"
            name="lastName"
            onChange={handleInput}
            value={filter.lastName || ''}
            placeholder="Search Last Name"
            aria-label="Search Last Name"
          />
          <ZInput
            type="search"
            name="firstName"
            onChange={handleInput}
            value={filter.firstName || ''}
            placeholder="Search First Name"
            aria-label="Search First Name"
          />
          <ZInput
            type="search"
            name="userName"
            onChange={handleInput}
            value={filter.userName || ''}
            placeholder="Search Email"
            aria-label="Search Email"
          />
        </Grid>
      </Flex>
      <ZCard
        variant="form"
        mt={4}
      >
        <ZCardBody p={8}>
          <UiSSDataTable
            isLoading={searchUsers.isFetching}
            columns={COLUMNS}
            variant="list"
            rows={normalizedUsers}
            rowKey="id"
            showSelect={true}
            onSelect={onSelect}
            hasMore={searchUsers.hasNextPage}
            fetchMore={searchUsers.fetchNextPage}
            totalRecords={totalRecords}
            filter={variables.filter}
            orderBy={orderBy}
            onOrderBy={setOrderBy}
            SelectCheckbox={ZCheckbox}
            HeaderButton={ZButton}
            tableProps={internalTableProps}
            progressBarProps={internalProgressBarProps}
          />
        </ZCardBody>
      </ZCard>
      {canUpdate && selectedUsers && teamUpdate.isOpen && (
        <BulkUserAccountUpdate
          {...teamUpdate}
          users={selectedUsers}
          onComplete={() => setSelectedUsers(undefined)}
        />
      )}
      {canUpdate && editRoles.isOpen && !!selectedUser && (
        <UserAccountsEdit
          userId={selectedUser.id}
          firstName={selectedUser.firstName}
          lastName={selectedUser.lastName}
          isOpen={editRoles.isOpen}
          onClose={editRoles.onClose}
          onComplete={() => setSelectedUser(undefined)}
        />
      )}
    </>
  )
}
