import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { omitDeep, sanitize, useAlertError, useAlerts } from '@postal-io/postal-ui'
import type { ApprovedPostalUpdateInput, Attribution, DesignTemplate, ProductVariant } from 'api'
import { CreateApprovedPostalDocument, GetMarketplaceProductDocument, Status } from 'api'
import {
  AnalyticsEvent,
  POSTAL_INVALIDATIONS,
  useAnalyticsEvent,
  useApprovedPostalVersion,
  useBackgroundQueue,
  useSession,
} from 'hooks'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MarketplacePostalEditor } from './MarketplacePostalEditor'

export interface ItemApproveFormState {
  name: string
  displayName: string
  description: string
  isActive: Boolean
  designTemplate?: DesignTemplate | null
  variants: ProductVariant[]
  teamIds: any[]
  attribution?: Attribution | null
}

export const MarketplaceProductPage: React.FC = () => {
  const transform = useApprovedPostalVersion()
  const Alert = useAlerts()
  const { marketplaceProductId } = useParams() as any
  const { session } = useSession()

  const navigate = useNavigate()

  const { data, error, isLoading } = useGraphqlQuery(GetMarketplaceProductDocument, {
    id: marketplaceProductId,
  })

  const product = useMemo(() => {
    return data?.getMarketplaceProduct
  }, [data?.getMarketplaceProduct])

  const activeVariants = useMemo(() => product?.variants?.filter((v) => v.status === Status.Active) || [], [product])

  useAlertError(error)

  useAnalyticsEvent({ event: AnalyticsEvent.ProductViewed, data: { marketplaceProductId } })

  const { queue, invalidate } = useBackgroundQueue()
  const createPostal = useGraphqlMutation(CreateApprovedPostalDocument, {
    onSuccess: (data) => {
      queue(data.createApprovedPostal.previewGenerationTask)
      invalidate(POSTAL_INVALIDATIONS)
    },
  })

  const onUpdate = async ({
    name,
    displayName,
    description,
    isActive,
    designTemplate,
    variants,
    teamIds,
    event,
    attribution,
  }: any) => {
    if (!product) return
    const status = isActive ? Status.Active : Status.Disabled

    const data: ApprovedPostalUpdateInput = {
      name,
      displayName,
      description: sanitize(description),
      status: event ? Status.Disabled : status,
      designTemplate: omitDeep(designTemplate, 'id'),
      teamIds,
      event,
      attribution,
      ...transform(product.category, {
        items: variants?.map((variant: string) => ({ marketplaceProductId, variant })),
        variants,
      }),
    }

    // If we have a teamId, it means we are a team admin, so lets
    // set the teamIds to that team
    if (!!session.teamId) {
      data.teamIds = [session.teamId]
    }

    try {
      // loadingModal.onOpen()
      const res = await createPostal.mutateAsync({ marketplaceProductId: product.id, data })
      const approvedPostalResponse = res.createApprovedPostal?.postal
      Alert.success(`Approved Item Created`)
      navigate(`/items/postals/${approvedPostalResponse?.id}`)
      // loadingModal.onClose()
    } catch (err) {
      // loadingModal.onClose()
      Alert.error(err)
    }
  }

  return (
    <MarketplacePostalEditor
      postal={product}
      activeVariants={activeVariants}
      availableVariants={activeVariants}
      isLoading={[createPostal.isLoading, isLoading].some(Boolean)}
      onUpdate={onUpdate}
    />
  )
}
