import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Grid, useBreakpointValue, Wrap } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { ZHeading, ZLink } from '@postal-io/postal-ui'
import type { MarketplaceProduct } from 'api'
import { SearchMarketplaceProductsDocument } from 'api'
import { AnalyticsEventV2, useAnalyticsSend } from 'hooks'
import { shuffle } from 'lodash'
import React, { useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { MARKETPLACE_SECTION_EVENT_TYPES } from '../Postals/data'
import { OldMarketplaceCardProduct } from './MarketplaceCardProduct'

interface MarketplaceSectionEventsV2Props extends Omit<BoxProps, 'onSelect'> {
  isLoading?: boolean
  onSelect: (product: MarketplaceProduct) => void
}

export const MarketplaceSectionEvents: React.FC<MarketplaceSectionEventsV2Props> = ({
  isLoading,
  onSelect,
  ...rest
}) => {
  const sendAnalytics = useAnalyticsSend()
  const numColumns = useBreakpointValue({ base: 1, sm: 2, md: 3, xl: 4 }) ?? 1

  // TODO: something dynamic for the new subCategory-ies
  // const getMarketplaceFilters = useGraphqlQuery(GetMarketplaceFiltersDocument, { categories: ['Events'] })
  // const eventTypes = useMemo(
  //   () => getMarketplaceFilters.data?.getMarketplaceFilters,
  //   [getMarketplaceFilters.data?.getMarketplaceFilters]
  // )?.find((t) => t.name === 'type')

  const searchMarketplaceProducts = useGraphqlQuery(SearchMarketplaceProductsDocument, {
    filter: { category: { eq: 'Events' }, type: { in: MARKETPLACE_SECTION_EVENT_TYPES } },
    // use larger LIMIT to make shuffling possible
    limit: 50,
  })

  const productEvents = useMemo(() => {
    return searchMarketplaceProducts?.data?.searchMarketplaceProducts ?? []
  }, [searchMarketplaceProducts?.data?.searchMarketplaceProducts])

  // don't overflow row or show too many rows
  const numProductEvents = useMemo(
    () => (numColumns === 1 ? 5 : numColumns === 2 ? 8 : numColumns === 3 ? 9 : 8),
    [numColumns]
  )

  const randomProductEvents = useMemo(() => shuffle(productEvents), [productEvents]).slice(0, numProductEvents)

  const handleSelectEventItem = (item: MarketplaceProduct) => {
    sendAnalytics({ event: AnalyticsEventV2.Marketplace_Event_Clicked, data: { id: item.id, name: item.name } })
    onSelect(item)
  }

  const handleClickEventType = (displayName: string) => {
    sendAnalytics({ event: AnalyticsEventV2.Marketplace_EventCategory_Clicked, data: { displayName } })
  }

  return (
    <Box
      w="100%"
      {...rest}
    >
      <Flex
        w="100%"
        alignItems="baseline"
        justifyContent="space-between"
        gap={10}
      >
        <Flex
          alignItems="baseline"
          gap={10}
        >
          <ZHeading
            as="h2"
            size="h5"
            variant="bold"
            mb={6}
          >
            Events
          </ZHeading>
          <Wrap
            spacing={5}
            mb={6}
          >
            {MARKETPLACE_SECTION_EVENT_TYPES.slice(0, 5).map((displayName) => (
              <ZLink
                as={RouterLink}
                color="atomicGray.500"
                variant="bold"
                key={displayName}
                onClick={() => handleClickEventType(displayName)}
                to={`/events/marketplace`}
                state={{ type: displayName }}
              >
                {displayName}
              </ZLink>
            ))}
          </Wrap>
        </Flex>

        <ZLink
          minW="max-content"
          as={RouterLink}
          color="atomicBlue.600"
          fontWeight="bold"
          to={'/events/marketplace'}
          state={{ type: undefined }}
        >
          Show All
        </ZLink>
      </Flex>
      <Grid
        gap={5}
        templateColumns={{ base: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }}
      >
        {randomProductEvents.map((product) => (
          <OldMarketplaceCardProduct
            key={product.id}
            isLoading={isLoading}
            variant="collapse"
            size="tall"
            product={product}
            onSelect={handleSelectEventItem}
          />
        ))}
      </Grid>
    </Box>
  )
}
