import type { BoxProps } from '@chakra-ui/react'
import { Fade, Flex, Grid, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiMenu, UiMenuButton, UiMenuList, useColor, ZButton, ZMoney, ZText } from '@postal-io/postal-ui'
import type { ProductAccess, User } from 'api'
import { BudgetMode, GetTeamByIdDocument, GetUserDocument, Role } from 'api'
import { ZAutoCompleteUser, ZFormLabel, ZInfoTooltip } from 'components/Common/ZComponents'
import {
  AnalyticsEventV2,
  useAcl,
  useAnalyticsSend,
  useBalance,
  useBudget,
  useExtension,
  useMe,
  useSession,
} from 'hooks'
import React, { useEffect, useMemo } from 'react'
import { MdOutlineArrowDropDown, MdOutlineSavings } from 'react-icons/md'
import { useCanSendAs } from './PostalCustomizeSendAs'
import type { PostalCustomizeV2Props } from './PostalCustomize'
import { PostalSpendAsDropdown } from './PostalSendSidebarSpendAs'

const PRODUCT_ID = process.env.REACT_APP_PRODUCT_ID

const validTeamFilter = (account: ProductAccess) => account.product === PRODUCT_ID && account.roles?.includes(Role.User)

interface PostalCustomizeSpendAsV2Props extends PostalCustomizeV2Props, BoxProps {
  subtotal?: number
  hasFunds?: boolean
}

export const PostalCustomizeSpendAs: React.FC<PostalCustomizeSpendAsV2Props> = ({
  context,
  send,
  subtotal,
  hasFunds,
}) => {
  const { Color } = useColor()
  const { isExtension } = useExtension()
  const { hasRole, hasFeature } = useAcl()
  const { session } = useSession()
  const canSpendAs = hasFeature('budgetDropDown')
  // we want to line up with send as component but only if it's there
  const canSendAs = useCanSendAs({ context })
  const isAdmin = hasRole(Role.Admin)
  const ROLES = [Role.User]
  const teamDisclosure = useDisclosure()
  const sendAnalytics = useAnalyticsSend()

  const selectedUserQuery = useGraphqlQuery(
    GetUserDocument,
    { id: context.spendAsUserId as string },
    { enabled: !!context.spendAsUserId }
  )
  const selectedUser = useMemo(() => selectedUserQuery.data?.getUser, [selectedUserQuery.data?.getUser])

  const teamQuery = useGraphqlQuery(GetTeamByIdDocument, { id: session.teamId }, { enabled: !!session.teamId })

  const { me } = useMe()
  // team name, or defaults to account name if logged-in user does not have team
  const entityName = useMemo(() => {
    return session.teamId ? teamQuery.data?.getTeamById?.name : me?.accountName
  }, [me?.accountName, session.teamId, teamQuery.data?.getTeamById?.name])

  const { showBudget, budgetRemaining } = useBudget()
  const { showBalance, balanceRemaining } = useBalance()
  const budgetDisplayValue = useMemo(() => {
    if (context?.spendAsTeamName)
      return context?.spendAsTeamBudgetMode === BudgetMode.Unlimited
        ? context.spendAsTeamBalance
        : context?.spendAsTeamBudget
    return showBudget ? budgetRemaining : showBalance ? balanceRemaining : 0
  }, [context, showBalance, showBudget, budgetRemaining, balanceRemaining])

  // get team name using teamId
  const insufficientBalance = useMemo(() => {
    if (teamQuery.isLoading) return false
    if (hasFunds !== undefined) return !hasFunds
    return (budgetDisplayValue ?? 0) < (subtotal ?? 0)
  }, [budgetDisplayValue, subtotal, teamQuery.isLoading, hasFunds])

  // only user accounts can send
  const selectedUserTeams = useMemo(
    () => selectedUser?.productAccess?.filter(validTeamFilter) || [],
    [selectedUser?.productAccess]
  )

  const handleSelectedUser = (user: User | null) => {
    sendAnalytics({ event: AnalyticsEventV2.SendFlow_SpendAs_Selected, data: { userId: user?.id } })
    send({ type: 'SET_SPEND_AS_USER_ID', data: { userId: user?.id } })

    const userTeams = user?.productAccess?.filter(validTeamFilter) || []
    // reset team if new user does not have current team
    if (!userTeams.find((account) => account.teamId === context?.spendAsTeamId)) {
      send({ type: 'SET_SPEND_AS_TEAM_ID', data: { teamId: userTeams[0]?.teamId || null } })
    }
  }

  /*
    If a non-admin gets to this page, we want to preset the userId and teamId that
    they will send as.  This is so we know to show the teamId list which would allow
    them to pick a different team.
  */
  useEffect(() => {
    if (!isAdmin && canSpendAs && !context.spendAsUserId) {
      send({ type: 'SET_SPEND_AS_USER_ID', data: { userId: session.userId } })
      send({ type: 'SET_SPEND_AS_TEAM_ID', data: { teamId: session.teamId || null } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const templateColumns = useMemo(() => {
    // extension stacks all elements vertically -> one column
    if (isExtension) return '1fr'
    // admin has two select elements to display -> 3 columns
    if (isAdmin) return '120px 1fr 1fr'
    // send as component is present, we're stacked on top of it & need to line up with it -> use send as component column pattern
    if (canSendAs) return '120px 1fr 1fr'
    // one select element to display & nothing to line up with -> 50/50 split
    return '1fr 1fr'
  }, [canSendAs, isAdmin, isExtension])

  if (!canSpendAs) return null

  return (
    <>
      <Grid
        templateColumns={templateColumns}
        columnGap={8}
        rowGap={isExtension ? 5 : 0}
      >
        <ZFormLabel
          display="flex"
          alignItems="center"
          variant="bold"
        >
          {isAdmin ? 'Spend As' : 'Spend From'}
          <ZInfoTooltip
            label="This order will live on the budget of the team and user selected."
            ml={2}
          />
        </ZFormLabel>
        {isAdmin && (
          <ZAutoCompleteUser
            data-testid="AutoCompleteSpendAs"
            inputId="spendas-user"
            value={selectedUser}
            onChange={handleSelectedUser}
            menuPlacement="top"
            roles={ROLES}
          />
        )}
        <Fade
          in={!!context.spendAsUserId}
          unmountOnExit
        >
          <UiMenu {...teamDisclosure}>
            <UiMenuButton
              as={ZButton}
              pr={3}
              height="40px"
              borderRadius={3}
              width="100%"
              fontSize="sm"
              variant="outline"
              position="relative"
              color="atomicGray.800"
              bg="white"
              borderColor="atomicGray.200"
              _hover={{ bg: 'white', borderColor: 'atomicGray.400' }}
              textAlign="left"
              leftIcon={
                <MdOutlineSavings
                  viewBox="2 2 20 20"
                  fontSize="16px"
                />
              }
              rightIcon={
                // isInteractive ? (
                <MdOutlineArrowDropDown
                  viewBox="2 2 20 20"
                  size="15px"
                  style={{
                    color: Color('atomicGray.500'),
                    // transform: dropdownOpen ? 'rotate(-180deg)' : '',
                    transition: 'transform 0.2s',
                  }}
                />
                // ) : undefined
              }
            >
              <Flex
                w="100%"
                justifyContent="space-between"
              >
                <ZText fontWeight={500}>{context.spendAsTeamName || entityName}</ZText>
                <ZMoney
                  fontWeight={insufficientBalance ? 600 : 500}
                  cents={budgetDisplayValue}
                  color={insufficientBalance ? 'atomicYellow.800' : 'atomicGray.500'}
                />
              </Flex>
              {/* <Fade in={dropdownOpen}>
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            pl={5}
            display="flex"
            alignItems="center"
            bg="white"
            _hover={{ bg: 'atomicGray.50' }}
          >
            <ZText fontWeight={500}>Select a team to Spend As</ZText>
          </Box>
        </Fade> */}
            </UiMenuButton>
            <UiMenuList boxShadow="0 0 15px rgba(0,0,0,.1)">
              <PostalSpendAsDropdown
                context={context}
                send={send}
                subtotal={subtotal ?? 0}
                onClose={teamDisclosure.onClose}
              />
              {!selectedUserTeams.length && <Text px={4}>No teams available</Text>}
            </UiMenuList>
          </UiMenu>
        </Fade>
      </Grid>
    </>
  )
}
