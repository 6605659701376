import type { GridProps } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { DateFilterProps, DateRangeOption, IUser } from '@postal-io/postal-ui'
import {
  GraphqlFilterTransform,
  internalTableProps,
  UiInlineFilters,
  UiInlineFiltersCalendarInput,
  UiInlineFiltersDownloadButton,
  UiInlineFiltersUsersInput,
  UiSSDataTable,
  useAlertError,
  useAlerts,
  useGraphqlFilter,
} from '@postal-io/postal-ui'
import type { BillingAccount, SearchLedgerQueryVariables } from 'api'
import { GetAccountDocument, SearchLedgerDocument, Status } from 'api'
import { ReportType, useReport, useUserOmniSearch } from 'hooks'
import { useDownloadPdf } from 'hooks/useDownloadPdf'
import { parseDateRangeOption } from 'lib/parseDateRange'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { combineSearchParams } from '../../lib/searchParams'
import { LEDGER_COLUMNS } from './data'

interface BillingAccountLedgerProps extends GridProps {
  billingAccount: BillingAccount
  isLoading?: boolean
}
export const BillingAccountLedger: React.FC<BillingAccountLedgerProps> = ({ billingAccount, isLoading }) => {
  const Alert = useAlerts()
  const [searchParams, setSearchParams] = useSearchParams()
  const { createDownload } = useDownloadPdf()
  const { createReport, isLoading: reportIsLoading } = useReport(ReportType.Ledger)

  const getAccountQuery = useGraphqlQuery(GetAccountDocument)
  const getAccount = getAccountQuery?.data?.getAccount
  useAlertError(getAccountQuery.error)

  const graphqlFilter = useGraphqlFilter<SearchLedgerQueryVariables>({
    staticVariables: { filter: { billingAccountId: { eq: billingAccount.id } }, limit: 20 },
    isManual: false,
    transforms: {
      userId: GraphqlFilterTransform.Equal,
      created: GraphqlFilterTransform.Between,
    },
  })

  useEffect(() => {
    graphqlFilter.transform()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphqlFilter.orderBy])

  const searchLedgerQuery = useGraphqlInfiniteQuery(SearchLedgerDocument, graphqlFilter.variables)
  const searchLedger = useMemo(
    () => searchLedgerQuery.mergedData?.searchLedger ?? [],
    [searchLedgerQuery.mergedData?.searchLedger]
  )
  useAlertError(searchLedgerQuery.error)

  const [userSearch, setUserSearch] = useState<string>('')
  const [debouncedSearchUsers] = useDebounce(userSearch, 400)

  const searchUsersQuery = useUserOmniSearch(debouncedSearchUsers, {
    filter: {
      status: { eq: Status.Active },
    },
    limit: 20,
  })
  useAlertError(searchUsersQuery.error)

  const handleChangeUser = (user: IUser | null) => {
    const _searchParams: any = { userId: user?.id }
    setSearchParams(combineSearchParams(searchParams, _searchParams))
  }

  const handleChangeDateRange = useCallback(
    ({ startDate, endDate, dateRangeOption }: DateFilterProps) => {
      const _searchParams: any = { startDate, endDate, dateRangeOption }
      setSearchParams(combineSearchParams(searchParams, _searchParams))
    },
    [searchParams, setSearchParams]
  )

  const handleClearFilters = () => setSearchParams({})

  const handleDownloadReport = () => {
    try {
      createReport(graphqlFilter.variables.filter)
    } catch (err) {
      Alert.error(err)
    }
  }

  useEffect(() => {
    const userId = searchParams.get('userId')
    const dateRangeOption = searchParams.get('dateRangeOption') as DateRangeOption
    const customStartDate = searchParams.get('startDate')
      ? new Date(searchParams.get('startDate') as string)
      : undefined
    const customEndDate = searchParams.get('endDate') ? new Date(searchParams.get('endDate') as string) : undefined
    const fiscalMethod = getAccount?.fiscalQuarterSetup?.method
    const fiscalConfiguration = getAccount?.fiscalQuarterSetup?.configuration

    if (dateRangeOption) {
      const parsed = parseDateRangeOption({
        dateRangeOption,
        customStartDate,
        customEndDate,
        fiscalMethod,
        fiscalConfiguration,
      })
      graphqlFilter.setFilter({
        key: 'created',
        value: parsed.startDate && parsed.endDate ? [parsed.startDate, parsed.endDate] : undefined,
      })
    }

    graphqlFilter.setFilter({ key: 'userId', value: userId })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccount?.fiscalQuarterSetup?.configuration, getAccount?.fiscalQuarterSetup?.method, searchParams])

  const rows = useMemo(() => {
    return searchLedger.map((ledger) => ({
      ...ledger,
      createDownload,
      billingAccount,
    }))
    // prevent render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingAccount, searchLedger])

  return (
    <>
      <UiInlineFilters onClear={handleClearFilters}>
        <UiInlineFiltersUsersInput
          options={searchUsersQuery.results}
          value={searchUsersQuery.results.find((r) => r.id === searchParams.get('userId'))}
          inputValue={userSearch}
          onChange={handleChangeUser}
          onInputChange={setUserSearch}
        />
        <UiInlineFiltersCalendarInput
          startDate={searchParams.get('startDate') ? new Date(searchParams.get('startDate') as string) : undefined}
          endDate={searchParams.get('endDate') ? new Date(searchParams.get('endDate') as string) : undefined}
          dateRangeOption={searchParams.get('dateRangeOption') as DateRangeOption}
          onChange={handleChangeDateRange}
        />
        <UiInlineFiltersDownloadButton
          onClick={handleDownloadReport}
          isLoading={reportIsLoading}
          isDisabled={reportIsLoading}
        />
      </UiInlineFilters>
      <UiSSDataTable
        mt={4}
        variant="list"
        isLoading={isLoading}
        columns={LEDGER_COLUMNS}
        rows={rows}
        rowKey="id"
        fetchMore={searchLedgerQuery.fetchNextPage}
        hasMore={searchLedgerQuery.hasNextPage}
        filter={graphqlFilter.variables.filter}
        orderBy={graphqlFilter.orderBy}
        onOrderBy={graphqlFilter.setOrderBy}
        tableProps={internalTableProps}
      />
    </>
  )
}
