import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  useAlerts,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZText,
} from '@postal-io/postal-ui'
import { DeleteMessageTemplateDocument } from 'api'
import React from 'react'

interface MessageDeleteProps {
  isOpen: boolean
  onClose: () => void
  onComplete?: () => void
  messageId?: string
}

export const MessagesDelete: React.FC<MessageDeleteProps> = ({ isOpen, onClose, onComplete, messageId }) => {
  const Alert = useAlerts()

  const deleteMessageTemplate = useGraphqlMutation(DeleteMessageTemplateDocument)

  const onConfirm = async () => {
    try {
      await deleteMessageTemplate.mutateAsync({ id: messageId as string })
      Alert.warning('Template removed')
    } catch (err) {
      Alert.error('Error removing Message Template')
    } finally {
      if (onComplete) {
        onComplete()
      }
      onClose()
    }
  }
  return (
    <ZModal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ZModalOverlay />
      <ZModalContent>
        <ZModalHeader>Delete Message Template</ZModalHeader>
        <ZModalCloseButton />
        <ZModalBody>
          <ZText>
            Are you sure you want to <strong>delete</strong> this template?
          </ZText>
        </ZModalBody>
        <ZModalButtons
          confirmText="Delete"
          confirmProps={{
            colorScheme: 'atomicRed',
          }}
          onConfirm={onConfirm}
          isConfirmDisabled={deleteMessageTemplate.isLoading}
          isConfirmLoading={deleteMessageTemplate.isLoading}
          onClose={onClose}
        />
      </ZModalContent>
    </ZModal>
  )
}
