import { createIcon } from '@chakra-ui/icon'
import type { ButtonProps, GridProps } from '@chakra-ui/react'
import { Box, Button, Grid, Heading, Text, useBreakpointValue } from '@chakra-ui/react'
import { UiLiftUpEffect } from '@postal-io/postal-ui'
import { AnalyticsEventV2, useAnalyticsSend } from 'hooks'
import { Link, useNavigate } from 'react-router-dom'

// https://kovart.github.io/dashed-border-generator/
import backgroundImage from 'assets/images/border-images/marketplace-card-dashed-border.svg'
import { useHasSwagEditor } from 'components/SwagEditor'

const PURPLE = '#efeffb'
const GREEN = '#f0fae4'
const BEIGE = '#fff5e0'
const POSTAL_DARK = '#222222'

interface MarketplaceSectionSwagProps extends GridProps {
  onSelect: () => void
}
export const MarketplaceSectionSwag = ({ onSelect, ...rest }: MarketplaceSectionSwagProps) => {
  const navigate = useNavigate()
  const hasSwagEditorAccess = useHasSwagEditor()
  const compactMode = useBreakpointValue({ 'base': true, 'lg': hasSwagEditorAccess ? true : false, '2xl': false })
  const sendAnalytics = useAnalyticsSend()

  return (
    <Grid
      templateColumns="repeat(auto-fit, minmax(200px, 1fr))"
      gap="30px"
      w="100%"
      minH="150px"
      {...rest}
    >
      <ActionCard
        compactMode={compactMode}
        title="My Swag"
        cta="View my swag items"
        Icon={TShirtIcon}
        bgColor={PURPLE}
        onClick={onSelect}
      />
      {hasSwagEditorAccess && (
        <ActionCard
          compactMode={compactMode}
          title="Swag Creator"
          cta="Design and order swag"
          Icon={WarehouseIcon}
          bgColor={GREEN}
          onClick={() => {
            sendAnalytics({ event: AnalyticsEventV2.Marketplace_SwagEditor })
            navigate('/swag-editor')
          }}
        />
      )}
      <ActionCard
        compactMode={compactMode}
        title={compactMode ? 'Paper Plane' : 'Paper Plane Agency'}
        cta="Craft uniquely curated experiences and items"
        Icon={PaperPlaneIcon}
        bgColor={BEIGE}
        to="/paperplane"
      />
    </Grid>
  )
}

type ActionCardProps = ButtonProps & {
  title: string
  cta?: string
  Icon: any
  compactMode?: boolean
} & (
    | {
        to: string
        onClick?: undefined
      }
    | {
        to?: undefined
        onClick: () => void
      }
  )

export const ActionCard = ({ title, Icon, to, onClick, ...rest }: ActionCardProps) => {
  return to ? (
    <Link to={to}>
      <ActionCardButton
        title={title}
        Icon={Icon}
        {...rest}
      />
    </Link>
  ) : (
    <ActionCardButton
      title={title}
      Icon={Icon}
      onClick={onClick}
      {...rest}
    />
  )
}

export const ActionCardButton = ({
  title,
  Icon,
  cta,
  compactMode,
  ...rest
}: ButtonProps & { Icon: any; cta?: string; compactMode?: boolean }) => {
  const subtext = compactMode ? 'Go There' : cta

  return (
    <UiLiftUpEffect
      scale={1.02}
      boxShadowColor="rgba(0,0,0,.1)"
      boxShadowSpread="15px"
    >
      <Button
        variant="unstyled"
        minH="150px"
        h="100%"
        w="100%"
        borderRadius="6px"
        backgroundImage={backgroundImage}
        textAlign="left"
        p={8}
        display="flex"
        flexDir={compactMode ? 'column' : 'row'}
        alignItems={compactMode ? 'left' : 'center'}
        {...rest}
      >
        {compactMode && (
          <Icon
            h="35px"
            w="auto"
            mb={5}
            color={POSTAL_DARK}
          />
        )}
        <Box
          whiteSpace="normal"
          flexGrow={1}
        >
          <Heading
            fontWeight="bold"
            fontSize="2rem"
          >
            {title}
          </Heading>
          <Text
            mt={5}
            fontWeight="bold"
          >
            {subtext} <ArrowRightIcon />
          </Text>
        </Box>
        {!compactMode && (
          <Icon
            h="35px"
            w="auto"
            ml={-2}
            color={POSTAL_DARK}
          />
        )}
      </Button>
    </UiLiftUpEffect>
  )
}

export const ArrowRightIcon = createIcon({
  displayName: 'ArrowForwardIcon',
  viewBox: '0 0 15 16',
  path: (
    <g fill="none">
      <path
        d="M7.5 3L6.61875 3.88125L10.1062 7.375H2.5V8.625H10.1062L6.61875 12.1187L7.5 13L12.5 8L7.5 3Z"
        fill="#222222"
      />
    </g>
  ),
})

export const TShirtIcon = createIcon({
  displayName: 'PaperPlaneIcon',
  viewBox: '0 0 40 37',
  path: (
    <g fill="none">
      <g clipPath="url(#clip0_3055_25404)">
        <path
          d="M24.7558 1.86714C24.7877 1.55863 25.1069 1.36714 25.4047 1.44161L33.7026 3.33523C35.4792 3.86714 36.8622 5.2714 37.3728 7.04799L39.2983 13.7395C39.426 14.165 39.1707 14.6118 38.7345 14.7288L33.9898 15.9735C33.6494 16.0586 33.2983 15.9203 33.1175 15.6331L31.9473 13.7714C31.8196 13.5586 31.4898 13.6544 31.4898 13.8991L30.7558 34.8139C30.7452 35.2395 30.3941 35.5799 29.9579 35.5799H10.043C9.6175 35.5799 9.26644 35.2395 9.24516 34.8139L8.51112 13.8991C8.51112 13.6544 8.18133 13.5693 8.05367 13.7714L6.87282 15.6331C6.69197 15.931 6.33027 16.0693 6.00048 15.9735L1.2558 14.7288C0.819631 14.6118 0.57495 14.165 0.691971 13.7395L2.6175 7.04799C3.12814 5.2714 4.51112 3.86714 6.28771 3.33523L14.5856 1.44161C14.8835 1.36714 15.2026 1.55863 15.2345 1.86714C15.5218 4.23948 17.543 6.07991 19.9898 6.07991C22.4366 6.07991 24.4579 4.23948 24.7452 1.86714H24.7558Z"
          stroke="#222222"
          strokeWidth="1.25"
          strokeMiterlimit="10"
        />
        <path
          d="M27.1074 14.1206L24.8839 15.3653C24.7988 15.4079 24.7031 15.4079 24.618 15.3653L22.3946 14.1206C22.3095 14.0781 22.2563 13.9823 22.2563 13.8866V10.1845C22.2563 10.0355 22.3733 9.91852 22.5222 9.91852H26.9797C27.1286 9.91852 27.2456 10.0355 27.2456 10.1845V13.8972C27.2456 13.993 27.1925 14.0781 27.1074 14.1313V14.1206Z"
          stroke="#222222"
          strokeWidth="1.25"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_3055_25404">
          <rect
            width="40"
            height="35.4787"
            fill="white"
            transform="translate(0 0.76062)"
          />
        </clipPath>
      </defs>
    </g>
  ),
})

export const WarehouseIcon = createIcon({
  displayName: 'WarehouseIcon',
  viewBox: '0 0 36 34',
  path: (
    <g fill="none">
      <path
        d="M30.0977 24.4912L14.3291 24.4912L14.3291 32.7618"
        stroke="#222222"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M30.0977 16.2207L19.6087 16.2207L19.6087 24.4913"
        stroke="#222222"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M0.703125 8.34192C0.703125 7.50295 1.22674 6.75316 2.0144 6.46425L17.3117 0.85321C17.7564 0.690107 18.2445 0.690107 18.6892 0.85321L33.9865 6.46425C34.7742 6.75316 35.2978 7.50295 35.2978 8.34192V31.7621C35.2978 32.3144 34.8501 32.7621 34.2978 32.7621H1.70313C1.15084 32.7621 0.703125 32.3144 0.703125 31.7621V8.34192Z"
        stroke="#222222"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
      <path
        d="M5.82227 13.8232C5.82227 12.7187 6.7177 11.8232 7.82227 11.8232H28.0968C29.2013 11.8232 30.0968 12.7187 30.0968 13.8232V32.7624H5.82227V13.8232Z"
        stroke="#222222"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
      <circle
        cx="17.9602"
        cy="6.70532"
        r="1.43774"
        stroke="#222222"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
      <line
        x1="19.6084"
        y1="24.4912"
        x2="19.6082"
        y2="32.7619"
        stroke="#222222"
        strokeWidth="1.25"
      />
      <line
        x1="24.9199"
        y1="16.2209"
        x2="24.9199"
        y2="32.7622"
        stroke="#222222"
        strokeWidth="1.25"
      />
    </g>
  ),
})

export const PaperPlaneIcon = createIcon({
  displayName: 'PaperPlaneIcon',
  viewBox: '0 0 49 37',
  path: (
    <g fill="none">
      <path
        d="M33.8085 8.82887L10.1728 17.3968C9.78759 17.5364 9.35622 17.4269 9.08419 17.1206L2.94616 10.2079C2.41914 9.61435 2.76047 8.67288 3.54542 8.555L45.302 2.28462C46.1831 2.15231 46.7842 3.15141 46.2547 3.86792L23.9935 33.9859C23.5936 34.527 22.7842 34.5267 22.3847 33.9853L13.8339 22.3983C13.3589 21.7547 12.3579 21.9016 12.0879 22.6546L9.65368 29.4435C9.34215 30.3124 10.2736 31.1034 11.0805 30.6553L17.2011 27.2564"
        stroke="#222222"
        strokeWidth="1.25"
      />
    </g>
  ),
})
