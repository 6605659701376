import type { ApprovedPostal, ApprovedProductVariant, SavedSend } from 'api'
import { SavedSendType } from 'api'
import {
  convertAddressToAddressInput,
  convertNewContactToQuickCreateContactForm,
  useDraftContacts,
} from 'components/Orders/Draft'
import { PostalSend, PostalSendType } from 'components/PostalSend'
import { getSendMethodFromSavedSendType } from 'components/PostalSend/postalSendHelpers'
import { PostalSendLoading } from 'components/PostalSend/PostalSendLoading'
import React, { useMemo } from 'react'

interface ExtDraftSendProps {
  draft: SavedSend
  approvedPostal: ApprovedPostal
  onExit: () => void
}
export const ExtDraftSend: React.FC<ExtDraftSendProps> = ({ draft, approvedPostal, onExit }) => {
  const variant = useMemo(
    () =>
      approvedPostal?.variants?.find((v: ApprovedProductVariant) => v.id === draft?.commonSendProperties?.variantId),
    [approvedPostal, draft]
  )

  const { contacts, isLoading: contactsLoading } = useDraftContacts(draft)

  const {
    commonSendProperties: commonInfo,
    savedSendMagicLinkInfo: magicLinkInfo,
    savedSendCampaignInfo: campaignInfo,
    savedSendBulkSendInfo: bulkSendInfo,
    sendType,
  } = draft ?? {}

  const sendMethod = getSendMethodFromSavedSendType(sendType ?? SavedSendType.GiftEmail)

  // we must convert some BE types to Input types here
  const newContactForm = convertNewContactToQuickCreateContactForm(bulkSendInfo?.newContact)
  const shipToAddress = convertAddressToAddressInput(bulkSendInfo?.shipToAddress)

  return contactsLoading ? (
    <PostalSendLoading
      onNavigateBack={onExit}
      backLabel="Back to Drafts"
    />
  ) : (
    <PostalSend
      onNavigateBack={onExit}
      navigateBackLabel="Back to Drafts"
      draft={draft}
      method={sendMethod}
      type={PostalSendType.Postal}
      stepToRecall={draft.sendFlowStep}
      contacts={contacts}
      postal={approvedPostal}
      variant={variant}
      giftMessage={campaignInfo?.giftMessage}
      physicalMessage={commonInfo?.physicalMessage}
      useSameMessage={campaignInfo?.useSameMessage ?? undefined}
      usePhysicalMessage={!!commonInfo?.physicalMessage}
      name={commonInfo?.name ?? undefined}
      date={campaignInfo?.scheduleDate ?? magicLinkInfo?.expirationDate}
      deliveryEmail={campaignInfo?.deliveryEmail}
      maxExecutions={magicLinkInfo?.maxExecutions ?? undefined}
      enabled={sendType === SavedSendType.MagicLink ? true : undefined}
      emailSubjectLine={campaignInfo?.emailSubjectLine}
      sendAsContactOwner={commonInfo?.sendAsContactOwner}
      sendAsUser={commonInfo?.sendAsUser}
      // bulk sends
      quantity={bulkSendInfo?.quantity ?? undefined}
      shipToAddress={shipToAddress}
      verifiedShipToAddress={bulkSendInfo?.addressVerified ?? undefined}
      newContact={newContactForm}
      meetingRequestSetting={commonInfo?.meetingRequestSetting}
      itemCustomizationInputs={commonInfo?.itemCustomizationInputs}
      formFieldList={commonInfo?.formFieldList}
      landingPageHeaderText={commonInfo?.landingPageCustomization?.headerText}
      landingPageBody={commonInfo?.landingPageCustomization?.body}
      landingPageIncludeHeadshot={!!commonInfo?.landingPageCustomization?.includeHeadshot}
      landingPageIncludeSenderName={!!commonInfo?.landingPageCustomization?.includeSenderName}
      spendAsTeamId={commonInfo?.spendAs?.teamId as string}
      spendAsUserId={commonInfo?.spendAs?.userId}
      linkNeedsApproval={magicLinkInfo?.requiresApproval}
      shippedEmailsOn={commonInfo?.recipientEmailSettings?.shippedEmailsOn ?? undefined}
      deliveredEmailsOn={commonInfo?.recipientEmailSettings?.deliveredEmailsOn ?? undefined}
    />
  )
}
