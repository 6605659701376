import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  GraphqlFilterTransform,
  humanize,
  internalProgressBarProps,
  internalTableProps,
  UiDropzone,
  UiFormControl,
  UiSSDataTable,
  useAlerts,
  useGraphqlFilter,
  ZButton,
  ZCard,
  ZCardBody,
  ZCardHeader,
  ZCheckbox,
  ZFormLabel,
  ZInput,
  ZInputSearch,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalButtons,
  ZModalCloseButton,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
  ZModalOverlay,
  ZSelect,
  ZSidebar,
  ZSidebarBanner,
  ZText,
} from '@postal-io/postal-ui'
import type { BlocklistEntry, SearchBlocklistEntrysQueryVariables } from 'api'
import {
  BlocklistFilterType,
  CreateBlocklistEntryDocument,
  DataObjectType,
  DeleteBlocklistEntryDocument,
  SearchBlocklistEntrysDocument,
  UpdateBlocklistEntryDocument,
} from 'api'
import api from 'api/rest'
import { CenteredBox } from 'components/Common'
import { PageTitle } from 'hooks'
import React, { useMemo, useState } from 'react'
import { useImmer } from 'use-immer'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

const TRANSFORMS = {
  entry: GraphqlFilterTransform.Contains,
}

const DEFAULT_FIELD_NAME = 'shipToEmail'
const DEFAULT_OBJECT_TYPE = DataObjectType.Postal

const HELP_URL = 'https://help.postal.com/helpcenter/s/article/Email-Blocklist-CSV-Upload'

const STATIC_VARIABLES = { limit: 100 }

const columns = [
  {
    label: 'Entry',
    key: 'entry',
  },
  {
    label: 'Type',
    key: 'filterType',
  },
]

interface FormState {
  id?: string
  entry: string
  filterType: BlocklistFilterType
}

const defaultEntry = () => ({ entry: '', filterType: BlocklistFilterType.Domain })

const options = Object.values(BlocklistFilterType).filter((f) => f !== BlocklistFilterType.Subnet)

export const EmailBlockList: React.FC = () => {
  const Alert = useAlerts()
  const importList = useDisclosure()
  const [importLoading, setImportLoading] = useState(false)
  const editEntry = useDisclosure()
  const deleteEntry = useDisclosure()
  const [entry, setEntry] = useImmer<FormState>(defaultEntry())

  const graphqlFilter = useGraphqlFilter<SearchBlocklistEntrysQueryVariables>({
    transforms: TRANSFORMS,
    staticVariables: STATIC_VARIABLES,
    debounce: 500,
  })

  const query = useGraphqlInfiniteQuery(SearchBlocklistEntrysDocument, graphqlFilter.variables)

  const createMutation = useGraphqlMutation(CreateBlocklistEntryDocument)
  const updateMutation = useGraphqlMutation(UpdateBlocklistEntryDocument)
  const deleteMutation = useGraphqlMutation(DeleteBlocklistEntryDocument)

  const blocklists = useMemo(
    () => query.mergedData?.searchBlocklistEntrys || [],
    [query.mergedData?.searchBlocklistEntrys]
  )

  const addEntry = () => {
    setEntry(defaultEntry)
    editEntry.onOpen()
  }

  const updateEntry = (entry: BlocklistEntry) => {
    setEntry(() => entry)
    editEntry.onOpen()
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const data = {
      dataObjectType: DEFAULT_OBJECT_TYPE,
      fieldName: DEFAULT_FIELD_NAME,
      entry: entry.entry,
      filterType: entry.filterType,
    }
    try {
      if (entry.id) {
        await updateMutation.mutateAsync({ id: entry.id, data })
        Alert.success('Entry Updated')
      } else {
        await createMutation.mutateAsync({ data })
        Alert.success('Entry Added')
      }
      editEntry.onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleDelete = async () => {
    if (!entry.id) return
    try {
      await deleteMutation.mutateAsync({ id: entry.id })
      Alert.warning('Entry Removed')
      editEntry.onClose()
    } catch (err) {
      Alert.error(err)
    } finally {
      deleteEntry.onClose()
    }
  }

  const onDrop = async (files: any) => {
    if (files.length < 1) return Alert.warning('Please drop only supported file types')
    setImportLoading(true)
    try {
      await api.importBlocklist(files)
      await query.refetch()
      importList.onClose()
    } catch (err) {
      Alert.error(err)
    } finally {
      setImportLoading(false)
    }
  }

  const isUpdating = updateMutation.isLoading || createMutation.isLoading || deleteMutation.isLoading
  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner title="Email Blocklist" />
        <PageTitle title="Email Blocklist" />
        <Flex flexDir="column">
          <Flex
            gridGap={4}
            w="100%"
            alignItems="center"
            mb={8}
          >
            <ZInputSearch
              height="40px"
              size="md"
              name="entry"
              value={graphqlFilter.filter.entry}
              onChange={(e) => graphqlFilter.setFilter({ key: 'entry', value: e.target.value })}
              placeholder="Search"
            />
            <Popover
              returnFocusOnClose={false}
              isOpen={importList.isOpen}
              onClose={importList.onClose}
              closeOnBlur={false}
            >
              <PopoverTrigger>
                <ZButton
                  variant="outline"
                  colorScheme="secondary"
                  onClick={importList.onOpen}
                  minW="150px"
                  justifyContent="center"
                >
                  Import List
                </ZButton>
              </PopoverTrigger>
              <PopoverContent
                minW="400px"
                data-testid="ContactsImport_popover"
                borderRadius="3px"
              >
                <PopoverArrow />
                <PopoverCloseButton
                  data-testid="ContactsImport_popover_close"
                  p={4}
                />
                <PopoverBody>
                  <ZCard>
                    <ZCardHeader>Import from a file</ZCardHeader>
                    <ZText color="atomicGray.600">
                      Need help? Check out our{' '}
                      <ZLink
                        href={HELP_URL}
                        isExternal
                      >
                        help article
                      </ZLink>
                      {', '}
                      which contains an import template.
                    </ZText>
                    <UiDropzone
                      onDrop={onDrop}
                      isLoading={importLoading}
                      accept={{
                        'ZText/csv': ['.csv'],
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                      }}
                      data-testid="dropZone"
                    >
                      <p>Drag files here or click to select</p>
                      <em>(Only .csv, .xls, or .xlsx files)</em>
                      <ZButton
                        mt={4}
                        colorScheme="atomicBlue"
                      >
                        Upload Files
                      </ZButton>
                    </UiDropzone>
                  </ZCard>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <ZButton
              onClick={addEntry}
              size="md"
              minW="150px"
              justifyContent="center"
              colorScheme="atomicBlue"
            >
              Add Entry
            </ZButton>
          </Flex>
          <ZCard variant="form">
            <ZCardBody p={8}>
              <UiSSDataTable
                variant="list"
                rowKey="id"
                columns={columns}
                rows={blocklists}
                isLoading={query.isFetching}
                hasMore={query.hasNextPage}
                fetchMore={query.fetchNextPage}
                onClick={updateEntry}
                SelectCheckbox={ZCheckbox}
                HeaderButton={ZButton}
                tableProps={internalTableProps}
                progressBarProps={internalProgressBarProps}
              />
            </ZCardBody>
          </ZCard>
        </Flex>
        {editEntry.isOpen && (
          <ZModal
            size="xl"
            isOpen={editEntry.isOpen}
            onClose={editEntry.onClose}
          >
            <ZModalOverlay />
            <ZModalContent>
              <form
                onSubmit={handleSubmit}
                id="update-entry-form"
              >
                <ZModalHeader>{entry.id ? 'Update' : 'Add'} Blocklist Entry</ZModalHeader>
                <ZModalCloseButton />
                <ZModalBody>
                  <Stack spacing={8}>
                    <UiFormControl id="filterType">
                      <ZFormLabel fontSize="md">Type</ZFormLabel>
                      <ZSelect
                        name="filterType"
                        value={entry.filterType ?? ''}
                        onChange={(e) =>
                          setEntry((draft) => void (draft.filterType = e.target.value as BlocklistFilterType))
                        }
                        isRequired
                      >
                        {options.map((type) => {
                          return (
                            <option
                              value={type}
                              key={type}
                            >
                              {humanize(type)}
                            </option>
                          )
                        })}
                      </ZSelect>
                    </UiFormControl>
                    <UiFormControl id="entry">
                      <ZFormLabel fontSize="md">Value</ZFormLabel>
                      <ZInput
                        name="entry"
                        value={entry.entry ?? ''}
                        onChange={(e: any) => setEntry((draft) => void (draft.entry = e.target.value))}
                        isRequired
                      />
                    </UiFormControl>
                  </Stack>
                </ZModalBody>
                <ZModalFooter>
                  <SimpleGrid
                    columns={entry.id ? 3 : 2}
                    spacing={4}
                    w="100%"
                  >
                    <ZButton
                      type="submit"
                      form="update-entry-form"
                      isDisabled={isUpdating}
                      isLoading={createMutation.isLoading || updateMutation.isLoading}
                      justifyContent="center"
                      colorScheme="atomicBlue"
                    >
                      Save Entry
                    </ZButton>
                    <ZButton
                      colorScheme="atomicGray"
                      onClick={editEntry.onClose}
                      isDisabled={isUpdating}
                      justifyContent="center"
                      variant="ghost"
                    >
                      Cancel
                    </ZButton>
                    {entry.id && (
                      <ZButton
                        colorScheme="atomicRed"
                        onClick={deleteEntry.onOpen}
                        isDisabled={isUpdating}
                        isLoading={deleteMutation.isLoading}
                        variant="ghost"
                        justifyContent="center"
                      >
                        Delete
                      </ZButton>
                    )}
                  </SimpleGrid>
                </ZModalFooter>
              </form>
            </ZModalContent>
          </ZModal>
        )}
        {deleteEntry.isOpen && (
          <ZModal
            isOpen={deleteEntry.isOpen}
            onClose={deleteEntry.onClose}
            size="sm"
          >
            <ZModalOverlay />
            <ZModalContent>
              <ZModalHeader>Delete Entry</ZModalHeader>
              <ZModalCloseButton />
              <ZModalBody>
                <ZText>
                  Are you sure you want to <strong>delete</strong> this entry?
                </ZText>
              </ZModalBody>
              <ZModalButtons
                confirmText="Delete"
                confirmProps={{
                  colorScheme: 'atomicRed',
                }}
                onConfirm={handleDelete}
                onClose={deleteEntry.onClose}
                isConfirmLoading={deleteMutation.isLoading}
              />
            </ZModalContent>
          </ZModal>
        )}
      </ZSidebar>
    </CenteredBox>
  )
}
