import { Text } from '@chakra-ui/react'
import type { Column } from '@postal-io/postal-ui'
import { joinStrings, UiDate } from '@postal-io/postal-ui'
import type { SearchableContact } from 'api'

export const LIMIT = 100
export const STATIC_VARIABLES = { limit: LIMIT }
export const DEFAULT_FILTER = { id: { in: [] } }

export const COLUMNS: Column[] = [
  {
    key: 'firstName',
    label: 'Name',
    render: ({ firstName, lastName }: SearchableContact) => (
      <Text data-private>{joinStrings([firstName, lastName])}</Text>
    ),
  },
  {
    key: 'emailAddress',
    label: 'Email',
    render: ({ emailAddress }: SearchableContact) => <Text data-private>{emailAddress}</Text>,
  },
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'companyName',
    label: 'Company',
  },
  {
    key: 'created',
    label: 'Created',
    // eslint-disable-next-line react/prop-types
    render: ({ created }: SearchableContact) => <UiDate date={created} />,
  },
]
