import type { TextProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'
import type { ZTextProps } from '@postal-io/postal-ui'
import { ZMoney, ZText } from '@postal-io/postal-ui'
import { isNumber, min } from 'lodash'
import React, { useMemo } from 'react'
import type { ApprovedPostal, MarketplaceProduct } from '../../api/index'

interface PriceTextProps extends ZTextProps {
  amountInCents?: number
  isFlatFee?: boolean
}
const PriceText: React.FC<PriceTextProps> = ({ amountInCents, isFlatFee, ...rest }) => {
  const priceVerbiage = isFlatFee ? '/ event' : '/ person'

  return (
    <ZText
      color="atomicBlue.400"
      {...rest}
    >
      {isNumber(amountInCents) ? (
        <chakra.span>
          From{' '}
          {
            <ZMoney
              color="inherit"
              cents={amountInCents}
              round
            />
          }{' '}
          {priceVerbiage}
        </chakra.span>
      ) : (
        'Price Not Available'
      )}
    </ZText>
  )
}

interface EventsProductPriceRangeProps extends ZTextProps {
  product: MarketplaceProduct
}
export const EventsProductPriceRange: React.FC<EventsProductPriceRangeProps> = ({ product, ...rest }) => {
  const amountInCents = useMemo(() => {
    return min(product?.variants?.map((v) => v?.fulfillmentPartnerList?.[0].displayPrice)?.filter((p) => isNumber(p)))
  }, [product?.variants])

  const isFlatFee = useMemo(() => {
    return product?.variants?.some((v) => !!v.flatFees)
  }, [product?.variants])

  return (
    <PriceText
      amountInCents={amountInCents}
      isFlatFee={isFlatFee}
      {...rest}
    />
  )
}

interface EventsApprovedPostalPriceRangeV2props extends TextProps {
  postal: ApprovedPostal
}
export const EventsApprovedPostalPriceRangeV2: React.FC<EventsApprovedPostalPriceRangeV2props> = ({
  postal,
  ...rest
}) => {
  const amountInCents = useMemo(() => {
    return min(postal?.variants?.map((v) => v.displayPrice)?.filter((p) => isNumber(p)))
  }, [postal?.variants])

  const isFlatFee = useMemo(() => {
    return postal?.variants?.some((v) => !!v.flatFees)
  }, [postal?.variants])

  return (
    <PriceText
      amountInCents={amountInCents}
      isFlatFee={isFlatFee}
      {...rest}
    />
  )
}
