import { useGraphqlMutation } from '@postal-io/postal-graphql'
import { useAlerts } from '@postal-io/postal-ui'
import type { Address, AddressInput, ApprovedPostal, ApprovedProductVariant, ContactInput, NewContactInput } from 'api'
import {
  BulkContactAddToCampaignDocument,
  CampaignStatus,
  ContactType,
  CreateCampaignDocument,
  CreateMagicLinkDocument,
  CreateSavedSendDocument,
  DeleteSavedSendDocument,
  MagicEventStatus,
  MagicLinkStatus,
  OrderPostalDocument,
  PhoneType,
  SavedSendStatus,
  SavedSendType,
  SendFlowStep,
  SendType,
  Status,
  UpdateMagicLinkDocument,
  UpdateSavedSendDocument,
  UpsertContactDocument,
} from 'api'
import { CATEGORY } from 'components/Postals'
import type { QuickCreateContactForm } from 'components/PostalSend/PostalCustomizeBulkSend'
import { addHours, addMinutes, isBefore, parseISO, startOfTomorrow } from 'date-fns'
import { AnalyticsEvent, CONTACT_INVALIDATIONS, useAnalyticsSend, useBackgroundQueue, useCopyMagicLink } from 'hooks'
import { getParsingError } from 'lib'
import { some } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { AUTOMATION_TYPES, PostalSendMethod, PostalSendType, SEND_METHODS_WITH_CONTACTS_STEP } from './data'
import type { PostalSendContext } from './usePostalSend'

// TODO: Update all imports to point directly at /data.tsx
export * from './data'

export const STARTING_BULK_SEND_QUANTITY = 2
export const MIN_BULK_SEND_QUANTITY = 2
export const DEFAULT_MAX_QUANTITY = 999

export enum SendAsType {
  Self = 'Self',
  User = 'User',
  ContactOwner = 'ContactOwner',
}

export const DEFAULT_ITEM_SUBJECT_LINE = (name: string, accountName: string) => {
  return `${name} from ${accountName} has sent you something`
}

export const DEFAULT_EVENT_SUBJECT_LINE = (accountName: string, postalName: string, eventDate?: string) => {
  const date = eventDate ? parseISO(eventDate) : new Date()
  const dateString = date.toLocaleDateString([], { month: 'long', day: 'numeric', year: 'numeric' })
  return `You've been invited to ${accountName}'s ${postalName} on ${dateString}`
}

export const DEFAULT_LANDING_PAGE_HEADER = (name: string, accountName: string, isEvent: boolean) => {
  return isEvent
    ? `${name} from ${accountName} just invited you to something!`
    : `${name} from ${accountName} just sent you something!`
}

// These are all the same for now but we may be breaking them out in the future
export const SEND_METHOD_COLORS = {
  [PostalSendMethod.Link]: 'atomicBlue.400',
  [PostalSendMethod.Direct]: 'atomicBlue.400',
  [PostalSendMethod.Email]: 'atomicBlue.400',
  [PostalSendMethod.BulkSend]: 'atomicBlue.400',
}

export const getSendMethodColor = (context?: PostalSendContext) => {
  if (!context) return SEND_METHOD_COLORS[PostalSendMethod.Email]
  if (context.method === PostalSendMethod.Link) return SEND_METHOD_COLORS[PostalSendMethod.Link]
  if (context.method === PostalSendMethod.Email) return SEND_METHOD_COLORS[PostalSendMethod.Email]
  if (context.method === PostalSendMethod.Direct) return SEND_METHOD_COLORS[PostalSendMethod.Direct]
  if (context.method === PostalSendMethod.BulkSend) return SEND_METHOD_COLORS[PostalSendMethod.BulkSend]
  return SEND_METHOD_COLORS[context.method ?? PostalSendMethod.Email]
}

/**
 * We are trying to recall the target step but want to ensure there are no errors in prior steps
 * If the user gets all the way to the review step but then goes back and removes all contacts, we want to
 * take them to the contact select page
 */
export const getLandingStep = (context: PostalSendContext, stepList: SendFlowStep[], furthestStep: SendFlowStep) => {
  const stepsToCheck = stepList.slice(0, stepList.indexOf(furthestStep))
  let firstStepWithErrors: SendFlowStep | undefined

  for (const step of stepsToCheck) {
    const key = step as keyof typeof checkForErrors
    if (checkForErrors[key]?.(context)) firstStepWithErrors = firstStepWithErrors ?? step
  }

  return firstStepWithErrors ?? furthestStep
}

export const getMaxBulkSendQuantity = (context?: PostalSendContext) => {
  if (!context) return DEFAULT_MAX_QUANTITY
  const { maximumOrderableQuantity, inventory } = context.parentVariant?.fulfillmentPartnerList?.[0] ?? {}
  return Math.min(maximumOrderableQuantity ?? DEFAULT_MAX_QUANTITY, inventory?.available ?? DEFAULT_MAX_QUANTITY)
}

export const getDefaultDraftName = (context: PostalSendContext) =>
  `${context?.postal?.displayName ?? 'Unknown Item'} - ${new Date().toLocaleString()}`

export const getSavedSendType = (context: PostalSendContext) => {
  if (context.method === PostalSendMethod.Link) return SavedSendType.MagicLink
  if (context.method === PostalSendMethod.BulkSend) return SavedSendType.BulkSend
  if (context.method === PostalSendMethod.Email) return SavedSendType.GiftEmail
  // if (context.type === PostalSendType.Postal && !context.deliveryEmail) return SavedSendType.Direct
  return SavedSendType.Direct
}

export const getSendMethodFromSavedSendType = (sendType: SavedSendType) => {
  if (sendType === SavedSendType.GiftEmail) return PostalSendMethod.Email
  if (sendType === SavedSendType.MagicLink) return PostalSendMethod.Link
  if (sendType === SavedSendType.BulkSend) return PostalSendMethod.BulkSend
  return PostalSendMethod.Direct
}

export const getApiSendType = (context: PostalSendContext) => {
  if (context.method === PostalSendMethod.BulkSend) return SendType.BulkSend
  if (context.method === PostalSendMethod.Email) return SendType.GiftEmail
  if (context.method === PostalSendMethod.Link) return SendType.MagicLink
  return SendType.Direct
}

export const getOrderType = (context: PostalSendContext) => {
  // can't use OrdersTypes constants here due to circular dependency
  if (context.method === PostalSendMethod.BulkSend) return 'BULK'
  if (context.method === PostalSendMethod.Email) return 'EMAILS'
  if (context.method === PostalSendMethod.Link) return 'LINKS'
  return 'DIRECT'
}

export const getFinalStepLabel = (context?: PostalSendContext) => {
  if (!context) return 'Send this Item'
  if (context.link) return 'Update MagicLink'
  if (context.method === PostalSendMethod.Link) return 'Create MagicLink'
  if (context.type === PostalSendType.Trigger) return 'Update Trigger'
  if (context.type === PostalSendType.PlaybookStep) return `Update Subscription Step`
  return 'Confirm and Send'
}

export const tomorrowMidnight = () => startOfTomorrow()
export const tomorrowMorning = () => addHours(startOfTomorrow(), 9)

export const nowPlusDelay = (delayMinutes: number) => {
  return addMinutes(new Date(), delayMinutes)
}

export const ensureFutureDate = (proposedDate: Date, delayMinutes = 0) => {
  const delayedStart = nowPlusDelay(delayMinutes)
  if (!proposedDate) return delayedStart
  return isBefore(proposedDate, delayedStart) ? delayedStart : proposedDate
}

export const getActiveVariants = (postal?: ApprovedPostal) => {
  return postal?.variants?.filter((v: ApprovedProductVariant) => v.status === Status.Active) || []
}

export const hasOrderFlexibility = (context?: PostalSendContext) =>
  ((context?.method === PostalSendMethod.Link || context?.deliveryEmail) && context?.postal?.variantOrderFlexibility) ??
  false

export const defaultCampaignName = (context: PostalSendContext) => {
  return context.postal?.name ?? `Campaign ${new Date().toLocaleDateString('en-US')}`
}

export const defaultLinkName = (context: PostalSendContext) => {
  return context.postal?.name ?? `MagicLink ${new Date().toLocaleDateString('en-US')}`
}

const variableEstimate = 10
export const estimateCharacterCount = (value?: string | null) => {
  if (!value) return 0
  //removes all characters that are not $'s and then counts the length of the $s
  const variablesCount: number | any = value.replace(/[^$]/g, '').length
  //removes all words starting with $ (but saves their surrounding spaces) then counts the length
  const restCount: number | any = value.replace(/\${\S+/g, '').length
  //assigns an estimate per $ and adds to the rest of the string
  const estimatedCount = variableEstimate * variablesCount + restCount
  return estimatedCount
}

export const hasUserMessage = (context: PostalSendContext) => {
  if (context.postal?.category !== CATEGORY.DirectMail) return false
  const sides = ['front', 'back'] as const
  return sides.some((side) => {
    return context.postal?.designTemplate?.[side]?.some?.((element) => {
      // eslint-disable-next-line no-template-curly-in-string
      return element.settings?.text?.includes('${userMessage}')
    })
  })
}

export const hasContactSelect = (context?: PostalSendContext) => {
  return !AUTOMATION_TYPES.includes(context?.type) && SEND_METHODS_WITH_CONTACTS_STEP.includes(context?.method)
}

export const canHaveRecipientNotifications = (context?: PostalSendContext) => {
  return (
    hasContactSelect(context) ||
    context?.method === PostalSendMethod.BulkSend ||
    context?.type === PostalSendType.Trigger
  )
}

export const canIncludeGiftEmail = (context: PostalSendContext) => {
  if (context.method === PostalSendMethod.Link) return false
  if (context.postal?.category === CATEGORY.Events) return true
  if (context.postal?.category === CATEGORY.DirectMail) return false
  if (context.postal?.category === CATEGORY.Handwritten) return false
  if (context.postalDeliveryEmailSetting === 'NEVER') return false
  return true
}

export const canToggleGiftEmail = (context: PostalSendContext) => {
  if (!canIncludeGiftEmail(context)) return false
  if (context.postalDeliveryEmailSetting === 'NEVER') return false
  if (context.postalDeliveryEmailSetting === 'ALWAYS') return false
  return true
}

export const canIncludeLandingPage = (context: PostalSendContext) => {
  if (context.method === PostalSendMethod.Link) return true
  if (context.postal?.category === CATEGORY.DirectMail) return false
  if (context.postal?.category === CATEGORY.Handwritten) return false
  return !!context.deliveryEmail
}

export const giftEmailRequired = (context: PostalSendContext) => {
  if (!canIncludeGiftEmail(context)) return false
  if (context.method === PostalSendMethod.Link) return false
  if (context.postalDeliveryEmailSetting === 'ALWAYS') return true
  return !!context.deliveryEmail
}

export const giftMessageTitle = (context: PostalSendContext) => {
  if (context.method === PostalSendMethod.Link) return 'Landing Page'
  if (context.postal?.category === CATEGORY.Events) return 'Event'
  return 'Gift Email'
}

export const giftMessagePlaceholder = (context: PostalSendContext) => {
  if (context.postal?.category === CATEGORY.Events) {
    return 'This message will be included in the event invitation email sent to your contacts'
  } else {
    return 'This message will be included in the gift email sent to your contacts'
  }
}

export const landingPageBodyPlaceholder = (context: PostalSendContext) => {
  if (context.postal?.category === CATEGORY.Events) {
    return 'This message will be included on the event registration page for recipients'
  }
  if (context.type === PostalSendType?.Link) {
    return 'This message will be included on the MagicLink landing page'
  } else {
    return 'This message will be included in the landing page for your recipients'
  }
}

export const canIncludePhysicalMessage = (context: PostalSendContext) => {
  if (hasUserMessage(context)) return true
  if (context.method === PostalSendMethod.BulkSend) return false
  if (context.postal?.category === CATEGORY.Handwritten) return true
  if (context.postal?.collection) {
    return context.postal?.variants?.some((item) => item.physicalMessageSupported)
  }
  if (context.variant?.physicalMessageSupported) return true
  return false
}

export const canTogglePhysicalMessage = (context: PostalSendContext) => {
  if (!canIncludePhysicalMessage(context)) return false
  if (context.postal?.category === CATEGORY.DirectMail) return false
  if (context.postal?.category === CATEGORY.Handwritten) return false
  return true
}

export const physicalMessageRequired = (context: PostalSendContext) => {
  if (!canIncludePhysicalMessage(context)) return false
  if (hasUserMessage(context)) return true
  if (context.postal?.category === CATEGORY.Handwritten) return true
  return !!context.usePhysicalMessage
}

export const physicalMessageTitle = (context: PostalSendContext) => {
  if (context.postal?.category === CATEGORY.DirectMail) return 'User'
  if (context.postal?.category === CATEGORY.Handwritten) return 'Handwritten'
  return 'Physical Card'
}

export const physicalMessagePlaceholder = (context: PostalSendContext) => {
  if (context.postal?.category === CATEGORY.DirectMail) {
    return 'This message will be included in the User Message section of your direct mail item'
  }
  if (context.postal?.category === CATEGORY.Handwritten) {
    return 'This message will be handwritten on your card'
  }
  return 'This message will be included in a card when your gift is delivered'
}

export const checkForParsingErrors = (text: string | null | undefined, description: string, field: string) => {
  return getParsingError(text) ? { message: `Correct the variable issue in the ${description}`, field } : null
}

export const checkForAddressError = (context: PostalSendContext) => {
  if (context.verifiedShipToAddress) return false

  // Only require state for the US
  const stateRequired = ['United States', 'US', 'USA']
    .map((s) => s.toUpperCase())
    .includes(context.shipToAddress?.country?.toUpperCase() ?? '')

  if (!context.shipToAddress?.address1) return { message: 'Add a shipping address', field: 'shipToAddress.address1' }
  if (!context.shipToAddress?.city) return { message: 'Add a city', field: 'shipToAddress.city' }
  if (!context.shipToAddress?.postalCode) return { message: 'Add a postal code', field: 'shipToAddress.postalCode' }
  if (stateRequired && !context.shipToAddress?.state) return { message: 'Choose a state', field: 'shipToAddress.state' }
  if (!context.shipToAddress?.country) return { message: 'Choose a country', field: 'shipToAddress.country' }
  return false
}

/*
  Sometimes we get an item that has item customizations, but attached to a disabled variant on a collection
  we should strip those out
*/
export const validItemCustomizations = (context: PostalSendContext) => {
  return (context.postal?.itemCustomizationList || []).filter(({ variantId }) => {
    return !variantId || context.postal?.variants?.some((v) => v.id === variantId && v.status === Status.Active)
  })
}

// if the sender can customize this item
export const hasItemCustomization = (context: PostalSendContext) => {
  return !!validItemCustomizations(context).some((f) => f?.customizableBySender)
}

// do we have all the required customizations we need
export const hasRequiredItemCustomizations = (context: PostalSendContext) => {
  return validItemCustomizations(context)
    .filter((f) => f.customizableBySender && f.required && !f.customizableByRecipient)
    .every(({ id, variantId }) => {
      return !!context.itemCustomizationInputs?.find((i) => i.id === id && i.variantId === variantId)?.value
    })
}

// can we edit the postal being sent in this flow
export const canChangePostal = (ctx: any) => {
  // if flow is set then we are in the new send flow & don't want to change the postal in this flow
  return ctx.type !== PostalSendType.Postal && ctx.type !== PostalSendType.Retry && !ctx.method
}

// Any postal we cannot send
export const isPostalDisabled = (postal?: ApprovedPostal | null) => {
  // Inactive postal
  if (postal?.status !== Status.Active) return true
  // No active variants => out of stock
  if (!some(postal.variants?.map((v) => v.status === Status.Active))) return true
  // Event not accepting invites
  if (postal?.event) return postal.event?.status !== MagicEventStatus.AcceptingInvites
}

/**
 * Step logic
 */

export const shouldHaveContactsStep = (ctx: PostalSendContext) => {
  // contacts have already been selected for the contact send type
  return hasContactSelect(ctx) && ctx.type !== PostalSendType.Contact
}
export const shouldHaveCustomizeStep = (ctx: PostalSendContext) => {
  // the customize step is the only chance to select a variant if it has not already been selected (like in the postal send type)
  if (ctx.type !== PostalSendType.Postal) return true

  // these cases have special config in the customize step
  if (!!ctx.isCampaign) return true
  if (ctx.method === PostalSendMethod.Link) return true
  if (ctx.method === PostalSendMethod.BulkSend) return true

  if (canIncludeGiftEmail(ctx)) return true
  if (canIncludePhysicalMessage(ctx)) return true
  if (canIncludeLandingPage(ctx)) return true
  return false
}
export const shouldHaveItemStep = (ctx: PostalSendContext) => {
  return hasItemCustomization(ctx)
}

/**
 * Error checks by step
 */

export const checkForErrors = {
  [SendFlowStep.ChooseMethod]: (ctx: PostalSendContext) => {
    // this is a fake step - perform the checks for the actual step
    if (SEND_METHODS_WITH_CONTACTS_STEP.includes(ctx.method)) return checkForErrors[SendFlowStep.ContactSelection](ctx)
    if (!SEND_METHODS_WITH_CONTACTS_STEP.includes(ctx.method))
      return checkForErrors[SendFlowStep.SendCustomization](ctx)
  },
  [SendFlowStep.ContactSelection]: (ctx: PostalSendContext) => {
    if (!ctx.contacts?.totalRecords) return { message: 'Select a Contact', field: 'contacts' }
  },
  [SendFlowStep.SendCustomization]: (ctx: PostalSendContext) => {
    if (!ctx.variant || ctx.variant.status !== Status.Active) {
      return { message: 'Select an Option', field: 'variant' }
    }

    // Required fields
    if (giftEmailRequired(ctx) && !ctx.giftMessage) {
      return { message: `Add a ${giftMessageTitle(ctx)} Message`, field: 'giftMessage' }
    }
    if (physicalMessageRequired(ctx) && !ctx.physicalMessage && !ctx.useSameMessage) {
      return { message: `Add a ${physicalMessageTitle(ctx)} Message`, field: 'physicalMessage' }
    }

    // Variable syntax errors
    const parsingError =
      checkForParsingErrors(ctx.physicalMessage, `${physicalMessageTitle(ctx)} Message`, 'physicalMessage') ||
      checkForParsingErrors(ctx.landingPageHeaderText, `Landing Page Header`, 'landingPageHeaderText') ||
      checkForParsingErrors(ctx.landingPageBody, `Landing Page Body`, 'landingPageBody') ||
      checkForParsingErrors(ctx.giftMessage, `${giftMessageTitle(ctx)} Message`, 'giftMessage') ||
      checkForParsingErrors(ctx.emailSubjectLine, `Email Subject Line`, 'emailSubjectLine')
    if (parsingError) return parsingError

    // Max characters
    if (ctx.maxPhysicalCharacters && estimateCharacterCount(ctx.physicalMessage) > ctx.maxPhysicalCharacters) {
      return { message: `Shorten your ${physicalMessageTitle(ctx)} Message`, field: 'physicalMessage' }
    }

    // Special cases
    if (ctx.isCampaign) {
      if (!ctx.name) return { message: 'Add a Name', field: 'campaignName' }
      if (!ctx.date) return { message: 'Add a Date', field: 'campaignDate' }
    }
    if (ctx.method === PostalSendMethod.Link) {
      if (!ctx.name) return { message: 'Add a Name', field: 'linkName' }
      if (!ctx.maxExecutions) return { message: 'Set the order limit', field: 'maxExecutions' }
    }
    if (ctx.method === PostalSendMethod.BulkSend) {
      const { bulkSendAvailable } = ctx.parentVariant?.fulfillmentPartnerList![0] ?? {}
      if (!bulkSendAvailable) return { message: 'Select a different variant', field: 'variant' }

      if (!ctx.quantity) return { message: 'Specify a quantity', field: 'quantity' }
      if (ctx.quantity > getMaxBulkSendQuantity(ctx)) return { message: 'Reduce Quantity', field: 'quantity' }
      if ((ctx?.quantity ?? 0) < 2) return { message: 'Quantity must be at least 2', field: 'quantity' }
      if ((!ctx?.newContact?.firstName || !ctx?.newContact?.lastName) && !ctx.contacts?.items?.length)
        return { message: 'Needs Contact info', field: 'contacts' }
      if (checkForAddressError(ctx)) return checkForAddressError(ctx)
      if (!ctx.verifiedShipToAddress) return { message: 'Verify your shipping address', field: 'verifyAddress' }
    }

    // Send as
    if (ctx.sendAsType === SendAsType.User && !ctx.sendAsUser) {
      return { message: 'Select the User to send as', field: 'sendAsUser' }
    }
  },
  [SendFlowStep.ItemCustomization]: (ctx: PostalSendContext) => {
    if (!hasRequiredItemCustomizations(ctx)) {
      return { message: 'Complete all the required fields', field: 'itemCustomization' }
    }
  },
}

export const pagePaths = {
  [SendFlowStep.ChooseMethod]: 'choose_method',
  [SendFlowStep.ContactSelection]: 'select_contacts',
  // postal: 'Select an Item', => deprecating this step
  [SendFlowStep.SendCustomization]: 'configuration',
  [SendFlowStep.ItemCustomization]: 'customization',
  [SendFlowStep.OrderPreview]: 'review',
}

export const pagePathRegExp = new RegExp(`(/(${Object.values(pagePaths).join('|')}))?$`)

export const pageTitles = {
  [SendFlowStep.ChooseMethod]: 'Choose Method',
  [SendFlowStep.ContactSelection]: 'Select Contacts',
  // postal: 'Select an Item', => deprecating this step
  [SendFlowStep.SendCustomization]: 'Configure your Item',
  [SendFlowStep.ItemCustomization]: 'Customize your Item',
  [SendFlowStep.OrderPreview]: 'Review',
}

/**
 * Type Conversions
 */

export const convertQuickContactFormToNewContactInput = (contactForm?: QuickCreateContactForm) => {
  if (!contactForm) return undefined
  const { firstName, lastName, phoneNumber, emailAddress } = contactForm
  return {
    firstName,
    lastName,
    emailAddress,
    phones: phoneNumber
      ? [
          {
            type: PhoneType.Other,
            phoneNumber,
          },
        ]
      : [],
  } as NewContactInput
}

export const convertQuickContactFormToContactInput = (contactForm?: QuickCreateContactForm) => {
  if (!contactForm) return undefined
  const { firstName, lastName, phoneNumber, emailAddress } = contactForm
  return {
    firstName,
    lastName,
    emailAddress,
    type: ContactType.Other,
    phones: phoneNumber
      ? [
          {
            type: PhoneType.Other,
            phoneNumber,
          },
        ]
      : [],
  } as ContactInput
}

export function convertAddressToAddressInput(address?: Address | null) {
  if (!address) return undefined

  // prune fields
  const { statusReason: __, systems: ___, uspsAddress: ____, ...shipToAddress } = address

  return shipToAddress as AddressInput
}

/**
 * API
 */

interface usePostalSendGraphqlArgs {
  onComplete?: (path?: string) => void
  onSaveDraft: () => void
}
export function usePostalSendGraphql({ onComplete, onSaveDraft }: usePostalSendGraphqlArgs) {
  const Alert = useAlerts()
  const navigate = useNavigate()

  const { queue, invalidate } = useBackgroundQueue()

  const sendAnalytics = useAnalyticsSend()

  const createContact = useGraphqlMutation(UpsertContactDocument)
  const orderPostal = useGraphqlMutation(OrderPostalDocument, {
    onSuccess: () => invalidate('getBudgetRemaining', 1000),
  })
  const createCampaign = useGraphqlMutation(CreateCampaignDocument, {
    onSuccess: () => sendAnalytics({ event: AnalyticsEvent.CampaignCreated }),
  })
  const bulkContactAddToCampaign = useGraphqlMutation(BulkContactAddToCampaignDocument, {
    onSuccess: (data) => {
      queue(data.bulkContactAddToCampaign)
      invalidate(CONTACT_INVALIDATIONS)
    },
  })

  const sendCampaignOrder = async (context: PostalSendContext) => {
    const {
      postal,
      variant,
      usePhysicalMessage,
      physicalMessage,
      name,
      date,
      deliveryEmail,
      emailSubjectLine,
      giftMessage,
      sendAsContactOwner,
      sendAsUser,
      meetingRequestSetting,
      itemCustomizationInputs,
      landingPageHeaderText,
      landingPageBody,
      landingPageIncludeHeadshot,
      landingPageIncludeSenderName,
      contacts,
      spendAsTeamId,
      spendAsUserId,
      formFieldList,
      shippedEmailsOn,
      deliveredEmailsOn,
    }: PostalSendContext = context

    const data = {
      name: name || '',
      status: CampaignStatus.Scheduled,
      approvedPostalId: postal?.id,
      variantId: variant?.id,
      giftMessage,
      // this is to avoid displaying the message on sent orders (i.e. PostalFulfillmentCard)
      // if we did not include them on the order
      physicalMessage: usePhysicalMessage ? physicalMessage : undefined,
      scheduleDate: date,
      deliveryEmail,
      emailSubjectLine,
      sendAsContactOwner,
      sendAsUser,
      meetingRequestSetting,
      itemCustomizationInputs,
      landingPageCustomization: {
        headerText: landingPageHeaderText,
        body: landingPageBody,
        includeHeadshot: landingPageIncludeHeadshot,
        includeSenderName: landingPageIncludeSenderName,
      },
      ...(spendAsUserId
        ? {
            spendAs: {
              teamId: spendAsTeamId,
              userId: spendAsUserId,
            },
          }
        : {}),
      formFieldList,
      recipientEmailSettings: {
        shippedEmailsOn,
        deliveredEmailsOn,
      },
    }
    try {
      const results = await createCampaign.mutateAsync({ data })
      const { id } = results?.createCampaign ?? {}
      await bulkContactAddToCampaign.mutateAsync({
        campaignId: id,
        campaignName: name || '',
        orfilters: contacts?.orfilters,
      })
      if (context.draft) deleteSavedSend.mutateAsync({ id: context.draft.id })
      Alert.success('Group Order Created!')
      onComplete ? onComplete() : navigate(`/orders/group/${results.createCampaign.id}`, { state: { isSuccess: true } })
      // onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  // only one contact
  const sendPostalOrder = async (context: PostalSendContext) => {
    const {
      postal,
      variant,
      contacts,
      newContact,
      quantity,
      shipToAddress,
      deliveryEmail,
      giftMessage,
      emailSubjectLine,
      usePhysicalMessage,
      physicalMessage,
      sendAsContactOwner,
      sendAsUser,
      meetingRequestSetting,
      itemCustomizationInputs,
      landingPageHeaderText,
      landingPageBody,
      landingPageIncludeHeadshot,
      landingPageIncludeSenderName,
      spendAsTeamId,
      spendAsUserId,
      formFieldList,
      shippedEmailsOn,
      deliveredEmailsOn,
    }: PostalSendContext = context

    // make new contact from entered bulk send info if no contact selected
    const contactId =
      contacts?.items?.[0]?.id ??
      (await createContact.mutateAsync({ data: convertQuickContactFormToContactInput(newContact)! })).upsertContact.id

    const data = {
      approvedPostalId: postal?.id,
      approvedPostalVariantId: variant?.id as string,
      contactId,
      sendType: getApiSendType(context),
      giftMessage,
      physicalMessage: usePhysicalMessage ? physicalMessage : undefined,
      skipDuplicateOrderCheck: true,
      deliveryEmail,
      emailSubjectLine,
      sendAsContactOwner,
      sendAsUser,
      meetingRequestSetting,
      itemCustomizationInputs,
      landingPageCustomization: {
        headerText: landingPageHeaderText,
        body: landingPageBody,
        includeHeadshot: landingPageIncludeHeadshot,
        includeSenderName: landingPageIncludeSenderName,
      },
      ...(spendAsUserId
        ? {
            spendAs: {
              teamId: spendAsTeamId,
              userId: spendAsUserId,
            },
          }
        : {}),
      // bulk send only fields
      ...(context.method === PostalSendMethod.BulkSend
        ? { quantity, shipToAddress: convertAddressToAddressInput(shipToAddress) }
        : {}),
      formFieldList,
      recipientEmailSettings: {
        shippedEmailsOn,
        deliveredEmailsOn,
      },
    }

    try {
      const res = await orderPostal.mutateAsync({ data })
      if (context.draft) deleteSavedSend.mutateAsync({ id: context.draft.id })
      Alert.success('Item is Ordered!')
      onComplete
        ? onComplete()
        : navigate(`/orders/${getOrderType(context).toLowerCase()}/${res.orderPostal.id}`, {
            state: { isSuccess: true },
          })
      // onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  /**
   * MagicLinks
   */

  const copyLink = useCopyMagicLink()

  const createLink = useGraphqlMutation(CreateMagicLinkDocument)
  const updateLink = useGraphqlMutation(UpdateMagicLinkDocument)

  const handleLinkComplete = async (context: PostalSendContext) => {
    const {
      name,
      date,
      link,
      maxExecutions,
      giftMessage,
      postal,
      variant,
      enabled,
      physicalMessage,
      sendAsContactOwner,
      sendAsUser,
      sendAsType,
      meetingRequestSetting,
      itemCustomizationInputs,
      formFieldList,
      landingPageHeaderText,
      landingPageBody,
      landingPageIncludeHeadshot,
      landingPageIncludeSenderName,
      spendAsTeamId,
      spendAsUserId,
      linkNeedsApproval,
    } = context
    if (!name) return Alert.error('Name is required.')
    if (!maxExecutions) return Alert.error('Please set a limit on orders.')
    if (!postal?.id || !variant?.id) return Alert.error('Please select an Item and Option')
    if (giftEmailRequired(context) && !giftMessage) {
      return Alert.error(`Please add a ${giftMessageTitle(context)} Message`)
    }
    if (physicalMessageRequired(context) && !physicalMessage) {
      return Alert.error(`Please add a ${physicalMessageTitle(context)} Message`)
    }
    if (sendAsType === SendAsType.User && !sendAsUser) {
      return Alert.error('Please select a User to send as')
    }

    const data = {
      name,
      status: enabled ? MagicLinkStatus.Active : MagicLinkStatus.Disabled,
      expirationDate: date ?? null,
      maxExecutions: maxExecutions,
      message: giftMessage ?? '',
      physicalMessage,
      approvedPostalId: postal?.id,
      variantId: variant?.id,
      sendAsContactOwner,
      sendAsUser,
      meetingRequestSetting,
      itemCustomizationInputs,
      formFieldList,
      landingPageCustomization: {
        headerText: landingPageHeaderText,
        body: landingPageBody,
        includeHeadshot: landingPageIncludeHeadshot,
        includeSenderName: landingPageIncludeSenderName,
      },
      ...(spendAsUserId
        ? {
            spendAs: {
              teamId: spendAsTeamId,
              userId: spendAsUserId,
            },
          }
        : {}),
      requiresApproval: linkNeedsApproval,
    }

    try {
      if (link?.id) {
        const res = await updateLink.mutateAsync({ id: link.id, data })
        if (context.draft) deleteSavedSend.mutateAsync({ id: context.draft.id })
        copyLink(res?.updateMagicLink.linkUrl)
        //redirect to link page
        onComplete ? onComplete() : navigate(`/links/${res.updateMagicLink?.id}`, { state: { isSuccess: true } })
      } else {
        const res = await createLink.mutateAsync({ data })
        if (context.draft) deleteSavedSend.mutateAsync({ id: context.draft.id })
        copyLink(res?.createMagicLink.linkUrl)
        // redirect to link page
        onComplete ? onComplete() : navigate(`/links/${res.createMagicLink?.id}`, { state: { isSuccess: true } })
      }
      // onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  /**
   * Drafts
   */

  const deleteSavedSend = useGraphqlMutation(DeleteSavedSendDocument)
  const createSavedSend = useGraphqlMutation(CreateSavedSendDocument)
  const updateSavedSend = useGraphqlMutation(UpdateSavedSendDocument)

  const handleDeleteDraft = async (context: PostalSendContext) => {
    const { draft }: PostalSendContext = context

    if (draft) {
      try {
        await deleteSavedSend.mutateAsync({ id: draft.id })
        Alert.success('Successfully deleted draft.')
        navigate('/orders')
      } catch (e) {
        Alert.error(e)
      }
    }
  }

  const handleSaveDraft = async (context: PostalSendContext, draftName?: string) => {
    const {
      draft,
      date,
      method,
      name,
      enabled: linkEnabled,
      // bulk send stuff
      quantity: bulkSendQuantity,
      shipToAddress,
      verifiedShipToAddress,
      newContact,
      furthestStep,
      postal,
      variant,
      contacts,
      deliveryEmail,
      giftMessage,
      maxExecutions,
      linkNeedsApproval,
      emailSubjectLine,
      usePhysicalMessage,
      physicalMessage,
      sendAsContactOwner,
      sendAsUser,
      itemCustomizationInputs,
      meetingRequestSetting,
      landingPageHeaderText,
      landingPageBody,
      landingPageIncludeHeadshot,
      landingPageIncludeSenderName,
      spendAsTeamId,
      spendAsUserId,
      formFieldList,
      shippedEmailsOn,
      deliveredEmailsOn,
    }: PostalSendContext = context

    const savedSendName = draftName ?? getDefaultDraftName(context)

    const data = {
      // missing params:
      // itemCustomizationInputs,
      // magicLink enabled
      // campaignInfo.useSameMessage (to use physical message as email)

      sendType: getSavedSendType(context),
      savedSendName,
      sendFlowStep: furthestStep,
      commonSendProperties: {
        name,
        approvedPostalId: postal?.id,
        variantId: variant?.id,
        formFieldList,
        physicalMessage: usePhysicalMessage ? physicalMessage : undefined,
        landingPageCustomization: {
          headerText: landingPageHeaderText,
          body: landingPageBody,
          includeHeadshot: landingPageIncludeHeadshot,
          includeSenderName: landingPageIncludeSenderName,
        },
        recipientEmailSettings: {
          shippedEmailsOn,
          deliveredEmailsOn,
        },
        itemCustomizationInputs,
        sendAsContactOwner,
        sendAsUser,
        meetingRequestSetting,
        ...(spendAsUserId
          ? {
              spendAs: {
                teamId: spendAsTeamId,
                userId: spendAsUserId,
              },
            }
          : {}),
      },
      ...(method === PostalSendMethod.Link
        ? {
            savedSendMagicLinkInfo: {
              expirationDate: date ?? null,
              maxExecutions,
              status: linkEnabled ? MagicLinkStatus.Active : MagicLinkStatus.Disabled,
              message: giftMessage ?? '',
              requiresApproval: linkNeedsApproval,
            },
          }
        : [PostalSendMethod.Direct, PostalSendMethod.Email].includes(method)
        ? {
            savedSendCampaignInfo: {
              deliveryEmail,
              //missing field: deliveryEmailTemplateId
              emailSubjectLine,
              giftMessage,
              scheduleDate: date,
              contactSearchFilters: contacts?.orfilters,
            },
          }
        : method === PostalSendMethod.BulkSend
        ? {
            savedSendBulkSendInfo: {
              quantity: bulkSendQuantity,
              shipToAddress: convertAddressToAddressInput(shipToAddress),
              addressVerified: verifiedShipToAddress,
              contactId: contacts?.items?.[0]?.id,
              newContact: convertQuickContactFormToNewContactInput(newContact),
            },
          }
        : {}),
      status: SavedSendStatus.Draft,
    }

    try {
      if (draft) {
        await updateSavedSend.mutateAsync({ id: draft.id, data })
        onSaveDraft && onSaveDraft()
        Alert.success(`Draft "${savedSendName}" Updated`)
      } else {
        await createSavedSend.mutateAsync({ data })
        onSaveDraft && onSaveDraft()
        Alert.success(`Draft Saved as "${savedSendName}"`)
      }
    } catch (e) {
      Alert.error(e)
    }
  }

  return {
    sendCampaignOrder,
    sendPostalOrder,
    handleLinkComplete,
    handleSaveDraft,
    handleDeleteDraft,
    isLoading:
      orderPostal.isLoading ||
      createCampaign.isLoading ||
      bulkContactAddToCampaign.isLoading ||
      deleteSavedSend.isLoading ||
      updateLink.isLoading,
  }
}
