import { FormControl, HStack, InputGroup, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import type { UseGraphqlFilterResponse } from '@postal-io/postal-ui'
import {
  SelectTypeaheadStylesV2,
  UiToggle,
  useColor,
  ZButton,
  ZInput,
  ZInputLeftIcon,
  ZSelect,
  ZText,
} from '@postal-io/postal-ui'
import { capitalize } from 'lodash'
import type { ChangeEvent } from 'react'
import React, { useMemo } from 'react'
import { MdFilterList, MdSearch } from 'react-icons/md'
import { useLocation } from 'react-router'
import type { ApprovedPostal, User } from '../../api'
import { MagicLinkStatus, Role } from '../../api'
import { useAcl } from '../../hooks/useAcl'
import { useSession } from '../../hooks/useSession'
import { AutoCompleteItem, AutoCompleteUser } from '../AutoComplete'
import { DEFAULT_ROLES } from '../Users/UsersData'

interface LinksFilterProps extends Pick<UseGraphqlFilterResponse, 'filter' | 'setFilter'> {
  canFilterOwner: boolean
  onCreate?: () => void
}

const STATUS = [MagicLinkStatus.Active, MagicLinkStatus.Disabled]

export const LinksFilter: React.FC<LinksFilterProps> = ({ filter, setFilter, canFilterOwner }) => {
  const { colorCode } = useColor()
  const { hasRole } = useAcl()
  const { session } = useSession()
  const { state } = useLocation() as any
  const isManager = hasRole(Role.Manager)
  const isAdmin = hasRole(Role.Admin)

  const showToggle = isManager || isAdmin

  const isMyMagicLinks = useMemo(() => filter.userId === session?.userId, [session?.userId, filter.userId])

  const showFiltersDisclosure = useDisclosure()

  const handleInput = ({ target: { name, value } }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (name === 'userId') {
      setFilter({ key: name, value: !isMyMagicLinks ? session.userId : undefined })
    } else {
      setFilter({ key: name, value })
    }
  }

  return (
    <>
      <SimpleGrid
        columns={{ base: 1, lg: 3 }}
        gap={4}
        w="100%"
      >
        {showToggle && (
          <HStack spacing={3}>
            <UiToggle
              name="userId"
              colorScheme="atomicBlue"
              isChecked={filter.userId === session.userId}
              onChange={handleInput}
              ml={1}
            />
            <ZText>My MagicLinks</ZText>
          </HStack>
        )}
        <FormControl
          id="search"
          justifySelf="center"
        >
          <InputGroup>
            <ZInputLeftIcon icon={<MdSearch size="16px" />} />
            <ZInput
              name="name"
              placeholder="Search"
              w="100%"
              value={filter.name || ''}
              onChange={handleInput}
            />
          </InputGroup>
        </FormControl>
        <ZButton
          justifySelf="right"
          w="100px"
          variant="outline"
          colorScheme="atomicGray"
          color="atomicGray.500"
          borderColor="atomicGray.10"
          fontWeight="normal"
          rightIcon={<MdFilterList color={colorCode('atomicGray.300')} />}
          onClick={showFiltersDisclosure.onToggle}
        >
          Filters
        </ZButton>
      </SimpleGrid>
      {showFiltersDisclosure.isOpen && (
        <SimpleGrid
          mt={4}
          gap={4}
          w="100%"
          columns={{ base: 1, lg: canFilterOwner ? 3 : 2 }}
        >
          {canFilterOwner && (
            <AutoCompleteUser
              placeholder="MagicLink Owner"
              roles={DEFAULT_ROLES}
              onChange={(user: User | null) => setFilter({ key: 'userId', value: user?.id })}
              {...SelectTypeaheadStylesV2}
            />
          )}

          <AutoCompleteItem
            onChange={(item: ApprovedPostal | null) => setFilter({ key: 'approvedPostalId', value: item?.id })}
            defaultInputValue={state?.name as string}
            {...SelectTypeaheadStylesV2}
          />

          <ZSelect
            onChange={handleInput}
            name="status"
            value={filter.status}
            placeholder="-- Any Status --"
          >
            {STATUS?.map((status) => {
              return (
                <option
                  key={status}
                  value={status}
                >
                  {capitalize(status)}
                </option>
              )
            })}
          </ZSelect>
        </SimpleGrid>
      )}
    </>
  )
}
