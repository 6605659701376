import type { ZInputProps } from '@postal-io/postal-ui'
import { countries, ZInput, ZSelectTypeahead } from '@postal-io/postal-ui'
import React, { forwardRef, useMemo } from 'react'

export interface StateInputProps extends Omit<ZInputProps, 'onChange'> {
  country?: string
  onChange?: (state: string | undefined) => void
}

export const StateInput: React.FC<React.PropsWithChildren<StateInputProps>> = forwardRef(
  ({ country, onChange, value, ...props }, ref) => {
    const provinces = useMemo(() => {
      const parent = countries.find((c) => {
        if (!country) return false
        return (
          c.iso2.toLowerCase() === country.toLowerCase() ||
          c.iso3.toLowerCase() === country.toLowerCase() ||
          c.name.toLowerCase() === country.toLowerCase()
        )
      })
      return parent ? parent.provinces : countries.map((c) => c.provinces).flat()
    }, [country])

    const selectedState = useMemo(() => {
      return (
        countries
          .filter((c) => (country ? c.iso3 === country : true))
          .map((c) => c.provinces)
          .flat()
          .find((s) => s.code === value || s.name === value) || null
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return provinces.length ? (
      <ZSelectTypeahead
        inputRef={ref as any}
        options={provinces}
        onChange={(state) => onChange?.(state?.code || undefined)}
        value={selectedState}
        getOptionLabel={(province) => province.name}
        getOptionValue={(province) => province.code}
        {...props}
      />
    ) : (
      <ZInput
        value={value}
        onChange={(e: any) => onChange?.(e.target.value || '')}
      />
    )
  }
)
