import type { SlideDirection } from '@chakra-ui/react'
import { Flex, Slide, StatNumber } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import {
  UiIconButton,
  UiSlideContainer,
  UiStat,
  UiStatGroup,
  UiStatHelpText,
  useColor,
  usePagination,
  ZCard,
  ZCardHeader,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import { DataObjectType, Granularity, UserAnalyticsV2Document } from 'api'
import { ZStatArrow } from 'components/Common/ZComponents'
import { differenceInMilliseconds, subMilliseconds } from 'date-fns'
import { getChangeMetric } from 'lib'
import { isEqual, sortBy } from 'lodash'
import React, { useCallback, useMemo, useRef } from 'react'
import type { AxisOptions } from 'react-charts'
import { Chart } from 'react-charts'
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md'
import { GroupBy } from '../../../api/index'
import { useSession } from '../../../hooks/useSession'
import { NoDataOverlay } from '../NoDataOverlay'
import type { GroupByOptionV2 } from '../ReportingFilter/GroupByFilter'
import { PLACEHOLDER_USER_ANALYTICS_V2 } from './data'

type BarData = { name: string; data: number; color: string }

const CHART_SIZE = 5

interface SpendCardV2Props extends ZCardProps {
  startDate: Date
  endDate: Date
  teamIds?: string[]
  userIds?: string[]
  groupBy?: GroupByOptionV2
}

export const SpendCard: React.FC<SpendCardV2Props> = ({ startDate, endDate, teamIds, userIds, groupBy, ...rest }) => {
  const { session } = useSession()
  const { colorCode } = useColor()

  const curQuery = useGraphqlQuery(UserAnalyticsV2Document, {
    config: {
      startDate,
      endDate,
      teamIds,
      userIds,
      groupBy,
      granularity: Granularity.All,
      type: DataObjectType.User,
    },
  })

  const ALL_DATA_SORTED = useMemo(() => {
    const ALL_DATA = curQuery.data?.userAnalyticsV2 || []
    return ALL_DATA?.length ? sortBy(ALL_DATA, 'firstName') : sortBy(PLACEHOLDER_USER_ANALYTICS_V2, 'firstName')
  }, [curQuery.data?.userAnalyticsV2])

  const curCost = useMemo(() => {
    const data = curQuery.data?.userAnalyticsV2 || []
    return data.reduce((sum, user) => sum + (user.costInCents || 0), 0)
  }, [curQuery.data?.userAnalyticsV2])

  const newStartDate = useMemo(() => {
    const diff = differenceInMilliseconds(endDate, startDate)
    return subMilliseconds(startDate, diff)
  }, [endDate, startDate])

  const prevQuery = useGraphqlQuery(UserAnalyticsV2Document, {
    config: {
      startDate: newStartDate,
      endDate: startDate,
      teamIds,
      userIds,
      groupBy,
      granularity: Granularity.All,
      type: DataObjectType.User,
    },
  })

  const prevCost = useMemo(() => {
    const data = prevQuery.data?.userAnalyticsV2 || []
    return data.reduce((sum, user) => sum + (user.costInCents || 0), 0) / 100
  }, [prevQuery.data?.userAnalyticsV2])

  const costChange = useMemo(() => getChangeMetric(curCost, prevCost), [curCost, prevCost])

  const [items, pager] = usePagination(ALL_DATA_SORTED ?? [], CHART_SIZE)

  const getItemName = useCallback(
    (item: any) => {
      if (groupBy === GroupBy.Team) return item.teamId === null ? session.accountName : item.teamName
      return `${item?.firstName} ${item?.lastName}` ?? ''
    },
    [groupBy, session.accountName]
  )

  const normalizedDataSpend = useMemo(() => {
    return items.map((item) => ({
      name: getItemName(item),
      data: (item.costInCents || 0) / 100,
      color: 'atomicBlue.400',
    }))
  }, [getItemName, items]) as BarData[]

  const normalizedChartData = useMemo(() => {
    return [{ label: 'Spend (USD)', data: normalizedDataSpend }]
  }, [normalizedDataSpend])

  const primaryAxis = React.useMemo(
    (): AxisOptions<BarData> => ({ getValue: (datum) => datum.name, scaleType: 'band' }),
    []
  )

  const secondaryAxes = React.useMemo(
    (): AxisOptions<BarData>[] => [{ getValue: (datum) => datum.data, elementType: 'bar', min: 0 }],
    []
  )

  const direction = useRef('right')
  const currentItemList = useRef(items)

  const nextPage = () => {
    direction.current = 'right'
    currentItemList.current = items
    pager.onNext()
  }

  const prevPage = () => {
    direction.current = 'left'
    currentItemList.current = items
    pager.onPrevious()
  }

  const showNoDataOverlayV2 = useMemo(() => !curQuery.isFetching && !curCost, [curCost, curQuery.isFetching])

  return (
    <ZCard
      isLoading={prevQuery.isLoading}
      isFetching={prevQuery.isFetching}
      variant="form"
      {...rest}
    >
      {showNoDataOverlayV2 && <NoDataOverlay />}

      <Flex
        justifyContent="space-between"
        alignItems="baseline"
      >
        <ZCardHeader
          display="flex"
          p={4}
          pb={2}
          fontSize="lg"
        >
          Spend
        </ZCardHeader>
        <UiStatGroup fontFamily="Lexend">
          <UiStat
            display="flex"
            justifyContent="flex-end"
          >
            <StatNumber
              fontWeight="normal"
              fontSize="lg"
              textAlign="right"
              p={4}
              pb={2}
            >
              <ZMoney
                cents={curCost}
                currency="USD"
                round
                color="atomicGray.700"
                fontSize="inherit"
              />
            </StatNumber>
            <UiStatHelpText
              px={4}
              m={0}
              display="flex"
              alignItems="center"
              gap={1}
            >
              <ZStatArrow
                color={costChange >= 0 ? 'vendorGreen.500' : 'atomicRed.500'}
                type={costChange >= 0 ? 'increase' : 'decrease'}
              />
              <ZText
                fontSize="xs"
                color="atomicGray.500"
              >
                {Math.round(costChange) || 0} %
              </ZText>
            </UiStatHelpText>
          </UiStat>
        </UiStatGroup>
      </Flex>

      <Flex
        alignItems="center"
        mt={8}
        pb={8}
      >
        <UiIconButton
          aria-label="previous chart"
          size="sm"
          variant="ghost"
          icon={<MdOutlineChevronLeft size="50px" />}
          onClick={prevPage}
          disabled={pager.page === 1}
        />
        <UiSlideContainer h="400px">
          <Slide
            key={pager.page}
            direction={direction.current as SlideDirection}
            in={pager.page === 1 || !isEqual(items, currentItemList.current)}
            style={{ position: 'relative' }}
          >
            <Chart
              options={{
                getDatumStyle: (datum) => ({ color: colorCode(datum.originalDatum.color) }),
                data: normalizedChartData,
                primaryAxis,
                secondaryAxes,
              }}
            />
          </Slide>
        </UiSlideContainer>
        <UiIconButton
          aria-label="previous chart"
          size="sm"
          variant="ghost"
          icon={<MdOutlineChevronRight size="50px" />}
          onClick={nextPage}
          disabled={pager.pages === pager.page}
        />
      </Flex>
    </ZCard>
  )
}
