import type { ButtonProps, FlexProps } from '@chakra-ui/react'
import { Box, Divider, Flex, Grid, Icon, Image, List, ListIcon, ListItem, useDisclosure } from '@chakra-ui/react'
import { UiButton, UiIconGift, ZHeading, ZText } from '@postal-io/postal-ui'
import { AnalyticsEventV2, PageTitle, useAnalyticsSend } from 'hooks'
import React, { Fragment, useEffect } from 'react'
import { MdSend } from 'react-icons/md'
import { conciergeHero, CONCIERGE_IMGS } from '../../assets'
import { CONSIERGE_INFO } from './data'
import { PostalConciergeForm } from './PostalConciergeForm'

interface GetStartedBlockProps extends Omit<FlexProps, 'onClick'> {
  title: string
  onClick: ButtonProps['onClick']
}
const GetStartedBlock: React.FC<GetStartedBlockProps> = ({ title, onClick, children, ...rest }) => {
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      bg="app.bg"
      p={8}
      {...rest}
    >
      <ZText
        fontSize="3xl"
        variant="bold"
        color="atomicGray.700"
        my={8}
      >
        {title}
      </ZText>
      <ZText
        fontSize="xl"
        color="atomicGray.900"
        maxW="950px"
        mb={8}
        textAlign="center"
      >
        {children}
      </ZText>
      <UiButton
        variant="ghost"
        backgroundColor="white"
        borderRadius="full"
        mb={8}
        py={8}
        px={8}
        size="lg"
        boxShadow="md"
        onClick={onClick}
      >
        <Icon
          as={UiIconGift}
          mr={4}
          boxSize="8"
          color="atomicBlue.500"
        />
        Let's Get Started
      </UiButton>
    </Flex>
  )
}

export const PostalConcierge: React.FC = () => {
  const sendAnalytics = useAnalyticsSend()
  const conciergeForm = useDisclosure()

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  const onGetStarted = () => {
    sendAnalytics({ event: AnalyticsEventV2.Concierge_LetsGetStarted_Clicked })
    conciergeForm.onOpen()
  }

  return (
    <Box bg="white">
      <PageTitle title="Paper Plane Agency" />
      <Box backgroundColor="#efefef">
        <Box
          maxW="1600px"
          mx="auto"
          backgroundColor="white"
          pt={8}
        >
          <Grid
            templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
            px="68px"
            gap={8}
            maxW="1440px"
            mx="auto"
          >
            <Image
              src={conciergeHero}
              objectFit="cover"
              mx="auto"
              mt={2}
              borderWidth="6px"
              borderColor="shades.20"
              borderStyle="solid"
            />
            <Flex direction="column">
              <ZHeading
                mb="33px"
                fontSize={{ base: '50px', lg: '72px' }}
                textAlign={{ base: 'center', lg: 'left' }}
                lineHeight={1}
              >
                Customized and Curated
              </ZHeading>
              <ZText
                size="lg"
                textAlign={{ base: 'center', lg: 'left' }}
                mb={2}
              >
                Work with our Postal Concierge Team who is now the Paper Plane Marketing Agency to
              </ZText>
              <List
                mb={8}
                ml={1}
              >
                <ListItem>
                  <ListIcon
                    as={MdSend}
                    color="atomicBlue.900"
                  />
                  <ZText display="inline">Deliver uniquely curated experiences and items</ZText>
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={MdSend}
                    color="atomicBlue.900"
                  />
                  <ZText display="inline">Bring your physical swag closet into Postal</ZText>
                </ListItem>
              </List>
              <Divider w="100%" />
              <Flex
                justifyContent="space-around"
                alignItems="center"
              >
                {CONSIERGE_INFO.map((item, idx) => {
                  const isLast = CONSIERGE_INFO.length - 1 === idx

                  return (
                    <Fragment key={idx}>
                      <Flex
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        py={8}
                      >
                        <ZText
                          color={item.titleColor}
                          size="sm"
                          variant="bold"
                          mb={4}
                        >
                          {item.title.toUpperCase()}
                        </ZText>
                        <Icon
                          as={item.icon}
                          h="62px"
                          w="68px"
                          color={item.textColor}
                          mb={4}
                        />
                        <ZText
                          color={item.textColor}
                          size="lg"
                          variant="bold"
                          mb={4}
                        >
                          {item.text}
                        </ZText>
                      </Flex>
                      {!isLast && (
                        <Divider
                          orientation="vertical"
                          h="100px"
                        />
                      )}
                    </Fragment>
                  )
                })}
              </Flex>
            </Flex>
          </Grid>

          <GetStartedBlock
            title="Create and Deliver More Meaningful Experiences"
            onClick={onGetStarted}
            backgroundColor="#f6f8fa"
          >
            The Paper Plane Marketing Agency can help design, source, and store customized items for your business.{' '}
            <br /> Start a new project or bring your physical swag closet into Postal.
          </GetStartedBlock>

          <ZText
            textAlign="center"
            fontSize="32px"
            color="#29404E"
            my={12}
          >
            Customized Postal Examples
          </ZText>
          <Box
            pb={5}
            maxW="1440px"
            mx="auto"
          >
            <Grid
              templateColumns={{ base: '1fr 1fr', lg: '1fr 1fr 1fr' }}
              justifyItems="stretch"
              gap={5}
              px={5}
            >
              {CONCIERGE_IMGS.map((img, idx) => (
                <Box
                  borderColor="gray.100"
                  borderRadius="lg"
                  borderWidth="1px"
                  key={idx}
                >
                  <Image
                    objectFit="cover"
                    width="100%"
                    h="100%"
                    key={idx}
                    src={img}
                    borderRadius="lg"
                  />
                </Box>
              ))}
            </Grid>
          </Box>

          <GetStartedBlock
            title="Get Started with Paper Plane"
            onClick={onGetStarted}
            mt={16}
            mb={-8}
            backgroundColor="#f2fafe"
          >
            Connect with Paper Plane today and start creating a <br /> world-class experience for your prospects,
            customers, and employees.
          </GetStartedBlock>
        </Box>
      </Box>

      {conciergeForm.isOpen && <PostalConciergeForm {...conciergeForm} />}
    </Box>
  )
}
