import type { BoxProps } from '@chakra-ui/react'
import { AspectRatio, Box, Flex, Grid, SkeletonCircle, useBreakpointValue } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiLiftUpEffectProps } from '@postal-io/postal-ui'
import { UiLiftUpEffect, ZHeading } from '@postal-io/postal-ui'
import { BEST_SELLERS_BY_PRICE } from 'components/Postals'
import { AnalyticsEventV2, useAnalyticsSend } from 'hooks'
import React, { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { SearchMarketplaceProductsDocument } from '../../api'
import type { MarketplaceProduct } from '../../api/index'
import { ZLink } from '../Common/ZComponents'
import { OldMarketplaceCardProduct } from './MarketplaceCardProduct'
import { MarketplaceContext } from './useMarketplace'

const BestSellerByPriceCircle: React.FC<UiLiftUpEffectProps> = ({ children, onClick, ...rest }) => {
  return (
    <UiLiftUpEffect
      borderRadius="full"
      w="100%"
      maxW="228px"
      {...rest}
    >
      <AspectRatio ratio={1}>
        <Flex
          gap={2}
          flexDir="column"
          borderRadius="full"
          bgColor="atomicBlue.300"
          borderWidth="10px"
          borderColor="atomicBlue.10"
          alignItems="center"
          justifyContent="space-between"
          color="white"
          cursor="pointer"
          onClick={onClick}
          p={6}
        >
          {children}
        </Flex>
      </AspectRatio>
    </UiLiftUpEffect>
  )
}

interface MarketplaceSectionPopularItemsV2Props extends Omit<BoxProps, 'onSelect'> {
  isLoading?: boolean
  onSelect: (product: MarketplaceProduct) => void
}

export const OldMarketplaceSectionPopularItems: React.FC<MarketplaceSectionPopularItemsV2Props> = ({
  isLoading,
  onSelect,
  ...rest
}) => {
  const navigate = useNavigate()
  const sendAnalytics = useAnalyticsSend()

  const subCategory = 'Popular'

  const numColumns = useBreakpointValue({ base: 1, sm: 2, md: 3, xl: 4 }) ?? 1

  const circleStyleProps = useBreakpointValue({
    base: { title: { size: 'h6' }, pricing: { size: 'h5' }, gap: 2 },
    lg: { title: { size: 'h5' }, pricing: { size: 'h4' }, gap: 10 },
  })

  const numPopularProducts = useMemo(() => (numColumns === 3 ? 6 : 4), [numColumns])

  const {
    marketplaceFilters: { updateFilter },
  } = useContext(MarketplaceContext)

  const searchMarketplaceProducts = useGraphqlQuery(SearchMarketplaceProductsDocument, {
    filter: { subCategory: { eq: subCategory } },
    // use larger LIMIT to make shuffling possible
    limit: 100,
  })

  const popularProducts = useMemo(() => {
    return searchMarketplaceProducts?.data?.searchMarketplaceProducts?.slice(0, numPopularProducts) ?? []
  }, [numPopularProducts, searchMarketplaceProducts?.data?.searchMarketplaceProducts])

  const handleClickPriceRange = (price: { min?: number; max?: number }) => {
    sendAnalytics({ event: AnalyticsEventV2.Marketplace_BestSellers_Selected, data: { price } })
    updateFilter('subCategory', subCategory, 0)
    updateFilter('price', price, 5)
    navigate('/items/marketplace')
  }

  const handleShowAll = () => {
    updateFilter('subCategory', subCategory, 0)
    navigate('/items/marketplace')
  }

  const isSomethingLoading = searchMarketplaceProducts?.isLoading || isLoading

  return (
    <Box
      w="100%"
      {...rest}
    >
      <Flex
        w="100%"
        align="baseline"
        justifyContent="space-between"
        gap={10}
      >
        <ZHeading
          as="h2"
          size="h5"
          variant="bold"
          mb={6}
        >
          Best Sellers
        </ZHeading>

        <ZLink
          color="atomicBlue.600"
          fontWeight="bold"
          onClick={handleShowAll}
        >
          Show All
        </ZLink>
      </Flex>
      <Grid
        gap={5}
        templateColumns={{ base: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }}
      >
        {popularProducts.map((product) => (
          <OldMarketplaceCardProduct
            isLoading={isSomethingLoading}
            variant="collapse"
            key={product.id}
            product={product}
            onSelect={(item: MarketplaceProduct) => onSelect(item)}
          />
        ))}
      </Grid>
      <Grid
        mt={10}
        templateColumns="repeat(5, 1fr)"
        gap={circleStyleProps?.gap as number}
        justifyItems="center"
      >
        {BEST_SELLERS_BY_PRICE?.map((obj) => (
          <SkeletonCircle
            key={obj.text}
            w="100%"
            h="100%"
            startColor="atomicGray.50"
            endColor="atomicGray.100"
            isLoaded={!isSomethingLoading}
          >
            <BestSellerByPriceCircle onClick={() => handleClickPriceRange(obj.priceRange)}>
              <ZHeading
                color="white"
                textAlign="center"
                {...(circleStyleProps?.title as any)}
              >
                {'Best Sellers ' + obj.heading}
              </ZHeading>
              <ZHeading
                variant="bold"
                color="white"
                textAlign="center"
                {...(circleStyleProps?.pricing as any)}
              >
                {obj.text}
              </ZHeading>
            </BestSellerByPriceCircle>
          </SkeletonCircle>
        ))}
      </Grid>
    </Box>
  )
}
