import { Box, Flex, Progress, Stack } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { ZCardProps } from '@postal-io/postal-ui'
import { UiStat, UiStatGroup, UiStatHelpText, ZCard, ZCardHeader, ZHeading, ZText } from '@postal-io/postal-ui'
import { DataObjectType, Granularity, UserAnalyticsV2Document } from 'api'
import { useMemo } from 'react'
import { IndustryStandardV2 } from '../../../lib/reporting'
import { NoDataOverlay } from '../NoDataOverlay'

interface GiftEmailStatsCardV2Props extends ZCardProps {
  startDate: Date
  endDate: Date
  teamIds?: string[]
  userIds?: string[]
}

export const GiftEmailStatsCard: React.FC<GiftEmailStatsCardV2Props> = ({
  startDate,
  endDate,
  teamIds,
  userIds,
  ...rest
}) => {
  const query = useGraphqlQuery(UserAnalyticsV2Document, {
    config: {
      startDate,
      endDate,
      teamIds,
      userIds,
      granularity: Granularity.All,
      type: DataObjectType.User,
    },
  })

  const { deliveryEmailSent, deliveryEmailOpened, deliveryEmailClicked, deliveryEmailAccepted } = useMemo(() => {
    const data = query?.data?.userAnalyticsV2 || []
    return data.reduce((sum, user) => {
      sum.deliveryEmailSent = (sum.deliveryEmailSent || 0) + (user.deliveryEmailSent || 0)
      sum.deliveryEmailOpened = (sum.deliveryEmailOpened || 0) + (user.deliveryEmailOpened || 0)
      sum.deliveryEmailClicked = (sum.deliveryEmailClicked || 0) + (user.deliveryEmailClicked || 0)
      sum.deliveryEmailAccepted = (sum.deliveryEmailAccepted || 0) + (user.deliveryEmailAccepted || 0)
      return sum
    }, {})
  }, [query?.data?.userAnalyticsV2])

  const openRate = (Number(deliveryEmailOpened) / Number(deliveryEmailSent)) * 100 || 0
  const clickRate = (Number(deliveryEmailClicked) / Number(deliveryEmailSent)) * 100 || 0
  const acceptanceRate = (Number(deliveryEmailAccepted) / Number(deliveryEmailSent)) * 100 || 0

  const showNoDataOverlayV2 = useMemo(
    () => !query.isFetching && !deliveryEmailSent,
    [deliveryEmailSent, query.isFetching]
  )

  return (
    <ZCard
      isLoading={query.isLoading}
      isFetching={query.isFetching}
      variant="form"
      {...rest}
    >
      {showNoDataOverlayV2 && <NoDataOverlay />}
      <Flex
        justifyContent="space-between"
        alignItems="baseline"
      >
        <ZCardHeader
          display="flex"
          alignItems="center"
          p={4}
          pb={2}
          fontSize="lg"
        >
          Gift Emails
        </ZCardHeader>
        <UiStatGroup
          textAlign="center"
          p={4}
        >
          <UiStat>
            <ZHeading fontSize="lg">{deliveryEmailSent ?? 0} sent</ZHeading>
          </UiStat>
        </UiStatGroup>
      </Flex>
      <Flex
        gap={8}
        p={4}
        w="full"
      >
        <Stack
          spacing={4}
          w="full"
        >
          <UiStat
            display="block"
            w="full"
          >
            <Flex
              justify="space-between"
              align="flex-end"
            >
              <ZText fontSize="md">Open Rate {Math.round(openRate)}%</ZText>
              <Flex
                flexDir="column"
                align="flex-end"
              >
                <ZText fontSize="md">Avg {IndustryStandardV2.AverageOpenRate}%</ZText>
              </Flex>
            </Flex>
            <Progress
              value={openRate}
              h="38px"
              colorScheme="atomicBlue"
              borderRadius="10px"
              isIndeterminate={query.isLoading}
              isAnimated
              mt={2}
            />
            <UiStatHelpText
              fontWeight="normal"
              color="atomicGray.600"
              mt={2}
              ml={2}
            >
              <Flex justify="space-between">
                <Box>Total Opens</Box>
                <Box>{deliveryEmailOpened ?? 0}</Box>
              </Flex>
            </UiStatHelpText>
          </UiStat>
          <UiStat display="block">
            <Flex
              justify="space-between"
              align="flex-end"
            >
              <ZText fontSize="md">Click Rate {Math.round(clickRate)}%</ZText>
              <Flex
                flexDir="column"
                align="flex-end"
              >
                <ZText fontSize="md">Avg {IndustryStandardV2.AverageClickRate}%</ZText>
              </Flex>
            </Flex>
            <Progress
              value={clickRate}
              h="38px"
              colorScheme="atomicPurple"
              borderRadius="10px"
              isIndeterminate={query.isLoading}
              isAnimated
              mt={2}
            />
            <UiStatHelpText
              fontWeight="normal"
              color="atomicGray.600"
              mt={2}
              ml={2}
            >
              <Flex justify="space-between">
                <Box>Total Clicks</Box>
                <Box>{deliveryEmailClicked ?? 0}</Box>
              </Flex>
            </UiStatHelpText>
          </UiStat>
          <UiStat display="block">
            <Flex
              justify="space-between"
              align="flex-end"
            >
              <ZText fontSize="md">Acceptance Rate {Math.round(acceptanceRate)}%</ZText>
              <Flex
                flexDir="column"
                align="flex-end"
              >
                <ZText fontSize="md">Avg {IndustryStandardV2.AverageAcceptanceRate}%</ZText>
              </Flex>
            </Flex>
            <Progress
              value={acceptanceRate}
              h="38px"
              colorScheme="vendorGreen"
              borderRadius="10px"
              isIndeterminate={query.isLoading}
              isAnimated
              mt={2}
            />
            <UiStatHelpText
              fontWeight="normal"
              color="atomicGray.600"
              mt={2}
              ml={2}
            >
              <Flex justify="space-between">
                <Box>Total Acceptances</Box>
                <Box>{deliveryEmailAccepted ?? 0}</Box>
              </Flex>
            </UiStatHelpText>
          </UiStat>
        </Stack>
      </Flex>
    </ZCard>
  )
}
