import type { Column } from '@postal-io/postal-ui'
import { joinStrings, UiDate, UiLink, UiStopPropagation } from '@postal-io/postal-ui'
import type { SearchableContact } from 'api'
import { FulfillmentStatus } from 'api'
import { Link } from 'react-router-dom'

export const columns: Column[] = [
  {
    key: 'firstName',
    label: 'Name',
    render: ({ id, firstName, lastName }: SearchableContact) => (
      <UiStopPropagation>
        <UiLink
          as={Link}
          to={`/contacts/${id}`}
          data-private
        >
          {joinStrings([firstName, lastName])}
        </UiLink>
      </UiStopPropagation>
    ),
  },
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'created',
    label: 'Created',
    render: ({ created }: SearchableContact) => (
      <UiDate
        date={created}
        fallback=""
      />
    ),
  },
  {
    key: 'status',
    label: 'Status',
  },
]

export const SUCCESS_LIST = [
  FulfillmentStatus.Placed,
  FulfillmentStatus.Confirmed,
  FulfillmentStatus.ServerErrorBackoff,
  FulfillmentStatus.Shipped,
  FulfillmentStatus.PendingDelivery,
  FulfillmentStatus.OutForDelivery,
  FulfillmentStatus.Delivered,
  FulfillmentStatus.DeliveredAssumed,
  FulfillmentStatus.Processing,
  FulfillmentStatus.ProcessingInternal,
]

export const FAILED_LIST = [
  FulfillmentStatus.DeliveryError,
  FulfillmentStatus.Cancelled,
  FulfillmentStatus.ProcessingError,
  FulfillmentStatus.PostalDeliveryExpired,
  FulfillmentStatus.PostalDeliveryEmailError,
]
