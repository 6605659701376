import type { WrapProps } from '@chakra-ui/react'
import { Grid, Tag, TagLabel, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import { UiDateTime, ZFlowButton, ZHeading, ZText } from '@postal-io/postal-ui'
import { MultiSelectSelectedTable } from 'components/MultiSelectContacts/MultiSelectSelectedTable'
import React, { useMemo } from 'react'
import { MdAdd } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { SearchContactsV2Document } from '../../api'

const LIMIT = 20
const NUM_TAGS = 20

export interface OrderRecipientsBlockProps extends WrapProps {
  campaignId?: string
  scheduleDate?: Date
}

export const OrderRecipientsBlock: React.FC<OrderRecipientsBlockProps> = ({ campaignId, scheduleDate, ...rest }) => {
  const viewContacts = useDisclosure()
  const navigate = useNavigate()

  const filter = { campaigns: { eq: campaignId } }

  const searchContacts = useGraphqlInfiniteQuery(
    SearchContactsV2Document,
    { filter, limit: LIMIT },
    {
      enabled: !!campaignId,
    }
  )
  const contacts = useMemo(
    () => searchContacts.mergedData?.searchContactsV2?.searchableContacts ?? [],
    [searchContacts.mergedData?.searchContactsV2?.searchableContacts]
  )
  const totalCount = useMemo(
    () => searchContacts.mergedData?.searchContactsV2.resultsSummary.totalRecords ?? 0,
    [searchContacts.mergedData?.searchContactsV2.resultsSummary.totalRecords]
  )

  const handleClickRecipient = (contactId: string) => () =>
    navigate(`/contacts/${contactId}`, { state: { returnTo: 'Order', campaignId } })

  return (
    <>
      <ZHeading
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        size="h6"
        color="atomicGray.700"
        whiteSpace="nowrap"
      >
        {searchContacts.isLoading
          ? 'Recipients Loading...'
          : `${new Intl.NumberFormat('en-US').format(totalCount)} Recipients`}
        {scheduleDate && (
          <ZText
            color="atomicGray.500"
            fontWeight="bold"
            float="right"
          >
            Scheduled for <UiDateTime date={scheduleDate} />
          </ZText>
        )}
      </ZHeading>
      <Grid
        w="100%"
        mt={5}
        gap={3}
        templateColumns="repeat(5, 1fr)"
        {...rest}
      >
        {contacts.slice(0, NUM_TAGS).map((contact) => (
          <Tag
            bg="atomicGray.50"
            w="100%"
            justifyContent="center"
            borderRadius="100px"
            fontSize="xs"
            key={contact.id}
            _hover={{
              bg: 'atomicGray.100',
            }}
            cursor="pointer"
            onClick={handleClickRecipient(contact.id)}
          >
            <TagLabel>{`${contact?.firstName} ${contact?.lastName}`}</TagLabel>
          </Tag>
        ))}
      </Grid>
      {contacts.length < NUM_TAGS ? null : (
        <ZFlowButton
          mt={5}
          bg="atomicBlue.10"
          color="atomicBlue.900"
          _hover={{ bg: 'atomicBlue.50' }}
          _active={{ bg: 'atomicBlue.100' }}
          leftIcon={<MdAdd />}
          onClick={viewContacts.onToggle}
        >
          Show all
        </ZFlowButton>
      )}
      {viewContacts.isOpen && (
        <MultiSelectSelectedTable
          title="Group Order Contacts"
          {...viewContacts}
          orfilters={[{ campaigns: { eq: campaignId } }]}
        />
      )}
    </>
  )
}
