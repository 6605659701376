import { Box, Grid, Heading, HStack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiConfirm, UiIconPostalEdit2, UiSkeleton, useAlertError, useAlerts } from '@postal-io/postal-ui'
import type { ApprovedPostal, ApprovedProductVariant } from 'api'
import {
  CheckAutomationsTiedToPostalDocument,
  DeleteApprovedPostalAndAllAutomationsDocument,
  GetApprovedPostalDocument,
  Status,
} from 'api'
import { PostalSendSidebar } from 'components/PostalSend'
import { PostalSendMethod } from 'components/PostalSend/postalSendHelpers'
import { NavbarActionMenu, NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import {
  AnalyticsEventV2,
  PageTitle,
  POSTAL_INVALIDATIONS,
  useAnalyticsSend,
  useBackgroundQueue,
  useMe,
  useNavigateSendFlow,
  usePostalPermissions,
  useRouteBack,
} from 'hooks'
import { usePostalFavoriteStatusToggle } from 'hooks/useFavoriteStatusToggle'
import { useFavoriteItemId } from 'hooks/usePostalFavoriteStatus'
import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CenteredBox } from '../Common'
import { NavbarButtonV2 } from '../Common/NavbarLink'
import { ApprovedPostalGeneralView } from '../Postal/ApprovedPostalGeneralView'
import { AutomationInfo } from '../Postal/AutomationInfo'
import { PostalClone } from '../Postal/PostalClone'
import { CurrencyAlert } from '../Postal/ProductAlerts'

export const MarketplaceApprovedPostalPage: React.FC = () => {
  const { approvedPostalId } = useParams() as any
  const navigate = useNavigate()
  const Alert = useAlerts()
  const sendAnalytics = useAnalyticsSend()

  const deletePostal = useDisclosure()
  const clonePostal = useDisclosure()

  const approvedPostalQuery = useGraphqlQuery(
    GetApprovedPostalDocument,
    { id: approvedPostalId },
    { enabled: !!approvedPostalId }
  )

  const postal = useMemo(
    () => approvedPostalQuery.data?.getApprovedPostal,
    [approvedPostalQuery.data?.getApprovedPostal]
  )

  const [selectedVariant, setSelectedVariant] = useState<ApprovedProductVariant | undefined>(
    postal?.variants?.length ? (postal?.variants[0] as ApprovedProductVariant) : undefined
  )

  const { hasCurrency } = useMe()

  const [warningLevel, _] = useState<'info' | 'warning' | 'error'>('info')

  const { canDelete, canUpdate, canLink, canSend } = usePostalPermissions(postal)

  const favoriteItemId = useFavoriteItemId(postal)
  const { toggleFavorite, isFavorite } = usePostalFavoriteStatusToggle({
    favoriteItemId,
    approvedPostalId: postal?.id ?? '',
  })

  const automationsQuery = useGraphqlQuery(
    CheckAutomationsTiedToPostalDocument,
    { approvedPostalId: postal?.id as string },
    { enabled: !!postal?.id && canDelete }
  )

  const currentAutomations = useMemo(() => {
    return automationsQuery.data?.checkAutomationsTiedToPostal?.filter((item) => item.hasAutomations) ?? []
  }, [automationsQuery])

  const { invalidate, queue } = useBackgroundQueue()
  const deletePostalAndAutomations = useGraphqlMutation(DeleteApprovedPostalAndAllAutomationsDocument, {
    onSuccess: (data) => {
      invalidate(POSTAL_INVALIDATIONS)
      queue(data.deleteApprovedPostalAndAllAutomations)
    },
  })

  useAlertError(approvedPostalQuery.error)
  useAlertError(deletePostalAndAutomations.error)

  const handleDelete = async () => {
    if (!postal) return
    try {
      await deletePostalAndAutomations.mutateAsync({ approvedPostalId: postal.id })
      Alert.success(' Approved Item Deleted')
      navigate('/items/postals')
    } catch (e) {
      Alert.error(e)
    }
  }

  const location = useLocation()
  const sendFlowLink = useNavigateSendFlow()

  type ActionTypes = 'EDIT' | 'SEND' | 'SEND_DIRECT' | 'CREATE_LINK' | 'TOGGLE_FAVORITE_STATUS' | 'CLONE' | 'DELETE'
  const handleAction = (type: ActionTypes) => {
    switch (type) {
      case 'EDIT':
        canUpdate && navigate(`${location.pathname}/edit`)
        break
      case 'SEND':
        canSend && navigate(sendFlowLink(`${location.pathname}/send`, { returnTo: 'Marketplace' }))
        break
      case 'TOGGLE_FAVORITE_STATUS':
        toggleFavorite()
        break
      case 'CLONE':
        canUpdate && clonePostal.onOpen()
        break
      case 'DELETE':
        canDelete && deletePostal.onOpen()
        break

      default:
    }
  }

  const actions = [
    {
      title: 'Send Item',
      onClick: () => handleAction('SEND'),
      isHidden: !canSend,
    },
    {
      title: 'Clone Item',
      onClick: () => handleAction('CLONE'),
      isHidden: !canUpdate,
    },
    {
      title: 'Add to Favorites',
      onClick: () => handleAction('TOGGLE_FAVORITE_STATUS'),
      isHidden: isFavorite,
    },
    {
      title: 'Remove from Favorites',
      onClick: () => handleAction('TOGGLE_FAVORITE_STATUS'),
      isHidden: !isFavorite,
    },
    {
      title: 'Delete Item',
      onClick: () => handleAction('DELETE'),
      isHidden: !canDelete,
    },
  ]

  const activeVariants = useMemo(() => postal && postal.variants?.filter((v) => v.status === Status.Active), [postal])

  const disclosure = useDisclosure({ defaultIsOpen: true })

  const back = useRouteBack('My Items', '/items/postals')

  const onClone = (postal: ApprovedPostal) => {
    clonePostal.onClose()
    disclosure.onClose()
    navigate(`/items/postals/${postal.id}`)
  }

  // const handleWarning = () => {
  //   switch (warningLevel) {
  //     case 'info':
  //       setWarningLevel('warning')
  //       break
  //     case 'warning':
  //       setWarningLevel('error')
  //       break
  //   }
  // }

  const errorMessage = useMemo(
    () => {
      if (!canSend) return 'User Role required to send items'
      if (!activeVariants?.length) return 'Out of Stock'
      if (postal?.status === Status.Disabled) return 'Item is Draft'
      if (postal?.status === Status.VendorDisabled) return 'Vendor Disabled'
      if (postal?.status === Status.Pending) return 'Item Pending Approval'
      return undefined
    },
    // canUpdate
    //   ? 'This product is currently unavailable. It may become available in the future, so we recommend you keep it as approved. You can update its status to “draft” if you don’t want users to see it while it is unavailable.'
    //   : 'This item is currently unavailable.',
    [activeVariants?.length, canSend, postal?.status]
  )

  const handleNext = () => {
    navigate(
      sendFlowLink(`${location.pathname}/send`, {
        returnTo: 'Marketplace',
        returnPath: '/items/postals',
        sendMethod: canLink ? PostalSendMethod.Link : undefined,
        variantId: selectedVariant?.id,
      })
    )
    sendAnalytics({ event: AnalyticsEventV2.Product_SendThisItem_Clicked })
  }

  const hasAutomations = currentAutomations.length > 0

  const PostalDisplayV2: React.FC = () => (
    <>
      <SecondaryNavbar
        right={
          <HStack
            spacing={5}
            display={{ base: 'none', md: 'flex' }}
          >
            {canUpdate && (
              <NavbarButtonV2
                leftIcon={<UiIconPostalEdit2 />}
                onClick={() => handleAction('EDIT')}
              >
                Edit Item
              </NavbarButtonV2>
            )}
          </HStack>
        }
        farRight={<NavbarActionMenu actionItems={actions} />}
        left={
          <NavbarBackButton
            onClick={() => (back.path ? navigate(back.path) : navigate(-1))}
            label={back.title}
          />
        }
        header="Product Information"
      />
      <CenteredBox
        pt={5}
        isLoaded={!approvedPostalQuery.isLoading}
      >
        <Grid
          templateColumns={{ base: '1fr', lg: '1fr 400px' }}
          gap={{ base: 16 }}
        >
          <ApprovedPostalGeneralView postal={postal} />
          <PostalSendSidebar
            postal={postal}
            onNext={handleNext}
            sending={false}
            setVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
            nextStepButtonText="Send this Item"
            errorMessage={errorMessage}
          />
        </Grid>
      </CenteredBox>
    </>
  )

  return (
    <>
      <PageTitle
        title={postal?.name}
        section="Items"
      />
      {!!postal?.currency && !hasCurrency(postal.currency) && (
        <CurrencyAlert
          currency={postal.currency}
          status={warningLevel}
        />
      )}

      {postal && <PostalDisplayV2 />}

      {postal && canUpdate && clonePostal.isOpen && (
        <PostalClone
          postal={postal}
          onClose={clonePostal.onClose}
          isOpen={clonePostal.isOpen}
          onClone={onClone}
        />
      )}
      {canDelete && deletePostal.isOpen && (
        <UiConfirm
          status="warning"
          title={`Delete Item ${hasAutomations ? ' & Related Integrations' : ''}`}
          isOpen={deletePostal.isOpen}
          onConfirm={handleDelete}
          onClose={deletePostal.onClose}
          buttonColor="red"
          buttonText="Delete"
          isLoading={deletePostalAndAutomations.isLoading || automationsQuery.isFetching}
          size="2xl"
        >
          <UiSkeleton isLoaded={!automationsQuery.isFetching}>
            <Box minH="100px">
              {hasAutomations ? (
                <AutomationInfo automations={currentAutomations} />
              ) : (
                <Heading
                  as="h2"
                  fontSize="lg"
                  mt={4}
                  color="gray.600"
                >
                  Are you sure you want to <strong>Delete</strong> this Approved Item?
                </Heading>
              )}
            </Box>
          </UiSkeleton>
        </UiConfirm>
      )}
    </>
  )
}
