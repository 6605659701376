import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiLoadingModal } from '@postal-io/postal-ui'
import { CenteredBox } from 'components/Common'
import { PostalSelectItemContent } from 'components/PostalSend/PostalSelectItem'
import { NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { PageTitle, usePostalPermissions } from 'hooks'
import React, { useEffect, useState } from 'react'
import type { ApprovedPostal, ApprovedProductVariant, IntegrationTrigger } from '../../api'
import { GetApprovedPostalDocument } from '../../api'
import { PostalSendType, PostalSend } from '../PostalSend'

interface TriggerEditPostalSendPropsV2 {
  onComplete: (context?: any) => void
  onNavigateBack: () => void
  trigger?: IntegrationTrigger
  variant?: ApprovedProductVariant
  approvedPostalId?: any
}
export const TriggerEditPostalSend: React.FC<TriggerEditPostalSendPropsV2> = ({
  onComplete,
  onNavigateBack,
  trigger,
  variant,
  approvedPostalId,
}) => {
  const [selectedPostal, setSelectedPostal] = useState<ApprovedPostal | undefined>()
  const { data: loadedPostal, isLoading } = useGraphqlQuery(
    GetApprovedPostalDocument,
    { id: approvedPostalId as string },
    { enabled: !!approvedPostalId }
  )

  useEffect(() => {
    if (approvedPostalId && loadedPostal?.getApprovedPostal) setSelectedPostal(loadedPostal?.getApprovedPostal)
  }, [approvedPostalId, loadedPostal, setSelectedPostal])

  const { defaultNonLinkSendMethod } = usePostalPermissions(selectedPostal)

  if (isLoading) return <UiLoadingModal isOpen={isLoading} />

  return !selectedPostal ? (
    <>
      <PageTitle title="Choose Item" />
      <SecondaryNavbar
        maxWidth="1280px"
        px={8}
        left={
          <NavbarBackButton
            onClick={onNavigateBack}
            label="Back to Edit Trigger"
          />
        }
        header="Choose Item"
      />
      <CenteredBox
        isLoaded
        maxW="1280px"
        px={8}
        pt={8}
      >
        <PostalSelectItemContent onSelect={(postal) => setSelectedPostal(postal)} />
      </CenteredBox>
    </>
  ) : (
    <PostalSend
      method={defaultNonLinkSendMethod}
      onNavigateBack={() => (approvedPostalId ? onNavigateBack() : setSelectedPostal(undefined))}
      onComplete={onComplete}
      navigateBackLabel="Back to Choose Item"
      type={PostalSendType.Trigger}
      postal={selectedPostal}
      variant={variant}
      giftMessage={trigger?.giftMessage}
      // gift email message
      deliveryEmail={trigger?.deliveryEmail}
      emailSubjectLine={trigger?.emailSubjectLine}
      landingPageHeaderText={trigger?.landingPageCustomization?.headerText}
      landingPageBody={trigger?.landingPageCustomization?.body}
      landingPageIncludeHeadshot={!!trigger?.landingPageCustomization?.includeHeadshot}
      landingPageIncludeSenderName={!!trigger?.landingPageCustomization?.includeSenderName}
      formFieldList={trigger?.formFieldList}
      // physical message
      usePhysicalMessage={!!trigger?.physicalMessage}
      physicalMessage={trigger?.physicalMessage}
      sendAsContactOwner={trigger?.sendAsContactOwner}
      sendAsUser={trigger?.sendAsUser}
      spendAsTeamId={trigger?.spendAs?.teamId as string}
      spendAsUserId={trigger?.spendAs?.userId}
      meetingRequestSetting={trigger?.meetingRequestSetting}
      itemCustomizationInputs={trigger?.itemCustomizationInputs}
    />
  )
}
