import { Box } from '@chakra-ui/react'
import { ZLink, ZText } from '@postal-io/postal-ui'
import { Fragment, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

const SPLIT_REGEXP = /({\s?[_a-zA-Z]+\s?})/
const KEY_REGEXP = /{\s?([_a-zA-Z]+)\s?}/

interface GetUrlProps {
  dataId: string
  dataType: string
}

const getUrl = ({ dataId, dataType }: GetUrlProps) => {
  if (!dataId || !dataType) {
    return ''
  }

  switch (dataType) {
    case 'CAMPAIGN':
      return `/orders/group/${dataId}`
    case 'PRODUCT':
      return `/items/marketplace/${dataId}`
    case 'CONTACT':
      return `/contacts/${dataId}`
    case 'USER':
      return `/users/${dataId}`
    case 'POSTAL':
      // return `/items/postals/view/${dataId}`
      return `/redirect/items/${dataId}`
    case 'FULFILLMENT':
      return `/reporting/orders/${dataId}`
    case 'EVENT':
      return `/events/postals/${dataId}`
    case 'COLLECTION':
      return `/items/collections/${dataId}`
    case 'MAGIC_LINK':
      return `/links/${dataId}`
    case 'BILLING_ACCOUNT':
      return `/billing/accounts/${dataId}`
    default:
      return ''
  }
}

export interface ActivityStreamItemV2Props {
  message: string
  count?: number | null
  callouts?: any[] | null
  // for links that don't use URLs, but need an action instead
  onClick: (callouts: any[], type: string, action?: string) => void
}

export const ActivityStreamItem = ({ message, count, callouts, onClick }: ActivityStreamItemV2Props) => {
  const calloutMap = useMemo(() => {
    return callouts?.reduce((obj, item) => {
      obj[item.targetText] = item
      return obj
    }, {})
  }, [callouts])

  // this will be used for handling actions instead of URL links
  const handleClick = (e: any, key: any) => {
    if (key === 'retryPostalFulfillmentId') {
      e.preventDefault()
      onClick(callouts || [], 'ACTION', 'postalFulfillment.retryOrder')
    }
  }

  const parseElement = useCallback(
    (str: string) => {
      const matches = str.match(KEY_REGEXP)
      if (!matches) return str

      const key = matches[1]
      const callout = calloutMap[key]
      if (!callout) return str

      const { dataId, dataType, text } = callout

      const url = getUrl({ dataId, dataType })
      const to = { pathname: url, state: { returnTo: 'Activity Stream' } }

      return url ? (
        <ZLink
          fontSize="inherit"
          as={Link}
          onClick={(e: any) => handleClick(e, key)}
          to={to}
          display="inline"
        >
          {text}
        </ZLink>
      ) : (
        <ZText
          fontSize="inherit"
          fontWeight="bold"
          display="inline"
        >
          {text}
        </ZText>
      )
    },
    // eslint-disable-next-line
    [calloutMap]
  )

  return (
    <Box
      data-testid="ActivityStreamItem_item"
      fontSize="sm"
      display="block"
      gap={1}
      w="100%"
    >
      {message.split(SPLIT_REGEXP).map((element, idx) => {
        return <Fragment key={idx}>{parseElement(element)}</Fragment>
      })}
      {count && count > 1 && ` ${count - 1} similar messages found.`}
    </Box>
  )
}
