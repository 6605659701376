import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlertError, useAlerts } from '@postal-io/postal-ui'
import { useMemo, useState } from 'react'
import { MeDocument } from '../api'
import api from '../api/rest'
import type { ExternalProvider } from '../components/Integrations'
import { ExternalProviders } from '../components/Integrations'
import { useAcl } from './useAcl'

export const useEmailIntegrations = () => {
  const Alert = useAlerts()
  const { aclCheck } = useAcl()

  // query me
  const { data: meData, error: meError } = useGraphqlQuery(MeDocument)
  const user = meData?.me

  // available email providers
  const availableEmailProviders = useMemo(() => {
    if (!user) return []
    return ExternalProviders.filter((p) => p.email?.enabled && aclCheck(p.email))
  }, [aclCheck, user])

  // has at least one sso integration available
  const hasSsoIntegration = useMemo(
    () => availableEmailProviders.some((e) => e.sso?.authType && user?.authTypes?.includes(e.sso?.authType)),
    [availableEmailProviders, user?.authTypes]
  )

  // has at least one email integration available
  const hasEmailIntegration = useMemo(
    () => availableEmailProviders.some((e) => e.email?.authType && user?.authTypes?.includes(e.email.authType)),
    [availableEmailProviders, user?.authTypes]
  )

  // is integration enabled
  const isEnabled = (provider: ExternalProvider) => {
    if (!provider.email?.authType) return false
    return user?.authTypes?.includes(provider.email.authType)
  }

  // enable integration
  const [preconnectLoading, setPreconnectLoading] = useState(false)
  const enableIntegration = async (provider?: ExternalProvider) => {
    if (!provider?.email?.endpoint) return
    try {
      setPreconnectLoading(true)
      await api.oathPreconnect(provider.email.endpoint).then((res: any) => {
        setPreconnectLoading(false)
        if (res?.target) {
          window.location.href = res?.target
        } else {
          window.location.reload()
        }
      })
    } catch (e) {
      setPreconnectLoading(false)
      Alert.error(e)
    }
  }

  useAlertError(meError)

  return {
    availableEmailProviders,
    isEnabled,
    enableIntegration,
    hasEmailIntegration,
    hasSsoIntegration,
    isLoading: preconnectLoading,
  }
}
