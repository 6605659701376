import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import React, { useRef } from 'react'

interface PostalUnavailableV2Props {
  isOpen: boolean
  onClose: () => void
}
export const PostalUnavailable: React.FC<PostalUnavailableV2Props> = (props) => {
  const cancelRef = useRef(null)
  return (
    <AlertDialog
      size="lg"
      leastDestructiveRef={cancelRef}
      isCentered
      {...props}
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Item Unavailable</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          The Item that you are attempting to send is out of stock or has been removed by our Marketplace team, please
          select a different Item.
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            minW={32}
            onClick={props.onClose}
          >
            Ok
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
