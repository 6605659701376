import type { FlexProps } from '@chakra-ui/react'
import { Fade, Flex, Grid, VStack } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiTruncate,
  useColor,
  ZButton,
  ZHeading,
  ZHoverCard,
  ZHoverCardClosed,
  ZHoverCardOpen,
  ZMoney,
  ZText,
} from '@postal-io/postal-ui'
import type { PlaybookDefinition } from 'api'
import { GetApprovedPostalDocument } from 'api'
import React, { useMemo } from 'react'
import { MdMenuBook } from 'react-icons/md'

interface PlaybookDefinitionCardV2Props extends FlexProps {
  definition: PlaybookDefinition
  isSelected?: boolean
  onSelect: any
  buttonText?: string
  isFetching?: boolean
}
export const PlaybookDefinitionCard: React.FC<PlaybookDefinitionCardV2Props> = ({
  definition,
  isSelected,
  onSelect,
  buttonText,
  isFetching,
  ...rest
}) => {
  return (
    <Fade in>
      <ZHoverCard
        isLoading={isFetching}
        onClick={onSelect}
        cursor="pointer"
        {...rest}
      >
        <ZHoverCardClosed>
          <DefinitionInfo
            definition={definition}
            onSelect={onSelect}
          />
        </ZHoverCardClosed>
        <ZHoverCardOpen>
          <Hoverview
            playbook={definition}
            onSelect={onSelect}
            isSelected={isSelected}
            buttonText={buttonText || 'Open Subscription'}
          />
        </ZHoverCardOpen>
      </ZHoverCard>
    </Fade>
  )
}

interface HoverviewProps {
  playbook: PlaybookDefinition
  onSelect: () => void
  buttonText: string
  isSelected?: boolean
}

export const Hoverview: React.FC<HoverviewProps> = ({ playbook, onSelect, isSelected, buttonText }) => {
  return (
    <Flex
      w="100%"
      h="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
    >
      <ZText
        w="100%"
        size="lg"
        variant="bold"
        textAlign="center"
        data-testid="subscription-name"
      >
        <UiTruncate
          text={playbook.name as string}
          length={30}
        />
      </ZText>

      <ZButton
        mt={5}
        minW="50%"
        colorScheme="atomicBlue"
        onClick={onSelect}
        isDisabled={isSelected}
      >
        {isSelected ? 'Selected' : buttonText}
      </ZButton>
    </Flex>
  )
}

interface DefinitionInfoProps extends FlexProps {
  definition: any
  isOpen?: boolean
  onSelect?: (e: any) => void
}

export const DefinitionInfo: React.FC<DefinitionInfoProps> = ({ definition, isOpen, onSelect, ...rest }) => {
  const normalizePlaybook = useMemo(() => {
    return {
      id: definition?.id,
      name: definition?.name,
      steps: definition?.steps.length,
      cost: definition?.steps.reduce((a: number, b: any) => (a += b?.displayPrice), 0),
      duration: definition.steps && Math.max(...definition?.steps.map((item: any) => item.delay)),
    }
  }, [definition?.id, definition?.name, definition?.steps])

  return (
    <Flex
      onClick={onSelect}
      direction="column"
      alignItems="center"
      w="100%"
      h="100%"
      p={2}
      gap={6}
      {...rest}
    >
      <ZHeading
        size="h6"
        variant="bold"
        bg="white"
        pt={4}
        pb={0}
      >
        <UiTruncate
          text={normalizePlaybook.name}
          length={30}
        />
      </ZHeading>

      <VStack
        w="100%"
        bg="white"
      >
        <Grid
          templateColumns={{ base: '1fr 1fr 1fr' }}
          justifyItems="center"
          w="100%"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <ZText color="atomicGray.500">Items</ZText>
          <ZText color="atomicGray.500">Cost</ZText>
          <ZText
            color="atomicGray.500"
            textAlign="center"
          >
            Duration
            <br />
            (Days)
          </ZText>
        </Grid>
        <Grid
          w="100%"
          templateColumns={{ base: '1fr 1fr 1fr' }}
          justifyItems="center"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <ZHeading
            size="h4"
            data-testid="subscription-items"
          >
            {normalizePlaybook.steps}
          </ZHeading>
          <ZHeading
            size="h4"
            data-testid="subscription-cost"
          >
            <ZMoney
              fontSize="inherit"
              cents={normalizePlaybook?.cost}
            />
          </ZHeading>
          <ZHeading
            size="h4"
            data-testid="subscription-duration"
          >
            {normalizePlaybook?.duration}
          </ZHeading>
        </Grid>
      </VStack>
      <Flex
        direction="column"
        justify="flex-start"
        backgroundColor="atomicGray.10"
        w="100%"
        h="100%"
        borderBottomLeftRadius="10px"
        borderBottomRightRadius="10px"
        p={4}
        gap={1}
      >
        {definition?.steps.slice(0, 3).map((item: any, idx: number) => (
          <PlaybookDefCardDetail
            key={idx}
            item={item}
            stepNumber={idx + 1}
          />
        ))}
        {definition.steps.length > 3 && (
          <ZText
            size="lg"
            variant="bold"
            color="atomicBlue.500"
          >
            + {`${definition.steps.length - 3} more step${definition.steps.length > 1 ? 's' : ''}`}
          </ZText>
        )}
      </Flex>
    </Flex>
  )
}

export const PlaybookDefCardDetail = ({ item, stepNumber }: any) => {
  const query = useGraphqlQuery(GetApprovedPostalDocument, { id: item.approvedPostalId }, { useErrorBoundary: false })
  const name = query.data?.getApprovedPostal?.name || 'N/A'

  return (
    <Flex gap={2}>
      <ZText
        size="lg"
        color="atomicGray.500"
        whiteSpace="nowrap"
      >
        Step {stepNumber} -
      </ZText>
      <ZText
        size="lg"
        color="atomicGray.700"
        isTruncated
      >
        {name}
      </ZText>
    </Flex>
  )
}

interface PlaybookDefinitionCardCreateV2Props extends FlexProps {
  onCreate: () => void
}
export const PlaybookDefinitionCardCreateV2: React.FC<PlaybookDefinitionCardCreateV2Props> = ({
  onCreate,
  ...rest
}) => {
  const { colorCode } = useColor()
  return (
    <ZHoverCard {...rest}>
      <Flex
        w="100%"
        h="100%"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <MdMenuBook
          size="100px"
          color={colorCode('atomicGray.100')}
        />
        <ZHeading
          size="h6"
          maxW="200px"
          lineHeight="normal"
          textAlign="center"
        >
          Create a subscription from scratch
        </ZHeading>

        <ZButton
          mt={5}
          colorScheme="atomicBlue"
          onClick={onCreate}
        >
          Start Here
        </ZButton>
      </Flex>
    </ZHoverCard>
  )
}
