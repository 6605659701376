import type { ModalProps } from '@chakra-ui/react'
import { Grid } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  FontWeight,
  UiButton,
  UiFormControl,
  UiFormLabel,
  UiInput,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalFooter,
  UiModalFooterButtons,
  UiModalHeader,
  UiModalOverlay,
  UiTextarea,
  useAlertError,
  useAlerts,
} from '@postal-io/postal-ui'
import { FormType, SubmitFormDocument } from 'api'
import React from 'react'

export interface MarketplaceProductRequestProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
}

export const MarketplaceProductRequest: React.FC<MarketplaceProductRequestProps> = ({ isOpen, onClose, ...rest }) => {
  const Alert = useAlerts()

  const submitFormMutation = useGraphqlMutation(SubmitFormDocument)
  useAlertError(submitFormMutation.error)

  const handleSubmit = async (e: React.FormEvent<any>) => {
    e.preventDefault()
    const target = e.currentTarget
    const productName = target.productName.value
    const productURL = target.productURL.value
    const message = target.message.value
    try {
      await submitFormMutation.mutateAsync({
        type: FormType.ProductRequest,
        variables: { productName, productURL, message },
      })
      onClose()
      Alert.success('Product request sent')
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <UiModal
      size="4xl"
      isOpen={isOpen}
      onClose={onClose}
      {...rest}
    >
      <UiModalOverlay />
      <UiModalContent>
        <UiModalCloseButton
          fontSize="2xl"
          top={5}
          right={4}
        />
        <UiModalHeader p={6}>Recommend a Product</UiModalHeader>
        <UiModalBody
          p={8}
          display="flex"
          justifyContent="center"
        >
          <form
            id="MarketplaceProductRequest_form"
            onSubmit={handleSubmit}
          >
            <Grid
              columnGap={4}
              rowGap={8}
              w="650px"
              templateColumns="1fr 1fr"
            >
              <UiFormControl
                id="productName"
                gridColumn="1 / 2"
                isRequired
              >
                <UiFormLabel fontWeight={FontWeight.Bold}>Product Name</UiFormLabel>
                <UiInput name="productName" />
              </UiFormControl>
              <UiFormControl
                id="productURL"
                gridColumn="2 / 3"
                isRequired
              >
                <UiFormLabel fontWeight={FontWeight.Bold}>Product URL</UiFormLabel>
                <UiInput
                  type="url"
                  name="productURL"
                />
              </UiFormControl>
              <UiFormControl
                id="message"
                gridColumn="1 / 3"
              >
                <UiFormLabel fontWeight={FontWeight.Bold}>Your Message</UiFormLabel>
                <UiTextarea name="message" />
              </UiFormControl>
            </Grid>
          </form>
        </UiModalBody>
        <UiModalFooter>
          <UiModalFooterButtons>
            <UiButton
              size="lg"
              w="300px"
              colorScheme="tertiary"
              type="submit"
              form="MarketplaceProductRequest_form"
            >
              Send Request
            </UiButton>
          </UiModalFooterButtons>
        </UiModalFooter>
      </UiModalContent>
    </UiModal>
  )
}
