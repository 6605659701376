import { ChevronLeftIcon, CloseIcon, QuestionOutlineIcon } from '@chakra-ui/icons'
import type { ChakraProps, FormControlProps } from '@chakra-ui/react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FormHelperText,
  Grid,
  List,
  ListItem,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { createId } from '@paralleldrive/cuid2'
import {
  humanize,
  UiButton,
  UiCard,
  UiFieldType,
  UiFormControl,
  UiFormField,
  UiFormLabel,
  UiIconButton,
  UiIconCheckboxChecked,
  UiIconDate,
  UiIconDelete2,
  UiIconDropdown,
  UiIconPlusSquare,
  UiIconPostalEdit,
  UiIconShortText,
  UiIconToggle,
  UiInput,
  UiMenu,
  UiMenuButton,
  UiMenuItem,
  UiMenuList,
  UiSortableDragHandle,
  UiSortableDragHandleIcon,
  UiSortableItem,
  UiSortableList,
  UiSwitch,
  useImmerWithMemory,
} from '@postal-io/postal-ui'
import type { FormFieldInput } from 'api'
import { FieldType } from 'api'
import { AutoCompleteMultiSelectCreatable } from 'components/AutoComplete/AutoCompleteSelect'
import { ConfirmClose } from 'components/Common/ConfirmClose'
import { addDays, subDays } from 'date-fns'
import React, { useRef } from 'react'
import { useImmer } from 'use-immer'

const DEFAULT_STATE = {
  name: '',
  type: null,
  isRequired: false,
  min: undefined,
  max: undefined,
  options: [],
}

export const DEFAULT_FORM_FIELDS_V2 = [
  {
    id: createId(),
    name: 'First Name',
    isRequired: true,
    min: 1,
    max: 99,
    options: [],
    type: FieldType.Text,
  },
  {
    id: createId(),
    name: 'Last Name',
    isRequired: true,
    min: 1,
    max: 99,
    options: [],
    type: FieldType.Text,
  },
  {
    id: createId(),
    name: 'Email Address',
    isRequired: true,
    min: 1,
    max: 99,
    options: [],
    type: FieldType.Text,
  },
  {
    id: createId(),
    name: 'Title',
    isRequired: false,
    min: 1,
    max: 99,
    options: [],
    type: FieldType.Text,
  },
  {
    id: createId(),
    name: 'Company',
    isRequired: false,
    min: 1,
    max: 99,
    options: [],
    type: FieldType.Text,
  },
]

interface PostalEventCustomFieldsV2Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onConfirm: (e: FormFieldInput[]) => void
  customFields?: FormFieldInput[] | null
}

const getFieldName = (fieldType: FieldType | 'PARAGRAPH') => {
  switch (fieldType) {
    case FieldType.Datetime:
      return 'Date'
    case FieldType.Multiselect:
      return 'Checkboxes'
    case FieldType.Select:
      return 'Dropdown'
    case FieldType.Boolean:
      return 'Yes/No'
    case FieldType.Number:
      return 'Number'
    case FieldType.Text:
      return 'Short Answer'
    default:
      return humanize(fieldType)
  }
}

const FieldIcon: React.FC<ChakraProps & { fieldType: FieldType }> = ({ fieldType, ...props }) => {
  switch (fieldType) {
    case FieldType.Text:
      return <UiIconShortText {...props} />
    case FieldType.Boolean:
      return <UiIconToggle {...props} />
    case FieldType.Datetime:
      return <UiIconDate {...props} />
    case FieldType.Multiselect:
      return <UiIconCheckboxChecked {...props} />
    case FieldType.Select:
      return <UiIconDropdown {...props} />
    default:
      return <QuestionOutlineIcon {...props} />
  }
}

const fieldTypes: FieldType[] = [
  FieldType.Text,
  FieldType.Multiselect,
  FieldType.Boolean,
  FieldType.Select,
  FieldType.Datetime,
]

interface FormState extends FormFieldInput {
  id: string
}
export const PostalEventCustomFields: React.FC<PostalEventCustomFieldsV2Props> = ({
  isOpen,
  onClose,
  onConfirm,
  customFields,
}) => {
  const cancelRef = useRef(null)

  const {
    state: formFieldList,
    setState: setFormFieldlist,
    isChanged,
  } = useImmerWithMemory<FormState[]>(
    // this boolean check is because we've had instances where customFields contained a null in the array
    (customFields?.filter(Boolean).length ? customFields.filter(Boolean) : DEFAULT_FORM_FIELDS_V2).map((f) => ({
      ...f,
      id: createId(),
    }))
  )
  const [formField, setFormField] = useImmer<FormState | null>(null)
  const [multipleChoiceOption, setMultipleChoiceOption] = useImmer<string>('')
  const [multipleChoiceOptionError, setMultipleChoiceOptionError] = useImmer<string>('')
  const confirmationDisclosure = useDisclosure()

  const handleEdit = (id: string) => {
    const field = formFieldList.find((f) => f.id === id)
    setFormField(() => field)
  }

  const handleDelete = (id: string) => {
    const filteredList = formFieldList.filter((i) => i.id !== id)
    setFormFieldlist(filteredList)
  }

  const handleCloseAttempt = () => {
    if (isChanged) confirmationDisclosure.onOpen()
    else onClose()
  }

  // form field handlers

  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = target
    if (name === 'isRequired') {
      setFormField((draft: any) => void (draft.isRequired = checked))
    } else {
      setFormField((draft: any) => void (draft[name] = value))
    }
  }

  // const handleInputNumber = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, valueAsNumber } = target
  //   setFormField((draft: any) => void (draft[name] = valueAsNumber ?? undefined))
  // }

  const handleAddField = (e: React.FormEvent) => {
    e.preventDefault()
    if (!formField) return
    setFormFieldlist((draft) => {
      const existing = draft.find((f) => f.id === formField.id)
      return existing ? draft.map((f) => (f.id === formField.id ? formField : f)) : [...formFieldList, formField]
    })
    setFormField(null)
  }

  const handleMultiList = (val: any) => {
    setFormField((draft: any) => void (draft.options = val))
  }

  const handleAddMultiOption = () => {
    if (multipleChoiceOption.length) {
      setFormField((draft: any) => void draft.options.push(multipleChoiceOption))
      setMultipleChoiceOptionError('')
      setMultipleChoiceOption('')
    } else {
      setMultipleChoiceOptionError('Cannot add blank option.')
    }
  }

  const handleAddMultiOptionInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMultipleChoiceOption(e.currentTarget.value)
  }

  const handleAddMultiOptionKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAddMultiOption()
    }
  }

  const handleRemoveMultiOption = (option: string, i: number) => () => {
    setFormField(
      (draft: any) => void (draft.options = draft.options.filter((opt: string, index: number) => index !== i))
    )
  }

  const handleConfirm = () => {
    onConfirm(
      formFieldList.map((f) => {
        const { id, ...rest } = f
        return rest
      })
    )
  }

  const onClickSelectType = (type: FieldType) => () => {
    setFormField({
      id: createId(),
      ...DEFAULT_STATE,
      ...formField,
      max: type === FieldType.Text ? 99 : undefined,
      type,
    })
  }

  const onClickBack = () => setFormField(null)

  const onSortEnd = (newIndex: number, oldIndex: number) => {
    const field = formFieldList[oldIndex]
    const modifiedFieldList = formFieldList.filter((item, index) => index !== oldIndex)
    modifiedFieldList.splice(newIndex, 0, field)
    setFormFieldlist(modifiedFieldList)
  }

  return (
    <>
      <AlertDialog
        size="6xl"
        isOpen={isOpen}
        onClose={handleCloseAttempt}
        scrollBehavior="outside"
        leastDestructiveRef={cancelRef}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Customize Form Settings</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody overflowY="scroll">
            <Grid
              templateColumns="1fr 2fr"
              minH="300px"
              gap={4}
            >
              <Box background="atomicGray.50">
                {!formField ? (
                  <Grid
                    templateColumns="1fr 1fr"
                    autoRows="min-content 1fr 1fr 1fr"
                    gap={4}
                    p={4}
                  >
                    <Box gridColumn="span 2">
                      <Text fontSize="xl">Add a field:</Text>
                    </Box>
                    {fieldTypes.map((ft) => (
                      <SelectField
                        key={`create-field-select-${ft}`}
                        type={ft}
                        onClick={onClickSelectType(ft)}
                      />
                    ))}
                  </Grid>
                ) : formField?.type ? (
                  <form
                    onSubmit={handleAddField}
                    id="newFormField"
                  >
                    <Grid
                      templateColumns="1fr 1fr"
                      templateRows="auto 1fr auto"
                      height="100%"
                    >
                      <Box
                        gridColumn="span 2"
                        p={4}
                        borderBottom="1px solid"
                        borderBottomColor="atomicGray.300"
                      >
                        <UiMenu matchWidth>
                          <UiMenuButton
                            w="100%"
                            leftIcon={<FieldIcon fieldType={formField?.type} />}
                            textAlign="left"
                          >
                            {getFieldName(formField?.type)}
                          </UiMenuButton>
                          <UiMenuList>
                            {fieldTypes.map((ft) => (
                              <UiMenuItem
                                key={`edit-field-select-${ft}`}
                                icon={
                                  <FieldIcon
                                    fieldType={ft}
                                    textAlign="left"
                                  />
                                }
                                onClick={onClickSelectType(ft)}
                              >
                                {getFieldName(ft)}
                              </UiMenuItem>
                            ))}
                          </UiMenuList>
                        </UiMenu>
                      </Box>
                      <Box
                        gridColumn="span 2"
                        p={4}
                        display="grid"
                        gridTemplateColumns="1fr 1fr"
                        gridAutoRows="min-content"
                        gridGap={4}
                        height="100%"
                      >
                        <UiFormControl
                          id="name"
                          isRequired
                          gridColumn="span 2"
                        >
                          <UiInput
                            name="name"
                            value={formField.name || ''}
                            onChange={handleInput}
                            placeholder="Question"
                            autoFocus
                          />
                        </UiFormControl>

                        {formField.type === FieldType.Datetime && (
                          <>
                            <UiFormControl
                              id="min"
                              gridColumn="span 2"
                            >
                              <UiFormLabel fontSize="sm">Min Date</UiFormLabel>
                              <UiInput
                                type="date"
                                name="min"
                                value={formField.min ?? ''}
                                onChange={handleInput}
                              />
                            </UiFormControl>
                            <UiFormControl
                              id="min"
                              gridColumn="span 2"
                            >
                              <UiFormLabel fontSize="sm">Max Date</UiFormLabel>
                              <UiInput
                                min={0}
                                type="date"
                                name="max"
                                value={formField.max ?? ''}
                                onChange={handleInput}
                              />
                            </UiFormControl>
                          </>
                        )}

                        {formField.type === FieldType.Select && (
                          <UiFormControl
                            id="options"
                            isRequired
                            gridColumn="span 2"
                          >
                            <UiFormLabel fontSize="med">Answers</UiFormLabel>
                            <AutoCompleteMultiSelectCreatable
                              options={formField.options as any[]}
                              name="options"
                              placeholder="Add new answer..."
                              value={formField.options}
                              onChange={(val) => handleMultiList(val)}
                              isRequired
                            />
                          </UiFormControl>
                        )}

                        {formField.type === FieldType.Multiselect && (
                          <UiFormControl
                            id="options"
                            gridColumn="span 2"
                          >
                            <UiFormLabel fontSize="med">Answers</UiFormLabel>
                            <List mb={2}>
                              {formField.options?.map((opt: string, i: number) => (
                                <ListItem
                                  key={`${i}-${opt}-${createId()}`}
                                  _hover={{ background: 'atomicGray.100' }}
                                  p={1}
                                >
                                  <Flex alignItems="center">
                                    <Checkbox />
                                    <Text
                                      fontSize="sm"
                                      ml={2}
                                      flex={1}
                                    >
                                      {opt}
                                    </Text>
                                    <UiIconButton
                                      icon={<CloseIcon />}
                                      aria-label="Remove option"
                                      title="Remove option"
                                      variant="link"
                                      color="atomicGray.400"
                                      boxSize={5}
                                      alignSelf="self-end"
                                      _hover={{ color: 'atomicGray.600' }}
                                      onClick={handleRemoveMultiOption(opt, i)}
                                    />
                                  </Flex>
                                </ListItem>
                              ))}
                            </List>
                            <Flex
                              gridColumn="span 2"
                              flexDir="column"
                            >
                              <UiInput
                                type={UiFieldType.Text}
                                size="xs"
                                p={2}
                                placeholder="Option"
                                onChange={handleAddMultiOptionInput}
                                value={multipleChoiceOption}
                                onKeyDown={handleAddMultiOptionKeyDown}
                              />
                              <Flex
                                justifyContent="space-between"
                                alignItems="baseline"
                                pr={4}
                              >
                                <UiButton
                                  leftIcon={<UiIconPlusSquare boxSize="16px" />}
                                  colorScheme="tertiary"
                                  size="xs"
                                  variant="ghost"
                                  p={2}
                                  onClick={handleAddMultiOption}
                                  alignSelf="self-start"
                                >
                                  Add Option
                                </UiButton>
                                <Flex
                                  flex={1}
                                  flexDir="column"
                                  alignItems="flex-end"
                                >
                                  {!formField?.options?.length && (
                                    <FormHelperText>At least one option required.</FormHelperText>
                                  )}
                                  {multipleChoiceOptionError && (
                                    <FormHelperText color="red.400">{multipleChoiceOptionError}</FormHelperText>
                                  )}
                                </Flex>
                              </Flex>
                            </Flex>
                          </UiFormControl>
                        )}

                        <UiFormControl
                          id="isRequired"
                          gridColumn="span 2"
                          display="flex"
                          alignItems="center"
                          pt={4}
                        >
                          <UiSwitch
                            name="isRequired"
                            size="lg"
                            isChecked={!!formField.isRequired}
                            onChange={handleInput}
                          />
                          <UiFormLabel
                            fontSize="sm"
                            ml={4}
                          >
                            Require This Field
                          </UiFormLabel>
                        </UiFormControl>
                      </Box>
                      <Box
                        alignSelf="self-end"
                        gridColumn="span 2"
                        p={4}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <UiButton
                          flex={1}
                          mr={2}
                          colorScheme="gray"
                          leftIcon={<ChevronLeftIcon />}
                          onClick={onClickBack}
                        >
                          Back
                        </UiButton>
                        <UiButton
                          flex={1}
                          ml={2}
                          type="submit"
                          isDisabled={
                            !formField?.name ||
                            (formField?.type === FieldType.Multiselect && !formField?.options?.length)
                          }
                        >
                          Save Field
                        </UiButton>
                      </Box>
                    </Grid>
                  </form>
                ) : null}
              </Box>
              <UiSortableList
                items={formFieldList}
                onSortEnd={onSortEnd}
                restrict="y"
              >
                <Stack
                  spacing={6}
                  p={8}
                  pl={4}
                  alignSelf="self-start"
                  zIndex={9999}
                >
                  {!!formFieldList.length ? (
                    formFieldList.map((item) => {
                      const locked = ['First Name', 'Last Name', 'Email Address'].includes(item.name)

                      if (locked)
                        return (
                          <InputFieldSwitch
                            key={`input-field-switch-${item.id}`}
                            item={item}
                            onDelete={handleDelete}
                            onEdit={handleEdit}
                            twoColumn={['First Name', 'Last Name'].includes(item.name)}
                            fieldLocked={locked}
                          />
                        )
                      return (
                        <UiSortableItem
                          id={item.id}
                          key={item.id}
                          isDisabled={locked}
                          bg="white"
                          useDragHandle
                        >
                          <InputFieldSwitch
                            item={item}
                            key={`input-field-switch-${item.id}`}
                            onDelete={handleDelete}
                            onEdit={handleEdit}
                            twoColumn={['First Name', 'Last Name'].includes(item.name)}
                            fieldLocked={locked}
                          />
                        </UiSortableItem>
                      )
                    })
                  ) : (
                    <Text
                      fontSize=""
                      textAlign="center"
                      mt={10}
                    >
                      No custom fields have been added.
                    </Text>
                  )}
                  {formField && !formFieldList.map((field) => field.id).includes(formField.id) ? (
                    <InputFieldSwitch
                      item={formField}
                      onDelete={handleDelete}
                      onEdit={handleEdit}
                      twoColumn={['First Name', 'Last Name'].includes(formField.name)}
                      fieldLocked={false}
                    />
                  ) : null}
                </Stack>
              </UiSortableList>
            </Grid>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              onClick={handleConfirm}
              minW={32}
            >
              Save
            </Button>
            <Button
              ref={cancelRef}
              variant="ghost"
              colorScheme="atomicGray"
              onClick={handleCloseAttempt}
              minW={32}
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <ConfirmClose
        {...confirmationDisclosure}
        onConfirm={onClose}
      />
    </>
  )
}

const InputFieldSwitch: React.FC<{
  item: FormState
  onDelete: (id: string) => void
  onEdit: (id: string) => void
  twoColumn: boolean
  fieldLocked: boolean
}> = ({ item, onDelete, onEdit, twoColumn, fieldLocked }) => {
  const _hover = {
    '& .editFormField': {
      display: 'block',
    },
  }
  const _active = {
    '& .editFormField': {
      display: 'block',
    },
  }

  const inputProps: FormControlProps = {
    id: `${item.name}-${item.id}`,
    isRequired: !!item.isRequired,
    gridColumn: `span ${twoColumn ? 1 : 2}`,
    position: 'relative',
    ...(fieldLocked ? { opacity: 0.9 } : {}),
    ...(fieldLocked ? {} : { _hover, _active }),
    m: 0,
    p: 4,
    isReadOnly: fieldLocked,
  }
  switch (item.type) {
    case FieldType.Datetime:
      return (
        <UiFormControl
          zIndex={1}
          {...inputProps}
        >
          {fieldLocked && <FormFieldLocked />}
          <FormFieldHighlighter
            item={item}
            onEdit={onEdit}
            onDelete={onDelete}
          />
          <UiFormLabel fontSize="lg">{item.name || 'New Field'}</UiFormLabel>
          <Flex alignItems="center">
            <UiFormField
              name={item.name}
              type={UiFieldType.Datetime}
              min={subDays(new Date(), item.min || 0)}
              max={addDays(new Date(), item.max || 0)}
              tabIndex={-1}
            />
          </Flex>
        </UiFormControl>
      )
    case FieldType.Number:
      return (
        <UiFormControl {...inputProps}>
          {fieldLocked && <FormFieldLocked />}
          <FormFieldHighlighter
            item={item}
            onEdit={onEdit}
            onDelete={onDelete}
          />
          <UiFormLabel fontSize="lg">{item.name || 'New Field'}</UiFormLabel>
          <Flex alignItems="center">
            <UiFormField
              name={item.name}
              type={UiFieldType.Number}
              min={item.min}
              max={item.max}
              tabIndex={-1}
            />
          </Flex>
        </UiFormControl>
      )

    case FieldType.Select:
      return (
        <UiFormControl {...inputProps}>
          {fieldLocked && <FormFieldLocked />}
          <FormFieldHighlighter
            item={item}
            onEdit={onEdit}
            onDelete={onDelete}
          />
          <UiFormLabel fontSize="lg">{item.name || 'New Field'}</UiFormLabel>
          <Flex alignItems="center">
            <UiFormField
              name={item.name}
              type={UiFieldType.Select}
              options={item?.options || []}
              tabIndex={-1}
            />
          </Flex>
        </UiFormControl>
      )
    case FieldType.Multiselect:
      return (
        <Flex
          alignItems="center"
          gridColumn={`span ${twoColumn ? 1 : 2}`}
          position="relative"
          {...(fieldLocked ? { opacity: 0.9 } : {})}
          _hover={{ '& .editFormField': { display: 'block' } }}
          _active={{ '& .editFormField': { display: 'block' } }}
          m={0}
        >
          {fieldLocked && <FormFieldLocked />}
          <UiFormControl
            id={`${item.name}-${item.id}`}
            isRequired={!!item.isRequired}
            p={4}
          >
            <FormFieldHighlighter
              item={item}
              onEdit={onEdit}
              onDelete={onDelete}
            />
            <UiFormLabel fontSize="lg">{item.name || 'New Field'}</UiFormLabel>
            <UiFormField
              name={item.name}
              type={UiFieldType.MultiSelect}
              options={item?.options || []}
              tabIndex={-1}
            />
          </UiFormControl>
        </Flex>
      )
    case FieldType.Boolean:
      return (
        <UiFormControl {...inputProps}>
          {fieldLocked && <FormFieldLocked />}
          <FormFieldHighlighter
            item={item}
            onEdit={onEdit}
            onDelete={onDelete}
          />
          <Flex alignItems="center">
            <UiFormField
              name={item.name || 'New Field'}
              type={UiFieldType.Boolean}
            />
            <UiFormLabel
              fontSize="lg"
              pl={4}
            >
              {item.name || 'New Field'}
            </UiFormLabel>
          </Flex>
        </UiFormControl>
      )
    case FieldType.Text:
    default:
      return (
        <UiFormControl {...inputProps}>
          {fieldLocked && <FormFieldLocked />}
          <FormFieldHighlighter
            item={item}
            onEdit={onEdit}
            onDelete={onDelete}
          />
          <UiFormLabel fontSize="lg">{item.name || 'New Field'}</UiFormLabel>
          <Flex>
            <UiFormField
              name={item.name}
              type={UiFieldType.Text}
              min={item.min}
              max={item.max}
              tabIndex={-1}
            />
          </Flex>
        </UiFormControl>
      )
  }
}

const FormFieldLocked: React.FC = () => (
  <Box
    position="absolute"
    left="-4px"
    top="-4px"
    right="-4px"
    bottom="-4px"
    pointerEvents="none"
    zIndex={10000}
    background="repeating-linear-gradient(
        -62deg,
        rgba(255, 255, 255, .1),
        rgba(255, 255, 255, .1) 22px,
        rgba(0, 0, 0, .075) 12px,
        rgba(0, 0, 0, .075) 32px

      )"
  />
)

const FormFieldHighlighter: React.FC<{
  item: FormState
  onDelete: (id: string) => void
  onEdit: (id: string) => void
}> = ({ item, onEdit, onDelete }) => {
  const EditButton = () => (
    <UiIconButton
      size="sm"
      icon={<UiIconPostalEdit />}
      aria-label={`Edit ${item.type} field`}
      title={`Edit field: ${item.name}`}
      onClick={() => onEdit(item.id)}
    />
  )

  const DeleteButton = () => (
    <UiIconButton
      size="sm"
      icon={<UiIconDelete2 />}
      aria-label={`Delete ${item.type} field`}
      title={`Delete field: ${item.name}`}
      onClick={() => onDelete(item.id)}
    />
  )

  const Buttons = () => (
    <ButtonGroup
      right="20px"
      top={0}
      transform="translateY(-50%)"
      position="absolute"
      isAttached
      colorScheme="primary"
    >
      <EditButton />
      <DeleteButton />
    </ButtonGroup>
  )

  return (
    <Box
      position="absolute"
      display="none"
      left="-20px"
      top="0px"
      right="0px"
      bottom="-5px"
      border="1px solid rgb(159 180 194)"
      className="editFormField"
      boxShadow="lg"
      zIndex={1}
    >
      <UiSortableDragHandle
        id={item.id}
        h="100%"
      >
        <Flex
          width="20px"
          height="100%"
          borderRight="1px solid rgb(159 180 194)"
          background="rgb(159 180 194)"
          justifyContent="center"
          alignItems="center"
          cursor="grab"
        >
          <UiSortableDragHandleIcon
            color="white"
            data-testid={`handle-${item.name}`}
          />
        </Flex>
      </UiSortableDragHandle>
      <Buttons />
    </Box>
  )
}

const SelectField: React.FC<{ type: FieldType; onClick: () => void }> = ({ type, onClick }) => {
  return (
    <UiCard
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      textAlign="center"
      cursor="pointer"
      _hover={{ background: 'atomicGray.50' }}
    >
      <FieldIcon
        boxSize={8}
        mb={4}
        fieldType={type}
        {...(type === FieldType.Number ? { size: '2rem' } : {})}
      />
      {getFieldName(type)}
    </UiCard>
  )
}
