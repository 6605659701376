import { ScrollTop } from 'components/Main/ScrollTop'
import { Analytics } from 'components/ThirdParty/Analytics'
import { LaunchDarkly } from 'components/ThirdParty/LaunchDarkly'
import { LogRocketInit } from 'components/ThirdParty/LogRocketInit'
import { Segment } from 'components/ThirdParty/Segment'
import { SfdcChat } from 'components/ThirdParty/SfdcChat'
import { Outlet } from 'react-router-dom'

export default function AppRoot() {
  return (
    <>
      <Outlet />
      <ScrollTop />
      <Segment />
      <Analytics />
      <LaunchDarkly />
      <LogRocketInit appId={process.env.REACT_APP_LOG_ROCKET_KEY} />
      <SfdcChat />
    </>
  )
}
