import type { MagicLink } from 'api'
import { MagicLinkStatus } from 'api'
import { isBefore, parseISO } from 'date-fns'
import isNumber from 'lodash/isNumber'

export const getLinkStatus = (link?: MagicLink) => {
  if (!link) return {}

  const isExpired = link.expirationDate ? isBefore(parseISO(link.expirationDate), new Date()) : false

  const isFull = isNumber(link.maxExecutions) ? link.maxExecutions < (link.metrics?.linkExecutions || 0) : false

  const isDisabled = link.status === MagicLinkStatus.Disabled

  const isActive = link.status === MagicLinkStatus.Active

  const isUsable = isActive && !isExpired && !isFull

  return { isExpired, isFull, isDisabled, isActive, isUsable }
}
