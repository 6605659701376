import { CheckIcon } from '@chakra-ui/icons'
import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, Stack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiChangeEvent } from '@postal-io/postal-ui'
import {
  humanize,
  UiFormControl,
  UiHelpButton,
  UiRadioButtonGroup,
  ZFormLabel,
  ZInput,
  ZInputMoney,
  ZRadioButton,
  ZSelect,
} from '@postal-io/postal-ui'
import { BudgetHelp } from 'components/Account/BudgetHelp'
import React, { useMemo } from 'react'
import { MdOutlineInfo } from 'react-icons/md'
import type { Updater } from 'use-immer'
import type { BillingAccountMapInput } from '../../api'
import { BillingAccountsDocument, BillingAccountType, BudgetDuration, BudgetMode, Status } from '../../api'

const MIN_AMOUNT = 0
const MAX_AMOUNT = 1000000

const DURATIONS = [BudgetDuration.Monthly, BudgetDuration.Quarterly, BudgetDuration.Yearly]

const DEPARTMENTS = ['Sales', 'Marketing', 'People Ops', 'Other']

export const budgetOutV2 = (amount?: number) => Number(amount || 0)

export interface TeamSettingsStateV2 {
  name: string
  department?: string
  budgetMode: BudgetMode
  budgetDuration?: BudgetDuration
  budgetAmount?: number
  billingAccount?: BillingAccountMapInput
}
export interface TeamSettingsPropsV2 extends BoxProps {
  isLoading?: boolean
  state: TeamSettingsStateV2
  setState: Updater<TeamSettingsStateV2>
}

export const TeamSettings: React.FC<TeamSettingsPropsV2> = ({ isLoading, state, setState, ...rest }) => {
  const billingAccountsQuery = useGraphqlQuery(BillingAccountsDocument, {
    filter: { type: { eq: BillingAccountType.Funds }, status: { in: [Status.Active, null] } },
  })
  const billingAccounts = useMemo(
    () => billingAccountsQuery.data?.billingAccounts || [],
    [billingAccountsQuery.data?.billingAccounts]
  )

  const showBudgetHelp = useDisclosure()

  const handleInputMoney = ({ value }: UiChangeEvent<number>) => {
    setState((draft: any) => {
      draft.budgetAmount = value
    })
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setState((draft: any) => {
      switch (name) {
        case 'name':
          draft.name = value
          break
        case 'billingAccountId':
          const billingAccount = billingAccounts.find((b) => b.id === value)
          draft.billingAccount = billingAccount
            ? ({
                billingAccountId: billingAccount?.id,
                currency: billingAccount?.currency,
              } as BillingAccountMapInput)
            : undefined
          break
        case 'budgetMode':
          draft.budgetMode = value
          break
        case 'budgetDuration':
          draft.budgetDuration = value
          break
        case 'budgetAmount':
          draft.budgetAmount = value ? Number(value) : ''
          break
        case 'department':
          draft.department = value
          break
        default:
      }
    })
  }

  return (
    <>
      <Stack
        spacing={8}
        {...rest}
      >
        <UiFormControl isRequired>
          <ZFormLabel
            htmlFor="team-name"
            fontSize="lg"
          >
            Name
          </ZFormLabel>
          <ZInput
            id="team-name"
            name="name"
            isRequired
            value={state?.name || ''}
            onChange={handleChange}
          />
        </UiFormControl>
        <UiFormControl isRequired>
          <ZFormLabel
            htmlFor="billingAccountId"
            fontSize="lg"
          >
            Billing Account
          </ZFormLabel>
          <ZSelect
            isDisabled={isLoading}
            id="billingAccountId"
            name="billingAccountId"
            onChange={handleChange}
            value={state?.billingAccount?.billingAccountId || ''}
            placeholder={state?.billingAccount ? '' : 'Select a Billing Account'}
            isRequired
          >
            {billingAccounts.map(({ id, name }) => {
              return (
                <option
                  key={id}
                  value={id}
                >
                  {name}
                </option>
              )
            })}
          </ZSelect>
        </UiFormControl>
        <UiFormControl id="department">
          <ZFormLabel fontSize="lg">Department</ZFormLabel>
          <ZSelect
            isDisabled={isLoading}
            id="department"
            name="department"
            onChange={handleChange}
            value={state?.department || ''}
            placeholder={state?.department ? '' : 'Select a Department'}
            isRequired
          >
            {DEPARTMENTS.map((department, idx) => {
              return (
                <option
                  key={idx}
                  value={department}
                >
                  {department}
                </option>
              )
            })}
          </ZSelect>
        </UiFormControl>
        <Box>
          <ZFormLabel
            htmlFor="budgetMode"
            fontSize="lg"
            mb={2}
            gap={1}
          >
            Budget Mode
            <UiHelpButton
              aria-label="Help"
              onClick={showBudgetHelp.onOpen}
              colorScheme="atomicGray"
              color="atomicGray.400"
              icon={
                <MdOutlineInfo
                  color="inherit"
                  size="20px"
                />
              }
            />
          </ZFormLabel>
          <UiRadioButtonGroup
            id="budgetMode"
            name="budgetMode"
            value={state?.budgetMode || ''}
            icon={<CheckIcon />}
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr"
            onChange={handleChange}
            spacing={2}
          >
            <ZRadioButton value={BudgetMode.Unlimited}>Disabled</ZRadioButton>
            <ZRadioButton value={BudgetMode.Pooled}>Pooled</ZRadioButton>
            <ZRadioButton value={BudgetMode.PerUser}>Per User</ZRadioButton>
          </UiRadioButtonGroup>
        </Box>
        {state?.budgetMode !== BudgetMode.Unlimited && (
          <Box>
            <ZFormLabel
              htmlFor="budgetDuration"
              fontSize="lg"
            >
              Budget Duration
            </ZFormLabel>
            <UiRadioButtonGroup
              id="budgetDuration"
              name="budgetDuration"
              icon={<CheckIcon />}
              value={state?.budgetDuration}
              display="grid"
              gridTemplateColumns="1fr 1fr 1fr"
              onChange={handleChange}
              spacing={1}
            >
              {DURATIONS.map((duration) => (
                <ZRadioButton
                  key={duration}
                  value={duration}
                  minW="150px"
                >
                  {humanize(duration)}
                </ZRadioButton>
              ))}
            </UiRadioButtonGroup>
          </Box>
        )}
        {state?.budgetMode !== BudgetMode.Unlimited && (
          <UiFormControl isRequired>
            <ZFormLabel
              htmlFor="budgetAmount"
              fontSize="lg"
            >
              Budget Amount
            </ZFormLabel>
            <Flex alignItems="flex-start">
              <ZInputMoney
                id="budgetAmount"
                name="budgetAmount"
                isRequired
                value={state?.budgetAmount}
                onChange={handleInputMoney}
                min={MIN_AMOUNT}
                max={MAX_AMOUNT}
              />
            </Flex>
          </UiFormControl>
        )}
      </Stack>
      {showBudgetHelp.isOpen && (
        <BudgetHelp
          isOpen={showBudgetHelp.isOpen}
          onClose={showBudgetHelp.onClose}
        />
      )}
    </>
  )
}
