import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiFormControl, useAlerts, ZFormLabel, ZInput, ZModalBody, ZModalButtons } from '@postal-io/postal-ui'
import type { Account, BillingAccount, Currency } from 'api'
import { CreateBillingAccountDocument, MeDocument } from 'api'
import { AutoCompleteState } from 'components/AutoComplete'
import { AutoCompleteCountry } from 'components/AutoComplete/AutoCompleteCountry'
import type { CountryInput } from 'hooks'
import { useGetCountry } from 'hooks'
import type { ChangeEvent } from 'react'
import React from 'react'
import { useImmer } from 'use-immer'

interface BillingAccountCreateInlineProps {
  account: Account
  isOpen: boolean
  onClose: () => void
  currency?: string
  onCreate?: (i: BillingAccount) => void
}

interface FormProps {
  firstName: string
  lastName: string
  name: string
  email: string
  state: string
  country: string
  currency: Currency
}

export const BillingAccountCreateInline: React.FC<BillingAccountCreateInlineProps> = ({
  account,
  currency,
  onClose,
  onCreate,
}) => {
  const Alert = useAlerts()

  const country = useGetCountry()

  const meQuery = useGraphqlQuery(MeDocument)

  const user = meQuery.data?.me

  const [form, setForm] = useImmer<FormProps>({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.emailAddress || '',
    name: '',
    state: account?.address?.state || '',
    country: account?.address?.country || '',
    currency: currency as Currency,
  })

  const createBillingAccount = useGraphqlMutation(CreateBillingAccountDocument)

  const handleCreate = async () => {
    if (!form.name) return Alert.error('Please provide a name for this account.')

    try {
      const res = await createBillingAccount.mutateAsync({ data: form })
      const newAccount = res.createBillingAccount
      onCreate && onCreate(newAccount)
      onClose()
      Alert.success('New billing account created')
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleInput = async ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = target
    setForm((draft: any) => {
      draft[name] = value
    })
  }

  const handleStateInputChange = (state: any) => {
    setForm((draft: any) => {
      draft.state = state
    })
  }
  const handleCountryChange = (value: CountryInput | null) => {
    setForm && setForm((draft: any) => void (draft.country = value?.iso3 || ''))
  }

  const isLoading = createBillingAccount.isLoading || meQuery.isLoading

  return (
    <>
      <ZModalBody
        display="flex"
        flexDirection="column"
        p={0}
      >
        <form
          onSubmit={handleCreate}
          name="FormCreateNewAccount"
        >
          <UiFormControl
            id="name"
            mb={8}
            isRequired
          >
            <ZFormLabel fontSize="md">Account Name</ZFormLabel>
            <ZInput
              placeholder="Please name this account"
              name="name"
              value={form.name}
              onChange={handleInput}
            />
          </UiFormControl>
          <UiFormControl
            id="state"
            mb={8}
            isRequired
          >
            <ZFormLabel fontSize="md">Billing State</ZFormLabel>
            <AutoCompleteState
              country={form.country ?? country}
              value={form.state}
              onChange={handleStateInputChange}
            />
          </UiFormControl>

          <UiFormControl
            id="country"
            mb={8}
            isRequired
          >
            <ZFormLabel fontSize="md">Billing Country</ZFormLabel>
            <AutoCompleteCountry onChange={handleCountryChange} />
          </UiFormControl>
        </form>
      </ZModalBody>
      <ZModalButtons
        px={0}
        onConfirm={handleCreate}
        isConfirmLoading={isLoading}
        isConfirmDisabled={isLoading}
        confirmText="Save"
        onClose={onClose}
      />
    </>
  )
}
