import { ViewIcon } from '@chakra-ui/icons'
import type { FlexProps } from '@chakra-ui/react'
import { Flex, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiButtonProps } from '@postal-io/postal-ui'
import {
  UiButton,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalHeader,
  UiModalOverlay,
} from '@postal-io/postal-ui'
import type { PropsWithChildren } from 'react'
import React, { useMemo } from 'react'
import { useMeasure } from 'react-use'
import type { MagicLink } from '../../api'
import { PreviewMagicLinkDocument } from '../../api'
import { Iframe } from '../Common'

interface LinkPreviewProps extends FlexProps {
  link: MagicLink
}

export const LinkPreview: React.FC<PropsWithChildren<LinkPreviewProps>> = ({ link, children, ...rest }) => {
  const disclosure = useDisclosure()
  const [ref, { height }] = useMeasure()

  const previewLinkQuery = useGraphqlQuery(PreviewMagicLinkDocument, {
    approvedPostalId: link.approvedPostalId,
    variantId: link.variantId,
    message: link.message,
    landingPageCustomization: link.landingPageCustomization,
  })

  const previewUrl = useMemo(
    () => previewLinkQuery.data?.previewMagicLink.htmlUrl,
    [previewLinkQuery.data?.previewMagicLink.htmlUrl]
  )

  if (!previewUrl) return null

  return (
    <>
      {children ? (
        <UiButton
          variant="link"
          {...(rest as UiButtonProps)}
          onClick={disclosure.onToggle}
        >
          {children}
        </UiButton>
      ) : (
        <Flex
          alignItems="center"
          {...rest}
        >
          <ViewIcon mr={2} />
          <UiButton
            variant="link"
            onClick={disclosure.onToggle}
          >
            Preview Link
          </UiButton>
        </Flex>
      )}
      <UiModal
        size="full"
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        scrollBehavior="inside"
      >
        <UiModalOverlay />
        <UiModalContent>
          <UiModalHeader>
            Landing Page
            <UiModalCloseButton />
          </UiModalHeader>
          <UiModalBody
            bg="gray.200"
            p={0}
            /* @ts-ignore */
            ref={ref}
          >
            <Iframe
              src={previewUrl}
              minH={height}
              maxW="90rem"
              mx="auto"
            />
          </UiModalBody>
        </UiModalContent>
      </UiModal>
    </>
  )
}
