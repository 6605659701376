import { createId } from '@paralleldrive/cuid2'
import { useCallback, useContext } from 'react'
import { GoogleReCaptchaContext } from 'react-google-recaptcha-v3'

const SITE_VERIFY = process.env.REACT_APP_SITE_VERIFY

const useFakeRecaptcha = () => {
  const executeRecaptcha = useCallback((action: string) => `${SITE_VERIFY}-${action}-${createId()}`, [])
  return { executeRecaptcha }
}

const useRealRecaptcha = () => {
  return useContext(GoogleReCaptchaContext)
}

export const useRecaptcha = SITE_VERIFY ? useFakeRecaptcha : useRealRecaptcha
