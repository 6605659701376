import { Box, Grid, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButtonScrollTop,
  UiSkeleton,
  useInfiniteScroll,
  ZButton,
  ZSidebar,
  ZSidebarBanner,
} from '@postal-io/postal-ui'
import { GetAccountDocument, Status, TeamsDocument } from 'api'
import { AccountPage } from 'components/Account/AccountPage'
import { CenteredBox } from 'components/Common'
import { TeamCreate } from 'components/Teams/TeamCreate'
import { PageTitle, useAcl } from 'hooks'
import React from 'react'
import { MdAddCircleOutline } from 'react-icons/md'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

const LIMIT = 6

export const TeamBudgets: React.FC = () => {
  const teamsQuery = useGraphqlInfiniteQuery(TeamsDocument, {
    limit: LIMIT,
    filter: { status: { eq: Status.Active } },
  })
  const getAccountQuery = useGraphqlQuery(GetAccountDocument)

  const account = getAccountQuery.data?.getAccount
  const teams = teamsQuery.mergedData?.teams || []

  const { hasPermission } = useAcl()
  const canCreate = hasPermission('teams.create')
  const createTeam = useDisclosure()

  const { bottomRef, topRef, scrollTop } = useInfiniteScroll({
    hasMore: teamsQuery.hasNextPage,
    loadMore: teamsQuery.fetchNextPage,
    loading: teamsQuery.isFetching,
  })

  const isLoaded = !teamsQuery.isLoading && !getAccountQuery.isLoading
  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        gridTemplateColumns={{
          base: '100%',
          lg: `230px minmax(0,1fr)`,
        }}
        m={0}
        p={0}
      >
        <PageTitle title="Team Budgets" />
        <ZSidebarBanner
          title="Team Budgets"
          actions={
            <ZButton
              variant="link"
              color="atomicBlue.400"
              leftIcon={<MdAddCircleOutline size="24px" />}
              size="sm"
              height="21px"
              onClick={createTeam.onOpen}
            >
              Add a Team
            </ZButton>
          }
        />
        <UiSkeleton isLoaded={isLoaded}>
          <Box
            ref={topRef}
            style={{ scrollMargin: '20px' }}
          />
          <Grid
            gridGap={8}
            templateColumns="repeat(3, minmax(100px, 345px))"
          >
            {account && (
              <>
                <AccountPage account={account} />
                {teams.map((team) => {
                  return (
                    <AccountPage
                      key={team.id}
                      account={account}
                      team={team}
                    />
                  )
                })}
              </>
            )}
          </Grid>
          {teams.length >= LIMIT && (
            <Box
              ref={bottomRef}
              position="relative"
            >
              <UiButtonScrollTop
                onClick={scrollTop}
                position="absolute"
                top="0px"
                right="0px"
                isLoading={teamsQuery.isFetching}
                aria-label="scroll button"
              />
            </Box>
          )}
        </UiSkeleton>
        {createTeam.isOpen && canCreate && <TeamCreate {...createTeam} />}
      </ZSidebar>
    </CenteredBox>
  )
}
