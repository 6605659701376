import { Container, Grid, HStack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiCard,
  UiCardHeader,
  UiIconPostalEdit2,
  useAlertError,
  useAlerts,
  ZCard,
  ZCardBody,
  ZCardButton,
  ZCardButtons,
  ZCardHeader,
  ZCardTitle,
  ZConfirm,
  ZText,
} from '@postal-io/postal-ui'
import {
  CreatePlaybookDefinitionDocument,
  GetPlaybookDefinitionDocument,
  Status,
  UpdatePlaybookDefinitionDocument,
} from 'api'
import { NavbarButtonV2 } from 'components/Common/NavbarLink'
import { NavbarActionMenu, NavbarBackButton, SecondaryNavbar } from 'components/PostalSend/SecondaryNavbar'
import { PageTitle, useAcl } from 'hooks'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PostalFulfillments } from '../PostalFulfillments/PostalFulfillments'
import { PlaybookDefinitionSettings } from './PlaybookDefinitionSettings'
import { PlaybookDefinitionSortableSteps } from './PlaybookDefinitionSortableSteps'
import { PlaybookDefinitionStats } from './PlaybookDefinitionStats'
import { PlaybookInstances } from './PlaybookInstances'

export const PlaybookDefinitionPage: React.FC = () => {
  const navigate = useNavigate()
  const Alert = useAlerts()
  const { hasPermission } = useAcl()
  const { playbookId } = useParams() as any
  const canEdit = hasPermission('playbooks.create')

  const deleteDisclosure = useDisclosure()
  const clonePlaybookDisclosure = useDisclosure()

  const openEditModal = () => navigate(`/subscriptions/${playbookId}/edit`)

  const getPlaybook = useGraphqlQuery(
    GetPlaybookDefinitionDocument,
    { id: playbookId ?? '' },
    { enabled: !!playbookId, keepPreviousData: true }
  )
  useAlertError(getPlaybook.error)
  const playbook = useMemo(() => getPlaybook.data?.getPlaybookDefinition, [getPlaybook.data?.getPlaybookDefinition])
  const steps = playbook?.steps ?? []

  const createPlaybookDefinition = useGraphqlMutation(CreatePlaybookDefinitionDocument)
  const updatePlaybookDefinition = useGraphqlMutation(UpdatePlaybookDefinitionDocument)

  const clonePlaybook = async () => {
    if (!playbook) return Alert.error(`Subscription still loading.  Please try again`)
    const normalizedSteps = steps.map(({ productName, ...step }: any) => step)
    try {
      const res = await createPlaybookDefinition.mutateAsync({
        data: {
          name: `Copy of ${playbook.name}`,
          status: Status.Disabled,
          steps: normalizedSteps,
        },
      })
      Alert.success(`Subscription cloned`)
      navigate(`/subscriptions/${res?.createPlaybookDefinition?.id}`)
    } catch (error) {
      Alert.error(error)
    } finally {
      clonePlaybookDisclosure.onClose()
    }
  }

  const actions = [
    {
      title: `Delete Subscription`,
      onClick: deleteDisclosure.onOpen,
      isHidden: !canEdit,
    },
    {
      title: `Clone Subscription`,
      onClick: clonePlaybookDisclosure.onOpen,
      isHidden: !canEdit,
    },
  ]

  const handleDelete = async () => {
    try {
      await updatePlaybookDefinition.mutateAsync({
        id: playbook?.id ?? '',
        data: { status: Status.Delete },
      })
      navigate('/subscriptions')
      Alert.success(`Subscription status updated`)
      deleteDisclosure.onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const isLoading = getPlaybook.isLoading || updatePlaybookDefinition.isLoading

  if (playbook?.status === Status.Delete) {
    return (
      <UiCard m={8}>
        <UiCardHeader>{playbook?.name}</UiCardHeader>
        <ZText>This subscription was deleted</ZText>
      </UiCard>
    )
  }

  return (
    <>
      <PageTitle
        title={playbook?.name ?? ''}
        section="Subscription"
      />
      <SecondaryNavbar
        zIndex={100}
        // maxWidth and px match the page content
        maxWidth="100rem"
        px={{ md: 4, lg: 8, xl: 16 }}
        right={
          <HStack
            spacing={5}
            display={{ base: 'none', md: 'flex' }}
          >
            {canEdit && (
              <NavbarButtonV2
                leftIcon={<UiIconPostalEdit2 />}
                onClick={openEditModal}
              >
                Edit Subscription
              </NavbarButtonV2>
            )}
          </HStack>
        }
        farRight={<NavbarActionMenu actionItems={actions} />}
        left={
          <NavbarBackButton
            onClick={() => navigate('/subscriptions')}
            label="Back to Subscriptions"
          />
        }
        header={playbook?.name ?? ''}
      />
      <Container
        maxW="1440px"
        p={8}
      >
        <Grid
          templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
          gap={8}
          mx={8}
          maxW="100%"
        >
          <PlaybookDefinitionStats
            playbookId={playbookId}
            gridColumn="1 / 2"
          />
          <PlaybookDefinitionSettings
            playbook={playbook}
            gridColumn="1 / 2"
          />
          <ZCard
            gridColumn="2 / 3"
            gridRow="1 / 3"
            variant="dash"
            isLoading={isLoading}
            borderWidth="1px"
            borderColor="atomicGray.200"
          >
            <ZCardHeader alignItems="center">
              <ZCardTitle>Steps</ZCardTitle>
              <ZCardButtons>
                {canEdit && (
                  <ZCardButton
                    aria-label="Edit steps button"
                    colorScheme="atomicBlue"
                    icon={<UiIconPostalEdit2 />}
                    onClick={openEditModal}
                  />
                )}
              </ZCardButtons>
            </ZCardHeader>
            <ZCardBody>
              <PlaybookDefinitionSortableSteps steps={playbook?.steps ?? []} />
            </ZCardBody>
          </ZCard>
          {hasPermission('contacts.read') && (
            <PlaybookInstances
              playbook={playbook}
              gridColumn="1 / 3"
            />
          )}
          <PostalFulfillments
            playbookDefinitionId={playbookId}
            gridColumn="1 / 3"
          />
        </Grid>
      </Container>

      <ZConfirm
        title={`Clone Subscription`}
        isOpen={clonePlaybookDisclosure.isOpen}
        onConfirm={clonePlaybook}
        onClose={clonePlaybookDisclosure.onClose}
        isLoading={getPlaybook.isLoading}
        buttonText="Clone"
      >
        <ZText textAlign="justify">
          Cloning a Subscription will create a copy of this Subscription and all the steps, but not copy over the
          contacts.
        </ZText>
        <ZText
          textAlign="justify"
          mt={4}
        >
          Are you sure you want to clone this Subscription?
        </ZText>
      </ZConfirm>
      <ZConfirm
        title={`Delete Subscription`}
        isOpen={deleteDisclosure.isOpen}
        onConfirm={handleDelete}
        onClose={deleteDisclosure.onClose}
        isLoading={updatePlaybookDefinition.isLoading}
        buttonText="Delete"
        buttonColor="atomicRed"
      >
        <ZText textAlign="justify">Are you sure you want to delete this Subscription?</ZText>
      </ZConfirm>
    </>
  )
}
