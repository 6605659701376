import { Flex, FormControl, Grid } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiCardSeperator, UiToggle, useAlertError, useAlerts, ZSelect, ZText } from '@postal-io/postal-ui'
import type { EmailDigestSettings } from 'api'
import { EmailDigestFrequency, MeDocument, UpdateSelfDocument } from 'api'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'

export const NotificationSummaryEmailSettings: React.FC = () => {
  const Alert = useAlerts()
  const meQuery = useGraphqlQuery(MeDocument)
  const updateSelf = useGraphqlMutation(UpdateSelfDocument)

  const emailDigestSettings = useMemo(
    () => meQuery.data?.me?.emailDigestSettings || ({} as EmailDigestSettings),
    [meQuery.data?.me?.emailDigestSettings]
  )

  const [form, setForm] = useImmer<Record<string, any>>({
    frequency: EmailDigestFrequency.Never,
  })

  const resetForm = useCallback(() => {
    setForm((draft: any) => {
      draft.frequency = emailDigestSettings.frequency
      draft.processingErrors = emailDigestSettings.processingErrors
      draft.ordersRequiringUserApproval = emailDigestSettings.ordersRequiringUserApproval
      draft.itemRemoved = emailDigestSettings.itemRemoved
      draft.newItemApproved = emailDigestSettings.newItemApproved
      draft.newEventApproved = emailDigestSettings.newEventApproved
      draft.newCollectionApproved = emailDigestSettings.newCollectionApproved
    })
  }, [emailDigestSettings, setForm])

  useEffect(() => {
    resetForm()
  }, [resetForm])

  const handleChange = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = target
    setForm((draft: Record<string, any>) => {
      draft[name] = checked
    })
    const changedEmailDigestSettings = { ...form, [name]: checked } as any
    try {
      await updateSelf.mutateAsync({ data: { emailDigestSettings: changedEmailDigestSettings } })
      Alert.success('Setting Updated')
    } catch (err) {
      Alert.error(err)
      resetForm()
    }
  }

  const handleChangeSelect = async ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = target
    setForm((draft: Record<string, any>) => {
      draft[name] = value
    })
    const changedEmailDigestSettings = { ...form, [name]: value } as any
    try {
      await updateSelf.mutateAsync({ data: { emailDigestSettings: changedEmailDigestSettings } })
      Alert.success('Setting Updated')
    } catch (err) {
      Alert.error(err)
      resetForm()
    }
  }

  useAlertError(meQuery.error)
  useAlertError(updateSelf.error)

  return (
    <Grid
      templateColumns="1fr min-content"
      alignItems="center"
      gap={8}
    >
      <ZText gridColumn="span 2">
        These settings affect the notification type and frequency of the digest email and will not affect your other
        email notification settings.
      </ZText>

      <Flex
        h="100%"
        alignItems="center"
      >
        <ZText fontWeight="bold">Frequency</ZText>
      </Flex>

      <ZSelect
        id="frequency"
        value={form.frequency}
        name="frequency"
        onChange={handleChangeSelect}
        width="200px"
      >
        <option value={EmailDigestFrequency.Never}>Never</option>
        <option value={EmailDigestFrequency.Daily}>Every Day</option>
        <option value={EmailDigestFrequency.Weekly}>Every Week</option>
      </ZSelect>

      <UiCardSeperator
        my={0}
        gridColumn="span 2"
      />

      <ZText fontWeight="bold">Processing Errors</ZText>
      <FormControl
        id="processingErrors"
        width="min-content"
        justifySelf="end"
      >
        <UiToggle
          variant="button"
          name="processingErrors"
          isChecked={!!form.processingErrors}
          onChange={handleChange}
        />
      </FormControl>

      <ZText fontWeight="bold">MagicLink Approvals</ZText>
      <FormControl
        id="ordersRequiringUserApproval"
        width="min-content"
        justifySelf="end"
      >
        <UiToggle
          variant="button"
          name="ordersRequiringUserApproval"
          isChecked={!!form.ordersRequiringUserApproval}
          onChange={handleChange}
        />
      </FormControl>

      <ZText fontWeight="bold">Item Removed</ZText>
      <FormControl
        id="itemRemoved"
        width="min-content"
        justifySelf="end"
      >
        <UiToggle
          variant="button"
          name="itemRemoved"
          isChecked={!!form.itemRemoved}
          onChange={handleChange}
        />
      </FormControl>

      <UiCardSeperator
        my={0}
        gridColumn="span 2"
      />

      <ZText
        gridColumn="span 2"
        fontSize="body-lg"
      >
        New Approvals
      </ZText>

      <ZText fontWeight="bold">New Item</ZText>
      <FormControl
        id="newItemApproved"
        width="min-content"
        justifySelf="end"
      >
        <UiToggle
          variant="button"
          name="newItemApproved"
          isChecked={!!form.newItemApproved}
          onChange={handleChange}
        />
      </FormControl>

      <ZText fontWeight="bold">New Event</ZText>
      <FormControl
        id="newEventApproved"
        width="min-content"
        justifySelf="end"
      >
        <UiToggle
          variant="button"
          name="newEventApproved"
          isChecked={!!form.newEventApproved}
          onChange={handleChange}
        />
      </FormControl>

      <ZText fontWeight="bold">New Collection</ZText>
      <FormControl
        id="newCollectionApproved"
        width="min-content"
        justifySelf="end"
      >
        <UiToggle
          variant="button"
          name="newCollectionApproved"
          isChecked={!!form.newCollectionApproved}
          onChange={handleChange}
        />
      </FormControl>
    </Grid>
  )
}
