import { useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiSelectTypeaheadProps } from '@postal-io/postal-ui'
import { UiSelectTypeahead, useAlertError } from '@postal-io/postal-ui'
import { GetCachedCurrencyConfigsDocument } from 'api'
import { useCallback } from 'react'

export enum CurrencyEnableFilter {
  BillingAccount,
}

export type AutoCompleteCurrencyProps = UiSelectTypeaheadProps<any> & {
  currencyEnableFilter: CurrencyEnableFilter
}

export function AutoCompleteCurrency(props: AutoCompleteCurrencyProps) {
  const { onChange, isDisabled, currencyEnableFilter, ...rest } = props

  const query = useGraphqlQuery(GetCachedCurrencyConfigsDocument, { filter: { billingAccountEnabled: true } })
  useAlertError(query.error)

  // Map to currency
  const currencies = query.data?.getCachedCurrencyConfigs?.map((config) => config.currency) ?? []

  // Convert to option object
  const options = currencies.map((c) => ({ label: c.toString(), value: c.toString() }))

  const handleChange = useCallback((value: any, action: any) => onChange?.(value?.value, action), [onChange])

  return (
    <UiSelectTypeahead
      data-testid="AutoCompleteCurrency"
      options={options}
      getOptionLabel={(t) => t.label}
      getOptionValue={(t) => t.value}
      onChange={handleChange}
      isLoading={query.isLoading}
      placeholder="Currency"
      noOptionsMessage={() => 'No Currencies Found'}
      isDisabled={props.isDisabled}
      {...rest}
    />
  )
}
