import type { BoxProps } from '@chakra-ui/react'
import { FormControl, Stack } from '@chakra-ui/react'
import { ZFormLabel, ZInput, ZTextarea } from '@postal-io/postal-ui'
import type { UpdateSelfInput } from 'api'
import React from 'react'
import type { Updater } from 'use-immer'

interface UserProfileEditUserProps extends BoxProps {
  form: UpdateSelfInput
  setForm: Updater<UpdateSelfInput>
}

export const UserProfileEditUser: React.FC<UserProfileEditUserProps> = ({ form, setForm, ...rest }) => {
  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = target
    setForm((draft: any) => void (draft[name] = value))
  }

  return (
    <Stack
      spacing={8}
      {...rest}
    >
      <FormControl
        id="firstName"
        isRequired
      >
        <ZFormLabel>First Name</ZFormLabel>
        <ZInput
          name="firstName"
          onChange={handleInput}
          value={form.firstName || ''}
        />
      </FormControl>
      <FormControl
        id="lastName"
        isRequired
      >
        <ZFormLabel>Last Name</ZFormLabel>
        <ZInput
          name="lastName"
          onChange={handleInput}
          value={form.lastName || ''}
        />
      </FormControl>
      <FormControl
        id="emailAddress"
        isRequired
      >
        <ZFormLabel>Email</ZFormLabel>
        <ZInput
          name="emailAddress"
          onChange={handleInput}
          value={form.emailAddress || ''}
        />
      </FormControl>
      <FormControl id="title">
        <ZFormLabel>Title</ZFormLabel>
        <ZInput
          name="title"
          onChange={handleInput}
          value={form.title || ''}
        />
      </FormControl>
      <FormControl id="phoneNumber">
        <ZFormLabel>Phone Number</ZFormLabel>
        <ZInput
          name="phoneNumber"
          onChange={handleInput}
          value={form.phoneNumber || ''}
        />
      </FormControl>
      <FormControl id="meetingLink">
        <ZFormLabel>Meeting Link</ZFormLabel>
        <ZInput
          name="meetingLink"
          onChange={handleInput}
          value={form.meetingLink || ''}
        />
      </FormControl>
      <FormControl id="emailSignatureHtml">
        <ZFormLabel>Email Signature</ZFormLabel>
        <ZTextarea
          name="emailSignatureHtml"
          onChange={handleInput}
          minH="250px"
          value={form.emailSignatureHtml || ''}
        />
      </FormControl>
    </Stack>
  )
}
