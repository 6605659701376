import { UiLoading } from '@postal-io/postal-ui'
import 'animate.css/animate.css'
import 'flatpickr/dist/flatpickr.min.css'
import { useSession } from 'hooks'
import { enableMapSet } from 'immer'
import { useEffect, useState } from 'react'
import { AppRoutes } from 'routes/AppRoutes'
import { ExtensionRoutes } from 'routes/ExtensionRoutes'
import './App.css'

// enable immer to use maps
enableMapSet()

export const App = () => {
  const { session, loadSession, removeSession } = useSession()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // if we are already logged in then skip loading
    if (session) {
      setLoading(false)
    } else {
      // try to load a session from storage
      loadSession()
        .catch(() => removeSession())
        .finally(() => setLoading(false))
    }
  }, [loadSession, removeSession, session])

  const isExtension = window.location.pathname.startsWith('/extension')

  if (loading) {
    return (
      <main>
        <UiLoading />
      </main>
    )
  }

  return isExtension ? <ExtensionRoutes /> : <AppRoutes />
}
