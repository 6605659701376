import { FormControl, FormLabel, HStack } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiToggle, useAlertError, useAlerts, ZInfoTooltip } from '@postal-io/postal-ui'
import {
  AddProductAccessDocument,
  DataObjectType,
  GetUserDocument,
  Product,
  RemoveProductAccessDocument,
  Role,
  SearchIntegrationSyncDocument,
  Status,
} from 'api'
import { ExternalSystem } from 'components/Integrations'
import { useAcl } from 'hooks'
import { some } from 'lodash'
import React, { useMemo } from 'react'

export const UserABMToggle: React.FC<{ userId: string }> = ({ userId }) => {
  const Alert = useAlerts()
  const { hasRole, hasSubscription } = useAcl()
  const canUpdateABM = hasRole(Role.Admin) && hasSubscription('POSTAL_ABM_ACCESS')

  const addProductAccess = useGraphqlMutation(AddProductAccessDocument)
  const removeProductAccess = useGraphqlMutation(RemoveProductAccessDocument)

  const getUserQuery = useGraphqlQuery(GetUserDocument, { id: userId as string })
  const user = useMemo(() => getUserQuery.data?.getUser, [getUserQuery.data?.getUser])
  useAlertError(getUserQuery.error)

  const hasABMAccess = useMemo(
    // complete list of productAccess only found on User not UserAccount
    () => user?.productAccess?.some((p) => p.product === Product.PostalIoAbm && p.roles?.includes(Role.User)),
    [user?.productAccess]
  )

  const searchIntegrationSyncQuery = useGraphqlQuery(
    SearchIntegrationSyncDocument,
    {
      filter: {
        objectType: { in: [DataObjectType.User] },
        system: { in: [ExternalSystem.Salesforce, ExternalSystem.SalesforceSandbox] },
      },
    },
    // we only need this data if they can update ABM
    { enabled: canUpdateABM }
  )
  useAlertError(searchIntegrationSyncQuery.error)

  const isSalesforceIntegrationActive = useMemo(
    () => some(searchIntegrationSyncQuery?.data?.searchIntegrationSync ?? [], (i) => i?.status === Status.Active),
    [searchIntegrationSyncQuery?.data?.searchIntegrationSync]
  )

  const handleChangeABMAccess = async ({ target: { checked: addAccess } }: React.ChangeEvent<HTMLInputElement>) => {
    if (!user) return

    try {
      if (!canUpdateABM) throw new Error('Account does not have ABM Subscription')

      if (addAccess) {
        await addProductAccess.mutateAsync({
          id: user.id,
          teamId: null,
          roles: [Role.User, Role.Admin],
          sendNotification: true,
          product: Product.PostalIoAbm,
        })
      } else {
        const productAccessId = user.productAccess?.find(
          (pa) => pa?.product === Product.PostalIoAbm && !!pa.roles?.length
        )?.id

        if (productAccessId) {
          await removeProductAccess.mutateAsync({ id: user.id, productAccessId: productAccessId })
        }
      }

      Alert.success(`ABM Access ${addAccess ? 'Added' : 'Removed'}`)
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <FormControl mt={8}>
      <HStack gap={2}>
        <UiToggle
          isChecked={hasABMAccess}
          onChange={handleChangeABMAccess}
          isDisabled={!isSalesforceIntegrationActive && !hasABMAccess}
        />
        <FormLabel
          color="atomicGray.600"
          fontSize="body-md"
          display="flex"
          alignItems="center"
          gap={2}
        >
          ABM Access
          {!isSalesforceIntegrationActive && (
            <ZInfoTooltip label="ABM Access requires an active Salesforce integration" />
          )}
        </FormLabel>
      </HStack>
    </FormControl>
  )
}
