import { ZSidebar, ZSidebarBanner } from '@postal-io/postal-ui'
import { BudgetHistoryTable } from 'components/BudgetHistory/BudgetHistoryTable'
import { CenteredBox } from 'components/Common'
import { PageTitle } from 'hooks'
import React from 'react'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

export const BudgetHistory: React.FC = () => {
  return (
    <CenteredBox isLoaded>
      <ZSidebar
        sidebarBlocks={<ProfileSidebarBlocks />}
        m={0}
        p={0}
      >
        <ZSidebarBanner title="Budget History" />
        <PageTitle title="Budget History" />
        <BudgetHistoryTable />
      </ZSidebar>
    </CenteredBox>
  )
}
