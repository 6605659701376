import { Collapse, Flex } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import { UiToggle, useAlerts, ZButton, ZCardBody, ZCardHeader, ZText, ZTextarea } from '@postal-io/postal-ui'
import { GetAccountDocument, UpdateAccountDocument } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

const MAX_DISCLAIMER_LENGTH = 280

export const SignatureCopyright: React.FC<UiCardProps> = (props) => {
  const Alert = useAlerts()
  const [disclaimer, setDisclaimer] = useState<string>('')
  const [disclaimerToggle, setDisclaimerToggle] = useState<boolean>(false)

  const accountData = useGraphqlQuery(GetAccountDocument)

  const accountId = useMemo(() => accountData?.data?.getAccount?.id, [accountData?.data?.getAccount])

  const accountDisclaimerInfo = useMemo(
    () => accountData?.data?.getAccount?.sendSettings?.disclaimerInfo,
    [accountData]
  )
  const accountDisclaimerEnabled = useMemo(
    () => accountDisclaimerInfo?.enabled ?? false,
    [accountDisclaimerInfo?.enabled]
  )

  const updateAccount = useGraphqlMutation(UpdateAccountDocument)

  useEffect(() => {
    if (accountDisclaimerInfo?.enabled) setDisclaimerToggle(accountDisclaimerInfo?.enabled)
    if (accountDisclaimerInfo?.disclaimer) setDisclaimer(accountDisclaimerInfo?.disclaimer)
  }, [accountDisclaimerInfo])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDisclaimer(e.target.value)
  }

  const characterCount = useMemo(() => disclaimer.length ?? 0, [disclaimer])

  const hasChanged = useMemo(
    () => (accountDisclaimerInfo?.disclaimer ?? '') !== disclaimer,
    [accountDisclaimerInfo?.disclaimer, disclaimer]
  )

  const onToggleDisclaimer = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setDisclaimerToggle(e.target.checked)
      try {
        await updateAccount.mutateAsync({
          id: accountId!,
          data: {
            sendSettings: {
              ...accountData?.data?.getAccount?.sendSettings,
              disclaimerInfo: {
                ...accountDisclaimerInfo,
                enabled: e.target.checked,
              },
            },
          },
        })

        Alert.success(
          `Additional Company Information is now ${
            e.target.checked ? 'enabled' : 'disabled'
          } on all recipient landing pages.`
        )
      } catch (e) {
        Alert.error(e)
      }
    },
    [updateAccount, accountId, Alert, accountDisclaimerInfo, accountData]
  )

  const onSubmit = useCallback(async () => {
    try {
      await updateAccount.mutateAsync({
        id: accountId!,
        data: {
          sendSettings: {
            ...accountData?.data?.getAccount?.sendSettings,
            disclaimerInfo: {
              ...accountDisclaimerInfo,
              disclaimer,
            },
          },
        },
      })
      Alert.success('Additional Company Information has been saved.')
    } catch (e) {
      Alert.error(e)
    }
  }, [updateAccount, Alert, disclaimer, accountId, accountDisclaimerInfo, accountData])

  return (
    <>
      <ZCardHeader
        fontSize="lg"
        fontWeight="normal"
        display="flex"
        alignItems="center"
        p={8}
        pb={0}
      >
        <Flex
          alignItems="center"
          gap={2}
        >
          Additional Company Information
          <ZInfoTooltip label="The information placed in this box will be surfaced on every recipient landing page – from both Personalized Emails and MagicLinks. Examples include: a company disclaimer, copyright, or signature." />
        </Flex>
        <Flex
          alignItems="center"
          gap={4}
        >
          <ZText color="atomicGray.600">Enabled</ZText>
          <UiToggle
            fontSize="md"
            isChecked={disclaimerToggle}
            onChange={onToggleDisclaimer}
          />
        </Flex>
      </ZCardHeader>
      <ZCardBody {...props}>
        <ZTextarea
          value={disclaimer}
          onChange={handleChange}
          maxLength={MAX_DISCLAIMER_LENGTH}
          isDisabled={updateAccount.isLoading || !accountDisclaimerEnabled}
          mb={2}
        />
        <Flex
          justifyContent="space-between"
          opacity={accountDisclaimerEnabled ? 1 : 0.5}
          transition="opacity linear 200ms"
        >
          <ZText fontSize="md">Max Length: 280</ZText>
          <ZText fontSize="md">
            Your estimated character count is: <strong>{characterCount}</strong>
          </ZText>
        </Flex>
        <Collapse in={hasChanged && (accountDisclaimerInfo?.enabled ?? false)}>
          <ZButton
            colorScheme="atomicBlue"
            onClick={onSubmit}
            isLoading={updateAccount.isLoading}
            isDisabled={updateAccount.isLoading || !accountDisclaimerInfo?.enabled}
            mt={8}
          >
            Save Disclaimer
          </ZButton>
        </Collapse>
      </ZCardBody>
    </>
  )
}
