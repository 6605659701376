import { localStorage, sessionStorage } from '@postal-io/postal-ui'
import type { LoginPasswordRequest, SignUpPasswordRequest } from 'api/rest'
import api from 'api/rest'
import jwtDecode from 'jwt-decode'
import { logger, StorageKeys } from 'lib'
import type { PropsWithChildren } from 'react'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { useQueryClient } from 'react-query'

const LOCAL_STORAGE_KEYS_TO_KEEP: string[] = [StorageKeys.AppProductAccess, StorageKeys.AppRedirectPath]

export const getToken = () => {
  return sessionStorage.getItem(StorageKeys.AppToken) || ''
}

export const getRefreshToken = () => {
  return localStorage.getItem(StorageKeys.AppRefreshToken) || ''
}

export const clearSession = () => {
  Object.keys(sessionStorage).forEach((key) => {
    if (!LOCAL_STORAGE_KEYS_TO_KEEP.includes(key)) sessionStorage.removeItem(key)
  })
  Object.keys(localStorage).forEach((key) => {
    if (!LOCAL_STORAGE_KEYS_TO_KEEP.includes(key)) localStorage.removeItem(key)
  })
}

export const getProductAccessId = () => {
  return localStorage.getItem(StorageKeys.AppProductAccess) || ''
}

export const clearProductAccessId = () => {
  return localStorage.removeItem(StorageKeys.AppProductAccess)
}

export const saveSession = ({ token, refreshToken }: { token?: string; refreshToken?: string }) => {
  if (token) sessionStorage.setItem(StorageKeys.AppToken, token)
  if (refreshToken) localStorage.setItem(StorageKeys.AppRefreshToken, refreshToken)
  if (token) {
    try {
      const { productAccessId } = jwtDecode(token) as any
      if (productAccessId) localStorage.setItem(StorageKeys.AppProductAccess, productAccessId)
    } catch (err) {
      console.error(err)
    }
  }
  return { token, refreshToken }
}

// track a single promise
let reloadingPromise: any

export const reloadSession = () => {
  if (reloadingPromise) return reloadingPromise
  reloadingPromise = api.accessToken().catch(() => {})
  return reloadingPromise
}

type SessionContextProps = {
  replaceSession?: any
  loadSession?: any
  loginPassword?: any
  loginSso?: any
  logout?: any
  removeSession?: any
  session?: any
  signUpPassword?: any
  switchSession?: any
  getInviteInfo?: any
  inviteComplete?: any
  signUpVerify?: any
  resetPassword?: any
  forgotPassword?: any
  signUp?: any
}

const SessionContext = createContext<SessionContextProps>({})

export const SessionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const client = useQueryClient()
  const [session, setSession] = useState<any>()

  logger('SESSION', session)

  const removeSession = useCallback(async () => {
    clearSession()
    client.clear()
    setSession(false)
  }, [client])

  const startSession = useCallback(async () => {
    try {
      const data: any = jwtDecode(getToken() as string)
      client.clear()
      setSession(data)
      return data
    } catch (e) {
      removeSession()
    }
  }, [client, removeSession])

  const replaceSession = useCallback(
    async ({ token, refreshToken }: { token: string; refreshToken: string }) => {
      saveSession({ token, refreshToken })
      const session = await startSession()
      return session
    },
    [startSession]
  )

  const switchSession = useCallback(async (productAccessId: string) => {
    await api.accessToken({ productAccessId })
    window.location.reload()
    return new Promise((res) => setTimeout(() => res(true), 500))
  }, [])

  const logout = useCallback(() => {
    return api
      .logout()
      .then(removeSession)
      .then(() => session)
  }, [removeSession, session])

  const loginPassword = useCallback(
    async (args: LoginPasswordRequest) => {
      saveSession(await api.loginPassword(args))
      const session = await startSession()
      return session
    },
    [startSession]
  )

  const loginSso = useCallback(
    async (args: string) => {
      saveSession(await api.loginSso(args))
      const session = await startSession()
      return session
    },
    [startSession]
  )

  const signUpPassword = useCallback(
    async (args: SignUpPasswordRequest) => {
      saveSession(await api.signUpPassword(args))
      await startSession()
    },
    [startSession]
  )

  const loadSession = useCallback(
    async (refreshToken?: string) => {
      await api.accessToken({ refreshToken })
      await startSession()
    },
    [startSession]
  )

  const signUpVerify = useCallback(
    async (args: any) => {
      const { refreshToken } = await api.signUpVerify(args)
      await api.accessToken({ refreshToken })
      await startSession()
    },
    [startSession]
  )

  const value = {
    replaceSession,
    loadSession,
    loginPassword,
    loginSso,
    logout,
    removeSession,
    session,
    signUpPassword,
    signUpVerify,
    switchSession,
    forgotPassword: api.forgotPassword,
    getInviteInfo: api.getInviteInfo,
    inviteComplete: api.inviteComplete,
    resetPassword: api.resetPassword,
    signUp: api.signUp,
  }
  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
}

export const useSession = () => useContext(SessionContext)
