import { HStack, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlerts, useGraphqlFilter } from '@postal-io/postal-ui'
import { ZLink } from 'components/Common/ZComponents'
import { ContactCreateEdit } from 'components/Contact/ContactCreateEdit'
import type { MultiSelectContactsState } from 'components/MultiSelectContacts/MultiSelectContacts'
import { MultiSelectContacts } from 'components/MultiSelectContacts/MultiSelectContacts'
import { useMultiSelect } from 'components/MultiSelectContacts/useMultiSelect'
import { PostalSelectItem } from 'components/PostalSend/PostalSelectItem'
import { AnalyticsEvent, PageTitle, useAcl, useAnalyticsEvent, useNavigateSendFlow, useSession } from 'hooks'
import { StorageKeys } from 'lib'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { MdPersonAddAlt } from 'react-icons/md'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import type {
  ApprovedPostal,
  SearchableContact,
  SearchableContactFilterInput,
  SearchContactsV2QueryVariables,
} from '../../api'
import { Role, SearchContactListsDocument, SearchContactsV2Document } from '../../api'
import { CenteredBox } from '../Common/CenteredBox'
import { ContactsBulkList } from './ContactsBulkList'
import { ContactsBulkTagV2 } from './ContactsBulkTagV2'
import { ContactsButtons } from './ContactsButtons'
import { ContactsCreatePlaybookInstances } from './ContactsCreatePlaybookInstances'
import { initialState, LIMIT, TRANSFORMS } from './ContactsData'
import { ContactsDelete } from './ContactsDelete'
import { ContactsFavorite } from './ContactsFavorite'
import { ContactsHeader } from './ContactsHeader'
import { ContactsImport } from './ContactsImport'
import { ContactListEdit } from './ContactsListEdit'

export const Contacts: React.FC = () => {
  const Alert = useAlerts()

  const state = useMultiSelect<SearchableContact, SearchableContactFilterInput>({})

  const location = useLocation()
  const prevLocation = useRef(location.pathname)
  useEffect(() => {
    if (location.pathname !== prevLocation.current) {
      prevLocation.current = location.pathname
      state.clear()
    }
  }, [location.pathname, state])

  const addContactDisclosure = useDisclosure()
  const favoriteDisclosure = useDisclosure()
  const deleteDisclosure = useDisclosure()
  const listDisclosure = useDisclosure()
  const tagDisclosure = useDisclosure()
  const editList = useDisclosure()
  const sendPostal = useDisclosure()
  const sendPlaybook = useDisclosure()

  const onSelectButton = (type: string) => {
    switch (type) {
      case 'FAVORITE':
        return favoriteDisclosure.onOpen()
      case 'DELETE':
        return deleteDisclosure.onOpen()
      case 'CONTACT_LIST':
        return listDisclosure.onOpen()
      case 'POSTAL':
        return sendPostal.onOpen()
      case 'TAGS':
        return tagDisclosure.onOpen()
      case 'SUBSCRIPTION':
        return sendPlaybook.onOpen()
      default:
        Alert.warning(`${type} not implemented yet`)
    }
  }

  const {
    session: { userId },
  } = useSession()

  const { hasRole } = useAcl()
  const { ownerId, listId } = useParams() as any

  const isOwnerMe = ownerId === 'me'

  const staticVariables = useMemo(() => {
    const vars = { filter: {}, limit: LIMIT } as any
    if (isOwnerMe) vars.filter.ownerId = { eq: userId }
    if (listId) vars.filter.lists = { eq: listId }
    return vars
  }, [isOwnerMe, listId, userId])

  // TODO: make sure sort is proper, not q unless q has a filter
  // Adding search by name to go in AutoCompleteTags
  const graphqlFilter = useGraphqlFilter<SearchContactsV2QueryVariables>({
    transforms: TRANSFORMS,
    initialState,
    staticVariables,
    persistKey: StorageKeys.ContactsFilter,
    debounce: 500,
  })

  // get the user's lists
  const { data: listsData, isLoading: listsLoading } = useGraphqlQuery(SearchContactListsDocument)
  const lists = useMemo(() => listsData?.searchContactLists || [], [listsData?.searchContactLists])
  const selectedList = useMemo(() => lists?.find(({ id }) => id === listId), [listId, lists])
  const favoriteList = useMemo(() => lists?.find(({ name }) => name.toLowerCase() === 'favorites'), [lists])
  const canEditList = selectedList && selectedList.id !== favoriteList?.id

  // Get the contacts, but wait on the me query and lists query in case
  // this page was loaded from a deep link
  const searchContacts = useGraphqlInfiniteQuery(SearchContactsV2Document, graphqlFilter.variables, {
    enabled: !listsLoading,
  })

  const title = useMemo(() => {
    const isAdmin = hasRole(Role.Manager)
    if (isOwnerMe) return 'My Contacts'
    if (selectedList) return selectedList.name
    if (!ownerId) return isAdmin ? 'All Contacts' : 'My Contacts'
  }, [hasRole, isOwnerMe, ownerId, selectedList])

  const refetchDebounced = useDebouncedCallback(() => searchContacts.refetch(), 1000)

  const sendFlowLink = useNavigateSendFlow()
  const navigate = useNavigate()

  const sendContacts = useMemo(() => {
    return (
      state.items.length > 0
        ? { items: state.items }
        : {
            filters: [{ filter: graphqlFilter.variables?.filter, totalRecords: state.totalRecords }],
            orfilters: state.orfilters,
          }
    ) as Partial<MultiSelectContactsState>
  }, [graphqlFilter.variables.filter, state.items, state.orfilters, state.totalRecords])

  const handleSelectItem = useCallback(
    (postal: ApprovedPostal) => {
      sendPostal.onClose()
      navigate(
        sendFlowLink(`/contacts/send/${postal.id}`, {
          returnTo: 'Contacts',
          contactFilter: sendContacts,
        })
      )
    },
    [navigate, sendContacts, sendFlowLink, sendPostal]
  )

  useAnalyticsEvent({ event: AnalyticsEvent.ContactsPageViewed })
  return (
    <>
      <PageTitle
        title={title}
        section="Contacts"
      />
      <ContactsHeader
        mb={0}
        header="Contacts"
        right={
          <HStack
            display="flex"
            verticalAlign="center"
            spacing={5}
          >
            <ZLink
              color="atomicBlue.400"
              fontWeight="bold"
              onClick={addContactDisclosure.onOpen}
            >
              <MdPersonAddAlt
                size={20}
                style={{ marginRight: 5 }}
              />
              Create a Contact
            </ZLink>
            <ContactsImport />
          </HStack>
        }
      />
      <CenteredBox
        pt={4}
        isLoaded
      >
        <MultiSelectContacts
          graphqlFilter={graphqlFilter}
          state={state}
        />
      </CenteredBox>
      <ContactsButtons
        onSelectButton={onSelectButton}
        isDisabled={state.totalRecords < 1}
        mt={4}
        selectedList={selectedList}
        favoriteList={favoriteList}
      />
      {addContactDisclosure.isOpen && (
        <ContactCreateEdit
          isOpen={addContactDisclosure.isOpen}
          onClose={addContactDisclosure.onClose}
        />
      )}
      {sendPostal.isOpen && (
        <PostalSelectItem
          {...sendPostal}
          onSelect={handleSelectItem}
        />
      )}
      {sendPlaybook.isOpen && (
        <ContactsCreatePlaybookInstances
          isOpen={sendPlaybook.isOpen}
          onClose={sendPlaybook.onClose}
          contacts={state.items}
          filters={state.orfilters}
        />
      )}
      {favoriteDisclosure.isOpen && (
        <ContactsFavorite
          isOpen={favoriteDisclosure.isOpen}
          onClose={favoriteDisclosure.onClose}
          onComplete={refetchDebounced}
          selectedList={selectedList}
          favoriteList={favoriteList}
          count={state.totalRecords}
          filters={state.orfilters}
        />
      )}
      {listDisclosure.isOpen && (
        <ContactsBulkList
          isOpen={listDisclosure.isOpen}
          onClose={listDisclosure.onClose}
          onComplete={refetchDebounced}
          count={state.totalRecords}
          filters={state.orfilters}
          selectedList={canEditList ? selectedList : undefined}
        />
      )}
      {tagDisclosure.isOpen && (
        <ContactsBulkTagV2
          isOpen={tagDisclosure.isOpen}
          onClose={tagDisclosure.onClose}
          onComplete={refetchDebounced}
          filters={state.orfilters}
          count={state.totalRecords}
        />
      )}
      {deleteDisclosure.isOpen && (
        <ContactsDelete
          isOpen={deleteDisclosure.isOpen}
          onClose={deleteDisclosure.onClose}
          onComplete={() => {
            refetchDebounced()
            state.clearItems()
          }}
          contacts={state.items}
          filters={state.orfilters}
          count={state.totalRecords}
        />
      )}
      {canEditList && editList.isOpen && selectedList && (
        <ContactListEdit
          isOpen={editList.isOpen}
          onClose={editList.onClose}
          list={selectedList}
        />
      )}
    </>
  )
}
