import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { GetApprovedPostalDocument, GetContactDocument } from 'api'
import type { MultiSelectContactsState } from 'components/MultiSelectContacts'
import { generateOrfilter } from 'components/MultiSelectContacts/useMultiSelect'
import { PostalSend } from 'components/PostalSend'
import { isPostalDisabled, PostalSendType } from 'components/PostalSend/postalSendHelpers'
import { PostalSendLoading } from 'components/PostalSend/PostalSendLoading'
import { PostalUnavailable } from 'components/PostalSend/PostalUnavailable'
import {
  AnalyticsEventV2,
  useAnalyticsSend,
  useExtension,
  useNavigateSendFlowParams,
  usePostalPermissions,
} from 'hooks'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const ExtContactPostalSendV2: React.FC = () => {
  const { contactId, setContactId } = useExtension()
  const params = useParams()
  const approvedPostalId = params.approvedPostalId as string
  const { variantId } = useNavigateSendFlowParams()

  const navigate = useNavigate()

  const backLabel = 'Back to Contact'

  function goBack() {
    navigate(`/extension/contacts/${contactId}`)
  }

  const sendAnalytics = useAnalyticsSend()

  const onNavigateBack = () => {
    sendAnalytics({ event: AnalyticsEventV2.ExtensionSendFlowBackClicked })
    goBack()
  }

  const getApprovedPostalQuery = useGraphqlQuery(
    GetApprovedPostalDocument,
    { id: approvedPostalId },
    { enabled: !!contactId }
  )
  const postal = useMemo(
    () => getApprovedPostalQuery.data?.getApprovedPostal,
    [getApprovedPostalQuery.data?.getApprovedPostal]
  )

  const variant = postal?.variants?.find((v) => v.id === variantId)

  const getContactQuery = useGraphqlQuery(
    GetContactDocument,
    { id: contactId },
    {
      enabled: !!contactId,
      onError: () => setContactId(''),
    }
  )

  const contacts = useMemo(() => {
    const contact = getContactQuery.data?.getContact
    if (!contact) return
    return {
      items: [contact],
      totalRecords: 1,
      orfilters: generateOrfilter({ items: [contact] }),
    } as Partial<MultiSelectContactsState>
  }, [getContactQuery.data?.getContact])

  const { defaultNonLinkSendMethod } = usePostalPermissions(postal)

  if (getApprovedPostalQuery.isLoading || getContactQuery.isLoading) {
    return (
      <PostalSendLoading
        onNavigateBack={goBack}
        backLabel={backLabel}
      />
    )
  }

  if (!postal || isPostalDisabled(postal)) {
    return (
      <PostalUnavailable
        isOpen
        onClose={goBack}
      />
    )
  }

  return (
    <PostalSend
      method={defaultNonLinkSendMethod}
      type={PostalSendType.Contact}
      postal={postal}
      variant={variant}
      contacts={contacts}
      onNavigateBack={onNavigateBack}
      navigateBackLabel={backLabel}
      onComplete={() => {
        navigate(`/extension/contacts/${contactId}`)
      }}
    />
  )
}
