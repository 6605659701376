import { Box, Flex, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { ZButton, ZCardBody, ZHeading } from '@postal-io/postal-ui'
import type { MessageTemplate } from 'api'
import { GetAccountDocument } from 'api'
import { ZAlert, ZFormLabel, ZInputTextWithVariables, ZTextareaWithVariables } from 'components/Common/ZComponents'
import { MessageVariablesDrawer } from 'components/Messages/MessageVariablesDrawer'
import { CATEGORY } from 'components/Postals'
import { PostalReviewPopOver } from 'components/PostalSend/PostalReviewPopOver'
import { useAcl, useMe, useSendAs } from 'hooks'
import { getMessageVariablesErrors, getParsingError } from 'lib'
import React, { useEffect, useMemo, useState } from 'react'
import { MessagesDrawer, messageVariables } from '../Messages'
import type { PostalCustomizeV2Props } from './PostalCustomize'
import {
  DEFAULT_EVENT_SUBJECT_LINE,
  DEFAULT_ITEM_SUBJECT_LINE,
  estimateCharacterCount,
  getSendMethodColor,
  giftMessagePlaceholder,
  SendAsType,
} from './usePostalSend'
import { usePostalSendFieldErrors } from './usePostalSendFieldErrors'

interface InlineErrorDisplayProps {
  error?: string
}
export const InlineErrorDisplay: React.FC<InlineErrorDisplayProps> = ({ error }) =>
  error ? (
    <Text
      textStyle="italic"
      mt={2}
      color="red.500"
    >
      {error}
    </Text>
  ) : null

export const MessageVariablesButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <ZButton
    display="inline"
    fontSize="body-sm"
    color="atomicBlue.400"
    _hover={{ color: 'atomicBlue.600' }}
    variant="link"
    p={0}
    h="unset"
    onClick={onClick}
  >
    Message Variables
  </ZButton>
)
export const SavedMessagesButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <ZButton
    display="inline"
    fontSize="body-sm"
    color="atomicBlue.400"
    _hover={{ color: 'atomicBlue.600' }}
    ml={8}
    variant="link"
    p={0}
    h="unset"
    onClick={onClick}
  >
    Saved Messages
  </ZButton>
)

export const PostalCustomizeEmail: React.FC<PostalCustomizeV2Props> = ({ context, send }) => {
  const { hasPermission, hasFeature } = useAcl()
  const selectGiftMessage = useDisclosure()
  const seeMessageVariables = useDisclosure()
  const [subjectCharacterCount, setSubjectCharacterCount] = useState(0)

  const handleSelectGiftMessage = ({ templateText }: MessageTemplate) => {
    send({ type: 'SET_GIFT_MESSAGE', data: templateText })
    selectGiftMessage.onClose()
  }

  const canUseMessageVariable = hasFeature('variableReplacement')

  const { me } = useMe()
  const { user: sendAsUser } = useSendAs({
    sendAsContactOwner: context.sendAsContactOwner,
    sendAsUser: context.sendAsUser,
  })

  const getAccount = useGraphqlQuery(GetAccountDocument)
  const expiration = useMemo(
    () => getAccount.data?.getAccount?.postalDeliveryExpirationDays,
    [getAccount.data?.getAccount?.postalDeliveryExpirationDays]
  )

  useEffect(() => {
    setSubjectCharacterCount(estimateCharacterCount(context.emailSubjectLine || ''))
  }, [context.emailSubjectLine])

  const subjectError = useMemo(
    () =>
      getParsingError(context.emailSubjectLine || '') ||
      getMessageVariablesErrors(context.emailSubjectLine || '', sendAsUser || me),
    [context.emailSubjectLine, me, sendAsUser]
  )
  const giftMessageError = useMemo(
    () =>
      getParsingError(context.giftMessage || '') ||
      getMessageVariablesErrors(context.giftMessage || '', sendAsUser || me),
    [context.giftMessage, me, sendAsUser]
  )

  const handleGiftMessage = (newVal: string) => {
    send({ type: 'SET_GIFT_MESSAGE', data: newVal })
  }

  const handleEmailSubjectLine = (newVal: string) => {
    send({ type: 'SET_EMAIL_SUBJECT_LINE', data: newVal })
  }
  const accountName = useMemo(() => {
    const { displayName, name } = getAccount.data?.getAccount || {}
    return displayName || name || 'COMPANY'
  }, [getAccount.data?.getAccount])

  const sendAsName = useMemo(() => {
    return (context.sendAsType === SendAsType.User && sendAsUser?.firstName) || me?.firstName || me?.lastName || 'USER'
  }, [context.sendAsType, me?.firstName, me?.lastName, sendAsUser?.firstName])

  const subjectLinePlaceholder = useMemo(() => {
    return context.postal?.category === CATEGORY.Events
      ? DEFAULT_EVENT_SUBJECT_LINE(accountName, context.postal?.name, context.postal?.event?.eventDateTime)
      : DEFAULT_ITEM_SUBJECT_LINE(sendAsName, accountName)
  }, [accountName, context.postal?.category, context.postal?.event?.eventDateTime, context.postal?.name, sendAsName])

  const giftMessageErrorProps = usePostalSendFieldErrors({ context, field: 'giftMessage' })
  const emailSubjectLineErrorProps = usePostalSendFieldErrors({ context, field: 'emailSubjectLine' })

  return (
    <>
      <ZCardBody pt={{ base: 6, md: 6 }}>
        <ZHeading
          as="h3"
          size="h6"
          pb={8}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="atomicGray.800"
        >
          Email
          <PostalReviewPopOver context={context} />
        </ZHeading>
        <Stack spacing={5}>
          <Box>
            <ZFormLabel>Header</ZFormLabel>
            <ZInputTextWithVariables
              {...emailSubjectLineErrorProps}
              placeholder={subjectLinePlaceholder}
              data-testid="PostalCustomizeGiftEmail_SubjectLine_Input"
              value={context.emailSubjectLine || ''}
              onChange={handleEmailSubjectLine}
              isInvalid={subjectCharacterCount > 60 || !!subjectError}
              messageVariables={messageVariables}
              colorScheme={getSendMethodColor(context).replace(/\.\d00/, '')}
              // isDisabled={!context.deliveryEmail}
            />
            {subjectCharacterCount > 60 && (
              <Text mt={2}>
                Please keep in mind that the optimal length of an email subject line is 60 characters or less.
              </Text>
            )}
            <InlineErrorDisplay error={subjectError} />
          </Box>

          <Box>
            <Flex
              justifyContent="space-between"
              alignItems="baseline"
            >
              <ZFormLabel>
                Email Message{' '}
                <Box
                  as="span"
                  color="red.500"
                >
                  *
                </Box>
              </ZFormLabel>
              {canUseMessageVariable && (
                <Box textAlign="right">
                  <MessageVariablesButton onClick={seeMessageVariables.onOpen} />
                  <SavedMessagesButton onClick={selectGiftMessage.onOpen} />
                </Box>
              )}
            </Flex>

            <ZTextareaWithVariables
              {...giftMessageErrorProps}
              value={context.giftMessage || ''}
              onChange={handleGiftMessage}
              placeholder={giftMessagePlaceholder(context)}
              isInvalid={!!giftMessageError}
              data-testid="PostalCustomizeGiftEmail_textarea_message"
              messageVariables={messageVariables}
              colorScheme={getSendMethodColor(context).replace(/\.\d00/, '')}
              // isDisabled={!giftEmailRequired(context) && !context.deliveryEmail}
            />
            <InlineErrorDisplay error={giftMessageError} />
          </Box>

          {expiration && (
            <Box>
              <ZAlert
                status="info"
                hideClose
              >
                This {context.postal?.category === CATEGORY.Events ? 'invitation' : 'gift email'} expires in{' '}
                {expiration} business days.{' '}
                {hasPermission('account.update')
                  ? `This is can be updated or modified in your Profile under Email Settings > Gift Emails.`
                  : `This setting is managed by your Admin. Please contact them to learn more.`}
              </ZAlert>
            </Box>
          )}
        </Stack>
      </ZCardBody>

      {seeMessageVariables.isOpen && (
        <MessageVariablesDrawer
          isOpen={seeMessageVariables.isOpen}
          onClose={seeMessageVariables.onClose}
          method={context.method}
        />
      )}

      {selectGiftMessage.isOpen && (
        <MessagesDrawer
          isOpen={selectGiftMessage.isOpen}
          onClose={selectGiftMessage.onClose}
          onSelect={handleSelectGiftMessage}
        />
      )}
    </>
  )
}
