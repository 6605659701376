import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import type { UiCardProps } from '@postal-io/postal-ui'
import {
  internalProgressBarProps,
  internalTableProps,
  UiSSDataTable,
  useAlertError,
  useGraphqlFilter,
  ZButton,
  ZCard,
  ZCardBody,
  ZCardHeader,
  ZCheckbox,
} from '@postal-io/postal-ui'
import type { SearchIntegrationSyncQueryVariables } from 'api'
import { SearchIntegrationSyncDocument } from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { capitalize } from 'lodash'
import React from 'react'
import { INTEGRATION_HISTORY_COLUMNSV2 } from './data'

interface IntegrationHistoryProps extends UiCardProps {
  system?: string
}

export const IntegrationHistory: React.FC<IntegrationHistoryProps> = ({ system, ...rest }) => {
  const graphqlFilter = useGraphqlFilter<SearchIntegrationSyncQueryVariables>({
    initialState: { filter: { system: { eq: system } } },
    staticVariables: { limit: 100 },
  })

  const searchIntegrationsSync = useGraphqlInfiniteQuery(SearchIntegrationSyncDocument, graphqlFilter.variables)
  const integrations = searchIntegrationsSync.mergedData?.searchIntegrationSync ?? []
  useAlertError(searchIntegrationsSync.error)

  return (
    <ZCard
      isLoading={searchIntegrationsSync.isLoading}
      variant="form"
      {...rest}
    >
      <ZCardHeader
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        gap={2}
        p={8}
        pb={0}
      >
        History
        <ZInfoTooltip
          size="lg"
          label={`History of ${capitalize(system)} integrations`}
        />
      </ZCardHeader>
      <ZCardBody p={8}>
        <UiSSDataTable
          variant="list"
          isLoading={searchIntegrationsSync.isFetching}
          columns={INTEGRATION_HISTORY_COLUMNSV2}
          rows={integrations}
          rowKey="id"
          fetchMore={searchIntegrationsSync.fetchNextPage}
          hasMore={searchIntegrationsSync.hasNextPage}
          filter={graphqlFilter.variables.filter}
          orderBy={graphqlFilter.orderBy}
          onOrderBy={graphqlFilter.setOrderBy}
          HeaderButton={ZButton}
          SelectCheckbox={ZCheckbox}
          tableProps={internalTableProps}
          progressBarProps={internalProgressBarProps}
        />
      </ZCardBody>
    </ZCard>
  )
}
