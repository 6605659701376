import { UiButton, UiTopbar } from '@postal-io/postal-ui'
import { useEmailIntegrations } from 'hooks'
import { CompletedAction, useCompletedActions } from 'hooks/useCompletedActions'
import { useMemo } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

export const IntegrationTopbar = () => {
  const navigate = useNavigate()
  const swagCreator = useMatch('/swag-creator')
  const swagOrders = useMatch('/swag-creator/account/orders')
  const swag = swagCreator || swagOrders

  const { completedActions, markComplete } = useCompletedActions()
  const { hasSsoIntegration, hasEmailIntegration } = useEmailIntegrations()

  const handleCloseTopbar = () => markComplete(CompletedAction.DismissIntegrationTopbar)

  // Hide the topbar if
  // - user already has integration
  // - user has dismissed the topbar
  const showIntegrationTopbar = useMemo(() => {
    if (swag) return false
    if (hasEmailIntegration) return false
    if (completedActions.dismissIntegrationTopbar) return false
    return true
  }, [completedActions.dismissIntegrationTopbar, hasEmailIntegration, swag])

  if (!showIntegrationTopbar) return null

  return (
    <UiTopbar
      text="Connect your Google Apps account to send more personalized and effective emails."
      /* we force the topbar by not allowing it to be closed (or the close icon to be shown) if we have any sso integrations */
      onClose={hasSsoIntegration ? undefined : handleCloseTopbar}
      onClick={() => navigate('/profile')}
    >
      <UiButton
        size="sm"
        px={2}
        ml={4}
        minW="100px"
        variant="outline"
        color="white"
        borderColor="white"
        _hover={{ bg: 'secondary.300' }}
        _active={{ bg: 'secondary.300' }}
        onClick={() => navigate('/profile')}
      >
        Learn More
      </UiButton>
    </UiTopbar>
  )
}
