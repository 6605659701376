import type { BoxProps } from '@chakra-ui/react'
import { Box, Wrap, WrapItem } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiSlide,
  UiSlideArrows,
  UiSlideCard,
  UiSlideCards,
  UiSlideContainer,
  UiSlideDots,
  UiSlideTitle,
  useAlerts,
  ZButton,
  ZCard,
  ZCardBody,
  ZCardHeader,
  ZHeading,
  ZLink,
  ZModal,
  ZModalBody,
  ZModalCloseButton,
  ZModalContent,
  ZModalHeader,
  ZModalOverlay,
  ZSidebar,
  ZSidebarBanner,
} from '@postal-io/postal-ui'
import type { EmailPreviewUrl, EmailTemplate } from 'api'
import {
  CreateEmailPreviewUrlsDocument,
  GetAccountDocument,
  SearchEmailTemplatesDocument,
  UpdateAccountDocument,
} from 'api'
import { ZInfoTooltip } from 'components/Common/ZComponents'
import { PageTitle } from 'hooks'
import { DEFAULT_EMAIL_TEMPLATE_ID } from 'lib'
import React, { useCallback, useMemo, useState } from 'react'
import { MdOutlineCheckCircleOutline, MdOutlineChevronRight } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useMeasure } from 'react-use'
import { CenteredBox, Iframe } from '../Common'
import { ProfileSidebarBlocks } from './ProfileSidebarBlocks'

interface EmailPreviewProps extends Omit<BoxProps, 'onSelect'> {
  isSelected: boolean
  isDisabled: boolean
  template: EmailTemplate
  onSelect: (template: EmailTemplate) => void
  onPreview: (previews: EmailPreviewUrl[]) => void
}

const EmailPreview: React.FC<EmailPreviewProps> = ({
  isSelected,
  isDisabled,
  template,
  onSelect,
  onPreview,
  ...rest
}) => {
  const previewQuery = useGraphqlQuery(CreateEmailPreviewUrlsDocument, {
    data: { emailTemplateId: template.id },
    renderSubjectLine: false,
  })

  const previews = useMemo(
    () => previewQuery.data?.createEmailPreviewUrls?.filter((p) => p.previewType === 'fullscreen') || [],
    [previewQuery.data?.createEmailPreviewUrls]
  )

  const preview = useMemo(() => previews.find((p) => p.emailType === 'GIFT_EMAIL'), [previews])

  return (
    <>
      <Box
        w="100%"
        h="100%"
        data-testid="EmailTemplate"
        {...rest}
      >
        <ZButton
          variant="link"
          isDisabled={isDisabled}
          color="atomicBlue.400"
          fontWeight={500}
          fontSize="md"
          textAlign="center"
          w="100%"
          mb={1}
          onClick={() => onSelect(template)}
        >
          {template.name}
        </ZButton>

        <Box position="relative">
          {isSelected && (
            <Box
              color="atomicBlue.400"
              bgColor="white"
              w="28px"
              h="28px"
              position="absolute"
              top="-8px"
              left="-8px"
              borderRadius="full"
              zIndex={1}
            >
              <MdOutlineCheckCircleOutline size="28px" />
            </Box>
          )}
          <Box
            borderRadius="lg"
            background="white"
            w="100%"
            h="385px"
            overflow="hidden"
            borderWidth={isSelected ? '2px' : '1px'}
            borderColor={isSelected ? 'atomicBlue.400' : 'atomicGray.300'}
            boxSizing="border-box"
            padding={4}
          >
            <Iframe
              src={preview?.htmlUrl}
              minH={{ base: '300px', md: '400px' }}
              width="620px"
              height="760px"
              transform="scale(0.46)"
              transformOrigin="0 0"
            />
          </Box>
          <ZButton
            variant="link"
            mt={1}
            textAlign="center"
            color="atomicBlue.400"
            w="100%"
            onClick={() => onPreview(previews)}
            isDisabled={previewQuery.isLoading}
          >
            See an Example
          </ZButton>
        </Box>
      </Box>
    </>
  )
}

export const EmailTemplates: React.FC = () => {
  const Alert = useAlerts()
  const emailTemplatesQuery = useGraphqlQuery(SearchEmailTemplatesDocument)
  const templates = useMemo(
    () => emailTemplatesQuery.data?.searchEmailTemplates || [],
    [emailTemplatesQuery.data?.searchEmailTemplates]
  )
  const accountQuery = useGraphqlQuery(GetAccountDocument)
  const account = useMemo(() => accountQuery.data?.getAccount, [accountQuery.data?.getAccount])
  const isLoading = accountQuery.isLoading && emailTemplatesQuery.isLoading

  const updateAccount = useGraphqlMutation(UpdateAccountDocument)

  const isSelected = useCallback(
    (template: EmailTemplate) => {
      return (account?.emailTemplateId ?? DEFAULT_EMAIL_TEMPLATE_ID) === template.id
    },
    [account?.emailTemplateId]
  )

  const handleSelect = useCallback(
    async (template: EmailTemplate) => {
      if (isSelected(template)) return Alert.success('This template is already selected')
      try {
        await updateAccount.mutateAsync({ id: account?.id as string, data: { emailTemplateId: template.id } })
        Alert.success('Email template updated')
      } catch (err) {
        Alert.error(err)
      }
    },
    [Alert, account?.id, isSelected, updateAccount]
  )

  const [previews, setPreviews] = useState<EmailPreviewUrl[]>([])
  const [ref] = useMeasure()
  return (
    <>
      <CenteredBox isLoaded>
        <ZSidebar
          sidebarBlocks={<ProfileSidebarBlocks />}
          m={0}
          p={0}
        >
          <ZSidebarBanner title="Email Templates" />
          <PageTitle title="Email Templates" />
          <ZCard
            isLoading={isLoading}
            variant="form"
            w="100%"
            h="100%"
          >
            <ZCardHeader
              p={8}
              pb={0}
              justifyContent="flex-start"
              alignItems="center"
              gap={2}
            >
              Email Templates
              <ZInfoTooltip label="Select which email template you prefer for all emails that are sent to your contacts." />
            </ZCardHeader>
            <Wrap
              spacing={4}
              p={8}
            >
              {templates.map((template) => (
                <WrapItem
                  key={template.id}
                  maxW="320px"
                  w="100%"
                >
                  <EmailPreview
                    template={template}
                    isSelected={isSelected(template)}
                    onSelect={handleSelect}
                    onPreview={setPreviews}
                    isDisabled={updateAccount.isLoading}
                  />
                </WrapItem>
              ))}
            </Wrap>
            <Box
              p={8}
              borderTopWidth="1px"
              borderTopColor="atomicGray.300"
            >
              <ZLink
                to={'/account/branding'}
                color="atomicBlue.400"
                display="flex"
                alignItems="center"
                as={Link}
              >
                Set your branding, colors, fonts, and logo <MdOutlineChevronRight size="20px" />
              </ZLink>
            </Box>
          </ZCard>
        </ZSidebar>
      </CenteredBox>
      {!!previews.length && (
        <ZModal
          size="6xl"
          isOpen={!!previews.length}
          onClose={() => setPreviews([])}
          scrollBehavior="inside"
        >
          <ZModalOverlay />
          <ZModalContent>
            <ZModalHeader>Personalized Email Template</ZModalHeader>
            <ZModalCloseButton />
            <ZModalBody
              /* @ts-ignore */
              ref={ref}
            >
              <ZCard
                variant="form"
                mb={8}
              >
                <ZCardBody p={8}>
                  <UiSlide>
                    <UiSlideContainer
                      maxW="6xl"
                      mx="auto"
                    >
                      <UiSlideCards>
                        {previews.map((preview, idx) => {
                          return (
                            <UiSlideCard key={idx}>
                              <UiSlideTitle>
                                <ZHeading
                                  fontSize="lg"
                                  fontWeight="bold"
                                >
                                  {/* quick and dirty string replace since we're not using Gift Email in Engage */}
                                  {preview.emailName.replace('Gift Email', 'Personalized Email')}
                                </ZHeading>
                              </UiSlideTitle>
                              <Iframe
                                pt={9}
                                src={preview.htmlUrl}
                                h="900px"
                              />
                            </UiSlideCard>
                          )
                        })}
                      </UiSlideCards>
                      <UiSlideArrows />
                      <UiSlideDots />
                    </UiSlideContainer>
                  </UiSlide>
                </ZCardBody>
              </ZCard>
            </ZModalBody>
          </ZModalContent>
        </ZModal>
      )}
    </>
  )
}
